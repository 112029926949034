import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    generatedDocumentsFeatureKey,
    GeneratedDocumentState,
    selectAllGeneratedDocument,
    selectGeneratedDocumentEntities,
    selectGeneratedDocumentFetched,
    selectGeneratedDocumentLatestUpdatedAt,
} from '../reducers/generatedDocuments.reducer';

const selectGeneratedDocument = createFeatureSelector<GeneratedDocumentState>(generatedDocumentsFeatureKey);
const getGeneratedDocumentEntitys = createSelector(selectGeneratedDocument, selectGeneratedDocumentEntities);

export const getGeneratedDocuments = createSelector(selectGeneratedDocument, selectAllGeneratedDocument);
export const getGeneratedDocumentsDictionary = getGeneratedDocumentEntitys;
export const getGeneratedDocumentsFetched = createSelector(selectGeneratedDocument, selectGeneratedDocumentFetched);
export const getGeneratedDocumentLatestUpdatedAt = createSelector(selectGeneratedDocument, selectGeneratedDocumentLatestUpdatedAt);
export const getGeneratedDocumentById = (props: { id: number }) => createSelector(getGeneratedDocumentEntitys, (dictionary) => props?.id && dictionary[props.id]);

const getSorted = (props: { sortBy: 'createdAt'; direction?: 'desc' | 'asc' }) =>
    createSelector(getGeneratedDocuments, (entities) => {
        switch (props.sortBy) {
            case 'createdAt': {
                if (props.direction === 'desc') {
                    return entities.sort((a, b) => (a.CreatedAt < b.CreatedAt ? 1 : -1));
                } else {
                    return entities.sort((a, b) => (a.CreatedAt > b.CreatedAt ? 1 : -1));
                }
            }
            default: {
                return entities;
            }
        }
    });
export const getGeneratedDocumentsSortedByCreatedAt = getSorted({ sortBy: 'createdAt', direction: 'desc' });
