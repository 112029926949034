<div
    class="dropzone dz-wrapper"
    #dropzone="ngxDropzone"
    [dropzone]="dropzoneConfig"
    (error)="dropzoneError($event)"
    (success)="dropzoneSuccess($event)"
    (uploadProgress)="dropzoneUploadProcess($event)"
    (addedFile)="dropzoneAddedFile($event)"
    (queueComplete)="onDropzoneQueueComplete($event)"
>
    <div class="dz-message">
        <div class="dz-text">{{ dropzoneConfig.dictDefaultMessage }}</div>
    </div>
</div>
<ng-template #deleteButton>
    <button app-round-button Color="red" class="delete-button"><fa-icon icon="trash"></fa-icon></button>
</ng-template>
