import { formatDate } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter, first, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { ResourceAvailableType } from 'src/app/dave-data-module/graphql-types';
import { ResourceResolver } from 'src/app/dave-data-module/guards/resource-dispo/resource.resolver';
import { ResourceScheduleTypeEnum } from '../../../dave-data-module/entities/resource-dispo/resource-schedule.entity';
import { AmountTypeEnumNames } from '../../../dave-data-module/entities/resource-dispo/resource.entity';
import { ResourceScheduleResolver } from '../../../dave-data-module/guards/resource-dispo/resource-scheduler.resolver';
import { ResourceTypeResolver } from '../../../dave-data-module/guards/resource-dispo/resource-type.resolver';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { ResourceScheduleActionTypes } from '../../../dave-data-module/State/actions/resource-dispo/resource-schedule.actions';
import { getCommissionById } from '../../../dave-data-module/State/selectors/commission.selector';
import { getResourceSchedules, getResourceSchedulesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource-schedule.selectors';
import { getResourceTypeDictionary, getResourceTypesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource-type.selectors';
import { getResourceDictionary, getResourcesActive, getResourcesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import { SearchQueriesDebounceTime, stringSearch } from '../../../helper/helper';
import { SelectResourcePopupComponentDialogData } from '../select-resource-popup/select-resource-popup.component';
import { BaseActionTypes } from "../../../dave-data-module/State/actions/base.actions";

interface TableData {
    ResourceId: number;
    Name: string;
    Type: string;
    Available$: Observable<string>;
    AmountForm: FormControl<number>;
    CheckboxForm: FormControl<boolean>;
    TimespanForm: FormGroup<{ from: FormControl<Moment>; to: FormControl<Moment> }>;
}

@Component({
    selector: 'app-select-resource',
    templateUrl: './select-resource.component.html',
    styleUrls: ['./select-resource.component.scss'],
})
export class SelectResourceComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(MatSort) private matSort?: MatSort;

    public SelectedRowIndex: number;
    public Selected$: Observable<{ id: number; label: string }[]>;

    public PersonColumnHeaders = {
        Name: 'Name',
        Available: 'Verfügbar',
        Type: 'Art',
    };
    public PersonAutoColumns = ['Name', 'Type'];
    public Multiselect = new BehaviorSubject(false);
    public Columns$ = this.Multiselect.pipe(map((m) => ['checkbox', 'Name', 'Type', 'available', 'formControl']));

    private searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private subscriptions: Subscription[] = [];
    private subscriptionTimeForm: Subscription;
    public DataSource$: BehaviorSubject<TableVirtualScrollDataSource<TableData>> = new BehaviorSubject(new TableVirtualScrollDataSource<TableData>([]));

    public TimespanForm = new FormGroup({
        from: new FormControl<Moment>(moment()),
        to: new FormControl<Moment>(moment()),
    });

    private availableRes$ = this.TimespanForm.valueChanges.pipe(
        debounceTime(300),
        startWith(null),
        map(() => this.TimespanForm.value),
        switchMap((timespan) => this.store.select(getResourceSchedules).pipe(map(() => timespan))),
        switchMap((timespan) => {
            const query = `
            {
              resourceAvailable(startDate: "${FrontendDate(timespan.from?.toDate())}", endDate: "${FrontendDate(timespan.to?.toDate())}") {
                amount
                resourceId
              }
            }
            `;

            return timespan.from && timespan.to && timespan.from.isSameOrBefore(timespan.to) ? this.api.graphQl({ query }).pipe(map((res) => res?.resourceAvailable)) : of(new Array<ResourceAvailableType>());
        }),
        tap(res =>  {
            if (!res) {
                this.store.dispatch(BaseActionTypes.ErrorAction({Payload: {ToasterMessage: 'Anzahl verfügbarer Geräte abrufen fehlgeschlagen'}}))
            }
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    @Input() ShowHeadline = true;
    @Input() ComponentData: SelectResourcePopupComponentDialogData;

    constructor(
        private store: Store<State>,
        private resourceTypeResolver: ResourceTypeResolver,
        private resourceResolver: ResourceResolver,
        private resourceScheduleResolver: ResourceScheduleResolver,
        private api: HttpService,
    ) {
        this.subscriptionTimeForm = this.TimespanForm.valueChanges.subscribe((x) => {
            this.DataSource$.pipe(first()).subscribe((y) => {
                y.data.forEach((item) => {
                    item.TimespanForm.reset(x);
                });
            });
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(combineLatest([
            this.store.select(getResourcesFetched).pipe(
                tap((fetched) => {
                    if (!fetched) {
                        this.resourceResolver.resolve();
                    }
                }),
                filter((v) => !!v),
                switchMap(() => this.store.select(getResourcesActive)),
            ),
            this.store.select(getResourceTypesFetched).pipe(
                tap((fetched) => {
                    if (!fetched) {
                        this.resourceTypeResolver.resolve();
                    }
                }),
                filter((v) => !!v),
                switchMap(() => this.store.select(getResourceTypeDictionary)),
            ),
        ])
            .pipe(
                map(([resources, resourceTypes]) => {
                    return new TableVirtualScrollDataSource(
                        resources.map<TableData>((resource) => {
                            const type = resource.ResourceTypeId && resourceTypes[resource.ResourceTypeId];
                            const TimespanForm = new FormGroup({
                                from: new FormControl<Moment>(this.TimespanForm.value.from),
                                to: new FormControl<Moment>(this.TimespanForm.value.to),
                            });
                            return {
                                ResourceId: resource.Id,
                                Name: resource.Name,
                                Type: type ? type.Name : '',
                                Available$: combineLatest([
                                    TimespanForm.valueChanges.pipe(debounceTime(300), startWith(null), map(() => TimespanForm.value)),
                                ]).pipe(
                                    switchMap(v => this.store.select(getResourceSchedules).pipe(map(() => v))),
                                    switchMap(([form]) => {
                                        const globalForm = this.TimespanForm.value;
                                        if (globalForm.to === form.to && globalForm.from === form.from) {
                                            return this.availableRes$;
                                        }
                                        const query = `
                                        {
                                          resourceAvailable(startDate: "${FrontendDate(form.from?.toDate())}", endDate: "${FrontendDate(form.to?.toDate())}", resourceId: ${resource.Id}) {
                                            amount
                                            resourceId
                                          }
                                        }
                                        `;
                                        return form.from && form.to && form.from.isSameOrBefore(form.to) ? this.api.graphQl({ query }).pipe(map((res) => res?.resourceAvailable)) : of(new Array<ResourceAvailableType>());
                                    }),
                                    map((res) => {
                                        if (!res) {
                                            this.store.dispatch(BaseActionTypes.ErrorAction({Payload: {ToasterMessage: 'Anzahl verfügbarer Ressourcen abrufen fehlgeschlagen'}}))
                                        }
                                        const amount = res?.find((r) => r.resourceId === resource.Id)?.amount;
                                        return amount ? amount + (resource.AmountType ? ' ' + AmountTypeEnumNames.get(resource.AmountType) : '') : '-';
                                    }),
                                ),
                                AmountForm: new FormControl<number | null>(null),
                                TimespanForm,
                                CheckboxForm: new FormControl<boolean>(false),
                            };
                        }),
                    );
                }),

                tap(
                    (dataSource) =>
                        (dataSource.sortingDataAccessor = (object, key) => {
                            switch (key) {
                                case 'KdNr':
                                    return `${object[key]}`.trim().toLowerCase();
                                default:
                                    return object[key];
                            }
                        }),
                ),

                tap((dataSource) => (dataSource.filterPredicate = (data, searchTerm) => [data.Name, data.Type].some((value) => value && stringSearch(value, searchTerm)))),
                tap((dataSource) => {
                    if (this.matSort) {
                        dataSource.sort = this.matSort;
                    } else {
                        console.warn('no matSort');
                    }
                }),

                switchMap((dataSource) =>
                    this.searchString.pipe(
                        debounceTime(SearchQueriesDebounceTime),
                        tap((searchTerm) => (dataSource.filter = searchTerm.trim().toLowerCase())),
                        map(() => dataSource),
                    ),
                ),
            )
            .subscribe((v) => {
                this.DataSource$.next(v);
            }));
    }

    SaveButtonClick(data: TableData) {
        if (this.TimespanForm.valid && data.AmountForm.valid && data.AmountForm.value) {
            const EndDate = data.TimespanForm.value.to.toDate();
            const StartDate = data.TimespanForm.value.from.toDate();
            StartDate.setHours(0, 0, 0, 0);
            EndDate.setHours(23, 59, 59, 999);
            this.store.dispatch(
                ResourceScheduleActionTypes.Create({
                    Payload: {
                        resourceId: data.ResourceId,
                        amount: data.AmountForm.value,
                        commissionId: this.ComponentData.CommissionId,
                        endDate: FrontendDate(EndDate),
                        startDate: FrontendDate(StartDate),
                        type: ResourceScheduleTypeEnum.Commission,
                    },
                }),
            );
            data.AmountForm.reset(null);
        }
    }

    RemoveSelected(id: number) {
        this.store.dispatch(ResourceScheduleActionTypes.Delete({ Payload: id }));
    }

    public DoSearch(value: string | null) {
        return this.searchString.next(value);
    }

    public Save() {
        this.DataSource$.pipe(first()).subscribe((v) => {
            v.data.filter((d) => d.CheckboxForm.value).forEach((d) => this.SaveButtonClick(d));
        });
    }

    ngOnInit(): void {
        if (this.ComponentData.startDate || this.ComponentData.endDate) {
            this.TimespanForm.reset({
                from: this.ComponentData.startDate ? moment(this.ComponentData.startDate) : moment(),
                to: this.ComponentData.endDate ? moment(this.ComponentData.endDate) : moment(),
            });
        } else if (this.ComponentData.CommissionId) {
            this.subscriptions.push(this.store
                .select(
                    getCommissionById({
                        id: this.ComponentData.CommissionId,
                    }),
                )
                .pipe()
                .subscribe((res) => {
                    this.TimespanForm.reset({
                        from: res.StartDate ? moment(res.StartDate) : res.PlannedStartDate ? moment(res.PlannedStartDate) : this.TimespanForm.value.from,
                        to: res.EndDate ? moment(res.EndDate) : res.PlannedEndDate ? moment(res.PlannedEndDate) : this.TimespanForm.value.to,
                    });
                }));
        }
        this.Selected$ = this.store.select(getResourceSchedulesFetched).pipe(
            tap((fetched) => {
                if (!fetched) {
                    this.resourceScheduleResolver.resolve();
                }
            }),
            filter((v) => v),
            switchMap(() => this.store.select(getResourceSchedules)),
            switchMap((rs) =>
                this.store.select(getResourcesFetched).pipe(
                    filter((result) => result),
                    switchMap(() => this.store.select(getResourceDictionary)),
                    map((resources) => {
                        return rs
                            .filter((r) => r.CommissionId === this.ComponentData.CommissionId && r.Type === ResourceScheduleTypeEnum.Commission)
                            .map((r) => {
                                return {
                                    id: r.Id,
                                    label:
                                        resources[r.ResourceId].Name +
                                        ' ' +
                                        formatDate(r.StartDate, 'shortDate', 'de-DE') +
                                        ' - ' +
                                        formatDate(r.EndDate, 'shortDate', 'de-DE') +
                                        ' ' +
                                        r.Amount +
                                        ' ' +
                                        (resources[r.ResourceId].AmountType ? AmountTypeEnumNames.get(resources[r.ResourceId].AmountType) : ''),
                                };
                            });
                    }),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }
}
