import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { CommissionActionTypes } from '../State/actions/commission.actions';
import { getCommissionLatestUpdatedAt, getCommissionsFetched } from '../State/selectors/commission.selector';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class CommissionResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getCommissionsFetched, getCommissionLatestUpdatedAt, CommissionActionTypes.Load, store);
    }
}
