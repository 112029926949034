import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { stringSearch } from '../../../../helper/helper';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';



export interface SelectSearchData {
    Name: string;
    Id: number;
    SearchContent?: string;
}

@Component({
    selector: 'app-select-search-legacy',
    templateUrl: './select-search-legacy.component.html',
    styleUrls: ['./select-search-legacy.component.scss'],
})
export class SelectSearchLegacyComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @ViewChild(CdkVirtualScrollViewport, { static: false }) cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
    @Input() Control: /*FormControlTyped<SelectSearchData> | */FormControl<SelectSearchData> = new FormControl<SelectSearchData>(null);
    @Input() Disabled = false;
    @Input() Values: SelectSearchData[];
    @Input() ValuesAreSorted: boolean;
    @Input() Key: SelectSearchData = null;
    @Input() Placeholder: string;
    @Input() Required = false;
    @Input() FullWidth = true;
    @Input() appearance: MatFormFieldAppearance;
    @Output() SelectionChange = new EventEmitter<MatSelectChange>();
    public SearchValue: UntypedFormControl = new UntypedFormControl('');

    public FilteredValues: ReplaySubject<SelectSearchData[]> = new ReplaySubject<SelectSearchData[]>(1);

    @ViewChild('singleSelect', {static: true}) SingleSelect: MatSelect;

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();
    public SelectedOptionBuffer = null;
    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (this.Key) {
            this.Control.setValue(this.Key);
        }
        if(this.Control.value) {
            const valueFromArray = this.Values.find(v => v.Id === this.Control.value.Id);
            this.SelectedOptionBuffer = valueFromArray || this.Control.value;
        }
        this.FilteredValues.next(this.ValuesAreSorted ? this.Values.slice() : this.Values.slice().sort((a, b) => b.Id - a.Id));

        this.Control.valueChanges.pipe(takeUntil(this._onDestroy), distinctUntilChanged((a, b) => a?.Id === b?.Id))
            .subscribe((value) => {
                const valueFromArray = value && this.Values.find(v => v.Id === value.Id);
                this.SelectedOptionBuffer = valueFromArray || value;
                this.cdr.detectChanges();
                this.SingleSelect.options.notifyOnChanges();
            });
        // listen for search field value changes
        this.SearchValue.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterValues();
            });
    }

    ngAfterViewInit() {
        this.setInitialValue();
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    protected setInitialValue() {
        this.FilteredValues.pipe(take(1), takeUntil(this._onDestroy)).subscribe(
            () => {
                this.SingleSelect.compareWith = (
                    a: SelectSearchData,
                    b: SelectSearchData
                ) => a && b && a.Id === b.Id;
            }
        );
    }

    protected filterValues() {
        if (!this.Values) {
            return;
        }
        // get the search keyword
        const search = this.SearchValue.value;
        if (!search) {
            this.FilteredValues.next(this.ValuesAreSorted ? this.Values.slice() : this.Values.slice().sort((a, b) => b.Id - a.Id));
            return;
        }
        // filter the banks
        this.FilteredValues.next(
            (this.ValuesAreSorted ? this.Values.slice() : this.Values.slice().sort((a, b) => b.Id - a.Id)).filter(
                (value) => stringSearch(value.Name, search) || stringSearch(value.SearchContent || '', search)
            )
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filterValues();
    }
    openChange($event: boolean) {
        console.log("open change", $event);
        if ($event) {
            this.cdkVirtualScrollViewPort.scrollToIndex(0);
            this.cdkVirtualScrollViewPort.checkViewportSize();
        } else {
        }
    }
    CompareFunction() {

    }
}
