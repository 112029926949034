import { Address } from '../../helper/helper';
import { OfficeType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export class OfficeEntity implements Address {
    constructor(
        public Id: number = null,
        public PhoneNumber: string = null,
        public Email: string = null,
        public InvoiceEmail: string = null,
        public Street: string = null,
        public PostalCode: string = null,
        public City: string = null,
        public Country: string = null,
        public Longitude: string = null,
        public Latitude: string = null,
        public IsHeadquarter: boolean = null,
        public CustomerId: number = null,
        public FaxNumber: string = null,
        public MobileNumber: string = null,
        public IBAN: string = null,
        public Swift: string = null,
        public BankName: string = null,
        public BankLocation: string = null,
        public BankPostalCode: string = null,
        public BankSortCode: string = null,
        public UpdatedAt: Date = null,
    ) {}

    get DisplayName() {
        return this.City ? (this.Street ? `${this.City} ${this.Street}` : this.City) : this.Street ? this.Street : this.Email;
    }
    public static readonly GQLFields: Array<keyof OfficeType> = [
        'bankLocation',
        'bankName',
        'bankPostalCode',
        'bankSortCode',
        'city',
        'country',
        'customerId',
        'email',
        'invoiceEmail',
        'faxNumber',
        'iBAN',
        'id',
        'isHeadquarter',
        'latitude',
        'longitude',
        'mobileNumber',
        'phoneNumber',
        'postalCode',
        'street',
        'swift',
        'updatedAt',
    ];

    public Clone(override: OfficeEntityAttributes = {}): OfficeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new OfficeEntity(), attr);
    }
}

export function OfficeEntityFromBackend(res: OfficeType): OfficeEntity {
    return new OfficeEntity(
        res.id,
        res.phoneNumber,
        res.email,
        res.invoiceEmail,
        res.street,
        res.postalCode,
        res.city,
        res.country,
        '' + res.longitude, // ToDo Datentyp anpassen
        '' + res.latitude, // ToDo Datentyp anpassen
        res.isHeadquarter,
        res.customerId,
        res.faxNumber,
        res.mobileNumber,
        res.iBAN,
        res.swift,
        res.bankName,
        res.bankLocation,
        res.bankPostalCode,
        res.bankSortCode,
        BackendDate(res.updatedAt),
    );
}

export interface OfficeEntityAttributes {
    Id?: number;
    PhoneNumber?: string;
    Email?: string;
    InvoiceEmail?: string;
    Street?: string;
    PostalCode?: string;
    City?: string;
    Country?: string;
    Longitude?: string;
    Latitude?: string;
    IsHeadquarter?: boolean;
    CustomerId?: number;
    FaxNumber?: string;
    MobileNumber?: string;
    IBAN?: string;
    Swift?: string;
    BankName?: string;
    BankLocation?: string;
    BankPostalCode?: string;
    BankSortCode?: string;
    UpdatedAt?: Date;
}
