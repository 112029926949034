import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { BaseActionTypes } from '../actions/base.actions';
import { NewsEntity, NewsEntityFromBackend } from '../../entities/news.entity';
import { HttpService } from '../../services/http.service';
import { NewsActionTypes } from '../actions/news.actions';
import { State } from '../index';


enum ErrorCodes {
    Load = 'News Abrufen fehlgeschlagen',
    Modify = 'News Bearbeiten fehlgeschlagen',
    Remove = 'News Löschen fehlgeschlagen',
    Add = 'News Hinzufügen fehlgeschlagen',
}

@Injectable()
export class NewsEffects {

    CreateNews$ = createEffect(() => this.actions$.pipe(
        ofType(NewsActionTypes.Create),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
                mutation {
                    createNews(
                     ${stringifyIfNotNullOrUndefined(Payload, 'headline')}
                     ${stringifyIfNotNullOrUndefined(Payload, 'text')}
                     ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                ) {
                    ${NewsEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.createNews
                        ? NewsActionTypes.UpdateOne({
                              Payload: NewsEntityFromBackend(res.createNews),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    ModifyNews$ = createEffect(() => this.actions$.pipe(
        ofType(NewsActionTypes.Change),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
            mutation {
                changeNews(
                    id: ${Payload.id}
                     ${stringifyIfNotNullOrUndefined(Payload, 'headline')}
                     ${stringifyIfNotNullOrUndefined(Payload, 'text')}
                     ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                     ${stringifyIfNotNullOrUndefined(Payload, 'hasBeenRead')}
                ) {
                    ${NewsEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res => {
                    const ret: Action[] = [];
                    if (res && res.changeNews) {
                        ret.push(
                            NewsActionTypes.UpdateOne({
                                Payload: NewsEntityFromBackend(res.changeNews),
                            }),
                        );
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    GetNews$ = createEffect(() => this.actions$.pipe(
        ofType(NewsActionTypes.LoadAll),
        concatMap(() => {
            const queryString = `
            query {
                news {
                    ${NewsEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                map(res => {
                    return res && res.news
                        ? NewsActionTypes.UpdateAll({
                              Payload: res.news.map(e => NewsEntityFromBackend(e)),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    RemoveNews$ = createEffect(() => this.actions$.pipe(
        ofType(NewsActionTypes.Delete),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
                mutation{
                  deleteNews(
                  id: ${action.Payload.id}
                  )
                }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.deleteNews
                        ? NewsActionTypes.RemoveOne({Payload: action.Payload.id})
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
