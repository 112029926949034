import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DocumentEditorContainerModule, DocumentEditorModule } from '@syncfusion/ej2-angular-documenteditor';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SimplebarAngularModule } from 'simplebar-angular';
import { NewDocumentViewMenu } from '../components/templates/new-document-view/new-document-view.module';
import { TagChipAutocompleteModule } from '../components/templates/tag-chip-autocomplete/tag-chip-autocomplete.module';
import { CustomMatMenuModule } from '../custom-mat-menu/custom-mat-menu.module';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveFileExplorerModule } from '../dave-file-explorer/dave-file-explorer.module';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { DaveScan2DaveListModule } from '../dave-scan2dave-list/dave-scan2dave-list.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { FileSizePipe } from '../dave-utils-module/dave-shared-components-module/pipes/filesize.pipe';
import { PipesModule } from '../dave-utils-module/dave-shared-components-module/pipes/pipes.module';
import { IconPipeModule } from '../dave-utils-module/icon-pipe/icon-pipe.module';
import { DaveDMSRoutingModule } from './dave-dms-routing.module';
import { DmsWrapperComponent } from './dave-dms/dms-wrapper/dms-wrapper.component';
import { FileCardComponent } from './dave-dms/file-card/file-card.component';
import { ImageEditorModule } from './dave-dms/image-editor/image-editor.module';
import { PdfEditorModule } from './dave-dms/pdf-editor/pdf-editor.module';
import { PreviewModule } from './dave-dms/preview/preview.module';

@NgModule({
    declarations: [DmsWrapperComponent],
    imports: [
        CommonModule,
        DaveDMSRoutingModule,
        AppButtonModule,
        CustomMatMenuModule,
        SimplebarAngularModule,
        FontAwesomeModule,
        DaveSharedComponentsModule,
        MatCardModule,
        PipesModule,
        MatButtonModule,
        MatSidenavModule,
        TagChipAutocompleteModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule,
        PdfViewerModule,
        NewDocumentViewMenu,
        MatChipsModule,
        MatRadioModule,
        FormsModule,
        MatDialogModule,
        DocumentEditorModule,
        DocumentEditorContainerModule,
        IconPipeModule,
        RouterModule,
        PreviewModule,
        MatInputModule,
        ReactiveFormsModule,
        DaveDoubleIconModule,
        PdfEditorModule,
        ImageEditorModule,
        DaveFileExplorerModule,
        MatMenuModule,
        ScrollingModule,
        DaveScan2DaveListModule,
        DaveLoadingModule,
    ],
    providers: [FileSizePipe],
})
export class DaveDmsModule {}
