import { Directive, ElementRef, HostListener, Input, OnDestroy, Optional } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { stringSearch } from '../../helper/helper';

/**
 * Direktive für HTML Input-Elemente, die ein Array übergeben bekommt und es anhand des eingegebenen
 * Suchbegriffs filtert.
 *
 * Inputs: `Options` (`appFilter` im Template), `MapFn`
 *
 * Wenn das `Options` Array (vom Typ `T`) nicht aus primitiven Datentypen besteht, ist es ratsam,
 * ebenfalls eine `MapFn` zu übergeben.
 *
 * Auf das gefilterte Array kann durch das `FilteredOptions$` Observable zugegriffen werden.
 *
 * Wenn das Eingabefeld in einem `MatSelect` liegt, fokussiert die Direktive es beim Öffnen des
 * Overlays automatisch und löscht ebenfalls den eingegebenen Wert beim Schließen des Overlays.
 *
 * Beispielhafte Verwendung im Template (Property Binding zur Übergabe des Arrays, optional Export
 * in Template-Variable zum Verwenden des gefilterten Arrays):
 * ```html
 * <mat-form-field>
 *   <mat-select>
 *     <mat-form-field>
 *       <input matInput [appFilter]="Data" #filter="appFilter" />
 *     </mat-form-field>
 *
 *     <mat-option *ngFor="let option of filter.FilteredOptions$ | async" [value]="option">
 *       {{ option }}
 *     </mat-option>
 *   </mat-select>
 * </mat-form-field>
 * ```
 *
 * Verwendung in der Komponentenklasse (mittels View Query):
 * ```ts
 * @ViewChild(FilterDirective) filter?: FilterDirective<T>;
 *
 * ngAfterViewInit() {
 *   this.filter.FilteredOptions$.subscribe( ... );
 * }
 * ```
 */
@Directive({
    selector: 'input[appFilter]',
    exportAs: 'appFilter',
})
export class FilterDirective<T = unknown> implements OnDestroy {
    /** Das Array der zu filternden Werte */
    @Input('appFilter')
    public get Options() {
        return this.options$.value;
    }

    public set Options(options: ReadonlyArray<T>) {
        if (Array.isArray(options)) {
            this.options$.next(options);
        }
    }

    /** Das Array der zu filternden Werte */
    private options$ = new BehaviorSubject<ReadonlyArray<T>>([]);

    /** Der Suchbegriff, nach dem das Array gefiltert werden soll */
    private searchTerm$ = new BehaviorSubject('');

    /** Ein Observable des Arrays der gefilterten Werte */
    public FilteredOptions$ = combineLatest([
        this.options$,
        this.searchTerm$.pipe(map(searchTerm => searchTerm.trim().toLowerCase())),
    ]).pipe(
        map(([options, searchTerm]) => {
            // console.log([options, searchTerm])
            return options.filter(option =>
                    stringSearch(this.MapFn(option), searchTerm),
                )
                    .slice(0, 50)
            }
        ),
    );

    /** Gibt ein Mal einen Wert aus, wenn die Direktive zerstört wird */
    private onDestroy$ = new Subject<void>();

    constructor(elementRef: ElementRef<HTMLInputElement>, @Optional() matSelect?: MatSelect) {
        if (matSelect) {
            matSelect.openedChange
                .asObservable()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(opened =>
                    opened
                        ? elementRef.nativeElement.focus()
                        : ((elementRef.nativeElement.value = ''),
                            // Wenn das Input-Element andernorts verwendet wird, z.B. mit Angular
                            // Forms, muss Angular über ein Event erfahren, dass sich der Wert
                            // geändert hat und es den Wert der FormControl aktualisieren muss.
                            elementRef.nativeElement.dispatchEvent(new Event('input'))),
                );
        }
    }

    /** Mappingfunktion, die einem Arrayelement einen anzeigbaren String zuordnet */
    @Input() public MapFn: (option: T) => string = option => `${option}`;

    // Wenn das Eingabefeld, auf dem diese Direktive aufbaut, eine Eingabe erhält...
    @HostListener('input', ['$event.target.value'])
    /** Setzt den Suchbegriff, nach dem das Array gefiltert wird */
    public SetSearchTerm(searchTerm: string) {
        this.searchTerm$.next(searchTerm);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }
}
