import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PublicFileEntity } from '../../entities/public-file.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { PublicFileActionTypes } from '../actions/public-file.actions';

export const PUBLIC_FILE_KEY = 'public-file';

export interface PublicFileState extends EntityState<PublicFileEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;
}

const adapter = createEntityAdapter<PublicFileEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const publicFileReducer = createReducer(
    initialState,
    on(PublicFileActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(PublicFileActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(PublicFileActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
export const selectAllPublicFile = selectAll;
export const selectPublicFileEntities = selectEntities;
export const selectPublicFileFetched = (state: PublicFileState) => state.fetched;
export const selectPublicFileLatestUpdatedAt = (state: PublicFileState) => state.latestUpdatedAt;
