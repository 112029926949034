import { ItemModel } from '@syncfusion/ej2-angular-navigations';
import {
    EmployeeToVacationEntity,
    VacationStatusEnum,
    VacationTypeEnum,
} from '../../dave-data-module/entities/employee-to-vacation.entity';
import { ColorEntityFromString } from '../../dave-data-module/entities/color.entity';

export const desktopHeaderButtons: ItemModel[] = [
    {
        align: 'Left',
        prefixIcon: 'e-icon-prev',
        tooltipText: 'Previous',
        overflow: 'Show',
        cssClass: 'e-prev',
        htmlAttributes: { 'aria-label': 'previous period', role: 'navigation' },
    },
    {
        align: 'Left',
        prefixIcon: 'e-icon-next',
        tooltipText: 'Next',
        overflow: 'Show',
        cssClass: 'e-next',
        htmlAttributes: { 'aria-label': 'next period', role: 'navigation' },
    },
    {
        align: 'Left',
        text: 'September 2022',
        suffixIcon: 'e-icon-down-arrow',
        cssClass: 'e-date-range',
        overflow: 'Show',
        htmlAttributes: { 'aria-atomic': 'true', 'aria-live': 'assertive', 'aria-label': 'title', role: 'navigation' },
    },
    { align: 'Right', showAlwaysInPopup: false, prefixIcon: 'e-icon-day', text: 'heute', cssClass: 'e-today', overflow: 'Show' },
    { align: 'Right', type: 'Separator', cssClass: 'e-schedule-seperator' },
];
export const viewButtonWeek: ItemModel = { align: 'Right', showAlwaysInPopup: false, prefixIcon: 'e-icon-timeline-workweek', text: 'Arbeitswoche', cssClass: 'e-views e-timeline-work-week' };
export const viewButtonMonth: ItemModel = { align: 'Right', showAlwaysInPopup: false, prefixIcon: 'e-icon-timeline-month', text: 'Monat', cssClass: 'e-views e-timeline-month' };
export const viewButton3Month: ItemModel = { align: 'Right', showAlwaysInPopup: false, prefixIcon: 'e-icon-timeline-month', text: '3 Monate', cssClass: 'e-views e-timeline-month' };
export const viewButtonYear: ItemModel = { align: 'Right', showAlwaysInPopup: false, prefixIcon: 'e-icon-timeline-year-vertical', text: 'Jahr', cssClass: 'e-views e-timeline-year' };
export type ShiftplanViews = 'TimelineWorkWeek' | 'TimelineMonth' | 'Timeline3Month' | 'TimelineYear';
export interface ISchedulerEvent {
    EndTime: Date;
    IsAllDay?: boolean;
    StartTime: Date;
    /** can be html*/
    Subject: string;
}
export const SchedulerEventColors: {[key: string]: {primary: string, secondary: string}} = {
    grey: {
        primary: '#8f8f8f',
        secondary: '#d5d5d5',
    },
    red: {
        primary: '#c74747',
        secondary: '#FAE3E3',
    },
    blue: {
        primary: '#6fa1d2',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#dac465',
        secondary: '#FDF1BA',
    },
    green: {
        primary: '#78af66',
        secondary: '#cbf5bc',
    },
    purple: {
        primary: '#7758b7',
        secondary: '#cabce3',
    },
    purpleCapacity: {
        primary: '#6a52b1',
        secondary: '#cabce3',
    },
    lightpurple: {
        primary: '#c5b4e5',
        secondary: '#DDD7EA',
    },
    resourceTheme: {
        primary: '#f39325',
        secondary: '#FDF1BA',
    },
    lightpink: {
        primary: '#f79f9f',
        secondary: '#f7d7d7',
    }
};
export const getVacationColorEntity = (v: EmployeeToVacationEntity) => {
    switch (v.Type) {
        case VacationTypeEnum.Vacation:
            return v.VacationStatus === VacationStatusEnum.Approved ? ColorEntityFromString(SchedulerEventColors.purple.primary) : ColorEntityFromString(SchedulerEventColors.lightpurple.primary);
        case VacationTypeEnum.Absent:
            return ColorEntityFromString(SchedulerEventColors.grey.primary);
        case VacationTypeEnum.Illness:
            return ColorEntityFromString(SchedulerEventColors.red.primary);
        case VacationTypeEnum.Illnesswithoutcontinuedpay :
            return ColorEntityFromString(SchedulerEventColors.lightpink.primary);
        default:
            return ColorEntityFromString(SchedulerEventColors.blue.primary);
    }
}
export const getVacationColor = (v: EmployeeToVacationEntity) => {
    switch (v.Type) {
        case VacationTypeEnum.Vacation:
            return v.VacationStatus === VacationStatusEnum.Approved ? SchedulerEventColors.purple : SchedulerEventColors.lightpurple;
        case VacationTypeEnum.Absent:
            return SchedulerEventColors.grey;
        case VacationTypeEnum.Illness:
            return SchedulerEventColors.red;
        default:
            return SchedulerEventColors.blue;
    }
};
