import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DamageFlowEntity } from '../../entities/damageflow.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { DamageFlowActionTypes } from '../actions/damageflow.actions';

export const damageFlowsFeatureKey = 'damageFlosdasdaws';
export interface DamageFlowState extends EntityState<DamageFlowEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<DamageFlowEntity>({
    selectId: Entity => Entity.Id,
});

const initialState: DamageFlowState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const damageFlowReducer = createReducer(
    initialState,
    on(DamageFlowActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(DamageFlowActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllDamageFlow = selectAll;
export const selectDamageFlowEntities = selectEntities;

export const selectDamageFlowFetched = (state: DamageFlowState) => state.fetched;
export const selectDamageFlowLatestUpdatedAt = (state: DamageFlowState) => state.latestUpdatedAt;
