import { EmailSettingsTypeType } from '../graphql-types';

export class EmailSettingsTypeEntity {
    constructor(
        public Id: number = null,
        public Name: string = null,
        public Description: string = null,
        public PartnerId = null,
    ) {
    }

    public Clone(override: EmailSettingsTypeEntityAttributes = {}): EmailSettingsTypeEntity {
        const attr = {...this, ...override};
        return Object.assign(new EmailSettingsTypeEntity(), attr);
    }
}

export function EmailSettingsTypeEntityFromBackend(res: EmailSettingsTypeType): EmailSettingsTypeEntity {
    return new EmailSettingsTypeEntity(
        res.id,
        res.name,
        res.description,
        res.partnerId,

    );
}


export interface EmailSettingsTypeEntityAttributes {
    Id?: number;
    Name?: string ;
     Description?: string;
     PartnerId?: null;
        }
