import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-loading',
    template: `
        <mat-spinner [diameter]="Size"></mat-spinner>
    `,
    styles: [
        `
            :host {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        `,
    ],
})
export class LoadingComponent implements OnInit {
    /** Groesse in px */
    @Input() Size = 100;
    @Input() Transparent = true;

    @HostBinding('class') ElementClass;

    ngOnInit(): void {
        this.ElementClass = this.Transparent ? '' : 'not-transparent';
    }
}
