<!--<div class="header">-->
<!--    <h2 class="headline" *ngIf="Headline">{{Headline}}</h2>-->
<!--    <span class="spacer"></span>-->
<!--    <ng-content select="[headerButton]"></ng-content>-->
<!--</div>-->
<h1 mat-dialog-title>Bearbeiter verwalten</h1>
<app-multi-select-table
    mat-dialog-content
    *ngIf="ContentResolved$ | async; else loading"
    [TableData]="FilteredTableData$ | async"
    [TableDataConfig]="TableDataConfig$ | async"
    [Selected]="SelectedUsers$ | async"
    (RemoveSelected)="RemoveSelected($event)"
    Headline=""
    [SelectedHeadline]="'zugewiesene Bearbeiter'"
    SearchPlaceHolder="nach Bearbeitern suchen"
    (SearchStringChange)="SearchString.next($event)"
>
    <div class="filter-wrapper">
        <button
            app-round-button
            Inverted
            [matBadge]="FilterAmount$ | async"
            matTooltip="Filter"
            [matBadgeOverlap]="true"
            [matBadgeHidden]="!(FilterAmount$ | async)"
            (click)="filter.Open()"
        >
            <fa-icon icon="filter"></fa-icon>
        </button>
        <app-filter-card UnlimitedHeight #filter [Settings]="_FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
    </div>
</app-multi-select-table>

<mat-dialog-actions>
    <div class="cancel-button-wrapper">
        <button app-button mat-dialog-close="" Inverted [IsLoading]="LS.IsLoading$ | async">Abbrechen</button>
    </div>
    <button app-button (click)="Submit()" [IsLoading]="LS.IsLoading$ | async" Color="green">Speichern</button>
</mat-dialog-actions>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
