import { ComplaintTypeEntity } from '../../entities/complaintType.entity';
import { createReducer, on } from '@ngrx/store';
import { ComplaintTypeActionTypes } from '../actions/complaintType.actions';


export const complaintTypesFeatureKey = 'complaintTypes';

export interface ComplaintTypeState {
    complaintTypes: ComplaintTypeEntity[];
    fetched: boolean;
}

export const initialState: ComplaintTypeState = {
    complaintTypes: null,
    fetched: false,
};

export const ComplaintTypeReducer = createReducer(
    initialState,
    on(ComplaintTypeActionTypes.UpdateComplaintType, (state, { Payload }) => ({
        ...state,
        complaintTypes: Payload,
        fetched: true,
    })),
);
