import { createAction, props } from '@ngrx/store';
import { QMStatusEntity } from '../../entities/qMStatus.entity';


export const QMStatusActionTypes = {
    UpdateQMStatus: createAction(
        'data/QMStatus/update',
        props<{ Payload: Array<QMStatusEntity> }>(),
    ),
    GetQMStatusRequest: createAction(
        'data/QMStatus/getAll',
    ),
};

