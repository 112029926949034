import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { EmailDialogComponent, EmailDialogData } from '../../../components/detail-views/detail-event/email-dialog/email-dialog.component';
import { BookmarkEntity } from '../../../dave-data-module/entities/bookmark.entity';
import { CommentEntityTypeEnum } from '../../../dave-data-module/entities/comment.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { EventTypeEntity } from '../../../dave-data-module/entities/event-type.entity';
import { EventEntity, TaskPriorityNameColorIconMap } from '../../../dave-data-module/entities/event.entity';
import { FileEntity } from '../../../dave-data-module/entities/file.entity';
import { FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntity, Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { UserEntity } from '../../../dave-data-module/entities/user.entity';
import { EventResolver } from '../../../dave-data-module/guards/event.resolver';
import { EmailDataService } from '../../../dave-data-module/services/email-data.service';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { State } from '../../../dave-data-module/State';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { BookmarkActionTypes } from '../../../dave-data-module/State/actions/bookmarks.actions';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import { Person2EntityActionTypes } from '../../../dave-data-module/State/actions/person2entity.action';
import { getBookmarkByEventId } from '../../../dave-data-module/State/selectors/bookmarks.selectors';
import { getCommentByEntityTypeEntityId, getCommentFetched } from '../../../dave-data-module/State/selectors/comment.selector';
import { getCustomerDictionary } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getEmployees } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getEventTypesDictionary } from '../../../dave-data-module/State/selectors/event-type.selector';
import { getEventById } from '../../../dave-data-module/State/selectors/events.selectors';
import { getPartner } from '../../../dave-data-module/State/selectors/partners.selectors';
import { getPersonDictionary, getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2EntityByEventId } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { getUserDictionary } from '../../../dave-data-module/State/selectors/users.selectors';
import { IDetailListTemplateDataProperty } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { getErrorMessage } from '../../../helper/validation.helper';
import { DetailTasksComponent, DetailTasksComponentDialogData } from '../detail-tasks/detail-tasks.component';
import { ContactBookMeta, HistoryMeta, TaskPageMeta, UserAdministrationMeta } from './../../../helper/page-metadata';
interface TemplateData {
    Event: EventEntity;
    User: UserEntity;
    Users: UserEntity[];
    EventType: EventTypeEntity;
    Customer: CustomerEntity;
    Person: PersonEntity;
    Priority: { name: string; color: string; icon: IconProp };
    User2EventData: IDetailListTemplateDataProperty;
    Person2EntityData: IDetailListTemplateDataProperty;
    Person2Entities: Person2EntityEntity[];
    Persons: PersonEntity[];
    HasComment: boolean;
}
@Component({
    selector: 'app-event-card',
    templateUrl: './event-card.component.html',
    styleUrls: ['./event-card.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventCardComponent implements OnInit {
    @Output() FileClicked = new EventEmitter<number>();
    @Output() EventClicked = new EventEmitter<number>();
    @Input() EventId: number;
    @Input() Data: TemplateData;
    @Input() Expandable = true;

    public Bookmark$: Observable<BookmarkEntity | undefined>;
    public ChronikPath = HistoryMeta.Path;
    public ChronikIcon = HistoryMeta.Icon;
    public ContactBookPath = ContactBookMeta.Path;
    public UserAdministrationPath = UserAdministrationMeta.Path;
    public StyleTagRegEx = new RegExp('<style>(.|\\n)*<\\/style>', 'gi');

    GetErrorMessage = getErrorMessage;
    Event$: Observable<TemplateData>;
    public Files$: Observable<FileEntity[]>;
    EditUser2Event = false;
    EditPerson2Entity = false;
    protected bookmarkLoading$ = new BehaviorSubject<boolean>(false);
    constructor(
        private store$: Store<State>,
        private router: Router,
        private dialog: MatDialog,
        private emailDataService: EmailDataService,
        private fileDataService: FileDataService,
        private eventResolver: EventResolver,
        private actions$: Actions,
        private folderDataService: FolderDataService,
    ) {}

    public ClipboardIcon = TaskPageMeta.Icon;

    ngOnInit(): void {
        this.Bookmark$ = this.store$.select(getBookmarkByEventId({ id: this.EventId })).pipe(
            distinctUntilChanged((a, b) => a?.Id === b?.Id),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.Files$ = this.folderDataService.getFolderFromEntity(this.EventId, FolderTypes.event).pipe(
            switchMap((folder) => {
                if (!folder) {
                    console.error('EventFolder not found, EventId: ' + this.EventId);
                    return of([]);
                }

                return this.folderDataService.getFolderByIdWithChildren(folder.Id).pipe(
                    switchMap((folders) => combineLatest(folders.map((f) => f.Id).map((id) => this.fileDataService.GetFilesStateFromFolder$(id, false)))),
                    map((files) => files.filter(isNotNullOrUndefined).reduce((prev, curr) => [...prev, ...curr], [])),
                );
            }),
            withLatestFrom(this.store$.select(getEventById({ id: this.EventId }))),
            tap(([files, event]) => {
                if (files && event && files.length !== event.FileCount) {
                    this.eventResolver.resolve();
                }
            }),
            map(([files]) => files),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
        this.Event$ = combineLatest([
            this.store$.select(getEventById({ id: this.EventId })),
            this.store$.select(getUserDictionary),
            this.store$.select(getEventTypesDictionary),
            this.store$.select(getCustomerDictionary),
            combineLatest([
                this.store$.select(getPersonDictionary),
                this.store$.select(getPartner),
                this.store$.select(getPerson2EntityByEventId({ eventId: this.EventId })),
                this.store$.select(getCommentFetched).pipe(
                    filter((v) => !!v),
                    switchMap(() => this.store$.select(getCommentByEntityTypeEntityId({ entityId: this.EventId, entityType: CommentEntityTypeEnum.Event }))),
                ),
            ]),
        ]).pipe(
            map(([event, users, eventTypes, customers, [persons, partner, person2Entities, comments]]) => {
                return {
                    HasComment: !!comments.length,
                    Event: event,
                    User: users[event.UserId],
                    Users: event.UserIds?.map((id) => users[id]) || [],
                    EventType: eventTypes[event.EventTypeId],
                    Customer: event.CustomerId ? customers[event.CustomerId] : null,
                    Person: event.PersonId ? persons[event.PersonId] : null,
                    Priority: event.AdditionalData?.Priority ? TaskPriorityNameColorIconMap.get(event.AdditionalData?.Priority) : null,
                    User2EventData: {
                        key: ' ',
                        formControl: new UntypedFormControl(event.UserIds?.map((id) => users[id]) || []),
                        options: {
                            specialInput: {
                                chipAutocomplete: {
                                    MapFn: (option: UserEntity) => option.DisplayName + ', ' + option.Email,
                                    Options: Object.values(users).filter((u) => u.PartnerId === partner.Id),
                                    initialPatchDefaultValue: true,
                                },
                            },
                        },
                    },
                    Person2Entities: person2Entities,
                    Persons: Object.values(persons).filter((p) => isNotNullOrUndefined(person2Entities?.find((p2e) => p2e.PersonId == p.Id))),
                    Person2EntityData: {
                        key: ' ',
                        formControl: new UntypedFormControl(Object.values(persons).filter((p) => isNotNullOrUndefined(person2Entities?.find((p2e) => p2e.PersonId == p.Id)))),
                        options: {
                            specialInput: {
                                chipAutocomplete: {
                                    MapFn: (option: PersonEntity) => option.DisplayName + ', ' + option.Email,
                                    Options: Object.values(persons).filter((u) => u.PartnerId === partner.Id),
                                    initialPatchDefaultValue: true,
                                },
                            },
                        },
                    },
                };
            }),
        );
    }
    RemoveUser2Event(user: UserEntity, event: EventEntity) {
        this.store$.dispatch(
            EventsActionTypes.ModifyEvent({
                Payload: {
                    id: event.Id,
                    userIds: event.UserIds.filter((e) => e !== user.Id),
                },
            }),
        );
    }
    SaveUserToEvent(users: UserEntity[], event: EventEntity) {
        this.store$.dispatch(
            EventsActionTypes.ModifyEvent({
                Payload: {
                    id: event.Id,
                    userIds: users.map((u) => u.Id),
                },
            }),
        );
        this.EditUser2Event = false;
    }
    RemovePerson2Entity(person: PersonEntity) {
        this.store$
            .select(getPerson2EntityByEventId({ eventId: this.EventId }))
            .pipe(take(1))
            .subscribe((person2EntityList) => {
                const person2entity = person2EntityList.find((p2e) => p2e.PersonId == person.Id);
                if (isNotNullOrUndefined(person2entity)) {
                    this.store$.dispatch(
                        Person2EntityActionTypes.DeletePerson2Entity({
                            Payload: {
                                id: person2entity.Id,
                            },
                        }),
                    );
                }
            });
    }
    SavePersonToEvent(persons: PersonEntity[], event: EventEntity) {
        combineLatest([this.store$.select(getPerson2EntityByEventId({ eventId: this.EventId })), this.store$.select(getPersons)])
            .pipe(
                map(([person2Entities, personList]) => personList.filter((p) => isNotNullOrUndefined(person2Entities?.find((p2e) => p2e.PersonId == p.Id)))),
                take(1),
            )
            .subscribe((selectedPersonList) => {
                const newPersons = persons?.filter((p) => !selectedPersonList.includes(p));
                if (isNotNullOrUndefined(newPersons)) {
                    for (const np of newPersons) {
                        this.store$.dispatch(
                            Person2EntityActionTypes.AddPerson2Entity({
                                Payload: {
                                    entityType: Person2EntityEntityTypeEnum.Event,
                                    entityId: event.Id,
                                    personId: np.Id,
                                },
                            }),
                        );
                    }
                }
                this.EditPerson2Entity = false;
            });
    }
    OpenDetailTaskDialog(taskId?: number) {
        this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
            ...DetailTasksComponent.DefaultConfig,
            data: {
                EventId: taskId || null,
            },
        });
    }
    BookmarkClicked(EventId: number) {
        this.bookmarkLoading$.next(true);
        firstValueFrom(this.actions$.pipe(ofType(BookmarkActionTypes.RemoveMany, BookmarkActionTypes.SetOne, BaseActionTypes.ErrorAction))).then(() => {
            this.bookmarkLoading$.next(false);
        });
        firstValueFrom(this.Bookmark$).then((bookmark) => {
            if (bookmark) {
                this.store$.dispatch(
                    BookmarkActionTypes.RemoveBookmark({
                        BookmarkIds: [bookmark.Id],
                    }),
                );
            } else {
                this.store$.dispatch(BookmarkActionTypes.AddBookmark({ EventId }));
            }
        });
    }
    PanelClicked(event: MouseEvent, eventId: number, isTask: boolean) {
        console.log('panelClick');
        this.EventClicked.emit(eventId);
        event.stopPropagation();
        event.stopImmediatePropagation();
        if (isTask) {
            this.OpenDetailTaskDialog(eventId);
        }
    }

    ShowEmail(event: EventEntity) {
        if (event.EmailId) {
            this.emailDataService
                .GetEmailById$(event.EmailId)
                .pipe(filter(isNotNullOrUndefined), take(1))
                .subscribe((email) => {
                    if (email) {
                        this.dialog.open(EmailDialogComponent, {
                            ...EmailDialogComponent.DefaultConfig,
                            data: {
                                email,
                                event,
                                styleTagRegEx: this.StyleTagRegEx,
                            } as EmailDialogData,
                        });
                    } else {
                        console.error('email with id ' + event.EmailId + 'not found');
                    }
                });
        }
    }
    RouteTo(eventId) {
        this.router.navigate([this.ChronikPath + '/' + 'alle' + '/' + eventId + '']);
    }

    RouteToPerson(personId: number) {
        this.router.navigate([this.ContactBookPath + '/' + personId + '']);
    }

    RouteToEmployee(userId: number) {
        this.store$
            .select(getEmployees)
            .pipe(
                take(1),
                map((employees) => employees.find((e) => e.UserId === userId)),
            )
            .subscribe((employee) => {
                this.router.navigate([this.UserAdministrationPath + '/' + employee.Id + '']);
            });
    }
}
