import { createEntityAdapter,EntityState } from '@ngrx/entity';
import { createReducer,on } from '@ngrx/store';
import { CommentEntity } from '../../entities/comment.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { CommentActionTypes } from '../actions/comment.action';

export const COMMENTS_FEATURE_KEY = 'comments';

export interface CommentState extends EntityState<CommentEntity> {
    entityMap: { [key: string]: number[] };
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<CommentEntity>({
    selectId: Entity => Entity.Id,
});

const initialState: CommentState = adapter.getInitialState({
    entityMap: {},
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const commentReducer = createReducer(
    initialState,
    on(CommentActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, {
            ...state,
            fetched: true,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
            entityMap: Payload.reduce((prev, curr) => addToEntityMap(prev, getKeyForMap(curr), curr.Id), {}),
        }),
    ),
    on(CommentActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
            entityMap: Payload.reduce((prev, curr) =>  addToEntityMap(removeFromMap(prev, curr.Id), getKeyForMap(curr), curr.Id), {...state.entityMap}),
        }),
    ),
    on(CommentActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, { ...state,
        entityMap: [Payload].reduce((prev, curr) => addToEntityMap(removeFromMap(prev, curr.Id), getKeyForMap(curr), curr.Id), {...state.entityMap}), })),
    on(CommentActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, { ...state, entityMap: removeFromMap({...state.entityMap}, Payload)} )),
);
export function getKeyForMap(entity: {EntityId, EntityType}) {
    return entity.EntityType + '-' + entity.EntityId;
}
function removeFromMap(map: { [key: string]: number[] }, value: number) {
    Object.keys(map).forEach(key => {
        if (map[key].includes(value)) {
            const newArray = map[key].slice();
            newArray.splice(map[key].indexOf(value), 1);
            map[key] = newArray;
        }
    });
    return map;
}
function addToEntityMap(map: {[key: string]: number[]}, key: string, value: number) {
    if (map[key]) {
        map[key] = [...map[key], value];
    } else {
        map[key] = [value];
    }
    return map;
}
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllComment = selectAll;
export const selectCommentEntities = selectEntities;

export const selectCommentFetched = (state: CommentState) => state.fetched;
export const selectCommentLatestUpdatedAt = (state: CommentState) => state.latestUpdatedAt;
export const selectCommentEntityMap = (state: CommentState) => state.entityMap;
