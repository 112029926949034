import { Store } from '@ngrx/store';
import { State } from '../State';
import { Selector } from '@ngrx/store/src/models';
import { filter, switchMap, tap } from 'rxjs/operators';
import { EntityType } from "@dave/types";
import { ProcessEntity } from "../entities/process.entity";
import { CustomerEntity } from "../entities/customer.entity";
import { EmployeeEntity } from "../entities/employee.entity";
import { FolderEntity } from "../entities/folder.entity";
import { PersonEntity } from "../entities/person.entity";
import { Address } from "../../helper/helper";


export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const getFetched$ = <T>(store: Store<State>, fetchedSelector: Selector<object, boolean>, dataSelector: Selector<object, T>, resolver?: { resolve: () => void }) => {
    return store.select(fetchedSelector).pipe(
        tap((fetched) => {
            if (!fetched && resolver) {
                resolver.resolve();
            }
        }),
        filter((fetched) => fetched),
        switchMap(() => store.select(dataSelector)),
    );
};
export const EntityNameMap = new Map<EntityType, string>([
    // [EntityType.Customer, ''],
    // [EntityType.Commission, ''],
    [EntityType.Event, 'Ereignis'],
    [EntityType.Transmission, 'Videoübertragung'],
    [EntityType.Employee, 'Mitarbeiter'],
    [EntityType.Process, ProcessEntity.EntityName],
    [EntityType.Folder, 'Ordner'],
    [EntityType.Document, 'Datei'],
    [EntityType.Email, 'E-Mail'],
    [EntityType.Person, 'Kontakt'],
])

export const currencies: {code: string, label: string}[] = [
    {code: 'EUR', label: 'Euro'},
]
