import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, withLatestFrom } from 'rxjs/operators';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { SettingsActionTypes } from '../actions/settings.actions';
import { UsersActionTypes } from '../actions/users.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { SettingEntityAttributes, UserEntity } from '../../entities/user.entity';
import { getSetting, getUser } from '../selectors/users.selectors';

enum ErrorCodes {
    Modify = 'Benutzer Einstellungen Bearbeiten fehlgeschlagen',
}
@Injectable()
export class SettingsEffects {
    constructor(private actions$: Actions<DaveActions>, private store: Store<State>, private gatewayHttpService: HttpService) {}

    changeSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActionTypes.ChangeSettings),
            withLatestFrom(this.store.select(getSetting), this.store.select(getToken), this.store.select(getUser)),
            concatMap(([action, userSettings, token, user]) => {
                const settings: SettingEntityAttributes = { ...userSettings, ...action.Settings };
                if (settings.ListFilters) {
                    settings.ListFilters = Array.from(settings.ListFilters).reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {}) as any;
                }
                const queryString = `
              mutation {
                  changeUser(
                      id: ${user.Id}
                      additionalData: ${JSON.stringify(JSON.stringify({ ...user.AdditionalData, settings }))}
                  ) {${UserEntity.GqlFields}}
              }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
                    concatMap((res) => {
                        if (res && res.changeUser) {
                            const additionalData = res.changeUser.additionalData as any;
                            return [
                                UsersActionTypes.UpdateUserSettings({
                                    Payload: additionalData?.settings,
                                }),
                            ];
                        } else {
                            return [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } })];
                        }
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Modify,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
}
