import { CommonModule,CurrencyPipe,DatePipe } from '@angular/common';
import { Component,EventEmitter,Input,Output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LedgerImportDocumentTypeNames,LedgerImportDocumentTypeNumberLabel,LedgerImportDocumentTypes,LedgerImportEntity } from '../dave-data-module/entities/ledger-import.entity';
import { ViewStyleConfig } from '../dave-data-module/entities/viewStyleSetting.entity';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveListCardModule } from '../dave-list-card/dave-list-card.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { PermissionService } from '../dave-utils-module/dave-shared-components-module/services/permission.service';
import { DaveHeaderHeight,Mehrwertsteuer } from '../helper/helper';
import { AllReportsMeta,DMSPageMeta,InvoiceEditorMeta,ReportsPageMeta } from '../helper/page-metadata';

@Component({
    selector: 'app-ledger-import-list-card',
    standalone: true,
    imports: [CommonModule, MatExpansionModule, FontAwesomeModule, MatTabsModule, DaveListCardModule, DaveDoubleIconModule, MatMenuModule, AppButtonModule, MatTooltipModule],
    templateUrl: './ledger-import-list-card.component.html',
    styleUrls: ['./ledger-import-list-card.component.scss'],
    providers: [CurrencyPipe, DatePipe],
})
export class LedgerImportListCardComponent {
    public HeaderHeight = DaveHeaderHeight;
    private ledgerImports$ = new BehaviorSubject<LedgerImportEntity[]>([]);
    @Input() set LedgerImports(v: LedgerImportEntity[]) {
        this.ledgerImports$.next(v || []);
    }
    @Input() view: Partial<ViewStyleConfig> = {};
    /**
     * Represents an event emitter for document click events.
     * @param DocumentId
     */
    @Input() NavigateOnClick = false;
    @Input() ShowDocumentButton = true;
    @Output() DocumentClick = new EventEmitter<number>();
    @Output() LedgerImportClick = new EventEmitter<{ entry: LedgerImportEntity; index: number; element: HTMLElement }>();
    @Output() NewLedgerImportClick = new EventEmitter<LedgerImportDocumentTypes>();
    private getLedgerImportsByDocTypes$ = (...documentTypes: LedgerImportDocumentTypes[]) =>
        this.ledgerImports$.pipe(
            map((LedgerImports) =>
                LedgerImports.filter((li) => documentTypes.includes(li.DocumentType))
                    .map((b) => {
                        return {
                            ...b,
                            Sum: this.currencyPipe.transform(b.ConsolidatedAmount / (1 + Mehrwertsteuer), b.ConsolidatedCurrencyCode),
                            DueDate: this.datePipe.transform(b.DueDate),
                            Notes: b.AdditionalData?.notes,
                            suffixButton: b.DocumentId
                                ? {
                                      icon: DMSPageMeta.Icon,
                                      tooltip: 'Zum Dokument',
                                      hide: (args: LedgerImportEntity) => {
                                          return !args.DocumentId;
                                      },
                                      onClick: (args: LedgerImportEntity) => {
                                          this.DocumentClick.emit(args.DocumentId);
                                      },
                                  }
                                : null,
                            cssClass: b.GetBackgroundColorClass(),
                        };
                    })
                    .sort((a, b) => (a.CreatedAt < b.CreatedAt ? 1 : -1)),
            ),
        );
    private liInvoiceGroup = [LedgerImportDocumentTypes.Invoice, LedgerImportDocumentTypes.PartialInvoice, LedgerImportDocumentTypes.ClosingInvoice];

    protected ledgerImportListData = [
        {
            documentTypes: this.liInvoiceGroup,
            documentTypeLabel: LedgerImportDocumentTypeNames.get(this.liInvoiceGroup[0]),
        },
        ...Array.from(LedgerImportDocumentTypeNames, ([val, key]) => ({
            documentTypes: [val],
            documentTypeLabel: key,
        })).filter((v) => !this.liInvoiceGroup.includes(v.documentTypes[0])),
    ].map((v) => ({
        ...v,
        ListCardData$: this.getLedgerImportsByDocTypes$(...v.documentTypes),
    }));
    constructor(protected PS: PermissionService, private currencyPipe: CurrencyPipe, private datePipe: DatePipe) {}

    ArraySum(arr: Array<number>) {
        return arr.reduce((prev, curr) => (curr ? prev + curr : prev), 0);
    }
    GetRouteForLedgerImport = (ledgerImport: LedgerImportEntity) => ['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, ledgerImport.Id];

    protected readonly InvoiceEditorMeta = InvoiceEditorMeta;
    protected readonly LedgerImportDocumentTypeNumberLabel = LedgerImportDocumentTypeNumberLabel;
    protected readonly LedgerImportDocumentTypeNames = LedgerImportDocumentTypeNames;
}
