import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    MATERIAL_GROUP_KEY,
    MaterialGroupState, selectAllMaterialGroups,
    selectMaterialGroupEntities, selectMaterialGroupFetched, selectMaterialGroupLatestUpdatedAt,
} from '../reducers/material-group.reducers';

const selectState = createFeatureSelector<MaterialGroupState>(MATERIAL_GROUP_KEY);

export const getMaterialGroupDictionary = createSelector(selectState, selectMaterialGroupEntities);
export const getMaterialGroups = createSelector(selectState, selectAllMaterialGroups);
export const getMaterialGroupsFetched = createSelector(selectState, selectMaterialGroupFetched);
export const getMaterialGroupLatestUpdatedAt = createSelector(selectState, selectMaterialGroupLatestUpdatedAt);

export const getMaterialGroupById = (props: { id: number }) => createSelector(getMaterialGroupDictionary, (dictionary) => props?.id && dictionary[props.id]);
