<h1 mat-dialog-title>
    <fa-icon [icon]="(IsVacation$ | async) ? VacationMetaIcon : AbsentMetaIcon"></fa-icon> {{ Headline }}
    <button style="float: right; margin-left: 1rem" app-round-button matTooltip="Löschen" Color="red" *ngIf="showDeleteBtn$ | async" [Disabled]="!(canDelete$ | async)" (click)="Delete()">
        <fa-icon icon="trash"></fa-icon>
    </button>
</h1>

<mat-dialog-content>
    <I *ngIf="EntityEdited$ | async as edited"><fa-icon icon="pencil-alt"></fa-icon>{{ edited.date | date: 'short' }} {{ edited.name }}</I>
    <div style="margin-bottom: 1rem; margin-left: 1rem" *ngIf="IsVacation$ | async">
        <span [class.mat-error]="VacationDays.errors?.maxVacationDayCount"
            ><label style="font-size: 1.17rem; font-weight: bold">{{ VacationDayCount$ | async }}</label> Tage verfügbar</span
        >
        <br />
        <mat-error class="day-error" *ngIf="VacationDays.errors?.maxVacationDayCount">Achtung! Das Urlaubskonto droht ins Minus zu rutschen. Du kannst zwar weitermachen, aber es werden nun negative Urlaubstage verzeichnet.</mat-error>
    </div>
    <div style="display: flex; justify-content: center; flex-wrap: wrap">
        <app-detail-list-template *ngIf="Data; else loading" [Editing]="canEdit$ | async" [Data]="Data" [Inline]="true" style="max-width: 16rem;"> </app-detail-list-template>
        <div style="margin-left: 0.5rem; display: flex; flex-direction: column; align-items: center" *ngIf="IsVacation$ | async">
            <span>halb</span>
            <mat-checkbox color="primary" style="margin-top: 0.25rem" [disabled]="!(canEdit$ | async)" [formControl]="Form.controls.HalfFrom"></mat-checkbox>
            <mat-checkbox color="primary" style="margin-top: 2.75rem" [disabled]="!(canEdit$ | async)" [formControl]="Form.controls.HalfTo"></mat-checkbox>
        </div>
    </div>
    <ng-container *ngIf="FileUpload">
        <div class="file-list" *ngIf="(Vacation$ | async)?.AdditionalData?.fileIds as fileIds">
            <app-small-file-card *ngFor="let fileId of fileIds" (FileClick)="OpenFilePreview($event.Id)" (RemoveClick)="RemoveAssignedFile(fileId)" [RemoveButton]="canEdit$ | async" [FileId]="fileId"></app-small-file-card>
        </div>
        <dropzone #dorpzone *ngIf="DropzoneConfig && (canEdit$ | async)" [config]="DropzoneConfig" [message]="'Klicken oder Dateien hinein ziehen'" (error)="OnUploadError($event)" (success)="OnSuccess($event)" (uploadProgress)="onDropzoneUploadProgress($event)"> </dropzone>
    </ng-container>
    <div class="action-wrapper" *ngIf="DialogData.employeeToVacationId && (PS.Has(PS.Permission.ApproveVacation) | async)">
        <button app-button (click)="OpenVacationDialog(false)" Color="red" *ngIf="IsVacation$ | async" [Disabled]="DisableDeclineButton">Ablehnen</button>
        <button app-button style="margin-left: 1rem" (click)="OpenVacationDialog(true)" class="accept-button" Color="green" [Disabled]="DisableApproveButton">
            {{ (IsVacation$ | async) ? 'Genehmigen' : 'Gesehen' }}
        </button>
    </div>
    <div class="status-wrapper">
        <div style="overflow-x: visible">
            {{ (IsVacation$ | async) ? 'Akzeptiert' : 'Gesehen' }} von:
            <ng-container *ngFor="let user of AcceptedList"> {{ user }},</ng-container>
            <ng-container *ngIf="IsVacation$ | async">
                <br />
                Abgelehnt von:
                <ng-container *ngFor="let user of DeclinedList"> {{ user }},</ng-container>
            </ng-container>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button Color="cancel" Inverted app-button matDialogClose>Abbrechen</button>

    <button app-button (click)="Save()" [Disabled]="Form.invalid || !(canEdit$ | async)">
        {{ DialogData.employeeToVacationId ? 'Speichern' : 'Einreichen' }}
    </button>
</mat-dialog-actions>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
