import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoubleIconComponent } from './components/double-icon/double-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
    declarations: [DoubleIconComponent],
    exports: [
        DoubleIconComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule
    ]
})
export class DaveDoubleIconModule { }
