
<!--<ng-template-->
<!--    #commentCard-->
<!--    let-author="author"-->
<!--    let-isEditing$="isEditing$"-->
<!--    let-unSeen="unSeen"-->
<!--    let-editedAt="editedAt"-->
<!--    let-createdAt="createdAt"-->
<!--    let-commentText="commentText"-->

<!--    let-documentIds="documentIds"-->
<!--&gt;-->
    <mat-card class="comment-card" [class.its-me]="itsMe$ | async">
        <ng-template #btns>
            <div class="card-header-buttons">
                <button app-round-button *ngIf="(canEdit$ | async) && !(isEditing$ | async)"  matTooltip="Bearbeiten" (click)="onEditClick()"><fa-icon icon="pencil-alt"></fa-icon></button>
                <button app-round-button *ngIf="(canDelete$ | async) && (isEditing$ | async)" Inverted Color="red" (click)="DeleteComment()" matTooltip="Löschen"><fa-icon icon="trash"></fa-icon></button>
            </div>
        </ng-template>
        <mat-card-header>
            <ng-container [ngTemplateOutlet]="btns" *ngIf="!(BS.MobileQuery | async) || (isEditing$ | async)"></ng-container>
            <!--            <div mat-card-avatar><fa-icon icon="user" size="2x"></fa-icon></div>-->
            <mat-card-title style="font-size: 16px; line-height: 16px;">
                <span class="unseen" *ngIf="unSeen$ | async"></span>
                {{ author$ | async }}
            </mat-card-title>
            <mat-card-subtitle style="font-size: 12px; line-height: 16px;">{{ (comment$ | async).CreatedAt | date: 'short' }}<I class="ml1" *ngIf="editedAt$ | async as editedAt"><fa-icon icon="pencil-alt"></fa-icon>{{ editedAt | date: 'short' }}</I></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="content-wrapper">
                <ng-container *ngTemplateOutlet="(isEditing$ | async) ? editComment : notEditComment; "></ng-container>
            </div>
            <ng-container [ngTemplateOutlet]="btns" *ngIf="(BS.MobileQuery | async) && !(isEditing$ | async)"></ng-container>
        </mat-card-content>
    </mat-card>
<!--</ng-template>-->
<ng-template #editComment>
    <div class="editor-dz-wrapper">
        <app-ck-editor [CKEditorConfig]="CKEditorConfig" [(Content)]="EditorContent"></app-ck-editor>
        <!--        <ckeditor [(ngModel)]="EditorContent" [config]="CKEditorConfig" [editor]="Editor"></ckeditor>-->
        <ng-container [ngTemplateOutlet]="fileList" [ngTemplateOutletContext]="{ editing: true, canUpload: !!FileUploadParams }"></ng-container>
    </div>
    <mat-card-actions>
        <button Inverted app-button (click)="onAbortEditing()" [IsLoading]="LS.IsLoading$ | async" Color="cancel">Abbrechen</button>
        <button app-button (click)="SaveComment()" [IsLoading]="LS.IsLoading$ | async">Speichern</button>
    </mat-card-actions>
</ng-template>
<ng-template #notEditComment >
    <div [innerHTML]="(comment$ | async)?.Text" class="content-text"></div>
    <ng-container [ngTemplateOutlet]="fileList" [ngTemplateOutletContext]="{ editing: false, canUpload: !!FileUploadParams }"></ng-container>
</ng-template>
<ng-template #fileList let-editing="editing" >
    <div class="file-list-wrapper" *ngIf="((comment$ | async)?.DocumentIds | length) || editing">
        <p style="display: block; text-align: end; margin: 0;">
            <b>Anhänge </b>
            <fa-icon *ngIf="!editing" icon="paperclip"></fa-icon>
            <button mat-icon-button *ngIf="editing && !!FileUploadParams" matTooltip="Anhang hinzufügen" (click)="fileInput.click()">
                <app-double-icon Icon="paperclip" Icon2="plus"></app-double-icon>
            </button>
        </p>
        <input id="file" type="file" hidden multiple #fileInput (change)="onFileChange($event)" />
        <app-small-file-card style="display: inline-block" [RemoveButton]="editing" (RemoveClick)="onRemoveFileClick(id)" (FileClick)="onFileClick(id)" *ngFor="let id of (comment$ | async)?.DocumentIds" [FileId]="id"></app-small-file-card>
    </div>
</ng-template>

<!--<ng-template #dropzoneTemplate let-canUpload="canUpload">-->
<!--    <ng-container *ngIf="canUpload">-->
<!--        <div-->
<!--            *ngIf="dropzoneConfig$ | async as config"-->
<!--            class="dropzone dz-wrapper"-->
<!--            [class.hidden]="HideDropzone"-->
<!--            #dropzone="ngxDropzone"-->
<!--            [dropzone]="config"-->
<!--            (error)="dropzoneError($event)"-->
<!--            (success)="calcFileCount()"-->
<!--            (addedFile)="calcFileCount()"-->
<!--            (addedFiles)="calcFileCount()"-->
<!--            (removedFile)="calcFileCount()"-->
<!--        >-->
<!--            <div class="dz-message">-->
<!--                <div class="dz-text">{{ config.dictDefaultMessage }}</div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </ng-container>-->
<!--    &lt;!&ndash;   <dropzone-->
<!--        #dropzone-->
<!--        [config]="dropzoneConfig$ | async"-->
<!--        (error)="dropzoneError($event)"-->
<!--        (success)="dropzoneSuccess($event)"-->
<!--        (addedFile)="dropzoneAddedFile($event)"-->
<!--        (removedFile)="dropzoneRemovedFile($event)"-->
<!--        (queueComplete)="dropzoneQueueComplete($event)"-->
<!--    ></dropzone> &ndash;&gt;-->
<!--</ng-template>-->
