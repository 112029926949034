import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * Strukturelle Direktive, zu verwenden mit `ListLayoutWithRoutingComponent`
 *
 * - Erzeugt einen Tab in der mobilen Ansicht
 * - Inhalt des Tabs ist das Element, auf dem diese Direktive sitzt
 * - Label des Tabs ist der String, der an diese Direktive übergeben wird
 *
 * ```html
 * <app-list-layout-with-routing>
 *   <div *appListLayoutWithRoutingTab="'Label'">Inhalt</div>
 * </app-list-layout-with-routing>
 * ```
 */
@Directive({
    selector: '[appListLayoutWithRoutingTab]',
})
export class ListLayoutWithRoutingTabDirective {
    @Input('appListLayoutWithRoutingTab') Label: string;

    constructor(public Template: TemplateRef<void>) {}
}
