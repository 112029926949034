import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink,RouterLinkWithHref } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { GoToButtonComponent } from './go-to-commission/go-to-button.component';



@NgModule({
    declarations: [GoToButtonComponent],
    imports: [CommonModule, AppButtonModule, RouterLink, FontAwesomeModule, RouterLinkWithHref],
    exports: [GoToButtonComponent],
})
export class GoToButtonsModule {}
