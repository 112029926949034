import { createAction, props } from '@ngrx/store';
import { JobSpecificationEntity } from '../../entities/job-specification.entity';
import { DaveMutationChangeJobSpecificationArgs, DaveMutationCreateJobSpecificationArgs } from '../../graphql-types';

const prefix = 'data/' + 'job-specification/';
export const JobSpecificationActionTypes = {
    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<JobSpecificationEntity> }>()),
    UpdateOne: createAction(prefix + 'updateOne', props<{ Payload: JobSpecificationEntity }>()),

    LoadAll: createAction(prefix + 'loadAll'),
    Create: createAction(prefix + 'create', props<{ Payload: DaveMutationCreateJobSpecificationArgs }>()),
    Modify: createAction(prefix + 'modify', props<{ Payload: DaveMutationChangeJobSpecificationArgs }>()),
};
