import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { ChartOfAccountEntity, ChartOfAccountEntityFromBackend } from '../../entities/chart-of-Account.entity';
import { GraphQlEffectService } from '../../services/graph-ql-effect.service';
import { HttpService } from '../../services/http.service';
import { BaseActionTypes } from '../actions/base.actions';
import { ChartOfAccountActionTypes } from '../actions/chartOfAccount.actions';
import { State } from '../index';

enum ErrorCodes {
    Load = 'ChartOfAccount Abrufen fehlgeschlagen',
    Change = 'ChartOfAccount Bearbeiten fehlgeschlagen',
    Remove = 'ChartOfAccount Löschen fehlgeschlagen',
    Add = 'ChartOfAccount Hinzufügen fehlgeschlagen',
}

@Injectable()
export class ChartOfAccountsEffects {
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService, private graphQlEffectService: GraphQlEffectService) {}

    RemoveChartOfAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChartOfAccountActionTypes.Delete),
            concatMap(({ Payload }) => {
                const queryString = `
      mutation{
        deleteChartOfAccount(id: ${Payload.id}){${ChartOfAccountEntity.GqlFields.join(',')}}
      }`;
                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    map((res) => {
                        return res && res.deleteChartOfAccount && res.deleteChartOfAccount.id
                            ? ChartOfAccountActionTypes.DeleteSuccess({ Payload: res.deleteChartOfAccount.id })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    GetChartOfAccounts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChartOfAccountActionTypes.LoadAll),
            switchMap(({ Payload }) => {
                const queryString = `
            query {chartOfAccount${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''}{${ChartOfAccountEntity.GqlFields.join(', ')}}}`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                    map((res) => {
                        return res && res.chartOfAccount
                            ? Payload?.updatedSince
                                ? ChartOfAccountActionTypes.UpdateMany({
                                      Payload: res.chartOfAccount.map((val) => ChartOfAccountEntityFromBackend(val)),
                                      updateLatestUpdatedAt: true,
                                  })
                                : ChartOfAccountActionTypes.UpdateAll({
                                      Payload: res.chartOfAccount.map((val) => ChartOfAccountEntityFromBackend(val)),
                                      updateLatestUpdatedAt: true,
                                  })
                            : BaseActionTypes.ErrorAction({
                                  Payload: { ToasterMessage: ErrorCodes.Load },
                              });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    CreateCharOfAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChartOfAccountActionTypes.Add),
            withLatestFrom(this.store$),
            concatMap(([{ Payload }, store]) => {
                console.log(Payload.accountType)
                const queryString = ` mutation{
        createChartOfAccount(
         ${stringifyIfNotNullOrUndefined(Payload, 'name')}
         ${stringifyIfNotNullOrUndefined(Payload, 'number')}
         ${stringifyIfNotNullOrUndefined(Payload, 'description')}
        ${Payload.accountType ? `accountType: ${Payload.accountType}` : null}
        ){${ChartOfAccountEntity.GqlFields.join(', ')}}
      }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    map((res) => {
                        return res && res.createChartOfAccount && res.createChartOfAccount.id
                            ? ChartOfAccountActionTypes.AddSuccess({
                                  Payload: ChartOfAccountEntityFromBackend(res.createChartOfAccount),
                              })
                            : ChartOfAccountActionTypes.AddFailure({
                                  Payload: {
                                      Errors: 'wrong response',
                                      ToasterMessage: ErrorCodes.Add,
                                  },
                              });
                    }),
                    catchError((err, caught) =>
                        of(
                            ChartOfAccountActionTypes.AddFailure({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Errors: err,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    ModifyChartOfAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChartOfAccountActionTypes.ChangeChartOfAccount),
            withLatestFrom(this.store$),
            concatMap(([{ Payload }, store]) => {
                const queryString = `
            mutation {
                changeChartOfAccount(
                    id: ${Payload.id}
                  ${stringifyIfNotNullOrUndefined(Payload, 'name')}
                  ${stringifyIfNotNullOrUndefined(Payload, 'number')}
                  ${stringifyIfNotNullOrUndefined(Payload, 'description')}
                  ${Payload.accountType ? `accountType: ${Payload.accountType}` : Payload.accountType}
                ) {
                   ${ChartOfAccountEntity.GqlFields.join(', ')}
                }
            }`;

                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    map((res) => {
                        return res && res.changeChartOfAccount && res.changeChartOfAccount.id
                            ? ChartOfAccountActionTypes.ChangeSuccess({
                                  Payload: ChartOfAccountEntityFromBackend(res.changeChartOfAccount),
                              })
                            : ChartOfAccountActionTypes.ChangeFailure({
                                  Payload: {
                                      Errors: 'wrong response',
                                      ToasterMessage: ErrorCodes.Change,
                                  },
                              });
                    }),
                    catchError((err, caught) =>
                        of(
                            ChartOfAccountActionTypes.ChangeFailure({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Errors: err,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
}
