import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { UserToEventEntity } from '../../entities/user-to-event.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { UserToEventActionTypes } from '../actions/user-to-event.action';
export const USER_TO_EVENT_KEY = 'userToEvent';

export interface UserToEventState extends EntityState<UserToEventEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<UserToEventEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const userToEventReducer = createReducer(
    initialState,
    on(UserToEventActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(UserToEventActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, { ...state })),
    on(UserToEventActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(UserToEventActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllUserToEvent = selectAll;
export const selectUserToEventEntities = selectEntities;

export const selectUserToEventFetched = (state: UserToEventState) => state.fetched;
export const selectUserToEventLatestUpdatedAt = (state: UserToEventState) => state.latestUpdatedAt;
