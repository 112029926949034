// tslint:disable: naming-convention
import {Action} from '@ngrx/store';
import {MandateTypeEntity} from '../../entities/mandate-type.entity';

export enum MandateTypeActionTypes {
    GetMandateTypesRequest = '[MandateTypes] Get Request',
    GetMandateTypesResponse = '[MandateTypes] Get Response'
}

export class GetMandateTypesRequest implements Action {
    readonly type = MandateTypeActionTypes.GetMandateTypesRequest;
}

export class GetMandateTypesResponse implements Action {
    readonly type = MandateTypeActionTypes.GetMandateTypesResponse;
    constructor(public Payload: MandateTypeEntity[]) {}
}

export type MandateTypeActions = GetMandateTypesRequest | GetMandateTypesResponse;
