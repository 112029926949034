import {Injectable} from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}
export const PENDING_CHANGES_DEFAULT_MESSAGE = 'Sie haben ungespeicherte änderungen, klicken Sie auf ABBRECHEN und speichern Sie die Änderungen oder klicken Sie auf OK und verlieren Sie die Änderungen';
@Injectable({
    providedIn: 'root'
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        if (!component) {
            // fix for D277-2217
            return true;
        }
        const canDe = component.canDeactivate();
        if (typeof canDe === 'boolean') {
            return canDe || confirm(PENDING_CHANGES_DEFAULT_MESSAGE);
        } else if (canDe instanceof Observable) {
            return canDe.pipe(first(), map(v => v || confirm(PENDING_CHANGES_DEFAULT_MESSAGE)));
        }
    }
}
