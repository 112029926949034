import { createEntityAdapter,EntityState } from '@ngrx/entity';
import { createReducer,on } from '@ngrx/store';
import { ProcessEntity } from '../../entities/process.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { ProcessActions } from '../actions/process.actions';

export const PROCESS_FEATURE_KEY = 'process';

export interface ProcessState extends EntityState<ProcessEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<ProcessEntity>({
    selectId: Entity => Entity.Id,
});

const initialState: ProcessState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const processReducer = createReducer(
    initialState,
    on(ProcessActions.updateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, {
            ...state,
            fetched: true,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(ProcessActions.updateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(ProcessActions.updateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
    on(ProcessActions.removeOne, (state, { Payload }) => adapter.removeOne(Payload, state)),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllProcess = selectAll;
export const selectProcessEntities = selectEntities;

export const selectProcessFetched = (state: ProcessState) => state.fetched;
export const selectProcessLatestUpdatedAt = (state: ProcessState) => state.latestUpdatedAt;
