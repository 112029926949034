import { EventTypeType } from '../graphql-types';
import { GeneratedDocumentsTypeSlugs, GeneratedDocumentsTypeSlugsForDiary } from "./generatedDocumentsType.entity";
export enum EventTypeNamesEnum {
    Erschwernis = 'Erschwernis',
    Zusatzarbeit = 'Zusatzarbeit',
    BesonderesVorkommen = 'Besonderes Vorkommen',
    Leistungsaenderung = 'Leistungsänderung',
    Bedenkenanzeige = 'Bedenkenanzeige',
    Mangelanzeige = 'Mängelanzeige',
}
export enum EventTypeIdsEnum {
    Email = 1,
    OutgoingEmail = 4,
}
export class EventTypeEntity {
    constructor(public Id: number = null, public Name: EventTypeNamesEnum | string = null, public IsDefault: boolean = null, public ImagePath: string = null, public Markdown: string = null) {}
    get GeneratedDocumentsTypeSlugsForDiary(): GeneratedDocumentsTypeSlugsForDiary {
        switch (this.Name) {
            case EventTypeNamesEnum.Zusatzarbeit:
                return GeneratedDocumentsTypeSlugs.zusatzarbeit;
            case EventTypeNamesEnum.Mangelanzeige:
                return GeneratedDocumentsTypeSlugs.mangel;
            case EventTypeNamesEnum.Bedenkenanzeige:
                return GeneratedDocumentsTypeSlugs.bedenken;
            default:
                return null;
        }
    }
    public Clone(override: EventTypeEntityAttributes = {}): EventTypeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new EventTypeEntity(), attr);
    }
}

export function EventTypeEntityFromBackend(res: EventTypeType): EventTypeEntity {
    return new EventTypeEntity(res.id, res.name, res.isDefault, res.imagePath, res.markdown);
}

export interface EventTypeEntityAttributes {
    Id?: number;
    Name?: string;
    IsDefault?: boolean;
    ImagePath?: string;
    Markdown?: string;
}
