import { EntityRolesType, PermissionType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export enum EntityTypesEnum {
    Commission = 'COMMISSION',
    Customer = 'CUSTOMER',
    Event = 'EVENT',
    Process = 'PROCESS',
}
export enum EntityRoleSlugEnum {
    Vorarbeiter = 'Vorarbeiter',
    Monteur = 'Monteur',
    Admin = 'Admin',
    Projektleiter = 'Projektleiter',
    Sachbearbeiter = 'Sachbearbeiter',
    Prozessbearbeiter = 'Prozessbearbeiter',
    Prozessverantwortlicher = 'Prozessverantwortlicher',
}
export enum EntityRolePermissionNameEnum {
    GetStatusButtonCommission = 'GetStatusButtonCommission',
    GetAddEventButtonCommission = 'GetAddEventButtonCommission',
    GetSendEmailButtonCommission = 'GetSendEmailButtonCommission',
    GetCompleteBusinessVolume = 'GetCompleteBusinessVolume',
    GetCostsCompleteBusinessVolume = 'GetCostsCompleteBusinessVolume',
    GetVersicherungssumme = 'GetVersicherungssumme',
    GetPlanedWorkingHours = 'GetPlanedWorkingHours',
}
export interface EntityRoleEntityAdditionalData {}
export class EntityRoleEntity {
    constructor(
        public AdditionalData: EntityRoleEntityAdditionalData = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public Entity: EntityTypesEnum = null,
        public Id: number = null,
        public Level: number = null,
        public Name: EntityRoleSlugEnum | string = null,
        public PartnerId: number = null,
        public UpdatedAt: Date = null,
        public Permissions: Array<{name: EntityRolePermissionNameEnum}> = null,
    ) {}
    get DisplayName() {
        return this.Name;
    }
    public static readonly GqlFields = `additionalData
createdAt
deletedAt
entity
id
level
name
partnerId
updatedAt
permissions{name}`;
    public Clone(override: Partial<EntityRoleEntity> = {}): EntityRoleEntity {
        const attr = { ...this, ...override };
        return Object.assign(new EntityRoleEntity(), attr);
    }
}

export function EntityRoleEntityFromBackend(res: EntityRolesType): EntityRoleEntity {
    return new EntityRoleEntity(
        res.additionalData,
        res.createdAt ? BackendDate(res.createdAt) : null,
        res.deletedAt ? BackendDate(res.deletedAt) : null,
        res.entity,
        res.id,
        res.level,
        res.name,
        res.partnerId,
        res.updatedAt ? BackendDate(res.updatedAt) : null,
        res.permissions as any,
    );
}
