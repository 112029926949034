import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Address, getAddressString, getNavigationURL } from '../../../../../helper/helper';
import { AppButtonModule } from '../../../../app-button-module/app-button.module';
import { GoogleMapsService } from '../../../services/google-maps.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DaveLoadingModule } from '../../../../../dave-loading/dave-loading.module';

@Component({
    standalone: true,
    imports: [FontAwesomeModule, CommonModule, GoogleMapsModule, HttpClientModule, HttpClientJsonpModule, MatTooltipModule, AppButtonModule, MatDialogModule, DaveLoadingModule],
    selector: 'app-map-dialog',
    templateUrl: './map-dialog.component.html',
    styleUrls: ['./map-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapDialogComponent {
    public static DefaultConfig: MatDialogConfig = {
        autoFocus: false,
    };

    public Url = '';
    public Ready$: Observable<boolean>;
    public MapOptions$: BehaviorSubject<google.maps.MapOptions> = new BehaviorSubject<google.maps.MapOptions>(null);

    constructor(private mapsService: GoogleMapsService, @Inject(MAT_DIALOG_DATA) public Data: Address, cdr: ChangeDetectorRef) {
        this.Url = getNavigationURL(null, this.Data);
        this.Ready$ = mapsService.ApiLoaded$;
        if (Data.Latitude && Data.Longitude) {
            this.MapOptions$.next({ center: { lat: +Data.Latitude, lng: +Data.Longitude }, zoom: 12 });
        } else {
            this.mapsService.Geocode(getAddressString(Data)).subscribe(({ Latitude, Longitude }) => {
                this.MapOptions$.next({ center: { lat: Latitude, lng: Longitude }, zoom: 12 });
            });
        }
    }
}
