import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { GroupEntity } from '../../entities/group.entity';
import { GroupActionTypes, GroupMemberActionTypes } from '../actions/group.actions';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';

export const GROUP_KEY = 'group';

export interface GroupState extends EntityState<GroupEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;
}

const adapter = createEntityAdapter<GroupEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const groupsReducer = createReducer(
    initialState,
    on(GroupActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(GroupActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(GroupActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
    on(GroupActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, state)),

    on(GroupMemberActionTypes.AddOne, (state, { Payload }) => adapter.setOne(state.entities[Payload.GroupId].Clone({
        GroupMembers: [...state.entities[Payload.GroupId].GroupMembers, Payload],
    }), state)),
    on(GroupMemberActionTypes.RemoveOne, (state, { Payload }) => adapter.setOne(state.entities[Payload.groupId]?.Clone({
        GroupMembers: state.entities[Payload.groupId].GroupMembers.filter(m => m.Id !== Payload.id),
    }), state)),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllGroup = selectAll;
export const selectGroupEntities = selectEntities;

export const selectGroupFetched = (state: GroupState) => state.fetched;
export const selectGroupLatestUpdatedAt = (state: GroupState) => state.latestUpdatedAt;
