import { CommonModule } from '@angular/common';
import { Component, ViewChild } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogConfig,MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Store } from "@ngrx/store";
import { firstValueFrom } from "rxjs";
import { EventTypeResolver } from "../../../../dave-data-module/guards/event-type.resolver";
import { State } from "../../../../dave-data-module/State";
import { getEventTypeFetched,getEventTypes } from "../../../../dave-data-module/State/selectors/event-type.selector";
import { DaveLoadingModule } from "../../../../dave-loading/dave-loading.module";
import { AppButtonModule } from "../../../../dave-utils-module/app-button-module/app-button.module";
import { appMatDialogDefaultConfig } from "../../../../helper/helper";
import { EventTypesSelectorCardComponent } from "../event-types-selector-card.component";
import { EventTypeEntity } from "../../../../dave-data-module/entities/event-type.entity";

export type EventTypesSelectorCardDialogComponentReturnData = undefined | EventTypeEntity;
@Component({
    selector: 'app-event-types-selector-card-dialog',
    standalone: true,
    imports: [CommonModule, EventTypesSelectorCardComponent, MatDialogModule, AppButtonModule, FontAwesomeModule, MatButtonModule, MatTooltipModule, DaveLoadingModule],
    templateUrl: './event-types-selector-card-dialog.component.html',
    styleUrls: ['./event-types-selector-card-dialog.component.scss'],
})
export class EventTypesSelectorCardDialogComponent {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
    };
    @ViewChild('eventTypeSelector') eventTypeSelector: EventTypesSelectorCardComponent;
    protected eventTypes$ = this.store.select(getEventTypes);
    fetched$ = this.store.select(getEventTypeFetched);
    constructor(private store: Store<State>, resolver: EventTypeResolver) {
        firstValueFrom(this.fetched$).then((f) => {
            if (!f) {
                resolver.resolve();
            }
        });
    }
}
