<h1 mat-dialog-title>Wählen Sie eine Lizenz</h1>
<div mat-dialog-content>
    <div style="display: flex">
        <form>
            <mat-form-field>
                <mat-label>Produkt</mat-label>
                <mat-select [(ngModel)]="selectedProduct" name="license">
                    <mat-option *ngFor="let product of Data.productList" [value]="product">
                        {{product.Name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="selectedProduct != null">
                <mat-label>Lizenz</mat-label>
                <mat-select [(ngModel)]="selectedLicense" name="license2">
                    <mat-option *ngFor="let license of Data.licenseList | filter: 'Id' : selectedProduct?.Id.toString()"
                                [value]="license">
                        {{license.MaxUserTypeName}}
                        {{license.MaxUser != 0 ? 'Min: ' + license.MinUser + ' Benutzer, Max: ' + license.MaxUser + ' Benutzer' :
                        'Min: ' + license.MinUser + ' Benutzer'}}

                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="selectedProduct != null">
                <mat-label>Laufzeit</mat-label>
                <mat-select [(ngModel)]="selectedVarianteType" name="varianteType">
                    <mat-option
                        *ngFor="let varianteType of Data.varianteTypeList | filter: 'ProductId' : selectedProduct?.Id.toString()"
                        [value]="varianteType">
                        {{varianteType.TimePeriodInMonth}} Monat(e) - {{varianteType.DiscountInPercent}}%

                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <div class="table-wrapper">
            <table>
                <tr *ngIf="selectedProduct != null">
                    <td>Produkt:</td>
                    <td>{{selectedProduct.Name}}</td>
                </tr>
                <tr *ngIf="selectedLicense != null">
                    <td>Lizenz:</td>
                    <td>{{selectedLicense.MaxUserTypeName}}</td>
                </tr>
                <tr *ngIf="selectedVarianteType != null">
                    <td>Laufzeit:</td>
                    <td>{{selectedVarianteType.TimePeriodInMonth}} {{selectedVarianteType.TimePeriodInMonth === 1 ? 'Monat' : 'Monate'}}</td>
                </tr>
                <tr *ngIf="selectedLicense!= null && selectedLicense.MinUser != 0">
                    <td>Mindestanzahl Benutzer:</td>
                    <td>{{selectedLicense.MinUser}}</td>
                </tr>
                <tr *ngIf="selectedLicense!= null && selectedLicense.MaxUser != 0">
                    <td>Maximalanzahl Benutzer:</td>
                    <td>{{selectedLicense.MaxUser}}</td>
                </tr>
                <tr *ngIf="selectedLicense!= null && selectedLicense.PricePerUser != 0">
                    <td>Preis pro Benutzer:</td>
                    <td>{{selectedLicense.PricePerUser | currency: 'EUR'}}</td>
                </tr>
                <tr *ngIf="selectedLicense!= null && selectedLicense.BasePrice != 0">
                    <td>Festpreis:</td>
                    <td>{{selectedLicense.BasePrice | currency: 'EUR'}}</td>
                </tr>
                <tr *ngIf="selectedVarianteType!= null && selectedVarianteType.DiscountInPercent !== 0">
                    <td>Rabatt:</td>
                    <td>{{selectedVarianteType.DiscountInPercent}}%</td>
                </tr>
            </table>
        </div>
    </div>
</div>
<div mat-dialog-actions class="buttons">
    <button app-button mat-dialog-close class="button">Nein</button>

    <button
        app-button
        class="button"
        [mat-dialog-close]="{
            selectedLicense: selectedLicense,
            selectedVarianteType: selectedVarianteType
        }"
    >
        Ja
    </button>
</div>
