import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {Subscription} from 'rxjs';
import {MaterialColor} from '../textfield/textfield.component';
import { getErrorMessage } from '../../../../../helper/validation.helper';
import { BreakpointObserverService } from '../../../services/breakpoint-observer.service';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, OnDestroy, OnChanges {
    @Input() Key = '';
    @Input() Value: Date;
    @Input() Disabled = false;
    @Input() Required = false;
    @Input() Color: MaterialColor = 'primary';
    @Input() Max: Date;
    @Input() Min: Date;

    @Output() Input = new EventEmitter<MatDatepickerInputEvent<Date>>();

    private subscription: Subscription;

    public DateFormControl = new UntypedFormControl('');
    public Today = new Date();
    public Mobile = false;
    public GetErrorMessage = getErrorMessage;

    constructor(private breakpointService: BreakpointObserverService) {}

    ngOnInit() {
        this.subscription = this.breakpointService.MobileQuery.subscribe(
            value => (this.Mobile = value)
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.Value) {
            this.DateFormControl.setValue(this.Value);
        }

        if (changes.Disabled) {
            changes.Disabled.currentValue
                ? this.DateFormControl.disable()
                : this.DateFormControl.enable();
        }

        if (changes.Required) {
            changes.Required.currentValue
                ? this.DateFormControl.setValidators(Validators.required)
                : this.DateFormControl.clearValidators();
        }
    }

    public InputEvent(event) {
        // cast Moment to Date
        if (event.value) {
            event.value = this.DateFormControl.invalid
                ? null
                : event.value.toDate();
        }
        this.Input.emit(event);
    }
}
