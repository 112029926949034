import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { ProductEntityFromBackend } from '../../entities/product.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { ProductActionTypes } from '../actions/product.actions';
import { ResolverActionTypes } from '../actions/resolver.actions';
import { State } from '../index';

enum ErrorCodes {
    Load = 'Produkte Abrufen fehlgeschlagen',
}

@Injectable()
export class ProductEffects {
    GetProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResolverActionTypes.ResolverLoadProducts),
            withLatestFrom(this.store$),
            concatMap(([action, store]) => {
                const queryString = `
                query{
                    licenseProduct{
                        id
                        name
                    }
                }
            `;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                    map((res) => {
                        return res && res.licenseProduct
                            ? ProductActionTypes.GetProducts({
                                  Payload: res.licenseProduct.map((p) => ProductEntityFromBackend(p)),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
