<div class="wrapper">
    <div class="headline">
        <h2>Text hinzufügen</h2>
    </div>
    <h3 *ngIf="(SelectedTextTemplates$ | async).length !== 0">ausgewählte Texte:</h3>
    <mat-chip-list *ngIf="(SelectedTextTemplates$ | async).length !== 0">
        <mat-chip
                class="example-box"
                *ngFor="let textTemplates of (SelectedTextTemplates$ | async)">
            {{textTemplates.ShortDescription}}
            <button (click)="RemoveSelectedTextTemplates(textTemplates)">
                <fa-icon [icon]="'times-circle'"></fa-icon>
            </button>
        </mat-chip>
    </mat-chip-list>
    <h3>weitere Texte auswählen:</h3>
    <input
        type="text"
        class="search-field"
        placeholder="nach Texten suchen"
        #searchString
        (keyup)="DoSearch(searchString.value)"
    />

    <cdk-virtual-scroll-viewport
    [tvsItemSize]="56"
    [headerHeight]="56"
    style="height: 20rem; overflow-y: scroll"
    [bufferMultiplier]="2"
>
        <table mat-table matSort [dataSource]="DataSource$ | async" *ngIf="TextTemplatesColumns$ | async as TextTemplatesColumns">
            <ng-container *ngFor="let collumName of TextTemplatesAutoColumns" [matColumnDef]="collumName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    {{ TextTemplatesColumnHeaders ? TextTemplatesColumnHeaders[collumName] : '' }}
                </th>
                <td mat-cell *matCellDef="let rowData">
                    {{ rowData[collumName] }}
                </td>
            </ng-container>
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef style="padding: 0 1rem; width: 1rem">
                    <fa-icon icon="check-square"></fa-icon>
                </th>
                <td mat-cell *matCellDef="let rowData" style="padding: 0 1rem; width: 1rem">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="CheckBoxSelectTextTemplates($event, rowData)"
                        [checked]="rowData.selected$ | async"
                        color="primary"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="TextTemplatesColumns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let rowData; columns: TextTemplatesColumns"
                (click)="RowClickSelectTextTemplates(rowData);"
                [class.highlight]="SelectedRowIndex === rowData.Id"
            ></tr>
        </table>

        <table *ngIf="(DataSource$ | async)?.data?.length === 0" style="width: 100%">
            <tr>
                <td class="empty-table-text">
                    Keine Texte vorhanden
                </td>
            </tr>
        </table>
    </cdk-virtual-scroll-viewport>
    <div class="button-wrapper">
        <button app-button Inverted [mat-dialog-close]="null">Zurück</button>
        <button app-button (click)="Close()">Speichern</button>
    </div>
</div>
