import {registerLocaleData} from '@angular/common';
import localeGermany from '@angular/common/locales/de';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private locale: string;

    setLocale(value: string) {
        this.locale = value;
    }
    getLocale(): string {
        return this.locale || 'de-DE';
    }

    registerCulture(culture: string) {
        if (!culture) {
            return;
        }
        this.locale = culture;

        // Register locale data since only the en-US locale data comes with Angular
        switch (culture) {
            case 'de-DE': {
                registerLocaleData(localeGermany);
                break;
            }
        }
    }
}
