import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { EmployeeToVacationEntity, VacationTypeEnum } from '../../../dave-data-module/entities/employee-to-vacation.entity';
import { ProcessState } from '../../../dave-data-module/entities/process.entity';
import { State } from '../../../dave-data-module/State';
import { getNotByUserAcceptedEmployeeToVacationsByType, getOpenEmployeeToVacationsByType } from '../../../dave-data-module/State/selectors/employee-to-vacation.selectors';
import { getEmployeeDictionary } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getOpenTasksByUserId } from '../../../dave-data-module/State/selectors/events.selectors';
import { getProcessDictionary, getProcessFetched } from '../../../dave-data-module/State/selectors/process.selector';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { PermissionService } from './permission.service';

@Injectable({
    providedIn: 'root',
})
export class BookmarkService {
    constructor(private store: Store<State>, private PS: PermissionService) {}
    getVacations() {
        return combineLatest([this.PS.Has(this.PS.Permission.ApproveVacation), this.PS.Has(this.PS.Permission.ChangeVacation), this.PS.Has(this.PS.Permission.ApproveAbsent)]).pipe(
            switchMap((hasPermission) =>
                hasPermission.every((v) => !!v)
                    ? combineLatest([this.store.select(getOpenEmployeeToVacationsByType({ type: VacationTypeEnum.Vacation })), this.store.select(getEmployeeDictionary).pipe(filter(isNotNullOrUndefined)), this.store.select(getUser)]).pipe(
                          map(([e2vs, employees, user]) => e2vs.filter((e2v) => employees[e2v.EmployeeId]?.UserIds.includes(user?.Id))),
                      )
                    : of([] as EmployeeToVacationEntity[]),
            ),
        );
    }
    getAbsents() {
        return this.PS.Has(this.PS.Permission.CreateAbsent).pipe(
            switchMap((permission) =>
                permission
                    ? this.store.select(getUser).pipe(
                          switchMap((user) =>
                              combineLatest([
                                  this.store.select(getNotByUserAcceptedEmployeeToVacationsByType({ type: VacationTypeEnum.Absent, userId: user.Id })),
                                  this.store.select(getNotByUserAcceptedEmployeeToVacationsByType({ type: VacationTypeEnum.Illness, userId: user.Id })),
                                  this.store.select(getNotByUserAcceptedEmployeeToVacationsByType({ type: VacationTypeEnum.Illnesswithoutcontinuedpay, userId: user.Id })),
                              ]),
                          ),
                          map((v) => v.flat()),
                      )
                    : of([] as EmployeeToVacationEntity[]),
            ),
        );
    }
    Tasks$ = this.store.select(getUser).pipe(
        filter(isNotNullOrUndefined),
        switchMap((user) => this.store.select(getOpenTasksByUserId({ userId: user.Id }))),
        switchMap((tasks) =>
            this.store.select(getProcessFetched).pipe(
                filter((f) => f),
                switchMap(() => this.store.select(getProcessDictionary)),
                map((processes) =>
                    tasks.filter((t) => {
                        const process = processes[t.ProcessId];
                        if (process && (process.State === ProcessState.ProcessStateDone || process.State === ProcessState.ProcessStateArchived || process.State === ProcessState.ProcessStateCanceled)) {
                            return false;
                        }
                        const processStep = t.ProcessId && process?.Steps[processes[t.ProcessId].ActiveStep];
                        return !processStep || processStep.TaskId === t.Id || processStep.TaskIds.includes(t.Id);
                    }),
                ),
            ),
        ),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    UnseenTasksCount$ = this.Tasks$.pipe(map((tasks) => tasks.filter((t) => !t.LastSeenAt || t.LastSeenAt < t.UpdatedAt).length));
}
