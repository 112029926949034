import { DamageFlowTypeType } from '../graphql-types';

export class DamageFlowTypeEntity {
    constructor(
        public Id: number = null,
        public Name: string = null,
        public PartnerId: number = null,
    ) {
    }

    public Clone(override: DamageFlowTypeEntityAttributes = {}): DamageFlowTypeEntity {
        const attr = {...this, ...override};
        return Object.assign(new DamageFlowTypeEntity(), attr);
    }
}

export function DamageFlowTypeEntityFromBackend(res: DamageFlowTypeType): DamageFlowTypeEntity {
    return new DamageFlowTypeEntity(
        res.id,
        res.name,
        res.partnerId,
    );
}


export interface DamageFlowTypeEntityAttributes {
    Id?: number;
         Namme?: string;
         PartnerId?: number ;
        }
