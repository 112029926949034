import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
AfterViewInit,
ChangeDetectionStrategy,
Component,
EventEmitter,
Input,
OnDestroy,
Output,
TemplateRef,
ViewChild
} from "@angular/core";
import { FormControl, UntypedFormControl } from "@angular/forms";
import { MatAutocomplete,MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent,MatChipList } from '@angular/material/chips';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BehaviorSubject,combineLatest,Subscription } from "rxjs";
import { map, skip } from 'rxjs/operators';
import { FormControlTyped } from '../../../dave-utils-module/typings';

/**
 * Generische Implementation eines Eingabefeldes mit `MatChips` und einem `MatAutocomplete`.
 *
 * Inputs: `Placeholder`, `ClearOnUnknownOptionSubmit`, `OptionTemplate`, `Options`, `Disabled`,
 * `SelectedOptions`, `MapFn`
 *
 * Outputs: `SelectedOptionsChange`, `UnknownOptionSubmitted`
 *
 * Die Komponente funktioniert ohne gesetzte Inputs, empfehlenswert ist es aber, `Options` zu
 * übergeben. Wenn die Arrayelemente keine primitiven Datentypen sind, sollte auch `MapFn` gesetzt
 * werden. Wenn der return wert von MapFn nicht uniq ist muss `CompareFn` überschrieben werden.
 */
@Component({
    selector: 'app-chip-autocomplete',
    templateUrl: './chip-autocomplete.component.html',
    styleUrls: ['./chip-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipAutocompleteComponent<T = unknown> implements OnDestroy, AfterViewInit {
    protected _required = false
    @Input() set required(value: any) {
        this._required = coerceBooleanProperty(value);
    }
    /** Alle Elemente, die in der Autovervollständigung angezeigt werden können */
    @Input()
    public get Options() {
        return this.options$.value;
    }
    public set Options(options: ReadonlyArray<T>) {
        if (Array.isArray(options)) {
            this.options$.next(options);
        }
    }
    @Output()
    EntryClick = new EventEmitter<T>();
    /** Setzt die Komponente auf `disabled` und verwehrt Nutzereingaben */
    @Input()
    public get Disabled() {
        return this.IsDisabled$.value;
    }
    public set Disabled(isDisabled: boolean) {
        // Wenn `Disabled` ohne Property Binding im HTML verwendet wird, ist der Wert ''
        this.IsDisabled$.next(isDisabled || (isDisabled as unknown) === '');
    }
    /** Die vom Nutzer ausgewählten Elemente */
    @Input()
    public get SelectedOptions() {
        return this.selectedOptions$.value;
    }
    public set SelectedOptions(options: ReadonlyArray<T>) {
        if (Array.isArray(options)) {
            this.selectedOptions$.next(options);
        } else if (!options) {
            this.selectedOptions$.next([]);
        }
    }
    public ChipInputAddOnBlur = true;

    /** Platzhalter im HTML Eingabefeld */
    @Input() public Placeholder?: string;
    @Input() public PlaceholderIcon?: IconProp;
    @Input() public appearance?: MatFormFieldAppearance;

    /** Tooltip für das HTML Eingabefeld */
    @Input() public Tooltip?: string;

    /**
     * Ob das Eingabefeld geleert werden soll, nachdem der Nutzer einen unbekannten Suchstring
     * eingegeben und mit Enter bestätigt hat
     */
    @Input() public ClearOnUnknownOptionSubmit = true;

    /**
     * Bauplan (TemplateRef) für die Optionen des Autocompletes
     *
     * Wird für jedes anzuzeigende (vorgeschlagene) Element erzeugt. Bekommt das Element übergeben.
     *
     * @example
     * <app-chip-autocomplete [OptionTemplate]="template"></app-chip-autocomplete>
     *
     * <ng-template #template let-Option>
     *     <i>MapFn(Option)</i>
     * </ng-template>
     */
    @Input() public OptionTemplate?: TemplateRef<unknown>;

    /** Alle Elemente */
    private options$ = new BehaviorSubject<ReadonlyArray<T>>([]);

    /** Ob die Komponente `disabled` ist */
    public IsDisabled$ = new BehaviorSubject(false);

    /**
     * FormControl für das Eingabefeld
     *
     * `T`, da MatAutocomplete die `value` seiner `mat-option` in diese FormControl schreibt.
     */
    public InputForm = new FormControl<string | T>(null);

    /** Die vom Nutzer ausgewählten Elemente */
    protected selectedOptions$ = new BehaviorSubject<ReadonlyArray<T>>([]);

    private sub: Subscription
    constructor() {
        this.sub = this.selectedOptions$.pipe(skip(1)).subscribe(value => {
            this.SelectedOptionsChange.emit(value.slice())
        })
    }

    ngAfterViewInit(): void {
        if (this.ErrorMatcher.isErrorState()) {
            if (this.chiplist) {
                this.chiplist.updateErrorState();
            }
        }
    }

    /** Die vom Nutzer ausgewählten Elemente */
    @Output() public SelectedOptionsChange = new EventEmitter<T[]>();

    /** Gibt den Suchstring aus, wenn kein dazu passendes Element gefunden werden konnte */
    @Output() public UnknownOptionSubmitted = new EventEmitter<string>();

    @ViewChild('chipList') private chiplist: MatChipList;
    protected errorMessage = '';
    @Input()
    public get ErrorMessage() {
        return this.errorMessage;
    }
    public set ErrorMessage(errorMessage) {
        this.errorMessage = errorMessage;
        this.ErrorMatcher.ErrorMessage = this.errorMessage;

        if (this.chiplist) {
            this.chiplist.updateErrorState();
        }
    }
    /** Alle Elemente, die noch zur Auswahl stehen */
    public OptionsWithoutSelected$ = combineLatest([this.options$, this.selectedOptions$]).pipe(
        map(([options, selectedOptions]) => options.filter(o => !selectedOptions.some(so => this.CompareFn(so, o)))),
    );
    public ErrorMatcher = new ErrorMatcher(this.ErrorMessage, this.InputForm);

    /**
     * Mappingfunktion, die einem Arrayelement einen anzeigbaren String zuordnet
     *
     * **Objekte mit gleichen Strings werden wie gleiche Objekte behandelt.** Das kommt daher,
     * da es keine Duplikate in den Chips oder Autovervollständigungsvorschlägen geben soll.
     */
    @Input() public MapFn: (option: T) => string = option => `${option}`;

    @Input() CompareFn: (a: T, b: T) => boolean = (a, b) => this.MapFn(a) === this.MapFn(b);

    /**
     * Handlerfunktion für `matChipInputTokenEnd` Event
     *
     * Bei `autocomplete.options` wird gepflegt auf "interne Implementationsdetails" geschissen.
     * Wenn ihr mir keine gescheite API gebt, nehme ich mir eben eure. Selber schuld.
     */
    public Add(event: MatChipInputEvent, autocomplete: MatAutocomplete) {
        const value = event.value.trim();
        // MatAutocompleteSelectedEvents werden, wie dieses Event, via Enter ausgelöst
        if (!value || autocomplete.options.find(o => o.active)) {
            return;
        }

        const existingOption = this.Options.find(option => this.MapFn(option) === value);

        if (!existingOption) {
            this.UnknownOptionSubmitted.emit(value);

            if (this.ClearOnUnknownOptionSubmit) {
                this.resetInput(event.input);
            }
        } else if (
            // Wenn das Element noch nicht in den ausgewählten Elementen ist...
            !this.SelectedOptions.map(this.MapFn).includes(this.MapFn(existingOption))
        ) {
            this.SelectedOptions = [...this.SelectedOptions, existingOption];
            this.resetInput(event.input);
        }
    }

    /** Handlerfunktion für `mat-chip`'s `removed` Event */
    public Remove(option: T) {
        this.SelectedOptions = this.SelectedOptions.filter(opt => !this.CompareFn(opt, option));
    }

    /** Handlerfunktion für `mat-autocomplete`'s `optionSelected` Event */
    Select(event: MatAutocompleteSelectedEvent, input: HTMLInputElement) {
        this.SelectedOptions = [...this.SelectedOptions, event.option.value];
        this.resetInput(input);
    }

    /**
     * Hilfsfunktion. Da die MatChips & MatAutocomplete Komponenten nicht so gut mit Angular Forms
     * kooperieren, muss das Input-Element manuell zurückgesetzt werden.
     */
    private resetInput(input: HTMLInputElement) {
        input.value = '';
        this.InputForm.setValue(null);
    }
    ngOnDestroy(): void {
        this.sub?.unsubscribe()
    }
}

/** Error when ErrorMessage*/
class ErrorMatcher implements ErrorStateMatcher {
    constructor(errorMessage: string, private form: FormControl) {
        this.ErrorMessage = errorMessage;
    }
    public ErrorMessage: string;
    isErrorState(): boolean {
        return !!this.ErrorMessage && this.form.touched;
    }
}
