import { ActivityType } from '../graphql-types';
import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';

export class ActivityEntity {
    constructor(public Id: number = null, public DeletedAt: Date = null, public Name: string = null, public UpdatedAt: Date = null) {}
    public static readonly GqlFields: Array<keyof ActivityType> = ['id', 'deletedAt', 'name', 'updatedAt']
    public Clone(override: Partial<ActivityEntity> = {}): ActivityEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ActivityEntity(), attr);
    }
}
export function ActivityEntityFromBackend(res: ActivityType): ActivityEntity {
    return new ActivityEntity(res.id, res.deletedAt ? BackendDateTimestamp(res.deletedAt) : null, res.name, res.updatedAt && BackendDateTimestamp(res.updatedAt));
}
