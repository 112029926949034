import { Component, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { appMatDialogDefaultConfig } from '../../../../../helper/helper';
export interface DaveFileUploadDialogComponentDialogData {
    EventId?: number;
    CustomerId?: number;
    CommissionId?: number;
    TransmissionId?: number;
    FolderId?: number;
    ConstructionDiaryId?: number;
}
@Component({
    selector: 'app-dave-file-upload-dialog',
    templateUrl: './dave-file-upload-dialog.component.html',
    styleUrls: ['./dave-file-upload-dialog.component.scss'],
})
export class DaveFileUploadDialogComponent {
    public Uploading  = false ;
    public UploadFinished(val: boolean){
        this.Uploading = val;
    }
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        maxWidth: '100vw',
        hasBackdrop: false,
        panelClass: [...appMatDialogDefaultConfig.panelClass, 'custom-dialog-class-for-overlay-on-right-side'],
    };
    constructor(@Inject(MAT_DIALOG_DATA) 
    public Data: DaveFileUploadDialogComponentDialogData, 
    public DialogRef: MatDialogRef<DaveFileUploadDialogComponent>) {}
}
