import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { UserToCommissionEntity } from '../../entities/user-to-commission.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { UserToCommissionActionTypes } from '../actions/user-to-commission.action';

export interface UserToCommissionState extends EntityState<UserToCommissionEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
    commissionIdMap: Map<number, number[]>;
}

export const USER_TO_COMMISSION_KEY = 'userToCommission';

const adapter = createEntityAdapter<UserToCommissionEntity>({
    selectId: (Entity) => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
    commissionIdMap: new Map<number, number[]>(),
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const userToCommissionReducer = createReducer(
    initialState,
    on(UserToCommissionActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) => {
        let map = new Map<number, number[]>();

        Payload.forEach((p) => {
            map = addToCommissionIdMap(map, p.CommissionId, [p.Id]);
        });
        return adapter.setAll(Payload, {
            ...state,
            fetched: true,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
            commissionIdMap: map,
        });
    }),
    on(UserToCommissionActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) => {
        let map = removeFromCommissionIdMap(
            state.commissionIdMap,
            Payload.map((p) => p.Id),
        );

        Payload.forEach((p) => {
            map = addToCommissionIdMap(map, p.CommissionId, [p.Id]);
        });
        return adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
            commissionIdMap: map,
        });
    }),
    on(UserToCommissionActionTypes.SetByCommission, (state, { Payload, CommissionId }) => {
        const u2cIds = Object.values(state.entities)
            .filter((u2c) => u2c.CommissionId === CommissionId)
            .map((u2c) => u2c.Id);

        return adapter.setMany(Payload, {
            ...adapter.removeMany(u2cIds, { ...state, commissionIdMap: resetU2cToCommission(state.commissionIdMap, CommissionId, u2cIds) }),
        });
    }),
    on(UserToCommissionActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, { ...state, commissionIdMap: resetU2cToCommission(state.commissionIdMap, Payload.CommissionId, [Payload.Id]) })),
    on(UserToCommissionActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, {
        ...state,
        commissionIdMap: removeFromCommissionIdMap(state.commissionIdMap, [Payload])
    })),
    on(UserToCommissionActionTypes.RemoveByCommission, (state, { Payload }) => {
        const u2cIds = Object.values(state.entities)
            .filter((u2c) => u2c.CommissionId === Payload)
            .map((u2c) => u2c.Id);
        return adapter.removeMany(u2cIds, { ...state, commissionIdMap: removeFromCommissionIdMap(state.commissionIdMap, u2cIds) });
    }),
);
function resetU2cToCommission(map: Map<number, number[]>, targetCommissionId: number, u2cIds: number[]) {
    return addToCommissionIdMap(removeFromCommissionIdMap(map, u2cIds), targetCommissionId, u2cIds);
}
function removeFromCommissionIdMap(_map: Map<number, number[]>, u2cIds: number[]) {
    const map = new Map<number, number[]>(_map.entries());

    [...map.keys()].forEach((key) => {
        if (map.get(key).some((id) => u2cIds.includes(id))) {
            map.set(
                key,
                map.get(key).filter((id) => !u2cIds.includes(id)),
            );
        }
    });
    return map;
}
function addToCommissionIdMap(map: Map<number, number[]>, commissionId: number, u2cIds: number[]) {
    const cache = map.get(commissionId);
    if (cache) {
        cache.push(...u2cIds);
    } else {
        map.set(commissionId, [...u2cIds]);
    }
    return map;
}

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllUserToCommission = selectAll;
export const selectUserToCommissionEntities = selectEntities;

export const selectUserToCommissionFetched = (state: UserToCommissionState) => state.fetched;
export const selectUserToCommissionLatestUpdatedAt = (state: UserToCommissionState) => state.latestUpdatedAt;
export const selectUserToCommissionCommissionIdsMap = (state: UserToCommissionState) => state.commissionIdMap;
