import {CustomerTypeType} from '../graphql-types';

export class CustomerTypeEntity {
    public static readonly GqlFields = 'id name usedByCount';

    public Id: number;
    public Name: string;
    public UsedByCount: number;

    constructor(customerTypeType?: CustomerTypeType | null) {
        if (
            customerTypeType &&
            customerTypeType.id != null &&
            customerTypeType.name != null &&
            customerTypeType.usedByCount != null
        ) {
            this.Id = customerTypeType.id;
            this.Name = customerTypeType.name;
            this.UsedByCount = customerTypeType.usedByCount;
        } else {
            console.error('Received broken CustomerTypeType', customerTypeType);
            throw Error();
        }
    }
}
