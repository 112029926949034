import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    EmployeeSalaryTypeState,
    selectAllEmployeeSalaryType,
    selectEmployeeSalaryTypeEntities,
    selectEmployeeSalaryTypeFetched,
    EMPLOYEE_SALARY_TYPE_FEATURE_KEY,
} from '../reducers/employee-salary-type.reducer';

const selectState = createFeatureSelector<EmployeeSalaryTypeState>(EMPLOYEE_SALARY_TYPE_FEATURE_KEY);
export const getEmployeeSalaryTypeDictionary = createSelector(selectState, selectEmployeeSalaryTypeEntities);
// export const getEmployeeSalaryTypeLatestUpdatedAt = createSelector(selectState, selectEmployeeSalaryTypeLatestUpdatedAt);
export const getEmployeeSalaryTypeFetched = createSelector(selectState, selectEmployeeSalaryTypeFetched);
export const getEmployeeSalaryTypes = createSelector(selectState, selectAllEmployeeSalaryType);
export const getEmployeeSalaryTypesActive = createSelector(getEmployeeSalaryTypes, (employeeSalaryTypes) => employeeSalaryTypes.filter((employeeSalaryType) => !employeeSalaryType.DeletedAt));
export const getEmployeeSalaryTypeById = (props: { id: number }) => createSelector(getEmployeeSalaryTypeDictionary, (dictionary) => props.id && dictionary[props.id]);
