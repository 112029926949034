import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { EntityRoleEntity } from '../../entities/entity-role.entity';
import { EntityRoleActionTypes } from '../actions/entity-role.actions';

export interface EntityRoleState extends EntityState<EntityRoleEntity> {
    fetched: boolean;
}

export const ENTITY_ROLE_KEY = 'entityRole';

const adapter = createEntityAdapter<EntityRoleEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const entityRoleReducer = createReducer(
    initialState,
    on(EntityRoleActionTypes.UpdateAll, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllEntityRole = selectAll;
export const selectentityRoleEntities = selectEntities;

export const selectEntityRoleFetched = (state: EntityRoleState) => state.fetched;
