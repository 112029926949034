
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getToken } from '../selectors/base.selectors';
import { BaseActionTypes } from '../actions/base.actions';
import { EMPTY, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../index';
import { HttpService } from '../../services/http.service';
import { GraphQlEffectService } from '../../services/graph-ql-effect.service';
import { SurchargeRateActionTypes } from '../actions/surcharge-rate.actions';
import { SurchargeRateEntity, SurchargeRateEntityFromBackend } from '../../entities/surcharge-rate.entity';
import { stringifyIfNotUndefined } from '../../../helper/helper';
import { FileEntity, FileEntityFromBackend } from '../../entities/file.entity';


enum ErrorCodes {
    Load = 'Standard Zuschläge Abrufen fehlgeschlagen',
    Modify = 'Standard Zuschläge Bearbeiten fehlgeschlagen',
    Remove = 'Standard Zuschläge Löschen fehlgeschlagen',
    Add = 'Standard Zuschläge Hinzufügen fehlgeschlagen',
}

@Injectable()
export class SurchargeRateEffects {
    errors$ = createEffect(() => this.actions$.pipe(
        ofType(SurchargeRateActionTypes.loadFailure, SurchargeRateActionTypes.putFailure),
        map((action) => BaseActionTypes.ErrorAction({
            Payload: {
                ToasterMessage: action.ToasterMessage,
                Err: action.Errors,
            }
        }))
    ))

    GetSurchargeRate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SurchargeRateActionTypes.loadRequest),
                withLatestFrom(this.store$.select(getToken)),
                switchMap(([{ Payload }, token]) => {
                    const queryString = `query {
                    surchargeRate{
                        ${SurchargeRateEntity.GqlFields.join(',')}
                    }
                }`;
                    return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                        tap((res) => {
                            this.store$.dispatch(
                                res && res.surchargeRate
                                    ?
                                    SurchargeRateActionTypes.loadSuccess({
                                        Payload: res.surchargeRate.map((val) => SurchargeRateEntityFromBackend(val)),
                                    })
                                    : SurchargeRateActionTypes.loadFailure(
                                        { ToasterMessage: ErrorCodes.Load,
                                            Errors: 'wrong response surchargeRate'},
                                    ),
                            );
                        }),
                        catchError((err, caught) => {
                            this.store$.dispatch(
                                SurchargeRateActionTypes.loadFailure({
                                    ToasterMessage: ErrorCodes.Load,
                                    Errors: err,
                                }),
                            );
                            return EMPTY;
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    PutsurchargeRate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SurchargeRateActionTypes.putRequest),
            withLatestFrom(this.store$.select(getToken)),
            concatMap(([{ Payload }, token]) => {
                const queryString = `
mutation{
  putSurchargeRate(
    ${Payload.name !== null ? `name: ${Payload.name}` : ''}
    ${stringifyIfNotUndefined(Payload, 'percentageValue')}
  ){
  ${SurchargeRateEntity.GqlFields.join(',')
                }}
}
`
                return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                    map((res) => {
                       if (res?.putSurchargeRate) {
                           return SurchargeRateActionTypes.putSuccess({Payload: SurchargeRateEntityFromBackend(res.putSurchargeRate)});
                       } else {
                           return SurchargeRateActionTypes.putFailure({
                               ToasterMessage: ErrorCodes.Load,
                               Errors: 'wrong response putSurchargeRate',
                           });
                       }
                    }),
                    catchError((err, caught) => {
                        return of(SurchargeRateActionTypes.putFailure({
                            ToasterMessage: ErrorCodes.Load,
                            Errors: err,
                        }));
                    }),
                );
            }),
        ),
    );

    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService, private graphQlEffectService: GraphQlEffectService) {}
}
