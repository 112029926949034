import { CustomFieldsModalTemplate } from '../../custom-form-fields/custom-form-fields.helper';
import { GeneratedDocumentsTypeType } from '../graphql-types';
export interface GeneratedDocumentsTypeEntityAdditionalData {
    CustomFieldsModalTemplates?: CustomFieldsModalTemplate[];
    NameTemplate?: string;
}
export type GeneratedDocumentsTypeSlugsForDiary =
    GeneratedDocumentsTypeSlugs.zusatzarbeit |
    GeneratedDocumentsTypeSlugs.constructionDiary |
    GeneratedDocumentsTypeSlugs.mangel |
    GeneratedDocumentsTypeSlugs.bedenken;
export enum GeneratedDocumentsTypeSlugs {
    kagetecInspection = 'kagetec_inspection',
    zusatzarbeit = 'zusatzarbeit', // ist ales der Bautagebuch bericht
    constructionDiary = 'construction_diary', // ist ales der Bautagebuch bericht
    mangel = 'mangel', // ist ales der Bautagebuch bericht
    bedenken = 'bedenken', // ist ales der Bautagebuch bericht
}
export class GeneratedDocumentsTypeEntity {
    public static readonly GQLFields: Array<keyof GeneratedDocumentsTypeType> = ['id', 'name', 'slug', 'partnerId', 'partnerTypeId', 'additionalData']; // markdown
    constructor(
        public Id: number = null,
        public Name: string = null,
        public PartnerId: number = null,
        public PartnerTypeId: number = null,
        public Markdown: string = null,
        public Slug: GeneratedDocumentsTypeSlugs = null,
        public AdditionalData: GeneratedDocumentsTypeEntityAdditionalData = null,
    ) {}
    get IsForDiary(): boolean {
        return this.Slug === GeneratedDocumentsTypeSlugs.constructionDiary
        || this.Slug === GeneratedDocumentsTypeSlugs.bedenken
        || this.Slug === GeneratedDocumentsTypeSlugs.mangel
        || this.Slug === GeneratedDocumentsTypeSlugs.zusatzarbeit
    }

    public Clone(override: Partial<GeneratedDocumentsTypeEntity> = {}): GeneratedDocumentsTypeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new GeneratedDocumentsTypeEntity(), attr);
    }
}

export function GeneratedDocumentsTypeEntityFromBackend(res: GeneratedDocumentsTypeType): GeneratedDocumentsTypeEntity {
    return new GeneratedDocumentsTypeEntity(res.id, res.name, res.partnerId, res.partnerTypeId, res.markdown, res.slug as GeneratedDocumentsTypeSlugs, res.additionalData as any);
}
