import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { State } from '../../../dave-data-module/State';
import { AccountsReceivableLedgerTypeEnum } from "../../../dave-data-module/entities/accounts-receivable-ledger.entity";
import { FormControlCache } from "./formcontrol-cache";

export type calculationArlFormGroup = FormGroup<calculationArlForm>;
export type calculationArlForm = {
    costAmountWithoutTax: FormControl<number>;
    CostAmount: FormControl<number>;
    Quantity: FormControl<number>;
    QuantityTypeId: FormControl<number>;
    Information: FormControl<string>;
    BookingText: FormControl<string>;
    Type: FormControl<AccountsReceivableLedgerTypeEnum>;
    ResourceId: FormControl<number>;
    JobSpecificationId: FormControl<number>;
    IsVisible: FormControl<boolean>;
};
@Injectable()
export class CalculationArlFormDataService extends FormControlCache<calculationArlFormGroup> {

}
