import { createAction, props } from '@ngrx/store';
import { DaveMutationCreateSharedCommissionsArgs, DaveMutationDeleteSharedCommissionsArgs } from '../../graphql-types';
import { SharedCommissionsEntity } from '../../entities/sharedCommissions.entity';


export const SharedCommissionsActionTypes = {
    UpdateSharedCommissions: createAction(
        'data/SharedCommissions/update',
        props<{ Payload: Array<SharedCommissionsEntity> }>(),
    ),
    GetSharedCommissionsRequest: createAction(
        'data/SharedCommissions/getAll',
    ),
    AddSharedCommissions: createAction(
        'data/SharedCommissions/add',
        props<{ Payload: DaveMutationCreateSharedCommissionsArgs[] }>(),
    ),
    DeleteSharedCommissions: createAction(
        'data/SharedCommissions/delete',
        props<{ Payload: DaveMutationDeleteSharedCommissionsArgs[] }>(),
    ),
};

