import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { SearchResultEntity, SearchResultEntityFromBackend, SearchTypeEnum } from '../dave-data-module/entities/search-result.entity';
import { HttpService } from '../dave-data-module/services/http.service';
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';

const ErrorCode = 'Suche fehlgeschlagen';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    constructor(private store: Store<State>, private gatewayHttpService: HttpService) {}
    private static getSearchQuery(searchString: string, showOnly?: SearchTypeEnum[]) {
        return `
        query {
            search(query: "${searchString}", showOnly: ${showOnly ? JSON.stringify(showOnly) : '[]'}) {
                available_types
                found
                total_results
            }
        }`;
    }
    public Search(searchString: string, showOnly?: SearchTypeEnum[]): Observable<SearchResultEntity> {
        return this.gatewayHttpService.graphQl({ query: SearchService.getSearchQuery(searchString, showOnly) }).pipe(
            map(res => {
                if (res && res.search) {
                    return SearchResultEntityFromBackend(res.search);
                } else {
                    this.store.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCode,
                            },
                        }),
                    );
                }
            }),
            take(1),
        );
    }
}
