import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { isNotNullOrUndefined } from '../../helper/helper';
import { UploadFileArgs } from '../entities/file.entity';
import { VersionEntity } from '../entities/version.entity';
import { DaveMutation, Query } from '../graphql-types';
import { retryWithBackoff } from '../helper/http-service.helper';
import { State } from '../State';
import { getToken } from '../State/selectors/base.selectors';
import { ErrorPopupService } from '../../error-popup/error-popup.service';
import { PublicVersionEntity } from '../entities/public-version.entity';

/**
 * Dieser Service benötigt Konfigurationen, daher sollte eine Factory genutzt werden
 * der provider könnte zum Beispiel so außsehen:
 * {  provide: HttpService,
 *    useFactory: (httpClient: HttpClient) =>
 *      httpServiceFactory(httpClient, HTTP_SERVICE_CONFIG),
 *    deps: [HttpClient]
 * },
 */

export function DownloadVersions(versions: VersionEntity[], token?: string) {
    let timer = 0;
    for (const v of versions) {
        setTimeout(() => {
            const link = document.createElement('a');
            link.download = '';
            link.href = HttpService.getUrl(v.GetDownloadLink(token || localStorage.getItem('token')), 'file');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }, timer);
        timer += 2500;
    }
}

export function DownloadZip(fileIds: number[], folderIds: number[]) {
    const url = new URL(HttpService.getUrl(`/files/download/zip`, 'file'));
    url.searchParams.set('token', localStorage.getItem('token'));
    fileIds?.length && url.searchParams.set('file_ids', fileIds.join(','));
    folderIds?.length && url.searchParams.set('folder_ids', folderIds.join(','));
    const link = document.createElement('a');
    link.download = '';
    link.href = url.toString();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private http: HttpClient, private store$: Store<State>, private errorPopup: ErrorPopupService) {}

    public static getUrl(url: string, service: string = 'gateway', id: number = -1) {
        if (url && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        if (service && service.charAt(0) !== '/') {
            service = '/' + service;
        }

        url = environment.gatewayApiUrl + service + url;

        //fix for local gateway
        // url = url.replace('/gateway', '');

        if (id > -1) {
            if (url.indexOf('?') === -1) {
                url += '?Id=' + id;
            } else {
                url += '&Id=' + id;
            }
        }
        return url;
    }

    public static extractData(body: any) {
        if (body && body.data) {
            return body.data as Query & DaveMutation;
        }
        return null;
    }

    public GetUrl(url: string, service?: string, id: number = -1) {
        return HttpService.getUrl(url, service, id);
    }

    public graphQlWithProgress(query: { query: string; variables?: object }, options?: { token?: string, retry?: boolean}, service: string = 'gateway', customUrl?: string): Observable<HttpEvent<Object>> {
        return this._graphQl(query, options, service, customUrl, true) as Observable<HttpEvent<Object>>;
    }
    public graphQl(query: { query: string; variables?: object }, options?: { token?: string, retry?: boolean}, service: string = 'gateway', customUrl?: string) {
        return this._graphQl(query, options, service, customUrl).pipe(
            map(body => HttpService.extractData(body)),
        );
    }
    private _graphQl(query: { query: string; variables?: object }, options?: { token?: string, retry?: boolean}, service: string = 'gateway', customUrl?: string, withProgress = false) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        const token = options?.token || localStorage.getItem('token');
        if (token) {
            headers = headers.set('Authorization', 'Bearer ' + token);
        }

        const bodyData = JSON.stringify(query);

        const req = withProgress ? this.graphQlPostWithProgressEvents(customUrl || this.GetUrl('query', service), bodyData, headers) : this.graphQlPost(customUrl || this.GetUrl('query', service), bodyData, headers)

        if (options?.retry) {
            return req.pipe(
                retryWithBackoff(),
                catchError((err, caught) => {
                    console.error({ err, bodyData });
                    this.errorPopup.OpenErrorPopup();
                    return of(null);
                }),
            );
        } else {
            return req.pipe(
                catchError((err, caught) => {
                    console.error({ err, bodyData });
                    return of(null);
                }),
            );}
    }
    private graphQlPost(url: string, bodyData: string, headers: HttpHeaders) {
        return this.http.post(url, bodyData, {
            headers,
        });
    }
    private graphQlPostWithProgressEvents(url: string, bodyData: string, headers: HttpHeaders) {
        return this.http.post(url, bodyData, {
            headers,

            reportProgress: true,
            observe: 'events',
        });
    }
    public download(v: VersionEntity | PublicVersionEntity, options?: { token?: string }, service: string = 'file'): Observable<Blob> {
        let headers = new HttpHeaders();
        if (options && options.token) {
            headers = headers.set('Authorization', 'Bearer ' + options.token);
        } else {
            headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
        }
        return this.http.get(this.GetUrl((v instanceof PublicVersionEntity) ? v.DownloadPath : v.GetDownloadLink(options?.token || localStorage.getItem('token')), service), {
            headers,
            responseType: 'blob',
        });
    }

    /**
     * Lädt eine Bilddatei hoch.
     *
     * @throws Gibt die Fehler des HttpClients ungefiltert durch.
     */
    public UploadFile(payload: UploadFileArgs) {
        const uploadPath = 'files';
        const formData = new FormData();

        formData.append('files', payload.file, payload.name);
        payload.documentId && formData.append('documentId', payload.documentId + '');
        payload.description && formData.append('description', payload.description);
        payload.name && formData.append('name', payload.name);
        payload.customerIds && formData.append('customerIds', JSON.stringify(payload.customerIds));
        payload.commissionIds && formData.append('commissionIds', JSON.stringify(payload.commissionIds));
        payload.transmissionIds && formData.append('transmissionIds', JSON.stringify(payload.transmissionIds));
        payload.tagIds && formData.append('tagIds', JSON.stringify(payload.tagIds));
        payload.hidden && formData.append('hidden', JSON.stringify(payload.hidden));
        payload.eventIds && formData.append('eventIds', JSON.stringify(payload.eventIds));

        return this.store$.select(getToken).pipe(
            filter(isNotNullOrUndefined),
            take(1),
            switchMap((token) =>
                this.http
                    .post(this.GetUrl(uploadPath, 'gateway'), formData, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .pipe(map((res) => res as { data?: any })),
            ),
        );
    }
}
