<div class="mat-elevation-z8">
    <table mat-table class="full-width-table" aria-label="Elements" matSort multiTemplateDataRows>
        <!-- Id Column -->
        <ng-container matColumnDef="TemplateType" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorlagentyp</th>
            <td mat-cell *matCellDef="let row" class="text-cell">
                <ng-container *ngIf="isExpanded(row.Id); else value">
                    <mat-form-field>
                        <mat-label>Vorlagentyp</mat-label>
                        <input type="text" matInput [formControl]="row.form.controls.TemplateTypeName" [matAutocomplete]="auto" />
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option
                                *ngFor="let option of arlCalculationDataService.arlTemplateTypes$ | async"
                                [value]="option.Name"
                            >
                                {{ option.Name }}
                                <button
                                    style="height: 1.5rem; width: 1.5rem; margin-top: 0.5rem; float: right"
                                    app-round-button
                                    matTooltipPosition="left"
                                    Color="red"
                                    (click)="deleteArlTemplateType(option)"
                                    matTooltip="Vorlage löschen"
                                >
                                    <fa-icon icon="trash" size="xs"></fa-icon>
                                </button>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Währung</mat-label>
                        <mat-select [formControl]="row.form.controls.CurrencyCode" required>
                            <mat-option *ngFor="let currency of currencies" [value]="currency.code">
                                {{currency.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
                <ng-template #value>{{ row.TemplateTypeName }}</ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="Information">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Bezeichnung</th>
            <td mat-cell *matCellDef="let row" class="text-cell">
                <div *ngIf="isExpanded(row.Id); else value" class="cell-wrapper">
                    <mat-form-field>
                        <mat-label>Bezeichnung</mat-label>
                        <input matInput type="text" [formControl]="row.form.controls.Information" />
                    </mat-form-field>
                    <div class="long-text-wrapper">
                        <mat-form-field>
                            <mat-label>Langtext</mat-label>
                            <textarea matInput  cdkTextareaAutosize
                                      [cdkAutosizeMinRows]="3" [formControl]="row.form.controls.Longtext"></textarea>
                        </mat-form-field>
                        <mat-checkbox [formControl]="row.form.controls.ShowLongtext" matTooltip="Langtext anzeigen"></mat-checkbox>
                    </div>
                </div>
                <ng-template #value>{{ row.Information }}</ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="BookingText">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Art-Nr.</th>
            <td mat-cell *matCellDef="let row" class="text-cell">
                <ng-container *ngIf="isExpanded(row.Id); else value">
                    <mat-form-field>
                        <mat-label>Art-Nr.</mat-label>
                        <input matInput type="text" [formControl]="row.form.controls.BookingText" />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Steuern</mat-label>
                        <input matInput type="number" [formControl]="row.form.controls.Tax" />
                        <span matSuffix>%</span>
                    </mat-form-field>
                </ng-container>
                <ng-template #value>{{ row.BookingText }}</ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="Quantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="small">Menge</th>
            <td mat-cell *matCellDef="let row" class="text-cell">
                <mat-form-field *ngIf="isExpanded(row.Id); else value">
                    <mat-label>Menge</mat-label>
                    <input matInput type="number" [formControl]="row.form.controls.Quantity" />
                </mat-form-field>
                <ng-template #value>{{ row.Quantity }}</ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="BaseCost">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="small">Preis</th>
            <td mat-cell *matCellDef="let row" class="text-cell">
                <mat-form-field *ngIf="!row.form.value.InheritFromChildren && isExpanded(row.Id); else value">
                    <mat-label>Preis</mat-label>
                    <input matInput type="number" [formControl]="row.form.controls.BaseCost" />
                </mat-form-field>
                <ng-template #value>{{ row.form.value.BaseCost | currency: row.CurrencyCode }}</ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="SumNetto">
            <th mat-header-cell *matHeaderCellDef class="small">Summe</th>
            <td mat-cell *matCellDef="let row" class="text-cell">
                {{ row.sumNetto$ | async }}
            </td>
        </ng-container>
        <ng-container matColumnDef="QuantityType" class="small">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Einheit</th>
            <td mat-cell *matCellDef="let row" class="text-cell">
                <mat-form-field *ngIf="isExpanded(row.Id); else value">
                    <mat-select [placeholder]="'Einheit'" [formControl]="row.form.controls.QuantityTypeId">
                        <mat-option *ngFor="let t of arlCalculationDataService.quantityTypes$ | async" [value]="t.Id">{{ t.Name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-template #value>
                    {{ arlCalculationDataService.getQuantityTypeNameById$(row.form.value.QuantityTypeId) | async }}
                </ng-template>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Hint">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="x-small"></th>
            <td mat-cell *matCellDef="let row">
                <fa-icon *ngIf="row.Hint" icon="info-circle" [matTooltip]="row.Hint" style="margin: 0.5rem" size="lg"> </fa-icon>
                <fa-icon *ngIf="row.dontEditHint" icon="question-circle" [matTooltip]="row.dontEditHint" style="margin: 0.5rem" size="lg"> </fa-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="InheritFromChildren">
            <th mat-header-cell class="x-small" *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <span class="inherit-from-children-button" *ngIf="row.form.value.InheritFromChildren">
                    <strong>K</strong>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="IsVisible">
            <th mat-header-cell class="x-small" *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button
                    mat-icon-button
                    (click)="row.form.controls.childrenVisible.setValue(!row.form.value.childrenVisible); row.form.controls.childrenVisible.markAsDirty()"
                    *ngIf="isExpanded(row.Id); else icon"
                    [matTooltip]="'unterpositionen ' + (row.form.value.childrenVisible ? 'ausblenden' : 'einblenden')"
                    [disabled]="row.form.controls.childrenVisible.disabled"
                >
                    <fa-icon [icon]="row.form.value.childrenVisible ? 'eye' : 'eye-slash'"></fa-icon>
                </button>
                <ng-template #icon>
                    <fa-icon *ngIf="row.form.controls.childrenVisible.enabled && !row.form.value.childrenVisible" [icon]="row.form.value.childrenVisible ? 'eye' : 'eye-slash'"></fa-icon>
                </ng-template>
            </td>
        </ng-container>
        <!-- Checkbox Column -->
        <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef class="x-small">
                <mat-checkbox
                    (change)="selectAll($event)"
                    [checked]="isAllSelected() |async"
                    [disabled]="disableIfAllSelected()|async"
                    ></mat-checkbox></th>
            <td mat-cell *matCellDef="let row"><mat-checkbox [checked]="isDisabled(row.Id) || isSelected(row.Id)" [disabled]="isDisabled(row.Id)" (change)="selectionChange($event, row.Id)"></mat-checkbox></td>
        </ng-container>
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef class="x-small"></th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button aria-label="expand row" (click)="expandRow(row.Id); $event.stopPropagation()">
                    <fa-icon [icon]="isExpanded(row.Id) ? 'chevron-up' : 'chevron-down'"></fa-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
<!--                <ng-container *ngIf="isExpanded(row.Id)">-->
                <div
                    class="expandable"
                    [class.collapsed]="!isExpanded(row.Id)">
                    <app-arl-template-table-detail-row
                        [position]="''"
                        [arl]="row.entity$ | async"
                        (SaveClick)="onSaveClick(row)"
                        (AbortClick)="onAbortClick(row)"
                        (DeleteClick)="onDeleteClick(row)"
                        (DuplicateClick)="onDuplicateClick(row)"
                        [ArlFormDirty]="row.form.dirty"
                        [ArlFormInvalid]="row.form.invalid"
                        [inheritFromChildrenForm]="row.form.controls.InheritFromChildren"
                        [childrenVisibleForm]="row.form.controls.childrenVisible"
                        [ZuschlaegeResourcen]="row.form.value.ZuschlaegeResourcen"
                        [ZuschlaegeDefault]="row.form.value.ZuschlaegeDefault"
                        (calculatedAmount)="onAmountCalculated(row, $event)"
                    ></app-arl-template-table-detail-row>
                </div>
<!--                </ng-container>-->
            </td>
        </ng-container>
        <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef="let row" [attr.colspan]="displayedColumns.length">
                <mat-paginator #paginator [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" aria-label="Select page"> </mat-paginator>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator'], sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="data-row" [class.expanded-data-row]="isExpanded(row.Id)"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns?.length">
                <span>Keine Abrechnungspositionsvorlagen vorhanden</span>
            </td>
        </tr>
    </table>

<!--    <mat-paginator #paginator [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" aria-label="Select page"> </mat-paginator>-->
</div>
