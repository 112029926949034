import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { QMStatusEntity } from '../entities/qMStatus.entity';
import { State } from '../State';
import { getQMStatus } from '../State/selectors/qMStatus.selectors';
import { QMStatusActionTypes } from '../State/actions/qMStatus.actions';

@Injectable({
  providedIn: 'root'
})
export class QMStatusResolver implements Resolve<QMStatusEntity[]> {
  constructor(private store: Store<State>) {
  }

  resolve() {
    const value$ = this.store.select(getQMStatus);

    value$
      .pipe(
        filter(value => !value),
        take(1),
        tap(() => {
          if (localStorage.getItem('token') != null) {
            this.store.dispatch(QMStatusActionTypes.GetQMStatusRequest());
          }
        })
      )
      .subscribe();

    return value$.pipe(
      filter(value => !!value),
      take(1)
    );
  }
}
