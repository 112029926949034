import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../../helper/helper';
import { ResourceScheduleEntity, ResourceScheduleEntityFromBackend } from '../../../entities/resource-dispo/resource-schedule.entity';
import { HttpService } from '../../../services/http.service';
import { DaveActions } from '../../actions/actions';
import { BaseActionTypes } from '../../actions/base.actions';
import { ResourceScheduleActionTypes } from '../../actions/resource-dispo/resource-schedule.actions';
import { State } from '../../index';

enum ErrorCodes {
    Add = 'ResourceSchedule Hinzufügen fehlgeschlagen',
    Load = 'ResourceSchedule Abrufen fehlgeschlagen',
    Modify = 'ResourceSchedule Bearbeiten fehlgeschlagen',
    Remove = 'ResourceSchedule Löschen fehlgeschlagen',
}

@Injectable()
export class ResourceScheduleEffects {
    ModifyResourceSchedule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResourceScheduleActionTypes.Change),
            concatMap(({ Payload }) => {
                const queryString = `
      mutation{
        changeResourceSchedule(id: ${Payload.id},
        ${stringifyIfNotNullOrUndefined(Payload, 'amount')}
        ${stringifyIfNotNullOrUndefined(Payload, 'resourceId')}
        ${Payload.type ? `type: ${Payload.type}` : ''}
        ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'startDate')}
        ${stringifyIfNotNullOrUndefined(Payload, 'endDate')}
          ){${ResourceScheduleEntity.GqlFields.join(',')}}
      }`;
                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    map((res) =>
                        res && res.changeResourceSchedule && res.changeResourceSchedule.id
                            ? ResourceScheduleActionTypes.UpdateMany({
                                  Payload: [ResourceScheduleEntityFromBackend(res.changeResourceSchedule)],
                              })
                            : BaseActionTypes.ErrorAction({
                                  Payload: { ToasterMessage: ErrorCodes.Modify },
                              }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Modify,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

 RemoveResourceSchedule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResourceScheduleActionTypes.Delete),
            concatMap(({ Payload }) => {
                const queryString = `
      mutation{
        deleteResourceSchedule(id: ${Payload}){${ResourceScheduleEntity.GqlFields.join(',')}}
      }`;
                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    map((res) =>
                        res && res.deleteResourceSchedule && res.deleteResourceSchedule.id
                            ? ResourceScheduleActionTypes.RemoveOne({
                                  Payload: res.deleteResourceSchedule.id,
                              })
                            : BaseActionTypes.ErrorAction({
                                  Payload: { ToasterMessage: ErrorCodes.Remove },
                              }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    AddResourceSchedule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResourceScheduleActionTypes.Create),
            concatMap(({ Payload }) => {
                const queryString = `
      mutation{
        createResourceSchedule(
        ${stringifyIfNotNullOrUndefined(Payload, 'amount')}
        ${stringifyIfNotNullOrUndefined(Payload, 'resourceId')}
        ${Payload.type ? `type: ${Payload.type}` : ''}
        ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'startDate')}
        ${stringifyIfNotNullOrUndefined(Payload, 'endDate')}
          ){${ResourceScheduleEntity.GqlFields.join(',')}}
      }`;

                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    map((res) => {
                        return res && res.createResourceSchedule
                            ? ResourceScheduleActionTypes.UpdateMany({
                                  Payload: [ResourceScheduleEntityFromBackend(res.createResourceSchedule)],
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    LoadResourceSchedules = createEffect(() =>
        this.actions$.pipe(
            ofType(ResourceScheduleActionTypes.Load),
            switchMap(({ Payload }) => {
                const queryString = `
query{
    resourceSchedule${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''}{${ResourceScheduleEntity.GqlFields.join(',')}}
      }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                    map((res) =>
                        res && res.resourceSchedule
                            ? Payload?.updatedSince
                                ? ResourceScheduleActionTypes.UpdateMany({
                                      Payload: res.resourceSchedule.map((val) => ResourceScheduleEntityFromBackend(val)),
                                      updateLatestUpdatedAt: true,
                                  })
                                : ResourceScheduleActionTypes.UpdateAll({
                                      Payload: res.resourceSchedule.map((val) => ResourceScheduleEntityFromBackend(val)),
                                      updateLatestUpdatedAt: true,
                                  })
                            : BaseActionTypes.ErrorAction({
                                  Payload: { ToasterMessage: ErrorCodes.Load },
                              }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
