import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ShowImageComponentDialogData {
    Url: string;
    Titel?: string;
    ShowCloseButton: boolean;
}

@Component({
    selector: 'app-show-image',
    templateUrl: 'show-image.component.html',
    styleUrls: ['show-image.component.scss'],
})
export class ShowImageComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: ShowImageComponentDialogData,
    ) {
    }
}
