import { Injectable } from '@angular/core';

import { DaveMutationChangeSalaryTypeArgs,DaveMutationCreateSalaryTypeArgs,DaveMutationDeleteSalaryTypeArgs,QuerySalaryTypeArgs,SalaryTypeType } from '../../graphql-types';
import { GraphQlEffectService } from '../../services/graph-ql-effect.service';
import { SalaryTypeEntity, SalaryTypeEntityFromBackend } from "../../entities/salary-type.entity";
import { SalaryTypeActions } from "../actions/salary-type.actions";

enum ErrorCodes {
    Load = 'Lohnart Abrufen fehlgeschlagen',
    Modify = 'Lohnart Bearbeiten fehlgeschlagen',
    Remove = 'Lohnart Löschen fehlgeschlagen',
    Add = 'Lohnart Hinzufügen fehlgeschlagen',
}

@Injectable()
export class SalaryTypeEffects {
    CreateSalaryType$ = this.graphQlEffectService.createAddEffect<DaveMutationCreateSalaryTypeArgs, SalaryTypeEntity, SalaryTypeType>(
        'createSalaryType',
        SalaryTypeEntity.GqlFields.join(','),
        SalaryTypeActions.add,
        SalaryTypeActions.updateOne,
        SalaryTypeEntityFromBackend,
        ErrorCodes.Add,
        ['name', 'salaryTypeNumber'],
        [
            'amount',
            'bearbeitungsschluessel',
            'description',
            'percentAmountFromSalary',
        ]
    );
    ModifySalaryType$ = this.graphQlEffectService.createModifyEffect<DaveMutationChangeSalaryTypeArgs, SalaryTypeEntity, SalaryTypeType>(
        'changeSalaryType',
        SalaryTypeEntity.GqlFields.join(','),
        SalaryTypeActions.change,
        SalaryTypeActions.updateOne,
        SalaryTypeEntityFromBackend,
        ErrorCodes.Modify,
        ['id'],
        [
            'amount',
            'bearbeitungsschluessel',
            'description',
            'name',
            'percentAmountFromSalary',
            'salaryTypeNumber',
        ],
    );
    RemoveSalaryType$ = this.graphQlEffectService.createDeleteOneEffect<DaveMutationDeleteSalaryTypeArgs, SalaryTypeEntity, SalaryTypeType>(
        'deleteSalaryType',
        SalaryTypeEntity.GqlFields.join(','),
        SalaryTypeActions.delete,
        SalaryTypeActions.removeOne,
        (v) => v.id,
        ErrorCodes.Remove,
        ['id'],
    );
    GetSalaryType = this.graphQlEffectService.createGetAllEffect<QuerySalaryTypeArgs, SalaryTypeEntity, SalaryTypeType>(
        'salaryType',
        SalaryTypeEntity.GqlFields.join(','),
        SalaryTypeActions.load,
        SalaryTypeActions.updateAll,
        (a) => a.map(SalaryTypeEntityFromBackend),
        ErrorCodes.Load,
    );

    constructor(private graphQlEffectService: GraphQlEffectService) {}
}
