import { createAction, props } from '@ngrx/store';
import { EmailSettingsTypeEntity } from '../../entities/emailSettingsType.entity';


export const EmailSettingsTypeActionTypes = {
    UpdateEmailSettingsType: createAction(
        'data/EmailSettingsType/update',
        props<{ Payload: Array<EmailSettingsTypeEntity> }>(),
    ),
    GetEmailSettingsTypeRequest: createAction(
        'data/EmailSettingsType/getAll',
    ),
};

