import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { isNotNullOrUndefined, stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { TagEntity } from '../../entities/tag.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { ResolverActionTypes } from '../actions/resolver.actions';
import { TagActionTypes } from '../actions/tag.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { getTags } from '../selectors/tag.selectors';

enum ErrorCodes {
    Add = 'Tag Hinzufügen fehlgeschlagen',
    Load = 'Tag Abrufen fehlgeschlagen',
    Delete = 'Tag Löschen fehlgeschlagen',
}

@Injectable()
export class TagEffects {
    LoadTags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResolverActionTypes.LoadTags),
            withLatestFrom(this.store$),
            switchMap(([action, store]) => {
                const queryString = `
            query {
                tag {
                    ${TagEntity.GqlFields}
                }
            }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                    map((res) =>
                        res && res.tag
                            ? TagActionTypes.UpdateTags({
                                  Payload: res.tag.map((val) => new TagEntity(val)),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    Post$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagActionTypes.AddTag),
            map(
                ({ Payload }) => `
            mutation {
                createTag(${stringifyIfNotNullOrUndefined(Payload, 'name')}) {
                    ${TagEntity.GqlFields}
                }
            }`,
            ),
            withLatestFrom(this.store$.select(getToken)),
            concatMap(([query, token]) =>
                this.gatewayHttpService.graphQl({ query }, { token }).pipe(
                    map((res) => res && res.createTag),
                    concatMap((res) =>
                        res
                            ? this.store$.select(getTags).pipe(
                                  filter(isNotNullOrUndefined),
                                  take(1),
                                  map((tags) =>
                                      TagActionTypes.UpdateTags({
                                          Payload: [...tags, new TagEntity(res)],
                                      }),
                                  ),
                              )
                            : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } })],
                    ),
                    catchError((err, caught) =>
                        // Besonderes Error Handling: Trotz Fehler einen neuen Wert für Tags in den
                        // Store geben, damit TagChipAutocomplete das Eingabefeld wieder aktiviert
                        this.store$.select(getTags).pipe(
                            filter(isNotNullOrUndefined),
                            take(1),
                            concatMap((tags) => [
                                TagActionTypes.UpdateTags({
                                    Payload: [...tags],
                                }),
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Add,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            ]),
                        ),
                    ),
                ),
            ),
        ),
    );

    Delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagActionTypes.DeleteTag),
            withLatestFrom(this.store$.select(getToken)),
            concatMap(
                ([
                    {
                        Payload: { id },
                    },
                    token,
                ]) =>
                    this.gatewayHttpService
                        .graphQl(
                            {
                                query: `
                        mutation {
                            deleteTag(id: ${JSON.stringify(id)})
                        }`,
                            },
                            { token },
                        )
                        .pipe(
                            map((res) => res && res.deleteTag),
                            concatMap((res) =>
                                res
                                    ? this.store$.select(getTags).pipe(
                                          filter(isNotNullOrUndefined),
                                          take(1),
                                          map((tags) =>
                                              TagActionTypes.UpdateTags({
                                                  Payload: tags.filter((t) => t.Id !== id),
                                              }),
                                          ),
                                      )
                                    : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Delete } })],
                            ),
                            catchError(
                                (err, caught) => (
                                    this.store$.dispatch(
                                        BaseActionTypes.ErrorAction({
                                            Payload: {
                                                ToasterMessage: ErrorCodes.Delete,
                                                Err: err,
                                                Caught: caught,
                                            },
                                        }),
                                    ),
                                    caught
                                ),
                            ),
                        ),
            ),
        ),
    );

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
