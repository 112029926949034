<h1 matDialogTitle>Ereignisart auswählen</h1>
<mat-dialog-content>
    <app-event-types-selector-card #eventTypeSelector [EventTypeList]="eventTypes$ | async" *ngIf="fetched$ | async else loading" [Inline]="true"></app-event-types-selector-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button app-button Color="green" [matDialogClose]="eventTypeSelector?.CurrentEventType" [Disabled]="!eventTypeSelector?.CurrentEventType">speichern</button>
</mat-dialog-actions>
<button mat-icon-button [matDialogClose]="undefined" class="close-mat-dialog-button" matTooltip="Schließen">
    <fa-icon icon="times" size="2x"></fa-icon>
</button>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
