import { AsyncPipe, NgForOf, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, startWith} from 'rxjs/operators';
import { DashboardHelperService } from '../../services/dashboard-helper.service';
import {getUser} from "../../dave-data-module/State/selectors/users.selectors";
import {
    BreakpointObserverService
} from "../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service";
import {Store} from "@ngrx/store";
import {State} from "../../dave-data-module/State";
import {getEmployees} from "../../dave-data-module/State/selectors/employees.selectors";
import { isNotNullOrUndefined } from '../../helper/helper';

@Component({
    standalone: true,
    selector: 'app-side-nav-bar',
    templateUrl: './side-nav-bar.component.html',
    styleUrls: ['./side-nav-bar.component.scss'],
    imports: [AsyncPipe, NgForOf, RouterModule, MatListModule, NgIf, FontAwesomeModule, MatTooltipModule, NgStyle],
})
export class SideNavBarComponent implements OnDestroy {
    @Input() public Expanded = false;
    @Input() public ShowIncBar = false;
    @Input() public HomeButton = false;
    @Input() public TopDivider = false;

    private routerUrl$: Observable<string> = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects + ''),
        startWith(this.router.url),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public NavigationListEntries$ = combineLatest([
        this.dashboardHelperService.GetDashboardItems$(),
        this.store.select(getUser).pipe(filter(isNotNullOrUndefined)),
        this.store.select(getEmployees),
        this.bs.MobileQuery,
    ]).pipe(
        map(([items, user, employees, isMobile]) => {
            const employee = employees.find(e => e.UserId == user.Id);
            if (isMobile && employee) {
                items.forEach((c, i) => {
                    if (c.Route === 'personal_und_urlaub') {
                        items[i].Route = ['personal_und_urlaub', employee.Id]
                    }
                })
            }
            return items.map((item) => ({
                ...item,
                isActive$: this.routerUrl$.pipe(
                    map((url) => `/${item.Route}` === url || url.includes(`/${item.Route}/`)),
                    distinctUntilChanged(),
                ),
            }));
        }),
    );
    private routeSub: Subscription = this.routerUrl$.subscribe();
    constructor(private dashboardHelperService: DashboardHelperService, private router: Router, protected bs: BreakpointObserverService, protected store: Store<State>) {}

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }
}
