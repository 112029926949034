import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EventTypeEntity } from '../../entities/event-type.entity';
import { PersonEntity } from '../../entities/person.entity';
import {
    eventsFeatureKey,
    EventsState,
    selectAllEvent,
    selectEventEntities,
    selectEventFetched,
    selectEventLatestUpdatedAt,
} from '../reducers/events.reducer';
import { getEventTypes } from './event-type.selector';
import { getPersons } from './person.selectors';
import { selectFormData } from './selectors';
import { EventStateEnum } from '../../entities/event.entity';
import { Moment } from "moment";

const selectState = createFeatureSelector< EventsState>(eventsFeatureKey);
export const getEventDictionary = createSelector(selectState, selectEventEntities);

export const getAllEvents = createSelector(selectState, selectAllEvent);
export const getEventFetched = createSelector(selectState, selectEventFetched);
export const getTasksFetched = getEventFetched;
export const getEventLatestUpdatedAt = createSelector(selectState, selectEventLatestUpdatedAt);

export const getEventById = (props: { id: number }) => createSelector(getEventDictionary, (dictionary) => props?.id && dictionary[props.id]);

export const getTaskById = (props: { id: number }) => createSelector(getEventDictionary, (dictionary) => props?.id && dictionary[props.id]);

export const getEvents = createSelector(getAllEvents, data => data?.filter(e => !e.IsTask));
export const getTasks = createSelector(getAllEvents, data => data?.filter(e => e.IsTask && !e.AdditionalData?.IsDailyToDo));
export const getDailyToDos = createSelector(getAllEvents, data => data?.filter(e => e.IsTask && !!e.AdditionalData?.IsDailyToDo));

export const getEventsWithCommission = createSelector(getEvents, (events: ReturnType<typeof getEvents>, CommissionId: number) =>
    events ? events.find(e => e.CommissionId === CommissionId) : null,
);

const getSorted = (props: { sortBy: 'createdAt', direction?: 'desc' | 'asc' }) => createSelector(getEvents, (entities) => {
    switch (props.sortBy) {
        case 'createdAt': {
            if (props.direction === 'desc') {
                return entities.sort((a, b) => (a.CreatedAt < b.CreatedAt ? 1 : -1));
            } else {
                return entities.sort((a, b) => (a.CreatedAt > b.CreatedAt ? 1 : -1));
            }
        }
        default: {
            return entities;
        }
    }
});
export const getEventsSortedByCreatedAt = getSorted({sortBy: 'createdAt', direction: 'desc'})

export const getTasksByUserId = (props: { userId: number }) => createSelector(getTasks, (tasks) => tasks.filter(t => t.UserIds.includes(props.userId)));
export const getOpenTasksByUserId = (props: { userId: number }) => createSelector(getTasksByUserId(props), (tasks) => tasks.filter(t => t.State !== EventStateEnum.Done));
export const getOpenUnseenTasksByUserId = (props: { userId: number }) => createSelector(getOpenTasksByUserId(props), (tasks) => tasks.filter(t => !t.LastSeenAt || t.LastSeenAt < t.UpdatedAt));



/* #################### */
/* spezielle Selektoren */
/* #################### */

/**
 * NgRx Selektor für `NewEventComponent` und `DetailEventComponent`.
 *
 * Gibt die Werte der Event-Eingabefelder zurück -
 * siehe [DHISTORY-267](http://jira.omnia5.local/browse/DHISTORY-267).
 */
export const getEventFormData = createSelector(getPersons, getEventTypes, selectFormData, (contactPersons, eventTypes, formData): {
    Person: PersonEntity;
    EventType: EventTypeEntity;
    EventDate: Moment;
    EventEndDate: Moment;
    CustomerId: number;
    PartnerId: number;
    Hint: string;
    Name: string;
    Street: string;
    City: string;
    Country: string;
    PostalCode: string;
    Author: string;
    EndTime: string;
    Note: string;
    StartTime: string;
    Editing: boolean;
    Private: boolean;
    CommissionId: number;
} => {
    const ContactPerson = formData.PersonId ? contactPersons.find(contactPerson => contactPerson.Id === formData.PersonId) : null;

    const EventType = formData.EventTypeId ? eventTypes.find(event => event.Id === formData.EventTypeId) : null;

    return {
        Person: ContactPerson,
        EventType,
        CustomerId: formData.CustomerId,
        Hint: formData.Hint,
        Author: formData.Author,
        Street: formData.Street,
        City: formData.City,
        Country: formData.Country,
        PostalCode: formData.PostalCode,
        PartnerId: formData.PartnerId,
        EventDate: formData.Date,
        EventEndDate: formData.EndDate,
        EndTime: formData.EndTime,
        Note: formData.Note,
        StartTime: formData.StartTime,
        Editing: formData.Editing,
        Private: formData.Private,
        CommissionId: formData.CommissionId,
        Name: formData.Name,
    };
});
