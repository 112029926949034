import { Injectable } from '@angular/core';
import { isNotNullOrUndefined, JSONReplacer, JSONReviver } from '../helper/helper';
import { BehaviorSubject, Observable } from 'rxjs';
import { QuickNavigationFoldersData } from "../dave-file-explorer/components/select-folder-dialog/select-folder-dialog.component";

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    constructor() {}
    get token(): string | null {
        return localStorage.getItem('token');
    }
    set token(v: string | null) {
        if (v) {
            localStorage.setItem('token', v);
        } else {
            localStorage.removeItem('token');
        }
    }
    get shiftPlanCollapsed(): boolean | null {
        const item = localStorage.getItem('shiftPlanCollapsed');
        return isNotNullOrUndefined(item) ? JSON.parse(item) : null;
    }
    set shiftPlanCollapsed(v: boolean | null) {
        if (v) {
            localStorage.setItem('shiftPlanCollapsed', JSON.stringify(v));
        } else {
            localStorage.removeItem('shiftPlanCollapsed');
        }
    }
    get resourcePlanCollapsed(): boolean | null {
        const item = localStorage.getItem('shiftPlanCollapsed');
        return isNotNullOrUndefined(item) ? JSON.parse(item) : null;
    }
    set resourcePlanCollapsed(v: boolean | null) {
        if (v) {
            localStorage.setItem('shiftPlanCollapsed', JSON.stringify(v));
        } else {
            localStorage.removeItem('shiftPlanCollapsed');
        }
    }
}
