import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Creator, Store } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotUndefined } from '../../helper/helper';
import { DaveMutation, Query } from '../graphql-types';
import { State } from '../State';
import { BaseActionTypes } from '../State/actions/base.actions';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class GraphQlEffectService {
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService) {}

    // todo support für updatedSince einbauen
    createSingleEffect<Request, Entity, GraphQlType>(
        queryType: 'query' | 'mutation',
        mutationName: keyof Query | keyof DaveMutation,
        GqlResponseFields: string,
        triggerAction: ActionCreator<string, Creator<any[], { Payload: Request }>>,
        successAction: ActionCreator<string, (props: { Payload: Entity | number | string }) => { Payload: Entity | number | string } & TypedAction<string>>,
        castToEntity: (entity: GraphQlType) => Entity | number | string,
        errorMessage: string,
        keys: Array<keyof Request> = [],
        keysNullable: Array<keyof Request> = [],
        customParseToMutationValue: Map<keyof Request, (value: Request) => string> = new Map(),
    ) {
        return createEffect(() =>
            this.actions$.pipe(
                ofType(triggerAction),
                withLatestFrom(this.store$),
                concatMap(([{ Payload }, store]) => {
                    let queryString = `
                ${queryType} {
                    ${mutationName}`;
                    if (keys.length + keysNullable.length > 0) {
                        queryString = queryString + '(\n';
                        keys.forEach((key) => {
                            queryString = queryString + `${String(key)}:  ${customParseToMutationValue.has(key) ? customParseToMutationValue.get(key)(Payload) : JSON.stringify(Payload[key])}` + '\n';
                        });
                        keysNullable.forEach((key) => {
                          if (Payload[key] !== undefined) {
                            queryString = queryString + `${String(key)}:  ${customParseToMutationValue.has(key) ? customParseToMutationValue.get(key)(Payload) : JSON.stringify(Payload[key])}` + '\n';
                          }
                        });
                        queryString = queryString + '\n)';
                    }
                    queryString =
                        queryString +
                        ` {
                    ${GqlResponseFields}
                }
            }`;

                    return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                        map((res) => {
                            return res && res[mutationName]
                                ? successAction({
                                      Payload: castToEntity(res[mutationName] as GraphQlType),
                                  })
                                : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: errorMessage } });
                        }),
                        catchError((err, caught) =>
                            of(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: errorMessage,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            ),
                        ),
                    );
                }),
            ),
        );
    }

    createMutationEffect = <Request, Entity, GraphQlType>(
        mutationName: keyof DaveMutation,
        GqlResponseFields: string,
        triggerAction: ActionCreator<string, Creator<any[], { Payload: Request }>>,
        successAction: ActionCreator<string, (props: { Payload: Entity }) => { Payload: Entity } & TypedAction<string>>,
        castToEntity: (entity: GraphQlType) => Entity,
        errorMessage: string,
        keys: Array<keyof Request> = [],
        keysNullable: Array<keyof Request> = [],
        customParseToMutationValue: Map<keyof Request, (value: Request) => string> = new Map(),
    ) => this.createSingleEffect<Request, Entity, GraphQlType>('mutation', mutationName, GqlResponseFields, triggerAction, successAction, castToEntity, errorMessage, keys, keysNullable, customParseToMutationValue);
    createMutationDeleteEffect = <Request, Entity, GraphQlType>(
        mutationName: keyof DaveMutation,
        GqlResponseFields: string,
        triggerAction: ActionCreator<string, Creator<any[], { Payload: Request }>>,
        successAction: ActionCreator<string, (props: { Payload: number | string }) => { Payload: number | string } & TypedAction<string>>,
        castToKey: (entity: GraphQlType) => number | string,
        errorMessage: string,
        keys: Array<keyof Request> = [],
        keysNullable: Array<keyof Request> = [],
        customParseToMutationValue: Map<keyof Request, (value: Request) => string> = new Map(),
    ) => this.createSingleEffect<Request, Entity, GraphQlType>('mutation', mutationName, GqlResponseFields, triggerAction, successAction, castToKey, errorMessage, keys, keysNullable, customParseToMutationValue);
    createQueryEffect = <Request, Entity, GraphQlType>(
        queryName: keyof Query,
        GqlResponseFields: string,
        triggerAction: ActionCreator<string, Creator<any[], { Payload: Request }>>,
        successAction: ActionCreator<string, (props: { Payload: Array<Entity> }) => { Payload: Array<Entity> } & TypedAction<string>>,
        castToEntity: (entity: Array<GraphQlType>) => Array<Entity>,
        errorMessage: string,
        keys: Array<keyof Request> = [],
        keysNullable: Array<keyof Request> = [],
    ) => this.createSingleEffect<Request, Array<Entity>, Array<GraphQlType>>('query', queryName, GqlResponseFields, triggerAction, successAction, castToEntity, errorMessage, keys, keysNullable);

    createAddEffect = this.createMutationEffect;
    createModifyEffect = this.createMutationEffect;
    createGetAllEffect = this.createQueryEffect;
    createDeleteOneEffect = this.createMutationDeleteEffect;

    // todo createRemoveEffect = this.createSingleEffect;
}
