<mat-expansion-panel *ngIf="Expandable" #panel>
    <mat-expansion-panel-header class="card-headline" style="padding: 0 15px 0 7px">
        <div class="header-content">
            <h3>Material ({{ (scheduledMaterials$ | async)?.data.length || 0 }})</h3>
        </div>
        <div class="header-button-wrapper">
            <mat-form-field floatLabel="never" [class.invisible]="!searchVisible" (click)="$event.stopPropagation()" style="width: 12rem">
                <mat-label>Suche</mat-label>
                <input matInput type="search" [formControl]="searchForm" #searchInput />
                <button class="clear-search" *ngIf="searchForm.value" mat-icon-button aria-label="Clear" (click)="searchForm.setValue('')">
                    <!--                        <fa-icon icon="times"></fa-icon>-->
                </button>
            </mat-form-field>
            <button *ngIf="!searchVisible && (scheduledMaterials$ | async)?.data?.length && panel.expanded" app-round-button matTooltip="Zus. Spalten anzeigen" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu">
                <fa-icon icon="table-columns"></fa-icon>
            </button>
            <button *ngIf="panel.expanded" style="margin: auto 0 auto auto" app-round-button (click)="$event.stopPropagation(); toggleSearch()" [matTooltip]="searchVisible ? 'abbrechen' : 'suchen'">
                <fa-icon [icon]="searchVisible ? 'times' : 'search'"></fa-icon>
            </button>
            <button *ngIf="!dialogRef && !searchVisible" matTooltip="Vollbild" app-round-button (click)="openDialog(); $event.stopPropagation()">
                <fa-icon icon="expand"></fa-icon>
            </button>
        </div>
    </mat-expansion-panel-header>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</mat-expansion-panel>
<div *ngIf="!Expandable">
    <div class="card-headline" style="padding: 0 0.5rem 0 0.5rem">
        <div class="header-content">
            <h3>Material ({{ (scheduledMaterials$ | async)?.data?.length }})</h3>
        </div>
        <div class="header-button-wrapper" *ngIf="Expanded">
            <div class="header-button-wrapper">
                <mat-form-field floatLabel="never" [class.invisible]="!searchVisible" (click)="$event.stopPropagation()" style="width: 15rem; margin-right: 8px; height: auto">
                    <mat-label>Suche</mat-label>
                    <input matInput type="search" [formControl]="searchForm" #searchInput />
                    <button class="clear-search" *ngIf="searchForm.value" mat-icon-button aria-label="Clear" (click)="searchForm.setValue('')">
                        <fa-icon icon="times"></fa-icon>
                    </button>
                </mat-form-field>
                <button *ngIf="!searchVisible && (scheduledMaterials$ | async)?.data?.length" app-round-button matTooltip="Zus. Spalten anzeigen" [matMenuTriggerFor]="menu">
                    <fa-icon icon="table-columns"></fa-icon>
                </button>
                <button style="margin: auto 0 auto auto" app-round-button (click)="$event.stopPropagation(); toggleSearch()" [matTooltip]="searchVisible ? 'abbrechen' : 'suchen'">
                    <fa-icon [icon]="searchVisible ? 'times' : 'search'"></fa-icon>
                </button>
                <button *ngIf="!searchVisible" app-round-button (click)="openNewPopup(); $event.stopPropagation()" matTooltip="Material zuweisen">
                    <app-double-icon [Icon]="ResourcePageMeta.Icon" Icon2="plus"></app-double-icon>
                </button>
            </div>
        </div>
    </div>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
    <ng-container [ngTemplateOutlet]="footer"></ng-container>
</div>

<ng-template #content>
    <h3 class="table-header">Materialliste</h3>
    <div class="planed-materials">
        <table *ngIf="scheduledMaterials$ | async" mat-table class="mat-elevation-z2 fixed-header" [dataSource]="scheduledMaterials$ | async">
            <ng-container matColumnDef="MaterialName" style="height: 2.3rem; overflow: hidden">
                <th mat-header-cell *matHeaderCellDef>Material</th>
                <td mat-cell *matCellDef="let element">{{ element.MaterialName }}</td>
            </ng-container>
            <ng-container matColumnDef="Quantity">
                <th mat-header-cell *matHeaderCellDef>Anzahl</th>
                <td mat-cell *matCellDef="let element">{{ element.Quantity }}</td>
            </ng-container>
            <ng-container matColumnDef="QuantityType">
                <th mat-header-cell *matHeaderCellDef>Einheit</th>
                <td mat-cell *matCellDef="let element">{{ element.QuantityType }}</td>
            </ng-container>
            <ng-container matColumnDef="CreatedAt">
                <th mat-header-cell *matHeaderCellDef>Datum</th>
                <td mat-cell *matCellDef="let element">{{ element.CreatedAt }}</td>
            </ng-container>
            <ng-container *ngIf="!visibility.Status" matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">{{ element.Status }}</td>
            </ng-container>
            <ng-container *ngIf="!visibility.Lieferant" matColumnDef="Lieferant">
                <th mat-header-cell *matHeaderCellDef>Lieferant</th>
                <td mat-cell *matCellDef="let element">{{ element.Lieferant }}</td>
            </ng-container>
            <ng-container *ngIf="!visibility.User" matColumnDef="User">
                <th mat-header-cell *matHeaderCellDef>Bearbeiter</th>
                <td mat-cell *matCellDef="let element">{{ element.User }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="MaterialColumns"></tr>
            <tr mat-row *matRowDef="let rowData; columns: MaterialColumns"></tr>
        </table>
        <h4 *ngIf="(scheduledMaterials$ | async)?.data.length === 0" class="no-content">Keine Einträge vorhanden</h4>
    </div>
</ng-template>
<ng-template #footer>
    <mat-dialog-actions class="actions" *ngIf="Expanded">
        <button app-button Inverted mat-dialog-close Color="cancel">schließen</button>
    </mat-dialog-actions>
</ng-template>

<mat-menu #menu="matMenu">
    <!--                    <button mat-menu-item (click)="toggleColumn('Lieferant')">-->
    <!--                        {{ visibility.Lieferant ? 'Lieferant anzeigen' : 'Lieferant ausblenden' }}-->
    <!--                    </button>-->
    <button mat-menu-item (click)="toggleColumn('Status')">
        {{ visibility.Status ? 'Status anzeigen' : 'Status ausblenden' }}
    </button>
    <button mat-menu-item (click)="toggleColumn('User')">
        {{ visibility.User ? 'Bearbeiter anzeigen' : 'Bearbeiter ausblenden' }}
    </button>
</mat-menu>
