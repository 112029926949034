<h1 matDialogTitle>Kolonnenerfassung</h1>
<div matDialogContent class="content-wrapper">
    <mat-card class="timer-card">
        <mat-card-title class="title"><fa-icon icon="stopwatch"></fa-icon> {{(runningType$ | async)?.Name || 'Zeiterfassung'}}</mat-card-title>
<!--        <mat-card-subtitle *ngIf="runningTime$ | async as time">{{ time.h | number: '2.0-0' }}:{{ time.m | number: '2.0-0' }}:{{ time.s | number: '2.0-0' }}</mat-card-subtitle>-->
        <span class="running-time" *ngIf="runningTime$ | async as time">{{ time.h | number: '2.0-0' }}:{{ time.m | number: '2.0-0' }}:{{ time.s | number: '2.0-0' }}</span>
        <mat-card-actions>
            <button app-button class="wide-button" [IsLoading]="ls.IsLoading$ | async" Color="green" [Disabled]="!!(runningType$ | async) || form.invalid" [matMenuTriggerFor]="clockInTypesMenu">Start</button>
            <span class="spacer"></span>
            <button app-button class="wide-button" [IsLoading]="ls.IsLoading$ | async" Color="red" [Disabled]="form.invalid" *ngIf="runningType$ | async" (click)="onStopClick()">Ende</button>
            <button app-button class="wide-button" [IsLoading]="ls.IsLoading$ | async" Color="red" [Disabled]="form.invalid" *ngIf="!(runningType$ | async) && (someoneHasRunningWorkday$ | async)" (click)="onStopDayClick()">Tag Ende</button>
            <mat-menu #clockInTypesMenu>
                <button mat-menu-item *ngFor="let type of selectableClockInTypes$ | async" (click)="onStartByTypeClick(type)">{{type.Name}}</button>
            </mat-menu>
        </mat-card-actions>
    </mat-card>
    <app-chip-autocomplete class="full-width" required [formControl]="form.controls.employees" [MapFn]="getName" [Options]="selectableEmployees$ | async" Placeholder="Mitarbeiter"></app-chip-autocomplete>
    <mat-form-field class="full-width">
        <mat-label>{{cls.getSingle$('Commission') | async}}</mat-label>
        <app-select-search [formControl]="form.controls.commission" [CompareOptions]="compareById" [Options]="selectableCommissions$ | async"></app-select-search>
        <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer;" (click)="form.controls.commission.setValue(null);$event.stopPropagation();" *ngIf="!form.controls.commission.disabled"></fa-icon>
    </mat-form-field>
    <mat-form-field class="full-width">
        <mat-label>Notiz</mat-label>
        <textarea
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="10"
            [formControl]="form.controls.workDescription"
        ></textarea>
    </mat-form-field>
</div>
<button mat-icon-button (click)="closeDialog()" class="close-mat-dialog-button" matTooltip="Schließen">
    <fa-icon icon="times" size="2x"></fa-icon>
</button>
