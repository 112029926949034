import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import * as L from 'leaflet';
import { icon, Marker } from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { getImageDimensionsFromBase64 } from '../helper/helper';

type tooltipOptions = {content: String|HTMLElement|Function, opacity?: number};
// function determineIfIsTooltipOptions(v: string | tooltipOptions): is tooltipOptions {
//
// }

require('leaflet.fullscreen')

const iconRetinaUrl = 'assets/leaflet/marker-icon-2x.png';
const iconUrl = 'assets/leaflet/marker-icon.png';
const shadowUrl = 'assets/leaflet/marker-shadow.png';
const iconDefault = icon({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
});
const faLocationDotSvg =
    '<svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill="currentColor" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>';
const iconDefaultSvgOptions = {
    // iconRetinaUrl,
    // iconUrl,
    // shadowUrl,
    html: faLocationDotSvg,
    className: 'fa-icon-dot',
    iconSize: [36, 48],
    iconAnchor: [18, 48],
    popupAnchor: [1, -40],
    tooltipAnchor: [0, -40],
    shadowSize: [48, 48],
};
const iconDefaultSvg = L.divIcon(iconDefaultSvgOptions);
Marker.prototype.options.icon = iconDefaultSvg;

type marker = { _latlng: { lat: number; lng: number } };
type addMapMarkerOptions = { draggable?: boolean; icon?: any };
export type markerColor = 'red' | 'blue' | 'green' | '#fac600' | 'grey';
@Component({
    selector: 'app-leaflet-wrapper',
    standalone: true,
    imports: [CommonModule, DaveLoadingModule, FontAwesomeModule],
    templateUrl: './leaflet-wrapper.component.html',
    styleUrls: ['./leaflet-wrapper.component.scss', '../../../node_modules/leaflet.fullscreen/Control.FullScreen.css'],
})
export class LeafletWrapperComponent implements AfterViewInit, OnDestroy {
    static nextId = 0;

    protected uniqId = 'app-leaflet-wrapper-' + LeafletWrapperComponent.nextId++;
    @Input() InitialDocument: { imageUrl: string /*imageBounds?: [[number, number], [number, number]]*/ };
    @Output() MapInitialized = new EventEmitter<void>();
    @Output() MarkerAdded = new EventEmitter<marker>();
    @Output() MarkerMoved = new EventEmitter<marker>();

    public MarkerTooltipsPermanent = false;
    public LeafletInstance: any;
    public AddMarkerMode = false;
    private AddMarkerModeContext: {
        color: markerColor;
        options: addMapMarkerOptions;
    };
    protected ngStyle = null;
    protected style = null;

    protected baseLayer = {
        background: null,
    };
    protected overlayLayer = {
        marker: L.layerGroup(),
    };
    private markerCache = [];

    protected loadBackgroundLayer$ = new BehaviorSubject(false);

    public OnDestroy$ = new EventEmitter<void>();
    @ViewChild('mapMarkerTemplate') mapMarkerTemplate: TemplateRef<any>;

    constructor(private store: Store<State>) {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.initMap();
        }, 1);
    }
    ngOnDestroy(): void {
        this.OnDestroy$.emit();
    }
    private initMap() {
        this.LeafletInstance = L.map(this.uniqId + '-map', {
            crs: L.CRS.Simple,
            minZoom: -5,
            fullscreenControl: document.fullscreenEnabled,
            fullscreenControlOptions: {
                position: 'topleft'
            },
        })
            .addLayer(this.overlayLayer.marker)
            .on('click', (e) => {
                if (this.AddMarkerMode) {
                    this.addMarker(e.latlng.lat, e.latlng.lng, this.AddMarkerModeContext?.options, this.AddMarkerModeContext?.color);
                }
            });

        if (this.InitialDocument) {
            this.setBackground(
                this.InitialDocument.imageUrl,
            );
        }
        this.MapInitialized.emit();
    }

    public setBackground(imageUrl: string) {
        this.loadBackgroundLayer$.next(true);

        getImageDimensionsFromBase64(this.InitialDocument.imageUrl).then((dimensions) => {
            // [90, 180],
            // [-90, -180],
            const ratio = dimensions.width / dimensions.heigth;
            const bigger = dimensions.width > dimensions.heigth ? dimensions.width : dimensions.heigth;
            this.baseLayer.background = L.imageOverlay(imageUrl, [
                // [dimensions.heigth * 9, dimensions.width * 18],
                // [dimensions.heigth * -1 * 9, dimensions.width * -1 * 18],
                // [90 * (ratio), 180 / (ratio)],
                // [-90 * (ratio), -180 / (ratio)],
                [dimensions.heigth / 2, dimensions.width / 2],
                [(dimensions.heigth / 2) * -1, (dimensions.width / 2) * -1],
            ])
                .addTo(this.LeafletInstance)
                .on('load', () => {
                    this.loadBackgroundLayer$.next(false);
                })
                .on('error', () => {
                    this.loadBackgroundLayer$.next(false);
                    this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'Fehler beim Laden der Hintergrundkarte' } }));
                });
            this.LeafletInstance.setView([0, 0], +(bigger / (360 * 4)).toFixed(0) * -1 + 0)
        });
    }
    public stopAddMarkerMode() {
        this.AddMarkerMode = false;
        const classList = document.querySelector('#' + this.uniqId + '-map')?.classList;
        classList?.forEach((className) => {
            if (className.startsWith('cursor-color-')) {
                classList.remove(className);
            }
        });
    }
    public startAddMarkerMode(options?: addMapMarkerOptions, color?: markerColor) {
        document.querySelector('#' + this.uniqId + '-map').classList.add('cursor-color-' + color);
        this.AddMarkerMode = true;
        this.AddMarkerModeContext = { options, color };
    }
    public clearMarkers() {
        this.overlayLayer.marker.clearLayers();
        this.markerCache = [];
    }
    public toggleMarkerTooltipsPermanent() {
        this.MarkerTooltipsPermanent = !this.MarkerTooltipsPermanent;
        this.markerCache.forEach(m => {
            this.reRenderTooltipFromMarter(m);
        });
    }
    private reRenderTooltipFromMarter(marker) {
        const t = marker.getTooltip();
        if (t) {
            const t2 = L.tooltip({...t.options, permanent: this.MarkerTooltipsPermanent})
            marker.unbindTooltip();
            marker.bindTooltip(t2);
        }
    }
    public addMarker(lat: number, lng: number, options?: addMapMarkerOptions, color?: markerColor, onClick?: (marker: marker, event: any) => void, tooltip?: string | tooltipOptions) {
        const _options = {
            ...options,
            icon: L.divIcon({
                ...(options?.icon || iconDefaultSvgOptions),
                className: (options?.icon?.className ? options.icon.className : iconDefaultSvgOptions.className) + (color ? ' marker-color-' + color : ''),
            }),
        };
        const marker = L.marker([lat, lng], _options).on('move', (event) => {
            this.MarkerMoved.emit(marker);
        });
        if (tooltip) {
            const config = typeof tooltip === 'string' ? {content: tooltip} : tooltip;
            const t = L.tooltip({
                direction: 'top',
                permanent: this.MarkerTooltipsPermanent,
                className: color ? 'colorful-marker-tooltip marker-color-' + color : '',
                ...config
            });
            marker.bindTooltip(t);
        }
        if (onClick) {
            marker.on('click', (event) => {
                onClick(marker, event);
                this.reRenderTooltipFromMarter(marker); //fix BUG "tooltip disappears on click"
            });
        }
        this.markerCache.push(marker);
        this.overlayLayer.marker.addLayer(marker);
        this.MarkerAdded.emit(marker);
    }
    public getMarker() {
        return this.overlayLayer.marker.getLayers();
    }
    public closeFullscreen() {
        if (this.LeafletInstance._isFullscreen) {
            this.LeafletInstance.toggleFullscreen();
        }
    }
}
