import { createAction, props } from '@ngrx/store';
import { EmailEntity } from '../../entities/email.entity';
import {
    DaveMutationChangeEmailArgs,
    DaveMutationChangeEmailMultipleArgs, DaveMutationChangeSentEmailArgs,
    DaveMutationCreateEmailArgs, DaveMutationCreateSentEmailArgs,
    DaveMutationDeleteEmailMultipleArgs,
    DaveMutationSentEmailArgs,
} from '../../graphql-types';
import { GatewayResponseErrorType } from '../../services/app-gateway.service';

export const EmailActionTypes = {
    UpdateSomeEmails: createAction('data/Emails/updateSome', props<{ Payload: Array<EmailEntity> }>()),
    UpdateOneEmail: createAction('data/Emails/updateOne', props<{ Payload: EmailEntity }>()),
    ModifyEmail: createAction('data/Emails/modify', props<{ Payload: DaveMutationChangeEmailArgs }>()),
    AddEmail: createAction('data/Emails/add', props<{ Payload: DaveMutationCreateEmailArgs }>()),

    DeleteEmails: createAction('data/Emails/delete', props<{ Payload: DaveMutationDeleteEmailMultipleArgs }>()),
    ReadEmails: createAction('data/Emails/read', props<{ Payload: DaveMutationChangeEmailMultipleArgs }>()),

    ModifyAndSendRequest: createAction('data/Emails/modifyAndSave/Request', props<{ Payload: DaveMutationChangeSentEmailArgs }>()),
    ModifyAndSendSuccess: createAction('data/Emails/modifyAndSave/Success', props<{ Payload: EmailEntity }>()),
    ModifyAndSendFailure: createAction('data/Emails/modifyAndSave/Failure', props<{ Errors: any; ToasterMessage?: string }>()),

    CreateAndSendRequest: createAction('data/Emails/createAndSend/Request', props<{ Payload: DaveMutationCreateSentEmailArgs }>()),
    CreateAndSendSuccess: createAction('data/Emails/createAndSend/Success', props<{ Payload: EmailEntity }>()),
    CreateAndSendFailure: createAction('data/Emails/createAndSend/Failure', props<{ Errors: any; ToasterMessage?: string }>()),
};
