<ngx-simplebar class="scrollbar">
    <div class="icons-wrapper">
        <div class="iconwrapper" *ngFor="let icon of Icons" [class.selectedIcon]="icon === SelectedIcon" (click)="Select(icon)">
            <fa-icon [icon]="icon"></fa-icon>
        </div>
    </div>
</ngx-simplebar>

<mat-dialog-actions class="buttons">
    <button app-button Inverted class="button" [mat-dialog-close]="null">Abbrechen</button>
    <button app-button class="button" [Disabled]="!SelectedIcon" (click)="Submit()">
        Auswählen
    </button>
</mat-dialog-actions>
