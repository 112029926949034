import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AkkordToCommissionEntity } from '../../../entities/akkord/akkord-to-commisson.entity';
import { AkkordToCommissionActionTypes } from '../../actions/akkord.action';

export interface AkkordToCommissionState extends EntityState<AkkordToCommissionEntity> {
    fetched: boolean;
}

export const AKKORD_TO_COMMISSION_KEY = 'akkordToCommission';

const adapter = createEntityAdapter<AkkordToCommissionEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const akkordToCommissionReducer = createReducer(
    initialState,
        on(AkkordToCommissionActionTypes.UpdateAll, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
        on(AkkordToCommissionActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllAkkordToCommission = selectAll;
export const selectAkkordToCommissionEntities = selectEntities;

export const selectAkkordToCommissionFetched = (state: AkkordToCommissionState) => state.fetched;
