import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EventTypeState, EVENT_TYPE_FEATURE_KEY, selectAllEventType, selectEventTypeEntities, selectEventTypeFetched } from '../reducers/event-types.reducer';

const selectState = createFeatureSelector< EventTypeState>(EVENT_TYPE_FEATURE_KEY);
const selectEntities = createSelector(selectState, selectEventTypeEntities);

export const getEventTypes = createSelector(selectState, selectAllEventType);
export const getEventTypesDictionary = selectEntities;
export const getEventTypeFetched = createSelector(selectState, selectEventTypeFetched);
export const getEventTypeById = (props: { id: number }) => createSelector(selectEntities, (dictionary) => props?.id && dictionary[props.id]);
