import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';
import {
    selectAllTextTemplates,
    selectTextTemplatesEntities,
    selectTextTemplatesFetched,
    TextTemplatesState,
    Text_Templates_Key,
} from '../reducers/textTemplates.reducer';

const selectTextTemplates = createFeatureSelector< TextTemplatesState>(Text_Templates_Key);
const getTextTemplatesEntitys = createSelector(selectTextTemplates, selectTextTemplatesEntities);

export const getTextTemplates = createSelector(selectTextTemplates, selectAllTextTemplates);
export const getTextTemplatesFetched = createSelector(selectTextTemplates, selectTextTemplatesFetched);
export const getTextTemplatesById = createSelector(
    getTextTemplatesEntitys,
    (textTemplates: ReturnType<typeof getTextTemplatesEntitys>, Id: number) => Id && textTemplates[Id],
);
