<div class="wrapper">
    <mat-card class="list-card">
        <mat-card-header>
            <h3 mat-card-title>Buchungspositionen</h3>
        </mat-card-header>
        <div>
            <table
                mat-table
                *ngIf="SortedBookingPositionList"
                [dataSource]="SortedBookingPositionList"
                style="width: 100%"
                #bookingPositionTable
            >
                <ng-container *ngFor="let collumName of AutoColumns" [matColumnDef]="collumName">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ AutoColumnHeaders[collumName] }}
                    </th>
                    <td mat-cell *matCellDef="let rowData">
                        {{ rowData ? rowData[collumName] : '' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Quantity">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ AutoColumnHeaders['Quantity'] }}
                    </th>
                    <td mat-cell *matCellDef="let rowData">
                        {{ rowData?.Quantity | number }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="BaseCost">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ AutoColumnHeaders['BaseCost'] }}
                    </th>
                    <td mat-cell *matCellDef="let rowData">
                        {{ rowData?.BaseCost | currency: rowData?.CurrencyCode }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="CompleteCost">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ AutoColumnHeaders['CompleteCost'] }}
                    </th>
                    <td mat-cell *matCellDef="let rowData">
                        {{ rowData?.CompleteCost | currency: rowData?.CurrencyCode }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="QuantityType">
                    <th mat-header-cell *matHeaderCellDef>
                        Einheit
                    </th>
                    <td mat-cell *matCellDef="let rowData">
                        {{ GetQuantityTypeById(rowData?.QuantityTypeId, QuantityTypes$ | async) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Checkbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <fa-icon icon="check-square"></fa-icon>
                    </th>
                    <td mat-cell *matCellDef="let rowData">
                        <mat-checkbox (change)="SelectAncillary($event, rowData)" color="primary"> </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Position">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ AutoColumnHeaders['Position'] }}
                    </th>
                    <td mat-cell *matCellDef="let rowData">
                        {{ GetPosition(rowData) }}
                    </td>
                </ng-container>
                <tr
                    [class.hide]="!SortedBookingPositionList?.length"
                    mat-header-row
                    *matHeaderRowDef="DisplayedColumns"
                ></tr>
                <tr
                    [class.not-clickable-list-item]="true"
                    mat-row
                    *matRowDef="let rowData; columns: DisplayedColumns"
                ></tr>
            </table>

            <table *ngIf="!SortedBookingPositionList?.length" style="width: 100%">
                <tr>
                    <td class="empty-table-text">
                        Keine Abrechnungspositionen vorhanden
                    </td>
                </tr>
            </table>
        </div>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Neue Nebenkosten hinzufügen</mat-card-title>
        </mat-card-header>
        <app-add-ancillary-costs
            [Save$]="Save$.asObservable()"
            [BookingOptionTemplateId]="SelectedTemplateId$ | async"
            [SelectedPrices]="SelectedPositions"
            [LedgerImportId]="LedgerImportId"
        ></app-add-ancillary-costs>
        <mat-card-actions>
            <div class="cancel-button-wrapper">
                <button app-button Inverted mat-dialog-close matTooltip="Fenster schließen">
                    Abbrechen
                </button>
            </div>
            <button
                [Disabled]="ancillaryChild?.Form?.invalid"
                app-button
                mat-dialog-close
                (click)="Save$.next()"
                matTooltip="Nebenkosten speichern"
            >
                Speichern
            </button>
        </mat-card-actions>
    </mat-card>
</div>
