import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { SimplebarAngularModule } from 'simplebar-angular';
import {
DetailCommissionEventListModule
} from '../dave-commission-module/components/detail-commission/detail-commission-event-list/detail-commission-event-list.module';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveEventListEntryModule } from "../dave-event-list-entry/dave-event-list-entry.module";
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import {
DaveSharedComponentsModule
} from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { SelectSearchModule } from '../dave-utils-module/select-search/select-search.module';
import { DaveListCardModule } from './../dave-list-card/dave-list-card.module';
import { DetailPersonComponent } from './components/detail-person/detail-person.component';
import { PersonDetailFormComponent } from './components/person-detail-form/person-detail-form.component';
import { PersonModalComponent } from './components/person-modal/person-modal';
import { SelectPersonsComponent } from './components/select-persons/select-persons.component';
import { DavePersonRoutingModule } from './dave-person-routing.module';

@NgModule({
    declarations: [DetailPersonComponent, PersonDetailFormComponent, PersonModalComponent, SelectPersonsComponent],
    imports: [
        DavePersonRoutingModule,
        CommonModule,
        DaveSharedComponentsModule,
        FontAwesomeModule,
        MatTooltipModule,
        AppButtonModule,
        DaveLoadingModule,
        SimplebarAngularModule,
        MatCheckboxModule,
        MatTableModule,
        MatDialogModule,
        MatChipsModule,
        DaveListCardModule,
        MatSortModule,
        ScrollingModule,
        TableVirtualScrollModule,
        MatCardModule,
        DaveEventListEntryModule,
        MatMenuModule,
        DetailCommissionEventListModule,
        DaveDoubleIconModule,
        RouterLink,
        MatFormFieldModule,
        SelectSearchModule,
        ReactiveFormsModule,
    ],
})
export class DavePersonModule {}
