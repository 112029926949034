import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    commissionsFeatureKey,
    CommissionState,
    selectAllCommission,
    selectCommissionEntities,
    selectCommissionFetched,
    selectCommissionLatestUpdatedAt,
} from '../reducers/commission.reducer';
import { getStatusFromBackoffice } from './statusFromBackoffice.selectors';
import { getEvents } from './events.selectors';

const selectCommission = createFeatureSelector< CommissionState>(commissionsFeatureKey);
const getCommissionEntitys = createSelector(selectCommission, selectCommissionEntities);

export const getCommissionDictionary = getCommissionEntitys;
export const getCommissions = createSelector(selectCommission, selectAllCommission);
export const getCommissionsFetched = createSelector(selectCommission, selectCommissionFetched);
export const getCommissionLatestUpdatedAt = createSelector(selectCommission, selectCommissionLatestUpdatedAt);
export const getCommissionById = (props: { id: number }) => createSelector(getCommissionEntitys, (dictionary) => props.id && dictionary[props.id]);
const getSorted = (props: { sortBy: 'updatedAt', direction?: 'desc' | 'asc' }) => createSelector(getCommissions, (entities) => {
    switch (props.sortBy) {
        case 'updatedAt': {
            if (props.direction === 'desc') {
                return entities.sort((a, b) => b.UpdatedAt.getTime() - a.UpdatedAt.getTime());
            } else {
                return entities.sort((a, b) => a.UpdatedAt.getTime() - b.UpdatedAt.getTime());
            }
        }
        default: {
            return entities;
        }
    }
});
export const getCommissionSorted = getSorted({sortBy: 'updatedAt', direction: 'desc'});

export const getCommissionsActive = createSelector(
    getCommissionSorted,
    getStatusFromBackoffice,
    (commissions, statusFromBackoffice) => {
        // console.log('getCommissionsActive !')
        const unwantedIds = statusFromBackoffice?.filter(a => a.IsCompleted).map(a => a.Id);
        return unwantedIds && commissions?.filter(c => !c.Deleted && !unwantedIds.includes(c.StatusBackofficeId));
    },
);
