<app-chip-autocomplete
    [formControl]="FormControl"
    [MapFn]="MapFn"
    [Options]="Options$ | async"
    [OptionTemplate]="optionsTemplate"
    Placeholder="Schlagwörter"
    Tooltip='Mit "Enter" bestätigen Sie das Schlagwort.'
    (UnknownOptionSubmitted)="Add($event)"
    [initialPatchDefaultValue]="true"
></app-chip-autocomplete>

<ng-template #optionsTemplate let-Option>
    <div>
        {{ MapFn(Option) }}
        <button mat-icon-button (click)="Delete(Option, $event)">
            <fa-icon icon="trash"></fa-icon>
        </button>
    </div>
</ng-template>
