import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MatDialogState, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { GetTimestampFromTime } from '@dave/types';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subscription, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, take, withLatestFrom } from 'rxjs/operators';
import { FileEntity } from '../../../dave-data-module/entities/file.entity';
import { FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { ProcessEntity } from '../../../dave-data-module/entities/process.entity';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { ProcessTemplateResolver } from '../../../dave-data-module/guards/process-template.resolver';
import { ProcessResolver } from '../../../dave-data-module/guards/process.resolver';
import { StatusFromBackofficeResolver } from '../../../dave-data-module/guards/statusFromBackoffice.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { ProcessActions } from '../../../dave-data-module/State/actions/process.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getCommissionsActive, getCommissionsFetched } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomersFetched, getNotDeletedCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getProcessTemplateActive, getProcessTemplateById, getProcessTemplateFetched } from '../../../dave-data-module/State/selectors/process-template.selector';
import { getProcessesActive, getProcessFetched } from '../../../dave-data-module/State/selectors/process.selector';
import { getStatusFromBackofficeFetched } from '../../../dave-data-module/State/selectors/statusFromBackoffice.selectors';
import { FileCardComponent } from '../../../dave-dms-module/dave-dms/file-card/file-card.component';
import { PdfEditorModule } from '../../../dave-dms-module/dave-dms/pdf-editor/pdf-editor.module';
import { DaveDoubleIconModule } from '../../../dave-double-icon/dave-double-icon.module';
import { FileExplorerComponent } from '../../../dave-file-explorer/components/file-explorer/file-explorer.component';
import { SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData } from '../../../dave-file-explorer/components/select-folder-dialog/select-folder-dialog.component';
import { DaveFileExplorerModule } from '../../../dave-file-explorer/dave-file-explorer.module';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { DaveFullscreenDialogModule } from '../../../dave-fullscreen-dialog/dave-fullscreen-dialog.module';
import {
    DaveSelectFileFromDmsComponent,
    DaveSelectFileFromDmsComponentDialogData,
    DaveSelectFileFromDmsComponentReturnData,
} from '../../../dave-select-file-from-dms/components/dave-select-file-from-dms/dave-select-file-from-dms.component';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { DaveSharedComponentsModule } from '../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { ExpansionPanelComponent } from '../../../expansion-panel/expansion-panel.component';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, uniqArray } from '../../../helper/helper';
import { DMSPageMeta, ProcessMeta } from '../../../helper/page-metadata';
import { CustomLabelService } from '../../../services/custom-label.service';
import { LoadingService } from '../../../services/loading.service';
import { SmallFileCardModule } from '../../../small-file-card/small-file-card.module';

export interface StartProcessDialogComponentDialogData {
    fileId?: number;
    defaultProcessTemplateId?: number;
    fileIds?: number[];
}
@Component({
    selector: 'app-start-process-dialog',
    standalone: true,
    imports: [
        CommonModule,
        DaveSharedComponentsModule,
        DaveFileExplorerModule,
        DaveFullscreenDialogModule,
        PdfEditorModule,
        MatDialogModule,
        AppButtonModule,
        FontAwesomeModule,
        MatButtonModule,
        MatTooltipModule,
        DaveDoubleIconModule,
        MatSlideToggleModule,
        FormsModule,
        MatMenuModule,
        FileCardComponent,
        ExpansionPanelComponent,
        SmallFileCardModule,
        //DaveDmsModule,
    ],
    templateUrl: './start-process-dialog.component.html',
    styleUrls: ['./start-process-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartProcessDialogComponent implements OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        minWidth: '90vw',
        maxWidth: '95vw',
        height: '90vh',
        maxHeight: '95vh',
    };
    @ViewChild('fileExplorerComponent') fileExplorerComponent: FileExplorerComponent;
    protected fileId$ = new BehaviorSubject<number>(null);
    @Input() set FileId(id: number) {
        this.fileId$.next(id);
    }

    protected DMSNotMinimalistic = false;
    protected file$ = this.fileId$.pipe(
        distinctUntilChanged(),
        switchMap((id) => (id ? this.fileDataService.GetFileById$(id) : of(null))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected version$ = this.file$.pipe(map((file) => file?.GetLastVersion()));
    protected versionSrc$ = this.version$.pipe(
        withLatestFrom(this.store.select(getToken)),
        map(([version, token]) => this.api.GetUrl(version.GetDownloadLink(token), 'file')),
        distinctUntilChanged(),
    );
    formGroup = new FormGroup({
        name: new FormControl<string>('' /*[Validators.required]*/),
        processType: new FormControl<{ Id: number; optionLabel: string }>(null, [Validators.required]),
        customer: new FormControl<{ Id: number; optionLabel: string }>(null),
        commission: new FormControl<{ Id: number; optionLabel: string }>(null),
        timespan: new FormGroup({
            from: new FormControl<Moment>(moment()),
            to: new FormControl<Moment>(null),
        }),
        Description: new FormControl<string>(''),
        Description2: new FormControl<string>(''),
    });
    detailListData$: Observable<IDetailListTemplateData> = combineLatest([
        getFetched$(this.store, getProcessTemplateFetched, getProcessTemplateActive, this.processTemplateResolver),
        getFetched$(this.store, getProcessFetched, getProcessesActive, this.processResolver),
        getFetched$(this.store, getCustomersFetched, getNotDeletedCustomers, this.customerResolver),
        getFetched$(this.store, getCommissionsFetched, getCommissionsActive, this.commissionResolver),
        this.cls.getSingle$('Customer'),
        this.cls.getSingle$('Commission'),
    ]).pipe(
        map(([processTemplates, processes, customers, commissions, customerLabel, commissionLabel]) => {
            return {
                Properties: [
                    {
                        key: ProcessEntity.EntityPropertyNames.get('Name'),
                        formControl: this.formGroup.controls.name,
                    },
                    {
                        key: ProcessEntity.EntityPropertyNames.get('ProcessTemplateId'),
                        formControl: this.formGroup.controls.processType,
                        options: {
                            specialInput: {
                                singleSelectSearch: {
                                    options: processTemplates.map((processTemplate) => ({ Id: processTemplate.Id, optionLabel: processTemplate.DisplayName })),
                                    compareOptions: (a, b) => a.Id === b.Id,
                                },
                            },
                        },
                    },
                    {
                        key: customerLabel + "/Lieferant", //ProcessEntity.EntityPropertyNames.get('CustomerId'),
                        formControl: this.formGroup.controls.customer,
                        options: {
                            specialInput: {
                                singleSelectSearch: {
                                    options: customers.map((customer) => ({ Id: customer.Id, optionLabel: customer.DisplayName })),
                                    compareOptions: (a, b) => a.Id === b.Id,
                                },
                            },
                        },
                    },
                    {
                        key: commissionLabel, //ProcessEntity.EntityPropertyNames.get('CommissionId'),
                        formControl: this.formGroup.controls.commission,
                        options: {
                            specialInput: {
                                singleSelectSearch: {
                                    options$: this.formGroup.controls.customer.valueChanges.pipe(
                                        map((customer) =>
                                            (customer?.Id ? commissions.filter((commission) => commission.CustomerId === customer.Id) : commissions).map((commission) => ({ Id: commission.Id, optionLabel: commission.DisplayName })),
                                        ),
                                    ),
                                    compareOptions: (a, b) => a.Id === b.Id,
                                },
                            },
                        },
                    },
                    {
                        key: 'Zeitspanne',
                        formControl: this.formGroup.controls.timespan,
                        options: {
                            specialInput: {
                                timeSpan: {
                                    formControlFrom: this.formGroup.controls.timespan.controls.from,
                                    formControlTo: this.formGroup.controls.timespan.controls.to,
                                },
                            },
                        },
                    },
                    {
                        key: ProcessEntity.EntityPropertyNames.get('Description'),
                        formControl: this.formGroup.controls.Description,
                        options: {
                            specialInput: {
                                textArea: {
                                    Fill: false,
                                },
                            },
                        },
                    },
                    {
                        key: ProcessEntity.EntityPropertyNames.get('Description2'),
                        formControl: this.formGroup.controls.Description2,
                        options: {
                            specialInput: {
                                textArea: {
                                    Fill: false,
                                },
                            },
                        },
                    },
                ],
            };
        }),
    );
    selectedDocumentIds: number[] = [];
    protected fileExplorerExpanded$ = new BehaviorSubject<boolean>(false);
    private subscriptions: Subscription[] = [];
    constructor(
        protected store: Store<State>,
        protected processTemplateResolver: ProcessTemplateResolver,
        protected processResolver: ProcessResolver,
        protected customerResolver: CustomerResolver,
        protected commissionResolver: CommissionResolver,
        protected statusFromBoRes: StatusFromBackofficeResolver,
        private fileDataService: FileDataService,
        protected api: HttpService,
        protected actions$: Actions,
        protected router: Router,
        protected ls: LoadingService,
        protected dialogRef: MatDialogRef<StartProcessDialogComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public DialogData: StartProcessDialogComponentDialogData,
        protected cls: CustomLabelService,
        private folderDataService: FolderDataService,
    ) {
        this.subscriptions.push(
            combineLatest([
                this.formGroup.controls.commission.valueChanges.pipe(startWith(this.formGroup.value.commission)),
                this.formGroup.controls.customer.valueChanges.pipe(startWith(this.formGroup.value.customer)),
                this.fileExplorerExpanded$,
                this.file$,
            ])
                .pipe(
                    filter(([, , explorerExpanded]) => explorerExpanded),
                    map(([commission, customer, explorerExpanded, file]) => ({ commissionId: commission?.Id || null, customerId: (!commission && customer?.Id) || null, parentFolderId: file?.FolderId })),
                    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
                    switchMap((idObj) => {
                        if (idObj.commissionId) {
                            return folderDataService.getFolderFromEntity(idObj.commissionId, FolderTypes.commission);
                        } else if (idObj.customerId) {
                            return folderDataService.getFolderFromEntity(idObj.customerId, FolderTypes.customer);
                        } else if (idObj.parentFolderId) {
                            return folderDataService.getFolderById(idObj.parentFolderId);
                        } else {
                            return of(null);
                        }
                    }),
                    map((folder) => (folder && !folder.Hidden ? folder.Id : null)),
                    distinctUntilChanged(),
                )
                .subscribe((folderId) => {
                    if (this.fileExplorerComponent) {
                        this.fileExplorerComponent.SelectedFolderId = folderId || null;
                    }
                }),
        );
        if (DialogData?.fileId) {
            this.FileId = DialogData.fileId;
        }
        if (DialogData?.defaultProcessTemplateId) {
            firstValueFrom(
                this.store.select(getProcessTemplateFetched).pipe(
                    filter((f) => f),
                    switchMap(() => this.store.select(getProcessTemplateById({ id: DialogData.defaultProcessTemplateId }))),
                ),
            ).then((processTemplate) => {
                this.formGroup.controls.processType.setValue({ Id: processTemplate.Id, optionLabel: processTemplate.DisplayName });
            });
        }
        if (DialogData?.fileIds){
            this.selectedDocumentIds = DialogData.fileIds;
        }
        firstValueFrom(this.store.select(getStatusFromBackofficeFetched)).then((fetched) => {
            if (!fetched) {
                this.statusFromBoRes.resolve();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    onAddFileClick(id: number) {
        console.log({ id });
        if (!this.selectedDocumentIds.includes(id)) {
            this.selectedDocumentIds.push(id);
            this.selectedDocumentIds = [...this.selectedDocumentIds];
            // this.cdr.detectChanges();
        }
    }
    onRemoveFileClick(file: FileEntity) {
        console.log({ file });
        const i = this.selectedDocumentIds.indexOf(file?.Id);
        if (i > -1) {
            this.selectedDocumentIds = [...this.selectedDocumentIds];
            this.selectedDocumentIds.splice(i, 1);
            // this.cdr.detectChanges();
        }
    }
    onSubmitClick() {
        this.ls.startLoading('StartProcessDialogComponent onSubmitClick');
        if (this.formGroup.valid) {
            this.store.dispatch(
                ProcessActions.start({
                    Payload: {
                        Name: this.formGroup.controls.name.value,
                        TemplateId: this.formGroup.controls.processType.value.Id + '',
                        CustomerId: this.formGroup.controls.customer.value && this.formGroup.controls.customer.value.Id + '',
                        CommissionId: this.formGroup.controls.commission.value && this.formGroup.controls.commission.value?.Id + '',
                        Description: this.formGroup.controls.Description.value,
                        Description2: this.formGroup.controls.Description2.value,
                        StartDate: this.formGroup.value.timespan.from && GetTimestampFromTime(this.formGroup.value.timespan.from.toDate()) + '',
                        EndDate: this.formGroup.value.timespan.to && GetTimestampFromTime(this.formGroup.value.timespan.to.toDate()) + '',
                        DocumentId: this.fileId$.value && this.fileId$.value + '',
                        DocumentIds: this.selectedDocumentIds && this.selectedDocumentIds.map((id) => id + ''),
                    },
                }),
            );
            firstValueFrom(this.actions$.pipe(ofType(ProcessActions.updateOne, BaseActionTypes.ErrorAction))).then((action) => {
                this.ls.endLoading('StartProcessDialogComponent onSubmitClick');
                if (action.type === ProcessActions.updateOne.type) {
                    this.router.navigate(['/', ProcessMeta.Path, this.formGroup.value.processType.Id, action.Payload.Id]);
                    this.dialogRef.close();
                }
            });
        }
    }

    protected readonly DMSPageMeta = DMSPageMeta;
    openFileSelectDialog() {
        this.dialog
            .open<DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData, DaveSelectFileFromDmsComponentReturnData>(DaveSelectFileFromDmsComponent, {
                ...DaveSelectFileFromDmsComponent.DefaultConfig,
                data: {
                    moveWarning: '',
                    preSelectedFiles: [],
                    maxFiles: 1,
                    // folderId:,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result?.documents?.length) {
                    this.fileId$.next(result.documents[0]);
                }
            });
    }
    onMainFileChanged() {
        // if ((event.target as HTMLInputElement)?.files?.length === 1) {
        const dialogRef = this.dialog.open<SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData>(SelectFolderDialogComponent, {
            ...SelectFolderDialogComponent.DefaultConfig,
            data: {
                ButtonText: 'HOCHLADEN',
                matTreeNodePaddingIndent: 10,
                uploadMode: true,
                synchronUpload: true,
                maxFilesUpload: 1,
            },
        });
        // firstValueFrom(this.folderTreeService.Tree).then((folder) => {
        //     dialogRef.componentInstance.TreeDataSource = new MatTreeFlatDataSource(folderTreeControl, folderTreeFlattener, folder);
        // });

        dialogRef.afterClosed().subscribe((res) => {
            if (res?.synchronUploadedDocuments?.length) {
                if (res.synchronUploadedDocuments?.length > 1) {
                    alert('mehr als eine datei ausgewählt');
                }
                this.fileId$.next(res.synchronUploadedDocuments[0].Id);

                // this.ls.startLoading('upload-main-file', { dialogMessage: 'Hauptdatei wird hochgeladen' });
                // firstValueFrom(this.actions$.pipe(ofType(FilesActionTypes.UpdateOne, BaseActionTypes.ErrorAction))).then((action) => {
                //     if (action.type === FilesActionTypes.UpdateOne.type) {
                //         this.fileId$.next(action.Payload.Id);
                //     }
                //     this.ls.endLoading('upload-main-file');
                // });
                // this.store.dispatch(
                //     FilesActionTypes.UploadFileBackend({
                //         Payload: {
                //             file: (event.target as HTMLInputElement).files[0],
                //             //params: new Map([
                //             //    ['filename', (event.target as HTMLInputElement).files[0].name],
                //             //    ['folder_id', res],
                //             //])
                //             params: (new Map([]) as FileUploadParams).set('name', (event.target as HTMLInputElement).files[0].name).set('folder_id', res.toString()),
                //         },
                //     }),
                // );
            }
        });
        // }
    }
    private filePreviewDialogRef: MatDialogRef<DaveFilePreviewComponent>;
    openFilePreview(fileId: number) {
        if (this.filePreviewDialogRef && this.filePreviewDialogRef?.getState() !== MatDialogState.CLOSED) {
            this.filePreviewDialogRef.close();
        }
        let ImageIds: number[];
        this.fileDataService.GetFilesById(uniqArray([...this.selectedDocumentIds, fileId].filter(isNotNullOrUndefined))).pipe(take(1), map(files => files.filter((f) =>
            f.MIMEType.indexOf('image/') > -1).map((f) => f.Id))).subscribe(imgIds=> {
            ImageIds = imgIds
            });

        this.filePreviewDialogRef = this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: {
                fileId,
                fileExplorerContext: ImageIds ? {
                    directoryFileIds: ImageIds,
                } : undefined
            },
        });
    }

    protected readonly ProcessMeta = ProcessMeta;
}
