import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, of, Subject } from "rxjs";
import { catchError, filter, map, tap } from "rxjs/operators";
import { ClockInEntityFromBackend } from '../dave-data-module/entities/clock-in.entity';
import { DaveMutationChangeClockInArgs, DaveMutationCreateClockInArgs, DaveMutationDeleteClockInArgs } from '../dave-data-module/graphql-types';
import { HttpService } from '../dave-data-module/services/http.service';
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';
import { ClockInActionTypes } from '../dave-data-module/State/actions/clock-in.actions';
import { ClockInErrorCodes } from '../dave-data-module/State/effects/clock-in.effects';
import { ClockInIcon } from '../helper/page-metadata';
import { ProgressToastrComponent, ProgressToastrComponentDialogData } from '../progress-toastr/progress-toastr.component';
import { ClockInDataService } from './clock-in-data.service';

@Injectable({
    providedIn: 'root',
})
export class ClockInHelperService {
    private activeDialog: MatDialogRef<ProgressToastrComponent>;
    private pendingRequestCount = 0;
    private totalRequestCount = 0;
    constructor(private store: Store<State>, private matDialog: MatDialog, private clockInDataService: ClockInDataService) {}
    dispatchClockInActions(add: DaveMutationCreateClockInArgs[], change: DaveMutationChangeClockInArgs[], remove: DaveMutationDeleteClockInArgs[]) {
        const requestCount = add.length + change.length + remove.length;

        this.pendingRequestCount = this.pendingRequestCount + requestCount;
        this.totalRequestCount = this.totalRequestCount + requestCount;

        let uploadedCount = 0;
        let readyCount = 0;

        const uploadProcessSubject = new Subject<{ loaded: number; total: number }>();
        const responseProcessSubject = new Subject<{ loaded: number; total: number }>();

        const onRequestUploaded = () => {
            uploadedCount++;
            uploadProcessSubject.next({ loaded: uploadedCount, total: requestCount });
            if (uploadedCount >= requestCount) {
                uploadProcessSubject.complete();
            }
        };
        const onRequestResponded = () => {
            readyCount++;
            responseProcessSubject.next({ loaded: readyCount, total: requestCount });
            if (readyCount >= requestCount) {
                responseProcessSubject.complete();
            }
            this.pendingRequestCount--;
            if (this.activeDialog?.componentInstance) {
                this.activeDialog.componentInstance.progress = ((this.totalRequestCount - this.pendingRequestCount) / this.totalRequestCount) * 100;
            }
            if (this.pendingRequestCount <= 0) {
                this.totalRequestCount = 0;
                this.pendingRequestCount = 0;
                this.activeDialog.close();
            }
        };

        if (add.length) {
            firstValueFrom(
                combineLatest(
                    add.map((p) => {
                        return this.clockInDataService.createClockIn(p, true).pipe(
                            tap((res) => {
                                if (!res || res.type === HttpEventType.UploadProgress && res.loaded >= res.total) {
                                    onRequestUploaded();
                                }
                            }),
                            filter((res) => !res || res.type === HttpEventType.Response),
                            tap(() => onRequestResponded()),
                            map((res) => res && HttpService.extractData(res['body'])),
                        );
                    }),
                ),
            ).then((res) => {
                if (res?.every((r) => r?.createClockIn)) {
                    this.store.dispatch(ClockInActionTypes.AddClockInsSuccess());
                } else {
                    this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ClockInErrorCodes.Add } }));
                }
            });
        }

        if (change.length) {
            firstValueFrom(
                combineLatest(
                    change.map((p) => {
                        return this.clockInDataService.changeClockIn(p, true).pipe(
                            tap((res) => {
                                if (!res || res.type === HttpEventType.UploadProgress && res.loaded >= res.total) {
                                    onRequestUploaded();
                                }
                            }),
                            filter((res) => !res || res.type === HttpEventType.Response),
                            tap(() => onRequestResponded()),
                            map((res) => res && HttpService.extractData(res['body'])),
                        );
                    }),
                ),
            ).then((res) => {
                if (res?.every((r) => r?.changeClockIn)) {
                    this.store.dispatch(ClockInActionTypes.ModifyClockInsSuccess());
                } else {
                    this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ClockInErrorCodes.Modify } }));
                }
            });
        }
        if (remove.length) {
            firstValueFrom(
                combineLatest(
                    remove.map((p) => {
                        return this.clockInDataService.deleteClockIn(p, true).pipe(
                            tap((res) => {
                                if (!res || res.type === HttpEventType.UploadProgress && res.loaded >= res.total) {
                                    onRequestUploaded();
                                }
                            }),
                            filter((res) => !res || res.type === HttpEventType.Response),
                            tap(() => onRequestResponded()),
                            map((res) => res && HttpService.extractData(res['body'])),
                        );
                    }),
                ),
            ).then((res) => {
                if (res?.every((r) => r?.deleteClockIn)) {
                    this.store.dispatch(ClockInActionTypes.DeleteClockInsSuccess({ Payload: res.map((r) => ClockInEntityFromBackend(r.deleteClockIn)) }));
                } else {
                    this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ClockInErrorCodes.Delete } }));
                }
            });
        }

        const title = 'Stempeln ...';
        const subTitle = 'Stempelzeiten werden bearbeitet';
        const icon = ClockInIcon;
        if (this.activeDialog?.getState() !== MatDialogState.OPEN) {
            this.activeDialog = this.matDialog.open<ProgressToastrComponent, ProgressToastrComponentDialogData>(ProgressToastrComponent, {
                ...ProgressToastrComponent.DefaultConfig,
                data: {
                    progressStartValue: 0,
                    title,
                    subTitle,
                    icon,
                },
                exitAnimationDuration: '1500ms',
            });
        } else {
            this.activeDialog.componentInstance.title = title;
            this.activeDialog.componentInstance.subTitle = subTitle;
            this.activeDialog.componentInstance.icon = icon;
        }
        return uploadProcessSubject;
    }
}
