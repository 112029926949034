import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { UserToEventEntity, UserToEventEntityFromBackend } from '../../entities/user-to-event.entity';
import { HttpService } from '../../services/http.service';
import { BaseActionTypes } from '../actions/base.actions';
import { UserToEventActionTypes } from '../actions/user-to-event.action';
import { State } from '../index';

enum ErrorCodes {
    Load = 'UserToEvent Abrufen fehlgeschlagen',
    Modify = 'UserToEvent Bearbeiten fehlgeschlagen',
    Remove = 'UserToEvent Löschen fehlgeschlagen',
    Add = 'UserToEvent Hinzufügen fehlgeschlagen',
}

@Injectable()
export class UserToEventEffects {

    CreateUserToEvent$ = createEffect(() => this.actions$.pipe(
        ofType(UserToEventActionTypes.Create),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
                mutation {
                    createUser2EventIndividually(
                        ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'eventId')}
                ) {
                    ${UserToEventEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.createUser2EventIndividually
                        ? UserToEventActionTypes.UpdateOne({
                              Payload: UserToEventEntityFromBackend(res.createUser2EventIndividually),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    ModifyUserToEvent$ = createEffect(() => this.actions$.pipe(
        ofType(UserToEventActionTypes.Change),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
            mutation {
                changeUser2Event(
                    ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'id')}
                ) {
                    ${UserToEventEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res => {
                    const ret: Action[] = [];
                    if (res && res.changeUser2Event) {
                        ret.push(
                            UserToEventActionTypes.UpdateOne({
                                Payload: UserToEventEntityFromBackend(res.changeUser2Event),
                            }),
                        );
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    GetUserToEvent$ = createEffect(() => this.actions$.pipe(
        ofType(UserToEventActionTypes.Load),
        switchMap(({ Payload }) => {
            const queryString = `
            query {
                user2Event${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
                    ${UserToEventEntity.GqlFields}
                }
            }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                tap(res => {
                    if (res && res.user2Event) {
                        if (Payload?.updatedSince) {
                            if (res.user2Event.length) {
                                this.store$.dispatch(
                                    UserToEventActionTypes.UpdateMany({
                                        Payload: res.user2Event.map(val => UserToEventEntityFromBackend(val)),
                                        updateLatestUpdatedAt: true,
                                    }),
                                );
                            }
                        } else {
                            this.store$.dispatch(
                                UserToEventActionTypes.UpdateAll({
                                    Payload: res.user2Event.map(val => UserToEventEntityFromBackend(val)),
                                    updateLatestUpdatedAt: true,
                                }),
                            );
                        }
                    } else {
                        this.store$.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }));
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            );
        }),
    ), { dispatch: false });


    RemoveUserToEvent$ = createEffect(() => this.actions$.pipe(
        ofType(UserToEventActionTypes.Delete),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
                mutation{
                  deleteUser2Event(
                  id: ${action.Payload.id}
                  )
                }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.deleteUser2Event
                        ? UserToEventActionTypes.RemoveOne({ Payload: action.Payload.id })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
