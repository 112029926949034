import { createAction, props } from '@ngrx/store';
import { CustomerTypeEntity } from '../../entities/customer-type.entity';
import { CustomerLocationEntity } from '../../entities/customer-location.entity';
import { DaveMutationCreateCustomerTypeArgs, DaveMutationDeleteCustomerTypeArgs } from '../../graphql-types';

export const CustomerTypeActionTypes = {
    UpdateCustomerTypes: createAction(
        'data/customerTypes/update',
        props<{ Payload: Array<CustomerTypeEntity> }>(),
    ),
    UpdateCustomerLocations: createAction(
        'data/customerLocations/update',
        props<{ Payload: Array<CustomerLocationEntity> }>(),
    ),
    AddCustomerType: createAction(
        'data/customerTypes/add',
        props<{ Payload: DaveMutationCreateCustomerTypeArgs }>(),
    ),
    DeleteCustomerType: createAction(
        'data/customerTypes/delete',
        props<{ Payload: DaveMutationDeleteCustomerTypeArgs }>(),
    ),
};
