import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'length',
    pure: true,
    standalone: true,
})
export class LengthPipe implements PipeTransform {
    transform(array: Array<any> | null): number | null {
        return array ? array.length : null;
    }
}
