import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy,Component,Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-dynamic-folder-node[expanded][expandable]',
    templateUrl: './dynamic-folder-node.component.html',
    styleUrls: ['./dynamic-folder-node.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MatButtonModule, FontAwesomeModule, MatProgressBarModule],
})
export class DynamicFolderNodeComponent {
    @Input() public expanded!: boolean;
    @Input() public expandable!: boolean;
    @Input() public loading: boolean;
}
