import { Injectable } from '@angular/core';
import {ComplaintTypesEnum} from "../dave-data-module/entities/complaintType.entity";
import {getPerson2Entities} from "../dave-data-module/State/selectors/person2entity.selectors";
import {filter, map, take, withLatestFrom} from "rxjs/operators";
import {Person2EntityEntityTypeEnum} from "../dave-data-module/entities/person2entity.entity";
import {SelectPersonsComponent, SelectPersonsComponentDialogData} from "../dave-person-module/components/select-persons/select-persons.component";
import {PersonEntity} from "../dave-data-module/entities/person.entity";
import {isNotNullOrUndefined} from "../helper/helper";
import {getComplaintTypes} from "../dave-data-module/State/selectors/complaintType.selectors";
import {Person2EntityActionTypes} from "../dave-data-module/State/actions/person2entity.action";
import {Store} from "@ngrx/store";
import {State} from "../dave-data-module/State";
import {MatDialog} from "@angular/material/dialog";
import {CustomerEntity} from "../dave-data-module/entities/customer.entity";

@Injectable({
  providedIn: 'root'
})
/**
 * Opens SelectPerson Popup and then saves selected Persons to Customer. Could be extended for use with Commissions
 */
export class SelectPersonService {
  constructor(
      private store: Store<State>,
      private dialog: MatDialog,
  ) { }
    public OpenPersonDialog(complaintType: ComplaintTypesEnum | null = null, customer?: CustomerEntity) {
        this.store
            .select(getPerson2Entities)
            .pipe(
                take(1),
                map((pes) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Customer && pe.EntityId === customer.Id)),
                map((pes) => pes.map((pe) => pe.PersonId)),
            )
            .subscribe((selectedIds) => {
                this.dialog
                    .open<SelectPersonsComponent, SelectPersonsComponentDialogData, PersonEntity[]>(SelectPersonsComponent, {
                        ...SelectPersonsComponent.DefaultConfig,
                        data: {
                            ButtonText: customer.AnsprechpartnerText(),
                            SelectedPersonIds: selectedIds,
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter(isNotNullOrUndefined),
                        withLatestFrom(
                            this.store.select(getPerson2Entities).pipe(
                                filter(isNotNullOrUndefined),
                                map((pes) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Customer && pe.EntityId === customer.Id)),
                            ),
                            this.store.select(getComplaintTypes),
                        ),
                    )
                    .subscribe(([selectedPersons, personEntities, personTypes]) => {
                        let addIds: number[] = [];
                        let delIds: number[] = [];

                        selectedPersons.forEach((p) => {
                            if (personEntities.filter((pe) => pe.PersonId === p.Id).length === 0) {
                                addIds.push(p.Id);
                            }
                        });

                        personEntities.forEach((pe) => {
                            if (selectedPersons.filter((p) => p.Id === pe.PersonId).length === 0) {
                                delIds.push(pe.Id);
                            }
                        });

                        addIds.forEach((pId) => {
                            this.store.dispatch(
                                Person2EntityActionTypes.AddPerson2Entity({
                                    Payload: {
                                        personId: pId,
                                        entityId: customer.Id,
                                        entityType: Person2EntityEntityTypeEnum.Customer,
                                        complaintTypeId: complaintType ? personTypes?.find((p) => p.Name === complaintType)?.Id : null,
                                    },
                                }),
                            );
                        });

                        delIds.forEach((id) => {
                            this.store.dispatch(
                                Person2EntityActionTypes.DeletePerson2Entity({
                                    Payload: {
                                        id: id,
                                    },
                                }),
                            );
                        });
                    });
            });
    }
}
