import { JwtPayload } from 'jwt-decode';

export class TokenEntity {
  constructor(
    public Firstname: string = null,
    public Lastname: string = null,
    public PartnerId: number = null,
    public UserId: number = null,
    public Iat: number = null,
    public Exp: number = null,
    public Iss: string = null,
    public Aud: string = null
  ) {
  }

  public Clone(override: TokenEntityAttributes = {}): TokenEntity {
    const attr = {...this, ...override};
    return Object.assign(new TokenEntity(), attr);
  }
}


export interface TokenEntityAttributes {
  Firstname?: string;
  Lastname?: string;
  PartnerId?: number;
  UserId?: number;
  Iat?: number;
  Exp?: number;
  Iss?: string;
  Aud?: string;
}

export interface DecodedTokenType extends JwtPayload {
  firstname: string;
  lastname: string;
  partnerId: string;
  userId: string;
  aud: string;
}
