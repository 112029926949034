import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';
import { WorkDayType } from '../graphql-types';

export const WorkDayEntityGqlFields = `
breakTime
forcedBreakTime
confirmed
createdAt
deletedAt
employeeId
id
pause
timespan
updatedAt
timestamp
overtime
employee2VacationIds
isHoliday
isValid
`;
export class WorkDayEntity {
    constructor(
        public BreakTime: number = null,
        public Confirmed: boolean = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public EmployeeId: number = null,
        public Id: number = null,
        public Pause: number = null,
        public Timespan: number = null,
        public ForcedBreakTime: number = null,
        public UpdatedAt: Date = null,
        public Timestamp: Date = null,
        public Overtime: number = null,
        public Employee2VacationIds: number[] = null,
        public IsHoliday: boolean = null,
        public IsValid: boolean = null,
) {
    }
    public Clone(override: Partial<WorkDayEntity> = {}): WorkDayEntity {
        const attr = {...this, ...override};
        return Object.assign(new WorkDayEntity(), attr);
    }
}

export function WorkDayEntityFromBackend(res: WorkDayType): WorkDayEntity {
    return new WorkDayEntity(
        res.breakTime,
        res.confirmed,
        res.createdAt  ? BackendDateTimestamp(res.createdAt) : null,
        res.deletedAt ? BackendDateTimestamp(res.deletedAt) : null,
        res.employeeId,
        res.id,
        res.pause,
        res.timespan,
        res.forcedBreakTime,
        res.updatedAt ? BackendDateTimestamp(res.updatedAt) : null,
        res.timestamp ? BackendDateTimestamp(res.timestamp) : null,
        res.overtime,
        res.employee2VacationIds,
        res.isHoliday,
        res.isValid,
    );
}
