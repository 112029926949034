import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { UserToCustomerEntity, UserToCustomerEntityFromBackend } from '../../entities/user-to-customer.entity';
import { HttpService } from '../../services/http.service';
import { BaseActionTypes } from '../actions/base.actions';
import { UserToCustomerActionTypes } from '../actions/customers.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { getUser } from '../selectors/users.selectors';

enum ErrorCodes {
    Load = 'UserToCustomer Abrufen fehlgeschlagen',
    Modify = 'UserToCustomer Bearbeiten fehlgeschlagen',
    Remove = 'UserToCustomer Löschen fehlgeschlagen',
    Add = 'UserToCustomer Hinzufügen fehlgeschlagen',
}

@Injectable()
export class UserToCustomerEffects {

    LoadUserToCustomers$ = createEffect(() => this.actions$.pipe(
        ofType(UserToCustomerActionTypes.Load),
        withLatestFrom(this.store$.select(getToken)),
        switchMap(([{ Payload }, token]) => {
            const queryString = `
            query {
                user2Customer${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
                    ${UserToCustomerEntity.GQLFields.join(',')}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                tap(res => {
                    if (res && res.user2Customer) {
                        if (Payload?.updatedSince) {
                            if (res.user2Customer.length) {
                                this.store$.dispatch(
                                    UserToCustomerActionTypes.UpdateMany({
                                        Payload: res.user2Customer.map(val => UserToCustomerEntityFromBackend(val)),
                                        updateLatestUpdatedAt: true,
                                    }),
                                );
                            }
                        } else {
                            this.store$.dispatch(
                                UserToCustomerActionTypes.UpdateAll({
                                    Payload: res.user2Customer.map(val => UserToCustomerEntityFromBackend(val)),
                                    updateLatestUpdatedAt: true,
                                }),
                            );
                        }
                    } else {
                        this.store$.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }));
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            );
        }),
    ), { dispatch: false });


    AddUserToCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(UserToCustomerActionTypes.Create),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{ Payload }, token]) => {
            const queryString = `
            mutation {
                createUser2Customer(
                    ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'customerId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'roleId')}
                ) {
                    ${UserToCustomerEntity.GQLFields.join(',')}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
                concatMap(res => {
                    const ret: Action[] = [];
                    if (res && res.createUser2Customer) {
                        ret.push(UserToCustomerActionTypes.UpdateMany({ Payload: res.createUser2Customer.map(v => UserToCustomerEntityFromBackend(v)) }));
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    RemoveUserToCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(UserToCustomerActionTypes.Delete),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{ Payload }, token]) => {
            const queryString = `
            mutation {
                deleteUser2Customer(
                    id: ${Payload.id}
                ) {
                    ${UserToCustomerEntity.GQLFields.join(',')}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
                concatMap(res => {
                    const ret: Action[] = [];
                    if (res && res.deleteUser2Customer) {
                        ret.push(
                            UserToCustomerActionTypes.RemoveOne({
                                Payload: Payload.id,
                            }),
                        );
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
