import { EmployeeSalaryTypeType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export class EmployeeSalaryTypeEntity {
    constructor(
        public Amount: number = null,
        public Bearbeitungsschluessel: number = null,
        public CommissionId: number = null,
        // CreatedAt: = null,
        public Date: Date = null,
        public DeletedAt: Date = null,
        public Description: string = null,
        public EmployeeId: number = null,
        public Id: number = null,
        public Name: string = null,
        // PartnerId: = null,
        public PercentAmountFromSalary: number = null,
        public SalaryTypeNumber: number = null,
        public UpdatedAt: Date = null,
    ) // UserId: = null,
    {}
    public static readonly GqlFields: Array<keyof EmployeeSalaryTypeType> = [
        'amount',
        'bearbeitungsschluessel',
        'commissionId',
        // 'createdAt',
        'date',
        'deletedAt',
        'description',
        'employeeId',
        'id',
        'name',
        // 'partnerId',
        'percentAmountFromSalary',
        'salaryTypeNumber',
        'updatedAt',
        // 'userId',
    ];
    public static EntityName = 'Baulohn';
    public static EntityPropertyNames: Map<keyof EmployeeSalaryTypeEntity, string> = new Map([
        ['Amount', 'Wert'],
        ['Bearbeitungsschluessel', 'Bearbeitungsschlüssel'],
        ['Date', 'Datum'],
        ['DeletedAt', 'gelöscht am'],
        ['Description', 'Kommentar'],
        ['Name', 'Name'],
        ['PercentAmountFromSalary', 'Prozentualer Wert'],
        ['SalaryTypeNumber', 'Lohnartennummer'],
        ['UpdatedAt', 'aktualisiert am'],
    ])
    public Clone(override: Partial<EmployeeSalaryTypeEntity> = {}): EmployeeSalaryTypeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new EmployeeSalaryTypeEntity(), attr);
    }
}
export function EmployeeSalaryTypeEntityFromBackend(res: EmployeeSalaryTypeType): EmployeeSalaryTypeEntity {
    return new EmployeeSalaryTypeEntity(
        res.amount,
        res.bearbeitungsschluessel,
        res.commissionId,
        res.date && BackendDate(res.date),
        res.deletedAt && BackendDate(res.deletedAt),
        res.description,
        res.employeeId,
        res.id,
        res.name,
        res.percentAmountFromSalary,
        res.salaryTypeNumber,
        res.updatedAt && BackendDate(res.updatedAt),
    );
}
