import { CommonModule } from '@angular/common';
import { Component, Inject, Injector, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppButtonModule } from '../../../../dave-utils-module/app-button-module/app-button.module';
import { appMatDialogDefaultConfig } from '../../../../helper/helper';
import { CustomLabelService } from '../../../../services/custom-label.service';
import { newCustomerDefaultValues, NewCustomerMainComponent } from '../new-customer-main/new-customer-main.component';
import { Subscription } from 'rxjs';
import { PENDING_CHANGES_DEFAULT_MESSAGE } from '../../../../dave-data-module/guards/pending-changes.guard';

export type NewCustomerDialogComponentDialogReturnData = number | undefined;
export interface NewCustomerDialogComponentDialogData {
    defaultValues?: newCustomerDefaultValues;
    preventNewPersonDialog?: boolean;
}
@Component({
    selector: 'app-new-customer-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule, AppButtonModule, NewCustomerMainComponent],
    templateUrl: './new-customer-dialog.component.html',
    styleUrls: ['./new-customer-dialog.component.scss'],
})
export class NewCustomerDialogComponent implements OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        disableClose: true,
    };
    @ViewChild('mainComponent') protected mainComponent: NewCustomerMainComponent;
    private subs: Subscription[] = [];
    constructor(
        protected cls: CustomLabelService,
        protected dialogRef: MatDialogRef<NewCustomerDialogComponent, NewCustomerDialogComponentDialogReturnData>,
        protected injector: Injector,
        @Inject(MAT_DIALOG_DATA) protected data: NewCustomerDialogComponentDialogData,
    ) {
        if (this.dialogRef) {
            this.subs.push(this.dialogRef.backdropClick().subscribe(() => {
                this.closeDialog();
            }))
        }
        Object.values(NewCustomerMainComponent.RequiredResolvers).forEach((resolver) => {
            this.injector.get(resolver).resolve();
        });
    }

    Submit() {
        this.mainComponent?.Submit().then((latestCustomerId) => {
            if (latestCustomerId) {
                this.dialogRef.close(latestCustomerId);
            }
        });
    }
    closeDialog() {
        if (this.dialogRef) {
            if (this.mainComponent.canDeactivate() || confirm(PENDING_CHANGES_DEFAULT_MESSAGE)) {
                this.dialogRef.close();
            }
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }
}
