import {VarianteTypeType} from '../graphql-types';

export class VarianteTypeEntity {
    constructor(
        public Id: number = null,
        public TimePeriodInMonth: number = null,
        public DiscountInPercent: number = null,
        public PartnerTypeId: number = null,
        public ProductId: number = null,
        public CanBeBookedByUser: boolean = null
    ) {}

    public Clone(override: VarianteTypeEntityAttributes = {}): VarianteTypeEntity {
        const attr = {...this, ...override};
        return Object.assign(new VarianteTypeEntity(), attr);
    }
}

export function VarianteTypeEntityFromBackend(res: VarianteTypeType): VarianteTypeEntity {
    return new VarianteTypeEntity(
        res.id,
        res.timePeriodInMonth,
        res.discountInPercent,
        res.partnerTypeId,
        res.productId,
        res.canBeBookedByUser
    );
}

export interface VarianteTypeEntityAttributes {
    Id?: number;
    TimePeriodInMonth?: number;
    DiscountInPercent?: number;
    CanBeBookedByUser?: boolean;
    PartnerTypeId?: number;
    ProductId?: number;
}
