<mat-form-field [color]="Color">
    <input type="number" matInput [placeholder]="Key" [formControl]="FormControl" [required]="Required" [pattern]="Pattern" />
    <div matSuffix class="suffix">
        <ng-content select="[textfieldSuffix]"></ng-content>
        <fa-icon icon="question-circle" [matTooltip]="Hint" *ngIf="Hint != null" style="flex-shrink: 100"> </fa-icon>
    </div>
    <mat-error *ngIf="FormControl.invalid && (FormControl.dirty || FormControl.touched)">
        {{ GetErrorMessage(FormControl) }}
    </mat-error>
</mat-form-field>
