import { createActionGroup, props } from '@ngrx/store';
import {
    DaveMutationChangeEmployeeSalaryTypeArgs,
    DaveMutationCreateEmployeeSalaryTypeArgs,
    DaveMutationDeleteEmployeeSalaryTypeArgs, QueryEmployeeSalaryTypeArgs,
} from '../../graphql-types';
import { EmployeeSalaryTypeEntity } from "../../entities/employee-salary-type.entity";

export const EmployeeSalaryTypeActions = createActionGroup({
    source: 'EmployeeSalaryType',
    events: {
        'Update All': props<{ Payload: EmployeeSalaryTypeEntity[], updateLatestUpdatedAt?: boolean }>(),
        'Update Many': props<{ Payload: EmployeeSalaryTypeEntity[], updateLatestUpdatedAt?: boolean }>(),
        'Update One': props<{ Payload: EmployeeSalaryTypeEntity }>(),
        'Remove One': props<{ Payload: number }>(),

        'Load': props<{ Payload: QueryEmployeeSalaryTypeArgs }>(),
        'Add': props<{ Payload: DaveMutationCreateEmployeeSalaryTypeArgs }>(),
        'Change': props<{ Payload: DaveMutationChangeEmployeeSalaryTypeArgs }>(),
        'Delete': props<{ Payload: DaveMutationDeleteEmployeeSalaryTypeArgs }>(),
    },
});
