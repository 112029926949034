import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Person2EntityEntityTypeEnum } from '../../entities/person2entity.entity';
import {
    person2EntityFeatureKey,
    Person2EntityState,
    selectAllPerson2Entity,
    selectPerson2EntityEntities,
    selectPerson2EntityFetched,
    selectPerson2EntityLatestUpdatedAt,
} from '../reducers/person2Entity.reducer';

const selectState = createFeatureSelector<Person2EntityState>(person2EntityFeatureKey);
const selectEntities = createSelector(selectState, selectPerson2EntityEntities);

export const getPerson2Entities = createSelector(selectState, selectAllPerson2Entity);
export const getPerson2EntitiesFetched = createSelector(selectState, selectPerson2EntityFetched);
export const getPerson2EntityLatestUpdatedAt = createSelector(selectState, selectPerson2EntityLatestUpdatedAt);
export const getPerson2EntityById = createSelector(selectEntities, (Person2Entity: ReturnType<typeof selectEntities>, Id: number) => Id && Person2Entity[Id]);

export const getPerson2EntityEventByPersonId = (props: { personId: number }) => createSelector(getPerson2Entities, (person2Entity ) =>
    person2Entity ? person2Entity.filter((p) => p.PersonId === props.personId && p.EntityType === Person2EntityEntityTypeEnum.Event) : null,
);
export const getPerson2EntityByEventId = (props: { eventId: number }) =>
    createSelector(getPerson2Entities, (person2Entity) => {
        return person2Entity ? person2Entity.filter((p) => p.EntityType === Person2EntityEntityTypeEnum.Event && p.EntityId == props.eventId) : null;
    });
