import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseEntityReloadResolver2 } from './resolver.helper';
import { State } from '../State';
import { getMaterialListLatestUpdatedAt, getMaterialListsFetched } from '../State/selectors/material-list.selector';
import { MaterialListActionTypes } from '../State/actions/material-list.actions';

@Injectable({
    providedIn: 'root',
})
export class MaterialListResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getMaterialListsFetched, getMaterialListLatestUpdatedAt, MaterialListActionTypes.Load, store);
    }
}
