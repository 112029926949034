import { Injectable } from '@angular/core';
import { Actions,createEffect,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError,concatMap,map,switchMap } from 'rxjs/operators';
import { isNotNullOrUndefined,stringifyIfNotNullOrUndefined } from '../../../../helper/helper';
import { EmployeeResourceScheduleEntity,EmployeeResourceScheduleEntityFromBackend } from '../../../entities/resource-dispo/employee-resource-schedule.entity';
import { HttpService } from '../../../services/http.service';
import { DaveActions } from '../../actions/actions';
import { BaseActionTypes } from '../../actions/base.actions';
import { EmployeeResourceScheduleActionTypes } from '../../actions/resource-dispo/employee-resource-schedule.actions';
import { State } from '../../index';

enum ErrorCodes {
    Add = 'EmployeeResourceSchedule Hinzufügen fehlgeschlagen',
    Load = 'EmployeeResourceSchedule Abrufen fehlgeschlagen',
    Modify = 'EmployeeResourceSchedule Bearbeiten fehlgeschlagen',
    Remove = 'EmployeeResourceSchedule Löschen fehlgeschlagen',
}

@Injectable()
export class EmployeeResourceScheduleEffects {
    ModifyEmployeeResourceSchedule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeeResourceScheduleActionTypes.Change),
            concatMap(({ Payload }) => {
                const queryString = `
      mutation{
        changeEmployeeResourceSchedule(id: ${Payload.id},
        ${stringifyIfNotNullOrUndefined(Payload, 'amount')}
        ${stringifyIfNotNullOrUndefined(Payload, 'startDate')}
        ${stringifyIfNotNullOrUndefined(Payload, 'endDate')}
        ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'jobSpecificationId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'name')}
        ${stringifyIfNotNullOrUndefined(Payload, 'activityIds')}
        ${isNotNullOrUndefined(Payload.color) ? `color: {
            ${stringifyIfNotNullOrUndefined(Payload.color, 'red')}
            ${stringifyIfNotNullOrUndefined(Payload.color, 'green')}
            ${stringifyIfNotNullOrUndefined(Payload.color, 'blue')}
            ${stringifyIfNotNullOrUndefined(Payload.color, 'opacity')}
        }` : ''}
          ){${EmployeeResourceScheduleEntity.GqlFields.join(',')}}
      }`;
                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    map((res) =>
                        res && res.changeEmployeeResourceSchedule && res.changeEmployeeResourceSchedule.id
                            ? EmployeeResourceScheduleActionTypes.UpdateMany({
                                  Payload: [EmployeeResourceScheduleEntityFromBackend(res.changeEmployeeResourceSchedule)],
                              })
                            : BaseActionTypes.ErrorAction({
                                  Payload: { ToasterMessage: ErrorCodes.Modify },
                              }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Modify,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    AddEmployeeResourceSchedule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeeResourceScheduleActionTypes.Create),
            concatMap(({ Payload }) => {
                const queryString = `
      mutation{
        createEmployeeResourceSchedule(
        ${stringifyIfNotNullOrUndefined(Payload, 'amount')}
        ${stringifyIfNotNullOrUndefined(Payload, 'startDate')}
        ${stringifyIfNotNullOrUndefined(Payload, 'endDate')}
        ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'jobSpecificationId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'name')}
        ${stringifyIfNotNullOrUndefined(Payload, 'activityIds')}
        ${isNotNullOrUndefined(Payload.color) ? `color: {
            ${stringifyIfNotNullOrUndefined(Payload.color, 'red')}
            ${stringifyIfNotNullOrUndefined(Payload.color, 'green')}
            ${stringifyIfNotNullOrUndefined(Payload.color, 'blue')}
            ${stringifyIfNotNullOrUndefined(Payload.color, 'opacity')}
        }` : ''}
          ){${EmployeeResourceScheduleEntity.GqlFields.join(',')}}
      }`;

                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    map((res) => {
                        return res && res.createEmployeeResourceSchedule
                            ? EmployeeResourceScheduleActionTypes.UpdateMany({
                                Payload: [EmployeeResourceScheduleEntityFromBackend(res.createEmployeeResourceSchedule)],
                            })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    AddResourceScheduleFromCommission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeeResourceScheduleActionTypes.CreateFromCommission),
            concatMap(({ Payload }) => {
                const queryString = `
      mutation{
        createEmployeeResourceScheduleWithTemplate(
        ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'commissionTypeId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'startDate')}
          ){${EmployeeResourceScheduleEntity.GqlFields.join(',')}}
      }`;

                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    map((res) => {
                        return res && res.createEmployeeResourceScheduleWithTemplate
                            ? EmployeeResourceScheduleActionTypes.UpdateMany({
                                Payload: [EmployeeResourceScheduleEntityFromBackend(res.createEmployeeResourceScheduleWithTemplate)],
                            })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    DeleteEmployeeResourceSchedule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeeResourceScheduleActionTypes.Delete),
            concatMap(({ Payload }) => {
                const queryString = `
      mutation{
        deleteEmployeeResourceSchedule(id: ${Payload}){${EmployeeResourceScheduleEntity.GqlFields.join(',')}}
      }`;

                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    map((res) => {
                        return res && res.deleteEmployeeResourceSchedule
                            ? EmployeeResourceScheduleActionTypes.RemoveOne({
                                Payload: res.deleteEmployeeResourceSchedule.id,
                            })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    LoadEmployeeResourceSchedules = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeeResourceScheduleActionTypes.Load),
            switchMap(({ Payload }) => {
                const queryString = `
query{
    employeeResourceSchedule${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''}{${EmployeeResourceScheduleEntity.GqlFields.join(',')}}
      }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                    map((res) =>
                        res && res.employeeResourceSchedule
                            ? Payload?.updatedSince
                                ? EmployeeResourceScheduleActionTypes.UpdateMany({
                                      Payload: res.employeeResourceSchedule.map((val) => EmployeeResourceScheduleEntityFromBackend(val)),
                                      updateLatestUpdatedAt: true,
                                  })
                                : EmployeeResourceScheduleActionTypes.UpdateAll({
                                      Payload: res.employeeResourceSchedule.map((val) => EmployeeResourceScheduleEntityFromBackend(val)),
                                      updateLatestUpdatedAt: true,
                                  })
                            : BaseActionTypes.ErrorAction({
                                  Payload: { ToasterMessage: ErrorCodes.Load },
                              }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
