import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { BaseEntityReloadResolver2 } from './resolver.helper';
import { getLedgerImportLatestUpdatedAt, getLedgerImportsFetched } from '../State/selectors/ledger-import.selector';
import { LedgerImportActionTypes } from '../State/actions/accounting.actions';

@Injectable({
    providedIn: 'root',
})
export class LedgerImportResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getLedgerImportsFetched, getLedgerImportLatestUpdatedAt, LedgerImportActionTypes.GetLedgerImports, store);
    }
}
