import { createAction, createActionGroup, props } from '@ngrx/store';
import {
    DaveMutationPutSurchargeRateArgs,
    QuerySurchargeRateArgs,
} from '../../graphql-types';
import { SurchargeRateEntity } from '../../entities/surcharge-rate.entity';



// export const SurchargeRateActionTypes = {
//     Load: createAction('data/surchargeRate/loadAll', props<{ Payload?: QuerySurchargeRateArgs }>()),
//
//     UpdateAll: createAction('data/surchargeRate/updateAll', props<{ Payload: Array<SurchargeRateEntity> }>()),
//
//
// };
export const SurchargeRateActionTypes = createActionGroup({
    source: 'surchargeRate',
    events: {
        'Load Success': props<{ Payload: SurchargeRateEntity[] }>(),
        'Load Request': props<{ Payload?: QuerySurchargeRateArgs }>(),
        'Load Failure': props<{ Errors: any; ToasterMessage?: string }>(),

        'Put Request': props<{ Payload: DaveMutationPutSurchargeRateArgs }>(),
        'Put Failure': props< { Errors: any; ToasterMessage?: string }>(),
        'Put Success': props<{ Payload: SurchargeRateEntity }>(),
    },
});
