import { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface InfoDialogData {
    heading?: string;
    paragraph?: string;
    routingButtonRoute?: any[] | string | null | undefined;
    routingButtonText?: string;
}

@Component({
    selector: 'app-info-dialog',
    template: `
        <mat-dialog-content class="content">
            <h3 *ngIf="Data.heading">{{ Data.heading }}</h3>
            <p *ngIf="Data.paragraph">{{ Data.paragraph }}</p>
            <div class="routing-button-wrapper" *ngIf="Data.routingButtonText || Data.routingButtonRoute">
                <p class="routing-button-text">{{ Data.routingButtonText }}</p>
                <a *ngIf="Data.routingButtonRoute" app-round-button mat-dialog-close [routerLink]="Data.routingButtonRoute"><fa-icon icon="share"></fa-icon></a>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions class="buttons">
            <button app-button mat-dialog-close>OK</button>
        </mat-dialog-actions>
    `,
    styles: [
        `
            .routing-button-wrapper {
                display: flex;
                align-items: center;
            }
            .routing-button-text {
                word-break: break-word;
                margin-right: 1rem;
            }
            .content > :first-child {
                margin-top: 0;
            }
            .content > :last-child {
                margin-bottom: 0;
            }
            p {
                white-space: pre-line;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDialogComponent implements OnInit {
    private routerSubscription: Subscription;

    constructor(@Inject(MAT_DIALOG_DATA) public Data: InfoDialogData, private router: Router, private dialogRef: MatDialogRef<InfoDialogComponent>) {
        this.routerSubscription = this.router.events
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationStart),
                filter(() => !!this.dialogRef),
            )
            .subscribe(() => {
                this.dialogRef.close();
            });
    }
    ngOnInit(): void {
        this.routerSubscription.unsubscribe();
    }
}
