import { createReducer, on } from '@ngrx/store';
import { WorkDayEntity } from '../../entities/work-day.entity';
import { WorkDayActionTypes } from '../actions/work-day.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';


export const workDaysFeatureKey = 'workdays';

export interface WorkDaysState extends EntityState<WorkDayEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}
const adapter = createEntityAdapter<WorkDayEntity>({
    selectId: Entity => Entity.Id,
});

const initialConditionsState: WorkDaysState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const workDaysStateReducer = createReducer(
    initialConditionsState,
    on(WorkDayActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(WorkDayActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(WorkDayActionTypes.RemoveMany, (state, { Payload }) => adapter.removeMany(Payload, { ...state })),
);


/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllWorkDay = selectAll;
export const selectWorkDayEntities = selectEntities;

export const selectWorkDayFetched = (state: WorkDaysState) => state.fetched;
export const selectWorkDayLatestUpdatedAt = (state: WorkDaysState) => state.latestUpdatedAt;
