import { ChangeDetectionStrategy,ChangeDetectorRef,Component,ElementRef,HostListener,Inject,Input,OnDestroy,OnInit,Optional,ViewChild } from '@angular/core';
import { FormControl,FormGroup,Validators } from '@angular/forms';
import { MatDialog,MatDialogConfig,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, ResolveData, Router } from '@angular/router';
import { Actions,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from "@ngrx/store/src/models";
import { CheckBoxFormFieldInfo,DropDownFormFieldInfo,FormFieldData,TextFormFieldInfo } from '@syncfusion/ej2-angular-documenteditor';
import { Internationalization } from '@syncfusion/ej2-base';
import moment,{ Moment } from 'moment';
import { BehaviorSubject,combineLatest,firstValueFrom,Observable,of,Subject,Subscription,switchMap } from 'rxjs';
import { debounceTime,distinctUntilChanged,filter,map,shareReplay,withLatestFrom } from 'rxjs/operators';
import { CustomFields,CustomFieldTypes } from '../../../custom-form-fields/custom-form-fields.helper';
import {
    FileEntity,
    FileEntityDocumentStateNames,
    DocumentState,
    getGQLDocumentStateFromDocumentState,
} from '../../../dave-data-module/entities/file.entity';
import { GeneratedDocumentsEntity } from '../../../dave-data-module/entities/generatedDocuments.entity';
import { ComponentCanDeactivate,PENDING_CHANGES_DEFAULT_MESSAGE } from '../../../dave-data-module/guards/pending-changes.guard';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { GeneratedDocumentsActionTypes } from '../../../dave-data-module/State/actions/generatedDocuments.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getCommissionDictionary } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomerDictionary } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getGeneratedDocumentById } from '../../../dave-data-module/State/selectors/generatedDocuments.selectors';
import { getGeneratedDocumentsTypes } from '../../../dave-data-module/State/selectors/generatedDocumentsType.selectors';
import { EmailEditorComponent,EmailEditorComponentDialogData } from '../../../dave-email-module/components/email-editor/email-editor.component';
import { DaveFilePreviewComponent,DaveFilePreviewComponentDialogData } from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { FullscreenDialogComponent,FullscreenDialogComponentConfig,FullscreenDialogData } from '../../../dave-fullscreen-dialog/components/fullscreen-dialog.component';
import { AppConfirmationDialogComponent,AppConfirmationDialogData } from '../../../dave-utils-module/app-dialog-module/app-confirmation-dialog/app-confirmation-dialog.component';
import { IDetailListTemplateData,IDetailListTemplateDataProperty } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { SelectSearchOption } from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { appMatDialogDefaultConfig,isNotNullOrUndefined,programmaticDownloadAnchor } from '../../../helper/helper';
import { DMSPageMeta } from '../../../helper/page-metadata';
import { LoadingService } from '../../../services/loading.service';
import { CustomToolbarItemIds,DocumentEditorComponent } from '../document-editor/document-editor.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BurgerButtonWrapperComponent } from '../../../burger-button-wrapper/burger-button-wrapper.component';
import { getResourceDictionary } from '../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import { TokenResolver } from '../../../dave-data-module/guards/token.resolver';
import {
    GeneratedDocumentWithFileByIdResolver
} from '../../../dave-data-module/guards/generatedDocumentWithFileById.resolver';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { GeneratedDocumentsTypeResolver } from '../../../dave-data-module/guards/generatedDocumentsType.resolver';
import { commissionsFeatureKey } from '../../../dave-data-module/State/reducers/commission.reducer';
import { customersFeatureKey } from '../../../dave-data-module/State/reducers/customers.reducer';
import {
    generatedDocumentsTypesFeatureKey
} from '../../../dave-data-module/State/reducers/generatedDocumentsType.reducer';
import { ResourceResolver } from '../../../dave-data-module/guards/resource-dispo/resource.resolver';
import { RESOURCE_KEY } from '../../../dave-data-module/State/reducers/resource-dispo/resource.reducer';
import { CustomLabelService } from "../../../services/custom-label.service";
import { AppDialogService } from "../../../dave-utils-module/app-dialog-module/app-dialog.service";

const SYNCFUSION_DEFAULT_DATE_FORMAT = 'M/d/yyyy';
const intl: Internationalization = new Internationalization();

const timeRegex = /(h|H|s|m)/g;
const dateRegex = /(M|y|d)/g;
const syncfusionNewlineRegex = /(\u000b|\v)/g;

function syncfusionDateParser(value: string, format: string = SYNCFUSION_DEFAULT_DATE_FORMAT) {
    let dParser: Function = intl.getDateParser({ skeleton: 'full', type: 'dateTime', format });
    return moment(dParser(value));
}
function syncfusionDateFormatter(value: Moment, format: string = SYNCFUSION_DEFAULT_DATE_FORMAT) {
    let dFormatter: Function = intl.getDateFormat({ skeleton: 'full', type: 'dateTime', format });
    return dFormatter(value.toDate()) as string;
}
type sfdtObject = {
    protectionType?: string;
    optimizeSfdt?: false;
    enforcement?: boolean;
    formatting?: boolean;
    [key: string]: any;
}
function compareSFDTContent(a: string, b: string) : boolean {
    if (a === b) {
        return true;
    } else if (a?.length && b?.length) {
        const aObj: sfdtObject = JSON.parse(a);
        const bObj: sfdtObject = JSON.parse(b);
        if (!aObj.optimizeSfdt && !bObj.optimizeSfdt) {
            aObj.protectionType = bObj.protectionType = '';
            aObj.enforcement = bObj.enforcement = false;
            aObj.formatting = bObj.formatting = false;
            return JSON.stringify(aObj) === JSON.stringify(bObj);
        } else {
            throw new Error('optimized sfdt not implemented');
        }
    } else {
        return false;
    }
}
export interface DocumentEditorDialogComponentCloseData {
    deleted?: boolean;
}
export interface DocumentEditorDialogComponentDialogData {
    generatedDocumentId: number;
    showGeneratedDocumentData?: boolean;
    formFieldMode?: boolean;
}
@Component({
    selector: 'app-document-editor-dialog',
    templateUrl: './document-editor-dialog.component.html',
    styleUrls: ['./document-editor-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentEditorDialogComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    public static readonly RequiredResolvers: ResolveData = {
        token: TokenResolver,
        documentById: GeneratedDocumentWithFileByIdResolver,
        [commissionsFeatureKey]: CommissionResolver,
        [customersFeatureKey]: CustomerResolver,
        [generatedDocumentsTypesFeatureKey]: GeneratedDocumentsTypeResolver,
        [RESOURCE_KEY]: ResourceResolver,
    }
    public EditFormFields = true;
    public EditorToolbarItems = [
        ...DocumentEditorComponent.DefaultToolbarItems.filter((e) => {
            if (typeof e === 'string') {
                return e !== 'New' && e !== 'Open';
            } else {
                return (
                    e.id !== CustomToolbarItemIds.CustomShare &&
                    e.id !== CustomToolbarItemIds.CustomSign &&
                    e.id !== CustomToolbarItemIds.CustomDelete &&
                    e.id !== CustomToolbarItemIds.CustomWordExport &&
                    e.id !== CustomToolbarItemIds.CustomPfdExport
                );
            }
        }),
    ];
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        panelClass: [...appMatDialogDefaultConfig.panelClass, 'custom-dialog-class-fullscreen'],
        // maxWidth: '100%',
        // width: '100%',
        // height: '100%',
        // maxHeight: '100%',
    };
    @ViewChild('editor') public editorComponent: DocumentEditorComponent;
    @ViewChild('burgerButtonWrapperComponent') public burgerButtonWrapperComponent: BurgerButtonWrapperComponent;

    private formFieldDataBuffer: FormFieldData[];
    public GeneratedDoc$: Observable<GeneratedDocumentsEntity>;
    public PreventEditing$: Observable<boolean>;
    public PdfFile$: Observable<FileEntity | null>;
    public PdfFilePath$: Observable<string | null>;
    private subs: Subscription[] = [];
    public EditorCreated$ = new BehaviorSubject(false);
    public handleEditorChanges$ = new Subject<void>();
    public FormsInitialised = false;
    public CustomFields: Array<
        CustomFields & {
            formControl: FormControl<any>;
        }
    > = [];
    public DMSPageMeta = DMSPageMeta;
    public formFieldSubscriptions: Subscription[] = [];
    public GeneratedDocumentId$ = new BehaviorSubject<number | null>(null);
    public EditorContentDirty$ = new BehaviorSubject<boolean>(false);
    @Input() ShowGeneratedDocumentData: boolean = true;
    public GeneratedDocumentForm = new FormGroup({
        Name: new FormControl<string | null>(null),
        State: new FormControl<DocumentState | null>(null),
        Type: new FormControl<SelectSearchOption<{ Id: number }> | null>(null),
        Customer: new FormControl<SelectSearchOption<{ Id: number }> | null>(null),
        Commission: new FormControl<SelectSearchOption<{ Id: number }> | null>(null),
        Resource: new FormControl<SelectSearchOption<{ Id: number }> | null>(null),
    });
    public States = Object.values(DocumentState).map((v) => ({ value: v, label: FileEntityDocumentStateNames.get(v) }));
    public GeneratedDocumentData$: Observable<Array<IDetailListTemplateData & { headline: string }>>;
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public Dialogdata: DocumentEditorDialogComponentDialogData,
        private elementRef: ElementRef,
        private store: Store<State>,
        public BS: BreakpointObserverService,
        public LoadingService: LoadingService,
        private actions$: Actions,
        private dialog: MatDialog,
        private appDialog: AppDialogService,
        @Optional() private dialogRef: MatDialogRef<DocumentEditorDialogComponent, DocumentEditorDialogComponentCloseData>,
        private router: Router,
        private route: ActivatedRoute,
        private fileDataService: FileDataService,
        private api: HttpService,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        protected cls: CustomLabelService,
    ) {}

    ngOnInit(): void {
        if (this.Dialogdata) {
            this.GeneratedDocumentId$.next(this.Dialogdata.generatedDocumentId);
            this.ShowGeneratedDocumentData = !!this.Dialogdata.showGeneratedDocumentData;
            this.EditFormFields = isNotNullOrUndefined(this.Dialogdata.formFieldMode) ? this.Dialogdata.formFieldMode : true;
            this.store.dispatch(GeneratedDocumentsActionTypes.LoadOne({ id: this.Dialogdata.generatedDocumentId, withFile: true }));

            this.dialogRef.disableClose = true;
            this.dialogRef.backdropClick().subscribe(() => this.CloseDialog());
        } else {
            this.subs.push(
                this.handleEditorChanges$
                    .pipe(
                        filter(() => !document.activeElement?.hasAttribute('matInput')),
                        debounceTime(300), // Debounce to fix multiple calls from editor
                    )
                    .subscribe(() => {
                        if (this.EditFormFields && this.formFieldDataBuffer) {
                            const formFieldData: FormFieldData[] = this.editorComponent.DocumentEditorContainer.documentEditor.exportFormData();
                            if (formFieldData.length === this.CustomFields.length) {
                                this.CustomFields.forEach((cf) => {
                                    const formFieldDataForCustomField = formFieldData.find((f) => f.fieldName === cf.name);
                                    if (!formFieldDataForCustomField) {
                                        // No form field data for custom field maybe renamed
                                        this.initForms();
                                        return;
                                    }
                                    const v = formFieldDataForCustomField.value;
                                    if (cf.formControl.value !== v) {
                                        const props = this.editorComponent.DocumentEditorContainer.documentEditor.getFormFieldInfo(cf.name);
                                        if ((props as TextFormFieldInfo).type === 'Date' && v) {
                                            if ((props as TextFormFieldInfo).format.match(timeRegex) && !(props as TextFormFieldInfo).format.match(dateRegex)) {
                                                const momentValue = syncfusionDateParser(v as string, (props as TextFormFieldInfo).format);
                                                if (momentValue.format('HH:mm') !== cf.formControl.value) {
                                                    cf.formControl.reset(momentValue.format('HH:mm'));
                                                }
                                            } else if ((props as TextFormFieldInfo).format.match(dateRegex) && !(props as TextFormFieldInfo).format.match(timeRegex)) {
                                                const momentValue = syncfusionDateParser(v as string, (props as TextFormFieldInfo).format);
                                                if (momentValue?.toDate()?.getTime() !== cf.formControl.value?.toDate()?.getTime()) {
                                                    cf.formControl.reset(momentValue);
                                                }
                                            } else {
                                                throw new Error('Date Format must be either only date or only time');
                                            }
                                        } else if ((props as TextFormFieldInfo).type === 'Number' && v != null && +v !== cf.formControl.value) {
                                            cf.formControl.reset(v);
                                        } else if ((props as TextFormFieldInfo).type === 'Text') {
                                            const newLineRegex = /(\r\n|\r|\n)/g;
                                            if ((v as string)?.replace(syncfusionNewlineRegex, '') !== cf.formControl.value?.replace(newLineRegex, '')) {
                                                cf.formControl.reset((v as string).replace(syncfusionNewlineRegex, '\n'));
                                            }
                                        } else {
                                            cf.formControl.reset(v);
                                        }
                                    }
                                });
                            } else {
                                this.initForms();
                            }
                        }
                    }),
                this.activatedRoute.paramMap
                    .pipe(
                        filter(isNotNullOrUndefined),
                        map((paramMap) => +paramMap.get('documentId')),
                        distinctUntilChanged(),
                    )
                    .subscribe((id) => {
                        this.GeneratedDocumentId$.next(id || null);
                    }),
            );
        }
        this.GeneratedDoc$ = this.GeneratedDocumentId$.pipe(
            switchMap((id) => this.store.select(getGeneratedDocumentById({ id }))),
            filter((d) => d?.GeneratedFile !== undefined),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.PreventEditing$ = this.GeneratedDoc$.pipe(
            map((d) => d.State === DocumentState.DocumentStateSigned),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.PdfFile$ = this.GeneratedDoc$.pipe(
            distinctUntilChanged((a, b) => a.DocumentIdFromPdf === b.DocumentIdFromPdf),
            switchMap((doc) => (doc.DocumentIdFromPdf ? this.fileDataService.GetFileById$(doc.DocumentIdFromPdf) : of(null))),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.PdfFilePath$ = this.PdfFile$.pipe(
            withLatestFrom(this.store.select(getToken)),
            map(([file, token]) => (file ? this.getFileUrl(file, token) : null)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        this.GeneratedDocumentData$ = combineLatest([this.store.select(getGeneratedDocumentsTypes), this.store.select(getCustomerDictionary), this.store.select(getCommissionDictionary), this.store.select(getResourceDictionary), this.cls.getSingle$('Commission')]).pipe(
            map(([types, customers, commissions, resources, commissionLabel]) => {
                return [
                    {
                        headline: 'Bericht',
                        Properties: [
                            {
                                key: 'Name',
                                formControl: this.GeneratedDocumentForm.controls.Name,
                            },
                            // {
                            //     key: 'Vorlage',
                            //     formControl: this.GeneratedDocumentForm.controls.Type,
                            //     options: {
                            //         specialInput: {
                            //             singleSelectSearch: {
                            //                 options: types.map((t) => ({
                            //                     Id: t.Id,
                            //                     optionLabel: t.Name,
                            //                 })),
                            //                 compareOptions: (a, b) => a.Id === b.Id,
                            //             },
                            //         },
                            //     },
                            // } as IDetailListTemplateDataProperty<{ Id: number }>,
                        ],
                    },
                    {
                        headline: 'Zuordnung',
                        Properties: [
                            {
                                key: 'Kunde',
                                formControl: this.GeneratedDocumentForm.controls.Customer,
                                options: {
                                    specialInput: {
                                        singleSelectSearch: {
                                            options: Object.values(customers).map((t) => ({
                                                Id: t.Id,
                                                optionLabel: t.DisplayName,
                                            })),
                                            compareOptions: (a, b) => a.Id === b.Id,
                                        },
                                    },
                                },
                            } as IDetailListTemplateDataProperty<{ Id: number }>,
                            {
                                key: commissionLabel,
                                formControl: this.GeneratedDocumentForm.controls.Commission,
                                options: {
                                    specialInput: {
                                        singleSelectSearch: {
                                            options: Object.values(commissions).map((t) => ({
                                                Id: t.Id,
                                                optionLabel: t.DisplayName,
                                            })),
                                            compareOptions: (a, b) => a.Id === b.Id,
                                        },
                                    },
                                },
                            } as IDetailListTemplateDataProperty<{ Id: number }>,

                            {
                                key: 'Ressource',
                                formControl: this.GeneratedDocumentForm.controls.Resource,
                                options: {
                                    specialInput: {
                                        singleSelectSearch: {
                                            options: Object.values(resources).map((t) => ({
                                                Id: t.Id,
                                                optionLabel: t.DisplayName,
                                            })),
                                            compareOptions: (a, b) => a.Id === b.Id,
                                        },
                                    },
                                }
                            } as IDetailListTemplateDataProperty<{ Id: number }>,
                        ],
                    },
                ];
            }),
        );
        this.subs.push(
            this.GeneratedDocumentForm.controls.State.valueChanges.subscribe(() => {
                this.SaveClick();
            }),
            combineLatest([
                this.GeneratedDoc$.pipe(
                    distinctUntilChanged((a, b) => a.CommissionId === b.CommissionId && a.CustomerId === b.CustomerId && a.GeneratedDocumentsTypeId === b.GeneratedDocumentsTypeId && a.Name === b.Name && a.State === b.State),
                ),
                this.store.select(getGeneratedDocumentsTypes),
                this.store.select(getCustomerDictionary),
                this.store.select(getCommissionDictionary),
                this.store.select(getResourceDictionary)
            ]).subscribe(([doc, types, customers, commissions, resources]) => {
                const type = doc.GeneratedDocumentsTypeId && types.find((t) => t.Id === doc.GeneratedDocumentsTypeId);
                const customer = doc.CustomerId && customers[doc.CustomerId];
                const commission = doc.CommissionId && commissions[doc.CommissionId];
                const resource = doc.ResourceId && resources[doc.ResourceId];
                this.GeneratedDocumentForm.reset(
                    {
                        Name: doc.Name,
                        State: doc.State,
                        Type: type ? { Id: type.Id, optionLabel: type.Name } : null,
                        Customer: customer ? { Id: customer.Id, optionLabel: customer.DisplayName } : null,
                        Commission: commission ? { Id: commission.Id, optionLabel: commission.DisplayName } : null,
                        Resource: resource ? { Id: resource.Id, optionLabel: resource.DisplayName } : null,
                    },
                    { emitEvent: false },
                );
                this.GeneratedDocumentForm.markAsPristine();
            }),
            this.PreventEditing$.subscribe((disable) => {
                if (disable) {
                    this.CustomFields.forEach((c) => {
                        c.formControl.disable();
                        if (this.editorComponent?.DocumentEditorContainer?.documentEditor?.editor.documentHelper.isDocumentProtected === false) {
                            this.RestrictEditingClick();
                        }
                    });
                }
            }),
            combineLatest([this.GeneratedDoc$, this.EditorCreated$.pipe(filter((created) => created))]).subscribe(([doc]) => {
                this.editorComponent.Open(doc.GeneratedFile || '');
                this.EditorContentDirty$.next(false);
                this.editorComponent.DocumentEditorContainer.documentEditor.showRestrictEditingPane(false);
                if (this.EditFormFields) {
                    this.editorComponent.DocumentEditorContainer.documentEditor.editor.enforceProtection('', 'FormFieldsOnly');
                    this.editorComponent.setMobileContentEditable(false);
                    setTimeout(() => {
                        this.editorComponent.DocumentEditorContainer.documentEditor.fitPage('FitPageWidth');
                    }, 50);
                }
                this.initForms();
            }),
        );
    }
    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): boolean {
        const pendingChanges = this.GeneratedDocumentForm.dirty || this.EditorContentDirty$.value;
        return !pendingChanges;
    }

    ngOnDestroy(): void {
        this.editorComponent?.DocumentEditorContainer?.documentEditor?.editor?.stopProtection('');
        this.subs.forEach((s) => s.unsubscribe());
        this.formFieldSubscriptions.forEach((s) => s.unsubscribe());
    }
    initForms() {
        firstValueFrom(this.BS.TouchQuery).then((touch) => {
            const forms = this.editorComponent.DocumentEditorContainer.documentEditor.getFormFieldNames();
            this.formFieldDataBuffer = this.editorComponent.DocumentEditorContainer.documentEditor.exportFormData();

            this.formFieldSubscriptions.forEach((s) => s.unsubscribe());
            this.formFieldSubscriptions.splice(0, this.formFieldSubscriptions.length);
            this.CustomFields = forms.map((fName) => {
                const props = this.editorComponent.DocumentEditorContainer.documentEditor.getFormFieldInfo(fName);
                const name = props.name;
                const hint = props.helpText;

                if ((props as DropDownFormFieldInfo).dropdownItems) {
                    const propsTyped: DropDownFormFieldInfo = props as DropDownFormFieldInfo;
                    const data = this.formFieldDataBuffer.find((d) => d.fieldName === fName);
                    const formControl = new FormControl<number>(isNotNullOrUndefined(data.value) ? (data.value as number) : null);
                    if (!props.enabled) {
                        formControl.disable();
                    }
                    return {
                        name,
                        hint,
                        value: '',
                        options: propsTyped.dropdownItems.map((item, i) => ({ value: i, label: item })),
                        type: CustomFieldTypes.select,
                        formControl,
                    } as CustomFields & { formControl: FormControl<any> };
                } else if ((props as TextFormFieldInfo).type) {
                    const propsTyped: TextFormFieldInfo = props as TextFormFieldInfo;
                    const data = this.formFieldDataBuffer.find((d) => d.fieldName === fName);

                    let type: CustomFieldTypes = null;
                    let formControl: FormControl;
                    switch (propsTyped.type) {
                        case 'Date': {
                            if (propsTyped.format.match(timeRegex) && !propsTyped.format.match(dateRegex)) {
                                type = CustomFieldTypes.time;
                                const parsedDate =
                                    (data.value && syncfusionDateParser(data.value as string, propsTyped.format)) || (propsTyped.defaultValue ? syncfusionDateParser(propsTyped.defaultValue as string, propsTyped.format) : null);
                                formControl = new FormControl<string>(parsedDate ? parsedDate.format('HH:mm') : null, { updateOn: 'blur' });
                            } else if (propsTyped.format.match(dateRegex) && !propsTyped.format.match(timeRegex)) {
                                type = CustomFieldTypes.date;
                                formControl = new FormControl<Moment>(
                                    (data.value && syncfusionDateParser(data.value as string, propsTyped.format)) || (propsTyped.defaultValue ? syncfusionDateParser(propsTyped.defaultValue as string, propsTyped.format) : null),
                                );
                            } else {
                                throw new Error('Date Format must be either only date or only time');
                            }
                            break;
                        }
                        case 'Text': {
                            type = CustomFieldTypes.text;
                            formControl = new FormControl<string>((data.value as string)?.replace(syncfusionNewlineRegex, '\n') || propsTyped.defaultValue || null, { updateOn: touch ? 'blur' : 'change' });
                            break;
                        }
                        case 'Number': {
                            type = CustomFieldTypes.number;
                            formControl = new FormControl<number>(data.value != null && data.value !== '' ? +data.value : propsTyped.defaultValue != null && propsTyped.defaultValue !== '' ? +propsTyped.defaultValue : null, {
                                updateOn: touch ? 'blur' : 'change',
                            });
                            break;
                        }
                        default: {
                            console.warn('Unknown type', propsTyped.type, {props,name});
                            type = CustomFieldTypes.text;
                            formControl = new FormControl<string>((data.value as string)?.replace(syncfusionNewlineRegex, '\n') || propsTyped.defaultValue || null, { updateOn: touch ? 'blur' : 'change' });
                            formControl.disable();
                            break;
                        }
                    }
                    if (propsTyped.maxLength) {
                        formControl.addValidators(Validators.maxLength(propsTyped.maxLength));
                    }
                    if (!props.enabled) {
                        formControl.disable();
                    }
                    return {
                        name,
                        hint,
                        value: data.value as any,
                        type,
                        formControl,
                    } as CustomFields & { formControl: FormControl<any> };
                } else {
                    const propsTyped: CheckBoxFormFieldInfo = props as CheckBoxFormFieldInfo;
                    const data = this.formFieldDataBuffer.find((d) => d.fieldName === fName);
                    const formControl = new FormControl<boolean>(isNotNullOrUndefined(data.value) ? (data.value as boolean) : !!propsTyped.defaultValue);
                    if (!props.enabled) {
                        formControl.disable();
                    }
                    return {
                        name,
                        hint,
                        value: data.value as boolean,
                        type: CustomFieldTypes.checkbox,
                        formControl,
                    } as CustomFields & { formControl: FormControl<any> };
                }
            });
            firstValueFrom(this.PreventEditing$).then((disable) => {
                if (disable) {
                    this.CustomFields.forEach((c) => {
                        c.formControl.disable();
                    });
                }
            });
            this.cdr.detectChanges();
            this.formFieldSubscriptions.push(
                ...this.CustomFields.map((c) =>
                    c.formControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
                        /*todo: only submit this form value to the editor*/
                        this.SubmitFormFields();
                    }),
                ),
            );
            this.FormsInitialised = true;
        });
    }
    editFormControlClick() {
        this.EditFormFields = !this.EditFormFields;
        if (this.EditFormFields) {
            this.initForms();
        }
        setTimeout(() => this.editorComponent.DocumentEditorContainer.resize(), 10);
    }
    SubmitFormFields() {
        this.editorComponent.DocumentEditorContainer.documentEditor.importFormData(
            this.CustomFields.filter((cf) => cf.formControl.dirty && cf.formControl.valid).map((cf) => {
                const value =
                    cf.type === CustomFieldTypes.date && cf.formControl.value
                        ? syncfusionDateFormatter(cf.formControl.value as Moment, (this.editorComponent.DocumentEditorContainer.documentEditor.getFormFieldInfo(cf.name) as TextFormFieldInfo).format)
                        : cf.type === CustomFieldTypes.time && cf.formControl.value
                        ? syncfusionDateFormatter(moment(cf.formControl.value as string, 'HH:mm'), (this.editorComponent.DocumentEditorContainer.documentEditor.getFormFieldInfo(cf.name) as TextFormFieldInfo).format)
                        : cf.type === CustomFieldTypes.number && cf.formControl.value != null
                        ? cf.formControl.value + ''
                        : cf.type === CustomFieldTypes.textarea || cf.type === CustomFieldTypes.text
                        ? cf.formControl.value?.replace(/\n/g, '\u000b')
                        : cf.formControl.value;
                return {
                    fieldName: cf.name,
                    value,
                };
            }),
        );
    }
    EditorContentChange(sfdt: string) {
        if (!this.EditorContentDirty$.value) {
            this.EditorContentDirty$.next(true);
        }
        this.handleEditorChanges$.next();
    }

    private getFileUrl = (file, token) => this.api.GetUrl(file.GetLastVersion().GetDownloadLink(token), 'file');

    public SendEmailClick() {
        if (this.canDeactivate()) {
            firstValueFrom(this.GeneratedDoc$).then((doc) => this.openEmailDialog(doc));
        } else {
            firstValueFrom(this.appDialog.OpenConfirmationDialog({
                heading: 'Ungespeicherte Änderungen',
                paragraph: 'Es gibt ungespeicherte Änderungen, möchten Sie diese Speichern bevor das Dokument versendet wird ?',
                noButton: 'nein',
                yesButton: 'ja',
            })).then(res => {
                if (res[0]) {
                    return this.SaveClick().then(action => {
                        if (action.type === GeneratedDocumentsActionTypes.UpdateOne.type) {
                            return action.Payload
                        } else {
                            throw new Error('Could not save document');
                        }
                    })
                } else {
                    return firstValueFrom(this.GeneratedDoc$);
                }
            }).then((doc) => this.openEmailDialog(doc));
        }
    }
    private openEmailDialog(doc: GeneratedDocumentsEntity) {

            this.dialog.open<EmailEditorComponent, EmailEditorComponentDialogData>(EmailEditorComponent, {
                ...EmailEditorComponent.DefaultConfig,
                data: {
                    EmailHtml: `
                                <p>${doc.Name}</p>
                                `,
                    CustomerId: doc.CustomerId,
                    CommissionId: doc.CommissionId,
                    GeneratedFileAttachmntFileIds: [doc.Id],
                },
            });

    }
    public DeleteClick() {
        this.dialog
            .open<AppConfirmationDialogComponent, AppConfirmationDialogData>(AppConfirmationDialogComponent, {
                data: {
                    paragraph: 'Ausgewählte Berichte wirklich löschen?',
                    styleDelete: true,
                },
            })
            .afterClosed()
            .subscribe(([res]) => {
                if (res) {
                    firstValueFrom(this.actions$.pipe(ofType(GeneratedDocumentsActionTypes.RemoveOne, BaseActionTypes.ErrorAction))).then((action) => {
                        if (action.type === GeneratedDocumentsActionTypes.RemoveOne.type) {
                            if (this.Dialogdata) {
                                // this.dialogRef.close({ deleted: true });
                            } else {
                                this.router.navigate(['../../'], { relativeTo: this.route });
                            }
                        }
                    });
                    this.store.dispatch(GeneratedDocumentsActionTypes.DeleteGeneratedDocuments({ Payload: this.GeneratedDocumentId$.value }));
                }
                return;
            });
    }
    public SaveClick(uploadPdf = false): Promise<({Payload: {ToasterMessage: string, Err?: any, Caught?: any}} & TypedAction<"error">) | ({Payload: GeneratedDocumentsEntity} & TypedAction<"data/GeneratedDocuments/updateOne">)> {
        this.LoadingService.startLoading('saveDocument', { dialogMessage: 'Ihr Bericht wird gespeichert!' });
        const isReady = firstValueFrom(this.GeneratedDoc$).then((doc) => {
            this.store.dispatch(
                GeneratedDocumentsActionTypes.ModifyGeneratedDocuments({
                    Payload: {
                        id: this.GeneratedDocumentId$.value,
                        name: this.GeneratedDocumentForm.value.Name || (uploadPdf && 'Bericht') || null, // cant generate pdf without name
                        generatedDocumentsTypeId: this.GeneratedDocumentForm.value.Type?.Id || null,
                        customerId: this.GeneratedDocumentForm.value.Customer?.Id || null,
                        commissionId: this.GeneratedDocumentForm.value.Commission?.Id || null,
                        resourceId: this.GeneratedDocumentForm.value.Resource?.Id || null,
                        generatedFile: this.editorComponent.DocumentEditorContainer?.documentEditor.serialize(),
                        state: this.GeneratedDocumentForm.value.State ? getGQLDocumentStateFromDocumentState(this.GeneratedDocumentForm.value.State) : null,
                        uploadPdf: uploadPdf || (!!doc.DocumentIdFromPdf && !compareSFDTContent(this.editorComponent.DocumentEditorContainer?.documentEditor.serialize(), doc.GeneratedFile)),
                    },
                }),
            );
            return firstValueFrom(this.actions$.pipe(ofType(GeneratedDocumentsActionTypes.UpdateOne, BaseActionTypes.ErrorAction)));
        });

        isReady
            .then((action) => {
                if (action.type === GeneratedDocumentsActionTypes.UpdateOne.type) {
                    this.GeneratedDocumentForm.markAsPristine();
                }
            })
            .finally(() => {
                this.LoadingService.endLoading('saveDocument');
            });
        return isReady;
    }
    public RestrictEditingClick() {
        if (this.editorComponent?.DocumentEditorContainer?.documentEditor?.editor.documentHelper.isDocumentProtected) {
            this.editorComponent.DocumentEditorContainer.documentEditor.editor.stopProtection('');
            this.editorComponent.DocumentEditorContainer.enableToolbar = true;
            this.editorComponent.setMobileContentEditable(true);
        } else if (this.editorComponent.DocumentEditorContainer) {
            this.editorComponent.DocumentEditorContainer.documentEditor?.editor?.enforceProtection('', 'FormFieldsOnly');
            this.editorComponent.DocumentEditorContainer.enableToolbar = false;
            this.editorComponent.DocumentEditorContainer.documentEditor?.showRestrictEditingPane(false);
            this.editorComponent.DocumentEditorContainer.showHidePropertiesPane(false);
            this.editorComponent.setMobileContentEditable(false);
        }
    }
    public SignatureClick() {
        this.LoadingService.startLoading('create-pdf-for-signing', { dialogMessage: 'Ihre PDF wird erstellt!' });
        firstValueFrom(this.PdfFile$)
            .then((pdf) => {
                if (!pdf || this.GeneratedDocumentForm.dirty || this.EditorContentDirty$.value || this.GeneratedDocumentForm.value.State !== DocumentState.DocumentStateSigned) {
                    if (this.GeneratedDocumentForm.value.State !== DocumentState.DocumentStateSigned) {
                        this.GeneratedDocumentForm.controls.State.setValue(DocumentState.DocumentStateSigned, { emitEvent: false });
                    }
                    return this.SaveClick(!pdf || this.GeneratedDocumentForm.dirty || this.EditorContentDirty$.value).then((action) => {
                        if (action.type === GeneratedDocumentsActionTypes.UpdateOne.type) {
                            return firstValueFrom(this.PdfFile$.pipe(filter(isNotNullOrUndefined)));
                        } else {
                            throw new Error('Could not save document');
                        }
                    });
                } else {
                    return firstValueFrom(of(pdf));
                }
            })
            .then((file) => {
                const dialogRef = this.dialog.open<FullscreenDialogComponent, FullscreenDialogData>(FullscreenDialogComponent, {
                    ...FullscreenDialogComponentConfig,
                    data: { file, version: file.GetLastVersion(), signingMode: true },
                });
                dialogRef.backdropClick().subscribe(async () => {
                        await dialogRef.componentInstance.Close();
                        dialogRef.close();
                    }
                );
            })
            .finally(() => {
                this.LoadingService.endLoading('create-pdf-for-signing');
            });
    }
    public FileUploadClick() {
        this.editorComponent.OpenFileUpload();
    }
    public DownloadDocxClick() {
        firstValueFrom(this.GeneratedDoc$).then((doc) => {
            this.editorComponent?.Save(doc.Name, 'Docx');
        });
    }
    public DownloadPdfClick() {
        this.LoadingService.startLoading('create-pdf', { dialogMessage: 'Ihre PDF wird erstellt!' });
        firstValueFrom(this.PdfFile$)
            .then((pdf) => {
                if (!pdf) {
                    return this.SaveClick(true).then((action) => {
                        if (action.type === GeneratedDocumentsActionTypes.UpdateOne.type) {
                            return firstValueFrom(this.PdfFile$.pipe(filter(isNotNullOrUndefined)));
                        } else {
                            throw new Error('Could not save document');
                        }
                    });
                } else {
                    return firstValueFrom(of(pdf));
                }
            })
            .then((file) => {
                firstValueFrom(this.store.select(getToken)).then((token) => {
                    programmaticDownloadAnchor(this.getFileUrl(file, token), file.Name);
                });
            })
            .finally(() => {
                this.LoadingService.endLoading('create-pdf');
            });
    }
    public CloseDialog() {
        if (this.canDeactivate() || confirm(PENDING_CHANGES_DEFAULT_MESSAGE)) {
            this.dialogRef.close();
        }
    }
    public OpenFilePreviewClick(fileId: number) {
        this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: { fileId },
        });
    }
    selectedTabChange(event: MatTabChangeEvent) {
        if (event.index === 1) {
            this.editorComponent?.DocumentEditorContainer?.resize();
            this.editorComponent?.DocumentEditorContainer?.documentEditor?.fitPage('FitPageWidth');
            this.burgerButtonWrapperComponent?.ManualReRender$.next();
        }
    }
}
