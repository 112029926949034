import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, take, tap } from 'rxjs/operators';
import { State } from '../State';
import { DaveActions } from '../State/actions/actions';
import { GeneratedDocumentsActionTypes } from '../State/actions/generatedDocuments.actions';
import { GeneratedDocumentsResolver } from './generatedDocuments.resolver';
import { getToken } from '../State/selectors/base.selectors';

@Injectable({
    providedIn: 'root',
})
export class GeneratedDocumentWithFileByIdResolver implements Resolve<null> {
    constructor(private store: Store<State>, private generatedDocumentsResolver: GeneratedDocumentsResolver, private actions$: Actions<DaveActions>) {}

    resolve(route: ActivatedRouteSnapshot) {
        const id = route.params?.documentId;
        this.store.select(getToken).pipe(
                filter(value => !!value),
                take(1),
                tap(() => {
                    this.store.dispatch(GeneratedDocumentsActionTypes.LoadOne({ id, withFile: true }));
                }),
            )
            .subscribe();

        return this.actions$.pipe(
            ofType(GeneratedDocumentsActionTypes.UpdateOne),
            map(() => null),
            take(1),
        );
    }
}
