import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageEditorModule } from '../dave-dms-module/dave-dms/image-editor/image-editor.module';
import { PdfEditorModule } from '../dave-dms-module/dave-dms/pdf-editor/pdf-editor.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { FullscreenDialogComponent } from './components/fullscreen-dialog.component';

@NgModule({
    declarations: [FullscreenDialogComponent],
    imports: [CommonModule, FontAwesomeModule, ImageEditorModule, PdfEditorModule, MatButtonModule, MatTooltipModule, AppButtonModule],
    exports: [
        FullscreenDialogComponent,
    ],
})
export class DaveFullscreenDialogModule {}
