import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PreviewModule } from '../dave-dms-module/dave-dms/preview/preview.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { DaveFilePreviewComponent } from './components/dave-file-preview/dave-file-preview.component';

@NgModule({
    declarations: [DaveFilePreviewComponent],
    imports: [CommonModule, PreviewModule, FontAwesomeModule, AppButtonModule, MatDialogModule],
    exports: [DaveFilePreviewComponent],
})
export class DaveFilePreviewDialogModule {}
