import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MaterialDataService {
    constructor() {}
    getDescription$(materialId: number) {
        alert('getDescription$ not implemented')
        return of('test $');
    }
}
