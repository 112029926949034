import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { ClockInActionTypes } from '../State/actions/clock-in.actions';
import { getClockInLatestUpdatedAt, getClockInsFetched } from '../State/selectors/clock-in.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class ClockInResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getClockInsFetched, getClockInLatestUpdatedAt, ClockInActionTypes.Load, store);
    }
}
