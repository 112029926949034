import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Store} from '@ngrx/store';
import {filter, take, tap} from 'rxjs/operators';
import { VarianteTypeEntity } from '../entities/varianteType.entity';
import { State } from '../State';
import { getVarianteTypes } from '../State/selectors/variante-types.selectors';
import { ResolverLoadVarianteTypes } from '../State/actions/resolver.actions';

@Injectable({
    providedIn: 'root'
})
export class VarianteTypeResolver implements Resolve<VarianteTypeEntity[]> {
    constructor(private store: Store<State>) {
    }

    resolve() {
        const value$ = this.store.select(getVarianteTypes);
        value$
            .pipe(
                filter(value => !value),
                take(1),
                tap(() => {
                    if (localStorage.getItem('token') != null) {
                        this.store.dispatch(new ResolverLoadVarianteTypes());
                    }}
                )
            )
            .subscribe();

        return value$.pipe(
            filter(value => !!value),
            take(1)
        );
    }
}
