import { Color } from '@angular-material-components/color-picker';
import { formatDate } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ResolveData } from '@angular/router';
import { IconProp, Transform } from '@fortawesome/fontawesome-svg-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    ActionEventArgs,
    DragAndDropService,
    DragEventArgs,
    EventRenderedArgs,
    EventSettingsModel,
    GroupModel,
    NavigatingEventArgs,
    PopupOpenEventArgs,
    ResizeEventArgs,
    ResizeService,
    ScheduleComponent,
    TimelineMonthService,
    TimelineViewsService,
    TimelineYearService,
    ToolbarActionArgs,
    View,
    ViewsData,
} from '@syncfusion/ej2-angular-schedule';
import { loadCldr } from '@syncfusion/ej2-base';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, interval, Observable, of, Subject, Subscription, takeWhile } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    share,
    shareReplay,
    skip,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom
} from "rxjs/operators";
import * as n4 from '../../../../../node_modules/cldr-data/main/de/ca-gregorian.json';
import * as n1 from '../../../../../node_modules/cldr-data/main/de/currencies.json';
import * as n3 from '../../../../../node_modules/cldr-data/main/de/numbers.json';
import * as n2 from '../../../../../node_modules/cldr-data/main/de/timeZoneNames.json';
import * as s from '../../../../../node_modules/cldr-data/supplemental/currencyData.json';
import * as s2 from '../../../../../node_modules/cldr-data/supplemental/numberingSystems.json';
import { ActivityEntity } from '../../../dave-data-module/entities/activity.entity';
import { ColorEntity, ColorEntityFromColorType, ColorEntityFromString } from '../../../dave-data-module/entities/color.entity';
import { VacationTypeEnum, VacationTypeEnumNameMap } from '../../../dave-data-module/entities/employee-to-vacation.entity';
import { EntityRoleSlugEnum, EntityTypesEnum } from '../../../dave-data-module/entities/entity-role.entity';
import { AmountTypeEnumNames, ResourceEntity } from '../../../dave-data-module/entities/resource-dispo/resource.entity';
import { CapacityCalculationType } from '../../../dave-data-module/graphql-types';
import { ActivityResolver } from '../../../dave-data-module/guards/activity.resolver';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { EmployeeToVacationResolver } from '../../../dave-data-module/guards/employee-to-vacation.resolver';
import { EmployeeResolver } from '../../../dave-data-module/guards/employee.resolver';
import { EntityRoleResolver } from '../../../dave-data-module/guards/entity-role.resolver';
import { JobSpecificationResolver } from '../../../dave-data-module/guards/job-specification.resolver';
import { EmployeeResourceScheduleResolver } from '../../../dave-data-module/guards/resource-dispo/employee-resource-scheduler.resolver';
import { ResourceScheduleResolver } from '../../../dave-data-module/guards/resource-dispo/resource-scheduler.resolver';
import { ResourceResolver } from '../../../dave-data-module/guards/resource-dispo/resource.resolver';
import { StatusFromBackofficeResolver } from '../../../dave-data-module/guards/statusFromBackoffice.resolver';
import { User2CommissionShiftResolver } from '../../../dave-data-module/guards/user-to-commission-shift.resolver';
import { UserToCommissionResolver } from '../../../dave-data-module/guards/user-to-commission.resolver';
import { BackendDate, FrontendDate, FrontendDateTimestamp } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { ActivityActions } from '../../../dave-data-module/State/actions/activity.actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { EmployeeToVacationActionTypes } from '../../../dave-data-module/State/actions/employee-to-vacation.actions';
import { EmployeeResourceScheduleActionTypes } from '../../../dave-data-module/State/actions/resource-dispo/employee-resource-schedule.actions';
import { ResourceScheduleActionTypes } from '../../../dave-data-module/State/actions/resource-dispo/resource-schedule.actions';
import { UserToCommissionShiftActionTypes } from '../../../dave-data-module/State/actions/user2CommissionShift.action';
import { ACTIVITY_FEATURE_KEY } from '../../../dave-data-module/State/reducers/activity.reducer';
import { commissionsFeatureKey } from '../../../dave-data-module/State/reducers/commission.reducer';
import { employeesFeatureKey } from '../../../dave-data-module/State/reducers/employees.reducer';
import { employeeToVacationFeatureKey } from '../../../dave-data-module/State/reducers/employeeToVacation.reducer';
import { ENTITY_ROLE_KEY } from '../../../dave-data-module/State/reducers/entity-role.reducer';
import { JOB_SPECIFICATION_KEY } from '../../../dave-data-module/State/reducers/job-specification.reducer';
import { EMPLOYEE_RESOURCE_SCHEDULE_KEY } from '../../../dave-data-module/State/reducers/resource-dispo/employee-resource-schedule.reducer';
import { RESOURCE_SCHEDULE_KEY } from '../../../dave-data-module/State/reducers/resource-dispo/resource-schedule.reducer';
import { RESOURCE_KEY } from '../../../dave-data-module/State/reducers/resource-dispo/resource.reducer';
import { StatusFromBackofficeFeatureKey } from '../../../dave-data-module/State/reducers/statusFromBackoffice.reducer';
import { USER_TO_COMMISSION_KEY } from '../../../dave-data-module/State/reducers/user-to-commission.reducer';
import { user2CommissionShiftKey } from '../../../dave-data-module/State/reducers/user2CommissionShift.reducer';
import { getActivities, getActivityDictionary } from '../../../dave-data-module/State/selectors/activity.selector';
import { getCommissionById, getCommissionsActive, getCommissionSorted } from '../../../dave-data-module/State/selectors/commission.selector';
import { getEmployeeToVacations } from '../../../dave-data-module/State/selectors/employee-to-vacation.selectors';
import { getEmployeeDictionary, getEmployees } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getEntityRole, getEntityRoleFetched } from '../../../dave-data-module/State/selectors/entity-role.selector';
import { getJobSpecification, getJobSpecificationDictionary, getJobSpecificationFetched } from '../../../dave-data-module/State/selectors/job-specification.selector';
import { getEmployeeResourceScheduleById, getEmployeeResourceSchedules } from '../../../dave-data-module/State/selectors/resource-dispo/employee-resource-schedule.selectors';
import { getResourceScheduleById, getResourceSchedules } from '../../../dave-data-module/State/selectors/resource-dispo/resource-schedule.selectors';
import { getResourceDictionary, getResources } from '../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import { getUserToCommissionFetched, getUserToCommissions } from '../../../dave-data-module/State/selectors/user-to-commission.selector';
import { getUserToCommissionShiftById, getUserToCommissionShifts } from '../../../dave-data-module/State/selectors/users-to-commission-shift.selectors';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { NewVacationComponent, NewVacationComponentDialogData } from '../../../dave-employee-administration-module/components/vacation/new-vacation/new-vacation.component';
import { SelectUserCommissionDialogComponent, SelectUserCommissionDialogComponentDialogData } from '../../../dave-select-user/components/select-user-dialogs/select-user-commission-dialog/select-user-commission-dialog.component';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { FilterOption, FILTER_TYPE_SEARCH_MULTI_SELECT, IFilterTypeSearchMultiSelectValue } from '../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';

import { JobSpecificationEntity } from '../../../dave-data-module/entities/job-specification.entity';
import { IDetailListTemplateDataProperty } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { DetailListDialogReturn, DetailListTemplateDialogComponent, DetailListTemplateDialogData } from '../../../detail-list-template-dialog/components/detail-list-template-dialog.component';
import { isNotNullOrUndefined, sameDay, SearchQueriesDebounceTime } from '../../../helper/helper';
import { AbsentMetaIcon, AllCommissionMeta, CalendarPageMeta, CommissionMeta, ConstructionDiaryIcon, ResourcePageMeta, ShiftPlanPageMeta, UserAdministrationMeta } from '../../../helper/page-metadata';
import { SelectResourcePopupComponent, SelectResourcePopupComponentDialogData } from '../../../resource-dispo/components/select-resource-popup/select-resource-popup.component';
import { CustomLabelService } from '../../../services/custom-label.service';
import { FilterTypes } from '../../../services/default-filter.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { desktopHeaderButtons, getVacationColorEntity, ISchedulerEvent, SchedulerEventColors, ShiftplanViews, viewButton3Month, viewButtonMonth } from '../scheduler.helper';
import { Permission } from '../../../helper/permission.helper';

enum EntityType {
    EmployeeToVacationEntity,
    ResourceScheduleEntity,
    EmployeeResourceScheduleEntity,
    User2CommissionShiftEntity,
}
function decodeAppointmentId(id: string): { id: number; entityType: EntityType } {
    return { id: +id.split('|')[0], entityType: +id.split('|')[1] };
}
function encodeAppointmentId(id: number, entityType: EntityType): string {
    return id + '|' + entityType;
}
enum ScheduleType {
    ResourceSchedule,
    EmployeeResourceSchedule,
    UserToCommissionShift,
}

/**
 * Muss JSON.stringify() aushalten , da der scheduler das beim drag and drop versucht
 */
interface ResourcePlanEventBase extends ISchedulerEvent {
    Id: string; // EntityId|EntityType
    CategoryColor?: ColorEntity;
    isReadonly: boolean;
    ResourceDetails$?: () => Observable<{ Id: number | VacationTypeEnum; Text: string }>;
    quickViewDetails$?: () => Observable<{ icon?: IconProp; text: string }[]>;
}
interface U2CSEventType extends ResourcePlanEventBase {
    CommissionId: number;
    Type: ScheduleType.UserToCommissionShift;
}
interface RSEventType extends ResourcePlanEventBase {
    CommissionId: number;
    Type: ScheduleType.ResourceSchedule;
}
interface EmployeeRSEventType extends ResourcePlanEventBase {
    CommissionId: number;
    Type: ScheduleType.EmployeeResourceSchedule;
}
interface VacationEventData extends ResourcePlanEventBase {
    CommissionId: VacationTypeEnum;
    Type: VacationTypeEnum;
}
export type ResourcePlanEvents = VacationEventData | EmployeeRSEventType | RSEventType | U2CSEventType;
export interface ResourcePlanFilter {
    [FilterTypes.CommissionId]: IFilterTypeSearchMultiSelectValue[];
    [FilterTypes.JobSpecificationIds]: IFilterTypeSearchMultiSelectValue[];
    [FilterTypes.ResourceIds]: IFilterTypeSearchMultiSelectValue[];
}
interface CapacityRow {
    icon: IconProp;
    label: string;
    cell: (time: number) => { textContent: string; class?: string };
}
@Component({
    selector: 'app-resource-plan',
    templateUrl: './resource-plan.component.html',
    styleUrls: ['./resource-plan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TimelineViewsService, TimelineMonthService, TimelineYearService, DragAndDropService, ResizeService],
})
export class ResourcePlanComponent implements OnInit, OnDestroy {
    @Input() CommissionId: number;
    @Input() HeaderColorful = true;
    @Input() ShowShiftplanButton = true;
    @Input() ShowAddResourceButton = false;
    @Output() AddResourceButtonClick = new EventEmitter<void>();
    @Input() AdditionalButtons: {
        icon: IconProp;
        onClick: (clickEvent) => void;
        tooltip: string;
        iconTransform: string | Transform;
    }[];
    public static readonly RequiredResolvers: ResolveData = {
        [RESOURCE_SCHEDULE_KEY]: ResourceScheduleResolver,
        [RESOURCE_KEY]: ResourceResolver,
        [commissionsFeatureKey]: CommissionResolver,
        [employeesFeatureKey]: EmployeeResolver,
        [employeeToVacationFeatureKey]: EmployeeToVacationResolver,
        [StatusFromBackofficeFeatureKey]: StatusFromBackofficeResolver,
        [EMPLOYEE_RESOURCE_SCHEDULE_KEY]: EmployeeResourceScheduleResolver,
        [user2CommissionShiftKey]: User2CommissionShiftResolver,
        [USER_TO_COMMISSION_KEY]: UserToCommissionResolver,
        [JOB_SPECIFICATION_KEY]: JobSpecificationResolver,
        [ENTITY_ROLE_KEY]: EntityRoleResolver,
        [ACTIVITY_FEATURE_KEY]: ActivityResolver,
    };
    private resizingClonePrevEndDate: Date | null = null;
    private resizingClonePrevStartDate: Date | null = null;
    private resizingCloneWidth = 0;
    private resizeObserver = new ResizeObserver((entries) => {
        if (entries?.length === 1) {
            this.resizingCloneWidth = entries[0].target.clientWidth;
        }
    });
    PageMeta = CalendarPageMeta;
    ShiftPlanMeta = ShiftPlanPageMeta;
    CommissionMeta = CommissionMeta;
    private resizeStop$: Subject<void> = new Subject<void>();
    private dataBound$: Subject<void> = new Subject<void>();
    public Rows: CapacityRow[] = [
        {
            icon: ConstructionDiaryIcon,
            label: 'Bedarf MA',
            cell: (time: number) => {
                const content1 = this.CapacityCache.get(time)?.planed;
                const content2 = this.CapacityCache.get(time)?.assigned;
                return { textContent: (content1 > content2 ? content1 : content2) + '', class: null };
            },
        },
        {
            icon: AbsentMetaIcon,
            label: 'Abwesend',
            cell: (time: number) => ({ textContent: this.CapacityCache.get(time)?.absent + '', class: null }),
        },
        {
            icon: 'people-roof',
            label: 'Kapazität',
            cell: (time: number) => {
                const content = this.CapacityCache.get(time)?.available;
                return { textContent: content + '', class: content < 0 ? 'red' : content > 2 ? 'green' : 'yellow' };
            },
        },
    ];
    // rows * row-height + border-top
    @HostBinding('style.--footer-height')
    public FooterHeightVar = this.Rows.length * 36 + 4;

    @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
    @ViewChild('loadingTemplate') loadingTemplate: ElementRef;
    @ViewChild('commissionIcon') commissionIconElement: ElementRef;
    @ViewChild('matMenuTrigger') matMenuTriggerElement: ElementRef;

    // @ViewChild('selectJobSpecificationTemplate') selectJobSpecificationTemplate: TemplateRef<any>;
    @ViewChild('jobSpecificationOptionTemplate') jobSpecificationOptionTemplate: TemplateRef<any>;
    @ViewChild('activityOptionTemplate') activityOptionTemplate: TemplateRef<any>;
    public JobSpecifications$ = this.store.select(getJobSpecification);

    public SelectedDate: Date = new Date();
    public Group: GroupModel = {
        enableCompactView: false,
        // byGroupID: false,
        resources: ['Commission'],
    };
    public CapacityCache: Map<number, CapacityCalculationType> = new Map<number, CapacityCalculationType>();
    private pendingCapacityCache: string[] = [];
    public FilterValues$: BehaviorSubject<ResourcePlanFilter> = new BehaviorSubject({
        [FilterTypes.CommissionId]: [],
        [FilterTypes.JobSpecificationIds]: [],
        [FilterTypes.ResourceIds]: [],
    });
    public Data$: Observable<EventSettingsModel> = combineLatest([
        combineLatest([
            this.store.select(getResourceSchedules),
            this.store.select(getResourceDictionary),
            this.FilterValues$.pipe(
                distinctUntilChanged((a, b) => a[FilterTypes.ResourceIds].toString() === b[FilterTypes.ResourceIds].toString() && a[FilterTypes.JobSpecificationIds].toString() === b[FilterTypes.JobSpecificationIds].toString()),
                debounceTime(SearchQueriesDebounceTime),
            ),
        ]).pipe(
            map(([resourceSchedules, resources, filter]) =>
                resourceSchedules
                    .filter((v) => !filter || !filter[FilterTypes.ResourceIds] || !filter[FilterTypes.ResourceIds].length || filter[FilterTypes.ResourceIds].some((f) => f.id === v.ResourceId))
                    .map<RSEventType>((rs) => {
                        const res: ResourceEntity = resources[rs.ResourceId];
                        return {
                            Id: encodeAppointmentId(rs.Id, EntityType.ResourceScheduleEntity),
                            Subject: `${rs.Amount}${res.AmountType ? ' ' + AmountTypeEnumNames.get(res.AmountType) : ''} ${res.Name}`,
                            StartTime: rs.StartDate,
                            CommissionId: rs.CommissionId,
                            EndTime: rs.EndDate,
                            IsAllDay: true,
                            CategoryColor: ColorEntityFromString(SchedulerEventColors.resourceTheme.primary),
                            isReadonly: false,
                            Type: ScheduleType.ResourceSchedule,
                            ResourceDetails$: () => this.CommissionCalendarResource$.pipe(map((a) => a.find((x) => x.Id === rs.CommissionId))),
                        };
                    }),
            ),
        ),
        combineLatest([
            this.store.select(getUser),
            this.PS.Has(Permission.ChangeVacation),
            this.store.select(getEmployeeToVacations),
            this.store.select(getEmployeeDictionary),
            this.FilterValues$.pipe(
                distinctUntilChanged((a, b) => a[FilterTypes.ResourceIds].toString() === b[FilterTypes.ResourceIds].toString() && a[FilterTypes.JobSpecificationIds].toString() === b[FilterTypes.JobSpecificationIds].toString()),
                debounceTime(SearchQueriesDebounceTime),
            ),
        ]).pipe(
            map(([user, changeVacation, e2v, employeeDictionary, filter]) =>
                e2v
                    .filter((e) =>
                        employeeDictionary[e.EmployeeId]
                            ? !filter ||
                              !filter[FilterTypes.JobSpecificationIds] ||
                              !filter[FilterTypes.JobSpecificationIds].length ||
                              filter[FilterTypes.JobSpecificationIds].some((f) => f.id === employeeDictionary[e.EmployeeId].JobSpecificationId)
                            : false,
                    )
                    .map<VacationEventData>((e2v) => {
                        const employee = employeeDictionary[e2v.EmployeeId];
                        const isAssigned = employee?.UserIds.includes(user.Id);
                        return {
                            Id: encodeAppointmentId(e2v.Id, EntityType.EmployeeToVacationEntity),
                            Type: e2v.Type,
                            CommissionId: e2v.Type,
                            Subject: employeeDictionary[e2v.EmployeeId] ? employeeDictionary[e2v.EmployeeId].DisplayName : '',
                            StartTime: e2v.StartDate,
                            EndTime: e2v.EndDate,
                            IsAllDay: true,
                            CategoryColor: getVacationColorEntity(e2v),
                            isReadonly: !(!employee?.Deleted && (employee?.UserId === user.Id || (changeVacation && isAssigned))),
                            ResourceDetails$: () =>
                                this.CommissionCalendarResource$.pipe(
                                    map((res) => {
                                        return res.find((r) => r.Id === e2v.Type);
                                    }),
                                ),
                        };
                    }),
            ),
        ),
        combineLatest([this.store.select(getEmployeeResourceSchedules), this.store.select(getJobSpecificationDictionary), this.PS.Has(Permission.EditEmployeeresourceSchedule), this.PS.Has(Permission.DeleteEmployeeresourceSchedule)]).pipe(
            map(([employeeResourceSchedules, jobs, canEdit, canDelete]) =>
                employeeResourceSchedules.map<EmployeeRSEventType>((ers) => {
                    const job = ers.JobSpecificationId && jobs[ers.JobSpecificationId];
                    return {
                        Id: encodeAppointmentId(ers.Id, EntityType.EmployeeResourceScheduleEntity),
                        Subject: ers.Amount + ' ' + (ers.Name ? ers.Name : job ? job.Name : 'Mitarbeiter'),
                        StartTime: ers.StartDate,
                        CommissionId: ers.CommissionId,
                        EndTime: ers.EndDate,
                        IsAllDay: true,
                        CategoryColor: ers.Color || (job?.AdditionalData?.color && ColorEntityFromColorType(jobs[ers.JobSpecificationId]?.AdditionalData?.color)) || ColorEntityFromString(SchedulerEventColors.purpleCapacity.primary),
                        isReadonly: !canEdit || !canDelete,
                        Type: ScheduleType.EmployeeResourceSchedule,
                        ResourceDetails$: () => this.CommissionCalendarResource$.pipe(
                            map((a) => a.find((x) => x.Id === ers.CommissionId)),
                        ),
                        quickViewDetails$: () => this.store.select(getActivityDictionary).pipe(
                            map((activities) => {
                                const ret: { icon?: IconProp; text: string }[] = [];
                                if (ers.ActivityIds) {
                                    ret.push({ text: 'Tätigkeiten: ' + ers.ActivityIds.map((id) => activities[id]?.Name).join(', ') });
                                }
                                return ret;
                            }),
                        ),
                    };
                }),
            ),
        ),
        combineLatest([
            this.store.select(getUserToCommissionShifts),
            this.store.select(getEmployees),
            this.store.select(getJobSpecificationDictionary),
            this.FilterValues$.pipe(
                distinctUntilChanged((a, b) => a[FilterTypes.ResourceIds].toString() === b[FilterTypes.ResourceIds].toString() && a[FilterTypes.JobSpecificationIds].toString() === b[FilterTypes.JobSpecificationIds].toString()),
                debounceTime(SearchQueriesDebounceTime),
            ),
            this.PS.Has(Permission.EditRoleCommissionManagement),
            this.PS.Has(Permission.DeleteRoleCommissionManagement),
            this.store.select(getJobSpecificationFetched).pipe(
                filter((f) => f),
                switchMap(() => this.store.select(getJobSpecificationDictionary)),
            ),
        ]).pipe(
            map(([u2cs, employees, jobSpecifications, filter, canEdit, canDelete, jobDictionary]) =>
                u2cs
                    .map((u2c) => ({ u2c, employee: employees.find((e) => e.UserId === u2c.UserId) }))
                    .filter(
                        ({ u2c, employee }) =>
                            !filter || !filter[FilterTypes.JobSpecificationIds] || !filter[FilterTypes.JobSpecificationIds].length || filter[FilterTypes.JobSpecificationIds].some((f) => f.id === employee.JobSpecificationId),
                    )
                    .map<U2CSEventType>(({ u2c, employee }) => {
                        const color = employee?.JobSpecificationId ? jobDictionary[employee.JobSpecificationId]?.AdditionalData?.color : null;
                        const colorEntity = color && ColorEntityFromColorType(color);
                        return {
                            Id: encodeAppointmentId(u2c.Id, EntityType.User2CommissionShiftEntity),
                            Subject: employee ? `${employee.DisplayName}${employee.JobSpecificationId ? ' (' + jobSpecifications[employee.JobSpecificationId].Name + ')' : ''}` : null,
                            StartTime: u2c.StartDate,
                            CommissionId: u2c.CommissionId,
                            EndTime: u2c.EndDate,
                            IsAllDay: true,
                            CategoryColor: colorEntity || ColorEntityFromString(SchedulerEventColors.purple.primary), //SchedulerEventColors.purple,
                            isReadonly: !canEdit || !canDelete,
                            Type: ScheduleType.UserToCommissionShift,
                            ResourceDetails$: () => this.CommissionCalendarResource$.pipe(map((a) => a.find((x) => x.Id === u2c.CommissionId))),
                        };
                    }),
            ),
        ),
    ]).pipe(
        map((events) => {
            return {
                allowDeleting: true,
                dataSource: events.flat(),
            };
        }),
        filter(isNotNullOrUndefined),
        tap(() => {
            this.CapacityCache.clear(); // todo beim bearbeiten nur den nötigen cache löschen
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    @Input() View: ShiftplanViews = 'Timeline3Month';
    @Output() OnViewChange = new EventEmitter<ShiftplanViews>();

    public AdditionalHeaderButtons: { icon: IconProp; routerLink?: string | string[]; onClick?: (clickEvent: Event) => void; tooltip: string }[] = [];
    @ViewChild('scheduleObj') schedule: ScheduleComponent;
    public CommissionCalendarResource$: /*Observable<Record<string, any>[]> */ Observable<{ Id: number | VacationTypeEnum; Text: string }[]> = combineLatest([
        this.store.select(getCommissionSorted).pipe(map((commissions) => commissions.filter((c) => !c.Deleted))),
        this.FilterValues$.pipe(
            distinctUntilChanged((a, b) => a[FilterTypes.CommissionId].toString() === b[FilterTypes.CommissionId].toString()),
            debounceTime(SearchQueriesDebounceTime),
        ),
    ]).pipe(
        map(([commissions, filter]) => {
            return [
                ...Object.values(VacationTypeEnum).map((value) => {
                    return {
                        Id: value,
                        Text: VacationTypeEnumNameMap.get(value),
                    };
                }),
                ...commissions.map((commission) => ({
                    Text: [commission.InterneNummer, commission.Description].filter((v) => !!v).join('\n'),
                    Id: commission.Id,
                    Path: ['/', CommissionMeta.Path, AllCommissionMeta.Path, commission.Id],
                })),
            ].filter((v) => !filter || !filter.commissionId || !filter.commissionId.length || filter.commissionId.some((f) => f.id === v.Id));
        }),
        filter(isNotNullOrUndefined),

        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        map((v) => [...v.map((e) => ({ ...e }))]), // hier muss "deep" copy gemacht werden da der scheduler an den objekten rum bastelt und die dann nicht mehr durchs distinctUntilChanged verglichen werden können
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    public FilterAmount$ = this.FilterValues$.pipe(
        map((val) => {
            let filterAmount = 0;
            for (const [key, value] of Object.entries(val)) {
                if (value && `${value}` !== '-' && (value.length === undefined || value.length)) {
                    filterAmount++;
                }
            }
            return filterAmount;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public FilterSettings$: Observable<FilterOption[]> = combineLatest([this.store.select(getCommissionsActive), this.store.select(getResources), this.store.select(getJobSpecification), this.cls.getMultiple$('Commission')]).pipe(
        map(([commissions, resources, jobSpecifications, commissionLabel]) => {
            const resourceValues: IFilterTypeSearchMultiSelectValue[] = resources.map((r) => ({
                label: r.Name,
                id: r.Id,
            }));

            const commissionValues: IFilterTypeSearchMultiSelectValue[] = commissions.map((c) => ({
                label: c.DisplayName,
                id: c.Id,
            }));

            Object.values(VacationTypeEnum).forEach((t) => {
                commissionValues.push({
                    id: t,
                    label: VacationTypeEnumNameMap.get(t),
                });
            });

            const jobSpecificationValues: IFilterTypeSearchMultiSelectValue[] = jobSpecifications.map((j) => ({
                id: j.Id,
                label: j.Name,
            }));
            return [
                {
                    Name: FilterTypes.CommissionId,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: commissionLabel,
                    Icon: CommissionMeta.Icon,
                    Values: commissionValues,
                },
                {
                    Name: FilterTypes.ResourceIds,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Geräte',
                    Icon: ResourcePageMeta.Icon,
                    Values: resourceValues,
                },
                {
                    Name: FilterTypes.JobSpecificationIds,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Jobbezeichnung',
                    Icon: 'id-card' as IconProp,
                    Values: jobSpecificationValues,
                },
            ];
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    public selectedElement: { CommissionId: number | VacationTypeEnum; startTime: Date; endTime: Date };
    private footerRenderSub: Subscription;
    private breakpointSubscription: Subscription;
    public AddButtons: {
        icon: IconProp;
        label: string;
        onClick: (event) => void;
        disabled$: Observable<boolean>;
    }[] = [
        {
            icon: UserAdministrationMeta.Icon,
            label: 'Mitarbeiter',
            onClick: (e) => {
                this.dialog.open<SelectUserCommissionDialogComponent, SelectUserCommissionDialogComponentDialogData>(SelectUserCommissionDialogComponent, {
                    ...SelectUserCommissionDialogComponent.DefaultConfig,
                    data: {
                        commissionId: +this.selectedElement.CommissionId,
                        preFilledStartDate: this.selectedElement.startTime,
                        preFilledEndDate: this.selectedElement.startTime,
                    },
                });
            },
            disabled$: this.PS.Has(Permission.EditRoleCommissionManagement).pipe(map((v) => !v)),
        },
        {
            icon: ResourcePageMeta.Icon,
            label: 'Gerät',
            onClick: (e) => {
                this.dialog.open<SelectResourcePopupComponent, SelectResourcePopupComponentDialogData>(SelectResourcePopupComponent, {
                    ...SelectResourcePopupComponent.DefaultConfig,
                    data: {
                        CommissionId: +this.selectedElement.CommissionId,
                        startDate: this.selectedElement.startTime,
                        endDate: this.selectedElement.startTime,
                    },
                });
            },
            disabled$: of(false),
        },
        {
            icon: UserAdministrationMeta.Icon,
            label: 'Bedarf',
            onClick: (e) => {
                const ResourceForm = new FormGroup({
                    from: new FormControl<Moment>(moment(this.selectedElement.startTime), Validators.required),
                    to: new FormControl<Moment>(moment(this.selectedElement.startTime), Validators.required),
                    amount: new FormControl<number>(null, Validators.required),
                    jobSpecification: new FormControl<JobSpecificationEntity>(null),
                    color: new FormControl<Color>(null),
                    name: new FormControl<string>(null),
                    activities: new FormControl<ActivityEntity[]>(null),
                });

                this.openEditEmployeeResourceScheduleDialog(ResourceForm, 'Bedarf Anlegen').then((ret) => {
                    if (ret.Action === 'save') {
                        const StartDate = ResourceForm.value.from.toDate();
                        StartDate.setHours(0, 0, 0, 0);
                        const EndDate = ResourceForm.value.to.toDate();
                        EndDate.setHours(23, 59, 59, 999);
                        this.store.dispatch(
                            EmployeeResourceScheduleActionTypes.Create({
                                Payload: {
                                    commissionId: +this.selectedElement.CommissionId,
                                    amount: ResourceForm.value.amount,
                                    endDate: FrontendDate(EndDate),
                                    startDate: FrontendDate(StartDate),
                                    color: ResourceForm.value.color ? { red: ResourceForm.value.color.r, green: ResourceForm.value.color.g, blue: ResourceForm.value.color.b, opacity: ResourceForm.value.color.a } : null,
                                    name: ResourceForm.value.name,
                                    jobSpecificationId: ResourceForm.value.jobSpecification?.Id,
                                    activityIds: ResourceForm.value.activities?.map((a) => a.Id),
                                },
                            }),
                        );
                    }
                });
            },
            disabled$: this.PS.Has(Permission.EditEmployeeresourceSchedule).pipe(map((v) => !v)),
        },
    ];
    private canSeeFooterTable = true;
    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private httpService: HttpService,
        private dialog: MatDialog,
        private appDialog: AppDialogService,
        private componentElement: ElementRef,
        private actions$: Actions,
        public PS: PermissionService,
        public BS: BreakpointObserverService,
        public LS: LocalStorageService,
        protected cls: CustomLabelService,
    ) {
        loadCldr(n1, n2, n3, n4, s, s2);
        this.dataBound$
            .pipe(
                takeUntil(this.onDestroy$),
                takeWhile(() => {
                    const scrollWrapper = this.componentElement.nativeElement.querySelector(`.e-schedule-table.e-outer-table > tbody div.e-content-wrap`);
                    return !scrollWrapper?.scrollLeft;
                }, false),
            )
            .subscribe(() => {
                this.scrollToToday();
            });
        // 'Kapazitätsplanungstabelle: wenn man irgendwo Sachbearbeiter oder Projektleiter hat' https://jira.dave-cc.local/browse/D277-2113
        combineLatest([
            this.store.select(getUserToCommissionFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getUser)),
                switchMap((user) => this.store.select(getUserToCommissions).pipe(map((u2c) => u2c.filter((u) => u.UserId === user.Id)))),
            ),
            this.store.select(getEntityRoleFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getEntityRole)),
                map((roles) => roles.filter((r) => r.Entity === EntityTypesEnum.Commission)),
            ),
        ])
            .pipe(
                map(([u2c, roles]) => roles.filter((r) => u2c.some((u) => u.RoleId === r.Id))),
                map((roles) => roles.some((r) => r.Name === EntityRoleSlugEnum.Projektleiter || r.Name === EntityRoleSlugEnum.Sachbearbeiter || r.Name === EntityRoleSlugEnum.Admin)),
                first(),
            )
            .subscribe((v) => {
                this.FooterHeightVar = v ? this.Rows.length * 36 + 4 : 0;
                this.canSeeFooterTable = v;
            });
    }
    public ViewChange(view: View, options: ViewsData) {
        // todo dies auch triggern wenn von month auf 3 month geändert wird
        if (this.View !== view) {
            this.View = view as ShiftplanViews;
        }
        if (options?.interval === 3 && view === 'TimelineMonth') {
            this.OnViewChange.emit('Timeline3Month');
        } else {
            this.OnViewChange.emit(view as ShiftplanViews);
        }
    }
    ngOnInit(): void {
        if (this.CommissionId) {
            firstValueFrom(this.store.select(getCommissionById({ id: this.CommissionId }))).then((commission) => {
                const tempFilterValue: ResourcePlanFilter = {
                    [FilterTypes.CommissionId]: [{ id: commission.Id, label: commission.DisplayName }],
                    [FilterTypes.JobSpecificationIds]: [],
                    [FilterTypes.ResourceIds]: [],
                };
                this.FilterValues$.next(tempFilterValue);
            });
        }
    }

    onPopupOpen(args: PopupOpenEventArgs): void {
        if (args.type === 'Editor' || (args.type === 'QuickInfo' && !args.data.Id)) {
            args.cancel = true;
            if (args.data.Id && args.type === 'Editor') {
                if (+args.data.CommissionId) {
                    if (args.data.Type === ScheduleType.ResourceSchedule) {
                        this.store
                            .select(getResourceScheduleById({ id: decodeAppointmentId(args.data.Id).id }))
                            .pipe(first())
                            .subscribe((res) => {
                                const ResourceForm = new FormGroup({
                                    from: new FormControl<Moment>(moment(res.StartDate)),
                                    to: new FormControl<Moment>(moment(res.EndDate)),
                                    amount: new FormControl<number>(res.Amount),
                                });
                                this.openEditDialog(ResourceForm, 'Ressource bearbeiten')
                                    .afterClosed()
                                    .subscribe((ret) => {
                                        if (ret.Action === 'delete') {
                                            this.store.dispatch(
                                                ResourceScheduleActionTypes.Delete({
                                                    Payload: res.Id,
                                                }),
                                            );
                                        }
                                        if (ret.Action === 'save') {
                                            const EndDate = ResourceForm.value.to.toDate();
                                            const StartDate = ResourceForm.value.from.toDate();
                                            StartDate.setHours(0, 0, 0, 0);
                                            EndDate.setHours(23, 59, 59, 999);
                                            this.store.dispatch(
                                                ResourceScheduleActionTypes.Change({
                                                    Payload: {
                                                        id: res.Id,
                                                        amount: ResourceForm.value.amount,
                                                        endDate: FrontendDate(EndDate),
                                                        startDate: FrontendDate(StartDate),
                                                    },
                                                }),
                                            );
                                        }
                                    });
                            });
                    } else if (args.data.Type === ScheduleType.EmployeeResourceSchedule) {
                        this.store
                            .select(getEmployeeResourceScheduleById({ id: decodeAppointmentId(args.data.Id).id }))
                            .pipe(withLatestFrom(this.store.select(getActivityDictionary), this.store.select(getJobSpecificationDictionary)), first())
                            .subscribe(([res, activities, jobs]) => {
                                const ResourceForm = new FormGroup({
                                    from: new FormControl<Moment>(moment(res.StartDate)),
                                    to: new FormControl<Moment>(moment(res.EndDate)),
                                    amount: new FormControl<number>(res.Amount),
                                    jobSpecification: new FormControl<JobSpecificationEntity>(res.JobSpecificationId && jobs[res.JobSpecificationId]),
                                    color: new FormControl<Color>(res.Color?.getNgxMaterialColorPickerObject()),
                                    name: new FormControl<string>(res.Name),
                                    activities: new FormControl<ActivityEntity[]>(res.ActivityIds?.map((id) => activities[id])),
                                });
                                this.openEditEmployeeResourceScheduleDialog(ResourceForm, 'Bedarf bearbeiten').then((ret) => {
                                    if (ret?.Action === 'delete') {
                                        this.store.dispatch(
                                            EmployeeResourceScheduleActionTypes.Delete({
                                                Payload: res.Id,
                                            }),
                                        );
                                    }
                                    if (ret?.Action === 'save') {
                                        const StartDate = ResourceForm.value.from.toDate();
                                        StartDate.setHours(0, 0, 0, 0);
                                        const EndDate = ResourceForm.value.to.toDate();
                                        EndDate.setHours(23, 59, 59, 999);
                                        this.store.dispatch(
                                            EmployeeResourceScheduleActionTypes.Change({
                                                Payload: {
                                                    id: res.Id,
                                                    amount: ResourceForm.value.amount,
                                                    endDate: FrontendDate(EndDate),
                                                    startDate: FrontendDate(StartDate),
                                                    color: ResourceForm.value.color ? { red: ResourceForm.value.color.r, green: ResourceForm.value.color.g, blue: ResourceForm.value.color.b, opacity: ResourceForm.value.color.a } : null,
                                                    name: ResourceForm.value.name,
                                                    jobSpecificationId: ResourceForm.value.jobSpecification?.Id,
                                                    activityIds: ResourceForm.value.activities?.map((a) => a.Id),
                                                },
                                            }),
                                        );
                                    }
                                });
                            });
                    } else if (args.data.Type === ScheduleType.UserToCommissionShift) {
                        this.store
                            .select(getUserToCommissionShiftById({ id: decodeAppointmentId(args.data.Id).id }))
                            .pipe(first())
                            .subscribe((res) => {
                                const ResourceForm = new FormGroup({
                                    from: new FormControl<Moment>(moment(res.StartDate)),
                                    to: new FormControl<Moment>(moment(res.EndDate)),
                                });
                                this.openEditDialog(ResourceForm, 'Schicht bearbeiten')
                                    .afterClosed()
                                    .subscribe((ret) => {
                                        if (ret.Action === 'delete') {
                                            this.store.dispatch(
                                                UserToCommissionShiftActionTypes.DeleteOne({
                                                    Payload: res.Id,
                                                }),
                                            );
                                        }
                                        if (ret.Action === 'save') {
                                            const StartDate = ResourceForm.value.from.toDate();
                                            StartDate.setHours(0, 0, 0, 0);
                                            const EndDate = ResourceForm.value.to.toDate();
                                            EndDate.setHours(23, 59, 59, 999);
                                            this.store.dispatch(
                                                UserToCommissionShiftActionTypes.ChangeUser2CommissionShift({
                                                    Payload: {
                                                        id: res.Id,
                                                        endDate: FrontendDate(EndDate),
                                                        startDate: FrontendDate(StartDate),
                                                    },
                                                }),
                                            );
                                        }
                                    });
                            });
                    }
                } else {
                    this.dialog.open<NewVacationComponent, NewVacationComponentDialogData>(NewVacationComponent, {
                        ...NewVacationComponent.DefaultConfig,
                        data: {
                            employeeId: null,
                            employeeToVacationId: decodeAppointmentId(args.data.Id).id,
                        },
                    });
                }
            } else if (+args.data.CommissionId) {
                const eventRect = args.target.getBoundingClientRect();
                this.selectedElement = args.data as any;
                this.matMenuTriggerElement.nativeElement.style.left = eventRect.left + eventRect.width - 16 + 'px';
                this.matMenuTriggerElement.nativeElement.style.top = eventRect.top + eventRect.height - 20 + 'px';
                this.matMenuTrigger.openMenu();
            }
        }
        if ((args.type === 'QuickInfo' || args.type === 'ViewEventInfo') && args.data.Id) {
            // args.element['style'].top = (+args.element['style'].top.split('px')[0] - this.FooterHeightVar) + 'px';

            // args.data['ResourceDetails$'] =
            // if (+args.data.CommissionId) {
            //     const iconDiv = args.element.querySelector('div.e-resource-icon');
            //     if (iconDiv) {
            //         iconDiv.classList.add('hide-before-content');
            //         iconDiv.appendChild(this.commissionIconElement.nativeElement.childNodes[0].cloneNode(true));
            //     }
            // }
            this.AdditionalHeaderButtons = this.GetAdditionalPopupHeaderButtons(args.data as ResourcePlanEvents);
        } else if (args.type === 'DeleteAlert') {
            args.cancel = true;

            if (!args.data.isReadonly) {
                this.appDialog
                    .OpenConfirmationDialog({
                        paragraph: `Soll der Eintrag wirklich gelöscht werden?`,
                        styleDelete: true,
                    })
                    .pipe(take(1))
                    .subscribe(([res]) => {
                        if (res) {
                            const entityType = decodeAppointmentId(args.data.Id).entityType;
                            switch (entityType) {
                                case EntityType.EmployeeResourceScheduleEntity:
                                    this.store.dispatch(
                                        EmployeeResourceScheduleActionTypes.Delete({
                                            Payload: decodeAppointmentId(args.data.Id).id,
                                        }),
                                    );
                                    break;
                                case EntityType.ResourceScheduleEntity:
                                    this.store.dispatch(
                                        ResourceScheduleActionTypes.Delete({
                                            Payload: decodeAppointmentId(args.data.Id).id,
                                        }),
                                    );
                                    break;
                                case EntityType.EmployeeToVacationEntity:
                                    this.store.dispatch(
                                        EmployeeToVacationActionTypes.Delete({
                                            Payload: { id: decodeAppointmentId(args.data.Id).id },
                                        }),
                                    );
                                    break;
                                case EntityType.User2CommissionShiftEntity:
                                    this.store.dispatch(
                                        UserToCommissionShiftActionTypes.DeleteOne({
                                            Payload: decodeAppointmentId(args.data.Id).id,
                                        }),
                                    );
                                    break;
                            }
                        }
                    });
            }
        }
    }

    private openEditEmployeeResourceScheduleDialog(
        ResourceForm: FormGroup<{
            from: FormControl<Moment>;
            to: FormControl<Moment>;
            amount: FormControl<number>;
            jobSpecification: FormControl<JobSpecificationEntity>;
            color: FormControl<Color>;
            name: FormControl<string>;
            activities: FormControl<ActivityEntity[]>;
        }>,
        headline: string,
    ) {
        const dialogRef = this.dialog.open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
            ...DetailListTemplateDialogComponent.DefaultConfig,
            data: {
                Editing: true,
                DisableSaveButton$: ResourceForm.statusChanges.pipe(
                    startWith(ResourceForm.status),
                    map((state) => state !== 'VALID'),
                ),
                Data: {
                    Headline: headline,
                    Properties: [
                        {
                            key: 'Zeitraum',
                            formControl: ResourceForm,
                            options: {
                                specialInput: {
                                    timeSpan: {
                                        formControlFrom: ResourceForm.controls.from,
                                        formControlTo: ResourceForm.controls.to,
                                    },
                                },
                            },
                        },
                        {
                            key: 'Anzahl',
                            formControl: ResourceForm.controls.amount,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: 'Jobbezeichnung',
                            formControl: ResourceForm.controls.jobSpecification,
                            options: {
                                specialInput: {
                                    singleSelectSearch: {
                                        options$: this.JobSpecifications$, //.pipe(map(jobs => jobs.map(({Id}) => Id))),
                                        searchFunction: (search: string, option: JobSpecificationEntity) => option.Name.toLowerCase().includes(search.toLowerCase()),
                                        compareOptions: (a: JobSpecificationEntity, b: JobSpecificationEntity) => a.Id === b.Id,
                                        optionTemplate: this.jobSpecificationOptionTemplate,
                                    },
                                },
                            },
                        },
                        {
                            key: 'Tätigkeiten',
                            formControl: ResourceForm.controls.activities,
                            options: {
                                specialInput: {
                                    chipAutocomplete: {
                                        MapFn: (option: ActivityEntity) => option.Name,
                                        Options$: this.store.select(getActivities).pipe(map((activities) => activities.filter((a) => !a.DeletedAt || ResourceForm.value.activities?.some((b) => b.Id === a.Id)))),
                                        onUnknownOptionSubmitted: (value) => this.addNewActivity(value, ResourceForm.controls.activities),
                                        OptionTemplate: this.activityOptionTemplate,
                                        initialPatchDefaultValue: true,
                                    },
                                },
                            },
                        },
                        {
                            key: 'Name',
                            formControl: ResourceForm.controls.name,
                        },
                        {
                            key: 'Farbe',
                            formControl: ResourceForm.controls.color,
                            options: {
                                specialInput: {
                                    colorPicker: true,
                                },
                            },
                        },
                    ],
                },
            },
        });
        ResourceForm.controls.jobSpecification.valueChanges
            .pipe(
                takeUntil(dialogRef.afterClosed()),
                startWith(ResourceForm.value.jobSpecification),
                distinctUntilChanged((a, b) => !!a === !!b),
            )
            .subscribe((jobSpecificationId) => {
                if (jobSpecificationId) {
                    ResourceForm.controls.color.setValue(null);
                    ResourceForm.controls.color.disable();
                    ResourceForm.controls.name.setValue(null);
                    ResourceForm.controls.name.disable();
                } else {
                    ResourceForm.controls.color.enable();
                    ResourceForm.controls.name.enable();
                }
            });

        return firstValueFrom(dialogRef.afterClosed());
    }
    private openEditDialog(form: FormGroup<{ from: FormControl<Moment>; to: FormControl<Moment>; amount: FormControl<number> }> | FormGroup<{ from: FormControl<Moment>; to: FormControl<Moment> }>, headline: string) {
        const Properties: IDetailListTemplateDataProperty[] = [
            {
                key: 'Zeitraum',
                formControl: form,
                options: {
                    specialInput: {
                        timeSpan: {
                            formControlFrom: form.controls.from,
                            formControlTo: form.controls.to,
                        },
                    },
                },
            },
        ];
        if (form.controls['amount']) {
            Properties.push({
                key: 'Anzahl',
                formControl: form.controls['amount'],
                options: {
                    specialInput: {
                        number: true,
                    },
                },
            });
        }
        return this.dialog.open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
            ...DetailListTemplateDialogComponent.DefaultConfig,
            data: {
                Editing: true,
                DeleteButton: true,
                Data: {
                    Headline: headline,
                    Properties,
                },
            },
        });
    }
    onActionBegin(args: ActionEventArgs & ToolbarActionArgs) {
        if (args.requestType === 'toolbarItemRendering') {
            args.items = [...desktopHeaderButtons, viewButtonMonth, viewButton3Month];
        }
    }
    ApplyCategoryColor(args: EventRenderedArgs): void {
        let categoryColor = args.data.CategoryColor as ColorEntity;
        if (!args.element || !categoryColor) {
            return;
        }

        if (this.schedule.currentView === 'Agenda') {
            (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor.getHexString();
        } else {
            args.element.style.backgroundColor = categoryColor.getHexString();
            args.element.style.color = categoryColor.getTextColor();
        }
        if (args.data.IsAllDay) {
            const timeDiv = args.element.querySelector('div.e-time');
            timeDiv.textContent = formatDate(args.data.StartTime, 'dd.MM', 'de-DE') + '-' + formatDate(args.data.EndTime.getTime(), 'dd.MM.YY', 'de-DE');
        }
    }
    GetAdditionalPopupHeaderButtons(data: ResourcePlanEvents) {
        const buttons: { icon: IconProp; routerLink?: string | string[]; tooltip: string; onClick?: (event) => void }[] = [];
        if (decodeAppointmentId(data.Id).entityType === EntityType.EmployeeResourceScheduleEntity) {
            buttons.push({
                icon: UserAdministrationMeta.Icon,
                tooltip: 'Mitarbeiter zuordnen',
                onClick: (e) => {
                    firstValueFrom(this.store.select(getEmployeeResourceScheduleById(decodeAppointmentId(data.Id)))).then((ers) => {
                        this.dialog.open<SelectUserCommissionDialogComponent, SelectUserCommissionDialogComponentDialogData>(SelectUserCommissionDialogComponent, {
                            ...SelectUserCommissionDialogComponent.DefaultConfig,
                            data: {
                                commissionId: +data.CommissionId,
                                preFilledStartDate: data.StartTime,
                                preFilledEndDate: data.EndTime,
                                headlineSuffix: '(Bedarf ' + ers.Amount + ')',
                                defaultFilter: ers.JobSpecificationId ? { jobSpecificationIds: [ers.JobSpecificationId] } : null,
                            },
                        });
                    });
                },
            });
        }
        return buttons;
    }
    onDragStart(args: DragEventArgs) {
        if (args.data.isReadonly || args.event.type === 'touchmove') {
            args.cancel = true;
        }
        args.navigation.enable = true;
    }
    onResizeStart(args: ResizeEventArgs) {
        if (args.data.isReadonly || decodeAppointmentId(args.data.Id).entityType === EntityType.EmployeeToVacationEntity || args.event.type === 'touchstart') {
            args.cancel = true;
        }
        this.resizingClonePrevEndDate = args.data.EndTime;
        this.resizingClonePrevStartDate = args.data.StartTime;
        interval(100)
            .pipe(
                takeUntil(this.resizeStop$),
                map(() => this.componentElement.nativeElement.querySelector('div.e-appointment.e-resize-clone')),
                takeWhile((v) => !v, true),
                filter((v) => !!v),
            )
            .subscribe((element) => {
                this.resizeObserver.disconnect();
                this.resizeObserver.observe(element);
            });
    }
    onDragStop(args: DragEventArgs) {
        const StartDate = args.data.StartTime;
        const EndDate = args.data.EndTime;
        StartDate.setHours(0, 0, 0, 0);
        EndDate.setHours(23, 59, 59, 999);
        if (+args.data.CommissionId) {
            if (args.data.Type === ScheduleType.ResourceSchedule) {
                this.store.dispatch(
                    ResourceScheduleActionTypes.Change({
                        Payload: {
                            id: decodeAppointmentId(args.data.Id).id,
                            commissionId: args.data.CommissionId,
                            endDate: FrontendDateTimestamp(EndDate),
                            startDate: FrontendDateTimestamp(StartDate),
                        },
                    }),
                );
            } else if (args.data.Type === ScheduleType.EmployeeResourceSchedule) {
                this.store.dispatch(
                    EmployeeResourceScheduleActionTypes.Change({
                        Payload: {
                            id: decodeAppointmentId(args.data.Id).id,
                            commissionId: args.data.CommissionId,
                            endDate: FrontendDateTimestamp(EndDate),
                            startDate: FrontendDateTimestamp(StartDate),
                        },
                    }),
                );
            } else if (args.data.Type === ScheduleType.UserToCommissionShift) {
                this.store.dispatch(
                    UserToCommissionShiftActionTypes.ChangeUser2CommissionShift({
                        Payload: {
                            id: decodeAppointmentId(args.data.Id).id,
                            commissionId: args.data.CommissionId,
                            endDate: FrontendDateTimestamp(EndDate),
                            startDate: FrontendDateTimestamp(StartDate),
                        },
                    }),
                );
            }
        }
    }
    onResizeStop(args: ResizeEventArgs) {
        // wenn man das event nicht bewegt ist this.resizingCloneWidth = 0
        if (!this.resizingCloneWidth) {
            return;
        }
        // args.data.EndTime is broken
        this.resizeStop$.next();
        this.resizeObserver.disconnect();

        const prevWidth = args.element.clientWidth;
        const currWidth = this.resizingCloneWidth;
        const dayWidth = this.schedule.element.querySelector('.e-schedule-table.e-content-table').clientWidth / this.schedule.activeView.renderDates.length;

        const dateDiff = Math.round((currWidth - prevWidth) / dayWidth);

        const StartDate: Date = args.data.StartTime;
        const EndDate: Date = new Date(this.resizingClonePrevEndDate);
        if (this.resizingClonePrevStartDate.getTime() === args.data.StartTime.getTime()) {
            EndDate.setDate(EndDate.getDate() + dateDiff);
        }

        StartDate.setHours(0, 0, 0, 0);
        EndDate.setHours(23, 59, 59, 999);
        this.resizingClonePrevEndDate = null;
        this.resizingClonePrevStartDate = null;

        if (args.data.Type === ScheduleType.ResourceSchedule) {
            this.store.dispatch(
                ResourceScheduleActionTypes.Change({
                    Payload: {
                        id: decodeAppointmentId(args.data.Id).id,
                        endDate: FrontendDateTimestamp(EndDate),
                        startDate: FrontendDateTimestamp(StartDate),
                    },
                }),
            );
        } else if (args.data.Type === ScheduleType.EmployeeResourceSchedule) {
            this.store.dispatch(
                EmployeeResourceScheduleActionTypes.Change({
                    Payload: {
                        id: decodeAppointmentId(args.data.Id).id,
                        endDate: FrontendDateTimestamp(EndDate),
                        startDate: FrontendDateTimestamp(StartDate),
                    },
                }),
            );
        } else if (args.data.Type === ScheduleType.UserToCommissionShift) {
            this.store.dispatch(
                UserToCommissionShiftActionTypes.ChangeUser2CommissionShift({
                    Payload: {
                        id: decodeAppointmentId(args.data.Id).id,
                        endDate: FrontendDateTimestamp(EndDate),
                        startDate: FrontendDateTimestamp(StartDate),
                    },
                }),
            );
        }
    }
    private getTableBody() {
        return this.componentElement.nativeElement.querySelector(`.e-schedule-table.e-outer-table > tbody`);
    }
    public AddFooterRows(rows: Array<CapacityRow>) {
        const tBody = this.getTableBody();
        const header = tBody?.querySelector('tr');
        const footerWrapper = document.createElement('div');

        footerWrapper.append(header?.cloneNode(true));
        const rightBody = footerWrapper.querySelector('.e-schedule-table > tbody');

        rightBody.classList.add('right-footer-body');
        const rightRowTemplate = rightBody.childNodes.item(0).cloneNode(false);
        rightBody.childNodes.item(0).childNodes.forEach((v) => {
            rightRowTemplate.appendChild(v.cloneNode(false));
        });

        rightBody.removeChild(rightBody.childNodes.item(0));

        const leftTBody = document.createElement('tbody');

        rows.forEach((r, index) => {
            const row = rightRowTemplate.cloneNode(true);
            row.childNodes.forEach((value: ChildNode, key: number, parent: NodeListOf<ChildNode>) => {
                //@ts-ignore
                const date: number = +value.dataset.date;
                const cell = r.cell(date);
                const content = document.createElement('span');
                content.textContent = cell.textContent;
                if (cell.class) {
                    //@ts-ignore
                    value.classList.add(cell.class);
                }
                value.appendChild(content);
            });
            rightBody.appendChild(row);

            const tr = document.createElement('tr');
            const td = document.createElement('td');
            const span = document.createElement('span');
            span.textContent = r.label;
            if (r.icon) {
                const icon = this.componentElement.nativeElement.querySelector(`#footer-row-icons fa-icon.fa-icon-${r.icon}`);
                td.appendChild(icon.cloneNode(true));
            }
            td.appendChild(span);
            tr.appendChild(td);
            leftTBody.append(tr);
        });
        const leftTable = document.createElement('table');
        leftTable.classList.add('e-schedule-table');
        leftTable.append(leftTBody);

        const div = document.createElement('div');
        div.classList.add('e-date-header-wrap');
        div.append(leftTable);

        const leftTd = footerWrapper.querySelector('.e-resource-left-td');
        leftTd.removeChild(leftTd.childNodes.item(0));
        leftTd.append(div);

        this.removeFooter(tBody);
        const footer = footerWrapper.childNodes.item(0);
        tBody.appendChild(footer);
        // this.footerTableElement.nativeElement.appendChild(footer);
        const scrollWrapper = this.componentElement.nativeElement.querySelector(`.e-schedule-table.e-outer-table > tbody div.e-content-wrap`);
        scrollWrapper?.addEventListener('scroll', (event) => {
            //@ts-ignore
            const sl = event.target.scrollLeft;
            rightBody.parentElement.parentElement.scrollLeft = sl;
        });
        if (scrollWrapper) {
            rightBody.parentElement.parentElement.scrollLeft = scrollWrapper.scrollLeft;
        }
    }
    removeFooter(tBody: Element) {
        if (tBody?.childNodes.length > 2) {
            tBody.removeChild(tBody.childNodes.item(2));
        }
    }
    dataBound(e) {
        this.dataBound$.next();
        setTimeout(() => {
            this.generateFooter();
        }, 0);
    }
    navigating(e: NavigatingEventArgs) {
        if (e.action === 'view' || (e.action === 'date' && sameDay(e.currentDate, new Date()))) {
            setTimeout(() => {
                this.generateFooter();
            }, 0);
            // fix für wechsel auf 3 monats ansicht
            interval(300)
                .pipe(
                    skip(4), // auf animation warten
                    take(10), // fallback unsubscribe
                    takeWhile(() => {
                        // check if left column has more height then right column
                        const row = this.schedule.element.querySelector('.e-schedule-table.e-outer-table > tbody > tr:nth-child(2)');
                        return row.children.item(0).children.item(0).clientHeight > row.children.item(1).children.item(0).clientHeight;
                    }, false),
                )
                .subscribe(() => {
                    this.schedule.refreshLayout();
                    this.scrollToToday();
                });
        }
    }
    renderFooter() {
        interval(10)
            .pipe(takeWhile(() => !this.getTableBody(), true))
            .subscribe((i) => {
                if (this.getTableBody()) {
                    this.AddFooterRows(this.Rows);
                }
            });
    }
    generateFooter() {
        if (this.canSeeFooterTable) {
            const dates = this.schedule.getCurrentViewDates();
            const dateTimes = dates.map((d) => d.getTime());

            if (dateTimes.some((t) => !this.CapacityCache.has(t))) {
                if (this.pendingCapacityCache.includes(JSON.stringify(dates))) {
                    return;
                }
                this.pendingCapacityCache.push(JSON.stringify(dates));
                this.removeFooter(this.getTableBody());
                if (this.loadingTemplate.nativeElement.childNodes[0]) {
                    this.getTableBody()?.appendChild(this.loadingTemplate.nativeElement.childNodes[0].cloneNode(true));
                }

                const query = `
            query {
            capacityCalculation(days: ${JSON.stringify(dates)}) {
                absent
                assigned
                available
                day
                planed
                total
              }
            }`;
                this.httpService.graphQl({ query }).subscribe((res) => {
                    this.pendingCapacityCache.splice(this.pendingCapacityCache.indexOf(JSON.stringify(dates)), 1);
                    if (res?.capacityCalculation?.length) {
                        res.capacityCalculation.forEach((c) => {
                            this.CapacityCache.set(BackendDate(c.day).getTime(), c);
                        });
                        this.renderFooter();
                    } else {
                        this.store.dispatch(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: 'Kapazitäten abrufen fehlgeschlagen.',
                                },
                            }),
                        );
                    }
                });
            } else {
                this.renderFooter();
            }
        }
    }
    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.footerRenderSub?.unsubscribe();
        this.breakpointSubscription?.unsubscribe();
    }
    public scrollToToday() {
        this.schedule.scrollTo(null, this.SelectedDate);
    }
    actionComplete(c: ActionEventArgs) {
        if (c.requestType === 'viewNavigate') {
            const options = this.schedule.activeViewOptions;

            if (!options) {
                return;
            }
            const view = options.option;

            if (options?.interval === 3 && view === 'TimelineMonth') {
                this.OnViewChange.emit('Timeline3Month');
                this.View = 'Timeline3Month' as ShiftplanViews;
            } else {
                this.OnViewChange.emit(view as ShiftplanViews);
                this.View = view as ShiftplanViews;
            }
            this.scrollToToday();
        } else if (c.requestType === 'dateNavigate') {
            this.scrollToToday();
        }
    }
    addNewActivity(name: string, activities: FormControl<ActivityEntity[]>) {
        activities.disable();
        firstValueFrom(this.actions$.pipe(ofType(ActivityActions.updateOne, BaseActionTypes.ErrorAction))).then((a) => {
            activities.enable();
            if (a.type === ActivityActions.updateOne.type) {
                activities.setValue([...activities.value, a.Payload]);
            }
        });
        this.store.dispatch(ActivityActions.add({ Payload: { name } }));
    }

    DeleteActivity(Option: ActivityEntity) {
        this.appDialog
            .OpenConfirmationDialog({
                heading: 'Aktivität löschen ?',
                paragraph: 'Möchten Sie die Tätigkeit ' + Option.Name + ' wirklich löschen?',
                styleDelete: true,
            })
            .subscribe(([del, s]) => {
                if (del) {
                    this.store.dispatch(ActivityActions.delete({ Payload: { id: Option.Id } }));
                }
            });
    }
}
