import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { DocumentUserEntity } from '../../../dave-data-module/entities/document-user.entity';
import { State } from '../../../dave-data-module/State';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { FileUserActionTypes } from '../../../dave-data-module/State/actions/file-user';
import { getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getUsers } from '../../../dave-data-module/State/selectors/users.selectors';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { isNotNullOrUndefined } from '../../../helper/helper';

export interface EditDocumentUserComponentDialogData {
    DocumentUser?: DocumentUserEntity;
    DocumentUsers?: DocumentUserEntity[];
    DocumentId: number;
}

interface FromData {
    FromCustomer: CustomerEntity | undefined;
    FromCommission: CommissionEntity | undefined;
    DocumentUser: DocumentUserEntity;
}

@Component({
    selector: 'app-edit-document-user',
    templateUrl: './edit-document-user.component.html',
    styleUrls: ['./edit-document-user.component.scss'],
})
export class EditDocumentUserComponent implements OnDestroy {
    public New = false;
    public Loading = false;
    public Users$ = this.store.select(getUsers).pipe(
        filter(isNotNullOrUndefined),
        map((users) =>
            users
                .filter((u) => !u.Deleted)
                .map((u) => ({
                    Name: u.DisplayName,
                    Id: u.Id,
                })),
        ),
    );
    public Form = new FormGroup({
        User: new FormControl<SelectSearchData | null>(null),
        Edit: new FormControl<boolean>(false),
    });
    private subscriptions: Subscription[] = [];
    public From: FromData[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public Data: EditDocumentUserComponentDialogData,
        public DialogRef: MatDialogRef<EditDocumentUserComponent>,
        public CS: CustomerNameService,
        protected store: Store<State>,
        private actions$: Actions,
    ) {
        if (!this.Data.DocumentUser && !this.Data.DocumentUsers) {
            this.New = true;
        }
        if (this.Data.DocumentUser) {
            this.Form.controls.Edit.setValue(this.Data.DocumentUser.Edit);
            this.Form.controls.User.setValue({
                Id: this.Data.DocumentUser.UserId,
                Name: '',
            });
        }
        if (this.Data.DocumentUsers) {
            this.Form.controls.Edit.setValue(this.Data.DocumentUsers[0].Edit);
            this.Form.controls.User.setValue({
                Id: this.Data.DocumentUsers[0].UserId,
                Name: '',
            });
        }
        this.subscriptions.push(
            combineLatest([this.store.select(getCustomers), this.store.select(getCommissions)]).subscribe(([customers, commissions]) => {
                this.Data.DocumentUsers?.forEach((du) => {
                    const out: FromData = {
                        FromCustomer: undefined,
                        FromCommission: undefined,
                        DocumentUser: du,
                    };

                    if (du.FromType !== '' && du.FromId !== 0) {
                        switch (du.FromType) {
                            default:
                                console.error('FromType not found', du.FromType);
                                break;
                            case 'customer':
                                out.FromCustomer = customers.find((c) => c.Id === du.FromId);
                                break;
                            case 'commission':
                                out.FromCommission = commissions.find((c) => c.Id === du.FromId);
                                break;
                        }
                    }
                    this.From.push(out);
                });
            }),
        );
    }

    Save() {
        this.Loading = true;
        firstValueFrom(this.actions$.pipe(ofType(FileUserActionTypes.Update, BaseActionTypes.ErrorAction))).then((action) => {
            this.Loading = false;
            if (action.type === FileUserActionTypes.Update.type) {
                this.DialogRef.close();
            }
        });
        this.store.dispatch(
            FileUserActionTypes.AddFileUser({
                Payload: {
                    documentId: this.Data.DocumentId,
                    userId: this.Form.value.User.Id,
                    edit: this.Form.value.Edit,
                },
            }),
        );
    }

    Delete() {
        this.Loading = true;
        firstValueFrom(this.actions$.pipe(ofType(FileUserActionTypes.Update, BaseActionTypes.ErrorAction))).then((action) => {
            this.Loading = false;
            if (action.type === FileUserActionTypes.Update.type) {
                this.DialogRef.close();
            }
        });
        this.store.dispatch(
            FileUserActionTypes.DeleteFileUser({
                Payload: {
                    documentId: this.Data.DocumentId,
                    userId: this.Data.DocumentUser.UserId,
                },
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
