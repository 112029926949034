import { Injectable } from '@angular/core';
import { BaseEntityReloadResolver2, BaseEntityResolver } from './resolver.helper';
import { Store } from '@ngrx/store';
import { State } from '../State';

import { getSurchargeRateFetched } from '../State/selectors/surcharge-rate.selector';
import { SurchargeRateActionTypes } from '../State/actions/surcharge-rate.actions';

@Injectable({
    providedIn: 'root',
})

export class SurchargeRateResolver extends BaseEntityResolver {
    constructor(store: Store<State>) {
        super(getSurchargeRateFetched, SurchargeRateActionTypes.loadRequest({ Payload: {} }), store);
    }
}
