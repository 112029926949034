import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';
import { officeFeatureKey, OfficeState, selectAllOffice, selectOfficeEntities, selectOfficeFetched, selectOfficeLatestUpdatedAt } from '../reducers/office.reducer';

const selectOffice = createFeatureSelector< OfficeState>(officeFeatureKey);
const getEntities = createSelector(selectOffice, selectOfficeEntities);

export const getOffices = createSelector(selectOffice, selectAllOffice);
export const getOfficesFetched = createSelector(selectOffice, selectOfficeFetched);
export const getOfficeLatestUpdatedAt = createSelector(selectOffice, selectOfficeLatestUpdatedAt);
export const getOfficeById = createSelector(getEntities, (office: ReturnType<typeof getEntities>, Id: number) => Id && office[Id]);
export const getOfficeByCustomerId = (props: { id: number }) => createSelector(getOffices, (office) => office.find(o => o.CustomerId === props.id));
