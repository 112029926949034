import { Injectable } from '@angular/core';
import { ResolveData } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import { combineLatest,firstValueFrom,Observable,of,switchMap } from 'rxjs';
import { filter,map,tap } from 'rxjs/operators';
import { EmployeeEntity } from '../dave-data-module/entities/employee.entity';
import { EmployeeResolver } from '../dave-data-module/guards/employee.resolver';
import { JobSpecificationResolver } from '../dave-data-module/guards/job-specification.resolver';
import { ProductResolver } from '../dave-data-module/guards/product.resolver';
import { State } from '../dave-data-module/State';
import { employeesFeatureKey } from '../dave-data-module/State/reducers/employees.reducer';
import { JOB_SPECIFICATION_KEY } from '../dave-data-module/State/reducers/job-specification.reducer';
import { productsFeatureKey } from '../dave-data-module/State/reducers/products.reducer';
import { getEmployees,getEmployeesFetched } from '../dave-data-module/State/selectors/employees.selectors';
import { getJobSpecificationById,getJobSpecificationFetched } from '../dave-data-module/State/selectors/job-specification.selector';
import { getProductsFetched,getUserProducts } from '../dave-data-module/State/selectors/products.selectors';
import { getUser } from '../dave-data-module/State/selectors/users.selectors';
import { CustomerNameService } from '../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { PermissionService } from '../dave-utils-module/dave-shared-components-module/services/permission.service';
import { isNotNullOrUndefined } from '../helper/helper';
import { DashboardItemsData,DashboardItemsDataType } from '../mocks/dashboard-items-data';
import { CustomLabelService } from "./custom-label.service";
import { Permission } from '../helper/permission.helper';
import { getFetched$ } from '../dave-data-module/helper/helper';
import { getPartner, getPartnerFetched } from '../dave-data-module/State/selectors/partners.selectors';
import { PartnerTypeEnum } from '../dave-data-module/entities/partner.entity';
import { Permissions } from '@dave/types/dist/proto/global/permissions';

export class ICard {
    constructor(public Title: string, public Route: string | any[], public Image: IconProp, public Theme: string, public Disabled: boolean, public Buttons: { name: string; icon: IconProp }[]) {}
}
const defaultOrder: DashboardItemsDataType[] = [
    DashboardItemsDataType.akkord,
    DashboardItemsDataType.customerAdministration,
    DashboardItemsDataType.commissionAdministration,
    DashboardItemsDataType.email,
    DashboardItemsDataType.resourcePlan,
    DashboardItemsDataType.shiftPlan,
    DashboardItemsDataType.resourceAdministration,
    DashboardItemsDataType.tasks,
    DashboardItemsDataType.contactBook,
    DashboardItemsDataType.process,
    DashboardItemsDataType.dms,
    DashboardItemsDataType.employeeAdministration,
    DashboardItemsDataType.invoices,
    DashboardItemsDataType.generatedDocuments,
    DashboardItemsDataType.chronik,
    DashboardItemsDataType.videoDoku,
    DashboardItemsDataType.settings,
    DashboardItemsDataType.partnerAdministration,
];
const tsbOrder: DashboardItemsDataType[] = [
    DashboardItemsDataType.customerAdministration,
    DashboardItemsDataType.commissionAdministration,
    DashboardItemsDataType.invoices,
    DashboardItemsDataType.email,
    DashboardItemsDataType.resourceAdministration,
];


// ToDo: entfernen, wenn keine Mockdaten mehr verwendet werden (benötigt "User"-Entity)

@Injectable({
    providedIn: 'root',
})
export class DashboardHelperService {
    public static readonly RequiredResolvers: ResolveData = {
        [employeesFeatureKey]: EmployeeResolver,
        [productsFeatureKey]: ProductResolver,
        [JOB_SPECIFICATION_KEY]: JobSpecificationResolver,
    };
    constructor(
        public PS: PermissionService,
        protected cs: CustomerNameService,
        protected labelService: CustomLabelService,
        private store: Store<State>,
        private productResolver: ProductResolver,
        private employeeResolver: EmployeeResolver,
        private jobSpecificationResolver: JobSpecificationResolver,
    ) {
        productResolver.resolve();
        firstValueFrom(this.store.select(getEmployeesFetched)).then((fetched) => {
            if (!fetched) {
                employeeResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getJobSpecificationFetched)).then((fetched) => {
            if (!fetched) {
                jobSpecificationResolver.resolve();
            }
        });
    }

    public GetDashboardItems$(): Observable<ICard[]> {
        // todo das Observable als variable speichern hot machenm und das hier zurück geben
        const dashboardItemsData = DashboardItemsData.map((value) => {
            if (isNotNullOrUndefined(value.title) && value.route && value.image && value.theme && value.disabled !== undefined) {
                return value;
            } else {
                throw new Error('could not create history cards: not all required parameters have been provided.');
            }
        });
        const requiredPermissions: Permissions[] = [];
        dashboardItemsData.filter((d) => d.requiredPermissions && d.requiredPermissions.length).forEach((d) => requiredPermissions.push(...d.requiredPermissions));
        const availablePermissions$ = combineLatest([...requiredPermissions.map((p) => this.PS.Has$(p).pipe(map((v) => (v ? p : null))))]);
        return combineLatest([
            this.store.select(getProductsFetched).pipe(
                tap((f) => {
                    if (!f) {
                        this.productResolver.resolve();
                    }
                }),
                filter((f) => f),
                switchMap(() => this.store.select(getUserProducts)),
            ),
            availablePermissions$,
            this.store.select(getUser).pipe(
                filter(isNotNullOrUndefined),
                switchMap((user) =>
                    this.store.select(getEmployeesFetched).pipe(
                        tap((f) => {
                            if (!f) {
                                this.employeeResolver.resolve();
                            }
                        }),
                        filter((f) => f),
                        switchMap(() => this.store.select(getEmployees)),
                        map((employees) => employees.find((e) => e.UserId === user.Id)),
                    ),
                ),
                filter(isNotNullOrUndefined),
                switchMap<EmployeeEntity, Observable<DashboardItemsDataType[]>>((employee) =>
                    employee.JobSpecificationId
                        ? this.store.select(getJobSpecificationFetched).pipe(
                              tap((f) => {
                                  if (!f) {
                                      this.jobSpecificationResolver.resolve();
                                  }
                              }),
                              filter((v) => v),
                              switchMap(() => this.store.select(getJobSpecificationById({ id: employee.JobSpecificationId }))),
                              map((js) => js?.AdditionalData?.excludeServiceApps || []),
                          )
                        : of([]),
                ),
            ),
            this.labelService.getMultiple$('Commission'),
            this.labelService.getMultiple$('RessourcePlan'),
            getFetched$(this.store, getPartnerFetched, getPartner).pipe(map(partner => {
                if (partner.AdditionalData?.dashboardItemsOrder?.length) {
                    return [...partner.AdditionalData.dashboardItemsOrder, ...defaultOrder.filter(d => !partner.AdditionalData.dashboardItemsOrder.some(t => t === d))]
                }
                return defaultOrder;
            })),
        ]).pipe(
            map(([productList, permissionArray, excludeServiceApps, commissionLabel, resPlanLabel, order]) => {
                return dashboardItemsData.slice().sort((a, b) => {
                    const indexA = order.findIndex(t => t === a.type);
                    const indexB = order.findIndex(t => t === b.type);
                    if (indexA !== indexB && (indexA < 0 || indexB < 0)) {
                        return indexA < 0 ? 1 : -1;
                    }
                    return indexA - indexB
                })
                    .filter(
                        (value) =>
                            ((productList != null && productList.some((e) => e.Name === value.license)) || value.license === null) &&
                            (!value.requiredPermissions || !value.requiredPermissions.length || value.requiredPermissions.every((p) => permissionArray.includes(p))) &&
                            !excludeServiceApps.includes(value.type),
                    )
                    .map((value) => {
                        let title = value.title;
                        switch (value.type) {
                            case DashboardItemsDataType.customerAdministration:
                                title = this.cs.GetMultiple();
                                break;
                            case DashboardItemsDataType.commissionAdministration:
                                title = commissionLabel;
                                break;
                            case DashboardItemsDataType.resourcePlan:
                                title = resPlanLabel;
                                break;
                        }
                        return new ICard(title, value.disabled ? null : value.route, value.image, value.theme, value.disabled, value.buttons || []);
                    })
                    .filter((e) => e != null);
            }),
        );
    }
}
