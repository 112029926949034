import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { LicensePackageEntityFromBackend } from '../../entities/license-package.entity';
import { LicenseEntityFromBackend } from '../../entities/license.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { LicensesActionTypes } from '../actions/licenses.actions';
import { ResolverActionTypes, ResolverLoadAllLicenses, ResolverLoadLicenses, ResolverLoadUser } from '../actions/resolver.actions';
import { State } from '../index';

enum ErrorCodes {
    Load = 'Laden der Lizenzen fehlgeschlagen',
    LoadAll = 'Laden aller Lizenzen fehlgeschlagen',
    LoadPackages = 'Laden der Lizenzpackete fehlgeschlagen',
    Add = 'Lizenz Hinzufügen fehlgeschlagen',
    Modify = 'Lizenz Bearbeiten fehlgeschlagen',
    Remove = 'Lizenz Löschen fehlgeschlagen',
    AddOrder = 'Speichern der Bestellung fehlgeschlagen',
    VerifyOrder = 'Verifizieren der Bestellung fehlgeschlagen',
    CancelLicense = 'Kündigen der Lizenz fehlgeschlagen',
}

@Injectable()
export class LicenseEffects {
    LoadLicense$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResolverActionTypes.LoadLicenses),
            withLatestFrom(this.store$),
            switchMap(([action, store]) => {
                const queryString = `
            query {
                license {
                        activeUser
                        basePrice
                        cancelled
                        discountInPercent
                        id
                        licenseOrderId
                        maxUser
                        maxUserTypeId
                        maxUserTypeName
                        minUser
                        monthlyCost
                        partnerId
                        pricePerUser
                        productId
                        productName
                        timePeriodInMonth
                        validUntil
                }
            }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                    map((res: any) => {
                        // const today = new Date(Date.parse(Date()));
                        // today.setHours(0, 0, 0, 0);
                        // for (const li of res.license) {
                        //     const d = BackendDate(li.validUntil);
                        //     d.setHours(0, 0, 0, 0);
                        //     const dayDiff =
                        //         (d.getTime() - today.getTime()) /
                        //         (1000 * 60 * 60 * 24);
                        //     if (dayDiff >= 0 && dayDiff <= 5 && li.cancelled) {
                        //         const displayText =
                        //             dayDiff === 1
                        //                 ? ' Eine Ihrer Lizenzen läuft in einem Tag aus: ' +
                        //                   li.productName
                        //                 : ' Eine Ihrer Lizenzen läuft in ' +
                        //                   dayDiff +
                        //                   ' Tagen aus: ' +
                        //                   li.productName;
                        //         this.apiToasterService.success(displayText);
                        //     }
                        // }
                        return res && res.license
                            ? LicensesActionTypes.UpdateLicense({
                                  Payload: res.license.map((val) => LicenseEntityFromBackend(val)),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    LoadAllLicense$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResolverActionTypes.LoadAllLicenses),
            withLatestFrom(this.store$),
            switchMap(([action, store]) => {
                const queryString = `
            query {
                licenseAll {
                        activeUser
                        basePrice
                        cancelled
                        discountInPercent
                        id
                        licenseOrderId
                        maxUser
                        maxUserTypeId
                        maxUserTypeName
                        minUser
                        monthlyCost
                        partnerId
                        pricePerUser
                        productId
                        productName
                        timePeriodInMonth
                        validUntil
                }
            }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                    map((res) =>
                        res && res.licenseAll
                            ? LicensesActionTypes.UpdateAllLicenses({
                                  Payload: res.licenseAll.map((val) => LicenseEntityFromBackend(val)),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.LoadAll } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.LoadAll,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    LoadLicensePackages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResolverActionTypes.LoadLicensPackage),
            withLatestFrom(this.store$),
            switchMap(([action, store]) => {
                const queryString = `
            query {
                licensePackages {
                    basePrice
                    canBeBookedByUser
                    id
                    maxUser
                    maxUserTypeId
                    maxUserTypeName
                    minUser
                    pricePerUser
                    productName
                }
            }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                    map((res) =>
                        res && res.licensePackages
                            ? LicensesActionTypes.UpdateLicensePackage({ Payload: res.licensePackages.map((val) => LicensePackageEntityFromBackend(val)) })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.LoadPackages } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.LoadPackages,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    DeleteLicense$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LicensesActionTypes.DeleteLicense),
            withLatestFrom(this.store$),
            concatMap(([action, store]) => {
                const queryString = `
            mutation {
                deleteLicense(id: ${action.Payload})
            }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    map((res) =>
                        res && res.deleteLicense === null
                            ? (new ResolverLoadLicenses(), new ResolverLoadAllLicenses())
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    CancelLicense$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LicensesActionTypes.CancelLicense),
            withLatestFrom(this.store$),
            concatMap(([action, store]) => {
                const queryString = `
            mutation {
                cancelLicense(id: ${action.Payload})
            }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    concatMap((res) =>
                        res && res.cancelLicense === null
                            ? [new ResolverLoadLicenses(), new ResolverLoadAllLicenses()]
                            : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.CancelLicense } })],
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.CancelLicense,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    AddLicenseOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LicensesActionTypes.AddLicenseOrder),
            withLatestFrom(this.store$),
            concatMap(([action, store]) =>
                this.gatewayHttpService
                    .graphQl(
                        {
                            query: `
            mutation{
              createLicenseOrder(
                productId: ${action.Payload.productId}
                maxUserTypeId: ${action.Payload.maxUserTypeId}
                partnerId: ${action.Payload.partnerId}
                varianteTypeId: ${action.Payload.varianteTypeId}){
                    id
                    maxUserTypeId
                    orderedAt
                    partnerId
                    productId
                    userId
                    varianteTypeId
                    verificationCode
                    verifiedAt
              }
            }`,
                        },
                        { token: store.base.token },
                    )
                    .pipe(
                        concatMap((ret) =>
                            ret && ret.createLicenseOrder && ret.createLicenseOrder.id > 0
                                ? [
                                      new ResolverLoadAllLicenses(),
                                      new ResolverLoadLicenses(),
                                      new ResolverLoadUser({ id: ret.createLicenseOrder.userId }),
                                      BaseActionTypes.CheckToken(),
                                  ]
                                : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.AddOrder } })],
                        ),
                        catchError(() => {
                            this.apiToasterService.error('Beim Erstellen der Bestellung ist etwas schiefgelaufen, bitte wenden Sie sich an den Kundenservice.');
                            return EMPTY;
                        }),
                    ),
            ),
        ),
    );
    // @Effect()
    // verifyLicenseOrder$ = this.actions$.pipe(
    //     ofType(DataActionTypes.VerifyLicenseOrder),
    //     withLatestFrom(this.store$),
    //     concatMap(([action, store]) => this.gatewayHttpService.graphQl({
    //         query: `
    //         mutation{
    //           activateLicenseOrder(
    //             id:${action.Payload.id}
    //             verificationCode: "${action.Payload.verificationCode}"
    //             verifiedAt: "${FrontendDate(new Date())}"){
    //                 id
    //                 maxUserTypeId
    //                 orderedAt
    //                 productId
    //                 userId
    //                 verificationCode
    //                 verifiedAt
    //           }
    //         }`
    //     }, {token: store.base.token}).pipe(
    //         map((ret: any) => {
    //             if (ret && ret.activateLicenseOrder && ret.activateLicenseOrder.id > 0) {
    //                 this.apiToasterService.DisplayApiToaster('Ihre Bestellung wurde erfolgreich bestätigt!');
    //                 return (new ResolverLoadLicenses(), new ResolverLoadAllLicenses());
    //             } else {
    //                 this.apiToasterService.DisplayApiToaster(
    //                     'Beim Bestätigen der Bestellung ist etwas schiefgelaufen, bitte wenden Sie sich an den Kundenservice.');
    //                 return BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.VerifyOrder}});
    //             }
    //         }),
    //         catchError(() => {
    //             this.apiToasterService.DisplayApiToaster(
    //                 'Beim Bestätigen der Bestellung ist etwas schiefgelaufen, bitte wenden Sie sich an den Kundenservice.');
    //             return EMPTY;
    //         })
    //     ))
    // );

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private apiToasterService: ToastrService, private gatewayHttpService: HttpService) {}
}
