<h1 mat-dialog-title>Sind Sie sich Sicher?</h1>
<div mat-dialog-content>
    <p>{{Data.name}}</p>
    <p *ngIf="Data.warn">{{Data.warn}}</p>
    <div>
        <p>Durch Ihre Buchung verändern sich Ihre monatlichen Lizenzkosten nicht.</p>
    </div>
        <!--
        wurde wegen D277-4049
        <table>
            <tr *ngIf="Data.costPerUser">
                <td>Kosten pro Nutzer:</td>
                <td>{{Data.costPerUser | currency: 'EUR'}}</td>
            </tr>
            <br>
            <tr>
                <td>Vorherige Kosten:</td>
                <td>{{Data.oldCost | currency: 'EUR'}}</td>
            </tr>
            <tr>
                <td>MwSt ({{MWST*100}}%):</td>
                <td>{{Data.oldCost * MWST | currency: 'EUR'}}</td>
            </tr>
            <tr>
                <td>Gesamt:</td>
                <td>{{Data.oldCost * (1 + MWST) | currency: 'EUR'}}</td>
            </tr>
            <br>
            <tr>
                <td>Ab Sofort:</td>
                <td>{{Data.newCost | currency: 'EUR'}}</td>
            </tr>
            <tr>
                <td>MwSt ({{MWST*100}}%):</td>
                <td>{{Data.newCost * MWST | currency: 'EUR'}}</td>
            </tr>
            <tr>
                <td>Gesamt:</td>
                <td>{{Data.newCost * (1 + MWST) | currency: 'EUR'}}</td>
            </tr>
            <br>
        </table>
    -->
</div>
<div mat-dialog-actions class="buttons">
    <button app-button class="button" [mat-dialog-close]="false">Nein</button>
    <button app-button class="button" [mat-dialog-close]="true">Ja</button>
</div>
