import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { PartnerEntity, PartnerEntityFromBackend } from '../../entities/partner.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { PartnerOfficeActionTypes, PartnersActionTypes } from '../actions/partners.actions';
import { SepaActionTypes } from '../actions/sepa.actions';
import { State } from '../index';

enum ErrorCodes {
    Add = 'Partner Hinzufügen fehlgeschlagen',
    Load = 'Partner Abrufen fehlgeschlagen',
    Modify = 'Partner Bearbeiten fehlgeschlagen',
    Remove = 'Partner Löschen fehlgeschlagen',
}

@Injectable()
export class PartnerListEffects {

    AddPartner$ = createEffect(() => this.actions$.pipe(
        ofType(PartnersActionTypes.AddPartner),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                createPartner(
                   ${stringifyIfNotNullOrUndefined(action.Payload.partner, 'partnerTypeId')}
                   ${stringifyIfNotNullOrUndefined(action.Payload.partner, 'automaticPartnerNo')}
                   ${stringifyIfNotNullOrUndefined(action.Payload.partner, 'partnerNo')}
                ) {${PartnerEntity.GqlFields.join(',')}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res => {
                    // if (res && res.createPartner) {
                    //     this.router.navigate(['/partner', res.createPartner.id]);
                    // }
                    const ret: Action[] = [];
                    if (res && res.createPartner) {
                        ret.push(
                            PartnersActionTypes.UpdatePartnerList({
                                Payload: [
                                    ...(store.partners.partnerList ? store.partners.partnerList : []),
                                    PartnerEntityFromBackend(res.createPartner),
                                ],
                            }),
                        );
                        if (action.Payload.mainOffice) {
                            ret.push(
                                PartnerOfficeActionTypes.AddPartnerOffice({
                                    Payload: { ...action.Payload.mainOffice, partnerId: res.createPartner.id },
                                }),
                            );
                        }
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    LoadPartnerList$ = createEffect(() => this.actions$.pipe(
        ofType(PartnersActionTypes.LoadPartnerList),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
            query {
                allPartner {${PartnerEntity.GqlFields.join(',')}}
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                map(res =>
                    res && res.allPartner
                        ? PartnersActionTypes.UpdatePartnerList({
                              Payload: res.allPartner.map(val => PartnerEntityFromBackend(val)),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    ModifyPartner$ = createEffect(() => this.actions$.pipe(
        ofType(PartnersActionTypes.ModifyPartner),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                changePartnerById(
                    id: ${action.Payload.id}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'mandateReference')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'partnerTypeId')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'partnerNo')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'automaticPartnerNo')}
                ) {${PartnerEntity.GqlFields.join(',')}}
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res => {
                    const ret: Action[] = [];
                    if (action.createNewSepa != null && action.createNewSepa) {
                        ret.push(
                            SepaActionTypes.AddSepa({
                                Payload: {
                                    partnerId: 0,
                                    userId: 0,
                                    faelligkeitsdatum: null,
                                },
                            }),
                        );
                    }
                    if (res?.changePartnerById) {
                        if (store.partners.partnerList) {
                            ret.push(
                                PartnersActionTypes.UpdatePartnerList({
                                    Payload: [
                                        ...store.partners.partnerList.map(val =>
                                            val.Id === action.Payload.id
                                                ? PartnerEntityFromBackend(res.changePartnerById)
                                                : val,
                                        ),
                                    ],
                                }),
                            );
                        }
                        if (res.changePartnerById.id === store.partners.partner?.Id) {
                            ret.push(
                                PartnersActionTypes.UpdatePartner({
                                    Payload: PartnerEntityFromBackend(res.changePartnerById),
                                }),
                            );
                        }
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    DeletePartner$ = createEffect(() => this.actions$.pipe(
        ofType(PartnersActionTypes.DeletePartner),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                deletePartner(id: ${action.Payload})
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res =>
                    res && res.deletePartner
                        ? PartnersActionTypes.UpdatePartnerList({
                              Payload: store.partners.partnerList.map(val =>
                                  val.Id === action.Payload ? val.Clone({ Deleted: true }) : val,
                              ),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    constructor(
        private router: Router,
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
    ) {}
}
