import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy,Component,Input,OnInit } from '@angular/core';
import { FormControl,ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { OpenEditJobspecificationPopup } from '../components/detail-views/detail-employee/employee-helper';
import { JobSpecificationEntity } from '../dave-data-module/entities/job-specification.entity';
import { State } from '../dave-data-module/State';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { QuantityTypeResolver } from '../dave-data-module/guards/quantity-type.resolver';

@Component({
    selector: 'app-job-specification-option[JobSpecificationEntity]',
    standalone: true,
    imports: [CommonModule, AppButtonModule, FontAwesomeModule, MatOptionModule, MatSelectModule, MatTooltipModule, ReactiveFormsModule],
    templateUrl: './job-specification-option.component.html',
    styleUrls: ['./job-specification-option.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class JobSpecificationOptionComponent {
    @Input()  public JobSpecificationEntity: JobSpecificationEntity;
    constructor(private store: Store<State>, private dialog: MatDialog, private quantityTypeRes: QuantityTypeResolver,) {
    }
    EditJobSpecification = (jsEntity: JobSpecificationEntity) => OpenEditJobspecificationPopup(jsEntity, this.dialog, this.store, this.quantityTypeRes);
}
