import { Injectable } from '@angular/core';
import { BaseEntityReloadResolver2 } from './resolver.helper';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { GetTimestampFromTime } from '@dave/types';
import { getMaterialGroupLatestUpdatedAt, getMaterialGroupsFetched } from '../State/selectors/material-group.selectors';
import { MaterialGroupActionTypes } from '../State/actions/material-group.actions';

@Injectable({
    providedIn: 'root',
})
export class MaterialGroupResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getMaterialGroupsFetched, getMaterialGroupLatestUpdatedAt, MaterialGroupActionTypes.Load, store,(d) => GetTimestampFromTime(d)+'');
    }
}
