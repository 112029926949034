import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    ResourceState,
    RESOURCE_KEY,
    selectAllResource,
    selectResourceEntities,
    selectResourceFetched,
    selectResourceLatestUpdatedAt,
} from '../../reducers/resource-dispo/resource.reducer';

const selectState = createFeatureSelector<ResourceState>(RESOURCE_KEY);

export const getResourceDictionary = createSelector(selectState, selectResourceEntities);
export const getResources = createSelector(selectState, selectAllResource);
export const getResourcesActive = createSelector(getResources, e => e.filter(v => !v.DeletedAt));
export const getResourcesFetched = createSelector(selectState, selectResourceFetched);
export const getResourceLatestUpdatedAt = createSelector(selectState, selectResourceLatestUpdatedAt);

export const getResourceById = (props: { id: number }) => createSelector(getResourceDictionary, (dictionary) => props?.id && dictionary[props.id]);
