<mat-drawer-container style="height: 100%">
    <div class="example-container">
        <div class="header">
            <button app-round-button class="toggle-view-button" (click)="Scan2DaveClicked$.emit(FolderId)" style="margin-left: 1rem;">
                <fa-icon [icon]="'arrow-left'"> </fa-icon>
            </button>
            <div class="scrollbar-wrapper">
            </div>
            <div class="header-buttons">
                <button
                    app-round-button
                    (click)="Reload()"
                    matTooltip="Daten abrufen"
                >
                    <fa-icon icon="redo"></fa-icon>
                </button>
                <mat-form-field floatLabel="never" class="search-bar" *ngIf="ShowDataSearch" color="accent">
                    <mat-label>Suche</mat-label>
                    <input type="text" [formControl]="DataSearchForm" matInput />

                    <button
                        class="clear-search"
                        (click)="DataSearchForm.setValue('')"
                        mat-icon-button
                        matSuffix
                        *ngIf="true"
                    >
                        <fa-icon icon="times"></fa-icon>
                    </button>
                </mat-form-field>
                <button app-round-button matTooltip="Suchen" (click)="ShowDataSearch = !ShowDataSearch">
                    <fa-icon [icon]="ShowDataSearch ? 'times' : 'search'"></fa-icon>
                </button>
                <button app-round-button matTooltip="Filtern" aria-label="Filtern" (click)="filter.Open()" [matBadge]="filterAmount$ | async">
                    <fa-icon icon="filter"></fa-icon>
                </button>

                <app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
                <button app-round-button matTooltip="Mehrfachauswahl" *ngIf="!Multiselect" (click)="ToggleMultiselect()">
                    <fa-icon icon="check-square"></fa-icon>
                </button>
            </div>
            <div *ngIf="Multiselect" class="header-buttons">
                <mat-divider vertical></mat-divider>
                <button
                    app-round-button
                    matTooltip="Verschieben"
                    [Disabled]="!(FileMultisilect$ | async).length"
                    (click)="ClickMove$.next()"
                >
                    <fa-icon icon="share"></fa-icon>
                </button>
                <button app-round-button matTooltip="Mehrfachauswahl beenden" (click)="ToggleMultiselect()">
                    <fa-icon icon="times"></fa-icon>
                </button>
                <mat-divider vertical></mat-divider>
            </div>
        </div>
        <ng-container>
            <div class="content">
                <cdk-virtual-scroll-viewport tvsItemSize="48" headerHeight="56" style="height: 100%">
                    <table
                        mat-table
                        [dataSource]="ShownElementsTableDataSource"
                        matSort
                        (matSortChange)="SortTableData($event)"
                        class="mat-elevation-z8 table"
                    >
                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox
                                    (change)="$event ? MultiselectMasterToggle() : null"
                                    [checked]="IsAllSelected$ | async"
                                    [indeterminate]="
                                        (FileMultisilect$.value.length > 0) &&
                                        !(IsAllSelected$ | async)
                                    "
                                    color="primary"
                                >
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row" style="width: 2rem">
                                <mat-checkbox
                                    (click)="$event.stopPropagation()"
                                    (change)="$event ? ClickMultiselect(row) : null"
                                    [checked]="FileMultisilect$.value.includes(row.documentId)"
                                    color="primary"
                                >
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                            <td mat-cell *matCellDef="let element">
                                <fa-icon [icon]="'file'"></fa-icon> {{ element.name }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="folderPath">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pfad</th>
                            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">

                                <a href="dms?documentId={{element.fileId}}" (click)="RouteToFile(element, $event)">{{ element.folderPath }}</a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="updatedAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Änderungsdatum</th>
                            <td mat-cell *matCellDef="let element">{{ element.updatedAt | date: 'dd.MM.yy' }}{{', '}}{{ element.updatedAt | date: 'mediumTime' }}</td>
                        </ng-container>
                        <ng-container matColumnDef="size">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Größe</th>
                            <td mat-cell *matCellDef="let element">{{(element.size | fileSize)}}</td>
                        </ng-container>
                        <ng-container matColumnDef="fileType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Dateityp</th>
                            <td mat-cell *matCellDef="let element">{{ element.fileType }}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="DisplayedColumns"></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: DisplayedColumns"
                            class="list-item"
                            (click)="ClickItem(row)"
                        ></tr>
                    </table>
                </cdk-virtual-scroll-viewport>
            </div>
        </ng-container>
    </div>
</mat-drawer-container>

