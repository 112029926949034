import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { AkkordEntity } from '../../../dave-data-module/entities/akkord/akkord.entity';
import { AkkordResolver } from '../../../dave-data-module/guards/akkord/akkord.resolver';
import { State } from '../../../dave-data-module/State';
import { getAkkordFetched, getAkkords } from '../../../dave-data-module/State/selectors/akkord/akkord.selector';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { calcKagetecAccordFactor, calcKagetecAccordStundenLohn } from '../piecework-calculation.helper';
import {
    BreakpointObserverService
} from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { appMatDialogDefaultConfig } from '../../../helper/helper';

@Component({
    selector: 'app-piecework-demo',
    templateUrl: './piecework-demo.component.html',
    styleUrls: ['./piecework-demo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PieceworkDemoComponent {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        panelClass: [...appMatDialogDefaultConfig.panelClass, 'custom-dialog-class-without-padding', 'custom-dialog-class-for-overlay-on-right-side'],
    };
    public CommissionForm = new UntypedFormGroup({
        nachlass: new UntypedFormControl(0),
        bonus: new UntypedFormControl(0),
    }) as FormGroupTyped<{
        nachlass: number;
        bonus: number;
    }>;
    public EmployeeForms = this.fb.array([]);
    public User$ = this.store.select(getUser);
    public Tasks$ = this.store.select(getAkkords).pipe(
        map(tasks => tasks?.map(t => Object.assign(t.Clone(), { quantityForm: new UntypedFormControl(0) /* as FormGroupTyped<number>*/ }))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public Salary$ = combineLatest([
        this.EmployeeForms.valueChanges,
        this.Tasks$.pipe(
            switchMap(t =>
                combineLatest(
                    t.map(t =>
                        t.quantityForm.valueChanges.pipe(
                            startWith(t.quantityForm.value),
                            map(quantityValue => Object.assign(t, { quantityValue })),
                        ),
                    ),
                ),
            ),
        ),
        this.CommissionForm.valueChanges.pipe(startWith(this.CommissionForm.value)),
    ]).pipe(
        map(
            ([values, tasks, nk]: [
                { percentage: number | null; time: number }[],
                (AkkordEntity & { quantityForm: UntypedFormControl; quantityValue: number })[],
                { nachlass: number; bonus: number },
            ]) => {
                const filteredValues = values.map(v => ({ ...v, percentage: v.percentage || 0, time: v.time || 0 }));
                const factors = filteredValues.map(v => calcKagetecAccordFactor(v.percentage / 100, v.time * 60 * 60 * 1000));
                const factorSum = factors.reduce((a, b) => a + b, 0);
                const taskSumme = tasks
                    .filter(t => t.quantityValue)
                    .map(t => t.Price * t.quantityValue)
                    .reduce((a, b) => a + b, 0);
                const lohnSumme = taskSumme * (1 + nk.bonus / 100) * (1 + nk.nachlass / 100);
                const stundenlohn = calcKagetecAccordStundenLohn(lohnSumme / 100, factorSum, filteredValues[0].percentage);
                return stundenlohn ? stundenlohn / 100 : 0;
            },
        ),
        startWith(0),
    );
    public Loading$ = this.store.select(getAkkordFetched).pipe(map(v => !v));
    constructor(private store: Store<State>, private fb: UntypedFormBuilder, akkordResolver: AkkordResolver, public DialogRef: MatDialogRef<PieceworkDemoComponent>, public BS: BreakpointObserverService) {
        const group = new UntypedFormGroup({ percentage: new UntypedFormControl(100), time: new UntypedFormControl(0) });
        this.EmployeeForms.push(group);
        akkordResolver.resolve();
    }
    public AddEmployeeLine() {
        const group = new UntypedFormGroup({ percentage: new UntypedFormControl(100), time: new UntypedFormControl(0) });
        this.EmployeeForms.push(group);
    }
}
