import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveListCardModule } from '../dave-list-card/dave-list-card.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { GroupListComponent } from './group-list.component';



@NgModule({
    declarations: [GroupListComponent],
    imports: [CommonModule, DaveListCardModule, AppButtonModule, DaveDoubleIconModule, MatTooltipModule, MatMenuModule, FontAwesomeModule],
    exports: [GroupListComponent],
})
export class GroupListModule {}
