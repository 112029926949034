<div
    class="click-overlay"
    *ngIf="Mobile && !Disabled"
    (click)="matDatePicker.open()"
></div>

<mat-form-field [color]="Color">
    <input
        matInput
        [matDatepicker]="matDatePicker"
        (dateChange)="InputEvent($event)"
        [placeholder]="Key"
        [formControl]="DateFormControl"
        [max]="Max"
        [min]="Min"
        [required]="Required"
    />

    <mat-datepicker-toggle
        matSuffix
        [for]="matDatePicker"
    ></mat-datepicker-toggle>

    <mat-datepicker #matDatePicker [touchUi]="Mobile"></mat-datepicker>

    <mat-error *ngIf="DateFormControl.invalid">
        {{ GetErrorMessage(DateFormControl) }}
    </mat-error>
</mat-form-field>
