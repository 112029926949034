<h2 mat-dialog-title>Ordner Freigaben
	<ng-container *ngIf="New">anlegen</ng-container>
	<ng-container *ngIf="!New">bearbeiten</ng-container>
</h2>
<mat-dialog-content>
    <ng-container *ngIf="!Loading">
        <app-select-search-legacy
            [Placeholder]="'Benutzer'"
            [Values]="Users$|async"
            [Key]="Form.controls.User.value"
            (SelectionChange)="Form.controls.User.setValue($event.value)"
        >
        </app-select-search-legacy>

        <h4>Berechtigungen</h4>
        <mat-checkbox checked="true" disabled="true" name="view">
            <fa-icon size="xs" icon="eye"></fa-icon>
            ansehen
        </mat-checkbox>
        <br>
        <mat-checkbox [disabled]="!New" (change)="Form.controls.Edit.setValue($event.checked)"
                      [checked]="this.Form.controls.Edit.value" name="edit">
            <fa-icon size="xs" icon="pen"></fa-icon>
            bearbeiten
        </mat-checkbox>

        <!--<h4 *ngIf="From.length !== 0">geerbt von</h4>
        <ng-container *ngFor="let data of From; let last = last;">
            <p *ngIf="data.FolderUser.FromParent !== 0">von übergeordneten Ordner: {{data.FromParentFolder.Name}}</p>
            <p *ngIf="data.FolderUser.FromChild !== 0">von Unterordner: {{data.FromChildFolder.Name}}</p>
            <p *ngIf="data.FolderUser.FromDocument !== 0">von datei: {{data.FromFile.Name}}</p>
            <p *ngIf="data.FolderUser.FromType === 'customer' && data.FolderUser.FromId !== 0">von {{CS.GetSingle$()|async}}
                : {{data.FromCustomer.DisplayName}}</p>
            <p *ngIf="data.FolderUser.FromType === 'commission' && data.FolderUser.FromId !== 0">von
                Auftrag: {{data.FromCommission.DisplayName}}</p>
            <hr *ngIf="!last">
        </ng-container>
    -->
    </ng-container>
    <app-loading *ngIf="Loading"></app-loading>


</mat-dialog-content>
<mat-dialog-actions>
	<div class="buttons">
		<div class="cancel-button-wrapper">
			<button app-button Inverted mat-dialog-close="">Abbrechen</button>
		</div>
		<button app-button *ngIf="New" (click)="Save()" [Disabled]="Form.value.User === null || Loading">Speichern</button>
		<button app-button *ngIf="!New && Data.FolderUsers === undefined" (click)="Delete()" [Disabled]="Loading">Löschen</button>
	</div>
</mat-dialog-actions>
