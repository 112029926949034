<app-detail-list-template [Data]="PersonMainData$ | async" class="bank-data" [Editing]="Editing$ | async" [Wrapped]="!IsModal"> </app-detail-list-template>
<ng-template #emailTemplate let-formControl="formControl">
    <div class="email-form-wrapper">
        <app-textfield [Key]="'E-Mail'" [Value]="formControl" [Hint]="(OtherPersonsWithSameEmail$ | async)?.length ? 'Diese E-Mail Adresse wurde schon vergeben' : null"></app-textfield>
        <div class="duplicate-person-button-wrapper">
            <button app-round-button *ngFor="let person of OtherPersonsWithSameEmail$ | async" [matTooltip]="person.DisplayName" [routerLink]="['/', ContactBookMeta.Path, person.Id]">
                <app-double-icon [Icon]="ContactBookMeta.Icon" Icon2="arrow-right"></app-double-icon>
            </button>
        </div>
    </div>
</ng-template>
<ng-template #secondNameTemplate let-formControl="formControl" let-key="key">
    <app-textfield *ngIf="PersonForm.controls.Salutation.value === Salutation.life_companions" [Key]="key" [Value]="formControl"></app-textfield>
</ng-template>
<ng-template #personTypeTemplate >
    <div class="selectPersonTypes">
       <mat-form-field>
           <app-select-search
               [placeholder]="'Rolle'"
               [formControl]="PersonForm.controls.PersonTypeId"
               [Options]="PersonTypes"
           >
           </app-select-search>
       </mat-form-field>
        <button app-round-button matTooltip="neue Rolle anlegen" (click)="newPersonTypePopUp()"><fa-icon icon="plus"></fa-icon></button>
    </div>
</ng-template>
