import { Component, OnDestroy } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DropzoneConfig } from 'ngx-dropzone-wrapper';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileEntityFromFileBackend } from '../../../../dave-data-module/entities/file.entity';
import { State } from '../../../../dave-data-module/State';
import { FilesActionTypes } from '../../../../dave-data-module/State/actions/files.actions';
import { getToken } from '../../../../dave-data-module/State/selectors/base.selectors';
import { appMatDialogDefaultConfig } from '../../../../helper/helper';

export type EmailFileUploadFileSystemComponentDialogReturnData = number[] | undefined;
export type EmailFileUploadFileSystemComponentDialogData = undefined;

@Component({
    selector: 'app-email-file-upload-file-system',
    templateUrl: './email-file-upload-file-system.component.html',
    styleUrls: ['./email-file-upload-file-system.component.scss'],
})
export class EmailFileUploadFileSystemComponent implements OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        maxHeight: '100%',
        maxWidth: '100%',
        height: '50vh',
        minWidth: '35vw',
    };
    public NoFileAdded = true;
    public SelectedFiles = new BehaviorSubject<number[]>([]);
    public UploadedFiles: number[] = [];
    public Saved = false;

    public DropzoneConfig$ = this.store.select(getToken).pipe(
        map(
            (token) =>
                ({
                    autoProcessQueue: true,
                    uploadMultiple: false,
                    paramName: () => 'file',
                    parallelUploads: 100,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {},
                } as DropzoneConfig),
        ),
    );
    constructor(private store: Store<State>, private dialogRef: MatDialogRef<EmailFileUploadFileSystemComponent,EmailFileUploadFileSystemComponentDialogReturnData>) {}
    public OnUploadError([file, message]) {
        console.error({file, message});
        if (file.previewElement) {
            this.removeProgressClass(file.previewElement);
        }
    }

    public OnUploadSuccess([file, files, progressEvent]) {
        if (file.previewElement) {
            this.removeProgressClass(file.previewElement);
        }
        const dataId = files[0].id;
        const temp = this.SelectedFiles.value.slice();
        temp.push(dataId);
        this.SelectedFiles.next(temp);
        this.UploadedFiles.push(dataId);
        this.store.dispatch(
            FilesActionTypes.UpdateOne({
                Payload: FileEntityFromFileBackend(files[0]),
            }),
        );
        // this.store.dispatch(
        // FilesActionTypes.ModifyFile({
        //     Payload: {
        //         id: dataId,
        //     },
        // }),
        // );
    }

    public OnAddedFile(event: any) {
        this.NoFileAdded = false;
    }
    CloseAndSave() {
        this.dialogRef.close(this.SelectedFiles.value);
        this.Saved = true;
    }
    ngOnDestroy(): void {
        if (!this.Saved) {
            this.UploadedFiles.forEach((fileId) => this.store.dispatch(FilesActionTypes.DeleteFileRequest({ Payload: { DocumentId: fileId.toString() } })));
        }
    }
    removeProgressClass(previewElement: Element) {
        for (let node of previewElement.querySelectorAll('[data-dz-uploadprogress].indeterminate-progress')) {
            node.classList.remove('indeterminate-progress');
        }
    }
    addProgressClass(previewElement: Element) {
        for (let node of previewElement.querySelectorAll('[data-dz-uploadprogress]')) {
            node.classList.add('indeterminate-progress');
        }
    }
    onDropzoneUploadProgress([file, progress, bytesSent]) {
        if (progress === 100 && file.previewElement) {
            this.addProgressClass(file.previewElement);
        }
    }
}
