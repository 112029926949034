import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from "@angular/material/dialog";
import { Params } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventTypeEntity } from '../../../dave-data-module/entities/event-type.entity';
import { GetFileMimeTypeText } from '../../../dave-data-module/entities/file.entity';
import { SearchResultEntity, SearchResultType, SearchTypeEnum } from '../../../dave-data-module/entities/search-result.entity';
import { BackendDateTimestamp } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { getEventTypes } from '../../../dave-data-module/State/selectors/event-type.selector';
import { DetailTasksComponent, DetailTasksComponentDialogData } from '../../../dave-event-card/components/detail-tasks/detail-tasks.component';
import { CustomLabelService } from '../../../services/custom-label.service';
import {
    AllCommissionMeta, AllEventsMeta, AllGeneratedDocumentsMeta,
    CommissionMeta,
    ContactBookMeta, CustomerAdministrationMeta,
    DMSPageMeta, DocumentEditorMeta, GeneratedDocumentsPageMeta, HistoryMeta, TaskPageMeta,
    UserAdministrationMeta
} from "../../../helper/page-metadata";
import {
    DaveFilePreviewComponent,
    DaveFilePreviewComponentDialogData
} from "../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component";

interface displayResultData {
    theme: string;
    icon: IconProp;
    route?: any[];
    queryParams?: Params | null;
    text: any[];
    onClick?: () => void;
}

interface availableType {
    icon: IconProp | null;
    name: string;
    type: SearchTypeEnum;
    selected: boolean;
}

interface displayResult {
    result: SearchResultType;
    data: displayResultData;
}

@Component({
    selector: 'app-search-return',
    templateUrl: './search-return.component.html',
    styleUrls: ['./search-return.component.scss'],
})
export class SearchReturnComponent implements OnInit, OnDestroy {
    @Output() selectedTypes = new EventEmitter<SearchTypeEnum[]>();
    protected subscriptions: Subscription[] = [];
    availableTypesConfig$: Observable<availableType[]> = combineLatest([this.cls.getMultiple$('Commission'), this.cls.getMultiple$('Customer')]).pipe(map(([comissionLabel, customerLabel])=> [
            {
                type: null,
                icon: 'search',
                name: 'Bestes Ergebnis',
                selected: true,
            },
            {
                type: SearchTypeEnum.person,
                icon: 'user',
                name: 'Kontakte',
                selected: false,
            },
            {
                type: SearchTypeEnum.commission,
                icon: 'file-contract',
                name: comissionLabel,
                selected: false,
            },
            {
                type: SearchTypeEnum.document,
                icon: 'file',
                name: 'Dokument',
                selected: false,
            },
            {
                type: SearchTypeEnum.event,
                icon: 'history',
                name: 'Ereignisse',
                selected: false,
            },
            {
                type: SearchTypeEnum.customer,
                icon: 'building',
                name: customerLabel,
                selected: false,
            },
            {
                type: SearchTypeEnum.employee,
                icon: 'users',
                name: 'Mitarbeiter',
                selected: false,
            },
            {
                type: SearchTypeEnum.generated_document,
                icon: 'file-contract',
                name: 'Berichte',
                selected: false,
            },
        ]
    ));
    AvailableTypesInResult$ = new BehaviorSubject<SearchTypeEnum[]>([]);
    SelectedTypes$ = new BehaviorSubject<SearchTypeEnum[]>([]);
    AvailableTypes$ = combineLatest([this.AvailableTypesInResult$, this.SelectedTypes$, this.availableTypesConfig$]).pipe(
        map(([inResult, selectedTypes, availableTypesConfig]) => {
            return availableTypesConfig
                .filter((t) => inResult.includes(t.type))
                .map((t) => {
                    let selectAll = selectedTypes.length == 0;
                    return {
                        type: t.type,
                        icon: t.icon,
                        name: t.name,
                        selected: selectAll ? t.type === null : selectedTypes.includes(t.type),
                    } as availableType;
                });
        }),
    );
    displayResults$ = new BehaviorSubject<displayResult[]>([]);
    displayResultsFiltered$: Observable<displayResult[]> = combineLatest([this.displayResults$, this.SelectedTypes$]).pipe(
        map(([res, selected]) => {
            if (selected.length == 0) {
                return res;
            } else {
                return res.filter((r) => selected.includes(r.result.type));
            }
        }),
    );
    protected _returns: SearchResultEntity;

    get Returns(): SearchResultEntity {
        return this._returns;
    }

    @Input() set Returns(value: SearchResultEntity) {
        let types = value.AvailableTypes.slice();
        types.push(null);
        this.AvailableTypesInResult$.next(types);
        this.displayResults$.next(
            value.Found.map((f) => {
                let data: displayResultData;

                switch (f.type) {
                    case SearchTypeEnum.commission:
                        data = {
                            theme: CommissionMeta.Theme,
                            icon: CommissionMeta.Icon,
                            route: ['/', CommissionMeta.Path, AllCommissionMeta.Path, f.commission.id],
                            text: [f.commission.interneNummer, f.commission.description, f.commission.auftragsnummer, this.buildFromParts(', ', f.commission.street, f.commission.postalCode, f.commission.city, f.commission.country)],
                        };
                        break;
                    case SearchTypeEnum.employee:
                        data = {
                            theme: UserAdministrationMeta.Theme,
                            icon: UserAdministrationMeta.Icon,
                            route: ['/', UserAdministrationMeta.Path, f.employee.id],
                            text: [
                                this.buildFromParts(' ', f.employee.firstname, f.employee.lastname),
                                f.employee.jobSpecification,
                                f.employee.phoneNumber,
                                f.employee.mobileNumber,
                                this.buildFromParts(', ', f.employee.street, f.employee.postalCode, f.employee.city, f.employee.country),
                            ],
                        };
                        break;
                    case SearchTypeEnum.generated_document:
                        data = {
                            theme: GeneratedDocumentsPageMeta.Theme,
                            icon: GeneratedDocumentsPageMeta.Icon,
                            route: ['/', GeneratedDocumentsPageMeta.Path, AllGeneratedDocumentsMeta.Path, DocumentEditorMeta.Path, f.generated_document.id],
                            text: [f.generated_document.name],
                        };
                        break;
                    case SearchTypeEnum.document:
                        let m = BackendDateTimestamp(f.document.updatedAt);
                        data = {
                            theme: DMSPageMeta.Theme,
                            icon: DMSPageMeta.Icon,
                            // route: ['/', DMSPageMeta.Path],
                            // queryParams: {
                            //     documentId: f.document.id,
                            // },
                            onClick: () => this.openFileDialog(f.document.id),
                            text: [f.document.name, GetFileMimeTypeText(f.document.mimeType), this.datePipe.transform(m, 'dd.MM.yy') + ' ' + this.datePipe.transform(m, 'mediumTime'), f.document.description],
                        };
                        break;
                    case SearchTypeEnum.person:
                        data = {
                            theme: ContactBookMeta.Theme,
                            icon: ContactBookMeta.Icon,//'user',
                            route: ['/', ContactBookMeta.Path, f.person.id],
                            text: [
                                this.buildFromParts(' ', f.person.firstname, f.person.lastname),
                                f.person.email,
                                f.person.phoneNumber,
                                f.person.mobileNumber,
                                f.person.faxNumber,
                                f.person.description,
                                this.buildFromParts(', ', f.person.street, f.person.postalCode, f.person.city, f.person.country),
                            ],
                        };
                        break;
                    case SearchTypeEnum.customer:
                        data = {
                            theme: CustomerAdministrationMeta.Theme,
                            icon: CustomerAdministrationMeta.Icon,
                            route: ['/', CustomerAdministrationMeta.Path, f.customer.id],
                            text: [f.customer.name, f.customer.customerNo, f.customer.description],
                        };
                        break;
                    case SearchTypeEnum.event:
                        if (f.event.is_task) {
                            data = {
                                theme: TaskPageMeta.Theme,
                                icon:  TaskPageMeta.Icon,
                                route: ['/',  TaskPageMeta.Path, f.event.id],
                                text: [f.event.name, f.event.description],
                            };
                        } else {
                            data = {
                                theme: HistoryMeta.Theme,
                                icon: HistoryMeta.Icon,
                                route: ['/', HistoryMeta.Path, AllEventsMeta.Path, f.event.id],
                                text: [f.event.name, f.event.description],
                            };
                        }
                        break;
                }

                data.text = data.text.filter((v) => !!v);

                return {
                    result: f,
                    data: data,
                } as displayResult;
            }),
        );
        this._returns = value;
    }

    private previewDialogRef: MatDialogRef<DaveFilePreviewComponent | DetailTasksComponent>

    constructor(private readonly store: Store<State>, private dialog: MatDialog, private datePipe: DatePipe, protected cls: CustomLabelService) {
    }

    buildFromParts(separator: string, ...parts: (string | null)[]): string | null {
        let out = parts.filter((v) => !!v && v !== '');
        return out.length == 0 ? null : out.join(separator);
    }

    ToggleResultType(event: MouseEvent, type: SearchTypeEnum | null) {
        event.stopPropagation();
        if (type === null) {
            if (this.SelectedTypes$.getValue().length != 0) {
                this.SelectedTypes$.next([]);
            }
        } else {
            if (this.SelectedTypes$.getValue().includes(type)) {
                let arr = this.SelectedTypes$.getValue().slice();
                arr.splice(arr.indexOf(type), 1);
                this.SelectedTypes$.next(arr);
            } else {
                let arr = this.SelectedTypes$.getValue().slice();
                arr.push(type);
                if (arr.length == this.AvailableTypesInResult$.getValue().length - 1) {
                    arr = [];
                }
                this.SelectedTypes$.next(arr);
            }
        }
    }

    OpenTaskDialog(EventId: number) {
        if(this.previewDialogRef && this.previewDialogRef.getState() !== MatDialogState.CLOSED) {
            this.previewDialogRef.close();
        }
        this.previewDialogRef = this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
            ...DetailTasksComponent.DefaultConfig,
            data: {
                EventId,
            },
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.SelectedTypes$.subscribe((types) => {
                this.selectedTypes.emit(types.slice());
            }),
        );
    }
    openFileDialog(fileId: number) {
        if(this.previewDialogRef && this.previewDialogRef.getState() !== MatDialogState.CLOSED) {
            this.previewDialogRef.close();
        }
        this.previewDialogRef = this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: {
                fileId,
            },
        });
    }
}
