import { createAction, props } from '@ngrx/store';
import { StatusFromSVEntity } from '../../entities/statusFromSV.entity';


export const StatusFromSVActionTypes = {
    UpdateStatusFromSV: createAction(
        'data/StatusFromSV/update',
        props<{ Payload: Array<StatusFromSVEntity> }>(),
    ),
    GetStatusFromSVRequest: createAction(
        'data/StatusFromSV/getAll',
    ),
};

