import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { WorkDayActionTypes } from '../State/actions/work-day.actions';
import { getWorkDayLatestUpdatedAt, getWorkDaysFetched } from '../State/selectors/work-day.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class WorkDayResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getWorkDaysFetched, getWorkDayLatestUpdatedAt, WorkDayActionTypes.Load, store);
    }
}
