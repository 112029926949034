import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from '../index';
import {baseFeatureKey, BaseState} from '../reducers/base.reducer';

export const selectData = createFeatureSelector< BaseState>(
    baseFeatureKey
);

export const getIsPerformingLogin = createSelector(
    selectData,
    data => data.performingLogin
);

export const getToken = createSelector(
    selectData,
        data => data.token
);
export const getTokenPayload = createSelector(
    selectData,
        data => data.tokenPayload
);
