import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Store} from '@ngrx/store';
import {filter, take, tap} from 'rxjs/operators';
import {State} from '../State';
import {getTransmission} from '../State/selectors/transmission.selector';
import {TransmissionActionTypes} from '../State/actions/transmission.actions';
import {TransmissionEntity} from '../entities/transmission.entity';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TransmissionResolver implements Resolve<TransmissionEntity[]> {
    constructor(private store: Store<State>) {
    }

    resolve() {
        const value$ = this.store.select(getTransmission);

        value$
            .pipe(
                filter(value => !value),
                take(1),
                tap(() => {
                    if (localStorage.getItem('token') != null) {
                        this.store.dispatch(TransmissionActionTypes.GetTransmissionRequest());
                    }
                })
            )
            .subscribe();

        return value$.pipe(
            filter(value => !!value),
            take(1)
        );
    }
}
