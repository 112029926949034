import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { ProcessTemplateActions } from '../State/actions/process-template.actions';
import { getProcessTemplateFetched } from '../State/selectors/process-template.selector';
import { BaseEntityResolver } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class ProcessTemplateResolver extends BaseEntityResolver {
    constructor(store: Store<State>) {
        super(getProcessTemplateFetched, ProcessTemplateActions.load({Payload: {}}), store);
    }
}
