import { createAction, props } from '@ngrx/store';
import {
    DaveMutationChangeNewsArgs,
    DaveMutationCreateNewsArgs,
    DaveMutationDeleteNewsArgs,
} from '../../graphql-types';
import { NewsEntity } from '../../entities/news.entity';


export const NewsActionTypes = {
    LoadAll: createAction('data/News/loadAll'),
    Change: createAction('data/News/modify', props<{ Payload: DaveMutationChangeNewsArgs }>()),
    Create: createAction('data/News/create', props<{ Payload: DaveMutationCreateNewsArgs }>()),
    Delete: createAction('data/News/delete', props<{ Payload: DaveMutationDeleteNewsArgs }>()),

    UpdateAll: createAction('data/News/updateAll', props<{ Payload: Array<NewsEntity> }>()),
    UpdateOne: createAction('data/News/updateOne', props<{ Payload: NewsEntity }>()),
    RemoveOne: createAction('data/News/removeOne', props<{ Payload: number }>()),
};
