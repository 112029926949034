import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { DaveMutationConvertPdfToImageArgs } from '../dave-data-module/graphql-types';
import { HttpService } from '../dave-data-module/services/http.service';
import { State } from '../dave-data-module/State';

const queryTemplate = `mutation {
  convertPDFToImage(hasOnlyOnePage: {{hasOnlyOnePage}}, versionId: {{versionId}}) {
    documentIds
    hasOnlyOnePage
  }
}`;
@Injectable({
    providedIn: 'root',
})
export class PdfToolsService {
    constructor(private store: Store<State>, private api: HttpService) {}
    convertPDFToImage(Payload: DaveMutationConvertPdfToImageArgs) {
        return firstValueFrom(this.api.graphQl({ query: queryTemplate.replace('{{hasOnlyOnePage}}', Payload.hasOnlyOnePage.toString()).replace('{{versionId}}', Payload.versionId.toString()) })).then((res) => {
            return res.convertPDFToImage;
        });
    }
}
