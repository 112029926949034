import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ProcessTemplateEntity } from '../dave-data-module/entities/process-template.entity';
import { ProcessEntity, ProcessState } from '../dave-data-module/entities/process.entity';
import { ProcessTemplateResolver } from '../dave-data-module/guards/process-template.resolver';
import { ProcessResolver } from '../dave-data-module/guards/process.resolver';
import { State } from '../dave-data-module/State';
import { ProcessActions } from '../dave-data-module/State/actions/process.actions';
import { getProcessTemplateActive, getProcessTemplateFetched } from '../dave-data-module/State/selectors/process-template.selector';
import { getProcessesActive, getProcessFetched } from '../dave-data-module/State/selectors/process.selector';
import { DetailListDialogReturn, DetailListTemplateDialogComponent, DetailListTemplateDialogData } from '../detail-list-template-dialog/components/detail-list-template-dialog.component';
import { sortByDateProperty, uniqArray } from '../helper/helper';

@Injectable({
    providedIn: 'root',
})
export class ProcessService {
    constructor(private processTemplateResolver: ProcessTemplateResolver, private processResolver: ProcessResolver, private dialog: MatDialog, private store: Store<State>) {}
    openAddFileToProcessDialog(fileIds: number[]) {
        firstValueFrom(
            combineLatest([
                this.store.select(getProcessTemplateFetched).pipe(
                    tap((fetched) => {
                        if (!fetched) {
                            this.processTemplateResolver.resolve();
                        }
                    }),
                    filter((fetched) => fetched),
                    switchMap(() => this.store.select(getProcessTemplateActive)),
                ),
                this.store.select(getProcessFetched).pipe(
                    tap((fetched) => {
                        if (!fetched) {
                            this.processResolver.resolve();
                        }
                    }),
                    filter((fetched) => fetched),
                    switchMap(() => this.store.select(getProcessesActive)),
                ),
            ]),
        ).then(([processTemplates, processes]) => {
            const form = new FormGroup({
                processTemplate: new FormControl<{ Id: number }>(null),
                process: new FormControl<{ Id: number }>(null, Validators.required),
            });
            this.dialog
                .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                    ...DetailListTemplateDialogComponent.DefaultConfig,
                    data: {
                        DisableSaveButton$: form.statusChanges.pipe(
                            startWith(form.status),
                            map((state) => state !== 'VALID'),
                        ),
                        Editing: true,
                        Data: {
                            Headline: 'Zu Prozess hinzufügen',
                            Properties: [
                                {
                                    key: ProcessTemplateEntity.EntityName,
                                    formControl: form.controls.processTemplate,
                                    options: {
                                        specialInput: {
                                            singleSelectSearch: {
                                                options: processTemplates.map((t) => ({
                                                    Id: t.Id,
                                                    optionLabel: t.DisplayName,
                                                })),
                                                compareOptions: (a, b) => a.Id === b.Id,
                                            },
                                        },
                                    },
                                },
                                {
                                    key: ProcessEntity.EntityName,
                                    formControl: form.controls.process,
                                    options: {
                                        specialInput: {
                                            singleSelectSearch: {
                                                compareOptions: (a, b) => a.Id === b.Id,
                                                options$: form.controls.processTemplate.valueChanges.pipe(
                                                    startWith(form.controls.processTemplate.value),
                                                    map((template) =>
                                                        processes
                                                            .filter((p: ProcessEntity) => !template || p.ProcessTemplateId === template?.Id)
                                                            .filter((p: ProcessEntity) => p.State !== ProcessState.ProcessStateArchived)
                                                            .filter((p: ProcessEntity) => p.State !== ProcessState.ProcessStateCanceled)
                                                            .filter((p: ProcessEntity) => p.State !== ProcessState.ProcessStateDone)
                                                            .sort(sortByDateProperty('CreatedAt'))
                                                            .reverse()
                                                            .map((p) => ({
                                                                Id: p.Id,
                                                                optionLabel: p.DisplayName,
                                                            })),
                                                    ),
                                                ),
                                            },
                                        },
                                    },
                                },
                            ],
                        },
                    },
                })
                .afterClosed()
                .subscribe((ret) => {
                    if (ret.Action === 'save') {
                        const process = processes.find((p) => p.Id === form.value.process.Id);
                        this.store.dispatch(
                            ProcessActions.change({
                                Payload: {
                                    Id: process.Id.toString(),
                                    DocumentIds: uniqArray([...process.DocumentIds, ...fileIds].map((id) => id.toString())),
                                    EmailIds: process.EmailIds.map((id) => id.toString()),
                                    // EventIds: process.EventIds.map(toString),
                                    PropertyConfigValues: {},
                                    AddTasksToStep: {},
                                    SetDeadlines: {},
                                },
                            }),
                        );
                    }
                });
        });
    }
}
