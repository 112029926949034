<angular-image-viewer #imageViewer [src]="Images$ | async" [index]="Index"
                      [config]="ImageViewerConfig"></angular-image-viewer>
<button app-round-button [Disabled]="images.length < 2" *ngIf="Images$ | async as images"
        (click)="Index = Index === 0 ? images?.length - 1 : Index - 1; imageViewer.reset(); $event.preventDefault()"
        style="position: absolute; left: 1rem; top: 50%;" matTooltip="Vorheriges Bild">
    <fa-icon icon="chevron-left"></fa-icon>
</button>
<button app-round-button [Disabled]="images.length < 2" *ngIf="Images$ | async as images"
        (click)="Index = Index === images?.length - 1 ? 0 : Index + 1; imageViewer.reset(); $event.preventDefault()"
        style="position: absolute; right: 1rem; top: 50%;" matTooltip="Nächstes Bild">
    <fa-icon icon="chevron-right"></fa-icon>
</button>
<button mat-icon-button matDialogClose class="close-mat-dialog-button" matTooltip="Schließen">
    <fa-icon icon="times" size="2x"></fa-icon>
</button>

<app-burger-button-wrapper class="button-wrapper">
    <button app-round-button (click)="imageViewer.zoomIn();" matTooltip="Vergrößern">
        <fa-icon icon="plus"></fa-icon>
    </button>
    <button app-round-button (click)="imageViewer.zoomOut();" matTooltip="Verkleinern">
        <fa-icon icon="minus"></fa-icon>
    </button>
    <button app-round-button (click)="imageViewer.rotateCounterClockwise();"
            matTooltip="Gegen den Uhrzeigersinn drehen">
        <fa-icon icon="undo"></fa-icon>
    </button>
    <button app-round-button (click)="imageViewer.rotateClockwise();" matTooltip="Im Uhrzeigersinn drehen">
        <fa-icon icon="redo"></fa-icon>
    </button>
    <button app-round-button (click)="OpenInEditor();" matTooltip="Im Editor bearbeiten">
        <fa-icon icon="edit"></fa-icon>
    </button>
    <button app-round-button (click)="OpenMailDialog()" matTooltip="per E-Mail senden" aria-label="per E-Mail senden">
        <fa-icon icon="paper-plane"></fa-icon>
    </button>
    <button
        app-round-button
        matTooltip="herunterladen"
        aria-label="herunterladen"
        (click)="download()"
    >
        <fa-icon icon="download"></fa-icon>
    </button>
    <button
        app-round-button
        *ngIf="file?.PartnerId === (Partner$ | async)?.Id"
        (click)="fileUpload.click()"
        [Disabled]="!(HasEditPermissions$ | async)"
        matTooltip="Neue Version hochladen"
        aria-label="Neue Version hochladen"
    >
        <fa-icon icon="upload"></fa-icon>
    </button>
    <button
        app-round-button
        matTooltip="Verschieben"
        aria-label="Verschieben"
        (click)="moveImage()"
    >
        <fa-icon icon="share"></fa-icon>
    </button>
    <button
        app-round-button
        matTooltip="Löschen"
        aria-label="Löschen"
        (click)="ClickDelete()"
    >
        <fa-icon icon="trash"></fa-icon>
    </button>

    <button app-round-button matTooltip="Prozess" aria-label="Prozess" [matMenuTriggerFor]="processMenu">
        <fa-icon [icon]="ProcessMeta.Icon"></fa-icon>
    </button>
    <button style="margin-bottom: .4rem" app-round-button matTooltip="Verknüpfung erstellen" aria-label="Verknüpfung erstellen"
            (click)="createLink()">
        <app-double-icon [Icon]="FileLinkIcon" Icon2="plus"></app-double-icon>
    </button>
    <button app-round-button matTooltip="Aufgabe erstellen" aria-label="Aufgabe erstellen" (click)="createTaskDialog()">
        <app-double-icon [Icon]="TaskPageMeta.Icon" Icon2="plus"></app-double-icon>
    </button>
    <!--    <button app-round-button (click)="imageViewer.reset()" matTooltip="Zurücksetzen">-->
    <!--        <fa-icon icon="undo"></fa-icon>-->
    <!--    </button>-->
</app-burger-button-wrapper>

<input type="file" id="file" (change)="Upload$.next($event.target.files)" hidden [accept]="file?.MIMEType" #fileUpload />
<mat-menu #processMenu="matMenu">
    <button mat-menu-item (click)="onAddToProcessClick()">
        <fa-icon class="mat-icon" icon="pencil-alt"></fa-icon>
        Zu Prozess hinzufügen
    </button>
    <button mat-menu-item (click)="onStartProcessClick()">
        <fa-icon class="mat-icon" icon="plus"></fa-icon>
        Prozess mit Dokument starten
    </button>
</mat-menu>
