<mat-card *ngIf="!Inline else content">
    <h5 class="headline">
        {{Headline}}<span *ngIf="CurrentEventType">: {{ CurrentEventType.Name }}</span><span *ngIf="!CurrentEventType">*</span>
    </h5>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</mat-card>
<ng-template #content>
    <div class="flex-row">
        <div *ngIf="!Editing || !(IsMobile$ | async)">
            <button
                type="button"
                *ngFor="let EventTypeItem of EventTypeList"
                app-round-button
                class="button"
                [Inverted]="CurrentEventType === EventTypeItem && !Deleting"
                [matTooltip]="EventTypeItem.Name"
                (click)="OnEventTypeClick(EventTypeItem)"
            >
                <fa-icon
                    *ngIf="EventTypeItem.ImagePath; else letter"
                    [icon]="EventTypeItem.ImagePath | icon"
                ></fa-icon>

                <ng-template #letter>
                    <span>{{ EventTypeItem.Name.slice(0, 1).toUpperCase() }}</span>
                </ng-template>

                <fa-icon
                    *ngIf="Deleting && !EventTypeItem.IsDefault"
                    class="delete-icon"
                    icon="trash"
                ></fa-icon>
            </button>
        </div>

        <div class="new-event-type-container">
            <form (ngSubmit)="OnNewEventButtonClick()">
                <app-textfield
                    *ngIf="Editing"
                    [Required]="true"
                    Key="Neue Ereignisart"
                    [Value]="CustomEventTypeControl"
                ></app-textfield>
            </form>

            <div class="button-wrapper">
                <button
                    type="button"
                    *ngIf="Editing"
                    app-round-button
                    class="button"
                    matTooltip="Neue Ereignisart auswählen"
                    (click)="OpenIconSelectDialog()"
                >
                    <fa-icon [icon]="CustomImagePath || 'fas,faIcons' | icon"></fa-icon>
                </button>

                <button
                    type="button"
                    *ngIf="!Editing"
                    app-round-button
                    class="button"
                    Color="red"
                    matTooltip="Ereignisart löschen"
                    (click)="OnDeleteButtonClick()"
                >
                    <fa-icon [icon]="Deleting ? 'times' : 'trash'"></fa-icon>
                </button>

                <button
                    type="button"
                    app-round-button
                    class="button"
                    [ngClass]="SaveActive ? 'save-active' : '' "
                    matTooltip="Ereignisart hinzufügen"
                    (click)="OnNewEventButtonClick()"
                >
                    <fa-icon [icon]="NewEventButtonIcon()"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</ng-template>
