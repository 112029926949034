import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { SimplebarAngularModule } from 'simplebar-angular';
import { CkEditorComponent } from "../ck-editor/ck-editor.component";
import {
EventTypesSelectorCardComponent
} from '../components/templates/event-types-selector-card/event-types-selector-card.component';
import { TextareaModule } from '../components/templates/textarea/textarea.module';
import { DaveDmsModule } from '../dave-dms-module/dave-dms.module';
import { PreviewModule } from '../dave-dms-module/dave-dms/preview/preview.module';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveFileExplorerModule } from '../dave-file-explorer/dave-file-explorer.module';
import { DaveHistoryModule } from '../dave-history-module/dave-history.module';
import { DaveListCardModule } from '../dave-list-card/dave-list-card.module';
import { DaveSelectFileFromDmsModule } from '../dave-select-file-from-dms/dave-select-file-from-dms.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { PipesModule } from '../dave-utils-module/dave-shared-components-module/pipes/pipes.module';
import { IconPipeModule } from '../dave-utils-module/icon-pipe/icon-pipe.module';
import { SelectSearchModule } from '../dave-utils-module/select-search/select-search.module';
import { OptionsScrollDirective } from '../directives/options-scroll/options-scroll.directive';
import { IncludesPipe } from "../helper/includes.pipe";
import { MatMenuHeaderComponent } from "../mat-menu-header/mat-menu-header.component";
import { TimePickerSelectComponent } from "../time-picker-select/time-picker-select.component";
import { EndTimeDirective } from "../time-span-picker-select/end-time.directive";
import { StartTimeDirective } from "../time-span-picker-select/start-time.directive";
import { TimeSpanPickerSelectComponent } from "../time-span-picker-select/time-span-picker-select.component";
import { DaveLoadingModule } from './../dave-loading/dave-loading.module';
import { EmailClientComponent } from './components/email-client.component';
import { EmailFileUploadFileSystemComponent } from './components/email-file-upload/email-file-upload-file-system/email-file-upload-file-system.component';
import { EmailFileUploadReportsComponent } from './components/email-file-upload/email-file-upload-reports/email-file-upload-reports.component';
import { EmailPreviewComponent } from './components/email-preview/email-preview/email-preview.component';
import { EmailViewComponent } from './components/email-view/email-view.component';
import { EventFromEmailComponent } from './components/event-from-email/event-from-email.component';
import { DaveEmailRoutingModule } from './dave-email-routing.module';
import { EmailEditorCloseDialogComponent } from './components/email-editor-close-dialog/email-editor-close-dialog.component';

@NgModule({
    declarations: [
        EmailClientComponent,
        EventFromEmailComponent,
        EmailViewComponent,
        EmailFileUploadReportsComponent,
        EmailFileUploadFileSystemComponent,
        EmailPreviewComponent,
        EmailEditorCloseDialogComponent,
    ],
    imports: [
        NgClickOutsideDirective,
        DaveEmailRoutingModule,
        CommonModule,
        MatCardModule,
        MatAutocompleteModule,
        MatChipsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatFormFieldModule,
        FontAwesomeModule,
        // CKEditorModule,
        AppButtonModule,
        MatSelectModule,
        DaveSharedComponentsModule,
        DropzoneModule,
        DaveDmsModule,
        SimplebarAngularModule,
        MatDialogModule,
        DragDropModule,
        MatListModule,
        MatTableModule,
        RouterModule,
        MatTooltipModule,
        FormsModule,
        MatTabsModule,
        MatSidenavModule,
        MatToolbarModule,
        IconPipeModule,
        MatInputModule,
        MatButtonModule,
        PreviewModule,
        MatMenuModule,
        TextareaModule,
        SelectSearchModule,
        ScrollingModule,
        TableVirtualScrollModule,
        DaveFileExplorerModule,
        DaveListCardModule,
        MatExpansionModule,
        DaveSelectFileFromDmsModule,
        DaveHistoryModule,
        DaveLoadingModule,
        MatCheckboxModule,
        MatTreeModule,
        DaveDoubleIconModule,
        PipesModule,
        MatProgressBarModule,
        OptionsScrollDirective,
        CkEditorComponent,
        EventTypesSelectorCardComponent,
        MatMenuHeaderComponent,
        IncludesPipe,
        EndTimeDirective,
        MatDatepickerModule,
        StartTimeDirective,
        TimePickerSelectComponent,
        TimeSpanPickerSelectComponent,
    ],
    exports: [EmailViewComponent, EmailPreviewComponent],
})
export class DaveEmailModule {}
