import { Injectable } from '@angular/core';
import Holidays, { HolidaysTypes } from 'date-holidays';
import { FrontendDate } from '../dave-data-module/helper/backend-frontend-conversion.helper';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
@Injectable({
    providedIn: null,
    // useFactory: HolidayServiceFactory,
})
export class HolidayService {
    private holiday = new Holidays();
    constructor(country?: string, state?: string, region?: string, opts?: HolidaysTypes.Options) {
        this.Init(country, state, region, opts);
    }
    public Init(country?: string, state?: string, region?: string, opts?: HolidaysTypes.Options) {
        this.holiday.init(country, state, region, opts);
    }
    /**
     * @param language - ISO-639 language shortcode https://de.wikipedia.org/wiki/Liste_der_ISO-639-1-Codes
     */
    public GetCountries = (language: string = 'de') => this.holiday.getCountries(language);
    /**
     * @param country – shortcode of country
     * @param language - ISO-639 language shortcode https://de.wikipedia.org/wiki/Liste_der_ISO-639-1-Codes
     */
    public GetStates = (country: string = 'de', language: string = 'de') => this.holiday.getStates(country, language);
    /**
     * @param state – shortcode of state
     * @param country – shortcode of country
     * @param language - ISO-639 language shortcode https://de.wikipedia.org/wiki/Liste_der_ISO-639-1-Codes
     */
    public GetRegions = (state: string, country: string = 'de', language: string = 'de') => this.holiday.getRegions(country, state, language);

    public IsHoliday = (date: Date | string) => this.holiday.isHoliday(date);
    /**
     * @param date1 From Date
     * @param date2 To Date
     * @param weekendDays array of week days (from 0 = Sunday to 6 = Saturday)
     */
    public GetWorkingDayCount(date1: Date, date2: Date, isIllness? : boolean , weekendDays: number[] = [0, 6]) {
        const date = new Date(FrontendDate(date1, true));
        const endDate = new Date(FrontendDate(date2, true));
        let workingDayCount = 0;
        while (date <= endDate) {
                const holiday = this.holiday.isHoliday(date);
                if ( isIllness === true) {
                    if (!weekendDays.includes(date.getDay())) {
                        workingDayCount++;
                    }
                } else {
                    if ((!holiday || (holiday && !holiday?.some(h => h.type === 'public'))) && !weekendDays.includes(date.getDay())) {
                        workingDayCount++;
                    }
                }

            date.setDate(date.getDate() + 1);
        }
        return workingDayCount;
    }
}
export function HolidayServiceFactory(country: string = 'de', state?: string, region?: string, opts?: HolidaysTypes.Options) {
    return new HolidayService(country, state, region, opts);
}
