import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';
import {
    selectAllEntityRole,
    selectentityRoleEntities,
    selectEntityRoleFetched,
    EntityRoleState,
    ENTITY_ROLE_KEY,
} from '../reducers/entity-role.reducer';

const selectEntityRole = createFeatureSelector< EntityRoleState>(ENTITY_ROLE_KEY);
export const getEntityRoleDictionary = createSelector(selectEntityRole, selectentityRoleEntities);

export const getEntityRole = createSelector(selectEntityRole, selectAllEntityRole);
export const getEntityRoleFetched = createSelector(selectEntityRole, selectEntityRoleFetched);
export const getEntityRoleById = createSelector(
    getEntityRoleDictionary,
    (entityRole: ReturnType<typeof getEntityRoleDictionary>, Id: number) => Id && entityRole[Id],
);
