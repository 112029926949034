import { Person2EntityResolver } from '../dave-data-module/guards/person2Entity.resolver';
import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { DetailEventComponent } from '../components/detail-views/detail-event/detail-event.component';
import { HistorySideElementsComponent } from '../components/history/history-side-elements/history-side-elements.component';
import { HistoryComponent } from '../components/history/history.component';
import { TimelineComponent } from '../components/history/timeline/timeline.component';
import { NewEventComponent } from '../components/new-view/new-event/new-event.component';
import { TokenResolver } from '../dave-data-module/guards/token.resolver';
import { OfficeResolver } from '../dave-data-module/guards/office.resolver';
import { PartnerResolver } from '../dave-data-module/guards/partner.resolver';
import { TagResolver } from '../dave-data-module/guards/tag.resolver';
import { EmailConnectionResolver } from '../dave-data-module/guards/email-connection.resolver';
import { ValidTokenGuard } from '../dave-data-module/guards/valid-token.guard';
import { PermissionsResolver } from '../dave-data-module/guards/permissions.resolver';
import { BookmarkResolver } from '../dave-data-module/guards/bookmark.resolver';
import { CustomerResolver } from '../dave-data-module/guards/customer.resolver';
import { UserResolver } from '../dave-data-module/guards/user.resolver';
import { CommissionResolver } from '../dave-data-module/guards/commission.resolver';
import { EventTypeResolver } from '../dave-data-module/guards/event-type.resolver';
import {AllEventsMeta, HistoryMeta} from '../helper/page-metadata';
import { DamageFlowResolver } from '../dave-data-module/guards/damageflow.resolver';
import { PersonResolver } from '../dave-data-module/guards/person.resolver';
import { PersonTypeResolver } from '../dave-data-module/guards/personType.resolver';
import { PendingChangesGuard } from '../dave-data-module/guards/pending-changes.guard';
import { StatusFromBackofficeResolver } from '../dave-data-module/guards/statusFromBackoffice.resolver';
import { EventResolver } from '../dave-data-module/guards/event.resolver';
import { UserToEventResolver } from '../dave-data-module/guards/user-to-event.resolver';
import { CommentResolver } from '../dave-data-module/guards/comment.resolver';
import { EmployeeResolver } from '../dave-data-module/guards/employee.resolver';
import {
    CustomerListComponent
} from '../dave-utils-module/dave-shared-components-module/components/customer-list/customer-list.component';
import { PROCESS_FEATURE_KEY } from "../dave-data-module/State/reducers/process.reducer";
import { ProcessResolver } from "../dave-data-module/guards/process.resolver";

const detailEventRoute: Route = {
    path: ':eventId',
    data: {breadcrumb: 'eventId'},
    component: DetailEventComponent,
    canDeactivate: [PendingChangesGuard],
    resolve: {
        events: EventResolver,
        persons: PersonResolver,
        token: TokenResolver,
        permissions: PermissionsResolver,
        bookmarks: BookmarkResolver,
        eventTypes: EventTypeResolver,
        offices: OfficeResolver,
        partner: PartnerResolver,
        users: UserResolver,
        tags: TagResolver,
        commissions: CommissionResolver,
        damageFlows: DamageFlowResolver,
        personTypes: PersonTypeResolver,
        customer: CustomerResolver,
        userToEvent: UserToEventResolver,
        employees: EmployeeResolver,
    }
};
const routes: Routes = [
    {
        path: '',
        data: {breadcrumb: HistoryMeta.Breadcrumb, breadcrumbIcon: HistoryMeta.Icon},
        component: HistoryComponent,
        canActivate: [ValidTokenGuard],
        canActivateChild: [ValidTokenGuard],
        resolve: {
            token: TokenResolver,
            permissions: PermissionsResolver,
            bookmarks: BookmarkResolver,
            eventTypes: EventTypeResolver,
            emailConnections: EmailConnectionResolver,
            partner: PartnerResolver,
            users: UserResolver,
            tags: TagResolver,
            commissions: CommissionResolver,
            damageFlows: DamageFlowResolver,
            persons: PersonResolver,
            personTypes: PersonTypeResolver,
            p2e: Person2EntityResolver,
            ...CustomerListComponent.RequiredResolvers,
        },
        children: [
            {
                path: '',
                component: HistorySideElementsComponent,
                pathMatch: 'full'
            },
            {
                path: AllEventsMeta.Path,
                data: {breadcrumb: AllEventsMeta.Breadcrumb},
                children: [
                    {
                        path: '',
                        component: TimelineComponent,
                        resolve: {
                            events: EventResolver,
                            comments: CommentResolver,
                        }
                    },
                    {
                        path: 'neuesEreignis',
                        data: {breadcrumb: 'Neues Ereignis'},
                        component: NewEventComponent,
                        resolve: {
                            customers: CustomerResolver,
                            statusfromBackOffice: StatusFromBackofficeResolver,
                            [PROCESS_FEATURE_KEY]: ProcessResolver,
                        },
                        canDeactivate: [PendingChangesGuard],
                    },
                    detailEventRoute
                ]
            },
            {
                path: ':customerId',
                data: {breadcrumb: 'customerId'},
                children: [
                    {
                        path: '',
                        component: TimelineComponent,
                        resolve: {
                            events: EventResolver,
                            comments: CommentResolver,
                        }
                    },
                    {
                        path: 'neuesEreignis',
                        data: {breadcrumb: 'Neues Ereignis'},
                        component: NewEventComponent,
                        resolve: {
                            customers: CustomerResolver,
                            statusfromBackOffice: StatusFromBackofficeResolver,
                            [PROCESS_FEATURE_KEY]: ProcessResolver,
                        },
                        canDeactivate: [PendingChangesGuard],
                    },
                    detailEventRoute
                ]
            },
        ]
    },

];
@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class DaveHistoryRoutingModule {}
