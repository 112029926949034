<ng-container *ngFor="let template of Data$ | async">
    <ng-container *ngIf="!template.expandable">
        <h4 *ngIf="template.headline">{{template.headline}}</h4>
        <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{template}"></ng-container>
    </ng-container>
    <mat-expansion-panel *ngIf="template.expandable" [expanded]="template.expanded">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h3>{{template.headline}}</h3>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{template}"></ng-container>
    </mat-expansion-panel>
</ng-container>

<ng-template #content let-template="template">
    <div class="template-section" [class.alignment-column]="template.alignment ? template.alignment === 'column' : DefaultAlignment === 'column'">
        <ng-container *ngFor="let field of template.fields">
            <mat-form-field *ngIf="field.type === CustomFieldTypes.textarea">
                <textarea matInput
                          [formControl]="field.formControl" [placeholder]="field.name"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="6"></textarea>
                <mat-hint *ngIf="field.hint as hint">{{hint}}</mat-hint>
                <mat-error *ngIf="field.formControl.invalid">
                    {{ GetErrorMessage(field.formControl) }}
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="field.type === CustomFieldTypes.text">
                <input matInput [formControl]="field.formControl" type="textarea" [placeholder]="field.name" />
                <mat-hint *ngIf="field.hint as hint">{{hint}}</mat-hint>
                <mat-error *ngIf="field.formControl.invalid">
                    {{ GetErrorMessage(field.formControl) }}
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="field.type === CustomFieldTypes.time">
                <input #input matInput [formControl]="field.formControl" type="time" [placeholder]="field.name" class="hide-icon-from-browser" />
                <mat-hint *ngIf="field.hint as hint">{{hint}}</mat-hint>
                <mat-error *ngIf="field.formControl.invalid">
                    {{ GetErrorMessage(field.formControl) }}
                </mat-error>
                <fa-icon class="picker-icon" icon="clock" (click)="OpenHTMLInputPicker(input)" matSuffix></fa-icon>
            </mat-form-field>
            <mat-form-field *ngIf="field.type === CustomFieldTypes.number">
                <input matInput [formControl]="field.formControl" type="number" [placeholder]="field.name" />
                <mat-hint *ngIf="field.hint as hint">{{hint}}</mat-hint>
            </mat-form-field>
            <mat-checkbox *ngIf="field.type === CustomFieldTypes.checkbox" [formControl]="field.formControl">
                {{field.name}}
                <mat-hint *ngIf="field.hint as hint">{{hint}}</mat-hint>
            </mat-checkbox>
            <mat-form-field  *ngIf="field.type === CustomFieldTypes.date">
                <input matInput [matDatepicker]="picker" (click)="picker.open();" [placeholder]="field.name" [formControl]="field.formControl" [readonly]="bs.TouchQuery | async" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [touchUi]="bs.TouchQuery | async"></mat-datepicker>
                <mat-hint *ngIf="field.hint as hint">{{hint}}</mat-hint>
            </mat-form-field>
            <mat-form-field *ngIf="field.type === CustomFieldTypes.select">
                <mat-label>{{field.name}}</mat-label>
                <mat-select [formControl]="field.formControl">
                    <mat-option *ngFor="let option of field.options" [value]="option.value">{{option.label}}</mat-option>
                </mat-select>
                <mat-hint *ngIf="field.hint as hint">{{hint}}</mat-hint>
            </mat-form-field>
        </ng-container>
    </div>
</ng-template>
