import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DAVE_CC_PARTNERTYPE_ID, OBLIGO_PARTNERTYPE_ID } from '../../../app.component';
import { LicensePackageEntity } from '../../../dave-data-module/entities/license-package.entity';
import { LicenseEntity } from '../../../dave-data-module/entities/license.entity';
import { PartnerEntity } from '../../../dave-data-module/entities/partner.entity';
import { PartnerOfficeEntity } from '../../../dave-data-module/entities/partnerOffice.entity';
import { ProductEntity } from '../../../dave-data-module/entities/product.entity';
import { UserEntity } from '../../../dave-data-module/entities/user.entity';
import { VarianteTypeEntity } from '../../../dave-data-module/entities/varianteType.entity';
import { ComponentCanDeactivate } from '../../../dave-data-module/guards/pending-changes.guard';
import { State } from '../../../dave-data-module/State';
import { LicensesActionTypes } from '../../../dave-data-module/State/actions/licenses.actions';
import {
    PartnerOfficeActionTypes,
    PartnersActionTypes,
} from '../../../dave-data-module/State/actions/partners.actions';
import {
    getPartnerOffices,
    getPartners,
    getPartnerWithMainOfficeByPartnerId,
} from '../../../dave-data-module/State/selectors/partners.selectors';
import { getVarianteTypes } from '../../../dave-data-module/State/selectors/variante-types.selectors';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import {
    CustomPropertyType,
    IProfileTemplateData,
} from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { bICValidator, iBANValidator, phoneNumberValidator } from '../../../helper/validation.helper';
import {
    MandatsreferenzDialogComponent,
    MandatsreferenzDialogData,
} from '../../partner-list/mandatsreferenz-dialog/mandatsreferenz-dialog.component';
import { NewLicenseDialogComponent, NewLicenseDialogData } from './new-license-dialog/new-license-dialog.component';

@Component({
    selector: 'app-detail-partner',
    templateUrl: './detail-partner.component.html',
    styleUrls: ['./detail-partner.component.scss'],
})
export class DetailPartnerComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    // NgRx Observables
    private partners$: Observable<PartnerEntity[]>;
    private users$: Observable<UserEntity[]>;
    private licenses$: Observable<LicenseEntity[]>;
    private licensePackages$: Observable<LicensePackageEntity[]>;
    private varianteTypes$: Observable<VarianteTypeEntity[]>;

    // NgRx Data
    private subscriptions: Subscription[] = [];
    public PartnerList: PartnerEntity[] = null;
    public PartnerOfficeList: PartnerOfficeEntity[] = null;
    public UserList: UserEntity[] = null;
    public LicenseList: LicenseEntity[] = null;
    public LicensePackageList: LicensePackageEntity[] = null;
    public VarianteTypeList: VarianteTypeEntity[] = null;
    public BookableLicensePackages: LicensePackageEntity[] = [];
    public BookableVarianteTypes: VarianteTypeEntity[] = [];
    public PartnerLicenseList$: Observable<LicenseEntity[]>;
    public ManageUserList$: Observable<UserEntity[]>;
    public NameForm = new UntypedFormControl('', [Validators.required]);
    public StreetForm = new UntypedFormControl('');
    public PhoneNumberForm = new UntypedFormControl('', phoneNumberValidator());
    public PostalCodeForm = new UntypedFormControl('');
    public CityForm = new UntypedFormControl('');
    public CountryForm = new UntypedFormControl('');
    public EmailForm = new UntypedFormControl('', Validators.email);
    public IbanForm = new UntypedFormControl('', iBANValidator());
    public BicForm = new UntypedFormControl('', bICValidator());
    public FaxNumberForm = new UntypedFormControl('');
    public BanknameForm = new UntypedFormControl('');
    public PartnerNoForm = new UntypedFormControl('');
    public AutomaticPartnerNoForm = new UntypedFormControl(false);
    public IsObligoPartnerForm = new UntypedFormControl(false);
    public UstIdForm = new UntypedFormControl('');
    public SteuernummerForm = new UntypedFormControl('');

    public ProfileData$ = this.route.paramMap.pipe(
        map(paramMap => +paramMap.get('partnerId')),
        switchMap(partnerId => this.store.select(getPartnerWithMainOfficeByPartnerId({id: partnerId}))),
        map(
            ({ partner, mainOffice }): IProfileTemplateData => ({
                Icon: partner.Deleted ? 'user-slash' : 'user',
                Properties: [
                    {
                        icon: 'trash',
                        options: { type: CustomPropertyType.Title },
                        value: `${mainOffice.Name}${partner.Deleted ? ' (gelöscht)' : ''}`,
                    },
                    {
                        icon: 'trash',
                        formControl: this.NameForm,
                        key: 'Name',
                    },
                    {
                        key: 'Obligo Partner',
                        formControl: this.IsObligoPartnerForm,
                        options: {
                            type: CustomPropertyType.Boolean,
                            specialInput: { boolean: true },
                        },
                    },
                    {
                        icon: 'hashtag',
                        formControl: this.PartnerNoForm,
                        key: 'Mitgliedernummer',
                        value: partner.PartnerNo,
                    },
                    {
                        key: 'Mitgliedernummer automatisch vergeben',
                        formControl: this.AutomaticPartnerNoForm,
                        options: {
                            type: CustomPropertyType.Boolean,
                            specialInput: { boolean: true },
                            showHint:
                                'Erzeugt eine Mitgliedernummer aus Jahr, Monat und einer fortlaufenden Nummer nach dem Muster P202001-1.',
                        },
                    },
                    {
                        icon: 'envelope',
                        formControl: this.EmailForm,
                        key: 'E-Mail',
                        options: { type: CustomPropertyType.Mailto },
                        value: mainOffice.Email,
                    },
                    {
                        icon: 'credit-card',
                        value: partner.MandateReference,
                    },
                    {
                        icon: 'phone',
                        formControl: this.PhoneNumberForm,
                        key: 'Telefonnummer',
                        options: { type: CustomPropertyType.Tel },
                        value: mainOffice.PhoneNumber,
                    },
                    {
                        icon: 'fax',
                        formControl: this.FaxNumberForm,
                        key: 'Faxnummer',
                        value: mainOffice.FaxNumber,
                    },
                    {
                        icon: 'university',
                        formControl: this.BanknameForm,
                        key: 'Bankname',
                        value: mainOffice.BankName,
                    },
                    {
                        icon: 'university',
                        formControl: this.IbanForm,
                        key: 'IBAN',
                        value: mainOffice.IBAN,
                    },
                    {
                        icon: 'university',
                        formControl: this.BicForm,
                        key: 'BIC',
                        value: mainOffice.Bic,
                    },
                    {
                        icon: 'university',
                        formControl: this.UstIdForm,
                        key: 'Umsatzsteueridentifikationsnummer',
                        value: mainOffice.UstID,
                    },
                    {
                        icon: 'university',
                        formControl: this.SteuernummerForm,
                        key: 'Steuernummer',
                        value: mainOffice.Steuernummer,
                    },
                ],
            }),
        ),
    );
    public Partner: PartnerEntity;
    public PartnerMainOffice: PartnerOfficeEntity;
    public Deleted = false;
    public Editing = false;

    constructor(
        private appDialog: AppDialogService,
        private store: Store<State>,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private apiToasterService: ToastrService,
        public PS: PermissionService,
    ) {
        this.users$ = this.store.select(state => state.users.manageUserList);
        this.partners$ = this.store.select(getPartners);
        this.licenses$ = this.store.select(state => state.licenses.licensList);
        this.licensePackages$ = this.store.select(state => state.licenses.licensePackages);
        this.varianteTypes$ = this.store.select(getVarianteTypes);
        this.PartnerLicenseList$ = combineLatest([this.ProfileData$, this.licenses$]).pipe(
            map(([, licenses]) => licenses?.filter(x => x.PartnerId === this.Partner?.Id)),
        );
        this.ManageUserList$ = combineLatest([this.ProfileData$, this.users$]).pipe(
            map(([, users]) =>
                users == null
                    ? null
                    : users
                          .filter(value => value.PartnerId === this.Partner?.Id)
                          .sort((a, b) => {
                              if (a.Deleted && !b.Deleted) {
                                  return 1;
                              }
                              if (!a.Deleted && b.Deleted) {
                                  return -1;
                              }
                              if (a.DisplayName.toLowerCase() < b.DisplayName.toLowerCase()) {
                                  return -1;
                              }
                              if (a.DisplayName.toLowerCase() > b.DisplayName.toLowerCase()) {
                                  return 1;
                              }
                              return 0;
                          }),
            ),
        );
    }

    ngOnInit() {
        this.subscriptions.push(
            combineLatest([
                this.users$,
                this.partners$,
                this.licenses$,
                this.licensePackages$,
                this.varianteTypes$,
                this.store.select(getPartnerOffices),
            ]).subscribe(([users, partners, licenses, licensePackages, varianteTypes, partnerOffices]) => {
                this.UserList = users;
                this.PartnerList = partners;
                this.LicenseList = licenses;
                this.LicensePackageList = licensePackages;
                this.VarianteTypeList = varianteTypes;
                this.PartnerOfficeList = partnerOffices;
                this.selectProfileByUrl();
            }),
            this.AutomaticPartnerNoForm.valueChanges.subscribe(v => {
                if (v && this.PartnerNoForm.enabled) {
                    this.PartnerNoForm.disable();
                    this.PartnerNoForm.setValue(null);
                } else if (!v && this.PartnerNoForm.disabled) {
                    this.PartnerNoForm.enable();
                    this.PartnerNoForm.setValue(this.Partner.PartnerNo);
                }
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public OpenEdit() {
        this.Editing = true;

        this.NameForm.setValue(this.PartnerMainOffice.Name);
        this.EmailForm.setValue(this.PartnerMainOffice.Email);
        this.StreetForm.setValue(this.PartnerMainOffice.Street);
        this.PhoneNumberForm.setValue(this.PartnerMainOffice.PhoneNumber);
        this.PostalCodeForm.setValue(this.PartnerMainOffice.PostalCode);
        this.CityForm.setValue(this.PartnerMainOffice.City);
        this.CountryForm.setValue(this.PartnerMainOffice.Country);
        this.IbanForm.setValue(this.PartnerMainOffice.IBAN);
        this.BicForm.setValue(this.PartnerMainOffice.Bic);
        this.BanknameForm.setValue(this.PartnerMainOffice.BankName);
        this.FaxNumberForm.setValue(this.PartnerMainOffice.FaxNumber);
        this.PartnerNoForm.setValue(this.Partner.PartnerNo);
        this.IsObligoPartnerForm.setValue(this.Partner.PartnerTypeId === OBLIGO_PARTNERTYPE_ID);
        this.UstIdForm.setValue(this.PartnerMainOffice.UstID);
        this.SteuernummerForm.setValue(this.PartnerMainOffice.Steuernummer);
    }

    public CloseEdit() {
        this.Editing = false;
        this.selectProfileByUrl();
    }

    public Delete() {
        this.store.dispatch(PartnersActionTypes.DeletePartner({ Payload: this.Partner.Id }));
    }

    public GenerateMandatsreferenz() {
        this.dialog.open(MandatsreferenzDialogComponent, {
            autoFocus: false,
            width: '90%',
            maxWidth: '400px',
            data: { partner: this.Partner } as MandatsreferenzDialogData,
        });
    }

    public Save() {
        if (this.AreFormsInvalid) {
            return;
        }
        this.NameForm.setValue(this.NameForm.value.trim());
        this.EmailForm.setValue(this.EmailForm.value?.trim());
        this.StreetForm.setValue(this.StreetForm.value?.trim());
        this.PhoneNumberForm.setValue(this.PhoneNumberForm.value?.trim());
        this.PostalCodeForm.setValue(this.PostalCodeForm.value?.trim());
        this.CityForm.setValue(this.CityForm.value?.trim());
        this.CountryForm.setValue(this.CountryForm.value?.trim());
        this.IbanForm.setValue(this.IbanForm.value?.trim());
        this.BicForm.setValue(this.BicForm.value?.trim());
        this.FaxNumberForm.setValue(this.FaxNumberForm.value?.trim());
        this.BanknameForm.setValue(this.BanknameForm.value?.trim());
        this.PartnerNoForm.setValue(this.PartnerNoForm.value?.trim());
        this.store.dispatch(
            PartnersActionTypes.ModifyPartner({
                Payload: {
                    id: this.Partner.Id,
                    partnerTypeId: this.IsObligoPartnerForm.value ? OBLIGO_PARTNERTYPE_ID : DAVE_CC_PARTNERTYPE_ID,
                    partnerNo: this.PartnerNoForm.value,
                    automaticPartnerNo: this.AutomaticPartnerNoForm.value,
                },
                createNewSepa: false,
            }),
        );
        this.store.dispatch(
            PartnerOfficeActionTypes.ModifyPartnerOffice({
                Payload: {
                    id: this.PartnerMainOffice.Id,
                    name: this.NameForm.value,
                    email: this.EmailForm.value,
                    street: this.StreetForm.value,
                    postalCode: this.PostalCodeForm.value,
                    faxNumber: this.FaxNumberForm.value,
                    bankname: this.BanknameForm.value,
                    city: this.CityForm.value,
                    phonenumber: this.PhoneNumberForm.value,
                    iban: this.IbanForm.value,
                    bic: this.BicForm.value,
                    country: this.CountryForm.value,
                    ustId: this.UstIdForm.value,
                    steuernummer: this.SteuernummerForm.value,
                },
            }),
        );
        this.CloseEdit();
    }

    DeletePartner(): void {
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: `Partner ${this.Partner.PartnerNo} ${this.PartnerMainOffice.Name} wirklich löschen?`,
                styleDelete: true,
            })
            .subscribe(([result,]) => result && this.Delete());
    }

    DeleteLicense(license: LicenseEntity): void {
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: `Lizenz ${license.MaxUserTypeName} wirklich löschen?`,
                styleDelete: true,
            })
            .subscribe(
                ([result,]) =>
                    result &&
                    this.store.dispatch(
                        LicensesActionTypes.CancelLicense({
                            Payload: license.Id,
                        }),
                    ),
            );
    }

    AddLicense(): void {
        const distBookableLicensePackages = this.BookableLicensePackages.filter(
            (thing, i, arr) => arr.findIndex(t => t.Id === thing.Id) === i,
        );
        const bookableProductList = distBookableLicensePackages.map(l => new ProductEntity(l.Id, l.ProductName));
        const dialogRef = this.dialog.open(NewLicenseDialogComponent, {
            autoFocus: false,
            width: '90%',
            maxWidth: '500px',
            data: {
                productList: bookableProductList,
                licenseList: this.BookableLicensePackages,
                varianteTypeList: this.BookableVarianteTypes,
            } as NewLicenseDialogData,
        });

        const subscription = dialogRef.afterClosed().subscribe(result => {
            if (result !== null && result.selectedLicense != null && result.selectedVarianteType != null) {
                this.store.dispatch(
                    LicensesActionTypes.AddLicenseOrder({
                        Payload: {
                            maxUserTypeId: result.selectedLicense.MaxUserTypeId,
                            productId: result.selectedLicense.Id,
                            partnerId: this.Partner.Id,
                            varianteTypeId: result.selectedVarianteType.Id,
                        },
                    }),
                );
            }

            subscription.unsubscribe();
        });
    }
    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): Observable<boolean> | boolean {
        return (
            !this.Editing ||
            (!this.NameForm.dirty &&
                !this.StreetForm.dirty &&
                !this.PhoneNumberForm.dirty &&
                !this.PostalCodeForm.dirty &&
                !this.CityForm.dirty &&
                !this.CountryForm.dirty &&
                !this.EmailForm.dirty &&
                !this.IbanForm.dirty &&
                !this.BicForm.dirty &&
                !this.FaxNumberForm.dirty &&
                !this.BanknameForm.dirty &&
                !this.PartnerNoForm.dirty &&
                !this.AutomaticPartnerNoForm.dirty &&
                !this.IsObligoPartnerForm.dirty &&
                !this.UstIdForm.dirty &&
                !this.SteuernummerForm.dirty)
        );
    }
    private selectProfileByUrl() {
        const partnerId: number = +this.route.snapshot.paramMap.get('partnerId');
        this.Partner = this.PartnerList == null ? null : this.PartnerList.find(value => value.Id === partnerId);
        if (!this.Partner) {
            return this.apiToasterService.error('Es konnte kein Partner gefunden werden.');
        } else {
            this.PartnerMainOffice = this.PartnerOfficeList.find(
                value => value.PartnerId === partnerId && value.MainOffice,
            );
        }
        this.BookableLicensePackages = [];
        /**
         * BuchbarePakete:
         *  - wenn kein Product mit Id 1 dann nur Product mit Id 1 buchbar
         *  - wenn Product mit Id 1 gebucht werden soll, dann nur mit größerer Lizenz
         *  - wenn ein anderes Produkt gebucht werden soll, dann nur mit dem selben Werten, wie das Produkt mit der Id 1 (laufzeit, Anzahl Benutzer) oder mehr Benutzern
         */
        // const daveLicense = this.LicenseList;
        const daveLicense = this.LicenseList.filter(x => x.PartnerId === this.Partner.Id && x.ProductId === 1);
        for (const licensePackage of this.LicensePackageList) {
            if (daveLicense.length <= 0 && licensePackage.Id === 1) {
                this.BookableLicensePackages.push(licensePackage);
                this.BookableVarianteTypes = this.BookableVarianteTypes.concat(
                    this.VarianteTypeList.filter(vt => vt.ProductId === licensePackage.Id),
                );
                continue;
            }

            if (
                daveLicense.length > 0 &&
                licensePackage.Id === 1 &&
                (daveLicense[0].MaxUser <= licensePackage.MaxUser || daveLicense[0].MinUser <= licensePackage.MinUser)
            ) {
                this.BookableLicensePackages.push(licensePackage);
                this.BookableVarianteTypes = this.BookableVarianteTypes.concat(
                    this.VarianteTypeList.filter(
                        vt =>
                            vt.ProductId === licensePackage.Id &&
                            vt.TimePeriodInMonth >= daveLicense[0].TimePeriodInMonth,
                    ),
                );
                continue;
            }
            if (
                daveLicense.length > 0 &&
                licensePackage.Id !== 1 &&
                daveLicense[0].MaxUser >= licensePackage.MaxUser &&
                daveLicense[0].MinUser >= licensePackage.MinUser
            ) {
                this.BookableLicensePackages.push(licensePackage);
                this.BookableVarianteTypes = this.BookableVarianteTypes.concat(
                    this.VarianteTypeList.filter(
                        vt =>
                            vt.ProductId === licensePackage.Id &&
                            vt.TimePeriodInMonth === daveLicense[0].TimePeriodInMonth,
                    ),
                );
                continue;
            }
        }
        this.BookableVarianteTypes = this.BookableVarianteTypes.filter(
            (thing, i, arr) => arr.findIndex(t => t.Id === thing.Id) === i,
        );
    }

    get AreFormsInvalid() {
        const isInvalid =
            this.NameForm.invalid ||
            this.EmailForm.invalid ||
            this.StreetForm.invalid ||
            this.PhoneNumberForm.invalid ||
            this.PostalCodeForm.invalid ||
            this.CityForm.invalid ||
            this.CountryForm.invalid ||
            this.IbanForm.invalid ||
            this.BicForm.invalid;
        return isInvalid;
    }
}
