    <h2 mat-dialog-title><fa-icon style="margin-right: .5rem" [icon]="ReportsMeta.Icon"></fa-icon>Anhänge aus den Berichten hinzufügen</h2>
    <mat-dialog-content>
            <table class="full-width" mat-table [dataSource]="ShowGenFilesList$ | async">
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef>
                        Name
                    </th>
                    <td mat-cell *matCellDef="let rowData">
                        {{ rowData.Name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Type">
                    <th mat-header-cell *matHeaderCellDef>
                    Typ
                    </th>
                    <td mat-cell *matCellDef="let rowData">
                        {{ rowData.GenFilesType?.Name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Date">
                    <th mat-header-cell *matHeaderCellDef>
                    Bearbeitet
                    </th>
                    <td mat-cell *matCellDef="let rowData" style="min-width: 8rem">
                        <label>
                            {{ rowData.UpdatedAt | date: 'dd.MM.yy' }}{{', '}}{{ rowData.UpdatedAt | date: 'mediumTime' }}
                        </label>
                        <fa-icon
                            matSuffix
                            icon="check"
                            style="float: right"
                            *ngIf="SelectedGeneratedFiles.includes(rowData.Id)"
                        ></fa-icon>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['Type', 'Name', 'Date']"></tr>
                <tr
                    mat-row
                    *matRowDef="let rowData; columns: ['Type', 'Name', 'Date']"
                    (click)="SelectUnselectGeneratedFile(rowData.Id)"
                ></tr>
            </table>

            <table *ngIf="!((ShowGenFilesList$ | async)?.length > 0)" style="width: 100%">
                <tr>
                    <app-loading *ngIf="!(ShowGenFilesList$ | async)"></app-loading>
                    <td class="empty-table-text" *ngIf="(ShowGenFilesList$ | async)?.length === 0">
                        Keine generierten Dokumente vorhanden
                    </td>
                </tr>
            </table>
    </mat-dialog-content>
    <mat-dialog-actions>
            <button Color="cancel" app-button matDialogClose>Abbrechen</button>
        <button app-button (click)="CloseAndSave()">Speichern</button>
    </mat-dialog-actions>
