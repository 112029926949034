import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ClockInTypeEntityFromBackend, ClockInTypeEntityGqlFields } from '../../entities/clock-in-type.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { ClockInTypeActionTypes } from '../actions/clock-in-type.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';

enum ErrorCodes {
    Load = 'Stempelart Abrufen fehlgeschlagen',
    Remove = 'Stempelart Löschen fehlgeschlagen',
    Modify = 'Stempelart ändern fehlgeschlagen',
    Add = 'Stempelart hinzufügen fehlgeschlagen',
    Delete = 'Stempelart löschen fehlgeschlagen',
}

@Injectable()
export class ClockInTypeEffects {
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}

    GetAllClockInTypesQuery$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ClockInTypeActionTypes.Load),
                withLatestFrom(this.store$.select(getToken)),
                switchMap(([action, token]) => {
                    const queryString = `query {
          clockintype {
            ${ClockInTypeEntityGqlFields.join(',')}
          }
        }
        `;
                    return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                        tap((res) => {
                            this.store$.dispatch(
                                res && res.clockintype
                                    ? ClockInTypeActionTypes.UpdateAll({
                                          Payload: res.clockintype.map((val) => ClockInTypeEntityFromBackend(val)),
                                          updateLatestUpdatedAt: true,
                                      })
                                    : BaseActionTypes.ErrorAction({
                                          Payload: { ToasterMessage: ErrorCodes.Load },
                                      }),
                            );
                        }),
                        catchError((err, caught) => {
                            this.store$.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Load,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            );
                            return EMPTY;
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );
}
