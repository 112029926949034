import { JobSpecificationType } from '../graphql-types';
import { DashboardItemsDataType } from '../../mocks/dashboard-items-data';
import { Color } from '@dave/types';
export interface JobSpecificationEntityAdditionalData {
    excludeServiceApps?: DashboardItemsDataType[];
    color?: Color;
}

export class JobSpecificationEntity {
    constructor(
        public Id: number = null,
        public Name: string = null,
        public UsedInCapacityCalculation: boolean = null,
        public AdditionalData: JobSpecificationEntityAdditionalData = null,
        public Amount: number = null, //Kosten (EK)
        public QuantityTypeId: number = null,
    ) {}
    public static readonly GqlFields: Array<keyof JobSpecificationType> = [
        'id',
        'name',
        'usedInCapacityCalculation',
        'additionalData',
        'amount',
        'quantityTypeId',
    ]
    public Clone(override: Partial<JobSpecificationEntity> = {}): JobSpecificationEntity {
        const attr = { ...this, ...override };
        return Object.assign(new JobSpecificationEntity(), attr);
    }
}

export function JobSpecificationEntityFromBackend(res: JobSpecificationType): JobSpecificationEntity {
    return new JobSpecificationEntity(
        res.id,
        res.name,
        res.usedInCapacityCalculation,
        res.additionalData && JSON.parse(res.additionalData) as JobSpecificationEntityAdditionalData,
        res.amount,
        res.quantityTypeId,
    );
}
