<h1 mat-dialog-title>
    <fa-icon mat-card-avatar [icon]="FileLinkIcon"></fa-icon>
    Neue Verknüpfung
</h1>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>Name</mat-label>
        <app-filename-input [formControl]="form.controls.name"></app-filename-input>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button app-button Inverted Color="cancel" [IsLoading]="ls.IsLoading$ | async" mat-dialog-close="">Abbrechen</button>
    <button app-button [Disabled]="form.invalid" [IsLoading]="ls.IsLoading$ | async" Color="green" (click)="submit()">Speichern</button>
</mat-dialog-actions>
