import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store";
import { State } from '../index';
import { licensesFeatureKey, LicensesState } from '../reducers/licenses.reducer';
import { productsFeatureKey, ProductsState } from '../reducers/products.reducer';
import { getUser } from './users.selectors';
import { ProductEntity } from "../../entities/product.entity";

export const selectProducts = createFeatureSelector< ProductsState>(productsFeatureKey);
export const selectLicenses = createFeatureSelector< LicensesState>(licensesFeatureKey);

export const getProducts = createSelector(selectProducts, data => data.products);
export const getProductsFetched = createSelector(selectProducts, data => data.fetched);
export const getLicenses = createSelector(selectLicenses, data => data.licenses);

export const getProductsWithMaxUser: MemoizedSelector<object, Array<Partial<ProductEntity>&{
    Available: 'Unbegrenzt' | number | null;
}>> = createSelector(
    getProducts,
    getLicenses,
    (products, licenses) =>
        products &&
        products.map(p => {
            return {
                ...p,
                Available:
                    licenses.find(l => l.ProductId === p.Id) && licenses.find(l => l.ProductId === p.Id).MaxUser === 0
                        ? 'Unbegrenzt'
                        : licenses.find(l => l.ProductId === p.Id)
                        ? licenses.find(l => l.ProductId === p.Id).MaxUser -
                          licenses.find(l => l.ProductId === p.Id).ActiveUser
                        : null,
                //   ? licenses.find(l => l.ProductId === p.Id).MaxUser -
                //   licenses.find(l => l.ProductId === p.Id).ActiveUser
                //   : null
            };
        }),
);

export const getUserProducts = createSelector(
    getProducts,
    getUser,
    (products: ReturnType<typeof getProducts>, user: ReturnType<typeof getUser>) => {
        if (products && user) {
            let userProductsId: number[] = [];
            userProductsId = user.ProductIds;
            return products.filter(x => userProductsId.includes(x.Id));
        } else {
            return null;
        }
    },
);

export const getAllLicenses = createSelector(selectLicenses, data => data.licensList);

export const getLicensePackages = createSelector(selectLicenses, data => data.licensePackages);

export const getBookableByUsersLicensePackages = createSelector(
    getLicensePackages,
    licensePackages => licensePackages && licensePackages.filter(x => x.CanBeBookedByUser),
);

export const getBookableByUsersProducts = createSelector(
    getProducts,
    getBookableByUsersLicensePackages,
    (products, licensePackages) =>
        products && licensePackages && products.filter(p => licensePackages.find(lp => lp.Id === p.Id) !== undefined),
);

// Get by PartnerId

export const getProductsWithMaxUserByPartnerId: MemoizedSelector<object, Array<Partial<ProductEntity>&{
    Available: 'Unbegrenzt' | number | null;
}>> = createSelector(
    getProducts,
    getAllLicenses,
    getUser,
    (
        products: ReturnType<typeof getProducts>,
        licenses: ReturnType<typeof getAllLicenses>,
        user: ReturnType<typeof getUser>,
        partnerId?: number,
    ) =>
        products &&
        products.map(p => {
            const partnerIdSearch = partnerId ? partnerId : user.PartnerId;
            const filteredLicenses = licenses.filter(l => l.PartnerId === partnerIdSearch);
            return {
                ...p,
                Available:
                    // ToDo: Sonderfall maxuser === 0 entfernen sobald es keine unendlichlizenzen mehr gint
                    filteredLicenses.find(l => l.ProductId === p.Id) &&
                    filteredLicenses.find(l => l.ProductId === p.Id).MaxUser === 0
                        ? 'Unbegrenzt'
                        : filteredLicenses.find(l => l.ProductId === p.Id)
                        ? filteredLicenses.find(l => l.ProductId === p.Id).MaxUser -
                          filteredLicenses.find(l => l.ProductId === p.Id).ActiveUser
                        : null,
            };
        }),
);
