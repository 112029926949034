import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SimplebarAngularModule } from 'simplebar-angular';
import { BurgerButtonWrapperComponent } from '../../../burger-button-wrapper/burger-button-wrapper.component';
import { TagChipAutocompleteModule } from '../../../components/templates/tag-chip-autocomplete/tag-chip-autocomplete.module';
import { DaveDoubleIconModule } from "../../../dave-double-icon/dave-double-icon.module";
import { DaveEventListEntryModule } from '../../../dave-event-list-entry/dave-event-list-entry.module';
import { DaveFullscreenDialogModule } from "../../../dave-fullscreen-dialog/dave-fullscreen-dialog.module";
import { DaveLoadingModule } from '../../../dave-loading/dave-loading.module';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { DaveSharedComponentsModule } from '../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { PipesModule } from '../../../dave-utils-module/dave-shared-components-module/pipes/pipes.module';
import { IconPipeModule } from '../../../dave-utils-module/icon-pipe/icon-pipe.module';
import { SelectSearchModule } from '../../../dave-utils-module/select-search/select-search.module';
import {
DocumentDetectionResultDataModalComponent
} from "../../../document-detection-result-data-modal/document-detection-result-data-modal.component";
import { EditDocumentUserComponent } from '../edit-document-user/edit-document-user.component';
import { PdfEditorModule } from '../pdf-editor/pdf-editor.module';
import { PreviewComponent } from './preview.component';
import { DaveEventCardModule } from '../../../dave-event-card/dave-event-card.module';

@NgModule({
    declarations: [PreviewComponent, EditDocumentUserComponent],
    imports: [
        CommonModule,
        TagChipAutocompleteModule,
        RouterModule,
        PipesModule,
        DaveSharedComponentsModule,
        FontAwesomeModule,
        PdfViewerModule,
        AppButtonModule,
        MatTooltipModule,
        SimplebarAngularModule,
        MatChipsModule,
        MatButtonModule,
        IconPipeModule,
        PdfEditorModule,
        MatTabsModule,
        MatTableModule,
        DaveLoadingModule,
        DaveFullscreenDialogModule,
        MatDialogModule,
        SelectSearchModule,
        MatCheckboxModule,
        BurgerButtonWrapperComponent,
        DaveEventListEntryModule,
        MatMenuModule,
        DocumentDetectionResultDataModalComponent,
        DaveDoubleIconModule,
        DaveEventCardModule,
    ],
    exports: [PreviewComponent],
})
export class PreviewModule {}
