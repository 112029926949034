import { ChangeDetectionStrategy,Component,Inject,OnDestroy } from '@angular/core';
import { FormControl,FormGroup } from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';

export type AppConfirmationDialogReturnData = [boolean, string]
export interface AppConfirmationDialogData {
    /** Standardmäßig "Sind Sie sich sicher?" */
    heading?: string;
    paragraph?: string;
    warning?: string;
    styleDelete?: boolean; // toggle delete markup
    inputText?: string;
    placeholder?: string;
    yesButton?: string;
    noButton?: string;
    hideButtons?: boolean;
    onlyOkButton?: boolean;
}
@Component({
    selector: 'app-confirmation-dialog',
    template: `
        <h1 mat-dialog-title class="headline">{{ Data.heading || 'Sind Sie sich sicher?' }}</h1>
        <mat-dialog-content class="content">
            <!-- Headline & Warning -->
            <p *ngIf="Data.paragraph">{{ Data.paragraph }}</p>
            <p *ngIf="Data.warning">
                <strong>{{ Data.warning }}</strong>
            </p>

            <form *ngIf="Data.placeholder">
                <app-textfield [Key]="Data.inputText" [Value]="DialogFormGroup.controls.ResponseText"></app-textfield>
            </form>
        </mat-dialog-content>

        <mat-dialog-actions class="buttons" *ngIf="!Data.hideButtons">
            <ng-container *ngIf="Data.onlyOkButton">
                <span></span>
                <button
                    app-button
                    class="button"
                    [Color]="Data.styleDelete ? 'red' : null"
                    [IsLoading]="IsLoading$ | async"
                    (click)="SaveClick$.next(); onSaveClick()"
                >
                    ok
                </button>
                <span></span>
            </ng-container>
            <ng-container *ngIf="!Data.onlyOkButton">
                <div class="cancel-button-wrapper">
                    <button app-button class="button" Inverted [mat-dialog-close]="[false]" [IsLoading]="IsLoading$ | async">
                        {{Data.noButton || 'Abbrechen'}}
                    </button>
                </div>
                <div class="confirm-button-wrapper">
                    <button
                        app-button
                        class="button"
                        [Color]="Data.styleDelete ? 'red' : null"
                        [IsLoading]="IsLoading$ | async"
                        (click)="SaveClick$.next(); onSaveClick()"
                    >
                        {{Data.yesButton || 'Ja'}}
                    </button>
                </div>
            </ng-container>
        </mat-dialog-actions>
    `,
    styles: [
        `
            :host {
                display: flex;
                flex-direction: column;
                height: 100%;
            }
            .content p {
                white-space: pre-wrap;
            }
            .buttons {
                justify-content: space-between;
            }

            .button + .button {
                margin-left: 0.5rem;
            }

            .content h3.headline {
                margin-bottom: 1rem;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppConfirmationDialogComponent implements OnDestroy {
    public SaveClick$: Subject<void> = new Subject<void>();
    public IsLoading$ = new BehaviorSubject(false);
    constructor(@Inject(MAT_DIALOG_DATA) public Data: AppConfirmationDialogData, private dialogRef: MatDialogRef<AppConfirmationDialogComponent>) {}

    ngOnDestroy(): void {
        this.SaveClick$.complete();
    }
    public DialogFormGroup = new FormGroup({
        ResponseText: new FormControl<string | null>(this.Data.placeholder),
    });
    onSaveClick() {
        if (!this.dialogRef?.disableClose) {
            this.dialogRef.close([true, this.DialogFormGroup.controls.ResponseText.value]);
        }
    }
}
