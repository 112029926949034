import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {map, switchMap} from 'rxjs/operators';
import {StatisticsApiService} from 'src/app/services/statistics-api.service';


// auskommentiert, da nicht mehr verwendet, verwendet GoogleCharts, wir sind aber auf chart.Js umgestiegen
/* ################################################### */
/* ## BEVOR JEMAND AN DIESER KOMPONENTE HERUMSPIELT ## */
/* ##   BITTE ../google-charts.helper.ts BEACHTEN   ## */

/* ################################################### */

@Component({
    selector: 'app-customer-statistics',
    templateUrl: './customer-statistics.component.html',
    styleUrls: ['./customer-statistics.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerStatisticsComponent {
  /*  /!* Konfigurationseinstellungen für Google Charts *!/
    EventCountWithTypesOptions: google.visualization.PieChartOptions;
    ContactPersonsToEventsOptions: google.visualization.PieChartOptions;
    EventCountWithCreationDates: {
        ColumnNames: string[];
        Options: google.visualization.LineChartOptions;
        Roles: any[];
    };
    private customerId$ = this.route.paramMap.pipe(
        map(paramMap => +paramMap.get(StatisticsMeta.Breadcrumb)),
    );
    Customer$ = this.customerId$.pipe(
        switchMap(customerId => this.store.select(getCustomerById, customerId)),
    );

    /!* Diese Daten benötigt die Komponente *!/

    Data$: Observable<{
        /!** Anzahl Ereignisse *!/
        eventCount: number;
        /!** Gesamtlänge aller Ereignisse *!/
        totalEventDurations: string;
        /!** [Name der Ereignisart, Anzahl Ereignisse dieser Art] *!/
        eventCountWithTypes: [string, number][];
        /!** [Name des Ansprechpartners, Anzahl Ereignisse mit diesem AP] *!/
        personsToEvents: [string, number][];
        /!** [Monat, Anzahl neuer Ereignisse pro Monat, Tooltip] *!/
        eventCountWithCreationDates: [Date, number, string][];
    }> = this.customerId$.pipe(switchMap(customerId => this.statApi.GetCustomerStats(customerId)));
    private darkMode$ = this.store.select(getSetting).pipe(map(s => s.DarkMode));
    private currentDate = new Date();
    private dateOneYearAgo = new Date(
        this.currentDate.getFullYear() - 1,
        this.currentDate.getMonth(),
        this.currentDate.getDate(),
    );

    constructor(
        private route: ActivatedRoute,
        private statApi: StatisticsApiService,
        private store: Store<State>,
        public CS: CustomerNameService
    ) {
        resizeGoogleChartsAfterRouterAnimation();

        /!* Konfigurationseinstellungen für Google Charts *!/
        this.darkMode$.subscribe(darkmode => {
            this.EventCountWithTypesOptions = {
                ...GetGooglePieChartOptions(darkmode),
                title: 'Anzahl Ereignisse pro Ereignisart',
            };
            this.ContactPersonsToEventsOptions = {
                ...GetGooglePieChartOptions(darkmode),
                title: 'Korrespondenz mit Ansprechpartnern / Sachbearbeiter',
            };

            this.EventCountWithCreationDates = {
                ColumnNames: ['Monat', 'Anzahl neuer Ereignisse'], // & Tooltip
                Options: {
                    ...GetGoogleLineChartOptions(darkmode),
                    title: 'Entwicklung der Korrespondenz im letzten Jahr',
                    hAxis: {
                        ...GetGoogleLineChartOptions(darkmode).hAxis,
                        format: 'MM.yyyy',
                        viewWindow: {
                            min: this.dateOneYearAgo,
                            max: this.currentDate,
                        },
                    },
                    vAxis: {
                        ...GetGoogleLineChartOptions(darkmode).vAxis,
                        format: '#',
                    },
                    legend: {position: 'none'},
                    pointSize: 5,
                    chartArea: {width: '80%', height: '80%'},
                },
                Roles: [{type: 'string', role: 'tooltip', index: 2}],
            };
        });
    }*/
}
