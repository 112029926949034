import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentEditorComponent } from './document-editor.component';
import { DocumentEditorContainerModule } from '@syncfusion/ej2-angular-documenteditor';


@NgModule({
  declarations: [DocumentEditorComponent],
    imports: [
        CommonModule,
        DocumentEditorContainerModule,
    ],
    exports: [DocumentEditorComponent]
})
export class DocumentEditorModule { }
