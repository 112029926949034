import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'app-progress-card-progress-bar',
    templateUrl: './progress-card-progress-bar.component.html',
    styleUrls: ['./progress-card-progress-bar.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class ProgressCardProgressBarComponent {
@Input() progress: number;
@Input() progressTarget = 100;

    constructor() {}


}
