import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';
import {
    selectAllViewStyleSetting,
    selectviewStyleSettingEntities,
    selectViewStyleSettingFetched,
    ViewStyleSettingState,
    VIEW_STYLE_SETTING_KEY,
} from '../reducers/viewStyleSetting.reducer';

const selectViewStyleSetting = createFeatureSelector< ViewStyleSettingState>(VIEW_STYLE_SETTING_KEY);
const getViewStyleSettingEntitys = createSelector(selectViewStyleSetting, selectviewStyleSettingEntities);

export const getViewStyleSetting = createSelector(selectViewStyleSetting, selectAllViewStyleSetting);
export const getViewStyleSettingFetched = createSelector(selectViewStyleSetting, selectViewStyleSettingFetched);
export const getViewStyleSettingById = createSelector(
    getViewStyleSettingEntitys,
    (viewStyleSetting: ReturnType<typeof getViewStyleSettingEntitys>, Id: number) => Id && viewStyleSetting[Id],
);
