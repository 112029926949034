// tslint:disable: naming-convention
import { Action } from '@ngrx/store';

export enum ResolverActionTypes {
    LoadBookmarks = '[Resolver] Load Bookmarks',
    ReLoadBookmarks = '[Resolver] ReLoad Bookmarks',
    LoadEmailConnections = '[Resolver] Load EmailConnections',
    LoadEventTypes = '[Resolver] Load EventTypes',
    LoadUser = '[Resolver] Load User',
    LoadManageUserList = '[Resolver] Load ManageUserList',
    LoadCustomerTypes = '[Resolver] Load CustomerTypes',
    LoadCustomerLocations = '[Resolver] Load CustomerLocations',
    LoadLicenses = '[Resolver] Load Licenses',
    LoadAllLicenses = '[Resolver] Load All Licenses',
    LoadLicensPackage = '[Resolver] Load LicensePackage',
    ResolverLoadProducts = '[Resolver] Load LoadProducts',
    ResolverLoadSepa = '[Resolver] Load Sepa',
    LoadVarianteTypes = '[Resolver] Load VarianteTypes',
    LoadRoles = '[Resolver] Load Roles',
    LoadPermissions = '[Resolver] Load Permissions',
    LoadTags = '[Resolver] Load Tags',
    LoadScan2DaveEmails = '[Resolver] Load Scan2goeEmails',
    LoadDetections = '[Resolver] Load Detections',
}

export class ResolverLoadBookmarks implements Action {
    readonly type = ResolverActionTypes.LoadBookmarks;
}
export class ResolverReLoadBookmarks implements Action {
    readonly type = ResolverActionTypes.ReLoadBookmarks;
}
export class ResolverLoadEmailConnections implements Action {
    readonly type = ResolverActionTypes.LoadEmailConnections;
}
export class ResolverLoadEventTypes implements Action {
    readonly type = ResolverActionTypes.LoadEventTypes;
}
export class ResolverLoadManageUserList implements Action {
    readonly type = ResolverActionTypes.LoadManageUserList;
}
export class ResolverLoadUser implements Action {
    constructor(public Payload: { id: number }) {}
    readonly type = ResolverActionTypes.LoadUser;
}
export class ResolverLoadCustomerTypes implements Action {
    readonly type = ResolverActionTypes.LoadCustomerTypes;
}
export class ResolverLoadCustomerLocations implements Action {
    readonly type = ResolverActionTypes.LoadCustomerLocations;
}

export class ResolverLoadLicenses implements Action {
    readonly type = ResolverActionTypes.LoadLicenses;
}

export class ResolverLoadAllLicenses implements Action {
    readonly type = ResolverActionTypes.LoadAllLicenses;
}

export class ResolverLoadLicensePackage implements Action {
    readonly type = ResolverActionTypes.LoadLicensPackage;
}
export class ResolverLoadProducts implements Action {
    readonly type = ResolverActionTypes.ResolverLoadProducts;
}
export class ResolverLoadSepa implements Action {
    readonly type = ResolverActionTypes.ResolverLoadSepa;
}
export class ResolverLoadVarianteTypes implements Action {
    readonly type = ResolverActionTypes.LoadVarianteTypes;
}
export class ResolverLoadRoles implements Action {
    readonly type = ResolverActionTypes.LoadRoles;
}
export class ResolverLoadPermissions implements Action {
    readonly type = ResolverActionTypes.LoadPermissions;
}
export class ResolverLoadTags implements Action {
    readonly type = ResolverActionTypes.LoadTags;
}
export class ResolverLoadScan2DaveEmails implements Action {
    readonly type = ResolverActionTypes.LoadScan2DaveEmails;
}

export class ResolverLoadDetections implements Action {
    readonly type = ResolverActionTypes.LoadDetections;
}
export type ResolverActions =
    | ResolverReLoadBookmarks
    | ResolverLoadBookmarks
    | ResolverLoadEmailConnections
    | ResolverLoadEventTypes
    | ResolverLoadUser
    | ResolverLoadManageUserList
    | ResolverLoadCustomerTypes
    | ResolverLoadCustomerLocations
    | ResolverLoadLicenses
    | ResolverLoadAllLicenses
    | ResolverLoadLicensePackage
    | ResolverLoadProducts
    | ResolverLoadSepa
    | ResolverLoadVarianteTypes
    | ResolverLoadRoles
    | ResolverLoadPermissions
    | ResolverLoadTags
    | ResolverLoadScan2DaveEmails
    | ResolverLoadDetections;
