import { BooleanInput,coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy,ChangeDetectorRef,Component,EventEmitter,Input,Output } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule,MatMenuPanel } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { combineLatest, firstValueFrom, of } from 'rxjs';
import { FileEntity } from '../../../dave-data-module/entities/file.entity';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { EmailEditorComponent, EmailEditorComponentDialogData } from '../../../dave-email-module/components/email-editor/email-editor.component';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { ExpansionPanelComponent } from '../../../expansion-panel/expansion-panel.component';
import { SmallFileCardModule } from '../../../small-file-card/small-file-card.module';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import {
    SortFilesDialogComponent,
    SortFilesDialogComponentDialogData,
} from '../../../sort-files-dialog/sort-files-dialog.component';
import { Actions, ofType } from '@ngrx/effects';
import { FilesActionTypes } from '../../../dave-data-module/State/actions/files.actions';
import { LoadingService } from '../../../services/loading.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DownloadVersions, DownloadZip } from '../../../dave-data-module/services/http.service';
import { getFileById } from '../../../dave-data-module/State/selectors/files.selectors';
import { Store } from '@ngrx/store';
import { State } from '../../../dave-data-module/State';
import { ReportsPageMeta } from '../../../helper/page-metadata';
import { MoveFileFolderService } from '../../../services/move-file-folder.service';
import { DaveEmailModule } from '../../../dave-email-module/dave-email.module';

@Component({
    selector: 'app-document-list-card[FileIds]',
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        MatExpansionModule,
        SmallFileCardModule,
        ExpansionPanelComponent,
        MatBadgeModule,
        FontAwesomeModule,
        AppButtonModule,
        //DaveEmailModule,  // verursacht ReferenceError zwischen Modulen (Circular dependency)
        EmailEditorComponent,   /// DaveEmailModule  durch EmailEditorComponent(standalone) ersetzt
        MatMenuModule,
        MatTooltipModule,
        //für dialog
    ],
    templateUrl: './document-list-card.component.html',
    styleUrls: ['./document-list-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentListCardComponent {
    protected multiselect = false;
    protected valid_fileIds: number[];
    @Input() set RemoveButton(value) {
        this.removeButton = coerceBooleanProperty(value);
    }
    protected removeButton = false;
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_RemoveButton: BooleanInput;

    @Input() public set FileIds(ids: number[]) {
        if (ids == null) {
            this.valid_fileIds = null;
            this.changeDetectionRef.detectChanges();
            return;
        }
        firstValueFrom(this.fileDataService.GetFilesById(ids)).then((files) => {
            this.valid_fileIds = files.map((file) => file.Id);
            this.changeDetectionRef.detectChanges();
        });
    }
    @Input() Expanded = true;
    @Input() Headline = 'Dateien';
    @Input() EmptyText = 'Keine Dateien vorhanden';
    @Input() excludeRemoveButtonFromFiles: number[] = [];

    protected ledgerPath : string ;
    @Input() public set ledgerImportPath (path: string){
        if (path){
            this.ledgerPath = path;
        }
    } ;
    @Input() UploadMenu: MatMenuPanel;

    @Input() SelectedFiles: number[] = [];
    @Output() SelectedFilesChange = new EventEmitter<number[]>();

    @Output() FileClick = new EventEmitter<{ file: FileEntity; multiselect: boolean }>();
    @Output() RemoveClick = new EventEmitter<FileEntity>();

    @Output() UploadClick = new EventEmitter<void>();
    @Output() MergePdfClick = new EventEmitter<number[]>();


    constructor(protected fileDataService: FileDataService,
                protected changeDetectionRef: ChangeDetectorRef,
                private dialog: MatDialog ,
                private store: Store<State>,
                private moveFileFolderService: MoveFileFolderService) {}

    getMergePdfDisabled$ = () => {
        const fileIds = this.SelectedFiles;
        if (!fileIds || fileIds.length < 2) {
            return of(true);
        } else {
            return this.fileDataService.GetFilesById(fileIds).pipe(first(), map(files => files.some(f => f?.MIMEType !== 'application/pdf')))
        }
    }
    onFileClick(file: FileEntity) {
        this.FileClick.emit({ file, multiselect: this.multiselect });
        if (this.multiselect) {
            if (this.SelectedFiles.every((id) => id !== file.Id)) {
                this.SelectedFiles.push(file.Id);
                this.SelectedFilesChange.emit(this.SelectedFiles.slice());
                this.changeDetectionRef.detectChanges();
            } else if (this.SelectedFiles.includes(file.Id)) {
                this.SelectedFiles.splice(this.SelectedFiles.indexOf(file.Id), 1);
                this.SelectedFilesChange.emit(this.SelectedFiles.slice());
                this.changeDetectionRef.detectChanges();
            }
        }
    }
    resetMultiselect() {
        this.SelectedFiles = [];
        this.multiselect = false;
    }
    onEmailClick() {
        const FileAttachmntFileIds = this.SelectedFiles.slice();
        if (FileAttachmntFileIds?.length) {
            this.resetMultiselect();
            this.dialog.open<EmailEditorComponent, EmailEditorComponentDialogData>(EmailEditorComponent, {
                ...EmailEditorComponent.DefaultConfig,
                data: {
                    FileAttachmntFileIds,
                },
            });
        }
    }

    onMergePdfClick() {
        this.MergePdfClick.emit(this.SelectedFiles);
        this.resetMultiselect();
    }


    onFileDownloadClick(){
        if (this.SelectedFiles.length){
            // DownloadZip()
            // DownloadVersions()
            if (this.SelectedFiles.length == 1)
            { /// wenn nur eine Datei ausgewählt ist, dann holen wir die lätzte Version davon
                firstValueFrom(this.store.select(getFileById({id: this.SelectedFiles[0]}))).then(document => {
                    DownloadVersions( [ document.GetLastVersion() ] );
                    //download version
                })
            }
            else {
                  ////  wenn mehrere Dateien ausgewält sind, dann werden sie als zip-datei heruntergeladen
                DownloadZip(this.SelectedFiles , []); // zweite Parameter leeres array bei folderIds muss nicht angegeben werden
            }
        }
    }

    onFileMoveClick(){

        if (this.SelectedFiles.length){
            this.moveFileFolderService.MoveFileFolder( this.SelectedFiles , [] );
            this.resetMultiselect();
        }
    }

    isNotExcludedFromRemove(id: number) {
        return this.excludeRemoveButtonFromFiles ? !this.excludeRemoveButtonFromFiles.includes(id) : true
    }


    protected readonly ReportsPageMeta = ReportsPageMeta;
}

