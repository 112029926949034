import {Component, Input} from '@angular/core';
import {LoginService} from '../../services/login.service';
import { SettingsPageMeta } from '../../helper/page-metadata';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
    public SettingsMeta = SettingsPageMeta;
    constructor(public LogoutService: LoginService) {
    }
    @Input() ButtonText = 'Logout';
}
