import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { SimplebarAngularModule } from 'simplebar-angular';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../dave-utils-module/app-filter-module/app-filter.module';
import { MultiSelectTableComponent } from './components/multi-select-table/multi-select-table.component';
import {
SelectCommissionDialogComponent
} from './components/select-user-dialogs/select-commission-dialog/select-commission-dialog.component';
import {
SelectGroupMemberDialogComponent
} from './components/select-user-dialogs/select-group-member-dialog/select-group-member-dialog.component';
import { SelectUserCommissionDialogComponent } from './components/select-user-dialogs/select-user-commission-dialog/select-user-commission-dialog.component';
import {
SelectUserCustomerDialogComponent
} from './components/select-user-dialogs/select-user-customer-dialog/select-user-customer-dialog.component';
import {
SelectUserEmployeeDialogComponent
} from './components/select-user-dialogs/select-user-employee-dialog/select-user-employee-dialog.component';
import { SelectUserEventDialogComponent } from './components/select-user-dialogs/select-user-event-dialog/select-user-event-dialog.component';
import { NewCommissionDialogComponent } from "../new-commission-dialog/new-commission-dialog.component";



@NgModule({
    declarations: [
        MultiSelectTableComponent,
        SelectUserCommissionDialogComponent,
        SelectUserEventDialogComponent,
        SelectUserCustomerDialogComponent,
        SelectUserEmployeeDialogComponent,
        SelectGroupMemberDialogComponent,
        SelectCommissionDialogComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        AppButtonModule,
        SimplebarAngularModule,
        MatTableModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatChipsModule,
        ScrollingModule,
        TableVirtualScrollModule,
        ReactiveFormsModule,
        MatSortModule,
        MatCheckboxModule,
        MatSelectModule,
        DaveLoadingModule,
        AppFilterModule,
        MatBadgeModule,
        MatTooltipModule,
        DaveDoubleIconModule,
        NewCommissionDialogComponent
    ],
    exports: [SelectUserCommissionDialogComponent, MultiSelectTableComponent],
})
export class DaveSelectUserModule {}
