import { ResourceToCommissionType } from '../../graphql-types';
import { BackendDateTimestamp } from '../../helper/backend-frontend-conversion.helper';


export enum WorkTypeEnum {
    FreeWork = 'FreeWork'
}


export class ResourceToCommissionEntity {
    constructor(
        public Id: number = null,
        public CommissionId: number = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public Date: Date = null,
        public DeletedAt: Date = null,
        public Multiplicator: number = null,
        public Name: string = null,
        public PartnerId: number = null,
        public QuantityTypeId: number = null,
        public ResourceId: number = null,
        public UserId: number = null,
        public Quantity: number = null,
        public Type: string = null,
    ) {}

    public static readonly GqlFields: Array<keyof ResourceToCommissionType> = ['id', 'commissionId', 'createdAt', 'updatedAt', 'date', 'deletedAt', 'multiplicator', 'name', 'partnerId', 'quantityTypeId', 'resourceId', 'userId', 'value' , 'workType'];

    public Clone(override: Partial<ResourceToCommissionEntity> = {}): ResourceToCommissionEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ResourceToCommissionEntity(), attr);
    }
}

export function ResourceToCommissionEntityFromBackend(res: ResourceToCommissionType): ResourceToCommissionEntity {
    return new ResourceToCommissionEntity(
        res.id,
        res.commissionId,
        res.createdAt ? BackendDateTimestamp(res.createdAt) : null,
        res.updatedAt ? BackendDateTimestamp(res.updatedAt) : null,
        res.date ? BackendDateTimestamp(res.date) : null,
        res.deletedAt ? BackendDateTimestamp(res.deletedAt) : null,
        res.multiplicator,
        res.name,
        res.partnerId,
        res.quantityTypeId,
        res.resourceId,
        res.userId ? res.userId: null,
        res.value,
        res.workType,

    );
}
