import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';
import {
    selectAllUserToCustomer,
    selectUserToCustomerEntities,
    selectUserToCustomerFetched,
    selectUserToCustomerLatestUpdatedAt,
    userToCustomerFeatureKey,
    UserToCustomerState,
} from '../reducers/user-to-customer.reducer';

const selectUserToCustomer = createFeatureSelector< UserToCustomerState>(userToCustomerFeatureKey);
const getEntities = createSelector(selectUserToCustomer, selectUserToCustomerEntities);

export const getUserToCustomers = createSelector(selectUserToCustomer, selectAllUserToCustomer);
export const getUserToCustomersFetched = createSelector(selectUserToCustomer, selectUserToCustomerFetched);
export const getUserToCustomerLatestUpdatedAt = createSelector(selectUserToCustomer, selectUserToCustomerLatestUpdatedAt);
export const getUserToCustomerById = createSelector(getEntities, (userToCustomer: ReturnType<typeof getEntities>, Id: number) => Id && userToCustomer[Id]);
