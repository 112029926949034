import { Component, OnInit } from '@angular/core';
import { appMatDialogDefaultConfig, HomepageBaseUrl } from '../../../../../helper/helper';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
    selector: 'app-support-dialog',
    templateUrl: './support-dialog.component.html',
    styleUrls: ['./support-dialog.component.scss'],
})
export class SupportDialogComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
    };
    public HomepageBaseUrl = HomepageBaseUrl;
    constructor() {}
}
