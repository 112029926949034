import { createReducer, on } from '@ngrx/store';
import { FileEntity } from '../../entities/file.entity';
import { FilesActionTypes } from '../actions/files.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { BusinessVolumeActionTypes } from '../actions/business-volume.actions';

export const FILES_FEATURE_KEY = 'files';

export interface FileState extends EntityState<FileEntity> {
    fetched: boolean;
}
const sortByName = (a, b): 1 | -1 | 0 => {
    const aName = (a.Name || '').toLowerCase();
    const bName = (b.Name || '').toLowerCase();
    return aName < bName ? -1 : aName > bName ? 1 : 0;
};

const adapter = createEntityAdapter<FileEntity>({
    selectId: Entity => Entity.Id,
    sortComparer: sortByName,
});

const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const filesReducer = createReducer(
    initialState,
    on(FilesActionTypes.DeleteFileSuccess, (state, { Payload }) => adapter.removeOne(Payload, { ...state })),
    on(FilesActionTypes.UpdateMany, (state, { Payload }) => adapter.setMany(Payload, { ...state })),
    on(FilesActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(FilesActionTypes.UploadFileBackendSuccess, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(BusinessVolumeActionTypes.ExportSuccess, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(FilesActionTypes.ModifyFileSuccess, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(FilesActionTypes.CreateLinkSuccess, (state, { Payload }) => adapter.addOne(Payload, { ...state })),
    on(FilesActionTypes.UpdateOneVersion, (state, { Payload }) => adapter.setOne(state.entities[Payload.DocumentId].Clone({Versions: state.entities[Payload.DocumentId].Versions.map(v => v.Id === Payload.Id ? Payload : v)}), { ...state })),
    on(FilesActionTypes.UpdateAll, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllFile = selectAll;
export const selectFileEntities = selectEntities;
export const selectFileIds = selectIds;
export const selectFileFetched = (state: FileState) => state.fetched;
