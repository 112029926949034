import { CommonModule } from '@angular/common';
import { Component, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResolveData } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, interval, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { NewCustomerMainComponent } from '../../../components/new-view/new-customer/new-customer-main/new-customer-main.component';
import { CustomFormsModule } from '../../../custom-forms-module/custom-forms.module';
import { ClockInTypeEntity, EnumClockInTypeSlug } from '../../../dave-data-module/entities/clock-in-type.entity';
import { ClockInTypes } from '../../../dave-data-module/entities/clock-in.entity';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { EmployeeEntity } from '../../../dave-data-module/entities/employee.entity';
import { DaveMutationCreateClockInArgs } from '../../../dave-data-module/graphql-types';
import { ClockInTypeResolver } from '../../../dave-data-module/guards/clock-in-type.resolver';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { EmployeeResolver } from '../../../dave-data-module/guards/employee.resolver';
import { FrontendDateTimestamp } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { ClockInActionTypes } from '../../../dave-data-module/State/actions/clock-in.actions';
import { clockInTypesFeatureKey } from '../../../dave-data-module/State/reducers/clock-in-type.reducer';
import { commissionsFeatureKey } from '../../../dave-data-module/State/reducers/commission.reducer';
import { employeesFeatureKey } from '../../../dave-data-module/State/reducers/employees.reducer';
import { getClockInTypeById, getClockInTypes, getClockInTypesFetched } from '../../../dave-data-module/State/selectors/clock-in-type.selectors';
import { getClockInsFetched, getClockInsWithoutDeleted } from '../../../dave-data-module/State/selectors/clock-in.selectors';
import { getCommissionsActive, getCommissionsFetched } from '../../../dave-data-module/State/selectors/commission.selector';
import { getEmployeeByUserId, getEmployees, getEmployeesFetched } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { TimeTrackerService } from '../../../dave-time-tracker/time-tracker.service';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { SelectSearchOption } from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { SelectSearchModule } from '../../../dave-utils-module/select-search/select-search.module';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, sortClockIns, uniqArray } from '../../../helper/helper';
import { CustomLabelService } from '../../../services/custom-label.service';
import { LoadingService } from '../../../services/loading.service';
import { ClockInHelperService } from "../../../services/clock-in-helper.service";
import { PENDING_CHANGES_DEFAULT_MESSAGE } from '../../../dave-data-module/guards/pending-changes.guard';
import {
    convertNodeSourceSpanToLoc
} from '@angular-eslint/template-parser/dist/template-parser/src/convert-source-span-to-loc';

@Component({
    selector: 'app-time-tracker-multiple',
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatCardModule, AppButtonModule, FontAwesomeModule, MatButtonModule, MatTooltipModule, MatMenuModule, CustomFormsModule, ReactiveFormsModule, MatInputModule, SelectSearchModule],
    templateUrl: './time-tracker-multiple.component.html',
    styleUrls: ['./time-tracker-multiple.component.scss'],
})
export class TimeTrackerMultipleComponent implements OnInit, OnDestroy {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        width: '32rem',
        maxWidth: '95vw',
        disableClose: true,
    };
    public static readonly RequiredResolvers: ResolveData = {
        [employeesFeatureKey]: EmployeeResolver,
        [commissionsFeatureKey]: CommissionResolver,
        [clockInTypesFeatureKey]: ClockInTypeResolver,
    };
    protected form = new FormGroup({
        employees: new FormControl<EmployeeEntity[]>(null, [Validators.required]),
        commission: new FormControl<SelectSearchOption<CommissionEntity>>(null),
        workDescription: new FormControl<string>(null),
    });
    protected selectableCommissions$: Observable<SelectSearchOption<CommissionEntity>[]> = this.store.select(getCommissionsFetched).pipe(
        filter((f) => f),
        switchMap(() => this.store.select(getCommissionsActive)),
    );
    protected selectableEmployees$ = this.store.select(getEmployeesFetched).pipe(
        filter((f) => f),
        switchMap(() => this.store.select(getEmployees)),
    );
    private clockIns$ = this.store.select(getClockInsFetched).pipe(
        filter((f) => f),
        switchMap(() => this.store.select(getClockInsWithoutDeleted)),
        map((clockIns) => {
            const now = new Date().getTime();
            return clockIns.filter((c) => c.TimeStamp?.getTime() <= now);
        }),
    );
    protected selectableClockInTypes$ = this.timeTrackerService.clockInTypesWithoutMain$;
    private mainClockInType$ = this.timeTrackerService.mainClockInType$;
    private getLastClockInByEmployeeId$ = (employeeId: number) =>
        combineLatest([this.clockIns$, this.mainClockInType$]).pipe(
            map(([cis, mainClockInType]) => {
                const lastClockIn = cis
                    .filter((ci) => ci.EmployeeId === employeeId)
                    .sort(sortClockIns(mainClockInType.Id))
                    .reverse()
                    .find(() => true);
                return lastClockIn;
            }),
        );
    private hasRunningWorkdayByEmployeeId$ = (employeeId: number) =>
        this.getLastClockInByEmployeeId$(employeeId).pipe(
            switchMap((clockIn) =>
                !clockIn
                    ? of(false)
                    : clockIn.Type === ClockInTypes.End
                    ? this.store.select(getClockInTypesFetched).pipe(
                          filter((f) => f),
                          switchMap(() => this.store.select(getClockInTypeById({ id: clockIn.ClockInTypeId }))),
                          map((ct) => ct.Slug !== EnumClockInTypeSlug.workTime),
                      )
                    : of(true),
            ),
        );
    protected myOwnLastClockIn$ = this.store.select(getUser).pipe(
        map((user) => user.Id),
        distinctUntilChanged(),
        switchMap((userId) => this.store.select(getEmployeeByUserId({ userId }))),
        map((employee) => employee?.Id),
        filter(isNotNullOrUndefined),
        distinctUntilChanged(),
        switchMap(this.getLastClockInByEmployeeId$),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected runningType$ = this.myOwnLastClockIn$.pipe(
        switchMap((clockIn) =>
            clockIn?.ClockInTypeId && clockIn.Type === ClockInTypes.Start
                ? this.store.select(getClockInTypesFetched).pipe(
                      filter((f) => f),
                      switchMap(() => this.store.select(getClockInTypeById({ id: clockIn.ClockInTypeId }))),
                  )
                : of(null),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected runningTime$ = this.runningType$.pipe(
        switchMap((runningType) => {
            if (runningType) {
                return this.myOwnLastClockIn$.pipe(
                    switchMap((lastClockIn) =>
                        interval(1000).pipe(
                            map(() => new Date().getTime() - lastClockIn.TimeStamp.getTime()),
                            map((timeMs) => {
                                return {
                                    h: Math.trunc(timeMs / 1000 / 60 / 60),
                                    m: Math.trunc(timeMs / 1000 / 60) % 60,
                                    s: Math.trunc(timeMs / 1000) % 60,
                                };
                            }),
                        ),
                    ),
                );
            } else {
                return this.timeTrackerService.RunningWorkTime$;
            }
        }),
    );

    protected someoneHasRunningWorkday$ = this.form.controls.employees.valueChanges.pipe(
        startWith(''),
        map(() => this.form.controls.employees.value),
        switchMap((value) => (value?.length ? combineLatest(value.map((e) => this.hasRunningWorkdayByEmployeeId$(e.Id))).pipe(map((values) => values.some((v) => v))) : of(false))),
    );
    private subs: Subscription[] = [];
    constructor(
        private store: Store<State>,
        injector: Injector,
        protected cls: CustomLabelService,
        protected ls: LoadingService,
        private actions$: Actions,
        private timeTrackerService: TimeTrackerService,
        private clockInHelperService: ClockInHelperService,
        private dialogRef: MatDialogRef<TimeTrackerMultipleComponent>,
    ) {
        if (this.dialogRef) {
            this.subs.push(this.dialogRef.backdropClick().subscribe(() => {
                this.closeDialog();
            }))
        }
        Object.values(NewCustomerMainComponent.RequiredResolvers).forEach((resolver) => {
            injector.get(resolver).resolve();
        });
    }

    getName(entity: EmployeeEntity) {
        return entity.DisplayName;
    }
    compareById(a, b) {
        return a?.Id === b?.Id;
    }
    async addClockIns(clockInTypeId: number, type: ClockInTypes, addStartMainClockInIfMissing = false) {
        const selectedEmployees = this.form.value.employees;
        const timeStamp = FrontendDateTimestamp(new Date());
        const commissionId = this.form.controls.commission.getRawValue()?.Id || undefined;
        const workDescription = this.form.value.workDescription;

        if (selectedEmployees?.length && clockInTypeId) {
            this.ls.startLoading('add-clock-ins');
            const payload: DaveMutationCreateClockInArgs[] = selectedEmployees.map((employee) => ({
                clockInTypeId,
                commissionId,
                workDescription,
                employeeId: employee.Id,
                type,
                timeStamp,
            }));
            if (addStartMainClockInIfMissing) {
                const employeesWithoutRunningWorkDay$ = combineLatest(selectedEmployees.map((employee) => this.hasRunningWorkdayByEmployeeId$(employee.Id).pipe(map((running) => (running ? null : employee))))).pipe(
                    map((employeeIds) => employeeIds.filter(isNotNullOrUndefined)),
                );
                const [employees, mainClockInType] = await firstValueFrom(combineLatest([employeesWithoutRunningWorkDay$, this.mainClockInType$]));
                if (employees.length) {
                    payload.push(
                        ...employees.map<DaveMutationCreateClockInArgs>((employee) => ({
                            clockInTypeId: mainClockInType.Id,
                            // commissionId, soll nicht gesetzt werden
                            employeeId: employee.Id,
                            type: ClockInTypes.Start,
                            timeStamp,
                        })),
                    );
                }
            }
            firstValueFrom(this.actions$.pipe(ofType(BaseActionTypes.ErrorAction, ClockInActionTypes.AddClockInsSuccess))).then(() => {
                this.ls.endLoading('add-clock-ins');
                this.form.controls.workDescription.reset();
            });
            this.clockInHelperService.dispatchClockInActions(payload, [], []);
        }
    }
    async onStopClick() {
        const runningType = await firstValueFrom(this.runningType$);
        this.addClockIns(runningType?.Id, ClockInTypes.End);
    }
    async onStopDayClick() {
        const selectedEmployees = this.form.value.employees;
        const timeStamp = FrontendDateTimestamp(new Date());
        const mainClockInType = await firstValueFrom(this.mainClockInType$);

        const employeesWithRunningWorkDay$ = combineLatest(selectedEmployees.map((employee) => this.hasRunningWorkdayByEmployeeId$(employee.Id).pipe(map((running) => (running ? employee : null))))).pipe(
            map((employees) => employees.filter(isNotNullOrUndefined)),
        );
        const employees = await firstValueFrom(employeesWithRunningWorkDay$);

        if (employees.length) {
            this.ls.startLoading('add-clock-ins');
            firstValueFrom(this.actions$.pipe(ofType(BaseActionTypes.ErrorAction, ClockInActionTypes.AddClockInsSuccess))).then(() => this.ls.endLoading('add-clock-ins'));
            this.clockInHelperService.dispatchClockInActions(employees.map<DaveMutationCreateClockInArgs>((employee) => ({
                clockInTypeId: mainClockInType.Id,
                employeeId: employee.Id,
                type: ClockInTypes.End,
                timeStamp,
            })), [], []);
        }
    }
    onStartByTypeClick(clType: ClockInTypeEntity) {
        this.addClockIns(clType?.Id, ClockInTypes.Start, true).then(() => this.form.markAsPristine());
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    ngOnInit(): void {
        firstValueFrom(this.myOwnLastClockIn$)
            .then((clockIn) => {
                if (clockIn?.CommissionId) {
                    return firstValueFrom(this.selectableCommissions$.pipe(map((commissions) => commissions.find((c) => c.Id === clockIn.CommissionId))));
                }
                return firstValueFrom(of(null));
            })
            .then((commission) => {
                if (commission) {
                    this.form.controls.commission.setValue(commission, {emitEvent: false});
                }
            });
        firstValueFrom(this.myOwnLastClockIn$)
            .then((clockIn) => {
                if (clockIn) {
                    return firstValueFrom(
                        this.clockIns$.pipe(
                            map((clockIns) => uniqArray(clockIns.filter((c) => c.TimeStamp.getTime() === clockIn.TimeStamp.getTime()).map((c) => c.EmployeeId))),
                            switchMap((employeeIds) => this.selectableEmployees$.pipe(map((employees) => employees.filter((e) => employeeIds.includes(e.Id))))),
                            switchMap(employees => combineLatest(employees.map(e => this.getLastClockInByEmployeeId$(e.Id).pipe(map(lastCiFromEmployee => lastCiFromEmployee?.TimeStamp?.getTime() === clockIn.TimeStamp.getTime() ? e : null))))),
                            map(employees => employees.filter(isNotNullOrUndefined)),
                        ),
                    );
                } else {
                    return firstValueFrom(combineLatest([this.selectableEmployees$, this.store.select(getUser)]).pipe(map(([employees, user]) => employees.filter((e) => e.UserId === user.Id))));
                }
            })
            .then((employees) => {
                this.form.controls.employees.setValue(employees, {emitEvent: false});
            });
        this.subs.push(
            combineLatest([
                this.runningType$.pipe(
                    map((t) => !!t),
                    distinctUntilChanged(),
                ),
                this.ls.IsLoading$,
            ]).subscribe(([type, loading]) => {
                const wasPristine = this.form.controls.commission.pristine;
                if (type || loading) {
                    this.form.controls.commission.disable();
                } else {
                    this.form.controls.commission.enable();
                }
                if (wasPristine) {
                    this.form.controls.commission.markAsPristine();
                }

            }),
            // this.form.controls.commission.valueChanges.subscribe((value) => {
            //     if (value?.Id) {
            //         //todo Mitarbeiter auswählen die dem auftrag als monteur oder vorarbeiter zugewiesen und nicht abwesend sind
            //     }
            // }),
        );
    }
    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): boolean {
        return !this.form.dirty;
    }
    closeDialog() {
        if (this.dialogRef) {
            if (this.canDeactivate() || confirm(PENDING_CHANGES_DEFAULT_MESSAGE)) {
                this.dialogRef.close();
            }
        }
    }
}
