import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CustomerLocationEntity } from '../../entities/customer-location.entity';
import { CustomerTypeEntity } from '../../entities/customer-type.entity';
import { CustomerEntity } from '../../entities/customer.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { CustomerTypeActionTypes } from '../actions/customer-types.actions';
import { CustomerActionTypes } from '../actions/customers.actions';

export const customersFeatureKey = 'customers';

export interface CustomerState extends EntityState<CustomerEntity> {
    customerTypes: CustomerTypeEntity[];
    customerLocations: CustomerLocationEntity[];
    fetched: boolean;
    latestUpdatedAt: Date | null;
}
const adapter = createEntityAdapter<CustomerEntity>({
    selectId: Entity => Entity.Id,
});

export const initialState: CustomerState = adapter.getInitialState({
    customerTypes: null,
    customerLocations: null,
    fetched: false,
    latestUpdatedAt: null,
});

export const customerReducer = createReducer(
    initialState,
    on(CustomerActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, { ...state })),
    on(CustomerActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(CustomerActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(CustomerTypeActionTypes.UpdateCustomerTypes, (state, { Payload }) => ({
        ...state,
        customerTypes: Payload,
    })),
    on(CustomerTypeActionTypes.UpdateCustomerLocations, (state, { Payload }) => ({
        ...state,
        customerLocations: Payload,
    })),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllCustomer = selectAll;
export const selectCustomerEntities = selectEntities;

export const selectCustomerFetched = (state: CustomerState) => state.fetched;
export const selectCustomerLatestUpdatedAt = (state: CustomerState) => state.latestUpdatedAt;
