import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { ErrorPopupService } from './error-popup.service';

@Component({
    standalone: true,
    selector: 'app-error-popup',
    templateUrl: './error-popup.component.html',
    styleUrls: ['./error-popup.component.scss'],
    imports: [
        MatCardModule,
        AppButtonModule,
    ],
})
export class ErrorPopupComponent {
    constructor(public PopupService: ErrorPopupService) {}

    reload() {
        window.location.reload()
    }
}
