import { Address } from '../../helper/helper';
import { PartnerOfficeType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

type IRegexHelper = {
    startCounter: number;
    endCoutner: number;
}

export interface PartnerOfficeAdditionalData {
    rechnungsnNummer?: IRegexHelper;
    angebotsNummer?: IRegexHelper;

    BestellNummer?: IRegexHelper;
    AuftragsbestaetigungsNummer?: IRegexHelper;
    AufmassNummer?: IRegexHelper;
    BestellbestätigungNummer?: IRegexHelper;
    LieferscheinNummer?: IRegexHelper;
    StornoNummer?: IRegexHelper;
    GutschriftNummer?: IRegexHelper;
    Abschlagsnummer?: IRegexHelper;
    SchlussNummer?: IRegexHelper;

    invoiceSfdtTemplateId?: number;
    offerSfdtTemplateId?: number;
    type?: string;
    port?: number;
    host?: string;
    method?: string;
    sendPort?: number;
    sendHost?: string;
    sendMethod?: string;
    hideEmployeeSalaryType?: boolean;
    defaultWorkTimeBegin?: string; //"07:00"
    defaultClockInTypeId?: number; // for time-tracker
    calculateDefaultEndClockInFromEmployeeWorkingHours?: boolean; // for time-tracker
}


export class PartnerOfficeEntity implements Address {
    constructor(
        public BankLocation: string = null,
        public BankName: string = null,
        public BankPostalCode: string = null,
        public BankSortCode: string = null,
        public Bic: string = null,
        public City: string = null,
        public CostCentre: string = null,
        public Country: string = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public Email: string = null,
        public FaxNumber: string = null,
        public IBAN: string = null,
        public Id: number = null,
        public MainOffice: Boolean = null,
        public MobilNumber: string = null,
        public Name: string = null,
        public PartnerId: number = null,
        public PhoneNumber: string = null,
        public PostalCode: string = null,
        public Steuernummer: string = null,
        public Street: string = null,
        public UpdatedAt: Date = null,
        public UstID: string = null,
        public EmailSignature: string = null,
        public AdditionalData: PartnerOfficeAdditionalData = null,
        public CeoName: string = null,
        public RegistryCourt: string = null,
        public CommercialRegister: string = null,
    ) {}
    public Clone(override: Partial<PartnerOfficeEntity> = {}): PartnerOfficeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new PartnerOfficeEntity(), attr);
    }
    get DisplayName() {
        return this.Name;
    }
    public static readonly GqlFields = `
bankLocation
bankName
bankPostalCode
bankSortCode
bic
city
costCentre
country
createdAt
deletedAt
email
faxNumber
iBAN
id
mainOffice
mobilNumber
name
partnerId
phoneNumber
postalCode
steuernummer
street
updatedAt
ustID
signature
additionalData
cEOName
registryCourt
commercialCourt`;
}

export function PartnerOfficeEntityFromBackend(res: PartnerOfficeType): PartnerOfficeEntity {
    return new PartnerOfficeEntity(
        res.bankLocation,
        res.bankName,
        res.bankPostalCode,
        res.bankSortCode,
        res.bic,
        res.city,
        res.costCentre,
        res.country,
        BackendDate(res.createdAt),
        res.deletedAt ? BackendDate(res.deletedAt) : null,
        res.email,
        res.faxNumber,
        res.iBAN,
        res.id,
        res.mainOffice,
        res.mobilNumber,
        res.name,
        res.partnerId,
        res.phoneNumber,
        res.postalCode,
        res.steuernummer,
        res.street,
        res.updatedAt ? BackendDate(res.updatedAt) : null,
        res.ustID,
        res.signature,
        JSON.parse(res.additionalData) as PartnerOfficeAdditionalData,
        res.cEOName,
        res.registryCourt,
        res.commercialCourt,
    );
}
