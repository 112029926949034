import { BackendDate } from '../helper/backend-frontend-conversion.helper';
import {EventEntity, EventEntityFromBackend} from './event.entity';
import { EventBookmarkType } from '../graphql-types';

export class BookmarkEntity {
    constructor(
        public Id: number = null,
        public EventId: number = null,
        public UserId: number = null,
        public Event: EventEntity = null,
        public CreatedAt: Date = null
    ) {
    }

    public static GQLFields = `
    event {
        ${EventEntity.GQLFields}
        }
    eventId
    id
    userId
    createdAt
    `;
    public Clone(override: BookmarkEntityAttributes = {}): BookmarkEntity {
        const attr = {...this, ...override};
        return Object.assign(new BookmarkEntity(), attr);
    }
}

export function BookmarkEntityFromBackend(res: EventBookmarkType): BookmarkEntity {
    return new BookmarkEntity(
        res.id,
        res.eventId,
        res.userId,
        EventEntityFromBackend(res.event),
        BackendDate(res.createdAt)
    );
}

export interface BookmarkEntityAttributes {
    Id?: number;
    EventId?: number;
    UserId?: number;
    Event?: EventEntity;
    CreatedAt?: Date;
}

