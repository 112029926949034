<app-detail-view-template>
    <ng-container *appDetailViewButtons>
        <div [matTooltipDisabled]="!saveBtn?.Disabled" matTooltip="Ressource wurde gelöscht" *ngIf="!Editing">
            <button [IsLoading]="LS.IsLoading$ | async" app-round-button matTooltip="Bearbeiten" #saveBtn [Inverted]="true" class="header-button" (click)="Editing = true" [Disabled]="!!(Resource$ | async)?.DeletedAt">
                <fa-icon icon="pencil-alt"></fa-icon>
            </button>
        </div>
        <button [IsLoading]="LS.IsLoading$ | async" app-round-button matTooltip="Speichern" class="header-button" *ngIf="Editing" (click)="onSave()" Color="green" [Disabled]="!(resourceData?.FormsValid$ | async)">
            <fa-icon icon="save"></fa-icon>
        </button>
        <button [IsLoading]="LS.IsLoading$ | async" app-round-button matTooltip="Zurücksetzen" [Inverted]="true" class="header-button" *ngIf="Editing" (click)="onUndo()">
            <fa-icon icon="undo"></fa-icon>
        </button>
        <button [IsLoading]="LS.IsLoading$ | async" app-round-button matTooltip="Löschen" class="header-button" *ngIf="Editing && (ResourceId$ | async)" (click)="onDelete()" Color="red">
            <fa-icon icon="trash"></fa-icon>
        </button>
    </ng-container>
    <ng-container *appDetailViewLeftContent>
        <mat-card>
            <app-resource-main-data #resourceData [ShowResourceButton]="false" [FolderId]="FolderId$ | async" [(Editing)]="Editing" [ResourceId]="ResourceId$ | async"></app-resource-main-data>
        </mat-card>
        <ng-container *ngIf="ResourceId$ | async">
            <ng-container *ngIf="!(fileExplorerLoading | async); else loading">
                <app-file-explorer
                    *ngIf="FolderId$ | async as id"
                    [SelectedFolderId]="id"
                    [FileUploadButton]="true"
                ></app-file-explorer>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *appDetailViewRightContent>
        <app-dave-list-card
            *ngIf="ResourceId$ | async"
            [Headline]="cls.getMultiple$('Commission') | async"
            [HeaderIcon]="CommissionMeta.Icon"
            EmptyText="Keine Zuordnungen vorhanden"
            [Data]="ResourceScheduleTableData$ | async"
            [DisplayedCollums]="['CommissionName', 'Amount', 'TimeSpan']"
            [TableHeaderCollumsMap]="{ CommissionName: cls.getSingle$('Commission') | async, Amount: 'Anzahl', TimeSpan: 'Zeitspanne' }"
            [Clickable]="true"
            [GetRouterLinkFunction]="ResourceScheduleTableDataRouterLink"
            style="max-height: 50vh"
        >
        </app-dave-list-card>
        <app-dave-list-card
            [Headline]="reportsView.headline"
            HeaderIcon="file-signature"
            [EmptyText]="'Keine ' + reportsView.headline +  ' vorhanden'"
            [Data]="reportsData$"
            [HasSuffixButton]="true"
            [DisplayedCollums]="['label']"
            [Clickable]="true"
            [GetRouterLinkFunction]="GetRouteForGenDoc"
            [IsExpandable]="true"
            [Expanded]="reportsView.expanded"
            style="max-height: 50vh"
        >
            <app-add-report-button *ngIf="ResourceId$ | async as id" headerButton [Inverted]="false" [ResourceId]="id"></app-add-report-button>
        </app-dave-list-card>

    </ng-container>
</app-detail-view-template>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
