import {CustomerLocationType, } from '../graphql-types';

export class CustomerLocationEntity {
    constructor(
        public Name: string = null
    ) {}

    public Clone(override: CustomerLocationEntityAttributes = {}): CustomerLocationEntity {
        const attr = {...this, ...override};
        return Object.assign(new CustomerLocationEntity(), attr);
    }
}

export function CustomerLocationEntityFromBackend(res: CustomerLocationType): CustomerLocationEntity {
    return new CustomerLocationEntity(
        res.hQLocation
    );
}

export interface CustomerLocationEntityAttributes {
    Name?: string;
}
