import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';
import {
    selectAllUserToCommissionShift,
    selectUserToCommissionShiftEntities, selectUserToCommissionShiftFetched, selectUserToCommissionShiftLatestUpdatedAt,
    user2CommissionShiftKey,
    User2commissionShiftState,
} from '../reducers/user2CommissionShift.reducer';
import { usersFeatureKey } from '../reducers/users.reducer';


const selectState = createFeatureSelector<User2commissionShiftState>(user2CommissionShiftKey);

export const getUserToCommissionShiftDictionary = createSelector(selectState, selectUserToCommissionShiftEntities);
export const getUserToCommissionShifts = createSelector(selectState, selectAllUserToCommissionShift);
export const getUserToCommissionShiftsActive = createSelector(getUserToCommissionShifts, (entities) => entities.filter(e => !e.DeletedAt));
export const getUserToCommissionShiftFetched = createSelector(selectState, selectUserToCommissionShiftFetched);
export const getUserToCommissionShiftLatestUpdatedAt = createSelector(selectState, selectUserToCommissionShiftLatestUpdatedAt);

export const getUserToCommissionShiftById = (props: { id: number }) => createSelector(getUserToCommissionShiftDictionary, (dictionary) => props?.id && dictionary[props.id]);
