import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {
    CommissionMeta,
    ContactBookMeta,
    CustomerAdministrationMeta,
    DMSPageMeta,
    HistoryMeta,
    ReportsPageMeta,
    UserAdministrationMeta
} from '../../helper/page-metadata';
import {SearchType} from '../graphql-types';

export enum SearchTypeEnum {
    event = 'event',
    customer = 'customer',
    commission = 'commission',
    person = 'person',
    document = 'document',
    generated_document = 'generated_document',
    employee = 'employee',
}

export interface SearchResultEventType {
    id: number;
    partnerId: number;
    description: string | null;
    hint: string | null;
    start: string | null;
    end: string | null;
    name: string | null;
    street: string | null;
    postalCode: string | null;
    city: string | null;
    country: string | null;
    updatedAt: string | null;
    is_task: boolean;
}

export interface SearchResultCustomerType {
    id: number;
    partnerId: number;
    name: string | null;
    customerNo: string | null;
    description: string | null;
    ustId: string | null;
    steuernummer: string | null;
    hqLocation: string | null;
    updatedAt: string | null;
}

export interface SearchResultCommissionType {
    id: number;
    partnerId: number;
    auftragsnummer: string | null;
    schadennummer: string | null;
    versicherungsnummer: string | null;
    interneNummer: string | null;
    description: string | null;
    aktenzeichen: string | null;
    versicherungsName: string | null;
    versicherungsPhoneNumber: string | null;
    versicherungsMobileNumber: string | null;
    versicherungsStreet: string | null;
    versicherungsPostalCode: string | null;
    versicherungsCity: string | null;
    versicherungsZusatzName: string | null;
    versicherungsDescription: string | null;
    versicherungsArbeitsgebiet: string | null;
    damageFlowDescription: string | null;
    damageFlowCity: string | null;
    damageFlowStreet: string | null;
    damageFlowPostalCode: string | null;
    country: string | null;
    postalCode: string | null;
    street: string | null;
    city: string | null;
    updatedAt: string | null;
}

export interface SearchResultPersonType {
    id: number;
    partnerId: number;
    firstname: string | null;
    lastname: string | null;
    email: string | null;
    phoneNumber: string | null;
    mobileNumber: string | null;
    faxNumber: string | null;
    description: string | null;
    updatedAt: string | null;

    city: string | null;
    country: string | null;
    postalCode: string | null;
    street: string | null;
}

export interface SearchResultDocumentType {
    id: number;
    partnerId: number;
    name: string | null;
    mimeType: string | null;
    description: string | null;
    tags: string[] | null;
    updatedAt: string | null;
}

export interface SearchResultGeneratedDocumentType {
    id: number;
    partnerId: number;
    name: string | null;
    updatedAt: string | null;
}

export interface SearchResultEmployeeType {
    id: number;
    partnerId: number;
    country: string | null;
    email: string | null;
    employeeNo: string | null;
    faxNumber: string | null;
    firstname: string | null;
    jobSpecification: string | null;
    lastname: string | null;
    latitude: number | null;
    longitude: number | null;
    mobileNumber: string | null;
    phoneNumber: string | null;
    postalCode: string | null;
    street: string | null;
    city: string | null;
    updatedAt: string | null;
}

export interface SearchResultType {
    type: SearchTypeEnum;
    score: number;
    event: SearchResultEventType | null;
    customer: SearchResultCustomerType | null;
    commission: SearchResultCommissionType | null;
    person: SearchResultPersonType | null;
    document: SearchResultDocumentType | null;
    generated_document: SearchResultGeneratedDocumentType | null;
    employee: SearchResultEmployeeType | null;
}

export class SearchResultEntity {
    constructor(
        public AvailableTypes: SearchTypeEnum[] = null,
        public Found: SearchResultType[] = null,
        public TotalResults: number = null
    ) {
    }

    public static readonly GqlFields = `
    available_types
    found
    total_results`;
}

export function SearchResultEntityFromBackend(res: SearchType): SearchResultEntity {
    return new SearchResultEntity(res.available_types?.sort() as any, res.found as any, res.total_results);
}
