import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { EmployeeToVacationActionTypes } from '../State/actions/employee-to-vacation.actions';
import { getEmployeeToVacationLatestUpdatedAt, getEmployeeToVacationsFetched } from '../State/selectors/employee-to-vacation.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class EmployeeToVacationResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getEmployeeToVacationsFetched, getEmployeeToVacationLatestUpdatedAt, EmployeeToVacationActionTypes.Load, store);
    }
}
