import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { firstValueFrom, of } from 'rxjs';
import { catchError, concatMap, debounceTime, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined, stringifyIfNotUndefined } from '../../../helper/helper';
import { EmailEntityFromBackend } from '../../entities/email.entity';

import {
    DaveMutationChangeEmailArgs,
    DaveMutationChangeSentEmailArgs,
    DaveMutationCreateEmailArgs,
} from '../../graphql-types';
import { EventResolver } from '../../guards/event.resolver';
import { EmailDataService } from '../../services/email-data.service';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { EmailActionTypes } from '../actions/email.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { EmailEntity } from './../../entities/email.entity';
import { EmailFolderActionTypes } from './../actions/email-folder.actions';

export enum EMailErrorCodes {
    Add = 'E-Mail Hinzufügen fehlgeschlagen',
    Load = 'E-Mail Abrufen fehlgeschlagen',
    Modify = 'E-Mail Bearbeiten fehlgeschlagen',
    Remove = 'E-Mail Löschen fehlgeschlagen',
    Sent = 'E-Mail Senden fehlgeschlagen',
}
const getCreateEmailMutation = (Payload: DaveMutationCreateEmailArgs | DaveMutationChangeSentEmailArgs, mutationName = 'createEmail'): string => {
    return `
            mutation {
                ${mutationName}(
                    ${stringifyIfNotNullOrUndefined(Payload, 'body')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'htmlBody')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'textBody')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'subject')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'to')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'from')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'emailConnectionId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'customerId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'size')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'personId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'folderId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'isFromDave')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'seen')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'carbonCopy')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'blindCarbonCopy')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'documentIds')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'generatedDocumentIds')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'processId')}
                ) {${EmailEntity.GQLFields}}
            }`;
};

const getChangeEmailMutation = (Payload: DaveMutationChangeEmailArgs | DaveMutationChangeSentEmailArgs, mutationName = 'changeEmail'): string => {
    return `
            mutation {
                ${mutationName}(
                    id: ${JSON.stringify(Payload.id)}
                    ${stringifyIfNotNullOrUndefined(Payload, 'body')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'htmlBody')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'textBody')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'subject')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'to')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'from')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'emailConnectionId')}
                    ${stringifyIfNotUndefined(Payload, 'commissionId')}
                    ${stringifyIfNotUndefined(Payload, 'customerId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'size')}
                    ${stringifyIfNotUndefined(Payload, 'personId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'folderId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'isFromDave')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'seen')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'carbonCopy')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'blindCarbonCopy')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'documentIds')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'generatedDocumentIds')}
                ) {${EmailEntity.GQLFields}}
            }`;
};

@Injectable()
export class EmailEffects {
    PollEmails$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(EmailActionTypes.CreateAndSendSuccess, EmailActionTypes.ModifyAndSendSuccess),
                debounceTime(2000),
                tap((action) => {
                    this.eventResolver.resolve();
                    firstValueFrom(this.emailDataService.GetEmailById$(action.Payload.Id, true));
                }),
            ),
        {
            dispatch: false,
        },
    );
    error$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmailActionTypes.CreateAndSendFailure, EmailActionTypes.ModifyAndSendFailure),
            map((action) =>
                BaseActionTypes.ErrorAction({
                    Payload: {
                        ToasterMessage: action.ToasterMessage,
                        Err: action.Errors,
                    },
                }),
            ),
        ),
    );

    ModifyEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmailActionTypes.ModifyEmail),
            withLatestFrom(this.store$),
            concatMap(([{ Payload }, store]) => {
                const queryString = getChangeEmailMutation(Payload);
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    concatMap((res) => {
                        return res && res.changeEmail && res.changeEmail.id
                            ? [
                                  EmailActionTypes.UpdateOneEmail({
                                      Payload: EmailEntityFromBackend(res.changeEmail),
                                  }),
                                  EmailFolderActionTypes.LoadEmailFolders(),
                              ]
                            : [
                                  BaseActionTypes.ErrorAction({
                                      Payload: {
                                          ToasterMessage: EMailErrorCodes.Modify,
                                      },
                                  }),
                              ];
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: EMailErrorCodes.Modify,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    ModifyAndSendEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmailActionTypes.ModifyAndSendRequest),
            withLatestFrom(this.store$),
            concatMap(([{ Payload }, store]) => {
                const queryString = getChangeEmailMutation(Payload, 'changeSentEmail');
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    concatMap((res) =>
                        res && res.changeSentEmail && res.changeSentEmail.id
                            ? [
                                  EmailActionTypes.ModifyAndSendSuccess({
                                      Payload: EmailEntityFromBackend(res.changeSentEmail),
                                  }),
                                  EmailFolderActionTypes.LoadEmailFolders(),
                              ]
                            : [EmailActionTypes.ModifyAndSendFailure({ ToasterMessage: EMailErrorCodes.Modify, Errors: 'changeSentEmail wrong response' })],
                    ),
                    catchError((err, caught) => of(EmailActionTypes.ModifyAndSendFailure({ ToasterMessage: EMailErrorCodes.Modify, Errors: err }))),
                );
            }),
        ),
    );

    addAndSend = createEffect(() =>
        this.actions$.pipe(
            ofType(EmailActionTypes.CreateAndSendRequest),
            withLatestFrom(this.store$.select(getToken)),
            switchMap(([{ Payload }, token]) => {
                return this.gatewayHttpService.graphQl({ query: getCreateEmailMutation(Payload, 'createSentEmail') }, { token }).pipe(
                    concatMap((res) => {
                        return res && res.createSentEmail && res.createSentEmail.id
                            ? [
                                  EmailActionTypes.CreateAndSendSuccess({
                                      Payload: EmailEntityFromBackend(res.createSentEmail),
                                  }),
                                  EmailFolderActionTypes.LoadEmailFolders(),
                              ]
                            : [EmailActionTypes.CreateAndSendFailure({ ToasterMessage: EMailErrorCodes.Add, Errors: 'createSentEmail wrong response' })];
                    }),
                    catchError((err, caught) => of(EmailActionTypes.CreateAndSendFailure({ ToasterMessage: EMailErrorCodes.Add, Errors: err }))),
                );
            }),
        ),
    );

    AddEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmailActionTypes.AddEmail),
            withLatestFrom(this.store$),
            switchMap(([{ Payload }, store]) => {
                return this.gatewayHttpService.graphQl({ query: getCreateEmailMutation(Payload) }, { token: store.base.token }).pipe(
                    concatMap((res) => {
                        return res && res.createEmail && res.createEmail.id
                            ? [
                                  EmailActionTypes.UpdateOneEmail({
                                      Payload: EmailEntityFromBackend(res.createEmail),
                                  }),
                                  EmailFolderActionTypes.LoadEmailFolders(),
                              ]
                            : [
                                  BaseActionTypes.ErrorAction({
                                      Payload: {
                                          ToasterMessage: EMailErrorCodes.Add,
                                      },
                                  }),
                              ];
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: EMailErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    DeleteEmails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmailActionTypes.DeleteEmails),
            withLatestFrom(this.store$),
            switchMap(([action, store]) => {
                const queryString = `
            mutation{
                deleteEmailMultiple(ids: ${JSON.stringify(action.Payload.ids)}){
                    ${EmailEntity.GQLFields}
                }
            }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    concatMap((res) =>
                        res && res.deleteEmailMultiple
                            ? [EmailFolderActionTypes.LoadEmailFolders(), EmailActionTypes.UpdateSomeEmails({ Payload: res.deleteEmailMultiple.map((mail) => EmailEntityFromBackend(mail)) })]
                            : [
                                  BaseActionTypes.ErrorAction({
                                      Payload: { ToasterMessage: EMailErrorCodes.Remove },
                                  }),
                              ],
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: EMailErrorCodes.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    ReadEmails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmailActionTypes.ReadEmails),
            withLatestFrom(this.store$),
            switchMap(([action, store]) => {
                const queryString = `
            mutation{
                changeEmailMultiple(
                    ids: ${JSON.stringify(action.Payload.ids)}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'seen')}
                    ){
                        ${EmailEntity.GQLFields}
                    }
            }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    concatMap((res) =>
                        res && res.changeEmailMultiple
                            ? [EmailFolderActionTypes.LoadEmailFolders(), EmailActionTypes.UpdateSomeEmails({ Payload: res.changeEmailMultiple.map((mail) => EmailEntityFromBackend(mail)) })]
                            : [
                                  BaseActionTypes.ErrorAction({
                                      Payload: { ToasterMessage: EMailErrorCodes.Modify },
                                  }),
                              ],
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: EMailErrorCodes.Modify,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService, private eventResolver: EventResolver, private emailDataService: EmailDataService) {}
}
