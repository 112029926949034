<h1 mat-dialog-title>
    Tagebucheintrag erstellen
</h1>
<mat-dialog-content>
    <mat-form-field class="full-width" *ngIf="Diaries; else loading">
        <mat-label>Datum {{(IsToday$ | async) ? '(heute)' : ''}}</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="DateForm" (click)="picker.open()" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker [dateClass]="DateClass" #picker></mat-datepicker>
    </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions>
    <button app-button Inverted Color="cancel" [mat-dialog-close]="null">Abbrechen</button>
    <button app-button [mat-dialog-close]="DateForm.value">Öffnen</button>
</div>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
