<app-dave-list-card
    [Headline]="DialogData.Headline"
    [EmptyText]="DialogData.EmptyText"
    [Data]="DialogData.Data ? DialogData.Data : (DialogData.Data$ | async)"
    [DisplayedCollums]="DialogData.DisplayedCollums"
    [Clickable]="DialogData.Clickable"
    [GetRouterLinkFunction]="DialogData.GetRouterLinkFunction"
    [TableHeaderCollumsMap]="DialogData.TableHeaderCollumsMap"
    [HasSuffixButton]="DialogData.HasSuffixButton"
    [CustomHeaderTemplate]="searchHeader"
    [CustomCellTemplates]="DialogData.CustomCellTemplates || {}"
    (EntryClicked$)="EntryClicked$.emit($event)"
>
    <div listCardFooter class="footer" mat-dialog-actions>
        <button *ngIf="!DialogData.footer; else DialogData.footer" mat-dialog-close="" Inverted app-button Color="cancel">Abbrechen</button>
    </div>
</app-dave-list-card>
<ng-template #searchHeader>
    <div class="header">
        <h3 class="headline">{{ DialogData.Headline }}</h3>
        <!-- search bar -->
        <ng-container *ngIf="DialogData.Search">
            <mat-form-field *ngIf="SearchVisible" >
                <mat-label>Suche</mat-label>
                <input [formControl]="SearchForm" matInput appPrefixFocusAndSelect #input/>
                <button (click)="SearchForm.setValue('')" mat-icon-button matSuffix *ngIf="SearchForm.value">
                    <fa-icon icon="times"></fa-icon>
                </button>
            </mat-form-field>
            <button app-round-button Inverted matTooltipPosition="left" [matTooltip]="SearchVisible ? 'Ausblenden' : 'Suche'" (click)="SearchVisible = !SearchVisible;">
                <fa-icon [icon]="SearchVisible ? 'times' : 'search'"></fa-icon>
            </button>
        </ng-container>
        <ng-container [ngTemplateOutlet]="DialogData.headerSuffixTemplate"></ng-container>

    </div>
</ng-template>
