import { createReducer, on } from '@ngrx/store';
import {ConditionEntity} from '../../entities/condition.entity';
import {ConditionActionTypes} from '../actions/condition.actions';


export const conditionsFeatureKey = 'conditions';

export interface ConditionsState {
    conditions: ConditionEntity[];
}

export const initialConditionsState: ConditionsState = {
    conditions: null,
};

export const conditionsStateReducer = createReducer(
    initialConditionsState,
    on(ConditionActionTypes.GetCondition, (state, { Payload }) => ({
        ...state,
        conditions: Payload.Conditions
    })),
);

