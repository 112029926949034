<div class="search-bar dark-theme">
    <mat-form-field *ngIf="visible" class="input-field" floatLabel="never" color="accent" appearance="fill">
        <input #searchQuery placeholder="{{ProductName$ | async}} - Suche" type="text" (keyup)="Search.next(searchQuery.value)" (focusout)="Defocus()" autocomplete="off" matInput />
        <div matSuffix class="suffix" *ngIf="searchQuery.value?.length && searchQuery.value.length < MIN_SEARCH_QUERY_LENGTH">
            <span class="suffix-text">Mindestens {{MIN_SEARCH_QUERY_LENGTH}} Zeichen eingeben</span>
            <fa-icon class="reset-btn" icon="times" (click)="Reset()"></fa-icon>
        </div>
        <div matPrefix class="prefix">
            <fa-icon [icon]="isSearching ? 'spinner' : 'search'" [spin]="isSearching"></fa-icon>
<!--            Einkommentieren wenn von der Suche immer alle die available types zurück kommen -->
<!--            <mat-chip (click)="ToggleExcludeType(type)" *ngFor="let type of (Results | async)?.AvailableTypes" style="margin-left: .5rem">-->
<!--                <fa-icon style="margin-right: .25rem" [icon]="SearchTypeEnumNameMap.get(type).icon"></fa-icon>-->
<!--                {{ SearchTypeEnumNameMap.get(type).name }}-->
<!--                <mat-checkbox style="margin-left: .25rem" (click)="$event.stopPropagation()" [checked]="!(ExcludeTypes$ | async)?.includes(type)" (change)="ToggleExcludeType(type)"></mat-checkbox>-->
<!--            </mat-chip>-->
            |
        </div>
    </mat-form-field>
</div>
