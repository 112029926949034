import { createAction, props } from '@ngrx/store';
import {
    DaveMutationChangeResourceScheduleArgs,
    DaveMutationCreateResourceScheduleArgs,
    QueryResourceScheduleArgs
} from "../../../graphql-types";
import { ResourceScheduleEntity } from '../../../entities/resource-dispo/resource-schedule.entity';


const prefix = 'data/' + 'resourceSchedule/';
export const ResourceScheduleActionTypes = {
    Load: createAction(prefix + 'loadAll', props<{ Payload?: QueryResourceScheduleArgs }>()),
    Create: createAction(prefix + 'create', props<{ Payload: DaveMutationCreateResourceScheduleArgs }>()),
    Change: createAction(prefix + 'change', props<{ Payload: DaveMutationChangeResourceScheduleArgs }>()),
    Delete: createAction(prefix + 'delete', props<{ Payload: number }>()),

    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<ResourceScheduleEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<ResourceScheduleEntity>; updateLatestUpdatedAt?: boolean }>()),
    RemoveOne: createAction(prefix + 'remove', props<{ Payload: number }>()),
};
