import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { BehaviorSubject, of, Subscription, switchMap, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { AppConfirmationDialogComponent, AppConfirmationDialogData } from '../dave-utils-module/app-dialog-module/app-confirmation-dialog/app-confirmation-dialog.component';

/**
 * @property dialogMessage z.B.: "Ihr Kommentar wird Gelöscht"
 */
interface loadingConfig {

    dialogMessage?: string;
}
@Injectable({
    providedIn: 'root',
})
export class LoadingService implements OnDestroy {
    private loadingStarted: Date;
    private dialogRef: MatDialogRef<AppConfirmationDialogComponent>;
    private dialogSub: Subscription;
    private loadingMap = new Map<string, loadingConfig>();
    private loadingLegacy = false;
    private isLoading$ = new BehaviorSubject(this.loadingLegacy);
    public IsLoading$ = this.isLoading$.pipe(startWith(this.loadingLegacy), distinctUntilChanged(), shareReplay({ refCount: true, bufferSize: 1 }));

    get isLoading(): boolean {
        return this.loadingLegacy || !!this.loadingMap.size;
    }

    /**
     * @deprecated use {@link startLoading} and {@link endLoading} instead
     * @param val
     */
    set isLoading(val: boolean) {
        this.loadingLegacy = val;
        this.isLoading$.next(this.isLoading);
    }
    public startLoading(uniqId: string, config?: loadingConfig) {
        if (this.loadingMap.has(uniqId)) {
            console.error('Is already loading: ' + uniqId);
        } else {
            this.loadingMap.set(uniqId, config || null);
            this.isLoading$.next(true);
        }
        const l = []
        this.loadingMap.forEach((m, k) => l.push(k))
        console.log(l)

    }
    public endLoading(uniqId: string) {
        if (this.loadingMap.has(uniqId)) {
            this.loadingMap.delete(uniqId);
        }
        this.isLoading$.next(this.isLoading);

        const l = []
        this.loadingMap.forEach((m, k) => l.push(k))
        console.log(l)
    }

    constructor(private dialog: MatDialog) {
        const dialogOpen$ = this.isLoading$.pipe(
            pairwise(),
            filter(([isLoadingBefore, isLoadingCurr]) => isLoadingCurr !== isLoadingBefore),
            switchMap(([, isLoading]) => (isLoading ? timer(5 * 1000).pipe(map(() => true)) : of(false))),
        );
        this.dialogSub = dialogOpen$.subscribe((openDialog) => {
            if (!openDialog) {
                this.dialogRef?.close();
            } else {
                this.isLoading$
                    .pipe(
                        filter((v) => !!v),
                        takeUntil(dialogOpen$.pipe(filter((v) => !v))),
                    )
                    .subscribe(() => {
                        const paragraphMessages = [];
                        this.loadingMap.forEach((value) => {
                            if (value?.dialogMessage) {
                                paragraphMessages.push(value.dialogMessage);
                            }
                        });
                        if (this.dialogRef?.getState() === MatDialogState.OPEN) {
                            this.dialogRef.componentInstance.Data = {
                                ...this.dialogRef.componentInstance.Data,
                                paragraph: paragraphMessages.length ? paragraphMessages.join('\n') : 'Es wird etwas im Hintergrund bearbeitet.',
                            };
                        } else {
                            this.dialogRef = this.dialog.open<AppConfirmationDialogComponent, AppConfirmationDialogData>(AppConfirmationDialogComponent, {
                                hasBackdrop: false,
                                data: {
                                    hideButtons: true,
                                    heading: 'Bitte haben Sie einen moment Geduld...',
                                    paragraph: paragraphMessages.length ? paragraphMessages.join('\n') : 'Es wird etwas im Hintergrund bearbeitet.',
                                },
                            });
                        }
                    });
            }
        });
    }

    ngOnDestroy(): void {
        this.dialogSub.unsubscribe();
    }
}
