import { ArlTemplateTypeType } from '../graphql-types';

export class ARLTemplateTypeEntity {
    constructor(public Id: number = null, public PartnerId: number = null, public Name: string = null) {}
    public static readonly GqlFields = `id partnerId name`;
    public Clone(override: ArlTemplateTypeAttributes = {}): ARLTemplateTypeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ARLTemplateTypeEntity(), attr);
    }
}

export function ARLTemplateTypeEntityFromBackend(
    res: ArlTemplateTypeType,
): ARLTemplateTypeEntity {
    return new ARLTemplateTypeEntity(res.id, res.partnerId, res.name);
}

export interface ArlTemplateTypeAttributes {
    Id?: number;
    PartnerId?: number;
    Name?: string;
}
