import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { OfficeEntity, OfficeEntityFromBackend } from '../../entities/office.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { OfficeActionTypes } from '../actions/office.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { getOffices } from '../selectors/offices.selectors';
import { getUser } from '../selectors/users.selectors';

enum ErrorCodes {
    Add = 'Standort Hinzufügen fehlgeschlagen',
    Load = 'Standorte Abrufen fehlgeschlagen',
    Modify = 'Standort Bearbeiten fehlgeschlagen',
}

@Injectable()
export class OfficeEffects {

    AddOffice$ = createEffect(() => this.actions$.pipe(
        ofType(OfficeActionTypes.Add),
        map(
            ({ Payload }) => `
            mutation {
                createOffice(
                    ${stringifyIfNotNullOrUndefined(Payload, 'phoneNumber')}
                    email: ${JSON.stringify(Payload.email)}
                    ${stringifyIfNotNullOrUndefined(Payload, 'street')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'postalCode')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'city')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'country')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'longitude')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'latitude')}
                    isHeadquarter: ${JSON.stringify(Payload.isHeadquarter)}
                    customerId: ${JSON.stringify(Payload.customerId)}
                    ${stringifyIfNotNullOrUndefined(Payload, 'faxNumber')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'mobileNumber')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'iBAN')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'swift')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'bankName')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'bankLocation')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'bankPostalCode')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'bankSortCode')}
                ) {${OfficeEntity.GQLFields.join(',')}
                }
            }`,
        ),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([query, token]) => this.gatewayHttpService.graphQl({ query }, { token })),
        withLatestFrom(this.store$.select(getOffices)),
        concatMap(([res, offices]) =>
            res
                ? [
                      OfficeActionTypes.UpdateAll({
                          Payload: [...offices, OfficeEntityFromBackend(res.createOffice)],
                      }),
                  ]
                : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } })],
        ),
        catchError(
            (err, caught) => (
                this.store$.dispatch(
                    BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Add,
                            Err: err,
                            Caught: caught,
                        },
                    }),
                ),
                caught
            ),
        ),
    ));


    LoadOffices$ = createEffect(() => this.actions$.pipe(
        ofType(OfficeActionTypes.Load),
        withLatestFrom(this.store$.select(getUser), this.store$.select(getToken)),
        switchMap(([{ Payload }, user, token]) => {
            const queryString = `
            query {
                office(partnerId: ${user == null ? 0 : user.PartnerId}${Payload?.updatedSince ? `, updatedSince: "${Payload.updatedSince}"` : ''}) {
                    ${OfficeEntity.GQLFields.join(',')}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                tap(res => {
                    if (res && res.office) {
                        if (Payload?.updatedSince) {
                            if (res.office.length) {
                                this.store$.dispatch(
                                    OfficeActionTypes.UpdateMany({
                                        Payload: res.office.map(val => OfficeEntityFromBackend(val)),
                                        updateLatestUpdatedAt: true,
                                    }),
                                );
                            }
                        } else {
                            this.store$.dispatch(
                                OfficeActionTypes.UpdateAll({
                                    Payload: res.office.map(val => OfficeEntityFromBackend(val)),
                                    updateLatestUpdatedAt: true,
                                }),
                            );
                        }
                    } else {
                        this.store$.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }));
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            );
        }),
    ), { dispatch: false });


    ModifyOffice$ = createEffect(() => this.actions$.pipe(
        ofType(OfficeActionTypes.Modify),
        map(
            ({ Payload }) => `
            mutation {
                changeOffice(
                    id: ${Payload.id}
                    ${stringifyIfNotNullOrUndefined(Payload, 'phoneNumber')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'email')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'invoiceEmail')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'street')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'postalCode')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'city')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'country')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'longitude')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'latitude')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'isHeadquarter')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'customerId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'faxNumber')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'mobileNumber')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'iBAN')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'swift')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'bankName')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'bankLocation')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'bankPostalCode')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'bankSortCode')}
                ) {
                    ${OfficeEntity.GQLFields.join(',')}
                }
            }`,
        ),
        withLatestFrom(this.store$.select(getToken)),
        switchMap(([query, token]) => this.gatewayHttpService.graphQl({ query }, { token })),
        withLatestFrom(this.store$.select(getOffices)),
        map(([res, offices]) => {
            if (!res) {
                throw Error('empty response');
            }
            return OfficeActionTypes.UpdateAll({
                Payload: offices.map(office => (office.Id === res.changeOffice.id ? OfficeEntityFromBackend(res.changeOffice) : office)),
            });
        }),
        catchError(
            (err, caught) => (
                this.store$.dispatch(
                    BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Modify,
                            Err: err,
                            Caught: caught,
                        },
                    }),
                ),
                caught
            ),
        ),
    ));

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
