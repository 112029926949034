import { ResourceType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
import { CustomFields } from '../../custom-form-fields/custom-form-fields.helper';
import { GatewayResponse, GetTimeFromTimestamp, Material } from '@dave/types';
import { ResourceResponse } from '@dave/types/dist/proto/erp/resource';


export enum AmountTypeEnum {
    Centimeter = 'CENTIMETER',
    Gramm = 'GRAMM',
    Kilogramm = 'KILOGRAMM',
    Kilometer = 'KILOMETER',

    Kubikcentimeter = 'KUBIKCENTIMETER',
    Kubikkilometer = 'KUBIKKILOMETER',
    Kubikmeter = 'KUBIKMETER',
    Liter = 'LITER',

    Meter = 'METER',
    Quadratcentimeter = 'QUADRATCENTIMETER',
    Quadratkilometer = 'QUADRATKILOMETER',
    Quadratmeter = 'QUADRATMETER',

    Std = 'STD',
    Stk = 'STK',
    Tone = 'TONE',
    Laufendermeter = 'LAUFENDERMETER',
}

export const AmountTypeEnumNames = new Map<AmountTypeEnum, string>([
    [AmountTypeEnum.Gramm, 'g'],
    [AmountTypeEnum.Kilogramm, 'kg'],
    [AmountTypeEnum.Kilometer, 'km'],
    [AmountTypeEnum.Liter, 'l'],

    [AmountTypeEnum.Std, 'Std.'],
    [AmountTypeEnum.Stk, 'Stk.'],
    [AmountTypeEnum.Tone, 't'],
    [AmountTypeEnum.Meter, 'm'],

    [AmountTypeEnum.Centimeter, 'cm'],
    [AmountTypeEnum.Kubikcentimeter, 'cm³'],
    [AmountTypeEnum.Kubikkilometer, 'km³'],
    [AmountTypeEnum.Kubikmeter, 'm³'],
    [AmountTypeEnum.Quadratcentimeter, 'cm²'],
    [AmountTypeEnum.Quadratkilometer, 'km²'],
    [AmountTypeEnum.Quadratmeter, 'm²'],
    [AmountTypeEnum.Laufendermeter, 'lfm'],
]);
export const AmountTypeEnumNamesLong = new Map<AmountTypeEnum, string>([
    [AmountTypeEnum.Gramm, 'Gramm'],
    [AmountTypeEnum.Kilogramm, 'Kilogramm'],
    [AmountTypeEnum.Kilometer, 'Kilometer'],
    [AmountTypeEnum.Liter, 'Liter'],

    [AmountTypeEnum.Std, 'Stunde'],
    [AmountTypeEnum.Stk, 'Stück'],
    [AmountTypeEnum.Tone, 'Tonne'],
    [AmountTypeEnum.Meter, 'Meter'],

    [AmountTypeEnum.Centimeter, 'Zentimeter'],
    [AmountTypeEnum.Kubikcentimeter, 'Kubikzentimeter'],
    [AmountTypeEnum.Kubikkilometer, 'Kubikkilometer'],
    [AmountTypeEnum.Kubikmeter, 'Kubikmeter'],
    [AmountTypeEnum.Quadratcentimeter, 'Quadratzentimeter'],
    [AmountTypeEnum.Quadratkilometer, 'Quadratkilometer'],
    [AmountTypeEnum.Quadratmeter, 'Quadratmeter'],
    [AmountTypeEnum.Laufendermeter, 'LaufenderMeter'],
]);
export class MaterialEntity {
    constructor(
        public Id: number = null,
        public ProductGroupId: number = null,
        public Amount: number = null,
        public CustomFields: CustomFields[] = null,
        public PublicFileId: number = null,
        public Kostenstelle: string = null,
        public ArticleNumber: string = null,
        public Name: string = null,
        public Cost: number = null,
        public ListPrice : number = null,
        public SellPrice : number = null,
        public QuantityTypeId: number = null,
        public SellQuantityTypeId: number = null,
        public PartnerId: number = null,
        public UserId: number = null,
        public InventoryNumber: string = null,
        public GlobalTradeItemNr: string = null,
        public StorageLocation: string = null,
        // public Description: string = null,
        public UseSurchargeRate: boolean = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
    ) {}
    public static EntityName = 'Material';


    public Clone(override: Partial<MaterialEntity> = {}): MaterialEntity {
        const attr = { ...this, ...override };
        return Object.assign(new MaterialEntity(), attr);
    }
    public get DisplayName() {
        return [this.ArticleNumber, this.Name].filter(x => x).join(' ');
    }
}

export function MaterialEntityFromBackend(res: Material): MaterialEntity {
//     const amountTypeEnumValue = res.Amount ? AmountTypeEnum[res.AmountType as keyof typeof AmountTypeEnum] : null;

    return new MaterialEntity(
        res.Id && +res.Id,
        res.ProductGroupId && +res.ProductGroupId,
        res.Amount && +res.Amount,
        res.CustomFields && JSON.parse(res.CustomFields),
        res.PublicFileId && +res.PublicFileId,
        res.Kostenstelle,
        res.ArticleNumber,
        res.Name,
        res.Cost && +res.Cost,
        res.ListPrice && +res.ListPrice,
        res.SellPrice && +res.SellPrice,
        res.QuantityTypeId && +res.QuantityTypeId,
        res.SellQuantityTypeId && +res.SellQuantityTypeId,
        res.PartnerId && +res.PartnerId,
        res.UserId && +res.UserId,
        res.InventoryNumber,
        res.GlobalTradeItemNr,
        res.StorageLocation,
        // res.Description,
        res.UseSurchargeRate,
        res.CreatedAt && GetTimeFromTimestamp(+res.CreatedAt),
        res.UpdatedAt && GetTimeFromTimestamp(+res.UpdatedAt),
        res.DeletedAt && GetTimeFromTimestamp(+res.DeletedAt),
    );
}



