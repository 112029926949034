<div class="chart-row">
    <div class="chart-container">
        <app-doughnut-chart-wrapper
            #taskChart
            *ngIf="TaskDataSets$ | async as datasets; else noTasks"
            Headline="Aufgaben"
            [HideLegend]="Mobile$ | async"
            [Options]="TaskChartOptions"
            [CenterTemplate]="centerTaskTemplate"
            [Datasets]="datasets"
            [Labels]="TaskLabels"
        ></app-doughnut-chart-wrapper>

        <ng-template #centerTaskTemplate>
            <div style="transition: transform 0.2s; font-size: 2rem">
                <fa-icon style="cursor: pointer" [icon]="TaskIcon" size="lg" (click)="OnTaskIconClick($event)"></fa-icon>
            </div>
        </ng-template>

        <ng-template #noTasks>
            <ng-container *ngTemplateOutlet="noContent; context: { text: 'Keine Aufgaben vorhanden' }"> </ng-container>
        </ng-template>
    </div>
    <div class="chart-container business-volume" *ngIf="ps.Has$(ps.Permissions.GetBusinessVolume) | async">
        <div class="header">
            <h3>
                Umsätze (Netto)
            </h3>
            <button mat-icon-button (click)="bvFilter?.Toggle()" [matBadge]="bvFilterCount$ | async" matBadgeOverlap class="mat-badge-more-overlay" matBadgeSize="small"><fa-icon icon="filter"></fa-icon></button>
        </div>
        <app-filter-card UnlimitedHeight #bvFilter *ngIf="bvFilterSettings$ | async as settings" [Settings]="settings" [Values]="bvFilterValues$ | async" (ValuesChange)="bvFilterValues$.next($event)"></app-filter-card>
        <app-doughnut-chart-wrapper
            #bvChart
            *ngIf="BusinessVolumeDataSets$ | async as datasets; else noBV"
            [HideLegend]="Mobile$ | async"
            [Subline]="(Commission$ | async)?.CompleteBusinessVolume ? 'geplant: ' + ((Commission$ | async)?.CompleteBusinessVolume / 100 | number: '1.2-2') + ' €' : ''"
            [Options]="BusinessVolumeChartOptions"
            [CenterTemplate]="centerBVTemplate"
            [Datasets]="datasets"
            [Labels]="BusinessVolumeLabels"
        ></app-doughnut-chart-wrapper>
        <ng-template #centerBVTemplate>
            <div style="text-align: center; display: flex; flex-direction: column">
                <button mat-icon-button (click)="$event.stopPropagation(); openBVPopup(null, BusinessVolumeCostStateEnum.Income)" style="width: 30px;height: 30px;margin: -10px auto 0;"><fa-icon icon="list"></fa-icon></button>
                <span>Umsatz bisher:</span>
                <span style="font-size: larger; font-weight: bold">{{ BusinessVolumeSum$ | async | number: '1.2-2' }} €</span>
            </div>
        </ng-template>
        <ng-template #noBV>
            <ng-container *ngTemplateOutlet="noContent; context: { text: 'Keine Umsätze vorhanden' }"> </ng-container>
        </ng-template>
    </div>
    <div class="chart-container business-volume" *ngIf="ps.Has$(ps.Permissions.GetBusinessVolume) | async">
        <div class="header">
            <h3>
                Kosten (Netto)
            </h3>
            <button mat-icon-button (click)="costFilter?.Toggle()" [matBadge]="bvCostFilterCount$ | async" matBadgeOverlap class="mat-badge-more-overlay" matBadgeSize="small"><fa-icon icon="filter"></fa-icon></button>
        </div>
        <app-filter-card UnlimitedHeight #costFilter *ngIf="bvCostFilterSettings$ | async as settings" [Settings]="settings" [Values]="bvCostFilterValues$ | async" (ValuesChange)="bvCostFilterValues$.next($event)"></app-filter-card>
        <app-doughnut-chart-wrapper
            #bvCostChart
            *ngIf="BusinessVolumeCostDataSets$ | async as datasets; else noBVC"
            [HideLegend]="Mobile$ | async"
            [Subline]="(Commission$ | async)?.CostsCompleteBusinessVolume ? 'geplant: ' + ((Commission$ | async)?.CostsCompleteBusinessVolume / 100 | number: '1.2-2') + ' €' : ''"
            [Options]="BusinessVolumeCostChartOptions"
            [CenterTemplate]="centerBVCostTemplate"
            [Datasets]="datasets"
            [Labels]="BusinessVolumeCostsLabels"
        ></app-doughnut-chart-wrapper>
        <ng-template #centerBVCostTemplate>
            <div style="text-align: center; display: flex; flex-direction: column">
                <button mat-icon-button (click)="$event.stopPropagation(); openBVPopup(null, BusinessVolumeCostStateEnum.Costs)" style="width: 30px;height: 30px;margin: -10px auto 0;"><fa-icon icon="list"></fa-icon></button>
                <span>Bezahlt bisher:</span>
                <span style="font-size: larger; font-weight: bold">{{ BusinessVolumeCostSum$ | async | number: '1.2-2' }} €</span>
            </div>
        </ng-template>
        <ng-template #noBVC>
            <ng-container *ngTemplateOutlet="noContent; context: { text: 'Keine Kosten vorhanden' }"> </ng-container>
        </ng-template>
    </div>
    <div class="chart-container">
        <app-doughnut-chart-wrapper
            #timeChart
            *ngIf="TimeDataSets$ | async as datasets; else noTime"
            [Headline]="'Zeiten'"
            [HideLegend]="Mobile$ | async"
            [Subline]="(TargetTime$ | async) ? 'geplant: ' + ((TargetTime$ | async) / 1000 / 60 / 60 | number: '1.2-2') + ' Std.' : ''"
            [Options]="TimeChartOptions$ | async"
            [CenterTemplate]="centerTimeTemplate"
            [Datasets]="datasets"
            [Labels]="TimeLabels$ | async"
        ></app-doughnut-chart-wrapper>
        <ng-template #centerTimeTemplate>
            <div style="text-align: center; display: flex; flex-direction: column">
                <span>Stunden bisher:</span>
                <span style="font-size: larger; font-weight: bold">{{ (TimeSum$ | async) / 1000 / 60 / 60 | number: '1.2-2' }} Std.</span>
            </div>
        </ng-template>
        <ng-template #noTime>
            <ng-container *ngTemplateOutlet="noContent; context: { text: 'Keine Zeiten vorhanden' }"> </ng-container>
        </ng-template>
    </div>
    <div class="chart-container larger">
        <h3>Letzte {{ DayCount }} Tage KW {{ FromWeek }} - {{ ToWeek }}</h3>
        <ng-container *ngIf="BtgbTable$ | async as table; else loading">
            <ng-container *ngTemplateOutlet="btgbTable; context: { table }"></ng-container>
        </ng-container>
    </div>
</div>
<ng-container *ngTemplateOutlet="timelineTemplate"></ng-container>
<ng-template #noContent let-text="text">
    <p class="no-content">{{ text }}</p>
</ng-template>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<ng-template #btgbTable let-table="table">
    <div class="btgb-table-wrapper">
        <table>
            <tr *ngFor="let row of table.rows; let first = first; let i = index">
                <ng-container *ngIf="first">
                    <th class="theme-override" [class]="table.rows[i].theme">
                        <button mat-mini-fab aria-label="{{ table.rows[i].label }}" (click)="table.rows[i].onClick()" color="primary">
                            <fa-icon [icon]="table.rows[i].icon"></fa-icon>
                        </button>
                    </th>
                    <th *ngFor="let col of table.columns">
                        {{ col[i].text || '' }}
                        <span class="sub-line" *ngIf="col[i].subLine as subLine"><br />{{ subLine }}</span>
                    </th>
                </ng-container>
                <ng-container *ngIf="!first">
                    <td class="theme-override" [class]="table.rows[i].theme">
                        <button mat-mini-fab aria-label="{{ table.rows[i].label }}" (click)="table.rows[i].onClick()" color="primary">
                            <fa-icon [icon]="table.rows[i].icon"></fa-icon>
                        </button>
                    </td>
                    <td *ngFor="let col of table.columns">
                        <span (click)="col[i].onClick ? col[i].onClick() : {}" [style.cursor]="col[i].onClick ? 'pointer' : 'text'"
                            >{{ col[i].text || '' }}<span *ngIf="col[i].suffixText" class="suffixText"><br />{{ col[i].suffixText }}</span></span
                        >
                        <div
                            *ngIf="col[i].colorDot as color"
                            class="color-dot"
                            [matTooltip]="col[i].tooltip"
                            (click)="col[i].onClick ? col[i].onClick() : {}"
                            [style.cursor]="col[i].onClick ? 'pointer' : 'default'"
                            [style.background-color]="color"
                        ></div>
                        <span class="sub-line" *ngIf="col[i].subLine as subLine">{{ subLine }}</span>
                    </td>
                </ng-container>
            </tr>
        </table>
    </div>
</ng-template>

<ng-template #timelineTemplate>
    <!--        <div class="pointer-wrapper">-->
    <!--            <span class="pointer" *ngFor="let conf of timelineConfig.pointer" [style.left]="conf.state">{{conf.label}}</span>-->
    <!--        </div>-->
    <!-- Meilensteine / Milestone-->
    <hr />
    <h3>Projektverlauf</h3>
    <button class="milestone-button" app-round-button matTooltip="Meilenstein erstellen" matTooltipPosition="left" (click)="MilestoneClicked()">
        <app-double-icon Icon="flag" Icon2="plus"></app-double-icon>
    </button>

        <app-progress-timeline class="timeline-wrapper" (EventClick)="MilestoneClicked($event)" [From]="timelineConfig.start" [To]="timelineConfig.end" [State]="timelineConfig.fillDate" [Events]="timelineConfig.pointer$ | async" *ngIf="TimelineFillWidth$ | async as timelineConfig" ></app-progress-timeline>

</ng-template>
<ng-template #listCardDialogFooter>
    <button (click)="BVListDialogRef.close()" app-button>Schließen</button>
</ng-template>
