import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopupComponent } from './error-popup.component';

@Injectable({
    providedIn: 'root',
})
export class ErrorPopupService {
    private portalHost: DomPortalHost;
    private portal: ComponentPortal<ErrorPopupComponent>;
    constructor(private dialog: MatDialog, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private appRef: ApplicationRef) {}

    public CloseErrorPopup() {
        if (this.portalHost?.hasAttached()) {
            this.portalHost.detach();
        }
    }
    public OpenErrorPopup() {
        if (this.portalHost?.hasAttached()) {
            return;
        }
        this.doRender();
    }
    private doRender() {
        // Create a portalHost from a DOM element
        this.portalHost = new DomPortalHost(document.querySelector('#routerWrapper'), this.componentFactoryResolver, this.appRef, this.injector);
        // Locate the component factory for the HeaderComponent
        this.portal = new ComponentPortal(ErrorPopupComponent);
        // Attach portal to host
        this.portalHost.attach(this.portal);
    }
}
