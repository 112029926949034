import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined, stringifyIfNotUndefined } from '../../../helper/helper';
import { JobSpecificationEntity, JobSpecificationEntityFromBackend } from '../../entities/job-specification.entity';
import { HttpService } from '../../services/http.service';
import { BaseActionTypes } from '../actions/base.actions';
import { JobSpecificationActionTypes } from '../actions/job-specification.action';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';

enum ErrorCodes {
    Load = 'Berufsbezeichnungen Abrufen fehlgeschlagen',
    Modify = 'Berufsbezeichnung Bearbeiten fehlgeschlagen',
    Remove = 'Berufsbezeichnung Löschen fehlgeschlagen',
    Add = 'Berufsbezeichnung Hinzufügen fehlgeschlagen',
}

@Injectable()
export class JobSpecificationEffects {
    CreateJobSpecification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobSpecificationActionTypes.Create),
            withLatestFrom(this.store$),
            concatMap(([{ Payload }, store]) => {
                const queryString = `
                mutation {
                    createJobSpecification(
                        ${stringifyIfNotNullOrUndefined(Payload, 'usedInCapacityCalculation')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'name')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                        ${stringifyIfNotUndefined(Payload, 'amount')}
                        ${stringifyIfNotUndefined(Payload, 'quantityTypeId')}
                ) {
                    ${JobSpecificationEntity.GqlFields.join(',')}
                }
            }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    map((res) => {
                        return res && res.createJobSpecification
                            ? JobSpecificationActionTypes.UpdateOne({
                                  Payload: JobSpecificationEntityFromBackend(res.createJobSpecification),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    ChangeJobSpecification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobSpecificationActionTypes.Modify),
            withLatestFrom(this.store$.select(getToken)),
            concatMap(([{ Payload }, token]) => {
                const queryString = `
                mutation {
                    changeJobSpecification(
                    id: ${Payload.id}
                        ${stringifyIfNotNullOrUndefined(Payload, 'usedInCapacityCalculation')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'name')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                        ${stringifyIfNotUndefined(Payload, 'amount')}
                        ${stringifyIfNotUndefined(Payload, 'quantityTypeId')}
                ) {
                    ${JobSpecificationEntity.GqlFields.join(',')}
                }
            }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
                    map((res) => {
                        return res && res.changeJobSpecification
                            ? JobSpecificationActionTypes.UpdateOne({
                                  Payload: JobSpecificationEntityFromBackend(res.changeJobSpecification),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Modify,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    GetJobSpecification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobSpecificationActionTypes.LoadAll),
            withLatestFrom(this.store$.select(getToken)),
            concatMap(([action, token]) => {
                const queryString = `
            query {
                jobSpecification {
                    ${JobSpecificationEntity.GqlFields}
                }
            }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                    map((res) => {
                        return res && res.jobSpecification
                            ? JobSpecificationActionTypes.UpdateAll({
                                  Payload: res.jobSpecification.map((e) => JobSpecificationEntityFromBackend(e)),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
