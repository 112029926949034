<!-- Image editor controls - top area -->
<div class="header">
    <!--    <div>-->
    <!--        <img class="logo" src="img/TOAST UI Component.png" /> <span class="name">Image Editor</span>-->
    <!--    </div>-->
    <div class="menu">
        <!--        <span class="button">-->
        <!--          <img src="img/openImage.png" style="margin-top: 5px" />-->
        <!--          <input type="file" accept="image/*" id="input-image-file" />-->
        <!--        </span>-->
        <button app-round-button class="button disabled" id="btn-undo" [disabled]="true"><fa-icon icon="undo"></fa-icon></button>
        <button app-round-button class="button disabled" id="btn-redo" [disabled]="true"><fa-icon icon="redo"></fa-icon></button>
        <button app-round-button class="button" id="btn-remove-active-object" Color="red"><fa-icon icon="trash"></fa-icon></button>
        <button app-round-button class="button" [IsLoading]="ls.IsLoading$ | async" (click)="Save()"><fa-icon icon="save"></fa-icon></button>
        <!--        <button class="button" id="btn-download"><img src="img/download.png" /></button>-->
    </div>
</div>
<!-- Image editor area -->
<div class="tui-image-editor"></div>
<!-- Image editor controls - bottom area -->
<div class="tui-image-editor-controls custom">
    <ul class="scrollable">
<!--        <li class="menu-item">-->
<!--            <button class="sub-menu-button" app-round-button id="btn-drag">-->
<!--                <fa-icon icon="hand"></fa-icon>-->
<!--            </button>-->
<!--        </li>-->
        <li class="menu-item">
            <button class="menu-button" app-round-button id="btn-crop">
                <fa-icon icon="crop-simple"></fa-icon>
            </button>
            <div class="submenu">
                <button class="btn-prev" app-round-button>
                    <fa-icon icon="arrow-left"></fa-icon>
                </button>

                <button app-round-button class="submenu-button" id="btn-apply-crop">
                    <fa-icon icon="check"></fa-icon>
                </button>
            </div>
        </li>
        <li class="menu-item">
            <button class="menu-button" app-round-button>
                <fa-icon icon="rotate"></fa-icon>
            </button>
            <div class="submenu">
                <button app-round-button class="btn-prev">
                    <fa-icon icon="arrow-left"></fa-icon>
                </button>

                <button app-round-button class="submenu-button" id="btn-rotate-clockwise">
                    <fa-icon icon="rotate-right"></fa-icon>
                </button>

                <button app-round-button class="submenu-button" id="btn-rotate-counter-clockwise">
                    <fa-icon icon="rotate-left"></fa-icon>
                </button>

                <button app-round-button class="submenu-button" id="btn-flip-x">
                    <fa-icon icon="arrows-left-right-to-line"></fa-icon>
                </button>

                <button app-round-button class="submenu-button" id="btn-flip-y">
                    <fa-icon icon="arrows-left-right-to-line" rotate="90" ></fa-icon>
                    <!-- rotate klappt nicht -->
                </button>
            </div>
        </li>
        <li class="menu-item">
            <button app-round-button class="menu-button show-hidden-menu-button" id="btn-draw-line">
                <fa-icon icon="pen"></fa-icon>
            </button>
            <div class="submenu">
                <button app-round-button class="btn-prev"><fa-icon icon="arrow-left"></fa-icon></button>

                <button app-round-button class="submenu-button" id="btn-free-drawing">
                    <fa-icon icon="paint-brush"></fa-icon>
                </button>

                <button app-round-button class="submenu-button" id="btn-line-drawing">
                    <fa-icon icon="ruler"></fa-icon>
                </button>
                <div class="hiddenmenu">
                    <div id="tui-brush-color-picker"></div>
                    <input id="input-brush-range" class="input-brush-range" type="range" min="10" max="100" value="20" />
                </div>
            </div>
        </li>
        <li class="menu-item">
            <button app-round-button class="menu-button show-hidden-menu-button" id="btn-draw-shape">
                <fa-icon icon="shapes"></fa-icon>
            </button>
            <div class="submenu">
                <button app-round-button class="btn-prev">
                    <fa-icon icon="arrow-left"></fa-icon>
                </button>

                <button app-round-button class="submenu-button" id="btn-add-rect">
                    <fa-icon icon="square"></fa-icon>
                </button>

                <button app-round-button class="submenu-button" id="btn-add-circle">
                    <fa-icon icon="circle"></fa-icon>
                </button>

                <button app-round-button class="submenu-button" id="btn-add-triangle">
                    <fa-icon icon="play"></fa-icon>
                </button>

                <div class="hiddenmenu">
                    <div id="tui-shape-color-picker"></div>
                    <input id="input-stroke-range" type="range" min="1" max="100" value="10" />
                    <div class="top">
                        <label for="fill-color"><input type="radio" id="fill-color" name="select-color-type" value="fill" checked="checked" /> Füllen</label>
                        <label for="stroke-color"><input type="radio" id="stroke-color" name="select-color-type" value="stroke" /> Stroke</label>
                        <label for="input-check-transparent"><input type="checkbox" id="input-check-transparent" />Transparent</label>
                    </div>
                </div>
            </div>
        </li>
        <li class="menu-item">
            <button app-round-button class="menu-button show-hidden-menu-button">
                <fa-icon icon="star"></fa-icon>
            </button>
            <div class="submenu">
                <button app-round-button class="btn-prev">
                    <fa-icon icon="arrow-left"></fa-icon>
                </button>

                <button app-round-button class="submenu-button" id="btn-add-arrow-icon">
                    <fa-icon icon="long-arrow-alt-up"></fa-icon>
                </button>

                <button app-round-button class="submenu-button" id="btn-add-cancel-icon">
                    <fa-icon icon="xmark"></fa-icon>
                </button>

                <div class="hiddenmenu">
                    <div id="tui-icon-color-picker"></div>
                </div>
            </div>
        </li>
        <li class="menu-item">
            <button app-round-button class="menu-button" id="btn-add-text">
                <fa-icon icon="font"></fa-icon>
            </button>
            <div class="submenu">
                <button app-round-button class="btn-prev">
                    <fa-icon icon="arrow-left"></fa-icon>
                </button>

                    <button app-round-button class="submenu-button" id="btn-change-size">
                        <fa-icon icon="text-height"></fa-icon>
                    </button>
                    <div class="text-height">
                        <input id="input-text-size-range" type="range" min="10" max="240" value="120" />
                    </div>

                    <button app-round-button class="submenu-button" id="btn-change-style">
                        <fa-icon icon="italic"></fa-icon>
                    </button>
                    <div class="text-style">
                        <button app-round-button class="hiddenmenu-button btn-change-text-style" data-style-type="bold">
                            <b>T</b>
                        </button>
                        <button app-round-button class="hiddenmenu-button btn-change-text-style" data-style-type="italic">
                            <i>T</i>
                        </button>
                        <button app-round-button class="hiddenmenu-button btn-change-text-style" data-style-type="underline">
                            <u>T</u>
                        </button>

                    </div>

                    <button app-round-button class="submenu-button" id="btn-change-align">
                        <fa-icon icon="align-center"></fa-icon>
                    </button>
                    <div class="text-align">
                        <button app-round-button class="hiddenmenu-button btn-change-text-style" data-style-type="left">
                            <fa-icon icon="align-left"></fa-icon>
                        </button>
                        <button app-round-button class="hiddenmenu-button btn-change-text-style" data-style-type="center">
                            <fa-icon icon="align-center"></fa-icon>
                        </button>
                        <button app-round-button class="hiddenmenu-button btn-change-text-style" data-style-type="right">
                            <fa-icon icon="align-right"></fa-icon>
                        </button>
                    </div>

                    <button app-round-button class="submenu-button" id="btn-change-color">
                        <fa-icon icon="palette"></fa-icon>
                    </button>
                    <div class="text-color">
                        <div id="tui-text-color-picker"></div>
                    </div>
                </div>

        </li>
    </ul>
    <p class="msg">Menü scrollen <b>Liks ⇔ Rechts</b></p>
</div>
<!--<script-->
<!--    type="text/javascript"-->
<!--    src="https://api-storage.cloud.toast.com/v1/AUTH_e18353c4ea5746c097143946d0644e61/toast-ui-cdn/tui-image-editor/v3.11.0/example/fabric-v4.2.0.js"-->
<!--&gt;</script>-->
<!--<script-->
<!--    type="text/javascript"-->
<!--    src="https://uicdn.toast.com/tui.code-snippet/v1.5.0/tui-code-snippet.min.js"-->
<!--&gt;</script>-->
<!--<script-->
<!--    type="text/javascript"-->
<!--    src="https://uicdn.toast.com/tui-color-picker/v2.2.6/tui-color-picker.min.js"-->
<!--&gt;</script>-->
<!--<script-->
<!--    type="text/javascript"-->
<!--    src="https://cdnjs.cloudflare.com/ajax/libs/jquery/1.8.3/jquery.min.js"-->
<!--&gt;</script>-->
<!--<script-->
<!--    type="text/javascript"-->
<!--    src="https://cdnjs.cloudflare.com/ajax/libs/FileSaver.js/1.3.3/FileSaver.min.js"-->
<!--&gt;</script>-->
<!--<script type="text/javascript" src="../dist/tui-image-editor.js"></script>-->
