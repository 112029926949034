import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ProductNames } from '../dave-data-module/entities/license.entity';
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(private store: Store<State>, private router: Router) {}

    public Login(Username: string, Password: string) {
        this.unsetNotifications();
        this.store.dispatch(BaseActionTypes.PerformLogin({ Payload: { Username, Password } }));
    }

    public Logout() {
        this.router.navigateByUrl('/login').then(() => {
            this.store.dispatch(BaseActionTypes.InitStore());
        });
    }

    private unsetNotifications() {
        for (const value in ProductNames) {
            localStorage.removeItem(ProductNames[value]);
        }
    }
}
