import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getLatestUpdatedAt } from '../../../helper/reducer.helper';
import { ResourceScheduleEntity } from '../../../entities/resource-dispo/resource-schedule.entity';
import { ResourceScheduleActionTypes } from '../../actions/resource-dispo/resource-schedule.actions';

export const RESOURCE_SCHEDULE_KEY = 'resourceSchedule';

export interface ResourceScheduleState extends EntityState<ResourceScheduleEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;
}

const adapter = createEntityAdapter<ResourceScheduleEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const resourceSchedulesReducer = createReducer(
    initialState,
    on(ResourceScheduleActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(ResourceScheduleActionTypes.RemoveOne, (state, { Payload }) =>
        adapter.removeOne(Payload, state),
    ),
    on(ResourceScheduleActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllResourceSchedule = selectAll;
export const selectResourceScheduleEntities = selectEntities;

export const selectResourceScheduleFetched = (state: ResourceScheduleState) => state.fetched;
export const selectResourceScheduleLatestUpdatedAt = (state: ResourceScheduleState) => state.latestUpdatedAt;
