import { CollectionViewer,DataSource,SelectionChange } from '@angular/cdk/collections';
import { BehaviorSubject,merge,Observable,of,Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DynamicFolderTreeDataService,DynamicFolderTreeFlatNode } from '../../dynamic-folder-tree-data.service';
import { FolderTreeControl } from './folder-tree-control';

export class DynamicFolderTreeDataSource implements DataSource<DynamicFolderTreeFlatNode> {
    dataChange = new BehaviorSubject<DynamicFolderTreeFlatNode[]>([]);
    private childrenSubscriptions = new Map<string, Subscription>();

    get data(): DynamicFolderTreeFlatNode[] {
        return this.dataChange.value;
    }
    set data(value: DynamicFolderTreeFlatNode[]) {
        this._treeControl.dataNodes = value;
        this.dataChange.next(value);
    }
    private _disabledFolderIds: number[] = [];
    set disabledFolderIds(ids: number[]) {
        this._disabledFolderIds = ids.slice();
        // this.data = this.data.map(d => d.disabled = d.item.isFolder ? this._disabledFolderIds.includes(d.item.entityId) : d.disabled)
        this.data.filter((d) => d.item.isFolder).forEach((d) => (d.disabled = ids.includes(d.item.entityId)));
        this.data = this.data;
    }
    get disabledFolderIds() {
        return this._disabledFolderIds;
    }
    constructor(private _treeControl: FolderTreeControl, private _database: DynamicFolderTreeDataService) {}

    connect(collectionViewer?: CollectionViewer): Observable<DynamicFolderTreeFlatNode[]> {
        this._treeControl.expansionModel.changed.subscribe((change) => {
            if ((change as SelectionChange<DynamicFolderTreeFlatNode>).added || (change as SelectionChange<DynamicFolderTreeFlatNode>).removed) {
                this.handleTreeControl(change as SelectionChange<DynamicFolderTreeFlatNode>);
            }
        });

        return merge(collectionViewer?.viewChange || of(), this.dataChange).pipe(
            map(() => {
                return this.data;
            }),
        );
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.childrenSubscriptions.forEach((s) => {
            s.unsubscribe();
        });
        this.childrenSubscriptions.clear();
    }

    /** Handle expand/collapse behaviors */
    handleTreeControl(change: SelectionChange<DynamicFolderTreeFlatNode>) {
        if (change.added) {
            change.added.forEach((node) => this.toggleNode(node, true));
        }
        if (change.removed) {
            change.removed
                .slice()
                .reverse()
                .forEach((node) => this.toggleNode(node, false));
        }
    }

    /**
     * Toggle the node, remove from display list
     */
    toggleNode(node: DynamicFolderTreeFlatNode, expand: boolean) {
        const index = this.data.indexOf(node);
        if (!node.expandable || index < 0 || (expand && node.isLoading)) {
            // If no children, or cannot find the node, no op
            return;
        }
        node.isLoading = true;

        const notifyChange = () => {
            // notify the change
            this.dataChange.next(this.data);
            node.isLoading = false;
        };
        if (expand) {
            if (this.childrenSubscriptions.has(node.UId)) {
                console.error('subscription already running ' + node.UId);
            } else {
                this.childrenSubscriptions.set(
                    node.UId,
                    this._database.getChildren(node).subscribe((res) => {
                        const nodes = res;
                        const parentIndex = this.data.indexOf(node);
                        if (parentIndex < 0) {
                            throw 'parentIndex not found';
                            return;
                        }
                        let lastChildIndex =
                            this.data.findIndex((e, index) => {
                                return index > parentIndex && e.level <= node.level;
                            }) - 1;
                        if (lastChildIndex < 0) {
                            lastChildIndex = this.data.length - 1;
                        }
                        // const startIndex = parentIndex + 1
                        const alreadyChildren = lastChildIndex !== parentIndex;
                        if (alreadyChildren) {
                            const nodesToAdd = [];
                            nodes.forEach((newNode) => {
                                const currentIndex = this.data.findIndex((d) => d.UId === newNode.UId);
                                if (currentIndex < 0) {
                                    nodesToAdd.push(newNode);
                                }
                            });
                            const nodesToDelete: number[] = [];
                            for (let i = parentIndex + 1; i < this.data.length && this.data[i].level > node.level; i++) {
                                if (!nodes.some((newNode) => newNode.UId === this.data[i].UId)) {
                                    this.childrenSubscriptions.get(this.data[i].UId)?.unsubscribe();
                                    this.childrenSubscriptions.delete(this.data[i].UId);
                                    nodesToDelete.push(i);
                                }
                            }
                            nodesToDelete.forEach((index) => {
                                this.data.splice(index, 1);
                            });
                            this.data.splice(parentIndex + 1, 0, ...nodesToAdd);
                        } else {
                            this.data.splice(parentIndex + 1, 0, ...nodes);
                        }
                        if (nodes.length === 0) {
                            node.expandable = false;
                        }
                        notifyChange();
                        this._treeControl.expanded.next(node);
                    }),
                );
            }
            // firstValueFrom(this._database.getChildren(node)).then((res) => {
            //     const nodes = res
            //     this.data.splice(index + 1, 0, ...nodes);
            //     if (nodes.length === 0) {
            //         node.expandable = false;
            //     }
            //     notifyChange();
            //     this._treeControl.expanded.next(node)
            // });
        } else {
            this.childrenSubscriptions.get(node.UId)?.unsubscribe();
            this.childrenSubscriptions.delete(node.UId);
            let count = 0;
            for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
            if (count) {
                this.data.splice(index + 1, count);
                notifyChange();
            }
        }
    }
}
