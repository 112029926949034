<mat-expansion-panel class="events" [expanded]="View.expanded">
    <mat-expansion-panel-header [collapsedHeight]="HeaderHeight">
        <fa-stack matCardAvatar>
            <fa-icon stackItemSize="1x" class="icon" icon="history"></fa-icon>
        </fa-stack>
        <h3 class="card-header">Ereignisse und Aufgaben</h3>
        <ng-container *ngIf="UnseenSum$ | async as count">
            <span class="unseen">{{ count }}</span>
        </ng-container>
        <div class="check-button" *ngIf="group?.selectedIndex != null && SelectedTab">
            <ng-container *ngIf="SelectedTab.searchForm">
                <mat-form-field floatLabel="never" [class.invisible]="!ShowSearchBar" (click)="$event.stopPropagation();" style="width: 10rem">
                    <mat-label>Suche</mat-label>
                    <input type="search" (keydown.Space)="$event.stopImmediatePropagation();" [formControl]="SelectedTab.searchForm" matInput />
                    <button matSuffix (click)="SelectedTab.searchForm.setValue('')" mat-icon-button [disabled]="!SelectedTab.searchForm.value">
                        <fa-icon icon="times"></fa-icon>
                    </button>
                </mat-form-field>
                <button
                    app-round-button
                    [matTooltip]="SelectedTab.searchLabel"
                    (click)="$event.stopPropagation(); ShowSearchBar = !ShowSearchBar;"
                >
                    <fa-icon [icon]="ShowSearchBar ? 'times' : 'search'"></fa-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="SelectedTab.headerButtons && !ShowSearchBar">
                <button
                    app-round-button
                    *ngFor="let btn of SelectedTab.headerButtons"
                    [matBadge]="btn.badge$ | async"
                    [matTooltip]="btn.checkLabel"
                    [Disabled]="btn.disableCheck$ | async"
                    (click)="$event.stopPropagation(); btn.checkAction()"
                    [matMenuTriggerFor]="btn.matMenu"
                >
                    <fa-icon [icon]="btn.checkIcon"></fa-icon>
                </button>
            </ng-container>
        </div>
    </mat-expansion-panel-header>
    <mat-progress-bar *ngIf="LoadingData$ | async" mode="indeterminate"></mat-progress-bar>
    <mat-tab-group (selectedIndexChange)="SelectedTab = Tabs[$event]" #group>
        <mat-tab labelClass="small-mat-tab-label" *ngFor="let tab of Tabs">
            <ng-template mat-tab-label>
                <div class="tab-header">
                    <fa-icon [icon]="tab.icon"></fa-icon>
                    ({{ (tab.listDataSource$ | async)?.length }})
                    <ng-container *ngIf="tab.unseenCount$">
                        <span class="unseen" *ngIf="tab.unseenCount$ | async as count">{{ count }}</span>
                    </ng-container>
                </div>
            </ng-template>
            <ng-template matTabContent>
                <cdk-virtual-scroll-viewport class="scroll-viewport" itemSize="89" minBufferPx="200" maxBufferPx="400">
                    <app-event-list-entry
                        class="full-width"
                        *cdkVirtualFor="let rowData of tab.listDataSource$ | async"
                        [EscapeCommissionDetails]="true"
                        [ShowRoutingButtons]="true"
                        [Event]="rowData.Event || rowData"
                    ></app-event-list-entry>
                </cdk-virtual-scroll-viewport>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</mat-expansion-panel>
<mat-menu #sortMenu >
    <button mat-menu-item (click)="TaskSort$.next({sortBy: 'endDate', direction: 'asc'})"><fa-icon icon="chevron-up"></fa-icon> Frist</button>
    <button mat-menu-item (click)="TaskSort$.next({sortBy: 'endDate', direction: 'dsc'})"><fa-icon icon="chevron-down"></fa-icon> Frist</button>
    <button mat-menu-item (click)="TaskSort$.next({sortBy: 'priority', direction: 'asc'})"><fa-icon icon="chevron-up"></fa-icon> Priorität</button>
    <button mat-menu-item (click)="TaskSort$.next({sortBy: 'priority', direction: 'dsc'})"><fa-icon icon="chevron-down"></fa-icon> Priorität</button>
</mat-menu>
<app-filter-card UnlimitedHeight #taskFilter [Settings]="TaskFilterSettings$ | async" [Values]="TaskFilterValues$ | async" (ValuesChange)="TaskFilterValues$.next($event)"></app-filter-card>
<app-filter-card UnlimitedHeight #bookmarkFilter [Settings]="BookmarkFilterSettings$ | async" [Values]="BookmarkFilterValues$ | async" (ValuesChange)="BookmarkFilterValues$.next($event)"></app-filter-card>
<app-filter-card UnlimitedHeight #eventFilter [Settings]="EventFilterSettings$ | async" [Values]="EventFilterValues$ | async" (ValuesChange)="EventFilterValues$.next($event)"></app-filter-card>
