import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { JobSpecificationActionTypes } from '../State/actions/job-specification.action';
import { getJobSpecificationFetched } from '../State/selectors/job-specification.selector';
import { BaseEntityResolver } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class JobSpecificationResolver extends BaseEntityResolver {
    constructor(store: Store<State>) {
        super(getJobSpecificationFetched, JobSpecificationActionTypes.LoadAll(), store);
    }
}
