<app-list-layout-with-routing   [Path]="PageMetaMaterial.Path" TabLabel="Materialien">
    <app-dave-list
        [ColumnConfig]="TableColumns"
        [Columns]="MaterialDisplayedColumns"
        [ColumnsSmall]="DisplayedColumnsSmall"
        [DataSource$]="MaterialDataSource$"
        [Headline]="PageMetaMaterial.Breadcrumb"
        [HeadlineIcon]="PageMetaMaterial.Icon"
        [NewEntryUrl]="'./' + NewMaterialPageMeta.Path"
        NewEntryToolTip="Material Hinzufügen"
        [EmptyText]="'Keine ' + PageMetaMaterial.Breadcrumb + ' Vorhanden'"
        [FilterValues]="FilterValues$ | async"
        (FilterValuesChange)="FilterValues$.next($event)"
        [FilterSettings]="FilterSettings$ | async"
        [DefaultSortActive]="'Name'"
    >
        <ng-container *appDetailViewButtons>
            <button app-round-button (click)="onDatanormClick()" matTooltip="Datanorm V4 importieren" Inverted style="margin: 0.25rem"><fa-icon icon="upload"></fa-icon></button>
        </ng-container>
    </app-dave-list>
</app-list-layout-with-routing>





