import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WeatherHttpService {
    private url: string;

    constructor(private http: HttpClient) {
        this.url = 'https://api.openweathermap.org';
    }

    public Jsonp(url: string, callback: string) {
        return this.http.jsonp(`${this.url}/${url}`, callback);
    }
}
