import { SharedCommissionsType } from '../graphql-types';

export class SharedCommissionsEntity {
    constructor(
        public Id: number = null,
        public PartnerId: number = null,
        public PartnerLinkedId: number = null,
        public CommissionId: number = null,
    ) {
    }

    public Clone(override: SharedCommissionsEntityAttributes = {}): SharedCommissionsEntity {
        const attr = {...this, ...override};
        return Object.assign(new SharedCommissionsEntity(), attr);
    }
}

export function SharedCommissionsEntityFromBackend(res: SharedCommissionsType): SharedCommissionsEntity {
    return new SharedCommissionsEntity(
        res.id,
        res.partnerId,
        res.partnerLinkedId,
        res.commissionId,
    );
}


export interface SharedCommissionsEntityAttributes {
    Id?: number;
         PartnerId?: number ;
         PartnerLinkedId?: number;
         CommissionId?: number;
        }
