import { createFeatureSelector,createSelector } from '@ngrx/store';
import {
employeesFeatureKey,
EmployeesState,
selectAllEmployee,
selectEmployeeEntities,
selectEmployeeFetched,
selectEmployeeLatestUpdatedAt
} from '../reducers/employees.reducer';
import { getManagedUsers,getUser } from './users.selectors';
import { EmployeeStatusEnum } from "../../entities/employee.entity";

const selectEmployee = createFeatureSelector<EmployeesState>(employeesFeatureKey);
const getEmployeeEntitys = createSelector(selectEmployee, selectEmployeeEntities);

export const getEmployeeDictionary = getEmployeeEntitys;
export const getEmployees = createSelector(selectEmployee, selectAllEmployee);
export const getEmployeesActive = createSelector(getEmployees, (entities) => entities.filter(e => !e.Deleted && e.EmployeeStatus === EmployeeStatusEnum.Aktiv));
export const getEmployeesFetched = createSelector(selectEmployee, selectEmployeeFetched);
export const getEmployeeLatestUpdatedAt = createSelector(selectEmployee, selectEmployeeLatestUpdatedAt);
export const getEmployeeById = (props: { id: number }) => createSelector(getEmployeeEntitys, (dictionary) => props?.id && dictionary[props.id]);
export const getEmployeeByUserId = (props: { userId: number }) => createSelector(getEmployees, (entities) => props?.userId && entities.find(e => e.UserId === props.userId));

export const getUserByEmployeeId = (props: { id: number }) =>
    createSelector(getEmployeeById(props), getManagedUsers, (employee, users) => (employee && employee.UserId && users ? users.find((u) => u.Id === employee.UserId) : null));

const getSorted = (props: { sortBy: 'name'; direction?: 'desc' | 'asc' }) =>
    createSelector(getEmployees, (entities) => {
        switch (props.sortBy) {
            case 'name': {
                if (props.direction === 'desc') {
                    return entities.sort((a, b) => (a.DisplayName.toLowerCase() < b.DisplayName.toLowerCase() ? 1 : -1));
                } else {
                    return entities.sort((a, b) => (a.DisplayName.toLowerCase() > b.DisplayName.toLowerCase() ? 1 : -1));
                }
            }
            default: {
                return entities;
            }
        }
    });
export const getEmployeesSortedByName = getSorted({ sortBy: 'name' });
export const getEmployeesSortedByNameStartWithMe = createSelector(getEmployeesSortedByName, getUser, (employees, user) => {
    if (!user) {
        console.error('User Not Set; from getEmployeesSortedByNameStartWithMe');
        return null;
    }
    return [employees.find((e) => e.UserId === user.Id), ...employees.filter((u) => u.UserId !== user.Id)];
});
