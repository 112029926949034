<app-list-layout-with-routing [Path]="PageMeta.Path" TabLabel="Ressourcen">
    <app-dave-list
        [ColumnConfig]="TableColumns"
        [Columns]="DisplayedColumns"
        [ColumnsSmall]="DisplayedColumnsSmall"
        [DataSource$]="DataSource$"
        [Headline]="PageMeta.Breadcrumb"
        [HeadlineIcon]="PageMeta.Icon"
        [NewEntryUrl]="'./' + NewPageMeta.Path"
        NewEntryToolTip="Ressource Hinzufügen"
        [EmptyText]="'Keine ' + PageMeta.Breadcrumb + ' Vorhanden'"
        [FilterValues]="FilterValues$ | async"
        (FilterValuesChange)="FilterValues$.next($event)"
        [FilterSettings]="FilterSettings$ | async"
        [FilterCount]="FilterCount$ | async"
        [DefaultSortActive]="'Name'"
    ></app-dave-list>
</app-list-layout-with-routing>
