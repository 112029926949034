import { createActionGroup, props } from '@ngrx/store';
import { BVCustomNameEntity } from '../../../entities/b-v-custom-name.entity';
import { DaveMutationChangeBvCustomNamesArgs, DaveMutationCreateBvCustomNamesArgs, DaveMutationDeleteBvCustomNamesArgs, QueryBvCustomNamesArgs } from '../../../graphql-types';

export const BVCustomNameActions = createActionGroup({
    source: 'BVCustomName',
    events: {
        'Update All': props<{ Payload: BVCustomNameEntity[]; updateLatestUpdatedAt?: boolean }>(),
        'Update Many': props<{ Payload: BVCustomNameEntity[]; updateLatestUpdatedAt?: boolean }>(),
        'Update One': props<{ Payload: BVCustomNameEntity }>(),
        'Remove One': props<{ Payload: number }>(),

        Load: props<{ Payload: QueryBvCustomNamesArgs }>(),
        Add: props<{ Payload: DaveMutationCreateBvCustomNamesArgs }>(),
        Change: props<{ Payload: DaveMutationChangeBvCustomNamesArgs }>(),
        Delete: props<{ Payload: DaveMutationDeleteBvCustomNamesArgs }>(),
    },
});
