import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommissionResolver } from '../dave-data-module/guards/commission.resolver';
import { EmployeeResolver } from '../dave-data-module/guards/employee.resolver';
import { StatusFromBackofficeResolver } from '../dave-data-module/guards/statusFromBackoffice.resolver';
import { ValidTokenGuard } from '../dave-data-module/guards/valid-token.guard';
import {
    CalendarPageMeta,
    CustomerAdministrationMeta,
    ResourcePageMeta,
    RessourcePlanPageMeta,
    ShiftPlanPageMeta
} from "../helper/page-metadata";
import { EmployeeToVacationResolver } from '../dave-data-module/guards/employee-to-vacation.resolver';
import { ManageUserListResolver } from '../dave-data-module/guards/manage-user-list.resolver';
import { User2CommissionShiftResolver } from '../dave-data-module/guards/user-to-commission-shift.resolver';
import { ShiftPlanComponent } from './components/shift-plan/shift-plan.component';
import { UserToCommissionResolver } from '../dave-data-module/guards/user-to-commission.resolver';
import { ResourcePlanComponent } from './components/resource-plan/resource-plan.component';
import { SpecialRouteLabel } from "../dave-utils-module/dave-shared-components-module/services/route-label.service";

const routes: Routes = [
    {
        path: '',
        data: {
            breadcrumb: 'none',
        },
        children: [
            {
                path: '',
                redirectTo: ShiftPlanPageMeta.Path,
                pathMatch: 'full'
            },{
            path: ShiftPlanPageMeta.Path,
            component: ShiftPlanComponent,
            canActivate: [ValidTokenGuard],
            data: {
                breadcrumb: ShiftPlanPageMeta.Breadcrumb,
            },
            resolve: {
                commissions: CommissionResolver,
                employees: EmployeeResolver,
                employeeToVacazions: EmployeeToVacationResolver,
                user2CommissionShifts: User2CommissionShiftResolver,
                user2Commission: UserToCommissionResolver,
                managedUserList: ManageUserListResolver, // nur für die select User componente
                statusFromBackoffice: StatusFromBackofficeResolver,
            },
        },
            {
                path: RessourcePlanPageMeta.Path,
                data: {
                    breadcrumb: SpecialRouteLabel.RessourcePlan,
                },
                component: ResourcePlanComponent,
                canActivate: [ValidTokenGuard],
                resolve: {
                    ...ResourcePlanComponent.RequiredResolvers
                },
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class DaveCalendarRoutingModule {
    constructor() {
    }
}
