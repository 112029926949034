import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Moment } from 'moment';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, map, pairwise, shareReplay, skip, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { SimplebarAngularComponent } from 'simplebar-angular';
import { BurgerButtonWrapperComponent } from '../../../burger-button-wrapper/burger-button-wrapper.component';
import { DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData } from '../../../components/templates/new-document-view/component/dave-file-upload-dialog/dave-file-upload-dialog.component';
import { DocumentState, FileEntity, FileEntityDocumentStateNames, NEW_DOCUMENT_DEFAULT_NAME } from '../../../dave-data-module/entities/file.entity';
import { FolderEntity, FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { DmsViewStyle } from '../../../dave-data-module/entities/user.entity';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { TagResolver } from '../../../dave-data-module/guards/tag.resolver';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { DownloadVersions, DownloadZip, HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { FilesActionTypes } from '../../../dave-data-module/State/actions/files.actions';
import { FolderActionTypes } from '../../../dave-data-module/State/actions/folder.actions';
import { ChangeSettings } from '../../../dave-data-module/State/actions/settings.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getCommissions, getCommissionsFetched } from '../../../dave-data-module/State/selectors/commission.selector';
import { getFilesWithoutHidden } from '../../../dave-data-module/State/selectors/files.selectors';
// import { getFolderFetched,getFolders } from '../../../dave-data-module/State/selectors/folder.selectors';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Actions, ofType } from '@ngrx/effects';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { getTagFetched, getTags } from '../../../dave-data-module/State/selectors/tag.selectors';
import { getSetting } from '../../../dave-data-module/State/selectors/users.selectors';
import { EmailEditorComponent, EmailEditorComponentDialogConfig, EmailEditorComponentDialogData } from '../../../dave-email-module/components/email-editor/email-editor.component';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { FilterOption, FILTER_TYPE_SEARCH_MULTI_SELECT, FILTER_TYPE_TIMESPAN, IFilterTypeSearchMultiSelectValue } from '../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { isNotNullOrUndefined, uniqArray } from '../../../helper/helper';
import { CommissionMeta, FileLinkIcon } from '../../../helper/page-metadata';
import { CustomLabelService } from '../../../services/custom-label.service';
import { FileExplorerSortingService } from '../../../services/fileexplorer-sorting.service';
import { LoadingService } from '../../../services/loading.service';
import { MoveFileFolderService } from '../../../services/move-file-folder.service';
import { SortFilesDialogComponent, SortFilesDialogComponentDialogData } from '../../../sort-files-dialog/sort-files-dialog.component';
import { DynamicFolderTreeDataService, DynamicFolderTreeFlatNode } from '../../dynamic-folder-tree-data.service';
import { NewFolderDialogComponent, NewFolderDialogComponentDialogData } from '../new-folder-dialog/new-folder-dialog.component';
import { ViewFolderComponent, ViewFolderComponentDialogData } from '../view-folder/view-folder.component';
import { FolderTreeControl } from './folder-tree-control';
import { DynamicFolderTreeDataSource } from './folder-tree-datasource';
import { getPartner } from '../../../dave-data-module/State/selectors/partners.selectors';
import { PartnerTypeEnum } from '../../../dave-data-module/entities/partner.entity';
export const sortByName = (a, b): 1 | -1 | 0 => {
    const aName = a.Name.toLowerCase();
    const bName = b.Name.toLowerCase();
    return aName < bName ? -1 : aName > bName ? 1 : 0;
};

interface ExplorerElement {
    name: string;
    id: number;
    isFolder: boolean;
    isLink: boolean;
    size?: string;
    createdAt?: Date;
    updatedAt?: Date;
    fileType?: string;
    thumbnailPath?: string;
    prevSelected$?: Observable<boolean>;
    icon?: IconProp;
    isChecked?: boolean;
    searchStrings: string[];
}

export const MatTreeNodePaddingIndent = 10; // in px

interface FileFilter {
    tagIds: IFilterTypeSearchMultiSelectValue<number>[];
    commissionIds: IFilterTypeSearchMultiSelectValue<number>[];
    createdAt: { to: Moment; from: Moment };
    updatedAt: { to: Moment; from: Moment };
}

@Component({
    selector: 'app-file-explorer',
    templateUrl: './file-explorer.component.html',
    styleUrls: ['./file-explorer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileExplorerComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() EmailEditorDialogDataForFilePreview: Partial<EmailEditorComponentDialogData>;
    @Input() ShowTree = false;
    @Input() DisableBackButtonFolderId: number;
    public Minimalistic$ = new BehaviorSubject(false);
    @Input()
    set Minimalistic(value) {
        this.Minimalistic$.next(coerceBooleanProperty(value));
    }
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_Minimalistic: BooleanInput;
    public ViewStyleOverride$ = new BehaviorSubject<DmsViewStyle | null>(null);
    @Input() set ViewStyle(v: DmsViewStyle) {
        this.ViewStyleOverride$.next(v);
    }
    @Input() ShowScan2DaveButton = false;
    @Input() DisableRoutingParams = false;
    @Input() FileUploadButton = false;
    @Input() FileUploadConfig: DaveFileUploadDialogComponentDialogData = {};

    @Input()
    set PreventFileClick(value) {
        this.preventFileClick = coerceBooleanProperty(value);
    }
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_PreventFileClick: BooleanInput;
    @Input()
    set PreventFileUploadClick(value) {
        this.preventFileUploadClick = coerceBooleanProperty(value);
    }
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_PreventFileUploadClick: BooleanInput;
    @Output() Scan2DaveClicked$ = new EventEmitter<number>();
    @Output() FilterOpen$ = new EventEmitter<boolean>();
    @Output() FileClicked = new EventEmitter<number>();
    @Output() FileUploadClicked = new EventEmitter<number>();
    @Output() fileExplorerContext = new EventEmitter<{ directoryFileIds: number[] } | undefined>();
    private preventFileClick = false;
    private preventFileUploadClick = false;
    public OpenMailDialog$: Subject<void> = new Subject<void>();
    public ViewStyle$ = this.ViewStyleOverride$.pipe(
        switchMap((v) =>
            v
                ? of(v)
                : this.Minimalistic$.pipe(
                      switchMap((minimalistic) =>
                          minimalistic
                              ? of(DmsViewStyle.Tile)
                              : this.store.select(getSetting).pipe(
                                    map((setting) => setting?.DmsViewStyle),
                                    map((s) => (s === undefined || s === DmsViewStyle.OldDms ? DmsViewStyle.List : s)),
                                ),
                      ),
                  ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public ShowNotFoundError: boolean;
    public Math = Math;
    @HostListener('window:resize')
    sizing() {
        const smallWidth = this.ElementRef?.nativeElement.offsetWidth < 640;
        if (this.Small$.value !== smallWidth) {
            this.Small$.next(smallWidth);
        }
    }

    public Small$ = new BehaviorSubject(true);
    public dynamicTreeControl: FolderTreeControl;

    public ViewStyles = DmsViewStyle;
    public DisplayedColumns$: Observable<Array<keyof ExplorerElement | 'select' | 'options'>> = this.Small$.pipe(
        map((mobile) => (mobile ? ['name', 'fileType', 'createdAt', 'updatedAt', 'options'] : ['name', 'fileType', 'createdAt', 'updatedAt', 'size', 'options'])),
        switchMap((cols: Array<keyof ExplorerElement | 'select' | 'options'>) => this.Multiselect$.pipe(map((ms) => (ms ? ['select' as 'select', ...cols] : cols)))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public SelectedFolderId$ = new BehaviorSubject<number>(null);
    public SelectedFileId$ = new BehaviorSubject<number>(null);

    public PreviousSelectedFolderId$ = this.SelectedFolderId$.pipe(
        distinctUntilChanged(),
        startWith(null),
        pairwise(),
        map(([prev, curr]) => prev),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    // public PreviousSelectedFolderId$ = this.SelectedFileId$.pipe(
    //     switchMap(fileId => {
    //         if (fileId !== null) {
    //             return this.SelectedFolderId$.pipe(
    //                 startWith(null),
    //                 pairwise(),
    //                 map(([prev, curr]) => prev)
    //             );
    //         } else {
    //             return this.SelectedFolderId$.pipe(
    //                 distinctUntilChanged(),
    //                 startWith(null),
    //                 pairwise(),
    //                 map(([prev, curr]) => prev )
    //             );
    //         }
    //     }),
    //     distinctUntilChanged(),
    //     shareReplay({ refCount: true, bufferSize: 1 })
    // );

    public CurrentSelectedFileId$ = this.SelectedFileId$.pipe(
        distinctUntilChanged(),
        startWith(null),
        pairwise(),
        map(([prev, curr]) => curr),
        distinctUntilChanged(),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public Path$: Observable<FolderEntity[]> = this.SelectedFolderId$.pipe(
        distinctUntilChanged(),
        switchMap((id) => (id ? this.folderDataService.getFolderAndParentsById(id, true) : of(null))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public foldersBySelectedParent$: Observable<FolderEntity[]> = this.SelectedFolderId$.pipe(
        map((id) => id || null),
        distinctUntilChanged(),
        tap(() => this.LoadingFolders$.next(true)),
        switchMap((id) => this.folderDataService.getFolderByParent(id)),
        tap(() => this.LoadingFolders$.next(false)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected dynamicTreeDataSource: DynamicFolderTreeDataSource;

    public Folder$: Observable<{ folders: FolderEntity[]; files: FileEntity[] }>;
    public ShownElements$: Observable<ExplorerElement[]> = new BehaviorSubject([]);
    public ShownElementsTableDataSource$: Observable<TableVirtualScrollDataSource<ExplorerElement>> /* = new BehaviorSubject(new TableVirtualScrollDataSource([]))*/;
    public BaseUrl = this.api.GetUrl('', 'file');
    public PathIds$: Observable<number[]> = new BehaviorSubject([]);
    public FolderMultisilect$ = new BehaviorSubject<number[]>([]);
    public FileMultisilect$ = new BehaviorSubject<number[]>([]);
    protected mergePdfDisabled$ = combineLatest([this.FileMultisilect$, this.FolderMultisilect$]).pipe(
        switchMap(([fileIds, folderIds]) => {
            if (!fileIds || fileIds.length < 2 || folderIds?.length) {
                return of(true);
            } else {
                return this.fileDataService.GetFilesById(fileIds).pipe(
                    first(),
                    map((files) => files.some((f) => f?.MIMEType !== 'application/pdf')),
                );
            }
        }),
    );
    public IsAllSelected$: Observable<boolean> = new BehaviorSubject(false);
    public Multiselect$ = new BehaviorSubject(false);
    public ShowDataSearch = false;
    public DataSearchForm = new FormControl<string>('');
    public FolderNameForm = new FormControl<string>('');
    public ClickMove$: Subject<void> = new Subject<void>();
    public ClickDownload$: Subject<void> = new Subject<void>();
    public Commissions$ = this.store.select(getCommissions);
    public FolderWithTypeSelected$: Observable<boolean> = new BehaviorSubject(false);
    public Sharing$ = new BehaviorSubject<boolean>(true);
    // public ItemHeight = 138.25;
    public ItemHeight = 158;
    public ItemWidth = 80;
    public HasFolderEditPermission = false;
    @ViewChild(MatSort) private matSortDOM?: MatSort;
    @ViewChild('breadcrumbScrollbar') private breadcrumbScrollbar?: SimplebarAngularComponent;
    @ViewChild('scrollableTree') private treeWrapperScrollbar?: CdkVirtualScrollViewport;
    @ViewChild('drawer') private drawer?: MatDrawer;
    @ViewChild('buttonWrapper') private buttonWrapper?: BurgerButtonWrapperComponent;
    @ViewChild('scrollViewportForList') private scrollViewportForList?: CdkVirtualScrollViewport;
    @ViewChild('scrollViewportForTile') private scrollViewportForTile?: CdkVirtualScrollViewport;
    private subscriptions: Subscription[] = [];
    private onDestroy$ = new Subject<void>();
    private onInit$ = new BehaviorSubject<boolean>(false);
    private afterViewInit$ = new BehaviorSubject<boolean>(false);
    public FilterValues$: BehaviorSubject<FileFilter> = new BehaviorSubject({
        tagIds: [],
        commissionIds: [],
        createdAt: { to: null, from: null },
        updatedAt: { to: null, from: null },
    });

    public FilterSettings$: Observable<FilterOption<keyof FileFilter>[]> = combineLatest([this.store.select(getTags), this.store.select(getCommissions), this.cls.getMultiple$('Commission')]).pipe(
        filter((v) => v.every(isNotNullOrUndefined)),
        map(([tags, commissions, commissionLabel]) => {
            let tagValues = tags.map((c) => ({
                id: c.Id,
                label: c.Name,
            }));
            let commissionValues = commissions.map((c) => ({
                id: c.Id,
                label: c.DisplayName,
            }));
            return [
                {
                    Name: 'tagIds',
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Schlagwörter',
                    Icon: 'tags',
                    Values: tagValues,
                },
                {
                    Name: 'commissionIds',
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: commissionLabel,
                    Icon: CommissionMeta.Icon,
                    Values: commissionValues,
                },
                {
                    Name: 'createdAt',
                    Type: FILTER_TYPE_TIMESPAN,
                    Label: 'Datei hochgeladen',
                    Icon: 'calendar',
                },
                {
                    Name: 'updatedAt',
                    Type: FILTER_TYPE_TIMESPAN,
                    Label: 'Datei aktualisiert',
                    Icon: 'calendar',
                },
            ];
        }),
    );
    public LoadingFiles$ = new BehaviorSubject(false);
    public LoadingFolders$ = new BehaviorSubject(false);
    protected showProgressBar$ = combineLatest([this.LoadingFiles$, this.LoadingFolders$]).pipe(
        map((loading) => loading.some((l) => l)),
        distinctUntilChanged(),
    );
    // protected dynamicTreeDataAsArray$: Observable<DynamicFolderTreeFlatNode[]>;
    constructor(
        private moveFileFolderService: MoveFileFolderService,
        private api: HttpService,
        private store: Store<State>,
        tagResolver: TagResolver,
        commissionResolver: CommissionResolver,
        private dialog: MatDialog,
        private sortService: FileExplorerSortingService,
        private appDialog: AppDialogService,
        protected route: ActivatedRoute,
        protected router: Router,
        private fileDataService: FileDataService,
        public ElementRef: ElementRef,
        protected cls: CustomLabelService,
        private actions$: Actions,
        private ls: LoadingService,
        private folderDataService: FolderDataService,
        folderTreeDataService: DynamicFolderTreeDataService,
    ) {
        this.dynamicTreeControl = new FolderTreeControl(
            this.folderDataService,
            (node: DynamicFolderTreeFlatNode) => node.level,
            (node: DynamicFolderTreeFlatNode) => node.expandable,
        );
        this.dynamicTreeDataSource = new DynamicFolderTreeDataSource(this.dynamicTreeControl, folderTreeDataService);
        this.dynamicTreeDataSource.data = folderTreeDataService.initialData();
        // this.dynamicTreeDataAsArray$ = this.dynamicTreeDataSource.connect().pipe(shareReplay({refCount: true, bufferSize: 1}));
        firstValueFrom(this.store.select(getCommissionsFetched)).then((fetched) => {
            if (!fetched) {
                commissionResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getTagFetched)).then((fetched) => {
            if (!fetched) {
                tagResolver.resolve();
            }
        });

        this.FolderWithTypeSelected$ = this.FolderMultisilect$.pipe(
            switchMap((selectedIds) => this.folderDataService.getFolderByIds(selectedIds, true)),
            map((folders) => folders.some((f) => f.Type != null)),
        );

        this.subscriptions.push(
            this.PreviousSelectedFolderId$.subscribe(),
            this.SelectedFolderId$.pipe(switchMap((id) => (id ? this.folderDataService.getFolderById(id, true) : of(null)))).subscribe((folder) => {
                if (folder) {
                    this.HasFolderEditPermission = folder.CanEdit;
                } else {
                    this.HasFolderEditPermission = true;
                }
            }),
        );

        this.Folder$ = combineLatest([this.SelectedFolderId$, this.onInit$.pipe(map((v) => !!v))])
            .pipe(
                map(([folderId]) => folderId),
                distinctUntilChanged(),
                tap(() => this.LoadingFiles$.next(true)),
                switchMap((folderId) => {
                    return combineLatest([
                        this.store.select(getPartner).pipe(
                            map(p => p.PartnerTypeId === PartnerTypeEnum.TSB),
                            distinctUntilChanged(),
                            switchMap(isTsb => {
                                if (isTsb) {
                                    return this.foldersBySelectedParent$.pipe(switchMap(folders => {
                                        return this.SelectedFolderId$.pipe(
                                            distinctUntilChanged(),
                                            switchMap(id => id ? this.folderDataService.getFolderById(id, true) : of(null)),
                                            first(),
                                            map(parent => {
                                                if (parent?.Type === FolderTypes.commission) {
                                                    return folders.filter(f => f.Type !== FolderTypes.construction_diary_root && f.Type !== FolderTypes.event_root && f.Type !== FolderTypes.transmission_root);
                                                } else {
                                                    return folders.filter((f) => !(f.Type == FolderTypes.email_attachment && f.ParentId == null));
                                                }
                                            }),
                                        )
                                    }));
                                } else {
                                    return this.foldersBySelectedParent$.pipe(map(folders => folders.filter((f) => !(f.Type == FolderTypes.email_attachment && f.ParentId == null))))
                                }
                            })
                        ),
                        this.fileDataService.GetFilesStateFromFolder$(folderId || 0).pipe(
                            filter(isNotNullOrUndefined),
                            distinctUntilChanged((a, b) => a.length === b.length && a.every((e, index) => e.Id === b[index].Id && e.UpdatedAt.getTime() === b[index].UpdatedAt.getTime())),
                            tap(() => this.LoadingFiles$.next(false)),
                            switchMap((files) =>
                                this.FilterValues$.pipe(
                                    map((filter) => {
                                        let filteredFiles = files.slice();
                                        if (filter.tagIds?.length) {
                                            filteredFiles = filteredFiles.filter((file) => file.TagIds.length >= filter.tagIds.length && filter.tagIds.every((tag) => file.TagIds.includes(tag.id)));
                                        }
                                        if (filter.commissionIds?.length) {
                                            filteredFiles = filteredFiles.filter((file) => file.ContractId && filter.commissionIds.some((c) => c.id === file.ContractId));
                                        }
                                        if (filter.createdAt?.from) {
                                            filteredFiles = filteredFiles.filter((f) => filter.createdAt?.from.isSameOrBefore(f.CreatedAt, 'date'));
                                        }
                                        if (filter.createdAt?.to) {
                                            filteredFiles = filteredFiles.filter((f) => filter.createdAt?.to.isSameOrAfter(f.CreatedAt, 'date'));
                                        }
                                        if (filter.updatedAt?.from) {
                                            filteredFiles = filteredFiles.filter((f) => filter.updatedAt?.from.isSameOrBefore(f.UpdatedAt, 'date'));
                                        }
                                        if (filter.updatedAt?.to) {
                                            filteredFiles = filteredFiles.filter((f) => filter.updatedAt?.to.isSameOrAfter(f.UpdatedAt, 'date'));
                                        }
                                        return filteredFiles;
                                    }),
                                ),
                            ),
                        ),
                    ]);
                }),
            )
            .pipe(
                map(([folders, files]) => ({
                    folders: folders
                        .slice()
                        .sort(sortByName),
                    files: files?.slice().sort(sortByName),
                })),
                shareReplay({ refCount: true, bufferSize: 1 }),
            );
        this.ShownElements$ = combineLatest([this.store.select(getToken).pipe(filter(isNotNullOrUndefined)), this.Folder$]).pipe(
            map(
                ([token, folder]) => [
                    ...folder.folders.map<ExplorerElement>((f) => ({
                        id: f.Id,
                        isFolder: true,
                        isLink: false,
                        name: f.Name,
                        createdAt: f.CreatedAt,
                        updatedAt: f.UpdatedAt,
                        prevSelected$: this.PreviousSelectedFolderId$.pipe(
                            map((id) => id === f.Id),
                            distinctUntilChanged(),
                        ),
                        searchStrings: [f.Name].map((s) => s.toLowerCase()),
                    })),
                    ...folder.files.map<ExplorerElement>((f) => ({
                        id: f.Id,
                        shared: f.Shared,
                        isFolder: false,
                        isLink: !!f.LinkedDocumentId,
                        name: f.Name,
                        size: f.GetLastVersion()?.Size.toString(),
                        createdAt: f.CreatedAt,
                        updatedAt: f.UpdatedAt,
                        fileType: f.MIMETypeDescription,
                        thumbnailPath: f.GetLastVersion()?.HasThumbnail ? this.BaseUrl + f.GetLastVersion().GetThumbnailLink(token) : null,
                        icon: f.FileIcon,
                        isChecked: f?.State ? f.State === DocumentState.DocumentStateChecked : false,
                        prevSelected$: this.CurrentSelectedFileId$.pipe(
                            map((id) => id === f.Id),
                            distinctUntilChanged(),
                        ),
                        searchStrings: [f.Name, f.Description].map((s) => s.toLowerCase()),
                    })),
                ],
                // searchString
                //     ? [
                //           ...folder.folders
                //               .filter((f) => f.Name.toLocaleLowerCase().includes(searchString))
                //               .map<ExplorerElement>((f) => ({
                //                   id: f.Id,
                //                   isFolder: true,
                //                   isLink: false,
                //                   name: f.Name,
                //                   createdAt: f.CreatedAt,
                //                   updatedAt: f.UpdatedAt,
                //                   searchStrings: [f.Name],
                //                   // prevSelected: f.Id === prevFolderId,
                //               })),
                //           ...folder.files
                //               .filter((f) => [f.Name, f.Description].filter(isNotNullOrUndefined).some((v) => stringSearch(v, searchString)))
                //               .map<ExplorerElement>((f) => ({
                //                   id: f.Id,
                //                   shared: f.Shared,
                //                   isFolder: false,
                //                   isLink: !!f.LinkedDocumentId,
                //                   name: f.Name,
                //                   size: f.GetLastVersion()?.Size.toString(),
                //                   createdAt: f.CreatedAt,
                //                   updatedAt: f.UpdatedAt,
                //                   fileType: f.MIMETypeDescription,
                //                   thumbnailPath: f.GetLastVersion()?.HasThumbnail ? this.BaseUrl + f.GetLastVersion().GetThumbnailLink(token) : null,
                //                   icon: f.FileIcon,
                //                   isChecked: f?.State ? f.State === DocumentState.DocumentStateChecked : false,
                //                   searchStrings: [f.Name, f.Description],
                //                   // prevSelected: f.Id === prevFileId,
                //               })),
                //       ]
                //     :
            ),
            switchMap((elements) =>
                this.DataSearchForm.valueChanges.pipe(
                    startWith(''),
                    map(() => this.DataSearchForm.value?.toLowerCase()),
                    distinctUntilChanged(),
                    map((searchQuery) => (searchQuery ? elements.filter((e) => e.searchStrings.some((s) => s.includes(searchQuery))) : elements)),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        this.PathIds$ = this.Path$.pipe(map((folders) => folders?.map((f) => f.Id)));
        this.ShownElementsTableDataSource$ = combineLatest([this.afterViewInit$.pipe(filter((v) => !!v)), this.ShownElements$]).pipe(
            map(([, elements]) => {
                const ret = new TableVirtualScrollDataSource<ExplorerElement>(elements);
                ret.sort = this.matSortDOM;
                ret.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case 'name':
                            return item.name.toLowerCase();
                        case 'fileType':
                            return item.fileType?.toLowerCase();
                        default:
                            return item[property];
                    }
                };
                return ret;
            }),
        );

        this.SelectedFolderId$.pipe(
            takeUntil(this.onDestroy$),
            distinctUntilChanged(),
            switchMap(() => this.ShownElements$.pipe(skip(1), first())),
            withLatestFrom(this.PreviousSelectedFolderId$),
        ).subscribe(([data, prevFolderId]) => {
            const index = data.findIndex((e) => e.id === prevFolderId);
            if (index !== -1) {
                setTimeout(() => {
                    this.scrollViewportForList?.scrollToIndex(index);
                    this.scrollViewportForTile?.scrollToIndex(index);

                    setTimeout(() => {
                        const elementRef = (this.scrollViewportForTile || this.scrollViewportForList)?.elementRef;
                        const element = (this.scrollViewportForTile || this.scrollViewportForList)?.elementRef.nativeElement.querySelector('.prev-selected');
                        (element as HTMLElement)?.focus();
                    }, 1);
                }, 1);
            }
        });
        this.IsAllSelected$ = combineLatest([this.FileMultisilect$, this.FolderMultisilect$, this.ShownElements$]).pipe(
            map(([selectedFiles, selectedFolders, allElements]) => {
                return allElements && selectedFiles && selectedFolders && allElements.length === selectedFiles.length + selectedFolders.length;
            }),
        );
        this.ClickMove$.pipe(
            takeUntil(this.onDestroy$),
            withLatestFrom(this.FileMultisilect$, this.FolderMultisilect$, this.SelectedFolderId$),
            tap(([, selectedFileIds, selectedFolderIds]) => {
                this.moveFileFolderService.MoveFileFolder(selectedFileIds, selectedFolderIds);
                this.ToggleMultiselect();
            }),
        ).subscribe();
        this.Path$.pipe(
            takeUntil(this.onDestroy$),
            filter(isNotNullOrUndefined),
            tap((path) => {
                this.FolderNameForm.patchValue(path[path.length - 1].Name);
                this.BreadcrumbScrollToTheEnd();
                this.expandTree(path);
            }),
        ).subscribe();
        this.ClickDownload$.pipe(
            takeUntil(this.onDestroy$),
            withLatestFrom(this.store.select(getFilesWithoutHidden)),
            tap(([, files]) => {
                if (this.FileMultisilect$.value?.length === 1 && !this.FolderMultisilect$.value?.length) {
                    DownloadVersions(files.filter((f) => this.FileMultisilect$.value.includes(f.Id)).map((f) => f.GetLastVersion()));
                } else {
                    DownloadZip(this.FileMultisilect$.value, this.FolderMultisilect$.value);
                }
                this.ToggleMultiselect();
            }),
        ).subscribe();
    }

    @Input() set SelectedFolderId(value: number) {
        this.SelectedFolderId$.next(value);
        this.Path$.pipe(
            take(1),
            tap(() => {
                setTimeout(() => this.ScrollTreeToFolder(value), 1);
            }),
        ).subscribe();
    }
    hasChild = (_: number, _nodeData: DynamicFolderTreeFlatNode) => _nodeData.expandable;
    ngOnInit(): void {
        this.onInit$.next(true);
        this.subscriptions.push(
            this.OpenMailDialog$.pipe(
                withLatestFrom(this.FileMultisilect$),
                tap(([, files]) => {
                    this.dialog.open<EmailEditorComponent, EmailEditorComponentDialogData>(EmailEditorComponent, {
                        ...EmailEditorComponentDialogConfig,
                        data: {
                            FileAttachmntFileIds: files,
                        },
                    });
                }),
            ).subscribe(),
            this.FileClicked.subscribe((id) => {
                if (!this.DisableRoutingParams) {
                    this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: {
                            documentId: id,
                        },
                        queryParamsHandling: 'merge',
                        replaceUrl: true,
                    });
                }
            }),
            this.SelectedFolderId$.pipe(
                distinctUntilChanged(),
                tap((folderId) => {
                    if (!this.DisableRoutingParams) {
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: {
                                folderId,
                            },
                            queryParamsHandling: 'merge',
                            replaceUrl: true,
                        });
                    }
                }),
            ).subscribe(),
        );
    }

    public ScrollTreeToFolder(id: number) {
        // @ts-ignore
        if (this.treeWrapperScrollbar && this.dynamicTreeDataSource._expandedData) {
            this.treeWrapperScrollbar.scrollToIndex(
                // @ts-ignore
                this.dynamicTreeDataSource._expandedData.value.findIndex((node) => node.Id === id),
                'smooth',
            );
        }
    }

    public BreadcrumbScrollToTheEnd() {
        if (this.breadcrumbScrollbar) {
            setTimeout(() => {
                this.breadcrumbScrollbar.SimpleBar.getScrollElement().scrollLeft = this.breadcrumbScrollbar.SimpleBar.getScrollElement().scrollWidth;
            }, 1);
        }
    }

    public SortTableData(sort: Sort, dataSource: TableVirtualScrollDataSource<ExplorerElement>) {
        this.sortService.SetSortable(this.SelectedFolderId$.value, {
            id: sort.direction ? sort.active : null,
            start: sort.direction || 'asc',
            disableClear: false,
        });
        if (!dataSource.sort) {
            dataSource.sort = this.matSortDOM;
        }
    }

    ClickNode2(node: DynamicFolderTreeFlatNode) {
        if (node.item.isFolder) {
            this.SelectedFolderId$.next(node.item.entityId);
        } else {
            this.fileClick(node.item.entityId);
        }
    }

    ClickFolder(id: number) {
        if (this.Multiselect$.value) {
            if (this.FolderMultisilect$.value.includes(id)) {
                this.FolderMultisilect$.next(this.FolderMultisilect$.value.slice().filter((fId) => fId !== id));
            } else {
                this.FolderMultisilect$.next([...this.FolderMultisilect$.value.slice(), id]);
            }
        } else {
            this.SelectedFileId$.next(null);
            this.SelectedFolderId$.next(id);
        }
    }

    ClickFile(id: number) {
        if (this.Multiselect$.value) {
            if (this.FileMultisilect$.value.includes(id)) {
                this.FileMultisilect$.next(this.FileMultisilect$.value.slice().filter((fId) => fId !== id));
            } else {
                this.FileMultisilect$.next([...this.FileMultisilect$.value.slice(), id]);
            }
        } else {
            this.fileClick(id);
            this.setImageIds();
        }
    }

    CilickItem(item: ExplorerElement) {
        if (item.isFolder) {
            this.ClickFolder(item.id);
        } else {
            this.ClickFile(item.id);
        }
    }

    public UploadClick(FolderId: number) {
        this.FileUploadClicked.emit(FolderId);
        if (this.preventFileUploadClick) {
            return;
        }
        this.dialog.open<DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData>(DaveFileUploadDialogComponent, {
            ...DaveFileUploadDialogComponent.DefaultConfig,
            data: { ...this.FileUploadConfig, FolderId },
        });
    }
    public getFileIdsFromCurrentDirectory$(): Observable<null | number[]> {
        return this.ViewStyle$.pipe(
            switchMap((vs) => {
                if (vs === DmsViewStyle.Tile) {
                    return this.ShownElements$.pipe(map((elements) => elements.filter((e) => !e.isFolder).map((e) => e.id)));
                } else if (vs === DmsViewStyle.List) {
                    return this.ShownElementsTableDataSource$.pipe(
                        map((ds) =>
                            ds
                                ._orderData(ds.filteredData)
                                .filter((d) => !d.isFolder)
                                .map((d) => d.id),
                        ),
                    );
                }
                return of(null);
            }),
        );
    }
    private fileClick(fileId: number) {
        this.FileClicked.emit(fileId);
        this.SelectedFileId$.next(fileId);
        if (this.preventFileClick) {
            return;
        }
        firstValueFrom(this.getFileIdsFromCurrentDirectory$()).then((fileIds) => {
            this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
                ...DaveFilePreviewComponent.DefaultConfig,
                data: {
                    fileId,
                    emailEditorDialogData: this.EmailEditorDialogDataForFilePreview,
                    fileExplorerContext: fileIds
                        ? {
                              directoryFileIds: fileIds,
                          }
                        : undefined,
                },
            });
        });
    }

    setImageIds() {
        firstValueFrom(combineLatest([this.getFileIdsFromCurrentDirectory$(), this.SelectedFileId$])).then(([fileIds, fileId]) => {
            this.fileDataService
                .GetFilesById(uniqArray([...fileIds, fileId].filter(isNotNullOrUndefined)))
                .pipe(
                    take(1),
                    map((files) => files.filter((f) => f.MIMEType.indexOf('image/') > -1).map((f) => f.Id)),
                )
                .subscribe((imgIds) => {
                    this.fileExplorerContext.emit({ directoryFileIds: imgIds });
                });
        });
    }

    ClickAddFolder() {
        this.dialog.open(NewFolderDialogComponent, {
            ...NewFolderDialogComponent.DefaultConfig,
            data: {
                rootFolderId: this.SelectedFolderId$.value,
            } as NewFolderDialogComponentDialogData,
        });
    }

    ClickDelete(): void {
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: `Ausgewählte Dateien wirklich löschen?`,
                styleDelete: true,
            })
            .subscribe(([result]) => {
                if (result) {
                    this.ShowNotFoundError = false;
                    this.FolderMultisilect$.value.forEach((id) => this.store.dispatch(FolderActionTypes.DeleteFolder({ Payload: id })));
                    this.FileMultisilect$.value.forEach((id) => this.store.dispatch(FilesActionTypes.DeleteFileRequest({ Payload: { DocumentId: id.toString() } })));
                    this.ToggleMultiselect();
                }
            });
    }

    ClickShare() {
        this.FolderMultisilect$.value.forEach((id) =>
            this.store.dispatch(
                FolderActionTypes.ModifyFolder({
                    Payload: {
                        id,
                        shared: this.Sharing$.value,
                    },
                }),
            ),
        );
        this.FileMultisilect$.value.forEach((id) =>
            this.store.dispatch(
                FilesActionTypes.ModifyFileRequest({
                    Payload: {
                        DocumentId: id.toString(),
                        Shared: this.Sharing$.value,
                    },
                }),
            ),
        );
        this.ToggleMultiselect();
    }

    MultiselectMasterToggle(data: ExplorerElement[]) {
        if (this.FileMultisilect$.value?.length + this.FolderMultisilect$.value?.length === data.length) {
            this.FileMultisilect$.next([]);
            this.FolderMultisilect$.next([]);
        } else {
            const folderIds = data
                .slice()
                .filter((item) => item.isFolder)
                .map((item) => item.id);
            const fileIds = data
                .slice()
                .filter((item) => !item.isFolder)
                .map((item) => item.id);

            this.FolderMultisilect$.next(folderIds);
            this.FileMultisilect$.next(fileIds);
        }
    }

    ToggleMultiselect() {
        this.Multiselect$.next(!this.Multiselect$.value);
        this.FileMultisilect$.next([]);
        this.FolderMultisilect$.next([]);
    }

    IsSelected(item: ExplorerElement) {
        return (item.isFolder && this.FolderMultisilect$.value.includes(item.id)) || (!item.isFolder && this.FileMultisilect$.value.includes(item.id));
    }

    SetViewStyle(viewStyle: DmsViewStyle) {
        this.store.dispatch(
            new ChangeSettings({
                DmsViewStyle: viewStyle,
            }),
        );
    }

    SetFocus(a) {
        setTimeout(() => a.focus());
    }

    ngAfterViewInit(): void {
        this.sizing();
        this.afterViewInit$.next(true);
        // fix für buttonwraper nach dem routen auf die Kundenseite (mobile) D277-1930
        setTimeout(() => this.buttonWrapper?.renderIfOverflown(), 10);
        setTimeout(() => this.buttonWrapper?.renderIfOverflown(), 500);
        setTimeout(() => this.buttonWrapper?.renderIfOverflown(), 1000);
        setTimeout(() => this.buttonWrapper?.renderIfOverflown(), 1500);
        setTimeout(() => this.buttonWrapper?.renderIfOverflown(), 2000);
        setTimeout(() => this.buttonWrapper?.renderIfOverflown(), 4000);

        this.subscriptions.push(
            this.SelectedFolderId$.pipe(distinctUntilChanged()).subscribe((folderId) => {
                this.DataSearchForm.setValue('');
            }),

            this.ViewStyle$.pipe(
                filter((v) => v === DmsViewStyle.List),
                switchMap(() => this.SelectedFolderId$.pipe(distinctUntilChanged())),
            ).subscribe((folderId) => {
                const sort = this.sortService.GetSortable(folderId);

                if (this.matSortDOM) {
                    this.matSortDOM.sort(sort);
                } else {
                    let t = 0;
                    const step = 300;
                    const max = 2000;

                    const int = setInterval(() => {
                        t += step;
                        this.matSortDOM?.sort(sort);
                        if (t >= max || this.matSortDOM) {
                            clearInterval(int);
                        }
                    }, step);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    Options(event: Event, folderId: number, ignore: boolean) {
        if (!ignore) {
            event.preventDefault();
            event.stopPropagation();
            this.dialog.open<ViewFolderComponent, ViewFolderComponentDialogData>(ViewFolderComponent, {
                data: {
                    FolderId: folderId,
                },
                maxWidth: '100vw',
                hasBackdrop: false,
                panelClass: 'custom-dialog-class-for-overlay-on-right-side',
            });
        }
    }

    private async expandTree(path?: FolderEntity[]) {
        this.dynamicTreeControl.collapseAll();
        if (path?.length) {
            await this.dynamicTreeControl.expandFolder(path[path.length - 1].Id);
        }
    }
    public ToggleSidenav() {
        this.drawer.toggle().then((open) => {
            if (open) {
                setTimeout(() => this.buttonWrapper.render());
            } else {
                this.buttonWrapper.render();
            }
        });
    }

    protected readonly FileLinkIcon = FileLinkIcon;

    onMergePdfClick() {
        const dialogRef = this.dialog.open<SortFilesDialogComponent, SortFilesDialogComponentDialogData>(SortFilesDialogComponent, {
            ...SortFilesDialogComponent.DefaultConfig,
            data: {
                headline: 'PDFs zusammenführen',
                documentIds: this.FileMultisilect$.value,
                showFileNameForm: true,
                fileName: NEW_DOCUMENT_DEFAULT_NAME + '.pdf',
            },
        });
        dialogRef.componentInstance.submitClick.pipe(takeUntil(dialogRef.beforeClosed())).subscribe(() => {
            this.ls.startLoading('file-explorer--merge-pdf');
            firstValueFrom(this.actions$.pipe(ofType(FilesActionTypes.MergePdfVersionsFailure, FilesActionTypes.MergePdfVersionsSuccess))).then((action) => {
                this.ls.endLoading('file-explorer--merge-pdf');
                if (action.type === FilesActionTypes.MergePdfVersionsSuccess.type) {
                    dialogRef.close();
                    this.ToggleMultiselect();
                }
            });
            this.store.dispatch(
                FilesActionTypes.MergePdfVersionsRequest({
                    Payload: {
                        FolderId: this.SelectedFolderId$.value ? this.SelectedFolderId$.value.toString() : null,
                        VersionIds: dialogRef.componentInstance.documentsSorted.map((d) => d.GetLastVersion().Id.toString()),
                        DocumentName: dialogRef.componentInstance.fileName,
                    },
                }),
            );
        });
    }

    protected readonly FileEntityDocumentStateNames = FileEntityDocumentStateNames;
    protected readonly DocumentState = DocumentState;
}
