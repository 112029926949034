import { createFeatureSelector, createSelector } from '@ngrx/store';
import { partnersFeatureKey, PartnersState } from '../reducers/patners.reducer';

export const selectParents = createFeatureSelector<PartnersState>(partnersFeatureKey);
export const getPartners = createSelector(selectParents, (data) => data.partnerList);
export const getPartnerById = (props: { id: number }) => createSelector(getPartners, (data) => data?.find((d) => d.Id === props.id) || null);

export const getPartner = createSelector(selectParents, (data) => data.partner);
export const getPartnerFetched = createSelector(getPartner, (data) => !!data);
export const getPartnersFetched = createSelector(getPartners, (data) => !!data);
export const getPartnerOfficesFetched = createSelector(selectParents, (data) => !!data?.partnerOffices);
export const getPartnerOffices = createSelector(selectParents, (data) => data.partnerOffices?.filter((po) => !po.DeletedAt));
export const getPartnerOfficeById = (props: { id: number }) => createSelector(getPartnerOffices, (data) => data?.find((d) => d.Id === props.id) || null);
export const getPartnerMainOffice = createSelector(getPartnerOffices, getPartner, (offices, partner) => offices?.find((o) => o.PartnerId === partner?.Id && o.MainOffice));
export const getPartnerWithMainOffice = createSelector(selectParents, (data) => ({
    partner: data.partner,
    mainOffice: data.partnerOffices?.find((o) => o.PartnerId === data.partner?.Id && o.MainOffice),
}));
export const getPartnerWithMainOfficeByPartnerId = (props: { id: number }) =>
    createSelector(getPartners, getPartnerOffices, (partners: ReturnType<typeof getPartners>, partnerOffices: ReturnType<typeof getPartnerOffices>) => {
        const partner = partners ? partners.find((e) => e.Id === props.id) : null;
        const mainOffice = partnerOffices ? partnerOffices.find((e) => e.PartnerId === props.id && e.MainOffice) : null;
        return partner && mainOffice ? { partner, mainOffice } : null;
    });

export const getOwnPartnerOffices = createSelector(
    getPartner,
    getPartnerOffices,
    (partner: ReturnType<typeof getPartner>, partnerOffices: ReturnType<typeof getPartnerOffices>) => {
        return partnerOffices?.filter((p) => p.PartnerId === partner?.Id);
    },
);
