import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';
import {
    selectAllTextTemplateLanguage,
    selectTextTemplateLanguageEntities, selectTextTemplateLanguageFetched, selectTextTemplateLanguageLatestUpdatedAt,
    textTemplateLanguageFeatureKey, TextTemplateLanguageState,
} from '../reducers/text-template-language.reducer';

const selectTextTemplateLanguage = createFeatureSelector< TextTemplateLanguageState>(textTemplateLanguageFeatureKey);
const getTextTemplateLanguageEntities = createSelector(selectTextTemplateLanguage, selectTextTemplateLanguageEntities);

export const getTextTemplateLanguage = createSelector(selectTextTemplateLanguage, selectAllTextTemplateLanguage);
export const getTextTemplateLanguageFetched = createSelector(selectTextTemplateLanguage, selectTextTemplateLanguageFetched);
export const getTextTemplateLanguageLatestUpdatedAt = createSelector(selectTextTemplateLanguage, selectTextTemplateLanguageLatestUpdatedAt);
export const getTextTemplateLanguageById = createSelector(getTextTemplateLanguageEntities, (textTemplateLanguage: ReturnType<typeof getTextTemplateLanguageEntities>, Id: number) => Id && textTemplateLanguage[Id]);
