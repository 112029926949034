import { GeneratedDocumentsType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
import { DocumentState, DocumentStateFromGql, getDocumentStateFromGQLDocumentState } from './file.entity';
import { CustomFieldsModalTemplate } from '../../custom-form-fields/custom-form-fields.helper';

export interface GeneratedDocumentsEntityAdditionalData {
    ConstructionDiary?:{
        ConstructionDiaryIds?: number[];
        CommissionInspectionId?: number;
        EventIds?: number[];
    };
    FileId?: number;
    CustomFieldsModalTemplates?: CustomFieldsModalTemplate[];
}

export class GeneratedDocumentsEntity {
    constructor(
        public Id: number = null,
        public PartnerId: number = null,
        public CommissionId: number = null,
        public CustomerId: number = null,
        public GeneratedFile: string = undefined,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public Deleted: boolean = false,
        public GeneratedDocumentsTypeId: number = null,
        public Name: string = null,
        public AdditionalData: GeneratedDocumentsEntityAdditionalData = null,
        public DocumentIdFromPdf: number = null,
        public State: DocumentState = null,
        public ResourceId: number = null,
    ) {}
    public static readonly GqlFields: Array<keyof GeneratedDocumentsType> = [
    'id',
    'partnerId',
    'commissionId',
    'customerId',
    'createdAt',
    'updatedAt',
    'deleted',
    'generatedDocumentsTypeId',
    'name',
    'additionalData',
    'documentId',
    'state',
    'resourceId',
    ];
    // document - kommt nur beim changeGeneratedDocument(...) zurück
    public Clone(override: Partial<GeneratedDocumentsEntity> = {}): GeneratedDocumentsEntity {
        const attr = { ...this, ...override };
        return Object.assign(new GeneratedDocumentsEntity(), attr);
    }
    get DisplayName() {
        return this.Name;
    }
}

export function GeneratedDocumentsEntityFromBackend(res: GeneratedDocumentsType): GeneratedDocumentsEntity {
    return new GeneratedDocumentsEntity(
        res.id,
        res.partnerId,
        res.commissionId,
        res.customerId,
        res.generatedFile,
        res.createdAt ? BackendDate(res.createdAt) : null,
        res.updatedAt ? BackendDate(res.updatedAt) : null,
        res.deleted,
        res.generatedDocumentsTypeId,
        res.name,
        res.additionalData as any,
        res.documentId,
        res.state ? getDocumentStateFromGQLDocumentState(res.state) : null,
        res.resourceId,
    );
}
