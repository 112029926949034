import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import {Pipe, PipeTransform} from '@angular/core';
import * as farModule from '@fortawesome/free-regular-svg-icons';
import * as fasModule from '@fortawesome/free-solid-svg-icons';

export const AllSolidIcons = fasModule;
export const AllRegularIcons = farModule;

@Pipe({
    name: 'icon',
    pure: true
})
export class IconPipe implements PipeTransform {
    transform(iconString: string): IconProp | null {
        if (!iconString) {
            return null;
        }
        const iconSplit = iconString.split(',').map(s => s.trim());

        const iconDefinition =
            iconSplit.length === 1 // wenn kein Prefix, ist es ein fasIcon
                ? fasModule[iconSplit[0]]
                : iconSplit.length === 2 // wenn Prefix, testen
                ? iconSplit[0] === 'fas'
                    ? fasModule[iconSplit[1]] // entweder fasIcon
                    : iconSplit[0] === 'far'
                    ? farModule[iconSplit[1]] // oder farIcon
                    : null
                : null;
        // alles mit mehr als 2 Splits oder einem anderen Prefix wird nicht unterstützt.

        if (iconDefinition) {
            return iconDefinition;
        } else {
            console.warn(`Icon "${iconString}" nicht gefunden`);
            return iconString as IconProp;
        }
    }
}
