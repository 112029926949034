import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { State } from '../State';
import { AccountsReceivableLedgerTemplateActionTypes } from '../State/actions/accounting.actions';
import { getAccountsReceivableLedgerTemplates } from '../State/selectors/accounting.selector';
import { AccountsReceivableLedgerTemplateEntity } from '../entities/accounts-receivable-ledger-template.entity';

@Injectable({
    providedIn: 'root',
})
export class AccountsReceivableLedgerTemplateResolver implements Resolve<AccountsReceivableLedgerTemplateEntity[]> {
    constructor(private store: Store<State>) {}

    resolve() {
        const value$ = this.store.select(getAccountsReceivableLedgerTemplates);
        value$
            .pipe(
                filter(value => !value),
                take(1),
                tap(() => {
                    if (localStorage.getItem('token') != null) {
                        this.store.dispatch(AccountsReceivableLedgerTemplateActionTypes.GetAccountsReceivableLedgerTemplate());
                    }
                }),
            )
            .subscribe();

        return value$.pipe(
            filter(value => !!value),
            take(1),
        );
    }
}
