import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DaveEventListEntryModule } from '../../../../dave-event-list-entry/dave-event-list-entry.module';
import { AppButtonModule } from '../../../../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../../../../dave-utils-module/app-filter-module/app-filter.module';
import { DetailCommissionEventListComponent } from './detail-commission-event-list.component';

@NgModule({
    declarations: [DetailCommissionEventListComponent],
    exports: [DetailCommissionEventListComponent, DetailCommissionEventListComponent],
    imports: [
        CommonModule,
        ScrollingModule,
        FontAwesomeModule,
        MatTabsModule,
        AppButtonModule,
        MatExpansionModule,
        MatTooltipModule,
        MatMenuModule,
        MatCardModule,
        DaveEventListEntryModule,
        MatProgressBarModule,
        AppFilterModule,
        MatBadgeModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
    ],
})
export class DetailCommissionEventListModule {}
