import { createActionGroup, props } from '@ngrx/store';
import {
    DaveMutationChangeSalaryTypeArgs,
    DaveMutationCreateSalaryTypeArgs,
    DaveMutationDeleteSalaryTypeArgs, QuerySalaryTypeArgs,
} from '../../graphql-types';
import { SalaryTypeEntity } from "../../entities/salary-type.entity";

export const SalaryTypeActions = createActionGroup({
    source: 'SalaryType',
    events: {
        'Update All': props<{ Payload: SalaryTypeEntity[], updateLatestUpdatedAt?: boolean }>(),
        'Update Many': props<{ Payload: SalaryTypeEntity[], updateLatestUpdatedAt?: boolean }>(),
        'Update One': props<{ Payload: SalaryTypeEntity }>(),
        'Remove One': props<{ Payload: number }>(),

        'Load': props<{ Payload: QuerySalaryTypeArgs }>(),
        'Add': props<{ Payload: DaveMutationCreateSalaryTypeArgs }>(),
        'Change': props<{ Payload: DaveMutationChangeSalaryTypeArgs }>(),
        'Delete': props<{ Payload: DaveMutationDeleteSalaryTypeArgs }>(),
    },
});
