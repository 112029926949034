import { createFeatureSelector, createSelector } from '@ngrx/store';
import {

} from '../reducers/clock-in.reducer';
import { State } from '../index';
import {
    clockInTypesFeatureKey, ClockInTypesState,
    selectAllClockInType,
    selectClockInTypeEntities,
    selectClockInTypeFetched,
} from '../reducers/clock-in-type.reducer';

const selectClockInType = createFeatureSelector< ClockInTypesState>(clockInTypesFeatureKey);
const getClockInTypeEntitys = createSelector(selectClockInType, selectClockInTypeEntities);

export const getClockInTypeDictionary = getClockInTypeEntitys;
export const getClockInTypes = createSelector(selectClockInType, selectAllClockInType);
export const getClockInTypesFetched = createSelector(selectClockInType, selectClockInTypeFetched);
export const getClockInTypeById = (props: { id: number }) => createSelector(getClockInTypeEntitys, (dictionary) => props.id && dictionary[props.id]);
