import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import {ShiftplanViews} from "../../scheduler.helper";
import {ResourcePlanFilter} from "../resource-plan.component";
export interface ResourcePlanComponentDialogData {
    CommissionId?: number;
    View?: ShiftplanViews;
    DefaultFilter?: ResourcePlanFilter;

}

@Component({
    selector: 'app-resource-plan-dialog',
    template: `
        <button mat-icon-button class="close-button" (click)="MatDialogRef.close(ViewStyle)">
            <fa-icon icon="times"></fa-icon>
        </button>
        <app-resource-plan *ngIf="Dialogdata" [ShowShiftplanButton]="false" (OnViewChange)="ViewStyle = $event" [CommissionId]="Dialogdata.CommissionId" [View]="Dialogdata.View"></app-resource-plan>
    `,
    styleUrls: ['./resource-plan-dialog.component.scss'],
})
export class ResourcePlanDialogComponent implements OnInit {
    public static DefaultConfig: MatDialogConfig = {
        height: '90vh',
        minWidth: '90vw',
        panelClass: ['custom-dialog-class-mobile-fullscreen', 'mat-dialog-with-close-button'],
    };
    public ViewStyle: ShiftplanViews = 'Timeline3Month';
    constructor(@Inject(MAT_DIALOG_DATA) public Dialogdata: ResourcePlanComponentDialogData, public MatDialogRef: MatDialogRef<ResourcePlanDialogComponent>) {}
    ngOnInit(): void {
        if (this.Dialogdata.View !== undefined) {
            this.ViewStyle = this.Dialogdata.View;
        }
    }
}
