import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { ProcessActions } from '../State/actions/process.actions';
import { getProcessFetched, getProcessLatestUpdatedAt } from '../State/selectors/process.selector';
import { BaseEntityReloadResolver2 } from './resolver.helper';
import { GetTimestampFromTime } from "@dave/types";

@Injectable({
    providedIn: 'root',
})
export class ProcessResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getProcessFetched, getProcessLatestUpdatedAt, ProcessActions.load, store, (d) => GetTimestampFromTime(d)+'');
    }
}
