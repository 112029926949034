import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextareaComponent } from './textarea.component';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
    declarations: [TextareaComponent],
    imports: [
        CommonModule,
        MatInputModule,
        ReactiveFormsModule,
    ],
    exports: [
        TextareaComponent,
    ],
})
export class TextareaModule { }
