import { Injectable } from '@angular/core';
import { GetTimestampFromTime } from '@dave/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, EMPTY, firstValueFrom, from, mergeMap, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { MaterialListEntityFromBackend } from '../../entities/material-list.entity';
import { AppGatewayService } from '../../services/app-gateway.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { MaterialListActionTypes } from '../actions/material-list.actions';
import { State } from '../index';
import { getMaterialListDictionary } from '../selectors/material-list.selector';

enum ErrorCodes {
    Add = 'MaterialList Hinzufügen fehlgeschlagen',
    Load = 'MaterialList Abrufen fehlgeschlagen',
    Modify = 'MaterialList Bearbeiten fehlgeschlagen',
    Remove = 'MaterialList Löschen fehlgeschlagen',
}

@Injectable()
export class MaterialListEffects {
    ModifyMaterialList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MaterialListActionTypes.Change),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ErpMaterialListEdit: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return MaterialListActionTypes.UpdateOne({ Payload: MaterialListEntityFromBackend(res.ErpMaterialListEdit.MaterialListResponse) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );

    DeleteMaterialList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MaterialListActionTypes.Delete),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ErpMaterialListDelete: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return MaterialListActionTypes.RemoveOne({ Payload: +Payload.Id });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );

    AddMaterialList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MaterialListActionTypes.CreateRequest),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ErpMaterialListAdd: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return MaterialListActionTypes.CreateSuccess({
                                Payload: MaterialListEntityFromBackend(res.ErpMaterialListAdd.MaterialListResponse),
                            });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        MaterialListActionTypes.CreateFailure({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Errors: err,
                            },
                        }),
                    ),
            ),
        ),
    );

    LoadMaterialLists = createEffect(() => {
        return this.actions$.pipe(
            ofType(MaterialListActionTypes.Load),
            switchMap(({ Payload }) => {
                const requestPayload = isNotNullOrUndefined(Payload?.updatedSince) ? { UpdatedAt: Payload.updatedSince, WithDeleted: true } : { WithDeleted: true };

                return from(this.gatewayService.Request({ ErpMaterialListGet: requestPayload })).pipe(
                    mergeMap((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            if (!isNotNullOrUndefined(Payload.updatedSince)) {
                                return of(
                                    MaterialListActionTypes.UpdateAll({
                                        Payload: res.ErpMaterialListGet.MaterialListResponses.map((val) => MaterialListEntityFromBackend(val)),
                                        updateLatestUpdatedAt: true,
                                    }),
                                );
                            } else if (res.ErpMaterialListGet.MaterialListResponses.length) {
                                return from(firstValueFrom(this.store$.select(getMaterialListDictionary))).pipe(
                                    mergeMap((MaterialLists) => {
                                        const filteredMaterialLists = res.ErpMaterialListGet.MaterialListResponses.filter((MaterialList) => {
                                            const fromState = MaterialLists[MaterialList.Id];
                                            return !fromState || GetTimestampFromTime(fromState.UpdatedAt).toString() !== MaterialList.UpdatedAt;
                                        });

                                        if (filteredMaterialLists.length) {
                                            return of(
                                                MaterialListActionTypes.UpdateMany({
                                                    Payload: filteredMaterialLists.map((val) => MaterialListEntityFromBackend(val)),
                                                    updateLatestUpdatedAt: true,
                                                }),
                                            );
                                        } else {
                                            return EMPTY;
                                        }
                                    }),
                                );
                            }
                            return EMPTY;
                        } else {
                            throw res.Errors;
                        }
                    }),
                    catchError((err) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                },
                            }),
                        ),
                    ),
                );
            }),
        );
    });
    error$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MaterialListActionTypes.CreateFailure),
            map(({ Payload }) =>
                BaseActionTypes.ErrorAction({
                    Payload: {
                        ToasterMessage: Payload.ToasterMessage,
                        Err: Payload.Errors,
                    },
                }),
            ),
        ),
    );
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayService: AppGatewayService) {}
}
