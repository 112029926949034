import { createActionGroup, props } from '@ngrx/store';
import { ActivityEntity } from '../../entities/activity.entity';
import {
    DaveMutationChangeActivityArgs,
    DaveMutationCreateActivityArgs,
    DaveMutationDeleteActivityArgs, QueryActivityArgs,
} from '../../graphql-types';

export const ActivityActions = createActionGroup({
    source: 'Activity',
    events: {
        'Update All': props<{ Payload: ActivityEntity[], updateLatestUpdatedAt?: boolean }>(),
        'Update Many': props<{ Payload: ActivityEntity[], updateLatestUpdatedAt?: boolean }>(),
        'Update One': props<{ Payload: ActivityEntity }>(),
        'Remove One': props<{ Payload: number }>(),

        'Load': props<{ Payload: QueryActivityArgs }>(),
        'Add': props<{ Payload: DaveMutationCreateActivityArgs }>(),
        'Change': props<{ Payload: DaveMutationChangeActivityArgs }>(),
        'Delete': props<{ Payload: DaveMutationDeleteActivityArgs }>(),
    },
});
