import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { HttpService } from '../../services/http.service';
import { BaseActionTypes } from '../actions/base.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { OAuthTokenActionTypes } from '../actions/o-auth-token.actions';
import { OAuthTokenEntity, OAuthTokenEntityFromBackend } from '../../entities/o-auth-token.entity';

enum ErrorCodes {
    Load = 'OAuth Token Abrufen fehlgeschlagen',
    Modify = 'OAuth Token Bearbeiten fehlgeschlagen',
    Remove = 'OAuth Token Löschen fehlgeschlagen',
    Add = 'OAuth Token Hinzufügen fehlgeschlagen',
}

@Injectable()
export class OAuthTokenEffects {
    GetOAuthToken$ = createEffect(() => this.actions$.pipe(
        ofType(OAuthTokenActionTypes.Load),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([action, token]) => {
            const queryString = `
            query {
                oauthtokens {
                    ${OAuthTokenEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                map(res => {
                    return res && res.oauthtokens
                        ? OAuthTokenActionTypes.UpdateAll({
                              Payload: res.oauthtokens.map(e => OAuthTokenEntityFromBackend(e)),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
