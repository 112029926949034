import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { State } from '../State';
import { documentDetectionFeatureKey } from '../State/reducers/detection.reducer';
import {DetectionEntity} from '../entities/detection.entity';
import { ResolverLoadDetections } from '../State/actions/resolver.actions';

@Injectable({
    providedIn: 'root'
})
export class DetectionResolver implements Resolve<DetectionEntity[]> {
    constructor(private store: Store<State>) {}

    resolve() {
        const value$ = this.store.select(state => state[documentDetectionFeatureKey].detection);
        value$
            .pipe(
                filter(value => !value),
                take(1),
                tap(() => {
                    if (localStorage.getItem('token') != null) {
                        this.store.dispatch(new ResolverLoadDetections());
                    }
                })
            )
            .subscribe();

        return value$.pipe(
            filter(value => !!value),
            take(1)
        );
    }
}
