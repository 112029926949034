<fa-icon
    [icon]="GetIcon(20)"
    [class.clickable]="Clickable"
    (click)="Rate(20)"
    [size]="OwnRating === 20 ? 'lg' : '1x'"
></fa-icon>
<fa-icon
    [icon]="GetIcon(40)"
    [class.clickable]="Clickable"
    (click)="Rate(40)"
    [size]="OwnRating === 40 ? 'lg' : '1x'"
></fa-icon>
<fa-icon
    [icon]="GetIcon(60)"
    [class.clickable]="Clickable"
    (click)="Rate(60)"
    [size]="OwnRating === 60 ? 'lg' : '1x'"
></fa-icon>
<fa-icon
    [icon]="GetIcon(80)"
    [class.clickable]="Clickable"
    (click)="Rate(80)"
    [size]="OwnRating === 80 ? 'lg' : '1x'"
></fa-icon>
<fa-icon
    [icon]="GetIcon(100)"
    [class.clickable]="Clickable"
    (click)="Rate(100)"
    [size]="OwnRating === 100 ? 'lg' : '1x'"
></fa-icon>
<fa-icon *ngIf="RatingCount" icon="user" style="margin-left: 1rem"></fa-icon>
<p *ngIf="RatingCount">{{ RatingCount | number }}</p>
