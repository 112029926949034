import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';

const resizableMimeTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/tiff']
export class PublicVersionEntity {
    constructor(
        public Id: number = null,
        public FileId: number = null,
        public UserId: number = null,
        public Size: number = null,
        public MimeType: string = null,
        public Number: number = null,
        public Width: number = null,
        public Height: number = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
    ) {}

    public get IsResizable() {
        return resizableMimeTypes.includes(this.MimeType);
    }
    public get DownloadPath() {
        return 'public-files/' + this.Id;
    }
    public Clone(override: Partial<PublicVersionEntity> = {}): PublicVersionEntity {
        const attr = { ...this, ...override };
        return Object.assign(new PublicVersionEntity(), attr);
    }
}
export function PublicVersionEntityFromBackend(res: PublicVersionType): PublicVersionEntity {
    return new PublicVersionEntity(
        res.id,
        res.file_id,
        res.user_id,
        res.size,
        res.mime_type,
        res.number,
        res.width,
        res.height,
        res.created_at && BackendDateTimestamp(res.created_at),
        res.updated_at && BackendDateTimestamp(res.updated_at),
        res.deleted_at && BackendDateTimestamp(res.deleted_at),
    );
}
export type PublicVersionType = {
    id: number;
    file_id: number;
    user_id: number;
    size: number;
    mime_type: string;
    number: number;
    width: number;
    height: number;
    created_at: string;
    updated_at: string;
    deleted_at: null;
};
