import { CommonModule,formatCurrency,formatDate } from "@angular/common";
import { ChangeDetectionStrategy,Component,Inject,Input,LOCALE_ID } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { ChangeType, EntityType, GetTimeFromTimestamp } from "@dave/types";
import { Process } from "@dave/types/dist/proto/process/process";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Store } from "@ngrx/store";
import { BehaviorSubject,Observable,of,switchMap } from "rxjs";
import { map,shareReplay } from "rxjs/operators";
import { ChangeEntity,ChangeTypeIcons,ChangeTypeNames } from "../dave-data-module/entities/change.entity";
import { CommissionEntity } from "../dave-data-module/entities/commission.entity";
import { CustomerEntity } from "../dave-data-module/entities/customer.entity";
import { FileEntity } from "../dave-data-module/entities/file.entity";
import { FolderEntity } from "../dave-data-module/entities/folder.entity";
import { ProcessEntity } from "../dave-data-module/entities/process.entity";
import { EntityNameMap } from "../dave-data-module/helper/helper";
import { State } from "../dave-data-module/State";
import { getCommissionById,getCommissionsFetched } from "../dave-data-module/State/selectors/commission.selector";
import { getCustomerById,getCustomersFetched } from "../dave-data-module/State/selectors/customers.selectors";
import { getFileById } from "../dave-data-module/State/selectors/files.selectors";
import { getFolderById } from "../dave-data-module/State/selectors/folder.selectors";
import { getProcessById } from "../dave-data-module/State/selectors/process.selector";
import { DaveLoadingModule } from "../dave-loading/dave-loading.module";
import { CustomLabelService } from "../services/custom-label.service";
import { BackendDateTimestamp } from "../dave-data-module/helper/backend-frontend-conversion.helper";

const processEntityHumanReadableProperTies: Array<keyof Process> = ['Name', 'Description', 'Description2'];
const processEntityDateProperTies: Array<keyof Process> = ['StartDate', 'EndDate'];

const commissionEntityHumanReadableProperTies: Array<keyof CommissionEntity> = [
    'Description',
    'Versicherungsbedingung',
    'InterneNummer',
    'Auftragsnummer',
    'Aktenzeichen',
    'VersicherungsName',
    'VersicherungsDescription',
    'VersicherungsZusatzName',
    'VersicherungsCity',
    'VersicherungsStreet',
    'VersicherungsPostalCode',
    'VersicherungsMobileNumber',
    'VersicherungsPhoneNumber',
    'VersicherungsArbeitsgebiet',
    'Schadennummer',
    'Versicherungsnummer',
    'CustomerName',
    'City',
    'PostalCode',
    'Street',
    'Country',
    'Versicherungssumme',
    'FreeTextField1',
    'FreeTextField2',
    'FreeTextField3',
    'OrderNoCustomer',
    'CostCenter',
];
const commissionEntityDateProperTies: Array<keyof CommissionEntity> = ['Schadensdatum', 'AuftragseingangDurchAuftraggeber', 'AbgabeterminAG', 'AbgabeterminQM', 'LastDeadline', 'PlannedStartDate', 'StartDate', 'PlannedEndDate', 'EndDate'];
const commissionEntityCurrencyProperTies: Array<keyof CommissionEntity> = ['CompleteBusinessVolume', 'CostsCompleteBusinessVolume',];

const customerEntityHumanReadableProperties: Array<keyof CustomerEntity> = ['Name', 'Description', 'Sales', 'EmployeeNumber', 'Homepage', 'Steuernummer', 'DebitorNo'];
const folderEntityHumanReadableProperties: Array<keyof FolderEntity> = ['Name'];
const fileEntityHumanReadableProperties: Array<keyof FileEntity> = ['Name', 'Description'];
const getChangeDataHumanReadableValues = (key: string, v: ChangeEntity): {oldValue$: Observable<string>, newValue$: Observable<string>} => ({
    oldValue$: of(v.OldValue[key] && (v.OldValue[key] + '')),
    newValue$: of(v.NewValue[key] && (v.NewValue[key] + '')),
});
const getChangeDataDateValues = (key: string, v: ChangeEntity, format: 'milliseconds' | 'ISO_8601' = 'milliseconds'): {oldValue$: Observable<string>, newValue$: Observable<string>} => {
    let parse = (v: string) => GetTimeFromTimestamp(+v);
    if (format === 'ISO_8601') {
        parse = BackendDateTimestamp;
    }

    return {
        oldValue$: of(v.OldValue[key] ? formatDate(parse(v.OldValue[key]), 'dd.MM.yyyy', 'de-DE') : null),
        newValue$: of(v.NewValue[key] ? formatDate(parse(v.NewValue[key]), 'dd.MM.yyyy', 'de-DE') : null),
    }
};
const getChangeDataCurrencyValues = (key: string, v: ChangeEntity, faktor = 1, locale: string = 'de-DE', currency: string = '€', currencyCode?: string): {oldValue$: Observable<string>, newValue$: Observable<string>} => ({
    oldValue$: of(v.OldValue[key] ? formatCurrency(v.OldValue[key] * faktor, locale, currency, currencyCode) : null),
    newValue$: of(v.NewValue[key] ? formatCurrency(v.NewValue[key] * faktor, locale, currency, currencyCode) : null),
});
@Component({
    selector: 'app-change-list-entry',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, MatIconModule, MatListModule, DaveLoadingModule],
    templateUrl: './change-list-entry.component.html',
    styleUrls: ['./change-list-entry.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeListEntryComponent {
    icon: IconProp;
    changeEntity$ = new BehaviorSubject<ChangeEntity | null>(null);
    unseen$ = this.changeEntity$.pipe(map((entity) => !entity.LastSeenAt));
    entityName$ = this.changeEntity$.pipe(
        switchMap((change) => {
            if (!change) {
                return of(null);
            }
            switch (change.Entity) {
                case EntityType.Process:
                    return this.store.select(getProcessById({ id: change.EntityId })).pipe(map((p) => (p ? p.DisplayName : ProcessEntity.EntityName + ' unbekannt')));
                case EntityType.Commission:
                    return this.store.select(getCommissionById({ id: change.EntityId })).pipe(switchMap((p) => (p ? of(p.DisplayName) : this.cls.getSingle$('Commission').pipe(map((l) => l + ' unbekannt')))));
                case EntityType.Customer:
                    return this.store.select(getCustomerById({ id: change.EntityId })).pipe(switchMap((p) => (p ? of(p.DisplayName) : this.cls.getSingle$('Customer').pipe(map((l) => l + ' unbekannt')))));
                case EntityType.Folder:
                    return this.store.select(getFolderById({ id: change.EntityId })).pipe(map((p) => (p ? p.Name : EntityNameMap.get(EntityType.Folder) + ' unbekannt')));
                case EntityType.Document:
                    return this.store.select(getFileById({ id: change.EntityId })).pipe(map((p) => (p ? p.Name : EntityNameMap.get(EntityType.Document) + ' unbekannt')));
                default:
                    return of(null);
            }
        }),
    );
    protected typeLabel$ = this.changeEntity$.pipe(
        switchMap((change) => {
            let typeLabel = '';
            if (ChangeTypeNames.has(change.Type)) {
                typeLabel += ChangeTypeNames.get(change.Type);
            }
            const unshiftEntityLabel = (entityType: EntityType) => {
                if (EntityNameMap.has(entityType)) {
                    typeLabel = EntityNameMap.get(entityType) + ' ' + typeLabel;
                } else if (change.Entity === EntityType.Commission) {
                    return this.cls.getSingle$('Commission').pipe(map((name) => (typeLabel = name + ' ' + typeLabel)));
                } else if (change.Entity === EntityType.Customer) {
                    return this.cls.getSingle$('Customer').pipe(map((name) => (typeLabel = name + ' ' + typeLabel)));
                }
                return of(typeLabel);
            };
            if (change.Type === ChangeType.Removed || change.Type === ChangeType.Added) {
                return unshiftEntityLabel(change.ReferredEntity);
            } else {
                return unshiftEntityLabel(change.Entity);
            }
        }),
    );
    protected changedData$ = this.changeEntity$.pipe(
        switchMap((v) => {
            const changeData: Array<{ label: string; oldValue$?: Observable<string>; newValue$?: Observable<string>; oldValueLoading$?: Observable<boolean>; newValueLoading$?: Observable<boolean> }> = [];
            if (v.OldValue && v.NewValue) {
                switch (v.Entity) {
                    case EntityType.Process:
                        Object.keys(v.OldValue).forEach((key: keyof Process) => {
                            const label = ProcessEntity.EntityPropertyNames.get(key as keyof ProcessEntity);
                            if (processEntityHumanReadableProperTies.includes(key)) {
                                changeData.push({ label, ...getChangeDataHumanReadableValues(key, v) });
                            } else if (processEntityDateProperTies.includes(key)) {
                                changeData.push({ label, ...getChangeDataDateValues(key, v) });
                            } else if (key === 'ActiveStep') {
                                changeData.push({ label, oldValue$: of(v.OldValue[key] + 1 + ''), newValue$: of(v.NewValue[key] + 1 + '') });
                            } else if (key === 'CustomerId') {
                                const loading$ = this.store.select(getCustomersFetched).pipe(map((v) => !v));
                                changeData.push({
                                    label,
                                    oldValue$: v.OldValue[key] ? this.store.select(getCustomerById({ id: v.OldValue[key] })).pipe(map((c) => (c ? c.DisplayName : 'unbekannt'))) : of(null),
                                    newValue$: v.NewValue[key] ? this.store.select(getCustomerById({ id: v.NewValue[key] })).pipe(map((c) => (c ? c.DisplayName : 'unbekannt'))) : of(null),
                                    oldValueLoading$: !!v.OldValue[key] ? loading$ : undefined,
                                    newValueLoading$: !!v.NewValue[key] ? loading$ : undefined,
                                });
                            } else if (key === 'CommissionId') {
                                const loading$ = this.store.select(getCommissionsFetched).pipe(map((v) => !v));
                                changeData.push({
                                    label,
                                    oldValue$: v.OldValue[key] ? this.store.select(getCommissionById({ id: v.OldValue[key] })).pipe(map((c) => (c ? c.DisplayName : 'unbekannt'))) : of(null),
                                    newValue$: v.NewValue[key] ? this.store.select(getCommissionById({ id: v.NewValue[key] })).pipe(map((c) => (c ? c.DisplayName : 'unbekannt'))) : of(null),
                                    oldValueLoading$: !!v.OldValue[key] ? loading$ : undefined,
                                    newValueLoading$: !!v.NewValue[key] ? loading$ : undefined,
                                });
                            } else if (key === 'DocumentIds') {
                                changeData.push({ label, oldValue$: of(v.OldValue['DocumentIds']?.length || 0), newValue$: of(v.NewValue['DocumentIds']?.length || 0) });
                            } else if (label) {
                                changeData.push({ label: label + ' bearbeitet' });
                            }
                        });
                        break;
                    case EntityType.Commission:
                        Object.keys(v.OldValue).forEach((key: keyof CommissionEntity) => {
                            const label = CommissionEntity.EntityPropertyNames.get(key);
                            if (commissionEntityHumanReadableProperTies.includes(key)) {
                                changeData.push({ label, ...getChangeDataHumanReadableValues(key, v) });
                            } else if (commissionEntityDateProperTies.includes(key)) {
                                changeData.push({ label, ...getChangeDataDateValues(key, v, 'ISO_8601') });
                            } else if (commissionEntityCurrencyProperTies.includes(key)) {
                                changeData.push({ label, ...getChangeDataCurrencyValues(key, v, 0.01, this.locale) });
                            }
                        });
                        break;
                    case EntityType.Customer:
                        Object.keys(v.OldValue).forEach((key: keyof CustomerEntity) => {
                            const label = CustomerEntity.EntityPropertyNames.get(key);
                            if (customerEntityHumanReadableProperties.includes(key)) {
                                changeData.push({ label, ...getChangeDataHumanReadableValues(key, v) });
                            }
                        });
                        break;
                    case EntityType.Folder:
                        Object.keys(v.OldValue).forEach((key: keyof FolderEntity) => {
                            const label = FolderEntity.EntityPropertyNames.get(key);
                            if (folderEntityHumanReadableProperties.includes(key)) {
                                changeData.push({ label, ...getChangeDataHumanReadableValues(key, v) });
                            }
                        });
                        break;
                    case EntityType.Document:
                        Object.keys(v.OldValue).forEach((key: keyof FileEntity) => {
                            const label = FileEntity.EntityPropertyNames.get(key);
                            if (fileEntityHumanReadableProperties.includes(key)) {
                                changeData.push({ label, ...getChangeDataHumanReadableValues(key, v) });
                            }
                        });
                        break;
                }
            }
            return of(changeData);
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    @Input() set ChangeEntity(v: ChangeEntity) {
        this.icon = ChangeTypeIcons.get(v.Type);
        this.changeEntity$.next(v);
    }
    constructor(private store: Store<State>, private cls: CustomLabelService, @Inject(LOCALE_ID) public locale: string) {}
}
