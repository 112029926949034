import {
    selectAllWorkDay,
    selectWorkDayEntities,
    selectWorkDayFetched,
    selectWorkDayLatestUpdatedAt,
    workDaysFeatureKey,
    WorkDaysState,
} from '../reducers/work-day.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';


const selectWorkDay = createFeatureSelector< WorkDaysState>(workDaysFeatureKey);
const getWorkDayEntitys = createSelector(selectWorkDay, selectWorkDayEntities);

export const getWorkDays = createSelector(selectWorkDay, selectAllWorkDay);
export const getWorkDayDictionary = getWorkDayEntitys
export const getWorkDaysFetched = createSelector(selectWorkDay, selectWorkDayFetched);
export const getWorkDayLatestUpdatedAt = createSelector(selectWorkDay, selectWorkDayLatestUpdatedAt);
export const getWorkDayById  = (props: { id: number }) => createSelector(getWorkDayEntitys, (data) => props.id && data[props.id]);
