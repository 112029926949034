
<mat-expansion-panel class="custom-mat-expansion-panel" [expanded]="view.expanded" *ngIf="[] as countArray">
    <mat-expansion-panel-header [collapsedHeight]="HeaderHeight">
        <div class="card-header">
            <fa-icon *ngIf="(view.headlineIcon || InvoiceEditorMeta.Icon) as icon" class="icon" [icon]="icon"></fa-icon>
            <h3>{{ view.headline || 'Dokumente' }}<span *ngIf="countArray.length"> ({{ArraySum(countArray)}})</span></h3>
            <!--                <ng-container *ngIf="headerButtonTemplate" [ngTemplateOutlet]="headerButtonTemplate"></ng-container>-->
        </div>
    </mat-expansion-panel-header>
    <mat-tab-group>
        <mat-tab *ngFor="let types of ledgerImportListData; index as index" [label]="types.documentTypeLabel" #tab>
            <!--                <ng-container [ngTemplateOutlet]="GetTemplateByType(childView.type)" [ngTemplateOutletContext]="{ view: childView, index, countArray, tab }"></ng-container>-->
            <app-dave-list-card
                (Count)="countArray ? countArray[index] = $event : null"
                [Headline]="types.documentTypeLabel"
                [HeaderIcon]="InvoiceEditorMeta.Icon"
                [EmptyText]="'Keine ' + types.documentTypeLabel + ' vorhanden'"
                [Data]="types.ListCardData$"
                [HasSuffixButton]="ShowDocumentButton"
                [DisplayedCollums]="['ConsolidatedInvoiceId', 'DueDate','Sum','Notes']"
                [TableHeaderCollumsMap]="{ ConsolidatedInvoiceId: LedgerImportDocumentTypeNumberLabel.get(types.documentTypes[0]), DueDate: 'Frist', Sum: 'Summe', Notes:'Notiz' }"
                [Clickable]="true"
                [GetRouterLinkFunction]="NavigateOnClick ? GetRouteForLedgerImport : null"
                [IsExpandable]="true"
                [Expanded]="true"
                (EntryClicked$)="LedgerImportClick.emit($event)"
                style="max-height: 50vh"
            >
                <button headerButton app-round-button [matMenuTriggerFor]="types.documentTypes.length > 1 ? newInvoiceMenu : null" (click)="types.documentTypes.length === 1 ? NewLedgerImportClick.emit(types.documentTypes[0]) : {}" [matTooltip]="types.documentTypeLabel + ' hinzufügen'" *ngIf="PS.Has(PS.Permission.CreateLedgerImports) | async">
                    <app-double-icon [Icon]="InvoiceEditorMeta.Icon" Icon2="plus"></app-double-icon>
                </button>
                <mat-menu #newInvoiceMenu="matMenu">
                    <button mat-menu-item *ngFor="let t of types.documentTypes" (click)="NewLedgerImportClick.emit(t)">{{LedgerImportDocumentTypeNames.get(t)}}</button>
                </mat-menu>
            </app-dave-list-card>
        </mat-tab>
    </mat-tab-group>
</mat-expansion-panel>
