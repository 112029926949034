import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { CommentActionTypes } from '../State/actions/comment.action';
import { getCommentFetched, getCommentLatestUpdatedAt } from '../State/selectors/comment.selector';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class CommentResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getCommentFetched, getCommentLatestUpdatedAt, CommentActionTypes.Load, store);
    }
}
