import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { EmployeeActionTypes } from '../State/actions/employee.actions';
import { getEmployeeLatestUpdatedAt, getEmployeesFetched } from '../State/selectors/employees.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class EmployeeResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getEmployeesFetched, getEmployeeLatestUpdatedAt, EmployeeActionTypes.Load, store);
    }
}
