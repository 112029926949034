import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { IconPipeModule } from '../dave-utils-module/icon-pipe/icon-pipe.module';
import { DocumentListCardComponent } from '../process/components/document-list-card/document-list-card.component';
import { AppButtonModule } from './../dave-utils-module/app-button-module/app-button.module';
import { DetailListTemplateDialogComponent } from './components/detail-list-template-dialog.component';
import { EditGegenkontoComponent } from './edit-gegenkonto/edit-gegenkonto.component';

@NgModule({
    declarations: [DetailListTemplateDialogComponent, EditGegenkontoComponent],
    imports: [CommonModule, MatDialogModule, AppButtonModule, DaveSharedComponentsModule, MatIconModule, IconPipeModule, FontAwesomeModule, MatTooltipModule, DocumentListCardComponent, MatMenuModule],
    exports: [EditGegenkontoComponent],
})
export class DetailListTemplateDialogModule {}
