import { createReducer, on } from '@ngrx/store';
import { LicensesActionTypes } from '../actions/licenses.actions';
import { LicensePackageEntity } from '../../entities/license-package.entity';
import { LicenseEntity } from '../../entities/license.entity';

export const licensesFeatureKey = 'licenses';

export interface LicensesState {
    licenses: LicenseEntity[];
    licensePackages: LicensePackageEntity[];
    licensList: LicenseEntity[];
}

export const initialState: LicensesState = {
    licenses: null,
    licensePackages: null,
    licensList: null,
};

export const licensesReducer = createReducer(
    initialState,
    on(LicensesActionTypes.UpdateLicense, (state, { Payload }) => ({
        ...state,
        licenses: Payload,
    })),
    on(LicensesActionTypes.UpdateLicensePackage, (state, { Payload }) => ({
        ...state,
        licensePackages: Payload,
    })),
    on(LicensesActionTypes.UpdateAllLicenses, (state, { Payload }) => ({
        ...state,
        licensList: Payload,
    })),
);
