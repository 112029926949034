<mat-card class="filter-card" [class.max-height]="!unlimitedHeight" [class.fly-top-right]="!Inline" [class.max-width-hundert-percent]="maxWidthHundertPercent" *ngIf="ShowFilter">
    <div class="button-wrapper">
        <button app-round-button (click)="ShowFilter = false">
            <fa-icon icon="times"></fa-icon>
        </button>
<!--        <button app-round-button *ngIf="DefaultValues" (click)="ValuesChange.emit(DefaultValues)">-->
<!--            <fa-icon icon="undo"></fa-icon>-->
<!--        </button>-->
        <button app-round-button (click)="filter.ResetValues()">
            <fa-icon icon="undo"></fa-icon>
        </button>
    </div>
    <app-filter [Settings]="Settings" #filter [Values]="Values" (ValuesChange)="ValuesChange.emit($event)"></app-filter>
</mat-card>
