<h1 mat-dialog-title>
    <fa-icon icon="phone"></fa-icon>
    Telefonat anlegen
</h1>
<form mat-dialog-content [formGroup]="NewEventForm" (ngSubmit)="Submit()">
    <div class="contactPersonWrapper">
        <mat-form-field class="type">
            <mat-select
                [placeholder]="this.Customer ? this.Customer.AnsprechpartnerText() : 'Ansprechpartner / Sachbearbeiter'"
                [formControl]="NewEventForm.controls.Person"
            >
                <mat-option
                    *ngFor="let option of PersonList"
                    [value]="option"
                >
                    {{ContactPersonText(option)}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="NewEventForm.controls.Person.invalid">
                {{ GetErrorMessage(NewEventForm.controls.Person) }}
            </mat-error>
        </mat-form-field>
        <span class="button" [matTooltip]="this.Customer ? '' : (CS.GetSingle$()|async) + ' wählen'">
                <button
                    type="button"
                    app-round-button
                    class="button"
                    [matTooltip]="this.Customer?.AnsprechpartnerText()"
                    (click)="NewContactPerson()"
                >
                <fa-icon icon="plus"></fa-icon>
            </button>
        </span>
    </div>

    <mat-select
        [formControl]="NewEventForm.controls.Time"
    >
        <mat-option [value]="15"> 15 min</mat-option>
        <mat-option [value]="30"> 30 min</mat-option>
        <mat-option [value]="45"> 45 min</mat-option>
        <mat-option [value]="60"> 1h</mat-option>
        <mat-option [value]="75"> 1h 15 min</mat-option>
        <mat-option [value]="90"> 1h 30 min</mat-option>
        <mat-option [value]="105"> 1h 45 min</mat-option>
        <mat-option [value]="120"> 2h</mat-option>
    </mat-select>

    <app-textarea Key="Notiz" [Value]="NewEventForm.controls.Note"></app-textarea>
    <app-textarea Key="Name" [Value]="NewEventForm.controls.Name"></app-textarea>
</form>
<mat-dialog-actions class="buttons">
    <button
        Inverted
        app-button
        mat-dialog-close
        Color="cancel"
    >
        Abbrechen
    </button>

    <button
        app-button
        [Disabled]="NewEventForm.invalid"
        (click)="Submit()"
        mat-dialog-close
    >
        Speichern
    </button>
</mat-dialog-actions>
