import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { FolderEntity, FolderTypes } from '../dave-data-module/entities/folder.entity';
import { FolderDataService } from '../dave-data-module/services/folder-data.service';
import { isNotNullOrUndefined } from '../helper/helper';
import { getPartner } from '../dave-data-module/State/selectors/partners.selectors';
import { PartnerTypeEnum } from '../dave-data-module/entities/partner.entity';
import { Store } from '@ngrx/store';
import { State } from '../dave-data-module/State';

interface folderTreeNodeItem {
    // name: string;
    name$?: Observable<string>;
    isFolder: boolean;
    entityId: number;
}

/** Flat node with expandable and level information */
export class DynamicFolderTreeFlatNode {
    public readonly UId: string;
    constructor(public item: folderTreeNodeItem, public level = 1, public expandable = false, public isLoading = false, public disabled = false) {
        this.UId = item.isFolder ? 'folder|' + item.entityId : 'file|' + item.entityId;
    }
}
/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */
@Injectable({ providedIn: 'root' })
export class DynamicFolderTreeDataService {
    rootLevelNodes: folderTreeNodeItem[] = [{ name$: of('Hauptordner'), entityId: null, isFolder: true }];

    constructor(private folderDataService: FolderDataService, private store: Store<State>) {}

    /** Initial data from database */
    initialData(rootFolderId: number[]): Promise<DynamicFolderTreeFlatNode[]>;
    initialData(): DynamicFolderTreeFlatNode[];
    initialData(rootFolderIds?: number[] | undefined, disabledFolderIds: number[] = []): DynamicFolderTreeFlatNode[] | Promise<DynamicFolderTreeFlatNode[]> {
        if (rootFolderIds?.length) {
            return firstValueFrom(this.folderDataService.getFolderByIds(rootFolderIds)).then((folders) =>
                folders
                    .filter((f) => !(f.Type == FolderTypes.email_attachment && f.ParentId == null))
                    .map(
                        (folder) =>
                            new DynamicFolderTreeFlatNode(
                                {
                                    name$: this.folderDataService.getFolderById(folder.Id, true).pipe(
                                        filter(isNotNullOrUndefined),
                                        map((f) => f.Name),
                                    ),
                                    isFolder: true,
                                    entityId: folder.Id,
                                },
                                0,
                                true,
                                false,
                                disabledFolderIds.includes(folder.Id),
                            ),
                    ),
            );
        }
        return this.rootLevelNodes.map((item) => new DynamicFolderTreeFlatNode(item, 0, true));
    }

    getChildren(node: DynamicFolderTreeFlatNode): Observable<DynamicFolderTreeFlatNode[]> | undefined {
        if (node.item.isFolder) {
            return this.store.select(getPartner).pipe(
                map(p => p.PartnerTypeId === PartnerTypeEnum.TSB),
                distinctUntilChanged(),
                switchMap(isTsb => {
                    if (isTsb) {
                        return this.folderDataService.getFolderByParent(node.item.entityId).pipe(switchMap(folders => {
                            return (node.item.entityId ? this.folderDataService.getFolderById(node.item.entityId, true) : of(null)).pipe(
                                first(),
                                map(parent => {
                                    if (parent?.Type === FolderTypes.commission) {
                                        return folders.filter(f => f.Type !== FolderTypes.construction_diary_root && f.Type !== FolderTypes.event_root && f.Type !== FolderTypes.transmission_root);
                                    } else {
                                        return folders.filter((f) => !(f.Type == FolderTypes.email_attachment && f.ParentId == null));
                                    }
                                }),
                            )
                        }));
                    } else {
                        return this.folderDataService.getFolderByParent(node.item.entityId).pipe(map(folders => folders.filter((f) => !(f.Type == FolderTypes.email_attachment && f.ParentId == null))))
                    }
                })
            ).pipe(
                map((childFolders) =>
                    childFolders
                        .sort((a, b) => (a.Name || '').localeCompare(b.Name || ''))
                        .map(
                            (folder) =>
                                new DynamicFolderTreeFlatNode(
                                    {
                                        name$: this.folderDataService.getFolderById(folder.Id, true).pipe(
                                            filter(isNotNullOrUndefined),
                                            map((f) => f.Name),
                                        ),
                                        entityId: folder.Id,
                                        isFolder: true,
                                    },
                                    node.level + 1,
                                    this.isExpandable(folder),
                                    false,
                                    false /*todo: this.disabledFolderIds.includes(f.Id)*/,
                                ),
                        )
                ),
            );
        } else {
            throw 'not implemented';
        }
    }

    isExpandable(node: FolderEntity): boolean {
        return true; // todo. an die folder schreiben ob die children haben
    }
}
