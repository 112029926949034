<h1 mat-dialog-title>{{ headline$ | async }}</h1>

<app-detail-list-template mat-dialog-content *ngIf="listTemplateData$ | async as data" [Data]="data" [Editing]="true" [Inline]="true"> </app-detail-list-template>
<span *ngIf="formMilestone.value.ClockInTypeId">Ist Stunden: {{ currHours$ | async | number: '1.0-1' }}</span>
<app-progress-card-progress-bar *ngIf="formMilestone.value.TargetHours != null" [progress]="currHours$ | async" [progressTarget]="formMilestone.value.TargetHours"></app-progress-card-progress-bar>
<mat-dialog-actions class="actions">
    <button app-button Inverted mat-dialog-close Color="cancel">Abbrechen</button>
    <button app-round-button Color="red" *ngIf="milestoneId$ | async" (click)="onDeleteClick()" [matTooltip]="'Meilenstein löschen'">
        <fa-icon icon="trash"></fa-icon>
    </button>
    <button app-button [Disabled]="formMilestone.invalid" (click)="onSaveClick()" Color="green">Speichern</button>
</mat-dialog-actions>
