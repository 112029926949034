import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';
import { LedgerImportTemplateType } from '../graphql-types';

export class SfdtTemplateEntity {
    constructor(
        public Id: number = null,
        public PartnerId: number = null,
        public Html: string = null,
        public Name: string = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
    ) {
    }
    public static readonly GqlFields = `
    createdAt
    deletedAt
    id
    name
    partnerId
    updatedAt
    `;// html options
    public Clone(override: Partial<SfdtTemplateEntity> = {}): SfdtTemplateEntity {
        const attr = {...this, ...override};
        return Object.assign(new SfdtTemplateEntity(), attr);
    }
}

export function SfdtTemplateEntityFromBackend(res: LedgerImportTemplateType): SfdtTemplateEntity {
    return new SfdtTemplateEntity(
        res.id,
        res.partnerId,
        res.html,
        res.name,
        res.createdAt  ? BackendDateTimestamp(res.createdAt) : null,
        res.updatedAt ? BackendDateTimestamp(res.updatedAt) : null,
        res.deletedAt ? BackendDateTimestamp(res.deletedAt) : null,
    );
}


