import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
    LedgerImportDocumentTypeNames,
    LedgerImportStatusEnum,
} from 'src/app/dave-data-module/entities/ledger-import.entity';
import { isNotNullOrUndefined } from 'src/app/helper/helper';
import { State } from '../../../dave-data-module/State';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { DatePipe } from '@angular/common';
import { getLedgerImports } from '../../../dave-data-module/State/selectors/ledger-import.selector';

export interface LedgerImportTableData {
    Id: number;
    InvoiceNumber: string;
    Typ: string;
    ConsolidatedDate: string;
}

@Component({
    selector: 'app-select-ledger-import-dialog',
    templateUrl: './select-ledger-import-dialog.component.html',
    styleUrls: ['./select-ledger-import-dialog.component.scss'],
    providers: [DatePipe]
})
export class SelectLedgerImportDialogComponent {
    public Headers = {
        Typ: 'Typ',
        ConsolidatedDate: 'Re-Datum',
        InvoiceNumber: 'Re-Nr.',
    };

    DisplayedColumns$: Observable<string[]>;

    public LITableData$: Observable<LedgerImportTableData[]> = this.store.select(getLedgerImports).pipe(
        filter(isNotNullOrUndefined),
        map(li => {
            const ret = li
                .filter(
                    l => l.Status === LedgerImportStatusEnum.Verrechnet || l.Status === LedgerImportStatusEnum.Gebucht,
                )
                .sort((a, b) => a.ConsolidatedDate?.getTime() - b.ConsolidatedDate?.getTime())
                .map<LedgerImportTableData>(invoice => {
                    return {
                        Id: invoice.Id,
                        Typ: LedgerImportDocumentTypeNames.get(invoice.DocumentType),
                        ConsolidatedDate: this.datePipe.transform(invoice.ConsolidatedDate),
                        InvoiceNumber: invoice.ConsolidatedInvoiceId
                            ? invoice.ConsolidatedInvoiceId
                            : 'unbenannte ' + LedgerImportDocumentTypeNames.get(invoice.DocumentType) + 'snummer',
                    };
                });
            ret.unshift({
                Id: -1,
                Typ: '',
                ConsolidatedDate: null,
                InvoiceNumber: 'Ohne Rechnung',
            });
            return ret;
        }),
    );
    public SelectedLiId = -1;
    constructor(private store: Store<State>, bp: BreakpointObserverService, private datePipe: DatePipe) {
        this.DisplayedColumns$ = bp.MobileQuery.pipe(
            map(mobile =>
                mobile ? ['InvoiceNumber', 'Typ', 'ConsolidatedDate'] : ['InvoiceNumber', 'Typ', 'ConsolidatedDate'],
            ),
        );
    }
}
