import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Mehrwertsteuer } from '../../../../helper/helper';

export interface User2ProductDialogData {
    name: string;
    warn?: string;
    oldCost?: number;
    newCost?: number;
    costPerUser?: number;
}

@Component({
    selector: 'app-user2product-dialog',
    templateUrl: './user2-product-dialog.component.html',
    styleUrls: ['./user2-product-dialog.component.scss'],
})
export class User2ProductDialogComponent {
    public MWST = Mehrwertsteuer;
    constructor(@Inject(MAT_DIALOG_DATA) public Data: User2ProductDialogData) {}
}
