<h1 mat-dialog-title>
    <div>
        <fa-icon icon="stopwatch" style="margin-right: 1rem"></fa-icon>
        Stundenliste
        <button app-round-button style="margin-left: 1rem" (click)="CreateClockIn()" matTooltip="Stempelzeit hinzufügen">
            <fa-icon icon="plus"></fa-icon>
        </button>
    </div>
    <span class="spacer"></span>
    <button
        *ngIf="showFilter"
        app-round-button
        [matBadge]="filterAmount$ | async"
        matTooltip="Filter"
        [matBadgeOverlap]="true"
        [matBadgeHidden]="!(filterAmount$ | async)"
        (click)="filter.Toggle()"
        [Active]="filter.ShowFilter"
    >
        <fa-icon icon="filter"></fa-icon>
    </button>
    <button mat-icon-button matDialogClose matTooltip="Schließen">
        <fa-icon icon="times" size="2x"></fa-icon>
    </button>
</h1>
<app-filter-card UnlimitedHeight #filter [Settings]="filterSettings$ | async" [Values]="filterValues$ | async" (ValuesChange)="filterValues$.next($event)"></app-filter-card>
<mat-dialog-content>
    <ng-container *ngIf="dataFetched; else loading">
        <mat-accordion *ngIf="PanelData | async as panelData">
            <mat-expansion-panel *ngFor="let data of panelData; trackBy: Identifyer" (opened)="ExpandedPanel = data.WeekAndYear" (closed)="ExpandedPanel = null" [expanded]="ExpandedPanel === data.WeekAndYear" hideToggle="true" #panel>
                <mat-expansion-panel-header>
                    <div class="left">
                        <h2>KW {{ data.WeekOfYear }} </h2> <p style="margin-left: .5rem"> ({{ data.WeekStart}} - {{data.WeekEnd}})</p>
                        <p *ngIf="!(BP.MobileQuery | async)">{{ GetHourStringFromMilliseconds(data.WorkTimeSum) }} ingesamt</p>
                        <p *ngIf="!(BP.MobileQuery | async) && (ShowOvertime$ | async)">Überstundenkonto: {{ GetHourStringFromMilliseconds(data.OverTimeSum) }}</p>
                    </div>
                    <div class="right">
                        <!--                <fa-icon icon="print"></fa-icon>-->
                        <!--                <fa-icon icon="file-export"></fa-icon>-->
                        <fa-icon [icon]="panel.expanded ? 'chevron-up' : 'chevron-down'"></fa-icon>
                    </div>
                </mat-expansion-panel-header>
                <table mat-table [dataSource]="data.TableData" multiTemplateDataRows *ngIf="panel.expanded">
                    <ng-container *ngFor="let column of WorkDayColumns.filter(FilterCustomColumns); trackBy: Identifyer" [matColumnDef]="column.name">
                        <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
                        <td mat-cell *matCellDef="let rowData">
                            {{ rowData[column.name] }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="CommissionNames">
                        <th mat-header-cell *matHeaderCellDef>{{ cls.getMultiple$('Commission') | async }}</th>
                        <td mat-cell *matCellDef="let rowData">
                            <span class="ellipsis-cell">{{ rowData.CommissionNames }}</span>
                        </td>
                    </ng-container>

                    <!-- For no Commission-->
                    <ng-container matColumnDef="expansion" *ngIf="!showEmployeeHierarchie; else UserList">
                    <td mat-cell *matCellDef="let r" [attr.colspan]="(DisplayedWorkDayColumns$ | async)?.length" style="padding: 0">
                        <div [@detailExpand]="r.Date === ExpandedWorkDay ? 'expanded' : 'collapsed'" style="overflow: hidden">
                            <div class="expanded-table-wrapper">
                                <!--Table here-->

                                <mat-list *ngIf="r.SalaryTypes$ && (ShowSalaryTypes$ | async)">
                                    <ng-container *ngFor="let salaryType of r.SalaryTypes$ | async">
                                        <mat-list-item class="salary-type-list-entry">
                                            <fa-icon class="icon" [icon]="EmployeeSalaryTypeIcon"></fa-icon>
                                            <span>{{ salaryType.salaryType.Name || '' }} {{salaryType.commissionLabel$ | async}} {{ salaryType.salaryType.Bearbeitungsschluessel ? 'BS: ' + salaryType.salaryType.Bearbeitungsschluessel : '' }} {{ salaryType.salaryType.SalaryTypeNumber ? 'Lo-Nr.: ' + salaryType.salaryType.SalaryTypeNumber : '' }}</span>
                                            <span class="spacer"></span>
                                            <span>
                                                {{ salaryType.salaryType.Amount != null ? (salaryType.salaryType.Amount | currency: 'EUR') : '' }} {{ salaryType.salaryType.PercentAmountFromSalary != null ? (salaryType.salaryType.PercentAmountFromSalary | number) + ' %' : '' }}
                                            </span>
                                            <button class="edit-button" app-round-button (click)="ChangeEmployeeSalaryType(salaryType.salaryType.Id)"><fa-icon icon="pencil-alt"></fa-icon></button>
                                        </mat-list-item>
                                        <mat-divider></mat-divider>
                                    </ng-container>
                                </mat-list>
                                <ng-container *ngTemplateOutlet="userClockinsByWorkday; context: { rowdata: r.TableData,  date: r.DateData }"></ng-container>
                                <p class="empty-text" *ngIf="r.IsHoliday">Feiertag</p>
                                <p class="empty-text" *ngFor="let vacation of r.EmployeeToVacations$ | async">{{ VacationTypeEnumNameMap.get(vacation.Type) }}</p>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <!---->

                    <!--For Commission-->
                    <!--Nested Workdays with UserList-->
                    <ng-template #UserList>
                        <ng-container matColumnDef="expansion">
                            <td mat-cell *matCellDef="let workDayTableData" [attr.colspan]="(DisplayedWorkDayColumns$ | async)?.length" style="padding: 0">
                                <div [@detailExpand]="workDayTableData.Date === ExpandedWorkDay ? 'expanded' : 'collapsed'" style="overflow: hidden">
                                    <div class="expanded-table-wrapper" *ngIf="workDayTableData.Date === ExpandedWorkDay">
                                        <table style="background: transparent" mat-table [dataSource]="workDayTableData.EmployeeListData$ | async" multiTemplateDataRows>
                                            <ng-container *ngFor="let column of EmployeeListColumns" [matColumnDef]="column.name">
                                                <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
                                                <td mat-cell *matCellDef="let r">{{ r[column.name] }}</td>
                                                <!--Clock in Times-->
                                            </ng-container>
                                            <ng-container matColumnDef="expansion">
                                                <td mat-cell *matCellDef="let r" [attr.colspan]="(DisplayedEmployeeListColumns$ | async)?.length" style="padding: 0">
                                                    <div [@detailExpand]="r.Identifier === ExpandedWorkDayFromEmployee ? 'expanded' : 'collapsed'" style="overflow: hidden">
                                                        <div class="expanded-table-wrapper">
                                                            <!--Table here-->
                                                            <ng-container
                                                                *ngTemplateOutlet="
                                                                userClockinsByWorkday;
                                                                context: {
                                                                    rowdata: r.ClockInTableData,
                                                                    date: r.Date,
                                                                    employeeId: r.EmployeeId
                                                                }
                                                            "
                                                            ></ng-container>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="icon">
                                                <th mat-header-cell *matHeaderCellDef></th>
                                                <td mat-cell *matCellDef="let r" style="width: 2.5rem">
                                                    <fa-icon [icon]="r.Identifier === ExpandedWorkDayFromEmployee ? 'chevron-up' : 'chevron-down'"></fa-icon>
                                                    <ng-container *ngIf="!r.IsValid" [ngTemplateOutlet]="brokenWorkDayIcon"></ng-container>
                                                </td>
                                            </ng-container>
                                            <tr style="background: inherit" mat-header-row *matHeaderRowDef="DisplayedEmployeeListColumns$ | async"></tr>
                                            <tr
                                                mat-row
                                                *matRowDef="let row; columns: DisplayedEmployeeListColumns$ | async"
                                                class="clickable-row"
                                                [class.selected]="row.Identifier === ExpandedWorkDayFromEmployee"
                                                (click)="ExpandedWorkDayFromEmployee = row.Identifier === ExpandedWorkDayFromEmployee ? null : row.Identifier"
                                            ></tr>
                                            <tr mat-row *matRowDef="let row; columns: ['expansion']" class="expandable-row"></tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                    </ng-template>

                    <ng-container matColumnDef="icon">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let r" style="width: 2.5rem">
                            <fa-icon [icon]="r.Date === ExpandedWorkDay ? 'chevron-up' : 'chevron-down'"></fa-icon>
                            <ng-container *ngIf="!r.EveryValid" [ngTemplateOutlet]="brokenWorkDayIcon"></ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let r" style="width: 1rem">
                            <!-- <button type="button" app-button class="button" class="delete-button">
                                    Löschen
                                </button> -->
                            <div (click)="$event.stopPropagation()" class="delete-button">
                                <button
                                    app-round-button
                                    *ngIf="r.Date === ExpandedWorkDay && r.WorkDayIds.length < 2 && r.TableData.data.length"
                                    (click)="DeleteWorkDay(r.WorkDayIds[0], $event)"
                                    matTooltip="Arbeitszeiten löschen"
                                    Color="red"
                                    [Disabled]="!(r.CanDeleteAll$ | async) || r.SomeExported && !(PS.Has(PS.Permission.EditExportedClockIn) | async)"
                                >
                                    <fa-icon icon="trash"></fa-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="DisplayedWorkDayColumns$ | async"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: DisplayedWorkDayColumns$ | async"
                        class="clickable-row"
                        [class.selected]="row.Date === ExpandedWorkDay"
                        (click)="ExpandedWorkDay = row.Date === ExpandedWorkDay ? null : row.Date"
                    ></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expansion']" class="expandable-row"></tr>
                </table>
            </mat-expansion-panel>
        </mat-accordion>
        <h2 *ngIf="(PanelData | async)?.length == 0" class="empty">keine Einträge vorhanden</h2>
    </ng-container>
</mat-dialog-content>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
<ng-template #userClockinsByWorkday let-tabledata="rowdata" let-date="date" let-employeeId="employeeId">
    <table style="background: transparent" mat-table [dataSource]="tabledata" multiTemplateDataRows>
        <ng-container *ngFor="let column of (ClockInColumns$ | async)?.filter(FilterCustomColumns); trackBy: Identifyer" [matColumnDef]="column.name">
            <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
            <td mat-cell *matCellDef="let r">{{ r[column.name] }}</td>
            <!--Clock in Times-->
        </ng-container>
        <ng-container matColumnDef="Note">
            <th mat-header-cell *matHeaderCellDef>Anmerkung</th>
            <td mat-cell class="white-space-wrap" *matCellDef="let r">{{ r.Note }}</td>
            <!--Clock in Times-->
        </ng-container>
        <ng-container matColumnDef="button">
            <th mat-header-cell *matHeaderCellDef="let r" class="action">
                <button app-round-button style="margin-left: 1rem" (click)="CreateClockIn(employeeId, date)" matTooltip="Stempelzeit hinzufügen">
                    <fa-icon icon="plus"></fa-icon>
                </button>
                <ng-container *ngIf="ShowSalaryTypes$ | async">
                    <button app-round-button *ngIf="ShowEmployeeSalaryTypesForEmployeeId$ | async as id" style="margin-left: 1rem" (click)="CreateEmployeeSalaryType(id, date)" [matTooltip]="EmployeeSalaryTypeLabel + ' hinzufügen'">
                        <fa-icon [icon]="EmployeeSalaryTypeIcon"></fa-icon>
                    </button>
                </ng-container>
            </th>
            <td mat-cell *matCellDef="let r" style="width: 8rem">
                <div style="display: flex; justify-content: end">
                    <button
                        app-round-button
                        (click)="EditingClockIn(r.ClockInEntity)"
                        style="margin-left: 1rem"
                        matTooltip="Stempelzeit bearbeiten"
                        [Disabled]="!(r.CanEdit$ | async) || r.ClockInEntity.ExportedAt && !(PS.Has(PS.Permission.EditExportedClockIn) | async)"
                    >
                        <fa-icon icon="pencil-alt"></fa-icon>
                    </button>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="line">
            <th mat-header-cell *matHeaderCellDef class="line"></th>
            <td mat-cell *matCellDef="let r" class="line">
                <div
                    class="line-wrapper"
                    [class.line-start]="r.ClockInEntity.Type == ClockInTypes.Start && r.ClockInEntity.ClockInTypeId === GlobalClockInTypeId"
                    [class.line-end]="r.ClockInEntity.Type == ClockInTypes.End && r.ClockInEntity.ClockInTypeId === GlobalClockInTypeId"
                >
                    <div class="line"></div>
                </div>
            </td>
        </ng-container>
        <tr style="background: inherit" mat-header-row *matHeaderRowDef="DisplayedClockInColumns$ | async"></tr>
        <tr style="background: inherit" mat-row *matRowDef="let row; columns: DisplayedClockInColumns$ | async"></tr>
    </table>
    <p *ngIf="!tabledata.data.length" class="empty-text">Keine Stempelzeiten vorhanden</p>
</ng-template>
<ng-template #brokenWorkDayIcon>
    <fa-icon icon="exclamation-triangle" style="float: right" matTooltip="dieser Tag ist fehlerhaft"></fa-icon>
</ng-template>
<ng-template #selectSalaryTypeForm let-formControl="formControl" let-key="key" let-onlyIcon="onlyIcon">
    <div class="select-salary-type-form">
        <mat-form-field>
            <mat-label>
                <fa-icon [icon]="icon" *ngIf="onlyIcon as icon"></fa-icon>
                {{ key }}
            </mat-label>
<!--            <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer;" (click)="formControl.setValue(null);$event.stopPropagation();" *ngIf="!formControl.disabled"></fa-icon>-->
            <app-select-search #selectSearch [formControl]="formControl" [Options]="salaryTypeOptions$ | async" [CompareOptions]="CompareById"></app-select-search>
        </mat-form-field>
        <button app-round-button (click)="onAddSalaryTypeClick()"><fa-icon icon="plus"></fa-icon></button>
    </div>
</ng-template>
