import {EmailSettingsTypeEntity} from '../../entities/emailSettingsType.entity';
import { createReducer, on } from '@ngrx/store';
import {EmailSettingsTypeActionTypes} from '../actions/emailsettingstype.actions';


export const emailSettingsTypeFeatureKey = 'emailSettingsTypes';

export interface EmailSettingsTypeState {
    emailSettingsTypes: EmailSettingsTypeEntity[];
}

export const initialState: EmailSettingsTypeState = {
    emailSettingsTypes: null,
};

export const EmailSettingsTypesReducer = createReducer(
    initialState,
    on(EmailSettingsTypeActionTypes.UpdateEmailSettingsType, (state, { Payload }) => ({
        ...state,
        emailSettingsTypes: Payload,
    })),
);
