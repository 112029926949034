<mat-form-field [color]="Color">
    <mat-label>
        <span #label><ng-content select="[app-label]"></ng-content></span>
        <ng-container *ngIf="!label.children.length">{{Key}}</ng-container>
    </mat-label>
    <input
        matInput
        [type]="Type"
        [placeholder]="Key"
        [formControl]="Value"
        [required]="Required"
    />
    <div matSuffix>
        <ng-content select="[textfieldSuffix]"></ng-content>
        <fa-icon
            icon="question-circle"
            [matTooltip]="Hint"
            *ngIf="Hint != null"
            style="flex-shrink: 100"
        >
        </fa-icon>
    </div>
    <mat-error *ngIf="ErrorMessage$ | async as error">
        {{ error }}
    </mat-error>
</mat-form-field>

