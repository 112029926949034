import { GeneratedDocumentsEntity } from './../../entities/generatedDocuments.entity';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { isNotNullOrUndefined, stringifyIfNotNullOrUndefined, stringifyIfNotUndefined } from '../../../helper/helper';
import { GeneratedDocumentsEntityFromBackend } from '../../entities/generatedDocuments.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { GeneratedDocumentsActionTypes } from '../actions/generatedDocuments.actions';
import { State } from '../index';
import { getGeneratedDocuments } from '../selectors/generatedDocuments.selectors';
import { FilesActionTypes } from '../actions/files.actions';
import { FileEntity, FileEntityFromBackend } from '../../entities/file.entity';
import { CommissionActionTypes } from '../actions/commission.actions';
import { CommissionEntityFromBackend } from '../../entities/commission.entity';
import { getToken } from '../selectors/base.selectors';

enum ErrorCodes {
    Add = 'Dokument Hinzufügen fehlgeschlagen',
    Load = 'Dokument Abrufen fehlgeschlagen',
    Modify = 'Dokument Bearbeiten fehlgeschlagen',
    Remove = 'Dokument Löschen fehlgeschlagen',
}

@Injectable()
export class GeneratedDocumentsEffects {

    RemoveGeneratedDocuments$ = createEffect(() => this.actions$.pipe(
        ofType(GeneratedDocumentsActionTypes.DeleteGeneratedDocuments),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
mutation{
  deleteGeneratedDocuments(id: ${action.Payload})
}`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                withLatestFrom(this.store$.select(getGeneratedDocuments)),
                map(([res, generatedDocuments]) =>
                    res && res.deleteGeneratedDocuments
                        ? GeneratedDocumentsActionTypes.RemoveOne({
                              Payload: action.Payload,
                          })
                        : BaseActionTypes.ErrorAction({
                              Payload: {
                                  ToasterMessage: ErrorCodes.Remove,
                              },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    ModifyGeneratedDocuments$ = createEffect(() => this.actions$.pipe(
        ofType(GeneratedDocumentsActionTypes.ModifyGeneratedDocuments),
        withLatestFrom(this.store$),
        concatMap(([{Payload}, store]) => {
            const queryString = `
mutation${Payload.generatedFile ? '($sfdtFile: String)' : ''}{
  changeGeneratedDocuments(id: ${Payload.id}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
    ${Payload.generatedFile ? 'generatedFile: $sfdtFile' : ''}
    ${stringifyIfNotNullOrUndefined(Payload, 'generatedDocumentsTypeId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'name')}
    ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
    ${stringifyIfNotNullOrUndefined(Payload, 'uploadPdf')}
    ${stringifyIfNotUndefined(Payload, 'resourceId')}
    ${Payload.state !== undefined ? `state: ${Payload.state}` : ''}
    ){
    ${GeneratedDocumentsEntity.GqlFields}
    generatedFile
    document {${FileEntity.GqlFields}}
  }
}`;
            const variables = {
                sfdtFile: Payload.generatedFile,
            };
            return this.gatewayHttpService.graphQl({ query: queryString, variables }, { token: store.base.token }).pipe(
                concatMap(res =>
                    res && res.changeGeneratedDocuments && res.changeGeneratedDocuments.id
                        ? [
                            GeneratedDocumentsActionTypes.UpdateOne({
                              Payload: GeneratedDocumentsEntityFromBackend(res.changeGeneratedDocuments),
                          }),
                            res.changeGeneratedDocuments.document
                                ? FilesActionTypes.UpdateOne({Payload: FileEntityFromBackend(res.changeGeneratedDocuments.document)})
                                : null
                        ].filter(isNotNullOrUndefined)
                        : [
                            BaseActionTypes.ErrorAction({
                              Payload: {
                                  ToasterMessage: ErrorCodes.Modify,
                              },
                          })
                        ],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    AddGeneratedDocuments$ = createEffect(() => this.actions$.pipe(
        ofType(GeneratedDocumentsActionTypes.AddGeneratedDocuments),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([action, token]) => {
            const query = `
mutation($sfdtFile: String){
  createGeneratedDocuments${
      action.Payload.customerId ||
      action.Payload.commissionId ||
      action.Payload.name ||
      action.Payload.generatedDocumentsTypeId ||
      action.Payload.resourceId ||
      action.Payload.additionalData
          ? `(
    ${stringifyIfNotNullOrUndefined(action.Payload, 'customerId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'commissionId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'name')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'generatedDocumentsTypeId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'additionalData')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'resourceId')}
    ${action.Payload.generatedFile ? 'generatedFile: $sfdtFile' : ''}
    ${action.Payload.state !== undefined ? `state: ${action.Payload.state}` : ''}
  )`
          : ''
  }{
    ${GeneratedDocumentsEntity.GqlFields}
    generatedFile
  }
}`;
            const variables = {
                sfdtFile: action.Payload.generatedFile,
            };
            return this.gatewayHttpService.graphQl({ query, variables }, { token }).pipe(
                concatMap(res => {
                    return res && res.createGeneratedDocuments
                        ? [
                              GeneratedDocumentsActionTypes.UpdateOne({
                                  Payload: GeneratedDocumentsEntityFromBackend(res.createGeneratedDocuments),
                              }),
                          ]
                        : [
                              BaseActionTypes.ErrorAction({
                                  Payload: {
                                      ToasterMessage: ErrorCodes.Add,
                                  },
                              }),
                          ];
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    LoadGeneratedDocuments = createEffect(() => this.actions$.pipe(
        ofType(GeneratedDocumentsActionTypes.Load),
        withLatestFrom(this.store$),
        switchMap(([{Payload}, store]) => {
            const queryString = `query {
                  generatedDocuments${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''}{${GeneratedDocumentsEntity.GqlFields}}
            }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                map(res =>
                    res && res.generatedDocuments
                        ? Payload?.updatedSince
                            ? GeneratedDocumentsActionTypes.UpdateMany({
                                Payload: res.generatedDocuments.map(val => GeneratedDocumentsEntityFromBackend(val)),
                                updateLatestUpdatedAt: true,
                            })
                            : GeneratedDocumentsActionTypes.UpdateAll({
                                Payload: res.generatedDocuments.map(val => GeneratedDocumentsEntityFromBackend(val)),
                                updateLatestUpdatedAt: true,
                            })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Load },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    LoadGeneratedDocumentById = createEffect(() => this.actions$.pipe(
        ofType(GeneratedDocumentsActionTypes.LoadOne),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `query {
                  generatedDocuments(id: ${action.id}){
                    ${GeneratedDocumentsEntity.GqlFields}
                    ${action.withFile ? 'generatedFile' : ''}
                  }
            }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                withLatestFrom(this.store$.select(getGeneratedDocuments)),
                map(([res, documents]) =>
                    res && res.generatedDocuments && res.generatedDocuments.length === 1
                        ? GeneratedDocumentsActionTypes.UpdateOne({
                              Payload: GeneratedDocumentsEntityFromBackend(res.generatedDocuments[0]),
                          })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Load },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
    ) {}
}
