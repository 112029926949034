import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { GeneratedDocumentsActionTypes } from '../State/actions/generatedDocuments.actions';
import { getGeneratedDocumentLatestUpdatedAt, getGeneratedDocumentsFetched } from '../State/selectors/generatedDocuments.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class GeneratedDocumentsResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getGeneratedDocumentsFetched, getGeneratedDocumentLatestUpdatedAt, GeneratedDocumentsActionTypes.Load, store);
    }
}
