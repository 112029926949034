import { createAction, props } from '@ngrx/store';
import { SettingEntityAttributes, UserEntity } from '../../entities/user.entity';
import { DaveMutationCreateUserArgs, QueryUserArgs } from '../../graphql-types';

export const UsersActionTypes = {
    UpdateUser: createAction('data/user/update', props<{ Payload: UserEntity }>()),
    UpdateUserSettings: createAction('data/user/update/settings', props<{ Payload: SettingEntityAttributes }>()),
    Load: createAction('data/user/load', props<{ Payload?: QueryUserArgs }>()),
    UpdateMany: createAction('data/users/updateMany', props<{ Payload: Array<UserEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateAll: createAction('data/users/updateAll', props<{ Payload: Array<UserEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateManageUserList: createAction('data/manageUserList/update', props<{ Payload: Array<UserEntity> }>()),
    AddManageUser: createAction(
        'data/manageUserList/add',
        props<{ Payload: DaveMutationCreateUserArgs }>(),
    ),
    ModifyManageUser: createAction('data/manageUser/modify', props<{ Payload: UserEntity }>()),
    DeleteManageUser: createAction('data/manageUser/delete', props<{ Payload: number }>()),
};
