import { createAction, props } from '@ngrx/store';
import { DaveMutationCreateEmailConnection2EmailSettingsTypeArgs, DaveMutationDeleteEmailConnection2EmailSettingsTypeArgs } from '../../graphql-types';
import { EmailConnection2EmailSettingsTypeEntity } from '../../entities/emailConnection2EmailSettingsType.entity';


export const EmailConnection2EmailSettingsTypeActionTypes = {
    UpdateEmailConnection2EmailSettingsType: createAction(
        'data/EmailConnection2EmailSettingsType/update',
        props<{ Payload: Array<EmailConnection2EmailSettingsTypeEntity> }>(),
    ),
    GetEmailConnection2EmailSettingsTypeRequest: createAction(
        'data/EmailConnection2EmailSettingsType/getAll',
    ),
    AddEmailConnection2EmailSettingsType: createAction(
        'data/EmailConnection2EmailSettingsType/add',
        props<{ Payload: DaveMutationCreateEmailConnection2EmailSettingsTypeArgs }>(),
    ),
    DeleteEmailConnection2EmailSettingsType: createAction(
        'data/EmailConnection2EmailSettingsType/delete',
        props<{ Payload: DaveMutationDeleteEmailConnection2EmailSettingsTypeArgs }>(),
    ),
};

