import { EmailConnectionType } from '../graphql-types';
export enum EmailAuthTypeEnum {
    default,
    microsoftRest,
    microsoft,
}
export enum EmailSecurityMethod {
    SSL = 'SSL',
    STARTLS = 'STARTTLS',
}

export enum EmailServerType {
    POP = 'POP3',
    IMAP = 'IMAP',
    Microsoft = 'MICROSOFT',
    MicrosoftRest = 'MS_REST',
}

export class EmailConnectionEntity {
    constructor(
        public Id: number = null,
        public Host: string = null,
        public SendHost: string = null,
        public Method: EmailSecurityMethod = null,
        public SendMethod: EmailSecurityMethod = null,
        public Port: number = null,
        public SendPort: number = null,
        public EventTypeId: number = null,
        public Username: string = null,
        public Type: EmailServerType = null,
        public Pollingrate: number = null,
        public ConnectionTested: boolean = null,
        public Deleted: boolean = null,
        public AssignToOtherUser: boolean = null,
        public ConnectionPrivate: boolean = null,
        public Signature: string = null,
        public Address: string = null,
        public TokenId: number = null,
    ) {}

    public Clone(override: Partial<EmailConnectionEntity> = {}): EmailConnectionEntity {
        const attr = { ...this, ...override };
        return Object.assign(new EmailConnectionEntity(), attr);
    }
    public get EmailAuthType(): EmailAuthTypeEnum {
        switch (this.Type) {
            case EmailServerType.IMAP:
            case EmailServerType.POP:
                return EmailAuthTypeEnum.default;
            case EmailServerType.Microsoft:
                return EmailAuthTypeEnum.microsoft;
            case EmailServerType.MicrosoftRest:
                return EmailAuthTypeEnum.microsoftRest;
        }
        return null;
    }
}

export function EmailConnectionEntityFromBackend(res: EmailConnectionType): EmailConnectionEntity {
    console.warn(res.method as EmailSecurityMethod, res.sendMethod as EmailSecurityMethod, res.type as EmailServerType);
    return new EmailConnectionEntity(
        res.id,
        res.host,
        res.sendHost,
        res.method as EmailSecurityMethod,
        res.sendMethod as EmailSecurityMethod,
        res.port,
        res.sendPort,
        res.eventTypeId,
        res.username,
        res.type as EmailServerType,
        res.pollingrate,
        res.connectionTested,
        res.deleted,
        res.assignToOtherUser,
        res.connectionPrivate,
        res.signature,
        res.address,
        res.tokenId,
    );
}
