import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { FileEntity } from '../../../dave-data-module/entities/file.entity';
import { FolderUserEntity } from '../../../dave-data-module/entities/folder-user.entity';
import { FolderEntity } from '../../../dave-data-module/entities/folder.entity';
import { State } from '../../../dave-data-module/State';
import { FolderUserActionTypes } from '../../../dave-data-module/State/actions/folder-user';
import { getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { Actions, ofType } from '@ngrx/effects';
import { EmployeeResolver } from '../../../dave-data-module/guards/employee.resolver';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { getEmployeesActive, getEmployeesFetched } from '../../../dave-data-module/State/selectors/employees.selectors';

export interface EditFolderUserComponentDialogData {
    FolderUser?: FolderUserEntity;
    FolderUsers?: FolderUserEntity[];
    FolderId: number;
}

interface FromData {
    FromFile: FileEntity | undefined;
    FromParentFolder: FolderEntity | undefined;
    FromChildFolder: FolderEntity | undefined;
    FromCustomer: CustomerEntity | undefined;
    FromCommission: CommissionEntity | undefined;
    FolderUser: FolderUserEntity;
}

@Component({
    selector: 'app-edit-folder-user',
    templateUrl: './edit-folder-user.component.html',
    styleUrls: ['./edit-folder-user.component.scss'],
})
export class EditFolderUserComponent implements OnDestroy {
    public New = false;
    public Loading = false;
    public From: FromData[] = [];
    public Users$ = this.store.select(getEmployeesActive).pipe(
        map((employees) =>
            employees
                .filter((u) => !!u.UserId)
                .map((u) => ({
                    Name: u.DisplayName,
                    Id: u.UserId,
                })),
        ),
    );
    public Form = this.fb.group({
        User: new UntypedFormControl(null),
        Edit: new UntypedFormControl(false),
    }) as FormGroupTyped<{
        User: SelectSearchData | null;
        Edit: boolean;
    }>;
    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public Data: EditFolderUserComponentDialogData,
        public DialogRef: MatDialogRef<EditFolderUserComponent>,
        public CS: CustomerNameService,
        protected store: Store<State>,
        private fb: UntypedFormBuilder,
        private fileDataService: FileDataService,
        employeeResolver: EmployeeResolver,
        folderDataService: FolderDataService,
        private actions$: Actions,
    ) {
        firstValueFrom(this.store.select(getEmployeesFetched)).then((f) => {
            if (!f) {
                employeeResolver.resolve();
            }
        });
        if (!this.Data.FolderUser && !this.Data.FolderUsers) {
            this.New = true;
        }
        if (this.Data.FolderUser) {
            this.Form.controls.Edit.setValue(this.Data.FolderUser.Edit);
            this.Form.controls.User.setValue({
                Id: this.Data.FolderUser.UserId,
                Name: '',
            });
        }
        if (this.Data.FolderUsers) {
            this.Form.controls.Edit.setValue(this.Data.FolderUsers[0].Edit);
            this.Form.controls.User.setValue({
                Id: this.Data.FolderUsers[0].UserId,
                Name: '',
            });
            const folderIds = this.Data.FolderUsers.map((fu) => [fu.FromParent, fu.FromChild])
                .flat()
                .filter(isNotNullOrUndefined);
            this.subscriptions.push(
                combineLatest([
                    this.fileDataService.GetFilesById(this.Data.FolderUsers.map((fu) => fu.FromDocument).filter((id, i, arr) => id && arr.indexOf(id) === i)),
                    //this.store.select(getFolders),
                    folderDataService.getFolderByIds(folderIds),
                    this.store.select(getCustomers),
                    this.store.select(getCommissions),
                ]).subscribe(([files, folders, customers, commissions]) => {
                    this.Data.FolderUsers.forEach((fu) => {
                        const out: FromData = {
                            FolderUser: fu,
                            FromFile: undefined,
                            FromParentFolder: undefined,
                            FromChildFolder: undefined,
                            FromCustomer: undefined,
                            FromCommission: undefined,
                        };
                        if (fu.FromDocument !== 0) {
                            out.FromFile = files.find((f) => f.Id === fu.FromDocument);
                        }

                        if (fu.FromParent !== 0) {
                            out.FromParentFolder = folders.find((f) => f.Id === fu.FromParent);
                        }

                        if (fu.FromChild !== 0) {
                            out.FromChildFolder = folders.find((f) => f.Id === fu.FromChild);
                        }

                        if (fu.FromType !== '' && fu.FromId !== 0) {
                            switch (fu.FromType) {
                                default:
                                    console.error('FromType not found', fu.FromType);
                                    break;
                                case 'customer':
                                    out.FromCustomer = customers.find((c) => c.Id === fu.FromId);
                                    break;
                                case 'commission':
                                    out.FromCommission = commissions.find((c) => c.Id === fu.FromId);
                                    break;
                            }
                        }
                        this.From.push(out);
                    });
                }),
            );
        }
    }
    Save() {
        this.Loading = true;
        firstValueFrom(this.actions$.pipe(ofType(FolderUserActionTypes.AddFolderUserSuccess, BaseActionTypes.ErrorAction))).then((action) => {
            this.Loading = false;
            if (action.type === FolderUserActionTypes.AddFolderUserSuccess.type) {
                this.DialogRef.close();
            }
        });
        this.store.dispatch(
            FolderUserActionTypes.AddFolderUserRequest({
                Payload: {
                    folderId: this.Data.FolderId,
                    userId: this.Form.value.User.Id,
                    edit: this.Form.value.Edit,
                },
            }),
        );
    }
    Delete() {
        this.Loading = true;
        firstValueFrom(this.actions$.pipe(ofType(FolderUserActionTypes.DeleteFolderUserSuccess, BaseActionTypes.ErrorAction))).then((action) => {
            this.Loading = false;
            if (action.type === FolderUserActionTypes.DeleteFolderUserSuccess.type) {
                this.DialogRef.close();
            }
        });

        this.store.dispatch(
            FolderUserActionTypes.DeleteFolderUserRequest({
                Payload: {
                    folderId: this.Data.FolderUser.FolderId,
                    userId: this.Data.FolderUser.UserId,
                },
            }),
        );

        // this.DialogRef.close();
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
