export function LicenseCost(basePrice: number, pricePerUser: number, activeUser: number, minUser: number, discountInPercent: number) {
    const userNumber = activeUser > minUser
        ? activeUser
        : minUser;
    const calcPricePerUser = PricePerUser(basePrice, pricePerUser, activeUser, minUser);
    return (basePrice + calcPricePerUser * userNumber) * (100 - discountInPercent) / 100;
}

export function PricePerUser(basePrice: number, pricePerUser: number, activeUser: number, minUser: number) {
    const userNumber = activeUser > minUser
        ? activeUser
        : minUser;
    return  basePrice !== 0 || pricePerUser !== 0
        ? pricePerUser
        : (-0.5012 * Math.log(userNumber) + 9.0748);
}
