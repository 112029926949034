import { CommonModule,CurrencyPipe,DecimalPipe } from "@angular/common";
import { ChangeDetectionStrategy,Component,EventEmitter,OnDestroy,Output } from '@angular/core';
import { ReactiveFormsModule,UntypedFormBuilder,UntypedFormControl } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Store } from '@ngrx/store';
import { combineLatest,Observable,Subject,Subscription } from 'rxjs';
import { debounceTime,filter,map,startWith,tap,withLatestFrom } from 'rxjs/operators';
import { SimplebarAngularModule } from "simplebar-angular";
import { CalcAccountsReceivableLedgerAmount } from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { State } from '../../../dave-data-module/State';
import {
getAccountsReceivableLedgerTemplates,
getQuantityTypes
} from '../../../dave-data-module/State/selectors/accounting.selector';
import { isNotNullOrUndefined } from '../../../helper/helper';

@Component({
    selector: 'app-jveg-template-list',
    templateUrl: './jveg-template-list.component.html',
    styleUrls: ['./jveg-template-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DecimalPipe, CurrencyPipe],
    standalone: true,
    imports: [CommonModule, MatCardModule, MatInputModule, FontAwesomeModule, SimplebarAngularModule, MatTableModule, MatTooltipModule, ReactiveFormsModule, MatButtonModule],
})
export class JvegTemplateListComponent implements OnDestroy {
    @Output() SelectedJVEGTemplateId = new EventEmitter<number>();
    public SelectedRowIndex: number;
    public SearchForm: FormControlTyped<string> = new UntypedFormControl('');
    public ARLTemplateColumns = ['BookingText', 'Information', 'Quantity', 'QuantityType', 'SumNetto', 'Hint'];
    public ARLTemplateColumnHeaders = {
        BookingText: 'Art-Nr.',
        Information: 'Bezeichnung',
        SumNetto: 'Summe',
        QuantityType: 'Einheit',
        Quantity: 'Menge',
    };
    public ARLTemplateAutoColumns = ['BookingText', 'Information', 'SumNetto', 'QuantityType', 'Quantity'];
    public BookingPositionTemplates$: Observable<
        ReadonlyArray<{
            BookingText: string;
            Information: string;
            SumNetto: string;
            Quantity: string;
            QuantityType: string;
            Hint?: string;
        }>
    > = this.store.select(getAccountsReceivableLedgerTemplates).pipe(
        filter(isNotNullOrUndefined),
        map((val) => val.filter((v) => v.CustomProperties?.jveg)),
        map((val) =>
            val.slice().sort((a, b) => {
                const nameA = parseFloat(a.BookingText); // Groß-/Kleinschreibung ignorieren
                const nameB = parseFloat(b.BookingText);
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            }),
        ),
        withLatestFrom(this.store.select(getQuantityTypes)),
        map(([val, qt]) =>
            val.map((v) => ({
                ...v,
                SumNetto: this.currencyPipe.transform(CalcAccountsReceivableLedgerAmount(v.Quantity, 0, v.BaseCost), v.CurrencyCode),
                Quantity: this.decimalPipe.transform(v.Quantity),
                QuantityType: qt.find((q) => q.Id === v.QuantityTypeId)?.Name || '',
                Hint: v.CustomProperties?.hint,
            })),
        ),
    );
    public FilteredBookingPositionTemplates$: Observable<
        ReadonlyArray<{
            BookingText: string;
            Information: string;
            SumNetto: string;
            Quantity: string;
            QuantityType: string;
            Hint?: string;
        }>
    > = combineLatest([this.BookingPositionTemplates$, this.SearchForm.valueChanges.pipe(startWith(this.SearchForm.value), debounceTime(300))]).pipe(
        map(([val, search]) => {
            if (!search) {
                return val;
            }
            return val.filter((v) => v.BookingText.toLowerCase().includes(search) || v.Information.toLowerCase().includes(search) || v.Hint?.toLowerCase().includes(search));
        }),
    );
    public SelectedTemplateId$ = new Subject<number>();
    private subs: Subscription[] = [];
    constructor(private fb: UntypedFormBuilder, private store: Store<State>, private decimalPipe: DecimalPipe, private currencyPipe: CurrencyPipe) {
        this.subs.push(this.SelectedTemplateId$.pipe(tap((id) => this.SelectedJVEGTemplateId.emit(id))).subscribe());
    }

    public highlight(rowData) {
        this.SelectedRowIndex = rowData.Id;
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
}
