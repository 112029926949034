import { Directive, OnDestroy } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SelectEventTypeComponent } from "./select-event-type.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({
  selector: 'app-select-event-type[formControlName], ' + 'app-select-event-type[formControl], ' + 'app-select-event-type[ngModel]',
  standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SelectEventTypeDirective,
            multi: true,
        },
    ],
})
export class SelectEventTypeDirective implements ControlValueAccessor, OnDestroy {
    /** Gibt ein Mal einen Wert aus, wenn die Direktive zerstört wird */
    private onDestroy$ = new Subject<void>();
    private onChange: (value: number | null) => void = () => {};
    private onTouched: () => void = () => {};

  constructor(private component: SelectEventTypeComponent) {
      this.component.selectedEventTypeId$
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((value) =>
              {
                  this.onChange(value);
                  this.onTouched();
              },
          );
  }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: number | null): void {
      this.component.selectedEventTypeId$.next(obj);
    }
    ngOnDestroy() {
        this.onDestroy$.next();
    }
    setDisabledState(isDisabled: boolean): void {
      this.component.setDisabledState(isDisabled)
    }
}
