import { Component, Inject } from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';

export interface ResourceMainDataPopupComponentDialogData {
    ResourceId: number;
}
@Component({
    selector: 'app-resource-main-data-popup',
    templateUrl: './resource-main-data-popup.component.html',
    styleUrls: ['./resource-main-data-popup.component.scss'],
})
export class ResourceMainDataPopupComponent {
    public static DefaultConfig: MatDialogConfig = {
        maxWidth: '100vw',
        hasBackdrop: false,
        closeOnNavigation: true,
        panelClass: 'custom-dialog-class-for-overlay-on-right-side',
    };
    public Editing = false;
    public FolderId$: Observable<number>;
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: ResourceMainDataPopupComponentDialogData,
        folderDataService: FolderDataService,
    ) {
        this.FolderId$ = folderDataService.getFolderFromEntity(Dialogdata.ResourceId, FolderTypes.resource).pipe(
            map((folders) => folders?.Id),
            shareReplay({bufferSize: 1, refCount: true}),
        );
    }
}
