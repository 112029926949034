<h1 mat-dialog-title>
    <fa-icon icon="file-csv"></fa-icon>
    Bitte wählen Sie die zu exportierenden {{cls.getMultiple$('Commission') | async}} aus
</h1>
<mat-dialog-content>
    <table mat-table [dataSource]="DisplayCommissions$">
        <ng-container matColumnDef="icon">
            <td mat-cell *matCellDef="let rowData">
                <fa-icon icon="times" size="lg" *ngIf="!rowData.Selected"></fa-icon>
                <fa-icon icon="check" size="lg" *ngIf="rowData.Selected"></fa-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <td mat-cell *matCellDef="let rowData">
                {{ rowData.Commission.InterneNummer }} - {{ rowData.Commission.Auftragsnummer }} - {{ rowData.Commission.SchadenNr }} - {{ rowData.Commission.Description }}
            </td>
        </ng-container>
        <tr
            mat-row
            *matRowDef="let rowData; columns: ['icon', 'name']"
            (click)="ToggleSelected(rowData.Commission.Id)"
        >
        </tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions class="buttons">
    <button app-button Inverted Color="cancel" mat-dialog-close="">Abbrechen</button>
    <button app-button (click)="Download()">Herunterladen</button>
</mat-dialog-actions>
