import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomFormsModule } from '../../../custom-forms-module/custom-forms.module';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { DaveSharedComponentsModule } from '../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { TagChipAutocompleteComponent } from './component/tag-chip-autocomplete.component';



@NgModule({
    declarations: [TagChipAutocompleteComponent],
    exports: [TagChipAutocompleteComponent],
    imports: [CommonModule, AppButtonModule, MatMenuModule, DaveSharedComponentsModule, ReactiveFormsModule, FontAwesomeModule, MatButtonModule, CustomFormsModule],
})
export class TagChipAutocompleteModule {}
