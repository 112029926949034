import { Component, Inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VarianteTypeEntity } from '../../../../dave-data-module/entities/varianteType.entity';
import { LicensePackageEntity } from '../../../../dave-data-module/entities/license-package.entity';
import { ProductEntity } from '../../../../dave-data-module/entities/product.entity';

@Pipe({
    name: 'filter',
})
@Injectable()
export class FilterPipe implements PipeTransform {
    // tslint:disable-next-line:naming-convention
    transform(items: any[], field: string, value: string): any[] {
        if (!items) {
            return [];
        }
        if (!value || value.length === 0) {
            return items;
        }
        return items.filter(it =>
            it[field].toString().toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }
}

export interface NewLicenseDialogData {
    productList: ProductEntity[];
    licenseList: LicensePackageEntity[];
    varianteTypeList: VarianteTypeEntity[];
}

@Component({
    selector: 'app-new-license-dialog',
    templateUrl: './new-license-dialog.component.html',
    styleUrls: ['./new-license-dialog.component.scss'],
})
export class NewLicenseDialogComponent {
    selectedProduct: ProductEntity = null;
    selectedLicense: LicensePackageEntity = null;
    selectedVarianteType: VarianteTypeEntity = null;

    constructor(@Inject(MAT_DIALOG_DATA) public Data: NewLicenseDialogData) {
    }
}
