import { createReducer, on } from '@ngrx/store';
import { User2CommissionShiftEntity } from '../../entities/user2CommissionShift.entity';
import { UserToCommissionShiftActionTypes } from '../actions/user2CommissionShift.action';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';

export const user2CommissionShiftKey = 'user2CommissionShift';

export interface User2commissionShiftState extends EntityState<User2CommissionShiftEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;
}

const adapter = createEntityAdapter<User2CommissionShiftEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});
export const user2CommissionShiftReducer = createReducer(
    initialState,
    on(UserToCommissionShiftActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(UserToCommissionShiftActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, { ...state, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(UserToCommissionShiftActionTypes.RemoveOne, (state, { Payload }) =>
        adapter.removeOne(Payload, state),
    ),
    on(UserToCommissionShiftActionTypes.RemoveByCommission, (state, { Payload }) =>
        adapter.removeMany(Object.values(state.entities).filter(entity => entity.CommissionId === Payload).map(entity => entity.Id), state)
       ),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllUserToCommissionShift = selectAll;
export const selectUserToCommissionShiftEntities = selectEntities;

export const selectUserToCommissionShiftFetched = (state: User2commissionShiftState) => state.fetched;
export const selectUserToCommissionShiftLatestUpdatedAt = (state: User2commissionShiftState) => state.latestUpdatedAt;
