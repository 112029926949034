import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
@Injectable()
export class FormControlCache<T = any> implements OnDestroy {
    private readonly _destroy$ = new Subject<void>();
    protected arlFormMap = new Map<number, T>();
    // public

    constructor() {}
    public getForm(id: number) {
        return this.arlFormMap.get(id);
    }
    public setForm(id: number, form: T) {
        this.arlFormMap.set(id, form);
    }
    public clearAllForms() {
        this.arlFormMap.clear();
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
