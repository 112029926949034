
<ng-container *ngIf="ViewStyleConfig$ | async as conf; else loadingTemplate">
    <app-detail-view-template [ShowButtons]="ShownButtons">
        <ng-container *appDetailViewButtons>
            <app-detail-list-template
                *ngIf="ShowStatus"
                class="dark-theme"
                [Inline]="true"
                [Editing]="true"
                [Data]="StatusData | async"
                style="margin: 0.5rem; min-width: 6rem"
            >
            </app-detail-list-template>
            <button
                *ngIf="!(Editing$ | async)"
                app-round-button
                class="header-button"
                Inverted
                (click)="ShowStatus = !ShowStatus"
                aria-label="Status ändern"
                matTooltip="Status ändern"
                [IsLoading]="LS.IsLoading$ | async"
                [Disabled]="DisableStatusButton$ | async"
            >
                <fa-icon [icon]="ShowStatus ? 'chevron-right' : 'info'"></fa-icon>
            </button>
            <button
                *ngIf="!(Editing$ | async)"
                app-round-button
                Inverted
                class="header-button"
                matTooltip="Bearbeiten"
                aria-label="Bearbeiten"
                [Disabled]="(Commission$ | async)?.Deleted || !((Commission$ | async)?.CanEdit && (PS.Has(PS.Permission.EditCommission) | async))"
                (click)="Editing$.next(true)"
            >
                <fa-icon icon="pencil-alt"></fa-icon>
            </button>
            <button
                *ngIf="Editing$ | async"
                app-round-button
                class="header-button"
                aria-label="Speichern"
                matTooltip="Speichern"
                [Disabled]="AreFormsInvalid"
                (click)="Save$.next(undefined)"
                [class.save-active]="Editing$ | async"
            >
                <fa-icon icon="save"></fa-icon>
            </button>

            <button
                app-round-button
                *ngIf="(HasDiary$ | async) && !(Editing$ | async)"
                Inverted
                class="header-button"
                (click)="OpenSelectDiaryDate()"
                aria-label="Bautagebuch hinzufügen"
                matTooltip="Bautagebuch hinzufügen"
            >
                <fa-icon [icon]="ConstructionDiaryIcon"></fa-icon>
            </button>
            <button app-round-button Inverted class="header-button" *ngIf="!(Editing$ | async)" aria-label="Stundenliste" matTooltip="Stundenliste" (click)="OpenTimeTracker()">
                <fa-icon icon="stopwatch"></fa-icon>
            </button>
            <button app-round-button Inverted class="header-button" *ngIf="!(Editing$ | async)" aria-label="Schnell anlegen" matTooltip="Schnell Hinzufügen" [matMenuTriggerFor]="QuickMenu" [Disabled]="DisableAddEventButton$ | async">
                <div class="icon-mutiple">
                    <fa-icon icon="history" size="xs"></fa-icon>
                    <fa-icon icon="plus" size="xs"></fa-icon>
                </div>
                <mat-menu #QuickMenu="matMenu">
                    <button mat-menu-item (click)="CreateEventAndTransmission()">
                        <div class="menu-icon-wrapper">
                            <span>Event mit Videoübetragung</span>
                            <fa-icon icon="video"></fa-icon>
                        </div>
                    </button>
                    <button mat-menu-item (click)="CreateCall()">
                        <div class="menu-icon-wrapper">
                            <span>Telefonat</span>
                            <fa-icon icon="phone"></fa-icon>
                        </div>
                    </button>
                    <button mat-menu-item (click)="CreateOnSiteEvent()">
                        <div class="menu-icon-wrapper">
                            <span>Ortstermin</span>
                            <fa-icon icon="map-marker-alt"></fa-icon>
                        </div>
                    </button>
                    <button mat-menu-item (click)="CreateEvent()">
                        <div class="menu-icon-wrapper">
                            <span>Ereignis</span>
                            <fa-icon icon="history"></fa-icon>
                        </div>
                    </button>
                </mat-menu>
            </button>
            <button app-round-button Inverted *ngIf="!(Editing$ | async)" class="header-button" aria-label="Email senden" matTooltip="Email senden" [Disabled]="DisableMailButton$ | async" (click)="OpenMailDialog()">
                <fa-icon icon="paper-plane"></fa-icon>
            </button>

            <button *ngIf="Editing$ | async" app-round-button Inverted class="header-button" aria-label="Zurücksetzen" matTooltip="Zurücksetzen" (click)="Editing$.next(false)">
                <fa-icon icon="undo"></fa-icon>
            </button>

            <button
                app-round-button
                *ngIf="Editing$ | async"
                class="header-button"
                Color="red"
                [class.delete-active]="Editing$ | async"
                aria-label="Löschen"
                matTooltip="Löschen"
                [Disabled]="(Commission$ | async)?.Deleted || !((Commission$ | async)?.CanDelete && (PS.Has(PS.Permission.DeleteCommission) | async))"
                (click)="DeleteCommission()"
            >
                <fa-icon icon="trash"></fa-icon>
            </button>
        </ng-container>
        <ng-container *appDetailViewLeftContent>

            <div class="detail-card" style="max-width: 100vw" *ngIf="Commission$ | async as data">
                <!--oberster container in der standardansicht-->
                <mat-card *ngIf="(showWizard$ | async) && (CommissionMainData | async) && (CommissionSubData | async)" style="margin-top: 1rem">
                    <mat-card-header>
                        <h2>{{wizardComponent?.Headline$ | async}}</h2>
                    </mat-card-header>
                    <app-commission-wizard
                        #wizardComponent
                        [CommissionId]="data.Id"
                        [DetailListTemplateEntrys]="DetailListTemplateEntrys"
                        (Save)="Save$.next($event)"
                    >
                    </app-commission-wizard>
                </mat-card>
            </div>
            <div class="detail-card" *ngFor="let view of conf.left">
                <ng-container [ngTemplateOutlet]="GetTemplateByType(view.type)" [ngTemplateOutletContext]="{ view: view }"></ng-container>
            </div>
        </ng-container>
        <ng-container *appDetailViewRightContent>
            <div class="detail-card" *ngFor="let view of conf.right">
                <ng-container [ngTemplateOutlet]="GetTemplateByType(view.type)" [ngTemplateOutletContext]="{ view: view }"></ng-container>
            </div>
        </ng-container>
    </app-detail-view-template>
    <mat-card *ngIf="PreviewOpen" class="new-document-overlay">
        <button app-round-button class="back-button" (click)="PreviewOpen = false">
            <fa-icon icon="chevron-right"></fa-icon>
        </button>
        <app-preview *ngIf="PreviewedFileId$ | async as id" [EmailEditorDialogData]="{CommissionId: (Commission$ | async)?.Id}" [FileId$]="PreviewedFileId$" [fileExplorerContext]="ImageIds"  (ClosePreview)="PreviewOpen = false" class="preview"></app-preview>
    </mat-card>
</ng-container>
<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>
<ng-template #entityHistoryTemplate let-view="view">
    <ng-container *ngIf="Commission$ | async as data">
        <app-entity-history class="card-height" [Expanded]="view.expanded" [EntityType]="EntityType.Commission" [EntityId]="data.Id"></app-entity-history>
    </ng-container>
</ng-template>

<ng-template #reportsTabGroupTemplate let-view="view">
    <ng-template #headerButtonTemplate>
        <app-add-report-button
            style="margin-right: -.5rem"
            (click)="$event.stopPropagation()"
            [CustomerId]="(Commission$ | async)?.CustomerId"
            [CommissionId]="(Commission$ | async)?.Id"
            [Inverted]="false"
        ></app-add-report-button>
    </ng-template>
    <ng-container [ngTemplateOutlet]="tabGroupTemplate" [ngTemplateOutletContext]="{ view, headerButtonTemplate }">
    </ng-container>
</ng-template>
<ng-template #tabGroupTemplate let-view="view" let-headerButtonTemplate="headerButtonTemplate">
    <mat-expansion-panel class="custom-mat-expansion-panel" [expanded]="view.expanded" *ngIf="[] as countArray">
        <mat-expansion-panel-header [collapsedHeight]="HeaderHeight">
            <div class="card-header">
                <fa-icon *ngIf="view.headlineIcon as icon" class="icon" [icon]="icon"></fa-icon>
                <h3>{{ view.headline }}<span *ngIf="countArray.length"> ({{ArraySum(countArray)}})</span></h3>
                <ng-container *ngIf="headerButtonTemplate" [ngTemplateOutlet]="headerButtonTemplate"></ng-container>
            </div>
        </mat-expansion-panel-header>
        <mat-tab-group>
            <mat-tab *ngFor="let childView of view.elements; index as index" [label]="childView.headline" #tab>
                <ng-container [ngTemplateOutlet]="GetTemplateByType(childView.type)" [ngTemplateOutletContext]="{ view: childView, index, countArray, tab }"></ng-container>
            </mat-tab>
        </mat-tab-group>
    </mat-expansion-panel>
</ng-template>
<ng-template #group let-view="view">
    <mat-expansion-panel class="custom-mat-expansion-panel" [expanded]="view.expanded">
        <mat-expansion-panel-header class="expansion-header"
            ><h3 matCardTitle *ngIf="view.headline">{{ view.headline }}</h3></mat-expansion-panel-header
        >
        <ng-container [ngTemplateOutlet]="GetTemplateByType(childView.type)" [ngTemplateOutletContext]="{ view: childView }" *ngFor="let childView of view.elements">
        </ng-container>
    </mat-expansion-panel>
</ng-template>
<ng-template #commissionTitleTabGroupTemplate let-view="view">
    <ng-container *ngIf="Commission$ | async as data">
        <h1 *ngIf="(Commission$ | async)?.Deleted === true">VORSICHT, BITTE BEACHTEN! DIESES PROJEKT IST GELÖSCHT. JEGLICHE BEARBEITUNG DIESES PROJEKTES HAT KEINE AUSWIRKUNGEN! DIESE
            ANSICHT IST LEDIGLICH ZUR INFORMATION GEDACHT!
        </h1>
        <mat-card class="commissionTitleTabGroupTemplate" *ngIf="!(showWizard$ | async)">
            <header class="commissionTitleTabGroupTemplateHeader">
                <div class="title-text">
                    <div class="header-row">
                        <h3 *ngIf="data.Description">{{ data.Description }}</h3>
                        <h3 *ngIf="CommissionMainData | async as mainData">
                            <fa-icon [icon]="mainData.HeaderIcon"></fa-icon>
                            {{ mainData.Headline }}
                        </h3>
                        <h3 *ngIf="CommissionType$ | async as commissionType">
                            {{ commissionType.Name }}
                        </h3>
                        <span *ngIf="CommissionAddress$ | async as address">
                            <fa-icon icon="location-dot"></fa-icon>
                            <span class="link" (click)="OpenMapDialog(data)">
                                {{ address }}
                            </span>
                        </span>
                    </div>
                    <h3 *ngIf="!!(Customer$ | async)?.Name || data.CustomerName">
                        <fa-icon icon="building"></fa-icon>
                        {{ (Customer$ | async)?.Name || data.CustomerName }}
                    </h3>
                </div>
                <div class="title-buttons">
                    <div class="akkord-button-wrapper" *ngIf="PS.Has(PS.Permission.GetAkkord) | async">
                        <a app-button Lighter [matTooltip]="AkkordMeta.Breadcrumb" [routerLink]="['/', AkkordMeta.Path, data.Id]">
                            <fa-icon [icon]="AkkordMeta.Icon"></fa-icon>
                        </a>
                    </div>
                    <app-go-to-button *ngIf="!!data.CustomerId && data.PartnerId === (Partner$ | async)?.Id" EntityType="customer" matTooltip="Kunden" [EntityId]="data.CustomerId"></app-go-to-button>
                    <app-go-to-button *ngIf="data.ParentId" EntityType="commission" matTooltip="Auftrag" [EntityId]="data.ParentId"></app-go-to-button>
                    <app-go-to-button *ngIf="(PS.Has(PS.Permission.GetFiles) | async) && (FolderId$ | async) as id" EntityType="folder" matTooltip="DMS" [EntityId]="id"></app-go-to-button>
                    <div class="history-button-wrapper" *ngIf="PS.Has(PS.Permission.GetEvents) | async">
                        <a
                            *ngIf="!!data.CustomerId && data.PartnerId === (Partner$ | async)?.Id"
                            app-button
                            Lighter
                            matTooltip="Chronik"
                            [routerLink]="['/', ChronikPath, data.CustomerId]"
                            [queryParams]="NavigateChronikFilter$ | async"
                        >
                            <fa-icon [icon]="ChronikIcon | icon"></fa-icon>
                        </a>
                    </div>
                </div>
            </header>
            <!--            <mat-card-content>-->
            <mat-tab-group #commissionTitleTabGroupTemplateTabGroup *ngIf="view.elements?.length" preserveContent>
                <mat-tab id="{{ childView.type }}" *ngFor="let childView of view.elements" [label]="childView.headline" #tab>
                    <ng-template matTabContent>
                        <ng-container [ngTemplateOutlet]="GetTemplateByType(childView.type)" [ngTemplateOutletContext]="{ view: childView, tab: tab }"></ng-container>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
    </ng-container>
</ng-template>
<ng-template #detailListTemplateCommission let-view="view" let-tab="tab">
    <div style="max-width: 100vw" *ngIf="Commission$ | async as data">
        <app-detail-list-template class="detail-card" [Editing]="Editing$ | async" [Data]="CommissionMainData | async"> </app-detail-list-template>
        <mat-expansion-panel class="custom-mat-expansion-panel detail-card" [expanded]="false">
            <mat-expansion-panel-header class="expansion-header"><h2>{{(cls.getLabels$('Commission') | async)?.wordBinding + 'daten'}}</h2></mat-expansion-panel-header>
            <mat-tab-group>
                <mat-tab [label]="(cls.getLabels$('Commission') | async)?.wordBinding + 'daten'">
                    <app-detail-list-template [Data]="CommissionSubData | async" [Editing]="Editing$ | async"></app-detail-list-template>
                </mat-tab>
                <ng-container *ngIf="(Partner$ | async)?.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser">
                    <mat-tab label="Schadensort / -daten">
                        <app-detail-list-template [Data]="DamageFlowData$ | async" [Editing]="Editing$ | async"></app-detail-list-template>
                    </mat-tab>
                    <mat-tab label="Versicherungsdaten" *ngIf="!(IsVersicherung$ | async)">
                        <app-detail-list-template [Data]="VersicherungsData$ | async" [Editing]="Editing$ | async"></app-detail-list-template>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>
        </mat-expansion-panel>
    </div>
</ng-template>
<ng-template #taskTemplate let-view="view">
    <ng-container *ngIf="Commission$ | async as data">
        <div class="taskboard-header">
            <fa-icon icon="clipboard-check" style="font-size: 1.5rem; padding-left: 1rem;"></fa-icon>
            <button app-round-button matTooltip="Sortieren" aria-label="Sortieren" style="margin: 0.5rem" [matMenuTriggerFor]="sortMenu">
                <fa-icon icon="sort"></fa-icon>
            </button>
        </div>
        <mat-menu #sortMenu >
            <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'endDate', direction: 'asc'}"><fa-icon icon="chevron-up"></fa-icon> Frist</button>
            <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'endDate', direction: 'dsc'}"><fa-icon icon="chevron-down"></fa-icon> Frist</button>
            <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'priority', direction: 'asc'}"><fa-icon icon="chevron-up"></fa-icon> Priorität</button>
            <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'priority', direction: 'dsc'}"><fa-icon icon="chevron-down"></fa-icon> Priorität</button>
        </mat-menu>
        <mat-card>
            <app-tasks-board [Filter]="{ CommissionIds: [data.Id] }" [Sort]="TaskBoardSort" (FileClicked)="PreviewedFileId$.next($event); PreviewOpen = true"></app-tasks-board>
        </mat-card>
    </ng-container>
</ng-template>
<ng-template #shiftPlanTemplate let-view="view" let-tab="tab">
    <ng-container *ngIf="Commission$ | async as data">
        <mat-card style="max-height: 50vh" *ngIf="!tab || tab.isActive">
            <app-shift-plan
                #shiftPlanComponent
                [Headline]="false"
                (OnViewChange)="ShiftPlanActiveView$.next($event)"
                [View]="ShiftPlanActiveView$ | async"
                [AdditionalButtons]="AdditionalShiftPlanButtons"
                [AutoFilterCommissionByCurrentCommissionShifts]="false"
                [HeaderColorful]="false"
                [ShowResourcePlanButton]="false"
                [CommissionId]="data.Id"
            ></app-shift-plan>
        </mat-card>
    </ng-container>
</ng-template>
<ng-template #resourcePlanTemplate let-view="view" let-tab="tab">
    <ng-container *ngIf="Commission$ | async as data">
        <mat-card style="max-height: 50vh" *ngIf="!tab || tab.isActive">
            <app-resource-plan
                #resourcePlanComponent
                [ShowAddResourceButton]="(CommissionType$ | async)?.GeneratedEmployeeResourceSchedules?.length > 0"
                (AddResourceButtonClick)="onAddResourcesButtonClick()"
                (OnViewChange)="ResourcePlanActiveView$.next($event)"
                [View]="ResourcePlanActiveView$ | async"
                [AdditionalButtons]="AdditionalResourcePlanButtons"
                [HeaderColorful]="false"
                [ShowShiftplanButton]="false"
                style="max-height: 25rem; min-height: 25rem"
                [CommissionId]="data.Id"
            ></app-resource-plan>
        </mat-card>
    </ng-container>
</ng-template>
<ng-template #fileExplorerTemplate let-view="view">
    <app-file-explorer
        PreventFileClick
        PreventFileUploadClick
        (FilterOpen$)="ShownButtons = !$event"
        [SelectedFolderId]="(Folder$ | async)?.Id"
        [FileUploadButton]="true"
        (FileUploadClicked)="UploadFolderId = $event; PreviewOpen = false; OpenUploadDialog()"
        (FileClicked)="PreviewedFileId$.next($event); PreviewOpen = true"
        (fileExplorerContext) = "ImageIds = $event"
        class="mat-card"
        style="height: 40rem; padding: 0"
    >
    </app-file-explorer>
</ng-template>
<ng-template #eventListTemplate let-view="view">
    <app-detail-commission-event-list
        *ngIf="!(Editing$ | async) && (PS.Has(PS.Permission.GetServiceHistory) | async)"
        [View]="view"
        [CommissionId]="(Commission$ | async)?.Id"
    ></app-detail-commission-event-list>
</ng-template>
<ng-template #personListTemplate let-view="view">
    <app-dave-list-card
        *ngIf="!(Editing$ | async) && !(IsGericht$ | async)"
        [Headline]="'Projektbeteiligte'"
        [HeaderIcon]="ContactBookMeta.Icon"
        [EmptyText]="'Keine beteiligten Personen vorhanden'"
        [Data]="ShowBeteiligteList$ | async"
        [DisplayedCollums]="['custom']"
        [Clickable]="true"
        [GetRouterLinkFunction]="GetRouteForPerson"
        [CustomCellTemplates]="{ custom: personListEntry }"
        [IsExpandable]="true"
        [Expanded]="view.expanded"
    >
        <button
            headerButton
            app-round-button
            matTooltipPosition="left"
            matTooltip="Hinzufügen"
            (click)="OpenPersonDialog('Beteiligte Personen', 'beteiligte person')"
            [Disabled]="(Commission$ | async)?.Deleted || !((Commission$ | async)?.CanEdit && (PS.Has(PS.Permission.EditCommission) | async))"
        >
            <app-double-icon Icon="user" Icon2="plus"></app-double-icon>
        </button>
    </app-dave-list-card>
</ng-template>
<ng-template #bookingPositionListTemplate let-view="view">
    <app-booking-position-list-card
        *ngIf="PS.Has(PS.Permission.GetAccountsReceivableLedgers) | async"
        [Data]="BookingPositions$"
        (EntryClicked$)="BookingPositionClicked($event.entry, $event.element)"
        (NewClicked$)="NewBookingPositionClick$.next()"
        [Clickable]="true"
        [IsExpandable]="true"
        [Expanded]="view.expanded"
        [NewButton]="true"
        [HideColumns]="['InvoiceNumber']"
        style="max-height: 50vh"
    >
    </app-booking-position-list-card>
</ng-template>

<ng-template #ledgerImportListTemplate let-view="view" let-index="index" let-countArray="countArray">
    <ng-container *ngIf="PS.Has(PS.Permission.GetLedgerImports) | async">
        <app-ledger-import-list-card [NavigateOnClick]="true" [view]="view" *ngIf="LedgerImports$ | async as lis; else loadingTemplate" [LedgerImports]="lis" (NewLedgerImportClick)="NewLedgerImportClick$.next($event)" (DocumentClick)="PreviewedFileId$.next($event); PreviewOpen = true;"></app-ledger-import-list-card>
    </ng-container>
</ng-template>

<ng-template #dateColumn let-data="data">
    {{ data.EventDate | date }}&nbsp;
</ng-template>
<ng-template #nachnahmenListTemplate let-view="view" let-index="index" let-countArray="countArray">
    <app-dave-list-card
        [HasSuffixButton]="true"
        (Count)="countArray ? countArray[index] = $event : null"
        [Headline]="view.headline"
        EmptyText="Keine Zusatzarbeiten vorhanden"
        [Data]="Nachnahmen$ | async"
        [DisplayedCollums]="['Id', 'Name', 'Description', 'EventDate', 'FileCount' ]"
        [TableHeaderCollumsMap]="{ Id: 'Id', Name: 'Name', Description: 'Beschreibung', EventDate: 'Datum', FileCount: 'Anhänge' }"
        [CustomCellTemplates]="{ EventDate: dateColumn }"
        [DefaultSortActive]="'EventDate'"
        [Clickable]="true"
        (EntryClicked$)="OpenEditEventDialog($event.entry, $event.element)"
        [IsExpandable]="true"
        [Expanded]="view.expanded"
        style="max-height: 50vh"
    >
        <button *ngIf="NachnahmeType$ | async as type" headerButton app-round-button (click)="OpenAddEventDialog(type.Id, type.Name)" matTooltip="Zusatzarbeiten hinzufügen">
            <app-double-icon [Icon]="type.ImagePath | icon" Icon2="plus"></app-double-icon>
        </button>
    </app-dave-list-card>
</ng-template>
<ng-template #erschwernisListTemplate let-view="view" let-index="index" let-countArray="countArray">
    <app-dave-list-card
        (Count)="countArray ? countArray[index] = $event : null"
        [Headline]="view.headline"
        EmptyText="Keine Erschwernisse vorhanden"
        [Data]="Mangel$ | async"
        [DisplayedCollums]="['Id', 'Name', 'Description', 'EventDate', 'FileCount' ]"
        [TableHeaderCollumsMap]="{ Id: 'Id', Name: 'Name', Description: 'Beschreibung', EventDate: 'Datum', FileCount: 'Anhänge' }"
        [CustomCellTemplates]="{ EventDate: dateColumn }"
        [DefaultSortActive]="'EventDate'"
        [Clickable]="true"
        (EntryClicked$)="OpenEditEventDialog($event.entry, $event.element)"
        [IsExpandable]="true"
        [Expanded]="view.expanded"
        style="max-height: 50vh"
    >
        <button *ngIf="MangelType$ | async as type" headerButton app-round-button (click)="OpenAddEventDialog(type.Id, type.Name)" [matTooltip]="type.Name + ' hinzufügen'">
            <app-double-icon [Icon]="type.ImagePath | icon" Icon2="plus"></app-double-icon>
        </button>
    </app-dave-list-card>
</ng-template>

<ng-template #mangelanzeigeListTemplate let-view="view" let-index="index" let-countArray="countArray">
    <app-dave-list-card
        (Count)="countArray ? countArray[index] = $event : null"
        [Headline]="view.headline"
        EmptyText="Keine Mängelanzeigen vorhanden"
        [DisplayedCollums]="['Id', 'Name', 'Description', 'EventDate', 'FileCount' ]"
        [TableHeaderCollumsMap]="{ Id: 'Id', Name: 'Name', Description: 'Beschreibung', EventDate: 'Datum', FileCount: 'Anhänge' }"
        [CustomCellTemplates]="{ EventDate: dateColumn }"
        [DefaultSortActive]="'EventDate'"
        [Data]="Mangelanzeigen$ | async"
        [Clickable]="true"
        (EntryClicked$)="OpenEditEventDialog($event.entry, $event.element)"
        [HasSuffixButton]="true"
        [IsExpandable]="true"
        [Expanded]="view.expanded"
        style="max-height: 50vh"
    >
        <button *ngIf="MangelanzeigeType$ | async as type" headerButton app-round-button (click)="OpenAddEventDialog(type.Id, type.Name)" [matTooltip]="type.Name + ' hinzufügen'">
            <app-double-icon [Icon]="type.ImagePath | icon" Icon2="plus"></app-double-icon>
        </button>
    </app-dave-list-card>
</ng-template>

<ng-template #bedenkenanzeigeListTemplate let-view="view" let-index="index" let-countArray="countArray">
    <app-dave-list-card
        (Count)="countArray ? countArray[index] = $event : null"
        [Headline]="view.headline"
        EmptyText="Keine Bedenkenanzeigen vorhanden"
        [Data]="Bedenkenanzeigen$ | async"
        [DisplayedCollums]="['Id', 'Name', 'Description', 'EventDate', 'FileCount' ]"
        [TableHeaderCollumsMap]="{ Id: 'Id', Name: 'Name', Description: 'Beschreibung', EventDate: 'Datum', FileCount: 'Anhänge' }"
        [CustomCellTemplates]="{ EventDate: dateColumn }"
        [DefaultSortActive]="'EventDate'"
        [Clickable]="true"
        (EntryClicked$)="OpenEditEventDialog($event.entry, $event.element)"
        [IsExpandable]="true"
        [Expanded]="view.expanded"
        style="max-height: 50vh"
    >
        <button *ngIf="BedenkenanzeigeType$ | async as type" headerButton app-round-button (click)="OpenAddEventDialog(type.Id, type.Name)" [matTooltip]="type.Name + ' hinzufügen'">
            <app-double-icon [Icon]="type.ImagePath | icon" Icon2="plus"></app-double-icon>
        </button>
    </app-dave-list-card>
</ng-template>

<ng-template #bookedBookingPositionListTemplate let-view="view" let-index="index" let-countArray="countArray">
    <app-booking-position-list-card
      *ngIf="PS.Has(PS.Permission.GetAccountsReceivableLedgers) | async"
      [Data]="BookedBookingPositions$"
      (EntryClicked$)="BookingPositionClicked($event.entry, $event.element)"
      [Clickable]="true"
      [IsExpandable]="true"
      [Expanded]="view.expanded"
      HeaderIcon2="slash"
      Headline="Vergebene Abr.-Pos."
      style="max-height: 50vh"
    >
    </app-booking-position-list-card>
</ng-template>
<ng-template #hiddenBookingPositionListTemplate let-view="view" let-index="index" let-countArray="countArray">
    <app-dave-list-card
        (Count)="countArray ? countArray[index] = $event : null"
        Headline="Versteckte Abrechnungspositionen"
        *ngIf="(PS.Has(PS.Permission.GetAccountsReceivableLedgers) | async) && (HiddenBookingPositions$ | async)?.length > 0"
        [HeaderIcon]="BookingPositionMetaIcon"
        HeaderIcon2="slash"
        EmptyText="Keine versteckten Abrechnungspositionen vorhanden"
        [Data]="HiddenBookingPositions$"
        Hint="Diese Abrechnungspositionen wurden in Rechnungen, Abschlagsrechnungen und Schlussrechnungen in einer neuen Abrechnungspositionen zusammengefasst."
        [DisplayedCollums]="
            ['BookingText', 'Information', 'CompleteCost', 'InvoiceNumber']
        "
        [TableHeaderCollumsMap]="{
            BookingText: 'Art-Nr.',
            Information: 'Bezeichnung',
            CompleteCost: 'Summe',
            Quantity: 'Menge',
            Unit: 'Einheit',
            InvoiceNumber: 'Re-Nr.'
        }"
        [IsExpandable]="true"
        [Expanded]="view.expanded"
        style="max-height: 50vh"
    >
    </app-dave-list-card>
</ng-template>
<ng-template #judgeListTemplate let-view="view">
    <app-dave-list-card
        *ngIf="IsGericht$ | async"
        Headline="Vorsitzenden"
        HeaderIcon="users"
        EmptyText="Keine Vorsitzenden vorhanden"
        [Data]="ShownJudgeList$ | async"
        [DisplayedCollums]="['custom']"
        [Clickable]="true"
        [GetRouterLinkFunction]="GetRouteForPerson"
        [CustomCellTemplates]="{ custom: personListEntry }"
        [IsExpandable]="true"
        [Expanded]="view.expanded"
    >
        <button
            headerButton
            app-round-button
            matTooltipPosition="left"
            matTooltip="Hinzufügen"
            (click)="OpenPersonDialog('Vorsitzender', 'vorsitzender')"
            [Disabled]="(Commission$ | async)?.Deleted || !((Commission$ | async)?.CanEdit && (PS.Has(PS.Permission.EditCommission) | async))"
        >
            <app-double-icon Icon="user" Icon2="plus"></app-double-icon>
        </button>
    </app-dave-list-card>
</ng-template>
<ng-template #reportsListDiary let-view="view" let-index="index" let-countArray="countArray">
    <ng-container [ngTemplateOutlet]="reportsList" [ngTemplateOutletContext]="{view: view, index: index, countArray: countArray, data: DiaryReports$, reportType: GeneratedDocumentsTypeSlugs.constructionDiary }"></ng-container>
</ng-template>
<ng-template #reportsListZusatz let-view="view" let-index="index" let-countArray="countArray">
    <ng-container [ngTemplateOutlet]="reportsList" [ngTemplateOutletContext]="{view: view, index: index, countArray: countArray, data: ZusatzReports$, reportType: GeneratedDocumentsTypeSlugs.zusatzarbeit }"></ng-container>
</ng-template>
<ng-template #reportsListBedenken let-view="view" let-index="index" let-countArray="countArray">
    <ng-container [ngTemplateOutlet]="reportsList" [ngTemplateOutletContext]="{view: view, index: index, countArray: countArray, data: BedenkenReports$, reportType: GeneratedDocumentsTypeSlugs.bedenken }"></ng-container>
</ng-template>
<ng-template #reportsListMangel let-view="view" let-index="index" let-countArray="countArray">
    <ng-container [ngTemplateOutlet]="reportsList" [ngTemplateOutletContext]="{view: view, index: index, countArray: countArray, data: MangelReports$, reportType: GeneratedDocumentsTypeSlugs.mangel }"></ng-container>
</ng-template>
<ng-template #reportsListOther let-view="view" let-index="index" let-countArray="countArray">
    <ng-container [ngTemplateOutlet]="reportsList" [ngTemplateOutletContext]="{view: view, index: index, countArray: countArray, data: OtherReports$, excludeReportTypes: [GeneratedDocumentsTypeSlugs.constructionDiary, GeneratedDocumentsTypeSlugs.zusatzarbeit, GeneratedDocumentsTypeSlugs.bedenken, GeneratedDocumentsTypeSlugs.mangel] }"></ng-container>
</ng-template>
<ng-template #reportsList let-view="view" let-index="index" let-countArray="countArray" let-data="data" let-showIcon="showIcon" let-reportType="reportType" let-excludeReportTypes="excludeReportTypes">
    <app-dave-list-card
        (Count)="countArray ? countArray[index] = $event : null"
        [Headline]="view.headline"
        [HeaderIcon]="showIcon ? 'file-signature' : null"
        [EmptyText]="'Keine ' + view.headline +  ' vorhanden'"
        [Data]="data"
        [HasSuffixButton]="true"
        [DisplayedCollums]="['label']"
        [Clickable]="true"
        [GetRouterLinkFunction]="GetRouteForGenDoc"
        [IsExpandable]="true"
        [Expanded]="view.expanded"
        style="max-height: 50vh"
    >
        <app-add-report-button headerButton [PreselectedType]="reportType" [ExcludeTypes]="excludeReportTypes" [CustomerId]="(Commission$ | async)?.CustomerId" [CommissionId]="(Commission$ | async)?.Id"></app-add-report-button>
    </app-dave-list-card>
</ng-template>
<ng-template #klaegerListTemplate let-view="view">

    <app-dave-list-card
        *ngIf="!(Editing$ | async) && (IsGericht$ | async)"
        Headline="Partei: Kläger"
        HeaderIcon="users"
        EmptyText="Keine Kläger vorhanden"
        [Data]="ShowKlaeger$ | async"
        [DisplayedCollums]="['custom']"
        [Clickable]="true"
        [GetRouterLinkFunction]="GetRouteForPerson"
        [CustomCellTemplates]="{ custom: personListEntry }"
        [IsExpandable]="true"
        [Expanded]="view.expanded"
    >
        <button
            headerButton
            app-round-button
            matTooltipPosition="left"
            (click)="OpenPersonDialog('Kläger', 'klagender')"
            [Disabled]="(Commission$ | async)?.Deleted || !((Commission$ | async)?.CanEdit && (PS.Has(PS.Permission.EditCommission) | async))"
            matTooltip="Hinzufügen"
        >
            <app-double-icon Icon="user" Icon2="plus"></app-double-icon>
        </button>
    </app-dave-list-card>
</ng-template>
<ng-template #beklagteListTemplate let-view="view">
    <app-dave-list-card
        *ngIf="!(Editing$ | async) && (IsGericht$ | async)"
        Headline="Partei: Beklagte"
        HeaderIcon="users"
        EmptyText="Keine Beklagten vorhanden"
        [Data]="ShowBeklagte$ | async"
        [DisplayedCollums]="['custom']"
        [Clickable]="true"
        [GetRouterLinkFunction]="GetRouteForPerson"
        [CustomCellTemplates]="{ custom: personListEntry }"
        [IsExpandable]="true"
        [Expanded]="view.expanded"
    >
        <button
            headerButton
            app-round-button
            matTooltipPosition="left"
            (click)="OpenPersonDialog('Beklagte', 'beklagter')"
            [Disabled]="(Commission$ | async)?.Deleted || !((Commission$ | async)?.CanEdit && (PS.Has(PS.Permission.EditCommission) | async))"
            matTooltip="Hinzufügen"
        >
            <app-double-icon Icon="user" Icon2="plus"></app-double-icon>
        </button>
    </app-dave-list-card>
</ng-template>
<ng-template #selectedPartnerListTemplate let-view="view">
    <mat-expansion-panel class="custom-mat-expansion-panel" [expanded]="view.expanded" *ngIf="!(Editing$ | async) && (NetworkPartners$ | async).length > 1">
        <mat-expansion-panel-header [collapsedHeight]="HeaderHeight">
            <div class="card-header">
                <fa-icon class="icon" icon="user"></fa-icon>

                <h3>Wird bearbeitet von:</h3>

                <!-- ToDo 'a' statt 'button', wenn event.stopImmediatePropagation() tut -->
                <button
                    app-round-button
                    matTooltipPosition="left"
                    (click)="OpenPartnerSelect()"
                    [Disabled]="
                        (Commission$ | async)?.Deleted ||
                        !((Commission$ | async)?.CanEdit && (PS.Has(PS.Permission.EditCommission) | async)) ||
                        !((Commission$ | async)?.CanShareWithPartner && (PS.Has(PS.Permission.CanShareWithPartner) | async))
                    "
                    matTooltip="Hinzufügen"
                >
                    <app-double-icon Icon="user" Icon2="plus"></app-double-icon>
                </button>
            </div>
        </mat-expansion-panel-header>
        <mat-card class="card">
            <!-- "clickable"-Klasse ist nur für den inset box shadow -->
            <ngx-simplebar class="clickable-list-container">
                <table mat-table [dataSource]="ShowSelectedPartners$ | async">
                    <ng-container matColumnDef="Name">
                        <td mat-cell *matCellDef="let rowData">
                            <label>{{ rowData.PartnerNo || 'No.' }} &nbsp; {{ rowData.Name || 'Platzhalter' }}</label>
                        </td>
                    </ng-container>
                    <tr mat-row *matRowDef="let rowData; columns: ['Name']" [routerLink]="['/', 'partner', rowData.Id]" routerLinkActive="active"></tr>
                </table>

                <table *ngIf="!((ShowSelectedPartners$ | async)?.length > 0)" style="width: 100%">
                    <tr>
                        <td class="empty-table-text">Kein Mitglied zugeordnet</td>
                    </tr>
                </table>
            </ngx-simplebar>
        </mat-card>
    </mat-expansion-panel>
</ng-template>
<ng-template #selectedUsersListTemplate let-view="view">
    <mat-expansion-panel class="custom-mat-expansion-panel" [expanded]="view.expanded" *ngIf="!(Editing$ | async)">
        <mat-expansion-panel-header [collapsedHeight]="HeaderHeight">
            <div class="card-header">
                <fa-icon class="icon" icon="users"></fa-icon>

                <h3>Bearbeiter ({{ (ShowSelectedUsers$ | async)?.length }})</h3>

                <!-- ToDo 'a' statt 'button', wenn event.stopImmediatePropagation() tut -->
                <!--                    (click)="OpenUserSelect()"-->
                <button
                    class="adjustLeft"
                    app-round-button
                    matTooltipPosition="left"
                    (click)="OpenUserSelect()"
                    [Disabled]="(Commission$ | async)?.Deleted || !((Commission$ | async)?.CanRoleEdit && (PS.Has(PS.Permission.EditRoleCommissionManagement) | async))"
                    matTooltip="Auswählen"
                >
                    <app-double-icon Icon="user-cog"></app-double-icon>
                </button>
            </div>
        </mat-expansion-panel-header>
        <mat-card class="card">
            <!-- "clickable"-Klasse ist nur für den inset box shadow -->
            <ngx-simplebar class="clickable-list-container">
                <table mat-table [dataSource]="ShowSelectedUsers$ | async">
                    <ng-container matColumnDef="Name">
                        <td mat-cell *matCellDef="let rowData">
                            <label>
                                <fa-icon icon="envelope" *ngIf="rowData.Assignee"></fa-icon>
                                <fa-icon icon="check" *ngIf="!rowData.Assignee"></fa-icon>
                                {{ rowData.User.DisplayName || 'Name' }}&nbsp;:&nbsp;
                                <span [innerHTML]="'<i>' + rowData.roles.join('</i> | <i>') + '</i>'"></span>
                            </label>
                        </td>
                    </ng-container>
                    <tr mat-row *matRowDef="let rowData; columns: ['Name']" [routerLink]="['/', 'personal_und_urlaub', rowData.User.Id]" routerLinkActive="active"></tr>
                </table>

                <table *ngIf="!((ShowSelectedUsers$ | async)?.length > 0)" style="width: 100%">
                    <tr>
                        <td class="empty-table-text">Keine Mitarbeiter zugeordnet</td>
                    </tr>
                </table>
            </ngx-simplebar>
        </mat-card>
    </mat-expansion-panel>
</ng-template>
<ng-template #commentsTemplate let-view="view">
    <app-comment-list
        *ngIf="Commission$ | async as commission"
        [CanDelete]="IamAssignee$ | async"
        [ViewConfig]="view"
        [EntityId]="commission.Id"
        [EntityType]="CommentEntityTypeEnum.Commission"
        [InlineSendMethod]="true"
        [FileUploadParams]="CommentFileUploadParams$ | async"
    ></app-comment-list>
</ng-template>
<ng-template #commissionOverviewTemplate let-view="view">
    <mat-card>
        <app-commission-overview *ngIf="Commission$ | async as commission" [CommissionId]="commission.Id"></app-commission-overview>
    </mat-card>
</ng-template>

<ng-template #resourceListTemplate let-view="view">
    <app-resource-to-commission-popup [CommissionId]="(Commission$ | async)?.Id"  [Expanded]="view.expanded" [Expandable]="true" ></app-resource-to-commission-popup>
</ng-template>

<ng-template #resourceScheduleListeTemplate let-view="view">
    <app-resource-schedule-list-card [CommissionId]="(Commission$ | async)?.Id" [Expanded]="view.expanded" [Expandable]="true"></app-resource-schedule-list-card>
</ng-template>

<ng-template #materialListTemplate let-view="view">
    <app-material-list [CommissionId]="(Commission$ | async)?.Id" [Expanded]="view.expanded" [Expandable]="true"></app-material-list>
</ng-template>

<ng-template #groupListTemplate let-view="view">
    <app-group-list [CommissionId]="(Commission$ | async)?.Id" [Expanded]="view.expanded" [Headline]="view.headline" (SendEmailClick)="OpenMailDialog($event.group, $event.toType)"></app-group-list>
</ng-template>
<ng-template #childCommissionsTemplate let-view="view" let-index="index" let-countArray="countArray">
    <app-dave-list-card
        (Count)="countArray ? countArray[index] = $event : null"
        [Headline]="((isTsb$ | async) ? 'Aufträge' : (view.headline || 'Teilprojekte'))"
        [HeaderIcon]="CommissionMeta.Icon"
        [EmptyText]="'Keine ' + ((isTsb$ | async) ? 'Aufträge' : (view.headline || 'Teilprojekte')) + ' vorhanden'"
        [Data]="ChildCommissions$ | async"
        [DisplayedCollums]="childCommissionColumns$ | async"
        [TableHeaderCollumsMap]="{Auftragsnummer: 'Auftragsnummer', Description: 'Bezeichnung', InterneNummer: 'Interne Nr.', createdAt: 'Erstelldatum', status: 'Status'}"
        [Clickable]="true"
        [GetRouterLinkFunction]="GetRouteForCommissionImport"
        [IsExpandable]="true"
        [Expanded]="view.expanded"
        style="max-height: 50vh"
    >
        <button headerButton app-round-button [matTooltip]="(cls.getSingle$('Commission') | async) + ' zuordnen'" (click)="addChildCommissionClick()">
            <app-double-icon [Icon]="CommissionMeta.Icon" Icon2="plus"></app-double-icon>
        </button>
    </app-dave-list-card>
</ng-template>
<ng-template #CommissionBusinessVolumeList let-view="view" let-index="index" let-countArray="countArray">
    <app-commission-busines-volume-list
        *ngIf="PS.Has$(PS.Permissions.GetBusinessVolume) | async"
        style="max-height: 50vh"
        [Expandable]="true"
        [Expanded]="view.expanded"
        [CommissionId]="(Commission$ | async)?.Id"
        [Columns]="['customerName', 'businessVolumeNo', 'amount', 'createdAt']"
        [tableLayoutFixed]="true"
    >
    </app-commission-busines-volume-list>
</ng-template>
<ng-template #personListEntry let-data="data">
    <app-person-list-entry [Person]="data"></app-person-list-entry>
</ng-template>
<ng-template #blueprintTemplate>
    <app-blueprint [CommissionId]="(Commission$ | async)?.Id" (EventMarkerClicked)="OpenEditEventDialog($event)" (EventListEntryClicked)="OpenEditEventDialog($event)"></app-blueprint>
</ng-template>
