import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageEditorComponent } from './image-editor.component';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MobileImageEditorComponent } from './mobile-image-editor/mobile-image-editor.component';
import {MatMenuModule} from "@angular/material/menu";
import {DaveDoubleIconModule} from "../../../dave-double-icon/dave-double-icon.module";

@NgModule({
    declarations: [ImageEditorComponent, MobileImageEditorComponent],
    imports: [CommonModule, AppButtonModule, FontAwesomeModule, MatMenuModule, DaveDoubleIconModule],
    exports: [
        ImageEditorComponent,
    ],
})
export class ImageEditorModule {}
