<mat-expansion-panel *ngIf="Expandable" class="panel" [expanded]="ViewConfig.expanded" (opened)="Visible = true" #panel>
    <mat-expansion-panel-header class="card-header card-header-expandable" collapsedHeight="HeaderHeight" *ngIf="!HideHeadline">
        <ng-container [ngTemplateOutlet]="headline"></ng-container>
        <div class="header-button-wrapper" (click)="$event.stopPropagation()">
            <!--            <button app-round-button *ngIf="!InlineSendMethod" [Disabled]="NewComment" (click)="panel.expanded = true; SetEditorForNew()" matTooltip="Kommentar hinzufügen"><fa-icon icon="plus"></fa-icon></button>-->
        </div>
    </mat-expansion-panel-header>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</mat-expansion-panel>
<mat-card *ngIf="!Expandable" class="panel fixed-height" [class.no-shadow]="Inline">
    <mat-card-header class="card-header card-header-expandable" *ngIf="!HideHeadline">
        <ng-container [ngTemplateOutlet]="headline"></ng-container>
        <div class="header-button-wrapper" (click)="$event.stopPropagation()">
            <!--            <button app-round-button *ngIf="!InlineSendMethod" [Disabled]="NewComment" (click)="SetEditorForNew()" matTooltip="Kommentar hinzufügen"><fa-icon icon="plus"></fa-icon></button>-->
        </div>
    </mat-card-header>

    <ng-container [ngTemplateOutlet]="content"></ng-container>
</mat-card>
<ng-template #content>
    <div class="scroll-container">
        <div class="container">
            <div>
                <ng-container *ngFor="let comment of ShownComments$ | async">
                    <app-comment-card [CommentId]="comment.commentId" [CanDelete]="CanDelete" [FileUploadParams]="FileUploadParams"></app-comment-card>
                </ng-container>
                <!--                <ng-container *ngIf="NewComment" [ngTemplateOutlet]="commentCard" [ngTemplateOutletContext]="NewCommentDefaults$ | async"></ng-container>-->
            </div>
        </div>
    </div>
    <app-comment-inline-editor
        #inlineEditorComponent
        class="inline-send"
        [EntityId]="entityId$ | async"
        [EntityType]="entityType$ | async"
        [FileUploadParams]="FileUploadParams"
        [GetEntityIdBeforeSaveComment]="GetEntityIdBeforeSaveComment"
        [GetFileUploadParamsBeforeSaveComment]="GetFileUploadParamsBeforeSaveComment"
        [HideDropzone]="HideDropzone"
    ></app-comment-inline-editor>
</ng-template>

<ng-template #headline>
    <fa-stack matCardAvatar>
        <fa-icon [icon]="CommentIcon" stackItemSize="1x"></fa-icon>
    </fa-stack>
    <h3 class="headline">
        {{ ViewConfig.headline || 'Kommentare' }} ({{ (ShownComments$ | async)?.length || 0 }}) <span class="unseen" *ngIf="(UnseenComments$ | async)?.length as count">{{ count }}</span>
    </h3>
</ng-template>
