import { createEffect, ofType, Actions } from "@ngrx/effects";
import {
    withLatestFrom,
    switchMap,
    map,
    catchError, concatMap,
} from 'rxjs/operators';
import { Store } from "@ngrx/store";
import { DaveActions } from "../actions/actions";
import { HttpService } from "../../services/http.service";
import { State } from "../index";
import { PersonTypeActionTypes } from "../actions/personType.actions";
import { PersonTypeEntity, PersonTypeEntityFromBackend } from '../../entities/personType.entity';
import { BaseActionTypes } from "../actions/base.actions";
import { EMPTY, of } from 'rxjs';
import { Injectable } from "@angular/core";
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { GraphQlEffectService } from '../../services/graph-ql-effect.service';
import { DaveMutationDeletePersonTypeArgs, PersonTypeType } from '../../graphql-types';

enum ErrorCodes {
  Load = "Auftragstypen Abrufen fehlgeschlagen",
  Modify = "Auftragstypen Bearbeiten fehlgeschlagen",
  Add = 'PersonType Hinzufügen fehlgeschlagen',
    Remove = 'PersonType Löschen fehlgeschlagen'
}

@Injectable()
export class PersonTypeEffects {
    error$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PersonTypeActionTypes.CreateFailure),
            map(({Payload}) =>
                BaseActionTypes.ErrorAction({
                    Payload: {
                        ToasterMessage: Payload.ToasterMessage,
                        Err: Payload.Errors,
                    },
                }),
            ),
        ),
    );


    DeletePersonType$ = this.graphQlEffectService.createDeleteOneEffect<DaveMutationDeletePersonTypeArgs, PersonTypeEntity, PersonTypeType>(
        'deletePersonType',
        PersonTypeEntity.GqlFields.join(','),
        PersonTypeActionTypes.DeletePersonType,
        PersonTypeActionTypes.RemoveOne,
        (v) => v.id,
        ErrorCodes.Remove,
        ['id'],
    )

    CreatePersonType$ = createEffect( ()=> this.actions$.pipe(
        ofType(PersonTypeActionTypes.Create),
        concatMap(({Payload}) => {
            const queryString =
                ` mutation{
        createPersonType(
         ${stringifyIfNotNullOrUndefined(Payload, 'name')}
        ){${PersonTypeEntity.GqlFields.join(', ')}}
      }`;
            return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                map(res => {
                    return res && res.createPersonType && res.createPersonType.id
                        ? PersonTypeActionTypes.CreateSuccess({
                             Payload: PersonTypeEntityFromBackend(res.createPersonType),
                        })
                        : PersonTypeActionTypes.CreateFailure({ Payload: { Errors: 'wrong response', ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        PersonTypeActionTypes.CreateFailure({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Errors: err,
                            },
                        }),
                    ),
                ),
            );
        })
    ))

  LoadPersonTypes = createEffect(() => this.actions$.pipe(
    ofType(PersonTypeActionTypes.GetPersonTypeRequest),
    concatMap(() => {
      const queryString = `
      query{
        personType{
          ${PersonTypeEntity.GqlFields.join(', ')}
        }
      }`;
      return this.gatewayHttpService
        .graphQl({ query: queryString }, {  retry: true  })
        .pipe(
          map(res =>
            res && res.personType
              ? PersonTypeActionTypes.UpdatePersonType({
                  Payload: res.personType.map(val =>
                    PersonTypeEntityFromBackend(val)
                  )
                })
              : BaseActionTypes.ErrorAction({
                  Payload: { ToasterMessage: ErrorCodes.Load }
                })
          ),
          catchError((err, caught) =>
            of(
              BaseActionTypes.ErrorAction({
                Payload: {
                  ToasterMessage: ErrorCodes.Load,
                  Err: err,
                  Caught: caught
                }
              })
            )
          )
        );
    })
  ));
  constructor(
    private actions$: Actions<DaveActions>,
    private store$: Store<State>,
    private gatewayHttpService: HttpService,
    private graphQlEffectService: GraphQlEffectService,
  ) {}
}
