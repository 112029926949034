import {
    ActivatedRouteSnapshot,
    DetachedRouteHandle,
    RouteReuseStrategy,
} from '@angular/router';
import { DetailCustomerComponent } from '../components/detail-views/detail-customer/detail-customer.component';
import { DetailEmployeeComponent } from '../components/detail-views/detail-employee/detail-employee.component';
import { DetailPartnerComponent } from '../components/detail-views/detail-partner/detail-partner.component';
import { TimelineComponent } from '../components/history/timeline/timeline.component';
import { CustomerStatisticsComponent } from '../components/statistics/customer-statistics/customer-statistics.component';
import { DetailCommissionComponent } from '../dave-commission-module/components/detail-commission/detail-commission.component';
import { DetailVideodokumentationComponent } from '../dave-videodokumentation-module/components/detail-videodokumentation/detail-videodokumentation.component';
import { InvoiceEditorComponent } from '../dave-reports/components/invoice-editor/invoice-editor.component';
import { DetailPersonComponent } from '../dave-person-module/components/detail-person/detail-person.component';
import { Injectable } from "@angular/core";
import { ProcessDetailViewComponent } from '../process/components/process-detail-view/process-detail-view.component';

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
    handlers: { [key: string]: DetachedRouteHandle } = {};

    shouldDetach(): boolean {
        return false;
    }

    store(route: ActivatedRouteSnapshot): void {
        this.handlers[route.routeConfig.path] = null;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        if (!route.routeConfig) {
            return null;
        }
        return this.handlers[route.routeConfig.path];
    }

    shouldReuseRoute(
        future: ActivatedRouteSnapshot,
        curr: ActivatedRouteSnapshot,
    ): boolean {

        // console.log(curr, future)
        if (
            (curr.component === TimelineComponent &&
                future.component === TimelineComponent) ||
            (curr.component === DetailCustomerComponent &&
                future.component === DetailCustomerComponent) ||
            (curr.component === CustomerStatisticsComponent &&
                future.component === CustomerStatisticsComponent)
        ) {
            return (
                curr.paramMap.get('customerId') ===
                future.paramMap.get('customerId')
            );
        } else if (
            curr.component === DetailPartnerComponent &&
            future.component === DetailPartnerComponent
        ) {
            return (
                curr.paramMap.get('partnerId') ===
                future.paramMap.get('partnerId')
            );
        } else if (
            curr.component === DetailEmployeeComponent &&
            future.component === DetailEmployeeComponent
        ) {
            return (
                curr.paramMap.get('employeeId') ===
                future.paramMap.get('employeeId')
            );
        } else if (
            curr.component === DetailCommissionComponent &&
            future.component === DetailCommissionComponent
        ) {
            return (
                curr.paramMap.get('commissionId') ===
                future.paramMap.get('commissionId')
            );
        } else if (
            curr.component === DetailVideodokumentationComponent &&
            future.component === DetailVideodokumentationComponent
        ) {
            return (
                curr.paramMap.get('transmissionId') ===
                future.paramMap.get('transmissionId')
            );
        } else if (
            curr.component === DetailPersonComponent &&
            future.component === DetailPersonComponent
        ) {
            return (
                curr.paramMap.get('personId') ===
                future.paramMap.get('personId')
            );
        } else if (
            curr.component === InvoiceEditorComponent &&
            future.component === InvoiceEditorComponent
        ) {
            return false;
        } else {
            return future.routeConfig === curr.routeConfig;
        }
    }
}
