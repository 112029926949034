import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    MaterialState,
    Material_KEY,
    selectAllMaterial,
    selectMaterialEntities,
    selectMaterialFetched,
    selectMaterialLatestUpdatedAt,
} from '../reducers/material.reducer';

const selectState = createFeatureSelector<MaterialState>(Material_KEY);

export const getMaterialDictionary = createSelector(selectState, selectMaterialEntities);
export const getMaterials = createSelector(selectState, selectAllMaterial);
export const getMaterialsActive = createSelector(getMaterials, e => e.filter(v => !v.DeletedAt));
export const getMaterialsFetched = createSelector(selectState, selectMaterialFetched);
export const getMaterialLatestUpdatedAt = createSelector(selectState, selectMaterialLatestUpdatedAt);

export const getMaterialById = (props: { id: number }) => createSelector(getMaterialDictionary, (dictionary) => props?.id && dictionary[props.id]);
