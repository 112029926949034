import { CdkDragDrop,DragDropModule,moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component,EventEmitter,Inject,OnDestroy,OnInit,Output,TemplateRef,ViewChild } from "@angular/core";
import { FormControl,FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog,MatDialogModule,MatDialogRef,MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { Subscription } from "rxjs";
import { FileEntity } from '../../../dave-data-module/entities/file.entity';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { State } from '../../../dave-data-module/State';
import { FilesActionTypes } from '../../../dave-data-module/State/actions/files.actions';
import { AppButtonModule } from "../../../dave-utils-module/app-button-module/app-button.module";
import {
AppConfirmationDialogComponent,AppConfirmationDialogData
} from "../../../dave-utils-module/app-dialog-module/app-confirmation-dialog/app-confirmation-dialog.component";
import { DetailListDialogReturn,DetailListTemplateDialogComponent,DetailListTemplateDialogData } from '../../../detail-list-template-dialog/components/detail-list-template-dialog.component';

export interface FileOrderDialogComponentDialogData {
    fileIds: number[];
    withDelete?: boolean;
    withVersionUploadButton?: boolean;
    deleteDialogData?: AppConfirmationDialogData;
}
export type FileOrderDialogComponentDialogReturnData = number[];
@Component({
    selector: 'app-file-order-dialog',
    standalone: true,
    imports: [CommonModule, MatListModule, FontAwesomeModule, DragDropModule, MatButtonModule, MatDialogModule, AppButtonModule, MatMenuModule, MatTooltipModule],
    templateUrl: './file-order-dialog.component.html',
    styleUrls: ['./file-order-dialog.component.scss'],
})
export class FileOrderDialogComponent implements OnInit, OnDestroy {
    protected files: FileEntity[];
    private fileSub: Subscription;
    protected editDialogRef: MatDialogRef<any>;
    constructor(
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private dialogData: FileOrderDialogComponentDialogData,
        private fileDataService: FileDataService,
        private store: Store<State>,
        private dialogRef: MatDialogRef<FileOrderDialogComponent>,
    ) {}
    @Output() public deleteFile = new EventEmitter<number>();
    @Output() public versionUpload = new EventEmitter<number>();
    @Output() public versionUploadDms = new EventEmitter<number>();
    @ViewChild('fileUploadTemplate') fileUploadTemplate: TemplateRef<any>;

    ngOnInit(): void {
        this.fileSub = this.fileDataService.GetFilesById(this.dialogData.fileIds).subscribe((files) => {
            this.files = this.dialogData.fileIds.map((id) => files.find((f) => f.Id === id));
        });
    }
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.files, event.previousIndex, event.currentIndex);
    }

    ngOnDestroy(): void {
        this.fileSub?.unsubscribe();
        this.editDialogRef?.close(null);
    }

    onEditFileClick(file: FileEntity) {
        const form = new FormGroup({
            name: new FormControl<string>(file.Name),
            description: new FormControl<string>(file.Description),
        });
        this.editDialogRef = this.dialog.open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
            data: {
                DeleteButton: this.dialogData?.withDelete,
                DeleteButtonTooltip: 'Plan löschen',
                Editing: true,
                Data: {
                    Headline: 'Datei bearbeiten',
                    Properties: [
                        ...(this.dialogData?.withVersionUploadButton
                            ? [
                                  {
                                      key: 'upload',
                                      formControl: new FormControl<number>(file.Id),
                                      options: {
                                          specialInput: {
                                              customTemplate: this.fileUploadTemplate,
                                          },
                                      },
                                  },
                              ]
                            : []),
                        {
                            key: 'Name',
                            formControl: form.controls.name,
                        },
                        {
                            key: 'Beschreibung',
                            formControl: form.controls.description,
                        },
                    ],
                },
            },
        });
        this.editDialogRef.afterClosed().subscribe((result) => {
            if (result?.Action === 'save') {
                this.store.dispatch(FilesActionTypes.ModifyFileRequest({ Payload: {
                        DocumentId: file.Id.toString(),
                        Name: form.value.name,
                        Description: form.value.description
                } }));
            } else if (result?.Action === 'delete') {
                this.dialog
                    .open<AppConfirmationDialogComponent, AppConfirmationDialogData>(AppConfirmationDialogComponent, {
                        data: {
                            paragraph: 'Wollen Sie die Datei wirklich löschen ?',
                            styleDelete: true,
                            ...(this.dialogData?.deleteDialogData || {}),
                        },
                    })
                    .afterClosed()
                    .subscribe(([res]) => {
                        if (res) {
                            this.files = this.files.filter((f) => f.Id !== file.Id);

                            this.deleteFile.emit(file.Id);
                        }
                    });
            }
        });
    }

    onSaveClick() {
        this.dialogRef.close(this.files.map((f) => f.Id));
    }
}
