import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    damageFlowsFeatureKey,
    DamageFlowState,
    selectAllDamageFlow,
    selectDamageFlowEntities,
    selectDamageFlowFetched,
    selectDamageFlowLatestUpdatedAt,
} from '../reducers/damageflow.reducer';

const selectDamageFlow = createFeatureSelector<DamageFlowState>(damageFlowsFeatureKey);
const getDamageFlowEntities = createSelector(selectDamageFlow, selectDamageFlowEntities);

export const getDamageFlows = createSelector(selectDamageFlow, selectAllDamageFlow);
export const getDamageFlowsFetched = createSelector(selectDamageFlow, selectDamageFlowFetched);
export const getDamageFlowLatestUpdatedAt = createSelector(selectDamageFlow, selectDamageFlowLatestUpdatedAt);

export const getDamageFlowById = (props: { id: number }) => createSelector(getDamageFlowEntities, (dictionary) => props?.id && dictionary[props.id]);

export const getDamageFlowByCommissionId = (props: { commissionId: number }) =>
    createSelector(getDamageFlows, (damageflow) => (damageflow ? damageflow.find((e) => e.CommissionId === props.commissionId) : null));
