import { createAction, props } from '@ngrx/store';
import { CommissionEntity } from '../../entities/commission.entity';
import {
    DaveMutationAddResourcesFromLedgerImportIdArgs,
    DaveMutationChangeCommissionArgs,
    DaveMutationCreateCommissionArgs,
    DaveTypeCommissionArgs, QueryCommissionArgs
} from "../../graphql-types";

export const CommissionActionTypes = {
    UpdateAll: createAction('data/Commission/updateAll', props<{ Payload: Array<CommissionEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/Commission/updateMany', props<{ Payload: Array<CommissionEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction('data/Commission/updateOne', props<{ Payload: CommissionEntity, }>()),
    ModifyCommission: createAction('data/Commission/modify', props<{ Payload: DaveMutationChangeCommissionArgs }>()),
    AddCommission: createAction('data/Commission/add', props<{ Payload: DaveMutationCreateCommissionArgs }>()),
    DeleteCommission: createAction('data/Commission/delete', props<{ Payload: number }>()),
    Load: createAction('data/Commission/get', props<{ Payload?: QueryCommissionArgs }>()),
    /**
     * @deprecated Use the {@link UserToCommissionActionTypes} instead.
     */
    SetCommissionUser: createAction('data/Commission/set/user', props<{ Payload: { UserIds: number[]; AssigneeUserIds: number[]; CommissionId: number; } }>()),
    AddResources: createAction('data/Commission/addResources', props<{Payload: DaveMutationAddResourcesFromLedgerImportIdArgs}>()),
    AddResourcesSuccess: createAction('data/Commission/addResources/success'),
};
