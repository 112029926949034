import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChipAutocompleteComponent } from './components/chip-autocomplete/chip-autocomplete.component';
import { ChipAutocompleteFormDirective } from './directives/chip-autocomplete-form.directive';
import { FilterDirective } from './directives/filter.directive';

@NgModule({
    declarations: [ChipAutocompleteFormDirective, ChipAutocompleteComponent, FilterDirective],
    imports: [CommonModule, MatChipsModule, MatFormFieldModule, FontAwesomeModule, ReactiveFormsModule, MatAutocompleteModule, MatTooltipModule],
    exports: [ChipAutocompleteFormDirective, ChipAutocompleteComponent, FilterDirective],
})
export class CustomFormsModule {}
