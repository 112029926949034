import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { isNotNullOrUndefined, stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { AccountsReceivableLedgerTemplateEntity, AccountsReceivableLedgerTemplateEntityFromBackend } from '../../entities/accounts-receivable-ledger-template.entity';
import { ArlTemplateDataService } from '../../services/arl-template-data.service';
import { HttpService } from '../../services/http.service';
import { AccountsReceivableLedgerTemplateActionTypes } from '../actions/accounting.actions';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { State } from '../index';
import { getAccountsReceivableLedgerTemplates } from '../selectors/accounting.selector';

enum ErrorCodes {
    Load = 'Buchungspositionsvorlage Abrufen fehlgeschlagen',
    Remove = 'Buchungspositionsvorlage Löschen fehlgeschlagen',
    Modify = 'Buchungspositionsvorlage ändern fehlgeschlagen',
    Add = 'Buchungspositionsvorlage hinzufügen fehlgeschlagen',
}

@Injectable()
export class AccountsReceivableLedgerTemplateEffects {
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService, private apiToasterService: ToastrService, private arlTemplateDataService: ArlTemplateDataService) {}

    GetAllAccountsReceivableLedgerTemplateQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountsReceivableLedgerTemplateActionTypes.GetAccountsReceivableLedgerTemplate),
            switchMap(() => {
                const queryString = `query {
          accountsReceivableLedgerTemplate {${AccountsReceivableLedgerTemplateEntity.GqlFields.join(',')}}
        }
        `;
                return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                    map((res) =>
                        res && res.accountsReceivableLedgerTemplate
                            ? AccountsReceivableLedgerTemplateActionTypes.UpdateAccountsReceivableLedgerTemplate({
                                  Payload: res.accountsReceivableLedgerTemplate.map((f) => AccountsReceivableLedgerTemplateEntityFromBackend(f)),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    AddAccountsReceivableLedgerTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountsReceivableLedgerTemplateActionTypes.AddAccountsReceivableLedgerTemplate),
            tap(({ Payload }) => {
                this.arlTemplateDataService
                    .addArlTemplates([Payload])
                    .then(() => this.apiToasterService.success('Speichern erfolgreich'))
                    .catch(() =>
                        this.store$.dispatch(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                },
                            }),
                        ),
                    );
            }),
        )
    , {dispatch: false});

    ChangeAccountsReceivableLedgerTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountsReceivableLedgerTemplateActionTypes.ChangeAccountsReceivableLedgerTemplate),
            tap(({ Payload }) => {
                this.arlTemplateDataService
                    .changeArlTemplatess([Payload])
                    .then(() => this.apiToasterService.success('Speichern erfolgreich'))
                    .catch(() =>
                        this.store$.dispatch(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Modify,
                                },
                            }),
                        ),
                    );
            }),
        )
    , {dispatch: false});

    DeleteAccountsReceivableLedgerTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountsReceivableLedgerTemplateActionTypes.DeleteAccountsReceivableLedgerTemplate),
            switchMap(({ Payload }) => {
                const queryString = `mutation {
          deleteAccountsReceivableLedgerTemplate(
    id: ${Payload.id}
          )
        }
        `;
                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    withLatestFrom(this.store$.select(getAccountsReceivableLedgerTemplates)),
                    map(([res, bookingPositions]) =>
                        res && res.deleteAccountsReceivableLedgerTemplate
                            ? AccountsReceivableLedgerTemplateActionTypes.UpdateAccountsReceivableLedgerTemplate({
                                  Payload: [...bookingPositions.filter((bp) => bp.Id !== Payload.id)],
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
}
