import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { KAGETEC_PARTNERTYPE_ID, OBLIGO_PARTNERTYPE_ID, ZERT_PARTNERTYPE_ID } from '../../app.component';
import { ProductNames } from '../../dave-data-module/entities/license.entity';
import { PartnerTypeEnum } from '../../dave-data-module/entities/partner.entity';
import { SearchResultEntity, SearchTypeEnum } from '../../dave-data-module/entities/search-result.entity';
import { UserEntity } from '../../dave-data-module/entities/user.entity';
import { CustomerResolver } from '../../dave-data-module/guards/customer.resolver';
import { EventResolver } from '../../dave-data-module/guards/event.resolver';
import { State } from '../../dave-data-module/State';
import { BookmarkActionTypes } from '../../dave-data-module/State/actions/bookmarks.actions';
import { EventTypesActionTypes } from '../../dave-data-module/State/actions/event-types.actions';
import { ResolverLoadEventTypes, ResolverReLoadBookmarks } from '../../dave-data-module/State/actions/resolver.actions';
import { getBookmarks, getUnseenBookmarks } from '../../dave-data-module/State/selectors/bookmarks.selectors';
import { getNews, getNewsFetched } from '../../dave-data-module/State/selectors/news.selector';
import { getPartner } from '../../dave-data-module/State/selectors/partners.selectors';
import { getUserProducts } from '../../dave-data-module/State/selectors/products.selectors';
import { NewsComponent, NewsComponentDialogData } from '../../dave-news/news/news.component';
import { SupportDialogComponent } from '../../dave-utils-module/dave-shared-components-module/components/dialogs/support-dialog/support-dialog.component';
import { PermissionService } from '../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { BookmarkService } from '../../dave-utils-module/dave-shared-components-module/services/bookmark.service';
import { CustomLabelService } from "../../services/custom-label.service";

@Component({
    selector: 'app-home-dashboard',
    templateUrl: './home-dashboard.component.html',
    styleUrls: ['./home-dashboard.component.scss'],
})
export class HomeDashboardComponent implements OnInit, OnDestroy {
    private news$ = this.store.select(getNewsFetched).pipe(
        filter((v) => !!v),
        switchMap(() => this.store.select(getNews)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public UnreadCount$ = this.news$.pipe(
        map((news) => news.filter((n) => !n.PartnerId && !n.HasBeenRead).length),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public UnreadPartnerCount$ = this.news$.pipe(
        map((news) => news.filter((n) => n.PartnerId && !n.HasBeenRead).length),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    public User: UserEntity = null;
    public Mobile$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(map((result) => result.matches));
    public Tablet$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.TabletPortrait]).pipe(map((result) => result.matches));
    public Bookmarks = false;
    public Comissions = false;
    public Dashboard = true;
    public Now: string;

    public ShowSearchBar = true;
    public ShowSearchReturn = true;
    public PartnerTypeId: number;
    public SearchReturn$: BehaviorSubject<SearchResultEntity> = new BehaviorSubject(null);
    protected showDefaultLogo$ = this.store.select(getPartner).pipe(
        map((partner) => partner?.PartnerTypeId !== PartnerTypeEnum.YustInfra),
    );
    public BrandLogoUrl$ = this.store.select(getPartner).pipe(
        map((partner) => {
            this.PartnerTypeId = partner?.PartnerTypeId ? partner?.PartnerTypeId : 1;
            switch (partner?.PartnerTypeId) {
                case OBLIGO_PARTNERTYPE_ID:
                    return 'assets/images/obligo_logo.svg';
                case ZERT_PARTNERTYPE_ID:
                    return 'assets/images/zert_logo.svg';
                case KAGETEC_PARTNERTYPE_ID:
                    return 'assets/images/kagetec.jpg';
                case PartnerTypeEnum.YustInfra:
                    return 'assets/images/yust_infra.png';
                default:
                    return null;
            }
        }),
    );
    public SearchFocus$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private user$: Observable<UserEntity>;
    private subscriptions: Subscription[] = [];
    private interval: number;
    public SelectedTypes: SearchTypeEnum[] = [];

    public IsKagetec$ = this.store.select(getPartner).pipe(
        map((p) => p?.PartnerTypeId === PartnerTypeEnum.Kagetec),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    public HasCommissionProduct$ = this.store.select(getUserProducts).pipe(map((up) => up.some((u) => u.Name === ProductNames.Commission)));
    public TaskCount$ = combineLatest([
        this.store.select(getBookmarks).pipe(map((b) => b.length)),
        this.bookmarkService.Tasks$.pipe(
            map((tasks) => tasks.length),
        ),
        this.bookmarkService.getAbsents().pipe(map((v) => v.length)),
        this.bookmarkService.getVacations().pipe(map((v) => v.length)),
    ]).pipe(
        map((counts) => counts.reduce((a, b) => a + b, 0)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public UnseenTaskCount$ = combineLatest([
        this.store.select(getUnseenBookmarks).pipe(map((b) => b.length)),
        this.bookmarkService.UnseenTasksCount$,
    ]).pipe(
        map((counts) => counts.reduce((a, b) => a + b, 0)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor(
        private store: Store<State>,
        public LogoutService: LoginService,
        public PS: PermissionService,
        private dialog: MatDialog,
        public Notifications$: NotificationService,
        private customerResolver: CustomerResolver,
        private eventResolver: EventResolver,
        private breakpointObserver: BreakpointObserver,
        private bookmarkService: BookmarkService,
        protected cls: CustomLabelService,
    ) {
        this.user$ = store.select((state) => state.users.user);
        this.interval = window.setInterval(() => {
            const time = new Date();
            this.Now = ('0' + time.getHours()).substr(-2) + ('0' + time.getMinutes()).substr(-2) + ('0' + time.getSeconds()).substr(-2);
        }, 1000);
    }

    ngOnInit() {
        this.eventResolver.resolve();
        this.subscriptions.push(
            this.user$.subscribe((value) => {
                this.User = value;
            }),
            this.PS.Has(this.PS.Permission.GetCustomer).subscribe((value) => {
                if (value) {
                    this.customerResolver.resolve();
                }
            }),
            this.PS.Has(this.PS.Permission.GetEvents).subscribe((value) => {
                value
                    ? this.store.dispatch(new ResolverLoadEventTypes())
                    : this.store.dispatch(
                          EventTypesActionTypes.UpdateEventTypes({
                              Payload: [],
                          }),
                      );
                value
                    ? this.store.dispatch(new ResolverReLoadBookmarks())
                    : this.store.dispatch(
                          BookmarkActionTypes.UpdateBookmarks({
                              NewBookmarks: [],
                          }),
                      );
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        clearInterval(this.interval);
        this.ShowSearchReturn = false;
    }

    ToggleSearchBar() {
        this.ShowSearchBar = !this.ShowSearchBar;
        this.SearchFocus$.next(this.ShowSearchBar);
    }

    DisplaySearch(msg: SearchResultEntity) {
        this.SearchReturn$.next(msg);
    }
    public GetWelcomeMessage() {
        const now = new Date().getHours();
        let anrede: string;

        if (now < 4 || now >= 17) {
            anrede = 'Guten Abend';
        } else if (now < 12) {
            anrede = 'Guten Morgen';
        } else if (now < 17) {
            anrede = 'Guten Tag';
        }

        return `${anrede}${this.User && this.User.Firstname ? ', ' + this.User.Firstname : ''}`;
    }

    public SetBookmarks() {
        this.Bookmarks = true;
        this.Dashboard = false;
        this.Comissions = false;
    }

    public SetDashboard() {
        this.Bookmarks = false;
        this.Dashboard = true;
        this.Comissions = false;
    }

    public SetComissions() {
        this.Bookmarks = false;
        this.Dashboard = false;
        this.Comissions = true;
    }

    public OpenSupportDialog() {
        this.dialog.open(SupportDialogComponent, { ...SupportDialogComponent.DefaultConfig });
    }

    public NewsDialog(restriction: boolean = false) {
        this.dialog.open<NewsComponent, NewsComponentDialogData>(NewsComponent, {
            ...NewsComponent.DefaultConfig,
            data: {
                PartnerRestricted: restriction,
            },
        });
    }
}
