import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component,
    ElementRef, EventEmitter,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    Optional, Output,
    Self,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, Subject, Subscription, switchMap } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { SearchQueriesDebounceTime, stringSearch } from '../../../../helper/helper';

export type SelectSearchOption<T = any> = T&{
    optionLabel?: string | (() => string);
    infoTooltip?: string;
}
@Component({
    selector: 'app-select-search',
    templateUrl: './select-search.component.html',
    styleUrls: ['./select-search.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: SelectSearchComponent }],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[id]': 'id',
    },
})
export class SelectSearchComponent implements ControlValueAccessor, MatFormFieldControl<SelectSearchOption>, OnDestroy {
    static nextId = 0;
    @ViewChild('select') matSelect: MatSelect;

    _options: SelectSearchOption[];
    @Input()
    NoEntriesFoundLabel = 'keine Einträge gefunden';
    @Input()
    SearchPlaceholderLabel = 'Suchen ...';
    @Input()
    CompareOptions: (a: SelectSearchOption, b: SelectSearchOption) => boolean = (a, b) => a === b;
    @Input()
    SearchFunction: (search: string, option: SelectSearchOption) => boolean = (search, option) => {
        if (!option.optionLabel) {
            return false;
        }
        const searchContent = typeof option.optionLabel === 'string' ? option.optionLabel : option.optionLabel();
        return stringSearch(searchContent, search);
    };
    @Input()
    OptionTemplate: TemplateRef<any>;
    @Input()
    set Options(options: SelectSearchOption[]) {
        this._options = options;
        this.filterOptions();
        this.stateChanges.next();
    }
    @Input()
    panelClass = '';
    @Input()
    itemSize = 42;
    @Output()
    selectionChange = new EventEmitter<any>();
    form = new FormControl<SelectSearchOption | null>(null);
    searchForm = new FormControl<string | null>(null);
    filteredOptions$: BehaviorSubject<SelectSearchOption[]> = new BehaviorSubject<SelectSearchOption[]>([]);
    selectedOption$ = new BehaviorSubject<SelectSearchOption | null>(null);
    shadowOption$ = this.filteredOptions$.pipe(
        switchMap(options => this.selectedOption$.pipe(map(value => {
            if (value) {
                return options.find(o => this.CompareOptions(o, value));
            }
            return null;
        })))
    );
    isSearching$ = new BehaviorSubject(false);
    stateChanges = new Subject<void>();
    focused = false;
    touched = false;
    controlType = 'search-select-input';
    id = `search-select-input-${SelectSearchComponent.nextId++}`;
    onTouched = () => {};
    onChangeSubscription: Subscription;
    subs: Subscription[] = [];

    get empty() {
        return !this.form.value;
    }

    @Input() userAriaDescribedBy: string;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    private _placeholder: string;

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.form.disable() : this.form.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    @Input()
    get value(): SelectSearchOption | null {
        if (this.form.valid) {
            return this.form.value;
        }
        return null;
    }
    set value(tel: SelectSearchOption | null) {
        this.form.setValue(tel, {emitEvent: false});
        this.selectedOption$.next(this.value);
        this.stateChanges.next();
    }

    get errorState(): boolean {
        return (this.form.invalid || this.ngControl?.invalid) && this.touched;
    }

    constructor(
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
        this.subs.push(
            this.searchForm.valueChanges
                .pipe(
                    tap(() => this.isSearching$.next(true)),
                    debounceTime(SearchQueriesDebounceTime),
                )
                .subscribe(() => {
                    this.filterOptions();
                    this.isSearching$.next(false);
                }),
            this.form.valueChanges.subscribe(v => this.selectedOption$.next(this.value))
        );
    }

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }
    autofilled?: boolean;

    ngOnDestroy() {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
        this.onChangeSubscription?.unsubscribe();
        for (let sub of this.subs) {
            sub.unsubscribe();
        }
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        this.matSelect?._elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
    }


    onContainerClick() {
        this._focusMonitor.focusVia(this.matSelect._elementRef, 'program');
        if(!this.matSelect.panelOpen){
            this.matSelect.open();
        }
    }
    writeValue(tel: SelectSearchOption | null): void {
        this.value = tel;
    }

    registerOnChange(fn: any): void {
        this.onChangeSubscription?.unsubscribe();
        this.onChangeSubscription = this.form.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    protected filterOptions() {
        if (!this._options) {
            return;
        }

        if (this.searchForm.value) {
            this.filteredOptions$.next(this._options.filter((option) => this.SearchFunction(this.searchForm.value, option)));
        } else {
            this.filteredOptions$.next(this._options);
        }
    }
}
