import { formatDate } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, skip, startWith, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { FrontendDate } from 'src/app/dave-data-module/helper/backend-frontend-conversion.helper';
import { PersonEntity, Salutation } from '../../../dave-data-module/entities/person.entity';
import { PersonTypeEntity } from '../../../dave-data-module/entities/personType.entity';
import { ComponentCanDeactivate } from '../../../dave-data-module/guards/pending-changes.guard';
import { State } from '../../../dave-data-module/State';
import { DaveActions } from '../../../dave-data-module/State/actions/actions';
import { PersonActionTypes } from '../../../dave-data-module/State/actions/person.actions';
import { PersonTypeActionTypes } from '../../../dave-data-module/State/actions/personType.actions';
import { getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPersonTypes, getPersonTypesFetched } from '../../../dave-data-module/State/selectors/personType.selectors';
import { AppConfirmationDialogComponent, AppConfirmationDialogData, AppConfirmationDialogReturnData } from '../../../dave-utils-module/app-dialog-module/app-confirmation-dialog/app-confirmation-dialog.component';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { DetailListDialogReturn, DetailListTemplateDialogComponent, DetailListTemplateDialogData } from '../../../detail-list-template-dialog/components/detail-list-template-dialog.component';
import { getAddressString, isNotNullOrUndefined, SearchQueriesDebounceTime } from '../../../helper/helper';
import { ContactBookMeta } from '../../../helper/page-metadata';

@Component({
    selector: 'app-person-detail-form',
    templateUrl: './person-detail-form.component.html',
    styleUrls: ['./person-detail-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonDetailFormComponent implements OnInit, OnDestroy, AfterViewInit, ComponentCanDeactivate {
    @ViewChild('emailTemplate') emailTemplate: TemplateRef<any>;
    @ViewChild('personTypeTemplate') personTypeTemplate: TemplateRef<any>;
    @ViewChild('secondNameTemplate') secondNameTemplate: TemplateRef<any>;
    @Output() dirtyChanged = new EventEmitter<boolean>();
    @Output() formInvalidChanged = new EventEmitter<boolean>();
    @Output() formEmpty = new EventEmitter<boolean>();
    @Output() saved = new EventEmitter<PersonEntity>();
    public Editing$ = new BehaviorSubject<boolean>(false);
    public PersonTypes: Array<PersonTypeEntity & { optionLabel: string }>;
    public PersonForm = new FormGroup({
        WorkNumber: new FormControl<string>(null),
        Organisation: new FormControl<string>(null),
        SecondMail: new FormControl<string>('', Validators.email),
        Birthdate: new FormControl<Moment>(null),
        Title: new FormControl<string>(null),
        Salutation: new FormControl<Salutation>(null),
        Firstname: new FormControl<string>(null),
        Lastname: new FormControl<string>(null),
        CompanyName: new FormControl<string>(null),
        Email: new FormControl<string>('', Validators.email),
        PhoneNumber: new FormControl<string>(null),
        MobileNumber: new FormControl<string>(null),
        FaxNumber: new FormControl<string>(null),
        Description: new FormControl<string>(null),
        PersonTypeId: new FormControl<PersonTypeEntity & { optionLabel: string }>(null),
        Location: this.fb.group({
            Street: new FormControl<string>(null),
            PostalCode: new FormControl<string>(null),
            City: new FormControl<string>(null),
            Country: new FormControl<string>(null),
        }),
        SecondLastname: new FormControl<string>(null),
        SecondFirstname: new FormControl<string>(null),
    });

    public Person$ = new BehaviorSubject<PersonEntity | null>(null);
    public OtherPersonsWithSameEmail$: Observable<PersonEntity[] | undefined> = this.Person$.pipe(
        map((p) => p?.Id),
        distinctUntilChanged(),
        switchMap((personId) =>
            this.store.select(getPersons).pipe(
                map((persons) => {
                    const personEmailMap: { [key: string]: PersonEntity[] } = {};
                    persons.forEach((p) => {
                        if (p.Email && p.Id !== personId) {
                            if (!personEmailMap[p.Email.trim()]) {
                                personEmailMap[p.Email.trim()] = [];
                            }
                            personEmailMap[p.Email.trim()].push(p);
                        }
                    });
                    return personEmailMap;
                }),
            ),
        ),
        switchMap((personEmailMap) =>
            this.PersonForm.controls.Email.valueChanges.pipe(
                startWith(this.PersonForm.controls.Email.value),
                debounceTime(SearchQueriesDebounceTime),
                distinctUntilChanged(),
                map((email) => personEmailMap[(email || '').trim()]),
            ),
        ),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    private afterViewInit$ = new BehaviorSubject(false);
    public PersonMainData$: Observable<IDetailListTemplateData> = combineLatest([
        this.Person$.pipe(filter(isNotNullOrUndefined)),
        this.store.select(getPersonTypesFetched).pipe(
            filter((f) => f),
            switchMap(() => this.store.select(getPersonTypes)),
        ),
        this.afterViewInit$.pipe(filter((f) => f)),
    ]).pipe(
        map(([person, ptypes, p]) => {
            return person
                ? {
                      HeaderIcon: person.Deleted ? 'user-slash' : 'user',
                      Headline: [person.Salutation, person.Title, person.Firstname, person.Lastname, person.Organisation].filter((v) => !!v).join(' '),
                      Properties: [
                          {
                              //onlyIcon: 'id-card' as IconProp,
                              value: person?.PersonTypeId !== null ? ptypes?.find((p) => p.Id === person?.PersonTypeId).Name : null,
                              key: 'Rolle',
                              formControl: this.PersonForm.controls.PersonTypeId,
                              options: {
                                  specialInput: {
                                      customTemplate: this.personTypeTemplate,
                                  },
                              },
                          },
                          {
                              onlyIcon: 'building' as IconProp,
                              formControl: this.PersonForm.controls.Title,
                              key: 'Titel',
                              value: person.Title,
                          },
                          {
                              formControl: this.PersonForm.controls.Salutation,
                              key: 'Anrede',
                              value: person.Salutation,
                              options: {
                                  specialInput: {
                                      select: Object.values(Salutation).map((s) => ({ optionValue: s, optionLabel: s })),
                                  },
                              },
                          },
                          {
                              formControl: this.PersonForm.controls.Lastname,
                              key: 'Nachname',
                              value: person.Lastname,
                          },
                          {
                              formControl: this.PersonForm.controls.Firstname,
                              key: 'Vorname',
                              value: person.Firstname,
                          },
                          {
                              formControl: this.PersonForm.controls.SecondLastname,
                              key: 'Nachname 2',
                              value: person.Salutation === Salutation.life_companions ? (person.SecondLastname || null) : null,
                              options: {
                                  specialInput: {
                                      customTemplate: this.secondNameTemplate,
                                  }
                              }
                          },
                          {
                              formControl: this.PersonForm.controls.SecondFirstname,
                              key: 'Vorname 2',
                              value: person.Salutation === Salutation.life_companions ? (person.SecondFirstname || null) : null,
                              options: {
                                  specialInput: {
                                      customTemplate: this.secondNameTemplate,
                                  }
                              }
                          },
                          {
                              formControl: this.PersonForm.controls.Organisation,
                              key: 'Organisation',
                              value: person.Organisation,
                          },
                          {
                              onlyIcon: 'envelope' as IconProp,
                              formControl: this.PersonForm.controls.Email,
                              key: 'Email',
                              value: person.Email,
                              options: {
                                  specialInput: {
                                      customTemplate: this.emailTemplate,
                                  },
                              },
                          },
                          {
                              onlyIcon: 'envelope' as IconProp,
                              formControl: this.PersonForm.controls.SecondMail,
                              key: 'Zweite Email',
                              value: person.SecondMail,
                          },
                          {
                              onlyIcon: 'note-sticky' as IconProp,
                              formControl: this.PersonForm.controls.Description,
                              key: 'Notiz',
                              value: person.Description,
                          },
                          {
                              onlyIcon: 'mobile-screen-button' as IconProp,
                              formControl: this.PersonForm.controls.MobileNumber,
                              key: 'Mobil',
                              value: person.MobileNumber,
                              options: {
                                  type: CustomPropertyType.Tel,
                              },
                          },
                          {
                              onlyIcon: 'phone' as IconProp,
                              formControl: this.PersonForm.controls.PhoneNumber,
                              key: 'Telefon',
                              value: person.PhoneNumber,
                              options: {
                                  type: CustomPropertyType.Tel,
                              },
                          },
                          {
                              formControl: this.PersonForm.controls.WorkNumber,
                              key: 'Telefon (Arbeit)',
                              value: person.WorkNumber,
                              options: {
                                  type: CustomPropertyType.Tel,
                              },
                          },
                          {
                              onlyIcon: 'fax' as IconProp,
                              formControl: this.PersonForm.controls.FaxNumber,
                              key: 'Fax',
                              value: person.FaxNumber,
                          },
                          {
                              formControl: this.PersonForm.controls.Birthdate,
                              key: 'Geburtsdatum',
                              value: person.Birthdate ? formatDate(person.Birthdate, 'dd.MM.yyyy', 'de-DE') : null,
                              options: {
                                  specialInput: {
                                      date: true,
                                  },
                              },
                          },
                          {
                              onlyIcon: 'location-dot' as IconProp,
                              key: 'Adresse',
                              options: {
                                  type: CustomPropertyType.Location,
                                  specialInput: {
                                      location: {
                                          value: person,
                                          formGroup: this.PersonForm.controls.Location,
                                      },
                                  },
                              },
                              value: getAddressString(person) || null,
                          },
                      ],
                  }
                : null;
        }),
    );
    private subscriptions: Subscription[] = [
        this.store
            .select(getPersonTypesFetched)
            .pipe(
                filter((f) => f),
                switchMap(() => this.store.select(getPersonTypes).pipe(map((pt) => pt.filter((p) => !p.DeletedAt)))),
            )
            .subscribe((pt) => (this.PersonTypes = pt.map((r) => Object.assign(r.Clone(), { optionLabel: r.Name })))),
    ];

    constructor(private fb: UntypedFormBuilder, private store: Store<State>, private dialog: MatDialog, private actions$: Actions<DaveActions>) {}

    @Input() set Editing(e: boolean | null) {
        if (e != null) {
            this.Editing$.next(e);
        }
    }

    @Input() set Person(p: PersonEntity) {
        this.Person$.next(p);
    }

    @Input() public IsModal = false;

    Save(): void {
        const _save = () => {
            firstValueFrom(this.Person$).then((person) => {
                if (this.PersonForm.status === 'VALID') {
                    this.dirtyChanged.next(false);
                    if (person.Id) {
                        this.store.dispatch(
                            PersonActionTypes.ModifyPerson({
                                Payload: {
                                    id: person.Id,
                                    title: this.PersonForm.controls.Title.value,
                                    salutation: this.PersonForm.controls.Salutation.value,
                                    firstname: this.PersonForm.controls.Firstname.value,
                                    lastname: this.PersonForm.controls.Lastname.value,
                                    companyName: this.PersonForm.value.CompanyName,
                                    email: this.PersonForm.controls.Email.value,
                                    phonenumber: this.PersonForm.controls.PhoneNumber.value,
                                    mobilenumber: this.PersonForm.controls.MobileNumber.value,
                                    faxnumber: this.PersonForm.controls.FaxNumber.value,
                                    description: this.PersonForm.controls.Description.value,
                                    street: this.PersonForm.value.Location.Street,
                                    postalCode: this.PersonForm.value.Location.PostalCode,
                                    city: this.PersonForm.value.Location.City,
                                    country: this.PersonForm.value.Location.Country,
                                    personTypeId: this.PersonForm.controls.PersonTypeId.value?.Id,
                                    organisation: this.PersonForm.controls.Organisation.value,
                                    workNumber: this.PersonForm.controls.WorkNumber.value,
                                    birthdate: this.PersonForm.controls.Birthdate.value ? FrontendDate(this.PersonForm.controls.Birthdate.value.toDate()) : null,
                                    secondMail: this.PersonForm.controls.SecondMail.value,
                                    secondFirstname: this.PersonForm.controls.SecondFirstname.value,
                                    secondLastname: this.PersonForm.controls.SecondLastname.value,
                                },
                            }),
                        );
                        // this.Editing$.next(false);
                        this.saved.emit(person);
                        this.PersonForm.markAsPristine();
                    } else {
                        this.store.dispatch(
                            PersonActionTypes.AddPerson({
                                Payload: {
                                    title: this.PersonForm.controls.Title.value,
                                    salutation: this.PersonForm.controls.Salutation.value,
                                    firstname: this.PersonForm.controls.Firstname.value,
                                    lastname: this.PersonForm.controls.Lastname.value,
                                    companyName: this.PersonForm.value.CompanyName,
                                    email: this.PersonForm.controls.Email.value,
                                    phonenumber: this.PersonForm.controls.PhoneNumber.value,
                                    mobilenumber: this.PersonForm.controls.MobileNumber.value,
                                    faxnumber: this.PersonForm.controls.FaxNumber.value,
                                    description: this.PersonForm.controls.Description.value,
                                    street: this.PersonForm.value.Location.Street,
                                    postalCode: this.PersonForm.value.Location.PostalCode,
                                    city: this.PersonForm.value.Location.City,
                                    country: this.PersonForm.value.Location.Country,
                                    personTypeId: this.PersonForm.controls.PersonTypeId.value?.Id,
                                    workNumber: this.PersonForm.controls.WorkNumber.value,
                                    organisation: this.PersonForm.controls.Organisation.value,
                                    birthdate: this.PersonForm.controls.Birthdate.value ? FrontendDate(this.PersonForm.controls.Birthdate.value?.toDate()) : null,
                                    secondMail: this.PersonForm.controls.SecondMail.value,
                                    secondFirstname: this.PersonForm.controls.SecondFirstname.value,
                                    secondLastname: this.PersonForm.controls.SecondLastname.value,
                                },
                            }),
                        );
                        this.store
                            .select(getPersons)
                            .pipe(skip(1), take(1))
                            .subscribe((persons) => {
                                const latestId = persons.reduce((id, person) => (person.Id > id ? person.Id : id), -1);
                                this.Editing$.next(false);
                                this.saved.emit(persons.find((p) => p.Id == latestId));
                                this.PersonForm.markAsPristine();
                            });
                    }
                }
            });
        };
        firstValueFrom(this.OtherPersonsWithSameEmail$).then((otherPersonsWithSameEmail) => {
            if (otherPersonsWithSameEmail?.length) {
                this.dialog
                    .open<AppConfirmationDialogComponent, AppConfirmationDialogData, AppConfirmationDialogReturnData>(AppConfirmationDialogComponent, {
                        autoFocus: false,
                        data: {
                            heading: 'E-Mail Adresse bereits vergeben',
                            paragraph: 'Die E-Mail Adresse ist bereits vergeben, möchten Sie dennoch speichern ?',
                        },
                    })
                    .afterClosed()
                    .subscribe(([r]) => {
                        if (r) {
                            _save();
                        }
                    });
            } else {
                _save();
            }
        });
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.PersonForm.statusChanges.subscribe((s) => {
                this.formInvalidChanged.next(s === 'INVALID');
            }),
            this.PersonForm.valueChanges.subscribe((v) => {
                this.dirtyChanged.next(this.PersonForm.dirty);
                if (
                    (v.Email === '' || v.Email === null) &&
                    v.Salutation === null &&
                    (v.CompanyName === '' || v.CompanyName === null) &&
                    (v.Description === '' || v.Description === null) &&
                    (v.FaxNumber === '' || v.FaxNumber === null) &&
                    (v.PhoneNumber === '' || v.PhoneNumber === null) &&
                    (v.Firstname === '' || v.Firstname === null) &&
                    (v.Lastname === '' || v.Lastname === null) &&
                    (v.MobileNumber === '' || v.MobileNumber === null) &&
                    (v.Title === '' || v.Title === null) &&
                    v.Birthdate === null &&
                    (v.SecondMail === '' || v.SecondMail === null) &&
                    (v.WorkNumber === '' || v.WorkNumber === null) &&
                    (v.Organisation === '' || v.Organisation === null) &&
                    (v.SecondFirstname === '' || v.SecondFirstname === null) &&
                    (v.SecondLastname === '' || v.SecondLastname === null)
                ) {
                    this.formEmpty.next(true);
                } else {
                    this.formEmpty.next(false);
                }
            }),
            this.Editing$.pipe(
                withLatestFrom(
                    this.Person$,
                    this.store.select(getPersonTypesFetched).pipe(
                        filter((f) => f),
                        switchMap(() => this.store.select(getPersonTypes)),
                    ),
                ),
            ).subscribe(([editing, person, personTypes]) => {
                if (editing) {
                    this.PersonForm.reset({
                        Title: person?.Title,
                        Salutation: person?.Salutation,
                        Firstname: person?.Firstname,
                        Lastname: person?.Lastname,
                        CompanyName: person?.CompanyName,
                        Email: person?.Email,
                        PhoneNumber: person?.PhoneNumber,
                        MobileNumber: person?.MobileNumber,
                        FaxNumber: person?.FaxNumber,
                        Description: person?.Description,
                        // Street: person?.Street,
                        // PostalCode: person?.PostalCode,
                        // City: person?.City,
                        // Country: person?.Country,
                        WorkNumber: person?.WorkNumber,
                        Organisation: person?.Organisation,
                        Birthdate: person?.Birthdate ? moment(person.Birthdate) : null,
                        SecondMail: person?.SecondMail,
                        PersonTypeId: person ? this.PersonTypes.find((t) => t.Id === person.PersonTypeId && !t.DeletedAt) : null,
                        Location: {
                            Street: person?.Street,
                            PostalCode: person?.PostalCode,
                            City: person?.City,
                            Country: person?.Country,
                        },
                        SecondLastname: person?.SecondLastname,
                        SecondFirstname: person?.SecondFirstname,
                    });
                } else {
                    this.PersonForm.reset();
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    ngAfterViewInit(): void {
        this.afterViewInit$.next(true);
    }

    newPersonTypePopUp(): void {
        const pTypeName = new FormControl<string>('', Validators.required);
        this.dialog
            .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                ...DetailListTemplateDialogComponent.DefaultConfig,
                data: {
                    DisableSaveButton$: pTypeName.statusChanges.pipe(
                        startWith(pTypeName.status),
                        map((state) => state !== 'VALID'),
                    ),
                    Editing: true,
                    Data: {
                        Headline: 'Neue Rolle anlegen',
                        Properties: [
                            {
                                key: 'Bezeichnung',
                                formControl: pTypeName,
                            },
                        ],
                    },
                },
            })
            .afterClosed()
            .subscribe((ret) => {
                if (ret?.Action === 'save') {
                    this.store.dispatch(PersonTypeActionTypes.Create({ Payload: { name: pTypeName.value } }));
                    this.actions$.pipe(ofType(PersonTypeActionTypes.CreateSuccess)).subscribe((res) => {
                        if (res.Payload) {
                            this.PersonForm.controls.PersonTypeId.setValue(Object.assign(res.Payload && { optionLabel: res.Payload.Name }));
                        }
                    });
                }
            });
    }

    protected readonly ContactBookMeta = ContactBookMeta;
    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): boolean {
        return !this.PersonForm.dirty;
    }

    protected readonly FormControl = FormControl;
    protected readonly Salutation = Salutation;
}
