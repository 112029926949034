import { CommonModule } from '@angular/common';
import { Component,Inject,Input } from '@angular/core';
import { MatCardModule } from "@angular/material/card";
import { MatDialogConfig,MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { appMatDialogDefaultConfig } from "../helper/helper";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ProgressToastrComponentDialogData {
    title: string;
    subTitle: string;
    icon: IconProp;
    progressStartValue: number;
}

@Component({
    selector: 'app-progress-toastr',
    standalone: true,
    imports: [CommonModule, MatProgressBarModule, MatCardModule, FontAwesomeModule],
    templateUrl: './progress-toastr.component.html',
    styleUrls: ['./progress-toastr.component.scss'],
})
export class ProgressToastrComponent {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        panelClass: [...appMatDialogDefaultConfig.panelClass.filter(c => c !== 'custom-dialog-class-mobile-fullscreen'), 'custom-dialog-class-without-padding'],
        hasBackdrop: false,
        disableClose: true,
        maxWidth: "20rem",
        position: {
            top: '1rem',
            right: '1rem',
        },
    };
    @Input() title: string;
    @Input() subTitle: string;
    @Input() icon: IconProp;
    @Input() progress: number = 0;
    constructor(@Inject(MAT_DIALOG_DATA) dialogData: ProgressToastrComponentDialogData) {
        if (dialogData) {
            this.title = dialogData.title;
            this.subTitle = dialogData.subTitle;
            this.icon = dialogData.icon;
            if (dialogData.progressStartValue) {
                this.progress = dialogData.progressStartValue;
            }
        }
    }
}
