import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, of, Subscription } from 'rxjs';
import { catchError, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EmailEntity } from '../dave-data-module/entities/email.entity';
import { FileDataService } from '../dave-data-module/services/file-data.service';
import { HttpService } from '../dave-data-module/services/http.service';
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';
import { getToken } from '../dave-data-module/State/selectors/base.selectors';
import { blobToBase64 } from '../helper/helper';

@Injectable({
    providedIn: 'root',
})
export class MailContentService implements OnDestroy {
    private subscriptions: Subscription[] = [];
    private token = '';

    constructor(private gatewayHttpService: HttpService, private store: Store<State>, private fileDataService: FileDataService, private Api: HttpService) {
        this.subscriptions.push(this.store.select(getToken).subscribe((t) => (this.token = t)));
    }

    public TransformToMail(e: EmailEntity, replaceInlineImagesCid: false | 'file_service_url' | 'base64' = false) {
        let queryString = `
                                query{
                                  email(id: ${e.Id}){
                                    hTMLBody
                                    textBody
                                  }
                                }`;
        return this.gatewayHttpService.graphQl({ query: queryString }, { token: this.token }).pipe(
            switchMap((res) => {
                if (replaceInlineImagesCid && e.DocumentIds?.length) {
                    return this.fileDataService.GetFilesById(e.DocumentIds, { withEmailInline: true }).pipe(
                        first(),
                        withLatestFrom(this.store.select(getToken)),
                        switchMap(([files, token]) => {
                            let html = res.email[0].hTMLBody;
                            if (html != null) {
                                files = files.filter((f) => e.DocumentIds?.includes(f.Id));
                                if (replaceInlineImagesCid === 'file_service_url') {
                                    files.forEach((f) => {
                                        let cid = f.MetaData?.email?.cid;
                                        if (cid) {
                                            let url = this.Api.GetUrl(f.GetLastVersion().GetDownloadLink(token), 'file');
                                            // @ts-ignore
                                            html = html.replaceAll('cid:' + cid, url);
                                        }
                                    });
                                    return of({
                                        html,
                                        Text: res.email[0].textBody,
                                    });
                                } else if (replaceInlineImagesCid === 'base64') {
                                    return combineLatest(
                                        files.map((file) =>
                                            this.Api.download(file.GetLastVersion()).pipe(
                                                switchMap(blobToBase64),
                                                map((base64) => ({ fileEntity: file, base64 })),
                                            ),
                                        ),
                                    ).pipe(
                                        first(),
                                        map((files) => {
                                            files.forEach((f) => {
                                                let cid = f.fileEntity.MetaData?.email?.cid;
                                                if (cid && f.base64) {
                                                    // @ts-ignore
                                                    html = html.replaceAll('cid:' + cid, f.base64);
                                                }
                                            });
                                            return {
                                                html,
                                                Text: res.email[0].textBody,
                                            };
                                        }),
                                    );
                                }
                            } else {
                                return of({
                                    html: res.email[0].hTMLBody,
                                    Text: res.email[0].textBody,
                                });
                            }
                        }),
                    );
                } else {
                    return of({
                        html: res.email[0].hTMLBody,
                        Text: res.email[0].textBody,
                    });
                }
            }),
            catchError((err, caught) => {
                this.store.dispatch(
                    BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: 'Email Inhalt abrufen gehlgeschlagen',
                            Err: err,
                            Caught: caught,
                        },
                    }),
                );
                return of({
                    html: '',
                    Text: '',
                });
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
