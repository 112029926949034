import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMatMenuComponent } from './component/custom-mat-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';



@NgModule({
    declarations: [CustomMatMenuComponent],
    exports: [
        CustomMatMenuComponent
    ],
    imports: [
        CommonModule,
        AppButtonModule,
        MatMenuModule
    ]
})
export class CustomMatMenuModule { }
