import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { filter, first, tap, withLatestFrom } from 'rxjs/operators';
import { State } from '../../dave-data-module/State';
import { EmployeeToVacationActionTypes } from '../../dave-data-module/State/actions/employee-to-vacation.actions';
import {
    AppConfirmationDialogComponent,
    AppConfirmationDialogData,
    AppConfirmationDialogReturnData,
} from './app-confirmation-dialog/app-confirmation-dialog.component';
import {
    SelectDiaryDateDialogComponent,
    SelectDiaryDateDialogComponentDialogData,
} from '../../dave-commission-module/components/detail-commission/select-diary-date-dialog/select-diary-date-dialog.component';
import { Moment } from 'moment/moment';
import { isNotNullOrUndefined } from '../../helper/helper';
import {
    DetailConstructionDiaryEntryComponent,
    DetailConstructionDiaryEntryComponentDialogData,
} from '../../dave-commission-module/components/detail-commission/detail-construction-diary-entry/detail-construction-diary-entry.component';
import {
    TimeTrackerKagetecComponent,
    TimeTrackerKagetecDialogData,
} from '../../components/dashboard/services/time-tracker-kagetec/time-tracker-kagetec.component';
import { getCommissionById } from '../../dave-data-module/State/selectors/commission.selector';
import {
    HourListComponent,
    HourListComponentDialogData,
} from '../../dave-time-tracker/components/hour-list/hour-list.component';

@Injectable({
    providedIn: 'root',
})
export class AppDialogService {
    constructor(private matDialog: MatDialog, private store: Store<State>) {}

    OpenConfirmationDialog(data: AppConfirmationDialogData) {
        return this.matDialog
            .open<AppConfirmationDialogComponent, AppConfirmationDialogData, AppConfirmationDialogReturnData>(AppConfirmationDialogComponent, {
                autoFocus: false,
                data,
                maxWidth: '400px',
                width: '90%',
            })
            .afterClosed();
    }

    OpenVacationConfirmationDialog(id: number, approved: boolean) {
        return this.matDialog
            .open<AppConfirmationDialogComponent, AppConfirmationDialogData>(AppConfirmationDialogComponent, {
                data: {
                    heading: 'Urlaubsantrag ' + (approved ? 'genehmigen' : 'ablehnen'),
                    paragraph: 'Wollen Sie den Urlaubsantrag ' + (approved ? 'genehmigen' : 'ablehnen') + '?',
                },
            })
            .afterClosed()
            .pipe(
                filter(([response]) => response),
                tap(() => this.store.dispatch(EmployeeToVacationActionTypes.Approve({ Payload: { id, approved } }))),
            );
    }
    OpenConstructionDiaryDatepickerDialog(commissionId: number) {
        this.matDialog
            .open<SelectDiaryDateDialogComponent, SelectDiaryDateDialogComponentDialogData, Moment | null>(SelectDiaryDateDialogComponent, {
                ...SelectDiaryDateDialogComponent.DefaultConfig,
                data: { CommissionId: commissionId },
            })
            .afterClosed()
            .pipe(filter(isNotNullOrUndefined))
            .subscribe((ret) => {
                this.OpenDetailConstructionDiaryPopup(ret, commissionId);
            });
    }
    OpenDetailConstructionDiaryPopup(moment: Moment, commissionId: number) {
        this.store.select(getCommissionById({id: commissionId})).pipe(first()).subscribe(commission => {
            let dialogRes = this.matDialog.open<DetailConstructionDiaryEntryComponent, DetailConstructionDiaryEntryComponentDialogData>(
                DetailConstructionDiaryEntryComponent,
                {
                    ...DetailConstructionDiaryEntryComponent.DefaultConfig,
                    data: {
                        CommissionId: commissionId,
                        DiaryDate: moment.toDate(),
                    },
                },
            );

            dialogRes
                .afterClosed()
                .toPromise()
                .then((res) => {
                    if (res === 'open-time-tracking') {
                        this.matDialog.open<HourListComponent, HourListComponentDialogData>(HourListComponent, {
                            ...HourListComponent.DefaultConfig,
                            data: {
                                CommissionId: commissionId,
                            },
                        });
                    } else if (res === 'open-time-tracking-kagetec') {
                        this.matDialog.open<TimeTrackerKagetecComponent, TimeTrackerKagetecDialogData>(TimeTrackerKagetecComponent, {
                            ...TimeTrackerKagetecComponent.DefaultConfig,
                            data: {
                                DefaultValues: {
                                    CommissionId: commissionId,
                                    CommissionName: commission.DisplayName,
                                    Date: moment.toDate(),
                                },
                            },
                        });
                    }
                });
        })
    }
}
