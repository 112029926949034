<mat-form-field style="width: 100%">
    <mat-chip-list #chipList>
        <mat-chip
            *ngFor="let email of SelectedEmails$ | async"
            (removed)="Remove(email)"
        >
            {{ email }}
            <fa-icon matChipRemove icon="trash"></fa-icon>
        </mat-chip>
        <input
            [matChipInputAddOnBlur]="ChipInputAddOnBlur"
            [placeholder]="Placeholder"
            #emailInput
            [formControl]="EmailCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="SeparatorKeysCodes"
            (matChipInputTokenEnd)="Add($event)"
        />
    </mat-chip-list>
    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="Selected($event)"
        (optionsScroll)="onScroll()"
        [displayWith]="displayWith"
    >
        <mat-option (mouseover)="ChipInputAddOnBlur = false" (mouseleave)="ChipInputAddOnBlur = true" (click)="ChipInputAddOnBlur = true"
            *ngFor="let option of FilteredEmails | async"
            [value]="option.Email"
        >
            {{ option.DisplayName }}<I>{{'  ' + option.Email}}</I>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
