import { Component,ElementRef,EventEmitter,Input,OnInit,Output,ViewChild } from "@angular/core";
import { FormControl,Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from "@angular/material/menu";
import { ResolveData } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Observable } from "rxjs";
import { distinctUntilChanged,filter,map,shareReplay,startWith,switchMap,tap } from 'rxjs/operators';
import { GroupEntity } from '../dave-data-module/entities/group.entity';
import { EmployeeResolver } from '../dave-data-module/guards/employee.resolver';
import { GroupResolver } from '../dave-data-module/guards/group.resolver';
import { PersonResolver } from '../dave-data-module/guards/person.resolver';
import { State } from '../dave-data-module/State';
import { GroupActionTypes } from '../dave-data-module/State/actions/group.actions';
import { GROUP_KEY } from '../dave-data-module/State/reducers/group.reducer';
import { getEmployeeDictionary,getEmployeesFetched } from '../dave-data-module/State/selectors/employees.selectors';
import { getGroupById, getGroups, getGroupsFetched } from "../dave-data-module/State/selectors/group.selectors";
import { getPersonDictionary,getPersonsFetched } from '../dave-data-module/State/selectors/person.selectors';
import { DaveListCardcolumnProperties } from '../dave-list-card/dave-list-card.component';
import { SelectGroupMemberDialogComponent,SelectGroupMemberDialogComponentDialogData } from '../dave-select-user/components/select-user-dialogs/select-group-member-dialog/select-group-member-dialog.component';
import { DetailListDialogReturn,DetailListTemplateDialogComponent,DetailListTemplateDialogData } from '../detail-list-template-dialog/components/detail-list-template-dialog.component';
import { isNotNullOrUndefined } from '../helper/helper';
import { GroupsIcon } from '../helper/page-metadata';

interface TableData {
    Name: string;
    groupId: number;
}
@Component({
    selector: 'app-group-list',
    templateUrl: './group-list.component.html',
    styleUrls: ['./group-list.component.scss'],
})
export class GroupListComponent implements OnInit {
    public static readonly RequiredResolvers: ResolveData = {
        [GROUP_KEY]: GroupResolver,
    };

    @Input() CommissionId: number;
    @Input() Expanded: boolean = true;
    @Input() Headline: string;
    @Output() SendEmailClick = new EventEmitter<{ group: GroupEntity, toType?: "to" | "cc" | "bcc"}>();

    public GroupsIcon = GroupsIcon;
    public Groups$: Observable<DaveListCardcolumnProperties<TableData>[]>;

    protected contentResolved$ = combineLatest([
        this.store.select(getGroupsFetched).pipe(
            distinctUntilChanged(),
            tap((fetched) => {
                if (!fetched) {
                    this.groupRes.resolve();
                }
            }),
        ),
        this.store.select(getPersonsFetched).pipe(
            distinctUntilChanged(),
            tap((fetched) => {
                if (!fetched) {
                    this.personRes.resolve();
                }
            }),
        ),
        this.store.select(getEmployeesFetched).pipe(
            distinctUntilChanged(),
            tap((fetched) => {
                if (!fetched) {
                    this.employeeRes.resolve();
                }
            }),
        ),
    ]).pipe(
        map((values) => values.every((v) => v)),
        distinctUntilChanged(),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    constructor(private store: Store<State>, private dialog: MatDialog, public groupRes: GroupResolver, public personRes: PersonResolver, public employeeRes: EmployeeResolver) {}
    ngOnInit(): void {
        this.Groups$ = this.store.select(getGroupsFetched).pipe(
            filter((v) => v),
            switchMap(() => this.store.select(getGroups)),
            map((groups) => groups.filter((g) => g.CommissionId === this.CommissionId)),
            switchMap((groups) =>
                combineLatest([this.store.select(getPersonDictionary), this.store.select(getEmployeeDictionary)]).pipe(
                    map(([persons, employees]) =>
                        groups.map((group) => ({
                            group,
                            members: group.GroupMembers.map((member) => (member.EmployeeId ? employees[member.EmployeeId] : persons[member.PersonId]))
                                .filter(isNotNullOrUndefined)
                                .map((member) => member.DisplayName),
                        })),
                    ),
                ),
            ),
            map((groups) =>
                groups.map<DaveListCardcolumnProperties<TableData>>(({ group, members }) => ({
                    groupId: group.Id,
                    Name: group.Name + ' (' + group.GroupMembers.length + ') ' + members.join(' | '),
                })),
            ),
        );
    }
    openSelectMembers({ entry, element }: { entry: TableData; element: HTMLElement }) {
        element?.classList.add('is-active');
        this.dialog
            .open<SelectGroupMemberDialogComponent, SelectGroupMemberDialogComponentDialogData>(SelectGroupMemberDialogComponent, {
                ...SelectGroupMemberDialogComponent.DefaultConfig,
                data: {
                    groupId: entry.groupId,
                },
            })
            .afterClosed()
            .subscribe(() => document.addEventListener('click', () => element?.classList.remove('is-active'), { once: true }));
    }
    openNewGroupPopUp() {
        const cName = new FormControl<string>('', Validators.required);
        this.dialog
            .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                ...DetailListTemplateDialogComponent.DefaultConfig,
                data: {
                    DisableSaveButton$: cName.statusChanges.pipe(
                        startWith(cName.status),
                        map((state) => state !== 'VALID'),
                    ),
                    Editing: true,
                    Data: {
                        Headline: 'Gruppe anlegen',
                        Properties: [
                            {
                                key: 'Bezeichnung',
                                formControl: cName,
                            },
                        ],
                    },
                },
            })
            .afterClosed()
            .subscribe((ret) => {
                if (ret.Action === 'save') {
                    this.store.dispatch(GroupActionTypes.Create({ Payload: { name: cName.value, commissionId: this.CommissionId } }));
                }
            });
    }
    OpenEmailDialog(data: TableData,  toType: 'to' | 'cc' | 'bcc') {
        firstValueFrom(this.store.select(getGroupById({id: data.groupId}))).then(group => {
            this.SendEmailClick.emit({ group, toType });
        })
    }
}
