<h2 mat-dialog-title><fa-icon style="margin-right: .5rem" [icon]="'upload'"></fa-icon>Anhänge vom Computer hochladen</h2>
<mat-dialog-content>
    <dropzone
        [config]="DropzoneConfig$ | async"
        [message]="'Klicken oder Dateien hinein ziehen'"
        (error)="OnUploadError($event)"
        (success)="OnUploadSuccess($event)"
        (addedFile)="OnAddedFile($event)"
        (uploadProgress)="onDropzoneUploadProgress($event)"

    ></dropzone>
</mat-dialog-content>
<mat-dialog-actions>
    <button Color="cancel" app-button matDialogClose>Abbrechen</button>
    <button app-button (click)="CloseAndSave()">Speichern</button>
</mat-dialog-actions>
