import { Injectable } from '@angular/core';
import { ActivityEntity, ActivityEntityFromBackend } from '../../entities/activity.entity';
import { ActivityType, DaveMutationChangeActivityArgs, DaveMutationCreateActivityArgs, DaveMutationDeleteActivityArgs, QueryActivityArgs } from '../../graphql-types';
import { GraphQlEffectService } from '../../services/graph-ql-effect.service';
import { ActivityActions } from '../actions/activity.actions';

enum ErrorCodes {
    Load = 'Tätigkeiten Abrufen fehlgeschlagen',
    Modify = 'Tätigkeit Bearbeiten fehlgeschlagen',
    Remove = 'Tätigkeit Löschen fehlgeschlagen',
    Add = 'Tätigkeit Hinzufügen fehlgeschlagen',
}

@Injectable()
export class ActivityEffects {
    CreateActivity$ = this.graphQlEffectService.createAddEffect<DaveMutationCreateActivityArgs, ActivityEntity, ActivityType>(
        'createActivity',
        ActivityEntity.GqlFields.join(','),
        ActivityActions.add,
        ActivityActions.updateOne,
        ActivityEntityFromBackend,
        ErrorCodes.Add,
        ['name'],
    );
    ModifyActivity$ = this.graphQlEffectService.createModifyEffect<DaveMutationChangeActivityArgs, ActivityEntity, ActivityType>(
        'changeActivity',
        ActivityEntity.GqlFields.join(','),
        ActivityActions.change,
        ActivityActions.updateOne,
        ActivityEntityFromBackend,
        ErrorCodes.Modify,
        ['id'],
        ['name'],
    );
    RemoveActivity$ = this.graphQlEffectService.createModifyEffect<DaveMutationDeleteActivityArgs, ActivityEntity, ActivityType>(
        'deleteActivity',
        ActivityEntity.GqlFields.join(','),
        ActivityActions.delete,
        ActivityActions.updateOne,
        ActivityEntityFromBackend,
        ErrorCodes.Remove,
        ['id'],
    );
    GetActivity = this.graphQlEffectService.createGetAllEffect<QueryActivityArgs, ActivityEntity, ActivityType>(
        'activity',
        ActivityEntity.GqlFields.join(','),
        ActivityActions.load,
        ActivityActions.updateAll,
        (a) => a.map(ActivityEntityFromBackend),
        ErrorCodes.Load,
    );

    constructor(private graphQlEffectService: GraphQlEffectService) {}
}
