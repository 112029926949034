import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ViewStyleSettingEntity } from '../../entities/viewStyleSetting.entity';
import { ViewStyleSettingActionTypes } from '../actions/viewStyleSetting.action';

export interface ViewStyleSettingState extends EntityState<ViewStyleSettingEntity> {
    fetched: boolean;
}

export const VIEW_STYLE_SETTING_KEY = 'viewStyleSetting';

const adapter = createEntityAdapter<ViewStyleSettingEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const viewStyleSettingReducer = createReducer(
    initialState,
    on(ViewStyleSettingActionTypes.UpdateAll, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
    on(ViewStyleSettingActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(ViewStyleSettingActionTypes.DeleteOne, (state, { Payload }) => adapter.removeOne(Payload, { ...state })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllViewStyleSetting = selectAll;
export const selectviewStyleSettingEntities = selectEntities;
export const selectViewStyleSettingFetched = (state: ViewStyleSettingState) => state.fetched;
