import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { EmployeeToCommissionEntity } from '../../entities/employee-to-commission.entity';
import { EmployeeToCommissionActionTypes } from '../actions/employee-to-commission.action';

export interface EmployeeToCommissionState extends EntityState<EmployeeToCommissionEntity> {
    fetched: boolean;
}

export const EMPLOYEE_TO_COMMISSION_KEY = 'EmployeeToCommission';

const adapter = createEntityAdapter<EmployeeToCommissionEntity>({
    selectId: Entity => Entity.EmployeeId + '-' + Entity.CommissionId,
});

const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const employeeToCommissionReducer = createReducer(
    initialState,
    on(EmployeeToCommissionActionTypes.SetAll, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
    on(EmployeeToCommissionActionTypes.SetOne, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(EmployeeToCommissionActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload.EmployeeId + '-' + Payload.CommissionId, { ...state })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllEmployeeToCommission = selectAll;
export const selectEmployeeToCommissionEntities = selectEntities;

export const selectEmployeeToCommissionFetched = (state: EmployeeToCommissionState) => state.fetched;
