import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { HttpService } from '../../services/http.service';
import { BaseActionTypes } from '../actions/base.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { EntityRoleEntity, EntityRoleEntityFromBackend } from '../../entities/entity-role.entity';
import { EntityRoleActionTypes } from '../actions/entity-role.actions';

enum ErrorCodes {
    Load = 'EntityRole Abrufen fehlgeschlagen',
    Modify = 'EntityRole Bearbeiten fehlgeschlagen',
    Remove = 'EntityRole Löschen fehlgeschlagen',
    Add = 'EntityRole Hinzufügen fehlgeschlagen',
}

@Injectable()
export class EntityRoleEffects {

    GetEntityRole$ = createEffect(() => this.actions$.pipe(
        ofType(EntityRoleActionTypes.LoadAll),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([action, token]) => {
            const queryString = `
            query {
                entityRoles {
                    ${EntityRoleEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                map(res => {
                    return res && res.entityRoles
                        ? EntityRoleActionTypes.UpdateAll({
                              Payload: res.entityRoles.map(e => EntityRoleEntityFromBackend(e)),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
