import { CommonModule,CurrencyPipe,DecimalPipe } from '@angular/common';
import {
ChangeDetectionStrategy,
Component,DEFAULT_CURRENCY_CODE,
ElementRef,
HostListener,
Inject,
OnDestroy,
ViewChild
} from "@angular/core";
import { FormControl,ReactiveFormsModule,UntypedFormControl,UntypedFormGroup } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog,MatDialogConfig,MatDialogRef,MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { BehaviorSubject,combineLatest,firstValueFrom,Observable,of,Subject,Subscription } from "rxjs";
import { distinctUntilChanged,filter,map,shareReplay,startWith,take,withLatestFrom } from "rxjs/operators";
import { SimplebarAngularModule } from 'simplebar-angular';
import { AccountsReceivableLedgerTemplateEntity } from '../../../dave-data-module/entities/accounts-receivable-ledger-template.entity';
import { AccountsReceivableLedgerEntity,NettoCost } from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { QuantityTypeNames } from '../../../dave-data-module/entities/quantity-type.entity';
import {
    DaveMutationCreateAccountsReceivableLedgerArgs
} from '../../../dave-data-module/graphql-types';
import { ARLTemplateTypeResolver } from '../../../dave-data-module/guards/a-r-l-template-type.resolver';
import { AccountsReceivableLedgerTemplateResolver } from '../../../dave-data-module/guards/accounts-receivable-ledger-template.resolver';
import { CommissionTypeResolver } from '../../../dave-data-module/guards/commissionType.resolver';
import { QuantityTypeResolver } from '../../../dave-data-module/guards/quantity-type.resolver';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { ArlDataService } from "../../../dave-data-module/services/arl-data.service";
import { State } from '../../../dave-data-module/State';
import { AccountsReceivableLedgerActionTypes,AccountsReceivableLedgerTemplateActionTypes } from '../../../dave-data-module/State/actions/accounting.actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import {
    getAccountsReceivableLedgerTemplateDictionary,
    getAccountsReceivableLedgerTemplates,
    getARLTemplateTypes, getARLTemplateTypesFetched,
    getQuantityTypes,
} from '../../../dave-data-module/State/selectors/accounting.selector';
import { getCommissionTypes } from '../../../dave-data-module/State/selectors/commissionType.selectors';
import { getLedgerImportById,getLedgerImports } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { DaveLoadingModule } from '../../../dave-loading/dave-loading.module';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { SelectSearchOption } from "../../../dave-utils-module/select-search/components/select-search/select-search.component";
import { SelectSearchModule } from '../../../dave-utils-module/select-search/select-search.module';
import { appMatDialogDefaultConfig,DEFAULT_TAX,isNotNullOrUndefined,stringSearch } from "../../../helper/helper";
import { LoadingService } from '../../../services/loading.service';
import { ArlTemplateTableComponent } from "../arl-calculation/arl-template-table/arl-template-table.component";
import { ModifyArlTemplateComponent } from '../modify-arl-template/modify-arl-template.component';
import { ModifyBookingOptionTemplateDialogComponent,ModifyBookingOptionTemplateDialogComponentData } from '../modify-booking-option-template/modify-booking-option-template-dialog.component';
import {
NewOptionDefaultValues
} from '../modify-booking-option/modify-booking-option.component';
import { getFetched$ } from '../../../dave-data-module/helper/helper';

enum templateTypes {
    none = 'none',
    all = 'all',
    commissionType = 'commissionType',
    arlTemplateType= 'arlTemplateType'
}
export type NewBookingOptionComponentReturnType = AccountsReceivableLedgerEntity[] | undefined;

export interface NewOptionDialogData {
    newOptionDefaultValues?: NewOptionDefaultValues;
    addToLedgerImport?: boolean;
    accountsReceivableLedgerTemplateFilter?: {
        CommissionTypeId?: number;
    };
    ListView?: boolean;
    addedTemplateIds?: number[];
}
interface TableData {
    BookingText: string;
    TemplateType?: string;
    Information: string;
    SumNetto: string;
    Quantity: string;
    QuantityType: string;
    Hint?: string;
    ARLTemplate: AccountsReceivableLedgerTemplateEntity;
    dontEditHint?: string;
}
@Component({
    selector: 'app-new-booking-option',
    templateUrl: './new-booking-option.component.html',
    styleUrls: ['./new-booking-option.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DecimalPipe, CurrencyPipe],
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        FontAwesomeModule,
        SelectSearchModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTooltipModule,
        AppButtonModule,
        DaveLoadingModule,
        MatSelectModule,
        SimplebarAngularModule,
        MatTableModule,
        MatCheckboxModule,
        ModifyArlTemplateComponent,
        ArlTemplateTableComponent,
    ],
})
export class NewBookingOptionComponent implements OnDestroy {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        panelClass: ['custom-dialog-class-without-padding', ...appMatDialogDefaultConfig.panelClass],
        maxWidth: '100%',
        width: '90%',
    };
    protected IsSmallWidth$ = new BehaviorSubject(false);
    @ViewChild('wrapperElement') private wrapperElement: ElementRef;

    @ViewChild(ArlTemplateTableComponent) ArlTemplateTableComponent: ArlTemplateTableComponent;

    @HostListener('window:resize')
    ngAfterViewChecked() {
        if (this.wrapperElement?.nativeElement) {
            const smallWidth = this.wrapperElement.nativeElement.offsetWidth < 640;
            if (this.IsSmallWidth$.value !== smallWidth) {
                this.IsSmallWidth$.next(smallWidth);
            }
        }
    }
    protected templateFilterTypes = templateTypes;
    public ARLTemplateColumnHeaders = {
        BookingText: 'Art-Nr.',
        TemplateType: 'Vorlagentyp',
        Information: 'Bezeichnung',
        SumNetto: 'Summe',
        QuantityType: 'Einheit',
        Quantity: 'Menge',
    };
    public ARLTemplateAutoColumns = [
        'BookingText',
        // 'TemplateType',
        // 'Information',
        'SumNetto',
        'QuantityType',
        'Quantity',
    ];
    public Multiselect = new BehaviorSubject(true);
    public EditMode = new BehaviorSubject(false);
    public ARLTemplateColumns$ = this.Multiselect.pipe(
        map((m) => (m ? ['checkbox', 'BookingText', 'TemplateType', 'Information', 'Quantity', 'QuantityType', 'SumNetto', 'Hint'] : ['BookingText', 'TemplateType', 'Information', 'Quantity', 'QuantityType', 'SumNetto', 'Hint'])),
    );

    public ARLTemplateColumnsListView$ = this.Multiselect.pipe(
        map((m) => (m ? ['checkbox', 'BookingText', 'TemplateType', 'Information', 'Quantity', 'QuantityType', 'SumNetto', 'Hint'] : ['BookingText', 'TemplateType', 'Information', 'Quantity', 'QuantityType', 'SumNetto', 'Hint'])),
    );
    public ArlTypeForm = new FormControl<SelectSearchOption<{ id: number; type: templateTypes }>>({ id: null, type: templateTypes.all, optionLabel: 'Alle' });
    public SearchForm = new UntypedFormControl('');
    testFilter = of('pip')
    public BookingPositionTemplates$: Observable<ReadonlyArray<TableData>> = combineLatest([
        this.store.select(getAccountsReceivableLedgerTemplates).pipe(filter(isNotNullOrUndefined)),
        this.ArlTypeForm.valueChanges.pipe(distinctUntilChanged()),
        this.SearchForm.valueChanges.pipe(startWith('')),
    ]).pipe(
        map(([val, arlTypeForm, search]) =>
            val.filter(
                (v) =>
                    (arlTypeForm.type === templateTypes.all ||
                        (arlTypeForm.type === templateTypes.none && !v.CommissionTypeId && !v.ARLTemplateTypeId) ||
                        (arlTypeForm.type === templateTypes.commissionType && arlTypeForm.id === v.CommissionTypeId) ||
                        (arlTypeForm.type === templateTypes.arlTemplateType && arlTypeForm.id === v.ARLTemplateTypeId)
                    ) &&
                    !v.CustomProperties?.jveg &&
                    (!search || stringSearch(v.Information || '', search) || stringSearch(v.BookingText || '', search)),
            ),
        ),
        map((val) =>
            val.sort((a, b) => {
                const nameA = (a.BookingText || '').toUpperCase(); // Groß-/Kleinschreibung ignorieren
                const nameB = (b.BookingText || '').toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            }),
        ),
        withLatestFrom(this.store.select(getQuantityTypes), getFetched$(this.store, getARLTemplateTypesFetched, getARLTemplateTypes), this.store.select(getCommissionTypes)),
        map(([val, qt, type, commissiontype]) =>
            val.map((v) => ({
                ...v,
                SumNetto: this.currencyPipe.transform(NettoCost(v.Quantity, v.BaseCost), v.CurrencyCode),
                Quantity: this.decimalPipe.transform(v.Quantity),
                QuantityType: qt?.find((q) => q.Id === v.QuantityTypeId)?.Name || '',
                Hint: v.CustomProperties?.hint,
                dontEditHint: v.CustomProperties?.timeTrackingTemplate
                    ? 'Diese Vorlage ist für die automatische Zeiterfassung.'
                    : v.CommissionTypeId
                    ? 'Diese Vorlage gehört zu einer Auftragsart.'
                    : v.EventTypeId
                    ? 'Diese Vorlage gehört zu einer Ereignisart.'
                    : null,
                TemplateType: v.ARLTemplateTypeId ? type.find((arl) => arl.Id === v.ARLTemplateTypeId)?.Name : v.CommissionTypeId ? commissiontype.find((c) => c.Id === v.CommissionTypeId)?.Name : 'Kein Typ angegeben',
                ARLTemplate: v,
            })),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public IsFormDirty = false;
    public SelectedRowIndex: number;
    public ShouldGenerateTemplate = false;
    public SelectedTemplateId$ = new BehaviorSubject<number>(null);
    // private templateId: number = null;
    public Save$: Subject<void> = new Subject<void>();
    public MultipleSave$: Subject<void> = new Subject<void>();
    public Loaded$ = combineLatest([this.accountsReceivableLedgerTemplateResolver.resolve(), this.quantityTypeResolver.resolve(), this.arlTemplateTypeResolver.resolve(), this.commissionTypesResolver.resolve()]).pipe(map(() => true));
    private subs: Subscription[] = [];
    public SelectedTemplates: number[] = [];
    public ShowSearchBar$ = new BehaviorSubject(false);
    public TemplateTypes$: Observable<Array<SelectSearchOption<{ id: number; type: templateTypes }>>> = combineLatest([getFetched$(this.store, getARLTemplateTypesFetched, getARLTemplateTypes), this.store.select(getCommissionTypes)]).pipe(
        map(([arltt, commissionTypes]) => [
            { optionLabel: 'Alle', id: null, type: templateTypes.all },
            { optionLabel: 'Keine', id: null, type: templateTypes.none },
            ...arltt.map((a) => ({ optionLabel: a.Name, id: a.Id, type: templateTypes.arlTemplateType })),
            ...commissionTypes.map((a) => ({ optionLabel: a.Name, id: a.Id, type: templateTypes.commissionType })),
        ]),
    );

    constructor(
        private appDialog: AppDialogService,
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA) public Data: NewOptionDialogData,
        private accountsReceivableLedgerTemplateResolver: AccountsReceivableLedgerTemplateResolver,
        private quantityTypeResolver: QuantityTypeResolver,
        private arlTemplateTypeResolver: ARLTemplateTypeResolver,
        private commissionTypesResolver: CommissionTypeResolver,
        private dialog: MatDialog,
        private decimalPipe: DecimalPipe,
        private currencyPipe: CurrencyPipe,
        private dialogRef: MatDialogRef<NewBookingOptionComponent, NewBookingOptionComponentReturnType>,
        public PS: PermissionService,
        public LS: LoadingService,
        @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string,
        private arlDataService: ArlDataService,
        arlTemplateTypeRes: ARLTemplateTypeResolver
    ) {
        firstValueFrom(this.store.select(getARLTemplateTypesFetched)).then((fetched) => {
            if (!fetched) {
                arlTemplateTypeRes.resolve();
            }
        })
        this.Multiselect.next(!this.Data.ListView);
        this.subs.push(
            // combineLatest([ this.store.select(getARLTemplateTypes), this.store.select(getCommissionTypes)]).subscribe(([arlTypes, comTypes]) => {
            //     let Blabal: any[] = [Object.assign({'none'}, {optionLabel: 'Keine'}), Object.assign( 'all', {optionLabel: 'Alle'})]
            //     arlTypes.forEach( at => Blabal.push(Object.assign( at.Clone(), {optionLabel: at.Name})))
            // }),
            // this.SelectedTemplateId$.subscribe(val => (this.templateId = val)),
            this.BookingPositionTemplates$.subscribe(),
            this.store
                .select(getCommissionTypes)
                .pipe(filter(isNotNullOrUndefined))
                .subscribe((cts) => {
                    this.ArlTypeForm.setValue(
                        this.Data?.accountsReceivableLedgerTemplateFilter?.CommissionTypeId
                            ? {
                                  optionLabel: cts.find((ct) => ct.Id === this.Data.accountsReceivableLedgerTemplateFilter.CommissionTypeId).Name,
                                  id: cts.find((ct) => ct.Id === this.Data.accountsReceivableLedgerTemplateFilter.CommissionTypeId).Id,
                                  type: templateTypes.commissionType,
                              }
                            : { optionLabel: 'Alle', id: null, type: templateTypes.all },
                    );
                }),
            this.MultipleSave$.pipe(withLatestFrom(this.store.select(getQuantityTypes), this.store.select(getLedgerImports))).subscribe(([, qt, ledgerImports]) => {
                this.LS.startLoading('save-multiple-arls', { dialogMessage: 'Abrechnungspositionen werden hinzugefügt' });
                const defaultQuantityTypeId = qt?.find((q) => q.Name === QuantityTypeNames.Generel)?.Id;
                // this.actions$
                //     .pipe(
                //         ofType(AccountsReceivableLedgerActionTypes.UpdateSingleAccountsReceivableLedger, BaseActionTypes.ErrorAction),
                //         take(this.SelectedTemplates.length),
                //         withLatestFrom(this.store.select(getLedgerImportById({ id: this.Data.newOptionDefaultValues.LedgerImportId }))),
                //     )
                //     .subscribe(([action, li]) => {
                //         if (action.type === AccountsReceivableLedgerActionTypes.UpdateSingleAccountsReceivableLedger.type) {
                //             ids.push(action.Payload);
                //         } else {
                //             ids.push(null);
                //         }
                //         if (ids.length === this.SelectedTemplates.length) {
                //             this.LS.endLoading('save-multiple-arls');
                //             if (this.Data?.newOptionDefaultValues?.LedgerImportId && this.Data.addToLedgerImport) {
                //                 this.dialogRef.close(ids.filter(isNotNullOrUndefined));
                //             } else {
                //                 this.dialogRef.close();
                //             }
                //         }
                //     });
                firstValueFrom(this.ArlTemplateTableComponent.someDetailRowDirty$).then(dirty => {
                    if (dirty) {
                        firstValueFrom(this.appDialog.OpenConfirmationDialog({
                            heading: 'Änderungen vorher speichern?',
                            paragraph: 'Es gibt noch ungespeicherte änderungen an den Vorlagen, möchten Sie diese Speichern?',
                        })).then(([res]) => {
                            if (res) {
                                return this.ArlTemplateTableComponent.Submit();
                            }
                        })
                    }
                }).then(() => {
                    firstValueFrom(this.store.select(getAccountsReceivableLedgerTemplateDictionary)).then(async arlTemplateDict => {
                        const ids: AccountsReceivableLedgerEntity[] = [];
                        const ledgerImportId = this.Data.newOptionDefaultValues.LedgerImportId;
                        const documentType = this.Data.newOptionDefaultValues.LedgerImportId ? ledgerImports.find((l) => l.Id === this.Data.newOptionDefaultValues.LedgerImportId).DocumentType : null;
                        const customerId = this.Data.newOptionDefaultValues.CustomerId;
                        const commissionId = this.Data.newOptionDefaultValues.CommissionId;
                        const date = FrontendDate(new Date());


                        for (const id of this.SelectedTemplates) {
                            const v = arlTemplateDict[id];

                            const [parent] = await this.arlDataService.addArls([
                                {
                                    quantity: v.Quantity || 0,
                                    tax: v.Tax || 0,
                                    currencyCode: v.CurrencyCode || this.defaultCurrencyCode,
                                    bookingText: v.BookingText,
                                    baseCost: v.BaseCost || 0,
                                    costAmount: v.CostAmount,
                                    ledgerImportId,
                                    documentType,
                                    customerId,
                                    commissionId,
                                    information: v.Information,
                                    quantityTypeId: v.QuantityTypeId || defaultQuantityTypeId,
                                    multiplikator: v.Multiplier,
                                    customProperties: JSON.stringify({ ...v.CustomProperties, rootTemplateId: v.Id }),
                                    date,
                                    isVisible: true,
                                    longInformation: v.Longtext,
                                    showLongInformation: v.ShowLongtext,
                                    inheritFromChildren: v.InheritFromChildren,
                                    type: v.Type as any,
                                    resourceId: v.ResourceId,
                                },
                            ]);
                            ids.push(parent);
                            if (v.ArlTemplateIds?.length) {
                                const childArgs = v.ArlTemplateIds.map<DaveMutationCreateAccountsReceivableLedgerArgs>((tId) => {
                                    const t = arlTemplateDict[tId];
                                    return {
                                        quantity: t.Quantity || 0,
                                        tax: t.Tax || DEFAULT_TAX,
                                        currencyCode: t.CurrencyCode || this.defaultCurrencyCode,
                                        bookingText: t.BookingText,
                                        baseCost: t.BaseCost || 0,
                                        costAmount: t.CostAmount,
                                        ledgerImportId,
                                        documentType,
                                        customerId,
                                        commissionId,
                                        information: t.Information,
                                        quantityTypeId: t.QuantityTypeId || defaultQuantityTypeId,
                                        multiplikator: t.Multiplier,
                                        customProperties: JSON.stringify({ ...t.CustomProperties, rootTemplateId: t.Id }),
                                        date,
                                        isVisible: t.IsVisible,
                                        longInformation: t.Longtext,
                                        showLongInformation: t.ShowLongtext,
                                        parentId: parent.Id,
                                        type: t.Type as any,
                                        resourceId: t.ResourceId,
                                    };
                                });
                                const children = await this.arlDataService.addArls(childArgs);
                                this.arlDataService.changeArls([{id: parent.Id, arlIds: children.map(c => c.Id), multiplikator: parent.Multiplier}]);
                            }

                        }
                        this.LS.endLoading('save-multiple-arls');
                        if (this.Data?.newOptionDefaultValues?.LedgerImportId && this.Data.addToLedgerImport) {
                            this.dialogRef.close(ids.filter(isNotNullOrUndefined));
                        } else {
                            this.dialogRef.close();
                        }
                    })
                })

            }),
        );
    }

    CompareByIdAndType = (a: SelectSearchOption<{ id: number; type: templateTypes }>, b: SelectSearchOption<{ id: number; type: templateTypes }>) => a.id === b.id && a.type === b.type;
    OpenModifyTemplateDialog(id: number = null, IsLarge = false) {
        this.dialog.open(ModifyBookingOptionTemplateDialogComponent, {
            data: {
                BookingOptionTemplateId: id,
                IsLarge: IsLarge,
            } as ModifyBookingOptionTemplateDialogComponentData,
            maxWidth: '82rem',
            minWidth: '20rem',
            // width: '90vw',
            panelClass: 'custom-dialog-class-without-padding',
        });
    }

    public DeleteARLTemplate() {
        firstValueFrom(this.SelectedTemplateId$).then((templateId) => {
            if (templateId) {
                this.appDialog
                    .OpenConfirmationDialog({
                        paragraph: `Möchten Sie die gewählte Vorlage wirklich löschen?`,
                        styleDelete: true,
                    })
                    .subscribe(([res]) => {
                        if (res) {
                            this.store.dispatch(
                                AccountsReceivableLedgerTemplateActionTypes.DeleteAccountsReceivableLedgerTemplate({
                                    Payload: { id: templateId },
                                }),
                            );
                        }
                    });
            }
        });
    }

    //workaround because we can't use Form.dirty since mat-chip component is using a directive which makes the formcontrol inaccessible
    public IsDirty(fg: UntypedFormGroup): boolean {
        let isDirty = false;
        if (fg) {
            Object.keys(fg?.controls).forEach((key) => {
                if (fg?.controls[key].dirty && key !== 'TemplateTypeId') {
                    console.log('Dirty!');
                    isDirty = true;
                }
            });
        }

        return isDirty;
    }

    public ConfirmDiscard(fg: UntypedFormGroup, rowData) {
        console.log('Form has been changed!!');
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: `Möchten Sie Ihre Änderungen wirklich verwerfen?`,
            })
            .subscribe(([res]) => {
                if (res) {
                    fg.markAsPristine();
                    this.highlight(rowData);
                }
            });
    }

    public highlight(rowData) {
        this.SelectedRowIndex = rowData.Id;
        this.SelectedTemplateId$.next(rowData.Id);
    }
    // ClickTablerow(rowData) {
    //     if (this.Multiselect.value) {
    //         this.MultiSelectTemplate(rowData);
    //     } else {
    //         this.SelectedTemplateId$.next(rowData.Id);
    //         this.ShouldGenerateTemplate = false;
    //         this.highlight(rowData);
    //     }
    // }

    ngOnDestroy() {
        this.subs.forEach((s) => s.unsubscribe());
    }
    // MultiSelectTemplate(rowData: TableData) {
    //     const index = this.SelectedTemplates.findIndex((v) => v.Id === rowData.ARLTemplate.Id);
    //     if (index > -1) {
    //         this.SelectedTemplates.splice(index, 1);
    //     } else {
    //         this.SelectedTemplates.push(rowData.ARLTemplate);
    //     }
    // }

    // IsSelected = (rowData: TableData) => this.SelectedTemplates.findIndex((v) => v.Id === rowData.ARLTemplate.Id) > -1;
    getTooltip(selectedRowIndex = this.SelectedRowIndex): string {
        if (this.Data.addedTemplateIds?.includes(selectedRowIndex)) {
            return 'Bereits Hinzugefügt';
        } else {
            return null;
        }
    }
    public Close(arl?: AccountsReceivableLedgerEntity) {
        if (this.IsFormDirty) {
            this.appDialog
                .OpenConfirmationDialog({
                    paragraph: `Möchten Sie Ihre Änderungen wirklich verwerfen?`,
                })
                .subscribe(([res]) => {
                    if (res) {
                        this.dialogRef.close();
                    }
                });
        } else if (arl) {
            this.dialogRef.close([arl]);
        } else {
            this.dialogRef.close();
        }
    }
}
