import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, interval, of, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay } from 'rxjs/operators';
import { EnumClockInTypeSlug, WorkDayClockInTypeSlug } from '../dave-data-module/entities/clock-in-type.entity';
import { ClockInEntity, ClockInTypes } from '../dave-data-module/entities/clock-in.entity';
import { ClockInTypeResolver } from '../dave-data-module/guards/clock-in-type.resolver';
import { getFetched$ } from '../dave-data-module/helper/helper';
import { State } from '../dave-data-module/State';
import { getClockInTypeDictionary, getClockInTypes, getClockInTypesFetched } from '../dave-data-module/State/selectors/clock-in-type.selectors';
import { getClockInsWithoutDeleted } from '../dave-data-module/State/selectors/clock-in.selectors';
import { getEmployees } from '../dave-data-module/State/selectors/employees.selectors';
import { getUser } from '../dave-data-module/State/selectors/users.selectors';
import { getWorkDays } from '../dave-data-module/State/selectors/work-day.selectors';
import { isNotNullOrUndefined, sameDay, sortClockIns } from "../helper/helper";

@Injectable({
    providedIn: 'root',
})
export class TimeTrackerService {
    public Running = false;
    constructor(
        private store: Store<State>,
        private clockInTypeResolver: ClockInTypeResolver,
    ) {}
    public clockInTypes$ = getFetched$(this.store, getClockInTypesFetched, getClockInTypes, this.clockInTypeResolver).pipe(shareReplay({refCount: true, bufferSize: 1}));
    public clockInTypesWithoutMain$ = this.clockInTypes$.pipe(map((cts) => cts.filter((ct) => ct.Slug !== WorkDayClockInTypeSlug)));
    public mainClockInType$ = this.clockInTypes$.pipe(map((cts) => cts.find((c) => c.Slug === WorkDayClockInTypeSlug)));

    public lastClockIn$ = combineLatest([
        this.store.select(getClockInsWithoutDeleted).pipe(filter(isNotNullOrUndefined)),
        this.store.select(getUser).pipe(filter(isNotNullOrUndefined)),
        this.store.select(getEmployees).pipe(filter(isNotNullOrUndefined)),
    ]).pipe(
        map(([clockIns, user, employees]) => {
            const employee = employees.find((e) => e.UserId === user.Id);
            const ownClockIns = clockIns.filter((c) => c.EmployeeId === employee.Id);
            return ownClockIns.length ? ownClockIns.reduce((a, b) => (a.TimeStamp > b.TimeStamp ? a : b)) : null;
        }),
        distinctUntilChanged(),
    );
    public runningWorkday$ = combineLatest([this.store.select(getWorkDays).pipe(filter(isNotNullOrUndefined)), this.store.select(getClockInsWithoutDeleted).pipe(filter(isNotNullOrUndefined)), this.lastClockIn$]).pipe(
        // takeUntil(this.onDestroy),
        map(([workdays, clockIns, lastClockIn]) => {
            if (!lastClockIn) {
                return null;
            }
            const now = new Date();
            const wd = workdays?.find((w) => w.Id === lastClockIn.WorkDayId);
            return sameDay(now, lastClockIn?.TimeStamp) ? wd : null;
        }),
    );
    public sortedClockIns$ = this.runningWorkday$.pipe(
        map((wd) => wd?.Id),
        distinctUntilChanged(),
        switchMap((wId) =>
            combineLatest([
                wId ? this.store.select(getClockInsWithoutDeleted).pipe(map((cis) => cis.filter((c) => c.WorkDayId === wId))) : of([]),
                this.store.select(getClockInTypesFetched).pipe(
                    filter((fetched) => !!fetched),
                    switchMap(() => this.store.select(getClockInTypeDictionary)),
                ),
            ]),
        ),
        map(([clockIns, clockInTypes]) => {
            const globalTypeId = Object.values(clockInTypes).find((ci) => ci.Slug === EnumClockInTypeSlug.workTime)?.Id;
            const sorted: ClockInEntity[] = clockIns.slice().sort(sortClockIns(globalTypeId));
            return sorted.length && sorted[sorted.length - 1].ClockInTypeId === globalTypeId && sorted[sorted.length - 1].Type === ClockInTypes.End ? [] : sorted;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public RunningWorkTime$ = this.sortedClockIns$.pipe(
        switchMap((sorted) =>
            combineLatest([
                of(sorted),
                this.store.select(getClockInTypesFetched).pipe(
                    filter((fetched) => !!fetched),
                    switchMap(() => this.store.select(getClockInTypeDictionary)),
                    first(),
                ),
            ]),
        ),
        switchMap(([sorted, clockInTypes]) => {
            const globalTypeId = Object.values(clockInTypes).find((ci) => ci.Slug === EnumClockInTypeSlug.workTime)?.Id;
            let time = 0;
            if (!sorted.length) {
                this.Running = false;
                return of(time);
            }
            let valid = sorted[0].Type === ClockInTypes.Start && sorted[0].ClockInTypeId === globalTypeId;
            const now = new Date();
            if (valid) {
                time = now.getTime() - sorted[0].TimeStamp.getTime();
            }
            let i = 1;
            let currentlyPause = false;
            while (valid && i < sorted.length) {
                currentlyPause = sorted[i].Type === ClockInTypes.Start && clockInTypes[sorted[i].ClockInTypeId].IsBreakTime;
                if (currentlyPause) {
                    if (i + 1 < sorted.length) {
                        time -= sorted[i + 1].TimeStamp.getTime() - sorted[i].TimeStamp.getTime();
                    } else {
                        time -= now.getTime() - sorted[i].TimeStamp.getTime();
                    }
                }
                i++;
            }
            if (!valid) {
                return of(null);
            }
            this.Running = !(sorted[sorted.length - 1].Type === ClockInTypes.End && sorted[sorted.length - 1].ClockInTypeId === globalTypeId);
            if (currentlyPause || !this.Running) {
                return of(time);
            } else {
                // time += new Date().getTime() - now.getTime();
                return interval(1000).pipe(map((v) => time + new Date().getTime() - now.getTime()));
            }
        }),
        filter(isNotNullOrUndefined),
        map((timeMs) => {
            return {
                h: Math.trunc(timeMs / 1000 / 60 / 60),
                m: Math.trunc(timeMs / 1000 / 60) % 60,
                s: Math.trunc(timeMs / 1000) % 60,
            };
        }),
    );
}
