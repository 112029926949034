import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';
import {
    FILES_FEATURE_KEY,
    FileState,
    selectAllFile,
    selectFileEntities,
    selectFileFetched, selectFileIds
} from "../reducers/files.reducer";

const selectFile = createFeatureSelector< FileState>(FILES_FEATURE_KEY);
const getEntities = createSelector(selectFile, selectFileEntities);

export const getFiles = createSelector(selectFile, selectAllFile);
export const getFileIds = createSelector(selectFile, selectFileIds);
export const getFilesWithoutHidden = createSelector(getFiles, files => files?.filter(f => !f.Hidden));
export const getFileFetched = createSelector(selectFile, selectFileFetched);
export const getFileDictionary = getEntities;
export const getFileById = (props: { id: number }) => createSelector(getEntities, (dictionary) => props?.id && dictionary[props.id]);
export const getFilesByFolderId = (props: {folderId: number}) => createSelector(
    getFiles,
    (files) => files?.filter(f => f.FolderId === props.folderId && !f.Hidden),
);
