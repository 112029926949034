import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatPseudoCheckboxModule } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppButtonModule } from './../dave-utils-module/app-button-module/app-button.module';
import { SmallFileCardComponent } from './small-file-card.component';

@NgModule({
    declarations: [SmallFileCardComponent],
    imports: [CommonModule, FontAwesomeModule, MatButtonModule, AppButtonModule, MatTooltipModule, MatPseudoCheckboxModule],
    exports: [SmallFileCardComponent],
})
export class SmallFileCardModule {}
