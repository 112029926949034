<button class="button" mat-button [matMenuTriggerFor]="menu">
    <fa-icon class="user-icon" icon="user"></fa-icon>
    <span>{{ButtonText}}</span>
</button>
<mat-menu #menu>
    <a class="popup-menu-item" mat-menu-item [routerLink]="SettingsMeta.Path">
        <fa-icon class="popup-menu-icon" icon="cog"></fa-icon>
        <span>Einstellungen</span>
    </a>
    <button class="popup-menu-item" mat-menu-item (click)="LogoutService.Logout()">
        <fa-icon class="popup-menu-icon" icon="sign-out-alt"></fa-icon>
        <span>Logout</span>
    </button>
</mat-menu>
