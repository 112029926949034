import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HistoryMeta } from '../../helper/page-metadata';
import { CustomerNameService } from '../../dave-utils-module/dave-shared-components-module/services/customer-name.service';

@Component({
    selector: 'app-history',
    template: `
        <app-list-layout-with-routing [Path]="PageMetaPath" [TabLabel]="(CS.GetMultiple$()|async)+'liste'">
            <app-customer-list></app-customer-list>

            <app-history-bookmarks
                *appListLayoutWithRoutingTab="'Lesezeichen'"
            ></app-history-bookmarks>
        </app-list-layout-with-routing>
    `,
    styles: [``],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryComponent {
    PageMetaPath = HistoryMeta.Path;

    constructor(public CS: CustomerNameService) {
    }
}
