import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';
import {
    selectAllUserToEvent,
    selectUserToEventEntities,
    selectUserToEventFetched,
    selectUserToEventLatestUpdatedAt,
    UserToEventState,
    USER_TO_EVENT_KEY,
} from '../reducers/user-to-event.reducer';

const selectState = createFeatureSelector< UserToEventState>(USER_TO_EVENT_KEY);
const selectEntities = createSelector(selectState, selectUserToEventEntities);

export const getUserToEvents = createSelector(selectState, selectAllUserToEvent);
export const getUserToEventFetched = createSelector(selectState, selectUserToEventFetched);
export const getUserToEventLatestUpdatedAt = createSelector(selectState, selectUserToEventLatestUpdatedAt);

