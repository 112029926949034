import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RoutingHistoryService {
    private history = [];
    private currentPosition: number;
    private navigateBack: boolean;
    private popstate: boolean;

    constructor(private router: Router) {}

    public LoadRouting(): void {
        window.addEventListener('popstate', () => (this.popstate = true));

        this.currentPosition = 0;
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(({urlAfterRedirects}: NavigationEnd) => {
                this.history = [...this.history, urlAfterRedirects];

                this.currentPosition++;

                if (
                    this.history[
                        this.history.length - (this.currentPosition + 2)
                    ] === this.history[this.history.length - 1] &&
                    this.popstate
                ) {
                    this.history.pop();
                    this.navigateBack = true;

                    if (
                        this.history[
                            this.history.length - (this.currentPosition + 1)
                        ] ===
                        this.history[
                            this.history.length - (this.currentPosition - 1)
                        ]
                    ) {
                        this.navigateBack = false;
                    }
                } else if (
                    this.history[
                        this.history.length - (this.currentPosition + 1)
                    ] === this.history[this.history.length - 1]
                ) {
                    this.history.pop();
                    if (this.currentPosition > 0) {
                        this.currentPosition--;
                    }
                    this.navigateBack = false;
                } else {
                    this.history = this.history.slice(
                        0,
                        this.history.length - (this.currentPosition - 1)
                    );
                    this.navigateBack = false;
                    this.currentPosition = 0;
                }
                this.popstate = false;
            });
    }

    public get History() {
        return this.history;
    }

    public get PreviousUrl() {
        return (
            this.history[this.history.length - (this.currentPosition + 2)] ||
            '/'
        );
    }

    public get NextUrl() {
        return this.history[this.history.length - this.currentPosition] || null;
    }

    public get CurrentPosition() {
        return this.currentPosition;
    }

    public get NavigateBack() {
        return this.navigateBack;
    }

    public NavigateBackwards(steps = 1) {
        this.popstate = true;
        this.currentPosition = this.currentPosition + (steps - 1);
        window.history.go(steps * -1);
    }
}
