import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { UserToCommissionShiftActionTypes } from '../State/actions/user2CommissionShift.action';
import { BaseEntityReloadResolver2 } from './resolver.helper';
import {
    getUserToCommissionShiftFetched,
    getUserToCommissionShiftLatestUpdatedAt,
} from '../State/selectors/users-to-commission-shift.selectors';

@Injectable({
    providedIn: 'root',
})
export class User2CommissionShiftResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getUserToCommissionShiftFetched, getUserToCommissionShiftLatestUpdatedAt, UserToCommissionShiftActionTypes.Load, store);
    }
}
