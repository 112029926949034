import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { HttpService } from '../../services/http.service';
import { BaseActionTypes } from '../actions/base.actions';
import { State } from '../index';
import { CommentActionTypes } from '../actions/comment.action';
import { CommentEntity, CommentEntityFromBackend } from '../../entities/comment.entity';
import { getToken } from '../selectors/base.selectors';
import { CommissionActionTypes } from '../actions/commission.actions';
import { CommissionEntityFromBackend } from '../../entities/commission.entity';
import {EventsActionTypes} from "../actions/events.actions";
import {getUser} from "../selectors/users.selectors";
import {getComments} from "../selectors/comment.selector";
import {BackendDate} from "../../helper/backend-frontend-conversion.helper";

enum ErrorCodes {
    Load = 'Kommentare Abrufen fehlgeschlagen',
    Modify = 'Kommentar Bearbeiten fehlgeschlagen',
    Remove = 'Kommentar Löschen fehlgeschlagen',
    Add = 'Kommentar Hinzufügen fehlgeschlagen',
    LastSeen = 'Kommentar auf gelesen setzen fehlgeschlagen',
}

@Injectable()
export class CommentEffects {

    CreateComment$ = createEffect(() => this.actions$.pipe(
        ofType(CommentActionTypes.Create),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
                mutation {
                    createComment(
                        ${stringifyIfNotNullOrUndefined(Payload, 'entityId')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'documentIds')}
                        entityType: ${Payload.entityType}
                        ${stringifyIfNotNullOrUndefined(Payload, 'text')}
                ) {
                    ${CommentEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.createComment
                        ? CommentActionTypes.UpdateOne({
                              Payload: CommentEntityFromBackend(res.createComment),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    ModifyComment$ = createEffect(() => this.actions$.pipe(
        ofType(CommentActionTypes.Change),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
            mutation {
                changeComment(
                    id: ${Payload.id}
                     ${stringifyIfNotNullOrUndefined(Payload, 'text')}
                     ${stringifyIfNotNullOrUndefined(Payload, 'documentIds')}
                ) {
                    ${CommentEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res => {
                    const ret: Action[] = [];
                    if (res && res.changeComment) {
                        ret.push(
                            CommentActionTypes.UpdateOne({
                                Payload: CommentEntityFromBackend(res.changeComment),
                            }),
                        );
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    GetComment$ = createEffect(() => this.actions$.pipe(
        ofType(CommentActionTypes.Load),
        switchMap(({ Payload }) => {
            const queryString = `
            query {
                comment${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''}{
                    ${CommentEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                map(res => {
                    return res && res.comment
                        ? Payload?.updatedSince
                            ? CommentActionTypes.UpdateMany({
                                Payload: res.comment.map(val => CommentEntityFromBackend(val)),
                                updateLatestUpdatedAt: true,
                            })
                            : CommentActionTypes.UpdateAll({
                                Payload: res.comment.map(val => CommentEntityFromBackend(val)),
                                updateLatestUpdatedAt: true,
                            })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    RemoveComment$ = createEffect(() => this.actions$.pipe(
        ofType(CommentActionTypes.Delete),
        withLatestFrom(this.store$),
        concatMap(([{Payload}, store]) => {
            const queryString = `
                mutation{
                  deleteComment(
                  id: ${Payload.id}
                  )
                }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.deleteComment
                        ? CommentActionTypes.RemoveOne({Payload: Payload.id})
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    CommentSeen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommentActionTypes.SetLastSeen),
            withLatestFrom(this.store$, this.store$.select(getUser)),
            concatMap(([action, store, user]) => {
                const queryString = `
                mutation{
                  createLastSeenComment(userId: ${user.Id}, commentIds: ${JSON.stringify(action.Payload.commentIds)}) {
                    commentId
                    lastSeenAt
                    userId
                  }
                }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    withLatestFrom(this.store$.select(getComments)),
                    map(([res, comments]) => {
                        return res && res.createLastSeenComment
                            ? CommentActionTypes.UpdateMany({Payload: comments
                                    .filter(c => action.Payload.commentIds.includes(c.Id))
                                    .map(c => c.Clone({LastSeenAt: BackendDate(res.createLastSeenComment.lastSeenAt)}))
                            })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.LastSeen } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.LastSeen,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),);
            }),
        ),
    );
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
