import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl,MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterLinkWithHref } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { SimplebarAngularModule } from 'simplebar-angular';
import { BurgerButtonWrapperComponent } from '../burger-button-wrapper/burger-button-wrapper.component';
import { NewDocumentViewMenu } from '../components/templates/new-document-view/new-document-view.module';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../dave-utils-module/app-filter-module/app-filter.module';
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { PipesModule } from '../dave-utils-module/dave-shared-components-module/pipes/pipes.module';
import { SelectSearchModule } from '../dave-utils-module/select-search/select-search.module';
import { GermanPaginatorIntl } from "../services/german-paginator-intl.service";
import { DynamicFolderNodeComponent } from './components/dynamic-folder-node/dynamic-folder-node.component';
import { EditFolderUserComponent } from './components/edit-folder-user/edit-folder-user.component';
import { FileExplorerComponent } from './components/file-explorer/file-explorer.component';
import { NewFolderDialogComponent } from './components/new-folder-dialog/new-folder-dialog.component';
import { SelectFolderDialogComponent } from './components/select-folder-dialog/select-folder-dialog.component';
import { SelectFolderComponent } from './components/select-folder/select-folder.component';
import { ViewFolderComponent } from './components/view-folder/view-folder.component';

@NgModule({
    declarations: [FileExplorerComponent, NewFolderDialogComponent, SelectFolderDialogComponent, ViewFolderComponent, EditFolderUserComponent],
    imports: [
        CommonModule,
        MatSidenavModule,
        AppButtonModule,
        FontAwesomeModule,
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        SimplebarAngularModule,
        MatTooltipModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatCardModule,
        MatDividerModule,
        DaveSharedComponentsModule,
        MatDialogModule,
        MatMenuModule,
        ScrollingModule,
        TableVirtualScrollModule,
        PipesModule,
        DaveDoubleIconModule,
        MatTabsModule,
        SelectSearchModule,
        MatSelectModule,
        DaveLoadingModule,
        AppFilterModule,
        MatProgressBarModule,
        BurgerButtonWrapperComponent,
        RouterLinkWithHref,
        MatExpansionModule,
        SelectFolderComponent,
        NewDocumentViewMenu,
        MatPaginatorModule,
        DynamicFolderNodeComponent,
    ],
    exports: [FileExplorerComponent],
    providers: [{ provide: MatPaginatorIntl, useClass: GermanPaginatorIntl }],
})
export class DaveFileExplorerModule {}
