import { CommonModule } from '@angular/common';
import {
ChangeDetectionStrategy,
Component,
DEFAULT_CURRENCY_CODE,
EventEmitter,
Inject,
Input,
OnDestroy,
OnInit,
Output
} from '@angular/core';
import { FormControl,FormGroup,FormsModule,ReactiveFormsModule,Validators } from "@angular/forms";
import { MatAutocompleteModule,MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange,MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResolveData } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions,ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import {
BehaviorSubject,
combineLatest,
firstValueFrom,
merge,
Observable,
of,
Subject,
Subscription,
switchMap
} from "rxjs";
import { distinctUntilChanged,filter,map,shareReplay,startWith } from 'rxjs/operators';
import {
AccountsReceivableLedgerEntity,
AccountsReceivableLedgerTypeEnum,
ARLTypeEnumNames,
CalcAccountsReceivableLedgerAmount
} from "../../../../dave-data-module/entities/accounts-receivable-ledger.entity";
import { ResourceEntity } from '../../../../dave-data-module/entities/resource-dispo/resource.entity';
import { SurchargeRateEntity } from '../../../../dave-data-module/entities/surcharge-rate.entity';
import { AccountsReceivableLedgerResolver } from '../../../../dave-data-module/guards/accounts-receivable-ledger.resolver';
import { ResourceResolver } from '../../../../dave-data-module/guards/resource-dispo/resource.resolver';
import { SurchargeRateResolver } from '../../../../dave-data-module/guards/surcharge-rate.resolver';
import { getFetched$ } from '../../../../dave-data-module/helper/helper';
import { ArlDataService } from '../../../../dave-data-module/services/arl-data.service';
import { State } from '../../../../dave-data-module/State';
import { AccountsReceivableLedgerActionTypes } from '../../../../dave-data-module/State/actions/accounting.actions';
import { BaseActionTypes } from "../../../../dave-data-module/State/actions/base.actions";
import { RESOURCE_KEY } from '../../../../dave-data-module/State/reducers/resource-dispo/resource.reducer';
import { getAccountsReceivableLedgerById } from '../../../../dave-data-module/State/selectors/accounting.selector';
import { getResourceById } from '../../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import {
    getSurchargeRate, getSurchargeRateByArlType,
    getSurchargeRateFetched,
} from '../../../../dave-data-module/State/selectors/surcharge-rate.selector';
import { DaveDoubleIconModule } from '../../../../dave-double-icon/dave-double-icon.module';
import { AppDialogService } from '../../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { DEFAULT_TAX,isNotNullOrUndefined } from "../../../../helper/helper";
import { ResourcePageMeta } from '../../../../helper/page-metadata';
import { NumberTransformInputComponent } from '../../../../number-transform-input/number-transform-input.component';
import { ArlInformationInputComponent } from '../../arl-information-input/arl-information-input.component';
import { ArlCalculationDataService } from '../arl-calculation-data.service';
import { ArlCalculationSurcharge } from '../arl-calculation-surcharge';
import { ArlFormDataService,arlTableDataArlForm } from '../arl-form-data.service';
import {
calculationArlForm,
CalculationArlFormDataService,
calculationArlFormGroup
} from "../calculation-arl-form-data.service";
import { getJobSpecificationById } from '../../../../dave-data-module/State/selectors/job-specification.selector';
import { Material_KEY } from '../../../../dave-data-module/State/reducers/material.reducer';
import { MaterialResolver } from '../../../../dave-data-module/guards/material.resolver';



export interface arlTableData {
    arl: {
        Id: number;
        Multiplier: number;
        CurrencyCode?: string;
        Tax: number;
    };
    resource$: Observable<ResourceEntity | null>;
    // informationOptions$: Observable<{ value: string; optionLabel: string; resourceId: number }[]>;
    form: FormGroup<calculationArlForm>;
    getBaseCost: () => number;
}
@Component({
    selector: 'app-arl-calculation-view',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        NumberTransformInputComponent,
        MatSlideToggleModule,
        FormsModule,
        MatAutocompleteModule,
        DaveDoubleIconModule,
        MatTooltipModule,
        ArlInformationInputComponent,
    ],
    templateUrl: './arl-calculation-view.component.html',
    styleUrls: ['./arl-calculation-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CalculationArlFormDataService]
})
export class ArlCalculationViewComponent implements OnDestroy, OnInit {
    public static readonly RequiredResolvers: ResolveData = {
        [RESOURCE_KEY]: ResourceResolver,
        [Material_KEY]: MaterialResolver,
        accountsReceivableLedger: AccountsReceivableLedgerResolver,
        ...ArlCalculationSurcharge.RequiredResolvers,
    };
    @Input() positionPrefix = '';
    @Input() inheritFromChildrenForm: FormControl<boolean> = null;
    private _childrenVisibleForm: FormControl<boolean> = null;
    private _childrenVisibleFormSubscription: Subscription = null;

    @Input() set childrenVisibleForm(form: FormControl<boolean>) {
        this._childrenVisibleForm = form;
        if (form) {
            this._childrenVisibleFormSubscription?.unsubscribe();
            this._childrenVisibleFormSubscription = form.valueChanges.subscribe(visible => {
                firstValueFrom(this.arlData$).then(data => {
                    data.forEach(d => {
                        if (visible !== d.form.controls.IsVisible.value) {
                            d.form.controls.IsVisible.setValue(visible)
                            d.form.controls.IsVisible.markAsDirty();
                        }
                    });
                })
            })
        }
    }
    get childrenVisibleForm() {
        return this._childrenVisibleForm;
    }
    @Input() set ZuschlaegeResourcen(val: number) {
        console.log('ZuschlaegeResourcen', val)
        this.resourceZuschl.init(val)
    }
    @Input() set ZuschlaegeDefault(val: number) {
        console.log('ZuschlaegeDefault', val);
        this.defaultZuschl.init(val);
    }
    @Input() set ZuschlaegeEmployee(val: number) {
        console.log('ZuschlaegeEmployee', val);
        this.employeeZuschl.init(val);
    }
    @Input() set ZuschlaegeExternalService(val: number) {
        console.log('ZuschlaegeExternalService', val);
        this.externalZuschl.init(val);
    }
    @Input() set ZuschlaegeOther(val: number) {
        console.log('ZuschlaegeOther', val);
        this.otherZuschl.init(val);
    }
    @Output() calculatedAmount = new EventEmitter<number>();

    protected selectableArlTypes = [AccountsReceivableLedgerTypeEnum.Arl, AccountsReceivableLedgerTypeEnum.ResourceArl, AccountsReceivableLedgerTypeEnum.EmployeeArl, AccountsReceivableLedgerTypeEnum.ExternalService, AccountsReceivableLedgerTypeEnum.Other].map((value) => ({ value, label: ARLTypeEnumNames.get(value) }));
    public getChildFormValues = () =>
        firstValueFrom(this.arlData$).then((data) => {
            return data.map((d) => ({ ...d.form.value, getBaseCost: d.getBaseCost }));
        });
    // protected _arl = null;
    private arlId$ = new BehaviorSubject<number>(null);
    private childArlIdsFromState$ = this.arlId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((id) => this.store.select(getAccountsReceivableLedgerById({id}))),
        map(arl => arl?.ArlIds),
    );
    private childArlIds$ = new BehaviorSubject<number[]>(null);
    private childArls$ = this.childArlIds$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((ArlIds) => ArlIds?.length ? combineLatest(ArlIds.map(id => this.store.select(getAccountsReceivableLedgerById({id})))) : of([] as AccountsReceivableLedgerEntity[])),
        map(arls => arls.filter(isNotNullOrUndefined)),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    private arlForms$: Observable<arlTableData[]> = this.childArls$.pipe(
        map((arls) => arls.map((a) => a.Id)),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        switchMap((ids) =>
            ids.length
                ? combineLatest(
                      ids.map((id) =>
                          this.store.select(getAccountsReceivableLedgerById({ id })).pipe(
                              distinctUntilChanged((a, b) => a?.UpdatedAt.getTime() === b?.UpdatedAt.getTime()),
                              map((arl) => arl && this.getARLTableData(arl)),
                          ),
                      ),
                  )
                : of([]),
        ),
        map(arls => arls.filter(isNotNullOrUndefined)),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    private addedArls$ = new BehaviorSubject<Array<arlTableData>>([]);
    protected arlData$: Observable<arlTableData[]> = combineLatest([this.arlForms$, this.addedArls$]).pipe(
        map((v) => v.flat()),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    protected defaultZuschl = new ArlCalculationSurcharge(AccountsReceivableLedgerTypeEnum.Arl, this.arlData$, this.store);
    protected resourceZuschl = new ArlCalculationSurcharge(AccountsReceivableLedgerTypeEnum.ResourceArl, this.arlData$, this.store);
    protected employeeZuschl = new ArlCalculationSurcharge(AccountsReceivableLedgerTypeEnum.EmployeeArl, this.arlData$, this.store);
    protected externalZuschl = new ArlCalculationSurcharge(AccountsReceivableLedgerTypeEnum.ExternalService, this.arlData$, this.store);
    protected otherZuschl = new ArlCalculationSurcharge(AccountsReceivableLedgerTypeEnum.Other, this.arlData$, this.store);

    protected zuschlaege = [
        this.defaultZuschl,
        this.resourceZuschl,
        this.employeeZuschl,
        this.externalZuschl,
        this.otherZuschl,
    ]

    protected currencyCode$ = this.arlData$.pipe(
        map((data) => data?.find((d) => d.arl?.CurrencyCode)?.arl.CurrencyCode || this.currencyCode),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    private formDirtyChange$ = new Subject<void>();
    public arlFormsDirty$ = this.arlData$.pipe(
        switchMap((data) =>
            data.length
                ? combineLatest([
                    ...data.map((d) => d.form.valueChanges.pipe(startWith(null))),
                    this.resourceZuschl.form.valueChanges.pipe(startWith(null)),
                    this.defaultZuschl.form.valueChanges.pipe(startWith(null)),
                    this.employeeZuschl.form.valueChanges.pipe(startWith(null)),
                    this.externalZuschl.form.valueChanges.pipe(startWith(null)),
                    this.otherZuschl.form.valueChanges.pipe(startWith(null)),
                    this.formDirtyChange$.pipe(startWith(null)),
                ]).pipe(
                      map(() => data.some((d) => d.form.dirty) || this.resourceZuschl.form.dirty || this.defaultZuschl.form.dirty || this.employeeZuschl.form.dirty || this.externalZuschl.form.dirty || this.otherZuschl.form.dirty),
                  )
                : of(false),
        ),
        // tap(arlFormsDirty$ => console.log('###arlFormsDirty$', arlFormsDirty$))
    );
    public arlFormsInvalid$ = this.arlData$.pipe(switchMap((data) => (data.length ? combineLatest(data.map((d) => d.form.statusChanges.pipe(startWith(null)))).pipe(map(() => data.some((d) => d.form.invalid))) : of(false))));


    protected amountSum$: Observable<number> = combineLatest([this.defaultZuschl.amountSum$, this.resourceZuschl.amountSum$, this.employeeZuschl.amountSum$, this.externalZuschl.amountSum$, this.otherZuschl.amountSum$]).pipe(map((values) => values.reduce((prev, curr) => prev + (curr || 0), 0)));
    protected amountSumWithZuschl$: Observable<number> = combineLatest([this.defaultZuschl.amountSumWithZuschl$, this.resourceZuschl.amountSumWithZuschl$, this.employeeZuschl.amountSumWithZuschl$, this.externalZuschl.amountSumWithZuschl$, this.otherZuschl.amountSumWithZuschl$]).pipe(
        map((values) => values.reduce((prev, curr) => prev + (curr || 0), 0)),
    );

    private multiplier: number;
    private ledgerImportId: number;
    @Input() expanded = false;
    @Input() set arl(v: AccountsReceivableLedgerEntity /*| AccountsReceivableLedgerTemplateEntity*/) {
        // todo checken ob template oder nicht
        // AccountsReceivableLedgerTemplateEntity hat keinen amount
        if (v) {
            // this._arl = v;
            this.arlId$.next(v.Id);
            this.parentForm = this.arlFormDataService.getForm(v.Id);
            console.log(this.parentForm);
            if (!this.parentForm) {
                console.error('noParentForm');
            }
            this.multiplier = v.Multiplier;
            this.ledgerImportId = v.LedgerImportId;
            console.log('inp', this.arlId$.value);
        }
    }
    private parentForm: arlTableDataArlForm;
    private subs: Subscription[] = [];
    constructor(
        private store: Store<State>,
        private appDialog: AppDialogService,
        protected arlCalculationDataService: ArlCalculationDataService,
        private arlDataService: ArlDataService,
        private actions$: Actions,

        private arlFormDataService: ArlFormDataService,
        private calcArlFormDataService: CalculationArlFormDataService,
        protected surchargeRateResolver: SurchargeRateResolver,
        @Inject(DEFAULT_CURRENCY_CODE) private currencyCode: string,
    ) {
        this.subs.push(
            this.childArlIdsFromState$.subscribe(ids => this.childArlIds$.next(ids)),
            ...this.zuschlaege.map(z => z.amountSum$.pipe(distinctUntilChanged()).subscribe((amountSum) => {
                const { usePercent, percent, decimal } = z.form.value;
                z.setFormValue(usePercent, amountSum, usePercent ? percent : decimal, usePercent);
            })),
            this.otherZuschl.amountSum$.pipe(distinctUntilChanged()).subscribe((amountSum) => {
                const { usePercent, percent, decimal } = this.otherZuschl.form.value;
                this.otherZuschl.setFormValue(usePercent, amountSum, usePercent ? percent : decimal, usePercent);
            }),
            this.childArls$.pipe(map((arls) => /*arls.length === 0 ||*/ arls.some((a) => a.IsVisible))).subscribe((showChilds) => {
                console.log({ someVisible: showChilds, dirty: this.childrenVisibleForm.dirty, value: this.childrenVisibleForm.value });
                if (!this.childrenVisibleForm.dirty && this.childrenVisibleForm.value !== showChilds) {
                    this.childrenVisibleForm.setValue(showChilds);
                    console.log({ value: this.childrenVisibleForm.value });
                    this.childrenVisibleForm.markAsPristine();
                }
            }),
            // combineLatest([this.amountSumWithZuschl$, this.inheritFromChildrenForm.valueChanges.pipe(startWith(this.inheritFromChildrenForm.value))]).subscribe(([amount, calculate]) => {
            //     this.calculatedAmount.emit(amount);
            //     if (calculate) {
            //
            //     }
            // }),
            this.arlData$
                .pipe(
                    switchMap((data) =>
                        merge(
                            ...data
                                .map((d) => [
                                    d.form.controls.costAmountWithoutTax.valueChanges.pipe(map((v) => ({ changed: 'Amount', value: v, form: d }))),
                                    d.form.controls.Quantity.valueChanges.pipe(map((v) => ({ changed: 'Quantity', value: v, form: d }))),
                                    d.form.controls.CostAmount.valueChanges.pipe(map((v) => ({ changed: 'CostAmount', value: v, form: d }))),
                                ])
                                .flat(),
                        ),
                    ),
                )
                .subscribe(({ changed, form, value }) => {
                    console.log({ changed, form, value });
                    switch (changed) {
                        case 'Quantity':
                            if (form.form.value.CostAmount != null && value != null) {
                                form.form.controls.costAmountWithoutTax.setValue(form.form.value.CostAmount * value);
                            }
                            break;
                        case 'CostAmount':
                            if (value != null && form.form.value.Quantity != null) {
                                form.form.controls.costAmountWithoutTax.setValue(value * form.form.value.Quantity);
                            }
                            break;
                        //     case 'Amount':
                        //         console.log('Amount');
                        //         if (form.form.value.Amount != null && form.form.value.Quantity != null) {
                        //             form.form.controls.BaseCost.setValue(form.form.value.Amount / form.form.value.Quantity, {emitEvent: false});
                        //         }
                        //         break;
                    }
                }),
    );
    }

    ngOnInit(): void {
        this.subs.push(
            combineLatest([
                this.amountSumWithZuschl$,
                this.inheritFromChildrenForm ? this.inheritFromChildrenForm.valueChanges.pipe(startWith(null)) : of(null)
            ]).subscribe(([amount]) => this.calculatedAmount.emit(parseFloat((amount).toFixed(3)))),
            this.arlData$.subscribe((d) => {
                if (this.childrenVisibleForm) {
                    if (!d.length && this.childrenVisibleForm.enabled) {
                        this.childrenVisibleForm.disable();
                    } else if (!!d.length && this.childrenVisibleForm.disabled) {
                        this.childrenVisibleForm.enable();
                    }
                }
            }),
        );
    }

    getARLTableData(arl: AccountsReceivableLedgerEntity | null): arlTableData {
        let form: calculationArlFormGroup = arl && this.calcArlFormDataService.getForm(arl.Id);
        if (!form) {
            form = new FormGroup({
                costAmountWithoutTax: new FormControl<number>(arl ? (arl.Quantity || 0) * (arl.CostAmount || 0) : null, { nonNullable: true }),
                CostAmount: new FormControl<number>(arl ? arl.CostAmount : null, { nonNullable: true }),
                Quantity: new FormControl<number>(arl ? arl.Quantity : null, { nonNullable: true }),
                QuantityTypeId: new FormControl<number>(arl ? arl.QuantityTypeId : null, { nonNullable: true }),
                Information: new FormControl<string>(arl ? arl.Information : null, { nonNullable: true }),
                BookingText: new FormControl<string>(arl ? arl.BookingText : null, { nonNullable: true }),
                Type: new FormControl<AccountsReceivableLedgerTypeEnum>(arl ? arl.Type : AccountsReceivableLedgerTypeEnum.Arl, { nonNullable: true, validators: Validators.required }),
                ResourceId: new FormControl<number>(arl ? arl.ResourceId : null, { nonNullable: true }),
                JobSpecificationId: new FormControl<number>(arl ? arl.JobSpecificationId : null, { nonNullable: true }),
                IsVisible: new FormControl<boolean>(arl ? arl.IsVisible : this._childrenVisibleForm.value, { nonNullable: true }),
            });
            if (arl?.Id) {
                this.calcArlFormDataService.setForm(arl.Id, form);
            }
        }
        const baseCost = () => {
            const zuschlInPercent = (this.zuschlaege.find(z => z.type === form.value.Type).form.value.percent) || 0;
            return parseFloat(((form.value.CostAmount || 0) + (form.value.CostAmount || 0) * (zuschlInPercent / 100)).toFixed(3));
        };

        return {
            arl,
            resource$: form.controls.ResourceId.valueChanges.pipe(
                startWith(null),
                switchMap(() => (form.controls.ResourceId.value ? this.store.select(getResourceById({ id: form.controls.ResourceId.value })) : of(null))),
            ),
            getBaseCost: () => baseCost(),
            form,
        };
    }
    private deletedArlIds: number[] = [];
    removeArl(data: arlTableData) {
        if (data?.arl) {
            this.appDialog
                .OpenConfirmationDialog({
                    styleDelete: true,
                    paragraph: 'Wirklich löschen ?',
                })
                .subscribe((res) => {
                    if (res && res[0]) {
                        firstValueFrom(this.actions$.pipe(ofType(AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger, BaseActionTypes.ErrorAction))).finally(() => this.setParentFormArlIds())
                        this.deletedArlIds.push(data.arl.Id);
                        this.store.dispatch(AccountsReceivableLedgerActionTypes.DeleteAccountsReceivableLedger({ Payload: { id: data.arl.Id } }));
                    }
                });
        } else {
            this.addedArls$.next(this.addedArls$.value.filter((d) => d !== data));
            this.setParentFormArlIds();
        }
    }
    private setParentFormArlIds() {
        firstValueFrom(this.arlForms$).then((arls) => {
            this.parentForm?.controls.ARLIds.setValue([...arls.map((a) => a.arl.Id), ...this.addedArls$.value.map(() => null)]);
        });
    }
    addArl() {
        this.addedArls$.next([...this.addedArls$.value, this.getARLTableData(null)]);
        this.setParentFormArlIds();
    }
    public reset() {
        this.addedArls$.next([]);
        firstValueFrom(this.arlForms$).then((data) => {
            data.forEach((d) => d.form.reset());
        });
        this.setParentFormArlIds();
    }
    public submit() {
        const visible = !!this.childrenVisibleForm.value;
        const childrenVisibleFormDirty = this.childrenVisibleForm.dirty;
        const zuschlDirty = this.zuschlaege.some(z => z.form.dirty);
        return firstValueFrom(combineLatest([this.arlForms$, this.addedArls$, this.arlId$])).then(([arlForms, addedArls, parentArlId]) => {
            const toChange: /* DaveMutationChangeAccountsReceivableLedgerArgs*/ any[] = [];

            if (arlForms.every((a) => a.form.valid) && addedArls.every((a) => a.form.valid)) {
                console.log({ visible });
                arlForms
                    .filter((arl) => arl.form.dirty || childrenVisibleFormDirty || zuschlDirty)
                    .forEach((arl) => {
                        toChange.push({
                            id: arl.arl.Id,
                            amount: CalcAccountsReceivableLedgerAmount(arl.form.value.Quantity, arl.arl.Tax, arl.getBaseCost()),
                            costAmount: arl.form.value.CostAmount || 0,
                            baseCost: arl.getBaseCost() || 0,
                            quantity: arl.form.value.Quantity || 0,
                            quantityTypeId: arl.form.value.QuantityTypeId,
                            information: arl.form.value.Information,
                            bookingText: arl.form.value.BookingText,
                            multiplikator: arl.arl.Multiplier,
                            isVisible: visible,
                            type: arl.form.value.Type, // todo auf type umbauen
                            // todo save zuschl
                            resourceId: arl.form.value.ResourceId,
                            jobSpecificationId: arl.form.value.JobSpecificationId,
                        });
                    });
                const updateParent = (arlIds: number[]) => {
                    const parentForm = this.arlFormDataService.getForm(parentArlId);
                    if(!parentForm) {
                        console.error('parentForm not found', parentArlId)
                    } else {
                        const {ARLIds, ZuschlaegeResourcen, ZuschlaegeDefault, ZuschlaegeEmployee, ZuschlaegeExternalService, ZuschlaegeOther,} = parentForm.getRawValue();
                        console.log('updateParent', {ZuschlaegeResourcen, ZuschlaegeDefault, ZuschlaegeEmployee, ZuschlaegeExternalService, ZuschlaegeOther })
                        if (JSON.stringify(ARLIds) !== JSON.stringify(arlIds)) {
                            parentForm.controls.ARLIds.setValue(arlIds);
                            parentForm.controls.ARLIds.markAsDirty();
                        }
                        if ((ZuschlaegeResourcen || 0) !== (this.resourceZuschl.form.value.decimal || 0)) {
                            parentForm.controls.ZuschlaegeResourcen.setValue(this.resourceZuschl.form.value.decimal);
                            parentForm.controls.ZuschlaegeResourcen.markAsDirty();
                        }
                        if ((ZuschlaegeDefault || 0) !== (this.defaultZuschl.form.value.decimal || 0)) {
                            parentForm.controls.ZuschlaegeDefault.setValue(this.defaultZuschl.form.value.decimal);
                            parentForm.controls.ZuschlaegeDefault.markAsDirty();
                        }
                        if ((ZuschlaegeEmployee || 0) !== (this.employeeZuschl.form.value.decimal || 0)) {
                            parentForm.controls.ZuschlaegeEmployee.setValue(this.employeeZuschl.form.value.decimal);
                            parentForm.controls.ZuschlaegeEmployee.markAsDirty();
                        }
                        if ((ZuschlaegeExternalService || 0) !== (this.externalZuschl.form.value.decimal || 0)) {
                            parentForm.controls.ZuschlaegeExternalService.setValue(this.externalZuschl.form.value.decimal);
                            parentForm.controls.ZuschlaegeExternalService.markAsDirty();
                        }
                        if ((ZuschlaegeOther || 0) !== (this.otherZuschl.form.value.decimal || 0)) {
                            parentForm.controls.ZuschlaegeOther.setValue(this.otherZuschl.form.value.decimal);
                            parentForm.controls.ZuschlaegeOther.markAsDirty();
                        }
                    }
                    console.log(parentForm)
                    console.log(parentForm.controls.ARLIds.value.toString())

                    if (!parentForm) {
                        toChange.push({
                            id: parentArlId,
                            multiplikator: this.multiplier,
                            arlIds, //: [...arlForms.map((a) => a.arl.Id), ...arls.map((a) => a.Id)],
                            //     // zuschläge in additionaldata speichern (wird jetzt in arl-table-component gemacht)
                        });
                    }
                    return (toChange.length ? this.arlDataService
                        .changeArls([
                            ...toChange,
                            // {
                            //     id: parentArlId,
                            //     multiplikator: this.multiplier,
                            //     arlIds, //: [...arlForms.map((a) => a.arl.Id), ...arls.map((a) => a.Id)],
                            //     // zuschläge in additionaldata speichern (wird jetzt in arl-table-component gemacht)
                            // },
                        ]) : firstValueFrom(of([])))
                        .then(() => {
                            this.addedArls$.next([]);

                            if (this.childrenVisibleForm.dirty) {
                                this.childrenVisibleForm.markAsPristine();
                            }
                            this.zuschlaege.forEach(z => z.form.markAsPristine());

                            arlForms.forEach(d => {
                                if (d.form.dirty) {
                                    d.form.markAsPristine();
                                }
                            })
                            this.formDirtyChange$.next();
                        });
                };
                if (addedArls?.length) {
                    return this.arlDataService
                        .addArls(
                            addedArls.map((arl) => ({
                                amount: CalcAccountsReceivableLedgerAmount(arl.form.value.Quantity || 0, DEFAULT_TAX, arl.getBaseCost() || 0),
                                costAmount: arl.form.value.CostAmount || 0,
                                baseCost: arl.getBaseCost() || 0,
                                quantity: arl.form.value.Quantity || 0,
                                quantityTypeId: arl.form.value.QuantityTypeId,
                                information: arl.form.value.Information,
                                bookingText: arl.form.value.BookingText,
                                parentId: parentArlId,
                                ledgerImportId: this.ledgerImportId,
                                multiplikator: 1,
                                isVisible: visible,
                                type: arl.form.value.Type as any,
                                // todo save zuschl
                                resourceId: arl.form.value.ResourceId,
                            })),
                        )
                        .then((arls) => {
                            console.log({ arls, addedArls });
                            if (arls.length && arls.every((a) => a?.Id)) {
                                console.log('addedArls', arls)
                                this.childArlIds$.next([...this.childArlIds$.value, ...arls.map(a => a.Id)]);
                                this.addedArls$.next([]);
                                return updateParent([...arlForms.map((a) => a.arl.Id), ...arls.map((a) => a.Id)]);
                                // unnötig, da die fortan nicht mehr added sind
                                // addedArls.forEach(a => a.form.markAsPristine())
                            } else {
                                throw 'arls anlegen fehlgeschlagen';
                            }
                        });
                } else {
                    return updateParent(arlForms.map((a) => a.arl.Id));
                }
            } else {
                throw 'arl form not valid'
            }
        });
    }


    onInformationOptionSelected(data: arlTableData, event: MatAutocompleteSelectedEvent) {
        this.onSetEntityId(data, +event.option.id);
    }
    onSetEntityId(data: arlTableData, id: number | null) {
        console.log('setEntity', id, data)
        switch (data.form.value.Type) {
            case AccountsReceivableLedgerTypeEnum.ResourceArl:
                data.form.controls.ResourceId.setValue(id);
                data.form.controls.ResourceId.markAsDirty();
                if (id) {
                    firstValueFrom(data.resource$).then((resource) => {
                        if (resource?.Id === id) {
                            if (isNotNullOrUndefined(resource.MaxChargeRate)) {
                                data.form.controls.CostAmount.setValue(parseFloat((resource.MaxChargeRate / 100).toFixed(4)));
                            }
                            if (resource.QuantityTypeId) {
                                data.form.controls.QuantityTypeId.setValue(resource.QuantityTypeId);
                            }
                        }
                    });
                }
                break;
            case AccountsReceivableLedgerTypeEnum.EmployeeArl:
                data.form.controls.JobSpecificationId.setValue(id);
                data.form.controls.JobSpecificationId.markAsDirty();
                if (id) {
                    firstValueFrom(this.store.select(getJobSpecificationById({id}))).then((job) => {
                        if (job?.Id === id) {
                            if (isNotNullOrUndefined(job.Amount)) {
                                data.form.controls.CostAmount.setValue(parseFloat((job.Amount /* / 100*/).toFixed(4)));
                            }
                            if (job.QuantityTypeId) {
                                data.form.controls.QuantityTypeId.setValue(job.QuantityTypeId);
                            }
                        }
                    });
                }
                break;
        }

    }

    ngOnDestroy() {
        this.subs.forEach((s) => s.unsubscribe());
        this._childrenVisibleFormSubscription?.unsubscribe();
    }

    protected readonly ResourcePageMeta = ResourcePageMeta;
    protected readonly ARLTypeEnum = AccountsReceivableLedgerTypeEnum;

    onTypeSelectionChange($event: MatSelectChange, data: arlTableData) {
        const val: AccountsReceivableLedgerTypeEnum = $event.value;
        if (val !== AccountsReceivableLedgerTypeEnum.ResourceArl && data.form.controls.ResourceId.value) {
            data.form.controls.ResourceId.setValue(null);
        }
    }
}
