<ngx-simplebar [class.show]="!Editing">
	<app-person-detail-form
        #Form
		[Editing]="Editing"
		[Person]="Person"
		[IsModal]="true"
		(saved)="closeDialog()"
	></app-person-detail-form>
</ngx-simplebar>

<div class="card-actions" *ngIf="Editing">
	<button app-button [Inverted]="true" Color="cancel" type="button" (click)="closeDialog()">Abbrechen</button>
	<button app-button (click)="Save()" Color="green" type="button">Speichern</button>
</div>

<div class="card-actions" *ngIf="!Editing">
	<button app-button [Inverted]="true" Color="cancel" type="button" (click)="closeDialog()">schließen</button>
</div>
