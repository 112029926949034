import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "../index";
import {StatusFromSVFeatureKey, StatusFromSVState} from '../reducers/statusFromSV.reducer';

export const selectStatusFromSV = createFeatureSelector<
  StatusFromSVState
>(StatusFromSVFeatureKey);
export const getStatusFromSV = createSelector(
  selectStatusFromSV,
  data => data.StatusFromSV
);
export const getSingleStatusFromSV = createSelector(
  getStatusFromSV,
  (statusFromSV: ReturnType<typeof getStatusFromSV>, Id: number) =>
    statusFromSV ? statusFromSV.find(e => e.Id === Id) : null
);
