import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { GeneratedDocumentsTypeEntityFromBackend } from '../../entities/generatedDocumentsType.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { GeneratedDocumentsTypeActionTypes } from '../actions/generatedDocumentsType.actions';
import { State } from '../index';
import { GeneratedDocumentsTypeEntity } from './../../entities/generatedDocumentsType.entity';

enum ErrorCodes {
    Load = 'Dokumenteytypen Abrufen fehlgeschlagen',
    Modify = 'Dokumententypen Bearbeiten fehlgeschlagen',
}

@Injectable()
export class GeneratedDocumentsTypeEffects {

    LoadGeneratedDocumentsTypes = createEffect(() => this.actions$.pipe(
        ofType(GeneratedDocumentsTypeActionTypes.GetGeneratedDocumentsTypeRequest),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
      query{
        generatedDocumentsType{${GeneratedDocumentsTypeEntity.GQLFields.join(',')}}
      }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                map(res =>
                    res && res.generatedDocumentsType
                        ? GeneratedDocumentsTypeActionTypes.UpdateGeneratedDocumentsType({
                              Payload: res.generatedDocumentsType.map(val => GeneratedDocumentsTypeEntityFromBackend(val)),
                          })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Load },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    LoadGeneratedDocumentTypeById = createEffect(() => this.actions$.pipe(
        ofType(GeneratedDocumentsTypeActionTypes.GetByIdRequest),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `query {
			generatedDocumentsType(id: ${action.id}){
				  ${GeneratedDocumentsTypeEntity.GQLFields.join(',')}
				  ${action.withFile ? ',markdown' : ''}
				}
		  }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                map(res =>
                    res && res.generatedDocumentsType && res.generatedDocumentsType.length === 1
                        ? GeneratedDocumentsTypeActionTypes.UpdateGeneratedDocumentType({
                              Payload: GeneratedDocumentsTypeEntityFromBackend(res.generatedDocumentsType[0]),
                          })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Load },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
