import { Address, AddressEntityPropertyNames, uniqArray } from "../../helper/helper";
import { CommissionType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
import { EntityRolePermissionNameEnum } from './entity-role.entity';
import { UserToCommissionEntity } from "./user-to-commission.entity";
import {
    SelectSearchOption
} from "../../dave-utils-module/select-search/components/select-search/select-search.component";

export interface AdditionalDataCommissionEntity {
    wizardPage?: number;
    akkord?: {
        nachlassZulage?: number;
        vorarbeiterBonus?: number;
    };
    planedWorkingHours?: number;
}
export const CommissionEntitygqlFields = `
        additionalData
        abgabeterminAG
        abgabeterminQM
        aktenzeichen
        auftragseingangDurchAuftraggeber
        auftragsnummer
        canCreate
        canDelete
        canEdit
        canRoleEdit
        canShareWithPartner
        commissionTypeId
        personId
        completeBusinessVolume
        createdAt
        customerId
        customerName
        deleted
        description
        employeeId
        ersterBerichtErstellt
        id
        interneNummer
        lastDeadline
        partnerId
        schadennummer
        schadensdatum
        statusBackofficeId
        statusFromSVId
        updatedAt
        userId
        versicherungsArbeitsgebiet
        versicherungsbedingung
        versicherungsCity
        versicherungsDescription
        versicherungsMobileNumber
        versicherungsName
        versicherungsnummer
        versicherungsPhoneNumber
        versicherungsPostalCode
        versicherungsStreet
        versicherungsZusatzName
        automaticAccountsReceivableLedger
        automaticAccountsReceivableLedgerByClockIns
        user2commission {
          ${UserToCommissionEntity.GqlFields}
        }
        startDate
        plannedStartDate
        endDate
        plannedEndDate
        partnerOfficeId
        city
        postalCode
        street
        country
        versicherungssumme
        freeTextField1
        freeTextField2
        freeTextField3
        orderNoCustomer
        parentId
        costsCompleteBusinessVolume
        planIds
        costCenter
        handoverDate`;

export class CommissionEntity implements Address, SelectSearchOption {
    public AdditionalData: AdditionalDataCommissionEntity = null;
    public static FormFieldNames: Map<keyof CommissionEntity, string> = new Map([['Auftragsnummer', 'Auftragsnr.']]);
    public static EntityPropertyNames: Map<keyof CommissionEntity, string> = new Map([
        ['AbgabeterminAG', 'Abgabetermin AG'],
        ['AbgabeterminQM', 'Abgabetermin QM'],
        ['LastDeadline', 'Letzte Frist'],
        ['Description', 'Sache'],
        ['Auftragsnummer', CommissionEntity.FormFieldNames.get('Auftragsnummer')],
        ['CostCenter', 'Kostenstelle'],
        ['InterneNummer', 'InterneNr.'],
        ['Street', AddressEntityPropertyNames.get('Street')],
        ['PostalCode', AddressEntityPropertyNames.get('PostalCode')],
        ['City', AddressEntityPropertyNames.get('City')],
        ['Country', AddressEntityPropertyNames.get('Country')],
        ['AuftragseingangDurchAuftraggeber', 'Auftragseingang'],
        ['StartDate', 'Startdatum'],
        ['EndDate', 'Enddatum'],
        ['Schadensdatum', 'Schadensdatum'],
        ['Versicherungssumme', 'Versicherungssumme'],
        ['Schadennummer', 'SchadenNr.'],
        ['Versicherungsnummer', 'VersicherungsNr.'],
        ['AutomaticAccountsReceivableLedger', 'Automatische Buchungspositionen'],
        ['AutomaticAccountsReceivableLedgerByClockIns', 'Automatische Buchungspositionen auf Basis der Zeiterfassungsdaten'],
        ['FreeTextField1', 'Allg. Infos zum Projekt'],
        ['OrderNoCustomer', 'Bestellnummer des Kunden'],
        ['CompleteBusinessVolume', 'Geplanter Umsatz (Netto)'],
        ['CostsCompleteBusinessVolume', 'Geplante Kosten (Netto)'],
    ]);
    constructor(
        public Id: number = null,
        public Description: string = '', // sache
        public CommissionTypeId: number = null,
        public Schadensdatum: Date = null,
        public Versicherungsbedingung: string = '',
        public InterneNummer: string = '',
        public Auftragsnummer: string = '',
        public Aktenzeichen: string = '',
        public VersicherungsName: string = '',
        public VersicherungsDescription = '',
        public VersicherungsZusatzName = '',
        public VersicherungsCity = '',
        public VersicherungsStreet = '',
        public VersicherungsPostalCode = '',
        public VersicherungsMobileNumber = '',
        public VersicherungsPhoneNumber = '',
        public VersicherungsArbeitsgebiet = '',
        public Schadennummer: string = '',
        public Versicherungsnummer: string = '',
        public CustomerName: string = '',
        public AuftragseingangDurchAuftraggeber: Date = null,
        public AbgabeterminAG: Date = null,
        public AbgabeterminQM: Date = null,
        public StatusfromSVId: number = null,
        public StatusBackofficeId: number = null,
        public LastDeadline: Date = null,
        public CustomerId: number = null,
        public EmployeeId: number = null,
        public UserId: number = null,
        public PersonId: number = null,
        public PartnerId: number = null,
        public Deleted: boolean = false,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public CanCreate: boolean = true,
        public CanEdit: boolean = true,
        public CanRoleEdit: boolean = true,
        public CanDelete: boolean = true,
        public CanShareWithPartner: boolean = true,
        public AutomaticAccountsReceivableLedger: number = null,
        public AutomaticAccountsReceivableLedgerByClockIns: number = null,
        public UserIds: number[] = [],
        public AssignUserIds: number[] = [],
        public PlannedStartDate: Date = null,
        public StartDate: Date = null,
        public PlannedEndDate: Date = null,
        public EndDate: Date = null,
        public PartnerOfficeId: number = null,
        public City: string = null,
        public PostalCode: string = null,
        public Street: string = null,
        public Country: string = null,
        AdditionalDataInput: string = null,
        public Versicherungssumme: string = '',
        public FreeTextField1: string = null,
        public FreeTextField2: string = null,
        public FreeTextField3: string = null,
        public OrderNoCustomer: string = null,
        public CompleteBusinessVolume: number = null,
        public ParentId: number = null,
        public CostsCompleteBusinessVolume: number = null,
        public PlanIds: number[] = null,
        public CostCenter: string = null,
        public HandoverDate: Date = null,
    ) {
        if (AdditionalDataInput != '') {
            try {
                this.AdditionalData = JSON.parse(AdditionalDataInput);
            } catch (e) {
                throw 'broken Json in additionaldata, commissionId: ' + Id;
            }
        }
    }
    public GetBackgroundColor(isCompleted: boolean, color: string, opacity = 20) {
        if (!color) {
            return '#00000000'
        }
        let toHex = parseInt(opacity+'', 16)
        if (toHex > 255) {
            toHex = 255;
        }
        const hexOpacity = toHex.toString(16);
        let ret = !isCompleted && this.LastDeadline && this.LastDeadline <= new Date() ? '#ff0000' : color;
        return ret.slice(0,7)+hexOpacity;
    }
    public Clone(override: Partial<CommissionEntity> = {}): CommissionEntity {
        const attr = { ...this, ...override };
        return Object.assign(new CommissionEntity(), attr);
    }

    public GetDisplayName() {
        let name = this.Auftragsnummer ? this.Auftragsnummer : '';
        name = name && this.InterneNummer ? name + ', ' : name;
        name = this.InterneNummer ? name + this.InterneNummer : name;
        name = name && this.Description ? name + ', ' : name;
        name = this.Description ? name + this.Description : name;
        name = name && this.Schadennummer ? name + ', ' : name;
        name = this.Schadennummer ? name + this.Schadennummer : name;

        return name ? name : 'unbenannt';
    }

    get DisplayName() {
        return (this.InterneNummer ? this.InterneNummer : '--') + ' | ' + (this.Description ? this.Description : '--') + ' | ' + (this.Auftragsnummer ? this.Auftragsnummer : '--');
    }
    get optionLabel() {
        return this.DisplayName;
    }
    permissionGet(prop: keyof CommissionEntity | keyof AdditionalDataCommissionEntity, EntityRoles: Array<{ Permissions: Array<{ name: EntityRolePermissionNameEnum }> }>): boolean {
        switch (prop) {
            case 'CompleteBusinessVolume':
                return EntityRoles.some(e => e.Permissions.some((p) => p.name === EntityRolePermissionNameEnum.GetCompleteBusinessVolume));

            case 'CostsCompleteBusinessVolume':
                return EntityRoles.some(e => e.Permissions.some((p) => p.name === EntityRolePermissionNameEnum.GetCostsCompleteBusinessVolume));

            case 'Versicherungssumme':
                return EntityRoles.some(e => e.Permissions.some((p) => p.name === EntityRolePermissionNameEnum.GetVersicherungssumme));

            case 'planedWorkingHours':
                return EntityRoles.some(e => e.Permissions.some((p) => p.name === EntityRolePermissionNameEnum.GetPlanedWorkingHours));
            default:
                return true;
        }
    }
}

export function CommissionEntityFromBackend(res: CommissionType): CommissionEntity {
    const assignedUsers: number[] = uniqArray((res.user2commission || []).filter((u2c) => u2c.assignee).map((c) => c.userId));
    const users = uniqArray((res.user2commission || []).filter((u2c) => !u2c.assignee && !assignedUsers.includes(u2c.userId)).map((c) => c.userId));
    return new CommissionEntity(
        res.id,
        res.description,
        res.commissionTypeId,
        res.schadensdatum && BackendDate(res.schadensdatum),
        res.versicherungsbedingung,
        res.interneNummer,
        res.auftragsnummer,
        res.aktenzeichen,
        res.versicherungsName,
        res.versicherungsDescription,
        res.versicherungsZusatzName,
        res.versicherungsCity,
        res.versicherungsStreet,
        res.versicherungsPostalCode,
        res.versicherungsMobileNumber,
        res.versicherungsPhoneNumber,
        res.versicherungsArbeitsgebiet,
        res.schadennummer,
        res.versicherungsnummer,
        res.customerName,
        res.auftragseingangDurchAuftraggeber ? BackendDate(res.auftragseingangDurchAuftraggeber) : null,
        res.abgabeterminAG ? BackendDate(res.abgabeterminAG) : null,
        res.abgabeterminQM ? BackendDate(res.abgabeterminQM) : null,
        res.statusFromSVId,
        res.statusBackofficeId,
        res.lastDeadline ? BackendDate(res.lastDeadline) : null,
        res.customerId,
        res.employeeId,
        res.userId,
        res.personId,
        res.partnerId,
        res.deleted,
        res.createdAt ? BackendDate(res.createdAt) : null,
        res.updatedAt ? BackendDate(res.updatedAt) : null,
        res.canCreate,
        res.canEdit,
        res.canRoleEdit,
        res.canDelete,
        res.canShareWithPartner,
        res.automaticAccountsReceivableLedger,
        res.automaticAccountsReceivableLedgerByClockIns,
        users,
        assignedUsers,
        res.plannedStartDate ? BackendDate(res.plannedStartDate) : null,
        res.startDate ? BackendDate(res.startDate) : null,
        res.plannedEndDate ? BackendDate(res.plannedEndDate) : null,
        res.endDate ? BackendDate(res.endDate) : null,
        res.partnerOfficeId,
        res.city,
        res.postalCode,
        res.street,
        res.country,
        res.additionalData,
        res.versicherungssumme,
        res.freeTextField1,
        res.freeTextField2,
        res.freeTextField3,
        res.orderNoCustomer,
        res.completeBusinessVolume,
        res.parentId,
        res.costsCompleteBusinessVolume,
        res.planIds || [],
        res.costCenter,
        res.handoverDate ? BackendDate(res.handoverDate) : null,
    );
}
