import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UserEntity, UserEntityFromBackend } from '../../entities/user.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { EmployeeActionTypes } from '../actions/employee.actions';
import { ResolverActionTypes, ResolverLoadLicenses, ResolverLoadManageUserList } from '../actions/resolver.actions';
import { UsersActionTypes } from '../actions/users.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';

enum ErrorCodes {
    Load = 'Benutzer Abrufen fehlgeschlagen',
    Modify = 'Benutzer Bearbeiten fehlgeschlagen',
    Remove = 'Benutzer Löschen fehlgeschlagen',
    Add = 'Benutzer Hinzufügen fehlgeschlagen! Bitte überprüfe die eingegebene E-Mail!',
}

@Injectable()
export class UserEffects {

    AddManageUser$ = createEffect(() => this.actions$.pipe(
        ofType(UsersActionTypes.AddManageUser),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                createUser(
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'firstname')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'lastname')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'email')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'employeeId')}

                    ${action.Payload.partnerIds && action.Payload.partnerIds.length > 0 ? `partnerIds: [${action.Payload.partnerIds[0]}]` : ''}
                    ${action.Payload.productIds ? `productIds: [${action.Payload.productIds.join(', ')}]` : ''}

                ) {
                    ${UserEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res => {
                    return res && res.createUser
                        ? [
                              UsersActionTypes.UpdateManageUserList({
                                  Payload: [...(store.users.manageUserList ? store.users.manageUserList : []), UserEntityFromBackend(res.createUser)],
                              }),
                              EmployeeActionTypes.Load({}),
                          ]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } })];
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    LoadUsers$ = createEffect(() => this.actions$.pipe(
        ofType(UsersActionTypes.Load),
        withLatestFrom(this.store$.select(getToken)),
        switchMap(([{ Payload }, token]) => {
            // ToDo: add role
            const queryString = `
            query {
                user${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
                    ${UserEntity.GqlFields}
                }
            }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                tap(res => {
                    if (!(Payload?.updatedSince && !res.user.length)) {
                        this.store$.dispatch(
                            res && res.user
                                ? Payload?.updatedSince
                                    ? UsersActionTypes.UpdateMany({
                                          Payload: res.user.map(val => UserEntityFromBackend(val)),
                                          updateLatestUpdatedAt: true,
                                      })
                                    : UsersActionTypes.UpdateAll({
                                          Payload: res.user.map(val => UserEntityFromBackend(val)),
                                          updateLatestUpdatedAt: true,
                                      })
                                : BaseActionTypes.ErrorAction({
                                      Payload: { ToasterMessage: ErrorCodes.Load },
                                  }),
                        );
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            );
        })
    ), { dispatch: false });


    LoadUser$ = createEffect(() => this.actions$.pipe(
        ofType(ResolverActionTypes.LoadUser),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
            query {
                user(
                id: ${action.Payload.id})
                 {
                    ${UserEntity.GqlFields}
                }
            }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                concatMap(res =>
                    res && res.user && res.user.length > 0
                        ? [UsersActionTypes.UpdateUser({
                              Payload: UserEntityFromBackend(res.user[0]),
                          }),
                            UsersActionTypes.UpdateUserSettings({Payload: (res.user[0].additionalData as any)?.settings})]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } })],
                ),
                catchError((err, caught) => {
                    console.error(err, caught);
                    return of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                }),
            );
        }),
        catchError((err, c) => {
            console.error(err, c);
            return of(), c;
        }),
    ));


    LoadManageUserList$ = createEffect(() => this.actions$.pipe(
        ofType(ResolverActionTypes.LoadManageUserList),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            // ToDo: add role
            const queryString = `
            query{
              allUser{
                ${UserEntity.GqlFields}
              }
            }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                map(res =>
                    res && res.allUser
                        ? UsersActionTypes.UpdateManageUserList({
                              Payload: res.allUser.map(val => UserEntityFromBackend(val)),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    ModifyManageUser$ = createEffect(() => this.actions$.pipe(
        ofType(UsersActionTypes.ModifyManageUser),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                changeUser(
                    id: ${action.Payload.Id}
                    firstname: "${action.Payload.Firstname}"
                    lastname: "${action.Payload.Lastname}"
                    email: "${action.Payload.Email}"
                    ${action.Payload.DocumentId ? `${'documentId: ' + action.Payload.DocumentId}` : ''}
                    ${action.Payload.ProductIds ? `productIds: [${action.Payload.ProductIds.join(', ')}]` : ''}
                ) {
                    ${UserEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res => {
                    const ret: Action[] =
                        res && res.changeUser
                            ? [
                                  UsersActionTypes.UpdateManageUserList({
                                      Payload: store.users.manageUserList ? [...store.users.manageUserList.map(val => val)] : null,
                                  }),
                                  new ResolverLoadLicenses(),
                                  UsersActionTypes.Load({}),
                                  new ResolverLoadManageUserList(),
                              ]
                            : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } })];
                    if (res.changeUser && res.changeUser.id === store.users.user?.Id) {
                        ret.push(
                            UsersActionTypes.UpdateUser({
                                Payload: UserEntityFromBackend(res.changeUser),
                            }),
                        );
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    DeleteManageUser$ = createEffect(() => this.actions$.pipe(
        ofType(UsersActionTypes.DeleteManageUser),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                deleteUser(id: ${action.Payload})
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res =>
                    res && res.deleteUser
                        ? UsersActionTypes.UpdateManageUserList({
                              Payload: store.users.manageUserList.map(val => (val.Id === action.Payload ? val.Clone({ Deleted: true }) : val)),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
