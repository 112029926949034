import { CommonModule, formatCurrency, formatDate, formatNumber, getCurrencySymbol } from '@angular/common';
import { Component, DEFAULT_CURRENCY_CODE, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GetTimeFromTimestamp, GetTimestampFromTime } from '@dave/types';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { firstValueFrom, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { FileDataService } from '../dave-data-module/services/file-data.service';
import { State } from '../dave-data-module/State';
import { FilesActionTypes } from '../dave-data-module/State/actions/files.actions';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { IDetailListTemplateData } from '../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { isNotNullOrUndefined } from '../helper/helper';

@Component({
    selector: 'app-document-detection-result-data-modal',
    standalone: true,
    imports: [CommonModule, DaveSharedComponentsModule, AppButtonModule],
    templateUrl: './document-detection-result-data-modal.component.html',
    styleUrls: ['./document-detection-result-data-modal.component.scss'],
})
export class DocumentDetectionResultDataModalComponent implements OnInit, OnDestroy {
    protected _documentId$ = new ReplaySubject<number>();
    @Input() set documentId(id: number) {
        this._documentId$.next(id);
    }
    @Input() Editing = false;
    resetFormSub: Subscription;
    private resetForm$ = new Subject<void>();
    protected document$ = this._documentId$.pipe(
        switchMap((id) => this.fileDataService.GetFileById$(id, false, { withDocumentDetectionResult: true })),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    private isFreeze= false;
    protected invoiceDataForm = new FormGroup({
        Total: new FormControl<number>(null),
        SubTotal: new FormControl<number>(null),
        TaxTotal: new FormControl<number>(null),
        InvoiceDate: new FormControl<Moment>(null),
        InvoiceNo: new FormControl<string>(null),
        CustomerNo: new FormControl<string>(null),
        ExternalNumber: new FormControl<string>(null),
        OrderNumber: new FormControl<string>(null),
        ToName: new FormControl<string>(null),
        ToAddress: new FormControl<string>(null),
        FromName: new FormControl<string>(null),
        FromAddress: new FormControl<string>(null),
        Iban: new FormControl<string>(null),
        Bic: new FormControl<string>(null),
        Bank: new FormControl<string>(null),
        UstId: new FormControl<string>(null),
    });
    protected templateData$: Observable<IDetailListTemplateData> = this.document$.pipe(
        map((document) => {
            const invoiceData = document.GetLastVersion().DocumentDetectionData?.Invoice || {};
            return {
                Properties: [
                    {
                        key: 'Rechnungsnummer',
                        formControl: this.invoiceDataForm.controls.InvoiceNo,
                        value: invoiceData.InvoiceNo || '',
                    },
                    {
                        key: 'Rechnungsdatum',
                        formControl: this.invoiceDataForm.controls.InvoiceDate,
                        value: invoiceData.InvoiceDate ? formatDate(GetTimeFromTimestamp(+invoiceData.InvoiceDate), 'mediumDate', 'de-DE') : '',
                        options: {
                            specialInput: {
                                date: true,
                            },
                        },
                    },
                    {
                        key: 'Summe',
                        formControl: this.invoiceDataForm.controls.Total,
                        value: isNotNullOrUndefined(invoiceData.Total) ? formatCurrency(invoiceData.Total, this.locale, getCurrencySymbol(this.defaultCurrencyCode,'narrow'), this.defaultCurrencyCode) : '',
                        options: {
                            suffix: '€',
                            specialInput: {
                                number: true,
                            },
                        },
                    },
                    {
                        key: 'Netto',
                        formControl: this.invoiceDataForm.controls.SubTotal,
                        value: isNotNullOrUndefined(invoiceData.SubTotal) ? formatCurrency(invoiceData.SubTotal, this.locale, getCurrencySymbol(this.defaultCurrencyCode,'narrow'), this.defaultCurrencyCode) : '',
                        options: {
                            suffix: '€',
                            specialInput: {
                                number: true,
                            },
                        },
                    },
                    {
                        key: 'Steuern',
                        formControl: this.invoiceDataForm.controls.TaxTotal,
                        value: isNotNullOrUndefined(invoiceData.TaxTotal) ? formatCurrency(invoiceData.TaxTotal, this.locale, getCurrencySymbol(this.defaultCurrencyCode,'narrow'), this.defaultCurrencyCode) : '',
                        options: {
                            suffix: '€',
                            specialInput: {
                                number: true,
                            },
                        },
                    },
                    {
                        key: 'Kundennummer', //todo labelservice
                        formControl: this.invoiceDataForm.controls.CustomerNo,
                        value: invoiceData.CustomerNo || '',
                    },
                    {
                        key: 'Externe Nummer',
                        formControl: this.invoiceDataForm.controls.ExternalNumber,
                        value: invoiceData.ExternalNumber || '',
                    },
                    {
                        key: 'Bestellnummer',
                        formControl: this.invoiceDataForm.controls.OrderNumber,
                        value: invoiceData.OrderNumber || '',
                    },
                    {
                        key: 'Empfänger Name',
                        formControl: this.invoiceDataForm.controls.ToName,
                        value: invoiceData.ToName || '',
                    },
                    {
                        key: 'Empfänger Adresse',
                        formControl: this.invoiceDataForm.controls.ToAddress,
                        value: invoiceData.ToAddress || '',
                        options: {
                            specialInput: {
                                textArea: { Fill: true },
                            },
                        },
                    },
                    {
                        key: 'Absender Name',
                        formControl: this.invoiceDataForm.controls.FromName,
                        value: invoiceData.FromName || '',
                    },
                    {
                        key: 'Absender Adresse',
                        formControl: this.invoiceDataForm.controls.FromAddress,
                        value: invoiceData.FromAddress || '',
                        options: {
                            specialInput: {
                                textArea: { Fill: true },
                            },
                        },
                    },
                    {
                        key: 'IBAN',
                        formControl: this.invoiceDataForm.controls.Iban,
                        value: invoiceData.Iban || '',
                    },
                    {
                        key: 'BIC',
                        formControl: this.invoiceDataForm.controls.Bic,
                        value: invoiceData.Bic || '',
                    },
                    {
                        key: 'Bank',
                        formControl: this.invoiceDataForm.controls.Bank,
                        value: invoiceData.Bank || '',
                    },
                    {
                        key: 'Umsatzsteueridentifikationsnummer',
                        formControl: this.invoiceDataForm.controls.UstId,
                        value: invoiceData.UstId || '',
                    },
                ],
            };
        }),
    );
    constructor(private fileDataService: FileDataService, private store: Store<State>, @Inject(LOCALE_ID) public locale: string, @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string,) {}
    ngOnInit(): void {
        this.resetFormSub = this.document$
            .pipe(
                switchMap((document) =>
                    this.resetForm$.pipe(
                        startWith(''),
                        map(() => {
                            return document.GetLastVersion().DocumentDetectionData?.Invoice || {};
                        }),
                    ),
                ),
            )
            .subscribe((invoiceData) => {
                if (!this.isFreeze) {
                    this.invoiceDataForm.reset({ ...invoiceData, InvoiceDate: invoiceData.InvoiceDate ? moment(GetTimeFromTimestamp(+invoiceData.InvoiceDate)) : null })
                }
            });
    }
    resetForm() {
        this.resetForm$.next();
    }
    Submit() {
        if (this.invoiceDataForm.valid) {
            firstValueFrom(this.document$).then((doc) => {
                const version = doc.GetLastVersion();
                this.store.dispatch(
                    FilesActionTypes.ModifyVersion({
                        parentDocumentId: version.parentDocumentId,
                        Payload: {
                            Id: version.Id + '',
                            DocumentDetectionData: {
                                Invoice: {
                                    Total: this.invoiceDataForm.value.Total || undefined,
                                    SubTotal: this.invoiceDataForm.value.SubTotal || undefined,
                                    TaxTotal: this.invoiceDataForm.value.TaxTotal || undefined,
                                    InvoiceDate: this.invoiceDataForm.value.InvoiceDate ? GetTimestampFromTime(this.invoiceDataForm.value.InvoiceDate.toDate()).toString() : undefined,
                                    InvoiceNo: this.invoiceDataForm.value.InvoiceNo || undefined,
                                    CustomerNo: this.invoiceDataForm.value.CustomerNo || undefined,
                                    ExternalNumber: this.invoiceDataForm.value.ExternalNumber || undefined,
                                    OrderNumber: this.invoiceDataForm.value.OrderNumber || undefined,
                                    ToName: this.invoiceDataForm.value.ToName || undefined,
                                    ToAddress: this.invoiceDataForm.value.ToAddress || undefined,
                                    FromName: this.invoiceDataForm.value.FromName || undefined,
                                    FromAddress: this.invoiceDataForm.value.FromAddress || undefined,
                                    Iban: this.invoiceDataForm.value.Iban || undefined,
                                    Bic: this.invoiceDataForm.value.Bic || undefined,
                                    Bank: this.invoiceDataForm.value.Bank || undefined,
                                    UstId: this.invoiceDataForm.value.UstId || undefined,
                                },
                            },
                        },
                    }),
                );
                this.Editing = false;
            });
        }
    }

    ngOnDestroy(): void {
        this.resetFormSub?.unsubscribe();
    }

    /**
     * unfollows the state-changes
     */
    freeze() {
        this.isFreeze = true;
    }
    /**
     * follows the state-changes
     */
    unFreeze() {
        this.isFreeze = false;
    }
}
