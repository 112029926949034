<app-dave-list-card
    class="detail-card"
    [Headline]="Headline"
    [HeaderIcon]="HeaderIcon"
    [HeaderIcon2]="HeaderIcon2"
    EmptyText="Keine Abrechnungspositionen vorhanden"
    [Data]="ShownData1"
    [DisplayedCollums]="displayedColumns"
    [TableHeaderCollumsMap]="TableConfig"
    (EntryClicked$)="EntryClicked$.emit($event)"
    [Clickable]="true"
    [DisableSortHeader]="false"
    style="max-height: inherit;"
    [IsExpandable]="IsExpandable"
    [Expanded]="Expanded"
>
    <button app-round-button headerButton matTooltip="Filter" (click)="filter.Open()" [matBadge]="FilterAmount$ | async">
        <fa-icon icon="filter"></fa-icon>
    </button>
    <button app-round-button headerButton (click)="NewClicked$.emit()" *ngIf="NewButton && (PS.Has(PS.Permission.CreateLedgerImports) | async)" matTooltip="Abrechnungsposition hinzufügen">
        <app-double-icon [Icon]="BookingPositionMetaIcon" Icon2="plus"></app-double-icon>
    </button>
</app-dave-list-card>
<app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
