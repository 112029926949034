import { Employee2CommissionType } from '../graphql-types';

export interface EmployeeToCommissionEntityAdditionalData {
    akkord?: {
        percentage?: number;
    };
}

export class EmployeeToCommissionEntity {
    constructor(
        public AdditionalData: EmployeeToCommissionEntityAdditionalData = null,
        public CommissionId: number = null,
        public EmployeeId: number = null,
    ) {}
    public static readonly GqlFields = `
additionalData
commissionId
employeeId
`;
    public Clone(override: EmployeeToCommissionAttributes = {}): EmployeeToCommissionEntity {
        const attr = { ...this, ...override };
        return Object.assign(new EmployeeToCommissionEntity(), attr);
    }
}

export function EmployeeToCommissionEntityFromBackend(res: Employee2CommissionType): EmployeeToCommissionEntity {
    return new EmployeeToCommissionEntity(
        res.additionalData as any,
        res.commissionId,
        res.employeeId,
        );
}

export interface EmployeeToCommissionAttributes {
    AdditionalData?: EmployeeToCommissionEntityAdditionalData;
    CommissionId?: number;
    EmployeeId?: number;
}
