import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    emailConnectionFeatureKey,
    EmailConnectionState,
} from '../reducers/email-connection.reducer';
import { State } from '../index';

export const selectEmailConnections = createFeatureSelector<
    EmailConnectionState
>(emailConnectionFeatureKey);

export const getEmailConnectionsFetched = createSelector(
    selectEmailConnections,
    data => !!data.emailConnections,
);

export const getEmailConnections = createSelector(
    selectEmailConnections,
    data => data.emailConnections,
);

export const getUndeletedEmailConnections = createSelector(
    selectEmailConnections,
    data => data.emailConnections?.filter(ec => !ec.Deleted),
);
