import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, merge, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ErrorPopupService } from '../../error-popup/error-popup.service';
import { isNotNullOrUndefined } from '../../helper/helper';
import { DocumentUserEntity, DocumentUserEntityFromBackend } from "../entities/document-user.entity";
import { FileEntity, FileEntityFromFileBackend, FileTypeFromFileBackend } from '../entities/file.entity';
import { DocumentUserType } from '../graphql-types';
import { retryWithBackoff } from '../helper/http-service.helper';
import { State } from '../State';
import { BaseActionTypes } from '../State/actions/base.actions';
import { FileUserActionTypes } from '../State/actions/file-user';
import { FilesActionTypes } from '../State/actions/files.actions';
import { getToken } from '../State/selectors/base.selectors';
import { getFileById, getFiles, getFilesByFolderId } from '../State/selectors/files.selectors';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class FileDataService implements OnDestroy {
    /**
     *  key is folderId
     *  value is Date of latest updatedAt
     * @private
     */
    private folderCacheMap: Map<number, Date>;
    private pendingRequestsBuffer: Map<string, Observable<FileTypeFromFileBackend[]>>;
    private baseUrl: string;
    private clearSub: Subscription;
    constructor(private http: HttpClient, private store: Store<State>, private gatewayHttpService: HttpService, private actions$: Actions, private errorPopup: ErrorPopupService) {
        this.reset();
        this.clearSub = this.actions$.pipe(ofType(BaseActionTypes.InitStore)).subscribe(() => this.reset());
    }
    ngOnDestroy(): void {
        this.clearSub.unsubscribe();
    }
    private reset() {
        this.pendingRequestsBuffer = new Map<string, Observable<FileTypeFromFileBackend[]>>();
        this.folderCacheMap = new Map<number, Date>();
        this.baseUrl = this.gatewayHttpService.GetUrl('files', 'file');
    }
    private getFilesFromBackend(options: {
        folderId?: number;
        updatedAtSince?: Date;
        documentIds?: number[];
        withEmailInline?: boolean;
        withSubFolder?: boolean;
        withDocumentDetectionResult?: boolean;
    }): Observable<FileTypeFromFileBackend[]> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
        const params: { [key: string]: string } = Object.create(null);

        if (options.folderId != null) {
            // null für die Files die im imaginären Hauptordner liegen, also keine FolderId haben
            params['parent_folder'] = options.folderId ? options.folderId + '' : 'null';
        }
        if (options.updatedAtSince) {
            params['since'] = options.updatedAtSince.toISOString();
        }
        if (options.withDocumentDetectionResult) {
            params['with_document_detection_data'] = 'true';
        }
        if (options.documentIds) {
            params['document_ids'] = options.documentIds.join(',');
        }
        if (options.withEmailInline) {
            params['with_email_inline'] = 'true';
        }
        if (options.withSubFolder) {
            params['with_sub_folder'] = 'true';
        }
        const paramString = `${
            Object.keys(params).length
                ? '?' +
                  Object.keys(params)
                      .map((k) => k + '=' + params[k])
                      .join('&')
                : ''
        }`;
        if (!this.pendingRequestsBuffer.has(paramString)) {
            this.pendingRequestsBuffer.set(
                paramString,
                this.http
                    .get<FileTypeFromFileBackend[]>(this.baseUrl + paramString, {
                        headers,
                    })
                    .pipe(
                        retryWithBackoff(),
                        catchError((err, caught) => {
                            this.errorPopup.OpenErrorPopup();
                            this.store.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: 'Dateien Abrufen fehlgeschlagen',
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            );
                            return of([]);
                        }),
                        take(1),
                        tap(() => this.pendingRequestsBuffer.delete(paramString)),
                        shareReplay({ refCount: true, bufferSize: 1 }),
                    ),
            );
        }
        return this.pendingRequestsBuffer.get(paramString);
    }
    public GetFileById$(id: number, reload = false, config?: { withEmailInline?: boolean; withDocumentDetectionResult?: boolean }): Observable<FileEntity> {
        if (!id) {
            console.error('Id is required');
            return of(null);
        }
        return this.store.select(getFileById({ id })).pipe(
            take(1),
            switchMap((file) => {
                if (file && !reload && !config?.withDocumentDetectionResult) {
                    return this.store.select(getFileById({ id }));
                } else {
                    const req = this.getFilesFromBackend({ documentIds: [id], ...config }).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
                    req.subscribe((files) => (files.length === 1 ? this.handleFileReturn(files) : console.error('File mit Id ' + id + ' konnte nicht abgerufen werden.')));
                    return req.pipe(switchMap(() => this.store.select(getFileById({ id }))));
                }
            }),
        );
    }
    public GetFilesByFolderIdNoCache$(folderId: number, withSubFolder = false): Promise<FileEntity[]> {
        return firstValueFrom(this.getFilesFromBackend({ folderId, withSubFolder })).then((files) => files.map(FileEntityFromFileBackend));
    }

    /**
     * Retrieves files by their ids. only missing ones are polled from server
     *
     * @param {number[]} ids - The array of file ids to retrieve.
     * @param {Object} [config] - Additional configuration options.
     * @param {boolean} [config.withEmailInline] - Specifies whether to include email inline.
     * @returns {Observable<FileEntity[]>} - An observable that emits an array of file entities that match the provided ids.
     */
    public GetFilesById(ids: number[], config?: { withEmailInline: boolean }): Observable<FileEntity[]> {
        return ids?.length
            ? combineLatest(ids.map((id) => this.store.select(getFileById({ id })))).pipe(
                  take(1),
                  switchMap((documents) => {
                      const availableIds = documents.filter(isNotNullOrUndefined).map((d) => d.Id);
                      const missingIds = ids.filter((id) => !availableIds.includes(id));
                      if (!missingIds.length) {
                          return this.store.select(getFiles).pipe(map((files) => files.filter((f) => ids.includes(f.Id))));
                      }
                      const req = this.getFilesFromBackend({ documentIds: missingIds, ...config }).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
                      req.subscribe((files) => this.handleFileReturn(files));
                      return req.pipe(
                          switchMap(() => this.store.select(getFiles)),
                          map((files) => files.filter((f) => ids.includes(f.Id))),
                      );
                  }),
              )
            : of([]);
    }
    public GetFilesFromFolder(folderId: number): Observable<FileEntity[]> {
        if (folderId == null) {
            throw new Error('FolderId is required (can be 0 for root folder)');
        }
        if (this.folderCacheMap.has(folderId)) {
            return this.store.select(getFilesByFolderId({ folderId: folderId || null })).pipe(take(1));
        } else {
            const now = new Date();
            return this.getFilesFromBackend({ folderId })
                .pipe(
                    map((files) => this.handleFileReturn(files, folderId, now)),
                    shareReplay({ refCount: true, bufferSize: 1 }),
                )
                .pipe(take(1));
        }
    }
    public GetFilesStateFromFolder$(folderId: number, reload = true): Observable<FileEntity[]> {
        if (folderId == null) {
            throw new Error('FolderId is required (can be 0 for root folder)');
        }
        if (this.folderCacheMap.has(folderId)) {
            if (reload) {
                const now = new Date();
                this.getFilesFromBackend({ folderId, updatedAtSince: this.folderCacheMap.get(folderId) }).subscribe((files) => this.handleFileReturn(files, folderId, now));
            }
            return this.store.select(getFilesByFolderId({ folderId: folderId || null }));
        } else {
            const now = new Date();
            const req = this.getFilesFromBackend({ folderId }).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
            req.subscribe((files) => this.handleFileReturn(files, folderId, now));
            return req.pipe(switchMap(() => this.store.select(getFilesByFolderId({ folderId: folderId || null }))),
                distinctUntilChanged((a, b) => a.length === b.length && a.every((e, index) => e === b[index])));
        }
    }
    private handleFileReturn(files: FileTypeFromFileBackend[], folderId?: number, timeStamp?: Date) {
        const fileEntities = files.map(FileEntityFromFileBackend);
        if (files) {
            if (folderId) {
                this.folderCacheMap.set(folderId, timeStamp);
            }
            if (files.length) {
                this.store.dispatch(FilesActionTypes.UpdateMany({ Payload: fileEntities }));
            }
        }
        return fileEntities;
    }
    public getUserForDocument(fileId: number): Observable<DocumentUserEntity[]> {
        return merge(
            this.store.select(getToken).pipe(
                switchMap((token) => {
                    let headers = new HttpHeaders();
                    headers = headers.set('Content-Type', 'application/json');
                    headers = headers.set('Authorization', 'Bearer ' + token);

                    return this.http.get<DocumentUserType[]>(this.gatewayHttpService.GetUrl('document-user', 'file') + '?document_id=' + fileId, {
                        headers,
                    });
                }),
                map((res) => res?.map((u) => DocumentUserEntityFromBackend(u))),
            ),
            this.actions$.pipe(
                ofType(FileUserActionTypes.Update),
                filter((p) => p.Payload.documentId === fileId),
                map((p) => p.Payload.users),
            ),
        );
    }
}
