import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeneratedDocumentsResolver } from '../../../../dave-data-module/guards/generatedDocuments.resolver';
import { State } from '../../../../dave-data-module/State';
import { getGeneratedDocuments } from '../../../../dave-data-module/State/selectors/generatedDocuments.selectors';
import { getGeneratedDocumentsTypes } from '../../../../dave-data-module/State/selectors/generatedDocumentsType.selectors';
import { appMatDialogDefaultConfig } from '../../../../helper/helper';
import { ReportsPageMeta } from '../../../../helper/page-metadata';
export type EmailFileUploadReportsComponentDialogData = undefined;
export type EmailFileUploadReportsComponentReturnDialogData = number[] | undefined;
@Component({
    selector: 'app-email-file-upload-reports',
    templateUrl: './email-file-upload-reports.component.html',
    styleUrls: ['./email-file-upload-reports.component.scss'],
})
export class EmailFileUploadReportsComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        maxHeight: '100%',
        maxWidth: '100%',
        minWidth: '80vw',
        height: '90vh',
    };
    public ShowGenFilesList$ = combineLatest([this.store.select(getGeneratedDocuments), this.store.select(getGeneratedDocumentsTypes)]).pipe(
        map(([genFiles, genFilesType]) =>
            genFiles?.map((e) => ({
                ...e,
                GenFilesType: genFilesType?.find((gft) => gft.Id === e.GeneratedDocumentsTypeId),
            })),
        ),
    );
    public SelectedGeneratedFiles: number[] = [];
    public ReportsMeta = ReportsPageMeta;
    constructor(private dialogRef: MatDialogRef<EmailFileUploadReportsComponent, EmailFileUploadReportsComponentReturnDialogData>, private store: Store<State>, generatedDocsResolver: GeneratedDocumentsResolver) {
        generatedDocsResolver.resolve();
    }
    public SelectUnselectGeneratedFile(fileId: number) {
        if (this.SelectedGeneratedFiles.indexOf(fileId) >= 0) {
            this.SelectedGeneratedFiles.splice(this.SelectedGeneratedFiles.indexOf(fileId), 1);
        } else {
            this.SelectedGeneratedFiles.push(fileId);
        }
    }
    CloseAndSave() {
        this.dialogRef.close(this.SelectedGeneratedFiles.slice());
    }
}
