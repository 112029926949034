<mat-expansion-panel *ngIf="Expandable" #panel>
    <mat-expansion-panel-header class="card-headline" style="padding: 0 15px 0 7px;">
        <div class="header-content" >
            <fa-stack matCardAvatar>
                <fa-icon [icon]="ResourcePageMeta.Icon" stackItemSize="1x"></fa-icon>
            </fa-stack>
            <h3 >Geräte ({{ (ResourceSchedules$|async)?.data?.length }})</h3>
        </div>
        <div class="header-button-wrapper">
                <mat-form-field floatLabel="never" [class.invisible]="!searchVisible" (click)="$event.stopPropagation()"
                                style="width: 12rem">
                    <mat-label>Suche</mat-label>
                    <input matInput type="search" [formControl]="searchForm" #searchInput />
                    <button class="clear-search" *ngIf="searchForm.value" mat-icon-button aria-label="Clear"
                            (click)="searchForm.setValue('')">
<!--                        <fa-icon icon="times"></fa-icon>-->
                    </button>
                </mat-form-field>
                <button *ngIf="panel.expanded"
                        style="margin: auto 0 auto auto;"
                        app-round-button
                        (click)="$event.stopPropagation(); toggleSearch()"
                        [matTooltip]="searchVisible ? 'abbrechen' : 'suchen'"
                >
                    <fa-icon [icon]="searchVisible ? 'times' : 'search'"></fa-icon>
                </button>
            <button *ngIf="!dialogRef && !searchVisible" matTooltip="Vollbild" app-round-button
                    (click)="openDialog(); $event.stopPropagation();">
                <fa-icon icon="expand"></fa-icon>
            </button>
        </div>
    </mat-expansion-panel-header>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</mat-expansion-panel>
<div *ngIf="!Expandable">
    <div class="card-headline" style="padding: 0 0.5rem 0 0.5rem ; ">
        <div class="header-content">
            <fa-stack matCardAvatar>
                <fa-icon [icon]="ResourcePageMeta.Icon" stackItemSize="1x"></fa-icon>
            </fa-stack>
            <h3>Geräte
                ({{ (ResourceSchedules$|async)?.data?.length }})</h3>
        </div>
        <div class="header-button-wrapper" *ngIf="Expanded">
                <mat-form-field floatLabel="never" [class.invisible]="!searchVisible" (click)="$event.stopPropagation()"
                                style="width: 15rem ; margin-right: 8px ; height: auto">
                    <mat-label>Suche</mat-label>
                    <input matInput type="search" [formControl]="searchForm" #searchInput />
                    <button class="clear-search" *ngIf="searchForm.value" mat-icon-button aria-label="Clear"
                            (click)="searchForm.setValue('')">
                        <fa-icon icon="times"></fa-icon>
                    </button>
                </mat-form-field>
                <button style="margin: auto 0 auto auto;"
                        app-round-button
                        (click)="$event.stopPropagation(); toggleSearch()"
                        [matTooltip]="searchVisible ? 'abbrechen' : 'suchen'"
                >
                    <fa-icon [icon]="searchVisible ? 'times' : 'search'"></fa-icon>
                </button>
                <button *ngIf="!searchVisible" app-round-button (click)="openNewPopup() ; $event.stopPropagation()"
                        matTooltip="Ressource zuweisen">
                    <app-double-icon [Icon]="ResourcePageMeta.Icon" Icon2="plus"></app-double-icon>
                </button>
        </div>
    </div>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
    <ng-container [ngTemplateOutlet]="footer"></ng-container>
</div>

<ng-template #content>
            <h3 class="table-header">Geräte</h3>
            <div class="planed-resources">
                <table mat-table class="mat-elevation-z2 fixed-header" [dataSource]=" (ResourceSchedules$ | async)">
                    <ng-container matColumnDef="ResourceName">
                        <th mat-header-cell *matHeaderCellDef>Gerät</th>
                        <td mat-cell *matCellDef="let element">{{ element.ResourceName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Amount">
                        <th mat-header-cell *matHeaderCellDef>Anzahl</th>
                        <td mat-cell *matCellDef="let element">{{ element.Amount }}</td>
                    </ng-container>
                    <ng-container matColumnDef="QuantityType">
                        <th mat-header-cell *matHeaderCellDef>Einheit</th>
                        <td mat-cell *matCellDef="let element">{{ element.QuantityType }}</td>
                    </ng-container>
                    <ng-container matColumnDef="TimeSpan">
                        <th mat-header-cell *matHeaderCellDef>Zeitraum</th>
                        <td mat-cell *matCellDef="let element">{{ element.TimeSpan }}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="DisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: DisplayedColumns" (click)="entryClicked(row)"></tr>
                </table>
                <h4 *ngIf="(ResourceSchedules$ | async)?.data?.length === 0" class="no-content">Keine Einträge
                    vorhanden</h4>
            </div>
</ng-template>
<ng-template #footer>
    <mat-dialog-actions class="actions" *ngIf="Expanded">
        <button app-button Inverted mat-dialog-close Color="cancel">schließen</button>
    </mat-dialog-actions>
</ng-template>
