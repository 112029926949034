<mat-nav-list>
    <mat-divider class="top-divider" *ngIf="TopDivider"></mat-divider>
    <a mat-list-item class="home-button" *ngIf="HomeButton" routerLink="/" matTooltip="Dashboard" matTooltipPosition="right">
        <fa-icon icon="home"></fa-icon>
        <span class="ml-1rem" *ngIf="Expanded">Dashboard</span>
    </a>
    <mat-divider *ngIf="HomeButton"></mat-divider>
    <a mat-list-item *ngFor="let item of NavigationListEntries$ | async" [routerLink]="item.Route" [class.is-active]="item.isActive$ | async" [matTooltip]="item.Title" matTooltipPosition="right">
        <div [class]="item.Theme" class="inc-bar-wrapper theme-override" *ngIf="ShowIncBar">
            <div class="dave-inc-bar"></div>
        </div>
        <fa-icon [icon]="item.Image"></fa-icon>
        <span class="ml-1rem" *ngIf="Expanded">{{ item.Title }}</span>
    </a>
    <mat-divider class="bottom-button"></mat-divider>
    <button mat-list-item matTooltip="Ausklappen" matTooltipPosition="right" (click)="Expanded = !Expanded">
        <fa-icon [ngStyle]="{'margin-left': Expanded ? 'auto' : 'unset'}" [icon]="Expanded ? 'chevron-left' : 'chevron-right'"></fa-icon>
    </button>
</mat-nav-list>
