import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { EmailEntity } from '../../../dave-data-module/entities/email.entity';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { MailContentService } from '../../mail-content.service';

@Component({
    selector: 'app-email-view',
    templateUrl: './email-view.component.html',
    styleUrls: ['./email-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailViewComponent {
    public Email$ = new BehaviorSubject(new EmailEntity());
    @Input() set Email(e: EmailEntity) {
        if (e.Id !== this.Email$.getValue()?.Id) {
            this.Email$.next(e);
            if (e.DocumentIds) {
                this.fileDataService.GetFilesById(e.DocumentIds, { withEmailInline: true }).pipe(take(1)).subscribe();
            }
        }
    }
    @ViewChild('MailHtml') MailIFrame: ElementRef<HTMLIFrameElement>;

    constructor(private MailContentService: MailContentService, private fileDataService: FileDataService, private domSanitizer: DomSanitizer) {}
    protected emailHtml$ = this.Email$.pipe(
        switchMap((e) =>
            this.MailContentService.TransformToMail(e, 'file_service_url').pipe(
                map((e) => {
                    let html: string;
                    if (e.html != null) {
                        html = e.html;
                        let regex = /<(a)([^>]+)>/g;
                        // @ts-ignore
                        html = html.replaceAll(regex, (a, b, c: string) => {
                            let d = /target.*?=.*?"(.+?)"/g.exec(c);
                            if (d !== null) {
                                if (d[1] != '_blank') {
                                    return a.replace(c, c.replace(d[1], '_blank'));
                                } else {
                                    return a;
                                }
                            }
                            return a.replace(c, c + ' target="_blank"');
                        });
                    } else {
                        let regex = /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi;

                        // @ts-ignore
                        html = e.Text.replaceAll(regex, (a: string) => {
                            return '<a class="dave-email-inline-link" href="' + a + '" target="_blank">' + a + '</a>';
                        });
                        html = `
<p class="mail-text">${html}</p>
<style>
.mail-text {
    width: 100%;
    white-space: break-spaces;
    margin: 0;
    max-height: 100%;
}
</style>`;
                    }
                    return html;
                    // return this.domSanitizer.bypassSecurityTrustHtml(html);
                }),
                startWith(null),
            ),
        ),
    );
}
