import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { GeneratedDocumentsEntity } from '../dave-data-module/entities/generatedDocuments.entity';
import { GeneratedDocumentsTypeSlugsForDiary } from '../dave-data-module/entities/generatedDocumentsType.entity';
import { GeneratedDocumentsType } from '../dave-data-module/graphql-types';
import { GeneratedDocumentsResolver } from '../dave-data-module/guards/generatedDocuments.resolver';
import { FrontendDate } from '../dave-data-module/helper/backend-frontend-conversion.helper';
import { HttpService } from '../dave-data-module/services/http.service';
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';

@Injectable({
    providedIn: 'root',
})
export class DiaryDataService {
    constructor(private store: Store<State>, private api: HttpService, private generatedDocumentsResolver: GeneratedDocumentsResolver) {}
    generateEvents(typeToGenerate: GeneratedDocumentsTypeSlugsForDiary, eventIds: number[]) {
        return this.generate([typeToGenerate], null, null, null, eventIds);
    }
    generateDiary(typesToGenerate: GeneratedDocumentsTypeSlugsForDiary[], commissionId: number, fromDate: Date, toDate: Date) {
        return this.generate(typesToGenerate, commissionId, fromDate, toDate);
    }
    generate(typesToGenerate?: GeneratedDocumentsTypeSlugsForDiary[], commissionId?: number, fromDate?: Date, toDate?: Date, eventIds?: number[]) {
        const requestNamePrefix = 'createGeneratedDocumentsDiary';

        const query = `mutation($commissionId: Int, $from: DateTime, $to: DateTime, $eventIds: [Int], ${typesToGenerate.map((_, i) => '$generatedDocumentsDiaryTypeSlug' + i + ': GeneratedDocumentsDiaryTypeSlug').join(', ')}){
            ${typesToGenerate.map(
                (_, i) => `${requestNamePrefix + i}: createGeneratedDocumentsDiary(commissionId: $commissionId, eventIds: $eventIds, from: $from, to: $to, generatedDocumentsDiaryTypeSlug: $generatedDocumentsDiaryTypeSlug${i}) {
            ...genDocFields
            }`,
            )}
        }
        fragment genDocFields on GeneratedDocumentsType {
  ${GeneratedDocumentsEntity.GqlFields}
}`;
        const variables = { eventIds, commissionId, from: fromDate && FrontendDate(fromDate), to: toDate && FrontendDate(toDate) };
        typesToGenerate.forEach((generatedDocumentsDiaryTypeSlug, i) => {
            variables['generatedDocumentsDiaryTypeSlug' + i] = generatedDocumentsDiaryTypeSlug;
        });
        return firstValueFrom(this.api.graphQl({ query, variables })).then((res) => {
            if (res && typesToGenerate.every((_, i) => res[requestNamePrefix + i])) {
                // if (count === 1) {
                //     this.router.navigate(['/', GeneratedDocumentsPageMeta.Path, AllReportsMeta.Path, DocumentEditorMeta.Path, res[requestNamePrefix + '0'].id]);
                // } else {
                this.generatedDocumentsResolver.pollUpdated();
                // }
                return typesToGenerate.map<GeneratedDocumentsType>((_, i) => res[requestNamePrefix + i]);
            } else {
                this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'Bericht erstellen fehlgeschlagen' } }));
                return undefined;
            }
        });
    }
}
