import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    Chart,
    ChartDataset,
    ChartOptions,
    ChartType,
    DefaultDataPoint,
    LegendItem,
    PluginOptionsByType,
    registerables,
} from 'chart.js';
import { DeepPartial } from 'chart.js/types/utils';
import * as moment from 'moment/moment';
import { htmlLegendPlugin } from './html-legend-plugin';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const locale = 'de-DE';

export type CustomDoughnutPluginOptions = DeepPartial<PluginOptionsByType<'doughnut'>> & {
    htmlLegend: {
        // ID of the container to put the legend in
        containerRef?: ElementRef<HTMLElement>;
        display: boolean;
        showValue: boolean;
        callbacks?: {
            afterValue?: (ctx: LegendItem) => string;
            value?: (value: number) => string;
        };
        filter?: (ctx: LegendItem) => boolean;
    };
};
export type CustomDoughnutChartOptions = DeepPartial<ChartOptions<'doughnut'>> & { plugins: CustomDoughnutPluginOptions };
@Component({
    selector: 'app-doughnut-chart',
    templateUrl: './doughnut-chart.component.html',
    styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent<TType extends ChartType = 'doughnut', TData = DefaultDataPoint<'doughnut'>, TLabel = unknown> implements AfterViewInit {
    @ViewChild('chart') private canvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('legendContainer') private legendContainer: ElementRef<HTMLDivElement>;
    @Input() set Labels(d: string[]) {
        this.labels = d;
        if (this.Chart) {
            this.Chart.data.labels = d;
            this.Chart.update();
        }
    }
    get Labels() {
        return this.labels;
    }
    @Input() set Datasets(d /*: ChartDataset<'doughnut', TData[]>[]*/) {
        this.datasets = d;
        if (this.Chart) {
            this.Chart.data.datasets = d;
            this.Chart.update();
        }
    }
    get Datasets() {
        return this.datasets;
    }
    @Input() set Options(d: CustomDoughnutChartOptions) {
        this.options = d;
        if (this.Chart) {
            this.Chart.options = this.getOptions(d);
            this.Chart.update();
        }
    }

    get Options() {
        return this.options;
    }
    @Input() CenterIconClickable = false;
    @Input() HideLegend = false;
    @Output() OnCenterIconClick = new EventEmitter()
    @Output() CenterClick = new EventEmitter()

    @Input() CenterTemplate: TemplateRef<any>;
    private options: CustomDoughnutChartOptions;

    public Chart: Chart<'doughnut', TData[], unknown>;
    private datasets: ChartDataset<'doughnut', TData[]>[] = [];
    private labels: string[] = [];

    constructor(public cdr: ChangeDetectorRef) {
        moment.locale(locale);
        Chart.register(...registerables);
        Chart.defaults.font.family = 'Quicksand';
        // Chart.defaults.color = 'white';
        Chart.defaults.locale = locale;
    }
    ngAfterViewInit(): void {
        const ctx = this.canvas.nativeElement.getContext('2d');
        this.Chart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: this.labels,
                datasets: this.datasets,
            },
            options: this.getOptions(this.options),
            plugins: [htmlLegendPlugin],
        });
    }
    private getOptions(options: CustomDoughnutChartOptions): CustomDoughnutChartOptions {
        return {
            cutout: '75%', // steht auch im css
            ...options,
            layout: {
                padding: 8,
                ...options?.layout,
            },
            plugins: {
                ...options?.plugins,
                htmlLegend: this.HideLegend ? null : {
                    // ID of the container to put the legend in
                    containerRef: this.legendContainer,
                    ...(options as any)?.plugins?.htmlLegend,
                },
            },
        };
    }
}
