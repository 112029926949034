import { createAction, props } from '@ngrx/store';
import { DocumentUserEntity } from '../../entities/document-user.entity';
import { DaveMutationCreateDocumentUserArgs, DaveMutationDeleteDocumentUserArgs } from '../../graphql-types';

export const FileUserActionTypes = {
    AddFileUser: createAction('data/Document-user/add', props<{ Payload: DaveMutationCreateDocumentUserArgs }>()),
    DeleteFileUser: createAction('data/Document-user/delete', props<{ Payload: DaveMutationDeleteDocumentUserArgs }>()),
    Update: createAction(
        'data/Document-user/update',
        props<{
            Payload: {
                documentId: number;
                users: DocumentUserEntity[];
            };
        }>(),
    ),
};
