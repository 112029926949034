import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MilestoneEntity } from '../../entities/milestone.entity';
import { MilestoneActionTypes } from '../actions/milestone.actions';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';

export const Milestone_KEY = 'milestone';

export interface MilestoneState extends EntityState<MilestoneEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;
}

const adapter = createEntityAdapter<MilestoneEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const milestonesReducer = createReducer(
    initialState,
    on(MilestoneActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(MilestoneActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(MilestoneActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
    on(MilestoneActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, state)),

);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllMilestone = selectAll;
export const selectMilestoneEntities = selectEntities;

export const selectMilestoneFetched = (state: MilestoneState) => state.fetched;
export const selectMilestoneLatestUpdatedAt = (state: MilestoneState) => state.latestUpdatedAt;
