import { User2CommissionType } from '../graphql-types';
import { FromEntityEnum } from './user-to-customer.entity';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export interface UserToCommissionEntityAdditionalData {
}

export class UserToCommissionEntity {
    constructor(
        public AdditionalData: UserToCommissionEntityAdditionalData = null,
        public CommissionId: number = null,
        public Id: number = null,
        public UserId: number = null,
        public Assignee: boolean = null,
        public FromEntity: FromEntityEnum = null,
        public FromId: number = null,
        public RoleId: number = null,
        public UpdatedAt: Date = null,
    ) {}
    public static readonly GqlFields = `
additionalData
commissionId
id
userId
assignee
fromEntity
fromId
roleId
updatedAt`;
    public Clone(override: UserToCommissionAttributes = {}): UserToCommissionEntity {
        const attr = { ...this, ...override };
        return Object.assign(new UserToCommissionEntity(), attr);
    }
}

export function UserToCommissionEntityFromBackend(res: User2CommissionType): UserToCommissionEntity {
    return new UserToCommissionEntity(
        res.additionalData ? JSON.parse(res.additionalData) : null,
        res.commissionId,
        res.id,
        res.userId,
        res.assignee,
        res.fromEntity,
        res.fromId,
        res.roleId,
        BackendDate(res.updatedAt),
    );
}

export interface UserToCommissionAttributes {
    AdditionalData?: UserToCommissionEntityAdditionalData;
    CommissionId?: number;
    Id?: number;
    UserId?: number;
    Assignee?: boolean;
    FromEntity?: FromEntityEnum;
    FromId?: number;
    RoleId?: number;
    UpdatedAt?: Date;
}
