import { createAction, props } from '@ngrx/store';
import { UploadActionProps } from '../../guards/resolver.helper';
import {
    MaterialGroupTypeAddRequest, MaterialGroupTypeDeleteRequest, MaterialGroupTypeEditRequest,
} from '@dave/types';
import { MaterialGroupEntity } from '../../entities/material-group.entity';
import { GatewayResponseErrorType } from '../../services/app-gateway.service';

const prefix = 'data/' + 'materialGroup/';
export const MaterialGroupActionTypes = {
    Load: createAction(prefix + 'load', props< UploadActionProps >()),
    Change: createAction(prefix + 'change', props<{ Payload: MaterialGroupTypeEditRequest }>()),
    Delete: createAction(prefix + 'delete', props<{ Payload: MaterialGroupTypeDeleteRequest }>()),

    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<MaterialGroupEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<MaterialGroupEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction(prefix + 'updateOne', props<{ Payload: MaterialGroupEntity }>()),
    RemoveOne: createAction(prefix + 'remove', props<{ Payload: number }>()),

    CreateRequest: createAction(prefix + 'create/request', props<{ Payload: MaterialGroupTypeAddRequest }>()),
    CreateSuccess: createAction(prefix + 'create/success', props<{ Payload: MaterialGroupEntity }>()),
    CreateFailure: createAction(prefix + 'create/failure', props<{ Payload: { Errors: GatewayResponseErrorType; ToasterMessage?: string } }>()),
};
