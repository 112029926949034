import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FolderState, FOLDER_FEATURE_KEY, selectAllFolder, selectFolderEntities, selectFolderFetched, selectFolderLatestUpdatedAt } from '../reducers/folder.reducer';
import { FolderEntity, FolderTypes } from '../../entities/folder.entity';

const selectFolder = createFeatureSelector<FolderState>(FOLDER_FEATURE_KEY);
const getFolderEntitys = createSelector(selectFolder, selectFolderEntities);

export const getFolders = createSelector(selectFolder, selectAllFolder);
export const getFoldersDictionary = getFolderEntitys;
export const getFoldersWithoutHidden = createSelector(getFolders, (folders) => folders?.filter((f) => !f.Hidden));
export const getFolderFetched = createSelector(selectFolder, selectFolderFetched);
export const getFolderLatestUpdatedAt = createSelector(selectFolder, selectFolderLatestUpdatedAt);
export const getFolderById = (props: { id: number }) => createSelector(getFolderEntitys, (dictionary) => props?.id && dictionary[props.id]);
export const getFolderByEntity = (props: { type: FolderTypes, entityId: number }) => createSelector(getFolders, (folders) => folders?.find((f) => f.EntityId === props.entityId && f.Type === props.type));
export const getFolderByParentId = (props: { parentId: number }) => createSelector(getFolders, (folders) => folders?.filter((f) => f.ParentId === props.parentId));

export const getFolderByIdWithChildren = (props: { id: number }) => createSelector(getFolderById({id: props.id}), getFolders, (folder, folders) => props?.id && [folder, ...getSubFolders(props.id, folders)]);

export const getSubFolders = (folderId: number, folders: FolderEntity[]): FolderEntity[] => {
    const subFolders = folders.filter((f) => f.ParentId === folderId);
    return subFolders.reduce((prev, curr) => [...prev, ...getSubFolders(curr.Id, folders)], subFolders);
};
