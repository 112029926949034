import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { LicenseResolver } from '../dave-data-module/guards/license.resolver';
import { State } from '../dave-data-module/State';
import { getLicenses } from '../dave-data-module/State/selectors/products.selectors';
import { isNotNullOrUndefined } from '../helper/helper';
import { LicenseSettingsMeta, SettingsPageMeta } from '../helper/page-metadata';

export interface INotification {
    headline: string;
    message: string;
    routingButtonRoute?: string | string[] | null | undefined;
    routingButtonDescription?: string;
    routingButtonTooltip?: string;
    shown: boolean;
}
@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    public Notifications$: Observable<INotification[]>;
    constructor(private store: Store<State>, licenseResolver: LicenseResolver, private datePipe: DatePipe) {
        licenseResolver.resolve();

        this.Notifications$ = combineLatest([store.select(getLicenses).pipe(filter(isNotNullOrUndefined))]).pipe(
            filter(isNotNullOrUndefined),
            map(([licenses]) => {
                const now = new Date();
                const yesterday = new Date(new Date(now).setDate(new Date(now).getDate() - 1));
                const oneWeekAway = new Date();
                oneWeekAway.setDate(oneWeekAway.getDate() + 7);
                const notifications: INotification[] = [];
                licenses
                    .filter(l => l.ValidUntil < oneWeekAway && l.ValidUntil > now)
                    .forEach(l => {
                        if (!this.isAlreadySeen(l.ProductName)) {
                            notifications.push({
                                headline: 'Lizenz Läuft ab',
                                message: l.MaxUserTypeName.toLowerCase().includes('dave : free')
                                    ? `Ihre Testlizenz läuft am ${datePipe.transform(l.ValidUntil)} ab.
                             Klicken Sie auf "zur Lizenzverwaltung" und werden Sie noch heute Teil der Zukunft.`
                                    : `Ihre Lizenz "${l.ProductName}" läuft am ${datePipe.transform(
                                          l.ValidUntil,
                                      )} ab und verlängert sich automatisch um ${
                                          l.TimePeriodInMonth === 1
                                              ? ' einen Monat'
                                              : `${l.TimePeriodInMonth.toString()} Monate`
                                      }.`,
                                routingButtonDescription: 'Zur Lizenzverwaltung',
                                routingButtonRoute: l.MaxUserTypeName.toLowerCase().includes('dave : free')
                                    ? ['/', SettingsPageMeta.Path, LicenseSettingsMeta.Path]
                                    : null,
                                shown: true,
                            });
                            this.setAlreadySeen(l.ProductName, l.ValidUntil);
                        }
                    });
                if (!licenses.find(l => yesterday <= l.ValidUntil)) {
                    notifications.push({
                        shown: true,
                        headline: 'Keine Lizenz erworben',
                        message: `Es konnte keine gültige Lizenz gefunden werden. Klicken Sie auf "zur Lizenzverwaltung", legen Sie dort ein SEPA - Lastschriftmandat an und wählen Sie Ihre bevorzugte Lizenz aus.`,
                        routingButtonDescription: 'Zur Lizenzverwaltung',
                        routingButtonRoute: ['/', SettingsPageMeta.Path, LicenseSettingsMeta.Path],
                    });
                }

                return notifications;
            }),
            shareReplay({refCount: true, bufferSize: 1}),
        );
    }

    private isAlreadySeen(productName: string) {
        let storage = localStorage.getItem(productName);
        if (storage) {
            return true;
        } else {
            return false;
        }
    }

    private setAlreadySeen(productName: string, validUntil: Date) {
        localStorage.setItem(productName, validUntil.toString());
    }
}
