import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { TextTemplateLanguageEntity, TextTemplateLanguageEntityFromBackend } from '../../entities/text-template-language.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { TextTemplateLanguageActionTypes } from '../actions/text-template-language.actions';
import { State } from '../index';

enum ErrorCodes {
    Add = 'Textvorlagen Sprache Hinzufügen fehlgeschlagen',
    Load = 'Textvorlagen Sprache Abrufen fehlgeschlagen',
    Modify = 'Textvorlagen Sprache Bearbeiten fehlgeschlagen',
    Remove = 'Textvorlagen Sprache Löschen fehlgeschlagen',
}

@Injectable()
export class TextTemplateLanguageEffects {

    LoadTextTemplateLanguages = createEffect(() => this.actions$.pipe(
        ofType(TextTemplateLanguageActionTypes.Load),
        withLatestFrom(this.store$),
        switchMap(([{ Payload }, store]) => {
            const queryString = `
query{
    textTemplateLanguages${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''}{
    ${TextTemplateLanguageEntity.GqlFields}
    }
      }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                map(res =>
                res && res.textTemplateLanguages
                        ? Payload?.updatedSince
                            ? BaseActionTypes.ErrorAction({
                                  Payload: { ToasterMessage: 'Textvorlagen nachladen noch nicht implementiert' },
                              })
                            : TextTemplateLanguageActionTypes.UpdateAll({
                                  Payload: (res.textTemplateLanguages || []).map(val => TextTemplateLanguageEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Load },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
