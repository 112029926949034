<mat-card class="list-card">
    <mat-card-header>
        <h3 mat-card-title>JVEG</h3>
        <mat-form-field floatLabel="never" style="margin: 0 1rem">
            <mat-label>Suche </mat-label>
            <input type="text" [formControl]="SearchForm" matInput />
            <button class="clear-search" (click)="SearchForm.setValue('')" mat-icon-button matSuffix>
                <fa-icon [icon]="SearchForm.value ? 'times' : 'search'"></fa-icon>
            </button>
        </mat-form-field>
    </mat-card-header>
    <div>
        <ngx-simplebar class="clickable-list-container">
            <table mat-table [dataSource]="FilteredBookingPositionTemplates$">
                <ng-container *ngFor="let collumName of ARLTemplateAutoColumns" [matColumnDef]="collumName">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ ARLTemplateColumnHeaders ? ARLTemplateColumnHeaders[collumName] : '' }}
                    </th>
                    <td mat-cell *matCellDef="let rowData">
                        {{ rowData[collumName] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Hint">
                    <th mat-header-cell *matHeaderCellDef style="width: 0.1rem;"></th>
                    <td mat-cell *matCellDef="let rowData" style="width: 1rem">
                        <fa-icon *ngIf="rowData.Hint" icon="info-circle" [matTooltip]="rowData.Hint" size="lg">
                        </fa-icon>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="ARLTemplateColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let rowData; columns: ARLTemplateColumns"
                    (click)="SelectedTemplateId$.next(rowData.Id); highlight(rowData)"
                    class="table-item"
                    [class.highlight]="SelectedRowIndex == rowData.Id"
                >
                    >
                </tr>
            </table>

            <table *ngIf="(FilteredBookingPositionTemplates$ | async)?.length === 0" style="width: 100%">
                <tr>
                    <td class="empty-table-text">
                        Keine JVEG Vorlagen vorhanden
                    </td>
                </tr>
            </table>
        </ngx-simplebar>
    </div>
</mat-card>
