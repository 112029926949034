import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includes',
    standalone: true
})
export class IncludesPipe<T = any> implements PipeTransform {
    transform(value: Array<T> | null, ...args: T[]): boolean {
        return value && args.length ? args.every(a => value.includes(a)) : false;
    }
}
