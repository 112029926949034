import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ValidTokenGuard } from '../dave-data-module/guards/valid-token.guard';
import { MaterialPageMeta, NewMaterialPageMeta, NewResourcePageMeta, ResourcePageMeta } from '../helper/page-metadata';
import { ResourceDetailViewComponent } from './components/resource-detail-view/resource-detail-view.component';
import { ResourceListComponent } from './components/resource-list/resource-list.component';
import { MaterialDetailViewComponent } from './components/material-detail-view/material-detail-view.component';
import { MaterialListViewComponent } from './components/material-list-view/material-list-view.component';

const routes: Routes = [
    {
        path: ResourcePageMeta.Path,
        data: {
            breadcrumb: ResourcePageMeta.Breadcrumb,
            breadcrumbIcon: ResourcePageMeta.Icon,
        },
        component: ResourceListComponent,
        canActivate: [ValidTokenGuard],
        canActivateChild: [ValidTokenGuard],
        resolve: {
            ...ResourceListComponent.RequiredResolvers,
        },
        children: [
            {
                path: NewResourcePageMeta.Path,
                data: { breadcrumb: NewResourcePageMeta.Breadcrumb },
                component: ResourceDetailViewComponent,
                resolve: {
                    ...ResourceDetailViewComponent.RequiredResolvers,
                }
            },
            {
                path: ':resourceId',
                data: { breadcrumb: 'resourceId' },
                component: ResourceDetailViewComponent,
                resolve: {
                    ...ResourceDetailViewComponent.RequiredResolvers,
                }
            },
        ],
    },

    {
        path: MaterialPageMeta.Path,
        data: {
            breadcrumb: MaterialPageMeta.Breadcrumb,
            breadcrumbIcon: MaterialPageMeta.Icon,
        },
        component: MaterialListViewComponent,
        canActivate: [ValidTokenGuard],
        canActivateChild: [ValidTokenGuard],
        resolve: {
            ...MaterialListViewComponent.RequiredResolvers,
        },
        children: [
            {
                path: NewMaterialPageMeta.Path,
                data: { breadcrumb: NewMaterialPageMeta.Breadcrumb },
                component: MaterialDetailViewComponent,
                resolve: {
                    ...MaterialDetailViewComponent.RequiredResolvers,
                }
            },
            {
                path: ':materialId',
                data: { breadcrumb: 'materialId' },
                component: MaterialDetailViewComponent,
                resolve: {
                    ...MaterialDetailViewComponent.RequiredResolvers,
                }
            },
        ],
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class ResourceDispoRoutingModule {}
