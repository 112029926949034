import { ResourceToCommissionEntity } from './resource-dispo/resource-to-commission.entity';
import { GetTimeFromTimestamp, MaterialToCommission } from '@dave/types';

export enum WorkTypeEnum {
    FreeWork = 'FreeWork'
}


export class MaterialToCommissionEntity {
    constructor(
        public Id: number = null,
        public Name: string = null,
        public MaterialId: number = null,
        public CommissionId: number = null,
        public Value: number = null,
        public Date: Date = null,
        public QuantityTypeId: number = null,
        public Multiplicator: number = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
        public PartnerId: number = null,
        public UserId: number = null,
        public WorkType: string = null,
    ) {}
    public static EntityName = 'MaterialToCommission';


    public Clone(override: Partial<MaterialToCommissionEntity> = {}): MaterialToCommissionEntity {
        const attr = { ...this, ...override };
        return Object.assign(new MaterialToCommissionEntity(), attr);
    }

}

export function MaterialToCommissionEntityFromBackend(res: MaterialToCommission): MaterialToCommissionEntity {
      const workTypeEnumValue = res.WorkType ? WorkTypeEnum[res.WorkType as keyof typeof WorkTypeEnum] : null;

    return new MaterialToCommissionEntity(
        res.Id && +res.Id,
        res.Name,
        res.MaterialId && +res.MaterialId,
        res.CommissionId && +res.CommissionId,
        res.Value && +res.Value,
        res.Date && GetTimeFromTimestamp(+res.Date),
        res.QuantityTypeId && +res.QuantityTypeId,
        res.Multiplicator && +res.Multiplicator,
        res.CreatedAt && GetTimeFromTimestamp(+res.CreatedAt),
        res.UpdatedAt && GetTimeFromTimestamp(+res.UpdatedAt),
        res.DeletedAt && GetTimeFromTimestamp(+res.DeletedAt),
        res.PartnerId && +res.PartnerId,
        res.UserId && +res.UserId,
        workTypeEnumValue
        );
}



