import { BvCustomNamesType } from "../graphql-types";
import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';

export class BVCustomNameEntity {
    constructor(public Id: number = null, public DeletedAt: Date = null, public Name: string = null, public UpdatedAt: Date = null) {}
    public static readonly GqlFields: Array<keyof BvCustomNamesType> = ['id', 'deletedAt', 'name', 'updatedAt'];
    public Clone(override: Partial<BVCustomNameEntity> = {}): BVCustomNameEntity {
        const attr = { ...this, ...override };
        return Object.assign(new BVCustomNameEntity(), attr);
    }
}
export function BVCustomNameEntityFromBackend(res: BvCustomNamesType): BVCustomNameEntity {
    return new BVCustomNameEntity(res.id, res.deletedAt ? BackendDateTimestamp(res.deletedAt) : null, res.name, res.updatedAt && BackendDateTimestamp(res.updatedAt));
}
