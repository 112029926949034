import { createAction, props } from '@ngrx/store';
import { EmailConnectionEntity } from '../../entities/email-connection.entity';

export const EmailConnectionActionTypes = {
    UpdateEmailConnections: createAction(
        'user/emailConnection/update',
        props<{ Payload: Array<EmailConnectionEntity> }>(),
    ),
    ModifyEmailConnection: createAction(
        'user/emailConnection/modify',
        props<{ Payload: EmailConnectionEntity; Payload2: string }>(),
    ),
    AddEmailConnection: createAction(
        'user/emailConnection/add',
        props<{ Payload: EmailConnectionEntity; Payload2: string }>(),
    ),
    DeleteEmailConnection: createAction(
        'user/emailConnection/delete',
        props<{ Payload: number }>(),
    ),
    TestNewEmail: createAction(
        'user/emailConnection/testNew',
        props<{ Payload: EmailConnectionEntity; Payload2: string }>(),
    ),
};
