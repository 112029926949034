import { createAction, props } from '@ngrx/store';
import { OfficeEntity } from '../../entities/office.entity';
import { DaveMutationChangeOfficeArgs, DaveMutationCreateOfficeArgs, QueryOfficeArgs } from '../../graphql-types';

export const OfficeActionTypes = {
    Load: createAction('data/Office/get', props<{ Payload?: QueryOfficeArgs }>()),
    UpdateAll: createAction('data/Office/updateAll', props<{ Payload: Array<OfficeEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/Office/updateMany', props<{ Payload: Array<OfficeEntity>; updateLatestUpdatedAt?: boolean }>()),
    Modify: createAction('data/Office/modify', props<{ Payload: DaveMutationChangeOfficeArgs }>()),
    Add: createAction('data/Office/add', props<{ Payload: DaveMutationCreateOfficeArgs }>()),
};
