import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import {
    CKEditorDefaultConfig,
    CKEditorDefaultConfigEmailFontFamilyOptions,
    CKEditorDefaultConfigEmailFontSizeOptions,
} from '../helper/ckeditor.helper';

require('../../assets/ck-editor/de.js');
@Component({
    selector: 'app-ck-editor',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './ck-editor.component.html',
    styleUrls: ['./ck-editor.component.scss'],
})
export class CkEditorComponent implements AfterViewInit {
    @Input() CKEditorConfig: EditorConfig = CKEditorDefaultConfig;
    private editorInstance: ClassicEditor;
    @ViewChild('editor') editor: ElementRef<HTMLDivElement>;

    @Input() set Readonly(value) {
        this.readonly = coerceBooleanProperty(value);
        this.setReadonly(this.readonly);
    }
    private readonly = false;
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_Readonly: BooleanInput;
    constructor() {}

    private _content: string;
    @Input() defaultFontSize: CKEditorDefaultConfigEmailFontSizeOptions;
    @Input() defaultFontFamily: CKEditorDefaultConfigEmailFontFamilyOptions;
    @Input() set Content(c: string) {
        if (c !== this._content) {
            this._content = c;
            if (this.editorInstance) {
                this.setReadonly(false);
                this.editorInstance.setData(c);
                this.setReadonly(this.readonly);
            }
        }
    }
    get Content(): string {
        return this._content;
    }
    @Output() ContentChange = new EventEmitter<string>();
    @Output() EditorCreated = new EventEmitter<ClassicEditor>();

    ngAfterViewInit(): void {
        ClassicEditor.create(this.editor.nativeElement, {...this.CKEditorConfig, initialData: this._content ? this._content : this.CKEditorConfig.initialData}).then((editor) => {
            if (this.Content) {
                editor.setData(this.Content);
            }
            this.editorInstance = editor;

            if (this.defaultFontSize) {
                this.setFontSize(this.defaultFontSize)
            }

            if (this.defaultFontFamily) {
                this.setFontFamily(this.defaultFontFamily)
            }
            this.editorInstance.model.document.on('change:data', () => {
                const content = this.editorInstance.getData();
                this._content = content;
                this.ContentChange.emit(content);
            });

            const toolbarElement = this.editorInstance.ui.view.toolbar.element;
            this.editorInstance.on('change:isReadOnly', (evt, propertyName, isReadOnly) => {
                if (isReadOnly) {
                    toolbarElement.style.display = 'none';
                } else {
                    toolbarElement.style.display = 'flex';
                }
            });
            this.setReadonly(this.readonly);
            /**
             * Email-settings-component needs this event
             */
            this.EditorCreated.emit(editor);
        });
    }
    public setFontSize(fontSize: CKEditorDefaultConfigEmailFontSizeOptions) {
        if (this.editorInstance) {
            if (fontSize  === 'default') {
                this.editorInstance.execute('fontSize');
            } else {
                this.editorInstance.execute('fontSize', { value: fontSize } );
            }
        }
    }
    public setFontFamily(fontFamily: CKEditorDefaultConfigEmailFontFamilyOptions) {
        if (this.editorInstance) {
            if (fontFamily === 'default') {
                this.editorInstance.execute('fontFamily');
            } else {
                this.editorInstance.execute('fontFamily', { value: fontFamily } );
            }
        }
    }
    private setReadonly(readonly: boolean) {
        if (readonly !== this.editorInstance?.isReadOnly) {
            if (readonly) {
                this.editorInstance?.enableReadOnlyMode('Readonly-input');
            } else {
                this.editorInstance?.disableReadOnlyMode('Readonly-input');
            }
        }
    }
}
