<nav>
    <ul class="nav-list">
        <div class="scrollbar" style="display: flex" #breadcrumbScrollbar>
            <li class="nav-list-item" *ngFor="let Breadcrumb of Data$ | async; trackBy: TrackByBreadcrumb; let i = index" @breadcrumbAnimation>
                <fa-icon *ngIf="i" icon="chevron-right"></fa-icon>
                <ng-container *ngIf="Breadcrumb.Icon && Breadcrumb.Previous; then iconBreadcrumb; else regularBreadcrumb"></ng-container>

                <ng-template #iconBreadcrumb>
                    <a mat-icon-button class="nav-previous-button" [routerLink]="Breadcrumb.Url">
                        <fa-icon class="nav-previous-icon" [icon]="Breadcrumb.Icon | icon"></fa-icon>
                    </a>
                </ng-template>

                <ng-template #regularBreadcrumb>
                    <a mat-button class="nav-link" [routerLink]="Breadcrumb.Url">
                        <ng-container *ngIf="Breadcrumb.Label$ | async as label; else loading">{{ label }}</ng-container>
                    </a>
                </ng-template>
            </li>
        </div>
    </ul>
</nav>
<ng-template #loading>
    <app-loading [Size]="24"></app-loading>
</ng-template>
