import { COMMA,ENTER,TAB } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy,Component,ElementRef,Input,OnChanges,OnInit,Output,SimpleChanges,ViewChild } from '@angular/core';
import { ReactiveFormsModule,UntypedFormControl } from '@angular/forms';
import { MatAutocomplete,MatAutocompleteModule,MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent,MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BehaviorSubject,exhaustMap,of,Subject,switchMap,takeWhile } from 'rxjs';
import { debounceTime,filter,scan,startWith,tap } from 'rxjs/operators';
import { OptionsScrollDirective } from '../../../directives/options-scroll/options-scroll.directive';

export interface EmailAutocompleteOption {
    DisplayName: string;
    Email: string;
}
@Component({
    selector: 'app-email-form',
    templateUrl: './email-form.component.html',
    styleUrls: ['./email-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MatInputModule, MatChipsModule, FontAwesomeModule, ReactiveFormsModule, MatAutocompleteModule, OptionsScrollDirective],
})
export class EmailFormComponent implements OnInit, OnChanges {
    public SelectedEmails$ = new BehaviorSubject<string[]>([]);
    public SeparatorKeysCodes: number[] = [ENTER, COMMA, TAB];
    public EmailCtrl = new UntypedFormControl();
    public FilteredEmails: Observable<EmailAutocompleteOption[]>;
    public ChipInputAddOnBlur = true;
    private nextPage$ = new Subject<void>();

    @Input() Placeholder = 'E-Mail';
    @Input() AllEmails: EmailAutocompleteOption[] = [];
    @Input() InitialValue: string[];

    @Input() set SelectedEmails(emails: string[]) {
        this.SelectedEmails$.next(emails);
    }
    @Output() SelectedEmailsChange = this.SelectedEmails$;

    @ViewChild('emailInput') private emailInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') private matAutocomplete: MatAutocomplete;

    constructor() {}
    Add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            const val = this.SelectedEmails$.value.slice();
            val.push(value.trim());
            this.SelectedEmails$.next(val);
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.EmailCtrl.setValue(null);
    }

    Remove(value: string): void {
        const index = this.SelectedEmails$.value.indexOf(value);
        if (index >= 0) {
            const emailList = this.SelectedEmails$.value.slice();
            emailList.splice(index, 1);
            this.SelectedEmails$.next(emailList);
        }
    }

    Selected(event: MatAutocompleteSelectedEvent): void {
        const val = this.SelectedEmails$.value.slice();
        val.push(event.option.value);
        this.SelectedEmails$.next(val);
        this.emailInput.nativeElement.value = '';
        this.EmailCtrl.setValue(null);
    }
    displayWith(element: any) {
        return element ? element.name : null;
    }

    onScroll() {
        //Note: This is called multiple times after the scroll has reached the 80% threshold position.
        this.nextPage$.next();
    }
    ngOnInit(): void {
        if (this.InitialValue && this.InitialValue.length > 0) {
            this.SelectedEmails$.next(this.InitialValue);
        }
        // Note: listen for search text changes
        const filter$ = this.EmailCtrl.valueChanges.pipe(
            startWith(''),
            debounceTime(200),
            filter((q) => typeof q === 'string'),
        );

        this.FilteredEmails = filter$.pipe(
            switchMap((filter) => {
                //Note: Reset the page with every new seach text
                let currentPage = 1;
                return this.nextPage$.pipe(
                    startWith(currentPage),
                    // Note: Until the backend responds, ignore NextPage requests.
                    exhaustMap((_) => this.getMailsList(filter, currentPage)),
                    tap(() => currentPage++),
                    /** Note: This is a custom operator because we also need the last emitted value.
                     Note: Stop if there are no more pages, or no results at all for the current search text.
                     */
                    takeWhile((p) => p.length > 0, true),
                    scan((allProducts: any, newProducts: any) => allProducts.concat(newProducts), []),
                );
            }),
        );
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.InitialValue && changes.InitialValue.currentValue !== changes.InitialValue.previousValue) {
            this.SelectedEmails$.next(changes.InitialValue.currentValue);
        }
    }
    getMailsList(startsWith: string, page: number): Observable<EmailAutocompleteOption[]> {
        const take = 10;
        const skip = page > 0 ? (page - 1) * take : 0;
        const filtered = this.AllEmails?.filter(
            (f) =>
                f.DisplayName?.replace(',', '').replace(/\s/g, '').toLowerCase().includes(startsWith.toLowerCase()) ||
                f.DisplayName?.replace(',', '').toLowerCase().includes(startsWith.toLowerCase()) ||
                f.Email?.toLowerCase().includes(startsWith.toLowerCase()),
        );
        return of(filtered.slice(skip, skip + take));
    }
}
