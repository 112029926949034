import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { TextTemplatesActionTypes } from '../State/actions/textTemplates.actions';
import { getTextTemplatesFetched } from '../State/selectors/textTemplates.selector';
import { BaseEntityResolver } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class TextTemplatesResolver extends BaseEntityResolver {
    constructor(store: Store<State>) {
        super(getTextTemplatesFetched, TextTemplatesActionTypes.Load(), store);
    }
}
