import { LicenseType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export class LicenseEntity {
    constructor(
        public Id: number = null,
        public MaxUserTypeId: number = null,
        public PartnerId: number = null,
        public ProductId: number = null,
        public MaxUserTypeName: string = null,
        public ProductName: string = null,
        public MaxUser: number = null,
        public MinUser: number = null,
        public ActiveUser: number = null,
        public PricePerUser: number = null,
        public MonthlyCost: number = null,
        public BasePrice: number = null,
        public Cancelled: boolean = null,
        public DiscountInPercent: number = null,
        public LicenseOrderId: number = null,
        public TimePeriodInMonth: number = null,
        public ValidUntil: Date = null,
    ) {}

    public Clone(override: LicenseEntityAttributes = {}): LicenseEntity {
        const attr = { ...this, ...override };
        return Object.assign(new LicenseEntity(), attr);
    }
}

export function LicenseEntityFromBackend(res: LicenseType): LicenseEntity {
    return new LicenseEntity(
        res.id,
        res.maxUserTypeId,
        res.partnerId,
        res.productId,
        res.maxUserTypeName,
        res.productName,
        res.maxUser,
        res.minUser,
        res.activeUser,
        res.pricePerUser,
        res.monthlyCost,
        res.basePrice,
        res.cancelled,
        res.discountInPercent,
        res.licenseOrderId,
        res.timePeriodInMonth,
        BackendDate(res.validUntil),
    );
}

export interface LicenseEntityAttributes {
    Id?: number;
    MaxUserTypeId?: number;
    PartnerId?: number;
    ProductId?: number;
    MaxUserTypeName?: string;
    ProductName?: string;
    MaxUser?: number;
    MinUser?: number;
    ActiveUser?: number;
    PricePerUser?: number;
    MonthlyCost?: number;
    BasePrice?: number;
    Cancelled?: boolean;
    DiscountInPercent?: number;
    LicenseOrderId?: number;
    TimePeriodInMonth?: number;
    ValidUntil?: Date;
}

export enum ProductNames {
    Partner = 'c2go : Partnerverwaltung',
    Accounting = 'c2go: Rechnungsverwaltung',
    Video = 'c2go : Videodokumentation',
    Commission = 'c2go : Auftragsverwaltung',
    Basis = 'c2go',
}
