<div style="height: 40px; width: 40px">
    <button mat-icon-button *ngIf="expandable"><fa-icon [icon]="expanded ? 'chevron-down' : 'chevron-right'"></fa-icon></button>
</div>

<span>
    <!--    <fa-icon [icon]="'folder'"></fa-icon>-->
    <!--    {{ Node.name }}-->
    <ng-content></ng-content>
</span>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
