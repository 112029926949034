import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { DaveActions } from "../actions/actions";
import { HttpService } from "../../services/http.service";
import { BaseActionTypes } from '../actions/base.actions';
import { catchError, withLatestFrom, switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { State } from "../index";
import { Store } from '@ngrx/store';
import { EmailSettingsTypeEntityFromBackend } from '../../entities/emailSettingsType.entity';
import { EmailSettingsTypeActionTypes } from '../actions/emailsettingstype.actions';


enum ErrorCodes {
    Load = "E-Mail - Prozesstypen Abrufen fehlgeschlagen",
    Modify = "E-Mail - Prozesstypen Bearbeiten fehlgeschlagen"
  }

@Injectable()
export class EmailSettingsTypeEffects {
    constructor( private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService){

    }

  LoadEmailSettingsTypes = createEffect(() => this.actions$.pipe(
    ofType(EmailSettingsTypeActionTypes.GetEmailSettingsTypeRequest),
    withLatestFrom(this.store$),
    switchMap(([action, store]) => {
      const queryString = `
    query{
      emailSettingsType{
        description
        id
        name
        partnerId
      }
    }`;
      return this.gatewayHttpService
        .graphQl({ query: queryString }, { token: store.base.token, retry: true })
        .pipe(
          map(res =>
            res && res.emailSettingsType
              ? EmailSettingsTypeActionTypes.UpdateEmailSettingsType({
                  Payload: res.emailSettingsType.map(val =>
                    EmailSettingsTypeEntityFromBackend(val)
                  )
                })
              : BaseActionTypes.ErrorAction({
                  Payload: { ToasterMessage: ErrorCodes.Load }
                })
          ),
          catchError((err, caught) =>
            of(
              BaseActionTypes.ErrorAction({
                Payload: {
                  ToasterMessage: ErrorCodes.Load,
                  Err: err,
                  Caught: caught
                }
              })
            )
          )
        );
    })
  ));
}
