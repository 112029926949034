<h1 mat-dialog-title>Erfasste Zeit speichern</h1>
<mat-dialog-content>
    <h4 *ngIf="!Dialogdata.SelectDate && Dialogdata.Type === ClockInTypes.End">Der Arbeitstag wird beendet</h4>
    <h4 *ngIf="Dialogdata.CommissionId && (OtherClockInsExist$ | async)"><mat-error>Es existieren Stempelzeiten zu diesem Zeitraum für diesen Mitarbeiter</mat-error></h4>
    <form [formGroup]="NewClockinForm" (ngSubmit)="Submit()">
        <app-select-search-legacy
            *ngIf="Dialogdata.ShowEmployeeList && (UserList$ | async)"
            class="hover-form"
            [Values]="UserList$ | async"
            [ValuesAreSorted]="true"
            [Placeholder]="'Mitarbeiter'"
            [Control]="NewClockinForm.controls.EmployeeId"
            [Required]="true"
        ></app-select-search-legacy>

        <app-datepicker
            *ngIf="Dialogdata.SelectDate"
            class="hover-form"
            [Max]="MaxDate"
            [Key]="ShowEnd ? 'Startdatum' : 'Datum'"
            [Value]="NewClockinForm.controls.StartDate.value"
            (Input)="NewClockinForm.controls.StartDate.setValue($event.value)"
            [Required]="true"
            [Disabled]="NewClockinForm.controls.StartDate.disabled"
        ></app-datepicker>

        <mat-form-field class="hover-form full-width" *ngIf="Dialogdata.SelectDate">
            <input class="hide-icon-from-browser" matInput type="time" [placeholder]="ShowEnd ? 'Startzeit' : 'Zeit'" [formControl]="NewClockinForm.controls.StartDateTime" required #startTimeInput />
            <fa-icon (click)="OpenHTMLInputPicker(startTimeInput)" icon="clock" matSuffix class="pointer"></fa-icon>
        </mat-form-field>

        <mat-slide-toggle [(ngModel)]="ShowEnd" style="margin-bottom: 0.5rem" [ngModelOptions]="{ standalone: true }" *ngIf="Dialogdata.SelectDateEnd">setze Endzeit</mat-slide-toggle>

        <!--    <mat-form-field class="full-width" *ngIf="!ShowEnd && Dialogdata.SelectDate">-->
        <!--&lt;!&ndash;        <mat-select [formControl]="NewClockinForm.controls.Type" placeholder="Typ">&ndash;&gt;-->
        <!--&lt;!&ndash;            <mat-option [value]="ClockInTypes.Start">Start</mat-option>&ndash;&gt;-->
        <!--&lt;!&ndash;            <mat-option [value]="ClockInTypes.End">Stop</mat-option>&ndash;&gt;-->
        <!--&lt;!&ndash;        </mat-select>&ndash;&gt;-->

        <!--    </mat-form-field>-->
        <div class="full-width" style="margin-bottom: 0.5rem">
            <mat-radio-group class="radio-group" *ngIf="!ShowEnd && Dialogdata.SelectDate" [formControl]="NewClockinForm.controls.Type">
                <mat-radio-button color="primary" [value]="ClockInTypes.Start">Start</mat-radio-button>
                <mat-radio-button color="primary" [value]="ClockInTypes.End">Stop</mat-radio-button>
            </mat-radio-group>
        </div>
        <mat-form-field class="full-width" *ngIf="Dialogdata.SelectDate">
            <mat-select [formControl]="NewClockinForm.controls.ClockInTypeId" placeholder="Art">
                <mat-option *ngFor="let type of ClockInTypes$ | async" [value]="type.Id">{{ type.Name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <app-datepicker
            class="hover-form"
            *ngIf="Dialogdata.SelectDateEnd && ShowEnd"
            Key="Enddatum"
            [Min]="NewClockinForm.value.StartDate"
            [Value]="NewClockinForm.controls.EndDate.value"
            (Input)="NewClockinForm.controls.EndDate.setValue($event.value)"
            [Required]="true"
            [Disabled]="NewClockinForm.controls.EndDate.disabled"
        ></app-datepicker>

        <mat-form-field class="hover-form full-width" *ngIf="Dialogdata.SelectDateEnd && ShowEnd">
            <input class="hide-icon-from-browser" matInput type="time" placeholder="Endzeit" [formControl]="NewClockinForm.controls.EndDateTime" required #endTimeInput />
            <fa-icon (click)="OpenHTMLInputPicker(endTimeInput)" icon="clock" matSuffix class="pointer"></fa-icon>
        </mat-form-field>
        <mat-error *ngIf="IsOnHoliday$ | async">Der Mitarbeiter ist in diesem Zeitraum abwesend</mat-error>
        <mat-form-field class="hover-form full-width" *ngIf="PS.Has(PS.Permission.GetCommission) | async">
            <mat-label>{{cls.getSingle$('Commission') | async}} (optional)</mat-label>
            <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer" (click)="NewClockinForm.controls.CommissionId?.setValue(null); $event.stopPropagation()"></fa-icon>
            <app-select-search
                [formControl]="NewClockinForm.controls.CommissionId"
                [Options]="Commissions$ | async"
                [CompareOptions]="CompareSelectSearchCommissionOptions"
                [SearchFunction]="SelectSearchCommissionOptionsFunction"
            ></app-select-search>
        </mat-form-field>
        <!--    <app-select-search-legacy-->
        <!--        *ngIf="PS.Has(PS.Permission.GetCommission) | async"-->
        <!--        class="hover-form"-->
        <!--        [Values]="Commissions$ | async"-->
        <!--        [ValuesAreSorted]="true"-->
        <!--        [Placeholder]="'Auftrag (optional)'"-->
        <!--        [Control]="NewClockinForm.controls.CommissionId"-->
        <!--    ></app-select-search-legacy>-->

        <app-textarea Key="Notiz (optional)" [Value]="NewClockinForm.controls.Note"></app-textarea>

        <!-- <button
        type="button"
        app-button
        class="button"
        *ngIf="!Dialogdata.New"
        class="delete-button"
        (click)="deleteClockIn()"
    >
        Löschen
    </button> -->
    </form></mat-dialog-content
>
<div mat-dialog-actions class="buttons" [class.multiple]="Dialogdata.Close">
    <button Inverted type="button" [IsLoading]="LS.IsLoading$ | async" app-button class="button" mat-dialog-close="" *ngIf="Dialogdata.Close" Color="cancel">Abbrechen</button>
    <button app-round-button Color="red" type="button" [IsLoading]="LS.IsLoading$ | async" *ngIf="!Dialogdata.New && !Dialogdata?.Dashboard" (click)="deleteClockIn()" matTooltip="Stempelzeit löschen">
        <fa-icon icon="trash"></fa-icon>
    </button>
    <button app-button type="button" [IsLoading]="LS.IsLoading$ | async" [Disabled]="NewClockinForm.invalid" (click)="Submit()">Speichern</button>
</div>
