<div class="taskboard-header" *ngIf="Dialogdata.showSortButton">
    <fa-icon icon="clipboard-check" class="task-icon"></fa-icon>
    <button app-round-button matTooltip="Sortieren" aria-label="Sortieren" style="margin: 0.5rem" [matMenuTriggerFor]="sortMenu">
        <fa-icon icon="sort"></fa-icon>
    </button>
</div>
<mat-menu #sortMenu >
    <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'endDate', direction: 'asc'}"><fa-icon icon="chevron-up"></fa-icon> Frist</button>
    <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'endDate', direction: 'dsc'}"><fa-icon icon="chevron-down"></fa-icon> Frist</button>
    <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'priority', direction: 'asc'}"><fa-icon icon="chevron-up"></fa-icon> Priorität</button>
    <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'priority', direction: 'dsc'}"><fa-icon icon="chevron-down"></fa-icon> Priorität</button>
</mat-menu>
<app-tasks-board [Filter]="Dialogdata?.filter" [Sort]="TaskBoardSort" (FileClicked)="OpenFile($event)"></app-tasks-board>
