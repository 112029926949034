import { createReducer, on } from '@ngrx/store';
import { FolderEntity } from '../../entities/folder.entity';
import { FolderActionTypes } from '../actions/folder.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';

export const FOLDER_FEATURE_KEY = 'folder';

export interface FolderState extends EntityState<FolderEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}
const adapter = createEntityAdapter<FolderEntity>({
    selectId: Entity => Entity.Id,
});
const initialState: FolderState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const folderReducer = createReducer(
    initialState,
    on(FolderActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(FolderActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(FolderActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(FolderActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, { ...state })),

);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllFolder = selectAll;
export const selectFolderEntities = selectEntities;
export const selectFolderFetched = (state: FolderState) => state.fetched;
export const selectFolderLatestUpdatedAt = (state: FolderState) => state.latestUpdatedAt;
