import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { getToken } from '../selectors/base.selectors';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { BaseActionTypes } from '../actions/base.actions';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { State } from '../index';
import { PublicFileActionTypes } from '../actions/public-file.actions';
import { PublicFileEntityFromBackend, PublicFileType } from '../../entities/public-file.entity';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileEntityFromFileBackend, FileTypeFromFileBackend } from '../../entities/file.entity';
import { retryWithBackoff } from '../../helper/http-service.helper';
import { FilesActionTypes } from '../actions/files.actions';

enum ErrorCodes {
    Add = 'Datei Hinzufügen fehlgeschlagen',
    Load = 'Datei Abrufen fehlgeschlagen',
    Modify = 'Datei Bearbeiten fehlgeschlagen',
    Remove = 'Datei Löschen fehlgeschlagen',
}

@Injectable()
export class PublicFileEffects {

    LoadPublicFiles = createEffect(() => this.actions$.pipe(
        ofType(PublicFileActionTypes.Load),
        withLatestFrom(this.store$.select(getToken)),
        switchMap(([{Payload}, token]) => {
            let headers = new HttpHeaders();
            headers = headers.set('Content-Type', 'application/json');
            headers = headers.set('Authorization', 'Bearer ' + token);
            let params = [];
            if (Payload.updatedSince) {
                params.push('since='+Payload.updatedSince);
            }
            if (Payload.deleted != null) {
                params.push('deleted='+Payload.deleted);
            }
            return this.http.get<PublicFileType[]>(this.gatewayHttpService.GetUrl('public-files', 'file') + (params.length ? ('?' + params.join('?')) : ''), {
                headers
            }).pipe(
                retryWithBackoff(),
                map(res =>
                    res
                        ? Payload?.updatedSince
                            ? PublicFileActionTypes.UpdateMany({
                                  Payload: res.map(val => PublicFileEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                            : PublicFileActionTypes.UpdateAll({
                                  Payload: res.map(val => PublicFileEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Load },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    Upload$ = createEffect(() => this.actions$.pipe(
        ofType(PublicFileActionTypes.Upload),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {

            let headers = new HttpHeaders();
            headers = headers.set('Authorization', 'Bearer ' + store.base.token);
            const payload = new FormData();
            action.Payload.params.forEach((param, key) => {
                payload.append(key, param);
            });
            payload.append('file', action.Payload.file);

            return this.http.put<PublicFileType>(this.gatewayHttpService.GetUrl('public-files', 'file'), payload, {
                headers,
            }).pipe(
                map(res => PublicFileActionTypes.UpdateOne({Payload: PublicFileEntityFromBackend(res)})),
                catchError((err, caught) => {
                        return of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        )
                    },
                ),
            );
        }),
    ));

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService,
                private http: HttpClient,) {}
}
