
<h2 mat-dialog-title><fa-icon style="margin-right: .5rem" [icon]="DMSMeta.Icon"></fa-icon>Anhänge aus dem DMS hinzufügen</h2>
    <mat-dialog-content>
        <span *ngIf="DialogData.moveWarning">{{DialogData.moveWarning}}</span>
        <!-- <div class="mobile-scollbar"> -->
        <mat-tab-group *ngIf="Mobile$ | async" mat-align-tabs="center">
            <mat-tab label="DMS">
                <ng-container *ngTemplateOutlet="fileExplorer">

                </ng-container>
            </mat-tab>
            <mat-tab [label]="'Auswahl (' + (SelectedFiles.value.length) + ')'">
                <ng-container *ngTemplateOutlet="selectedFiles">

                </ng-container>
            </mat-tab>
        </mat-tab-group>
        <div class="flex-row" *ngIf="!(Mobile$ | async)">
            <ng-container *ngTemplateOutlet="fileExplorer">
            </ng-container>

            <mat-card class="selected-files-card">
                <ng-container *ngTemplateOutlet="selectedFiles">
                </ng-container>
            </mat-card>
        </div>
        <!-- </div> -->
    </mat-dialog-content>
    <mat-dialog-actions>
        <button Color="cancel" Inverted app-button matDialogClose>Abbrechen</button>
        <button Color="green" app-button [matDialogClose]="{ documents: SelectedFiles.value }">Speichern</button>
    </mat-dialog-actions>
<mat-card *ngIf="PreviewedFileId$ | async" class="preview">
    <button app-round-button class="back-button" (click)="PreviewedFileId$.next(null)">
        <fa-icon icon="chevron-right"></fa-icon>
    </button>
    <app-preview style="z-index: 1" [FileId$]="PreviewedFileId$" (ClosePreview)="PreviewedFileId$.next(null)"> </app-preview>
</mat-card>

<ng-template #fileExplorer>
    <app-file-explorer
        PreventFileClick
        PreventFileUploadClick
        (FileClicked)="SelectUnselectFile($event)"
        class="detail-card mat-card file-explorer-card"
        [SelectedFolderId]="DialogData?.folderId"
        [DisableRoutingParams]="true"
    >
    </app-file-explorer>
</ng-template>

<ng-template #selectedFiles>
    <div class="selected-files-wrapper">
        <h2>Bereits ausgewählt</h2>
        <span *ngIf="DialogData.maxFiles">Maximal {{this.DialogData.maxFiles === 1 ? 'eine Datei' : (DialogData.maxFiles + ' Dateien')}} auswählen</span>
        <ngx-simplebar>
            <div style="display: flex; flex-wrap: wrap;">
                <div *ngFor="let file of ShownDMSFiles$ | async" style="position: relative; margin: .5rem">
                    <fa-icon icon="check" size="2x" class="selected-file-icon"></fa-icon>
                    <app-small-file-card (FileClick)="openPreview(file.Id)" RemoveButton (RemoveClick)="SelectUnselectFile(file.Id)" [FileId]="file.Id"></app-small-file-card>
                </div>
            </div>
        </ngx-simplebar>
    </div>
</ng-template>
