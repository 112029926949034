import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
    EmailSettingsTypeState,
  emailSettingsTypeFeatureKey
} from "../reducers/emailsettingstype.reducer";
import { State } from "../index";

export const selectEmailSettingsTypes = createFeatureSelector<
  EmailSettingsTypeState
>(emailSettingsTypeFeatureKey);
export const getEmailSettingsTypes = createSelector(
  selectEmailSettingsTypes,
  data => data.emailSettingsTypes
);
export const getEmailSettingsTypesFetched = createSelector(
  selectEmailSettingsTypes,
  data => !!data.emailSettingsTypes
);
export const getSingleEmailSettingsType = createSelector(
  getEmailSettingsTypes,
  (emailSettingsType: ReturnType<typeof getEmailSettingsTypes>, Id: number) =>
    emailSettingsType ? emailSettingsType.find(e => e.Id === Id) : null
);
