import { GetTimeFromTimestamp, ProcessPropertyConfig, ProcessStepTemplate } from '@dave/types';
import { ProcessTemplate } from '@dave/types/dist/proto/process/process';

export class ProcessTemplateEntity {
    constructor(
        public Id: number = null,
        public PartnerId: number = null,
        public Name: string = null,
        /** number in days  */
        public EndDate: number = null,
        public Steps: ProcessStepTemplate[] = null,
        public DocumentIds: number[] = null,
        public Description: string = null,
        public Description2: string = null,
        public CustomFields: string = null,
        public PropertyConfig: ProcessPropertyConfig[] = null, // erstmal nur für die steps
        public AutoUpdateSteps: boolean = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
    ) {}
    public static EntityName = 'Prozessvorlage';

    public get DisplayName(): string {
        return this.Name;
    }
    public Clone(override: Partial<ProcessTemplateEntity> = {}): ProcessTemplateEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ProcessTemplateEntity(), attr);
    }
}
export function ProcessTemplateEntityFromBackend(res: ProcessTemplate): ProcessTemplateEntity {
    return new ProcessTemplateEntity(
        res.Id && +res.Id,
        res.PartnerId && +res.PartnerId,
        res.Name,
        res.EndDate && +res.EndDate,
        res.Steps,
        res.DocumentIds && res.DocumentIds.map((id) => +id),
        res.Description,
        res.Description2,
        res.CustomFields,
        res.PropertyConfig,
        res.AutoUpdateSteps,
        res.CreatedAt && GetTimeFromTimestamp(+res.CreatedAt),
        res.UpdatedAt && GetTimeFromTimestamp(+res.UpdatedAt),
        null, //res.DeletedAt && GetTimeFromTimestamp(+res.DeletedAt),
    );
}
