import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../../index';
import {
    AKKORD_KEY,
    AkkordState,
    selectAkkordEntities,
    selectAkkordFetched,
    selectAllAkkord,
} from '../../reducers/akkord/akkord.reducer';


const selectState = createFeatureSelector< AkkordState>(AKKORD_KEY);
const selectEntities = createSelector(selectState, selectAkkordEntities);

export const getAkkords = createSelector(selectState, selectAllAkkord);
export const getAkkordFetched = createSelector(selectState, selectAkkordFetched);
export const getAkkordById = createSelector(
    selectEntities,
    (akkord: ReturnType<typeof selectEntities>, Id: number) => Id && akkord[Id],
);
