import { ChartOfAccountType } from '../graphql-types';
import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';


export enum AccountTypeEnum {
    Contra = 'CONTRA',
    Normal = 'NORMAL'
}

export class ChartOfAccountEntity {
    constructor(
        public Id: number = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public Description: string = null,
        public Name: string = null,
        public Number: number = null,
        public PartnerId: number = null,
        public UpdatedAt: Date = null,
        public UserId: number = null,
        public AccountType: AccountTypeEnum = null,
    ) {}

    public static readonly GqlFields: Array<keyof ChartOfAccountType> = [
        'createdAt',
        'deletedAt',
        'description',
        'id',
        'name',
        'number',
        'partnerId',
        'updatedAt',
        'userId',
        'accountType'
    ];

    public Clone(override: Partial<ChartOfAccountEntity> = {}): ChartOfAccountEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ChartOfAccountEntity(), attr);
    }
}

export function ChartOfAccountEntityFromBackend(res: ChartOfAccountType): ChartOfAccountEntity {
    if (res.number) {
        console.warn('number ', res.number);
    }
    return new ChartOfAccountEntity(
        res.id,
        res.createdAt ? BackendDateTimestamp(res.createdAt) : null,
        res.deletedAt ? BackendDateTimestamp(res.deletedAt) : null,
        res.description,
        res.name,
        res.number,
        res.partnerId,
        res.updatedAt ? BackendDateTimestamp(res.updatedAt) : null,
        res.userId,
        res.accountType,
    );
}
