import { createAction, props } from '@ngrx/store';
import { UserToEventEntity } from '../../entities/user-to-event.entity';
import { DaveMutationChangeUser2EventArgs, DaveMutationCreateUser2EventIndividuallyArgs, DaveMutationDeleteUser2EventArgs, QueryUser2EventArgs } from '../../graphql-types';

export const UserToEventActionTypes = {
    Load: createAction('data/userToEvent/load', props<{ Payload?: QueryUser2EventArgs }>()),
    UpdateAll: createAction('data/userToEvent/updateAll', props<{ Payload: Array<UserToEventEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/userToEvent/updateMany', props<{ Payload: Array<UserToEventEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction('data/userToEvent/updateOne', props<{ Payload: UserToEventEntity }>()),
    RemoveOne: createAction('data/userToEvent/removeOne', props<{ Payload: number }>()),
    Change: createAction('data/userToEvent/modify', props<{ Payload: DaveMutationChangeUser2EventArgs }>()),
    Create: createAction('data/userToEvent/create', props<{ Payload: DaveMutationCreateUser2EventIndividuallyArgs }>()),
    Delete: createAction('data/userToEvent/delete', props<{ Payload: DaveMutationDeleteUser2EventArgs }>()),
};
