import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import {
    CustomToolbarItemModel,
    DocumentEditorContainerComponent,
    DocumentEditorKeyDownEventArgs, DocumentEditorSettings,
    PageFitType,
    ToolbarService
} from "@syncfusion/ej2-angular-documenteditor";
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { FormatType } from '@syncfusion/ej2-documenteditor/src/document-editor';
import { ContainerContentChangeEventArgs } from '@syncfusion/ej2-documenteditor/src/document-editor/base';
import { ToolbarItem } from '@syncfusion/ej2-documenteditor/src/document-editor/base/types';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, firstValueFrom, from, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, map, skip, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { LoadingService } from '../../../services/loading.service';

// must be included in styles.scss
export const CustomFonts = [ 'Century Gothic' ];
export enum CustomToolbarItemIds {
    CustomWordExport = 'CustomWordExport',
    CustomPrint = 'CustomPrint',
    CustomDelete = 'CustomDelete',
    CustomPfdExport = 'CustomPfdExport',
    CustomShare = 'CustomShare',
    CustomSign = 'CustomSign',
}

const SignatureTemplate = (base64: string, width: number, height: number) => ({
    styles: [],
    sections: [
        {
            blocks: [
                {
                    paragraphFormat: {
                        styleName: 'Normal',
                        listFormat: {},
                    },
                    characterFormat: {},
                    inlines: [
                        {
                            characterFormat: {},
                            imageString: base64,
                            width: 120,
                            height: (120 / width) * height,
                            isMetaFile: false,
                            isCompressed: false,
                            iscrop: false,
                            name: '',
                            alternativeText: '',
                            title: '',
                            verticalPosition: 0,
                            horizontalPosition: 0,
                            textWrappingStyle: 'Inline',
                        },
                    ],
                },
            ],
            headersFooters: {},
            sectionFormat: {},
            customStyles: {},
        },
    ],
});
export interface IToolbarOverrideFunction {
    toolbarItemId: string;
    function: (args: ClickEventArgs) => void;
}
@Component({
    selector: 'app-document-editor',
    templateUrl: './document-editor.component.html',
    styleUrls: ['./document-editor.component.scss'],
    // encapsulation: ViewEncapsulation.None,
    providers: [ToolbarService],
})
export class DocumentEditorComponent implements AfterViewInit, OnDestroy {
    @Input() Filename = 'Dokument';
    @Input() RestrictEditing = false;
    @Input() EnableToolbar = true;
    @Input() EnableAutoFocus = true;
    /** @deprecated breaks date forms from not optimized sfdt in version 21.1.41 */
    @Input() EnableOptimization = false;
    @Input() ToolbarOverrideFunctions: IToolbarOverrideFunction[] = null;
    @Input() DefaultPageFit: PageFitType = 'FitOnePage';
    @Output() OnCreated = new EventEmitter<void>();
    protected initialized = false;
    protected onCreated = new EventEmitter<number>();
    @Output() ContentChange = new EventEmitter<string>();
    @Output() ShareButtonClick = new EventEmitter<void>();
    @Output() DeleteButtonClick = new EventEmitter<void>();
    @Output() StrgS = new EventEmitter<void>();
    @ViewChild('container', { static: false })
    public DocumentEditorContainer: DocumentEditorContainerComponent;
    @ViewChild('fileUpload')
    public fileUpload: ElementRef<HTMLInputElement>;
    private change$: Observable<ContainerContentChangeEventArgs>;
    public ServiceUrl = this.api.GetUrl('DocumentEditor/', 'sfdt');
    public AuthHeader$ = this.store.select(getToken).pipe(map((token) => [{ Authorization: 'Bearer ' + token }]));
    public ExportWord$ = new Subject<void>();
    private subscriptions: Subscription[] = [];
    private static wordExportButton: CustomToolbarItemModel = {
        prefixIcon: 'e-custom-icons e-word-export',
        tooltipText: 'Export Word',
        text: 'Word',
        id: CustomToolbarItemIds.CustomWordExport,
    };
    private static printButton: CustomToolbarItemModel = {
        prefixIcon: 'e-custom-icons e-print',
        tooltipText: 'Drucken',
        text: 'Drucken',
        id: CustomToolbarItemIds.CustomPrint,
    };
    private static deleteButton: CustomToolbarItemModel = {
        prefixIcon: 'e-custom-icons e-delete',
        tooltipText: 'Löschen',
        text: 'Löschen',
        id: CustomToolbarItemIds.CustomDelete,
    };
    private static pdfExportButton: CustomToolbarItemModel = {
        prefixIcon: 'e-custom-icons e-pdf-export',
        tooltipText: 'Export PDF',
        text: 'Pdf',
        id: CustomToolbarItemIds.CustomPfdExport,
    };
    private static shareButton: CustomToolbarItemModel = {
        prefixIcon: 'e-custom-icons e-share',
        tooltipText: 'Email Senden',
        text: 'Senden',
        id: CustomToolbarItemIds.CustomShare,
    };
    private static signeButton: CustomToolbarItemModel = {
        prefixIcon: 'fa-custom-icons fa-signature',
        tooltipText: 'Unterschrift einfügen',
        text: 'Unterschrift',
        id: CustomToolbarItemIds.CustomSign,
    };
    public static DefaultToolbarItems: (CustomToolbarItemModel | ToolbarItem)[] = [
        // 'RestrictEditing',
        DocumentEditorComponent.shareButton,
        DocumentEditorComponent.pdfExportButton,
        DocumentEditorComponent.wordExportButton,
        DocumentEditorComponent.printButton,
        'Separator',
        'Open',
        DocumentEditorComponent.deleteButton,
        'Separator',
        'Undo',
        'Redo',
        'Separator',
        DocumentEditorComponent.signeButton,
        'Image',
        'Table',
        'Hyperlink',
        'Bookmark',
        'Comments',
        'TableOfContents',
        'Separator',
        'Header',
        'Footer',
        'PageSetup',
        'PageNumber',
        'Break',
        'Separator',
        'Find',
    ];
    @Input() ToolbarItems: (CustomToolbarItemModel | ToolbarItem)[] = [...DocumentEditorComponent.DefaultToolbarItems];
    public OnToolbarClick(args: ClickEventArgs): void {
        const customFunction = this.ToolbarOverrideFunctions?.find((cf) => cf.toolbarItemId === args.item.id);
        if (customFunction) {
            customFunction.function(args);
        } else {
            switch (args.item.id) {
                case 'CustomWordExport':
                    this.ExportWord$.next();
                    break;
                case 'CustomPrint':
                    this.Print();
                    break;
                case 'CustomPfdExport':
                    console.error('CustomPfdExport default action not implemented');
                    break;
                case 'CustomShare':
                    this.ShareButtonClick.emit();
                    break;
                case 'CustomDelete':
                    this.DeleteButtonClick.emit();
                    break;
                case 'CustomSign':
                    this.SigneButtonClick();
                    break;
            }
        }
    }
    editorVisible$ = new BehaviorSubject(true);
    constructor(private store: Store<State>, private api: HttpService, private apiToasterService: ToastrService, private fileDataService: FileDataService, private ls: LoadingService) {
        // fontLoader.resolve()
        this.subscriptions.push(
            this.ExportWord$.pipe(
                withLatestFrom(this.store.select(getToken)),
                tap(([, token]) => {
                    this.ls.startLoading('Export/sfdt/docx');
                    const documentData = {
                        fileName: (this.Filename || 'Unbenannt') + '.docx', // wird nicht genutzt
                        documentData: this.DocumentEditorContainer.documentEditor.serialize(),
                    };
                    const httpRequest = new XMLHttpRequest();
                    httpRequest.open('Post', this.ServiceUrl + 'Export/sfdt/docx', true);
                    httpRequest.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                    httpRequest.setRequestHeader('Authorization', 'Bearer ' + token);
                    httpRequest.responseType = 'blob';
                    httpRequest.onreadystatechange = () => {
                        if (httpRequest.readyState === 4) {
                            this.ls.endLoading('Export/sfdt/docx');
                            if (httpRequest.status === 200 || httpRequest.status === 304) {
                                saveAs(httpRequest.response, (this.Filename || 'Unbenannt') + '.docx');
                            }
                        }
                    };
                    httpRequest.send(JSON.stringify(documentData));
                }),
            ).subscribe(),
        );
    }
    public resize() {
        this.DocumentEditorContainer.resize()
    }

    destroyAndReloadEditor() {
        this.editorVisible$.next(false);
        setTimeout(() => {
            this.editorVisible$.next(true);
        }, 1);
    }
    public setMobileContentEditable(canEdit: boolean) {
        const element = document.querySelector(`div[id=${this.DocumentEditorContainer.element.id}_editor_editableDiv]`);
        element?.setAttribute('contentEditable', canEdit.toString());
    }
    Open(file: string) {
        if (this.DocumentEditorContainer.documentEditor) {
            try {
                this.DocumentEditorContainer.documentEditor.open(file);
            } catch (e) {
                console.log('open failed, try to repair editor');
                try {
                    JSON.parse(file);
                    this.destroyAndReloadEditor();
                    firstValueFrom(this.onCreated).then((v) => {
                        try {
                            console.log('this.DocumentEditorContainer.documentEditor.documentHelper', this.DocumentEditorContainer.documentEditor.documentHelper)
                            console.log('this.DocumentEditorContainer.documentEditor', this.DocumentEditorContainer.documentEditor)
                            this.DocumentEditorContainer.documentEditor.open(file);
                        } catch (e) {
                            console.error('unable to open file in editor, JSON is valid but open fails', { e, file });
                            this.apiToasterService.error('Dokument öffnen fehlgeschlagen');
                            firstValueFrom(this.store.select(getUser)).then((user) => {
                                Sentry.captureException(e, {
                                    user: {
                                        id: user?.Id + '',
                                        ip_address: '',
                                        email: user?.Email,
                                        username: user?.DisplayName,
                                        userEntity: user,
                                    },
                                    level: 'error',
                                    contexts: {
                                        auth: { Bearer: localStorage.getItem('token') },
                                    },
                                    tags: {
                                        Syncfusion: 'DocumentEditor',
                                    },
                                    extra: {
                                        sfdt: file,
                                    },
                                });
                            });
                        }
                    });
                } catch (e) {
                    console.error('unable to open file in editor, no valid JSON', { e, file });
                    this.apiToasterService.error('Dokument öffnen fehlgeschlagen');
                }
            }
            if (this.DefaultPageFit) {
                this.DocumentEditorContainer.documentEditor.fitPage(this.DefaultPageFit);
            }
        } else {
            console.error('unable to open file in editor, this.DocumentEditorContainer.documentEditor is falsy');
            this.apiToasterService.error('Dokument öffnen fehlgeschlagen');
        }
    }

    Save(name?: string, format?: FormatType) {
        this.DocumentEditorContainer.documentEditor.save(name || 'Dokument', format || 'Docx');
    }

    Print() {
        this.DocumentEditorContainer.documentEditor.print();
    }
    public SigneButtonClick() {
        this.store
            .select(getUser)
            .pipe(
                take(1),
                switchMap((user) => this.fileDataService.GetFileById$(user.DocumentId)),
                take(1),
                filter((file) => file && !file.DeletedAt),
                withLatestFrom(this.store.select(getToken)),
                switchMap(([file, token]) =>
                    this.api.download(file.GetLastVersion(), { token }).pipe(
                        map((blob) => new Blob([blob], { type: file.MIMEType })),
                        switchMap((a) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(a);
                            return from(
                                new Promise((resolve) => {
                                    reader.onloadend = () => {
                                        resolve(reader.result as string);
                                    };
                                }),
                            );
                        }),
                    ),
                ),
                take(1),
            )
            .subscribe((base64: string) => {
                const i = new Image();
                i.src = base64;
                i.onload = () => {
                    this.InsertSFDT(JSON.stringify(SignatureTemplate(base64, i.width, i.height)));
                };
            });
    }

    /**
     * Pastes provided sfdt content or the data present in local clipboard if any .
     * @param sfdt? insert the specified sfdt content at current position
     */
    InsertSFDT = (sfdt?: string) => {
        try {
            this.DocumentEditorContainer.documentEditor.editor.paste(sfdt);
        } catch (e) {
            this.apiToasterService.error('einfügen fehlgeschlagen');
            console.log(e);
            console.log('content einfügen fehlgeschlagen');
            console.log(JSON.parse(sfdt));
            console.log(sfdt);
        }
    };

    public OnKeyDown(args: DocumentEditorKeyDownEventArgs) {
        const keyCode: number = args.event.which || args.event.keyCode;
        const isCtrlKey: boolean = args.event.ctrlKey || args.event.metaKey ? true : keyCode === 17;
        // 83 is the character code for 'S'
        if (isCtrlKey && keyCode === 83) {
            // this.Save();
            args.event.preventDefault();
            this.StrgS.emit();
            args.isHandled = true;
        }
    }

    ngAfterViewInit(): void {
        if (this.DocumentEditorContainer) {
            this.change$ = of(this.DocumentEditorContainer.contentChange);

            this.subscriptions.push(
                this.DocumentEditorContainer.created.subscribe(() => {
                    this.DocumentEditorContainer.showPropertiesPane = this.EnableToolbar;
                    this.DocumentEditorContainer.documentEditor.addEventListener('keyDown', (e) => this.OnKeyDown(e));
                }),
            );
        }
        setTimeout(() => {
            const element: HTMLElement = document.getElementsByClassName('e-de-showhide-btn')[0] as HTMLElement;
            element?.click();
        }, 1000);
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.ExportWord$.complete();
        this.DocumentEditorContainer?.documentEditor?.destroy();
        this.DocumentEditorContainer?.destroy();
    }
    public OpenFileUpload() {
        this.fileUpload?.nativeElement.click();
    }
    public fileUploadChange(e: any) {
        if (e.target.files[0]) {
            //Get the selected file.
            let file = e.target.files[0];
            let fileReader: FileReader = new FileReader();
            fileReader.onload = (e: any) => {
                let contents: string = e.target.result;
                if (file.name.substr(file.name.lastIndexOf('.')) === '.sfdt') {
                    //Open the sfdt document in Document Editor.
                    try {
                        this.DocumentEditorContainer.documentEditor.open(contents);
                    } catch (error) {
                        this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'Fehler beim Laden der Datei' } }));
                    }
                } else {
                    this.loadFile(file);
                }
            };
            //Read the input file.
            fileReader.readAsText(file);
        }
    }
    public loadFile(file: File): void {
        firstValueFrom(this.store.select(getToken)).then((token) => {
            let ajax: XMLHttpRequest = new XMLHttpRequest();
            ajax.open('POST', this.ServiceUrl + 'Import', true);
            ajax.setRequestHeader('Authorization', 'Bearer ' + token);
            ajax.onreadystatechange = () => {
                if (ajax.readyState === 4) {
                    if (ajax.status === 200 || ajax.status === 304) {
                        // open SFDT text in document editor
                        try {
                            JSON.parse(ajax.responseText);
                            this.DocumentEditorContainer.documentEditor.open(ajax.responseText);
                        } catch (error) {
                            this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'Fehler beim Laden der Datei' } }));
                        }
                    } else {
                        this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'Fehler beim Laden der Datei' } }));
                    }
                }
            };
            let formData: FormData = new FormData();
            formData.append('files', file);
            ajax.send(formData);
        });
    }

    editorCreated() {
        if (!this.initialized) {
            this.addCustomFonts();
            this.OnCreated.emit();
            this.initialized = true;
        }
        this.onCreated.emit();
    }
    addCustomFonts() {
        const missing = CustomFonts.filter(f => !this.DocumentEditorContainer.documentEditorSettings.fontFamilies.includes(f));
        if (missing.length) {
            this.DocumentEditorContainer.documentEditorSettings.fontFamilies.push(...missing);
            this.DocumentEditorContainer.documentEditorSettings.fontFamilies.sort();
            this.DocumentEditorContainer.refresh();
        }
    }
}
