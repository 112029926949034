import { NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { SimplebarAngularModule } from 'simplebar-angular';
import { AddReportButtonComponent } from '../../add-report-button/add-report-button.component';
import { AddressFormComponent } from '../../address-form/address-form.component';
import { BurgerButtonWrapperComponent } from '../../burger-button-wrapper/burger-button-wrapper.component';
import { TextareaModule } from '../../components/templates/textarea/textarea.module';
import { CustomFormsModule } from '../../custom-forms-module/custom-forms.module';
import { DaveDoubleIconModule } from '../../dave-double-icon/dave-double-icon.module';
import { DaveEventListEntryModule } from '../../dave-event-list-entry/dave-event-list-entry.module';
import { DaveLoadingModule } from '../../dave-loading/dave-loading.module';
import { FilenameInputComponent } from "../../filename-input/filename-input.component";
import { MatColorPickerToggleComponent } from '../../mat-color-picker-toggle/mat-color-picker-toggle.component';
import { MatColorPreviewComponent } from '../../mat-color-preview/mat-color-preview.component';
import { PersonListEntryComponent } from '../../person-list-entry/person-list-entry.component';
import { AppButtonModule } from '../app-button-module/app-button.module';
import { AppFilterModule } from '../app-filter-module/app-filter.module';
import { IconPipeModule } from '../icon-pipe/icon-pipe.module';
import { SelectSearchModule } from '../select-search/select-search.module';
import {
DetailListTemplateComponent,
ProfileTemplateDetailDialogComponent
} from './components/detail-views/detail-list-template/detail-list-template.component';
import { DetailViewButtonsDirective } from './components/detail-views/detail-view-template/detail-view-buttons.directive';
import { DetailViewLeftContentDirective } from './components/detail-views/detail-view-template/detail-view-left-content.directive';
import { DetailViewRightContentDirective } from './components/detail-views/detail-view-template/detail-view-right-content.directive';
import { DetailViewTemplateComponent } from './components/detail-views/detail-view-template/detail-view-template.component';
import { DetailViewTopContentDirective } from './components/detail-views/detail-view-template/detail-view-top-content.directive';
import { ProfileTemplateComponent } from './components/detail-views/profile-template/profile-template.component';
import { InfoDialogComponent } from './components/dialogs/info-dialog/info-dialog.component';
import { SupportDialogComponent } from './components/dialogs/support-dialog/support-dialog.component';
import { HistoryBookmarksComponent } from './components/history-bookmarks/history-bookmarks.component';
import { ChipsComponent } from './components/templates/chips/chips.component';
import { DatepickerComponent } from './components/templates/datepicker/datepicker.component';
import { IconSelectDialogComponent } from './components/templates/event-types-selector-card/icon-select-dialog/icon-select-dialog.component';
import { ImageUploadComponent } from './components/templates/image-upload/image-upload.component';
import { ListLayoutWithRoutingTabDirective } from './components/templates/list-layout-with-routing/list-layout-with-routing-tab.directive';
import { ListLayoutWithRoutingComponent } from './components/templates/list-layout-with-routing/list-layout-with-routing.component';
import { NumberfieldComponent } from './components/templates/numberfield/numberfield.component';
import { RatingComponent } from './components/templates/rating/rating.component';
import { TextfieldComponent } from './components/templates/textfield/textfield.component';
import { ClickableMatRowDirective } from './directives/clickable-mat-row.directive';
import { PipesModule } from './pipes/pipes.module';
import { BreakpointObserverService } from './services/breakpoint-observer.service';
import { CustomerNameService } from './services/customer-name.service';

@NgModule({
    declarations: [
        ClickableMatRowDirective,
        ListLayoutWithRoutingComponent,
        HistoryBookmarksComponent,
        ListLayoutWithRoutingTabDirective,
        DatepickerComponent,
        RatingComponent,
        ImageUploadComponent,
        TextfieldComponent,
        DetailViewTemplateComponent,
        ProfileTemplateComponent,
        DetailViewLeftContentDirective,
        DetailViewTopContentDirective,
        DetailViewRightContentDirective,
        DetailListTemplateComponent,
        ProfileTemplateDetailDialogComponent,
        NumberfieldComponent,
        DetailViewButtonsDirective,
        IconSelectDialogComponent,
        ChipsComponent,
        InfoDialogComponent,
        SupportDialogComponent,
    ],
    imports: [
        IconPipeModule,
        MatCheckboxModule,
        CommonModule,
        MatTabsModule,
        RouterModule,
        MatDatepickerModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        FontAwesomeModule,
        MatTooltipModule,
        SimplebarAngularModule,
        MatTableModule,
        MatDividerModule,
        MatListModule,
        PipesModule,
        MatSortModule,
        MatInputModule,
        MatButtonModule,
        AppButtonModule,
        MatSelectModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        SelectSearchModule,
        MatSlideToggleModule,
        DaveDoubleIconModule,
        MatSidenavModule,
        AppFilterModule,
        TextareaModule,
        ScrollingModule,
        TableVirtualScrollModule,
        // TableVirtualScrollModule,
        MatMenuModule,
        DaveLoadingModule,
        MatExpansionModule,
        DaveEventListEntryModule,
        BurgerButtonWrapperComponent,
        MatBadgeModule,
        CustomFormsModule,
        AddReportButtonComponent,
        NgClickOutsideDirective,
        NgxMatColorPickerModule,
        MatColorPreviewComponent,
        MatColorPickerToggleComponent,
        PersonListEntryComponent,
        FilenameInputComponent,
        AddressFormComponent,
        FormsModule,
    ],
    exports: [
        ListLayoutWithRoutingComponent,
        HistoryBookmarksComponent,
        ListLayoutWithRoutingTabDirective,
        DatepickerComponent,
        RatingComponent,
        ImageUploadComponent,
        TextfieldComponent,
        DetailViewTemplateComponent,
        ProfileTemplateComponent,
        DetailViewLeftContentDirective,
        DetailViewRightContentDirective,
        DetailListTemplateComponent,
        NumberfieldComponent,
        DetailViewButtonsDirective,
        ClickableMatRowDirective,
        IconSelectDialogComponent,
        ChipsComponent,
        InfoDialogComponent,
        DetailViewTopContentDirective,
    ],
    providers: [CustomerNameService, BreakpointObserverService],
})
export class DaveSharedComponentsModule {}
