import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { CommonModule } from '@angular/common';
import { Component,ElementRef,HostBinding,Input,OnDestroy,Optional,Self } from "@angular/core";
import { ControlValueAccessor,FormControl,NgControl,ReactiveFormsModule } from "@angular/forms";
import { MatFormField,MatFormFieldControl } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Subject,Subscription } from "rxjs";
import { map } from "rxjs/operators";

const fileNameRegex = new RegExp('(.*)\\.([^\\.]+$)');
function splitFileName(fileName: string) {
    const matches = fileName.match(fileNameRegex);
    return matches ? [matches[1], '.' + matches[2]] : [fileName, null];
}
@Component({
    selector: 'app-filename-input',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatInputModule, FontAwesomeModule, MatTooltipModule],
    templateUrl: './filename-input.component.html',
    styleUrls: ['./filename-input.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: FilenameInputComponent }],
})
export class FilenameInputComponent implements OnDestroy, ControlValueAccessor, MatFormFieldControl<string> {
    nameForm = new FormControl<string>(null);
    fileNameSuffix: string = null;
    controlType = 'filename-input';
    editFileNameSuffix = false;
    static nextId = 0;
    stateChanges = new Subject<void>();

    focused = false;
    touched = false;

    onTouched = () => {};
    subs: Subscription[] = [];

    @HostBinding() id = `app-filename-input-${FilenameInputComponent.nextId++}`;
    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }
    private _placeholder: string;

    @Input()
    get value(): string | null {
        if (this.nameForm.value !== null || this.fileNameSuffix !== null) {
            return (this.nameForm.value || '') + (this.fileNameSuffix || '');
        }
        return null;
    }
    set value(v: string | null) {
        if (this.editFileNameSuffix || !v) {
            this.nameForm.setValue(v);
            this.fileNameSuffix = null;
        } else {
            const t = splitFileName(v);
            this.nameForm.setValue(t[0]);
            this.fileNameSuffix = t[1];
        }
        this.stateChanges.next();
    }
    constructor(@Optional() @Self() public ngControl: NgControl, @Optional() public parentFormField: MatFormField, private _elementRef: ElementRef<HTMLElement>) {
        // Replace the provider from above with this.
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }
    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }
    get empty() {
        return !this.nameForm.value && !this.fileNameSuffix;
    }
    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.nameForm.disable() : this.nameForm.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    get errorState(): boolean {
        return this.nameForm.invalid && this.touched;
    }
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('aria-describedby') userAriaDescribedBy: string;
    setDescribedByIds(ids: string[]) {
        const controlElement = this._elementRef.nativeElement.querySelector('.app-filename-input-container')!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            this._elementRef.nativeElement.querySelector('input').focus();
        }
    }
    writeValue(tel: string | null): void {
        this.value = tel;
    }

    registerOnChange(fn: any): void {
        const sub = this.nameForm.valueChanges.pipe(map(() => this.value)).subscribe(fn);
        this.subs.push(sub);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // setDisabledState(isDisabled: boolean): void {
    //     this.disabled = isDisabled;
    // }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this.subs.forEach((s) => s.unsubscribe());
    }

    onMakeSuffixEditable() {
        this.editFileNameSuffix = true;
        this.nameForm.setValue(this.value);
        this.fileNameSuffix = null;
    }
}
