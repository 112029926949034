import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip, startWith, tap } from "rxjs/operators";
import { SearchResultEntity, SearchTypeEnum } from '../../dave-data-module/entities/search-result.entity';
import { State } from '../../dave-data-module/State';
import { ProductName, SearchQueriesDebounceTime } from '../../helper/helper';
import { SearchService } from '../../services/search.service';
import {readableStreamLikeToAsyncGenerator} from "rxjs/internal/util/isReadableStreamLike";
import { getPartner } from '../../dave-data-module/State/selectors/partners.selectors';
import { PartnerTypeEnum } from '../../dave-data-module/entities/partner.entity';

const MIN_SEARCH_QUERY_LENGTH = 3;
@Component({
    selector: 'app-search-bar',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
    @Input()
    Focus$: BehaviorSubject<boolean>;
    @Input()
    Reset$: BehaviorSubject<boolean>;
    @Input()
    visible: boolean;
    @Input()
    set SearchTypes(value: SearchTypeEnum[]){
        if (!this.SearchTypesSet) {
            this.SearchTypesSet = true
        } else {
            this.isSearching = true;
        }
        this.SearchTypes$.next(value);
    }
    get SearchTypes(): SearchTypeEnum[]{
        return this.SearchTypes$.getValue();
    }
    @Output()
    Results: BehaviorSubject<SearchResultEntity> = new BehaviorSubject<SearchResultEntity>(null);
    @Output()
    Count: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    @Output()
    DefocusEvent = new EventEmitter<void>();
    @ViewChild('searchQuery')
    searchInput: ElementRef<HTMLInputElement>;
    public ProductName$ = this.store.select(getPartner).pipe(map(partner => partner?.PartnerTypeId === PartnerTypeEnum.YustInfra ? 'yust infra' : ProductName));
    protected isSearching = false;
    public Search: Subject<string> = new Subject<string>();
    public SearchTypes$: BehaviorSubject<SearchTypeEnum[]> = new BehaviorSubject<SearchTypeEnum[]>([]);
    protected SearchTypesSet = false;
    private subs: Subscription[] = [];

    public MIN_SEARCH_QUERY_LENGTH = MIN_SEARCH_QUERY_LENGTH
    constructor(private store: Store<State>, private searchService: SearchService) {
        this.subs.push(
            combineLatest([
                this.Search.pipe(
                    map(query => query.length < MIN_SEARCH_QUERY_LENGTH ? '' : query),
                    tap((query) => {
                        if (query) {
                            this.isSearching = true
                        }
                    }),
                    debounceTime(SearchQueriesDebounceTime),
                    distinctUntilChanged(),
                ),
                this.SearchTypes$,
            ]).subscribe(([query, types]) => {
                if(types.length == 0) {
                    types = Object.values(SearchTypeEnum);
                }
                if (query) {
                    this.searchService.Search(query, types).subscribe(res => {
                        this.Results.next(res);
                        this.isSearching = false;
                    });
                } else {
                    this.Results.next(new SearchResultEntity([], [], 0));
                    this.isSearching = false;
                }
            }),
        );
    }

    ngOnInit(): void {
        if (this.Focus$) {
            this.subs.push(
                this.Focus$.subscribe(focus => {
                    if (focus) {
                        setTimeout(() => this.searchInput.nativeElement.focus());
                    }
                }),
            );
        }
    }
    Defocus() {
        if (this.Focus$) {
            this.Focus$.next(false);
        }
        this.DefocusEvent.emit();
    }
    Reset() {
        this.searchInput.nativeElement.value = null;
        this.Results.next(new SearchResultEntity([], [], 0));
    }
    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }
}
