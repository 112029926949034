import { createEntityAdapter,EntityState } from '@ngrx/entity';
import { createReducer,on } from '@ngrx/store';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { SalaryTypeActions } from "../actions/salary-type.actions";
import { SalaryTypeEntity } from "../../entities/salary-type.entity";

export const SALARY_TYPE_FEATURE_KEY = 'salaryType';

export interface SalaryTypeState extends EntityState<SalaryTypeEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<SalaryTypeEntity>({
    selectId: Entity => Entity.Id,
});

const initialState: SalaryTypeState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const salaryTypeReducer = createReducer(
    initialState,
    on(SalaryTypeActions.updateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, {
            ...state,
            fetched: true,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(SalaryTypeActions.updateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(SalaryTypeActions.updateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
    on(SalaryTypeActions.removeOne, (state, { Payload }) => adapter.removeOne(Payload, state)),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllSalaryType = selectAll;
export const selectSalaryTypeEntities = selectEntities;

export const selectSalaryTypeFetched = (state: SalaryTypeState) => state.fetched;
export const selectSalaryTypeLatestUpdatedAt = (state: SalaryTypeState) => state.latestUpdatedAt;
