<div class="header" [class.header-colorful]="HeaderColorful">
    <fa-icon class="icon" icon="calendar"></fa-icon>
    <h3 class="headline">{{ cls.getSingle$('RessourcePlan') | async }}</h3>
    <a *ngIf="ShowShiftplanButton" app-round-button class="header-button" Inverted [matTooltip]="ShiftPlanMeta.Breadcrumb" [routerLink]="['../', ShiftPlanMeta.Path]">
        <fa-icon [icon]="PageMeta.Icon"></fa-icon>
    </a>
    <button
        *ngIf="ShowAddResourceButton"
        app-round-button
        class="header-button"
        Inverted
        matTooltip="Automatisch {{ cls.getMultiple$('RessourcePlan') | async }} anlegen"
        (click)="AddResourceButtonClick.emit()"
    >
        <fa-icon icon="bars-staggered"></fa-icon>
    </button>
    <button
        app-round-button
        class="header-button"
        Inverted
        [matBadge]="FilterAmount$ | async"
        [matBadgeHidden]="!(FilterAmount$ | async)"
        matTooltip="Filter"
        [matBadgeOverlap]="true"
        (click)="filter.Open()"
    >
        <fa-icon icon="filter"></fa-icon>
    </button>
    <button app-round-button class="header-button" *ngFor="let button of AdditionalButtons" Inverted (click)="button.onClick($event)">
        <fa-icon [icon]="button.icon" [transform]="button.iconTransform" [matTooltip]="button.tooltip"></fa-icon>
    </button>
</div>
<div style="height: 0; flex-grow: 1; position: relative; display: flex; flex-direction: column;">
    <button app-round-button class="hide-left-row-button" (click)="LS.resourcePlanCollapsed = !LS.resourcePlanCollapsed"><fa-icon [icon]="LS.resourcePlanCollapsed ? 'chevron-right' : 'chevron-left'"></fa-icon></button>
    <ejs-schedule
        #scheduleObj
        width="100%"
        height="100%"
        [class.hide-left-row]="LS.resourcePlanCollapsed && (BS.MobileQuery | async)"
        (popupOpen)="onPopupOpen($event)"
        [enableAdaptiveUI]="false"
        [eventSettings]="Data$ | async"
        (eventRendered)="ApplyCategoryColor($event)"
        (navigating)="navigating($event)"
        (dataBound)="dataBound($event)"
        (currentViewChange)="ViewChange($event, scheduleObj.activeViewOptions); OnViewChange.emit($event)"
        [selectedDate]="SelectedDate"
        (actionBegin)="onActionBegin($event)"
        (actionComplete)="actionComplete($event)"
        [group]="Group"
        [rowAutoHeight]="true"
        (dragStart)="onDragStart($event)"
        (resizeStart)="onResizeStart($event)"
        (dragStop)="onDragStop($event)"
        (resizeStop)="onResizeStop($event)"
        cssClass="schedule-cell-dimension"
    >
        <e-resources>
            <e-resource field="CommissionId" name="Commission" [title]="cls.getMultiple$('Commission') | async" textField="Text" idField="Id" [dataSource]="CommissionCalendarResource$ | async"></e-resource>
        </e-resources>
        <ng-template #resourceHeaderTemplate let-data>
            <a class='custom-resource-text' *ngIf="data.resourceData.Path as path" [routerLink]="path">
                {{data.resourceData.Text}}
            </a>
            <div class='custom-resource-text' *ngIf="!data.resourceData.Path">
                {{data.resourceData.Text}}
            </div>
        </ng-template>
        <e-views>
            <e-view option="TimelineMonth" [allowVirtualScrolling]="false" displayName="Monat" [isSelected]="View === 'TimelineMonth'"> </e-view>
            <e-view option="TimelineMonth" [allowVirtualScrolling]="false" displayName="3 Monate" [interval]="3" [isSelected]="View === 'Timeline3Month'"> </e-view>
        </e-views>
        <e-header-rows>
            <e-header-row option="Date">
                <ng-template #dateHeaderTemplate let-data>
                    <div>{{ data.date | date: 'd.' }}</div>
                    <div>{{ data.date | date: 'EE' }}</div>
                    <!--                macht abstand der für den footer benötigt wird, damit die tabelle mit dem content nicht zu groß wird-->
                    <div [style.height]="FooterHeightVar + 'px'"></div>
                </ng-template>
            </e-header-row>
        </e-header-rows>
        <ng-template #quickInfoTemplatesHeader let-data>
            <div class="e-popup-header" [ngStyle]="{ 'background-color': data.CategoryColor?.primary ? data.CategoryColor.primary : 'unset' }">
                <div class="e-header-icon-wrapper">
                    <ng-container *ngIf="AdditionalHeaderButtons">
                        <ng-container *ngFor="let button of AdditionalHeaderButtons">
                            <a *ngIf="button.routerLink" class="e-edit e-btn e-lib e-flat e-round e-small e-icon-btn" [routerLink]="button.routerLink" [title]="button.tooltip">
                                <span class="e-btn-icon e-icons"><fa-icon [icon]="button.icon"></fa-icon></span>
                            </a>
                            <button
                                *ngIf="!button.routerLink"
                                class="e-edit e-btn e-lib e-flat e-round e-small e-icon-btn"
                                [title]="button.tooltip"
                                (click)="button.onClick($event)"
                            >
                                <span class="e-btn-icon e-icons"><fa-icon [icon]="button.icon"></fa-icon></span>
                            </button>
                        </ng-container>
                    </ng-container>
                    <button class="e-edit e-btn e-lib e-flat e-round e-small e-icon-btn" [disabled]="data.isReadonly" title="Bearbeiten"></button>
                    <button class="e-delete e-btn e-lib e-flat e-round e-small e-icon-btn" [disabled]="data.isReadonly" title="Löschen"></button>
                    <button class="e-close e-btn e-lib e-flat e-round e-small e-icon-btn" title="Schließen"></button>
                </div>
                <div class="e-subject-wrap">
                    <div class="e-subject e-text-ellipsis" [title]="data.Subject">{{ data.Subject }}</div>
                </div>
            </div>
        </ng-template>
        <ng-template #quickInfoTemplatesContent let-data>
            <div class="e-date-time" *ngIf="data.StartTime && data.EndTime">
                <div class="e-date-time-icon e-icons"></div>
                <div class="e-date-time-wrapper e-text-ellipsis">
                    <div class="e-date-time-details e-text-ellipsis">{{data.StartTime | date: 'd. MMM y' }} {{data.IsAllDay ? ' (ganztägig)' : ''}}&nbsp;-&nbsp;{{data.EndTime | date: 'd. MMM y' }}{{data.IsAllDay ? ' (ganztägig)' : ''}}</div>
                </div>
            </div>
<!--            <div class="e-location">-->
<!--                <div class="e-location-icon e-icons"></div>-->
<!--                <div class="e-location-details e-text-ellipsis">teststraße 1<br>12367 Testort<br>Testland</div>-->
<!--            </div>-->
<!--            <div class="e-description">-->
<!--                <div class="e-description-icon e-icons"></div>-->
<!--                <div class="e-description-details e-text-ellipsis">23, August | Bierski, Timo</div>-->
<!--            </div>-->
            <ng-container *ngIf="data.ResourceDetails$">
                <div class="e-resource" *ngIf="data.ResourceDetails$() | async as res">
                    <div class="e-resource-icon e-icons hide-before-content">
                        <fa-icon [icon]="CommissionMeta.Icon"></fa-icon>
                    </div>
                    <div class="e-resource-details e-text-ellipsis">
                        {{res.Text}}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="data.quickViewDetails$">
                <div class="e-description" *ngFor="let res of data.quickViewDetails$() | async">
                    <div class="e-resource-icon e-icons hide-before-content" *ngIf="res.icon">
                        <fa-icon [icon]="res.icon"></fa-icon>
                    </div>
                    <div class="e-resource-details e-text-ellipsis">
                        {{res.text}}
                    </div>
                </div>
            </ng-container>
<!--            <ng-container [ngTemplateOutlet]="data.elementType == 'cell' ? cellContent : eventContent"-->
<!--                          [ngTemplateOutletContext]="{data:data}"></ng-container>-->
        </ng-template>
    </ejs-schedule>
</div>
<app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
<mat-menu #menu>
    <button *ngFor="let button of AddButtons" mat-menu-item (click)="button.onClick($event)" [disabled]="button.disabled$ | async">
        <fa-icon [icon]="button.icon" style="margin-right: 0.5rem"></fa-icon>
        <span>{{ button.label }}</span>
    </button>
</mat-menu>
<button #matMenuTrigger style="visibility: hidden; position: fixed" [matMenuTriggerFor]="menu"></button>
<div style="visibility: hidden; position: absolute" id="footer-row-icons">
    <fa-icon *ngFor="let row of Rows" [icon]="row.icon" [class]="'fa-icon-' + row.icon"></fa-icon>
</div>
<div #commissionIcon style="visibility: hidden; position: absolute">
    <fa-icon [icon]="CommissionMeta.Icon"></fa-icon>
</div>
<div #loadingTemplate style="visibility: hidden; position: absolute">
    <tr>
        <td colspan="2">
            <app-loading [Size]="75" style="padding: 1rem"></app-loading>
        </td>
    </tr>
</div>
<ng-template #jobSpecificationOptionTemplate let-option>
    <app-job-specification-option [JobSpecificationEntity]="option"></app-job-specification-option>
</ng-template>
<ng-template #activityOptionTemplate let-Option>
    <div style="display: flex; justify-content: space-between; align-items: center">
        {{Option.Name}} <button *ngIf="!Option.DeletedAt" mat-icon-button (mousedown)="$event.stopPropagation()" (click)="$event.stopPropagation(); DeleteActivity(Option);"><fa-icon icon="trash"></fa-icon></button>
    </div>
</ng-template>
