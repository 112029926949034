<app-dave-list-card
    [Data]="Groups$"
    [DisplayedCollums]="['Name']"
    [Expanded]="Expanded"
    [IsExpandable]="true"
    [Headline]="Headline || 'Gruppen'"
    [HeaderIcon]="GroupsIcon"
    (EntryClicked$)="openSelectMembers($event)"
    [Clickable]="true"
    [IsLoading]="!(contentResolved$ | async)"
    [CustomCellTemplates]="{Name: nameWithButton}"
>
    <button headerButton app-round-button matTooltip="Gruppe anlegen" (click)="openNewGroupPopUp()">
        <app-double-icon [Icon]="GroupsIcon" Icon2="plus"></app-double-icon>
    </button>
</app-dave-list-card>
<ng-template #nameWithButton let-data="data">
   <span style="display: flex;
    justify-content: space-between;
    align-items: center;">
       {{data?.Name}} <button app-round-button matTooltip="E-Mail senden" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"><fa-icon icon="paper-plane"></fa-icon></button>
   </span>
    <mat-menu #menu>
        <button mat-menu-item (click)="OpenEmailDialog(data, 'to')">Empfänger</button>
        <button mat-menu-item (click)="OpenEmailDialog(data, 'cc')">CC</button>
        <button mat-menu-item (click)="OpenEmailDialog(data, 'bcc')">BCC</button>
    </mat-menu>
</ng-template>

