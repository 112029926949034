import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { PersonActionTypes } from '../State/actions/person.actions';
import { getPersonLatestUpdatedAt, getPersonsFetched } from '../State/selectors/person.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class PersonResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getPersonsFetched, getPersonLatestUpdatedAt, PersonActionTypes.Load, store);
    }
}
