import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {GatewayRequest, GatewayResponse} from '@dave/types';
import {Store} from '@ngrx/store';
import {firstValueFrom} from 'rxjs';
import {environment} from '../../../environments/environment';
import {State} from '../State';
import {getToken} from '../State/selectors/base.selectors';
import { API_METRICS, API_TRACING, ProtoApiService } from './proto-api.service';

export type GatewayResponseErrorType = {
    [key: string]: string;
}


@Injectable({
    providedIn: 'root',
})
export class AppGatewayService {
    constructor(protected router: Router, protected store: Store<State>, private protoApiService: ProtoApiService) {
    }

    GetEntity(method: keyof GatewayRequest): Promise<GatewayResponse> {
        return this.Request({
            [method]: {},
        });
    }
    Request(requestObj: GatewayRequest): Promise<GatewayResponse> {
        return this.protoApiService.Request<GatewayRequest, GatewayResponse>(
            GatewayRequest.create({
                EnableTracing: API_TRACING,
                ReturnMetrics: API_METRICS,
                ...requestObj,
            }),
            (v) => GatewayResponse.decode(new Uint8Array(v)),
            (v) => GatewayRequest.encode(v).finish(),
            '/gateway-v2/request'
        )
    }
    // Requestold(requestObj: GatewayRequest): Promise<GatewayResponse> {
    //     return firstValueFrom(this.store.select(getToken)).then((token) => {
    //         return new Promise((resolve, reject) => {
    //             const req = new XMLHttpRequest();
    //             req.onload = (e) => {
    //                 if (req.status === 200) {
    //                     if (window["env"].useProtobuf) {
    //                         const response = GatewayResponse.decode(new Uint8Array(req.response));
    //                         resolve(response);
    //                     } else {
    //                         resolve(JSON.parse(req.response));
    //                     }
    //                 } else {
    //                     reject(req.response);
    //                 }
    //             };
    //             req.onerror = (err) => {
    //                 reject(err);
    //             };
    //             req.open('POST', environment.gatewayApiUrl + '/gateway-v2/request');
    //             req.setRequestHeader('Authorization', 'Bearer ' + token);
    //             const body = GatewayRequest.create({
    //                 EnableTracing: TRACING,
    //                 ReturnMetrics: METRICS,
    //                 ...requestObj,
    //             });
    //             if (window["env"].useProtobuf) {
    //                 req.responseType = 'arraybuffer';
    //                 req.setRequestHeader('Content-Type', 'application/protobuf');
    //                 req.send(GatewayRequest.encode(body).finish());
    //             } else {
    //                 req.responseType = 'text';
    //                 req.setRequestHeader('Content-Type', 'application/json');
    //                 req.send(JSON.stringify(body));
    //             }
    //         });
    //     });
    // }
}
