import { PersonTypeType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
export enum PersonTypeNames {
    ConstructionManager = 'Bauleiter',
    BuildingOwner = 'Bauherr',
    Policyholder = 'Versicherungsnehmer',
    Architect = 'Architekt / Planer',
}
export class PersonTypeEntity {
    constructor(
        public Id: number = null,
        public Name: string = null,
        public PartnerId: number = null,
        public DeletedAt: Date = null,
    ) {
    }
    public static readonly GqlFields: Array<keyof PersonTypeType> = [
        'id',
        'name',
        'partnerId',
        'deletedAt',
    ]
    public Clone(override: Partial<PersonTypeEntity> = {}): PersonTypeEntity {
        const attr = {...this, ...override};
        return Object.assign(new PersonTypeEntity(), attr);
    }
}

export function PersonTypeEntityFromBackend(res: PersonTypeType): PersonTypeEntity {
    return new PersonTypeEntity(
        res.id,
        res.name,
        res.partnerId,
        res.deletedAt ? res.deletedAt && BackendDate(res.deletedAt) : null,
    );
}
