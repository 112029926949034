import { Color } from '@angular-material-components/color-picker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ColorEntityFromColorType, ColorEntityFromNgxMatColor } from '../../../dave-data-module/entities/color.entity';
import { JobSpecificationEntity, JobSpecificationEntityAdditionalData } from '../../../dave-data-module/entities/job-specification.entity';
import { State } from '../../../dave-data-module/State';
import { JobSpecificationActionTypes } from '../../../dave-data-module/State/actions/job-specification.action';
import {
    getQuantityTypes,
    getQuantityTypesFetched,
} from '../../../dave-data-module/State/selectors/accounting.selector';
import { SelectSearchOption } from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { DetailListDialogReturn, DetailListTemplateDialogComponent, DetailListTemplateDialogData } from '../../../detail-list-template-dialog/components/detail-list-template-dialog.component';
import { getFetched$ } from '../../../dave-data-module/helper/helper';

export function OpenEditJobspecificationPopup(jsEntity: JobSpecificationEntity, dialog: MatDialog, store: Store<State>, quantityTypeResolver:  { resolve: () => void }) {
    const cName = new FormControl<string>(jsEntity.Name, Validators.required);
    const cUseForCalc = new FormControl<boolean>(jsEntity.UsedInCapacityCalculation);
    const cAmount = new FormControl<number>(jsEntity.Amount);
    const cQuantityTypeId = new FormControl<SelectSearchOption<{ Id: number }> | null>(jsEntity.QuantityTypeId ? { Id: jsEntity.QuantityTypeId } : null);
    const cColor = new FormControl<Color>(jsEntity.AdditionalData?.color ? ColorEntityFromColorType(jsEntity.AdditionalData.color).getNgxMaterialColorPickerObject() : null);

    dialog
        .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
            ...DetailListTemplateDialogComponent.DefaultConfig,
            data: {
                DisableSaveButton$: cName.statusChanges.pipe(
                    startWith(cName.status),
                    map((state) => state !== 'VALID'),
                ),
                Editing: true,
                Data: {
                    Headline: 'Jobbezeichnung bearbeiten',
                    Properties: [
                        {
                            key: 'Bezeichnung',
                            formControl: cName,
                        },
                        {
                            key: 'Farbe',
                            formControl: cColor,
                            options: {
                                specialInput: {
                                    colorPicker: true,
                                },
                            },
                        },
                        {
                            key: 'In Kapazitätsplanung einbeziehen',
                            formControl: cUseForCalc,
                            options: {
                                specialInput: {
                                    boolean: true,
                                },
                            },
                        },
                        {
                            key: 'Kosten (EK)',
                            formControl: cAmount,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                                suffix: '€',
                            },
                        },
                        {
                            key: 'Einheit',
                            formControl: cQuantityTypeId,
                            options: {
                                specialInput: {
                                    singleSelectSearch: {
                                        options$: getFetched$(store, getQuantityTypesFetched, getQuantityTypes, quantityTypeResolver).pipe(map((types) => types?.map((t) => ({ Id: t.Id, optionLabel: [t.Name, t.LangerName].filter(v => !!v).join(' ') })))),
                                        compareOptions: (a, b) => a.Id === b.Id,
                                    },
                                },
                            },
                        },
                    ],
                },
            },
        })
        .afterClosed()
        .subscribe((ret) => {
            if (ret.Action === 'save' && cName.valid && cUseForCalc.valid && cColor.valid && cAmount.valid && cQuantityTypeId.valid) {
                const additionalData: JobSpecificationEntityAdditionalData = { ...jsEntity.AdditionalData, color: cColor.value ? ColorEntityFromNgxMatColor(cColor.value) : null };
                store.dispatch(
                    JobSpecificationActionTypes.Modify({
                        Payload: {
                            id: jsEntity.Id,
                            name: cName.value,
                            usedInCapacityCalculation: cUseForCalc.value,
                            additionalData: JSON.stringify(additionalData),
                            amount: cAmount.value,
                            quantityTypeId: cQuantityTypeId.value?.Id || null,
                        },
                    }),
                );
            }
        });
}
export function OpenNewJobspecificationPopup(dialog: MatDialog, store: Store<State>): Promise<boolean> {
    const forms = new FormGroup({
        cName: new FormControl<string>('', Validators.required),
        cUseForCalc: new FormControl<boolean>(false),
        cColor: new FormControl<Color>(null),
    });
    return firstValueFrom(
        dialog
            .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                ...DetailListTemplateDialogComponent.DefaultConfig,
                data: {
                    DisableSaveButton$: forms.statusChanges.pipe(
                        startWith(forms.status),
                        map((state) => state !== 'VALID'),
                    ),
                    DeleteButton: true,
                    Editing: true,
                    Data: {
                        Headline: 'Jobbezeichnung anlegen',
                        Properties: [
                            {
                                key: 'Bezeichnung',
                                formControl: forms.controls.cName,
                            },
                            {
                                key: 'Farbe',
                                formControl: forms.controls.cColor,
                                options: {
                                    specialInput: {
                                        colorPicker: true,
                                    },
                                },
                            },
                            {
                                key: 'In Kapazitätsplanung einbeziehen',
                                formControl: forms.controls.cUseForCalc,
                                options: {
                                    specialInput: {
                                        boolean: true,
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .afterClosed(),
    ).then((ret) => {
        if (ret.Action === 'save' && forms.valid) {
            const additionalData: JobSpecificationEntityAdditionalData = { color: forms.value.cColor ? ColorEntityFromNgxMatColor(forms.value.cColor) : null };
            store.dispatch(JobSpecificationActionTypes.Create({ Payload: { name: forms.value.cName, usedInCapacityCalculation: forms.value.cUseForCalc, additionalData: JSON.stringify(additionalData) } }));
            return true;
        } else {
            return false;
        }
    });
}
