import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren, EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild
} from "@angular/core";
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, skip, startWith, tap } from "rxjs/operators";
import { BreakpointObserverService } from '../../../services/breakpoint-observer.service';
import { RouteLabelService } from '../../../services/route-label.service';
import { ListLayoutWithRoutingTabDirective } from './list-layout-with-routing-tab.directive';
import { ListLayoutWithRoutingService } from "./list-layout-with-routing.service";

/**
 * Layout-Template mit einer vertikal aufgeteilten Desktopansicht und einer
 * in Tabs unterteilten Mobilansicht
 *
 * - `Path` und `TabLabel` müssen gesetzt sein
 * - Hauptinhalt (linke Seite/erster Tab) über content projection übergeben
 * - gerouteter Inhalt wird automatisch (rechte Seite/letzter Tab) angezeigt,
 *   wenn eine Unterroute aufgerufen wurde
 * - zusätzlicher Inhalt (rechte Seite, wenn keine Unterroute aufgerufen/
 *   mittlere Tabs) auf Desktop via Routing, auf Mobile via
 *   `ListLayoutWithRoutingTabDirective` übergeben
 *
 *   man kann zwei (bisher) zusätzliche tabs zwischen router-outlet und content setzen, indem man tab1 und tab3 als outlet in der router config übergibt
 *
 * ```html
 * <app-list-layout-with-routing Path="customer-list" TabLabel="Kundenliste">
 *   <app-customer-list></app-customer-list>
 * </app-list-layout-with-routing>
 * ```
 */
@Component({
    selector: 'app-list-layout-with-routing',
    templateUrl: './list-layout-with-routing.component.html',
    styleUrls: ['./list-layout-with-routing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListLayoutWithRoutingComponent implements OnInit, OnDestroy {
    /** **Erforderlich**: Base-Pfad des Features (`chronik`, `einstellungen` etc.) */
    @Input() Path!: string;

    /** **Erforderlich**: Das Label des ersten Tabs in der mobilen Ansicht (z.B. `Kundenliste`) */
    @Input() TabLabel!: string;
    @Input() MobileBreakpoint: Observable<boolean> = null;

    HideSideContent$ = new BehaviorSubject(false);
    @Input()
    set HideSideContent(hide: boolean) {
        this.HideSideContent$.next(hide);
    }
    @Output() OnResize = new EventEmitter<void>();
    /** Die Daten, die im Template verwendet werden */
    Data$: Observable<{
        IsMobile: boolean;
        /** Zeigt an, ob eine Unterroute aufgerufen wurde, also ein Listeneintrag ausgewählt ist */
        IsSelected: boolean;
    }>;
    protected routerTabLabel$ = this.routeLabelService.RouteLabel$;
    tab1exists$ = new BehaviorSubject(false);
    tab1label$ = new BehaviorSubject(null);
    tab2exists$ = new BehaviorSubject(false);
    tab2label$ = new BehaviorSubject(null);
    primaryExists$ = new BehaviorSubject(false);
    ContentIsVisible$: Observable<boolean>;
    /** Die zusätzlichen Tabs für die Mobilansicht */
    @ContentChildren(ListLayoutWithRoutingTabDirective)
    Tabs!: QueryList<ListLayoutWithRoutingTabDirective>;

    // Material-Direktiven aus dem Template zum Ändern des aktiven Tabs nach einem Routingvorgang
    @ViewChild(MatTabGroup) private tabGroup?: MatTabGroup;
    @ViewChild('routerTab') private routerTab?: MatTab;
    @ViewChild('tab1') private tab1?: MatTab;
    @ViewChild('tab2') private tab2?: MatTab;

    private closeSideVavSub: Subscription;
    private contentIsVisibleSub: Subscription;
    private observeRouterOutlets: Subscription;
    constructor(private listLayoutWithRoutingService: ListLayoutWithRoutingService, private breakpoints: BreakpointObserverService, private cdr: ChangeDetectorRef, private routeLabelService: RouteLabelService, private router: Router, private activatedRoute: ActivatedRoute) {
        if (!this.MobileBreakpoint) {
            this.MobileBreakpoint = this.breakpoints.MobileQuery;
        }
    }

    ngOnDestroy(): void {
        this.closeSideVavSub?.unsubscribe();
        this.observeRouterOutlets?.unsubscribe();
        this.contentIsVisibleSub?.unsubscribe();
    }

    ngOnInit() {
        // Angular setzt @Input() Werte erst in ngOnInit, nicht schon im constructor
        if (!this.Path) {
            throw Error('No value for [Path] provided!');
        }
        if (!this.TabLabel) {
            throw Error('No value for [TabLabel] provided!');
        }
        this.observeRouterOutlets = this.router.events
            .pipe(
                // Künstliches Event, um direkt den ersten Wert auszugeben ohne auf Events zu warten
                startWith(new NavigationEnd(null, this.router.url, null)),
                filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                tap((event) => {
                    const routes: ActivatedRoute[] = [];
                    addRoute(this.activatedRoute);
                    function addRoute(v: ActivatedRoute) {
                        routes.push(v);
                        v.children?.forEach((a) => addRoute(a));
                    }
                    const tab1 = routes.find((a) => a.outlet === 'tab1' && a.component);
                    if (this.tab1exists$.value !== !!tab1) {
                        this.tab1exists$.next(!!tab1);
                        if (tab1) {
                            firstValueFrom(this.routeLabelService.GetLabelForRoute$(tab1.snapshot)).then(label => this.tab1label$.next(label))
                        } else {
                            this.tab1label$.next(null);
                        }
                    }
                    const tab2 = routes.find((a) => a.outlet === 'tab2' && a.component);
                    if (this.tab2exists$.value !== !!tab2) {
                        this.tab2exists$.next(!!tab2);
                        if (tab2) {
                            firstValueFrom(this.routeLabelService.GetLabelForRoute$(tab2.snapshot)).then(label => this.tab1label$.next(label))
                        } else {
                            this.tab1label$.next(null);
                        }
                    }
                    const tab3 = routes.some((a, index) => index && a.outlet === 'primary' && a.component);
                    if (this.primaryExists$.value !== tab3) {
                        this.primaryExists$.next(tab3);
                    }

                    setTimeout(() => {
                        // setTimeout, da tabGroup und routerTab erst nach dem Aufrufen einer Unterroute existieren
                        this.setRouterTab((tab3 && this.routerTab) || (tab2 && this.tab2) || (tab1 && this.tab1) || null);
                    });
                }),
            )
            .subscribe();
        const isSelected$ = this.router.events.pipe(
            // Künstliches Event, um direkt den ersten Wert auszugeben ohne auf Events zu warten
            startWith(new NavigationEnd(null, this.router.url, null)),
            // nur nach erfolgreicher Navigation (`NavigationEnd`) neuen Wert ausgeben
            filter((event): event is NavigationEnd => event instanceof NavigationEnd),
            map((event) => event.url.includes(`/${this.Path}/`)),
            // Seiteneffekt: Wenn ein Listenelement ausgewählt wurde, auf den RouterTab wechseln
            // tap((IsSelected) => IsSelected && this.setRouterTab()),
            distinctUntilChanged(),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

        this.Data$ = combineLatest([
            this.MobileBreakpoint,
            isSelected$,
        ]).pipe(
            map(([IsMobile, IsSelected]) => ({
                IsMobile,
                IsSelected,
            })),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.ContentIsVisible$ = combineLatest([this.Data$, this.HideSideContent$]).pipe(
            map(([data, hideContent]) => !(data.IsSelected && hideContent) || data.IsMobile),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.contentIsVisibleSub = this.ContentIsVisible$.pipe(distinctUntilChanged(), skip(1)).subscribe(() => {
            this.OnResize.emit();
            this.listLayoutWithRoutingService.emmitResizeEvent();
        })
        // Im Tablet-Modus soll der Side-Content beim Aufruf der Seite nicht zu sehen sein
        this.closeSideVavSub = combineLatest([this.breakpoints.TabletQuery, isSelected$]).subscribe(([isTablet, isSelected]) => {
            if (isTablet) {
                this.HideSideContent = !((!isSelected && isTablet) || !isTablet);
                this.cdr.markForCheck();
            }
        });
    }

    /** Setzt den aktiven Tab der mobilen Ansicht auf den RouterTab */
    private setRouterTab(tab: MatTab) {
        if (this.tabGroup && tab) {
            this.tabGroup.selectedIndex = this.tabGroup.selectedIndex + tab.position;
            // ChangeDetection.OnPush führt keine Change Detection nach setTimeout aus
            this.cdr.markForCheck();
        }
    }
}
