<app-dave-list-card
    [Headline]=""
    EmptyText="Keine Umsätze vorhanden"
    [Data]="Data$ | async"
    [class.is-in-dialog]="!!dialogRef"
    [DisplayedCollums]="displayedColumns"
    [Clickable]="true"
    [TableHeaderCollumsMap]="tableHeaders$ | async"
    [HasSuffixButton]="false"
    [CustomHeaderTemplate]="searchHeader"
    (EntryClicked$)="EntryClick($event)"
    [IsExpandable]="Expandable"
    [Expanded]="Expanded"
    [tableLayoutFixed]="tableLayoutFixed"
>
    <div *ngIf="DialogData" listCardFooter class="footer" mat-dialog-actions>
        <button mat-dialog-close="" Inverted app-button Color="cancel" class="button">Abbrechen</button>
    </div>
</app-dave-list-card>
<ng-template #searchHeader>
<!--    <div class="header">-->
        <h3 class="headline">{{(BvFilterValues$ | async).type === BusinessVolumeCostStateEnum.Costs ? 'Kosten (Netto)' : 'Umsätze (Netto)'}}</h3>
    <div class="header-buttons">
        <button *ngIf="dialogRef" app-round-button matTooltip="Buchhaltungsdaten exportieren" (click)="exportBusinessVolumes()" ><fa-icon icon="download"></fa-icon></button>
        <button app-round-button (click)="bVFilter.Toggle(); $event.stopPropagation();" [matBadge]="FilterAmount$ | async" [matBadgeHidden]="!(FilterAmount$ | async)" matTooltip="Filter" [matBadgeOverlap]="true" class="mat-badge-more-overlay">
            <fa-icon icon="filter"></fa-icon>
        </button>
        <button app-round-button matTooltip="Umsatz hinzufügen" (click)="addBV(); $event.stopPropagation();"  *ngIf="hasCreatePermission$ | async"><fa-icon icon="plus"></fa-icon></button>
        <button *ngIf="!dialogRef" app-round-button matTooltip="Vollbild" (click)="openDialog(); $event.stopPropagation();"><fa-icon icon="expand"></fa-icon></button>
    </div>
<!--    </div>-->
</ng-template>
<app-filter-card UnlimitedHeight MaxWidthHundertPercent #bVFilter [Settings]="BvFilterSettings | async" [Values]="BvFilterValues$ | async" (ValuesChange)="BvFilterValues$.next($event)"></app-filter-card>
<ng-template #lIButton>
    <div class="reports-theme theme-override">
        <a app-button class="wide-button" [href]="ledgerImportPath" matTooltip="zur Rechnung">
            <fa-icon [icon]="ReportsPageMeta.Icon"></fa-icon>
        </a>
    </div>
</ng-template>
<!--<mat-dialog-actions *ngIf="DialogData">-->
<!--    <button mat-dialog-close="" Inverted app-button Color="cancel" class="button">Abbrechen</button>-->
<!--</mat-dialog-actions>-->

