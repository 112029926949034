import { CommonModule } from '@angular/common';
import { Component,OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { BehaviorSubject,firstValueFrom } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';
import {
EventTypesSelectorCardDialogComponent,EventTypesSelectorCardDialogComponentReturnData
} from "../components/templates/event-types-selector-card/event-types-selector-card-dialog/event-types-selector-card-dialog.component";
import { EventTypeEntity } from '../dave-data-module/entities/event-type.entity';
import { EventTypeResolver } from '../dave-data-module/guards/event-type.resolver';
import { State } from '../dave-data-module/State';
import { getEventTypeFetched,getEventTypes } from '../dave-data-module/State/selectors/event-type.selector';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { IconPipeModule } from '../dave-utils-module/icon-pipe/icon-pipe.module';

@Component({
    selector: 'app-select-event-type',
    standalone: true,
    imports: [CommonModule, DaveLoadingModule, MatButtonModule, FontAwesomeModule, IconPipeModule, AppButtonModule, MatTooltipModule],
    templateUrl: './select-event-type.component.html',
    styleUrls: ['./select-event-type.component.scss'],
})
export class SelectEventTypeComponent implements OnInit {
    protected disabled$ = new BehaviorSubject(false);
    protected eventTypes$ = this.store.select(getEventTypes);
    protected eventTypesFetched$ = this.store.select(getEventTypeFetched);
    public selectedEventTypeId$ = new BehaviorSubject<number | null>(null);
    protected previewEventTypes$: Observable<Array<EventTypeEntity & { selected?: boolean }>> = this.eventTypes$.pipe(
        switchMap((eventTypes) => this.selectedEventTypeId$.pipe(map((selectedId) => eventTypes.filter((e) => e.IsDefault || e.Id === selectedId).map((e) => (e.Id === selectedId ? Object.assign(e.Clone(), { selected: true }) : e))))),
    );

    constructor(private eventTypeRes: EventTypeResolver, private store: Store<State>, private dialog: MatDialog) {}

    ngOnInit(): void {
        firstValueFrom(this.eventTypesFetched$).then((f) => {
            if (!f) {
                this.eventTypeRes.resolve();
            }
        });
    }
    setDisabledState(isDisabled: boolean) {
        this.disabled$.next(!!isDisabled)
    }

    openPopup() {
        this.dialog
            .open<EventTypesSelectorCardDialogComponent, any, EventTypesSelectorCardDialogComponentReturnData>(EventTypesSelectorCardDialogComponent, EventTypesSelectorCardDialogComponent.DefaultConfig)
            .afterClosed()
            .subscribe((ret) => {
                if (ret) {
                    this.selectedEventTypeId$.next(ret.Id);
                }
            });
    }
}
