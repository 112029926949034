    <p class="headline">
        <fa-icon icon="stopwatch"></fa-icon>&nbsp;Zeiterfassung&nbsp;
<!--        <span *ngIf="this.timeTrackerService.Running">stoppen</span>-->
<!--        <span *ngIf="!this.timeTrackerService.Running">starten</span>-->
    </p>
    <div class="wrapper">
        <div class="icons">
<!--            todo active nach arbeitszeit auf oberen button setzen-->
            <fa-icon class="active" [class.active]="!this.timeTrackerService.Running && !this.PauseRunning" (click)="PlayStop();" [icon]="this.timeTrackerService.Running ? ['far', 'stop-circle'] : ['far', 'play-circle']"></fa-icon>
            <fa-icon [class.active]="this.timeTrackerService.Running || this.PauseRunning" *ngIf="this.timeTrackerService.Running || this.PauseRunning" (click)="PlayPause();" [icon]="this.PauseRunning ? ['far', 'play-circle'] : ['far', 'circle-pause']"></fa-icon>
        </div>
        <div class="time">
            <div class="current-time-wrapper">
                <p class="current-time" *ngIf="RunningWorkTime$ | async as time">{{ time.h | number: '2.0-0' }}:{{ time.m | number: '2.0-0' }}:{{ time.s | number: '2.0-0' }}<span class="suffix">h</span></p>
            </div>
            <p class="break-time" *ngIf="BreakTime$ | async as time">Pause {{ time.h | number: '2.0-0' }}:{{ time.m | number: '2.0-0' }}:{{ time.s | number: '2.0-0' }}<span class="suffix">h</span></p>
        </div>
    </div>
