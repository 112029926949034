import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SimplebarAngularModule } from 'simplebar-angular';
import { TextareaModule } from '../components/templates/textarea/textarea.module';
import { DaveLoadingModule } from "../dave-loading/dave-loading.module";
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from "../dave-utils-module/app-filter-module/app-filter.module";
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { SelectSearchModule } from '../dave-utils-module/select-search/select-search.module';
import { HourListComponent } from './components/hour-list/hour-list.component';
import { NewClockinPopupComponent } from './components/new-clockin-popup/new-clockin-popup.component';
import { TimeTrackerComponent } from './components/time-tracker/time-tracker.component';
import { MatListModule } from "@angular/material/list";


@NgModule({
    declarations: [TimeTrackerComponent, NewClockinPopupComponent, HourListComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        TextareaModule,
        AppButtonModule,
        MatDialogModule,
        SelectSearchModule,
        MatExpansionModule,
        MatTooltipModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        DaveSharedComponentsModule,
        MatInputModule,
        SimplebarAngularModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        FormsModule,
        MatRadioModule,
        DaveLoadingModule,
        AppFilterModule,
        MatBadgeModule,
        MatListModule,
    ],
    exports: [TimeTrackerComponent, NewClockinPopupComponent, HourListComponent],
})
export class DaveTimeTrackerModule {}
