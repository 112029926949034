import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { State } from '../State';
import { EmailSettingsTypeEntity } from '../entities/emailSettingsType.entity';
import { EmailSettingsTypeActionTypes } from '../State/actions/emailsettingstype.actions';
import { getEmailSettingsTypes } from '../State/selectors/emailSettingsType.selector';

@Injectable({
  providedIn: 'root'
})
export class EmailSettingsTypeResolver implements Resolve<EmailSettingsTypeEntity[]> {
  constructor(private store: Store<State>) {
  }

  resolve() {
    const value$ = this.store.select(getEmailSettingsTypes);

    value$
      .pipe(
        filter(value => !value),
        take(1),
        tap(() => {
          if (localStorage.getItem('token') != null) {
            this.store.dispatch(EmailSettingsTypeActionTypes.GetEmailSettingsTypeRequest());
          }
        })
      )
      .subscribe();

    return value$.pipe(
      filter(value => !!value),
      take(1)
    );
  }
}
