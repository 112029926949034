
import {ConditionType} from '../graphql-types';

export class ConditionEntity {
    public static readonly GqlFields = 'fileId id name text';

    constructor(
        public Id: number = null,
        public Name: string = null,
        public Text: string = null,
        public FileId: number = null,
    ) {}

    public Clone(override: ConditionEntityAttributes = {}): ConditionEntity {
        const attr = {...this, ...override};
        return Object.assign(new ConditionEntity(), attr);
    }
}

export function ConditionEntityFromBackend(res: ConditionType): ConditionEntity {
    return new ConditionEntity(
        res.id,
        res.name,
        res.text,
        res.fileId
    );
}

export interface ConditionEntityAttributes {
    Id?: number;
    Name?: string;
    Text?: string;
    FileId?: number;
}
