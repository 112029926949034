import { ActionReducerMap } from '@ngrx/store';
import { accountingFeatureKey, accountingReducer, AccountingState } from './reducers/accounting.reducer';
import { activityReducer, ActivityState, ACTIVITY_FEATURE_KEY } from './reducers/activity.reducer';
import { akkordToCommissionReducer, AkkordToCommissionState, AKKORD_TO_COMMISSION_KEY } from './reducers/akkord/akkord-to-commission.reducer';
import { akkordReducer, AkkordState, AKKORD_KEY } from './reducers/akkord/akkord.reducer';
import { bVCustomNameReducer, BVCustomNameState, B_V_CUSTOM_NAME_FEATURE_KEY } from './reducers/b-v-custom-name.reducer';
import { baseFeatureKey, baseReducer, BaseState } from './reducers/base.reducer';
import { bookmarkReducer, BookmarkState, BOOKMARK_KEY } from './reducers/bookmarks.reducer';
import { businessVolumeReducer, BusinessVolumeState, BUSINESS_VOLUME_KEY } from './reducers/business-volume.reducer';
import { clockInTypesFeatureKey, ClockInTypesState, clockInTypesStateReducer } from './reducers/clock-in-type.reducer';
import { clockInsFeatureKey, ClockInsState, clockInsStateReducer } from './reducers/clock-in.reducer';
import { commentReducer, CommentState, COMMENTS_FEATURE_KEY } from './reducers/comment.reducer';
import { commissionReducer, commissionsFeatureKey, CommissionState } from './reducers/commission.reducer';
import { CommissionTypeReducer, CommissionTypesFeatureKey, CommissionTypeState } from './reducers/commissionType.reducer';
import { ComplaintTypeReducer, complaintTypesFeatureKey, ComplaintTypeState } from './reducers/complaintType.reducer';
import { conditionsFeatureKey, ConditionsState, conditionsStateReducer } from './reducers/conditions.reducer';
import { constructionDiaryReducer, ConstructionDiaryState, CONSTRUCTION_DIARY_KEY } from './reducers/construction-diary.reducer';
import { customerReducer, customersFeatureKey, CustomerState } from './reducers/customers.reducer';
import { CustomerSpecificationTypeReducer, customerSpecificationTypesFeatureKey, CustomerSpecificationTypeState } from './reducers/customerSpecificationType.reducer';
import { damageFlowReducer, damageFlowsFeatureKey, DamageFlowState } from './reducers/damageflow.reducer';
import { DamageFlowTypeReducer, damageFlowTypesFeatureKey, DamageFlowTypeState } from './reducers/damageFlowType.reducer';
import { documentDetectionFeatureKey, documentDetectionReducer, DocumentDetectionState } from './reducers/detection.reducer';
import { emailConnectionFeatureKey, emailConnectionReducer, EmailConnectionState } from './reducers/email-connection.reducer';
import { emailFeatureKey, EmailReducer, EmailState } from './reducers/email.reducer';
import { emailConnection2EmailSettingsTypeFeatureKey, EmailConnection2EmailSettingsTypesReducer, EmailConnection2EmailSettingsTypeState } from './reducers/emailConnection2EmailSettingsType.reducer';
import { EmailFolderReducer, emailFoldersFeatureKey, EmailFolderState } from './reducers/emailfolders.reducer';
import { emailSettingsTypeFeatureKey, EmailSettingsTypesReducer, EmailSettingsTypeState } from './reducers/emailsettingstype.reducer';
import { employeeSalaryTypeReducer, EmployeeSalaryTypeState, EMPLOYEE_SALARY_TYPE_FEATURE_KEY } from './reducers/employee-salary-type.reducer';
import { employeeToCommissionReducer, EmployeeToCommissionState, EMPLOYEE_TO_COMMISSION_KEY } from './reducers/employee-to-commission.reducer';
import { employeesFeatureKey, EmployeesState, employeesStateReducer } from './reducers/employees.reducer';
import { employeeToVacationFeatureKey, EmployeeToVacationState, employeeToVacationStateReducer } from './reducers/employeeToVacation.reducer';
import { entityRoleReducer, EntityRoleState, ENTITY_ROLE_KEY } from './reducers/entity-role.reducer';
import { eventTypesReducer, EventTypeState, EVENT_TYPE_FEATURE_KEY } from './reducers/event-types.reducer';
import { eventsFeatureKey, eventsReducer, EventsState } from './reducers/events.reducer';
import { filesReducer, FileState, FILES_FEATURE_KEY } from './reducers/files.reducer';
import { folderReducer, FolderState, FOLDER_FEATURE_KEY } from './reducers/folder.reducer';
import { FormDataKey, formDataReducer, FormDataState } from './reducers/form-data.reducer';
import { generatedDocumentsFeatureKey, GeneratedDocumentsReducer, GeneratedDocumentState } from './reducers/generatedDocuments.reducer';
import { GeneratedDocumentsTypeReducer, generatedDocumentsTypesFeatureKey, GeneratedDocumentsTypeState } from './reducers/generatedDocumentsType.reducer';
import { groupsReducer, GroupState, GROUP_KEY } from './reducers/group.reducer';
import { jobSpecificationReducer, JobSpecificationState, JOB_SPECIFICATION_KEY } from './reducers/job-specification.reducer';
import { ledgerImportReducer, LedgerImportState, LEDGER_IMPORT_KEY } from './reducers/ledger-import.reducer';
import { licensesFeatureKey, licensesReducer, LicensesState } from './reducers/licenses.reducer';
import { mandateTypeReducer, MandateTypeState, MANDATE_TYPE_KEY } from './reducers/mandate-type.reducer';
import { milestonesReducer, MilestoneState, Milestone_KEY } from './reducers/milestone.reducer';
import { newsReducer, NewsState, NEWS_KEY } from './reducers/news.reducer';
import { oAuthTokenReducer, OAuthTokenState, O_AUTH_TOKEN_KEY } from './reducers/o-auth-token.reducer';
import { officeFeatureKey, officeReducer, OfficeState } from './reducers/office.reducer';
import { partnersFeatureKey, partnersReducer, PartnersState } from './reducers/patners.reducer';
import { permissionsFeatureKey, permissionsReducer, PermissionsState } from './reducers/permissions.reducer';
import { PersonReducer, personsFeatureKey, PersonState } from './reducers/person.reducer';
import { person2EntityFeatureKey, person2EntityReducer, Person2EntityState } from './reducers/person2Entity.reducer';
import { PersonTypeReducer, personTypesFeatureKey, PersonTypeState } from './reducers/personType.reducer';
import { processTemplateReducer, ProcessTemplateState, PROCESS_TEMPLATE_FEATURE_KEY } from './reducers/process-template.reducer';
import { processReducer, ProcessState, PROCESS_FEATURE_KEY } from './reducers/process.reducer';
import { productsFeatureKey, productsReducer, ProductsState } from './reducers/products.reducer';
import { publicFileReducer, PublicFileState, PUBLIC_FILE_KEY } from './reducers/public-file.reducer';
import { QMStatusFeatureKey, QMStatusReducer, QMStatusState } from './reducers/qMStatus.reducer';
import { employeeResourceSchedulesReducer, EmployeeResourceScheduleState, EMPLOYEE_RESOURCE_SCHEDULE_KEY } from './reducers/resource-dispo/employee-resource-schedule.reducer';
import { resourceSchedulesReducer, ResourceScheduleState, RESOURCE_SCHEDULE_KEY } from './reducers/resource-dispo/resource-schedule.reducer';
import { resourceTypeReducer, ResourceTypeState, RESOURCE_TYPE_KEY } from './reducers/resource-dispo/resource-type.reducer';
import { resourcesReducer, ResourceState, RESOURCE_KEY } from './reducers/resource-dispo/resource.reducer';
import { salaryTypeReducer, SalaryTypeState, SALARY_TYPE_FEATURE_KEY } from './reducers/salary-type.reducer';
import { scan2DaveFeatureKey, scan2DaveReducer, Scan2DaveState } from './reducers/scan2dave.reducer';
import { sepaFeatureKey, SepaReducer, SepaState } from './reducers/sepa.reducer';
import { sharedCommissionsFeatureKey, SharedCommissionsReducer, SharedCommissionsState } from './reducers/sharedCommissions.reducer';
import { StatusFromBackofficeFeatureKey, StatusFromBackofficeReducer, StatusFromBackofficeState } from './reducers/statusFromBackoffice.reducer';
import { StatusFromSVFeatureKey, StatusFromSVReducer, StatusFromSVState } from './reducers/statusFromSV.reducer';
import { tagReducer, TagState, TAG_FEATURE_KEY } from './reducers/tag.reducer';
import { textTemplateLanguageFeatureKey, textTemplateLanguageReducer, TextTemplateLanguageState } from './reducers/text-template-language.reducer';
import { textTemplatesReducer, TextTemplatesState, Text_Templates_Key } from './reducers/textTemplates.reducer';
import { transmissionFeatureKey, TransmissionReducer, TransmissionState } from './reducers/transmission.reducer';
import { userToCommissionReducer, UserToCommissionState, USER_TO_COMMISSION_KEY } from './reducers/user-to-commission.reducer';
import { userToCustomerFeatureKey, userToCustomerReducer, UserToCustomerState } from './reducers/user-to-customer.reducer';
import { userToEventReducer, UserToEventState, USER_TO_EVENT_KEY } from './reducers/user-to-event.reducer';
import { user2CommissionShiftKey, user2CommissionShiftReducer, User2commissionShiftState } from './reducers/user2CommissionShift.reducer';
import { usersFeatureKey, usersReducer, UsersState } from './reducers/users.reducer';
import { varianteTypesFeatureKey, VarianteTypesState, varianteTypesStateReducer } from './reducers/variante-types.reducer';
import { viewStyleSettingReducer, ViewStyleSettingState, VIEW_STYLE_SETTING_KEY } from './reducers/viewStyleSetting.reducer';
import { workDaysFeatureKey, WorkDaysState, workDaysStateReducer } from './reducers/work-day.reducer';
import { workedTimesFeatureKey, WorkedTimesState, workedTimesStateReducer } from './reducers/worked-times.reducer';
import { SURCHARGE_RATE_KEY, surchargeRateReducer, SurchargeRateState } from './reducers/surcharge-rate.reducer';
import {
    RESOURCE_TO_COMMISSION_KEY, resourceToCommissionReducer,
    ResourceToCommissionState,
} from './reducers/resource-dispo/resource-to-commission.reducer';
import { CHART_OF_ACCOUNT_KEY, chartOfAccountReducer, ChartOfAccountState } from './reducers/chartOfAccount.reducer';
import { MATERIAL_LIST_KEY, materialListsReducer, MaterialListState } from './reducers/material-list.reducer';
import { Material_KEY, MaterialsReducer, MaterialState } from './reducers/material.reducer';
import {
    MATERIAL_TO_COMMISSION_KEY,
    MaterialToCommissionReducer,
    MaterialToCommissionState,
} from './reducers/material-to-commission.reducer';
import { MATERIAL_GROUP_KEY, materialGroupReducer, MaterialGroupState } from './reducers/material-group.reducers';

export interface State {
    [FormDataKey]: FormDataState;
    [MANDATE_TYPE_KEY]: MandateTypeState;
    [customersFeatureKey]: CustomerState;
    [commissionsFeatureKey]: CommissionState;
    [CommissionTypesFeatureKey]: CommissionTypeState;
    [QMStatusFeatureKey]: QMStatusState;
    [StatusFromBackofficeFeatureKey]: StatusFromBackofficeState;
    [StatusFromSVFeatureKey]: StatusFromSVState;
    [damageFlowsFeatureKey]: DamageFlowState;
    [permissionsFeatureKey]: PermissionsState;
    [eventsFeatureKey]: EventsState;
    [usersFeatureKey]: UsersState;
    [varianteTypesFeatureKey]: VarianteTypesState;
    [conditionsFeatureKey]: ConditionsState;
    [employeesFeatureKey]: EmployeesState;
    [productsFeatureKey]: ProductsState;
    [partnersFeatureKey]: PartnersState;
    [licensesFeatureKey]: LicensesState;
    [FILES_FEATURE_KEY]: FileState;
    [scan2DaveFeatureKey]: Scan2DaveState;
    [documentDetectionFeatureKey]: DocumentDetectionState;
    [officeFeatureKey]: OfficeState;
    [emailConnectionFeatureKey]: EmailConnectionState;
    [baseFeatureKey]: BaseState;
    [transmissionFeatureKey]: TransmissionState;
    [emailSettingsTypeFeatureKey]: EmailSettingsTypeState;
    [emailConnection2EmailSettingsTypeFeatureKey]: EmailConnection2EmailSettingsTypeState;
    [sharedCommissionsFeatureKey]: SharedCommissionsState;
    [generatedDocumentsFeatureKey]: GeneratedDocumentState;
    [generatedDocumentsTypesFeatureKey]: GeneratedDocumentsTypeState;
    [personsFeatureKey]: PersonState;
    [sepaFeatureKey]: SepaState;
    [personTypesFeatureKey]: PersonTypeState;
    [complaintTypesFeatureKey]: ComplaintTypeState;
    [customerSpecificationTypesFeatureKey]: CustomerSpecificationTypeState;
    [damageFlowTypesFeatureKey]: DamageFlowTypeState;
    [emailFeatureKey]: EmailState;
    [accountingFeatureKey]: AccountingState;
    [emailFoldersFeatureKey]: EmailFolderState;
    [employeeToVacationFeatureKey]: EmployeeToVacationState;
    [user2CommissionShiftKey]: User2commissionShiftState;
    [person2EntityFeatureKey]: Person2EntityState;
    [VIEW_STYLE_SETTING_KEY]: ViewStyleSettingState;
    [CONSTRUCTION_DIARY_KEY]: ConstructionDiaryState;
    [AKKORD_KEY]: AkkordState;
    [AKKORD_TO_COMMISSION_KEY]: AkkordToCommissionState;
    [USER_TO_COMMISSION_KEY]: UserToCommissionState;
    [ENTITY_ROLE_KEY]: EntityRoleState;
    [Text_Templates_Key]: TextTemplatesState;
    [EMPLOYEE_TO_COMMISSION_KEY]: EmployeeToCommissionState;
    [BOOKMARK_KEY]: BookmarkState;
    [EVENT_TYPE_FEATURE_KEY]: EventTypeState;
    [FOLDER_FEATURE_KEY]: FolderState;
    [TAG_FEATURE_KEY]: TagState;
    [USER_TO_EVENT_KEY]: UserToEventState;
    [COMMENTS_FEATURE_KEY]: CommentState;
    [NEWS_KEY]: NewsState;
    [workDaysFeatureKey]: WorkDaysState;
    [clockInsFeatureKey]: ClockInsState;
    [textTemplateLanguageFeatureKey]: TextTemplateLanguageState;
    [userToCustomerFeatureKey]: UserToCustomerState;
    [BUSINESS_VOLUME_KEY]: BusinessVolumeState;
    [clockInTypesFeatureKey]: ClockInTypesState;
    [workedTimesFeatureKey]: WorkedTimesState;
    [JOB_SPECIFICATION_KEY]: JobSpecificationState;
    [EMPLOYEE_RESOURCE_SCHEDULE_KEY]: EmployeeResourceScheduleState;
    [RESOURCE_SCHEDULE_KEY]: ResourceScheduleState;
    [RESOURCE_KEY]: ResourceState;
    [RESOURCE_TYPE_KEY]: ResourceTypeState;
    [LEDGER_IMPORT_KEY]: LedgerImportState;
    [PUBLIC_FILE_KEY]: PublicFileState;
    [GROUP_KEY]: GroupState;
    [Milestone_KEY]: MilestoneState;
    [ACTIVITY_FEATURE_KEY]: ActivityState;
    [PROCESS_FEATURE_KEY]: ProcessState;
    [PROCESS_TEMPLATE_FEATURE_KEY]: ProcessTemplateState;
    [O_AUTH_TOKEN_KEY]: OAuthTokenState;
    [B_V_CUSTOM_NAME_FEATURE_KEY]: BVCustomNameState;
    [SALARY_TYPE_FEATURE_KEY]: SalaryTypeState;
    [EMPLOYEE_SALARY_TYPE_FEATURE_KEY]: EmployeeSalaryTypeState;
    [SURCHARGE_RATE_KEY]: SurchargeRateState;
    [RESOURCE_TO_COMMISSION_KEY]:ResourceToCommissionState;
    [MATERIAL_TO_COMMISSION_KEY]:MaterialToCommissionState;
    [CHART_OF_ACCOUNT_KEY]: ChartOfAccountState;
    [Material_KEY]: MaterialState;
    [MATERIAL_LIST_KEY]: MaterialListState;
    [MATERIAL_GROUP_KEY]:MaterialGroupState
}
export const reducers: ActionReducerMap<State> = {
    [FormDataKey]: formDataReducer,
    [MANDATE_TYPE_KEY]: mandateTypeReducer,
    [customersFeatureKey]: customerReducer,
    [commissionsFeatureKey]: commissionReducer,
    [CommissionTypesFeatureKey]: CommissionTypeReducer,
    [QMStatusFeatureKey]: QMStatusReducer,
    [StatusFromBackofficeFeatureKey]: StatusFromBackofficeReducer,
    [StatusFromSVFeatureKey]: StatusFromSVReducer,
    [damageFlowsFeatureKey]: damageFlowReducer,
    [permissionsFeatureKey]: permissionsReducer,
    [eventsFeatureKey]: eventsReducer,
    [usersFeatureKey]: usersReducer,
    [varianteTypesFeatureKey]: varianteTypesStateReducer,
    [conditionsFeatureKey]: conditionsStateReducer,
    [employeesFeatureKey]: employeesStateReducer,
    [productsFeatureKey]: productsReducer,
    [partnersFeatureKey]: partnersReducer,
    [licensesFeatureKey]: licensesReducer,
    [FILES_FEATURE_KEY]: filesReducer,
    [scan2DaveFeatureKey]: scan2DaveReducer,
    [documentDetectionFeatureKey]: documentDetectionReducer,
    [officeFeatureKey]: officeReducer,
    [emailConnectionFeatureKey]: emailConnectionReducer,
    [baseFeatureKey]: baseReducer,
    [transmissionFeatureKey]: TransmissionReducer,
    [emailSettingsTypeFeatureKey]: EmailSettingsTypesReducer,
    [emailConnection2EmailSettingsTypeFeatureKey]: EmailConnection2EmailSettingsTypesReducer,
    [sharedCommissionsFeatureKey]: SharedCommissionsReducer,
    [generatedDocumentsFeatureKey]: GeneratedDocumentsReducer,
    [generatedDocumentsTypesFeatureKey]: GeneratedDocumentsTypeReducer,
    [personsFeatureKey]: PersonReducer,
    [sepaFeatureKey]: SepaReducer,
    [personTypesFeatureKey]: PersonTypeReducer,
    [complaintTypesFeatureKey]: ComplaintTypeReducer,
    [customerSpecificationTypesFeatureKey]: CustomerSpecificationTypeReducer,
    [damageFlowTypesFeatureKey]: DamageFlowTypeReducer,
    [emailFeatureKey]: EmailReducer,
    [accountingFeatureKey]: accountingReducer,
    [emailFoldersFeatureKey]: EmailFolderReducer,
    [employeeToVacationFeatureKey]: employeeToVacationStateReducer,
    [user2CommissionShiftKey]: user2CommissionShiftReducer,
    [person2EntityFeatureKey]: person2EntityReducer,
    [VIEW_STYLE_SETTING_KEY]: viewStyleSettingReducer,
    [CONSTRUCTION_DIARY_KEY]: constructionDiaryReducer,
    [AKKORD_KEY]: akkordReducer,
    [AKKORD_TO_COMMISSION_KEY]: akkordToCommissionReducer,
    [USER_TO_COMMISSION_KEY]: userToCommissionReducer,
    [ENTITY_ROLE_KEY]: entityRoleReducer,
    [Text_Templates_Key]: textTemplatesReducer,
    [EMPLOYEE_TO_COMMISSION_KEY]: employeeToCommissionReducer,
    [BOOKMARK_KEY]: bookmarkReducer,
    [EVENT_TYPE_FEATURE_KEY]: eventTypesReducer,
    [FOLDER_FEATURE_KEY]: folderReducer,
    [TAG_FEATURE_KEY]: tagReducer,
    [USER_TO_EVENT_KEY]: userToEventReducer,
    [COMMENTS_FEATURE_KEY]: commentReducer,
    [NEWS_KEY]: newsReducer,
    [workDaysFeatureKey]: workDaysStateReducer,
    [clockInsFeatureKey]: clockInsStateReducer,
    [textTemplateLanguageFeatureKey]: textTemplateLanguageReducer,
    [userToCustomerFeatureKey]: userToCustomerReducer,
    [BUSINESS_VOLUME_KEY]: businessVolumeReducer,
    [clockInTypesFeatureKey]: clockInTypesStateReducer,
    [workedTimesFeatureKey]: workedTimesStateReducer,
    [JOB_SPECIFICATION_KEY]: jobSpecificationReducer,
    [EMPLOYEE_RESOURCE_SCHEDULE_KEY]: employeeResourceSchedulesReducer,
    [RESOURCE_SCHEDULE_KEY]: resourceSchedulesReducer,
    [RESOURCE_KEY]: resourcesReducer,
    [RESOURCE_TYPE_KEY]: resourceTypeReducer,
    [LEDGER_IMPORT_KEY]: ledgerImportReducer,
    [PUBLIC_FILE_KEY]: publicFileReducer,
    [GROUP_KEY]: groupsReducer,
    [Milestone_KEY]: milestonesReducer,
    [ACTIVITY_FEATURE_KEY]: activityReducer,
    [PROCESS_FEATURE_KEY]: processReducer,
    [PROCESS_TEMPLATE_FEATURE_KEY]: processTemplateReducer,
    [O_AUTH_TOKEN_KEY]: oAuthTokenReducer,
    [B_V_CUSTOM_NAME_FEATURE_KEY]: bVCustomNameReducer,
    [SALARY_TYPE_FEATURE_KEY]: salaryTypeReducer,
    [EMPLOYEE_SALARY_TYPE_FEATURE_KEY]: employeeSalaryTypeReducer,
    [SURCHARGE_RATE_KEY]: surchargeRateReducer,
    [RESOURCE_TO_COMMISSION_KEY]: resourceToCommissionReducer,
    [MATERIAL_TO_COMMISSION_KEY]: MaterialToCommissionReducer,
    [CHART_OF_ACCOUNT_KEY]: chartOfAccountReducer,
    [Material_KEY]: MaterialsReducer,
    [MATERIAL_LIST_KEY]: materialListsReducer,
    [MATERIAL_GROUP_KEY]:materialGroupReducer
};
