// tslint:disable: naming-convention
import { Action } from '@ngrx/store';
import { Moment } from "moment";

export enum DetailEventActionTypes {
    SetEventTypeId = '[DetailEvent] SetEventTypeId',
    SetContactPersonId = '[DetailEvent] SetContactPersonId',
    SetDate = '[DetailEvent] SetDate',
    SetEndDate = '[DetailEndEvent] SetEndDate',
    SetStreet = '[DetailEvent] SetStreet',
    SetPostalCode = '[DetailEvent] SetPostalCode',
    SetCity = '[DetailEvent] SetCity',
    SetCountry = '[DetailEvent] SetCountry',
    SetStartTime = '[DetailEvent] SetStartTime',
    SetEndTime = '[DetailEvent] SetEndTime',
    SetNote = '[DetailEvent] SetNote',
    SetFiles = '[DetailEvent] SetFiles',
    SetEditing = '[DetailEvent] SetEditing',
    SetPrivate = '[DetailEvent] SetPrivate',
    SetCommission = '[DetailEvent] SetCommission',
    SetCustomerId = '[DetailEvent] SetCustomerId',
    ClearStore = '[DetailEvent] ClearStore',
}

export class DetailEventSetEventTypeId implements Action {
    readonly type = DetailEventActionTypes.SetEventTypeId;

    constructor(public Payload: { EventTypeId: number }) {}
}

// nicht benutzt
export class DetailEventSetContactPersonId implements Action {
    readonly type = DetailEventActionTypes.SetContactPersonId;

    constructor(public Payload: { PersonId: number }) {}
}

export class DetailEventSetDate implements Action {
    readonly type = DetailEventActionTypes.SetDate;

    constructor(public Payload: { Date: Moment }) {}
}

export class DetailEventSetEndDate implements Action {
    readonly type = DetailEventActionTypes.SetEndDate;

    constructor(public Payload: { EndDate: Moment }) {}
}

export class DetailEventSetStreet implements Action {
    readonly type = DetailEventActionTypes.SetStreet;

    constructor(public Payload: { Street: string }) {}
}

export class DetailEventSetCity implements Action {
    readonly type = DetailEventActionTypes.SetCity;

    constructor(public Payload: { City: string }) {}
}

export class DetailEventSetCountry implements Action {
    readonly type = DetailEventActionTypes.SetCountry;

    constructor(public Payload: { Country: string }) {}
}

export class DetailEventSetPostalCode implements Action {
    readonly type = DetailEventActionTypes.SetPostalCode;

    constructor(public Payload: { PostalCode: string }) {}
}

export class DetailEventSetStartTime implements Action {
    readonly type = DetailEventActionTypes.SetStartTime;

    constructor(public Payload: { StartTime: string }) {}
}

export class DetailEventSetEndTime implements Action {
    readonly type = DetailEventActionTypes.SetEndTime;

    constructor(public Payload: { EndTime: string }) {}
}

export class DetailEventSetNote implements Action {
    readonly type = DetailEventActionTypes.SetNote;

    constructor(public Payload: { Note: string }) {}
}

export class DetailEventSetCommission implements Action {
    readonly type = DetailEventActionTypes.SetCommission;

    constructor(public Payload: { CommissionId: number }) {}
}
export class DetailEventSetCustomerId implements Action {
    readonly type = DetailEventActionTypes.SetCustomerId;

    constructor(public Payload: { CustomerId: number }) {}
}

// nicht benutzt
export class DetailEventSetFiles implements Action {
    readonly type = DetailEventActionTypes.SetFiles;

    constructor(public Payload: { Files: unknown }) {}
}

export class DetailEventSetEditing implements Action {
    readonly type = DetailEventActionTypes.SetEditing;

    constructor(public Payload: { Editing: boolean }) {}
}

export class DetailEventSetPrivate implements Action {
    readonly type = DetailEventActionTypes.SetPrivate;

    constructor(public Payload: { Private: boolean }) {}
}

export class DetailEventClearStore implements Action {
    readonly type = DetailEventActionTypes.ClearStore;
}

export type DetailEventActions =
    | DetailEventSetEventTypeId
    | DetailEventSetContactPersonId
    | DetailEventSetDate
    | DetailEventSetEndDate
    | DetailEventSetCity
    | DetailEventSetCountry
    | DetailEventSetStreet
    | DetailEventSetPostalCode
    | DetailEventSetStartTime
    | DetailEventSetEndTime
    | DetailEventSetNote
    | DetailEventSetFiles
    | DetailEventSetEditing
    | DetailEventSetPrivate
    | DetailEventSetCommission
    | DetailEventSetCustomerId
    | DetailEventClearStore;
