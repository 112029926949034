import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CommissionEntity } from '../../entities/commission.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { CommissionActionTypes } from '../actions/commission.actions';

export const commissionsFeatureKey = 'commissions';

export interface CommissionState extends EntityState<CommissionEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<CommissionEntity>({
    selectId: Entity => Entity.Id,
});

const initialState: CommissionState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const commissionReducer = createReducer(
    initialState,
    on(CommissionActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(CommissionActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, { ...state, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(CommissionActionTypes.UpdateOne, (state, { Payload }) =>
        adapter.setOne(Payload, state),
    ),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllCommission = selectAll;
export const selectCommissionEntities = selectEntities;

export const selectCommissionFetched = (state: CommissionState) => state.fetched;
export const selectCommissionLatestUpdatedAt = (state: CommissionState) => state.latestUpdatedAt;
