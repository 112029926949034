<div class="wrapper mat-elevation-z8">
    <div class="available-types mat-elevation-z4">
        <mat-chip-list>
            <mat-chip *ngFor="let type of AvailableTypes$ | async" [selected]="type.selected" (click)="ToggleResultType($event, type.type)">
                <fa-icon [icon]="type.icon"></fa-icon>
                {{ type.name }}
            </mat-chip>
        </mat-chip-list>
    </div>
    <div class="results" *ngIf="(displayResultsFiltered$ | async).length != 0">
        <ng-container *ngFor="let ret of displayResultsFiltered$ | async">
            <a *ngIf="ret.data.route" class="data {{ ret.data.theme }} theme-override" role="button" [routerLink]="ret.data.route" [queryParams]="ret.data.queryParams">
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            </a>
            <div *ngIf="!ret.data.route && ret.data.onClick" (click)="ret.data.onClick()" class="data {{ ret.data.theme }} theme-override">
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            </div>
            <ng-template #content>
                <div class="icon-wrapper app-background-primary">
                    <fa-icon [icon]="ret.data.icon"></fa-icon>
                </div>
                <span class="result-text">
                    <ng-container *ngFor="let p of ret.data.text">
                        <span [innerHTML]="p"></span>
                    </ng-container>
                </span>
            </ng-template>
        </ng-container>
    </div>

    <ng-container *ngIf="Returns.TotalResults === 0">
        <div class="data">
            <fa-icon icon="search"></fa-icon>
            <a>Keine Daten gefunden...</a>
        </div>
    </ng-container>
</div>
