import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {DaveActions} from '../actions/actions';
import {HttpService} from '../../services/http.service';
import {State} from '../index';
import {BaseActionTypes} from '../actions/base.actions';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {TransmissionActionTypes} from '../actions/transmission.actions';
import {TransmissionEntity, TransmissionEntityFromBackend} from '../../entities/transmission.entity';
import {stringifyIfNotNullOrUndefined} from '../../../helper/helper';

enum ErrorCodes {
    Add = 'Übertragung Hinzufügen fehlgeschlagen',
    Delete = 'Übertragung Löschen fehlgeschlagen',
    Load = 'Übertragungen Abrufen fehlgeschlagen',
    Change = 'Übertragunge Ändern fehlgeschlagen',
}

@Injectable()
export class TransmissionEffects {

    AddTranmission$ = createEffect(() => this.actions$.pipe(
        ofType(TransmissionActionTypes.AddTransmission),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
mutation{
  createTransmission(
    ${stringifyIfNotNullOrUndefined(action.Payload, 'name')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'customerId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'commissionId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'eventId')}
  ){
    ${TransmissionEntity.GQLFields}
  }
}`;

            return this.gatewayHttpService
                .graphQl({query: queryString}, {token: store.base.token})
                .pipe(
                    map(res => {
                        return res && res.createTransmission
                            ? TransmissionActionTypes.UpdateTransmission({
                                Payload: [
                                    ...(store.transmission.transmission ? store.transmission.transmission : []),
                                    TransmissionEntityFromBackend(res.createTransmission)
                                ]
                            })
                            : BaseActionTypes.ErrorAction({
                                Payload: {ToasterMessage: ErrorCodes.Add}
                            });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught
                                }
                            })
                        )
                    )
                );
        })
    ));


    LoadTransmissions = createEffect(() => this.actions$.pipe(
        ofType(TransmissionActionTypes.GetTransmissionRequest),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `query{
  transmission{
    ${TransmissionEntity.GQLFields}
  }
}`;
            return this.gatewayHttpService
                .graphQl({query: queryString}, {token: store.base.token, retry: true })
                .pipe(
                    map(res =>
                        res && res.transmission
                            ? TransmissionActionTypes.UpdateTransmission({
                                Payload: res.transmission.map(t => TransmissionEntityFromBackend(t))
                            })
                            : BaseActionTypes.ErrorAction({
                                Payload: {ToasterMessage: ErrorCodes.Load}
                            })
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught
                                }
                            })
                        )
                    )
                );
        })
    ));


    DeleteTranmission$ = createEffect(() => this.actions$.pipe(
        ofType(TransmissionActionTypes.DeleteTransmission),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
mutation{
  deleteTransmission(id: ${JSON.stringify(action.Payload.id)})
}`;

            return this.gatewayHttpService
                .graphQl({query: queryString}, {token: store.base.token})
                .pipe(
                    map(res => {
                        return res && res.deleteTransmission
                            ? TransmissionActionTypes.UpdateTransmission({
                                Payload: store.transmission.transmission.filter(t => t.Id != action.Payload.id)
                            })
                            : BaseActionTypes.ErrorAction({
                                Payload: {ToasterMessage: ErrorCodes.Delete}
                            });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Delete,
                                    Err: err,
                                    Caught: caught
                                }
                            })
                        )
                    )
                );
        })
    ));


    ChangeTranmission$ = createEffect(() => this.actions$.pipe(
        ofType(TransmissionActionTypes.ChangeTransmission),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            let params = [
                `id: ${JSON.stringify(action.Payload.id)}`
            ];

            if(action.Payload.name !== undefined) {
                params.push(`name: ${JSON.stringify(action.Payload.name)}`)
            }

            if(action.Payload.customerId !== undefined) {
                params.push(`customerId: ${JSON.stringify(action.Payload.customerId)}`)
            }

            if(action.Payload.commissionId !== undefined) {
                params.push(`commissionId: ${JSON.stringify(action.Payload.commissionId)}`)
            }

            const queryString = `
mutation{
  changeTransmission(${params.join(', ')}){
    ${TransmissionEntity.GQLFields}
  }
}`;

            return this.gatewayHttpService
                .graphQl({query: queryString}, {token: store.base.token})
                .pipe(
                    map(res => {
                        return res && res.changeTransmission
                            ? TransmissionActionTypes.UpdateTransmission({
                                Payload: store.transmission.transmission.map(t => t.Id == action.Payload.id ? TransmissionEntityFromBackend(res.changeTransmission) : t)
                            })
                            : BaseActionTypes.ErrorAction({
                                Payload: {ToasterMessage: ErrorCodes.Change}
                            });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Change,
                                    Err: err,
                                    Caught: caught
                                }
                            })
                        )
                    )
                );
        })
    ));

    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService
    ) {
    }
}
