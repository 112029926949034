import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink,RouterLinkWithHref } from "@angular/router";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { SimplebarAngularModule } from 'simplebar-angular';
import {
EventTypesSelectorCardComponent
} from '../components/templates/event-types-selector-card/event-types-selector-card.component';
import { CustomFormsModule } from '../custom-forms-module/custom-forms.module';
import { DaveCommentsModule } from '../dave-comments/dave-comments.module';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveFileExplorerModule } from '../dave-file-explorer/dave-file-explorer.module';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import {
DropzoneComponent
} from "../dave-utils-module/dave-shared-components-module/components/dropzone/dropzone.component";
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { IconPipeModule } from '../dave-utils-module/icon-pipe/icon-pipe.module';
import { SelectEventTypeComponent } from "../select-event-type/select-event-type.component";
import { SelectEventTypeDirective } from "../select-event-type/select-event-type.directive";
import { SmallFileCardModule } from '../small-file-card/small-file-card.module';
import { DetailTasksComponent } from './components/detail-tasks/detail-tasks.component';
import { EventCardComponent } from './components/event-card/event-card.component';


@NgModule({
    declarations: [EventCardComponent, DetailTasksComponent],
    imports: [
        CommonModule,
        MatExpansionModule,
        AppButtonModule,
        MatTooltipModule,
        MatListModule,
        FontAwesomeModule,
        MatChipsModule,
        IconPipeModule,
        DaveSharedComponentsModule,
        DropzoneModule,
        MatDialogModule,
        ReactiveFormsModule,
        SimplebarAngularModule,
        MatCardModule,
        DaveDoubleIconModule,
        MatFormFieldModule,
        DaveLoadingModule,
        SmallFileCardModule,
        DaveFileExplorerModule,
        MatTabsModule,
        DaveCommentsModule,
        CustomFormsModule,
        MatCheckboxModule,
        FormsModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        EventTypesSelectorCardComponent,
        SelectEventTypeComponent,
        SelectEventTypeDirective,
        DropzoneComponent,
        RouterLink,
        RouterLinkWithHref,
        DragDropModule,
    ],
    exports: [EventCardComponent, DetailTasksComponent],
})
export class DaveEventCardModule {}
