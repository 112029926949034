<div class="button-wrapper">
    <button app-round-button mat-dialog-close><fa-icon icon="chevron-right"></fa-icon></button>
    <button app-round-button *ngIf="!Editing" (click)="Editing = true"><fa-icon icon="pencil-alt"></fa-icon></button>
    <button app-round-button *ngIf="Editing" (click)="resourceData.SaveForm()"><fa-icon icon="save"></fa-icon></button>
    <button app-round-button *ngIf="Editing" (click)="resourceData.ResetForm(); Editing = false"><fa-icon icon="undo"></fa-icon></button>
</div>
<div class="scrollbar" >
    <app-resource-main-data #resourceData [FolderId]="FolderId$ | async" [(Editing)]="Editing" [ResourceId]="Dialogdata.ResourceId"></app-resource-main-data>
    <app-file-explorer [FileUploadButton]="true" *ngIf="FolderId$ | async as folderId" [SelectedFolderId]="folderId"></app-file-explorer>
</div>
