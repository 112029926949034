import { Component, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogConfig } from "@angular/material/dialog";
import { appMatDialogDefaultConfig } from '../../../helper/helper';

export type EmailEditorCloseDialogComponentReturn = 'save' | 'revert' | undefined;
export type EmailEditorCloseDialogComponentDialogData = {
    emailIsNew: boolean;
};
enum paragraph {
    new = 'Möchten Sie die E-Mail im Entwürfe Ordner speichern?',
    existing = 'Möchten Sie die Änderungen an der E-Mail speichern?',
}
@Component({
    selector: 'app-email-editor-close-dialog',
    templateUrl: './email-editor-close-dialog.component.html',
    styleUrls: ['./email-editor-close-dialog.component.scss'],
})
export class EmailEditorCloseDialogComponent {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
    };
    paragraph = paragraph.new;

    @Input() set emailIsNew(v: boolean) {
        if (v) {
            this.paragraph = paragraph.new;
        } else {
            this.paragraph = paragraph.existing;
        }
    }
    constructor(@Inject(MAT_DIALOG_DATA) data: EmailEditorCloseDialogComponentDialogData) {
        if (data) {
            this.emailIsNew = data.emailIsNew;
        }
    }
}
