import { createEffect, ofType, Actions } from "@ngrx/effects";
import {
  withLatestFrom,
  switchMap,
  map,
  catchError,
} from "rxjs/operators";
import { Store } from "@ngrx/store";
import { DaveActions } from "../actions/actions";
import { HttpService } from "../../services/http.service";
import { State } from "../index";
import { CustomerSpecificationTypeActionTypes } from "../actions/customerSpecificationType.actions";
import { CustomerSpecificationTypeEntityFromBackend } from "../../entities/customerSpecificationType.entity";
import { BaseActionTypes } from "../actions/base.actions";
import { of } from "rxjs";
import { Injectable } from "@angular/core";

enum ErrorCodes {
  Load = "Auftragstypen Abrufen fehlgeschlagen",
  Modify = "Auftragstypen Bearbeiten fehlgeschlagen"
}

@Injectable()
export class CustomerSpecificationTypeEffects {

  LoadCustomerSpecificationTypes = createEffect(() => this.actions$.pipe(
    ofType(CustomerSpecificationTypeActionTypes.GetCustomerSpecificationTypeRequest),
    withLatestFrom(this.store$),
    switchMap(([action, store]) => {
      const queryString = `
      query{
        customerspecificationtype{
          id
          name
          partnerId
        }
      }`;
      return this.gatewayHttpService
        .graphQl({ query: queryString }, { token: store.base.token, retry: true })
        .pipe(
          map(res =>
            res && res.customerspecificationtype
              ? CustomerSpecificationTypeActionTypes.UpdateCustomerSpecificationType({
                  Payload: res.customerspecificationtype.map(val =>
                    CustomerSpecificationTypeEntityFromBackend(val)
                  )
                })
              : BaseActionTypes.ErrorAction({
                  Payload: { ToasterMessage: ErrorCodes.Load }
                })
          ),
          catchError((err, caught) =>
            of(
              BaseActionTypes.ErrorAction({
                Payload: {
                  ToasterMessage: ErrorCodes.Load,
                  Err: err,
                  Caught: caught
                }
              })
            )
          )
        );
    })
  ));
  constructor(
    private actions$: Actions<DaveActions>,
    private store$: Store<State>,
    private gatewayHttpService: HttpService
  ) {}
}
