import { createAction,props } from '@ngrx/store';
import {
    DaveMutationChangeMilestoneArgs,
    DaveMutationCreateMilestoneArgs, 
    DaveMutationDeleteMilestoneArgs,    
    QueryMilestoneArgs,
} from '../../graphql-types';
import { MilestoneEntity } from '../../entities/milestone.entity';

const prefix = 'data/' + 'milestone/';

export const MilestoneActionTypes = {
    Load: createAction(prefix + 'load', props<{ Payload?: QueryMilestoneArgs }>()),
    Create: createAction(prefix + 'create', props<{ Payload: DaveMutationCreateMilestoneArgs }>()),
    Change: createAction(prefix + 'change', props<{ Payload: DaveMutationChangeMilestoneArgs }>()),
    Delete: createAction(prefix + 'delete', props<{ Payload: DaveMutationDeleteMilestoneArgs }>()),

    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<MilestoneEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<MilestoneEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction(prefix + 'updateOne', props<{ Payload: MilestoneEntity }>()),
    RemoveOne: createAction(prefix + 'removeOne', props<{ Payload: number }>()),
};

