import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    clockInsFeatureKey,
    ClockInsState, selectAllClockIn,
    selectClockInEntities,
    selectClockInFetched,
    selectClockInLatestUpdatedAt,
} from '../reducers/clock-in.reducer';
import { State } from '../index';

const selectClockIn = createFeatureSelector< ClockInsState>(clockInsFeatureKey);
const getClockInEntitys = createSelector(selectClockIn, selectClockInEntities);

export const getClockIns = createSelector(selectClockIn, selectAllClockIn);
export const getClockInsWithoutDeleted = createSelector(getClockIns, (clockIns) => clockIns?.filter(c => !c.DeletedAt));
export const getClockInsFetched = createSelector(selectClockIn, selectClockInFetched);
export const getClockInLatestUpdatedAt = createSelector(selectClockIn, selectClockInLatestUpdatedAt);
export const getClockInById = (props: { id: number }) => createSelector(getClockInEntitys, (dictionary) => props.id && dictionary[props.id]);
