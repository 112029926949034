import { SepaType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export class SepaEntity {
    constructor(
        public Id: number = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public Faelligkeitsdatum: Date = null,
        public Mandatsreferenz: string = null,
        public Iban: string = null,
        public BIC: string = null,
        public Bankname: string = null,
        public UserId: number = null,
        public PartnerId: number = null
    ) {}

    public Clone(override: SepaEntityAttributes = {}): SepaEntity {
        const attr = {...this, ...override};
        return Object.assign(new SepaEntity(), attr);
    }
}

export function SepaEntityFromBackend(res: SepaType): SepaEntity {
    return new SepaEntity(
            res.id,
            res.createdAt && BackendDate(res.createdAt),
            res.updatedAt && BackendDate(res.updatedAt),
            res.faelligkeitsdatum && BackendDate(res.faelligkeitsdatum),
            res.mandatsreferenz,
            res.iban,
            res.bic,
            res.bankname,
            res.userId,
            res.partnerId,
    );
                                }

export interface SepaEntityAttributes {
        Id?: number;
        CreatedAt?: Date;
        UpdatedAt?: Date;
        Faelligkeitsdatum?: Date;
        Mandatsreferenz?: string;
        Iban?: string;
        BIC?: string;
        Bankname?: string;
        UserId?: number;
        PartnerId?: number;
}
