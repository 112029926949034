import { ViewStyleSettingType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export enum ViewStyleSettingViewEnum {
    Commission = 'COMMISSION',
    Employee = 'EMPLOYEE',
    Person = 'PERSON',
}
export interface ViewStyleConfig {
    type: string,
    // entity?: any,
    order: number,
    headline?: string,
    headlineIcon?: IconProp,
    elements?: ViewStyleConfig[],                                  //wir benötigen gruppen, dafür ein Key "elements" hinzufügen und den type "group" setzen
    expanded: boolean,                                          // wir benötigen einen expanded key der sagt ob das element eingeklappt oder ausgeklappt ist
    // size?: {
    //     xs: {
    //         width: 12,
    //         heiht: 12,
    //     },
    //     sm: {
    //         width: 12,
    //         heiht: 12,
    //     }
    //.... md,lg,xl,xxl
    // }
    right?: boolean,
}
export class ViewStyleSettingEntity {
    constructor(
        public Config: {config: ViewStyleConfig[]} = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public EntityId: number = null,
        public EntityTypeId: number = null,
        public Id: number = null,
        public PartnerId: number = null,
        public UpdatedAt: Date = null,
        public UserId: number = null,
        public UserTypeId: number = null,
        public View: ViewStyleSettingViewEnum = null,
    ) {}
    public static readonly GqlFields = `config
    createdAt
    deletedAt
    entityId
    entityTypeId
    id
    partnerId
    updatedAt
    userId
    userTypeId
    view`;
    public Clone(override: ViewStyleSettingAttributes = {}): ViewStyleSettingEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ViewStyleSettingEntity(), attr);
    }
}

export function ViewStyleSettingEntityFromBackend(res: ViewStyleSettingType): ViewStyleSettingEntity {
    return new ViewStyleSettingEntity(
        res.config as any,
        res.createdAt && BackendDate(res.createdAt),
        res.deletedAt && BackendDate(res.deletedAt),
        res.entityId,
        res.entityTypeId,
        res.id,
        res.partnerId,
        res.updatedAt && BackendDate(res.updatedAt),
        res.userId,
        res.userTypeId,
        res.view,
    );
}

export interface ViewStyleSettingAttributes {
    Config?: string;
    CreatedAt?: Date;
    DeletedAt?: Date;
    EntityId?: number;
    EntityTypeId?: number;
    Id?: number;
    PartnerId?: number;
    UpdatedAt?: Date;
    UserId?: number;
    UserTypeId?: number;
    View?: ViewStyleSettingViewEnum;
}
