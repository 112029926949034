import { createAction, props } from '@ngrx/store';
import { BookmarkEntity } from '../../entities/bookmark.entity';

export const BookmarkActionTypes = {
    UpdateBookmarks: createAction('data/Bookmarks/update', props<{ NewBookmarks: Array<BookmarkEntity> }>()),
    UpdateFetched: createAction('data/Bookmarks/updateFetched', props<{ Date: Date }>()),
    AddBookmark: createAction('data/Bookmarks/add', props<{ EventId: number }>()),
    RemoveBookmark: createAction('data/Bookmarks/del', props<{ BookmarkIds: number[] }>()),
    RemoveMany: createAction('data/Bookmarks/remove', props<{ Payload: number[] }>()),
    SetOne: createAction('data/Bookmarks/setOne', props<{ Payload: BookmarkEntity }>()),
};
