import { MaterialGroupType } from '@dave/types/dist/proto/erp/materialGroupType';
import { GetTimeFromTimestamp } from '@dave/types';

export class MaterialGroupEntity {
    constructor(
        public Id: number = null,
        public Name: string = null,
        public ParentId: number = null,
        public PartnerId: number = null,
        public HasMaterial: boolean = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
    ) { }
    public static EntityName = 'MaterialGroup';

    public Clone(override: Partial<MaterialGroupEntity> = {}): MaterialGroupEntity {
        const attr = { ...this, ...override };
        return Object.assign(new MaterialGroupEntity(), attr);
    }
}
export function MaterialGroupEntityFromBackend(res: MaterialGroupType): MaterialGroupEntity {
    return new MaterialGroupEntity(
        res.Id && +res.Id,
        res.Name,
        res.ParentId && +res.ParentId,
        res.PartnerId && +res.PartnerId,
        res.HasMaterial,
        res.CreatedAt && GetTimeFromTimestamp(+res.CreatedAt),
        res.UpdatedAt && GetTimeFromTimestamp(+res.UpdatedAt),
        res.DeletedAt && GetTimeFromTimestamp(+res.DeletedAt)
    );
}
