import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AccountsReceivableLedgerEntity } from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { QuantityTypeEntity } from '../../../dave-data-module/entities/quantity-type.entity';
import { State } from '../../../dave-data-module/State';
import { getQuantityTypes } from '../../../dave-data-module/State/selectors/accounting.selector';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { AddAncillaryCostsComponent } from '../add-ancillary-costs/add-ancillary-costs.component';

export interface AncillaryData {
    SortedBookingPositionList: AccountsReceivableLedgerEntity[];
    InfoiceFormGroup: any;
    LedgerImportId: number;
}

interface Base64Logo {
    base64: string;
    width: number;
    height: number;
}

@Component({
    selector: 'app-ancillary-costs',
    templateUrl: './ancillary-costs.component.html',
    styleUrls: ['./ancillary-costs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AncillaryCostsComponent implements OnInit, AfterViewChecked {
    constructor(
        @Inject(MAT_DIALOG_DATA) public Data: AncillaryData,
        private fb: UntypedFormBuilder,
        private store: Store<State>,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
    ) {}

    @ViewChild(AddAncillaryCostsComponent) ancillaryChild;

    public SortedBookingPositionList: AccountsReceivableLedgerEntity[] = null;
    public LedgerImportId = null;
    public SelectedPositions = [];
    public QuantityTypes$ = this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined));
    public Save$ = new Subject<void>();
    public SelectedTemplateId$ = new Subject<number>();
    public AutoColumns = ['BookingText', 'Information'];
    public DisplayedColumns = [
        'Checkbox',
        'Position',
        'BookingText',
        'Information',
        'Quantity',
        'QuantityType',
        'BaseCost',
        'CompleteCost',
    ];
    public AutoColumnHeaders = {
        BookingText: 'Art-Nr.',
        Information: 'Bezeichnung',
        BaseCost: 'Kosten',
        Quantity: 'Menge',
        CompleteCost: 'Summe',
        Position: 'Pos.',
    };
    public InfoiceFormGroup = null;

    ngOnInit(): void {
        this.InfoiceFormGroup = this.Data.InfoiceFormGroup;
        this.SortedBookingPositionList = this.Data.SortedBookingPositionList;
        this.LedgerImportId = this.Data.LedgerImportId;
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }

    SelectAncillary(isChecked, rowdata) {
        if (isChecked.checked) {
            this.SelectedPositions.push({
                pos: this.GetPosition(rowdata),
                sum: rowdata.Amount,
                sumNetto: rowdata.Quantity * rowdata.BaseCost,
            });
        } else {
            this.SelectedPositions = this.SelectedPositions.filter(item => {
                return item.pos !== this.GetPosition(rowdata);
            });
        }
    }

    GetQuantityTypeById(id: number, quantityTypes: QuantityTypeEntity[]) {
        return quantityTypes?.find(q => q.Id === id)?.Name;
    }

    GetPosition(x: AccountsReceivableLedgerEntity) {
        const index = this.InfoiceFormGroup.value.BookingPositions?.map(bp => bp?.Id).indexOf(x.Id);
        return index > -1 ? index + 1 : '';
    }
}
