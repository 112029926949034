import { Component,ElementRef,EventEmitter,Input,OnDestroy,OnInit,Output,ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu,MatMenuTrigger } from '@angular/material/menu';
import { IconProp,Transform } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import {
ActionEventArgs,
DragAndDropService,
DragEventArgs,EventClickArgs,
EventRenderedArgs,
EventSettingsModel,
GroupModel,
PopupOpenEventArgs,
ResizeEventArgs,
ResizeService,
TimelineMonthService,
TimelineViewsService,
TimelineYearService,
ToolbarActionArgs
} from '@syncfusion/ej2-angular-schedule';
import { loadCldr } from '@syncfusion/ej2-base';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject,combineLatest,firstValueFrom,interval,Observable,of,Subject,switchMap,takeWhile,tap } from 'rxjs';
import {
debounceTime,
distinctUntilChanged,
filter,
first,
map,
shareReplay,
skip,
take,
takeUntil
} from "rxjs/operators";

import { isArray } from "chart.js/helpers";
import { AppDialogService } from 'src/app/dave-utils-module/app-dialog-module/app-dialog.service';
import * as n4 from '../../../../../node_modules/cldr-data/main/de/ca-gregorian.json';
import * as n1 from '../../../../../node_modules/cldr-data/main/de/currencies.json';
import * as n3 from '../../../../../node_modules/cldr-data/main/de/numbers.json';
import * as n2 from '../../../../../node_modules/cldr-data/main/de/timeZoneNames.json';
import * as s from '../../../../../node_modules/cldr-data/supplemental/currencyData.json';
import * as s2 from '../../../../../node_modules/cldr-data/supplemental/numberingSystems.json';
import { SelectUserCommissionLegacyComponent,SelectUserCommissionLegacyComponentDialogData } from '../../../dave-commission-module/components/detail-commission/select-user/select-user-commission-legacy.component';
import {
ColorEntity,ColorEntityFromString
} from '../../../dave-data-module/entities/color.entity';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { EmployeeToVacationEntity,VacationStatusEnum,VacationTypeEnum,VacationTypeEnumNameMap } from '../../../dave-data-module/entities/employee-to-vacation.entity';
import {
EmployeeEntity,
EmployeeStatusEnum,
EmployeeStatusNames
} from "../../../dave-data-module/entities/employee.entity";
import { User2CommissionShiftEntity } from '../../../dave-data-module/entities/user2CommissionShift.entity';
import { JobSpecificationResolver } from '../../../dave-data-module/guards/job-specification.resolver';
import { FrontendDateTimestamp } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { EmployeeToVacationActionTypes } from '../../../dave-data-module/State/actions/employee-to-vacation.actions';
import { UserToCommissionShiftActionTypes } from '../../../dave-data-module/State/actions/user2CommissionShift.action';
import { getCommissions,getCommissionsActive } from '../../../dave-data-module/State/selectors/commission.selector';
import { getEmployeeToVacations } from '../../../dave-data-module/State/selectors/employee-to-vacation.selectors';
import { getEmployeeById,getEmployeeDictionary,getEmployeesSortedByNameStartWithMe } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getJobSpecification,getJobSpecificationDictionary,getJobSpecificationFetched } from '../../../dave-data-module/State/selectors/job-specification.selector';
import { getPartner } from '../../../dave-data-module/State/selectors/partners.selectors';
import { getUserToCommissionShifts } from '../../../dave-data-module/State/selectors/users-to-commission-shift.selectors';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { NewVacationComponent,NewVacationComponentDialogData } from '../../../dave-employee-administration-module/components/vacation/new-vacation/new-vacation.component';
import { FilterOption,FILTER_TYPE_BOOLEAN,FILTER_TYPE_SEARCH_MULTI_SELECT,IFilterTypeSearchMultiSelectValue } from '../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { getAddressString,getNavigationURL,isNotNullOrUndefined,SearchQueriesDebounceTime,uniqArray } from '../../../helper/helper';
import { AbsentMetaIcon,AllCommissionMeta,CalendarPageMeta,CommissionMeta,RessourcePlanPageMeta,VacationMetaIcon } from '../../../helper/page-metadata';
import { Permission } from '../../../helper/permission.helper';
import { CustomLabelService } from "../../../services/custom-label.service";
import { DefaultFilterService,FilterApps,FilterTypes } from '../../../services/default-filter.service';
import { HolidayService,HolidayServiceFactory } from '../../../services/holiday.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import {
desktopHeaderButtons,
getVacationColorEntity,
SchedulerEventColors,
ShiftplanViews,
viewButton3Month,
viewButtonMonth,
viewButtonWeek,
viewButtonYear
} from '../scheduler.helper';

interface EventData {
    Id: number;
    Subject: string;
    StartTime: Date;
    EndTime: Date;
    IsAllDay?: boolean;
    EmployeeId: number;
    Description?: string;
    Location?: string;
    LocationURL?: string;
    Type: 'u2c' | 'vacation';
    meta: User2CommissionShiftEntity | EmployeeToVacationEntity;
    CategoryColor: ColorEntity;
}
export interface ShiftPlanFilter {
    [FilterTypes.CommissionId]: IFilterTypeSearchMultiSelectValue<number>[];
    [FilterTypes.JobSpecificationIds]: IFilterTypeSearchMultiSelectValue<number>[];
    [FilterTypes.Status]: IFilterTypeSearchMultiSelectValue<EmployeeStatusEnum>[];
    [FilterTypes.ShowNotApprovedVacations]: boolean;
}
@Component({
    selector: 'app-shift-plan',
    templateUrl: './shift-plan.component.html',
    styleUrls: ['./shift-plan.component.scss'],
    providers: [{ provide: HolidayService, useFactory: HolidayServiceFactory }, TimelineViewsService, TimelineMonthService, TimelineYearService, DragAndDropService, ResizeService],
})
export class ShiftPlanComponent implements OnInit, OnDestroy {
    @Input() DefaultFilter: ShiftPlanFilter;
    @Input() AutoFilterCommissionByCurrentCommissionShifts = true;
    @Input() Headline = true;
    @Input() CommissionId: number;
    @Input() HeaderColorful = true;
    @Input() ShowResourcePlanButton = true;
    @Input() AdditionalButtons: {
        icon: IconProp;
        onClick: (clickEvent) => void;
        tooltip: string;
        iconTransform: string | Transform;
    }[];
    @Input() View: ShiftplanViews = 'Timeline3Month';
    @Output() OnViewChange = new EventEmitter<ShiftplanViews>();
    @ViewChild('scheduleObj') schedule;
    @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
    @ViewChild('matMenuTrigger') matMenuTriggerElement: ElementRef;
    @ViewChild(MatMenu) matMenu: MatMenu;
    private resizingClonePrevEndDate: Date | null = null;
    private resizingClonePrevStartDate: Date | null = null;
    private resizingCloneWidth = 0;
    private resizeObserver = new ResizeObserver((entries) => {
        if (entries?.length === 1) {
            this.resizingCloneWidth = entries[0].target.clientWidth;
        }
    });
    private resizeStop$: Subject<void> = new Subject<void>();
    public CanCreateEmployeeVacation = false;
    public CanCreateEmployeeAbsent = false;
    public AdditionalHeaderButtons: { icon: IconProp; routerLink?: string | string[]; onClick?: (clickEvent: Event) => void; tooltip$: Observable<string>; URL: string }[] = [];
    public FilterValues$: BehaviorSubject<ShiftPlanFilter> = new BehaviorSubject({
        [FilterTypes.CommissionId]: [],
        [FilterTypes.JobSpecificationIds]: [],
        [FilterTypes.Status]: [],
        [FilterTypes.ShowNotApprovedVacations]: false,
    });
    public FilterAmount$ = this.FilterValues$.pipe(
        map((val) => {
            let filterAmount = 0;
            for (const [key, value] of Object.entries(val)) {
                if (value && (!Array.isArray(value) || value.length)) {
                    filterAmount++;
                }
            }
            return filterAmount;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public FilterSettings$: Observable<FilterOption[]> = combineLatest([
        this.store.select(getCommissionsActive),
        this.store.select(getJobSpecificationFetched).pipe(
            tap((fetched) => {
                if (!fetched) {
                    this.jobSpecificationResolver.resolve();
                }
            }),
            filter((fetched) => !!fetched),
            switchMap(() => this.store.select(getJobSpecification)),
        ),
        this.cls.getMultiple$('Commission'),
    ]).pipe(
        filter((v) => v.every(isNotNullOrUndefined)),
        map(([commissions, jobSpecifications, commissionLabel]) => {
            let commissionValues: IFilterTypeSearchMultiSelectValue<number>[] = commissions.map((c) => ({
                label: c.DisplayName,
                id: c.Id,
            }));

            let jobSpecificationValues: IFilterTypeSearchMultiSelectValue<number>[] = jobSpecifications.map((str) => ({
                label: str.Name,
                id: str.Id,
            }));

            return [
                {
                    Name: FilterTypes.CommissionId,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: commissionLabel,
                    Icon: CommissionMeta.Icon,
                    Values: commissionValues,
                },
                {
                    Name: FilterTypes.JobSpecificationIds,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Jobbezeichnung',
                    Icon: 'id-card' as IconProp,
                    Values: jobSpecificationValues,
                },
                {
                    Name: FilterTypes.Status,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Status',
                    // Icon: 'id-card' as IconProp,
                    Values: Object.values(EmployeeStatusEnum).map<IFilterTypeSearchMultiSelectValue<EmployeeStatusEnum>>((key) => ({ id: key, label: EmployeeStatusNames.get(key) })),
                },
                {
                    Name: FilterTypes.ShowNotApprovedVacations,
                    Type: FILTER_TYPE_BOOLEAN,
                    Label: 'Alle Urlaubsanträge anzeigen',
                },
            ];
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    public selectedElement: { StartTime: Date ; EndTime: Date ; EmployeeId: number };
    public AddButtons$: Observable<
        {
            icon: IconProp;
            label: string;
            isDisabled: () => boolean;
            onClick: (event) => void;
        }[]
    > = this.cls.getSingle$('Commission').pipe(
        map((commissionLabel) => [
            {
                icon: CommissionMeta.Icon,
                label: commissionLabel,
                isDisabled: () => false,
                onClick: (e) => {
                    if (this.selectedElement.EmployeeId) {
                        this.store
                            .select(getEmployeeById({ id: this.selectedElement.EmployeeId }))
                            .pipe(take(1))
                            .subscribe((employee) => {
                                this.addU2C(this.selectedElement.StartTime , this.selectedElement.EndTime , employee.UserId);
                            });
                    } else {
                        throw 'No EmployeeId';
                    }
                },
            },
            {
                icon: VacationMetaIcon,
                label: 'Urlaub',
                isDisabled: () => !this.CanCreateEmployeeVacation,
                onClick: () =>
                    this.dialog.open<NewVacationComponent, NewVacationComponentDialogData>(NewVacationComponent, {
                        ...NewVacationComponent.DefaultConfig,
                        data: {
                            employeeId: this.selectedElement.EmployeeId,
                            defaultStartDate: this.selectedElement.StartTime,
                            vacation: true,
                        },
                    }),
            },
            {
                icon: AbsentMetaIcon,
                label: 'Abwesend und Krankmeldung',
                isDisabled: () => !this.CanCreateEmployeeAbsent,
                onClick: () =>
                    this.dialog.open<NewVacationComponent, NewVacationComponentDialogData>(NewVacationComponent, {
                        ...NewVacationComponent.DefaultConfig,
                        data: {
                            employeeId: this.selectedElement.EmployeeId,
                            defaultStartDate: this.selectedElement.StartTime,
                            vacation: false,
                        },
                    }),
            },
        ]),
    );

    public ResourcePlanMeta = RessourcePlanPageMeta;
    public PageMeta = CalendarPageMeta;
    private dataBound$: Subject<void> = new Subject<void>();
    constructor(
        private store: Store<State>,
        private dialog: MatDialog,
        private defaultFilterService: DefaultFilterService,
        private holidayService: HolidayService,
        private apiToasterService: ToastrService,
        private ps: PermissionService,
        private appDialog: AppDialogService,
        private jobSpecificationResolver: JobSpecificationResolver,
        private componentElement: ElementRef,
        public BS: BreakpointObserverService,
        protected cls: CustomLabelService,
        public LS: LocalStorageService,
    ) {
        loadCldr(n1, n2, n3, n4, s, s2);
        this.dataBound$
            .pipe(
                takeUntil(this.onDestroy$),
                takeWhile(() => {
                    const scrollWrapper = this.componentElement.nativeElement.querySelector(`.e-schedule-table.e-outer-table > tbody div.e-content-wrap`);
                    return !scrollWrapper?.scrollLeft;
                }, false),
            )
            .subscribe(() => {
                this.scrollToToday();
            });
    }
    public Employees$: Observable<EmployeeEntity[]> = combineLatest([this.store.select(getPartner), this.store.select(getEmployeesSortedByNameStartWithMe)]).pipe(
        map(([partner, employees]) => employees?.filter((e) => e.PartnerId === partner.Id)),
    );
    public Commissions$: Observable<CommissionEntity[]> = this.store.select(getCommissions);
    public Data$: Observable<EventSettingsModel>;
    private onDestroy$: Subject<void> = new Subject<void>();

    public Group: GroupModel = {
        enableCompactView: false,
        resources: ['Employee'],
    };
    public EmployeeDataSource$: Observable<Record<string, any>[]>;
    public SelectedDate: Date = new Date();

    ngOnInit() {
        this.FilterValues$.pipe(takeUntil(this.onDestroy$), skip(2)).subscribe((val) => {
            if (Object.keys(val).length !== 0) {
                this.defaultFilterService.SetFilterByApp(FilterApps.ShiftPlan, val);
            }
        });
        combineLatest([this.FilterSettings$, this.defaultFilterService.GetFilterByApp$(FilterApps.ShiftPlan)])
            .pipe(take(1))
            .subscribe(([val, filterValues]) => {
                let temp: ShiftPlanFilter = {
                    [FilterTypes.CommissionId]: isArray(filterValues[FilterTypes.CommissionId]) && filterValues[FilterTypes.CommissionId].every((v) => v.id) ? filterValues[FilterTypes.CommissionId] : [],
                    [FilterTypes.JobSpecificationIds]: isArray(filterValues[FilterTypes.JobSpecificationIds]) && filterValues[FilterTypes.JobSpecificationIds].every((v) => v.id) ? filterValues[FilterTypes.JobSpecificationIds] : [],
                    [FilterTypes.ShowNotApprovedVacations]: !!filterValues[FilterTypes.ShowNotApprovedVacations],
                    [FilterTypes.Status]:
                        isArray(filterValues[FilterTypes.Status]) && filterValues[FilterTypes.Status].every((v) => v.id)
                            ? filterValues[FilterTypes.Status]
                            : [{ id: EmployeeStatusEnum.Aktiv, label: EmployeeStatusNames.get(EmployeeStatusEnum.Aktiv) }],
                };
                if (this.DefaultFilter) {
                    temp = { ...temp, ...this.DefaultFilter };
                }
                if (this.CommissionId) {
                    temp = {
                        ...temp,
                        [FilterTypes.CommissionId]: [
                            ...temp[FilterTypes.CommissionId].filter((v) => v.id !== this.CommissionId),
                            { id: this.CommissionId, label: val.find((s) => s.Name === FilterTypes.CommissionId).Values.find((s) => s.id === this.CommissionId)?.label },
                        ],
                    };
                }
                this.FilterValues$.next(temp);
            });
        if (this.AutoFilterCommissionByCurrentCommissionShifts) {
            this.store
                .select(getUser)
                .pipe(
                    filter(isNotNullOrUndefined),
                    take(1),
                    switchMap((user) =>
                        this.store.select(getUserToCommissionShifts).pipe(
                            filter(isNotNullOrUndefined),
                            take(1),
                            map((u2cs) => {
                                // Filtert die u2cs raus denen der user aktuell (+- 3 Tage) zugeordnet ist
                                const from = moment();
                                from.add(-3, 'day');
                                const to = moment();
                                to.add(3, 'day');
                                return uniqArray(u2cs.filter((u) => u.UserId === user.Id && to.isAfter(u.StartDate) && from.isBefore(u.EndDate)).map((u) => u.CommissionId));
                            }),
                            switchMap((commissionIds) => this.store.select(getCommissions).pipe(map((com) => com.filter((c) => commissionIds.includes(c.Id))))),
                        ),
                    ),
                )
                .subscribe((commissions) => this.FilterValues$.next({ ...this.FilterValues$.value, commissionId: commissions.map((com) => ({ label: com.DisplayName, id: com.Id })) }));
        }
        this.Data$ = combineLatest([
            this.Employees$,
            this.Commissions$,
            this.store.select(getUserToCommissionShifts),
            this.FilterValues$.pipe(
                debounceTime(SearchQueriesDebounceTime),
                map((filter) => ({
                    [FilterTypes.CommissionId]: filter[FilterTypes.CommissionId],
                    [FilterTypes.ShowNotApprovedVacations]: filter[FilterTypes.ShowNotApprovedVacations],
                })),
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            ),
            this.store.select(getEmployeeToVacations).pipe(
                filter(isNotNullOrUndefined),
                map((e2vs) => e2vs.filter((v) => !v.DeletedAt)),
            ),
            this.store.select(getUser),
            this.ps.Has(Permission.ChangeVacation),
            this.store.select(getEmployeeDictionary),

            this.ps.Has(Permission.EditRoleCommissionManagement),
            this.ps.Has(Permission.DeleteRoleCommissionManagement),
            this.store.select(getJobSpecificationFetched).pipe(
                filter((f) => f),
                switchMap(() => this.store.select(getJobSpecificationDictionary)),
            ),
        ]).pipe(
            map(([employees, commissions, u2cs, filter, vacations, user, changeVacation, employeeDictionary, canEditRoleCommissionManagement, canDeleteRoleCommissionManagement, jobDictionary]) => {
                const filteredu2cs: User2CommissionShiftEntity[] = u2cs.filter((u) => !filter.commissionId?.length || filter.commissionId.some((c) => c.id?.toString() === u.CommissionId.toString()));
                return {
                    allowDeleting: true,
                    dataSource: [
                        ...vacations
                            .filter((v) => filter.showNotApprovedVacations === true || v.Type !== VacationTypeEnum.Vacation || (filter.showNotApprovedVacations === false && v.VacationStatus === VacationStatusEnum.Approved))
                            .map<EventData>((v) => {
                                const employee = employeeDictionary[v.EmployeeId];
                                const isAssigned = employee?.UserIds.includes(user.Id);
                                return {
                                    Id: /*'vacation,' + */ v.Id,
                                    Subject: VacationTypeEnumNameMap.get(v.Type),
                                    StartTime: v.StartDate,
                                    EndTime: v.EndDate,
                                    IsAllDay: true,
                                    EmployeeId: v.EmployeeId,
                                    Type: 'vacation',
                                    Description: v.Notes,
                                    CategoryColor: getVacationColorEntity(v),
                                    meta: v,
                                    isReadonly: !(!employee?.Deleted && (employee?.UserId === user.Id || (changeVacation && isAssigned))),
                                };
                            }),
                        ...filteredu2cs
                            .filter((c) => !commissions.find((co) => co.Id === c.CommissionId)?.Deleted && c.DeletedAt === null)
                            .map<EventData>((u2c) => {
                                const employee = employees.find((e) => e.UserId === u2c.UserId);
                                const StartDate = u2c.StartDate ? u2c.StartDate : new Date(); //new Date has to be replaced with desired default value
                                const EndDate: Date = u2c.EndDate ? u2c.EndDate : new Date(); //new Date has to be replaced with desired default value
                                const commission = commissions.find((c) => c.Id === u2c.CommissionId);
                                if (!commission) {
                                    console.error('commission for userToCommissionShiftEntity not found: ' + JSON.stringify(u2c));
                                }
                                if (!employee) {
                                    console.error('employee for userToCommissionShiftEntity not found: ' + JSON.stringify(u2c));
                                }
                                return {
                                    Id: /*'u2c,' + */ u2c.Id * -1,
                                    Subject: commission?.GetDisplayName(),
                                    StartTime: StartDate,
                                    EndTime: EndDate,
                                    // IsAllDay: true,
                                    EmployeeId: employee?.Id,
                                    Type: 'u2c',
                                    Description: uniqArray(filteredu2cs.filter((u2cs) => u2cs.CommissionId === u2c.CommissionId).map((u2cs) => u2cs.UserId))
                                        .map((userId) => employees.find((e) => e.UserId === userId)?.DisplayName)
                                        .join(' | '),
                                    Location: commission ? getAddressString(commission) : '',
                                    LocationURL: commission ? getNavigationURL(null, commission) : '',
                                    CategoryColor: u2c.CommissionId === this.CommissionId ? ColorEntityFromString(SchedulerEventColors.green.primary) : ColorEntityFromString(SchedulerEventColors.yellow.primary),
                                    meta: u2c,
                                    isReadonly: !canEditRoleCommissionManagement || !canDeleteRoleCommissionManagement,
                                };
                            }),
                    ],
                };
            }),
            filter(isNotNullOrUndefined),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

        this.EmployeeDataSource$ = combineLatest([
            this.Employees$,
            this.store.select(getUserToCommissionShifts),
            this.FilterValues$.pipe(
                debounceTime(SearchQueriesDebounceTime),
                map((filter) => ({
                    [FilterTypes.Status]: filter[FilterTypes.Status],
                    [FilterTypes.CommissionId]: filter[FilterTypes.CommissionId],
                    [FilterTypes.JobSpecificationIds]: filter[FilterTypes.JobSpecificationIds],
                })),
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            ),
            this.store.select(getUser),
        ]).pipe(
            map(([employees, u2cs, filter, user]) => {
                const filteredu2cs = u2cs.filter((u) => !filter.commissionId?.length || filter.commissionId.some((com) => com.id === u.CommissionId));
                return (
                    employees
                        // andere employees rausfiltern wenn es im auftrag angezeigt wird und der filter aktiv ist
                        .filter((e) => !filter[FilterTypes.Status]?.length || filter[FilterTypes.Status].some((js) => js.id === e.EmployeeStatus))
                        .filter((e) => !filter.commissionId?.length || filteredu2cs.some((u2c) => u2c.UserId === e.UserId))
                        .filter((e) => !filter.jobSpecificationIds?.length || filter.jobSpecificationIds.some((js) => js.id === e.JobSpecificationId))
                        .sort((a, b) => {
                            if (a.UserId !== user.Id && b.UserId !== user.Id) {
                                return 0;
                            } else if (a.UserId === user.Id) {
                                return -1;
                            } else {
                                return 1;
                            }
                        })
                        .map((employee) => ({ Text: employee.DisplayName, Id: employee.Id, Designation: employee.JobSpecificationId }))
                );
            }),
            filter(isNotNullOrUndefined),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    public OpenU2CSDialog(commissionId: number, userId: number) {
        this.dialog.open(SelectUserCommissionLegacyComponent, {
            ...SelectUserCommissionLegacyComponent.DefaultConfig,
            data: { commissionId, onlyOneUser: userId },
        });
    }

    private addU2C( selectedDateStartTime: Date  , selectedDateEndTime  , userId: number) {

        this.dialog.open<SelectUserCommissionLegacyComponent, SelectUserCommissionLegacyComponentDialogData>(SelectUserCommissionLegacyComponent, {
            ...SelectUserCommissionLegacyComponent.DefaultConfig,
            data: {
                preFilledStartDate: selectedDateStartTime,
                preFilledEndDate: selectedDateEndTime,
                onlyOneUser: userId,
                commissionId: this.CommissionId,
            },
        });
    }
    public OpenVacationDialog(e2v: EmployeeToVacationEntity) {
        this.dialog.open<NewVacationComponent, NewVacationComponentDialogData>(NewVacationComponent, {
            ...NewVacationComponent.DefaultConfig,
            data: {
                employeeToVacationId: e2v.Id || null,
                employeeId: e2v.EmployeeId,
            },
        });
    }
    public actionComplete(c: ActionEventArgs) {
        if (c.requestType === 'viewNavigate') {
            const options = this.schedule.activeViewOptions;

            if (!options) {
                return;
            }
            const view = options.option;

            if (options?.interval === 3 && view === 'TimelineMonth') {
                this.OnViewChange.emit('Timeline3Month');
                this.View = 'Timeline3Month' as ShiftplanViews;
            } else {
                this.OnViewChange.emit(view as ShiftplanViews);
                this.View = view as ShiftplanViews;
            }
            this.scrollToToday();
        } else if (c.requestType === 'dateNavigate') {
            this.scrollToToday();
        }
    }

    public scrollToToday() {
        this.schedule.scrollTo(null, this.SelectedDate);
    }
    onPopupOpen(args: PopupOpenEventArgs): void {
        if (args.type === 'DeleteAlert') {
            args.cancel = true;

            if (!args.data.isReadonly) {
                this.appDialog
                    .OpenConfirmationDialog({
                        paragraph: `Soll der Eintrag wirklich gelöscht werden?`,
                        styleDelete: true,
                    })
                    .pipe(take(1))
                    .subscribe(([res]) => {
                        if (res) {
                            switch (args.data.Type /*(event.id as string)?.split(',')[0]*/) {
                                case 'u2c':
                                    this.store.dispatch(
                                        UserToCommissionShiftActionTypes.DeleteOne({
                                            Payload: args.data.meta.Id,
                                        }),
                                    );
                                    break;
                                case 'vacation':
                                    this.store.dispatch(
                                        EmployeeToVacationActionTypes.Delete({
                                            Payload: { id: args.data.meta.Id },
                                        }),
                                    );
                                    break;
                            }
                        }
                    });
            }
        }
        if (args.type === 'Editor' || (args.type === 'QuickInfo' && !args.data.Id)) {
            args.cancel = true;
            if (args.data.Id && args.type === 'Editor') {
                switch (args.data.Type /*(event.id as string)?.split(',')[0]*/) {
                    case 'u2c':
                        this.OpenU2CSDialog(args.data.meta.CommissionId, args.data.meta.UserId);
                        break;
                    case 'vacation':
                        this.OpenVacationDialog(args.data.meta);
                        break;
                }
            } else {
                const eventRect = args.target.getBoundingClientRect();
                this.selectedElement = args.data as any;
                firstValueFrom(combineLatest([this.store.select(getEmployeeById({ id: args.data.EmployeeId })), this.store.select(getUser), this.ps.Has(Permission.CreateVacation), this.ps.Has(Permission.CreateAbsent)])).then(
                    ([e, u, pCreateVacation, pCreateAbsent]) => {
                        this.CanCreateEmployeeVacation = !e.Deleted && ((e.UserId !== null && e.UserId === u.Id) || (pCreateVacation && e.UserIds?.includes(u.Id)));
                        this.CanCreateEmployeeAbsent = !e.Deleted && ((e.UserId !== null && e.UserId === u.Id) || (pCreateAbsent && e.UserIds?.includes(u.Id)));
                        this.matMenuTriggerElement.nativeElement.style.left = eventRect.left + eventRect.width - 16 + 'px';
                        this.matMenuTriggerElement.nativeElement.style.top = eventRect.top + eventRect.height - 20 + 'px';
                        this.matMenuTrigger.openMenu();
                    },
                );
            }
        }
        if (args.type === 'QuickInfo' || args.type === 'ViewEventInfo') {
            if (args.data.Id) {
                const btns = args.element.querySelectorAll('.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-btn.e-small');
                btns.forEach((b) => b.classList.remove('e-small'));
            }
            this.AdditionalHeaderButtons = this.GetAdditionalPopupHeaderButtons(args.data);
        }
    }
    ngOnDestroy() {
        this.onDestroy$.next();
    }
    dataBound() {
        this.dataBound$.next();
    }
    onActionBegin(args: ActionEventArgs & ToolbarActionArgs) {
        if (args.requestType === 'toolbarItemRendering') {
            args.items = [...desktopHeaderButtons, viewButtonWeek, viewButtonMonth, viewButton3Month, viewButtonYear];
        }
    }
    ApplyCategoryColor(args: EventRenderedArgs): void {
        let categoryColor = args.data.CategoryColor as ColorEntity;
        if (!args.element || !categoryColor) {
            return;
        }

        if (this.schedule.currentView === 'Agenda') {
            (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor.getHexString();
        } else {
            args.element.style.backgroundColor = categoryColor.getHexString();
            args.element.style.color = categoryColor.getTextColor();
        }
    }
    GetAdditionalPopupHeaderButtons(data) {
        const buttons: { icon: IconProp; routerLink?: string | string[]; tooltip$: Observable<string>; URL: string }[] = [];
        if (data.LocationURL) {
            buttons.push({ icon: 'directions', tooltip$: of('Losfahren'), routerLink: null, URL: data.LocationURL });
        }
        if (data.Id) {
            switch (data.Type /*(event.id as string)?.split(',')[0]*/) {
                case 'u2c':
                    buttons.push({
                        icon: CommissionMeta.Icon,
                        tooltip$: this.cls.getSingle$('Commission').pipe(map((label) => 'Zum ' + label)),
                        routerLink: ['/', CommissionMeta.Path, AllCommissionMeta.Path, data.meta.CommissionId],
                        URL: null,
                    });
                    break;
            }
        }
        return buttons;
    }
    // GetVacationDays(date1: Date, date2: Date) {
    //     let workingDays = this.holidayService.GetWorkingDayCount(date1, date2);
    //     if (date1.getHours() < 22 && date1.getHours() > 2) {
    //         // half
    //         workingDays += -0.5;
    //     }
    //     if (date2.getHours() < 22 && date2.getHours() > 2) {
    //         // half
    //         workingDays += -0.5;
    //     }
    //     return workingDays;
    // }
    onDragStart(args: DragEventArgs) {
        if (args.data.isReadonly) {
            args.cancel = true;
        }
        args.navigation.enable = true;
    }
    onResizeStart(args: ResizeEventArgs) {
        if (args.data.isReadonly) {
            args.cancel = true;
        }
        this.resizingClonePrevEndDate = args.data.EndTime;
        this.resizingClonePrevStartDate = args.data.StartTime;
        interval(100)
            .pipe(
                takeUntil(this.resizeStop$),
                map(() => this.componentElement.nativeElement.querySelector('div.e-appointment.e-resize-clone')),
                takeWhile((v) => !v, true),
                filter((v) => !!v),
            )
            .subscribe((element) => {
                this.resizeObserver.disconnect();
                this.resizeObserver.observe(element);
            });
    }
    onDragStop(args: DragEventArgs) {
        switch (args.data.Type) {
            case 'u2c':
                const employee$ = this.store.select(getEmployeeById({ id: args.data.EmployeeId }));
                employee$.pipe(first()).subscribe((employee) => {
                    if (!employee.UserId) {
                        this.apiToasterService.error(`Der Mitarbeiter ${employee.DisplayName} hat keinen Account.`);
                        args.cancel = true;
                    } else if (args.data.EndTime.getTime() !== args.data.meta.EndDate.getTime() || args.data.StartTime.getTime() !== args.data.meta.StartDate.getTime() || employee.UserId !== args.data.meta.UserId) {
                        const EndDate = args.data.EndTime;
                        const StartDate = args.data.StartTime;
                        StartDate.setHours(args.data.StartTime.getHours());
                        StartDate.setMinutes(args.data.StartTime.getMinutes());
                        EndDate.setHours(args.data.EndTime.getHours());
                        EndDate.setMinutes(args.data.EndTime.getMinutes());
                        // StartDate.setHours(0, 0, 0, 0);
                        // EndDate.setHours(23, 59, 59, 999);
                        this.store.dispatch(
                            UserToCommissionShiftActionTypes.ChangeUser2CommissionShift({
                                Payload: {
                                    id: args.data.meta.Id,
                                    endDate: FrontendDateTimestamp(EndDate),
                                    startDate: FrontendDateTimestamp(StartDate),
                                    userId: employee.UserId,
                                },
                            }),
                        );
                    }
                });
                break;
            /* ONDRAG UND ONRESIZE WÜRDEN HALBE URLAUBSTAGE NICHT FUNKTIONIEREN!!!
                case 'vacation':
                if (args.data.EmployeeId !== args.data.meta.EmployeeId) {
                    this.apiToasterService.error('"' + VacationTypeEnumNameMap.get(args.data.meta.Type) + '" kann keinem anderen Mitarbeiter zugewiesen werden.');
                    args.cancel = true;
                } else if (args.data.EndTime.getTime() !== args.data.meta.EndDate.getTime() || args.data.StartTime.getTime() !== args.data.meta.StartDate.getTime()) {
                    const vacationDays = this.GetVacationDays(args.data.StartTime, args.data.EndTime);
                    const EndDate = args.data.EndTime;
                    const StartDate = args.data.StartTime;
                    StartDate.setHours(0, 0, 0, 0);
                    EndDate.setHours(23, 59, 59, 999);
                    this.store.dispatch(
                        EmployeeToVacationActionTypes.Modify({
                            Payload: {
                                id: args.data.meta.Id,
                                endDate: FrontendDateTimestamp(EndDate),
                                startDate: FrontendDateTimestamp(StartDate),
                                vacationDays,
                            },
                        }),
                    );
                }
                break;*/
        }
    }
    onResizeStop(args: ResizeEventArgs) {
        // wenn man das event nicht bewegt ist this.resizingCloneWidth = 0
        if (!this.resizingCloneWidth) {
            return;
        }

        if (args.data.EndTime.getTime() !== args.data.meta.EndDate.getTime() || args.data.StartTime.getTime() !== args.data.meta.StartDate.getTime()) {
            // args.data.EndTime is broken
            this.resizeStop$.next();
            this.resizeObserver.disconnect();

            const StartDate: Date = new Date(args.data.StartTime);
            const EndDate: Date = args.data.EndTime ? new Date(args.data.EndTime) : new Date(this.resizingClonePrevEndDate);

            if (args.data.hasOwnProperty('StartTime') || args.data.hasOwnProperty('EndTime')) {
                switch (args.data.Type) {
                    case 'u2c':
                        this.store.dispatch(
                            UserToCommissionShiftActionTypes.ChangeUser2CommissionShift({
                                Payload: {
                                    id: args.data.meta.Id,
                                    endDate: FrontendDateTimestamp(EndDate),
                                    startDate: FrontendDateTimestamp(StartDate),
                                },
                            }),
                        );
                        break;
                    /* ONDRAG UND ONRESIZE WÜRDEN HALBE URLAUBSTAGE NICHT FUNKTIONIEREN!!!
                    case 'vacation':
                    const vacationDays = this.GetVacationDays(args.data.StartTime, args.data.EndTime);
                    if (vacationDays !== args.data.meta.VacationDays && args.data.meta.Type === VacationTypeEnum.Vacation) {
                        this.apiToasterService.info(`Es werden ${vacationDays} Urlaubstage genutzt (vorher ${args.data.meta.VacationDays})`);
                    }
                    this.store.dispatch(
                        EmployeeToVacationActionTypes.Modify({
                            Payload: {
                                id: args.data.meta.Id,
                                endDate: FrontendDateTimestamp(EndDate),
                                startDate: FrontendDateTimestamp(StartDate),
                                vacationDays,
                            },
                        }),
                    );
                    break;*/
                }
            }
        }
    }
}
