<ng-container *ngFor="let s of $Settings | async">
    <ng-container [ngSwitch]="s.Type">
        <div class="text" *ngSwitchCase="'text'">
            <mat-form-field appearance="fill">
                <mat-label>
                    <fa-icon [icon]="s.Icon" *ngIf="s.Icon"></fa-icon>
                    {{ s.Label }}
                </mat-label>
                <input matInput [value]="FormGroup.get(s.Name)?.value" (change)="FormGroup.get(s.Name)?.setValue($event.target.value)" />
            </mat-form-field>
        </div>
        <div class="select" *ngSwitchCase="'select'">
            <mat-form-field appearance="fill">
                <mat-label>
                    <fa-icon [icon]="s.Icon" *ngIf="s.Icon"></fa-icon>
                    {{ s.Label }}
                </mat-label>
                <mat-select panelClass="filter-with-increased-height" [value]="FormGroup.get(s.Name)?.value" (selectionChange)="FormGroup.get(s.Name)?.setValue($event.value)">
                    <mat-option [value]="i.key" *ngFor="let i of s.Values | keyvalue">{{ i.value }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="select" *ngSwitchCase="'search-select'">
            <mat-form-field appearance="fill" *ngIf="SelectSearchValues.has(s.Name)">
                <mat-label>
                    <fa-icon [icon]="s.Icon" *ngIf="s.Icon"></fa-icon>
                    {{ s.Label }}
                </mat-label>
                <fa-icon
                    matSuffix
                    icon="times"
                    style="margin-left: 3px; cursor: pointer"
                    (click)="FormGroupForSearchSelect.controls[s.Name]?.setValue(null); $event.stopPropagation()"
                    *ngIf="!FormGroupForSearchSelect.controls[s.Name].disabled"
                ></fa-icon>
                <app-select-search
                    [formControl]="FormGroupForSearchSelect.controls[s.Name]"
                    [Options]="SelectSearchValues.get(s.Name)"
                    [CompareOptions]="CompareSelectSearchOptions"
                    [SearchFunction]="SelectSearchOptionsFunction"
                ></app-select-search>
            </mat-form-field>
        </div>
        <div class="select" *ngSwitchCase="'search-multi-select'">
            <app-chip-autocomplete [initialPatchDefaultValue]="true" appearance="fill" [formControl]="FormGroup.get(s.Name)" [MapFn]="chipAutocompleteMapFunction" [Options]="s.Values" [Placeholder]="s.Label" [PlaceholderIcon]="s.Icon"></app-chip-autocomplete>
        </div>
        <div class="toggle" *ngSwitchCase="'boolean'">
            <label>
                <fa-icon [icon]="s.Icon" *ngIf="s.Icon"></fa-icon>
                {{ s.Label }}
                <form class="example-form" [formGroup]="FormGroup" ngNativeValidate>
                    <mat-slide-toggle class="example-margin" [formControlName]="s.Name"> </mat-slide-toggle>
                </form>
            </label>
        </div>
        <mat-form-field *ngSwitchCase="'date'" appearance="fill">
            <mat-label>
                <fa-icon [icon]="s.Icon" *ngIf="s.Icon"></fa-icon>
                {{ s.Label }}
            </mat-label>
            <mat-date-range-input [formGroup]="FormGroup.get(s.Name)" [rangePicker]="campaignOnePicker">
                <input matStartDate placeholder="von" formControlName="from" />
                <input matEndDate placeholder="bis" formControlName="to" />
            </mat-date-range-input>
            <!--                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>-->
            <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
        </mat-form-field>
        <div class="select" *ngSwitchCase="'multi-select'">
            <mat-form-field appearance="fill">
                <mat-label>
                    <fa-icon [icon]="s.Icon" *ngIf="s.Icon"></fa-icon>
                    {{ s.Label }}
                </mat-label>
                <mat-select multiple panelClass="filter-with-increased-height" [value]="FormGroup.get(s.Name)?.value" (selectionChange)="FormGroup.get(s.Name)?.setValue($event.value)">
                    <mat-select-trigger>
                        {{ FormGroup.get(s.Name)?.value?.length ? (FormGroup.get(s.Name)?.value?.length > 1 ? FormGroup.get(s.Name)?.value?.length + ' ' + s.Label : s.Values[FormGroup.get(s.Name)?.value[0]]) : '' }}
                    </mat-select-trigger>
                    <div class="mat-option">
                        <button app-button Inverted class="toggle-button" (click)="FormGroup.get(s.Name).setValue([])">zurücksetzen</button>
                    </div>
                    <mat-option [value]="i.key" *ngFor="let i of s.Values | keyvalue">{{ i.value }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </ng-container>
</ng-container>
