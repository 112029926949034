<mat-card class="list-card">
    <mat-card-header>
        <h3 mat-card-title>
            Vorlagen
            <fa-icon icon="info-circle" [matTooltip]="Data.ListView ? 'Hier bearbeiten Sie Ihre Vorlagen für die Abrechnungspositionen' : 'Hier bearbeiten Sie Ihre Abrechnungspositionen und nicht die Vorlagen'" size="lg">
            </fa-icon>
        </h3>

        <mat-form-field>
            <app-select-search [placeholder]="'Vorlagentyp'" [formControl]="ArlTypeForm" [Options]="TemplateTypes$ | async" [CompareOptions]="CompareByIdAndType"></app-select-search>
        </mat-form-field>
        <div>
            <mat-form-field floatLabel="never" color="accent" *ngIf="ShowSearchBar$ | async" [ngStyle]="{ width: (IsSmallWidth$ | async) ? '100%' : '50%' }" style="margin-right: 0.5rem">
                <mat-label>Suche</mat-label>
                <input type="text" [formControl]="SearchForm" matInput #input />
            </mat-form-field>
            <button
                app-round-button
                [Inverted]="Data.ListView"
                matTooltipPosition="left"
                [matTooltip]="(ShowSearchBar$ | async) ? 'Ausblenden' : 'Suche'"
                (click)="ShowSearchBar$.next(!this.ShowSearchBar$.value)"
                style="margin-right: 0.5rem"
            >
                <fa-icon [icon]="(ShowSearchBar$ | async) ? 'times' : 'search'"></fa-icon>
            </button>
            <button
                style="margin-right: 0.5rem"
                app-round-button
                (click)="EditMode.next(!EditMode.value)"
                [matTooltip]="Data.ListView ? 'Bearbeiten' : 'Vorlage bearbeiten'"
                [Inverted]="EditMode | async"
                *ngIf="SelectedTemplateId$ | async"
            >
                <fa-icon icon="pencil-alt"></fa-icon>
            </button>
            <button
                style="margin-right: 0.5rem"
                app-round-button
                (click)="Multiselect.next(!Multiselect.value)"
                [matTooltip]="!Data.ListView && (Multiselect | async) ? 'Aktiv' : 'Mehrfachauswahl'"
                [Inverted]="Multiselect | async"
                *ngIf="!(EditMode | async) && !Data.ListView"
            >
                <fa-icon icon="check-square"></fa-icon>
            </button>
            <button
                [style]="(ShowSearchBar$ | async) && !(SelectedTemplateId$ | async) ? 'margin-right: -2.5rem' : 'margin-right: .5rem'"
                app-round-button
                (click)="OpenModifyTemplateDialog(null, true)"
                matTooltip="Vorlage hinzufügen"
            >
                <fa-icon icon="plus"></fa-icon>
            </button>
        </div>
    </mat-card-header>
    <div>
        <ngx-simplebar class="clickable-list-container">
            <app-arl-template-table
                [multiselect]="Multiselect | async"
                [filterQuery]="SearchForm.value"
                [filterCommissionType]="ArlTypeForm.value?.type === templateFilterTypes.commissionType ? ArlTypeForm.value.id : null"
                [filterARLTemplateType]="ArlTypeForm.value?.type === templateFilterTypes.arlTemplateType ? ArlTypeForm.value.id : null"
                [filterNoCommissionTypeAndNoArlTemplateType]="ArlTypeForm.value?.type === templateFilterTypes.none"
                [disabledForMultiselect]="Data.addedTemplateIds || []"
                [(selectedArlTemplates)]="SelectedTemplates"
            ></app-arl-template-table>
            <!--                        <table mat-table [dataSource]="BookingPositionTemplates$" *ngIf="(Data.ListView ? ARLTemplateColumnsListView$ : ARLTemplateColumns$) | async as ARLTemplateColumns">-->
            <!--                            <ng-container *ngFor="let collumName of ARLTemplateAutoColumns" [matColumnDef]="collumName">-->
            <!--                                <th mat-header-cell *matHeaderCellDef>-->
            <!--                                    {{ ARLTemplateColumnHeaders ? ARLTemplateColumnHeaders[collumName] : '' }}-->
            <!--                                </th>-->
            <!--                                <td mat-cell *matCellDef="let rowData" (click)="autoColumnClick(rowData, modifyBookingOption.Form)">-->
            <!--                                    {{ rowData[collumName] }}-->
            <!--                                </td>-->
            <!--                            </ng-container>-->

            <!--                            <ng-container matColumnDef="Hint">-->
            <!--                                <th mat-header-cell *matHeaderCellDef></th>-->
            <!--                                <td mat-cell *matCellDef="let rowData" style="width: 1rem">-->
            <!--                                    <fa-icon *ngIf="rowData.Hint" icon="info-circle" [matTooltip]="rowData.Hint" style="margin: 0.5rem" size="lg"> </fa-icon>-->
            <!--                                    <fa-icon *ngIf="rowData.dontEditHint" icon="question-circle" [matTooltip]="rowData.dontEditHint" style="margin: 0.5rem" size="lg"> </fa-icon>-->
            <!--                                </td>-->
            <!--                            </ng-container>-->
            <!--                            <ng-container matColumnDef="Button">-->
            <!--                                <th mat-header-cell *matHeaderCellDef></th>-->
            <!--                                <td mat-cell *matCellDef="let rowData" style="width: 3rem">-->
            <!--                                    <button app-round-button (click)="OpenModifyTemplateDialog(rowData.Id)" [matTooltip]="'Bearbeiten'">-->
            <!--                                        <fa-icon icon="pencil-alt"></fa-icon>-->
            <!--                                    </button>-->
            <!--                                </td>-->
            <!--                            </ng-container>-->
            <!--                            <ng-container matColumnDef="Information">-->
            <!--                                <th mat-header-cell *matHeaderCellDef style="width: 17rem">Bezeichnung</th>-->
            <!--                                <td mat-cell *matCellDef="let rowData" (click)="IsDirty(modifyBookingOption?.Form) ? ConfirmDiscard(modifyBookingOption?.Form, rowData) : highlight(rowData)">-->
            <!--                                    {{ rowData.Information }}-->
            <!--                                </td>-->
            <!--                            </ng-container>-->
            <!--                            <ng-container matColumnDef="TemplateType">-->
            <!--                                <th mat-header-cell *matHeaderCellDef style="width: 10rem">Vorlagentyp</th>-->
            <!--                                <td mat-cell *matCellDef="let rowData" (click)="IsDirty(modifyBookingOption.Form) ? ConfirmDiscard(modifyBookingOption.Form, rowData) : highlight(rowData)">-->
            <!--                                    {{ rowData.TemplateType }}-->
            <!--                                </td>-->
            <!--                            </ng-container>-->
            <!--                            <ng-container matColumnDef="checkbox">-->
            <!--                                <th mat-header-cell *matHeaderCellDef style="padding: 0 1rem">-->
            <!--                                    <fa-icon icon="check-square"></fa-icon>-->
            <!--                                </th>-->
            <!--                                <td mat-cell *matCellDef="let rowData" style="padding: 0 1rem" [style.display]="!(Multiselect | async) ? 'none' : ''">-->
            <!--                                    <mat-checkbox-->
            <!--                                        (click)="$event.stopPropagation()"-->
            <!--                                        (change)="MultiSelectTemplate(rowData)"-->
            <!--                                        [checked]="IsSelected(rowData) || Data.addedTemplateIds?.includes(rowData.Id)"-->
            <!--                                        color="primary"-->
            <!--                                        [disabled]="Data.addedTemplateIds?.includes(rowData.Id)"-->
            <!--                                        [class.disabled]="Data.addedTemplateIds?.includes(rowData.Id)"-->
            <!--                                        [style.display.none]="!(Multiselect | async)"-->
            <!--                                    >-->
            <!--                                    </mat-checkbox>-->
            <!--                                </td>-->
            <!--                            </ng-container>-->
            <!--                            <tr mat-header-row *matHeaderRowDef="ARLTemplateColumns"></tr>-->
            <!--                            <ng-container *ngIf="!Data.ListView">-->
            <!--                                <ng-container *ngIf="Multiselect | async as multi">-->
            <!--                                    <tr-->
            <!--                                        class="clickable-list-item"-->
            <!--                                        mat-row-->
            <!--                                        *matRowDef="let rowData; columns: ARLTemplateColumns"-->
            <!--                                        (click)="Data.addedTemplateIds?.includes(rowData.Id) && multi ? null : ClickTablerow(rowData)"-->
            <!--                                        [class.highlight]="SelectedRowIndex === rowData.Id"-->
            <!--                                        [class.dont-edit]="rowData.dontEditHint"-->
            <!--                                        [class.disabled]="Data.addedTemplateIds?.includes(rowData.Id)"-->
            <!--                                        matTooltip=" {{ getTooltip(rowData.Id) }} "-->
            <!--                                    ></tr>-->
            <!--                                </ng-container>-->
            <!--                            </ng-container>-->
            <!--                            <ng-container *ngIf="Data.ListView">-->
            <!--                                <tr mat-row *matRowDef="let rowData; columns: ARLTemplateColumns" class="clickable-list-item" (click)="ShouldGenerateTemplate = false" [class.highlight]="SelectedRowIndex === rowData.Id" [class.dont-edit]="rowData.dontEditHint"></tr>-->
            <!--                            </ng-container>-->
            <!--                        </table>-->

            <!--                        <table *ngIf="(BookingPositionTemplates$ | async)?.length === 0" style="width: 100%">-->
            <!--                            <tr>-->
            <!--                                <td class="empty-table-text">Keine Vorlagen vorhanden</td>-->
            <!--                            </tr>-->
            <!--                        </table>-->
        </ngx-simplebar>
    </div>
    <button app-button style="margin: .5rem auto -.5rem .5rem" *ngIf="Multiselect | async" [IsLoading]="LS.IsLoading$ | async" (click)="MultipleSave$.next()" [Disabled]="!SelectedTemplates.length">
        hinzufügen
    </button>
</mat-card>
<ng-template #loading>
    <div style="width: 76rem; height: 40rem">
        <app-loading></app-loading>
    </div>
</ng-template>
