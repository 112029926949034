import {createFeatureSelector, createSelector} from '@ngrx/store';
import {permissionsFeatureKey, PermissionsState} from '../reducers/permissions.reducer';
import {State} from '../index';

export const selectPermissions = createFeatureSelector< PermissionsState>(permissionsFeatureKey);

export const getRoles = createSelector(
  selectPermissions,
  permissions => permissions ? permissions.roles : null
);


export const getPermissions = createSelector(
  selectPermissions,
  permissions => permissions ? permissions.permissions : null,
);


export const getPermissionsFetched = createSelector(
  selectPermissions,
  permissions => !!permissions.permissions,
);

