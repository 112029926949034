import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { GeneratedDocumentsTypeResolver } from '../dave-data-module/guards/generatedDocumentsType.resolver';
import { State } from '../dave-data-module/State';
import { getGeneratedDocumentsTypes, getGeneratedDocumentsTypesFetched } from '../dave-data-module/State/selectors/generatedDocumentsType.selectors';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { AllGeneratedDocumentsMeta, DocumentEditorMeta, GeneratedDocumentsPageMeta } from '../helper/page-metadata';
import { AddReportService } from './add-report.service';
import { GeneratedDocumentsActionTypes } from '../dave-data-module/State/actions/generatedDocuments.actions';
import {
    SelectDiaryTimespanDialogComponent,
    SelectDiaryTimespanDialogComponentDialogData,
} from '../dave-commission-module/components/detail-commission/select-diary-timespan-dialog/select-diary-timespan-dialog.component';
import { Moment } from 'moment/moment';
import { MatDialog } from '@angular/material/dialog';
import {
    GeneratedDocumentsTypeEntity,
    GeneratedDocumentsTypeSlugs, GeneratedDocumentsTypeSlugsForDiary,
} from '../dave-data-module/entities/generatedDocumentsType.entity';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-add-report-button',
    standalone: true,
    imports: [CommonModule, DaveDoubleIconModule, AppButtonModule, MatTooltipModule, MatMenuModule],
    templateUrl: './add-report-button.component.html',
    styleUrls: ['./add-report-button.component.scss'],
})
export class AddReportButtonComponent {
    private commissionId$ = new BehaviorSubject<number | null>(null);
    private presetType$ = new BehaviorSubject<GeneratedDocumentsTypeSlugs | null>(null);
    private excludedTypes$ = new BehaviorSubject<GeneratedDocumentsTypeSlugs[] | null>(null);
    @Input() set PreselectedType(t: GeneratedDocumentsTypeSlugs) {
        this.presetType$.next(t);
    };
    @Input() set ExcludeTypes(t: GeneratedDocumentsTypeSlugs[]) {
        this.excludedTypes$.next(t);
    };
    @Input() Inverted = true;
    @Input() CustomerId: number = null;
    @Input() ResourceId: number = null;
    @Input() MaterialId: number = null;
    @Input() set CommissionId(id: number) {
        this.commissionId$.next(id);
    }
    get CommissionId() {
        return this.commissionId$.value
    }
    Types$ = this.store.select(getGeneratedDocumentsTypesFetched).pipe(
        tap((fetched) => {
            if (!fetched) {
                this.generatedDocumentsTypesResolver.resolve();
            }
        }),
        filter((f) => f),
        switchMap(() => combineLatest([this.store.select(getGeneratedDocumentsTypes), this.commissionId$]).pipe(map(([types, cId]) => cId ? types : types.filter(t => !t.IsForDiary)))),
        switchMap((types) => this.presetType$.pipe(map((presetType) => presetType ? types.filter(t => t.Slug === presetType) : types))),
        switchMap((types) => this.excludedTypes$.pipe(map((excludedTypes) => excludedTypes ? types.filter(t => !excludedTypes.includes(t.Slug)) : types))),
        shareReplay({ bufferSize: 1, refCount: true }),
);
    constructor(protected AddReportService: AddReportService, protected store: Store<State>, protected router: Router, protected generatedDocumentsTypesResolver: GeneratedDocumentsTypeResolver, private dialog: MatDialog) {}

    protected readonly GeneratedDocumentsPageMeta = GeneratedDocumentsPageMeta;
    AddReport(type: GeneratedDocumentsTypeEntity) {
        if (type.IsForDiary) {
            this.dialog.open<SelectDiaryTimespanDialogComponent, SelectDiaryTimespanDialogComponentDialogData>(SelectDiaryTimespanDialogComponent, {
                data: { CommissionId: this.CommissionId, DiaryType: type.Slug as GeneratedDocumentsTypeSlugsForDiary },
            });
        } else {
            this.AddReportService.NewReport(type, this.CustomerId, this.CommissionId, null, this.ResourceId).then(action => {
                if (action.type === GeneratedDocumentsActionTypes.UpdateOne.type) {
                    this.router.navigate([GeneratedDocumentsPageMeta.Path, AllGeneratedDocumentsMeta.Path, DocumentEditorMeta.Path, action.Payload.Id]);
                }
            })
        }
    }
    btnClick() {
        firstValueFrom(this.Types$).then(types => {
            if (types?.length === 1) {
                this.AddReport(types[0]);
            }
        })
    }
}
