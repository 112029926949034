import { GroupType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
import { GroupMemberEntity, GroupMemberEntityFromBackend } from './group-member.entity';

export class GroupEntity {
    constructor(
        public CommissionId: number = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public GroupMembers: GroupMemberEntity[] = null,
        public Id: number = null,
        public Name: string = null,
        public PartnerId: number = null,
        public UpdatedAt: Date = null,
        public UserId: number = null,
    ) {}

    public static readonly GqlFields: Array<keyof GroupType> = ['commissionId', 'createdAt', 'deletedAt', `groupMembers {${GroupMemberEntity.GqlFields.join(',')}}` as keyof GroupType, 'id', 'partnerId', 'updatedAt', 'userId', 'name'];

    public Clone(override: Partial<GroupEntity> = {}): GroupEntity {
        const attr = { ...this, ...override };
        return Object.assign(new GroupEntity(), attr);
    }
}

export function GroupEntityFromBackend(res: GroupType): GroupEntity {
    return new GroupEntity(
        res.commissionId,
        BackendDate(res.createdAt),
        res.deletedAt && BackendDate(res.deletedAt),
        res.groupMembers.map(GroupMemberEntityFromBackend),
        res.id,
        res.name,
        res.partnerId,
        BackendDate(res.updatedAt),
        res.userId,
    );
}
