import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { isNotNullOrUndefined } from 'src/app/helper/helper';
import { HttpService } from '../dave-data-module/services/http.service';
import { State } from '../dave-data-module/State';
import { getToken } from './../dave-data-module/State/selectors/base.selectors';

@Injectable({
    providedIn: 'root',
})
export class SfdtConverterService {
    ServiceUrl = this.api.GetUrl('DocumentEditor/', 'sfdt');
    constructor(private api: HttpService, private store: Store<State>) {}
    public ConvertToSfdt(blob: Blob, succCallback:(req: XMLHttpRequest) => void) {
        return this.store
            .select(getToken)
            .pipe(filter(isNotNullOrUndefined), take(1))
            .toPromise()
            .then(token => {

                // JavaScript file-like object
                var content = '<a id="a"><b id="b">hey!</b></a>'; // the body of the new file...
                var blob2 = new Blob([content], { type: "text/xml"});
                console.log({blob2});

                const formData = new FormData(),
                      httpRequest = new XMLHttpRequest();

                formData.append('files', blob);

                httpRequest.open('Post', this.ServiceUrl + 'Import', true);
                // httpRequest.setRequestHeader('Content-Type', 'multipart/form-data;');
                httpRequest.setRequestHeader('Authorization', 'Bearer ' + token);
                // httpRequest.responseType = 'blob';
                // Define what happens on successful data submission
                httpRequest.onreadystatechange = () => {
                    if (httpRequest.readyState === 4) {
                        if (
                            httpRequest.status === 200 ||
                            httpRequest.status === 304
                        ) {
                            succCallback(httpRequest);
                        }
                        else {
                            console.error('Get sfdt request failed', httpRequest);
                        }
                    }
                };

                httpRequest.send(formData);
            });
    }
}
