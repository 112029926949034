import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MaterialEntity } from '../../entities/material.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { MaterialActionTypes } from '../actions/material.actions';

export const Material_KEY = 'Material';

export interface MaterialState extends EntityState<MaterialEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;
}

const adapter = createEntityAdapter<MaterialEntity>({
    selectId: Entity => Entity.Id,
    sortComparer: (a, b) => (a.Name || '').localeCompare((b.Name || '')),
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const MaterialsReducer = createReducer(
    initialState,
    on(MaterialActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(MaterialActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(MaterialActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
    on(MaterialActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, state)),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllMaterial = selectAll;
export const selectMaterialEntities = selectEntities;

export const selectMaterialFetched = (state: MaterialState) => state.fetched;
export const selectMaterialLatestUpdatedAt = (state: MaterialState) => state.latestUpdatedAt;
