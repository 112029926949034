<mat-card>
    <mat-card-title>Es ist etwas schief gelaufen.</mat-card-title>
    <mat-card-content>Es scheint so als hätten Sie Verbindungsprobleme, <br> Sie können es erneut versuchen.</mat-card-content>
    <mat-card-actions>
        <div class="cancel-button-wrapper">
            <button app-button Inverted (click)="PopupService.CloseErrorPopup()">schließen</button>
        </div>
        <button app-button (click)="reload()">erneut verbinden</button>
    </mat-card-actions>
</mat-card>
