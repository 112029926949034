import { CustomerSpecificationTypeEntity } from '../../entities/customerSpecificationType.entity';
import { createReducer, on } from '@ngrx/store';
import { CustomerSpecificationTypeActionTypes } from '../actions/customerSpecificationType.actions';


export const customerSpecificationTypesFeatureKey = 'customerSpecificationTypes';

export interface CustomerSpecificationTypeState {
    customerSpecificationTypes: CustomerSpecificationTypeEntity[];
}

export const initialState: CustomerSpecificationTypeState = {
    customerSpecificationTypes: null,
};

export const CustomerSpecificationTypeReducer = createReducer(
    initialState,
    on(CustomerSpecificationTypeActionTypes.UpdateCustomerSpecificationType, (state, { Payload }) => ({
        ...state,
        customerSpecificationTypes: Payload,
    })),
);
