<app-dave-list
    [ColumnConfig]="TableColumns"
    [Columns]="DisplayedColumns$ | async"
    [ColumnsSmall]="DisplayedColumnsSmall$ | async"
    [DataSource$]="DataSource$"
    [Headline]="cls.getMultiple$('Commission') | async"
    [HeadlineIcon]="CommissionIcon"
    [NewEntryToolTip]="(cls.getSingle$('Commission') | async) + 'Hinzufügen'"
    [EmptyText]="'Keine' + (cls.getMultiple$('Commission') | async) + ' Vorhanden'"
    [FilterValues]="FilterValues$ | async"
    (FilterValuesChange)="FilterValues$.next($event)"
    [FilterSettings]="FilterSettings$ | async"
    [FilterCount]="FilterAmount$ | async"
    [DefaultSortDirection]="DefaultSortDirection"
    [DefaultSortActive]="DefaultSortActive"
    (SortChange)="defaultTableSortingService.setSort('commissionList', $event)"
>
    <ng-container *appDetailViewButtons>
        <button class="header-button" app-round-button Inverted matTooltip="Demo - Berechnung" (click)="OpenDemo()" *ngIf="AkkordDemoButton">
            <fa-icon icon="euro-sign"></fa-icon>
        </button>
        <!--<button class="header-button" app-round-button Inverted *ngIf="CommissionExportButton" [matTooltip]="(cls.getMultiple$('Commission') | async) + ' als CSV Exportieren'" matTooltipPosition="right" (click)="CsvDownload()">
            <app-double-icon Icon="file-contract" Icon2="download"></app-double-icon>
        </button>
    -->


        <button
            class="header-button"
            app-round-button
            Inverted
            *ngIf="NewCommissionButton"
            [matTooltip]="(cls.getSingle$('Commission') | async) + ' hinzufügen'"
            matTooltipPosition="right"
            (click)="CreateCommission()"
            [Disabled]="!(PS.Has(PS.Permission.CreateCommission) | async) || LS.isLoading"
        >
            <app-double-icon Icon="file-contract" Icon2="plus"></app-double-icon>
        </button>
    </ng-container>
</app-dave-list>
