import {AfterContentChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import { State } from '../../dave-data-module/State';
// import {VerifyLicenseOrder} from '../../actions/data.actions';

@Component({
    selector: 'app-verify-order',
    templateUrl: './verify-order.component.html',
    styleUrls: ['./verify-order.component.scss']
})
export class VerifyOrderComponent implements OnInit {
    public GoodLink = false;

    constructor(
        private store: Store<State>,
        private route: ActivatedRoute ) {
    }

    ngOnInit() {
        const orderId: number = +this.route.snapshot.paramMap.get(
            'orderId'
        );
        const verificationCode: string = this.route.snapshot.paramMap.get(
            'verificationCode'
        );
        if (orderId && verificationCode) {
            this.GoodLink = true;
            /* this.store.dispatch(new VerifyLicenseOrder({
                verificationCode,
                id: orderId
            })); */
        } else {
            this.GoodLink = false;

        }

    }

}
