import { Action, createReducer, on } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import {BaseActionTypes} from '../actions/base.actions';
import { DecodedTokenType, TokenEntity } from '../../entities/token.entity';


export const baseFeatureKey = 'base';

export interface BaseState {
    token: string;
    performingLogin: boolean;
    tokenPayload: TokenEntity;
}

export const initialBaseState: BaseState = {
    token: null,
    performingLogin: false,
    tokenPayload: null
};


export const baseReducer = createReducer(
    initialBaseState,
    on(BaseActionTypes.PerformLogin, (state, { Payload }) => ({
        ...state,
        performingLogin: true
    })),
    on(BaseActionTypes.UpdateToken, (state, { Payload }) => ({
        ...state,
        token: Payload,
        performingLogin: false,
        tokenPayload: GetDecodeAccessToken(Payload),
    })),
    // on(BaseActionTypes.InitStore, () => initialBaseState),
);

function GetDecodeAccessToken(token: string) {
  try {
    const t = jwt_decode<DecodedTokenType>(token);
    return new TokenEntity(t.firstname, t.lastname, +t.partnerId, +t.userId, +t.iat, t.exp, t.iss, t.aud);
  } catch (e) {
    return null;
  }
}
