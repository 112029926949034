import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { TextTemplatesEntity } from '../../../dave-data-module/entities/text-templates.entity';
import { TextTemplateLanguageResolver } from '../../../dave-data-module/guards/text-template-language.resolver';
import { TextTemplatesResolver } from '../../../dave-data-module/guards/text-templates.resolver';
import { State } from '../../../dave-data-module/State';
import { getTextTemplateLanguage, getTextTemplateLanguageFetched } from '../../../dave-data-module/State/selectors/text-template-language.selectors';
import { getTextTemplates, getTextTemplatesFetched } from '../../../dave-data-module/State/selectors/textTemplates.selector';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { SearchQueriesDebounceTime, stringSearch } from '../../../helper/helper';

export interface SelectTextTemplatesComponentDialogResult {
    textTemplates: TextTemplatesEntity[];
}
export interface SelectTextTemplatesComponentDialogData {
    SelectedTextTemplatesIds?: number[];
    Language?: string;
}

interface TableData {
    IdCode?: string;
    ShortDescription?: string;
    Description?: string;
    MatchingCode?: string;
    Category?: string;
    QuantityType?: string;
    TextTemplate?: TextTemplatesEntity;
    selected$: Observable<boolean>;
}

@Component({
    selector: 'app-select-text-templates',
    templateUrl: './select-text-templates.component.html',
    styleUrls: ['./select-text-templates.component.scss'],
})
export class SelectTextTemplatesComponent implements AfterViewInit, OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        maxWidth: '100%',
        maxHeight: 'calc(100vh - 3.5rem)',
        panelClass: ['custom-dialog-class-mobile-fullscreen' , 'custom-dialog-class-without-padding'],
    };
    @ViewChild(MatSort) private matSort?: MatSort;

    public SelectedRowIndex: number;
    public SelectedTextTemplates$ = new BehaviorSubject<TextTemplatesEntity[]>([]);
    private language = 'DE';
    public TextTemplatesColumnHeaders = {
        IdCode: 'Nummer',
        ShortDescription: 'Kurztext',
        Description: 'Beschreibung',
        MatchingCode: 'Matchcode',
        Category: 'Gruppe',
        QuantityType: 'Einh',
    };
    public TextTemplatesAutoColumns: Array<keyof TableData> = ['IdCode', 'ShortDescription', 'Description', 'MatchingCode', 'Category', 'QuantityType'];
    private searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public TextTemplatesColumns$: Observable<Array<keyof TableData | 'checkbox'>> = this.bs.MobileQuery.pipe(
        map(mobile =>
            mobile
                ? ['checkbox', 'IdCode', 'ShortDescription', 'QuantityType']
                : ['checkbox', 'IdCode', 'ShortDescription', 'Description', 'MatchingCode', 'Category', 'QuantityType'],
        ),
    );
    private subscription: Subscription;
    public DataSource$: BehaviorSubject<TableVirtualScrollDataSource<TableData>> = new BehaviorSubject(new TableVirtualScrollDataSource<TableData>([]));

    constructor(
        private store: Store<State>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: SelectTextTemplatesComponentDialogData,
        protected bs: BreakpointObserverService,
        protected dialogRef: MatDialogRef<SelectTextTemplatesComponent, SelectTextTemplatesComponentDialogResult>,
        textTemplateResolver: TextTemplatesResolver,
        textTemplateLanguageResolver: TextTemplateLanguageResolver,
    ) {
        textTemplateResolver.resolve();
        textTemplateLanguageResolver.resolve();
        if (this.Dialogdata.Language) {
            this.language = this.Dialogdata.Language;
        }
        if (Dialogdata.SelectedTextTemplatesIds) {
            this.store
                .select(getTextTemplatesFetched)
                .pipe(
                    filter(fetched => !!fetched),
                    switchMap(() => this.store.select(getTextTemplates)),
                    take(1),
                )
                .subscribe(templates => {
                    this.SelectedTextTemplates$.next(templates.filter(t => Dialogdata.SelectedTextTemplatesIds.includes(t.Id)));
                });
        }
    }
    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.subscription = combineLatest([
            this.store.select(getTextTemplates),
            this.store.select(getTextTemplateLanguage).pipe(map(templates => templates?.filter(t => t.Language === this.language))),
            this.store.select(getTextTemplatesFetched).pipe(filter(v => !!v)),
            this.store.select(getTextTemplateLanguageFetched).pipe(filter(v => !!v)),
        ])
            .pipe(
                map(([textTemplates, textTemplateLanguage]) => {
                    return new TableVirtualScrollDataSource(
                        textTemplates.map<TableData>(textTemplate => {
                            const lang = textTemplateLanguage.find(t => t.TextTemplateId === textTemplate.Id);
                            return {
                                IdCode: textTemplate.IdCode,
                                ShortDescription: lang.ShortText,
                                Description: lang.Text,
                                MatchingCode: textTemplate.MatchingCode,
                                Category: textTemplate.Category,
                                QuantityType: textTemplate.QuantityType,
                                TextTemplate: textTemplate,
                                selected$: this.SelectedTextTemplates$.pipe(map(templates => templates.some(v => v.Id === textTemplate.Id))),
                            };
                        }),
                    );
                }),

                // Der Standard-`sortingDataAccessor` kommt mit numerischen Strings - wie der
                // Vertragsnummer - nicht klar und sortiert nicht. Workaround:
                tap(
                    dataSource =>
                        (dataSource.sortingDataAccessor = (object: TableData, key: string) => {
                            switch (key) {
                                default:
                                    return object[key];
                            }
                        }),
                ),

                // Vergleichsfunktion zum Freitext-Filtern
                tap(
                    dataSource =>
                        (dataSource.filterPredicate = (data, searchTerm) =>
                            [data.IdCode, data.ShortDescription, data.Description, data.MatchingCode, data.Category, data.QuantityType].some(
                                value => value && stringSearch(value, searchTerm),
                            )),
                ),
                // this.matSort sollte hier immer gesetzt sein (da ngAfterViewInit gefeuert haben muss),
                // aber das weiß TypeScript natürlich nicht.
                tap(dataSource => {
                    if (this.matSort) {
                        dataSource.sort = this.matSort;
                    } else {
                        console.warn('no matSort');
                    }
                }),

                // Sucheingaben an die DataSource weitergeben
                switchMap(dataSource =>
                    this.searchString.pipe(
                        debounceTime(SearchQueriesDebounceTime),
                        tap(searchTerm => (dataSource.filter = searchTerm.trim().toLowerCase())),
                        map(() => dataSource),
                    ),
                ),
            )
            .subscribe(v => {
                this.DataSource$.next(v);
            });
    }

    CheckBoxSelectTextTemplates(event: MatCheckboxChange, rowData: TableData) {
        let v = this.SelectedTextTemplates$.value.slice();
        const index = v.findIndex(v => v.Id === rowData.TextTemplate.Id);
        if (!event?.checked && index > -1) {
            v.splice(index, 1);
        } else {
            v.push(rowData.TextTemplate);
        }
        this.SelectedTextTemplates$.next(v);
    }

    RowClickSelectTextTemplates(rowData: TableData) {
        let v = this.SelectedTextTemplates$.getValue();
        const index = v.findIndex(v => v.Id === rowData.TextTemplate.Id);
        if (index > -1) {
            v.splice(index, 1);
        } else {
            v.push(rowData.TextTemplate);
        }
        this.SelectedTextTemplates$.next(v);
    }

    RemoveSelectedTextTemplates(textTemplate: TextTemplatesEntity) {
        let v = this.SelectedTextTemplates$.getValue();
        const index = v.findIndex(v => v.Id === textTemplate.Id);
        if (index > -1) {
            v.splice(index, 1);
        }
        this.SelectedTextTemplates$.next(v);
    }

    public DoSearch(value: string | null) {
        return this.searchString.next(value);
    }

    public Close() {
        this.dialogRef.close({ textTemplates: this.SelectedTextTemplates$.getValue() });
    }
}
