import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {DaveActions} from '../actions/actions';
import {CustomerTypeActionTypes} from '../actions/customer-types.actions';
import {ResolverActionTypes} from '../actions/resolver.actions';
import {CustomerLocationEntityFromBackend} from '../../entities/customer-location.entity';
import {HttpService} from '../../services/http.service';
import {State} from '../index';
import {BaseActionTypes} from '../actions/base.actions';

enum ErrorCodes {
    Load = 'Kundensitz Abrufen fehlgeschlagen',
}

@Injectable()
export class CustomerLocationsEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService
    ) {
    }


    LoadCustomerLocations$ = createEffect(() => this.actions$.pipe(
        ofType(ResolverActionTypes.LoadCustomerLocations),
        withLatestFrom(this.store$),
        switchMap(([_, store]) => {
            const queryString = `
            query {
                customerlocation {
                    hQLocation
                }
            }`;
            return this.gatewayHttpService
                .graphQl({query: queryString}, {token: store.base.token,  retry: true })
                .pipe(
                    map(res =>
                        res && res.customerlocation
                            ? CustomerTypeActionTypes.UpdateCustomerLocations({
                                Payload: res.customerlocation.map(val =>
                                    CustomerLocationEntityFromBackend(val)
                                )
                            })
                            : BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Load}})
                    ),
                    catchError((err, caught) => of(BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Load,
                            Err: err,
                            Caught: caught
                        }
                    })))
                );
        })
    ));
}
