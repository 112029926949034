import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  DamageFlowTypeState,
  damageFlowTypesFeatureKey
} from "../reducers/damageFlowType.reducer";
import { State } from "../index";

export const selectDamageFlowTypes = createFeatureSelector<
  DamageFlowTypeState
>(damageFlowTypesFeatureKey);
export const getDamageFlowTypes = createSelector(
  selectDamageFlowTypes,
  data => data.damageFlowTypes
);
export const getDamageFlowTypesFetched = createSelector(
  selectDamageFlowTypes,
  data => !!data.damageFlowTypes
);
export const getSingleDamageFlowType = createSelector(
  getDamageFlowTypes,
  (damageFlowType: ReturnType<typeof getDamageFlowTypes>, Id: number) =>
    damageFlowType ? damageFlowType.find(e => e.Id === Id) : null
);
