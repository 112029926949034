import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MaterialGroupEntity } from '../../entities/material-group.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { MaterialGroupActionTypes } from '../actions/material-group.actions';

export const MATERIAL_GROUP_KEY = 'materialGroup';

export interface MaterialGroupState extends EntityState<MaterialGroupEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;
}

const adapter = createEntityAdapter<MaterialGroupEntity>({
    selectId: (Entity) => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const materialGroupReducer = createReducer(
    initialState,
    on(MaterialGroupActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(MaterialGroupActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
    on(MaterialGroupActionTypes.CreateSuccess, (state, { Payload }) => adapter.addOne(Payload, state)),
    on(MaterialGroupActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, state)),
    on(MaterialGroupActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllMaterialGroups = selectAll;
export const selectMaterialGroupEntities = selectEntities;

export const selectMaterialGroupFetched = (state: MaterialGroupState) => state.fetched;
export const selectMaterialGroupLatestUpdatedAt = (state: MaterialGroupState) => state.latestUpdatedAt;
