import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MaterialListEntity } from '../../entities/material-list.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { MaterialListActionTypes } from '../actions/material-list.actions';

export const MATERIAL_LIST_KEY = 'materialList';

export interface MaterialListState extends EntityState<MaterialListEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;
}

const adapter = createEntityAdapter<MaterialListEntity>({
    selectId: (Entity) => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const materialListsReducer = createReducer(
    initialState,
    on(MaterialListActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(MaterialListActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
    on(MaterialListActionTypes.CreateSuccess, (state, { Payload }) => adapter.addOne(Payload, state)),
    on(MaterialListActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, state)),
    on(MaterialListActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllMaterialList = selectAll;
export const selectMaterialListEntities = selectEntities;

export const selectMaterialListFetched = (state: MaterialListState) => state.fetched;
export const selectMaterialListLatestUpdatedAt = (state: MaterialListState) => state.latestUpdatedAt;
