<mat-form-field [class.full-width]="FullWidth" [appearance]="appearance">
    <fa-icon matSuffix icon="times" style="margin-left: 3px" (click)="Control?.setValue(null);$event.stopPropagation();SelectionChange.emit({source: singleSelect, value: {Id:null}});singleSelect.value=null" *ngIf="!Required && !Disabled && !Control.disabled"></fa-icon>

    <mat-select
        [disabled]="Disabled"
        [required]="Required"
        ngDefaultControl
        [formControl]="Control"
        [placeholder]="Placeholder"
        #singleSelect
        (selectionChange)="SelectionChange.emit($event)"
        (openedChange)="openChange($event)"
    >
        <mat-option
            [value]="SelectedOptionBuffer"
            style="display: none"
        >
            {{ SelectedOptionBuffer?.Name }}
        </mat-option>
        <mat-option>
            <ngx-mat-select-search
                [formControl]="SearchValue"
            ></ngx-mat-select-search>
        </mat-option>
        <cdk-virtual-scroll-viewport itemSize="48" minBufferPx="200" maxBufferPx="400" class="example-viewport-select">
            <mat-option
                *cdkVirtualFor="let value of FilteredValues | async"
                [value]="value"
            >
                {{ value.Name }}
            </mat-option>
        </cdk-virtual-scroll-viewport>

    </mat-select>
</mat-form-field>
