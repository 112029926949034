<h1 mat-dialog-title *ngIf="!Dialogdata?.IsNotOrtstermin">
    <fa-icon icon="map-marker-alt"></fa-icon>
    Ortstermin anlegen
</h1>
<h1 mat-dialog-title *ngIf="Dialogdata?.IsNotOrtstermin">
    <fa-icon icon="history"></fa-icon>
    Ereignis anlegen
</h1>
<form>
    <div mat-dialog-content>
        <app-event-types-selector-card
            style="max-width: 46rem"
            *ngIf="Dialogdata?.IsNotOrtstermin"
            [EventTypeList]="EventTypeList"
            [CurrentEventType]="NewEventForm.controls.EventType.value"
            (EventTypeChange)="OnEventTypeChange($event)"
        ></app-event-types-selector-card>
        <div class="contactPersonWrapper" *ngIf="!Dialogdata?.PersonId">
            <mat-form-field class="type">
                <app-select-search [placeholder]="this.Customer ? this.Customer.AnsprechpartnerText() : 'Ansprechpartner / Sachbearbeiter'" [formControl]="NewEventForm.controls.Person" [CompareOptions]="compareById" [Options]="PersonList"></app-select-search>

                <mat-error *ngIf="NewEventForm.controls.Person.invalid">
                    {{ GetErrorMessage(NewEventForm.controls.Person) }}
                </mat-error>
            </mat-form-field>
            <span class="button" [matTooltip]="this.Customer ? '' : (CS.GetSingle$() | async) + ' wählen'">
                <button type="button" app-round-button class="button" [matTooltip]="this.Customer?.AnsprechpartnerText()" (click)="CreateNewPerson()">
                    <fa-icon icon="plus"></fa-icon>
                </button>
            </span>
        </div>
        <div class="contactPersonWrapper" *ngIf="!Dialogdata?.CommissionId">
            <app-select-search-legacy [Values]="CommissionList" [Placeholder]="cls.getSingle$('Commission') | async" [Control]="NewEventForm.controls.Commission"></app-select-search-legacy>
        </div>
        <div class="contactPersonWrapper" *ngIf="!Dialogdata?.ProcessId">
            <mat-form-field class="type">
                <app-select-search placeholder="Prozess" [formControl]="NewEventForm.controls.Process" [Options]="ProcessList$ | async" [CompareOptions]="compareById"></app-select-search>
                <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer;" (click)="NewEventForm.controls.Process.setValue(null);$event.stopPropagation();" *ngIf="!NewEventForm.controls.Process.disabled"></fa-icon>
                <mat-error *ngIf="NewEventForm.controls.Process.invalid">
                    {{ GetErrorMessage(NewEventForm.controls.Process) }}
                </mat-error>
            </mat-form-field>
            <!--            <app-select-search-legacy [Values]="CustomerList" [Placeholder]="'Kunde'" [Control]="NewEventForm.controls.Customer"></app-select-search-legacy>-->
        </div>
        <div class="contactPersonWrapper" *ngIf="!Dialogdata?.CustomerId">
            <app-select-search-legacy [Values]="CustomerList" [Placeholder]="'Kunde'" [Control]="NewEventForm.controls.Customer"></app-select-search-legacy>
        </div>

        <div class="date-time-picker">
            <mat-form-field>
                <mat-label>Datum</mat-label>
                <mat-date-range-input [rangePicker]="picker" required>
                    <input matStartDate placeholder="Start" [formControl]="NewEventForm.controls.EventDate" required>
                    <input matEndDate placeholder="Ende" [formControl]="NewEventForm.controls.EventEndDate" required>
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field *ngIf="!NewEventForm.value.EventEndDate || (NewEventForm.value.EventDate && sameDay(NewEventForm.value.EventDate.toDate(), NewEventForm.value.EventEndDate.toDate()))">
                <mat-label>Zeitraum</mat-label>
                <app-time-span-picker-select>
                    <app-time-picker-select appStartTime required placeholder="Start" [formControl]="NewEventForm.controls.EventStartTimeString"></app-time-picker-select>
                    <app-time-picker-select appEndTime required placeholder="Ende" [min]="NewEventForm.value.EventStartTimeString" [offsetTimeForOptionLabel]="NewEventForm.value.EventStartTimeString" [formControl]="NewEventForm.controls.EventEndTimeString"></app-time-picker-select>
                </app-time-span-picker-select>
            </mat-form-field>
        </div>

        <div class="error-message">{{ Error }}</div>
        <app-textarea Key="Name" [Value]="NewEventForm.controls.Name"></app-textarea>
    <app-textarea Key="Notiz" [Value]="NewEventForm.controls.Note"></app-textarea>
        <app-detail-list-template (Submit)="Submit()" [Editing]="true" [Data]="AddressTemplateData$ | async" [Inline]="true" style="width: 100%; box-sizing: border-box"></app-detail-list-template>
    </div>
    <mat-dialog-actions class="buttons">
        <button Inverted type="button" app-button mat-dialog-close Color="cancel">Abbrechen</button>

        <button app-button type="submit" [Disabled]="NewEventForm.invalid" (click)="Submit()">Speichern</button>
    </mat-dialog-actions>
</form>
