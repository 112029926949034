import { StatusFromSVEntity } from '../../entities/statusFromSV.entity';
import { createReducer, on } from '@ngrx/store';
import { StatusFromSVActionTypes } from '../actions/statusFromSV.actions';


export const StatusFromSVFeatureKey = 'statusFromSV';

export interface StatusFromSVState {
    StatusFromSV: StatusFromSVEntity[];
}

export const initialState: StatusFromSVState = {
    StatusFromSV: null,
};

export const StatusFromSVReducer = createReducer(
    initialState,
    on(StatusFromSVActionTypes.UpdateStatusFromSV, (state, { Payload }) => ({
        ...state,
        StatusFromSV: Payload,
    })),
);
