import { NewsType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
export interface NewsEntityAdditionalData {

}
export class NewsEntity {
    constructor(
        public AdditionalData: NewsEntityAdditionalData = null,
        public AuthorName: string = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public HasBeenRead: boolean = null,
        public Headline: string = null,
        public Id: number = null,
        public PartnerId: number = null,
        public Text: string = null,
        public UpdatedAt: Date = null,
        public UserId: number = null,
) {
    }
    public static readonly GqlFields = `
    additionalData
    authorName
    createdAt
    deletedAt
    hasBeenRead
    headline
    id
    partnerId
    text
    updatedAt
    userId`;
    public Clone(override: NewsEntityAttributes = {}): NewsEntity {
        const attr = {...this, ...override};
        return Object.assign(new NewsEntity(), attr);
    }
}

export function NewsEntityFromBackend(res: NewsType): NewsEntity {
    return new NewsEntity(
        res.additionalData as any,
        res.authorName,
        res.createdAt ? BackendDate(res.createdAt) : null,
        res.deletedAt ? BackendDate(res.deletedAt) : null,
        res.hasBeenRead,
        res.headline,
        res.id,
        res.partnerId,
        res.text,
        res.updatedAt ? BackendDate(res.updatedAt) : null,
        res.userId,
    );
}

export interface NewsEntityAttributes {
    AdditionalData?: NewsEntityAdditionalData;
    AuthorName?: string;
    CreatedAt?: Date;
    DeletedAt?: Date;
    HasBeenRead?: boolean;
    Headline?: string;
    Id?: number;
    PartnerId?: number;
    Text?: string;
    UpdatedAt?: Date;
    UserId?: number;
}
