import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-history-side-elements',
    templateUrl: './history-side-elements.component.html',
    styleUrls: ['./history-side-elements.component.scss']
})
export class HistorySideElementsComponent {

    constructor() {
    }
}
