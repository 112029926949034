import { Moment } from 'moment/moment';
import { BehaviorSubject } from 'rxjs';
import { CommissionEntity } from '../dave-data-module/entities/commission.entity';
import { EventEntity, TaskPriorities, TaskPriorityNameColorIconMap } from '../dave-data-module/entities/event.entity';
import { UserEntity } from '../dave-data-module/entities/user.entity';
import { FilterOption, FILTER_TYPE_SEARCH_MULTI_SELECT, FILTER_TYPE_SEARCH_SELECT, FILTER_TYPE_TIMESPAN, IFilterTypeSearchMultiSelectValue } from '../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { FilterTypes } from '../services/default-filter.service';
import { isNotNullOrUndefined } from './helper';
import { CommissionMeta, UserAdministrationMeta } from './page-metadata';
import { EventTypeEntity } from '../dave-data-module/entities/event-type.entity';
import { PersonEntity } from '../dave-data-module/entities/person.entity';
import { BookmarkEntity } from '../dave-data-module/entities/bookmark.entity';

export type eventFilterTypes = FilterTypes.Priority | FilterTypes.Date | FilterTypes.EndDate | FilterTypes.CommissionId | FilterTypes.UserId | FilterTypes.AutorId | FilterTypes.Persons |FilterTypes.EventType;
export interface IEventFilterType {
    [FilterTypes.Priority]: IFilterTypeSearchMultiSelectValue[];
    [FilterTypes.Date]: { from: Moment; to: Moment };
    [FilterTypes.EndDate]: { from: Moment; to: Moment };
    [FilterTypes.CommissionId]: IFilterTypeSearchMultiSelectValue[];
    [FilterTypes.Persons]: IFilterTypeSearchMultiSelectValue[];
    [FilterTypes.EventType]: IFilterTypeSearchMultiSelectValue[];
    [FilterTypes.UserId]: IFilterTypeSearchMultiSelectValue[];
    [FilterTypes.AutorId]: IFilterTypeSearchMultiSelectValue[];
}
export const clacEventFilterAmount = (val: IEventFilterType) => {
    let filterAmount = 0;
    if (val[FilterTypes.Priority]?.length) {
        filterAmount++;
    }
    if (val[FilterTypes.Date]?.from || val[FilterTypes.Date]?.to) {
        filterAmount++;
    }
    if (val[FilterTypes.EndDate]?.from || val[FilterTypes.EndDate]?.to) {
        filterAmount++;
    }
    if (val[FilterTypes.CommissionId]?.length) {
        filterAmount++;
    }
    if (val[FilterTypes.Persons]?.length) {
        filterAmount++;
    }
    if (val[FilterTypes.EventType]?.length) {
        filterAmount++;
    }
    if (val[FilterTypes.UserId]?.length) {
        filterAmount++;
    }
    if (val[FilterTypes.AutorId]?.length) {
        filterAmount++;
    }
    return filterAmount;
};
export const getFilterSettings = (filters: eventFilterTypes[], editorUsers?: UserEntity[], authorUsers?: UserEntity[], commissions?: CommissionEntity[], eventTypes?: EventTypeEntity[], persons?: PersonEntity[], commissionLabel?: string): FilterOption[] => {
    const ret: FilterOption[] = [];
    if (filters.includes(FilterTypes.EventType)) {
        if (!eventTypes) {
            throw new Error('eventTypes must be set if FilterTypes.EventType is used');
        }
        const eventTypeValues: IFilterTypeSearchMultiSelectValue[] = [];
        eventTypeValues.push(
            ...eventTypes.map((c) => ({
                label: c.Name,
                id: c.Id,
            })),
        );
        ret.push({
            Name: FilterTypes.EventType,
            Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
            Label: 'Art des Ereignisses\n',
            // Icon: 'calendar',
            Values: eventTypeValues,
        });
    }
    if (filters.includes(FilterTypes.Persons)) {
        if (!persons) {
            throw new Error('persons must be set if FilterTypes.Persons is used');
        }
        const personValues: IFilterTypeSearchMultiSelectValue[] = [];
        personValues.push(
            ...persons.map((c) => ({
                label: c.DisplayName,
                id: c.Id,
            })),
        );
        ret.push({
            Name: FilterTypes.Persons,
            Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
            Label: 'Ansprechpartner',
            // Icon: 'calendar',
            Values: personValues,
        });
    }
    if (filters.includes(FilterTypes.Priority)) {
        const priorities: IFilterTypeSearchMultiSelectValue[] = TaskPriorities.map((c) => ({
            label: TaskPriorityNameColorIconMap.get(c).name,
            id: c,
        }));
        ret.push({
            Name: FilterTypes.Priority,
            Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
            Label: 'Priorität',
            Icon: 'arrow-up',
            Values: priorities,
        });
    }

    if (filters.includes(FilterTypes.Date)) {
        ret.push({
            Name: FilterTypes.Date,
            Type: FILTER_TYPE_TIMESPAN,
            Label: 'Datum',
            Icon: 'calendar',
        });
    }
    if (filters.includes(FilterTypes.EndDate)) {
        ret.push({
            Name: FilterTypes.EndDate,
            Type: FILTER_TYPE_TIMESPAN,
            Label: 'Frist',
            Icon: 'calendar',
        });
    }
    if (filters.includes(FilterTypes.CommissionId)) {
        if (!commissions || !commissionLabel) {
            throw new Error('commissions and commissionLabel must be set if FilterTypes.CommissionId is used');
        }
        const commissionValues: IFilterTypeSearchMultiSelectValue[] = commissions.map((c) => ({
                label: c.DisplayName,
                id: c.Id,
            }),
        );
        ret.push({
            Name: FilterTypes.CommissionId,
            Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
            Label: commissionLabel,
            Icon: CommissionMeta.Icon,
            Values: commissionValues,
        });
    }
    // Object.values(VacationTypeEnum).forEach((t) => {
    //     commissionValues.push({
    //         id: t,
    //         label: VacationTypeEnumNameMap.get(t),
    //     });
    // });
    //
    // Object.values(VacationTypeEnum).forEach((t) => {
    //     commissionValues.push({
    //         id: t,
    //         label: VacationTypeEnumNameMap.get(t),
    //     });
    // });

    if (filters.includes(FilterTypes.UserId) || filters.includes(FilterTypes.AutorId)) {
        if (!editorUsers || !authorUsers) {
            throw new Error('users must be set if FilterTypes.UserId or FilterTypes.AutorId is used');
        }
        const autorValues: IFilterTypeSearchMultiSelectValue[] = authorUsers.map((c) => ({
            label: c.DisplayName,
            id: c.Id,
        }));
        const userValues: IFilterTypeSearchMultiSelectValue[] = editorUsers.map((c) => ({
            label: c.DisplayName,
            id: c.Id,
        }));

        if (filters.includes(FilterTypes.UserId)) {
            ret.push({
                Name: FilterTypes.UserId,
                Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                Label: 'Bearbeiter',
                Icon: UserAdministrationMeta.Icon,
                Values: userValues,
            });
        }
        if (filters.includes(FilterTypes.AutorId)) {
            ret.push({
                Name: FilterTypes.AutorId,
                Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                Label: 'Autor',
                Icon: UserAdministrationMeta.Icon,
                Values: autorValues,
            });
        }
    }

    return ret;
};
export const filterEvents = (events: EventEntity[], filter: IEventFilterType) => {
    if (filter) {
        if (filter[FilterTypes.AutorId]?.length) {
            const ids = filter[FilterTypes.AutorId].map((e) => e.id);
            events = events.filter((e) => e.UserId && ids.includes(e.UserId));
        }
        if (filter[FilterTypes.UserId]?.length) {
            const ids = filter[FilterTypes.UserId].map((e) => e.id);
            events = events.filter((e) => e.UserIds?.length && e.UserIds.some((id) => ids.includes(id)));
        }
        if (filter[FilterTypes.Date]?.from) {
            events = events.filter((e) => e.EventDate && filter[FilterTypes.Date].from.isSameOrBefore(e.EventDate.getTime(), 'day'));
        }
        if (filter[FilterTypes.Date]?.to) {
            events = events.filter((e) => e.EventDate && filter[FilterTypes.Date].to.isSameOrAfter(e.EventDate.getTime(), 'day'));
        }
        if (filter[FilterTypes.EndDate]?.from) {
            events = events.filter((e) => e.EventEndDate && filter[FilterTypes.EndDate].from.isSameOrBefore(e.EventEndDate.getTime(), 'day'));
        }
        if (filter[FilterTypes.EndDate]?.to) {
            events = events.filter((e) => e.EventEndDate && filter[FilterTypes.EndDate].to.isSameOrAfter(e.EventEndDate.getTime(), 'day'));
        }
        if (filter[FilterTypes.CommissionId]?.length) {
            const ids = filter[FilterTypes.CommissionId].map((e) => e.id);
            events = events.filter((e) => e.CommissionId && ids.includes(e.CommissionId));
        }
        if (filter[FilterTypes.Priority]?.length) {
            const ids = filter[FilterTypes.Priority].map((e) => e.id);
            events = events.filter((e) => isNotNullOrUndefined(e.AdditionalData?.Priority) && ids.includes(e.AdditionalData.Priority));
        }
        if (filter[FilterTypes.Persons]?.length) {
            const ids = filter[FilterTypes.Persons].map((e) => e.id);
            events = events.filter((e) => e.PersonId && ids.includes(e.PersonId));
        }
        if (filter[FilterTypes.EventType]?.length) {
            const ids = filter[FilterTypes.EventType].map((e) => e.id);
            events = events.filter((e) => e.EventTypeId && ids.includes(e.EventTypeId));
        }
    }
    return events;
};
export const filterBookmarks = (bookmarks: BookmarkEntity[], filter: IEventFilterType) => {
    const filteredEvents = filterEvents(bookmarks.map(b => b.Event), filter);
    return bookmarks.filter(b => filteredEvents.includes(b.Event)); // maybe use id for compare
}
export const getEventFilterValues = (): BehaviorSubject<IEventFilterType> =>
    new BehaviorSubject({
        [FilterTypes.Priority]: null,
        [FilterTypes.Date]: null,
        [FilterTypes.EndDate]: null,
        [FilterTypes.CommissionId]: null,
        [FilterTypes.UserId]: null,
        [FilterTypes.AutorId]: null,
        [FilterTypes.Persons]: null,
        [FilterTypes.EventType]: null,
    });
