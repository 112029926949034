import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from '../../../dave-data-module/State';
import { FolderActionTypes } from '../../../dave-data-module/State/actions/folder.actions';
import { appMatDialogDefaultConfig } from '../../../helper/helper';

export interface NewFolderDialogComponentDialogData {
    rootFolderId: number | null;
}
@Component({
    selector: 'app-new-folder-dialog',
    templateUrl: './new-folder-dialog.component.html',
    styleUrls: ['./new-folder-dialog.component.scss'],
})
export class NewFolderDialogComponent {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
    };
    public NameForm: FormControlTyped<string> = new UntypedFormControl('', [Validators.required]);

    constructor(
        public DialogRef: MatDialogRef<NewFolderDialogComponent>,
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA)
        public Data: NewFolderDialogComponentDialogData,
    ) {}

    Save() {
        // console.log('Create Folder\nRootId: ' + this.Data.rootFolderId + '\nName: ' + this.NameForm.value);
        this.store.dispatch(FolderActionTypes.AddFolder({ Payload: { parentId: this.Data.rootFolderId, name: this.NameForm.value } }));
        this.DialogRef.close();
    }
}
