<mat-expansion-panel [expanded]="Expanded" (expandedChange)="ExpandedChange.emit($event)" [class.scrollable]="ScrollableStyle">
    <mat-expansion-panel-header class="top-shadow">
<!--        <span style="display: flex; align-items: center">-->
            <h3 style="margin-right: auto" *ngIf="!HideHeadline">{{Headline}}</h3>
            <ng-content select="[headerSuffix]"></ng-content>
<!--        </span>-->
    </mat-expansion-panel-header>
<!--    <div class="scroll-container" *matExpansionPanelContent>-->
<!--        <div class="top-shadow"></div>-->
        <ng-content></ng-content>
<!--        <div class="bottom-shadow">-->
<!--        </div>-->
<!--    </div>-->
    <mat-action-row *ngIf="ScrollableStyle"  class="bottom-shadow">
    </mat-action-row>
</mat-expansion-panel>
