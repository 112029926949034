import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, shareReplay, startWith, take, tap, withLatestFrom } from 'rxjs/operators';
import { EmailDialogData } from '../../components/detail-views/detail-event/email-dialog/email-dialog.component';
import { EmailConnectionEntity } from '../../dave-data-module/entities/email-connection.entity';
import { EmailEntity } from '../../dave-data-module/entities/email.entity';
import { EmailFolderEntity, EmailFolderEntityType } from '../../dave-data-module/entities/emailFolder.entity';
import { EventEntity } from '../../dave-data-module/entities/event.entity';
import { FileEntity } from '../../dave-data-module/entities/file.entity';
import { EmailConnectionResolver } from '../../dave-data-module/guards/email-connection.resolver';
import { EventTypeResolver } from '../../dave-data-module/guards/event-type.resolver';
import { EventResolver } from '../../dave-data-module/guards/event.resolver';
import { EmailDataService } from '../../dave-data-module/services/email-data.service';
import { FileDataService } from '../../dave-data-module/services/file-data.service';
import { State } from '../../dave-data-module/State';
import { BaseActionTypes } from '../../dave-data-module/State/actions/base.actions';
import { EmailActionTypes } from '../../dave-data-module/State/actions/email.actions';
import { getCommissions } from '../../dave-data-module/State/selectors/commission.selector';
import { getEmailConnections } from '../../dave-data-module/State/selectors/email-connection.selectors';
import { getEmailFolderById, getEmailFolders } from '../../dave-data-module/State/selectors/email-folders.selectors';
import { getEventFetched, getEvents } from '../../dave-data-module/State/selectors/events.selectors';
import { BreakpointObserverService } from '../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { PermissionService } from '../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { isNotNullOrUndefined, SearchQueriesDebounceTime } from '../../helper/helper';
import { AllCommissionMeta, CommissionMeta, ContactBookMeta, CustomerAdministrationMeta, HistoryMeta, TaskPageMeta } from '../../helper/page-metadata';
import { EmailEditorComponent, EmailEditorComponentDialogConfig, EmailEditorComponentDialogData } from './email-editor/email-editor.component';
import { getEmailFromAuthor } from './create-email-event/email-helper';
import {PersonResolver} from "../../dave-data-module/guards/person.resolver";
import {PersonTypeResolver} from "../../dave-data-module/guards/personType.resolver";
import { getSetting } from "../../dave-data-module/State/selectors/users.selectors";

const MIN_SEARCH_CHARS = 3;

interface NavFolder {
    icon: IconProp;
    text: string;
    textMenu: string;
    folder?: EmailFolderEntity;
    folderType: string;
    connectionId: number | null;
    order: number;
    children: NavFolder[];
    onClick: (nav: NavFolder) => void;
    emailUnreadCount$: Observable<number>;
    childrenUnreadCount$: Observable<number>;
}


interface ShowEmail {
    email: EmailEntity;
    event$: Observable<EventEntity | null>;
    canOpenCommission: boolean;
    emailConnectionId: number;
    srcEmail: string;
    toEmail: string[];
}
interface DataSource {
    emails$: Observable<ShowEmail[]>;
    folderType: EmailFolderEntityType;
}
type FolderIdType = number | 'shared-inbox' | 'shared-drafts' | 'shared-sent';

@Component({
    selector: 'app-email-client',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './email-client.component.html',
    styleUrls: ['./email-client.component.scss'],
})
export class EmailClientComponent implements OnDestroy, OnInit {
    public Multiselect = false;
    public Hide = true;
    public IsLastPage = false;
    public IsLoadingEmails = false;
    public SelectedMailIds: number[] = [];
    public StaticNavFolder: NavFolder[] = [
        {
            icon: 'plus',
            text: 'Verfassen',
            textMenu: 'Verfassen',
            folderType: '',
            order: 1,
            connectionId: null,
            children: [],
            onClick: () => this.OpenEmailEditor$.next({}),
            emailUnreadCount$: null,
            childrenUnreadCount$: null,
        },
        {
            icon: 'inbox',
            text: 'Gemeinsamer Posteingang',
            textMenu: 'Gemeinsamer Posteingang',
            folderType: 'inbox',
            order: 1,
            connectionId: null,
            children: [],
            onClick: () => this.staticFolderClick({ icon: 'inbox', text: 'Gemeinsamer Posteingang', folderId: 'shared-inbox' }),
            emailUnreadCount$: this.unreadCountForFolder$('shared-inbox'),
            childrenUnreadCount$: null,
        },
        {
            icon: 'pencil-alt',
            text: 'Gemeinsame Entwürfe',
            textMenu: 'Gemeinsame Entwürfe',
            folderType: 'drafts',
            order: 1,
            connectionId: null,
            children: [],
            onClick: () => this.staticFolderClick({ icon: 'pencil-alt', text: 'Gemeinsame Entwürfe', folderId: 'shared-drafts' }),
            emailUnreadCount$: this.unreadCountForFolder$('shared-drafts'),
            childrenUnreadCount$: null,
        },
        {
            icon: 'share',
            text: 'Gemeinsame Gesendete',
            textMenu: 'Gemeinsame Gesendete',
            folderType: 'sent',
            order: 1,
            connectionId: null,
            children: [],
            onClick: () => this.staticFolderClick({ icon: 'share', text: 'Gemeinsame Gesendete', folderId: 'shared-sent' }),
            emailUnreadCount$: this.unreadCountForFolder$('shared-sent'),
            childrenUnreadCount$: null,
        },
    ];
    private defaultFolder: NavFolder = this.StaticNavFolder[1];
    public NavListTreeControl = new NestedTreeControl<NavFolder>((node) => node.children);
    public NavList = new MatTreeNestedDataSource<NavFolder>();
    public SearchForm = new FormControl<string>('');
    public ShowSearchBar$ = new BehaviorSubject(false);
    public OpenEmailEditor$ = new Subject<EmailEditorComponentDialogData>();
    public EmailConnections$ = this.store.select(getEmailConnections).pipe(map((cons) => cons.filter((c) => !c?.Deleted)));
    public EmailConnectionId$ = new BehaviorSubject<number | null>(null);
    public FolderId$ = new BehaviorSubject<FolderIdType>(null);
    public EventsResolved$ = this.store.select(getEventFetched);
    public PreviewedEmailId$ = new BehaviorSubject<number | null>(null);
    public UnseenCount$ = this.FolderId$.pipe(
        switchMap((folderId) => this.unreadCountForFolder$(folderId)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public PreviewOpen = false;

    public ChronikPath = HistoryMeta.Path;
    public ContactPath = ContactBookMeta.Path;
    public HistoryMeta = HistoryMeta;
    public CustomerPath = CustomerAdministrationMeta.Path;
    public CustomerAdministrationMeta = CustomerAdministrationMeta;
    public CommissionPath = CommissionMeta.Path;
    public AllCommissionPath = AllCommissionMeta.Path;
    public CommissionIcon = CommissionMeta.Icon;
    public AnswerAll$ = new Subject<EmailEntity>();
    public FilterSeen$ = new BehaviorSubject<boolean | null>(null);
    public ListViewIcon: IconProp;
    public ListViewText: string;

    @ViewChild('mobileTabGroup') private tabGroup?: MatTabGroup;
    @ViewChild('scrollViewport') private scrollViewport?: CdkVirtualScrollViewport;

    public SearchIsPending$ = new BehaviorSubject(false);
    public MinSearchChars = MIN_SEARCH_CHARS;
    protected triggerSearch$ = new Subject<void>();
    public searchResults$ = combineLatest([
        this.SearchForm.valueChanges.pipe(
            tap((searchString) => {
                if (searchString) {
                    this.SearchIsPending$.next(true);
                }
            }),
            debounceTime(SearchQueriesDebounceTime),
            startWith(''),
            distinctUntilChanged(),
        ),
        this.FilterSeen$.pipe(
            distinctUntilChanged(),
            tap(() => this.SearchIsPending$.next(true)),
        ),
        this.FolderId$.pipe(
            map((f) => {
                let out: number | EmailFolderEntityType = EmailFolderEntityType.Inbox;
                if (typeof f === 'number') {
                    out = f;
                } else if (f === 'shared-drafts') {
                    out = EmailFolderEntityType.Drafts;
                } else if (f === 'shared-sent') {
                    out = EmailFolderEntityType.Sent;
                } else if (f === 'shared-inbox') {
                    out = EmailFolderEntityType.Inbox;
                }

                return out;
            }),
        ),
        this.triggerSearch$.pipe(startWith(() => {})),
    ]).pipe(
        switchMap(([search, seen, folder]) => (search ? this.emailDataService.GetEmailSearchString$(search, folder, seen) : of(null))),
        tap(() => {
            this.SearchIsPending$.next(false);
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    // public IsSearching$ = of(true)
    public IsSearching$ = this.SearchForm.valueChanges.pipe(
        startWith(''),
        map((search) => this.SearchForm.value?.length),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public DataSources$: Observable<Map<number | string, DataSource>> = this.store.select(getEmailFolders).pipe(
        distinctUntilChanged((a, b) => !a.some((v, i) => v.Id !== b[i].Id || v.Type !== b[i].Type)),
        map((folders) => {
            const initMap: [FolderIdType, DataSource][] = [
                [
                    'shared-inbox',
                    {
                        folderType: EmailFolderEntityType.Inbox,
                        emails$: of(null).pipe(
                            switchMap(() => combineLatest([this.searchResults$, this.FilterSeen$.pipe(distinctUntilChanged())])),
                            switchMap(([searchResults, seen]) => {
                                return searchResults ? of(searchResults) : this.emailDataService.GetFirstPage$(EmailFolderEntityType.Inbox, seen, false);
                            }),
                            map((emails) => emails.map<ShowEmail>(this.mapEmailToShowEmail)),
                        ),
                    },
                ],
                [
                    'shared-drafts',
                    {
                        folderType: EmailFolderEntityType.Drafts,
                        emails$: of(null).pipe(
                            switchMap(() => combineLatest([this.searchResults$, this.FilterSeen$.pipe(distinctUntilChanged())])),
                            switchMap(([searchResults, seen]) => {
                                return searchResults ? of(searchResults) : this.emailDataService.GetFirstPage$(EmailFolderEntityType.Drafts, seen, false);
                            }),
                            map((emails) => emails.map<ShowEmail>(this.mapEmailToShowEmail)),
                        ),
                    },
                ],
                [
                    'shared-sent',
                    {
                        folderType: EmailFolderEntityType.Sent,
                        emails$: of(null).pipe(
                            switchMap(() => combineLatest([this.searchResults$, this.FilterSeen$.pipe(distinctUntilChanged())])),
                            switchMap(([searchResults, seen]) => {
                                return searchResults ? of(searchResults) : this.emailDataService.GetFirstPage$(EmailFolderEntityType.Sent, seen, false);
                            }),
                            map((emails) => emails.map<ShowEmail>(this.mapEmailToShowEmail)),
                        ),
                    },
                ],
                ...folders.map<[FolderIdType, DataSource]>((f) => [
                    f.Id,
                    {
                        folderType: f.Type,
                        emails$: of(null).pipe(
                            switchMap(() => combineLatest([this.searchResults$, this.FilterSeen$.pipe(distinctUntilChanged())])),
                            switchMap(([searchResults, seen]) => (searchResults ? of(searchResults) : this.emailDataService.GetFirstPage$(f.Id, seen, false))),
                            map((emails) => emails.map<ShowEmail>(this.mapEmailToShowEmail)),
                        ),
                    },
                ]),
            ];
            return new Map<number | string, { emails$: Observable<ShowEmail[]>; folderType: EmailFolderEntityType }>(initMap);
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    // );

    private subscriptions: Subscription[] = [];
    private pollInterval: NodeJS.Timer;
    private currentId = 0;

    @ViewChild('input') private inputRef?: ElementRef<HTMLInputElement>;

    public IsMobile$ = this.breakpoints.MobileMailQuery;
    public TaskMeta = TaskPageMeta;
    protected emailPreviewSubscription: Subscription | null = null;
    private navTreeData$ = combineLatest([this.EmailConnections$, this.store.select(getEmailFolders).pipe(distinctUntilChanged((a, b) => a.length === b.length))]).pipe(
        map(([connections, folders]) => {
            const rec = (c: EmailConnectionEntity, parent: number, folders: EmailFolderEntity[]): NavFolder[] => {
                const nav: NavFolder[] = [];
                const subFolders = [];
                const notSubFolders = [];
                folders.forEach((f) => {
                    if (f.ParentId === parent) {
                        subFolders.push(f);
                    } else {
                        notSubFolders.push(f);
                    }
                });
                subFolders.forEach((f) => {
                    if (f.EmailConnectionId == c.Id) {
                        const name = f.Name.replace('INBOX', 'Posteingang');
                        const children = rec(c, f.Id, notSubFolders);
                        const icon = this.getIcon(f.Type);
                        const text = c.Address + ' ' + name;
                        const n: NavFolder = {
                            folder: f,
                            folderType: f.Type,
                            children: children,
                            connectionId: c.Id,
                            text,
                            textMenu: name,
                            order: this.getOrder(f.Type),
                            icon,
                            onClick: (nav: NavFolder) => {
                                this.EmailConnectionId$.next(c.Id);
                                this.FolderId$.next(f?.Id);
                                this.ListViewIcon = icon;
                                this.ListViewText = text;
                                this.PreviewOpen = false;
                                this.FilterSeen$.next(null);
                                this.setTabTo('folders');
                            },
                            emailUnreadCount$: this.store.select(getEmailFolderById({ id: f.Id })).pipe(
                                map((fo) => fo.EmailUnreadCount),
                                distinctUntilChanged(),
                                shareReplay({ refCount: true, bufferSize: 1 }),
                            ),
                            childrenUnreadCount$: children.length
                                ? combineLatest(children.map((c) => combineLatest([c.childrenUnreadCount$, c.emailUnreadCount$]).pipe(map(([cU, eU]) => cU + eU)))).pipe(
                                      map((counts) => counts.reduce((pr, curr) => pr + curr, 0)),
                                      shareReplay({ refCount: true, bufferSize: 1 }),
                                  )
                                : of(0),
                        };
                        nav.push(n);
                    }
                });

                return [...nav.filter(n => isNotNullOrUndefined(n.order)).sort((a, b) => (a.order > b.order ? 1 : -1)), ...nav.filter(n => !isNotNullOrUndefined(n.order)).sort((a,b) => (a.folder.Name.toLowerCase() > b.folder.Name.toLowerCase()) ? 1 : ((b.folder.Name.toLowerCase() > a.folder.Name.toLowerCase()) ? -1 : 0))];
            };

            const nav = connections.map<NavFolder>((c) => {
                const children = rec(
                    c,
                    null,
                    folders.filter((f) => f.EmailConnectionId === c.Id),
                );
                const n: NavFolder = {
                    children: children,
                    textMenu: c.Address,
                    text: c.Address,
                    icon: this.getIcon(EmailFolderEntityType.Root),
                    onClick: () => {
                        this.EmailConnectionId$.next(c.Id);
                        const nav = folders.find((n) => n.EmailConnectionId == c.Id && n.Type.toLowerCase() == EmailFolderEntityType.Inbox);
                        this.FolderId$.next(nav.Id);
                        this.ListViewIcon = this.getIcon(EmailFolderEntityType.Inbox);
                        this.ListViewText = c.Address;
                        this.PreviewOpen = false;
                        this.FilterSeen$.next(null);
                        this.setTabTo('folders');
                    },
                    folderType: 'root',
                    connectionId: c.Id,
                    order: this.getOrder(EmailFolderEntityType.Root),
                    emailUnreadCount$: null,
                    childrenUnreadCount$: combineLatest(children.map((c) => combineLatest([c.childrenUnreadCount$, c.emailUnreadCount$]).pipe(map(([cU, eU]) => cU + eU)))).pipe(map((counts) => counts.reduce((pr, curr) => pr + curr, 0))),
                };
                return n;
            });
            return [...this.StaticNavFolder, ...nav];
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    constructor(
        private dialog: MatDialog,
        private store: Store<State>,
        private breakpoints: BreakpointObserverService,
        emailConnectionResolver: EmailConnectionResolver,
        eventTypeResolver: EventTypeResolver,
        eventResolver: EventResolver,
        personResolver: PersonResolver,
        personTypeResolver: PersonTypeResolver,
        public PS: PermissionService,
        private fileDataService: FileDataService,
        private emailDataService: EmailDataService,
        private actions$: Actions,
    ) {
        emailConnectionResolver.resolve();
        personResolver.resolve();
        personTypeResolver.resolve();
        this.subscriptions.push(
            this.FolderId$.subscribe((folderId) => (this.IsLastPage = false)),
            this.navTreeData$.pipe(filter(isNotNullOrUndefined)).subscribe((data) => {
                this.NavList.data = data;
                this.NavListTreeControl.dataNodes = data;
            }),
            PS.Has(PS.Permission.GetEvents)
                .pipe(take(1))
                .subscribe((hasPermission) => {
                    if (hasPermission) {
                        eventTypeResolver.resolve();
                        eventResolver.resolve();
                    }
                }),
            this.AnswerAll$.pipe(
                withLatestFrom(this.store.select(getEmailConnections)),
                tap(([email, connections]) => {
                    const emailconnection = connections.find((c) => c.Id === email.EmailConnectionId);
                    return this.OpenEmailEditor$.next({
                        TargetEmails: [email.From, ...email.To].filter((e) => e && e.toLowerCase() !== emailconnection.Address.toLowerCase()),
                        EmailSubject: 'Re: ' + email.Subject,
                        EmailId: email.Id,
                        CCEmails: email.CarbonCopy,
                        EmailSourceConnectionId: email.EmailConnectionId,
                    });
                }),
            ).subscribe(),
            this.PreviewedEmailId$.pipe(
                filter(isNotNullOrUndefined),
                switchMap((id) => this.emailDataService.GetEmailById$(id)),
                filter(isNotNullOrUndefined),
                withLatestFrom(this.store.select(getSetting)),
            ).subscribe(([email, userSettings]) => {
                if (this.currentId != email.Id) {
                    this.currentId = email.Id;
                    if (!email.Seen && !userSettings.PreventEmailSeenOnOpenInSideView) {
                        //this removes the email from the list and is disabled because D277-3263
                        // if (this.FilterSeen$.value != null) {
                        //     this.emailDataService.ClearCache([email.Id]);
                        // }
                        // this.actions$
                        //     .pipe(ofType(EmailActionTypes.UpdateOneEmail, BaseActionTypes.ErrorAction))
                        //     .pipe(first())
                        //     .subscribe(() => {
                        //         this.triggerSearch$.next();
                        //     });
                        this.store.dispatch(
                            EmailActionTypes.ModifyEmail({
                                Payload: {
                                    id: email.Id,
                                    seen: true,
                                },
                            }),
                        );
                    }
                }
            }),
            this.OpenEmailEditor$.pipe(
                tap((dialogData) => {
                    this.dialog.open(EmailEditorComponent, {
                        ...EmailEditorComponentDialogConfig,
                        data: dialogData,
                    });
                }),
            ).subscribe(),
            this.ShowSearchBar$.pipe().subscribe((isSearching) => (isSearching ? setTimeout(() => this.inputRef && this.inputRef.nativeElement.focus()) : this.SearchForm.setValue(''))),
        );
    }

    ngOnInit(): void {
        this.defaultFolder.onClick(this.defaultFolder);
        let count = 0;
        this.pollInterval = setInterval(() => {
            count++;
            // if (count % 5 === 0) { // every 5 minutes
                this.LoadNextPage();
            // }
            this.SyncCurrentFolder();
        }, 1 * 60 * 1000);
    }

    private unreadCountForFolder$(folderId: FolderIdType): Observable<number> {
        if (typeof folderId === 'number') {
            return this.store.select(getEmailFolderById({ id: folderId })).pipe(map((folder) => folder?.EmailUnreadCount));
        } else if (folderId === 'shared-inbox') {
            return (combineLatest([this.store.select(getEmailFolders), this.store.select(getEmailConnections)])).pipe(
                map(([folders, emailConnections]) => {
                    const filteredEmailConnectionIds = emailConnections.filter(ec=> ec.Deleted === false).map(fec => fec.Id);
                    const filteredFolders = folders.filter((f) =>
                        f.Type === EmailFolderEntityType.Inbox &&
                        filteredEmailConnectionIds.indexOf(f.EmailConnectionId) >= 0
                    );
                    return filteredFolders.map((f) => f.EmailUnreadCount).reduce((sum, cost) => sum + cost, 0);
                }),
            );
        } else if (folderId === 'shared-drafts') {
            return (combineLatest([this.store.select(getEmailFolders), this.store.select(getEmailConnections)])).pipe(
                map(([folders, emailConnections]) => {
                    const filteredEmailConnectionIds = emailConnections.filter(ec=> ec.Deleted === false).map(fec => fec.Id);
                    const filteredFolders = folders.filter((f) =>
                        f.Type === EmailFolderEntityType.Drafts &&
                        filteredEmailConnectionIds.indexOf(f.EmailConnectionId) >= 0
                    );
                    return filteredFolders.map((f) => f.EmailUnreadCount).reduce((sum, cost) => sum + cost, 0);
                }),
            );
        } else if (folderId === 'shared-sent') {
                return (combineLatest([this.store.select(getEmailFolders), this.store.select(getEmailConnections)])).pipe(
                    map(([folders, emailConnections]) => {
                        const filteredEmailConnectionIds = emailConnections.filter(ec=> ec.Deleted === false).map(fec => fec.Id);
                        const filteredFolders = folders.filter((f) =>
                            f.Type === EmailFolderEntityType.Sent &&
                            filteredEmailConnectionIds.indexOf(f.EmailConnectionId) >= 0
                        );
                        return filteredFolders.map((f) => f.EmailUnreadCount).reduce((sum, cost) => sum + cost, 0);
                    }),
            );
        }
    }
    public SetPreviewedEmail(emailIn: ShowEmail) {
        this.PreviewedEmailId$.next(emailIn.email.Id);
        this.setTabTo('email-preview');
    }
    private staticFolderClick = ({ icon, text, folderId }: { icon: IconProp; text: string; folderId: FolderIdType }) => {
        this.EmailConnectionId$.next(null);
        this.FolderId$.next(folderId);
        this.ListViewIcon = icon;
        this.ListViewText = text;
        this.PreviewOpen = false;
        this.FilterSeen$.next(null);
        this.setTabTo('folders');
    };
    private setTabTo(tab: 'folders' | 'email-preview') {
        const index = tab === 'folders' ? 1 : tab === 'email-preview' ? 2 : null;
        if (this.tabGroup) {
            this.tabGroup.selectedIndex = index;
        } else {
            setTimeout(() => {
                if (this.tabGroup) {
                    this.tabGroup.selectedIndex = index;
                }
            }, 150);
        }
    }
    public ListScrolled() {
        const scrollelement = this.scrollViewport.elementRef.nativeElement;
        if (scrollelement.offsetHeight + scrollelement.scrollTop >= scrollelement.scrollHeight - 20) {
            if (!this.IsLoadingEmails) {
                this.LoadNextPage();
            }
        }
    }
    public SyncCurrentFolder() {
        const folderId = this.FolderId$.value;
        if (typeof folderId === 'number') {
            this.emailDataService.SyncEmails(folderId);
        } else if (folderId === 'shared-inbox') {
            this.emailDataService.SyncEmails(EmailFolderEntityType.Inbox);
        } else if (folderId === 'shared-drafts') {
            this.emailDataService.SyncEmails(EmailFolderEntityType.Drafts);
        } else if (folderId === 'shared-sent') {
            this.emailDataService.SyncEmails(EmailFolderEntityType.Sent);
        }
    }
    public LoadNextPage() {
        const folderId = this.FolderId$.value;
        if (typeof folderId === 'number') {
            this.IsLoadingEmails = true;
            this.emailDataService
                .LoadNextPage$(folderId)
                .pipe(first())
                .subscribe((res) => {
                    this.IsLoadingEmails = false;
                    this.IsLastPage = res.value.allPagesLoaded;
                });
        } else if (folderId === 'shared-inbox') {
            this.IsLoadingEmails = true;
            this.emailDataService
                .LoadNextPage$(EmailFolderEntityType.Inbox)
                .pipe(first())
                .subscribe((res) => {
                    this.IsLoadingEmails = false;
                    this.IsLastPage = res.value.allPagesLoaded;
                });
        } else if (folderId === 'shared-drafts') {
            this.IsLoadingEmails = true;
            this.emailDataService
                .LoadNextPage$(EmailFolderEntityType.Drafts)
                .pipe(first())
                .subscribe((res) => {
                    this.IsLoadingEmails = false;
                    this.IsLastPage = res.value.allPagesLoaded;
                });
        } else if (folderId === 'shared-sent') {
            this.IsLoadingEmails = true;
            this.emailDataService
                .LoadNextPage$(EmailFolderEntityType.Sent)
                .pipe(first())
                .subscribe((res) => {
                    this.IsLoadingEmails = false;
                    this.IsLastPage = res.value.allPagesLoaded;
                });
        }
    }


    public NavFolderHasChild = (_: number, node: NavFolder) => !!node.children && node.children.length > 0;

    ngOnDestroy() {
        if (this.emailPreviewSubscription) {
            this.emailPreviewSubscription.unsubscribe();
        }
        this.subscriptions.forEach((s) => s.unsubscribe());
        clearInterval(this.pollInterval);
    }
    private mapEmailToShowEmail = (email: EmailEntity): ShowEmail => {
        let targetEmails = email.To?.length > 0 ? email.To.slice() : [];
        const author = getEmailFromAuthor(email.From);
        if (author != null) {
            targetEmails.push(author);
        }
        // if (emailAddress != null) {
        //     targetEmails = targetEmails.filter((te) => !(te?.toLowerCase() === emailAddress.toLowerCase()));
        // }
        return {
            email,
            event$: this.store.select(getEvents).pipe(map((events) => events.find((e) => e.EmailId === email.Id && !e.IsTask))),
            canOpenCommission: true,
            emailConnectionId: email.EmailConnectionId,
            srcEmail: getEmailFromAuthor(email.From),
            toEmail: targetEmails,
        };
    };
    protected getIcon(type: EmailFolderEntityType): IconProp {
        switch (type) {
            case EmailFolderEntityType.Root:
                return 'user';
            case EmailFolderEntityType.Inbox:
                return 'inbox';
            case EmailFolderEntityType.Drafts:
                return 'pencil-alt';
            case EmailFolderEntityType.Sent:
                return 'share';
            case EmailFolderEntityType.Trash:
                return 'trash';
            case EmailFolderEntityType.Junk:
                return 'exclamation-triangle';
            case EmailFolderEntityType.Archive:
                return 'box-archive';
            default:
                return 'envelope';
        }
    }

    protected getOrder(type: EmailFolderEntityType): number {
        switch (type.toLowerCase()) {
            case 'root':
                return 1;
            case 'inbox':
                return 2;
            case 'drafts':
                return 3;
            case 'sent':
                return 4;
            case 'trash':
                return 5;
            case 'junk':
                return 6;
            default:
                return null;
        }
    }
    public MultiDelete() {
        if (this.SelectedMailIds.length) {
            const ids = this.SelectedMailIds.slice();
            this.actions$
                .pipe(ofType(EmailActionTypes.UpdateSomeEmails, BaseActionTypes.ErrorAction))
                .pipe(first())
                .subscribe(() => {
                    this.emailDataService.CleanPages(ids);
                });
            this.store.dispatch(EmailActionTypes.DeleteEmails({ Payload: { ids: this.SelectedMailIds } }));
            this.SelectedMailIds = [];
        }
        this.Multiselect = false;
    }
    public MultiRead(seen: boolean) {
        if (this.SelectedMailIds.length) {
            this.store.dispatch(EmailActionTypes.ReadEmails({ Payload: { ids: this.SelectedMailIds, seen } }));
            this.SelectedMailIds = [];
            this.emailDataService.ClearCache(this.SelectedMailIds);
            this.actions$
                .pipe(ofType(EmailActionTypes.UpdateSomeEmails, BaseActionTypes.ErrorAction))
                .pipe(first())
                .subscribe(() => {
                    this.triggerSearch$.next();
                });
        }
        this.Multiselect = false;
    }
    public SelectUnselect(rowData: ShowEmail, checked: boolean) {
        this.SelectedMailIds = this.SelectedMailIds.filter((id) => id !== rowData.email.Id);
        if (checked) {
            this.SelectedMailIds.push(rowData.email.Id);
        }
    }
}
