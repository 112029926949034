import { AsyncPipe, CommonModule, formatCurrency } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DEFAULT_CURRENCY_CODE, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AccountsReceivableLedgerTemplateEntity, ARLCustomProperties } from '../../../dave-data-module/entities/accounts-receivable-ledger-template.entity';
import { AccountsReceivableLedgerEntity, AccountsReceivableLedgerTypeEnum, ARLTypeEnumNames, availableBookingPositionTypeEnums, NettoCost } from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { ARLTemplateTypeResolver } from '../../../dave-data-module/guards/a-r-l-template-type.resolver';
import { QuantityTypeResolver } from '../../../dave-data-module/guards/quantity-type.resolver';
import { ResourceResolver } from '../../../dave-data-module/guards/resource-dispo/resource.resolver';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { AccountsReceivableLedgerActionTypes } from '../../../dave-data-module/State/actions/accounting.actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { getAccountsReceivableLedgerById, getAccountsReceivableLedgerTemplateById, getARLTemplateTypes, getQuantityTypes } from '../../../dave-data-module/State/selectors/accounting.selector';
import { getCommissionTypes } from '../../../dave-data-module/State/selectors/commissionType.selectors';
import { getLedgerImports } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { getResourceById, getResourcesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { DaveSharedComponentsModule } from '../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { DEFAULT_TAX, isNotNullOrUndefined } from '../../../helper/helper';
import { decimalPriceValidator } from '../../../helper/validation.helper';
import { LoadingService } from '../../../services/loading.service';
import { ArlInformationInputComponent } from '../arl-information-input/arl-information-input.component';

export interface NewOptionDefaultValues {
    LedgerImportId?: number;
    CustomerId?: number;
    CommissionId?: number;
}

@Component({
    selector: 'app-modify-booking-option',
    templateUrl: './modify-booking-option.component.html',
    styleUrls: ['./modify-booking-option.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, DaveSharedComponentsModule, AsyncPipe, ArlInformationInputComponent],
})
export class ModifyBookingOptionComponent implements OnInit, OnDestroy, AfterViewInit {
    private defaults = {
        Tax: DEFAULT_TAX,
        CurrencyCode: this.defaultCurrencyCode,
        BookingText: null,
        Information: null,
        CostAmount: null,

        Quantity: null,
        DiscountAmount: null,
        DiscountPercentage: null,
        DiscountPaymentDate: null,
        DiscountAmount2: null,
        DiscountPercentage2: null,
        DiscountPaymentDate2: null,
        Date: new Date(),
        LedgerImportId: null,
        CustomerId: null,
        CommissionId: null,
        QuantityTypeId: null,
        Multiplier: 1,
        CustomProperties: null,
        TemplateType: null,
        Zuschlag: 0,
        Type: AccountsReceivableLedgerTypeEnum.Arl,
        ResourceId: null,
        Longtext: null,
        ShowLongtext: true,
    };
    public Form = new FormGroup({
        CostAmount: new FormControl<number>(null, [Validators.required, decimalPriceValidator()]),
        BookingText: new FormControl<string>(null),
        CommissionId: new FormControl<number>(null),
        CurrencyCode: new FormControl<string>(this.defaults.CurrencyCode, [Validators.required]),
        CustomProperties: new FormControl<ARLCustomProperties>(null),
        CustomerId: new FormControl<number>(null),
        Date: new FormControl<Date>(null, [Validators.required]),
        DiscountAmount: new FormControl<number>(null, [decimalPriceValidator()]),
        DiscountAmount2: new FormControl<number>(null, [decimalPriceValidator()]),
        DiscountPaymentDate: new FormControl<Date>(null),
        DiscountPaymentDate2: new FormControl<Date>(null),
        DiscountPercentage: new FormControl<number>(null),
        DiscountPercentage2: new FormControl<number>(null),
        Information: new FormControl<string>(null, [Validators.required]),
        LedgerImportId: new FormControl<number>(null),
        Multiplier: new FormControl<number>(1, [Validators.required, Validators.min(0)]),
        Quantity: new FormControl<number>(null, [Validators.required]),
        QuantityTypeId: new FormControl<number>(null),
        Tax: new FormControl<number>(this.defaults.Tax, [Validators.required, Validators.max(100), Validators.min(0)]),
        TemplateType: new FormControl<string>(null),
        Zuschlag: new FormControl<number>(null),
        Type: new FormControl<AccountsReceivableLedgerTypeEnum>(null),
        ResourceId: new FormControl<number>(null),
        Longtext: new FormControl<string>(null),
        ShowLongtext: new FormControl<boolean>(null),
    });
    private fullPriceForm = new FormControl<string>('');
    private viewInit$ = new BehaviorSubject<boolean>(false);
    public ListTemplateData$ = new Subject<IDetailListTemplateData>();
    public BookingOption$: Observable<AccountsReceivableLedgerEntity>;
    public BookingOptionTemplate$: Observable<AccountsReceivableLedgerTemplateEntity>;
    private bookingOptionTemplateId$ = new BehaviorSubject<number>(null);
    private subscriptions: Subscription[] = [];

    constructor(
        private store: Store<State>,
        quantityTypeResolver: QuantityTypeResolver,
        arlTemplateTypeResolver: ARLTemplateTypeResolver,
        resourceResolver: ResourceResolver,
        private actions$: Actions,
        public LS: LoadingService,
        private cdr: ChangeDetectorRef,
        @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string,
    ) {
        quantityTypeResolver.resolve();
        arlTemplateTypeResolver.resolve();
        firstValueFrom(this.store.select(getResourcesFetched)).then((fetched) => {
            if (!fetched) {
                resourceResolver.resolve();
            }
        });
    }

    @ViewChild('informationFormTemplate') informationFormTemplate: TemplateRef<any>;
    @Input() Save$: Observable<void> = new Subject<void>();
    @Input() Delete$: Observable<void> = new Subject<void>();
    @Input() BookingOptionId: number = null;
    @Input() get DisableForms() {
        return this.Form.disabled;
    }
    @Output() NewArl = new EventEmitter<AccountsReceivableLedgerEntity>();
    set DisableForms(v: boolean) {
        if (v) {
            this.Form.disable();
        } else {
            this.Form.enable();
        }
    }

    @Input()
    set DefaultsForNewOptions(defaultValues: NewOptionDefaultValues) {
        this.defaults.LedgerImportId = defaultValues?.LedgerImportId || this.defaults.LedgerImportId;
        this.defaults.CustomerId = defaultValues?.CustomerId || this.defaults.CustomerId;
        this.defaults.CommissionId = defaultValues?.CommissionId || this.defaults.CommissionId;
    }

    // get DefaultsForNewOptions() {
    //     return this.defaults.LedgerImportId;
    // }
    @Input()
    set BookingOptionTemplateId(id: number) {
        this.bookingOptionTemplateId$.next(id);
    }

    get BookingOptionTemplateId() {
        return this.bookingOptionTemplateId$.value;
    }

    ngOnInit(): void {
        this.fullPriceForm.disable();
        this.BookingOption$ = this.BookingOptionId ? this.store.select(getAccountsReceivableLedgerById({ id: this.BookingOptionId })).pipe(filter(isNotNullOrUndefined), take(1)) : of(null);
        this.BookingOptionTemplate$ = this.bookingOptionTemplateId$.pipe(
            filter(isNotNullOrUndefined),
            distinctUntilChanged(),
            switchMap((id) => this.store.select(getAccountsReceivableLedgerTemplateById({ id })).pipe(filter(isNotNullOrUndefined), take(1))),
        );

        this.subscriptions.push(
            // this.Form.controls.CommissionTypeId.valueChanges
            //     .pipe(
            //         withLatestFrom(this.store.select(getCommissionTypes)),
            //         tap(([commissionTypeId, commissionTypes]) => {
            //             if (
            //                 !commissionTypes
            //                     .find(ct => ct.Id === commissionTypeId)
            //                     ?.Name.toLowerCase()
            //                     .includes('gericht')
            //             ) {
            //                 this.Form.controls.Multiplier.setValue(1);
            //             }
            //         }),
            //     )
            //     .subscribe(),
            combineLatest([
                this.BookingOption$.pipe(tap(() => console.log(1))),
                this.BookingOptionTemplate$.pipe(startWith(null)).pipe(tap(() => console.log(2))),
                this.store
                    .select(getQuantityTypes)
                    .pipe(filter(isNotNullOrUndefined))
                    .pipe(tap(() => console.log(3))),
                this.store
                    .select(getARLTemplateTypes)
                    .pipe(filter(isNotNullOrUndefined))
                    .pipe(tap(() => console.log(4))),
                // this.Form.controls.CommissionTypeId.valueChanges.pipe(startWith(this.Form.value.CommissionTypeId))
                this.viewInit$.pipe(filter((v) => !!v)),
            ])
                .pipe(
                    tap(([bookingOption, bookingOptionTemplate, quantityTypes /*commissionTypeId*/]) => this.setForm(bookingOption || null, bookingOptionTemplate || null)),
                    withLatestFrom(this.store.select(getCommissionTypes).pipe(filter(isNotNullOrUndefined))),
                    map(([[bookingOption, bookingOptionTemplate, quantityTypes, arlTemplateTypes /*commissionTypeId*/], commissionTypes]) => {
                        return {
                            Properties: [
                                {
                                    key: 'Artikelnummer',
                                    value: this.Form.value.BookingText || '',
                                    formControl: this.Form.controls.BookingText,
                                },
                                {
                                    key: 'Art',
                                    value: this.Form.value.Type ? ARLTypeEnumNames.get(this.Form.value.Type) : '',
                                    formControl: this.Form.controls.Type,
                                    options: {
                                        specialInput: {
                                            select: availableBookingPositionTypeEnums.map((q) => ({ optionValue: q, optionLabel: ARLTypeEnumNames.get(q) })),
                                        },
                                    },
                                },
                                {
                                    key: 'Bezeichnung',
                                    value: this.Form.value.Information || '',
                                    formControl: this.Form.controls.Information,
                                    options: {
                                        specialInput: {
                                            // textArea: { Fill: false },
                                            customTemplate: this.informationFormTemplate,
                                        },
                                    },
                                },
                                {
                                    key: 'Langtext',
                                    value: this.Form.value.Longtext || '',
                                    formControl: this.Form.controls.Longtext,
                                    options: {
                                        specialInput: {
                                            textArea: { Fill: false, MinRows: 3 },
                                        },
                                    },
                                },
                                {
                                    key: 'Langtext anzeigen',
                                    value: this.Form.value.ShowLongtext || '',
                                    formControl: this.Form.controls.ShowLongtext,
                                    options: {
                                        specialInput: {
                                            boolean: true,
                                        },
                                    },
                                },
                                {
                                    key: 'Menge',
                                    value: this.Form.value.Quantity || '',
                                    formControl: this.Form.controls.Quantity,
                                    options: {
                                        specialInput: {
                                            number: true,
                                        },
                                    },
                                },
                                {
                                    key: 'Einheit',
                                    value: this.Form.value.QuantityTypeId || '',
                                    formControl: this.Form.controls.QuantityTypeId,
                                    options: {
                                        specialInput: {
                                            select: quantityTypes.map((q) => ({ optionValue: q.Id, optionLabel: [q.Name, q.LangerName].filter((v) => !!v).join(' ') })),
                                        },
                                    },
                                },
                                {
                                    key: 'Vorlagentyp',
                                    value: this.Form.value.TemplateType || '',
                                    formControl: this.Form.controls.TemplateType,
                                    options: {
                                        specialInput: {
                                            select: arlTemplateTypes.map((q) => ({ optionValue: q.Id, optionLabel: q.Name })),
                                        },
                                    },
                                },
                                {
                                    key: 'Kosten',
                                    value: this.Form.value.CostAmount || '',
                                    formControl: this.Form.controls.CostAmount,
                                    options: {
                                        specialInput: {
                                            number: true,
                                        },
                                    },
                                },
                                {
                                    key: 'Zuschlag',
                                    value: this.Form.value.Zuschlag || '',
                                    formControl: this.Form.controls.Zuschlag,
                                    options: {
                                        specialInput: {
                                            number: true,
                                        },
                                    },
                                },
                                {
                                    key: 'Multiplikator',
                                    value: '',
                                    formControl: this.Form.controls.Multiplier,
                                    hideFormControl: !this.Form.value.CustomProperties?.showMultiplier,
                                    options: {
                                        specialInput: {
                                            number: true,
                                        },
                                    },
                                },
                                {
                                    key: 'Steuern',
                                    value: this.Form.value.Tax || '',
                                    formControl: this.Form.controls.Tax,
                                    options: {
                                        type: CustomPropertyType.Percentage,
                                        specialInput: {
                                            number: true,
                                        },
                                        suffix: '%',
                                    },
                                },
                                {
                                    key: 'Währung',
                                    value: this.Form.value.CurrencyCode || '',
                                    formControl: this.Form.controls.CurrencyCode,
                                    options: {
                                        specialInput: {
                                            select: [{ optionValue: 'EUR', optionLabel: 'Euro' }],
                                        },
                                    },
                                },
                                {
                                    key: 'Preis gesamt',
                                    value: this.fullPriceForm.value || '--',
                                    formControl: this.fullPriceForm,
                                },
                            ],
                        };
                    }),
                )
                .subscribe((v) => {
                    this.ListTemplateData$.next(v);
                    this.cdr.detectChanges();
                }),
            this.Form.valueChanges.pipe(tap((values) => this.fullPriceForm.setValue(formatCurrency(NettoCost(values.Quantity, (values.CostAmount || 0) + (values.Zuschlag || 0)), 'de-DE', '€')))).subscribe(),
            this.Delete$.pipe(
                withLatestFrom(this.BookingOption$),
                tap(([, bp]) => this.store.dispatch(AccountsReceivableLedgerActionTypes.DeleteAccountsReceivableLedger({ Payload: { id: bp.Id } }))),
            ).subscribe(),
            this.Save$.pipe(
                filter(() => this.Form.valid),
                withLatestFrom(this.BookingOption$),
                withLatestFrom(this.store.select(getLedgerImports)),
                tap(([[, bookingoption], ledgerImports]) => {
                    this.LS.startLoading('save-arl', { dialogMessage: 'Abrechnungsposition wird gespeichert' });
                    if (bookingoption) {
                        this.store.dispatch(
                            AccountsReceivableLedgerActionTypes.ChangeAccountsReceivableLedger({
                                Payload: {
                                    id: bookingoption.Id,
                                    quantity: this.Form.value.Quantity,
                                    tax: this.Form.value.Tax,
                                    currencyCode: this.Form.value.CurrencyCode,
                                    costAmount: this.Form.value.CostAmount,
                                    bookingText: this.Form.value.BookingText,
                                    baseCost: (this.Form.value.CostAmount || 0) + (this.Form.value.Zuschlag || 0),
                                    discountAmount: this.Form.value.DiscountAmount,
                                    discountPercentage: this.Form.value.DiscountPercentage,
                                    discountPaymentDate: this.Form.value.DiscountPaymentDate && FrontendDate(this.Form.value.DiscountPaymentDate),
                                    discountAmount2: this.Form.value.DiscountAmount2,
                                    discountPercentage2: this.Form.value.DiscountPercentage2,
                                    discountPaymentDate2: this.Form.value.DiscountPaymentDate2 && FrontendDate(this.Form.value.DiscountPaymentDate2),
                                    date: this.Form.value.Date && FrontendDate(this.Form.value.Date),
                                    information: this.Form.value.Information,
                                    quantityTypeId: this.Form.value.QuantityTypeId,
                                    multiplikator: this.Form.value.Multiplier,
                                    customProperties: JSON.stringify(this.Form.value.CustomProperties),
                                    type: this.Form.value.Type as any,
                                    resourceId: this.Form.value.ResourceId,
                                    showLongInformation: this.Form.value.ShowLongtext || false,
                                    longInformation: this.Form.value.Longtext,
                                },
                            }),
                        );
                    } else {
                        this.store.dispatch(
                            AccountsReceivableLedgerActionTypes.AddAccountsReceivableLedger({
                                Payload: {
                                    quantity: this.Form.value.Quantity,
                                    tax: this.Form.value.Tax,
                                    currencyCode: this.Form.value.CurrencyCode,
                                    costAmount: this.Form.value.CostAmount,
                                    bookingText: this.Form.value.BookingText,
                                    baseCost: (this.Form.value.CostAmount || 0) + (this.Form.value.Zuschlag || 0),
                                    discountAmount: this.Form.value.DiscountAmount,
                                    discountPercentage: this.Form.value.DiscountPercentage,
                                    discountPaymentDate: this.Form.value.DiscountPaymentDate && FrontendDate(this.Form.value.DiscountPaymentDate),
                                    discountAmount2: this.Form.value.DiscountAmount2,
                                    discountPercentage2: this.Form.value.DiscountPercentage2,
                                    discountPaymentDate2: this.Form.value.DiscountPaymentDate2 && FrontendDate(this.Form.value.DiscountPaymentDate2),
                                    date: this.Form.value.Date && FrontendDate(this.Form.value.Date),
                                    ledgerImportId: this.Form.value.LedgerImportId,
                                    documentType: this.Form.value.LedgerImportId ? ledgerImports.find((l) => l.Id === this.Form.value.LedgerImportId).DocumentType : null,
                                    customerId: this.Form.value.CustomerId,
                                    commissionId: this.Form.value.CommissionId,
                                    information: this.Form.value.Information,
                                    quantityTypeId: this.Form.value.QuantityTypeId,
                                    multiplikator: this.Form.value.Multiplier,
                                    customProperties: JSON.stringify(this.Form.value.CustomProperties),
                                    type: this.Form.value.Type as any,
                                    resourceId: this.Form.value.ResourceId,
                                    isVisible: true,
                                    showLongInformation: this.Form.value.ShowLongtext || false,
                                    longInformation: this.Form.value.Longtext,
                                },
                            }),
                        );
                    }
                    const LedgerImport = ledgerImports.find((li) => li.Id === this.Form.value.LedgerImportId);
                    firstValueFrom(this.actions$.pipe(ofType(AccountsReceivableLedgerActionTypes.UpdateSingleAccountsReceivableLedger, AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger, BaseActionTypes.ErrorAction))).then(
                        (action) => {
                            console.log({ action });
                            if (!bookingoption && action.type === AccountsReceivableLedgerActionTypes.UpdateSingleAccountsReceivableLedger.type) {
                                this.NewArl.emit(action.Payload);
                            }
                            this.LS.endLoading('save-arl');
                        },
                    );
                }),
            ).subscribe(),
        );
    }

    ngAfterViewInit(): void {
        this.viewInit$.next(true);
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    private setForm(bookingOption?: AccountsReceivableLedgerEntity, bookingOptionTemplate?: AccountsReceivableLedgerTemplateEntity) {
        if (bookingOption && bookingOptionTemplate) {
            throw 'wrong funktion input';
        }
        this.Form.setValue(
            bookingOption
                ? {
                      Tax: bookingOption.Tax,
                      CurrencyCode: bookingOption.CurrencyCode,
                      BookingText: bookingOption.BookingText,
                      Information: bookingOption.Information,
                      CostAmount: bookingOption.CostAmount || 0,

                      Quantity: bookingOption.Quantity,
                      DiscountAmount: bookingOption.DiscountAmount,
                      DiscountPercentage: bookingOption.DiscountPercentage,
                      DiscountPaymentDate: bookingOption.DiscountPaymentDate,
                      DiscountAmount2: bookingOption.DiscountAmount2,
                      DiscountPercentage2: bookingOption.DiscountPercentage2,
                      DiscountPaymentDate2: bookingOption.DiscountPaymentDate2,
                      Date: bookingOption.Date,
                      LedgerImportId: bookingOption.LedgerImportId,
                      CustomerId: bookingOption.CustomerId,
                      CommissionId: bookingOption.CommissionId,
                      QuantityTypeId: bookingOption.QuantityTypeId,

                      Multiplier: bookingOption.Multiplier,
                      CustomProperties: bookingOption.CustomProperties,
                      TemplateType: bookingOptionTemplate ? bookingOptionTemplate.ARLTemplateTypeId : 0,
                      Zuschlag: (bookingOption.BaseCost || 0) - (bookingOption.CostAmount || 0),
                      ResourceId: bookingOption.ResourceId,
                      Type: bookingOption.Type,
                      Longtext: bookingOption.Longtext,
                      ShowLongtext: bookingOption.ShowLongtext,
                  }
                : bookingOptionTemplate
                ? {
                      Tax: bookingOptionTemplate.Tax,
                      CurrencyCode: bookingOptionTemplate.CurrencyCode,
                      BookingText: bookingOptionTemplate.BookingText,
                      Information: bookingOptionTemplate.Information,
                      CostAmount: bookingOptionTemplate.CostAmount,
                      QuantityTypeId: bookingOptionTemplate.QuantityTypeId,
                      Quantity: bookingOptionTemplate.Quantity,

                      DiscountAmount: this.defaults.DiscountAmount,
                      DiscountPercentage: this.defaults.DiscountPercentage,
                      DiscountPaymentDate: this.defaults.DiscountPaymentDate,
                      DiscountAmount2: this.defaults.DiscountAmount2,
                      DiscountPercentage2: this.defaults.DiscountPercentage2,
                      DiscountPaymentDate2: this.defaults.DiscountPaymentDate2,
                      Date: this.defaults.Date,
                      LedgerImportId: this.defaults.LedgerImportId,
                      CustomerId: this.defaults.CustomerId,
                      CommissionId: this.defaults.CommissionId,

                      Multiplier: bookingOptionTemplate.Multiplier,
                      CustomProperties: bookingOptionTemplate.CustomProperties,
                      TemplateType: bookingOptionTemplate.ARLTemplateTypeId,
                      Zuschlag: (bookingOptionTemplate.BaseCost || 0) - (bookingOptionTemplate.CostAmount || 0),
                      ResourceId: bookingOptionTemplate.ResourceId,
                      Type: bookingOptionTemplate.Type,
                      Longtext: bookingOptionTemplate.Longtext,
                      ShowLongtext: bookingOptionTemplate.ShowLongtext,
                  }
                : this.defaults,
        );
    }
    onSetResourceId(id: number | null) {
        this.Form.controls.ResourceId.setValue(id);
        this.Form.controls.ResourceId.markAsDirty();
        if (id) {
            firstValueFrom(getFetched$(this.store, getResourcesFetched, getResourceById({ id }))).then((resource) => {
                if (isNotNullOrUndefined(resource.MaxChargeRate)) {
                    this.Form.controls.CostAmount.setValue(parseFloat((resource.MaxChargeRate / 100).toFixed(4)));
                }
                if (resource.QuantityTypeId) {
                    this.Form.controls.QuantityTypeId.setValue(resource.QuantityTypeId);
                }
            });
        }
    }

    protected readonly ARLTypeEnum = AccountsReceivableLedgerTypeEnum;
}
