<mat-card [style.min-width.rem]="IsLarge ? 60 : 40">
    <ngx-simplebar style="max-height: 100vh;">
        <app-modify-booking-option-template
            #modifyBookingOption
            [Save$]="Save$.asObservable()"
            [Delete$]="Delete$.asObservable()"
            [class.hide]="SelectType$ | async"
            (Close)="DialogRef.close()"
        ></app-modify-booking-option-template>
        <!-- <app-dave-list-card
            style="max-height: calc(100vh - 6rem)"
            *ngIf="SelectType$ | async"
            Headline="Vorlagentyp auswählen"
            [Data]="ARLTemplateTypes"
            [DisplayedCollums]="['Name']"
            [Clickable]="true"
            [Selectable]="true"
            [SelectedIndex]="0"
            (EntryClicked$)="EntryClicked($event.entry)"
        >
            <button headerButton app-round-button matTooltip="Vorlagentyp erstellen" (click)="OpenNewARLTemplateType()">
                <fa-icon icon="plus"></fa-icon>
            </button>
        </app-dave-list-card> -->
        <mat-card-actions>
            <div class="cancel-button-wrapper">
                <button class="action-button" app-button Inverted mat-dialog-close matTooltip="Fenster schließen">
                    Abbrechen
                </button>
            </div>

            <!-- ToDo Add Delete Permission-->
            <button
                app-round-button
                mat-dialog-close
                Color="red"
                *ngIf="Data.BookingOptionTemplateId"
                (click)="Delete$.next()"
                matTooltip="Abrechnungspositionsvorlage löschen"
                class="delete-button"
            >
                <fa-icon icon="trash"></fa-icon>
            </button>
            <!-- <button
                class="action-button"
                *ngIf="!(SelectType$ | async)"
                app-button
                (click)="SelectType$.next(true)"
                [Disabled]="modifyBookingOption.Form.invalid"
                matTooltip="Weiter"
            >
                Weiter
            </button> -->
            <button class="action-button" app-button (click)="Save$.next()" matTooltip="Speichern">
                Speichern
            </button>
        </mat-card-actions>
    </ngx-simplebar>
</mat-card>
