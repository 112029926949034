import {createAction, props} from '@ngrx/store';
import {ConditionEntity} from '../../entities/condition.entity';

export const ConditionActionTypes = {
    GetConditionRequest: createAction(
        '[Condition] Get Condition Request'
    ),
    GetCondition: createAction(
        '[Condition] Get Condition',
        props<{Payload: { Conditions: ConditionEntity[] }}>()
    )
};
