import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input, OnDestroy,
    Output,
    ViewChild
} from "@angular/core";
import { MatButtonModule } from '@angular/material/button';
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import { map } from 'rxjs/operators';
import { AccountsReceivableLedgerEntity } from '../../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { AppButtonModule } from '../../../../dave-utils-module/app-button-module/app-button.module';
import { ArlAmountCalculationViewComponent } from '../arl-amount-calculation-view/arl-amount-calculation-view.component';
import { ArlCalculationViewComponent } from '../arl-calculation-view/arl-calculation-view.component';
import { FormControl, FormGroup } from "@angular/forms";
import { LoadingService } from "../../../../services/loading.service";

@Component({
    selector: 'app-arl-table-detail-row',
    standalone: true,
    imports: [CommonModule, ArlAmountCalculationViewComponent, ArlCalculationViewComponent, AppButtonModule, MatButtonModule],
    templateUrl: './arl-table-detail-row.component.html',
    styleUrls: ['./arl-table-detail-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArlTableDetailRowComponent implements AfterViewInit, OnDestroy {
    @ViewChild(ArlCalculationViewComponent) arlCalculationViewComponent: ArlCalculationViewComponent;
    @Output() SaveClick = new EventEmitter<void>();
    @Output() AbortClick = new EventEmitter<void>();
    @Output() DeleteClick = new EventEmitter<void>();
    @Output() DuplicateClick = new EventEmitter<void>();
    protected _inheritFromChildrenForm: FormControl<boolean> = null;
    private inheritFromChildrenFormSubscription: Subscription = null;
    @Input() set inheritFromChildrenForm(form: FormControl<boolean>) {
        this._inheritFromChildrenForm = form;
        if (form && (form.value && !this.clacViewExpanded)) {
            this.clacViewExpanded = true;
        }
        this.inheritFromChildrenFormSubscription?.unsubscribe();
        this.inheritFromChildrenFormSubscription = form?.valueChanges.subscribe(val => {
            if (val && !this.clacViewExpanded) {
                this.clacViewExpanded = true;
            }
        })
    };
    @Input() childrenVisibleForm: FormControl<boolean> = null;
    @Input() ZuschlaegeResourcen: number;
    @Input() ZuschlaegeDefault: number;
    @Input() ZuschlaegeEmployee: number;
    @Input() ZuschlaegeExternalService: number;
    @Input() ZuschlaegeOther: number;
    @Output() calculatedAmount = new EventEmitter<number>();
    @Input() set position(val: string) {
        this._positionPrefix = val && val + '.' || '';
    }
    @Input() disabled = false;
    protected _positionPrefix: string;
    protected clacViewExpanded = false;
    protected clacAmountViewExpanded = false;
    // arlForm = new FormGroup({
    //     // Amount: new FormControl<number>(null),
    //     // Information: new FormControl<string>(null),
    //     // BookingText: new FormControl<string>(null),
    //     // BaseCost: new FormControl<number>(null),
    //     // Quantity: new FormControl<number>(null),
    //     // QuantityTypeId: new FormControl<number>(null),
    //     ChildIds: new FormControl<Array<number>>(null)
    // });
    protected _arl: AccountsReceivableLedgerEntity = null;
    @Input() set arl(v: AccountsReceivableLedgerEntity /*| AccountsReceivableLedgerTemplateEntity*/) {
        // todo checken ob template oder nicht
        // AccountsReceivableLedgerTemplateEntity hat keinen amount
        this._arl = v;
        if (v) {
            // this.arlForm.setValue({
            //     // Amount: v.Amount,
            //     // Information: v.Information,
            //     // BookingText: v.BookingText,
            //     // BaseCost: v.BaseCost,
            //     // Quantity: v.Quantity,
            //     // QuantityTypeId: v.QuantityTypeId,
            //     ChildIds: v.ArlIds || [],
            // });
            // this.arlForm.markAsPristine();
        }
    }
    get arl() {
        return this._arl;
    }
    public someFormDirty$: Observable<boolean>;
    private _ArlFormDirty$ = new BehaviorSubject<boolean>(false);
    @Input() set ArlFormDirty(v: boolean) {
        this._ArlFormDirty$.next(v);
    }
    protected someFormInvalid$: Observable<boolean>;
    private _ArlFormInvalid$ = new BehaviorSubject<boolean>(false);
    @Input() set ArlFormInvalid(v: boolean) {
        this._ArlFormInvalid$.next(v);
    }
    constructor(protected ls: LoadingService) {}

    ngAfterViewInit(): void {
        this.someFormDirty$ = combineLatest([this.arlCalculationViewComponent.arlFormsDirty$, this._ArlFormDirty$]).pipe(map((dirties) => dirties.includes(true)));
        this.someFormInvalid$ = combineLatest([this.arlCalculationViewComponent.arlFormsInvalid$, this._ArlFormInvalid$]).pipe(map((invalids) => invalids.includes(true)));
    }
    Submit() {
        return this.arlCalculationViewComponent?.submit();
    }
    protected onAbortClick() {
        this.AbortClick.emit();
        this.arlCalculationViewComponent?.reset();
    }
    protected onDuplicateClick() {
        this.DuplicateClick.emit();
    }
    protected onDeleteClick() {
        this.DeleteClick.emit();
    }

    ngOnDestroy(): void {
        this.inheritFromChildrenFormSubscription?.unsubscribe();
    }
}
