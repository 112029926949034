<app-detail-view-template *ngIf="Partner">
    <ng-container *appDetailViewButtons>
        <button
                *ngIf="!Editing && !Partner.Deleted"
                app-round-button
                Inverted
                class="header-button"
                matTooltip="Mandatsreferenz erzeugen"
                (click)="GenerateMandatsreferenz()"
        >
            <fa-icon icon="credit-card"></fa-icon>
        </button>

        <button
                *ngIf="!Editing && !Partner.Deleted"
                app-round-button
                Inverted
                class="header-button"
                matTooltip="Bearbeiten"
                (click)="OpenEdit()"
        >
            <fa-icon icon="pencil-alt"></fa-icon>
        </button>

        <button
                *ngIf="Editing"
                app-round-button
                Inverted
                class="header-button"
                matTooltip="Zurücksetzen"
                (click)="CloseEdit()"
        >
            <fa-icon icon="undo"></fa-icon>
        </button>

        <button
                *ngIf="Editing"
                app-round-button
                Inverted
                class="header-button"
                matTooltip="Speichern"
                [Disabled]="AreFormsInvalid"
                (click)="Save()"
        >
            <fa-icon icon="save"></fa-icon>
        </button>

        <button
                *ngIf="!Partner.Deleted"
                app-round-button
                Inverted
                class="header-button"
                Color="red"
                matTooltip="Löschen"
                (click)="DeletePartner()"
        >
            <fa-icon icon="trash"></fa-icon>
        </button>
    </ng-container>

    <ng-container *appDetailViewLeftContent>
        <app-profile-template
                [Data]="ProfileData$ | async"
                [Editing]="Editing"
                (Submit)="Save()"
        ></app-profile-template>

        <mat-card *ngIf="Editing; else addressTemplate" class="map">
            <form (ngSubmit)="Save()">
                <app-textfield Key="Straße" [Value]="StreetForm"></app-textfield>
            </form>
            <form (ngSubmit)="Save()">
                <app-textfield Key="Postleitzahl" [Value]="PostalCodeForm"></app-textfield>
            </form>
            <form (ngSubmit)="Save()">
                <app-textfield Key="Stadt" [Value]="CityForm"></app-textfield>
            </form>
            <form (ngSubmit)="Save()">
                <app-textfield Key="Land" [Value]="CountryForm"></app-textfield>
            </form>
        </mat-card>

        <ng-template #addressTemplate>
            <mat-card
                    *ngIf="PartnerMainOffice.Street || PartnerMainOffice.PostalCode || PartnerMainOffice.City || PartnerMainOffice.Country"
                    class="map"
            >
                {{ PartnerMainOffice.Street }}<br/>
                {{ PartnerMainOffice.PostalCode }}<br/>
                {{ PartnerMainOffice.City }}<br/>
                {{ PartnerMainOffice.Country }}
            </mat-card>
        </ng-template>
    </ng-container>

    <ng-container *appDetailViewRightContent>
        <ng-container *ngIf="!Editing">
            <ng-container [ngTemplateOutlet]="usersTemplate"></ng-container>

            <ng-container [ngTemplateOutlet]="licensesTemplate"></ng-container>
        </ng-container>
    </ng-container>
</app-detail-view-template>

<ng-template #usersTemplate>
    <mat-card class="card">
        <div class="card-header">
            <fa-icon class="icon" icon="users"></fa-icon>

            <h3>Benutzer</h3>

            <!-- ToDo 'a' statt 'button', wenn event.stopImmediatePropagation() tut -->
            <button
                    app-round-button
                    matTooltip="Benutzer hinzufügen"
                    matTooltipPosition="left"
                    routerLink="neuerBenutzer"
                    *ngIf="(PS.Has(PS.Permission.CreateUser) | async)"
                    [Disabled]="Partner.Deleted"
            >
                <app-double-icon Icon="user" Icon2="plus"></app-double-icon>
            </button>
        </div>

        <ngx-simplebar class="clickable-list-container">
            <table mat-table class="table" [dataSource]="ManageUserList$ | async">
                <ng-container matColumnDef="image">
                    <td mat-cell *matCellDef="let rowData">
                        <fa-icon mat-list-avatar icon="user" size="2x"></fa-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <td mat-cell *matCellDef="let rowData">
                        {{ rowData.DisplayName }}
                        <i *ngIf="rowData.Deleted">
                            gelöscht
                            <fa-icon icon="user-slash"></fa-icon>
                        </i>
                    </td>
                </ng-container>

                <tr mat-row *matRowDef="let rowData; columns: ['image', 'name']"
                    [routerLink]="rowData.Id"
                    routerLinkActive="active"></tr>
            </table>
            <table
                    *ngIf="
                        !(ManageUserList$ | async)?.length > 0
                    "
            >
                <tr>
                    <td class="empty-table-text">
                        Keine Benutzer zugeteilt
                    </td>
                </tr>
            </table>
        </ngx-simplebar>
    </mat-card>
</ng-template>

<ng-template #licensesTemplate>
    <mat-card class="card">
        <div class="card-header">
            <fa-icon class="icon" icon="file-contract"></fa-icon>

            <h3>Lizenzen</h3>

            <button
                    app-round-button
                    matTooltip="Lizenz hinzufügen"
                    matTooltipPosition="left"
                    [Disabled]="Partner.Deleted"
                    (click)="AddLicense()"
                    *ngIf="(PS.Has(PS.Permission.BookProductManager) | async)"
            >
                <fa-icon icon="plus"></fa-icon>
            </button>
        </div>

        <ngx-simplebar class="clickable-list-container">
            <table mat-table class="table" [dataSource]="PartnerLicenseList$ | async">
                <ng-container matColumnDef="image">
                    <td mat-cell *matCellDef="let rowData">
                        <fa-icon
                                mat-list-avatar
                                icon="times"
                                size="2x"
                                [matTooltip]="'Lizenz entfernen'"
                        ></fa-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <td mat-cell *matCellDef="let rowData">
                        {{ rowData.MaxUserTypeName }}: {{ rowData.ActiveUser }}
                        <fa-icon icon="user"></fa-icon>
                    </td>
                </ng-container>

                <tr
                        mat-row
                        *matRowDef="let rowData; columns: ['image', 'name']"
                        (click)="DeleteLicense(rowData)"
                ></tr>
            </table>
            <table
                    *ngIf="
                        !(PartnerLicenseList$ | async)?.length > 0
                    "
                    style="width: 100%"
            >
                <tr>
                    <td class="empty-table-text">
                        Keine Lizenz zugeteilt
                    </td>

                </tr>
            </table>
        </ngx-simplebar>
    </mat-card>
</ng-template>
