import { Directive } from "@angular/core";
import { TimePickerSelectComponent } from "../time-picker-select/time-picker-select.component";
import { TimeDirectiveBase } from "./time-directive-base";

@Directive({
    selector: 'app-time-picker-select[appStartTime]',
    standalone: true,
})
export class StartTimeDirective  extends TimeDirectiveBase {
    constructor(component: TimePickerSelectComponent) {
        super(component);
    }
}
