<mat-expansion-panel *ngIf="Expandable" #panel>
    <mat-expansion-panel-header class="card-headline" style="padding: 0 15px 0 7px;">
        <div class="header-content">
            <fa-stack matCardAvatar>
                <fa-icon icon="ruler-combined" stackItemSize="1x"></fa-icon>
            </fa-stack>
            <h3 >{{title}}</h3>
            <fa-icon *ngIf="(freeWorks$|async)?.tasks.length !== 0 && !searchVisible" icon="exclamation-triangle"
                     style="color: #a58c13 ; margin-left: 2px"></fa-icon>
        </div>
        <div class="header-button-wrapper">
                <mat-form-field floatLabel="never" [class.invisible]="!searchVisible" (click)="$event.stopPropagation()"
                                style="width: 12rem ">
                    <mat-label>Suche</mat-label>
                    <input matInput type="search" [formControl]="searchForm" #searchInput />
                    <button class="clear-search" *ngIf="searchForm.value" mat-icon-button aria-label="Clear"
                            (click)="searchForm.setValue('')">
                        <fa-icon icon="times"></fa-icon>
                    </button>
                </mat-form-field>
                <button *ngIf="panel.expanded"
                    style="margin: auto 0 auto auto;"
                        app-round-button
                        (click)="$event.stopPropagation(); toggleSearch()"
                        [matTooltip]="searchVisible ? 'abbrechen' : 'suchen'"
                >
                    <fa-icon [icon]="searchVisible ? 'times' : 'search'"></fa-icon>
                </button>
            <button *ngIf="!dialogRef && !searchVisible" matTooltip="Vollbild" app-round-button
                    (click)="openDialog(); $event.stopPropagation();">
                <fa-icon icon="expand"></fa-icon>
            </button>
        </div>
    </mat-expansion-panel-header>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</mat-expansion-panel>

<div *ngIf="!Expandable">
    <div class="card-headline" style="padding: 0 0.5rem 0 0.5rem ; ">
        <div class="header-content">
                            <fa-stack matCardAvatar>
                                <fa-icon icon="ruler-combined" stackItemSize="1x"></fa-icon>
                            </fa-stack>
            <h3>{{title}}</h3>
<!--            <h3>Aufmaß (geleistete Arbeiten)</h3>-->
        </div>
        <div class="header-button-wrapper" *ngIf="Expanded">
                <mat-form-field floatLabel="never" [class.invisible]="!searchVisible" (click)="$event.stopPropagation()"
                                style="width: 15rem ; margin-right: 8px ; height: auto">
                    <mat-label>Suche</mat-label>
                    <input matInput type="search" [formControl]="searchForm" #searchInput />
                    <button class="clear-search" *ngIf="searchForm.value" mat-icon-button aria-label="Clear"
                            (click)="searchForm.setValue('')">
                        <fa-icon icon="times"></fa-icon>
                    </button>
                </mat-form-field>
                <button style="margin: auto 0 auto auto;"
                        app-round-button
                        (click)="$event.stopPropagation(); toggleSearch()"
                        [matTooltip]="searchVisible ? 'abbrechen' : 'suchen'"
                >
                    <fa-icon [icon]="searchVisible ? 'times' : 'search'"></fa-icon>
                </button>
            </div>
            <button app-round-button *ngIf="!searchVisible" matTooltip="freie Arbeit melden"
                    (click)="OpenAddOrDeleteFreeWorkResourceToCommission(null, false , null , null , null)">
                <fa-icon icon="plus"></fa-icon>
            </button>
    </div>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
    <ng-container [ngTemplateOutlet]="footer"></ng-container>
</div>
<ng-template #content>
    <mat-list *ngIf="freeWorks$|async as freeWork" matSort>
        <h4 *ngIf="(freeWorks$ | async)?.tasks.length === 0" class="no-content">Keine Einträge vorhanden</h4>
        <mat-list-item *ngFor=" let tasks of freeWork.tasks " style="margin: 10px 0 17px 0">
            <div *ngIf="!tasks.withTriangle" class="notification">
                <fa-icon icon="exclamation-triangle" class="exclamation-triangle"
                         aria-hidden="true"></fa-icon>
                <div class="notification-content">
                    <span> Fehlende Leistung am {{tasks.Date  }} </span>
                    <span> ( {{  tasks.Quantity }} {{ tasks.QuantityType }} {{ tasks.ResourceName }} ) </span>
                    <div matTooltip="bearbeiten" style="margin-left: 0.2rem ; width: 1rem ; height: 1rem">
                        <fa-icon style="width: 0.9rem ; height: 0.9rem"
                                 (click)="OpenAddOrDeleteFreeWorkResourceToCommission(tasks.res2CommissionId , true , tasks.resId , tasks.matId ,  tasks.mat2CommissionId)"
                                 icon="pencil-alt">
                        </fa-icon>
                    </div>
                    <!--                        <div  matTooltip="löschen" style="margin-left: 0.2rem ; width: 1rem ; height: 1rem"><fa-icon   (click)="OpenAddOrDeleteFreeWorkResourceToCommission(tasks.res2CommissionId, false)" icon="times-circle"></fa-icon></div>-->
                </div>
            </div>
            <div *ngIf="tasks.withTriangle" class="notification-2">
                <div class="notification-content">
                    <span> Fehlende Leistung am {{ tasks.Date  }}</span>
                    <span>  ( {{ tasks.Quantity }} {{ tasks.QuantityType }} {{ tasks.ResourceName }} ) </span>
                    <div matTooltip="bearbeiten" style="margin-left: 0.2rem ; width: 1rem ; height: 1rem">
                        <fa-icon
                            (click)="OpenAddOrDeleteFreeWorkResourceToCommission(tasks.res2CommissionId , true, tasks.resId , tasks.matId ,  tasks.mat2CommissionId)"
                            icon="pencil-alt">
                        </fa-icon>
                    </div>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
<!--    <mat-tab-group (selectedTabChange)="onTabChange()">-->
<!--        <mat-tab label="geleistete Arbeiten (Aufmaß)" *ngIf="performedWorks$|async as resource">-->
<!--            <h3 class="table-header">geleistete Arbeiten (Aufmaß)</h3>-->
<!--            <div class="performed-works">-->
<!--                <hr *ngIf="resource?.length === 0" style="width: 90% ; border-color: #a0aaab ">-->
<!--                <mat-accordion  >-->
<!--                    <mat-expansion-panel *ngFor="let task of resource ">-->
<!--                        <mat-expansion-panel-header style="border-bottom: 1px solid #a0aaab">-->
<!--                            <div class="expansion-panel-title">-->
<!--                                <div class="text-overflow">{{ task.name }}</div>-->
<!--                                <div style="display: flex">-->
<!--                                <span class="text-overflow">-->
<!--                                    {{ task.quantitySum }}-->
<!--                                    / {{ task?.arlEntity ? task?.arlEntity?.Quantity : task?.resEntity ? task?.resEntity?.Amount : task?.matListEntity ? task?.matListEntity?.Amount : '' }} {{ task?.quantityName }}-->
<!--                                </span>-->
<!--                                    <button app-round-button-->
<!--                                            Inverted-->
<!--                                            *ngIf="task.resEntity || task.matListEntity || task.arlEntity.MaterialId || task.arlEntity.ResourceId"-->
<!--                                            (click)="onAddPerformedWorkClick(task, $event)"-->
<!--                                            class="add-button"-->
<!--                                            matTooltip="Hinzufügen">-->
<!--                                        <fa-icon icon="plus"></fa-icon>-->
<!--                                    </button>-->
<!--&lt;!&ndash;                                    <button app-round-button&ndash;&gt;-->
<!--&lt;!&ndash;                                            Inverted&ndash;&gt;-->
<!--&lt;!&ndash;                                            (click)="OpenDetailResourceCommission(task.arlEntity ? task.arlEntity : null,&ndash;&gt;-->
<!--&lt;!&ndash;                                            task.resEntity ? task.resEntity : null ,&ndash;&gt;-->
<!--&lt;!&ndash;                                            null ,&ndash;&gt;-->
<!--&lt;!&ndash;                                            task.matListEntity ? task.matListEntity : null,&ndash;&gt;-->
<!--&lt;!&ndash;                                            null,&ndash;&gt;-->
<!--&lt;!&ndash;                                             task.quantityName ,&ndash;&gt;-->
<!--&lt;!&ndash;                                             false )"&ndash;&gt;-->
<!--&lt;!&ndash;                                            class="add-button"&ndash;&gt;-->
<!--&lt;!&ndash;                                            matTooltip="Hinzufügen">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <fa-icon icon="plus"></fa-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </button>&ndash;&gt;-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </mat-expansion-panel-header>-->
<!--                        <h4 *ngIf="task?.entries?.length === 0" class="no-content">Keine Einträge vorhanden</h4>-->
<!--                        <mat-list>-->
<!--                            <mat-list-item class="height-2" *ngFor="let entry of task.entries">-->
<!--                                <div>{{ entry.Date | date }}</div>-->
<!--                                <div class="cell">{{ entry?.Quantity ? entry.Quantity : entry?.Value ? entry?.Value : '' }}  {{ task.quantityName }}</div>-->
<!--                                <button app-round-button-->
<!--                                        *ngIf="task?.resEntity"-->
<!--                                        (click)="onChangeResourceToCommissionClick(entry, task?.quantityName)"-->
<!--                                        Inverted-->
<!--                                        class="edit-button"-->
<!--                                        matTooltip="Bearbeiten">-->
<!--                                    <fa-icon icon="pencil-alt"></fa-icon>-->
<!--                                </button>-->
<!--                                <button app-round-button-->
<!--                                        *ngIf="task?.matListEntity"-->
<!--                                        (click)="onChangeMaterialToCommissionClick(entry, task?.quantityName)"-->
<!--                                        Inverted-->
<!--                                        class="edit-button"-->
<!--                                        matTooltip="Bearbeiten">-->
<!--                                    <fa-icon icon="pencil-alt"></fa-icon>-->
<!--                                </button>-->
<!--&lt;!&ndash;                                <button app-round-button&ndash;&gt;-->
<!--&lt;!&ndash;                                        (click)="OpenDetailResourceCommission(task.arl ,&ndash;&gt;-->
<!--&lt;!&ndash;                                         task?.resEntity,&ndash;&gt;-->
<!--&lt;!&ndash;                                         entry,&ndash;&gt;-->
<!--&lt;!&ndash;                                         task?.matListEntity ,&ndash;&gt;-->
<!--&lt;!&ndash;                                         entry,&ndash;&gt;-->
<!--&lt;!&ndash;                                         task?.quantityName ,&ndash;&gt;-->
<!--&lt;!&ndash;                                         true )"&ndash;&gt;-->
<!--&lt;!&ndash;                                        Inverted&ndash;&gt;-->
<!--&lt;!&ndash;                                        class="edit-button"&ndash;&gt;-->
<!--&lt;!&ndash;                                        matTooltip="Bearbeiten">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <fa-icon icon="pencil-alt"></fa-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                                </button>&ndash;&gt;-->
<!--                            </mat-list-item>-->
<!--                        </mat-list>-->
<!--                    </mat-expansion-panel>-->
<!--                </mat-accordion>-->
<!--                <h4 *ngIf="resource.length === 0" class="no-content">Keine Einträge vorhanden</h4>-->
<!--            </div>-->
<!--        </mat-tab>-->
<!--        <mat-tab label="verbrauchtes Material">-->
<!--            <h3 class="table-header">verbrauchtes Material</h3>-->
<!--            <hr *ngIf="(ConsumedMaterials$|async)?.tasks?.length === 0" style="width: 90% ; border-color: #a0aaab ">-->
<!--            <div class="consumed-materials">-->
<!--                <mat-accordion class="used-materials" *ngIf="ConsumedMaterials$|async as resource">-->
<!--                    <mat-expansion-panel *ngFor="let res of resource.tasks">-->
<!--                        <mat-expansion-panel-header style="border-bottom: 1px solid #8CAAAF; margin-bottom: 1px;">-->
<!--                            <div class="expansion-panel-title">-->
<!--                                <div>{{ res.name }}</div>-->
<!--                                <div>-->
<!--                                    <span style="margin-right: 1rem"> {{ res?.quantitySum }}-->
<!--                                        / {{ res?.quantity }} {{ res?.quantityName }}</span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </mat-expansion-panel-header>-->
<!--                        <h4 *ngIf="res?.entries?.length === 0" class="no-content">Keine Einträge vorhanden</h4>-->
<!--                        <mat-list>-->
<!--                            <mat-list-item class="height-2" *ngFor="let entry of res?.entries ; let last = last">-->
<!--                                <div>{{ entry?.Date | date }}</div>-->
<!--                                <div class="cell">{{ entry?.Quantity }} - {{ res?.quantityName }}</div>-->
<!--                            </mat-list-item>-->
<!--                        </mat-list>-->
<!--                    </mat-expansion-panel>-->
<!--                </mat-accordion>-->
<!--                <h4 *ngIf="(ConsumedMaterials$|async)?.tasks?.length === 0" class="no-content">Keine Einträge-->
<!--                    vorhanden</h4>-->
<!--            </div>-->

<!--        </mat-tab>-->
<!--        <mat-tab label="geleistete Stunden">-->
<!--            <h3 class="table-header"> geleistete Stunden</h3>-->
<!--            <hr *ngIf="(workedHours$|async)?.data?.length === 0" style="width: 90% ; border-color: #a0aaab ">-->
<!--            <div class="scrollable" style="scrollbar-width: thin ; margin-bottom: 10px ; max-height: 65vh">-->
<!--                <table mat-table matSort matSortActive="ResourceName" matSortDirection="asc" class="mat-elevation-z2" [dataSource]="workedHours$ | async">-->
<!--                    <ng-container matColumnDef="ResourceName">-->
<!--                        &lt;!&ndash;                    <th mat-header-cell *matHeaderCellDef></th>&ndash;&gt;-->
<!--                        <td mat-cell *matCellDef="let element">{{ element.ResourceName }}</td>-->
<!--                    </ng-container>-->
<!--                    <ng-container matColumnDef="Quantity">-->
<!--                        &lt;!&ndash;                    <th mat-header-cell *matHeaderCellDef ></th>&ndash;&gt;-->
<!--                        <td mat-cell *matCellDef="let element"></td>-->
<!--                    </ng-container>-->
<!--                    <ng-container matColumnDef="QuantityType">-->
<!--                        &lt;!&ndash;                    <th mat-header-cell *matHeaderCellDef ></th>&ndash;&gt;-->
<!--                        <td mat-cell *matCellDef="let element"> {{ element.QuantitySum }}-->
<!--                            / {{ element.Quantity }} {{ element.QuantityType }}-->
<!--                        </td>-->
<!--                    </ng-container>-->
<!--                    &lt;!&ndash;                <tr mat-header-row *matHeaderRowDef="PerformedHoursColumns"></tr>&ndash;&gt;-->
<!--                    <tr mat-row *matRowDef="let row; columns: PerformedHoursColumns;"></tr>-->
<!--                </table>-->
<!--                <h4 *ngIf="(workedHours$ | async)?.data.length === 0" class="no-content">Keine Einträge vorhanden</h4>-->
<!--            </div>-->
<!--        </mat-tab>-->
<!--        <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--                Freie Arbeiten-->
<!--                <fa-icon *ngIf="(freeWorks$|async)?.tasks.length !== 0" icon="exclamation-triangle"-->
<!--                         style=" color: #a58c13 ; margin-left: 2px"></fa-icon>-->
<!--            </ng-template>-->
<!--            <h3 class="table-header"> Freie Arbeiten</h3>-->
<!--            <hr *ngIf="(freeWorks$|async)?.tasks.length === 0" style="width: 90% ; border-color: #a0aaab ">-->
<!--            <div style="overflow-y: scroll ; scrollbar-width: thin ; max-height: 65vh">-->

<!--            </div>-->
<!--        </mat-tab>-->
<!--    </mat-tab-group>-->
</ng-template>
<ng-template #footer>
    <mat-dialog-actions class="actions" *ngIf="Expanded">
        <button app-button Inverted mat-dialog-close Color="cancel">schließen</button>
    </mat-dialog-actions>
</ng-template>
