import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { EventResolver } from '../../../dave-data-module/guards/event.resolver';
import { State } from '../../../dave-data-module/State';
import { getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomerById } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getEventTypes } from '../../../dave-data-module/State/selectors/event-type.selector';
import { getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { getUsersActive } from '../../../dave-data-module/State/selectors/users.selectors';
import { FilterOption, FILTER_TYPE_BOOLEAN, FILTER_TYPE_SEARCH_MULTI_SELECT, FILTER_TYPE_TIMESPAN, IFilterTypeSearchMultiSelectValue } from '../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { CommissionMeta, ContactBookMeta, HistoryMeta, TaskPageMeta, UserAdministrationMeta } from '../../../helper/page-metadata';
import { CustomLabelService } from '../../../services/custom-label.service';
import { FilterTypes } from '../../../services/default-filter.service';
import { TimeLineFilter, TimelineViewComponent } from "./timeline-view/timeline-view.component";

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent {
    public EventTypes$ = this.store.select(getEventTypes);
    private persons2EntityCustomer$ = this.store.select(getPerson2Entities).pipe(
        filter(isNotNullOrUndefined),
        map((p2es) => p2es.filter((p2e) => p2e.EntityType === Person2EntityEntityTypeEnum.Customer)),
    );
    public Users$ = this.store.select(getUsersActive);
    public CurrentCustomer$ = this.route.paramMap.pipe(
        map((params: ParamMap) => +params.get('customerId')),
        distinctUntilChanged(),
        switchMap((id) => (id ? this.store.select(getCustomerById({ id })) : of(null))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public ContactPersonsOfCurrentCustomer$ = this.CurrentCustomer$.pipe(
        switchMap((customer) =>
            combineLatest([this.persons2EntityCustomer$, this.store.select(getPersons)]).pipe(
                map(([p2es, persons]) => (customer ? persons.filter((p) => p2es.find((pe) => pe.PersonId === p.Id && pe.EntityId === customer.Id) !== null) : persons)),
            ),
        ),
    );
    public ListView: boolean;
    public CommissionOfCurrentCustomer$ = this.CurrentCustomer$.pipe(switchMap((customer) => this.store.select(getCommissions).pipe(map((commissions) => (customer ? commissions.filter((c) => c.CustomerId === customer.Id) : commissions)))));
    protected FilterValues$: BehaviorSubject<TimeLineFilter> = new BehaviorSubject<TimeLineFilter>({
        [FilterTypes.Persons]: null,
        [FilterTypes.Commissions]: null,
        [FilterTypes.Users]: null,
        [FilterTypes.EventTypes]: null,
        [FilterTypes.IsEvent]: true,
        [FilterTypes.IsTask]: true,
        [FilterTypes.Timespan]: null,
    });
    protected filterAmount$ = this.FilterValues$.pipe(
        map((filter) => {
            let amount = 0;
            for (const key in filter) {
                switch (key) {
                    case FilterTypes.Persons:
                    case FilterTypes.Commissions:
                    case FilterTypes.Users:
                    case FilterTypes.EventTypes:
                        if (filter[key] && filter[key].length > 0) {
                            amount++;
                        }
                        break;
                    case FilterTypes.IsEvent:
                    case FilterTypes.IsTask:
                        if (!filter[key]) {
                            amount++;
                        }
                        break;
                    case FilterTypes.Timespan:
                        if (filter[key] && filter[key].from) {
                            amount++;
                        }
                        if (filter[key] && filter[key].to) {
                            amount++;
                        }
                        break;
                }
            }
            return amount || null;
        }),
    );
    protected FilterSettings$: Observable<FilterOption<keyof TimeLineFilter>[]> = combineLatest([
        this.ContactPersonsOfCurrentCustomer$,
        this.CommissionOfCurrentCustomer$,
        this.CurrentCustomer$,
        this.EventTypes$,
        this.Users$,
        this.cls.getSingle$('Commission'),
    ]).pipe(
        map(([contactPersonsOfCurrentCustomer, commissionOfCurrentCustomer, currentCustomer, eventTypes, users, commissionLabel]) => {
            const personFilterValues: IFilterTypeSearchMultiSelectValue[] = contactPersonsOfCurrentCustomer.map((r) => ({
                label: r.DisplayName,
                id: r.Id,
            }));
            const commissionFilterValues: IFilterTypeSearchMultiSelectValue[] = commissionOfCurrentCustomer.map((r) => ({
                label: r.DisplayName,
                id: r.Id,
            }));
            const eventTypeFilterValues: IFilterTypeSearchMultiSelectValue[] = eventTypes.map((r) => ({
                label: r.Name,
                id: r.Id,
            }));
            const userFilterValues: IFilterTypeSearchMultiSelectValue[] = users.map((r) => ({
                label: r.DisplayName,
                id: r.Id,
            }));
            const ret: FilterOption<keyof TimeLineFilter>[] = [
                {
                    Name: FilterTypes.Persons,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Ansprechpartner / Sachbearbeiter',
                    Icon: ContactBookMeta.Icon,
                    Values: personFilterValues,
                },
                {
                    Name: FilterTypes.Commissions,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: commissionLabel,
                    Icon: CommissionMeta.Icon,
                    Values: commissionFilterValues,
                },
                {
                    Name: FilterTypes.Users,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Bearbeiter',
                    Icon: UserAdministrationMeta.Icon,
                    Values: userFilterValues,
                },
                {
                    Name: FilterTypes.EventTypes,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Ereignisart',
                    Icon: null,
                    Values: eventTypeFilterValues,
                },
                {
                    Name: FilterTypes.IsEvent,
                    Type: FILTER_TYPE_BOOLEAN,
                    Label: 'Ereignisse',
                    Icon: HistoryMeta.Icon,
                },
                {
                    Name: FilterTypes.IsTask,
                    Type: FILTER_TYPE_BOOLEAN,
                    Label: 'Aufgaben',
                    Icon: TaskPageMeta.Icon,
                },
                {
                    Name: FilterTypes.Timespan,
                    Type: FILTER_TYPE_TIMESPAN,
                    Label: 'Datum',
                    Icon: 'calendar',
                },
            ];
            return ret;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor(private store: Store<State>, private route: ActivatedRoute, public PS: PermissionService, public CS: CustomerNameService, private eventResolver: EventResolver, protected cls: CustomLabelService) {
        this.ListView = !!localStorage.getItem('timeline-list-view');
        if (this.route.snapshot.queryParams['filter']) {
            const filter: Partial<TimeLineFilter> = TimelineViewComponent.getFilterFromParams(this.route.snapshot.queryParams);
            if (filter) {
              this.FilterValues$.next({ ...this.FilterValues$.value, ...filter });
            }
        }
    }

    public ToggleListView() {
        if (this.ListView) {
            localStorage.removeItem('timeline-list-view');
        } else {
            localStorage.setItem('timeline-list-view', ' ');
        }
        this.ListView = !this.ListView;
    }

    public UpdateEvents() {
        this.eventResolver.pollUpdated();
    }
}
