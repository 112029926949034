import { Component, ViewChild } from '@angular/core';
import { distinctUntilChanged, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom, of } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { DaveActions } from '../../../dave-data-module/State/actions/actions';
import { ActivatedRoute, ResolveData, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../../dave-data-module/State';
import { LoadingService } from '../../../services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomLabelService } from '../../../services/custom-label.service';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { MaterialMainDataComponent } from '../material-main-data/material-main-data.component';
import { AllCommissionMeta, AllGeneratedDocumentsMeta, CommissionMeta, DMSPageMeta, DocumentEditorMeta, GeneratedDocumentsPageMeta } from '../../../helper/page-metadata';
import { FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { MaterialActionTypes } from '../../../dave-data-module/State/actions/material.actions';
import {
    getCommissionDictionary,
    getCommissionsFetched,
} from '../../../dave-data-module/State/selectors/commission.selector';
import { MatTableDataSource } from '@angular/material/table';
import {
    getMaterialLists,
    getMaterialListsFetched,
} from '../../../dave-data-module/State/selectors/material-list.selector';
import { TokenResolver } from '../../../dave-data-module/guards/token.resolver';
import { generatedDocumentsFeatureKey } from '../../../dave-data-module/State/reducers/generatedDocuments.reducer';
import { GeneratedDocumentsResolver } from '../../../dave-data-module/guards/generatedDocuments.resolver';
import {
    generatedDocumentsTypesFeatureKey
} from '../../../dave-data-module/State/reducers/generatedDocumentsType.reducer';
import { GeneratedDocumentsTypeResolver } from '../../../dave-data-module/guards/generatedDocumentsType.resolver';
import { Material_KEY } from '../../../dave-data-module/State/reducers/material.reducer';
import { MaterialResolver } from '../../../dave-data-module/guards/material.resolver';
import { MATERIAL_GROUP_KEY } from '../../../dave-data-module/State/reducers/material-group.reducers';
import { MaterialGroupResolver } from '../../../dave-data-module/guards/material-group.resolver';
import { formatDate } from '@angular/common';
import { ViewStyleConfig } from '../../../dave-data-module/entities/viewStyleSetting.entity';
import { getMaterialById, getMaterialsFetched } from '../../../dave-data-module/State/selectors/material.selector';
import { MaterialListResolver } from '../../../dave-data-module/guards/material-list.resolver';
import { getMaterialGroupsFetched } from '../../../dave-data-module/State/selectors/material-group.selectors';

interface MaterialListTableData {
    Amount: number;
    CommissionName: string;
    Date: string;
    CreatedAt: Date;
    commissionId: number;
}

@Component({
  selector: 'app-material-detail-view',
  templateUrl: './material-detail-view.component.html',
  styleUrls: ['./material-detail-view.component.scss']
})
export class MaterialDetailViewComponent {
    public static readonly RequiredResolvers: ResolveData = {
        token: TokenResolver,
        [Material_KEY]: MaterialResolver,
        [MATERIAL_GROUP_KEY]: MaterialGroupResolver,
        [generatedDocumentsFeatureKey]: GeneratedDocumentsResolver,
        [generatedDocumentsTypesFeatureKey]: GeneratedDocumentsTypeResolver,
    };
    @ViewChild('materialData') materialData: MaterialMainDataComponent;
    public Editing = false;
    public CommissionMeta = CommissionMeta ;
    protected reportsView: ViewStyleConfig = {
        type: '',
        order: -1,
        headline: 'Berichte',
        headlineIcon: GeneratedDocumentsPageMeta.Icon,
        expanded: true,
    };
    public MaterialId$ = this.route.paramMap.pipe(
        map((paramMap) => paramMap.get('materialId')),
        map((id) => +id || null),
        distinctUntilChanged(),
        tap((id) => (this.Editing = !id)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public Material$ = this.store.select(getMaterialsFetched).pipe(
        filter((v) => !!v),
        switchMap(() => this.MaterialId$),
        distinctUntilChanged(),
        switchMap((id) => (id ? this.store.select(getMaterialById({ id })) : of(null))),
    );
    public fileExplorerLoading = new BehaviorSubject<boolean>(true);
    public FolderId$ = this.MaterialId$.pipe(
        switchMap((mId) => mId ? this.folderDataService.getFolderFromEntity(mId, FolderTypes.resource) : of(null)),  // folderTypes.material ??????
        map((folder) => folder?.Id),
        tap(() => this.fileExplorerLoading.next(false)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    public MaterialListTableData$ = this.MaterialId$.pipe(
        switchMap((mId) =>
            combineLatest([
                this.store.select(getMaterialListsFetched).pipe(
                    filter((v) => !!v),
                    switchMap(() => this.store.select(getMaterialLists)),
                ),
                this.store.select(getCommissionsFetched).pipe(
                    filter((v) => !!v),
                    switchMap(() => this.store.select(getCommissionDictionary)),
                ),
            ]).pipe(
                map(([materialLists, commissions]) => {
                    return new MatTableDataSource<MaterialListTableData>(
                        materialLists
                            .filter((s) => s.MaterialId === mId)
                            .map((s) => {
                                return {
                                    Amount: s.Amount,
                                    CommissionName: s.CommissionId ? commissions[s.CommissionId].DisplayName : '',
                                    Date: formatDate(s.CreatedAt, 'shortDate', 'de-DE'),
                                    CreatedAt : s.CreatedAt,
                                    commissionId: s.CommissionId,
                                };
                            }),
                    );
                }),
                tap(
                    (dataSource) =>
                        (dataSource.sortingDataAccessor = (object, key) => {
                            switch (key) {
                                case 'Date':
                                    return object.CreatedAt.getTime();
                                case 'Amount':
                                    return object[key];
                                default:
                                    return object[key].trim().toLowerCase();
                            }
                        }),
                ),
            ),
        ),
    );

    // reportsData$ /*: Observable<{ type: GeneratedDocumentsTypeEntity; Name: string; Id: number; date: string; label: string;  }[]>*/ = combineLatest([
    //     this.store.select(getGeneratedDocumentsSortedByCreatedAt).pipe(filter(isNotNullOrUndefined)),
    //     this.store.select(getGeneratedDocumentsTypes).pipe(filter(isNotNullOrUndefined)),
    //     this.Material$,
    // ]).pipe(
    //     map(([genFiles, genFilesType, material]) => {
    //         if (!material?.Id) {
    //             return [];
    //         }
    //         return genFiles
    //             .filter((e) => e.MaterialId === material.Id)
    //             .map((e) => ({
    //                 type: genFilesType?.find((gft) => gft.Id === e.GeneratedDocumentsTypeId),
    //                 date: formatDate(e.CreatedAt, 'shortDate', 'de-DE'),
    //                 Name: e.Name || 'Platzhalter',
    //                 Id: e.Id,
    //                 suffixButton: e.DocumentIdFromPdf
    //                     ? {
    //                         icon: DMSPageMeta.Icon,
    //                         tooltip: 'Zum Dokument',
    //                         onClick: (args: any) => {
    //                             this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
    //                                 ...DaveFilePreviewComponent.DefaultConfig,
    //                                 data: {
    //                                     fileId: e.DocumentIdFromPdf,
    //                                 },
    //                             });
    //                         },
    //                     }
    //                     : null,
    //             }))
    //             .map((v) => Object.assign(v, { label: `${v.type?.Name || 'Typ'} ${v.Name} ${v.date}` }));
    //     }),
    //     shareReplay({ bufferSize: 1, refCount: true }),
    // );

  constructor(
      private actions$: Actions<DaveActions>,
      private route: ActivatedRoute,
      private router: Router,
      private store: Store<State>,
      public LS: LoadingService,
      protected dialog: MatDialog,
      protected cls: CustomLabelService,
      private folderDataService: FolderDataService,
      materialListResolver: MaterialListResolver,
      ) {
      firstValueFrom(this.store.select(getMaterialListsFetched)).then(fetched => {
          if (!fetched) {
              materialListResolver.resolve();
          }
      });
  }

    onSave() {
        this.actions$.pipe(ofType(MaterialActionTypes.UpdateOne, BaseActionTypes.ErrorAction), first(), tap(() => this.LS.endLoading('saveMaterial')), ofType(MaterialActionTypes.UpdateOne)).subscribe((event) => {

            this.router.navigate(['../', event.Payload.Id], { relativeTo: this.route });
        });
        this.materialData.SaveForm();
        this.LS.startLoading('saveMaterial');
    }
    onUndo() {
        this.materialData.ResetForm();
        firstValueFrom(this.MaterialId$).then((id) => {
            if (id) {
                this.Editing = false;
            }
        });
    }
    onDelete() {
        this.actions$.pipe(ofType(MaterialActionTypes.RemoveOne), first()).subscribe(() => {
            this.LS.endLoading('deleteMaterial');
            this.materialData.ResetForm();
            this.Editing = false;
            this.router.navigate(['../'], { relativeTo: this.route });
        });
        firstValueFrom(this.MaterialId$).then((id) => {
            this.store.dispatch(MaterialActionTypes.Delete({ Payload: { Id: String(id) } }));
            this.LS.startLoading('deleteMaterial');
        });
    }
    ResourceScheduleTableDataRouterLink(entry: MaterialListTableData) {
        return [CommissionMeta.Path, AllCommissionMeta.Path, entry.commissionId];
    }
    GetRouteForGenDoc = (doc: { Id: number }) => ['/', GeneratedDocumentsPageMeta.Path, AllGeneratedDocumentsMeta.Path, DocumentEditorMeta.Path, doc.Id];
}
