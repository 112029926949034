import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, ContentChild, ElementRef, HostBinding, Inject, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { merge, Subject } from 'rxjs';
import { TimePickerSelectComponent } from '../time-picker-select/time-picker-select.component';
import { EndTimeDirective } from './end-time.directive';
import { StartTimeDirective } from './start-time.directive';

type timeRange = { start: string; end: string };
@Component({
    selector: 'app-time-span-picker-select',
    standalone: true,
    imports: [CommonModule, MatSelectModule, FormsModule, TimePickerSelectComponent],
    templateUrl: './time-span-picker-select.component.html',
    styleUrls: ['./time-span-picker-select.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: TimeSpanPickerSelectComponent,
        },
    ],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[id]': 'id',
    },
})
export class TimeSpanPickerSelectComponent implements /*ControlValueAccessor,*/ MatFormFieldControl<timeRange>, OnDestroy, AfterContentInit {
    static nextId = 0;
    id = `app-time-span-picker-select-${TimeSpanPickerSelectComponent.nextId++}`;
    /** Name of the form control. */
    controlType = 'app-time-span-picker-select';
    stateChanges = new Subject<void>();

    // @ViewChild('from') timePickerFrom: TimePickerSelectComponent;
    // @ViewChild('to') timePickerTo: TimePickerSelectComponent;
    @ContentChild(StartTimeDirective) _startInput: StartTimeDirective;
    @ContentChild(EndTimeDirective) _endInput: EndTimeDirective;
    @Input() separator = '–';
    @Input()
    get placeholder() {
        // return this._placeholder;
        const start = this._startInput?.placeholder || '';
        const end = this._endInput?.placeholder || '';
        return start || end ? `${start} ${this.separator} ${end}` : '';
    }
    // set placeholder(plh) {
    //     this._placeholder = plh;
    //     this.stateChanges.next();
    // }
    // protected _placeholder: string;
    constructor(private _focusMonitor: FocusMonitor, private _elementRef: ElementRef<HTMLElement>, @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField, @Optional() @Self() public ngControl: NgControl) {
        // if (this.ngControl != null) {
        //     // Setting the value accessor directly (instead of using
        //     // the providers) to avoid running into a circular import.
        //     this.ngControl.valueAccessor = this;
        // }
        console.log('constr', this.ngControl);
    }
    // protected _startValue: string | null = null;
    // protected _endValue: string | null = null;

    // set value(value: timeRange | null) {
    //     console.log('set value', value);
    //     if (value !== undefined && (this._startValue !== value?.start || this._endValue !== value?.end)) {
    //         this._startValue = value?.start;
    //         this._endValue = value?.end;
    //         console.log('onChangeTrigger', value);
    //         this.onChange(value);
    //         this.onTouch(value);
    //         this.stateChanges.next();
    //     }
    // }
    get value() {
        return { start: this._startInput.value, end: this._endInput.value };
    }
    // onChange: any = () => {};
    // onTouch: any = () => {};
    // registerOnChange(fn: any): void {
    //     console.log('registerOnChange');
    //     this.onChange = fn;
    // }

    // registerOnTouched(fn: any): void {
    //     this.onTouch = fn;
    // }

/*    writeValue(obj: timeRange | null): void {
        console.log('writeValue', obj);

        this._startValue = obj?.start;
        this._endValue = obj?.end;
    }*/
    touched = false;
    // focused = false;
    get focused() {
        return this._startInput?.focused || this._endInput?.focused || false;
    }

    // onFocusIn(event: FocusEvent) {
    //     if (!this.focused) {
    //         this.focused = true;
    //         this.stateChanges.next();
    //     }
    // }
    //
    // onFocusOut(event: FocusEvent) {
    //     if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
    //         this.touched = true;
    //         this.focused = false;
    //         this.onTouch();
    //     }
    // }
    get empty() {
        // return !this._startValue && !this._endValue;
        const startEmpty = this._startInput ? this._startInput.empty : false;
        const endEmpty = this._endInput ? this._endInput.empty : false;
        return startEmpty && endEmpty;
    }
    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;
    // @Input()
    // get disabled(): boolean {
    //     return this._disabled;
    // }
    // set disabled(value: boolean) {
    //     this._disabled = coerceBooleanProperty(value);
    //     this.stateChanges.next();
    // }
    // protected _disabled = false;
    /** Whether the input is disabled. */
    @Input()
    get disabled(): boolean {
        return this._startInput && this._endInput
            ? this._startInput.disabled && this._endInput.disabled
            : this._groupDisabled;
    }
    set disabled(value: boolean) {
        if (value !== this._groupDisabled) {
            this._groupDisabled = coerceBooleanProperty(value);
            this.stateChanges.next(undefined);
        }
    }
    _groupDisabled = false;
    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }
    // @ViewChild('select') matSelect: MatSelect;
    setDescribedByIds(ids: string[]) {
        // todo tut -> https://angular-material.dev/articles/creating-a-custom-form-field-control-group-using-angular-material
        // this.matSelect?._elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
    }
    onContainerClick() {
        // if(!this._startInput.panelOpen && !this._endInput.panelOpen) {
        //     if (!this._startValue) {
        //         this._startInput.onContainerClick();
        //     } else {
        //         this._endInput.onContainerClick();
        //     }
        // }
        if (!this.focused && !this.disabled && !this.shouldLabelFloat) {
            if (this._startInput.empty) {
                this._startInput.onContainerClick();
            } else {
                this._endInput.onContainerClick();
            }
        }
    }
    get errorState(): boolean {
        if (this._startInput && this._endInput) {
            return this._startInput.errorState || this._endInput.errorState;
        }
        return false;
    }
    ngOnDestroy() {
        this.stateChanges.complete();
    }
    ngAfterContentInit() {
        if (!this._startInput) {
            throw Error('app-time-span-picker-select must contain a appStartTime');
        }

        if (!this._endInput) {
            throw Error('app-time-span-picker-select must contain a appEndTime');
        }

        // We don't need to unsubscribe from this, because we
        // know that the input streams will be completed on destroy.
        merge(this._startInput.stateChanges, this._endInput.stateChanges).subscribe(() => {
            this.stateChanges.next(undefined);
        });
    }
}
