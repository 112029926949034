import { createAction, props } from '@ngrx/store';
import { PersonEntity } from '../../entities/person.entity';
import { DaveMutationChangePersonArgs, DaveMutationCreatePersonArgs, QueryPersonArgs } from '../../graphql-types';

export const PersonActionTypes = {
    UpdateAll: createAction('data/person/updateAll', props<{ Payload: Array<PersonEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/person/updateMany', props<{ Payload: Array<PersonEntity>, updateLatestUpdatedAt?: boolean }>()),
    ModifyPerson: createAction('data/person/modify', props<{ Payload: DaveMutationChangePersonArgs }>()),
    AddPerson: createAction('data/person/add', props<{ Payload: DaveMutationCreatePersonArgs }>()),
    DeletePerson: createAction('data/person/delete', props<{ Payload: number }>()),
    Load: createAction('data/person/getAll', props<{ Payload: QueryPersonArgs }>()),
};
