import { Employee2VacationType } from '../graphql-types';
import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';


export interface EmployeeToVacationEntityAdditionalData {
    fileIds?: number[];
}
export enum VacationTypeEnum {
    Vacation = 'VACATION',
    Illness = 'ILLNESS',
    Absent = 'ABSENT',
    Illnesswithoutcontinuedpay = 'ILLNESSWITHOUTCONTINUEDPAY',
}
export const VacationTypeEnumNameMap: Map<VacationTypeEnum, string> = new Map<VacationTypeEnum, string>([
    [VacationTypeEnum.Vacation, 'Urlaub'],
    [VacationTypeEnum.Illness, 'Krank'],
    [VacationTypeEnum.Absent, 'Abwesend'],
    [VacationTypeEnum.Illnesswithoutcontinuedpay, 'Krank ohne Lohnfortzahlung'],
]);
export enum VacationStatusEnum {
    Open = 'OPEN',
    Approved = 'APPROVED',
    Declined = 'DECLINED',
}
export const VacationStatusEnumNameMap: Map<VacationStatusEnum, string> = new Map<VacationStatusEnum, string>([
    [VacationStatusEnum.Open, 'offen'],
    [VacationStatusEnum.Approved, 'genehmigt'],
    [VacationStatusEnum.Declined, 'abgelehnt'],
]);
export class EmployeeToVacationEntity {
    public static readonly GqlFields: Array<keyof Employee2VacationType> = [
        'createdAt',
        'deletedAt',
        'employeeId',
        'endDate',
        'id',
        'minimumVoteCount',
        'partnerId',
        'startDate',
        'updatedAt',
        'userId',
        'userIdListApproved',
        'userIdListDeclined',
        'vacationDays',
        'vacationStatus',
        'voteCount',
        'additionalData',
        'notes',
        'type',
        'lastUpdatedDate',
        'lastUpdatedUserId'
    ];

    /**
     * @param EmployeeId Employee who the vacation is for
     * @param UserId User who Created the Entity
     */
    constructor(
        public Id: number = null,
        public PartnerId: number = null,
        public EmployeeId: number = null,
        public UserId: number = null,
        public VoteCount: number = null,
        public MinimumVoteCount: number = null,
        public VacationDays: number = null, // (float)
        public StartDate: Date = null,
        public EndDate: Date = null,
        public VacationStatus: VacationStatusEnum = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
        public UserIdListApproved: number[] = null,
        public UserIdListDeclined: number[] = null,
        public AdditionalData: EmployeeToVacationEntityAdditionalData = null,
        public Notes: string = null,
        public Type: VacationTypeEnum = null,
        public LastUpdatedDate: Date = null,
        public LastUpdatedUserId: number = null,
    ) {}

    public Clone(override: EmployeeToVacationEntityAttributes = {}): EmployeeToVacationEntity {
        const attr = { ...this, ...override };
        return Object.assign(new EmployeeToVacationEntity(), attr);
    }
    get VacationStatusName() {
        return GetVacationStatusName(this);
    }
}
export function GetVacationStatusName(vacation: EmployeeToVacationEntity) {
    return VacationStatusEnumNameMap.get(vacation.VacationStatus);
}
export function EmployeeToVacationEntityFromBackend(res: Employee2VacationType): EmployeeToVacationEntity {
    return new EmployeeToVacationEntity(
        res.id,
        res.partnerId,
        res.employeeId,
        res.userId,
        res.voteCount,
        res.minimumVoteCount,
        res.vacationDays,
        res.startDate ? BackendDateTimestamp(res.startDate) : null,
        res.endDate ? BackendDateTimestamp(res.endDate) : null,
        res.vacationStatus,
        res.createdAt ? BackendDateTimestamp(res.createdAt) : null,
        res.updatedAt ? BackendDateTimestamp(res.updatedAt) : null,
        res.deletedAt ? BackendDateTimestamp(res.deletedAt) : null,
        res.userIdListApproved?.split(',').map(id => +id),
        res.userIdListDeclined?.split(',').map(id => +id),
        res.additionalData as any,
        res.notes,
        res.type,
        res.lastUpdatedDate ? BackendDateTimestamp(res.lastUpdatedDate) : null,
        res.lastUpdatedUserId,
    );
}

export interface EmployeeToVacationEntityAttributes {
    Id?: number;
    PartnerId?: number;
    EmployeeId?: number;
    UserId?: number;
    VoteCount?: number;
    minimumVoteCount?: number;
    vacationDays?: number;
    StartDate?: Date;
    EndDate?: Date;
    vacationStatusId?: number;
    CreatedAt?: Date;
    UpdatedAt?: Date;
    DeletedAt?: Date;
    UserIdListApproved?: number[];
    UserIdListDeclined?: number[];
    AdditionalData?: EmployeeToVacationEntityAdditionalData;
    Notes?: string;
    Type?: VacationTypeEnum;
}
