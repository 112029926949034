import { createAction, props } from '@ngrx/store';
import { GeneratedDocumentsEntity } from '../../entities/generatedDocuments.entity';
import {
    DaveMutationChangeGeneratedDocumentsArgs,
    DaveMutationCreateGeneratedDocumentsArgs,
    QueryGeneratedDocumentsArgs,
} from '../../graphql-types';

export const GeneratedDocumentsActionTypes = {
    UpdateAll: createAction('data/GeneratedDocuments/updateAll', props<{ Payload: Array<GeneratedDocumentsEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/GeneratedDocuments/updateMany', props<{ Payload: Array<GeneratedDocumentsEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction('data/GeneratedDocuments/updateOne', props<{ Payload: GeneratedDocumentsEntity }>()),
    RemoveOne: createAction('data/GeneratedDocuments/removeOne', props<{ Payload: number }>()),
    ModifyGeneratedDocuments: createAction('data/GeneratedDocuments/modify', props<{ Payload: DaveMutationChangeGeneratedDocumentsArgs }>()),
    AddGeneratedDocuments: createAction('data/GeneratedDocuments/add', props<{ Payload: DaveMutationCreateGeneratedDocumentsArgs }>()),
    DeleteGeneratedDocuments: createAction('data/GeneratedDocuments/delete', props<{ Payload: number }>()),
    Load: createAction('data/GeneratedDocuments/getAll', props<{ Payload: QueryGeneratedDocumentsArgs }>()),
    LoadOne: createAction('data/GeneratedDocuments/getById', props<{ id: number; withFile?: boolean }>()),
};
