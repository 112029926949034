import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  CustomerSpecificationTypeState,
  customerSpecificationTypesFeatureKey
} from "../reducers/customerSpecificationType.reducer";
import { State } from "../index";

export const selectCustomerSpecificationTypes = createFeatureSelector<
  CustomerSpecificationTypeState
>(customerSpecificationTypesFeatureKey);
export const getCustomerSpecificationTypes = createSelector(
  selectCustomerSpecificationTypes,
  data => data.customerSpecificationTypes
);
export const getCustomerSpecificationTypesFetched = createSelector(
  selectCustomerSpecificationTypes,
  data => !!data.customerSpecificationTypes
);
export const getSingleCustomerSpecificationType = createSelector(
  getCustomerSpecificationTypes,
  (customerSpecificationType: ReturnType<typeof getCustomerSpecificationTypes>, Id: number) =>
    customerSpecificationType ? customerSpecificationType.find(e => e.Id === Id) : null
);
