import { DetailEventActions, DetailEventActionTypes } from '../actions/detail-event.actions';
import { NewEventActions, NewEventActionTypes } from '../actions/new-event.actions';
import { Moment } from "moment";

export const FormDataKey = 'formData';

export interface FormDataState {
    EventTypeId: number;
    PersonId: number;
    Date: Moment;
    EndDate: Moment;
    PartnerId: number;
    CustomerId: number;
    Hint: string;
    Name: string;
    Street: string;
    City: string;
    Country: string;
    PostalCode: string;
    Author: string;
    StartTime: string;
    EndTime: string;
    Note: string;
    Files: unknown;
    Editing: boolean;
    Private: boolean;
    CommissionId: number;
}

export const initialFormDataState: FormDataState = {
    EventTypeId: null,
    PersonId: null,
    Date: null,
    EndDate: null,
    PartnerId: null,
    CustomerId: null,
    Hint: null,
    Name: null,
    Street: null,
    City: null,
    Country: null,
    PostalCode: null,
    Author: null,
    StartTime: null,
    EndTime: null,
    Note: null,
    Files: null,
    Editing: null,
    Private: null,
    CommissionId: null,
};

export function formDataReducer(state = initialFormDataState, action: NewEventActions | DetailEventActions): FormDataState {
    switch (action.type) {
        case NewEventActionTypes.SetEventTypeId:
        case DetailEventActionTypes.SetEventTypeId:
            return { ...state, EventTypeId: action.Payload.EventTypeId };

        case NewEventActionTypes.SetName:
            return { ...state, Name: action.Payload.Name };

        case NewEventActionTypes.SetContactPersonId:
        case DetailEventActionTypes.SetContactPersonId:
            return { ...state, PersonId: action.Payload.PersonId };

        case NewEventActionTypes.SetPrivate:
        case DetailEventActionTypes.SetPrivate:
            return { ...state, Private: action.Payload.Private };

        case NewEventActionTypes.SetDate:
        case DetailEventActionTypes.SetDate:
            return { ...state, Date: action.Payload.Date };

        case NewEventActionTypes.SetEndDate:
        case DetailEventActionTypes.SetEndDate:
            return { ...state, EndDate: action.Payload.EndDate };

        case NewEventActionTypes.SetStreet:
        case DetailEventActionTypes.SetStreet:
            return { ...state, Street: action.Payload.Street };

        case NewEventActionTypes.SetCity:
        case DetailEventActionTypes.SetCity:
            return { ...state, City: action.Payload.City };

        case NewEventActionTypes.SetCountry:
        case DetailEventActionTypes.SetCountry:
            return { ...state, Country: action.Payload.Country };

        case NewEventActionTypes.SetPostalCode:
        case DetailEventActionTypes.SetPostalCode:
            return { ...state, PostalCode: action.Payload.PostalCode };

        case NewEventActionTypes.SetStartTime:
        case DetailEventActionTypes.SetStartTime:
            return { ...state, StartTime: action.Payload.StartTime };

        case NewEventActionTypes.SetEndTime:
        case DetailEventActionTypes.SetEndTime:
            return { ...state, EndTime: action.Payload.EndTime };

        case NewEventActionTypes.SetNote:
        case DetailEventActionTypes.SetNote:
            return { ...state, Note: action.Payload.Note };

        case NewEventActionTypes.SetCommission:
        case DetailEventActionTypes.SetCommission:
            return { ...state, CommissionId: action.Payload.CommissionId };

        case NewEventActionTypes.SetFiles:
        case DetailEventActionTypes.SetFiles:
            return { ...state, Files: action.Payload.Files };

        case DetailEventActionTypes.SetEditing:
            return { ...state, Editing: action.Payload.Editing };

        case NewEventActionTypes.SetCustomerId:
        case DetailEventActionTypes.SetCustomerId:
            return { ...state, CustomerId: action.Payload.CustomerId };

        case NewEventActionTypes.ClearStore:
        case DetailEventActionTypes.ClearStore:
            return initialFormDataState;

        default:
            return state;
    }
}
