import { GroupMemberType } from '../graphql-types';

export class GroupMemberEntity {
    constructor(public EmployeeId: number = null, public GroupId: number = null, public Id: number = null, public PersonId: number = null) {}
    public static readonly GqlFields: Array<keyof GroupMemberType> = ['employeeId', 'groupId', 'id', 'personId'];
    public Clone(override: Partial<GroupMemberEntity> = {}): GroupMemberEntity {
        const attr = { ...this, ...override };
        return Object.assign(new GroupMemberEntity(), attr);
    }
}

export function GroupMemberEntityFromBackend(res: GroupMemberType): GroupMemberEntity {
    return new GroupMemberEntity(res.employeeId, res.groupId, res.id, res.personId);
}
