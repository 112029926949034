import { WorkedTimesType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
export class WorkedTimesEntity {
    constructor(
        public ClockInTypeId: number = null,
        public CommissionId: number = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public Id: number = null,
        public Timespan: number = null,
        public UpdatedAt: Date = null,
        public WorkDayId: number = null,
        public RealTimespan: number = null,
    ) {}
    public Clone(override: WorkedTimesEntityAttributes = {}): WorkedTimesEntity {
        const attr = { ...this, ...override };
        return Object.assign(new WorkedTimesEntity(), attr);
    }
    public static readonly GQLFields: Array<keyof WorkedTimesType> = [
        'clockInTypeId',
        'commissionId',
        'createdAt',
        'deletedAt',
        'id',
        'timespan',
        'updatedAt',
        'workDayId',
        'realTimespan'
    ]
}

export function WorkedTimesEntityFromBackend(res: WorkedTimesType): WorkedTimesEntity {
    return new WorkedTimesEntity(
        res.clockInTypeId,
        res.commissionId,
        res.createdAt && BackendDate(res.createdAt),
        res.deletedAt && BackendDate(res.deletedAt),
        res.id,
        res.timespan,
        res.updatedAt && BackendDate(res.updatedAt),
        res.workDayId,
        res.realTimespan,
    );
}

export interface WorkedTimesEntityAttributes {
    ClockInTypeId?: number;
    CommissionId?: number;
    CreatedAt?: Date;
    DeletedAt?: Date;
    Id?: number;
    Timespan?: number;
    UpdatedAt?: Date;
    WorkDayId?: number;
}
