// Funktionen statt Interfaces, da ich die String-Literal-Typen gerne für
// Switch/Case-Statements und Hover-Definitionen hätte. Interfaces können
// ihre generischen Typparameter nicht von den verwendeten Werten ableiten,
// Funktionen können das schon.
//
// interface Data<T> {data: T;}
// const data: Data = {data: 'test'};
//             ~~~~
// Error: Generic type 'Data<T>' requires 1 type argument(s).
//
// function Data<T>(input: {data: T}) {return input;}
// const data = Data({data: 'test'}); // Typ {data: string}
//

import { IconProp } from '@fortawesome/fontawesome-svg-core';

/** Metadaten für einzelne Unterseiten */
export function SubpageComponentMetadata<Path extends string, Breadcrumb extends string>(args: {
    /** Pfad zur Seite. Nur den Pfadabschnitt angeben (`page` statt `/index/page`) */
    Path: Path;
    /** Breadcrumb */
    Breadcrumb: Breadcrumb;
}) {
    return args;
}

/** Metadaten für einzelne Seiten */
export function PageComponentMetadata<
    Path extends string,
    Breadcrumb extends string,
    Icon extends IconProp,
    Theme extends string
>(args: {
    /** Pfad zur Seite. Nur den Pfadabschnitt angeben (`page` statt `/index/page`) */
    Path: Path;
    /** Breadcrumb */
    Breadcrumb: Breadcrumb;
    /** FontAwesome Icon, verwendet im Header als Breadcrumb */
    Icon: IconProp;
    /** CSS-Klasse des Material Themes */
    Theme: Theme;
}) {
    return args;
}

export const HomeDashboardMeta = SubpageComponentMetadata({
    Path: '',
    Breadcrumb: '',
});

export const NewEmployeeMeta = SubpageComponentMetadata({
    Path: 'neuerMitarbeiter',
    Breadcrumb: 'Neuer Mitarbeiter',
});

export const LoginMeta = SubpageComponentMetadata({
    Path: 'login',
    Breadcrumb: '',
});

export const ActivisionMeta = SubpageComponentMetadata({
    Path: 'login/:name/:userId/:verificationCode',
    Breadcrumb: 'Aktivierung',
});

export const HistoryMeta = PageComponentMetadata({
    Path: 'chronik',
    Breadcrumb: 'Chronik',
    Icon: 'history',
    Theme: 'history-theme',
});

export const AllEventsMeta = SubpageComponentMetadata({
    Breadcrumb: 'Alle Ereignisse',
    Path: 'alle',
});

export const CustomerAdministrationMeta = PageComponentMetadata({
    Path: 'kunden',
    Breadcrumb: 'Kunden',
    Icon: 'building',
    Theme: 'admin-theme',
});

export const UserAdministrationMeta = PageComponentMetadata({
    Path: 'personal_und_urlaub',
    Breadcrumb: 'Personal & Urlaub',
    Icon: 'user',
    Theme: 'user-theme',
});

export const CommissionMeta = PageComponentMetadata({
    Path: 'auftraege',
    Breadcrumb: '',
    Icon: 'file-contract',
    Theme: 'commission-theme',
});

export const StatisticsPageMeta = PageComponentMetadata({
    Breadcrumb: 'Statistiken',
    Icon: ['far', 'chart-bar'],
    Path: 'statistiken',
    Theme: 'stats-theme',
});

export const DMSPageMeta = PageComponentMetadata({
    Breadcrumb: 'DMS',
    Icon: 'file' as IconProp,
    Path: 'dms',
    Theme: 'dms-theme',
});

export const GlobalStatisticsMeta = SubpageComponentMetadata({
    Breadcrumb: 'Globale Statistik',
    Path: 'global',
});

export const StatisticsMeta = SubpageComponentMetadata({
    Breadcrumb: 'customerId',
    Path: ':customerId',
});

export const AGBMeta = SubpageComponentMetadata({
    Breadcrumb: 'AGB & Datenschutz',
    Path: 'agb',
});

export const ImpressumMeta = SubpageComponentMetadata({
    Breadcrumb: 'Impressum',
    Path: 'impressum',
});

export const SettingsPageMeta = PageComponentMetadata({
    Breadcrumb: 'Einstellungen',
    Icon: 'cog',
    Path: 'einstellungen',
    Theme: 'settings-theme',
});

export const LicenseSettingsMeta = SubpageComponentMetadata({
    Breadcrumb: 'Lizenzen',
    Path: 'lizenz',
});

export const UserSettingsMeta = SubpageComponentMetadata({
    Breadcrumb: 'Allgemeine Einstellungen',
    Path: 'allgemein',
});

export const CommissionSettingsMeta = SubpageComponentMetadata({
    Breadcrumb: 'Aufträge',
    Path: 'auftraege',
});

export const CalculationSettingsMeta = SubpageComponentMetadata({
    Breadcrumb: 'Belege/Kalkulation',
    Path: 'belege',
});

export const EventSettingsMeta = SubpageComponentMetadata({
    Breadcrumb: 'Aufgaben',
    Path: 'aufgaben',
});


export const AllCommissionMeta = SubpageComponentMetadata({
    Breadcrumb: 'Alle',
    Path: 'alle',
});

export const AllVideodokumentationenMeta = SubpageComponentMetadata({
    Breadcrumb: 'Alle Aufnahmen',
    Path: 'alle',
});

export const VideodokumentationPageMeta = PageComponentMetadata({
    Breadcrumb: 'Videodokumentation',
    Icon: 'video',
    Path: 'videodokumentation',
    Theme: 'video-theme',
});

export const VideodokumentationNewlMeta = SubpageComponentMetadata({
    Breadcrumb: 'Neue Übertragung',
    Path: 'neu',
});

export const DocumentEditorPageMeta = PageComponentMetadata({
    Breadcrumb: 'Dateien',
    Icon: 'file-signature',
    Path: 'docs',
    Theme: 'docs-theme',
});
export const AllDocumentEditorPageMeta = SubpageComponentMetadata({
    Breadcrumb: 'Alle Dateien',
    Path: 'alle',
});
export const DocumentEditorMeta = SubpageComponentMetadata({
    Breadcrumb: 'Editor',
    Path: 'editor',
});

export const EmailPageMeta = PageComponentMetadata({
    Breadcrumb: 'E-Mail',
    Icon: 'envelope',
    Path: 'email',
    Theme: 'email-theme',
});

export const ReportsPageMeta = PageComponentMetadata({
    Breadcrumb: 'Rechnungen & Angebote',
    Icon: 'file-invoice-dollar',
    Path: 'rechnungen_und_angebote',
    Theme: 'reports-theme',
});

export const GeneratedDocumentsPageMeta = PageComponentMetadata({
    Breadcrumb: 'Berichte',
    Icon: 'file-signature',
    Path: 'berichte',
    Theme: 'generated-documents-theme',
});

export const OrdersPageMeta = PageComponentMetadata({
    Breadcrumb: 'Bestellungen',
    Icon: 'file-signature',
    Path: 'bestellungen',
    Theme: 'reports-theme',
});
export const AllOrdersMeta = SubpageComponentMetadata({
    Breadcrumb: 'Alle',
    Path: 'alle',
});

export const NewOrderPageMeta = SubpageComponentMetadata({
    Breadcrumb: 'Neu',
    Path: 'neu',
});

export const AllReportsMeta = SubpageComponentMetadata({
    Breadcrumb: 'Alle',
    Path: 'alle',
});

export const AllGeneratedDocumentsMeta = SubpageComponentMetadata({
    Breadcrumb: 'Alle',
    Path: 'alle',
});

export const InvoiceEditorMeta = PageComponentMetadata({
    Breadcrumb: 'Rechnungseditor',
    Icon: 'file-invoice-dollar',
    Path: 'rechnungseditor',
    Theme: 'reports-theme',
});
export const PersonMeta = SubpageComponentMetadata({
    Breadcrumb: 'Person',
    Path: 'person',
});
export const NewPersonMeta = SubpageComponentMetadata({
    Breadcrumb: 'Neuer Ansprechpartner / Sachbearbeiter',
    Path: 'neuerAnsprechpartner',
});
export const BookingPositionMetaIcon: IconProp = 'money-bill';
export const ChangesMetaIcon: IconProp = 'rotate';
export const VacationMetaIcon: IconProp = 'plane';
export const AbsentMetaIcon: IconProp = 'exclamation-circle';
export const ConstructionDiaryIcon: IconProp = 'hard-hat';
export const BookmarkIcon: IconProp = 'bookmark';
export const GroupsIcon: IconProp = 'user-group';
export const CommentIcon: IconProp = 'comment';
export const ClockInIcon: IconProp = 'stopwatch';
export const FileLinkIcon: IconProp = 'arrow-up-right-from-square';


export const BookingPageMeta = PageComponentMetadata({
    Breadcrumb: 'Buchhaltung',
    Icon: 'book',
    Path: 'buchung',
    Theme: 'booking-theme'
});

export const AllBookingtsMeta = SubpageComponentMetadata({
    Breadcrumb: 'Alle',
    Path: 'alle'
});

export const ContactBookMeta = PageComponentMetadata({
    Breadcrumb: 'Kontakte',
    Icon: 'address-book',
    Path: 'kontakte',
    Theme: 'contacts-theme'
});

export const CalendarPageMeta = PageComponentMetadata({
    Breadcrumb: 'none',
    Icon: 'calendar',
    Path: 'kalender',
    Theme: 'calendar-theme'
});

export const ShiftPlanPageMeta = SubpageComponentMetadata({
    Breadcrumb: 'Schichtplan',
    Path: 'schichtplan',
});
export const RessourcePlanPageMeta = SubpageComponentMetadata({
    Breadcrumb: '',
    Path: 'ressourcen',
});

export const TaskPageMeta = PageComponentMetadata({
    Breadcrumb: 'Aufgaben',
    Icon: 'clipboard-check',
    Path: 'aufgaben',
    Theme: 'task-theme'
});
export const PieceworkCalculationPageMeta = PageComponentMetadata({
    Breadcrumb: 'Akkord',
    Icon: 'euro-sign',
    Path: 'akkord',
    Theme: 'piecework-theme'
});

export const ResourcePageMeta = PageComponentMetadata({
    Breadcrumb: 'Geräte',
    Icon: 'hammer',
    Path: 'geraete',
    Theme: 'ressource-theme'
});
export const MaterialPageMeta = PageComponentMetadata({
    Breadcrumb: 'Materialien',
    Icon: 'hammer',
    Path: 'material',
    Theme: 'ressource-theme'
});
export const NewResourcePageMeta = SubpageComponentMetadata({
    Breadcrumb: 'Neu',
    Path: 'neu',
});
export const NewMaterialPageMeta = SubpageComponentMetadata({
    Breadcrumb: 'Neu',
    Path: 'neu',
})
export const ProcessMeta = PageComponentMetadata({
    Path: 'prozess',
    Breadcrumb: 'Prozess',
    Icon: 'bolt',
    Theme: 'task-theme',
});
export const AllProcessMeta = SubpageComponentMetadata({
    Breadcrumb: 'Alle',
    Path: 'alle',
});
