<ng-content></ng-content>
<button [Inverted]="InvertedBurgerButton" #burgerButton="matMenuTrigger" [IsLoading]="IsLoading" app-round-button [class.default-margin]="BurgerButtonMargin" [matMenuTriggerFor]="menu" [ngStyle]=" {display: (ShowBurgerButton$ | async) ? 'block' : 'none'}" class="dont-display-in-menu burger-button">
    <fa-icon icon="bars"></fa-icon>
</button>
<mat-menu #menu>
    <ng-container *ngFor="let button of ElementRef.nativeElement.children; let isMatMenu = last;">
        <ng-container *ngIf="!button.classList.contains('dont-display-in-menu') && !isMatMenu" >
            <button *ngIf="getMenuForTrigger(button) as menu; else btnWithoutMenuTrigger" mat-menu-item class="menu-button" [disabled]="button.disabled" [matMenuTriggerFor]="menu">
                <ng-container [ngTemplateOutlet]="btnContent"></ng-container>
            </button>
            <ng-template #btnWithoutMenuTrigger>
                <button *ngIf="!getMenuForTrigger(button) as menu" mat-menu-item (click)="button.click()" class="menu-button" [disabled]="button.disabled">
                    <ng-container [ngTemplateOutlet]="btnContent"></ng-container>
                </button>
            </ng-template>
            <ng-template #btnContent>
                <span [innerHTML]="button.innerHTML | unsafeHtml" *ngIf="button.innerHTML" style="min-width: 2rem"></span>
                {{button.innerText ? '' : button.getAttribute('aria-label')}}
            </ng-template>
        </ng-container>
    </ng-container>
</mat-menu>
