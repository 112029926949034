import { BooleanInput,coerceBooleanProperty } from '@angular/cdk/coercion';
import { AsyncPipe,NgForOf,NgIf,NgStyle,NgTemplateOutlet } from '@angular/common';
import {
AfterContentChecked,
AfterContentInit,
AfterViewInit,
Component,ContentChildren,
ElementRef,
HostListener,
Input,
OnDestroy,
OnInit,
ViewChild,
ViewChildren
} from '@angular/core';
import { MatMenu,MatMenuModule,MatMenuTrigger } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BehaviorSubject,merge,Subject,Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { PipesModule } from '../dave-utils-module/dave-shared-components-module/pipes/pipes.module';

const HIDDEN_CLASS_NAME = 'app-burger-button-wrapper-menu-panel-hidden'; // Bei änderung auch in scss ändern

@Component({
    standalone: true,
    imports: [AppButtonModule, FontAwesomeModule, MatMenuModule, NgIf, NgForOf, NgStyle, PipesModule, AsyncPipe, NgTemplateOutlet],
    selector: 'app-burger-button-wrapper',
    templateUrl: './burger-button-wrapper.component.html',
    styleUrls: ['./burger-button-wrapper.component.scss'],
})
export class BurgerButtonWrapperComponent implements OnInit, OnDestroy, AfterContentInit, AfterContentChecked, AfterViewInit {
    @ViewChild('menu') menu: MatMenu;
    @ViewChild('burgerButton') menuTrigger: MatMenuTrigger;
    @ContentChildren(MatMenuTrigger) contentMenuTriggers: MatMenuTrigger[];
    private content: { classes: string[] }[];
    public MenuCount = 0;
    public ShowBurgerButton$ = new BehaviorSubject(false);
    private windowResize$ = new Subject<any>();
    private contentChange$ = new Subject<any>();
    private reCalcSub: Subscription;

    @Input() IsLoading = false;
    @Input() BurgerButtonMargin = true;
    @Input() ManualReRender$: Subject<void> = new Subject<void>();

    static ngAcceptInputType_InvertedBurgerButton: BooleanInput;
    @Input()
    get InvertedBurgerButton() {
        return this.invertedBurgerButton;
    }
    set InvertedBurgerButton(value: boolean) {
        this.invertedBurgerButton = coerceBooleanProperty(value);
    }
    public invertedBurgerButton = false;

    constructor(public ElementRef: ElementRef) {}
    ngOnInit(): void {
        this.CalcMenuCount();
        this.reCalcSub = merge(this.windowResize$.pipe(debounceTime(100)), this.contentChange$.pipe(debounceTime(10)), this.ManualReRender$).subscribe((e) => {
            this.CalcMenuCount();
        });
    }
    ngAfterViewInit(): void {
        this.menu.panelClass = 'app-burger-button-wrapper-menu-panel';
        this.menu.classList = 'app-burger-button-wrapper-menu-panel';
    }
    ngOnDestroy(): void {
        this.reCalcSub?.unsubscribe();
    }
    ngAfterContentInit(): void {
        this.content = this.getContent();
        this.contentChange$.next({ src: 'init', content: this.content });
    }

    ngAfterContentChecked(): void {
        const c = this.getContent();
        if (JSON.stringify(c) !== JSON.stringify(this.content)) {
            this.content = c;
            this.contentChange$.next({ src: 'change', content: this.content });
        }
    }
    @HostListener('window:resize', ['$event'])
    resizeWindow(event) {
        this.windowResize$.next(event);
    }

    public CalcMenuCount() {
        this.MenuCount = 0;
        this.ShowBurgerButton$.next(false);
        this.ElementRef.nativeElement.children.forEach((c) => c.classList.remove(HIDDEN_CLASS_NAME));
        setTimeout(() => this.removeRec(), 0);
    }
    private checkIsOverflown() {
        const clientWidth = this.ElementRef.nativeElement.clientWidth;
        const scrollWidth = this.ElementRef.nativeElement.scrollWidth;
        const offsetWidth = this.ElementRef.nativeElement.offsetWidth;
        return scrollWidth > clientWidth || offsetWidth > clientWidth;
    }
    private removeRec() {
        const isOverflown = this.checkIsOverflown();
        if (isOverflown && this.MenuCount < this.ElementRef.nativeElement.children.length - 2) {
            this.MenuCount += 1;
            if (this.MenuCount) {
                this.ShowBurgerButton$.next(true);
            }
            this.ElementRef.nativeElement.children[this.ElementRef.nativeElement.children.length - this.MenuCount].classList.add(HIDDEN_CLASS_NAME);
            setTimeout(() => this.removeRec(), 0);
        } else {
            this.menuTrigger?.closeMenu();
            const buttonCount = this.ElementRef.nativeElement.children.length - this.MenuCount;
            for (let i = 0; i < this.menu._allItems.length; i++) {
                if (i < buttonCount) {
                    // @ts-ignore
                    this.menu._allItems.get(i)._elementRef.nativeElement.classList.add(HIDDEN_CLASS_NAME);
                } else {
                    // @ts-ignore
                    this.menu._allItems.get(i)._elementRef.nativeElement.classList.remove(HIDDEN_CLASS_NAME);
                }
            }
        }
    }
    private getContent() {
        const arr = [];
        this.ElementRef.nativeElement.children.forEach((c) => {
            const classes: string[] = [];
            c.classList.forEach((cssClass) => classes.push(cssClass));
            arr.push({
                classes: classes.filter((cssClass) => cssClass !== HIDDEN_CLASS_NAME && cssClass.indexOf('cdk-')),
            });
        });
        return arr;
    }

    public render() {
        this.ManualReRender$.next();
    }
    public renderIfOverflown() {
        if (this.checkIsOverflown()) {
            this.removeRec();
        }
    }
    protected getMenuForTrigger(element: any) {
        // @ts-ignore
        const menuTrigger = this.contentMenuTriggers.find((m) => m._element.nativeElement === element);
        return menuTrigger?.menu;
    }
}
