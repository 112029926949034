import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from '../index';
import {varianteTypesFeatureKey, VarianteTypesState} from '../reducers/variante-types.reducer';

export const selectVarianteTypes = createFeatureSelector< VarianteTypesState>(varianteTypesFeatureKey);

export const getVarianteTypes = createSelector(
    selectVarianteTypes,
    data => data.varianteTypes
);

export const getBookableByUsersVarianteTypes = createSelector(
    getVarianteTypes,
    varianteTypes =>
        varianteTypes && varianteTypes.filter(v => v.CanBeBookedByUser),
);
