import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { ITaskBoardSort, TaskBoardFilter } from '../../../../../dave-tasks/components/tasks-board/tasks-board.component';
export interface TaskBoardPopupComponentDialogData {
    filter: TaskBoardFilter;
    showSortButton?: boolean;
}
@Component({
    selector: 'app-task-board-popup',
    templateUrl: './task-board-popup.component.html',
    styleUrls: ['./task-board-popup.component.scss'],
})
export class TaskBoardPopupComponent {
    public static DefaultConfig: MatDialogConfig = {
        panelClass: ['custom-dialog-class-mobile-fullscreen'],
        width: '70rem',
        height: '70vh',
        maxWidth: '100%',
        maxHeight: '100%'
    };
    public TaskBoardSort: ITaskBoardSort = {sortBy: 'endDate', direction: 'dsc'};
    constructor(
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: TaskBoardPopupComponentDialogData,
    ) {
    }
    OpenFile(fileId: number) {
        this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: {
                fileId,
            },
        });
    }
}
