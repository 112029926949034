import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CustomerEntity } from '../../dave-data-module/entities/customer.entity';
import { EventTypeEntity } from '../../dave-data-module/entities/event-type.entity';
import { EventEntity } from '../../dave-data-module/entities/event.entity';
import { PersonEntity } from '../../dave-data-module/entities/person.entity';
import { UserEntity } from '../../dave-data-module/entities/user.entity';
import { State } from '../../dave-data-module/State';
import { getCustomers } from '../../dave-data-module/State/selectors/customers.selectors';
import { getEventTypes } from '../../dave-data-module/State/selectors/event-type.selector';
import { getPersons } from '../../dave-data-module/State/selectors/person.selectors';
import { getUsers } from '../../dave-data-module/State/selectors/users.selectors';
import { PermissionService } from '../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { getAddressString } from '../../helper/helper';

@Injectable({
    providedIn: 'root',
})
export class IcsService {
    constructor(private store: Store<State>, private PS: PermissionService, private datePipe: DatePipe) {}

    private getICSFile(eventEntity: EventEntity, eventType: EventTypeEntity, user: UserEntity, person: PersonEntity, customer?: CustomerEntity): Promise<string> {
        if (!eventEntity.EventDate || !eventEntity.EventEndDate) {
            throw 'event must have EventDate and EventEndDate'
        }
        return new Promise<string>((resolve, reject) => {
            const ics = require('ics');
            const attendee = person
                ? [
                      {
                          name: person.DisplayName,
                          email: person.Email || user.Email,
                      },
                  ]
                : [];
            const event = {
                start: [
                    eventEntity.EventDate.getFullYear(),
                    eventEntity.EventDate.getMonth() + 1,
                    eventEntity.EventDate.getDate(),
                    eventEntity.EventDate.getHours(),
                    eventEntity.EventDate.getMinutes(),
                ],
                end: [
                    eventEntity.EventEndDate.getFullYear(),
                    eventEntity.EventEndDate.getMonth() + 1,
                    eventEntity.EventEndDate.getDate(),
                    eventEntity.EventEndDate.getHours(),
                    eventEntity.EventEndDate.getMinutes(),
                ],
                title: eventType.Name + (person ? ' mit ' + person.DisplayName : customer ? ' mit ' + customer.Name : ''),
                location: getAddressString(eventEntity)?.replace('\n', ', '),
                organizer: { name: user?.DisplayName, email: user?.Email },
                attendees: attendee,
                description: eventEntity.Description,
            };
            ics.createEvent(event, (error, value) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(value);
                }
            });
        });
    }

    public GetICSFileFromEvent(event: EventEntity): Promise<File> {
        if (!event.EventDate || !event.EventEndDate) {
            throw 'event must have EventDate and EventEndDate'
        }
        return new Promise<File>((resolve, reject) => {
            combineLatest([
                combineLatest([this.store.select(getUsers), this.PS.Has(this.PS.Permission.GetUser)]).pipe(
                    map(([users, hasPermission]) => (hasPermission ? users?.find(user => user.Id === event.UserId) : new UserEntity())),
                ),
                this.store.select(getPersons),
                this.store.select(getCustomers),
                this.store.select(getEventTypes),
            ])
                .pipe(take(1))
                .subscribe(([user, persons, customers, eventTypes]) => {
                    const eventType = eventTypes.find(e => e.Id === event.EventTypeId);
                    const customer = customers.find(c => event.CustomerId === c.Id);
                    const person = persons.find(p => event.PersonId === p.Id);

                    this.getICSFile(event, eventType, user, person, customer).then(
                        v => {
                            let eventDate = this.datePipe.transform(event?.EventDate, 'yyyy/MM/dd');
                            eventDate = eventDate.replace(/[/]/g, '-');
                            let filename = eventDate ? eventDate : '';
                            if (event.Name) {
                                filename += '-' + event.Name;
                            }
                            if (customer?.Name) {
                                filename += '-' + customer.Name;
                            }
                            if (eventType?.Name) {
                                filename += '-' + eventType.Name;
                            }
                            filename = filename.replace(/[~"#%&*:<>?\/\\{|}.]/g, '');
                            filename += '.ics';
                            return resolve(new File([v], filename, { type: 'text/calendar', lastModified: Date.now() }));
                        },
                        err => reject(err),
                    );
                });
        });
    }
}
