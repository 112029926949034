import { AsyncPipe } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { combineLatest, firstValueFrom, switchMap } from 'rxjs';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { StatusFromBackofficeResolver } from '../../../dave-data-module/guards/statusFromBackoffice.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { getCommissionDictionary, getCommissionsActive, getCommissionsFetched } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomersFetched, getNotDeletedCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getStatusFromBackofficeFetched } from '../../../dave-data-module/State/selectors/statusFromBackoffice.selectors';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { IDetailListTemplateData, IDetailListTemplateDataProperty } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { DaveSharedComponentsModule } from '../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { appMatDialogDefaultConfig, isNotNullOrUndefined } from '../../../helper/helper';
import { ExportBusinessVolumeDialogComponentDialogData, ExportBusinessVolumesDialogComponent } from '../export-business-volumes-dialog.component';
import {
    getChartOfAccountDictionary,
    getChartOfAccountsFetched,
} from '../../../dave-data-module/State/selectors/chartOfAccount.selectors';
import { ChartOfAccountResolver } from '../../../dave-data-module/guards/chartOfAccount.resolver';

export interface SelectBusinessVolumesDataDialogComponentData {
    CommissionId?: number;
    CustomerId?: number;
    DisableCommissionForm?: boolean;
    DisableCustomerForm?: boolean;
}

@Component({
    selector: 'app-select-business-volumes-data-dialog',
    templateUrl: './select-business-volumes-data-dialog.component.html',
    styleUrls: ['./select-business-volumes-data-dialog.component.scss'],
    standalone: true,
    imports: [DaveSharedComponentsModule, AsyncPipe, AppButtonModule, FontAwesomeModule, MatDialogModule],
})
export class SelectBusinessVolumesDataDialogComponent {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        maxWidth: '100%',
        width: '35%',
        minWidth: '320px',
    };
    disableCommissionForm: boolean = false;
    disableCustomerForm: boolean = false;
    commissionId: number;
    customerId: number;

    public bvsForm$: Promise<IDetailListTemplateData>;
    public form= new FormGroup({
        CustomerId: new FormControl<{ Id: number; optionLabel: string }>(null),
        CommissionId: new FormControl<{ Id: number; optionLabel: string }>(null),
        From: new FormControl<Moment>(null, [Validators.required]),
        To: new FormControl<Moment>(null, [Validators.required]),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public DialogData: SelectBusinessVolumesDataDialogComponentData,
        @Optional() protected dialogRef: MatDialogRef<SelectBusinessVolumesDataDialogComponent>,
        private store: Store<State>,
        statusFromBackofficeResolver: StatusFromBackofficeResolver,
        commissionResolver: CommissionResolver,
        customerResolver: CustomerResolver,
        private dialog: MatDialog,
        chartOfAccountsResolver: ChartOfAccountResolver,
    ) {
        if (DialogData) {
            this.commissionId = DialogData?.CommissionId;
            this.customerId = DialogData?.CustomerId;
            this.disableCommissionForm = DialogData?.DisableCommissionForm;
            this.disableCustomerForm = DialogData?.DisableCustomerForm;
        }

        firstValueFrom(this.store.select(getCommissionsFetched)).then((fetched) => {
            if (!fetched) {
                commissionResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getCustomersFetched)).then((fetched) => {
            if (!fetched) {
                customerResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getStatusFromBackofficeFetched)).then((fetched) => {
            if (!fetched) {
                statusFromBackofficeResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getChartOfAccountsFetched)).then((fetched) => {
            if (!fetched) {
                chartOfAccountsResolver.resolve();
            }
        });

        this.bvsForm$ = firstValueFrom(
            combineLatest([
                getFetched$(this.store, getCustomersFetched, getNotDeletedCustomers),
                getFetched$(this.store, getStatusFromBackofficeFetched, getStatusFromBackofficeFetched).pipe(
                    switchMap(() => getFetched$(this.store, getCommissionsFetched, getCommissionsActive)),
                ),
                getFetched$(this.store, getCommissionsFetched, getCommissionDictionary),
                getFetched$(this.store, getChartOfAccountsFetched, getChartOfAccountDictionary)
            ]),
        ).then(([ce, activeCommissions, allCommissions, chartDic]) => {
            const Commission = this.commissionId ? allCommissions[this.commissionId] : null;
            const CustomerToCommission = this.customerId ? ce.find((customer) => customer.Id === this.customerId) : null;
            const CommissionId = Commission
                ? { Id: Commission.Id, optionLabel: Commission.DisplayName }
                : {
                    Id: null,
                    optionLabel: '',
                };
            const CustomerId = CustomerToCommission
                ? {
                    Id: CustomerToCommission?.Id,
                    optionLabel: CustomerToCommission.Name,
                }
                : { Id: null, optionLabel: '' };

            const today = moment();
            const firstDayOfLastMonth = moment().subtract(1, 'months').startOf('month');

            this.form.reset({
                CommissionId: CommissionId ? CommissionId : null,
                CustomerId: CustomerId ? CustomerId : null,
                From: firstDayOfLastMonth,
                To: today,
            });

            if (this.disableCommissionForm) {
                this.form.controls.CommissionId.disable();
            }
            if (this.disableCustomerForm) {
                this.form.controls.CustomerId.disable();
            }

            const Properties: IDetailListTemplateDataProperty[] = [
                {
                    key: 'Zeitraum',
                    formControl: this.form,
                    options: {
                        specialInput: {
                            timeSpan: {
                                formControlFrom: this.form.controls.From,
                                formControlTo: this.form.controls.To,
                            },
                        },
                    },
                },
                {
                    key: 'Auftrag',
                    formControl: this.form.controls.CommissionId,
                    options: {
                        specialInput: {
                            singleSelectSearch: {
                                options: activeCommissions.map((commission) => ({
                                    Id: commission.Id,
                                    optionLabel: commission.DisplayName,
                                })),
                                compareOptions: (a, b) => a.Id === b.Id,
                            },
                        },
                    },
                },
                {
                    key: 'Bauherr/Lieferant',
                    formControl: this.form.controls.CustomerId,
                    options: {
                        specialInput: {
                            singleSelectSearch: {
                                options: ce.map((customer) => ({
                                    Id: customer.Id,
                                    optionLabel:
                                        customer.DisplayName
                                        +
                                        ' / ' +
                                        (customer.CustomerNo === null || customer.CustomerNo === undefined ? ' ' : customer?.CustomerNo) +
                                        (customer.KontoCOAId === null || customer.KontoCOAId === undefined ? '' : ' / ') +
                                        ( (customer.KontoCOAId === null || chartDic[customer.KontoCOAId] === null) ||
                                        (customer.KontoCOAId === undefined || chartDic[customer.KontoCOAId] === undefined) ? '' : chartDic[customer.KontoCOAId]?.Number ),
                                })),
                                compareOptions: (a, b) => a.Id === b.Id,
                            },
                        },
                    },
                },
            ].filter(isNotNullOrUndefined);
            return {
                Properties,
            };
        });
    }

    openExportBvs() {
        this.dialog.open<ExportBusinessVolumesDialogComponent, ExportBusinessVolumeDialogComponentDialogData>(ExportBusinessVolumesDialogComponent, {
            ...ExportBusinessVolumesDialogComponent.DefaultConfig,
            data: {
                FromDate: moment(this.form.controls.From.value).set({hour: 0, minute: 0, second: 0}),
                ToDate: moment(this.form.controls.To.value).set({hour: 23, minute: 59, second: 59}),
                CommissionId: this.form.controls.CommissionId.value ? this.form.controls.CommissionId.value.Id : null,
                CustomerId: this.form.controls.CustomerId.value ? this.form.controls.CustomerId.value.Id : null,
            },
        });
        this.dialogRef.close();
    }
}
