import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { DaveActions } from "../actions/actions";
import { HttpService } from "../../services/http.service";
import { BaseActionTypes } from '../actions/base.actions';
import { catchError, withLatestFrom, switchMap, map, filter, take, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { State } from "../index";
import { Store } from '@ngrx/store';
import { EmailConnection2EmailSettingsTypeEntityFromBackend } from '../../entities/emailConnection2EmailSettingsType.entity';
import { EmailConnection2EmailSettingsTypeActionTypes } from '../actions/emailConnection2EmailSettingsType.actions';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';


enum ErrorCodes {
    Load = "E-Mail - Prozess Abrufen fehlgeschlagen",
    Modify = "E-Mail - Prozess Bearbeiten fehlgeschlagen",
    Add = "E-Mail - Prozess Hinzufügen fehlgeschlagen"
  }

@Injectable()
export class EmailConnection2EmailSettingsTypeEffects {
    constructor( private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService){

    }

  LoadEmailConnection2EmailSettingsTypes = createEffect(() => this.actions$.pipe(
    ofType(EmailConnection2EmailSettingsTypeActionTypes.GetEmailConnection2EmailSettingsTypeRequest),
    withLatestFrom(this.store$),
    switchMap(([action, store]) => {
      const queryString = `
      query{
        emailConnection2EmailSettingsType{
          emailConnectionId
          emailSettingsTypeId
          id
          partnerId
        }
      }`;
      return this.gatewayHttpService
        .graphQl({ query: queryString }, { token: store.base.token, retry: true })
        .pipe(
          map(res =>
            res && res.emailConnection2EmailSettingsType
              ? EmailConnection2EmailSettingsTypeActionTypes.UpdateEmailConnection2EmailSettingsType({
                  Payload: res.emailConnection2EmailSettingsType.map(val =>
                    EmailConnection2EmailSettingsTypeEntityFromBackend(val)
                  )
                })
              : BaseActionTypes.ErrorAction({
                  Payload: { ToasterMessage: ErrorCodes.Load }
                })
          ),
          catchError((err, caught) =>
            of(
              BaseActionTypes.ErrorAction({
                Payload: {
                  ToasterMessage: ErrorCodes.Load,
                  Err: err,
                  Caught: caught
                }
              })
            )
          )
        );
    })
  ));


  AddEmailConnection2EmailSettingsTypes = createEffect(() => this.actions$.pipe(
    ofType(EmailConnection2EmailSettingsTypeActionTypes.AddEmailConnection2EmailSettingsType),
    withLatestFrom(this.store$),
    switchMap(([action, store]) => {
      const queryString = `
mutation{
  createEmailConnection2EmailSettingsType(
    ${stringifyIfNotNullOrUndefined(action.Payload, 'emailConnectionsId')}
    ${stringifyIfNotNullOrUndefined(action.Payload, 'emailSettingsTypeId')}
    ){
    emailConnectionId
    emailSettingsTypeId
    id
    partnerId
  }
}`;
      return this.gatewayHttpService
        .graphQl({ query: queryString }, { token: store.base.token })
        .pipe(
          map(res =>
            res && res.createEmailConnection2EmailSettingsType
              ? EmailConnection2EmailSettingsTypeActionTypes.UpdateEmailConnection2EmailSettingsType({
                  Payload: [
                      ...res.createEmailConnection2EmailSettingsType.map(r => EmailConnection2EmailSettingsTypeEntityFromBackend(r))
                  ]
                })
              : BaseActionTypes.ErrorAction({
                  Payload: { ToasterMessage: ErrorCodes.Add }
                })
          ),
          catchError((err, caught) =>
            of(
              BaseActionTypes.ErrorAction({
                Payload: {
                  ToasterMessage: ErrorCodes.Add,
                  Err: err,
                  Caught: caught
                }
              })
            )
          )
        );
    })
  ));




RemoveEmailConnection2EmailSettingsTypes = createEffect(() => this.actions$.pipe(
  ofType(EmailConnection2EmailSettingsTypeActionTypes.DeleteEmailConnection2EmailSettingsType),
  withLatestFrom(this.store$),
  switchMap(([action, store]) => {
    const queryString = `
    mutation{
      deleteEmailConnection2EmailSettingsType(id: ${action.Payload.id})
    }`;
    return this.gatewayHttpService
      .graphQl({ query: queryString }, { token: store.base.token })
      .pipe(
        withLatestFrom(this.store$),
        map(([res, store]) => res && res.deleteEmailConnection2EmailSettingsType
            ? EmailConnection2EmailSettingsTypeActionTypes.UpdateEmailConnection2EmailSettingsType({
                Payload : store.emailConnection2EmailSettingsTypes.emailConnection2EmailSettingsTypes.filter(
                    emailConnection2EmailSettingsType => emailConnection2EmailSettingsType.Id !== action.Payload.id
                )
            })
            : BaseActionTypes.ErrorAction({
                Payload: { ToasterMessage: ErrorCodes.Add }
              })
        ),
        catchError((err, caught) =>
          of(
            BaseActionTypes.ErrorAction({
              Payload: {
                ToasterMessage: ErrorCodes.Add,
                Err: err,
                Caught: caught
              }
            })
          )
        )
      );
  })
));
}
