<header class="header">
    <button app-round-button Inverted class="button" matTooltip="Aktualisieren" matTooltipPosition="left" (click)="UpdateEvents()">
        <fa-icon icon="sync"></fa-icon>
    </button>

    <button app-round-button Inverted class="button" [Active]="filter.ShowFilter" matTooltip="Filter" matTooltipPosition="left" (click)="filter.Toggle()" [matBadge]="filterAmount$ | async">
        <fa-icon [icon]="filter.ShowFilter ? 'times' : 'filter'"></fa-icon>
    </button>

    <button app-round-button Inverted class="button" matTooltip="Listenansicht" matTooltipPosition="left" [Active]="ListView" (click)="ToggleListView()">
        <fa-icon icon="list"></fa-icon>
    </button>

    <!-- ToDo 'a' statt 'button', wenn event.stopImmediatePropagation() tut -->

    <button
        app-round-button
        Inverted
        class="button"
        matTooltipPosition="left"
        routerLink="neuesEreignis"
        *ngIf="!(CurrentCustomer$ | async) || ((PS.Has(PS.Permission.CreateEvents) | async) && (CurrentCustomer$ | async)?.CanEdit && (PS.Has(PS.Permission.EditCustomer) | async))"
        [Disabled]="(CurrentCustomer$ | async)?.Deleted"
        [matTooltip]="(CurrentCustomer$ | async)?.Deleted ? (CS.GetSingle$() | async) + ' ist gelöscht' : 'Neues Ereignis'"
    >
        <app-double-icon Icon="history" Icon2="plus"></app-double-icon>
    </button>
</header>
<section>
    <app-timeline-view class="content" [ListView]="ListView" [EventFilter]="FilterValues$ | async"></app-timeline-view>
    <app-filter-card UnlimitedHeight #filter [class.margin]="filter.ShowFilter" [Inline]="true" [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
</section>
