import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {MandateTypeActions, MandateTypeActionTypes} from '../actions/mandate-type.actions';
import {MandateTypeEntity} from '../../entities/mandate-type.entity';

export interface MandateTypeState extends EntityState<MandateTypeEntity> {
    fetched: boolean;
}

export const MANDATE_TYPE_KEY = 'mandateType';

const adapter = createEntityAdapter<MandateTypeEntity>({
    selectId: mandateType => mandateType.Id
});

const initialState = adapter.getInitialState({
    fetched: false
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export function mandateTypeReducer(
    state = initialState,
    action: MandateTypeActions
): MandateTypeState {
    switch (action.type) {
        case MandateTypeActionTypes.GetMandateTypesResponse: {
            return adapter.setAll(action.Payload, {...state, fetched: true});
        }

        default: {
            return state;
        }
    }
}

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const {selectAll} = adapter.getSelectors();

export const selectAllMandateTypes = selectAll;

export const selectMandateTypesFetched = (state: MandateTypeState) => state.fetched;
