<button
    app-round-button
    [Inverted]="Inverted"
    matTooltip="Bericht hinzufügen"
    [IsLoading]="AddReportService.IsGenerating$ | async"
    [matMenuTriggerFor]="(Types$ | async)?.length === 1 ? null : menu"
    (click)="btnClick()"
>
    <app-double-icon [Icon]="GeneratedDocumentsPageMeta.Icon" Icon2="plus"></app-double-icon>
</button>
<mat-menu #menu >
<!--    <ng-container *ngIf="CommissionId">-->
<!--        <button mat-menu-item *ngFor="let type of DiaryTypes" (click)="openSelectDiaryTimespan(type.value)">{{type.name}}</button>-->
<!--    </ng-container>-->
    <button mat-menu-item *ngFor="let type of Types$ | async" (click)="AddReport(type)">{{type.Name}}</button>
</mat-menu>
