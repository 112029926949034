import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BehaviorSubject, Observable, Subscription, switchMap } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CommissionNameService } from '../dave-utils-module/dave-shared-components-module/services/commission-name.service';
import { DaveHeaderHeight, SearchQueriesDebounceTime } from './../helper/helper';
export type DaveListCardcolumnProperties<T> = {
    suffixButton?: ButtonConfig<T>;
    /**
     * @deprecated use {@link cssClass} instead
     */
    customBackgroundColorForListEntry?: { default: string; hover: string };
    /**
     * class string is added to list entry
     * can be used to set custom colors for clickable list items, see {@link src/app/styles/_clickable-list.theme.scss}
     */
    cssClass?: string;
} & Partial<T>;
export type ListCartInputData<T extends DaveListCardcolumnProperties<T>> = MatTableDataSource<T> | Observable<ReadonlyArray<T> | T[]> | ReadonlyArray<T> | T[];
export interface ButtonConfig<T> {
    icon?: IconProp;
    icon2?: IconProp;
    buttonText?: string;
    tooltip?: string;
    routerLink?: (args: T) => any[] | null | undefined;
    onClick?: (args: T) => void;
    hide?: (args: T) => boolean;
    disabled?: boolean;
    disabledTooltip?: string;
}
@Component({
    selector: 'app-dave-list-card',
    templateUrl: './dave-list-card.component.html',
    styleUrls: ['./dave-list-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaveListCardComponent<T = any> implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    // @ViewChild('scrollContainer') scrollContainer: ElementRef;
    @ViewChild('searchInput') searchInput: ElementRef;
    @Output() EntryClicked$ = new EventEmitter<{ entry: T; index: number; element: HTMLElement }>();
    @Input() EmptyText = 'Keine Einträge vorhanden';
    @Input() DisableListEntryByButton = false;
    @Input() Clickable = false;
    @Input()
    get Data() {
        return this.TableData;
    }
    set Data(val: ListCartInputData<T>) {
        setTimeout(() => this.setMatSort(), 100);
        if (val instanceof MatTableDataSource) {
            this.TableData = val;
            // this.NoContent$.next(false);
            this.Length$.next(val.data.length);
            this.searchFunction = (searchQuery: string) => {
                (this.TableData as MatTableDataSource<T>).filter = searchQuery.trim().toLowerCase();
            };
        } else if (val instanceof Observable) {
            const searchSub = new BehaviorSubject<string>('');
            this.TableData = val.pipe(switchMap((data) => searchSub.pipe(map((searchQuery) => (searchQuery ? data.filter((d) => this.searchCompareFunction(searchQuery, d)) : data)))));
            this.searchFunction = (searchQuery: string) => {
                searchSub.next(searchQuery.trim().toLowerCase());
            };
            val.subscribe((v) => {
                // this.NoContent$.next(!v || !v.length);
                this.Length$.next(v?.length || 0);
            });
        } else {
            this.TableData = val;
            this.searchFunction = (searchQuery: string) => {
                this.TableData = searchQuery ? val.filter((d) => this.searchCompareFunction(searchQuery, d)) : val;
            };
            // this.NoContent$.next(!val || !val.length);
            this.Length$.next(val?.length || 0);
        }
    }
    @Input() Headline: string;
    @Input() Hint: string;
    @Input() HeaderIcon: IconProp;
    @Input() HeaderIcon2: IconProp;
    @Input() DisableSortHeader = false;
    @Input() CustomHeaderTemplate: TemplateRef<any>;

    protected searchVisible = false;
    protected searchForm = new FormControl<string>('');
    private searchFunction: (searchQuery: string) => void;

    /**
     * Is only used if no Data is not of type MatTableDataSource, in this case use filterPredicate of MatTableDataSource
     * @param searchQuery
     * @param element
     */
    private searchCompareFunction = (searchQuery: string, element: T) =>
        this.CustomSearchCompareFunction ? this.CustomSearchCompareFunction(searchQuery, element) : this.AutoDisplayedColumns?.some((c) => {
            const content = element[c];
            if(content) {
                return (content + '').toLowerCase().includes(searchQuery);
            }
            return false;
        });
    @Input() CustomSearchCompareFunction: (searchQuery: string, element: T) => boolean;
    @Input() HideSearchButton = false;

    public AutoDisplayedColumns: string[] = [];
    private displayedColumns: string[] = [];
    public CustomCellTemplatesArray: { columnKey: string; template: TemplateRef<any> }[] = null;
    private customCellTemplates: { [key: string]: TemplateRef<any> } = null;
    @Input() CustomFooterCellTemplates: { [key: string]: TemplateRef<any> } = {};
    @Input() set CustomCellTemplates(templates: { [key: string]: TemplateRef<any> }) {
        this.customCellTemplates = templates;
        this.CustomCellTemplatesArray = Object.keys(templates).map((key) => ({ columnKey: key, template: templates[key] }));
        this.AutoDisplayedColumns = this.DisplayedCollums.filter((v) => this.customCellTemplates[v] === undefined);
    }
    get CustomCellTemplates() {
        return this.customCellTemplates;
    }
    @Input() set DisplayedCollums(DisplayedColumns: string[]) {
        this.displayedColumns = DisplayedColumns;
        this.AutoDisplayedColumns = this.CustomCellTemplates ? DisplayedColumns.filter((v) => this.CustomCellTemplates[v] === undefined) : DisplayedColumns;
    }
    get DisplayedCollums() {
        return this.displayedColumns;
    }
    @Input() TableHeaderCollumsMap: {} = null;
    @Input() GetRouterLinkFunction: (args: T) => any[] | null | undefined;
    @Input() Selectable = false;
    public TableData: ListCartInputData<T>;
    // public NoContent$ = new BehaviorSubject<boolean>(true);
    public Length$ = new BehaviorSubject<number>(0);
    @Output() Count = new EventEmitter<number>();
    @Input() SelectedIndex: number = null;
    @Input() IsLoading = false;
    @Input() IsExpandable = false;
    @Input() Expanded = true;
    @Input() HideCount = false;
    @Output() ExpandedChange = new EventEmitter<boolean>();
    @Input() DefaultSortActive: string; // & keyof T;
    @Input() DefaultSortDirection: SortDirection = 'asc';
    @Input() HasSuffixButton = false;
    @Input() tableLayoutFixed = false;
    public HeaderHeight = DaveHeaderHeight;
    private subs: Subscription[] = [];
    constructor(private router: Router, private elementRef: ElementRef, public CNS: CommissionNameService) {}

    ngOnInit(): void {
        this.subs.push(
            this.Length$.subscribe((l) => this.Count.emit(l)),
            this.searchForm.valueChanges.pipe(debounceTime(SearchQueriesDebounceTime)).subscribe((v) => this.searchFunction(v)),
        );
    }

    RouteTo(entry: T) {
        if (!this.GetRouterLinkFunction) {
            return;
        }
        this.router.navigate(this.GetRouterLinkFunction(entry));
    }
    get DisplayedColumnsWithButton() {
        return [...this.DisplayedCollums, 'dave-list-entry-suffix-button'];
    }
    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    ngAfterViewInit() {
        this.setMatSort();
    }
    setMatSort() {
        if (this.TableData instanceof MatTableDataSource && !this.DisableSortHeader) {
            if (this.sort) {
                this.sort.disabled = false;
                this.TableData.sort = this.sort;
            }
        } else if (this.sort) {
            this.sort.disabled = true;
        }
    }
    RowClick(rowData: T, i: number, $event: MouseEvent) {
        this.EntryClicked$.emit({ entry: rowData, index: i, element: this.elementRef.nativeElement.querySelectorAll('tr.mat-row')[i] });
        this.SelectedIndex = this.Selectable ? i : null;
        this.RouteTo(rowData);
    }

    onSearchBtnClick() {
        this.searchVisible = !this.searchVisible;
        this.searchForm.setValue('');
        if (this.searchVisible) {
            setTimeout(() => this.searchInput?.nativeElement?.focus(), 1);
        }
    }
}
