import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BVCustomNameEntity, BVCustomNameEntityFromBackend } from '../../entities/b-v-custom-name.entity';
import { BvCustomNamesType, DaveMutationChangeBvCustomNamesArgs, DaveMutationCreateBvCustomNamesArgs, DaveMutationDeleteBvCustomNamesArgs, QueryBvCustomNamesArgs } from '../../graphql-types';
import { GraphQlEffectService } from '../../services/graph-ql-effect.service';
import { HttpService } from '../../services/http.service';
import { BVCustomNameActions } from '../actions/resource-dispo/b-v-custom-names.actions';
import { State } from '../index';

enum ErrorCodes {
    Load = 'Tätigkeiten Abrufen fehlgeschlagen',
    Modify = 'Tätigkeit Bearbeiten fehlgeschlagen',
    Remove = 'Tätigkeit Löschen fehlgeschlagen',
    Add = 'Tätigkeit Hinzufügen fehlgeschlagen',
}

@Injectable()
export class BVCustomNameEffects {
    CreateBVCustomName$ = this.graphQlEffectService.createAddEffect<DaveMutationCreateBvCustomNamesArgs, BVCustomNameEntity, BvCustomNamesType>(
        'createBVCustomNames',
        BVCustomNameEntity.GqlFields.join(','),
        BVCustomNameActions.add,
        BVCustomNameActions.updateOne,
        BVCustomNameEntityFromBackend,
        ErrorCodes.Add,
        ['name'],
    );
    ModifyBVCustomName$ = this.graphQlEffectService.createModifyEffect<DaveMutationChangeBvCustomNamesArgs, BVCustomNameEntity, BvCustomNamesType>(
        'changeBVCustomNames',
        BVCustomNameEntity.GqlFields.join(','),
        BVCustomNameActions.change,
        BVCustomNameActions.updateOne,
        BVCustomNameEntityFromBackend,
        ErrorCodes.Modify,
        ['id'],
        ['name'],
    );
    RemoveBVCustomName$ = this.graphQlEffectService.createModifyEffect<DaveMutationDeleteBvCustomNamesArgs, BVCustomNameEntity, BvCustomNamesType>(
        'deleteBVCustomNames',
        BVCustomNameEntity.GqlFields.join(','),
        BVCustomNameActions.delete,
        BVCustomNameActions.updateOne,
        BVCustomNameEntityFromBackend,
        ErrorCodes.Remove,
        ['id'],
    );
    GetBVCustomName = this.graphQlEffectService.createGetAllEffect<QueryBvCustomNamesArgs, BVCustomNameEntity, BvCustomNamesType>(
        'bVCustomNames',
        BVCustomNameEntity.GqlFields.join(','),
        BVCustomNameActions.load,
        BVCustomNameActions.updateAll,
        (a) => a.map(BVCustomNameEntityFromBackend),
        ErrorCodes.Load,
    );

    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService, private graphQlEffectService: GraphQlEffectService) {}
}
