import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getLatestUpdatedAt } from '../../../helper/reducer.helper';
import { ResourceTypeEntity } from '../../../entities/resource-dispo/resource-type.entity';
import { ResourceTypeActionTypes } from '../../actions/resource-dispo/resource-type.actions';

export const RESOURCE_TYPE_KEY = 'resourceType';
export interface ResourceTypeState extends EntityState<ResourceTypeEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;
}

const adapter = createEntityAdapter<ResourceTypeEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const resourceTypeReducer = createReducer(
    initialState,
    on(ResourceTypeActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(ResourceTypeActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllResourceType = selectAll;
export const selectResourceTypeEntities = selectEntities;

export const selectResourceTypeFetched = (state: ResourceTypeState) => state.fetched;
export const    selectResourceTypeLatestUpdatedAt = (state: ResourceTypeState) => state.latestUpdatedAt;
