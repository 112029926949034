import { LicenseProductType } from '../graphql-types';
export enum ProductEntityIds {
    BaseLicense = 1
}
export class ProductEntity {
    constructor(public Id: number = null, public Name: string = null) {}

    public Clone(override: ProductEntityAttributes = {}): ProductEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ProductEntity(), attr);
    }
}

export function ProductEntityFromBackend(res: LicenseProductType): ProductEntity {
    return new ProductEntity(res.id, res.name);
}

export interface ProductEntityAttributes {
    Id?: number;
    Name?: string;
}
