<ng-container *ngIf="Data$ | async as data; else loadingTemplate">
    <app-detail-view-template>
        <ng-container *appDetailViewButtons>
            <button *ngIf="!(Editing$ | async)" app-round-button Inverted class="header-button" matTooltip="Bearbeiten" aria-label="Bearbeiten" (click)="Editing$.next(true)">
                <fa-icon icon="pencil-alt"></fa-icon>
            </button>
            <button *ngIf="Editing$ | async" app-round-button [class.save-active]="Editing$ | async " class="header-button" matTooltip="Speichern" aria-label="Speichern" (click)="Save$.next()">
                <fa-icon icon="save"></fa-icon>
            </button>
            <button *ngIf="Editing$ | async" app-round-button Inverted class="header-button" matTooltip="Zurücksetzen" aria-label="Zurücksetzen" (click)="Editing$.next(false)">
                <fa-icon icon="undo"></fa-icon>
            </button>
            <button *ngIf="Editing$ | async" app-round-button [class.delete-active]="Editing$ | async " class="header-button" Color="red" matTooltip="Löschen" aria-label="Löschen" (click)="Delete()">
                <fa-icon icon="trash"></fa-icon>
            </button>
        </ng-container>

        <ng-container *appDetailViewLeftContent>
            <div>
                <app-detail-list-template class="detail-card" [Editing]="Editing$ | async" [Data]="TransmissionData | async">
                    <div *ngIf="!(Editing$ | async)" class="contentContainerCommission">
                        <div class="links">
                            <!-- customer-button-wrapper für Theming -->
                            <div class="admin-button-wrapper link-wrapper">
                                <a
                                    *ngIf="!!data.transmission.CustomerId"
                                    app-button
                                    Lighter
                                    class="wide-button"
                                    [matTooltip]="CS.GetMultiple$() | async"
                                    [routerLink]="['/', CustomerPath, data.transmission.CustomerId]"
                                >
                                    <fa-icon [icon]="CustomerIcon"></fa-icon>
                                </a>
                            </div>
                            <div class="commission-button-wrapper link-wrapper" *ngIf="data.transmission.CommissionId && PS.Has(PS.Permission.GetCommission) | async">
                                <a
                                    app-button
                                    Lighter
                                    class="wide-button"
                                    [matTooltip]="cls.getMultiple$('Commission') | async"
                                    [routerLink]="['/', CommissionPath, data.transmission.CustomerId || AllCommissionPath, data.transmission.CommissionId]"
                                >
                                    <fa-icon [icon]="CommissionIcon"></fa-icon>
                                </a>
                            </div>
                            <!-- dms-button-wrapper für Theming -->
                            <div class="dms-button-wrapper link-wrapper" *ngIf="PS.Has(PS.Permission.GetFiles) | async">
                                <button
                                    app-button
                                    [disabled]
                                    Lighter
                                    class="wide-button"
                                    matTooltip="DMS"
                                    [routerLink]="['/', DMSPath]"
                                    [queryParams]="{ folderId: FolderId$ | async }"
                                >
                                    <!--evtl. Commission ID übergeben, um nach dem Auftrag zu filtern-->
                                    <fa-icon [icon]="DMSIcon"></fa-icon>
                                </button>
                            </div>
                            <!-- video-button-wrapper für Theming -->
                            <!--<div class="video-button-wrapper link-wrapper">
                                <a
                                    app-button
                                    Lighter
                                    class="wide-button"
                                    matTooltip="Videodoku"
                                    [routerLink]="['/', VideoPath, data.commission.CustomerId]"
                                    >
                                    <fa-icon icon="video"></fa-icon>
                                </a>
                            </div>-->

                            <!--                        &lt;!&ndash; history-button-wrapper für Theming &ndash;&gt;-->
                            <div class="history-button-wrapper link-wrapper" *ngIf="PS.Has(PS.Permission.GetEvents) | async">
                                <a
                                    *ngIf="!!data.transmission.CustomerId"
                                    app-button
                                    Lighter
                                    class="wide-button"
                                    matTooltip="Chronik"
                                    [routerLink]="['/', ChronikPath, data.transmission.CustomerId]"
                                >
                                    <fa-icon [icon]="ChronikIcon | icon"></fa-icon>
                                </a>
                            </div>
                        </div>
                        <div class="customericon" *ngIf="!(Editing$ | async)">
                            <fa-icon *ngIf="!!(Customer$ | async)?.Name" icon="building"> </fa-icon>
                            {{ (Customer$ | async)?.Name }}
                        </div>
                    </div>
                </app-detail-list-template>

                <mat-card class="detail-card" *ngIf="!TransmissionClosed">
                    <div class="headline">
                        <h3>Videoübertragung</h3>

                        <button
                            *ngIf="(Transmission$ | async).State == 0"
                            [Disabled]="!(ClientConnected$ | async) || !(SocketReady$ | async)"
                            app-round-button
                            Inverted
                            class="header-button"
                            [matTooltip]="!(ClientConnected$ | async) ? 'der User muss den link öffnen um die Übertragung zu starten' : 'Videostream starten'"
                            (click)="Connect()"
                        >
                            <fa-icon icon="play"></fa-icon>
                        </button>
                        <div class="dms-button-wrapper" *ngIf="(Transmission$ | async).State == 1 && (Connected$ | async) && PS.Has(PS.Permission.GetFiles) | async">
                            <button app-round-button Inverted class="header-button" matTooltip="Videostream stoppen" (click)="Stop()" [Disabled]="!(SocketReady$ | async)">
                                <fa-icon icon="phone-slash"></fa-icon>
                            </button>
                        </div>
                        <button app-round-button Inverted class="header-button" matTooltip="Link generieren" (click)="Link()">
                            <fa-icon icon="link"></fa-icon>
                        </button>
                        <button
                            [Disabled]="!(Connected$ | async) || !(SocketReady$ | async)"
                            app-round-button
                            Inverted
                            class="header-button"
                            matTooltip="Foto aufnehmen"
                            (click)="Photo()"
                        >
                            <fa-icon icon="camera"></fa-icon>
                        </button>
                    </div>
                    <div class="remote">
                        <video class="remote" #remote autoplay playsinline></video>
                    </div>
                    <div class="local">
                        <video class="local" #local autoplay playsinline muted></video>
                    </div>
                </mat-card>

                <app-file-explorer
                    PreventFileClick
                    PreventFileUploadClick
                    [SelectedFolderId]="FolderId$ | async"
                    [FileUploadButton]="true"
                    (FileUploadClicked)="
                        PreviewOpen = false;
                        OpenUploadDialog({
                            FolderId: $event,
                            CustomerId: data.transmission.CustomerId,
                            TransmissionId: data.transmission.Id,
                            CommissionId: data.transmission.CommissionId
                        })
                    "
                    (FileClicked)="PreviewedFileId$.next($event); PreviewOpen = true"
                    (fileExplorerContext) = "ImageIds = $event"
                    class="detail-card mat-card"
                    style="height: 40rem; padding: 0;"
                >
                </app-file-explorer>
            </div>
        </ng-container>
        <ng-container *appDetailViewRightContent>
            <mat-card class="detail-card" *ngIf="(Transmission$ | async).State <= 1">
                <h3>
                    <fa-icon icon="info-circle"></fa-icon>
                    Infos:
                </h3>
                <p>Verbindungsstatus: <span *ngIf="ClientConnected$ | async">verbunden</span><span *ngIf="!(ClientConnected$ | async)">nicht verbunden</span></p>
                <p>Verzögerung: {{ ConnectionText }}</p>
            </mat-card>

            <mat-card *ngIf="Event$ | async" class="detail-card note">
                <h3>
                    <fa-icon icon="sticky-note"></fa-icon>
                    Notiz
                </h3>

                <app-textarea *ngIf="Editing$ | async; else markdownTemplate" [Value]="TmpDescription" [Fill]></app-textarea>

                <ng-template #markdownTemplate>
                    <ngx-simplebar>
                        <markdown class="markdown" [data]="Linkify(data.event.Description?.replace(StyleTagRegEx, ''))"></markdown>
                    </ngx-simplebar>
                </ng-template>
            </mat-card>

            <mat-card class="detail-card settings-card" *ngIf="(Transmission$ | async).State <= 1">
                <h3>
                    <fa-icon icon="cog"></fa-icon>
                    Settings:
                </h3>

                <!--            <button app-button (click)="ConnectSocket(true)" class="fw" *ngIf="ShowUseHere">Hier verwenden</button>-->

                <mat-slide-toggle [formControl]="this.SettingsForm.controls.AllowClientShoot"> </mat-slide-toggle>
                <label class="toggle">Aufnahmen durch den Streamingpartner erlauben</label>
                <br />
                <mat-slide-toggle
                    [matTooltip]="
                        'Diese Funktionalität steht Ihnen zur Verfügung, wenn das Smartphone des Streamingpartners diese Funktionalität besitzt. Unter iOS wird dies derzeit nicht unterstützt!'
                    "
                    [formControl]="this.SettingsForm.controls.FlashLight"
                >
                    Taschenlampe
                </mat-slide-toggle>

                <mat-form-field class="fw" *ngIf="SpeakerSetEnabled">
                    <mat-label>Lautsprecher</mat-label>
                    <mat-select [formControl]="SettingsForm.controls.Speaker">
                        <mat-select-trigger>
                            {{ SettingsForm.controls.Speaker.value ? GetSpeaker(SettingsForm.controls.Speaker.value)?.label : 'Lautsprecher' }}
                        </mat-select-trigger>
                        <mat-option *ngFor="let speaker of this.Speakers$ | async" [value]="speaker.deviceId">{{ speaker?.label }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="fw">
                    <mat-label>Mikrofone</mat-label>
                    <mat-select [formControl]="SettingsForm.controls.Microphone">
                        <mat-select-trigger>
                            {{ SettingsForm.controls.Microphone.value ? GetMicrophone(SettingsForm.controls.Microphone.value)?.label : 'Mikrofone' }}
                        </mat-select-trigger>
                        <mat-option *ngFor="let microphone of this.Microphones$ | async" [value]="microphone.deviceId">{{ microphone?.label }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="fw">
                    <mat-label>Kamera</mat-label>
                    <mat-select [formControl]="SettingsForm.controls.Camera">
                        <mat-select-trigger>
                            {{ SettingsForm.controls.Camera.value ? GetCamera(SettingsForm.controls.Camera.value)?.label : 'Kamera' }}
                        </mat-select-trigger>
                        <mat-option *ngFor="let camera of this.Cameras$ | async" [value]="camera.deviceId">{{ camera?.label }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card>
            <mat-card class="detail-card" *ngIf="Images?.length > 0 && (Transmission$ | async).State == 1">
                <h3>
                    <fa-icon icon="images"></fa-icon>
                    Bilder:
                </h3>
                <ng-container *ngFor="let image of Images">
                    <mat-card class="detail-card">
                        <app-loading *ngIf="!image.Taken && !image.Uploaded && image.Loading"></app-loading>
                        <img class="image-preview" [src]="image.Preview" *ngIf="image.Taken && !image.Loading" [alt]="'image: ' + image.Id" />
                    </mat-card>
                </ng-container>
            </mat-card>
        </ng-container>
    </app-detail-view-template>
    <mat-card *ngIf="PreviewOpen" class="new-document-overlay">
        <button app-round-button class="back-button" (click)="PreviewOpen = false">
            <fa-icon icon="chevron-right"></fa-icon>
        </button>
        <app-preview *ngIf="PreviewedFileId$ | async as id" [FileId$]="PreviewedFileId$" [fileExplorerContext]="ImageIds" (ClosePreview)="PreviewOpen = false" class="preview"> </app-preview>
    </mat-card>
</ng-container>
<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>
