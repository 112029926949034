import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CdkDragDrop,DragDropModule,moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component,EventEmitter,Inject,Input,Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog,MatDialogConfig,MatDialogModule,MatDialogRef,MatDialogState } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { firstValueFrom } from 'rxjs';
import { FileEntity } from '../dave-data-module/entities/file.entity';
import { FileDataService } from '../dave-data-module/services/file-data.service';
import { FileCardComponent } from '../dave-dms-module/dave-dms/file-card/file-card.component';
import { DaveFilePreviewComponent,DaveFilePreviewComponentDialogData } from '../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { FilenameInputComponent } from '../filename-input/filename-input.component';
import { appMatDialogDefaultConfig } from '../helper/helper';
import { getErrorMessage } from '../helper/validation.helper';
import { LoadingService } from '../services/loading.service';
import { SmallFileCardModule } from '../small-file-card/small-file-card.module';

export interface SortFilesDialogComponentDialogData {
    documentIds: number[];
    headline: string;
    showFileNameForm: boolean;
    fileName?: string;
}
@Component({
    selector: 'app-sort-files-dialog',
    templateUrl: './sort-files-dialog.component.html',
    styleUrls: ['./sort-files-dialog.component.scss'],
    standalone: true,
    imports: [CommonModule, DragDropModule, MatDialogModule, AppButtonModule, FileCardComponent, SmallFileCardModule, MatInputModule, FontAwesomeModule, ReactiveFormsModule, FilenameInputComponent],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortFilesDialogComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
    };

    public documentsSorted: FileEntity[];

    private previewDialogRef: MatDialogRef<DaveFilePreviewComponent>;
    @Input() headline: string;
    @Input() showFileNameForm = false;
    @Input() set fileName(v: string) {
        if ((v || null) !== this.fileNameForm.value) {
            this.fileNameForm.setValue(v || null)
        }
    }
    get fileName() {
        return this.fileNameForm.value;
    }
    @Input() set documentIds(ids: number[]) {
        if (ids?.length) {
            firstValueFrom(this.fileDataService.GetFilesById(ids)).then((files) => (this.documentsSorted = files));
        } else {
            this.documentsSorted = [];
        }
    }
    @Output() submitClick = new EventEmitter<void>();

    protected fileNameForm = new FormControl<string>('')
    constructor(@Inject(DIALOG_DATA) data: SortFilesDialogComponentDialogData, private fileDataService: FileDataService, private dialog: MatDialog, protected ls: LoadingService) {
        if (data) {
            this.headline = data.headline;
            this.documentIds = data.documentIds;
            this.showFileNameForm = data.showFileNameForm;
            this.fileName = data.fileName;
        }
    }

    drop(event: CdkDragDrop<FileEntity[]>): void {
        if (event.previousContainer === event.container) {
            console.log(event.previousIndex, event.currentIndex);
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    onDocumentClick(fileId: number) {
        if (this.previewDialogRef && this.previewDialogRef.getState() !== MatDialogState.CLOSED) {
            this.previewDialogRef.close();
        }
        this.previewDialogRef = this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: { fileId },
        });
    }

    protected readonly GetFormErrorMessage = getErrorMessage;
}
