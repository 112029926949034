import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialog, MatDialogConfig, MatDialogModule, MAT_DIALOG_DATA, _closeDialogVia, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, map, merge, Observable, Subscription, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, shareReplay, skip, tap } from 'rxjs/operators';
import { NewCustomerDialogComponent, NewCustomerDialogComponentDialogData, NewCustomerDialogComponentDialogReturnData } from '../../../../components/new-view/new-customer/new-customer-dialog/new-customer-dialog.component';
import { newCustomerDefaultValues } from '../../../../components/new-view/new-customer/new-customer-main/new-customer-main.component';
import { Person2EntityEntityTypeEnum } from '../../../../dave-data-module/entities/person2entity.entity';
import { CustomerTypeResolver } from '../../../../dave-data-module/guards/customer-type.resolver';
import { CustomerResolver } from '../../../../dave-data-module/guards/customer.resolver';
import { OfficeResolver } from '../../../../dave-data-module/guards/office.resolver';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { Person2EntityActionTypes } from '../../../../dave-data-module/State/actions/person2entity.action';
import { getCustomerDictionary, getCustomers, getCustomersFetched, getCustomerTypeFetched, getCustomerTypes } from '../../../../dave-data-module/State/selectors/customers.selectors';
import { getPerson2Entities } from '../../../../dave-data-module/State/selectors/person2entity.selectors';
import { DaveDoubleIconModule } from '../../../../dave-double-icon/dave-double-icon.module';
import { DaveLoadingModule } from '../../../../dave-loading/dave-loading.module';
import { AppButtonModule } from '../../../../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../../../../dave-utils-module/app-filter-module/app-filter.module';
import { FilterOption, FILTER_TYPE_MULTI_SELECT } from '../../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { CommissionNameService } from '../../../../dave-utils-module/dave-shared-components-module/services/commission-name.service';
import { CustomerNameService } from '../../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { isNotNullOrUndefined, SearchQueriesDebounceTime, stringSearch } from '../../../../helper/helper';
import { CustomerAdministrationMeta } from '../../../../helper/page-metadata';
import { CustomLabelService } from '../../../../services/custom-label.service';
import { DefaultFilterService, FilterApps } from '../../../../services/default-filter.service';
import { LoadingService } from '../../../../services/loading.service';
import { DaveSelectUserModule } from '../../../dave-select-user.module';
import { ISelectedElement, ITableConfig, TableContentType } from '../../multi-select-table/multi-select-table.component';
import { SelectUserDialogBase } from '../select-user-dialog-base';
import { Router } from '@angular/router';

export interface SelectCustomerDialogComponentDialogData {
    personId: number;
    newCustomerDefaultValues?: newCustomerDefaultValues;
    preventNewPersonDialogWhileCreateCustomer?: boolean;
}
interface ITableData {
    customerId: number;
    selectedForm: FormControl<boolean>;
    specificationTypeId: number;
    name: TableContentType<any> & { __typename: 'string' };
    number: TableContentType<any> & { __typename: 'string' };
}
interface SelectedCustomerType extends ISelectedElement {
    customerId: number;
    personToEntityIds: number[];
}
enum filterNames {
    customerType = 'customerType',
}
interface IFilter {
    [filterNames.customerType]: string[];
}
@Component({
    selector: 'app-select-customer-dialog',
    templateUrl: './select-customer-dialog.component.html',
    styleUrls: ['./select-customer-dialog.component.scss'],
    standalone: true,
    imports: [CommonModule, MatDialogModule, AppButtonModule, DaveSelectUserModule, DaveDoubleIconModule, MatBadgeModule, MatTooltipModule, FontAwesomeModule, AppFilterModule, DaveLoadingModule],
})
export class SelectCustomerDialogComponent implements OnInit, OnDestroy {
    public static readonly DefaultConfig: MatDialogConfig = SelectUserDialogBase.DefaultConfig;
    public FilterSettings$: Observable<FilterOption[]> = this.store.select(getCustomerTypeFetched).pipe(
        filter((f) => f),
        switchMap(() => this.store.select(getCustomerTypes)),
        map((customerTypes) => {
            const ctValues = {};
            customerTypes.forEach((c) => {
                ctValues[c.Id.toString()] = c.Name;
            });
            return [
                {
                    Name: filterNames.customerType,
                    Type: FILTER_TYPE_MULTI_SELECT,
                    Label: 'Kundenart',
                    Values: ctValues,
                },
            ];
        }),
    );
    public FilterValues$: BehaviorSubject<IFilter> = new BehaviorSubject({
        [filterNames.customerType]: [],
    });
    public FilterAmount$ = this.FilterValues$.pipe(map((v) => (v[filterNames.customerType]?.length ? 1 : 0)));
    public SearchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

    SelectedCustomers$: Observable<SelectedCustomerType[]>;

    TableDataConfig: ITableConfig[] = [
        {
            header: 'Kundennummer',
            id: 'number',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
        {
            header: 'Name',
            id: 'name',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
    ];
    FilteredTableData$: Observable<ITableData[]>;
    TableData$: Observable<ITableData[]>;

    private subscriptions: Subscription[] = [];
    public ContentResolved$: Observable<boolean>;
    protected dirty = false;
    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA) public Dialogdata: SelectCustomerDialogComponentDialogData,
        private actions$: Actions,
        public LS: LoadingService,
        private defaultFilter: DefaultFilterService,
        private dialog: MatDialog,
        public CS: CustomerNameService,
        public CNS: CommissionNameService,
        customerRes: CustomerResolver,
        customerTypeRes: CustomerTypeResolver,
        protected officeRes: OfficeResolver,
        protected cls: CustomLabelService,
        private router: Router,
        private dialogRef: MatDialogRef<SelectCustomerDialogComponent>,
    ) {
        this.ContentResolved$ = combineLatest([
            this.store.select(getCustomersFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        customerRes.resolve();
                    }
                }),
            ),
            this.store.select(getCustomerTypeFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        customerTypeRes.resolve();
                    }
                }),
            ),
        ]).pipe(
            map((values) => values.every((v) => v)),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        this.SelectedCustomers$ = this.store.select(getPerson2Entities).pipe(
            map((entities) => entities.filter((e) => e.PersonId === this.Dialogdata.personId && e.EntityType === Person2EntityEntityTypeEnum.Customer)),
            switchMap((p2es) =>
                this.store.select(getCustomerDictionary).pipe(
                    map((customers) => {
                        const m = new Map<number, number[]>();
                        p2es.forEach((p2e) => {
                            if (m.has(p2e.EntityId)) {
                                m.get(p2e.EntityId).push(p2e.Id);
                            } else {
                                m.set(p2e.EntityId, [p2e.Id]);
                            }
                        });
                        return Array.from(m.entries()).map(([customerId, personToEntityIds]) => ({ customerId, personToEntityIds, label: customers[customerId]?.DisplayName }));
                    }),
                ),
            ),
        );
        // this.store.select(getCustomerDictionary).pipe(map((customers) => (this.Dialogdata.selectedCustomers || []).map((id) => ({ label: customers[id]?.DisplayName, customerId: id }))));
        this.TableData$ = combineLatest([
            this.store.select(getCustomerTypeFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getCustomerTypes)),
            ),
            this.store.select(getCustomersFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getCustomers)),
                map((customers) => customers.filter((c) => !c.Deleted)),
            ),
        ]).pipe(
            map(([types, customers]) => {
                return customers.map<ITableData>((c) => {
                    // const type = c.CustomerTypeIds && types.find((ct) => ct.Id === c.CommissionTypeId);
                    return {
                        customerId: c.Id,
                        specificationTypeId: c.CustomerSpecificationTypeId,
                        selectedForm: new FormControl<boolean>(false),
                        name: {
                            __typename: 'string',
                            content: c.DisplayName,
                        },
                        number: {
                            __typename: 'string',
                            content: c.CustomerNo,
                        },
                    };
                });
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.FilteredTableData$ = this.TableData$.pipe(
            switchMap((rows) =>
                this.FilterValues$.pipe(map((filter) => (filter[filterNames.customerType]?.length ? rows.filter((row) => row.specificationTypeId && filter[filterNames.customerType].includes(row.specificationTypeId.toString())) : rows))),
            ),
            switchMap((rows) => {
                return this.SearchString.pipe(
                    debounceTime(SearchQueriesDebounceTime),
                    map((searchString) => (!searchString && rows) || rows.filter((r) => [r.name.content, r.number.content].filter(isNotNullOrUndefined).some((s) => stringSearch(s, searchString)))),
                );
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }
    ngOnInit(): void {
        firstValueFrom(this.defaultFilter.GetFilterByApp$(FilterApps.SelectCommissionPopup)).then((filterValues) => {
            let temp = {
                [filterNames.customerType]: isNotNullOrUndefined(filterValues[filterNames.customerType]) ? filterValues[filterNames.customerType] : [],
            };
            this.FilterValues$.next(temp);
        });

        this.subscriptions.push(
            this.FilterValues$.pipe(skip(2)).subscribe((val) => {
                if (Object.keys(val).length !== 0) {
                    this.defaultFilter.SetFilterByApp(FilterApps.SelectCommissionPopup, val);
                }
            }),
            this.TableData$.pipe(
                // tap(() => this.dirty = false),
                switchMap((td) => merge(...td.map((t) => t.selectedForm.valueChanges.pipe(map(() => t.selectedForm.dirty))))),
            ).subscribe((v) => {
                if (v) {
                    this.dirty = true;
                }
            }),
            this.TableData$.pipe(
                switchMap((data) =>
                    this.SelectedCustomers$.pipe(
                        tap((selectedCustomers) =>
                            data.forEach((d) => {
                                const isSelected = selectedCustomers.some((u) => u.customerId === d.customerId);

                                if (isSelected && (d.selectedForm.enabled || !d.selectedForm.value)) {
                                    d.selectedForm.setValue(true, { emitEvent: false });
                                    d.selectedForm.disable();
                                } else if (!isSelected && (d.selectedForm.disabled || d.selectedForm.value)) {
                                    d.selectedForm.setValue(false, { emitEvent: false });
                                    d.selectedForm.enable();
                                }
                            }),
                        ),
                    ),
                ),
            ).subscribe(),
        );
    }
    RouteToCustomer(entity: SelectedCustomerType) {
        this.router.navigate(['/', CustomerAdministrationMeta.Path, entity.customerId]);
    }
    RemoveSelected(entity: SelectedCustomerType) {
        this.LS.startLoading('remove-selected-customer');
        firstValueFrom(this.actions$.pipe(ofType(Person2EntityActionTypes.RemoveOne, BaseActionTypes.ErrorAction), skip(entity.personToEntityIds.length - 1))).then(() => {
            this.LS.endLoading('remove-selected-customer');
        });
        entity.personToEntityIds.forEach((id) => {
            this.store.dispatch(Person2EntityActionTypes.DeletePerson2Entity({ Payload: { id } }));
        });
    }
    Submit() {
        this.LS.startLoading('set-person-to-customer');
        firstValueFrom(combineLatest([this.TableData$, this.SelectedCustomers$])).then(([data, selected]) => {
            const toAdd = data.filter((d) => d.selectedForm.value && selected.every((s) => s.customerId !== d.customerId));
            if (!toAdd.length) {
                this.dirty = false;
                this.LS.endLoading('set-person-to-customer');
                return;
            }
            firstValueFrom(this.actions$.pipe(ofType(Person2EntityActionTypes.UpdateMany, BaseActionTypes.ErrorAction), skip(toAdd.length - 1))).then(() => {
                this.dirty = false;
                this.LS.endLoading('set-person-to-customer');
            });
            toAdd.forEach(({ customerId }) =>
                this.store.dispatch(
                    Person2EntityActionTypes.AddPerson2Entity({
                        Payload: {
                            personId: this.Dialogdata.personId,
                            entityType: Person2EntityEntityTypeEnum.Customer,
                            entityId: customerId,
                        },
                    }),
                ),
            );
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    newCommissionClick() {
        firstValueFrom(
            this.dialog
                .open<NewCustomerDialogComponent, NewCustomerDialogComponentDialogData, NewCustomerDialogComponentDialogReturnData>(NewCustomerDialogComponent, {
                    ...NewCustomerDialogComponent.DefaultConfig,
                    data: {
                        defaultValues: this.Dialogdata?.newCustomerDefaultValues || null,
                        preventNewPersonDialog: this.Dialogdata?.preventNewPersonDialogWhileCreateCustomer || false,
                    },
                })
                .afterClosed(),
        ).then((customerId) => {
            if (customerId) {
                this.store.dispatch(
                    Person2EntityActionTypes.AddPerson2Entity({
                        Payload: {
                            personId: this.Dialogdata.personId,
                            entityType: Person2EntityEntityTypeEnum.Customer,
                            entityId: customerId,
                        },
                    }),
                );
            }
        });
    }
    closeDialog() {
        if (this.dialogRef) {
                    this.dialogRef.close();
            };
        }
    protected readonly CustomerAdministrationMeta = CustomerAdministrationMeta;
}
