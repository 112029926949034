import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { ResolverLoadBookmarks, ResolverReLoadBookmarks } from '../State/actions/resolver.actions';
import { getBookmarkFetched } from '../State/selectors/bookmarks.selectors';
import { BaseEntityReloadResolver } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class BookmarkResolver extends BaseEntityReloadResolver {
    constructor(store: Store<State>) {
        super(getBookmarkFetched, new ResolverLoadBookmarks(), new ResolverReLoadBookmarks(), store);
    }
}
