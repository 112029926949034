import { createAction, props } from '@ngrx/store';
import { ComplaintTypeEntity } from '../../entities/complaintType.entity';


export const ComplaintTypeActionTypes = {
    UpdateComplaintType: createAction(
        'data/ComplaintType/update',
        props<{ Payload: Array<ComplaintTypeEntity> }>(),
    ),
    GetComplaintTypeRequest: createAction(
        'data/ComplaintType/getAll',
    ),
};

