<div class="headline" *ngIf="ShowHeadline">
    <h2>Geräte hinzufügen</h2>
</div>
<mat-form-field appearance="fill">
    <mat-label>Zeitraum</mat-label>
    <mat-date-range-input [formGroup]="TimespanForm" [rangePicker]="timespanPicker">
        <input matStartDate placeholder="Von" formControlName="from" />
        <input matEndDate placeholder="Bis" formControlName="to" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="timespanPicker"></mat-datepicker-toggle>
    <mat-date-range-picker #timespanPicker></mat-date-range-picker>
</mat-form-field>
<h3 *ngIf="(Selected$ | async)?.length !== 0">ausgewählte Geräte:</h3>
<mat-chip-list *ngIf="(Selected$ | async)?.length !== 0">
    <mat-chip *ngFor="let entry of Selected$ | async">
        {{ entry.label }}
        <button (click)="RemoveSelected(entry.id)">
            <fa-icon [icon]="'times-circle'"></fa-icon>
        </button>
    </mat-chip>
</mat-chip-list>
<h3>weitere Geräte auswählen:</h3>
<input type="text" class="search-field" placeholder="nach Geräten suchen" #searchString (keyup)="DoSearch(searchString.value)" />

<cdk-virtual-scroll-viewport [tvsItemSize]="56" [headerHeight]="56" style="height: 20rem; overflow-y: scroll" [bufferMultiplier]="2">
    <table mat-table matSort [dataSource]="DataSource$ | async" *ngIf="Columns$ | async as PersonColumns">
        <ng-container *ngFor="let collumName of PersonAutoColumns" [matColumnDef]="collumName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                {{ PersonColumnHeaders ? PersonColumnHeaders[collumName] : '' }}
            </th>
            <td mat-cell *matCellDef="let rowData">
                {{ rowData[collumName] }}
            </td>
        </ng-container>
        <ng-container matColumnDef="available">
            <th mat-header-cell *matHeaderCellDef>Verfügbar</th>
            <td mat-cell *matCellDef="let rowData">
                {{ rowData.Available$ | async }}
            </td>
        </ng-container>
        <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef style="padding: 0 1rem; width: 1rem">
                <fa-icon icon="check-square"></fa-icon>
            </th>
            <td mat-cell *matCellDef="let rowData" style="padding: 0 1rem; width: 1rem">
                <mat-checkbox [formControl]="rowData.CheckboxForm" color="primary"> </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="formControl">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let rowData" class="form-cell">
                <mat-form-field>
                    <mat-label>Zeitraum</mat-label>
                    <mat-date-range-input [formGroup]="rowData.TimespanForm" [rangePicker]="timespanPicker">
                        <input matStartDate placeholder="Von" formControlName="from" />
                        <input matEndDate placeholder="Bis" formControlName="to" />
                    </mat-date-range-input>
                    <!--                        <mat-hint>TT.MM.JJJJ – TT.MM.JJJJ</mat-hint>-->
                    <mat-datepicker-toggle matSuffix [for]="timespanPicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #timespanPicker></mat-date-range-picker>
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        type="number"
                        min="0"
                        placeholder="Anzahl"
                        [formControl]="rowData.AmountForm"
                        (input)="rowData.CheckboxForm.value !== !!$event.target.value ? rowData.CheckboxForm.setValue(!!$event.target.value) : null"
                    />
                </mat-form-field>
                <!--                    <button app-round-button [Disabled]="!!rowData.AmountForm.invalid || !rowData.AmountForm.value" (click)="SaveButtonClick(rowData)"><fa-icon icon="save"></fa-icon></button>-->
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="PersonColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let rowData; columns: PersonColumns"></tr>
    </table>

    <table *ngIf="(DataSource$ | async)?.data?.length === 0" style="width: 100%">
        <tr>
            <td class="empty-table-text">Keine Geräte vorhanden</td>
        </tr>
    </table>
</cdk-virtual-scroll-viewport>
