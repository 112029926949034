<!--<fieldset [formGroup]="form">-->
<!--    <legend>{{ legend }}</legend>-->

    <mat-form-field *ngIf="SearchAddressOptions?.length > 0">
        <app-select-search placeholder="Adressen durchsuchen" (blur)="onTouched()" (selectionChange)="selectionChange($event)" [Options]="SearchAddressOptions" [CompareOptions]="compareOptions" [SearchFunction]="searchFunction"></app-select-search>
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Straße" [formControl]="form.controls.Street" (blur)="onTouched()" />
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Postleitzahl" [formControl]="form.controls.PostalCode" (blur)="onTouched()" />
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Ort" [formControl]="form.controls.City" (blur)="onTouched()" />
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="Land" [formControl]="form.controls.Country" (blur)="onTouched()" />
    </mat-form-field>
<!--</fieldset>-->
