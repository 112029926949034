import {LicensePackageType} from '../graphql-types';

export class LicensePackageEntity {
    constructor(
        public Id: number = null,
        public MaxUserTypeId: number = null,
        public MaxUserTypeName: string = null,
        public ProductName: string = null,
        public MaxUser: number = null,
        public MinUser: number = null,
        public PricePerUser: number = null,
        public BasePrice: number = null,
        public CanBeBookedByUser: boolean = null
    ) {}

    public Clone(override: LicensePackageEntityAttributes = {}): LicensePackageEntity {
        const attr = {...this, ...override};
        return Object.assign(new LicensePackageEntity(), attr);
    }
}

export function  LicensePackageEntityFromBackend(res: LicensePackageType): LicensePackageEntity {
    return new LicensePackageEntity(
        res.id,
        res.maxUserTypeId,
        res.maxUserTypeName,
        res.productName,
        res.maxUser,
        res.minUser,
        res.pricePerUser,
        res.basePrice,
        res.canBeBookedByUser
    );
}

export interface LicensePackageEntityAttributes {
    Id?: number;
    MaxUserTypeId?: number;
    MaxUserTypeName?: string;
    ProductName?: string;
    MaxUser?: number;
    MinUser?: number;
    PricePerUser?: number;
    BasePrice?: number;
    CanBeBookedByUser?: boolean;
}
