import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, concatMap, map, withLatestFrom} from 'rxjs/operators';
import {DaveActions} from '../actions/actions';
import {ConditionActionTypes} from '../actions/condition.actions';
import { ConditionEntity, ConditionEntityFromBackend } from '../../entities/condition.entity';
import {HttpService} from '../../services/http.service';
import {State} from '../index';
import {BaseActionTypes} from '../actions/base.actions';

enum ErrorCodes {
    Load = 'Kondition Abrufen fehlgeschlagen',
    Modify = 'Kondition Bearbeiten fehlgeschlagen',
    Remove = 'Kondition Löschen fehlgeschlagen',
    Add = 'Kondition Hinzufügen fehlgeschlagen',
}

@Injectable()
export class ConditionEffects {

    GetConditionEmployee$ = createEffect(() => this.actions$.pipe(
        ofType(ConditionActionTypes.GetConditionRequest),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
query{
  condition{
  ${ConditionEntity.GqlFields}
  }
}
`;

            return this.gatewayHttpService
                .graphQl({query: queryString}, {token: store.base.token, retry: true })
                .pipe(
                    map(res => {
                        return res && res.condition
                            ? ConditionActionTypes.GetCondition({
                                Payload: {
                                    Conditions: res.condition.map(c => ConditionEntityFromBackend(c))
                                }
                            })
                            : BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Load}});
                    }),
                    catchError((err, caught) => of(BaseActionTypes.ErrorAction({
  Payload: {
     ToasterMessage: ErrorCodes.Load,
     Err: err,
     Caught: caught,
  }
})))
                );
        })
    ));

    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService
    ) {
    }
}
