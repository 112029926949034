import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EmailEntity } from '../../../../dave-data-module/entities/email.entity';
import { EmailFolderEntityType } from '../../../../dave-data-module/entities/emailFolder.entity';
import { EventEntity } from '../../../../dave-data-module/entities/event.entity';
import { EmailFolderResolver } from '../../../../dave-data-module/guards/email-folder.resolver';
import { FrontendDate } from '../../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../../dave-data-module/State';
import { EventsActionTypes } from '../../../../dave-data-module/State/actions/events.actions';
import { getEmailFolders } from '../../../../dave-data-module/State/selectors/email-folders.selectors';
import { PermissionService } from '../../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { isNotNullOrUndefined, linkify } from '../../../../helper/helper';
import { TaskPageMeta } from '../../../../helper/page-metadata';

export interface EmailDialogData {
    event: EventEntity;
    email: EmailEntity;
}

@Component({
    selector: 'app-email-dialog',
    templateUrl: './email-dialog.component.html',
    styleUrls: ['./email-dialog.component.scss'],
})
export class EmailDialogComponent implements OnInit {
    @ViewChild('tabBodyWrapper') tabBodyWrapper!: ElementRef;
    public EditingDescription = false;
    public Description = new UntypedFormControl('', { updateOn: 'blur' });
    public static DefaultConfig: MatDialogConfig = {
        width: '80vw',
        height: '80vh',
        panelClass: ['custom-dialog-class-mobile-fullscreen', 'custom-dialog-class-small-fullscreen', 'mat-dialog-with-close-button'],
        autoFocus: false,
    };
    public Mobile$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(map((result) => result.matches));

    // public IsDraft = false;

    constructor(
        protected dialogRef: MatDialogRef<EmailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public Data: EmailDialogData,
        public PS: PermissionService,
        private store: Store<State>,
        // private cdRef: ChangeDetectorRef,
        // private emailFolderResolver: EmailFolderResolver,
        protected router: Router,
        private breakpointObserver: BreakpointObserver,
    ) {}
    ngOnInit(): void {
        // this.emailFolderResolver.resolve();
        // firstValueFrom(this.store.select(getEmailFolders).pipe(filter(isNotNullOrUndefined))).then((emailFolders) => {
        //     let emailFolder = emailFolders.find((ef) => ef.Id == this.Data.email.FolderId);
        //     this.IsDraft = isNotNullOrUndefined(emailFolder) && emailFolder.Type.toLowerCase() == EmailFolderEntityType.Drafts.toLowerCase();
        //     this.cdRef.detectChanges();
        // });

        this.Description.setValue(this.Data.event.Description);
        if (this.Data.event?.Id) {
            this.store.dispatch(EventsActionTypes.SetEventSeen({ Payload: { eventIds: [this.Data.event.Id] } }));
        }
    }

    CloseEdit(safe: boolean): void {
        if (safe) {
            this.store.dispatch(
                EventsActionTypes.ModifyEvent({
                    Payload: {
                        id: this.Data.event.Id,
                        personId: null,
                        customerId: null,
                        hint: null,
                        commissionId: null,
                        eventTypeId: this.Data.event.EventTypeId,
                        eventDate: FrontendDate(this.Data.event.EventDate),
                        eventEndDate: isNotNullOrUndefined(this.Data.event.EventEndDate) ? FrontendDate(this.Data.event.EventEndDate) : null,
                        description: this.Description.value,
                        private: this.Data.event.Private,
                    },
                }),
            );
        } else {
            this.Description.setValue(this.Data.event.Description);
        }
        this.EditingDescription = false;
    }

    public Linkify(text: string) {
        return linkify(text);
    }

    protected readonly TaskPageMeta = TaskPageMeta;
}
