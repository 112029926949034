import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, Router, UrlTree} from '@angular/router';
import {Store} from '@ngrx/store';
import {State} from '../State';
import {getToken} from '../State/selectors/base.selectors';

@Injectable({
    providedIn: 'root'
})
export class ValidTokenGuard implements CanActivate, CanActivateChild {
    constructor(private store: Store<State>, private router: Router) {
    }

    private isTokenValid() {
        return new Promise<boolean | UrlTree>((resolve, reject) => {
            let failed = false;
            const sub = this.store
                .select(getToken)
                .subscribe(t => {
                    if (t !== '' && t !== null) {
                        failed = false;
                        resolve(true);
                    } else {
                        failed = true;
                    }
                });

            // token not inited from appmodule on init
            setTimeout(() => {
                sub.unsubscribe();
                if (failed) {
                    resolve(this.router.createUrlTree(['/login']));
                }
            }, 1000);
        });
    }

    canActivate() {
        return this.isTokenValid();
    }

    canActivateChild() {
        return this.isTokenValid();
    }
}
