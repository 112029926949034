import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

export interface DaveLoadingPopupComponentDialogData {
    onReady$: Subject<{ text: string; state: 'success' | 'fail' }>;
    text?: string;
    title?: string;
}

@Component({
    selector: 'app-dave-loading-popup',
    templateUrl: './dave-loading-popup.component.html',
    styleUrls: ['./dave-loading-popup.component.scss'],
})
export class DaveLoadingPopupComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public Data: DaveLoadingPopupComponentDialogData) {}
}
