import { createAction, props } from '@ngrx/store';
import { TagEntity } from '../../entities/tag.entity';
import { DaveMutationCreateTagArgs, DaveMutationDeleteTagArgs } from '../../graphql-types';

export const TagActionTypes = {
  UpdateTags: createAction(
    'data/tags/update',
    props<{ Payload: Array<TagEntity> }>(),
  ),
  AddTag: createAction(
    'data/tags/add',
    props<{ Payload: DaveMutationCreateTagArgs }>(),
  ),
  DeleteTag: createAction(
    'data/tags/delete',
    props<{ Payload: DaveMutationDeleteTagArgs }>(),
  ),
};
