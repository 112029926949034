import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../dave-data-module/State';
import { MatDialog } from '@angular/material/dialog';
import { ChartOfAccountResolver } from '../../dave-data-module/guards/chartOfAccount.resolver';
import { ChartOfAccountEntity } from '../../dave-data-module/entities/chart-of-Account.entity';

@Component({
  selector: 'app-edit-gegenkonto',
  templateUrl: './edit-gegenkonto.component.html',
  styleUrls: ['./edit-gegenkonto.component.scss']
})
export class EditGegenkontoComponent  {

    @Input()  public ChartOfAccount: ChartOfAccountEntity;
    @Output() editEvent = new EventEmitter<ChartOfAccountEntity>();

    constructor() {}

    onEditClick( event: ChartOfAccountEntity ) {
        this.editEvent.emit(event);
    }

}
