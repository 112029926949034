import { Component,Inject,OnDestroy,OnInit } from '@angular/core';
import { FormControl,Validators } from '@angular/forms';
import { MatDialog,MatDialogConfig,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject,combineLatest,firstValueFrom,map,Observable,of,Subscription,switchMap } from 'rxjs';
import { debounceTime,distinctUntilChanged,filter,shareReplay,skip,startWith,tap } from 'rxjs/operators';
import { GroupMemberEntity } from '../../../../dave-data-module/entities/group-member.entity';
import { GroupEntity } from '../../../../dave-data-module/entities/group.entity';
import { Person2EntityEntityTypeEnum } from '../../../../dave-data-module/entities/person2entity.entity';
import { EmployeeResolver } from '../../../../dave-data-module/guards/employee.resolver';
import { GroupResolver } from '../../../../dave-data-module/guards/group.resolver';
import { PersonResolver } from '../../../../dave-data-module/guards/person.resolver';
import { Person2EntityResolver } from '../../../../dave-data-module/guards/person2Entity.resolver';
import { UserToCommissionResolver } from '../../../../dave-data-module/guards/user-to-commission.resolver';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { GroupActionTypes,GroupMemberActionTypes } from '../../../../dave-data-module/State/actions/group.actions';
import { getEmployeeDictionary,getEmployees,getEmployeesFetched } from '../../../../dave-data-module/State/selectors/employees.selectors';
import { getGroupById,getGroupsFetched } from '../../../../dave-data-module/State/selectors/group.selectors';
import { getPersonDictionary,getPersons,getPersonsFetched } from '../../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities,getPerson2EntitiesFetched } from '../../../../dave-data-module/State/selectors/person2entity.selectors';
import { getUserToCommissionFetched,getUserToCommissions } from '../../../../dave-data-module/State/selectors/user-to-commission.selector';
import { AppDialogService } from '../../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { FilterOption,FILTER_TYPE_BOOLEAN } from '../../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { DetailListDialogReturn,DetailListTemplateDialogComponent,DetailListTemplateDialogData } from '../../../../detail-list-template-dialog/components/detail-list-template-dialog.component';
import { isNotNullOrUndefined,SearchQueriesDebounceTime,stringSearch,uniqArray } from '../../../../helper/helper';
import { DefaultFilterService,FilterApps } from '../../../../services/default-filter.service';
import { LoadingService } from '../../../../services/loading.service';
import { ISelectedElement,ITableConfig,TableContentType } from '../../multi-select-table/multi-select-table.component';
import { SelectUserDialogBase } from '../select-user-dialog-base';

export interface SelectGroupMemberDialogComponentDialogData {
    groupId: number;
}
enum entityType {
    employee,
    person,
}
interface ITableData {
    entityId: number;
    entityType: entityType;
    name: TableContentType<any> & { __typename: 'string' };

    selectedForm: FormControl<boolean>;
    isFromCommission: boolean;
}
interface SelectedUserType extends ISelectedElement {
    groupMember: GroupMemberEntity;
}
enum filterNames {
    isPerson = 'isPerson',
    isEmployee = 'isEmployee',
    onlyFromCommission = 'onlyFromCommission',
}
interface IFilter {
    [filterNames.isPerson]: boolean;
    [filterNames.isEmployee]: boolean;
    [filterNames.onlyFromCommission]: boolean;
}
@Component({
    selector: 'app-select-group-member-dialog',
    templateUrl: './select-group-member-dialog.component.html',
    styleUrls: ['./select-group-member-dialog.component.scss'],
})
export class SelectGroupMemberDialogComponent implements OnInit, OnDestroy {
    public static readonly DefaultConfig: MatDialogConfig = SelectUserDialogBase.DefaultConfig;
    public FilterSettings: FilterOption[] = [
        {
            Name: filterNames.isPerson,
            Type: FILTER_TYPE_BOOLEAN,
            Label: 'Kontakte',
        },
        {
            Name: filterNames.isEmployee,
            Type: FILTER_TYPE_BOOLEAN,
            Label: 'Mitarbeiter',
        },
        {
            Name: filterNames.onlyFromCommission,
            Type: FILTER_TYPE_BOOLEAN,
            Label: 'nur zugewiesene',
        },
    ];
    public FilterValues$: BehaviorSubject<IFilter> = new BehaviorSubject({
        [filterNames.isPerson]: true,
        [filterNames.isEmployee]: true,
        [filterNames.onlyFromCommission]: true,
    });
    public FilterAmount$ = of(0); // todo
    public SearchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

    SelectedMembers$: Observable<SelectedUserType[]>;

    TableDataConfig: ITableConfig[] = [
        {
            header: 'Bezeichnung',
            id: 'name',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
    ];
    TableData$: Observable<ITableData[]>;
    FilteredTableData$: Observable<ITableData[]>;

    private subscriptions: Subscription[] = [];
    public ContentResolved$: Observable<boolean>;
    public Group$: Observable<GroupEntity>;
    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA) public Dialogdata: SelectGroupMemberDialogComponentDialogData,
        private actions$: Actions,
        public LS: LoadingService,
        private defaultFilter: DefaultFilterService,
        private appDialog: AppDialogService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<SelectGroupMemberDialogComponent>,
        groupRes: GroupResolver,
        personRes: PersonResolver,
        employeeRes: EmployeeResolver,
        userToCommissionRes: UserToCommissionResolver,
        person2EntitiesRes: Person2EntityResolver,
    ) {
        this.ContentResolved$ = combineLatest([
            this.store.select(getGroupsFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        groupRes.resolve();
                    }
                }),
            ),
            this.store.select(getPersonsFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        personRes.resolve();
                    }
                }),
            ),
            this.store.select(getEmployeesFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        employeeRes.resolve();
                    }
                }),
            ),
            this.store.select(getUserToCommissionFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        userToCommissionRes.resolve();
                    }
                }),
            ),
            this.store.select(getPerson2EntitiesFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        person2EntitiesRes.resolve();
                    }
                }),
            ),
        ]).pipe(
            map((values) => values.every((v) => v)),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.Group$ = this.store.select(getGroupsFetched).pipe(
            filter((v) => !!v),
            switchMap(() => this.store.select(getGroupById({ id: this.Dialogdata.groupId }))),
            shareReplay({ refCount: true, bufferSize: 1 }),
            filter(isNotNullOrUndefined), // für den Zeitpunkt an dem die gruppe gelöscht wurde, das PopUp aber noch offen ist
        );
        this.SelectedMembers$ = combineLatest([
            this.Group$,
            this.store.select(getEmployeesFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getEmployeeDictionary)),
            ),
            this.store.select(getPersonsFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getPersonDictionary)),
            ),
        ]).pipe(
            map(([group, Employees, Persons]) => group.GroupMembers.map((member) => ({ label: member.EmployeeId ? Employees[member.EmployeeId]?.DisplayName : Persons[member.PersonId]?.DisplayName, groupMember: member }))),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.TableData$ = combineLatest([
            this.store.select(getEmployeesFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getEmployees)),
            ),
            this.store.select(getPersonsFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getPersons)),
            ),
            this.Group$.pipe(
                filter(isNotNullOrUndefined),
                map((g) => g.CommissionId),
                distinctUntilChanged(),
                switchMap((commissionId) =>
                    combineLatest([
                        this.store.select(getUserToCommissionFetched).pipe(
                            filter((v) => v),
                            switchMap(() => this.store.select(getUserToCommissions)),
                            map((u2c) => uniqArray(u2c.filter((u) => u.CommissionId === commissionId).map((u) => u.UserId))),
                        ),
                        this.store.select(getPerson2EntitiesFetched).pipe(
                            filter((v) => v),
                            switchMap(() => this.store.select(getPerson2Entities)),
                            map((p2e) => uniqArray(p2e.filter((p) => p.EntityId === commissionId && p.EntityType === Person2EntityEntityTypeEnum.Commission).map((p) => p.PersonId))),
                        ),
                    ]),
                ),
            ),
        ]).pipe(
            map(([employees, persons, [userIdsByCommission, personIdsByCommission]]) => {
                return [
                    ...employees.map<ITableData>((e) => {
                        return {
                            name: {
                                __typename: 'string',
                                content: e.DisplayName,
                            },
                            entityId: e.Id,
                            entityType: entityType.employee,
                            selectedForm: new FormControl<boolean>(false),
                            isFromCommission: e.UserId && userIdsByCommission.includes(e.UserId),
                        };
                    }),
                    ...persons.map<ITableData>((p) => {
                        return {
                            name: {
                                __typename: 'string',
                                content: p.DisplayName,
                            },
                            entityId: p.Id,
                            entityType: entityType.person,
                            selectedForm: new FormControl<boolean>(false),
                            isFromCommission: personIdsByCommission.includes(p.Id),
                        };
                    }),
                ];
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.FilteredTableData$ = this.TableData$.pipe(
            switchMap((rows) =>
                this.FilterValues$.pipe(
                    map((filter) =>
                        rows.filter(
                            (row) =>
                                ((row.entityType === entityType.person && filter[filterNames.isPerson]) || (row.entityType === entityType.employee && filter[filterNames.isEmployee])) &&
                                (!filter[filterNames.onlyFromCommission] || row.isFromCommission),
                        ),
                    ),
                ),
            ),
            switchMap((rows) => {
                    return this.SearchString.pipe(
                        debounceTime(SearchQueriesDebounceTime),
                        map((searchString) => (!searchString && rows) || rows.filter((r) => [r.name.content].filter(isNotNullOrUndefined).some((s) => stringSearch(s, searchString)))),
                    )
                },
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        )
    }
    ngOnInit(): void {
        firstValueFrom(this.defaultFilter.GetFilterByApp$(FilterApps.SelectGroupMemberPopup)).then((filterValues) => {
            let temp = {
                [filterNames.isPerson]: isNotNullOrUndefined(filterValues[filterNames.isPerson]) ? filterValues[filterNames.isPerson] : true,
                [filterNames.isEmployee]: isNotNullOrUndefined(filterValues[filterNames.isEmployee]) ? filterValues[filterNames.isEmployee] : true,
                [filterNames.onlyFromCommission]: isNotNullOrUndefined(filterValues[filterNames.onlyFromCommission]) ? filterValues[filterNames.onlyFromCommission] : true,
            };
            this.FilterValues$.next(temp);
        });

        this.subscriptions.push(
            this.FilterValues$.pipe(skip(2)).subscribe((val) => {
                if (Object.keys(val).length !== 0) {
                    this.defaultFilter.SetFilterByApp(FilterApps.SelectGroupMemberPopup, val);
                }
            }),
            this.TableData$.pipe(
                switchMap((data) =>
                    this.SelectedMembers$.pipe(
                        tap((selectedMembers) =>
                            data.forEach((d) => {
                                const isSelected = selectedMembers.find(
                                    (u) => (d.entityId === u.groupMember.PersonId && d.entityType === entityType.person) || (d.entityId === u.groupMember.EmployeeId && d.entityType === entityType.employee),
                                );

                                if (isSelected && (d.selectedForm.enabled || !d.selectedForm.value)) {
                                    d.selectedForm.setValue(true);
                                    d.selectedForm.disable();
                                } else if (!isSelected && (d.selectedForm.disabled || d.selectedForm.value)) {
                                    d.selectedForm.setValue(false);
                                    d.selectedForm.enable();
                                }
                            }),
                        ),
                    ),
                ),
            ).subscribe(),
        );
    }
    RemoveSelected(entity: SelectedUserType) {
        this.LS.startLoading('delete-group-member');
        firstValueFrom(this.actions$.pipe(ofType(GroupMemberActionTypes.RemoveOne, BaseActionTypes.ErrorAction))).then(() => {
            this.LS.endLoading('delete-group-member');
        });
        this.store.dispatch(
            GroupMemberActionTypes.Delete({
                Payload: {
                    id: entity.groupMember.Id,
                },
            }),
        );
    }
    Submit() {
        this.LS.startLoading('set-user-to-event');
        firstValueFrom(combineLatest([this.TableData$, this.SelectedMembers$])).then(([data, selected]) => {
            const newMembers = data.filter(
                (d) => d.selectedForm.value && !selected.some((s) => (s.groupMember.PersonId === d.entityId && d.entityType === entityType.person) || (s.groupMember.EmployeeId === d.entityId && d.entityType === entityType.employee)),
            );
            firstValueFrom(this.actions$.pipe(ofType(GroupMemberActionTypes.AddOne, BaseActionTypes.ErrorAction), skip(newMembers.length - 1))).then(() => {
                this.LS.endLoading('set-user-to-event');
            });
            newMembers.forEach((member) =>
                this.store.dispatch(
                    GroupMemberActionTypes.Create({
                        Payload: {
                            groupId: this.Dialogdata.groupId,
                            personId: (member.entityType === entityType.person && member.entityId) || undefined,
                            employeeId: (member.entityType === entityType.employee && member.entityId) || undefined,
                        },
                    }),
                ),
            );
            this.LS.endLoading('set-user-to-event');
        });
    }
    openDeletePopup() {
        firstValueFrom(this.Group$).then((group) => {
            this.appDialog
                .OpenConfirmationDialog({
                    paragraph: `Gruppe ${group.Name} wirklich löschen?`,
                    styleDelete: true,
                })
                .subscribe(([result]) => {
                    if (result) {
                        firstValueFrom(this.actions$.pipe(ofType(GroupActionTypes.RemoveOne, BaseActionTypes.ErrorAction))).then(() => this.dialogRef.close());
                        this.store.dispatch(GroupActionTypes.Delete({ Payload: { id: group.Id } }));
                    }
                });
        });
    }
    openEditGroupPopUp() {
        firstValueFrom(this.Group$).then((group) => {
            const cName = new FormControl<string>(group.Name, Validators.required);
            this.dialog
                .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                    ...DetailListTemplateDialogComponent.DefaultConfig,
                    data: {
                        DisableSaveButton$: cName.statusChanges.pipe(
                            startWith(cName.status),
                            map((state) => state !== 'VALID'),
                        ),
                        Editing: true,
                        Data: {
                            Headline: 'Gruppe bearbeiten',
                            Properties: [
                                {
                                    key: 'Bezeichnung',
                                    formControl: cName,
                                },
                            ],
                        },
                    },
                })
                .afterClosed()
                .subscribe((ret) => {
                    if (ret.Action === 'save') {
                        this.store.dispatch(
                            GroupActionTypes.Change({
                                Payload: {
                                    id: group.Id,
                                    name: cName.value,
                                },
                            }),
                        );
                    }
                });
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
