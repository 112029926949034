import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { PipesModule } from '../dave-utils-module/dave-shared-components-module/pipes/pipes.module';
import { IconPipeModule } from '../dave-utils-module/icon-pipe/icon-pipe.module';
import { EventListEntryComponent } from './components/event-list-entry/event-list-entry.component';



@NgModule({
    declarations: [EventListEntryComponent],
    imports: [CommonModule, FontAwesomeModule, MatTooltipModule, IconPipeModule, MatDividerModule, PipesModule, AppButtonModule, RouterModule, MatListModule, MatProgressSpinnerModule, MatBadgeModule, MatCheckboxModule, FormsModule],
    exports: [EventListEntryComponent],
})
export class DaveEventListEntryModule {}
