import { Person2EntityType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export enum Person2EntityEntityTypeEnum {
    Commission = 'COMMISSION',
    Customer = 'CUSTOMER',
    Supplier = 'SUPPLIER',
    Person = 'PERSON',
    Event = 'EVENT',
    Employee = 'EMPLOYEE',
    Documents = 'DOCUMENTS',
    Transmission = 'TRANSMISSION',
    Process = 'PROCESS',
}
export class Person2EntityEntity {
    public static readonly GqlFields = 'entityId entityType id partnerId personId complaintTypeId updatedAt';
    // public static readonly GqlFields = 'entityId entityType id partnerId personId personTypeId complaintTypeId';

    constructor(
        public EntityId: number = null,
        public EntityType: Person2EntityEntityTypeEnum = null,
        public Id: number = null,
        public PartnerId: number = null,
        public PersonId: number = null,
        // public PersonTypeId: number = null, //redundant mit PersonTypeId in person aktueller stand kann gelöscht werden 2022.01.13
        public ComplaintTypeId: number = null,
        public UpdatedAt: Date = null,
    ) {}

    public Clone(override: Person2EntityAttributes = {}): Person2EntityEntity {
        const attr = { ...this, ...override };
        return Object.assign(new Person2EntityEntity(), attr);
    }
}

export function Person2EntityFromBackend(res: Person2EntityType): Person2EntityEntity {
    // @ts-ignore
    const entityType: Person2EntityEntityTypeEnum = res.entityType;

    return new Person2EntityEntity(res.entityId, entityType, res.id, res.partnerId, res.personId, res.complaintTypeId, BackendDate(res.updatedAt));
}

export interface Person2EntityAttributes {
    EntityId?: number;
    EntityType?: Person2EntityEntityTypeEnum;
    Id?: number;
    PartnerId?: number;
    PersonId?: number;
    // PersonTypeId?: number;
    ComplaintTypeId?: number;
    UpdatedAt?: Date;
}
