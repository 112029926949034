import { Chart } from 'chart.js';
import { ElementRef } from '@angular/core';

const PLUGIN_ID = 'htmlLegend';

const getOrCreateLegendList = (chart, container: ElementRef<HTMLElement>) => {
    const legendContainer: HTMLElement = container.nativeElement;
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.style.display = 'flex';
        listContainer.style.flexDirection = 'column';
        listContainer.style.margin = '0';
        listContainer.style.padding = '0';
        listContainer.classList.add(PLUGIN_ID + '__list_container');

        legendContainer.appendChild(listContainer);
    }

    return listContainer;
};

export const htmlLegendPlugin = {
    id: PLUGIN_ID,
    afterUpdate(chart/*: Chart*/, args, options/*: Proxy*/) {
        console.log({chart, args, options})
        if(!(options.display || chart.config.options.plugins.htmlLegend?.display)) {
            return;
        }
        const ref: ElementRef<HTMLElement> = options.containerRef || chart.config.options.plugins.htmlLegend?.containerRef;
        const ul = getOrCreateLegendList(chart, ref);

        // Remove old legend items
        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.config.options.plugins.htmlLegend.filter
            ? chart.options.plugins.legend.labels.generateLabels(chart).filter(chart.config.options.plugins.htmlLegend.filter)
            : chart.options.plugins.legend.labels.generateLabels(chart);
        items.forEach((item) => {
            const li = document.createElement('li');
            li.style.alignItems = 'center';
            li.style.cursor = 'pointer';
            li.style.display = 'flex';
            li.style.flexDirection = 'row';
            li.style.marginLeft = '0.625em';
            li.style.marginTop = '4px';
            li.style.marginBottom = '4px';
            li.style.whiteSpace = 'nowrap';
            li.style.textDecoration = item.hidden ? 'line-through' : '';
            li.classList.add(PLUGIN_ID + '__list_entry');

            li.onclick = () => {
                const { type } = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                    // Pie and doughnut charts only have a single dataset and visibility is per item
                    chart.toggleDataVisibility(item.index);
                } else {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };

            // Color box
            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle;
            boxSpan.style.borderColor = item.strokeStyle;
            boxSpan.style.borderWidth = item.lineWidth + 'px';
            boxSpan.style.display = 'inline-block';
            boxSpan.style.height = '1.25em';
            boxSpan.style.marginRight = '0.625em';
            boxSpan.style.width = '1.25em';
            boxSpan.style.borderRadius = '50%';
            boxSpan.classList.add(PLUGIN_ID + '__color_box');

            li.appendChild(boxSpan);

            //Value
            if (options.showValue || chart.config.options.plugins.htmlLegend?.showValue) {
                const valueContainer = document.createElement('B');
                valueContainer.style.marginRight = '0.625em';
                valueContainer.classList.add(PLUGIN_ID + '__label_value');

                const v = chart.config.data.datasets.reduce((sum, val) => sum + val.data[item.index], 0);
                const value = document.createTextNode(
                    (chart.config.options.plugins.htmlLegend.callbacks?.value ? chart.config.options.plugins.htmlLegend.callbacks.value(v) : v) +
                        '' +
                        (chart.config.options.plugins.htmlLegend.callbacks?.afterValue ? chart.config.options.plugins.htmlLegend.callbacks.afterValue(item) : ''),
                );
                valueContainer.appendChild(value);

                li.appendChild(valueContainer);
            }

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.margin = '0';
            textContainer.style.padding = '0';
            // textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
            textContainer.classList.add(PLUGIN_ID + '__label_text');
            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(textContainer);

            ul.appendChild(li);
        });
    },
};
