import { createAction, props } from '@ngrx/store';
import {DaveMutationChangeTransmissionArgs, DaveMutationCreateTransmissionArgs, DaveMutationDeleteTransmissionArgs} from '../../graphql-types';
import {TransmissionEntity} from '../../entities/transmission.entity';


export const TransmissionActionTypes = {
    UpdateTransmission: createAction(
        'data/Transmission/update',
        props<{ Payload: Array<TransmissionEntity> }>(),
    ),
    AddTransmission: createAction(
        'data/Transmission/add',
        props<{ Payload: DaveMutationCreateTransmissionArgs }>(),
    ),
    ChangeTransmission: createAction(
        'data/Transmission/change',
        props<{ Payload: DaveMutationChangeTransmissionArgs }>(),
    ),
    DeleteTransmission: createAction(
        'data/Transmission/delete',
        props<{ Payload: DaveMutationDeleteTransmissionArgs }>(),
    ),
    GetTransmissionRequest: createAction(
        'data/Transmission/getAll',
    ),
};

