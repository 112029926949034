import { Action, createReducer, on } from '@ngrx/store';
import { EmailConnectionEntity } from '../../entities/email-connection.entity';
import { EmailConnectionActionTypes } from '../actions/email-connection.actions';


export const emailConnectionFeatureKey = 'emailConnections';

export interface EmailConnectionState {
    emailConnections: EmailConnectionEntity[];
}

export const initialState: EmailConnectionState = {
    emailConnections: null,
};


export const emailConnectionReducer = createReducer(
  initialState,
    on(EmailConnectionActionTypes.UpdateEmailConnections, (state, { Payload }) => ({
        ...state,
        emailConnections: Payload
    })),
);
