import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    ConstructionDiaryState,
    CONSTRUCTION_DIARY_KEY,
    selectAllConstructionDiaries,
    selectConstructionDiaryEntities,
    selectConstructionDiaryLatestUpdatedAt,
} from '../reducers/construction-diary.reducer';
import { selectConstructionDiariesFetched } from '../reducers/construction-diary.reducer';

const selectConstructionDiary = createFeatureSelector< ConstructionDiaryState>(CONSTRUCTION_DIARY_KEY);
const getConstructionDiaryEntitys = createSelector(selectConstructionDiary, selectConstructionDiaryEntities);

export const getConstructionDiaries = createSelector(selectConstructionDiary, selectAllConstructionDiaries);
export const getConstructionDiariesFetched = createSelector(selectConstructionDiary, selectConstructionDiariesFetched);
export const getConstructionDiaryById = createSelector(
    getConstructionDiaryEntitys,
    (constructionDiary: ReturnType<typeof getConstructionDiaryEntitys>, Id: number) => Id && constructionDiary[Id],
);
export const getConstructionDiaryLatestUpdatedAt = createSelector(selectConstructionDiary, selectConstructionDiaryLatestUpdatedAt);
