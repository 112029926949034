<header>
    <h1 mat-dialog-title>{{cls.getMultiple$('Commission') | async}} zuweisen</h1>
    <span class="spacer"></span>
    <button app-round-button (click)="newCommissionClick()" [IsLoading]="LS.IsLoading$ | async"><app-double-icon [Icon]="CommissionMeta.Icon" Icon2="plus"></app-double-icon></button>
</header>
<app-multi-select-table
    mat-dialog-content
    *ngIf="ContentResolved$ | async; else loading"
    [TableData]="FilteredTableData$ | async"
    [TableDataConfig]="TableDataConfig"
    [Selected]="SelectedCommissions$ | async"
    (RemoveSelected)="RemoveSelected($event)"
    [EmptyText]="'Keine ' + (cls.getMultiple$('Commission') | async) + ' gefunden'"
    Headline=""
    [SearchPlaceHolder]="'nach ' + (cls.getMultiple$('Commission', 'Dativ') | async) + ' suchen'"
    [SelectedHeadline]="'Zugewiesene ' + (cls.getMultiple$('Commission') | async)"
    (SearchStringChange)="SearchString.next($event)"
    (RowClick)="$event.selectedForm.disabled ? {} : $event.selectedForm.setValue(!$event.selectedForm.value); this.dirty = true;"
>
    <div class="filter-wrapper">
        <button
            app-round-button
            Inverted
            [matBadge]="FilterAmount$ | async"
            matTooltip="Filter"
            [matBadgeOverlap]="true"
            [matBadgeHidden]="!(FilterAmount$ | async)"
            (click)="filter.Open()"
        >
            <fa-icon icon="filter"></fa-icon>
        </button>
        <app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
    </div>
</app-multi-select-table>

<mat-dialog-actions>
    <button app-button mat-dialog-close="" Inverted Color="cancel" [IsLoading]="LS.IsLoading$ | async">Abbrechen</button>
    <button app-button (click)="Submit()" [Disabled]="!dirty" [IsLoading]="LS.IsLoading$ | async" Color="green">Speichern</button>
</mat-dialog-actions>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
