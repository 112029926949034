import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { filter, skip, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TagActionTypes } from '../../../../dave-data-module/State/actions/tag.actions';
import { TagEntity } from '../../../../dave-data-module/entities/tag.entity';
import { State } from '../../../../dave-data-module/State';
import { AppDialogService } from '../../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { isNotNullOrUndefined } from '../../../../helper/helper';
import { getTags } from '../../../../dave-data-module/State/selectors/tag.selectors';
import { FormControl } from "@angular/forms";

/**
 * Spezifische Wrapperkomponente um die generische ChipAutocomplete-Komponente.
 *
 * Diese Komponente wird an mehreren Orten benutzt und besitzt eine nichttriviale Implementation.
 */
@Component({
    selector: 'app-tag-chip-autocomplete',
    templateUrl: './tag-chip-autocomplete.component.html',
    styleUrls: ['./tag-chip-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagChipAutocompleteComponent implements OnInit {
    /** (NN) Alle Tags */
    public Options$ = this.store.select(getTags).pipe(filter(isNotNullOrUndefined));

    /** FormControl der ausgewählten Tags */
    @Input() public FormControl: FormControlTyped<TagEntity[] | null> | FormControl<TagEntity[] | null>;

    /** Mappingfunktion, die einem Tag einen anzeigbaren String zuordnet */
    public MapFn: (option: TagEntity) => string = t => t.Name;

    constructor(
        private appDialog: AppDialogService,
        private store: Store<State>,
        private toast: ToastrService,
    ) {
    }

    ngOnInit() {
        if (!this.FormControl) {
            throw Error('No FormControl supplied!');
        }
    }

    /** Erstellt einen neuen Tag und fügt diesen dem Array der FormControl hinzu */
    public Add(optionName: string) {
        this.store.dispatch(
            TagActionTypes.AddTag({
                Payload: {
                    name: optionName,
                },
            }),
        );
        // doppelte Eingaben unterbinden, während auf die Response gewartet wird
        this.FormControl.disable();

        this.store
            .select(getTags)
            // aktuellen Wert überspringen und auf Response warten -
            // mit oder ohne neuem Tag, siehe AddTag Effect
            .pipe(skip(1), take(1))
            .subscribe(tags => {
                this.FormControl.enable();

                const newOpt = tags.find(tag => tag.Name === optionName);

                // eventuell wurde der Tag nicht angelegt
                if (newOpt) {
                    this.FormControl.setValue([...this.FormControl.value, newOpt]);
                }
            });
    }

    /**
     * Clickhandler des Tag-Löschen-Buttons einer Option im Autocomplete.
     *
     * Löscht den Tag wenn er nicht verwendet wird und der Nutzer die Löschung bestätigt.
     */
    public Delete(option: TagEntity, event: MouseEvent) {
        // nicht das `select` Event der `mat-option` auslösen
        event.stopPropagation();

        if (option.UsedByCount) {
            this.toast.error(
                `Der Tag "${option.Name}" ist noch ${
                    option.UsedByCount === 1 ? 'einem' : option.UsedByCount
                } DoKument zugeordnet. Entfernen Sie die Zuordnung und versuchen Sie es erneut.`,
            );
        } else {
            this.appDialog
                .OpenConfirmationDialog({paragraph: `Tag ${option.Name} wirklich löschen?`, styleDelete: true,})
                .subscribe(
                    ([result,]) =>
                        result &&
                        this.store.dispatch(
                            TagActionTypes.DeleteTag({
                                Payload: {
                                    id: option.Id,
                                },
                            }),
                        ),
                );
        }
    }
}
