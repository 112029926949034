import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy,Component,Inject,OnDestroy,ViewChild } from '@angular/core';
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule,MatDialogRef,MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BehaviorSubject,Subject,Subscription } from 'rxjs';
import { filter,tap } from 'rxjs/operators';
import { SimplebarAngularModule } from "simplebar-angular";
import { ARLTemplateTypeEntity } from '../../../dave-data-module/entities/a-r-l-template-type.entity';
import { CommissionTypeEntity } from '../../../dave-data-module/entities/commissionType.entity';
import { AppButtonModule } from "../../../dave-utils-module/app-button-module/app-button.module";
import { ModifyBookingOptionTemplateComponent } from './modify-booking-option-template.component';

export interface ModifyBookingOptionTemplateDialogComponentData {
    BookingOptionTemplateId?: number;
    IsLarge?: boolean;
}
@Component({
    selector: 'app-modify-booking-option-template-dialog',
    templateUrl: './modify-booking-option-template-dialog.component.html',
    styleUrls: ['./modify-booking-option-template-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MatCardModule, SimplebarAngularModule, ModifyBookingOptionTemplateComponent, AppButtonModule, MatDialogModule, MatTooltipModule, FontAwesomeModule],
})
export class ModifyBookingOptionTemplateDialogComponent implements OnDestroy {
    constructor(@Inject(MAT_DIALOG_DATA) public Data: ModifyBookingOptionTemplateDialogComponentData, public DialogRef: MatDialogRef<ModifyBookingOptionTemplateDialogComponent>) {
        this.subs.push(
            this.SelectType$.pipe(
                filter((v) => !!v),
                tap(() => this.EntryClicked({ isNoType: true })),
            ).subscribe(),
        );
        this.IsLarge = Data.IsLarge;
    }
    public IsLarge: boolean;
    public Save$ = new Subject<void>();
    public Delete$ = new Subject<void>();
    public SelectType$ = new BehaviorSubject<boolean>(false);

    private subs: Subscription[] = [];

    @ViewChild('modifyBookingOption') private modifyBookingOption: ModifyBookingOptionTemplateComponent;

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
    EntryClicked(entry: CommissionTypeEntity | ARLTemplateTypeEntity | { isNoType: true }) {
        this.modifyBookingOption.Form.controls.CommissionTypeId.setValue(entry instanceof CommissionTypeEntity ? entry.Id : null);
        this.modifyBookingOption.Form.controls.TemplateTypeName.setValue(entry instanceof ARLTemplateTypeEntity ? entry.Name : null);
    }
}
