import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { of, Subject } from 'rxjs';
import {catchError, concatMap, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import { DaveLoadingPopupComponent, DaveLoadingPopupComponentDialogData } from '../../../dave-loading-popup/dave-loading-popup.component';
import { EmailConnectionEntityFromBackend } from '../../entities/email-connection.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { EmailConnectionActionTypes } from '../actions/email-connection.actions';
import { EmailSettingsActionTypes } from '../actions/email-settings.actions';
import { EmailConnection2EmailSettingsTypeActionTypes } from '../actions/emailConnection2EmailSettingsType.actions';
import { ResolverActionTypes, ResolverLoadEmailConnections } from '../actions/resolver.actions';
import { State } from '../index';
import {getEmailSettingsTypes, getEmailSettingsTypesFetched} from '../selectors/emailSettingsType.selector';
import { stringifyIfNotUndefined } from '../../../helper/helper';
import {EmailSettingsTypeResolver} from "../../guards/emailSettingsType.resolver";
import { getEmailConnections, getEmailConnectionsFetched } from '../selectors/email-connection.selectors';

enum ErrorCodes {
    Add = 'E-Mail-Verbindungseinstellungen Hinzufügen fehlgeschlagen',
    Load = 'E-Mail-Verbindungseinstellungen Abrufen fehlgeschlagen',
    Modify = 'E-Mail-Verbindungseinstellungen Bearbeiten fehlgeschlagen',
    Remove = 'E-Mail-Verbindungseinstellungen Löschen fehlgeschlagen',
}
enum ValidationText {
    succ = 'Das E-Mail-Konto wurde erfolgreich verbunden.',
    fail = 'Das E-Mail-Konto konnte nicht verbunden werden, bitte überprüfen Sie Ihre Eingaben.',
    progress = 'Bitte haben Sie einen Moment Geduld ...',
    title = 'Das E-Mail-Konto wird überprüft'
}
@Injectable()
export class EmailConnectionEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
        private apiToasterService: ToastrService,
        private dialog: MatDialog,
        private emailSettingsTypeResolver: EmailSettingsTypeResolver
    ) {}


    AddEmailConnection$ = createEffect(() => this.actions$.pipe(
        ofType(EmailConnectionActionTypes.AddEmailConnection),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                createEmailConnection(
                    host: "${action.Payload.Host || ''}"
                    sendHost: "${action.Payload.SendHost || ''}"
                    method: "${action.Payload.Method || ''}"
                    sendMethod: "${action.Payload.SendMethod || ''}"
                    sendPort: ${action.Payload.SendPort || null}
                    port: ${action.Payload.Port || null}
                    eventTypeId: ${action.Payload.EventTypeId || null}
                    username: "${action.Payload.Username || ''}"
                    address: "${action.Payload.Address || ''}"
                    password: "${action.Payload2}"
                    type: "${action.Payload.Type || ''}"
                    pollingrate: ${action.Payload.Pollingrate || null}
                    connectionTested: ${action.Payload.ConnectionTested || false}
                    connectionPrivate: ${action.Payload.ConnectionPrivate || false}
                    assignToOtherUser: ${action.Payload.AssignToOtherUser || false}
                    signature: ${JSON.stringify(action.Payload.Signature) || '""'}
                    tokenId: ${JSON.stringify(action.Payload.TokenId) || 'null'}
                ) {
                    connectionPrivate
                    connectionTested
                    eventTypeId
                    host
                    id
                    method
                    sendMethod
                    pollingrate
                    port
                    type
                    username
                    sendPort
                    sendHost
                    signature
                    deleted
                    assignToOtherUser
                    address
                    tokenId
                }
            }`;
            const onReady$ = new Subject<{ text: string; state: 'success' | 'fail' }>();
            this.dialog.open<DaveLoadingPopupComponent, DaveLoadingPopupComponentDialogData>(DaveLoadingPopupComponent, {
                data: {
                    onReady$,
                    text: ValidationText.progress,
                    title: ValidationText.title,
                },
                hasBackdrop: false,
            });
            this.emailSettingsTypeResolver.resolve();
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                tap(res => {
                    if (res && res.createEmailConnection) {
                        if (res.createEmailConnection.connectionTested) {
                            onReady$.next({ text: ValidationText.succ, state: 'success' });
                            // this.apiToasterService.success('Die E-Mail des Benutzerkontos ist validiert');
                        } else {
                            onReady$.next({ text: ValidationText.fail, state: 'fail' });
                            // this.apiToasterService.error('Die E-Mail des Benutzerkontos ist nicht validiert');
                        }
                    } else {
                        onReady$.next({ text: ErrorCodes.Add, state: 'fail' });
                    }
                }),
                withLatestFrom(this.store$.select(getEmailSettingsTypesFetched).pipe(filter(f => f), switchMap(() => this.store$.select(getEmailSettingsTypes)))),
                map(([res, emailSettingsTypes]) => {
                    if (res && res.createEmailConnection) {
                        this.store$.dispatch(
                            EmailConnection2EmailSettingsTypeActionTypes.AddEmailConnection2EmailSettingsType({
                                Payload: {
                                    emailConnectionsId: res.createEmailConnection.id,
                                    emailSettingsTypeId: emailSettingsTypes.map(e => e.Id),
                                },
                            }),
                        );
                    }
                    return res;
                }),
                withLatestFrom(this.store$.select(getEmailConnectionsFetched), this.store$.select(getEmailConnections)),
                map(([res, ecFetched, ec]) =>
                    res && res.createEmailConnection
                        ? ecFetched ? EmailConnectionActionTypes.UpdateEmailConnections({
                              Payload: [...ec, EmailConnectionEntityFromBackend(res.createEmailConnection)],
                          }) : new ResolverLoadEmailConnections()
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    LoadEmailConnections$ = createEffect(() => this.actions$.pipe(
        ofType(ResolverActionTypes.LoadEmailConnections, EmailSettingsActionTypes.LoadEmailConnections),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
            query {
                emailconnection {
                    connectionTested
                    connectionPrivate
                    eventTypeId
                    host
                    sendHost
                    id
                    method
                    sendMethod
                    pollingrate
                    port
                    sendPort
                    type
                    username
                    signature
                    deleted
                    assignToOtherUser
                    address
                    tokenId
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                map(res =>
                    res && res.emailconnection
                        ? EmailConnectionActionTypes.UpdateEmailConnections({
                              Payload: res.emailconnection.map(val => EmailConnectionEntityFromBackend(val)),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    ModifyEmailConnection$ = createEffect(() => this.actions$.pipe(
        ofType(EmailConnectionActionTypes.ModifyEmailConnection),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation{
                changeEmailConnection(
                    id: ${action.Payload.Id || null}
                    eventTypeId: ${action.Payload.EventTypeId || ''}
                    host: "${action.Payload.Host || ''}"
                    sendHost: "${action.Payload.SendHost || ''}"
                    method: "${action.Payload.Method || ''}"
                    sendMethod: "${action.Payload.SendMethod || ''}"
                    sendPort: ${action.Payload.SendPort || null}
                    port: ${action.Payload.Port || null}
                    username: "${action.Payload.Username || ''}"
                    password: "${action.Payload2 || ''}"
                    address: "${action.Payload.Address || ''}"
                    type: "${action.Payload.Type || ''}"
                    pollingrate: ${action.Payload.Pollingrate || null}
                    connectionTested: ${action.Payload.ConnectionTested || false}
                    connectionPrivate: ${action.Payload.ConnectionPrivate || false}
                    assignToOtherUser: ${action.Payload.AssignToOtherUser || false}
                    signature: ${JSON.stringify(action.Payload.Signature) || '""'}
                    ${action.Payload.TokenId !== undefined ? `tokenId: ${JSON.stringify(action.Payload.TokenId)}` : ''}
                ) {
                    connectionTested
                    connectionPrivate
                    eventTypeId
                    host
                    sendHost
                    id
                    method
                    sendMethod
                    pollingrate
                    port
                    sendPort
                    type
                    username
                    signature
                    deleted
                    assignToOtherUser
                    address
                    tokenId
                }
            }`; // ToDo: Testen ob password ein leerer String sein darf

            const onReady$ = new Subject<{ text: string; state: 'success' | 'fail' }>();
            this.dialog.open<DaveLoadingPopupComponent, DaveLoadingPopupComponentDialogData>(DaveLoadingPopupComponent, {
                data: {
                    onReady$,
                    text: ValidationText.progress,
                    title: ValidationText.title,
                },
                hasBackdrop: false,
            });
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                tap(res => {
                    if (res && res.changeEmailConnection) {
                        if (res.changeEmailConnection.connectionTested) {
                            onReady$.next({ text: ValidationText.succ, state: 'success' });
                            // this.apiToasterService.success('Die E-Mail des Benutzerkontos ist validiert');
                        } else {
                            onReady$.next({ text: ValidationText.fail, state: 'fail' });
                            // this.apiToasterService.error('Die E-Mail des Benutzerkontos ist nicht validiert');
                        }
                    } else {
                        onReady$.next({ text: ErrorCodes.Modify, state: 'fail' });
                    }
                }),
                map(res =>
                    res && res.changeEmailConnection ? new ResolverLoadEmailConnections() : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    DeleteEmailConnection$ = createEffect(() => this.actions$.pipe(
        ofType(EmailConnectionActionTypes.DeleteEmailConnection),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
            mutation {
                deleteEmailConnection(id: ${action.Payload})
                {
                    connectionTested
                    connectionPrivate
                    eventTypeId
                    host
                    sendHost
                    id
                    method
                    sendMethod
                    pollingrate
                    port
                    sendPort
                    type
                    username
                    signature
                    deleted
                    assignToOtherUser
                    address
                    tokenId
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    if (res && res.deleteEmailConnection) {
                        this.apiToasterService.success('Die E-Mail-Verbindung wurde gelöscht');
                    }
                    return res && res.deleteEmailConnection ? new ResolverLoadEmailConnections() : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
}
