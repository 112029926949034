import { SepaEntity } from '../../entities/sepa.entity';
import { createReducer, on } from '@ngrx/store';
import { SepaActionTypes } from '../actions/sepa.actions';


export const sepaFeatureKey = 'sepa';

export interface SepaState {
    sepa: SepaEntity[];
}

export const initialState: SepaState = {
    sepa: null,
};

export const SepaReducer = createReducer(
    initialState,
    on(SepaActionTypes.UpdateSepa, (state, { Payload }) => ({
        ...state,
        sepa: Payload,
    })),
);
