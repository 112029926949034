import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../State';
import { ResourceActionTypes } from '../../State/actions/resource-dispo/resource.actions';
import { getResourceLatestUpdatedAt, getResourcesFetched } from '../../State/selectors/resource-dispo/resource.selectors';
import { BaseEntityReloadResolver2 } from '../resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class ResourceResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getResourcesFetched, getResourceLatestUpdatedAt, ResourceActionTypes.Load, store);
    }
}
