import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingComponent {
    @Input() OwnRating: number = null;
    @Input() RatingCount: number = null;
    @Input() Rating: number = null;
    @Input() Clickable = true;
    @Output() OwnRatingChange = new EventEmitter<number>();

    public Rate(rating: number) {
        if (this.Clickable) {
            this.OwnRating = rating;
            this.OwnRatingChange.emit(rating);
        }
    }

    public GetIcon(range: number): IconProp {
        return this.Rating > range - 6.67
            ? 'star'
            : this.Rating > range - 13.33
            ? 'star-half-alt'
            : ['far', 'star'];
    }
}
