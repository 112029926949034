import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { BookmarkActionTypes } from '../actions/bookmarks.actions';
import { BookmarkEntity } from '../../entities/bookmark.entity';
import { EmailActionTypes } from '../actions/email.actions';

export interface BookmarkState extends EntityState<BookmarkEntity> {
    fetched: boolean;
    lastFetched: Date | null;
}

export const BOOKMARK_KEY = 'bookmark';

const adapter = createEntityAdapter<BookmarkEntity>({
    selectId: Entity => Entity.EventId,
});

const initialState = adapter.getInitialState({
    fetched: false,
    lastFetched: null
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const bookmarkReducer = createReducer(
    initialState,
    on(BookmarkActionTypes.UpdateBookmarks, (state, { NewBookmarks }) => adapter.setAll(NewBookmarks, { ...state, fetched: true })),
    on(BookmarkActionTypes.RemoveMany, (state, { Payload }) => adapter.removeMany(Payload, { ...state })),
    on(BookmarkActionTypes.SetOne, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(BookmarkActionTypes.UpdateFetched, (state, {Date}) => ({
        ...state,
        lastFetched: Date
    }))
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllBookmark = selectAll;
export const selectBookmarkEntities = selectEntities;

export const selectBookmarkFetched = (state: BookmarkState) => state.fetched;
