import { createAction, props } from '@ngrx/store';
import { PersonTypeEntity } from '../../entities/personType.entity';
import { DaveMutationCreatePersonTypeArgs, DaveMutationDeletePersonTypeArgs } from '../../graphql-types';


export const PersonTypeActionTypes = {
    UpdatePersonType: createAction(
        'data/PersonType/update',
        props<{ Payload: Array<PersonTypeEntity> }>(),
    ),
    GetPersonTypeRequest: createAction(
        'data/PersonType/getAll',
    ),
    Create: createAction( 'data/PersonType/create/request', props<{Payload: DaveMutationCreatePersonTypeArgs}>()),
    CreateSuccess: createAction('data/PersonType/create/success', props<{ Payload: PersonTypeEntity }>()),
    CreateFailure: createAction('data/PersonType/create/failure', props<{ Payload: { Errors: any; ToasterMessage?: string } }>()),

    DeletePersonType: createAction('data/PersonType/delete', props<{Payload: DaveMutationDeletePersonTypeArgs}>()),
    RemoveOne: createAction('data/PersonType/removeOne', props<{ Payload: number }>()),
    };

