import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    selectAllUser, selectSettingFetched,
    selectUserEntities,
    selectUserFetched,
    selectUserLatestUpdatedAt,
    usersFeatureKey,
    UsersState,
} from '../reducers/users.reducer';

const selectUser = createFeatureSelector< UsersState>(usersFeatureKey);
const getUserEntitys = createSelector(selectUser, selectUserEntities);

export const getUsers = createSelector(selectUser, selectAllUser);
export const getUserDictionary = getUserEntitys;
export const getUsersFetched = createSelector(selectUser, selectUserFetched);
export const getUserLatestUpdatedAt = createSelector(selectUser, selectUserLatestUpdatedAt);
export const getUserById  = (props: { id: number }) => createSelector(getUserEntitys, (dictionary) => props.id && dictionary[props.id]);

export const getUser = createSelector(selectUser, users => {
    return users.user
});
export const getUsersWithMyPartnerId = createSelector(getUsers, getUser, (users, user) => {
    if (!user) {
        console.error('User Not Set; from getUsersWithMyPartnerId');
        return null;
    }
    const ret = users.filter(u => u.PartnerId === user.PartnerId);
    return ret;
});
export const getSettingFetched = createSelector(selectUser, selectSettingFetched);

export const getManagedUsers = createSelector(selectUser, users => users.manageUserList);
export const getSetting = createSelector(selectUser,
    users => users.settings || {}, // || {} ist für altlasten welche nicht auf den getSettingsFetched warten
);
const getSorted = (props: { sortBy: 'name', direction?: 'desc' | 'asc' }) => createSelector(getUsers, (entities) => {
    switch (props.sortBy) {
        case 'name': {
            if (props.direction === 'desc') {
                return entities.sort((a, b) => (a.DisplayName.toLowerCase() < b.DisplayName.toLowerCase() ? 1 : -1));
            } else {
                return entities.sort((a, b) => (a.DisplayName.toLowerCase() > b.DisplayName.toLowerCase() ? 1 : -1));
            }
        }
        default: {
            return entities;
        }
    }
});
export const getUsersSortedByName = getSorted({sortBy: 'name'});
export const getUsersSortedByNameStartWithMe = createSelector(getUsersSortedByName, getUser, (users, user) => {
    if (!user) {
        console.error('User Not Set; from getUsersSortedByNameStartWithMe');
        return null;
    }
    const ret = users.filter(u => u.Id !== user.Id);
    ret.unshift(user);
    return ret;
});
export const getUsersActive = createSelector(getUsersSortedByNameStartWithMe, users => {
    return users?.filter(U => !U.Deleted)
});
