<app-detail-view-template>
    <ng-container *appDetailViewButtons>
        <!--        <div class="button-wrapper">-->
        <!--            <app-burger-button-wrapper class="editor-button-wrapper" [BurgerButtonMargin]="false">-->
        <app-detail-list-template [class.hide]="!ShowStatus" [Inline]="true" [Editing]="true" [Data]="StatusData$ | async"></app-detail-list-template>
        <button
            class="detail-view-buttons"
            Inverted
            app-round-button
            [Active]="ShowStatus"
            (click)="onToggleStatusClick()"
            matTooltip="Status ändern"
            aria-label="Status ändern"
            [IsLoading]="LS.IsLoading$ | async"
        >
            <fa-icon [icon]="ShowStatus ? 'chevron-right' : 'info'"></fa-icon>
        </button>
        <button
            class="detail-view-buttons"
            Inverted
            app-round-button
            (click)="SaveClick$.next()"
            matTooltip="Speichern"
            aria-label="Speichern"
            [IsLoading]="LS.IsLoading$ | async"
            Color="green"
            [Disabled]="nothingDirty$ | async"
        >
            <fa-icon icon="save"></fa-icon>
        </button>
<!--        1{{InfoiceFormGroup.dirty}}-->
<!--        2{{(SfdtIsDirty$ | async)}}-->
<!--        3{{arlTableComponent?.SomeFormDirty$ | async}}-->
        <button class="detail-view-buttons" Inverted app-round-button matTooltip="Wiedervorlage erstellen" aria-label="Wiedervorlage erstellen" *ngIf="!(LedgerImport$ | async)?.IsTemplate" (click)="CreateDocument('template')">
            <app-double-icon Icon="file-alt" Icon2="plus"></app-double-icon>
        </button>
        <button class="detail-view-buttons" Inverted app-round-button matTooltip="Dokument generieren" aria-label="Dokument generieren" [matMenuTriggerFor]="documentTypeMenu">
            <app-double-icon Icon="file-invoice-dollar" Icon2="plus"></app-double-icon>
        </button>
        <mat-menu #documentTypeMenu>
            <button mat-menu-item *ngFor="let d of LiDocTypeOptions" (click)="CreateDocument(d.type)">{{ d.label }}</button>
        </mat-menu>
        <button class="detail-view-buttons" Inverted app-round-button matTooltip="Herunterladen" aria-label="Herunterladen" *ngIf="(LedgerImport$ | async)?.IsTemplate !== true" [matMenuTriggerFor]="downloadMenu">
            <fa-icon icon="download"></fa-icon>
        </button>
        <button class="detail-view-buttons" Inverted app-round-button matTooltip="E-Mail senden" aria-label="E-Mail senden" [matMenuTriggerFor]="emailMenu">
            <fa-icon icon="paper-plane"></fa-icon>
        </button>
        <button
            class="detail-view-buttons"
            [Disabled]="!(this.LedgerImport$ | async)?.AccountsReceivableLedgerIds?.length"
            (click)="onCreateCommissionClick()"
            *ngIf="(LedgerImport$ | async)?.CommissionId === null"
            Inverted
            app-round-button
            [matTooltip]="(cls.getSingle$('Commission') | async) + ' anlegen'"
            aria-label="{{ (cls.getSingle$('Commission') | async) + ' anlegen' }}"
        >
            <app-double-icon Icon2="plus" [Icon]="CommissionMeta.Icon"></app-double-icon>
        </button>
        <button
            class="detail-view-buttons"
            [Disabled]="!(this.LedgerImport$ | async)?.AccountsReceivableLedgerIds?.length"
            (click)="onAddToResToCommissionClick(commissionId)"
            *ngIf="(LedgerImport$ | async)?.CommissionId as commissionId"
            Inverted
            app-round-button
            [matTooltip]="'Ressourcen zu ' + (cls.getSingle$('Commission') | async) + ' hinzufügen'"
            aria-label="{{ 'Ressourcen zu ' + (cls.getSingle$('Commission') | async) + ' hinzufügen' }}"
        >
            <app-double-icon Icon2="arrow-right" [Icon]="CommissionMeta.Icon"></app-double-icon>
        </button>
        <button
            class="detail-view-buttons"
            Inverted
            app-round-button
            matTooltip="Löschen"
            aria-label="Löschen"
            Color="red"
            *ngIf="ShowDeleteButton$ | async"
            (click)="OpenDeleteDialog$.next()"
        >
            <fa-icon icon="trash"></fa-icon>
        </button>
        <!--            <div class="dms-button-wrapper">-->
        <!--                <button-->
        <!--                    app-button-->
        <!--                    Lighter-->
        <!--                    class="wide-button"-->
        <!--                    *ngIf="LedgerImport$ | async as li"-->
        <!--                    [Disabled]="!li.DocumentId"-->
        <!--                    [routerLink]="['/', DMSPageMeta.Path]"-->
        <!--                    [queryParams]="{ documentId: li.DocumentId }"-->
        <!--                    matTooltip="Zum DMS"-->
        <!--                >-->
        <!--                    <fa-icon [icon]="DMSPageMeta.Icon"></fa-icon>-->
        <!--                </button>-->
        <!--            </div>-->
        <!--            </app-burger-button-wrapper>-->
        <mat-menu #downloadMenu="matMenu" style="margin: 0">
            <a mat-menu-item [class.hide]="!(DownloadLink$ | async)" [href]="DownloadLink$ | async" [download]="GetFilename(InfoiceFormGroup.value.ConsolidatedInvoiceId)" matTooltip="PDF Herunterladen">
                <fa-icon icon="file-pdf" class="mr-1"></fa-icon> PDF
            </a>
            <button mat-menu-item *ngIf="!(DownloadLink$ | async)" (click)="DownloadPdfWithoutFile$.next('pdf')" matTooltip="PDF Herunterladen"><fa-icon icon="file-pdf" class="mr-1"></fa-icon> PDF</button>
            <a mat-menu-item [href]="XInvoiceDownloadLink$ | async" [download]="GetFilename(InfoiceFormGroup.value.ConsolidatedInvoiceId)" matTooltip="X-Rechnung Herunterladen" *ngIf="XInvoiceDownloadLink$ | async">
                <fa-icon icon="file-excel" class="mr-1"></fa-icon> X-Rechnung
            </a>
            <button mat-menu-item *ngIf="!(XInvoiceDownloadLink$ | async)" (click)="DownloadPdfWithoutFile$.next('xInvoice')" matTooltip="X-Rechnung Herunterladen"><fa-icon icon="file-excel" class="mr-1"></fa-icon> X-Rechnung</button>
        </mat-menu>
        <mat-menu #emailMenu="matMenu" style="margin: 0">
            <button mat-menu-item (click)="onOpenMailDialog('pdf')" matTooltip="als PDF senden"><fa-icon icon="file-pdf" class="mr-1"></fa-icon> PDF</button>
            <button mat-menu-item (click)="onOpenMailDialog('xRechnung')" matTooltip="als X-Rechnung senden"><fa-icon icon="file-excel" class="mr-1"></fa-icon> X-Rechnung</button>
        </mat-menu>
        <!--        </div>-->
    </ng-container>
    <ng-container *appDetailViewTopContent>
        <div class="wrapper">
            <!-- das ist die mat-card mit rechnung bearbeiten-->
            <mat-card class="edit-invoice-wrapper" *ngIf="!ShowDocumentPreview || !IsMobile">
                <mat-card-header class="card-header">
                    <fa-icon matCardAvatar [icon]="InvoiceEditorMeta.Icon"></fa-icon>
                    <h2 *ngIf="LedgerImport$ | async as ledgerImport" style="margin: 0">
                        {{ (ledgerImport.IsTemplate ? 'Vorlage für ' : '') + LedgerImportDocumentTypeNames.get(ledgerImport.DocumentType) }}
                        bearbeiten
                    </h2>
                    <div class="header-buttons">
                        <div class="commission-theme theme-override" *ngIf="InfoiceFormGroup.value.CommissionId?.Id as id">
                            <a app-button class="wide-button" [routerLink]="['/', CommissionMeta.Path, AllCommissionMeta.Path, id]"><fa-icon [icon]="CommissionMeta.Icon"></fa-icon></a>
                        </div>
                        <div class="admin-theme theme-override" *ngIf="InfoiceFormGroup.value.Customer?.Id as id">
                            <a app-button class="wide-button" [routerLink]="['/', CustomerAdministrationMeta.Path, id]"><fa-icon [icon]="CustomerAdministrationMeta.Icon"></fa-icon></a>
                        </div>
                        <div class="{{ TaskPageMeta.Theme }} theme-override" *ngIf="(LedgerImport$ | async)?.EventId as id">
                            <button app-button class="wide-button" (click)="onOpenEventClick(id)"><fa-icon [icon]="TaskPageMeta.Icon"></fa-icon></button>
                        </div>
                        <div class="dms-theme theme-override" *ngIf="(LedgerImport$ | async)?.DocumentId as documentId">
                            <a app-button class="wide-button" [routerLink]="['/', DMSPageMeta.Path]" [queryParams]="{ documentId }" matTooltip="Zum DMS">
                                <fa-icon [icon]="DMSPageMeta.Icon"></fa-icon>
                            </a>
                        </div>
                        <button app-button *ngIf="!ShowDocumentPreview" (click)="ShowDocumentPreview = !ShowDocumentPreview">Vorschau</button>
                    </div>
                </mat-card-header>
                <mat-card-content #formWrapper class="scroll-wrapper">
                    <div class="invoice-info-editing-wrapper">
                        <mat-accordion multi>
                            <app-detail-list-template [Inline]="false" [Editing]="true" [Data]="CustomerData | async"></app-detail-list-template>
                            <br />
                            <app-detail-list-template [Inline]="false" [Editing]="true" [Data]="PersonData$ | async"></app-detail-list-template>
                            <br />
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Empfänger Details </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="AddressFormData"></app-detail-list-template>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Notiz </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="NotesFormData"></app-detail-list-template>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <mat-accordion multi>
                            <app-detail-list-template [Inline]="false" [Editing]="InfoiceFormGroup.value.Customer ? false : true" [Data]="PartnerOfficeData$ | async"></app-detail-list-template>
                            <br />
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Absender Details </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div style="position: relative; width: 200px">
                                    <button app-round-button style="position: absolute; top: 0.5rem; right: 0.5rem" (click)="logoUpload.click()">
                                        <fa-icon icon="upload"></fa-icon>
                                    </button>

                                    <img *ngIf="LogoUrl$ | async as logoUrl" [src]="logoUrl" style="width: 200px" />
                                    <div
                                        *ngIf="!(LogoUrl$ | async)"
                                        style="width: 200px; height: 150px; display: flex; align-items: center; justify-content: center; flex-direction: column; border-radius: 4px; background-color: rgba(0, 0, 0, 0.15)"
                                    >
                                        <fa-icon icon="camera" size="3x"></fa-icon>
                                        <h2 style="margin: 0">Logo</h2>
                                    </div>
                                    <input id="file" type="file" accept="image/*" hidden #logoUpload (change)="OnFileChange($event)" />
                                </div>
                                <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="PartnerDetailData"></app-detail-list-template>
                            </mat-expansion-panel>
                            <br />
                            <app-detail-list-template [Inline]="false" [Editing]="true" [Data]="AssignedUserData | async"></app-detail-list-template>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Bearbeiter Details </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="UserDetailData"></app-detail-list-template>
                            </mat-expansion-panel>
                            <mat-expansion-panel expanded>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Allgemeine Details </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="FormData | async"></app-detail-list-template>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <mat-card style="width: 100%">
                        <app-textarea [Fill]="true" Key="Text über Buchungstabelle" [Value]="InfoiceFormGroup.controls.TextTop" style="padding-left: 1rem; padding-top: 1rem"></app-textarea>
                    </mat-card>
                    <mat-card class="detail-list-card" style="width: 100%; max-height: 70vh">
                        <mat-card-header class="card-header">
                            <fa-icon matCardAvatar [icon]="BookingPositionMetaIcon"></fa-icon>
                            <div class="title-shortener-workaround">
                                <h3 mat-card-title style="min-width: 0vw">Abr.-Pos.</h3>
                            </div>
                            <mat-slide-toggle style="margin: 0 1rem" [formControl]="InfoiceFormGroup.controls.HideNetto">Nur Brutto-Preise auswählen</mat-slide-toggle>
                            <div class="header-button-wrapper">
                                <button *ngIf="PS.Has(PS.Permission.CreateLedgerImports) | async" app-round-button (click)="NewBookingPositionClick$.next()" [Disabled]="DisableForms$ | async" matTooltip="Abr.-Pos. hinzufügen">
                                    <app-double-icon [Icon]="'money-bill'" Icon2="plus"></app-double-icon>
                                </button>
                            </div>
                        </mat-card-header>
                        <!--                        <app-arl-table #arlTableComponent class="scroll-wrapper" [AddARLDefaultValues]="AddARLDefaultValues$ | async" (AddBookingPosition)="AddBookingPositionClick($event)" [arls]="SortedBookingPositionList$ | async" (arlsChange)="onArlTableChange($event)" [selected]="getSelected()" (selectedChange)="onSelectionChange($event)" (DeleteClick)="onArlDeleteClick($event)"></app-arl-table>-->
                        <app-arl-table
                            [disabled]="DisableForms$ | async"
                            #arlTableComponent
                            class="scroll-wrapper"
                            [arlIds]="InfoiceFormGroup.getRawValue().AccountsReceivableLedgerIds"
                            (arlIdsChange)="InfoiceFormGroup.controls.AccountsReceivableLedgerIds.setValue($event); InfoiceFormGroup.controls.AccountsReceivableLedgerIds.markAsDirty()"
                            [AddARLDefaultValues]="AddARLDefaultValues$ | async"
                            (FormValueChange)="arlTableValuesChanged$.next()"
                            (DeleteClick)="onArlDeleteClick($event)"
                            [showBruttoColumn]="InfoiceFormGroup.value.HideNetto"
                        ></app-arl-table>
                        <!--                        <div class="scroll-wrapper"-->
                        <!--                             cdkDropList-->
                        <!--                             (cdkDropListDropped)="OnListDrop($event)">-->
                        <!--                            <mat-table-->
                        <!--                                [dataSource]="sortedbookingpositionlist"-->
                        <!--                                #bookingPositionTable-->
                        <!--                            >-->
                        <!--                                <ng-container *ngFor="let collumName of AutoColumns" [matColumnDef]="collumName">-->
                        <!--                                    <mat-header-cell *matHeaderCellDef class="description-cell">-->
                        <!--                                        {{ AutoColumnHeaders[collumName] }}-->
                        <!--                                    </mat-header-cell>-->
                        <!--                                    <mat-cell *matCellDef="let rowData" class="description-cell">-->
                        <!--                                        <div class="text-box-3-row">-->
                        <!--                                            {{ rowData ? rowData[collumName] : '' }}-->
                        <!--                                        </div>-->
                        <!--                                    </mat-cell>-->
                        <!--                                </ng-container>-->
                        <!--                                <ng-container matColumnDef="Quantity">-->
                        <!--                                    <mat-header-cell *matHeaderCellDef>-->
                        <!--                                        {{ AutoColumnHeaders['Quantity'] }}-->
                        <!--                                    </mat-header-cell>-->
                        <!--                                    <mat-cell *matCellDef="let rowData">-->
                        <!--                                        {{ rowData?.Quantity | number }}-->
                        <!--                                    </mat-cell>-->
                        <!--                                </ng-container>-->
                        <!--                                <ng-container matColumnDef="BaseCost">-->
                        <!--                                    <mat-header-cell *matHeaderCellDef class="cost-cell">-->
                        <!--                                        {{ AutoColumnHeaders['BaseCost'] }}-->
                        <!--                                    </mat-header-cell>-->
                        <!--                                    <mat-cell *matCellDef="let rowData" class="cost-cell">-->
                        <!--                                        {{ rowData?.BaseCost | currency: rowData?.CurrencyCode }}-->
                        <!--                                    </mat-cell>-->
                        <!--                                </ng-container>-->
                        <!--                                <ng-container matColumnDef="CompleteCost">-->
                        <!--                                    <mat-header-cell *matHeaderCellDef class="cost-cell">-->
                        <!--                                        {{ AutoColumnHeaders['CompleteCost'] }}-->
                        <!--                                    </mat-header-cell>-->
                        <!--                                    <mat-cell *matCellDef="let rowData" class="cost-cell">-->
                        <!--                                        {{ rowData?.CompleteCost - rowData?.CompleteTaxCost | currency: rowData?.CurrencyCode }}-->
                        <!--                                    </mat-cell>-->
                        <!--                                </ng-container>-->
                        <!--                                <ng-container matColumnDef="QuantityType">-->
                        <!--                                    <mat-header-cell *matHeaderCellDef>-->
                        <!--                                        Einheit-->
                        <!--                                    </mat-header-cell>-->
                        <!--                                    <mat-cell *matCellDef="let rowData">-->
                        <!--                                        {{ GetQuantityTypeById(rowData?.QuantityTypeId, QuantityTypes$ | async) }}-->
                        <!--                                    </mat-cell>-->
                        <!--                                </ng-container>-->
                        <!--                                <ng-container matColumnDef="Checkbox">-->
                        <!--                                    <mat-header-cell *matHeaderCellDef>-->
                        <!--                                        <fa-icon icon="check-square"></fa-icon>-->
                        <!--                                    </mat-header-cell>-->
                        <!--                                    <mat-cell *matCellDef="let rowData">-->
                        <!--                                        <mat-checkbox-->
                        <!--                                            [disabled]="DisableForms$ | async"-->
                        <!--                                            (click)="$event.stopPropagation()"-->
                        <!--                                            (change)="$event ? CilickItem(rowData) : null"-->
                        <!--                                            [checked]="IsSelected(rowData)"-->
                        <!--                                            color="primary"-->
                        <!--                                        >-->
                        <!--                                        </mat-checkbox>-->
                        <!--                                    </mat-cell>-->
                        <!--                                </ng-container>-->
                        <!--                                <ng-container matColumnDef="Position">-->
                        <!--                                    <mat-header-cell *matHeaderCellDef>-->
                        <!--                                        {{ AutoColumnHeaders['Position'] }}-->
                        <!--                                    </mat-header-cell>-->
                        <!--                                    <mat-cell mat-cell *matCellDef="let rowData">-->
                        <!--                                        {{ GetPosition(rowData) }}-->
                        <!--                                    </mat-cell>-->
                        <!--                                </ng-container>-->
                        <!--                                <ng-container matColumnDef="DragButton">-->
                        <!--                                    <mat-header-cell *matHeaderCellDef>-->
                        <!--                                        <fa-icon icon="arrows-alt-v" style="margin: 0 1rem"></fa-icon>-->
                        <!--                                    </mat-header-cell>-->
                        <!--                                    <mat-cell *matCellDef="let rowData">-->
                        <!--                                        <button style="cursor: ns-resize" cdkDragHandle mat-icon-button>-->
                        <!--                                            <fa-icon icon="bars"></fa-icon>-->
                        <!--                                        </button>-->
                        <!--                                    </mat-cell>-->
                        <!--                                </ng-container>-->
                        <!--                                <ng-container matColumnDef="EditButton">-->
                        <!--                                    <mat-header-cell *matHeaderCellDef></mat-header-cell>-->
                        <!--                                    <mat-cell *matCellDef="let rowData">-->
                        <!--                                        <button-->
                        <!--                                            *ngIf="PS.Has(PS.Permission.ChangeAccountsReceivableLedgers) | async"-->
                        <!--                                            app-round-button-->
                        <!--                                            (click)="BookingPositionClicked(rowData)"-->
                        <!--                                            matTooltip="Abrechnungspositionen bearbeiten"-->
                        <!--                                        >-->
                        <!--                                            <fa-icon icon="pencil-alt"></fa-icon>-->
                        <!--                                        </button>-->
                        <!--                                    </mat-cell>-->
                        <!--                                </ng-container>-->
                        <!--                                <mat-header-row-->
                        <!--                                    [class.hide]="!(SortedBookingPositionList$ | async)?.length"-->
                        <!--                                    *matHeaderRowDef="DisplayedColumns; sticky: true"-->
                        <!--                                ></mat-header-row>-->
                        <!--                                <mat-row-->
                        <!--                                    [class.clickable-list-item]="true"-->
                        <!--                                    [class.not-clickable-list-item]="true"-->
                        <!--                                    mat-row-->
                        <!--                                    cdkDrag-->
                        <!--                                    *matRowDef="let rowData; columns: DisplayedColumns"-->
                        <!--                                ></mat-row>-->
                        <!--                            </mat-table>-->
                        <!--                        </div>-->
                        <!--                        <table *ngIf="!(SortedBookingPositionList$ | async)?.length" style="width: 100%">-->
                        <!--                            <tr>-->
                        <!--                                <td class="empty-table-text">-->
                        <!--                                    Keine Abrechnungspositionen vorhanden-->
                        <!--                                </td>-->
                        <!--                            </tr>-->
                        <!--                        </table>-->
                        <!-- ^Nebenkosten tempoer ausgebaut da der workflow überarbeitet werden muss siehe D277-865 D277-717 -->
                        <!-- <button
                            app-button
                            (click)="NewAncillaryCostsClick$.next(sortedbookingpositionlist)"
                            class="add-ancillary-costs-button"
                            [Disabled]="!InfoiceFormGroup.value.BookingPositions?.length"
                        >
                            Nebenkosten hinzufügen
                        </button> -->
                    </mat-card>
                    <mat-expansion-panel style="width: 100%">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Rabatt </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-detail-list-template [Editing]="true" [Inline]="true" [Data]="DiscountData"></app-detail-list-template>
                    </mat-expansion-panel>
                    <mat-card style="width: 100%">
                        <app-textarea [Fill]="true" Key="Text unter Buchungstabelle" [Value]="InfoiceFormGroup.controls.TextBottom" style="padding-left: 1rem; padding-top: 1rem"></app-textarea>
                    </mat-card>
                    <mat-expansion-panel style="width: 100%">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Skonto </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-detail-list-template [Editing]="true" [Inline]="true" [Data]="SkontoData"></app-detail-list-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel style="width: 100%">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Fußnote </mat-panel-title>
                            <mat-panel-description> Steuernummer, Bankverbindung </mat-panel-description>
                        </mat-expansion-panel-header>
                        <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="FooterFormData"></app-detail-list-template>
                    </mat-expansion-panel>
                </mat-card-content>
            </mat-card>
            <!--Der container in des app-editors-->
            <mat-card class="app-editor-wrapper" *ngIf="ShowDocumentPreview">
                <!--        <mat-card-header>-->
                <div>
                    <div class="mat-card-title">
                        <span class="name">{{ LedgerImportDocTypeName$ | async }}</span>
                        <button mat-icon-button (click)="ShowDocumentPreview = false"><fa-icon icon="times"></fa-icon></button>
                    </div>
                    <div class="mat-card-subtitle" *ngIf="SfdtIsDirty$ | async">
                        <span><fa-icon icon="exclamation-triangle"></fa-icon> Das angezeigte Dokument entspricht nicht dem Original. Es wurde eine Veränderung an den Positionen vorgenommen. Bitte prüfen.</span>
                    </div>
                </div>
                <mat-progress-bar style="margin-top: -4px" *ngIf="previewPdfIsLoading$ | async" mode="indeterminate"></mat-progress-bar>

                <iframe #previewIframe *ngIf="pdfPreview$ | async as pdf" [src]="pdf" style="height: 100%;"></iframe>
            </mat-card>
        </div>
    </ng-container>
</app-detail-view-template>
