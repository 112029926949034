import {TagType} from '../graphql-types';

export class TagEntity {
  public static readonly GqlFields = 'id name used_By_Count partner_id';

  public Id: number;
  public Name: string;
  public UsedByCount: number;
  public PartnerId: number;

  constructor(tagType?: TagType | null) {
    if (
      tagType &&
      tagType.id != null &&
      tagType.name != null &&
      tagType.used_By_Count != null
    ) {
      this.Id = tagType.id;
      this.Name = tagType.name;
      this.UsedByCount = tagType.used_By_Count;
    } else {
      console.error('Received broken TagType', tagType);
      throw Error();
    }
  }
}
