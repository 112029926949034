import { createAction, props } from '@ngrx/store';
import { EmployeeToVacationEntity } from '../../entities/employee-to-vacation.entity';
import {
    DaveMutationChangeEmployee2VacationApprovalArgs,
    DaveMutationChangeEmployee2VacationArgs,
    DaveMutationCreateEmployee2VacationArgs,
    DaveMutationDeleteEmployee2VacationArgs, QueryEmployee2vacationArgs,
} from '../../graphql-types';

export const EmployeeToVacationActionTypes = {
    Approve: createAction('data/employee-to-vacation/approve', props<{ Payload: DaveMutationChangeEmployee2VacationApprovalArgs }>()),
    Add: createAction('data/employee-to-vacation/add', props<{ Payload: DaveMutationCreateEmployee2VacationArgs }>()),
    Modify: createAction('data/employee-to-vacation/modify', props<{ Payload: DaveMutationChangeEmployee2VacationArgs }>()),
    Delete: createAction('data/employee-to-vacation/delete', props<{ Payload: DaveMutationDeleteEmployee2VacationArgs }>()),
    Load: createAction('data/employee-to-vacation/load', props<{ Payload?: QueryEmployee2vacationArgs }>()),
    UpdateAll: createAction('data/employee-to-vacation/updateAll', props<{ Payload: EmployeeToVacationEntity[], updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/employee-to-vacation/updateMany', props<{ Payload: EmployeeToVacationEntity[], updateLatestUpdatedAt?: boolean }>()),
};
