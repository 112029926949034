import { FormControl, FormGroup, ɵFormGroupValue } from '@angular/forms';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { calculationArlForm } from './calculation-arl-form-data.service';
import {
    AccountsReceivableLedgerTypeEnum,
    ARLTypeEnumNames,
} from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { arlTableData } from './arl-calculation-view/arl-calculation-view.component';
import { isNotNullOrUndefined, MathRound } from '../../../helper/helper';
import { SurchargeRateEntity } from '../../../dave-data-module/entities/surcharge-rate.entity';
import { Store } from '@ngrx/store';
import { State } from '../../../dave-data-module/State';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import {
    getSurchargeRateByArlType,
    getSurchargeRateFetched,
} from '../../../dave-data-module/State/selectors/surcharge-rate.selector';
import { ResolveData } from '@angular/router';
import { SURCHARGE_RATE_KEY } from '../../../dave-data-module/State/reducers/surcharge-rate.reducer';
import { SurchargeRateResolver } from '../../../dave-data-module/guards/surcharge-rate.resolver';

export class ArlCalculationSurcharge {
    public static readonly RequiredResolvers: ResolveData = {
        [SURCHARGE_RATE_KEY]: SurchargeRateResolver,
    }
    public formInitialised$ = new BehaviorSubject(false);

    public form = new FormGroup({
        usePercent: new FormControl<boolean>(false),
        percent: new FormControl<number>(null),
        decimal: new FormControl<number>(null),
    });
    private arlsByType$: Observable<ɵFormGroupValue<calculationArlForm>[]>;

    public amountSum$: Observable<number>;
    public amountSumWithZuschl$ : Observable<number>;
    public name: string;
    private defaultSurchargeRate$: Observable<SurchargeRateEntity | null | undefined>
    constructor(public type: AccountsReceivableLedgerTypeEnum, tableData: Observable<arlTableData[]>, private store: Store<State>) {
        this.name = ARLTypeEnumNames.get(type);
        this.defaultSurchargeRate$ = getFetched$(this.store, getSurchargeRateFetched, getSurchargeRateByArlType({arlType: type}));
        this.arlsByType$ = tableData.pipe(
            switchMap((data) =>
                data.length
                    ? combineLatest(
                        data.map((d) =>
                            d.form.valueChanges.pipe(
                                startWith(null),
                                map(() => d.form.getRawValue()),
                            ),
                        ),
                    )
                    : of([]),
            ),
            map(val => val.filter((v) => v.Type === type)),
        );
        this.amountSum$ = this.arlsByType$.pipe(
            map((values) => {
                return values
                    .map((v) => v.costAmountWithoutTax)
                    .reduce((prev, curr) => prev + (curr || 0), 0);
            }),
        );
        this.amountSumWithZuschl$ = combineLatest([
            this.arlsByType$,
            this.form.valueChanges.pipe(startWith(null)),
            this.formInitialised$.pipe(filter(v=>!!v)),
        ]).pipe(
            map(([amounts]) => {
                const baseCost = (cost: number) => {
                    const zuschlInPercent = (this.form.value.percent) || 0;
                    return parseFloat(((cost || 0) + (cost || 0) * (zuschlInPercent / 100)).toFixed(3));
                };

                return amounts.reduce((prev, curr) => prev + baseCost(curr.CostAmount) * curr.Quantity, 0);
            }),
            shareReplay({refCount: true, bufferSize: 1})
        );
    }
    private _init(val: number) {
        val = val || 0;
        if (this.form.value.decimal !== val) {
            firstValueFrom(this.amountSum$).then((amount) => {
                this.setFormValue(false, amount, val, true, false);
                this.form.markAsPristine();
                this.formInitialised$.next(true);
            });
        } else {
            this.form.markAsPristine();
            this.formInitialised$.next(true);
        }
    }
    public init(val: number) {
        console.log('init#', val)
        if (!isNotNullOrUndefined(val)) {
            firstValueFrom(this.defaultSurchargeRate$).then(ds => {
                if (ds) {
                    firstValueFrom(this.amountSum$).then((amount) => {
                        this.setFormValue(true, amount, ds.PercentageValue, true, false);
                        this.form.markAsPristine();
                        this.formInitialised$.next(true);
                    });
                } else {
                    this._init(val);
                }
            })
        } else {
            this._init(val);
        }

    }
    public setFormValue(usePercentValue: boolean, sumAmount: number, value: number, setPercentActive: boolean, markDirty = true) {
        const round = (num: number) => MathRound(num * 100, true) / 100;
        let val;
        if (usePercentValue) {
            val = {
                usePercent: setPercentActive,
                percent: value,
                decimal: value && round(sumAmount * (1 + value / 100) - sumAmount),
            };
        } else {
            val = {
                usePercent: setPercentActive,
                percent: value && sumAmount && round((value / sumAmount) * 100),
                decimal: value,
            }
        }
        if (val.usePercent !== this.form.value.usePercent || val.percent !== this.form.value.percent || val.decimal !== this.form.value.decimal) {
            this.form.setValue(val)
            if (markDirty) {
                this.form.markAsDirty();
            }
        }
    }
    setPercent(value: number) {
        firstValueFrom(this.amountSum$).then((amount) => {
            this.setFormValue(true, amount, value, true);
        });
    }
    settDecimal(value: number) {
        firstValueFrom(this.amountSum$).then((amount) => {
            this.setFormValue(false, amount, value, false);
        });
    }
}
