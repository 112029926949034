import { isNotNullOrUndefined } from '../helper/helper';
import { formatDate } from '@angular/common';

export enum CustomFieldTypes {
    number = 'number',
    date = 'date',
    text = 'text',
    textarea = 'textarea',
    checkbox = 'checkbox',
    select = 'select',
    time = 'time',
}

export const CustomFieldTypesNamesMap: Map<CustomFieldTypes, string> = new Map<CustomFieldTypes, string>([
    [CustomFieldTypes.text, 'Text'],
    [CustomFieldTypes.textarea, 'Text'],
    [CustomFieldTypes.number, 'Zahl'],
    [CustomFieldTypes.date, 'Datum'],
    [CustomFieldTypes.checkbox, 'Kästchen'],
    [CustomFieldTypes.select, 'Auswahl'],
    [CustomFieldTypes.time, 'Zeit'],
]);
export const CustomFieldTypesDisplayValueMap: Map<CustomFieldTypes, (v: any) => string> = new Map<CustomFieldTypes, (v: any) => string>([
    [CustomFieldTypes.text, (v: string) => v],
    [CustomFieldTypes.textarea, (v: string) => v],
    [CustomFieldTypes.number, (v: number) => (isNotNullOrUndefined(v) ? `${v}` : v)],
    [CustomFieldTypes.date, (v: Date) => v && formatDate(v, 'shortDate', 'de-DE')],
    [CustomFieldTypes.checkbox, (v: boolean) => (isNotNullOrUndefined(v) ? (v ? 'ja' : 'nein') : v)],
    [CustomFieldTypes.select, (v: string) => v],
    [CustomFieldTypes.time, (v: string) => v],
]);

interface CustomFieldsBase<T> {
    name: string;
    value: T;
    hint?: string;
    // type: CustomFieldTypes;
}

type TextField = CustomFieldsBase<string> & {
    type: CustomFieldTypes.text | CustomFieldTypes.textarea;
};
type TimeField = CustomFieldsBase<string> & {
    type: CustomFieldTypes.time;
};
type NumberField = CustomFieldsBase<number> & {
    type: CustomFieldTypes.number;
};
type DateField = CustomFieldsBase<Date> & {
    type: CustomFieldTypes.date;
};
type CheckboxField = CustomFieldsBase<boolean> & {
    type: CustomFieldTypes.checkbox;
};
type SelectField = CustomFieldsBase<string> & {
    type: CustomFieldTypes.select;
    options: { value: string | number; label: string }[];
};
export type CustomFields = TextField | NumberField | DateField | CheckboxField | SelectField | TimeField;

export interface CustomFieldsModalTemplate {
    fieldNames: string[],
    headline?: string,
    expandable?: boolean,
    expanded?: boolean,
    alignment?: 'column' | 'row',

    fieldTypeOverrides?: {"name": string, "type": CustomFieldTypes.textarea}[],
}
