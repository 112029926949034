import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { DropzoneConfig } from 'ngx-dropzone-wrapper';
import { combineLatest, firstValueFrom, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FileEntityDocumentType, FileEntityDocumentTypeNames } from '../../../../dave-data-module/entities/file.entity';
import { TagEntity } from '../../../../dave-data-module/entities/tag.entity';
import { TransmissionEntity } from '../../../../dave-data-module/entities/transmission.entity';
import { CommissionResolver } from '../../../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../../../dave-data-module/guards/customer.resolver';
import { TransmissionResolver } from '../../../../dave-data-module/guards/transmission.resolver';
import { State } from '../../../../dave-data-module/State';
import { getToken } from '../../../../dave-data-module/State/selectors/base.selectors';
import { getCommissions } from '../../../../dave-data-module/State/selectors/commission.selector';
import { getCustomers } from '../../../../dave-data-module/State/selectors/customers.selectors';
import { getTransmission } from '../../../../dave-data-module/State/selectors/transmission.selector';
import { IDetailListTemplateData } from '../../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { DropzoneComponent, ngxDropzoneWrapperPreviewHTML } from '../../../../dave-utils-module/dave-shared-components-module/components/dropzone/dropzone.component';
import { CustomerNameService } from '../../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { SelectSearchData } from '../../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { isNotNullOrUndefined } from '../../../../helper/helper';
import { CustomLabelService } from '../../../../services/custom-label.service';

@Component({
    selector: 'app-new-document-view-component',
    templateUrl: './new-document-view.component.html',
    styleUrls: ['./new-document-view.component.scss'],
})
export class NewDocumentViewComponent implements OnInit, OnDestroy {
    @Input() SynchronUpload = false;
    @Input() EventId: number = null;
    @Input() CustomerId: number = null;
    @Input() CommissionId: number = null;
    @Input() TransmissionId: number = null;
    @Input() ShowSaveButton = true;
    @Input() FolderId: number = null;
    @Input() ConstructionDiaryId: number = null;
    @Input() maxFiles: number = null;
    @Input() acceptedFiles: string = null;
    @Output() UploadSuccess = new EventEmitter<void>();
    @Output() CloseViewEvent = new EventEmitter<void>();
    @Output() UploadInProgress = new EventEmitter<boolean>();
    @ViewChild('Drpzone') Drpzone: DropzoneComponent;
    @ViewChild('deleteButton') deleteButton: TemplateRef<any>;

    private quePending = false;
    public FileForm = new FormGroup({
        Event: new FormControl<any | null>(null),
        Customer: new FormControl<SelectSearchData | null>(null),
        Commission: new FormControl<SelectSearchData | null>(null),
        Transmission: new FormControl<TransmissionEntity | null>(null),
        Description: new FormControl<string | null>(null),
        Tags: new FormControl<TagEntity[] | null>(null),
        DocumentType: new FormControl<FileEntityDocumentType>(null),
        Name: new FormControl<string>(null),
    });
    public FileCount = 0;
    public Dropzone: DropzoneConfig;
    public FileDetails$: Observable<IDetailListTemplateData>;
    public Transmissions$ = this.store.select(getTransmission);
    private subscriptions: Subscription[] = [];
    private token$ = this.store.select(getToken).pipe(filter(isNotNullOrUndefined));

    private uploadParams = [];
    protected uploadParamsObj = {};
    public PreviewElements = [];
    constructor(
        private store: Store<State>,
        transmissionResolver: TransmissionResolver,
        commissionsResolver: CommissionResolver,
        private customerResolver: CustomerResolver,
        public CS: CustomerNameService,
        private router: Router,
        private vRef: ViewContainerRef,
        protected cls: CustomLabelService,
    ) {
        transmissionResolver.resolve();
        commissionsResolver.resolve();
        this.customerResolver.resolve();
    }

    ngOnInit(): void {
        this.FileForm.get('Customer').setValue({ Id: this.CustomerId } as SelectSearchData);
        this.FileForm.get('Commission').setValue({ Id: this.CommissionId } as SelectSearchData);
        this.subscriptions.push(
            this.router.events
                .pipe(
                    filter((event: RouterEvent) => event instanceof NavigationStart),
                    filter(() => !this.quePending),
                )
                .subscribe((event) => {
                    this.OnQueComplete();
                }),
            this.Transmissions$.subscribe((transmissions) => {
                if (!this.FileForm.get('Transmission').value && this.TransmissionId) {
                    const transmission = transmissions.find((t) => t.Id === this.TransmissionId);
                    if (transmission) {
                        this.FileForm.get('Transmission').setValue(transmission);
                    }
                }
            }),
            this.token$.subscribe((token) => {
                this.Dropzone = new DropzoneConfig({
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    dictRemoveFileConfirmation: null, // macht dialog weg
                    uploadMultiple: false,
                    clickable: true,
                    paramName: () => 'file',
                    parallelUploads: 100,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: this.uploadParams,
                    previewTemplate: ngxDropzoneWrapperPreviewHTML,
                    // renameFile: (file: File) => this.FileForm.value.Name || file.name,
                });
            }),
            this.FileForm.valueChanges.subscribe((values) => {
                this.refreshUploadParams(/*values*/);
            }),
        );
        // this.FileForm.controls.Name.valueChanges.subscribe(val => {
        //     console.log({dropzone})
        //     // @ts-ignore
        //     this.Drpzone.directiveRef.instance.files[0].upload.filename = val;
        //
        // })

        this.FileDetails$ = combineLatest([this.store.select(getCustomers), this.store.select(getCommissions), this.Transmissions$, this.CS.GetSingle$(), this.cls.getSingle$('Commission')]).pipe(
            map(([customers, commissions, transmissions, customerName, commissionLabel]) => {
                return {
                    Properties: [
                        {
                            key: 'Beschreibung',
                            formControl: this.FileForm.get('Description'),
                        },
                        {
                            key: customerName,
                            value: this.CustomerId && customers?.find((c) => c.Id === this.CustomerId).Name,
                            formControl: this.FileForm.get('Customer'),
                            options: {
                                specialInput: {
                                    selectSearch:
                                        customers?.map((c) => ({
                                            optionValue: c.Id,
                                            optionLabel: c.Name,
                                        })) || [],
                                },
                            },
                        },
                        {
                            key: commissionLabel,
                            value: this.CommissionId && commissions?.find((c) => c.Id === this.CommissionId)?.GetDisplayName(),
                            formControl: this.FileForm.get('Commission'),
                            options: {
                                specialInput: {
                                    selectSearch:
                                        commissions?.map((c) => ({
                                            optionValue: c.Id,
                                            optionLabel: c.DisplayName,
                                        })) || [],
                                },
                            },
                        },
                        {
                            key: 'Übertragung',
                            value: this.FileForm.value.Transmission?.GetDisplayName() || '', // this.FileForm.get('Transmission').value?.GetDisplayName(),
                            formControl: this.FileForm.get('Transmission'),
                            options: {
                                specialInput: {
                                    selectSearch:
                                        transmissions?.map((t) => ({
                                            optionValue: t.Id,
                                            optionLabel: t.GetDisplayName(),
                                        })) || [],
                                },
                            },
                        },
                        {
                            key: 'Dokumententyp',
                            formControl: this.FileForm.controls.DocumentType,
                            options: {
                                specialInput: {
                                    select:
                                        Object.values(FileEntityDocumentType).map((t) => ({
                                            optionValue: t,
                                            optionLabel: FileEntityDocumentTypeNames.get(t),
                                        })) || [],
                                },
                            },
                        },
                    ],
                };
            }),
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    private replaceDeleteLinkWithButton(previewElement: HTMLElement) {
        const deleteLink: HTMLElement = previewElement.querySelector('.dz-remove');
        if (deleteLink) {
            deleteLink.style.display = 'none';
            const embeddedView = this.vRef.createEmbeddedView(this.deleteButton);
            const newDeleteButton = embeddedView.rootNodes[0];
            deleteLink.eventListeners('click').forEach((e) => newDeleteButton.addEventListener('click', e));
            previewElement.appendChild(embeddedView.rootNodes[0]);
        }
    }
    private makeNameEditable(previewElement: HTMLElement, upload: { filename }) {
        const nameSpan: HTMLInputElement = previewElement.querySelector('[data-dz-name]');
        nameSpan.setAttribute('value', upload.filename);
        // nameSpan.setAttribute('contenteditable', 'true');
        nameSpan.addEventListener('input', (event: InputEvent) => {
            // upload.filename = nameSpan.textContent;
            upload.filename = nameSpan.value;
        });
    }
    public OnAddedFile(event: { previewElement: HTMLElement; upload: { filename: string } }) {
        this.PreviewElements.push(event.previewElement);
        event.previewElement && this.replaceDeleteLinkWithButton(event.previewElement);
        event.previewElement && this.makeNameEditable(event.previewElement, event.upload);
        this.FileCount = this.FileCount + 1;
        if (this.FileCount > 1) {
            this.FileForm.controls.Name.reset();
        }
    }

    public OnQueComplete() {
        this.quePending = false;
        this.UploadSuccess.emit();
        this.CloseViewEvent.emit();
    }
    removeProgressClass(previewElement: Element) {
        for (let node of previewElement.querySelectorAll('[data-dz-uploadprogress].indeterminate-progress')) {
            node.classList.remove('indeterminate-progress');
        }
    }

    public Save() {
        if (this.SynchronUpload) {
            this.Drpzone.ProcessUpload();
            firstValueFrom(this.Drpzone.dropzoneQueueComplete).then(() => {
                this.CloseViewEvent.emit();
            });
        } else {
            this.Drpzone.ProcessUploadAsync();
            this.CloseViewEvent.emit();
        }
    }
    public refreshUploadParams(/*values: typeof this.FileForm.value*/) {
        const values = this.FileForm.value;
        this.uploadParams.splice(0, this.uploadParams.length);
        if (this.EventId) {
            this.uploadParams['event_id'] = JSON.stringify([this.EventId]);
            this.uploadParams['auto_set_folder'] = !this.FolderId;
        }
        if (values.Customer?.Id) {
            this.uploadParams['customer_id'] = JSON.stringify([values.Customer.Id]);
        }
        if (values.Commission?.Id) {
            this.uploadParams['commission_id'] = JSON.stringify([values.Commission.Id]);
        }
        if (values.Transmission?.Id) {
            this.uploadParams['transmission_id'] = JSON.stringify([values.Transmission.Id]);
        }
        if (values.Tags?.length) {
            this.uploadParams['tag_id'] = JSON.stringify(values.Tags.map((t) => t.Id));
        }
        if (values.Name) {
            this.uploadParams['name'] = values.Name;
        }
        if (values.Description) {
            this.uploadParams['description'] = values.Description;
        }
        if (values.DocumentType || this.ConstructionDiaryId) {
            const metaData = {};
            if (values.DocumentType) {
                metaData['document_type'] = values.DocumentType;
            }
            if (this.ConstructionDiaryId) {
                metaData['constructionDiaryIds'] = [this.ConstructionDiaryId];
            }
            this.uploadParams['metadata'] = JSON.stringify(metaData);
        }
        if (this.FolderId) {
            this.uploadParams['folder_id'] = this.FolderId;
        }
        this.uploadParamsObj = Object.keys(this.uploadParams).reduce((prv, curr, i, ar) => {
            prv[curr] = this.uploadParams[curr];
            return prv;
        }, {});
    }
}
