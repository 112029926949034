import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter, map, scan, shareReplay, switchMap } from "rxjs/operators";
import { RouteLabelService } from '../dave-utils-module/dave-shared-components-module/services/route-label.service';
import { isNotNullOrUndefined, ProductName } from '../helper/helper';
import { combineLatest, Observable } from "rxjs";

export interface Breadcrumb {
    Label$: Observable<string>;
    Url: string;
    Previous?: Breadcrumb[];
    Icon?: string;
}

function getRoutes(route: ActivatedRouteSnapshot) {
    const routes: ActivatedRouteSnapshot[] = [];

    while (route.firstChild) {
        route = route.firstChild;
        routes.push(route);
    }

    return routes;
}

function positionInPreviousBreadcrumbs(
    oldBreadcrumbs: Breadcrumb[],
    currentBaseUrl: string,
    depth: number = 0,
): number {
    return oldBreadcrumbs[0].Url === currentBaseUrl
        ? depth
        : !oldBreadcrumbs[0].Previous
        ? -1
        : oldBreadcrumbs[1].Url === currentBaseUrl
        ? depth
        : positionInPreviousBreadcrumbs(oldBreadcrumbs[0].Previous, currentBaseUrl, depth + 1);
}

function mergeBreadcrumbs(oldBreadcrumbs: Breadcrumb[], newBreadcrumbs: Breadcrumb[]) {
    const currentBaseUrl: string = newBreadcrumbs[0].Url;

    let position = positionInPreviousBreadcrumbs(oldBreadcrumbs, currentBaseUrl);

    if (position === -1) {
        // wenn diese App noch nicht in den Breadcrumbs ist,
        // alle alten Breadcrumbs als Previous speichern
        newBreadcrumbs.unshift({
            Label$: oldBreadcrumbs[0].Label$,
            Url: oldBreadcrumbs[oldBreadcrumbs.length - 1].Url,
            Previous: oldBreadcrumbs.slice(),
            Icon: oldBreadcrumbs[0].Icon,
        });
    } else {
        // wenn diese App schon mal in den Breadcrumbs war,
        // zu entsprechender Position (in Previous-Arrays) gehen
        while (position) {
            oldBreadcrumbs = oldBreadcrumbs[0].Previous;
            position--;
        }
        if (oldBreadcrumbs[0].Previous) {
            newBreadcrumbs.unshift(oldBreadcrumbs[0]);
        }
    }

    return newBreadcrumbs;
}

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbsService {
    public Breadcrumbs$: Observable<Breadcrumb[]>;

    constructor(private router: Router, private routeLabelService: RouteLabelService, private title: Title) {
        this.Breadcrumbs$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            scan(oldBreadcrumbs => this.buildBreadcrumbs(oldBreadcrumbs), [] as Breadcrumb[]),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        // Seiteneffekt: Seitentitel aktualisieren
        this.Breadcrumbs$.pipe(switchMap(crumbs => combineLatest(crumbs.filter(breadcrumb => !breadcrumb.Previous).map(b => b.Label$)))).subscribe(breadcrumbLabels => {
            const path = breadcrumbLabels.join(' > ');

            this.title.setTitle(`${ProductName} | ${path || 'Dashboard'}`);
        });
    }

    private buildBreadcrumbs(oldBreadcrumbs: Breadcrumb[]) {
        const routes = getRoutes(this.router.routerState.root.snapshot);

        // build breadcrumbs
        let url = '';
        let breadcrumbs: Breadcrumb[] = routes
            .filter(route => route.url.length && route.data.breadcrumb !== 'none')
            .map(route => {
                const label = this.routeLabelService.GetLabelForRoute$(route);
                const icon: string = route.data.breadcrumbIcon;
                url += '/' + route.url.join();

                return {
                    Label$: label,
                    Icon: icon,
                    Url: url,
                };
            });

        // check for old routes
        if (oldBreadcrumbs.length && breadcrumbs.length) {
            breadcrumbs = mergeBreadcrumbs(oldBreadcrumbs, breadcrumbs);
        }

        return breadcrumbs;
    }
}
