import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, debounceTime, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { isNotNullOrUndefined, stringifyIfNotNullOrUndefined, stringifyIfNotUndefined } from "../../../helper/helper";
import {
    AccountsReceivableLedgerEntity,
    AccountsReceivableLedgerEntityFromBackend,
} from '../../entities/accounts-receivable-ledger.entity';
import { HttpService } from '../../services/http.service';
import { AccountsReceivableLedgerActionTypes, LedgerImportActionTypes } from '../actions/accounting.actions';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { EventsActionTypes } from '../actions/events.actions';
import { State } from '../index';
import { getAccountsReceivableLedgers } from '../selectors/accounting.selector';
import { getBusinessVolumeFetched } from '../selectors/business-volume.selector';
import { BusinessVolumeResolver } from '../../guards/business-volume.resolver';
import { getLedgerImportsFetched } from "../selectors/ledger-import.selector";
import { LedgerImportResolver } from "../../guards/ledger-import.resolver";
import { ArlDataService } from "../../services/arl-data.service";

enum ErrorCodes {
    Load = 'Buchungsposition Abrufen fehlgeschlagen',
    Remove = 'Buchungsposition Löschen fehlgeschlagen',
}

@Injectable()
export class AccountsReceivableLedgerEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
        private bvResolver: BusinessVolumeResolver,
        private liResolver: LedgerImportResolver,
        private arlDataService: ArlDataService,
    ) {}


    PollFolders$ = createEffect(() => this.actions$.pipe(
        ofType(EventsActionTypes.AddEvent, EventsActionTypes.ModifyEvent),
        debounceTime(2000),
        map(() => AccountsReceivableLedgerActionTypes.GetAccountsReceivableLedger()),
    ));

    GetAllAccountsReceivableLedgerQuery$ = createEffect(() => this.actions$.pipe(
        ofType(AccountsReceivableLedgerActionTypes.GetAccountsReceivableLedger),
        switchMap(() => {
            const queryString = `query {
          accountsReceivableLedger {${AccountsReceivableLedgerEntity.GqlFields.join(',')}}
        }
        `;
            return this.gatewayHttpService.graphQl({ query: queryString }, {retry: true}).pipe(
                map(res =>
                    res && res.accountsReceivableLedger
                        ? AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger({
                              Payload: res.accountsReceivableLedger.map(f =>
                                  AccountsReceivableLedgerEntityFromBackend(f),
                              ),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    AddAccountsReceivableLedger$ = createEffect(() => this.actions$.pipe(
        ofType(AccountsReceivableLedgerActionTypes.AddAccountsReceivableLedger),
        tap(({ Payload }) => {
            this.arlDataService.addArls([Payload], 100);
        }),
    ), { dispatch: false});


    ChangeAccountsReceivableLedger$ = createEffect(() => this.actions$.pipe(
        ofType(AccountsReceivableLedgerActionTypes.ChangeAccountsReceivableLedger),
        tap(({ Payload }) => {
            this.arlDataService.changeArls([Payload], 100);
        }),
    ), { dispatch: false});


    DeleteAccountsReceivableLedger$ = createEffect(() => this.actions$.pipe(
        ofType(AccountsReceivableLedgerActionTypes.DeleteAccountsReceivableLedger),
        concatMap(({ Payload }) => {
            const queryString = `mutation {
          deleteAccountsReceivableLedger(
    id: ${Payload.id}
          )
        }
        `;
            return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                withLatestFrom(this.store$.select(getAccountsReceivableLedgers), this.store$.select(getBusinessVolumeFetched)),
                tap(([,, bvFetched]) => {
                    if(bvFetched) {
                        this.bvResolver.resolve();
                    }
                }),
                concatMap(([res, bookingPositions]) =>
                    res && res.deleteAccountsReceivableLedger
                        ? [
                            LedgerImportActionTypes.GetLedgerImports({Payload: {}}),
                            AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger({
                              Payload: bookingPositions.filter(bp => bp.Id !== Payload.id),
                          })]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } })],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
}
