<ng-container *ngIf="PreviewedEmail$ | async as data; else noMail">
    <header class="card-header email-card-header">
        <div class="headline">
            <div class="text-with-expand">
                <h3 class="ellipsis content" #e>Betreff: {{ data.email.Subject }}</h3>
                <button mat-icon-button (click)="ToggleEllipsisClass(e)" *ngIf="!e.classList.contains('ellipsis') || IsEllipsisActive(e)">
                    <fa-icon [icon]="e.classList.contains('ellipsis') ? 'arrow-down' : 'arrow-up'"></fa-icon>
                </button>
            </div>
            <div *ngIf="data.email.SendDate">
                <h4>Erhalten am: {{ data.email.SendDate | date: 'dd.MM.yyyy HH:mm' }}</h4>
            </div>
            <div class="text-with-expand">
                <ng-template #FromEmailElse>{{ data.email.From }}</ng-template>
                <h4 class="ellipsis content" #e2>
                    Von:
                    <ng-container *ngIf="GetPersonId$(data.email.From) | async; let personId; else: FromEmailElse">
                        <a class="email-contact-link" [routerLink]="['/', ContactPath, personId]"> {{ data.email.From }} </a>
                    </ng-container>
                </h4>
                <button mat-icon-button (click)="ToggleEllipsisClass(e2)" *ngIf="!e2.classList.contains('ellipsis') || IsEllipsisActive(e2)">
                    <fa-icon [icon]="e2.classList.contains('ellipsis') ? 'arrow-down' : 'arrow-up'"></fa-icon>
                </button>
            </div>
            <div class="text-with-expand" *ngIf="data.email.To?.length">
                <h4 class="ellipsis content" #e3>
                    An:
                    <ng-container *ngFor="let email of data.email.To; last as isLast">
                        <ng-template #EmailToElse>{{ email }}</ng-template>
                        <ng-container *ngIf="GetPersonId$(email) | async; let personId; else: EmailToElse">
                            <a class="email-contact-link" [routerLink]="['/', ContactPath, personId]"> {{ email }} </a>
                        </ng-container>
                        <span *ngIf="!isLast">, </span>
                    </ng-container>
                </h4>
                <button mat-icon-button (click)="ToggleEllipsisClass(e3)" *ngIf="!e3.classList.contains('ellipsis') || IsEllipsisActive(e3)">
                    <fa-icon [icon]="e3.classList.contains('ellipsis') ? 'arrow-down' : 'arrow-up'"></fa-icon>
                </button>
            </div>
            <div class="text-with-expand" *ngIf="data.email.CarbonCopy?.length">
                <h4 class="ellipsis content" #e4>
                    CC:
                    <ng-container *ngFor="let email of data.email.CarbonCopy; last as isLast">
                        <ng-template #EmailCCElse>{{ email }}</ng-template>
                        <ng-container *ngIf="GetPersonId$(email) | async; let personId; else: EmailCCElse">
                            <a class="email-contact-link" [routerLink]="['/', ContactPath, personId]"> {{ email }} </a>
                        </ng-container>
                        <span *ngIf="!isLast">, </span>
                    </ng-container>
                </h4>
                <button mat-icon-button (click)="ToggleEllipsisClass(e4)" *ngIf="!e4.classList.contains('ellipsis') || IsEllipsisActive(e4)">
                    <fa-icon [icon]="e4.classList.contains('ellipsis') ? 'arrow-down' : 'arrow-up'"></fa-icon>
                </button>
            </div>
            <div class="text-with-expand" *ngIf="data.email.BlindCarbonCopy?.length">
                <h4 class="ellipsis content" #e5>
                    BCC:
                    <ng-container *ngFor="let email of data.email.BlindCarbonCopy; last as isLast">
                        <ng-template #EmailBCCElse>{{ email }}</ng-template>
                        <ng-container *ngIf="GetPersonId$(email) | async; let personId; else: EmailBCCElse">
                            <a class="email-contact-link" [routerLink]="['/', ContactPath, personId]"> {{ email }} </a>
                        </ng-container>
                        <span *ngIf="!isLast">, </span>
                    </ng-container>
                </h4>
                <button mat-icon-button (click)="ToggleEllipsisClass(e5)" *ngIf="!e5.classList.contains('ellipsis') || IsEllipsisActive(e5)">
                    <fa-icon [icon]="e5.classList.contains('ellipsis') ? 'arrow-down' : 'arrow-up'"></fa-icon>
                </button>
            </div>
        </div>

        <div class="buttons">
            <div class="admin-button-wrapper link-wrapper" *ngIf="data.email?.CustomerId">
                <a app-button Lighter class="wide-button" matTooltip="Kunde" [routerLink]="['/', CustomerPath, data.email.CustomerId]">
                    <fa-icon [icon]="CustomerAdministrationMeta.Icon"></fa-icon>
                </a>
            </div>
            <div class="history-button-wrapper link-wrapper" *ngIf="data.event?.Id && PS.Has(PS.Permission.GetEvents) | async">
                <a app-button Lighter class="wide-button" matTooltip="Chronik" [routerLink]="['/', ChronikPath, data.event?.CustomerId ? data.event.CustomerId : 'alle', data.event.Id]">
                    <fa-icon [icon]="HistoryMeta.Icon"></fa-icon>
                </a>
            </div>
            <div class="commission-button-wrapper link-wrapper" *ngIf="data.canOpenCommission && PS.Has(PS.Permission.GetCommission) | async">
                <a app-button Lighter class="wide-button" [matTooltip]="cls.getMultiple$('Commission') | async" [routerLink]="['/', CommissionPath, data.email?.CustomerId || AllCommissionPath, data.email.CommissionId]">
                    <fa-icon [icon]="CommissionMeta.Icon"></fa-icon>
                </a>
            </div>
            <div *ngIf="(emailTasks$|async).length" class="email-tasks">
                <div  class="history-button-wrapper link-wrapper" *ngFor="let tasks of emailTasks$ | async">
                    <button app-button Lighter class="wide-button" style="min-width: 3.5rem" matTooltip="Aufgabe bearbeiten" (click)="EditTaskDialog(tasks.Id)">
                        <fa-icon [icon]="TaskMeta.Icon"></fa-icon>
                        <fa-icon icon="pencil-alt" size="xs"></fa-icon>
                    </button>
                </div>
            </div>
<!--            <button class="answer-button" app-round-button matTooltipPosition="left" matTooltip="Aufgabe bearbeiten" *ngIf="data.task" (click)="EditTaskDialog(data.task.Id)">-->
<!--                <app-double-icon [Icon]="TaskMeta.Icon" Icon2="pencil-alt"></app-double-icon>-->
<!--            </button>-->
            <button class="answer-button" app-round-button matTooltipPosition="below" matTooltip="Zuordnung bearbeiten" *ngIf="data.event" (click)="editEvent()">
                <div class="icon-mutiple">
                    <fa-icon [icon]="HistoryMeta.Icon" size="xs"></fa-icon>
                    <fa-icon icon="pencil-alt" size="xs"></fa-icon>
                </div>
            </button>
            <button
                class="answer-button"
                app-round-button
                matTooltipPosition="below"
                [class.unseen]="!data.email.Seen"
                [Inverted]="data.email.Seen"
                [matTooltip]="data.email.Seen ? 'Auf ungelesen setzen' : 'Auf gelesen setzen'"
                (click)="toggleSeen()"
            >
                <fa-icon [icon]="data.email.Seen ? 'eye-slash' : 'eye'"></fa-icon>
            </button>
            <button class="answer-button" app-round-button matTooltipPosition="below" matTooltip="Anhänge" [matMenuTriggerFor]="documents" *ngIf="data.documentsShow.length">
                <fa-icon icon="paperclip"></fa-icon>
            </button>
            <mat-menu #documents="matMenu">
                <app-mat-menu-header class="file-menu-header mat-elevation-z2" (click)="$event.stopPropagation()">
                    <mat-checkbox
                        (change)="fileMultiselectSetAll($event.checked, data.documentsShow)"
                        [checked]="selectedFiles.length === data.documentsShow.length"
                        [indeterminate]="selectedFiles.length > 0 && selectedFiles.length < data.documentsShow.length"
                    ></mat-checkbox>
                    <span class="spacer"></span>
<!--                    <button mat-icon-button [disabled]="selectedFiles.length === 0" matTooltip="zu Prozess hinzufügen" (click)="addFilesToProcess()" ><fa-icon [icon]="ProcessMeta.Icon"></fa-icon></button>-->
                    <button mat-icon-button [disabled]="selectedFiles.length === 0" matTooltip="Prozess" aria-label="Prozess" [matMenuTriggerFor]="processMenu">
                        <fa-icon [icon]="ProcessMeta.Icon"></fa-icon>
                    </button>
                    <button mat-icon-button [disabled]="selectedFiles.length === 0" matTooltip="verschieben" (click)="moveFiles()" ><fa-icon icon="share"></fa-icon></button>
                    <button mat-icon-button [disabled]="selectedFiles.length === 0" matTooltip="per E-Mail senden" (click)="sendFiles()" ><fa-icon icon="paper-plane"></fa-icon></button>
<!--                    <button app-round-button (click)="sendFiles()" [Disabled]="selectedFiles.length === 0"><fa-icon icon="paper-plane"></fa-icon></button>-->
                </app-mat-menu-header>
                <button mat-menu-item *ngFor="let doc of data.documentsShow" (click)="OpenDocumentPreview(doc.Id)">
                    <mat-checkbox [checked]="selectedFiles | includes: doc.Id" (click)="$event.stopPropagation(); $event.preventDefault(); fileMultiselectToggle(doc.Id)"></mat-checkbox>
                    &nbsp;
                    <span>{{ doc.Name }}</span>
                </button>
            </mat-menu>
            <button class="answer-button" app-round-button matTooltipPosition="below" matTooltip="Ereignis Erstellen" *ngIf="!data.event && data.email?.SendDate && (PS.Has(PS.Permission.CreateEvents) | async)" (click)="addEvent()">
                <div class="icon-mutiple">
                    <fa-icon [icon]="HistoryMeta.Icon" size="xs"></fa-icon>
                    <fa-icon icon="plus" size="xs"></fa-icon>
                </div>
            </button>
            <button
                class="answer-button"
                app-round-button
                matTooltipPosition="left"
                matTooltip="Aufgabe Erstellen"
                *ngIf="data.email?.SendDate && (PS.Has(PS.Permission.CreateEvents) | async)"
                (click)="CreateTaskDialog(data.email)"
            >
                <div class="icon-mutiple">
                    <fa-icon [icon]="TaskMeta.Icon" size="xs"></fa-icon>
                    <fa-icon icon="plus" size="xs"></fa-icon>
                </div>
            </button>
            <button *ngIf="!data.isDraft" class="answer-button" app-round-button matTooltipPosition="below" matTooltip="Auf E-Mail antworten" (click)="answerEmail(data.email)">
                <fa-icon icon="reply"></fa-icon>
            </button>
            <button *ngIf="data.isDraft" class="answer-button" app-round-button matTooltipPosition="left" matTooltip="Bearbeiten" (click)="editEmail(data.email)">
                <fa-icon icon="pencil-alt"></fa-icon>
            </button>
            <button class="answer-button" app-round-button matTooltipPosition="below" matTooltip="Allen antworten" (click)="AnswerAll$.next(data.email)" *ngIf="!data.isDraft">
                <fa-icon icon="reply-all"></fa-icon>
            </button>
            <button class="answer-button" app-round-button matTooltipPosition="left" matTooltip="Weiterleiten" (click)="forwardEmail(data.email)" *ngIf="!data.isDraft">
                <fa-icon icon="share"></fa-icon>
            </button>
            <button class="answer-button" app-round-button matTooltipPosition="left" matTooltip="Löschen" Color="red" [Disabled]="!!data.email.DeletedAt" (click)="DeleteEmail(data.email)">
                <fa-icon icon="trash"></fa-icon>
            </button>
        </div>
    </header>
    <app-email-view [Email]="data.email"></app-email-view>
</ng-container>
<ng-template #noMail>
    <div class="center-content">
        <p *ngIf="!(emailId$ | async)">Bitte wählen sie eine E-Mail aus</p>
        <app-loading *ngIf="emailId$ | async"></app-loading>
    </div>
</ng-template>

<mat-menu #processMenu="matMenu">
    <button mat-menu-item (click)="addFilesToProcess()">
        <fa-icon class="mat-icon" icon="pencil-alt"></fa-icon>
        Zu Prozess hinzufügen</button>
    <button mat-menu-item (click)="onStartProcessClick()">
        <fa-icon class="mat-icon" icon="plus"></fa-icon>
        Prozess mit Dokument starten</button>
</mat-menu>
