import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    EMPLOYEE_RESOURCE_SCHEDULE_KEY,
    EmployeeResourceScheduleState,
    selectAllEmployeeResourceSchedule,
    selectEmployeeResourceScheduleEntities,
    selectEmployeeResourceScheduleFetched,
    selectEmployeeResourceScheduleLatestUpdatedAt,
} from '../../reducers/resource-dispo/employee-resource-schedule.reducer';

const selectState = createFeatureSelector<EmployeeResourceScheduleState>(EMPLOYEE_RESOURCE_SCHEDULE_KEY);

export const getEmployeeResourceScheduleDictionary = createSelector(selectState, selectEmployeeResourceScheduleEntities);
export const getEmployeeResourceSchedules = createSelector(selectState, selectAllEmployeeResourceSchedule);
export const getEmployeeResourceSchedulesFetched = createSelector(selectState, selectEmployeeResourceScheduleFetched);
export const getEmployeeResourceScheduleLatestUpdatedAt = createSelector(selectState, selectEmployeeResourceScheduleLatestUpdatedAt);

export const getEmployeeResourceScheduleById = (props: { id: number }) => createSelector(getEmployeeResourceScheduleDictionary, (dictionary) => props?.id && dictionary[props.id]);
