import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { appMatDialogDefaultConfig } from '../../../helper/helper';
import { ResourceDispoModule } from '../../resource-dispo.module';
import { SelectMaterialComponent } from '../select-material/select-material.component';
import { LoadingService } from '../../../services/loading.service';

export interface SelectMaterialPopupComponentDialogData {
    CommissionId: number;
    date?: Date;
}

@Component({
    selector: 'app-select-material-popup',
    standalone: true,
    imports: [CommonModule, MatDialogModule, SelectMaterialComponent, AppButtonModule, ResourceDispoModule],
    templateUrl: './select-material-popup.component.html',
    styleUrls: ['./select-material-popup.component.scss'],
})
export class SelectMaterialPopupComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        minWidth: '50vw',
        maxWidth: '75vw',
        maxHeight: 'calc(100vh - 3.5rem)',
        closeOnNavigation: true,
    };
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: SelectMaterialPopupComponentDialogData,
        protected ls: LoadingService
    ) {}
}
