import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAllStatusFromBackoffice, selectStatusFromBackofficeEntities, selectStatusFromBackofficeFetched, StatusFromBackofficeFeatureKey, StatusFromBackofficeState } from '../reducers/statusFromBackoffice.reducer';

const selectStatusFromBackoffice = createFeatureSelector<StatusFromBackofficeState>(StatusFromBackofficeFeatureKey);
const getStatusFromBackofficeEntitys = createSelector(selectStatusFromBackoffice, selectStatusFromBackofficeEntities);

export const getStatusFromBackoffice = createSelector(selectStatusFromBackoffice, selectAllStatusFromBackoffice);
export const getStatusFromBackofficeFetched = createSelector(selectStatusFromBackoffice, selectStatusFromBackofficeFetched);
export const getStatusFromBackofficeDictionary = getStatusFromBackofficeEntitys;
export const getStatusFromBackofficeById = (props: { id: number }) => createSelector(getStatusFromBackofficeDictionary, (dictionary) => props.id && dictionary[props.id]);
