import { createAction, props } from '@ngrx/store';
import { WorkedTimesEntity } from '../../entities/worked-times.entity';
import { QueryWorkedtimesArgs } from '../../graphql-types';

export const WorkedTimesActionTypes = {
    Load: createAction('data/worked-times/get', props<{ Payload?: QueryWorkedtimesArgs }>()),
    UpdateAll: createAction('data/worked-times/updateAll', props<{ Payload: WorkedTimesEntity[], updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/worked-times/updateMany', props<{ Payload: WorkedTimesEntity[], updateLatestUpdatedAt?: boolean }>()),
    RemoveMany: createAction('data/worked-times/removeMany', props<{ Payload: number[] }>()),
};
