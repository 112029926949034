import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { Moment } from 'moment/moment';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject } from 'rxjs';

export interface ISelectedElement {
    label: string;
}
type selectContentType<T extends { id: number | string }> = {
    __typename: 'select';
    options: T[];
    optionLabel: (option: T) => string;
    formControl: FormControl<number | string | null>;
};
type timespanContentType = {
    __typename: 'timespan';
    formGroup: FormGroup<{ from: FormControl<Moment>; to: FormControl<Moment> }>;
};
type booleanContentType = {
    __typename: 'boolean';
    formControl: FormControl<boolean>;
};
type stringContentType = {
    __typename: 'string';
    content: string;
};
type customContentType = {
    __typename: 'custom';
};

type ITableData = { [key: string]: TableContentType<any> } & { selectedForm: FormControl<boolean>; cssClass?: string | string[] | Set<string> | { [klass: string]: any; }};

export type TableContentType<T extends { id: string | number }> = stringContentType | booleanContentType | selectContentType<T> | timespanContentType | customContentType;
export interface ITableConfig {
    header: string;
    sortAccessor?: (cell: TableContentType<any>) => string | number;
    id: string;
    width?: string;
    customCellTemplate?: TemplateRef<any>;
}
@Component({
    selector: 'app-multi-select-table',
    templateUrl: './multi-select-table.component.html',
    styleUrls: ['./multi-select-table.component.scss'],
})
export class MultiSelectTableComponent implements AfterViewInit {
    @Input() CustomChipTemplate: TemplateRef<any>;
    @Input() Headline: string = '';
    @Input() SelectedHeadline: string = '';
    @Input() EmptyText: string = '';
    @Input() SearchPlaceHolder: string = 'suchen ...';
    @Input() Selected: ISelectedElement[];
    @Output() RemoveSelected = new EventEmitter<ISelectedElement>();
    @Output() ClickSelected = new EventEmitter<ISelectedElement>();
    @Output() SearchStringChange = new EventEmitter<string | null>();
    @Output() RowClick = new EventEmitter<ITableData>();

    @Input() set TableDataConfig(data: ITableConfig[]) {
        this.TableDataConfig$.next(data);
        this.Columns = [this.CheckBoxColumnName, ...data.map((d) => d.id)];
        this.dataSource.sortingDataAccessor = (object, key) => data.find((d) => d.id === key && d.sortAccessor)?.sortAccessor(object[key]) || '';
    }

    @Input() set TableData(data: ITableData[]) {
        this.dataSource.data = data;
    }

    /** Der `MatSort` der Tabelle */
    @ViewChild(MatSort) private matSort?: MatSort;
    public CheckBoxColumnName = '__isSelected';
    public Columns = [this.CheckBoxColumnName];
    public TableDataConfig$ = new BehaviorSubject<ITableConfig[]>([]);
    public Multiselect = new BehaviorSubject(false);

    public dataSource = new TableVirtualScrollDataSource<ITableData>([]);

    constructor() {}

    ngAfterViewInit(): void {
        this.dataSource.sort = this.matSort;
    }
}
