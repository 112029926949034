import { SalaryTypeType } from '../graphql-types';
import { BackendDate, BackendDateTimestamp } from "../helper/backend-frontend-conversion.helper";

export class SalaryTypeEntity {
    constructor(
        public Amount: number = null,
        public Bearbeitungsschluessel: number = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public Description: string = null,
        public Id: number = null,
        public Name: string = null,
        // PartnerId: = null,
        public PercentAmountFromSalary: number = null,
        public SalaryTypeNumber: number = null,
        public UpdatedAt: Date = null,
        // UserId: = null,
    ) {}
    public static readonly GqlFields: Array<keyof SalaryTypeType> = [
        'amount',
        'bearbeitungsschluessel',
        'createdAt',
        'deletedAt',
        'description',
        'id',
        'name',
        // 'partnerId',
        'percentAmountFromSalary',
        'salaryTypeNumber',
        'updatedAt',
        // 'userId',
    ];
    public static EntityName = 'Lohnart';
    public static EntityPropertyNames: Map<keyof SalaryTypeEntity, string> = new Map([
        ['Amount', 'Wert'],
        ['Bearbeitungsschluessel', 'Bearbeitungsschlüssel'],
        ['CreatedAt', 'erstellt am'],
        ['DeletedAt', 'gelöscht am'],
        ['Description', 'Kommentar'],
        ['Name', 'Name'],
        ['PercentAmountFromSalary', 'Prozentualer Wert'],
        ['SalaryTypeNumber', 'Lohnartennummer'],
        ['UpdatedAt', 'aktualisiert am'],
    ]);
    public Clone(override: Partial<SalaryTypeEntity> = {}): SalaryTypeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new SalaryTypeEntity(), attr);
    }
}
export function SalaryTypeEntityFromBackend(res: SalaryTypeType): SalaryTypeEntity {
    return new SalaryTypeEntity(
        res.amount,
        res.bearbeitungsschluessel,
        res.createdAt && BackendDate(res.createdAt),
        res.deletedAt && BackendDate(res.deletedAt),
        res.description,
        res.id,
        res.name,
        res.percentAmountFromSalary,
        res.salaryTypeNumber,
        res.updatedAt && BackendDate(res.updatedAt),
    );
}
