import { Component, HostBinding, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { getErrorMessage } from '../../../helper/validation.helper';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent {
    @Input() Key = '';
    @Input() Value: UntypedFormControl;
    @Input() Required = false;
    /**
     * deprecated
     */
    @Input() Fill = false;
    @Input() MaxHeight = '';
    protected minRows = 1;
    @Input() set MinRows(rows: number) {
        if (rows) {
            this.minRows = rows;
        }
    }

    @HostBinding('class.fill') get fill() {
        return this.Fill;
    }
    public GetErrorMessage = getErrorMessage;
}
