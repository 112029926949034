import { Injectable } from '@angular/core';
import { BaseEntityReloadResolver2, BaseEntityResolver } from '../resolver.helper';
import { Store } from '@ngrx/store';
import { State } from '../../State';
import {
    getResourceToCommissionFetched, getResourceToCommissionLatestUpdatedAt,
} from '../../State/selectors/resource-dispo/resource-to-commission.selectors';
import { ResourceToCommissionsActionTypes } from '../../State/actions/resource-dispo/resource-to-commission.actions';


@Injectable({
    providedIn: 'root',
})
export class ResourceToCommissionResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getResourceToCommissionFetched, getResourceToCommissionLatestUpdatedAt, ResourceToCommissionsActionTypes.Load, store);
    }
}
