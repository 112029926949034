import {
    DaveMutationChangeDetectionArgs,
} from '../../graphql-types';
import { createAction, props } from '@ngrx/store';
import { DetectionEntity } from '../../entities/detection.entity';

export const DetectionActionTypes = {
    UpdateDetections: createAction(
        'data/DocumentDetection/update',
        props<{ Payload: Array<DetectionEntity> }>(),
    ),
    ModifyDetection: createAction(
        'data/DocumentDetection/modify',
        props<{ Payload: DaveMutationChangeDetectionArgs }>(),
    ),
    RemoveDetection: createAction('data/DocumentDetection/remove', props<{ Payload: number }>()),
};
