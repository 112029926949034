import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {DaveActions} from '../actions/actions';
import {ResolverActionTypes} from '../actions/resolver.actions';
import {HttpService} from '../../services/http.service';
import {State} from '../index';
import {VarianteTypeEntityFromBackend} from '../../entities/varianteType.entity';
import {VarianteTypesActionTypes} from '../actions/variante-types.actions';
import {BaseActionTypes} from '../actions/base.actions';

enum ErrorCodes {
    Load = 'Varianten Abrufen fehlgeschlagen'
}

@Injectable()
export class VarianteTypesEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService
    ) {
    }


    LoadVarianteTypes$ = createEffect(() => this.actions$.pipe(
        ofType(ResolverActionTypes.LoadVarianteTypes),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
            query{
              varianteType{
                canBeBookedByUser
                discountInPercent
                id
                timePeriodInMonth
                partnerTypeId
                productId
              }
            }`;
            return this.gatewayHttpService
                .graphQl({query: queryString}, {token: store.base.token,  retry: true })
                .pipe(
                    map(res =>
                        res && res.varianteType
                            ? VarianteTypesActionTypes.UpdateVarianteTypes({
                                Payload: res.varianteType.map(val => VarianteTypeEntityFromBackend(val))
                            })
                            : BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Load}})
                    ),
                    catchError((err, caught) =>
                        of(BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            }
                        }))
                    )
                );
        })
    ));
}
