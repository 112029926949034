import { NgModule } from '@angular/core';
import { FileSizePipe } from './filesize.pipe';
import { AddQuarterMarkPipe } from './addQuartermark.pipe';
import { SafeHtmlPipe } from './safehtml.pipe';
import { UnsafeHtmlPipe } from './unsafehtml.pipe';
import { CustomDayPipe } from './custom-day.pipe';

@NgModule({
    declarations: [FileSizePipe, AddQuarterMarkPipe, SafeHtmlPipe, UnsafeHtmlPipe, CustomDayPipe],
    exports: [FileSizePipe, AddQuarterMarkPipe, SafeHtmlPipe, UnsafeHtmlPipe, CustomDayPipe],
})
export class PipesModule {}
