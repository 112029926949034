<header *ngIf="DialogData">
    <h1 mat-dialog-title>{{Headline$ | async}}</h1>
    <span class="spacer"></span>
    <button mat-icon-button matDialogClose matTooltip="Schließen">
        <fa-icon icon="times" size="2x"></fa-icon>
    </button>
</header>
<mat-horizontal-stepper linear #Stepper [class.mat-dialog-content]="!!DialogData">
    <ng-container *ngIf="Steps$ | async as steps">
        <mat-step *ngFor="let step of steps; let i = index" [stepControl]="step.controls">
            <ng-template matStepLabel>{{ step.name }}</ng-template>
            <app-detail-list-template *ngIf="step.data" [Inline]="true" [Data]="step.data | async" [Editing]="true"> </app-detail-list-template>
            <ng-container [ngTemplateOutlet]="step.customTemplate"></ng-container>
        </mat-step>
    </ng-container>
    <ng-template matStepperIcon="edit">
        <fa-icon icon="pencil-alt"></fa-icon>
    </ng-template>
    <ng-template matStepperIcon="done">
        <fa-icon icon="check"></fa-icon>
    </ng-template>
    <ng-template matStepperIcon="error">
        <fa-icon icon="exclamation-triangle"></fa-icon>
    </ng-template>
</mat-horizontal-stepper>
<div class="buttons" [class.mat-dialog-actions]="!!DialogData" *ngIf="Steps$ | async as Steps">
    <a *ngIf="DialogData && Steps && Stepper.selectedIndex === Steps.length - 1"
        [routerLink]="['/', CommissionPath, 'alle', CommissionId]"
       app-button
       [Disabled]="Steps[Stepper.selectedIndex].controls?.invalid"
       matTooltip="Auftrag speichern & anzeigen"
       (click)=" NextClick(Steps, true);"
    >
        Speichern & Details
    </a>
    <button *ngIf="Steps"
        app-button
        [Disabled]="Steps[Stepper.selectedIndex].controls?.invalid"
        (click)=" NextClick(Steps);"
    >
        {{ Stepper.selectedIndex === Steps.length - 1 ? 'Speichern' : 'Weiter' }}
    </button>
    <button *ngIf="Stepper.selectedIndex" style="margin-right: auto;" app-button Inverted (click)="Stepper.previous()">
        Zurück
    </button>
    <button *ngIf="DialogData && Stepper.selectedIndex === 0" style="margin-right: auto;" app-button Inverted mat-dialog-close="" Color="cancel">
        Abbrechen
    </button>
</div>
<ng-template #selectTeamTemplate>
    <div class="ressourceTemplate" *ngIf="CommissionId$ | async as commissionId">
        <app-select-user-commission-dialog #selectUserCommissionDialogComponent [PreventDialog]="true" [ComponentData]="{commissionId}"></app-select-user-commission-dialog>
        <button class="ressourceSaveButton" app-button (click)="selectUserCommissionDialogComponent.Submit()" [IsLoading]="selectUserCommissionDialogComponent.LS.IsLoading$ | async" Color="green">Speichern</button>
    </div>
</ng-template>

<ng-template #resourceTemplate>
    <div class="ressourceTemplate">
        <app-select-resource #selectResourceComponent [ComponentData]="{CommissionId: CommissionId}"></app-select-resource>
        <button class="ressourceSaveButton" app-button (click)="selectResourceComponent.Save()">Ressourcen zuweisen</button>
    </div>
</ng-template>

<ng-template #capacityTemplate>
    <div class="capacityTemplate">
        <div class="headline">
            <h2>Bedarf</h2>
        </div>
        <div class="capacity-row" *ngFor="let er of EmployeeResources; let f = last; let i = index">
            <mat-form-field appearance="fill" style="width: 13rem;">
                <mat-label>Zeitraum</mat-label>
                <mat-date-range-input
                    [formGroup]="er.form"
                    [rangePicker]="timespanPicker">
                    <input matStartDate placeholder="Von" formControlName="from">
                    <input matEndDate placeholder="Bis" formControlName="to">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="timespanPicker"></mat-datepicker-toggle>
                <mat-date-range-picker #timespanPicker></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field  appearance="fill" style="width: 10rem;">
                <mat-label>Anzahl Mitarbeiter</mat-label>
                <input matInput type="number" min="0" [formControl]="er.form.controls.amount">
            </mat-form-field>
            <mat-form-field appearance="fill" style="width: 11rem;">
                <mat-label>
                    Jobbezeichnung
                </mat-label>
                <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer;" (click)="er.form.controls.jobSpecification.setValue(null);onEmployeeResourceJobSelectionChange(er.form);$event.stopPropagation();" *ngIf="!er.form.controls.jobSpecification.disabled"></fa-icon>
                <app-select-search
                    [required]="HasRequiredField(er.form.controls.jobSpecification)"
                    [formControl]="er.form.controls.jobSpecification"
                    [Options]="JobSpecifications$ | async"
                    [SearchFunction]="SearchJobSpecification"
                    [OptionTemplate]="jobSpecificationOptionTemplate"
                    (selectionChange)="onEmployeeResourceJobSelectionChange(er.form)"
                ></app-select-search>
            </mat-form-field>
            <ng-template #jobSpecificationOptionTemplate let-option>
                <app-job-specification-option [JobSpecificationEntity]="option"></app-job-specification-option>
            </ng-template>
            <app-chip-autocomplete
                appearance="fill"
                style="min-width: 20rem;"
                [formControl]="er.form.controls.activities"
                [MapFn]="activityToLabel"
                [Options]="er.availableActivities$ | async"
                Placeholder="Tätigkeiten"
                (UnknownOptionSubmitted)="addNewActivity($event, er.form.controls.activities)"
                [OptionTemplate]="activityOptionTemplate"
            ></app-chip-autocomplete>
            <ng-template #activityOptionTemplate let-Option>
                <div style="display: flex; justify-content: space-between; align-items: center">
                    {{Option.Name}} <button *ngIf="!Option.DeletedAt" mat-icon-button (mousedown)="$event.stopPropagation()" (click)="$event.stopPropagation(); DeleteActivity(Option);"><fa-icon icon="trash"></fa-icon></button>
                </div>
            </ng-template>

            <mat-form-field appearance="fill" style="width: 10rem;">
                <mat-label>Name</mat-label>
                <input matInput type="text" [formControl]="er.form.controls.name">
            </mat-form-field>
            <mat-form-field appearance="fill" style="width: 11rem;">
                <mat-label>Farbe</mat-label>
                <input matInput [ngxMatColorPicker]="picker" [formControl]="er.form.controls.color">
                <app-mat-color-picker-toggle matSuffix [for]="picker"></app-mat-color-picker-toggle>
                <app-mat-color-preview matPrefix [for]="picker"></app-mat-color-preview>
                <ngx-mat-color-picker #picker [disabled]="er.form.controls.color.disabled" [touchUi]="BS.TouchQuery | async" [color]="'primary'"></ngx-mat-color-picker>
            </mat-form-field>
            <div class="buttonSpacer">
                <button app-round-button *ngIf="f" (click)="AddResourceEmployee()"><fa-icon icon="plus"></fa-icon></button>
            </div>
            <button mat-icon-button (click)="RemoveResourceEmployee(i)"><fa-icon icon="trash"></fa-icon></button>
        </div>
    </div>
</ng-template>
<ng-template #addressTemplate>
    <app-detail-list-template [Inline]="true" [Data]="{Properties: [DetailListTemplateEntrys.Location]}" [Editing]="true"> </app-detail-list-template>
</ng-template>
<ng-template #pageOne>
    <ng-container *ngIf="parentCommission$ | async">
        <label id="example-radio-group-label">Adresse übernehmen von:</label>
        <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            [ngModel]="addressFrom"
            (ngModelChange)="onAddressFromChange($event)"
        >
            <mat-radio-button class="example-radio-button" *ngFor="let season of addressFromValues$ | async" [value]="season.value">
                {{season.label}}
            </mat-radio-button>
        </mat-radio-group>
    </ng-container>
</ng-template>
