import { GetTimeFromTimestamp } from '@dave/types';
import { ProcessStep } from '@dave/types/dist/proto/process/process';
export enum ProcessStepState {
    ProcessStepStateNotDone = "ProcessStepStateNotDone",
    ProcessStepStateDone = "ProcessStepStateDone",
    ProcessStepStateCanceled = "ProcessStepStateCanceled"
}
export class ProcessStepEntity {
    constructor(public Deadline: Date = null, public Name: string = null, public TaskIds: number[] = null, public TaskId: number = null, public State: ProcessStepState = null) {}
    public Clone(override: Partial<ProcessStepEntity> = {}): ProcessStepEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ProcessStepEntity(), attr);
    }
}
export function ProcessStepEntityFromBackend(res: ProcessStep): ProcessStepEntity {
    return new ProcessStepEntity(res.Deadline && GetTimeFromTimestamp(+res.Deadline), res.Name, res.TaskIds && res.TaskIds.map(id => parseInt(id)), res.TaskId && +res.TaskId, res.State);
}
