import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Store} from '@ngrx/store';
import {filter, take} from 'rxjs/operators';
import { State } from '../State';
import { getToken } from '../State/selectors/base.selectors';

@Injectable({
    providedIn: 'root'
})
export class TokenResolver implements Resolve<string> {
    constructor(private store: Store<State>) {}

    resolve() {
        return this.store
            .select(getToken)
            .pipe(
                filter(value => !!value),
                take(1)
            );
    }
}
