import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuPanel } from '@angular/material/menu';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ITaskBoardSort } from 'src/app/dave-tasks/components/tasks-board/tasks-board.component';
import { HistoryMeta, TaskPageMeta } from 'src/app/helper/page-metadata';
import { BookmarkEntity } from '../../../../dave-data-module/entities/bookmark.entity';
import { EmailEntity } from '../../../../dave-data-module/entities/email.entity';
import { EventTypeEntity, EventTypeNamesEnum } from '../../../../dave-data-module/entities/event-type.entity';
import { EventEntity } from '../../../../dave-data-module/entities/event.entity';
import { PersonEntity } from '../../../../dave-data-module/entities/person.entity';
import { UserEntity } from '../../../../dave-data-module/entities/user.entity';
import { ViewStyleConfig } from '../../../../dave-data-module/entities/viewStyleSetting.entity';
import { Person2EntityResolver } from '../../../../dave-data-module/guards/person2Entity.resolver';
import { ProcessResolver } from '../../../../dave-data-module/guards/process.resolver';
import { State } from '../../../../dave-data-module/State';
import { BookmarkActionTypes } from '../../../../dave-data-module/State/actions/bookmarks.actions';
import { EventsActionTypes } from '../../../../dave-data-module/State/actions/events.actions';
import { getBookmarkFetched, getBookmarks } from '../../../../dave-data-module/State/selectors/bookmarks.selectors';
import { getCommissionsActive } from '../../../../dave-data-module/State/selectors/commission.selector';
import { getEmailDictionary } from '../../../../dave-data-module/State/selectors/emails.selectors';
import { getEventTypeFetched, getEventTypes, getEventTypesDictionary } from '../../../../dave-data-module/State/selectors/event-type.selector';
import { getEventFetched, getEvents, getTasks } from '../../../../dave-data-module/State/selectors/events.selectors';
import { getPersonDictionary, getPersons } from '../../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2EntitiesFetched, getPerson2EntityEventByPersonId } from '../../../../dave-data-module/State/selectors/person2entity.selectors';
import { getProcesses, getProcessFetched } from '../../../../dave-data-module/State/selectors/process.selector';
import { getUser, getUsers } from '../../../../dave-data-module/State/selectors/users.selectors';
import { FilterOption } from '../../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { FilterCardComponent } from '../../../../dave-utils-module/app-filter-module/filter-card/filter-card.component';
import { clacEventFilterAmount, eventFilterTypes, filterBookmarks, filterEvents, getEventFilterValues, getFilterSettings, IEventFilterType } from '../../../../helper/event.helper';
import { DaveHeaderHeight, stringSearch } from '../../../../helper/helper';
import { CustomLabelService } from '../../../../services/custom-label.service';
import { FilterTypes } from '../../../../services/default-filter.service';

interface TabData {
    icon: IconProp;
    searchLabel: string;
    listDataSource$: Observable<Array<EventEntity | BookmarkEntity>>;
    unseenCount$?: Observable<number>;
    searchForm?: FormControl<string>;
    headerButtons?: {
        checkAction?: () => void;
        checkLabel?: string;
        checkIcon?: IconProp;
        disableCheck$?: Observable<boolean>;
        badge$?: Observable<number | null>;
        matMenu?: MatMenuPanel | null;
    }[];
}

const filterUserByEvents = (events: EventEntity[], users: UserEntity[]): UserEntity[] => users.filter((user) => events.some((event) => event.UserIds.includes(user.Id)));
const filterAuthorsByEvents = (events: EventEntity[], users: UserEntity[]): UserEntity[] => users.filter((user) => events?.some((event) => event?.UserId === user.Id));
const filterAnsprechpartnerByEvents = (events: EventEntity[], persons: PersonEntity[]): PersonEntity[] => persons.filter((person) => events.some((event) => event.PersonId === person.Id));

const getSearchStrings = (event: EventEntity, eventTypes: Dictionary<EventTypeEntity>, emails: Dictionary<EmailEntity>, persons: Dictionary<PersonEntity>) => {
    const person = event.PersonId && persons[event.PersonId];
    return [event.Name, event.Description, event.From, event.EmailId && emails[event.EmailId]?.Subject, event.EventTypeId && eventTypes[event.EventTypeId]?.Name, person?.Firstname, person?.Lastname].filter((v) => v);
};

@Component({
    selector: 'app-detail-commission-event-list',
    templateUrl: './detail-commission-event-list.component.html',
    styleUrls: ['./detail-commission-event-list.component.scss'],
})
export class DetailCommissionEventListComponent implements AfterViewInit, OnInit, OnDestroy {
    HeaderHeight = DaveHeaderHeight;

    @Input() View: ViewStyleConfig;

    @Input() set CommissionId(id: number) {
        this.CommissionId$.next(id);
    }

    @Input() set CustomerId(id: number) {
        this.CustomerId$.next(id);
    }

    @Input() set PersonId(id: number) {
        this.PersonId$.next(id);
    }

    @ViewChild('sortMenu') private menuRef?: MatMenuPanel;
    public TaskSort$: BehaviorSubject<ITaskBoardSort> = new BehaviorSubject({ sortBy: 'endDate', direction: 'dsc' });
    public CommissionId$ = new BehaviorSubject<number | null>(null);
    public CustomerId$ = new BehaviorSubject<number | null>(null);
    public PersonId$ = new BehaviorSubject<number | null>(null);
    public EventUnSeenCount$ = new BehaviorSubject(0);

    private commissionsFromCustomer$ = this.CustomerId$.pipe(
        switchMap((cid) => (cid ? this.store.select(getCommissionsActive).pipe(map((commissions) => commissions?.filter((c) => c.CustomerId === cid))) : of(null))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    private preFilteredTaskList$: Observable<EventEntity[]> = combineLatest([
        this.store.select(getTasks),
        this.store.select(getUser),
        this.CommissionId$,
        this.CustomerId$,
        this.PersonId$.pipe(
            switchMap((personId) =>
                personId
                    ? this.store.select(getPerson2EntitiesFetched).pipe(
                          filter((f) => !!f),
                          switchMap(() => this.store.select(getPerson2EntityEventByPersonId({ personId }))),
                      )
                    : of(null),
            ),
        ),
        this.PersonId$,
    ]).pipe(
        filter(([, , commissionId, customerId, p2es]) => !!(commissionId || customerId || p2es)),
        map(([tasks, user, commissionId, customerId, p2es, personId]) => {
            return tasks
                .filter((e) => (!commissionId || e.CommissionId === commissionId) && (!customerId || e.CustomerId === customerId) && (!p2es || e.PersonId === personId || p2es.some((p) => p.EntityId === e.Id)) && e.UserIds.includes(user.Id))
                .slice();
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    private preFilteredBookmarkList$: Observable<BookmarkEntity[]> = combineLatest([
        this.store.select(getBookmarks),
        this.store.select(getUser),
        this.CommissionId$,
        this.CustomerId$,
        this.PersonId$.pipe(
            switchMap((personId) =>
                personId
                    ? this.store.select(getPerson2EntitiesFetched).pipe(
                          filter((f) => !!f),
                          switchMap(() => this.store.select(getPerson2EntityEventByPersonId({ personId }))),
                      )
                    : of(null),
            ),
        ),
        this.PersonId$,
    ]).pipe(
        filter(([, , commissionId, customerId, p2es, personId]) => !!(commissionId || customerId || personId)),
        map(([bookmarks, user, commissionId, customerId, p2es, personId]) => {
            return bookmarks?.filter(
                (b) =>
                    (!commissionId || b.Event.CommissionId === commissionId) &&
                    (!customerId || b.Event.CustomerId === customerId) &&
                    (!p2es || b.Event.PersonId === personId || p2es.some((p) => p.EntityId === b.EventId)) &&
                    b.UserId === user.Id,
            );
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    private preFilteredEventList$: Observable<EventEntity[]> = combineLatest([
        this.store.select(getEvents),
        this.store.select(getEventTypes),
        this.CommissionId$,
        this.CustomerId$,
        this.PersonId$.pipe(
            switchMap((personId) =>
                personId
                    ? this.store.select(getPerson2EntitiesFetched).pipe(
                          filter((f) => !!f),
                          switchMap(() => this.store.select(getPerson2EntityEventByPersonId({ personId }))),
                      )
                    : of(null),
            ),
        ),
        this.PersonId$,
        this.CommissionId$.pipe(switchMap((commissionId) => (commissionId ? this.store.select(getProcesses).pipe(map((processes) => processes.filter((p) => p.CommissionId === commissionId).map((p) => p.Id))) : of([] as Array<number>)))),
    ]).pipe(
        filter(([, , commissionId, customerId, p2es, personId]) => !!(commissionId || customerId || personId)),
        map(([events, eventTypes, commissionId, customerId, p2es, personId, commissionProcesses]) => {
            const nachnahmeType = eventTypes.find((e) => e.Name === EventTypeNamesEnum.Zusatzarbeit);
            const mangelType = eventTypes.find((e) => e.Name === EventTypeNamesEnum.Erschwernis);
            if (!mangelType || !nachnahmeType) {
                console.error('EventTypes Mangel/Nachnahme nicht gefunden');
            }
            const excludeIds = [mangelType.Id, nachnahmeType.Id];

            const filteredEvents = events?.filter(
                (e) =>
                    (!commissionId || e.CommissionId === commissionId || (e.ProcessId && commissionProcesses.includes(e.ProcessId))) &&
                    (!customerId || e.CustomerId === customerId) &&
                    (!p2es || e.PersonId === personId || p2es.some((p) => p.EntityId === e.Id)) &&
                    !excludeIds.includes(e.EventTypeId),
            );
            return filteredEvents;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    @ViewChild('taskFilter') private taskFilterComponent?: FilterCardComponent;
    public TaskFilterValues$: BehaviorSubject<IEventFilterType> = getEventFilterValues();
    public TaskFilterSettings$: Observable<FilterOption[]> = combineLatest([
        this.preFilteredTaskList$.pipe(switchMap((events) => this.store.select(getUsers).pipe(map((users) => filterUserByEvents(events, users))))),
        this.preFilteredTaskList$.pipe(switchMap((events) => this.store.select(getUsers).pipe(map((users) => filterAuthorsByEvents(events, users))))),
        // laut ticket D277-2816 soll man im kunde nicht nach frist filtern können
        this.commissionsFromCustomer$,
        this.cls.getMultiple$('Commission'),
    ]).pipe(
        map(([editorUsers, authorUsers, commissions, commissionLabel]) =>
            getFilterSettings([FilterTypes.Priority, commissions ? FilterTypes.CommissionId : FilterTypes.EndDate, FilterTypes.UserId, FilterTypes.AutorId], editorUsers, authorUsers, commissions, null, null, commissionLabel),
        ),
    );
    public TaskFilterAmount$ = this.TaskFilterValues$.pipe(
        map(clacEventFilterAmount),
        shareReplay({
            refCount: true,
            bufferSize: 1,
        }),
    );
    private taskSearchForm = new FormControl<string>('');

    @ViewChild('bookmarkFilter') private bookmarkFilterComponent?: FilterCardComponent;
    public BookmarkFilterValues$: BehaviorSubject<IEventFilterType> = getEventFilterValues();
    public BookmarkFilterSettings$: Observable<FilterOption[]> = combineLatest([
        this.preFilteredBookmarkList$.pipe(
            switchMap((bookmarks) =>
                this.store.select(getUsers).pipe(
                    map((users) =>
                        filterUserByEvents(
                            bookmarks.map((b) => b.Event),
                            users,
                        ),
                    ),
                ),
            ),
        ),
        this.store.select(getEventTypes),
        this.preFilteredBookmarkList$.pipe(
            switchMap((bookmarks) =>
                this.store.select(getPersons).pipe(
                    map((users) =>
                        filterAnsprechpartnerByEvents(
                            bookmarks.map((b) => b.Event),
                            users,
                        ),
                    ),
                ),
            ),
        ),
        this.commissionsFromCustomer$,
        this.cls.getMultiple$('Commission'),
    ]).pipe(
        map(([users, eventTypes, persons, commissions, commissionLabel]) => {
            const filters: eventFilterTypes[] = [FilterTypes.Date, FilterTypes.UserId, FilterTypes.Persons, FilterTypes.EventType];
            if (commissions) {
                filters.push(FilterTypes.CommissionId);
            }
            return getFilterSettings(filters, users, [], commissions, eventTypes, persons, commissionLabel);
        }),
    );
    public BookmarkFilterAmount$ = this.BookmarkFilterValues$.pipe(
        map(clacEventFilterAmount),
        shareReplay({
            refCount: true,
            bufferSize: 1,
        }),
    );
    private bookmarkSearchForm = new FormControl<string>('');

    @ViewChild('eventFilter') private eventFilterComponent?: FilterCardComponent;
    public EventFilterValues$: BehaviorSubject<IEventFilterType> = getEventFilterValues();
    public EventFilterSettings$: Observable<FilterOption[]> = combineLatest([
        this.preFilteredEventList$.pipe(switchMap((events) => this.store.select(getUsers).pipe(map((users) => filterUserByEvents(events, users))))),
        this.store.select(getEventTypes),
        this.preFilteredEventList$.pipe(switchMap((events) => this.store.select(getPersons).pipe(map((users) => filterAnsprechpartnerByEvents(events, users))))),
        this.commissionsFromCustomer$,
        this.cls.getMultiple$('Commission'),
    ]).pipe(
        map(([users, eventTypes, persons, commissions, commissionLabel]) => {
            const filters: eventFilterTypes[] = [FilterTypes.Date, FilterTypes.UserId, FilterTypes.Persons, FilterTypes.EventType];
            if (commissions) {
                filters.push(FilterTypes.CommissionId);
            }
            return getFilterSettings(filters, users, [], commissions, eventTypes, persons, commissionLabel);
        }),
    );
    public EventFilterAmount$ = this.EventFilterValues$.pipe(
        map(clacEventFilterAmount),
        shareReplay({
            refCount: true,
            bufferSize: 1,
        }),
    );
    private eventSearchForm = new FormControl<string>('');

    public EventList$: Observable<EventEntity[]> = combineLatest([this.preFilteredEventList$, this.EventFilterValues$]).pipe(
        map(([filteredEvents, filter]) => {
            return filterEvents(filteredEvents, filter);
        }),
        map((e) => e?.slice()?.sort((a, b) => (a.EventDate.getTime() < b.EventDate.getTime() ? 1 : -1))),
        startWith([]),
        tap((events) => this.EventUnSeenCount$.next(events.filter((e) => e.LastSeenAt === null || e.LastSeenAt?.getTime() < e.UpdatedAt.getTime()).length)),
        switchMap((events) =>
            this.eventSearchForm.valueChanges.pipe(
                startWith(this.eventSearchForm.value),
                withLatestFrom(this.store.select(getEventTypesDictionary), this.store.select(getEmailDictionary), this.store.select(getPersonDictionary)),
                //todo: das searchstrings zusammenbauen vorher machen oder cachen, dafür müssen die dazubenötigten daten reolved werden
                map(([search, eventTypes, emails, persons]) => (search ? events.filter((e) => getSearchStrings(e, eventTypes, emails, persons).some((v) => stringSearch(v, search))) : events)),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public TaskList$: Observable<EventEntity[]> = combineLatest([this.preFilteredTaskList$, this.TaskFilterValues$]).pipe(
        map(([filteredTasks, filter]) => {
            if (filter) {
                filteredTasks = filterEvents(filteredTasks, filter);
            }
            return filteredTasks;
        }),
        map((e) => e?.slice()?.sort((a, b) => (a.EventDate.getTime() < b.EventDate.getTime() ? 1 : -1))),
        startWith([]),
        switchMap((tasks: EventEntity[]) =>
            this.TaskSort$.pipe(
                distinctUntilChanged((a, b) => a.sortBy === b.sortBy && a.direction === b.direction),
                map((sort) => {
                    switch (sort.sortBy) {
                        case 'endDate':
                            return sort.direction === 'dsc' ? tasks : tasks.slice().reverse();
                        case 'priority':
                            const sortTask = tasks.slice().sort((a, b) => {
                                if (a.AdditionalData?.Priority === b.AdditionalData?.Priority) {
                                    return 0;
                                }
                                if (!a.AdditionalData?.Priority) {
                                    return -1;
                                }
                                if (!b.AdditionalData?.Priority) {
                                    return 1;
                                }
                                return a.AdditionalData.Priority - b.AdditionalData.Priority;
                            });
                            return sort.direction === 'asc' ? sortTask : sortTask.reverse();
                    }
                }),
            ),
        ),
        switchMap((events) =>
            this.taskSearchForm.valueChanges.pipe(
                startWith(this.taskSearchForm.value),
                withLatestFrom(this.store.select(getEventTypesDictionary), this.store.select(getEmailDictionary), this.store.select(getPersonDictionary)),
                map(([search, eventTypes, emails, persons]) => (search ? events.filter((e) => getSearchStrings(e, eventTypes, emails, persons).some((v) => stringSearch(v, search))) : events)),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public TaskUnSeenCount$ = this.TaskList$.pipe(
        map((tasks) => tasks.filter((e) => e.LastSeenAt === null || e.LastSeenAt?.getTime() < e.UpdatedAt.getTime()).length),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    public BookmarkList$: Observable<BookmarkEntity[]> = combineLatest([this.preFilteredBookmarkList$, this.BookmarkFilterValues$]).pipe(
        map(([filteredBookmarks, filter]) => {
            return filterBookmarks(filteredBookmarks, filter);
        }),
        map((e) => e?.slice()?.sort((a, b) => (a.CreatedAt.getTime() < b.CreatedAt.getTime() ? 1 : -1))),
        startWith([]),
        switchMap((events) =>
            this.bookmarkSearchForm.valueChanges.pipe(
                startWith(this.bookmarkSearchForm.value),
                withLatestFrom(this.store.select(getEventTypesDictionary), this.store.select(getEmailDictionary), this.store.select(getPersonDictionary)),
                map(([search, eventTypes, emails, persons]) => (search ? events.filter((e) => getSearchStrings(e.Event, eventTypes, emails, persons).some((v) => stringSearch(v, search))) : events)),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    public Tabs: TabData[];
    public SelectedTab: TabData;
    public UnseenSum$;
    public LoadingData$ = combineLatest([this.store.select(getEventFetched), this.store.select(getBookmarkFetched), this.store.select(getEventTypeFetched)]).pipe(map((v) => v.some((f) => !f)));
    private subs: Subscription[] = [];
    public ShowSearchBar = false;

    constructor(private store: Store<State>, private personToEntityResolver: Person2EntityResolver, protected cls: CustomLabelService, processResolver: ProcessResolver) {
        firstValueFrom(this.store.select(getProcessFetched)).then((f) => {
            if (!f) {
                processResolver.resolve();
            }
        });
    }

    ngOnInit(): void {
        this.subs.push(
            this.PersonId$.pipe(
                filter((p) => !!p),
                switchMap(() => this.store.select(getPerson2EntitiesFetched)),
                filter((f) => !f),
            ).subscribe(() => this.personToEntityResolver.resolve()),
        );
    }

    ngAfterViewInit(): void {
        this.Tabs = [
            {
                icon: TaskPageMeta.Icon,
                searchLabel: 'Aufgaben durchsuchen',
                searchForm: this.taskSearchForm,
                headerButtons: [
                    {
                        checkLabel: 'Filtern',
                        checkIcon: 'filter',
                        checkAction: () => this.taskFilterComponent.Open(),
                        badge$: this.TaskFilterAmount$.pipe(map((a) => a || null)),
                    },
                    {
                        checkLabel: 'Sortieren',
                        checkIcon: 'sort',
                        matMenu: this.menuRef,
                    },
                    {
                        checkAction: () => this.clearTasks(),
                        checkLabel: 'Alle Aufgaben gesehen',
                        checkIcon: 'eye',
                        disableCheck$: this.TaskUnSeenCount$.pipe(map((t) => !t)),
                    },
                ],
                listDataSource$: this.TaskList$,
                unseenCount$: this.TaskUnSeenCount$,
            },
            {
                icon: 'bookmark',
                searchLabel: 'Lesezeichen durchsuchen',
                searchForm: this.bookmarkSearchForm,
                headerButtons: [
                    {
                        checkLabel: 'Filtern',
                        checkIcon: 'filter',
                        checkAction: () => this.bookmarkFilterComponent.Open(),
                        badge$: this.BookmarkFilterAmount$.pipe(map((a) => a || null)),
                    },
                    {
                        checkAction: () => this.clearBookmarks(),
                        checkLabel: 'Alle Lesezeichen gesehen',
                        checkIcon: 'eye',
                        disableCheck$: this.BookmarkList$.pipe(map((d) => d.every((b) => b.Event.LastSeenAt?.getTime() >= b.Event.UpdatedAt.getTime()))),
                    },
                    {
                        checkAction: () => this.deleteBookmarks(),
                        checkLabel: 'Alle Lesezeichen entfernen',
                        checkIcon: 'check',
                        disableCheck$: this.BookmarkList$.pipe(map((d) => !d.length)),
                    },
                ],
                listDataSource$: this.BookmarkList$,
            },
            {
                icon: HistoryMeta.Icon,
                searchLabel: 'Ereignisse durchsuchen',
                searchForm: this.eventSearchForm,
                headerButtons: [
                    {
                        checkLabel: 'Filtern',
                        checkIcon: 'filter',
                        checkAction: () => this.eventFilterComponent.Open(),
                        badge$: this.EventFilterAmount$.pipe(map((a) => a || null)),
                    },
                    {
                        checkAction: () => this.clearEvents(),
                        checkLabel: 'Ereignisse gesehen',
                        checkIcon: 'eye',
                        disableCheck$: this.EventUnSeenCount$.pipe(map((t) => !t)),
                    },
                ],
                listDataSource$: this.EventList$,
                unseenCount$: this.EventUnSeenCount$,
            },
        ];
        this.UnseenSum$ = combineLatest(this.Tabs.map((t) => t.unseenCount$).filter((v) => !!v)).pipe(map((unseencounts) => unseencounts.filter((c) => !!c).reduce((previousValue, currentValue) => previousValue + currentValue, 0)));
        this.SelectedTab = this.Tabs[0];
    }

    public deleteBookmarks() {
        firstValueFrom(this.BookmarkList$).then((bookmarks) => this.store.dispatch(BookmarkActionTypes.RemoveBookmark({ BookmarkIds: bookmarks.map((d) => d.Id) })));
    }

    private clearBookmarks() {
        firstValueFrom(this.BookmarkList$).then((bookmarks) => this.store.dispatch(EventsActionTypes.SetEventSeen({ Payload: { eventIds: bookmarks.map((b) => b.EventId) } })));
    }

    private clearTasks() {
        firstValueFrom(this.TaskList$).then((events) => this.store.dispatch(EventsActionTypes.SetEventSeen({ Payload: { eventIds: events.map((e) => e.Id) } })));
    }

    private clearEvents() {
        firstValueFrom(this.EventList$).then((events) => this.store.dispatch(EventsActionTypes.SetEventSeen({ Payload: { eventIds: events.map((e) => e.Id) } })));
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
}
