import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, firstValueFrom, from, mergeMap, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BaseActionTypes } from '../actions/base.actions';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { GetTimestampFromTime } from '@dave/types';
import { DaveActions } from '../actions/actions';
import { State } from '../index';
import { AppGatewayService } from '../../services/app-gateway.service';
import {
    MaterialToCommissionActionTypes,
} from '../actions/material-to-commission.actions';
import { MaterialToCommissionEntityFromBackend } from '../../entities/material-to-commission.entity';
import { getMaterialToCommissionDictionary } from '../selectors/resource-to-commission.selector';

enum ErrorCodes {
    Add = 'MaterialToCommission Hinzufügen fehlgeschlagen',
    Load = 'MaterialToCommission Abrufen fehlgeschlagen',
    Modify = 'MaterialToCommission Bearbeiten fehlgeschlagen',
    Remove = 'MaterialToCommission Löschen fehlgeschlagen',
}

@Injectable()
export class MaterialToCommissionEffects {

    ModifyMaterialToCommission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MaterialToCommissionActionTypes.Change),
            switchMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ErpMaterialToCommissionEdit: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return MaterialToCommissionActionTypes.UpdateOne({ Payload: MaterialToCommissionEntityFromBackend(res.ErpMaterialToCommissionEdit.MaterialToCommission) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );

    DeleteMaterialToCommission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MaterialToCommissionActionTypes.Delete),
            switchMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ErpMaterialToCommissionDelete: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return MaterialToCommissionActionTypes.RemoveOne({ Payload: +Payload.Id });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );


    AddMaterialToCommission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MaterialToCommissionActionTypes.Create),
            switchMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ErpMaterialToCommissionAdd: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return MaterialToCommissionActionTypes.UpdateOne({ Payload: MaterialToCommissionEntityFromBackend(res.ErpMaterialToCommissionAdd.MaterialToCommission) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );



    LoadMaterialToCommissions = createEffect(() => {
        return this.actions$.pipe(
            ofType(MaterialToCommissionActionTypes.Load),
            switchMap(({ Payload }) => {
                const requestPayload = isNotNullOrUndefined(Payload?.updatedSince)
                    ? { UpdatedAt: Payload.updatedSince, WithDeleted: true }
                    : { WithDeleted: true };

                return from(this.gatewayService.Request({ ErpMaterialToCommissionGet: requestPayload })).pipe(
                    mergeMap(res => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            if (!isNotNullOrUndefined(Payload.updatedSince)) {
                                return of(MaterialToCommissionActionTypes.UpdateAll({
                                    Payload: res.ErpMaterialToCommissionGet.MaterialsToCommissions.map(val => MaterialToCommissionEntityFromBackend(val)),
                                    updateLatestUpdatedAt: true,
                                }));
                            } else if (res.ErpMaterialToCommissionGet.MaterialsToCommissions.length) {
                                return from(firstValueFrom(this.store$.select(getMaterialToCommissionDictionary))).pipe(
                                    mergeMap(MaterialToCommissions => {
                                        const filteredMaterialToCommissions = res.ErpMaterialToCommissionGet.MaterialsToCommissions.filter(MaterialToCommission => {
                                            const fromState = MaterialToCommissions[MaterialToCommission.Id];
                                            return !fromState || GetTimestampFromTime(fromState.UpdatedAt).toString() !== MaterialToCommission.UpdatedAt;
                                        });

                                        if (filteredMaterialToCommissions.length) {
                                            return of(MaterialToCommissionActionTypes.UpdateMany({
                                                Payload: filteredMaterialToCommissions.map(val => MaterialToCommissionEntityFromBackend(val)),
                                                updateLatestUpdatedAt: true,
                                            }));
                                        } else {
                                            return EMPTY;
                                        }
                                    }),
                                );
                            }
                            return EMPTY;
                        } else {
                            throw res.Errors;
                        }
                    }),
                    catchError(err =>
                        of(BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                            },
                        }))
                    ),
                );
            }),
        );
    });

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>,  private gatewayService: AppGatewayService) {}
}
