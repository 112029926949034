import { Component,HostBinding,Input,OnDestroy,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest,Observable,Subscription } from 'rxjs';
import { distinctUntilChanged,map } from 'rxjs/operators';
import { SalaryTypes } from '../../../dave-data-module/entities/employee.entity';
import { ProductEntity } from '../../../dave-data-module/entities/product.entity';
import { State } from '../../../dave-data-module/State';
import { getCommissionTypes } from '../../../dave-data-module/State/selectors/commissionType.selectors';
import { getEmployees } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getUserProducts } from '../../../dave-data-module/State/selectors/products.selectors';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { UserAdministrationMeta } from '../../../helper/page-metadata';
import { DashboardHelperService,ICard } from '../../../services/dashboard-helper.service';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit, OnDestroy {
    public Cards$: Observable<ICard[]> = combineLatest([
        this.store.select(getUser),
        this.store.select(getEmployees),
        this.dashboardHelperService.GetDashboardItems$(),
        this.bs.MobileQuery,
    ]).pipe(map(([user, employees, dashboardItems, isMobile]) => {
            const employee = employees.find((e) => e.UserId == user.Id);
            const cards = dashboardItems.filter((card) => card.Disabled === false);

            if (isMobile && employee) {
                cards.forEach((c, i) => {
                    if (c.Route === UserAdministrationMeta.Path) {
                        cards[i].Route = [UserAdministrationMeta.Path, employee.Id];
                    }
                });
            }
            return cards;
    }));
    private products$: Observable<ProductEntity[]>;
    private salaryType: Observable<SalaryTypes>;
    private subscriptions: Subscription[] = [];
    private hasBtgb = false;

    @HostBinding('style.--grid-column-count')
    @Input()
    MaxColumnCount: number | null = null;

    constructor(private dashboardHelperService: DashboardHelperService, private store: Store<State>, protected bs: BreakpointObserverService) {
        this.products$ = this.store.select(getUserProducts);
        this.salaryType = combineLatest([this.store.select(getEmployees), this.store.select(getUser)]).pipe(
            map(([employees, user]) => employees?.find((e) => e.UserId === user?.Id)?.SalaryType),
            distinctUntilChanged(),
        );
    }

    ngOnInit() {
        this.subscriptions.push(
            this.store.select(getCommissionTypes).subscribe((ct) => (this.hasBtgb = ct?.some((c) => c.AdditionalData?.ConstructionDiary))),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
