import {EmailConnection2EmailSettingsTypeEntity} from '../../entities/emailConnection2EmailSettingsType.entity';
import { createReducer, on } from '@ngrx/store';
import {EmailConnection2EmailSettingsTypeActionTypes} from '../actions/emailConnection2EmailSettingsType.actions';


export const emailConnection2EmailSettingsTypeFeatureKey = 'emailConnection2EmailSettingsTypes';

export interface EmailConnection2EmailSettingsTypeState {
    emailConnection2EmailSettingsTypes: EmailConnection2EmailSettingsTypeEntity[];
}

export const initialState: EmailConnection2EmailSettingsTypeState = {
    emailConnection2EmailSettingsTypes: null,
};

export const EmailConnection2EmailSettingsTypesReducer = createReducer(
    initialState,
    on(EmailConnection2EmailSettingsTypeActionTypes.UpdateEmailConnection2EmailSettingsType, (state, { Payload }) => ({
        ...state,
        emailConnection2EmailSettingsTypes: Payload,
    })),
);
