import { MaterialListAddRequest, MaterialListDeleteRequest, MaterialListEditRequest } from '@dave/types';
import { MaterialListEntity } from '../../entities/material-list.entity';
import { createAction, props } from '@ngrx/store';
import { UploadActionProps } from '../../guards/resolver.helper';
import { GatewayResponseErrorType } from '../../services/app-gateway.service';


const prefix = 'data/' + 'materialList/';
export const MaterialListActionTypes = {
    Load: createAction(prefix + 'load', props< UploadActionProps >()),
    Change: createAction(prefix + 'change', props<{ Payload: MaterialListEditRequest }>()),
    Delete: createAction(prefix + 'delete', props<{ Payload: MaterialListDeleteRequest }>()),

    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<MaterialListEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<MaterialListEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction(prefix + 'updateOne', props<{ Payload: MaterialListEntity }>()),
    RemoveOne: createAction(prefix + 'remove', props<{ Payload: number }>()),

    CreateRequest: createAction(prefix + 'create/request', props<{ Payload: MaterialListAddRequest }>()),
    CreateSuccess: createAction(prefix + 'create/success', props<{ Payload: MaterialListEntity }>()),
    CreateFailure: createAction(prefix + 'create/failure', props<{ Payload: { Errors: GatewayResponseErrorType; ToasterMessage?: string } }>()),
};
