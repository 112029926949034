import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    FormGroupDirective,
    Validators,
    FormGroup, FormControl,
} from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, merge, Subscription } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { UserEntity } from '../../dave-data-module/entities/user.entity';
import { State } from '../../dave-data-module/State';
import { DaveActions } from '../../dave-data-module/State/actions/actions';
import { BaseActionTypes } from '../../dave-data-module/State/actions/base.actions';
import { getIsPerformingLogin, getToken } from '../../dave-data-module/State/selectors/base.selectors';
import { getUser } from '../../dave-data-module/State/selectors/users.selectors';
import { LoginService } from '../../services/login.service';
import { equalPasswordValidator, getErrorMessage, passwordValidator } from '../../helper/validation.helper';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild('formRef')
    formRef: FormGroupDirective;

    @Input() Verification: {
        UserId: number;
        Code: string;
        Name: string;
    } = null;

    private subscriptions: Subscription[] = [];

    private user: UserEntity = null;
    public LoggedIn = false;

    public HidePassword = true;
    public NewPasswordDialog = false;
    public ResetPasswordDialog = false;
    public LoginFormGroup = new UntypedFormGroup({
        username: new UntypedFormControl('', Validators.required),
        password: new UntypedFormControl('', Validators.required),
    });
    public UsernameResetValidator = [Validators.required, Validators.email];
    public NewPasswordFormGroup: FormGroup<{ oldPassword: FormControl<string>; newPassword: FormControl<string>; confirmNewPassword: FormControl<string> }>;
    public ResetPasswordFormGroup = new UntypedFormGroup({
        usernameReset: new UntypedFormControl('', this.UsernameResetValidator),
    });
    public ActivateUserFormGroup = new FormGroup({
        newPassword: new FormControl<string>('', Validators.required),
        repeatNewPassword: new FormControl<string>('', [Validators.required, equalPasswordValidator(() => this.ActivateUserFormGroup?.controls?.newPassword)]),
    });

    public IsLoading$ = this.store.select(getIsPerformingLogin).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    constructor(
        private loginService: LoginService,
        private fb: UntypedFormBuilder,
        private store: Store<State>,
        private router: Router,
        private route: ActivatedRoute,
        private actions$: Actions<DaveActions>,
    ) {
        this.initForm();
    }

    ngOnInit(): void {
        this.subscriptions.push(
            combineLatest([this.store.select(getUser), this.store.select(getToken)]).subscribe(([user, token]) => {
                this.LoggedIn = token && token.length > 0;
                this.user = user;
            }),
            this.route.paramMap.subscribe((params: ParamMap) => {
                if (params.keys != null && params.keys.length === 3) {
                    this.Verification = {
                        Code: params.get('verificationCode'),
                        UserId: parseInt(params.get('userId'), 10),
                        Name: params.get('name'),
                    };
                }
                return params;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    private initForm() {
        this.NewPasswordFormGroup = new FormGroup(
            {
                oldPassword: new FormControl<string>(null, [Validators.required]),
                newPassword: new FormControl<string>(null, [Validators.required, passwordValidator]),
                confirmNewPassword: new FormControl<string>(null, [Validators.required, equalPasswordValidator(() => this.NewPasswordFormGroup?.controls?.newPassword)]),
            },
        );
    }

    public LogIn() {
        this.loginService.Login(this.UsernameForm.value, this.PasswordForm.value);
        merge(
            this.actions$.pipe(ofType(BaseActionTypes.UpdateToken)),
            this.actions$.pipe(ofType(BaseActionTypes.ErrorAction))
        )
            .pipe(take(1))
            .subscribe((action: any) => {
                if (action.type === BaseActionTypes.UpdateToken.type) {
                    this.router.navigateByUrl('');
                }
            });
    }

    public GetUserName() {
        return this.user?.DisplayName;
    }

    public Logout() {
        this.loginService.Logout();
        this.user = null;
    }

    public ToggleNewPasswordDialog(setting: boolean) {
        this.NewPasswordDialog = setting;
    }

    public ToggleResetDialog(setting: boolean) {
        this.ResetPasswordDialog = setting;
    }

    public ChangePassword() {
        this.store.dispatch(
            BaseActionTypes.ChangePassword({
                Payload: {
                    oldPassword: this.OldPasswordForm.value,
                    newPassword: this.NewPasswordForm.value,
                },
            }),
        );
    }

    public ResetPassword() {
        this.store.dispatch(BaseActionTypes.ResetPassword({ Payload: { Email: this.UsernameResetForm.value } }));
        this.ResetPasswordDialog = false;
    }

    public ActivateUser() {
        this.store.dispatch(
            BaseActionTypes.ActivateUser({
                Payload: {
                    verificationCode: this.Verification.Code,
                    userId: this.Verification.UserId,
                    password: this.ActivateUserNewPasswordForm.value,
                },
            }),
        );
        this.Verification = null;
    }

    public GetFormErrorMessage = getErrorMessage;

    get UsernameForm() {
        return this.LoginFormGroup.get('username');
    }
    get PasswordForm() {
        return this.LoginFormGroup.get('password');
    }

    get UsernameResetForm() {
        return this.ResetPasswordFormGroup.get('usernameReset');
    }

    get ActivateUserNewPasswordForm() {
        return this.ActivateUserFormGroup.get('newPassword');
    }
    get RepeatNewPasswordForm() {
        return this.ActivateUserFormGroup.get('repeatNewPassword');
    }

    get NewPasswordForm() {
        return this.NewPasswordFormGroup.get('newPassword');
    }
    get OldPasswordForm() {
        return this.NewPasswordFormGroup.get('oldPassword');
    }
    get ConfirmNewPasswordForm() {
        return this.NewPasswordFormGroup.get('confirmNewPassword');
    }
}
