import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { PersonEntity, PersonEntityFromBackend } from '../../entities/person.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { PersonActionTypes } from '../actions/person.actions';
import { State } from '../index';
import { getPersons } from '../selectors/person.selectors';

enum ErrorCodes {
    Add = 'Person Hinzufügen fehlgeschlagen',
    Load = 'Person Abrufen fehlgeschlagen',
    Modify = 'Person Bearbeiten fehlgeschlagen',
    Remove = 'Person Löschen fehlgeschlagen',
}

@Injectable()
export class PersonEffects {

    RemovePerson$ = createEffect(() => this.actions$.pipe(
        ofType(PersonActionTypes.DeletePerson),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
mutation{
  deletePerson(id: ${action.Payload})
}`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                withLatestFrom(this.store$.select(getPersons)),
                map(([res, persons]) =>
                    res && res.deletePerson
                        ? PersonActionTypes.UpdateMany({
                              Payload: [persons.find(val => val.Id === action.Payload).Clone({ Deleted: true })],
                          })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Remove },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    ModifyPerson$ = createEffect(() => this.actions$.pipe(
        ofType(PersonActionTypes.ModifyPerson),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
      mutation{
        changePerson(id: ${action.Payload.id},
            ${stringifyIfNotNullOrUndefined(action.Payload, 'street')}
            ${stringifyIfNotNullOrUndefined(action.Payload, 'companyName')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'postalCode')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'city')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'country')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'salutation')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'title')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'email')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'phonenumber')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'mobilenumber')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'faxnumber')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'description')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'firstname')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'lastname')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'personTypeId')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'workNumber')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'secondMail')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'birthdate')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'organisation')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'secondLastname')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'secondFirstname')}
          ){
        ${PersonEntity.GQLFields.join(',')}
        }
      }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res =>
                    res && res.changePerson && res.changePerson.id
                        ? PersonActionTypes.UpdateMany({
                              Payload: [PersonEntityFromBackend(res.changePerson)],
                          })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Modify },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    AddPerson$ = createEffect(() => this.actions$.pipe(
        ofType(PersonActionTypes.AddPerson),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
      mutation{
        createPerson(
            ${stringifyIfNotNullOrUndefined(action.Payload, 'street')}
            ${stringifyIfNotNullOrUndefined(action.Payload, 'companyName')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'postalCode')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'city')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'country')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'salutation')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'title')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'email')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'phonenumber')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'mobilenumber')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'faxnumber')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'description')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'firstname')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'lastname')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'personTypeId')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'workNumber')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'secondMail')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'birthdate')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'organisation')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'secondLastname')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'secondFirstname')}

          ){
            ${PersonEntity.GQLFields.join(',')}
        }
      }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.createPerson
                        ? PersonActionTypes.UpdateMany({
                              Payload: [PersonEntityFromBackend(res.createPerson)],
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    LoadPersons = createEffect(() => this.actions$.pipe(
        ofType(PersonActionTypes.Load),
        withLatestFrom(this.store$),
        switchMap(([{ Payload }, store]) => {
            const queryString = `
query{
    person${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''}{
      ${PersonEntity.GQLFields.join(',')}
    }
      }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                map(res =>
                    res && res.person
                        ? Payload?.updatedSince
                            ? PersonActionTypes.UpdateMany({
                                  Payload: res.person.map(val => PersonEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                            : PersonActionTypes.UpdateAll({
                                  Payload: res.person.map(val => PersonEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Load },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
