import { Directive } from '@angular/core';

/**
 * Direktive, die `MatRow`-Tabellenzeilen mit `routerLink`- oder `click`-Bindings eine CSS-Klasse
 * gibt.
 *
 * Da Angular beide Bindings nicht in das DOM schreibt, kann nicht per CSS darauf zugegriffen
 * werden. Diese Direktive ist der Workaround.
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'tr[mat-row][routerLink], tr[mat-row][click]',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'clickable-mat-row' },
})
export class ClickableMatRowDirective {}
