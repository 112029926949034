import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { UserToCustomerActionTypes } from '../actions/customers.actions';
import { UserToCustomerEntity } from '../../entities/user-to-customer.entity';

export const userToCustomerFeatureKey = 'userToCustomer';

export interface UserToCustomerState extends EntityState<UserToCustomerEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}
const adapter = createEntityAdapter<UserToCustomerEntity>({
    selectId: Entity => Entity.Id,
});

export const initialState: UserToCustomerState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const userToCustomerReducer = createReducer(
    initialState,
    on(UserToCustomerActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(UserToCustomerActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(UserToCustomerActionTypes.RemoveOne, (state, { Payload }) =>
        adapter.removeOne(Payload, {...state}),
    ),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllUserToCustomer = selectAll;
export const selectUserToCustomerEntities = selectEntities;

export const selectUserToCustomerFetched = (state: UserToCustomerState) => state.fetched;
export const selectUserToCustomerLatestUpdatedAt = (state: UserToCustomerState) => state.latestUpdatedAt;
