import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
    SharedCommissionsState,
    sharedCommissionsFeatureKey
} from "../reducers/sharedCommissions.reducer";
import { State } from "../index";

export const selectSharedCommissions = createFeatureSelector<
  SharedCommissionsState
>(sharedCommissionsFeatureKey);

export const getSharedCommissions = createSelector(
  selectSharedCommissions,
  data => data.sharedCommissions
);
export const getSingleSharedCommissions = createSelector(
  getSharedCommissions,
  (sharedCommissions: ReturnType<typeof getSharedCommissions>, Id: number) =>
  sharedCommissions ? sharedCommissions.find(e => e.Id === Id) : null
);
