import { createReducer, on } from '@ngrx/store';
import {VarianteTypeEntity} from '../../entities/varianteType.entity';
import {VarianteTypesActionTypes} from '../actions/variante-types.actions';


export const varianteTypesFeatureKey = 'varianteTypes';

export interface VarianteTypesState {
    varianteTypes: VarianteTypeEntity[];
}

export const initialVarianteTypesState: VarianteTypesState = {
    varianteTypes: null,
};


export const varianteTypesStateReducer = createReducer(
    initialVarianteTypesState,
    on(VarianteTypesActionTypes.UpdateVarianteTypes, (state, { Payload }) => ({
        ...state,
        varianteTypes: Payload
    })),
);

