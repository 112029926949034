import { Injectable } from '@angular/core';
import { BaseEntityReloadResolver2 } from './resolver.helper';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { MaterialToCommissionActionTypes } from '../State/actions/material-to-commission.actions';
import {
    getMaterialToCommissionFetched,
    getMaterialToCommissionLatestUpdatedAt,
} from '../State/selectors/resource-to-commission.selector';

@Injectable({
    providedIn: 'root',
})
export class MaterialToCommissionResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getMaterialToCommissionFetched, getMaterialToCommissionLatestUpdatedAt, MaterialToCommissionActionTypes.Load, store);
    }
}
