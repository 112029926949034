import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookmarkResolver } from '../dave-data-module/guards/bookmark.resolver';
import { CommissionResolver } from '../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../dave-data-module/guards/customer.resolver';
import { EmailConnectionResolver } from '../dave-data-module/guards/email-connection.resolver';
import { EmailFolderResolver } from '../dave-data-module/guards/email-folder.resolver';
import { EventTypeResolver } from '../dave-data-module/guards/event-type.resolver';
import { GeneratedDocumentsResolver } from '../dave-data-module/guards/generatedDocuments.resolver';
import { GeneratedDocumentsTypeResolver } from '../dave-data-module/guards/generatedDocumentsType.resolver';
import { PartnerOfficeResolver } from '../dave-data-module/guards/partner-office.resolver';
import { PartnerResolver } from '../dave-data-module/guards/partner.resolver';
import { PersonResolver } from '../dave-data-module/guards/person.resolver';
import { Person2EntityResolver } from '../dave-data-module/guards/person2Entity.resolver';
import { StatusFromBackofficeResolver } from '../dave-data-module/guards/statusFromBackoffice.resolver';
import { TokenResolver } from '../dave-data-module/guards/token.resolver';
import { UserResolver } from '../dave-data-module/guards/user.resolver';
import { ValidTokenGuard } from '../dave-data-module/guards/valid-token.guard';
import { EmailClientComponent } from './components/email-client.component';

const routes: Routes = [
    {
        path: '',
        component: EmailClientComponent,
        canActivate: [ValidTokenGuard],
        canActivateChild: [ValidTokenGuard],
        resolve: {
            users: UserResolver,
            token: TokenResolver,
            eventType: EventTypeResolver,
            person: PersonResolver,
            person2Entity: Person2EntityResolver,
            emailConnections: EmailConnectionResolver,
            genDocType: GeneratedDocumentsTypeResolver,
            emailFolders: EmailFolderResolver,
            customers: CustomerResolver,
            commission: CommissionResolver,
            bookmarks: BookmarkResolver,
            generatedDocumentsResolver: GeneratedDocumentsResolver,
            partnerOfficeResolver: PartnerOfficeResolver,
            partnerResolver: PartnerResolver,
            statusFromBackoffice: StatusFromBackofficeResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class DaveEmailRoutingModule {}
