import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy,Component,Input,OnInit } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { filter,map,skip,take } from 'rxjs/operators';
import { CustomFormsModule } from "../../../custom-forms-module/custom-forms.module";
import { CustomerTypeEntity } from '../../../dave-data-module/entities/customer-type.entity';
import { State } from '../../../dave-data-module/State';
import { CustomerTypeActionTypes } from '../../../dave-data-module/State/actions/customer-types.actions';
import { getCustomerTypes } from '../../../dave-data-module/State/selectors/customers.selectors';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import {
PermissionService
} from "../../../dave-utils-module/dave-shared-components-module/services/permission.service";
import { isNotNullOrUndefined } from '../../../helper/helper';
import { Permission } from '../../../helper/permission.helper';

/**
 * Spezifische Wrapperkomponente um die generische ChipAutocomplete-Komponente.
 *
 * Diese Komponente wird an mehreren Orten benutzt und besitzt eine nichttriviale Implementation.
 */
@Component({
    selector: 'app-customer-type-chip-autocomplete',
    templateUrl: './customer-type-chip-autocomplete.component.html',
    styleUrls: ['./customer-type-chip-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, CustomFormsModule, FontAwesomeModule, MatButtonModule, ReactiveFormsModule],
})
export class CustomerTypeChipAutocompleteComponent implements OnInit {
    /** (NN) Alle CustomerTypes */
    public Options$ = this.store.select(getCustomerTypes).pipe(filter(isNotNullOrUndefined));

    /** FormControl der ausgewählten CustomerTypes eines Customers */
    @Input() public FormControl: FormControlTyped<CustomerTypeEntity[] | null>;

    /** Mappingfunktion, die einem CustomerType einen anzeigbaren String zuordnet */
    public MapFn: (option: CustomerTypeEntity) => string = (o) => o.Name;

    public CanDelete$: Observable<boolean> = this.PS.Has(Permission.DeleteCustomerType);
    public CanCreate$: Observable<boolean> = this.PS.Has(Permission.CreateCustomerType);
    public ToolTip$: Observable<string> = this.CanCreate$.pipe(map((can) => (can ? 'Mit "Enter" bestätigen Sie die Branche oder fügen sie hinzu.' : 'Mit "Enter" wählen Sie die Branche aus.')));

    constructor(private appDialog: AppDialogService, private PS: PermissionService, private store: Store<State>, private toast: ToastrService, public CS: CustomerNameService) {}

    ngOnInit() {
        if (!this.FormControl) {
            throw Error('No FormControl supplied!');
        }
    }

    /** Erstellt einen neuen CustomerType und fügt diesen dem Array der FormControl hinzu */
    public Add(optionName: string) {
        this.CanCreate$.pipe(take(1)).subscribe((can) => {
            if (can) {
                this.store.dispatch(
                    CustomerTypeActionTypes.AddCustomerType({
                        Payload: {
                            name: optionName,
                        },
                    }),
                );
                // doppelte Eingaben unterbinden, während auf die Response gewartet wird
                this.FormControl.disable();

                this.store
                    .select(getCustomerTypes)
                    // aktuellen Wert überspringen und auf Response warten -
                    // mit oder ohne neuem CustomerType, siehe AddCustomerType Effect
                    .pipe(skip(1), take(1))
                    .subscribe((customerTypes) => {
                        this.FormControl.enable();

                        const newOpt = customerTypes.find((customerType) => customerType.Name === optionName);

                        // eventuell wurde der CustomerType nicht angelegt
                        if (newOpt) {
                            this.FormControl.setValue([...this.FormControl.value, newOpt]);
                        }
                    });
            } else {
                this.FormControl.setValue([...this.FormControl.value]);
                this.toast.error(`Sie haben nicht die Berechtigung Branchen hinzuzufügen.`);
            }
        });
    }

    /**
     * Clickhandler des CustomerType-Löschen-Buttons einer Option im Autocomplete.
     *
     * Löscht den CustomerType wenn er nicht verwendet wird und der Nutzer die Löschung bestätigt.
     */
    public Delete(option: CustomerTypeEntity, event: MouseEvent) {
        // nicht das `select` Event der `mat-option` auslösen
        event.stopPropagation();

        if (option.UsedByCount) {
            this.toast.error(`Die Branche "${option.Name}" ist noch ${option.UsedByCount === 1 ? 'einem' : option.UsedByCount} ${this.CS.GetMultiple()}  zugeordnet. Entfernen Sie die Zuordnung und versuchen Sie es erneut.`);
        } else {
            this.appDialog.OpenConfirmationDialog({ paragraph: `Branche ${option.Name} wirklich löschen?`, styleDelete: true }).subscribe(
                ([result]) =>
                    result &&
                    this.store.dispatch(
                        CustomerTypeActionTypes.DeleteCustomerType({
                            Payload: {
                                id: option.Id,
                            },
                        }),
                    ),
            );
        }
    }
}
