import { NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { SimplebarAngularModule } from 'simplebar-angular';
import { AddReportButtonComponent } from '../add-report-button/add-report-button.component';
import { AddressFormComponent } from '../address-form/address-form.component';
import { BlueprintComponent } from '../blueprint/blueprint.component';
import { BookingPositionListCardModule } from '../booking-position-list-card/booking-position-list-card.module';
import { EntityHistoryComponent } from '../components/entity-history/entity-history.component';
import {
EventTypesSelectorCardComponent
} from '../components/templates/event-types-selector-card/event-types-selector-card.component';
import { NewDocumentViewMenu } from '../components/templates/new-document-view/new-document-view.module';
import { TextareaModule } from '../components/templates/textarea/textarea.module';
import { CustomFormsModule } from '../custom-forms-module/custom-forms.module';
import { DaveCalendarModule } from '../dave-calendar/dave-calendar.module';
import { DaveCommentsModule } from '../dave-comments/dave-comments.module';
import { DaveDmsModule } from '../dave-dms-module/dave-dms.module';
import { PdfEditorModule } from "../dave-dms-module/dave-dms/pdf-editor/pdf-editor.module";
import { PreviewModule } from '../dave-dms-module/dave-dms/preview/preview.module';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveEventListEntryModule } from '../dave-event-list-entry/dave-event-list-entry.module';
import { DaveFileExplorerModule } from '../dave-file-explorer/dave-file-explorer.module';
import { DaveHistoryModule } from '../dave-history-module/dave-history.module';
import { DaveListCardModule } from '../dave-list-card/dave-list-card.module';
import { DaveListModule } from '../dave-list/dave-list.module';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { DavePersonModule } from '../dave-person-module/dave-person.module';
import { ModifyBookingOptionDialogComponent } from '../dave-reports/components/modify-booking-option/modify-booking-option-dialog/modify-booking-option-dialog.component';
import { NewBookingOptionComponent } from '../dave-reports/components/new-booking-option/new-booking-option.component';
import { DaveScan2DaveListModule } from '../dave-scan2dave-list/dave-scan2dave-list.module';
import { DaveSelectUserModule } from '../dave-select-user/dave-select-user.module';
import { DaveTasksModule } from '../dave-tasks/dave-tasks.module';
import { DaveTextTemplatesModuleModule } from '../dave-text-templates-module/dave-text-templates-module.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../dave-utils-module/app-filter-module/app-filter.module';
import { CustomerListComponent } from '../dave-utils-module/dave-shared-components-module/components/customer-list/customer-list.component';
import {
DropzoneComponent
} from "../dave-utils-module/dave-shared-components-module/components/dropzone/dropzone.component";
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { PipesModule } from '../dave-utils-module/dave-shared-components-module/pipes/pipes.module';
import { IconPipeModule } from '../dave-utils-module/icon-pipe/icon-pipe.module';
import { SelectSearchModule } from '../dave-utils-module/select-search/select-search.module';
import { DetailListTemplateDialogModule } from '../detail-list-template-dialog/detail-list-template-dialog.module';
import { AutofocusModule } from '../directives/autofocus/autofocus.module';
import { GoToButtonsModule } from '../go-to-buttons/go-to-buttons.module';
import { GroupListModule } from '../group-list/group-list.module';
import { JobSpecificationOptionComponent } from '../job-specification-option/job-specification-option.component';
import { LeafletWrapperComponent } from "../leaflet-wrapper/leaflet-wrapper.component";
import { LedgerImportListCardComponent } from "../ledger-import-list-card/ledger-import-list-card.component";
import { MatColorPickerToggleComponent } from '../mat-color-picker-toggle/mat-color-picker-toggle.component';
import { MatColorPreviewComponent } from '../mat-color-preview/mat-color-preview.component';
import { PersonListEntryComponent } from '../person-list-entry/person-list-entry.component';
import { ProgressTimelineComponent } from '../progress-bar/progress-timeline.component';
import { MaterialListComponent } from '../resource-dispo/components/material-list/material-list.component';
import {
ResourceToCommissionPopupComponent
} from '../resource-dispo/components/resource-to-commission-popup/resource-to-commission-popup.component';
import { ResourceDispoModule } from '../resource-dispo/resource-dispo.module';
import { SmallFileCardModule } from '../small-file-card/small-file-card.module';
import { TimePickerSelectComponent } from "../time-picker-select/time-picker-select.component";
import { EndTimeDirective } from "../time-span-picker-select/end-time.directive";
import { StartTimeDirective } from "../time-span-picker-select/start-time.directive";
import { TimeSpanPickerSelectComponent } from "../time-span-picker-select/time-span-picker-select.component";
import { CommissionDashboardComponent } from './components/comission-dashboard/commission-dashboard.component';
import {
CommissionBusinesVolumeListComponent
} from "./components/commission-busines-volume-list/commission-busines-volume-list.component";
import { CommissionDownloadComponent } from './components/commission-download/commission-download.component';
import { CommissionListComponent } from './components/commission-list/commission-list.component';
import { CommissionOverviewComponent } from './components/commission-overview/commission-overview.component';
import { DoughnutChartComponent } from './components/commission-overview/components/doughnut-chart/doughnut-chart.component';
import { DoughnutChartWrapperComponent } from './components/commission-overview/components/doughnut-wrapper-dialog/doughnut-chart-wrapper.component';
import { TaskBoardPopupComponent } from './components/commission-overview/components/task-board-popup/task-board-popup.component';
import { CommissionWizardComponent } from './components/commission-wizard/commission-wizard.component';
import { CsvExportDialogComponent } from './components/csv-export-dialog/csv-export-dialog.component';
import { DaveCommissionComponent } from './components/dave-commission.component';
import { AddEventDialogComponent } from './components/detail-commission/add-event-dialog/add-event-dialog.component';
import { DetailCommissionEventListModule } from './components/detail-commission/detail-commission-event-list/detail-commission-event-list.module';
import { DetailCommissionComponent } from './components/detail-commission/detail-commission.component';
import { DetailConstructionDiaryEntryComponent } from './components/detail-commission/detail-construction-diary-entry/detail-construction-diary-entry.component';
import { SelectDiaryDateDialogComponent } from './components/detail-commission/select-diary-date-dialog/select-diary-date-dialog.component';
import { SelectDiaryTimespanDialogComponent } from './components/detail-commission/select-diary-timespan-dialog/select-diary-timespan-dialog.component';
import { SelectPartnerComponent } from './components/detail-commission/select-partner/select-partner.component';
import { SelectUserCommissionLegacyComponent } from './components/detail-commission/select-user/select-user-commission-legacy.component';
import { NewCallComponent } from './components/new-call/new-call.component';
import { NewEventAndTransmissionComponent } from './components/new-event-and-transmission/new-event-and-transmission.component';
import { NewOnSiteEventComponent } from './components/new-on-site-event/new-on-site-event.component';
import { ShowImageComponent } from './components/show-image/show-image.component';
import { DaveCommissionRoutingModule } from './dave-commission-routing.module';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
    declarations: [
        DaveCommissionComponent,
        CommissionDownloadComponent,
        CommissionListComponent,
        DetailCommissionComponent,
        SelectPartnerComponent,
        SelectUserCommissionLegacyComponent,
        CommissionDashboardComponent,
        NewEventAndTransmissionComponent,
        NewCallComponent,
        NewOnSiteEventComponent,
        CommissionWizardComponent,
        CsvExportDialogComponent,
        SelectDiaryDateDialogComponent,
        DetailConstructionDiaryEntryComponent,
        AddEventDialogComponent,
        SelectDiaryTimespanDialogComponent,
        ShowImageComponent,
        SelectDiaryTimespanDialogComponent,
        CommissionOverviewComponent,
        DoughnutChartComponent,
        TaskBoardPopupComponent,
        DoughnutChartWrapperComponent,
    ],
    exports: [CommissionDashboardComponent, CommissionDownloadComponent, CommissionListComponent],
  imports: [
    CommonModule,
    DaveCommissionRoutingModule,
    DaveSharedComponentsModule,
    DaveCalendarModule,
    SimplebarAngularModule,
    FontAwesomeModule,
    MatFormFieldModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSelectModule,
    MatTabsModule,
    MatOptionModule,
    RouterModule,
    MatCardModule,
    NewDocumentViewMenu,
    AppButtonModule,
    SelectSearchModule,
    MatSortModule,
    IconPipeModule,
    MatListModule,
    DaveDmsModule,
    PipesModule,
    PreviewModule,
    MatInputModule,
    MatStepperModule,
    MatButtonModule,
    MatDialogModule,
    DaveDoubleIconModule,
    DaveHistoryModule,
    DaveFileExplorerModule,
    DaveScan2DaveListModule,
    MatMenuModule,
    DaveListCardModule,
    TextareaModule,
    BookingPositionListCardModule,
    MatCheckboxModule,
    FormsModule,
    AppFilterModule,
    MatExpansionModule,
    MatSidenavModule,
    MatIconModule,
    DaveTasksModule,
    MatDatepickerModule,
    DetailListTemplateDialogModule,
    DavePersonModule,
    DaveLoadingModule,
    MatChipsModule,
    MatStepperModule,
    DropzoneModule,
    AutofocusModule,
    SmallFileCardModule,
    MatSlideToggleModule,
    DaveCommentsModule,
    DaveTextTemplatesModuleModule,
    MatBadgeModule,
    DaveListModule,
    EntityHistoryComponent,
    ScrollingModule,
    DaveEventListEntryModule,
    ResourceDispoModule,
    DaveSelectUserModule,
    GroupListModule,
    AddReportButtonComponent,
    GoToButtonsModule,
    DetailCommissionEventListModule,
    NgxMatColorPickerModule,
    MatColorPreviewComponent,
    JobSpecificationOptionComponent,
    MatColorPickerToggleComponent,
    CustomFormsModule,
    PersonListEntryComponent,
    ProgressTimelineComponent,
    NewBookingOptionComponent,
    ModifyBookingOptionDialogComponent,
    EventTypesSelectorCardComponent,
    CustomerListComponent,
    CommissionBusinesVolumeListComponent,
    BlueprintComponent,
    PdfEditorModule,
    LeafletWrapperComponent,
    DropzoneComponent,
    EndTimeDirective,
    StartTimeDirective,
    TimePickerSelectComponent,
    TimeSpanPickerSelectComponent,
    LedgerImportListCardComponent,
    ResourceToCommissionPopupComponent,
    AddressFormComponent,
    MatRadioModule,
    MaterialListComponent
  ],
})
export class DaveCommissionModule {}
