import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { EventTypeEntity } from '../../entities/event-type.entity';
import { EventTypesActionTypes } from '../actions/event-types.actions';

export const EVENT_TYPE_FEATURE_KEY = 'eventTypes';

export interface EventTypeState extends EntityState<EventTypeEntity> {
    fetched: boolean;
}
const adapter = createEntityAdapter<EventTypeEntity>({
    selectId: Entity => Entity.Id,
});
const initialState = adapter.getInitialState({
    fetched: false,
});

export const eventTypesReducer = createReducer(
    initialState,
    on(EventTypesActionTypes.UpdateEventTypes, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
    on(EventTypesActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(EventTypesActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, { ...state })),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllEventType = selectAll;
export const selectEventTypeEntities = selectEntities;

export const selectEventTypeFetched = (state: EventTypeState) => state.fetched;
