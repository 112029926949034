import { MandateTypeType } from '../graphql-types';

export enum MandateTypeNames {
    debitor = 'Debitor',
    kreditor = 'Kreditor',
}

export class MandateTypeEntity {
    constructor(public Id: number = null, public Name: string = null) {}

    public Clone(override: MandateTypeEntityAttributes = {}): MandateTypeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new MandateTypeEntity(), attr);
    }
}

export function MandateTypeEntityFromBackend(res: MandateTypeType): MandateTypeEntity {
    return new MandateTypeEntity(res.id, res.name);
}

export interface MandateTypeEntityAttributes {
    Id?: number;
    Name?: string;
}
