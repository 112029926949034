import { TimePickerSelectComponent } from '../time-picker-select/time-picker-select.component';
export class TimeDirectiveBase {
    constructor(private component: TimePickerSelectComponent) {}
    get placeholder() {
        return this.component.placeholder;
    }
    get empty() {
        return this.component.empty;
    }
    get value() {
        return this.component.value;
    }
    get errorState() {
        return this.component.errorState;
    }
    get disabled() {
        return this.component.disabled;
    }
    get focused() {
        return this.component.focused;
    }
    get stateChanges() {
        return this.component.stateChanges;
    }
    onContainerClick() {
        this.component.onContainerClick();
    }
}
