<button mat-icon-button class="close-button" mat-dialog-close>
    <fa-icon icon="times"></fa-icon>
</button>
<mat-tab-group *ngIf="Mobile$ | async; else desktop"  preserveContent style="height: 100%">
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="tab-header">
                    E-Mail
                </div>
            </ng-template>
            <ng-template matTabContent>
                <app-email-preview [EmailId]="Data.email.Id"></app-email-preview>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="tab-header">
                    Notiz
                </div>
            </ng-template>
            <ng-template matTabContent>
                <div #tabBodyWrapper class="mat-tab-body-wrapper">
                    <mat-card class="note" (click)="textArea.focus();" style="width: 100%">
                        <header class="note-header">
                            <h3>
                                <fa-icon icon="sticky-note"></fa-icon>
                                Notiz
                            </h3>
                            <button
                                *ngIf="!EditingDescription"
                                app-round-button
                                class="buttons"
                                matTooltip="Notiz Bearbeiten"
                                [Disabled]="!(PS.Has(PS.Permission.EditEvents) | async)"
                                (click)="EditingDescription = true; textArea.focus();"
                            >
                                <fa-icon icon="pencil-alt"></fa-icon>
                            </button>
                            <button *ngIf="EditingDescription" app-round-button Inverted class="buttons" matTooltip="Zurücksetzen"
                                    (click)="CloseEdit(false)">
                                <fa-icon icon="undo"></fa-icon>
                            </button>
                            <button *ngIf="EditingDescription" app-round-button class="buttons" matTooltip="Speichern"
                                    (click)="CloseEdit(true)">
                                <fa-icon icon="save"></fa-icon>
                            </button>
                        </header>

                        <textarea #textArea matInput [class.hidden]="!EditingDescription" [formControl]="Description"></textarea>
                        <markdown *ngIf="!EditingDescription" class="markdown" [data]="Linkify(Description.value)"></markdown>
                    </mat-card>
                </div>
            </ng-template>
        </mat-tab>
</mat-tab-group>

<ng-template #desktop>
    <app-email-preview [EmailId]="Data.email.Id"></app-email-preview>
    <mat-card class="note" (click)="textArea.focus();" style="min-width: 38%">
        <header class="note-header">
            <h3>
                <fa-icon icon="sticky-note"></fa-icon>
                Notiz
            </h3>
            <button
                *ngIf="!EditingDescription"
                app-round-button
                class="buttons"
                matTooltip="Notiz Bearbeiten"
                [Disabled]="!(PS.Has(PS.Permission.EditEvents) | async)"
                (click)="EditingDescription = true; textArea.focus();"
            >
                <fa-icon icon="pencil-alt"></fa-icon>
            </button>
            <button *ngIf="EditingDescription" app-round-button Inverted class="buttons" matTooltip="Zurücksetzen"
                    (click)="CloseEdit(false)">
                <fa-icon icon="undo"></fa-icon>
            </button>
            <button *ngIf="EditingDescription" app-round-button class="buttons" matTooltip="Speichern"
                    (click)="CloseEdit(true)">
                <fa-icon icon="save"></fa-icon>
            </button>
        </header>

        <textarea #textArea matInput [class.hidden]="!EditingDescription" [formControl]="Description"></textarea>
        <markdown *ngIf="!EditingDescription" class="markdown" [data]="Linkify(Description.value)"></markdown>
    </mat-card>
</ng-template>
