import { createAction, props } from '@ngrx/store';
import {
    DaveMutationChangeEmployeeResourceScheduleArgs,
    DaveMutationCreateEmployeeResourceScheduleArgs, DaveMutationCreateEmployeeResourceScheduleWithTemplateArgs,
    QueryEmployeeResourceScheduleArgs
} from "../../../graphql-types";
import { EmployeeResourceScheduleEntity } from '../../../entities/resource-dispo/employee-resource-schedule.entity';


const prefix = 'data/' + 'employeeResourceSchedule/';
export const EmployeeResourceScheduleActionTypes = {
    Load: createAction(prefix + 'load', props<{ Payload?: QueryEmployeeResourceScheduleArgs }>()),
    Create: createAction(prefix + 'create', props<{ Payload: DaveMutationCreateEmployeeResourceScheduleArgs }>()),
    CreateFromCommission: createAction(prefix + 'createFromCommission', props<{ Payload: DaveMutationCreateEmployeeResourceScheduleWithTemplateArgs }>()),
    Change: createAction(prefix + 'change', props<{ Payload: DaveMutationChangeEmployeeResourceScheduleArgs }>()),
    Delete: createAction(prefix + 'deleteOne', props<{ Payload: number }>()),

    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<EmployeeResourceScheduleEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<EmployeeResourceScheduleEntity>; updateLatestUpdatedAt?: boolean }>()),
    RemoveOne: createAction(prefix + 'removeOne', props<{ Payload: number }>()),
};
