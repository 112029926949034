import { createEntityAdapter,EntityState } from '@ngrx/entity';
import { createReducer,on } from '@ngrx/store';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { EmployeeSalaryTypeActions } from "../actions/employee-salary-type.actions";
import { EmployeeSalaryTypeEntity } from "../../entities/employee-salary-type.entity";

export const EMPLOYEE_SALARY_TYPE_FEATURE_KEY = 'employeeSalaryType';

export interface EmployeeSalaryTypeState extends EntityState<EmployeeSalaryTypeEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<EmployeeSalaryTypeEntity>({
    selectId: Entity => Entity.Id,
});

const initialState: EmployeeSalaryTypeState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const employeeSalaryTypeReducer = createReducer(
    initialState,
    on(EmployeeSalaryTypeActions.updateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, {
            ...state,
            fetched: true,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(EmployeeSalaryTypeActions.updateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(EmployeeSalaryTypeActions.updateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
    on(EmployeeSalaryTypeActions.removeOne, (state, { Payload }) => adapter.removeOne(Payload, state)),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllEmployeeSalaryType = selectAll;
export const selectEmployeeSalaryTypeEntities = selectEntities;

export const selectEmployeeSalaryTypeFetched = (state: EmployeeSalaryTypeState) => state.fetched;
export const selectEmployeeSalaryTypeLatestUpdatedAt = (state: EmployeeSalaryTypeState) => state.latestUpdatedAt;
