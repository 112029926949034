import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../State';
import { ResourceTypeActionTypes } from '../../State/actions/resource-dispo/resource-type.actions';
import {
    getResourceTypeLatestUpdatedAt,
    getResourceTypesFetched,
} from '../../State/selectors/resource-dispo/resource-type.selectors';
import { BaseEntityReloadResolver2 } from '../resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class ResourceTypeResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getResourceTypesFetched, getResourceTypeLatestUpdatedAt, ResourceTypeActionTypes.Load, store);
    }
}
