import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule, formatNumber } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    Optional,
    Self,
    ViewChild
} from "@angular/core";
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { Subject } from 'rxjs';
import { isNotNullOrUndefined } from '../helper/helper';

type timeObj = {
    hour: number;
    minute: number;
};
function timeObjToString(val: timeObj): string {
    return `${val.hour.toString().padStart(2, '0')}:${val.minute.toString().padStart(2, '0')}`;
}
function isTimeStringValid(val: string) {
    return val && val.length === 5 && val.split(':').length === 2;
}
function timeObjFromString(val: string): timeObj {
    if (isTimeStringValid(val)) {
        const [h, m] = val.split(':').map((s) => +s);
        if (h <= 23 && h >= 0 && m <= 59 && m >= 0) {
            return { hour: h, minute: m };
        }
    }
    console.log(val);
    throw 'invalid value';
}
function timeObjToMinutes(val: timeObj): number {
    return val.hour * 60 + val.minute;
}
function timeObjFromMinutes(val: number): timeObj {
    return { hour: Math.floor(val / 60), minute: val % 60 };
}
@Component({
    selector: 'app-time-picker-select[formControlName], ' + 'app-time-picker-select[formControl], ' + 'app-time-picker-select[ngModel]',
    standalone: true,
    imports: [CommonModule, MatSelectModule],
    templateUrl: './time-picker-select.component.html',
    styleUrls: ['./time-picker-select.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: TimePickerSelectComponent,
        },
    ],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[id]': 'id',
    },
})
export class TimePickerSelectComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy {
    static nextId = 0;
    id = `app-time-picker-select-${TimePickerSelectComponent.nextId++}`;
    /** Name of the form control. */
    controlType = 'app-time-picker-select';
    protected _min: timeObj = { hour: 0, minute: 0 }; //'00:00';
    protected _max: timeObj = { hour: 23, minute: 59 }; //'23:59';
    protected _stepSize = 15; // in minutes;

    stateChanges = new Subject<void>();
    get panelOpen() {
        return this.matSelect.panelOpen;
    }

    options: { value: string; label: string }[] = [];
    private _offsetTimeForOptionLabel: string | null = null;
    @Input() set offsetTimeForOptionLabel(val: string) {
        this._offsetTimeForOptionLabel = val;
        this.createOptions();
    }
    @Input() set min(val: string) {
        try {
            let min = timeObjFromString(val);
            this._min = min;
            this.createOptions();
        } catch (e) {
            // console.error(e);
        }
    }
    @Input() set max(val: string) {
        try {
            let max = timeObjFromString(val);
            this._max = max;
            this.createOptions();
        } catch (e) {
            // console.log(e);
        }
    }
    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }
    protected _placeholder: string;
    constructor(
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
        @Inject(LOCALE_ID) public locale: string
    ) {
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
        //init options
        this.createOptions();
    }
    protected _value: string | null = null;

    private getOffsetString(minutes: number, offsetMinutes: number) {
        const timeDiff = minutes - offsetMinutes;
        if (timeDiff > 60) {
            return ' (' + formatNumber(timeDiff / 60, this.locale, '1.0-2') + ' Std.)'
        }
        return ' (' + timeDiff + ' Min.)'
    }
    private getOptionFromTimeObj(val: timeObj): { value: string; label: string } {
        const offsetMinutes = isNotNullOrUndefined(this._offsetTimeForOptionLabel) ? timeObjToMinutes(timeObjFromString(this._offsetTimeForOptionLabel)) : null;
        return { value: timeObjToString(val), label: offsetMinutes != null ? timeObjToString(val) + this.getOffsetString(timeObjToMinutes(val), offsetMinutes) : timeObjToString(val) };
    }
    createOptions() {
        if (this._min && this._max && this._stepSize) {
            this.options = [];
            let currTime: timeObj = { hour: 0, minute: 0 };
            while (timeObjToMinutes(currTime) <= timeObjToMinutes(this._max)) {
                if (timeObjToMinutes(currTime) >= timeObjToMinutes(this._min)) {
                    this.options.push(this.getOptionFromTimeObj(currTime));
                }
                currTime = timeObjFromMinutes(timeObjToMinutes(currTime) + this._stepSize);
            }
            if (this._value && !this.options.some((v) => v.value === this._value)) {
                this.options.push(this.getOptionFromTimeObj(timeObjFromString(this._value)));
                this.options.sort((a, b) => a.value.localeCompare(b.value));
            }
        }
    }

    set value(value: string | null) {
        if (value !== undefined && this._value !== value) {
            this._value = value;
            this.onChange(value);
            this.onTouch(value);
            this.stateChanges.next();
        }
    }
    get value() {
        return this._value;
    }
    onChange: any = () => {};
    onTouch: any = () => {};
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(obj: string | null): void {
        if (!obj || isTimeStringValid(obj)) {
            if (obj && !this.options.some((v) => v.value === obj)) {
                this.options.push(this.getOptionFromTimeObj(timeObjFromString(obj)));
                this.options.sort((a, b) => a.value.localeCompare(b.value));
            }
            this._value = obj;
        } else {
            throw 'invalid Value';
        }
    }
    touched = false;
    focused = false;

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouch();
            this.stateChanges.next();
        }
    }
    get empty() {
        return !this.value;
    }
    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    protected _disabled = false;
    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }
    @ViewChild('select') matSelect: MatSelect;
    setDescribedByIds(ids: string[]) {
        this.matSelect?._elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
    }
    onContainerClick() {
        this._focusMonitor.focusVia(this.matSelect._elementRef, 'program');
        if (!this.matSelect.panelOpen) {
            this.matSelect.open();
        }
    }
    get errorState(): boolean {
        return this.ngControl?.invalid && this.touched;
    }
    ngOnDestroy() {
        this.stateChanges.complete();
    }
}
