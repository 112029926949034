<mat-form-field>
    <mat-label>
        <span #label><ng-content select="[app-label]"></ng-content></span>
        <ng-container *ngIf="!label.children.length">{{Key}}</ng-container>
    </mat-label>
    <textarea
    cdkTextareaAutosize
        [cdkAutosizeMinRows]="minRows"
        [style]="MaxHeight ? 'max-height: ' + MaxHeight : ''"
        matInput
        [placeholder]="Key"
        [formControl]="Value"
        [required]="Required"
    ></textarea>
    <mat-error *ngIf="Value.invalid && (Value.dirty || Value.touched)">
        {{ GetErrorMessage(Value) }}
    </mat-error>
</mat-form-field>
