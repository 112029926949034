import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    ProcessState,
    selectAllProcess,
    selectProcessEntities,
    selectProcessFetched,
    PROCESS_FEATURE_KEY, selectProcessLatestUpdatedAt,
} from '../reducers/process.reducer';

const selectState = createFeatureSelector<ProcessState>(PROCESS_FEATURE_KEY);
export const getProcessDictionary = createSelector(selectState, selectProcessEntities);
export const getProcessLatestUpdatedAt = createSelector(selectState, selectProcessLatestUpdatedAt);
export const getProcessFetched = createSelector(selectState, selectProcessFetched);
export const getProcesses = createSelector(selectState, selectAllProcess);
export const getProcessesActive = createSelector(getProcesses, (activities) => activities.filter((process) => !process.DeletedAt));
export const getProcessById = (props: { id: number }) => createSelector(getProcessDictionary, (dictionary) => props.id && dictionary[props.id]);
