import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { ProcessTemplateEntity } from '../../../dave-data-module/entities/process-template.entity';
import { ProcessEntity } from '../../../dave-data-module/entities/process.entity';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { getCommissionById, getCommissionsFetched } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomerById, getCustomersFetched } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getEmployeeById, getEmployeesFetched } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getEventTypeById, getEventTypeFetched } from '../../../dave-data-module/State/selectors/event-type.selector';
import { getEventById, getEventFetched } from '../../../dave-data-module/State/selectors/events.selectors';
import { getGeneratedDocumentById, getGeneratedDocumentsFetched } from '../../../dave-data-module/State/selectors/generatedDocuments.selectors';
import { getLedgerImportById, getLedgerImportsFetched } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { getPartnerById, getPartnerFetched, getPartnerOfficesFetched, getPartnerWithMainOfficeByPartnerId } from '../../../dave-data-module/State/selectors/partners.selectors';
import { getPersonById, getPersonsFetched } from '../../../dave-data-module/State/selectors/person.selectors';
import { getProcessTemplateById, getProcessTemplateFetched } from '../../../dave-data-module/State/selectors/process-template.selector';
import { getProcessById, getProcessFetched } from '../../../dave-data-module/State/selectors/process.selector';
import { getResourceById, getResourcesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';

import { getTransmissionById, getTransmissionFetched } from '../../../dave-data-module/State/selectors/transmission.selector';
import { getUserById, getUsersFetched } from '../../../dave-data-module/State/selectors/users.selectors';
import { CustomLabelService } from '../../../services/custom-label.service';
import { isNotNullOrUndefined } from "../../../helper/helper";
import { getMaterialById, getMaterialsFetched } from '../../../dave-data-module/State/selectors/material.selector';

export enum SpecialRouteLabel {
    Customeradministration = 'Customeradministration',
    Commissionadministration = 'Commissionadministration',
    RessourcePlan = 'RessourcePlan',
    DMS = 'DMS',
}

@Injectable({
    providedIn: 'root',
})
export class RouteLabelService {
    constructor(private router: Router, private store: Store<State>, private cls: CustomLabelService) {}

    public GetLabelForRoute$(route: ActivatedRouteSnapshot): Observable<string> {
        // return of('test').pipe(delay(5 * 1000))
        console.log('GetLabelForRoute$')
        const data: string = route.data.breadcrumb;
        let label$: Observable<string | null> = of(null);
        if (data) {
            const parameter: string = route.paramMap.get(data);
            if (parameter) {
                console.log({data, parameter})
                // generate label from route parameter
                const id: number = +parameter;
                switch (data) {
                    case 'customerId':
                        label$ = getFetched$(this.store, getCustomersFetched, getCustomerById({ id })).pipe(map((c) => (c ? c.DisplayName : 'unbekannter Kunde')));
                        break;
                    case 'ledgerImportId':
                        label$ = getFetched$(this.store, getLedgerImportsFetched, getLedgerImportById({ id })).pipe(map((l) => (l ? (l.ConsolidatedInvoiceId || 'unbenannt') : 'unbekannte Rechnung')));
                        break;
                    case 'eventId':
                        label$ = getFetched$(this.store, getEventFetched, getEventById({ id })).pipe(
                            switchMap((e) => (e?.EventTypeId ? getFetched$(this.store, getEventTypeFetched, getEventTypeById({ id: e.EventTypeId })) : of(null))),
                            map((et) => (et ? et.Name : 'unbekannte Ereignisart')),
                        );
                        break;
                    case 'partnerId':
                        label$ = getFetched$(this.store, getPartnerFetched, getPartnerById({ id })).pipe(
                            switchMap((p) =>
                                p
                                    ? getFetched$(this.store, getPartnerOfficesFetched, getPartnerWithMainOfficeByPartnerId({ id })).pipe(map(({ partner, mainOffice }) => [partner.PartnerNo, mainOffice?.Name].filter((v) => !!v).join(' ')))
                                    : of(null),
                            ),
                            map((label) => label || 'unbekannter Partner'),
                        );
                        break;
                    case 'personId':
                        label$ = getFetched$(this.store, getPersonsFetched, getPersonById({ id })).pipe(
                            map((person) => (person ? (person.DisplayName?.length > 0 ? 'Person: ' + person.DisplayName : 'unbenannte Person') : 'unbekannte Person')),
                        );
                        break;
                    case 'employeeId':
                        label$ = getFetched$(this.store, getEmployeesFetched, getEmployeeById({ id })).pipe(map((b) => (b ? b.DisplayName : 'unbekannter Mitarbeiter')));
                        break;
                    case 'userId':
                        label$ = getFetched$(this.store, getUsersFetched, getUserById({ id })).pipe(map((u) => (u ? u.DisplayName : 'unbekannter Benutzer')));
                        break;
                    case 'commissionId':
                        label$ = getFetched$(this.store, getCommissionsFetched, getCommissionById({ id })).pipe(
                            switchMap((commission) => {
                                if (commission && commission.GetDisplayName()?.length > 0) {
                                    return of(commission.GetDisplayName());
                                } else {
                                    return this.cls.getSingle$('Commission').pipe(map((commissionLabel) => (commission ? 'unbenannter ' + commissionLabel : 'unbekannter ' + commissionLabel)));
                                }
                            }),
                        );
                        break;
                    case 'transmissionId':
                        label$ = getFetched$(this.store, getTransmissionFetched, getTransmissionById({ id })).pipe(map((transmission) => (transmission ? transmission.GetDisplayName() : 'unbekannte Aufnahme')));
                        break;
                    case 'documentId':
                        // generated document wird für diese route einzeln abgerufen, da gibt es keinen fetch selector
                        label$ = this.store.select(getGeneratedDocumentById({ id })).pipe(
                            filter(isNotNullOrUndefined),
                            map((generatedDocument) => generatedDocument.Name?.length > 0 ? generatedDocument.Name : 'unbenanntes Dokument'),
                        );
                        break;
                    case 'resourceId':
                        label$ = getFetched$(this.store, getResourcesFetched, getResourceById({ id })).pipe(map((resource) => (resource?.Name?.length > 0 ? resource.Name : 'unbekannte Ressource')));
                        break;
                    case 'materialId':
                        label$ = getFetched$(this.store, getMaterialsFetched, getMaterialById({ id })).pipe(map((material) => (material?.Name?.length > 0 ? material.Name : 'unbekanntes Material')));
                        break;
                    case 'processTemplateId':
                        label$ = getFetched$(this.store, getProcessTemplateFetched, getProcessTemplateById({ id })).pipe(
                            map((processTemplate) => (processTemplate?.Name?.length > 0 ? processTemplate.Name : 'unbekannte ' + ProcessTemplateEntity.EntityName)),
                        );
                        break;
                    case 'processId':
                        label$ = getFetched$(this.store, getProcessFetched, getProcessById({ id })).pipe(map((process) => (process?.Name?.length > 0 ? process.Name : 'unbekannter ' + ProcessEntity.EntityName)));
                        break;
                    case 'none':
                        label$ = of('none');
                        break;
                    default:
                        label$ = of('unbekannter Routenparameter');
                        break;
                }
            } else if (data === SpecialRouteLabel.Customeradministration) {
                label$ = this.cls.getMultiple$('Customer');
            } else if (data === SpecialRouteLabel.Commissionadministration) {
                label$ = this.cls.getSingle$('Commission');
            } else if (data === SpecialRouteLabel.RessourcePlan) {
                label$ = this.cls.getSingle$('RessourcePlan');
            } else if (data === SpecialRouteLabel.DMS) {
                label$ = of('DMS');
            } else {
                // generate label from route data
                label$ = of(data);
            }
        } else if (route.url[0]) {
            // generate label from url
            const url: string = route.url[0].path;
            label$ = of(url[0].toUpperCase() + url.slice(1).toLowerCase());
        }
        return label$.pipe(distinctUntilChanged());
    }

    /**
     * Gibt nach jedem `NavigationEnd` Router Event - und sofort nach dem Subscriben -
     * das Label für die tiefste Child Route aus
     */
    public get RouteLabel$() {
        const getDeepestRoute = (route: ActivatedRoute): ActivatedRoute => (route.firstChild ? getDeepestRoute(route.firstChild) : route);

        return this.router.events.pipe(
            // Künstliches Event, um direkt den ersten Wert auszugeben
            startWith(new NavigationEnd(null, this.router.url, null)),
            filter((e): e is NavigationEnd => e instanceof NavigationEnd),
            map(() => getDeepestRoute(this.router.routerState.root)),
            switchMap((route) => this.GetLabelForRoute$(route.snapshot)),
        );
    }
}
