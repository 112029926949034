import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { getErrorMessage } from '../../../../../helper/validation.helper';

export type MaterialColor = 'primary' | 'accent' | 'warn';

@Component({
    selector: 'app-numberfield',
    templateUrl: './numberfield.component.html',
    styleUrls: ['./numberfield.component.scss']
})
export class NumberfieldComponent {
    @Input() Key = '';
    @Input() FormControl: UntypedFormControl;
    @Input() Required = false;
    @Input() Color: MaterialColor = 'primary';
    @Input() Hint: string = null;
    @Input() Pattern: string | RegExp = null;

    public GetErrorMessage = getErrorMessage;
}
