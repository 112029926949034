import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    TAG_FEATURE_KEY, TagState,
    selectAllTag,
    selectTagEntities,
    selectTagFetched,
} from '../reducers/tag.reducer';

const selectTag = createFeatureSelector< TagState>(TAG_FEATURE_KEY);
const getTagEntitys = createSelector(selectTag, selectTagEntities);

export const getTags = createSelector(selectTag, selectAllTag);
export const getTagFetched = createSelector(selectTag, selectTagFetched);
export const getTagById = createSelector(
    getTagEntitys,
    (tag: ReturnType<typeof getTagEntitys>, Id: number) => Id && tag[Id],
);
