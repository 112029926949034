import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, of } from 'rxjs';
import { concatMap } from 'rxjs/internal/operators/concatMap';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { EmailConnectionActionTypes } from '../actions/email-connection.actions';
import { ResolverLoadUser } from '../actions/resolver.actions';
import { UsersActionTypes } from '../actions/users.actions';
import { State } from '../index';
import { getUser } from '../selectors/users.selectors';
import { DecodedTokenType } from '../../entities/token.entity';
import { getToken } from '../selectors/base.selectors';
enum ErrorCodes {
    Login = 'Keinen Benutzer mit dieser Kombination aus E-Mail und Passwort gefunden',
}

@Injectable()
export class DataEffects {

    Error$ = createEffect(() => this.actions$.pipe(
        ofType(BaseActionTypes.ErrorAction),
        withLatestFrom(this.store$.select(getUser), this.store$.select(getToken)),
        tap(([action, user, token]) => {
            console.warn(action.Payload.ToasterMessage);
            if (action.Payload.Err) {
                console.error(action.Payload.Err);
                if (action.Payload.Caught) {
                    console.error(action.Payload.Caught);
                }
            }
            const error = new Error(action.Payload.Err);
            error.message = action.Payload.ToasterMessage;
            Sentry.captureException(error, {
                user: {
                    id: user?.Id + '',
                    ip_address: '',
                    email: user?.Email,
                    username: user?.DisplayName,
                    userEntity: user,
                },
                level: 'error',
                contexts: {
                    auth: {Bearer: token},
                },
                tags: {
                    caught: action.Payload.Caught,
                },
            });
            this.apiToasterService.error(action.Payload.ToasterMessage, '', { timeOut: 5000 });
        }),
    ), { dispatch: false });

    TestNewEmail = createEffect(() => this.actions$.pipe(
        ofType(EmailConnectionActionTypes.TestNewEmail),
        withLatestFrom(this.store$),
        concatMap(([value, store]) => {
            const queryString = `
                mutation{
                    emailconnectiontest(
                        host: "${value.Payload.Host || ''}"
                        method: "${value.Payload.Method || ''}"
                        port: ${value.Payload.Port || ''}
                        username: "${value.Payload.Username || ''}"
                        type: "${value.Payload.Type || ''}"
                        password: "${value.Payload2 || ''}"
                    ) {
                        success
                        message
                    }
                }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map((res: any) => {
                    value.Payload.ConnectionTested = res.emailconnectiontest.success;
                    if (res) {
                        return EmailConnectionActionTypes.AddEmailConnection({
                            Payload: value.Payload,
                            Payload2: value.Payload2,
                        });
                    } else {
                        return BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: 'Test der Verbindung konnte nicht ausgeführt werden',
                            },
                        });
                    }
                }),
                catchError((err, caught) => {
                    console.error(err, caught);
                    this.apiToasterService.error('Test der Verbindung fehlgeschlagen');
                    return EMPTY;
                }),
            );
        }),
    ));


    CheckToken$ = createEffect(() => this.actions$.pipe(
        ofType(BaseActionTypes.CheckToken),
        switchMap(() =>
            this.gatewayHttpService.graphQl({ query: `query{login{token}}` }, { token: localStorage.getItem('token') }).pipe(
                map((res: any) => {
                    if (res && res.login && res.login.token) {
                        return BaseActionTypes.UpdateToken({ Payload: res.login.token });
                    } else {
                        localStorage.removeItem('token');
                        window.location.href = '/login';
                        return BaseActionTypes.ErrorAction({
                            Payload: { ToasterMessage: 'Login kann nicht verifiziert werden!' },
                        });
                    }
                }),
                catchError((err, caught) => {
                    this.apiToasterService.error('Loginversuch fehlgeschlagen');
                    return EMPTY;
                }),
            ),
        ),
    ));

    PerformLogin$ = createEffect(() => this.actions$.pipe(
        ofType(BaseActionTypes.PerformLogin),
        switchMap(action => {
            const queryString = `
            query {
                login(
                    username: "${action.Payload.Username}"
                    password: "${action.Payload.Password}"
                ) {
                    token
                }
            }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, null).pipe(
                map(res => {
                    if (res && res.login && res.login.token) {
                        return BaseActionTypes.UpdateToken({ Payload: res.login.token });
                    } else {
                        this.store$.dispatch(BaseActionTypes.UpdateToken({ Payload: '' }));
                        return BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Login } });
                    }
                }),
                catchError((err, caught) => {
                    of([
                            this.store$.dispatch(BaseActionTypes.UpdateToken({ Payload: '' })),
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Login,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ]);
                    return EMPTY;
                    },
                ),
            );
        }),
    ));

    UpdateToken$ = createEffect(() => this.actions$.pipe(
        ofType(BaseActionTypes.UpdateToken),
        map(action => {
            localStorage.setItem('token', action.Payload);
            const decToken = this.GetDecodeAccessToken(action.Payload);
            return decToken
                ? new ResolverLoadUser({ id: +decToken.userId })
                : UsersActionTypes.UpdateUser({
                      Payload: null,
                  });
        }),
    ));

    ChangePassword$ = createEffect(() => this.actions$.pipe(
        ofType(BaseActionTypes.ChangePassword),
        withLatestFrom(this.store$),
        switchMap(([value, store]) =>
            this.gatewayHttpService
                .graphQl(
                    {
                        query: `
            mutation{
                changePassword(oldPassword: "${value.Payload.oldPassword}", newPassword: "${value.Payload.newPassword}"){
                    id
                }
            }`,
                    },
                    { token: store.base.token },
                )
                .pipe(
                    map(res => {
                        if (res.changePassword?.id) {
                            this.apiToasterService.success('Passwort wurde geändert');
                        } else {
                            this.apiToasterService.error('Passwort konnte nicht geändert werden');
                        }
                        return EMPTY;
                    }),
                    catchError((err, caught) => {
                        console.error(err, caught);
                        this.apiToasterService.error('Passwort konnte nicht geändert werden');
                        return EMPTY;
                    }),
                ),
        ),
    ), { dispatch: false });

    ResetPassword$ = createEffect(() => this.actions$.pipe(
        ofType(BaseActionTypes.ResetPassword),
        switchMap(action =>
            this.gatewayHttpService
                .graphQl(
                    {
                        query: 'mutation{resetUser(email: "' + action.Payload.Email + '")}',
                    },
                    null,
                )
                .pipe(
                    map(() => {
                        this.apiToasterService.success('Password wurde erfolgreich zurückgesetzt!');
                    }),
                    catchError((err, caught) => {
                        console.error(err, caught);
                        this.apiToasterService.error('Passwort konnte nicht zurückgesetzt werden');
                        return EMPTY;
                    }),
                ),
        ),
    ), { dispatch: false });

    ActivateUser$ = createEffect(() => this.actions$.pipe(
        ofType(BaseActionTypes.ActivateUser),
        switchMap(action =>
            this.gatewayHttpService
                .graphQl(
                    {
                        query: `
            mutation{
                activateUser(
                    verificationCode: "${action.Payload.verificationCode}",
                    password: "${action.Payload.password}",
                    userId: ${action.Payload.userId}){
                        id
                    }
            }`,
                    },
                    null,
                )
                .pipe(
                    map((ret: any) => {
                        if (ret && ret.activateUser && ret.activateUser.id > 0) {
                            this.apiToasterService.success('Konto wurde erfolgreich aktiviert!');
                            return EMPTY;
                        } else {
                            this.apiToasterService.error('Konto konnte nicht aktiviert werden! Bitte wenden Sie sich an den Administrator.');
                            return EMPTY;
                        }
                    }),
                    catchError(() => {
                        this.apiToasterService.error('Konto konnte nicht aktiviert werden! Bitte wenden Sie sich an den Administrator.');
                        return EMPTY;
                    }),
                ),
        ),
    ), { dispatch: false });

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private apiToasterService: ToastrService, private gatewayHttpService: HttpService) {}

    GetDecodeAccessToken(token: string) {
        try {
            return jwt_decode<DecodedTokenType>(token);
        } catch (e) {
            return null;
        }
    }
}
