import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { State } from '../../../dave-data-module/State';
import { getFiles } from '../../../dave-data-module/State/selectors/files.selectors';
import { isNotNullOrUndefined } from '../../../helper/helper';
import {
    DaveFileUploadDialogComponent,
    DaveFileUploadDialogComponentDialogData
} from '../../../components/templates/new-document-view/component/dave-file-upload-dialog/dave-file-upload-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    DaveFilePreviewComponent, DaveFilePreviewComponentDialogData,
} from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import {
    BreakpointObserverService
} from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { FileExplorerComponent } from '../../../dave-file-explorer/components/file-explorer/file-explorer.component';

@Component({
    selector: 'app-dms-wrapper',
    template: `
        <app-file-explorer
            PreventFileClick
            PreventFileUploadClick
            *ngIf="!ShowScan2Dave"
            (FileClicked)="FileDialog?.close(); PreviewedFileId$.next($event)"
            (FileUploadClicked)="UploadTargetFolderId = $event; PreviewedFileId$.next(null); OpenUploadDialog({FolderId: UploadTargetFolderId})"
            (Scan2DaveClicked$)="this.FolderId$.next($event);ShowScan2Dave = true;"
            [ShowScan2DaveButton]="true"
            [FileUploadButton]="true"
            [SelectedFolderId]="FolderId$ | async"
            [ShowTree]="!(BOS.MobileQuery | async)"
        ></app-file-explorer>
        <app-scan2dave-list *ngIf="ShowScan2Dave"
                            (Scan2DaveClicked$)=" ShowScan2Dave = false;"
                            (FileClicked)="FileDialog?.close(); PreviewedFileId$.next($event)"
        ></app-scan2dave-list>
    `,
    styleUrls: ['./dms-wrapper.component.scss'],
})
export class DmsWrapperComponent implements OnInit, OnDestroy {
    @ViewChild(FileExplorerComponent) fileExplorerComponent: FileExplorerComponent;

    public ShowScan2Dave = false;
    public UploadTargetFolderId: number = null;
    public PreviewedFileId$ = new BehaviorSubject<number>(null);
    public FolderId$ = new BehaviorSubject<number>(null);
    public FileDialog: MatDialogRef<DaveFileUploadDialogComponent>;
    public PreviewDialog: MatDialogRef<DaveFilePreviewComponent>;
    private subs: Subscription[] = [];
    constructor(private store: Store<State>, private route: ActivatedRoute, protected router: Router, private dialog: MatDialog, public BOS: BreakpointObserverService) {
    }

    public OpenUploadDialog(data: DaveFileUploadDialogComponentDialogData) {
        this.FileDialog = this.dialog.open<DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData>(DaveFileUploadDialogComponent, {
            ...DaveFileUploadDialogComponent.DefaultConfig,
            data,
        });
    }

    ngOnInit(): void {
        this.subs.push(
            this.route.queryParamMap
                .pipe(
                    tap(
                        q =>
                            this.route.snapshot.queryParams?.documentId &&
                            this.PreviewedFileId$.next(+this.route.snapshot.queryParams.documentId),
                    ),
                    map(q => this.route.snapshot.queryParams?.documentId),
                    filter(isNotNullOrUndefined),
                    distinctUntilChanged(),
                    withLatestFrom(this.store.select(getFiles)),
                    map(([documentId, files]) => files.find(f => f.Id === +documentId)?.FolderId),
                    tap(id => {
                        this.FolderId$.next(id)
                    }),
                )
                .subscribe(),
            this.route.queryParamMap
                .pipe(
                    tap(
                        q => {
                            const fId = Number(q.get('folderId'));
                            if (fId !== this.FolderId$.value) {
                                this.FolderId$.next(fId);
                            }
                        }
                    ),
                )
                .subscribe(),
            this.PreviewedFileId$.subscribe(id => {
                if (id) {
                    firstValueFrom(this.fileExplorerComponent ? this.fileExplorerComponent.getFileIdsFromCurrentDirectory$() : of(null)).then(fileIds => {
                        this.FileDialog?.close();
                        this.PreviewDialog?.close('dontTriggerPreviewedFileId$');
                        this.PreviewDialog = this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
                            ...DaveFilePreviewComponent.DefaultConfig,
                            data: {
                                fileId: id,
                            fileExplorerContext: fileIds ? {
                                directoryFileIds: fileIds,
                            } : undefined,},
                        });
                        this.PreviewDialog.afterClosed().pipe(take(1)).subscribe(res => {
                            if (res !== 'dontTriggerPreviewedFileId$') {
                                this.PreviewedFileId$.next(null);
                            }
                        });
                    })
                } else if (id == null) {
                    this.PreviewDialog?.close('dontTriggerPreviewedFileId$');
                    this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: {
                            documentId: null
                        },
                        queryParamsHandling: 'merge',
                    });
                }
            }),
        );
    }
    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

}
