import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { FolderUserEntity, FolderUserEntityFromBackend } from '../../entities/folder-user.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { FilesActionTypes } from '../actions/files.actions';
import { FolderUserActionTypes } from '../actions/folder-user';
import { FolderActionTypes } from '../actions/folder.actions';
import { State } from '../index';
import { getFiles } from '../selectors/files.selectors';
import { getFolderById, getFolders } from '../selectors/folder.selectors';
import { getToken } from '../selectors/base.selectors';
import { retryWithBackoff } from '../../helper/http-service.helper';

enum ErrorCodes {
    Remove = 'Ordner Benutzer Löschen fehlgeschlagen',
    Load = 'Ordner Benutzer abrufen fehlgeschlagen',
    Add = 'Ordner Benutzer hinzufügen fehlgeschlagen',
}

@Injectable()
export class FolderUserEffects {

    GetUsersFromFolderQuery$ = createEffect(() => this.actions$.pipe(
        ofType(FolderUserActionTypes.GetFolderUser),
        withLatestFrom(this.store$.select(getToken)),
        switchMap(([action, token]) => {

            let headers = new HttpHeaders();
            headers = headers.set('Content-Type', 'application/json');
            headers = headers.set('Authorization', 'Bearer ' + token);

            return this.http.get<any[]>(this.gatewayHttpService.GetUrl('folder-user', 'file') + '?folder_id=' + action.Payload, {
                headers
            }).pipe(
                retryWithBackoff(),
                withLatestFrom(this.store$.select(getFolderById({id: action.Payload}))),
                map(([res, folder]) => FolderActionTypes.UpdateOne({
                    Payload: folder.Clone({Users: res.map(u => FolderUserEntityFromBackend(u))})
                })),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    AddFolderUser$ = createEffect(() => this.actions$.pipe(
        ofType(FolderUserActionTypes.AddFolderUserRequest),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{Payload}, token]) => {
            const queryString = `
        mutation {
            createFolderUser(
                ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'folderId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'edit')}
            ) {
                folderUsers {${FolderUserEntity.GqlFields}}
          }
        }`;

            return this.gatewayHttpService.graphQl({query: queryString}, {token}).pipe(
                withLatestFrom(this.store$.select(getFolders)),
                withLatestFrom(this.store$.select(getFiles)),
                concatMap(([[res, folders], files]) =>
                    res && res.createFolderUser
                        ? [
                            FolderActionTypes.UpdateAll({
                                Payload: [
                                    ...folders.map(f => f.Id === Payload.folderId ? f.Clone({Users: res.createFolderUser.folderUsers.map(u => FolderUserEntityFromBackend(u))}) : (f.Users !== null ? f.Clone({Users: null}) : f))
                                ]
                            }),
                            FilesActionTypes.UpdateMany({
                                Payload: files.map(f => f.Users !== null ? f.Clone({Users: null}) : f)
                            }),
                            FolderUserActionTypes.AddFolderUserSuccess(),
                        ]
                        : [BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Add}})],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    DeleteFolderUser$ = createEffect(() => this.actions$.pipe(
        ofType(FolderUserActionTypes.DeleteFolderUserRequest),
        withLatestFrom(this.store$),
        concatMap(([{Payload}, store]) => {
            const queryString = `
        mutation {
            deleteFolderUser(
                ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'folderId')}
            ) {
                 folderUsers {${FolderUserEntity.GqlFields}}
          }
        }`;

            return this.gatewayHttpService.graphQl({query: queryString}, {token: store.base.token}).pipe(
                withLatestFrom(this.store$.select(getFolders)),
                withLatestFrom(this.store$.select(getFiles)),
                concatMap(([[res, folders], files]) =>
                    res && res.deleteFolderUser
                        ? [
                            FolderActionTypes.UpdateAll({
                                Payload: [
                                    ...folders.map(f => f.Id === Payload.folderId ? f.Clone({Users: res.deleteFolderUser.folderUsers.map(u => FolderUserEntityFromBackend(u))}) : (f.Users !== null ? f.Clone({Users: null}) : f))
                                ]
                            }),
                            FilesActionTypes.UpdateMany({
                                Payload: files.map(f => f.Users !== null ? f.Clone({Users: null}) : f)
                            }),
                            FolderUserActionTypes.DeleteFolderUserSuccess(),
                        ]
                        : [BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Add}})],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService, private http: HttpClient) {
    }
}
