import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ResourceToCommissionEntity } from '../../../entities/resource-dispo/resource-to-commission.entity';
import { createReducer, on } from '@ngrx/store';
import { ResourceToCommissionsActionTypes } from '../../actions/resource-dispo/resource-to-commission.actions';
import { getLatestUpdatedAt } from '../../../helper/reducer.helper';

export const RESOURCE_TO_COMMISSION_KEY = 'resourceToCommission';

export interface ResourceToCommissionState extends EntityState<ResourceToCommissionEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;

}
const adapter = createEntityAdapter<ResourceToCommissionEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const resourceToCommissionReducer = createReducer(
    initialState,
    on(ResourceToCommissionsActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(ResourceToCommissionsActionTypes.RemoveOne, (state, { Payload }) =>
        adapter.removeOne(Payload, state),
    ),
    on(ResourceToCommissionsActionTypes.UpdateOne, (state, { Payload }) =>
        adapter.setOne(Payload, state)
    ),
    on(ResourceToCommissionsActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllResourceToCommission = selectAll;
export const selectResourceToCommissionEntities = selectEntities;
export const selectResourceToCommissionFetched = (state: ResourceToCommissionState)=> state.fetched;
export const selectResourceToCommissionLatestUpdatedAt = (state: ResourceToCommissionState) => state.latestUpdatedAt;
