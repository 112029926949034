import { Injectable } from '@angular/core';
import Twig from 'twig';

@Injectable({
    providedIn: 'root',
})
export class SfdtTemplateService {
    constructor() {
        Twig.extendFunction('newDate', () => new Date());
    }

    public FillTemplateByTwig(twigTemplate: string, content: object, namespaces = {}): string {
        return Twig.twig({ data: twigTemplate, namespaces }).render(content);
    }
}
