import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AkkordEntity } from '../../../entities/akkord/akkord.entity';
import { AkkordActionTypes } from '../../actions/akkord.action';
export const AKKORD_KEY = 'akkord';

export interface AkkordState extends EntityState<AkkordEntity> {
    fetched: boolean;
}

const adapter = createEntityAdapter<AkkordEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const akkordReducer = createReducer(
    initialState,
        on(AkkordActionTypes.UpdateAll, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllAkkord = selectAll;
export const selectAkkordEntities = selectEntities;

export const selectAkkordFetched = (state: AkkordState) => state.fetched;
