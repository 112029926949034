import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Person2EntityEntity } from '../../entities/person2entity.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { Person2EntityActionTypes } from '../actions/person2entity.action';

export const person2EntityFeatureKey = 'person2Entity';

export interface Person2EntityState extends EntityState<Person2EntityEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<Person2EntityEntity>({
    selectId: (Entity) => Entity.Id,
});
const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});
export const person2EntityReducer = createReducer(
    initialState,
    on(Person2EntityActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, { ...state })),
    on(Person2EntityActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(Person2EntityActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllPerson2Entity = selectAll;
export const selectPerson2EntityEntities = selectEntities;

export const selectPerson2EntityFetched = (state: Person2EntityState) => state.fetched;
export const selectPerson2EntityLatestUpdatedAt = (state: Person2EntityState) => state.latestUpdatedAt;
