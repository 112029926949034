<ng-container *ngIf="Data$ | async as Data">
    <!-- mobile Ansicht -->
    <mat-tab-group
        mat-align-tabs="center"
        animationDuration="500ms"
        *ngIf="Data.IsMobile; else desktopView"
        class="tab-group"
    >
        <mat-tab [label]="TabLabel">
            <ng-template matTabContent>
                <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
            </ng-template>
        </mat-tab>

        <mat-tab *ngFor="let tab of Tabs" [label]="tab.Label">
            <ng-template matTabContent>
                <ng-container [ngTemplateOutlet]="tab.Template"></ng-container>
            </ng-template>
        </mat-tab>

        <mat-tab [label]="tab1label$ | async" *ngIf="tab1exists$ | async" #tab1>
            <ng-template matTabContent>
                <ng-container [ngTemplateOutlet]="tab1template"></ng-container>
            </ng-template>
        </mat-tab>
        <mat-tab [label]="tab2label$ | async" *ngIf="tab2exists$ | async" #tab2>
            <ng-template matTabContent>
                <ng-container [ngTemplateOutlet]="tab2template"></ng-container>
            </ng-template>
        </mat-tab>
        <mat-tab [label]="routerTabLabel$ | async" *ngIf="primaryExists$ | async" #routerTab>
            <ng-template matTabContent>
                <ng-container [ngTemplateOutlet]="routerOutlet"></ng-container>
            </ng-template>
        </mat-tab>
    </mat-tab-group>

    <!-- Desktop-Ansicht -->
    <ng-template #desktopView>
        <div
            class="content"
            [class.selected]="Data.IsSelected"
            [class.has-side-content]="Tabs.length"
            [class.hide]="!(ContentIsVisible$ | async)"
        >
            <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
        </div>

        <div
            class="router-outlet"
            [class.selected]="Data.IsSelected"
            [class.has-side-content]="Tabs.length"
            [class.full-width]="!(ContentIsVisible$ | async)"
        >
            <button *ngIf="Data.IsSelected" mat-icon-button class="toggle-view-button" (click)="HideSideContent$.next(!HideSideContent$.value)"><fa-icon [icon]="(HideSideContent$ | async) ? 'chevron-right' : 'chevron-left'"></fa-icon></button>
            <ng-container [ngTemplateOutlet]="tab1template" *ngIf="(tab1exists$ | async) && !(tab2exists$ | async) && !(primaryExists$ | async)"></ng-container>
            <ng-container [ngTemplateOutlet]="tab2template" *ngIf="(tab2exists$ | async) && !(primaryExists$ | async)"></ng-container>
            <ng-container [ngTemplateOutlet]="routerOutlet" *ngIf="primaryExists$ | async"></ng-container>
        </div>
    </ng-template>
</ng-container>

<!--
    Angular sucht nur das erste unbenannte router-outlet und den ersten ng-content Marker ohne
    Selektor, um dort Inhalte einzufügen - auch wenn durch ein *ngIf sichergestellt ist, dass
    Angular immer nur eines dieser Outlets gleichzeitig auf der Seite rendert.

    Workaround: ngTemplates benutzen und diese Templates statt der Outlets in die *ngIfs schreiben
-->

<ng-template #ngContent>
    <ng-content></ng-content>
</ng-template>

<ng-template #routerOutlet>
    <router-outlet></router-outlet>
</ng-template>
<ng-template #tab1template>
    <router-outlet name="tab1"></router-outlet>
</ng-template>
<ng-template #tab2template>
    <router-outlet name="tab2"></router-outlet>
</ng-template>
