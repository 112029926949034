import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, bufferCount, combineLatest, firstValueFrom, merge, Observable, switchMap } from 'rxjs';
import { CkEditorComponent } from '../../../ck-editor/ck-editor.component';
import { FileEntity, FileMetaData } from '../../../dave-data-module/entities/file.entity';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';

import { MatDialog } from '@angular/material/dialog';
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Link } from '@ckeditor/ckeditor5-link';
import { List } from '@ckeditor/ckeditor5-list';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Table } from '@ckeditor/ckeditor5-table';
import { Store } from '@ngrx/store';
import { DropzoneConfig, DropzoneDirective, DropzoneModule } from 'ngx-dropzone-wrapper';
import { map, shareReplay, take } from 'rxjs/operators';
import { State } from '../../../dave-data-module/State';
import { DaveActions } from '../../../dave-data-module/State/actions/actions';
import { CommentActionTypes } from '../../../dave-data-module/State/actions/comment.action';
import { FilesActionTypes, FileUploadParams } from '../../../dave-data-module/State/actions/files.actions';
import { ErrorCodesFileActions } from '../../../dave-data-module/State/effects/file.effects';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getCommentById } from '../../../dave-data-module/State/selectors/comment.selector';
import { getEmployeeByUserId } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { DaveDoubleIconModule } from '../../../dave-double-icon/dave-double-icon.module';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { CustomToolbarButton } from '../../../helper/ckeditor.helper';
import { LengthPipe } from '../../../helper/length.pipe';
import { LoadingService } from '../../../services/loading.service';
import { SmallFileCardModule } from '../../../small-file-card/small-file-card.module';

interface CommentCardData {
    author: string;
    isEditing$: Observable<boolean>;
    itsMe: boolean;
    editedAt: null | Date;
    createdAt: string;
    commentId: number;
    commentText: string;
    unSeen: boolean;
    documentIds: number[];
}
function setParams(params: FileUploadParams) {
    if (params.has('metadata')) {
        params.set('metadata', JSON.stringify({ ...JSON.parse(params.get('metadata')), isInComment: true } as FileMetaData));
    } else {
        params.set('metadata', JSON.stringify({ isInComment: true } as FileMetaData));
    }
    return params;
}
@Component({
    selector: 'app-comment-card',
    standalone: true,
    imports: [CommonModule, AppButtonModule, FontAwesomeModule, MatCardModule, MatTooltipModule, CkEditorComponent, DaveDoubleIconModule, MatButtonModule, SmallFileCardModule, DropzoneModule, LengthPipe],
    templateUrl: './comment-card.component.html',
    styleUrls: ['./comment-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentCardComponent {
    protected commentId$ = new BehaviorSubject<number>(null);
    @Input() set CommentId(value: number) {
        this.commentId$.next(value);
    }
    @Input() FileUploadParams: FileUploadParams;
    protected forceDeletePermission$ = new BehaviorSubject<boolean>(false);

    @Input() set CanDelete(value: boolean) {
        this.forceDeletePermission$.next(value);
    }
    @Input() HideDropzone = false;

    @ViewChild('dropzone') dropzone: DropzoneDirective; //DropzoneComponent;

    protected comment$ = this.commentId$.pipe(
        switchMap((id) => this.store.select(getCommentById({ id }))),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    protected itsMe$ = combineLatest([this.comment$, this.store.select(getUser)]).pipe(
        map(([comment, user]) => {
            return comment?.UserId === user?.Id;
        }),
    );
    protected canEdit$ = this.itsMe$;
    protected canDelete$ = combineLatest([this.itsMe$, this.forceDeletePermission$]).pipe(map((values) => values.some((v) => v)));
    protected unSeen$ = this.comment$.pipe(map((c) => c.LastSeenAt == null || c.UpdatedAt.getTime() > c.LastSeenAt.getTime()));
    protected editedAt$ = this.comment$.pipe(map((c) => (c.CreatedAt.getTime() !== c.UpdatedAt.getTime() ? c.UpdatedAt : null)));
    protected author$ = this.comment$.pipe(
        switchMap((c) => this.store.select(getEmployeeByUserId({ userId: c.UserId }))),
        map((e) => e?.DisplayName),
    );
    protected isEditing$ = new BehaviorSubject<boolean>(false);
    protected fileCount$ = new BehaviorSubject(0);
    protected UploadFileBuffer = [];
    protected EditorContent = '';
    protected dropzoneConfig$ = this.store.select(getToken).pipe(
        map(
            (token) =>
                new DropzoneConfig({
                    autoProcessQueue: false,
                    addRemoveLinks: false,
                    dictRemoveFileConfirmation: null, // macht dialog weg
                    dictDefaultMessage: 'Klicken oder Dateien hinein ziehen',
                    uploadMultiple: false,
                    clickable: true,
                    paramName: () => 'file',
                    parallelUploads: 100,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    // params: this.FileUploadParams,
                    // previewsContainer: '#dropzone-previews',
                }),
        ),
    );

    public CKEditorConfig: EditorConfig = {
        plugins: [
            Essentials,
            Paragraph,
            Bold,
            Italic,
            Link,
            List,
            BlockQuote,
            Table,
            CustomToolbarButton(
                'fileUpload',
                () => {
                    this.dropzone?.dropzone()?.element?.click();
                },
                {
                    withText: false,
                    tooltip: 'Datei anhängen',
                    isToggleable: false,
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="0.75em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"/></svg>',
                },
            ),
        ],
        toolbar: ['fileUpload', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', '|', 'undo', 'redo'],
    };

    constructor(private store: Store<State>, private actions$: Actions<DaveActions>, protected dialog: MatDialog, protected LS: LoadingService, protected BS: BreakpointObserverService) {}
    async SaveComment(/*id?: number*/ /*, fromInlineEditor?: boolean*/) {
        const id = this.commentId$.value;

        console.log('save comment', id)


        this.LS.startLoading('saveComment' + id, { dialogMessage: 'Ihr Kommentar wird Gespeichert' });
        this.actions$.pipe(ofType(CommentActionTypes.UpdateOne, BaseActionTypes.ErrorAction), take(1)).subscribe(() => {
            // if (fromInlineEditor) {
            //     this.InlineEditorContent = '';
            // } else {
            // this.SetEditor('');
            // }
            this.LS.endLoading('saveComment' + id);
        });
        if (id) {
            this.store.dispatch(
                CommentActionTypes.Change({
                    Payload: {
                        id,
                        text: /*fromInlineEditor ? this.InlineEditorContent :*/ this.EditorContent,
                    },
                }),
            );
        } /*else {
            let entityId = this.entityId$.value;
            if (!entityId) {
                entityId = await this.GetEntityIdBeforeSaveComment().catch((reason) => {
                    throw new Error('Kommentar konnte nicht gespeichert werden! ' + reason);
                });
            }
            if (!entityId) {
                return;
            }
            this.entityId$.next(entityId);

            const dispatchAction = (fileIds: number[] = []) => {
                this.store.dispatch(
                    CommentActionTypes.Create({
                        Payload: {
                            entityId,
                            entityType: this.entityType$.value,
                            text: fromInlineEditor ? this.InlineEditorContent : this.EditorContent,
                            documentIds: fileIds,
                        },
                    }),
                );
            };

            const dropzoneInstance: any /!*Dropzone*!/ = this.dropzone?.dropzone(); // this.dropzone?.nativeElement?.dropzone;
            const files: File[] = dropzoneInstance?.files;
            if (files?.length) {
                (this.GetFileUploadParamsBeforeSaveComment ? this.GetFileUploadParamsBeforeSaveComment() : firstValueFrom(of(this.FileUploadParams))).then((params) => {

                    const filesUploaded: FileEntity[] = [];
                    dropzoneInstance.options.params = Object.fromEntries(setParams(new Map(params)));
                    dropzoneInstance.processQueue();
                    lastValueFrom(
                        this.dropzone.DZ_SUCCESS.pipe(
                            tap((s) => filesUploaded.push(FileEntityFromFileBackend(s[1][0]))),
                            takeUntil(this.dropzone.DZ_QUEUECOMPLETE),
                        ),
                    ).then(() => {
                        dispatchAction(filesUploaded.map((f) => f.Id));
                        this.dropzone.reset();
                    });
                })
            } else {
                dispatchAction();
            }
        }*/
    }

    DeleteComment() {
        const id = this.commentId$.value;
        this.LS.startLoading('deleteComment' + id, { dialogMessage: 'Ihr Kommentar wird Gelöscht' });
        merge(this.actions$.pipe(ofType(CommentActionTypes.RemoveOne)), this.actions$.pipe(ofType(BaseActionTypes.ErrorAction)))
            .pipe(take(1))
            .subscribe(() => {
                this.LS.endLoading('deleteComment' + id);
            });
        this.store.dispatch(
            CommentActionTypes.Delete({
                Payload: {
                    id,
                },
            }),
        );
    }
    onRemoveFileClick(id: number) {
        const commentId = this.commentId$.value;
        this.store.dispatch(FilesActionTypes.DeleteFileRequest({ Payload: { DocumentId: id.toString() } }));
        firstValueFrom(this.comment$).then(({ DocumentIds }) => {
            this.store.dispatch(CommentActionTypes.Change({ Payload: { id: commentId, documentIds: DocumentIds.filter((d) => d !== id) } }));
        });
    }

    onFileClick(id: number) {
        this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: {
                fileId: id,
            },
        });
    }
    onFileChange(event: Event) {
        const commentId = this.commentId$.value;
        firstValueFrom(this.comment$).then(({ DocumentIds }) => {
            const fileUploadParams = this.FileUploadParams;
            if (fileUploadParams) {
                if ((event.target as HTMLInputElement).files && (event.target as HTMLInputElement).files.length) {
                    firstValueFrom(this.actions$.pipe(ofType(FilesActionTypes.UploadFileBackendSuccess, FilesActionTypes.UploadFileBackendFailure), bufferCount((event.target as HTMLInputElement).files.length))).then((actions) => {
                        const ids = actions.filter((a) => a.type === FilesActionTypes.UploadFileBackendSuccess.type).map(({ Payload }) => (Payload as FileEntity).Id);
                        if (ids.length) {
                            this.store.dispatch(CommentActionTypes.Change({ Payload: { id: commentId, documentIds: [...DocumentIds, ...ids] } }));
                        }
                    });
                    for (const file of (event.target as HTMLInputElement).files) {
                        this.store.dispatch(
                            FilesActionTypes.UploadFileBackendRequest({
                                Payload: {
                                    file: file,
                                    params: setParams(new Map(fileUploadParams)).set('name', file.name),
                                },
                            }),
                        );
                    }
                }
            }
        });
    }
    dropzoneError($event) {
        this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodesFileActions.Upload } }));
    }
    calcFileCount() {
        this.fileCount$.next(this.dropzone?.dropzone()?.files?.length || 0);
    }

    protected SetEditor() {
        this.UploadFileBuffer = [];
        return firstValueFrom(this.comment$).then((comment) => {
            this.EditorContent = comment.Text;
        });
    }
    protected onEditClick() {
        this.SetEditor().then(() => {
            this.isEditing$.next(true);
        });
    }
    protected onAbortEditing() {
        this.SetEditor().then(() => this.isEditing$.next(false));
    }
}
