import { createActionGroup, props } from '@ngrx/store';
import { GetProcessTemplatesRequest } from '@dave/types/dist/proto/process/process';
import { ProcessTemplateEntity } from '../../entities/process-template.entity';


export const ProcessTemplateActions = createActionGroup({
    source: 'ProcessTemplate',
    events: {
        'Update All': props<{ Payload: ProcessTemplateEntity[], updateLatestUpdatedAt?: boolean }>(),
        'Update Many': props<{ Payload: ProcessTemplateEntity[], updateLatestUpdatedAt?: boolean }>(),

        'Load': props<{ Payload: GetProcessTemplatesRequest }>(),
    },
});
