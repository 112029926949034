import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { breadcrumbAnimation } from '../../../animations/breadcrumb.animation';
import { Breadcrumb, BreadcrumbsService } from '../../../services/breadcrumbs.service';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [breadcrumbAnimation],
})
export class BreadcrumbsComponent implements OnDestroy {
    public Data$: Observable<Breadcrumb[]> = this.breadcrumbsService.Breadcrumbs$;
    @ViewChild('breadcrumbScrollbar') private breadcrumbScrollbar?: ElementRef;

    private sub = this.Data$.subscribe(() => this.ScrollToEnd());
    constructor(private breadcrumbsService: BreadcrumbsService) {}
    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    // ngFor trackBy Funktion im HTML-Template
    public TrackByBreadcrumb(index: number, item: Breadcrumb): string {
        return '' + index + item.Url;
    }
    public ScrollToEnd() {
        if (this.breadcrumbScrollbar) {
            this.breadcrumbScrollbar.nativeElement.scrollLeft = this.breadcrumbScrollbar.nativeElement.scrollWidth;
        }
    }
}
