import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AppButtonModule } from '../app-button-module/app-button.module';
import { DaveSharedComponentsModule } from '../dave-shared-components-module/dave-shared-components.module';
import { AppConfirmationDialogComponent } from './app-confirmation-dialog/app-confirmation-dialog.component';

@NgModule({
    imports: [CommonModule, AppButtonModule, MatDialogModule, MatFormFieldModule, FormsModule, DaveSharedComponentsModule],
    declarations: [AppConfirmationDialogComponent],
})
export class AppDialogModule {}
