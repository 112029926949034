import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {combineLatest, Subject, Subscription} from 'rxjs';
import { filter, map, skip, take, tap } from 'rxjs/operators';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { EventTypeEntity } from '../../../dave-data-module/entities/event-type.entity';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { TransmissionEntity } from '../../../dave-data-module/entities/transmission.entity';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { DaveActions } from '../../../dave-data-module/State/actions/actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import { TransmissionActionTypes } from '../../../dave-data-module/State/actions/transmission.actions';
import { getCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { getTransmission } from '../../../dave-data-module/State/selectors/transmission.selector';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, OpenHTMLInputPicker } from '../../../helper/helper';
import { getErrorMessage, startTimeBeforeEndTimeValidator } from '../../../helper/validation.helper';
import { getEventTypes } from '../../../dave-data-module/State/selectors/event-type.selector';
export interface NewEventAndTransmissionComponentDialogData {
    CommissionId: number;
    CustomerId: number;
    CommissionTypeId: number;
}

@Component({
    selector: 'app-new-event-and-transmission',
    templateUrl: 'new-event-and-transmission.component.html',
    styleUrls: ['new-event-and-transmission.component.scss'],
})
export class NewEventAndTransmissionComponent implements OnInit, OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        hasBackdrop: false,
    };
    public OpenHTMLInputPicker = OpenHTMLInputPicker;
    public AddPersonClicked = new Subject()
    public PersonList: PersonEntity[] = [];
    // Other
    public GetErrorMessage = getErrorMessage;
    public UploadOpen = false;
    // 'updateOn: blur' sodass nicht permanent Events geworfen werden
    public NewEventForm = new UntypedFormGroup(
        {
            Person: new UntypedFormControl(''),
            EventDate: new UntypedFormControl('', Validators.required),
            EventEndDate: new UntypedFormControl('', Validators.required),
            EventStartTimeString: new UntypedFormControl('', {
                validators: Validators.required,
                updateOn: 'blur',
            }),
            EventEndTimeString: new UntypedFormControl('', {
                validators: Validators.required,
                updateOn: 'blur',
            }),
            Note: new UntypedFormControl('', {
                updateOn: 'blur',
            }),
            Name: new UntypedFormControl('Videoübertragung', {
                validators: Validators.required,
                updateOn: 'blur',
            }),
        },
        startTimeBeforeEndTimeValidator,
    ) as FormGroupTyped<{
        Person: PersonEntity;
        EventDate: Date;
        EventEndDate: Date;
        EventStartTimeString: string;
        EventEndTimeString: string;
        Note: string;
        Name: string;
    }>;
    public Customer: CustomerEntity = null;
    protected EventType: EventTypeEntity = null;
    private subscriptions: Subscription[] = [];
    constructor(
        private store: Store<State>,
        private router: Router,
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: NewEventAndTransmissionComponentDialogData,
        public CS: CustomerNameService,
        private actions$: Actions<DaveActions>,
        private dialog: MatDialog,
    ) {
        if (this.Dialogdata.CustomerId) {
            this.NewEventForm.controls.Person.setValue(null);
        }
    }

    get Error() {
        return this.NewEventForm.hasError('startTimeBeforeEndTimeValidator') ? 'Die Start-Zeit muss vor der Ende-Zeit liegen.' : '';
    }

    ngOnInit() {
        this.subscriptions.push(
            combineLatest([
                this.store.select(getPersons).pipe(
                    filter(isNotNullOrUndefined),
                    map(p => p.filter(pe => !pe.Deleted)),
                ),
                this.store.select(getPerson2Entities).pipe(
                    filter(isNotNullOrUndefined),
                    map(pes => pes.filter(pe => pe.EntityType === Person2EntityEntityTypeEnum.Customer || pe.EntityType === Person2EntityEntityTypeEnum.Commission)),
                ),
            ])
                .pipe(
                    map(([persons, person2Entities]) =>
                        this.Dialogdata.CustomerId || this.Dialogdata.CommissionId
                            ? persons.filter(p =>
                                  person2Entities.some(
                                      pe =>
                                          pe.PersonId === p.Id &&
                                          ((pe.EntityType === Person2EntityEntityTypeEnum.Customer && pe.EntityId === this.Dialogdata.CustomerId) ||
                                              (pe.EntityType === Person2EntityEntityTypeEnum.Commission && pe.EntityId === this.Dialogdata.CommissionId)),
                                  ),
                              )
                            : persons,
                    ),
                )
                .subscribe(c => this.PersonList = c),
            this.store.select(getCustomers).subscribe(customers => {
                this.Customer = customers.find(c => c.Id === this.Dialogdata.CustomerId);
            }),
            this.store.select(getEventTypes).subscribe(types => {
                this.EventType = types.find(t => t.Name.toLowerCase().includes('videoübertragung'));
            }),
        );

        if (!this.NewEventForm.controls.EventDate.value) {
            const startDate = new Date();
            startDate.setHours(0, 0, 0, 0);
            this.NewEventForm.controls.EventDate.setValue(startDate);
        }
        if (!this.NewEventForm.controls.EventEndDate.value) {
            const endDate = new Date();
            endDate.setHours(0, 0, 0, 0);
            this.NewEventForm.controls.EventEndDate.setValue(endDate);
        }
        const date = new Date();
        const h = date.getHours();
        const m = date.getMinutes();
        const timeString = `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}`;

        if (!this.NewEventForm.controls.EventStartTimeString.value) {
            this.NewEventForm.controls.EventStartTimeString.setValue(timeString);
        }

        if (!this.NewEventForm.controls.EventEndTimeString.value) {
            this.NewEventForm.controls.EventEndTimeString.setValue(timeString);
        }

        if (typeof this.NewEventForm.controls.Note.value !== 'string') {
            this.NewEventForm.controls.Note.setValue('');
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public Submit() {
        if (this.NewEventForm.invalid) {
            return;
        }
        const { Person, EventDate, EventEndDate, EventEndTimeString, EventStartTimeString, Note, Name } = this.NewEventForm.value;

        const eventEndDate = new Date(
            EventEndDate.getFullYear(),
            EventEndDate.getMonth(),
            EventEndDate.getDate(),
            +EventEndTimeString.split(':')[0],
            +EventEndTimeString.split(':')[1],
            EventEndDate.getSeconds(),
            EventEndDate.getMilliseconds(),
        );
        EventDate.setHours(+EventStartTimeString.split(':')[0]);
        EventDate.setMinutes(+EventStartTimeString.split(':')[1]);

        const sub = this.store
            .select(getTransmission)
            .pipe(
                skip(1),
                take(1),
                tap(transmissions => {
                    let id = 0;
                    let t: TransmissionEntity = null;

                    transmissions.forEach(tr => {
                        if (tr.Id > id) {
                            id = tr.Id;
                            t = tr;
                        }
                    });

                    sub.unsubscribe();

                    this.router.navigate(['/videodokumentation', t.CustomerId || 'alle', t.Id]);
                }),
            )
            .subscribe();


        this.actions$.pipe(
            ofType(EventsActionTypes.UpdateEvent, BaseActionTypes.ErrorAction),
            take(1)
        ).subscribe(
            event => {
                if (event.type === EventsActionTypes.UpdateEvent.type) {
                    this.store.dispatch(
                        TransmissionActionTypes.AddTransmission({
                            Payload: {
                                name: Name,
                                commissionId: this.Dialogdata.CommissionId,
                                customerId: this.Dialogdata.CustomerId,
                                eventId: event.Payload.Id,
                            },
                        }),
                    );
                }
            }
        );
        this.store.dispatch(
            EventsActionTypes.AddEvent({
                Payload: {
                    personId: Person?.Id,
                    customerId: this.Dialogdata.CustomerId,
                    hint: null,
                    street: null,
                    city: null,
                    postalCode: null,
                    commissionId: this.Dialogdata.CommissionId,
                    eventTypeId: this.EventType.Id,
                    eventDate: FrontendDate(EventDate),
                    eventEndDate: FrontendDate(eventEndDate),
                    description: Note || '',
                    private: false,
                    name: Name,
                },
            }),
        );
    }

    public NewContactPerson() {
        // const customerId: number = +this.Customer.Id;
        this.AddPersonClicked.next(null);
    }

    public OnDateChange(date: Date) {
        this.NewEventForm.controls.EventDate.setValue(date);
        this.OnEndDateChange(date);
    }

    public OnEndDateChange(date: Date) {
        this.NewEventForm.controls.EventEndDate.setValue(date);
    }
}
