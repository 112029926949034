import { ClockInTypeType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export enum EnumClockInTypeSlug {
    workTime = 'workTime',
    badWeather = 'badWeather',
    drivingTime = 'drivingTime',
    businessTrip = 'businessTrip',
    break = 'break',
    commissionWorkTime = 'commissionWorkTime',
    waitingTime = 'waitingTime',
    other = 'other',

}
export const WorkDayClockInTypeSlug: EnumClockInTypeSlug = EnumClockInTypeSlug.workTime;
interface ClockInTypeEntityAdditionalData {}
export const ClockInTypeEntityGqlFields: Array<keyof ClockInTypeType> = [
    'id',
    'partnerId',
    'userId',
    'name',
    'slug',
    'createdAt',
    'updatedAt',
    'deletedAt',
    'additionalData',
    'isWorkingTime',
    'isBreakTime',
];
export class ClockInTypeEntity {
    constructor(
        public Id: number | null = null,
        public PartnerId: number | null = null,
        public UserId: number | null = null,
        public Name: string | null = null,
        public Slug: EnumClockInTypeSlug | null = null,
        public CreatedAt: Date | null = null,
        public UpdatedAt: Date | null = null,
        public DeletedAt: Date | null = null,
        public AdditionalData: ClockInTypeEntityAdditionalData | null = null,
        public IsWorkingTime: boolean | null = null,
        public IsBreakTime: boolean | null = null,
    ) {}
    public Clone(override: Partial<ClockInTypeEntity> = {}): ClockInTypeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ClockInTypeEntity(), attr);
    }
}

export function ClockInTypeEntityFromBackend(res: ClockInTypeType): ClockInTypeEntity {
    return new ClockInTypeEntity(
        res.id,
        res.partnerId,
        res.userId,
        res.name,
        res.slug as EnumClockInTypeSlug,
        res.createdAt && BackendDate(res.createdAt),
        res.updatedAt && BackendDate(res.updatedAt),
        res.deletedAt && BackendDate(res.deletedAt),
        res.additionalData,
        res.isWorkingTime,
        res.isBreakTime,
    );
}
