import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {DocumentService} from '../../../services/document.service';
import { PartnersActionTypes } from '../../../dave-data-module/State/actions/partners.actions';
import { PartnerEntity } from '../../../dave-data-module/entities/partner.entity';
import { State } from '../../../dave-data-module/State';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';

export interface MandatsreferenzDialogData {
    partner: PartnerEntity;
}

@Component({
    selector: 'app-mandatsreferenz-dialog',
    templateUrl: './mandatsreferenz-dialog.component.html',
    styleUrls: ['./mandatsreferenz-dialog.component.scss']
})
export class MandatsreferenzDialogComponent implements OnInit {
    public Partner: PartnerEntity;

    private form = this.fb.group({
        MandateReference: [null]
    }) as FormGroupTyped<{
        MandateReference: string | null;
    }>;

    constructor(
        public DialogRef: MatDialogRef<MandatsreferenzDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public Data: MandatsreferenzDialogData,
        private appDialog: AppDialogService,
        private fb: UntypedFormBuilder,
        private store: Store<State>,
        private documentService: DocumentService
    ) {
        this.Partner = Data.partner;
    }

    ngOnInit() {
        this.MandateReferenceForm.setValue(this.Partner.MandateReference);
    }

    public Copy() {
        this.documentService.CopyToClipboard(this.Partner.MandateReference);
    }

    public Save() {
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: 'Wollen Sie wirklich eine neue Mandatsreferenz speichern?'
            })
            .subscribe(([result,]) => {
                if (result) {
                    this.store.dispatch(PartnersActionTypes.ModifyPartner({
                            Payload: {
                                id: this.Partner.Id,
                                mandateReference: this.MandateReferenceForm.value,
                            },
                            createNewSepa: false
                        }),
                    );
                    this.DialogRef.close();
                }
            });
    }

    public Generate() {
        if (this.Partner.MandateReference === '') {
            this.store.dispatch(PartnersActionTypes.SetMandateReferencePartner({Payload: {partnerId: this.Partner.Id}}));
            this.DialogRef.close();
        } else {
            this.appDialog
                .OpenConfirmationDialog({
                    paragraph: 'Wollen Sie wirklich eine neue Mandatsreferenz generieren?'
                })
                .subscribe(([result,]) => {
                    if (result) {
                        this.store.dispatch(
                            PartnersActionTypes.SetMandateReferencePartner({Payload: {partnerId: this.Partner.Id}})
                        );
                        this.DialogRef.close();
                    }
                });
        }
    }

    public Close() {
        this.DialogRef.close();
    }

    public get MandateReferenceForm() {
        return this.form.controls.MandateReference;
    }
}
