import { Directive, Input, TemplateRef } from '@angular/core';

/** strukturelle Marker-Direktive für die Verwendung mit `DetailViewTemplateComponent` */
@Directive({
    selector: '[appDetailViewRightContent]',
})
export class DetailViewRightContentDirective {
    // damit der Angular Language Service per Strg+Click auf die Direktive hierher findet
    @Input() appDetailViewRightContent: undefined | '';

    // damit der Angular Language Service das hier als strukturelle Direktive versteht
    constructor(private templateRef: TemplateRef<void>) {}
}
