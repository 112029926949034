import {Role2PermissionsType, User2RoleType} from '../graphql-types';

export class PermissionEntity {
    constructor(
        public Name: string = null,
        public Description: string = null,
    ) {}

    public Clone(override: PermissionEntityAttributes = {}): PermissionEntity {
        const attr = {...this, ...override};
        return Object.assign(new PermissionEntity(), attr);
    }
}

export function PermissionEntityFromBackend(res: User2RoleType | Role2PermissionsType): PermissionEntity {
    return new PermissionEntity(
        res.permissionName,
        res.permissionDescription,
    );
}

export interface PermissionEntityAttributes {
    Name?: string;
    Description?: string;
}
