import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Link } from '@ckeditor/ckeditor5-link';
import { List } from '@ckeditor/ckeditor5-list';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Table } from '@ckeditor/ckeditor5-table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DropzoneConfig, DropzoneDirective, DropzoneModule } from 'ngx-dropzone-wrapper';
import { BehaviorSubject, bufferCount, firstValueFrom, lastValueFrom, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CkEditorComponent } from '../../../ck-editor/ck-editor.component';
import { CommentEntityTypeEnum } from '../../../dave-data-module/entities/comment.entity';
import { FileEntity, FileEntityFromFileBackend, FileMetaData } from '../../../dave-data-module/entities/file.entity';
import { CommentResolver } from '../../../dave-data-module/guards/comment.resolver';
import { State } from '../../../dave-data-module/State';
import { DaveActions } from '../../../dave-data-module/State/actions/actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { CommentActionTypes } from '../../../dave-data-module/State/actions/comment.action';
import { FilesActionTypes, FileUploadParams } from '../../../dave-data-module/State/actions/files.actions';
import { ErrorCodesFileActions } from '../../../dave-data-module/State/effects/file.effects';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { CustomToolbarButton } from '../../../helper/ckeditor.helper';
import { LoadingService } from '../../../services/loading.service';

function setParams(params: FileUploadParams) {
    if (params.has('metadata')) {
        params.set('metadata', JSON.stringify({ ...JSON.parse(params.get('metadata')), isInComment: true } as FileMetaData));
    } else {
        params.set('metadata', JSON.stringify({ isInComment: true } as FileMetaData));
    }
    return params;
}
@Component({
    selector: 'app-comment-inline-editor',
    standalone: true,
    imports: [CommonModule, AppButtonModule, CkEditorComponent, FontAwesomeModule, DropzoneModule],
    templateUrl: './comment-inline-editor.component.html',
    styleUrls: ['./comment-inline-editor.component.scss'],
})
export class CommentInlineEditorComponent implements OnInit {
    @ViewChild('dropzone') dropzone: DropzoneDirective; //DropzoneComponent;

    @Input() FileUploadParams: FileUploadParams;
    @Input() GetEntityIdBeforeSaveComment: () => Promise<number>;
    @Input() GetFileUploadParamsBeforeSaveComment: () => Promise<FileUploadParams>;
    @Input() HideDropzone = false;
    @Input() EntityType: CommentEntityTypeEnum;
    @Input() set EntityId(v: number) {
        this.entityId$.next(v);
    }
    get EntityId() {
        return this.entityId$.value;
    }

    public InlineEditorContent = '';

    public CKEditorConfig: EditorConfig;
    protected dropzoneConfig$ = this.store.select(getToken).pipe(
        map(
            (token) =>
                new DropzoneConfig({
                    autoProcessQueue: false,
                    addRemoveLinks: false,
                    dictRemoveFileConfirmation: null, // macht dialog weg
                    dictDefaultMessage: 'Klicken oder Dateien hinein ziehen',
                    uploadMultiple: false,
                    clickable: true,
                    paramName: () => 'file',
                    parallelUploads: 100,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    // params: this.FileUploadParams,
                    // previewsContainer: '#dropzone-previews',
                }),
        ),
    );
    protected fileCount$ = new BehaviorSubject(0);
    private entityId$: BehaviorSubject<number> = new BehaviorSubject(null);

    constructor(
        private store: Store<State>,
        private actions$: Actions<DaveActions>,
        public LS: LoadingService,
        public BS: BreakpointObserverService,
        protected dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.CKEditorConfig = {
            plugins: [
                Essentials,
                Paragraph,
                Bold,
                Italic,
                Link,
                List,
                BlockQuote,
                Table,
                CustomToolbarButton(
                    'fileUpload',
                    () => {
                        this.dropzone?.dropzone()?.element?.click();
                    },
                    {
                        withText: false,
                        tooltip: 'Datei anhängen',
                        isToggleable: false,
                        icon: '<svg xmlns="http://www.w3.org/2000/svg" height="0.75em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"/></svg>',
                    },
                ),
            ],
            toolbar: [...(!!this.FileUploadParams || this.GetFileUploadParamsBeforeSaveComment ? ['fileUpload', '|'] : []), 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', '|', 'undo', 'redo'],
        };
    }
    removeProgressClass(previewElement: Element) {
        for (let node of previewElement.querySelectorAll('[data-dz-uploadprogress].indeterminate-progress')) {
            node.classList.remove('indeterminate-progress');
        }
    }
    addProgressClass(previewElement: Element) {
        for (let node of previewElement.querySelectorAll('[data-dz-uploadprogress]')) {
            node.classList.add('indeterminate-progress');
        }
    }
    dropzoneError([file, message]) {
        console.error(file, message);
        if (file.previewElement) {
            this.removeProgressClass(file.previewElement);
        }
        this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodesFileActions.Upload } }));
    }
    dropzoneSuccess([file, ]) {
        if (file.previewElement) {
            this.removeProgressClass(file.previewElement);
        }
        this.calcFileCount();
    }
    dropzoneUploadProcess([file, progress, bytesSent]) {
        if (progress === 100 && file.previewElement) {
            this.addProgressClass(file.previewElement);
        }
    }
    calcFileCount() {
        this.fileCount$.next(this.dropzone?.dropzone()?.files?.length || 0);
    }
    public IsDirty() {
        // !InlineEditorContent && (!dropzone || !(fileCount$ | async))
        return !!this.InlineEditorContent || (this.dropzone && this.fileCount$.value > 0)
    }
    async SaveComment() {
        this.LS.startLoading('addComment', { dialogMessage: 'Ihr Kommentar wird Gespeichert' });
        const resultPromise = firstValueFrom(this.actions$.pipe(ofType(CommentActionTypes.UpdateOne, BaseActionTypes.ErrorAction))).then(() => {
            this.InlineEditorContent = '';
            this.LS.endLoading('addComment');
        });
        let entityId = this.entityId$.getValue();
        if (!entityId) {
            entityId = await this.GetEntityIdBeforeSaveComment().catch((reason) => {
                throw new Error('Kommentar konnte nicht gespeichert werden! ' + reason);
            });
        }
        if (!entityId) {
            return;
        }
        this.entityId$.next(entityId);

        const dispatchAction = (fileIds: number[] = []) => {
            this.store.dispatch(
                CommentActionTypes.Create({
                    Payload: {
                        entityId,
                        entityType: this.EntityType,
                        text: /*fromInlineEditor ? */ this.InlineEditorContent /*: this.EditorContent*/,
                        documentIds: fileIds,
                    },
                }),
            );
        };

        const dropzoneInstance: any /*Dropzone*/ = this.dropzone?.dropzone(); // this.dropzone?.nativeElement?.dropzone;
        const files: File[] = dropzoneInstance?.files;
        if (files?.length) {
            (this.GetFileUploadParamsBeforeSaveComment ? this.GetFileUploadParamsBeforeSaveComment() : firstValueFrom(of(this.FileUploadParams))).then((params) => {
                const filesUploaded: FileEntity[] = [];
                dropzoneInstance.options.params = Object.fromEntries(setParams(new Map(params)));
                dropzoneInstance.processQueue();
                lastValueFrom(
                    this.dropzone.DZ_SUCCESS.pipe(
                        tap((s) => filesUploaded.push(FileEntityFromFileBackend(s[1][0]))),
                        takeUntil(this.dropzone.DZ_QUEUECOMPLETE),
                    ),
                ).then(() => {
                    dispatchAction(filesUploaded.map((f) => f.Id));
                    this.dropzone.reset();
                });
            });
        } else {
            dispatchAction();
        }
        return resultPromise;
    }
}
