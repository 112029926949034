import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { CommissionTypeActionTypes } from '../State/actions/commissionType.actions';
import { getCommissionTypesFetched } from '../State/selectors/commissionType.selectors';
import { BaseEntityResolver } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class CommissionTypeResolver extends BaseEntityResolver {
    constructor(store: Store<State>) {
        super(getCommissionTypesFetched, CommissionTypeActionTypes.GetCommissionTypeRequest(), store);
    }
}
