<header>
    <h1 mat-dialog-title>Mitarbeiter zuweisen</h1>
    <span class="spacer"></span>
</header>
<app-multi-select-table
    mat-dialog-content
    *ngIf="ContentResolved$ | async; else loading"
    [TableData]="FilteredTableData$ | async"
    [TableDataConfig]="TableDataConfig"
    [Selected]="SelectedUsers$ | async"
    (RemoveSelected)="RemoveSelected($event)"
    Headline=""
    SearchPlaceHolder="nach Mitarbeitern suchen"
    (SearchStringChange)="SearchString.next($event)"
>
    <div class="filter-wrapper">
        <button
            app-round-button
            Inverted
            [matBadge]="FilterAmount$ | async"
            matTooltip="Filter"
            [matBadgeOverlap]="true"
            [matBadgeHidden]="!(FilterAmount$ | async)"
            (click)="filter.Open()"
        >
            <fa-icon icon="filter"></fa-icon>
        </button>
        <app-filter-card UnlimitedHeight #filter [Settings]="_FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
    </div>
</app-multi-select-table>

<mat-dialog-actions>
    <button app-button mat-dialog-close="" Inverted Color="cancel" [IsLoading]="LS.IsLoading$ | async">Abbrechen</button>
    <button app-button (click)="Submit()" [IsLoading]="LS.IsLoading$ | async" Color="green">Speichern</button>
</mat-dialog-actions>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
