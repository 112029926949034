import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SimplebarAngularModule } from 'simplebar-angular';
import { BurgerButtonWrapperComponent } from '../burger-button-wrapper/burger-button-wrapper.component';
import { NewDocumentViewMenu } from '../components/templates/new-document-view/new-document-view.module';
import { CustomFormFieldsModule } from '../custom-form-fields/custom-form-fields.module';
import { DaveDmsModule } from '../dave-dms-module/dave-dms.module';
import { PreviewModule } from '../dave-dms-module/dave-dms/preview/preview.module';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { PipesModule } from '../dave-utils-module/dave-shared-components-module/pipes/pipes.module';
import { IconPipeModule } from '../dave-utils-module/icon-pipe/icon-pipe.module';
import { SelectSearchModule } from '../dave-utils-module/select-search/select-search.module';
import { GoToButtonsModule } from '../go-to-buttons/go-to-buttons.module';
import { DocumentEditorDialogComponent } from './components/document-editor-dialog/document-editor-dialog.component';
import { DocumentEditorModule } from './components/document-editor/document-editor.module';
import { DaveDocumentEditorRoutingModule } from './dave-document-editor-routing.module';
// import 'syncfusion-ej-global/i18n/ej.culture.de-DE.min.js';
// import 'syncfusion-ej-global/l10n/ej.localetexts.de-DE.min.js';

@NgModule({
    declarations: [DocumentEditorDialogComponent],
    imports: [
        CommonModule,
        DaveSharedComponentsModule,
        DaveDocumentEditorRoutingModule,
        SimplebarAngularModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSelectModule,
        MatOptionModule,
        MatTabsModule,
        RouterModule,
        MatCardModule,
        NewDocumentViewMenu,
        DaveDmsModule,
        AppButtonModule,
        SelectSearchModule,
        MatSortModule,
        IconPipeModule,
        MatListModule,
        PipesModule,
        MatInputModule,
        PreviewModule,
        DocumentEditorModule,
        MatDialogModule,
        DaveLoadingModule,
        CustomFormFieldsModule,
        GoToButtonsModule,
        MatExpansionModule,
        MatMenuModule,
        BurgerButtonWrapperComponent,
        MatDatepickerModule,
    ],
})
export class DaveDocumentEditorModule {}
