import { FlatTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Moment } from 'moment/moment';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { DetectionEntity } from '../../../dave-data-module/entities/detection.entity';
import { FileEntity } from '../../../dave-data-module/entities/file.entity';
import { FolderEntity } from '../../../dave-data-module/entities/folder.entity';
import { DmsViewStyle } from '../../../dave-data-module/entities/user.entity';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { TagResolver } from '../../../dave-data-module/guards/tag.resolver';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { FilesActionTypes } from '../../../dave-data-module/State/actions/files.actions';
import { ResolverLoadDetections } from '../../../dave-data-module/State/actions/resolver.actions';
import { ChangeSettings } from '../../../dave-data-module/State/actions/settings.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getDetection } from '../../../dave-data-module/State/selectors/detection.selectors';
import { getEmployees } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getSetting } from '../../../dave-data-module/State/selectors/users.selectors';
import { MatTreeNodePaddingIndent } from '../../../dave-file-explorer/components/file-explorer/file-explorer.component';
import { SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData } from '../../../dave-file-explorer/components/select-folder-dialog/select-folder-dialog.component';
import { FilterOption, FILTER_TYPE_SEARCH_MULTI_SELECT, FILTER_TYPE_TIMESPAN, IFilterTypeSearchMultiSelectValue } from '../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { isNotNullOrUndefined, uniqArray } from '../../../helper/helper';
import { EmailPageMeta, UserAdministrationMeta } from '../../../helper/page-metadata';
import { FilterTypes } from '../../../services/default-filter.service';

interface DisplayElement {
    documentId: number;
    name: string;
    fileId: number;
    detectionId: number;
    folderId: number;
    size?: string;
    updatedAt?: Date;
    createdAt?: Date;
    fileType?: string;
    thumbnailPath?: string;
    folderPath?: string;
    userId?: number;
    eMailId?: number;
}
interface CombineHelper {
    det: DetectionEntity;
    doc: FileEntity;
    fol: FolderEntity;
}
@Component({
    selector: 'app-scan2dave-list',
    templateUrl: './scan2dave-list.component.html',
    styleUrls: ['./scan2dave-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan2daveListComponent implements AfterViewInit, OnDestroy {
    @Input() FolderId: number = null;
    @ViewChild(MatSort) private matSortDOM?: MatSort;
    @Output() Scan2DaveClicked$ = new EventEmitter<number>();
    @Output() FileClicked = new EventEmitter<number>();
    private afterViewInit$ = new Subject<void>();
    public ShownElements$: Observable<DisplayElement[]>;
    public ShownElementsTableDataSource$: Observable<TableVirtualScrollDataSource<DisplayElement>>;
    public ShownElementsTableDataSource: TableVirtualScrollDataSource<DisplayElement> = new TableVirtualScrollDataSource<DisplayElement>([]);
    public ShowDataSearch = false;
    public DataSearchForm: FormControlTyped<string> = new UntypedFormControl();
    public BaseUrl = this.api.GetUrl('', 'file');
    public DisplayedColumns: string[] = ['name', 'folderPath', 'fileType', 'updatedAt', 'size'];
    public ViewStyle$ = this.store.select(getSetting).pipe(map((setting) => setting?.DmsViewStyle));
    public ViewStyle: DmsViewStyle = null;
    public FileMultisilect$ = new BehaviorSubject<number[]>([]);
    public IsAllSelected$: Observable<boolean>;
    public Multiselect = false;
    private subscriptions: Subscription[] = [];
    public ClickMove$ = new Subject<void>();
       public FilterValues$: BehaviorSubject<{
        [FilterTypes.Emails]: IFilterTypeSearchMultiSelectValue<string>[];
        [FilterTypes.Employees]: IFilterTypeSearchMultiSelectValue<number>[];
        [FilterTypes.Date]: { to: Moment; from: Moment };
    }> = new BehaviorSubject({
        [FilterTypes.Emails]: [],
        [FilterTypes.Employees]: [],
        [FilterTypes.Date]: { to: null, from: null },
    });
    protected filterAmount$ = this.FilterValues$.pipe(
        map((filter) => {
            let amount = 0;
            if (filter[FilterTypes.Emails]?.length > 0) {
                amount++;
            }
            if (filter[FilterTypes.Employees]?.length > 0) {
                amount++;
            }
            if (filter[FilterTypes.Date]?.from) {
                amount++;
            }
            if (filter[FilterTypes.Date]?.to) {
                amount++;
            }
            return amount || null;
        }),
    );
    public FilterSettings$: Observable<FilterOption[]> = combineLatest([
        this.store.select(getDetection).pipe(
            filter(isNotNullOrUndefined),
            map((detections) => uniqArray(detections.map((detection) => detection.FromMail).filter((v) => !!v)).sort()),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        ),
        this.store.select(getEmployees),
    ]).pipe(
        map(([emails, employees]) => {
            const employeeValues: IFilterTypeSearchMultiSelectValue<number>[] = employees.map((u) => ({
                label: u.DisplayName,
                id: u.Id,
            }));
            const emailsValues: IFilterTypeSearchMultiSelectValue<string>[] = emails.map((e) => ({
                label: e,
                id: e,
            }));
            return [
                {
                    Name: FilterTypes.Employees,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Mitarbeiter',
                    Icon: UserAdministrationMeta.Icon,
                    Values: employeeValues,
                },
                {
                    Name: FilterTypes.Date,
                    Type: FILTER_TYPE_TIMESPAN,
                    Label: 'Datei hochgeladen',
                    Icon: 'calendar',
                },
                {
                    Name: FilterTypes.Emails,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'E-Mail',
                    Icon: EmailPageMeta.Icon,
                    Values: emailsValues,
                },
            ];
        }),
    );
    constructor(
        private api: HttpService,
        private store: Store<State>,
        tagResolver: TagResolver,
        commissionResolver: CommissionResolver,
        private router: Router,
        dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private fileDataService: FileDataService,
        folderDataService: FolderDataService,
    ) {
        tagResolver.resolve();
        commissionResolver.resolve();

        this.ShownElements$ = this.store.select(getDetection).pipe(
            filter(isNotNullOrUndefined),
            switchMap((detections) => {
                const files$ = this.fileDataService.GetFilesById(detections.map((d) => d.DocumentId).filter((id, i, arr) => id && arr.indexOf(id) === i)).pipe(map((files) => files.filter(isNotNullOrUndefined)));

                return combineLatest([
                    this.store.select(getToken).pipe(filter(isNotNullOrUndefined)),
                    files$
                        .pipe(switchMap((documents) => combineLatest(uniqArray(documents.map((d) => d.FolderId).filter(isNotNullOrUndefined)).map((id) => folderDataService.getFolderAndParentsById(id, true)))))
                        .pipe(map((folders) => folders.flat())),
                    files$,
                    of(detections),
                ]);
            }),
            map(([token, folders, documents, detections]) =>
                detections
                    .map((det) => {
                        const doc = documents.find((d) => d.Id === det.DocumentId);
                        const result: CombineHelper = {
                            det,
                            doc,
                            fol: folders.find((f) => f.Id === doc?.FolderId),
                        };
                        return result;
                    })
                    .filter((entity) => isNotNullOrUndefined(entity.doc))
                    .map((entity) => {
                        let path = '';
                        let folder = entity.fol;
                        if (isNotNullOrUndefined(folder)) {
                            path = folder.Name + '/';
                            while (isNotNullOrUndefined(folder.ParentId)) {
                                folder = folders.find((f) => f.Id === folder.ParentId);
                                path = folder.Name + '/' + path;
                            }
                        }
                        path = 'Hauptordner/' + path;
                        return {
                            employeeId: entity.det.EmployeeId,
                            fromMail: entity.det.FromMail,
                            documentId: entity.doc.Id,
                            name: entity.doc.Name,
                            fileId: entity.det.DocumentId,
                            detectionId: entity.det.Id,
                            folderId: entity.det.FolderId,
                            size: entity.doc.GetLastVersion()?.Size.toString(),
                            updatedAt: entity.det.UpdatedAt,
                            createdAt: entity.det.CreatedAt,
                            fileType: entity.doc.MIMETypeDescription,
                            thumbnailPath: entity.doc.GetLastVersion()?.HasThumbnail ? this.BaseUrl + entity.doc.GetLastVersion().GetThumbnailLink(token) : null,
                            folderPath: path,
                        };
                    })
                    .sort((a, b) => {
                        const aDate = a.createdAt;
                        const bDate = b.createdAt;
                        return bDate < aDate ? -1 : bDate == aDate ? 0 : 1;
                    }),
            ),
            switchMap((elements) =>
                this.FilterValues$.pipe(
                    map((filterValues) => {
                        let filteredElements = elements;
                        if (filterValues[FilterTypes.Employees]?.length) {
                            const ids = filterValues[FilterTypes.Employees].map((v) => v.id);
                            filteredElements = filteredElements.filter((e) => ids.includes(e.employeeId));
                        }
                        if (filterValues[FilterTypes.Date]?.from) {
                            filteredElements = filteredElements.filter((e) => filterValues[FilterTypes.Date].from.isSameOrBefore(e.createdAt, 'day'));
                        }
                        if (filterValues[FilterTypes.Date]?.to) {
                            filteredElements = filteredElements.filter((e) => filterValues[FilterTypes.Date].to.isSameOrAfter(e.createdAt, 'day'));
                        }
                        if (filterValues[FilterTypes.Emails]?.length) {
                            const ids = filterValues[FilterTypes.Emails].map((v) => v.id);
                            filteredElements = filteredElements.filter((e) => ids.includes(e.fromMail));
                        }
                        return filteredElements;
                    }),
                ),
            ),
            switchMap((elements) =>
                this.DataSearchForm.valueChanges.pipe(
                    startWith(''),
                    map((v) => v?.toLowerCase()),
                    map((search) => {
                        if (search?.length) {
                            return elements.filter((e) => e.name.toLowerCase().includes(search));
                        } else {
                            return elements;
                        }
                    }),
                ),
            ),
        );
        this.ShownElementsTableDataSource$ = combineLatest([this.afterViewInit$, this.ShownElements$]).pipe(
            map(([, elements]) => {
                const ret = new TableVirtualScrollDataSource(elements);
                ret.sort = this.matSortDOM;
                ret.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case 'name':
                            return item.name.toLowerCase();
                        case 'fileType':
                            return item.fileType.toLowerCase();
                        case 'folderPath':
                            return item.folderPath.toLowerCase();
                        default:
                            return item[property];
                    }
                };
                return ret;
            }),
        );
        this.subscriptions.push(
            this.ShownElements$.subscribe(),
            this.ShownElementsTableDataSource$.subscribe((v) => {
                this.ShownElementsTableDataSource = v;
                this.cdr.detectChanges();
            }),
            this.ViewStyle$.subscribe((vs) => {
                this.ViewStyle = vs;
            }),
            this.ClickMove$.pipe(
                withLatestFrom(this.FileMultisilect$),
                tap(([, selectedFileIds]) => {
                    dialog
                        .open<SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData>(SelectFolderDialogComponent, {
                            ...SelectFolderDialogComponent.DefaultConfig,
                            data: {
                                matTreeNodePaddingIndent: MatTreeNodePaddingIndent,
                            },
                        })
                        .afterClosed()
                        .pipe(
                            take(1),
                            tap((ret) => {
                                const id = ret.folderId;
                                if (id) {
                                    selectedFileIds.forEach((f) => this.store.dispatch(FilesActionTypes.ModifyFileRequest({ Payload: { DocumentId: f.toString(), FolderId: id.toString() } })));
                                }
                                this.ToggleMultiselect();
                            }),
                        )
                        .subscribe();
                }),
            ).subscribe(),
        );
        this.IsAllSelected$ = combineLatest([this.ShownElements$, this.FileMultisilect$]).pipe(map(([shownElements, selectedFiles]) => shownElements.length === selectedFiles.length));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    public SortTableData(sort: Sort) {
        if (!this.ShownElementsTableDataSource.sort) {
            this.ShownElementsTableDataSource.sort = this.matSortDOM;
        }
    }

    ClickItem(item: DisplayElement) {
        this.FileClicked.emit(item.fileId);
    }

    RouteToFile(item: DisplayElement, event) {
        event.preventDefault();
        if (this.ViewStyle == DmsViewStyle.OldDms) {
            this.SetViewStyle(DmsViewStyle.List);
        }
        this.Scan2DaveClicked$.emit();
        this.router.navigate([], { queryParams: { documentId: item.fileId } });
    }

    SetViewStyle(viewStyle: DmsViewStyle) {
        this.store.dispatch(
            new ChangeSettings({
                DmsViewStyle: viewStyle,
            }),
        );
    }
    Reload() {
        // this.store.dispatch(FilesActionTypes.LoadAllFiles()); //todo files laden
        this.store.dispatch(new ResolverLoadDetections());
    }

    ClickMultiselect(element: DisplayElement) {
        if (this.FileMultisilect$.value.includes(element.documentId)) {
            this.FileMultisilect$.next(this.FileMultisilect$.value.slice().filter((fId) => fId !== element.documentId));
        } else {
            this.FileMultisilect$.next([...this.FileMultisilect$.value.slice(), element.documentId]);
        }
    }
    MultiselectMasterToggle() {
        if (this.FileMultisilect$.value?.length === this.ShownElementsTableDataSource.data.length) {
            this.FileMultisilect$.next([]);
        } else {
            const fileIds = this.ShownElementsTableDataSource.data.slice().map((item) => item.documentId);
            this.FileMultisilect$.next(fileIds);
        }
    }
    ToggleMultiselect() {
        this.Multiselect = !this.Multiselect;
        this.FileMultisilect$.next([]);
        if (!this.Multiselect) {
            this.DisplayedColumns.splice(this.DisplayedColumns.indexOf('select'), 1);
        } else {
            this.DisplayedColumns.unshift('select');
        }
    }
    ngAfterViewInit(): void {
        this.afterViewInit$.next();
    }
}
