import { NgxMatColorPickerComponent } from '@angular-material-components/color-picker';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, OnDestroy } from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { SafeSubscriber } from 'rxjs/internal/Subscriber';

@Component({
    selector: 'app-mat-color-preview',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './mat-color-preview.component.html',
    styleUrls: ['./mat-color-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatColorPreviewComponent implements OnDestroy {
    protected picker: NgxMatColorPickerComponent;
    protected stateChangeSubscription = Subscription.EMPTY;
    @Input() set for(picker: NgxMatColorPickerComponent) {
        this.picker = picker;
        this.backgroundColor = picker._selected ? '#' + picker._selected.hex : null;
        this.stateChangeSubscription = merge(picker._selectedChanged, picker._disabledChange).subscribe(() => {
            const color = picker._selected;
            this.backgroundColor = color ? '#' + color.hex : null;
        });
    }
    get for(): NgxMatColorPickerComponent {
        return this.picker;
    }
    @HostListener('click') onClick() {
        this.picker.open();
    }
    @HostBinding('style.background-color') backgroundColor: string;
    ngOnDestroy() {
        this.stateChangeSubscription.unsubscribe();
    }
}
