<ngx-simplebar class="content" [ngClass]="{ 'clickable-list-container': ListView }">
    <ng-container *ngIf="(GroupedEvents$ | async)?.length; else noDisplayedEvents">
        <div class="timeline" id="timeline" *ngIf="!ListView; else listViewTemplate">
            <ng-container *ngFor="let group of GroupedEvents$ | async; let i = index">
                    <div class="event" *ngIf="group.yearHeadline">
                        <h1 class="new-year">{{ group.yearHeadline }}</h1>
                    </div>

                <div class="event right">
                    <div class="event-date-wrapper">
                        <div class="event-icon-wrapper">
                            <div class="event-icon" (click)="Collapse(i)" tabindex="0" (keydown.enter)="Collapse(i)">
                                {{ group.dayHeadline }}
                                <fa-icon [icon]="Collapsed[i] ? 'chevron-down' : 'chevron-up'"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div
                        class="event-group"
                        [style.maxHeight.px]="EventGroupHeights[i] * 130"
                        [class.collapsed]="Collapsed[i]"
                    >
                        <app-event-card
                            class="clickable"
                            *ngFor="let event of group.events"
                            [EventId]="event.Id"
                            style="height: 5.75rem; width: 20.5rem; margin: 0 1.25rem 1.25rem 0"
                            [Expandable]="false"
                            (EventClicked)="event.IsTask ? {} : RouteTo($event)"
                        >
                        </app-event-card>
                    </div>
                </div>
            </ng-container>
        </div>

        <ng-template #listViewTemplate>
            <ng-container *ngFor="let group of GroupedEvents$ | async; let i = index">
                <h1 *ngIf="group.yearHeadline">
                    {{group.yearHeadline}}
                </h1>

                <div class="list-day" (click)="Collapse(i)" tabindex="0" (keydown.enter)="Collapse(i)">
                    {{ group.dayHeadline }}
                    <fa-icon [icon]="Collapsed[i] ? 'chevron-down' : 'chevron-up'"></fa-icon>
                </div>
                <mat-divider></mat-divider>

                <ul
                    class="clickable-list"
                    [style.maxHeight.px]="EventGroupHeights[i] * 70"
                    [class.collapsed]="Collapsed[i]"
                >
                    <ng-container *ngFor="let event of group.events">
                        <li class="clickable-list-item">
                            <a mat-button class="clickable-list-item-link" (click)="event.IsTask ? OpenDetailTaskDialog(event.Id) : RouteTo(event.Id)">
                                <div class="clickable-list-item-content">
                                    <ng-container *ngIf="event.eventType">
                                        <fa-icon
                                            *ngIf="event.eventType.ImagePath"
                                            matTooltipPosition="right"
                                            [icon]="event.eventType.ImagePath | icon"
                                            [matTooltip]="event.eventType.Name"
                                        ></fa-icon>
                                        <span
                                            *ngIf="!event.eventType.ImagePath"

                                            class="event-type-letter"
                                            matTooltipPosition="right"
                                            [matTooltip]="event.eventType.Name"
                                        >
                                            {{ event.eventType.Name.slice(0, 1).toUpperCase() }}
                                        </span>
                                    </ng-container>

                                    <p>{{ event.EventDate | date: 'shortTime' }}</p>
                                    <p>{{ event.personName }}</p>
                                    <p class="description">{{ event.Description }}</p>
                                    <div *ngIf="event.FileCount" class="files-icon">
                                        {{ event.FileCount }}
                                        <fa-icon icon="paperclip"></fa-icon>
                                    </div>
                                    <div class="placeholder"></div>
                                </div>
                            </a>

                            <div class="annotations">
                                <button
                                    *ngIf="event.bookmark$ | async as bookmarkEntity; else bookmarkEntity"
                                    app-round-button
                                    matTooltip="Lesezeichen entfernen"
                                    matTooltipPosition="left"
                                    Color="highlighted"
                                    (click)="RemoveBookmark(bookmarkEntity.Id)"
                                >
                                    <fa-icon [icon]="['fas', 'bookmark']"></fa-icon>
                                </button>
                                <ng-template #bookmarkEntity>
                                    <button
                                        app-round-button
                                        matTooltip="Lesezeichen hinzufügen }}"
                                        matTooltipPosition="left"
                                        (click)="AddBookmark(event.Id)"
                                    >
                                        <fa-icon [icon]="['far', 'bookmark']"></fa-icon>
                                    </button>
                                </ng-template>
                            </div>
                        </li>

                        <mat-divider></mat-divider>
                    </ng-container>
                </ul>
            </ng-container>
        </ng-template>
    </ng-container>

    <ng-template #noDisplayedEvents>
        <div class="no-displayed-events" *ngIf="CanEditCustomer$ | async">
            Keine Ereignisse zu den gewählten Kriterien gefunden.
        </div>
        <div class="no-displayed-events" *ngIf="!(CanEditCustomer$ | async)">
            Sie sind diesem {{ CS.GetMultiple$() | async }} nicht zugewiesen.
        </div>
    </ng-template>
</ngx-simplebar>
