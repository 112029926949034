import {animate, style, transition, trigger} from '@angular/animations';

export const breadcrumbAnimation = trigger('breadcrumbAnimation', [
    transition(':enter', [
        style({
            width: 0
        }),
        animate(
            '0.5s 0.5s ease-in-out',
            style({
                width: '*'
            })
        )
    ]),
    transition(':leave', [
        style({
            width: '*'
        }),
        animate(
            '0.5s ease-in-out',
            style({
                width: 0
            })
        )
    ])
]);
