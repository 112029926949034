import {createAction, props} from '@ngrx/store';
import {PartnerEntity} from '../../entities/partner.entity';
import {
    DaveMutationChangePartnerByIdArgs,
    DaveMutationChangePartnerOfficeArgs,
    DaveMutationCreatePartnerArgs,
    DaveMutationCreatePartnerOfficeArgs, DaveMutationDeletePartnerOfficeArgs,
    DaveMutationSetMandateReferenceArgs,
} from '../../graphql-types';
import { PartnerOfficeEntity } from '../../entities/partnerOffice.entity';

export const PartnersActionTypes = {
    UpdatePartner: createAction(
        'partner/update',
        props<{Payload: PartnerEntity}>()
    ),
    AddPartner: createAction(
        'data/Partner/add',
        props<{Payload: { partner: DaveMutationCreatePartnerArgs, mainOffice?: DaveMutationCreatePartnerOfficeArgs }}>()
    ),
    ModifyPartner: createAction(
        'data/Partner/modify',
        props<{Payload: DaveMutationChangePartnerByIdArgs, createNewSepa: boolean}>()
    ),
    DeletePartner: createAction(
        'data/Partner/delete',
        props<{Payload: number}>()
    ),
    SetMandateReferencePartner: createAction(
        'data/Partner/setMandateReference',
        props<{Payload: DaveMutationSetMandateReferenceArgs}>()
    ),
    UpdateSinglePartner: createAction(
        'partner/updateSingle',
        props<{Payload: PartnerEntity}>()
    ),
    LoadPartnerList: createAction(
        'data/PartnerList/get'
    ),
    LoadPartner: createAction(
        'data/Partner/get'
    ),
    UpdatePartnerList: createAction(
        'partnerList/update',
        props<{Payload: PartnerEntity[]}>()
    ),
};
export const PartnerOfficeActionTypes = {
    GetPartnerOffices: createAction(
        'data/PartnerOffice/get',
    ),
    UpdatePartnerOffices: createAction(
        'data/PartnerOffice/update',
        props<{Payload: PartnerOfficeEntity[]}>()
    ),
    AddPartnerOffice: createAction(
        'data/PartnerOffice/add',
        props<{Payload: DaveMutationCreatePartnerOfficeArgs}>()
    ),
    ModifyPartnerOffice: createAction(
        'data/PartnerOffice/modify',
        props<{Payload: DaveMutationChangePartnerOfficeArgs}>()
    ),
    DeletePartnerOffice: createAction(
        'data/PartnerOffice/delete',
        props<{Payload: DaveMutationDeletePartnerOfficeArgs}>()
    ),
};
