import {ChangeDetectionStrategy, Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {
    EmailEditorComponent,
    EmailEditorComponentDialogConfig,
    EmailEditorComponentDialogData
} from '../../../dave-email-module/components/email-editor/email-editor.component';
import {combineLatest, Subject} from 'rxjs';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {PersonResolver} from '../../../dave-data-module/guards/person.resolver';

export interface LinkDialogData {
    heading?: string;
    paragraph?: string;
    link?: string;
    small?: string;
}

@Component({
    selector: 'app-link-dialog',
    template: `
        <h1 mat-dialog-title *ngIf="Data.heading">{{ Data.heading }}</h1>
        <mat-dialog-content class="content">

            <p *ngIf="Data.paragraph" class="text">{{ Data.paragraph }}</p>
        </mat-dialog-content>
        <mat-dialog-actions class="buttons">
            <button app-button matTooltip="Email senden" (click)="OpenMailDialog$.next()">
                <fa-icon icon="paper-plane"></fa-icon>
            </button>
            <button app-button
                    [cdkCopyToClipboard]="Data.link"
                    matTooltip="In die Zwischenablage kopieren"
            >
                <fa-icon icon="copy"></fa-icon>
            </button>
            <button app-button mat-dialog-close>OK</button>
        </mat-dialog-actions>
    `,
    styles: [
        `
            :host {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
            .content > :first-child {
                margin-top: 0;
            }

            .content > :last-child {
                margin-bottom: 0;
            }

            .buttons {
                justify-content: flex-end;
            }

            button {
                margin-right: 1rem;
            }

            .text{
                word-break: break-all;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkDialogComponent implements OnDestroy {
    public OpenMailDialog$ = new Subject<void>();
    private onDestroy$ = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public Data: LinkDialogData,
        private dialog: MatDialog,
        personResolver: PersonResolver,
    ) {
        this.OpenMailDialog$.pipe(
            takeUntil(this.onDestroy$),
            switchMap(() => combineLatest([personResolver.resolve()])),
            tap(() =>
                this.dialog.open(EmailEditorComponent, {
                    ...EmailEditorComponentDialogConfig,
                    data: {
                        EmailSubject: 'dave: Link zur Videodokumentation',
                        EmailHtml: `<p>
                                        <a href="${this.Data.link}">Link zur Videodokumentation</a>
                                    </p>`
                    } as EmailEditorComponentDialogData,
                }))
        ).subscribe();
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }
}
