<app-chip-autocomplete
    [formControl]="FormControl"
    [MapFn]="MapFn"
    [Options]="Options$ | async"
    [OptionTemplate]="optionsTemplate"

    Placeholder="Branche"
    [Tooltip]="ToolTip$ | async"
    (UnknownOptionSubmitted)="Add($event)"
    [initialPatchDefaultValue]="true"
></app-chip-autocomplete>

<ng-template #optionsTemplate let-Option>
    <div>
        {{ MapFn(Option) }}

        <button mat-icon-button *ngIf="CanDelete$|async" (click)="Delete(Option, $event)">
            <fa-icon icon="trash"></fa-icon>
        </button>
    </div>
</ng-template>
