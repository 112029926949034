import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { TextTemplatesActionTypes } from '../State/actions/textTemplates.actions';
import { getTextTemplatesFetched } from '../State/selectors/textTemplates.selector';
import { BaseEntityResolver } from './resolver.helper';
import { getTextTemplateLanguageFetched } from '../State/selectors/text-template-language.selectors';
import { TextTemplateLanguageActionTypes } from '../State/actions/text-template-language.actions';

@Injectable({
    providedIn: 'root',
})
export class TextTemplateLanguageResolver extends BaseEntityResolver {
    constructor(store: Store<State>) {
        super(getTextTemplateLanguageFetched, TextTemplateLanguageActionTypes.Load({}), store);
    }
}
