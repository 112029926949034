<button app-round-button class="back-button" (click)="DialogRef.close()" [Disabled]="Uploading === true">
    <fa-icon icon="chevron-right"></fa-icon>
</button>
<app-new-document-view-component
    [CustomerId]="Data.CustomerId"
    [CommissionId]="Data.CommissionId"
    [TransmissionId]="Data.TransmissionId"
    [ShowSaveButton]="true"
    [FolderId]="Data.FolderId"
    [ConstructionDiaryId]="Data.ConstructionDiaryId"
    (CloseViewEvent)="DialogRef.close()"
    (UploadInProgress)="UploadFinished($event)"
    (UploadSuccess)="UploadFinished(false)"
    class="new-document-view"
></app-new-document-view-component>
