import {SharedCommissionsEntity} from '../../entities/sharedCommissions.entity';
import { createReducer, on } from '@ngrx/store';
import {SharedCommissionsActionTypes} from '../actions/sharedCommissions.actions';


export const sharedCommissionsFeatureKey = 'sharedCommissions';

export interface SharedCommissionsState {
    sharedCommissions: SharedCommissionsEntity[];
}

export const initialState: SharedCommissionsState = {
    sharedCommissions: null,
};

export const SharedCommissionsReducer = createReducer(
    initialState,
    on(SharedCommissionsActionTypes.UpdateSharedCommissions, (state, { Payload }) => ({
        ...state,
        sharedCommissions: Payload,
    })),
);
