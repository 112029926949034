import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { UserToEventActionTypes } from '../State/actions/user-to-event.action';
import { getUserToEventFetched, getUserToEventLatestUpdatedAt } from '../State/selectors/user-to-event.selector';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class UserToEventResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getUserToEventFetched, getUserToEventLatestUpdatedAt, UserToEventActionTypes.Load, store);
    }
}
