import { Change, GetTimeFromTimestamp, ChangeType, EntityType } from '@dave/types';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const ChangeTypeIcons = new Map<ChangeType, IconProp>([
  [ChangeType.Added, 'plus'],
  [ChangeType.Removed, 'pencil-alt'],
  [ChangeType.Created, 'plus'],
  [ChangeType.Deleted, 'trash'],
  [ChangeType.Changed, 'pencil-alt'],
  [ChangeType.Other, 'history'],
]);
export const ChangeTypeNames = new Map<ChangeType, string>([
  [ChangeType.Added, 'hinzugefügt'],
  [ChangeType.Removed, 'entfernt'],
  [ChangeType.Created, 'erstellt'],
  [ChangeType.Deleted, 'gelöscht'],
  [ChangeType.Changed, 'bearbeitet'],
]);

export class ChangeEntity {
    constructor(
        public Id: number = null,
        public PartnerId: number = null,
        public UserId: number = null,
        public Entity: EntityType = null,
        public EntityId: number = null,
        public Type: ChangeType = null,
        public OldValue: any = null,
        public NewValue: any = null,
        public Data: string = null,
        public Text: string = null,
        public ReferredEntity: EntityType = null,
        public ReferredEntityId: number = null,
        public CreatedAt: Date = null,
        public LastSeenAt: Date = null,
        public CustomerId: number = null,
        public CommissionId: number = null,
    ) {}
    public static EntityName = 'Prozess';
    public static EntityPropertyNames: Map<keyof ChangeEntity, string> = new Map([]);
    public Clone(override: Partial<ChangeEntity> = {}): ChangeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ChangeEntity(), attr);
    }
}
export function ChangeEntityFromBackend(res: Change): ChangeEntity {
    return new ChangeEntity(
        res.Id && +res.Id,
        res.PartnerId && +res.PartnerId,
        res.UserId && +res.UserId,
        res.Entity,
        res.EntityId && +res.EntityId,
        res.Type,
        res.OldValue && JSON.parse(res.OldValue),
        res.NewValue && JSON.parse(res.NewValue),
        res.Data,
        res.Text,
        res.ReferredEntity,
        res.ReferredEntityId && +res.ReferredEntityId,
        res.CreatedAt && GetTimeFromTimestamp(+res.CreatedAt),
        res.LastSeenAt && GetTimeFromTimestamp(+res.LastSeenAt),
        res.CustomerId && +res.CustomerId,
        res.CommissionId && +res.CommissionId,
    );
}
