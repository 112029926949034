import { ChangeDetectorRef, Directive, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChipAutocompleteComponent } from '../components/chip-autocomplete/chip-autocomplete.component';

/**
 * Direktive, die die `ChipAutocompleteComponent` mit dem `ControlValueAccessor` Interface von
 * Angular Forms verbindet.
 *
 * Die Direktive verbindet die übergebene `FormControl` mit den `SelectedOptions` der Komponente,
 * die `FormControl` wird nach Änderungen dementsprechend ein Array des Typs `T` enthalten.
 *
 * Die Direktive fügt keine neuen Inputs oder Outputs hinzu, sie ermöglicht lediglich die
 * Verwendung von Angular template-driven und reactive Forms mit der Komponente (siehe Selektor).
 */
@Directive({
    selector: 'app-chip-autocomplete[formControlName], ' + 'app-chip-autocomplete[formControl], ' + 'app-chip-autocomplete[ngModel]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ChipAutocompleteFormDirective,
            multi: true,
        },
    ],
})
export class ChipAutocompleteFormDirective<T = unknown> implements ControlValueAccessor, OnDestroy {
    /**
     * for legacy
     */
    @Input() initialPatchDefaultValue = false;
    /** Gibt ein Mal einen Wert aus, wenn die Direktive zerstört wird */
    private onDestroy$ = new Subject<void>();
    public PreventNextOnChange: boolean = false;
    // für ControlValueAccessor Interface
    private onChange: (value: T[]) => void = () => {};
    private onTouched: () => void = () => {};

    constructor(private component: ChipAutocompleteComponent<T>, private cdr: ChangeDetectorRef) {

        this.component.SelectedOptionsChange.asObservable()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((value) =>
                {
                    if (this.PreventNextOnChange) {
                        this.PreventNextOnChange = false;
                    } else {
                        this.onChange(value);
                        this.onTouched();
                    }
                    // Change Detection beruhigen (siehe https://github.com/angular/angular/issues/22426)

                },
            );
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    // für ControlValueAccessor Interface
    registerOnChange(fn: (value: T[]) => void) {
        this.onChange = fn;
        // fix for legacy Uses which can't handle its default Value
        if (this.initialPatchDefaultValue) {
            this.onChange(this.component.SelectedOptions.slice())
        }
    }
    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean) {
        this.component.Disabled = isDisabled;
    }
    writeValue(obj: T[]) {
        // console.log(obj)
        this.PreventNextOnChange = true; // prevent setValue trigger dirty & touched
        this.component.SelectedOptions = obj;
        // Change Detection beruhigen (siehe https://github.com/angular/angular/issues/22426)
        this.cdr.detectChanges()
    }
}
