import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { PartnerSpecificationType } from '../../dave-data-module/entities/partner.entity';
import { State } from '../../dave-data-module/State';
import { getPartner } from '../../dave-data-module/State/selectors/partners.selectors';
import { BreakpointObserverService } from '../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { CustomerNameService } from '../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { AllCommissionMeta, CommissionMeta } from '../../helper/page-metadata';
import {
    ListLayoutWithRoutingComponent
} from '../../dave-utils-module/dave-shared-components-module/components/templates/list-layout-with-routing/list-layout-with-routing.component';

@Component({
    selector: 'app-dave-commission',
    template: `
        <app-list-layout-with-routing #layout [Path]="PageMetaPath" [TabLabel]="(CS.GetMultiple$() | async) + 'liste'" [HideSideContent]="IsConstruction$ | async">
            <app-customer-list [ListStyle]="PageMetaPath" *ngIf="Layout?.ContentIsVisible$ | async"></app-customer-list>
        </app-list-layout-with-routing>
    `,
    styles: [``],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaveCommissionComponent implements OnInit {
    @ViewChild('layout') Layout: ListLayoutWithRoutingComponent;
    public IsConstruction$ = this.store.select(getPartner).pipe(
        map((partner) => partner.PartnerSpecificationTypeId === PartnerSpecificationType.Construction),
        distinctUntilChanged(),
    );
    constructor(
        private breakpoints: BreakpointObserverService,
        private router: Router,
        private route: ActivatedRoute,
        public CS: CustomerNameService,
        private store: Store<State>,
    ) {}
    PageMetaPath = CommissionMeta.Path;
    ngOnInit(): void {
        if (!this.router.url.includes(`/${CommissionMeta.Path}/`)) {
            this.router.navigate([AllCommissionMeta.Path], {
                relativeTo: this.route,
            });
        }
    }
}
