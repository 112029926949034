import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { PublicFileActionTypes } from '../State/actions/public-file.actions';
import { getPublicFileLatestUpdatedAt,getPublicFilesFetched } from '../State/selectors/public-file.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class PublicFileResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getPublicFilesFetched, getPublicFileLatestUpdatedAt, PublicFileActionTypes.Load, store);
    }
}
