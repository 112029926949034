<!--<button (click)='LogSfdt()' style='position:absolute; z-index: 1' >Log Sfdt</button>-->
<ejs-documenteditorcontainer
    #container
    style="display:block;height:100%"
    height="100%"
    [enableToolbar]="EnableToolbar"
    (created)="editorCreated()"
    [serviceUrl]="ServiceUrl"
    [headers]="AuthHeader$ | async"
    [locale]="'de'"
    [toolbarItems]="ToolbarItems"
    (toolbarClick)="OnToolbarClick($event)"
    [restrictEditing]="RestrictEditing"
    (contentChange)="ContentChange.emit($event)"
    [enableAutoFocus]="EnableAutoFocus"

    [documentEditorSettings]="{optimizeSfdt: EnableOptimization }"
    *ngIf="editorVisible$ | async"
>
</ejs-documenteditorcontainer>
<link href="https://cdn.syncfusion.com/ej2/custom-icons.css" rel="stylesheet" />
<input hidden #fileUpload type="file" (change)="fileUploadChange($event)" accept=".dotx,.docx,.docm,.dot,.doc,.rtf,.txt,.xml,.sfdt"/>
