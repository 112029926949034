import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';

/** Service, der Interaktionen mit `window.document` isoliert */
@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    constructor(@Optional() @Inject(DOCUMENT) private document?: Document) {}

    /** Kopiert `text` in die Zwischenablage des Nutzers, wenn der Browser dies zulässt */
    public CopyToClipboard(text: string): void {
        if (!this.document) {
            return;
        }

        const textarea = this.document.createElement('textarea');
        textarea.style.position = 'fixed';
        textarea.style.top = '0';
        textarea.style.left = '0';
        textarea.style.opacity = '0';
        textarea.value = text;

        this.document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();
        this.document.execCommand('copy');

        this.document.body.removeChild(textarea);
    }
}
