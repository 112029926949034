import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
    AllCommissionMeta,
    AllGeneratedDocumentsMeta,
    CommissionMeta,
    CustomerAdministrationMeta,
    DMSPageMeta, DocumentEditorMeta, GeneratedDocumentsPageMeta,
} from '../../helper/page-metadata';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'app-go-to-button',
    templateUrl: './go-to-button.component.html',
    styleUrls: ['./go-to-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoToButtonComponent {
    public RouterLink : Array<string | number> = null;
    public QueryParams = null;
    public Icon: IconProp;
    private entityType: 'commission' | 'customer' | 'file' | 'generatedDocument'  | 'folder' = null;
    private entityId: number = null;
    @Input() set EntityType(type: typeof this.entityType) {
        this.entityType = type;
        this.setRouterLink();
    }
    @Input() set EntityId(id: number) {
        this.entityId = id;
        this.setRouterLink();
    }
    constructor() {}
    @HostBinding('class') cssClass: string = null;
    @HostBinding('class.theme-override') themeOverride = true;
    setRouterLink() {
        let path = null;
        let params = null
        let icon = null;
        let cssClass = null;
        if (this.entityType && this.entityId) {
            switch (this.entityType) {
                case 'commission':
                    path = ['/', CommissionMeta.Path, AllCommissionMeta.Path, this.entityId];
                    icon = CommissionMeta.Icon;
                    cssClass = CommissionMeta.Theme;
                    break;
                case 'customer':
                    path = ['/', CustomerAdministrationMeta.Path, this.entityId];
                    icon = CustomerAdministrationMeta.Icon;
                    cssClass = CustomerAdministrationMeta.Theme;
                    break;
                case 'file':
                    path = ['/', DMSPageMeta.Path];
                    params = { documentId: this.entityId };
                    icon = DMSPageMeta.Icon;
                    cssClass = DMSPageMeta.Theme;
                    break;
                case 'generatedDocument':
                    path = ['/', GeneratedDocumentsPageMeta.Path, AllGeneratedDocumentsMeta.Path, DocumentEditorMeta.Path, this.entityId];
                    icon = GeneratedDocumentsPageMeta.Icon;
                    cssClass = GeneratedDocumentsPageMeta.Theme;
                    break;
                case 'folder':
                    path = ['/', DMSPageMeta.Path];
                    params = { folderId: this.entityId };
                    icon = DMSPageMeta.Icon;
                    cssClass = DMSPageMeta.Theme;
                    break;
            }
        }
        this.RouterLink = path;
        this.QueryParams = params;
        this.Icon = icon;
        this.cssClass = cssClass;
    }
}
