<mat-select #select [attr.aria-labelledby]="_formField.getLabelId()" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)" [formControl]="form" [compareWith]="CompareOptions" (selectionChange)="selectionChange.emit($event)" [panelClass]="panelClass">
    <mat-option>
        <ngx-mat-select-search [searching]="isSearching$ | async" [formControl]="searchForm" [placeholderLabel]="SearchPlaceholderLabel" [noEntriesFoundLabel]="NoEntriesFoundLabel">
            <fa-icon ngxMatSelectSearchClear icon="times"></fa-icon>
        </ngx-mat-select-search>
    </mat-option>
    <mat-option class="shadow-option" *ngIf="shadowOption$ | async as option" [value]="option">
        <ng-container *ngTemplateOutlet="OptionTemplate ? OptionTemplate : defaultTemplate; context: { $implicit: option }"></ng-container>
    </mat-option>
    <cdk-virtual-scroll-viewport [itemSize]="itemSize" minBufferPx="200" maxBufferPx="400" class="viewport-app-select-search" orientation="vertical">
        <mat-option *cdkVirtualFor="let option of filteredOptions$ | async" [value]="option">
            <ng-container *ngTemplateOutlet="OptionTemplate ? OptionTemplate : defaultTemplate; context: { $implicit: option }"></ng-container>
        </mat-option>
    </cdk-virtual-scroll-viewport>
</mat-select>

<ng-template #defaultTemplate let-option>
    {{ option.optionLabel }} <fa-icon *ngIf="option.infoTooltip" icon="question-circle" [matTooltip]="option.infoTooltip"></fa-icon>
</ng-template>
