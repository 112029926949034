import { NgModule } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { SimplebarAngularModule } from "simplebar-angular";
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import {
DropzoneComponent
} from "../../../dave-utils-module/dave-shared-components-module/components/dropzone/dropzone.component";
import { DaveSharedComponentsModule } from '../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { SelectSearchModule } from '../../../dave-utils-module/select-search/select-search.module';
import { TagChipAutocompleteModule } from '../tag-chip-autocomplete/tag-chip-autocomplete.module';
import { DaveFileUploadDialogComponent } from './component/dave-file-upload-dialog/dave-file-upload-dialog.component';
import { NewDocumentViewComponent } from './component/new-document-view.component';


@NgModule({
    declarations: [NewDocumentViewComponent, DaveFileUploadDialogComponent],
    exports: [NewDocumentViewComponent],
    imports: [SimplebarAngularModule, FontAwesomeModule, DropzoneModule, DaveSharedComponentsModule, SelectSearchModule, TagChipAutocompleteModule, AppButtonModule, MatCardModule, MatButtonModule, DropzoneComponent, MatTooltipModule],
})
export class NewDocumentViewMenu {}
