<ng-container *ngIf="!(Mobile$ | async)">
    <main class="desktop-wrapper dave-home-dashboard-background-image">
        <div style="z-index:100" class="notification-wrapper" *ngIf="(Notifications$.Notifications$ | async)?.length">
            <ng-container *ngFor="let notification of Notifications$.Notifications$ | async">
                <mat-card class="notification" *ngIf="notification.shown">
                    <button mat-icon-button class="close-button" (click)="notification.shown = false">
                        <fa-icon icon="times"></fa-icon>
                    </button>
                    <p mat-card-title>{{ notification.headline }}</p>
                    <p mat-card-content>{{ notification.message }}</p>
                    <a
                        mat-card-content
                        app-button
                        *ngIf="notification.routingButtonRoute"
                        [matTooltip]="notification.routingButtonTooltip || null"
                        [routerLink]="notification.routingButtonRoute"
                    >
                        {{ notification.routingButtonDescription }}
                    </a>
                </mat-card>
            </ng-container>
        </div>
        <div class="grid-header-item" style="overflow: visible;">
            <header class="widget transparent header" style="height: 100%">
                <div class="logo-wrapper">
                    <img class="c2c-logo" *ngIf="showDefaultLogo$ | async" src="assets/images/logo.svg" />
                    <img class="logo-ext" *ngIf="BrandLogoUrl$ | async as src" [src]="src" />
                </div>
                <div class="search-bar-wrapper">
                    <div class="search-bar" [class.visible]="ShowSearchBar">
                        <ng-container *ngIf="SearchReturn$ | async as s">
                            <span class="search-count" *ngIf="!ShowSearchBar && s.TotalResults > 0">({{ s.TotalResults }})</span>
                        </ng-container>
                        <app-search-bar
                            [SearchTypes]="SelectedTypes"
                            [Focus$]="SearchFocus$"
                            [visible]="ShowSearchBar"
                            (click)="ShowSearchReturn = true; $event.stopPropagation()"
                            (Results)="DisplaySearch($event); ShowSearchReturn = true"
                        ></app-search-bar>
                    </div>
                </div>
                <div class="headline">
                    <h1>{{ GetWelcomeMessage() }}</h1>
                    <h1 *ngIf="Now">Es ist {{ Now[0] }}{{ Now[1] }}:{{ Now[2] }}{{ Now[3] }} Uhr</h1>
                </div>
            </header>
            <div class="search-return" *ngIf="ShowSearchReturn && SearchReturn$ | async as Returns" [class.has-results]="ShowSearchBar && Returns.TotalResults">
                <app-search-return [Returns]="Returns" (selectedTypes)="SelectedTypes = $event" (clickOutside)="ShowSearchReturn = false"></app-search-return>
            </div>
        </div>
        <app-service-buttons *ngIf="Tablet$ | async" class="grid-main4-item widget"></app-service-buttons>
        <div class="grid-side-item">
            <div class="button-wrapper" *ngIf="!(Tablet$ | async)">
                <button
                    [matBadge]="(UnreadPartnerCount$ | async) + ''"
                    [matBadgeHidden]="(UnreadPartnerCount$ | async) === 0"
                    matBadgePosition="below before"
                    matBadgeSize="small"
                    app-round-button
                    [matTooltip]="(IsKagetec$ | async) ? 'Kagetec - News' : 'Schwarzes Brett'"
                    (click)="NewsDialog(true)"
                    class="unseen-mat-badge mat-badge-more-overlay"
                >
                    <fa-icon icon="podcast"></fa-icon>
                </button>
                <button
                    class="unseen-mat-badge mat-badge-more-overlay"
                    [matBadge]="(UnreadCount$ | async) + ''"
                    [matBadgeHidden]="(UnreadCount$ | async) === 0"
                    matBadgePosition="below before"
                    matBadgeSize="small"
                    app-round-button
                    matTooltip="Neuigkeiten"
                    (click)="NewsDialog()"
                >
                    <fa-icon icon="newspaper"></fa-icon>
                </button>

                <button app-round-button matTooltip="Support" (click)="OpenSupportDialog()">
                    <fa-icon icon="info-circle"></fa-icon>
                </button>
                <button app-round-button matTooltip="Logout" (click)="LogoutService.Logout()">
                    <fa-icon icon="sign-out-alt"></fa-icon>
                </button>
            </div>
            <app-weather [Clock]="false" class="widget transparent"></app-weather>
        </div>

        <div class="grid-main1-item widget flex-column">
<!--            <div class="widget">-->
                <app-service-buttons *ngIf="!(Tablet$ | async)"></app-service-buttons>
                <app-services [MaxColumnCount]="(Tablet$ | async) ? null : 4"></app-services>
<!--            </div>-->
        </div>

        <div class="grid-main2-item">
            <!--product check here-->
            <app-commission-dashboard class="widget" *ngIf="(PS.Has(PS.Permission.GetCommission) | async) && (HasCommissionProduct$ | async)"></app-commission-dashboard>
        </div>

        <div class="grid-main3-item">
            <app-history-bookmarks class="widget"></app-history-bookmarks>
        </div>
    </main>
</ng-container>
<ng-container *ngIf="Mobile$ | async">
    <div class="mobile-wrapper">
        <main class="mobile-main" *ngIf="Dashboard">
            <div class="search-bar-wrapper">
                <div class="search-bar" [class.visible]="ShowSearchBar">
                    <ng-container *ngIf="SearchReturn$ | async as s">
                        <span class="search-count" *ngIf="!ShowSearchBar && s.TotalResults > 0">({{ s.TotalResults }})</span>
                    </ng-container>
                    <app-search-bar
                        [visible]="ShowSearchBar"
                        (click)="ShowSearchReturn = true; $event.stopPropagation()"
                        (Results)="DisplaySearch($event); ShowSearchReturn = true"
                    ></app-search-bar>
                </div>
            </div>
            <div class="search-return" *ngIf="ShowSearchReturn && SearchReturn$ | async as Returns" [class.has-results]="ShowSearchBar && Returns.TotalResults">
                <ngx-simplebar style="height: 100%">
                    <app-search-return [Returns]="Returns" (clickOutside)="ShowSearchReturn = false"></app-search-return>
                </ngx-simplebar>
            </div>
            <div class="welcome-widget dave-background-image">
                <div class="welcome-message">
                    <h1 class="headline">{{ GetWelcomeMessage() }}</h1>
                </div>
                <app-weather [Clock]="true" class="widget weather-mobile transparent"></app-weather>
            </div>
            <app-service-buttons></app-service-buttons>
            <app-services></app-services>
        </main>

        <main class="mobile-main" *ngIf="Bookmarks">
            <app-history-bookmarks></app-history-bookmarks>
        </main>

        <main class="mobile-main" *ngIf="Comissions">
            <app-commission-dashboard></app-commission-dashboard>
        </main>
    </div>

    <div class="menue-button">
        <button mat-fab class="unseen-mat-badge mat-badge-more-overlay" color="primary" matTooltip="Menü" matTooltipPosition="left" [matMenuTriggerFor]="menu" [matBadge]="UnseenTaskCount$ | async" [matBadgeHidden]="!(UnseenTaskCount$ | async)">
            <fa-icon icon="bars" size="lg"></fa-icon>
        </button>
    </div>
    <mat-menu #menu="matMenu">
        <button (click)="SetDashboard()" mat-menu-item>
            <fa-icon class="mat-icon" icon="home"></fa-icon>
            <span>Dashboard</span>
        </button>

        <button (click)="SetBookmarks()" mat-menu-item *ngIf="PS.Has(PS.Permission.GetEvents) | async">
            <fa-icon class="mat-icon" [icon]="['far', 'bookmark']"></fa-icon>
            <span class="unseen-mat-badge" [matBadge]="UnseenTaskCount$ | async" [matBadgeHidden]="!(UnseenTaskCount$ | async)" matBadgeOverlap="false" matBadgeSize="small">Meine Aufgaben ({{TaskCount$ | async}})</span>
        </button>

        <button (click)="SetComissions()" mat-menu-item *ngIf="PS.Has(PS.Permission.GetCommission) | async">
            <fa-icon class="mat-icon" [icon]="'file-contract'"></fa-icon>
            <span>{{cls.getMultiple$('Commission') | async}}</span>
        </button>
        <button mat-menu-item (click)="NewsDialog(true)">
            <fa-icon class="mat-icon" icon="podcast"></fa-icon>
            <span>{{(IsKagetec$ | async) ? 'Kagetec - News' : 'Schwarzes Brett'}}</span>
        </button>
        <button mat-menu-item (click)="NewsDialog()">
            <fa-icon class="mat-icon" icon="newspaper"></fa-icon>
            <span>News</span>
        </button>
        <button (click)="OpenSupportDialog()" mat-menu-item>
            <fa-icon class="mat-icon" icon="info-circle"></fa-icon>
            <span>Support</span>
        </button>

        <button (click)="LogoutService.Logout()" mat-menu-item>
            <fa-icon class="mat-icon" icon="sign-out-alt"></fa-icon>
            <span>Logout</span>
        </button>
    </mat-menu>
</ng-container>
