import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectTextTemplatesComponent } from './components/select-text-templates/select-text-templates.component';
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { SimplebarAngularModule } from 'simplebar-angular';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { DaveListCardModule } from '../dave-list-card/dave-list-card.module';
import { MatSortModule } from '@angular/material/sort';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { TextAreaTemplatesComponent } from './components/text-area-templates/text-area-templates.component';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
    declarations: [SelectTextTemplatesComponent, TextAreaTemplatesComponent],
    exports: [
        TextAreaTemplatesComponent,
        TextAreaTemplatesComponent,
    ],
    imports: [
        CommonModule,
        DaveSharedComponentsModule,
        FontAwesomeModule,
        MatTooltipModule,
        AppButtonModule,
        DaveLoadingModule,
        SimplebarAngularModule,
        MatCheckboxModule,
        MatTableModule,
        MatDialogModule,
        MatChipsModule,
        DaveListCardModule,
        MatSortModule,
        ScrollingModule,
        TableVirtualScrollModule,
        MatButtonModule,
    ],
})
export class DaveTextTemplatesModuleModule { }
