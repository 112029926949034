import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { ViewStyleSettingEntity, ViewStyleSettingEntityFromBackend } from '../../entities/viewStyleSetting.entity';
import { HttpService } from '../../services/http.service';
import { BaseActionTypes } from '../actions/base.actions';
import { ViewStyleSettingActionTypes } from '../actions/viewStyleSetting.action';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';

enum ErrorCodes {
    Load = 'ViewStyleSetting Abrufen fehlgeschlagen',
    Modify = 'ViewStyleSetting Bearbeiten fehlgeschlagen',
    Remove = 'ViewStyleSetting Löschen fehlgeschlagen',
    Add = 'ViewStyleSetting Hinzufügen fehlgeschlagen',
}

@Injectable()
export class ViewStyleSettingEffects {

    CreateViewStyleSetting$ = createEffect(() => this.actions$.pipe(
        ofType(ViewStyleSettingActionTypes.Create),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
                mutation {
                    createViewStyleSetting(
                        ${stringifyIfNotNullOrUndefined(Payload, 'userTypeId')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'entityId')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'entityTypeId')}
                        ${Payload.view ? 'view: ' + Payload.view : ''}
                        ${stringifyIfNotNullOrUndefined(Payload, 'config')}
                ) {
                    ${ViewStyleSettingEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    console.log(Payload);
                    return res && res.createViewStyleSetting
                        ? ViewStyleSettingActionTypes.UpdateOne({
                              Payload: ViewStyleSettingEntityFromBackend(res.createViewStyleSetting),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    //view wird nicht übergeben, da keine berechtigungen

    ModifyViewStyleSetting$ = createEffect(() => this.actions$.pipe(
        ofType(ViewStyleSettingActionTypes.Change),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
            mutation {
                changeViewStyleSetting(
                    id: ${Payload.id}
                    ${stringifyIfNotNullOrUndefined(Payload, 'userTypeId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'entityId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'entityTypeId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'config')}
                ) {
                    ${ViewStyleSettingEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res => {
                    const ret: Action[] = [];
                    if (res && res.changeViewStyleSetting) {
                        ret.push(
                            ViewStyleSettingActionTypes.UpdateOne({
                                Payload: ViewStyleSettingEntityFromBackend(res.changeViewStyleSetting),
                            }),
                        );
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    GetViewStyleSetting$ = createEffect(() => this.actions$.pipe(
        ofType(ViewStyleSettingActionTypes.LoadAll),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([action, token]) => {
            const queryString = `
            query {
                viewStyleSetting {
                    ${ViewStyleSettingEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                map(res => {
                    return res && res.viewStyleSetting
                        ? ViewStyleSettingActionTypes.UpdateAll({
                              Payload: res.viewStyleSetting.map(e => ViewStyleSettingEntityFromBackend(e)),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    RemoveViewStyleSetting$ = createEffect(() => this.actions$.pipe(
        ofType(ViewStyleSettingActionTypes.Delete),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
                mutation{
                  deleteViewStyleSetting(
                  id: ${action.Payload}
                  )
                }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.deleteViewStyleSetting
                        ? ViewStyleSettingActionTypes.DeleteOne({
                              Payload: action.Payload,
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    // @Effect()
    // SetUser$ = this.actions$.pipe(
    //     ofType(ViewStyleSettingActionTypes.SetViewStyleSettingUser),
    //     withLatestFrom(this.store$),
    //     concatMap(([action, store]) => {
    //         const queryString = `mutation{
    //             setUser2ViewStyleSetting(
    //                 userIds: ${JSON.stringify(action.Payload.UserIds)},
    //                 viewStyleSettingId: ${action.Payload.ViewStyleSettingId}
    //             ){
    //                 userId
    //             }
    //         }`;

    //         return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
    //             map(res => {
    //                 if (res && res.setUser2ViewStyleSetting) {
    //                     const viewStyleSetting = store.viewStyleSettings.viewStyleSettings.find(val => val.Id === action.Payload.ViewStyleSettingId);
    //                     return ViewStyleSettingActionTypes.ModifyViewStyleSetting({
    //                         Payload: {
    //                             ViewStyleSetting: store.viewStyleSettings.viewStyleSettings
    //                                 .find(val => val.Id === action.Payload.ViewStyleSettingId)
    //                                 .Clone({ UserIds: res.setUser2ViewStyleSetting.map(u => u.userId) }),
    //                         },
    //                     });
    //                 } else {
    //                     return BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.SetUser } });
    //                 }
    //             }),
    //             catchError((err, caught) =>
    //                 of(
    //                     BaseActionTypes.ErrorAction({
    //                         Payload: {
    //                             ToasterMessage: ErrorCodes.SetUser,
    //                             Err: err,
    //                             Caught: caught,
    //                         },
    //                     }),
    //                 ),
    //             ),
    //         );
    //     }),
    // );

    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
