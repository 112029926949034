import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { UserToCustomerActionTypes } from '../State/actions/customers.actions';
import { getUserToCustomerLatestUpdatedAt, getUserToCustomersFetched } from '../State/selectors/user-to-customer.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class UserToCustomerResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getUserToCustomersFetched, getUserToCustomerLatestUpdatedAt, UserToCustomerActionTypes.Load, store);
    }
}
