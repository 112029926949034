import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, of, Subscription } from "rxjs";
import { filter, first, map, skip, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { DamageFlowEntity } from '../../../dave-data-module/entities/damageflow.entity';
import { EventTypeEntity } from '../../../dave-data-module/entities/event-type.entity';
import { EventEntity } from '../../../dave-data-module/entities/event.entity';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import {
    NewEventClearStore,
    NewEventSetCity,
    NewEventSetCommission,
    NewEventSetContactPersonId,
    NewEventSetCountry,
    NewEventSetCustomerId,
    NewEventSetDate,
    NewEventSetEndDate,
    NewEventSetEndTime,
    NewEventSetEventTypeId,
    NewEventSetName,
    NewEventSetNote,
    NewEventSetPostalCode,
    NewEventSetPrivate,
    NewEventSetStartTime,
    NewEventSetStreet,
} from '../../../dave-data-module/State/actions/new-event.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getCommissions, getCommissionsActive } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getDamageFlows } from '../../../dave-data-module/State/selectors/damageflow.selector';
import { getEventTypes } from '../../../dave-data-module/State/selectors/event-type.selector';
import { getEventFormData, getEvents } from '../../../dave-data-module/State/selectors/events.selectors';
import { getOffices } from '../../../dave-data-module/State/selectors/offices.selectors';
import { getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { RoutingHistoryService } from '../../../dave-utils-module/dave-shared-components-module/services/routing-history.service';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { isNotNullOrUndefined, OpenHTMLInputPicker, sameDay } from "../../../helper/helper";
import { getErrorMessage, startTimeBeforeEndTimeValidator } from '../../../helper/validation.helper';
import { CustomLabelService } from '../../../services/custom-label.service';
import { SelectPersonService } from '../../../services/select-person.service';
import { NewDocumentViewComponent } from '../../templates/new-document-view/component/new-document-view.component';
import { getProcessesActive } from "../../../dave-data-module/State/selectors/process.selector";
import { Actions, ofType } from "@ngrx/effects";
import { BaseActionTypes } from "../../../dave-data-module/State/actions/base.actions";
import moment from "moment";

@Component({
    selector: 'app-new-event',
    templateUrl: 'new-event.component.html',
    styleUrls: ['new-event.component.scss'],
})
export class NewEventComponent implements OnInit, OnDestroy, AfterViewInit {
    public OpenHTMLInputPicker = OpenHTMLInputPicker;
    // Observables
    protected ProcessList$ = this.store.select(getProcessesActive);
    public Customers$: Observable<SelectSearchData[]>;
    private changeCustomer$ = new BehaviorSubject<number | null>(null);
    private subscriptions: Subscription[] = [];
    private eventTypes$: Observable<EventTypeEntity[]>;
    private persons$: Observable<PersonEntity[]>;
    private events$: Observable<EventEntity[]>;
    private customers$: Observable<CustomerEntity[]>;
    private damageFlows$: Observable<DamageFlowEntity[]>;
    private formData$ = this.store.select(getEventFormData);
    private defaultCommission$ = new BehaviorSubject<CommissionEntity>(null);

    // NgRx Store data
    public EventTypeList: EventTypeEntity[] = null;
    public CustomerList: CustomerEntity[] = null;
    public DamageFlowList: DamageFlowEntity[] = null;
    public PersonList: PersonEntity[] = [];
    private eventList: EventEntity[] = [];
    private customerId: number;

    // Other
    public GetErrorMessage = getErrorMessage;

    public UploadOpen = false;
    public AddressForm = new FormGroup({
        Street: new FormControl(''),
        PostalCode: new FormControl(''),
        City: new FormControl(''),
        Country: new FormControl(''),
    });
    public NewEventForm = new FormGroup(
        {
            EventType: new FormControl<EventTypeEntity>(null, Validators.required),
            Person: new FormControl<PersonEntity | null>(null),
            Process: new FormControl<PersonEntity | null>(null),
            Private: new FormControl(false),
            EventDate: new FormControl(moment(), Validators.required),
            EventEndDate: new FormControl(moment(), Validators.required),
            EventStartTimeString: new FormControl('', {
                validators: Validators.required,
                updateOn: 'blur',
            }),
            EventEndTimeString: new FormControl('', {
                validators: Validators.required,
                updateOn: 'blur',
            }),
            Note: new FormControl('', {
                updateOn: 'blur',
            }),
            CommissionId: new FormControl<SelectSearchData | null>(null),
            Customer: new FormControl<CustomerEntity | null>(null),
            Name: new FormControl('', {
                updateOn: 'blur',
            }),
        },
        startTimeBeforeEndTimeValidator,
    );

    public SearchLocationForm = new UntypedFormControl(null);

    public AddressTemplateData$: Observable<IDetailListTemplateData> = combineLatest([this.store.select(getOffices).pipe(map((o) => o.filter((o) => o.Street))), this.store.select(getCustomers)]).pipe(
        map(([offices, customers]) => {
            return {
                Headline: 'Adresse',
                HeaderIcon: 'map-marker-alt',
                Properties: [
                    {
                        key: 'Adressen durchsuchen',
                        formControl: this.SearchLocationForm,
                        options: {
                            specialInput: {
                                selectSearch: offices.map((o) => ({
                                    optionValue: o.Id,
                                    optionLabel: [customers.find((c) => c.Id === o.CustomerId)?.Name, '-', o.Street, o.PostalCode, o.City, o.Country].join(' '),
                                })),
                            },
                        },
                    },
                    {
                        key: 'Adresse',
                        options: {
                            type: CustomPropertyType.Location,
                            specialInput: {
                                location: {
                                    value: this.AddressForm.value,
                                    formGroup: this.AddressForm,
                                },
                            },
                        },
                    },
                ],
            };
        }),
    );
    public Persons$: Observable<SelectSearchData[]> = combineLatest([
        this.store.select(getPersons),
        this.store.select(getPerson2Entities).pipe(map((p2es) => p2es?.filter((p2e) => p2e.EntityType === Person2EntityEntityTypeEnum.Customer))),
        this.store.select(getPerson2Entities).pipe(map((p2es) => p2es?.filter((p2e) => p2e.EntityType === Person2EntityEntityTypeEnum.Commission))),
    ]).pipe(
        switchMap(([persons, p2esCustomer, p2esCommission]) =>
            combineLatest([
                this.NewEventForm.controls.Customer.valueChanges.pipe(startWith(this.NewEventForm.controls.Customer.value)),
                this.NewEventForm.controls.CommissionId.valueChanges.pipe(startWith(this.NewEventForm.controls.CommissionId.value)),
            ]).pipe(
                map(([customer, commission]) => {
                    const contactPersonIds = customer?.Id ? p2esCustomer.filter((p2e) => p2e.EntityId === customer.Id).map((p2e) => p2e.PersonId) : [];
                    const commissionPersonIds = commission?.Id ? p2esCommission.filter((p2e) => p2e.EntityId === commission.Id).map((p2e) => p2e.PersonId) : [];
                    let filteredPersons = persons;
                    if (customer) {
                        filteredPersons = filteredPersons.filter((person) => contactPersonIds.includes(person.Id));
                    }
                    return [
                        { Id: null, Name: '--' },
                        ...filteredPersons
                            .sort((a, b) => {
                                if (contactPersonIds.includes(a.Id) !== contactPersonIds.includes(b.Id)) {
                                    return contactPersonIds.includes(a.Id) ? -1 : 1;
                                }
                                if (commissionPersonIds.includes(a.Id) !== commissionPersonIds.includes(b.Id)) {
                                    return commissionPersonIds.includes(a.Id) ? -1 : 1;
                                }
                                return a.CreatedAt.getTime() - b.CreatedAt.getTime();
                            })
                            .map((c) => ({
                                Id: c.Id,
                                Name: c.DisplayName,
                            })),
                    ];
                }),
            ),
        ),
    );
    public Commissions$: Observable<SelectSearchData[]> = combineLatest([merge(this.CustomerForm.valueChanges, of(null)), this.store.select(getCommissionsActive), this.defaultCommission$]).pipe(
        map(([customer, commissions, defaultCommission]) => {
            const cs = defaultCommission ? [defaultCommission, ...commissions.filter((c) => c.Id !== defaultCommission.Id)] : commissions;
            return customer ? cs.filter((c) => !customer || c.CustomerId === customer.Id) : commissions;
        }),
        map((commissions) =>
            commissions?.map((c) => ({
                Id: c.Id,
                Name: c.DisplayName,
            })),
        ),
    );
    // public Commissions$: Observable<SelectSearchData[]> = combineLatest([
    //     merge(this.CustomerForm.valueChanges, of(null)),
    //     this.store.select(getCommissions),
    // ]).pipe(
    //     map(([customer, commissions]) => {
    //         return customer ? commissions.filter(c => !customer || c.CustomerId === customer.Id) : commissions;
    //     }),
    //     map(commissions => commissions?.map(c => ({ Id: c.Id, Name: c.GetDisplayName() }))),
    // );
    public Customer: CustomerEntity = null;

    private fileUploadError = false;
    @ViewChild('FileUpload') private fileUpload: NewDocumentViewComponent;
    constructor(
        private store: Store<State>,
        private router: Router,
        private routingHistoryService: RoutingHistoryService,
        private route: ActivatedRoute,
        public CS: CustomerNameService,
        protected cls: CustomLabelService,
        private sp: SelectPersonService,
        private actions$: Actions,
    ) {
        this.Customers$ = store.select(getCustomers).pipe(
            map((customers) => customers.filter((c) => !c.Deleted)),
            map((c) => [
                { Id: null, Name: '--' },
                ...c.map((cus) => {
                    return { Id: cus.Id, Name: cus.DisplayInformation };
                }),
            ]),
        );
        this.eventTypes$ = store.select(getEventTypes);
        this.persons$ = combineLatest([this.store.select(getPersons), this.changeCustomer$, this.store.select(getCommissions)]).pipe(
            tap(([, customerId, commissions]) => {
                if (customerId) {
                    this.PersonForm.setValue(null);

                    if (commissions?.find((c) => this.CommissionForm.value?.Id === c.Id)?.CustomerId !== customerId) {
                        this.CommissionForm.setValue(null);
                    }
                }
            }),
            map(([persons]) => persons),
        );
        this.events$ = store.select(getEvents);
        this.customers$ = store.select(getCustomers);
        this.damageFlows$ = store.select(getDamageFlows);
    }

    ngAfterViewInit(): void {
        this.CustomerForm.setValue(this.CustomerForm.value); // fix, dont touch
    }

    private disableClearStore = false;

    ngOnInit() {
        this.subscriptions.push(
            this.SearchLocationForm.valueChanges.pipe(skip(1), filter(isNotNullOrUndefined), withLatestFrom(this.store.select(getOffices))).subscribe(([v, o]) => {
                const address = o.find((o) => o.Id === v.Id);
                this.AddressForm.setValue({
                    Street: address.Street,
                    PostalCode: address.PostalCode,
                    City: address.City,
                    Country: address.Country,
                });
            }),
            this.NewEventForm.controls.Customer.valueChanges
                .pipe(
                    withLatestFrom(this.customers$),
                    tap(([value, customers]) => {
                        this.Customer = customers.find((c) => c.Id === value?.Id);
                    }),
                )
                .subscribe(),
            // Store leeren, wenn aus der Komponente herausgeroutet wird.
            // - Warum nicht in ngOnDestroy? Weil dieser Workaround nur deshalb
            //   existiert, da wir das RouterOutlet verschieben und dadurch
            //   die Komponente destroyed wird
            //   => siehe http://jira.omnia5.local/browse/DHISTORY-267
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationStart && !this.disableClearStore),
                    tap(() => this.store.dispatch(new NewEventClearStore())),
                )
                .subscribe(),

            this.EventTypeForm.valueChanges.subscribe((value) => {
                this.store.dispatch(new NewEventSetEventTypeId({ EventTypeId: value.Id }));
                this.SetAddressFromCommission();
            }),
            this.PersonForm.valueChanges.subscribe((value) =>
                this.store.dispatch(
                    new NewEventSetContactPersonId({
                        PersonId: value?.Id,
                    }),
                ),
            ),
            this.PrivateForm?.valueChanges.subscribe((value) => this.store.dispatch(new NewEventSetPrivate({ Private: value }))),
            // this.EventDateForm.valueChanges.subscribe((value) => {
            //     this.store.dispatch(new NewEventSetDate({ Date: value }));
            // }),
            // this.EventEndDateForm.valueChanges.subscribe((value) => {
            //     this.store.dispatch(new NewEventSetEndDate({ EndDate: value }));
            // }),
            // this.EventStartTimeStringForm.valueChanges.subscribe((value) => this.store.dispatch(new NewEventSetStartTime({ StartTime: value }))),
            // this.EventEndTimeStringForm.valueChanges.subscribe((value) => this.store.dispatch(new NewEventSetEndTime({ EndTime: value }))),
            this.NoteForm.valueChanges.subscribe((value) => this.store.dispatch(new NewEventSetNote({ Note: value }))),
            this.AddressForm.controls.Street.valueChanges.subscribe((value) => this.store.dispatch(new NewEventSetStreet({ Street: value }))),
            this.AddressForm.controls.City.valueChanges.subscribe((value) => this.store.dispatch(new NewEventSetCity({ City: value }))),
            this.AddressForm.controls.Country.valueChanges.subscribe((value) => this.store.dispatch(new NewEventSetCountry({ Country: value }))),
            this.AddressForm.controls.PostalCode.valueChanges.subscribe((value) => this.store.dispatch(new NewEventSetPostalCode({ PostalCode: value }))),
            this.NewEventForm.controls.Name.valueChanges.subscribe((value) => this.store.dispatch(new NewEventSetName({ Name: value }))),
            this.CommissionForm.valueChanges.subscribe((value) => {
                this.store.dispatch(new NewEventSetCommission({ CommissionId: value?.Id }));
                this.SetAddressFromCommission();
            }),
            this.CustomerForm.valueChanges.subscribe((value) => {
                this.store.dispatch(new NewEventSetCustomerId({ CustomerId: value?.Id }));
                this.changeCustomer$.next(value?.Id);
            }),

            // Eingabefelder aus Store befüllen
            this.formData$.pipe(withLatestFrom(this.store.select(getCustomers))).subscribe(([formData, customers]) => {
                const selectedCustomer = customers.find((c) => c.Id === formData.CustomerId);
                this.NewEventForm.setValue(
                    {
                        EventType: formData.EventType,
                        Person: formData.Person,
                        Private: formData.Private,
                        EventDate: this.NewEventForm.value.EventDate,
                        EventEndDate: this.NewEventForm.value.EventEndDate,
                        EventStartTimeString: this.NewEventForm.value.EventStartTimeString,
                        EventEndTimeString: this.NewEventForm.value.EventEndTimeString,
                        Note: formData.Note,
                        CommissionId: { Id: formData.CommissionId, Name: null },
                        Customer: selectedCustomer ? selectedCustomer : null,
                        Name: formData.Name,
                        Process: this.NewEventForm.value.Process,
                    },
                    {
                        // 'emitEvent: false' sodass keine Endlosschleife mit den
                        // .valueChanges Observables entsteht
                        emitEvent: false,
                    },
                );
            }),

            combineLatest([combineLatest([this.persons$, this.events$, this.eventTypes$, this.customers$]), this.damageFlows$]).subscribe(
                ([[contactPersons, events, eventTypes, customers], damageFlows]) => {
                    this.PersonList = contactPersons;
                    this.eventList = events;
                    this.EventTypeList = eventTypes;
                    this.CustomerList = customers;
                    this.DamageFlowList = damageFlows;
                    this.isReadyToShow();
                },
            ),
        );

        // Initialisieren der Felder. ContactPerson wird eventuell in
        // isReadyToShow gesetzt und EventType kann nur der User selbst setzen
        if (!this.EventDateForm.value) {
            const startDate = new Date();
            startDate.setHours(0, 0, 0, 0);
            this.EventDateForm.setValue(moment(startDate));
        }
        if (!this.EventEndDateForm.value) {
            const endDate = new Date();
            endDate.setHours(0, 0, 0, 0);
            this.EventEndDateForm.setValue(moment(endDate));
        }
        const date = new Date();
        const h = date.getHours();
        const m = date.getMinutes();
        const timeString = `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}`;

        if (!this.EventStartTimeStringForm.value) {
            this.EventStartTimeStringForm.setValue(timeString);
        }
        if (!this.EventEndTimeStringForm.value) {
            this.EventEndTimeStringForm.setValue(timeString);
        }

        // if (!this.EventEndTimeStringForm.value) {
        //     this.EventEndTimeStringForm.setValue(timeString);
        // }

        if (typeof this.NoteForm.value !== 'string') {
            this.NoteForm.setValue('');
        }
        combineLatest([
            this.route.queryParamMap,
            this.store.select(getCommissions).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getCustomers).pipe(filter(isNotNullOrUndefined)),
            this.Commissions$.pipe(filter(isNotNullOrUndefined)),
        ])
            .pipe(take(1))
            .subscribe(([params, commissions, customers]) => {
                if (params.get('commissionId')) {
                    this.NewEventForm.controls.CommissionId.setValue({ Id: +params.get('commissionId'), Name: 'test' });
                    const commission = commissions.find((c) => c.Id === +params.get('commissionId'));
                    this.defaultCommission$.next(commission);
                    if (commission?.CustomerId) {
                        this.NewEventForm.controls.Customer.setValue(customers.find((c) => c.Id === commission.CustomerId));
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    get Error() {
        return this.NewEventForm.hasError('startTimeBeforeEndTimeValidator') ? 'Die Start-Zeit muss vor der Ende-Zeit liegen.' : this.fileUploadError ? 'Dateien Hochladen fehlgeschlagen...' : '';
    }

    private isReadyToShow() {
        this.customerId = +this.route.snapshot.paramMap.get('customerId');
        if (!this.CustomerForm.value && this.customerId) {
            this.customers$.subscribe((customers) => {
                this.CustomerForm.setValue(
                    customers.find((c) => this.customerId === c.Id),
                    { emitEvent: true },
                );
            });
        }

        if (this.eventList && this.eventList.length) {
            let lastEvent = null;
            this.eventList.forEach((eventItem) => {
                lastEvent = (!lastEvent || eventItem.Id > lastEvent.Id) && eventItem.CustomerId === this.customerId ? eventItem : lastEvent;
            });
            if (lastEvent && !this.PersonForm.value && this.PersonList.length) {
                this.PersonForm.setValue(this.PersonList.find((value) => value.Id === lastEvent.ContactPersonId));
            }
        }
    }

    public SetAddressFromCommission() {
        if (this.NewEventForm.controls.EventType.value?.Name == 'Ortstermin' && this.NewEventForm.controls.CommissionId?.value?.Id != null) {
            let damageFlow = this.DamageFlowList.find((df) => df.CommissionId === this.NewEventForm.controls.CommissionId?.value?.Id);
            this.AddressForm.controls.Street.setValue(damageFlow?.Street);
            this.AddressForm.controls.PostalCode.setValue(damageFlow?.PostalCode);
            this.AddressForm.controls.City.setValue(damageFlow?.City);
            this.AddressForm.controls.Country.setValue(damageFlow?.Country);
        }
    }

    public Cancel() {
        this.NewEventForm.reset(this.NewEventForm.value);
        this.routingHistoryService.NavigateBackwards();
    }

    public Submit() {
        if (this.NewEventForm.invalid) {
            return;
        }

        const eventEndDate = new Date(
            this.NewEventForm.value.EventEndDate.toDate().getFullYear(),
            this.NewEventForm.value.EventEndDate.toDate().getMonth(),
            this.NewEventForm.value.EventEndDate.toDate().getDate(),
            +this.NewEventForm.value.EventEndTimeString.split(':')[0],
            +this.NewEventForm.value.EventEndTimeString.split(':')[1],
            this.NewEventForm.value.EventEndDate.toDate().getSeconds(),
            this.NewEventForm.value.EventEndDate.toDate().getMilliseconds(),
        );
        this.NewEventForm.value.EventDate.set({
            hour: +this.NewEventForm.value.EventStartTimeString.split(':')[0],
            minute: +this.NewEventForm.value.EventStartTimeString.split(':')[1],
        });

        this.eventTypes$.pipe(take(1)).subscribe((eventTypes) => {
            let name = this.NewEventForm.value.Name;

            if (name === null || name === '') {
                console.warn(this.NewEventForm.value.EventType.Id, eventTypes);
                let type = eventTypes.find((t) => t.Id == this.NewEventForm.value.EventType.Id);
                if (type) {
                    name = type.Name;
                }
            }
             firstValueFrom(this.actions$.pipe(ofType(EventsActionTypes.UpdateEvent, BaseActionTypes.ErrorAction))).then(action => {
                 if (action.type === EventsActionTypes.UpdateEvent.type) {
                     if (this.fileUpload && this.fileUpload.FileCount) {
                         this.fileUpload.EventId = action.Payload.Id;
                         this.fileUpload.UploadSuccess.subscribe(() => {
                             this.onEventCreatedSucc(action.Payload.Id)
                         });
                         this.fileUpload.Save();
                     } else {
                         this.onEventCreatedSucc(action.Payload.Id)
                     }
                 }
             })
            this.store.dispatch(
                EventsActionTypes.AddEvent({
                    Payload: {
                        personId: this.NewEventForm.value.Person?.Id,
                        customerId: this.NewEventForm.value.Customer?.Id,
                        hint: null,
                        street: this.AddressForm.value.Street,
                        city: this.AddressForm.value.City,
                        country: this.AddressForm.value.Country,
                        postalCode: this.AddressForm.value.PostalCode,
                        commissionId: this.NewEventForm.value.CommissionId.Id,
                        eventTypeId: this.NewEventForm.value.EventType.Id,
                        eventDate: FrontendDate(this.NewEventForm.value.EventDate?.toDate()),
                        eventEndDate: FrontendDate(eventEndDate),
                        description: this.NewEventForm.value.Note || '',
                        private: this.NewEventForm.value.Private,
                        name: name,
                        processId: this.NewEventForm.value.Process?.Id || null,
                    },
                }),
            );
        });
    }

    onEventCreatedSucc(eventId: number) {
        this.NewEventForm.markAsPristine()
        this.router.navigate(['/chronik/alle', eventId]);
    }
    public NewContactPerson() {
        // const customerId: number = +this.Customer.Id;
        // this.disableClearStore = true;
        // this.router.navigate(['/kunden', customerId, 'neuerAnsprechpartner']);
        if (this.NewEventForm.value.Customer) {
            this.sp.OpenPersonDialog(null, this.NewEventForm.value.Customer);
        }
    }

    public OnEventTypeChange(eventType: EventTypeEntity) {
        this.EventTypeForm.setValue(eventType);
    }
    get EventTypeForm() {
        return this.NewEventForm.get('EventType');
    }

    get PersonForm() {
        return this.NewEventForm.get('Person');
    }
    get PrivateForm() {
        return this.NewEventForm.get('Private');
    }

    get EventDateForm() {
        return this.NewEventForm.get('EventDate');
    }

    get EventEndDateForm() {
        return this.NewEventForm.get('EventEndDate');
    }

    get EventStartTimeStringForm() {
        return this.NewEventForm.get('EventStartTimeString');
    }

    get EventEndTimeStringForm() {
        return this.NewEventForm.get('EventEndTimeString');
    }

    get NoteForm() {
        return this.NewEventForm.get('Note');
    }
    get CommissionForm() {
        return this.NewEventForm.get('CommissionId');
    }
    get CustomerForm() {
        return this.NewEventForm.get('Customer');
    }
    get NameForm() {
        return this.NewEventForm.get('Name');
    }

    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): Observable<boolean> | boolean {
        return !this.NewEventForm.dirty;
    }
    protected compareById(a,b) {
        return a.Id === b.Id;
    }

    protected readonly sameDay = sameDay;
}
