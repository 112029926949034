import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { ClockInTypeEntity } from '../../entities/clock-in-type.entity';
import { ClockInTypeActionTypes } from '../actions/clock-in-type.actions';

export const clockInTypesFeatureKey = 'clockInTypes';

export interface ClockInTypesState extends EntityState<ClockInTypeEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}
const adapter = createEntityAdapter<ClockInTypeEntity>({
    selectId: Entity => Entity.Id,
});

const initialConditionsState: ClockInTypesState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const clockInTypesStateReducer = createReducer(
    initialConditionsState,
    on(ClockInTypeActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) => {
        console.log({Payload})
        return adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt })
        },
    ),
);


/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllClockInType = selectAll;
export const selectClockInTypeEntities = selectEntities;

export const selectClockInTypeFetched = (state: ClockInTypesState) => state.fetched;
export const selectClockInTypeLatestUpdatedAt = (state: ClockInTypesState) => state.latestUpdatedAt;
