import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    JOB_SPECIFICATION_KEY,
    JobSpecificationState, selectAllJobSpecification,
    selectjobSpecificationEntities, selectJobSpecificationFetched,
} from '../reducers/job-specification.reducer';


const selectState = createFeatureSelector<JobSpecificationState>(JOB_SPECIFICATION_KEY);
const getDictionary = createSelector(selectState, selectjobSpecificationEntities);

export const getJobSpecificationDictionary = getDictionary;
export const getJobSpecification = createSelector(selectState, selectAllJobSpecification);
export const getJobSpecificationFetched = createSelector(selectState, selectJobSpecificationFetched);
export const getJobSpecificationById = (props: { id: number }) => createSelector(getDictionary, (dictionary) => props?.id && dictionary[props.id]);
