import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DoughnutChartComponent } from '../doughnut-chart/doughnut-chart.component';
export interface DoughnutChartWrapperComponentDialogData {
    Options: any;
    Datasets: any;
    CenterTemplate: any;
    Labels: any;
    Headline?: string;
    Subline?: string;
    HideLegend?: boolean;
}
@Component({
    selector: 'app-doughnut-chart-wrapper',
    templateUrl: './doughnut-chart-wrapper.component.html',
    styleUrls: ['./doughnut-chart-wrapper.component.scss'],
})
export class DoughnutChartWrapperComponent implements OnInit {
    public static DefaultConfig: MatDialogConfig = {
        closeOnNavigation: true,
    };
    @ViewChild('chart') DoughnutChartComponent: DoughnutChartComponent;

    @Input() Options: any;
    @Input() Datasets: any;
    @Input() CenterTemplate: any;
    @Input() Labels: any;
    @Input() Headline: string;
    @Input() Subline: string;
    @Input() HideLegend: boolean;
    constructor( @Optional() @Inject(MAT_DIALOG_DATA) public DialogData: DoughnutChartWrapperComponentDialogData, private dialog: MatDialog) {}

    ngOnInit(): void {
        if (this.DialogData) {

            this.Options = this.DialogData.Options;
            this.Datasets = this.DialogData.Datasets;
            this.CenterTemplate = this.DialogData.CenterTemplate;
            this.Labels = this.DialogData.Labels;
            this.Headline = this.DialogData.Headline;
            this.Subline = this.DialogData.Subline;
            this.HideLegend = this.DialogData.HideLegend;
        }
    }
    public OpenMeInPopUp() {
        if (this.DialogData) {
            // is already in a dialog
            return
        }
        const dialogRef = this.dialog.open<DoughnutChartWrapperComponent, DoughnutChartWrapperComponentDialogData>(DoughnutChartWrapperComponent, {
            ...DoughnutChartWrapperComponent.DefaultConfig,
            data: {
                Datasets: this.Datasets,
                CenterTemplate: this.CenterTemplate,
                Labels: this.Labels,
                Options: this.Options,
                Headline: this.Headline,
                Subline: this.Subline,
                HideLegend: false,
            }
        });
    }
}
