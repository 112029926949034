import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { emailFeatureKey, EmailState, selectEmailEntities } from '../reducers/email.reducer';

const selectState = createFeatureSelector<EmailState>(emailFeatureKey);
export const getEmailDictionary = createSelector(selectState, selectEmailEntities);

export const selectEmails = createFeatureSelector<EmailState>(emailFeatureKey);

export const getEmailsByIds = (props: { Ids: number[] }) =>
    createSelector(getEmailDictionary, (emailEntities) => props.Ids.map((id) => emailEntities[id]).filter(isNotNullOrUndefined));

export const getEmailById = (props: { id: number }) => createSelector(getEmailDictionary, (dictionary) => props?.id && dictionary[props.id]);
