import { Component,Inject } from '@angular/core';
import { MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LedgerImportEntity } from 'src/app/dave-data-module/entities/ledger-import.entity';
import { LedgerImportResolver } from '../../../dave-data-module/guards/ledger-import.resolver';
import { State } from '../../../dave-data-module/State';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { CommissionActionTypes } from '../../../dave-data-module/State/actions/commission.actions';
import { getLedgerImports } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { appMatDialogDefaultConfig } from '../../../helper/helper';
import { LoadingService } from '../../../services/loading.service';
import {
    PermissionService
} from "../../../dave-utils-module/dave-shared-components-module/services/permission.service";
import { AppDialogService } from "../../../dave-utils-module/app-dialog-module/app-dialog.service";
import { ResourceScheduleResolver } from "../../../dave-data-module/guards/resource-dispo/resource-scheduler.resolver";

export interface SelectResourcePopupComponentDialogData {
    CommissionId: number;
    startDate?: Date;
    endDate?: Date;
}

@Component({
    selector: 'app-select-resource-popup',
    templateUrl: './select-resource-popup.component.html',
    styleUrls: ['./select-resource-popup.component.scss'],
})
export class SelectResourcePopupComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        minWidth: '90vw',
        maxHeight: 'calc(100vh - 3.5rem)',
        closeOnNavigation: true,
    };
    protected LedgerImports$: Observable<LedgerImportEntity[]>;
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: SelectResourcePopupComponentDialogData,
        private store: Store<State>,
        protected LS: LoadingService,
        private toastrService: ToastrService,
        private resourceScheduleResolver: ResourceScheduleResolver,
        private actions$: Actions,
        public PS: PermissionService,
        private appDialog: AppDialogService,
    ) {
       // this.LedgerImports$ = this.store.select(getLedgerImports).pipe(map((lis) => lis.filter((l) => l.CommissionId === this.Dialogdata.CommissionId)));
    }
    // onAddToResToCommissionClick(ledgerImportId: number) {
    //     if (this.Dialogdata.CommissionId) {
    //         this.LS.startLoading('modify-commission');
    //         firstValueFrom(this.actions$.pipe(ofType(BaseActionTypes.ErrorAction, CommissionActionTypes.AddResourcesSuccess))).then((action) => {
    //             this.LS.endLoading('modify-commission');
    //             if (action.type === CommissionActionTypes.AddResourcesSuccess.type) {
    //                 this.toastrService.success('Ressourcen erfolgreich angelegt');
    //                 this.resourceScheduleResolver.pollUpdated();
    //             }
    //         });
    //         this.store.dispatch(CommissionActionTypes.AddResources({ Payload: { ledgerImportId, commissionId: this.Dialogdata.CommissionId } }));
    //     }
    // }

    // onLedgerImportClick(event: {entry: LedgerImportEntity; index: number; element: HTMLElement}) {
    //     console.log(event);
    //     this.appDialog.OpenConfirmationDialog({paragraph: event.entry.AccountsReceivableLedgerIds?.length ? 'Möchten Sie die Buchungspositionen als Ressourcen hinzufügen ?' : 'keine Buchungspositionen vorhanden'}).subscribe(([res]) => {
    //         if (res && event.entry.AccountsReceivableLedgerIds?.length) {
    //             this.onAddToResToCommissionClick(event.entry.Id)
    //         }
    //     })
    // }
}
