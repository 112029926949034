import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { DaveActions } from "../actions/actions";
import { HttpService } from "../../services/http.service";
import { BaseActionTypes } from "../actions/base.actions";
import {
    catchError,
    withLatestFrom,
    switchMap,
    map
} from "rxjs/operators";
import { combineLatest, EMPTY, of } from 'rxjs';
import { State } from "../index";
import { Store } from "@ngrx/store";
import { SharedCommissionsEntityFromBackend } from "../../entities/sharedCommissions.entity";
import { SharedCommissionsActionTypes } from "../actions/sharedCommissions.actions";
import {
    stringifyIfNotNullOrUndefined
} from "../../../helper/helper";
import { getSharedCommissions } from "../selectors/sharedCommissions.selectors";

enum ErrorCodes {
    Load = "Zuteilung Abrufen fehlgeschlagen",
    Modify = "Zuteilung Bearbeiten fehlgeschlagen",
    Add = "Zuteilung Hinzufügen fehlgeschlagen",
    Delete = "Zuteilung Löschen fehlgeschlagen"
}

@Injectable()
export class SharedCommissionsEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService
    ) {}


    LoadSharedCommissions = createEffect(() => this.actions$.pipe(
        ofType(SharedCommissionsActionTypes.GetSharedCommissionsRequest),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
query{
  sharedCommissions{
        partnerLinkedId
        commissionId
        id
        partnerId
  }
}`;
            return this.gatewayHttpService
                .graphQl({ query: queryString }, { token: store.base.token, retry: true })
                .pipe(
                    map(res =>
                        res && res.sharedCommissions
                            ? SharedCommissionsActionTypes.UpdateSharedCommissions(
                                  {
                                      Payload: res.sharedCommissions.map(val =>
                                          SharedCommissionsEntityFromBackend(
                                              val
                                          )
                                      )
                                  }
                              )
                            : BaseActionTypes.ErrorAction({
                                  Payload: { ToasterMessage: ErrorCodes.Load }
                              })
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught
                                }
                            })
                        )
                    )
                );
        })
    ));
    //ToDO Backend umbauen um Arrays anlegen zu können

    AddSharedCommissionss = createEffect(() => this.actions$.pipe(
        ofType(SharedCommissionsActionTypes.AddSharedCommissions),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const retObservables = [];
            action.Payload.forEach(payload => {
                const queryString = `
            mutation{
              createSharedCommissions(
                    ${stringifyIfNotNullOrUndefined(payload, "partnerLinkedId")}
                    ${stringifyIfNotNullOrUndefined(payload, "commissionId")}
                ){
                  commissionId
                  id
                  partnerId
                  partnerLinkedId
              }
            }`;
                retObservables.push(
                    this.gatewayHttpService
                        .graphQl({ query: queryString }, { token: store.base.token })
                        .pipe(
                            map(res => {
                                    if (res && res.createSharedCommissions) {
                                        this.store$.dispatch(SharedCommissionsActionTypes.GetSharedCommissionsRequest());
                                    } else {
                                        this.store$.dispatch(BaseActionTypes.ErrorAction({
                                            Payload: { ToasterMessage: ErrorCodes.Add }
                                        }));
                                    }
                                }
                            ),
                            catchError((err, caught) => {
                                    this.store$.dispatch(BaseActionTypes.ErrorAction({
                                            Payload: {
                                                ToasterMessage: ErrorCodes.Delete,
                                                Err: err,
                                                Caught: caught,
                                            }
                                        })
                                    );
                                    return EMPTY;
                                }
                            )
                        ));
            });
            return combineLatest(retObservables);
        })
    ), {dispatch: false});
    //ToDO Backend umbauen um Arrays löschen zu können

    RemoveSharedCommissionss = createEffect(() => this.actions$.pipe(
        ofType(SharedCommissionsActionTypes.DeleteSharedCommissions),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const retObservables = [];
            action.Payload.forEach(payload => {
                const queryString = `
                mutation{
                  deleteSharedCommissions(
                    ${stringifyIfNotNullOrUndefined(payload, "partnerLinkedId")}
                    ${stringifyIfNotNullOrUndefined(payload, "commissionId")}
                  )
                }`;
                retObservables.push(
                    this.gatewayHttpService
                        .graphQl({ query: queryString }, { token: store.base.token })
                        .pipe(
                            withLatestFrom(this.store$.select(getSharedCommissions)),
                            map(([res, sharedCommissions]) => {
                                if (res && res.deleteSharedCommissions) {
                                    this.store$.dispatch(SharedCommissionsActionTypes.GetSharedCommissionsRequest());
                                } else {
                                    this.store$.dispatch( BaseActionTypes.ErrorAction({
                                        Payload: { ToasterMessage: ErrorCodes.Delete }
                                    }));
                                }
                            }),
                            catchError((err, caught) => {
                                this.store$.dispatch(BaseActionTypes.ErrorAction({
                                        Payload: {
                                            ToasterMessage: ErrorCodes.Delete,
                                            Err: err,
                                            Caught: caught,
                                        }
                                    })
                                );
                                return EMPTY;
                                }
                            )
                        )
                );
            });

            return combineLatest(retObservables);
        })
    ), {dispatch: false});
}
