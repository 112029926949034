import { DocumentUserType } from '../graphql-types';
import { DocumentUser } from "@dave/types";

export class DocumentUserEntity {
    public static readonly GqlFields = 'document_id edit from_folder from_id from_type role_id user_id';
    public Inherited = false;

    constructor(
        public DocumentId: number = null,
        public UserId: number = null,
        public FromFolder: number = null,
        public FromType: string = null,
        public FromId: number = null,
        public RoleId: number = null,
        public Edit: boolean = null,
    ) {
        if (FromFolder !== 0 || FromType !== '' || FromId !== 0) {
            this.Inherited = true;
        }
    }

    public Clone(override: DocumentUserEntityAttributes = {}): DocumentUserEntity {
        const attr = {...this, ...override};
        return Object.assign(new DocumentUserEntity(), attr);
    }
}

export function DocumentUserEntityGateway(res: DocumentUser): DocumentUserEntity {
    return new DocumentUserEntity(
        res.DocumentId && +res.DocumentId,
        res.UserId && +res.UserId,
        res.FromFolder && +res.FromFolder,
        res.FromType,
        res.FromId && +res.FromId,
        res.RoleId && +res.RoleId,
        res.Edit,
    );
}
export function DocumentUserEntityFromBackend(res: DocumentUserType): DocumentUserEntity {
    return new DocumentUserEntity(
        res.document_id,
        res.user_id,
        res.from_folder,
        res.from_type,
        res.from_id,
        res.role_id,
        res.edit,
    );
}

export interface DocumentUserEntityAttributes {
    DocumentId?: number;
    UserId?: number;
    FromFolder?: number;
    FromType?: string;
    FromId?: number;
    RoleId?: number;
    Edit?: boolean;
}
