import { User2EventType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
export interface UserToEventEntityAdditionalData {
    timespan: string;
}
export class UserToEventEntity {
    constructor(
        public AdditionalData: UserToEventEntityAdditionalData = null,
        public EventId: number = null,
        public Id: number = null,
        public UserId: number = null,
        public UpdatedAt: Date = null,
    ) {}
    public static readonly GqlFields = `additionalData
eventId
userId
id
updatedAt`;
    public Clone(override: UserToEventAttributes = {}): UserToEventEntity {
        const attr = { ...this, ...override };
        return Object.assign(new UserToEventEntity(), attr);
    }
}

export function UserToEventEntityFromBackend(res: User2EventType): UserToEventEntity {
    return new UserToEventEntity(res.additionalData ? JSON.parse(res.additionalData) : null, res.eventId, res.id, res.userId, BackendDate(res.updatedAt));
}

export interface UserToEventAttributes {
    AdditionalData?: UserToEventEntityAdditionalData;
    EventId?: number;
    Id?: number;
    UserId?: number;
    UpdatedAt?: Date;
}
