
import { createAction, props } from '@ngrx/store';
import { GeneratedDocumentsTypeEntity } from '../../entities/generatedDocumentsType.entity';


export const GeneratedDocumentsTypeActionTypes = {
    UpdateGeneratedDocumentsType: createAction(
        'data/GeneratedDocumentsType/updateAll',
        props<{ Payload: Array<GeneratedDocumentsTypeEntity> }>(),
    ),
    UpdateGeneratedDocumentType: createAction(
        'data/GeneratedDocumentsType/updateOne',
        props<{ Payload: GeneratedDocumentsTypeEntity }>(),
    ),
    GetGeneratedDocumentsTypeRequest: createAction(
        'data/GeneratedDocumentsType/getAll',
    ),
    GetByIdRequest: createAction(
        'data/GeneratedDocumentsType/getById',
        props<{ id: number, withFile?: boolean}>(),
    ),
};

