import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy,Component,EventEmitter,Input,OnDestroy,Output } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterLink } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Store } from '@ngrx/store';
import { BehaviorSubject,combineLatest,Observable,Subject,Subscription } from 'rxjs';
import { filter,map,tap } from 'rxjs/operators';
import { EventTypeEntity } from '../../../dave-data-module/entities/event-type.entity';
import { EventEntity } from '../../../dave-data-module/entities/event.entity';
import { FileEntity } from '../../../dave-data-module/entities/file.entity';
import { TagEntity } from '../../../dave-data-module/entities/tag.entity';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { FilesActionTypes } from '../../../dave-data-module/State/actions/files.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getTags } from '../../../dave-data-module/State/selectors/tag.selectors';
import { DaveLoadingModule } from "../../../dave-loading/dave-loading.module";
import { AppButtonModule } from "../../../dave-utils-module/app-button-module/app-button.module";
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { CustomLabelService } from "../../../services/custom-label.service";
import { IconPipeModule } from "../../../dave-utils-module/icon-pipe/icon-pipe.module";
import { FileLinkIcon } from "../../../helper/page-metadata";

@Component({
    selector: 'app-file-card',
    templateUrl: './file-card.component.html',
    styleUrls: ['./file-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, AppButtonModule, MatCardModule, MatButtonModule, DaveLoadingModule, MatChipsModule, MatTooltipModule, RouterLink, IconPipeModule],
})
export class FileCardComponent implements OnDestroy {
    public FileIntern$ = new BehaviorSubject<FileEntity>(null);
    @Input() Highlight = false;
    @Input() Selected = false;
    @Input() SelectMode = false;
    @Input() DeleteButton = false;
    @Input() InsertButton = false;
    @Input() InfoButton = false;
    @Input() EventData: { event: EventEntity; eventType: EventTypeEntity };
    @Output() InsertButtonClicked = new EventEmitter<FileEntity>();
    @Output() InfoButtonClicked = new EventEmitter<number>();
    @Output() Clicked = new EventEmitter<FileEntity>();
    public FileTags$: Observable<TagEntity[]>;
    public Delete$ = new Subject<FileEntity>();
    public ShowTimelineItem = new Subject();
    public TimelineItemLeft: number;
    public TimelineItemTop: number;
    public ImageLoaded = false;
    public ImageBroken = false;
    public Thumbnaillink$ = combineLatest([this.store.select(getToken), this.FileIntern$.pipe(filter(isNotNullOrUndefined))]).pipe(
        map(([token, file]) => file.GetLastVersion().HasThumbnail ? this.Api.GetUrl(file.GetLastVersion().GetThumbnailLink(token), 'file') : null),
    );
    public Downloadlink$ = combineLatest([this.store.select(getToken), this.FileIntern$.pipe(filter(isNotNullOrUndefined))]).pipe(map(([token, file]) => this.Api.GetUrl(file.GetLastVersion().GetDownloadLink(token), 'file')));
    private subscriptions: Subscription[] = [];
    private openEventCard$ = new Subject<EventEntity>();

    constructor(private appDialog: AppDialogService, private store: Store<State>, public Api: HttpService, protected cls: CustomLabelService) {
        this.subscriptions.push(
            this.Delete$.pipe(
                tap((file) => {
                    this.appDialog
                        .OpenConfirmationDialog({
                            paragraph: `Die Datei ${file.Name} wirklich löschen?`,
                        })
                        .subscribe(([result]) => {
                            if (result) {
                                this.store.dispatch(
                                    FilesActionTypes.DeleteFileRequest({
                                        Payload: { DocumentId: file.Id.toString() },
                                    }),
                                );
                            }
                        });
                }),
            ).subscribe(),
            this.openEventCard$
                .pipe(
                    tap((event) => {
                        console.log(event);
                    }),
                )
                .subscribe(),
        );
        this.FileTags$ = combineLatest([this.store.select(getTags), this.FileIntern$.pipe(filter(isNotNullOrUndefined))]).pipe(map(([tags, file]) => tags?.filter((t) => file.TagIds.includes(t.Id))));
    }

    @Input()
    set File(f: FileEntity) {
        this.FileIntern$.next(f);
    }

    public DeleteButtonClick(file: FileEntity, $event: MouseEvent) {
        $event.stopPropagation();
        this.Delete$.next(file);
    }

    public OpenEventCard(event: EventEntity, $event: MouseEvent, div: any) {
        $event.stopPropagation();
        this.TimelineItemLeft = $event.offsetX + div.offsetLeft;
        this.TimelineItemTop = $event.offsetY + div.offsetTop;
        this.ShowTimelineItem.next(true);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    protected readonly FileLinkIcon = FileLinkIcon;
}
