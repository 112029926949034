import { CommonModule, formatDate } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, HostListener,
    Inject,
    Input,
    OnDestroy,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BusinessVolumeActionTypes } from 'src/app/dave-data-module/State/actions/business-volume.actions';
import { BVCustomNameEntity } from '../../dave-data-module/entities/b-v-custom-name.entity';
import { AvailableBusinessVolumeStatusByType, BusinessVolumeCostStateEnum, BusinessVolumeStatusEnum, BusinessVolumeStatusEnumNameMap } from '../../dave-data-module/entities/business-volume.entity';
import { FileEntity, NEW_DOCUMENT_DEFAULT_NAME } from '../../dave-data-module/entities/file.entity';
import { AccountTypeEnum, ChartOfAccountEntity } from '../../dave-data-module/entities/chart-of-Account.entity';
import { FolderTypes } from '../../dave-data-module/entities/folder.entity';
import { BVCustomNameResolver } from '../../dave-data-module/guards/b-v-custom-name.resolver';
import { ChartOfAccountResolver } from '../../dave-data-module/guards/chartOfAccount.resolver';
import { CommissionResolver } from '../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../dave-data-module/guards/customer.resolver';
import { StatusFromBackofficeResolver } from '../../dave-data-module/guards/statusFromBackoffice.resolver';
import { getFetched$ } from '../../dave-data-module/helper/helper';
import { FolderDataService } from '../../dave-data-module/services/folder-data.service';
import { State } from '../../dave-data-module/State';
import { BaseActionTypes } from '../../dave-data-module/State/actions/base.actions';
import { FilesActionTypes } from '../../dave-data-module/State/actions/files.actions';
import { BVCustomNameActions } from '../../dave-data-module/State/actions/resource-dispo/b-v-custom-names.actions';
import { getBVCustomNameDictionary,getBVCustomNameFetched,getBVCustomNames } from '../../dave-data-module/State/selectors/b-v-custom-name.selector';
import { getBusinessVolumeById } from '../../dave-data-module/State/selectors/business-volume.selector';
import {
    getChartOfAccountDictionary,
    getChartOfAccounts,
    getChartOfAccountsFetched,
} from '../../dave-data-module/State/selectors/chartOfAccount.selectors';
import {
getCommissionDictionary,
getCommissionsActive,
getCommissionsFetched
} from '../../dave-data-module/State/selectors/commission.selector';
import {
    getCustomerDictionary,
getCustomersFetched,
getNotDeletedCustomers
} from '../../dave-data-module/State/selectors/customers.selectors';

import { getStatusFromBackofficeFetched } from '../../dave-data-module/State/selectors/statusFromBackoffice.selectors';
import { SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData } from '../../dave-file-explorer/components/select-folder-dialog/select-folder-dialog.component';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData, DaveSelectFileFromDmsComponentReturnData } from '../../dave-select-file-from-dms/components/dave-select-file-from-dms/dave-select-file-from-dms.component';
import { AppButtonModule } from '../../dave-utils-module/app-button-module/app-button.module';
import {
IDetailListTemplateData,
IDetailListTemplateDataProperty
} from '../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { DaveSharedComponentsModule } from '../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { PermissionService } from '../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { SelectSearchOption } from '../../dave-utils-module/select-search/components/select-search/select-search.component';
import {
    appMatDialogDefaultConfig, DEFAULT_TAX,
    isNotNullOrUndefined,
    MathRound,
    stringSearch,
    uniqArray,
} from '../../helper/helper';
import { AllReportsMeta, InvoiceEditorMeta, ReportsPageMeta } from '../../helper/page-metadata';
import { bvStatusByBvTypedValidator, decimalValidator } from '../../helper/validation.helper';
import { JobSpecificationOptionComponent } from '../../job-specification-option/job-specification-option.component';
import { DocumentListCardComponent } from '../../process/components/document-list-card/document-list-card.component';
import { LoadingService } from '../../services/loading.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectSearchModule } from '../../dave-utils-module/select-search/select-search.module';
import { DetailListTemplateDialogModule } from '../detail-list-template-dialog.module';
import { MatInputModule } from '@angular/material/input';
import {
    DetailListDialogReturn,
    DetailListTemplateDialogComponent,
    DetailListTemplateDialogData,
} from '../components/detail-list-template-dialog.component';
import { ChartOfAccountActionTypes } from '../../dave-data-module/State/actions/chartOfAccount.actions';
import { SortFilesDialogComponent, SortFilesDialogComponentDialogData } from '../../sort-files-dialog/sort-files-dialog.component';
import { DetectionResultDataInvoice } from '@dave/types';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, reduce, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FrontendDate } from '../../dave-data-module/helper/backend-frontend-conversion.helper';
import { getLedgerImportById } from '../../dave-data-module/State/selectors/ledger-import.selector';
import { CustomerEntity } from '../../dave-data-module/entities/customer.entity';
import { DaveMutationCreateBusinessVolumeArgs } from '../../dave-data-module/graphql-types';
import { FileDataService } from '../../dave-data-module/services/file-data.service';
import { MatButtonModule } from '@angular/material/button';
import { CanDeactivate } from '@angular/router';
import {
    ComponentCanDeactivate,
    PENDING_CHANGES_DEFAULT_MESSAGE,
} from '../../dave-data-module/guards/pending-changes.guard';

export const getDefaultValuesFromDocumentDetection = (doc: DetectionResultDataInvoice, customers: CustomerEntity[]): Partial<DaveMutationCreateBusinessVolumeArgs> => {
    const ret: Partial<DaveMutationCreateBusinessVolumeArgs> = {
        businessVolumeNo: doc.InvoiceNo,
        amount: doc.SubTotal ? doc.SubTotal * 100 : null,
        name: [doc.FromName, doc.InvoiceNo].filter((v) => !!v).join(' '),
        taxValue: doc.TaxTotal,
        consolidatedDate: doc.InvoiceDate,
    };
    if (doc.FromName?.length > 2 || doc.CustomerNo?.length > 2) {
        const customer = customers.find((c) => (doc.CustomerNo?.length > 2 && c.CustomerNo && stringSearch(c.CustomerNo, doc.CustomerNo)) || (doc.FromName?.length > 2 && c.Name && stringSearch(c.Name, doc.FromName)));
        if (customer) {
            ret.customerId = customer.Id;
        }
    }
    return ret;
};
export interface BusinessVolumeDialogComponentDialogData {
    BvId?: number;
    ShowCommissionForm?: boolean;
    ShowCustomerForm?: boolean;
    AddBusinessVolumeDefaultValues?: Partial<DaveMutationCreateBusinessVolumeArgs>;
    disableUploadFiles?: boolean;
    disableFileClick?: boolean;
}
@Component({
    selector: 'app-business-volume-dialog',
    templateUrl: './business-volume-dialog.component.html',
    styleUrls: ['./business-volume-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        AppButtonModule,
        FontAwesomeModule,
        MatMenuModule,
        MatTooltipModule,
        DaveSharedComponentsModule,
        MatDialogModule,
        DocumentListCardComponent,
        MatFormFieldModule,
        SelectSearchModule,
        ReactiveFormsModule,
        JobSpecificationOptionComponent,
        DetailListTemplateDialogModule,
        MatInputModule,
        MatButtonModule,
    ],
})
export class BusinessVolumeDialogComponent implements AfterViewInit, OnDestroy, ComponentCanDeactivate {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        width: '38rem',
        maxWidth: '100vw',
        disableClose: true,
    };
    @ViewChild('skontoTemplate') skontoTemplateRef: TemplateRef<any>
    protected ledgerImportPath = '';
    public bvForm$: Promise<IDetailListTemplateData>;
    public chartOfAccounts: Array<ChartOfAccountEntity & { optionLabel: string }>;
    public form = new FormGroup({
        customerId: new FormControl<{ Id: number; optionLabel: string }>(null),
        commissionId: new FormControl<{ Id: number; optionLabel: string }>(null),
        name: new FormControl<string>(null),
        description: new FormControl<string>(null),
        amount: new FormControl<number>(null),
        payedAmount: new FormControl<number>(null),
        customNameIds: new FormControl<BVCustomNameEntity[]>(null),
        bvType: new FormControl<BusinessVolumeCostStateEnum>(null),
        status: new FormControl<SelectSearchOption<{ optionValue: BusinessVolumeStatusEnum }>>(null, [bvStatusByBvTypedValidator(() => this.form?.controls?.bvType?.value)]),
        number: new FormControl<string>(null),
        payedTaxValue: new FormControl<number>(null),
        documentIds: new FormControl<number[]>(null),
        consolidatedDate: new FormControl<Moment>(null),
        consolidatedDeliveryDate: new FormControl<Moment>(null),
        taxValue: new FormControl<number>(null),
        taxPercentageValue: new FormControl<number>(DEFAULT_TAX),
        discountValue: new FormControl<number>(null),
        gegenkonto: new FormControl<SelectSearchOption<ChartOfAccountEntity> | null>(null),
        exportDate: new FormControl<Moment>(null),
        exportTimespan: new FormControl<string>(null),
    });
    public headline = '';
    private filePreviewDialog: MatDialogRef<DaveFilePreviewComponent>;
    protected bvId$ = new BehaviorSubject<number | null>(null);

    @Input() set BvId(id: number | null) {
        this.headline = id ? 'Umsatz bearbeiten' : 'Umsatz Anlegen';
        this.bvId$.next(id);
    }
    get BvId() {
        return this.bvId$.value;
    }
    @Input() ShowCommissionForm?: boolean;
    @Input() ShowCustomerForm?: boolean;
    @Input() AddBusinessVolumeDefaultValues: Partial<DaveMutationCreateBusinessVolumeArgs>;
    protected disableUploadFiles$ = new BehaviorSubject(false);
    @Input() set disableUploadFiles(v: boolean) {
        this.disableUploadFiles$.next(v);
    }
    showDeleteButton = false;
    ledgerImportId: number;
    protected hasEditPermission$ = this.bvId$.pipe(switchMap((id) => this.ps.Has$(id ? this.ps.Permissions.ChangeBusinessVolume : this.ps.Permissions.CreateBusinessVolume)));
    protected hasDeletePermission$ = this.ps.Has$(this.ps.Permissions.DeleteBusinessVolume);
    protected canUploadFiles$ = combineLatest([this.disableUploadFiles$.pipe(map((v) => !v)), this.hasEditPermission$]).pipe(map((permissions) => permissions.every((p) => p)));
    private subs: Subscription[] = [];
    @ViewChild('chartOfAccountTemplate') chartOfAccountTemplate: TemplateRef<any>;
    @ViewChild('sumTemplate') sumTemplate: TemplateRef<any>;
    @ViewChild('payedSumTemplate') payedSumTemplate: TemplateRef<any>;


    public CompareSelectSearchChartOfAccountsOptions = (a: ChartOfAccountEntity & SelectSearchOption, b: ChartOfAccountEntity & SelectSearchOption) => a.Id === b.Id;

    public SelectSearchChartOfAccountsOptionsFunction = (search: string, option: ChartOfAccountEntity) => [option.Name, option.Number.toString()].some((v) => v && stringSearch(v, search));


    constructor(
        @Inject(MAT_DIALOG_DATA) public DialogData: BusinessVolumeDialogComponentDialogData,
        private dialogRef: MatDialogRef<BusinessVolumeDialogComponent>,
        private store: Store<State>,
        private actions$: Actions,
        protected customerResolver: CustomerResolver,
        protected commissionResolver: CommissionResolver,
        protected bVCustomNameResolver: BVCustomNameResolver,
        protected statusFromBackofficeResolver: StatusFromBackofficeResolver,
        protected dialog: MatDialog,
        protected ls: LoadingService,
        private ps: PermissionService,
        protected chartOfAccountResolver: ChartOfAccountResolver,
        private cdr: ChangeDetectorRef,
        private folderDataService: FolderDataService,
        private fileDataService: FileDataService,
    ) {
        if (this.dialogRef) {
            this.subs.push(this.dialogRef.backdropClick().subscribe(() => {
                this.closeDialog();
            }))
        }

        firstValueFrom(this.store.select(getChartOfAccountsFetched)).then((fetched) => {
            if (!fetched) {
                chartOfAccountResolver.resolve();
            }
        });

        if (DialogData) {
            this.BvId = DialogData.BvId;
            this.ShowCommissionForm = DialogData.ShowCommissionForm;
            this.ShowCustomerForm = DialogData.ShowCustomerForm;
            this.AddBusinessVolumeDefaultValues = DialogData.AddBusinessVolumeDefaultValues || {};
            if (isNotNullOrUndefined(DialogData.disableUploadFiles)) {
                this.disableUploadFiles = DialogData.disableUploadFiles;
            }
        }
        this.subs.push(
            this.hasEditPermission$.subscribe((has) => {
                if (!has && this.form.enabled) {
                    this.form.disable();
                } else if (has && this.form.disabled) {
                    this.form.enable();
                }
            }),
        );

        this.subs.push(
            this.store.select(getChartOfAccountsFetched).pipe(
                filter((f) => f),
                switchMap(() => this.store.select(getChartOfAccounts).pipe(map(ca=> ca.filter(p=> p.AccountType === AccountTypeEnum.Contra &&  !p.DeletedAt)))),
            ).subscribe((chartAcc) =>
                this.chartOfAccounts = chartAcc.map((r) => Object.assign(r.Clone()))),
        );
    }

    ngAfterViewInit(): void {
        this.bvForm$ = firstValueFrom(
            combineLatest([
                this.BvId ? this.store.select(getBusinessVolumeById({ id: this.BvId })) : of(null),
                getFetched$(this.store, getCustomersFetched, getNotDeletedCustomers, this.customerResolver),
                getFetched$(this.store, getCustomersFetched, getCustomerDictionary),
                getFetched$(this.store, getStatusFromBackofficeFetched, getStatusFromBackofficeFetched, this.statusFromBackofficeResolver).pipe(
                    switchMap(() => getFetched$(this.store, getCommissionsFetched, getCommissionsActive)),
                ),
                getFetched$(this.store, getCommissionsFetched, getCommissionDictionary, this.commissionResolver),
                getFetched$(this.store, getBVCustomNameFetched, getBVCustomNameDictionary, this.bVCustomNameResolver),
                getFetched$(this.store, getChartOfAccountsFetched, getChartOfAccountDictionary, this.chartOfAccountResolver)
            ]),
        ).then(([bv, ce, customerDict, activeCommissions, allCommissions, bvcns , chartDic ]) => {
            const commission = bv?.CommissionId ? allCommissions[bv.CommissionId] : this.AddBusinessVolumeDefaultValues.commissionId ? allCommissions[this.AddBusinessVolumeDefaultValues.commissionId] : null;
            const commissionId = commission ? { Id: commission.Id, optionLabel: commission.DisplayName } : null;

            const customer = bv?.CustomerId ? customerDict[bv.CustomerId] : this.AddBusinessVolumeDefaultValues?.customerId ? customerDict[this.AddBusinessVolumeDefaultValues?.customerId] : null;
            const customerId = customer ? { Id: customer.Id, optionLabel: customer.DisplayName } : null;
            const chartOfAccount =
                bv ? (bv.SachkontoCOAId ? this.chartOfAccounts.find(charts=> charts.Id === bv.SachkontoCOAId) : null ): null;



            this.form.reset({
                customerId,
                commissionId,
                name: bv ? bv.Name : this.AddBusinessVolumeDefaultValues?.name || null,
                description: bv ? bv.Description : this.AddBusinessVolumeDefaultValues?.description || null,
                amount: bv ? (bv.Amount ? bv.Amount / 100 : null) : isNotNullOrUndefined(this.AddBusinessVolumeDefaultValues?.amount) ? this.AddBusinessVolumeDefaultValues.amount / 100 : null,
                payedAmount: bv ? (bv.PayedAmount ? bv.PayedAmount / 100 : null) : isNotNullOrUndefined(this.AddBusinessVolumeDefaultValues?.payedAmount) ? this.AddBusinessVolumeDefaultValues.payedAmount / 100 : null,
                customNameIds: bv ? bv.BVCustomNamesIds.map((id) => bvcns[id]) : this.AddBusinessVolumeDefaultValues?.bVCustomNamesIds?.map((id) => bvcns[id]) || [],
                bvType: bv ? bv.BVType : this.AddBusinessVolumeDefaultValues?.bVType || BusinessVolumeCostStateEnum.Costs,
                status: bv
                    ? { optionValue: bv.Status, optionLabel: '' }
                    : this.AddBusinessVolumeDefaultValues?.status
                        ? { optionValue: this.AddBusinessVolumeDefaultValues.status, optionLabel: '' }
                        : { optionValue: BusinessVolumeStatusEnum.Open, optionLabel: '' },
                number: bv ? bv.BusinessVolumeNo : this.AddBusinessVolumeDefaultValues?.businessVolumeNo || null,
                documentIds: bv ? bv.DocumentIds || [] : this.AddBusinessVolumeDefaultValues?.documentIds || [],
                consolidatedDate: bv ? bv.ConsolidatedDate ? moment(bv.ConsolidatedDate) : null : this.AddBusinessVolumeDefaultValues?.consolidatedDate ? moment(this.AddBusinessVolumeDefaultValues.consolidatedDate) : null,
                consolidatedDeliveryDate: bv ? (bv.ConsolidatedDeliveryDate ? moment(bv.ConsolidatedDeliveryDate) : null) : null,
                taxValue: bv ? bv.TaxValue : this.AddBusinessVolumeDefaultValues?.taxValue || null,
                taxPercentageValue: bv && bv.TaxValue && bv.Amount ? Math.round(bv.TaxValue * 100 / (bv.Amount / 100)) : bv ? null : DEFAULT_TAX,
                discountValue: bv ? (bv.DiscountValue ? bv.DiscountValue : null) : null,
                gegenkonto: bv?.SachkontoCOAId ? chartOfAccount: null,
                exportDate: bv ? (bv.ExportDate ? moment(bv.ExportDate) : null) : null,
                exportTimespan: bv ? (bv.ExportPeriodStart && bv.ExportPeriodEnd ? formatDate(bv.ExportPeriodStart, 'mediumDate', 'de-DE') + ' - ' + formatDate(bv.ExportPeriodEnd, 'mediumDate', 'de-DE') : null) : null,
                payedTaxValue: bv ? (bv.PayedTaxValue ? bv.PayedTaxValue / 100 : null) : isNotNullOrUndefined(this.AddBusinessVolumeDefaultValues?.payedTaxValue) ? this.AddBusinessVolumeDefaultValues.payedTaxValue / 100 : null,
            });
            const ledgerImport$ = bv?.LedgerImportId ? this.store.select(getLedgerImportById({ id: bv.LedgerImportId })).pipe(tap((ledImPDoc) => console.log(ledImPDoc.DocumentId + ' ' + ledImPDoc.DocumentIdXRechnung))) : null;

            if (ledgerImport$) {
                ledgerImport$.subscribe((ledgerImport) => {
                    if (ledgerImport.DocumentId) {
                        this.addFiles([ledgerImport.DocumentId]);
                        this.ledgerImportId = ledgerImport.DocumentId;
                    }
                });
            }

            const readonly = !!bv?.LedgerImportId || bv?.ARLIds?.length;
            this.ledgerImportPath = bv?.LedgerImportId ? `/${ReportsPageMeta.Path}/${AllReportsMeta.Path}/${InvoiceEditorMeta.Path}/${bv.LedgerImportId}` : '';
            if (readonly) {
                this.form.controls.amount.disable();
                this.form.controls.bvType.disable();
                this.form.controls.taxValue.disable();
                this.form.controls.taxPercentageValue.disable();
                this.form.controls.taxPercentageValue.disable();
                // this.form.controls.status.disable();   // laut Ticket D277-3541 soll statusfeld enabled sein.
                this.showDeleteButton = false;
            } else {
                this.showDeleteButton = true;
            }
            this.form.controls.exportDate.disable();
            this.form.controls.exportTimespan.disable();
            this.form.controls.payedTaxValue.disable();

            const Properties: IDetailListTemplateDataProperty[] = [
                {
                    key: 'Typ',
                    formControl: this.form.controls.bvType,
                    options: {
                        specialInput: {
                            select: [
                                {
                                    optionValue: BusinessVolumeCostStateEnum.Income,
                                    optionLabel: 'Einnahmen',
                                },
                                {
                                    optionValue: BusinessVolumeCostStateEnum.Costs,
                                    optionLabel: 'Kosten',
                                },
                            ],
                        },
                    },
                },
                {
                    key: 'Kunde/Lieferant',
                    formControl: this.form.controls.customerId,
                    hideFormControl: !this.ShowCustomerForm,
                    options: {
                        specialInput: {
                            singleSelectSearch: {
                                options: [customerId, ...ce.filter(c => c.Id !== this.form.value.customerId?.Id).map((customer) => ({
                                    Id: customer.Id,
                                    optionLabel:
                                        customer.DisplayName
                                        +
                                        ' / ' +
                                        (customer.CustomerNo === null || customer.CustomerNo === undefined ? ' ' : customer?.CustomerNo)/* +
                                        (customer.KontoCOAId === null || customer.KontoCOAId === undefined ? '' : ' / ') +
                                        ( (customer.KontoCOAId === null || chartDic[customer.KontoCOAId] === null) ||
                                        (customer.KontoCOAId === undefined || chartDic[customer.KontoCOAId] === undefined) ? '' : chartDic[customer.KontoCOAId]?.Number ),*/
                                }))].filter(isNotNullOrUndefined),
                                compareOptions: (a, b) => a.Id === b.Id,
                            },
                        },
                    },
                },
                {
                    key: 'Auftrag',
                    formControl: this.form.controls.commissionId,
                    hideFormControl: !this.ShowCommissionForm,
                    options: {
                        specialInput: {
                            singleSelectSearch: {
                                options: (commission ? [commission, ...activeCommissions.filter((c) => c.Id !== commission.Id)] : activeCommissions).map((commission) => ({ Id: commission.Id, optionLabel: commission.DisplayName })),
                                compareOptions: (a, b) => a.Id === b.Id,
                            },
                        },
                    },
                },
                {
                    key: 'Status',
                    formControl: this.form.controls.status,
                    options: {
                        specialInput: {
                            singleSelectSearch: {
                                compareOptions: (a, b) => a.optionValue === b.optionValue,
                                options$: this.form.controls.bvType.valueChanges.pipe(
                                    startWith(this.form.controls.bvType.value),
                                    distinctUntilChanged(),
                                    map((type: BusinessVolumeCostStateEnum) => {
                                        if (type) {
                                            return uniqArray([...AvailableBusinessVolumeStatusByType.get(type), this.form.value.status?.optionValue].filter(isNotNullOrUndefined)).map((v) => ({
                                                optionValue: v,
                                                optionLabel: BusinessVolumeStatusEnumNameMap.get(v),
                                            }));
                                        } else {
                                            return [];
                                        }
                                    }),
                                ),
                            },
                        },
                    },
                },
                {
                    key: 'Name',
                    formControl: this.form.controls.name,
                },
                {
                    key: 'Nummer',
                    formControl: this.form.controls.number,
                },
                {
                    key: 'Gegenkonto',
                    formControl: this.form.controls.gegenkonto,
                    options: {
                        specialInput: {
                            customTemplate: this.chartOfAccountTemplate,
                        },
                    },
                },
                {
                    key: 'Summe',
                    formControl: this.form.controls.amount,
                    options: {
                        specialInput: {
                            customTemplate: this.sumTemplate,
                        },
                    },
                },
                {
                    key: 'bezahlteSumme',
                    formControl: this.form.controls.payedAmount,
                    options: {
                        specialInput: {
                            customTemplate: this.payedSumTemplate,
                        },
                    },
                },
                {
                    key: 'Skonto',
                    formControl: this.form.controls.discountValue,
                    options: {
                        // suffix: '€',
                        specialInput: {
                            // number: true,
                            customTemplate: this.skontoTemplateRef,
                        },
                    },
                },
                {
                    key: 'Rechnungsdatum',
                    formControl: this.form.controls.consolidatedDate,
                    value: bv ? (bv.ConsolidatedDate ? formatDate(bv.ConsolidatedDate, 'mediumDate', 'de-DE') : '') : '',
                    options: {
                        specialInput: {
                            date: true,
                        },
                    },
                },
                {
                    key: 'Leistungsdatum',
                    formControl: this.form.controls.consolidatedDeliveryDate,
                    value: bv ? (bv.ConsolidatedDeliveryDate ? formatDate(bv.ConsolidatedDeliveryDate, 'mediumDate', 'de-DE') : '') : '',
                    options: {
                        specialInput: {
                            date: true,
                        },
                    },
                },
                {
                    key: 'Beschreibung',
                    formControl: this.form.controls.description,
                },
                {
                    key: 'Art',
                    formControl: this.form.controls.customNameIds,
                    options: {
                        specialInput: {
                            chipAutocomplete: {
                                MapFn: (option: BVCustomNameEntity) => option.Name || '',
                                Options$: this.store.select(getBVCustomNames).pipe(map((bvs) => bvs.filter((a) => !a.DeletedAt || this.form.controls.customNameIds.value?.some((b) => b.Id === a.Id)))),
                                onUnknownOptionSubmitted: (value) => {
                                    this.form.controls.customNameIds.disable();
                                    firstValueFrom(this.actions$.pipe(ofType(BVCustomNameActions.updateOne, BaseActionTypes.ErrorAction))).then((a) => {
                                        this.form.controls.customNameIds.enable();
                                        if (a.type === BVCustomNameActions.updateOne.type) {
                                            this.form.controls.customNameIds.setValue([...this.form.controls.customNameIds.value, a.Payload]);
                                        }
                                    });
                                    this.store.dispatch(BVCustomNameActions.add({ Payload: { name: value } }));
                                },
                                // OptionTemplate: this.activityOptionTemplate,
                            },
                        },
                    },
                },
                {
                    key: 'Exportdatum',
                    formControl: this.form.controls.exportDate,
                    value: bv ? (bv.ExportDate ? formatDate(bv.ExportDate, 'mediumDate', 'de-DE') : '') : '',
                    options: {
                        specialInput: {
                            date: true,
                        },
                    },
                },
                {
                    key: 'Exportzeitraum',
                    formControl: this.form.controls.exportTimespan,
                    value: bv ? (bv.ExportPeriodStart && bv.ExportPeriodEnd ? formatDate(bv.ExportPeriodStart, 'mediumDate', 'de-DE') + ' - ' + formatDate(bv.ExportPeriodEnd, 'mediumDate', 'de-DE') : '') : '',
                },

            ].filter(isNotNullOrUndefined);
            return {
                Properties,
            };
        });
        this.form.controls.gegenkonto.valueChanges.subscribe(() => {
            this.form.markAsDirty();
            this.cdr.detectChanges();
        });

        setTimeout(() => {
            this.form.markAsDirty(false);
            this.form.markAsPristine(true);
            this.cdr.detectChanges();
        }, 0);
    }

    save() {
        if (!this.form.valid) {
            return;
        }
        this.ls.startLoading('business-volume-dialog--save');
        firstValueFrom(this.actions$.pipe(ofType(BusinessVolumeActionTypes.UpdateOne, BaseActionTypes.ErrorAction))).then((action) => {
            this.ls.endLoading('business-volume-dialog--save');
            if (action.type === BusinessVolumeActionTypes.UpdateOne.type) {
                this.dialogRef?.close();
            }
        });

        if (this.BvId) {
            this.store.dispatch(
                BusinessVolumeActionTypes.Change({
                    Payload: {
                        id: this.BvId,
                        customerId: this.form.controls.customerId.value?.Id || null,
                        commissionId: this.form.controls.commissionId.value?.Id || null,
                        name: this.form.controls.name.value,
                        description: this.form.controls.description.value,
                        bVType: this.form.controls.bvType.value,
                        status: this.form.controls.status.value?.optionValue || null,
                        amount: this.form.controls.amount.value ? MathRound(this.form.controls.amount.value * 100, true) : null,
                        payedAmount: this.form.controls.payedAmount.value ? MathRound(this.form.controls.payedAmount.value * 100, true) : null,
                        bVCustomNamesIds: this.form.controls.customNameIds.value.map((v) => v.Id),
                        businessVolumeNo: this.form.controls.number.value,
                        documentIds: this.form.value.documentIds,
                        consolidatedDate: this.form.value.consolidatedDate ? FrontendDate(this.form.value.consolidatedDate.toDate()) : null,
                        consolidatedDeliveryDate: this.form.value.consolidatedDeliveryDate ? FrontendDate(this.form.value.consolidatedDeliveryDate.toDate()) : null,
                        taxValue: this.form.controls.taxValue.value,
                        discountValue: this.form.controls.discountValue.value,
                        sachkontoCOAId: this.form.controls.gegenkonto.value?.Id,
                        payedTaxValue: this.form.controls.payedTaxValue?.value ? MathRound(this.form.controls.payedTaxValue.value * 100, true) : null,
                    },
                }),
            );
        } else {
            this.store.dispatch(
                BusinessVolumeActionTypes.Add({
                    Payload: {
                        ...this.AddBusinessVolumeDefaultValues,
                        bVType: this.form.controls.bvType.value,
                        customerId: this.form.controls.customerId.value?.Id,
                        commissionId: this.form.controls.commissionId.value?.Id,
                        name: this.form.controls.name.value,
                        description: this.form.controls.description.value,
                        status: this.form.controls.status.value?.optionValue || null,
                        amount: this.form.controls.amount.value ? MathRound(this.form.controls.amount.value * 100, true) : null,
                        payedAmount: this.form.controls.payedAmount.value ? MathRound(this.form.controls.payedAmount.value * 100, true) : null,
                        bVCustomNamesIds: this.form.controls.customNameIds.value.map((v) => v.Id),
                        businessVolumeNo: this.form.controls.number.value,
                        documentIds: this.form.value.documentIds,
                        consolidatedDate: this.form.value.consolidatedDate ? FrontendDate(this.form.value.consolidatedDate.toDate()) : undefined,
                        consolidatedDeliveryDate: this.form.value.consolidatedDeliveryDate ? FrontendDate(this.form.value.consolidatedDeliveryDate.toDate()) : undefined,
                        taxValue: this.form.controls.taxValue.value,
                        discountValue: this.form.controls.discountValue.value,
                        sachkontoCOAId: this.form.controls.gegenkonto.value?.Id,
                        payedTaxValue: this.form.controls.payedTaxValue.value ? MathRound(this.form.controls.payedTaxValue.value * 100, true) : null,
                    },
                }),
            );
        }
    }

    deleteBV() {
        this.ls.startLoading('business-volume-dialog--delete');
        firstValueFrom(this.actions$.pipe(ofType(BusinessVolumeActionTypes.Delete, BaseActionTypes.ErrorAction))).then((action) => {
            this.ls.endLoading('business-volume-dialog--delete');
            if (action.type === BusinessVolumeActionTypes.Delete.type) {
                this.dialogRef?.close();
            }
        });
        this.store.dispatch(BusinessVolumeActionTypes.Delete({ Payload: { id: this.BvId } }));
    }

    fileSelectDialog() {
        const formValues = { CustomerId: this.form.value.customerId?.Id, CommissionId: this.form.value.commissionId?.Id };

        const getFolder$ = () => {
            if (formValues.CommissionId) {
                return this.folderDataService.getFolderFromEntity(formValues.CommissionId, FolderTypes.commission);
            } else if (formValues.CustomerId) {
                return this.folderDataService.getFolderFromEntity(formValues.CustomerId, FolderTypes.customer);
            } else {
                return of(null);
            }
        };
        firstValueFrom(getFolder$()).then((folder) => {
            this.dialog
                .open<DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData, DaveSelectFileFromDmsComponentReturnData>(DaveSelectFileFromDmsComponent, {
                    ...DaveSelectFileFromDmsComponent.DefaultConfig,
                    data: {
                        moveWarning: '',
                        preSelectedFiles: this.form.value.documentIds,
                        folderId: folder?.Id || undefined,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result?.documents?.length) {
                        this.addFiles(result.documents);
                    }
                });
        });
    }

    fileUploadDialog() {
        const dialogRef = this.dialog.open<SelectFolderDialogComponent, SelectFolderDialogComponentDialogData>(SelectFolderDialogComponent, {
            ...SelectFolderDialogComponent.DefaultConfig,
            data: {
                ButtonText: 'HOCHLADEN',
                matTreeNodePaddingIndent: 10,
                uploadMode: true,
                synchronUpload: true,
            },
        });
        // firstValueFrom(this.folderTreeService.Tree).then((folder) => {
        //     dialogRef.componentInstance.TreeDataSource = new MatTreeFlatDataSource(folderTreeControl, folderTreeFlattener, folder);
        // });
        this.actions$
            .pipe(
                ofType(FilesActionTypes.UpdateMany),
                takeUntil(dialogRef.afterClosed()),
                reduce((acc, curr) => {
                    acc.push(...curr.Payload.map((p) => p.Id));
                    return acc;
                }, [] as number[]),
            )
            .subscribe((fileIds) => {
                if (fileIds?.length) {
                    this.addFiles(fileIds);
                }
            });
    }
    addFiles(fileIds: number[]) {
        this.form.controls.documentIds.setValue([...this.form.value.documentIds, ...fileIds]);
        this.form.controls.documentIds.markAsDirty();
    }

    onFileClick({ file, multiselect }: { file: FileEntity; multiselect: boolean }) {
        if (!multiselect && !this.DialogData.disableFileClick) {
            this.filePreviewDialog?.close();
            let imageIds : number[];
            this.fileDataService.GetFilesById(uniqArray([...this.form.controls.documentIds.value, file.Id].filter(isNotNullOrUndefined))).pipe(take(1), map(files => files.filter((f) =>
                    f.MIMEType.indexOf('image/') > -1).map((f) => f.Id))).subscribe(imgIds=> {
                    imageIds = imgIds
                });
            this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
                ...DaveFilePreviewComponent.DefaultConfig,
                data: {
                    fileId: file.Id,
                    fileExplorerContext: imageIds ? {
                        directoryFileIds: imageIds,
                    } : undefined
                },
            });
        }
    }

    onMergePdfClick(documentIds: number[]) {
        const dialogRef = this.dialog.open<SortFilesDialogComponent, SortFilesDialogComponentDialogData>(SortFilesDialogComponent, {
            ...SortFilesDialogComponent.DefaultConfig,
            data: {
                headline: 'PDFs zusammenführen',
                documentIds: documentIds,
                showFileNameForm: true,
                fileName: NEW_DOCUMENT_DEFAULT_NAME + '.pdf',
            },
        });
        dialogRef.componentInstance.submitClick.pipe(takeUntil(dialogRef.beforeClosed())).subscribe(() => {
            const dialogRef2 = this.dialog.open<SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData>(SelectFolderDialogComponent, {
                ...SelectFolderDialogComponent.DefaultConfig,
                data: {
                    ButtonText: 'HOCHLADEN',
                    matTreeNodePaddingIndent: 10,
                    uploadMode: false,
                },
            });
            dialogRef2.afterClosed().subscribe((res) => {
                if (res) {
                    this.ls.startLoading('file-explorer--merge-pdf');
                    firstValueFrom(this.actions$.pipe(ofType(FilesActionTypes.MergePdfVersionsFailure, FilesActionTypes.MergePdfVersionsSuccess))).then((action) => {
                        this.ls.endLoading('file-explorer--merge-pdf');
                        if (action.type === FilesActionTypes.MergePdfVersionsSuccess.type) {
                            dialogRef.close();
                            this.addFiles([action.Payload]);
                        }
                    });
                    this.store.dispatch(
                        FilesActionTypes.MergePdfVersionsRequest({
                            Payload: {
                                FolderId: res.folderId ? res.folderId.toString() : null,
                                VersionIds: dialogRef.componentInstance.documentsSorted.map((d) => d.GetLastVersion().Id.toString()),
                                DocumentName: dialogRef.componentInstance.fileName,
                            },
                        }),
                    );
                }
            });
        });
    }

    removeFile(file: FileEntity) {
        if (this.ledgerImportId === file.Id) {
            window.alert('Gebuchte bzw. verrechnete Rechnung kann nicht gelöscht werden!');
        } else {
            // const documentIds = this.form.value.documentIds as number[];             // dieser Code verursacht Fehler bei splice(). muss überprüft werden !
            // const index = documentIds.indexOf(file.Id);
            // if (index !== -1) {
            //     documentIds.splice(index, 1);
            //     this.form.controls.documentIds.setValue([...documentIds]);
            //     this.form.controls.documentIds.markAsDirty();
            // }
            const documentIds = this.form.value.documentIds as number[];
            const updatedDocumentIds = documentIds.filter((id) => id !== file.Id);
            this.form.controls.documentIds.setValue(updatedDocumentIds);
            this.form.controls.documentIds.markAsDirty();
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());

    }



    protected readonly AccountTypeEnum = AccountTypeEnum;


    taxPercentageValueChanged() {
        const taxPercentageValue = this.form.get('taxPercentageValue')?.value;
        const amount = this.form.get('amount')?.value;
        const payedSum = this.form.get("payedAmount")?.value;

        this.form.patchValue({
            taxValue: +(amount*taxPercentageValue/100).toFixed(2),
        });

        if (payedSum!= null){
            this.form.patchValue({
                payedTaxValue:  +(taxPercentageValue/100*payedSum).toFixed(2),
            });
        }
    }
    taxValueChanged() {
        const taxValue = this.form.get('taxValue')?.value;
        const amount = this.form.get('amount')?.value;
        const payedSum = this.form.get("payedAmount")?.value;
        const taxPercentageValue = taxValue*100/amount
        this.form.patchValue({
            taxPercentageValue: +(taxPercentageValue).toFixed(2),
        });
        if (payedSum!= null){
            this.form.patchValue({
                payedTaxValue:  +(taxPercentageValue/100*payedSum).toFixed(2),
            });
        }
    }

    payedSumValueChanged() {
        const taxPercentageValue = this.form.get('taxPercentageValue')?.value;
        const payedSum = this.form.get("payedAmount")?.value;

        this.form.patchValue({
            payedTaxValue: +(taxPercentageValue/100*payedSum).toFixed(2),
        });
    }

    newChartOfAccountPopUp(entity?: ChartOfAccountEntity , accountType? :AccountTypeEnum) {

        const chartOfAccount =  entity ? this.chartOfAccounts.find(chart=> chart.Id === entity.Id): null;

        const name = new FormControl<string>(chartOfAccount?.Name ? chartOfAccount.Name : '');
        const description = new FormControl<string>(chartOfAccount?.Description ? chartOfAccount.Description : '');
        const number = new FormControl<number>(chartOfAccount?.Number ? chartOfAccount.Number : null ,Validators.required);

        this.dialog
            .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                ...DetailListTemplateDialogComponent.DefaultConfig,
                data: {
                    DisableSaveButton$: number.statusChanges.pipe(
                        startWith(number.status),
                        map((state) => state !== 'VALID'),
                    ),
                    Editing: true,
                    DeleteButton: !!chartOfAccount,
                    Data: {
                        Headline: chartOfAccount ? (chartOfAccount.Name ? chartOfAccount.Name + ' bearbeiten' : 'Konto bearbeiten') : 'Neues Konto anlegen',
                        Properties: [
                            {
                                key: 'Name',
                                formControl: name,
                            },
                            {
                                key: 'Nummer',
                                formControl: number,
                                options: {
                                    specialInput: {
                                        number: true,
                                    }
                                }
                            },
                            {
                                key: 'Bezeichnung',
                                formControl: description,
                            },
                        ].filter(isNotNullOrUndefined),
                    },
                },
            })
            .afterClosed()
            .subscribe((ret)=> {
                if (ret.Action === 'delete') {
                    this.store.dispatch(ChartOfAccountActionTypes.Delete({ Payload: { id: chartOfAccount.Id } }))
                }
                if (ret.Action === 'save') {
                    if (!chartOfAccount) {
                        this.store.dispatch(ChartOfAccountActionTypes.Add({
                            Payload: {
                                description: description.value,
                                name: name.value,
                                number: number.value,
                                accountType: accountType,
                            }
                        }));
                        this.actions$
                            .pipe(ofType(ChartOfAccountActionTypes.AddSuccess))
                            .subscribe((res) => {
                                if (res.Payload ) {
                                    this.form.controls.gegenkonto.setValue(Object.assign(res.Payload ));
                                }
                            });
                    }
                    else {
                        this.store.dispatch(ChartOfAccountActionTypes.ChangeChartOfAccount({
                            Payload: {
                                id: chartOfAccount.Id,
                                description: description.value,
                                name: name.value,
                                number: number.value,
                                accountType: chartOfAccount.AccountType
                            }
                        }));
                        this.actions$
                            .pipe(ofType(ChartOfAccountActionTypes.ChangeSuccess))
                            .subscribe((res) => {
                                if (res.Payload ) {
                                    if (res.Payload.AccountType === AccountTypeEnum.Contra){
                                        this.form.controls.gegenkonto.setValue(Object.assign(res.Payload ));
                                    }
                                }
                            });
                    }
                }
            })

    }


    setSkontoForm() {
        if (this.form.value.amount && this.form.value.payedAmount) {
            this.form.controls.discountValue.setValue(this.form.value.amount - this.form.value.payedAmount)
        }
    }
    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): boolean {
        return !this.form.dirty;
    }
    closeDialog() {
        if (this.dialogRef) {
            if (this.canDeactivate() || confirm(PENDING_CHANGES_DEFAULT_MESSAGE)) {
                this.dialogRef.close();
            }
        }
    }
}
