import { Injectable } from '@angular/core';
import { Actions,createEffect,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError,switchMap,tap,withLatestFrom } from 'rxjs/operators';
import { WorkDayEntityFromBackend,WorkDayEntityGqlFields } from '../../entities/work-day.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { WorkDayActionTypes } from '../actions/work-day.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';

enum ErrorCodes {
    Load = 'Stundenzettel Abrufen fehlgeschlagen',
    Remove = 'Stundenzettel Löschen fehlgeschlagen',
    Modify = 'Stundenzettel ändern fehlgeschlagen',
    Add = 'Stundenzettel hinzufügen fehlgeschlagen',
    Delete = 'Stundenzettel löschen fehlgeschlagen',
}
@Injectable()
export class WorkDayEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
    ) {}

    GetAllWorkDaysQuery$ = createEffect(() => this.actions$.pipe(
        ofType(WorkDayActionTypes.Load),
        withLatestFrom(this.store$.select(getToken)),
        switchMap(([{Payload}, token]) => {
            const queryString = `query {
          workDay${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
            ${WorkDayEntityGqlFields}
          }
        }
        `;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                tap(res => {
                    if (!Payload?.updatedSince || res.workDay.length) {
                        if(
                            res && res.workDay) {
                            if (Payload?.updatedSince) {
                                this.store$.dispatch(
                                    WorkDayActionTypes.RemoveMany({
                                        Payload: res.workDay.filter(w => !!w.deletedAt).map((val) => val.id),
                                    }),
                                );
                                this.store$.dispatch(
                                    WorkDayActionTypes.UpdateMany({
                                        Payload: res.workDay.filter(w => !w.deletedAt).map((val) => WorkDayEntityFromBackend(val)),
                                        updateLatestUpdatedAt: true,
                                    }),
                                );
                            } else {
                                this.store$.dispatch(
                                    WorkDayActionTypes.UpdateAll({
                                        Payload: res.workDay.map((val) => WorkDayEntityFromBackend(val)),
                                        updateLatestUpdatedAt: true,
                                    }),
                                );
                            }
                        }
                        else {
                            this.store$.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: { ToasterMessage: ErrorCodes.Load },
                                }),
                            );
                        }
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            );
        }),
    ), { dispatch: false});
}
