import { ConstructionDiaryType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export enum ConstructionDiaryStatusEnum {
    Newly = 'NEWLY',
    Done = 'DONE',
}
export const ConstructionDiaryStatusEnumNameMap: Map<ConstructionDiaryStatusEnum, string> = new Map<ConstructionDiaryStatusEnum, string>([
    [ConstructionDiaryStatusEnum.Newly, 'Offen'],
    [ConstructionDiaryStatusEnum.Done, 'Erledigt'],
]);
export interface ConstructionDiaryEntityAdditionalData {
    weather: {
        description?: string;
        temp?: number;
    };
    leistungsergebnisseTemplateIds?: number[];
}
export class ConstructionDiaryEntity implements ConstructionDiaryEntityAttributes {
    constructor(
        public Id: number = null,
        public BehinderungenErwachsene: string = null,
        public CommissionId: number = null,
        public CreatedAt: Date = null,
        public Date: Date = null,
        public DeletedAt: Date = null,
        public EventIds: number[] = null,
        public FileId: number = null,
        public GeneratedFileId: number = null,
        public Leistungsaenderungen: string = null,
        public Leistungsergebnisse: string = null,
        public PartnerId: number = null,
        public Status: ConstructionDiaryStatusEnum = null,
        public UpdatedAt: Date = null,
        public UserId: number = null,
        public AdditionalData: ConstructionDiaryEntityAdditionalData = null,
        public BesondereVorkommnisse: string = null,
    ) {}
    public static readonly GqlFields = `
    additionalData
    besondereVorkommnisse
    behinderungenErschwernisse
    commissionId
    createdAt
    date
    deletedAt
    eventIds
    fileId
    generatedFileId
    id
    leistungsaenderungen
    leistungsergebnisse
    partnerId
    status
    updatedAt
    userId`;
    public Clone(override: ConstructionDiaryEntityAttributes = {}): ConstructionDiaryEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ConstructionDiaryEntity(), attr);
    }
    public getStatusColor(): string {
        // die Farben stehen auch in styles.scss in klassen für den mat-datepicker
        return this.GeneratedFileId
            ? 'rgb(84, 84, 199)'
            : this.Status === ConstructionDiaryStatusEnum.Newly
            ? 'orange'
            : this.Status === ConstructionDiaryStatusEnum.Done
            ? 'green'
            : 'red'; // kommt eigentlich nie vor da this.Status nicht null sein sollte
    }
    public getStatusDescription(): string {
        return this.GeneratedFileId
            ? 'exportiert'
            : ConstructionDiaryStatusEnumNameMap.get(this.Status);
    }
}

export function ConstructionDiaryEntityFromBackend(res: ConstructionDiaryType): ConstructionDiaryEntity {
    return new ConstructionDiaryEntity(
        res.id,
        res.behinderungenErschwernisse,
        res.commissionId,
        res.createdAt && BackendDate(res.createdAt),
        res.date && BackendDate(res.date),
        res.deletedAt && BackendDate(res.deletedAt),
        res.eventIds,
        res.fileId,
        res.generatedFileId,
        res.leistungsaenderungen,
        res.leistungsergebnisse,
        res.partnerId,
        res.status,
        res.updatedAt && BackendDate(res.updatedAt),
        res.userId,
        res.additionalData as any,
        res.besondereVorkommnisse,
    );
}

export interface ConstructionDiaryEntityAttributes {
    Id?: number;
    BehinderungenErwachsene?: string;
    CommissionId?: number;
    CreatedAt?: Date;
    Date?: Date;
    DeletedAt?: Date;
    EventIds?: number[];
    FileId?: number;
    GeneratedFileId?: number;
    Leistungsaenderungen?: string;
    Leistungsergebnisse?: string;
    PartnerId?: number;
    Status?: ConstructionDiaryStatusEnum;
    UpdatedAt?: Date;
    UserId?: number;
    AdditionalData?: ConstructionDiaryEntityAdditionalData;
    BesondereVorkommnisse?: string;
}
