import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { SimplebarAngularModule } from 'simplebar-angular';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from "../dave-utils-module/app-filter-module/app-filter.module";
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { PipesModule } from '../dave-utils-module/dave-shared-components-module/pipes/pipes.module';
import { Scan2daveListComponent } from './components/scan2dave-list/scan2dave-list.component';


@NgModule({
    declarations: [Scan2daveListComponent],
    imports: [
        CommonModule,
        MatSidenavModule,
        AppButtonModule,
        FontAwesomeModule,
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        SimplebarAngularModule,
        MatTooltipModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatCardModule,
        MatDividerModule,
        DaveSharedComponentsModule,
        MatDialogModule,
        MatMenuModule,
        ScrollingModule,
        TableVirtualScrollModule,
        PipesModule,
        RouterModule,
        AppFilterModule,
        MatBadgeModule,
    ],
    exports: [Scan2daveListComponent],
})
export class DaveScan2DaveListModule {}
