import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Store} from '@ngrx/store';
import {filter, take} from 'rxjs/operators';
import { State } from '../State';
import { getMandateTypesFetched } from '../State/selectors/mandate-types.selectors';
import { GetMandateTypesRequest } from '../State/actions/mandate-type.actions';

@Injectable({
    providedIn: 'root'
})
export class MandateTypeResolver implements Resolve<boolean> {
    constructor(private store: Store<State>) {}

    resolve() {
        const value$ = this.store.select(getMandateTypesFetched);

        value$
            .pipe(take(1))
            .subscribe(
                fetched =>
                    !fetched &&
                    localStorage.getItem('token') != null &&
                    this.store.dispatch(new GetMandateTypesRequest())
            );

        return value$.pipe(
            filter(v => v),
            take(1)
        );
    }
}
