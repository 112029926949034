import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, interval, of, Subject, switchMap } from 'rxjs';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { EnumClockInTypeSlug } from '../../../dave-data-module/entities/clock-in-type.entity';
import { ClockInTypes } from '../../../dave-data-module/entities/clock-in.entity';
import { ClockInTypeResolver } from '../../../dave-data-module/guards/clock-in-type.resolver';
import { FrontendDateTimestamp } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { getClockInTypeDictionary, getClockInTypes, getClockInTypesFetched } from '../../../dave-data-module/State/selectors/clock-in-type.selectors';
import { getEmployees } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { ClockInHelperService } from '../../../services/clock-in-helper.service';
import { TimeTrackerService } from '../../time-tracker.service';
import { NewClockinPopupComponent, NewClockinPopupComponentDialogData } from '../new-clockin-popup/new-clockin-popup.component';

@Component({
    selector: 'app-time-tracker',
    templateUrl: './time-tracker.component.html',
    styleUrls: ['./time-tracker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeTrackerComponent implements OnDestroy {
    public PauseRunning = false;
    private readonly onDestroy = new Subject<void>();
    // private lastClockIn$ = this.timeTrackerService.lastClockIn$;
    // private runningWorkday$ = this.timeTrackerService.runningWorkday$.pipe(takeUntil(this.onDestroy));
    private sortedClockIns$ = this.timeTrackerService.sortedClockIns$;
    public RunningWorkTime$ = this.timeTrackerService.RunningWorkTime$;
    public BreakTime$ = this.sortedClockIns$.pipe(
        switchMap((sorted) =>
            combineLatest([
                of(sorted),
                this.store.select(getClockInTypesFetched).pipe(
                    filter((fetched) => !!fetched),
                    switchMap(() => this.store.select(getClockInTypeDictionary)),
                    first(),
                ),
            ]),
        ),
        switchMap(([sorted, clockInTypes]) => {
            const globalTypeId = Object.values(clockInTypes).find((ci) => ci.Slug === EnumClockInTypeSlug.workTime)?.Id;
            const pauseTypeId = Object.values(clockInTypes).find((ci) => ci.Slug === EnumClockInTypeSlug.break)?.Id;
            let time = 0;
            if (!sorted.length) {
                this.PauseRunning = false;
                return of(time);
            }
            let valid = sorted[0].Type === ClockInTypes.Start && sorted[0].ClockInTypeId === globalTypeId;
            const now = new Date();
            let i = 1;
            let currentlyPause = false;
            while (valid && i < sorted.length) {
                currentlyPause = sorted[i].Type === ClockInTypes.Start && sorted[i].ClockInTypeId === pauseTypeId;
                if (currentlyPause) {
                    if (i + 1 < sorted.length) {
                        time += sorted[i + 1].TimeStamp.getTime() - sorted[i].TimeStamp.getTime();
                    } else {
                        time += now.getTime() - sorted[i].TimeStamp.getTime();
                    }
                }
                i++;
            }
            if (!valid) {
                return of(null);
            }
            if (!currentlyPause || (sorted[sorted.length - 1].Type === ClockInTypes.End && sorted[sorted.length - 1].ClockInTypeId === globalTypeId)) {
                this.PauseRunning = false;
                return of(time);
            } else {
                this.PauseRunning = true;
                // time += new Date().getTime() - now.getTime();
                return interval(1000).pipe(map((v) => time + new Date().getTime() - now.getTime()));
            }
        }),
        filter(isNotNullOrUndefined),
        map((timeMs) => {
            return {
                h: Math.trunc(timeMs / 1000 / 60 / 60),
                m: Math.trunc(timeMs / 1000 / 60) % 60,
                s: Math.trunc(timeMs / 1000) % 60,
            };
        }),
    );
    private employeeId: number;

    constructor(
        private store: Store<State>,
        private changeDetector: ChangeDetectorRef,
        private dialog: MatDialog,
        private clockInTypeResolver: ClockInTypeResolver,
        protected timeTrackerService: TimeTrackerService,
        private clockInService: ClockInHelperService,
    ) {
        this.clockInTypeResolver.resolve();
        combineLatest([this.store.select(getEmployees), this.store.select(getUser)])
            .pipe(takeUntil(this.onDestroy))
            .subscribe(([employees, user]) => (this.employeeId = employees?.find((e) => e.UserId === user?.Id)?.Id));
    }
    public PlayPause() {
        if (this.PauseRunning) {
            this.addClockIn(ClockInTypes.End, EnumClockInTypeSlug.break, new Date());
        } else {
            firstValueFrom(
                combineLatest([
                    this.store.select(getClockInTypesFetched).pipe(
                        tap((fetched) => {
                            if (!fetched) {
                                this.clockInTypeResolver.resolve();
                            }
                        }),
                        filter((fetched) => !!fetched),
                        switchMap(() => this.store.select(getClockInTypes)),
                    ),
                    this.sortedClockIns$,
                ]),
            ).then(([types, clockInsSorted]) => {
                this.dialog.open<NewClockinPopupComponent, NewClockinPopupComponentDialogData>(NewClockinPopupComponent, {
                    ...NewClockinPopupComponent.DefaultConfig,
                    closeOnNavigation: false,
                    disableClose: true,
                    data: {
                        EmployeeId: this.employeeId,
                        Date: new Date(),
                        New: false,
                        SelectDateEnd: false,
                        SelectDate: false,
                        Close: false,
                        Dashboard: true,
                        Type: ClockInTypes.Start,
                        AutoAssignCommissionId: true,
                        DefaulClockinTypeId: types.find((type) => type.Slug === EnumClockInTypeSlug.break).Id,
                        CommissionId: clockInsSorted
                            .slice()
                            .reverse()
                            .find((c) => c.CommissionId)?.CommissionId,
                    } as NewClockinPopupComponentDialogData,
                });
            });
        }
    }
    public PlayStop() {
        if (this.timeTrackerService.Running) {
            firstValueFrom(this.sortedClockIns$).then((clockInsSorted) =>
                this.dialog.open<NewClockinPopupComponent, NewClockinPopupComponentDialogData>(NewClockinPopupComponent, {
                    ...NewClockinPopupComponent.DefaultConfig,
                    closeOnNavigation: false,
                    disableClose: true,
                    data: {
                        EmployeeId: this.employeeId,
                        Date: new Date(),
                        New: false,
                        SelectDateEnd: false,
                        SelectDate: false,
                        Close: false,
                        Dashboard: true,
                        Type: ClockInTypes.End,
                        AutoAssignCommissionId: true,
                        CommissionId: clockInsSorted
                            .slice()
                            .reverse()
                            .find((c) => c.CommissionId)?.CommissionId,
                    } as NewClockinPopupComponentDialogData,
                }),
            );
        } else {
            this.addClockIn(ClockInTypes.Start, EnumClockInTypeSlug.workTime, new Date());
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
    }
    private addClockIn(startEnd: ClockInTypes, type: EnumClockInTypeSlug, timeStamp: Date) {
        this.store
            .select(getClockInTypesFetched)
            .pipe(
                tap((fetched) => {
                    if (!fetched) {
                        this.clockInTypeResolver.resolve();
                    }
                }),
                filter((fetched) => !!fetched),
                switchMap(() => this.store.select(getClockInTypes)),
                first(),
            )
            .subscribe((types) => {
                this.clockInService.dispatchClockInActions(
                    [
                        {
                            employeeId: this.employeeId,
                            type: startEnd,
                            timeStamp: FrontendDateTimestamp(timeStamp),
                            clockInTypeId: types.find((t) => t.Slug === type)?.Id,
                        },
                    ],
                    [],
                    [],
                );
            });
    }
}
