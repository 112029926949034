import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    RESOURCE_SCHEDULE_KEY,
    ResourceScheduleState,
    selectAllResourceSchedule,
    selectResourceScheduleEntities,
    selectResourceScheduleFetched,
    selectResourceScheduleLatestUpdatedAt,
} from '../../reducers/resource-dispo/resource-schedule.reducer';

const selectState = createFeatureSelector<ResourceScheduleState>(RESOURCE_SCHEDULE_KEY);

export const getResourceScheduleDictionary = createSelector(selectState, selectResourceScheduleEntities);
export const getResourceSchedules = createSelector(selectState, selectAllResourceSchedule);
export const getResourceSchedulesFetched = createSelector(selectState, selectResourceScheduleFetched);
export const getResourceScheduleLatestUpdatedAt = createSelector(selectState, selectResourceScheduleLatestUpdatedAt);

export const getResourceScheduleById = (props: { id: number }) => createSelector(getResourceScheduleDictionary, (dictionary) => props?.id && dictionary[props.id]);
