import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, concatMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {
    GetMandateTypesResponse,
    MandateTypeActions,
    MandateTypeActionTypes
} from '../actions/mandate-type.actions';
import {MandateTypeEntityFromBackend} from '../../entities/mandate-type.entity';
import {HttpService} from '../../services/http.service';
import {State} from '../index';
import {BaseActionTypes} from '../actions/base.actions';
import { getToken } from '../selectors/base.selectors';

enum ErrorCodes {
    Load = 'Mandantentypen Abrufen fehlgeschlagen'
}

@Injectable()
export class MandateTypeEffects {

    GetMandateTypes$ = createEffect(() => this.actions$.pipe(
        ofType(MandateTypeActionTypes.GetMandateTypesRequest),
        map(
            () => `
            {
                mandateTypes {
                    id
                    name
                }
            }`
        ),
        withLatestFrom(this.store.select(getToken)),
        switchMap(([query, token]) => this.api.graphQl({query}, {token, retry: true })),
        map(res => res && res.mandateTypes),
        concatMap(res =>
            res
                ? of(
                      new GetMandateTypesResponse(
                          res.map(
                              mandateType => MandateTypeEntityFromBackend(mandateType)
                          )
                      )
                  )
                : of(BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Load}}))
        ),
        catchError((err, caught) => (this.store.dispatch(BaseActionTypes.ErrorAction({
  Payload: {
     ToasterMessage: ErrorCodes.Load,
     Err: err,
     Caught: caught,
  }
})), caught))
    ));

    constructor(
        private actions$: Actions<MandateTypeActions>,
        private api: HttpService,
        private store: Store<State>
    ) {}
}
