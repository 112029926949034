<div class="buttons" *ngIf="this.ShowSaveButton">
    <button app-round-button [class.save-active]="FileCount > 0" matTooltip="Speichern" [Disabled]="Drpzone.getFileCount() < 1" (click)="Save()" *ngIf="this.ShowSaveButton">
        <fa-icon icon="save"></fa-icon>
    </button>
</div>
<ngx-simplebar class="scrollbar">
    <div class="dropzone, full-width">
        <app-dropzone #Drpzone [maxFiles]="maxFiles" [acceptedFiles]="acceptedFiles" style="min-height: 160px" [fileUploadParams]="uploadParamsObj">

        </app-dropzone>
<!--        <dropzone-->
<!--            #Drpzone-->
<!--            [config]="Dropzone"-->
<!--            [message]="'Klicken oder Dateien hinein ziehen'"-->
<!--            (error)="OnUploadError($event)"-->
<!--            (success)="OnUploadSuccess($event)"-->
<!--            (addedFile)="OnAddedFile($event)"-->
<!--            (removedFile)="OnRemovedFile($event)"-->
<!--            (queueComplete)="OnQueComplete()"-->
<!--            (uploadProgress)="onUploadProgress($event)"-->
<!--        >-->
<!--        </dropzone>-->
    </div>
    <app-detail-list-template [AutofocusIndex]="-1" [Data]="FileDetails$ | async" [Editing]="true" [Inline]="true" class="full-width"> </app-detail-list-template>
    <div class="tag-wrapper">
        <app-tag-chip-autocomplete [FormControl]="this.FileForm.controls.Tags"></app-tag-chip-autocomplete>
    </div>
</ngx-simplebar>

<!-- deleteButton das template darf nur ein child haben-->
<ng-template #deleteButton>
    <button app-round-button Color="red" class="delete-button"><fa-icon icon="trash"></fa-icon></button>
</ng-template>
