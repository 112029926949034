import { createEntityAdapter,EntityState } from '@ngrx/entity';
import { createReducer,on } from '@ngrx/store';
import { ActivityEntity } from '../../entities/activity.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { ActivityActions } from '../actions/activity.actions';

export const ACTIVITY_FEATURE_KEY = 'activity';

export interface ActivityState extends EntityState<ActivityEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<ActivityEntity>({
    selectId: Entity => Entity.Id,
});

const initialState: ActivityState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const activityReducer = createReducer(
    initialState,
    on(ActivityActions.updateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, {
            ...state,
            fetched: true,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(ActivityActions.updateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(ActivityActions.updateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
    on(ActivityActions.removeOne, (state, { Payload }) => adapter.removeOne(Payload, state)),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllActivity = selectAll;
export const selectActivityEntities = selectEntities;

export const selectActivityFetched = (state: ActivityState) => state.fetched;
export const selectActivityLatestUpdatedAt = (state: ActivityState) => state.latestUpdatedAt;
