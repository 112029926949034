import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { State } from '../index';
import { QuantityTypeActions } from '../actions/accounting.actions';
import { QuantityTypeEntity, QuantityTypeEntityFromBackend } from '../../entities/quantity-type.entity';

enum ErrorCodes {
    Load = 'Einheiten Abrufen fehlgeschlagen',
}

@Injectable()
export class QuantityTypeEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
    ) {}

    GetAllAccountsReceivableLedgerQuery$ = createEffect(() => this.actions$.pipe(
        ofType(QuantityTypeActions.GetQuantityTypes),
        switchMap(() => {
            const queryString = `query {
          quantityType {${QuantityTypeEntity.GqlFields}}
        }
        `;
            return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                map(res =>
                    res && res.quantityType
                        ? QuantityTypeActions.UpdateQuantityTypes({
                            Payload: res.quantityType.map(f => QuantityTypeEntityFromBackend(f)),
                        })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
}
