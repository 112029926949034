import { CommonModule } from '@angular/common';
import { Component,EventEmitter,Input,Output } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { first,skip } from 'rxjs/operators';
import { EventTypeEntity } from '../../../dave-data-module/entities/event-type.entity';
import { State } from '../../../dave-data-module/State';
import { EventTypesActionTypes } from '../../../dave-data-module/State/actions/event-types.actions';
import { getEventTypes } from '../../../dave-data-module/State/selectors/event-type.selector';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { IconSelectDialogComponent } from '../../../dave-utils-module/dave-shared-components-module/components/templates/event-types-selector-card/icon-select-dialog/icon-select-dialog.component';
import {
DaveSharedComponentsModule
} from '../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { IconPipeModule } from '../../../dave-utils-module/icon-pipe/icon-pipe.module';

@Component({
    selector: 'app-event-types-selector-card',
    templateUrl: './event-types-selector-card.component.html',
    styleUrls: ['./event-types-selector-card.component.scss'],
    standalone: true,
    imports: [CommonModule, AppButtonModule, MatTooltipModule, FontAwesomeModule, DaveSharedComponentsModule, IconPipeModule, MatCardModule],
})
export class EventTypesSelectorCardComponent {
    public IsMobile$ = this.breakpointObserverService.MobileQuery;

    @Input() Headline = 'Art des Ereignisses';
    @Input() EventTypeList: EventTypeEntity[] = null;
    @Input() CurrentEventType: EventTypeEntity = null;
    @Input() Inline = false;

    @Output() EventTypeChange = new EventEmitter<EventTypeEntity>();
    public SaveActive: boolean = false;
    public Editing = false;
    public Deleting = false;
    public CustomEventTypeControl = new FormControl<string>('', [Validators.required]);
    public CustomImagePath: string = null;

    constructor(private apiToasterService: ToastrService, private appDialog: AppDialogService, private breakpointObserverService: BreakpointObserverService, private dialog: MatDialog, private store: Store<State>) {}

    public OnEventTypeClick(eventType: EventTypeEntity) {
        if (!this.Deleting) {
            this.CurrentEventType = eventType;
            this.EventTypeChange.emit(eventType);
        } else if (eventType.IsDefault) {
            this.apiToasterService.error('Vorinstallierte Ereignissarten können nicht gelöscht werden.');
        } else {
            this.appDialog
                .OpenConfirmationDialog({
                    paragraph: `Ereignisart ${eventType.Name} wirklich löschen?`,
                    styleDelete: true,
                })
                .subscribe(([result]) => {
                    if (result) {
                        if (this.CurrentEventType && this.CurrentEventType.Id === eventType.Id) {
                            this.CurrentEventType = null;
                        }

                        this.store.dispatch(EventTypesActionTypes.DeleteEventType({ Payload: eventType.Id }));
                    }
                });
        }
    }

    public NewEventButtonIcon() {
        (this.Editing && this.CustomEventTypeControl.value) ? this.SaveActive = true : this.SaveActive = false;
        return this.Editing ? (this.CustomEventTypeControl.value ? 'save' : 'times') : 'plus';
    }

    public OnNewEventButtonClick() {
        this.Editing = !this.Editing;
        this.Deleting = false;
        if (this.CustomEventTypeControl.value && !this.Editing && !this.EventTypeList.find((value) => value.IsDefault && value.Name === this.CustomEventTypeControl.value)) {
            const newCustomEventType = new EventTypeEntity(null, this.CustomEventTypeControl.value, false, this.CustomImagePath);
            this.store.dispatch(EventTypesActionTypes.AddEventType({ Payload: newCustomEventType }));
            this.store
                .select(getEventTypes)
                // erster *neuer* Wert (der newCustomEventType enthält)
                // ansonsten wird der neu erstellte EventTypeButton nicht gehighlighted
                .pipe(skip(1), first())
                .subscribe((eventTypes) => this.OnEventTypeClick(eventTypes.find((eventType) => eventType.Name === newCustomEventType.Name)));
        }
    }

    public OnDeleteButtonClick() {
        this.Deleting = !this.Deleting;
    }

    public OpenIconSelectDialog() {
        const dialogRef = this.dialog.open(IconSelectDialogComponent, {
            autoFocus: false,
            width: '90%',
            maxWidth: '800px',
            height: '90%',
            maxHeight: '800px',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result || result === null) {
                this.CustomImagePath = result;
            }
        });
    }
}
