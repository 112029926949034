<header class="headline">
    <h1 mat-dialog-title>Projektbeteiligte verwalten</h1>
    <button app-round-button matTooltip="neue Person zum Kontaktbuch hinzufügen" (click)="CreateNewPerson()">
        <fa-icon icon="plus"></fa-icon>
    </button>
</header>

<mat-dialog-content class="wrapper">
    <h3 *ngIf="(SelectedPersons$ | async).length !== 0">ausgewählte Personen:</h3>
    <mat-chip-list *ngIf="(SelectedPersons$ | async).length !== 0">
        <mat-chip
                class="example-box"
                *ngFor="let person of (SelectedPersons$ | async)">
            {{person.DisplayName}}
            <button (click)="RemoveSelectedPersons(person)">
                <fa-icon [icon]="'times-circle'"></fa-icon>
            </button>
        </mat-chip>
    </mat-chip-list>
    <h3>weitere Personen auswählen:</h3>
    <input
        type="text"
        class="search-field"
        placeholder="nach Person suchen"
        #searchString
        (keyup)="DoSearch(searchString.value)"
    />

    <cdk-virtual-scroll-viewport
    [tvsItemSize]="56"
    [headerHeight]="56"
    [bufferMultiplier]="2"
>
        <table mat-table matSort [dataSource]="DataSource$ | async" *ngIf="PersonColumns$ | async as PersonColumns">
            <ng-container *ngFor="let collumName of PersonAutoColumns" [matColumnDef]="collumName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    {{ PersonColumnHeaders ? PersonColumnHeaders[collumName] : '' }}
                </th>
                <td mat-cell *matCellDef="let rowData">
                    {{ rowData[collumName] }}
                </td>
            </ng-container>
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef style="padding: 0 1rem; width: 1rem">
                    <fa-icon icon="check-square"></fa-icon>
                </th>
                <td mat-cell *matCellDef="let rowData" style="padding: 0 1rem; width: 1rem">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="CheckBoxSelectPersons($event, rowData)"
                        [checked]="IsSelected(rowData)"
                        color="primary"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="PersonColumns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let rowData; columns: PersonColumns"
                (click)="RowClickSelectPersons(rowData);"
                [class.highlight]="SelectedRowIndex === rowData.Id"
            ></tr>
        </table>

        <table *ngIf="(DataSource$ | async)?.data?.length === 0" style="width: 100%">
            <tr>
                <td class="empty-table-text">
                    Keine Personen vorhanden
                </td>
            </tr>
        </table>
    </cdk-virtual-scroll-viewport>
</mat-dialog-content>
<mat-dialog-actions class="button-wrapper">
    <button app-button Inverted [mat-dialog-close]="null">Zurück</button>
    <button app-button (click)="Close()">{{ Dialogdata?.ButtonText }} speichern</button>
</mat-dialog-actions>
