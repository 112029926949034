import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    ResourceTypeState,
    RESOURCE_TYPE_KEY,
    selectAllResourceType,
    selectResourceTypeEntities,
    selectResourceTypeFetched, selectResourceTypeLatestUpdatedAt,
} from '../../reducers/resource-dispo/resource-type.reducer';

const selectState = createFeatureSelector<ResourceTypeState>(RESOURCE_TYPE_KEY);

export const getResourceTypeDictionary = createSelector(selectState, selectResourceTypeEntities);
export const getResourceTypes = createSelector(selectState, selectAllResourceType);
export const getResourceTypesFetched = createSelector(selectState, selectResourceTypeFetched);
export const getResourceTypeLatestUpdatedAt = createSelector(selectState, selectResourceTypeLatestUpdatedAt);

export const getResourceTypeById = (props: { id: number }) => createSelector(getResourceTypeDictionary, (dictionary) => props?.id && dictionary[props.id]);

