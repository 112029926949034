<ng-container *ngIf="Router.url !== '/login'; else loginHeader">
    <button *ngIf="NavBarButton" mat-icon-button (click)="NavBarOpenChange.emit(!NavBarOpen)">
        <fa-icon [icon]="NavBarOpen ? 'times' : 'bars'" class="mobile-nav-menu-button-icon"></fa-icon>
    </button>
    <app-breadcrumbs *ngIf="!(Mobile$ | async)"></app-breadcrumbs>
    <div class="app-title" *ngIf="!ShowSearchBar && (Mobile$ | async)">{{ AppTitle$ | async }}</div>

    <div class="spinner-wrapper" *ngIf="LS.isLoading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
    <div class="search-bar" [class.visible]="ShowSearchBar">
        <ng-container *ngIf="SearchReturn$ | async as s">
            <span class="search-count" *ngIf="!ShowSearchBar && s.TotalResults > 0">({{ s.TotalResults }})</span>
        </ng-container>
        <span *ngIf="!ShowSearchBar && !(Mobile$ | async)" (click)="ToggleSearchBar()">{{ProductName$ | async}} - Suche</span>
        <app-search-bar
            [visible]="ShowSearchBar"
            [SearchTypes]="SelectedTypes"
            (click)="ShowSearchReturn = true; $event.stopPropagation()"
            (Results)="SearchReturn$.next($event)"
            [Focus$]="Focus$"
        ></app-search-bar>
        <button
            class="search-button"
            mat-icon-button
            color="primary"
            matTooltip="Suchen"
            (click)="ToggleSearchBar()"
            [class.search]="!ShowSearchBar"
        >
            <fa-icon [icon]="ShowSearchBar ? 'chevron-right' : 'search'"></fa-icon>
        </button>
    </div>
    <!-- Konto: Abmelden -->
    <div class="button-wrapper" *ngIf="!(Mobile$ | async)">
        <app-logout
            class="logout"
            [ButtonText]="User.Firstname"
            *ngIf="(!ShowSearchBar || !(Mobile$ | async)) && User$ | async as User"
        ></app-logout>
    </div>
</ng-container>
<div
    class="search-result mat-elevation-z8"
    *ngIf="ShowSearchReturn && SearchReturn$ | async as Returns"
    [class.has-results]="ShowSearchBar && Returns.TotalResults"
>
        <app-search-return
            [Returns]="Returns"
            (selectedTypes)="SelectedTypes = $event"
            (clickOutside)="ShowSearchReturn = !!Returns.TotalResults"
        ></app-search-return>
</div>

<ng-template #loginHeader>
    <h3>Willkommen bei {{ProductName}}</h3>
</ng-template>
