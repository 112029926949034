import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveListCardModule } from '../dave-list-card/dave-list-card.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from "../dave-utils-module/app-filter-module/app-filter.module";
import { AutofocusModule } from '../directives/autofocus/autofocus.module';
import { BookingPositionListCardComponent } from './booking-position-list-card.component';

@NgModule({
    declarations: [BookingPositionListCardComponent],
    exports: [BookingPositionListCardComponent],
    imports: [
        CommonModule,
        DaveListCardModule,
        DaveDoubleIconModule,
        AppButtonModule,
        MatTooltipModule,
        FontAwesomeModule,
        MatSortModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        AutofocusModule,
        MatBadgeModule,
        AppFilterModule,
    ],
})
export class BookingPositionListCardModule {}
