<mat-form-field [appearance]="appearance">
    <mat-label>
        <fa-icon *ngIf="PlaceholderIcon as icon" [icon]="icon"></fa-icon>
        {{Placeholder}}
    </mat-label>
    <mat-chip-list [errorStateMatcher]="ErrorMatcher" #chipList [disabled]="IsDisabled$ | async" [required]="_required">
        <mat-chip (click)="EntryClick.emit(Option)" *ngFor="let Option of selectedOptions$ | async" (removed)="Remove(Option)">
            <span class="name-wrapper">{{ MapFn(Option) }}</span>
            <fa-icon icon="times" matChipRemove></fa-icon>
        </mat-chip>

        <input
            type="text"
            (blur)="chipList.updateErrorState()"
            [formControl]="InputForm"
            [matAutocomplete]="autocomplete"
            [matChipInputFor]="chipList"
            (matChipInputTokenEnd)="Add($event, autocomplete)"
            [matChipInputAddOnBlur]="ChipInputAddOnBlur"
            #input
            [appFilter]="OptionsWithoutSelected$ | async"
            [MapFn]="MapFn"
            #filter="appFilter"
            [matTooltip]="Tooltip"
        />
    </mat-chip-list>

    <mat-autocomplete (optionSelected)="Select($event, input)" #autocomplete>
        <div class="scrollbar">
            <mat-option
                (mouseover)="ChipInputAddOnBlur = false"
                (mouseleave)="ChipInputAddOnBlur = true"
                (click)="ChipInputAddOnBlur = true"
                *ngFor="let Option of filter.FilteredOptions$ | async"
                [value]="Option"
            >
                <ng-container *ngIf="OptionTemplate; else defaultTemplate" [ngTemplateOutlet]="OptionTemplate" [ngTemplateOutletContext]="{ $implicit: Option }"></ng-container>

                <ng-template #defaultTemplate>{{ MapFn(Option) }}</ng-template>
            </mat-option>
        </div>
    </mat-autocomplete>
    <mat-error *ngIf="errorMessage as error">{{ error }}</mat-error>
</mat-form-field>
