import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { GroupActionTypes } from '../State/actions/group.actions';
import { getGroupLatestUpdatedAt, getGroupsFetched } from '../State/selectors/group.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class GroupResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getGroupsFetched, getGroupLatestUpdatedAt, GroupActionTypes.Load, store);
    }
}
