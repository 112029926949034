import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  QMStatusState,
  QMStatusFeatureKey
} from "../reducers/qMStatus.reducer";
import { State } from "../index";

export const selectQMStatus = createFeatureSelector<
  QMStatusState
>(QMStatusFeatureKey);
export const getQMStatus = createSelector(
  selectQMStatus,
  data => data.QMStatus
);
export const getSingleQMStatus = createSelector(
  getQMStatus,
  (qMStatus: ReturnType<typeof getQMStatus>, Id: number) =>
    qMStatus ? qMStatus.find(e => e.Id === Id) : null
);
