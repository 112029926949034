import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { PartnerOfficeEntity, PartnerOfficeEntityFromBackend } from '../../entities/partnerOffice.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { PartnerOfficeActionTypes } from '../actions/partners.actions';
import { State } from '../index';
import { getPartnerOffices } from '../selectors/partners.selectors';

enum ErrorCodes {
    Add = 'Impressum Hinzufügen fehlgeschlagen',
    Load = 'Impressum Abrufen fehlgeschlagen',
    Modify = 'Impressum Bearbeiten fehlgeschlagen',
    Remove = 'Impressum Löschen fehlgeschlagen',
}

@Injectable()
export class PartnerOfficeEffects {

    AddPartnerOffice$ = createEffect(() => this.actions$.pipe(
        ofType(PartnerOfficeActionTypes.AddPartnerOffice),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                createPartnerOffice(
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'partnerId')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'name')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'email')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'street')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'postalCode')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'city')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'phonenumber')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'mobilenumber')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'faxNumber')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'bankname')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'bankLocation')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'bankPostalCode')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'bankSortCode')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'iban')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'bic')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'country')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'ustId')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'costCentre')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'mainOffice')}
                   ${stringifyIfNotNullOrUndefined(action.Payload, 'steuernummer')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'ceoName')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'registryCourt')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'commercialCourt')}
                ) {${PartnerOfficeEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                withLatestFrom(this.store$.select(getPartnerOffices)),
                map(([res, partnerOffice]) => {
                    return res && res.createPartnerOffice
                        ? PartnerOfficeActionTypes.UpdatePartnerOffices({
                              Payload: [...partnerOffice, PartnerOfficeEntityFromBackend(res.createPartnerOffice)],
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    LoadPartnerOffices$ = createEffect(() => this.actions$.pipe(
        ofType(PartnerOfficeActionTypes.GetPartnerOffices),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
            query {
                partnerOffice {${PartnerOfficeEntity.GqlFields}}
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                map(res =>
                    res && res.partnerOffice
                        ? PartnerOfficeActionTypes.UpdatePartnerOffices({
                              Payload: res.partnerOffice.map(val => PartnerOfficeEntityFromBackend(val)),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    ModifyPartnerOffice$ = createEffect(() => this.actions$.pipe(
        ofType(PartnerOfficeActionTypes.ModifyPartnerOffice),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                changePartnerOffice(
                    id: ${action.Payload.id}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'name')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'email')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'street')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'postalCode')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'city')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'phonenumber')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'mobilenumber')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'faxNumber')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'bankname')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'bankLocation')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'bankPostalCode')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'bankSortCode')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'iban')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'bic')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'country')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'ustId')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'costCentre')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'mainOffice')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'steuernummer')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'signature')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'ceoName')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'registryCourt')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'commercialCourt')}
                ) {${PartnerOfficeEntity.GqlFields}}
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                withLatestFrom(this.store$.select(getPartnerOffices)),
                map(([res, partnerOffices]) => {
                    if (res?.changePartnerOffice) {
                        return PartnerOfficeActionTypes.UpdatePartnerOffices({
                            Payload: [
                                ...partnerOffices.filter(p => p.Id !== res.changePartnerOffice.id),
                                PartnerOfficeEntityFromBackend(res.changePartnerOffice),
                            ],
                        });
                    } else {
                        return BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } });
                    }
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    DeletePartner$ = createEffect(() => this.actions$.pipe(
        ofType(PartnerOfficeActionTypes.DeletePartnerOffice),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                deletePartnerOffice(id: ${action.Payload.id})
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                withLatestFrom(this.store$.select(getPartnerOffices)),
                map(([res, partnerOffices]) =>
                    res && res.deletePartnerOffice
                        ? PartnerOfficeActionTypes.UpdatePartnerOffices({
                              Payload: partnerOffices.filter(p => p.Id !== action.Payload.id),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    constructor(
        private router: Router,
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
    ) {}
}
