import { QmStatusType } from '../graphql-types';

export class QMStatusEntity {
    constructor(
        public Id: number = null,
        public Name: string = null,
        public PartnerId: number = null,
    ) {
    }

    public Clone(override: QMStatusEntityAttributes = {}): QMStatusEntity {
        const attr = {...this, ...override};
        return Object.assign(new QMStatusEntity(), attr);
    }
}

export function QMStatusEntityFromBackend(res: QmStatusType): QMStatusEntity {
    return new QMStatusEntity(
        res.id,
        res.name,
        res.partnerId,
    );
}


export interface QMStatusEntityAttributes {
    Id?: number;
         Name?: string;
         PartnerId?: number ;
        }
