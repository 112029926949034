import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, CurrencyPipe, DatePipe, DecimalPipe, formatDate, KeyValuePipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResolveData } from '@angular/router';
import { GetTimestampFromTime } from '@dave/types/dist/convert';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment/moment';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject, switchMap } from 'rxjs';
import { filter, map, shareReplay, startWith, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { AccountsReceivableLedgerEntity, AccountsReceivableLedgerTypeEnum } from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { LedgerImportDocumentTypes } from '../../../dave-data-module/entities/ledger-import.entity';
import { MaterialListEntity } from '../../../dave-data-module/entities/material-list.entity';
import { MaterialToCommissionEntity } from '../../../dave-data-module/entities/material-to-commission.entity';
import { QuantityTypeNames } from '../../../dave-data-module/entities/quantity-type.entity';
import { ResourceScheduleEntity } from '../../../dave-data-module/entities/resource-dispo/resource-schedule.entity';
import { ResourceToCommissionEntity, WorkTypeEnum } from '../../../dave-data-module/entities/resource-dispo/resource-to-commission.entity';
import {
    AmountTypeEnum,
    AmountTypeEnumNames,
    ResourceEntity,
} from '../../../dave-data-module/entities/resource-dispo/resource.entity';
import { MaterialToCommissionResolver } from '../../../dave-data-module/guards/material-to-commission.resolver';
import { QuantityTypeResolver } from '../../../dave-data-module/guards/quantity-type.resolver';
import { ResourceScheduleResolver } from '../../../dave-data-module/guards/resource-dispo/resource-scheduler.resolver';
import { ResourceToCommissionResolver } from '../../../dave-data-module/guards/resource-dispo/resource-to-commission.resolver';
import { ResourceTypeResolver } from '../../../dave-data-module/guards/resource-dispo/resource-type.resolver';
import { ResourceResolver } from '../../../dave-data-module/guards/resource-dispo/resource.resolver';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { ArlDataService } from '../../../dave-data-module/services/arl-data.service';
import { State } from '../../../dave-data-module/State';
import { MaterialToCommissionActionTypes } from '../../../dave-data-module/State/actions/material-to-commission.actions';
import { ResourceToCommissionsActionTypes } from '../../../dave-data-module/State/actions/resource-dispo/resource-to-commission.actions';
import { QUANTITY_TYPE_KEY } from '../../../dave-data-module/State/reducers/accounting.reducer';
import { RESOURCE_SCHEDULE_KEY } from '../../../dave-data-module/State/reducers/resource-dispo/resource-schedule.reducer';
import { RESOURCE_TO_COMMISSION_KEY } from '../../../dave-data-module/State/reducers/resource-dispo/resource-to-commission.reducer';
import { RESOURCE_TYPE_KEY } from '../../../dave-data-module/State/reducers/resource-dispo/resource-type.reducer';
import { RESOURCE_KEY } from '../../../dave-data-module/State/reducers/resource-dispo/resource.reducer';
import { getAccountsReceivableLedgerDictionary, getAccountsReceivableLedgersFetched, getQuantityTypeDictionary, getQuantityTypes, getQuantityTypesFetched } from '../../../dave-data-module/State/selectors/accounting.selector';
import { getLedgerImports, getLedgerImportsFetched } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { getMaterialLists, getMaterialListsFetched } from '../../../dave-data-module/State/selectors/material-list.selector';
import { getMaterialDictionary, getMaterials, getMaterialsFetched } from '../../../dave-data-module/State/selectors/material.selector';
import { getResourceSchedules, getResourceSchedulesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource-schedule.selectors';
import { getResourceToCommission, getResourceToCommissionFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource-to-commission.selectors';
import { getResourceTypeDictionary, getResourceTypesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource-type.selectors';
import { getResourceDictionary, getResources, getResourcesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import { getMaterialToCommission, getMaterialToCommissionFetched } from '../../../dave-data-module/State/selectors/resource-to-commission.selector';
import { DaveDoubleIconModule } from '../../../dave-double-icon/dave-double-icon.module';
import { DaveListCardModule } from '../../../dave-list-card/dave-list-card.module';
import { DaveListModule } from '../../../dave-list/dave-list.module';
import { DaveLoadingModule } from '../../../dave-loading/dave-loading.module';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { IDetailListTemplateDataProperty } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { DaveSharedComponentsModule } from '../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { DetailListDialogReturn, DetailListTemplateDialogComponent, DetailListTemplateDialogData } from '../../../detail-list-template-dialog/components/detail-list-template-dialog.component';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, TableColumnConfig } from '../../../helper/helper';
import { ResourcePageMeta } from '../../../helper/page-metadata';
import { MaterialEntity } from '../../../dave-data-module/entities/material.entity';
import { MaterialResolver } from '../../../dave-data-module/guards/material.resolver';

export interface ResourceToCommissionPopupComponentDialogData {
    CommissionId: number;
}
export type performedWorksType = {
    entries: ResourceToCommissionEntity[] | MaterialToCommissionEntity[];
    quantityName: QuantityTypeNames;
    name: string;
    arlEntity: AccountsReceivableLedgerEntity;
    quantitySum: number;
    resEntity: ResourceScheduleEntity;
    matListEntity:  MaterialListEntity;
}
interface ResourceToCommissionData {
    ResourceName?: string;
    ResourceId?: number;
    MaterialId?: number;
    Amount?: number;
    TimeSpan?: string;
    startDate?: Date;
    QuantityType?: string;
    UpdatedAt?: string;
    Quantity?: number;
    CreatedAt?: string;
    QuantitySum?: number;
    tasks?: Tasks[];
}

interface Tasks {
    arlEntity?: AccountsReceivableLedgerEntity;
    resEntity?: ResourceScheduleEntity;
    matListEntity?: MaterialListEntity;
    resId?: number;
    matId?: number;
    name?: string;
    quantity?: number;
    quantityName?: string;
    quantitySum?: number;
    res2CommissionId?: number;
    mat2CommissionId?: number;
    QuantityTypeId?: number;
    Date?: Date;
    withTriangle?: boolean;
    entries?: ResourceToCommissionEntity[] | MaterialToCommissionEntity[] | { Date?: Date; Quantity?: number }[];
}

@Component({
    selector: 'app-resource-to-commission-popup',
    templateUrl: './resource-to-commission-popup.component.html',
    styleUrls: ['./resource-to-commission-popup.component.scss'],
    providers: [DecimalPipe],
    standalone: true,
    imports: [
        AppButtonModule,
        FontAwesomeModule,
        MatTabsModule,
        AsyncPipe,
        DaveDoubleIconModule,
        DaveListCardModule,
        MatTooltipModule,
        CurrencyPipe,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        DaveListModule,
        MatDialogModule,
        MatTableModule,
        NgIf,
        DaveLoadingModule,
        ScrollingModule,
        MatSortModule,
        NgForOf,
        TableVirtualScrollModule,
        DaveSharedComponentsModule,
        MatExpansionModule,
        DatePipe,
        KeyValuePipe,
        MatCardModule,
        NgTemplateOutlet,
        MatButtonModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatSelectModule,
    ],
})
export class ResourceToCommissionPopupComponent implements OnDestroy {
    protected title = 'Freie Arbeiten';
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        width: '50rem',
        maxHeight: '90vh',
    };
    public static readonly RequiredResolvers: ResolveData = {
        [RESOURCE_TO_COMMISSION_KEY]: ResourceToCommissionResolver,
        [RESOURCE_KEY]: ResourceResolver,
        [RESOURCE_SCHEDULE_KEY]: ResourceScheduleResolver,
        [QUANTITY_TYPE_KEY]: QuantityTypeResolver,
        [RESOURCE_TYPE_KEY]: ResourceTypeResolver,
    };
    private commissionId$ = new BehaviorSubject<number | null>(null);
    public ResourcePageMeta = ResourcePageMeta;

    @Input() set CommissionId(id: number) {
        this.commissionId$.next(id);
    }

    @Input() Expanded = true;
    @Input() Expandable = false;
    protected searchVisible = false;
    protected searchForm = new FormControl<string>('');
    @ViewChild('searchInput') searchInput: ElementRef;
    @ViewChild(MatSort) private matSort?: MatSort;
    private destroy$ = new Subject<void>();
    public freeWorkForm = new FormGroup({
        Date: new FormControl<Moment | null>(null, Validators.required),
        Description: new FormControl<string>(null, Validators.required),
        Resource: new FormControl<{ Id: number; optionLabel: string } | null>(null),
        Material: new FormControl<{ Id: number; optionLabel: string } | null>(null),
        Quantity: new FormControl<number>(null, Validators.required),
        QuantityType: new FormControl<{ Id: number; optionLabel: string } | null>(null),
    });

    public TableColumns: TableColumnConfig<ResourceToCommissionData>[] = [
        { header: 'Ressourcen', name: 'ResourceName' },
        { header: 'Einheit', name: 'QuantityType' },
        { header: 'Anzahl', name: 'Quantity' },
    ];
    public PerformedHoursColumns: Array<keyof ResourceToCommissionData> = ['ResourceName', 'Quantity', 'QuantityType'];

    public ResourceToCommission$ = this.commissionId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((commissionId) => this.store.select(getResourceToCommission).pipe(map((r2c) => r2c.filter((r2cEntity) => r2cEntity.CommissionId === commissionId && !r2cEntity.DeletedAt)))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public MaterialToCommission$ = this.commissionId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((commissionId) => this.store.select(getMaterialToCommission).pipe(map((m2c) => m2c.filter((materialToCommission) => materialToCommission.CommissionId === commissionId && !materialToCommission.DeletedAt)))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public performedWorks$: Observable<performedWorksType[]> = this.commissionId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((commissionId) =>
            combineLatest([
                combineLatest([
                    getFetched$(this.store, getLedgerImportsFetched, getLedgerImports).pipe(
                        switchMap((ledgerImports) => {
                            const filteredLedgerImports = ledgerImports.filter((l) => l.CommissionId === commissionId && l.DocumentType === LedgerImportDocumentTypes.Offer);
                            if (!filteredLedgerImports?.length) {
                                return of([] as AccountsReceivableLedgerEntity[][]);
                            }
                            return combineLatest(filteredLedgerImports.map((li) => this.arlDataService.getArlsFromLedgerImport$(li.Id)));
                        }),
                        map((arls) => arls.flat().filter((arl) => arl.Type === AccountsReceivableLedgerTypeEnum.Arl || arl.Type === AccountsReceivableLedgerTypeEnum.ResourceArl || arl.Type === AccountsReceivableLedgerTypeEnum.MaterialArl)),
                    ),
                    getFetched$(this.store, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgerDictionary),
                ]).pipe(
                    map(([filteredArls, arls]) => {
                        // valid arls :  alle Leistungspositionen aus dem Angebot , die nicht kalkuliert sind.  also immer die oberen Positionen

                        const validArls = filteredArls.filter(
                            (arl) =>
                                arls[arl.Id]?.Type !== AccountsReceivableLedgerTypeEnum.Group &&
                                arls[arl.ParentId]?.Type !== AccountsReceivableLedgerTypeEnum.Arl &&
                                arls[arl.ParentId]?.Type !== AccountsReceivableLedgerTypeEnum.ResourceArl &&
                                arls[arl.ParentId]?.Type !== AccountsReceivableLedgerTypeEnum.MaterialArl,
                        );
                        return validArls;
                    }),
                ),
                this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined)),
                this.ResourceToCommission$,
                this.MaterialToCommission$,
                combineLatest([
                    getFetched$(this.store, getResourceSchedulesFetched, getResourceSchedules),
                    getFetched$(this.store, getResourcesFetched, getResourceDictionary),
                    getFetched$(this.store, getResourceTypesFetched, getResourceTypeDictionary),
                ]).pipe(
                    map(([filteredSchedules, resources, resourceTypes]) =>
                        filteredSchedules.filter((schedule) => {
                            if (schedule.CommissionId !== commissionId) {
                                return false;
                            }
                            const resource = resources[schedule.ResourceId];
                            const resourceType = resource && resourceTypes[resource.ResourceTypeId];
                            return resourceType;
                        }),
                    ),
                ),
                getFetched$(this.store, getResourcesFetched, getResourceDictionary),
                combineLatest([getFetched$(this.store, getMaterialListsFetched, getMaterialLists), getFetched$(this.store, getMaterialsFetched, getMaterialDictionary)]).pipe(
                    map(([materialLists, materialListsDictionary]) =>
                        materialLists.filter((matList) => {
                            if (matList.CommissionId !== commissionId) {
                                return false;
                            }
                            const material = materialListsDictionary[matList?.MaterialId];
                            return material;
                        }),
                    ),
                ),
                getFetched$(this.store, getMaterialsFetched, getMaterialDictionary),
            ]),
        ),
        map(([ARLs, quantityTypes, resourcesToCommission, materialToCommission, scheduledResources, resourceDictionary, scheduledMaterials, materialDictionary]) => {
            const tasksFromResourceARLs = ARLs?.map<performedWorksType>((arlEntity) => {
                let entries: ResourceToCommissionEntity[] |  MaterialToCommissionEntity[];
                let quantitySum: number;
                const name = arlEntity.Information ? arlEntity.Information : '';
                const quantityName = quantityTypes.find((q) => q.Id === arlEntity.QuantityTypeId)?.Name;
                if (!arlEntity.MaterialId && arlEntity.ResourceId) {
                    entries = resourcesToCommission?.filter((r2c) => r2c.ResourceId === arlEntity.ResourceId);
                    quantitySum = entries.reduce((acc, val) => acc + val.Quantity, 0);
                } else if (arlEntity.MaterialId && !arlEntity.ResourceId) {
                    entries = materialToCommission?.filter((r2c) => r2c.MaterialId === arlEntity.MaterialId);
                    quantitySum = entries.reduce((acc, val) => acc + val.Value, 0);
                } else {
                    entries = [];
                    quantitySum = arlEntity.Quantity;
                }
                return {
                    arlEntity,
                    name,
                    quantityName,
                    quantitySum,
                    entries,
                    resEntity: null,
                    matListEntity: null,
                };
            });

            const tasksFromScheduledResources = scheduledResources?.map<performedWorksType>((resEntity) => {
                const resource = resourceDictionary[resEntity?.ResourceId];
                const entries = resourcesToCommission?.filter((r2c) => r2c.ResourceId === resEntity.ResourceId);
                const name = resource.Name ? resource.Name : '';
                const quantityName = resource ? quantityTypes.find((q) => q.Id === resource.QuantityTypeId)?.Name : null;
                const quantitySum = entries.reduce((acc, val) => acc + val.Quantity, 0);
                return {
                    resEntity,
                    name,
                    quantityName,
                    quantitySum,
                    entries,
                    arlEntity: null,
                    matListEntity: null,
                };
            });

            const tasksFromScheduledMaterials = scheduledMaterials?.filter((m)=> !m?.DeletedAt).map<performedWorksType>((matListEntity) => {

                const material = materialDictionary[matListEntity?.MaterialId];
                const entries = materialToCommission?.filter((mat2Comm) => mat2Comm?.MaterialId === matListEntity?.MaterialId);
                const name = material?.Name ? material?.Name : '';
                const quantityName = material ? quantityTypes.find((q) => q.Id === material?.QuantityTypeId)?.Name : null;
                const quantitySum = entries.reduce((acc, val) => acc + val.Value, 0);
                return {
                    matListEntity,
                    name,
                    quantityName,
                    quantitySum,
                    entries,
                    arlEntity: null,
                    resEntity: null,
                };
            });
            return [...tasksFromResourceARLs, ...tasksFromScheduledResources, ...tasksFromScheduledMaterials];

        }),
        switchMap((tableData) => {
            tableData = tableData.map((task) => {
                if (task.entries) {
                    task.entries.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());
                }
                return task;
            });
            return of(tableData);
        }),

        switchMap((tableData) =>
            this.searchForm.valueChanges.pipe(
                startWith(''),
                map(() => (this.searchForm.value || '').toLowerCase()),
                map((searchTerm) => {
                    if (searchTerm) {
                        return tableData.filter((data) => (data.name && data.name.trim().toLowerCase().includes(searchTerm)) || (data.name && data.name.trim().toLowerCase().includes(searchTerm)));
                    } else {
                        return tableData;
                    }
                }),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    // geleistete stunden: hier werden alle kalkulierten unterpositionen aufgelistet, die vom typ stunden oder mit der einheit min, h , std. sind. (Nur Geräte)
    public workedHours$ = this.commissionId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((commissionId) =>
            combineLatest([
                combineLatest([
                    getFetched$(this.store, getLedgerImportsFetched, getLedgerImports).pipe(
                        switchMap((ledgerImports) => {
                            const filteredLedgerImports = ledgerImports.filter((l) => l.CommissionId === commissionId && l.DocumentType === LedgerImportDocumentTypes.Offer);
                            if (!filteredLedgerImports?.length) {
                                return of([] as AccountsReceivableLedgerEntity[][]);
                            }
                            return combineLatest(filteredLedgerImports.map((li) => this.arlDataService.getArlsFromLedgerImport$(li.Id)));
                        }),
                        map((arls) => arls.flat().filter((arl) => arl.isBookingPosition && arl.ParentId)),
                    ),
                    getFetched$(this.store, getResourcesFetched, getResources),
                    getFetched$(this.store, getQuantityTypesFetched, getQuantityTypes),
                    getFetched$(this.store, getQuantityTypesFetched, getQuantityTypeDictionary),
                    getFetched$(this.store, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgerDictionary),
                ]).pipe(
                    map(([filteredArls, resources, quantityTypes, qTypeDec, arlsDec]) => {
                        const validResourceIds = resources.filter((res) => filteredArls.some((arl) => arl.ResourceId === res.Id && qTypeDec[arl.QuantityTypeId].Name === AmountTypeEnumNames.get(AmountTypeEnum.Std))).map((res) => res.Id);

                        const allowedQuantityTypes = [QuantityTypeNames.Hours, QuantityTypeNames.Minutes];
                        const allowedArlTypes = [AccountsReceivableLedgerTypeEnum.ResourceArl];

                        const validQuantityTypeIds = quantityTypes.filter((qt) => filteredArls.some((arl) => arl.QuantityTypeId === qt.Id && allowedQuantityTypes.includes(qTypeDec[arl.QuantityTypeId].Name))).map((qt) => qt.Id);

                        const ret = filteredArls.filter((arls) => validResourceIds.includes(arls.ResourceId) || validQuantityTypeIds.includes(arls.QuantityTypeId));
                        const validArls = ret.filter((arl) => allowedArlTypes.includes(arlsDec[arl.ParentId]?.Type));

                        return validArls;
                    }),
                ),
                getFetched$(this.store, getResourcesFetched, getResourceDictionary),
                this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined)),
                getFetched$(this.store, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgerDictionary),
                this.ResourceToCommission$,
                combineLatest([
                    getFetched$(this.store, getResourceSchedulesFetched, getResourceSchedules),
                    getFetched$(this.store, getResourcesFetched, getResourceDictionary),
                    getFetched$(this.store, getResourceTypesFetched, getResourceTypeDictionary),
                    getFetched$(this.store, getQuantityTypesFetched, getQuantityTypeDictionary),
                ]).pipe(
                    map(([filteredResourceSchedules, resources, resourceTypes, qTypeDictionary]) =>
                        filteredResourceSchedules.filter((schedule) => {
                            if (schedule.CommissionId !== commissionId) {
                                return false;
                            }
                            const resource = resources[schedule.ResourceId];
                            const resourceType = resource && resourceTypes[resource.ResourceTypeId];
                            return resourceType && qTypeDictionary[resource?.QuantityTypeId]?.Name === AmountTypeEnumNames.get(AmountTypeEnum.Std);
                        }),
                    ),
                ),
            ]),
        ),
        map(([resourceARLs, resources, quantityTypes, arlDictionary, resourceToCommission, resourceschedules]) => {
            const groupAndAggregateEntries = (entries) => {
                return entries.reduce((acc, entry) => {
                    const resourceName = entry.ResourceName;

                    if (!acc[resourceName]) {
                        acc[resourceName] = {
                            ResourceName: resourceName,
                            ResourceId: entry.ResourceId,
                            Quantity: 0,
                            QuantitySum: 0,
                            QuantityType: entry.QuantityType,
                            EarliestDate: entry.CreatedAt,
                            LatestDate: entry.CreatedAt,
                        };
                    }
                    acc[resourceName].Quantity += entry.Quantity || 0;
                    acc[resourceName].QuantitySum += entry.QuantitySum || 0;
                    return acc;
                }, {});
            };

            const resourceArlsEntries = resourceARLs.map<ResourceToCommissionData>((r) => {
                const ressource = resources[r.ResourceId];
                const validR2C = resourceToCommission.filter((r2c) => r2c.ResourceId === arlDictionary[r.ParentId]?.Id);
                const quantitySum = validR2C.reduce((acc, val) => acc + val.Quantity * r.Quantity, 0);
                return {
                    ResourceName: ressource ? (ressource?.Name ? ressource.Name : '') : r?.Information || '',
                    Quantity: r.Quantity ? r.Quantity * arlDictionary[r.ParentId].Quantity : null,
                    QuantitySum: quantitySum ? quantitySum : 0,
                    QuantityType: r.QuantityTypeId ? quantityTypes.find((q) => q.Id === r.QuantityTypeId)?.Name : '',
                };
            });

            const resourceSchedulesEntries = resourceschedules?.map<ResourceToCommissionData>((res) => {
                const resource = resources[res.ResourceId];
                const validR2C = resourceToCommission.filter((r2c) => r2c.ResourceId === res.Id);
                const quantitySum = validR2C.reduce((acc, val) => acc + val.Quantity, 0);

                return {
                    ResourceName: resource?.Name?.trim() || '',
                    ResourceId: resource ? resource.Id : null,
                    Quantity: res.Amount ? res.Amount : null,
                    QuantitySum: quantitySum ? quantitySum : 0,
                    QuantityType: resource ? quantityTypes.find((q) => q.Id === resource.QuantityTypeId)?.Name : null,
                    CreatedAt: formatDate(res.StartDate, 'shortDate', 'de-DE'),
                };
            });

            const allEntries = [...resourceArlsEntries, ...resourceSchedulesEntries];
            const groupedEntries = groupAndAggregateEntries(allEntries);

            const dataSourceEntries = Object.values(groupedEntries);

            return new MatTableDataSource(dataSourceEntries);
        }),
        tap((dataSource: MatTableDataSource<ResourceToCommissionData>) => {
            // return  dataSource.sortingDataAccessor = (object, key) => {
            //      switch (key) {
            //          case 'ResourceName':
            //              return `${object[key]}`.trim().toLowerCase();
            //          default:
            //              return object[key];
            //      }
            //  };
            if (this.matSort) {
                dataSource.sort = this.matSort;
            }
        }),
        switchMap((tableData: MatTableDataSource<ResourceToCommissionData>) =>
            this.searchForm.valueChanges.pipe(
                startWith(''),
                map(() => (this.searchForm.value || '').toLowerCase()),
                map((searchTerm) => {
                    if (searchTerm) {
                        const filteredData = tableData?.data?.filter((data) => data?.ResourceName?.trim().toLowerCase().includes(searchTerm));
                        return new MatTableDataSource(filteredData);
                    } else {
                        return tableData;
                    }
                }),
            ),
        ),

        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    public freeWorks$ = this.commissionId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((commissionId) =>
            combineLatest([
                this.store.select(getResourceToCommission).pipe(map((res2com) => res2com.filter((res) => res.CommissionId === commissionId && res.Type === WorkTypeEnum.FreeWork && !res.DeletedAt))),
                this.store.select(getMaterialToCommission).pipe(map((mat2Comm) => mat2Comm.filter((res) => res.CommissionId === commissionId && res.WorkType === WorkTypeEnum.FreeWork && !res.DeletedAt))),
                this.store.select(getQuantityTypes),
            ]).pipe(
                map(([freeWorksResources, freeWorkMaterials, quantityTypes]) => {
                    const tasksFromRessources = freeWorksResources.map<ResourceToCommissionData>((resourceToCommissionData) => {
                        const Date = formatDate(resourceToCommissionData.Date, 'shortDate', 'de-DE');
                        const ResourceName = resourceToCommissionData.Name;
                        const Quantity = resourceToCommissionData.Quantity;
                        const QuantityType = quantityTypes.find((q) => q.Id === resourceToCommissionData.QuantityTypeId)?.Name;
                        const res2CommissionId = resourceToCommissionData.Id;
                        const quantityTypeId = resourceToCommissionData.QuantityTypeId;
                        const resId = resourceToCommissionData?.ResourceId ? resourceToCommissionData.ResourceId : null;
                        const withTriangle = !!resId;
                        return {
                            Date,
                            ResourceName,
                            Quantity,
                            QuantityType,
                            res2CommissionId,
                            quantityTypeId,
                            resId,
                            withTriangle,
                        };
                    });
                    const tasksfromMaterials = freeWorkMaterials.map<ResourceToCommissionData>((materialToCommissionData) => {
                        const Date = formatDate(materialToCommissionData.Date, 'shortDate', 'de-DE');
                        const ResourceName = materialToCommissionData.Name;
                        const Quantity = materialToCommissionData.Value;
                        const QuantityType = quantityTypes.find((q) => q.Id === materialToCommissionData.QuantityTypeId)?.Name;
                        const mat2CommissionId = materialToCommissionData.Id;
                        const quantityTypeId = materialToCommissionData.QuantityTypeId;
                        const matId = materialToCommissionData?.MaterialId ? materialToCommissionData.MaterialId : null;
                        const withTriangle = !!matId;
                        return {
                            Date,
                            ResourceName,
                            Quantity,
                            QuantityType,
                            mat2CommissionId,
                            quantityTypeId,
                            matId,
                            withTriangle,
                        };
                    });
                    const allTasks = [...tasksFromRessources, ...tasksfromMaterials];
                    return {
                        allTasks,
                    };
                }),
            ),
        ),
        switchMap((tableData) =>
            this.searchForm.valueChanges.pipe(
                startWith(''),
                map(() => (this.searchForm.value || '').toLowerCase()),
                map((searchTerm) => {
                    let filteredTasks = tableData.allTasks;
                    if (searchTerm) {
                        filteredTasks = tableData.allTasks.filter((data) => data.ResourceName && data.ResourceName.trim().toLowerCase().includes(searchTerm));
                    }
                    return {
                        ...tableData,
                        tasks: filteredTasks || [],
                    };
                }),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    public ConsumedMaterials$: Observable<ResourceToCommissionData> = this.commissionId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((commissionId) =>
            combineLatest([
                combineLatest([
                    getFetched$(this.store, getLedgerImportsFetched, getLedgerImports).pipe(
                        switchMap((ledgerImports) => {
                            const filteredLedgerImports = ledgerImports.filter((l) => l.CommissionId === commissionId && l.DocumentType === LedgerImportDocumentTypes.Offer);
                            if (!filteredLedgerImports?.length) {
                                return of([] as AccountsReceivableLedgerEntity[][]);
                            }
                            return combineLatest(filteredLedgerImports.map((li) => this.arlDataService.getArlsFromLedgerImport$(li.Id)));
                        }),
                        map((arls) => arls.flat().filter((arl) => arl.MaterialId && arl.Type === AccountsReceivableLedgerTypeEnum.MaterialArl)),
                    ),
                    getFetched$(this.store, getMaterialsFetched, getMaterials),
                    //getFetched$(this.store, getResourceTypesFetched, getResourceTypeDictionary),
                ]).pipe(
                    map(([filteredArls, materials]) => {
                        const validMaterialIds = materials.filter((res) => filteredArls.some((arl) => arl.MaterialId === res.Id)).map((res) => res.Id);
                        const ret = filteredArls.filter((arl) => validMaterialIds.includes(arl.MaterialId));

                        return ret;
                    }),
                ),
                getFetched$(this.store, getMaterialsFetched, getMaterialDictionary),
                this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined)),
                getFetched$(this.store, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgerDictionary),
                this.MaterialToCommission$,
                //getFetched$(this.store, getResourceToCommissionFetched, getResourceToCommissionDictionary),
                combineLatest([
                    getFetched$(this.store, getMaterialListsFetched, getMaterialLists),
                    getFetched$(this.store, getMaterialsFetched, getMaterialDictionary),
                    //getFetched$(this.store, getResourceTypesFetched, getResourceTypeDictionary),
                ]).pipe(
                    map(([materialLists, materialsDictionary]) =>
                        materialLists.filter((material) => {
                            if (material.CommissionId !== commissionId) {
                                return false;
                            }
                            const MaterialEntity = materialsDictionary[material.MaterialId] && !material.DeletedAt;
                            //const resourceType = MaterialEntity && resourceTypes[resource.ResourceTypeId];
                            return MaterialEntity;
                        }),
                    ),
                ),
            ]),
        ),
        map(([materialARLs, materialsDictionary, quantityTypes, arlDictionary, mat2Comm, scheduledMaterials]) => {
            const groupAndAggregateTasks = (tasks: Tasks[]) => {
                return tasks.reduce((acc, task) => {
                    const name = task.name;

                    if (!acc[name]) {
                        acc[name] = {
                            name: name,
                            quantity: 0,
                            quantitySum: 0,
                            quantityName: task.quantityName,
                            entries: [],
                        };
                    }

                    acc[name].quantity += task.quantity || 0;
                    acc[name].quantitySum += task.quantitySum || 0;
                    acc[name].entries = acc[name].entries.concat(task.entries);

                    return acc;
                }, {});
            };

            const tasksFromMaterialARLs = materialARLs?.map((arl) => {
                const material = materialsDictionary[arl.MaterialId];
                const entries1 =
                    mat2Comm
                        ?.filter((m2c) => !m2c.DeletedAt && m2c.MaterialId === arl.ParentId)
                        .map((res) => ({
                            Date: res.Date,
                            Quantity: res?.Value * arl?.Quantity,
                        })) || [];

                const entries2 =
                    mat2Comm
                        ?.filter((m2c) => !m2c.DeletedAt && m2c.MaterialId === arl.Id)
                        .map((res) => ({
                            Date: res?.Date,
                            Quantity: res?.Value,
                        })) || [];

                const entries = [...entries1, ...entries2];
                const name = material ? material?.Name : arl?.Information || '';
                const quantityName = quantityTypes.find((q) => q.Id === arl.QuantityTypeId)?.Name;
                const quantitySum = entries.reduce((acc, val) => acc + val.Quantity, 0);
                const arlQuantitySum = arl.Quantity ? (arl.ParentId ? arl.Quantity * arlDictionary[arl.ParentId]?.Quantity : arl.Quantity) : null; // arlDictionary[arl.ParentId]?.Quantity * arl.Quantity;
                return {
                    arl,
                    name,
                    quantity: arlQuantitySum,
                    quantityName,
                    quantitySum,
                    entries,
                };
            });

            const tasksFromScheduledMaterials = scheduledMaterials?.map((scheduledMaterial) => {
                const material = materialsDictionary[scheduledMaterial.MaterialId];
                const entries = mat2Comm
                    ?.filter((m2c) => !m2c.DeletedAt && m2c.MaterialId === scheduledMaterial.Id)
                    .map((res) => {
                        return {
                            Date: res.Date,
                            Quantity: res.Value,
                        };
                    });
                const name = material ? material?.Name : '';
                const quantityName = material ? quantityTypes.find((q) => q.Id === material.QuantityTypeId)?.Name : null;
                const quantitySum = entries.reduce((acc, val) => acc + val.Quantity, 0);
                const scheduledQuantitySum = scheduledMaterial.Amount;
                return {
                    task: scheduledMaterial,
                    name,
                    quantity: scheduledQuantitySum,
                    quantityName,
                    quantitySum,
                    entries,
                };
            });
            const allTasks = [...tasksFromMaterialARLs, ...tasksFromScheduledMaterials];
            const groupedTasks = groupAndAggregateTasks(allTasks);
            const tasks = Object.values(groupedTasks);
            return {
                tasks: tasks,
            };
        }),
        switchMap((tableData) =>
            this.searchForm.valueChanges.pipe(
                startWith(''),
                map(() => (this.searchForm.value || '').toLowerCase()),
                map((searchTerm) => {
                    if (searchTerm) {
                        return {
                            ...tableData,
                            tasks: tableData.tasks.filter(
                                (data) => (data.name && data.name.trim().toLowerCase().includes(searchTerm)) || data.quantity.includes(searchTerm) || (data.quantityName && data.quantityName.trim().toLowerCase().includes(searchTerm)),
                            ),
                        };
                    } else {
                        return tableData;
                    }
                }),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) DialogData: ResourceToCommissionPopupComponentDialogData,
        @Optional() protected dialogRef: MatDialogRef<ResourceToCommissionPopupComponent>,
        private store: Store<State>,
        resourceResolver: ResourceResolver,
        private dialog: MatDialog,
        quantityTypeResolver: QuantityTypeResolver,
        resourceTypeResolver: ResourceTypeResolver,
        private arlDataService: ArlDataService,
        resourceToCommissionResolver: ResourceToCommissionResolver,
        materialToCommissionResolver: MaterialToCommissionResolver,
        materialResolver: MaterialResolver,
    ) {
        firstValueFrom(this.store.select(getQuantityTypesFetched)).then((fetched) => {
            if (!fetched) {
                quantityTypeResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getResourceToCommissionFetched)).then((fetched) => {
            if (!fetched) {
                resourceToCommissionResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getMaterialToCommissionFetched)).then((fetched) => {
            if (!fetched) {
                materialToCommissionResolver.resolve();
            }
        });

        firstValueFrom(this.store.select(getResourceTypesFetched)).then((fetched) => {
            if (!fetched) {
                resourceTypeResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getResourcesFetched)).then((fetched) => {
            if (!fetched) {
                resourceResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getMaterialsFetched)).then((fetched) => {
            if (!fetched) {
                materialResolver.resolve();
            }
        });
        if (DialogData) {
            this.CommissionId = DialogData.CommissionId;
        }
    }

    protected readonly PageMeta = ResourcePageMeta;

    onTabChange(): void {
        this.searchForm.reset();
        this.searchVisible = false;
    }

    toggleSearch() {
        this.searchVisible = !this.searchVisible;
        this.searchForm.setValue('');
        if (this.searchVisible) {
            setTimeout(() => {
                this.searchInput.nativeElement.focus();
            }, 0); // ensure it runs after the view updates
        }
    }

    OpenAddOrDeleteFreeWorkResourceToCommission(res2CommissonId?: null, edit?: boolean, resourceId?: number, materialId?: number, materialToCommissionId?: null) {
        firstValueFrom(
            combineLatest([
                this.store.select(getQuantityTypes),
                this.store.select(getResourceToCommission),
                this.store.select(getResources),
                this.store.select(getMaterialToCommission),
                //getFetched$(this.store , getMaterialToCommissionFetched , getMaterialToCommission),
                // this.store.select(getMaterials),
                getFetched$(this.store, getMaterialsFetched, getMaterialDictionary)
            ]),
        ).then(([quantityTypes, res2Comm, resources, mat2Comm, materials]) => {
            const resource = resources?.find((res) => res?.Id === resourceId);
            const material = materialId && materials[materialId];

            const resId = resource
                ? { Id: resource.Id, optionLabel: resource.DisplayName }
                : {
                      Id: null,
                      optionLabel: '',
                  };
            const matId = material
                ? { Id: material.Id, optionLabel: material.DisplayName }
                : {
                      Id: null,
                      optionLabel: '',
                  };

            const res2comm = res2Comm?.find((res) => res.Id === res2CommissonId);
            const material2Comm = mat2Comm?.find((mat) => mat?.Id === materialToCommissionId);
            const res2CommQuantityType = res2comm ? quantityTypes?.find((qtypes) => qtypes.Id === res2comm.QuantityTypeId) : null;
            const mat2CommQuantityType = material2Comm ? quantityTypes?.find((qtypes) => qtypes.Id === material2Comm.QuantityTypeId) : null;

            const res2commQType = res2CommQuantityType
                ? {
                      Id: res2CommQuantityType.Id,
                      optionLabel: res2CommQuantityType.Name,
                  }
                : { Id: null, optionLabel: '' };
            const mat2CommQType = mat2CommQuantityType
                ? {
                      Id: mat2CommQuantityType.Id,
                      optionLabel: mat2CommQuantityType.Name,
                  }
                : { Id: null, optionLabel: '' };

            this.freeWorkForm.reset({
                Date: edit ? moment(res2comm?.Date ? res2comm.Date : material2Comm?.Date ? material2Comm.Date : moment()) : moment(),
                Description: edit ? (res2comm?.Name ? res2comm.Name : material2Comm?.Name ? material2Comm.Name : null) : null,
                Resource: resourceId ? resId : null,
                Material: materialId ? matId : null,
                Quantity: edit ? (res2comm?.Quantity ? res2comm?.Quantity : material2Comm?.Value ? material2Comm?.Value : null) : null,
                QuantityType: edit ? (res2commQType ? res2commQType : mat2CommQType ? mat2CommQType : null) : null,
            });

            this.freeWorkForm.controls.Resource?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
                if (value?.Id) {
                    if (this.freeWorkForm.controls.Material.enabled) {
                        this.freeWorkForm.controls.Material.disable();
                    }
                } else {
                    if (this.freeWorkForm.controls.Material.disabled) {
                        this.freeWorkForm.controls.Material.enable();
                    }
                }
                const quantityType = this.freeWorkForm.controls.QuantityType?.value?.Id;
                if (!quantityType) {
                    const qtypeId = resources?.find((resource) => resource?.Id === value?.Id)?.QuantityTypeId;
                    const qtype = quantityTypes?.find((qtypes) => qtypes?.Id === qtypeId);
                    qtypeId !== undefined || null
                        ? this.freeWorkForm.controls.QuantityType.setValue({
                              Id: qtype?.Id,
                              optionLabel: qtype?.Name,
                          })
                        : res2commQType
                        ? res2commQType
                        : mat2CommQType;
                }
            });

            this.freeWorkForm.controls.Material?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
                if (value?.Id) {
                    if (this.freeWorkForm.controls.Resource.enabled) {
                        this.freeWorkForm.controls.Resource.disable();
                    }
                } else {
                    if (this.freeWorkForm.controls.Resource.disabled) {
                        this.freeWorkForm.controls.Resource.enable();
                    }
                }
                const quantityType = this.freeWorkForm.controls.QuantityType?.value?.Id;
                if (!quantityType) {
                    const qtypeId = value?.Id && materials[value?.Id]?.QuantityTypeId;
                    const qtype = quantityTypes?.find((qtypes) => qtypes?.Id === qtypeId);
                    qtypeId !== undefined || null
                        ? this.freeWorkForm.controls.QuantityType.setValue({
                              Id: qtype?.Id,
                              optionLabel: qtype?.Name,
                          })
                        : res2commQType
                        ? res2commQType
                        : mat2CommQType;
                }
            });

            const Properties: IDetailListTemplateDataProperty[] = [
                {
                    key: 'Datum',
                    formControl: this.freeWorkForm.controls.Date,
                    options: {
                        specialInput: {
                            date: true,
                        },
                    },
                },
                {
                    key: 'Bezeichnung',
                    formControl: this.freeWorkForm.controls.Description,
                },
                {
                    key: ResourceEntity.EntityName,
                    formControl: this.freeWorkForm.controls.Resource,
                    hideFormControl: !!materialToCommissionId && edit,
                    options: {
                        specialInput: {
                            singleSelectSearch: {
                                options: resources.map((res) => ({ Id: res.Id, optionLabel: res.DisplayName })),
                                compareOptions: (a, b) => a?.Id === b?.Id,
                            },
                        },
                    },
                },
                {
                    key: MaterialEntity.EntityName,
                    formControl: this.freeWorkForm.controls.Material,
                    hideFormControl: !!res2CommissonId && edit,
                    options: {
                        specialInput: {
                            singleSelectSearch: {
                                options: Object.values(materials).map((res) => ({ Id: res.Id, optionLabel: res.DisplayName })),
                                compareOptions: (a, b) => a?.Id === b?.Id,
                            },
                        },
                    },
                },
                {
                    key: 'Menge',
                    formControl: this.freeWorkForm.controls.Quantity,
                    options: {
                        specialInput: {
                            number: true,
                        },
                    },
                },
                {
                    key: 'Einheit',
                    formControl: this.freeWorkForm.controls.QuantityType,
                    options: {
                        specialInput: {
                            singleSelectSearch: {
                                options: quantityTypes.map((res) => ({ Id: res.Id, optionLabel: res.Name })),
                                compareOptions: (a, b) => a?.Id === b?.Id,
                            },
                        },
                    },
                },
            ].filter(isNotNullOrUndefined);
            this.dialog
                .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                    ...DetailListTemplateDialogComponent.DefaultConfig,
                    width: '25rem',
                    data: {
                        DisableSaveButton$: this.freeWorkForm.statusChanges.pipe(
                            startWith(this.freeWorkForm.status),
                            map((state) => state !== 'VALID'),
                        ),
                        Data: {
                            Headline: (res2comm || material2Comm) && edit ? (res2comm?.Name ? res2comm?.Name + ' bearbeiten' : material2Comm?.Name ? material2Comm?.Name + ' bearbeiten' : '') : ' Freie Arbeit melden',
                            Properties,
                        },
                        DeleteButton: !!edit,
                        Editing: true,
                    },
                })
                .afterClosed()
                .pipe(take(1), withLatestFrom(this.commissionId$))
                .subscribe(([retData, commissionId]) => {
                    if (retData) {
                        if (retData.Action === 'delete') {
                            if (res2CommissonId) {
                                this.store.dispatch(ResourceToCommissionsActionTypes.Delete({ Payload: res2CommissonId }));
                            }
                            if (materialToCommissionId) {
                                this.store.dispatch(
                                    MaterialToCommissionActionTypes.Delete({
                                        Payload: { Id: materialToCommissionId },
                                    }),
                                );
                            }
                        }
                        if (retData.Action === 'save' && edit === false) {
                            if (this.freeWorkForm.controls.Material?.value) {
                                this.store.dispatch(
                                    MaterialToCommissionActionTypes.Create({
                                        Payload: {
                                            CommissionId: commissionId?.toString(),
                                            MaterialId: this.freeWorkForm.value.Material?.Id ? this.freeWorkForm.value.Material?.Id.toString() : null,
                                            Date: GetTimestampFromTime(this.freeWorkForm.controls.Date.value?.toDate()) + '',
                                            Name: this.freeWorkForm.value?.Description,
                                            Value: this.freeWorkForm.controls.Quantity?.value,
                                            QuantityTypeId: this.freeWorkForm.controls.QuantityType.value?.Id ? this.freeWorkForm.controls.QuantityType.value.Id + '' : null,
                                            WorkType: WorkTypeEnum.FreeWork,
                                        },
                                    }),
                                );
                            } else {
                                this.store.dispatch(
                                    ResourceToCommissionsActionTypes.Create({
                                        Payload: {
                                            commissionId: commissionId,
                                            resourceId: this.freeWorkForm.value.Resource?.Id ? this.freeWorkForm.value.Resource?.Id : null,
                                            date: FrontendDate(this.freeWorkForm.controls.Date.value?.toDate(), true),
                                            name: this.freeWorkForm.value?.Description,
                                            value: this.freeWorkForm.controls.Quantity?.value,
                                            quantityTypeId: this.freeWorkForm.controls.QuantityType.value?.Id,
                                            workType: WorkTypeEnum.FreeWork,
                                        },
                                    }),
                                );
                            }
                        }
                        if (retData.Action === 'save' && edit === true) {
                            if (materialToCommissionId) {
                                this.store.dispatch(
                                    MaterialToCommissionActionTypes.Change({
                                        Payload: {
                                            Id: materialToCommissionId,
                                            CommissionId: commissionId?.toString(),
                                            MaterialId: this.freeWorkForm.value.Material?.Id ? this.freeWorkForm.value.Material?.Id.toString() : null,
                                            Date: GetTimestampFromTime(this.freeWorkForm.controls.Date.value?.toDate()) + '',
                                            Name: this.freeWorkForm.value?.Description,
                                            Value: this.freeWorkForm.controls.Quantity?.value,
                                            QuantityTypeId: this.freeWorkForm.controls.QuantityType.value?.Id ? this.freeWorkForm.controls.QuantityType.value.Id + '' : null,
                                            WorkType: WorkTypeEnum.FreeWork,
                                        },
                                    }),
                                );
                            } else {
                                this.store.dispatch(
                                    ResourceToCommissionsActionTypes.Change({
                                        Payload: {
                                            id: res2CommissonId,
                                            commissionId: commissionId,
                                            resourceId: this.freeWorkForm.controls.Resource.value?.Id ? this.freeWorkForm.value.Resource?.Id : null,
                                            date: FrontendDate(this.freeWorkForm.controls.Date.value?.toDate(), true),
                                            name: this.freeWorkForm.controls.Description?.value,
                                            value: this.freeWorkForm.controls.Quantity?.value,
                                            quantityTypeId: this.freeWorkForm.controls.QuantityType.value?.Id,
                                            workType: WorkTypeEnum.FreeWork,
                                        },
                                    }),
                                );
                            }
                        }
                    }
                });
        });
        this.freeWorkForm.enable();
        this.freeWorkForm.reset();
    }


    openEntityToCommissionDialog(quantityTypeName: string, date: Date = null, quantity: number = null, deleteBtn = false) {
        const Properties = [
            {
                key: 'Datum',
                formControl: new FormControl<Moment>(date ? moment(date) : moment()),
                options: {
                    specialInput: {
                        date: true,
                    },
                },
            },
            {
                formControl: new FormControl<number>(quantity || null),
                key: 'Menge',
                options: {
                    specialInput: {
                        number: true,
                    },
                    suffix: quantityTypeName,
                },
            },
        ];
        return this.dialog
            .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                ...DetailListTemplateDialogComponent.DefaultConfig,
                data: {
                    Data: {
                        Headline: 'anlegen',
                        Properties,
                    },
                    Editing: true,
                    DeleteButton: deleteBtn,
                },
                autoFocus: false,
            })
            .afterClosed()
    }
    addMaterialToCommission(quantityTypeName: string, materialId: number, commissionId: number) {
        this.openEntityToCommissionDialog(quantityTypeName).subscribe(ret => {
            if (ret.Action === 'save') {
                this.store.dispatch(MaterialToCommissionActionTypes.Create({
                    Payload: {
                        MaterialId: materialId.toString(),
                        CommissionId: commissionId.toString(),
                        Value: ret.DialogData.Data.Properties[1].formControl.value,
                        Date:ret.DialogData.Data.Properties[0].formControl.value ? GetTimestampFromTime(ret.DialogData.Data.Properties[0].formControl.value.toDate()) + '' : null,
                    },
                }));
            }
        })
    }
    addResourceToCommission(quantityTypeName: string, resourceId: number, commissionId: number) {
        this.openEntityToCommissionDialog(quantityTypeName).subscribe(ret => {
            if (ret.Action === 'save') {
                this.store.dispatch(ResourceToCommissionsActionTypes.Create({
                    Payload: {
                        resourceId,
                        commissionId,
                        value: ret.DialogData.Data.Properties[1].formControl.value,
                        date: ret.DialogData.Data.Properties[0].formControl.value ? FrontendDate(ret.DialogData.Data.Properties[0].formControl.value.toDate(), true) : null,
                    },
                }));
            }
        })
    }
    onAddPerformedWorkClick(performedWork: performedWorksType, event: MouseEvent) {
        event.stopPropagation();
        firstValueFrom(this.commissionId$).then(commissionId => {
            if (performedWork.matListEntity) {
                this.addMaterialToCommission(performedWork.quantityName, performedWork.matListEntity.MaterialId, commissionId);
            } else if (performedWork.resEntity) {
                this.addResourceToCommission(performedWork.quantityName, performedWork.resEntity.ResourceId, commissionId);
            } else if (performedWork.arlEntity.MaterialId) {
                this.addMaterialToCommission(performedWork.quantityName, performedWork.arlEntity.MaterialId, commissionId);
            } else if (performedWork.arlEntity.ResourceId) {
                this.addMaterialToCommission(performedWork.quantityName, performedWork.arlEntity.ResourceId, commissionId);
            } else {
                alert('broken')
            }
        })
    }
    onChangeResourceToCommissionClick(r2c: ResourceToCommissionEntity, quantityTypeName: string) {
        this.openEntityToCommissionDialog(quantityTypeName, r2c.Date, r2c.Quantity, true).subscribe(ret => {
            if (ret.Action === 'delete') {
                this.store.dispatch(ResourceToCommissionsActionTypes.Delete({ Payload: r2c.Id }));
            }
            if (ret.Action === 'save') {
                this.store.dispatch(
                    ResourceToCommissionsActionTypes.Change({
                        Payload: {
                            id: r2c.Id,
                            value: ret.DialogData.Data.Properties[1].formControl.value,
                            date: ret.DialogData.Data.Properties[0].formControl.value ? FrontendDate(ret.DialogData.Data.Properties[0].formControl.value.toDate(), true) : null,
                        },
                    }),
                );
            }
        })
    }
    onChangeMaterialToCommissionClick(m2c: MaterialToCommissionEntity, quantityTypeName: string) {
        this.openEntityToCommissionDialog(quantityTypeName, m2c.Date, m2c.Value, true).subscribe(ret => {
            if (ret.Action === 'delete') {
                this.store.dispatch(MaterialToCommissionActionTypes.Delete({ Payload: { Id: m2c.Id.toString() } }));
            }
            if (ret.Action === 'save') {
                this.store.dispatch(
                    MaterialToCommissionActionTypes.Change({
                        Payload: {
                            Id: m2c.Id.toString(),
                            Value: ret.DialogData.Data.Properties[1].formControl.value,
                            Date: ret.DialogData.Data.Properties[0].formControl.value ? GetTimestampFromTime(ret.DialogData.Data.Properties[0].formControl.value.toDate()) + '' : null,
                        },
                    }),
                );
            }
        })
    }
    OpenDetailResourceCommission(
        arl: AccountsReceivableLedgerEntity,
        resource: ResourceScheduleEntity,
        resourceToCommission: ResourceToCommissionEntity = null,
        material: MaterialListEntity,
        materialToCommission: MaterialToCommissionEntity = null,
        quantityTypeName: string,
        editing: boolean,
    ) {
        const Properties = [
            {
                key: 'Datum',
                formControl: new UntypedFormControl(moment(resourceToCommission ? resourceToCommission?.Date : materialToCommission ? materialToCommission?.Date : moment())),
                options: {
                    specialInput: {
                        date: true,
                    },
                },
            },
            {
                formControl: new UntypedFormControl(resourceToCommission?.Quantity ? resourceToCommission.Quantity : materialToCommission?.Value ? materialToCommission.Value : null),
                key: 'Menge',
                options: {
                    specialInput: {
                        number: true,
                    },
                    suffix: quantityTypeName,
                },
            },
        ];
        this.dialog
            .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                ...DetailListTemplateDialogComponent.DefaultConfig,
                data: {
                    Data: {
                        Headline: resourceToCommission || materialToCommission ? ' bearbeiten' : 'anlegen',
                        Properties,
                    },
                    Editing: true,
                    DeleteButton: !!resourceToCommission || !!materialToCommission,
                },
                autoFocus: false,
            })
            .afterClosed()
            .pipe(take(1), withLatestFrom(this.commissionId$))
            .subscribe(([ret, commissionId]) => {
                if (ret) {
                    if (ret.Action === 'delete') {
                        if (resourceToCommission?.ResourceId) {
                            this.store.dispatch(ResourceToCommissionsActionTypes.Delete({ Payload: resourceToCommission?.Id }));
                        }
                        if (materialToCommission?.MaterialId) {
                            this.store.dispatch(MaterialToCommissionActionTypes.Delete({ Payload: { Id: materialToCommission?.Id.toString() } }));
                        }
                    }
                    if (ret.Action === 'save') {
                        if (!editing) {
                            // todo das macht keinen sinn man darf die nicht einfach mit einer arlId anlegen
                            // if (arl) {
                            if (false) {
                                if (arl.Type === AccountsReceivableLedgerTypeEnum.ResourceArl || arl.Type === AccountsReceivableLedgerTypeEnum.Arl) {
                                    this.store.dispatch(
                                        ResourceToCommissionsActionTypes.Create({
                                            Payload: {
                                                resourceId: arl?.Id,
                                                commissionId: commissionId,
                                                value: ret.DialogData.Data.Properties[1].formControl.value,
                                                date: FrontendDate(ret.DialogData.Data.Properties[0].formControl.value.toDate(), true),
                                            },
                                        }),
                                    );
                                }
                                if (arl.Type === AccountsReceivableLedgerTypeEnum.MaterialArl) {
                                    this.store.dispatch(
                                        MaterialToCommissionActionTypes.Create({
                                            Payload: {
                                                MaterialId: arl?.Id.toString(),
                                                CommissionId: commissionId.toString(),
                                                Value: ret.DialogData.Data.Properties[1].formControl.value,
                                                Date: GetTimestampFromTime(ret.DialogData.Data.Properties[0].formControl.value.toDate()) + '',
                                            },
                                        }),
                                    );
                                }
                            } else {
                                if (resource) {
                                    this.store.dispatch(
                                        ResourceToCommissionsActionTypes.Create({
                                            Payload: {
                                                resourceId: resource?.Id, //todo falsche id
                                                commissionId: commissionId,
                                                value: ret.DialogData.Data.Properties[1].formControl.value,
                                                date: FrontendDate(ret.DialogData.Data.Properties[0].formControl.value.toDate(), true),
                                            },
                                        }),
                                    );
                                }
                                if (material) {
                                    this.store.dispatch(
                                        MaterialToCommissionActionTypes.Create({
                                            Payload: {
                                                MaterialId: material?.Id.toString(), //todo falsche id
                                                CommissionId: commissionId.toString(),
                                                Value: ret.DialogData.Data.Properties[1].formControl.value,
                                                Date: GetTimestampFromTime(ret.DialogData.Data.Properties[0].formControl.value.toDate()) + '',
                                            },
                                        }),
                                    );
                                }
                            }
                        } else {
                            if (resourceToCommission?.ResourceId) {
                                this.store.dispatch(
                                    ResourceToCommissionsActionTypes.Change({
                                        Payload: {
                                            id: resourceToCommission?.Id,
                                            value: ret.DialogData.Data.Properties[1].formControl.value,
                                            date: FrontendDate(ret.DialogData.Data.Properties[0].formControl.value.toDate(), true),
                                        },
                                    }),
                                );
                            }
                            if (materialToCommission?.MaterialId) {
                                this.store.dispatch(
                                    MaterialToCommissionActionTypes.Change({
                                        Payload: {
                                            Id: materialToCommission?.Id.toString(),
                                            Value: ret.DialogData.Data.Properties[1].formControl.value,
                                            Date: GetTimestampFromTime(ret.DialogData.Data.Properties[0].formControl.value.toDate()) + '',
                                        },
                                    }),
                                );
                            }
                        }
                    }
                }
            });
    }

    openDialog() {
        this.dialog.open<ResourceToCommissionPopupComponent, ResourceToCommissionPopupComponentDialogData>(ResourceToCommissionPopupComponent, {
            ...ResourceToCommissionPopupComponent.DefaultConfig,
            panelClass: 'no-padding-dialog',
            data: {
                CommissionId: this.commissionId$.value,
            },
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
