import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { hmrBootstrap } from './hmr';
import * as Sentry from '@sentry/angular-ivy';
import { registerLicense } from '@syncfusion/ej2-base';

if (window['env']['sentry_dsn']) {
    Sentry.init({
        dsn: window['env']['sentry_dsn'],
        environment: window["env"]["CI_COMMIT_BRANCH"],
        release: window["env"]["sentry_release"],
        autoSessionTracking: false,
        tracesSampleRate: 0
    });
}
if (window['env']['syncfusionLicenseKey']) {
    registerLicense(window['env']['syncfusionLicenseKey']);
}
if (environment.production) {
  enableProdMode();
    if (window) {
        window['consoleLog'] = console.log;
        window['consoleError'] = console.error;
        window['consoleDebug'] = console.debug;
        window['consoleWarn'] = console.warn;
    }
    if (localStorage.getItem('logs') === null) {
        console.log = console.debug = console.error = console.warn = () => {};
    }
}
const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  // @ts-ignore
  if (module.hot) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.log(environment);
    // @ts-ignore
    console.log(window?.env);
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap().catch(err => console.log(err));
}

// platformBrowserDynamic().bootstrapModule(AppModule)
//  .catch(err => console.error(err));

