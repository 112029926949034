
<app-dave-list-card
    style='max-height: 30rem'
    Headline="Rechnung für Abschlags/- Schlussrechnung auswählen"
    [Data]="LITableData$"
    [DisplayedCollums]="DisplayedColumns$ | async"
    [TableHeaderCollumsMap]="Headers"
    (EntryClicked$)="SelectedLiId = $event.entry.Id"
    [Selectable]="true"
    [Clickable]="true"
    [SelectedIndex]='0'>
</app-dave-list-card>
<mat-dialog-actions style='display: flex; justify-content: space-between'>
    <div class='cancel-button-wrapper'>
        <button app-button Inverted matDialogClose>Abbrechen</button>
    </div>
    <button app-button [matDialogClose]='SelectedLiId'>Speichern</button>
</mat-dialog-actions>
