import { Component,EventEmitter,Input,OnDestroy,OnInit,Output } from '@angular/core';
import { FormGroup, FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import { filter } from 'rxjs/operators';
import { SelectSearchOption } from '../../select-search/components/select-search/select-search.component';
import { SelectSearchData } from '../../select-search/components/select-search-legacy/select-search-legacy.component';
import { stringSearch } from '../../../helper/helper';
import { Moment } from 'moment';

export const FILTER_TYPE_TEXT: FilterOptionType = 'text';
export const FILTER_TYPE_SELECT: FilterOptionType = 'select';
export const FILTER_TYPE_MULTI_SELECT: FilterOptionType = 'multi-select';
export const FILTER_TYPE_SEARCH_MULTI_SELECT: FilterOptionType = 'search-multi-select';
export const FILTER_TYPE_BOOLEAN: FilterOptionType = 'boolean';
/**
 * needs form with from and to
 */
export const FILTER_TYPE_TIMESPAN: FilterOptionType = 'date';
export const FILTER_TYPE_SEARCH_SELECT: FilterOptionType = 'search-select';

export type FilterOptionType = 'text' | 'select' | 'boolean' | 'date' | 'multi-select' | 'search-select' | 'search-multi-select';

interface AppFilterSelectSearchData extends SelectSearchData, SelectSearchOption {

}
export interface IFilterTypeSearchMultiSelectValue<K = number | string> {
    id: K;
    label: string;
    // searchContent?: string;
}

export interface FilterOption<T = string> {
    Name: T;
    Label: string;
    Type: FilterOptionType;
    Icon?: IconProp | null;
    Values?: any[any];
}

@Component({
    selector: 'app-filter',
    templateUrl: './app-filter.component.html',
    styleUrls: ['./app-filter.component.scss'],
})
export class AppFilterComponent implements OnInit, OnDestroy {
    public FormGroup = new UntypedFormGroup({});
    public FormGroupForSearchSelect = new FormGroup<{ [key: string]: FormControl<AppFilterSelectSearchData> }>({});

    @Input()
    set Settings(filters: FilterOption[]) {
        this.$Settings.next(filters);
    }
    public $Settings: BehaviorSubject<FilterOption[]> = new BehaviorSubject([]);

    @Input()
    set Values(filters: any) {
        this.$Values.next(filters);
    }
    public $Values: BehaviorSubject<any> = new BehaviorSubject({});
    @Output() ValuesChange = new EventEmitter<any>();

    public FormControlIsOnInit = false;
    public SelectSearchValues = new Map<string, AppFilterSelectSearchData[]>();
    private subs: Subscription[] = [];
    ngOnInit(): void {
        this.subs.push(
            this.FormGroup.valueChanges.pipe(filter(() => !this.FormControlIsOnInit)).subscribe((v) => {
                if (JSON.stringify(v) !== JSON.stringify(this.$Values.getValue())) {
                    this.$Values.next(v);
                    this.ValuesChange.emit(v);
                }
                Object.keys(v).forEach((key) => {
                    if ((this.FormGroupForSearchSelect.get(key)?.value?.Id || v[key]) && this.FormGroupForSearchSelect.get(key)?.value?.Id + '' !== v[key] + '') {
                        this.FormGroupForSearchSelect.get(key)?.setValue(this.GetSelectSearchDataById(+(v[key] + ''), key));
                    }
                });
            }),
            this.FormGroupForSearchSelect.valueChanges.pipe(filter(() => !this.FormControlIsOnInit)).subscribe((v) => {
                Object.keys(v).forEach((key) => {
                    if ((this.FormGroup.get(key).value || v[key]?.Id) && this.FormGroup.get(key).value + '' !== v[key]?.Id + '') {
                        this.FormGroup.get(key).setValue(v[key] ? (v[key].Id ? v[key].Id : null) + '' : v[key]);
                    }
                });
            }),
            this.$Values.subscribe((v) => {
                Object.keys(v).forEach((key) => {
                    const c = this.FormGroup.get(key);
                    if (c != null) {
                        if (this.$Settings.value.find(s => s.Name === key)?.Type === FILTER_TYPE_SEARCH_SELECT && !Number.isInteger(+v[key])) {
                            console.error('filterValue for FILTER_TYPE_SEARCH_SELECT has to be number castable or null. set to null');
                            c.setValue(null);
                        } else {
                            c.setValue(v[key]);
                        }
                    }
                });
            }),
            this.$Settings.subscribe((settings) => {
                this.FormControlIsOnInit = true;
                this.SelectSearchValues = new Map();
                settings.forEach((s) => {
                    if (this.FormGroup.get(s.Name) === null || s.Type === FILTER_TYPE_SELECT || s.Type === FILTER_TYPE_SEARCH_SELECT) {
                        let c: AbstractControl = new UntypedFormControl(false);
                        if (s.Type === FILTER_TYPE_BOOLEAN) {
                            c = new FormControl(this.$Values.value[s.Name] || false, {nonNullable: true}); // for reset to the default value
                            // c.setValue(this.$Values.value[s.Name] || false);
                        }
                        if (s.Type === FILTER_TYPE_TEXT) {
                            c.setValue(this.$Values.value[s.Name] || '');
                        }
                        if (s.Type === FILTER_TYPE_SELECT || s.Type === FILTER_TYPE_SEARCH_SELECT) {
                            if (s.Values) {
                                if (s.Type === FILTER_TYPE_SEARCH_SELECT) {
                                    this.SelectSearchValues.set(s.Name, this.MapValuesToSelectSearchData(s.Values));
                                    const sc = new FormControl<AppFilterSelectSearchData | null>(this.GetSelectSearchDataById(Number.isInteger(+this.$Values.value[s.Name]) ? +this.$Values.value[s.Name] : null, s.Name));
                                    this.FormGroupForSearchSelect.addControl(s.Name, sc);
                                    c.setValue(Number.isInteger(+this.$Values.value[s.Name]) ? this.$Values.value[s.Name] : null);
                                } else {
                                    c.setValue(this.$Values.value[s.Name]);
                                }
                            }
                        }
                        if (s.Type === FILTER_TYPE_MULTI_SELECT) {
                            if (s.Values) {
                                c.setValue(this.$Values.value[s.Name] || []);
                            }
                        }
                        if (s.Type === FILTER_TYPE_SEARCH_MULTI_SELECT) {
                            if (s.Values) {
                                c.setValue(this.$Values.value[s.Name] || []);
                            }
                        }
                        if (s.Type === FILTER_TYPE_TIMESPAN) {
                            c = new FormGroup({
                                from: new FormControl<Moment>(this.$Values.value[s.Name]?.from || null),
                                to: new FormControl<Moment>(this.$Values.value[s.Name]?.to || null),
                            });
                        }
                        this.FormGroup.addControl(s.Name, c);
                    }
                });
                this.FormControlIsOnInit = false;
            }),
        );
    }
    CompareSelectSearchOptions(a: AppFilterSelectSearchData, b: AppFilterSelectSearchData) {
        return a.Id === b.Id;
    }
    SelectSearchOptionsFunction(search: string, option: AppFilterSelectSearchData) {
        return stringSearch(option.Name, search) || stringSearch(option.SearchContent || '', search)
    }
    public MapValuesToSelectSearchData(Values: any[any]): AppFilterSelectSearchData[] {
        return Object.keys(Values)
            .map((key) => ({
                Id: +key,
                Name: Values[key],
                optionLabel: Values[key],
            }))
            .slice()
            .sort((a, b) => ('' + a.Name).localeCompare(b.Name));
    }
    public GetSelectSearchDataById(id: number, name: string): AppFilterSelectSearchData | null {
        // return this.SelectSearchValues.get(name).find(o => o.optionId === id);
        if (!id) {
            return null;
        }
        return { Id: id, Name: '', SearchContent: '', optionLabel: '' };
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
    public chipAutocompleteMapFunction = (option: IFilterTypeSearchMultiSelectValue) => option.label;
    public ResetValues() {
        this.FormGroup.reset();
        this.FormGroupForSearchSelect.reset();
    }
}
