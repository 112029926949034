import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    RESOURCE_TO_COMMISSION_KEY,
    ResourceToCommissionState,
    selectAllResourceToCommission,
    selectResourceToCommissionEntities,
    selectResourceToCommissionFetched, selectResourceToCommissionLatestUpdatedAt,
} from '../../reducers/resource-dispo/resource-to-commission.reducer';
import { selectResourceScheduleLatestUpdatedAt } from '../../reducers/resource-dispo/resource-schedule.reducer';

const selectState = createFeatureSelector<ResourceToCommissionState>(RESOURCE_TO_COMMISSION_KEY);

export const getResourceToCommissionDictionary = createSelector(selectState, selectResourceToCommissionEntities);
export const getResourceToCommission = createSelector(selectState, selectAllResourceToCommission);
export const getResourceToCommissionFetched = createSelector(selectState, selectResourceToCommissionFetched);
export const getResourceToCommissionLatestUpdatedAt = createSelector(selectState, selectResourceToCommissionLatestUpdatedAt);

export const getResourceToCommissionById = (props: { id: number }) => createSelector(getResourceToCommissionDictionary, (dictionary) => props?.id && dictionary[props.id]);
