import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { JobSpecificationEntity } from '../../entities/job-specification.entity';
import { JobSpecificationActionTypes } from '../actions/job-specification.action';

export interface JobSpecificationState extends EntityState<JobSpecificationEntity> {
    fetched: boolean;
}

export const JOB_SPECIFICATION_KEY = 'jobSpecification';

const adapter = createEntityAdapter<JobSpecificationEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const jobSpecificationReducer = createReducer(
    initialState,
    on(JobSpecificationActionTypes.UpdateAll, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
    on(JobSpecificationActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllJobSpecification = selectAll;
export const selectjobSpecificationEntities = selectEntities;
export const selectJobSpecificationFetched = (state: JobSpecificationState) => state.fetched;
