import { EmployeeResourceScheduleType } from '../../graphql-types';
import { BackendDate } from '../../helper/backend-frontend-conversion.helper';
import { ColorEntity, ColorEntityFromBackend, ColorEntityFromColorType } from '../color.entity';

export class EmployeeResourceScheduleEntity {
    constructor(
        public Id: number = null,
        public Amount: number = null,
        public StartDate: Date = null,
        public EndDate: Date = null,
        public UpdatedAt: Date = null,
        public CommissionId: number = null,
        public JobSpecificationId: number = null,
        public Name: string = null,
        public Color: ColorEntity = null,
        public ActivityIds: number[] = null,
    ) {}
    public static readonly GqlFields: Array<keyof EmployeeResourceScheduleType | string> = [
        'id',
        'amount',
        'startDate',
        'endDate',
        'updatedAt',
        'commissionId',
        'jobSpecificationId',
        'name',
        'color { red green blue opacity }',
        'activityIds',
        ];
    public Clone(override: Partial<EmployeeResourceScheduleEntity> = {}): EmployeeResourceScheduleEntity {
        const attr = { ...this, ...override };
        return Object.assign(new EmployeeResourceScheduleEntity(), attr);
    }
}

export function EmployeeResourceScheduleEntityFromBackend(res: EmployeeResourceScheduleType): EmployeeResourceScheduleEntity {
    return new EmployeeResourceScheduleEntity(
        res.id,
        res.amount,
        res.startDate && BackendDate(res.startDate),
        res.endDate && BackendDate(res.endDate),
        BackendDate(res.updatedAt),
        res.commissionId,
        res.jobSpecificationId,
        res.name,
        res.color && ColorEntityFromBackend(res.color),
        res.activityIds,
    );
}
