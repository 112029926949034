import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ConstructionDiaryEntity } from '../../entities/construction-diary.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { ConstructionDiaryActionTypes } from '../actions/construction-diary.actions';

export interface ConstructionDiaryState extends EntityState<ConstructionDiaryEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

export const CONSTRUCTION_DIARY_KEY = 'constructionDiary';

const adapter = createEntityAdapter<ConstructionDiaryEntity>({
    selectId: (Entity) => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const constructionDiaryReducer = createReducer(
    initialState,
    on(ConstructionDiaryActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(ConstructionDiaryActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(ConstructionDiaryActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllConstructionDiaries = selectAll;
export const selectConstructionDiaryEntities = selectEntities;
export const selectConstructionDiaryLatestUpdatedAt = (state: ConstructionDiaryState) => state.latestUpdatedAt;

export const selectConstructionDiariesFetched = (state: ConstructionDiaryState) => state.fetched;
