import { createAction, props } from '@ngrx/store';
import { PublicFileEntity } from '../../entities/public-file.entity';

const prefix = 'data/' + 'public-file/';
export type PublicFileUploadParams = Map<'entity_id' | 'type' | 'additional_data' | 'name' | 'file_id', string>
export const PublicFileActionTypes = {
    Load: createAction(prefix + 'load', props<{ Payload: { deleted?: boolean; updatedSince?: string } }>()),
    Upload: createAction(prefix + 'upload', props<{ Payload: { file: Blob; params: PublicFileUploadParams } }>()),

    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<PublicFileEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<PublicFileEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction(prefix + 'updateOne', props<{ Payload: PublicFileEntity }>()),
};
