import { createReducer, on } from '@ngrx/store';
import { GeneratedDocumentsActionTypes } from '../actions/generatedDocuments.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { GeneratedDocumentsEntity } from '../../entities/generatedDocuments.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';


export const generatedDocumentsFeatureKey = 'generatedDocuments';
export interface GeneratedDocumentState extends EntityState<GeneratedDocumentsEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<GeneratedDocumentsEntity>({
    selectId: Entity => Entity.Id,
});

const initialState: GeneratedDocumentState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const GeneratedDocumentsReducer = createReducer(
    initialState,
    on(GeneratedDocumentsActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(GeneratedDocumentsActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(GeneratedDocumentsActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(GeneratedDocumentsActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, { ...state })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllGeneratedDocument = selectAll;
export const selectGeneratedDocumentEntities = selectEntities;

export const selectGeneratedDocumentFetched = (state: GeneratedDocumentState) => state.fetched;
export const selectGeneratedDocumentLatestUpdatedAt = (state: GeneratedDocumentState) => state.latestUpdatedAt;
