import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProcessTemplateState, PROCESS_TEMPLATE_FEATURE_KEY, selectAllProcessTemplate, selectProcessTemplateEntities, selectProcessTemplateFetched, selectProcessTemplateLatestUpdatedAt } from '../reducers/process-template.reducer';

const selectState = createFeatureSelector<ProcessTemplateState>(PROCESS_TEMPLATE_FEATURE_KEY);
export const getProcessTemplateDictionary = createSelector(selectState, selectProcessTemplateEntities);
export const getProcessTemplateLatestUpdatedAt = createSelector(selectState, selectProcessTemplateLatestUpdatedAt);
export const getProcessTemplateFetched = createSelector(selectState, selectProcessTemplateFetched);
export const getProcessTemplates = createSelector(selectState, selectAllProcessTemplate);
export const getProcessTemplateActive = createSelector(getProcessTemplates, (activities) => activities.filter((processTemplate) => !processTemplate.DeletedAt));
export const getProcessTemplateById = (props: { id: number }) => createSelector(getProcessTemplateDictionary, (dictionary) => props.id && dictionary[props.id]);
