import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TextTemplateLanguageEntity } from '../../entities/text-template-language.entity';
import { TextTemplateLanguageActionTypes } from '../actions/text-template-language.actions';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';


export const textTemplateLanguageFeatureKey = 'textTemplateLanguage';

export interface TextTemplateLanguageState extends EntityState<TextTemplateLanguageEntity> {

    fetched: boolean;
    latestUpdatedAt: Date | null;
}
const adapter = createEntityAdapter<TextTemplateLanguageEntity>({
    selectId: Entity => Entity.Id,
});

export const initialState: TextTemplateLanguageState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const textTemplateLanguageReducer = createReducer(
    initialState,
    on(TextTemplateLanguageActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllTextTemplateLanguage = selectAll;
export const selectTextTemplateLanguageEntities = selectEntities;

export const selectTextTemplateLanguageFetched = (state: TextTemplateLanguageState) => state.fetched;
export const selectTextTemplateLanguageLatestUpdatedAt = (state: TextTemplateLanguageState) => state.latestUpdatedAt;
