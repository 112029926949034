import {createAction, props} from '@ngrx/store';
import { DaveMutationChangePasswordArgs } from '../../graphql-types';

export const BaseActionTypes = {
    PerformLogin: createAction(
        'login/post',
        props<{ Payload: { Username: string; Password: string } }>()
    ),
    CheckToken: createAction(
        'login/check'
    ),
    UpdateToken: createAction(
        'login/update',
        props<{ Payload: string }>()
    ),
    ChangePassword: createAction(
        'user/password/modify',
        props<{ Payload: DaveMutationChangePasswordArgs }>()
    ),
    ResetPassword: createAction(
        'user/password/reset',
        props<{ Payload: any }>()
    ),
    ActivateUser: createAction(
        'user/user/activate',
        props<{ Payload: any }>()
    ),
    InitStore: createAction(
        'data/init'
    ),
    ErrorAction: createAction(
        'error',
        props<{
            Payload: {
                ToasterMessage: string,
                Err?: any,
                Caught?: any
            }
        }>()
    )
};
