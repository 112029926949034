import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CommissionTypeEntity } from '../../entities/commissionType.entity';
import { CommissionTypeActionTypes } from '../actions/commissionType.actions';

export const CommissionTypesFeatureKey = 'commissionTypes';

export interface CommissionTypeState extends EntityState<CommissionTypeEntity> {
    fetched: boolean;
}
const adapter = createEntityAdapter<CommissionTypeEntity>({
    selectId: (Entity) => Entity.Id,
});
const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const CommissionTypeReducer = createReducer(
    initialState,
    on(CommissionTypeActionTypes.UpdateCommissionType, (state, { Payload }) =>
        adapter.setAll(Payload, {
            ...state,
            fetched: true,
        }),
    ),
);


/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllCommissionType = selectAll;
export const selectCommissionTypeEntities = selectEntities;
export const selectCommissionTypeFetched = (state: CommissionTypeState) => state.fetched;
