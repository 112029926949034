import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommentEntityTypeEnum } from '../../entities/comment.entity';
import {
    CommentState,
    COMMENTS_FEATURE_KEY,
    getKeyForMap,
    selectAllComment,
    selectCommentEntities,
    selectCommentEntityMap,
    selectCommentFetched,
    selectCommentLatestUpdatedAt,
} from '../reducers/comment.reducer';

const selectState = createFeatureSelector<CommentState>(COMMENTS_FEATURE_KEY);
const selectEntities = createSelector(selectState, selectCommentEntities);
const selectMap = createSelector(selectState, selectCommentEntityMap);
export const getCommentLatestUpdatedAt = createSelector(selectState, selectCommentLatestUpdatedAt);
export const getCommentFetched = createSelector(selectState, selectCommentFetched);

export const getComments = createSelector(selectState, selectAllComment);
export const getCommentById = (props: { id: number }) => createSelector(selectEntities, (dictionary) => props.id && dictionary[props.id]);
export const getCommentByEntityTypeEntityId = (props: { entityId: number; entityType: CommentEntityTypeEnum }) =>
    createSelector(selectMap, selectEntities, (map, dictionary) => map[getKeyForMap({ EntityType: props.entityType, EntityId: props.entityId })]?.map((id) => dictionary[id]) || []);
