import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    ActivityState,
    selectAllActivity,
    selectActivityEntities,
    selectActivityFetched,
    ACTIVITY_FEATURE_KEY,
} from '../reducers/activity.reducer';

const selectState = createFeatureSelector<ActivityState>(ACTIVITY_FEATURE_KEY);
export const getActivityDictionary = createSelector(selectState, selectActivityEntities);
// export const getActivityLatestUpdatedAt = createSelector(selectState, selectActivityLatestUpdatedAt);
export const getActivityFetched = createSelector(selectState, selectActivityFetched);
export const getActivities = createSelector(selectState, selectAllActivity);
export const getActivitiesActive = createSelector(getActivities, (activities) => activities.filter((activity) => !activity.DeletedAt));
export const getActivityById = (props: { id: number }) => createSelector(getActivityDictionary, (dictionary) => props.id && dictionary[props.id]);
