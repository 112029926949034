import {createReducer, on} from '@ngrx/store';
import {TransmissionEntity} from '../../entities/transmission.entity';
import {TransmissionActionTypes} from '../actions/transmission.actions';


export const transmissionFeatureKey = 'transmission';

export interface TransmissionState {
    transmission: TransmissionEntity[];
}

export const initialState: TransmissionState = {
    transmission: null
};

export const TransmissionReducer = createReducer(
    initialState,
    on(TransmissionActionTypes.UpdateTransmission, (state, {Payload}) => ({
        ...state,
        transmission: Payload
    }))
);
