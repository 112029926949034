import {createReducer, on} from '@ngrx/store';
import {ProductEntity} from '../../entities/product.entity';
import {ProductActionTypes} from '../actions/product.actions';


export const productsFeatureKey = 'products';

export interface ProductsState {
    products: ProductEntity[];
    fetched: boolean;
}

export const initialProductsState: ProductsState = {
    products: null,
    fetched: false,
};


export const productsReducer = createReducer(
    initialProductsState,
    on(ProductActionTypes.GetProducts, (state, {Payload}) => ({
        ...state,
        products: Payload,
        fetched: true,
    }))
);

