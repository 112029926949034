import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EntityType, GetTimestampFromTime } from "@dave/types";
import { Store } from '@ngrx/store';
import { firstValueFrom, from, of, scan, Subject, switchMap, tap } from "rxjs";
import { ChangeEntity, ChangeEntityFromBackend } from '../entities/change.entity';
import { State } from '../State';
import { getUser } from '../State/selectors/users.selectors';
import { AppGatewayService } from './app-gateway.service';
import { getProcessById } from "../State/selectors/process.selector";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { getCommissionById } from "../State/selectors/commission.selector";

@Injectable({
    providedIn: 'root',
})
export class ChangesDataService {
    lastSeenAction = new Subject<{lastSeen: Date, changeIds: number[]}>()
    constructor(protected router: Router, protected store: Store<State>, private api: AppGatewayService) {}

    private changesRequest(entityType: EntityType, entityId: number) {
        return from(this.api
            .Request({
                ChangeGet: {
                    UpdatedAt: undefined,
                    Entities: [{ Type: entityType, Ids: [entityId.toString()] }],
                    Limit: undefined,
                    Page: undefined,
                    WithExtracted: true,
                },
            })).pipe(
                map((res) => {
                    return res.ChangeGet.Changes.map(ChangeEntityFromBackend);
                }),
                switchMap(changes => this.lastSeenAction.pipe(
                    filter(changeAction => changes.some(c => changeAction.changeIds.includes(c.Id))),
                    scan((acc, curr) => acc.map(c => curr.changeIds.includes(c.Id) ? c.Clone({LastSeenAt: curr.lastSeen}) : c), changes),
                    startWith(changes),
                )),
            );
    }
    getProcessChanges$(processId: number): Observable<ChangeEntity[]> {
        return this.store.select(getProcessById({id: processId})).pipe(
            map(p => p?.UpdatedAt.getTime()),
            distinctUntilChanged(),
            switchMap(() => this.changesRequest(EntityType.Process, processId))
        );
    }
    getCommissionChanges$(commissionId: number): Observable<ChangeEntity[]> {
        return this.store.select(getCommissionById({id: commissionId})).pipe(
            map(p => p?.UpdatedAt.getTime()),
            distinctUntilChanged(),
            switchMap(() => this.changesRequest(EntityType.Commission, commissionId))
        );
    }
    setChangesSeen(ids: number[], lastSeenAt: Date = new Date()) {
        if (ids?.length) {
            firstValueFrom(this.store.select(getUser)).then((user) =>
                this.api.Request({
                    ChangeSetLastSeen: {
                        ChangeIds: ids.map((id) => id.toString()),
                        UserId: user.Id.toString(),
                        LastSeenAt: GetTimestampFromTime(lastSeenAt).toString(),
                    },
                }).then(res => {
                    if (res.ChangeSetLastSeen && Object.keys(res.Errors).length === 0) {
                        this.lastSeenAction.next({lastSeen: lastSeenAt, changeIds: ids})
                    }
                }),
            );
        }
    }
    setAllChangesFromEntitySeen(entityId: number, entityType: EntityType) {
        if (entityId && entityType) {
            firstValueFrom(this.store.select(getUser)).then((user) =>
                this.api.Request({
                    ChangeSetAllChangesLastSeen: {
                        Entity: entityType,
                        EntityIds: [entityId.toString()],
                        UserId: user.Id.toString(),
                    },
                }),
            );
        }
    }
}
