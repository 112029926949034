import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, scan, shareReplay, startWith, withLatestFrom } from 'rxjs/operators';
import { PartnerEntity, PartnerTypeEnum } from './dave-data-module/entities/partner.entity';
import { State } from './dave-data-module/State';
import { BaseActionTypes } from './dave-data-module/State/actions/base.actions';
import { getPartner } from './dave-data-module/State/selectors/partners.selectors';
import { getSetting } from './dave-data-module/State/selectors/users.selectors';
import { TranslationObject } from './dave-document-editor-module/components/document-editor/syncfusion-translate';
import { RoutingHistoryService } from './dave-utils-module/dave-shared-components-module/services/routing-history.service';
import { LoginMeta } from './helper/page-metadata';
import { DashboardItemsData } from './mocks/dashboard-items-data';
import { SessionService } from './services/session.service';

export const KAGETEC_PARTNERTYPE_ID = PartnerTypeEnum.Kagetec;
export const ZERT_PARTNERTYPE_ID = PartnerTypeEnum.Zert;
export const OBLIGO_PARTNERTYPE_ID = PartnerTypeEnum.Obligo;
export const DAVE_CC_PARTNERTYPE_ID = PartnerTypeEnum.Dave;

const ThemePrimaryColours = {
    default: '#f39325',
    'obligo-theme': '#ff7409',
    'zert-theme': '#a3a3a3',
    'kagetec-theme': '#008639',
    'history-theme': '#03a9f4',
    'admin-theme': '#558b2f',
    'stats-theme': '#3949ab',
    'settings-theme': '#757575',
    'partner-theme': '#43a047',
    'user-theme': '#5e35b1',
    'commission-theme': '#8caaaf',
    'docs-theme': '#546e7a',
    'dms-theme': '#e53935',
    'video-theme': '#00897b',
    'email-theme': '#f0b745',
    'reports-theme': '#d90707',
    'generated-documents-theme': '#244b8d',
    'contacts-theme': '#ffbb06',
    'calendar-theme': '#546e7a',
    'task-theme': '#00acc1',
    'piecework-theme': '#2e7d32',
    'tsb-theme': '#1E73BE',
    'yust-infra-theme': '#00A7DE',
};

const getCustomThemeByUrl = (url: string, partner: PartnerEntity): string => {
    if (url.includes('?')) {
        url = url.split('?')[0];
    }
    const service = DashboardItemsData.find((card) => `/${card.route}` === url || url.includes(`/${card.route}/`));
    if (service) {
        return service.theme;
    }
    if (url === '/' + LoginMeta.Path) {
        return '';
    }
    if (partner?.PartnerTypeId === PartnerTypeEnum.Obligo) {
        return 'obligo-theme';
    }
    if (partner?.PartnerTypeId === PartnerTypeEnum.Zert) {
        return 'zert-theme';
    }
    if (partner?.PartnerTypeId === PartnerTypeEnum.Kagetec) {
        return 'kagetec-theme';
    }
    if (partner?.PartnerTypeId === PartnerTypeEnum.TSB) {
        return 'tsb-theme';
    }
    if (partner?.PartnerTypeId === PartnerTypeEnum.YustInfra) {
        return 'yust-infra-theme';
    }
    return '';
};

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public NavBarOpen = false;
    public Loading$: Observable<boolean>;
    public CustomTheme$: Observable<string>;
    public NavBarFlyMode$ = this.bs.observe([Breakpoints.TabletPortrait, Breakpoints.Handset]).pipe(
        map((state) => state.matches),
        distinctUntilChanged(),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public IsHomeDashboard$ = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects === '/'),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    public IsLogin$ = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects === '/' + LoginMeta.Path),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    protected subscriptions: Subscription[] = [];

    constructor(private store: Store<State>, private router: Router, private routingHistory: RoutingHistoryService, private sessionService: SessionService, private bs: BreakpointObserver) {
        polyfillCountryFlagEmojis('flagfont');
        L10n.load(TranslationObject);
        setCulture('de');
        if (localStorage.getItem('token')) {
            this.store.dispatch(BaseActionTypes.CheckToken());
        }

        const navigationStartAndEnd$ = this.router.events.pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError));

        this.CustomTheme$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map((v) => v as RouterEvent),
            withLatestFrom(this.store.select(getPartner)),
            scan((oldTheme, [event, partner]) => {
                const url = event instanceof NavigationEnd ? event.urlAfterRedirects : event.url;
                const newTheme = getCustomThemeByUrl(url, partner);
                return newTheme;
            }, ''),
        );
        this.Loading$ = navigationStartAndEnd$.pipe(map((event) => event instanceof NavigationStart));
        this.sessionService.registerCulture('de-DE');
        this.routingHistory.LoadRouting();
    }
    ngOnInit(): void {
        this.subscriptions.push(
            this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationStart)).subscribe(() => {
                this.NavBarOpen = false;
            }),
            combineLatest([
                this.CustomTheme$.pipe(startWith(null)),
                this.store.select(getSetting).pipe(
                    map((s) => s.DarkMode),
                    startWith(null),
                ),
            ])
                .pipe(pairwise())
                .subscribe(([[oldTheme, oldDarkMode], [theme, darkMode]]) => {
                    if (oldDarkMode !== darkMode) {
                        if (darkMode) {
                            document.body.classList.add('dark-theme');
                        } else {
                            document.body.classList.remove('dark-theme');
                        }
                    }
                    if (oldTheme !== theme) {
                        if (document.body.classList.contains(oldTheme)) {
                            document.body.classList.remove(oldTheme);
                        }
                        if (theme) {
                            document.body.classList.add(theme);
                        }
                        if (ThemePrimaryColours[theme]) {
                            document.querySelector('#themeColorMetaTag')['content'] = ThemePrimaryColours[theme];
                        } else {
                            document.querySelector('#themeColorMetaTag')['content'] = ThemePrimaryColours['default'];
                        }
                    }
                }),
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
