<h1 mat-dialog-title class="mat-headline">Mitglied auswählen</h1>
<div mat-dialog-content class="content">
    <div class="scrollbar-wrapper">
        <ngx-simplebar>
            <table mat-table [dataSource]="DisplayPartner$">
                <ng-container matColumnDef="icon">
                    <td mat-cell *matCellDef="let rowData" style="width: 1.5rem; padding-left: 0;">
                        <fa-icon icon="times" size="lg" *ngIf="!rowData.Selected"></fa-icon>
                        <fa-icon icon="check" size="lg" *ngIf="rowData.Selected"></fa-icon>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <td mat-cell *matCellDef="let rowData">
                        {{ rowData.Partner.PartnerNo }} - {{ rowData.Partner.Name }}
                    </td>
                </ng-container>
                <tr
                        mat-row
                        *matRowDef="let rowData; columns: ['icon', 'name']"
                        [class.disabled-table-entry]="rowData.Disabled"
                        (click)="!rowData.Disabled ? ToggleSelected(rowData.Partner.Id) : {}"
                >
                </tr>
            </table>
        </ngx-simplebar>
    </div>
</div>
<div mat-dialog-actions class="buttons">
    <button app-button class="button" (click)="Save$.next()">Speichern</button>
    <button app-button Inverted class="button" mat-dialog-close="">Schließen</button>
</div>
