import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from '../index';
import { employeeToVacationFeatureKey, EmployeeToVacationState } from '../reducers/employeeToVacation.reducer';
import {
    selectAllEmployeeToVacation,
    selectEmployeeToVacationEntities, selectEmployeeToVacationFetched, selectEmployeeToVacationLatestUpdatedAt,
} from '../reducers/employeeToVacation.reducer';
import { VacationStatusEnum, VacationTypeEnum } from '../../entities/employee-to-vacation.entity';


const selectEmployeeToVacation = createFeatureSelector< EmployeeToVacationState>(employeeToVacationFeatureKey);
export const getEmployeeToVacationDictionary = createSelector(selectEmployeeToVacation, selectEmployeeToVacationEntities);

export const getEmployeeToVacations = createSelector(selectEmployeeToVacation, selectAllEmployeeToVacation);
export const getEmployeeToVacationsFetched = createSelector(selectEmployeeToVacation, selectEmployeeToVacationFetched);
export const getEmployeeToVacationLatestUpdatedAt = createSelector(selectEmployeeToVacation, selectEmployeeToVacationLatestUpdatedAt);
export const getEmployeeToVacationById = (props: { id: number }) => createSelector(getEmployeeToVacationDictionary, (dictionary) => props?.id && dictionary[props.id]);

export const getOpenEmployeeToVacations = createSelector(getEmployeeToVacations, (e2vs => e2vs.filter(e2v => e2v.VacationStatus === VacationStatusEnum.Open)));
export const getOpenEmployeeToVacationsByType = (props: { type: VacationTypeEnum }) => createSelector(getOpenEmployeeToVacations, (e2vs => e2vs.filter(e2v => e2v.Type === props.type)));

export const getEmployeeToVacationsByType = (props: { type: VacationTypeEnum }) => createSelector(getEmployeeToVacations, (e2vs => e2vs.filter(e2v => e2v.Type === props.type)));
// export const getNotAcceptedEmployeeToVacations = createSelector(getEmployeeToVacations, (e2vs => e2vs.filter(e2v => e2v.VacationStatus === VacationStatusEnum.Open)));
export const getNotAcceptedEmployeeToVacationsByTypeAndUser = (props: { type: VacationTypeEnum, userId: number }) => createSelector(getEmployeeToVacationsByType(props), (e2vs => e2vs.filter(e2v => e2v.UserIdListApproved?.includes(props.userId))));
export const getNotByUserAcceptedEmployeeToVacationsByType = (props: { type: VacationTypeEnum, userId: number }) => createSelector(getEmployeeToVacationsByType(props), (e2vs => e2vs.filter(e2v => !e2v.UserIdListApproved?.includes(props.userId))));

