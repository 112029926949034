<ngx-simplebar class="scrollbar">
    <div style="width: 100%; height: 100%; display: flex">
        <div class="content">
            <mat-card class="input">
                <form [formGroup]="NewEventForm" (ngSubmit)="Submit()">
                    <form name="fieldName" ngDefaultControl>
                        <app-event-types-selector-card
                            [EventTypeList]="EventTypeList"
                            [CurrentEventType]="EventTypeForm.value"
                            (EventTypeChange)="OnEventTypeChange($event)"
                        ></app-event-types-selector-card>
                    </form>

                    <mat-form-field class="hover-form" style="width: 100%;">
                        <input matInput type="text" placeholder="Name" [formControl]="NameForm" />
                    </mat-form-field>

                    <app-select-search-legacy
                        class="hover-form"
                        *ngIf="Customers$ | async as customers"
                        [Values]="customers"
                        [Placeholder]="CS.GetSingle$() | async"
                        [Control]="CustomerForm"
                    ></app-select-search-legacy>
                    <app-select-search-legacy
                        class="hover-form"
                        *ngIf="Commissions$ | async as commissions"
                        [Values]="commissions"
                        [Placeholder]="cls.getSingle$('Commission') | async"
                        [Control]="CommissionForm"
                        [Disabled]="!this.Customer"
                    ></app-select-search-legacy>

                    <div class="contactPersonWrapper hover-form">
                        <mat-form-field class="type">
                            <app-select-search placeholder="Prozess" [formControl]="NewEventForm.controls.Process" [Options]="ProcessList$ | async" [CompareOptions]="compareById"></app-select-search>
                            <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer;" (click)="NewEventForm.controls.Process.setValue(null);$event.stopPropagation();" *ngIf="!NewEventForm.controls.Process.disabled"></fa-icon>
                            <mat-error *ngIf="NewEventForm.controls.Process.invalid">
                                {{ GetErrorMessage(NewEventForm.controls.Process) }}
                            </mat-error>
                        </mat-form-field>
                        <!--            <app-select-search-legacy [Values]="CustomerList" [Placeholder]="'Kunde'" [Control]="NewEventForm.controls.Customer"></app-select-search-legacy>-->
                    </div>
                    <div class="contactPersonWrapper hover-form">
                        <app-select-search-legacy
                            class="hover-form"
                            style="width: 100%"
                            *ngIf="Persons$ | async as persons"
                            [Values]="persons"
                            [Placeholder]="this.Customer ? this.Customer.AnsprechpartnerText() : 'Ansprechpartner / Sachbearbeiter'"
                            [Control]="PersonForm"
                            [ValuesAreSorted]="true"
                        ></app-select-search-legacy>
                        <span class="button" [matTooltip]="Customer ? '' : (CS.GetSingle$() | async) + ' wählen'">
                            <button
                                type="button"
                                app-round-button
                                class="button"
                                [Disabled]="!Customer"
                                [matTooltip]="Customer ? Customer.AnsprechpartnerText() + ' hinzufügen' : (CS.GetSingle$() | async) + ' wählen'"
                                (click)="NewContactPerson()"
                            >
                                <fa-icon icon="plus"></fa-icon>
                            </button>
                        </span>
                    </div>

                    <div class="date-time-picker">
                        <mat-form-field>
                            <mat-label>Datum</mat-label>
                            <mat-date-range-input [rangePicker]="picker" required>
                                <input matStartDate placeholder="Start" [formControl]="NewEventForm.controls.EventDate" required>
                                <input matEndDate placeholder="Ende" [formControl]="NewEventForm.controls.EventEndDate" required>
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>

                        <mat-form-field *ngIf="!NewEventForm.value.EventEndDate || (NewEventForm.value.EventDate && sameDay(NewEventForm.value.EventDate.toDate(), NewEventForm.value.EventEndDate.toDate()))">
                            <mat-label>Zeitraum</mat-label>
                            <app-time-span-picker-select required>
                                <app-time-picker-select appStartTime required placeholder="Start" [formControl]="NewEventForm.controls.EventStartTimeString"></app-time-picker-select>
                                <app-time-picker-select appEndTime required placeholder="Ende" [min]="NewEventForm.value.EventStartTimeString" [offsetTimeForOptionLabel]="NewEventForm.value.EventStartTimeString" [formControl]="NewEventForm.controls.EventEndTimeString"></app-time-picker-select>
                            </app-time-span-picker-select>
                        </mat-form-field>
                    </div>

                    <app-detail-list-template [Editing]="true" [Data]="AddressTemplateData$ | async" [Inline]="true"></app-detail-list-template>
                    <div class="contactPersonWrapper">
                        <mat-slide-toggle class="hover-form" [formControl]="PrivateForm">Privat </mat-slide-toggle>
                    </div>

                    <app-textarea Key="Notiz" [Value]="NoteForm" class="hover-form"></app-textarea>
                    <!--    passt momentan nicht zu unserem angestrebten Workflow -->
                    <!--                    <div class="dropzone">-->
                    <!--                        <dropzone-->
                    <!--                            #dropzone-->
                    <!--                            [config]="DropzoneConfig"-->
                    <!--                            [message]="'Klicken oder Dateien hinein ziehen'"-->
                    <!--                        ></dropzone>-->
                    <!--                    </div>-->

                    <div class="error-message">{{ Error }}</div>

                    <div class="buttons">
                        <div class="cancel-button-wrapper">
                            <button Inverted type="button" app-button class="button" (click)="Cancel()">Abbrechen</button>
                        </div>

                        <button app-button class="button" [Disabled]="NewEventForm?.invalid">Speichern</button>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</ngx-simplebar>

<button *ngIf="!UploadOpen" app-round-button Inverted class="upload-button" matTooltip="Dokument hochladen" (click)="UploadOpen = true">
    <fa-icon icon="upload"></fa-icon>
</button>
<mat-card [ngStyle]="{ display: UploadOpen ? 'block' : 'none' }" class="new-document-overlay">
    <div>
        <button app-round-button class="back-button" (click)="UploadOpen = false">
            <fa-icon icon="chevron-right"></fa-icon>
        </button>
        <app-new-document-view-component #FileUpload (CloseViewEvent)="UploadOpen = false" class="new-document-view" [ShowSaveButton]="false"></app-new-document-view-component>
    </div>
</mat-card>
