<div class="scroll-wrapper">
    <ng-container *ngFor="let button of Buttons$ | async">
        <div class="theme-override" [ngClass]="button.theme" *ngIf="button.name !== 'timer' && (ShowButton(button.name) | async)">
            <button mat-flat-button class="button" (click)="ButtonClickHandler(button.name); $event.stopPropagation(); $event.preventDefault()" color="primary-lighter" #btn
                    (mouseover)="btn.color = 'primary'"
                    (mouseout)="btn.color = 'primary-lighter'">
                <fa-icon class="icon" size="2x" [icon]="button.icon"></fa-icon>
            </button>
        </div>
        <div *ngIf="button.name === 'timer'" class="theme-override" [ngClass]="button.theme">
            <app-time-tracker></app-time-tracker>
        </div>
    </ng-container>
</div>
