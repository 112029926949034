<!--<weather-widget class="weather-widget" *ngIf="CurrentWeather" [currentWeather]="CurrentWeather"-->
<!--                [settings]="WeatherSettings"></weather-widget>-->

<div class="wrapper">
    <div class="headercontainer">
        <div class="dummy"></div>
        <p *ngIf="Clock && Now" class="time">{{Now[0]}}{{Now[1]}}:{{Now[2]}}{{Now[3]}}</p>
    </div>
    <div class="weathercontainer" *ngIf="CurrentWeather">
        <div>
            <p class="location">{{CurrentWeather.Location}}</p>
        </div>
        <div class="weather">
            <div class="img">
                <i *ngIf="CurrentWeather.IconClass" [class]="CurrentWeather.IconClass"></i>
            </div>
            <div class="temp-wrapper">
                <p class="temp">{{Round(CurrentWeather.Temp)}}°C</p>
            </div>
        </div>
        <p class="description">{{CurrentWeather.Description}}</p>
    </div>
</div>

