import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { BehaviorSubject, of, switchMap } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
const MAPS_API_KEY = 'AIzaSyC7-k3ocWL6wbayhN2vcVuFa_Fnjv947i0';
@Injectable({
    providedIn: 'root',
})
export class GoogleMapsService {
    public ApiLoaded$ = new BehaviorSubject(false);
    constructor(private geocoder: MapGeocoder, httpClient: HttpClient) {
        httpClient
            .jsonp('https://maps.googleapis.com/maps/api/js?key=' + MAPS_API_KEY, 'callback')
            .pipe(
                map(() => true),
                catchError(() => of(false)),
                take(1),
            )
            .subscribe((v) => this.ApiLoaded$.next(v));
    }

    /**
     * Gibt Längen- und Breitengrad zu einer Adresse zurück.
     *
     * Dieses Observable muss nicht unsubscribed werden.
     * @param address Die Adresse (Google Maps Suchbegriff), die aufgelöst werden soll
     */
    Geocode(address: string) {
        return this.ApiLoaded$.pipe(
            filter(v => !!v),
            switchMap(() => this.geocoder.geocode({ address })),
            map(({ results, status }) => ({
                Latitude: status === 'OK' ? results[0].geometry.location.lat() : null,
                Longitude: status === 'OK' ? results[0].geometry.location.lng() : null,
            })),
        );
    }
}
