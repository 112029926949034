import { createAction, props } from '@ngrx/store';
import { EventTypeEntity } from '../../entities/event-type.entity';

export const EventTypesActionTypes = {
    UpdateEventTypes: createAction(
        'data/EventTypes/update',
        props<{ Payload: Array<EventTypeEntity> }>(),
    ),
    UpdateOne: createAction(
        'data/EventTypes/updateOne',
        props<{ Payload: EventTypeEntity }>(),
    ),
    RemoveOne: createAction(
        'data/EventTypes/removeOne',
        props<{ Payload: number }>(),
    ),
    AddEventType: createAction(
        'data/EventTypes/add',
        props<{ Payload: EventTypeEntity }>(),
    ),
    DeleteEventType: createAction(
        'data/EventTypes/delete',
        props<{ Payload: number }>(),
    ),
};
