import { createAction, props } from '@ngrx/store';
import {
    DaveMutationChangeResourceToCommissionArgs,
    DaveMutationCreateResourceToCommissionArgs,
    QueryResourcetocommissionArgs,
} from '../../../graphql-types';
import { ResourceToCommissionEntity } from '../../../entities/resource-dispo/resource-to-commission.entity';


const prefix = 'data/' + 'resourceToCommission/';
export const ResourceToCommissionsActionTypes = {
    Load: createAction(prefix + 'loadAll' , props<{ Payload?: QueryResourcetocommissionArgs }>()),
    Create: createAction(prefix + 'create', props<{ Payload: DaveMutationCreateResourceToCommissionArgs }>()),
    Change: createAction(prefix + 'change', props<{ Payload: DaveMutationChangeResourceToCommissionArgs }>()),
    Delete: createAction(prefix + 'delete', props<{ Payload: number }>()),

    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<ResourceToCommissionEntity> ,  updateLatestUpdatedAt?: boolean}>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<ResourceToCommissionEntity>,  updateLatestUpdatedAt?: boolean}>()),
    UpdateOne: createAction(prefix + 'updateOne', props<{ Payload: ResourceToCommissionEntity }>()),
    RemoveOne: createAction(prefix + 'remove', props<{ Payload: number }>()),
};
