import { createReducer, on } from '@ngrx/store';
import { EventEntity } from '../../entities/event.entity';
import { EventsActionTypes } from '../actions/events.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';

export const eventsFeatureKey = 'events';

export interface EventsState extends EntityState<EventEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;
}

const adapter = createEntityAdapter<EventEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const eventsReducer = createReducer(
    initialState,
    on(EventsActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(EventsActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) => {
        return Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
                ...state,
                latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
            })
        },
    ),
    on(EventsActionTypes.DeleteEventFromStore, (state, { payload }) => adapter.removeOne(payload, state)),
    on(EventsActionTypes.UpdateEvent, (state, { Payload }) => adapter.setOne(Payload, state)),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllEvent = selectAll;
export const selectEventEntities = selectEntities;

export const selectEventFetched = (state: EventsState) => state.fetched;
export const selectEventLatestUpdatedAt = (state: EventsState) => state.latestUpdatedAt;
