import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { DaveActions } from '../actions/actions';
import { ResolverActionTypes } from '../actions/resolver.actions';
import { CustomerTypeEntity } from '../../entities/customer-type.entity';
import { HttpService } from '../../services/http.service';
import { State } from '../index';
import { BaseActionTypes } from '../actions/base.actions';
import { getToken } from '../selectors/base.selectors';
import { isNotNullOrUndefined, stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { Scan2DaveEmailEntity } from '../../entities/scan2daveEmail.entity';
import { Scan2DaveEmailActionTypes } from '../actions/scan2DaveEmail.actions';
import { getScan2DaveEmail } from '../selectors/scan2DaveEmail.selectors';
import { CustomerEntityFromBackend } from '../../entities/customer.entity';

enum ErrorCodes {
    Add = 'E-Mail Hinzufügen fehlgeschlagen',
    Load = 'E-Mail Abrufen fehlgeschlagen',
    Modify = 'E-Mail Ändern fehlgeschlagen',
    Delete = 'E-Mail Löschen fehlgeschlagen',
}

@Injectable()
export class Scan2DaveEmailEffects {


    LoadScan2DaveEmails$ = createEffect(() => this.actions$.pipe(
        ofType(ResolverActionTypes.LoadScan2DaveEmails),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
            query {
                scan2DaveEmail {
                    ${Scan2DaveEmailEntity.GqlFields}
                }
            }`;
            return this.gatewayHttpService
                .graphQl({query: queryString}, {token: store.base.token, retry: true })
                .pipe(
                    map(res =>
                        res && res.scan2DaveEmail
                            ? Scan2DaveEmailActionTypes.UpdateScan2DaveEmails({
                                Payload: res.scan2DaveEmail.map(val =>
                                    new Scan2DaveEmailEntity(val),
                                ),
                            })
                            : BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Load}}),
                    ),
                    catchError((err, caught) =>
                        of(BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            }
                        })),
                    ),
                );
        }),
    ));


    Post$ = createEffect(() => this.actions$.pipe(
        ofType(Scan2DaveEmailActionTypes.AddScan2DaveEmail),
        map(
            ({Payload}) => `
            mutation {
                createScan2DaveEmail(${stringifyIfNotNullOrUndefined(Payload, 'email')}) {
                    ${Scan2DaveEmailEntity.GqlFields}
                }
            }`
        ),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([query, token]) =>
            this.gatewayHttpService.graphQl({query}, {token}).pipe(
                map(res => res && res.createScan2DaveEmail),
                concatMap(res =>
                    res
                        ? this.store$.select(getScan2DaveEmail).pipe(
                        filter(isNotNullOrUndefined),
                        take(1),
                        map(scan2DaveEmails =>
                            Scan2DaveEmailActionTypes.UpdateScan2DaveEmails({
                                Payload: [...scan2DaveEmails, new Scan2DaveEmailEntity(res)]
                            })
                        )
                        )
                        : [BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Add}})]
                ),
                catchError((err, caught) =>
                    of(BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Add,
                            Err: err,
                            Caught: caught,
                        }
                    })),
                ),
            )
        )
    ));


    Modify$ = createEffect(() => this.actions$.pipe(
        ofType(Scan2DaveEmailActionTypes.ModifyScan2DaveEmail),
        map(
            ({Payload}) => `
            mutation {
                changeScan2DaveEmail(
                ${stringifyIfNotNullOrUndefined(Payload, 'email')}
                ${stringifyIfNotNullOrUndefined(Payload, 'id')}
                ) {
                    ${Scan2DaveEmailEntity.GqlFields}
                }
            }`
        ),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([query, token]) =>
            this.gatewayHttpService.graphQl({query}, {token}).pipe(
                map(res => res && res.changeScan2DaveEmail),
                concatMap(res => res
                        ? this.store$.select(getScan2DaveEmail).pipe(
                        filter(isNotNullOrUndefined),
                        take(1),
                        map(scan2DaveEmails =>
                            Scan2DaveEmailActionTypes.UpdateScan2DaveEmails(
                                {
                                    Payload: [
                                        ...scan2DaveEmails.map(val =>
                                            val.Id === res.id
                                                ? new Scan2DaveEmailEntity(res)
                                                : val,
                                        ),
                                    ],
                                }
                            )
                        )
                        )
                        : [BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Modify}})]
                ),
                catchError((err, caught) =>
                    of(BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Modify,
                            Err: err,
                            Caught: caught,
                        }
                    })),
                ),
            )
        )
    ));


    Delete$ = createEffect(() => this.actions$.pipe(
        ofType(Scan2DaveEmailActionTypes.DeleteScan2DaveEmail),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{Payload: {id}}, token]) =>
            this.gatewayHttpService
                .graphQl(
                    {
                        query: `
                        mutation {
                            deleteScan2DaveEmail(id: ${JSON.stringify(id)})
                        }`
                    },
                    {token}
                )
                .pipe(
                    map(res => res && res.deleteScan2DaveEmail),
                    concatMap(res => res
                                ? this.store$.select(getScan2DaveEmail).pipe(
                                    filter(isNotNullOrUndefined),
                                    take(1),
                                    map(getScan2DaveEmails =>
                                        Scan2DaveEmailActionTypes.RemoveScan2DaveEmail({
                                            Payload: id
                                        })
                                    )
                                )
                                : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Delete } })]

                    ),
                    catchError(
                        (err, caught) => (
                            this.store$.dispatch(BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Delete,
                                    Err: err,
                                    Caught: caught
                                }
                            })),
                                caught
                        )
                    )
                )
        )
    ));

    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService
    ) {
    }
}
