import { createReducer, on } from '@ngrx/store';
import { TagEntity } from '../../entities/tag.entity';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { TagActionTypes } from '../actions/tag.actions';

export const TAG_FEATURE_KEY = 'tag';

export interface TagState extends EntityState<TagEntity> {
    fetched: boolean;
}
const adapter = createEntityAdapter<TagEntity>({
    selectId: Entity => Entity.Id,
});
const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const tagReducer = createReducer(
    initialState,
    on(TagActionTypes.UpdateTags, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllTag = selectAll;
export const selectTagEntities = selectEntities;
export const selectTagFetched = (state: TagState) => state.fetched;
