<mat-card
    (click)="Clicked.emit(file)"
    *ngIf="(FileIntern$ | async) as file"
    class="card"
    [class.inset-box-shadow]="Highlight"
>
    <div class="button-wrapper">
        <fa-icon [icon]="Selected ? ['far', 'check-square'] : ['far', 'square']" class="selected-icon" *ngIf="SelectMode"></fa-icon>
        <button
            *ngIf="InsertButton"
            app-round-button
            class="insert-button"
            (click)=" $event.stopPropagation(); InsertButtonClicked.emit(file);">
            <fa-icon icon="chevron-right"></fa-icon>
        </button>
        <button
            *ngIf="DeleteButton"
            class="delete-button"
            (click)="DeleteButtonClick(file, $event)"
            mat-icon-button
        >
            <fa-icon icon="trash"></fa-icon>
        </button>
        <fa-icon  *ngIf="file.LinkedDocumentId" class="link-icon" matTooltip="dies ist eine Verknüpfung" [icon]="FileLinkIcon"></fa-icon>
    </div>
    <ng-container *ngIf="file.GetLastVersion().HasThumbnail; else noImage">
        <div
            class="image-container"
            [class.inset-box-shadow-left-right-top]="Highlight"
            [class.more-height]="!((FileTags$ | async)?.length > 0)"
            [class.hide]="!ImageLoaded"
            *ngIf="!ImageBroken; else imageBroken"
        >
            <img
                class="image-button"
                [class.inset-box-shadow-left-right]="Highlight"
                [class.more-max-height]="!((FileTags$ | async)?.length > 0)"
                [src]="Thumbnaillink$ | async"
                (load)="loadingSpinner.remove(); ImageLoaded = true"
                (error)="loadingSpinner.remove(); ImageBroken = true"
            />
        </div>
        <div #loadingSpinner
            class="no-image"
            [class.more-height]="!((FileTags$ | async)?.length > 0)"
        >
            <app-loading [Size]="75"></app-loading>
        </div>
    </ng-container>
    <div class="card-content">
        <mat-chip-list
            *ngIf="(FileTags$ | async)?.length > 0"
            class="chips"
            [disabled]="true"
        >
            <mat-chip
                class="chip"
                *ngFor="let tag of FileTags$ | async"
                [disabled]="true"
            >{{ tag.Name }}</mat-chip
            >
        </mat-chip-list>
        <p>{{ file.Name }}</p>
        <mat-card-subtitle>
            {{ file.CreatedAt | date: 'shortDate' }}
            <fa-icon
                class="shared-icon"
                icon="unlock"
                [matTooltip]="'Diese Datei wird bei geteilten ' + (cls.getMultiple$('Commission', 'Dativ') | async) + ' mit geteilt'"
                *ngIf="file.Shared"
            ></fa-icon>
            <div class="event-button">
                <button *ngIf="EventData?.event as event" mat-icon-button [routerLink]="['/', 'chronik', event.CustomerId || 'alle', event.Id]">
                    <fa-icon
                        *ngIf="EventData.eventType?.ImagePath; else letterTemplate"
                        [icon]="EventData.eventType?.ImagePath | icon"
                    ></fa-icon>
                    <ng-template #letterTemplate>
                            <span class="event-type-letter">{{
                                EventData.eventType?.Name[0]
                                }}</span>
                    </ng-template>
                </button>
            </div>
            <button
                *ngIf="InfoButton"
                class="file-info-button"
                (click)="$event.stopPropagation();InfoButtonClicked.emit(file.Id)"
                mat-icon-button
            >
                <fa-icon icon="info-circle"></fa-icon>
            </button>
            <a
                class="download-button"
                [href]="Downloadlink$ | async"
                download
                mat-icon-button
                (click)="$event.stopPropagation()"
            >
                <fa-icon icon="download"></fa-icon>
            </a>
        </mat-card-subtitle>
    </div>
</mat-card>
<ng-template #noImage>
    <div
        class="no-image"
        [class.more-height]="!((FileTags$ | async)?.length > 0)"
    >
        <fa-icon icon="file" size="4x"></fa-icon>
    </div>
</ng-template>

<ng-template #imageBroken>
    <div
        class="no-image"
        [class.more-height]="!((FileTags$ | async)?.length > 0)"
    >
        <fa-icon icon="exclamation-triangle" size="4x"></fa-icon>
    </div>
</ng-template>

<div class="overlay" (click)="ShowTimelineItem.next(false)" *ngIf="ShowTimelineItem | async"></div>
