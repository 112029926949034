import { DamageFlowType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export class DamageFlowEntity {
    public static GQLFields = `
          abgabeterminBerichtReal
          canCreate
          canDelete
          canEdit
          canRoleEdit
          city
          country
          commissionId
          createdAt
          damageFlowTypeId
          deadlineVerlaengerung
          deleted
          description
          employeeId
          ersterBerichtEingegangen
          ersterOrtstermin
          id
          kontaktMitVersicherungsnehmer
          objektiveSchadensumme
          partnerId
          postalCode
          qMDurchgefuehrtAm
          qMStatusId
          reportCount
          schadenannahmeDurchSV
          street
          updatedAt
          userId
          weiterleitungAnVu`;
    constructor(
        public Id: number = null,
        public Street: string = '',
        public Description: string = '',
        public DamageFlowTypeId: number = null,
        public PostalCode: string = '',
        public City: string = '',
        public Country: string = '',
        public DeadlineVerlaengerung: string = '',
        public SchadenannahmeDurchSV: Date = null,
        public KontaktMitVersicherungsnehmer: Date = null,
        public ErsterOrtstermin: Date = null,
        public ErsterBerichtEingegangen: boolean = false,
        public AbgabeterminBerichtReal: Date = null,
        public QMStatusId: number = null,
        public QMDurchgefuehrtAm: Date = null,
        public WeiterleitungAnVu: Date = null,
        public ObjektiveSchadensumme: string = '',
        public ReportCount: number = null,
        public CommissionId: number = null,
        public EmployeeId: number = null,
        public UserId: number = null,
        public PartnerId: number = null,
        public Deleted: boolean = false,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public CanCreate: boolean = true,
        public CanEdit: boolean = true,
        public CanRoleEdit: boolean = true,
        public CanDelete: boolean = true,
    ){
    }

    public Clone(override: DamageFlowEntityAttributes = {}): DamageFlowEntity {
        const attr = {...this, ...override};
        return Object.assign(new DamageFlowEntity(), attr);
    }
}
export function DamageFlowEntityFromBackend(res: DamageFlowType): DamageFlowEntity {
    return new DamageFlowEntity(
        res.id,
        res.street,
        res.description,
        res.damageFlowTypeId,
        res.postalCode,
        res.city,
        res.country,
        res.deadlineVerlaengerung,
        res.schadenannahmeDurchSV ? BackendDate(res.schadenannahmeDurchSV) : null,
        res.kontaktMitVersicherungsnehmer ? BackendDate(res.kontaktMitVersicherungsnehmer) : null,
        res.ersterOrtstermin ? BackendDate(res.ersterOrtstermin) : null,
        res.ersterBerichtEingegangen,
        res.abgabeterminBerichtReal ? BackendDate(res.abgabeterminBerichtReal) : null,
        res.qMStatusId,
        res.qMDurchgefuehrtAm ? BackendDate(res.qMDurchgefuehrtAm) : null,
        res.weiterleitungAnVu ? BackendDate(res.weiterleitungAnVu) : null,
        res.objektiveSchadensumme,
        res.reportCount,
        res.commissionId,
        res.employeeId,
        res.userId,
        res.partnerId,
        res.deleted,
        res.createdAt ? BackendDate(res.createdAt) : null,
        res.updatedAt ? BackendDate(res.updatedAt) : null,
        res.canCreate,
        res.canEdit,
        res.canRoleEdit,
        res.canDelete

    );
}

export interface DamageFlowEntityAttributes {
    Id?: number;
     Street?: string ;
     Description?: string;
     DamageFlowTypeId?: number;
     PostalCode?: string ;
     City?: string ;
     Country?: string ;
     DeadlineVerlaengerung?: string ;
     SchadenannahmeDurchSV?: Date ;
     KontaktMitVersicherungsnehmer?: Date ;
     ErsterOrtstermin?: Date ;
     ErsterBerichtEingegangen?: boolean ;
     AbgabeterminBerichtReal?: Date ;
     QMStatusId?: number ;
     QMDurchgefuehrtAm?: Date ;
     WeiterleitungAnVu?: Date ;
     ObjektiveSchadensumme?: string ;
     ReportCount?: number ;
    CommissionId?: number ;
         EmployeeId?: number ;
         UserId?: number ;
         PartnerId?: number ;
         Deleted?: boolean ;
         CreatedAt?: Date ;
         UpdatedAt?: Date ;
         CanCreate?: boolean;
         CanEdit?: boolean ;
         CanRoleEdit?: boolean ;
         CanDelete?: boolean;
        }
