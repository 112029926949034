import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { isNotNullOrUndefined, OpenHTMLInputPicker } from '../../../helper/helper';
import { getErrorMessage } from '../../../helper/validation.helper';
import { CustomFields, CustomFieldsModalTemplate, CustomFieldTypes } from '../../custom-form-fields.helper';
import {
    BreakpointObserverService
} from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';

@Component({
    selector: 'app-custom-form-fields-modal',
    templateUrl: './custom-form-fields-modal.component.html',
    styleUrls: ['./custom-form-fields-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFormFieldsModalComponent {
    private customFields: Array<
        CustomFields & {
            formControl: FormControl<any>;
        }
    > = null;
    public GetErrorMessage = getErrorMessage;
    private customFieldsModalTemplates: CustomFieldsModalTemplate[] = [];
    public Data$ = new BehaviorSubject<Array<Partial<CustomFieldsModalTemplate> & { fields: typeof this.customFields }>>([]);
    @Input() DefaultAlignment: 'row' | 'column' = 'column';

    @Input() set CustomFieldsModalTemplates(v: typeof this.customFieldsModalTemplates) {
        this.customFieldsModalTemplates = v;
        if (this.customFields) {
            this.renderData();
        }
    }
    @Input() set CustomFields(v: typeof this.customFields) {
        this.customFields = v;
        this.renderData();
    }
    get CustomFields() {
        return this.customFields;
    }

    public CustomFieldTypes = CustomFieldTypes;
    constructor(protected bs: BreakpointObserverService) {}
    renderData() {
        const ret: Partial<typeof this.customFieldsModalTemplates>&{fields: typeof this.customFields}[] = [
            ...this.customFieldsModalTemplates
                .map((c) => ({
                    ...c,
                    fields: c.fieldNames
                        .map((name) => this.customFields.find((f) => f.name === name))
                        .filter(isNotNullOrUndefined)
                        .map(f => {
                            const override = c.fieldTypeOverrides?.length && c.fieldTypeOverrides.find((o) => o.name === f.name);
                            if (override) {
                                return {
                                    ...f,
                                    ...override,
                                };
                            } else {
                                return f;
                            }
                        }),
                }))
                .filter((v) => v.fields.length),
        ];
        const missingFields = this.customFields.filter((c) => !this.customFieldsModalTemplates.some((t) => t.fieldNames.includes(c.name)));
        if (missingFields.length) {
            ret.push({
                fields: missingFields,
            });
        }
        this.Data$.next(ret);
    }

    protected readonly OpenHTMLInputPicker = OpenHTMLInputPicker;
}
