import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Subscription } from 'rxjs';
import { SelectSearchOption } from '../dave-utils-module/select-search/components/select-search/select-search.component';
import { SelectSearchModule } from '../dave-utils-module/select-search/select-search.module';
import { Address, stringSearch } from '../helper/helper';

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: AddressFormComponent,
        },
    ],
    standalone: true,
    imports: [CommonModule, MatInputModule, ReactiveFormsModule, SelectSearchModule, MatSelectModule, NgxMatSelectSearchModule, FontAwesomeModule, ScrollingModule],
})
export class AddressFormComponent implements ControlValueAccessor, OnDestroy {
    // @Input() legend: string;
    @Input() SearchAddressOptions: SelectSearchOption<{ searchValues?: string[]; value: Address }>[] | null = null;

    onTouched = () => {};
    onChange: (c: any) => void

    onChangeSub: Subscription;

    form = new FormGroup({
        Street: new FormControl<string>(null),
        PostalCode: new FormControl<string>(null),
        City: new FormControl<string>(null),
        Country: new FormControl<string>(null),
        // AddressSupplement:  new FormControl<string>(null),
        // Longitude:  new FormControl<string>(null),
        // Latitude:  new FormControl<string>(null),
    });
    compareOptions = (a: SelectSearchOption<{ searchValues?: string[]; value: Address }>, b: SelectSearchOption<{ searchValues?: string[]; value: Address }>) => {
        return a && b && this.compareAddress(a.value, b.value);
    };
    compareAddress = (a: Address, b: Address) => a.Street === b.Street && a.PostalCode === b.PostalCode && a.City === b.City && a.Country === b.Country;
    searchFunction = (search: string, option: SelectSearchOption<{ searchValues?: string[]; value: Address }>) =>
        option.searchValues ? option.searchValues.some((s) => stringSearch(s, search)) : stringSearch(option.optionLabel as string, search);

    constructor() {}

    registerOnChange(onChange: any) {
        this.onChange = onChange;
        this.onChangeSub = this.form.valueChanges.subscribe(onChange);
    }

    ngOnDestroy() {
        this.onChangeSub.unsubscribe();
    }

    writeValue(value: Address) {
        if (value && !this.compareAddress(value, this.form.value as Address)) {
            this.form.setValue(
                {
                    Street: value.Street,
                    PostalCode: value.PostalCode,
                    City: value.City,
                    Country: value.Country,
                    // AddressSupplement: value.AddressSupplement,
                    // Longitude: value.Longitude,
                    // Latitude: value.Latitude,
                },
                { emitEvent: false },
            );
        }
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    selectionChange(event: MatSelectChange) {
        const val: SelectSearchOption<{ searchValues?: string[]; value: Address }> = event.value;
        if (val) {
            this.writeValue(val.value);
            this.onChange(this.form.value);
        }
    }
}
