import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined, uniqArray } from '../../../helper/helper';
import { UserToCommissionEntity, UserToCommissionEntityFromBackend } from '../../entities/user-to-commission.entity';
import { HttpService } from '../../services/http.service';
import { BaseActionTypes } from '../actions/base.actions';
import { CommissionActionTypes } from '../actions/commission.actions';
import { UserToCommissionActionTypes } from '../actions/user-to-commission.action';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { getCommissionById, getCommissions } from '../selectors/commission.selector';
import { getUserToCommissions } from '../selectors/user-to-commission.selector';

enum ErrorCodes {
    Load = 'UserToCommission Abrufen fehlgeschlagen',
    Modify = 'UserToCommission Bearbeiten fehlgeschlagen',
    Remove = 'UserToCommission Löschen fehlgeschlagen',
    Add = 'UserToCommission Hinzufügen fehlgeschlagen',
}

@Injectable()
export class UserToCommissionEffects {

    ModifyUserToCommission$ = createEffect(() => this.actions$.pipe(
        ofType(UserToCommissionActionTypes.Change),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{ Payload }, token]) => {
            const queryString = `
            mutation {
                changeUser2Commission(
                    id: ${Payload.id}
                    ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                ) {
                    ${UserToCommissionEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
                concatMap(res => {
                    const ret: Action[] = [];
                    if (res && res.changeUser2Commission) {
                        ret.push(
                            UserToCommissionActionTypes.UpdateOne({
                                Payload: UserToCommissionEntityFromBackend(res.changeUser2Commission[0]), // todo fix in be, should not be an array
                            }),
                        );
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    AddUserToCommission$ = createEffect(() => this.actions$.pipe(
        ofType(UserToCommissionActionTypes.Create),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{ Payload }, token]) => {
            const queryString = `
            mutation {
                createUser2Commission(
                    ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'assignee')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'fromEntity')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'roleId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'fromId')}
                ) {
                    ${UserToCommissionEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
                withLatestFrom(this.store$.select(getCommissionById({id: Payload.commissionId})), this.store$.select(getUserToCommissions)),
                concatMap(([res, commission, u2c]) => {
                    const ret: Action[] = [];
                    if (res && res.createUser2Commission) {
                        ret.push(
                            UserToCommissionActionTypes.UpdateAll({
                                Payload: [
                                    ...u2c.filter(v => v.CommissionId !== res.createUser2Commission[0].commissionId),
                                    ...res.createUser2Commission.map(v => UserToCommissionEntityFromBackend(v))
                                ],
                            }),
                        );
                        if (commission) {
                            const AssignUserIds: number[] = uniqArray((res.createUser2Commission).filter(u2c => u2c.assignee).map(c => c.userId));
                            const UserIds = uniqArray((res.createUser2Commission).filter(u2c => !u2c.assignee && !AssignUserIds.includes(u2c.userId)).map(c => c.userId));
                            ret.push(CommissionActionTypes.UpdateMany({Payload: [commission.Clone({AssignUserIds, UserIds})]}));
                        }
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    RemoveUserToCommission$ = createEffect(() => this.actions$.pipe(
        ofType(UserToCommissionActionTypes.Delete),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{ Payload }, token]) => {
            const queryString = `
            mutation {
                deleteUser2Commission(
                    id: ${Payload.id}
                ) {
                    ${UserToCommissionEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
                withLatestFrom(this.store$.select(getCommissions)),
                withLatestFrom(this.store$.select(getUserToCommissions)),
                concatMap(([[res, commissions], u2c]) => {
                    const ret: Action[] = [];
                    if (res && res.deleteUser2Commission) {
                        ret.push(
                            UserToCommissionActionTypes.UpdateAll({
                                Payload: [
                                    ...u2c.filter(v => v.CommissionId !== res.deleteUser2Commission[0].commissionId),
                                    ...res.deleteUser2Commission.map(v => UserToCommissionEntityFromBackend(v))
                                ],
                            }),
                        );
                        if (commissions) {
                            ret.push(CommissionActionTypes.Load({}));
                        }
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    GetUserToCommission$ = createEffect(() => this.actions$.pipe(
        ofType(UserToCommissionActionTypes.Load),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{Payload}, token]) => {
            const queryString = `
            query {
                user2Commission${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
                    ${UserToCommissionEntity.GqlFields}
                }
            }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                tap(res => {
                    if (!(Payload?.updatedSince && !res.user2Commission.length)) {
                        this.store$.dispatch(
                            res && res.user2Commission
                                ? Payload?.updatedSince
                                    ? UserToCommissionActionTypes.UpdateMany({
                                        Payload: res.user2Commission.map(val => UserToCommissionEntityFromBackend(val)),
                                        updateLatestUpdatedAt: true,
                                    })
                                    : UserToCommissionActionTypes.UpdateAll({
                                        Payload: res.user2Commission.map(val => UserToCommissionEntityFromBackend(val)),
                                        updateLatestUpdatedAt: true,
                                    })
                                : BaseActionTypes.ErrorAction({
                                    Payload: { ToasterMessage: ErrorCodes.Load },
                                }),
                        );
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            );
        }),
    ), {dispatch: false});
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
