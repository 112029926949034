import { BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

export enum CustomBreakpoints {
    /**
     * @deprecated
     * Warum nicht die von Angular ? {@link Breakpoints}
     */
    DetailViewWidth = '(max-width: 949px)',
    /**
     * @deprecated
     * Warum nicht die von Angular ? {@link Breakpoints}
     */
    MobileWidth = '(max-width: 819px)',
    /**
     * @deprecated
     * Warum nicht die von Angular ? {@link Breakpoints}
     */
    MobileMailWidth= '(max-width: 1000px)',
    /**
     * @deprecated
     * Warum nicht die von Angular ? {@link Breakpoints}
     */
    TabletWidth = '(max-width: 1440px)',
    /**
     * @deprecated
     * Warum nicht die von Angular ? {@link Breakpoints}
     */
    SmallWidth = '(max-width: 1200px)',

    IsTouch = '(hover: none)',
}

@Injectable({
    providedIn: 'root',
})
export class BreakpointObserverService {
    public DetailViewWidthQuery: Observable<boolean>;
    public MobileQuery: Observable<boolean>;
    public MobileMailQuery: Observable<boolean>;
    public TabletQuery: Observable<boolean>;
    public SmallQuery: Observable<boolean>;
    public TouchQuery: Observable<boolean>;

    constructor(breakpointObserver: BreakpointObserver) {
        this.DetailViewWidthQuery = breakpointObserver.observe(CustomBreakpoints.DetailViewWidth).pipe(
            map(breakpointState => breakpointState.matches),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        this.MobileQuery = breakpointObserver
            .observe(CustomBreakpoints.MobileWidth)
            .pipe(
                map(breakpointState => breakpointState.matches),
                shareReplay({ refCount: true, bufferSize: 1 }),
            );

        this.MobileMailQuery= breakpointObserver
        .observe(CustomBreakpoints.MobileMailWidth)
        .pipe(
            map(breakpointState => breakpointState.matches),
            shareReplay({ refCount: true, bufferSize: 1}),
        );

        this.SmallQuery= breakpointObserver
            .observe(CustomBreakpoints.SmallWidth)
            .pipe(
                map(breakpointState => breakpointState.matches),
                shareReplay({ refCount: true, bufferSize: 1}),
            );

        this.TabletQuery= breakpointObserver
        .observe(CustomBreakpoints.TabletWidth)
        .pipe(
            map(breakpointState => breakpointState.matches),
            shareReplay({ refCount: true, bufferSize: 1}),
        );

        this.TouchQuery= breakpointObserver
        .observe(CustomBreakpoints.IsTouch)
        .pipe(
            map(breakpointState => breakpointState.matches),
            shareReplay({ refCount: true, bufferSize: 1}),
        );
    }
}
