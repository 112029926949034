import moment from 'moment';


export function BackendDate(input: string): Date {
    check(input, 'BackendDate');

    return new Date(input.indexOf('Z') >= 0 ? input : input + 'Z');
}
export function BackendDateTimestamp(input: string): Date {
    // todo schneller machen dauert bis zu 2.5ms
    if (input.indexOf('Z') >= 0) {
        return moment(input, moment.ISO_8601).toDate();
    } else {
        if (input.indexOf('+') >= 0) {
            return moment(input, moment.ISO_8601).toDate();
        } else {
            return moment(input+'Z', moment.ISO_8601).toDate();
        }
    }
}

export function FrontendDate(input: Date, onlyDate: boolean = false): string {
    check(input, 'FrontendDate');
    return input ? onlyDate ? input.getFullYear() + '/' + (input.getMonth() + 1) + '/' + input.getDate() : input.toISOString() : '';
}

export function FrontendDateTimestamp(input: Date): string {
    let date = moment(input);

    return date.toISOString();
}

export function BackendDescription(input: string) {
    check(input, 'BackendDescription');

    try {
        return input ? JSON.parse(addQuotesIfMissing(input).replace(/:new_line:/g, '\n')) : '';
    } catch (e) {
        console.log('CorruptDescriptionString', input);
        console.error(e);
        return input;
    }
}

export function FrontendDescription(input: string) {
    check(input, 'FrontendDescription');

    return input ? input.replace(/\r?\n|\r/g, ':new_line:') : '';
}

function check(input: any, func: string) {
    if (input === null) {
        console.log(`${func} called with null argument.`);
    } else if (input === undefined) {
        console.log(`${func} called with undefined argument.`);
    }
}

function addQuotesIfMissing(input: string) {
    return input.indexOf('"') === 0 && input.lastIndexOf('"') === input.length - 1
        ? input
        : '"' + input + '"';
}


export function gzipCompressionIsSupported() {
    return !!(window as any).CompressionStream
}
/**
 * Convert a string to its UTF-8 bytes and compress it.
 *
 * @param {string} str
 * @returns {Promise<Uint8Array>}
 */
export async function gzipCompress(str) {
    // Convert the string to a byte stream.
    const stream: ReadableStream = new Blob([str]).stream() as unknown as ReadableStream;

    // Create a compressed stream.
    const compressedStream = stream.pipeThrough(
        new (window as any).CompressionStream("gzip")
    );

    // Read all the bytes from this stream.
    const chunks = [];
    // @ts-ignore
    for await (const chunk of compressedStream) {
        chunks.push(chunk);
    }
    console.log(chunks)
    // return new Blob(chunks);
    return await concatUint8Arrays(chunks);
}

/**
 * Decompress bytes into a UTF-8 string.
 *
 * @param {Uint8Array} compressedBytes
 * @returns {Promise<string>}
 */
export async function gzipDecompress(compressedBytes) {
    // Convert the bytes to a stream.
    const stream = new window.Blob([compressedBytes]).stream() as unknown as ReadableStream;

    // Create a decompressed stream.
    const decompressedStream = stream.pipeThrough(
        new (window as any).DecompressionStream("gzip")
    );

    // Read all the bytes from this stream.
    const chunks = [];
    // @ts-ignore
    for await (const chunk of decompressedStream) {
        chunks.push(chunk);
    }
    const stringBytes = await concatUint8Arrays(chunks);

    // Convert the bytes to a string.
    return new TextDecoder().decode(stringBytes);
}

/**
 * Combine multiple Uint8Arrays into one.
 *
 * @param {ReadonlyArray<Uint8Array>} uint8arrays
 * @returns {Promise<Uint8Array>}
 */
async function concatUint8Arrays(uint8arrays) {
    const blob = new Blob(uint8arrays);
    const buffer = await blob.arrayBuffer();
    return new Uint8Array(buffer);
}
export function Uint8ToString(u8a){
    var CHUNK_SZ = 0x8000;
    var c = [];
    for (var i=0; i < u8a.length; i+=CHUNK_SZ) {
        c.push(String.fromCharCode.apply(null, u8a.subarray(i, i+CHUNK_SZ)));
    }
    return c.join("");
}
