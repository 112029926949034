import { createAction, props } from '@ngrx/store';
import { MaterialEntity } from '../../entities/material.entity';

import { MaterialAddRequest, MaterialDeleteRequest, MaterialEditRequest, MaterialGetRequest } from '@dave/types';
import { UploadActionProps } from '../../guards/resolver.helper';




const prefix = 'data/' + 'material/';
export const MaterialActionTypes = {
    Load: createAction(prefix + 'load', props< UploadActionProps >()),
    Create: createAction(prefix + 'create', props<{ Payload: MaterialAddRequest }>()),
    Change: createAction(prefix + 'change', props<{ Payload: MaterialEditRequest }>()),
    Delete: createAction(prefix + 'delete', props<{ Payload: MaterialDeleteRequest }>()),

    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<MaterialEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<MaterialEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction(prefix + 'updateOne', props<{ Payload: MaterialEntity }>()),
    RemoveOne: createAction(prefix + 'removeOne', props<{ Payload: number }>()),
};
