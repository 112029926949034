import { CommentType } from '../graphql-types';
import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';

export enum CommentEntityTypeEnum {
    Commission = 'COMMISSION',
    Customer = 'CUSTOMER',
    Event = 'EVENT',
    Process = 'PROCESS',
}

export class CommentEntity {
    constructor(
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public EmployeeId: number = null,
        public EntityId: number = null,
        public EntityType: CommentEntityTypeEnum = null,
        public Id: number = null,
        public PartnerId: number = null,
        public Text: string = null,
        public UpdatedAt: Date = null,
        public LastSeenAt: Date = null,
        public UserId: number = null,
        public DocumentIds: number[] = null,
) {
    }
    public static readonly GqlFields = `
    createdAt
    deletedAt
    employeeId
    entityId
    entityType
    lastSeenAt
    id
    partnerId
    text
    updatedAt
    userId
    documentIds`;
    public Clone(override: Partial<CommentEntity> = {}): CommentEntity {
        const attr = {...this, ...override};
        return Object.assign(new CommentEntity(), attr);
    }
}

export function CommentEntityFromBackend(res: CommentType): CommentEntity {
    return new CommentEntity(
        res.createdAt ? BackendDateTimestamp(res.createdAt) : null,
        res.deletedAt ? BackendDateTimestamp(res.deletedAt) : null,
        res.employeeId,
        res.entityId,
        res.entityType,
        res.id,
        res.partnerId,
        res.text,
        res.updatedAt ? BackendDateTimestamp(res.updatedAt) : null,
        res.lastSeenAt ? BackendDateTimestamp(res.lastSeenAt) : null,
        res.userId,
        res.documentIds || [],
    );
}
