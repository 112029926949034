import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SelectSearchLegacyComponent } from './components/select-search-legacy/select-search-legacy.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';



@NgModule({
    declarations: [SelectSearchLegacyComponent, SelectSearchComponent],
    imports: [CommonModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, NgxMatSelectSearchModule, FontAwesomeModule, ScrollingModule, MatTooltipModule],
    exports: [SelectSearchLegacyComponent, SelectSearchComponent, SelectSearchComponent],
    // exports: [SelectSearchComponent],
})
export class SelectSearchModule {}
