import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../State';
import { EmployeeResourceScheduleActionTypes } from '../../State/actions/resource-dispo/employee-resource-schedule.actions';
import {
    getEmployeeResourceScheduleLatestUpdatedAt,
    getEmployeeResourceSchedulesFetched,
} from '../../State/selectors/resource-dispo/employee-resource-schedule.selectors';
import { BaseEntityReloadResolver2 } from '../resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class EmployeeResourceScheduleResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getEmployeeResourceSchedulesFetched, getEmployeeResourceScheduleLatestUpdatedAt, EmployeeResourceScheduleActionTypes.Load, store);
    }
}
