<button class="explorer-item" [class.with-filename]="ShowFileName" [matTooltip]="ShowFileName ? '' : file.Name" mat-button *ngIf="File$ | async as file" (click)="FileClick.emit(file)">
    <div class="icon-header">
        <ng-container *ngIf="checkbox">
            <mat-pseudo-checkbox class="checkbox" [state]="checkboxSelected ? 'checked' : 'unchecked'"></mat-pseudo-checkbox>
            <span class="spacer"></span>
        </ng-container>
        <fa-icon *ngIf="file.LinkedDocumentId" class="link-icon" matTooltip="dies ist eine Verknüpfung" [icon]="FileLinkIcon"></fa-icon>
        <ng-container *ngIf="removeButton">
            <span class="spacer"></span>
            <button mat-icon-button color="accent" class="badge">
                <fa-icon icon="times-circle" (click)="$event.stopPropagation(); $event.stopImmediatePropagation(); $event.preventDefault(); RemoveClick.emit(file)"></fa-icon>
            </button>
        </ng-container >
    </div>
    <div class="footer">
        <ng-container *ngIf="file.State === DocumentState.DocumentStateChecked">
            <span class="spacer-bottom"></span>
            <button mat-icon-button class="icon">
                <fa-icon icon="check-circle"></fa-icon>
            </button>
        </ng-container>
    </div>
    <div *ngIf="file.thumbnailPath" class="image-wrapper">
        <div class="icon-wrapper" #button>
            <fa-icon [icon]="file.FileIcon"></fa-icon>
        </div>
        <img #img [class.hide]="!img.id" (load)="img.id = '1'; file.thumbnailPath && button.remove()" [src]="file.thumbnailPath" />
    </div>
    <div *ngIf="!file.thumbnailPath" class="image-wrapper">
        <fa-icon [icon]="file.FileIcon"></fa-icon>
    </div>
    <p class="button-text" *ngIf="ShowFileName">{{ file.Name }}</p>
</button>
