import { createAction, props } from '@ngrx/store';
import { AkkordToCommissionEntity } from '../../entities/akkord/akkord-to-commisson.entity';
import { AkkordEntity } from '../../entities/akkord/akkord.entity';
import { DaveMutationChangeAkkord2CommissionArgs, DaveMutationCreateAkkord2CommissionArgs, DaveMutationDeleteAkkord2CommissionArgs } from '../../graphql-types';

export const AkkordActionTypes = {
    LoadAll: createAction('data/akkord/loadAll'),
    UpdateAll: createAction('data/akkord/updateAll', props<{ Payload: Array<AkkordEntity> }>()),
};
export const AkkordToCommissionActionTypes = {
    LoadAll: createAction('data/akkordToCommission/loadAll'),
    UpdateAll: createAction('data/akkordToCommission/updateAll', props<{ Payload: Array<AkkordToCommissionEntity> }>()),
    Change: createAction('data/akkordToCommission/modify', props<{ Payload: DaveMutationChangeAkkord2CommissionArgs }>()),
    Create: createAction('data/akkordToCommission/create', props<{ Payload: DaveMutationCreateAkkord2CommissionArgs }>()),
    Delete: createAction('data/akkordToCommission/delete', props<{ Payload: DaveMutationDeleteAkkord2CommissionArgs }>()),
    UpdateOne: createAction('data/akkordToCommission/updateOne', props<{ Payload: AkkordToCommissionEntity }>()),
};
