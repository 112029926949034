import { StatusFromSvType } from '../graphql-types';

export enum CommissionSvStatusCodes {
    angelegt = 'Angelegt',
    ortstermin = 'Ortstermin',
    gutachten = 'Gutachten',
    bearbeitung = 'in Bearbeitung',
    rechnungsstellung = 'Rechnungsstellung',
    erledigt = 'Erledigt',
}
export class StatusFromSVEntity {
    constructor(
        public Id: number = null,
        public Name: string = null,
        public PartnerId: number = null,
    ) {
    }

    public Clone(override: StatusFromSVEntityAttributes = {}): StatusFromSVEntity {
        const attr = {...this, ...override};
        return Object.assign(new StatusFromSVEntity(), attr);
    }
}

export function StatusFromSVEntityFromBackend(res: StatusFromSvType): StatusFromSVEntity {
    return new StatusFromSVEntity(
        res.id,
        res.name,
        res.partnerId,
    );
}


export interface StatusFromSVEntityAttributes {
    Id?: number;
         Namme?: string;
         PartnerId?: number ;
        }
