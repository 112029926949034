import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ProcessTemplateEntity } from '../../entities/process-template.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { ProcessTemplateActions } from '../actions/process-template.actions';

export const PROCESS_TEMPLATE_FEATURE_KEY = 'process-template';

export interface ProcessTemplateState extends EntityState<ProcessTemplateEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<ProcessTemplateEntity>({
    selectId: (Entity) => Entity.Id,
});

const initialState: ProcessTemplateState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const processTemplateReducer = createReducer(
    initialState,
    on(ProcessTemplateActions.updateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, {
            ...state,
            fetched: true,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(ProcessTemplateActions.updateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllProcessTemplate = selectAll;
export const selectProcessTemplateEntities = selectEntities;

export const selectProcessTemplateFetched = (state: ProcessTemplateState) => state.fetched;
export const selectProcessTemplateLatestUpdatedAt = (state: ProcessTemplateState) => state.latestUpdatedAt;
