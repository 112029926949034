import { createFeatureSelector,createSelector } from '@ngrx/store';
import {
BusinessVolumeState,
BUSINESS_VOLUME_KEY,
selectAllBusinessVolume,
selectBusinessVolumeEntities,
selectBusinessVolumeFetched,
selectBusinessVolumeLatestUpdatedAt
} from '../reducers/business-volume.reducer';
import { getEventDictionary } from "./events.selectors";

const selectState = createFeatureSelector<BusinessVolumeState>(BUSINESS_VOLUME_KEY);
const getEntities = createSelector(selectState, selectBusinessVolumeEntities);

export const getBusinessVolume = createSelector(selectState, selectAllBusinessVolume);
export const getBusinessVolumeFetched = createSelector(selectState, selectBusinessVolumeFetched);
export const getBusinessVolumeUpdatedAt = createSelector(selectState, selectBusinessVolumeLatestUpdatedAt);

export const getBusinessVolumeById = (props: { id: number }) => createSelector(getEntities, (dictionary) => props?.id && dictionary[props.id]);
