import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { Person2EntityActionTypes } from '../State/actions/person2entity.action';
import { getPerson2EntitiesFetched, getPerson2EntityLatestUpdatedAt } from '../State/selectors/person2entity.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class Person2EntityResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getPerson2EntitiesFetched, getPerson2EntityLatestUpdatedAt, Person2EntityActionTypes.Load, store);
    }
}
