import { QMStatusEntity } from '../../entities/qMStatus.entity';
import { createReducer, on } from '@ngrx/store';
import { QMStatusActionTypes } from '../actions/qMStatus.actions';


export const QMStatusFeatureKey = 'qMStatus';

export interface QMStatusState {
    QMStatus: QMStatusEntity[];
}

export const initialState: QMStatusState = {
    QMStatus: null,
};

export const QMStatusReducer = createReducer(
    initialState,
    on(QMStatusActionTypes.UpdateQMStatus, (state, { Payload }) => ({
        ...state,
        QMStatus: Payload,
    })),
);
