import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getLatestUpdatedAt } from '../../../helper/reducer.helper';
import { ResourceEntity } from '../../../entities/resource-dispo/resource.entity';
import { ResourceActionTypes } from '../../actions/resource-dispo/resource.actions';

export const RESOURCE_KEY = 'resource';

export interface ResourceState extends EntityState<ResourceEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;
}

const adapter = createEntityAdapter<ResourceEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const resourcesReducer = createReducer(
    initialState,
    on(ResourceActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(ResourceActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(ResourceActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
    on(ResourceActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, state)),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllResource = selectAll;
export const selectResourceEntities = selectEntities;

export const selectResourceFetched = (state: ResourceState) => state.fetched;
export const selectResourceLatestUpdatedAt = (state: ResourceState) => state.latestUpdatedAt;
