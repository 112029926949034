import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-hint-icon',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, MatTooltipModule],
    templateUrl: './hint-icon.component.html',
    styleUrls: ['./hint-icon.component.scss'],
})
export class HintIconComponent {
    @Input() hint: string;
    @ViewChild(MatTooltip) tooltip: MatTooltip
    constructor() {}
    onTooltipClick() {
        this.tooltip?.show()
    }
}
