import { createAction, props } from '@ngrx/store';
import { LicensePackageEntity } from '../../entities/license-package.entity';
import { LicenseEntity } from '../../entities/license.entity';

export const LicensesActionTypes = {
    LoadLicense: createAction('user/license/get'),
    UpdateLicense: createAction(
        'user/license/update',
        props<{ Payload: Array<LicenseEntity> }>(),
    ),
    UpdateAllLicenses: createAction(
        'user/license/updateAll',
        props<{ Payload: Array<LicenseEntity> }>(),
    ),
    DeleteLicense: createAction(
        'user/license/delete',
        props<{ Payload: number }>(),
    ),
    CancelLicense: createAction(
        'user/license/cancel',
        props<{ Payload: number }>(),
    ),
    LoadAllLicense: createAction('user/license/getAll'),
    LoadPackageLicense: createAction('user/licensePackage/get'),
    UpdateLicensePackage: createAction(
        'user/licensePackage/update',
        props<{ Payload: Array<LicensePackageEntity> }>(),
    ),
    AddLicenseOrder: createAction(
        'user/licenseOrder/add',
        props<{ Payload: any }>(),
    ),
};
