import { moveItemInArray } from "@angular/cdk/drag-drop";
import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy } from '@angular/core';
import { FormArray,FormControl,FormGroup,FormsModule,ReactiveFormsModule,Validators,ɵFormGroupValue } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogConfig,MatDialogModule,MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Permissions } from '@dave/types';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import moment,{ Moment } from 'moment';
import { combineLatest,firstValueFrom,Subject,Subscription } from 'rxjs';
import {
distinctUntilChanged,
filter,
map,shareReplay,
startWith,
switchMap,takeUntil
} from "rxjs/operators";
import { ClockInEntity,ClockInTypes } from '../../../dave-data-module/entities/clock-in.entity';
import { DaveMutationCreateClockInArgs } from '../../../dave-data-module/graphql-types';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { EmployeeResolver } from '../../../dave-data-module/guards/employee.resolver';
import {
    ComponentCanDeactivate,
    PENDING_CHANGES_DEFAULT_MESSAGE,
} from '../../../dave-data-module/guards/pending-changes.guard';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { getClockInTypeDictionary,getClockInTypesFetched } from '../../../dave-data-module/State/selectors/clock-in-type.selectors';
import { getClockInsWithoutDeleted } from '../../../dave-data-module/State/selectors/clock-in.selectors';
import {
    getCommissionDictionary,
    getCommissionsActive,
    getCommissionsFetched,
} from '../../../dave-data-module/State/selectors/commission.selector';
import { getEmployeeByUserId,getEmployeesFetched } from '../../../dave-data-module/State/selectors/employees.selectors';
import {
getPartnerMainOffice,
getPartnerOfficesFetched
} from "../../../dave-data-module/State/selectors/partners.selectors";
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { getWorkDays } from '../../../dave-data-module/State/selectors/work-day.selectors';
import { TimeTrackerService } from '../../../dave-time-tracker/time-tracker.service';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { SelectSearchModule } from '../../../dave-utils-module/select-search/select-search.module';
import {
appMatDialogDefaultConfig,
DEFAULT_START_TIME,
getMinimumBreakHours,
isNotNullOrUndefined,
sameDay,
sortClockIns
} from "../../../helper/helper";
import { ClockInHelperService } from "../../../services/clock-in-helper.service";
import { CustomLabelService } from '../../../services/custom-label.service';
import { LoadingService } from '../../../services/loading.service';
import { TimePickerSelectComponent } from '../../../time-picker-select/time-picker-select.component';
import { EndTimeDirective } from '../../../time-span-picker-select/end-time.directive';
import { StartTimeDirective } from '../../../time-span-picker-select/start-time.directive';
import { TimeSpanPickerSelectComponent } from '../../../time-span-picker-select/time-span-picker-select.component';
import {
    SelectSearchOption
} from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';

type additionalTimeSpanForm = {
    startTime: FormControl<string>;
    endTime: FormControl<string>;
    clockInTypeId: FormControl<number>;
    commissionId: FormControl<SelectSearchOption<CommissionEntity>>;
    note: FormControl<string>;
};
@Component({
    selector: 'app-day-time-tracker',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        AppButtonModule,
        MatInputModule,
        MatDatepickerModule,
        EndTimeDirective,
        FormsModule,
        StartTimeDirective,
        TimePickerSelectComponent,
        TimeSpanPickerSelectComponent,
        MatButtonModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatSelectModule,
        SelectSearchModule,
    ],
    templateUrl: './day-time-tracker.component.html',
    styleUrls: ['./day-time-tracker.component.scss'],
})
export class DayTimeTrackerComponent implements OnDestroy, ComponentCanDeactivate {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        disableClose: true,
    };
    private onDestroy = new Subject<void>();
    protected expandedIndex: number = null;
    protected form = new FormGroup({
        date: new FormControl<Moment>(moment(), Validators.required),
        startTime: new FormControl<string>(DEFAULT_START_TIME, Validators.required),
        endTime: new FormControl<string>(null),
        additionalTimeSpans: new FormArray<FormGroup<additionalTimeSpanForm>>([]),
    });
    protected clockInTypes$ = this.timeTrackerService.clockInTypesWithoutMain$;
    protected commissions$ = getFetched$(this.store, getCommissionsFetched, getCommissionsActive, this.commissionResolver);
    private employee$ = this.store.select(getUser).pipe(
        filter(isNotNullOrUndefined),
        map((u) => u.Id),
        distinctUntilChanged(),
        switchMap((userId) => getFetched$(this.store, getEmployeesFetched, getEmployeeByUserId({ userId }), this.employeeResolver)),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    protected hasPermissions$ = this.employee$.pipe(
        map((employee) => employee && employee.PermissionsFromEntityRoles.includes(Permissions.EditClockIn) /*&& employee.PermissionsFromEntityRoles.includes(Permissions.CreateClockIn)*/),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    private subs: Subscription[] = [];
    private workDays$ = this.store.select(getWorkDays).pipe(map((wd) => wd.filter((w) => !w.DeletedAt)));
    private workdaysFromDate$ = combineLatest([
        this.form.controls.date.valueChanges.pipe(startWith(this.form.value.date)),
        this.employee$.pipe(
            map((e) => e.Id),
            distinctUntilChanged(),
            switchMap((employeeId) => this.workDays$.pipe(map((workDays) => workDays.filter((w) => w.EmployeeId === employeeId)))),
        ),
    ]).pipe(
        takeUntil(this.onDestroy),
        map(([date, workdays]) => date && workdays.filter((c) => sameDay(c.Timestamp, date.toDate()))),
        shareReplay(1),
    );
    private clockInsFromEmployee$ = this.employee$.pipe(
        map((e) => e.Id),
        distinctUntilChanged(),
        switchMap((employeeId) => this.store.select(getClockInsWithoutDeleted).pipe(map((clockIns) => clockIns.filter((c) => c.EmployeeId === employeeId)))),
    );
    private mainPartnerOffice$ = getFetched$(this.store, getPartnerOfficesFetched, getPartnerMainOffice).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    private defaultStartTimeFromPartner$ = this.mainPartnerOffice$.pipe(map((po) => po?.AdditionalData?.defaultWorkTimeBegin || DEFAULT_START_TIME));
    private defaultClockInTypeFromPartner$ = this.mainPartnerOffice$.pipe(map((po) => po?.AdditionalData?.defaultClockInTypeId));
    private calculateDefaultEndClockInFromEmployeeWorkingHours$ = this.mainPartnerOffice$.pipe(map((po) => !!po?.AdditionalData?.calculateDefaultEndClockInFromEmployeeWorkingHours));
    constructor(
        protected ls: LoadingService,
        private store: Store<State>,
        private commissionResolver: CommissionResolver,
        private employeeResolver: EmployeeResolver,
        protected ps: PermissionService,
        protected cls: CustomLabelService,
        private timeTrackerService: TimeTrackerService,
        private dialogRef: MatDialogRef<DayTimeTrackerComponent>,
        private clockInHelperService: ClockInHelperService,
    ) {
        if (this.dialogRef) {
            this.subs.push(this.dialogRef.backdropClick().subscribe(() => {
                this.closeDialog();
            }))
        }
        this.subs.push(
            combineLatest([
                this.form.controls.date.valueChanges.pipe(startWith(this.form.value.date)),
                this.workdaysFromDate$,
                this.clockInsFromEmployee$,
                this.timeTrackerService.mainClockInType$,
                getFetched$(this.store, getClockInTypesFetched, getClockInTypeDictionary),
                this.defaultStartTimeFromPartner$,
                this.employee$,
                this.calculateDefaultEndClockInFromEmployeeWorkingHours$,
                getFetched$(this.store , getCommissionsFetched , getCommissionDictionary)
            ]).subscribe(([date, selectedWorkdays, clockIns, mainClockInType, clockInTypes, defaultStartTime, employee, calculateDefaultEnd, commissionDictionary]) => {
                if (date) {
                    // const selectedWorkdays = workdays.filter((c) => sameDay(c.Timestamp, date.toDate()));
                    const selectedWorkday = selectedWorkdays.length === 1 && selectedWorkdays[0];
                    this.form.controls.additionalTimeSpans.clear();
                    console.log({ defaultStartTime });
                    this.form.controls.startTime.setValue(defaultStartTime);
                    let endTime: string | null = null;
                    if (calculateDefaultEnd && employee.WeeklyWorkingHours?.length === 7) {
                        const dow = date.day(); // 0-6 0=Sunday
                        const workingHours = employee.WeeklyWorkingHours.slice();
                        moveItemInArray(workingHours, 6, 0);
                        const hours = workingHours[dow] || null;
                        if (hours && defaultStartTime.split(':').length === 2) {
                            const sh = +defaultStartTime.split(':')[0];
                            const sm = +defaultStartTime.split(':')[1];
                            const hoursToAdd = (hours + getMinimumBreakHours(hours)) * 60 * 60 * 1000;
                            const date = new Date(0, 0, 0, sh, sm);
                            date.setTime(date.getTime() + hoursToAdd);
                            endTime = date.getHours() + ':' + date.getMinutes();
                        }
                    }
                    this.form.controls.endTime.setValue(endTime);

                    if (selectedWorkday) {
                        const selectedClockIns = clockIns.filter((c) => c.WorkDayId === selectedWorkday.Id).sort(sortClockIns(mainClockInType.Id));
                        const startClockIn = selectedClockIns.find((c) => c.ClockInTypeId === mainClockInType.Id && c.Type === ClockInTypes.Start);
                        if (startClockIn) {
                            this.setFormByClockIn(
                                this.form.controls,
                                startClockIn,
                                selectedClockIns.find((c) => c.ClockInTypeId === startClockIn.ClockInTypeId && c.Type === ClockInTypes.End),
                            );
                        }

                        selectedClockIns.forEach((ci, i, arr) => {
                            if (ci.ClockInTypeId !== mainClockInType.Id && ci.Type === ClockInTypes.Start) {
                                let endClockIn: ClockInEntity;

                                for (let k = i + 1; k < arr.length; k++) {
                                    if ((arr[k].ClockInTypeId === ci.ClockInTypeId && arr[k].Type === ClockInTypes.End) || (arr[k].Type === ClockInTypes.Start && clockInTypes[arr[k].ClockInTypeId].IsWorkingTime)) {
                                        endClockIn = arr[k];
                                        break;
                                    }
                                }

                                this.addForm({
                                    startTime: ci.TimeStamp.getHours().toString().padStart(2, '0') + ':' + ci.TimeStamp.getMinutes().toString().padStart(2, '0'),
                                    endTime: endClockIn ? endClockIn.TimeStamp.getHours().toString().padStart(2, '0') + ':' + endClockIn.TimeStamp.getMinutes().toString().padStart(2, '0') : this.form.value.endTime,
                                    note: ci.WorkDescription,
                                    commissionId: commissionDictionary[ci?.CommissionId],
                                    clockInTypeId: ci.ClockInTypeId,
                                });
                            }
                        });
                    }
                }
            }),
        );
    }
    public setFormByClockIn(forms: { startTime: FormControl<string>; endTime: FormControl<string> }, startClockIn: ClockInEntity, endClockIn?: ClockInEntity) {
        forms.startTime.setValue(startClockIn.TimeStamp.getHours().toString().padStart(2, '0') + ':' + startClockIn.TimeStamp.getMinutes().toString().padStart(2, '0'));
        forms.endTime.setValue(endClockIn ? endClockIn.TimeStamp.getHours().toString().padStart(2, '0') + ':' + endClockIn.TimeStamp.getMinutes().toString().padStart(2, '0') : null);
    }
    ngOnDestroy(): void {
        this.onDestroy.next();
        this.subs.forEach((s) => s.unsubscribe());
    }
    protected addForm(defaults?: ɵFormGroupValue<additionalTimeSpanForm>) {
        this.form.controls.additionalTimeSpans.push(
            new FormGroup<additionalTimeSpanForm>({
                startTime: new FormControl<string>(defaults?.startTime !== undefined ? defaults.startTime : null, Validators.required),
                endTime: new FormControl<string>(defaults?.endTime !== undefined ? defaults.endTime : null, Validators.required),
                clockInTypeId: new FormControl<number>(defaults?.clockInTypeId !== undefined ? defaults.clockInTypeId : null, Validators.required),
                commissionId: new FormControl <SelectSearchOption<CommissionEntity>>(defaults?.commissionId ? defaults.commissionId : null),
                note: new FormControl<string>(defaults?.note !== undefined ? defaults.note : null),
            }),
        );
    }
    protected submit() {
        this.ls.startLoading('app-day-time-tracker-submit');
        firstValueFrom(combineLatest([this.clockInsFromEmployee$, this.timeTrackerService.mainClockInType$, this.workdaysFromDate$, this.employee$])).then(([clockIns, mainClockInType, workdaysFromDate, employee]) => {
            const clockInsToDelete = clockIns.filter((c) => workdaysFromDate.some((w) => w.Id === c.WorkDayId));
            const clockInsToAdd: DaveMutationCreateClockInArgs[] = [
                {
                    type: ClockInTypes.Start,
                    timeStamp: FrontendDate(new Date(this.form.value.date.year(), this.form.value.date.month(), this.form.value.date.date(), +this.form.value.startTime.split(':')[0], +this.form.value.startTime.split(':')[1], 0, 0)),
                    clockInTypeId: mainClockInType.Id,
                    employeeId: employee.Id,
                },
            ];
            if (this.form.value.endTime) {
                clockInsToAdd.push({
                    type: ClockInTypes.End,
                    timeStamp: FrontendDate(new Date(this.form.value.date.year(), this.form.value.date.month(), this.form.value.date.date(), +this.form.value.endTime.split(':')[0], +this.form.value.endTime.split(':')[1], 0, 0)),
                    clockInTypeId: mainClockInType.Id,
                    employeeId: employee.Id,
                });
            }
            this.form.controls.additionalTimeSpans.value.forEach((f) => {
                clockInsToAdd.push(
                    {
                        type: ClockInTypes.Start,
                        timeStamp: FrontendDate(new Date(this.form.value.date.year(), this.form.value.date.month(), this.form.value.date.date(), +f.startTime.split(':')[0], +f.startTime.split(':')[1], 0, 0)),
                        clockInTypeId: f.clockInTypeId,
                        commissionId: f.commissionId?.Id,
                        workDescription: f.note,
                        employeeId: employee.Id,
                    },
                    {
                        type: ClockInTypes.End,
                        timeStamp: FrontendDate(new Date(this.form.value.date.year(), this.form.value.date.month(), this.form.value.date.date(), +f.endTime.split(':')[0], +f.endTime.split(':')[1], 0, 0)),
                        clockInTypeId: f.clockInTypeId,
                        commissionId: f.commissionId?.Id,
                        workDescription: f.note,
                        employeeId: employee.Id,
                    },
                );
            });
            this.clockInHelperService
                .dispatchClockInActions(
                    clockInsToAdd,
                    [],
                    clockInsToDelete.map((c) => ({ id: c.Id })),
                )
                .subscribe(() => {
                    this.ls.endLoading('app-day-time-tracker-submit');
                    this.dialogRef?.close();
                });
            // let error = false;
            // this.actions$
            //     .pipe(ofType(BaseActionTypes.ErrorAction, ClockInActionTypes.UpdateMany, ClockInActionTypes.AddClockInsSuccess), take(clockInsToDelete.length + 1))
            //     .pipe(
            //         tap((action) => {
            //             if (action.type === BaseActionTypes.ErrorAction.type) {
            //                 error = true;
            //             }
            //         }),
            //         last(),
            //     )
            //     .subscribe(() => {
            //         this.ls.endLoading('app-day-time-tracker-submit');
            //         if (!error) {
            //             this.dialogRef?.close();
            //         }
            //     });
            // clockInsToDelete.forEach((c) => this.store.dispatch(ClockInActionTypes.DeleteClockIn({ Payload: { id: c.Id } })));
            // this.store.dispatch(ClockInActionTypes.AddClockIns({ Payload: clockInsToAdd }));
        });
    }

    onAddFormClick() {
        firstValueFrom(this.defaultClockInTypeFromPartner$).then((clockInTypeId) => {
            this.addForm({
                startTime: this.form.controls.additionalTimeSpans.controls.length === 0 ? this.form.value.startTime : this.form.controls.additionalTimeSpans.controls[this.form.controls.additionalTimeSpans.length - 1].value.endTime,
                endTime: this.form.value.endTime,
                clockInTypeId: clockInTypeId || null,
            });
            this.expandedIndex = this.form.controls.additionalTimeSpans.controls.length - 1;
        });
    }

    onDeleteClick(index: number) {
        this.form.controls.additionalTimeSpans.removeAt(index);
    }

    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): boolean {
        return !this.form.dirty;
    }
    closeDialog() {
        if (this.dialogRef) {
            if (this.canDeactivate() || confirm(PENDING_CHANGES_DEFAULT_MESSAGE)) {
                this.dialogRef.close();
            }
        }
    }
}
