import { createAction, props } from '@ngrx/store';
import { DamageFlowTypeEntity } from '../../entities/damageFlowType.entity';


export const DamageFlowTypeActionTypes = {
    UpdateDamageFlowType: createAction(
        'data/DamageFlowType/update',
        props<{ Payload: Array<DamageFlowTypeEntity> }>(),
    ),
    GetDamageFlowTypeRequest: createAction(
        'data/DamageFlowType/getAll',
    ),
};

