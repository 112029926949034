import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { HomeDashboardComponent } from './components/dashboard/home-dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { VerifyOrderComponent } from './components/verify-order/verify-order.component';
import { ClockInResolver } from './dave-data-module/guards/clock-in.resolver';
import { CommentResolver } from './dave-data-module/guards/comment.resolver';
import { CommissionResolver } from './dave-data-module/guards/commission.resolver';
import { CommissionTypeResolver } from './dave-data-module/guards/commissionType.resolver';
import { EmployeeToVacationResolver } from './dave-data-module/guards/employee-to-vacation.resolver';
import { EmployeeResolver } from './dave-data-module/guards/employee.resolver';
import { EntityRoleResolver } from './dave-data-module/guards/entity-role.resolver';
import { LicenseResolver } from './dave-data-module/guards/license.resolver';
import { ManageUserListResolver } from './dave-data-module/guards/manage-user-list.resolver';
import { NewsResolver } from './dave-data-module/guards/news.resolver';
import { PartnerOfficeResolver } from './dave-data-module/guards/partner-office.resolver';
import { PartnerResolver } from './dave-data-module/guards/partner.resolver';
import { PermissionsResolver } from './dave-data-module/guards/permissions.resolver';
import { PersonResolver } from './dave-data-module/guards/person.resolver';
import { Person2EntityResolver } from './dave-data-module/guards/person2Entity.resolver';
import { ProductResolver } from './dave-data-module/guards/product.resolver';
import { StatusFromBackofficeResolver } from './dave-data-module/guards/statusFromBackoffice.resolver';
import { StatusFromSVResolver } from './dave-data-module/guards/statusFromSv.resolver';
import { TokenResolver } from './dave-data-module/guards/token.resolver';
import { ValidTokenGuard } from './dave-data-module/guards/valid-token.guard';
import { VarianteTypeResolver } from './dave-data-module/guards/variante-type.resolver';
import { ViewStyleSettingResolver } from './dave-data-module/guards/viewStyleSetting.guard';
import { WorkDayResolver } from './dave-data-module/guards/work-day.resolver';
import { employeesFeatureKey } from './dave-data-module/State/reducers/employees.reducer';
import { productsFeatureKey } from './dave-data-module/State/reducers/products.reducer';
import { FileExplorerComponent } from './dave-file-explorer/components/file-explorer/file-explorer.component';
import { SpecialRouteLabel } from './dave-utils-module/dave-shared-components-module/services/route-label.service';
import {
    BookingPageMeta,
    CalendarPageMeta,
    CommissionMeta,
    ContactBookMeta,
    CustomerAdministrationMeta,
    DMSPageMeta,
    EmailPageMeta,
    GeneratedDocumentsPageMeta,
    HistoryMeta, MaterialPageMeta,
    PieceworkCalculationPageMeta,
    ProcessMeta,
    ReportsPageMeta,
    ResourcePageMeta,
    SettingsPageMeta,
    StatisticsPageMeta,
    TaskPageMeta,
    UserAdministrationMeta,
    VideodokumentationPageMeta,
} from './helper/page-metadata';
import { CustomReuseStrategy } from './helper/router.helper';
import { DashboardHelperService } from './services/dashboard-helper.service';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        // canActivate: [LoggedInGuard],
    },
    {
        path: '',
        component: HomeDashboardComponent,
        canActivate: [ValidTokenGuard],
        resolve: {
            token: TokenResolver,
            partner: PartnerResolver,
            licenses: LicenseResolver,
            [productsFeatureKey]: ProductResolver,
            varianteTypes: VarianteTypeResolver,
            permissions: PermissionsResolver,
            persons: PersonResolver,
            vacation: EmployeeToVacationResolver,
            [employeesFeatureKey]: EmployeeResolver,
            partnerOffices: PartnerOfficeResolver,
            clockIn: ClockInResolver,
            workDay: WorkDayResolver,
            statusSv: StatusFromSVResolver,
            commissions: CommissionResolver,
            viewStyleSetting: ViewStyleSettingResolver,
            commissionTypes: CommissionTypeResolver,
            person2Entities: Person2EntityResolver,
            statusFromBackOffice: StatusFromBackofficeResolver,
            entityRoles: EntityRoleResolver,
            newsResolver: NewsResolver,
            // für das icon in der ereignisliste
            comments: CommentResolver,
            ...DashboardHelperService.RequiredResolvers,
        },
    },
    {
        path: 'login/:name/:userId/:verificationCode',
        data: { breadcrumb: 'Aktivierung' },
        component: LoginComponent,
    },
    {
        path: 'order/:orderId/:verificationCode',
        data: { breadcrumb: 'Lizenzbestätigung' },
        component: VerifyOrderComponent,
        canActivate: [ValidTokenGuard],
        canActivateChild: [ValidTokenGuard],
        resolve: {
            token: TokenResolver,
            permissions: PermissionsResolver,
        },
    },
    {
        path: SettingsPageMeta.Path,
        loadChildren: () => import('./dave-settings-module/dave-settings.module').then((m) => m.DaveSettingsModule),
    },
    {
        path: BookingPageMeta.Path,
        loadChildren: () => import('./dave-booking/dave-booking.module').then((m) => m.DaveBookingModule),
    },
    {
        path: HistoryMeta.Path,
        loadChildren: () => import('./dave-history-module/dave-history.module').then((m) => m.DaveHistoryModule),
        resolve: {
            manageUser: ManageUserListResolver,
        },
    },
    {
        path: CustomerAdministrationMeta.Path,
        data: {
            breadcrumb: SpecialRouteLabel.Customeradministration,
            breadcrumbIcon: CustomerAdministrationMeta.Icon,
        },
        loadChildren: () => import('./dave-customer-administration-module/dave-customer-administration.module').then((m) => m.DaveCustomerAdministrationModule),
    },
    {
        path: ContactBookMeta.Path,
        data: {
            breadcrumb: ContactBookMeta.Breadcrumb,
            breadcrumbIcon: ContactBookMeta.Icon,
        },
        loadChildren: () => import('./dave-contact-book/dave-contact-book.module').then((m) => m.DaveContactBookModule),
    },
    {
        path: EmailPageMeta.Path,
        data: {
            breadcrumb: EmailPageMeta.Breadcrumb,
            breadcrumbIcon: EmailPageMeta.Icon,
        },
        loadChildren: () => import('./dave-email-module/dave-email.module').then((m) => m.DaveEmailModule),
    },
    {
        path: UserAdministrationMeta.Path,
        data: {
            breadcrumb: UserAdministrationMeta.Breadcrumb,
            breadcrumbIcon: UserAdministrationMeta.Icon,
        },
        loadChildren: () => import('./dave-employee-administration-module/dave-employee-administration.module').then((m) => m.DaveEmployeeAdministrationModule),
    },
    {
        path: 'partner',
        loadChildren: () => import('./dave-partner-administration-module/dave-partner-administration.module').then((m) => m.DavePartnerAdministrationModule),
    },
    {
        path: StatisticsPageMeta.Path,
        loadChildren: () => import('./dave-statistics-module/dave-statistics.module').then((m) => m.DaveStatisticsModule),
    },
    {
        path: CommissionMeta.Path,
        data: {
            breadcrumb: SpecialRouteLabel.Commissionadministration,
        },
        loadChildren: () => import('./dave-commission-module/dave-commission.module').then((m) => m.DaveCommissionModule),
    },
    {
        path: VideodokumentationPageMeta.Path,
        loadChildren: () => import('./dave-videodokumentation-module/dave-videodokumentation.module').then((m) => m.DaveVideodokumentationModule),
    },
    // {
    //     path: DocumentEditorMeta.Path,
    //     loadChildren: () => import('./dave-document-editor-module/dave-document-editor.module').then(m => m.DaveDocumentEditorModule),
    // },
    {
        path: DMSPageMeta.Path,
        data: { breadcrumb: DMSPageMeta.Breadcrumb },
        loadChildren: () => import('./dave-dms-module/dave-dms.module').then((m) => m.DaveDmsModule),
    },
    {
        path: ReportsPageMeta.Path,
        data: { breadcrumb: ReportsPageMeta.Breadcrumb },
        loadChildren: () => import('./dave-reports/dave-reports.module').then((m) => m.DaveReportsModule),
    },
    {
        path: GeneratedDocumentsPageMeta.Path,
        loadChildren: () => import('./dave-generated-documents/dave-generated-documents.module').then((m) => m.DaveGeneratedDocumentsModule),
    },
    {
        path: CalendarPageMeta.Path,
        loadChildren: () => import('./dave-calendar/dave-calendar.module').then((m) => m.DaveCalendarModule),
    },
    {
        path: TaskPageMeta.Path,
        loadChildren: () => import('./dave-tasks/dave-tasks.module').then((m) => m.DaveTasksModule),
    },
    {
        path: PieceworkCalculationPageMeta.Path,
        loadChildren: () => import('./dave-piecework-calculation/dave-piecework-calculation.module').then((m) => m.DavePieceworkCalculationModule),
    },
    {
        path: 'fileexplorer',
        component: FileExplorerComponent,
        resolve: {
            token: TokenResolver,
        },
    },
    {
        path: ResourcePageMeta.Path,
        loadChildren: () => import('./resource-dispo/resource-dispo.module').then((m) => m.ResourceDispoModule),
    },
    {
        path: MaterialPageMeta.Path,
        loadChildren: () => import('./resource-dispo/resource-dispo.module').then((m) => m.ResourceDispoModule),
    },
    {
        path: ProcessMeta.Path,
        loadChildren: () => import('./process/routes').then((r) => r.default), // todo remove .then(...) after angular update
    },
    { path: '**', redirectTo: '/' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', enableTracing: false, relativeLinkResolution: 'legacy' })],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: CustomReuseStrategy,
        },
    ],
})
export class AppRoutingModule {}
