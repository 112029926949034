import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { ResolverLoadTags } from '../State/actions/resolver.actions';
import { getTagFetched } from '../State/selectors/tag.selectors';
import { BaseEntityResolver } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class TagResolver extends BaseEntityResolver {
    constructor(store: Store<State>) {
        super(getTagFetched, new ResolverLoadTags(), store);
    }
}
