<header>
    <h1 mat-dialog-title><fa-icon *ngIf="DialogData.Data.HeaderIcon as icon" [icon]="icon"></fa-icon> {{ DialogData.Data.Headline }}</h1>
    <span class="spacer"></span>

</header>
<app-detail-list-template mat-dialog-content [Data]="DialogData.Data" [AutofocusIndex]="DialogData.AutofocusIndex" [Editing]="DialogData.Editing" [Inline]="true"> </app-detail-list-template>
<mat-dialog-actions class="actions">
    <button app-button Inverted mat-dialog-close Color="cancel">Abbrechen</button>
    <button app-round-button Color="red" *ngIf="DialogData.DeleteButton" [mat-dialog-close]="{ DialogData: DialogData, Action: 'delete' }" [matTooltip]="DialogData.DeleteButtonTooltip || 'Löschen'">
        <fa-icon icon="trash"></fa-icon>
    </button>
    <button app-button [Disabled]="DialogData.DisableSaveButton$ | async" [mat-dialog-close]="{ DialogData: DialogData, Action: 'save' }" [Color]="DialogData.AlternativeButtonColor">{{ DialogData.AlternativeButton ? DialogData.AlternativeButton : 'Speichern' }}</button>
</mat-dialog-actions>
