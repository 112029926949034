import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { sameDay } from '../../../helper/helper';

@Pipe({
    name: 'customDay',
})
export class CustomDayPipe extends DatePipe implements PipeTransform {
    transform(date: Date, timeFormat?: string, fallbackFormat?: string, todayFormat?: string): any {
        let dayString = '';
        if (sameDay(new Date(), date)) {
            dayString += todayFormat ? super.transform(date, todayFormat) : 'heute';
        }
        const yesterday = new Date(Date.now() - 86400000);
        // yesterday.setDate(date.getDate() - 1); This line makes the if statement below impossible to be true. I think it can be deleted.
        if (sameDay(yesterday, date)) {
            dayString += 'gestern';
        }
        if (!dayString) {
            dayString += super.transform(date, fallbackFormat);
        }
        return timeFormat ? dayString + super.transform(date, timeFormat) : dayString;
    };
}
