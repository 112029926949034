import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PersonTypeNames } from '../../entities/personType.entity';
import {
    personTypesFeatureKey,
    PersonTypeState,
    selectAllPersonType, selectPersonTypeEntities,
    selectPersonTypeFetched,
} from '../reducers/personType.reducer';

const selectState = createFeatureSelector<PersonTypeState>(personTypesFeatureKey);
export const getPersonTypeDictionary = createSelector(selectState, selectPersonTypeEntities);
export const getPersonTypes = createSelector(selectState, selectAllPersonType);
export const getPersonTypesActive = createSelector(getPersonTypes, (entitys) => entitys.filter(e => !e.DeletedAt));
export const getPersonTypesFetched = createSelector(selectState, selectPersonTypeFetched);
export const getPersonTypeById = (props: { id: number }) => createSelector(getPersonTypeDictionary, (dictionary) => props.id && dictionary[props.id]);

export const getFilterdPersonTypesForBeteiligtePersonen = createSelector(
    getPersonTypes,
    (personTypes: ReturnType<typeof getPersonTypes>) =>
        personTypes
            ? personTypes.filter(
                  pt =>
                      pt.Name.toLowerCase().includes('unternehmen') ||
                      pt.Name.toLowerCase().includes('mieter') ||
                      pt.Name === PersonTypeNames.ConstructionManager ||
                      pt.Name === PersonTypeNames.BuildingOwner ||
                      pt.Name.toLowerCase().includes('projektleiter') ||
                      pt.Name.toLowerCase().includes('hausverwaltung') ||
                      pt.Name === PersonTypeNames.Policyholder ||
                      pt.Name.toLowerCase().includes('anspruchsteller') ||
                      pt.Name.toLowerCase().includes('anspruchgegner') ||
                      pt.Name.toLowerCase().includes('verfahrensbevollmächtigter') ||
                      pt.Name.toLowerCase().includes('bevollmächtigter') ||
                      pt.Name.toLowerCase().includes('auftraggeber') ||
                      pt.Name.toLowerCase().includes('leistungsempfänger') ||
                      pt.Name.toLowerCase().includes('kaufmännischer mitarbeiter') ||
                      pt.Name.toLowerCase().includes('sonstige') ||
                      pt.Name.toLowerCase().includes('ausführendes unternehmen') ||
                      pt.Name === PersonTypeNames.Architect ||
                      pt.Name.toLowerCase().includes('facility manager'),
              )
            : null,
);
