import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, switchMap } from "rxjs";
import { filter, map, skip, takeUntil, tap } from 'rxjs/operators';
import * as SimpleBar from 'simplebar';
import { SimplebarAngularComponent } from 'simplebar-angular';
import { SearchResultEntity, SearchTypeEnum } from '../../dave-data-module/entities/search-result.entity';
import { UserEntity } from '../../dave-data-module/entities/user.entity';
import { State } from '../../dave-data-module/State';
import { BreakpointObserverService } from '../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { isNotNullOrUndefined, ProductName } from '../../helper/helper';
import { BreadcrumbsService } from '../../services/breadcrumbs.service';
import { LoadingService } from '../../services/loading.service';
import { getPartner } from '../../dave-data-module/State/selectors/partners.selectors';
import { PartnerTypeEnum } from '../../dave-data-module/entities/partner.entity';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
    public ProductName$ = this.store.select(getPartner).pipe(map(partner => partner?.PartnerTypeId === PartnerTypeEnum.YustInfra ? 'yust infra' : ProductName));
    public SelectedTypes: SearchTypeEnum[] = [];
    User$: Observable<UserEntity>;
    Mobile$: Observable<boolean>;

    ShowSearchBar = false;
    ShowSearchReturn = true;
    SearchReturn$: BehaviorSubject<SearchResultEntity> = new BehaviorSubject(null);

    Focus$: BehaviorSubject<boolean>;

    @Input() NavBarButton = false;
    @Input() NavBarOpen = true;
    @Output() NavBarOpenChange = new EventEmitter<boolean>();
    @ViewChild(SimplebarAngularComponent)
    private simplebar?: SimplebarAngularComponent;
    public AppTitle$ = this.bs.Breadcrumbs$.pipe(
        filter((v) => isNotNullOrUndefined(v) && !!v.length),
        switchMap((v) => v.length && v[v.length - 1].Label$),
    );
    private onDestroy$ = new Subject<void>();

    constructor(
        private bs: BreadcrumbsService,
        private store: Store<State>,
        // tslint:disable-next-line:naming-convention
        private breakPoints: BreakpointObserverService,
        public Router: Router,
        public LS: LoadingService,
    ) {
        this.User$ = this.store.select((state) => state.users.user);
        this.Focus$ = new BehaviorSubject<boolean>(false);
        this.Router.events
            .pipe(
                takeUntil(this.onDestroy$),
                filter((event) => event instanceof NavigationEnd),
                skip(1),
                tap(() => {
                    this.ShowSearchReturn = false;
                }),
            )
            .subscribe();
        this.SearchReturn$.subscribe(() => {
            this.ShowSearchReturn = true;
        });
        this.SearchReturn$.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
            if (value && this.simplebar) {
                (this.simplebar.SimpleBar as SimpleBar).getScrollElement().scrollTop = 0;
            }
        });
        this.Mobile$ = this.breakPoints.MobileQuery;
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    public ToggleSearchBar() {
        this.ShowSearchBar = !this.ShowSearchBar;
        this.Focus$.next(this.ShowSearchBar);
    }

    protected readonly ProductName = ProductName;
}
