import { CommonModule } from '@angular/common';
import { Component,Inject } from '@angular/core';
import { FormControl,FormGroup,ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from "@angular/material/card";
import { MatDialogConfig,MatDialogModule,MatDialogRef,MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Actions,ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { firstValueFrom } from "rxjs";
import { FileEntity } from "../dave-data-module/entities/file.entity";
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';
import { FilesActionTypes } from '../dave-data-module/State/actions/files.actions';
import { ErrorCodesFileActions } from '../dave-data-module/State/effects/file.effects';
import { AppButtonModule } from "../dave-utils-module/app-button-module/app-button.module";
import {
DaveSharedComponentsModule
} from "../dave-utils-module/dave-shared-components-module/dave-shared-components.module";
import { FilenameInputComponent } from '../filename-input/filename-input.component';
import { appMatDialogDefaultConfig } from "../helper/helper";
import { FileLinkIcon } from "../helper/page-metadata";
import { LoadingService } from "../services/loading.service";

export interface NewFileLinkComponentDialogData {
    fileId: number;
    defaultName?: string;
    folderId?: number;
}

@Component({
    selector: 'app-new-file-link',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, MatCardModule, MatDialogModule, AppButtonModule, FilenameInputComponent, MatFormFieldModule, ReactiveFormsModule],
    templateUrl: './new-file-link.component.html',
    styleUrls: ['./new-file-link.component.scss'],
})
export class NewFileLinkComponent {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
    };
    protected form = new FormGroup({
        name: new FormControl<string>(null),
    });
    private readonly fileId: number = null;
    private readonly folderId: number = null;
    constructor(
        private dialogRef: MatDialogRef<NewFileLinkComponent, undefined | FileEntity>,
        protected ls: LoadingService,
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA)
        dialogData: NewFileLinkComponentDialogData,
        private actions$: Actions,
    ) {
        if (dialogData) {
            this.form.reset({
                name: dialogData.defaultName !== undefined ? dialogData.defaultName : this.form.value.name,
            });
            this.fileId = dialogData.fileId;
            this.folderId = dialogData.folderId;
        }
    }
    submit() {
        if (this.form.invalid || !this.fileId) {
            this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodesFileActions.CreateLink } }));
            return;
        }
        this.ls.startLoading('app-new-file-link-submit');
        firstValueFrom(this.actions$.pipe(ofType(FilesActionTypes.CreateLinkFailure, FilesActionTypes.CreateLinkSuccess))).then((action) => {
            this.ls.endLoading('app-new-file-link-submit');
            if (action.type === FilesActionTypes.CreateLinkSuccess.type) {
                this.dialogRef.close(action.Payload);
            }
        });
        this.store.dispatch(
            FilesActionTypes.CreateLinkRequest({
                Payload: {
                    DocumentId: this.fileId.toString(),
                    Name: this.form.value.name,
                    FolderId: this.folderId ? this.folderId.toString() : undefined,
                },
            }),
        );
    }

    protected readonly FileLinkIcon = FileLinkIcon;
}
