import { Injectable } from '@angular/core';

import { EmployeeSalaryTypeEntity, EmployeeSalaryTypeEntityFromBackend } from '../../entities/employee-salary-type.entity';
import { DaveMutationChangeEmployeeSalaryTypeArgs, DaveMutationCreateEmployeeSalaryTypeArgs, DaveMutationDeleteEmployeeSalaryTypeArgs, EmployeeSalaryTypeType, QueryEmployeeSalaryTypeArgs } from '../../graphql-types';
import { GraphQlEffectService } from '../../services/graph-ql-effect.service';
import { EmployeeSalaryTypeActions } from '../actions/employee-salary-type.actions';

enum ErrorCodes {
    Load = 'Mitarbeiterlohnart Abrufen fehlgeschlagen',
    Modify = 'Mitarbeiterlohnart Bearbeiten fehlgeschlagen',
    Remove = 'Mitarbeiterlohnart Löschen fehlgeschlagen',
    Add = 'Mitarbeiterlohnart Hinzufügen fehlgeschlagen',
}

@Injectable()
export class EmployeeSalaryTypeEffects {
    CreateEmployeeSalaryType$ = this.graphQlEffectService.createAddEffect<DaveMutationCreateEmployeeSalaryTypeArgs, EmployeeSalaryTypeEntity, EmployeeSalaryTypeType>(
        'createEmployeeSalaryType',
        EmployeeSalaryTypeEntity.GqlFields.join(','),
        EmployeeSalaryTypeActions.add,
        EmployeeSalaryTypeActions.updateOne,
        EmployeeSalaryTypeEntityFromBackend,
        ErrorCodes.Add,
        ['date', 'employeeId', 'name'],
        ['amount', 'bearbeitungsschluessel', 'commissionId', 'description', 'percentAmountFromSalary', 'salaryTypeNumber'],
    );
    ModifyEmployeeSalaryType$ = this.graphQlEffectService.createModifyEffect<DaveMutationChangeEmployeeSalaryTypeArgs, EmployeeSalaryTypeEntity, EmployeeSalaryTypeType>(
        'changeEmployeeSalaryType',
        EmployeeSalaryTypeEntity.GqlFields.join(','),
        EmployeeSalaryTypeActions.change,
        EmployeeSalaryTypeActions.updateOne,
        EmployeeSalaryTypeEntityFromBackend,
        ErrorCodes.Modify,
        ['id'],
        ['amount', 'bearbeitungsschluessel', 'commissionId', 'date', 'description', 'employeeId', 'name', 'percentAmountFromSalary', 'salaryTypeNumber'],
    );
    RemoveEmployeeSalaryType$ = this.graphQlEffectService.createDeleteOneEffect<DaveMutationDeleteEmployeeSalaryTypeArgs, EmployeeSalaryTypeEntity, EmployeeSalaryTypeType>(
        'deleteEmployeeSalaryType',
        EmployeeSalaryTypeEntity.GqlFields.join(','),
        EmployeeSalaryTypeActions.delete,
        EmployeeSalaryTypeActions.removeOne,
        (v) => v.id,
        ErrorCodes.Remove,
        ['id'],
    );
    GetEmployeeSalaryType = this.graphQlEffectService.createGetAllEffect<QueryEmployeeSalaryTypeArgs, EmployeeSalaryTypeEntity, EmployeeSalaryTypeType>(
        'employeeSalaryType',
        EmployeeSalaryTypeEntity.GqlFields.join(','),
        EmployeeSalaryTypeActions.load,
        EmployeeSalaryTypeActions.updateAll,
        (a) => a.map(EmployeeSalaryTypeEntityFromBackend),
        ErrorCodes.Load,
    );

    constructor(private graphQlEffectService: GraphQlEffectService) {}
}
