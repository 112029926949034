import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DaveEventCardModule } from '../dave-event-card/dave-event-card.module';
import { DaveFilePreviewDialogModule } from '../dave-file-preview-dialog/dave-file-preview-dialog.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../dave-utils-module/app-filter-module/app-filter.module';
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { TaskPageComponent } from './components/task-page/task-page.component';
import { TasksBoardComponent } from './components/tasks-board/tasks-board.component';
import { DaveTasksRoutingModule } from './dave-tasks-routing.module';

@NgModule({
    declarations: [TasksBoardComponent, TaskPageComponent],
    imports: [
        CommonModule,
        DaveTasksRoutingModule,
        MatExpansionModule,
        MatChipsModule,
        FontAwesomeModule,
        MatListModule,
        MatButtonModule,
        MatCardModule,
        DaveSharedComponentsModule,
        MatDialogModule,
        AppButtonModule,
        DropzoneModule,
        MatTooltipModule,
        DaveEventCardModule,
        AppFilterModule,
        MatSidenavModule,
        DaveFilePreviewDialogModule,
        MatBadgeModule,
        DragDropModule,
        MatMenuModule,
    ],
    exports: [TasksBoardComponent],
})
export class DaveTasksModule {}
