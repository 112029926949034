import { createAction, props } from '@ngrx/store';
import { DaveMutationChangeEmployee2CommissionArgs } from '../../graphql-types';
import { EmployeeToCommissionEntity } from '../../entities/employee-to-commission.entity';

export const EmployeeToCommissionActionTypes = {
    LoadAll: createAction('data/EmployeeToCommission/loadAll'),
    SetAll: createAction('data/EmployeeToCommission/updateAll', props<{ Payload: Array<EmployeeToCommissionEntity> }>()),
    Change: createAction('data/EmployeeToCommission/modify', props<{ Payload: DaveMutationChangeEmployee2CommissionArgs }>()),
    Create: createAction('data/EmployeeToCommission/add', props<{ Payload: DaveMutationChangeEmployee2CommissionArgs }>()),
    Delete: createAction('data/EmployeeToCommission/delete', props<{ Payload: DaveMutationChangeEmployee2CommissionArgs }>()),
    SetOne: createAction('data/EmployeeToCommission/updateOne', props<{ Payload: EmployeeToCommissionEntity }>()),
    RemoveOne: createAction('data/EmployeeToCommission/RemoveOne', props<{ Payload: EmployeeToCommissionEntity }>()),
};
