import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { State } from '../../../../dave-data-module/State';
import { ConstructionDiaryEntity, ConstructionDiaryStatusEnum } from '../../../../dave-data-module/entities/construction-diary.entity';
import { getConstructionDiaries } from '../../../../dave-data-module/State/selectors/construction-diary.selectors';
import { appMatDialogDefaultConfig, sameDay } from '../../../../helper/helper';
export interface SelectDiaryDateDialogComponentDialogData {
    CommissionId: number;
}
@Component({
    selector: 'app-select-diary-date-dialog',
    templateUrl: './select-diary-date-dialog.component.html',
    styleUrls: ['./select-diary-date-dialog.component.scss'],
})
export class SelectDiaryDateDialogComponent implements OnInit, OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
    };
    public DateForm: FormControlTyped<Moment> = new UntypedFormControl(moment());
    public Diaries: ConstructionDiaryEntity[];
    private subs: Subscription;
    public IsToday$ = this.DateForm.valueChanges.pipe(startWith(this.DateForm.value), map(date => sameDay(new Date(), date.toDate())));
    DateClass = (d: Moment): MatCalendarCellCssClasses => {
        const date = d.toDate();
        const diary = this.Diaries.find(d => sameDay(d.Date, date));
        return diary?.GeneratedFileId ? 'mat-datepicker-blue' : diary?.Status === ConstructionDiaryStatusEnum.Newly ? 'mat-datepicker-yellow' : diary?.Status === ConstructionDiaryStatusEnum.Done ? 'mat-datepicker-green' : '';    };
    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA)
        public DialogData: SelectDiaryDateDialogComponentDialogData,
    ) {}
    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }

    ngOnInit(): void {
        this.subs = this.store.select(getConstructionDiaries).subscribe(diaries => (this.Diaries = diaries?.filter(d => d.CommissionId === this.DialogData.CommissionId)));
    }
}
