import { Component, EventEmitter, Inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ListCartInputData } from '../../dave-list-card.component';
import { appMatDialogDefaultConfig } from '../../../helper/helper';

export interface ListCardDialogComponentDialogData<T> {
    Data?: ListCartInputData<T>;
    Data$?: Observable<ListCartInputData<T>>;
    DisplayedCollums: Array<keyof T>;
    Clickable: boolean;
    GetRouterLinkFunction?: (args: T) => any[] | null | undefined;
    TableHeaderCollumsMap?: {}; //Map<keyof T, string> //{ [key: keyof T]: string};
    HasSuffixButton?: boolean;
    Headline: string;
    EmptyText?: string;
    Search?: boolean;
    footer?: TemplateRef<any>;
    CustomCellTemplates?: {[key: string]: TemplateRef<any>}
    headerSuffixTemplate?: TemplateRef<any>
}
@Component({
    selector: 'app-list-card-dialog',
    templateUrl: './list-card-dialog.component.html',
    styleUrls: ['./list-card-dialog.component.scss'],
})
export class ListCardDialogComponent<T> implements OnInit, OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        panelClass: [...appMatDialogDefaultConfig.panelClass, 'custom-dialog-class-without-padding'],
        minWidth: '20rem',
        width: '30vw',
        maxWidth: '100vw',
        closeOnNavigation: true,
    };
    public EntryClicked$ = new EventEmitter<{ entry: T; index: number }>();
    private routerSubscription: Subscription;
    public SearchForm = new FormControl<string>('');
    public SearchVisible = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public DialogData: ListCardDialogComponentDialogData<T>,
        private router: Router,
        private dialogRef: MatDialogRef<ListCardDialogComponent<T>>,
    ) {}

    ngOnInit(): void {
        this.routerSubscription = this.router.events
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationStart),
                filter(() => !!this.dialogRef),
            )
            .subscribe(() => {
                this.dialogRef.close();
            });
    }
    ngOnDestroy() {
        this.routerSubscription?.unsubscribe();
    }
}
