<h1 mat-dialog-title>Zeit erfassen x</h1>
<mat-dialog-content>
    <ng-container *ngIf="Page === 1">
        <ng-container *ngIf="!(LoadingService.IsLoading$ | async); else loading">
            <ng-container *ngIf="Commissions$ | async as commissions; else loading">
                <form [formGroup]="NewClockinForm" (ngSubmit)="SetPageTo2()" class="form">
                    <mat-form-field>
                        <mat-label>Datum</mat-label>
                        <input matInput [matDatepicker]="picker" [formControl]="NewClockinForm.controls.Day" (click)="picker.open()" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <app-textarea Key="Notiz" [Value]="NewClockinForm.controls.Note"></app-textarea>
                </form>
                <app-dave-list-card
                    class="commission-list"
                    [EmptyText]="'Keine aktiven ' + (cls.getMultiple$('Commission') | async) + ' vorhanden'"
                    [Data]="commissions"
                    [DisplayedCollums]="['DisplayName']"
                    [Clickable]="NewClockinForm.valid"
                    (EntryClicked$)="NewClockinForm.controls.CommissionId.setValue($event.entry.Id); SetPageTo2()"
                    [CustomHeaderTemplate]="searchHeader"
                >
                </app-dave-list-card>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="Page === 2">
        <div class="info-icon-wrapper">
            <section>
                <p class="mt0">{{cls.getSingle$('Commission') | async}}: {{ CommissionName$ | async }}</p>
                <p class="mt0">Datum: {{ NewClockinForm.value.Day?.toDate() | date }}</p>
                <mat-form-field appearance="fill" matTooltip="Startzeit für alle setzen">
                    <mat-label>Startzeit</mat-label>
                    <input type="time" matInput  [formControl]="StartTimeForm" #input class="hide-icon-from-browser"/>
                    <fa-icon class="picker-icon" icon="clock" (click)="OpenHTMLInputPicker(input)" matSuffix></fa-icon>
                </mat-form-field>
            </section>
            <fa-icon size="2x" icon="question-circle" matTooltip="Hin- und Rückfahrt"></fa-icon>
        </div>
        <ng-container *ngIf="Employees$ | async as employees">
            <p *ngIf="!employees.length">Keine Mitarbeiter zugewiesen</p>

            <div class="scroll-wrapper" *ngIf="!Loading">
                <div *ngFor="let employee of employees" class="employee-row">
                    <div class="label">{{ employee.DisplayName }}</div>
                    <div class="forms" *ngIf="!(employee.isOnHoliday$ | async); else absent">
                        <mat-form-field appearance="fill" [matTooltip]=" (DrivingTimeIsIncluded$ | async) ?  'Die Arbeitszeit muss mindestens Gesamtfahrzeit + Schlechtwetter betragen' : 'Die Arbeitszeit muss mindestens den Wert von Schlechtwetter betragen'" matTooltipPosition="above" [matTooltipDisabled]="(employee.workTimeForm.value >= (employee.drivingTimeForm.value + employee.badWeatherForm.value))">
                            <mat-label>Arbeitszeit</mat-label>
                            <input type="number" [min]=" (DrivingTimeIsIncluded$ | async) ?  (employee.drivingTimeForm.value + employee.badWeatherForm.value) : (employee.badWeatherForm.value)" [formControl]="employee.workTimeForm" matInput/>
                            <span matSuffix>
                                Std.
                            </span>
                        </mat-form-field>
                        <mat-form-field appearance="fill" matTooltip="Startzeit">
                            <mat-label>Startzeit</mat-label>
                            <input type="time" matInput [formControl]="employee.startTimeForm" #timeInput class="hide-icon-from-browser"/>
                            <fa-icon class="picker-icon" icon="clock" (click)="OpenHTMLInputPicker(timeInput)" matSuffix></fa-icon>
                        </mat-form-field>
                        <mat-form-field appearance="fill" matTooltip="Hin- und Rückfahrt" matTooltipPosition="above">
                            <mat-label>Gesamtfahrzeit</mat-label>
                            <input type="number" [min]="0" [max]=" (DrivingTimeIsIncluded$ | async) ?  (employee.workTimeForm.value - employee.badWeatherForm.value) : null" [formControl]="employee.drivingTimeForm" matInput/>
                            <span matSuffix>
                                Std.
                            </span>
                        </mat-form-field>
                        <mat-form-field appearance="fill" *ngIf="!(IsKagetec$ | async)">
                            <mat-label>Schlechtwetter</mat-label>
                            <input type="number" [min]="0" [max]=" (DrivingTimeIsIncluded$ | async) ?  (employee.workTimeForm.value - employee.drivingTimeForm.value) : employee.workTimeForm.value" [formControl]="employee.badWeatherForm" matInput/>
                            <span matSuffix>
                                Std.
                            </span>
                        </mat-form-field>
                    </div>
                    <ng-template #absent>
                        <I class="m1-0">Abwesend</I>
                    </ng-template>
                </div>
            </div>
            <app-loading *ngIf="Loading"></app-loading>
        </ng-container>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions *ngIf="Page === 1">
    <div class="cancel-button-wrapper button">
        <button Inverted type="button" app-button (click)="closeDialog()">Abbrechen</button>
    </div>
    <!--        <button app-button class="button" [Disabled]="NewClockinForm.invalid" (click)="SetPageTo2()">Auswählen</button>-->
</mat-dialog-actions>
<mat-dialog-actions *ngIf="Page === 2">
    <button Inverted Color="cancel" type="button" class="button" app-button (click)="closeDialog()" [Disabled]="Loading">Abbrechen</button>
    <button Color="cancel" (click)="Page = 1" type="button" class="button" app-button  style="margin-right: auto" [Disabled]="Loading">Zurück</button>
    <button app-button class="button" (click)="Save()" [IsLoading]="Loading" [Disabled]="SaveDisabled$ | async">Speichern</button>
</mat-dialog-actions>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
<ng-template #searchHeader>
    <div class="header">
        <h3 class="headline">{{cls.getMultiple$('Commission') | async}}</h3>
        <!-- search bar -->
        <mat-form-field *ngIf="SearchVisible" >
            <mat-label>Suche</mat-label>
            <input [formControl]="SearchForm" matInput appPrefixFocusAndSelect #input/>
            <button (click)="SearchForm.setValue('')" mat-icon-button matSuffix *ngIf="SearchForm.value">
                <fa-icon icon="times"></fa-icon>
            </button>
        </mat-form-field>

        <button app-round-button Inverted matTooltipPosition="left" [matTooltip]="SearchVisible ? 'Ausblenden' : 'Suche'" (click)="SearchVisible = !SearchVisible;">
            <fa-icon [icon]="SearchVisible ? 'times' : 'search'"></fa-icon>
        </button>
    </div>
</ng-template>
