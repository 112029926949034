import { LoadingComponent } from './loading.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
    declarations: [
        LoadingComponent,
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        LoadingComponent,
    ],
})
export class DaveLoadingModule {}
