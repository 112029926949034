import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
    DetailConstructionDiaryEntryComponent,
    DetailConstructionDiaryEntryComponentDialogData,
} from '../../../../dave-commission-module/components/detail-commission/detail-construction-diary-entry/detail-construction-diary-entry.component';
import { CommissionEntity } from '../../../../dave-data-module/entities/commission.entity';
import { PartnerTypeEnum } from '../../../../dave-data-module/entities/partner.entity';
import { PENDING_CHANGES_DEFAULT_MESSAGE } from '../../../../dave-data-module/guards/pending-changes.guard';
import { State } from '../../../../dave-data-module/State';
import { UsersActionTypes } from '../../../../dave-data-module/State/actions/users.actions';
import { getCommissions, getCommissionsActive } from '../../../../dave-data-module/State/selectors/commission.selector';
import { getCommissionTypes } from '../../../../dave-data-module/State/selectors/commissionType.selectors';
import { getEmployeeByUserId } from '../../../../dave-data-module/State/selectors/employees.selectors';
import { getPartner } from '../../../../dave-data-module/State/selectors/partners.selectors';
import { getSetting, getUser } from '../../../../dave-data-module/State/selectors/users.selectors';
import { DetailTasksComponent, DetailTasksComponentDialogData } from '../../../../dave-event-card/components/detail-tasks/detail-tasks.component';
import { SelectFolderDialogComponent, SelectFolderDialogComponentDialogData } from '../../../../dave-file-explorer/components/select-folder-dialog/select-folder-dialog.component';
import { ListCardDialogComponent, ListCardDialogComponentDialogData } from '../../../../dave-list-card/components/list-card-dialog/list-card-dialog.component';
import { isNotNullOrUndefined, SearchQueriesDebounceTime } from '../../../../helper/helper';
import { DMSPageMeta, UserAdministrationMeta } from '../../../../helper/page-metadata';
import { CustomLabelService } from '../../../../services/custom-label.service';
import { DashboardHelperService } from '../../../../services/dashboard-helper.service';
import { DayTimeTrackerComponent } from '../../../../time-tracker/components/day-time-tracker/day-time-tracker.component';
import { TimeTrackerMultipleComponent } from '../../../../time-tracker/components/time-tracker-multiple/time-tracker-multiple.component';
import { DaveFileUploadDialogComponentDialogData } from '../../../templates/new-document-view/component/dave-file-upload-dialog/dave-file-upload-dialog.component';
import { TimeTrackerKagetecComponent, TimeTrackerKagetecDialogData } from '../time-tracker-kagetec/time-tracker-kagetec.component';

@Component({
    selector: 'app-service-buttons',
    templateUrl: './service-buttons.component.html',
    styleUrls: ['./service-buttons.component.scss'],
})
export class ServiceButtonsComponent {
    @Input() FileUploadConfig: DaveFileUploadDialogComponentDialogData = {};
    public Buttons$: Observable<{ name: string; theme: string; icon: IconProp }[]> = combineLatest([
        this.store.select(getSetting),
        this.store.select(getUser).pipe(
            switchMap((u) => this.store.select(getEmployeeByUserId({ userId: u.Id }))),
            filter(isNotNullOrUndefined),
        ),
        this.store.select(getPartner),
        this.dashboardHelperService.GetDashboardItems$(),
    ]).pipe(
        map(([settings, employee, partner, dashboardItems]) => {
            const cards = dashboardItems
                .filter((card) => !card.Disabled && card.Buttons?.length)
                .map((c) => c.Buttons.map((b) => ({ ...b, theme: c.Theme })))
                .flat();

            const add: { name: string; icon: IconProp; theme: string }[] = [];
            if (settings?.ShowTimeTrackingClockWatch === undefined && settings?.ShowTimeTrackingPopup === undefined) {
                let newSettings = Object.assign({}, settings);
                if (partner.PartnerTypeId === PartnerTypeEnum.Kagetec && [10, 11].includes(employee?.JobSpecificationId)) {
                    newSettings.ShowTimeTrackingClockWatch = false;
                    newSettings.ShowTimeTrackingPopup = true;
                } else {
                    newSettings.ShowTimeTrackingClockWatch = true;
                    newSettings.ShowTimeTrackingPopup = false;
                }
                settings = newSettings;
                this.store.dispatch(
                    UsersActionTypes.UpdateUserSettings({
                        Payload: settings,
                    }),
                );
            }
            if (settings.ShowTimeTrackingClockWatch) {
                add.push({
                    name: 'timer',
                    icon: 'stopwatch',
                    theme: UserAdministrationMeta.Theme,
                });
            }
            if (settings.ShowTimeTrackingPopup) {
                add.push({
                    name: 'timer-popup',
                    icon: 'stopwatch',
                    theme: UserAdministrationMeta.Theme,
                });
            }
            if (settings.ShowTimeTrackingMultiplePopup) {
                add.push({
                    name: 'timer-multiple-popup',
                    icon: 'stopwatch',
                    theme: UserAdministrationMeta.Theme,
                });
            }
            if (settings.ShowDayTimeTrackingPopup) {
                add.push({
                    name: 'day-timer-popup',
                    icon: 'stopwatch',
                    theme: UserAdministrationMeta.Theme,
                });
            }
            add.push({
                name: 'photo-button',
                icon: 'camera',
                theme: DMSPageMeta.Theme,
            });
            // if (salaryType === SalaryTypes.PerHour) {
            cards.unshift(...add);
            // } else {
            //     cards.push(...add);
            // }
            return cards;
        }),
    );
    constructor(private dashboardHelperService: DashboardHelperService, private store: Store<State>, private dialog: MatDialog, protected cls: CustomLabelService) {}

    ShowButton(actionName: string): Observable<boolean> {
        if (actionName === 'diary-button') {
            return combineLatest([this.store.select(getCommissionTypes), this.store.select(getCommissions)]).pipe(
                map(([ct, commissions]) => {
                    const typesWithBtgb = ct?.filter((c) => c.AdditionalData?.ConstructionDiary).map((c) => c.Id);
                    return commissions.some((c) => c.CommissionTypeId && typesWithBtgb.includes(c.CommissionTypeId) && !c.Deleted);
                }),
            );
        }
        if (actionName === 'photo-button') {
            return of(true);
        }

        if (actionName === 'add-task-button') {
            return of(true);
        }
        if (actionName === 'timer-popup') {
            return of(true);
        }
        if (actionName === 'timer-multiple-popup') {
            return of(true);
        }
        if (actionName === 'day-timer-popup') {
            return of(true);
        }
    }

    ButtonClickHandler(actionName: string) {
        if (actionName === 'diary-button') {
            this.store
                .select(getCommissionsActive)
                .pipe(take(1), withLatestFrom(this.store.select(getUser), this.store.select(getCommissionTypes), this.cls.getMultiple$('Commission')))
                .subscribe(([commissions, user, commissionTypes, commissionLabel]) => {
                    const data = commissions
                        .filter((c) => /* c.UserIds?.includes(user.Id) && Alexander Bogdanow 14:57 */ commissionTypes.some((ct) => ct.Id === c.CommissionTypeId && ct.AdditionalData?.ConstructionDiary))
                        .sort((a, b) => {
                            if (a.UpdatedAt.getTime() < b.UpdatedAt.getTime()) {
                                return 1;
                            }
                            if (b.UpdatedAt.getTime() < a.UpdatedAt.getTime()) {
                                return -1;
                            }
                            return 0;
                        });
                    let filteredData$ = new BehaviorSubject(data.slice());
                    const dialogRef = this.dialog.open<ListCardDialogComponent<CommissionEntity>, ListCardDialogComponentDialogData<CommissionEntity>>(ListCardDialogComponent, {
                        ...ListCardDialogComponent.DefaultConfig,
                        data: {
                            Headline: commissionLabel,
                            EmptyText: 'Keine aktiven ' + commissionLabel + ' vorhanden',
                            Data$: filteredData$,
                            DisplayedCollums: ['DisplayName'],
                            Clickable: true,
                            HasSuffixButton: false,
                            Search: true,
                        },
                    });
                    dialogRef.afterOpened().subscribe((d) => {
                        dialogRef.componentInstance.SearchForm.valueChanges
                            .pipe(
                                takeUntil(dialogRef.afterClosed()),
                                debounceTime(SearchQueriesDebounceTime),
                                map((s) => s.toLowerCase()),
                            )
                            .subscribe((searchString) => {
                                if (searchString) {
                                    filteredData$.next(data.filter((e) => e.DisplayName.toLowerCase().includes(searchString)));
                                } else if (filteredData$.value.length !== data.length) {
                                    filteredData$.next(data.slice());
                                }
                            });
                        dialogRef.componentInstance.EntryClicked$.pipe(takeUntil(dialogRef.afterClosed())).subscribe((event) => {
                            dialogRef.close();
                            const commissionId = event.entry.Id;
                            const date = new Date();
                            this.dialog
                                .open<DetailConstructionDiaryEntryComponent, DetailConstructionDiaryEntryComponentDialogData>(DetailConstructionDiaryEntryComponent, {
                                    ...DetailConstructionDiaryEntryComponent.DefaultConfig,
                                    data: {
                                        CommissionId: commissionId,
                                        DiaryDate: date,
                                    },
                                })
                                .afterClosed()
                                .subscribe((res) => {
                                    if (res === 'open-time-tracking-kagetec') {
                                        this.dialog.open<TimeTrackerKagetecComponent, TimeTrackerKagetecDialogData>(TimeTrackerKagetecComponent, {
                                            ...TimeTrackerKagetecComponent.DefaultConfig,
                                            data: {
                                                DefaultValues: {
                                                    CommissionId: commissionId,
                                                    CommissionName: commissions.find((c) => c.Id === commissionId).DisplayName,
                                                    Date: date,
                                                },
                                            },
                                        });
                                    }
                                });
                        });
                    });
                });
        }

        if (actionName === 'add-task-button') {
            this.OpenDetailTaskDialog();
        }
        if (actionName === 'timer-popup') {
            this.OpenTimeDialog();
        }
        if (actionName === 'timer-multiple-popup') {
            this.OpenTimeMultiDialog();
        }
        if (actionName === 'day-timer-popup') {
            this.OpenDayTimerDialog();
        }
        if (actionName === 'photo-button') {
            const dialogRef = this.dialog.open<SelectFolderDialogComponent, SelectFolderDialogComponentDialogData>(SelectFolderDialogComponent, {
                ...SelectFolderDialogComponent.DefaultConfig,
                panelClass: [...(SelectFolderDialogComponent.DefaultConfig.panelClass || []), 'no-header'],
                data: {
                    ButtonText: 'HOCHLADEN',
                    matTreeNodePaddingIndent: 10,
                    uploadMode: true,
                },
            });
            // firstValueFrom(this.folderTreeService.Tree).then((folder) => {
            //     dialogRef.componentInstance.TreeDataSource = new MatTreeFlatDataSource(folderTreeControl, folderTreeFlattener, folder);
            // });
            // Tree Data Source
            // firstValueFrom(this.store.select(getFolderFetched)).then(f => {
            //          if (!f) {
            //            this.folderResolver.resolve();
            //          }
            //        });
            // this.store.select(getFolderFetched).pipe(
            //   tap(f => {
            //     if (!f) {
            //       this.folderResolver.resolve();
            //     }
            //   }),
            // filter(f => f),
            // switchMap(() => this.store.select(getFoldersWithoutHidden)),
            // take(1))
            //       .subscribe((folders) => {
            //           const selectedFolders = folders;
            //           // !f.PartnerId für den "GeteilteAufträge" Ordner
            //           const filteredFolders = selectedFolders?.length ? folders.filter((f) => !f.PartnerId || f.PartnerId === selectedFolders[0].PartnerId) : folders;
            //           const root = filteredFolders
            //               .slice()
            //               .filter((f) => !isNotNullOrUndefined(f.ParentId))
            //               .sort(sortByName);
            //           const data = root.map((f) => mapfoldertofood(f, filteredFolders.slice()).foodNodes);
            //
            //           const rootNode: FoodNode = {
            //               name: 'Hauptordner',
            //               children: data,
            //               Id: null,
            //               isFolder: true,
            //               disabled: false,
            //           };
            //           dialogRef.componentInstance.TreeDataSource = new MatTreeFlatDataSource(folderTreeControl, folderTreeFlattener, [rootNode]);
            //       });
            //const selectDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
            //this.dialog
            //    .open<DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData>(DaveFileUploadDialogComponent, {
            //      ...DaveFileUploadDialogComponent.DefaultConfig,
            //    data: { ...this.FileUploadConfig, FolderId: 0 },
            // })
            //   .afterClosed()
            //  .subscribe(() => {
            //      this.store
            //          .select(getFiles)
            //           .pipe(skip(1), take(1))
            //           .subscribe((files) => {
            //               const latestFile = [files.reduce((id, file) => (file.Id > id ? file.Id : id), -1)];
            //               this.moveFileFolderService.MoveFileFolder(latestFile, [], parentFolderId);
            //           });
            //   });
        }
    }

    public OpenTimeMultiDialog() {
        firstValueFrom(this.store.select(getSetting).pipe(map((s) => s.DarkMode))).then((isDarkTheme) => {
            this.dialog.open(TimeTrackerMultipleComponent, {
                ...TimeTrackerMultipleComponent.DefaultConfig,
                panelClass: [...TimeTrackerMultipleComponent.DefaultConfig.panelClass, UserAdministrationMeta.Theme, ...(isDarkTheme ? ['dark-theme'] : [])],
            });
        });
    }
    public OpenTimeDialog() {
        this.dialog.open(TimeTrackerKagetecComponent, TimeTrackerKagetecComponent.DefaultConfig);
    }
    public OpenDayTimerDialog() {
        const dialogRef = this.dialog.open(DayTimeTrackerComponent, {
            ...DayTimeTrackerComponent.DefaultConfig,
            disableClose: true,
        });
        dialogRef
            .backdropClick()
            .pipe(takeUntil(dialogRef.beforeClosed()))
            .subscribe((event) => {
                if (dialogRef.componentInstance.canDeactivate() || confirm(PENDING_CHANGES_DEFAULT_MESSAGE)) {
                    dialogRef.close();
                }
            });
    }

    OpenDetailTaskDialog(taskId?: number) {
        this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
            ...DetailTasksComponent.DefaultConfig,
            data: {
                EventId: taskId || null,
            },
        });
    }
}
