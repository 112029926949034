import { SelectSearchOption } from '../../dave-utils-module/select-search/components/select-search/select-search.component';
import { Address } from '../../helper/helper';
import { PersonType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export enum Salutation {
    herr = 'Herr',
    frau = 'Frau',
    familie = 'Familie',
    firma = 'Firma',
    life_companions = 'Lebensgefährten',
}

export enum PersonCreateType {
    Normal = 'NORMAL',
    Automatic = 'AUTOMATIC',
}

export class PersonEntity implements Address, SelectSearchOption {
    constructor(
        public Id: number = null,
        public CompanyName: string = '',
        public Street: string = '',
        public PostalCode: string = '',
        public City: string = '',
        public Country: string = '',
        public Salutation: Salutation = null,
        public Title: string = '',
        public Firstname: string = '',
        public Lastname: string = '',
        public Email: string = '',
        public PhoneNumber: string = '',
        public MobileNumber: string = '',
        public FaxNumber: string = '',
        public Description: string = '',
        public PersonTypeId: number = null,
        public PartnerId: number = null,
        public CreateType: PersonCreateType = null,
        public Deleted: boolean = false,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public Birthdate: Date = null,
        public SecondMail: string = '',
        public WorkNumber: string = '',
        public Organisation: string = '',
        public SecondFirstname: string = '',
        public SecondLastname: string = '',
    ) {}
    public static readonly GQLFields: Array<keyof PersonType> = [
        'city',
    'companyName',
    'country',
    'createdAt',
    'deleted',
    'description',
    'email',
    'faxNumber',
    'firstname',
    'id',
    'lastname',
    'mobileNumber',
    'partnerId',
    'personTypeId',
    'phoneNumber',
    'postalCode',
    'salutation',
    'street',
    'title',
    'createType',
    'updatedAt',
    'workNumber',
    'secondMail',
    'birthdate',
    'organisation',
    'secondLastname',
    'secondFirstname',
        ]

    public Clone(override: Partial<PersonEntity> = {}): PersonEntity {
        const attr = { ...this, ...override };
        return Object.assign(new PersonEntity(), attr);
    }
    get DisplayName(): string {
        const ret = [[this.Lastname, this.Firstname].filter(v => !!v).join(', ')];
        if (this.Salutation === Salutation.life_companions) {
            ret.push([this.SecondLastname, this.SecondFirstname].filter(v => !!v).join(', '))
        }
        if (ret.length) {
            return ret.join(' & ');
        } else {
            return this.Email ? this.Email : this.PhoneNumber || '';
        }
    }
    public get optionLabel(): string {
        return this.DisplayName;
    }
}
export function PersonEntityFromBackend(res: PersonType): PersonEntity {
    // @ts-ignore
    const createType: PersonCreateType = res.createType;

    return new PersonEntity(
        res.id,
        res.companyName,
        res.street,
        res.postalCode,
        res.city,
        res.country,
        res.salutation as Salutation,
        res.title,
        res.firstname,
        res.lastname,
        res.email,
        res.phoneNumber,
        res.mobileNumber,
        res.faxNumber,
        res.description,
        res.personTypeId,
        res.partnerId,
        createType,
        res.deleted,
        res.createdAt ? BackendDate(res.createdAt) : null,
        res.updatedAt ? BackendDate(res.updatedAt) : null,
        res.birthdate ? BackendDate(res.birthdate) : null,
        res.secondMail,
        res.workNumber,
        res.organisation,
        res.secondFirstname,
        res.secondLastname,
    );
}
