import { Component,Inject,ViewChild } from '@angular/core';
import { MatDialogConfig,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from "rxjs";
import { filter } from "rxjs/operators";
import { FileEntity } from '../../dave-data-module/entities/file.entity';
import { VersionEntity } from '../../dave-data-module/entities/version.entity';
import { PdfEditorComponent } from '../../dave-dms-module/dave-dms/pdf-editor/components/pdf-editor.component';
export const FullscreenDialogComponentConfig: MatDialogConfig = {
    ...new MatDialogConfig(),
    height: '90%',
    width: '90%',
    panelClass: ['custom-dialog-class-without-padding', 'custom-dialog-class-mobile-fullscreen', 'custom-dialog-class-tablet-fullscreen', 'no-header'],
    disableClose: true,
};
export interface FullscreenDialogData {
    file: FileEntity;
    version: VersionEntity;
    /**
     * works only with pdf files
     */
    signingMode?: boolean;
}

@Component({
    selector: 'app-fullscreen-dialog',
    template: `
        <button class="right-bottom" app-round-button (click)="DialogRef.close()" *ngIf="Data.file.MIMEType.indexOf('application/pdf') > -1">
            <fa-icon icon="compress"></fa-icon>
        </button>
        <button mat-icon-button (click)="DialogRef.close()" class="close-mat-dialog-button" matTooltip="Schließen" style="z-index: 10000;" *ngIf="Data.file.MIMEType.indexOf('image/') > -1">
            <fa-icon icon="times" size="2x"></fa-icon>
        </button>
        <app-image-editor *ngIf="Data.file.MIMEType.indexOf('image/') > -1" [File]="Data.file" [Version]="Data.version"></app-image-editor>
        <app-pdf-editor #pdfEditor [SigningMode]="Data.signingMode" *ngIf="Data.file.MIMEType.indexOf('application/pdf') > -1" [Version]="Data.version"></app-pdf-editor>
    `,
    styleUrls: ['./fullscreen-dialog.component.scss'],
})
export class FullscreenDialogComponent {
    @ViewChild('pdfEditor') PdfEditor: PdfEditorComponent;
    constructor(@Inject(MAT_DIALOG_DATA) public Data: FullscreenDialogData, public DialogRef: MatDialogRef<FullscreenDialogComponent>) {}
    public async Close() {
        if (this.PdfEditor) {
            await firstValueFrom(this.PdfEditor.LS.IsLoading$.pipe(filter((loading) => !loading)));
            if(!this.PdfEditor.canDeactivate() && confirm('Möchten Sie die Änderungen speichern ?')) {
                this.PdfEditor.Save();
                await firstValueFrom(this.PdfEditor.LS.IsLoading$.pipe(filter((loading) => !loading)));
            }
            this.PdfEditor?.Close();
        }
    }
}
