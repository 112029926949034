import { createReducer, on } from '@ngrx/store';
import { EmployeeEntity } from '../../entities/employee.entity';
import { EmployeeActionTypes } from '../actions/employee.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';


export const employeesFeatureKey = 'employees';

export interface EmployeesState extends EntityState<EmployeeEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}
const adapter = createEntityAdapter<EmployeeEntity>({
    selectId: Entity => Entity.Id,
    sortComparer: sortByName,
});

const initialConditionsState: EmployeesState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});
export function sortByName(a: EmployeeEntity, b: EmployeeEntity): number {
    return a.DisplayName.localeCompare(b.DisplayName);
}
export const employeesStateReducer = createReducer(
    initialConditionsState,
    on(EmployeeActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(EmployeeActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(EmployeeActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, { ...state })),

    on(EmployeeActionTypes.AddEmployee, (state, { Payload }) => adapter.addOne(Payload, { ...state })),
    on(EmployeeActionTypes.DeleteEmployee, (state, { Payload }) => adapter.removeOne(Payload, { ...state })),
);


/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllEmployee = selectAll;
export const selectEmployeeEntities = selectEntities;

export const selectEmployeeFetched = (state: EmployeesState) => state.fetched;
export const selectEmployeeLatestUpdatedAt = (state: EmployeesState) => state.latestUpdatedAt;
