import { EmailConnection2EmailSettingsTypeType } from '../graphql-types';

export class EmailConnection2EmailSettingsTypeEntity {
    constructor(
        public Id: number = null,
        public EmailConnectionId: number = null,
        public EmailSettingsTypeId: number = null,
        public PartnerId = null,
    ) {
    }

    public Clone(override: EmailConnection2EmailSettingsTypeEntityAttributes = {}): EmailConnection2EmailSettingsTypeEntity {
        const attr = {...this, ...override};
        return Object.assign(new EmailConnection2EmailSettingsTypeEntity(), attr);
    }
}

export function EmailConnection2EmailSettingsTypeEntityFromBackend(res: EmailConnection2EmailSettingsTypeType): EmailConnection2EmailSettingsTypeEntity {
    return new EmailConnection2EmailSettingsTypeEntity(
        res.id,
        res.emailConnectionId,
        res.emailSettingsTypeId,
        res.partnerId,

    );
}


export interface EmailConnection2EmailSettingsTypeEntityAttributes {
    Id?: number;
    EmailConnectionId?: null ;
    EmailSettingsTypeId?: null;
     PartnerId?: null;
        }
