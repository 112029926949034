import { ResourceType } from '../../graphql-types';
import { BackendDate } from '../../helper/backend-frontend-conversion.helper';
import { CustomFields } from '../../../custom-form-fields/custom-form-fields.helper';
import { GatewayResponse, GetTimeFromTimestamp, Resource } from '@dave/types';
import { ResourceResponse } from '@dave/types/dist/proto/erp/resource';


export enum AmountTypeEnum {
    Centimeter = 'CENTIMETER',
    Gramm = 'GRAMM',
    Kilogramm = 'KILOGRAMM',
    Kilometer = 'KILOMETER',

    Kubikcentimeter = 'KUBIKCENTIMETER',
    Kubikkilometer = 'KUBIKKILOMETER',
    Kubikmeter = 'KUBIKMETER',
    Liter = 'LITER',

    Meter = 'METER',
    Quadratcentimeter = 'QUADRATCENTIMETER',
    Quadratkilometer = 'QUADRATKILOMETER',
    Quadratmeter = 'QUADRATMETER',

    Std = 'STD',
    Stk = 'STK',
    Tone = 'TONE',
    Laufendermeter = 'LAUFENDERMETER',
}

export const AmountTypeEnumNames = new Map<AmountTypeEnum, string>([
    [AmountTypeEnum.Gramm, 'g'],
    [AmountTypeEnum.Kilogramm, 'kg'],
    [AmountTypeEnum.Kilometer, 'km'],
    [AmountTypeEnum.Liter, 'l'],

    [AmountTypeEnum.Std, 'Std.'],
    [AmountTypeEnum.Stk, 'Stk.'],
    [AmountTypeEnum.Tone, 't'],
    [AmountTypeEnum.Meter, 'm'],

    [AmountTypeEnum.Centimeter, 'cm'],
    [AmountTypeEnum.Kubikcentimeter, 'cm³'],
    [AmountTypeEnum.Kubikkilometer, 'km³'],
    [AmountTypeEnum.Kubikmeter, 'm³'],
    [AmountTypeEnum.Quadratcentimeter, 'cm²'],
    [AmountTypeEnum.Quadratkilometer, 'km²'],
    [AmountTypeEnum.Quadratmeter, 'm²'],
    [AmountTypeEnum.Laufendermeter, 'lfm'],
]);
export const AmountTypeEnumNamesLong = new Map<AmountTypeEnum, string>([
    [AmountTypeEnum.Gramm, 'Gramm'],
    [AmountTypeEnum.Kilogramm, 'Kilogramm'],
    [AmountTypeEnum.Kilometer, 'Kilometer'],
    [AmountTypeEnum.Liter, 'Liter'],

    [AmountTypeEnum.Std, 'Stunde'],
    [AmountTypeEnum.Stk, 'Stück'],
    [AmountTypeEnum.Tone, 'Tonne'],
    [AmountTypeEnum.Meter, 'Meter'],

    [AmountTypeEnum.Centimeter, 'Zentimeter'],
    [AmountTypeEnum.Kubikcentimeter, 'Kubikzentimeter'],
    [AmountTypeEnum.Kubikkilometer, 'Kubikkilometer'],
    [AmountTypeEnum.Kubikmeter, 'Kubikmeter'],
    [AmountTypeEnum.Quadratcentimeter, 'Quadratzentimeter'],
    [AmountTypeEnum.Quadratkilometer, 'Quadratkilometer'],
    [AmountTypeEnum.Quadratmeter, 'Quadratmeter'],
    [AmountTypeEnum.Laufendermeter, 'LaufenderMeter'],
]);
export class ResourceEntity {
    constructor(
        public Id: number = null,
        public ResourceTypeId: number = null,
        public Amount: number = null,
        // public FileId: number = null,
        public Cost: number = null,
        public MinChargeRate: number = null,
        public MaxChargeRate: number = null,
        public Name: string = null,
        public Kostenstelle: string = null,
        public AmountType: AmountTypeEnum = null,
        public Manufacturer: string = null,
        public ArticleNumber: string = null,
        public CustomFields: CustomFields[] = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
        public InventoryNumber: string = null,
        public QuantityTypeId: number = null,
        public StorageLocation: string = null,
        public GlobalTradeItemNr: string = null,
    ) {}
    public static EntityName = 'Gerät';

    public static readonly GqlFields: Array<keyof ResourceType> = [
        'id',
        'resourceTypeId',
        'amount',
        // 'fileId',
        'cost',
        'minChargeRate',
        'maxChargeRate',
        'name',
        'kostenstelle',
        'amountType',
        'manufacturer',
        'articleNumber',
        'customFields',
        'updatedAt',
        'deletedAt',
        'inventoryNumber',
        'quantityTypeId',
        'storageLocation',
        'globalTradeItemNr',
    ]
    public Clone(override: Partial<ResourceEntity> = {}): ResourceEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ResourceEntity(), attr);
    }
    public get DisplayName() {
        return [this.ArticleNumber, this.Name].filter(x => x).join(' ');
    }

}

export function ResourceEntityFromBackend(res: Resource): ResourceEntity {
    const amountTypeEnumValue = res.AmountType ? AmountTypeEnum[res.AmountType as keyof typeof AmountTypeEnum] : null;

    return new ResourceEntity(
        res.Id && +res.Id,
        res.ResourceTypeId && +res.ResourceTypeId,
        res.Amount && +res.Amount,
        // res.fileId,
        res.Cost && +res.Cost,
        res.MinChargeRate && +res.MinChargeRate,
        res.MaxChargeRate && +res.MaxChargeRate,
        res.Name,
        res.Kostenstelle,
        amountTypeEnumValue,
        res.Manufacturer,
        res.ArticleNumber,
        res.CustomFields && JSON.parse(res.CustomFields),
        res.UpdatedAt && GetTimeFromTimestamp(+res.UpdatedAt),
        res.DeletedAt && GetTimeFromTimestamp(+res.DeletedAt),
        res.InventoryNumber,
        res.QuantityTypeId && +res.QuantityTypeId,
        res.StorageLocation,
        res.GlobalTradeItemNr
    );
}



