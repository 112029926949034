import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    LEDGER_IMPORT_KEY,
    LedgerImportState,
    selectAllLedgerImport,
    selectLedgerImportEntities,
    selectLedgerImportFetched,
    selectLedgerImportLatestUpdatedAt,
} from '../reducers/ledger-import.reducer';

const getState = createFeatureSelector<LedgerImportState>(LEDGER_IMPORT_KEY);
const getDictionary = createSelector(getState, selectLedgerImportEntities);

export const getLedgerImportDictionary = getDictionary;
export const getLedgerImports = createSelector(getState, selectAllLedgerImport);
export const getLedgerImportsFetched = createSelector(getState, selectLedgerImportFetched);
export const getLedgerImportLatestUpdatedAt = createSelector(getState, selectLedgerImportLatestUpdatedAt);
export const getLedgerImportById = (props: { id: number }) => createSelector(getDictionary, (dictionary) => props.id && dictionary[props.id]);
