import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { FileEntityFromFileBackend, FileTypeFromFileBackend, getFileIconByMimeType } from '../dave-data-module/entities/file.entity';
import { HttpService } from '../dave-data-module/services/http.service';
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';
import { FilesActionTypes, FileUploadParams } from '../dave-data-module/State/actions/files.actions';
import { getToken } from '../dave-data-module/State/selectors/base.selectors';
import { ProgressToastrComponent, ProgressToastrComponentDialogData } from '../progress-toastr/progress-toastr.component';
import { isNotNullOrUndefined } from "../helper/helper";
import { FileDataService } from "../dave-data-module/services/file-data.service";

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {
    private uploadQue: Array<{ file: File; params: FileUploadParams }> = [];
    private isRunning = false;
    private activeDialog: MatDialogRef<ProgressToastrComponent>;
    constructor(private store: Store<State>, private http: HttpClient, private gatewayHttpService: HttpService, private matDialog: MatDialog, private fileDataService: FileDataService) {}

    startQue() {
        if (!this.isRunning && this.uploadQue.length) {
            this.isRunning = true;
            this.queWorker();
        }
    }
    queWorker() {
        const next = this.uploadQue.pop();
        const fileName = next.params.has('name') ? next.params.get('name') : next.file.name;
        const title = 'Hochladen' + (this.uploadQue.length > 0 ? ' (' + this.uploadQue.length + ')' : ' ...');
        const icon = getFileIconByMimeType(next.file.type);
        if (this.activeDialog?.getState() !== MatDialogState.OPEN) {
            this.activeDialog = this.matDialog.open<ProgressToastrComponent, ProgressToastrComponentDialogData>(ProgressToastrComponent, {
                ...ProgressToastrComponent.DefaultConfig,
                data: {
                    progressStartValue: 0,
                    title,
                    subTitle: fileName,
                    icon,
                },
                exitAnimationDuration: '1500ms',
            });
        } else {
            this.activeDialog.componentInstance.title = title;
            this.activeDialog.componentInstance.subTitle = fileName;
            this.activeDialog.componentInstance.icon = icon;
        }
        this.uploadToBe(next.file, next.params).finally(() => {
            if (this.uploadQue.length) {
                this.queWorker();
            } else {
                this.isRunning = false;
                this.activeDialog?.close();
            }
        });
    }
    upload(files: Array<{ file: File; params: FileUploadParams }>) {
        this.uploadQue.push(...files);
        this.startQue();
    }
    uploadToBe(file: File, params: FileUploadParams) {
        return firstValueFrom(this.store.select(getToken)).then((token) => {
            let headers = new HttpHeaders();
            headers = headers.set('Authorization', 'Bearer ' + token);
            const payload = new FormData();
            params.forEach((param, key) => {
                payload.append(key, param);
            });
            payload.append('file', file);
            // headers = headers.set('Content-Type', 'multipart/form-data; boundary='+payload.get('file'));

            return firstValueFrom(
                this.http
                    .put(this.gatewayHttpService.GetUrl('files', 'file'), payload, {
                        headers,
                        reportProgress: true,
                        observe: 'events',
                    })
                    .pipe(
                        tap((req) => {
                            if (req.type === HttpEventType.UploadProgress) {
                                if (this.activeDialog?.componentInstance) {
                                    this.activeDialog.componentInstance.progress = (req.loaded / req.total) * 100;
                                }
                            }
                        }),
                        filter((req) => req.type === HttpEventType.Response),
                    ),
            )
                .then((res: HttpResponse<Array<FileTypeFromFileBackend>>) => {
                    if (res?.body?.length === 1) {
                        this.store.dispatch(FilesActionTypes.UpdateOne({ Payload: FileEntityFromFileBackend(res.body[0]) }));
                        this.pollThumbnails([res.body[0].id])
                    } else {
                        throw 'unvalid response';
                    }
                })
                .catch((reason) => {
                    this.store.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: 'Datei ' + (params.get('name') || file.name) + ' hochladen fehlgeschlagen',
                                Err: reason,
                                // Caught: caught,
                            },
                        }),
                    );
                });
        });
    }
    public pollThumbnails(fileIds: number[]) {
        fileIds?.forEach(id=> this.pollThumbnail(id));
    }
    private pollThumbnail(fileId: number, i = 0) {
        const pollingDelays = [30000, 30000];
        if (isNotNullOrUndefined(pollingDelays[i])) {
            setTimeout(() => {
                firstValueFrom(this.fileDataService.GetFileById$(fileId, true)).then((file) => {
                    if (!file.GetLastVersion().HasThumbnail) {
                        this.pollThumbnail(fileId, i+1);
                    }
                });
            }, pollingDelays[i])
        }
    }
}
