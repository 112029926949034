// tslint:disable: naming-convention
import { Action } from '@ngrx/store';
import { SettingEntityAttributes } from '../../entities/user.entity';

export enum SettingsActionTypes {
    ChangeSettings = '[Settings] Change Settings',
}
export class ChangeSettings implements Action {
    readonly type = SettingsActionTypes.ChangeSettings;
    constructor(public Settings: SettingEntityAttributes) {
    }
}

export type SettingsActions = ChangeSettings;
