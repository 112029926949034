import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { State } from '../State';
import { ARLTemplateTypeEntity } from '../entities/a-r-l-template-type.entity';
import { getARLTemplateTypes } from '../State/selectors/accounting.selector';
import { ARLTemplateTypeActions } from '../State/actions/accounting.actions';

@Injectable({
    providedIn: 'root',
})
export class ARLTemplateTypeResolver implements Resolve<ARLTemplateTypeEntity[]> {
    constructor(private store: Store<State>) {}

    resolve() {
        const value$ = this.store.select(getARLTemplateTypes);
        value$
            .pipe(
                filter(value => !value),
                take(1),
                tap(() => {
                    if (localStorage.getItem('token') != null) {
                        this.store.dispatch(ARLTemplateTypeActions.GetARLTemplateTypes());
                    }
                }),
            )
            .subscribe();

        return value$.pipe(
            filter(value => !!value),
            take(1),
        );
    }
}
