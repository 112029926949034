import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { WorkedTimesEntity, WorkedTimesEntityFromBackend } from '../../entities/worked-times.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { WorkedTimesActionTypes } from '../actions/worked-times.actions';

enum ErrorCodes {
    Load = 'Zeitstatistiken Abrufen fehlgeschlagen',
}
@Injectable()
export class WorkedTimesEffects {
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}

    GetAllWorkedTimesQuery$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(WorkedTimesActionTypes.Load),
                withLatestFrom(this.store$.select(getToken)),
                switchMap(([{ Payload }, token]) => {
                    const queryString = `query {
          workedtimes${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}", withDeleted: true)` : ''} {
            ${WorkedTimesEntity.GQLFields.join(',')}
          }
        }
        `;
                    return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                        tap((res) => {
                            if (!(Payload?.updatedSince && !res.workedtimes.length)) {
                                this.store$.dispatch(
                                    res && res.workedtimes
                                        ? Payload?.updatedSince
                                            ? WorkedTimesActionTypes.UpdateMany({
                                                  Payload: res.workedtimes.map((val) => WorkedTimesEntityFromBackend(val)),
                                                  updateLatestUpdatedAt: true,
                                              })
                                            : WorkedTimesActionTypes.UpdateAll({
                                                  Payload: res.workedtimes.map((val) => WorkedTimesEntityFromBackend(val)),
                                                  updateLatestUpdatedAt: true,
                                              })
                                        : BaseActionTypes.ErrorAction({
                                              Payload: { ToasterMessage: ErrorCodes.Load },
                                          }),
                                );
                                const del = res?.workedtimes.filter(w => w.deletedAt);
                                if (del?.length) {
                                    this.store$.dispatch(WorkedTimesActionTypes.RemoveMany({Payload: del.map(w => w.id)}))
                                }
                            }
                        }),
                        catchError((err, caught) => {
                            this.store$.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Load,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            );
                            return EMPTY;
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );
}
