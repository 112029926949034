import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BVCustomNameState, B_V_CUSTOM_NAME_FEATURE_KEY, selectAllBVCustomName, selectBVCustomNameEntities, selectBVCustomNameFetched } from '../reducers/b-v-custom-name.reducer';

const selectState = createFeatureSelector<BVCustomNameState>(B_V_CUSTOM_NAME_FEATURE_KEY);
export const getBVCustomNameDictionary = createSelector(selectState, selectBVCustomNameEntities);
// export const getBVCustomNameLatestUpdatedAt = createSelector(selectState, selectBVCustomNameLatestUpdatedAt);
export const getBVCustomNameFetched = createSelector(selectState, selectBVCustomNameFetched);
export const getBVCustomNames = createSelector(selectState, selectAllBVCustomName);
export const getBVCustomNamesActive = createSelector(getBVCustomNames, (activities) => activities.filter((bVCustomName) => !bVCustomName.DeletedAt));
export const getBVCustomNameById = (props: { id: number }) => createSelector(getBVCustomNameDictionary, (dictionary) => props.id && dictionary[props.id]);
