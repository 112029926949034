import { Injectable } from '@angular/core';
import { MatSortable } from '@angular/material/sort';
import { JSONReplacer, JSONReviver } from '../helper/helper';

@Injectable({
    providedIn: 'root'
})
export class FileExplorerSortingService {
    private sortables: Map<number, MatSortable>;
    constructor() {
        const ls = localStorage.getItem('folderDefaultSorting');
        this.sortables = ls ? new Map(JSON.parse(ls, JSONReviver)) : new Map();
    }
    GetSortable(folderId: number): MatSortable {
        return this.sortables.get(folderId) || {disableClear: false, id: '', start: 'asc'};
    }
    SetSortable(folderId: number, sort: MatSortable) {
        if (sort.id) {
            this.sortables.set(folderId, sort);
        } else {
            this.sortables.delete(folderId);
        }
        localStorage.setItem('folderDefaultSorting', JSON.stringify(this.sortables, JSONReplacer));
    }
}
