import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment/moment';
import { firstValueFrom, merge, Observable, of, switchMap } from 'rxjs';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { filter, first, map, shareReplay, startWith } from 'rxjs/operators';
import { EnumClockInTypeSlug } from '../../../dave-data-module/entities/clock-in-type.entity';
import { ClockInEntity, ClockInTypes } from '../../../dave-data-module/entities/clock-in.entity';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { VacationStatusEnum } from '../../../dave-data-module/entities/employee-to-vacation.entity';
import { UserEntity } from '../../../dave-data-module/entities/user.entity';
import { DaveMutationChangeClockInArgs, DaveMutationCreateClockInArgs } from '../../../dave-data-module/graphql-types';
import { ClockInTypeResolver } from '../../../dave-data-module/guards/clock-in-type.resolver';
import { FrontendDateTimestamp } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { getClockInTypes, getClockInTypesFetched } from '../../../dave-data-module/State/selectors/clock-in-type.selectors';
import { getClockIns } from '../../../dave-data-module/State/selectors/clock-in.selectors';
import { getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getEmployeeToVacations, getEmployeeToVacationsFetched } from '../../../dave-data-module/State/selectors/employee-to-vacation.selectors';
import { getEmployees } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getStatusFromSV } from '../../../dave-data-module/State/selectors/status-from-sv.selectors';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { SelectSearchOption } from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, OpenHTMLInputPicker, sameDay, stringSearch } from '../../../helper/helper';
import { ClockInHelperService } from '../../../services/clock-in-helper.service';
import { CustomLabelService } from '../../../services/custom-label.service';
import { LoadingService } from '../../../services/loading.service';
import { CommissionSvStatusCodes } from '../../../dave-data-module/entities/statusFromSV.entity';

export interface NewClockinPopupComponentDialogData {
    EmployeeId: number;
    ClockInEntity?: ClockInEntity;
    Date?: Date;
    New: boolean;
    SelectDate: boolean;
    SelectDateEnd: boolean;
    Close: boolean;
    Dashboard?: boolean;
    Type?: ClockInTypes;
    CommissionId?: number;
    ShowEmployeeList?: boolean;
    UsersOfCommission: Array<UserEntity>;
    AutoAssignCommissionId?: boolean;
    DefaulClockinTypeId?: number;
}

@Component({
    selector: 'app-new-clockin-popup',
    templateUrl: './new-clockin-popup.component.html',
    styleUrls: ['./new-clockin-popup.component.scss'],
})
export class NewClockinPopupComponent implements OnInit {
    public OpenHTMLInputPicker = OpenHTMLInputPicker;
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        width: '350px',
    };
    public ClockInTypes$ = this.store.select(getClockInTypesFetched).pipe(
        filter((fetched) => !!fetched),
        switchMap(() => this.store.select(getClockInTypes)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public ClockInTypes = ClockInTypes;
    public NewClockinForm = new FormGroup({
        CommissionId: new FormControl<CommissionEntity & SelectSearchOption>(null),
        EmployeeId: new FormControl<SelectSearchData>(null),
        StartDate: new FormControl<Date>(new Date(), Validators.required),
        StartDateTime: new FormControl<string>('00:00'),
        EndDate: new FormControl<Date>(new Date()),
        EndDateTime: new FormControl<string>('00:00'),
        Type: new FormControl<ClockInTypes>(ClockInTypes.Start),
        Note: new FormControl<string>('', {
            updateOn: 'blur',
        }),
        ClockInTypeId: new FormControl<number>(null),
    });
    public IsOnHoliday$: Observable<boolean>;
    public CompareSelectSearchCommissionOptions = (a: CommissionEntity & SelectSearchOption, b: CommissionEntity & SelectSearchOption) => a.Id === b.Id;
    public SelectSearchCommissionOptionsFunction = (search: string, option: CommissionEntity & SelectSearchOption) => [option.InterneNummer, option.Description, option.Auftragsnummer].some((v) => v && stringSearch(v, search));
    public Commissions$ = combineLatest([this.store.select(getCommissions), this.store.select(getStatusFromSV)]).pipe(
        map(([commissions, svStatus]) => {
            const stateId = svStatus?.find((s) => s.Name === CommissionSvStatusCodes.erledigt)?.Id;
            const openCommissions = commissions?.filter((c) => !c.Deleted && c.StatusfromSVId !== stateId).sort((a, b) => b.CreatedAt?.getTime() - a.CreatedAt?.getTime());
            const closedCommissions = commissions?.filter((c) => !c.Deleted && c.StatusfromSVId === stateId).sort((a, b) => b.CreatedAt?.getTime() - a.CreatedAt?.getTime());
            return [...openCommissions, ...closedCommissions] as CommissionEntity[];
        }),
    );
    public OtherClockInsExist$: Observable<boolean>;

    public UserList$;

    /*public EmployeesOfCommission$ = combineLatest([
        this.store.select(getEmployees),
        this.store.select(getCommissions),
    ]).pipe(
        map(([employees, commissions]) => {
            const activeCommission = commissions.find(c => c.Id === this.Dialogdata.ClockInEntity.CommissionId);
            console.log('activecommission: ', activeCommission);
            return employees.filter(e => activeCommission.UserIds.includes(e.UserId));
        }),
    );*/

    public ShowEnd = false;
    public MaxDate: Date;

    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: NewClockinPopupComponentDialogData,
        private appDialog: AppDialogService,
        public PS: PermissionService,
        private clockInTypeResolver: ClockInTypeResolver,
        public LS: LoadingService,
        private actions$: Actions,
        private dialogRef: MatDialogRef<NewClockinPopupComponent>,
        protected cls: CustomLabelService,
        private clockInHelperService: ClockInHelperService,
    ) {
        console.log(Dialogdata);
        clockInTypeResolver.resolve();
        this.OtherClockInsExist$ = combineLatest([
            this.store.select(getClockIns),
            merge(of(null), this.NewClockinForm.controls.EmployeeId.valueChanges, this.NewClockinForm.controls.StartDate.valueChanges, this.NewClockinForm.controls.EndDate.valueChanges),
        ]).pipe(
            map(([cl]) => {
                const employeeId = this.NewClockinForm.controls.EmployeeId.value;
                const startDate = this.NewClockinForm.controls.StartDate.value;
                const endDate = this.NewClockinForm.controls.EndDate.value;
                return employeeId?.Id && (startDate || endDate) ? cl.some((c) => c.EmployeeId === employeeId.Id && ((startDate && sameDay(startDate, c.TimeStamp)) || (endDate && sameDay(endDate, c.TimeStamp)))) : false;
            }),
        );
        this.UserList$ = this.store.select(getEmployees).pipe(
            filter(isNotNullOrUndefined),
            map((e) => {
                return this.Dialogdata.UsersOfCommission.filter((u) => u && !u.Deleted).map((u) => {
                    const employeeOfUser = e.find((em) => em.UserId === u.Id);
                    return {
                        Name: u.DisplayName + ', ' + (u.Email || ''),
                        Id: employeeOfUser?.Id ? employeeOfUser.Id : u.Id,
                        SearchContent: (u.DisplayName || '') + (u.Email || ''),
                    };
                });
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    ngOnInit(): void {
        this.MaxDate = new Date();
        this.NewClockinForm.controls.StartDate.valueChanges.subscribe((v) => {
            this.NewClockinForm.controls.EndDate.setValue(new Date(v));
        });

        if (this.Dialogdata.Date) {
            this.NewClockinForm.controls.StartDate.setValue(this.Dialogdata.Date);
        }
        if (this.Dialogdata.ClockInEntity) {
            this.NewClockinForm.controls.Note.setValue(this.Dialogdata.ClockInEntity.WorkDescription);
            this.NewClockinForm.controls.StartDate.setValue(this.Dialogdata.ClockInEntity.TimeStamp);
            this.NewClockinForm.controls.Type.setValue(this.Dialogdata.ClockInEntity.Type);
            this.NewClockinForm.controls.StartDateTime.setValue(moment(this.Dialogdata.ClockInEntity.TimeStamp).format('HH:mm'));
            // this.NewClockinForm.controls.StartDate.disable();
            // this.NewClockinForm.controls.EndDate.disable();
            this.NewClockinForm.controls.ClockInTypeId.setValue(this.Dialogdata.ClockInEntity.ClockInTypeId);
            if (this.Dialogdata.ClockInEntity.CommissionId != null) {
                let sub = this.Commissions$.subscribe((commissions) => {
                    this.NewClockinForm.controls.CommissionId.setValue(commissions.find((c) => c.Id === this.Dialogdata.ClockInEntity.CommissionId));
                    setTimeout(() => {
                        sub.unsubscribe();
                    }, 50);
                });
            }
        } else {
            if (this.Dialogdata.DefaulClockinTypeId) {
                this.NewClockinForm.controls.ClockInTypeId.setValue(this.Dialogdata.DefaulClockinTypeId);
            } else {
                this.ClockInTypes$.pipe(first()).subscribe((types) => this.NewClockinForm.controls.ClockInTypeId.setValue(types.find((c) => c.Slug === EnumClockInTypeSlug.workTime)?.Id));
            }
            if (this.Dialogdata.Type) {
                this.NewClockinForm.controls.Type.setValue(this.Dialogdata.Type);
            }
            if (this.Dialogdata.ShowEmployeeList) {
                if (this.Dialogdata.EmployeeId) {
                    this.NewClockinForm.controls.EmployeeId.setValue({ Id: this.Dialogdata.EmployeeId, Name: '' });
                } else {
                    firstValueFrom(combineLatest([this.store.select(getEmployees), this.store.select(getUser)])).then(([employees, user]) => {
                        const e = employees?.find((e) => e.UserId === user.Id);
                        if (e) {
                            this.NewClockinForm.controls.EmployeeId.setValue({ Id: e.Id, Name: '' });
                        } else {
                            console.error('own employee not found');
                        }
                    });
                }
            }
        }

        if (this.Dialogdata.CommissionId) {
            this.Commissions$.subscribe((commissions) => {
                this.NewClockinForm.controls.CommissionId.setValue(commissions.find((c) => c.Id === this.Dialogdata.CommissionId));
            });
        }
        this.IsOnHoliday$ = this.store.select(getEmployeeToVacationsFetched).pipe(
            filter((v) => !!v),
            switchMap(() =>
                combineLatest([
                    this.store.select(getEmployeeToVacations),
                    this.Dialogdata.ShowEmployeeList
                        ? this.NewClockinForm.controls.EmployeeId.valueChanges.pipe(
                              filter(isNotNullOrUndefined),
                              map((sv) => sv.Id),
                          )
                        : of(this.Dialogdata.EmployeeId),
                ]),
            ),
            map(([e2vs, employeeId]) => e2vs.filter((e2v) => e2v.EmployeeId === employeeId && e2v.VacationStatus === VacationStatusEnum.Approved)),
            switchMap((e2vs) =>
                this.NewClockinForm.valueChanges.pipe(
                    startWith(this.NewClockinForm.value),
                    map((form) => {
                        let Start = new Date(form.StartDate);
                        let End = new Date(form.EndDate);

                        const timesStart = form.StartDateTime.split(':');
                        const timesEnd = form.EndDateTime.split(':');

                        Start.setHours(+timesStart[0], +timesStart[1], 0, 0);
                        End.setHours(+timesEnd[0], +timesEnd[1], 0, 0);

                        const start = moment(Start);
                        const end = moment(End);

                        return e2vs.some((e2v) => start.isBetween(e2v.StartDate, e2v.EndDate) || end.isBetween(e2v.StartDate, e2v.EndDate) || moment(e2v.StartDate).isBetween(start, end));
                    }),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }

    public Submit() {
        if (this.NewClockinForm.invalid) {
            return;
        }
        this.LS.startLoading('save-clock-in');

        let Start: Date;
        let End: Date;

        if (this.Dialogdata.SelectDate) {
            const timesStart = this.NewClockinForm.controls.StartDateTime.value.split(':');
            Start = new Date(this.NewClockinForm.controls.StartDate.value);
            Start.setHours(+timesStart[0], +timesStart[1], 0, 0);
        } else {
            Start = this.Dialogdata.Date;
        }
        const clockInsToAdd: DaveMutationCreateClockInArgs[] = [];
        const clockInsToModify: DaveMutationChangeClockInArgs[] = [];

        if (this.Dialogdata.ClockInEntity) {
            clockInsToModify.push({
                id: this.Dialogdata.ClockInEntity.Id,
                timeStamp: FrontendDateTimestamp(Start),
                workDescription: this.NewClockinForm.value.Note,
                commissionId: isNotNullOrUndefined(this.NewClockinForm.value.CommissionId?.Id) ? this.NewClockinForm.value.CommissionId?.Id : null,
                type: this.NewClockinForm.value.Type,
                clockInTypeId: this.NewClockinForm.value.ClockInTypeId,
            });
        } else {
            if (this.Dialogdata.SelectDateEnd && this.ShowEnd) {
                const timesEnd = this.NewClockinForm.controls.EndDateTime.value.split(':');
                End = new Date(this.NewClockinForm.controls.EndDate.value);
                End.setHours(+timesEnd[0], +timesEnd[1], 0, 0);

                clockInsToAdd.push(
                    {
                        employeeId: this.Dialogdata.ShowEmployeeList ? this.NewClockinForm.value.EmployeeId.Id : this.Dialogdata.EmployeeId,
                        type: ClockInTypes.Start,
                        timeStamp: FrontendDateTimestamp(Start),
                        commissionId: this.NewClockinForm.value.CommissionId?.Id,
                        clockInTypeId: this.NewClockinForm.value.ClockInTypeId,
                    },
                    {
                        employeeId: this.Dialogdata.ShowEmployeeList ? this.NewClockinForm.value.EmployeeId.Id : this.Dialogdata.EmployeeId,
                        type: ClockInTypes.End,
                        timeStamp: FrontendDateTimestamp(End),
                        workDescription: this.NewClockinForm.value.Note,
                        commissionId: this.NewClockinForm.value.CommissionId?.Id,
                        clockInTypeId: this.NewClockinForm.value.ClockInTypeId,
                        autoAssignCommissionId: this.Dialogdata.AutoAssignCommissionId,
                    },
                );
            } else {
                let defaultDate = -3600000;
                if (Start.getTime() !== defaultDate) {
                    clockInsToAdd.push({
                        employeeId: this.Dialogdata.EmployeeId,
                        // type: ClockInTypes.End,
                        type: this.NewClockinForm.value.Type,
                        timeStamp: FrontendDateTimestamp(Start),
                        workDescription: this.NewClockinForm.value.Note,
                        commissionId: this.NewClockinForm.value.CommissionId?.Id,
                        clockInTypeId: this.NewClockinForm.value.ClockInTypeId,
                        autoAssignCommissionId: this.Dialogdata.AutoAssignCommissionId,
                    });
                }
            }
        }
        if (clockInsToAdd.length || clockInsToModify.length) {
            this.clockInHelperService.dispatchClockInActions(clockInsToAdd, clockInsToModify, []).subscribe(() => {
                this.LS.endLoading('save-clock-in');
                this.dialogRef.close();
            });
        }
    }

    public deleteClockIn() {
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: `Wollen Sie die Stempelzeit wirklich löschen?`,
            })
            .subscribe(([result]) => {
                if (result) {
                    if (this.Dialogdata.ClockInEntity) {
                        this.LS.startLoading('delete-clock-in');
                        this.clockInHelperService.dispatchClockInActions([], [], [{ id: this.Dialogdata.ClockInEntity.Id }]).subscribe(() => {
                            this.LS.endLoading('delete-clock-in');
                            this.dialogRef.close();
                        });
                    }
                }
            });
    }
}
