import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { DamageFlowEntity, DamageFlowEntityFromBackend } from '../../entities/damageflow.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { DamageFlowActionTypes } from '../actions/damageflow.actions';
import { State } from '../index';
import { getDamageFlowById } from '../selectors/damageflow.selector';

enum ErrorCodes {
    Add = 'Schadensverlauf Hinzufügen fehlgeschlagen',
    Load = 'Schadensverlauf Abrufen fehlgeschlagen',
    Modify = 'Schadensverlauf Bearbeiten fehlgeschlagen',
    Remove = 'Schadensverlauf Löschen fehlgeschlagen',
}

@Injectable()
export class DamageFlowEffects {

    RemoveDamageFlow$ = createEffect(() => this.actions$.pipe(
        ofType(DamageFlowActionTypes.DeleteDamageFlow),
        withLatestFrom(this.store$),
        concatMap(([{Payload}, store]) => {
            const queryString = `
mutation{
  deleteDamageFlow(id: ${Payload})
}`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                withLatestFrom(this.store$.select(getDamageFlowById({id: Payload}))),
                map(([res, damageFlow]) =>
                    res && res.deleteDamageFlow
                        ? DamageFlowActionTypes.UpdateMany({
                              Payload: [damageFlow.Clone({Deleted: true})],
                          })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Remove },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    ModifyDamageFlow$ = createEffect(() => this.actions$.pipe(
        ofType(DamageFlowActionTypes.ModifyDamageFlow),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
      mutation{
        changeDamageFlow(id: ${action.Payload.id}, ${stringifyIfNotNullOrUndefined(action.Payload, 'street')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'postalCode')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'city')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'country')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'description')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'damageFlowTypeId')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'deadlineVerlaengerung')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'schadenannahmeDurchSV')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'kontaktMitVersicherungsnehmer')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'ersterBerichtEingegangen')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'abgabeterminBerichtReal')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'qMStatusId')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'qMDurchgefuehrtAm')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'weiterleitungAnVu')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'objektiveSchadensumme')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'ersterOrtstermin')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'commissionId')}
          ${stringifyIfNotNullOrUndefined(action.Payload, 'employeeId')}
          ${stringifyIfNotNullOrUndefined(action.Payload, 'reportCount')}
          ){
          ${DamageFlowEntity.GQLFields}
        }
      }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res =>
                    res && res.changeDamageFlow && res.changeDamageFlow.id
                        ? DamageFlowActionTypes.UpdateMany({
                              Payload: [DamageFlowEntityFromBackend(res.changeDamageFlow)],
                          })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Modify },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    AddDamageFlow$ = createEffect(() => this.actions$.pipe(
        ofType(DamageFlowActionTypes.AddDamageFlow),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
      mutation{
        createDamageFlow(${stringifyIfNotNullOrUndefined(action.Payload, 'street')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'postalCode')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'city')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'country')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'description')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'damageFlowTypeId')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'deadlineVerlaengerung')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'schadenannahmeDurchSV')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'kontaktMitVersicherungsnehmer')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'ersterBerichtEingegangen')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'abgabeterminBerichtReal')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'qMStatusId')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'qMDurchgefuehrtAm')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'weiterleitungAnVu')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'objektiveSchadensumme')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'commissionId')}
          ${stringifyIfNotNullOrUndefined(action.Payload, 'employeeId')}
          ${stringifyIfNotNullOrUndefined(action.Payload, 'reportCount')}
          ){
                    ${DamageFlowEntity.GQLFields}
        }
      }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.createDamageFlow
                        ? DamageFlowActionTypes.UpdateMany({
                              Payload: [DamageFlowEntityFromBackend(res.createDamageFlow)],
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    LoadDamageFlows = createEffect(() => this.actions$.pipe(
        ofType(DamageFlowActionTypes.Load),
        withLatestFrom(this.store$),
        switchMap(([{Payload}, store]) => {
            const queryString = `
      query{
        damageFlow${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''}{
            ${DamageFlowEntity.GQLFields}
            }
      }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                map(res =>
                    res && res.damageFlow
                        ? Payload?.updatedSince
                            ? DamageFlowActionTypes.UpdateMany({
                                  Payload: res.damageFlow.map(val => DamageFlowEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                            : DamageFlowActionTypes.UpdateAll({
                                  Payload: res.damageFlow.map(val => DamageFlowEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Load },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
