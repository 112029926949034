import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { FileEntity } from '../dave-data-module/entities/file.entity';
import { FileDataService } from '../dave-data-module/services/file-data.service';
import { DownloadVersions, HttpService } from '../dave-data-module/services/http.service';
import { State } from '../dave-data-module/State';
import { DaveActions } from '../dave-data-module/State/actions/actions';
import { FilesActionTypes } from '../dave-data-module/State/actions/files.actions';
import { getToken } from '../dave-data-module/State/selectors/base.selectors';
import { getPartner } from '../dave-data-module/State/selectors/partners.selectors';
import { EmailEditorComponent, EmailEditorComponentDialogConfig, EmailEditorComponentDialogData } from '../dave-email-module/components/email-editor/email-editor.component';
import { DetailTasksComponent, DetailTasksComponentDialogData } from '../dave-event-card/components/detail-tasks/detail-tasks.component';
import { FullscreenDialogComponent, FullscreenDialogComponentConfig, FullscreenDialogData } from '../dave-fullscreen-dialog/components/fullscreen-dialog.component';
import { AppDialogService } from '../dave-utils-module/app-dialog-module/app-dialog.service';
import { isNotNullOrUndefined, uniqArray } from '../helper/helper';
import { FileLinkIcon, ProcessMeta, TaskPageMeta } from '../helper/page-metadata';
import { NewFileLinkComponent, NewFileLinkComponentDialogData } from '../new-file-link/new-file-link.component';
import { StartProcessDialogComponent, StartProcessDialogComponentDialogData } from '../process/components/start-process-dialog/start-process-dialog.component';
import { MoveFileFolderService } from '../services/move-file-folder.service';
import { ProcessService } from '../services/process.service';

export interface DaveImageSliderComponentDialogData {
    fileIds: number[];
    fileId: number;
}

export interface ImageObject {
    image: string;
    thumbImage: string;
    alt: string;
    title: string;
}
const sortByName = (a, b): 1 | -1 | 0 => {
    const aName = a.Name.toLowerCase();
    const bName = b.Name.toLowerCase();
    return aName < bName ? -1 : aName > bName ? 1 : 0;
};
@Component({
    selector: 'app-dave-image-slider',
    templateUrl: './dave-image-slider.component.html',
    styleUrls: ['./dave-image-slider.component.scss'],
})
export class DaveImageSliderComponent implements OnInit {
    ImageViewerConfig = {
        btnContainerClass: '', // The CSS class(es) to be applied to the button container
        btnClass: 'default', // The CSS class(es) that will be applied to the buttons e.g. default is needed for FontAwesome icons, while not needed for Material Icons
        btnSubClass: 'material-icons', // The CSS class(es) that will be applied to span elements inside material buttons (a Elements)
        zoomFactor: 0.1, // The amount that the scale will be increased by
        containerBackgroundColor: 'transparent', // The color to use for the background. This can provided in hex, or rgb(a).
        wheelZoom: true, // If true, the mouse wheel can be used to zoom in
        allowFullscreen: false, // If true, the fullscreen button will be shown, allowing the user to enter fullscreen mode
        allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
        btnShow: {
            // Control which icons should be visible
            zoomIn: false,
            zoomOut: false,
            rotateClockwise: false,
            rotateCounterClockwise: false,
            next: false,
            prev: false,
            reset: false,
        },
        btnIcons: {
            zoomIn: {
                classes: null, // this property will be used for FontAwesome and other libraries to set the icons via the classes - choose one: classes or text
                text: null, // this property will be used for Material-Icons and similar libraries to set the icons via the text
            },
            zoomOut: {
                classes: null,
                text: null,
            },
            rotateClockwise: {
                classes: null,
                text: null,
            },
            rotateCounterClockwise: {
                classes: null,
                text: null,
            },
            next: {
                classes: null,
                text: null,
            },
            prev: {
                classes: null,
                text: null,
            },
            fullscreen: {
                classes: null,
                text: null,
            },
            reset: {
                classes: null,
                text: null,
            },
        },
    };
    public Images$: Observable<string[]>;
    public Files$: Observable<FileEntity[]>;
    public Index = 0;
    public FileId: number;
    public HasEditPermissions$: Observable<boolean>;
    public Upload$ = new Subject<File[]>();
    public file: FileEntity;
    public Partner$ = this.store.select(getPartner);

    constructor(
        @Inject(MAT_DIALOG_DATA) public Data: DaveImageSliderComponentDialogData,
        private store: Store<State>,
        public Api: HttpService,
        public DialogRef: MatDialogRef<DaveImageSliderComponent>,
        private dialog: MatDialog,
        private fileDataService: FileDataService,
        private moveFileFolderService: MoveFileFolderService,
        private actions$: Actions<DaveActions>,
        private appDialog: AppDialogService,
        private processService: ProcessService,
    ) {}

    ngOnInit(): void {
        if (this.Data?.fileId != null) {
            this.FileId = this.Data.fileId;
        }
        this.Files$ = this.fileDataService.GetFilesById(uniqArray([...this.Data.fileIds, this.FileId].filter(isNotNullOrUndefined))).pipe(
            filter(isNotNullOrUndefined),
            map((images) => {
                if (this.Data?.fileIds?.length) {
                    images.sort((a, b) => this.Data.fileIds.indexOf(a.Id) - this.Data.fileIds.indexOf(b.Id));
                } else {
                    images.sort(sortByName);
                }
                this.Index = images.findIndex((i) => i.Id === this.FileId);
                return images;
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
        this.Images$ = combineLatest([this.store.select(getToken), this.Files$]).pipe(
            filter((v) => v.every(isNotNullOrUndefined)),
            map(([token, files]) => files.map((f) => this.Api.GetUrl(f.GetLastVersion().GetDownloadLink(token), 'file'))),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        this.HasEditPermissions$ = this.Files$.pipe(map((f) => f[this.Index]?.CanEdit));

        this.Upload$.pipe(
            tap((fileList) => {
                if (!(fileList && fileList[0]) || fileList[1]) {
                    return;
                }
                this.Files$.pipe(take(1)).subscribe((files) => {
                    this.store.dispatch(
                        FilesActionTypes.UploadFileBackendRequest({
                            Payload: {
                                file: fileList[0],
                                params: new Map([['document_id', files[this.Index]?.Id.toString()]]),
                            },
                        }),
                    );
                });
            }),
        ).subscribe();

        this.Files$.pipe(take(1)).subscribe((files) => {
            this.file = files[this.Index];
        });
    }

    public OpenInEditor() {
        this.Files$.pipe(take(1)).subscribe((files) => {
            const dialogRef = this.dialog.open<FullscreenDialogComponent, FullscreenDialogData>(FullscreenDialogComponent, {
                ...FullscreenDialogComponentConfig,
                data: { file: files[this.Index], version: files[this.Index].GetLastVersion() },
                height: '100%',
                width: '100%',
                maxWidth: '100%',
                autoFocus: false,
            });
            dialogRef.backdropClick().subscribe(async () => {
                await dialogRef.componentInstance.Close();
                dialogRef.close();
            });
        });
    }

    public download() {
        this.Files$.pipe(take(1)).subscribe((files) => {
            DownloadVersions([files[this.Index].GetLastVersion()]);
        });
    }

    public moveImage() {
        this.Files$.pipe(take(1)).subscribe((files) => {
            this.moveFileFolderService.MoveFileFolder([files[this.Index]?.Id], []);
        });
        this.actions$.pipe(ofType(FilesActionTypes.ModifyFileSuccess)).subscribe((res) => {
            if (res.Payload) {
                this.DialogRef.close();
            }
        });
    }

    ClickDelete() {
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: `Ausgewählte Dateien wirklich löschen?`,
                styleDelete: true,
            })
            .subscribe(([result]) => {
                if (result) {
                    this.Files$.pipe(take(1)).subscribe((files) => {
                        this.store.dispatch(FilesActionTypes.DeleteFileRequest({ Payload: { DocumentId: files[this.Index]?.Id.toString() } }));
                    });
                }
                this.actions$.pipe(ofType(FilesActionTypes.DeleteFileSuccess)).subscribe((res) => {
                    if (res.Payload) {
                        this.DialogRef.close();
                    }
                });
            });
    }

    OpenMailDialog() {
        this.Files$.pipe(take(1)).subscribe((files) => {
            this.dialog.open<EmailEditorComponent, EmailEditorComponentDialogData>(EmailEditorComponent, {
                ...EmailEditorComponentDialogConfig,
                data: {
                    FileAttachmntFileIds: [files[this.Index]?.Id],
                },
            });
        });
    }

    protected readonly TaskPageMeta = TaskPageMeta;

    createTaskDialog() {
        this.Files$.pipe(take(1)).subscribe((files) => {
            this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
                ...DetailTasksComponent.DefaultConfig,
                data: {
                    InitialValues: {
                        DocumentIds: [files[this.Index]?.Id],
                        CommissionId: files[this.Index]?.ContractId,
                        CustomerId: files[this.Index]?.CustomerId,
                    },
                },
            });
        });
    }

    onAddToProcessClick() {
        this.Files$.pipe(take(1)).subscribe((files) => {
            this.processService.openAddFileToProcessDialog([files[this.Index]?.Id]);
        });
    }

    onStartProcessClick() {
        this.Files$.pipe(take(1)).subscribe((files) => {
            this.dialog.open<StartProcessDialogComponent, StartProcessDialogComponentDialogData>(StartProcessDialogComponent, {
                ...StartProcessDialogComponent.DefaultConfig,
                data: {
                    fileId: files[this.Index]?.Id,
                },
            });
        });
    }

    protected readonly ProcessMeta = ProcessMeta;
    protected readonly FileLinkIcon = FileLinkIcon;

    createLink() {
        this.Files$.pipe(take(1)).subscribe((files) => {
            this.dialog.open<NewFileLinkComponent, NewFileLinkComponentDialogData>(NewFileLinkComponent, {
                ...NewFileLinkComponent.DefaultConfig,
                data: {
                    defaultName: files[this.Index]?.Name,
                    folderId: files[this.Index]?.FolderId,
                    fileId: files[this.Index]?.Id,
                },
            });
        });
        firstValueFrom(this.actions$.pipe(ofType(FilesActionTypes.CreateLinkFailure, FilesActionTypes.CreateLinkSuccess))).then((action) => {
            if (action.type === FilesActionTypes.CreateLinkSuccess.type) {
                this.DialogRef.close(action.Payload);
            }
        });
    }
}
