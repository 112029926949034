import { BooleanInput,coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component,EventEmitter,Input,Output } from '@angular/core';
import { FilterOption } from '../app-filter/app-filter.component';

@Component({
    selector: 'app-filter-card',
    templateUrl: './filter-card.component.html',
    styleUrls: ['./filter-card.component.scss'],
})
export class FilterCardComponent {
    public ShowFilter = false;
    @Input() Settings: FilterOption[];
    @Input() Values: any;
    @Input() DefaultValues: any = {};
    @Input() Inline = false;
    @Output() ValuesChange = new EventEmitter<any>();
    protected unlimitedHeight = false;
    @Input()
    set UnlimitedHeight(value) {
        this.unlimitedHeight = coerceBooleanProperty(value);
    }
    static ngAcceptInputType_UnlimitedHeight: BooleanInput;

    protected maxWidthHundertPercent = false;
    @Input()
    set MaxWidthHundertPercent(value) {
        this.maxWidthHundertPercent = coerceBooleanProperty(value);
    }
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_MaxWidthHundertPercent: BooleanInput;

    public Open() {
        this.ShowFilter = true;
    }
    public Close() {
        this.ShowFilter = false;
    }
    public Toggle() {
        this.ShowFilter = !this.ShowFilter;
    }
    constructor() {}
}
