import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { PersonDetailFormComponent } from '../person-detail-form/person-detail-form.component';
import { PENDING_CHANGES_DEFAULT_MESSAGE } from '../../../dave-data-module/guards/pending-changes.guard';
import { Subscription } from 'rxjs';
import { appMatDialogDefaultConfig } from '../../../helper/helper';

export interface PersonModalComponentDialogData {
    Person?: PersonEntity;
    Edit?: boolean;
    newPersonDefaultValues?: Partial<PersonEntity>;
}

@Component({
    selector: 'app-person-detail-modal',
    templateUrl: './person-modal.html',
    styleUrls: ['./person-modal.scss'],
})
export class PersonModalComponent implements OnDestroy {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        minWidth: '40vw',
        maxWidth: '100%',
        maxHeight: 'calc(100vh - 3.5rem)',
        disableClose: true,
    }
    public Person: PersonEntity;
    public Editing = false;
    private subs: Subscription[] = [];
    @ViewChild('Form') protected Form: PersonDetailFormComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: PersonModalComponentDialogData,
        protected dialogRef: MatDialogRef<PersonModalComponent>
    ) {
        if (Dialogdata.Edit != null) {
            this.Editing = Dialogdata.Edit;
        }

        if (Dialogdata.Person != null) {
            this.Person = Dialogdata.Person;
        } else {
            this.Person = new PersonEntity().Clone(Dialogdata.newPersonDefaultValues || {});
            this.Editing = true;
        }
        if (this.dialogRef) {
            this.subs.push(this.dialogRef.backdropClick().subscribe(() => {
                this.closeDialog();
            }))
        }
    }

    Save() {
        this.Form.Save();
    }

    closeDialog() {
        if (this.dialogRef) {
            if (this.Form.canDeactivate() || confirm(PENDING_CHANGES_DEFAULT_MESSAGE)) {
                this.dialogRef.close();
            }
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe())
    }
}
