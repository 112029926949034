import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy,Component,Inject,OnInit } from '@angular/core';
import { MatDialogConfig,MatDialogModule,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { Observable,Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
AccountsReceivableLedgerStatusEnum
} from '../../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { State } from '../../../../dave-data-module/State';
import { getAccountsReceivableLedgerById } from '../../../../dave-data-module/State/selectors/accounting.selector';
import { AppButtonModule } from '../../../../dave-utils-module/app-button-module/app-button.module';
import { appMatDialogDefaultConfig } from '../../../../helper/helper';
import { ModifyBookingOptionComponent,NewOptionDefaultValues } from '../modify-booking-option.component';

export interface ModifyBookingOptionDialogComponentData {
    DefaultsForNewOptions?: NewOptionDefaultValues;
    BookingOptionId?: number;
}

@Component({
    selector: 'app-modify-booking-option-dialog',
    template: `
        <app-modify-booking-option
            mat-dialog-content
            #modifyBookingOption
            [Save$]="Save$.asObservable()"
            [Delete$]="Delete$.asObservable()"
            [BookingOptionId]="Data.BookingOptionId"
            [DefaultsForNewOptions]="Data.DefaultsForNewOptions"
        ></app-modify-booking-option>
        <mat-dialog-actions>
            <button app-button Inverted mat-dialog-close Color="cancel">Abbrechen</button>
            <!--ToDo Delete Permission einbauen-->
            <button
                app-round-button
                mat-dialog-close
                Color="red"
                *ngIf="Data.BookingOptionId"
                [Disabled]="!(CanDelete$ | async)"
                (click)="Delete$.next()"
                style="position: absolute; top: .5rem; right: .5rem"
                matTooltip="Abrechnungsposition löschen"
            >
                <fa-icon icon="trash"></fa-icon>
            </button>
            <button app-button mat-dialog-close (click)="Save$.next()" [Disabled]="modifyBookingOption.Form.invalid">Speichern</button>
        </mat-dialog-actions>
    `,
    styles: [
        `
            :host {
                position: relative;
                display: flex;
                flex-direction: column;
                height: 100%;
            }
            mat-dialog-actions {
                margin-top: auto;
                display: flex;
                justify-content: space-between;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MatDialogModule, AppButtonModule, MatTooltipModule, FontAwesomeModule, ModifyBookingOptionComponent],
})
export class ModifyBookingOptionDialogComponent implements OnInit {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        maxWidth: '20rem',
        width: '90%',
        maxHeight: '90%',
    };
    constructor(@Inject(MAT_DIALOG_DATA) public Data: ModifyBookingOptionDialogComponentData, private store: Store<State>) {}
    public Save$ = new Subject<void>();
    public Delete$ = new Subject<void>();
    public CanDelete$: Observable<boolean>;
    ngOnInit(): void {
        this.CanDelete$ = this.store
            .select(getAccountsReceivableLedgerById({ id: this.Data.BookingOptionId }))
            .pipe(map((arl) => arl.Status !== AccountsReceivableLedgerStatusEnum.Storniert && arl.Status !== AccountsReceivableLedgerStatusEnum.Verrechnet));
    }
}
