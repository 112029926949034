import {createReducer, on} from '@ngrx/store';
import {PartnerEntity} from '../../entities/partner.entity';
import { PartnerOfficeActionTypes, PartnersActionTypes } from '../actions/partners.actions';
import { PartnerOfficeEntity } from '../../entities/partnerOffice.entity';


export const partnersFeatureKey = 'partners';

export interface PartnersState {
    partner: PartnerEntity;
    partnerList: PartnerEntity[];
    partnerOffices: PartnerOfficeEntity[];
}

export const initialPartnersState: PartnersState = {
    partner: null,
    partnerList: null,
    partnerOffices: null
};


export const partnersReducer = createReducer(
    initialPartnersState,
    on(PartnersActionTypes.UpdatePartner, (state, {Payload}) => ({
        ...state,
        partner: Payload
    })),
    on(PartnersActionTypes.UpdatePartnerList, (state, {Payload}) => ({
        ...state,
        partnerList: Payload
    })),
    on(PartnerOfficeActionTypes.UpdatePartnerOffices, (state, {Payload}) => ({
        ...state,
        partnerOffices: Payload
    })),
    on(PartnersActionTypes.UpdateSinglePartner, (state, {Payload}) => ({
        ...state,
        partnerList: state.partnerList
            ? [...state.partnerList.filter(e => e.Id !== Payload.Id), Payload]
            : [Payload]
    })),
);

