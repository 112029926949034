import { Injectable } from '@angular/core';
import { DaveMutationCreateConstructionDiaryArgs } from '../graphql-types';
import { EventEntity, EventStateEnum } from '../entities/event.entity';
import { ConstructionDiaryEntity, ConstructionDiaryStatusEnum } from '../entities/construction-diary.entity';
import { ConstructionDiaryActionTypes } from '../State/actions/construction-diary.actions';
import { Actions, ofType } from '@ngrx/effects';
import { skip, take, tap } from 'rxjs/operators';
import { EventsActionTypes } from '../State/actions/events.actions';
import { FrontendDate } from '../helper/backend-frontend-conversion.helper';
import { ButtonConfig } from '../../dave-list-card/dave-list-card.component';
import { LoadingService } from '../../services/loading.service';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { UntypedFormBuilder } from '@angular/forms';

export type DailyTaskType = EventEntity & { stateName: string; time: string; suffixButton: ButtonConfig<DailyTaskType> };

@Injectable({
  providedIn: 'root'
})
export class ConstructionDiaryDataService {
  constructor( public LS: LoadingService,
               private store: Store<State>,
               private actions$: Actions,) {

  }
  saveDiaryEntry(diary: {Id?: number, Date: Date}, tasks: EventEntity[], createConstructionDiaryArgs: DaveMutationCreateConstructionDiaryArgs, CommissionId: number) {
      createConstructionDiaryArgs.status =
          tasks.some((t) => t.State !== EventStateEnum.Done) || (!createConstructionDiaryArgs.leistungsergebnisse && !JSON.parse(createConstructionDiaryArgs.additionalData).leistungsergebnisseTemplateIds?.length)
              ? ConstructionDiaryStatusEnum.Newly
              : ConstructionDiaryStatusEnum.Done;
      if (diary.Id) {
          this.store.dispatch(ConstructionDiaryActionTypes.Modify({ Payload: { ...createConstructionDiaryArgs, id: diary.Id } }));
      } else {
          this.LS.startLoading('add_construction_diary');
          this.actions$.pipe(ofType(ConstructionDiaryActionTypes.UpdateOne), take(1)).subscribe(() => (this.LS.endLoading('add_construction_diary')));
          if (tasks?.length) {
              const taskIds = [];
              this.actions$
                  .pipe(
                      ofType(EventsActionTypes.UpdateEvent),
                      tap((action) => taskIds.push(action.Payload.Id)),
                      skip(tasks.length - 1),
                      take(1),
                  )
                  .subscribe(() =>
                      this.store.dispatch(
                          ConstructionDiaryActionTypes.Add({
                              Payload: {
                                  ...createConstructionDiaryArgs,
                                  eventIds: taskIds,
                                  date: FrontendDate(diary.Date, true),
                              },
                          }),
                      ),
                  );
              tasks.forEach((t) =>
                  this.store.dispatch(
                      EventsActionTypes.AddEvent({
                          Payload: {
                              commissionId: CommissionId,
                              name: t.Name,
                              isTask: true,
                              state: t.State,
                              additionalData: JSON.stringify({ ...t.AdditionalData, IsDailyToDo: true }),
                              eventDate: FrontendDate(t.EventDate),
                          },
                      }),
                  ),
              );
          } else {
              this.store.dispatch(
                  ConstructionDiaryActionTypes.Add({
                      Payload: {
                          eventIds: [],
                          ...createConstructionDiaryArgs,
                          date: FrontendDate(diary.Date, true),
                      },
                  }),
              );
          }
      }
  }
}
