import { createAction, props } from '@ngrx/store';
import { User2CommissionShiftEntity } from '../../entities/user2CommissionShift.entity';
import { DaveMutationChangeUser2CommissionShiftArgs, DaveMutationCreateUser2CommissionShiftArgs, QueryUser2CommissionShiftArgs } from './../../graphql-types.d';

export const UserToCommissionShiftActionTypes = {
    Load: createAction('data/userToCommissionShift/load', props<{ Payload?: QueryUser2CommissionShiftArgs }>()),
    UpdateAll: createAction('data/userToCommissionShift/updateAll', props<{ Payload: User2CommissionShiftEntity[]; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/userToCommissionShift/updateMany', props<{ Payload: User2CommissionShiftEntity[]; updateLatestUpdatedAt?: boolean }>()),
    ChangeUser2CommissionShift: createAction('data/userToCommissionShift/change', props<{ Payload: DaveMutationChangeUser2CommissionShiftArgs }>()),
    AddUser2CommissionShift: createAction('data/userToCommissionShift/add', props<{ Payload: DaveMutationCreateUser2CommissionShiftArgs }>()),
    DeleteOne: createAction('data/userToCommissionShift/delete', props<{ Payload: number }>()),
    RemoveOne: createAction('data/userToCommissionShift/removeOne', props<{ Payload: number }>()),
    RemoveByCommission: createAction('data/userToCommissionShift/removeByCommission', props<{ Payload: number }>()),
};
