import { CommonModule } from '@angular/common';
import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { SmallFileCardModule } from '../small-file-card/small-file-card.module';

@Component({
    selector: 'app-expansion-panel',
    standalone: true,
    imports: [CommonModule, MatExpansionModule, SmallFileCardModule, MatDialogModule],
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent {
    @Input() public Expanded = true;
    @Output() public ExpandedChange = new EventEmitter<boolean>();
    @Input() public Headline = '';
    @Input() public HideHeadline = false;
    @Input() public ScrollableStyle = false;
    constructor() {}

}
