import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SimplebarAngularModule } from 'simplebar-angular';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { NewsComponent } from './news/news.component';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CkEditorComponent } from "../ck-editor/ck-editor.component";



@NgModule({
    declarations: [NewsComponent],
    imports: [
        CommonModule,
        SimplebarAngularModule,
        MatDividerModule,
        MatButtonModule,
        MatDialogModule,
        AppButtonModule,
        FontAwesomeModule,
        // CKEditorModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTooltipModule,
        CkEditorComponent,
    ],
})
export class DaveNewsModule {}
