import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { of, combineLatest } from 'rxjs';
import { concatMap } from 'rxjs/internal/operators/concatMap';
import { catchError, filter, first, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { isNotNullOrUndefined, stringifyIfNotNullOrUndefined, stringifyIfNotUndefined } from '../../../helper/helper';
import {
    LedgerImportEntity,
    LedgerImportEntityFromBackend,
    LedgerImportStatusEnum,
    LedgerImportStatusNames
} from "../../entities/ledger-import.entity";
import {
    SfdtTemplateEntity,
    SfdtTemplateEntityFromBackend,
} from '../../entities/sfdt-template.entity';
import { BusinessVolumeResolver } from '../../guards/business-volume.resolver';
import { FrontendDate } from '../../helper/backend-frontend-conversion.helper';
import { FileDataService } from '../../services/file-data.service';
import { HttpService } from '../../services/http.service';
import { AccountsReceivableLedgerActionTypes, LedgerImportActionTypes } from '../actions/accounting.actions';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { FilesActionTypes } from '../actions/files.actions';
import { State } from '../index';
import {
    getAccountsReceivableLedgerDictionary,
    getAccountsReceivableLedgers, getAccountsReceivableLedgersFetched,
    getSfdtTemplates,
    getSfdtTemplatesFetched
} from "../selectors/accounting.selector";
import { getBusinessVolumeFetched } from '../selectors/business-volume.selector';
import { getCustomers, getCustomersFetched } from "../selectors/customers.selectors";
import { getLedgerImports } from '../selectors/ledger-import.selector';
import { getOffices, getOfficesFetched } from "../selectors/offices.selectors";
import { getOwnPartnerOffices, getPartner, getPartnerOfficesFetched } from "../selectors/partners.selectors";
import { getUser } from '../selectors/users.selectors';
import { getCommissionDictionary, getCommissionsFetched } from "../selectors/commission.selector";
import { getFetched$ } from "../../helper/helper";
import { SFDTTemplateResolver } from "../../guards/sfdt-template.resolver";
import { CustomerResolver } from "../../guards/customer.resolver";
import { OfficeResolver } from "../../guards/office.resolver";
import { AccountsReceivableLedgerResolver } from "../../guards/accounts-receivable-ledger.resolver";
import { PartnerOfficeResolver } from "../../guards/partner-office.resolver";
import { CommissionResolver } from "../../guards/commission.resolver";

export enum LedgerImportEffectErrorCodes {
    Load = 'Rechnung Abrufen fehlgeschlagen',
    Remove = 'Rechnung Löschen fehlgeschlagen',
    Modify = 'Rechnung ändern fehlgeschlagen',
    Duplicate = 'Rechnung duplizieren fehlgeschlagen',
    Add = 'Rechnung hinzufügen fehlgeschlagen',
    CreateXRechnungFromLedgerImport = 'x-Rechnung generieren fehlgeschlagen',
    sfdtTemplateGet = 'Rechnungstemplates abrufen fehlgeschlagen',
}

@Injectable()
export class LedgerImportEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
        private apiToasterService: ToastrService,
        private bvResolver: BusinessVolumeResolver,
        private fileDataService: FileDataService,
        private customerResolver: CustomerResolver,
        private officeResolver: OfficeResolver,
        private accountsReceivableLedgerResolver: AccountsReceivableLedgerResolver,
        private partnerOfficeResolver: PartnerOfficeResolver,
        private sFDTTemplateResolver: SFDTTemplateResolver,
        private commissionResolver: CommissionResolver,
    ) {}

    Load = createEffect(() =>
        this.actions$.pipe(
            ofType(LedgerImportActionTypes.GetLedgerImports),
            withLatestFrom(this.store$),
            switchMap(([{ Payload }, store]) => {
                const queryString = `query {
                  ledgerImport${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''}{${LedgerImportEntity.GqlFields}}
            }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                    map((res) =>
                        res && res.ledgerImport
                            ? Payload?.updatedSince
                                ? LedgerImportActionTypes.UpdateMany({
                                      Payload: res.ledgerImport.map((val) => LedgerImportEntityFromBackend(val)),
                                      updateLatestUpdatedAt: true,
                                  })
                                : LedgerImportActionTypes.UpdateAll({
                                      Payload: res.ledgerImport.map((val) => LedgerImportEntityFromBackend(val)),
                                      updateLatestUpdatedAt: true,
                                  })
                            : BaseActionTypes.ErrorAction({
                                  Payload: { ToasterMessage: LedgerImportEffectErrorCodes.Load },
                              }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: LedgerImportEffectErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    AddLedgerImportByDefaultsQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LedgerImportActionTypes.AddLedgerImportWithDefaults),
            withLatestFrom(this.store$.select(getUser), this.store$.select(getPartner)),
            switchMap(v => combineLatest([
                of(v),
                getFetched$(this.store$, getCustomersFetched, getCustomers, this.customerResolver),
                getFetched$(this.store$, getOfficesFetched, getOffices, this.officeResolver),
                getFetched$(this.store$, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgers, this.accountsReceivableLedgerResolver),
                getFetched$(this.store$, getPartnerOfficesFetched, getOwnPartnerOffices, this.partnerOfficeResolver),
                getFetched$(this.store$, getSfdtTemplatesFetched, getSfdtTemplates, this.sFDTTemplateResolver),
                getFetched$(this.store$, getCommissionsFetched, getCommissionDictionary, this.commissionResolver),
            ]).pipe(first())),
            map(([[{ Payload }, user, partner], customers, offices, bookingPositions, partnerOffices, templates, commissions]) => {
                const commission = Payload.commissionId ? commissions[Payload.commissionId] : null;
                const customer = Payload.customerId ? customers.find((c) => c.Id === Payload.customerId) : null;
                const office = customer ? offices.find((o) => o.CustomerId === customer.Id) : null;
                let amount = 0;
                bookingPositions
                    ?.filter((bp) => Payload.accountsReceivableLedgerIds?.includes(bp.Id) && isNotNullOrUndefined(bp.CompleteCost))
                    .forEach((bp) => {
                        amount = parseFloat((amount + bp.CompleteCost).toFixed(10));
                    });
                const invoiceDate = new Date();
                const deliveryDate = new Date();
                const dueDate = new Date();
                const discountPaymentDate = new Date();
                deliveryDate.setDate(deliveryDate.getDate() + 30);
                dueDate.setDate(deliveryDate.getDate() + 30);
                discountPaymentDate.setDate(discountPaymentDate.getDate() + 14);
                const partnerOffice = Payload.partnerOfficeId ? partnerOffices.find((p) => p.Id === Payload.partnerOfficeId) : partnerOffices?.find((p) => p.MainOffice);
                return LedgerImportActionTypes.AddLedgerImports({
                    Payload: {
                        discountPaymentDate: FrontendDate(discountPaymentDate),
                        accountsReceivableLedgerIds: Payload.accountsReceivableLedgerIds,
                        consolidatedAmount: amount,
                        consolidatedDate: FrontendDate(invoiceDate),
                        consolidatedDeliveryDate: FrontendDate(deliveryDate),
                        consolidatedInvoiceId: '',
                        customerId: customer?.Id,
                        commissionId: Payload.commissionId,
                        ustId: partnerOffice?.UstID,
                        iban: partnerOffice?.IBAN,
                        bic: partnerOffice?.Bic,
                        bankname: partnerOffice?.BankName,
                        status: LedgerImportStatusEnum.Neu,
                        commissionNumber: commission?.Auftragsnummer,
                        customerName: customer?.Name,
                        customerEmail: office?.Email,
                        customerPostalCode: office?.PostalCode,
                        customerCity: office?.City,
                        customerStreet: office?.Street,
                        partnerPhoneNumber: partnerOffice?.PhoneNumber,
                        partnerEmail: partnerOffice?.Email,
                        partnerPostalCode: partnerOffice?.PostalCode,
                        partnerCity: partnerOffice?.City,
                        partnerName: partnerOffice?.Name,
                        partnerStreet: partnerOffice?.Street,
                        partnerCountry: partnerOffice?.Country,
                        userEmail: user.Email,
                        userName: user.DisplayName,
                        textBottom:
                            (partner.Id === 117 || partner.Id === 40 || partner.Id === 1) && !(Payload.documentType === 'offer')
                                ? 'Zahlungsbedingungen: \n' + '\n' + 'Zahlbar ohne Abzug innerhalb 14 Tage\t \n' + '\n' + ' \n' + '\n' + 'Leistungszeitraum:     /2021 '
                                : 'Dieses Schreiben wurde maschinell erstellt und ist ohne Unterschrift gültig.',
                        textTop: (partner.Id === 117 || partner.Id === 40 || partner.Id === 1) && !(Payload.documentType === 'offer') ? 'Gutachterliche Beratung  \n' + '\n' + 'BV:  ' : '',
                        documentType: Payload.documentType,
                        automaticInvoiceId: false,
                        ledgerImportId: Payload.ledgerImportId,
                        partnerOfficeId: partnerOffice?.Id,
                        dueDate: FrontendDate(dueDate),
                        additionalData: JSON.stringify({
                            twigTemplateId: templates.find((t) => t.PartnerId === partner.Id) || templates.find((t) => t.PartnerId === null),
                        }),
                        registryCourt: partnerOffice?.RegistryCourt,
                        commercialCourt: partnerOffice?.CommercialRegister,
                        ceoName: partnerOffice?.CeoName,
                        eventId: Payload.eventId,
                    },
                });
            }),
        ),
    );

    AddLedgerImportQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LedgerImportActionTypes.AddLedgerImports),
            switchMap(({ Payload }) => {
                const status = Payload.status != null ? Payload.status : LedgerImportStatusEnum.Neu;
                const queryString = `mutation { createLedgerImport(
    ${isNotNullOrUndefined(Payload.consolidatedAmount) ? 'consolidatedAmount:' + (Payload.consolidatedAmount * 100).toFixed(0) : ''}
    ${stringifyIfNotNullOrUndefined(Payload, 'consolidatedDate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'consolidatedInvoiceId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'consolidatedCurrencyCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'consolidatedDeliveryDate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'consolidatedOrderId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'textBottom')}
    ${stringifyIfNotNullOrUndefined(Payload, 'textTop')}
    ${stringifyIfNotNullOrUndefined(Payload, 'ustId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'iban')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bic')}
    ${stringifyIfNotNullOrUndefined(Payload, 'accountNumber')}
    ${stringifyIfNotNullOrUndefined(Payload, 'blz')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bankname')}
    ${stringifyIfNotNullOrUndefined(Payload, 'accountsReceivableLedgerIds')}
    status: ${JSON.stringify(status)}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerName')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerEmail')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerPostalCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerCity')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerStreet')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerCountry')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerPhoneNumber')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerEmail')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerName')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerPostalCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerCity')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerStreet')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerCountry')}
    ${stringifyIfNotNullOrUndefined(Payload, 'userName')}
    ${stringifyIfNotNullOrUndefined(Payload, 'userEmail')}
    ${stringifyIfNotNullOrUndefined(Payload, 'documentType')}
    ${stringifyIfNotNullOrUndefined(Payload, 'rootId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'guaranteedDiscountPercentage')}
    ${stringifyIfNotNullOrUndefined(Payload, 'isTemplate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'automaticInvoiceId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'dueDate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'ledgerImportId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerOfficeId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'ceoName')}
    ${stringifyIfNotNullOrUndefined(Payload, 'registryCourt')}
    ${stringifyIfNotNullOrUndefined(Payload, 'commercialCourt')}
    ${stringifyIfNotNullOrUndefined(Payload, 'commissionNumber')}
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPaymentDate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'eventId')}
  ) {${LedgerImportEntity.GqlFields}}
        }
        `;
                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    withLatestFrom(this.store$.select(getLedgerImports), this.store$.select(getBusinessVolumeFetched)),
                    tap(([, , bvFetched]) => {
                        if (bvFetched) {
                            this.bvResolver.resolve();
                        }
                    }),
                    concatMap(([res, ledgerImports]) =>
                        res && res.createLedgerImport
                            ? [
                                  LedgerImportActionTypes.UpdateAll({
                                      Payload: [...ledgerImports, LedgerImportEntityFromBackend(res.createLedgerImport)],
                                  }),
                                  AccountsReceivableLedgerActionTypes.GetAccountsReceivableLedger(), // ARL neu holen da sich die LedgerImportId geändert haben könnte
                              ]
                            : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: LedgerImportEffectErrorCodes.Add } })],
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: LedgerImportEffectErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    ChangeLedgerImportQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LedgerImportActionTypes.ChangeLedgerImports),
            concatMap(({ Payload }) => {

                if (!Payload.accountsReceivableLedgerIds) {
                    console.error('no accountsReceivableLedgerIds');
                }
                const hasVars = Payload.html !== undefined
                const query = `
            mutation${hasVars ? '(' : ''}${Payload.html !== undefined? '$html: String ' : ''}${hasVars ? ')' : ''} { changeLedgerImport(
    id: ${Payload.id}
    ${isNotNullOrUndefined(Payload.consolidatedAmount) ? 'consolidatedAmount:' + (Payload.consolidatedAmount * 100).toFixed(0) : ''}
    ${stringifyIfNotUndefined(Payload, 'consolidatedDate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'consolidatedInvoiceId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'consolidatedCurrencyCode')}
    ${stringifyIfNotUndefined(Payload, 'consolidatedDeliveryDate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'consolidatedOrderId')}
    ${stringifyIfNotUndefined(Payload, 'customerId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'textBottom')}
    ${stringifyIfNotNullOrUndefined(Payload, 'textTop')}
    ${stringifyIfNotNullOrUndefined(Payload, 'ustId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'iban')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bic')}
    ${stringifyIfNotNullOrUndefined(Payload, 'accountNumber')}
    ${stringifyIfNotNullOrUndefined(Payload, 'blz')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bankname')}
    ${stringifyIfNotNullOrUndefined(Payload, 'booked')}
    ${stringifyIfNotNullOrUndefined(Payload, 'accountsReceivableLedgerIds')}
    ${stringifyIfNotNullOrUndefined(Payload, 'status')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerName')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerEmail')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerPostalCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerCity')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerStreet')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerCountry')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerPhoneNumber')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerEmail')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerName')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerPostalCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerCity')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerStreet')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerCountry')}
    ${stringifyIfNotNullOrUndefined(Payload, 'userName')}
    ${stringifyIfNotNullOrUndefined(Payload, 'userEmail')}
    ${stringifyIfNotNullOrUndefined(Payload, 'documentType')}
    ${stringifyIfNotNullOrUndefined(Payload, 'rootId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'guaranteedDiscountPercentage')}
    ${stringifyIfNotNullOrUndefined(Payload, 'isDirty')}
    ${stringifyIfNotNullOrUndefined(Payload, 'isTemplate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPaymentDate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPaymentDate2')}
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPercentage')}
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPercentage2')}
    ${stringifyIfNotNullOrUndefined(Payload, 'automaticInvoiceId')}
    ${stringifyIfNotUndefined(Payload, 'dueDate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerOfficeId')}
    ${stringifyIfNotUndefined(Payload, 'commissionId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
    ${stringifyIfNotNullOrUndefined(Payload, 'ceoName')}
    ${stringifyIfNotNullOrUndefined(Payload, 'registryCourt')}
    ${stringifyIfNotNullOrUndefined(Payload, 'commercialCourt')}
    ${stringifyIfNotNullOrUndefined(Payload, 'commissionNumber')}
    ${stringifyIfNotNullOrUndefined(Payload, 'hideNetto')}
    ${Payload.html != null ? 'html: $html' : ''}
  ) {${LedgerImportEntity.GqlFields}}
        }
        `;
                const variables = {
                    html: Payload.html,
                };
                return this.gatewayHttpService.graphQl({ query, variables }).pipe(
                    withLatestFrom(this.store$.select(getLedgerImports), this.store$.select(getBusinessVolumeFetched)),
                    tap(([, , bvFetched]) => {
                        if (bvFetched) {
                            this.bvResolver.resolve();
                        }
                    }),
                    tap(([res, ledgerImports]) => {
                        if (res && res.changeLedgerImport) {
                            this.apiToasterService.success('Speichern erfolgreich');
                            if (res.changeLedgerImport.status !== ledgerImports.find((l) => l.Id === res.changeLedgerImport.id)?.Status) {
                                this.apiToasterService.success(`Status auf ${LedgerImportStatusNames.get(res.changeLedgerImport.status)} geändert`);
                            }
                        }
                    }),
                    concatMap(([res, ledgerImports]) => {
                        if (res && res.changeLedgerImport) {
                            if (res.changeLedgerImport.documentId) {
                                this.fileDataService.GetFileById$(res.changeLedgerImport.documentId, true).pipe(first()).subscribe();
                            }
                            return [
                                LedgerImportActionTypes.UpdateAll({
                                    Payload: [...ledgerImports.filter((li) => li.Id !== res.changeLedgerImport.id), LedgerImportEntityFromBackend(res.changeLedgerImport)],
                                }),
                                AccountsReceivableLedgerActionTypes.GetAccountsReceivableLedger(), // ARL neu holen da sich die LedgerImportId geändert haben könnte
                            ];
                        } else {
                            return [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: LedgerImportEffectErrorCodes.Modify } })];
                        }
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: LedgerImportEffectErrorCodes.Modify,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    CreateXRechnungFromLedgerImportQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LedgerImportActionTypes.CreateXRechnungFromLedgerImport),
            switchMap(({ Payload }) => {
                const query = `mutation{createXRechnungFromLedgerImport(id: ${Payload.id}){${LedgerImportEntity.GqlFields}}}`;

                return this.gatewayHttpService.graphQl({ query }).pipe(
                    withLatestFrom(this.store$.select(getLedgerImports), this.store$.select(getBusinessVolumeFetched)),
                    tap(([, , bvFetched]) => {
                        if (bvFetched) {
                            this.bvResolver.resolve();
                        }
                    }),
                    tap(([res, ledgerImports]) => {
                        if (res && res.createXRechnungFromLedgerImport) {
                            this.apiToasterService.success('X-Rechnung generiert');
                        }
                    }),
                    concatMap(([res, ledgerImports]) =>
                        res && res.createXRechnungFromLedgerImport
                            ? [
                                  LedgerImportActionTypes.UpdateAll({
                                      Payload: [...ledgerImports.filter((li) => li.Id !== res.createXRechnungFromLedgerImport.id), LedgerImportEntityFromBackend(res.createXRechnungFromLedgerImport)],
                                  }),
                                  FilesActionTypes.LoadFilesAfter({
                                      Payload: 10,
                                  }), // files neu holen da die Pdf abgelegt worden sein könnte
                              ]
                            : [
                                  BaseActionTypes.ErrorAction({
                                      Payload: { ToasterMessage: LedgerImportEffectErrorCodes.CreateXRechnungFromLedgerImport },
                                  }),
                              ],
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: LedgerImportEffectErrorCodes.CreateXRechnungFromLedgerImport,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    DuplicateLedgerImportQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LedgerImportActionTypes.CreateLedgerImportFromLedgerImport),
            switchMap(({ Payload }) => {
                const query = `
    mutation{
    createLedgerImportFromLedgerImport(
    rootId: ${Payload.rootId}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'isTemplate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'documentType')}
    ${stringifyIfNotNullOrUndefined(Payload, 'templateName')}
  ) {
    ${LedgerImportEntity.GqlFields}
    }
  }
        `;
                return this.gatewayHttpService.graphQl({ query }).pipe(
                    withLatestFrom(this.store$.select(getLedgerImports), this.store$.select(getBusinessVolumeFetched)),
                    tap(([, , bvFetched]) => {
                        if (bvFetched) {
                            this.bvResolver.resolve();
                        }
                    }),
                    concatMap(([res, ledgerImports]) =>
                        res && res.createLedgerImportFromLedgerImport
                            ? [
                                  LedgerImportActionTypes.UpdateAll({
                                      Payload: [...ledgerImports, LedgerImportEntityFromBackend(res.createLedgerImportFromLedgerImport)],
                                  }),
                                  FilesActionTypes.LoadFilesAfter({
                                      Payload: 10,
                                  }), // files neu holen da die Pdf abgelegt worden sein könnte
                                  AccountsReceivableLedgerActionTypes.GetAccountsReceivableLedger(), // ARL neu holen da sich die LedgerImportId geändert haben könnte
                              ]
                            : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: LedgerImportEffectErrorCodes.Duplicate } })],
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: LedgerImportEffectErrorCodes.Duplicate,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    SfdtTemplateQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LedgerImportActionTypes.GetSFDTTemplate),
            switchMap(({ Payload, withData }) => {
                const query = `query{ledgerImportTemplate${Payload?.id ? `(id: ${Payload.id})` : ' '}{
    ${SfdtTemplateEntity.GqlFields}
    ${withData ? 'html' :'' }
  }
}
        `;
                return this.gatewayHttpService.graphQl({ query }, { retry: true }).pipe(
                    withLatestFrom(this.store$.select(getSfdtTemplates)),
                    concatMap(([res, templates]) =>
                        res && res.ledgerImportTemplate && (!Payload?.id || res.ledgerImportTemplate.length === 1)
                            ? [
                                  LedgerImportActionTypes.UpdateSFDTTemplate({
                                      // Payload: []
                                      Payload: Payload?.id ? [...(templates || []).filter((t) => t.Id !== res.ledgerImportTemplate[0].id), SfdtTemplateEntityFromBackend(res.ledgerImportTemplate[0])] : res.ledgerImportTemplate.map(SfdtTemplateEntityFromBackend),
                                  }),
                                  // BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'test' } })
                              ]
                            : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: LedgerImportEffectErrorCodes.sfdtTemplateGet } })],
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: LedgerImportEffectErrorCodes.sfdtTemplateGet,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    DeleteLedgerImportQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LedgerImportActionTypes.DeleteLedgerImport),
            withLatestFrom(this.store$),
            concatMap(([action, store]) => {
                const query = `
    mutation{
    deleteLedgerImport(
    id: ${action.Payload.id}
  )
  }
        `;
                return this.gatewayHttpService.graphQl({ query }, { token: store.base.token }).pipe(
                    withLatestFrom(this.store$.select(getLedgerImports), this.store$.select(getBusinessVolumeFetched)),
                    tap(([, , bvFetched]) => {
                        if (bvFetched) {
                            this.bvResolver.resolve();
                        }
                    }),
                    concatMap(([res, ledgerImports]) =>
                        res //Todo uncomment && res.deleteLedgerImport
                            ? [
                                  LedgerImportActionTypes.UpdateAll({
                                      Payload: [...ledgerImports.filter((l) => l.Id !== action.Payload.id)],
                                  }),
                                  AccountsReceivableLedgerActionTypes.GetAccountsReceivableLedger(), // ARL neu holen da sich deren status geändert haben könnte
                              ]
                            : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: LedgerImportEffectErrorCodes.Remove } })],
                    ),

                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: LedgerImportEffectErrorCodes.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
}
