import { TextTemplateLanguagesType, TextTemplatesType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';


export class TextTemplateLanguageEntity {
    constructor(
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public Id: number = null,
        public Language: string = null,
        public PartnerId: number = null,
        public Text: string = null,
        public TextTemplateId: number = null,
        public UpdatedAt: Date = null,
        public ShortText: string = null,
    ) {}
    public static readonly GqlFields = `createdAt
    deletedAt
    id
    language
    partnerId
    text
    textTemplateId
    updatedAt
    shortText`;
    public Clone(override: TextTemplateLanguageAttributes = {}): TextTemplateLanguageEntity {
        const attr = { ...this, ...override };
        return Object.assign(new TextTemplateLanguageEntity(), attr);
    }
}

export function TextTemplateLanguageEntityFromBackend(res: TextTemplateLanguagesType): TextTemplateLanguageEntity {
    return new TextTemplateLanguageEntity(
        BackendDate(res.createdAt),
        res.deletedAt ? BackendDate(res.deletedAt) : null,
        res.id,
        res.language,
        res.partnerId,
        res.text,
        res.textTemplateId,
        BackendDate(res.updatedAt),
        res.shortText,
    );
}

export interface TextTemplateLanguageAttributes {
    CreatedAt?: Date;
    DeletedAt?: Date;
    Id?: number;
    Language?: string;
    PartnerId?: number;
    Text?: string;
    TextTemplateId?: number;
    UpdatedAt?: Date;
    ShortText?: string;
}
