import {createReducer, on} from '@ngrx/store';
import {RoleEntity} from '../../entities/role.entity';
import {PermissionEntity} from '../../entities/permission.entity';
import {PermissionsActionTypes} from '../actions/permissions.actions';


export const permissionsFeatureKey = 'permissions';

export interface PermissionsState {
    roles: RoleEntity[];
    permissions: PermissionEntity[];
}

export const initialPermissionsState: PermissionsState = {
    roles: null,
    permissions: null
};


export const permissionsReducer = createReducer(
    initialPermissionsState,
    on(PermissionsActionTypes.LoadRoles, (state, { Payload }) => ({
        ...state,
        roles: Payload
    })),
    on(PermissionsActionTypes.LoadPermissions, (state, { Payload }) => ({
        ...state,
        permissions: Payload
    })),
);

