import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { ViewStyleSettingActionTypes } from '../State/actions/viewStyleSetting.action';
import { getViewStyleSettingFetched } from '../State/selectors/viewStyleSetting.selector';
import { BaseEntityResolver } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class ViewStyleSettingResolver extends BaseEntityResolver {
    constructor(store: Store<State>) {
        super(getViewStyleSettingFetched, ViewStyleSettingActionTypes.LoadAll(), store);
    }
}
