import { createAction, props } from '@ngrx/store';
import { EmployeeEntity } from '../../entities/employee.entity';
import {
    DaveMutationChangeEmployeeArgs,
    DaveMutationCreateEmployeeArgs,
    DaveMutationDeleteEmployeeArgs, QueryEmployeeArgs,
} from '../../graphql-types';

export const EmployeeActionTypes = {
    Load: createAction('data/Employee/Load', props<{ Payload?: QueryEmployeeArgs }>()),
    UpdateAll: createAction('data/Employee/UpdateAll', props<{ Payload: EmployeeEntity[]; updateLatestUpdatedAt?: boolean; }>()),
    UpdateMany: createAction('data/Employee/UpdateMany', props<{ Payload: EmployeeEntity[]; updateLatestUpdatedAt?: boolean; }>()),
    UpdateOne: createAction('data/Employee/UpdateOne', props<{ Payload: EmployeeEntity }>()),
    AddEmployeeRequest: createAction('data/Employee/AddEmployeeRequest', props<{ Payload: DaveMutationCreateEmployeeArgs }>()),
    AddEmployee: createAction('data/Employee/AddEmployee', props<{ Payload: EmployeeEntity }>()),
    ModifyEmployeeRequest: createAction('data/Employee/ModifyEmployeeRequest', props<{ Payload: DaveMutationChangeEmployeeArgs }>()),
    DeleteEmployeeRequest: createAction('data/Employee/DeleteEmployeeRequest', props<{ Payload: { EmployeeId: DaveMutationDeleteEmployeeArgs } }>()),
    SetEmployeeUser: createAction('data/Employee/SetEmployeeUser', props<{ Payload: { UserIds: number[]; EmployeeId } }>()),
    DeleteEmployee: createAction('data/Employee/DeleteEmployee', props<{ Payload: number }>()),
};
