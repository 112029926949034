
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { GeneratedDocumentsTypeEntity } from '../entities/generatedDocumentsType.entity';
import { State } from '../State';
import { getGeneratedDocumentsTypes } from '../State/selectors/generatedDocumentsType.selectors';
import { GeneratedDocumentsTypeActionTypes } from '../State/actions/generatedDocumentsType.actions';

@Injectable({
  providedIn: 'root'
})
export class GeneratedDocumentsTypeResolver implements Resolve<GeneratedDocumentsTypeEntity[]> {
  constructor(private store: Store<State>) {
  }

  resolve() {
    const value$ = this.store.select(getGeneratedDocumentsTypes);

    value$
      .pipe(
        filter(value => !value),
        take(1),
        tap(() => {
          if (localStorage.getItem('token') != null) {
            this.store.dispatch(GeneratedDocumentsTypeActionTypes.GetGeneratedDocumentsTypeRequest());
          }
        })
      )
      .subscribe();

    return value$.pipe(
      filter(value => !!value),
      take(1)
    );
  }
}
