import { StepperSelectionEvent, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { DropzoneConfig } from 'ngx-dropzone-wrapper';
import { combineLatest, firstValueFrom, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, first, map, shareReplay, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AddReportService } from '../../../../add-report-button/add-report.service';
import { ConstructionDiaryEntity, ConstructionDiaryEntityAdditionalData, ConstructionDiaryStatusEnum, ConstructionDiaryStatusEnumNameMap } from '../../../../dave-data-module/entities/construction-diary.entity';
import { EventTypeNamesEnum } from '../../../../dave-data-module/entities/event-type.entity';
import { EventEntity, EventStateEnum, EventStateEnumNameMap } from '../../../../dave-data-module/entities/event.entity';
import { FileEntity, FileMetaData } from '../../../../dave-data-module/entities/file.entity';
import { FolderEntity, FolderTypes } from '../../../../dave-data-module/entities/folder.entity';
import { GeneratedDocumentsEntityAdditionalData } from '../../../../dave-data-module/entities/generatedDocuments.entity';
import { GeneratedDocumentsTypeSlugs } from '../../../../dave-data-module/entities/generatedDocumentsType.entity';
import { PartnerTypeEnum } from '../../../../dave-data-module/entities/partner.entity';
import { DaveMutationCreateConstructionDiaryArgs } from '../../../../dave-data-module/graphql-types';
import { CommissionResolver } from '../../../../dave-data-module/guards/commission.resolver';
import { ConstructionDiaryResolver } from '../../../../dave-data-module/guards/construction-diary.resolver';
import { EventTypeResolver } from '../../../../dave-data-module/guards/event-type.resolver';
import { EventResolver } from '../../../../dave-data-module/guards/event.resolver';
import { GeneratedDocumentsResolver } from '../../../../dave-data-module/guards/generatedDocuments.resolver';
import { GeneratedDocumentsTypeResolver } from '../../../../dave-data-module/guards/generatedDocumentsType.resolver';
import { FrontendDate } from '../../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { ConstructionDiaryDataService, DailyTaskType } from '../../../../dave-data-module/services/construction-diary-data.service';
import { FileDataService } from '../../../../dave-data-module/services/file-data.service';
import { FolderDataService } from '../../../../dave-data-module/services/folder-data.service';
import { State } from '../../../../dave-data-module/State';
import { ConstructionDiaryActionTypes } from '../../../../dave-data-module/State/actions/construction-diary.actions';
import { EventsActionTypes } from '../../../../dave-data-module/State/actions/events.actions';
import { FilesActionTypes, fileUploadParamsObj } from '../../../../dave-data-module/State/actions/files.actions';
import { GeneratedDocumentsActionTypes } from '../../../../dave-data-module/State/actions/generatedDocuments.actions';
import { getToken } from '../../../../dave-data-module/State/selectors/base.selectors';
import { getCommissionById, getCommissions } from '../../../../dave-data-module/State/selectors/commission.selector';
import { getCommissionTypes } from '../../../../dave-data-module/State/selectors/commissionType.selectors';
import { getConstructionDiaries, getConstructionDiariesFetched } from '../../../../dave-data-module/State/selectors/construction-diary.selectors';
import { getEventTypes } from '../../../../dave-data-module/State/selectors/event-type.selector';
import { getDailyToDos, getEventById, getEvents } from '../../../../dave-data-module/State/selectors/events.selectors';
import { getFiles } from '../../../../dave-data-module/State/selectors/files.selectors';
import { getFolderByEntity } from '../../../../dave-data-module/State/selectors/folder.selectors';
import { getGeneratedDocuments, getGeneratedDocumentsFetched } from '../../../../dave-data-module/State/selectors/generatedDocuments.selectors';
import { getGeneratedDocumentsTypes, getGeneratedDocumentsTypesFetched } from '../../../../dave-data-module/State/selectors/generatedDocumentsType.selectors';
import { getPartner } from '../../../../dave-data-module/State/selectors/partners.selectors';
import { DocumentEditorDialogComponent, DocumentEditorDialogComponentCloseData, DocumentEditorDialogComponentDialogData } from '../../../../dave-document-editor-module/components/document-editor-dialog/document-editor-dialog.component';
import { ButtonConfig } from '../../../../dave-list-card/dave-list-card.component';
import {
    DaveSelectFileFromDmsComponent,
    DaveSelectFileFromDmsComponentDialogData,
    DaveSelectFileFromDmsComponentReturnData,
} from '../../../../dave-select-file-from-dms/components/dave-select-file-from-dms/dave-select-file-from-dms.component';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, sameDay } from '../../../../helper/helper';
import { DMSPageMeta } from '../../../../helper/page-metadata';
import { LoadingService } from '../../../../services/loading.service';
import { ShowImageComponent, ShowImageComponentDialogData } from '../../show-image/show-image.component';
import { AddEventDialogComponent, AppAddEventDialogData, AppAddEventDialogDataReturn } from '../add-event-dialog/add-event-dialog.component';
import { getUser } from 'src/app/dave-data-module/State/selectors/users.selectors';
import { CommissionEntity } from 'src/app/dave-data-module/entities/commission.entity';
import { getUserToCommissions } from 'src/app/dave-data-module/State/selectors/user-to-commission.selector';
import { getEmployeesSortedByNameStartWithMe } from 'src/app/dave-data-module/State/selectors/employees.selectors';
import { getEntityRole } from 'src/app/dave-data-module/State/selectors/entity-role.selector';
import { EntityRoleSlugEnum } from 'src/app/dave-data-module/entities/entity-role.entity';

export interface DetailConstructionDiaryEntryComponentDialogData {
    CommissionId: number;
    DiaryDate: Date;
}
@Component({
    selector: 'app-detail-construction-diary-entry',
    templateUrl: './detail-construction-diary-entry.component.html',
    styleUrls: ['./detail-construction-diary-entry.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
        DatePipe,
    ],
})
export class DetailConstructionDiaryEntryComponent implements OnInit, OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        minWidth: '50vw',
        // panelClass: ['custom-dialog-class-without-padding', 'custom-dialog-class-mobile-fullscreen'],
    };
    @ViewChild('matStepper') MatStepper: MatStepper;
    public DMSNotMinimalistic = false;
    public FolderTypes = FolderTypes;
    public LeistungsergebnisseUseTemplateMode = false;
    public Diary$: Observable<ConstructionDiaryEntity>;
    public Folder$: Observable<FolderEntity>;
    public Loaded$: Observable<true>;
    public Files$: Observable<FileEntity[]>;
    public EventsErschwerniss$: Observable<{ type: string; typeId: number; events: MatTableDataSource<EventEntity>; icon: string }[]>;
    public EventsZusatz$: Observable<{ type: string; typeId: number; events: MatTableDataSource<EventEntity>; icon: string }[]>;
    public Tasks$: Observable<DailyTaskType[]>;
    public TaskTableData$: Observable<MatTableDataSource<EventEntity & { stateName: string; time: string }>>;
    private fileDialog: MatDialogRef<any>;
    public PartnerTypeKagetec = PartnerTypeEnum.Kagetec;
    public PartnerTypeId$ = this.store.select(getPartner).pipe(
        map((partner) => {
            return partner?.PartnerTypeId;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public NewTaskForm = this.fb.group({});
    public Form = new FormGroup({
        Weather: new FormControl<string>(''),
        Temperature: new FormControl<number>(null),
        Leistungsergebnisse: new FormControl<string>(''),
        LeistungsergebnisseTemplate: new FormControl<number[]>([]),
        BesondereVorkommnisse: new FormControl<string>(''),
        Leistungsaenderungen: new FormControl<string>(''),
    });
    public DMSMeta = DMSPageMeta;
    public DropzoneConfig$: Observable<DropzoneConfig>;
    private save$ = new Subject<void>();
    private routerSubscription: Subscription;
    public IsToday$: Observable<boolean>;
    protected subscriptions: Subscription[] = [];
    public SomeRequiredTaskPending$: Observable<boolean>;
    // i == 0 && !diary.Id && NoInstruction
    public SaveButtonDisabled$: Observable<boolean>;
    public HasInspection$: Observable<boolean>;
    public SelectedIndex: number = 0;
    public CurrentUser$ = this.store.select(getUser)
    public Commission$: Observable<CommissionEntity> = this.store.select(getCommissionById({id: this.DialogData.CommissionId}));
    private userToCommissionFromCommission$ = this.Commission$.pipe(
        switchMap((c) => this.store.select(getUserToCommissions).pipe(map((u2cs) => u2cs.filter((u2c) => u2c.CommissionId === c.Id)))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public ShowSelectedUsers$ = combineLatest([this.store.select(getEmployeesSortedByNameStartWithMe), this.Commission$, this.store.select(getEntityRole), this.userToCommissionFromCommission$]).pipe(
        map(([employees, commission, er, u2c]) => {
            return employees
                .filter((e) => u2c.some((u) => u.UserId === e.UserId))
                .map((e) => {
                    return {
                        User: e,
                        Assignee: u2c.some((u) => u.UserId === e.UserId && u.Assignee),
                        roles: u2c
                            .filter((u2) => u2.UserId === e.UserId && commission.Id === u2.CommissionId)
                            .map((item) => {
                                return er?.find((x) => x?.Id === item?.RoleId)?.DisplayName;
                            }),
                    };
                });
        }),
    );
    constructor(
        private constructionDiaryDataService: ConstructionDiaryDataService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public DialogData: DetailConstructionDiaryEntryComponentDialogData,
        public LS: LoadingService,
        private store: Store<State>,
        private fb: UntypedFormBuilder,
        private actions$: Actions,
        public dialogRef: MatDialogRef<DetailConstructionDiaryEntryComponent>,
        private router: Router,
        private datePipe: DatePipe,
        commissionResolver: CommissionResolver,
        constructionDiaryResolver: ConstructionDiaryResolver,
        eventTypeResolver: EventTypeResolver,
        eventResolver: EventResolver,
        private fileDataService: FileDataService,
        private generatedDocumentsTypesResolver: GeneratedDocumentsTypeResolver,
        protected addReportService: AddReportService,
        generatedDocsRes: GeneratedDocumentsResolver,
        private folderDataService: FolderDataService,
    ) {
        eventTypeResolver.resolve();
        commissionResolver.resolve();
        constructionDiaryResolver.resolve();
        eventResolver.resolve();

        this.HasInspection$ = this.store.select(getGeneratedDocumentsFetched).pipe(
            tap((fetched) => {
                if (!fetched) {
                    generatedDocsRes.resolve();
                }
            }),
            filter((fetched) => fetched),
            switchMap(() => this.store.select(getGeneratedDocuments)),
            map((docs) => docs.some((d) => d.AdditionalData?.ConstructionDiary?.CommissionInspectionId === this.DialogData.CommissionId)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.SaveButtonDisabled$ = this.PartnerTypeId$.pipe(switchMap((pTId) => (pTId === PartnerTypeEnum.Kagetec ? this.HasInspection$.pipe(map((hasInspection) => !hasInspection)) : of(false))));
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.routerSubscription?.unsubscribe();
    }
    getFileUploadParams(folder: FolderEntity): fileUploadParamsObj {
        const ret: fileUploadParamsObj = { folder_id: folder.Id.toString() };

        if (folder.Type === FolderTypes.construction_diary) {
            const metaData = {};
            metaData['constructionDiaryIds'] = [folder.EntityId];
            ret.metadata = JSON.stringify(metaData);
        }
        return ret;
    }
    ngOnInit(): void {
        this.routerSubscription = this.router.events
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationStart),
                filter(() => !!this.dialogRef),
            )
            .subscribe(() => {
                this.dialogRef.close();
            });
        this.Diary$ = combineLatest([this.store.select(getCommissionById({ id: this.DialogData.CommissionId })), this.store.select(getConstructionDiaries)]).pipe(
            map(([commission, diaries]) => diaries.find((d) => d.CommissionId === commission.Id && sameDay(d.Date, this.DialogData.DiaryDate))),
            map((diary) =>
                diary
                    ? diary
                    : new ConstructionDiaryEntity().Clone({
                          CommissionId: this.DialogData.CommissionId,
                          Date: this.DialogData.DiaryDate,
                          Status: ConstructionDiaryStatusEnum.Newly,
                      }),
            ),
            tap((diary) =>
                this.Form.setValue({
                    Weather: diary.AdditionalData?.weather?.description || '',
                    Leistungsergebnisse: diary.Leistungsergebnisse || '',
                    LeistungsergebnisseTemplate: diary.AdditionalData?.leistungsergebnisseTemplateIds || [],
                    Temperature: diary.AdditionalData?.weather?.temp || null,
                    BesondereVorkommnisse: diary.BesondereVorkommnisse || '',
                    Leistungsaenderungen: diary.Leistungsaenderungen || '',
                }),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.Folder$ = this.Diary$.pipe(
            filter((d) => d && !!d.Id),
            switchMap((diary) =>
                this.folderDataService.getFolderFromEntity(diary.Id, FolderTypes.construction_diary).pipe(switchMap(() => this.store.select(getFolderByEntity({ entityId: diary.Id, type: FolderTypes.construction_diary })))),
            ),
        );
        this.IsToday$ = this.Diary$.pipe(map((diary) => sameDay(diary.Date, new Date())));
        this.DropzoneConfig$ = combineLatest([this.store.select(getToken), this.Diary$, this.Folder$]).pipe(
            filter((v) => v.every(isNotNullOrUndefined)),
            map(([token, diary, folder]) => ({ token, diary, folder })),
            map(({ token, diary, folder }) => {
                const params = [];
                const metaData: FileMetaData = { constructionDiaryIds: [diary.Id] };
                params['commission_id'] = JSON.stringify([diary.CommissionId]);
                params['metadata'] = JSON.stringify(metaData);
                params['folder_id'] = folder.Id;
                return new DropzoneConfig({
                    autoProcessQueue: true,
                    uploadMultiple: false,
                    paramName: () => 'file',
                    parallelUploads: 100,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params,
                });
            }),
        );
        this.Loaded$ = combineLatest([
            this.store.select(getConstructionDiariesFetched).pipe(filter((v) => !!v)),
            this.store.select(getCommissions).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getEventTypes).pipe(filter(isNotNullOrUndefined)),
        ]).pipe(map(() => true));
        this.Files$ = this.Folder$.pipe(switchMap((folder) => (folder ? this.fileDataService.GetFilesStateFromFolder$(folder.Id).pipe(map((files) => files.filter((f) => !f.Hidden))) : of([]))));
        const diaryEvents$ = combineLatest([this.Diary$, this.store.select(getEvents)]).pipe(
            map(([diary, events]) => (diary?.EventIds ? events?.filter((e) => diary?.EventIds.includes(e.Id)) : [])),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.EventsErschwerniss$ = combineLatest([this.store.select(getEventTypes).pipe(filter(isNotNullOrUndefined)), diaryEvents$]).pipe(
            map(([eventTypes, diaryEvents]) => {
                const mangelType = eventTypes.find((e) => e.Name === EventTypeNamesEnum.Erschwernis);
                const mangelanzeigeType = eventTypes.find((e) => e.Name === EventTypeNamesEnum.Mangelanzeige);
                const bedenkenanzeigeType = eventTypes.find((e) => e.Name === EventTypeNamesEnum.Bedenkenanzeige);
                return [
                    {
                        type: 'Erschwernisse',
                        typeId: mangelType.Id,
                        icon: mangelType.ImagePath,
                        events: new MatTableDataSource(diaryEvents.filter((e) => e.EventTypeId === mangelType.Id)),
                    },
                    {
                        type: 'Mängelanzeigen',
                        typeId: mangelanzeigeType.Id,
                        icon: mangelanzeigeType.ImagePath,
                        events: new MatTableDataSource(diaryEvents.filter((e) => e.EventTypeId === mangelanzeigeType.Id)),
                    },
                    {
                        type: 'Bedenkenanzeigen',
                        typeId: bedenkenanzeigeType.Id,
                        icon: bedenkenanzeigeType.ImagePath,
                        events: new MatTableDataSource(diaryEvents.filter((e) => e.EventTypeId === bedenkenanzeigeType.Id)),
                    },
                ];
            }),
        );
        this.EventsZusatz$ = combineLatest([this.store.select(getEventTypes).pipe(filter(isNotNullOrUndefined)), diaryEvents$]).pipe(
            map(([eventTypes, diaryEvents]) => {
                const nachnahmeType = eventTypes.find((e) => e.Name === EventTypeNamesEnum.Zusatzarbeit);
                return [
                    {
                        type: 'Zusatzarbeiten',
                        typeId: nachnahmeType.Id,
                        icon: nachnahmeType.ImagePath,
                        events: new MatTableDataSource(diaryEvents.filter((e) => e.EventTypeId === nachnahmeType.Id)),
                    },
                ];
            }),
        );
        this.Tasks$ = this.Diary$.pipe(
            switchMap((diary) => {
                if (diary.Id) {
                    return combineLatest([this.Diary$, this.store.select(getDailyToDos)]).pipe(map(([diary, events]) => (diary?.EventIds ? events?.filter((e) => diary?.EventIds.includes(e.Id)) : [])));
                } else {
                    return combineLatest([this.store.select(getCommissionTypes).pipe(filter(isNotNullOrUndefined)), this.store.select(getCommissionById({ id: diary.CommissionId }))]).pipe(
                        switchMap(([cts, commission]) => {
                            this.NewTaskForm = this.fb.group({});
                            const dayliTodos = cts?.find((ct) => ct.Id === commission?.CommissionTypeId)?.AdditionalData?.ConstructionDiary.dailyTodos || [];
                            dayliTodos.forEach((d) => this.NewTaskForm.addControl(d.unicId + '', new UntypedFormControl(EventStateEnum.New)));
                            return this.NewTaskForm.valueChanges.pipe(
                                startWith(''),
                                map((v) => {
                                    return dayliTodos.map((dailyTodo) => {
                                        const date = new Date(FrontendDate(diary.Date, true));
                                        date.setHours(dailyTodo.time.hour);
                                        date.setMinutes(dailyTodo.time.minute);

                                        return new EventEntity().Clone({
                                            Name: dailyTodo.name,
                                            State: v ? v[dailyTodo.unicId] : EventStateEnum.New,
                                            AdditionalData: { ...dailyTodo.additionalData, UnicId: dailyTodo.unicId },
                                            EventDate: date,
                                        });
                                    });
                                }),
                            );
                        }),
                    );
                }
            }),
            withLatestFrom(this.Diary$),
            map(([tasks, diary]) => {
                const now = moment();
                return tasks.map((t) => {
                    return Object.assign(t.Clone(), {
                        stateName: EventStateEnumNameMap.get(t.State),
                        time: this.datePipe.transform(t.EventDate, 'shortTime'),
                        suffixButton: {
                            icon: t.State === EventStateEnum.Done ? 'clipboard-check' : 'clipboard',
                            tooltip: t.State === EventStateEnum.Done ? 'Zurücksetzen' : 'Erledigt',
                            disabled: t.AdditionalData?.preventEarlyExecution && now.isBefore(t.EventDate) ? true : false,
                            disabledTooltip: 'ab ' + this.datePipe.transform(t.EventDate, 'shortTime') + ' verfügbar',
                            onClick: (task) => {
                                if (task.Id) {
                                    const allOthersDone = tasks.filter((t) => t.Id !== task.Id).every((t) => t.State === EventStateEnum.Done);
                                    if (task.State !== EventStateEnum.Done && allOthersDone && (diary.Leistungsergebnisse || diary.AdditionalData?.leistungsergebnisseTemplateIds?.length)) {
                                        this.store.dispatch(
                                            ConstructionDiaryActionTypes.Modify({
                                                Payload: { ...this.getModifyActionPayload(diary), id: diary.Id, status: ConstructionDiaryStatusEnum.Done },
                                            }),
                                        );
                                    } else if (diary.Status === ConstructionDiaryStatusEnum.Done) {
                                        this.store.dispatch(
                                            ConstructionDiaryActionTypes.Modify({
                                                Payload: { ...this.getModifyActionPayload(diary), id: diary.Id, status: ConstructionDiaryStatusEnum.Newly },
                                            }),
                                        );
                                    }
                                    this.store.dispatch(
                                        EventsActionTypes.ModifyEvent({
                                            Payload: {
                                                id: task.Id,
                                                state: task.State === EventStateEnum.Done ? EventStateEnum.New : EventStateEnum.Done,
                                            },
                                        }),
                                    );
                                } else {
                                    this.NewTaskForm.get(task.AdditionalData.UnicId + '').setValue(task.State === EventStateEnum.Done ? EventStateEnum.New : EventStateEnum.Done);
                                }

                                if (task.State !== EventStateEnum.Done && task.AdditionalData?.kagetecImage) {
                                    this.PartnerTypeId$.pipe(first()).subscribe((pId) => {
                                        if (pId === PartnerTypeEnum.Kagetec) {
                                            this.dialog.open<ShowImageComponent, ShowImageComponentDialogData>(ShowImageComponent, {
                                                data: {
                                                    ShowCloseButton: true,
                                                    Url: '/assets/kagetec/' + task.AdditionalData?.kagetecImage + '.jpg',
                                                },
                                                // minWidth: '50vw',
                                                maxHeight: '100vh',
                                                panelClass: 'custom-dialog-class-mobile-fullscreen',
                                                closeOnNavigation: false,
                                            });
                                        }
                                    });
                                }
                            },
                        } as ButtonConfig<DailyTaskType>,
                    });
                });
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.TaskTableData$ = this.Tasks$.pipe(map((v) => new MatTableDataSource(v)));
        this.SomeRequiredTaskPending$ = this.Tasks$.pipe(map((tasks) => tasks?.some((t) => t.State !== EventStateEnum.Done && t.AdditionalData?.isRequiredToComplete)));
        this.subscriptions.push(
            this.save$.pipe(withLatestFrom(this.Diary$, this.Tasks$)).subscribe(([, diary, tasks]) => {
                if (this.LS.isLoading) {
                    return;
                }
                const values: DaveMutationCreateConstructionDiaryArgs = {
                    ...this.getModifyActionPayload(diary),
                };
                this.constructionDiaryDataService.saveDiaryEntry(diary, tasks, values, this.DialogData.CommissionId);
            }),
        );
    }
    public Save($event?: StepperSelectionEvent, isLastStep?: boolean) {
        if ($event || isLastStep) {
            firstValueFrom(this.Tasks$).then((tasks) => {
                tasks
                    .filter((t) => t.State !== EventStateEnum.Done && t.AdditionalData?.autoCompleteOnStepSave && (isLastStep || t.AdditionalData.autoCompleteOnStepSave < $event.selectedIndex + 1))
                    .forEach((t) => t.suffixButton.onClick(t));
            });
        }
        this.save$.next();
    }
    public AddEvent(eventTypeId: number, diary: ConstructionDiaryEntity, eventTypeName: string) {
        this.store
            .select(getCommissionById({ id: diary.CommissionId }))
            .pipe(filter(isNotNullOrUndefined), take(1))
            .subscribe((commission) =>
                this.store.dispatch(
                    EventsActionTypes.AddEvent({
                        Payload: {
                            isTask: false,
                            eventTypeId,
                            commissionId: diary.CommissionId,
                            customerId: commission.CustomerId,
                            eventDate: FrontendDate(diary.Date),
                            state: EventStateEnum.New,
                            name: eventTypeName + ' ' + formatDate(diary.Date, 'dd.MM.yyyy', 'de-DE'),
                        },
                    }),
                ),
            );
        this.actions$.pipe(ofType(EventsActionTypes.UpdateEvent), take(1)).subscribe((action) => {
            this.store.dispatch(ConstructionDiaryActionTypes.Modify({ Payload: { id: diary.Id, eventIds: diary.EventIds ? [...diary.EventIds, action.Payload.Id] : [action.Payload.Id] } }));
            this.dialog
                .open<AddEventDialogComponent, AppAddEventDialogData, AppAddEventDialogDataReturn>(AddEventDialogComponent, {
                    data: {
                        eventId: action.Payload.Id,
                        headline: eventTypeName,
                        hideDate: true,
                    },
                    ...AddEventDialogComponent.DefaultConfig,
                })
                .afterClosed()
                .pipe(
                    switchMap((type) =>
                        this.store.select(getEventById({ id: action.Payload.Id })).pipe(
                            first(),
                            switchMap((event) =>
                                this.folderDataService.getFolderFromEntity(event.Id, FolderTypes.event).pipe(
                                    first(),
                                    tap((folder) => {
                                        if (type === 'abort' || (type === undefined && !event.Name && !event.Description && !folder)) {
                                            this.store.dispatch(EventsActionTypes.DeleteEvent({ Payload: action.Payload.Id }));
                                        }
                                    }),
                                ),
                            ),
                        ),
                    ),
                )
                .subscribe();
        });
    }

    public GetStatusName = (diary: ConstructionDiaryEntity) => ConstructionDiaryStatusEnumNameMap.get(diary.Status);

    public CloseSideDialog() {
        if (this.fileDialog?.getState() === MatDialogState.OPEN) {
            this.fileDialog.close();
        }
    }
    public OpenFileUpload(CommissionId: number) {
        combineLatest([this.Files$, this.folderDataService.getFolderFromEntity(CommissionId, FolderTypes.commission)])
            .pipe(take(1))
            .subscribe(([files, folder]) => {
                this.CloseSideDialog();
                this.fileDialog = this.dialog.open<DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData, DaveSelectFileFromDmsComponentReturnData>(DaveSelectFileFromDmsComponent, {
                    ...DaveSelectFileFromDmsComponent.DefaultConfig,
                    data: {
                        moveWarning: '',
                        folderId: folder?.Id,
                        preSelectedFiles: [...files.map((f) => f.Id)],
                    },
                });
                this.fileDialog
                    .afterClosed()
                    .pipe(
                        take(1),
                        filter((v) => !!v),
                        withLatestFrom(this.store.select(getFiles), this.Diary$),
                        tap(([{ documents }, allfiles, diary]) => {
                            if (documents) {
                                const selectedFiles = allfiles.filter((f) => documents.includes(f.Id));
                                const filesToSet = selectedFiles.filter((f) => !f.MetaData?.constructionDiaryIds?.includes(f.Id));
                                const filesToUnSet = files.filter((f) => !documents.includes(f.Id));
                                filesToSet.forEach((f) => {
                                    const additionaldata: FileMetaData = { ...f.MetaData };
                                    additionaldata.constructionDiaryIds = additionaldata.constructionDiaryIds ? [...additionaldata.constructionDiaryIds, diary.Id] : [diary.Id];
                                    this.store.dispatch(
                                        // FilesActionTypes.ModifyFileRequest({
                                        //     Payload: { DocumentId: f.Id.toString(), AutoSetFolder: true, ConstructionDiaryId: diary.Id.toString(), Metadata: JSON.stringify(additionaldata) },
                                        // }),
                                        FilesActionTypes.CreateLinkRequest({
                                            Payload: { DocumentId: f.Id.toString(), AutoSetFolder: true, ConstructionDiaryId: diary.Id.toString(), Metadata: JSON.stringify(additionaldata) },
                                        }),
                                    );
                                });
                                filesToUnSet.forEach((f) => this.UnsetFile(f, diary.Id));
                            }
                        }),
                    )
                    .subscribe();
            });
    }
    public OpenEditEventDialog(event: EventEntity) {
        let userId: number;
        let deletePermission: boolean = false;
        this.CurrentUser$.subscribe(user => {
            userId = user.Id
        })
        if(event.UserId === userId) {
            deletePermission = true;
        }
        else {
            this.ShowSelectedUsers$.subscribe(user => {
                let permission = user.filter((u) => u.User.UserId === userId && (u.roles.includes(String(EntityRoleSlugEnum.Admin))|| u.roles.includes(String(EntityRoleSlugEnum.Projektleiter))) && isNotNullOrUndefined);
                if(permission.length > 0){
                    deletePermission = true;
                }
            })
        }
        this.dialog.open<AddEventDialogComponent, AppAddEventDialogData, AppAddEventDialogDataReturn>(AddEventDialogComponent, {
            data: {
                eventId: event.Id,
                canDelete: deletePermission,
            },
            ...AddEventDialogComponent.DefaultConfig,
        });
    }
    public UnsetFile(file: FileEntity, diaryId: number) {
        const additionaldata: FileMetaData = { ...file.MetaData };
        additionaldata.constructionDiaryIds = additionaldata.constructionDiaryIds ? additionaldata.constructionDiaryIds.filter((id) => id !== diaryId) : [];
        this.store.dispatch(FilesActionTypes.ModifyFileRequest({ Payload: { DocumentId: file.Id.toString(), Metadata: JSON.stringify(additionaldata) } }));
    }
    private getModifyActionPayload(diary: ConstructionDiaryEntity) {
        const addData: ConstructionDiaryEntityAdditionalData = {
            weather: { description: this.Form.value.Weather, temp: this.Form.value.Temperature },
            leistungsergebnisseTemplateIds: this.LeistungsergebnisseUseTemplateMode ? this.Form.value.LeistungsergebnisseTemplate : null,
        };
        const values: DaveMutationCreateConstructionDiaryArgs = {
            leistungsergebnisse: this.LeistungsergebnisseUseTemplateMode ? null : this.Form.value.Leistungsergebnisse,
            additionalData: JSON.stringify(addData),
            leistungsaenderungen: this.Form.value.Leistungsaenderungen,
            besondereVorkommnisse: this.Form.value.BesondereVorkommnisse,
            commissionId: diary.CommissionId,
        };
        return values;
    }
    public onOpenInspection() {
        firstValueFrom(
            combineLatest([
                this.store.select(getGeneratedDocuments),
                this.Diary$
                    .pipe
                    // first(),
                    // switchMap((diary) => {
                    //     if (diary.Id) {
                    //         return of(diary);
                    //     } else {
                    //         this.save$.next();
                    //         return this.Diary$.pipe(filter((d) => !!d?.Id));
                    //     }
                    // }),
                    (),
            ]),
        ).then(([documents, diary]) => {
            const doc = documents.find((d) => d.CommissionId === this.DialogData.CommissionId && d.AdditionalData?.ConstructionDiary?.CommissionInspectionId === this.DialogData.CommissionId);
            if (doc) {
                this.routeToGenDoc(doc.Id);
            } else {
                firstValueFrom(
                    this.store.select(getGeneratedDocumentsTypesFetched).pipe(
                        tap((fetched) => {
                            if (!fetched) {
                                this.generatedDocumentsTypesResolver.resolve();
                                this.LS.startLoading('resolveGeneratedDocTypes');
                            }
                        }),
                        filter((fetched) => fetched),
                        tap(() => this.LS.endLoading('resolveGeneratedDocTypes')),
                        switchMap(() => this.store.select(getGeneratedDocumentsTypes)),
                    ),
                ).then((types) => {
                    const inspectionType = types.find((t) => t.Slug === GeneratedDocumentsTypeSlugs.kagetecInspection);
                    const addionalData: GeneratedDocumentsEntityAdditionalData = {
                        ConstructionDiary: { CommissionInspectionId: this.DialogData.CommissionId },
                        CustomFieldsModalTemplates: inspectionType.AdditionalData.CustomFieldsModalTemplates,
                    };

                    this.addReportService
                        .NewReport(inspectionType, null, diary.CommissionId, { name: formatDate(diary.Date, 'yyyy-MM-dd', 'de-DE') + '-Inspektionsformular', commissionId: diary.CommissionId, additionalData: JSON.stringify(addionalData) })
                        .then((action) => {
                            if (action.type === GeneratedDocumentsActionTypes.UpdateOne.type) {
                                this.routeToGenDoc(action.Payload.Id);
                            }
                        });
                });
            }
        });
    }
    private routeToGenDoc(id: number) {
        this.dialog.open<DocumentEditorDialogComponent, DocumentEditorDialogComponentDialogData, DocumentEditorDialogComponentCloseData>(DocumentEditorDialogComponent, {
            ...DocumentEditorDialogComponent.DefaultConfig,
            data: {
                generatedDocumentId: id,
            },
        });
    }
    public onStepSelectionChange(event: StepperSelectionEvent) {
        console.log(event);
        this.SelectedIndex = event.selectedIndex;
        this.Save(event);
    }
}
