import { createAction, props } from '@ngrx/store';
import { ViewStyleSettingEntity } from '../../entities/viewStyleSetting.entity';
import { DaveMutationChangeViewStyleSettingArgs, DaveMutationCreateViewStyleSettingArgs } from '../../graphql-types';

export const ViewStyleSettingActionTypes = {
    LoadAll: createAction('data/ViewStyleSettings/loadAll'),
    UpdateAll: createAction('data/ViewStyleSettings/updateAll', props<{ Payload: Array<ViewStyleSettingEntity> }>()),
    Change: createAction('data/ViewStyleSettings/modify', props<{ Payload: DaveMutationChangeViewStyleSettingArgs }>()),
    Create: createAction('data/ViewStyleSettings/create', props<{ Payload: DaveMutationCreateViewStyleSettingArgs }>()),
    Delete: createAction('data/ViewStyleSettings/delete', props<{ Payload: number }>()),
    DeleteOne: createAction('data/ViewStyleSettings/deleteOne', props<{ Payload: number }>()),
    UpdateOne: createAction('data/ViewStyleSettings/updateOne', props<{ Payload: ViewStyleSettingEntity }>()),
};
