import { Component, OnInit, Inject, OnDestroy, Injector } from "@angular/core";
import { Store } from '@ngrx/store';
import { State } from '../../dave-data-module/State';
import { getPartner } from '../../dave-data-module/State/selectors/partners.selectors';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { NewsEntity } from '../../dave-data-module/entities/news.entity';
import { getNews, getNewsFetched } from '../../dave-data-module/State/selectors/news.selector';
import { NewsActionTypes } from '../../dave-data-module/State/actions/news.actions';
import { appMatDialogDefaultConfig } from '../../helper/helper';
import { getUser } from '../../dave-data-module/State/selectors/users.selectors';
import { UntypedFormControl, Validators } from '@angular/forms';
import { PermissionService } from '../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { CKEditorDefaultConfigPublicFileImages } from "../../helper/ckeditor.helper";
import { getToken } from "../../dave-data-module/State/selectors/base.selectors";
import { HttpService } from "../../dave-data-module/services/http.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

export interface NewsComponentDialogData {
    PartnerRestricted: boolean;
}
@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit, OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        maxWidth: '97vw',
        width: '97vw',
        height: '90vh',
        maxHeight: '92vh',
    };
    public Editing = false;
    protected subscriptions: Subscription[] = [];
    public Newses$ = this.store.select(getNewsFetched).pipe(
        filter(v => !!v),
        switchMap(() => this.store.select(getNews)),
        map(news => news.sort((a, b) => b.CreatedAt.getTime() - a.CreatedAt.getTime())),
        shareReplay({refCount: true, bufferSize: 1}),
    );
    public Partner$ = this.store.select(getPartner).pipe(map(p => p));
    private _news: NewsEntity;
    public set News(value: NewsEntity) {
        this._news = value;
        this.EditorContent = value?.Text || '';
    }
    public get News() {
        return this._news;
    }
    public UserId$ = this.store.select(getUser).pipe(map(u => u?.Id));
    public EditorContent = '';
    public TitleForm = new UntypedFormControl('', Validators.required);
    // CKEditorConfig$ = this.store.select(getToken).pipe(map(token => CKEditorDefaultConfigPublicFileImages(token, this.gatewayHttpService.GetUrl('public-files', 'file'))));
    CKEditorConfig = CKEditorDefaultConfigPublicFileImages(this.injector)
    constructor(
        private injector: Injector,
        protected store: Store<State>,
        public PS: PermissionService,
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: NewsComponentDialogData,
        private gatewayHttpService: HttpService,
        private sanitizer: DomSanitizer
        ) {}

    ngOnInit(): void {
        this.subscriptions.push(this.Newses$.subscribe(news => {
            if (this.News) {
                this.News = news.find(n => n.Id === this.News.Id);
            }
        }));
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => {
            s.unsubscribe();
        });
    }
    public SelectNews(n: NewsEntity) {
        this.News = n;
        this.Editing = false;
        if (!n.HasBeenRead) {
            this.store.dispatch(NewsActionTypes.Change({Payload: {id: n.Id, hasBeenRead: true}}));
        }
    }
    public SaveNews() {
        if (this.News) {
            this.store.dispatch(NewsActionTypes.Change({Payload: {id: this.News.Id, text: this.EditorContent, headline: this.TitleForm.value}}));
        } else {
            this.store.dispatch(NewsActionTypes.Create({Payload: {text: this.EditorContent, headline: this.TitleForm.value}}));
        }
        this.Editing = false;
    }
    public SetEditor(news: NewsEntity = null) {
        this.Editing = true;
        this.News = news;
        // this.EditorContent = news?.Text || '';
        this.TitleForm.reset(news?.Headline || '');
    }
    public DeleteNews(id: number) {
        this.store.dispatch(NewsActionTypes.Delete({Payload: {id}}));
        this.News = null;
        this.Editing = false;
    }
}
