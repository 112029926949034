import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined, stringifyIfNotUndefined } from "../../../helper/helper";
import { CustomerEntityFromBackend, CustomerEntityGQLFields, CustomerStatusEnum } from '../../entities/customer.entity';
import { OfficeEntity, OfficeEntityFromBackend } from '../../entities/office.entity';
import { PersonEntityFromBackend } from '../../entities/person.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { CustomerActionTypes } from '../actions/customers.actions';
import { OfficeActionTypes } from '../actions/office.actions';
import { PersonActionTypes } from '../actions/person.actions';
import { ResolverLoadCustomerTypes } from '../actions/resolver.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { getCustomers } from '../selectors/customers.selectors';
import { getOffices } from '../selectors/offices.selectors';
import { getPersons } from '../selectors/person.selectors';

enum ErrorCodes {
    Add = 'Kunde Hinzufügen fehlgeschlagen',
    Load = 'Kunden Abrufen fehlgeschlagen',
    LoadImage = 'Kunden-Bild Abrufen fehlgeschlagen',
    SetUser = 'Nutzer Setzen fehlgeschlagen',
    Modify = 'Kunde Bearbeiten fehlgeschlagen',
    Remove = 'Kunde Löschen fehlgeschlagen',
}

@Injectable()
export class CustomerEffects {

    AddCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(CustomerActionTypes.AddCustomer),
        map(
            ({ Payload }) => `
            mutation {
                createCustomer(
                    customerStatus: ${CustomerStatusEnum.Aktiv}
                    name: ${JSON.stringify(Payload.name)}
                    ${stringifyIfNotNullOrUndefined(Payload, 'customerNo')}
                    partnerId: ${JSON.stringify(Payload.partnerId)}
                    ${stringifyIfNotNullOrUndefined(Payload, 'description')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'customerSpecificationTypeId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'hqLocation')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'gerichtsort')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'sales')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'employeeNumber')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'userRanking')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'customerTypeIds')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'homepage')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'mandateTypeIds')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'automaticCustomerNo')}
                      ${stringifyIfNotNullOrUndefined(Payload, 'automaticAccountsReceivableLedger')}
                      ${stringifyIfNotNullOrUndefined(Payload, 'ustId')}
                      ${stringifyIfNotNullOrUndefined(Payload, 'steuernummer')}
                      ${stringifyIfNotNullOrUndefined(Payload, 'partnerOfficeId')}
                      ${stringifyIfNotNullOrUndefined(Payload, 'debitorNo')}
                      ${stringifyIfNotUndefined(Payload, 'sachkontoCOAId')}
                      ${stringifyIfNotUndefined(Payload, 'kontoCOAId')}
                      ${stringifyIfNotUndefined(Payload, 'paymentTargetInDays')}
                      ${stringifyIfNotUndefined(Payload, 'paymentDiscount')}
                    ${Payload.taxType ? 'taxType: ' + Payload.taxType : ''}
                ) {
                    ${CustomerEntityGQLFields}
                }
            }`,
        ),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([query, token]) => this.gatewayHttpService.graphQl({ query }, { token })),
        map(res => res && res.createCustomer),
        withLatestFrom(this.store$.select(getCustomers)),
        concatMap(([res, customers]) =>
            res
                ? [
                      CustomerActionTypes.UpdateAll({
                          Payload: [...customers, CustomerEntityFromBackend(res)],
                      }),
                  ]
                : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } })],
        ),
        catchError(
            (err, caught) => (
                this.store$.dispatch(
                    BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Add,
                            Err: err,
                            Caught: caught,
                        },
                    }),
                ),
                caught
            ),
        ),
    ));


    AddCustomerAndOfficeAndPerson$ = createEffect(() => this.actions$.pipe(
        ofType(CustomerActionTypes.AddCustomerAndOffice),
        map(
            ({ Payload }) => `
            mutation {
                createCustomerWithOfficePerson(
                    customerStatus: ${CustomerStatusEnum.Aktiv}
                    name: ${JSON.stringify(Payload.name)}
                    partnerId: ${JSON.stringify(Payload.partnerId)}
                    ${stringifyIfNotNullOrUndefined(Payload, 'customerNo')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'description')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'customerSpecificationTypeId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'hqLocation')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'gerichtsort')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'sales')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'employeeNumber')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'userRanking')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'customerTypeIds')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'homepage')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'mandateTypeIds')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'automaticCustomerNo')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'automaticAccountsReceivableLedger')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'ustId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'steuernummer')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'partnerOfficeId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'debitorNo')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'sachkontoCOAId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'kontoCOAId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'paymentTargetInDays')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'paymentDiscount')}
                    ${Payload.taxType ? 'taxType: ' + Payload.taxType : ''}

                    office: ${
                        Payload.office
                            ? `{
                        isHeadquarter: ${JSON.stringify(Payload.office.isHeadquarter)}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'email')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'invoiceEmail')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'phoneNumber')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'street')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'postalCode')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'city')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'country')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'longitude')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'latitude')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'faxNumber')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'mobileNumber')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'iBAN')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'swift')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'bankName')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'bankLocation')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'bankPostalCode')}
                        ${stringifyIfNotNullOrUndefined(Payload.office, 'bankSortCode')}
                    }`
                            : 'null'
                    }
                    person: ${
                        Payload.person
                            ? `{
                        ${stringifyIfNotNullOrUndefined(Payload.person, 'firstname')}
                        ${stringifyIfNotNullOrUndefined(Payload.person, 'lastname')}
                        ${stringifyIfNotNullOrUndefined(Payload.person, 'email')}
                        ${stringifyIfNotNullOrUndefined(Payload.person, 'phoneNumber')}
                        ${stringifyIfNotNullOrUndefined(Payload.person, 'mobileNumber')}
                        ${stringifyIfNotNullOrUndefined(Payload.person, 'faxNumber')}
                        ${stringifyIfNotNullOrUndefined(Payload.person, 'description')}
                    }`
                            : 'null'
                    }
                ) {
                    customer{
                        ${CustomerEntityGQLFields}
                    }
                    office {
                    ${OfficeEntity.GQLFields.join(',')}
                    }
                    person {
                        city
                        companyName
                        country
                        createdAt
                        deleted
                        description
                        email
                        faxNumber
                        firstname
                        id
                        lastname
                        mobileNumber
                        partnerId
                        personTypeId
                        phoneNumber
                        postalCode
                        salutation
                        street
                        title
                        updatedAt
                    }
                }
            }`,
        ),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([query, token]) => this.gatewayHttpService.graphQl({ query }, { token })),
        map(res => res && res.createCustomerWithOfficePerson),
        withLatestFrom(
            combineLatest([this.store$.select(getOffices), this.store$.select(getPersons), this.store$.select(getCustomers)]).pipe(
                map(([offices, persons, customers]) => ({
                    customers,
                    offices,
                    persons,
                })),
            ),
        ),
        concatMap(([res, state]) => {
            return res
                ? [
                      CustomerActionTypes.UpdateAll({
                          Payload: [...state.customers, CustomerEntityFromBackend(res.customer)],
                      }),
                      OfficeActionTypes.UpdateAll({
                          Payload: [...state.offices, OfficeEntityFromBackend(res.office)],
                      }),
                      res.person
                          ? PersonActionTypes.UpdateAll({
                                Payload: [...state.persons, PersonEntityFromBackend(res.person)],
                            })
                          : null,
                  ].filter(action => action != null)
                : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } })];
        }),
        catchError((err, caught) =>
            of(
                BaseActionTypes.ErrorAction({
                    Payload: {
                        ToasterMessage: ErrorCodes.Add,
                        Err: err,
                        Caught: caught,
                    },
                }),
            ),
        ),
        tap(() => this.store$.dispatch(new ResolverLoadCustomerTypes())), // ToDo wofür ist das nötig?
    ));


    LoadCustomers$ = createEffect(() => this.actions$.pipe(
        ofType(CustomerActionTypes.Load),
        withLatestFrom(this.store$.select(getToken)),
        switchMap(([{ Payload }, token]) => {
            const queryString = `
            query {
                customer${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
                    ${CustomerEntityGQLFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                tap(res => {
                    if (res && res.customer) {
                        if (Payload?.updatedSince) {
                            if (res.customer.length) {
                                this.store$.dispatch(
                                    CustomerActionTypes.UpdateMany({
                                        Payload: res.customer.map(val => CustomerEntityFromBackend(val)),
                                        updateLatestUpdatedAt: true,
                                    }),
                                );
                            }
                        } else {
                            this.store$.dispatch(
                                CustomerActionTypes.UpdateAll({
                                    Payload: res.customer.map(val => CustomerEntityFromBackend(val)),
                                    updateLatestUpdatedAt: true,
                                }),
                            );
                        }
                    } else {
                        this.store$.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }));
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            );
        }),
    ), { dispatch: false });


    ModifyCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(CustomerActionTypes.ModifyCustomer),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
            mutation {
                changeCustomer(
                    id: ${JSON.stringify(Payload.id)}
                    gerichtsort: ${JSON.stringify(Payload.gerichtsort)}
                    ${stringifyIfNotNullOrUndefined(Payload, 'name')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'customerNo')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'description')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'customerSpecificationTypeId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'hqLocation')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'sales')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'employeeNumber')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'userRanking')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'customerTypeIds')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'homepage')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'mandateTypeIds')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'automaticCustomerNo')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'automaticAccountsReceivableLedger')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'ustId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'steuernummer')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'debitorNo')}
                      ${stringifyIfNotUndefined(Payload, 'sachkontoCOAId')}
                      ${stringifyIfNotUndefined(Payload, 'kontoCOAId')}
                      ${stringifyIfNotUndefined(Payload, 'paymentTargetInDays')}
                      ${stringifyIfNotUndefined(Payload, 'paymentDiscount')}
                    partnerOfficeId: ${JSON.stringify(Payload.partnerOfficeId)}
                    ${Payload.taxType ? 'taxType: ' + Payload.taxType : ''}
                    ${Payload.customerStatus ? `customerStatus: ${Payload.customerStatus}` : ''}
                      ) {
                        ${CustomerEntityGQLFields}
                }
            }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res =>
                    res && res.changeCustomer && res.changeCustomer.id
                        ? CustomerActionTypes.UpdateMany({
                              Payload: [CustomerEntityFromBackend(res.changeCustomer)],
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    DeleteCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(CustomerActionTypes.DeleteCustomer),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
            mutation {
                deleteCustomer(id: ${Payload})
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res =>
                    res && res.deleteCustomer ? CustomerActionTypes.RemoveOne({ Payload }) : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
