<header>
    <h1 mat-dialog-title>{{headline}}</h1>
    <span class="spacer"></span>
</header>
<mat-dialog-content>
    <app-detail-list-template [Data]="bvForm$| async" [Editing]="true" [Inline]="true">  </app-detail-list-template>
    <app-document-list-card
        [FileIds]="form.value.documentIds"
        (FileClick)="onFileClick($event)"
        (MergePdfClick)="onMergePdfClick($event)"
        [UploadMenu]="(canUploadFiles$ | async) ? fileUploadMenu : null" style="margin-bottom: 18px"
        [ledgerImportPath]="ledgerImportPath"
        [RemoveButton]="hasEditPermission$ | async"
        (RemoveClick)="removeFile($event)"
        [excludeRemoveButtonFromFiles]="[ledgerImportId]">
    </app-document-list-card>
</mat-dialog-content>
<mat-dialog-actions class="actions">
    <button app-button Inverted type="button" (click)="closeDialog()" Color="cancel">Abbrechen</button>
    <button app-round-button Color="red" *ngIf="showDeleteButton && BvId && (hasDeletePermission$ | async)" [IsLoading]="ls.IsLoading$ | async" (click)="deleteBV()"  [matTooltip]="'Löschen'">
        <fa-icon icon="trash"></fa-icon>
    </button>
    <button app-button *ngIf="hasEditPermission$ | async" [Disabled]="form.invalid || ((bvId$ | async) && form.pristine)" [IsLoading]="ls.IsLoading$ | async" (click)="save()" Color="green">Speichern</button>
</mat-dialog-actions>

<mat-menu #fileUploadMenu>
    <button mat-menu-item (click)="fileSelectDialog()">Datei hinzufügen</button>
    <button mat-menu-item (click)="fileUploadDialog()">Datei hochladen</button>
</mat-menu>

<ng-template #chartOfAccountTemplate >
    <div class="selectChartOfAccounts">
        <mat-form-field>
            <app-select-search
                [placeholder]="'GegenKonto/Sachkonto'"
                [formControl]="form.controls.gegenkonto"
                [Options]="chartOfAccounts"
                [OptionTemplate]="gegenkontoOptionTemplate"
                [CompareOptions]="CompareSelectSearchChartOfAccountsOptions"
                [SearchFunction]="SelectSearchChartOfAccountsOptionsFunction"
            >
            </app-select-search>
        </mat-form-field>
        <button app-round-button matTooltip="neues Konto anlegen" (click)="newChartOfAccountPopUp(null, AccountTypeEnum.Contra)"><fa-icon icon="plus"></fa-icon></button>
    </div>
</ng-template>
<ng-template #gegenkontoOptionTemplate let-option>
    <app-edit-gegenkonto [ChartOfAccount]="option" (editEvent)="newChartOfAccountPopUp($event)" ></app-edit-gegenkonto>
</ng-template>

<ng-template #sumTemplate >
    <div class="sumInput">
        <mat-form-field class="input-38">
            <input
                matInput
                [placeholder]="'Summe (netto)'"
                [formControl]="form.controls.amount"
                (input)="taxPercentageValueChanged()"
                type="number"
            />
            <span class="suffix" matSuffix>€</span>
        </mat-form-field>
        <mat-form-field class="input-38">
            <input
                matInput
                [placeholder]="'MwSt Summe'"
                [formControl]="form.controls.taxValue"
                type="number"
                (input)="taxValueChanged()"
            />
            <span class="suffix" matSuffix>€</span>
        </mat-form-field>
        <mat-form-field class="input-18">
            <input
                matInput
                [placeholder]="'MwSt in %'"
                [formControl]="form.controls.taxPercentageValue"
                (input)="taxPercentageValueChanged()"
                type="number"
            />
            <span class="suffix" matSuffix>%</span>
        </mat-form-field>
    </div>
</ng-template>
<ng-template #payedSumTemplate >
    <div class="sumInput">
        <mat-form-field class="input-38">
            <input
                matInput
                [placeholder]="'Bezahlte Summe (netto)'"
                [formControl]="form.controls.payedAmount"
                (input)="payedSumValueChanged()"
                type="number"
            />
            <span class="suffix" matSuffix>€</span>
        </mat-form-field>
        <mat-form-field class="input-38">
            <input
                matInput
                [placeholder]="'MwSt bez. Summe'"
                [formControl]="form.controls.payedTaxValue"
                type="number"
            />
            <span class="suffix" matSuffix>€</span>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #skontoTemplate>
    <div class="skonto-wrapper">
        <mat-form-field >
            <input
                matInput
                [placeholder]="'Skonto (Netto)'"
                [formControl]="this.form.controls.discountValue"
                type="number"
            />
            <span class="suffix" matSuffix>€</span>
        </mat-form-field>
        <button mat-icon-button [disabled]="!this.form.value.amount || !this.form.value.payedAmount" (click)="setSkontoForm()" type="button"><fa-icon icon="calculator"></fa-icon></button> <span>Skonto berechnen</span>
        <fa-icon
            icon="question-circle"
            matTooltip="Summe (Netto) - Bezahlte Summe (Netto) = Skonto (Netto)"
            style="margin: 1rem"
        >
        </fa-icon>
    </div>
</ng-template>
