    <h1 mat-dialog-title>
        <fa-icon mat-card-avatar icon="folder"></fa-icon>
        Neuer Ordner
    </h1>
    <mat-dialog-content>
        <app-textfield Key="Name" [Value]="NameForm"></app-textfield>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button app-button Inverted Color="cancel" mat-dialog-close="">Abbrechen</button>
        <button app-button [Disabled]="NameForm.invalid" Color="green" (click)="Save()">Speichern</button>
    </mat-dialog-actions>
