import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MaterialToCommissionEntity } from '../../entities/material-to-commission.entity';
import { createReducer, on } from '@ngrx/store';
import { MaterialToCommissionActionTypes } from '../actions/material-to-commission.actions';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';


export const MATERIAL_TO_COMMISSION_KEY = 'materialToCommission';

export interface MaterialToCommissionState extends EntityState<MaterialToCommissionEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;

}
const adapter = createEntityAdapter<MaterialToCommissionEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const MaterialToCommissionReducer = createReducer(
    initialState,
    on(MaterialToCommissionActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(MaterialToCommissionActionTypes.RemoveOne, (state, { Payload }) =>
        adapter.removeOne(Payload, state),
    ),
    on(MaterialToCommissionActionTypes.UpdateOne, (state, { Payload }) =>
        adapter.setOne(Payload, state)
    ),
    on(MaterialToCommissionActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllMaterialToCommission = selectAll;
export const selectMaterialToCommissionEntities = selectEntities;
export const selectMaterialToCommissionFetched = (state: MaterialToCommissionState)=> state.fetched;
export const selectMaterialToCommissionLatestUpdatedAt = (state: MaterialToCommissionState) => state.latestUpdatedAt;
