<h1 mat-dialog-title>{{ Data.headline || (EventType$ | async).Name }}</h1>
<div mat-dialog-content *ngIf="Event$ | async as event" class="wrapper">
    <div class="form-wrapper">
        <mat-form-field style="margin-right: 1rem; flex-grow: 1;" *ngIf="Diaries && !Data.hideDate">
            <mat-label >Datum</mat-label>
            <input  matInput [matDatepicker]="picker" [formControl]="DialogFormGroup.controls.date" (click)="picker.open()" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker [dateClass]="DateClass" #picker></mat-datepicker>
        </mat-form-field>
    </div>
     <app-textarea
     MaxHeight="200px"
     [Key]="'Name'"
     [Value]="DialogFormGroup.controls.eventName"
        >
        </app-textarea>
    <mat-form-field>
        <mat-select [formControl]="DialogFormGroup.controls.state" placeholder="Status">
            <mat-option *ngFor="let o of EventStateOptions" [value]="o.value">{{o.label}}</mat-option>
        </mat-select>
    </mat-form-field>
    <app-textarea [Key]="'Beschreibung'" MaxHeight="200px" [Value]="DialogFormGroup.controls.eventText"> </app-textarea>
    <h3><fa-icon icon="stopwatch"></fa-icon> Zeiten </h3>
    <table mat-table [dataSource]="UserToEvents$ | async">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Mitarbeiter</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            <td mat-footer-cell *matFooterCellDef >
                <app-select-search-legacy
                    class="hover-form"
                    [Values]="UsersForSelectSearch$ | async"
                    [Control]="NewUserToEventFormGroup.controls.user"
                    Placeholder="Mitarbeiter"
                    [Required]="true"
                    [FullWidth]="false"

                >
                </app-select-search-legacy>
            </td>
        </ng-container>
        <ng-container matColumnDef="timeForm">
            <th mat-header-cell *matHeaderCellDef>Zeit</th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field style="min-width: 70px; width: 4rem;">
                    <mat-label>Zeit</mat-label>
                    <input [formControl]="element.timeForm" matInput required type="number" />
                    <span matSuffix>Std.</span>
                    <mat-error *ngIf="element.timeForm.invalid">
                        {{ getErrorMessage(element.timeForm) }}
                    </mat-error>
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef >
                <mat-form-field style="min-width: 70px; width: 4rem;">
                    <mat-label>Zeit</mat-label>
                    <input [formControl]="NewUserToEventFormGroup.controls.time" matInput required />
                    <mat-error *ngIf="NewUserToEventFormGroup.controls.time.invalid">
                        {{ getErrorMessage(NewUserToEventFormGroup.controls.time) }}
                    </mat-error>
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="button">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <div class="fr">
                    <button app-round-button matTooltip="Speichern" [Disabled]="element.timeForm.invalid || !element.timeForm.dirty" (click)="SaveChangeEventToUser(element)">
                        <fa-icon icon="save"></fa-icon>
                    </button>
                    <button app-round-button matTooltip="Löschen" class="ml05" Inverted Color="red" (click)="DeleteEventToUser(element.u2e.Id)">
                        <fa-icon icon="trash"></fa-icon>
                    </button>
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <button class="fr" app-round-button matTooltip="Hinzufügen" (click)="SaveNewUserToEvent()" [Disabled]="NewUserToEventFormGroup.invalid">
                    <fa-icon icon="plus"></fa-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['name', 'timeForm', 'button']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['name', 'timeForm', 'button']"></tr>
        <tr mat-footer-row *matFooterRowDef="['name', 'timeForm', 'button']"></tr>
    </table>
    <div class="headline">
        <h3><fa-icon icon="paperclip"></fa-icon> Anhänge</h3>
        <span class="spacer"></span>
        <mat-slide-toggle [(ngModel)]="DMSNotMinimalistic" [disabled]="!(Folder$ | async)">DMS</mat-slide-toggle>
        <button style="margin-left: auto;" app-round-button (click)="OpenFileUpload(event.CommissionId)" matTooltip="Dateien hinzufügen">
            <app-double-icon [Icon]="DMSMeta.Icon" Icon2="upload"></app-double-icon>
        </button>
    </div>
    <!--
    *ngIf="Data.hideDMS ||  !(Folder$ | async)"
    -->
    <dropzone
        *ngIf="!DMSNotMinimalistic"
        [config]="DropzoneConfig$ | async"
        [message]="'Foto hinzufügen'"
        (error)="OnUploadError($event)"
        (success)="onDropzoneSuccess($event)"
        (uploadProgress)="onDropzoneUploadProgress($event)"
        (queueComplete)="QueComplete()"
        (addedFile)="OnSelect($event)"
        (removedFile)="OnRemove($event)"
    >
    </dropzone>
    <app-file-explorer
        [FileUploadConfig]="{EventId: event.Id, CommissionId: event.CommissionId}"
        [FileUploadButton]="true"
        *ngIf="Folder$ | async as folder"
        [SelectedFolderId]="folder.Id"
        [DisableRoutingParams]="true"
        [DisableBackButtonFolderId]="folder.Id"
        [Minimalistic]="!DMSNotMinimalistic"
    ></app-file-explorer>
<!--    <app-pdf-editor #appPdfEditor [Version]="MockPlan | async" (FileLoaded)="planLoaded(appPdfEditor)"></app-pdf-editor>-->
    <mat-form-field>
        <app-select-search
            [placeholder]="'Plan'"
            [formControl]="planForm"
            [Options]="plans$ | async"
            [CompareOptions]="CompareByDocumentId"
        ></app-select-search>
        <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer;" (click)="planForm.reset();$event.stopPropagation();" *ngIf="!planForm.disabled"></fa-icon>

<!--        <button mat-icon-button matSuffix><fa-icon icon="times" (click)="planForm.reset()"></fa-icon></button>-->
    </mat-form-field>
    <div *ngIf="planForm.value as selectedPlan">
        <ng-container *ngFor="let plan of plans$ | async">
            <app-leaflet-wrapper *ngIf="CompareByDocumentId(plan, selectedPlan)" (MapInitialized)="setEvents2(leafletMap, plan.documentId)" (MarkerAdded)="refreshMarker(leafletMap, plan.documentId)" (MarkerMoved)="refreshMarker(leafletMap, plan.documentId)" [InitialDocument]="plan" #leafletMap></app-leaflet-wrapper>
        </ng-container>
    </div>
<!--    <div *ngIf="plans$ | async as plans">-->
<!--        <ng-container *ngIf="plans.length">-->
<!--            <mat-tab-group style="flex-grow: 1;">-->
<!--                <mat-tab #tab [label]="plan.documentName" *ngFor="let plan of plans;" >-->
<!--                    <ng-container *ngIf="tab.isActive">-->
<!--                        <button app-round-button (click)="toggleMapMarkerMode(leafletMap)" [Active]="leafletMap.AddMarkerMode"><fa-icon icon="map-marker-alt"></fa-icon></button>-->
<!--                        <app-leaflet-wrapper (MapInitialized)="setEvents2(leafletMap, plan.documentId)" (MarkerAdded)="refreshMarker(leafletMap, plan.documentId)" (MarkerMoved)="refreshMarker(leafletMap, plan.documentId)" [InitialDocument]="plan" #leafletMap></app-leaflet-wrapper>-->
<!--                    </ng-container>-->
<!--                </mat-tab>-->
<!--            </mat-tab-group>-->
<!--        </ng-container>-->
<!--    </div>-->
</div>
<mat-dialog-actions class="button-wrapper">
    <button Inverted app-button Color="cancel" [matDialogClose]="'abort'">Abbrechen</button>
    <button app-round-button *ngIf="this.Data.canDelete" matTooltip="Löschen" class="header-button" Color="red" (click)="Delete()" >
        <fa-icon icon="trash"></fa-icon>
    </button>
    <button app-button Color="green" (click)="Save()" [Disabled]="!(Event$ | async) || DialogFormGroup.invalid" [IsLoading]="LS.IsLoading$ | async" style="margin-left: 1rem">
        Speichern
    </button>
</mat-dialog-actions>
