<app-detail-view-template [ShowButtons]="ShownButtons" *ngIf="Employee$ | async as employee; else loadingTemplate">
    <ng-container *appDetailViewButtons>
        <app-detail-list-template
            *ngIf="ShowStatus && !Editing"
            class="dark-theme"
            [Inline]="true"
            [Editing]="true"
            [Data]="StatusData"
            style="margin: 0.5rem; min-width: 6rem"
        ></app-detail-list-template>
        <button *ngIf="!Editing" app-round-button class="header-button" Inverted (click)="ShowStatus = !ShowStatus"
                    aria-label="Status ändern" matTooltip="Status ändern">
                <fa-icon [icon]="ShowStatus ? 'chevron-right' : 'info'"></fa-icon>
        </button>
        <button
            app-round-button
            Inverted
            class="header-button"
            matTooltip="Stundenliste"
            aria-label="Stundenliste"
            (click)="OpenTimeTracker(employee)"
            [Disabled]="Editing"
            *ngIf="((Employee$ | async).CanEdit || (IsMe$ | async)) && !Editing"
        >
            <fa-icon icon="stopwatch"></fa-icon>
        </button>
        <button
            *ngIf="!Editing && !employee.Deleted"
            app-round-button
            Inverted
            class="header-button"
            matTooltip="Bearbeiten"
            aria-label="Bearbeiten"
            (click)="OpenEdit(employee)"
            [Disabled]="!(employee.CanEdit && (PS.Has(PS.Permission.EditUser) | async))"
        >
            <fa-icon icon="pencil-alt"></fa-icon>
        </button>
        <button
            *ngIf="Editing"
            app-round-button
            Color="green"
            class="header-button"
            matTooltip="Speichern"
            aria-label="Speichern"
            [Disabled]="EmployeeForm.invalid"
            (click)="Save(employee)"
        >
            <fa-icon icon="save"></fa-icon>
        </button>
        <button *ngIf="Editing" app-round-button Inverted class="header-button" matTooltip="Zurücksetzen" aria-label="Zurücksetzen" (click)="CloseEdit()">
            <fa-icon icon="undo"></fa-icon>
        </button>

        <button
            *ngIf="!employee.Deleted && !(IsMe$ | async) && Editing"
            app-round-button
            [class.delete-active]="Editing"
            class="header-button"
            Color="red"
            matTooltip="Löschen"
            aria-label="Löschen"
            (click)="DeleteEmployee(employee)"
            [Disabled]="!(employee.CanDelete && (PS.Has(PS.Permission.DeleteUser) | async))"
        >
            <fa-icon icon="trash"></fa-icon>
        </button>
    </ng-container>
    <ng-container *appDetailViewLeftContent>
        <app-profile-template *ngIf="ProfileData$ | async" [Data]="ProfileData$ | async" [Editing]="Editing" (Submit)="Save(employee)">
            <div class="dms-button-wrapper link-wrapper" *ngIf="(PS.Has(PS.Permission.GetFiles) | async) && (Employee$ | async).CanEdit">
                <a app-button Lighter class="wide-button" matTooltip="DMS" [routerLink]="['/', DMSPath]" [queryParams]="{ folderId: FolderId$ | async }">
                    <fa-icon [icon]="DMSIcon"></fa-icon>
                </a>
            </div>
        </app-profile-template>
        <ng-container *ngIf="((PS.Has(PS.Permission.GetFiles) | async) && (PS.Has(PS.Permission.EditEmployee) | async) && (Employee$ | async).CanEdit) || (IsMe$ | async)">
            <ng-container *ngIf="FolderLoaded$ | async; else loading">
                <app-file-explorer
                    PreventFileClick
                    PreventFileUploadClick
                    (FilterOpen$)="ShownButtons = !$event"
                    [SelectedFolderId]="FolderId$ | async"
                    [FileUploadButton]="
                        (Employee$ | async) && !(Employee$ | async).Deleted && (((Employee$ | async).CanEdit && (PS.Has(PS.Permission.EditEmployee) | async)) || (IsMe$ | async))
                    "
                    (FileUploadClicked)="UploadFolderId = $event; PreviewOpen = false; OpenUploadDialog()"
                    (FileClicked)="PreviewedFileId$.next($event); PreviewOpen = true"
                    (fileExplorerContext) = "ImageIds = $event"
                    class="mt-025"
                    style="height: 40rem; padding: 0; margin-top: 1rem"
                >
                </app-file-explorer>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *appDetailViewRightContent>
        <mat-card *ngIf="IsMe$ | async" class="mt-025">
            <mat-card-title class="card-header-with-help-icon">
                Unterschrift
                <fa-icon icon="question-circle" class="help-icon" matTooltip="Diese Unterschrift kann mit Ihrem Account in einem Bericht gesetzt werden."></fa-icon>
            </mat-card-title>

            <ng-container style="position: relative" *ngIf="(User$ | async).DocumentId">
                <div style="position: relative" *ngIf="SigneUrls$ | async as urls; else loading">
                    <button app-round-button style="position: absolute; top: 0.5rem; right: 0.5rem" (click)="logoUpload.click()" matTooltip="Bild hochladen">
                        <fa-icon icon="upload"></fa-icon>
                    </button>
                    <img #image style="width: 100%" [src]="urls.download" alt="Unterschrift" />
                    <input type="file" accept="image/*" hidden #logoUpload (change)="ChangeUserSign($event)" />
                </div>
            </ng-container>
            <div style="position: relative" *ngIf="!(User$ | async).DocumentId">
                <button app-round-button style="position: absolute; top: 0.5rem; right: 0.5rem" (click)="logoUpload.click()" matTooltip="Bild hochladen">
                    <fa-icon icon="upload"></fa-icon>
                </button>
                <div
                    *ngIf="!(SigneUrls$ | async)"
                    style="
                        width: 100%;
                        height: 150px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        border-radius: 4px;
                        background-color: rgba(0, 0, 0, 0.15);
                    "
                >
                    <fa-icon icon="signature" size="3x"></fa-icon>
                    <h2 style="margin: 0">Unterschrift</h2>
                </div>
                <input type="file" accept="image/*" hidden #logoUpload (change)="ChangeUserSign($event)" />
            </div>
        </mat-card>
        <app-dave-list-card
            *ngIf="(IsMe$ | async) || employee.CanEdit"
            [Headline]="'Urlaub (' + (employee.RemainingVacationDaysFromCurrentYear + employee.RemainingVacationDaysFromPastYears) + ' Tage verfügbar)'"
            [HeaderIcon]="VacationMetaIcon"
            EmptyText="Keine Urlaubsanträge vorhanden"
            [Data]="Vacations$ | async"
            [DisplayedCollums]="['From', 'To', 'Days', 'State']"
            [TableHeaderCollumsMap]="{ From: 'von', To: 'bis', Days: 'Tage', State: 'Status' }"
            style="max-height: 50vh"
            class="mt-025"
            (EntryClicked$)="OpenVacationPopup($event.entry)"
            [Clickable]="true"
            [HideCount]="true"
        >
            <button
                *ngIf="employee.PermissionsFromEntityRoles | includes: Permissions.CreateVacation"
                app-round-button
                headerButton
                matTooltip="Urlaub Beantragen"
                (click)="OpenVacationPopup(null, employee.Id, true)"
            >
                <fa-icon icon="plus"></fa-icon>
            </button>
        </app-dave-list-card>
        <app-dave-list-card
            *ngIf="(IsMe$ | async) || employee.CanEdit"
            Headline="Abwesenheit"
            [HeaderIcon]="AbsentMetaIcon"
            EmptyText="Keine Abwesenheiten vorhanden"
            [Data]="Absents$ | async"
            [DisplayedCollums]="['From', 'To', 'Days', 'Type']"
            [TableHeaderCollumsMap]="{ From: 'von', To: 'bis', Days: 'Tage', Type: 'Art' }"
            style="max-height: 50vh"
            class="mt-025"
            (EntryClicked$)="OpenVacationPopup($event.entry)"
            [Clickable]="true"
            [HideCount]="true"
        >
            <button
                *ngIf="employee.PermissionsFromEntityRoles | includes: Permissions.CreateAbsent"
                app-round-button
                headerButton
                matTooltip="Abwesenheit einreichen"
                (click)="OpenVacationPopup(null, employee.Id, false)"
            >
                <fa-icon icon="plus"></fa-icon>
            </button>
        </app-dave-list-card>
        <mat-card class="card mt-025" *ngIf="(PS.Has(PS.Permission.BookProductManager) | async) && (Employee$ | async).CanEdit">
            <div class="card-header">
                <fa-icon class="icon" icon="key"></fa-icon>
                <h3>Lizenzen</h3>
            </div>

            <ngx-simplebar class="clickable-list-container">
                <table *ngIf="!Editing && DisplayUser$ | async as displayUser" mat-table class="table" [dataSource]="displayUser.products">
                    <ng-container matColumnDef="image">
                        <td mat-cell *matCellDef="let rowData">
                            <div class="icon-width">
                                <fa-icon class="license-icon" icon="key" size="lg"></fa-icon>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <td mat-cell *matCellDef="let rowData">
                            {{ rowData.Name }}
                        </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let rowData; columns: ['image', 'name']"></tr>
                </table>

                <table *ngIf="!Editing && (!(DisplayUser$ | async)?.ProductIds || (DisplayUser$ | async)?.ProductIds.length < 1)" style="width: 100%">
                    <tr>
                        <td class="empty-table-text">Keine Lizenz zugeteilt</td>
                    </tr>
                </table>

                <ul class="clickable-list" *ngIf="Editing">
                    <ng-container *ngFor="let product of Products">
                        <li class="clickable-list-item" [matTooltip]="!employee.Email ? 'Der Mitarbeiter benötigt eine E-Mail' : ''">
                            <button
                                mat-button
                                class="clickable-list-item-link"
                                [disabled]="(product.Available < 1 && !HasProduct(product.Id)) || !employee.Email || (product.Id === ProductEntityIds.BaseLicense && (this.LicensesForm?.value?.length !== 1 && HasProduct(product.Id))) || (product.Id !== ProductEntityIds.BaseLicense && !HasProduct(product.Id) && !HasProduct(ProductEntityIds.BaseLicense))"
                                (click)="SetLicense(product, employee.Email, employee.Id)"
                            >
                                <div class="clickable-list-item-content">
                                    <div class="contactperson-img mat-card-avatar" [matTooltip]="HasProduct(product.Id) ? 'Lizenz hinzufügen' : 'Lizenz entfernen'">
                                        <fa-icon [icon]="HasProduct(product.Id) ? 'check' : 'times'" size="lg"></fa-icon>
                                    </div>
                                    <p class="name">
                                        {{ product.Name }}
                                        {{ product.Available ? '(' + product.Available + ' verfügbar)' : '' }}
                                    </p>
                                </div>
                            </button>
                        </li>
                        <mat-divider></mat-divider>
                    </ng-container>
                </ul>
            </ngx-simplebar>
        </mat-card>

        <mat-card class="card mt-025" *ngIf="(PS.Has(PS.Permission.EditRoleEmployeeManager) | async) && (Employee$ | async).CanRoleEdit">
            <div class="card-header">
                <fa-icon class="icon" icon="user-shield"></fa-icon>
                <h3>Rollen</h3>
            </div>

            <ngx-simplebar class="clickable-list-container">
                <table mat-table [dataSource]="RolesFormatedFromEmployee$" *ngIf="(Employee$ | async).UserRolesList?.length !== 0 && !Editing">
                    <ng-container matColumnDef="image">
                        <td mat-cell *matCellDef="let rowData" style="width: 2.5rem">
                            <div class="icon-width">
                                <fa-icon class="license-icon" icon="unlock" size="lg"></fa-icon>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <td mat-cell *matCellDef="let rowData" style="width: 100%">
                            {{ rowData.name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="info">
                        <td mat-cell *matCellDef="let rowData" style="width: 20px; padding-left: 0">
                            <fa-icon icon="info-circle" size="lg" [matTooltip]="rowData.info" [matTooltipPosition]="'left'"></fa-icon>
                        </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let rowData; columns: ['image', 'name', 'info']"></tr>
                </table>

                <table *ngIf="(Employee$ | async).UserRolesList?.length === 0 && !Editing">
                    <tr>
                        <td>Keine Rollen zugeteilt</td>
                    </tr>
                </table>

                <table mat-table [dataSource]="RolesFormated$ | async" *ngIf="Editing">
                    <ng-container matColumnDef="icon">
                        <td mat-cell *matCellDef="let rowData" style="width: 2.5rem">
                            <fa-icon icon="times" *ngIf="!rowData.selected" size="lg"></fa-icon>
                            <fa-icon icon="check" *ngIf="rowData.selected" size="lg"></fa-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="info">
                        <td mat-cell *matCellDef="let rowData" style="width: 20px; padding-left: 0">
                            <fa-icon icon="info-circle" size="lg" [matTooltip]="rowData.info" [matTooltipPosition]="'left'"></fa-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <td mat-cell *matCellDef="let rowData">
                            {{ rowData.name }}
                        </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let rowData; columns: ['icon', 'name', 'info']" (click)="PermissionToggle(rowData.key)"></tr>
                </table>
            </ngx-simplebar>
        </mat-card>

        <mat-card class="card mt-025 right" *ngIf="!Editing && (PS.Has(PS.Permission.EditRoleEmployeeManager) | async)">
            <div class="card-header" #cardHeader>
                <fa-icon class="icon" icon="users"></fa-icon>

                <h3>Bearbeiter</h3>

                <button app-round-button matTooltip="Auswählen" matTooltipPosition="left" (click)="OpenUserSelect()" [Disabled]="(Employee$ | async).Deleted">
                    <fa-icon icon="user-cog"></fa-icon>
                </button>
            </div>

            <!-- CSS-Klasse ist nur für den inset box shadow -->
            <ngx-simplebar class="clickable-list-container">
                <table mat-table [dataSource]="Users$">
                    <ng-container matColumnDef="image">
                        <td mat-cell *matCellDef="let rowData">
                            <div class="icon-width">
                                <ng-template #noImage>
                                    <fa-icon mat-list-avatar icon="user" size="lg"></fa-icon>
                                </ng-template>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <td mat-cell *matCellDef="let rowData">
                            {{ rowData.User.DisplayName + (rowData.User.JobSpecification || rowData.User.Phonenumber || rowData.User.Email ? ', ' : '') }}

                            <span style="font-weight: lighter">
                                {{ rowData.User.JobSpecification ? rowData.User.JobSpecification : '' }}
                                <br />
                                {{ (rowData.User.Email ? rowData.User.Email : '') + (rowData.User.Phonenumber ? ', Tel: ' + rowData.User.Phonenumber : '') }}
                            </span>
                            <ng-container *ngIf="rowData.User.Deleted">
                                <i
                                    >gelöscht
                                    <fa-icon icon="user-slash"></fa-icon>
                                </i>
                            </ng-container>
                        </td>
                    </ng-container>

                    <tr
                        mat-row
                        *matRowDef="let rowData; columns: ['image', 'name']"
                        [class.is-highlighted]="rowData.ItsMe"
                        [routerLink]="['/personal_und_urlaub', rowData.User.Id]"
                        routerLinkActive="inactive"
                    ></tr>
                </table>

                <table *ngIf="!((Users$ | async)?.length > 0)" style="width: 100%">
                    <tr>
                        <td class="empty-table-text">Keine Mitarbeiter vorhanden</td>
                    </tr>
                </table>
            </ngx-simplebar>
        </mat-card>
    </ng-container>
</app-detail-view-template>

<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<mat-card *ngIf="PreviewOpen" class="new-document-overlay">
    <button app-round-button class="back-button" (click)="PreviewOpen = false">
        <fa-icon icon="chevron-right"></fa-icon>
    </button>
    <app-preview *ngIf="PreviewedFileId$ | async as id" [FileId$]="PreviewedFileId$" [fileExplorerContext]="ImageIds" (ClosePreview)="PreviewOpen = false" class="preview"> </app-preview>
</mat-card>

<ng-template #jobSpecificationTemplate>
    <div class="jsform">
        <mat-form-field>
            <app-select-search
                [placeholder]="'Jobbezeichnung'"
                [formControl]="EmployeeForm.controls.JobSpecificationId"
                [Options]="JobSpecifications"
                [OptionTemplate]="jobSpecificationOptionTemplate"
                [CompareOptions]="CompareById"
            ></app-select-search>
        </mat-form-field>
        <button app-round-button (click)="NewJobSpecificationClick()" matTooltip="Neue Jobbezeichnung anlegen" type="button">
            <fa-icon icon="plus" ></fa-icon>
        </button>
    </div>
</ng-template>
<ng-template #jobSpecificationOptionTemplate let-option>
    <app-job-specification-option [JobSpecificationEntity]="option"></app-job-specification-option>
</ng-template>
