import { PersonTypeEntity } from '../../entities/personType.entity';
import { createReducer, on } from '@ngrx/store';
import { PersonTypeActionTypes } from '../actions/personType.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';


export const personTypesFeatureKey = 'personTypes';

export interface PersonTypeState extends EntityState<PersonTypeEntity>{
    fetched: boolean;
}
const adapter = createEntityAdapter<PersonTypeEntity>({
    selectId: Entity => Entity.Id,
});
export const initialState: PersonTypeState = adapter.getInitialState({
    fetched: false,
});

export const PersonTypeReducer = createReducer(
    initialState,
    on(PersonTypeActionTypes.UpdatePersonType, (state, { Payload }) =>
        adapter.setAll(Payload, {
            ...state,
            fetched: true,
        }),
    ),
    on(PersonTypeActionTypes.RemoveOne, (state, { Payload }) =>
        adapter.removeOne(Payload, { ...state }),
    ),
    on(PersonTypeActionTypes.CreateSuccess, (state, { Payload }) =>
        adapter.setOne(Payload, {
            ...state,
        }),
    ),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllPersonType = selectAll;
export const selectPersonTypeEntities = selectEntities;

export const selectPersonTypeFetched = (state: PersonTypeState) => state.fetched;
