import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, firstValueFrom, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LedgerImportEntity, LedgerImportEntityFromBackend } from '../entities/ledger-import.entity';
import { State } from '../State';
import { BaseActionTypes } from '../State/actions/base.actions';
import { LedgerImportEffectErrorCodes } from '../State/effects/ledger-import.effects';
import { HttpService } from './http.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HtmlToPdfRequest } from '@dave/types/dist/proto/htmlToPdf/htmlToPdf';
import { isNotNullOrUndefined } from '../../helper/helper';
import { ProcessActions } from '../State/actions/process.actions';
import { ProcessEntityFromBackend } from '../entities/process.entity';
import { getProcessDictionary } from '../State/selectors/process.selector';
import { GatewayRequest, GatewayResponse, GetTimestampFromTime } from '@dave/types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppGatewayService } from './app-gateway.service';
import { API_METRICS, API_TRACING, ProtoApiService } from './proto-api.service';
import { getToken } from '../State/selectors/base.selectors';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LedgerImportDataService {
    constructor(private gatewayHttpService: HttpService, private store: Store<State>, private httpService: HttpService, private gatewayService: AppGatewayService, private http: HttpClient, private protoApiService: ProtoApiService) {}
    public getLedgerImportByEventId$(eventId: number): Promise<LedgerImportEntity[]> {
        if (!eventId) {
            throw 'eventId required';
        }
        const queryString = `{
  ledgerImport(eventId: ${eventId}, ) {
    ${LedgerImportEntity.GqlFields}}
    }`;
        return firstValueFrom(
            this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                map((res) => {
                    if (res && res.ledgerImport) {
                        return res.ledgerImport.map(LedgerImportEntityFromBackend);
                    } else {
                        this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: LedgerImportEffectErrorCodes.Load } }));
                        return null;
                    }
                }),
            ),
        );
    }
    getPdfFromHtml(Payload: HtmlToPdfRequest): Promise<Blob | unknown> {
        const body = HtmlToPdfRequest.create({
                ...Payload,
            });
        return firstValueFrom(this.store.select(getToken)).then((token) => {
            return new Promise((resolve, reject) => {
                const req = new XMLHttpRequest();
                req.onload = (e) => {
                    if (req.status === 200) {
                        console.log(req)
                        console.log(req.response)
                        resolve(req.response);
                    } else {
                        reject(req.response);
                    }
                };
                req.onerror = (err) => {
                    reject(err);
                };
                req.open('POST', environment.gatewayApiUrl + '/html-to-pdf/htmlToPdf');
                req.setRequestHeader('Authorization', 'Bearer ' + token);
                if(window['env']["useGzip"]) {
                    req.setRequestHeader('Accept-Encoding', 'gzip');
                }
                req.responseType = 'blob';
                if (window["env"].useProtobuf) {
                    // req.responseType = 'arraybuffer';
                    req.setRequestHeader('Content-Type', 'application/protobuf');
                    req.send(HtmlToPdfRequest.encode(body).finish());
                } else {
                    // req.responseType = 'text';
                    req.setRequestHeader('Content-Type', 'application/json');
                    req.send(JSON.stringify(body));
                }
            });
        }).catch((e) => {
            this.store.dispatch(BaseActionTypes.ErrorAction({Payload: {
                    ToasterMessage: 'Pdf generieren fehlgeschlagen',
                    Err: e,
                }}))
            return null
        });
    }
}
