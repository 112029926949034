import {EmailType} from '../graphql-types';
import {BackendDate} from '../helper/backend-frontend-conversion.helper';

export class EmailEntity implements EmailEntityAttributes {
    constructor(
        public BlindCarbonCopy: string[] = null,
        public Body: string = null,
        public CarbonCopy: string[] = null,
        public EmailConnectionId: number = null,
        public CommissionId: number = null,
        public CustomerId: number = null,
        public Id: number = null,
        public From: string = null,
        public To: string[] = [],
        public FolderId: number = null,
        public Sent: boolean = null,
        public Subject: string = null,
        public UserId: number = null,
        public DocumentIds: number[] = null,
        public GeneratedDocumentIds: number[] = null,
        public IsFromDave: boolean = false,
        public Seen: boolean = false,
        public Size: number = null,
        public PersonId: number = null,
        public PreviewText: string = null,
        public SendDate: Date = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
        public HasAttachment: boolean = null,
        public IsSending: boolean = null,
        public ErrorMessage: string = null,
        public ReferredEventId: number = null,
    ) {
    }
    public static readonly GQLFields = `blindCarbonCopy
    carbonCopy
    emailConnectionId
    commissionId
    customerId
    id
    from
    to
    folderId
    sent
    subject
    userId
    documentIds
    generatedDocumentIds
    isFromDave
    seen
    size
    personId
    previewText
    sendDate
    createdAt
    updatedAt
    deletedAt
    hasAttachment
    isSending
    errorMessage
    referredEventId`;
    public Clone(override: EmailEntityAttributes = {}): EmailEntity {
        const attr = {...this, ...override};
        return Object.assign(new EmailEntity(), attr);
    }
}

export function EmailEntityFromBackend(res: EmailType): EmailEntity {
    return new EmailEntity(
        res.blindCarbonCopy,
        res.body,
        res.carbonCopy,
        res.emailConnectionId,
        res.commissionId,
        res.customerId,
        res.id,
        res.from,
        res.to,
        res.folderId,
        res.sent,
        res.subject,
        res.userId,
        res.documentIds,
        res.generatedDocumentIds,
        res.isFromDave,
        res.seen,
        res.size,
        res.personId,
        res.previewText,
        res.sendDate && BackendDate(res.sendDate),
        res.createdAt && BackendDate(res.createdAt),
        res.updatedAt && BackendDate(res.updatedAt),
        res.deletedAt && BackendDate(res.deletedAt),
        res.hasAttachment,
        res.isSending,
        res.errorMessage,
        res.referredEventId,
    );
}


export interface EmailEntityAttributes {
    BlindCarbonCopy?: string[];
    Body?: string;
    CarbonCopy?: string[];
    EmailConnectionId?: number;
    CommissionId?: number;
    CustomerId?: number;
    Id?: number;
    From?: string;
    To?: string[];
    FolderId?: number;
    Sent?: boolean;
    Subject?: string;
    PreviewText?: string;
    UserId?: number;
    DocumentIds?: number[];
    GeneratedDocumentIds?: number[];
    IsFromDave?: boolean;
    Seen?: boolean;
    Size?: number;
    PersonId?: number;
    SendDate?: Date;
    CreatedAt?: Date;
    UpdatedAt?: Date;
    DeletedAt?: Date;
    HasAttachment?: boolean;
}
