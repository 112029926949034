import { createAction, props } from '@ngrx/store';
import { UserToCommissionEntity } from '../../entities/user-to-commission.entity';
import {
    DaveMutationChangeUser2CommissionArgs,
    DaveMutationCreateUser2CommissionArgs,
    DaveMutationDeleteUser2CommissionArgs,
    QueryUser2CommissionArgs,
} from '../../graphql-types';

export const UserToCommissionActionTypes = {
    Load: createAction('data/userToCommission/loadAll', props<{ Payload?: QueryUser2CommissionArgs }>()),
    UpdateAll: createAction('data/userToCommission/updateAll', props<{ Payload: Array<UserToCommissionEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/userToCommission/updateMany', props<{ Payload: Array<UserToCommissionEntity>, updateLatestUpdatedAt?: boolean }>()),
    SetByCommission: createAction('data/userToCommission/updateMany', props<{ Payload: Array<UserToCommissionEntity>, CommissionId: number }>()),
    Change: createAction('data/userToCommission/modify', props<{ Payload: DaveMutationChangeUser2CommissionArgs }>()),
    Create: createAction('data/userToCommission/add', props<{ Payload: DaveMutationCreateUser2CommissionArgs }>()),
    Delete: createAction('data/userToCommission/delete', props<{ Payload: DaveMutationDeleteUser2CommissionArgs }>()),
    UpdateOne: createAction('data/userToCommission/updateOne', props<{ Payload: UserToCommissionEntity }>()),
    RemoveOne: createAction('data/userToCommission/removeOne', props<{ Payload: number }>()),
    RemoveByCommission: createAction('data/userToCommission/removeByCommission', props<{ Payload: number }>()),
};
