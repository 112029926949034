import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, of } from 'rxjs';
import { GeneratedDocumentsTypeEntity } from '../dave-data-module/entities/generatedDocumentsType.entity';
import { CommissionResolver } from '../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../dave-data-module/guards/customer.resolver';
import { DamageFlowResolver } from '../dave-data-module/guards/damageflow.resolver';
import { DamageFlowTypeResolver } from '../dave-data-module/guards/damageFlowType.resolver';
import { EmployeeResolver } from '../dave-data-module/guards/employee.resolver';
import { EventTypeResolver } from '../dave-data-module/guards/event-type.resolver';
import { EventResolver } from '../dave-data-module/guards/event.resolver';
import { JobSpecificationResolver } from '../dave-data-module/guards/job-specification.resolver';
import { OfficeResolver } from '../dave-data-module/guards/office.resolver';
import { PersonResolver } from '../dave-data-module/guards/person.resolver';
import { Person2EntityResolver } from '../dave-data-module/guards/person2Entity.resolver';
import { PersonTypeResolver } from '../dave-data-module/guards/personType.resolver';
import { State } from '../dave-data-module/State';
import { GeneratedDocumentsPageMeta } from '../helper/page-metadata';

import { GeneratedDocumentsTypeActionTypes } from '../dave-data-module/State/actions/generatedDocumentsType.actions';

import { CommissionEntity } from '../dave-data-module/entities/commission.entity';
import { CustomerEntity } from '../dave-data-module/entities/customer.entity';
import { DamageFlowEntity } from '../dave-data-module/entities/damageflow.entity';
import { DamageFlowTypeEntity } from '../dave-data-module/entities/damageFlowType.entity';
import { EventTypeEntity } from '../dave-data-module/entities/event-type.entity';
import { EventEntity } from '../dave-data-module/entities/event.entity';
import { GeneratedDocumentsEntityAdditionalData } from '../dave-data-module/entities/generatedDocuments.entity';
import { OfficeEntity } from '../dave-data-module/entities/office.entity';
import { PersonEntity } from '../dave-data-module/entities/person.entity';
import { Person2EntityEntity, Person2EntityEntityTypeEnum } from '../dave-data-module/entities/person2entity.entity';
import { PersonTypeEntity, PersonTypeNames } from '../dave-data-module/entities/personType.entity';
import { DaveMutationCreateGeneratedDocumentsArgs } from '../dave-data-module/graphql-types';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';
import { GeneratedDocumentsActionTypes } from '../dave-data-module/State/actions/generatedDocuments.actions';
import { getCommissions, getCommissionsFetched } from '../dave-data-module/State/selectors/commission.selector';
import { getCustomers, getCustomersFetched } from '../dave-data-module/State/selectors/customers.selectors';
import { getDamageFlows, getDamageFlowsFetched } from '../dave-data-module/State/selectors/damageflow.selector';
import { getDamageFlowTypes, getDamageFlowTypesFetched } from '../dave-data-module/State/selectors/damageFlowType.selectors';
import { getEmployees, getEmployeesFetched } from '../dave-data-module/State/selectors/employees.selectors';
import { getEventTypeFetched, getEventTypes } from '../dave-data-module/State/selectors/event-type.selector';
import { getEventFetched, getEvents } from '../dave-data-module/State/selectors/events.selectors';
import { getJobSpecificationDictionary, getJobSpecificationFetched } from '../dave-data-module/State/selectors/job-specification.selector';
import { getOffices, getOfficesFetched } from '../dave-data-module/State/selectors/offices.selectors';
import { getPartner } from '../dave-data-module/State/selectors/partners.selectors';
import { getPersons, getPersonsFetched } from '../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities, getPerson2EntitiesFetched } from '../dave-data-module/State/selectors/person2entity.selectors';
import { getPersonTypes, getPersonTypesFetched } from '../dave-data-module/State/selectors/personType.selectors';
import { getUser } from '../dave-data-module/State/selectors/users.selectors';
import { LoadingService } from '../services/loading.service';
import { SfdtTemplateService } from '../services/sfdt-template.service';
import { DocumentState, getGQLDocumentStateFromDocumentState } from '../dave-data-module/entities/file.entity';
import { Base64Logo, transparentPixelLogo } from '../helper/sfdt.helper';
import {
    getResourceById,
    getResourcesFetched,
} from '../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import { ResourceResolver } from '../dave-data-module/guards/resource-dispo/resource.resolver';
import { ResourceTypeResolver } from '../dave-data-module/guards/resource-dispo/resource-type.resolver';
import {
    getResourceTypeDictionary,
    getResourceTypesFetched,
} from '../dave-data-module/State/selectors/resource-dispo/resource-type.selectors';
import { getFetched$ } from '../dave-data-module/helper/helper';

@Injectable({
    providedIn: 'root',
})
export class AddReportService {
    public IsGenerating$ = new BehaviorSubject(false);
    constructor(
        protected store: Store<State>,
        protected router: Router,
        protected actions$: Actions,
        protected sfdtTemplateService: SfdtTemplateService,
        public LS: LoadingService,
        protected employeeResolver: EmployeeResolver,
        protected jobSpecificationResolver: JobSpecificationResolver,
        protected eventResolver: EventResolver,
        protected eventTypeResolver: EventTypeResolver,
        protected customerResolver: CustomerResolver,
        protected officeResolver: OfficeResolver,
        protected personTypeResolver: PersonTypeResolver,
        protected commissionResolver: CommissionResolver,
        protected damageFlowResolver: DamageFlowResolver,
        protected damageFlowTypeResolver: DamageFlowTypeResolver,
        protected personResolver: PersonResolver,
        protected person2EntityResolver: Person2EntityResolver,
        protected resourceResolver: ResourceResolver,
        protected resourceTypeResolver: ResourceTypeResolver,
    ) {}

    protected readonly GeneratedDocumentsPageMeta = GeneratedDocumentsPageMeta;
    NewReport(type: GeneratedDocumentsTypeEntity, customerId?: number, commissionId?: number, actionPayload?: DaveMutationCreateGeneratedDocumentsArgs, resourceId?: number) {
        //todo: add resourceId support
        this.LS.startLoading('generate-report-from-type');
        this.IsGenerating$.next(true);
        const addAction = (type: GeneratedDocumentsTypeEntity) => {

            firstValueFrom(
                combineLatest([
                    customerId
                        ? combineLatest([getFetched$(this.store, getCustomersFetched, getCustomers, this.customerResolver), getFetched$(this.store, getOfficesFetched, getOffices, this.officeResolver)])
                        : of([[], []] as [CustomerEntity[], OfficeEntity[]]),
                    commissionId
                        ? combineLatest([
                              getFetched$(this.store, getPersonsFetched, getPersons, this.personResolver),
                              getFetched$(this.store, getPerson2EntitiesFetched, getPerson2Entities, this.person2EntityResolver),
                              getFetched$(this.store, getPersonTypesFetched, getPersonTypes, this.personTypeResolver),
                              getFetched$(this.store, getCommissionsFetched, getCommissions, this.commissionResolver),
                              getFetched$(this.store, getDamageFlowsFetched, getDamageFlows, this.damageFlowResolver),
                              getFetched$(this.store, getDamageFlowTypesFetched, getDamageFlowTypes, this.damageFlowTypeResolver),
                          ])
                        : of([[], [], [], [], [], []] as [PersonEntity[], Person2EntityEntity[], PersonTypeEntity[], CommissionEntity[], DamageFlowEntity[], DamageFlowTypeEntity[]]),
                    customerId || commissionId
                        ? combineLatest([getFetched$(this.store, getEventFetched, getEvents, this.eventResolver), getFetched$(this.store, getEventTypeFetched, getEventTypes, this.eventTypeResolver)])
                        : of([[], []] as [EventEntity[], EventTypeEntity[]]),
                    this.store.select(getPartner),
                    this.store.select(getUser),
                    getFetched$(this.store, getEmployeesFetched, getEmployees, this.employeeResolver),
                    getFetched$(this.store, getJobSpecificationFetched, getJobSpecificationDictionary, this.jobSpecificationResolver),
                    resourceId ? getFetched$(this.store, getResourcesFetched, getResourceById({id: resourceId}), this.resourceResolver) : of(null),
                    resourceId ? getFetched$(this.store, getResourceTypesFetched, getResourceTypeDictionary, this.resourceTypeResolver) : of(null),
                ]),
            ).then(([[customers, offices], [persons, personToEntitys, personTypes, commissions, damageflows, damageFlowTypes], [events, eventtypes], partner, user, employees, jobstecifications, resource, resourceTypes]) => {
                try {
                    let filteredEvents: EventEntity[] = events?.filter((e) => (e.CustomerId === customerId || e.CommissionId === commissionId) && eventtypes.find((et) => e.EventTypeId === et.Id && et.Name.includes('termin')));
                    filteredEvents = filteredEvents?.slice().sort((a, b) => (b.EventDate > a.EventDate ? 1 : b.EventDate < a.EventDate ? -1 : 0));
                    const customer = customers.find((c) => customerId === c.Id);
                    const commission: CommissionEntity = commissions.find((c) => commissionId === c.Id);
                    const versicherungsnehmerPersonTypeId = personTypes.find((p) => p.Name === PersonTypeNames.Policyholder)?.Id;
                    const bauherrPersonTypeId = personTypes.find((p) => p.Name === PersonTypeNames.BuildingOwner)?.Id;
                    const bauleiterPersonTypeId = personTypes.find((p) => p.Name === PersonTypeNames.ConstructionManager)?.Id;
                    const architectPersonTypeId = personTypes.find((p) => p.Name === PersonTypeNames.Architect)?.Id;
                    const DamageFlowEntity = damageflows?.find((d) => d.CommissionId === commission?.Id);
                    const EmployeeEntity = user && employees ? employees.find((e) => e.UserId === user.Id) : null;
                    const JobSpecificationEntity = EmployeeEntity?.JobSpecificationId && jobstecifications ? jobstecifications[EmployeeEntity.JobSpecificationId] : null;
                    let PersonEntity = undefined;
                    let commissionPersons = [];
                    if (commissionId) {
                        const pIds = personToEntitys?.filter((p) => p.EntityType === Person2EntityEntityTypeEnum.Commission && p.EntityId === commissionId).map((p) => p.PersonId);
                        commissionPersons = persons?.filter((p) => pIds?.includes(p.Id));
                        PersonEntity = commissionPersons?.length ? commissionPersons[0] : {};
                    }
                    const PartnerOfficeLogo: Base64Logo = transparentPixelLogo; // todo get logo from partnerOffice

                    this.store.dispatch(
                        GeneratedDocumentsActionTypes.AddGeneratedDocuments({
                            Payload: {
                                customerId,
                                commissionId,
                                resourceId,
                                name: type.AdditionalData?.NameTemplate !== undefined ? this.sfdtTemplateService.FillTemplateByTwig(type.AdditionalData.NameTemplate, {}) : type.Name,
                                state: getGQLDocumentStateFromDocumentState(DocumentState.DocumentStateNew),
                                generatedDocumentsTypeId: type.Id,
                                additionalData: JSON.stringify({ CustomFieldsModalTemplates: type.AdditionalData?.CustomFieldsModalTemplates || null } as GeneratedDocumentsEntityAdditionalData),
                                generatedFile: this.sfdtTemplateService.FillTemplateByTwig(type.Markdown, {
                                    PartnerOfficeLogo,
                                    ResourceEntity: resource,
                                    ResourceTypeEntity: resource?.ResourceTypeId ? resourceTypes[resource.ResourceTypeId] : null,
                                    CustomerEntity: customer,
                                    CommissionEntity: commission,
                                    PersonEntity,
                                    EventEntity: filteredEvents?.length ? filteredEvents[0] : {},
                                    PartnerEntity: partner,
                                    UserEntity: user,
                                    EmployeeEntity,
                                    JobSpecificationEntity,
                                    OfficeEntity: offices?.find((o) => o.CustomerId === customer?.Id),
                                    DamageFlowEntity,
                                    DamageFlowTypeEntity: damageFlowTypes?.find((d) => d.Id === DamageFlowEntity?.DamageFlowTypeId),
                                    VersicherungsnehmerEntity: persons?.find((p) => p.PersonTypeId === versicherungsnehmerPersonTypeId),
                                    Bauherr: commissionPersons?.find((p) => p.PersonTypeId === bauherrPersonTypeId),
                                    Bauleiter: commissionPersons?.find((p) => p.PersonTypeId === bauleiterPersonTypeId),
                                    Architekt: commissionPersons?.find((p) => p.PersonTypeId === architectPersonTypeId),
                                }),
                                ...actionPayload,
                            },
                        }),
                    );
                } catch (e) {
                    console.error(e);
                    this.LS.endLoading('generate-report-from-type');
                    this.IsGenerating$.next(false);
                }
            });
        };
        const isReady = firstValueFrom(this.actions$.pipe(ofType(GeneratedDocumentsActionTypes.UpdateOne, BaseActionTypes.ErrorAction))).finally(() => {
            this.LS.endLoading('generate-report-from-type');
            this.IsGenerating$.next(false);
        });
        if (!type.Markdown) {
            firstValueFrom(this.actions$.pipe(ofType(GeneratedDocumentsTypeActionTypes.UpdateGeneratedDocumentType, BaseActionTypes.ErrorAction))).then((action) => {
                if (action.type === GeneratedDocumentsTypeActionTypes.UpdateGeneratedDocumentType.type) {
                    addAction(action.Payload);
                }
            });
            this.store.dispatch(GeneratedDocumentsTypeActionTypes.GetByIdRequest({ id: type.Id, withFile: true }));
        } else {
            addAction(type);
        }
        return isReady;
    }
}
