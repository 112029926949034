<ng-container *ngIf="person$ | async as person">
    <fa-icon [icon]="person.Deleted ? 'user-slash' : 'user'" class="image bigger"></fa-icon>
    <div class="main content">
        <strong class="bigger">{{person.DisplayName}}</strong>
        <span>{{(personType$ | async)?.Name}}</span>
        <a class="link" *ngIf="person.Email as value" [href]="'mailto:' + value" (click)="$event.stopPropagation();">{{ value }}</a>
    </div>
    <div class="side content">
        <a class="link" *ngIf="person.PhoneNumber as value" [href]="'tel:' + value" (click)="$event.stopPropagation();">{{ value }}</a>
        <a class="link" *ngIf="person.MobileNumber as value" [href]="'tel:' + value" (click)="$event.stopPropagation();">{{ value }}</a>
    </div>
</ng-container>
