import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserResolver } from '../dave-data-module/guards/user.resolver';
import { ValidTokenGuard } from '../dave-data-module/guards/valid-token.guard';
import { TokenResolver } from '../dave-data-module/guards/token.resolver';
import { CustomerResolver } from '../dave-data-module/guards/customer.resolver';
import { TagResolver } from '../dave-data-module/guards/tag.resolver';
import { CommissionResolver } from '../dave-data-module/guards/commission.resolver';
import { EventTypeResolver } from '../dave-data-module/guards/event-type.resolver';
import { DmsWrapperComponent } from './dave-dms/dms-wrapper/dms-wrapper.component';
import { DetectionResolver } from '../dave-data-module/guards/detection.resolver';
import {PersonResolver} from '../dave-data-module/guards/person.resolver';

const routes: Routes = [
    {
        path: '',
        data: {},
        component: DmsWrapperComponent,
        canActivate: [ValidTokenGuard],
        canActivateChild: [ValidTokenGuard],
        resolve: {
            token: TokenResolver,
            tags: TagResolver,
            commission: CommissionResolver,

            // Für die Filter
            customers: CustomerResolver,
            persons: PersonResolver,

            // Für die Event Card
            eventTypes: EventTypeResolver,
            detections: DetectionResolver,
            // bookmarks: BookmarkResolver,
            users: UserResolver
        },
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class DaveDMSRoutingModule {}
