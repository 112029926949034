import { createEffect, ofType, Actions } from "@ngrx/effects";
import {
    withLatestFrom,
    switchMap,
    map,
    catchError,
    concatMap
} from "rxjs/operators";
import { Store } from "@ngrx/store";
import { DaveActions } from "../actions/actions";
import { HttpService } from "../../services/http.service";
import { State } from "../index";
import { SepaActionTypes } from "../actions/sepa.actions";
import { SepaEntityFromBackend } from "../../entities/sepa.entity";
import { BaseActionTypes } from "../actions/base.actions";
import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { ResolverActionTypes } from '../actions/resolver.actions';

enum ErrorCodes {
    Add = "Sepa Hinzufügen fehlgeschlagen",
    Load = "Sepa Abrufen fehlgeschlagen",
    Modify = "Sepa Bearbeiten fehlgeschlagen",
    Remove = "Sepa Löschen fehlgeschlagen"
}

@Injectable()
export class SepaEffects {

    ModifySepa$ = createEffect(() => this.actions$.pipe(
        ofType(SepaActionTypes.ModifySepa),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
      mutation{
        changeSepa(id: ${action.Payload.id},
            ${stringifyIfNotNullOrUndefined(action.Payload, 'faelligkeitsdatum')}
            ${stringifyIfNotNullOrUndefined(action.Payload, 'userId')}
        ${stringifyIfNotNullOrUndefined(action.Payload, 'partnerId')}
          ){
            bankname
            bic
            createdAt
            faelligkeitsdatum
            iban
            id
            mandatsreferenz
            partnerId
            updatedAt
            userId
        }
      }`;
            return this.gatewayHttpService
                .graphQl({ query: queryString }, { token: store.base.token })
                .pipe(
                    map(res =>
                        res && res.changeSepa && res.changeSepa.id
                            ? SepaActionTypes.UpdateSepa({
                                Payload: res.sepa.map(val =>
                                    SepaEntityFromBackend(val)
                                )
                            })
                            : BaseActionTypes.ErrorAction({
                                Payload: { ToasterMessage: ErrorCodes.Modify }
                            })
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Modify,
                                    Err: err,
                                    Caught: caught
                                }
                            })
                        )
                    )
                );
        })
    ));

    AddSepa$ = createEffect(() => this.actions$.pipe(
        ofType(SepaActionTypes.AddSepa),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
      mutation{
        createSepa(
            ${stringifyIfNotNullOrUndefined(action.Payload, 'faelligkeitsdatum')}
            ${stringifyIfNotNullOrUndefined(action.Payload, 'userId')}
            ${stringifyIfNotNullOrUndefined(action.Payload, 'partnerId')}
                  ){
            bankname
            bic
            createdAt
            faelligkeitsdatum
            iban
            id
            mandatsreferenz
            partnerId
            updatedAt
            userId
        }
      }`;

            return this.gatewayHttpService
                .graphQl({query: queryString}, {token: store.base.token})
                .pipe(
                    map(res => {
                        return res && res.createSepa
                            ? SepaActionTypes.UpdateSepa({
                                Payload: [SepaEntityFromBackend(res.createSepa)]
                            })
                            : BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Add}});
                    }),
                    catchError((err, caught) => of(BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Add,
                            Err: err,
                            Caught: caught,
                        }
                    }))),
                );
        }),
    ));


    LoadSepa = createEffect(() => this.actions$.pipe(
        ofType(ResolverActionTypes.ResolverLoadSepa),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
            query{
                  sepa{
                    bankname
                    bic
                    createdAt
                    faelligkeitsdatum
                    iban
                    id
                    mandatsreferenz
                    partnerId
                    updatedAt
                    userId
                  }
                }`;
            return this.gatewayHttpService
                .graphQl({ query: queryString }, { token: store.base.token, retry: true })
                .pipe(
                    map(res =>
                        res && res.sepa
                            ? SepaActionTypes.UpdateSepa({
                                Payload: res.sepa.map(val =>
                                    SepaEntityFromBackend(val)
                                )
                            })
                            : BaseActionTypes.ErrorAction({
                                Payload: { ToasterMessage: ErrorCodes.Load }
                            })
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught
                                }
                            })
                        )
                    )
                );
        })
    ));
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService
    ) {}
}
