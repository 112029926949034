import { BusinessVolumeType, Scalars, SurchargeRateType } from '../graphql-types';
import { BackendDate, BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';

export enum SurchargeRateNameEnum {
    ExternalService = 'ExternalService',
    Leistung = 'Leistung',
    Lohn = 'Lohn',
    Material = 'Material',
    Other = 'Other',
    Ressource = 'Ressource'
}

export class SurchargeRateEntity {
    constructor(
        public CreatedAt: Date = null,
        public Name: SurchargeRateNameEnum = null,
        public PartnerId: number = null,
        public PercentageValue: number = null,
        public UpdatedAt: Date = null,

    ) {
    }
    public static readonly GqlFields: Array<keyof SurchargeRateType> = [
    'createdAt',
    'name',
    'partnerId',
    'percentageValue',
    'updatedAt',
    ];
    public Clone(override: Partial<SurchargeRateEntity> = {}): SurchargeRateEntity {
        const attr = {...this, ...override};
        return Object.assign(new SurchargeRateEntity(), attr);
    }
}

export function SurchargeRateEntityFromBackend(res: SurchargeRateType): SurchargeRateEntity {
    return new SurchargeRateEntity(
        res.createdAt ? BackendDateTimestamp(res.createdAt) : null,
        res.name,
        res.partnerId,
        res.percentageValue,
        res.updatedAt ? BackendDateTimestamp(res.updatedAt) : null,
    );
}
