import { OAuthTokenType } from '../graphql-types';
import { GetTimeFromTimestamp } from '@dave/types';
import { isNotNullOrUndefined } from '../../helper/helper';
export enum OAuthType {
    Microsoft = 'MICROSOFT',
    MicrosoftMail = 'MICROSOFT_MAIL'
}

export class OAuthTokenEntity {
    constructor(
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public DisplayName: string = null,
        public Expired: boolean = null,
        public Id: number = null,
        public PartnerId: number = null,
        public ProfileImage: string = null,
        public Type: OAuthType = null,
        public UpdatedAt: Date = null,
        public UserId: number = null,
        public Username: string = null,
    ) {}
    public static readonly GqlFields: Array<keyof OAuthTokenType> = [
        'createdAt',
        'deletedAt',
        'displayName',
        'expired',
        'id',
        'partnerId',
        'profileImage',
        'type',
        'updatedAt',
        'userId',
        'username',
    ]
    public Clone(override: Partial<OAuthTokenEntity> = {}): OAuthTokenEntity {
        const attr = { ...this, ...override };
        return Object.assign(new OAuthTokenEntity(), attr);
    }
}

export function OAuthTokenEntityFromBackend(res: OAuthTokenType): OAuthTokenEntity {
    return new OAuthTokenEntity(
    isNotNullOrUndefined(res.createdAt) ? GetTimeFromTimestamp(res.createdAt) : res.createdAt,
    isNotNullOrUndefined(res.deletedAt) ? GetTimeFromTimestamp(res.deletedAt) : res.deletedAt,
    res.displayName,
    res.expired,
    res.id,
    res.partnerId,
    res.profileImage,
    res.type,
    isNotNullOrUndefined(res.updatedAt) ? GetTimeFromTimestamp(res.updatedAt) : res.updatedAt,
    res.userId,
    res.username,
    );
}

