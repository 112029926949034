import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { WorkedTimesEntity } from '../../entities/worked-times.entity';
import { WorkedTimesActionTypes } from '../actions/worked-times.actions';


export const workedTimesFeatureKey = 'worked-times';

export interface WorkedTimesState extends EntityState<WorkedTimesEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}
const adapter = createEntityAdapter<WorkedTimesEntity>({
    selectId: Entity => Entity.Id,
});

const initialConditionsState: WorkedTimesState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const workedTimesStateReducer = createReducer(
    initialConditionsState,
    on(WorkedTimesActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(WorkedTimesActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(WorkedTimesActionTypes.RemoveMany, (state, { Payload }) =>
        adapter.removeMany(Payload, state),
    ),
);


/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllWorkedTimes = selectAll;
export const selectWorkedTimesEntities = selectEntities;

export const selectWorkedTimesFetched = (state: WorkedTimesState) => state.fetched;
export const selectWorkedTimesLatestUpdatedAt = (state: WorkedTimesState) => state.latestUpdatedAt;
