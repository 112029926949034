import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    SalaryTypeState,
    selectAllSalaryType,
    selectSalaryTypeEntities,
    selectSalaryTypeFetched,
    SALARY_TYPE_FEATURE_KEY,
} from '../reducers/salary-type.reducer';

const selectState = createFeatureSelector<SalaryTypeState>(SALARY_TYPE_FEATURE_KEY);
export const getSalaryTypeDictionary = createSelector(selectState, selectSalaryTypeEntities);
// export const getSalaryTypeLatestUpdatedAt = createSelector(selectState, selectSalaryTypeLatestUpdatedAt);
export const getSalaryTypeFetched = createSelector(selectState, selectSalaryTypeFetched);
export const getSalaryTypes = createSelector(selectState, selectAllSalaryType);
export const getSalaryTypesActive = createSelector(getSalaryTypes, (salaryTypes) => salaryTypes.filter((salaryType) => !salaryType.DeletedAt));
export const getSalaryTypeById = (props: { id: number }) => createSelector(getSalaryTypeDictionary, (dictionary) => props.id && dictionary[props.id]);
