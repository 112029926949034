import { Component,Inject,OnDestroy,OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject,combineLatest,firstValueFrom,map,Observable,Subscription,switchMap } from 'rxjs';
import { debounceTime,distinctUntilChanged,filter,shareReplay,tap } from 'rxjs/operators';
import { EmployeeEntity, EmployeeStatusEnum } from "../../../../dave-data-module/entities/employee.entity";
import { EmployeeResolver } from '../../../../dave-data-module/guards/employee.resolver';
import { JobSpecificationResolver } from '../../../../dave-data-module/guards/job-specification.resolver';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { EmployeeActionTypes } from '../../../../dave-data-module/State/actions/employee.actions';
import {
getEmployeeById,
getEmployees,
getEmployeesFetched
} from '../../../../dave-data-module/State/selectors/employees.selectors';
import { getJobSpecificationDictionary,getJobSpecificationFetched } from '../../../../dave-data-module/State/selectors/job-specification.selector';
import { FilterOption } from '../../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { isNotNullOrUndefined,SearchQueriesDebounceTime,stringSearch,uniqArray } from '../../../../helper/helper';
import { DefaultFilterService,FilterApps,FilterTypes } from '../../../../services/default-filter.service';
import { LoadingService } from '../../../../services/loading.service';
import {
ISelectedElement,
ITableConfig,
TableContentType
} from '../../multi-select-table/multi-select-table.component';
import { SelectUserDialogBase } from '../select-user-dialog-base';

export interface SelectUserEmployeeDialogComponentDialogData {
    employeeId: number;
}
interface ITableData {
    userId: number;
    selectedForm: FormControl<boolean>;
    name: TableContentType<any> & { __typename: 'string' };
    jobSpecification: TableContentType<any> & { __typename: 'string' };
    jobSpecificationId: number | null;
    employeeStaus: EmployeeStatusEnum | null;
}
interface SelectedUserType extends ISelectedElement {
    employee: EmployeeEntity;
}
@Component({
    selector: 'app-select-user-employee-dialog',
    templateUrl: './select-user-employee-dialog.component.html',
    styleUrls: ['./select-user-employee-dialog.component.scss'],
})
export class SelectUserEmployeeDialogComponent extends SelectUserDialogBase implements OnInit, OnDestroy {
    public _FilterSettings$: Observable<FilterOption[]> = this.FilterSettings$.pipe(map(filters => filters.filter(f => f.Name !== FilterTypes.HideKollision)));
    public SearchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

    SelectedUsers$: Observable<SelectedUserType[]>;

    TableDataConfig: ITableConfig[] = [
        {
            header: 'Name',
            id: 'name',
            sortAccessor: (cell: TableContentType<any>&{__typename: "string"}) => cell.content || '',
        },
        {
            header: 'Jobbezeichnung',
            id: 'jobSpecification',
            sortAccessor: (cell: TableContentType<any>&{__typename: "string"}) => cell.content || '',
        },
    ];
    FilteredTableData$: Observable<ITableData[]>;
    TableData$: Observable<ITableData[]>;

    private subscriptions: Subscription[] = [];
    public ContentResolved$: Observable<boolean>;
    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA) public Dialogdata: SelectUserEmployeeDialogComponentDialogData,
        private actions$: Actions,
        public LS: LoadingService,
        defaultFilter: DefaultFilterService,
        employeeRes: EmployeeResolver,
        jobRes: JobSpecificationResolver,
    ) {
        super(store, defaultFilter, FilterApps.SelectUserForEmployeePopup);
        this.ContentResolved$ = combineLatest([
            this.store.select(getEmployeesFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        employeeRes.resolve();
                    }
                }),
            ),
            this.store.select(getJobSpecificationFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        jobRes.resolve();
                    }
                }),
            ),
        ]).pipe(
            map((values) => values.every((v) => v)),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        const employeesWithUserId$ = this.store.select(getEmployeesFetched).pipe(
            filter((v) => !!v),
            switchMap(() => this.store.select(getEmployees)),
            map(employees => employees.filter((e) => e.UserId)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.SelectedUsers$ = combineLatest([
            this.store.select(getEmployeesFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getEmployeeById({id: this.Dialogdata.employeeId}))),
            ),
            employeesWithUserId$.pipe(map((employees) => new Map<number, EmployeeEntity>(employees.map((e) => [e.UserId, e])))),
        ]).pipe(
            map(([e, employeeMap]) => e.UserIds
                    .map((uId) => ({
                        employee: employeeMap.get(uId),
                        label: employeeMap.get(uId)?.DisplayName || '',
                    }))
                    // für kaputte mitarbeiterEntities
                    .filter(u => u.employee),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.TableData$ = combineLatest([
            employeesWithUserId$,
            this.store.select(getJobSpecificationFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getJobSpecificationDictionary)),
            ),
        ]).pipe(
            map(([employees, jobs]) => {
                return employees
                    // .filter((e) => e.Id !== this.Dialogdata.employeeId)
                    .map<ITableData>((e) => {
                        const job = e.JobSpecificationId && jobs[e.JobSpecificationId];
                        return {
                            userId: e.UserId,
                            selectedForm: new FormControl<boolean>(false),
                            name: {
                                __typename: 'string',
                                content: e.DisplayName,
                            },
                            jobSpecification: {
                                __typename: 'string',
                                content: job?.Name || '',
                            },
                            jobSpecificationId: e.JobSpecificationId,
                            employeeStaus: e.EmployeeStatus,
                        };
                    });
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.FilteredTableData$ = this.TableData$.pipe(
            switchMap((rows) =>
                this.FilterValues$.pipe(
                    map((filter) =>
                        filter[FilterTypes.JobSpecificationIds]?.length
                            ? rows.filter((r) => (!filter[FilterTypes.JobSpecificationIds]?.length || r.jobSpecificationId && filter[FilterTypes.JobSpecificationIds].includes(r.jobSpecificationId + '')) && !filter[FilterTypes.Status]?.length || filter[FilterTypes.Status].some(f => f.id === r.employeeStaus))
                            : rows,
                    ),
                ),
            ),
            switchMap((rows) =>
                this.SearchString.pipe(
                    debounceTime(SearchQueriesDebounceTime),
                    map((searchString) => (!searchString && rows) || rows.filter((r) => [r.name.content, r.jobSpecification.content].filter(isNotNullOrUndefined).some(s => stringSearch(s, searchString)))),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }
    ngOnInit(): void {
        this.subscriptions.push(
            this.TableData$.pipe(
                switchMap((data) =>
                    this.SelectedUsers$.pipe(
                        tap((selected) =>
                            data.forEach((d) => {
                                const isSelected = selected.some((u) => d.userId === u.employee.UserId);

                                if (isSelected && (d.selectedForm.enabled || !d.selectedForm.value)) {
                                    d.selectedForm.setValue(true);
                                    d.selectedForm.disable();
                                } else if (!isSelected && (d.selectedForm.disabled || d.selectedForm.value)) {
                                    d.selectedForm.setValue(false);
                                    d.selectedForm.enable();
                                }
                            }),
                        ),
                    ),
                ),
            ).subscribe(),
        );
    }
    RemoveSelected(entity: SelectedUserType) {
        this.LS.startLoading('delete-user-to-employee');
        firstValueFrom(this.actions$.pipe(ofType(EmployeeActionTypes.UpdateOne, BaseActionTypes.ErrorAction))).then(() => {
            this.LS.endLoading('delete-user-to-employee');
        });
        firstValueFrom(this.SelectedUsers$).then(prevSelected => {
            console.log({prevSelected})
            this.store.dispatch(
                EmployeeActionTypes.SetEmployeeUser({
                    Payload: {
                        EmployeeId: this.Dialogdata.employeeId,
                        UserIds: prevSelected.filter(s => s.employee.Id !== entity.employee.Id).map(s => s.employee.UserId),
                    },
                }),
            );
        })
    }
    Submit() {
        this.LS.startLoading('set-user-to-employee');
        firstValueFrom(combineLatest([this.TableData$, this.SelectedUsers$])).then(([data, selected]) => {
            firstValueFrom(this.actions$.pipe(ofType(EmployeeActionTypes.UpdateOne, BaseActionTypes.ErrorAction))).then(() => {
                this.LS.endLoading('set-user-to-employee');
            });
            this.store.dispatch(
                EmployeeActionTypes.SetEmployeeUser({
                    Payload: {
                        EmployeeId: this.Dialogdata.employeeId,
                        UserIds: uniqArray([...selected.map((s) => s.employee.UserId), ...data.filter((d) => d.selectedForm.value).map((d) => d.userId)]),
                    },
                }),
                // EventsActionTypes.SetUserToEvent({
                //     Payload: {
                //         userIds: uniqArray([...selected.map((s) => s.userToEvent.UserId), ...data.filter((d) => d.selectedForm.value).map((d) => d.userId)]),
                //         eventId: this.Dialogdata.eventId,
                //     },
                // }),
            );
            // this.LS.endLoading('set-user-to-employee');
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
