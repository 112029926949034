import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { DocumentUserEntity, DocumentUserEntityFromBackend } from '../../entities/document-user.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { FileUserActionTypes } from '../actions/file-user';
import { FilesActionTypes } from '../actions/files.actions';
import { FolderActionTypes } from '../actions/folder.actions';
import { State } from '../index';
import { getFileById, getFiles } from '../selectors/files.selectors';
import { getFolders } from '../selectors/folder.selectors';
import { getToken } from '../selectors/base.selectors';
import { retryWithBackoff } from '../../helper/http-service.helper';

enum ErrorCodes {
    Remove = 'Datei Benutzer Löschen fehlgeschlagen',
    Add = 'Datei Benutzer hinzufügen fehlgeschlagen',
    Load = 'Datei Benutzer abrufen fehlgeschlagen',
}

@Injectable()
export class FileUserEffects {

    AddFileUser$ = createEffect(() => this.actions$.pipe(
        ofType(FileUserActionTypes.AddFileUser),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{Payload}, token]) => {
            const queryString = `
        mutation {
            createDocumentUser(
                ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'documentId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'edit')}
            ) {
                documentUsers {${DocumentUserEntity.GqlFields}}
          }
        }`;

            return this.gatewayHttpService.graphQl({query: queryString}, {token}).pipe(
                withLatestFrom(this.store$.select(getFiles)),
                withLatestFrom(this.store$.select(getFolders)),
                concatMap(([[res, documents], folders]) =>
                    res && res.createDocumentUser
                        ? [
                            FileUserActionTypes.Update({Payload:{documentId: Payload.documentId, users: res.createDocumentUser.documentUsers.map(u => DocumentUserEntityFromBackend(u))}}),
                            // FilesActionTypes.UpdateMany({
                            //     Payload: [
                            //         ...documents.map(f => f.Id === Payload.documentId ? f.Clone({Users: res.createDocumentUser.documentUsers.map(u => DocumentUserEntityFromBackend(u))}) : (f.Users !== null ? f.Clone({Users: null}) : f))
                            //     ]
                            // }),
                            FolderActionTypes.UpdateAll({
                                Payload: folders.map(f => f.Users !== null ? f.Clone({Users: null}) : f)
                            })
                        ]
                        : [BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Add}})],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    DeleteFileUser$ = createEffect(() => this.actions$.pipe(
        ofType(FileUserActionTypes.DeleteFileUser),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{Payload}, token]) => {
            const queryString = `
        mutation {
            deleteDocumentUser(
                ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'documentId')}
            ) {
                documentUsers {${DocumentUserEntity.GqlFields}}
          }
        }`;

            return this.gatewayHttpService.graphQl({query: queryString}, {token}).pipe(
                withLatestFrom(this.store$.select(getFiles)),
                withLatestFrom(this.store$.select(getFolders)),
                concatMap(([[res, documents], folders]) =>
                    res && res.deleteDocumentUser
                        ? [
                            FileUserActionTypes.Update({Payload:{documentId: Payload.documentId, users: res.deleteDocumentUser.documentUsers.map(u => DocumentUserEntityFromBackend(u))}}),
                            // FilesActionTypes.UpdateMany({
                            //     Payload: [
                            //         ...documents.map(f => f.Id === Payload.documentId ? f.Clone({Users: res.deleteDocumentUser.documentUsers.map(u => DocumentUserEntityFromBackend(u))}) : (f.Users !== null ? f.Clone({Users: null}) : f))
                            //     ]
                            // }),
                            FolderActionTypes.UpdateAll({
                                Payload: folders.map(f => f.Users !== null ? f.Clone({Users: null}) : f)
                            })
                        ]
                        : [BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Remove}})],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService, private http: HttpClient) {
    }
}
