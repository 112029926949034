import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventTypeEntityFromBackend } from '../../entities/event-type.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { EventTypesActionTypes } from '../actions/event-types.actions';
import { ResolverActionTypes } from '../actions/resolver.actions';
import { State } from '../index';

enum ErrorCodes {
    Add = 'Ereignistyp Hinzufügen fehlgeschlagen',
    Load = 'Ereignistypen Abrufen fehlgeschlagen',
    Remove = 'Ereignistyp Löschen fehlgeschlagen',
}

@Injectable()
export class EventTypesEffects {
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}

    AddEventType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EventTypesActionTypes.AddEventType),
            withLatestFrom(this.store$),
            concatMap(([action, store]) => {
                const queryString = `
            mutation {
                createEventType(
                    name: "${action.Payload.Name || ''}"
                    isDefault: ${action.Payload.IsDefault || false}
                    imagePath: "${action.Payload.ImagePath || ''}"
                ) {
                    id
                    imagePath
                    isDefault
                    name
                    partnerId
                    markdown
                }
            }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    map((res) =>
                        res && res.createEventType
                            ? EventTypesActionTypes.UpdateOne({
                                  Payload: EventTypeEntityFromBackend(res.createEventType),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    LoadEventTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResolverActionTypes.LoadEventTypes),
            withLatestFrom(this.store$),
            switchMap(([action, store]) => {
                const queryString = `
            query {
                eventtype {
                    id
                    imagePath
                    isDefault
                    name
                    partnerId
                    markdown
                }
            }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                    map((res) =>
                        res && res.eventtype
                            ? EventTypesActionTypes.UpdateEventTypes({
                                  Payload: res.eventtype.map((val) => EventTypeEntityFromBackend(val)),
                              }) // ToDo: Backend EventType has PartnerId
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    DeleteEventType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EventTypesActionTypes.DeleteEventType),
            withLatestFrom(this.store$),
            concatMap(([action, store]) => {
                const queryString = `
            mutation {
                deleteEventType(id: ${action.Payload})
            }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    map((res) =>
                        res && res.deleteEventType
                            ? EventTypesActionTypes.RemoveOne({
                                  Payload: action.Payload,
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
}
