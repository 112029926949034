import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { State } from '..';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { User2CommissionShiftEntityFromBackend } from '../../entities/user2CommissionShift.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { CommissionActionTypes } from '../actions/commission.actions';
import { ResolverActionTypes } from '../actions/resolver.actions';
import { UserToCommissionShiftActionTypes } from '../actions/user2CommissionShift.action';
import { User2CommissionShiftEntity } from './../../entities/user2CommissionShift.entity';
import { getUserToCommissionShifts } from './../selectors/users-to-commission-shift.selectors';
import { getUserToCommissions } from '../selectors/user-to-commission.selector';
import { getCommissionById, getCommissionsFetched } from '../selectors/commission.selector';

enum ErrorCodes {
    Load = 'User2CommissionShift Abrufen fehlgeschlagen',
    Add = 'User2CommissionShift Speichern fehlgeschlagen',
    Change = 'User2CommissionShift Bearbeiten fehlgeschlagen',
    Remove = 'User2CommissionShift Löschen fehlgeschlagen',
}

@Injectable()
export class User2CommissionShiftEffects {

    AddManageUser$ = createEffect(() => this.actions$.pipe(
        ofType(UserToCommissionShiftActionTypes.AddUser2CommissionShift),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                createUser2CommissionShift(
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'userId')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'commissionId')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'startDate')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'endDate')}
                ) {${User2CommissionShiftEntity.GQLFields}}
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                withLatestFrom(this.store$.select(getUserToCommissionShifts), this.store$.select(getCommissionById({id: action.Payload.commissionId}))),
                concatMap(([res, oldU2CSs, commission]) => {
                    return res && res.createUser2CommissionShift
                        ? [
                              UserToCommissionShiftActionTypes.UpdateAll({
                                  Payload: [...(oldU2CSs || []), User2CommissionShiftEntityFromBackend(res.createUser2CommissionShift)],
                              }),
                              CommissionActionTypes.Load({}),
                          ]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } })];
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    ChangeManageUser$ = createEffect(() => this.actions$.pipe(
        ofType(UserToCommissionShiftActionTypes.ChangeUser2CommissionShift),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            console.log('user2CommissionShiftActionTypes.ChangeUser2CommissionShift');
            const queryString = `
          mutation {
              changeUser2CommissionShift(
                  id: ${action.Payload.id}
                  ${stringifyIfNotNullOrUndefined(action.Payload, 'userId')}
                  ${stringifyIfNotNullOrUndefined(action.Payload, 'commissionId')}
                  ${stringifyIfNotNullOrUndefined(action.Payload, 'startDate')}
                  ${stringifyIfNotNullOrUndefined(action.Payload, 'endDate')}
              ) {${User2CommissionShiftEntity.GQLFields}}
          }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                withLatestFrom(this.store$.select(getUserToCommissionShifts)),
                concatMap(([res, oldU2CSs]) => {
                    return res && res.changeUser2CommissionShift
                        ? [
                              UserToCommissionShiftActionTypes.UpdateAll({
                                  Payload: [
                                      ...(oldU2CSs || []).filter(u => u.Id !== res.changeUser2CommissionShift.id),
                                      User2CommissionShiftEntityFromBackend(res.changeUser2CommissionShift),
                                  ],
                              }),
                          ]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Change } })];
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Change,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    LoadUser2CommissionShift = createEffect(() => this.actions$.pipe(
        ofType(UserToCommissionShiftActionTypes.Load),
        switchMap(({ Payload }) => {
            const queryString = `
query{
    user2CommissionShift${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}", withDeleted: true)` : ''}{
    ${User2CommissionShiftEntity.GQLFields}}
      }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                map(res =>
                    res && res.user2CommissionShift
                        ? Payload?.updatedSince
                            ? UserToCommissionShiftActionTypes.UpdateMany({
                                  Payload: res.user2CommissionShift.map(val => User2CommissionShiftEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                            : UserToCommissionShiftActionTypes.UpdateAll({
                                  Payload: res.user2CommissionShift.map(val => User2CommissionShiftEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Load },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    DeleteUser2CommissionShift$ = createEffect(() => this.actions$.pipe(
        ofType(UserToCommissionShiftActionTypes.DeleteOne),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                deleteUser2CommissionShift(id: ${action.Payload})
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res =>
                    res && res.deleteUser2CommissionShift
                        ? [UserToCommissionShiftActionTypes.RemoveOne({
                              Payload: action.Payload,
                          }),
                          CommissionActionTypes.Load({}),]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } }),]
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
