import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    MATERIAL_TO_COMMISSION_KEY, MaterialToCommissionState,
    selectAllMaterialToCommission, selectMaterialToCommissionEntities,
    selectMaterialToCommissionFetched,
    selectMaterialToCommissionLatestUpdatedAt,
} from '../reducers/material-to-commission.reducer';


const selectState = createFeatureSelector<MaterialToCommissionState>(MATERIAL_TO_COMMISSION_KEY);

export const getMaterialToCommissionDictionary = createSelector(selectState, selectMaterialToCommissionEntities);
export const getMaterialToCommission = createSelector(selectState, selectAllMaterialToCommission);
export const getMaterialToCommissionFetched = createSelector(selectState, selectMaterialToCommissionFetched);
export const getMaterialToCommissionLatestUpdatedAt = createSelector(selectState, selectMaterialToCommissionLatestUpdatedAt);

export const getMaterialToCommissionById = (props: { id: number }) => createSelector(getMaterialToCommissionDictionary, (dictionary) => props?.id && dictionary[props.id]);
