import {Scan2DaveEmailType} from '../graphql-types';

export class Scan2DaveEmailEntity {
    public static readonly GqlFields = 'id email partner_id';

    public Id: number;
    public Email: string;
    public PartnerId: number;


    constructor(scan2DaveEmailType?: Scan2DaveEmailType | null) {
        if (
            scan2DaveEmailType &&
            scan2DaveEmailType.id != null &&
            scan2DaveEmailType.email != null
        ) {
            this.Id = scan2DaveEmailType.id;
            this.Email = scan2DaveEmailType.email;
        } else {
            console.error('Received broken Scan2DaveEmailType', scan2DaveEmailType);
            throw Error();
        }
    }
}
