import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PendingChangesGuard } from '../dave-data-module/guards/pending-changes.guard';
import { ValidTokenGuard } from '../dave-data-module/guards/valid-token.guard';
import { DocumentEditorDialogComponent } from './components/document-editor-dialog/document-editor-dialog.component';

const routes: Routes = [
    {
        path: ':documentId',
        data: { breadcrumb: 'documentId' },
        component: DocumentEditorDialogComponent,
        canActivate: [ValidTokenGuard],
        canActivateChild: [ValidTokenGuard],
        canDeactivate: [PendingChangesGuard],
        resolve: {
            ...DocumentEditorDialogComponent.RequiredResolvers,
        },
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class DaveDocumentEditorRoutingModule {}
