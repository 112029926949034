import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { DamageFlowTypeEntity } from '../entities/damageFlowType.entity';
import { State } from '../State';
import { getDamageFlowTypes } from '../State/selectors/damageFlowType.selectors';
import { DamageFlowTypeActionTypes } from '../State/actions/damageFlowType.actions';

@Injectable({
  providedIn: 'root'
})
export class DamageFlowTypeResolver implements Resolve<DamageFlowTypeEntity[]> {
  constructor(private store: Store<State>) {
  }

  resolve() {
    const value$ = this.store.select(getDamageFlowTypes);

    value$
      .pipe(
        filter(value => !value),
        take(1),
        tap(() => {
          if (localStorage.getItem('token') != null) {
            this.store.dispatch(DamageFlowTypeActionTypes.GetDamageFlowTypeRequest());
          }
        })
      )
      .subscribe();

    return value$.pipe(
      filter(value => !!value),
      take(1)
    );
  }
}
