import { CommonModule } from '@angular/common';
import { Component,ElementRef,HostBinding,Input,OnDestroy,Optional,Self } from "@angular/core";
import { ControlValueAccessor,FormControl,NgControl,NG_VALUE_ACCESSOR,ReactiveFormsModule } from "@angular/forms";
import { MatFormField, MatFormFieldControl } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { Subject,Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { isNotNullOrUndefined } from "../helper/helper";

@Component({
    selector: 'app-number-transform-input',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatInputModule],
    templateUrl: './number-transform-input.component.html',
    styleUrls: ['./number-transform-input.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: NumberTransformInputComponent },
        // {
        //     provide: NG_VALUE_ACCESSOR,
        //     useExisting: NumberTransformInputComponent,
        //     multi: true,
        // },
    ],
})
export class NumberTransformInputComponent implements OnDestroy, ControlValueAccessor , MatFormFieldControl<number> {
    static nextId = 0;
    stateChanges = new Subject<void>();
    numberForm = new FormControl<number>(null);

    focused = false;
    touched = false;
    onTouched = () => {};
    subs: Subscription[] = [];

    @HostBinding() id = `app-number-transform-input-${NumberTransformInputComponent.nextId++}`;
    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }
    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }
    private _placeholder: string;
    @Input() factor = 1;
    get value(): number | null {
        if (this.numberForm.value !== null) {
            return this.numberForm.value * this.factor;
        }
        return null;
    }
    set value(val: number | null) {
        this.numberForm.setValue(val != null ? val / this.factor : null);
        this.stateChanges.next();
    }
    constructor(@Optional() @Self() public ngControl: NgControl, @Optional() public parentFormField: MatFormField, private _elementRef: ElementRef<HTMLElement>) {
        // Replace the provider from above with this.
        console.log(this.parentFormField)
        console.log(this.ngControl)
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }
    writeValue(val: number | null): void {
        this.value = val;
    }

    registerOnChange(fn: any): void {
        const sub = this.numberForm.valueChanges.pipe(map(() => this.value)).subscribe(fn);
        this.subs.push(sub);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this.subs.forEach((s) => s.unsubscribe());
    }


    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }
    get empty() {
        return !isNotNullOrUndefined(this.numberForm.value);
    }
    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.numberForm.disable() : this.numberForm.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    get errorState(): boolean {
        return (this.numberForm.invalid || this.ngControl.invalid) && this.touched;
    }
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('aria-describedby') userAriaDescribedBy: string;
    setDescribedByIds(ids: string[]) {
        const controlElement = this._elementRef.nativeElement.querySelector('input')!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            this._elementRef.nativeElement.querySelector('input').focus();
        }
    }
}
