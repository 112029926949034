import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Store} from '@ngrx/store';
import {filter, take, tap} from 'rxjs/operators';
import { State } from '../State';
import { CustomerTypeEntity } from '../entities/customer-type.entity';
import { ResolverLoadCustomerTypes } from '../State/actions/resolver.actions';

@Injectable({
    providedIn: 'root'
})
export class CustomerTypeResolver implements Resolve<CustomerTypeEntity[]> {
    constructor(private store: Store<State>) {}

    resolve() {
        const value$ = this.store.select(state => state.customers.customerTypes);

        value$
            .pipe(
                filter(value => !value),
                take(1),
                tap(() => {
                    if (localStorage.getItem('token') != null) {
                        this.store.dispatch(new ResolverLoadCustomerTypes());
                    }
                })
            )
            .subscribe();

        return value$.pipe(
            filter(value => !!value),
            take(1)
        );
    }
}
