import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import moment from 'moment/moment';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FolderUserEntity } from '../../../dave-data-module/entities/folder-user.entity';
import { FolderEntity } from '../../../dave-data-module/entities/folder.entity';
import { UserEntity } from '../../../dave-data-module/entities/user.entity';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { State } from '../../../dave-data-module/State';
import { FolderUserActionTypes } from '../../../dave-data-module/State/actions/folder-user';
import { FolderActionTypes } from '../../../dave-data-module/State/actions/folder.actions';
import { getUsers } from '../../../dave-data-module/State/selectors/users.selectors';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { EditFolderUserComponent, EditFolderUserComponentDialogData } from '../edit-folder-user/edit-folder-user.component';

export interface ViewFolderComponentDialogData {
    FolderId: number;
}

interface UserElement {
    user: UserEntity;
    edit: boolean;
    inherited: boolean;
    folderUsers: FolderUserEntity[];
}

@Component({
    selector: 'app-view-folder',
    templateUrl: './view-folder.component.html',
    styleUrls: ['./view-folder.component.scss'],
})
export class ViewFolderComponent implements OnDestroy {
    public UserTableData: BehaviorSubject<MatTableDataSource<UserElement>> = new BehaviorSubject<MatTableDataSource<UserElement>>(new MatTableDataSource<UserElement>([]));
    public Folder$: BehaviorSubject<FolderEntity> = new BehaviorSubject<FolderEntity>(null);
    public Editing: boolean;
    public HasEditPermissions = false;
    public UsersLoading = false;
    protected UsersLoadingSend = false;
    public FolderForm = this.fb.group({
        Name: new UntypedFormControl(null),
    }) as FormGroupTyped<{
        Name: string | null;
    }>;
    public Details: Observable<IDetailListTemplateData> = combineLatest([this.Folder$.pipe(filter(isNotNullOrUndefined))]).pipe(
        map(([folder]) => ({
            Properties: [
                {
                    key: 'Name',
                    value: folder.Name,
                    formControl: this.FolderForm.controls.Name,
                },
                {
                    key: 'Änderungsdatum',
                    value: moment(folder.UpdatedAt).format('DD.MM.YYYY hh:mm:ss'),
                    hideFormControl: true,
                },
                {
                    key: 'Erstellungsdatum',
                    value: moment(folder.CreatedAt).format('DD.MM.YYYY hh:mm:ss'),
                    hideFormControl: true,
                },
            ],
        })),
    );

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public Data: ViewFolderComponentDialogData,
        public DialogRef: MatDialogRef<ViewFolderComponent>,
        protected store: Store<State>,
        private fb: UntypedFormBuilder,
        private dialog: MatDialog,
        folderDataService: FolderDataService,
    ) {
        this.subscriptions.push(
            folderDataService.getFolderById(this.Data.FolderId).subscribe((f) => {
                if (f) {
                    if (this.Folder$.getValue()?.Users && !f.Users) {
                        this.store.dispatch(FolderUserActionTypes.GetFolderUser({ Payload: f.Id }));
                    } else {
                        this.Folder$.next(f);
                    }
                }
            }),
            this.Folder$.subscribe((f) => {
                this.FolderForm.controls.Name.setValue(f.Name);
            }),
            combineLatest([this.Folder$.pipe(filter(isNotNullOrUndefined)), this.store.select(getUsers).pipe(filter(isNotNullOrUndefined))]).subscribe(([folder, users]) => {
                const uFu = new Map<string, FolderUserEntity[]>();
                this.HasEditPermissions = folder.CanEdit;
                if (folder.Users === null) {
                    this.UsersLoading = true;
                    if (!this.UsersLoadingSend) {
                        this.UsersLoadingSend = true;
                        this.store.dispatch(FolderUserActionTypes.GetFolderUser({ Payload: folder.Id }));
                    }
                } else {
                    this.UsersLoading = false;
                    folder.Users.slice().forEach((fu) => {
                        const k = fu.UserId + '|' + (fu.Inherited ? '1' : '0') + '|' + (fu.Edit ? '1' : '0');
                        if (uFu.has(k)) {
                            const u = uFu.get(k);
                            u.push(fu);
                            uFu.set(k, u);
                        } else {
                            uFu.set(k, [fu]);
                        }
                    });
                }
                const out: UserElement[] = [];

                uFu.forEach((v, k) => {
                    const parts = k.split('|');
                    let user = users.find((u) => u.Id.toString() === parts[0]);
                    //ignore deleted users
                    if (user) {
                        if (!user.Deleted) {
                            out.push({
                                user: user,
                                edit: parts[2] === '1',
                                inherited: parts[1] === '1',
                                folderUsers: v,
                            });
                        }
                    }
                });

                this.UserTableData.next(
                    new MatTableDataSource<UserElement>(
                        out.sort((fu1, fu2) => {
                            if (fu1.inherited === fu2.inherited) {
                                return fu1.user?.Id > fu2.user?.Id ? 1 : -1;
                            } else {
                                return fu1.inherited ? 1 : -1;
                            }
                        }),
                    ),
                );
            }),
        );
    }

    Save() {
        this.Editing = false;
        this.store.dispatch(
            FolderActionTypes.ModifyFolder({
                Payload: {
                    id: this.Folder$.getValue().Id,
                    name: this.FolderForm.value.Name,
                },
            }),
        );
    }

    Edit() {
        this.Editing = true;
    }

    NewFolderUser() {
        this.dialog.open<EditFolderUserComponent, EditFolderUserComponentDialogData>(EditFolderUserComponent, {
            data: {
                FolderId: this.Folder$.getValue().Id,
            } as EditFolderUserComponentDialogData,
            minWidth: '25rem',
            width: '30vw',
            maxWidth: '90vw',
        });
    }

    ViewFolderUser(fu: FolderUserEntity[]) {
        if (fu.length === 1 && !fu[0].Inherited) {
            this.dialog.open<EditFolderUserComponent, EditFolderUserComponentDialogData>(EditFolderUserComponent, {
                data: {
                    FolderUser: fu[0],
                    FolderId: this.Folder$.getValue().Id,
                } as EditFolderUserComponentDialogData,
                minWidth: '25rem',
                width: '30vw',
                maxWidth: '90vw',
            });
        } else {
            this.dialog.open<EditFolderUserComponent, EditFolderUserComponentDialogData>(EditFolderUserComponent, {
                data: {
                    FolderUsers: fu,
                    FolderId: this.Folder$.getValue().Id,
                } as EditFolderUserComponentDialogData,
                minWidth: '25rem',
                width: '30vw',
                maxWidth: '90vw',
            });
        }
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
