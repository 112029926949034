import { createAction, props } from '@ngrx/store';
import { ConstructionDiaryEntity } from '../../entities/construction-diary.entity';
import {
    DaveMutationChangeConstructionDiaryArgs,
    DaveMutationCreateConstructionDiaryArgs,
    DaveMutationDeleteConstructionDiaryArgs,
    QueryConstructionDiaryArgs,
} from '../../graphql-types';

export const ConstructionDiaryActionTypes = {
    Load: createAction('data/constructionDiary/getAll', props<{ Payload?: QueryConstructionDiaryArgs }>()),
    Delete: createAction('data/constructionDiary/delete', props<{ Payload: DaveMutationDeleteConstructionDiaryArgs }>()),
    Modify: createAction('data/constructionDiary/change', props<{ Payload: DaveMutationChangeConstructionDiaryArgs }>()),
    Add: createAction('data/constructionDiary/add', props<{ Payload: DaveMutationCreateConstructionDiaryArgs }>()), // todo
    UpdateAll: createAction('data/constructionDiary/updateAll', props<{ Payload: ConstructionDiaryEntity[]; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/constructionDiary/updateMany', props<{ Payload: ConstructionDiaryEntity[]; updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction('data/constructionDiary/updateOne', props<{ Payload: ConstructionDiaryEntity }>()),
};
