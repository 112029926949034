import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';

import { MatCardModule } from '@angular/material/card';

import { MatDialogModule } from '@angular/material/dialog';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatListModule } from '@angular/material/list';

import { MatSelectModule } from '@angular/material/select';

import { MatTooltipModule } from '@angular/material/tooltip';

import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SimplebarAngularModule } from 'simplebar-angular';

import { DaveListCardModule } from '../../../dave-list-card/dave-list-card.module';

import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';

import { NewReportDialogComponent } from './new-report-dialog.component';







































































@NgModule({
    imports: [CommonModule, AppButtonModule, MatDialogModule, MatTooltipModule, MatFormFieldModule, MatSelectModule, MatListModule, SimplebarAngularModule, FontAwesomeModule, RouterModule, MatCardModule, DaveListCardModule],
    declarations: [NewReportDialogComponent],
    exports: [NewReportDialogComponent],
})
export class NewReportDialogModule {}
