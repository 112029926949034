export const TranslationObject = {
    de: {
        calendar: {
            today: 'heute',
        },
        schedule: {
            // "day": "Day",
            // "week": "Week",
            // "workWeek": "Work Week",
            // "month": "Month",
            // "year": "Year",
            // "agenda": "Agenda",
            // "weekAgenda": "Week Agenda",
            // "workWeekAgenda": "Work Week Agenda",
            // "monthAgenda": "Month Agenda",
            today: 'heute',
            // "noEvents": "No events",
            // "emptyContainer": "There are no events scheduled on this day.",
            allDay: 'ganztägig',
            // "start": "Start",
            // "end": "End",
            // "more": "more",
            // "close": "Close",
            // "cancel": "Cancel",
            // "noTitle": "(No Title)",
            // "delete": "Delete",
            // "deleteEvent": "This Event",
            // "deleteMultipleEvent": "Delete Multiple Events",
            // "selectedItems": "Items selected",
            // "deleteSeries": "Entire Series",
            // "edit": "Edit",
            // "editSeries": "Entire Series",
            // "editEvent": "This Event",
            // "createEvent": "Create",
            // "subject": "Subject",
            // "addTitle": "Add title",
            // "moreDetails": "More Details",
            // "save": "Save",
            // "editContent": "How would you like to change the appointment in the series?",
            // "deleteContent": "Are you sure you want to delete this event?",
            // "deleteMultipleContent": "Are you sure you want to delete the selected events?",
            // "newEvent": "New Event",
            // "title": "Title",
            // "location": "Location",
            // "description": "Description",
            // "timezone": "Timezone",
            // "startTimezone": "Start Timezone",
            // "endTimezone": "End Timezone",
            // "repeat": "Repeat",
            // "saveButton": "Save",
            // "cancelButton": "Cancel",
            // "deleteButton": "Delete",
            // "recurrence": "Recurrence",
            // "wrongPattern": "The recurrence pattern is not valid.",
            // "seriesChangeAlert": "Do you want to cancel the changes made to specific instances of this series and match it to the whole series again?",
            // "createError": "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
            // "sameDayAlert": "Two occurrences of the same event cannot occur on the same day.",
            // "editRecurrence": "Edit Recurrence",
            // "repeats": "Repeats",
            // "alert": "Alert",
            // "startEndError": "The selected end date occurs before the start date.",
            // "invalidDateError": "The entered date value is invalid.",
            // "blockAlert": "Events cannot be scheduled within the blocked time range.",
            // "ok": "Ok",
            // "yes": "Yes",
            // "no": "No",
            // "occurrence": "Occurrence",
            // "series": "Series",
            // "previous": "Previous",
            // "next": "Next",
            // "timelineDay": "Timeline Day",
            // "timelineWeek": "Timeline Week",
            // "timelineWorkWeek": "Timeline Work Week",
            // "timelineMonth": "Timeline Month",
            // "timelineYear": "Timeline Year",
            // "editFollowingEvent": "Following Events",
            // "deleteTitle": "Delete Event",
            // "editTitle": "Edit Event",
            // "beginFrom": "Begin From",
            // "endAt": "End At",
            // "expandAllDaySection": "Expand-all-day-section",
            // "collapseAllDaySection": "Collapse-all-day-section",
        },
        DocumentEditor: {
            Table: 'Tabelle',
            Row: 'Reihe',
            Cell: 'Zelle',
            Ok: 'In Ordnung',
            Cancel: 'Abbrechen',
            Size: 'Grösse',
            'Preferred Width': 'Bevorzugte Breite',
            Points: 'Punkte',
            Percent: 'Prozent',
            'Measure in': 'Messen Sie in',
            Alignment: 'Ausrichtung',
            Left: 'Links',
            Center: 'Zentriert',
            Right: 'Rechts',
            Justify: 'Rechtfertigen',
            'Indent from left': 'Einzug von links',
            'Borders and Shading': 'Rahmen und Schattierung',
            Options: 'Optionen',
            'Specify height': 'Geben Sie die Höhe an',
            'At least': 'Mindestens',
            Exactly: 'Genau',
            'Row height is': 'Reihenhöhe ist',
            'Allow row to break across pages': 'Zeilenumbruch über Seiten zulassen',
            'Repeat as header row at the top of each page': 'Wiederholen Sie dies als Kopfzeile oben auf jeder Seite',
            'Vertical alignment': 'Vertikale Ausrichtung',
            Top: 'oben',
            Bottom: 'Unterseite',
            'Default cell margins': 'Standard-Zellenränder',
            'Default cell spacing': 'Standardzellenabstand',
            'Allow spacing between cells': 'Lassen Sie den Abstand zwischen den Zellen zu',
            'Cell margins': 'Zellränder',
            'Same as the whole table': 'Gleich wie der ganze Tisch',
            Borders: 'Grenzen',
            None: 'Keiner',
            Style: 'Stil',
            Width: 'Breite',
            Height: 'Höhe',
            Letter: 'Brief',
            Tabloid: 'Tabloid',
            Legal: 'Legal',
            Statement: 'Erklärung',
            Executive: 'Executive',
            A3: 'A3',
            A4: 'A4',
            A5: 'A5',
            B4: 'B4',
            B5: 'B5',
            'Custom Size': 'Benutzerdefiniertes Format',
            'Different odd and even': 'Unterschiedlich ungerade und gerade',
            'Different first page': 'Andere erste Seite',
            'From edge': 'Vom Rand',
            Header: 'Header',
            Footer: 'Fusszeile',
            Margin: 'Ränder',
            Paper: 'Papier',
            Layout: 'Layout',
            Orientation: 'Orientierung',
            Landscape: 'Landschaft',
            Portrait: 'Porträt',
            'Table Of Contents': 'Inhaltsverzeichnis',
            'Show page numbers': 'Seitenzahlen anzeigen',
            'Right align page numbers': 'Seitenzahlen nach rechts ausrichten',
            Nothing: 'Nichts',
            'Tab leader': 'Tab Leader',
            'Show levels': 'Ebenen anzeigen',
            'Use hyperlinks instead of page numbers': 'Verwenden Sie Hyperlinks anstelle von Seitenzahlen',
            'Build table of contents from': 'Erstellen Sie das Inhaltsverzeichnis von',
            Styles: 'Stile',
            'Available styles': 'Verfügbare Stile',
            'TOC level': 'TOC-Ebene',
            Heading: 'Überschrift',
            'List Paragraph': 'List Paragraph',
            Normal: 'Normal',
            'Outline levels': 'Gliederungsebenen',
            'Table entry fields': 'Tabelleneingabefelder',
            Modify: 'Ändern',
            Color: 'Farbe',
            Setting: 'Rahmen',
            Box: 'Box',
            All: 'Alle',
            Custom: 'Benutzerdefiniert',
            Preview: 'Vorschau',
            Shading: 'Schattierung',
            Fill: 'Füllen',
            'Apply To': 'Gelten',
            'Table Properties': 'Tabelleneigenschaften',
            'Cell Options': 'Zellenoptionen',
            'Table Options': 'Tabellenoptionen',
            'Insert Table': 'Tabelle einfügen',
            'Number of columns': 'Anzahl der Spalten',
            'Number of rows': 'Zeilenanzahl',
            'Text to display': 'Anzuzeigender Text',
            Address: 'Adresse',
            'Insert Hyperlink': 'Hyperlink einfügen',
            'Edit Hyperlink': 'Hyperlink bearbeiten',
            Insert: 'Einfügen',
            General: 'Allgemeines',
            Indentation: 'Vertiefung',
            'Before text': 'Vor dem Text',
            Special: 'Besondere',
            'First line': 'Erste Linie',
            Hanging: 'Hängend',
            'After text': 'Nach dem Text',
            By: 'Durch',
            Before: 'Vor',
            'Line Spacing': 'Zeilenabstand',
            After: 'Nach',
            At: 'Beim',
            Multiple: 'Mehrere',
            Spacing: 'Abstand',
            'Define new Multilevel list': 'Neue Mehrebenenliste definieren',
            'List level': 'Listenebene',
            'Choose level to modify': 'Wählen Sie die zu ändernde Ebene',
            Level: 'Niveau',
            'Number format': 'Zahlenformat',
            'Number style for this level': 'Zahlenstil für diese Ebene',
            'Enter formatting for number': 'Geben Sie die Formatierung für die Nummer ein',
            'Start at': 'Beginne bei',
            'Restart list after': 'Liste danach neu starten',
            Position: 'Position',
            'Text indent at': 'Text einrücken um',
            'Aligned at': 'Ausgerichtet um',
            'Follow number with': 'Folgen Sie der Nummer mit',
            'Tab character': 'Tabulatorzeichen',
            Space: 'Platz',
            Arabic: 'Arabisch',
            UpRoman: 'UpRoman',
            LowRoman: 'LowRoman',
            UpLetter: 'UpLetter',
            LowLetter: 'LowLetter',
            Number: 'Nummer',
            'Leading zero': 'Führende Null',
            Bullet: 'Kugel',
            Ordinal: 'Ordinal',
            'Ordinal Text': 'Ordinaler Text',
            'For East': 'Für den Osten',
            'No Restart': 'Kein Neustart',
            Font: 'Schriftart',
            'Font style': 'Schriftstil',
            'Underline style': 'Unterstreichen Sie Stil',
            'Font color': 'Schriftfarbe',
            Effects: 'Auswirkungen',
            Strikethrough: 'Durchgestrichen',
            Superscript: 'Hochgestellt',
            Subscript: 'Tiefgestellt',
            'Double strikethrough': 'Doppelt durchgestrichen',
            Regular: 'Normal',
            Bold: 'Fett gedruckt',
            Italic: 'Kursiv',
            Cut: 'Ausschneiden',
            Copy: 'Kopieren',
            Paste: 'Einfügen',
            Hyperlink: 'Hyperlink',
            'Open Hyperlink': 'Öffnen Sie den Hyperlink',
            'Copy Hyperlink': 'Hyperlink kopieren',
            'Remove Hyperlink': 'Hyperlink entfernen',
            Paragraph: 'Absatz',
            'Merge Cells': 'Zellen verbinden',
            'Insert Above': 'Oben einfügen',
            'Insert Below': 'Unten einfügen',
            'Insert Left': 'Links einfügen',
            'Insert Right': 'Rechts einfügen',
            Delete: 'Löschen',
            'Delete Table': 'Tabelle löschen',
            'Delete Row': 'Zeile löschen',
            'Delete Column': 'Spalte löschen',
            'File Name': 'Dateiname',
            'Format Type': 'Formattyp',
            Save: 'Speichern',
            Navigation: 'Navigation',
            Results: 'Ergebnisse',
            Replace: 'Ersetzen',
            'Replace All': 'Alles ersetzen',
            'We replaced all': 'Wir haben alle ersetzt',
            Find: 'Finden',
            'No matches': 'Keine Treffer',
            'All Done': 'Alles erledigt',
            Result: 'Ergebnis',
            of: 'von',
            instances: 'Instanzen',
            with: 'mit',
            'Click to follow link': 'Klicken Sie hier, um dem Link zu folgen',
            'Continue Numbering': 'Nummerierung fortsetzen',
            'Bookmark name': 'Name des Lesezeichens',
            Close: 'Schliessen',
            'Restart At': 'Neustart um',
            Properties: 'Eigenschaften',
            Name: 'Name',
            'Style type': 'Artart',
            'Style based on': 'Stil basiert auf',
            'Style for following paragraph': 'Stil für folgenden Absatz',
            Formatting: 'Formatierung',
            'Numbering and Bullets': 'Nummerierung und Aufzählungszeichen',
            Numbering: 'Nummerierung',
            'Update Field': 'Feld aktualisieren',
            'Edit Field': 'Feld bearbeiten',
            Bookmark: 'Lesezeichen',
            'Page Setup': 'Seiteneinrichtung',
            'No bookmarks found': 'Keine Lesezeichen gefunden',
            'Number format tooltip information':
                'Einstufiges Zahlenformat: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Zum Beispiel Kapitel% 1. Nummerierung wie in </br> Kapitel 1. Element </br> Kapitel 2. Element </br> ... </br> Kapitel N. Element </br> </br> Mehrstufiges Zahlenformat: </ br> > [PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Zum Beispiel% 1.% 2. zeigt die Nummerierung wie </br> 1.1 an. Punkt 1.2. Artikel </br>… </br> 1.N. Artikel',
            Format: 'Format',
            'Create New Style': 'Neuen Stil erstellen',
            'Modify Style': 'Stil ändern',
            New: 'Neu',
            Bullets: 'Aufzählungszeichen',
            'Use bookmarks': 'Verwenden Sie Lesezeichen',
            'Table of Contents': 'Inhaltsverzeichnis',
        },
        documenteditor: {
            Table: 'Tabelle',
            Row: 'Reihe',
            Cell: 'Zelle',
            Ok: 'In Ordnung',
            Cancel: 'Abbrechen',
            Size: 'Grösse',
            'Preferred Width': 'Bevorzugte Breite',
            Points: 'Punkte',
            Percent: 'Prozent',
            'Measure in': 'Messen Sie in',
            Alignment: 'Ausrichtung',
            Left: 'Links',
            Center: 'Zentriert',
            Right: 'Rechts',
            Justify: 'Rechtfertigen',
            'Indent from left': 'Einzug von links',
            'Borders and Shading': 'Rahmen und Schattierung',
            Options: 'Optionen',
            'Specify height': 'Geben Sie die Höhe an',
            'At least': 'Mindestens',
            Exactly: 'Genau',
            'Row height is': 'Reihenhöhe ist',
            'Allow row to break across pages': 'Zeilenumbruch über Seiten zulassen',
            'Repeat as header row at the top of each page': 'Wiederholen Sie dies als Kopfzeile oben auf jeder Seite',
            'Vertical alignment': 'Vertikale Ausrichtung',
            Top: 'oben',
            Bottom: 'Unterseite',
            'Default cell margins': 'Standard-Zellenränder',
            'Default cell spacing': 'Standardzellenabstand',
            'Allow spacing between cells': 'Lassen Sie den Abstand zwischen den Zellen zu',
            'Cell margins': 'Zellränder',
            'Same as the whole table': 'Gleich wie der ganze Tisch',
            Borders: 'Grenzen',
            None: 'Keiner',
            Style: 'Stil',
            Width: 'Breite',
            Height: 'Höhe',
            Letter: 'Brief',
            Tabloid: 'Tabloid',
            Legal: 'Legal',
            Statement: 'Erklärung',
            Executive: 'Executive',
            A3: 'A3',
            A4: 'A4',
            A5: 'A5',
            B4: 'B4',
            B5: 'B5',
            'Custom Size': 'Benutzerdefiniertes Format',
            'Different odd and even': 'Unterschiedlich ungerade und gerade',
            'Different first page': 'Andere erste Seite',
            'From edge': 'Vom Rand',
            Header: 'Header',
            Footer: 'Fusszeile',
            Margin: 'Ränder',
            Paper: 'Papier',
            Layout: 'Layout',
            Orientation: 'Orientierung',
            Landscape: 'Landschaft',
            Portrait: 'Porträt',
            'Show page numbers': 'Seitenzahlen anzeigen',
            'Right align page numbers': 'Seitenzahlen nach rechts ausrichten',
            Nothing: 'Nichts',
            'Tab leader': 'Tab Leader',
            'Show levels': 'Ebenen anzeigen',
            'Use hyperlinks instead of page numbers': 'Verwenden Sie Hyperlinks anstelle von Seitenzahlen',
            'Build table of contents from': 'Erstellen Sie das Inhaltsverzeichnis von',
            Styles: 'Stile',
            'Available styles': 'Verfügbare Stile',
            'TOC level': 'TOC-Ebene',
            Heading: 'Überschrift',
            'Heading 1': 'Überschrift 1',
            'Heading 2': 'Überschrift 2',
            'Heading 3': 'Überschrift 3',
            'Heading 4': 'Überschrift 4',
            'Heading 5': 'Überschrift 5',
            'Heading 6': 'Überschrift 6',
            'List Paragraph': 'List Paragraph',
            Normal: 'Normal',
            'Outline levels': 'Gliederungsebenen',
            'Table entry fields': 'Tabelleneingabefelder',
            Modify: 'Ändern',
            Color: 'Farbe',
            Setting: 'Rahmen',
            Box: 'Box',
            All: 'Alle',
            Custom: 'Benutzerdefiniert',
            Preview: 'Vorschau',
            Shading: 'Schattierung',
            Fill: 'Füllen',
            'Apply To': 'Gelten',
            'Table Properties': 'Tabelleneigenschaften',
            'Cell Options': 'Zellenoptionen',
            'Table Options': 'Tabellenoptionen',
            'Insert Table': 'Tabelle einfügen',
            'Number of columns': 'Anzahl der Spalten',
            'Number of rows': 'Zeilenanzahl',
            'Text to display': 'Anzuzeigender Text',
            Address: 'Adresse',
            'Insert Hyperlink': 'Hyperlink einfügen',
            'Edit Hyperlink': 'Hyperlink bearbeiten',
            Insert: 'Einfügen',
            General: 'Allgemeines',
            Indentation: 'Vertiefung',
            'Before text': 'Vor dem Text',
            Special: 'Besondere',
            'First line': 'Erste Linie',
            Hanging: 'Hängend',
            'After text': 'Nach dem Text',
            By: 'Durch',
            Before: 'Vor',
            'Line Spacing': 'Zeilenabstand',
            After: 'Nach',
            At: 'Beim',
            Multiple: 'Mehrere',
            Spacing: 'Abstand',
            'Define new Multilevel list': 'Neue Mehrebenenliste definieren',
            'List level': 'Listenebene',
            'Choose level to modify': 'Wählen Sie die zu ändernde Ebene',
            Level: 'Niveau',
            'Number format': 'Zahlenformat',
            'Number style for this level': 'Zahlenstil für diese Ebene',
            'Enter formatting for number': 'Geben Sie die Formatierung für die Nummer ein',
            'Start at': 'Beginne bei',
            'Restart list after': 'Liste danach neu starten',
            Position: 'Position',
            'Text indent at': 'Text einrücken um',
            'Aligned at': 'Ausgerichtet um',
            'Follow number with': 'Folgen Sie der Nummer mit',
            'Tab character': 'Tabulatorzeichen',
            Space: 'Platz',
            Arabic: 'Arabisch',
            UpRoman: 'UpRoman',
            LowRoman: 'LowRoman',
            UpLetter: 'UpLetter',
            LowLetter: 'LowLetter',
            Number: 'Nummer',
            'Leading zero': 'Führende Null',
            Bullet: 'Kugel',
            Ordinal: 'Ordinal',
            'Ordinal Text': 'Ordinaler Text',
            'For East': 'Für den Osten',
            'No Restart': 'Kein Neustart',
            Font: 'Schriftart',
            'Font style': 'Schriftstil',
            'Underline style': 'Unterstreichen Sie Stil',
            'Font color': 'Schriftfarbe',
            Effects: 'Auswirkungen',
            Strikethrough: 'Durchgestrichen',
            Superscript: 'Hochgestellt',
            Subscript: 'Tiefgestellt',
            'Double strikethrough': 'Doppelt durchgestrichen',
            Regular: 'Normal',
            Bold: 'Fett',
            Italic: 'Kursiv',
            Cut: 'Ausschneiden',
            Copy: 'Kopieren',
            Paste: 'Einfügen',
            Hyperlink: 'Hyperlink',
            'Open Hyperlink': 'Öffnen Sie den Hyperlink',
            'Copy Hyperlink': 'Hyperlink kopieren',
            'Remove Hyperlink': 'Hyperlink entfernen',
            Paragraph: 'Absatz',
            'Linked Style': 'Verknüpft (Absatz und Zeichen)',
            Character: 'Charakter',
            'Merge Cells': 'Zellen verbinden',
            'Insert Above': 'Oben einfügen',
            'Insert Below': 'Unten einfügen',
            'Insert Left': 'Links einfügen',
            'Insert Right': 'Rechts einfügen',
            Delete: 'Löschen',
            'Delete Table': 'Tabelle löschen',
            'Delete Row': 'Zeile löschen',
            'Delete Column': 'Spalte löschen',
            'File Name': 'Dateiname',
            'Format Type': 'Formattyp',
            Save: 'Speichern',
            Navigation: 'Navigation',
            Results: 'Ergebnisse',
            Replace: 'Ersetzen',
            'Replace All': 'Alles ersetzen',
            'We replaced all': 'Wir haben alle ersetzt',
            Find: 'Finden',
            'No matches': 'Keine Treffer',
            'All Done': 'Alles erledigt',
            Result: 'Ergebnis',
            of: 'von',
            instances: 'Instanzen',
            with: 'mit',
            'Click to follow link': 'Klicken Sie hier, um dem Link zu folgen',
            'Continue Numbering': 'Nummerierung fortsetzen',
            'Bookmark name': 'Name des Lesezeichens',
            Close: 'Schliessen',
            'Restart At': 'Neustart um',
            Properties: 'Eigenschaften',
            Name: 'Name',
            'Style type': 'Artart',
            'Style based on': 'Stil basiert auf',
            'Style for following paragraph': 'Stil für folgenden Absatz',
            Formatting: 'Formatierung',
            'Numbering and Bullets': 'Nummerierung und Aufzählungszeichen',
            Numbering: 'Nummerierung',
            'Update Field': 'Feld aktualisieren',
            'Edit Field': 'Feld bearbeiten',
            Bookmark: 'Lesezeichen',
            'Page Setup': 'Seiteneinrichtung',
            'No bookmarks found': 'Keine Lesezeichen gefunden',
            'Number format tooltip information':
                'Einstufiges Zahlenformat: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Beispiel: "Kapitel% 1". Nummerierung wie in </br> Kapitel 1. Element </br> Kapitel 2. Element </br>… </br> Kapitel N. Element </br> </br> Mehrstufiges Zahlenformat: </br> [ PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Zum Beispiel \'% 1.% 2\'. zeigt die Nummerierung wie </br> 1.1 an. Punkt 1.2. Artikel </br>… </br> 1.N. Artikel',
            Format: 'Format',
            'Create New Style': 'Neuen Stil erstellen',
            'Modify Style': 'Stil ändern',
            New: 'Neu',
            Bullets: 'Aufzählungszeichen',
            'Use bookmarks': 'Verwenden Sie Lesezeichen',
            'Table of Contents': 'Inhaltsverzeichnis',
            AutoFit: 'AutoFit',
            'AutoFit to Contents': 'AutoFit to Contents',
            'AutoFit to Window': 'AutoFit to Window',
            'Fixed Column Width': 'Feste Spaltenbreite',
            Reset: 'Zurücksetzen',
            'Match case': 'Gross- / Kleinschreibung',
            'Whole words': 'Ganze Wörter',
            Add: 'Hinzufügen',
            'Go To': 'Gehe zu',
            'Search for': 'Suchen nach',
            'Replace with': 'Ersetzen mit',
            'TOC 1': 'Inhaltsverzeichnis 1',
            'TOC 2': 'Inhaltsverzeichnis 2',
            'TOC 3': 'Inhaltsverzeichnis 3',
            'TOC 4': 'Inhaltsverzeichnis 4',
            'TOC 5': 'Inhaltsverzeichnis 5',
            'TOC 6': 'Inhaltsverzeichnis 6',
            'TOC 7': 'Inhaltsverzeichnis 7',
            'TOC 8': 'Inhaltsverzeichnis 8',
            'TOC 9': 'Inhaltsverzeichnis 9',
            'Right-to-left': 'Rechts nach links',
            'Left-to-right': 'Links nach rechts',
            Direction: 'Richtung',
            'Table direction': 'Tischrichtung',
            'Indent from right': 'Von rechts einrücken',
            'Contextual Spacing': 'Fügen Sie keinen Abstand zwischen den Absätzen der gleichen Stile ein',
            'Password Mismatch': 'Das Passwort stimmt nicht überein',
            'Restrict Editing': 'Bearbeitung einschränken',
            'Formatting restrictions': 'Formatierungsbeschränkungen',
            'Allow formatting': 'Formatierung zulassen',
            'Editing restrictions': 'Bearbeitungsbeschränkungen',
            'Read only': 'Schreibgeschützt',
            'Exceptions Optional': 'Ausnahmen (optional)',
            'Select Part Of Document And User': 'Wählen Sie Teile des Dokuments aus und wählen Sie Benutzer aus, die diese frei bearbeiten dürfen.',
            Everyone: 'Jeder',
            'More users': 'Weitere Benutzer',
            'Add Users': 'Benutzer hinzufügen',
            'Enforcing Protection': 'Ja, Schutz erzwingen',
            'Start Enforcing Protection': 'Starten Sie die Durchsetzung des Schutzes',
            'Enter User': 'Benutzer eingeben',
            Users: 'Benutzer',
            'Enter new password': 'Neues Passwort eingeben',
            'Reenter new password to confirm': 'Geben Sie das neue Passwort zur Bestätigung erneut ein',
            'Your permissions': 'Ihre Berechtigungen',
            'Protected Document': 'Dieses Dokument ist vor unbeabsichtigter Bearbeitung geschützt. Sie können in dieser Region Änderungen vornehmen.',
            FormFieldsOnly: 'Dieses Dokument ist vor unbeabsichtigter Bearbeitung geschützt. Sie dürfen nur Formulare in dieser Region ausfüllen.',
            'You may format text only with certain styles': 'Sie können Text nur mit bestimmten Stilen formatieren.',
            'Stop Protection': 'Stoppen Sie den Schutz',
            Password: 'Passwort',
            'Spelling Editor': 'Rechtschreibeditor',
            Spelling: 'Rechtschreibung',
            'Spell Check': 'Rechtschreibprüfung',
            'Underline errors': 'Fehler unterstreichen',
            Ignore: 'Ignorieren',
            'Ignore All': 'Alles ignorieren',
            'Add to Dictionary': 'Zum Wörterbuch hinzufügen',
            Change: 'Veränderung',
            'Change All': 'Ändere Alles',
            Suggestions: 'Vorschläge',
            'The password is incorrect': 'Das Passwort ist inkorrekt',
            'Error in establishing connection with web server': 'Fehler beim Herstellen der Verbindung mit dem Webserver',
            'Highlight the regions I can edit': 'Markieren Sie die Regionen, die ich bearbeiten kann',
            'Show All Regions I Can Edit': 'Alle Regionen anzeigen, die ich bearbeiten kann',
            'Find Next Region I Can Edit': 'Nächste Region suchen, die ich bearbeiten kann',
            'Keep source formatting': 'Quellformatierung beibehalten',
            'Match destination formatting': 'Zielformatierung anpassen',
            'Text only': 'Nur Text',
            Comments: 'Bemerkungen',
            'Type your comment': 'Geben Sie Ihren Kommentar ein',
            Post: 'Post',
            Reply: 'Antworten',
            'New Comment': 'Neuer Kommentar',
            Edit: 'Bearbeiten',
            Resolve: 'Entschlossenheit',
            Reopen: 'Wieder öffnen',
            'No comments in this document': 'Keine Kommentare in diesem Dokument',
            more: 'Mehr',
            'Type your comment here': 'Geben Sie hier Ihren Kommentar ein',
            'Next Comment': 'Nächster Kommentar',
            'Previous Comment': 'Vorheriger Kommentar',
            'Un-posted comments': 'Nicht veröffentlichte Kommentare',
            'Discard Comment': 'Hinzugefügt Kommentare nicht gepostet. Wenn Sie fortfahren, wird dieser Kommentar verworfen.',
            'No Headings': 'Keine Überschrift gefunden!',
            'Add Headings': 'Dieses Dokument hat keine Überschriften. Bitte fügen Sie Überschriften hinzu und versuchen Sie es erneut.',
            'More Options': 'Mehr Optionen',
            'Click to see this comment': 'Klicken Sie hier, um diesen Kommentar anzuzeigen',
            'Drop Down Form Field': 'Dropdown-Formularfeld',
            'Drop-down items': 'Dropdown-Elemente',
            'Items in drop-down list': 'Elemente in der Dropdown-Liste',
            ADD: 'HINZUFÜGEN',
            REMOVE: 'ENTFERNEN',
            'Field settings': 'Feldeinstellungen',
            Tooltip: 'Tooltip',
            'Drop-down enabled': 'Dropdown aktiviert',
            'Check Box Form Field': 'Kontrollkästchen Formularfeld',
            'Check box size': 'Kontrollkästchengröße',
            Auto: 'Auto',
            'Default value': 'Standardwert',
            'Not checked': 'Nicht geprüft',
            Checked: 'Überprüft',
            'Check box enabled': 'Kontrollkästchen aktiviert',
            'Text Form Field': 'Textformularfeld',
            Type: 'Art',
            'Default text': 'Standardtext',
            'Maximum length': 'Maximale Länge',
            'Text format': 'Textformat',
            'Fill-in enabled': 'Ausfüllen aktiviert',
            'Default number': 'Standardnummer',
            'Default date': 'Standarddatum',
            'Date format': 'Datumsformat',
            'Merge Track': 'Diese Aktion wird nicht als Änderung markiert. Wollen Sie fortfahren?',
            UnTrack: 'Kann nicht verfolgt werden',
            Accept: 'Akzeptieren',
            Reject: 'Ablehnen',
            'Previous Changes': 'Vorherige Änderungen',
            'Next Changes': 'Nächste Änderungen',
            Inserted: 'Eingefügt',
            Deleted: 'Gelöscht',
            Changes: 'Änderungen',
            'Accept all': 'Akzeptiere alle',
            'Reject all': 'Alles ablehnen',
            'No changes': 'Keine Änderungen',
            'Accept Changes': 'Änderungen akzeptieren',
            'Reject Changes': 'Änderungen ablehnen',
            User: 'Benutzer',
            View: 'Aussicht',
            Insertion: 'Einfügen',
            Deletion: 'Streichung',
            'All caps': 'Alle Kappen',
            'This region is locked by': 'Diese Region ist gesperrt durch',
            Lock: 'Sperren',
            Unlock: 'Freischalten',
            'Already locked': 'Ausgewählt oder ein Teil der Region ist bereits von einem anderen Benutzer gesperrt',
            '1, 2, 3, ...': '1, 2, 3, ...',
            'a, b, c, ...': 'a, b, c, ...',
            'A, B, C, ...': 'A, B, C, ...',
            'I, II, III, ...': 'I, II, III, ...',
            'i, ii, iii, ...': 'i, ii, iii, ...',
            'Text Form': 'Textform',
            'Check Box': 'Kontrollkästchen',
            DropDown: 'Dropdown-Liste',
            'Form Fields': 'Formularfelder',
            'Fillin enabled': 'Ausfüllen aktiviert',
            'Multiple Comment': 'Bitte posten Sie Ihren Kommentar',
            InsertFootnote: 'InsertFootnote',
            InsertEndnote: 'InsertEndnote',
            Footnote: 'Fußnote',
            Endnote: 'Endnote',
            'Click to View/Edit Footnote': 'Klicken Sie hier, um die Fußnote anzuzeigen / zu bearbeiten',
            'Click to View/Edit Endnote': 'Klicken Sie hier, um die Endnote anzuzeigen / zu bearbeiten',
            InsertAsRows: 'Als neue Zeilen einfügen',
            InsertAsColumns: 'Als neue Spalten einfügen',
            OverwriteCells: 'Zellen überschreiben',
            NestTable: 'Nesttisch',
            'No suggestions': 'Keine Vorschläge',
            'More Suggestion': 'Mehr Vorschlag',
            'Ignore Once': 'Einmal ignorieren',
            'Keep With Next': 'Einmal ignorieren',
            'Keep Lines Together': 'Weiter so',
            WidowControl: 'Halte die Linien zusammen',
            'Indents and Spacing': 'Witwen-/Waisenkontrolle',
            'Line and Page Breaks': 'Einzüge und Abstände',
            Pagination: 'Zeilen- und Seitenumbrüche',
            Single: 'Seitennummerierung',
            DashSmallGap: 'Einzel',
            DashDot: 'DashSmallGap',
            Double: 'Strich Punkt',
            ThinThickSmallGap: 'Doppelt',
            ThickThinSmallGap: 'DünnDickKleinGap',
            ThickThinMediumGap: 'DickDünnKleinGap',
            ThickThinLargeGap: 'DickDünnMittelLücke',
            SingleWavy: 'DickDünnLargeGap',
            DoubleWavy: 'SingleWellig',
            Inset: 'DoubleWavy',
            DashLargeGap: 'Einsatz',
            Dot: 'DashLargeGap',
            DashDotDot: 'Punkt',
            Triple: 'StrichDotDot',
            ThinThickThinSmallGap: 'Verdreifachen',
            ThinThickThinMediumGap: 'DünnDickDünnKleinLücke',
            ThinThickThinLargeGap: 'DünnDickDünnMittelLücke',
            DashDotStroked: 'ThinDickThinLargeGap',
            Engrave3D: 'StrichDotStroked',
            Thick: 'Gravieren3D',
            Outset: 'Dick',
            Emboss3D: 'Anfang',
            ThinThickLargeGap: 'Emboss3D',
            ThinThickMediumGap: 'ThinDickLargeGap',
            'Number of rows must be between 1 and 32767.': 'DünnDickMittelLücke',
            'Number of columns must be between 1 and 63.': 'Die Anzahl der Zeilen muss zwischen 1 und 32767 liegen.',
            'Ignore all': 'Alles ignorieren',
            'Table Of Contents': 'Inhaltsverzeichnis',
        },
        documenteditorcontainer: {
            New: 'Neu',
            Open: 'Öffnen',
            Undo: 'Rückgängig machen',
            Redo: 'Redo',
            Image: 'Bild',
            Table: 'Tabelle',
            Link: 'Verknüpfung',
            Bookmark: 'Lesezeichen',
            'Table of Contents': 'Inhaltsverzeichnis',
            'HEADING - - - - 1': 'ÜBERSCHRIFT 1',
            'HEADING - - - - 2': 'TITEL - - - - 2',
            'HEADING - - - - 3': 'TITEL - - - - 3',
            Header: 'Header',
            Footer: 'Fusszeile',
            'Page Setup': 'Seiteneinrichtung',
            'Page Number': 'Seitennummer',
            Break: 'Brechen',
            Find: 'Finden',
            'Local Clipboard': 'Lokale Zwischenablage',
            'Restrict Editing': 'Bearbeitung einschränken',
            'Upload from computer': 'Hochladen vom Computer',
            'By URL': 'Über die URL',
            'Page Break': 'Seitenumbruch',
            'Section Break': 'Abschnitt Pause',
            'Header And Footer': 'Kopfzeile Fußzeile',
            Options: 'Optionen',
            Levels: 'Ebenen',
            'Different First Page': 'Unterschiedliche erste Seite',
            'Different header and footer for odd and even pages': 'Unterschiedliche Kopf- und Fußzeilen für gerade und ungerade Seiten.',
            'Different Odd And Even Pages': 'Verschiedene gerade und ungerade Seiten',
            'Different header and footer for first page': 'Unterschiedliche Kopf- und Fußzeile für die erste Seite.',
            Position: 'Position',
            'Header from Top': 'Kopfzeile von oben',
            'Footer from Bottom': 'Fußzeile von unten',
            'Distance from top of the page to top of the header': 'Abstand vom oberen Rand der Seite zum oberen Rand der Kopfzeile.',
            'Distance from bottom of the page to bottom of the footer': 'Abstand vom unteren Rand der Seite zum unteren Rand der Fußzeile.',
            'Aspect ratio': 'Seitenverhältnis',
            W: 'W',
            H: 'H',
            Width: 'Breite',
            Height: 'Höhe',
            Text: 'Text',
            Paragraph: 'Absatz',
            Fill: 'Füllen',
            'Fill color': 'Füllfarbe',
            'Border Style': 'Rahmenstil',
            'Outside borders': 'Außengrenzen',
            'All borders': 'Alle Grenzen',
            'Inside borders': 'Innerhalb der Grenzen',
            'Left border': 'Linke Grenze',
            'Inside vertical border': 'Innerhalb der vertikalen Grenze',
            'Right border': 'Rechter Rand',
            'Top border': 'Oberer Rand',
            'Inside horizontal border': 'Innerhalb der horizontalen Grenze',
            'Bottom border': 'Untere Grenze',
            'Border color': 'Randfarbe',
            'Border width': 'Rahmenbreite',
            Cell: 'Zelle',
            'Merge cells': 'Zellen verbinden',
            'Insert Or Delete': 'Einfügen / Löschen',
            'Insert columns to the left': 'Spalten links einfügen',
            'Insert columns to the right': 'Spalten rechts einfügen',
            'Insert rows above': 'Zeilen darüber einfügen',
            'Insert rows below': 'Zeilen darunter einfügen',
            'Delete rows': 'Zeilen löschen',
            'Delete columns': 'Spalten löschen',
            'Cell Margin': 'Cell Margin',
            Top: 'oben',
            Bottom: 'Unterseite',
            Left: 'Links',
            Right: 'Rechts',
            'Align Text': 'Text ausrichten',
            'Align top': 'Oben ausrichten',
            'Align bottom': 'Boden ausrichten',
            'Align center': 'Im Zentrum anordnen',
            'Number of heading or outline levels to be shown in table of contents': 'Anzahl der Überschriften- oder Gliederungsebenen, die im Inhaltsverzeichnis angezeigt werden sollen.',
            'Show page numbers': 'Seitenzahlen anzeigen',
            'Show page numbers in table of contents': 'Seitenzahlen im Inhaltsverzeichnis anzeigen.',
            'Right align page numbers': 'Seitenzahlen nach rechts ausrichten',
            'Right align page numbers in table of contents': 'Richten Sie die Seitenzahlen im Inhaltsverzeichnis nach rechts aus.',
            'Use hyperlinks': 'Verwenden Sie Hyperlinks',
            'Use hyperlinks instead of page numbers': 'Verwenden Sie Hyperlinks anstelle von Seitenzahlen.',
            Font: 'Schriftart',
            'Font Size': 'Schriftgröße',
            'Font color': 'Schriftfarbe',
            'Text highlight color': 'Hervorhebungsfarbe für Text',
            'Clear all formatting': 'Löschen Sie alle Formatierungen',
            'Bold Tooltip': 'Fett (Strg + B)',
            'Italic Tooltip': 'Kursiv (Strg + I)',
            'Underline Tooltip': 'Unterstreichen (Strg + U)',
            Strikethrough: 'Durchgestrichen',
            'Superscript Tooltip': 'Hochgestellt (Strg + Umschalt ++)',
            'Subscript Tooltip': 'Tiefgestellt (Strg + =)',
            'Align left Tooltip': 'Links ausrichten (Strg + L)',
            'Center Tooltip': 'Zentrum (Strg + E)',
            'Align right Tooltip': 'Rechts ausrichten (Strg + R)',
            'Justify Tooltip': 'Ausrichten (Strg + J)',
            'Decrease indent': 'Einzug verkleinern',
            'Increase indent': 'Einzug vergrößern',
            'Line spacing': 'Zeilenabstand',
            Bullets: 'Aufzählungszeichen',
            Numbering: 'Nummerierung',
            Styles: 'Stile',
            'Manage Styles': 'Stile verwalten',
            Page: 'Seite',
            of: 'von',
            'Fit one page': 'Eine Seite einpassen',
            'Spell Check': 'Rechtschreibprüfung',
            'Underline errors': 'Fehler unterstreichen',
            'Fit page width': 'Seitenbreite anpassen',
            Update: 'Aktualisieren',
            Cancel: 'Abbrechen',
            Insert: 'Einfügen',
            'No Border': 'Keine Grenze',
            'Create a new document': 'Erstellen Sie ein neues Dokument.',
            'Open a document': 'Öffnen Sie ein Dokument.',
            'Undo Tooltip': 'Machen Sie die letzte Operation rückgängig (Strg + Z).',
            'Redo Tooltip': 'Wiederholen Sie die letzte Operation (Strg + Y).',
            'Insert inline picture from a file': 'Fügen Sie ein Inline-Bild aus einer Datei ein.',
            'Insert a table into the document': 'Fügen Sie eine Tabelle in das Dokument ein',
            'Create Hyperlink': 'Erstellen Sie in Ihrem Dokument einen Link für den schnellen Zugriff auf Webseiten und Dateien (Strg + K).',
            'Insert a bookmark in a specific place in this document': 'Fügen Sie ein Lesezeichen an einer bestimmten Stelle in dieses Dokument ein.',
            'Provide an overview of your document by adding a table of contents': 'Verschaffen Sie sich einen Überblick über Ihr Dokument, indem Sie ein Inhaltsverzeichnis hinzufügen.',
            'Add or edit the header': 'Fügen Sie den Header hinzu oder bearbeiten Sie ihn.',
            'Add or edit the footer': 'Fügen Sie die Fußzeile hinzu oder bearbeiten Sie sie.',
            'Open the page setup dialog': 'Öffnen Sie das Dialogfeld zur Seiteneinrichtung.',
            'Add page numbers': 'Seitenzahlen hinzufügen.',
            'Find Text': 'Suchen Sie nach Text im Dokument (Strg + F).',
            'Toggle between the internal clipboard and system clipboard':
                'Zwischen der internen Zwischenablage und der Systemzwischenablage wechseln. </br> Der Zugriff auf die Systemzwischenablage über ein Skript wird aufgrund der Sicherheitsrichtlinien des Browsers verweigert. Stattdessen </br> 1. Sie können die interne Zwischenablage zum Ausschneiden, Kopieren und Einfügen innerhalb der Komponente aktivieren. </br> 2. Sie können die Tastenkombinationen (Strg + X, Strg + C und Strg + V) zum Ausschneiden verwenden , kopieren und einfügen mit System-Zwischenablage.',
            'Current Page Number': 'Die aktuelle Seitenzahl im Dokument. Klicken oder tippen Sie, um zu einer bestimmten Seite zu navigieren.',
            'Read only': 'Schreibgeschützt',
            Protections: 'Schutzmaßnahmen',
            'Error in establishing connection with web server': 'Fehler beim Herstellen der Verbindung mit dem Webserver',
            Single: 'Single',
            Double: 'Doppelt',
            'New comment': 'Neuer Kommentar',
            Comments: 'Bemerkungen',
            'Print layout': 'Drucklayout',
            'Web layout': 'Web-Layout',
            'Text Form': 'Textform',
            'Check Box': 'Kontrollkästchen',
            DropDown: 'Dropdown-Liste',
            'Update Fields': 'Felder aktualisieren',
            'Update cross reference fields': 'Querverweisfelder aktualisieren',
            'Hide properties pane': 'Eigenschaftenbereich ausblenden',
            'Show properties pane': 'Eigenschaftenbereich anzeigen',
            'Form Fields': 'Formularfelder',
            'Track Changes': 'Verfolgen Sie die im Dokument vorgenommenen Änderungen',
            TrackChanges: 'Änderungen verfolgen',
            AllCaps: 'AllCaps',
            'Change case Tooltip': 'Fall ändern',
            'Insert Footnote': 'Fußnote einfügen',
            'Insert Endnote': 'Endnote einfügen',
            'Footnote Tooltip': 'Fußnote einfügen (Alt + Strg + F).',
            'Endnote Tooltip': 'Endnote einfügen (Alt + Strg + D).',
            UPPERCASE: 'GROSSBUCHSTABEN',
            'No color': 'GROSSBUCHSTABEN',
            'Top margin': 'Keine Farbe',
            'Bottom margin': 'Oberer Rand',
            'Left margin': 'Unterer Rand',
            'Right margin': 'Linker Rand',
            Normal: 'Rechter Rand',
            Heading: 'Normal',
            'Heading 1': 'Üerschrift',
            'Heading 2': 'Überschrift 1',
            'Heading 3': 'Überschrift 2',
            'Heading 4': 'Überschrift 3',
            'Heading 5': 'Überschrift 4',
            'Heading 6': 'Überschrift 5',
        },

        PdfViewerOLD: {
            PdfViewer: 'PDF Viewer',
            Cancel: 'Abbrechen',
            'Download file': 'Datei herunterladen',
            Download: 'Herunterladen',
            'Enter Password': 'Dieses Dokument ist passwortgeschützt. Bitte Passwort eingeben.',
            'File Corrupted': 'Datei ist beschädigt',
            'File Corrupted Content': 'Die Datei ist beschädigt und kann nicht geöffnet werden.',
            'Fit Page': 'Seite anpassen',
            'Fit Width': 'Breite anpassen',
            Automatic: 'Automatisch',
            'Go To First Page': 'Erste Seite anzeigen',
            'Invalid Password': 'Falsches Passwort. Bitte erneut versuchen.',
            'Next Page': 'Nächste Seite anzeigen',
            OK: 'in Ordnung',
            Open: 'Datei öffnen',
            'Page Number': 'Aktuelle Seitennummer',
            'Previous Page': 'Vorherige Seite anzeigen',
            'Go To Last Page': 'Letzte Seite anzeigen',
            Zoom: 'Zoomen',
            'Zoom In': 'Hineinzoomen',
            'Zoom Out': 'Herauszoomen',
            'Page Thumbnails': 'Seitenminiaturen',
            Bookmarks: 'Lesezeichen',
            Print: 'Druckdatei',
            'Password Protected': 'Passwort erforderlich',
            Copy: 'Kopieren',
            'Text Selection': 'Textauswahl-Tool',
            Panning: 'Schwenkmodus',
            'Text Search': 'Text finden',
            'Find in document': 'Im Dokument suchen',
            'Match case': 'Gross- / Kleinschreibung',
            Apply: 'Übernehmen',
            GoToPage: 'Gehe zu Seite',
            'No matches': 'Der Viewer hat die Suche im Dokument beendet. Es wurden keine weiteren Übereinstimmungen gefunden',
            'No Text Found': 'Kein Text gefunden',
            Undo: 'Rückgängig machen',
            Redo: 'Redo',
            Annotation: 'Anmerkungen hinzufügen oder bearbeiten',
            Highlight: 'Text hervorheben',
            Underline: 'Text unterstreichen',
            Strikethrough: 'Durchgestrichener Text',
            Delete: 'Anmerkung löschen',
            Opacity: 'Opazität',
            'Color edit': 'Farbe ändern',
            'Opacity edit': 'Deckkraft ändern',
            'Highlight context': 'Markieren',
            'Underline context': 'Unterstreichen',
            'Strikethrough context': 'Durchstreichen',
            'Server error': 'Web-Service hört nicht zu. PDF Viewer ist in allen Funktionen vom Web-Service abhängig. Bitte starten Sie den Webdienst, um fortzufahren.',
            'Open text': 'Öffnen',
            'First text': 'Erste Seite',
            'Previous text': 'Vorherige Seite',
            'Next text': 'Nächste Seite',
            'Last text': 'Letzte Seite',
            'Zoom in text': 'Hineinzoomen',
            'Zoom out text': 'Rauszoomen',
            'Selection text': 'Auswahl',
            'Pan text': 'Schwenken',
            'Print text': 'Drucken',
            'Search text': 'Suche',
            'Annotation Edit text': 'Anmerkung bearbeiten',
            'Line Thickness': 'Dicke der Linie',
            'Line Properties': 'Linieneigenschaften',
            'Start Arrow': 'Pfeil starten',
            'End Arrow': 'Pfeil beenden',
            'Line Style': 'Linienart',
            'Fill Color': 'Füllfarbe',
            'Line Color': 'Linienfarbe',
            None: 'Keiner',
            'Open Arrow': 'Öffnen',
            'Closed Arrow': 'Geschlossen',
            'Round Arrow': 'Runden',
            'Square Arrow': 'Platz',
            'Diamond Arrow': 'Diamant',
            Cut: 'Ausschneiden',
            Paste: 'Einfügen',
            'Delete Context': 'Löschen',
            Properties: 'Eigenschaften',
            'Add Stamp': 'Stempel hinzufügen',
            'Add Shapes': 'Formen hinzufügen',
            'Stroke edit': 'Strichfarbe ändern',
            'Change thickness': 'Randstärke ändern',
            'Add line': 'Zeile hinzufügen',
            'Add arrow': 'Pfeil hinzufügen',
            'Add rectangle': 'Rechteck hinzufügen',
            'Add circle': 'Kreis hinzufügen',
            'Add polygon': 'Polygon hinzufügen',
            'Add Comments': 'Füge Kommentare hinzu',
            Comments: 'Bemerkungen',
            'No Comments Yet': 'Noch keine Kommentare',
            Accepted: 'Akzeptiert',
            Completed: 'Abgeschlossen',
            Cancelled: 'Abgesagt',
            Rejected: 'Abgelehnt',
            'Leader Length': 'Länge des Anführers',
            'Scale Ratio': 'Skalierungsverhältnis',
            Calibrate: 'Kalibrieren',
            'Calibrate Distance': 'Entfernung kalibrieren',
            'Calibrate Perimeter': 'Perimeter kalibrieren',
            'Calibrate Area': 'Bereich kalibrieren',
            'Calibrate Radius': 'Radius kalibrieren',
            'Calibrate Volume': 'Lautstärke kalibrieren',
        },
        PdfViewer: {
            PdfViewer: 'PDF Viewer',
            Cancel: 'Abbrechen',
            'Download file': 'Datei herunterladen',
            Download: 'Herunterladen',
            'Enter Password': 'Dieses Dokument ist passwortgeschützt. Bitte Passwort eingeben.',
            'File Corrupted': 'Datei ist beschädigt',
            'File Corrupted Content': 'Die Datei ist beschädigt und kann nicht geöffnet werden.',
            'Fit Page': 'Seite anpassen',
            'Fit Width': 'Breite anpassen',
            Automatic: 'Automatisch',
            'Go To First Page': 'Erste Seite anzeigen',
            'Invalid Password': 'Falsches Passwort. Bitte erneut versuchen.',
            'Next Page': 'Nächste Seite anzeigen',
            OK: 'in Ordnung',
            Open: 'Datei öffnen',
            'Page Number': 'Aktuelle Seitennummer',
            'Previous Page': 'Vorherige Seite anzeigen',
            'Go To Last Page': 'Letzte Seite anzeigen',
            Zoom: 'Zoomen',
            'Zoom In': 'Hineinzoomen',
            'Zoom Out': 'Herauszoomen',
            'Page Thumbnails': 'Seitenminiaturen',
            Bookmarks: 'Lesezeichen',
            Print: 'Druckdatei',
            'Password Protected': 'Passwort erforderlich',
            Copy: 'Kopieren',
            'Text Selection': 'Textauswahl-Tool',
            Panning: 'Schwenkmodus',
            'Text Search': 'Text finden',
            'Find in document': 'Im Dokument suchen',
            'Match case': 'Gross- / Kleinschreibung',
            Apply: 'Übernehmen',
            GoToPage: 'Gehe zu Seite',
            'No matches': 'Der Viewer hat die Suche im Dokument beendet. Es wurden keine weiteren Übereinstimmungen gefunden',
            'No Text Found': 'Kein Text gefunden',
            Undo: 'Rückgängig machen',
            Redo: 'Wiederholen',
            Annotation: 'Anmerkungen hinzufügen oder bearbeiten',
            FormDesigner: 'Formular Felder erstellen und bearbeiten',
            Highlight: 'Text hervorheben',
            Underline: 'Text unterstreichen',
            Strikethrough: 'Durchgestrichener Text',
            Delete: 'Anmerkung löschen',
            Opacity: 'Deckkraft',
            'Color edit': 'Farbe ändern',
            'Opacity edit': 'Deckkraft ändern',
            'Highlight context': 'Markieren',
            'Underline context': 'Unterstreichen',
            'Strikethrough context': 'Durchstreichen',
            'Server error': 'Web-Service hört nicht zu. PDF Viewer ist in allen Funktionen vom Web-Service abhängig. Bitte starten Sie den Webdienst, um fortzufahren.',
            'Client error': 'Client-side error is found. Please check the custom headers provided in the AjaxRequestSettings property and web action methods in the ServerActionSettings property.',
            'Open text': 'Öffnen',
            'First text': 'Erste Seite',
            'Previous text': 'Vorherige Seite',
            'Next text': 'Nächste Seite',
            'Last text': 'Letzte Seite',
            'Zoom in text': 'Hineinzoomen',
            'Zoom out text': 'Rauszoomen',
            'Selection text': 'Auswahl',
            'Pan text': 'Schwenken',
            'Print text': 'Drucken',
            'Search text': 'Suche',
            'Annotation Edit text': 'Anmerkung bearbeiten',
            'FormDesigner Edit text': 'Formular Feld bearbeiten',
            'Line Thickness': 'Dicke der Linie',
            'Line Properties': 'Linieneigenschaften',
            'Start Arrow': 'Pfeil starten',
            'End Arrow': 'Pfeil beenden',
            'Line Style': 'Linienart',
            'Fill Color': 'Füllfarbe',
            'Line Color': 'Linienfarbe',
            None: 'Keiner',
            'Open Arrow': 'Öffnen',
            'Closed Arrow': 'Geschlossen',
            'Round Arrow': 'Runden',
            'Square Arrow': 'Platz',
            'Diamond Arrow': 'Diamant',
            Butt: 'Butt',
            Cut: 'Ausschneiden',
            Paste: 'Einfügen',
            'Delete Context': 'Löschen',
            Properties: 'Eigenschaften',
            'Add Stamp': 'Stempel hinzufügen',
            'Add Shapes': 'Formen hinzufügen',
            'Stroke edit': 'Strichfarbe ändern',
            'Change thickness': 'Randstärke ändern',
            'Add line': 'Zeile hinzufügen',
            'Add arrow': 'Pfeil hinzufügen',
            'Add rectangle': 'Rechteck hinzufügen',
            'Add circle': 'Kreis hinzufügen',
            'Add polygon': 'Polygon hinzufügen',
            'Add Comments': 'Füge Kommentare hinzu',
            Comments: 'Bemerkungen',
            SubmitForm: 'Submit Form',
            'No Comments Yet': 'Noch keine Kommentare',
            Accepted: 'Akzeptiert',
            Completed: 'Abgeschlossen',
            Cancelled: 'Abgesagt',
            Rejected: 'Abgelehnt',
            'Leader Length': 'Länge des Anführers',
            'Scale Ratio': 'Skalierungsverhältnis',
            Calibrate: 'Kalibrieren',
            'Calibrate Distance': 'Entfernung kalibrieren',
            'Calibrate Perimeter': 'Perimeter kalibrieren',
            'Calibrate Area': 'Bereich kalibrieren',
            'Calibrate Radius': 'Radius kalibrieren',
            'Calibrate Volume': 'Volumen kalibrieren',
            Depth: 'Tiefe',
            Closed: 'Geschlossen',
            Round: 'Runden',
            Square: 'Rechteck',
            Diamond: 'Raute',
            Edit: 'Bearbeiten',
            Comment: 'Kommentar',
            'Comment Panel': 'Kommentar Dialog',
            'Set Status': 'Status setzen',
            Post: 'Post',
            Page: 'Seite',
            'Add a comment': 'Kommentar hinzufügen',
            'Add a reply': 'Antwort hinzufügen',
            'Import Annotations': 'Anmerkungen importieren (JSON)',
            'Export Annotations': 'Anmerkungen exportieren (JSON)',
            'Export XFDF': 'Anmerkungen exportieren (XFDF)',
            'Import XFDF': 'Anmerkungen importieren (XFDF)',
            Add: 'Hinzufügen',
            Clear: 'Löschen',
            Bold: 'Fett',
            Italic: 'Kursiv',
            Strikethroughs: 'Durchgestrichen',
            Underlines: 'Unterstrichen',
            Superscript: 'Hochgestellt',
            Subscript: 'Tiefgestellt',
            'Align left': 'Linksbündig',
            'Align right': 'Rechtsbündig',
            Center: 'Mittig',
            Justify: 'Justieren',
            'Font color': 'Textfarbe',
            'Text Align': 'Textausrichtung',
            'Text Properties': 'Texteigenschaften',
            SignatureFieldDialogHeaderText: 'Unterschrift hinzufügen',
            HandwrittenSignatureDialogHeaderText: 'Unterschrift hinzufügen',
            InitialFieldDialogHeaderText: 'Initialen hinzufügen',
            HandwrittenInitialDialogHeaderText: 'Initialen hinzufügen',
            'Draw Ink': 'Freihand zeichnen',
            Create: 'Erstellen',
            'Font family': 'Schriftart',
            'Font size': 'Schriftgröße',
            'Free Text': 'Freitext',
            'Import Failed': 'Invalid JSON file type or file name; please select a valid JSON file',
            'Import PDF Failed': 'Invalid PDF file type or PDF file not found. Please select a valid PDF file',
            'File not found': 'Imported JSON file is not found in the desired location',
            'Export Failed': 'Export annotations action has failed; please ensure annotations are added properly',
            of: 'von ',
            Dynamic: 'Dynamic',
            'Standard Business': 'Standard Business',
            'Sign Here': 'Hier Unterschreiben',
            'Custom Stamp': 'Benutzerdefinierter Stempel',
            'Enter Signature as Name': 'Namen Eintragen',
            'Draw-hand Signature': 'Zeichnen',
            'Type Signature': 'Schreiben',
            'Upload Signature': 'Hochladen',
            'Browse Signature Image': 'Durchsuchen',
            'Save Signature': 'Unterschrift speichern',
            'Save Initial': 'Initialen speichern',
            Textbox: 'Textfeld',
            Password: 'Passwort',
            'Check Box': 'Checkbox',
            'Radio Button': 'Auswahlknopf',
            Dropdown: 'Drop Down',
            'List Box': 'List Box',
            Signature: 'Unterschrift',
            'Delete FormField': 'Formularfeld löschen',
            'Textbox Properties': 'Textfeld Eigenschaften',
            Name: 'Name',
            Tooltip: 'Tooltip',
            Value: 'Wert',
            'Form Field Visibility': 'Textfeld Sichtbarkeit',
            'Read Only': 'Nur Lesen',
            Required: 'Erforderlich',
            Checked: 'Checked',
            'Show Printing': 'Druckvorschau',
            Formatting: 'Formatierung',
            Fill: 'Füllen',
            Border: 'Rahmen',
            'Border Color': 'Rahmenfarbe',
            Thickness: 'Dicke',
            'Max Length': 'Maximale Länge',
            'List Item': 'Listeneintrag',
            'Export Value': 'Item Value',
            'Dropdown Item List': 'Dropdown Liste',
            'List Box Item List': 'List Box Item List',
            General: 'GENERAL',
            Appearance: 'APPEARANCE',
            Options: 'OPTIONS',
            'Delete Item': 'Löschen',
            Up: 'Hoch',
            Down: 'Runter',
            Multiline: 'Multiline',
            Revised: 'Revised',
            Reviewed: 'Reviewed',
            Received: 'Received',
            Confidential: 'Confidential',
            Approved: 'Bestätigt',
            'Not Approved': 'Nicht Bestätigt',
            Witness: 'Witness',
            'Initial Here': 'Initialen hier',
            Draft: 'Entwurf',
            Final: 'Final',
            'For Public Release': 'For Public Release',
            'Not For Public Release': 'Not For Public Release',
            'For Comment': 'For Comment',
            Void: 'Void',
            'Preliminary Results': 'Preliminary Results',
            'Information Only': 'Information Only',
            in: 'in',
            m: 'm',
            ft_in: 'ft_in',
            ft: 'ft',
            p: 'p',
            cm: 'cm',
            mm: 'mm',
            pt: 'pt',
            cu: 'cu',
            sq: 'sq',
            Initial: 'Initial',
        },
    },
};
