import { Injectable } from '@angular/core';
import { Permissions } from '@dave/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map,shareReplay } from 'rxjs/operators';
import { PermissionEntity } from '../../../dave-data-module/entities/permission.entity';
import { PermissionsResolver } from '../../../dave-data-module/guards/permissions.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { getPermissions,getPermissionsFetched } from '../../../dave-data-module/State/selectors/permissions.selectors';
import { Permission } from '../../../helper/permission.helper';

// TODO: move to dave frontend Utils. Änderungen an dieser Datei mit Simon absprechen

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    /**
     * @deprecated use {@link Permissions} instead.
     */
    public Permission = Permission;

    public Permissions = Permissions;
    private permissions$: Observable<PermissionEntity[]>;

    constructor(store: Store<State>,  permissionResolver: PermissionsResolver) {
        this.permissions$ = getFetched$(store, getPermissionsFetched, getPermissions, permissionResolver);
    }

    public Has$(p: Permissions): Observable<boolean> {
        return this.permissions$.pipe(
            map((permissions) => permissions?.some((permission) => permission.Name === p)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }

    /**
     * @deprecated use {@link Has$} instead
     */
    public Has(p: Permission): Observable<boolean> {
        return this.Has$(p as unknown as Permissions);
    }
}
