import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { BehaviorSubject, of, Subscription, switchMap } from 'rxjs';
import { PersonEntity } from '../dave-data-module/entities/person.entity';
import { State } from '../dave-data-module/State';
import { getPersonById } from '../dave-data-module/State/selectors/person.selectors';
import { getPersonTypeById } from '../dave-data-module/State/selectors/personType.selectors';
import { ResolveData } from '@angular/router';
import { GROUP_KEY } from '../dave-data-module/State/reducers/group.reducer';
import { GroupResolver } from '../dave-data-module/guards/group.resolver';
import { PersonResolver } from '../dave-data-module/guards/person.resolver';
import { personsFeatureKey } from '../dave-data-module/State/reducers/person.reducer';
import { personTypesFeatureKey } from '../dave-data-module/State/reducers/personType.reducer';
import { PersonTypeResolver } from '../dave-data-module/guards/personType.resolver';

@Component({
    selector: 'app-person-list-entry',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule],
    templateUrl: './person-list-entry.component.html',
    styleUrls: ['./person-list-entry.component.scss'],
})
export class PersonListEntryComponent {
    public static readonly RequiredResolvers: ResolveData = {
        [personsFeatureKey]: PersonResolver,
        [personTypesFeatureKey]: PersonTypeResolver,
    };
    @Input() set Person(p: PersonEntity) {
        if (this.personIdSubscription) {
            this.personIdSubscription.unsubscribe();
        }
        this.person$.next(p);
    }
    @Input() set PersonId(id: number) {
        this.personIdSubscription = this.store.select(getPersonById({ id })).subscribe((p) => {
            this.person$.next(p)
        });
    }
    private personIdSubscription: Subscription = null;
    protected person$ = new BehaviorSubject<PersonEntity>(null);
    protected personType$ = this.person$.pipe(switchMap((p) => (p?.PersonTypeId ? this.store.select(getPersonTypeById({ id: p.PersonTypeId })) : of(null))));
    constructor(protected store: Store<State>) {}
}
