import { MatDialogConfig } from '@angular/material/dialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, Subject, switchMap } from 'rxjs';
import { filter, shareReplay, skip, takeUntil } from 'rxjs/operators';
import { State } from '../../../dave-data-module/State';
import { getJobSpecification, getJobSpecificationFetched } from '../../../dave-data-module/State/selectors/job-specification.selector';
import {
    FilterOption,
    FILTER_TYPE_BOOLEAN,
    FILTER_TYPE_MULTI_SELECT,
    FILTER_TYPE_SEARCH_MULTI_SELECT, IFilterTypeSearchMultiSelectValue
} from "../../../dave-utils-module/app-filter-module/app-filter/app-filter.component";
import { appMatDialogDefaultConfig, isNotNullOrUndefined } from '../../../helper/helper';
import { DefaultFilterService, FilterApps, FilterTypes } from '../../../services/default-filter.service';
import {isArray} from "chart.js/helpers";
import { EmployeeStatusEnum, EmployeeStatusNames } from "../../../dave-data-module/entities/employee.entity";

interface IFilter {
    // [FilterTypes.CommissionId]: string[];
    [FilterTypes.JobSpecificationIds]: string[];
    [FilterTypes.HideKollision]: boolean;
    [FilterTypes.Status]: IFilterTypeSearchMultiSelectValue<EmployeeStatusEnum>[];
}
export class SelectUserDialogBase {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        width: 'calc(100vw - 3.5rem)',
        height: 'calc(100vh - 3.5rem)',
    };
    private _onDestroy$ = new Subject<void>()
    public FilterSettings$: Observable<FilterOption[]> = combineLatest([
        // this.store.select(getCommissionsFetched).pipe(
        //     filter((fetched) => !!fetched),
        //     switchMap(() => this.store.select(getCommissionsActive)),
        // ),
        this._store.select(getJobSpecificationFetched).pipe(
            filter((fetched) => !!fetched),
            switchMap(() => this._store.select(getJobSpecification)),
        ),
    ]).pipe(
        filter((v) => v.every(isNotNullOrUndefined)),
        map(([/*commissions,*/ jobSpecifications]) => {
            // let commissionValues = {};
            let jobSpecificationValues = {};

            // commissions.forEach((c) => {
            //     commissionValues[c.Id.toString()] = c.DisplayName;
            // });

            jobSpecifications.forEach((str) => {
                jobSpecificationValues[str.Id] = str.Name;
            });

            return [
                // {
                //     Name: FilterTypes.CommissionId,
                //     Type: FILTER_TYPE_MULTI_SELECT,
                //     Label: 'Aufträge',
                //     Icon: CommissionMeta.Icon,
                //     Values: commissionValues,
                // },
                {
                    Name: FilterTypes.JobSpecificationIds,
                    Type: FILTER_TYPE_MULTI_SELECT,
                    Label: 'Jobbezeichnung',
                    Icon: 'id-card' as IconProp,
                    Values: jobSpecificationValues,
                },
                {
                    Name: FilterTypes.Status,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Status',
                    // Icon: 'id-card' as IconProp,
                    Values: Object.values(EmployeeStatusEnum).map<IFilterTypeSearchMultiSelectValue<EmployeeStatusEnum>>(key => ({id: key, label: EmployeeStatusNames.get(key)})),
                },
                {
                    Name: FilterTypes.HideKollision,
                    Type: FILTER_TYPE_BOOLEAN,
                    Label: 'Mitarbeiter mit Kollisionen ausblenden',
                },
            ];
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public FilterValues$: BehaviorSubject<IFilter> = new BehaviorSubject({
        // [FilterTypes.CommissionId]: [],
        [FilterTypes.JobSpecificationIds]: [],
        [FilterTypes.Status]: [],
        [FilterTypes.HideKollision]: false,
    });
    public FilterAmount$ = this.FilterValues$.pipe(map(filter => [filter[FilterTypes.JobSpecificationIds]?.length, filter[FilterTypes.HideKollision], filter[FilterTypes.Status]?.length].filter(v => !!v).length));
    constructor(private _store: Store<State>, defaultFilterService: DefaultFilterService, filterApp: FilterApps) {
        firstValueFrom(combineLatest([this.FilterSettings$, defaultFilterService.GetFilterByApp$(filterApp)])).then(([val, filterValues]) => {
            let temp = {
                // [FilterTypes.CommissionId]: filterValues[FilterTypes.CommissionId] || null,
                [FilterTypes.Status]: isArray(filterValues[FilterTypes.Status]) && filterValues[FilterTypes.Status].every(v => v.id) ? filterValues[FilterTypes.Status] : [{id: EmployeeStatusEnum.Aktiv, label: EmployeeStatusNames.get(EmployeeStatusEnum.Aktiv)}],
                [FilterTypes.JobSpecificationIds]: (filterValues[FilterTypes.JobSpecificationIds] && isArray(filterValues[FilterTypes.JobSpecificationIds])) ? filterValues[FilterTypes.JobSpecificationIds] : null,
                [FilterTypes.HideKollision]: (filterValues[FilterTypes.HideKollision] && isArray(filterValues[FilterTypes.HideKollision])) ? filterValues[FilterTypes.HideKollision] : false,
            };
            // if (this.CommissionId) {
            //     temp = { ...temp, commissionId: uniqArray([...temp[FilterTypes.CommissionId], this.CommissionId + '']) };
            // }
            this.FilterValues$.next(temp);
        });
        this.FilterValues$.pipe(takeUntil(this._onDestroy$), skip(2)).subscribe((val) => {
            if (Object.keys(val).length !== 0) {
                defaultFilterService.SetFilterByApp(filterApp, val);
            }
        });
    }

    protected _onDestroy(): void {
        this._onDestroy$.next();
    }
}
