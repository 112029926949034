import {createReducer, on} from '@ngrx/store';
import { EmailEntity } from '../../entities/email.entity';
import { EmailActionTypes } from '../actions/email.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const emailFeatureKey = 'emails';

export interface EmailState extends EntityState<EmailEntity> {
}

const adapter = createEntityAdapter<EmailEntity>({
    selectId: Entity => Entity.Id,
});

export const initialState = adapter.getInitialState();

export const EmailReducer = createReducer(
    initialState,
    on(EmailActionTypes.UpdateSomeEmails, (state, {Payload}) => adapter.setMany(Payload, state)),
    on(EmailActionTypes.UpdateOneEmail, (state, {Payload}) => adapter.setOne(Payload, state)),
    on(EmailActionTypes.CreateAndSendSuccess, (state, {Payload}) => adapter.setOne(Payload, state)),
    on(EmailActionTypes.ModifyAndSendSuccess, (state, {Payload}) => adapter.setOne(Payload, state)),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllEmails = selectAll;
export const selectEmailEntities = selectEntities;

