import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    Milestone_KEY,
    MilestoneState,
    selectAllMilestone,
    selectMilestoneEntities,
    selectMilestoneFetched, selectMilestoneLatestUpdatedAt,
} from '../reducers/milestone.reducer';

const selectState = createFeatureSelector<MilestoneState>(Milestone_KEY);

export const getMilestoneDictionary = createSelector(selectState, selectMilestoneEntities);
export const getMilestones = createSelector(selectState, selectAllMilestone);
export const getMilestonesActive = createSelector(getMilestones, (entities) => entities.filter(e => !e.DeletedAt));
export const getMilestonesFetched = createSelector(selectState, selectMilestoneFetched);
export const getMilestoneLatestUpdatedAt = createSelector(selectState, selectMilestoneLatestUpdatedAt);

export const getMilestoneById = (props: { id: number }) => createSelector(getMilestoneDictionary, (dictionary) => props?.id && dictionary[props.id]);
