import { MilestoneType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export enum MilestoneEntityState {
    Done = 'DONE',
    InProgress = 'IN_PROGRESS'
  }
  export const MilestoneEntityStateNames = new Map<MilestoneEntityState,string>(
    [
        [MilestoneEntityState.Done, 'Erledigt'],
        [MilestoneEntityState.InProgress, 'In Bearbeitung']
    ]
  );
export class MilestoneEntity {
    constructor(
        public CommissionId: number = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public Id: number = null,
        public Name: string = null,
        public PartnerId: number = null,
        public UpdatedAt: Date = null,
        public UserId: number = null,
        public Description: string = null,
        public State: MilestoneEntityState = null,
        public Deadline: Date = null,
        public TargetHours: number = null,
        public ClockInTypeId: number = null,
    ) {}

    public static readonly GqlFields: Array<keyof MilestoneType> = ['commissionId', 'createdAt', 'deletedAt', 'id', 'partnerId', 'updatedAt', 'userId', 'name', 'description', 'state', 'deadline', 'targetHours', 'clockInTypeId',];

    public Clone(override: Partial<MilestoneEntity> = {}): MilestoneEntity {
        const attr = { ...this, ...override };
        return Object.assign(new MilestoneEntity(), attr);
    }
}

export function MilestoneEntityFromBackend(res: MilestoneType): MilestoneEntity {
    return new MilestoneEntity(
        res.commissionId,
        BackendDate(res.createdAt),
        res.deletedAt && BackendDate(res.deletedAt),
        res.id,
        res.name,
        res.partnerId,
        BackendDate(res.updatedAt),
        res.userId,
        res.description,
        res.state as any,
        res.deadline && BackendDate(res.deadline),
        res.targetHours,
        res.clockInTypeId,
        );
}
