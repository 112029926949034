import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  ComplaintTypeState,
  complaintTypesFeatureKey
} from "../reducers/complaintType.reducer";
import { State } from "../index";

export const selectComplaintTypes = createFeatureSelector<
  ComplaintTypeState
>(complaintTypesFeatureKey);
export const getComplaintTypes = createSelector(
  selectComplaintTypes,
  data => data.complaintTypes
);
export const getComplaintTypesFetched = createSelector(
    selectComplaintTypes,
    data => data.fetched
  );
export const getSingleComplaintType = createSelector(
  getComplaintTypes,
  (complaintType: ReturnType<typeof getComplaintTypes>, Id: number) =>
    complaintType ? complaintType.find(e => e.Id === Id) : null
);
