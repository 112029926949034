import { AbstractControl,FormControl,FormGroup,UntypedFormControl,ValidatorFn } from '@angular/forms';
import { electronicFormatIBAN } from 'ibantools';
import moment, { Moment } from 'moment';
import { FormGroupTyped } from '../dave-utils-module/typings';
import { isNotNullOrUndefined } from './helper';
import {
    SelectSearchOption
} from "../dave-utils-module/select-search/components/select-search/select-search.component";
import {
    AvailableBusinessVolumeStatusByType, BusinessVolumeCostStateEnum,
    BusinessVolumeStatusEnum
} from "../dave-data-module/entities/business-volume.entity";

export const hasRequiredValidator = (abstractControl: AbstractControl): boolean => {
    if (abstractControl.validator) {
        const validator = abstractControl.validator({} as AbstractControl);
        return !!validator?.required;
    }
};
export function getErrorMessage(Value: UntypedFormControl | AbstractControl) {
    return Value.hasError('required') || Value.hasError('requiredWhenValidator')
        ? 'Bitte füllen Sie dieses Feld aus.'
        : Value.hasError('email')
        ? 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
        : Value.hasError('phoneNumberValidator')
        ? 'Bitte geben Sie eine gültige Telefonnummer ein.'
        : Value.hasError('minlength')
        ? 'Bitte geben Sie mindestens ' + Value.errors['minlength']['requiredLength'] + ' Zeichen ein.'
        : Value.hasError('maxlength')
        ? 'Bitte geben Sie maximal ' + Value.errors['maxlength']['requiredLength'] + ' Zeichen ein.'
        : Value.hasError('coordValidator')
        ? 'Bitte verwenden Sie ausschließlich Ziffern von 0 - 9 und einen Dezimalpunkt.'
        : Value.hasError('matDatepickerMax')
        ? `Das Datum muss vor ${(Value.errors.matDatepickerMax.max as Moment).format('D.M.YYYY')} liegen`
        : Value.hasError('matDatepickerMin')
        ? `Das Datum muss nach ${(Value.errors.matDatepickerMin.min as Moment).format('D.M.YYYY')} liegen`
        : Value.hasError('decimalValidator')
        ? `Es sind ${Value.errors.decimalValidator.allowedDecimals ? Value.errors.decimalValidator.allowedDecimals + '' : 'keine'} Ziffern nach dem Komma erlaubt`
        : Value.hasError('min')
        ? 'Der eingegebene Wert ist zu niedrig'
        : Value.hasError('licenseAlreadyBookedValidator')
        ? 'Diese Lizenz besitzen Sie bereits.'
        : Value.hasError('passwordValidator')
        ? 'Ein Passwort muss aus mindestens 8 zeichen und mindestens einer Zahl, einem großen und einem kleinen Buchstaben bestehen.'
        : Value.hasError('equalPasswordValidator')
        ? 'Die Passwörter stimmen nicht überein.'
        : Value.hasError('iBANValidator')
        ? 'Die eingegebene Zeichenfolge ist keine gültige IBAN.'
        : Value.hasError('bICValidator')
        ? 'Die eingegebene Zeichenfolge ist keine gültige BIC.'
        : Value.hasError('numberValidator')
        ? 'Bitte verwenden Sie nur Ziffern'
        : Value.hasError('decimalPriceValidator')
        ? 'Bitte verwenden Sie maximal 2 Stellen hinter dem Komma'
        : Value.hasError('arrayLengthValidatorToLong')
        ? 'Bitte weniger auswählen'
        : Value.hasError('arrayLengthValidatorToShort')
        ? 'Bitte mehr auswählen'
        : Value.hasError('businessVolumeStateNotAvailable')
        ? 'Bitte Wählen Sie einen anderen Status.'
        : 'Bitte überprüfen Sie Ihre Eingabe.';
}

export function iBANValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const ibantools = require('ibantools');
        if (control.value == null || control.value.length === 0) {
            return null;
        }
        const iban = electronicFormatIBAN(control.value);
        const validIBAN = ibantools.isValidIBAN(iban);
        return validIBAN ? null : { iBANValidator: { value: control.value } };
    };
}

export function bICValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const ibantools = require('ibantools');
        if (control.value == null || control.value.length === 0) {
            return null;
        }
        const validBIC = ibantools.isValidBIC(control.value);
        return validBIC ? null : { bICValidator: { value: control.value } };
    };
}
export function numberValidator(nameRe: RegExp = /^[0-9]*$/): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const forbidden = !(nameRe[Symbol.match](control.value) || control.value === null);
        return forbidden ? { numberValidator: { value: control.value } } : null;
    };
}


export function decimalValidator(decimalCount: number = 0): ValidatorFn {
    const reg = new RegExp(`^-?\\d*[.,]?\\d{0,${decimalCount}}$`, 'g');
    return (control: AbstractControl): { [key: string]: any } | null => {
        const forbidden = !(reg[Symbol.match](control.value) || control.value === null);
        return forbidden ? { decimalValidator: { value: control.value, allowedDecimals: decimalCount } } : null;
    };
}
export function decimalPriceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        return decimalValidator(2)(control) ? { decimalPriceValidator: { value: control.value } } : null;
    };
}

export function phoneNumberValidator(
    // numberRe: RegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
    numberRe: RegExp = /(.*)/g,
): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null; // leere Werte sind okay, für nichtleere Werte gibts required
        }
        const allowed = numberRe[Symbol.match](control.value);
        return allowed ? null : { phoneNumberValidator: { value: control.value } };
    };
}

export function coordValidator(nameRe: RegExp = /[^0-9 .]/g): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const forbidden = nameRe[Symbol.match](control.value);
        return forbidden ? { coordValidator: { value: control.value } } : null;
    };
}

export function passwordValidator(): ValidatorFn {
    const hasNumberRe: RegExp = /\d/;
    const hasCapitalCaseRe: RegExp = /[A-Z]/;
    const hasLowerCaseRe: RegExp = /[a-z]/;

    return (control: AbstractControl): { [key: string]: any } | null => {
        const forbidden =
            control.value.length < 8 || !hasNumberRe[Symbol.match](control.value) || !hasCapitalCaseRe[Symbol.match](control.value) || !hasLowerCaseRe[Symbol.match](control.value);
        return forbidden ? { passwordValidator: { value: control.value } } : null;
    };
}

/**
 * Angular-Validierungsfunktion für eine FormGroup mit den Feldern `Firstname`,
 * `Lastname`, `Email` und `PhoneNumber`.
 *
 * Überprüft, ob mindestens ein Feld bei der Erstellung oder Bearbeitung eines
 * Ansprechpartners ausgefüllt wurde.
 *
 * Gibt entweder `null` oder ein Fehlerobjekt mit dem Wert
 * `contactPersonRequiredFieldValidator` zurück.
 */
export function contactPersonRequiredFieldValidator(
    formGroup: FormGroupTyped<{
        Firstname: string;
        Lastname: string;
        Email: string;
        PhoneNumber: string;
    }>,
): { [key: string]: any } | null {
    const { Firstname, Lastname, Email, PhoneNumber } = formGroup.value;

    return Firstname || Lastname || Email || PhoneNumber ? null : { contactPersonRequiredFieldValidator: true };
}

export function userRequiredFieldValidator(
    formGroup: FormGroupTyped<{
        Firstname: string;
        Lastname: string;
        Email: string;
    }>,
): { [key: string]: any } | null {
    const { Firstname, Lastname, Email } = formGroup.value;
    return Firstname || Lastname || Email ? null : { userRequiredFieldValidator: true };
}

export function equalPasswordValidator(Password: () => FormControl<string>) {
    return (RepeatedPassword: FormControl<string>) => `${Password()?.value}` === `${RepeatedPassword.value}` ? null : { equalPasswordValidator: true };
}
export function requiredWhenValidator(condition: () => boolean) {
    return (form: FormControl<any>) => !condition() || isNotNullOrUndefined(form.value) ? null : { requiredWhenValidator: true };
}

/**
 * Angular-Validierungsfunktion für eine FormGroup mit den Feldern
 * `EventDate`, `EventEndDate`,`EventStartTimeString` und `EventEndTimeString`.
 *
 * Überprüft, ob die StartTime vor der EndTime liegt.
 *
 * Gibt entweder `null` oder ein Fehlerobjekt mit dem Wert
 * `startTimeBeforeEndTimeValidator` zurück.
 */
export function startTimeBeforeEndTimeValidator(
    formGroup: FormGroupTyped<{
        EventDate: Date | Moment;
        EventEndDate: Date | Moment;
        EventStartTimeString: string;
        EventEndTimeString: string;
    }>,
): { [key: string]: any } {
    const { EventEndDate, EventDate, EventStartTimeString, EventEndTimeString } = formGroup.value;
    if (!EventStartTimeString || !EventEndTimeString || !EventEndDate || !EventDate) {
        return null;
    }

    const min = EventStartTimeString.split(':');
    const max = EventEndTimeString.split(':');

    const eventDate = moment(EventDate).toDate()
    const eventEndDate = moment(EventEndDate).toDate()

    eventDate.setHours(0, 0, 0, 0);
    eventEndDate.setHours(0, 0, 0, 0);

    if (eventEndDate > eventDate) {
        return null;
    } else if (eventDate > eventEndDate) {
        return { startTimeBeforeEndTimeValidator: true };
    }
    return min[0] > max[0] // Stunden falsch
        ? { startTimeBeforeEndTimeValidator: true }
        : min[0] === max[0] && min[1] > max[1] // Stunden gleich, Minuten falsch
        ? { startTimeBeforeEndTimeValidator: true }
        : null;
}
export function licenseAlreadyBookedValidator(minNumber: number = 0, maxNumber: number = 0): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null; // leere Werte sind okay, für nichtleere Werte gibts required
        }
        // Wert darf nicht zwischen minNumber und maxNumber sein, maxNumber = 0 ist ein Spezialfall,
        // der bedeutet, das maxNumber eigentlich unbegrenzt ist
        const forbidden = minNumber <= control.value && (maxNumber >= control.value || maxNumber === 0);
        return forbidden ? { licenseAlreadyBookedValidator: true } : null;
    };
}

export function arrayLengthValidator(minLength: number = null, maxLength: number = null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        // Wert darf nicht zwischen minNumber und maxNumber sein, maxNumber = 0 ist ein Spezialfall,
        // der bedeutet, das maxNumber eigentlich unbegrenzt ist
        const toShort = minLength !== null && (control.value?.length < minLength || (!control.value && minLength > 0));
        const toLong = maxLength !== null && control.value?.length > maxLength;
        return toShort ? { arrayLengthValidatorToShort: true } : toLong ? { arrayLengthValidatorToLong: true } : null;
    };
}
export function bvStatusByBvTypedValidator(getType: () => BusinessVolumeCostStateEnum) {
    return (statusFormControl: FormControl<SelectSearchOption<{ optionValue: BusinessVolumeStatusEnum }>>) => {
        const type = getType();
        return type && !AvailableBusinessVolumeStatusByType.get(type).includes(statusFormControl?.value?.optionValue) ? { businessVolumeStateNotAvailable: true } : null;
    }
}
