import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PersonEntity } from '../../entities/person.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { PersonActionTypes } from '../actions/person.actions';

export const personsFeatureKey = 'persons';
export interface PersonState extends EntityState<PersonEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<PersonEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});
export const PersonReducer = createReducer(
    initialState,
    on(PersonActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(PersonActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllPerson = selectAll;
export const selectPersonEntities = selectEntities;

export const selectPersonFetched = (state: PersonState) => state.fetched;
export const selectPersonLatestUpdatedAt = (state: PersonState) => state.latestUpdatedAt;
