import {EmailFolderType, EmailType} from '../graphql-types';
import {BackendDate} from '../helper/backend-frontend-conversion.helper';
export enum EmailFolderEntityType {
    Root = 'root',
    Inbox = 'inbox',
    Drafts = 'drafts',
    Sent = 'sent',
    Trash = 'trash',
    Junk = 'junk',
    Archive = 'archive',
}
export class EmailFolderEntity {
    constructor(
        public Id: number = null,
        public EmailConnectionId: number = null,
        public ParentId: number = null,
        public UserId: number = null,
        public FolderPath: string = null,
        public Type: EmailFolderEntityType = null,
        public Name: string = null,
        public DeletedAt: Date = null,
        public EmailCount: number = null,
        public EmailUnreadCount: number = null,
    ) {
    }

    public Clone(override: EmailFolderEntityAttributes = {}): EmailFolderEntity {
        const attr = {...this, ...override};
        return Object.assign(new EmailFolderEntity(), attr);
    }
}

export function EmailFolderEntityFromBackend(res: EmailFolderType): EmailFolderEntity {
    return new EmailFolderEntity(
        res.id,
        res.emailConnectionId,
        res.parentId,
        res.userId,
        res.folderPath,
        res.type?.toLowerCase() as any,
        res.name,
        res.deletedAt ? BackendDate(res.deletedAt) : null,
        res.emailCount,
        res.emailUnreadCount
    );
}


export interface EmailFolderEntityAttributes {
    Id?: number
    EmailConnectionId?: number
    ParentId?: number
    UserId?: number
    FolderPath?: string
    Type?: string
    Name?: string
    DeletedAt?: Date
    EmailCount?: number
    EmailUnreadCount?: number
}
