import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {combineLatest, firstValueFrom, Observable, Subject, Subscription} from 'rxjs';
import { distinctUntilChanged, filter, map, skip, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ComplaintTypesEnum } from 'src/app/dave-data-module/entities/complaintType.entity';
import { ComplaintTypeResolver } from 'src/app/dave-data-module/guards/complaintType.resolver';
import { BaseActionTypes } from 'src/app/dave-data-module/State/actions/base.actions';
import { Person2EntityActionTypes } from 'src/app/dave-data-module/State/actions/person2entity.action';
import { getComplaintTypes, getComplaintTypesFetched } from 'src/app/dave-data-module/State/selectors/complaintType.selectors';
import { PersonModalComponent, PersonModalComponentDialogData } from 'src/app/dave-person-module/components/person-modal/person-modal';
import { SelectSearchData } from 'src/app/dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { LoadingService } from 'src/app/services/loading.service';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { DamageFlowEntity } from '../../../dave-data-module/entities/damageflow.entity';
import { EventTypeEntity } from '../../../dave-data-module/entities/event-type.entity';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import { getCommissionById, getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomerById, getCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getDamageFlowByCommissionId } from '../../../dave-data-module/State/selectors/damageflow.selector';
import { getEventTypes } from '../../../dave-data-module/State/selectors/event-type.selector';
import { getOffices } from '../../../dave-data-module/State/selectors/offices.selectors';
import { getPersonById, getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import {hasRequiredField, IDetailListTemplateData} from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, OpenHTMLInputPicker, sameDay } from "../../../helper/helper";
import { getErrorMessage, startTimeBeforeEndTimeValidator } from '../../../helper/validation.helper';
import { CustomLabelService } from "../../../services/custom-label.service";
import {
    getProcessById,
    getProcessesActive,
    getProcessFetched
} from "../../../dave-data-module/State/selectors/process.selector";
import { ProcessEntity } from "../../../dave-data-module/entities/process.entity";
import { ProcessResolver } from "../../../dave-data-module/guards/process.resolver";
import moment, { Moment } from "moment";

export interface NewOnSiteEventComponentDialogData {
    CustomerId?: number;
    CommissionId?: number;
    IsNotOrtstermin?: boolean;
    Street?: string;
    PostalCode?: string;
    City?: string;
    Country?: string;
    PersonId?: number;
    ProcessId?: number;
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'new-on-site-event',
    templateUrl: 'new-on-site-event.component.html',
    styleUrls: ['new-on-site-event.component.scss'],
})
export class NewOnSiteEventComponent implements OnInit, OnDestroy {
    public AddPersonClicked = new Subject();
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        hasBackdrop: false,
    };
    public OpenHTMLInputPicker = OpenHTMLInputPicker;
    public PersonList: PersonEntity[] = [];
    protected ProcessList$ = this.store.select(getProcessesActive);
    public CommissionList: SelectSearchData[] = [];
    public CustomerList: SelectSearchData[] = [];
    // Other
    public GetErrorMessage = getErrorMessage;
    public UploadOpen = false;
    // 'updateOn: blur' sodass nicht permanent Events geworfen werden
    public EventTypeList: EventTypeEntity[] = null;
    public NewEventForm = new FormGroup(
        {
            EventType: new FormControl<EventTypeEntity>(null, Validators.required),
            Person: new FormControl<PersonEntity | null>(null),
            Process: new FormControl<ProcessEntity | null>(null),
            Commission: new FormControl<SelectSearchData | null>(null),
            Customer: new FormControl<SelectSearchData | null>(null),
            EventDate: new FormControl<Moment>(moment(), Validators.required),
            EventEndDate: new FormControl<Moment>(moment(), Validators.required),
            EventStartTimeString: new FormControl<string>('', {
                validators: Validators.required,
                updateOn: 'blur',
            }),
            EventEndTimeString: new FormControl<string>('', {
                validators: Validators.required,
                updateOn: 'blur',
            }),
            Note: new FormControl<string>('', {
                updateOn: 'blur',
            }),
            Name: new FormControl<string>('' , {
                updateOn: 'blur',
            })
        },
        startTimeBeforeEndTimeValidator,
    );
    public Customer: CustomerEntity = null;
    public Commission: CommissionEntity = null;
    public DamageFlow: DamageFlowEntity = null;
    protected EventType: EventTypeEntity = null;
    private subscriptions: Subscription[] = [];

    public SearchLocationForm = new UntypedFormControl(null);
    public AddressForm = new FormGroup({
        Street: new FormControl(this.Dialogdata.Street ? this.Dialogdata.Street : ''),
        PostalCode: new FormControl(this.Dialogdata.PostalCode ? this.Dialogdata.PostalCode : ''),
        City: new FormControl(this.Dialogdata.City ? this.Dialogdata.City : ''),
        Country: new FormControl(this.Dialogdata.Country ? this.Dialogdata.Country : ''),
    });
    public AddressTemplateData$: Observable<IDetailListTemplateData> = combineLatest([this.store.select(getOffices).pipe(map((o) => o.filter((o) => o.Street))), this.store.select(getCustomers)]).pipe(
        map(([offices, customers]) => {
            return {
                Headline: 'Adresse',
                HeaderIcon: 'map-marker-alt',
                Properties: [
                    {
                        key: 'Adressen durchsuchen',
                        formControl: this.SearchLocationForm,
                        options: {
                            specialInput: {
                                selectSearch: offices.map((o) => ({
                                    optionValue: o.Id,
                                    optionLabel: [customers.find((c) => c.Id === o.CustomerId)?.Name, '-', o.Street, o.PostalCode, o.City, o.Country].join(' '),
                                })),
                            },
                        },
                    },
                    {
                        key: 'Adresse',
                        options: {
                            type: CustomPropertyType.Location,
                            specialInput: {
                                location: {
                                    value: this.AddressForm.value,
                                    formGroup: this.AddressForm,
                                },
                            },
                        },
                    },
                ],
            };
        }),
    );

    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: NewOnSiteEventComponentDialogData,
        public CS: CustomerNameService,
        private cdr: ChangeDetectorRef,
        public LS: LoadingService,
        protected dialogRef: MatDialogRef<NewOnSiteEventComponent>,
        protected cls: CustomLabelService,
        processResolver: ProcessResolver,
    ) {
        firstValueFrom(this.store.select(getProcessFetched)).then(f => {
            if (!f) {
                processResolver.resolve();
            }
        })
        if (this.Dialogdata.CustomerId) {
            this.NewEventForm.controls.Person.setValue(null);
        }
    }

    get Error() {
        return this.NewEventForm.hasError('startTimeBeforeEndTimeValidator') ? 'Die Start-Zeit muss vor der Ende-Zeit liegen.' : '';
    }

    ngOnInit() {
        this.subscriptions.push(
            this.NewEventForm.controls.EventType.valueChanges.pipe(distinctUntilChanged((a, b) => a?.Id === b?.Id)).subscribe( eventType => {
               this.NewEventForm.controls.Name.setValue(eventType.Name);
            }),
            this.NewEventForm.controls.Commission.valueChanges.pipe(distinctUntilChanged((a, b) => a?.Id === b?.Id)).subscribe((commission) => {
                if (commission?.Id) {
                    this.store
                        .select(getCommissionById({ id: commission.Id }))
                        .pipe(take(1))
                        .subscribe((com) => {
                            this.store
                                .select(getCustomerById({ id: com.CustomerId }))
                                .pipe(take(1))
                                .subscribe((c) => {
                                    this.NewEventForm.controls.Customer.setValue({ Id: c.Id, Name: c.DisplayName });
                                    // For some reason NewEventForm.controls.Commission is set to a wrong Commission after .Customer.setValue(). Makes no sense but this fixes it.
                                    this.NewEventForm.controls.Commission.setValue({ Id: com.Id, Name: com.DisplayName});
                                    this.cdr.detectChanges();
                                });
                        });
                }
            }),
            this.NewEventForm.controls.Customer.valueChanges.pipe(distinctUntilChanged((a, b) => a?.Id === b?.Id)).subscribe((customer) => {
                if (!!customer && !!customer?.Id) {
                    if (this.NewEventForm.value.Commission?.Id) {
                        this.store
                            .select(getCommissionById({ id: this.NewEventForm.value.Commission.Id }))
                            .pipe(take(1))
                            .subscribe((com) => {
                                if (com.CustomerId !== customer.Id) {
                                    this.NewEventForm.controls.Commission.reset();
                                }
                            });
                    }
                    this.store
                        .select(getCommissions)
                        .pipe(
                            map((commissions) => commissions.filter((c) => !c.Deleted && c.CustomerId === customer.Id)),
                            map((commissions) => [
                                { Id: null, Name: '--' },
                                ...commissions.map((c) => {
                                    return { Id: c.Id, Name: c.DisplayName };
                                }),
                            ]),
                        )
                        .subscribe((c) => {
                            this.CommissionList = c;
                        });
                } else {
                    this.store
                        .select(getCommissions)
                        .pipe(
                            map((commissions) => commissions.filter((c) => !c.Deleted)),
                            map((commissions) => [
                                { Id: null, Name: '--' },
                                ...commissions.map((c) => {
                                    return { Id: c.Id, Name: c.DisplayName };
                                }),
                            ]),
                        )
                        .subscribe((c) => {
                            this.CommissionList = c;
                        });
                }
            }),
            this.SearchLocationForm.valueChanges.pipe(skip(1), filter(isNotNullOrUndefined), withLatestFrom(this.store.select(getOffices))).subscribe(([v, o]) => {
                const address = o.find((o) => o.Id === v.Id);
                this.AddressForm.setValue({
                    Street: address.Street,
                    PostalCode: address.PostalCode,
                    City: address.City,
                    Country: address.Country,
                });
            }),
            combineLatest([
                this.store.select(getPersons).pipe(
                    filter(isNotNullOrUndefined),
                    map((p) => p.filter((pe) => !pe.Deleted)),
                ),
                this.store.select(getPerson2Entities).pipe(
                    filter(isNotNullOrUndefined),
                    map((pes) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Customer || pe.EntityType === Person2EntityEntityTypeEnum.Commission)),
                ),
            ])
                .pipe(
                    map(([persons, person2Entities]) =>
                        this.Dialogdata.CustomerId || this.Dialogdata.CommissionId
                            ? persons.filter((p) =>
                                  person2Entities.some(
                                      (pe) =>
                                          pe.PersonId === p.Id &&
                                          ((pe.EntityType === Person2EntityEntityTypeEnum.Customer && pe.EntityId === this.Dialogdata.CustomerId) ||
                                              (pe.EntityType === Person2EntityEntityTypeEnum.Commission && pe.EntityId === this.Dialogdata.CommissionId)),
                                  ),
                              )
                            : persons,
                    ),
                )
                .subscribe((c) => (this.PersonList = c)),
            this.store
                .select(getCustomers)
                .pipe(
                    tap((cu) => (this.Customer = cu.find((c) => c.Id === this.Dialogdata.CustomerId))),
                    map((customers) => customers.filter((c) => !c.Deleted)),
                    map((c) => [
                        { Id: null, Name: '--' },
                        ...c.map((cus) => {
                            return { Id: cus.Id, Name: cus.DisplayName };
                        }),
                    ]),
                )
                .subscribe((customers) => {
                    this.CustomerList = customers;
                }),
            this.store
                .select(getCommissions)
                .pipe(
                    tap((co) => (this.Commission = co.find((c) => c.Id === this.Dialogdata.CommissionId))),
                    map((commissions) => commissions.filter((c) => !c.Deleted)),
                    map((c) => [
                        { Id: null, Name: '--' },
                        ...c.map((com) => {
                            return { Id: com.Id, Name: com.DisplayName };
                        }),
                    ]),
                )
                .subscribe((c) => {
                    this.CommissionList = c;
                }),
            this.store.select(getEventTypes).subscribe((types) => {
                this.EventTypeList = types;
                if (!this.Dialogdata.IsNotOrtstermin) {
                    this.NewEventForm.controls.EventType.setValue(types.find((t) => t.Name.toLowerCase().includes('ortstermin')));
                }
            }),
            this.store.select(getDamageFlowByCommissionId({ commissionId: this.Dialogdata.CommissionId })).subscribe((damageFlow) => {
                this.DamageFlow = damageFlow;
                this.AddressForm.setValue({
                    Street: this.Dialogdata.Street ? this.Dialogdata.Street : damageFlow?.Street || '',
                    PostalCode: this.Dialogdata.PostalCode ? this.Dialogdata.PostalCode : damageFlow?.PostalCode || '',
                    City: this.Dialogdata.City ? this.Dialogdata.City : damageFlow?.City || '',
                    Country: this.Dialogdata.Country ? this.Dialogdata.Country : damageFlow?.Country || '',
                });
            }),
        );
        if (this.Dialogdata.PersonId) {
            this.store
                .select(getPersonById({ id: this.Dialogdata.PersonId }))
                .pipe(take(1))
                .subscribe((person) => {
                    this.NewEventForm.controls.Person.setValue(person);
                });
        }
        if (this.Dialogdata.ProcessId) {
            firstValueFrom(this.store.select(getProcessById({ id: this.Dialogdata.ProcessId })))
                .then((process) => {
                    this.NewEventForm.controls.Process.setValue(process);
                });
        }
        if (!this.NewEventForm.controls.EventDate.value) {
            const startDate = new Date();
            startDate.setHours(0, 0, 0, 0);
            this.NewEventForm.controls.EventDate.setValue(moment(startDate));
        }
        if (!this.NewEventForm.controls.EventEndDate.value) {
            const endDate = new Date();
            endDate.setHours(0, 0, 0, 0);
            this.NewEventForm.controls.EventEndDate.setValue(moment(endDate));
        }
        const date = new Date();
        const h = date.getHours();
        const m = date.getMinutes();
        const timeString = `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}`;

        if (!this.NewEventForm.controls.EventStartTimeString.value) {
            this.NewEventForm.controls.EventStartTimeString.setValue(timeString);
        }

        if (!this.NewEventForm.controls.EventEndTimeString.value) {
            this.NewEventForm.controls.EventEndTimeString.setValue(timeString);
        }

        if (typeof this.NewEventForm.controls.Note.value !== 'string') {
            this.NewEventForm.controls.Note.setValue('');
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    public OnEventTypeChange(eventType: EventTypeEntity) {
        this.NewEventForm.controls.EventType.setValue(eventType);
    }
    public Submit(event?: any) {
        if (this.NewEventForm.invalid) {
            return;
        }
        const { Person, EventDate, EventEndDate, EventEndTimeString, EventStartTimeString, Note, Process } = this.NewEventForm.value;
        const { Street, PostalCode, City, Country } = this.AddressForm.value;

        const eventDate = EventDate?.toDate();
        const tempEventEndDate = EventEndDate?.toDate();
        const eventEndDate = new Date(
            tempEventEndDate.getFullYear(),
            tempEventEndDate.getMonth(),
            tempEventEndDate.getDate(),
            +EventEndTimeString.split(':')[0],
            +EventEndTimeString.split(':')[1],
            0,
            0
        );
        eventDate.setHours(+EventStartTimeString.split(':')[0]);
        eventDate.setMinutes(+EventStartTimeString.split(':')[1]);
        eventDate.setSeconds(0);
        eventDate.setMilliseconds(0);

        this.store.dispatch(
            EventsActionTypes.AddEvent({
                Payload: {
                    personId: Person?.Id,
                    customerId: this.Dialogdata.CustomerId || this.NewEventForm.value.Customer?.Id || null,
                    hint: null,
                    street: Street,
                    city: City,
                    postalCode: PostalCode,
                    country: Country,
                    commissionId: this.Dialogdata.CommissionId || this.NewEventForm.value.Commission?.Id || null,
                    eventTypeId: this.NewEventForm.value.EventType?.Id,
                    eventDate: FrontendDate(eventDate),
                    eventEndDate: FrontendDate(eventEndDate),
                    description: Note || '',
                    private: false,
                    name: this.NewEventForm.value.Name,
                    processId: Process?.Id || null,
                },
            }),
        );
        this.dialogRef?.close();
    }

    public CreateNewPerson() {
        this.AddPersonClicked.next(null);
    }

    protected compareById(a,b) {
        return a.Id === b.Id;
    }

    protected readonly sameDay = sameDay;
}
