import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../dave-utils-module/app-filter-module/app-filter.module';
import { DaveListComponent } from './components/dave-list/dave-list.component';
import { MatDividerModule } from '@angular/material/divider';


@NgModule({
    declarations: [DaveListComponent],
    exports: [
        DaveListComponent,
    ],
    imports: [
        CommonModule,
        MatTooltipModule,
        AppButtonModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        MatSidenavModule,
        AppFilterModule,
        ScrollingModule,
        MatTableModule,
        MatSortModule,
        RouterModule,
        ReactiveFormsModule,
        TableVirtualScrollModule,
        DaveLoadingModule,
        DaveDoubleIconModule,
        MatBadgeModule,
        MatDividerModule,
        MatCheckboxModule,
    ],
})
export class DaveListModule {
}
