import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { State } from '../index';
import { ARLTemplateTypeActions } from '../actions/accounting.actions';
import { ARLTemplateTypeEntity, ARLTemplateTypeEntityFromBackend } from '../../entities/a-r-l-template-type.entity';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { getARLTemplateTypes } from '../selectors/accounting.selector';


enum ErrorCodes {
    Load = 'Buchungspositionstypen abrufen fehlgeschlagen',
    Add = 'Buchungspositionstypen hinzufügen fehlgeschlagen',
    Delete = 'Buchungspositionstypen löschen fehlgeschlagen',
}

@Injectable()
export class ARLTemplateTypeEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
    ) {}

    GetAllAccountsReceivableLedgerQuery$ = createEffect(() => this.actions$.pipe(
        ofType(ARLTemplateTypeActions.GetARLTemplateTypes),
        switchMap(() => {
            const queryString = `query {
          aRLTemplateType {${ARLTemplateTypeEntity.GqlFields}}
        }
        `;
            return this.gatewayHttpService.graphQl({ query: queryString }, {retry: true}).pipe(
                map(res =>
                    res && res.aRLTemplateType
                        ? ARLTemplateTypeActions.UpdateARLTemplateType({
                            Payload: res.aRLTemplateType.map(f => ARLTemplateTypeEntityFromBackend(f)),
                        })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    AddARLTemplateType$ = createEffect(() => this.actions$.pipe(
        ofType(ARLTemplateTypeActions.AddARLTemplateType),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                createARLTemplateTypeType(
                  ${stringifyIfNotNullOrUndefined(action.Payload, 'name')}
                ) {${ARLTemplateTypeEntity.GqlFields}}
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                withLatestFrom(this.store$.select(getARLTemplateTypes)),
                concatMap(([res, arltts]) => {
                    return res && res.createARLTemplateTypeType
                        ? [
                            ARLTemplateTypeActions.UpdateARLTemplateType({
                                Payload: [
                                    ...(arltts ? arltts : []),
                                    ARLTemplateTypeEntityFromBackend(res.createARLTemplateTypeType),
                                ],
                            }),
                        ]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } })];
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    DeleteARLTemplateType$ = createEffect(() => this.actions$.pipe(
        ofType(ARLTemplateTypeActions.DeleteARLTemplateType),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                deleteARLTemplateTypeType(
                  ${stringifyIfNotNullOrUndefined(action.Payload, 'id')}
                )
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                withLatestFrom(this.store$.select(getARLTemplateTypes)),
                concatMap(([res, arltts]) => {
                    return res && res.deleteARLTemplateTypeType
                        ? [
                            ARLTemplateTypeActions.UpdateARLTemplateType({
                                Payload: arltts.filter(a => a.Id !== action.Payload.id),
                            }),
                        ]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Delete } })];
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Delete,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

}
