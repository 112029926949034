<div class="wrapper">
    <div class="header dave-background-image">
        <h2 mat-dialog-title>Bestätigung Ihrer Bestellung</h2>
    </div>
    <ngx-simplebar>
        <div class="content">
            <mat-card>
                <span *ngIf="GoodLink">
                    Wir bestätigen gerade Ihre Bestellung!
                </span>
                <span *ngIf="!GoodLink">
                    Ungültiger Link! Bitte überprüfen Sie den Link und wenden sich gegebenenfalls an
                    unseren Kundenservice.
                </span>
            </mat-card>
        </div>
    </ngx-simplebar>
</div>
