import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { TextTemplateLanguageEntity } from '../../../dave-data-module/entities/text-template-language.entity';
import { TextTemplateLanguageResolver } from '../../../dave-data-module/guards/text-template-language.resolver';
import { State } from '../../../dave-data-module/State';
import { getTextTemplateLanguage, getTextTemplateLanguageFetched } from '../../../dave-data-module/State/selectors/text-template-language.selectors';
import { isoCountryCodeToFlagEmoji } from '../../../helper/helper';
import {
    SelectTextTemplatesComponent,
    SelectTextTemplatesComponentDialogData,
    SelectTextTemplatesComponentDialogResult,
} from '../select-text-templates/select-text-templates.component';

@Component({
    selector: 'app-text-area-templates',
    templateUrl: './text-area-templates.component.html',
    styleUrls: ['./text-area-templates.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: TextAreaTemplatesComponent,
        },
    ],
})
export class TextAreaTemplatesComponent implements ControlValueAccessor/*<number[]>*/ {
    private textTemplateIds: number[] = [];
    private textTemplateIds$: BehaviorSubject<number[]> = new BehaviorSubject([]);
    public Language$: BehaviorSubject<string> = new BehaviorSubject('DE');
    public AvailableCountries = [
        {
            code: 'DE',
            label: 'Deutsch',
            icon: isoCountryCodeToFlagEmoji('de'),
        },
        {
            code: 'NL',
            label: 'Nederlands',
            icon: isoCountryCodeToFlagEmoji('nl'),
        },
        {
            code: 'GB',
            label: 'English',
            icon: isoCountryCodeToFlagEmoji('gb'),
        },
        {
            code: 'PL',
            label: 'Polski',
            icon: isoCountryCodeToFlagEmoji('pl'),
        },
    ];
    public ShownTextTemplates$: Observable<TextTemplateLanguageEntity[]> = this.store.select(getTextTemplateLanguageFetched).pipe(
        tap(fetched => {
            if (!fetched) {
                this.textTemplateLangResolver.resolve();
            }
        }),
        filter(fetched => !!fetched),
        switchMap(() =>
            this.store.select(getTextTemplateLanguage).pipe(
                switchMap(templates =>
                    this.textTemplateIds$.pipe(
                        switchMap(ids => {
                            const tempsById = templates.filter(t => ids.includes(t.TextTemplateId));
                            return this.Language$.pipe(
                                map(lang => tempsById.filter(t => t.Language === lang)),
                                map(tempsByLang => ids.map(id => tempsByLang.find(t => t.TextTemplateId === id))),
                            );
                        }),
                    ),
                ),
            ),
        ),
    );
    constructor(private dialog: MatDialog, private textTemplateLangResolver: TextTemplateLanguageResolver, private store: Store<State>) {}
    onChange = (value: number[]) => {};

    onTouched = () => {};

    touched = false;

    disabled = false;

    writeValue(textTemplateIds: number[]) {
        this.textTemplateIds = textTemplateIds;
        this.textTemplateIds$.next(this.textTemplateIds);
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
    // OnAdd(id: number) {
    //     this.markAsTouched();
    //     if (!this.disabled) {
    //         this.textTemplateIds.push(id);
    //         this.onChange(this.textTemplateIds);
    //     }
    // }
    //
    // OnRemove(id) {
    //     this.markAsTouched();
    //     if (!this.disabled) {
    //         this.textTemplateIds.splice(this.textTemplateIds.indexOf(id), 1);
    //         this.onChange(this.textTemplateIds);
    //     }
    // }
    OpenDialog() {
        this.dialog
            .open<SelectTextTemplatesComponent, SelectTextTemplatesComponentDialogData, SelectTextTemplatesComponentDialogResult>(SelectTextTemplatesComponent, {
                ...SelectTextTemplatesComponent.DefaultConfig,
                data: {
                    SelectedTextTemplatesIds: this.textTemplateIds$.value,
                    Language: this.Language$.value,
                },
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(v => {
                if (v) {
                    this.markAsTouched();
                    if (!this.disabled) {
                        this.writeValue(v.textTemplates.map(t => t.Id));
                        this.onChange(this.textTemplateIds);
                    }
                }
            });
    }
    public Move(templateId: number, direction: 'up' | 'down') {
        const arr = this.textTemplateIds$.value.slice();
        const i = arr.indexOf(templateId);
        arr.splice(i, 1);
        arr.splice(direction === 'up' ? i - 1 : i + 1, 0, templateId);
        this.writeValue(arr);
        this.onChange(arr);
    }
}
