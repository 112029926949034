import { formatDate } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ResolveData, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { ViewStyleConfig } from '../../../dave-data-module/entities/viewStyleSetting.entity';
import { GeneratedDocumentsResolver } from '../../../dave-data-module/guards/generatedDocuments.resolver';
import { GeneratedDocumentsTypeResolver } from '../../../dave-data-module/guards/generatedDocumentsType.resolver';
import { ResourceScheduleResolver } from '../../../dave-data-module/guards/resource-dispo/resource-scheduler.resolver';
import { ResourceTypeResolver } from '../../../dave-data-module/guards/resource-dispo/resource-type.resolver';
import { ResourceResolver } from '../../../dave-data-module/guards/resource-dispo/resource.resolver';
import { TokenResolver } from '../../../dave-data-module/guards/token.resolver';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { State } from '../../../dave-data-module/State';
import { DaveActions } from '../../../dave-data-module/State/actions/actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { ResourceActionTypes } from '../../../dave-data-module/State/actions/resource-dispo/resource.actions';
import { generatedDocumentsFeatureKey } from '../../../dave-data-module/State/reducers/generatedDocuments.reducer';
import { generatedDocumentsTypesFeatureKey } from '../../../dave-data-module/State/reducers/generatedDocumentsType.reducer';
import { RESOURCE_TYPE_KEY } from '../../../dave-data-module/State/reducers/resource-dispo/resource-type.reducer';
import { RESOURCE_KEY } from '../../../dave-data-module/State/reducers/resource-dispo/resource.reducer';
import { getCommissionDictionary, getCommissionsFetched } from '../../../dave-data-module/State/selectors/commission.selector';
import { getGeneratedDocumentsSortedByCreatedAt } from '../../../dave-data-module/State/selectors/generatedDocuments.selectors';
import { getGeneratedDocumentsTypes } from '../../../dave-data-module/State/selectors/generatedDocumentsType.selectors';
import { getResourceSchedules, getResourceSchedulesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource-schedule.selectors';
import { getResourceById, getResourcesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { AllCommissionMeta, AllGeneratedDocumentsMeta, CommissionMeta, DMSPageMeta, DocumentEditorMeta, GeneratedDocumentsPageMeta } from '../../../helper/page-metadata';
import { CustomLabelService } from '../../../services/custom-label.service';
import { LoadingService } from '../../../services/loading.service';
import { ResourceMainDataComponent } from '../resource-main-data/resource-main-data.component';

interface ResourceScheduleTableData {
    Amount: number;
    Type: string;
    CommissionName: string;
    TimeSpan: string;
    startDate: Date;
    commissionId: number;
}

@Component({
    selector: 'app-resource-detail-view',
    templateUrl: './resource-detail-view.component.html',
    styleUrls: ['./resource-detail-view.component.scss'],
})
export class ResourceDetailViewComponent {
    public static readonly RequiredResolvers: ResolveData = {
        token: TokenResolver,
        [RESOURCE_KEY]: ResourceResolver,
        [RESOURCE_TYPE_KEY]: ResourceTypeResolver,
        [generatedDocumentsFeatureKey]: GeneratedDocumentsResolver,
        [generatedDocumentsTypesFeatureKey]: GeneratedDocumentsTypeResolver,
    };
    @ViewChild('resourceData') resourceData: ResourceMainDataComponent;
    public Editing = false;
    public CommissionMeta = CommissionMeta;
    protected reportsView: ViewStyleConfig = {
        type: '',
        order: -1,
        headline: 'Berichte',
        headlineIcon: GeneratedDocumentsPageMeta.Icon,
        expanded: true,
    };

    public ResourceId$ = this.route.paramMap.pipe(
        map((paramMap) => paramMap.get('resourceId')),
        map((id) => +id || null),
        distinctUntilChanged(),
        tap((id) => (this.Editing = !id)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public Resource$ = this.store.select(getResourcesFetched).pipe(
        filter((v) => !!v),
        switchMap(() => this.ResourceId$),
        distinctUntilChanged(),
        switchMap((id) => (id ? this.store.select(getResourceById({ id })) : of(null))),
    );

    public ResourceScheduleTableData$ = this.ResourceId$.pipe(
        switchMap((rId) =>
            combineLatest([
                this.store.select(getResourceSchedulesFetched).pipe(
                    filter((v) => !!v),
                    switchMap(() => this.store.select(getResourceSchedules)),
                ),
                this.store.select(getCommissionsFetched).pipe(
                    filter((v) => !!v),
                    switchMap(() => this.store.select(getCommissionDictionary)),
                ),
            ]).pipe(
                map(([schedules, commissions]) => {
                    return new MatTableDataSource<ResourceScheduleTableData>(
                        schedules
                            .filter((s) => s.ResourceId === rId)
                            .map((s) => {
                                // todo nach type = commission filtern
                                return {
                                    Amount: s.Amount,
                                    Type: s.Type,
                                    CommissionName: s.CommissionId ? commissions[s.CommissionId].DisplayName : '',
                                    TimeSpan: formatDate(s.StartDate, 'shortDate', 'de-DE') + ' - ' + formatDate(s.EndDate, 'shortDate', 'de-DE'),
                                    startDate: s.StartDate,
                                    commissionId: s.CommissionId,
                                };
                            }),
                    );
                }),
                tap(
                    (dataSource) =>
                        (dataSource.sortingDataAccessor = (object, key) => {
                            switch (key) {
                                case 'TimeSpan':
                                    return object.startDate.getTime();
                                case 'Amount':
                                    return object[key];
                                default:
                                    return object[key].trim().toLowerCase();
                            }
                        }),
                ),
            ),
        ),
    );
    public fileExplorerLoading = new BehaviorSubject<boolean>(true);
    public FolderId$ = this.ResourceId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((rId) => this.folderDataService.getFolderFromEntity(rId, FolderTypes.resource)),
        map((folder) => folder?.Id),
        tap(() => this.fileExplorerLoading.next(false)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    constructor(
        private actions$: Actions<DaveActions>,
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<State>,
        public LS: LoadingService,
        protected dialog: MatDialog,
        resourceScheduleResolver: ResourceScheduleResolver,
        protected cls: CustomLabelService,
        private folderDataService: FolderDataService,
    ) {
        resourceScheduleResolver.resolve();
    }
    onSave() {
        this.actions$.pipe(ofType(ResourceActionTypes.UpdateOne, BaseActionTypes.ErrorAction), first(), ofType(ResourceActionTypes.UpdateOne)).subscribe((event) => {
            this.LS.endLoading('saveResource');
            this.router.navigate(['../', event.Payload.Id], { relativeTo: this.route });
        });
        this.resourceData.SaveForm();
        this.LS.startLoading('saveResource');
    }
    onUndo() {
        this.resourceData.ResetForm();
        firstValueFrom(this.ResourceId$).then((id) => {
            if (id) {
                this.Editing = false;
            }
        });
    }
    onDelete() {
        this.actions$.pipe(ofType(ResourceActionTypes.RemoveOne, BaseActionTypes.ErrorAction), first()).subscribe(() => {
            this.LS.endLoading('deleteResource');
            this.resourceData.ResetForm();
            this.Editing = false;
        });
        firstValueFrom(this.ResourceId$).then((id) => {
            this.store.dispatch(ResourceActionTypes.Delete({ Payload: { id: String(id) } }));
            this.LS.startLoading('deleteResource');
        });
    }
    ResourceScheduleTableDataRouterLink(entry: ResourceScheduleTableData) {
        return [CommissionMeta.Path, AllCommissionMeta.Path, entry.commissionId];
    }
    GetRouteForGenDoc = (doc: { Id: number }) => ['/', GeneratedDocumentsPageMeta.Path, AllGeneratedDocumentsMeta.Path, DocumentEditorMeta.Path, doc.Id];
    reportsData$ /*: Observable<{ type: GeneratedDocumentsTypeEntity; Name: string; Id: number; date: string; label: string;  }[]>*/ = combineLatest([
        this.store.select(getGeneratedDocumentsSortedByCreatedAt).pipe(filter(isNotNullOrUndefined)),
        this.store.select(getGeneratedDocumentsTypes).pipe(filter(isNotNullOrUndefined)),
        this.Resource$,
    ]).pipe(
        map(([genFiles, genFilesType, resource]) => {
            if (!resource?.Id) {
                return [];
            }
            return genFiles
                .filter((e) => e.ResourceId === resource.Id)
                .map((e) => ({
                    type: genFilesType?.find((gft) => gft.Id === e.GeneratedDocumentsTypeId),
                    date: formatDate(e.CreatedAt, 'shortDate', 'de-DE'),
                    Name: e.Name || 'Platzhalter',
                    Id: e.Id,
                    suffixButton: e.DocumentIdFromPdf
                        ? {
                              icon: DMSPageMeta.Icon,
                              tooltip: 'Zum Dokument',
                              onClick: (args: any) => {
                                  this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
                                      ...DaveFilePreviewComponent.DefaultConfig,
                                      data: {
                                          fileId: e.DocumentIdFromPdf,
                                      },
                                  });
                              },
                          }
                        : null,
                }))
                .map((v) => Object.assign(v, { label: `${v.type?.Name || 'Typ'} ${v.Name} ${v.date}` }));
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
}
