import {createReducer, on} from '@ngrx/store';
import {EmailFolderEntity} from '../../entities/emailFolder.entity';
import {EmailFolderActionTypes} from '../actions/email-folder.actions';


export const emailFoldersFeatureKey = 'emailFolders';

export interface EmailFolderState {
    emailFolders: EmailFolderEntity[];
}

export const initialState: EmailFolderState = {
    emailFolders: null,
};

export const EmailFolderReducer = createReducer(
    initialState,
    on(EmailFolderActionTypes.UpdateEmailFolders, (state, {Payload}) => ({
        ...state,
        emailFolders: Payload,
    })),
);
