import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { ProcessTemplateEntityFromBackend } from '../../entities/process-template.entity';
import { AppGatewayService } from '../../services/app-gateway.service';
import { BaseActionTypes } from '../actions/base.actions';
import { ProcessTemplateActions } from '../actions/process-template.actions';
import { State } from '../index';

enum ErrorCodes {
    Load = 'Prozessvorlagen Abrufen fehlgeschlagen',
    Modify = 'Prozessvorlagen Bearbeiten fehlgeschlagen',
    Remove = 'Prozessvorlagen Löschen fehlgeschlagen',
    Add = 'Prozessvorlagen Hinzufügen fehlgeschlagen',
}

@Injectable()
export class ProcessTemplateEffects {
    GetProcessTemplate = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessTemplateActions.load),
            switchMap(() =>
                this.gatewayService
                    .GetEntity('ProcessGetTemplates')
                    .then((res) => {
                        console.log(res)
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return ProcessTemplateActions.updateAll({ Payload: res.ProcessGetTemplates.ProcessTemplates.map(ProcessTemplateEntityFromBackend), updateLatestUpdatedAt: true });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayService: AppGatewayService) {}
}
