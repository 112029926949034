<ng-container [ngSwitch]="inputMode">
<!--    <mat-form-field [floatLabel]="floatLabel" *ngSwitchCase="'selectSearch'">-->
<!--        <input type="text" [placeholder]="placeholder" aria-label="{{placeholder}}" matInput [formControl]="informationControl" [matAutocomplete]="auto" />-->
<!--        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onSelected($event)">-->
<!--            &lt;!&ndash;        <mat-optgroup [label]="ResourcePageMeta.Breadcrumb">&ndash;&gt;-->
<!--            <mat-option *ngFor="let option of options$ | async" [value]="option.value" [id]="option.resourceId + ''">-->
<!--                {{ option.label }}-->
<!--            </mat-option>-->
<!--            &lt;!&ndash;        </mat-optgroup>&ndash;&gt;-->

<!--        </mat-autocomplete>-->
<!--    </mat-form-field>-->
    <mat-form-field [floatLabel]="floatLabel" *ngSwitchCase="'selectSearch'">
        <app-select-search
            [placeholder]="placeholder"
            [ngModel]="selectSearchForm.value"
            (ngModelChange)="onDropDownSelected($event)"
            [Options]="options$ | async"
            [CompareOptions]="compareOptions"
            [SearchFunction]="searchOptionsFunction"
            [panelClass]="matSelectPanelClass$ | async"
        ></app-select-search>
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabel" *ngSwitchDefault>
        <input type="text" [placeholder]="placeholder" aria-label="{{placeholder}}" matInput [formControl]="informationControl" [matAutocomplete]="auto" />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onAutocompleteSelected($event)">
            <!--        <mat-optgroup [label]="ResourcePageMeta.Breadcrumb">-->
            <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option.value" [id]="option.entityId + ''">
                {{ option.optionLabel }}
            </mat-option>
            <!--        </mat-optgroup>-->

        </mat-autocomplete>
    </mat-form-field>
</ng-container>
