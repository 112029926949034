<div class="buttons">
    <a class="button" app-round-button matTooltip="Route" target="_blank" [href]="Url">
        <fa-icon icon="directions"></fa-icon>
    </a>
    <button class="button" app-round-button Inverted mat-dialog-close matTooltip="Schließen">
        <fa-icon icon="times"></fa-icon>
    </button>
</div>
<ng-container *ngIf="(Ready$ | async) && (MapOptions$ | async); else loading">
    <google-map *ngIf="MapOptions$ | async as options" [options]="options">
        <map-marker [position]="{ lat: options.center.lat, lng: options.center.lng }" [options]="{ draggable: false }"></map-marker>
    </google-map>
</ng-container>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
