import { GeneratedDocumentsTypeEntity } from '../../entities/generatedDocumentsType.entity';
import { createReducer, on } from '@ngrx/store';
import { GeneratedDocumentsTypeActionTypes } from '../actions/generatedDocumentsType.actions';


export const generatedDocumentsTypesFeatureKey = 'generatedDocumentsTypes';

export interface GeneratedDocumentsTypeState {
    generatedDocumentsTypes: GeneratedDocumentsTypeEntity[];
}

export const initialState: GeneratedDocumentsTypeState = {
    generatedDocumentsTypes: null,
};

export const GeneratedDocumentsTypeReducer = createReducer(
    initialState,
    on(GeneratedDocumentsTypeActionTypes.UpdateGeneratedDocumentsType, (state, { Payload }) => ({
        ...state,
        generatedDocumentsTypes: Payload,
    })),
    on(GeneratedDocumentsTypeActionTypes.UpdateGeneratedDocumentType, (state, { Payload }) => ({
        ...state,
        generatedDocumentsTypes: [...state.generatedDocumentsTypes.filter(t => t.Id !== Payload.Id), Payload],
    })),
);
