import { createAction, props } from '@ngrx/store';
import { EventEntity } from '../../entities/event.entity';
import {
    DaveMutationChangeEventArgs,
    DaveMutationChangeLastSeenEventArgs,
    DaveMutationCreateEventArgs,
    DaveMutationCreateUser2EventArgs,
    QueryEventArgs
} from '../../graphql-types';

export const EventsActionTypes = {
    Load: createAction('data/Events/load', props<{ Payload?: QueryEventArgs }>()),
    UpdateAll: createAction('data/Events/updateAll', props<{ Payload: Array<EventEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/Events/updateMany', props<{ Payload: Array<EventEntity>; updateLatestUpdatedAt?: boolean }>()),
    AddEvent: createAction('data/Events/add', props<{ Payload: DaveMutationCreateEventArgs & { userIds?: number[]; personIds?: number[]; documentIds?: number[] } }>()),
    SetUserToEvent: createAction('data/UserToEvent/add', props<{ Payload: DaveMutationCreateUser2EventArgs }>()),
    SetEventSeen: createAction('data/Events/EventSeen', props<{ Payload: DaveMutationChangeLastSeenEventArgs }>()),
    ModifyEvent: createAction('data/Events/modify', props<{ Payload: DaveMutationChangeEventArgs & { userIds?: number[] } }>()),
    DeleteEvent: createAction('data/Events/delete', props<{ Payload: number }>()),
    DeleteEventFromStore: createAction('data/Events/delete/FROM-STORE', props<{ payload: number }>()),
    UpdateEvent: createAction('data/Events/singleUpdate', props<{ Payload: EventEntity }>()),
};
