import { DetectionType } from '../graphql-types';
import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';

export class DetectionEntity {
    public static readonly GqlFields =
        'commission_id' +
        '    created_at' +
        '    customer_id' +
        '    deleted_at' +
        '    detected_properties' +
        '    document_id' +
        '    document_type' +
        '    edited' +
        '    event_id' +
        '    folder_id' +
        '    id' +
        '    old_data' +
        '    partner_id' +
        '    successful' +
        '    updated_at' +
        '   employee_id' +
        '   from_mail';

    public Id: number;
    public PartnerId: number;
    public DocumentId: number;
    public DocumentType: string;
    public CommissionId: number;
    public CustomerId: number;
    public FolderId: number;
    public EventId: number;
    public Edited: boolean;
    public Successfull: boolean;
    public OldData: string;
    public DetectedProperties: string[];
    public CreatedAt: Date;
    public UpdatedAt: Date;
    public DeletedAt: Date;
    public EmployeeId: number;
    public FromMail: string;

    constructor(detectionType?: DetectionType | null) {
        if (detectionType && detectionType.id != null && detectionType.document_id != null) {
            this.Id = detectionType.id;
            this.DocumentId = detectionType.document_id;
            this.PartnerId = detectionType.partner_id;
            this.DocumentType = detectionType.document_type;
            this.CommissionId = detectionType.commission_id;
            this.CustomerId = detectionType.customer_id;
            this.FolderId = detectionType.folder_id;
            this.EventId = detectionType.event_id;
            this.Edited = detectionType.edited;
            this.Successfull = detectionType.successful;
            this.OldData = detectionType.old_data;
            this.DetectedProperties = detectionType.detected_properties;
            this.CreatedAt = detectionType.created_at ? BackendDateTimestamp(detectionType.created_at) : null;
            this.UpdatedAt = detectionType.updated_at ? BackendDateTimestamp(detectionType.updated_at) : null;
            this.DeletedAt = detectionType.deleted_at ? BackendDateTimestamp(detectionType.deleted_at) : null;
            this.EmployeeId = detectionType.employee_id;
            this.FromMail = detectionType.from_mail;
        } else {
            console.error('Received broken DetectionType', detectionType);
            throw Error();
        }
    }
}
