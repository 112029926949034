import { FlatTreeControl,FlatTreeControlOptions } from '@angular/cdk/tree';
import {  firstValueFrom, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FolderEntity } from '../../../dave-data-module/entities/folder.entity';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { DynamicFolderTreeFlatNode } from '../../dynamic-folder-tree-data.service';

export class FolderTreeControl<K = DynamicFolderTreeFlatNode> extends FlatTreeControl<DynamicFolderTreeFlatNode, K> {
    expanded = new Subject<DynamicFolderTreeFlatNode>();

    constructor(
        private folderDataService: FolderDataService,
        getLevel: (dataNode: DynamicFolderTreeFlatNode) => number,
        isExpandable: (dataNode: DynamicFolderTreeFlatNode) => boolean,
        options?: FlatTreeControlOptions<DynamicFolderTreeFlatNode, K> | undefined,
    ) {
        super(getLevel, isExpandable, options);
    }

    async expandFolder(id: number) {
        let folders = await firstValueFrom(this.folderDataService.getFolderAndParentsById(id));
        const foldersSorted = sortByParent(folders);
        const folderIdsSorted = foldersSorted.map(f => f.Id)
        // add root-folder if missing
        if (foldersSorted.length > 0 && foldersSorted[0].Id) {
            folderIdsSorted.unshift(foldersSorted[0].ParentId)
        }
        for (const folderId of folderIdsSorted) {
            const node = this.dataNodes.find((d) => d.item.entityId === folderId && d.item.isFolder === true);
            if (node) {
                this.expand(node);
                await firstValueFrom(this.expanded.pipe(filter((expandedNode) => expandedNode.item.entityId === node.item.entityId && expandedNode.item.isFolder === true)));
            }
        }
    }
}
export function sortByParent(array: FolderEntity[], rootId?: number) {
    const root = array.find((a) => a.Id || null === rootId || null);
    const ret: FolderEntity[] = [root];
    let noChildFound = false;
    while (noChildFound === false) {
        const child = array.find((a) => ret[ret.length - 1].Id === a.ParentId);
        if (child) {
            ret.push(child);
        }
        if (!child || ret.length === array.length) {
            noChildFound = true;
        }
    }
    return array;
}
