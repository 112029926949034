import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { MarkdownModule } from 'ngx-markdown';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SimplebarAngularModule } from 'simplebar-angular';
import { DetailEventComponent } from '../components/detail-views/detail-event/detail-event.component';
import {
HistorySideElementsComponent
} from '../components/history/history-side-elements/history-side-elements.component';
import { HistoryComponent } from '../components/history/history.component';
import { TimelineViewComponent } from '../components/history/timeline/timeline-view/timeline-view.component';
import { TimelineComponent } from '../components/history/timeline/timeline.component';
import { NewEventComponent } from '../components/new-view/new-event/new-event.component';
import {
EventTypesSelectorCardComponent
} from '../components/templates/event-types-selector-card/event-types-selector-card.component';
import { NewDocumentViewMenu } from '../components/templates/new-document-view/new-document-view.module';
import { TextareaModule } from '../components/templates/textarea/textarea.module';
import { DaveDmsModule } from '../dave-dms-module/dave-dms.module';
import { PreviewModule } from '../dave-dms-module/dave-dms/preview/preview.module';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveEventCardModule } from '../dave-event-card/dave-event-card.module';
import { DaveFileExplorerModule } from '../dave-file-explorer/dave-file-explorer.module';
import { DaveListCardModule } from '../dave-list-card/dave-list-card.module';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from "../dave-utils-module/app-filter-module/app-filter.module";
import {
CustomerListComponent
} from "../dave-utils-module/dave-shared-components-module/components/customer-list/customer-list.component";
import {
DaveSharedComponentsModule
} from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { PipesModule } from '../dave-utils-module/dave-shared-components-module/pipes/pipes.module';
import { IconPipeModule } from '../dave-utils-module/icon-pipe/icon-pipe.module';
import { SelectSearchModule } from '../dave-utils-module/select-search/select-search.module';
import { PersonListEntryComponent } from '../person-list-entry/person-list-entry.component';
import { TimePickerSelectComponent } from "../time-picker-select/time-picker-select.component";
import { EndTimeDirective } from "../time-span-picker-select/end-time.directive";
import { StartTimeDirective } from "../time-span-picker-select/start-time.directive";
import { TimeSpanPickerSelectComponent } from "../time-span-picker-select/time-span-picker-select.component";
import { DaveHistoryRoutingModule } from './dave-history-routing.module';

@NgModule({
    declarations: [HistoryComponent, HistorySideElementsComponent, TimelineComponent, TimelineViewComponent, DetailEventComponent, NewEventComponent],
    imports: [
        DaveHistoryRoutingModule,
        CommonModule,
        DaveSharedComponentsModule,
        IconPipeModule,
        MatTooltipModule,
        RouterModule,
        MatFormFieldModule,
        MatSelectModule,
        SimplebarAngularModule,
        MatDividerModule,
        MatButtonModule,
        FontAwesomeModule,
        AppButtonModule,
        MatCardModule,
        FormsModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        MarkdownModule,
        DropzoneModule,
        MatInputModule,
        NgxMatSelectSearchModule,
        PipesModule,
        NewDocumentViewMenu,
        SelectSearchModule,
        DaveDmsModule,
        PreviewModule,
        DaveDoubleIconModule,
        TextareaModule,
        DaveEventCardModule,
        MatRadioModule,
        DaveLoadingModule,
        MatExpansionModule,
        MatTableModule,
        DaveFileExplorerModule,
        DaveListCardModule,
        PersonListEntryComponent,
        EventTypesSelectorCardComponent,
        CustomerListComponent,
        AppFilterModule,
        MatBadgeModule,
        TimePickerSelectComponent,
        TimeSpanPickerSelectComponent,
        MatDatepickerModule,
        StartTimeDirective,
        EndTimeDirective,
    ],
    exports: [],
})
export class DaveHistoryModule {}
