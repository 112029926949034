import { createAction, props } from '@ngrx/store';
import { ClockInEntity } from '../../entities/clock-in.entity';
import { DaveMutationChangeClockInArgs, DaveMutationCreateClockInArgs, DaveMutationDeleteClockInArgs, QueryClockInArgs } from '../../graphql-types';

export const ClockInActionTypes = {
    Load: createAction('data/clock-in/get', props<{ Payload?: QueryClockInArgs }>()),
    UpdateAll: createAction('data/clock-in/updateAll', props<{ Payload: ClockInEntity[]; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/clock-in/updateMany', props<{ Payload: ClockInEntity[]; updateLatestUpdatedAt?: boolean }>()),

    AddClockIn: createAction('data/clock-in/add', props<{ Payload: DaveMutationCreateClockInArgs }>()),
    AddClockIns: createAction('data/clock-in/addMany', props<{ Payload: DaveMutationCreateClockInArgs[] }>()),
    AddClockInsSuccess: createAction('data/clock-in/addMany/success'),
    // AddClockInWithAutoWorkDays: createAction('data/clock-in/add-with-auto-work-day', props<{ Payload: DaveMutationCreateClockInArgs[] }>()),
    ModifyClockIn: createAction('data/clock-in/modify', props<{ Payload: DaveMutationChangeClockInArgs }>()),
    ModifyClockIns: createAction('data/clock-in/modifyMany', props<{ Payload: DaveMutationChangeClockInArgs[] }>()),
    ModifyClockInsSuccess: createAction('data/clock-in/modifyMany/success'),
    DeleteClockIn: createAction('data/clock-in/delete', props<{ Payload: DaveMutationDeleteClockInArgs }>()),
    DeleteClockInsSuccess: createAction('data/clock-in/deleteMany/success', props<{ Payload: ClockInEntity[];}>()),
};
