import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { getConstructionDiariesFetched, getConstructionDiaryLatestUpdatedAt } from '../State/selectors/construction-diary.selectors';
import { ConstructionDiaryActionTypes } from '../State/actions/construction-diary.actions';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class ConstructionDiaryResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getConstructionDiariesFetched, getConstructionDiaryLatestUpdatedAt, ConstructionDiaryActionTypes.Load, store);
    }
}
