import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { WorkedTimesActionTypes } from '../State/actions/worked-times.actions';
import { getWorkedTimesFetched, getWorkedTimesLatestUpdatedAt } from '../State/selectors/worked-times.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class WorkedTimesResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getWorkedTimesFetched, getWorkedTimesLatestUpdatedAt, WorkedTimesActionTypes.Load, store);
    }
}
