import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { firstValueFrom, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ErrorPopupService } from '../../../error-popup/error-popup.service';
import { FileEntity, FileEntityFromBackend, FileEntityFromFileBackend, FileEntityFromGateway, FileTypeFromFileBackend } from '../../entities/file.entity';
import { VersionEntityFromBackend } from '../../entities/version.entity';
import { retryWithBackoff } from '../../helper/http-service.helper';
import { AppGatewayService } from '../../services/app-gateway.service';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { FilesActionTypes } from '../actions/files.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { getFiles } from '../selectors/files.selectors';
import { FileEditRequest } from "@dave/types";
import { FileDataService } from '../../services/file-data.service';

export enum ErrorCodesFileActions {
    Load = 'Dateien Abrufen fehlgeschlagen',
    Remove = 'Datei Löschen fehlgeschlagen',
    Modify = 'Datei ändern fehlgeschlagen',
    ModifyVersion = 'Version ändern fehlgeschlagen',
    Upload = 'Datei hochladen fehlgeschlagen',
    RemoveOriginalVersion = 'Datei löschen fehlgeschlagen, es wurde versucht die Originalversion zu löschen',
    CreateLink = 'Verknüpfung erstellen fehlgeschlagen',
}
@Injectable()
export class FileEffects {
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService, private http: HttpClient, private errorPopup: ErrorPopupService, private gatewayService: AppGatewayService, private fileDataService: FileDataService) {}

    GetFilesFromFolderQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.LoadFilesFromFolder),
            withLatestFrom(this.store$),
            switchMap(([action, store]) => {
                let headers = new HttpHeaders();
                headers = headers.set('Content-Type', 'application/json');
                headers = headers.set('Authorization', 'Bearer ' + store.base.token);

                return this.http
                    .get<FileTypeFromFileBackend[]>(this.gatewayHttpService.GetUrl('files', 'file') + '?parent_folder=' + action.Payload, {
                        headers,
                    })
                    .pipe(
                        retryWithBackoff(),
                        map((res) => FilesActionTypes.UpdateMany({ Payload: res.map((f) => FileEntityFromFileBackend(f)) })),
                        catchError((err, caught) => {
                            this.errorPopup.OpenErrorPopup();
                            return of(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodesFileActions.Load,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            );
                        }),
                    );
            }),
        ),
    );

    GetFilesFromAfterQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.LoadFilesAfter),
            withLatestFrom(this.store$),
            switchMap(([action, store]) => {
                let headers = new HttpHeaders();
                headers = headers.set('Content-Type', 'application/json');
                headers = headers.set('Authorization', 'Bearer ' + store.base.token);

                const ts = moment().subtract(action.Payload, 'minutes').toISOString();

                return this.http
                    .get<FileTypeFromFileBackend[]>(this.gatewayHttpService.GetUrl('files', 'file') + '?since=' + ts, {
                        headers,
                    })
                    .pipe(
                        retryWithBackoff(),
                        map((res) => FilesActionTypes.UpdateMany({ Payload: res.map((f) => FileEntityFromFileBackend(f)) })),
                        catchError((err, caught) => {
                            this.errorPopup.OpenErrorPopup();
                            return of(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodesFileActions.Load,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            );
                        }),
                    );
            }),
        ),
    );

    GetFilesFromEmailQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.LoadEmailFiles),
            withLatestFrom(this.store$),
            switchMap(([action, store]) => {
                let headers = new HttpHeaders();
                headers = headers.set('Content-Type', 'application/json');
                headers = headers.set('Authorization', 'Bearer ' + store.base.token);

                const ids = action.Payload.join(',');

                return this.http
                    .get<FileTypeFromFileBackend[]>(this.gatewayHttpService.GetUrl('files', 'file') + '?with_email_inline=true&document_ids=' + ids, {
                        headers,
                    })
                    .pipe(
                        retryWithBackoff(),
                        map((res) => FilesActionTypes.UpdateMany({ Payload: res.map((f) => FileEntityFromFileBackend(f)) })),
                        catchError((err, caught) => {
                            this.errorPopup.OpenErrorPopup();
                            return of(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodesFileActions.Load,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            );
                        }),
                    );
            }),
        ),
    );
    UploadFileBackend$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.UploadFileBackendRequest),
            withLatestFrom(this.store$.select(getToken)),
            concatMap(([action, token]) => {
                let headers = new HttpHeaders();
                headers = headers.set('Authorization', 'Bearer ' + token);
                const payload = new FormData();
                action.Payload.params.forEach((param, key) => {
                    payload.append(key, param);
                });
                payload.append('file', action.Payload.file);
                // headers = headers.set('Content-Type', 'multipart/form-data; boundary='+payload.get('file'));

                return this.http
                    .put<FileTypeFromFileBackend>(this.gatewayHttpService.GetUrl('files', 'file'), payload, {
                        headers,
                    })
                    .pipe(
                        map((res) => FilesActionTypes.UploadFileBackendSuccess({ Payload: FileEntityFromFileBackend(res[0]) })),
                        catchError((err, caught) => {
                            return of(
                                FilesActionTypes.UploadFileBackendFailure({
                                    Payload: {
                                        ToasterMessage: ErrorCodesFileActions.Upload,
                                        Errors: err,
                                    },
                                }),
                            );
                        }),
                    );
            }),
        ),
    );

    DeleteFile = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.DeleteFileRequest),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ FileFileDelete: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return FilesActionTypes.DeleteFileSuccess({ Payload: +res.FileFileDelete.Id });
                        }
                        throw res?.Errors;
                    })
                    .catch((err) => FilesActionTypes.DeleteFileFailure({ Payload: { Errors: err, ToasterMessage: ErrorCodesFileActions.Remove } })),
            ),
        ),
    );

    // DeleteFile$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(FilesActionTypes.DeleteFile),
    //         withLatestFrom(this.store$.select(getToken)),
    //         concatMap(([action, token]) => {
    //             const queryString = `
    //         mutation {
    //             deleteFile(fileId: ${action.Payload}){
    //         ${FileEntity.GqlFields}
    //       }
    //     }`;
    //
    //             return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
    //                 map((res) =>
    //                     res && res.deleteFile
    //                         ? FilesActionTypes.RemoveFile({
    //                               Payload: action.Payload,
    //                           })
    //                         : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodesFileActions.Remove } }),
    //                 ),
    //                 catchError((err, caught) =>
    //                     of(
    //                         BaseActionTypes.ErrorAction({
    //                             Payload: {
    //                                 ToasterMessage: ErrorCodesFileActions.Remove,
    //                                 Err: err,
    //                                 Caught: caught,
    //                             },
    //                         }),
    //                     ),
    //                 ),
    //             );
    //         }),
    //     ),
    // );

    DeleteVersion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.DeleteVersion),
            withLatestFrom(this.store$.select(getToken), this.store$.select(getFiles)),
            concatMap(([action, token, files]) => {
                const queryString = `
            mutation {
                deleteFile(versionId: ${action.Payload}){
            ${FileEntity.GqlFields}
          }
        }`;

                const isOriginalVersion = files.find((f) => f.Versions?.find((v) => v.Id === action.Payload)).Versions.find((v) => v.Id === action.Payload).Number === 1;
                if (isOriginalVersion) {
                    return of(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodesFileActions.RemoveOriginalVersion } }));
                }
                return this.gatewayHttpService.graphQl({ query: queryString }, { token }).pipe(
                    map((res) =>
                        res && res.deleteFile
                            ? FilesActionTypes.UpdateOne({
                                  Payload: FileEntityFromBackend(res.deleteFile),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodesFileActions.Remove } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodesFileActions.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    UploadFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.UploadFile),
            switchMap((action) => {
                return this.gatewayHttpService.UploadFile(action.Payload).pipe(
                    map((res) => (res?.data ? res?.data[0] : null)),
                    tap((res) => {
                        if (res) {
                            setTimeout(() => {
                                this.store$.dispatch(FilesActionTypes.LoadFilesAfter({ Payload: 5 }));
                            }, 2000);
                            setTimeout(() => {
                                this.store$.dispatch(FilesActionTypes.LoadFilesAfter({ Payload: 5 }));
                            }, 4000);
                            setTimeout(() => {
                                this.store$.dispatch(FilesActionTypes.LoadFilesAfter({ Payload: 5 }));
                            }, 20000);
                        }
                    }),
                    map((res) => (res ? FilesActionTypes.UpdateOne({ Payload: FileEntityFromBackend(res) }) : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodesFileActions.Upload } }))),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodesFileActions.Upload,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    EditFile = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.ModifyFileRequest),
            switchMap(({ Payload }) =>
                this.gatewayService
                    .Request({ FileFileEdit: Payload as FileEditRequest })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return FilesActionTypes.ModifyFileSuccess({ Payload: FileEntityFromGateway(res.FileFileEdit) });
                        } else {
                            throw res?.Errors;
                        }
                    })
                    .catch((err) => FilesActionTypes.ModifyFileFailure({ Payload: { Errors: err, ToasterMessage: ErrorCodesFileActions.Modify } })),
            ),
        ),
    );
    ModifyVersion = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.ModifyVersion),
            concatMap(({ Payload, parentDocumentId }) =>
                this.gatewayService
                    .Request({ FileFileEditVersion: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return FilesActionTypes.UpdateOneVersion({ Payload: VersionEntityFromBackend(res.FileFileEditVersion, parentDocumentId) });
                        }
                        throw res.Errors;
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodesFileActions.ModifyVersion,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );
    CreateLink = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.CreateLinkRequest),
            switchMap(({ Payload }) =>
                this.gatewayService
                    .Request({ FileFileCreateDocumentLink: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return FilesActionTypes.CreateLinkSuccess({ Payload: FileEntityFromGateway(res.FileFileCreateDocumentLink) });
                        }
                        throw res?.Errors;
                    })
                    .catch((err) => FilesActionTypes.CreateLinkFailure({ Payload: { Errors: err, ToasterMessage: ErrorCodesFileActions.CreateLink } })),
            ),
        ),
    );
    MergeVersions = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.MergePdfVersionsRequest),
            switchMap(({ Payload }) =>
                this.gatewayService
                    .Request({ PdfToolsPdfMerge: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return FilesActionTypes.MergePdfVersionsSuccess({ Payload: +res.PdfToolsPdfMerge.DocumentId });
                        }
                        throw res?.Errors;
                    })
                    .catch((err) => FilesActionTypes.MergePdfVersionsFailure({ Payload: { Errors: err, ToasterMessage: ErrorCodesFileActions.CreateLink } })),
            ),
        ),
    );
    MergeVersionSuccesss = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.MergePdfVersionsSuccess),
            tap(({Payload}) => firstValueFrom(this.fileDataService.GetFileById$(Payload, true)))
        )
    , {dispatch: false});
    FailEffect = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActionTypes.CreateLinkFailure, FilesActionTypes.DeleteFileFailure, FilesActionTypes.ModifyFileFailure, FilesActionTypes.MergePdfVersionsFailure, FilesActionTypes.UploadFileBackendFailure),
            map(({ Payload }) => BaseActionTypes.ErrorAction({ Payload: { Err: Payload.Errors, ToasterMessage: Payload.ToasterMessage } })),
        ),
    );
}
