import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AllRegularIcons, AllSolidIcons } from '../../../../../icon-pipe/icon.pipe';

@Component({
    selector: 'app-icon-select-dialog',
    templateUrl: './icon-select-dialog.component.html',
    styleUrls: ['./icon-select-dialog.component.scss'],
})
export class IconSelectDialogComponent implements OnInit {
    public Icons: object[] = [];
    public SelectedIcon = null;

    constructor(private dialogRef: MatDialogRef<IconSelectDialogComponent>) {}

    ngOnInit() {
        const regularIcons = AllRegularIcons;
        for (const icon in regularIcons) {
            if (
                regularIcons[icon].prefix === 'far' &&
                regularIcons[icon].iconName !== 'font-awesome-logo-full'
            ) {
                this.Icons.push(regularIcons[icon]);
            }
        }
        const solidIcons = AllSolidIcons;
        for (const icon in solidIcons) {
            if (
                solidIcons[icon].prefix === 'fas' &&
                solidIcons[icon].iconName !== 'font-awesome-logo-full'
            ) {
                this.Icons.push(solidIcons[icon]);
            }
        }
    }

    public Select(icon: any) {
        this.SelectedIcon = icon;
    }

    public Submit() {
        this.dialogRef.close(
            this.SelectedIcon.prefix + ',fa' + this.toCamelCase(this.SelectedIcon.iconName),
        );
    }

    private toCamelCase(s: string) {
        const parts = s.split('-');
        let camelCaseString = '';
        parts.forEach(part => {
            camelCaseString = camelCaseString + this.toProperCase(part);
        });

        return camelCaseString;
    }

    private toProperCase(s: string) {
        return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
    }
}
