import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { CkEditorComponent } from "../ck-editor/ck-editor.component";
import { DaveDoubleIconModule } from "../dave-double-icon/dave-double-icon.module";
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { SmallFileCardModule } from "../small-file-card/small-file-card.module";
import { CommentCardComponent } from "./components/comment-card/comment-card.component";
import { CommentInlineEditorComponent } from "./components/comment-inline-editor/comment-inline-editor.component";
import { CommentListComponent } from './components/comment-list/comment-list.component';



@NgModule({
    declarations: [CommentListComponent],
    exports: [CommentListComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatExpansionModule,
        FontAwesomeModule,
        AppButtonModule,
        // CKEditorModule,
        FormsModule,
        MatTooltipModule,
        SmallFileCardModule,
        DaveDoubleIconModule,
        MatButtonModule,
        DropzoneModule,
        CkEditorComponent,
        CommentCardComponent,
        CommentInlineEditorComponent,
    ],
})
export class DaveCommentsModule {}
