import { createAction, props } from '@ngrx/store';
import { BusinessVolumeEntity } from '../../entities/business-volume.entity';
import {
    DaveMutationChangeBusinessVolumeArgs,
    DaveMutationCreateBusinessVolumeArgs, DaveMutationCreateDatevCsvArgs, DaveMutationDeleteBusinessVolumeArgs,
    QueryBusinessVolumeArgs,
} from '../../graphql-types';
import { FileEntity } from '../../entities/file.entity';

export const BusinessVolumeActionTypes = {
    Load: createAction('data/BusinessVolume/loadAll', props<{ Payload?: QueryBusinessVolumeArgs }>()),
    UpdateAll: createAction('data/BusinessVolume/updateAll', props<{ Payload: Array<BusinessVolumeEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/BusinessVolume/updateMany', props<{ Payload: Array<BusinessVolumeEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction('data/BusinessVolume/updateOne', props<{ Payload: BusinessVolumeEntity }>()),
    RemoveOne: createAction('data/BusinessVolume/removeOne', props<{ Payload: number }>()),

    Change: createAction('data/BusinessVolume/change', props<{ Payload: DaveMutationChangeBusinessVolumeArgs }>()),
    Add: createAction('data/BusinessVolume/add', props<{ Payload: DaveMutationCreateBusinessVolumeArgs }>()),
    Delete: createAction('data/BusinessVolume/delete', props<{ Payload: DaveMutationDeleteBusinessVolumeArgs }>()),

    ExportRequest: createAction('data/BusinessVolume/export/request', props<{ Payload: DaveMutationCreateDatevCsvArgs }>()),
    ExportSuccess: createAction('data/BusinessVolume/export/success', props<{ Payload: FileEntity }>()),
    ExportFailure: createAction('data/BusinessVolume/export/failure', props<{ Errors: any; ToasterMessage?: string }>()),
};
