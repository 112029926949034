<app-side-nav-bar
    [TopDivider]="NavBarFlyMode$ | async"
    *ngIf="!(IsHomeDashboard$ | async) && !(IsLogin$ | async)"
    [class.disabled]="(NavBarFlyMode$ | async) && !NavBarOpen"
    [HomeButton]="true"
    [class.fly-mode]="NavBarFlyMode$ | async"
    [Expanded]="NavBarFlyMode$ | async"
    xmlns:btn="http://www.w3.org/1999/html"></app-side-nav-bar>
<div class="flex-col">
    <app-header [class.disabled]="IsHomeDashboard$ | async" [NavBarButton]="NavBarFlyMode$ | async" [(NavBarOpen)]="NavBarOpen"></app-header>
    <div class="router-wrapper" id="routerWrapper">
        <app-loading [Transparent]="false" *ngIf="Loading$ | async"></app-loading>
        <!--
            Das Router-Outlet darf nicht durch ein *ngIf zerstört werden, da ansonsten
            Router-Outlets innerhalb der gerouteten Komponenten nicht deaktiviert werden
            und bei erneuter Aktivierung Fehler werfen
        -->
        <router-outlet></router-outlet>
    </div>
</div>
