<div
    class="wrapper"
    *ngIf="NewSwimLanes as swimLanes"
    cdkDropListGroup
>
    <div
        class="swimLane"
        *ngFor="let lane of swimLanes; let i = index; trackBy: TrackLanes"
    >
        <h4>{{ lane.Headline }}</h4>
        <button style="width: 100%" (click)="OpenAddTaskDialog()" mat-raised-button color="primary" *ngIf="ShowNewButton && i === 0">
            <div style="width: 100%; display: flex">
                neue Aufgabe <fa-icon icon="plus" style="margin-left: auto"></fa-icon>
            </div>
        </button>
        <div
            class="inner-wrapper"
            cdkDropList
            cdkDropListSortingDisabled
            [cdkDropListDisabled]="DisableDragNDrop$ | async"
            [cdkDropListData]="lane.Id"
            (cdkDropListDropped)="Drop($event)"
        >
            <p class="no-items">keine Aufgaben vorhanden</p>
            <app-event-card [id]="taskId" [cdkDragData]="taskId" cdkDrag (FileClicked)='FileClicked.emit($event)' style='margin-top: 1rem; display: block;' *ngFor='let taskId of lane.eventIds$ | async' [EventId]="taskId"></app-event-card>
        </div>
    </div>
</div>
