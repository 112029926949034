<div class="top-wrapper">
    <div class="top-wrapper-left">
        <h1 mat-dialog-title class="mat-headline" *ngIf="DisplayUsers$ | async as displayUsers" style="margin-right: 0.5rem">
            {{ Data.onlyOneUser ? displayUsers[0].User.DisplayName + ' zu ' + (cls.getSingle$('Commission') | async) + ' ' + (Commission$ | async)?.GetDisplayName() + ' zuweisen' : 'Mitarbeiter auswählen' }}
        </h1>
        <fa-icon
            class="help-icon"
            icon="question-circle"
            [matTooltip]="'Nur zugewiesene Mitarbeiter haben Zugriff auf diesen ' + (cls.getSingle$('Commission') | async) + '. Bearbeiter erhalten (wenn in den Einstellungen aktiviert) auf dem Dashboard unter &quot;meine Aufgaben&quot; zusätzlich Benachrichtigungen zum ' + (cls.getSingle$('Commission') | async)"
        ></fa-icon>
    </div>
    <button app-button style="margin-bottom: 1.5rem" *ngIf="!Data.onlyOneUser" (click)="ShowCollissions.next(!ShowCollissions.value)">
        Kollisionen {{ (ShowCollissions | async) ? 'ausblenden' : 'einblenden' }}
    </button>
</div>
<app-select-search-legacy *ngIf="!Data.commissionId" [Values]="Commissions$ | async" [Control]="CommissionForm" [Placeholder]="cls.getSingle$('Commission') | async" Required> </app-select-search-legacy>
<div class="content">
    <mat-accordion>
        <mat-expansion-panel
            *ngFor="let item of DisplayUsers$ | async"
            [expanded]="ExpandedPanelUserId === item.User.Id || !!Data.onlyOneUser"
            (afterExpand)="ExpandedPanelUserId = item.User.Id"
        >
            <mat-expansion-panel-header class="panel-header" *ngIf="!Data.onlyOneUser">
                <mat-panel-title class="panel-title">
                    <fa-icon [icon]="(item.roles$ | async)?.length ? 'check' : 'times'" size="lg"> </fa-icon>
                    &nbsp;&nbsp;{{ item.User.DisplayName }}&nbsp;:&nbsp;
                    <span [innerHTML]="'<i>' + (item.roles$ | async)?.join('</i> | <i>') + '</i>'"></span>
                </mat-panel-title>
                <mat-panel-description *ngIf="!(BS.MobileQuery | async)">
                    <!-- {{ item.User.Id }} -->
                    <span *ngIf="item.vacation?.length || item.absent?.length || item.illness?.length || item.commissions?.length">
                        <!-- <span> -->
                        <b>Kollisionen:&nbsp;</b>
                        {{
                            (item.vacation?.length == 0 ? '' : item.vacation?.length == 1 ? item.vacation?.length + ' Urlaubsantrag' : item.vacation?.length + ' Urlaubsanträge') +
                                (item.vacation.length && (item.commissions?.length || item.illness?.length || item.absent.length) ? ',' : '')
                        }}

                        {{
                            (item.absent?.length == 0 ? '' : item.absent?.length == 1 ? item.absent?.length + ' Abwesenheit' : item.absent?.length + ' Abwesenheiten') +
                                (item.absent.length && (item.commissions?.length || item.illness?.length) ? ',' : '')
                        }}

                        {{
                            (item.illness?.length == 0 ? '' : item.illness?.length == 1 ? item.illness?.length + ' Krankheitsausfall' : item.illness?.length + ' Krankheitsausfälle') +
                                (item.illness.length && item.commissions?.length ? ',' : '')
                        }}

                        {{ item.commissions?.length == 0 ? '' : item.commissions?.length == 1 ? item.commissions?.length + ' ' + (cls.getSingle$('Commission') | async) : item.commissions?.length + ' ' + (cls.getMultiple$('Commission') | async) }}

                        <!-- <b>Kollisionen:&nbsp;</b> {{ item.vacation?.length }} Urlaubsanträge,
                        {{ item.commissions?.length }} Aufträge -->
                    </span>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <ng-container *ngIf="ExpandedPanelUserId === item.User.Id || !!Data.onlyOneUser">
                <p>
                    <!-- wenn urlaub -->
                    <span *ngIf="item.vacation?.length">
                        <b>Urlaubsanträge</b>
                    </span>
                    <span class="detail-wrapper">
                        <span class="detail" *ngFor="let vacation of item.vacation">
                            {{ (vacation?.StartDate | date) + ' - ' + (vacation?.EndDate | date) }}
                        </span></span
                    >
                </p>
                <p>
                    <!-- wenn abwesend -->
                    <span *ngIf="item.absent?.length">
                        <b>Abwesenheiten</b>
                    </span>
                    <span class="detail-wrapper">
                        <span class="detail" *ngFor="let absent of item.absent">
                            {{ (absent?.StartDate | date) + ' - ' + (absent?.EndDate | date) }}
                        </span></span
                    >
                </p>
                <p>
                    <!-- wenn krank -->
                    <span *ngIf="item.illness?.length">
                        <b>Krankheitsausfälle</b>
                    </span>
                    <span class="detail-wrapper">
                        <span class="detail" *ngFor="let illness of item.illness">
                            {{ (illness?.StartDate | date) + ' - ' + (illness?.EndDate | date) }}
                        </span></span
                    >
                </p>
                <!-- wenn bereits auftrag zugeordnet -->
                <span *ngIf="item.commissions?.length"><b>Bereits zugewiesene {{cls.getMultiple$('Commission') | async}}:</b></span>

                <div class="detail-wrapper" *ngFor="let commission of item.commissions">
                    {{cls.getSingle$('Commission') | async}}:
                    <a [routerLink]="[CommissionsLink[0], CommissionsLink[1], commission.commission.Id]">{{ commission.commission.GetDisplayName() }}</a>
                    <div *ngFor="let u2cs of commission.user2commissionShifts">
                        <div>
                            Beginn Zuweisungszeitraum:
                            {{ u2cs.StartDate | date: 'mediumDate' }}
                        </div>
                        <div>
                            Ende Zuweisungszeitraum:
                            {{ u2cs.EndDate | date: 'mediumDate' }}
                        </div>
                        <br />
                    </div>
                </div>
                <span *ngIf="item.user2commissionShifts.length"><b>Bereits diesem {{cls.getSingle$('Commission') | async}} zugewiesen:</b></span>
                <mat-accordion multi="false">
                    <mat-expansion-panel *ngFor="let u2cs of item.user2commissionShifts"   hideToggle (opened)="SetTimespanFormValues(u2cs)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                von: {{ u2cs.StartDate | date }} ({{ u2cs.StartDate.getHours() }}:{{ u2cs.StartDate.getMinutes() !== 0 ? u2cs.StartDate.getMinutes() : '00' }} Uhr) - bis: {{ u2cs.EndDate | date }} ({{u2cs.EndDate.getHours()}}:{{ u2cs.EndDate.getMinutes() !== 0 ? u2cs.EndDate.getMinutes() : '00' }} Uhr)
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container matExpansionPanelContent>
                            <div class="flex-row">
                                <mat-form-field>
                                    <mat-label>Datum</mat-label>
                                    <mat-date-range-input [rangePicker]="picker" required>
                                        <input matStartDate placeholder="Start" [formControl]="timespanForm.controls.StartDate" required>
                                        <input matEndDate placeholder="Ende" [formControl]="timespanForm.controls.EndDate" required>
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                </mat-form-field>
                                <mat-form-field *ngIf="!timespanForm.value.EndDate || (timespanForm.value.StartDate && sameDay(timespanForm.value.StartDate.toDate(), timespanForm.value.EndDate.toDate()))">
                                    <mat-label>Zeitraum</mat-label>
                                    <app-time-span-picker-select>
                                        <app-time-picker-select appStartTime required placeholder="Start" [min]="'06:00'" [max]="'20:00'" [formControl]="timespanForm.controls.StartTimeString"></app-time-picker-select>
                                        <app-time-picker-select appEndTime required placeholder="Ende" [min]="'06:00'" [max]="'20:00'" [formControl]="timespanForm.controls.EndTimeString"></app-time-picker-select>
                                    </app-time-span-picker-select>
                                </mat-form-field>
                            </div>
                            <button app-button style="color: #8a1e1e ; margin: 0.5rem" (click)="DeleteU2C(u2cs.Id)">Zuordnung entfernen</button>
                            <button app-button style="margin: 0.5rem" class="right" (click)="OpenU2CsChangTimeDialog(u2cs)" [Disabled]="timespanForm.pristine">Zeitraum speichern</button>
                        </ng-container>
                    </mat-expansion-panel>
                </mat-accordion>
                <!-- wenn projekt zugeordnet -->
                <!-- <span class="detail">Zugewiesenes Projekt: <a href="#">Projektnr./AuftragsNr.</a></span> -->
                <mat-card *ngIf="item.formData$ | async as formData">
                    <app-detail-list-template
                        [AutofocusIndex]="null"
                        [Data]="formData.roleTemplateData"
                        [Editing]="!item.User.Roles.includes('CommissionManagementAdmin')"
                        [Inline]="true"
                    ></app-detail-list-template>
                    <mat-slide-toggle [formControl]="formData.isAssignedForm" [disabled]="!(item.roles$ | async)?.length">Verantwortlich</mat-slide-toggle>
                </mat-card>
                <mat-expansion-panel hideToggle style="display: flex; flex-direction: column; margin: 1rem 0" [expanded]="!!Data.onlyOneUser && Data.preFilledStartDate">
                    <mat-expansion-panel-header>
                        <mat-panel-title> Zeitraum zuweisen </mat-panel-title>
                        <mat-panel-description>
                            <fa-icon
                                icon="question-circle"
                                matTooltip=" Der angegebene Zeitraum wird im Schichtplan angezeigt und für die Kollisionsberechnung verwendet."
                            ></fa-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="flex-row">
                        <mat-form-field>
                            <mat-label>Datum</mat-label>
                            <mat-date-range-input [rangePicker]="picker" required>
                                <input matStartDate placeholder="Start" [formControl]="u2csTimespanForm.controls.StartDate" required>
                                <input matEndDate placeholder="Ende" [formControl]="u2csTimespanForm.controls.EndDate" required>
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                        <mat-form-field *ngIf="!u2csTimespanForm.value.EndDate || (u2csTimespanForm.value.StartDate && sameDay(u2csTimespanForm.value.StartDate.toDate(), u2csTimespanForm.value.EndDate.toDate()))">
                            <mat-label>Zeitraum</mat-label>
                            <app-time-span-picker-select>
                                <app-time-picker-select appStartTime required placeholder="Start" [formControl]="u2csTimespanForm.controls.StartTimeString"></app-time-picker-select>
                                <app-time-picker-select appEndTime required placeholder="Ende" [min]="u2csTimespanForm.value.StartTimeString" [offsetTimeForOptionLabel]="u2csTimespanForm.value.StartTimeString" [formControl]="u2csTimespanForm.controls.EndTimeString"></app-time-picker-select>
                            </app-time-span-picker-select>
                        </mat-form-field>
                    </div>
                    <button app-button class="right" (click)="AddU2CS(item.User.Id)" [Disabled]="u2csTimespanForm.invalid">Zeitraum speichern</button>
                </mat-expansion-panel>
                <button
                    app-button
                    Color="red"
                    Inverted
                    *ngIf="(item.roles$ | async)?.length"
                    (click)="RemoveUser(item.User.Id)"
                    [Disabled]="item.ItsMe || item.User.Roles.includes('CommissionManagementAdmin')"
                >
                    Mitarbeiter entfernen
                </button>
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div mat-dialog-actions class="buttons">
    <div class="cancel-button-wrapper">
        <button app-button Inverted (click)="Close()">Abbrechen</button>
    </div>
    <!-- <button app-button class="button" (click)="Save()">Speichern</button> -->
</div>

