<header class="dialog-header">
    <h3 mat-dialog-title>Tagebucheintrag</h3>
    <fa-icon class="close-button" icon="times" size="2x" (click)="dialogRef.close()"></fa-icon>
</header>

<mat-dialog-content *ngIf="Diary$ | async as diary; else loading">
    <div class="header">
        Datum: {{ diary.Date | date }} {{ (IsToday$ | async) ? '(heute)' : '' }}<br />
        Status: {{ GetStatusName(diary) }}
    </div>
    <mat-horizontal-stepper class="stepper-wrapper" [linear]="false" #matStepper (selectionChange)="onStepSelectionChange($event)">
        <mat-step *ngFor="let page of [page1, page2, page3, page4]; let i = index;">
            <ng-container *ngTemplateOutlet="page; context: { diary: diary }"></ng-container>
            <ng-template matStepLabel>S. {{ i + 1 }}</ng-template>
            <mat-divider class="bottom-divider"></mat-divider>
        </mat-step>
        <ng-template matStepperIcon="edit">
            <fa-icon icon="pencil-alt"></fa-icon>
        </ng-template>
        <!-- <ng-template matStepperIcon="number">
            <fa-icon icon="pencil-alt"></fa-icon>
        </ng-template> -->
        <ng-template matStepperIcon="done">
            <fa-icon icon="check"></fa-icon>
        </ng-template>
        <ng-template matStepperIcon="error">
            <fa-icon icon="exclamation-triangle"></fa-icon>
        </ng-template>
    </mat-horizontal-stepper>
</mat-dialog-content>
<mat-dialog-actions class="footer" [ngSwitch]="SelectedIndex">
    <ng-container *ngSwitchCase="3">
        <ng-container *ngIf="PartnerTypeId$ | async as partnertypeId">
            <div class="cancel-button-wrapper">
                <button app-button Inverted mat-dialog-close matTooltip="Abbrechen">Abbrechen</button>
            </div>
            <button
                [Disabled]="SomeRequiredTaskPending$ | async"
                *ngIf="partnertypeId !== PartnerTypeKagetec"
                app-button
                (click)="Save(null, true)"
                mat-dialog-close
                matTooltip="Speichern und schließen"
                [IsLoading]="LS.IsLoading$ | async"
            >
                Speichern
            </button>
            <button
                [Disabled]="SomeRequiredTaskPending$ | async"
                *ngIf="partnertypeId === PartnerTypeKagetec"
                app-button
                (click)="Save(null, true)"
                mat-dialog-close="open-time-tracking-kagetec"
                matTooltip="Jetzt Zeiten erfassen"
                [IsLoading]="LS.IsLoading$ | async"
            >
                Jetzt Zeiten erfassen
            </button>
        </ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <div class="cancel-button-wrapper">
            <button app-button Inverted mat-dialog-close matTooltip="Abbrechen">Abbrechen</button>
        </div>
        <button app-button matTooltip="Speichern und weiter" (click)="MatStepper?.next()" [IsLoading]="LS.IsLoading$ | async" [Disabled]="SelectedIndex === 0 && (SaveButtonDisabled$ | async)">
            Speichern und weiter
        </button>
    </ng-container>
</mat-dialog-actions>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<ng-template #page1 let-diary="diary">
    <ng-container *ngIf="PartnerTypeId$ | async as partnertype">
        <div *ngIf="PartnerTypeKagetec === partnertype">
            <h3>Baustellen Inspektion</h3>
            <div class="lmra-button-wrapper">
<!--                <a href="/assets/downloads/kagetec/lmra.pdf" target="_blank" app-button [Lighter]="true" class="open-button">öffnen</a>-->
                <div>
                    <button app-button [Lighter]="true" (click)="onOpenInspection()" [IsLoading]="LS.IsLoading$ | async">öffnen</button>
                    <mat-label *ngIf="HasInspection$ | async">Baustelleninspektion durchgeführt <fa-icon icon="check"></fa-icon></mat-label>
                    <mat-label *ngIf="!(HasInspection$ | async)">Baustelleninspektionsformular öffnen</mat-label>
                </div>
            </div>
        </div>
    </ng-container>
    <h3>Wetterverhältnisse</h3>
    <div class="weather-wrapper">
        <app-textarea Key="Wettersituation (sonnig, wolkig ...)" [Value]="Form.controls.Weather"></app-textarea>
        <app-numberfield Key="Temp" [FormControl]="Form.controls.Temperature"><div textfieldSuffix>°C</div></app-numberfield>
    </div>
    <app-dave-list-card
        Headline="Tägliche Aufgaben"
        [Data]="TaskTableData$ | async"
        [HasSuffixButton]="true"
        [DisplayedCollums]="['Name', 'stateName']"
        [TableHeaderCollumsMap]="{ time: 'Uhrzeit', Name: 'Name', stateName: 'Status' }"
        [Clickable]="true"
        [DisableListEntryByButton]="true"
        DefaultSortActive="time"
        (EntryClicked$)="$event.entry.suffixButton.disabled === false ? $event.entry.suffixButton.onClick($event.entry): null "
    >
    </app-dave-list-card>
</ng-template>

<ng-template #page2 let-diary="diary">
    <div class="flex-row">
        <h3>Leistungsergebnisse</h3>
        <mat-slide-toggle [(ngModel)]="LeistungsergebnisseUseTemplateMode">Vorlagen</mat-slide-toggle>
    </div>
<!--       <button-->
<!--                    app-round-button-->
<!--                    matTooltipPosition="left"-->
<!--                    matTooltip="Text hinzufügen"-->
<!--                    (click)="OpenTextTemplateDialog('Texte')"-->
<!--                >-->
<!--                    <app-double-icon Icon="comment-text" Icon2="plus"></app-double-icon>-->
<!--                </button>-->
<!--    <mat-form-field>-->
<!--        <label>Leistungsergebnisse</label>-->
        <app-text-area-templates *ngIf="LeistungsergebnisseUseTemplateMode" [formControl]="Form.controls.LeistungsergebnisseTemplate"></app-text-area-templates>
<!--    </mat-form-field>-->
    <app-textarea *ngIf="!LeistungsergebnisseUseTemplateMode" MaxHeight="200px" Key="Leistungsergebnisse" [Value]="Form.controls.Leistungsergebnisse"></app-textarea>
    <app-textarea MaxHeight="200px" Key="Besondere Vorkommnisse" [Value]="Form.controls.BesondereVorkommnisse"></app-textarea>
    <app-textarea MaxHeight="200px" Key="Leistungsänderungen" [Value]="Form.controls.Leistungsaenderungen"></app-textarea>
    <div class="headline">
        <h3><fa-icon icon="paperclip"></fa-icon> Anhänge</h3>
        <span class="spacer"></span>
        <mat-slide-toggle [(ngModel)]="DMSNotMinimalistic" [disabled]="!(Folder$ | async)">DMS</mat-slide-toggle>
        <button style="margin-left: auto;" app-round-button (click)="OpenFileUpload(diary.CommissionId)" matTooltip="Dateien hinzufügen">
            <app-double-icon [Icon]="DMSMeta.Icon" Icon2="upload"></app-double-icon>
        </button>
    </div>
    <app-dropzone *ngIf="Folder$ | async as folder" clearOnSuccess autoProcess style="min-height: 160px" [fileUploadParams]="getFileUploadParams(folder)"></app-dropzone>
    <app-file-explorer
        [FileUploadConfig]="{ ConstructionDiaryId: folder.Type === FolderTypes.construction_diary ? folder.EntityId : null, FolderId: folder.Id }"
        [FileUploadButton]="true"
        (FileClicked)="CloseSideDialog()"
        (FileUploadClicked)="CloseSideDialog()"
        [Minimalistic]="!DMSNotMinimalistic"
        style="height: 20rem"
        *ngIf="Folder$ | async as folder; else loading"
        [SelectedFolderId]="folder.Id"
        [DisableRoutingParams]="true"
    ></app-file-explorer>
</ng-template>

<ng-template #page3 let-diary="diary">
    <ng-container *ngIf="EventsErschwerniss$ | async as events">
        <app-dave-list-card
            *ngFor="let eventGroup of events"
            Headline="{{ eventGroup.type }}"
            [HeaderIcon]="eventGroup.icon | icon"
            [Data]="eventGroup.events"
            [DisplayedCollums]="['Name', 'Description', 'EventDate', 'FileCount' ]"
            [TableHeaderCollumsMap]="{ Name: 'Name', Description: 'Beschreibung', EventDate: 'Datum', FileCount: 'Anhänge' }"
            [CustomCellTemplates]="{ EventDate: dateColumn }"
            [DefaultSortActive]="'EventDate'"
            [Clickable]="true"
            (EntryClicked$)="OpenEditEventDialog($event.entry)"
            style="margin-bottom: 1rem; max-height: 50vh;"
            [IsExpandable]="true"
            [Expanded]="false"
        >
            <button
                headerButton
                app-round-button
                matTooltip="{{ eventGroup.type }} hinzufügen"
                [IsLoading]="!(Diary$ | async)?.Id"
                (click)="AddEvent(eventGroup.typeId, diary, eventGroup.type)"
            >
                <fa-icon icon="plus"></fa-icon>
            </button
            ></app-dave-list-card>
    </ng-container>
<!--    <ng-container *ngIf="EventsBedenkenanzeige$ | async as events">-->
<!--        <app-dave-list-card-->
<!--            *ngFor="let eventGroup of events"-->
<!--            Headline="{{ eventGroup.type }}"-->
<!--            [HeaderIcon]="eventGroup.icon | icon"-->
<!--            [Data]="eventGroup.events"-->
<!--            [DisplayedCollums]="['Name', 'Description']"-->
<!--            [TableHeaderCollumsMap]="{ Name: 'Name', Description: 'Beschreibung' }"-->
<!--            [Clickable]="true"-->
<!--            (EntryClicked$)="OpenEditEventDialog($event.entry)"-->
<!--            style="margin-bottom: 1rem; max-height: 50vh;"-->
<!--            [IsExpandable]="true"-->
<!--            [Expanded]="false"-->
<!--        >-->
<!--            <button-->
<!--                headerButton-->
<!--                app-round-button-->
<!--                matTooltip="{{ eventGroup.type }} hinzufügen"-->
<!--                [IsLoading]="!(Diary$ | async)?.Id"-->
<!--                (click)="AddEvent(eventGroup.typeId, diary, eventGroup.type)"-->
<!--            >-->
<!--                <fa-icon icon="plus"></fa-icon>-->
<!--            </button-->
<!--            ></app-dave-list-card>-->
<!--    </ng-container>-->
<!--    <ng-container *ngIf="EventsMangelanzeige$ | async as events">-->
<!--        <app-dave-list-card-->
<!--            *ngFor="let eventGroup of events"-->
<!--            Headline="{{ eventGroup.type }}"-->
<!--            [HeaderIcon]="eventGroup.icon | icon"-->
<!--            [Data]="eventGroup.events"-->
<!--            [DisplayedCollums]="['Name', 'Description']"-->
<!--            [TableHeaderCollumsMap]="{ Name: 'Name', Description: 'Beschreibung' }"-->
<!--            [Clickable]="true"-->
<!--            (EntryClicked$)="OpenEditEventDialog($event.entry)"-->
<!--            style="margin-bottom: 1rem; max-height: 50vh;"-->
<!--            [IsExpandable]="true"-->
<!--            [Expanded]="false"-->
<!--        >-->
<!--            <button-->
<!--                headerButton-->
<!--                app-round-button-->
<!--                matTooltip="{{ eventGroup.type }} hinzufügen"-->
<!--                [IsLoading]="!(Diary$ | async)?.Id"-->
<!--                (click)="AddEvent(eventGroup.typeId, diary, eventGroup.type)"-->
<!--            >-->
<!--                <fa-icon icon="plus"></fa-icon>-->
<!--            </button-->
<!--            ></app-dave-list-card>-->
<!--    </ng-container>-->
</ng-template>
<ng-template #dateColumn let-data="data">
<!--    <span class="custom-column">-->
        {{ data.EventDate | date }}&nbsp;
<!--    </span>-->
</ng-template>
<ng-template #page4 let-diary="diary">
    <ng-container *ngIf="EventsZusatz$ | async as events">
        <app-dave-list-card
            *ngFor="let eventGroup of events"
            Headline="{{ eventGroup.type }}"
            [HeaderIcon]="eventGroup.icon | icon"
            [Data]="eventGroup.events"
            [DisplayedCollums]="['Name', 'Description', 'EventDate', 'FileCount' ]"
            [TableHeaderCollumsMap]="{ Name: 'Name', Description: 'Beschreibung', EventDate: 'Datum', FileCount: 'Anhänge' }"
            [CustomCellTemplates]="{ EventDate: dateColumn }"
            [DefaultSortActive]="'EventDate'"
            [Clickable]="true"
            (EntryClicked$)="OpenEditEventDialog($event.entry, eventGroup.type)"
            style="margin-bottom: 1rem; max-height: 50vh;"
            [IsExpandable]="true"
            [Expanded]="false"
        >
            <button headerButton app-round-button matTooltip="{{ eventGroup.type }} hinzufügen" (click)="AddEvent(eventGroup.typeId, diary, eventGroup.type)">
                <fa-icon icon="plus"></fa-icon></button
        ></app-dave-list-card>
    </ng-container>
</ng-template>
