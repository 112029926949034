<!--[enableDesktopMode] tut auf desktop nicht da dann das hochladen von Bildern im Unterschrift dialog nicht mehr funktioniert-->
<ejs-pdfviewer
    #pdfViewer
    [serviceUrl]="Service"
    [toolbarSettings]="ToolbarSettings"
    [enableDesktopMode]="BS.TouchQuery | async"
    [enableToolbar]="true"
    (documentLoad)="documentLoad()"
    style="height:100%;width:100%;display:block"
></ejs-pdfviewer>
<div class="save-button">
    <button app-round-button *ngIf="Editing" (click)="OpenSignaturePopup();"><fa-icon icon="signature"></fa-icon></button>
    <button app-round-button *ngIf="SaveButton" (click)="Save()" [IsLoading]="LS.IsLoading$ | async"><fa-icon icon="save"></fa-icon></button>
    <button app-round-button (click)="Edit(); pdfViewer.toolbar.showAnnotationToolbar(this.Editing);"><fa-icon icon="pencil-alt"></fa-icon></button>
</div>
