<h3 mat-dialog-title>
    Zeitspanne für Export auswählen
</h3>
<mat-dialog-content>
    <div class="flex" *ngIf="Diaries; else loading">
        <ng-container *ngIf="!DialogData.DiaryType">
            <mat-slide-toggle [formControl]="FormGroup.controls.zusatzarbeiten">
                Zusatzarbeiten exportieren
            </mat-slide-toggle>
            <mat-slide-toggle [formControl]="FormGroup.controls.mangel">
                Mängel exportieren
            </mat-slide-toggle>
            <mat-slide-toggle [formControl]="FormGroup.controls.bedenken">
                Bedenkenanzeigen exportieren
            </mat-slide-toggle>
            <mat-slide-toggle [formControl]="FormGroup.controls.bautagebuch">
                Bautagebuch exportieren
            </mat-slide-toggle>
        </ng-container>
<!--        <mat-form-field class="example-full-width">-->
<!--            <mat-label>von</mat-label>-->
<!--            <input matInput [matDatepicker]="picker" [formControl]="DateForm" (click)="picker.open()" />-->
<!--            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--            <mat-datepicker [dateClass]="DateClass" #picker></mat-datepicker>-->
<!--        </mat-form-field>-->
<!--        <mat-form-field class="example-full-width">-->
<!--            <mat-label>bis</mat-label>-->
<!--            <input matInput [matDatepicker]="picker2" [formControl]="ToDateForm" (click)="picker2.open()" [min]="DateForm.value"/>-->
<!--            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>-->
<!--            <mat-datepicker [dateClass]="DateClass" #picker2 ></mat-datepicker>-->
<!--        </mat-form-field>-->

        <mat-form-field>
            <mat-label>Zeitraum</mat-label>
            <mat-date-range-input
                [rangePicker]="timespanPicker"
                (click)="timespanPicker.open()">
                <input matStartDate placeholder="Von" [formControl]="DateForm">
                <input matEndDate placeholder="Bis" [formControl]="ToDateForm">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="timespanPicker"></mat-datepicker-toggle>
            <mat-date-range-picker [dateClass]="DateClass" #timespanPicker></mat-date-range-picker>
        </mat-form-field>

        <ng-container *ngIf="FormGroup.controls.bautagebuch.value">
            <p *ngIf="DiaryCount$ | async as count">{{count === 1 ? 'Ein Bautagebucheintrag' : count + ' Bautagebucheinträge'}} für den Export ausgewählt</p>
            <p *ngIf="!(DiaryCount$ | async)">Keine Bautagebucheinträge für den Export ausgewählt</p>
        </ng-container>
        <ng-container *ngIf="FormGroup.controls.zusatzarbeiten.value">
            <p *ngIf="NachnahmenCount$ | async as count">{{count === 1 ? 'Eine Zusatzarbeit' : count + ' Zusatzarbeiten'}} für den Export ausgewählt</p>
            <p *ngIf="!(NachnahmenCount$ | async)">Keine Zusatzarbeiten für den Export ausgewählt</p>
        </ng-container>
        <ng-container *ngIf="FormGroup.controls.mangel.value">
            <p *ngIf="MangelCount$ | async as count">{{count === 1 ? 'Einen Mangel' : count + ' Mängel'}} für den Export ausgewählt</p>
            <p *ngIf="!(MangelCount$ | async)">Keine Mängel für den Export ausgewählt</p>
        </ng-container>
        <ng-container *ngIf="FormGroup.controls.bedenken.value">
            <p *ngIf="BedenkenCount$ | async as count">{{count === 1 ? 'Eine Bedenkenanzeige' : count + ' Bedenkenanzeigen'}} für den Export ausgewählt</p>
            <p *ngIf="!(BedenkenCount$ | async)">Keine Bedenkenanzeigen für den Export ausgewählt</p>
        </ng-container>
    </div>
</mat-dialog-content>
<div mat-dialog-actions>
    <button app-button Inverted mat-dialog-close [IsLoading]="LS.IsLoading$ | async" Color="cancel">Abbrechen</button>
    <button app-button [Disabled]="!FormGroup.controls.zusatzarbeiten.value && !(DiaryCount$ | async) || FormGroup.controls.zusatzarbeiten.value && !(NachnahmenCount$ | async) || FormGroup.invalid" [IsLoading]="LS.IsLoading$ | async" (click)="Submit()">Exportieren</button>
</div>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
