import { formatNumber } from '@angular/common';
import { FormControl,FormGroup,Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, map, skip, withLatestFrom } from 'rxjs/operators';
import { AdditionalDataCommissionEntity,CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { CommissionTypeEntity,CommissionTypeNameEnum } from '../../../dave-data-module/entities/commissionType.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { DamageFlowEntity } from '../../../dave-data-module/entities/damageflow.entity';
import { OfficeEntity } from '../../../dave-data-module/entities/office.entity';
import { PartnerEntity,PartnerTypeEnum } from '../../../dave-data-module/entities/partner.entity';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { CommissionActionTypes } from '../../../dave-data-module/State/actions/commission.actions';
import { DamageFlowActionTypes } from '../../../dave-data-module/State/actions/damageflow.actions';
import {
    getCustomerDictionary,
    getNotDeletedCustomers,
} from '../../../dave-data-module/State/selectors/customers.selectors';
import { getOffices } from '../../../dave-data-module/State/selectors/offices.selectors';
import { IDetailListTemplateDataProperty } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { Address, getAddressString, isNotNullOrUndefined, MathRound } from '../../../helper/helper';
import { decimalPriceValidator } from '../../../helper/validation.helper';
import { EntityRoleEntity } from "../../../dave-data-module/entities/entity-role.entity";
import moment, { Moment } from "moment";
import { combineLatest, Observable } from 'rxjs';
import {
    SelectSearchOption
} from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { getPartner } from '../../../dave-data-module/State/selectors/partners.selectors';
import { getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCommissionTypes } from '../../../dave-data-module/State/selectors/commissionType.selectors';
import { ChangeDetectorRef } from '@angular/core';

export interface ICommissionDetailListTemplateEntrys {
    Customer?: IDetailListTemplateDataProperty;
    CommissionType?: IDetailListTemplateDataProperty<SelectSearchData | null>;
    CommissonNumberAuto?: IDetailListTemplateDataProperty;
    CommissonNumber?: IDetailListTemplateDataProperty;
    InterneNummer?: IDetailListTemplateDataProperty;
    InterneNummerAuto?: IDetailListTemplateDataProperty;
    Sache?: IDetailListTemplateDataProperty;
    Location?: IDetailListTemplateDataProperty;
    Auftragseingang?: IDetailListTemplateDataProperty;
    StartDate?: IDetailListTemplateDataProperty;
    PlanedStartDate?: IDetailListTemplateDataProperty;
    EndDate?: IDetailListTemplateDataProperty;
    PlanedEndDate?: IDetailListTemplateDataProperty;
    AbgabeterminAG?: IDetailListTemplateDataProperty;
    AbgabeterminQM?: IDetailListTemplateDataProperty;
    LetzteFrist?: IDetailListTemplateDataProperty;
    Schadensdatum?: IDetailListTemplateDataProperty;
    Versicherungssumme?: IDetailListTemplateDataProperty;
    Schadennummer?: IDetailListTemplateDataProperty;
    Versicherungsnummer?: IDetailListTemplateDataProperty;
    AutomaticAccountsReceivableLedger?: IDetailListTemplateDataProperty;
    AutomaticAccountsReceivableLedgerByClockIns?: IDetailListTemplateDataProperty;
    VorarbeiterBonus?: IDetailListTemplateDataProperty;
    NachlassZulage?: IDetailListTemplateDataProperty;
    CustomerLocation?: IDetailListTemplateDataProperty;
    CompleteBusinessVolume?: IDetailListTemplateDataProperty;
    CostsCompleteBusinessVolume?: IDetailListTemplateDataProperty;
    PlanedWorkingHours?: IDetailListTemplateDataProperty;
    ProjectInfo?: IDetailListTemplateDataProperty;
    OrderNoCustomer?: IDetailListTemplateDataProperty;
    CostCenter?: IDetailListTemplateDataProperty;
    HandoverDate?: IDetailListTemplateDataProperty;
}
export interface ICommissionForm {
    FreeTextField3: FormControl<string | null>;
    FreeTextField1: FormControl<string | null>;
    Description: FormControl<string | null>;
    VersicherungsPhoneNumber: FormControl<string | null>;
    FreeTextField2: FormControl<string | null>;
    AbgabeterminQM: FormControl<Date | null>;
    Customer: FormControl<SelectSearchData | null>;
    PartnerOffice: FormControl<SelectSearchData | null>;
    Versicherungsbedingung: FormControl<string | null>;
    CreatedAt: FormControl<Date | null>;
    Versicherungssumme: FormControl<string | null>;
    VersicherungsName: FormControl<string | null>;
    AuftragseingangDurchAuftraggeber: FormControl<Date | null>;
    VersicherungsStreet: FormControl<string | null>;
    Auftragsnummer: FormControl<string | null>;
    StatusfromSVId: FormControl<SelectSearchData | null>;
    StartDate: FormControl<Date | null>;
    PlannedStartDate: FormControl<Date | null>;
    OrderNoCustomer: FormControl<string | null>;
    CommissionTypeId: FormControl<SelectSearchData | null>;
    StatusBackofficeId: FormControl<SelectSearchData | null>;
    AutomaticAuftragsnummer: FormControl<boolean | null>;
    Schadennummer: FormControl<string | null>;
    AbgabeterminAG: FormControl<Date | null>;
    AutomaticAccountsReceivableLedger: FormControl<boolean | null>;
    VorarbeiterBonus: FormControl<number | null>;
    VersicherungsArbeitsgebiet: FormControl<string | null>;
    CompleteBusinessVolume: FormControl<number | null>;
    CostsCompleteBusinessVolume: FormControl<number | null>;
    LastDeadline: FormControl<Date | null>;
    PlanedWorkingHours: FormControl<number | null>;
    AutomaticAccountsReceivableLedgerByClockIns: FormControl<boolean | null>;
    UpdatedAt: FormControl<Date | null>;
    EndDate: FormControl<Date | null>;
    NachlassZulage: FormControl<number | null>;
    VersicherungsCity: FormControl<string | null>;
    Schadensdatum: FormControl<Date | null>;
    VersicherungsPostalCode: FormControl<string | null>;
    PlannedEndDate: FormControl<Date | null>;
    Aktenzeichen: FormControl<string | null>;
    VersicherungsDescription: FormControl<string | null>;
    VersicherungsMobileNumber: FormControl<string | null>;
    InterneNummer: FormControl<string | null>;
    Versicherungsnummer: FormControl<string | null>;
    VersicherungsZusatzName: FormControl<string | null>;
    Person: FormControl<SelectSearchData | null>;
    AutomaticInterneNummer: FormControl<boolean | null>;
    CostCenter: FormControl<string | null>;
    HandoverDate: FormControl<Moment | null>;
}

export function createCommissionFormGroup(): FormGroup<ICommissionForm> {
    return new FormGroup({
        Auftragsnummer: new FormControl<string | null>(null),
        AutomaticAuftragsnummer: new FormControl<boolean>(null),
        Description: new FormControl<string | null>(null),
        InterneNummer: new FormControl<string | null>(null),
        AutomaticInterneNummer: new FormControl<boolean>(null),
        AutomaticAccountsReceivableLedger: new FormControl<boolean>(null),
        AutomaticAccountsReceivableLedgerByClockIns: new FormControl<boolean>(null),
        Schadennummer: new FormControl<string | null>(null),
        Versicherungsnummer: new FormControl<string | null>(null),
        Aktenzeichen: new FormControl<string | null>(null),
        AbgabeterminAG: new FormControl<Date | null>(null),
        AuftragseingangDurchAuftraggeber: new FormControl<Date | null>(null),
        AbgabeterminQM: new FormControl<Date | null>(null),
        CreatedAt: new FormControl<Date | null>(null),
        UpdatedAt: new FormControl<Date | null>(null),
        Schadensdatum: new FormControl<Date | null>(null),
        StatusBackofficeId: new FormControl<SelectSearchData | null>(null),
        StatusfromSVId: new FormControl<SelectSearchData | null>(null),
        CommissionTypeId: new FormControl<SelectSearchData | null>(null, Validators.required),
        LastDeadline: new FormControl<Date | null>(null),
        Versicherungsbedingung: new FormControl<string | null>(null),
        Versicherungssumme: new FormControl<string | null>(null),
        VersicherungsName: new FormControl<string | null>(null),
        VersicherungsZusatzName: new FormControl<string | null>(null),
        VersicherungsArbeitsgebiet: new FormControl<string | null>(null),
        VersicherungsDescription: new FormControl<string | null>(null),
        VersicherungsPhoneNumber: new FormControl<string | null>(null),
        VersicherungsMobileNumber: new FormControl<string | null>(null),
        VersicherungsStreet: new FormControl<string | null>(null),
        VersicherungsPostalCode: new FormControl<string | null>(null),
        VersicherungsCity: new FormControl<string | null>(null),
        Customer: new FormControl<SelectSearchData | null>(null),
        Person: new FormControl<SelectSearchData | null>(null),
        StartDate: new FormControl<Date | null>(null),
        PlannedStartDate: new FormControl<Date | null>(null),
        EndDate: new FormControl<Date | null>(null),
        PlannedEndDate: new FormControl<Date | null>(null),
        PartnerOffice: new FormControl<SelectSearchData | null>(null),
        NachlassZulage: new FormControl<number | null>(null),
        VorarbeiterBonus: new FormControl<number | null>(null),
        FreeTextField1: new FormControl<string | null>(null),
        FreeTextField2: new FormControl<string | null>(null),
        FreeTextField3: new FormControl<string | null>(null),
        OrderNoCustomer: new FormControl<string | null>(null),
        CompleteBusinessVolume: new FormControl<number | null>(null, decimalPriceValidator()),
        CostsCompleteBusinessVolume: new FormControl<number | null>(null, decimalPriceValidator()),
        PlanedWorkingHours: new FormControl<number | null>(null),
        CostCenter: new FormControl<string | null>(null),
        HandoverDate: new FormControl<Moment | null>(null),
    });
}
export function resetCommissionFormGroup(commission: CommissionEntity, form: ReturnType<typeof createCommissionFormGroup>) {
    form.reset({
        AutomaticAccountsReceivableLedger: commission.AutomaticAccountsReceivableLedger == 1,
        AutomaticAccountsReceivableLedgerByClockIns: commission.AutomaticAccountsReceivableLedgerByClockIns == 1,
        Auftragsnummer: commission.Auftragsnummer,
        Description: commission.Description,
        InterneNummer: commission.InterneNummer,
        Versicherungssumme: commission.Versicherungssumme,
        Schadennummer: commission.Schadennummer,
        Versicherungsnummer: commission.Versicherungsnummer,
        Aktenzeichen: commission.Aktenzeichen,
        AbgabeterminAG: commission.AbgabeterminAG,
        AuftragseingangDurchAuftraggeber: commission.AuftragseingangDurchAuftraggeber,
        AbgabeterminQM: commission.AbgabeterminQM,
        CreatedAt: commission.CreatedAt,
        UpdatedAt: commission.UpdatedAt,
        Schadensdatum: commission.Schadensdatum,
        StartDate: commission.StartDate,
        PlannedStartDate: commission.PlannedStartDate,
        EndDate: commission.EndDate,
        PlannedEndDate: commission.PlannedEndDate,
        PartnerOffice: {
            Name: '',
            Id: commission.PartnerOfficeId,
        },
        StatusBackofficeId: {
            Name: '',
            Id: commission.StatusBackofficeId,
        },
        StatusfromSVId: {
            Name: '',
            Id: commission.StatusfromSVId,
        },
        CommissionTypeId: {
            Name: '',
            Id: commission.CommissionTypeId,
        },
        LastDeadline: commission.LastDeadline,
        Versicherungsbedingung: commission.Versicherungsbedingung,
        VersicherungsName: commission.VersicherungsName,
        VersicherungsZusatzName: commission.VersicherungsZusatzName,
        VersicherungsArbeitsgebiet: commission.VersicherungsArbeitsgebiet,
        VersicherungsDescription: commission.VersicherungsDescription,
        VersicherungsPhoneNumber: commission.VersicherungsPhoneNumber,
        VersicherungsMobileNumber: commission.VersicherungsMobileNumber,
        VersicherungsStreet: commission.VersicherungsStreet,
        VersicherungsPostalCode: commission.VersicherungsPostalCode,
        VersicherungsCity: commission.VersicherungsCity,
        Customer: {
            Name: '',
            Id: commission.CustomerId,
        },
        Person: {
            Name: '',
            Id: commission.PersonId,
        },
        NachlassZulage: commission.AdditionalData?.akkord?.nachlassZulage * 100 || null,
        VorarbeiterBonus: commission.AdditionalData?.akkord?.vorarbeiterBonus * 100 || null,
        FreeTextField1: commission.FreeTextField1,
        FreeTextField2: commission.FreeTextField2,
        FreeTextField3: commission.FreeTextField3,
        OrderNoCustomer: commission.OrderNoCustomer,
        CompleteBusinessVolume: commission.CompleteBusinessVolume ? commission.CompleteBusinessVolume / 100 : null,
        CostsCompleteBusinessVolume: commission.CostsCompleteBusinessVolume ? commission.CostsCompleteBusinessVolume / 100 : null,
        PlanedWorkingHours: isNotNullOrUndefined(commission.AdditionalData?.planedWorkingHours) ? commission.AdditionalData.planedWorkingHours / 60 / 60 / 1000 : null,
        CostCenter: commission.CostCenter,
        HandoverDate: commission.HandoverDate && moment(commission.HandoverDate),
    });
    if (commission.CustomerId) {
        form.controls.PartnerOffice.disable();
    } else {
        form.controls.PartnerOffice.enable();
    }
}
export function setCommissionDetailListTemplateEntries(
    commission: CommissionEntity,
    obj: ICommissionDetailListTemplateEntrys,
    keys: Array<keyof ICommissionDetailListTemplateEntrys>,
    form: FormGroup<Partial<ICommissionForm>> |  FormGroup<ICommissionForm>,
    entityRoles: EntityRoleEntity[],
    data: {
        CustomerName?: string;
        CommissionName?: string;
        customers?: CustomerEntity[];
        offices?: OfficeEntity[];
        commissionType?: CommissionTypeEntity;
        commissionTypes?: CommissionTypeEntity[];
        partner?: PartnerEntity;
        currentCommissionTypeEntity?: CommissionTypeEntity;
        currentCommissionType?: SelectSearchData | { Id: number };
        SearchLocationForm?: FormControl;
        CommissionLocationForm?: FormControl<Address>;
        commissionLocationOptions?: SelectSearchOption<{ searchValues?: string[]; value: Address }>[];
    },
    commissionLabel: string,
) {
    let commissionTypeGericht = null;
    if (data.commissionTypes) {
        commissionTypeGericht = data.commissionTypes.find((c) => c.Name === CommissionTypeNameEnum.Gericht);
    }
    keys.forEach((key) => {
        switch (key) {
            case 'AbgabeterminAG':
                obj.AbgabeterminAG = {
                    value: commission.AbgabeterminAG,
                    key: CommissionEntity.EntityPropertyNames.get('AbgabeterminAG'),
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('AbgabeterminAG'),
                    options: {
                        type: CustomPropertyType.Datum,
                        specialInput: { date: true },
                    },
                };
                break;
            case 'AbgabeterminQM':
                obj.AbgabeterminQM = {
                    value: commission.AbgabeterminQM,
                    key: CommissionEntity.EntityPropertyNames.get('AbgabeterminQM'),
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('AbgabeterminQM'),
                    options: {
                        type: CustomPropertyType.Datum,
                        specialInput: { date: true },
                    },
                };
                break;
            case 'LetzteFrist':
                obj.LetzteFrist = {
                    value: commission.LastDeadline,
                    key: CommissionEntity.EntityPropertyNames.get('LastDeadline'),
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('LastDeadline'),
                    options: {
                        type: CustomPropertyType.Datum,
                        specialInput: { date: true },
                    },
                };
                break;
            case 'NachlassZulage':
                obj.NachlassZulage = {
                    value: commission.AdditionalData?.akkord?.nachlassZulage,
                    key: 'Nachlass/Zulage',
                    formControl: form.controls.NachlassZulage,
                    options: {
                        specialInput: {
                            number: true,
                        },
                        suffix: '%',
                        type: CustomPropertyType.Percentage,
                    },
                };
                break;
            case 'VorarbeiterBonus':
                obj.VorarbeiterBonus = {
                    value: commission.AdditionalData?.akkord?.vorarbeiterBonus,
                    key: 'Vorarbeiterbonus',
                    formControl: form.controls.VorarbeiterBonus,
                    options: {
                        specialInput: {
                            number: true,
                        },
                        suffix: '%',
                        type: CustomPropertyType.Percentage,
                    },
                };
                break;
            case 'Customer':
                obj.Customer = {
                    key: data.CustomerName as string,
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('Customer'),
                    options: {
                        specialInput: {
                            selectSearch: data.customers?.map((c) => ({
                                optionValue: c.Id,
                                optionLabel: isNotNullOrUndefined(c.CustomerNo)
                                    ? c.CustomerNo +
                                      (isNotNullOrUndefined(c.Name) ? ' ' + c.Name + ' ' : '') +
                                      (data.offices?.find((o) => o.CustomerId === c.Id)?.Street !== null ? ' - ' + getAddressString(data.offices?.find((o) => o.CustomerId === c.Id)) : '')
                                    : isNotNullOrUndefined(c.Name)
                                    ? c.Name
                                    : 'unbenannter Kunde',
                            })),
                        },
                    },
                };
                break;
            case 'CommissionType':
                obj.CommissionType = {
                    value: data.commissionType?.Name || null,
                    key: 'Auftragsart',
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('CommissionTypeId'),
                    options: {
                        specialInput: {
                            singleSelectSearch: {
                                options:
                                    data.commissionTypes
                                        ?.filter((ct) => ct.Id === data.commissionType?.Id || !ct.PartnerSpecificationTypeId || ct.PartnerSpecificationTypeId === data.partner.PartnerSpecificationTypeId)
                                        .filter((ct) => !data.commissionType || !data.commissionType.AdditionalData?.CanHaveChildCommissions || ct.AdditionalData?.CanHaveChildCommissions)
                                        .map((c) => ({
                                            optionLabel: c.Name,
                                            infoTooltip: c.AdditionalData?.CanHaveChildCommissions ? 'Diese Auftragsart kann Teilprojekte haben' : null,
                                            Id: c.Id,
                                            Name: c.Name,
                                        })) || [],
                                compareOptions: (a, b) => a.Id === b.Id,
                            },
                        },
                    },
                };
                break;
            case 'Sache':
                obj.Sache = {
                    value: commission.Description,
                    key: data.CommissionName,
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('Description'),
                };
                break;
            case 'CommissonNumber':
                obj.CommissonNumber = {
                    key: data.currentCommissionTypeEntity?.EntityPropertyNames.get('Auftragsnummer') || CommissionEntity.EntityPropertyNames.get('Auftragsnummer'),
                    value: commission.Auftragsnummer,
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('Auftragsnummer'),
                    options: {
                        showHint: 'Um einer Verwechslungen vorzubeugen, werden bei der automatisierten Zuordnung nur Nummern mit fünf oder mehr Zeichen berücksichtigt.',
                    },
                };
                break;
            case 'CommissonNumberAuto':
                obj.CommissonNumberAuto = {
                    key: (data.currentCommissionTypeEntity?.EntityPropertyNames.get('Auftragsnummer') || CommissionEntity.EntityPropertyNames.get('Auftragsnummer')) + ' automatisch vergeben',
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('AutomaticAuftragsnummer'),
                    options: {
                        type: CustomPropertyType.Boolean,
                        specialInput: { boolean: true },
                        showHint: 'Erzeugt eine Auftragsnummer aus Jahr, Monat und einer fortlaufenden Nummer nach dem Muster A202001-1.',
                    },
                };
                break;
            case 'CostCenter':
                obj.CostCenter = {
                    key: CommissionEntity.EntityPropertyNames.get('CostCenter'),
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('CostCenter'),
                    value: commission.CostCenter,
                };
                break;
            case 'InterneNummer':
                obj.InterneNummer = {
                    key: CommissionEntity.EntityPropertyNames.get('InterneNummer'),
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('InterneNummer'),
                    options: {
                        showHint: 'Um einer Verwechslungen vorzubeugen, werden bei der automatisierten Zuordnung nur Nummern mit fünf oder mehr Zeichen berücksichtigt.',
                    },
                };
                break;
            case 'InterneNummerAuto':
                obj.InterneNummerAuto = {
                    key: 'InterneNr. automatisch auswählen',
                    formControl: form.controls.AutomaticInterneNummer,
                    options: {
                        type: CustomPropertyType.Boolean,
                        specialInput: { boolean: true },
                        showHint: 'Erzeugt eine Interne Nummer aus Jahr, Monat und einer fortlaufenden Nummer nach dem Muster I202001-1.',
                    },
                };
                break;
            // case 'CustomerLocation':
            //     obj.CustomerLocation = {
            //         key: 'Adressen durchsuchen',
            //         formControl: data.SearchLocationForm,
            //         options: {
            //             specialInput: {
            //                 selectSearch: data.offices
            //                     ?.filter((o) => o.Street)
            //                     .map((o) => ({
            //                         optionValue: o.Id,
            //                         optionLabel: [data.customers.find((c) => c.Id === o.CustomerId)?.Name, '-', o.Street, o.PostalCode, o.City, o.Country].join(' '),
            //                     })),
            //             },
            //         },
            //     };
            //     break;
            case 'Location':
                const address = commission
                    ? {
                          Street: commission.Street,
                          PostalCode: commission.PostalCode,
                          City: commission.City,
                          Country: commission.Country,
                      }
                    : (null as Address);
                console.log(data.CommissionLocationForm)

                obj.Location = {
                    key: 'Adresse',
                    options: {
                        type: CustomPropertyType.Location,
                        specialInput: {
                            location: {
                                value: address,
                                formGroup: data.CommissionLocationForm,
                                options: data.commissionLocationOptions,
                            },
                        },
                    },
                    value: getAddressString(address),
                };
                break;
            case 'Auftragseingang':
                obj.Auftragseingang = {
                    value: commission.AuftragseingangDurchAuftraggeber,
                    key: CommissionEntity.EntityPropertyNames.get('AuftragseingangDurchAuftraggeber'),
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('AuftragseingangDurchAuftraggeber'),
                    options: {
                        type: CustomPropertyType.Datum,
                        specialInput: { date: true },
                    },
                };
                break;
            case 'StartDate':
                obj.StartDate = {
                    value: commission.StartDate,
                    key: CommissionEntity.EntityPropertyNames.get('StartDate'),
                    formControl: form.controls.StartDate,
                    options: {
                        type: CustomPropertyType.Datum,
                        specialInput: { date: true },
                    },
                };
                break;
            case 'PlanedStartDate':
                obj.PlanedStartDate = {
                    value: commission.PlannedStartDate,
                    key: data.partner.PartnerTypeId === PartnerTypeEnum.Kagetec ? 'Installations Start' : 'Geplantes Startdatum',
                    formControl: form.controls.PlannedStartDate,
                    options: {
                        type: CustomPropertyType.Datum,
                        specialInput: { date: true },
                    },
                };
                break;
            case 'EndDate':
                obj.EndDate = {
                    value: commission.EndDate,
                    key: CommissionEntity.EntityPropertyNames.get('EndDate'),
                    formControl: form.controls.EndDate,
                    options: {
                        type: CustomPropertyType.Datum,
                        specialInput: { date: true },
                    },
                };
                break;
            case 'PlanedEndDate':
                obj.PlanedEndDate = {
                    value: commission.PlannedEndDate,
                    key: data.partner.PartnerTypeId === PartnerTypeEnum.Kagetec ? 'Installations Ende' : 'Geplantes Enddatum',
                    formControl: form.controls.PlannedEndDate,
                    options: {
                        type: CustomPropertyType.Datum,
                        specialInput: { date: true },
                    },
                };
                break;
            case 'Schadensdatum':
                obj.Schadensdatum = {
                    value: commission.Schadensdatum,
                    key: CommissionEntity.EntityPropertyNames.get('Schadensdatum'),
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('Schadensdatum'),
                    options: {
                        type: CustomPropertyType.Datum,
                        specialInput: { date: true },
                    },
                };
                break;
            case 'Versicherungssumme':
                if (commission.permissionGet('Versicherungssumme', entityRoles)) {
                    obj.Versicherungssumme = {
                        hideFormControl: data.currentCommissionType?.Id === commissionTypeGericht.Id,
                        value: data.currentCommissionType?.Id === commissionTypeGericht.Id ? null : commission.Versicherungssumme,
                        key: CommissionEntity.EntityPropertyNames.get('Versicherungssumme'),
                        formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('Versicherungssumme'),
                    };
                }
                break;
            case 'Schadennummer':
                obj.Schadennummer = {
                    hideFormControl: data.currentCommissionType?.Id === commissionTypeGericht.Id,
                    value: data.currentCommissionType?.Id === commissionTypeGericht.Id ? null : commission.Schadennummer,
                    key: CommissionEntity.EntityPropertyNames.get('Schadennummer'),
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('Schadennummer'),
                    options: {
                        showHint: 'Um einer Verwechslungen vorzubeugen, werden bei der automatisierten Zuordnung nur Nummern mit fünf oder mehr Zeichen berücksichtigt.',
                    },
                };
                break;
            case 'Versicherungsnummer':
                obj.Versicherungsnummer = {
                    hideFormControl: data.currentCommissionType?.Id === commissionTypeGericht.Id,
                    value: data.currentCommissionType?.Id === commissionTypeGericht.Id ? null : commission.Versicherungsnummer,
                    key: CommissionEntity.EntityPropertyNames.get('Versicherungsnummer'),
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('Versicherungsnummer'),
                    options: {
                        showHint: 'Um einer Verwechslungen vorzubeugen, werden bei der automatisierten Zuordnung nur Nummern mit fünf oder mehr Zeichen berücksichtigt.',
                    },
                };
                break;
            case 'AutomaticAccountsReceivableLedger':
                obj.AutomaticAccountsReceivableLedger = {
                    key: CommissionEntity.EntityPropertyNames.get('AutomaticAccountsReceivableLedger'),
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('AutomaticAccountsReceivableLedger'),
                    value: commission.AutomaticAccountsReceivableLedger == 1,
                    options: {
                        type: CustomPropertyType.Boolean,
                        specialInput: { boolean: true },
                        showHint: 'Sollen Buchungspositionen für diesen ' + commissionLabel + ' automatisch erzeugt werden?',
                    },
                };
                break;
            case 'AutomaticAccountsReceivableLedgerByClockIns':
                obj.AutomaticAccountsReceivableLedgerByClockIns = {
                    key: CommissionEntity.EntityPropertyNames.get('AutomaticAccountsReceivableLedgerByClockIns'),
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('AutomaticAccountsReceivableLedgerByClockIns'),
                    value: commission.AutomaticAccountsReceivableLedgerByClockIns == 1,
                    options: {
                        type: CustomPropertyType.Boolean,
                        specialInput: { boolean: true },
                        showHint: 'Sollen Buchungspositionen für diesen ' + commissionLabel + ' automatisch mit Hilfe der Zeiterfassungsdaten erzeugt werden?',
                    },
                };
                break;
            case 'ProjectInfo':
                obj.ProjectInfo = {
                    value: commission.FreeTextField1,
                    key: CommissionEntity.EntityPropertyNames.get('FreeTextField1'),
                    formControl: form.controls.FreeTextField1,
                    options: {
                        specialInput: {
                            textArea: {
                                Fill: false,
                            },
                        },
                    },
                };
                break;
            case 'OrderNoCustomer':
                obj.OrderNoCustomer = {
                    value: commission.OrderNoCustomer,
                    key: CommissionEntity.EntityPropertyNames.get('OrderNoCustomer'),
                    formControl: form.controls.OrderNoCustomer,
                };
                break;
            case 'CompleteBusinessVolume':
                if (commission.permissionGet('CompleteBusinessVolume', entityRoles)) {
                    obj.CompleteBusinessVolume = {
                        value: commission.CompleteBusinessVolume ? formatNumber(commission.CompleteBusinessVolume / 100, 'de-DE', '1.2-2') + ' €' : null,
                        formControl: form.controls.CompleteBusinessVolume,
                        key: CommissionEntity.EntityPropertyNames.get('CompleteBusinessVolume'),
                        options: {
                            specialInput: { number: true },
                            suffix: '€',
                        },
                    };
                }
                break;
            case 'CostsCompleteBusinessVolume':
                if (commission.permissionGet('CostsCompleteBusinessVolume', entityRoles)) {
                    obj.CostsCompleteBusinessVolume = {
                        value: commission.CostsCompleteBusinessVolume ? formatNumber(commission.CostsCompleteBusinessVolume / 100, 'de-DE', '1.2-2') + ' €' : null,
                        formControl: form.controls.CostsCompleteBusinessVolume,
                        key: 'Geplante Kosten (Netto)',
                        options: {
                            specialInput: { number: true },
                            suffix: '€',
                        },
                    };
                }
                break;
            case 'PlanedWorkingHours':
                if (commission.permissionGet('planedWorkingHours', entityRoles)) {
                    obj.PlanedWorkingHours = {
                        value: isNotNullOrUndefined(commission.AdditionalData?.planedWorkingHours) ? formatNumber(commission.AdditionalData?.planedWorkingHours / 1000 / 60 / 60, 'de-DE', '1.0-2') + ' h' : null,
                        formControl: form.controls.PlanedWorkingHours,
                        key: 'Geplanter Arbeitsaufwand',
                        options: {
                            specialInput: { number: true },
                            suffix: 'h',
                            showHint: 'Wenn der geplante Arbeitsaufwand hier nicht eingetragen wird, wird dieser aus den bestätigten Angeboten errechnet.',
                        },
                    };
                }
                break;
            case 'HandoverDate':
                obj.HandoverDate = {
                    value: commission.HandoverDate,
                    key: 'Übergabetermin',
                    formControl: (form as  FormGroup<Partial<ICommissionForm>>).get('HandoverDate'),
                    options: {
                        type: CustomPropertyType.Datum,
                        specialInput: { date: true },
                    },
                };
                break;
        }
    });
}
export function saveCommission(
    store: Store<State>,
    commission: CommissionEntity,
    wizardPage: number,
    CommissionForm: FormGroup,
    CommissionLocationForm: FormControl<Address>,
    DamageFlowForm?: FormGroup,
    DamageFlowLocationForm?: FormGroup,
    damageFlows?: DamageFlowEntity[],
) {
    const additionalData: AdditionalDataCommissionEntity = {
        ...commission.AdditionalData,
        wizardPage,
        planedWorkingHours: CommissionForm.value.PlanedWorkingHours != null ? CommissionForm.value.PlanedWorkingHours * 1000 * 60 * 60 : null,
        akkord: {
            ...commission.AdditionalData?.akkord,
            nachlassZulage: CommissionForm.controls.NachlassZulage.value / 100,
            vorarbeiterBonus: CommissionForm.controls.VorarbeiterBonus.value / 100,
        },
    };
    store.dispatch(
        CommissionActionTypes.ModifyCommission({
            Payload: {
                id: commission.Id,
                description: CommissionForm.get('Description').value,
                commissionTypeId: CommissionForm.get('CommissionTypeId').value.Id,
                auftragsnummer: CommissionForm.get('Auftragsnummer').value,
                automaticAuftragsnummer: CommissionForm.get('AutomaticAuftragsnummer').value,
                interneNummer: CommissionForm.get('InterneNummer').value,
                automaticInterneNummer: CommissionForm.get('AutomaticInterneNummer').value,
                schadennummer: CommissionForm.get('Schadennummer').value,
                versicherungssumme: CommissionForm.get('Versicherungssumme').value,
                automaticAccountsReceivableLedger: CommissionForm.get('AutomaticAccountsReceivableLedger').value ? 1 : 2,
                automaticAccountsReceivableLedgerByClockIns: CommissionForm.get('AutomaticAccountsReceivableLedgerByClockIns').value ? 1 : 2,
                versicherungsnummer: CommissionForm.get('Versicherungsnummer').value,
                aktenzeichen: CommissionForm.controls.Aktenzeichen.value,
                versicherungsbedingung: CommissionForm.get('Versicherungsbedingung').value,
                abgabeterminAG: CommissionForm.get('AbgabeterminAG').value ? FrontendDate(CommissionForm.get('AbgabeterminAG').value) : null,
                auftragseingangDurchAuftraggeber: CommissionForm.get('AuftragseingangDurchAuftraggeber').value ? FrontendDate(CommissionForm.get('AuftragseingangDurchAuftraggeber').value) : null,
                abgabeterminQM: CommissionForm.get('AbgabeterminQM').value ? FrontendDate(CommissionForm.get('AbgabeterminQM').value) : null,
                schadensdatum: CommissionForm.get('Schadensdatum').value ? FrontendDate(CommissionForm.get('Schadensdatum').value) : null,
                statusBackofficeId: CommissionForm.get('StatusBackofficeId').value?.Id,
                statusFromSVId: CommissionForm.get('StatusfromSVId').value?.Id,
                lastDeadline: CommissionForm.get('LastDeadline').value ? FrontendDate(CommissionForm.get('LastDeadline').value) : null,
                versicherungsArbeitsgebiet: CommissionForm.controls.VersicherungsArbeitsgebiet.value,
                versicherungsCity: CommissionForm.controls.VersicherungsCity.value,
                versicherungsDescription: CommissionForm.controls.VersicherungsDescription.value,
                versicherungsMobileNumber: CommissionForm.controls.VersicherungsMobileNumber.value,
                versicherungsPhoneNumber: CommissionForm.controls.VersicherungsPhoneNumber.value,
                versicherungsName: CommissionForm.controls.VersicherungsName.value,
                versicherungsPostalCode: CommissionForm.controls.VersicherungsPostalCode.value,
                versicherungsStreet: CommissionForm.controls.VersicherungsStreet.value,
                versicherungsZusatzName: CommissionForm.controls.VersicherungsZusatzName.value,
                customerId: CommissionForm.controls.Customer.value?.Id || null,
                personId: CommissionForm.controls.Person.value?.Id || null,
                startDate: CommissionForm.controls.StartDate.value ? FrontendDate(CommissionForm.value.StartDate) : null,
                endDate: CommissionForm.controls.EndDate.value ? FrontendDate(CommissionForm.value.EndDate) : null,
                plannedStartDate: CommissionForm.controls.PlannedStartDate.value ? FrontendDate(CommissionForm.value.PlannedStartDate) : null,
                plannedEndDate: CommissionForm.controls.PlannedEndDate.value ? FrontendDate(CommissionForm.value.PlannedEndDate) : null,
                partnerOfficeId: CommissionForm.getRawValue().PartnerOffice?.Id,
                street: CommissionLocationForm.value.Street,
                postalCode: CommissionLocationForm.value.PostalCode,
                city: CommissionLocationForm.value.City,
                country: CommissionLocationForm.value.Country,
                additionalData: JSON.stringify(additionalData),
                freeTextField1: CommissionForm.value.FreeTextField1,
                freeTextField2: CommissionForm.value.FreeTextField2,
                freeTextField3: CommissionForm.value.FreeTextField3,
                orderNoCustomer: CommissionForm.value.OrderNoCustomer,
                completeBusinessVolume: MathRound(CommissionForm.value.CompleteBusinessVolume * 100, true),
                costsCompleteBusinessVolume: MathRound(CommissionForm.value.CostsCompleteBusinessVolume * 100, true),
                costCenter: CommissionForm.value.CostCenter,
                handoverDate: CommissionForm.value.HandoverDate,
            },
        }),
    );
    if (DamageFlowForm) {
        const damageFlow = damageFlows.find((d) => d.CommissionId === commission.Id);
        store.dispatch(
            DamageFlowActionTypes.ModifyDamageFlow({
                Payload: {
                    id: damageFlow.Id,
                    abgabeterminBerichtReal: DamageFlowForm.controls.AbgabeterminBerichtReal.value ? FrontendDate(DamageFlowForm.controls.AbgabeterminBerichtReal.value) : null,
                    street: DamageFlowLocationForm.controls.Street.value,
                    postalCode: DamageFlowLocationForm.controls.PostalCode.value,
                    city: DamageFlowLocationForm.controls.City.value,
                    country: DamageFlowLocationForm.controls.Country.value,
                    description: DamageFlowForm.controls.Description.value,
                    damageFlowTypeId: DamageFlowForm.controls.DamageFlowTypeId.value?.Id,
                    deadlineVerlaengerung: DamageFlowForm.controls.DeadlineVerlaengerung.value,
                    ersterBerichtEingegangen: Boolean(DamageFlowForm.controls.ErsterBerichtEingegangen.value),
                    commissionId: commission.Id,
                    ersterOrtstermin: DamageFlowForm.controls.ErsterOrtstermin.value ? FrontendDate(DamageFlowForm.controls.ErsterOrtstermin.value) : null,
                    kontaktMitVersicherungsnehmer: DamageFlowForm.controls.KontaktMitVersicherungsnehmer.value ? FrontendDate(DamageFlowForm.controls.KontaktMitVersicherungsnehmer.value) : null,
                    objektiveSchadensumme: DamageFlowForm.controls.ObjektiveSchadensumme.value,
                    qMDurchgefuehrtAm: DamageFlowForm.controls.QMDurchgefuehrtAm.value ? FrontendDate(DamageFlowForm.controls.QMDurchgefuehrtAm.value) : null,
                    qMStatusId: DamageFlowForm.controls.QMStatusId.value?.Id,
                    reportCount: DamageFlowForm.controls.ReportCount.value ? +DamageFlowForm.controls.ReportCount.value : null,
                    schadenannahmeDurchSV: DamageFlowForm.controls.SchadenannahmeDurchSV.value ? FrontendDate(DamageFlowForm.controls.SchadenannahmeDurchSV.value) : null,
                    weiterleitungAnVu: DamageFlowForm.controls.WeiterleitungAnVu.value ? FrontendDate(DamageFlowForm.controls.WeiterleitungAnVu.value) : null,
                },
            }),
        );
    }
}
export function getCommissionFormAddressSearchSubscriptions(commissionForm: ReturnType<typeof createCommissionFormGroup>, store: Store<State>, commissionLocationForm: FormControl<Address>, cdr: ChangeDetectorRef) {
    return [
        commissionForm.get('Customer')
            .valueChanges.pipe(filter(isNotNullOrUndefined), withLatestFrom(store.select(getOffices)))
            .subscribe(([v, o]) => {
                if (!commissionLocationForm.value.Street && !commissionLocationForm.value.PostalCode && !commissionLocationForm.value.City && !commissionLocationForm.value.Country) {

                    const address = o.find((o) => o.CustomerId === v.Id);
                    commissionLocationForm.setValue({
                        Street: address?.Street || '',
                        PostalCode: address?.PostalCode || '',
                        City: address?.City || '',
                        Country: address?.Country || '',
                    });
                    commissionLocationForm.patchValue({
                        Street: address?.Street || '',
                        PostalCode: address?.PostalCode || '',
                        City: address?.City || '',
                        Country: address?.Country || '',
                    });
                    cdr.detectChanges();
                }
            }),
    ]

}
export function getCommissionFormSubscriptions(form: ReturnType<typeof createCommissionFormGroup>, store: Store<State>) {
    return [

        form.controls.Customer.valueChanges.pipe(withLatestFrom(store.select(getNotDeletedCustomers))).subscribe(([{ Id }, customers]) => {
            if (Id) {
                const customer = customers.find((c) => c.Id === Id);
                form.controls.PartnerOffice.setValue({
                    Id: customer?.PartnerOfficeId,
                    Name: '',
                });
                form.controls.PartnerOffice.disable();
            } else {
                form.controls.PartnerOffice.enable();
            }
        }),
      getAutomaticAuftragsnummerSubscription(form.controls),
      getAutomaticInterneNummerSubscription(form.controls)
    ];
}
export function getAutomaticAuftragsnummerSubscription(form: { AutomaticAuftragsnummer: FormControl<boolean>; Auftragsnummer: FormControl<string> }) {
    return form.AutomaticAuftragsnummer.valueChanges.subscribe((v) => {
      if (v && form.Auftragsnummer.enabled) {
        form.Auftragsnummer.disable();
        form.Auftragsnummer.setValue(null);
      } else if (!v && form.Auftragsnummer.disabled) {
        form.Auftragsnummer.enable();
        form.Auftragsnummer.setValue(null);
      }
    });
}
export function getAutomaticInterneNummerSubscription(form: { AutomaticInterneNummer: FormControl<boolean>; InterneNummer: FormControl<string> }) {
    return form.AutomaticInterneNummer.valueChanges.subscribe((v) => {
      if (v && form.InterneNummer.enabled) {
        form.InterneNummer.disable();
        form.InterneNummer.setValue(null);
      } else if (!v && form.InterneNummer.disabled) {
        form.InterneNummer.enable();
        form.InterneNummer.setValue(null);
      }
    });
}
export function getCommissionLocationOptions(store: Store<State>): Observable<SelectSearchOption<{ searchValues?: string[]; value: Address }>[]> {
    return combineLatest([
        store.select(getPartner),
        store.select(getCustomerDictionary),
        store.select(getCommissions),
        store.select(getCommissionTypes),
        store.select(getOffices).pipe(map(o => o.filter(v => v.Street))),
    ]).pipe(map(([partner, customerDictionary, commissions, commissionTypes, offices,]) => {
        const customerAddresses = offices?.filter((o) => o.Street)
            .map<SelectSearchOption<{ searchValues?: string[]; value: Address }>>((o) => ({
                value: o,
                optionLabel: [customerDictionary[o.CustomerId]?.Name, '-', o.Street, o.PostalCode, o.City, o.Country].join(' '),
            }));
        if (partner.PartnerTypeId === PartnerTypeEnum.TSB) {
            const commissionTypesWithChildren = commissionTypes.filter(c => c.AdditionalData?.CanHaveChildCommissions).map(c => c.Id);
            const commissionAddresses = commissions.filter(commission => commissionTypesWithChildren.includes(commission.CommissionTypeId) && commission.Street).map<SelectSearchOption<{ searchValues?: string[]; value: Address }>>((commission) => ({
                value: commission,
                optionLabel: [commission.optionLabel, '-', commission.Street, commission.PostalCode, commission.City, commission.Country].join(' '),
            }));
            return [...commissionAddresses, ...customerAddresses]
        }
        return customerAddresses;
    }) )
}
