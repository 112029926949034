export enum MessageType {
    Event = 1,
    Register = 2,
    RegisterResponse = 3,
}

export interface Message<T> {
    Type: MessageType;
    Payload: T;
}

export interface RegisterMessagePayload {
    Slug?: string;
    Token?: string;
    Id?: number;
}


export interface EventMessagePayload {
    Name: string;
    Payload: any;
}
