import { createAction, props } from '@ngrx/store';
import { ResourceTypeEntity } from '../../../entities/resource-dispo/resource-type.entity';
import { DaveMutationCreateResourceTypeArgs, QueryResourceTypeArgs } from '../../../graphql-types';

const prefix = 'data/' + 'resource-type/';
export const ResourceTypeActionTypes = {
    Load: createAction(prefix + 'load', props<{ Payload?: QueryResourceTypeArgs }>()),
    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<ResourceTypeEntity>; updateLatestUpdatedAt?: boolean }>()),
    Create: createAction(prefix + 'create', props<{ Payload: DaveMutationCreateResourceTypeArgs }>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<ResourceTypeEntity>; updateLatestUpdatedAt?: boolean }>()),
};
