import { createAction, props } from '@ngrx/store';
import { CommissionTypeEntity } from '../../entities/commissionType.entity';


export const CommissionTypeActionTypes = {
    UpdateCommissionType: createAction(
        'data/CommissionType/update',
        props<{ Payload: Array<CommissionTypeEntity> }>(),
    ),
    GetCommissionTypeRequest: createAction(
        'data/CommissionType/getAll',
    ),
};

