import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { GroupActionTypes, GroupMemberActionTypes } from '../actions/group.actions';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { BaseActionTypes } from '../actions/base.actions';
import { GroupEntity, GroupEntityFromBackend } from '../../entities/group.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { State } from '../index';
import { GroupMemberEntity, GroupMemberEntityFromBackend } from '../../entities/group-member.entity';


enum ErrorCodes {
    Add = 'Gruppe Hinzufügen fehlgeschlagen',
    AddMember = 'Gruppenmitglied Hinzufügen fehlgeschlagen',
    Load = 'Gruppe Abrufen fehlgeschlagen',
    Modify = 'Gruppe Bearbeiten fehlgeschlagen',
    Remove = 'Gruppe Löschen fehlgeschlagen',
    RemoveMember = 'Gruppenmitglied Löschen fehlgeschlagen',
}

@Injectable()
export class GroupEffects {

    ModifyGroup$ = createEffect(() => this.actions$.pipe(
        ofType(GroupActionTypes.Change),
        concatMap(({Payload}) => {
            const queryString = `
      mutation{
        changeGroup(id: ${Payload.id},
        ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'name')}
          ){${GroupEntity.GqlFields.join(',')}}
      }`;
            return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                map(res =>
                    res && res.changeGroup && res.changeGroup.id
                        ? GroupActionTypes.UpdateOne({
                              Payload: GroupEntityFromBackend(res.changeGroup),
                          })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Modify },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    AddGroup$ = createEffect(() => this.actions$.pipe(
        ofType(GroupActionTypes.Create),
        concatMap(({Payload}) => {
            const queryString = `
      mutation{
        createGroup(
        ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'name')}
          ){${GroupEntity.GqlFields.join(',')}}
      }`;

            return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                map(res => {
                    return res && res.createGroup
                        ? GroupActionTypes.UpdateOne({
                              Payload: GroupEntityFromBackend(res.createGroup),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    DeleteGroup$ = createEffect(() => this.actions$.pipe(
        ofType(GroupActionTypes.Delete),
        concatMap(({Payload}) => {
            const queryString = `
      mutation{
        deleteGroup(id: ${Payload.id}){${GroupEntity.GqlFields.join(',')}}
      }`;

            return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                map(res => {
                    return res && res.deleteGroup
                        ? GroupActionTypes.RemoveOne({
                              Payload: res.deleteGroup.id,
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    LoadGroups = createEffect(() => this.actions$.pipe(
        ofType(GroupActionTypes.Load),
        switchMap(({ Payload }) => {
            const queryString = `
query{
    group${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}", withDeleted: true)` : ''}{
    ${GroupEntity.GqlFields.join(',')}}
      }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                map(res =>
                    res && res.group
                        ? Payload?.updatedSince
                            ? GroupActionTypes.UpdateMany({
                                  Payload: res.group.map(val => GroupEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                            : GroupActionTypes.UpdateAll({
                                  Payload: res.group.map(val => GroupEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Load },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    DeleteGroupMember$ = createEffect(() => this.actions$.pipe(
        ofType(GroupMemberActionTypes.Delete),
        concatMap(({Payload}) => {
            const queryString = `
      mutation{
        deleteGroupMember(id: ${Payload.id}){${GroupMemberEntity.GqlFields.join(',')}}
      }`;

            return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                map(res => {
                    return res && res.deleteGroupMember
                        ? GroupMemberActionTypes.RemoveOne({
                            Payload: {id: res.deleteGroupMember.id, groupId: res.deleteGroupMember.groupId},
                        })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.RemoveMember } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.RemoveMember,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    AddGroupMember$ = createEffect(() => this.actions$.pipe(
        ofType(GroupMemberActionTypes.Create),
        concatMap(({Payload}) => {
            const queryString = `
      mutation{
        createGroupMember(
        ${stringifyIfNotNullOrUndefined(Payload, 'employeeId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'groupId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'personId')}
          ){${GroupMemberEntity.GqlFields.join(',')}}
      }`;

            return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                map(res => {
                    return res && res.createGroupMember
                        ? GroupMemberActionTypes.AddOne({
                            Payload: GroupMemberEntityFromBackend(res.createGroupMember),
                        })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.AddMember } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.AddMember,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
