import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDetailListTemplateData } from '../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { appMatDialogDefaultConfig } from '../../helper/helper';
import { AppButtonColor } from '../../dave-utils-module/app-button-module/app-button/app-button.component';

export interface DetailListTemplateDialogData<T = any> {
    Data: IDetailListTemplateData;
    Editing: boolean;
    AdditionalData?: T;
    DeleteButton?: boolean;
    DeleteButtonTooltip?: string;
    AlternativeButton?: string;
    AlternativeButtonColor?: AppButtonColor;
    AutofocusIndex?: number;
    DisableSaveButton$?: Observable<boolean>;
    CommissionId?: number;
    BvId?:number;
}

export interface DetailListDialogReturn<T = any> {
    DialogData: DetailListTemplateDialogData<T>;
    Action: 'save' | 'delete';
}
@Component({
    selector: 'app-detail-list-template-dialog',
    templateUrl: './detail-list-template-dialog.component.html',
    styleUrls: ['./detail-list-template-dialog.component.scss'],
})
export class DetailListTemplateDialogComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        width: '20rem',
    };
    constructor(@Inject(MAT_DIALOG_DATA) public DialogData: DetailListTemplateDialogData<any>) {}
}
