import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { ShiftPlanFilter } from '../shift-plan.component';
import { ShiftplanViews } from '../../scheduler.helper';
import {FilterTypes} from "../../../../services/default-filter.service";
import {IFilterTypeSearchMultiSelectValue} from "../../../../dave-utils-module/app-filter-module/app-filter/app-filter.component";
export interface ShiftPlanComponentDialogData {
    CommissionId?: number;
    View?: ShiftplanViews;
    DefaultFilter?: {
        [FilterTypes.CommissionId]: IFilterTypeSearchMultiSelectValue<number>[];
        [FilterTypes.JobSpecificationIds]: IFilterTypeSearchMultiSelectValue<number>[];
        [FilterTypes.ShowNotApprovedVacations]: boolean;
    };

}

@Component({
    selector: 'app-shift-plan-dialog',
    template: `
        <button mat-icon-button class="close-button" (click)="MatDialogRef.close(ViewStyle)">
            <fa-icon icon="times"></fa-icon>
        </button>
        <app-shift-plan *ngIf="Dialogdata" [ShowResourcePlanButton]="false" [AutoFilterCommissionByCurrentCommissionShifts]=false [DefaultFilter]="Dialogdata.DefaultFilter" (OnViewChange)="ViewStyle = $event" [CommissionId]="Dialogdata.CommissionId" [View]="Dialogdata.View" [HeaderColorful]="false"></app-shift-plan>
    `,
    styleUrls: ['./shift-plan-dialog.component.scss'],
})
export class ShiftPlanDialogComponent implements OnInit {
    public static DefaultConfig: MatDialogConfig = {
        height: '90vh',
        minWidth: '90vw',
        panelClass: ['custom-dialog-class-mobile-fullscreen', 'mat-dialog-with-close-button'],
        autoFocus: 'dialog',
    };
    public ViewStyle: ShiftplanViews = 'Timeline3Month';
    constructor(@Inject(MAT_DIALOG_DATA) public Dialogdata: ShiftPlanComponentDialogData, public MatDialogRef: MatDialogRef<ShiftPlanDialogComponent>) {}
    ngOnInit(): void {
        if (this.Dialogdata.View !== undefined) {
            this.ViewStyle = this.Dialogdata.View;
        }
    }
}
