import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { OfficeEntity } from '../../entities/office.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { OfficeActionTypes } from '../actions/office.actions';

export const officeFeatureKey = 'offices';

export interface OfficeState extends EntityState<OfficeEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}
const adapter = createEntityAdapter<OfficeEntity>({
    selectId: Entity => Entity.Id,
});

export const initialState: OfficeState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const officeReducer = createReducer(
    initialState,
    on(OfficeActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(OfficeActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllOffice = selectAll;
export const selectOfficeEntities = selectEntities;

export const selectOfficeFetched = (state: OfficeState) => state.fetched;
export const selectOfficeLatestUpdatedAt = (state: OfficeState) => state.latestUpdatedAt;
