import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { BaseEntityResolver } from './resolver.helper';
import { BVCustomNameActions } from "../State/actions/resource-dispo/b-v-custom-names.actions";
import { getBVCustomNameFetched } from "../State/selectors/b-v-custom-name.selector";

@Injectable({
    providedIn: 'root',
})
export class BVCustomNameResolver extends BaseEntityResolver {
    constructor(store: Store<State>) {
        super(getBVCustomNameFetched, BVCustomNameActions.load({Payload: {}}), store);
    }
}
