import {TransmissionType} from '../graphql-types';
import {BackendDate} from '../helper/backend-frontend-conversion.helper';

export class TransmissionEntity {
    constructor(
        public Id: number = null,
        public Name: string = null,
        public UserId: number = null,
        public PartnerId: number = null,
        public State: number = null,
        public CustomerId: number = null,
        public CommissionId: number = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public EventId: number = null,
    ) {
    }
    public static GQLFields = `
    commissionId
    createdAt
    customerId
    id
    name
    partnerId
    updatedAt
    userId
    state
    eventId
    `;

    public Clone(override: TransmissionEntityAttributes = {}): TransmissionEntity {
        const attr = {...this, ...override};
        return Object.assign(new TransmissionEntity(), attr);
    }
    public GetDisplayName() {
        return 'Aufnahme: ' + this.Name;
      }
}

export function TransmissionEntityFromBackend(res: TransmissionType): TransmissionEntity {
    return new TransmissionEntity(
        res.id,
        res.name,
        res.userId,
        res.partnerId,
        res.state,
        res.customerId,
        res.commissionId,
        BackendDate(res.createdAt),
        BackendDate(res.updatedAt),
        res.eventId,
    );
}

export function TransmissionEntityFromSocket(res: any): TransmissionEntity {
    return new TransmissionEntity(
        res.id,
        res.name,
        res.user_id,
        res.partner_id,
        res.state,
        res.customer_id,
        res.commission_id,
        BackendDate(res.created_at),
        BackendDate(res.updated_at),
        res.eventId,
    );
}

export interface TransmissionEntityAttributes {
    Id?: number;
    Name?: string;
    UserId?: number;
    PartnerId?: number;
    State?: number;
    CustomerId?: number;
    CommissionId?: number;
    CreatedAt?: Date;
    UpdatedAt?: Date;
    EventId?: number;
}
