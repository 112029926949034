import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { State } from '../../../dave-data-module/State';
import { AccountsReceivableLedgerTypeEnum } from "../../../dave-data-module/entities/accounts-receivable-ledger.entity";
import { FormControlCache } from "./formcontrol-cache";

export type arlTemplateTableDataArlForm = FormGroup<{
    // Amount: FormControl<number>;
    Information: FormControl<string>;
    BookingText: FormControl<string>;
    BaseCost: FormControl<number>;
    Quantity: FormControl<number>;
    QuantityTypeId: FormControl<number>;
    childrenVisible: FormControl<boolean>;
    InheritFromChildren: FormControl<boolean>;
    ParentId: FormControl<number | null>;
    ARLIds: FormControl<number[]>;
    ZuschlaegeResourcen: FormControl<number>;
    ZuschlaegeDefault: FormControl<number>;
    Type: FormControl<AccountsReceivableLedgerTypeEnum>;
    ResourceId: FormControl<number>;
    Longtext: FormControl<string>;
    ShowLongtext: FormControl<boolean>;
    TemplateTypeName: FormControl<string>;
    Tax: FormControl<number>;
    CurrencyCode: FormControl<string>;
}>;
@Injectable()
export class ArlTemplateFormDataService extends FormControlCache<arlTemplateTableDataArlForm> {
    /**
     *
     * @param id Id from arl to remove
     * @return Ids from all childArls including id
     */
    public removeArlWithChildren(id: number) {
        this.arlFormMap.forEach((form) => {
            if (form.value.ARLIds.includes(id)) {
                form.controls.ARLIds.setValue(form.value.ARLIds.filter((arlId) => arlId !== id));
                form.controls.ARLIds.markAsDirty();
            }
        });
        const childIds = this.getChildIds(id);
        childIds.forEach((arlId) => this.arlFormMap.delete(arlId));
        return childIds;
    }
    /**
     *
     * @param id Id from arl
     * @return Ids from all childArls including id
     */
    public getChildIds = (id: number): number[] => {
        if (!this.arlFormMap.has(id)) {
            return [];
        }
        if (this.arlFormMap.get(id).value.ARLIds?.length) {
            return [
                id,
                ...this.arlFormMap
                    .get(id)
                    .value.ARLIds.map((arlId) => this.getChildIds(arlId))
                    .flat(),
            ];
        } else {
            return [id];
        }
    };
}
