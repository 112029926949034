import { createAction, props } from '@ngrx/store';
import { DaveMutationChangeSepaArgs, DaveMutationCreateSepaArgs } from '../../graphql-types';
import { SepaEntity } from '../../entities/sepa.entity';


export const SepaActionTypes = {
    UpdateSepa: createAction(
        'data/sepa/update',
        props<{ Payload: Array<SepaEntity> }>(),
    ),
    ModifySepa: createAction(
        'data/Sepa/update',
        props<{ Payload: DaveMutationChangeSepaArgs }>(),
    ),
    GetSepaRequest: createAction(
        'data/Sepa/getAll',
    ),
    AddSepa: createAction(
        'data/Sepa/add',
        props<{ Payload: DaveMutationCreateSepaArgs }>(),
    ),
};

