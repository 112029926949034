import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, switchMap } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined, stringifyIfNotUndefined } from 'src/app/helper/helper';
import { MilestoneEntity, MilestoneEntityFromBackend } from '../../entities/milestone.entity';
import { MilestoneResolver } from '../../guards/milestone.resolver';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { CommissionActionTypes } from '../actions/commission.actions';
import { MilestoneActionTypes } from '../actions/milestone.actions';
import { State } from '../index';

enum ErrorCodes {
    Add = 'Meilenstein hinzufügen fehlgeschlagen',
    Load = 'Meilenstein abrufen fehlgeschlagen',
    Modify = 'Meilenstein bearbeiten fehlgeschlagen',
    Remove = 'Meilenstein löschen fehlgeschlagen',
}

@Injectable()
export class MilestoneEffects {
    ModifyMilestone$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MilestoneActionTypes.Change),
            concatMap(({ Payload }) => {
                const queryString = `
      mutation{
        changeMilestone(id: ${Payload.id},
        ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'name')}
        ${stringifyIfNotNullOrUndefined(Payload, 'description')}
        ${stringifyIfNotNullOrUndefined(Payload, 'deadline')}
        ${stringifyIfNotUndefined(Payload, 'targetHours')}
        ${stringifyIfNotUndefined(Payload, 'clockInTypeId')}
        ${Payload.state ? 'state: ' + Payload.state : ''}
          ){${MilestoneEntity.GqlFields.join(',')}}
      }`;
                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    map((res) =>
                        res && res.changeMilestone && res.changeMilestone.id
                            ? MilestoneActionTypes.UpdateOne({
                                  Payload: MilestoneEntityFromBackend(res.changeMilestone),
                              })
                            : BaseActionTypes.ErrorAction({
                                  Payload: { ToasterMessage: ErrorCodes.Modify },
                              }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Modify,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    AddMilestone$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MilestoneActionTypes.Create),
            concatMap(({ Payload }) => {
                const queryString = `
      mutation{
        createMilestone(
            ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
            ${stringifyIfNotNullOrUndefined(Payload, 'name')}
            ${stringifyIfNotNullOrUndefined(Payload, 'description')}
            ${stringifyIfNotNullOrUndefined(Payload, 'deadline')}
            ${stringifyIfNotUndefined(Payload, 'targetHours')}
            ${stringifyIfNotUndefined(Payload, 'clockInTypeId')}
            ${Payload.state ? 'state: ' + Payload.state : ''}
          ){${MilestoneEntity.GqlFields.join(',')}}
      }`;

                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    map((res) => {
                        return res && res.createMilestone
                            ? MilestoneActionTypes.UpdateOne({
                                  Payload: MilestoneEntityFromBackend(res.createMilestone),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    DeleteMilestone$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MilestoneActionTypes.Delete),
            concatMap(({ Payload }) => {
                const queryString = `
      mutation{
        deleteMilestone(id: ${Payload.id}){${MilestoneEntity.GqlFields.join(',')}}
      }`;

                return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                    map((res) => {
                        return res && res.deleteMilestone
                            ? MilestoneActionTypes.RemoveOne({
                                  Payload: res.deleteMilestone.id,
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    PollMilestones$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CommissionActionTypes.AddCommission),
                debounceTime(3000),
                tap(() => {
                    this.milestoneResolver.resolve();
                    setTimeout(() => {
                        this.milestoneResolver.resolve();
                    }, 3000);
                    setTimeout(() => {
                        this.milestoneResolver.resolve();
                    }, 17000);
                }),
            ),
        { dispatch: false },
    );

    LoadMilestones = createEffect(() =>
        this.actions$.pipe(
            ofType(MilestoneActionTypes.Load),
            switchMap(({ Payload }) => {
                const queryString = `
query{
    milestone${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}", withDeleted: true)` : ''}{
    ${MilestoneEntity.GqlFields.join(',')}}
      }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                    map((res) =>
                        res && res.milestone
                            ? Payload?.updatedSince
                                ? MilestoneActionTypes.UpdateMany({
                                      Payload: res.milestone.map((val) => MilestoneEntityFromBackend(val)),
                                      updateLatestUpdatedAt: true,
                                  })
                                : MilestoneActionTypes.UpdateAll({
                                      Payload: res.milestone.map((val) => MilestoneEntityFromBackend(val)),
                                      updateLatestUpdatedAt: true,
                                  })
                            : BaseActionTypes.ErrorAction({
                                  Payload: { ToasterMessage: ErrorCodes.Load },
                              }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService, private milestoneResolver: MilestoneResolver) {}
}
