import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getLatestUpdatedAt } from '../../../helper/reducer.helper';
import { EmployeeResourceScheduleEntity } from '../../../entities/resource-dispo/employee-resource-schedule.entity';
import { EmployeeResourceScheduleActionTypes } from '../../actions/resource-dispo/employee-resource-schedule.actions';

export const EMPLOYEE_RESOURCE_SCHEDULE_KEY = 'employeeResourceSchedule';

export interface EmployeeResourceScheduleState extends EntityState<EmployeeResourceScheduleEntity> {
    fetched: boolean;
    latestUpdatedAt: Date;
}

const adapter = createEntityAdapter<EmployeeResourceScheduleEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const employeeResourceSchedulesReducer = createReducer(
    initialState,
    on(EmployeeResourceScheduleActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(EmployeeResourceScheduleActionTypes.RemoveOne, (state, { Payload }) =>
        adapter.removeOne(Payload, state),
    ),
    on(EmployeeResourceScheduleActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, { ...state, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllEmployeeResourceSchedule = selectAll;
export const selectEmployeeResourceScheduleEntities = selectEntities;

export const selectEmployeeResourceScheduleFetched = (state: EmployeeResourceScheduleState) => state.fetched;
export const selectEmployeeResourceScheduleLatestUpdatedAt = (state: EmployeeResourceScheduleState) => state.latestUpdatedAt;
