
import { Action, createReducer, on } from '@ngrx/store';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { SurchargeRateEntity } from '../../entities/surcharge-rate.entity';
import { SurchargeRateActionTypes } from '../actions/surcharge-rate.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';



export interface SurchargeRateState extends EntityState<SurchargeRateEntity> {
    fetched: boolean;

}

const adapter = createEntityAdapter<SurchargeRateEntity>({
    selectId: (Entity) => Entity.Name,
});

export const SURCHARGE_RATE_KEY = 'surchargeRate';

const initialState = adapter.getInitialState({
    fetched: false,

});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const surchargeRateReducer = createReducer(
    initialState,
    on(SurchargeRateActionTypes.loadSuccess, (state, { Payload }) =>
        adapter.setAll(Payload, { ...state, fetched: true}),
    ),
    on(SurchargeRateActionTypes.putSuccess, (state, { Payload }) =>
        adapter.setOne(Payload, { ...state}),
    ),

);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const {selectAll, selectEntities} = adapter.getSelectors();

export const selectSurchargeRateEntities = selectEntities;
export const selectAllSurchargeRate = selectAll;
export const selectSurchargeRateFetched = (state: SurchargeRateState) => state.fetched;
