import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    CHART_OF_ACCOUNT_KEY,
    ChartOfAccountState, selectAllChartOfAccounts,
    selectChartOfAccountEntities, selectChartOfAccountLatestUpdatedAt, selectChartOfAccountsFetched,
} from '../reducers/chartOfAccount.reducer';

const selectState = createFeatureSelector<ChartOfAccountState>(CHART_OF_ACCOUNT_KEY);
export const getChartOfAccountDictionary = createSelector(selectState, selectChartOfAccountEntities);
export const getChartOfAccounts = createSelector(selectState, selectAllChartOfAccounts);
export const getChartOfAccountsFetched = createSelector(selectState, selectChartOfAccountsFetched);
export const getChartOfAccountsLatestUpdatedAt = createSelector(selectState, selectChartOfAccountLatestUpdatedAt);
export const getChartOfAccountById = (props: { id: number }) => createSelector(getChartOfAccountDictionary, (dictionary) => props.id && dictionary[props.id]);
