import { Injectable } from '@angular/core';
import { Store } from "@ngrx/store";
import { State } from "../../dave-data-module/State";
import { CustomLabelService } from "../../services/custom-label.service";
import { combineLatest, Observable } from "rxjs";
import { FilterOption } from "../../dave-utils-module/app-filter-module/app-filter/app-filter.component";
import { getUsers } from "../../dave-data-module/State/selectors/users.selectors";
import { getCommissionsActive } from "../../dave-data-module/State/selectors/commission.selector";
import { filter, map, shareReplay } from "rxjs/operators";
import { isNotNullOrUndefined } from "../../helper/helper";
import { getFilterSettings } from "../../helper/event.helper";
import { FilterTypes } from "../../services/default-filter.service";

@Injectable({
  providedIn: 'root'
})
export class TaskFilterService {

    constructor(private store: Store<State>, private cls: CustomLabelService) {}
    filterSettings$: Observable<FilterOption[]> = combineLatest([this.store.select(getUsers), this.store.select(getCommissionsActive), this.cls.getMultiple$('Commission')]).pipe(
        filter((v) => v.every(isNotNullOrUndefined)),
        map(([users, commissions, commissionLabel]) => getFilterSettings([FilterTypes.Priority, FilterTypes.EndDate, FilterTypes.CommissionId, FilterTypes.UserId, FilterTypes.AutorId], users.filter(u => !u.Deleted), users, commissions, null, null, commissionLabel)),
        shareReplay({refCount: true, bufferSize: 1}),
    );
}
