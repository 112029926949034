import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../../helper/helper';
import { AkkordToCommissionEntity, AkkordToCommissionEntityFromBackend } from '../../../entities/akkord/akkord-to-commisson.entity';
import { HttpService } from '../../../services/http.service';
import { AkkordToCommissionActionTypes } from '../../actions/akkord.action';
import { BaseActionTypes } from '../../actions/base.actions';
import { State } from '../../index';

enum ErrorCodes {
    Load = 'AkkordToCommission Abrufen fehlgeschlagen',
    Modify = 'AkkordToCommission Bearbeiten fehlgeschlagen',
    Remove = 'AkkordToCommission Löschen fehlgeschlagen',
    Add = 'AkkordToCommission Hinzufügen fehlgeschlagen',
}

@Injectable()
export class AkkordToCommissionEffects {

    CreateAkkordToCommission$ = createEffect(() => this.actions$.pipe(
        ofType(AkkordToCommissionActionTypes.Create),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
                mutation {
                    createAkkord2Commission(
                         ${stringifyIfNotNullOrUndefined(Payload, 'quantityType')}
                         ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
                         ${stringifyIfNotNullOrUndefined(Payload, 'akkordId')}
                         ${stringifyIfNotNullOrUndefined(Payload, 'value')}
                         ${stringifyIfNotNullOrUndefined(Payload, 'multiplicator')}
                         ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                         ${stringifyIfNotNullOrUndefined(Payload, 'name')}
                         ${stringifyIfNotNullOrUndefined(Payload, 'date')}
                ) {
                    ${AkkordToCommissionEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.createAkkord2Commission
                        ? AkkordToCommissionActionTypes.UpdateOne({
                              Payload: AkkordToCommissionEntityFromBackend(res.createAkkord2Commission),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    ModifyAkkordToCommission$ = createEffect(() => this.actions$.pipe(
        ofType(AkkordToCommissionActionTypes.Change),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
            mutation {
                changeAkkord2Commission(
                    id: ${Payload.id}
                     ${stringifyIfNotNullOrUndefined(Payload, 'quantityType')}
                     ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
                     ${stringifyIfNotNullOrUndefined(Payload, 'akkordId')}
                     ${stringifyIfNotNullOrUndefined(Payload, 'value')}
                     ${stringifyIfNotNullOrUndefined(Payload, 'multiplicator')}
                     ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                     ${stringifyIfNotNullOrUndefined(Payload, 'date')}
                ) {
                    ${AkkordToCommissionEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res => {
                    const ret: Action[] = [];
                    if (res && res.changeAkkord2Commission) {
                        ret.push(
                            AkkordToCommissionActionTypes.UpdateOne({
                                Payload: AkkordToCommissionEntityFromBackend(res.changeAkkord2Commission),
                            }),
                        );
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    GetAkkordToCommission$ = createEffect(() => this.actions$.pipe(
        ofType(AkkordToCommissionActionTypes.LoadAll),
        concatMap(() => {
            const queryString = `
            query {
                akkord2Commission {
                    ${AkkordToCommissionEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                map(res => {
                    return res && res.akkord2Commission
                        ? AkkordToCommissionActionTypes.UpdateAll({
                              Payload: res.akkord2Commission.map(e => AkkordToCommissionEntityFromBackend(e)),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    RemoveAkkordToCommission$ = createEffect(() => this.actions$.pipe(
        ofType(AkkordToCommissionActionTypes.Delete),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
                mutation{
                  deleteAkkord2Commission(
                  id: ${action.Payload}
                  )
                }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.deleteAkkord2Commission
                        ? AkkordToCommissionActionTypes.LoadAll()
                        : // todo be soll entity zurück geben
                          // AkkordToCommissionActionTypes.UpdateOne({
                          //       Payload: action.Payload,
                          //   })
                          BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
