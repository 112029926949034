import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomFormFieldsModalComponent } from './components/custom-form-fields-modal/custom-form-fields-modal.component';



@NgModule({
    declarations: [CustomFormFieldsModalComponent],
    exports: [CustomFormFieldsModalComponent],
    imports: [CommonModule, MatInputModule, MatDatepickerModule, ReactiveFormsModule, MatCheckboxModule, MatSelectModule, MatExpansionModule, FontAwesomeModule],
})
export class CustomFormFieldsModule {}
