import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';
import {
    NEWS_KEY,
    NewsState,
    selectNewsEntities,
    selectNewsFetched,
    selectAllNews,
} from '../reducers/news.reducer';


const selectState = createFeatureSelector< NewsState>(NEWS_KEY);
const selectEntities = createSelector(selectState, selectNewsEntities);

export const getNews = createSelector(selectState, selectAllNews);
export const getNewsFetched = createSelector(selectState, selectNewsFetched);
export const getNewsById = createSelector(
    selectEntities,
    (news: ReturnType<typeof selectEntities>, Id: number) => Id && news[Id],
);
