import { createReducer, on } from '@ngrx/store';
import { FileEntity } from '../../entities/file.entity';
import { FolderEntity } from '../../entities/folder.entity';
import { TagEntity } from '../../entities/tag.entity';
import { FilesActionTypes } from '../actions/files.actions';
import { FolderActionTypes } from '../actions/folder.actions';
import { TagActionTypes } from '../actions/tag.actions';
import { Scan2DaveEmailEntity } from '../../entities/scan2daveEmail.entity';
import { Scan2DaveEmailActionTypes } from '../actions/scan2DaveEmail.actions';

export const scan2DaveFeatureKey = 'scan2Dave';

export interface Scan2DaveState {
    scan2DaveEmail: Scan2DaveEmailEntity[];
}

export const initialState: Scan2DaveState = {
    scan2DaveEmail: null,
};

export const scan2DaveReducer = createReducer(
    initialState,
    on(Scan2DaveEmailActionTypes.RemoveScan2DaveEmail, (state, { Payload }) => ({
        ...state,
        scan2DaveEmail: state.scan2DaveEmail ? [...state.scan2DaveEmail.filter(s2de => s2de.Id !== Payload)] : state.scan2DaveEmail,
    })),
    on(Scan2DaveEmailActionTypes.UpdateScan2DaveEmails, (state, { Payload }) => {
        const newIds = Payload.map(s2de => s2de.Id);
        const oldScan2DaveEmails = state.scan2DaveEmail ? state.scan2DaveEmail.filter(s2de => newIds.indexOf(s2de.Id) < 0) : [];
        return {
            ...state,
            scan2DaveEmail: [...oldScan2DaveEmails, ...Payload],
        };
    }),
);
