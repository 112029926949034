import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../State';
import { AkkordActionTypes } from '../../State/actions/akkord.action';
import { getAkkordFetched } from '../../State/selectors/akkord/akkord.selector';
import { BaseEntityResolver } from '../resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class AkkordResolver extends BaseEntityResolver {
    constructor(store: Store<State>) {
        super(getAkkordFetched, AkkordActionTypes.LoadAll(), store);
    }
}
