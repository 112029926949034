<app-dave-list-card
    [IsExpandable]="true"
    [(Expanded)]="Expanded"
    [HeaderIcon]="ChangesMetaIcon"
    [Data]="pagedChanges$ | async"
    [DisplayedCollums]="['custom']"
    [Clickable]="false"
    [CustomCellTemplates]="{ custom: customColumn }"
    [CustomHeaderTemplate]="header"
    [IsLoading]="!(Changes$ | async)"
>
    <!--    <div listCardFooter>-->
    <mat-paginator
        #paginator
        listCardFooter
        *ngIf="(Changes$ | async)?.length > initialPageSize"
        [length]="(Changes$ | async)?.length"
        [pageSize]="initialPageSize"
        [pageSizeOptions]="[initialPageSize, 100, 250]"
        (page)="page$.next($event)"
        aria-label="Seite auswählen">
    </mat-paginator>
    <!--    </div>-->
</app-dave-list-card>
<ng-template #customColumn let-data="data">
    <app-change-list-entry [ChangeEntity]="data"></app-change-list-entry>
</ng-template>

<ng-template #header>
    <h3 class="headline" [matBadgeOverlap]="false" [matBadge]="unseenCount$ | async">Projektänderungen</h3>
</ng-template>
