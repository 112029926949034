import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {combineLatest, Subject, Subscription} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { EventTypeEntity } from '../../../dave-data-module/entities/event-type.entity';
import { Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import { getCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { getErrorMessage } from '../../../helper/validation.helper';
import { appMatDialogDefaultConfig, isNotNullOrUndefined } from '../../../helper/helper';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getEventTypes } from '../../../dave-data-module/State/selectors/event-type.selector';

export interface NewCallComponentDialogData {
    CustomerId: number;
    CommissionId: number;
}

@Component({
    selector: 'app-new-call',
    templateUrl: 'new-call.component.html',
    styleUrls: ['new-call.component.scss'],
})
export class NewCallComponent implements OnInit, OnDestroy {
    public AddPersonClicked = new Subject();
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        hasBackdrop: false,
    };
    public PersonList: PersonEntity[] = [];
    public GetErrorMessage = getErrorMessage;
    public UploadOpen = false;
    public NewEventForm = new UntypedFormGroup(
        {
            Person: new UntypedFormControl(''),
            Time: new UntypedFormControl(15),
            Note: new UntypedFormControl('', {
                updateOn: 'blur',
            }),
            Name: new UntypedFormControl('Telefonat', {
                updateOn: 'blur',
            })
        },
    ) as FormGroupTyped<{
        Person: PersonEntity;
        Time: number;
        Note: string;
        Name: string;
    }>;
    public Customer: CustomerEntity = null;
    protected EventType: EventTypeEntity = null;
    private subscriptions: Subscription[] = [];

    constructor(
        private store: Store<State>,
        private router: Router,
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: NewCallComponentDialogData,
        public CS: CustomerNameService,
    ) {
        if (this.Dialogdata.CustomerId) {
            this.NewEventForm.controls.Person.setValue(null);
        }
    }

    ngOnInit() {
        this.subscriptions.push(
            combineLatest([
                this.store.select(getPersons).pipe(
                    filter(isNotNullOrUndefined),
                    map(p => p.filter(pe => !pe.Deleted)),
                ),
                this.store.select(getPerson2Entities).pipe(
                    filter(isNotNullOrUndefined),
                    map(pes => pes.filter(pe => pe.EntityType === Person2EntityEntityTypeEnum.Customer || pe.EntityType === Person2EntityEntityTypeEnum.Commission)),
                ),
            ]).pipe(
                map(([persons, person2Entities]) =>
                    this.Dialogdata.CustomerId || this.Dialogdata.CommissionId
                        ? persons.filter(p =>
                            person2Entities.some(
                                pe =>
                                    pe.PersonId === p.Id &&
                                    ((pe.EntityType === Person2EntityEntityTypeEnum.Customer && pe.EntityId === this.Dialogdata.CustomerId) ||
                                        (pe.EntityType === Person2EntityEntityTypeEnum.Commission && pe.EntityId === this.Dialogdata.CommissionId)),
                            ),
                        )
                        : persons,
                ),
            ).subscribe(c => this.PersonList = c),
            this.store.select(getCustomers).subscribe(customers => {
                this.Customer = customers.find(c => c.Id === this.Dialogdata.CustomerId);
            }),
            this.store.select(getEventTypes).subscribe(types => {
                this.EventType = types.find(t => t.Name.toLowerCase().includes('telefonat'));
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public Submit() {
        if (this.NewEventForm.invalid) {
            return;
        }
        const {
            Person,
            Time,
            Note,
            Name,
        } = this.NewEventForm.value;

        const eventEndDate = new Date();
        let eventDate: Date = new Date();
        eventDate.setMinutes(eventDate.getMinutes() - Time);

        // const sub = this.store.select(getTransmission).pipe(
        //     skip(1),
        //     take(1),
        //     tap(transmissions => {
        //         let id = 0;
        //         let t: TransmissionEntity = null;
        //
        //         transmissions.forEach(tr => {
        //             if (tr.Id > id) {
        //                 id = tr.Id;
        //                 t = tr;
        //             }
        //         });
        //
        //         sub.unsubscribe();
        //
        //         this.router.navigate(['/videodokumentation', t.CustomerId || 'alle', t.Id]);
        //     })
        // ).subscribe();

        this.store.dispatch(
            EventsActionTypes.AddEvent({
                Payload: {
                    personId: Person?.Id,
                    customerId: this.Dialogdata.CustomerId,
                    hint: null,
                    street: null,
                    city: null,
                    postalCode: null,
                    commissionId: this.Dialogdata.CommissionId,
                    eventTypeId: this.EventType.Id,
                    eventDate: FrontendDate(eventDate),
                    eventEndDate: FrontendDate(eventEndDate),
                    description: Note || '',
                    private: false,
                    name: Name || this.EventType.Name,
                },
            }),
        );
    }

    public NewContactPerson() {
        // const customerId: number = +this.Customer.Id;
        // this.router.navigate(['/kunden', customerId, 'neuerAnsprechpartner']);
        this.AddPersonClicked.next(null);
    }

    public ContactPersonText(option: PersonEntity): string {
        return isNotNullOrUndefined(option.DisplayName) ?  option.DisplayName + (isNotNullOrUndefined(option.Email ) ? " " + option.Email  : "") + (isNotNullOrUndefined(option.Description) ? " " + option.Description : "") : "unbennanter " + this.Customer ? this.Customer.AnsprechpartnerText() : 'Ansprechpartner / Sachbearbeiter';
    }
}
