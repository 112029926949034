import { ResourceTypeType } from '../../graphql-types';
import { BackendDate } from '../../helper/backend-frontend-conversion.helper';
import { CustomFields } from '../../../custom-form-fields/custom-form-fields.helper';

export class ResourceTypeEntity {
    constructor(
        public Id: number = null,
        public Name: string = null,
        public CustomFields: CustomFields[] = null,
        public UpdatedAt: Date = null,
    ) {}
    public static readonly GqlFields: Array<keyof ResourceTypeType> = [
        'id',
        'name',
        'customFields',
        'updatedAt',
    ]
    public Clone(override: Partial<ResourceTypeEntity> = {}): ResourceTypeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ResourceTypeEntity(), attr);
    }
}

export function ResourceTypeEntityFromBackend(res: ResourceTypeType): ResourceTypeEntity {
    return new ResourceTypeEntity(
        res.id,
        res.name,
        JSON.parse(res.customFields),
        res.customFields && BackendDate(res.updatedAt),
    );
}

