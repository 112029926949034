import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SimplebarAngularModule } from 'simplebar-angular';
import { DaveDmsModule } from '../dave-dms-module/dave-dms.module';
import { PreviewModule } from '../dave-dms-module/dave-dms/preview/preview.module';
import { DaveFileExplorerModule } from '../dave-file-explorer/dave-file-explorer.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { SmallFileCardComponent } from '../small-file-card/small-file-card.component';
import { SmallFileCardModule } from '../small-file-card/small-file-card.module';
import { DaveSelectFileFromDmsComponent } from './components/dave-select-file-from-dms/dave-select-file-from-dms.component';
import {MatTabsModule} from "@angular/material/tabs";

@NgModule({
    declarations: [DaveSelectFileFromDmsComponent],
    imports: [
        CommonModule,
        MatCardModule,
        DaveFileExplorerModule,
        SimplebarAngularModule,
        DaveDmsModule,
        FontAwesomeModule,
        AppButtonModule,
        PreviewModule,
        MatDialogModule,
        SmallFileCardModule,
        MatTabsModule,

    ],
    exports: [DaveSelectFileFromDmsComponent],
})
export class DaveSelectFileFromDmsModule {}
