import { Color } from '@dave/types';
import { isNotNullOrUndefined } from '../../helper/helper';
import { Color as NgxMatColor } from '@angular-material-components/color-picker';
import { ColorType } from '../graphql-types';


export const getHexFromColor = (color: Color) => {
    if (!color) {
        return null;
    }

    const alpha = color.Opacity;
    let hex =
            (color.Red | 1 << 8).toString(16).slice(1) +
            (color.Green | 1 << 8).toString(16).slice(1) +
            (color.Blue | 1 << 8).toString(16).slice(1);

    if (isNotNullOrUndefined(alpha)) {
        // multiply before convert to HEX
        const a = ((alpha * 255) | 1 << 8).toString(16).slice(1)
        hex = hex + a;
    }

    return '#' + hex;
}

export class ColorEntity implements Color {
    constructor(public Red: number, public Green: number, public Blue: number, public Opacity: number = null) {}
    public getHexString = () => getHexFromColor(this);
    // http://www.w3.org/TR/AERT#color-contrast
    public getBrightness = () => Math.round(((this.Red * 299) +
            (this.Green * 587) +
            (this.Blue * 114)) / 1000);
    public getTextColor = () =>  this.getBrightness() > 125 ? '#000000' : '#FFFFFF';
    public getNgxMaterialColorPickerObject = () => new NgxMatColor(this.Red, this.Green, this.Blue, this.Opacity);
}

export const ColorEntityFromNgxMatColor = (color: NgxMatColor): ColorEntity => new ColorEntity(color.r, color.g, color.b, color.a);
export const ColorEntityFromColorType = (res: Color): ColorEntity => new ColorEntity(res.Red, res.Green, res.Blue, res.Opacity);
export const ColorEntityFromBackend = (res: ColorType): ColorEntity => new ColorEntity(res.red, res.green, res.blue, res.opacity);
export function ColorEntityFromString(res: string): ColorEntity {
    let r: number | null = null;
    let g: number | null = null;
    let b: number | null = null;
    let o: number | null = null;
    if (res[0] === '#') {
        switch (res.length) {
            case 4:
            case 5:
                r = parseInt(res[1], 16);
                g = parseInt(res[2], 16);
                b = parseInt(res[3], 16);
                o = res[4] ? parseInt(res[4], 16) : null;
                break;
            case 7:
            case 9:
                r = parseInt(res.substring(1, 3), 16);
                g = parseInt(res.substring(3, 5), 16);
                b = parseInt(res.substring(5, 7), 16);
                o = res[8] ? parseInt(res.substring(7, 9), 16) : null;
                break;
            default:
                throw new Error(`Invalid color string: ${res}`);
        }
    } else {
        console.log('res', res, res[0]);
        console.log({ res });
        throw new Error(`Invalid color string: ${res}`);
    }
    return new ColorEntity(r, g, b, o);
}
