import { createAction, props } from '@ngrx/store';
import { Person2EntityEntity } from '../../entities/person2entity.entity';
import {
    DaveMutationChangePerson2EntityArgs,
    DaveMutationCreatePerson2EntityArgs,
    DaveMutationDeletePerson2EntityArgs,
    QueryPerson2EntityArgs,
} from '../../graphql-types';

export const Person2EntityActionTypes = {
    AddPerson2Entity: createAction('data/Person2Entity/Add', props<{ Payload: DaveMutationCreatePerson2EntityArgs }>()),
    ModifyPerson2Entity: createAction('data/Person2Entity/Modify', props<{ Payload: DaveMutationChangePerson2EntityArgs }>()),
    Load: createAction('data/Person2Entity/Get', props<{ Payload?: QueryPerson2EntityArgs }>()),
    DeletePerson2Entity: createAction('data/Person2Entity/Delete', props<{ Payload: DaveMutationDeletePerson2EntityArgs }>()),
    UpdateAll: createAction('data/Person2Entity/UpdateAll', props<{ Payload: Person2EntityEntity[], updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/Person2Entity/UpdateMany', props<{ Payload: Person2EntityEntity[], updateLatestUpdatedAt?: boolean }>()),
    RemoveOne: createAction('data/Person2Entity/RemoveOne', props<{ Payload: number }>()),
};
