import { formatCurrency } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Moment } from 'moment/moment';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, of, Subject, Subscription, switchMap, tap } from 'rxjs';
import { filter, first, map, shareReplay, startWith, withLatestFrom } from 'rxjs/operators';
import { ResourceTypeResolver } from 'src/app/dave-data-module/guards/resource-dispo/resource-type.resolver';
import { PublicFileTypeEnum } from '../../../dave-data-module/entities/public-file.entity';
import {
    AmountTypeEnum,
    AmountTypeEnumNames,
    AmountTypeEnumNamesLong,
} from '../../../dave-data-module/entities/resource-dispo/resource.entity';
import { DaveMutationCreateResourceArgs } from '../../../dave-data-module/graphql-types';
import { PublicFileResolver } from '../../../dave-data-module/guards/public-file.resolver';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { DaveActions } from '../../../dave-data-module/State/actions/actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { ResourceTypeActionTypes } from '../../../dave-data-module/State/actions/resource-dispo/resource-type.actions';
import { ResourceActionTypes } from '../../../dave-data-module/State/actions/resource-dispo/resource.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getPublicFilesActive, getPublicFilesFetched } from '../../../dave-data-module/State/selectors/public-file.selectors';
import { getResourceTypeDictionary, getResourceTypes, getResourceTypesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource-type.selectors';
import { getResourceById, getResourcesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import {
    IDetailListTemplateData,
    IDetailListTemplateDataProperty,
} from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import {
    DetailListDialogReturn,
    DetailListTemplateDialogComponent,
    DetailListTemplateDialogData,
} from '../../../detail-list-template-dialog/components/detail-list-template-dialog.component';
import { isNotNullOrUndefined, MathRound } from '../../../helper/helper';
import { CommissionMeta, DMSPageMeta, ResourcePageMeta } from '../../../helper/page-metadata';
import {
    PublicFileActionTypes,
    PublicFileUploadParams,
} from '../../../dave-data-module/State/actions/public-file.actions';
import { PublicVersionEntity } from '../../../dave-data-module/entities/public-version.entity';
import { DomSanitizer } from '@angular/platform-browser';
import {decimalPriceValidator} from "../../../helper/validation.helper";
import {
    CustomFields,
    CustomFieldTypesDisplayValueMap,
    CustomFieldTypesNamesMap,
    CustomFieldTypes,
} from '../../../custom-form-fields/custom-form-fields.helper';
import {
    CustomPropertyType
} from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import {ResourceTypeEntity} from "../../../dave-data-module/entities/resource-dispo/resource-type.entity";
import {
    getQuantityTypeDictionary,
    getQuantityTypes, getQuantityTypesFetched
} from "../../../dave-data-module/State/selectors/accounting.selector";
import { QuantityTypeResolver } from "../../../dave-data-module/guards/quantity-type.resolver";
import { getFetched$ } from "../../../dave-data-module/helper/helper";
import {
    SelectSearchOption
} from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { ResourceEditRequest } from '@dave/types';
import { ResourceAddRequest } from '@dave/types/dist/proto/erp/resource';

@Component({
    selector: 'app-resource-main-data',
    templateUrl: './resource-main-data.component.html',
    styleUrls: ['./resource-main-data.component.scss'],
})
export class ResourceMainDataComponent implements OnDestroy, AfterViewInit {
    @ViewChild('selectResourceType') selectResourceType: TemplateRef<any>;
    @ViewChild('selectResourceCategory') selectResourceCategory: TemplateRef<any>;
    @Input() Editing = false;
    @Input() ShowResourceButton = true;
    @Input() FolderId: number;
    @Output() EditingChange = new EventEmitter<boolean>();
    @Input() set ResourceId(v: number) {
        this.resourceId$.next(v);
    }

    /**
     * for new resources
     */
    public FileCache$: BehaviorSubject<Blob | null> = new BehaviorSubject<Blob | null>(null);
    public DMSMeta = DMSPageMeta;
    public CommissionMeta = CommissionMeta;
    public ResourcePageMeta = ResourcePageMeta;
    public ResourceForm = new FormGroup({
        Name: new FormControl<string>(null, Validators.required),
        ArticleNumber: new FormControl<string>(null),
        Manufacturer: new FormControl<string>(null),
        Kostenstelle: new FormControl<string>(null),
        Amount: new FormControl<number>(null),
        Cost: new FormControl<number>(null, [Validators.min(0), decimalPriceValidator()]),
        MinChargeRate: new FormControl<number>(null, [Validators.min(0), decimalPriceValidator()]),
        MaxChargeRate: new FormControl<number>(null, [Validators.min(0), decimalPriceValidator()]),
        ResourceTypeId: new FormControl<ResourceTypeEntity & { optionLabel: string}>(null, Validators.required),
        AmountType: new FormControl<{value: AmountTypeEnum, optionLabel: string}>(null),
        QuantityType: new FormControl<{Id: number}>(null),
        InventoryNumber: new FormControl<string>(null),
        AutomaticInventoryNumber: new FormControl<boolean>(null),
        StorageLocation: new FormControl<string>(null),
        GlobalTradeItemNr: new FormControl<string>(null),
    });
    public CustomFieldTypes = CustomFieldTypes;
    public CustomFields: Array<
        CustomFields & {
        formControl: FormControl<any>;
    }
    > = [];

    private resourceId$ = new BehaviorSubject<number | null>(null);

    public Resource$ = this.resourceId$.pipe(
        switchMap((id) =>
            id
                ? this.store.select(getResourcesFetched).pipe(
                    filter((v) => !!v),
                    switchMap(() => this.store.select(getResourceById({ id }))),
                )
                : of(null),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    FileIsLoaded = false;
    Loading: boolean = false;
    public File$ = this.resourceId$.pipe(
        switchMap((resourceId) =>
            this.store.select(getPublicFilesFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getPublicFilesActive)),
                map((files) => files.find((f) => f.EntityId === resourceId && f.Type === PublicFileTypeEnum.ResourceEntityProfileImage)?.LastVersion),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public ImageSource$ = combineLatest([this.File$, this.FileCache$, this.store.select(getToken)]).pipe(map(([publicFile, cachedFile, token]) => {
        if (publicFile?.MimeType.indexOf('image/') > -1) {
            return this.Api.GetUrl(publicFile.DownloadPath + '?token=' + token, 'file')
        } else if (cachedFile) {
            return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(cachedFile));
        }
    }));
    public ResourceTypes: Array<ResourceTypeEntity & { optionLabel: string}>;
    public DetailList$: Observable<IDetailListTemplateData>;
    private resetFormSubject$ = new Subject<void>();
    public CustomFieldsValid$$ = new BehaviorSubject<Observable<boolean>>(of(false));
    public FormsValid$ = combineLatest([this.CustomFieldsValid$$.pipe(switchMap((v) => v)), this.ResourceForm.statusChanges.pipe(startWith(this.ResourceForm.status))]).pipe(
        map(([customFieldsValid, resFormStatus]) => {
            return customFieldsValid && resFormStatus === 'VALID';
        }),
    );
    public CompareById = (a: SelectSearchOption<{ Id: number }>, b: SelectSearchOption<{ Id: number }>) => a.Id === b.Id;
    private subs: Subscription[] = [
        this.store.select(getResourceTypes).subscribe(rt => this.ResourceTypes = rt.map( r => Object.assign( r.Clone(), { optionLabel: r.Name}))),
        this.ResourceForm.controls.AutomaticInventoryNumber.valueChanges.pipe(withLatestFrom(this.Resource$)).subscribe(([v, resource]) => {
            if (v && this.ResourceForm.controls.InventoryNumber.enabled) {
                this.ResourceForm.controls.InventoryNumber.disable();
                this.ResourceForm.controls.InventoryNumber.setValue(null);
            } else if (!v && this.ResourceForm.controls.InventoryNumber.disabled) {
                this.ResourceForm.controls.InventoryNumber.enable();
                this.ResourceForm.controls.InventoryNumber.setValue(resource?.InventoryNumber || '');
            }
        }),
        merge(this.Resource$, this.resetFormSubject$)
            .pipe(withLatestFrom(this.Resource$))
            .subscribe(([, resource]) => {
                if (resource) {
                    const { InventoryNumber, Name, ArticleNumber, Manufacturer, Kostenstelle, Amount, Cost, MinChargeRate, MaxChargeRate, ResourceTypeId, CustomFields, AmountType, QuantityTypeId, StorageLocation, GlobalTradeItemNr } =
                        resource;

                    this.setCustomFields(CustomFields || []);
                    this.ResourceForm.reset(
                        {
                            Name,
                            ArticleNumber,
                            Manufacturer,
                            Kostenstelle,
                            Amount,
                            Cost: Cost ? Cost / 100 : null,
                            MinChargeRate: MinChargeRate ? MinChargeRate / 100 : null,
                            MaxChargeRate: MaxChargeRate ? MaxChargeRate / 100 : null,
                            ResourceTypeId: this.ResourceTypes.find( rt => rt.Id === ResourceTypeId),
                            AmountType: {value: AmountType, optionLabel: ''},
                            InventoryNumber,
                            AutomaticInventoryNumber: false,
                            QuantityType: QuantityTypeId ? {Id: QuantityTypeId} : null,
                            StorageLocation,
                            GlobalTradeItemNr,
                        },
                        { emitEvent: true },
                    );
                } else {
                    this.setCustomFields([]);
                    this.ResourceForm.reset();
                }
            }),
        // this.File$.subscribe(),
    ];
    constructor(
        private actions$: Actions<DaveActions>,
        private dialog: MatDialog,
        private store: Store<State>,
        private fileDataService: FileDataService,
        public Api: HttpService,
        private cdr: ChangeDetectorRef,
        private resourceTypeResolver: ResourceTypeResolver,
        private sanitizer: DomSanitizer,
        publicFileResolver: PublicFileResolver,
        quantityTypeResolver: QuantityTypeResolver,
    ) {
        firstValueFrom(this.store.select(getQuantityTypesFetched)).then(fetched => {
            if (!fetched) {
                quantityTypeResolver.resolve();
            }
        })
        firstValueFrom(this.store.select(getPublicFilesFetched)).then(fetched => {
            if (!fetched) {
                publicFileResolver.resolve();
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
    public NewResourceTypePopUp() {
        const cName = new FormControl<string>('', Validators.required);
        //const cCanBeConsumed = new FormControl<boolean>(false);
        this.dialog
            .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                ...DetailListTemplateDialogComponent.DefaultConfig,
                data: {
                    DisableSaveButton$: cName.statusChanges.pipe(
                        startWith(cName.status),
                        map((state) => state !== 'VALID'),
                    ),
                    Editing: true,
                    Data: {
                        Headline: 'Art der Ressource anlegen',
                        Properties: [
                            {
                                key: 'Bezeichnung',
                                formControl: cName,
                            },
                        ],
                    },
                },
            })
            .afterClosed()
            .subscribe((ret) => {
                if (ret?.Action === 'save') {
                    this.store.dispatch(ResourceTypeActionTypes.Create({ Payload: { name: cName.value } }));
                    this.actions$
                        .pipe(ofType(BaseActionTypes.ErrorAction, ResourceTypeActionTypes.UpdateMany), first(), ofType(ResourceTypeActionTypes.UpdateMany))
                        .subscribe((res) => {
                            if (res.Payload.length === 1) {
                                this.ResourceForm.controls.ResourceTypeId.setValue(Object.assign(res.Payload[0].Clone(), { optionLabel: res.Payload[0].Name}));
                            }
                        });
                }
            });
    }
    private uploadImage(file: Blob, resourceId: number, oldFile?: PublicVersionEntity) {
        const params: PublicFileUploadParams = new Map([
            ['entity_id', resourceId + ''],
            ['type', PublicFileTypeEnum.ResourceEntityProfileImage],
        ]);
        if (oldFile) {
            params.set('file_id', oldFile.FileId + '');
        }
        this.store.dispatch(
            PublicFileActionTypes.Upload({
                Payload: {
                    file,
                    params,
                },
            }),
        );
    }
    public OnFileChange(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);
            reader.onload = () => {
                fetch(reader.result as string)
                    .then((res) => res.blob())
                    .then((file) => {
                        firstValueFrom(combineLatest([this.Resource$, this.File$])).then(([resource, oldFile]) => {
                            if (resource) {
                                this.uploadImage(file, resource.Id, oldFile)
                            } else {
                                this.FileCache$.next(file);
                            }
                        });
                    });
            };
        }
    }
    public ResetForm() {
        this.resetFormSubject$.next();
    }
    public SaveForm() {
        if (this.ResourceForm.invalid) {
            return;
        }

        const { AutomaticInventoryNumber, InventoryNumber, Name, ArticleNumber, Manufacturer, Kostenstelle, Amount, Cost, MinChargeRate, MaxChargeRate, ResourceTypeId, AmountType, QuantityType, StorageLocation,GlobalTradeItemNr } = this.ResourceForm.value;
        const payload: ResourceAddRequest = {
            ResourceTypeId: ResourceTypeId ? String(ResourceTypeId.Id) : null,
            Amount: Amount ? String(Amount) : null,
            Cost: Cost ? String(MathRound(Cost * 100, true)) : null,
            MinChargeRate: MinChargeRate ? String(MathRound(MinChargeRate * 100, true)) : null,
            MaxChargeRate: MaxChargeRate ? String(MathRound(MaxChargeRate * 100, true)) : null,
            Name: Name,
            Kostenstelle: Kostenstelle,
            Manufacturer: Manufacturer,
            ArticleNumber: ArticleNumber,
            AmountType: AmountType?.value,
            InventoryNumber: InventoryNumber,
            CustomFields: JSON.stringify(this.CustomFields.map(({ name, type, formControl }) => ({ name, type, value: formControl.value }))),
            AutomaticInventoryNumber: AutomaticInventoryNumber,
            QuantityTypeId: QuantityType ? String(QuantityType.Id) : null,
            StorageLocation: StorageLocation,
            GlobalTradeItemNr: GlobalTradeItemNr,
        };

        if (this.resourceId$.value) {
            this.store.dispatch(
                ResourceActionTypes.Change({
                    Payload: {
                        ...payload,
                        Id: String(this.resourceId$.value),
                    },
                }),
            );
        } else {
            if (this.FileCache$.value) {
                this.actions$.pipe(ofType(BaseActionTypes.ErrorAction, ResourceActionTypes.UpdateOne), first(), ofType(ResourceActionTypes.UpdateOne)).subscribe(resource => {
                    this.uploadImage(this.FileCache$.value, resource.Payload.Id);
                })
            }

            this.store.dispatch(
                ResourceActionTypes.Create({
                    Payload: {
                        ...payload,
                    },
                }),
            );
        }
        this.EditingChange.emit(false);
    }
    private setCustomFields(fields: CustomFields[]) {
        this.CustomFields = fields.map((f) => {
            switch (f.type) {
                case CustomFieldTypes.text:
                    return {
                        ...f,
                        formControl: new FormControl<string>(f.value),
                    };
                case CustomFieldTypes.number:
                    return { ...f, formControl: new FormControl<number>(f.value) };
                case CustomFieldTypes.date:
                    return {
                        ...f,
                        formControl: new FormControl<Moment>(f.value ? moment(f.value) : null),
                    };
            }
        });
        this.CustomFieldsValid$$.next(
            this.CustomFields.length
                ? combineLatest(this.CustomFields.map((c) => c.formControl.statusChanges.pipe(startWith(c.formControl.status)))).pipe(map((v) => v.every((s) => s === 'VALID')))
                : of(true),
        );
    }
    public OpenCustomFieldPopup(index?: number) {
        const form = new FormGroup({
            name: new FormControl<string>(isNotNullOrUndefined(index) ? this.CustomFields[index].name : '', Validators.required),
            type: new FormControl<CustomFieldTypes>(isNotNullOrUndefined(index) ? this.CustomFields[index].type : CustomFieldTypes.text, Validators.required),
        });
        this.dialog
            .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                ...DetailListTemplateDialogComponent.DefaultConfig,
                data: {
                    Editing: true,
                    DeleteButton: index != null,
                    DisableSaveButton$: form.statusChanges.pipe(
                        startWith(form.status),
                        map((state) => state !== 'VALID'),
                    ),
                    Data: {
                        Properties: [
                            {
                                key: 'Name',
                                formControl: form.controls.name,
                            },
                            {
                                key: 'Art',
                                formControl: form.controls.type,
                                options: {
                                    specialInput: {
                                        select: Object.values(CustomFieldTypes).map((type) => ({
                                            optionValue: type,
                                            optionLabel: CustomFieldTypesNamesMap.get(type),
                                        })),
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .afterClosed()
            .subscribe((ret) => {
                if (ret?.Action === 'save') {
                    if (index != null) {
                        if (this.CustomFields[index].type !== form.value.type) {
                            this.CustomFields[index].value = null;
                        }
                        this.CustomFields[index].type = form.value.type;
                        this.CustomFields[index].name = form.value.name;
                    } else {
                        this.CustomFields.push({
                            type: form.value.type,
                            name: form.value.name,
                            value: null,
                            formControl: new FormControl<any>(null),
                            options: [],
                        });
                    }
                    this.cdr.markForCheck();
                } else if (ret?.Action === 'delete') {
                    this.CustomFields.splice(index, 1);
                    this.cdr.markForCheck();
                }
            });
    }

    ngAfterViewInit(): void {
        this.DetailList$ = this.Resource$.pipe(
            switchMap((resource) =>
                this.store.select(getResourceTypesFetched).pipe(
                    tap((fetched) => {
                        if (!fetched) {
                            this.Loading = true;
                            this.resourceTypeResolver.resolve();
                        }
                    }),
                    filter((v) => !!v),
                    switchMap(() =>
                        combineLatest([
                            this.store.select(getResourceTypeDictionary),
                            getFetched$(this.store, getQuantityTypesFetched, getQuantityTypeDictionary),
                            this.ResourceForm.controls.ResourceTypeId.valueChanges.pipe(startWith(this.ResourceForm.value.ResourceTypeId)),
                            // this.ResourceForm.controls.QuantityType.valueChanges.pipe(startWith(this.ResourceForm.value.QuantityType)),
                        ]),
                    ),
                    map(([resourceTypes, quantityTypes, typeId]) => {
                        const Properties: IDetailListTemplateDataProperty[] = [
                            {
                                key: 'Typ',
                                value: typeId?.Id && resourceTypes[typeId.Id].Name,
                                formControl: this.ResourceForm.controls.ResourceTypeId,
                                options: {
                                    specialInput: {
                                        customTemplate: this.selectResourceType,
                                    },
                                },
                            },
                            {
                                key: 'Name',
                                value: resource?.Name,
                                formControl: this.ResourceForm.controls.Name,
                            },
                            {
                                key: 'Artikelnummer',
                                value: resource?.ArticleNumber,
                                formControl: this.ResourceForm.controls.ArticleNumber,
                            },
                            {
                                key: 'Inventarnummer',
                                value: resource?.InventoryNumber,
                                formControl: this.ResourceForm.controls.InventoryNumber,
                            },

                            {
                                key: 'Inventarnummer automatisch vergeben',
                                formControl: this.ResourceForm.controls.AutomaticInventoryNumber,
                                options: {
                                    type: CustomPropertyType.Boolean,
                                    specialInput: { boolean: true },
                                    showHint: 'Erzeugt eine fortlaufende Inventarnummer.',
                                },
                            },
                            {
                                key: 'Kostenstelle',
                                value: resource?.Kostenstelle,
                                formControl: this.ResourceForm.controls.Kostenstelle,
                            },
                            {
                                key: 'Hersteller',
                                value: resource?.Manufacturer,
                                formControl: this.ResourceForm.controls.Manufacturer,
                            },
                            {
                                key: 'Menge',
                                value: resource?.Amount != null ? resource.Amount : '∞',
                                formControl: this.ResourceForm.controls.Amount,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                },
                            },
                            {
                                key: 'Einheit',
                                value: resource?.AmountType ? AmountTypeEnumNames.get(resource.AmountType) : null,
                                formControl: this.ResourceForm.controls.AmountType,
                                options: {
                                    specialInput: {
                                        singleSelectSearch: {
                                            options: Object.values(AmountTypeEnum).map((val) => ({
                                                value: val,
                                                optionLabel: AmountTypeEnumNames.get(val) + ' (' + AmountTypeEnumNamesLong.get(val) + ')',
                                            })) || [],
                                            compareOptions: (a, b) => a.value === b.value,
                                        }
                                    },
                                },
                            },
                            {
                                key: 'Verkaufseinheit',
                                value: resource?.QuantityTypeId ? quantityTypes[resource.QuantityTypeId]?.Name : null,
                                formControl: this.ResourceForm.controls.QuantityType,
                                options: {
                                    specialInput: {
                                        singleSelectSearch: {
                                            options$: getFetched$(this.store, getQuantityTypesFetched, getQuantityTypes),
                                            compareOptions: (a, b) => a?.Id === b?.Id,
                                        }
                                    },
                                },
                            },
                            {
                                key: 'Kaufpreis',
                                value: resource?.Cost && formatCurrency(resource.Cost / 100, 'de-DE', '€'),
                                formControl: this.ResourceForm.controls.Cost,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                    suffix: '€',
                                },
                            },
                            {
                                key: 'Minimaler Verrechnungssatz',
                                value: resource?.MinChargeRate && formatCurrency(resource.MinChargeRate / 100, 'de-DE', '€'),
                                formControl: this.ResourceForm.controls.MinChargeRate,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                    suffix: '€',
                                },
                            },
                            {
                                key: 'Maximaler Verrechnungssatz',
                                value: resource?.MaxChargeRate && formatCurrency(resource.MaxChargeRate / 100, 'de-DE', '€'),
                                formControl: this.ResourceForm.controls.MaxChargeRate,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                    suffix: '€',
                                },
                            },
                            {
                                key: 'Lagerplatz',
                                value: resource?.StorageLocation,
                                formControl: this.ResourceForm.controls.StorageLocation,
                            },
                            {
                                key: 'EAN',
                                value: resource?.GlobalTradeItemNr,
                                formControl: this.ResourceForm.controls.GlobalTradeItemNr,
                            },
                            ...(resource?.CustomFields || []).map((field) => ({
                                key: field.name,
                                value: CustomFieldTypesDisplayValueMap.get(field.type)(field.value),
                            })),
                        ];
                        return {
                            Properties,
                        };
                    }),
                    tap(() => (this.Loading = false)),
                ),
            ),
        );
    }
}
