<ng-container *ngIf="File$ | async as file">
    <app-burger-button-wrapper class="buttons">
        <button app-round-button (click)="OpenMailDialog$.next()" matTooltip="per E-Mail senden" aria-label="per E-Mail senden">
            <fa-icon icon="paper-plane"></fa-icon>
        </button>

        <!--        <button app-round-button *ngIf="Editing$ | async" (click)="ResetForms$.next()">-->
        <!--            <fa-icon icon="undo"></fa-icon>-->
        <!--        </button>-->
        <button app-round-button [class.save-active]="(Editing$ | async)" *ngIf="Editing$ | async" [disabled]="FileForm.status !== 'VALID'" (click)="Submit()" matTooltip="Speichern" aria-label="Speichern">
            <fa-icon icon="save"></fa-icon>
        </button>
        <button app-round-button *ngIf="Editing$ | async" (click)="Editing$.next(false)" matTooltip="Zurücksetzen" aria-label="Zurücksetzen">
            <fa-icon [icon]="'undo'"></fa-icon>
        </button>
        <button
            app-round-button
            *ngIf="!(Editing$ | async) && file.PartnerId === (Partner$ | async)?.Id"
            (click)="Editing$.next(true)"
            [Disabled]="!(HasEditPermissions$ | async)"
            matTooltip="Bearbeiten"
            aria-label="Bearbeiten"
        >
            <fa-icon [icon]="'pencil-alt'"></fa-icon>
        </button>

        <a
            style="margin-top: 0.5rem"
            app-round-button
            *ngIf="!(Editing$ | async)"
            [href]="this.Api.GetUrl(file.GetLastVersion().GetDownloadLink(TokenValue$ | async), 'file')"
            download
            matTooltip="Herunterladen"
            aria-label="Herunterladen"
        >
            <fa-icon icon="download"></fa-icon>
        </a>
        <button
            app-round-button
            *ngIf="!(Editing$ | async) && file.PartnerId === (Partner$ | async)?.Id"
            (click)="fileUpload.click()"
            [Disabled]="!(HasEditPermissions$ | async)"
            matTooltip="Neue Version hochladen"
            aria-label="Neue Version hochladen"
        >
            <fa-icon icon="upload"></fa-icon>
        </button>
        <button app-round-button matTooltip="Verschieben" aria-label="Verschieben" (click)="ClickMove$.next()" [Disabled]="!(HasEditPermissions$ | async)">
            <fa-icon icon="share"></fa-icon>
        </button>
        <button
            *ngIf="file.MIMEType === 'application/pdf' && !(Editing$ | async) && file.PartnerId === (Partner$ | async)?.Id"
            app-round-button
            matTooltip="PDFs zusammenführen"
            aria-label="PDFs zusammenführen"
            [matMenuTriggerFor]="mergePdfsMenu">
            <app-double-icon Icon="plus" Icon2="file-pdf"></app-double-icon>
        </button>
        <button app-round-button [class.delete-active]="(Editing$ | async)" matTooltip="Löschen" aria-label="Löschen" *ngIf="(Editing$ | async)" (click)="Delete$.next(file)" [Disabled]="!(HasEditPermissions$ | async)" Color="red">
            <fa-icon icon="trash"></fa-icon>
        </button>
        <button app-round-button matTooltip="Prozess" aria-label="Prozess" [matMenuTriggerFor]="processMenu">
            <fa-icon [icon]="ProcessMeta.Icon"></fa-icon>
        </button>
        <button app-round-button matTooltip="Verknüpfung erstellen" aria-label="Verknüpfung erstellen" (click)="createLink(file)">
            <app-double-icon [Icon]="FileLinkIcon" Icon2="plus"></app-double-icon>
        </button>
        <button app-round-button matTooltip="Aufgabe erstellen" aria-label="Aufgabe erstellen" (click)="createTaskDialog(file)">
            <app-double-icon [Icon]="TaskPageMeta.Icon" Icon2="plus"></app-double-icon>
        </button>
    </app-burger-button-wrapper>
    <mat-menu #mergePdfsMenu>
        <button mat-menu-item (click)="openFileSelectDialog()">Datei hinzufügen</button>
        <button mat-menu-item (click)="onUploadClick()">Datei hochladen</button>
    </mat-menu>

    <mat-menu #processMenu="matMenu">
        <button mat-menu-item (click)="onAddToProcessClick()">
            <fa-icon class="mat-icon" icon="pencil-alt"></fa-icon>
            Zu Prozess hinzufügen</button>
        <button mat-menu-item (click)="onStartProcessClick()">
            <fa-icon class="mat-icon" icon="plus"></fa-icon>
            Prozess mit Dokument starten</button>
    </mat-menu>

    <div class="preview-wrapper">
        <ng-container *ngIf="file.MIMEType.indexOf('image/') > -1 || file.MIMEType.indexOf('application/pdf') > -1 || (SelectedVersion$ | async)?.HasThumbnail; else noImage">
            <div class="no-image" *ngIf="!FileIsLoaded && !ThumbnailIsLoaded">
                <app-loading></app-loading>
            </div>
            <img
                *ngIf="!FileIsLoaded"
                class="full-screen-image thumbnail"
                [class.hide]="!ThumbnailIsLoaded"
                [src]="Api.GetUrl((SelectedVersion$ | async)?.GetThumbnailLink(TokenValue$ | async), 'file')"
                (load)="ThumbnailIsLoaded = true"
            />
            <img
                *ngIf="file.MIMEType.indexOf('image/') > -1"
                class="full-screen-image"
                [class.hide]="!FileIsLoaded"
                [src]="Api.GetUrl((SelectedVersion$ | async)?.GetDownloadLink(TokenValue$ | async), 'file')"
                (load)="FileIsLoaded = true"
            />
            <pdf-viewer
                *ngIf="file.MIMEType.indexOf('application/pdf') > -1"
                [src]="{
                    url: Api.GetUrl((SelectedVersion$ | async)?.GetDownloadLink(TokenValue$ | async), 'file')
                }"
                class="pdf-viewer"
                [original-size]="false"
                [show-all]="true"
                [render-text]="true"
                (after-load-complete)="FileIsLoaded = true"
            ></pdf-viewer>
        </ng-container>
        <div class="right-bottom">
            <button *ngIf="file.MIMEType.indexOf('image/') > -1" app-round-button (click)="OpenFullScreen(file)" matTooltip="Vollbild">
                <fa-icon icon="expand"></fa-icon>
            </button>
            <button
                *ngIf="file.MIMEType.indexOf('application/pdf') > -1"
                app-round-button
                (click)="OpenInEditor(file)"
                [Disabled]="!(HasEditPermissions$ | async)"
                matTooltip="Vollbild"
            >
                <fa-icon icon="expand"></fa-icon>
            </button>
            <button
                *ngIf="file.MIMEType.indexOf('application/msword') > -1 || file.MIMEType.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') > -1"
                app-round-button
                (click)="OpenReport(file)"
                matTooltip="Als Bericht öffnen"
            >
                <fa-icon icon="edit"></fa-icon>
            </button>
        </div>
        <div class="right-top" *ngIf="file.LinkedDocumentId">
            <fa-icon matTooltip="dies ist eine Verknüpfung" [icon]="FileLinkIcon"></fa-icon>
        </div>
    </div>

    <div class="flex-row">
        <mat-chip-list class="chips" *ngIf="!(Editing$ | async)" [disabled]="true">
            <mat-chip *ngFor="let tag of FileTags$ | async" [disabled]="true">{{ tag.Name }}</mat-chip>
        </mat-chip-list>
        <div class="button-wrapper">
            <div class="history-button-wrapper" style="margin: 4px" *ngFor="let eventData of EventData$ | async">
                <a
                    *ngIf="eventData.event as event"
                    app-button
                    Lighter
                    class="wide-button"
                    [routerLink]="['/', 'chronik', event.CustomerId || 'alle', event.Id]"
                    matTooltip="{{ eventData.eventType?.Name + ' ' }} {{ event.EventDate.toDateString() | date }}"
                >
                    <fa-icon *ngIf="eventData.eventType?.ImagePath; else letterTemplate" [icon]="eventData.eventType?.ImagePath | icon"></fa-icon>
                    <ng-template #letterTemplate>
                        <span class="event-type-letter">{{ (eventData.eventType?.Name)[0] }}</span>
                    </ng-template>
                </a>
            </div>
        </div>
    </div>
    <mat-tab-group preserveContent>
        <mat-tab label="Details">
            <div class="links">
                <div class="admin-button-wrapper link-wrapper" *ngIf="!!file.CustomerId && file.PartnerId === (Partner$ | async)?.Id">
                    <a app-button Lighter class="wide-button" [matTooltip]="CS.GetMultiple$() | async" [routerLink]="['/', CustomerPath, file.CustomerId]">
                        <fa-icon [icon]="CustomerIcon"></fa-icon>
                    </a>
                </div>
                <div
                    class="commission-button-wrapper link-wrapper"
                    *ngIf="!!(Commission$ | async) && file.PartnerId === (Partner$ | async)?.Id && PS.Has(PS.Permission.GetCommission) | async"
                >
                    <a
                        *ngIf="!!file.CustomerId"
                        app-button
                        Lighter
                        class="wide-button"
                        [matTooltip]="cls.getMultiple$('Commission') | async"
                        [routerLink]="['/', CommissionPath, file.CustomerId, file.ContractId]"
                    >
                        <fa-icon [icon]="CommissionIcon"></fa-icon>
                    </a>
                    <a *ngIf="!file.CustomerId" app-button Lighter class="wide-button" [matTooltip]="cls.getMultiple$('Commission') | async" [routerLink]="['/', CommissionPath, 'alle', file.ContractId]">
                        <fa-icon [icon]="CommissionIcon"></fa-icon>
                    </a>
                </div>
                <div class="dms-button-wrapper link-wrapper" *ngIf="!(IsInDms$ | async) && (PS.Has(PS.Permission.GetFiles) | async) && !(Folder$ | async)?.Hidden">
                    <a app-button Lighter class="wide-button" matTooltip="DMS" [routerLink]="['/', DMSPath]" [queryParams]="{ documentId: file.Id, folderId: file.FolderId }">
                        <fa-icon [icon]="DMSIcon"></fa-icon>
                    </a>
                </div>
                <div class="history-button-wrapper link-wrapper" *ngIf="file.EventIds.length == 1 && file.PartnerId === (Partner$ | async)?.Id">
                    <a *ngIf="!!file.CustomerId" app-button Lighter class="wide-button" matTooltip="Chronik" [routerLink]="['/', ChronikPath, file.CustomerId, file.EventIds[0]]">
                        <fa-icon [icon]="ChronikIcon | icon"></fa-icon>
                    </a>
                    <a *ngIf="!file.CustomerId" app-button Lighter class="wide-button" matTooltip="Chronik" [routerLink]="['/', ChronikPath, 'alle', file.EventIds[0]]">
                        <fa-icon [icon]="ChronikIcon | icon"></fa-icon>
                    </a>
                </div>
                <div class="video-button-wrapper link-wrapper" *ngIf="!!file.VideoId && file.PartnerId === (Partner$ | async)?.Id">
                    <a
                        *ngIf="!!file.CustomerId"
                        app-button
                        Lighter
                        class="wide-button"
                        matTooltip="Videodokumentation"
                        [routerLink]="['/', VideoPath, file.CustomerId, file.VideoId]"
                    >
                        <fa-icon [icon]="VideoIcon | icon"></fa-icon>
                    </a>
                    <a *ngIf="!file.CustomerId" app-button Lighter class="wide-button" matTooltip="Videodokumentation" [routerLink]="['/', VideoPath, 'alle', file.VideoId]">
                        <fa-icon [icon]="VideoIcon | icon"></fa-icon>
                    </a>
                </div>
            </div>
            <app-detail-list-template class="full-width" [Data]="FileDetails$ | async" [Editing]="Editing$ | async" [Inline]="true"> </app-detail-list-template>
            <div class="tag-wrapper">
                <app-tag-chip-autocomplete *ngIf="Editing$ | async" [FormControl]="FileForm.controls.Tags" class="hover-form"></app-tag-chip-autocomplete>
            </div>
        </mat-tab>
        <mat-tab label="Dokumentendaten">
            <button style="float: right" *ngIf="PS.Has$(PS.Permissions.CreateBusinessVolume) | async" app-round-button matTooltip="Kosten erfassen" (click)="openCreateBvDialog(file.Id)">
                <fa-icon icon="dollar-sign"></fa-icon>
                <fa-icon icon="plus"></fa-icon>
            </button>
            <ng-container *ngIf="PS.Has$(PS.Permissions.GetBusinessVolume) | async">
                <div class="wide-buttons" *ngIf="(AssignedBvs$|async).length">
                    <div   *ngFor="let bve of AssignedBvs$|async">
                        <button  class="butt" app-button matTooltip="Kosten einsehen und bearbeiten" (click)="openEditBvDialog(bve.Id)">
                            <fa-icon icon="dollar-sign"></fa-icon>
                            <fa-icon icon="pencil-alt"></fa-icon>
                        </button>
                    </div>
                </div>
            </ng-container>
            <app-document-detection-result-data-modal [Editing]="Editing$ | async" #documentDetectionResultDataModalComponent [documentId]="file.Id"></app-document-detection-result-data-modal>
        </mat-tab>
        <mat-tab label="Ereignisse">
            <div class="loading-wrapper" *ngIf="!(Events$ | async)">
                <app-loading></app-loading>
            </div>
            <p *ngIf="(Events$ | async)?.length === 0">
                Keine ereignisse vorhanden
            </p>
            <app-event-list-entry
                *ngFor="let event of Events$ | async"
                [EventId]="event.Id"
                [ShowRoutingButtons]="true"
            ></app-event-list-entry>
        </mat-tab>
        <mat-tab label="Versionen">
            <table mat-table [dataSource]="Versions$ | async" class="versions-table">
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Erstellt</th>
                    <td mat-cell *matCellDef="let element">{{ element.version.CreatedAt | date: 'dd.MM.yy H:mm:ss' }}</td>
                </ng-container>

                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef>Benutzer</th>
                    <td mat-cell *matCellDef="let element">{{ element.user?.DisplayName }}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="number">Nummer</th>
                    <td mat-cell *matCellDef="let element" class="number">{{ element.version.Number }}</td>
                </ng-container>

                <ng-container matColumnDef="buttons">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" class="actions">
                        <a
                            app-round-button
                            *ngIf="!(Editing$ | async)"
                            [href]="this.Api.GetUrl(element.version.GetDownloadLink(TokenValue$ | async), 'file')"
                            download
                            matTooltip="Herunterladen"
                        >
                            <fa-icon icon="download"></fa-icon>
                        </a>
                        <button app-round-button *ngIf="(Editing$ | async) && element.version.Number !== 1" Color="red" matTooltip="Löschen" (click)="DeleteVersion$.next(element.version)">
                            <fa-icon icon="trash"></fa-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['date', 'name', 'user', 'buttons']"></tr>
                <tr mat-row (click)="SelectVersion(row.version)" *matRowDef="let row; columns: ['date', 'name', 'user', 'buttons']"></tr>
            </table>
        </mat-tab>
        <mat-tab label="Freigaben">
            <ng-template #loading>
                <div class="loading-wrapper">
                    <app-loading></app-loading>
                </div>
            </ng-template>
            <table *ngIf="fileUser$ | async as users; else loading" class="user-table" mat-table [dataSource]="users">
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef>Benutzer</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.user.DisplayName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="permission">
                    <th mat-header-cell *matHeaderCellDef style="width: 7rem">Berechtigung</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.edit">
                            <fa-icon icon="pen" size="xs"></fa-icon>
                            bearbeiten
                        </ng-container>
                        <ng-container *ngIf="!element.edit">
                            <fa-icon icon="eye" size="xs"></fa-icon>
                            ansehen
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef style="width: 3rem">
                        <button app-round-button (click)="NewDocumentUser()" *ngIf="HasEditPermissions$ | async" matTooltip="Benutzer hinzufügen">
                            <fa-icon icon="plus"></fa-icon>
                        </button>
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="ViewDocumentUser(element.documentUsers)">
                        <button app-round-button>
                            <fa-icon icon="pen" *ngIf="!element.inherited" matTooltip="Benutzer bearbeiten"></fa-icon>
                            <fa-icon icon="eye" *ngIf="element.inherited" matTooltip="Benutzer ansehen"></fa-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['user', 'permission', 'edit']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['user', 'permission', 'edit']" [class.inherited]="row.inherited"></tr>
            </table>
        </mat-tab>
    </mat-tab-group>
    <input type="file" id="file" (change)="Upload$.next([$event.target.files, file])" hidden [accept]="file.MIMEType" #fileUpload />
</ng-container>
<ng-template #noImage>
    <div class="no-image">
        <fa-icon icon="file" size="5x"></fa-icon>
    </div>
</ng-template>
