import { User2CustomerType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
export enum FromEntityEnum {
    Customer = 'CUSTOMER',
    Event = 'EVENT',
    Commission = 'COMMISSION',
}
export class UserToCustomerEntity {
    constructor(
        public CustomerId: number = null,
        public FromEntity: FromEntityEnum = null,
        public FromId: number = null,
        public Id: number = null,
        public RoleId: number = null,
        public UserId: number = null,
        public UpdatedAt: Date = null,
    ) {}
    public static readonly GQLFields: Array<keyof User2CustomerType> = ['customerId', 'fromEntity', 'fromId', 'id', 'roleId', 'userId', 'updatedAt'];
    public Clone(override: UserToCustomerAttributes = {}): UserToCustomerEntity {
        const attr = { ...this, ...override };
        return Object.assign(new UserToCustomerEntity(), attr);
    }
}

export function UserToCustomerEntityFromBackend(res: User2CustomerType): UserToCustomerEntity {
    return new UserToCustomerEntity(res.customerId, res.fromEntity, res.fromId, res.id, res.roleId, res.userId, BackendDate(res.updatedAt));
}

export interface UserToCustomerAttributes {
    CustomerId?: number;
    FromEntity?: FromEntityEnum;
    FromId?: number;
    Id?: number;
    RoleId?: number;
    UserId?: number;
    UpdatedAt?: Date;
}
