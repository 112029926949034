<h1 mat-dialog-title>Mandatsreferenz</h1>
<div mat-dialog-content>
    <div class="mandate-reference-wrapper">
        <app-textfield Key="MandateReference" [Value]="MandateReferenceForm"></app-textfield>

        <button
            app-round-button
            class="button"
            matTooltip="In Zwischenablage kopieren"
            (click)="Copy()"
        >
            <fa-icon icon="copy"></fa-icon>
        </button>

        <button
            app-round-button
            class="button"
            matTooltip="erzeuge {{ Partner.MandateReference ? 'neue' : '' }} Mandatsreferenz"
            (click)="Generate()"
        >
            <fa-icon icon="plus"></fa-icon>
        </button>
    </div>
</div>
<div mat-dialog-actions class="buttons">
    <button Inverted app-button class="button" (click)="Close()">Schließen</button>

    <button
        app-button
        class="button"
        [Disabled]="Partner.MandateReference === MandateReferenceForm.value"
        (click)="Save()"
    >
        Speichern
    </button>
</div>
