import { NgxMatColorPickerComponent } from '@angular-material-components/color-picker';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-mat-color-picker-toggle',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, MatButtonModule, MatFormFieldModule],
    templateUrl: './mat-color-picker-toggle.component.html',
    styleUrls: ['./mat-color-picker-toggle.component.scss'],
})
export class MatColorPickerToggleComponent {
    @Input() for: NgxMatColorPickerComponent;
}
