import {createAction, props} from '@ngrx/store';
import {RoleEntity} from '../../entities/role.entity';
import {PermissionEntity} from '../../entities/permission.entity';

export const PermissionsActionTypes = {
  LoadRoles: createAction(
    'roles/get',
    props<{ Payload: Array<RoleEntity> }>()
  ),
  LoadPermissions: createAction(
    'permissions/get',
    props<{ Payload: Array<PermissionEntity> }>()
  ),
  AddUser2Role: createAction(
    'roles/user/add',
    props<{ Payload: { UserId: number, Role: string } }>()
  ),
  RemoveUser2Role: createAction(
    'roles/user/delete',
    props<{ Payload: { UserId: number, Role: string } }>()
  )
};
