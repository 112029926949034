import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AccountsReceivableLedgerEntity, NettoCost } from '../dave-data-module/entities/accounts-receivable-ledger.entity';
import { QuantityTypeResolver } from '../dave-data-module/guards/quantity-type.resolver';
import { State } from '../dave-data-module/State';
import { getQuantityTypes, getQuantityTypesFetched } from '../dave-data-module/State/selectors/accounting.selector';
import { DaveListCardcolumnProperties, ListCartInputData } from '../dave-list-card/dave-list-card.component';
import { FILTER_TYPE_SEARCH_MULTI_SELECT, IFilterTypeSearchMultiSelectValue } from '../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { PermissionService } from '../dave-utils-module/dave-shared-components-module/services/permission.service';
import { isNotNullOrUndefined } from '../helper/helper';
import { BookingPositionMetaIcon } from '../helper/page-metadata';
import { FilterTypes } from '../services/default-filter.service';

@Component({
    selector: 'app-booking-position-list-card',
    templateUrl: './booking-position-list-card.component.html',
    styleUrls: ['./booking-position-list-card.component.scss'],
    providers: [CurrencyPipe, DecimalPipe],
})
export class BookingPositionListCardComponent implements OnInit {
    @Input() Headline = 'Abr.-Pos.';
    @Input() HeaderIcon = BookingPositionMetaIcon;
    @Input() HeaderIcon2 = null;
    @Input() NewButton = false;
    @Input() Clickable = false;
    @Input() Data: Observable<AccountsReceivableLedgerEntity[]>;
    @Output() EntryClicked$ = new EventEmitter<{ entry: AccountsReceivableLedgerEntity; index: number; element: HTMLElement }>();
    @Output() NewClicked$ = new EventEmitter<void>();
    @Input() IsExpandable = false;
    @Input() Expanded = false;
    @Input() MaxHeight: string;
    displayedColumns: Array<keyof typeof this.TableConfig> = ['InvoiceNumber', 'BookingText', 'Information', 'Quantity', 'Unit', 'CompleteCost'];
    @Input() set HideColumns(v: Array<keyof typeof this.TableConfig>) {
        this.displayedColumns = this.displayedColumns.filter(c => !v.includes(c));
    };
    public BookingPositionMetaIcon = BookingPositionMetaIcon;
    public ShownData1: Observable<ListCartInputData<any>>;
    FilterValues$ = new BehaviorSubject<{ [FilterTypes.QuantityType]: IFilterTypeSearchMultiSelectValue[] }>({ [FilterTypes.QuantityType]: [] });
    FilterSettings$ = this.store.select(getQuantityTypesFetched).pipe(
        filter((f) => f),
        switchMap(() => this.store.select(getQuantityTypes)),
        map((qTypes) => {
            return [
                {
                    Name: FilterTypes.QuantityType,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Einheit',
                    Icon: null,
                    Values: qTypes.map<IFilterTypeSearchMultiSelectValue>((t) => ({ id: t.Id, label: t.Name })),
                },
            ];
        }),
    );
    FilterAmount$ = this.FilterValues$.pipe(map((filterValues) => (filterValues[FilterTypes.QuantityType]?.length ? 1 : null)));
    TableConfig = {
        BookingText: 'Art-Nr.',
        Information: 'Bezeichnung',
        CompleteCost: 'Summe',
        Quantity: 'Menge',
        Unit: 'Einheit',
        InvoiceNumber: 'Re-Nr.'
    };

    constructor(private store: Store<State>, private curPipe: CurrencyPipe, private decimalPipe: DecimalPipe, quantityTypeResolver: QuantityTypeResolver, public PS: PermissionService) {
        quantityTypeResolver.resolve();
    }

    ngOnInit(): void {
        this.ShownData1 = combineLatest([
            this.Data.pipe(
                filter(isNotNullOrUndefined),
                map((bookingPositions) => {
                    return [...bookingPositions.filter((b) => !b.EventId), ...bookingPositions.filter((b) => b.EventId)];
                }),
            ),
            this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined)),
        ]).pipe(
            map(([bookingPositions, quantityTypes]) =>
                bookingPositions
                    .map<DaveListCardcolumnProperties<any>>((b) => ({
                        ...b,
                        cssClass: b.EventId
                            ? 'app-color-event'
                            : b.CustomProperties?.timeTracking
                            ? 'app-color-time-tracking' //rgba(126, 87, 194, 1)
                            : null,
                        Unit: quantityTypes.find((q) => q.Id === b.QuantityTypeId)?.Name,
                        CompleteCost: this.curPipe.transform(NettoCost(b.Quantity, b.BaseCost), b.CurrencyCode),
                        Quantity: b.Quantity && this.decimalPipe.transform(b.Quantity),
                    }))
                    .sort((a, b) => (a.CreatedAt < b.CreatedAt ? 1 : -1)),
            ),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            switchMap((data) =>
                this.FilterValues$.pipe(
                    map((filter) => {
                        let filteredData = data.slice();
                        if (filter[FilterTypes.QuantityType]?.length) {
                            filteredData = filteredData.filter((d) => filter[FilterTypes.QuantityType].some((f) => f.id === d.QuantityTypeId));
                        }
                        return filteredData;
                    }),
                ),
            ),
            /* map(data => new MatTableDataSource(data)),
            tap(
                (dataSource) =>
                    (dataSource.filterPredicate = (data : AccountsReceivableLedgerEntity, searchTerm) =>
                        [data.BookingText].some((value) => stringSearch(value || '', searchTerm || ''))),
            ),*/
        );
    }
}
