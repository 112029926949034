import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { Person2EntityEntity, Person2EntityFromBackend } from '../../entities/person2entity.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { EmployeeActionTypes } from '../actions/employee.actions';
import { Person2EntityActionTypes } from '../actions/person2entity.action';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';

enum ErrorCodes {
    Load = 'Personenzuordnung Abrufen fehlgeschlagen',
    Remove = 'Personenzuordnung Löschen fehlgeschlagen',
    Modify = 'Personenzuordnung ändern fehlgeschlagen',
    Add = 'Personenzuordnung hinzufügen fehlgeschlagen',
    Approve = 'Personenzuordnung genehmigen fehlgeschlagen',
}

@Injectable()
export class Person2EntityEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
    ) {}

    GetAllPerson2EntitiesQuery$ = createEffect(() => this.actions$.pipe(
        ofType(Person2EntityActionTypes.Load),
        withLatestFrom(this.store$.select(getToken)),
        switchMap(([{Payload}, token]) => {
            const queryString = `query {
          person2Entity${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
            ${Person2EntityEntity.GqlFields}
          }
        }
        `;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                tap(res => {
                    if (res && res.person2Entity) {
                        if (Payload?.updatedSince) {
                            if (res.person2Entity.length) {
                                this.store$.dispatch(
                                    Person2EntityActionTypes.UpdateMany({
                                        Payload: res.person2Entity.map(val => Person2EntityFromBackend(val)),
                                        updateLatestUpdatedAt: true,
                                    }),
                                );
                            }
                        } else {
                            this.store$.dispatch(
                                Person2EntityActionTypes.UpdateAll({
                                    Payload: res.person2Entity.map(val => Person2EntityFromBackend(val)),
                                    updateLatestUpdatedAt: true,
                                }),
                            );
                        }
                    } else {
                        this.store$.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }));
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            );
        }),
    ), {dispatch: false});

    ModifyPerson2Entities$ = createEffect(() => this.actions$.pipe(
        ofType(Person2EntityActionTypes.ModifyPerson2Entity),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
        mutation {
            changePerson2Entity(
                id: ${JSON.stringify(Payload.id)}
                ${stringifyIfNotNullOrUndefined(Payload, 'personId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'personTypeId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'entityId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'entityType')}
                ${stringifyIfNotNullOrUndefined(Payload, 'complaintTypeId')}
            ) {
                ${Person2EntityEntity.GqlFields}
          }
        }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res =>
                    res && res.changePerson2Entity
                        ? [
                              Person2EntityActionTypes.UpdateMany({
                                  Payload: [
                                      Person2EntityFromBackend(res.changePerson2Entity),
                                  ],
                              }),
                              EmployeeActionTypes.Load({}),
                          ]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } })],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    AddPerson2Entities$ = createEffect(() => this.actions$.pipe(
        ofType(Person2EntityActionTypes.AddPerson2Entity),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{ Payload }, token]) => {
            const query = `
        mutation {
            createPerson2Entity(
                ${stringifyIfNotNullOrUndefined(Payload, 'personId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'personTypeId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'entityId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'entityType')}
                ${stringifyIfNotNullOrUndefined(Payload, 'complaintTypeId')}
            ) {
                ${Person2EntityEntity.GqlFields}
          }
        }`;

            return this.gatewayHttpService.graphQl({ query }, { token }).pipe(
                concatMap(res =>
                    res && res.createPerson2Entity
                        ? [
                              Person2EntityActionTypes.UpdateMany({
                                  Payload: [
                                      Person2EntityFromBackend(res.createPerson2Entity),
                                  ],
                              }),
                              //   PersonActionTypes.GetPersonRequest({}), //was hier übergeben?
                          ]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } })],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    DeletePerson2Vacation$ = createEffect(() => this.actions$.pipe(
        ofType(Person2EntityActionTypes.DeletePerson2Entity),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                deletePerson2Entity(id: ${action.Payload.id})
        }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res =>
                    res && res.deletePerson2Entity
                        ? [
                              Person2EntityActionTypes.RemoveOne({
                                  Payload: action.Payload.id,
                              }),
                              EmployeeActionTypes.Load({}),
                          ]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } })],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
}
