import { createAction,props } from '@ngrx/store';
import {
    DaveMutationChangeGroupArgs,
    DaveMutationCreateGroupArgs, DaveMutationCreateGroupMemberArgs,
    DaveMutationDeleteGroupArgs, DaveMutationDeleteGroupMemberArgs,
    QueryGroupArgs,
} from '../../graphql-types';
import { GroupEntity } from '../../entities/group.entity';
import { GroupMemberEntity } from '../../entities/group-member.entity';

const prefix = 'data/' + 'group/';

export const GroupActionTypes = {
    Load: createAction(prefix + 'load', props<{ Payload?: QueryGroupArgs }>()),
    Create: createAction(prefix + 'create', props<{ Payload: DaveMutationCreateGroupArgs }>()),
    Change: createAction(prefix + 'change', props<{ Payload: DaveMutationChangeGroupArgs }>()),
    Delete: createAction(prefix + 'delete', props<{ Payload: DaveMutationDeleteGroupArgs }>()),

    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<GroupEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<GroupEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction(prefix + 'updateOne', props<{ Payload: GroupEntity }>()),
    RemoveOne: createAction(prefix + 'removeOne', props<{ Payload: number }>()),
};
const prefix2 = prefix + 'member/';

export const GroupMemberActionTypes = {
    Create: createAction(prefix2 + 'create', props<{ Payload: DaveMutationCreateGroupMemberArgs }>()),
    Delete: createAction(prefix2 + 'delete', props<{ Payload: DaveMutationDeleteGroupMemberArgs }>()),
    RemoveOne: createAction(prefix2 + 'removeOne', props<{ Payload: {id: number, groupId: number} }>()),
    AddOne: createAction(prefix2 + 'addOne', props<{ Payload: GroupMemberEntity }>()),

};
