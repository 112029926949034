import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Permissions } from '@dave/types';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { DropzoneComponent, DropzoneConfig } from 'ngx-dropzone-wrapper';
import {
    BehaviorSubject,
    combineLatest,
    firstValueFrom,
    Observable,
    of,
    ReplaySubject,
    Subscription,
    switchMap
} from "rxjs";
import { filter, map, shareReplay, skip, take, tap } from 'rxjs/operators';
import { EmployeeToVacationEntityAdditionalData, VacationStatusEnum, VacationTypeEnum, VacationTypeEnumNameMap } from '../../../../dave-data-module/entities/employee-to-vacation.entity';
import { FileTypeFromFileBackend } from '../../../../dave-data-module/entities/file.entity';
import { FrontendDateTimestamp } from '../../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../../dave-data-module/State';
import { EmployeeToVacationActionTypes } from '../../../../dave-data-module/State/actions/employee-to-vacation.actions';
import { getEmployeeToVacationById, getEmployeeToVacations } from '../../../../dave-data-module/State/selectors/employee-to-vacation.selectors';
import { getEmployeeById, getEmployees } from '../../../../dave-data-module/State/selectors/employees.selectors';
import { getPartner } from '../../../../dave-data-module/State/selectors/partners.selectors';
import { getUser, getUserById } from '../../../../dave-data-module/State/selectors/users.selectors';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { AppDialogService } from '../../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { IDetailListTemplateData, IDetailListTemplateDataProperty } from '../../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { PermissionService } from '../../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, uniqArray } from "../../../../helper/helper";
import { AbsentMetaIcon, VacationMetaIcon } from '../../../../helper/page-metadata';
import { HolidayService, HolidayServiceFactory } from '../../../../services/holiday.service';

export interface NewVacationComponentDialogData {
    employeeToVacationId?: number;
    employeeId: number;
    vacation?: boolean;
    defaultStartDate?: Date;
}

@Component({
    selector: 'app-new-vacation',
    templateUrl: './new-vacation.component.html',
    styleUrls: ['./new-vacation.component.scss'],
    providers: [{ provide: HolidayService, useFactory: HolidayServiceFactory }],
})
export class NewVacationComponent implements OnInit, OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
    };
    public DropzoneConfig: DropzoneConfig;
    private fileUploadUIds: number[] = [];
    public IsVacation$: Observable<boolean>;
    public EntityEdited$: Observable<{ date: Date; name: string } | null>;
    public VacationDayCount$ = new BehaviorSubject<number>(-1);
    private vacationId$ = new ReplaySubject<number>();
    public Vacation$ = this.vacationId$.pipe(switchMap((id) => (id ? this.store.select(getEmployeeToVacationById({ id })) : of(null) )));
    public Form = this.fb.group({
        From: new UntypedFormControl(null, [Validators.required]),
        To: new UntypedFormControl(null, [Validators.required]),
        HalfFrom: new UntypedFormControl(false),
        HalfTo: new UntypedFormControl(false),
        Type: new UntypedFormControl(null, [Validators.required]),
        Note: new UntypedFormControl(''),
    }) as FormGroupTyped<{
        From: Moment | null;
        To: Moment | null;
        HalfFrom: boolean;
        HalfTo: boolean;
        Type: VacationTypeEnum;
        Note: string;
    }>;
    public AcceptedList = [];
    public DeclinedList = [];
    public VacationDays = new FormControl<number | null>(null, {
        asyncValidators: (control: FormControl<number>) =>
            this.VacationDayCount$.pipe(
                take(1),
                map((vacationDayCount) => {
                    return control.value && control.value > vacationDayCount ? { maxVacationDayCount: true } : null;
                }),
            ),
    });
    public Data: IDetailListTemplateData = null;
    public Headline: string;
    public FileUpload = true;
    public VacationMetaIcon = VacationMetaIcon;
    public AbsentMetaIcon = AbsentMetaIcon;

    public DisableApproveButton = false;
    public DisableDeclineButton = false;
    @ViewChild('dorpzone') Dropzone: DropzoneComponent;

    private isVacation$ = new ReplaySubject<boolean>()
    private subscriptions: Subscription[] = [];
    protected employeeId$ = new ReplaySubject<number>();
    protected employee$ = this.employeeId$.pipe(switchMap((id) => this.store.select(getEmployeeById({ id }))), shareReplay({refCount: true, bufferSize: 1}));
    protected hasCanEditPermission$ = combineLatest([this.employee$, this.isVacation$]).pipe(map(([e, isVacation]) => isVacation ? e?.PermissionsFromEntityRoles.includes(Permissions.ChangeVacation) : e?.PermissionsFromEntityRoles.includes(Permissions.ChangeAbsent)));
    protected hasDeleteDeclinedVacationPermission$ = combineLatest([this.employee$, this.isVacation$]).pipe(map(([e, isVacation]) => isVacation ? e?.PermissionsFromEntityRoles.includes(Permissions.DeleteDeclinedVacation) : false));
    protected hasCreatePermission$ = combineLatest([this.employee$, this.isVacation$]).pipe(map(([e, isVacation]) => isVacation ? e?.PermissionsFromEntityRoles.includes(Permissions.CreateVacation) : e?.PermissionsFromEntityRoles.includes(Permissions.CreateAbsent)));
    protected canEdit$: Observable<boolean> = combineLatest([this.hasCanEditPermission$, this.hasCreatePermission$, this.Vacation$, this.employee$]).pipe(
        map(
            ([editPermission, createPermission, vacation, employee]) =>
                !vacation && createPermission ||
                (!!vacation && editPermission && (vacation.VacationStatus !== VacationStatusEnum.Approved || vacation.Type !== VacationTypeEnum.Vacation || employee?.PermissionsFromEntityRoles.includes(Permissions.ChangeApprovedVacation))),
        ),
        shareReplay({refCount: true, bufferSize: 1}),
    );

    protected showDeleteBtn$ = combineLatest([this.hasCanEditPermission$, this.hasDeleteDeclinedVacationPermission$, this.Vacation$]).pipe(map(([ediTPermission, deletePermission, vacation]) => (!!vacation && isNotNullOrUndefined(vacation.Id) && (ediTPermission || (deletePermission && vacation.VacationStatus == VacationStatusEnum.Declined)))));
    protected canDelete$: Observable<boolean> = combineLatest([this.hasCanEditPermission$, this.hasCreatePermission$, this.Vacation$, this.employee$, this.hasDeleteDeclinedVacationPermission$]).pipe(
        map(
            ([editPermission, createPermission, vacation, employee, deletePermission]) =>
                !vacation && createPermission ||
                (!!vacation &&
                    ((deletePermission && vacation.VacationStatus == VacationStatusEnum.Declined) ||
                    (editPermission && (vacation.VacationStatus !== VacationStatusEnum.Approved || vacation.Type !== VacationTypeEnum.Vacation || employee?.PermissionsFromEntityRoles.includes(Permissions.ChangeApprovedVacation))))
                ),
        ),
        shareReplay({refCount: true, bufferSize: 1}),
    );

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<State>,
        public PS: PermissionService,
        private dialog: AppDialogService,
        private matDialog: MatDialog,
        private holidayService: HolidayService,
        private dialogRef: MatDialogRef<{ employeeToVacationId: number }>,
        private actions$: Actions,
        @Inject(MAT_DIALOG_DATA) public DialogData: NewVacationComponentDialogData,
    ) {
        // this.VacationDays.setAsyncValidators()
        this.Vacation$ = this.DialogData.employeeToVacationId ? this.store.select(getEmployeeToVacationById({ id: this.DialogData.employeeToVacationId })).pipe(shareReplay({ refCount: true, bufferSize: 1 })) : of(null);
        this.EntityEdited$ = this.Vacation$.pipe(
            switchMap((vacation) =>
                vacation?.LastUpdatedDate && vacation.LastUpdatedUserId
                    ? this.store.select(getUserById({ id: vacation.LastUpdatedUserId })).pipe(map((user) => ({ date: vacation.LastUpdatedDate, name: user ? user.DisplayName : 'unbekannt' })))
                    : of(null),
            ),
        );
        if (this.DialogData.employeeToVacationId) {
            firstValueFrom(this.Vacation$.pipe(filter(isNotNullOrUndefined))).then(vacation => this.isVacation$.next(vacation.Type === VacationTypeEnum.Vacation))
        } else {
            this.isVacation$.next(!!this.DialogData.vacation);
        }

        const params = [];
        params['employee_id'] = this.DialogData.employeeId + '';
        params['auto_set_folder'] = true;
        params['sub_folder_type'] = 'medical_certificates_root';

        this.DropzoneConfig = new DropzoneConfig({
            autoProcessQueue: true,
            uploadMultiple: false,
            paramName: () => 'file',
            parallelUploads: 100,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            params,
        });
        this.holidayService.Init('de');
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    public RemoveAssignedFile(fileId: number) {
        this.Vacation$.pipe(take(1)).subscribe((vacation) => {
            this.store.dispatch(
                EmployeeToVacationActionTypes.Modify({
                    Payload: {
                        id: this.DialogData.employeeToVacationId,
                        additionalData: JSON.stringify({
                            ...vacation.AdditionalData,
                            fileIds: uniqArray(vacation.AdditionalData?.fileIds.filter((id) => id !== fileId)),
                        } as EmployeeToVacationEntityAdditionalData),
                    },
                }),
            );
        });
    }
    public Save() {
        combineLatest([this.store.select(getUser), this.store.select(getEmployees), this.store.select(getEmployeeToVacations)])
            .pipe(take(1))
            .subscribe(([user, employees, e2vs]) => {
                // const fromDate = this.Form.value.From;
                // const toDate = this.Form.value.To;
                const fromDate = this.Form.value.From.toDate();
                const toDate = this.Form.value.To.toDate();
                if (this.Form.value.HalfFrom) {
                    // fromDate.add(12, 'hour');
                    fromDate.setHours(12, 0, 0, 0);
                } else {
                    fromDate.setHours(0, 0, 0, 0);
                }
                if (this.Form.value.HalfTo) {
                    // toDate.add(12, 'hour');
                    toDate.setHours(12, 0, 0, 0);
                } else {
                    // toDate.add(23, 'hour').add(59, 'minute');
                    toDate.setHours(23, 59, 59);
                }
                this.actions$.pipe(ofType(EmployeeToVacationActionTypes.UpdateMany), take(1)).subscribe(() => this.dialogRef.close());
                if (!this.DialogData?.employeeToVacationId) {
                    this.store.dispatch(
                        EmployeeToVacationActionTypes.Add({
                            Payload: {
                                employeeId: this.DialogData.employeeId || employees.find((e) => e.UserId === user.Id).Id,
                                startDate: FrontendDateTimestamp(fromDate),
                                endDate: FrontendDateTimestamp(toDate),
                                // vacationStatus: VacationStatusEnum.Open, // this breaks Backend
                                vacationDays: this.VacationDays.value,
                                type: this.Form.value.Type,
                                notes: this.Form.value.Note,
                                additionalData: JSON.stringify({ fileIds: uniqArray(this.fileUploadUIds) } as EmployeeToVacationEntityAdditionalData),
                            },
                        }),
                    );
                } else {
                    const vacation = e2vs.find((e2v) => e2v.Id === this.DialogData.employeeToVacationId);
                    this.store.dispatch(
                        EmployeeToVacationActionTypes.Modify({
                            Payload: {
                                id: this.DialogData.employeeToVacationId,
                                startDate: FrontendDateTimestamp(fromDate),
                                endDate: FrontendDateTimestamp(toDate),
                                vacationDays: this.VacationDays.value,
                                notes: this.Form.value.Note,
                                additionalData: JSON.stringify({
                                    ...vacation.AdditionalData,
                                    fileIds: uniqArray([...(vacation.AdditionalData?.fileIds ? vacation.AdditionalData?.fileIds : []), ...this.fileUploadUIds]),
                                } as EmployeeToVacationEntityAdditionalData),
                            },
                        }),
                    );
                }
            });
    }
    public Delete() {
        this.IsVacation$.pipe(take(1)).subscribe((isVacation) => {
            this.dialog
                .OpenConfirmationDialog({
                    paragraph: (isVacation ? 'Den Urlaubsantrag' : 'Die Abwesenheitsmeldung') + ' wirklich löschen?' + (isVacation ? '\nDie Urlaubstage werden dem Mitarbeiter wieder gutgeschrieben.' : ''),
                    styleDelete: true,
                })
                .subscribe(([result]) => {
                    if (result) {
                        this.store.dispatch(
                            EmployeeToVacationActionTypes.Delete({
                                Payload: { id: this.DialogData.employeeToVacationId },
                            }),
                        );
                        this.dialogRef.close();
                    }
                });
        });
    }
    ngOnInit(): void {
        this.employeeId$.next(this.DialogData.employeeId);
        this.vacationId$.next(this.DialogData.employeeToVacationId);
        this.subscriptions.push(
            this.store
                .select(getPartner)
                .pipe(
                    tap((p) => {
                        if (p.VacationCalculationDefaultProvince != null) {
                            this.holidayService.Init('de', p.VacationCalculationDefaultProvince);
                        }
                    }),
                )
                .subscribe(),
            this.store
                .select(getEmployeeById({ id: this.DialogData.employeeId }))
                .pipe(map((employee) => (employee.RemainingVacationDaysFromCurrentYear ? employee.RemainingVacationDaysFromCurrentYear : 0) + (employee.RemainingVacationDaysFromPastYears ? employee.RemainingVacationDaysFromPastYears : 0)))
                .subscribe((v) => this.VacationDayCount$.next(v)),
        );
        this.IsVacation$ = this.store.select(getEmployeeToVacations).pipe(
            map((e2vs) => {
                if (this.DialogData.employeeToVacationId) {
                    return e2vs?.find((e2v) => e2v.Id === this.DialogData.employeeToVacationId).Type === VacationTypeEnum.Vacation;
                }
                return this.DialogData.vacation;
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        combineLatest([this.store.select(getEmployeeToVacations), this.store.select(getEmployees), this.store.select(getUser), this.canEdit$, this.IsVacation$])
            .pipe(
                tap(([e2vs, employees, user, permissionChangeVacation, isVacation]) => {
                    if (!this.DialogData.employeeToVacationId || e2vs) {
                        const vacation = e2vs?.find((e2v) => e2v.Id === this.DialogData.employeeToVacationId);
                        if (this.DialogData.employeeToVacationId) {
                            this.Form.setValue({
                                From: moment(vacation.StartDate),
                                To: moment(vacation.EndDate),
                                HalfFrom: vacation.StartDate.getHours() > 0,
                                HalfTo: vacation.EndDate.getHours() < 23,
                                Type: vacation.Type,
                                Note: vacation.Notes,
                            });
                            this.Form.controls.Type.disable();
                            this.VacationDays.setValue(vacation.VacationDays);
                            this.AcceptedList = vacation.UserIdListApproved?.map((ua) => {
                                const e = employees.find((e) => e.UserId === ua);
                                return e ? e.Firstname + ' ' + e.Lastname : [''];
                            });
                            this.DeclinedList = vacation.UserIdListDeclined?.map((ua) => {
                                const e = employees.find((e) => e.UserId === ua);
                                return e ? e.Firstname + ' ' + e.Lastname : [''];
                            });
                            this.DisableApproveButton = vacation.UserIdListApproved?.includes(user?.Id) || (vacation.VacationStatus === VacationStatusEnum.Approved && vacation.Type === VacationTypeEnum.Vacation);
                            this.DisableDeclineButton = vacation.UserIdListDeclined?.includes(user?.Id) || vacation.VacationStatus === VacationStatusEnum.Declined;
                        } else {
                            const d = this.DialogData.defaultStartDate || new Date();
                            d.setHours(0, 0, 0, 0);
                            this.Form.setValue({
                                From: moment(d),
                                To: moment(d),
                                HalfFrom: false,
                                HalfTo: false,
                                Type: this.DialogData.vacation ? VacationTypeEnum.Vacation : null,
                                Note: '',
                            });
                            this.VacationDays.setValue(this.holidayService.GetWorkingDayCount(new Date(), new Date() , false));
                        }
                        if (permissionChangeVacation) {
                        } else {
                            this.VacationDays.disable();
                        }
                        this.Headline = (isVacation ? 'Urlaubsantrag ' : 'Abwesenheit ') + (this.DialogData.employeeToVacationId ? 'bearbeiten' : 'einreichen');
                        // this.FileUpload = !isVacation;
                        const Properties: IDetailListTemplateDataProperty[] = [
                            {
                                key: 'von',
                                formControl: this.Form.controls.From,
                                value: this.Form.value.From.toDate(),
                                options: {
                                    specialInput: {
                                        date: {
                                            dateClass: (date) => this.dateClass(date),
                                        },
                                    },
                                    type: CustomPropertyType.Datum,
                                },
                            },
                            {
                                key: 'bis',
                                formControl: this.Form.controls.To,
                                value: this.Form.value.To.toDate(),
                                options: {
                                    specialInput: {
                                        date: {
                                            matDatepickerFilter: (date) => moment(date) >= this.Form.value.From,
                                            dateClass: (date) => this.dateClass(date),
                                        },
                                    },
                                    type: CustomPropertyType.Datum,
                                },
                            },
                            {
                                key: isVacation ? 'benötigte Urlaubstage' : 'abwesende Arbeitstage',
                                formControl: this.VacationDays,
                                value: this.VacationDays.value,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                },
                            },
                            {
                                key: 'Notiz',
                                formControl: this.Form.controls.Note,
                                options: {
                                    specialInput: {
                                        textArea: { Fill: false },
                                    },
                                },
                            },
                        ];
                        if (!isVacation) {
                            Properties.unshift({
                                key: 'Art',
                                formControl: this.Form.controls.Type,
                                options: {
                                    specialInput: {
                                        select: Object.values(VacationTypeEnum)
                                            .filter((v) => v !== VacationTypeEnum.Vacation)
                                            .map((val) => ({
                                                optionValue: val,
                                                optionLabel: VacationTypeEnumNameMap.get(val),
                                            })),
                                    },
                                },
                            });
                        }
                        this.Data = {
                            Properties,
                        };
                    }
                }),
            )
            .subscribe();
        this.Form.valueChanges
            .pipe(
                skip(1),
                filter((value) => !!(value.To && value.From)),
                tap((value) => {
                    let workingDays = this.holidayService.GetWorkingDayCount(value.From.toDate(), value.To.toDate() , this.Form.controls.Type.value === VacationTypeEnum.Illness );
                    workingDays = value.HalfTo ? workingDays - 0.5 : workingDays;
                    workingDays = value.HalfFrom ? workingDays - 0.5 : workingDays;
                    this.VacationDays.setValue(workingDays);
                    if (value.From > value.To) {
                        this.Form.controls.To.setValue(value.From);
                    }
                }),
            )
            .subscribe();
    }
    public OpenVacationDialog(approved: boolean) {
        this.IsVacation$.pipe(take(1)).subscribe((isVacation) => {
            this.dialog
                .OpenConfirmationDialog({
                    heading: isVacation ? 'Urlaubsantrag ' + (approved ? 'genehmigen' : 'ablehnen') : 'Als gesehen markieren',
                    paragraph: isVacation ? 'Wollen Sie den Urlaubsantrag ' + (approved ? 'genehmigen' : 'ablehnen') + '?' : 'Wollen Sie die Abwesenheitsmeldung als gesehen markieren ?',
                })
                .pipe(
                    take(1),
                    filter(([response]) => response),
                )
                .subscribe(() => {
                    this.store.dispatch(EmployeeToVacationActionTypes.Approve({ Payload: { id: this.DialogData.employeeToVacationId, approved } }));
                    this.dialogRef.close();
                });
        });
    }
    private dateClass(date: moment.Moment): MatCalendarCellCssClasses {
        const holiday = this.holidayService.IsHoliday(date.toDate());
        return holiday && holiday?.some((h) => h.type === 'public') ? 'holiday' : '';
    }

    public OnUploadError([file, message]) {
        console.error({ file, message });
        if (file.previewElement) {
            this.removeProgressClass(file.previewElement);
        }
    }

    public OnSuccess([file, files, progressEvent]: [any, FileTypeFromFileBackend[], any]) {
        if (file.previewElement) {
            this.removeProgressClass(file.previewElement);
        }
        this.fileUploadUIds.push(...files.map((f) => f.id));
    }
    public OpenFilePreview(fileId: number) {
        this.matDialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: { fileId },
        });
    }
    removeProgressClass(previewElement: Element) {
        for (let node of previewElement.querySelectorAll('[data-dz-uploadprogress].indeterminate-progress')) {
            node.classList.remove('indeterminate-progress');
        }
    }
    addProgressClass(previewElement: Element) {
        for (let node of previewElement.querySelectorAll('[data-dz-uploadprogress]')) {
            node.classList.add('indeterminate-progress');
        }
    }
    onDropzoneUploadProgress([file, progress, bytesSent]) {
        if (progress === 100 && file.previewElement) {
            this.addProgressClass(file.previewElement);
        }
    }
}
