import {
    AfterViewInit,
    Component,
    ContentChild,
    ElementRef, EventEmitter,
    Input,
    OnInit, Output,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { map, take } from 'rxjs/operators';
import { BreakpointObserverService } from '../../../services/breakpoint-observer.service';
import { DetailViewButtonsDirective } from './detail-view-buttons.directive';
import { DetailViewLeftContentDirective } from './detail-view-left-content.directive';
import { DetailViewRightContentDirective } from './detail-view-right-content.directive';
import { DetailViewTopContentDirective } from './detail-view-top-content.directive';

/**
 * Dieses Template zeigt Inhalte an, die mit Marker-Direktiven an diese Komponente übergeben werden.
 *
 * ```html
 * <app-detail-view-template>
 *   <div *appDetailViewLeftContent>
 *     Inhalt
 *   </div>
 * </app-detail-view-template>
 * ```
 */
@Component({
    selector: 'app-detail-view-template',
    templateUrl: './detail-view-template.component.html',
    styleUrls: ['./detail-view-template.component.scss'],
})
export class DetailViewTemplateComponent implements AfterViewInit {

    @ContentChild(DetailViewButtonsDirective, { read: TemplateRef })
    public Buttons: DetailViewButtonsDirective['templateRef'];

    @ContentChild(DetailViewTopContentDirective, { read: TemplateRef })
    public TopContent: DetailViewTopContentDirective['templateRef'];

    @ContentChild(DetailViewLeftContentDirective, { read: TemplateRef })
    public LeftContent: DetailViewLeftContentDirective['templateRef'];

    @ContentChild(DetailViewRightContentDirective, { read: TemplateRef })
    public RightContent: DetailViewRightContentDirective['templateRef'];

    @Input()ShowButtons = true;
    @Input()ShowPlaceholder = true;

    private isMobile$ = this.breakpointService.MobileQuery.pipe(
        take(1),
        map(val => this.IsMobile = val)
    ).subscribe();
    @Input() Resizable = false;
    // @Output() OnResize = new EventEmitter<void>();
    @Output() AfterResize = new EventEmitter<void>();
    @ViewChild('resizer') resizerElement: ElementRef;
    public IsMobile: boolean;
    constructor(private breakpointService: BreakpointObserverService)
    {

    }

    ngAfterViewInit(): void {
        if(this.Resizable) {
            const leftMinWidth = 10;
            const rightMinWidth = 10;
            const dividerWidth = 18; // steht auch im .scss

            const resizer = this.resizerElement.nativeElement;
            const leftSide = resizer.previousElementSibling;
            const rightSide = resizer.nextElementSibling;

            // The current position of mouse
            let x = 0;
            let y = 0;

            const mouseMoveHandler = function (e) {
                // How far the mouse has been moved
                const dx = e.clientX - x;
                const dy = e.clientY - y;

                let newLeftWidth = ((leftWidth + dx) * 100) / (resizer.parentNode.getBoundingClientRect().width - dividerWidth);
                if (newLeftWidth < leftMinWidth) {
                    newLeftWidth = newLeftWidth > leftMinWidth / 2 ? leftMinWidth : 0
                } else if (newLeftWidth > 100 - rightMinWidth) {
                    newLeftWidth = newLeftWidth < 100 - rightMinWidth / 2 ? 100 - rightMinWidth : 100;
                }
                leftSide.style.flexBasis = `${newLeftWidth}%`;

                resizer.style.cursor = 'col-resize';

                document.body.style.cursor = 'col-resize';

                leftSide.style.userSelect = 'none';
                leftSide.style.pointerEvents = 'none';

                rightSide.style.userSelect = 'none';
                rightSide.style.pointerEvents = 'none';

            };
            const mouseUpHandler =  () => {
                resizer.style.removeProperty('cursor');
                document.body.style.removeProperty('cursor');

                leftSide.style.removeProperty('user-select');
                leftSide.style.removeProperty('pointer-events');

                rightSide.style.removeProperty('user-select');
                rightSide.style.removeProperty('pointer-events');

                // Remove the handlers of `mousemove` and `mouseup`
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
                this.AfterResize.emit();
            };


            // Width of left side
            let leftWidth = 0;

            // Handle the mousedown event
// that's triggered when user drags the resizer
            const mouseDownHandler = function (e) {
                // Get the current mouse position
                x = e.clientX;
                y = e.clientY;
                leftWidth = leftSide.getBoundingClientRect().width;

                // Attach the listeners to `document`
                document.addEventListener('mousemove', mouseMoveHandler);
                document.addEventListener('mouseup', mouseUpHandler);
            };

// Attach the handler
            resizer.addEventListener('mousedown', mouseDownHandler);
        }
    }


}
