import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { BVCustomNameEntity } from '../../entities/b-v-custom-name.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { BVCustomNameActions } from '../actions/resource-dispo/b-v-custom-names.actions';

export const B_V_CUSTOM_NAME_FEATURE_KEY = 'bVCustomName';

export interface BVCustomNameState extends EntityState<BVCustomNameEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<BVCustomNameEntity>({
    selectId: (Entity) => Entity.Id,
});

const initialState: BVCustomNameState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const bVCustomNameReducer = createReducer(
    initialState,
    on(BVCustomNameActions.updateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, {
            ...state,
            fetched: true,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(BVCustomNameActions.updateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(BVCustomNameActions.updateOne, (state, { Payload }) => adapter.setOne(Payload, state)),
    on(BVCustomNameActions.removeOne, (state, { Payload }) => adapter.removeOne(Payload, state)),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllBVCustomName = selectAll;
export const selectBVCustomNameEntities = selectEntities;

export const selectBVCustomNameFetched = (state: BVCustomNameState) => state.fetched;
export const selectBVCustomNameLatestUpdatedAt = (state: BVCustomNameState) => state.latestUpdatedAt;
