import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { SfdtTemplateEntity } from '../entities/sfdt-template.entity';
import { State } from '../State';
import { LedgerImportActionTypes } from '../State/actions/accounting.actions';
import { getSfdtTemplates } from '../State/selectors/accounting.selector';

@Injectable({
    providedIn: 'root',
})
export class SFDTTemplateResolver implements Resolve<SfdtTemplateEntity[]> {
    constructor(private store: Store<State>) {}

    resolve() {
        const value$ = this.store.select(getSfdtTemplates);
        value$
            .pipe(
                filter(value => !value),
                take(1),
                tap(() => {
                    if (localStorage.getItem('token') != null) {
                        this.store.dispatch(LedgerImportActionTypes.GetSFDTTemplate({}));
                    }
                }),
            )
            .subscribe();

        return value$.pipe(
            filter(value => !!value),
            take(1),
        );
    }
}
