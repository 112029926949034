import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NewReportDialogComponent, NewReportDialogComponentDialogData } from "./new-report-dialog.component";

@Injectable({
    providedIn: 'root',
})
export class NewReportDialogService {
    constructor(private matDialog: MatDialog) {}

    OpenNewReportDialog(data?: NewReportDialogComponentDialogData) {
        return this.matDialog
            .open(NewReportDialogComponent, {
                ...NewReportDialogComponent.DefaultConfig,
                data: data || {},
            })
            .afterClosed();
    }
}
