import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TextTemplatesEntity } from '../../entities/text-templates.entity';
import { TextTemplatesActionTypes } from '../actions/textTemplates.actions';

export interface TextTemplatesState extends EntityState<TextTemplatesEntity> {
    fetched: boolean;
}

export const Text_Templates_Key = 'textTemplates';

const adapter = createEntityAdapter<TextTemplatesEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const textTemplatesReducer = createReducer(
    initialState,
    on(TextTemplatesActionTypes.UpdateAll, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllTextTemplates = selectAll;
export const selectTextTemplatesEntities = selectEntities;

export const selectTextTemplatesFetched = (state: TextTemplatesState) => state.fetched;
