import { createAction, props } from '@ngrx/store';
import { StatusFromBackofficeEntity } from '../../entities/statusFromBackoffice.entity';


export const StatusFromBackofficeActionTypes = {
    UpdateStatusFromBackoffice: createAction(
        'data/StatusFromBackoffice/update',
        props<{ Payload: Array<StatusFromBackofficeEntity> }>(),
    ),
    GetStatusFromBackofficeRequest: createAction(
        'data/StatusFromBackoffice/getAll',
    ),
};

