import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';
import { DowncastDispatcher } from '@ckeditor/ckeditor5-engine';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { FontBackgroundColor, FontColor, FontFamily, FontSize } from '@ckeditor/ckeditor5-font';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Image, ImageResize, ImageStyle, ImageToolbar, ImageUpload } from '@ckeditor/ckeditor5-image';
import { Link } from '@ckeditor/ckeditor5-link';
import { List } from '@ckeditor/ckeditor5-list';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Table, TableCellProperties, TableProperties, TableToolbar } from '@ckeditor/ckeditor5-table';
import { ButtonView } from '@ckeditor/ckeditor5-ui';
import { Base64UploadAdapter, FileRepository, UploadAdapter, UploadResponse } from '@ckeditor/ckeditor5-upload';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { PublicFileEntityFromBackend, PublicFileType } from '../dave-data-module/entities/public-file.entity';
import { HttpService } from '../dave-data-module/services/http.service';
import { State } from '../dave-data-module/State';
import { getToken } from '../dave-data-module/State/selectors/base.selectors';

export function CustomToolbarButton(
    name: string,
    callBack: (editor) => void,
    config: object & {
        readonly [K in keyof ButtonView]?: unknown;
    },
) {
    return function customToolbarButton(editor) {
        editor.ui.componentFactory.add(name, (locale) => {
            const button = new ButtonView(locale);
            button.set(config);
            button.on('execute', () => {
                callBack(editor);
            });
            return button;
        });
    };
}
//
// const availablePluginNames = [
//     'Essentials',
//     'CKFinderUploadAdapter',
//     'Autoformat',
//     'Bold',
//     'Italic',
//     'BlockQuote',
//     'CKBox',
//     'CKFinder',
//     'CloudServices',
//     'EasyImage',
//     'Heading',
//     'Image',
//     'ImageCaption',
//     'ImageStyle',
//     'ImageToolbar',
//     'ImageUpload',
//     'Indent',
//     'Link',
//     'List',
//     'MediaEmbed',
//     'Paragraph',
//     'PasteFromOffice',
//     'PictureEditing',
//     'Table',
//     'TableToolbar',
//     'TextTransformation',
//     'Clipboard',
//     'ClipboardPipeline',
//     'DragDrop',
//     'Widget',
//     'WidgetTypeAround',
//     'Enter',
//     'Delete',
//     'PastePlainText',
//     'SelectAll',
//     'SelectAllEditing',
//     'SelectAllUI',
//     'ShiftEnter',
//     'Typing',
//     'Input',
//     'Undo',
//     'UndoEditing',
//     'UndoUI',
//     'FileRepository',
//     'PendingActions',
//     'BoldEditing',
//     'BoldUI',
//     'ItalicEditing',
//     'ItalicUI',
//     'BlockQuoteEditing',
//     'BlockQuoteUI',
//     'CKBoxEditing',
//     'CKBoxUploadAdapter',
//     'CKBoxUI',
//     'CKFinderEditing',
//     'Notification',
//     'CKFinderUI',
//     'CloudServicesCore',
//     'CloudServicesUploadAdapter',
//     'HeadingEditing',
//     'HeadingUI',
//     'ImageBlock',
//     'ImageBlockEditing',
//     'ImageEditing',
//     'ImageUtils',
//     'ImageTextAlternative',
//     'ImageTextAlternativeEditing',
//     'ImageTextAlternativeUI',
//     'ContextualBalloon',
//     'ImageInline',
//     'ImageInlineEditing',
//     'ImageCaptionEditing',
//     'ImageCaptionUtils',
//     'ImageCaptionUI',
//     'ImageStyleEditing',
//     'ImageStyleUI',
//     'WidgetToolbarRepository',
//     'ImageUploadEditing',
//     'ImageUploadUI',
//     'ImageUploadProgress',
//     'IndentEditing',
//     'IndentUI',
//     'LinkEditing',
//     'TwoStepCaretMovement',
//     'LinkUI',
//     'AutoLink',
//     'ListEditing',
//     'ListUI',
//     'MediaEmbedEditing',
//     'MediaEmbedUI',
//     'AutoMediaEmbed',
//     'TableEditing',
//     'TableUtils',
//     'TableUI',
//     'TableSelection',
//     'TableMouse',
//     'TableKeyboard',
//     'TableClipboard',
// ];
const getToolbar = (withImageUpload = false, withFontFeatures = false) => {
    const categorySection = ['heading'];
    const fontSection = ['bold', 'italic', ...(withFontFeatures ? ['fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor'] : [])];
    const insertSection = ['link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', ...(withImageUpload ? ['imageUpload'] : [])];
    const historySection = ['undo', 'redo'];
    return [...categorySection, '|', ...fontSection, '|', ...insertSection, '|', ...historySection];
};
const tablePlugins = [/*Table,*/ TableToolbar, TableProperties, TableCellProperties];

const advancedTableConfig = {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
};
const advancedImageConfig = {
    styles: {
        options: ['inline', 'alignLeft', 'alignRight', 'alignCenter', 'alignBlockLeft', 'alignBlockRight', 'block', 'side'],
    },
    toolbar: ['imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText' /*, '|', 'toggleImageCaption', 'imageTextAlternative'*/],
};

export const CKEditorDefaultConfig: EditorConfig = {
    image: {
        toolbar: ['imageStyle:alignBlockLeft', 'imageStyle:alignCenter', 'imageStyle:alignBlockRight'],
    },
    toolbar: getToolbar(false),
    plugins: [Essentials, Paragraph, Bold, Italic, Link, List, BlockQuote, Table, Heading, Image], //ClassicEditor.builtinPlugins,
    language: 'de',
};
/**
 * werden auch im additionalData vom Partner verwendet
 */
export type CKEditorDefaultConfigEmailFontSizeOptions = 'default' | '9pt' | '11pt' | '13pt' | '15pt' | '17pt' | '19pt' | '21pt';
const emailFontSizeOptions: Array<{ title: string; model: CKEditorDefaultConfigEmailFontSizeOptions} | 'default' > = [
    // { title: 'automatisch', model: '' },
    'default',
    { title: '9', model: '9pt' },
    { title: '11', model: '11pt' },
    { title: '13', model: '13pt' },
    { title: '15', model: '15pt' },
    { title: '17', model: '17pt' },
    { title: '19', model: '19pt' },
    { title: '21', model: '21pt' },
];
/**
 * werden auch im additionalData vom Partner verwendet
 */
export type CKEditorDefaultConfigEmailFontFamilyOptions =
    | 'default'
    | 'Arial, Helvetica, sans-serif'
    | 'Courier New, Courier, monospace'
    | 'Georgia, serif'
    | 'Lucida Sans Unicode, Lucida Grande, sans-serif'
    | 'Tahoma, Geneva, sans-serif'
    | 'Times New Roman, Times, serif'
    | 'Trebuchet MS, Helvetica, sans-serif'
    | 'Verdana, Geneva, sans-serif';
const emailFontFamilyOptions: Array<{ title: string; model: CKEditorDefaultConfigEmailFontFamilyOptions | '' } | CKEditorDefaultConfigEmailFontFamilyOptions> = [
    // { title: 'automatisch', model: '' },
    'default',
    'Arial, Helvetica, sans-serif',
    'Courier New, Courier, monospace',
    'Georgia, serif',
    'Lucida Sans Unicode, Lucida Grande, sans-serif',
    'Tahoma, Geneva, sans-serif',
    'Times New Roman, Times, serif',
    'Trebuchet MS, Helvetica, sans-serif',
    'Verdana, Geneva, sans-serif',
];
export const CKEditorDefaultConfigEmail: EditorConfig = {
    ...CKEditorDefaultConfig,
    plugins: [...(CKEditorDefaultConfig.plugins || []), Base64UploadAdapter, FileRepository, ImageUpload, ...tablePlugins, FontFamily, FontSize, FontColor, FontBackgroundColor],
    toolbar: getToolbar(true, true),
    table: advancedTableConfig,
    fontSize: {
        options: emailFontSizeOptions,
    },
    fontFamily: {
        options: emailFontFamilyOptions,
    },
};
export const CKEditorDefaultConfigPublicFileImages = (injector: Injector): EditorConfig => ({
    ...CKEditorDefaultConfig,
    plugins: [...(CKEditorDefaultConfig.plugins || []), FileRepository, ImageUpload, Image, ImageResize, ImageStyle, ImageToolbar, CKEditorPublicFileImageUploadAdapterPlugin(injector), CKEditorInjectAccessTokenPlugin(injector)],
    toolbar: getToolbar(true),
    image: advancedImageConfig,
});

@Injectable()
export class CKEditorPublicFileImageUploadAdapter implements UploadAdapter {
    private loader;
    constructor(private store$: Store<State>, private gatewayHttpService: HttpService, private http: HttpClient) {}
    set Loader(loader) {
        this.loader = loader;
    }
    upload(): Promise<UploadResponse> {
        if (!this.loader) {
            throw 'no loader initialised';
        }
        return this.loader.file.then((file) => {
            return firstValueFrom(this.store$.select(getToken)).then((token) => {
                let headers = new HttpHeaders();
                headers = headers.set('Authorization', 'Bearer ' + token);
                const payload = new FormData();
                // action.Payload.params.forEach((param, key) => {
                //     payload.append(key, param);
                // });
                payload.append('file', file);

                return firstValueFrom(
                    this.http
                        .put(this.gatewayHttpService.GetUrl('public-files', 'file'), payload, {
                            headers,
                            reportProgress: true,
                            observe: 'events',
                        })
                        .pipe(
                            tap((req) => {
                                if (req.type === HttpEventType.UploadProgress) {
                                    this.loader.uploadTotal = req.total;
                                    this.loader.uploaded = req.loaded;
                                }
                            }),
                            filter((req) => req.type === HttpEventType.Response),
                            map((res: HttpResponse<PublicFileType>) => {
                                if (res?.body?.id) {
                                    const entity = PublicFileEntityFromBackend(res.body).LastVersion;
                                    const ret = {
                                        default: this.gatewayHttpService.GetUrl(entity.DownloadPath /*+ '?token=' + token*/, 'file'),
                                    };
                                    // todo make responsive
                                    // if (entity.IsResizable) {
                                    //     Object.assign(ret, {
                                    //         '160': 'http://example.com/images/image–size-160.image.png',
                                    //         '500': 'http://example.com/images/image–size-500.image.png',
                                    //         '1000': 'http://example.com/images/image–size-1000.image.png',
                                    //         '1052': 'http://example.com/images/image–default-size.png'
                                    //     })
                                    // }
                                    return ret;
                                } else {
                                    throw 'unvalid response';
                                }
                            }),
                        ),
                );
            });
        });
    }
}

export function CKEditorPublicFileImageUploadAdapterPlugin(injector: Injector) {
    const childInjector = Injector.create({ providers: [{ provide: CKEditorPublicFileImageUploadAdapter }], parent: injector });

    return function cKEditorPublicFileImageUploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            const adapter = childInjector.get(CKEditorPublicFileImageUploadAdapter);
            adapter.Loader = loader;
            return adapter;
        };
    };
}
export function CKEditorInjectAccessTokenPlugin(injector: Injector, url?: string) {
    if (!url) {
        url = HttpService.getUrl('public-files', 'file');
    }
    const storeInjector = Injector.create({ providers: [{ provide: Store<State> }], parent: injector });
    const store = storeInjector.get(Store<State>);

    return function injectAccessTokenPlugin(editor: any) {
        const re = new RegExp(`${url}.+`);
        // https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/conversion/helpers/downcast.html
        // https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_conversion_conversion-Conversion.html
        editor.conversion.for('editingDowncast').add((dispatcher: DowncastDispatcher) =>
            // @ts-ignore
            dispatcher.on<`attribute:src${string}`>('attribute:src', (event, data: any, conversionApi: any) => {
                if (event.name !== 'attribute:src:imageInline' && event.name !== 'attribute:src:imageBlock') {
                    return;
                }

                if (!data.attributeKey) {
                    return;
                }

                conversionApi.consumable.consume(data.item, event.name);

                const viewWriter = conversionApi.writer;
                const viewElement = conversionApi.mapper.toViewElement(data.item);

                if (!viewElement) {
                    console.warn('toViewElement failed', data.item);
                    return;
                }

                let img = viewElement;
                // console.log("viewElement", viewElement);
                if (viewElement.name !== 'img') {
                    // imageBlock: <figure><img></figure>
                    // imageInline: <span><img></span>
                    const tmp = viewElement.getChild(0);
                    if (!tmp || !tmp.is('element') || tmp.name !== 'img') {
                        console.warn('figure/span has no img', viewElement, tmp);
                        return;
                    }
                    img = tmp;
                }
                if (data.attributeNewValue && typeof data.attributeNewValue === 'string') {
                    if (re.exec(data.attributeNewValue)) {
                        firstValueFrom(store.select(getToken)).then((token) => {
                            editor.editing.view.change(() => {
                                viewWriter.setAttribute(data.attributeKey || '', data.attributeNewValue + '?token=' + token, img);
                            });
                        });
                    } else {
                        editor.editing.view.change(() => {
                            viewWriter.setAttribute(data.attributeKey || '', data.attributeNewValue, img);
                        });
                    }
                } else {
                    viewWriter.removeAttribute(data.attributeKey, img);
                }
            }),
        );
    };
}
