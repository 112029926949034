import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, Subscription, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, shareReplay, skip, tap } from 'rxjs/operators';
import { EmployeeEntity, EmployeeStatusEnum } from "../../../../dave-data-module/entities/employee.entity";
import { EntityTypesEnum } from '../../../../dave-data-module/entities/entity-role.entity';
import { UserToCustomerEntity } from '../../../../dave-data-module/entities/user-to-customer.entity';
import { EmployeeResolver } from '../../../../dave-data-module/guards/employee.resolver';
import { EntityRoleResolver } from '../../../../dave-data-module/guards/entity-role.resolver';
import { JobSpecificationResolver } from '../../../../dave-data-module/guards/job-specification.resolver';
import { UserToCustomerResolver } from '../../../../dave-data-module/guards/user-to-customer.resolver';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { UserToCustomerActionTypes } from '../../../../dave-data-module/State/actions/customers.actions';
import { getEmployees, getEmployeesFetched } from '../../../../dave-data-module/State/selectors/employees.selectors';
import { getEntityRole, getEntityRoleDictionary, getEntityRoleFetched } from '../../../../dave-data-module/State/selectors/entity-role.selector';
import { getJobSpecificationDictionary, getJobSpecificationFetched } from '../../../../dave-data-module/State/selectors/job-specification.selector';
import { getUserToCustomers, getUserToCustomersFetched } from '../../../../dave-data-module/State/selectors/user-to-customer.selectors';
import { isNotNullOrUndefined, SearchQueriesDebounceTime, stringSearch } from '../../../../helper/helper';
import { DefaultFilterService, FilterApps, FilterTypes } from '../../../../services/default-filter.service';
import { LoadingService } from '../../../../services/loading.service';
import {
    ISelectedElement,
    ITableConfig,
    TableContentType,
} from '../../multi-select-table/multi-select-table.component';
import { SelectUserDialogBase } from '../select-user-dialog-base';
import { FilterOption } from '../../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import {
    BreakpointObserverService
} from '../../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';

export interface SelectUserCustomerDialogComponentDialogData {
    customerId: number;
}
interface ITableData {
    userId: number;
    jobSpecificationId: number;
    selectedForm: FormControl<boolean>;
    name: TableContentType<any> & { __typename: 'string' };
    jobSpecification: TableContentType<any> & { __typename: 'string' };
    role: TableContentType<{ id: number; label: string }> & { __typename: 'select' };
    employeeStaus: EmployeeStatusEnum | null;
}
interface SelectedUserType extends ISelectedElement {
    userToCustomer: UserToCustomerEntity;
}
@Component({
    selector: 'app-select-user-customer-dialog',
    templateUrl: './select-user-customer-dialog.component.html',
    styleUrls: ['./select-user-customer-dialog.component.scss'],
})
export class SelectUserCustomerDialogComponent extends SelectUserDialogBase implements OnInit, OnDestroy {
    public _FilterSettings$: Observable<FilterOption[]> = this.FilterSettings$.pipe(map(filters => filters.filter(f => f.Name !== FilterTypes.HideKollision)));
    public SearchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

    SelectedUsers$: Observable<SelectedUserType[]>;
    TableDataConfig$ = this.BS.MobileQuery.pipe(distinctUntilChanged(), map(mobile => {
        return<ITableConfig[]> [
            {
                header: 'Name',
                id: 'name',
                sortAccessor: (cell: TableContentType<any>&{__typename: "string"}) => cell.content || '',
            },
            {
                header: 'Jobbezeichnung',
                id: mobile ? null : 'jobSpecification',
                sortAccessor: (cell: TableContentType<any>&{__typename: "string"}) => cell.content || '',
            },
            {
                header: 'Rolle',
                id: 'role',
                width: mobile ? '10em' : '20em',
            },
        ].filter(c => !!c.id);
    }));
    FilteredTableData$: Observable<ITableData[]>;
    TableData$: Observable<ITableData[]>;

    private subscriptions: Subscription[] = [];
    public ContentResolved$: Observable<boolean>;
    EntityRolesForCustomer$ = this.store.select(getEntityRoleFetched).pipe(
        filter((v) => !!v),
        switchMap(() => this.store.select(getEntityRole)),
        map((roles) => roles.filter((role) => role.Entity === EntityTypesEnum.Customer)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA) public Dialogdata: SelectUserCustomerDialogComponentDialogData,
        private actions$: Actions,
        public LS: LoadingService,
        public BS: BreakpointObserverService,
        defaultFilter: DefaultFilterService,
        employeeRes: EmployeeResolver,
        jobRes: JobSpecificationResolver,
        userToCustomerRes: UserToCustomerResolver,
        entityRoleRes: EntityRoleResolver,
    ) {
        super(store, defaultFilter, FilterApps.SelectUserForCustomerPopup);
        // this._FilterSettings$ = this.FilterSettings$.pipe(map(filters => filters.filter(f => f.Name !== FilterTypes.HideKollision)));
        this.ContentResolved$ = combineLatest([
            this.store.select(getEmployeesFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        employeeRes.resolve();
                    }
                }),
            ),
            this.store.select(getJobSpecificationFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        jobRes.resolve();
                    }
                }),
            ),
            this.store.select(getUserToCustomersFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        userToCustomerRes.resolve();
                    }
                }),
            ),
            this.store.select(getEntityRoleFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        entityRoleRes.resolve();
                    }
                }),
            ),
        ]).pipe(
            map((values) => values.every((v) => v)),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        const employeesWithUserId$ = this.store.select(getEmployeesFetched).pipe(
            filter((v) => !!v),
            switchMap(() => this.store.select(getEmployees)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.SelectedUsers$ = combineLatest([
            this.store.select(getUserToCustomersFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getUserToCustomers)),
                map((u2e) => u2e.filter((u) => u.CustomerId === this.Dialogdata.customerId)),
            ),
            this.store.select(getEntityRoleFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getEntityRoleDictionary)),
            ),
            employeesWithUserId$.pipe(map((employees) => new Map<number, EmployeeEntity>(employees.map((e) => [e.UserId, e])))),
        ]).pipe(
            map(([u2c, roles, employeeMap]) => [
                ...u2c
                    .filter((u) => u.CustomerId === this.Dialogdata.customerId)
                    .map((u) => ({
                        userToCustomer: u,
                        label: (employeeMap.get(u.UserId)?.DisplayName || '') + (u.RoleId ? ' (' + (roles[u.RoleId]?.DisplayName || '') + ')' : ''),
                    })),
            ]),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.TableData$ = combineLatest([
            employeesWithUserId$,
            this.store.select(getJobSpecificationFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getJobSpecificationDictionary)),
            ),
            this.EntityRolesForCustomer$.pipe(map((roles) => roles.map((role) => ({ id: role.Id, label: role.DisplayName })))),
        ]).pipe(
            map(([employees, jobs, entityRoleOptions]) => {
                return employees
                    .filter((e) => e.UserId)
                    .map<ITableData>((e) => {
                        const job = e.JobSpecificationId && jobs[e.JobSpecificationId];
                        return {
                            userId: e.UserId,
                            selectedForm: new FormControl<boolean>(false),
                            jobSpecificationId: e.JobSpecificationId,
                            employeeStaus: e.EmployeeStatus,
                            name: {
                                __typename: 'string',
                                content: e.DisplayName,
                            },
                            jobSpecification: {
                                __typename: 'string',
                                content: job?.Name || '',
                            },
                            role: {
                                __typename: 'select',
                                options: entityRoleOptions,
                                optionLabel: (o) => o.label,
                                formControl: new FormControl<number | null>(
                                    job ? entityRoleOptions.find((er) => er.label === job.Name.trim())?.id || entityRoleOptions[0]?.id : entityRoleOptions[0]?.id,
                                    Validators.required,
                                ),
                            },
                        };
                    });
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.FilteredTableData$ = this.TableData$.pipe(
            switchMap((rows) =>
                this.FilterValues$.pipe(
                    map((filter) =>
                        filter[FilterTypes.JobSpecificationIds]?.length
                            ? rows.filter((r) => (!filter[FilterTypes.JobSpecificationIds]?.length || r.jobSpecificationId && filter[FilterTypes.JobSpecificationIds].includes(r.jobSpecificationId + '')) && !filter[FilterTypes.Status]?.length || filter[FilterTypes.Status].some(f => f.id === r.employeeStaus))
                            : rows,
                    ),
                ),
            ),
            switchMap((rows) =>
                this.SearchString.pipe(
                    debounceTime(SearchQueriesDebounceTime),
                    map((searchString) => (!searchString && rows) || rows.filter((r) => [r.name.content, r.jobSpecification.content].filter(isNotNullOrUndefined).some(s => stringSearch(s, searchString)))),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),

        )
    }
    ngOnInit(): void {
        this.subscriptions
            .push
            // this.TableData$.pipe(
            //     switchMap((data) =>
            //         this.SelectedUsers$.pipe(
            //             tap((u2es) =>
            //                 data.forEach((d) => {
            //                     const u2e = u2es.find((u) => d.userId === u.userToEvent.UserId);
            //
            //                     if (u2e && (d.selectedForm.enabled || !d.selectedForm.value)) {
            //                         d.selectedForm.setValue(true);
            //                         d.selectedForm.disable();
            //                     } else if (!u2e && (d.selectedForm.disabled || d.selectedForm.value)) {
            //                         d.selectedForm.setValue(false);
            //                         d.selectedForm.enable();
            //                     }
            //                 }),
            //             ),
            //         ),
            //     ),
            // ).subscribe(),
            ();
    }
    RemoveSelected(entity: SelectedUserType) {
        this.LS.startLoading('delete-user-to-customer');
        firstValueFrom(this.actions$.pipe(ofType(UserToCustomerActionTypes.RemoveOne, BaseActionTypes.ErrorAction))).then(() => {
            this.LS.endLoading('delete-user-to-customer');
        });
        this.store.dispatch(
            UserToCustomerActionTypes.Delete({
                Payload: {
                    id: entity.userToCustomer.Id,
                },
            }),
        );
    }
    Submit() {
        this.LS.startLoading('add-users-to-customer');
        firstValueFrom(this.TableData$).then((data) => {
            const actions = [];
            data.filter((d) => d.selectedForm.value).forEach((d) => {
                actions.push(
                    UserToCustomerActionTypes.Create({
                        Payload: {
                            userId: d.userId,
                            customerId: this.Dialogdata.customerId,
                            roleId: +d.role.formControl.value || undefined,
                        },
                    }),
                );
            });
            if (actions.length) {
                firstValueFrom(this.actions$.pipe(ofType(UserToCustomerActionTypes.UpdateMany, BaseActionTypes.ErrorAction), skip(actions.length - 1))).then(() => {
                    data.forEach(d => {
                        if (d.selectedForm.value) {
                            d.selectedForm.reset();
                        }
                    })
                    this.LS.endLoading('add-users-to-customer');
                });
            } else {
                this.LS.endLoading('add-users-to-customer');
            }
            actions.forEach((a) => this.store.dispatch(a));
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
