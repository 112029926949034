<header>
    <h1 mat-dialog-title>{{ComponentData.headlineSuffix ?  'Bearbeiter verwalten ' + ComponentData.headlineSuffix : 'Bearbeiter verwalten'}}</h1>
    <span class="spacer"></span>
</header>
<app-multi-select-table
    mat-dialog-content
    *ngIf="(ContentResolved$ | async) && (FilteredTableData$ | async); else loading"
    [TableData]="FilteredTableData$ | async"
    [TableDataConfig]="TableDataConfig$ | async"
    [Selected]="SelectedUsers$ | async"
    (RemoveSelected)="RemoveSelected($event)"
    (ClickSelected)="ClickUserToCommission($event)"
    Headline=""
    [SelectedHeadline]="'zugewiesene Bearbeiter'"
    SearchPlaceHolder="nach Bearbeitern suchen"
    (SearchStringChange)="SearchString.next($event)"
    [CustomChipTemplate]="customChipTemplate"
>
    <div class="additional-forms-row filter-wrapper">
        <mat-form-field appearance="fill">
            <mat-label>Zeitraum</mat-label>
            <mat-date-range-input [formGroup]="TimespanForm" [rangePicker]="timespanPicker">
                <input matStartDate placeholder="Von" formControlName="from" />
                <input matEndDate placeholder="Bis" formControlName="to" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="timespanPicker"></mat-datepicker-toggle>
            <mat-date-range-picker #timespanPicker></mat-date-range-picker>
            <fa-icon icon="times" matSuffix (click)="TimespanForm.reset()"></fa-icon>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>zusätzliche Rolle</mat-label>
            <mat-select [formControl]="EntityRoleForm">
                <mat-option *ngFor="let role of EntityRolesForCommissions$ | async" [value]="role">{{ role.Name }}</mat-option>
            </mat-select>
            <fa-icon icon="times" matSuffix (click)="EntityRoleForm.reset()"></fa-icon>
        </mat-form-field>
        <!--        <button app-button (click)="Submit()" [IsLoading]="LS.IsLoading$ | async" Color="green">Hinzufügen</button>-->
        <button
            app-round-button
            Inverted
            [matBadge]="FilterAmount$ | async"
            matTooltip="Filter"
            [matBadgeOverlap]="true"
            [matBadgeHidden]="!(FilterAmount$ | async)"
            (click)="filter.Open()"
        >
            <fa-icon icon="filter"></fa-icon>
        </button>
        <app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
    </div>
</app-multi-select-table>

<mat-dialog-actions *ngIf="Dialogdata && !PreventDialog">
    <div class="cancel-button-wrapper">
        <button app-button mat-dialog-close="" Inverted [IsLoading]="LS.IsLoading$ | async">Abbrechen</button>
    </div>
    <button app-button (click)="Submit()" [IsLoading]="LS.IsLoading$ | async" Color="green">Speichern</button>
</mat-dialog-actions>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<ng-template #customChipTemplate let-data="data">
    <div>
        {{ data.label }}
        <ng-container *ngFor="let timespan of data.timeSpans"
            ><br /><span><fa-icon icon="calendar"></fa-icon> {{ timespan }}</span></ng-container
        >
    </div>
</ng-template>

<ng-template #collisionCellTemplate let-data="data">
    <span class="collision-cell" *ngIf="data.conflicts.content$ | async as content"><span>{{ content.label }}</span> <fa-icon icon="eye" [matTooltip]="content.tooltip" matTooltipClass="mat-tooltip-white-space-pre"></fa-icon></span>
</ng-template>
