import { createAction, props } from '@ngrx/store';
import { DaveMutationCreateFolderUserArgs, DaveMutationDeleteFolderUserArgs } from '../../graphql-types';

export const FolderUserActionTypes = {
    AddFolderUserRequest: createAction(
        'data/folder-user/add/request',
        props<{ Payload: DaveMutationCreateFolderUserArgs }>()
    ),
    AddFolderUserSuccess: createAction(
        'data/folder-user/add/success',
    ),
    GetFolderUser: createAction(
        'data/folder-user/get',
        props<{ Payload: number }>()
    ),
    DeleteFolderUserRequest: createAction(
        'data/folder-user/delete/request',
        props<{ Payload: DaveMutationDeleteFolderUserArgs }>()
    ),
    DeleteFolderUserSuccess: createAction(
        'data/folder-user/delete/success',
    ),
};

