<h1 mat-dialog-title>Datanorm Import Einspielen</h1>
<div class="content" mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <app-select-search placeholder="Lieferant" required [formControl]="form.controls.customer" [Options]="customers$ | async" [CompareOptions]="compareById"></app-select-search>
        </mat-form-field>
        <p><mat-checkbox color="primary" [formControl]="form.controls.createMissingMaterials">NICHT-Vorhandene Materialien neu anlegen</mat-checkbox>
            <app-hint-icon
            hint="Die Zuschlagssätze werden für die Berechnung des VK - Preises verwendet"
            style="margin: 1rem"
            >
            </app-hint-icon>
        </p>
        <p>
            <mat-checkbox color="primary" [formControl]="form.controls.updateCost">Lieferanten Preise aktualisieren</mat-checkbox>
            <app-hint-icon
                hint="Es werden für alle Materialien die EK- und Listenpreise des Lieferanten aktualisiert. Wenn Ihr Lieferant Brutto Preise mitgibt, dann tragen Sie die Korrekte MwSt. ein, damit wir den Netto Preis berechnen können"
                style="margin: 1rem"
            >
            </app-hint-icon>
        </p>
        <mat-form-field>
            <input matInput placeholder="MwSt." type="number" [formControl]="form.controls.tax" />
            <span matSuffix>%</span>
        </mat-form-field>
        <p><mat-checkbox color="primary" [formControl]="form.controls.removeMissingMaterials">Lieferantenzuweisung von Artikeln, die der Lieferant nicht mehr anbietet, entfernen.</mat-checkbox></p>
        <p>
            <mat-checkbox color="primary" [formControl]="form.controls.updatePreference">Stammdaten aktualisieren Artikelname, Artikelbeschreibung</mat-checkbox>
            <app-hint-icon style="margin: 1rem" hint="Es werden alle Relevanten Stammdaten wie die Warengruppen, Artikelname und weitere aktualisiert. Aktualisierung der VK - Preise auf Basis des Zuschlagssatzes"></app-hint-icon>
        </p>
        <input type="file" hidden #filePicker (change)="onFilePicked($event)" [accept]="mimeType" />
    </form>
    <h2><strong>Bitte laden Sie hier ihre ZIP Datei mit den Datanorm Dateien hoch</strong></h2>
    <div class="upload-wrapper">
        <strong class="filename">{{ fileName$ | async }}</strong>
        <button app-button [matMenuTriggerFor]="menu" class="wide-button" [IsLoading]="ls.IsLoading$ | async"><fa-icon [icon]="form.value.documentId || form.value.file ? 'pencil-alt' : 'plus'"></fa-icon></button>
    </div>
    <mat-menu #menu>
        <button mat-menu-item (click)="onSelectFileClick()">Datei hinzufügen</button>
        <button mat-menu-item (click)="onUploadFileClick()">Datei hochladen</button>
    </mat-menu>
</div>
<mat-dialog-actions>
    <button app-button Inverted Color="cancel" mat-dialog-close="">abbrechen</button>
    <button app-button Color="green" (click)="onSubmitClick()" [Disabled]="form.invalid" [IsLoading]="ls.IsLoading$ | async">Dateien Importieren</button>
</mat-dialog-actions>
