import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Store} from '@ngrx/store';
import {filter, take, tap} from 'rxjs/operators';
import { PermissionEntity } from '../entities/permission.entity';
import { State } from '../State';
import { getPermissions } from '../State/selectors/permissions.selectors';
import { ResolverLoadPermissions } from '../State/actions/resolver.actions';

@Injectable({
    providedIn: 'root'
})
export class PermissionsResolver implements Resolve<PermissionEntity[]> {
    constructor(private store: Store<State>) {}

    resolve() {
        const value$ = this.store.select(getPermissions);

        value$
            .pipe(
                filter(value => !value),
                take(1),
                tap(() => {
                    if (localStorage.getItem('token') != null) {
                        this.store.dispatch(new ResolverLoadPermissions());
                    }
                })
            )
            .subscribe();

        return value$.pipe(
            filter(value => !!value),
            take(1)
        );
    }
}
