// tslint:disable: naming-convention
import { Action } from '@ngrx/store';
import { Moment } from "moment";

export enum NewEventActionTypes {
    SetEventTypeId = '[NewEvent] SetEventTypeId',
    SetContactPersonId = '[NewEvent] SetContactPersonId',
    SetCustomerId = '[NewEvent] SetCustomerId',
    SetName = '[NewEvent] SetName',
    SetHint = '[NewEvent] SetHint',
    SetAuthor = '[NewEvent] SetAuthor',
    SetPrivate = '[NewEvent] SetPrivate',
    SetDate = '[NewEvent] SetDate',
    SetEndDate = '[NewEvent] SetEndDate',
    SetStreet = '[NewEvent] SetStreet',
    SetPostalCode = '[NewEvent] SetPostalCode',
    SetCity = '[NewEvent] SetCity',
    SetCountry = '[NewEvent] SetCountry',
    SetStartTime = '[NewEvent] SetStartTime',
    SetEndTime = '[NewEvent] SetEndTime',
    SetNote = '[NewEvent] SetNote',
    SetFiles = '[NewEvent] SetFiles',
    SetCommission = '[NewEvent] SetCommission',
    ClearStore = '[NewEvent] ClearStore',
}

export class NewEventSetEventTypeId implements Action {
    readonly type = NewEventActionTypes.SetEventTypeId;

    constructor(public Payload: { EventTypeId: number }) {}
}

export class NewEventSetAuthor implements Action {
    readonly type = NewEventActionTypes.SetAuthor;

    constructor(public Payload: { Author: string }) {}
}
export class NewEventSetHint implements Action {
    readonly type = NewEventActionTypes.SetHint;

    constructor(public Payload: { Hint: string }) {}
}
export class NewEventSetContactPersonId implements Action {
    readonly type = NewEventActionTypes.SetContactPersonId;

    constructor(public Payload: { PersonId: number }) {}
}

export class NewEventSetCustomerId implements Action {
    readonly type = NewEventActionTypes.SetCustomerId;

    constructor(public Payload: { CustomerId: number }) {}
}
export class NewEventSetName implements Action {
    readonly type = NewEventActionTypes.SetName;

    constructor(public Payload: { Name: string }) {}
}

export class NewEventSetPrivate implements Action {
    readonly type = NewEventActionTypes.SetPrivate;

    constructor(public Payload: { Private: boolean }) {}
}

export class NewEventSetDate implements Action {
    readonly type = NewEventActionTypes.SetDate;

    constructor(public Payload: { Date: Moment }) {}
}

export class NewEventSetEndDate implements Action {
    readonly type = NewEventActionTypes.SetEndDate;

    constructor(public Payload: { EndDate: Moment }) {}
}

export class NewEventSetStreet implements Action {
    readonly type = NewEventActionTypes.SetStreet;

    constructor(public Payload: { Street: string }) {}
}

export class NewEventSetCity implements Action {
    readonly type = NewEventActionTypes.SetCity;

    constructor(public Payload: { City: string }) {}
}

export class NewEventSetCountry implements Action {
    readonly type = NewEventActionTypes.SetCountry;

    constructor(public Payload: { Country: string }) {}
}

export class NewEventSetPostalCode implements Action {
    readonly type = NewEventActionTypes.SetPostalCode;

    constructor(public Payload: { PostalCode: string }) {}
}

export class NewEventSetStartTime implements Action {
    readonly type = NewEventActionTypes.SetStartTime;

    constructor(public Payload: { StartTime: string }) {}
}

export class NewEventSetEndTime implements Action {
    readonly type = NewEventActionTypes.SetEndTime;

    constructor(public Payload: { EndTime: string }) {}
}

export class NewEventSetNote implements Action {
    readonly type = NewEventActionTypes.SetNote;

    constructor(public Payload: { Note: string }) {}
}

export class NewEventSetCommission implements Action {
    readonly type = NewEventActionTypes.SetCommission;

    constructor(public Payload: { CommissionId: number }) {}
}

// nicht benutzt
export class NewEventSetFiles implements Action {
    readonly type = NewEventActionTypes.SetFiles;

    constructor(public Payload: { Files: unknown }) {}
}

export class NewEventClearStore implements Action {
    readonly type = NewEventActionTypes.ClearStore;
}

export type NewEventActions =
    | NewEventSetEventTypeId
    | NewEventSetContactPersonId
    | NewEventSetPrivate
    | NewEventSetDate
    | NewEventSetEndDate
    | NewEventSetStreet
    | NewEventSetPostalCode
    | NewEventSetCity
    | NewEventSetCountry
    | NewEventSetStartTime
    | NewEventSetEndTime
    | NewEventSetNote
    | NewEventSetFiles
    | NewEventSetCommission
    | NewEventClearStore
    | NewEventSetCustomerId
    | NewEventSetName;
