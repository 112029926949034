import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { CustomLabelService } from "../../../services/custom-label.service";

interface DisplayCommission {
    Selected: boolean;
    Commission: CommissionEntity;
}

@Component({
    selector: 'app-comission-download',
    templateUrl: './commission-download.component.html',
    styleUrls: ['./commission-download.component.scss'],
})
export class CommissionDownloadComponent implements OnInit {
    public DisplayCommissions$: Observable<DisplayCommission[]>;
    protected selectedCommissions$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

    protected t = '';
    constructor(private store: Store, protected cls: CustomLabelService) {
        this.store.select(getToken).subscribe((t) => {
            this.t = t;
        });
    }

    ngOnInit(): void {
        this.DisplayCommissions$ = combineLatest([this.store.select(getCommissions), this.selectedCommissions$]).pipe(
            map(([commissions, selected]) =>
                commissions
                    .map((c) => {
                        return {
                            Selected: selected.includes(c.Id),
                            Commission: c,
                        };
                    })
                    .filter((c) => !c.Commission.Deleted),
            ),
        );
    }

    public ToggleSelected(id: number) {
        if (this.selectedCommissions$.getValue().includes(id)) {
            this.selectedCommissions$.next(this.selectedCommissions$.getValue().filter((i) => i !== id));
        } else {
            const value = Object.assign([], this.selectedCommissions$.getValue());
            value.push(id);
            this.selectedCommissions$.next(value);
        }
    }

    Download(): void {
        let d = {
            ids: this.selectedCommissions$.getValue().join(','),
            token: this.t,
        };
        let url = environment.gatewayApiUrl + '/commission_csv_download/?' + this.encodeQueryData(d);
        window.open(url, '_blank');
    }

    protected encodeQueryData(data) {
        const ret = [];
        for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
    }
}
