import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { GetFileMimeTypeText } from '../dave-data-module/entities/file.entity';
import { CustomerResolver } from '../dave-data-module/guards/customer.resolver';
import { getFetched$ } from '../dave-data-module/helper/helper';
import { AppGatewayService } from '../dave-data-module/services/app-gateway.service';
import { FileDataService } from '../dave-data-module/services/file-data.service';
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';
import { FilesActionTypes } from '../dave-data-module/State/actions/files.actions';
import { getCustomersFetched, getNotDeletedCustomers } from '../dave-data-module/State/selectors/customers.selectors';
import { SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData } from '../dave-file-explorer/components/select-folder-dialog/select-folder-dialog.component';
import { DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData, DaveSelectFileFromDmsComponentReturnData } from '../dave-select-file-from-dms/components/dave-select-file-from-dms/dave-select-file-from-dms.component';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { AppDialogService } from '../dave-utils-module/app-dialog-module/app-dialog.service';
import { SelectSearchOption } from '../dave-utils-module/select-search/components/select-search/select-search.component';
import { SelectSearchModule } from '../dave-utils-module/select-search/select-search.module';
import { appMatDialogDefaultConfig, DEFAULT_TAX, getMimeTypeMagicNumber } from '../helper/helper';
import { HintIconComponent } from '../hint-icon/hint-icon.component';
import { LoadingService } from '../services/loading.service';

export interface DatanormUploadDialogComponentDialogData {}

export type DatanormUploadDialogComponentDialogReturnData = undefined;

function requireOneControl(requiredControlNames: string[]) {
    return (formGroup: FormGroup) => {
        if (requiredControlNames.some((key) => formGroup.get(key).value)) {
            return null;
        }
        return { required: 'at least one of the items is required' };
    };
}
@Component({
    selector: 'app-datanorm-upload-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatInputModule, SelectSearchModule, ReactiveFormsModule, MatCheckboxModule, AppButtonModule, MatMenuModule, FontAwesomeModule, MatTooltipModule, HintIconComponent],
    templateUrl: './datanorm-upload-dialog.component.html',
    styleUrls: ['./datanorm-upload-dialog.component.scss'],
})
export class DatanormUploadDialogComponent {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
    };
    protected form = new FormGroup(
        {
            customer: new FormControl<SelectSearchOption<{ Id: number }>>(null, [Validators.required]),
            createMissingMaterials: new FormControl<boolean>(false),
            updateCost: new FormControl<boolean>(false),
            tax: new FormControl<number>(DEFAULT_TAX),
            removeMissingMaterials: new FormControl<boolean>(false),
            updatePreference: new FormControl<boolean>(false),
            file: new FormControl<File>(null),
            documentId: new FormControl<number>(null),
        },
        requireOneControl(['file', 'documentId']),
    );
    protected customers$ = getFetched$(this.store, getCustomersFetched, getNotDeletedCustomers, this.customerRes).pipe(
        map((customers) =>
            customers
                .filter((c) => true) // todo nur Lieferanten raus filtern
                .map<SelectSearchOption<{ Id: number }>>((c) => ({ Id: c.Id, optionLabel: c.DisplayName })),
        ),
    );
    protected fileName$ = this.form.valueChanges.pipe(
        distinctUntilChanged((a, b) => a.documentId === b.documentId && a.file === b.file),
        switchMap((changes) => {
            if (changes.documentId) {
                return this.fileDataService.GetFileById$(changes.documentId).pipe(map((f) => f.Name || 'unbenannte Datei'));
            } else if (changes.file) {
                return of(changes.file.name);
            }
            return of(null);
        }),
    );
    protected readonly mimeType = 'application/zip';
    protected readonly fileExtension = '.zip';
    protected readonly mimeTypeMagicNumber = '504b34';
    constructor(
        private store: Store<State>,
        private dialog: MatDialog,
        private fileDataService: FileDataService,
        private toastrService: ToastrService,
        private customerRes: CustomerResolver,
        private api: AppGatewayService,
        protected ls: LoadingService,
        private actions: Actions,
        private appDialog: AppDialogService,
        private dialogRef: MatDialogRef<DatanormUploadDialogComponent>,
    ) {}
    onFilePicked(event: Event) {
        const file = (event.target as HTMLInputElement).files[0];
        if (file && window.FileReader && window.Blob) {
            getMimeTypeMagicNumber(file).then((magicNumber) => {
                if (magicNumber === this.mimeTypeMagicNumber) {
                    this.form.patchValue({ file, documentId: null });
                } else if (file.type === this.mimeType) {
                    this.form.patchValue({ file, documentId: null });
                } else {
                    this.toastrService.error('Falscher Dateityp, nur ' + GetFileMimeTypeText(this.mimeType) + ' erlaubt');
                    this.form.patchValue({ file: null, documentId: null });
                }
            });
        }
        this.form.patchValue({ file, documentId: null });
    }
    onUploadFileClick() {
        const dialogRef = this.dialog.open<SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData>(SelectFolderDialogComponent, {
            ...SelectFolderDialogComponent.DefaultConfig,
            data: {
                ButtonText: 'HOCHLADEN',
                matTreeNodePaddingIndent: 10,
                uploadMode: true,
                synchronUpload: true,
                maxFilesUpload: 1,
                acceptedFiles: [this.mimeType, this.fileExtension].join(','),
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res?.synchronUploadedDocuments?.length) {
                if (res.synchronUploadedDocuments?.length > 1) {
                    console.error('mehr als eine datei ausgewählt');
                }
                this.form.patchValue({ file: null, documentId: res.synchronUploadedDocuments[0].Id });
            }
        });
    }
    protected compareById(a: { Id: number }, b: { Id: number }) {
        return a.Id === b.Id;
    }

    onSubmitClick() {
        if (this.form.invalid) {
            return;
        }
        const submitDatanorm = (versionId: number) => {
            this.api
                .Request({
                    DatanormStartDatanormImport: {
                        SupplierId: this.form.value.customer.Id + '',
                        UpdatePrices: this.form.value.updateCost,
                        DeletePrices: this.form.value.removeMissingMaterials,
                        UpdateBaseData: this.form.value.updatePreference,
                        CreateEditDataSets: this.form.value.createMissingMaterials,
                        TaxPercentageValue: this.form.value.tax,
                        SurchargeRate: undefined,
                        FileVersionId: versionId + '',
                    },
                })
                .then((res) => {
                    if (Object.keys(res?.Errors || {}).length > 0) {
                        throw res.Errors;
                    }
                    this.ls.endLoading('app-datanorm-upload-dialog-onSubmitClick');
                    const paragraphs: string[] = [];
                    if (+res.DatanormStartDatanormImport.CountCreated) {
                        paragraphs.push(res.DatanormStartDatanormImport.CountCreated + ' Materialien angelegt.');
                    }
                    if (+res.DatanormStartDatanormImport.CountDeleted) {
                        paragraphs.push(res.DatanormStartDatanormImport.CountDeleted + ' Materialien gelöscht.');
                    }
                    if (+res.DatanormStartDatanormImport.CountUpdated) {
                        paragraphs.push(res.DatanormStartDatanormImport.CountUpdated + ' Materialien aktualisiert.');
                    }
                    const brokenFile = +res.DatanormStartDatanormImport.CountinFile === 0;
                    return firstValueFrom(
                        this.appDialog.OpenConfirmationDialog({
                            heading: brokenFile ? 'Import fehlgeschlagen' : 'Import erfolgreich',
                            paragraph: brokenFile ? 'Es konnten keine Materialien in der Datei gefunden werden.' : paragraphs.length ? paragraphs.join('\n') : 'Es wurden keine Änderungen an den Materialien vorgenommen',
                            onlyOkButton: true,
                        }),
                    );
                })
                .then(() => this.dialogRef.close())
                .catch((err) => {
                    this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'Import fehlgeschlagen', Err: err } }));
                })
                .finally(() => {
                    this.ls.endLoading('app-datanorm-upload-dialog-onSubmitClick');
                });
        };
        this.ls.startLoading('app-datanorm-upload-dialog-onSubmitClick');
        if (this.form.value.file) {
            firstValueFrom(this.actions.pipe(ofType(FilesActionTypes.UploadFileBackendSuccess, FilesActionTypes.UploadFileBackendFailure))).then((action) => {
                if (action.type === FilesActionTypes.UploadFileBackendSuccess.type) {
                    submitDatanorm(action.Payload.GetLastVersion().Id);
                } else {
                    this.ls.endLoading('app-datanorm-upload-dialog-onSubmitClick');
                }
            });
            this.store.dispatch(FilesActionTypes.UploadFileBackendRequest({ Payload: { file: this.form.value.file, params: new Map([['hidden', 'true']]) } }));
        } else if (this.form.value.documentId) {
            firstValueFrom(this.fileDataService.GetFileById$(this.form.value.documentId)).then((file) => {
                submitDatanorm(file.GetLastVersion().Id);
            });
        }
    }

    onSelectFileClick() {
        this.dialog
            .open<DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData, DaveSelectFileFromDmsComponentReturnData>(DaveSelectFileFromDmsComponent, {
                ...DaveSelectFileFromDmsComponent.DefaultConfig,
                data: {
                    moveWarning: '',
                    preSelectedFiles: [],
                    acceptedMimeTypes: this.mimeType,
                    maxFiles: 1,
                    // folderId:,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result?.documents?.length) {
                    this.form.patchValue({ file: null, documentId: result.documents[0] });
                }
            });
    }
}
