import { createAction, props } from '@ngrx/store';
import { ChartOfAccountEntity } from '../../entities/chart-of-Account.entity';
import { DaveMutationChangeChartOfAccountArgs, DaveMutationCreateChartOfAccountArgs, DaveMutationDeleteChartOfAccountArgs, QueryChartOfAccountArgs } from '../../graphql-types';

const prefix = 'data/' + 'ChartOfAccount/';

export const ChartOfAccountActionTypes = {
    LoadAll: createAction(prefix + 'loadAll', props<{ Payload?: QueryChartOfAccountArgs }>()),
    UpdateAll: createAction(
        prefix + 'updateAll',
        props<{
            Payload: Array<ChartOfAccountEntity>;
            updateLatestUpdatedAt?: boolean;
        }>(),
    ),
    UpdateMany: createAction(
        prefix + 'updateMany',
        props<{
            Payload: Array<ChartOfAccountEntity>;
            updateLatestUpdatedAt?: boolean;
        }>(),
    ),
    ChangeChartOfAccount: createAction(prefix + 'change', props<{ Payload: DaveMutationChangeChartOfAccountArgs }>()),
    ChangeSuccess: createAction(prefix + 'Change/success', props<{ Payload: ChartOfAccountEntity }>()),
    ChangeFailure: createAction(
        prefix + 'Change/failure',
        props<{
            Payload: { Errors: any; ToasterMessage?: string };
        }>(),
    ),

    Add: createAction(prefix + 'add', props<{ Payload: DaveMutationCreateChartOfAccountArgs }>()),
    AddSuccess: createAction(prefix + 'Add/success', props<{ Payload: ChartOfAccountEntity }>()),
    AddFailure: createAction(prefix + 'Add/failure', props<{ Payload: { Errors: any; ToasterMessage?: string } }>()),

    Delete: createAction(prefix + 'delete', props<{ Payload: DaveMutationDeleteChartOfAccountArgs }>()),
    DeleteSuccess: createAction(prefix + 'removeOne', props<{ Payload: number }>()),
    DeleteFailure: createAction(prefix + 'Add/failure', props<{ Payload: { Errors: any; ToasterMessage?: string } }>()),
};
