import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
AgendaService,
DayService,
MonthAgendaService,MonthService,
ScheduleModule,WeekService,
WorkWeekService
} from '@syncfusion/ej2-angular-schedule';
import { SimplebarAngularModule } from 'simplebar-angular';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { SelectSearchModule } from '../dave-utils-module/select-search/select-search.module';
import { JobSpecificationOptionComponent } from '../job-specification-option/job-specification-option.component';
import { AppButtonModule } from './../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from './../dave-utils-module/app-filter-module/app-filter.module';
import {
ResourcePlanDialogComponent
} from "./components/resource-plan/resource-plan-dialog/resource-plan-dialog.component";
import { ResourcePlanComponent } from './components/resource-plan/resource-plan.component';
import { ShiftPlanDialogComponent } from './components/shift-plan/shift-plan-dialog/shift-plan-dialog.component';
import { ShiftPlanComponent } from './components/shift-plan/shift-plan.component';
import { DaveCalendarRoutingModule } from './dave-calendar-routing.module';

@NgModule({
    declarations: [ShiftPlanComponent, ShiftPlanDialogComponent, ResourcePlanComponent, ResourcePlanDialogComponent],
    imports: [
        CommonModule,
        DaveCalendarRoutingModule,
        MatMenuModule,
        FontAwesomeModule,
        MatButtonToggleModule,
        SimplebarAngularModule,
        AppFilterModule,
        MatCardModule,
        MatSidenavModule,
        AppButtonModule,
        MatTooltipModule,
        MatBadgeModule,
        MatButtonModule,
        MatIconModule,
        ScheduleModule,
        RouterModule,
        DaveLoadingModule,
        JobSpecificationOptionComponent,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        SelectSearchModule,
    ],
    exports: [ShiftPlanComponent, ResourcePlanComponent],
    providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService],
})
export class DaveCalendarModule {}
