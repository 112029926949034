import { CommonModule } from "@angular/common";
import { AfterViewChecked,AfterViewInit,ChangeDetectionStrategy,ChangeDetectorRef,Component,ElementRef,HostListener,Input,OnDestroy,OnInit,TemplateRef,ViewChild } from '@angular/core';
import { FormControl,ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatSort,MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute,ResolveData,Router,RouterLink,RouterLinkActive,RouterLinkWithHref } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Store } from '@ngrx/store';
import { isArray } from "chart.js/helpers";
import { TableVirtualScrollDataSource,TableVirtualScrollModule } from "ng-table-virtual-scroll";
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { debounceTime,filter,map,shareReplay,skip,startWith,switchMap,take,takeUntil,tap } from 'rxjs/operators';

import { AddReportButtonComponent } from "../../../../add-report-button/add-report-button.component";
import { CustomerEntity,CustomerStatusEnum,CustomerStatusTypeNames } from '../../../../dave-data-module/entities/customer.entity';
import { OfficeEntity } from '../../../../dave-data-module/entities/office.entity';
import { PersonEntity } from '../../../../dave-data-module/entities/person.entity';
import { Person2EntityEntityTypeEnum } from '../../../../dave-data-module/entities/person2entity.entity';
import { CustomerTypeResolver } from '../../../../dave-data-module/guards/customer-type.resolver';
import { CustomerResolver } from '../../../../dave-data-module/guards/customer.resolver';
import { OfficeResolver } from '../../../../dave-data-module/guards/office.resolver';
import { PartnerOfficeResolver } from '../../../../dave-data-module/guards/partner-office.resolver';
import { PartnerResolver } from '../../../../dave-data-module/guards/partner.resolver';
import { PersonResolver } from '../../../../dave-data-module/guards/person.resolver';
import { Person2EntityResolver } from '../../../../dave-data-module/guards/person2Entity.resolver';
import { PersonTypeResolver } from '../../../../dave-data-module/guards/personType.resolver';
import { State } from '../../../../dave-data-module/State';
import { customersFeatureKey } from '../../../../dave-data-module/State/reducers/customers.reducer';
import { officeFeatureKey } from '../../../../dave-data-module/State/reducers/office.reducer';
import { partnersFeatureKey } from '../../../../dave-data-module/State/reducers/patners.reducer';
import { personsFeatureKey } from '../../../../dave-data-module/State/reducers/person.reducer';
import { person2EntityFeatureKey } from '../../../../dave-data-module/State/reducers/person2Entity.reducer';
import {
    getNotDeletedCustomers,
    getCustomerTypeDictionary,
} from '../../../../dave-data-module/State/selectors/customers.selectors';
import { getOffices } from '../../../../dave-data-module/State/selectors/offices.selectors';
import { getOwnPartnerOffices, getPartner } from '../../../../dave-data-module/State/selectors/partners.selectors';
import { getPersonDictionary,getPersonsFetched } from '../../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities,getPerson2EntitiesFetched } from '../../../../dave-data-module/State/selectors/person2entity.selectors';
import {
getPersonTypesFetched
} from '../../../../dave-data-module/State/selectors/personType.selectors';
import { DaveDoubleIconModule } from "../../../../dave-double-icon/dave-double-icon.module";
import { ListCardDialogComponent,ListCardDialogComponentDialogData } from '../../../../dave-list-card/components/list-card-dialog/list-card-dialog.component';
import { NewReportDialogService } from '../../../../dave-reports/components/new-report-dialog/new-report-dialog.service';
import {
isNotNullOrUndefined,
SearchQueriesDebounceTime,
stringSearch,
TableColumnConfig,
uniqArray
} from '../../../../helper/helper';
import {
AllCommissionMeta,
AllDocumentEditorPageMeta,
AllEventsMeta,
AllOrdersMeta,
AllReportsMeta,
AllVideodokumentationenMeta,
BookingPageMeta,
CommissionMeta,
ContactBookMeta,
DocumentEditorPageMeta,
GeneratedDocumentsPageMeta,
GlobalStatisticsMeta,
HistoryMeta,
OrdersPageMeta,
ReportsPageMeta,
StatisticsPageMeta,
VideodokumentationNewlMeta,
VideodokumentationPageMeta
} from '../../../../helper/page-metadata';
import { PersonListEntryComponent } from "../../../../person-list-entry/person-list-entry.component";
import { DefaultFilterService,FilterApps,FilterTypes } from '../../../../services/default-filter.service';
import { LoadingService } from '../../../../services/loading.service';
import { AppButtonModule } from "../../../app-button-module/app-button.module";
import { AppFilterModule } from "../../../app-filter-module/app-filter.module";
import { FilterOption,FILTER_TYPE_SEARCH_MULTI_SELECT,IFilterTypeSearchMultiSelectValue } from '../../../app-filter-module/app-filter/app-filter.component';
import { FormControlTyped } from '../../../typings';
import { BreakpointObserverService } from '../../services/breakpoint-observer.service';
import { CustomerNameService } from '../../services/customer-name.service';
import { PermissionService } from '../../services/permission.service';
import {
    NewCommissionDialogComponent,
    NewCommissionDialogComponentDialogData
} from "../../../../new-commission-dialog/new-commission-dialog.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CustomLabelService } from "../../../../services/custom-label.service";
import { PartnerTypeEnum } from '../../../../dave-data-module/entities/partner.entity';
import { getCommissionTypes } from '../../../../dave-data-module/State/selectors/commissionType.selectors';


interface TableData {
    Status: CustomerStatusEnum;
    KdNr: string;
    Kunde: CustomerEntity;
    PLZ: string;
    Ort: string;
    Street: string;
    Branche: string;
    Zentrale: string;
    Office: OfficeEntity;
    partnerOfficeId: number | null;
    searchContents: string[];
}

@Component({
    selector: 'app-customer-list',
    templateUrl: './customer-list.component.html',
    styleUrls: ['./customer-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        MatListModule,
        FontAwesomeModule,
        RouterLinkWithHref,
        RouterLinkActive,
        MatSortModule,
        MatTooltipModule,
        MatButtonModule,
        AppFilterModule,
        MatInputModule,
        AppButtonModule,
        MatBadgeModule,
        DaveDoubleIconModule,
        AddReportButtonComponent,
        RouterLink,
        ReactiveFormsModule,
        PersonListEntryComponent,

        MatTableModule,
        ScrollingModule,
        TableVirtualScrollModule,
    ],
})
export class CustomerListComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
    public static readonly RequiredResolvers: ResolveData = {
        [personsFeatureKey]: PersonResolver,
        [customersFeatureKey]: CustomerResolver,
        partnerOffice: PartnerOfficeResolver,
        [officeFeatureKey]: OfficeResolver,
        customerType: CustomerTypeResolver,
        [partnersFeatureKey]: PartnerResolver,
        [person2EntityFeatureKey]: Person2EntityResolver,
    };
    @ViewChild('wrapperElement') WrapperElement: ElementRef;
    @ViewChild('personCell') personCell: TemplateRef<any>;
    public FilterValues$: BehaviorSubject<{
        [FilterTypes.PartnerOfficeId]: IFilterTypeSearchMultiSelectValue[];
    }> = new BehaviorSubject({
        [FilterTypes.PartnerOfficeId]: null,
    });
    Mobile$: Observable<boolean> = this.breakpointObserverService.MobileQuery.pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    @Input() ShowPersonButton = false;
    @Input() AdditionalButton: { label: string; path: any[] | string | null | undefined };
    @Input() ListStyle:
        | 'history'
        | 'kunden'
        | typeof ReportsPageMeta.Path
        | typeof GeneratedDocumentsPageMeta.Path
        | typeof StatisticsPageMeta.Path
        | typeof CommissionMeta.Path
        | typeof DocumentEditorPageMeta.Path
        | typeof OrdersPageMeta.Path
        | typeof VideodokumentationPageMeta.Path
        | typeof BookingPageMeta.Path = 'history';
    public SelectedId: number = null;
    public SearchForm: FormControlTyped<string> = new FormControl<string>('');
    public SelectedRowIndex: number;
    public CustomerStatusEnum = CustomerStatusEnum;
    public CustomerStatusTypeNames = CustomerStatusTypeNames;
    public ReportsPageMeta = ReportsPageMeta;

    /** Ob die Komponente unter einer bestimmten Breite ist */
    public IsSmallWidth$ = new BehaviorSubject(false);

    public ShowSearchBar$ = new BehaviorSubject(false);

    /** Konfiguration der Spalten der MatTable */
    public Columns: TableColumnConfig<TableData>[] = [
        { header: 'Kunde', name: 'Kunde' },
        { header: 'PLZ', name: 'PLZ' },
        { header: 'Ort', name: 'Ort' },
        { header: 'Straße', name: 'Street' },
        { header: 'Branche', name: 'Branche' },
        { header: 'Zentrale', name: 'Zentrale' },
    ];
    DefaultSort: keyof TableData = 'Kunde';

    /** Angezeigte Spalten der MatTable */
    public DisplayedColumns$: Observable<string[]>;

    /** Daten der Tabelle */
    public DataSource$: Observable<TableVirtualScrollDataSource<TableData>>;
    /** Der `MatSort` der Tabelle */
    @ViewChild(MatSort) private matSort?: MatSort;

    /** Das Suchfeld-Element */
    @ViewChild('input') private inputRef?: ElementRef<HTMLInputElement>;

    /** Gibt ein Mal einen Wert aus, wenn der ngAfterViewInit Hook ausgelöst wird */
    private afterViewInit$ = new BehaviorSubject<boolean>(false);

    /** Gibt ein Mal einen Wert aus, wenn die Komponente zerstört wird */
    private onDestroy$ = new Subject<void>();

    // Statistik-Daten und Auftrags-Daten (commission) für das HTML-Template
    StatsPagePath = StatisticsPageMeta.Path;
    GlobalStatsPath = GlobalStatisticsMeta.Path;
    CommissionPath = CommissionMeta.Path;
    AllCommissionPath = AllCommissionMeta.Path;
    AllVideodokumentationenPath = AllVideodokumentationenMeta.Path;
    NewVideoMeta = VideodokumentationNewlMeta;
    VideoMeta = VideodokumentationPageMeta;
    HistoryMeta = HistoryMeta;
    AllDocsPath = AllDocumentEditorPageMeta.Path;
    DocsPath = DocumentEditorPageMeta.Path;
    VideodokumentationPath = VideodokumentationPageMeta.Path;
    AllEventsPath = AllEventsMeta.Path;
    ReportsMeta = ReportsPageMeta;
    BookingMeta = BookingPageMeta;
    AllReportsMeta = AllReportsMeta;
    OrdersPageMeta = OrdersPageMeta;
    AllOrdersMeta = AllOrdersMeta;

    public ShowFilterButton$ = this.store.select(getOwnPartnerOffices).pipe(map((po) => po?.length > 1));
    public FilterSettings$: Observable<FilterOption[]> = this.store.select(getOwnPartnerOffices).pipe(
        filter(isNotNullOrUndefined),
        map((partners) => {
            // partnerValues['-'] = 'keinen auswählen';

            let partnerValues = partners.map((partner) => ({
                label: partner.DisplayName,
                id: partner.Id,
            }));

            return [
                {
                    Name: FilterTypes.PartnerOfficeId,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Unternehmen',
                    Icon: 'building',
                    Values: partnerValues,
                },
            ];
        }),
    );
    public FilterAmount$ = this.FilterValues$.pipe(
        map((val) => {
            let filterAmount = 0;
            for (const [key, value] of Object.entries(val)) {
                if (value?.length) {
                    filterAmount++;
                }
            }
            return filterAmount;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public PlaceHolder = new TableVirtualScrollDataSource<TableData>([]);
    constructor(
        private breakpointObserverService: BreakpointObserverService,
        private newReportDialogService: NewReportDialogService,
        private route: ActivatedRoute,
        private Router: Router,
        private store: Store<State>,
        public PS: PermissionService,
        public CS: CustomerNameService,
        public ActivatedRoute: ActivatedRoute,
        public LS: LoadingService,
        private defaultFilterService: DefaultFilterService,
        private dialog: MatDialog,
        private personToEntityRes: Person2EntityResolver,
        private personRes: PersonResolver,
        private personTypesRes: PersonTypeResolver,
        protected cls: CustomLabelService,
    ) {
        this.FilterValues$.pipe(takeUntil(this.onDestroy$), skip(2)).subscribe((val) => {
            if (Object.keys(val).length !== 0) {
                this.defaultFilterService.SetFilterByApp(FilterApps.CustomerList, val);
            }
        });
        this.DisplayedColumns$ = this.IsSmallWidth$.pipe(
            map((isTableSmallWidth) => (isTableSmallWidth ? ['Status', 'KdNr', 'Kunde'] : ['Status', 'KdNr', 'Kunde', 'Street', 'PLZ', 'Ort', 'Branche', 'Zentrale', this.ShowPersonButton ? 'person' : null].filter(isNotNullOrUndefined))),
            shareReplay({ refCount: true, bufferSize: 1 }), // wird mehrmals subscribed
        );

        this.DataSource$ = this.afterViewInit$.pipe(filter(v => v), map(() => new TableVirtualScrollDataSource<TableData>([]))).pipe(
            // this.matSort sollte hier immer gesetzt sein (da ngAfterViewInit gefeuert haben muss),
            // aber das weiß TypeScript natürlich nicht.
            tap((dataSource) => {
                this.matSort && (dataSource.sort = this.matSort);
            }),

            // Der Standard-`sortingDataAccessor` kommt mit numerischen Strings - wie der
            // Vertragsnummer - nicht klar und sortiert nicht. Workaround:
            tap(
                (dataSource) =>
                    (dataSource.sortingDataAccessor = (object, key) => {
                        switch (key) {
                            /*case 'Bewertung': // ausnahme für bewertungen
                                return object['Rating'];*/
                            case 'Kunde': // ausnahme für kunden name
                                return `${object['Kunde']['Name']}`.trim().toLowerCase();
                            case 'KdNr':
                                return `${object[key]}`.trim().toLowerCase();
                            default:
                                return object[key];
                        }
                    }),
            ),

            // Vergleichsfunktion zum Freitext-Filtern
            tap(
                (dataSource) =>
                    (dataSource.filterPredicate = (data, searchTerm) =>
                        [data.KdNr, data.Kunde.Name, data.Kunde.Description, data.Kunde.Homepage, data.Zentrale, data.Office?.Street + data.Office?.PostalCode + data.Office?.City, data.Branche, ...data.searchContents].some((value) =>
                            stringSearch(value || '', searchTerm),
                        )),
            ),
            switchMap((dataSource) =>
                combineLatest([
                    this.store.select(getOffices),
                    this.store.select(getNotDeletedCustomers),
                    this.store.select(getCustomerTypeDictionary),
                    this.store.select(getPersonDictionary),
                    this.store.select(getPerson2Entities).pipe(map((p2e) => p2e.filter((p) => p.EntityType === Person2EntityEntityTypeEnum.Customer))),
                    // this.afterViewInit$, // gibt erst etwas aus, nachdem Angular this.matSort gesetzt hat
                ]).pipe(
                    map(([offices, customers, customerTypes, persons, p2es /*, filter*/]) => {
                        if (this.ListStyle === StatisticsPageMeta.Path) {
                            customers = customers.filter((c) => c.CanEdit);
                        }
                        return customers.map<TableData>((customer) => {
                            const myOffice = offices.find((o) => o.CustomerId === customer.Id && o.IsHeadquarter);
                            let ctypes = customer.CustomerTypeIds.map((id) => customerTypes[id])
                                .filter(isNotNullOrUndefined)
                                .map((ct) => ct.Name)
                                .join(' ');

                            let routerLink: any[];
                            switch (this.ListStyle) {
                                case 'history':
                                    routerLink = ['/' + HistoryMeta.Path, customer.Id];
                                    break;
                                case 'kunden':
                                    routerLink = ['/kunden', customer.Id];
                                    break;
                                case StatisticsPageMeta.Path:
                                    routerLink = ['/' + StatisticsPageMeta.Path, customer.Id];
                                    break;
                                case CommissionMeta.Path:
                                    routerLink = ['/' + CommissionMeta.Path, customer.Id];
                                    break;
                                case DocumentEditorPageMeta.Path:
                                    routerLink = ['/' + DocumentEditorPageMeta.Path, customer.Id];
                                    break;
                                case ReportsPageMeta.Path:
                                    routerLink = ['/' + ReportsPageMeta.Path, customer.Id];
                                    break;
                                case GeneratedDocumentsPageMeta.Path:
                                    routerLink = ['/' + GeneratedDocumentsPageMeta.Path, customer.Id];
                                    break;
                                case VideodokumentationPageMeta.Path:
                                    routerLink = ['/' + VideodokumentationPageMeta.Path, customer.Id];
                                    break;
                                case OrdersPageMeta.Path:
                                    routerLink = ['/' + OrdersPageMeta.Path, customer.Id];
                                    break;
                                case BookingPageMeta.Path:
                                    routerLink = ['/' + BookingPageMeta.Path, customer.Id];
                                    break;
                                default:
                                    routerLink = ['/kunden', customer.Id];
                                    break;
                            }

                            return {
                                Status: customer.CustomerStatus,
                                KdNr: customer.CustomerNo || '--',
                                Kunde: customer,
                                PLZ: myOffice?.PostalCode,
                                Ort: myOffice?.City,
                                Street: myOffice?.Street,
                                Branche: ctypes,
                                Zentrale: myOffice?.PhoneNumber || '',
                                RouterLink: routerLink,
                                Office: myOffice,
                                partnerOfficeId: customer.PartnerOfficeId,
                                searchContents: uniqArray(p2es.filter((p2e) => p2e.EntityId === customer.Id).map((p2e) => p2e.PersonId))
                                    .map((id) => persons[id]?.DisplayName)
                                    .filter((f) => f),
                            };
                        });
                    }),
                    switchMap((data) =>
                        this.FilterValues$.pipe(
                            tap((filter) => {
                                dataSource.data = data.filter((c) => !filter.partnerOfficeId || !filter.partnerOfficeId?.length || filter.partnerOfficeId.some((po) => +po.id === c.partnerOfficeId));
                            }),
                            map(() => dataSource),
                        ),
                    ),
                ),
            ),

            switchMap((dataSource) =>
                this.SearchForm.valueChanges.pipe(
                    debounceTime(SearchQueriesDebounceTime),
                    startWith(this.SearchForm.value),
                    tap((searchTerm) => (dataSource.filter = searchTerm.trim().toLowerCase())),
                    map(() => dataSource),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        // Seiteneffekt: wenn das Suchfeld eingeblendet wird, fokussieren - asynchron, damit Angular
        // das Suchfeld erst anzeigt und danach fokussiert. Wenn ausgeblendet, den Suchwert löschen.
        this.ShowSearchBar$.pipe(takeUntil(this.onDestroy$)).subscribe((isSearching) => (isSearching ? setTimeout(() => this.inputRef && this.inputRef.nativeElement.focus()) : this.SearchForm.setValue('')));
    }

    ngAfterViewInit() {
        this.afterViewInit$.next(true);
    }

    ngOnInit() {
        if (this.Router.url.indexOf('history') > 0) {
            const idParts: string[] = this.Router.url.substr(this.Router.url.indexOf('history')).split('/');
            if (!!idParts && idParts.length > 0) {
                this.SelectedId = +idParts[0];
            }
        }
        if (this.Router.url.indexOf('kunden') > 0) {
            this.ListStyle = 'kunden';
        } else if (this.Router.url.includes(StatisticsPageMeta.Path)) {
            this.ListStyle = StatisticsPageMeta.Path;
        } else if (this.Router.url.includes(CommissionMeta.Path)) {
            this.ListStyle = CommissionMeta.Path;
        } else if (this.Router.url.includes(DocumentEditorPageMeta.Path)) {
            this.ListStyle = DocumentEditorPageMeta.Path;
        } else if (this.Router.url.includes(VideodokumentationPageMeta.Path)) {
            this.ListStyle = VideodokumentationPageMeta.Path;
        } else if (this.Router.url.includes(ReportsPageMeta.Path)) {
            this.ListStyle = ReportsPageMeta.Path;
        }
        combineLatest([this.FilterSettings$, this.defaultFilterService.GetFilterByApp$(FilterApps.CustomerList)])
            .pipe(take(1))
            .subscribe(([val, filterValues]) => {
                this.FilterValues$.next({
                    [FilterTypes.PartnerOfficeId]: isArray(filterValues[FilterTypes.PartnerOfficeId]) ? filterValues[FilterTypes.PartnerOfficeId] : [],
                });
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    ngAfterViewChecked() {
        this.sizing();
    }

    @HostListener('window:resize')
    sizing() {
        const smallWidth = this.WrapperElement?.nativeElement.offsetWidth < 640;

        if (this.IsSmallWidth$.value !== smallWidth) {
            this.IsSmallWidth$.next(smallWidth);
        }
    }

    NewInvoice() {
        this.newReportDialogService.OpenNewReportDialog();
    }
    public FilterCustomColumns(c: any) {
        return !(c.name === 'Kunde');
    }
    public CreateCommission() {
        firstValueFrom(
            this.store.select(getPartner).pipe(
                switchMap((partner) => (partner.PartnerTypeId === PartnerTypeEnum.TSB ? this.store.select(getCommissionTypes).pipe(map((cts) => cts.find((ct) => ct.AdditionalData?.IsDefault))) : of(null)))
            ),
        ).then((commissionType) => {
            this.dialog.open<NewCommissionDialogComponent, NewCommissionDialogComponentDialogData>(NewCommissionDialogComponent, {
                data: {
                    CreateCommissionPayload: {
                        auftragseingangDurchAuftraggeber: new Date().toDateString(),
                    },
                    ForceCommissionTypeId: commissionType?.Id,
                },
            });
        });
    }

    public highlight(rowData) {
        this.SelectedRowIndex = rowData.Kunde.Id;
    }
    public OpenPersonPopup(customer: CustomerEntity) {
        const dialogRef = this.dialog.open<ListCardDialogComponent<PersonEntity>, ListCardDialogComponentDialogData<PersonEntity>>(ListCardDialogComponent, {
            ...ListCardDialogComponent.DefaultConfig,
            data: {
                GetRouterLinkFunction: (person) => ['/' + ContactBookMeta.Path, person.Id],
                Headline: customer.AnsprechpartnerText(),
                EmptyText: 'Keine ' + customer.AnsprechpartnerText() + ' vorhanden',
                Data$: combineLatest([
                    this.store.select(getPersonsFetched).pipe(
                        tap((fetched) => {
                            if (!fetched) {
                                this.personRes.resolve();
                            }
                        }),
                    ),
                    this.store.select(getPerson2EntitiesFetched).pipe(
                        tap((fetched) => {
                            if (!fetched) {
                                this.personToEntityRes.resolve();
                            }
                        }),
                    ),
                    this.store.select(getPersonTypesFetched).pipe(
                        tap((fetched) => {
                            if (!fetched) {
                                this.personTypesRes.resolve();
                            }
                        }),
                    ),
                ]).pipe(
                    filter((fetched) => fetched.every((f) => f)),
                    switchMap(() => this.store.select(getPerson2Entities)),
                    map((p2e) => p2e.filter((p) => p.EntityId === customer.Id && p.EntityType === Person2EntityEntityTypeEnum.Customer)),
                    switchMap((p2e) => this.store.select(getPersonDictionary).pipe(map((persons) => p2e.map((p) => persons[p.PersonId])))),
                    map((persons) => persons.filter((p) => p && !p.Deleted).sort((a, b) => a.UpdatedAt.getTime() - b.UpdatedAt.getTime())),
                ),
                DisplayedCollums: ['DisplayName'],
                Clickable: true,
                HasSuffixButton: false,
                Search: false,
                CustomCellTemplates: {
                    DisplayName: this.personCell,
                },
            },
        });
    }
}
