import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResourcePlanComponent } from '../dave-calendar/components/resource-plan/resource-plan.component';
import { BookmarkResolver } from '../dave-data-module/guards/bookmark.resolver';
import { ClockInResolver } from '../dave-data-module/guards/clock-in.resolver';
import { CommentResolver } from '../dave-data-module/guards/comment.resolver';
import { CommissionResolver } from '../dave-data-module/guards/commission.resolver';
import { CommissionTypeResolver } from '../dave-data-module/guards/commissionType.resolver';
import { ComplaintTypeResolver } from '../dave-data-module/guards/complaintType.resolver';
import { CustomerSpecificationTypeResolver } from '../dave-data-module/guards/customerSpecificationType.resolver';
import { DamageFlowResolver } from '../dave-data-module/guards/damageflow.resolver';
import { DamageFlowTypeResolver } from '../dave-data-module/guards/damageFlowType.resolver';
import { EmployeeToVacationResolver } from '../dave-data-module/guards/employee-to-vacation.resolver';
import { EmployeeResolver } from '../dave-data-module/guards/employee.resolver';
import { EntityRoleResolver } from '../dave-data-module/guards/entity-role.resolver';
import { EventTypeResolver } from '../dave-data-module/guards/event-type.resolver';
import { EventResolver } from '../dave-data-module/guards/event.resolver';
import { GeneratedDocumentsResolver } from '../dave-data-module/guards/generatedDocuments.resolver';
import { GeneratedDocumentsTypeResolver } from '../dave-data-module/guards/generatedDocumentsType.resolver';
import { LicenseResolver } from '../dave-data-module/guards/license.resolver';
import { ManageUserListResolver } from '../dave-data-module/guards/manage-user-list.resolver';
import { PartnerListResolver } from '../dave-data-module/guards/partner-list.resolver';
import { PartnerOfficeResolver } from '../dave-data-module/guards/partner-office.resolver';
import { PendingChangesGuard } from '../dave-data-module/guards/pending-changes.guard';
import { PersonResolver } from '../dave-data-module/guards/person.resolver';
import { Person2EntityResolver } from '../dave-data-module/guards/person2Entity.resolver';
import { PersonTypeResolver } from '../dave-data-module/guards/personType.resolver';
import { ProductResolver } from '../dave-data-module/guards/product.resolver';
import { QMStatusResolver } from '../dave-data-module/guards/qMStatus.resolver';
import { SFDTTemplateResolver } from '../dave-data-module/guards/sfdt-template.resolver';
import { SharedCommissionsResolver } from '../dave-data-module/guards/sharedCommissions.resolver';
import { StatusFromBackofficeResolver } from '../dave-data-module/guards/statusFromBackoffice.resolver';
import { StatusFromSVResolver } from '../dave-data-module/guards/statusFromSv.resolver';
import { TagResolver } from '../dave-data-module/guards/tag.resolver';
import { TokenResolver } from '../dave-data-module/guards/token.resolver';
import { TransmissionResolver } from '../dave-data-module/guards/transmission.resolver';
import { UserToCommissionResolver } from '../dave-data-module/guards/user-to-commission.resolver';
import { UserResolver } from '../dave-data-module/guards/user.resolver';
import { ValidTokenGuard } from '../dave-data-module/guards/valid-token.guard';
import { ViewStyleSettingResolver } from '../dave-data-module/guards/viewStyleSetting.guard';
import { WorkDayResolver } from '../dave-data-module/guards/work-day.resolver';
import { CommissionTypesFeatureKey } from '../dave-data-module/State/reducers/commissionType.reducer';
import { CustomerListComponent } from '../dave-utils-module/dave-shared-components-module/components/customer-list/customer-list.component';
import { GroupListComponent } from '../group-list/group-list.component';
import { AllCommissionMeta, CommissionMeta, PersonMeta } from '../helper/page-metadata';
import { ResourceToCommissionPopupComponent } from '../resource-dispo/components/resource-to-commission-popup/resource-to-commission-popup.component';
import { ConstructionDiaryResolver } from './../dave-data-module/guards/construction-diary.resolver';
import { User2CommissionShiftResolver } from './../dave-data-module/guards/user-to-commission-shift.resolver';
import { CommissionListComponent } from './components/commission-list/commission-list.component';
import { DaveCommissionComponent } from './components/dave-commission.component';
import { DetailCommissionComponent } from './components/detail-commission/detail-commission.component';

const detailCommissionRoute: Routes = [
    {
        path: '',
        component: CommissionListComponent,
        resolve: {
            users: UserResolver,
            statusSv: StatusFromSVResolver,
            backofficeStatus: StatusFromBackofficeResolver,
            entityRoles: EntityRoleResolver,
            userToCommissions: UserToCommissionResolver,
            [CommissionTypesFeatureKey]: CommissionTypeResolver,
        },
        outlet: 'tab1',
    },
    {
        path: ':commissionId',
        data: { breadcrumb: 'commissionId' },
        resolve: {
            templates: SFDTTemplateResolver,
            damageFlows: DamageFlowResolver,
            commissionTypes: CommissionTypeResolver,
            statusSv: StatusFromSVResolver,
            backofficeStatus: StatusFromBackofficeResolver,
            qMStatus: QMStatusResolver,
            eventTypes: EventTypeResolver,
            generatedFileTypes: GeneratedDocumentsTypeResolver,
            genFiles: GeneratedDocumentsResolver,
            sharedCommissions: SharedCommissionsResolver,
            partners: PartnerListResolver,
            persons: PersonResolver,
            persontypes: PersonTypeResolver,
            complainttypes: ComplaintTypeResolver,
            damageFlowTypes: DamageFlowTypeResolver,
            transmissions: TransmissionResolver,
            licenses: LicenseResolver,
            products: ProductResolver,
            employees: EmployeeResolver,
            users: UserResolver,
            manageUser: ManageUserListResolver,
            employToVacations: EmployeeToVacationResolver,
            partnerOffices: PartnerOfficeResolver,
            bookmarks: BookmarkResolver,
            clockIns: ClockInResolver,
            workDays: WorkDayResolver,
            user2CommissionShifts: User2CommissionShiftResolver,
            user2Commissions: UserToCommissionResolver,
            viewStyleSetting: ViewStyleSettingResolver,
            constructionDiaries: ConstructionDiaryResolver,
            entityRoles: EntityRoleResolver,
            events: EventResolver,
            ...ResourcePlanComponent.RequiredResolvers,
            ...GroupListComponent.RequiredResolvers,
            ...ResourceToCommissionPopupComponent.RequiredResolvers,
        },
        children: [
            {
                path: '',
                component: DetailCommissionComponent,
                canDeactivate: [PendingChangesGuard],
                outlet: 'tab2',
            },
            {
                path: PersonMeta.Path,
                data: { breadcrumb: 'none' },
                loadChildren: () => import('../dave-person-module/dave-person.module').then((m) => m.DavePersonModule),
            },
        ],
    },
];
const routes: Routes = [
    {
        path: '',
        data: {
            breadcrumb: CommissionMeta.Breadcrumb,
            breadcrumbIcon: CommissionMeta.Icon,
        },
        component: DaveCommissionComponent,
        canActivate: [ValidTokenGuard],
        canActivateChild: [ValidTokenGuard],
        resolve: {
            token: TokenResolver,
            commissions: CommissionResolver,
            tags: TagResolver,
            eventTypes: EventTypeResolver,
            generatedFileTypes: GeneratedDocumentsTypeResolver,
            genFiles: GeneratedDocumentsResolver,
            sharedCommissions: SharedCommissionsResolver,
            partners: PartnerListResolver,
            persons: PersonResolver,
            Person2Entity: Person2EntityResolver,
            persontypes: PersonTypeResolver,
            complainttypes: ComplaintTypeResolver,
            damageFlowTypes: DamageFlowTypeResolver,
            CustomerSpecificationTypes: CustomerSpecificationTypeResolver,
            comments: CommentResolver,
            ...CustomerListComponent.RequiredResolvers,
        },
        children: [
            {
                path: AllCommissionMeta.Path,
                data: { breadcrumb: AllCommissionMeta.Breadcrumb },
                children: [...detailCommissionRoute],
            },
            {
                path: ':customerId',
                data: { breadcrumb: 'customerId' },
                children: [...detailCommissionRoute],
            },
        ],
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class DaveCommissionRoutingModule {}
