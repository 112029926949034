import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SimplebarAngularModule } from 'simplebar-angular';
import { DaveDoubleIconModule } from "../dave-double-icon/dave-double-icon.module";
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { AutofocusModule } from '../directives/autofocus/autofocus.module';
import { ListCardDialogComponent } from './components/list-card-dialog/list-card-dialog.component';
import { DaveListCardComponent } from './dave-list-card.component';

@NgModule({
    declarations: [DaveListCardComponent, ListCardDialogComponent],
    imports: [
        CommonModule,
        MatCardModule,
        AppButtonModule,
        SimplebarAngularModule,
        MatTableModule,
        FontAwesomeModule,
        RouterModule,
        MatTooltipModule,
        MatDialogModule,
        MatSortModule,
        DaveLoadingModule,
        MatExpansionModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        AutofocusModule,
        DaveDoubleIconModule,
    ],
    exports: [DaveListCardComponent],
})
export class DaveListCardModule {}
