import { Scan2DaveEmailEntity } from '../../entities/scan2daveEmail.entity';
import {
    DaveMutationChangeScan2DaveEmailArgs,
    DaveMutationCreateScan2DaveEmailArgs,
    DaveMutationDeleteScan2DaveEmailArgs,
} from '../../graphql-types';
import { createAction, props } from '@ngrx/store';

export const Scan2DaveEmailActionTypes = {
    UpdateScan2DaveEmails: createAction(
        'data/scan2DaveEmails/update',
        props<{ Payload: Array<Scan2DaveEmailEntity> }>(),
    ),
    ModifyScan2DaveEmail: createAction(
        'data/scan2DaveEmails/modify',
        props<{ Payload: DaveMutationChangeScan2DaveEmailArgs }>(),
    ),
    AddScan2DaveEmail: createAction(
        'data/scan2DaveEmails/add',
        props<{ Payload: DaveMutationCreateScan2DaveEmailArgs }>(),
    ),
    DeleteScan2DaveEmail: createAction(
        'data/Scan2DaveEmails/delete',
        props<{ Payload: DaveMutationDeleteScan2DaveEmailArgs }>(),
    ),
    RemoveScan2DaveEmail: createAction('data/Scan2DaveEmail/remove', props<{ Payload: number }>()),
};
