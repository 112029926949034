import {createAction, props} from '@ngrx/store';
import {EmailFolderEntity} from '../../entities/emailFolder.entity';

export const EmailFolderActionTypes = {
    LoadEmailFolders: createAction(
        'data/EmailFolders/load',
    ),
    UpdateEmailFolders: createAction(
        'data/EmailFolders/update',
        props<{ Payload: EmailFolderEntity[] }>(),
    )
}
