import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BookmarkState, BOOKMARK_KEY, selectAllBookmark, selectBookmarkEntities, selectBookmarkFetched } from '../reducers/bookmarks.reducer';

const selectState = createFeatureSelector<BookmarkState>(BOOKMARK_KEY);
const selectEntities = createSelector(selectState, selectBookmarkEntities);

export const getBookmarks = createSelector(selectState, selectAllBookmark);
export const getBookmarkFetched = createSelector(selectState, selectBookmarkFetched);
export const getBookmarkByEventId = (props: { id: number }) => createSelector(selectEntities, (dictionary) => props?.id && dictionary[props.id]);
export const getBookmarkLastFetched = createSelector(selectState, (state) => state.lastFetched);
export const getUnseenBookmarks = createSelector(getBookmarks, (bookmarks) => bookmarks.filter(t => !t.Event.LastSeenAt || t.Event.LastSeenAt < t.Event.UpdatedAt));
