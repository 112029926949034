<app-loading [Size]="48" *ngIf="!(eventTypesFetched$ | async)"></app-loading>
<div class="flex-row">
    <button [disabled]="disabled$ | async" *ngFor="let eventType of previewEventTypes$ | async" [matTooltip]="eventType.Name" mat-icon-button [color]="eventType.selected ? 'primary' : undefined" class="custom-border-hover" (click)="selectedEventTypeId$.next(eventType.Id)">
        <fa-icon *ngIf="eventType.ImagePath; else letter" [icon]="eventType.ImagePath | icon" size="lg"></fa-icon>

        <ng-template #letter>
            <span class="button-letter">{{ eventType.Name.slice(0, 1).toUpperCase() }}</span>
        </ng-template>
    </button>
    <button [Disabled]="disabled$ | async" app-round-button Inverted class="tailing-button" (click)="openPopup()"><fa-icon icon="plus"></fa-icon></button>
</div>
