<h2 matDialogTitle>{{ headline }}</h2>
<mat-dialog-content>
    <span class="mat-card-subtitle">Reihenfolge festlegen</span>
    <mat-form-field *ngIf="showFileNameForm" class="form-field">
        <mat-label>Dateiname</mat-label>
        <app-filename-input [formControl]="fileNameForm"></app-filename-input>
    </mat-form-field>
    <div cdkDropList #fileList="cdkDropList" [cdkDropListData]="documentsSorted" class="list" (cdkDropListDropped)="drop($event)" cdkDropListOrientation="vertical">
        <div *ngFor="let item of documentsSorted; let i = index" cdkDrag cdkDragPreviewContainer="parent" class="list-item">
            <span>{{i+1}}.</span>
            <app-small-file-card (FileClick)="onDocumentClick(item.Id)" [FileId]="item.Id"></app-small-file-card>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button app-button Inverted Color="cancel" mat-dialog-close="" [IsLoading]="ls.IsLoading$ | async">abbrechen</button>
    <button app-button Color="green" (click)="submitClick.emit()" [IsLoading]="ls.IsLoading$ | async">speichern</button>
</mat-dialog-actions>
