import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ChartOfAccountEntity } from '../../entities/chart-of-Account.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { ChartOfAccountActionTypes } from '../actions/chartOfAccount.actions';
import { PersonTypeActionTypes } from '../actions/personType.actions';

export const CHART_OF_ACCOUNT_KEY = 'chartOfAccount';

export interface ChartOfAccountState extends EntityState<ChartOfAccountEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;

}
const adapter = createEntityAdapter<ChartOfAccountEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const chartOfAccountReducer = createReducer(
    initialState,
    on(ChartOfAccountActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(ChartOfAccountActionTypes.DeleteSuccess, (state, { Payload }) =>
        adapter.removeOne(Payload, { ...state }),
    ),
    on(ChartOfAccountActionTypes.AddSuccess, (state, { Payload }) =>
        adapter.setOne(Payload, {
            ...state,
        }),
    ),
    on(ChartOfAccountActionTypes.ChangeSuccess, (state, { Payload }) =>
        adapter.setOne(Payload, {
            ...state,
        }),
    ),

);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllChartOfAccounts = selectAll;
export const selectChartOfAccountEntities = selectEntities;
export const selectChartOfAccountsFetched = (state: ChartOfAccountState)=> state.fetched;
export const selectChartOfAccountLatestUpdatedAt = (state: ChartOfAccountState) => state.latestUpdatedAt;

