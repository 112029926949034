import { createReducer, on } from '@ngrx/store';
import { SettingEntityAttributes, UserAdditionalData, UserEntity } from '../../entities/user.entity';
import { UsersActionTypes } from '../actions/users.actions';
import { CommissionActionTypes } from '../actions/commission.actions';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { CommissionEntity } from '../../entities/commission.entity';
import { CommissionState } from './commission.reducer';
import { FilterApps } from '../../../services/default-filter.service';

export const usersFeatureKey = 'users';

export interface UsersState extends EntityState<UserEntity> {
    user: UserEntity;
    manageUserList: UserEntity[];
    fetched: boolean;
    latestUpdatedAt: Date | null;
    settings: SettingEntityAttributes | null;
}
const adapter = createEntityAdapter<UserEntity>({
    selectId: Entity => Entity.Id,
});
export const initialUsersState: UsersState =  adapter.getInitialState({
    user: null,
    manageUserList: null,
    fetched: false,
    latestUpdatedAt: null,
    settings: null,
});

export const usersReducer = createReducer(
    initialUsersState,
    on(UsersActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(UsersActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(UsersActionTypes.UpdateUser, (state, { Payload }) => {
        return {
            ...state,
            user: Payload,
        }
    }),
    on(UsersActionTypes.UpdateUserSettings, (state, { Payload }) => {
        const settings = Payload ? {...Payload} : Payload;
        if (settings?.ListFilters) {
            settings.ListFilters = new Map(Object.entries(settings.ListFilters)) as Map<FilterApps, object>;
        }
        return {
            ...state,
            settings,
        }
    }),
    on(UsersActionTypes.UpdateManageUserList, (state, { Payload }) => ({
        ...state,
        manageUserList: Payload,
    })),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllUser = selectAll;
export const selectUserEntities = selectEntities;

export const selectUserFetched = (state: UsersState) => state.fetched;
export const selectSettingFetched = (state: UsersState) => !!state.settings;
export const selectUserLatestUpdatedAt = (state: UsersState) => state.latestUpdatedAt;
