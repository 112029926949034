import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { CommissionTypeEntity } from '../../../dave-data-module/entities/commissionType.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { DamageFlowEntity } from '../../../dave-data-module/entities/damageflow.entity';
import { PartnerSpecificationType } from '../../../dave-data-module/entities/partner.entity';
import { StatusFromBackofficeEntity } from '../../../dave-data-module/entities/statusFromBackoffice.entity';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { DamageFlowResolver } from '../../../dave-data-module/guards/damageflow.resolver';
import { StatusFromBackofficeResolver } from '../../../dave-data-module/guards/statusFromBackoffice.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCommissionTypes } from '../../../dave-data-module/State/selectors/commissionType.selectors';
import { getCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getDamageFlows, getDamageFlowsFetched } from '../../../dave-data-module/State/selectors/damageflow.selector';
import { getPartner } from '../../../dave-data-module/State/selectors/partners.selectors';
import { getStatusFromBackofficeById, getStatusFromBackofficeDictionary, getStatusFromBackofficeFetched } from '../../../dave-data-module/State/selectors/statusFromBackoffice.selectors';
import { getSetting, getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { CommissionNameService } from '../../../dave-utils-module/dave-shared-components-module/services/commission-name.service';
import { isNotNullOrUndefined, SearchQueriesDebounceTime, TableColumnConfig } from '../../../helper/helper';
import { CustomLabelService } from '../../../services/custom-label.service';

interface TableData {
    AuftragsNr: string;
    InterneNr: string;
    Sache: string;
    Ort: string;
    Datum: Date;
    Auftrag: CommissionEntity;
    backgroundColor: string | null;
    tooltip: string;
    StatusBackoffice: string;
    StartDate: Date;
    EndDate: Date;
}

const mapper = (input: { commissions: CommissionEntity[]; damageFlows: DamageFlowEntity[]; StatusBackoffice: StatusFromBackofficeEntity; customers: CustomerEntity[]; commissionTyp: CommissionTypeEntity[] }) => {
    // const finishedBoStateId = input.backofficeStates?.find(state => state.Name === StatusFromBackofficeEntityNames.abgeschlossen).Id
    const dataSource = new MatTableDataSource(
        input.commissions.map<TableData>((commission) => {
            const routerLink = ['auftraege', 'alle', commission.Id];
            const damageFlow = input.damageFlows.find((df) => df.CommissionId === commission.Id);
            if (!damageFlow) {
                console.error('DamageFlow not found, CommissionId: ' + commission.Id);
            }
            const now = new Date();
            now.setDate(now.getDate() + 2);
            let tooltip = input.customers?.find((c) => c.Id === commission.CustomerId)?.DisplayName || '';
            if (tooltip) {
                tooltip =
                    tooltip +
                    `
               `;
            }
            tooltip = commission.LastDeadline ? tooltip + 'Frist: ' + commission?.LastDeadline.getDate() + '.' + (commission.LastDeadline.getMonth() + 1) + '.' + commission.LastDeadline.getFullYear() : tooltip;
            return {
                Auftrag: commission,
                AuftragsNr: commission.Auftragsnummer || '-',
                InterneNr: commission.InterneNummer || '-',
                Ort: damageFlow?.City || '-',
                Sache: commission.Description || '-',
                Datum: commission.LastDeadline,
                RouterLink: routerLink,
                backgroundColor: commission.GetBackgroundColor(input.StatusBackoffice.IsCompleted, input.StatusBackoffice.Color),
                tooltip,
                StatusBackoffice: input.StatusBackoffice?.Name,
                StartDate: commission.StartDate || commission.PlannedStartDate,
                EndDate: commission.EndDate || commission.PlannedEndDate,
            };
        }),
    );
    dataSource.filterPredicate = (data, searchTerm) =>
        [data.InterneNr, data.AuftragsNr, data.StatusBackoffice, data.StartDate, data.EndDate, data.Datum, data.Ort]
            .filter((v) => !!v)
            .map((value) => `${value}`.trim().toLowerCase())
            .some((value) => value.includes(searchTerm));
    return dataSource;
};

@Component({
    selector: 'app-commission-dashboard',
    templateUrl: './commission-dashboard.component.html',
    styleUrls: ['./commission-dashboard.component.scss'],
})
export class CommissionDashboardComponent implements OnDestroy, AfterViewInit {
    @ViewChild('searchInput') searchInput: ElementRef;

    private afterViewInit$ = new Subject<void>();
    private subscriptions: Subscription[] = [];
    private getSettingsCommissionFilter$ = this.store.select(getSetting).pipe(
        map((s) => s?.FilterCommissions),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    );
    protected searchVisible = false;
    protected searchForm = new FormControl<string>('');

    public getDataSourceByBackofficeStatus(statusId: number): Observable<MatTableDataSource<TableData>> {
        return combineLatest([
            this.store
                .select(getCommissions)
                .pipe(filter(isNotNullOrUndefined))
                .pipe(map((cs) => cs?.filter((c) => !c.Deleted))),
            this.afterViewInit$,
            this.store.select(getDamageFlowsFetched).pipe(
                filter((v) => v),
                switchMap(() => this.store.select(getDamageFlows)),
            ),
            getFetched$(this.store, getStatusFromBackofficeFetched, getStatusFromBackofficeById({ id: statusId })),
            this.getSettingsCommissionFilter$,
            // combineLatest([
            this.store.select(getUser).pipe(
                map((u) => u.Id),
                distinctUntilChanged(),
            ),
            this.store.select(getCustomers),
            this.store.select(getCommissionTypes),
            // ]),
        ]).pipe(
            map(([commissions, viewInit, damageFlows, StatusBackoffice, settings, userId, customers, commissionTyp]) => {
                if (settings) {
                    commissions = commissions.filter((c) => c.AssignUserIds.includes(userId));
                }
                commissions = commissions
                    ?.filter((c) => c.StatusBackofficeId === statusId)
                    .slice()
                    .sort((a, b) => (b.UpdatedAt.getTime() > a.UpdatedAt.getTime() ? -1 : b.UpdatedAt.getTime() === a.UpdatedAt.getTime() ? 0 : 1));
                return mapper({
                    commissions,
                    damageFlows,
                    StatusBackoffice,
                    customers,
                    commissionTyp,
                });
            }),

            switchMap((dataSource) =>
                this.searchForm.valueChanges.pipe(
                    debounceTime(SearchQueriesDebounceTime),
                    startWith(''),
                    tap(() => (dataSource.filter = this.searchForm.value.trim().toLowerCase())),
                    map(() => dataSource),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }

    public Columns: TableColumnConfig<TableData>[] = [
        { header: 'InterneNr', name: 'InterneNr' },
        { header: 'AuftragsNr', name: 'AuftragsNr' },
        { header: 'Status', name: 'StatusBackoffice' },
        { header: 'Start', name: 'StartDate' },
        { header: 'Ende', name: 'EndDate' },
        { header: 'Frist', name: 'Datum' },
        { header: 'Ort', name: 'Ort' },
        { header: this.CNS.getName, name: 'Sache' },
    ];
    public Tabs$: Observable<{ label: string; dataSource$: Observable<MatTableDataSource<any>>; displayedColumns: Array<keyof TableData | 'chevron'> }[]> = combineLatest([
        this.store.select(getPartner).pipe(
            map((partner) => partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser),
            distinctUntilChanged(),
        ),
        this.store.select(getSetting),
        getFetched$(this.store, getStatusFromBackofficeFetched, getStatusFromBackofficeDictionary),
    ]).pipe(
        map(([isAppraiser, settings, statusBo]) => {
            const displayedColumns: Array<keyof TableData | 'chevron'> = isAppraiser ? ['InterneNr', 'AuftragsNr', 'Sache', 'Ort', 'chevron'] : ['InterneNr', 'Sache', 'AuftragsNr', 'StartDate', 'EndDate', 'chevron'];
            const stateBackoffice: StatusFromBackofficeEntity[] = [];
            if (settings?.CommissionDashboardComponentCommissionTypeIds?.length) {
                settings.CommissionDashboardComponentCommissionTypeIds.forEach((stateId) => stateBackoffice.push(statusBo[stateId]));
            } else {
                const fallback = ['bearbeitung', 'offen', 'neu'];
                fallback.forEach((name) => {
                    stateBackoffice.push(Object.values(statusBo).find((status) => status.Name.toLocaleLowerCase().includes(name)));
                });
                if (fallback.length !== stateBackoffice.filter(isNotNullOrUndefined).length) {
                    while (stateBackoffice.length) {
                        stateBackoffice.pop();
                    }
                    Object.values(statusBo)
                        .filter((status) => !status.IsCompleted)
                        .forEach((status) => stateBackoffice.push(status));
                }
            }
            return stateBackoffice.filter(isNotNullOrUndefined).map((s) => ({
                label: s.Name,
                dataSource$: this.getDataSourceByBackofficeStatus(s.Id),
                displayedColumns,
            }));
        }),
    );
    constructor(private store: Store<State>, commissions: CommissionResolver, damageFlows: DamageFlowResolver, statusFromBackoffice: StatusFromBackofficeResolver, public CNS: CommissionNameService, protected cls: CustomLabelService) {
        commissions.resolve();
        damageFlows.resolve();
        statusFromBackoffice.resolve();
        // this.subscriptions.push(this.BearbeitungDataSource$.subscribe(), this.FaelligDataSource$.subscribe(), this.OpenDataSource$.subscribe(), this.NewDataSource$.subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ngAfterViewInit(): void {
        this.afterViewInit$.next();
    }

    public FilterCustomColumns(c: TableColumnConfig<TableData> | { name: 'chevron' }) {
        return !(c.name === 'Datum' || c.name === 'StartDate' || c.name === 'EndDate' || c.name === 'chevron');
    }
    public FilterDateColumns(c: TableColumnConfig<TableData>) {
        return c.name === 'Datum' || c.name === 'StartDate' || c.name === 'EndDate';
    }
    onSearchBtnClick() {
        this.searchVisible = !this.searchVisible;
        this.searchForm.setValue('');
        if (this.searchVisible) {
            setTimeout(() => this.searchInput?.nativeElement?.focus(), 1);
        }
    }
}
