<div class="flag-container">
    <i
        tabindex="0"
        *ngFor="let country of AvailableCountries"
        [class.selected]="(Language$ | async) === country.code"
        [matTooltip]="country.label"
        (click)="Language$.next(country.code)"
        >{{ country.icon }}</i
    >
    <button app-round-button (click)="OpenDialog()"><fa-icon icon="plus"></fa-icon></button>
</div>
<div class="text-area">
    <section *ngFor="let template of ShownTextTemplates$ | async; first as isFirst; last as isLast">
        <p>{{ template?.Text || 'Sprache nicht verfügbar'}}</p>
        <div>
            <button mat-icon-button [disabled]="!template || isFirst" (click)="Move(template.TextTemplateId, 'up')"><fa-icon icon="chevron-up"></fa-icon></button>
            <button mat-icon-button [disabled]="!template || isLast" (click)="Move(template.TextTemplateId, 'down')"><fa-icon icon="chevron-down"></fa-icon></button>
        </div>
    </section>
</div>
