import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, formatDate } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { Status } from '@dave/types/dist/proto/erp/materialList';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, shareReplay, skip, switchMap, tap } from 'rxjs/operators';
import { MaterialListResolver } from '../../../dave-data-module/guards/material-list.resolver';
import { MaterialResolver } from '../../../dave-data-module/guards/material.resolver';
import { ResourceTypeResolver } from '../../../dave-data-module/guards/resource-dispo/resource-type.resolver';
import { State } from '../../../dave-data-module/State';
import { MaterialListActionTypes } from '../../../dave-data-module/State/actions/material-list.actions';
import { getQuantityTypeDictionary } from '../../../dave-data-module/State/selectors/accounting.selector';
import { getMaterialLists, getMaterialListsFetched } from '../../../dave-data-module/State/selectors/material-list.selector';
import { getMaterialDictionary, getMaterials, getMaterialsFetched } from '../../../dave-data-module/State/selectors/material.selector';
import { getResourceTypesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource-type.selectors';
import { isNotNullOrUndefined, SearchQueriesDebounceTime, stringSearch } from '../../../helper/helper';
import { LoadingService } from '../../../services/loading.service';
import { SelectMaterialPopupComponentDialogData } from '../select-material-popup/select-material-popup.component';

interface TableData {
    MaterialId: number;
    Name: string;
    Type: string | null;
    Available: number | null;
    AmountForm: FormControl<number>;
    CheckboxForm: FormControl<boolean>;
}

@Component({
    selector: 'app-select-material',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, MatChipsModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatSortModule, MatTableModule, ReactiveFormsModule, ScrollingModule, TableVirtualScrollModule],
    templateUrl: './select-material.component.html',
    styleUrls: ['./select-material.component.scss'],
})
export class SelectMaterialComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatSort) private matSort?: MatSort;
    @Input() ShowHeadline = true;
    @Input() ComponentData: SelectMaterialPopupComponentDialogData;
    public Selected$: Observable<{ id: number; label: string }[]>;
    private searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public DataSource$: BehaviorSubject<TableVirtualScrollDataSource<TableData>> = new BehaviorSubject(new TableVirtualScrollDataSource<TableData>([]));
    public PersonColumnHeaders = {
        Name: 'Name',
        Available: 'Verfügbar',
        Type: 'Einheit',
    };
    public SelectedRowIndex: number;
    public PersonAutoColumns = ['Type', 'Available'];
    public Multiselect = new BehaviorSubject(false);
    public Columns$ = this.Multiselect.pipe(map((m) => ['checkbox', 'Name', 'Type', 'Available', 'formControl']));
    private subscriptions: Subscription[] = [];

    constructor(
        private store: Store<State>,
        private materialListResolver: MaterialListResolver,
        private materialResolver: MaterialResolver,
        private resourceTypeResolver: ResourceTypeResolver,
        protected ls: LoadingService,
        private actions$: Actions,
    ) {}

    public Save() {
        this.ls.startLoading('app-select-material--Save');
        firstValueFrom(this.DataSource$).then((v) => {
            const selected = v.data.filter((data) => data.CheckboxForm.value && data.AmountForm.valid && data.AmountForm.value);
            if (selected.length) {
                firstValueFrom(this.actions$.pipe(ofType(MaterialListActionTypes.CreateSuccess, MaterialListActionTypes.CreateFailure), skip(selected.length - 1))).then(() => {
                    selected.forEach((data) => {
                        data.AmountForm.reset(null);
                    });
                    this.ls.endLoading('app-select-material--Save');
                });
                selected.forEach((data) => {
                    this.store.dispatch(
                        MaterialListActionTypes.CreateRequest({
                            Payload: {
                                MaterialId: data.MaterialId?.toString(),
                                Amount: data.AmountForm?.value,
                                CommissionId: this.ComponentData.CommissionId?.toString(),
                                Status: Status.OPEN,
                            },
                        }),
                    );
                });
            } else {
                this.ls.endLoading('app-select-material--Save');
            }
        });
    }

    RemoveSelected(id: number) {
        this.store.dispatch(MaterialListActionTypes.Delete({ Payload: { Id: String(id) } }));
    }

    public DoSearch(value: string | null) {
        return this.searchString.next(value);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ngOnInit(): void {
        this.Selected$ = this.store.select(getMaterialListsFetched).pipe(
            tap((fetched) => {
                if (!fetched) {
                    this.materialListResolver.resolve();
                }
            }),
            filter((v) => v),
            switchMap(() => this.store.select(getMaterialLists)),
            switchMap((ml) =>
                this.store.select(getMaterialsFetched).pipe(
                    filter((result) => result),
                    switchMap(() => combineLatest([this.store.select(getMaterialDictionary), this.store.select(getQuantityTypeDictionary)])),
                    map(([materials, qTypes]) => {
                        return ml
                            .filter((m) => m.CommissionId === this.ComponentData.CommissionId && !m.DeletedAt)
                            .map((m) => {
                                return {
                                    id: m?.Id,
                                    label:
                                        materials[m.MaterialId]?.Name +
                                        ' ( ' +
                                        formatDate(m?.CreatedAt, 'shortDate', 'de-DE') +
                                        ' ) ' +
                                        m?.Amount +
                                        ' ' +
                                        (materials[m.MaterialId]?.QuantityTypeId ? qTypes[materials[m.MaterialId].QuantityTypeId]?.Name : ''),
                                };
                            });
                    }),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(
            combineLatest([
                this.store.select(getMaterialsFetched).pipe(
                    tap((fetched) => {
                        if (!fetched) {
                            this.materialResolver.resolve();
                        }
                    }),
                    switchMap(() => this.store.select(getMaterials)),
                ),
                //this.store.select(getMaterials),
                this.store.select(getResourceTypesFetched).pipe(
                    tap((fetched) => {
                        if (!fetched) {
                            this.resourceTypeResolver.resolve();
                        }
                    }),
                    filter((v) => !!v),
                    switchMap(() => this.store.select(getQuantityTypeDictionary)),
                ),
            ])
                .pipe(
                    map(([materials, qTypes]) => {
                        return new TableVirtualScrollDataSource(
                            materials.map<TableData>((material) => {
                                const type = material.QuantityTypeId && qTypes[material.QuantityTypeId]?.Name;
                                return {
                                    MaterialId: material.Id,
                                    Name: material.Name,
                                    Type: type ? type : '',
                                    Available: isNotNullOrUndefined(material?.Amount) ? material.Amount : null,
                                    AmountForm: new FormControl<number | null>(null),
                                    CheckboxForm: new FormControl<boolean>(false),
                                };
                            }),
                        );
                    }),
                    tap(
                        (dataSource) =>
                            (dataSource.sortingDataAccessor = (object, key) => {
                                switch (key) {
                                    case 'Name':
                                        return `${object[key]}`.trim().toLowerCase();
                                    default:
                                        return object[key];
                                }
                            }),
                    ),

                    tap((dataSource) => (dataSource.filterPredicate = (data, searchTerm) => [data.Name, data.Type].some((value) => value && stringSearch(value, searchTerm)))),
                    tap((dataSource) => {
                        if (this.matSort) {
                            dataSource.sort = this.matSort;
                        } else {
                            console.warn('no matSort');
                        }
                    }),

                    switchMap((dataSource) =>
                        this.searchString.pipe(
                            debounceTime(SearchQueriesDebounceTime),
                            tap((searchTerm) => (dataSource.filter = searchTerm.trim().toLowerCase())),
                            map(() => dataSource),
                        ),
                    ),
                )
                .subscribe((v) => {
                    this.DataSource$.next(v);
                }),
        );
    }
}
