import { mergeMap, Observable, of, retryWhen, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';

const DEFAULT_MAX_RETRIES = 3;
const DEFAULT_BACKOFF = 1000;
const DEFAULT_DELAY = 0;

export function retryWithBackoff<T>(maxRetry = DEFAULT_MAX_RETRIES, backoffMs = DEFAULT_BACKOFF, delayMS: number = DEFAULT_DELAY) {
    let retries = maxRetry;

    return (src: Observable<T>) =>
        src.pipe(
            retryWhen((errors: Observable<T>) =>
                errors.pipe(
                    mergeMap((error) => {
                        if (retries-- > 0) {
                            const backoffTime = delayMS + (maxRetry - retries) * backoffMs;
                            return of(error).pipe(delay(backoffTime));
                        }
                        return throwError(() => new Error(`Tried ${maxRetry} times without success. Giving up.`));
                    }),
                ),
            ),
        );
}
