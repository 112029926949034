import { Directive, Input, TemplateRef } from '@angular/core';

/** strukturelle Marker-Direktive für die Verwendung mit `DetailViewTemplateComponent` */
@Directive({
    selector: '[appDetailViewLeftContent]',
})
export class DetailViewLeftContentDirective {
    // damit der Angular Language Service per Strg+Click auf die Direktive hierher findet
    @Input() appDetailViewLeftContent: undefined | '';

    // damit der Angular Language Service das hier als strukturelle Direktive versteht
    constructor(private templateRef: TemplateRef<void>) {}
}
