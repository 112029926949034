import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {DaveActions} from '../actions/actions';
import {HttpService} from '../../services/http.service';
import {BaseActionTypes} from '../actions/base.actions';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {State} from '../index';
import {Store} from '@ngrx/store';
import {EmailFolderActionTypes} from '../actions/email-folder.actions';
import {EmailFolderEntityFromBackend} from '../../entities/emailFolder.entity';


enum ErrorCodes {
    Load = 'E-Mail - Ordner Abrufen fehlgeschlagen',
}

@Injectable()
export class EmailFolderEffects {

    LoadEmailFolders = createEffect(() => this.actions$.pipe(
        ofType(EmailFolderActionTypes.LoadEmailFolders),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
        query{
          emailFolder{
            deletedAt
            emailConnectionId
            folderPath
            id
            name
            parentId
            type
            userId
            emailCount
            emailUnreadCount
          }
        }`;
            return this.gatewayHttpService
                .graphQl({query: queryString}, {token: store.base.token, retry: true })
                .pipe(
                    map(res =>
                        res && res.emailFolder
                            ? EmailFolderActionTypes.UpdateEmailFolders({
                                Payload: res.emailFolder.map(val => EmailFolderEntityFromBackend(val))
                            })
                            : BaseActionTypes.ErrorAction({
                                Payload: {ToasterMessage: ErrorCodes.Load}
                            })
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught
                                }
                            })
                        )
                    )
                );
        })
    ));

    constructor(private actions$: Actions<DaveActions>,
                private store$: Store<State>,
                private gatewayHttpService: HttpService) {

    }
}
