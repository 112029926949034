import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { DamageFlowActionTypes } from '../State/actions/damageflow.actions';
import { getDamageFlowLatestUpdatedAt, getDamageFlowsFetched } from '../State/selectors/damageflow.selector';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class DamageFlowResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getDamageFlowsFetched, getDamageFlowLatestUpdatedAt, DamageFlowActionTypes.Load, store);
    }
}
