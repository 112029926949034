<h2 mat-dialog-title>
    <fa-icon icon="newspaper"></fa-icon>
    Neuigkeiten
        <button app-round-button matTooltip="Neuigkeit anlegen" Inverted *ngIf="Dialogdata?.PartnerRestricted && (PS.Has(PS.Permission.CreateNews) | async)" (click)="SetEditor()"><fa-icon icon="plus"></fa-icon></button>
</h2>
<mat-dialog-content class="wrapper">
    <section class="left scrollbar" *ngIf="Partner$ | async as partner">
        <ul class="clickable-list">
            <ng-container *ngFor="let n of Newses$ | async">
                <ng-container *ngIf="(n.PartnerId === null && Dialogdata?.PartnerRestricted === false) || (n.PartnerId === partner.Id && Dialogdata?.PartnerRestricted === true)">
                    <li class="clickable-list-item" [class.new]="!n.HasBeenRead" [class.selected]="n.Id == News?.Id" >
                        <a
                            class="clickable-list-item-link"
                            mat-button
                            (click)="SelectNews(n)"
                        >
                            <div class="clickable-list-item-content">
                                <p>
                                    {{ n.Headline }}
                                    <span class="date">{{n.CreatedAt.toLocaleDateString()}}</span>
                                </p>
                                <fa-icon icon="bookmark" *ngIf="!n.HasBeenRead"></fa-icon>
                            </div>
                        </a>
                    </li>
                    <mat-divider></mat-divider>
                </ng-container>
            </ng-container>
        </ul>
    </section>
    <section class="right" *ngIf="!News && !Editing"></section>
    <section class="right news-content" *ngIf="News || Editing">
        <div class="header">
            <h1 *ngIf="!Editing">{{News.Headline}}</h1>
            <mat-form-field *ngIf="Editing">
                <mat-label>Titel</mat-label>
                <input matInput [formControl]="TitleForm" required>
            </mat-form-field>
            <div class="buttons">
                <button app-round-button matTooltip="Bearbeiten" (click)="SetEditor(News)" *ngIf="!Editing && News?.UserId === (UserId$ | async)"><fa-icon icon="pencil-alt"></fa-icon></button>
                <button app-round-button matTooltip="Zurücksetzen" *ngIf="Editing" (click)="SelectNews(News)"><fa-icon icon="undo"></fa-icon></button>
                <button app-round-button matTooltip="Speichern" *ngIf="Editing" (click)="SaveNews()" [Disabled]="TitleForm.invalid || !EditorContent" Color="green"><fa-icon icon="save"></fa-icon></button>
                <button app-round-button matTooltip="Löschen" [Color]="'red'" Inverted (click)="DeleteNews(News.Id)" *ngIf="Editing && News?.UserId === (UserId$ | async)"><fa-icon icon="trash"></fa-icon></button>
            </div>
        </div>
        <app-ck-editor *ngIf="CKEditorConfig as config" [(Content)]="EditorContent" [Readonly]="!Editing" [CKEditorConfig]="config"></app-ck-editor>
<!--        <div class="scrollbar flex-grow ck-content" *ngIf="!Editing; else editor" [innerHTML]="trustedNewsText"></div>-->
    </section>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button app-button Inverted class="button" matDialogClose>Schließen</button>
</mat-dialog-actions>
<ng-template #editor>
<!--    <ckeditor-->
<!--        [(ngModel)]="EditorContent"-->
<!--        [config]="CKEditorConfig"-->
<!--        [editor]="Editor"-->
<!--    ></ckeditor>-->
    <app-ck-editor *ngIf="CKEditorConfig as config" [(Content)]="EditorContent" [CKEditorConfig]="config"></app-ck-editor>
</ng-template>
