import {
    PUBLIC_FILE_KEY,
    PublicFileState,
    selectAllPublicFile,
    selectPublicFileEntities, selectPublicFileFetched, selectPublicFileLatestUpdatedAt,
} from '../reducers/public-file.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';


const selectState = createFeatureSelector<PublicFileState>(PUBLIC_FILE_KEY);

export const getPublicFileDictionary = createSelector(selectState, selectPublicFileEntities);
export const getPublicFiles = createSelector(selectState, selectAllPublicFile);
export const getPublicFilesActive = createSelector(getPublicFiles, e => e.filter(v => !v.DeletedAt));
export const getPublicFilesFetched = createSelector(selectState, selectPublicFileFetched);
export const getPublicFileLatestUpdatedAt = createSelector(selectState, selectPublicFileLatestUpdatedAt);

export const getPublicFileById = (props: { id: number }) => createSelector(getPublicFileDictionary, (dictionary) => props?.id && dictionary[props.id]);
