import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'app-double-icon',
    template: `
        <fa-icon class="icon" *ngIf="Icon" [icon]="Icon"></fa-icon>
        <fa-icon class="icon2" *ngIf="Icon2" size="xs" [icon]="Icon2"></fa-icon>
    `,
    styles: [],
})
export class DoubleIconComponent {
    @Input() Icon: IconProp;
    @Input() Icon2: IconProp;
}


// Hallo ich bins
