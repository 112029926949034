import { BooleanInput, coerceBooleanProperty, coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    ViewEncapsulation,
} from '@angular/core';

// Zu den @Host-Dekoratoren:
//
// "disabled" muss an das HTML-Attribut des Host-Elements und nicht an dessen gleichnamige
// JavaScript-Property gebunden werden, da zum einen Anchor-Tags keine disabled-Property haben
// und zum anderen Angular bei button-Elementen sofort Property Binding in JavaScript
// ausführt, ohne das disabled-Attribut im HTML zu setzen.
//
// null und undefined sind die einzigen Werte, mit denen das disabled-Attribut auf button-Elementen
// deaktiviert (null) bzw. gar nicht erst von Angular hinzugefügt wird (undefined);
// das heißt, dass auch Leerstrings und der String "false" den Button deaktivieren würden
//
// ngAcceptInputType_* umgeht das Problem, dass die in Angular 9 eingeführten, strikteren
// template type checks bei Boolean-Inputs auch tatsächlich nur Booleans zulassen.
// Oftmals ist aber gewünscht, dass nicht explizit ein Boolean übergeben werden muss
// (<button disabled /> statt <button [disabled]="true" />), das käme aber <button disabled="" />
// gleich und wird von TypeScript abgefangen. Mit ngAcceptInputType_* kann ein anderer Typ
// angegeben werden, der beim template type checking verwendet wird (z.B. boolean | "").

/** Attributselektoren und gleichzeitig CSS-Klassen der einzelnen Button-Arten */
const BUTTON_TYPES = ['app-button', 'app-round-button', 'app-stroked-round-button'];
export type AppButtonColor = 'red' | 'green' | 'highlighted' | 'cancel';
@Component({
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    // host: { '[style.cursor]': 'IsLoading' },
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: `button[app-button], button[app-round-button], button[app-stroked-round-button],
                    a[app-button],      a[app-round-button],      a[app-stroked-round-button]`,
    templateUrl: './app-button.component.html',
    styleUrls: ['./app-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppButtonComponent {
    /** Basis-CSS-Klasse, die alle Buttons bekommen */
    @HostBinding('class.app-button-base')
    readonly ShowBaseClass = true;

    /** CSS-Klasse, die dem Komponentenselektor entspricht - für entsprechendes Styling */
    private readonly buttonType?: string;

    /** Ob der Button disabled ist */
    @Input()
    @HostBinding('attr.disabled')
    get Disabled() {
        return this.disabled || this.IsLoading || undefined;
    }
    set Disabled(value) {
        this.disabled = coerceBooleanProperty(value);
    }
    private disabled = false;
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_Disabled: BooleanInput;

    /** Ob der Button spezielles Styling erhalten soll */
    @Input()
    @HostBinding('class.app-button-active')
    get Active() {
        return this.active || undefined;
    }
    set Active(value) {
        this.active = coerceBooleanProperty(value);
    }
    private active = false;
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_Active: BooleanInput;

    /** Ob die Farben des Buttons invertiert werden sollen */
    @Input()
    @HostBinding('class.app-button-inverted')
    get Inverted() {
        return this.inverted || undefined;
    }
    set Inverted(value) {
        this.inverted = coerceBooleanProperty(value);
    }
    private inverted = false;
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_Inverted: BooleanInput;

    /** Ob hellere Farben zum Theming verwendet werden sollen */
    @Input()
    @HostBinding('class.app-button-lighter')
    get Lighter() {
        return this.lighter || undefined;
    }
    set Lighter(value) {
        this.lighter = coerceBooleanProperty(value);
    }
    private lighter = false;
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_Lighter: BooleanInput;

    /** Button-Farbe */
    @Input()
    Color?: AppButtonColor;
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_Color: AppButtonColor/* | null | false | '' | 0*/;

    /** Loading Status Indikator */
    @HostBinding('class.is-loading')
    @Input()
    public IsLoading = false;

    /** Dynamische CSS-Klassen */
    // An class kann nur ein Wert gebunden werden, daher müssen alle dynamischen
    // Klassen hier zusammen als String, Array oder Objekt übergeben werden -
    // nicht verwechseln mit class.*, das eine fixe Klasse hinzufügt oder entfernt
    @HostBinding('class')
    get Classes() {
        return [this.buttonType, this.Color && `app-button-color-${this.Color}`].join(' ');
    }

    /** Tabindex des Buttons; wird automatisch gesetzt */
    // Anchor-Tags sind ohne diesen Workaround nicht wie button-Elemente fokussierbar
    @Input()
    @HostBinding('attr.tabindex')
    get Tabindex() {
        return this.disabled ? -1 : this.tabindex;
    }
    set Tabindex(value) {
        this.tabindex = coerceNumberProperty(value);
    }
    private tabindex = 0;
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_Tabindex: NumberInput;

    constructor(elementRef: ElementRef<HTMLElement>) {
        this.buttonType = BUTTON_TYPES.find(type => elementRef.nativeElement.hasAttribute(type));
    }

    /** Fängt click-events auf Anchor-Tags ab */
    // Anchor-Tags brauchen sie diesen Workaround, da sie gegenüber button-Elementen
    // kein disabled-Attribut haben, das automatisch click-events abbrechen kann
    @HostListener('click', ['$event'])
    InterceptClickEvent(event: Event) {
        if (this.disabled) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }
}
