import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';
import { personsFeatureKey, PersonState, selectAllPerson, selectPersonEntities, selectPersonFetched, selectPersonLatestUpdatedAt } from '../reducers/person.reducer';

const selectPerson = createFeatureSelector< PersonState>(personsFeatureKey);
const getPersonEntities = createSelector(selectPerson, selectPersonEntities);

export const getPersons = createSelector(selectPerson, selectAllPerson);
export const getPersonDictionary = getPersonEntities;
export const getPersonsFetched = createSelector(selectPerson, selectPersonFetched);
export const getPersonLatestUpdatedAt = createSelector(selectPerson, selectPersonLatestUpdatedAt);
export const getPersonById = (props: { id: number }) => createSelector(getPersonEntities, (dictionary) => props?.id && dictionary[props.id]);
