import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap, of, switchMap } from 'rxjs';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../../helper/helper';
import {
    ResourceToCommissionEntity,
    ResourceToCommissionEntityFromBackend,
} from '../../../entities/resource-dispo/resource-to-commission.entity';
import { HttpService } from '../../../services/http.service';
import { DaveActions } from '../../actions/actions';
import { BaseActionTypes } from '../../actions/base.actions';
import { ResourceToCommissionsActionTypes } from '../../actions/resource-dispo/resource-to-commission.actions';
import { State } from '../../index';

enum ErrorCodes {
    Add = 'ResourceToCommission Hinzufügen fehlgeschlagen',
    Load = 'ResourceToCommission Abrufen fehlgeschlagen',
    Modify = 'ResourceToCommission Bearbeiten fehlgeschlagen',
    Remove = 'ResourceTOCommission Löschen fehlgeschlagen',
}

@Injectable()
export class ResourceToCommissionEffects {
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {
    }

    AddResourceToCommission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResourceToCommissionsActionTypes.Create),
            withLatestFrom(this.store$),
            concatMap(([{ Payload }, store]) => {
                const queryString = `
      mutation{
        createResourceToCommission(
        ${stringifyIfNotNullOrUndefined(Payload, 'resourceId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'date')}
        ${stringifyIfNotNullOrUndefined(Payload, 'name')}
        ${stringifyIfNotNullOrUndefined(Payload, 'multiplicator')}
        ${stringifyIfNotNullOrUndefined(Payload, 'quantityTypeId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'value')}
        ${Payload.workType ? `workType: ${Payload.workType}` : ''}
          ){${ResourceToCommissionEntity.GqlFields.join(', ')}}
      }`;
                return this.gatewayHttpService.graphQl({ query: queryString },{ token: store.base.token }).pipe(
                    map((res) => {
                        return res && res.createResourceToCommission
                            ? ResourceToCommissionsActionTypes.UpdateOne({
                                Payload: ResourceToCommissionEntityFromBackend(res.createResourceToCommission),
                            })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    LoadResourceToCommssion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResourceToCommissionsActionTypes.Load),
            switchMap(({ Payload })=> {
                const queryString = `
            query {resourcetocommission${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''}{${ResourceToCommissionEntity.GqlFields.join(',')}}}`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                    map((res) => {
                         return res && res.resourcetocommission
                             ? Payload?.updatedSince  ?
                                    ResourceToCommissionsActionTypes.UpdateMany({
                                    Payload: res.resourcetocommission.map((val) => ResourceToCommissionEntityFromBackend(val)),
                                    updateLatestUpdatedAt: true,
                                })
                                 :     ResourceToCommissionsActionTypes.UpdateAll({
                                     Payload: res.resourcetocommission.map((val) => ResourceToCommissionEntityFromBackend(val)),
                                     updateLatestUpdatedAt: true,
                                 })
                                : BaseActionTypes.ErrorAction({
                                    Payload: { ToasterMessage: ErrorCodes.Load },
                                })
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    RemoveResourceToCommission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResourceToCommissionsActionTypes.Delete),
            concatMap(({ Payload }) => {
                const queryString = `
      mutation{
        deleteResourceToCommission(id: ${Payload}){${ResourceToCommissionEntity.GqlFields.join(',')}}
      }`;
                return this.gatewayHttpService
                    .graphQl({ query: queryString })
                    .pipe(
                        map((res) => {
                            return res && res.deleteResourceToCommission && res.deleteResourceToCommission.id
                                ? ResourceToCommissionsActionTypes.RemoveOne({ Payload: res.deleteResourceToCommission.id })
                                : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } })
                        }),
                        catchError((err, caught) =>
                            of(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Remove,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            ),
                        ),
                    );
            }),
        ),
    );

    ModifyResourceToCommission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResourceToCommissionsActionTypes.Change),
            withLatestFrom(this.store$),
            concatMap(([{ Payload }, store]) => {
                const queryString = `
      mutation{
        changeResourceToCommission(id: ${Payload.id},
        ${stringifyIfNotNullOrUndefined(Payload, 'multiplicator')}
        ${stringifyIfNotNullOrUndefined(Payload, 'resourceId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'date')}
        ${stringifyIfNotNullOrUndefined(Payload, 'name')}
        ${stringifyIfNotNullOrUndefined(Payload, 'quantityTypeId')}
        ${stringifyIfNotNullOrUndefined(Payload, 'value')}
        ${Payload.workType ? `workType: ${Payload.workType}` : ''}
          ){${ResourceToCommissionEntity.GqlFields.join(',')}}
      }`;
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    concatMap(res => {
                        const ret: Action[] = [];
                        if (res && res.changeResourceToCommission) {
                            ret.push(
                                ResourceToCommissionsActionTypes.UpdateOne({
                                    Payload: ResourceToCommissionEntityFromBackend(res.changeResourceToCommission),
                                }),
                            );
                        } else {
                            ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }));
                        }
                        return ret;
                    }),
                        catchError((err, caught) =>
                            of(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Modify,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            ),
                        ),
                );
            }),
        ));


}
