<h3 mat-dialog-title>{{ Data?.title || 'Einen Moment Geduld bitte' }}</h3>
<ng-container *ngIf="!(Data?.onReady$ | async)">
    <p mat-dialog-content>{{ Data?.text || 'Es wird etwas im hintergrund ausgeführt.' }}</p>
    <app-loading style="margin: 1rem"></app-loading>
</ng-container>
<ng-container *ngIf="Data?.onReady$ | async as onReady">
    <p mat-dialog-content>{{ onReady.text || (onReady.state === 'success' ? 'Fertig!' : 'Es ist etwas schief gelaufen.') }}</p>
    <div mat-dialog-actions>
        <button app-button [Color]="onReady.state === 'success' ? 'green' : 'red'" matDialogClose style="margin-left: auto">Ok</button>
    </div>
</ng-container>
