import { createAction, props } from '@ngrx/store';
import { WorkDayEntity } from '../../entities/work-day.entity';
import {
    QueryWorkDayArgs,
} from '../../graphql-types';

export const WorkDayActionTypes = {
    Load: createAction('data/work-day/get', props<{ Payload?: QueryWorkDayArgs }>()),
    UpdateAll: createAction('data/work-day/updateAll', props<{ Payload: WorkDayEntity[], updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/work-day/updateMany', props<{ Payload: WorkDayEntity[], updateLatestUpdatedAt?: boolean }>()),
    RemoveMany: createAction('data/work-day/removeMany', props<{ Payload: number[] }>()),
};
