import { createAction, props } from '@ngrx/store';
import { MaterialToCommissionEntity } from '../../entities/material-to-commission.entity';
import { UploadActionProps } from '../../guards/resolver.helper';
import {
    MaterialToCommissionAddRequest,
    MaterialToCommissionDeleteRequest,
    MaterialToCommissionEditRequest,
} from '@dave/types';



const prefix = 'data/' + 'materialToCommission/';
export const MaterialToCommissionActionTypes = {
    Load: createAction(prefix + 'load', props< UploadActionProps >()),
    Create: createAction(prefix + 'create', props<{ Payload: MaterialToCommissionAddRequest }>()),
    Change: createAction(prefix + 'change', props<{ Payload: MaterialToCommissionEditRequest }>()),
    Delete: createAction(prefix + 'delete', props<{ Payload: MaterialToCommissionDeleteRequest }>()),

    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<MaterialToCommissionEntity> ,  updateLatestUpdatedAt?: boolean}>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<MaterialToCommissionEntity>,  updateLatestUpdatedAt?: boolean}>()),
    UpdateOne: createAction(prefix + 'updateOne', props<{ Payload: MaterialToCommissionEntity }>()),
    RemoveOne: createAction(prefix + 'remove', props<{ Payload: number }>()),
};
