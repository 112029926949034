import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AccountsReceivableLedgerTypeEnum } from "../../../dave-data-module/entities/accounts-receivable-ledger.entity";
import { FormControlCache } from "./formcontrol-cache";

export type calculationArlTemplateFormGroup = FormGroup<calculationArlTemplateForm>;
export type calculationArlTemplateForm = {
    costAmountWithoutTax: FormControl<number>;
    CostAmount: FormControl<number>;
    Quantity: FormControl<number>;
    QuantityTypeId: FormControl<number>;
    Information: FormControl<string>;
    BookingText: FormControl<string>;
    Type: FormControl<AccountsReceivableLedgerTypeEnum>;
    ResourceId: FormControl<number>;
    JobSpecificationId: FormControl<number>;
    IsVisible: FormControl<boolean>;
};

@Injectable()
export class CalculationArlTemplateFormDataService extends FormControlCache<calculationArlTemplateFormGroup> {

}
