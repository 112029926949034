import { QuantityTypeType } from '../graphql-types';
import {
    SelectSearchOption
} from "../../dave-utils-module/select-search/components/select-search/select-search.component";

export enum QuantityTypeNames {
    Piece = 'Stk.',
    Hours = 'Std.',
    Minutes = 'min.',
    Generel = 'Pauschale',
    Kilometer = 'km',
    Meter = 'm',
    Kilogramm = 'kg',
    Tonne = 't',
    Liter = 'l',
    Gramm = 'g',
}
export class QuantityTypeEntity implements SelectSearchOption<QuantityTypeEntity> {
    constructor(
        public Id: number = null,
        public Description: string = null,
        public Name: QuantityTypeNames = null,
        public PartnerId: number = null,
        public LangerName: string = null,
    ) {}
    public static readonly GqlFields = `
    description
    id
    name
    partnerId
    langerName
    `;
    get optionLabel() {
        return this.Name
    }
    public Clone(override: QuantityTypeEntityAttributes = {}): QuantityTypeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new QuantityTypeEntity(), attr);
    }
}

export function QuantityTypeEntityFromBackend(res: QuantityTypeType): QuantityTypeEntity {
    return new QuantityTypeEntity(res.id, res.description, res.name as QuantityTypeNames, res.partnerId,res.langerName);
}

export interface QuantityTypeEntityAttributes {
    Id?: number;
    Description?: string;
    Name?: string;
    PartnerId?: number;
    LangerName?: string;
}
