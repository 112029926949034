import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Subscription } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CommentEntityTypeEnum } from '../../../dave-data-module/entities/comment.entity';
import { FileMetaData } from '../../../dave-data-module/entities/file.entity';
import { ViewStyleConfig } from '../../../dave-data-module/entities/viewStyleSetting.entity';
import { CommentResolver } from '../../../dave-data-module/guards/comment.resolver';
import { State } from '../../../dave-data-module/State';
import { CommentActionTypes } from '../../../dave-data-module/State/actions/comment.action';
import { FileUploadParams } from '../../../dave-data-module/State/actions/files.actions';
import { getComments } from '../../../dave-data-module/State/selectors/comment.selector';
import { getEmployeeDictionary } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { LoadingService } from '../../../services/loading.service';
import { CommentIcon } from '../../../helper/page-metadata';
import { CommentInlineEditorComponent } from '../comment-inline-editor/comment-inline-editor.component';

interface CommentCardData {
    commentId: number;
    unSeen: boolean;
}

@Component({
    selector: 'app-comment-list[ViewConfig]',
    templateUrl: './comment-list.component.html',
    styleUrls: ['./comment-list.component.scss'],
    providers: [DatePipe],
})
export class CommentListComponent implements OnDestroy {
    @ViewChild('inlineEditorComponent') inlineEditorComponent: CommentInlineEditorComponent; //DropzoneComponent;

    @Input() FileUploadParams: FileUploadParams;
    @Input() ViewConfig: ViewStyleConfig;
    @Input() CanDelete = false;
    @Input() Inline = false;
    @Input() Expandable = true;
    @Input() GetEntityIdBeforeSaveComment: () => Promise<number>;
    @Input() GetFileUploadParamsBeforeSaveComment: () => Promise<FileUploadParams>;
    @Input() HideDropzone = false;
    @Input() HideHeadline = false;
    @Input() set EntityType(v: CommentEntityTypeEnum) {
        this.entityType$.next(v);
    }

    @Input() set EntityId(v: number) {
        this.entityId$.next(v);
    }
    get EntityId() {
        return this.entityId$.value;
    }

    @Input() set Visible(v: boolean) {
        if (v) {
            this.viewed = true;
        }
    }
    @Output() UnseenCommentCount = new EventEmitter<number>();
    @Output() CommentCount = new EventEmitter<number>();

    CommentIcon = CommentIcon;
    public EditorContent = '';
    protected entityType$: BehaviorSubject<CommentEntityTypeEnum> = new BehaviorSubject(null);
    protected entityId$: BehaviorSubject<number> = new BehaviorSubject(null);
    public Comments$ = combineLatest([this.entityType$.pipe(filter(isNotNullOrUndefined)), this.entityId$.pipe(filter(isNotNullOrUndefined))]).pipe(
        switchMap(([entityType, entityId]) => this.store.select(getComments).pipe(map((comments) => comments.filter((c) => c.EntityId === entityId && c.EntityType === entityType)))),
    );
    public ShownComments$ = combineLatest([this.Comments$, this.store.select(getEmployeeDictionary), this.store.select(getUser)]).pipe(
        map(([comments, employees, user]) => {
            return comments.map<CommentCardData>((c) => {
                return {
                    commentId: c.Id,
                    unSeen: c.LastSeenAt == null || c.UpdatedAt.getTime() > c.LastSeenAt.getTime(),
                };
            });
        }),
        tap((comments) => this.CommentCount.emit(comments?.length)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public UnseenComments$ = this.ShownComments$.pipe(
        map((comments) => comments.filter((c) => c.unSeen)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    protected viewed = false;
    protected subs: Subscription[] = [];

    constructor(private store: Store<State>, public LS: LoadingService, commentResolver: CommentResolver, public BS: BreakpointObserverService, protected dialog: MatDialog) {
        commentResolver.resolve();
        this.subs.push(
            this.UnseenComments$.subscribe((comments) => {
                this.UnseenCommentCount.emit(comments?.length);
            }),
        );
    }

    public IsDirty() {
        // !InlineEditorContent && (!dropzone || !(fileCount$ | async))
        return this.inlineEditorComponent?.IsDirty();
    }
    async SaveComment() {
        return this.inlineEditorComponent?.SaveComment();
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
        if (this.viewed) {
            firstValueFrom(this.ShownComments$).then((comments) => {
                let ids = comments.map((c) => c.commentId);
                if (ids.length !== 0) {
                    this.store.dispatch(
                        CommentActionTypes.SetLastSeen({
                            Payload: {
                                commentIds: ids,
                            },
                        }),
                    );
                }
            });
        }
    }
}
