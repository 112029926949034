import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    MATERIAL_LIST_KEY,
    MaterialListState,
    selectAllMaterialList,
    selectMaterialListEntities, selectMaterialListFetched, selectMaterialListLatestUpdatedAt,
} from '../reducers/material-list.reducer';


const selectState = createFeatureSelector<MaterialListState>(MATERIAL_LIST_KEY);

export const getMaterialListDictionary = createSelector(selectState, selectMaterialListEntities);
export const getMaterialLists = createSelector(selectState, selectAllMaterialList);
export const getMaterialListsFetched = createSelector(selectState, selectMaterialListFetched);
export const getMaterialListLatestUpdatedAt = createSelector(selectState, selectMaterialListLatestUpdatedAt);

export const getMaterialListById = (props: { id: number }) => createSelector(getMaterialListDictionary, (dictionary) => props?.id && dictionary[props.id]);
