import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReplaySubject, Subject } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { DynamicFolderTreeDataService, DynamicFolderTreeFlatNode } from '../../dynamic-folder-tree-data.service';
import { FolderTreeControl } from '../file-explorer/folder-tree-control';
import { DynamicFolderTreeDataSource } from '../file-explorer/folder-tree-datasource';
import { NewFolderDialogComponent, NewFolderDialogComponentDialogData } from '../new-folder-dialog/new-folder-dialog.component';

@Component({
    selector: 'app-select-folder[dataSource]',
    standalone: true,
    imports: [CommonModule, AppButtonModule, FontAwesomeModule, MatButtonModule, MatDialogModule, MatTooltipModule, MatTreeModule, MatProgressBarModule],
    templateUrl: './select-folder.component.html',
    styleUrls: ['./select-folder.component.scss'],
})
export class SelectFolderComponent implements AfterViewInit, OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        autoFocus: false,
        panelClass: ['custom-dialog-class-mobile-fullscreen'],
    };
    @ViewChild('treeWrapperScrollbar') private treeWrapperScrollbar?: ElementRef;
    @Input() set SourceFolderId(FolderId: number) {
        this.TargetFolderId$.next(FolderId);
        this.treeControl.expandFolder(FolderId).then(() => {
            this.ScrollTreeToLastSelected();
            // setTimeout(() => this.ScrollTreeToLastSelected(), 1);
        });
    }
    protected _rootFolderId: number;
    protected _dataSource: DynamicFolderTreeDataSource;
    protected filteredDataSource: DynamicFolderTreeDataSource;
    @Input() set dataSource(ds: DynamicFolderTreeDataSource) {
        this._dataSource = ds;
        this.setDatasource();
    }
    treeControl: FolderTreeControl = new FolderTreeControl(
        this.folderDataService,
        (node: DynamicFolderTreeFlatNode) => node.level,
        (node: DynamicFolderTreeFlatNode) => node.expandable,
    );
    //Sets highest shown folder in tree
    @Input() set rootFolderId(id: number) {
        if (this._rootFolderId !== id) {
            this._rootFolderId = id;
            this.setDatasource();
        }
    }
    private _disabledFolderIds: number[] = [];
    @Input() set disabledFolderIds(ids: number[]) {
        this._disabledFolderIds = ids || [];
        if (this.filteredDataSource) {
            this.filteredDataSource.disabledFolderIds = ids || [];
        }
    }
    get disabledFolderIds(): number[] {
        return this._disabledFolderIds;
    }
    @Input() matTreeNodePaddingIndent = 40;
    private ngAfterViewInit$ = new Subject<void>();
    public TargetFolderId$ = new ReplaySubject<number | null>();

    @Output() SelectedFolderId = new EventEmitter<number>();
    private dataSourceSet$ = new Subject<void>();
    private expandFoldersSubscription = this.dataSourceSet$.pipe(withLatestFrom(this.TargetFolderId$)).subscribe(([_, targetFolderId]) => {
        this.treeControl.expandFolder(targetFolderId);
    });
    constructor(public LS: LocalStorageService, protected bs: BreakpointObserverService, private dialog: MatDialog, private folderDataService: FolderDataService, private folderTreeDataService: DynamicFolderTreeDataService) {
        this.TargetFolderId$.subscribe((id) => this.SelectedFolderId.emit(id));
    }

    ngOnDestroy(): void {
        this.TargetFolderId$.complete();
        this.expandFoldersSubscription.unsubscribe();
    }
    isDisabled(node: DynamicFolderTreeFlatNode) {
        return node.disabled;
    }
    async setDatasource() {
        if (this._dataSource) {
            if (this._rootFolderId) {
                // this.filteredDataSource = new MatTreeFlatDataSource<FoodNode, ExampleFlatNode>(this.treeControl, this.treeFlattener, newRoot.children);
                this.filteredDataSource = new DynamicFolderTreeDataSource(this.treeControl, this.folderTreeDataService);
                this.filteredDataSource.data = await this.folderTreeDataService.initialData([this._rootFolderId]);
            } else {
                // this.filteredDataSource = new MatTreeFlatDataSource<FoodNode, ExampleFlatNode>(this.treeControl, this.treeFlattener, this._dataSource.data);
                this.filteredDataSource = new DynamicFolderTreeDataSource(this.treeControl, this.folderTreeDataService);
                this.filteredDataSource.data = this.folderTreeDataService.initialData();
            }
            if (this.disabledFolderIds?.length) {
                this.filteredDataSource.disabledFolderIds = this.disabledFolderIds;
            }
            this.dataSourceSet$.next();
        }
    }
    HasChild(index: number, node: DynamicFolderTreeFlatNode) {
        return node.expandable;
    }

    ClickNode(node: DynamicFolderTreeFlatNode) {
        if (node.item.isFolder && !node.disabled) {
            this.TargetFolderId$.next(node.item.entityId);
        }
    }
    ScrollTreeToLastSelected() {
        if (!this.treeWrapperScrollbar) {
            return;
        }
        const scrollelement = this.treeWrapperScrollbar.nativeElement;
        const tree = this.treeWrapperScrollbar.nativeElement.children[0]; //.children[0];
        const htmlNodes: HTMLCollection = tree.children;
        const nodeArr = Array.from(htmlNodes);
        const lastNode: any = nodeArr
            .slice()
            .reverse()
            .find((c) => c.classList.contains('selected'));
        if (lastNode) {
            scrollelement.scrollTop = lastNode.offsetTop - 300;
        } else {
            console.warn('No selected node found');
        }
    }
    createChildFolder(rootFolderId: number) {
        this.dialog
            .open(NewFolderDialogComponent, {
                ...NewFolderDialogComponent.DefaultConfig,
                data: {
                    rootFolderId: rootFolderId,
                } as NewFolderDialogComponentDialogData,
            })
            .afterClosed();
    }

    ngAfterViewInit(): void {
        this.ngAfterViewInit$.next();
    }
}
