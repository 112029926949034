import { BooleanInput, coerceBooleanProperty, coerceNumberProperty } from "@angular/cdk/coercion";
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component, EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { DropzoneConfig, DropzoneDirective, DropzoneModule } from 'ngx-dropzone-wrapper';
import { firstValueFrom } from 'rxjs';

import { FileDataService } from '../../../../dave-data-module/services/file-data.service';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { FilesActionTypes, fileUploadParamsObj } from '../../../../dave-data-module/State/actions/files.actions';
import { ErrorCodesFileActions } from '../../../../dave-data-module/State/effects/file.effects';
import { getToken } from '../../../../dave-data-module/State/selectors/base.selectors';
import { FileUploadService } from '../../../../services/file-upload.service';
import { LoadingService } from '../../../../services/loading.service';
import { AppButtonModule } from '../../../app-button-module/app-button.module';
import {
    FileEntity,
    FileEntityFromFileBackend,
    FileTypeFromFileBackend
} from "../../../../dave-data-module/entities/file.entity";
import { isNotNullOrUndefined } from "../../../../helper/helper";
import { MatDialogModule } from '@angular/material/dialog';

// displayed file template
export const ngxDropzoneWrapperPreviewHTML = `
<div style="position: relative; margin: 1rem .5rem .5rem;">
    <div class="dz-preview dz-file-preview">
        <div class="dz-image"><img data-dz-thumbnail /></div>
        <div class="dz-details">
            <div class="dz-size"><span data-dz-size></span></div>
        </div>
        <div class="dz-progress"><span id="dz-progress" class="dz-upload hide" data-dz-uploadprogress></span></div>
        <div class="dz-error-message"><span data-dz-errormessage></span></div>
        <div class="dz-success-mark">
            <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Check</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path
                        d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"
                        stroke-opacity="0.198794158"
                        stroke="#747474"
                        fill-opacity="0.816519475"
                        fill="#FFFFFF"
                    ></path>
                </g>
            </svg>
        </div>
        <div class="dz-error-mark">
            <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Error</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g stroke="#747474" stroke-opacity="0.198794158" fill="#FFFFFF" fill-opacity="0.816519475">
                        <path
                            d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"
                        ></path>
                    </g>
                </g>
            </svg>
        </div>
    </div>
    <div class="dz-filename file-name">
        <input type="text" class="input" data-dz-name>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" class="svg-inline--fa fa-pen fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path class="pen-icon" fill="currentColor" d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z">
            </path>
        </svg>
    </div>
</div>
`;
const loadingServiceUploadKey = 'app-dropzone_upload-files';

/**
 * Diese komponente sollte nach möglichkeit immer genutzt werden wenn möglich
 * todo: logik in direktive auslagern, damit aus jedem element eine Dropzone gemacht werden kann
 * todo: filename input disablen wenn upload startet
 * todo: nach dem upload (oder generell) die preview durch die app-small-file-card ersetzen
 * todo: während des uploads aus dem löschen einen abbrechen button machen
 * todo: nach dem upload den löschen button entfernen oder die dateien dann wirklich löschen
 * todo: theming einbauen
 */
@Component({
    selector: 'app-dropzone',
    standalone: true,
    imports: [CommonModule, DropzoneModule, AppButtonModule, FontAwesomeModule , MatDialogModule],
    templateUrl: './dropzone.component.html',
    styleUrls: ['./dropzone.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropzoneComponent {
    @ViewChild('dropzone') dropzone: DropzoneDirective; //DropzoneComponent;
    @ViewChild('deleteButton') deleteButton: TemplateRef<any>;

    private _uploadParams: fileUploadParamsObj = {};
    @Input()
    set fileUploadParams(value: fileUploadParamsObj) {
        console.log('fileUploadParams', value);
        this._uploadParams = value || {};

        Object.keys(this.dropzoneConfig.params).forEach((key) => {
            delete this.dropzoneConfig.params[key];
        });
        for (let key of Object.keys(value)) {
            this.dropzoneConfig.params[key] = value[key];
        }
    }

    @Input()
    set autoProcess(value: boolean) {
        this.dropzoneConfig.autoProcessQueue = coerceBooleanProperty(value);
    }

    protected  maxFileCount: number;
    @Input()
    set maxFiles(value: number) {
        if ((value || undefined) !== this.dropzoneConfig.maxFiles) {
            this.dropzoneConfig.maxFiles = value || undefined;
        }
    }
    @Input()
    set acceptedFiles(value: string) {
        if ((value || undefined) !== this.dropzoneConfig.acceptedFiles) {
            this.dropzoneConfig.acceptedFiles = value || undefined;
        }
    }
    private _clearOnSuccess = false;
    @Input()
    set clearOnSuccess(value: boolean) {
        this._clearOnSuccess = coerceBooleanProperty(value);
    }
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_clearOnSuccess: BooleanInput;
    @Output() dropzoneQueueComplete = new EventEmitter<void>();
    @Output() dropzoneUploadSuccess = new EventEmitter<FileEntity[]>();
    protected dropzoneConfig: DropzoneConfig = new DropzoneConfig({
        autoProcessQueue: false,
        addRemoveLinks: true,
        dictRemoveFileConfirmation: null, // macht dialog weg
        dictDefaultMessage: 'Klicken oder Dateien hinein ziehen',
        uploadMultiple: false,
        clickable: true,
        paramName: () => 'file',
        parallelUploads: 100,
        headers: {
            // Authorization: `Bearer ${token}`,
        },
        params: [],
        previewTemplate: ngxDropzoneWrapperPreviewHTML,
    });
    constructor(private store: Store<State>, protected ls: LoadingService, private vRef: ViewContainerRef, private uploadService: FileUploadService) {
        this.store.select(getToken).subscribe((token) => {
            this.dropzoneConfig.headers['Authorization'] = `Bearer ${token}`;
        });
    }
    public ProcessUploadAsync() {
        if (this.dropzone?.dropzone()?.files?.length) {
            const uploadParams = this._uploadParams;
            const uploadParamsMap = new Map<keyof fileUploadParamsObj, string>([]);
            for (let key of Object.keys(uploadParams)) {
                uploadParamsMap.set(key as keyof fileUploadParamsObj, uploadParams[key]);
            }
            this.uploadService.upload(this.dropzone.dropzone().files.map((file) => ({ file, params: new Map(uploadParamsMap.set('name', file.upload?.filename || file.name)) })));
            this.dropzone.reset();
        }
    }
    public ProcessUpload() {
        this.ls.startLoading(loadingServiceUploadKey, { dialogMessage: 'Dokumente werden hochgeladen' });
        this.dropzone.dropzone().processQueue();

    }
    getFileCount() {
        return this.dropzone?.dropzone()?.files?.length ;
    }

    private replaceDeleteLinkWithButton(previewElement: HTMLElement) {
        const deleteLink: HTMLElement = previewElement.querySelector('.dz-remove');
        if (deleteLink) {
            deleteLink.style.display = 'none';
            const embeddedView = this.vRef.createEmbeddedView(this.deleteButton);
            const newDeleteButton = embeddedView.rootNodes[0];
            deleteLink.eventListeners('click').forEach((e) => newDeleteButton.addEventListener('click', e));
            previewElement.appendChild(embeddedView.rootNodes[0]);
        }
    }
    private makeNameEditable(previewElement: HTMLElement, upload: { filename }) {
        const nameSpan: HTMLInputElement = previewElement.querySelector('[data-dz-name]');
        nameSpan.setAttribute('value', upload.filename);
        nameSpan.addEventListener('input', (event: InputEvent) => {
            upload.filename = nameSpan.value;
        });
    }
    removeProgressClass(previewElement: Element) {
        for (let node of previewElement.querySelectorAll('[data-dz-uploadprogress].indeterminate-progress')) {
            node.classList.remove('indeterminate-progress');
        }
    }
    addProgressClass(previewElement: Element) {
        for (let node of previewElement.querySelectorAll('[data-dz-uploadprogress]')) {
            node.classList.add('indeterminate-progress');
        }
    }
    dropzoneError([file, message]) {
        if (file.previewElement) {
            this.removeProgressClass(file.previewElement);
        }
        this.ls.endLoading(loadingServiceUploadKey);
        this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodesFileActions.Upload } }));
    }
    dropzoneSuccess([file, files, progressEvent]: [any, FileTypeFromFileBackend[], any]) {
        if (file.previewElement) {
            this.removeProgressClass(file.previewElement);
        }
        if (this._clearOnSuccess) {
            this.dropzone?.reset();
        }
        if (files && files.length) {
            const f = files.map(FileEntityFromFileBackend);
            this.store.dispatch(FilesActionTypes.UpdateMany({ Payload: f }));
            this.uploadService.pollThumbnails(files.map(f => f.id));
            this.dropzoneUploadSuccess.emit(f);
            console.log('DZ success', f)
        } else  {
            this.dropzoneUploadSuccess.emit([]);
        }
        this.ls.endLoading(loadingServiceUploadKey);
    }
    dropzoneUploadProcess([file, progress, bytesSent]) {
        if (progress === 100 && file.previewElement) {
            this.addProgressClass(file.previewElement);
        }
    }
    dropzoneAddedFile(event: { previewElement: HTMLElement; upload: { filename: string } }) {

        event.previewElement && this.replaceDeleteLinkWithButton(event.previewElement);
        event.previewElement && this.makeNameEditable(event.previewElement, event.upload);

        if (this.maxFiles){
            if ( this.getFileCount() > 1) {
                // this.dropzone.reset(true);
                const  newFile = event.previewElement;
                const existingFile = this.dropzone.dropzone().files[0]
                this.dropzone.dropzone().removeFile(existingFile);
                this.dropzone.dropzone().addFiles(newFile);
            }
        }
    }
    onDropzoneQueueComplete(event) {
        console.log('DZ_Complete', event)
        this.dropzoneQueueComplete.emit();
    }
}
