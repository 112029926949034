import { createAction, props } from '@ngrx/store';
import { ResourceEntity } from '../../../entities/resource-dispo/resource.entity';
import {
    DaveMutationChangeResourceArgs,
    DaveMutationCreateResourceArgs,
    DaveMutationDeleteResourceArgs,
    QueryResourceArgs,
} from '../../../graphql-types';
import { ResourceAddRequest, ResourceDeleteRequest, ResourceEditRequest } from '@dave/types';

const prefix = 'data/' + 'resource/';
export const ResourceActionTypes = {
    Load: createAction(prefix + 'load', props<{ Payload?: QueryResourceArgs }>()),
    Create: createAction(prefix + 'create', props<{ Payload: ResourceAddRequest }>()),
    Change: createAction(prefix + 'change', props<{ Payload: ResourceEditRequest }>()),
    Delete: createAction(prefix + 'delete', props<{ Payload: ResourceDeleteRequest }>()),

    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<ResourceEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<ResourceEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction(prefix + 'updateOne', props<{ Payload: ResourceEntity }>()),
    RemoveOne: createAction(prefix + 'removeOne', props<{ Payload: number }>()),
};
