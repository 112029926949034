import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
    AccountsReceivableLedgerTemplateEntity,
    ARLCustomProperties,
} from '../../../dave-data-module/entities/accounts-receivable-ledger-template.entity';
import {
    AccountsReceivableLedgerEntity,
    CalcAccountsReceivableLedgerAmount,
} from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { QuantityTypeResolver } from '../../../dave-data-module/guards/quantity-type.resolver';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { AccountsReceivableLedgerActionTypes } from '../../../dave-data-module/State/actions/accounting.actions';
import {
    getAccountsReceivableLedgerById,
    getAccountsReceivableLedgerTemplateById,
    getQuantityTypes,
} from '../../../dave-data-module/State/selectors/accounting.selector';
import { getCommissionTypes } from '../../../dave-data-module/State/selectors/commissionType.selectors';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { FormControlTyped } from '../../../dave-utils-module/typings';
import { isNotNullOrUndefined } from '../../../helper/helper';
import {decimalPriceValidator, userRequiredFieldValidator} from '../../../helper/validation.helper';

export interface NewOptionDefaultValues {
    LedgerImportId?: number;
    CustomerId?: number;
    CommissionId?: number;
}

@Component({
    selector: 'app-add-ancillary-costs',
    templateUrl: './add-ancillary-costs.component.html',
    styleUrls: ['./add-ancillary-costs.component.scss'],
})
export class AddAncillaryCostsComponent implements OnInit, OnChanges {
    private defaults = {
        Tax: 19,
        AncillaryPercent: 0,
        CurrencyCode: 'EUR',
        BookingText: 'NK',
        Information: null,
        BaseCost: null,

        Quantity: null,
        DiscountAmount: null,
        DiscountPercentage: null,
        DiscountPaymentDate: null,
        DiscountAmount2: null,
        DiscountPercentage2: null,
        DiscountPaymentDate2: null,
        Date: new Date(),
        LedgerImportId: null,
        CustomerId: null,
        CommissionId: null,
        QuantityTypeId: null,
        Multiplier: 1,
        CustomProperties: null,
    };
    public Form = this.fb.group(
        {
            Quantity: new UntypedFormControl(null),
            Tax: new UntypedFormControl(this.defaults.Tax, [Validators.max(100), Validators.min(0)]),
            AncillaryPercent: new UntypedFormControl(0, [Validators.max(100), Validators.min(0)]),
            CurrencyCode: new UntypedFormControl(this.defaults.CurrencyCode),
            BookingText: new UntypedFormControl(null, [Validators.required]),
            Information: new UntypedFormControl(this.defaults.Information, [Validators.required]),
            BaseCost: new UntypedFormControl(null, [decimalPriceValidator()]),
            DiscountAmount: new UntypedFormControl(null, [decimalPriceValidator()]),
            DiscountPercentage: new UntypedFormControl(null),
            DiscountPaymentDate: new UntypedFormControl(null),
            DiscountAmount2: new UntypedFormControl(null, [decimalPriceValidator()]),
            DiscountPercentage2: new UntypedFormControl(null),
            DiscountPaymentDate2: new UntypedFormControl(null),
            Date: new UntypedFormControl(null, [Validators.required]),
            LedgerImportId: new UntypedFormControl(null),
            CustomerId: new UntypedFormControl(null),
            CommissionId: new UntypedFormControl(null),
            QuantityTypeId: new UntypedFormControl(null),
            // CommissionTypeId: new FormControl(null),
            Multiplier: new UntypedFormControl(1, [Validators.min(0)]),
            CustomProperties: new UntypedFormControl(null),
        },
        userRequiredFieldValidator,
    ) as FormGroupTyped<{
        Quantity: number;
        Tax: number;
        AncillaryPercent: number;
        CurrencyCode: string;
        BookingText: string;
        Information: string;
        BaseCost: number;
        DiscountAmount: number;
        DiscountPercentage: number;
        DiscountPaymentDate: Date;
        DiscountAmount2: number;
        DiscountPercentage2: number;
        DiscountPaymentDate2: Date;
        Date: Date;
        LedgerImportId: number;
        CustomerId: number;
        CommissionId: number;
        QuantityTypeId: number;
        // CommissionTypeId: number;
        Multiplier: number;
        CustomProperties: ARLCustomProperties;
    }>;
    private fullPriceForm: FormControlTyped<number> = new UntypedFormControl(null);
    private priceBeforeTaxes: FormControlTyped<number> = new UntypedFormControl(null);
    private generatedInformation: string;
    public ledgerimportid: number;

    public ListTemplateData$: Observable<IDetailListTemplateData>;
    public BookingOption$: Observable<AccountsReceivableLedgerEntity>;
    public BookingOptionTemplate$: Observable<AccountsReceivableLedgerTemplateEntity>;
    private bookingOptionTemplateId$ = new BehaviorSubject<number>(null);
    private subscriptions: Subscription[] = [];
    constructor(private fb: UntypedFormBuilder, private store: Store<State>, quantityTypeResolver: QuantityTypeResolver) {
        quantityTypeResolver.resolve();
    }

    @Input() LedgerImportId: number;
    @Input() SelectedPrices: any;
    @Input() Save$: Observable<void> = new Subject<void>();
    @Input() Delete$: Observable<void> = new Subject<void>();
    @Input() BookingOptionId: number = null;
    @Input()
    set DefaultsForNewOptions(defaultValues: NewOptionDefaultValues) {
        this.defaults.LedgerImportId = defaultValues?.LedgerImportId || this.defaults.LedgerImportId;
        this.defaults.CustomerId = defaultValues?.CustomerId || this.defaults.CustomerId;
        this.defaults.CommissionId = defaultValues?.CommissionId || this.defaults.CommissionId;
    }
    // get DefaultsForNewOptions() {
    //     return this.defaults.LedgerImportId;
    // }
    @Input()
    set BookingOptionTemplateId(id: number) {
        this.bookingOptionTemplateId$.next(id);
    }
    get BookingOptionTemplateId() {
        return this.bookingOptionTemplateId$.value;
    }
    ngOnInit(): void {
        this.fullPriceForm.disable();
        this.priceBeforeTaxes.disable();
        this.BookingOption$ = this.BookingOptionId
            ? this.store
                  .select(getAccountsReceivableLedgerById({id: this.BookingOptionId}))
                  .pipe(filter(isNotNullOrUndefined), take(1))
            : of(null);
        this.BookingOptionTemplate$ = this.bookingOptionTemplateId$.pipe(
            filter(isNotNullOrUndefined),
            distinctUntilChanged(),
            switchMap(id =>
                this.store
                    .select(getAccountsReceivableLedgerTemplateById({id}))
                    .pipe(filter(isNotNullOrUndefined), take(1)),
            ),
        );
        this.ListTemplateData$ = combineLatest([
            this.BookingOption$,
            this.BookingOptionTemplate$.pipe(startWith(null)),
            this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined)),
            // this.Form.controls.CommissionTypeId.valueChanges.pipe(startWith(this.Form.value.CommissionTypeId))
        ]).pipe(
            tap(([bookingOption, bookingOptionTemplate, quantityTypes /*commissionTypeId*/]) =>
                this.setForm(bookingOption || null, bookingOptionTemplate || null),
            ),
            withLatestFrom(this.store.select(getCommissionTypes).pipe(filter(isNotNullOrUndefined))),
            map(([[bookingOption, bookingOptionTemplate, quantityTypes /*commissionTypeId*/], commissionTypes]) => {
                return {
                    Properties: [
                        {
                            key: 'Artikelnummer',
                            value: this.Form.value.BookingText || '',
                            formControl: this.Form.controls.BookingText,
                        },
                        {
                            key: 'Bezeichnung',
                            value: this.Form.value.Information || '',
                            formControl: this.Form.controls.Information,
                        },
                        {
                            key: 'Prozentsatz',
                            value: this.Form.value.AncillaryPercent || '',
                            formControl: this.Form.controls.AncillaryPercent,
                            options: {
                                type: CustomPropertyType.Percentage,
                                specialInput: {
                                    number: true,
                                },
                                suffix: '%',
                            },
                        },
                        {
                            key: 'Multiplikator',
                            value: '',
                            formControl: this.Form.controls.Multiplier,
                            hideFormControl: !this.Form.value.CustomProperties?.showMultiplier,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: 'Preis vor Steuern',
                            value: this.priceBeforeTaxes.value || '--',
                            formControl: this.priceBeforeTaxes,
                        },
                        {
                            key: 'Steuern',
                            value: this.Form.value.Tax || '',
                            formControl: this.Form.controls.Tax,
                            options: {
                                type: CustomPropertyType.Percentage,
                                specialInput: {
                                    number: true,
                                },
                                suffix: '%',
                            },
                        },
                        {
                            key: 'Preis gesamt',
                            value: this.fullPriceForm.value || '--',
                            formControl: this.fullPriceForm,
                        },
                    ],
                };
            }),
        );
        this.subscriptions.push(
            this.Form.valueChanges
                .pipe(
                    tap(values => {
                        let sum = 0;
                        let sumNetto = 0;
                        let positions = [];
                        if (this.SelectedPrices.length > 0) {
                            sum = this.SelectedPrices?.map(i => {
                                positions.push(i.pos);
                                return i.sum;
                            })?.reduce((sum, curr) => sum + curr);

                            sumNetto = this.SelectedPrices?.map(i => {
                                return i.sumNetto;
                            })?.reduce((sumNetto, curr) => sumNetto + curr);
                        }
                        let priceBeforeTaxes = this.CalcAncillarycostsBeforeTaxes(sumNetto, values.AncillaryPercent);
                        // let priceBeforeTaxes = sumNetto * values.AncillaryPercent;

                        this.fullPriceForm.setValue(
                            CalcAccountsReceivableLedgerAmount(1, values.Tax, priceBeforeTaxes),
                        ),
                            this.priceBeforeTaxes.setValue(priceBeforeTaxes);
                        this.generatedInformation = 'Nebenkosten zu Position ' + positions.toString();
                    }),
                )
                .subscribe(),
            this.Delete$.pipe(
                withLatestFrom(this.BookingOption$),
                tap(([, bp]) =>
                    this.store.dispatch(
                        AccountsReceivableLedgerActionTypes.DeleteAccountsReceivableLedger({ Payload: { id: bp.Id } }),
                    ),
                ),
            ).subscribe(),
            this.Save$.pipe(
                filter(() => this.Form.valid),
                withLatestFrom(this.BookingOption$),
                tap(([, bookingoption]) => {
                    if (bookingoption) {
                        this.store.dispatch(
                            AccountsReceivableLedgerActionTypes.ChangeAccountsReceivableLedger({
                                Payload: {
                                    id: bookingoption.Id,
                                    quantity: 1,
                                    tax: this.Form.value.Tax,
                                    currencyCode: this.Form.value.CurrencyCode,
                                    bookingText: this.Form.value.BookingText,
                                    baseCost: this.Form.value.BaseCost,
                                    discountAmount: this.Form.value.DiscountAmount,
                                    discountPercentage: this.Form.value.DiscountPercentage,
                                    discountPaymentDate:
                                        this.Form.value.DiscountPaymentDate &&
                                        FrontendDate(this.Form.value.DiscountPaymentDate),
                                    discountAmount2: this.Form.value.DiscountAmount2,
                                    discountPercentage2: this.Form.value.DiscountPercentage2,
                                    discountPaymentDate2:
                                        this.Form.value.DiscountPaymentDate2 &&
                                        FrontendDate(this.Form.value.DiscountPaymentDate2),
                                    date: this.Form.value.Date && FrontendDate(this.Form.value.Date),
                                    information: this.Form.value.Information,
                                    quantityTypeId: this.Form.value.QuantityTypeId,
                                    multiplikator: this.Form.value.Multiplier,
                                    customProperties: JSON.stringify(this.Form.value.CustomProperties),
                                },
                            }),
                        );
                    } else {
                        this.store.dispatch(
                            AccountsReceivableLedgerActionTypes.AddAccountsReceivableLedger({
                                Payload: {
                                    quantity: 1,
                                    tax: this.Form.value.Tax,
                                    currencyCode: this.Form.value.CurrencyCode,
                                    bookingText: this.Form.value.BookingText,
                                    baseCost: this.priceBeforeTaxes.value,
                                    discountAmount: this.Form.value.DiscountAmount,
                                    discountPercentage: this.Form.value.DiscountPercentage,
                                    discountPaymentDate:
                                        this.Form.value.DiscountPaymentDate &&
                                        FrontendDate(this.Form.value.DiscountPaymentDate),
                                    discountAmount2: this.Form.value.DiscountAmount2,
                                    discountPercentage2: this.Form.value.DiscountPercentage2,
                                    discountPaymentDate2:
                                        this.Form.value.DiscountPaymentDate2 &&
                                        FrontendDate(this.Form.value.DiscountPaymentDate2),
                                    date: this.Form.value.Date && FrontendDate(this.Form.value.Date),
                                    ledgerImportId: this.LedgerImportId,
                                    // ledgerImportId: this.Form.value.LedgerImportId,
                                    customerId: this.Form.value.CustomerId,
                                    commissionId: this.Form.value.CommissionId,
                                    information: this.Form.value.Information,
                                    quantityTypeId: 3, // 2 = Pauschale
                                    multiplikator: this.Form.value.Multiplier,
                                    customProperties: JSON.stringify(this.Form.value.CustomProperties),
                                },
                            }),
                        );
                    }
                }),
            ).subscribe(),
        );
    }

    CalcAncillarycostsBeforeTaxes(price: number, percentage: number) {
        return +(price * (percentage * 0.01)).toFixed(2);
    }

    ngOnChanges(changes: SimpleChanges): void {
        //console.log('detected change! ', changes);
        if (changes.SelectedPrices) {
            this.SelectedPrices = changes.SelectedPrices.currentValue;
        }
        this.UpdateFormValues();
    }

    UpdateFormValues() {
        let sum = 0;
        let positions = [];
        if (this.SelectedPrices.length > 0) {
            sum = this.SelectedPrices?.map(i => {
                positions.push(i.pos);
                return i.sum;
            })?.reduce((sum, curr) => sum + curr);
        }
        let priceBeforeTaxes = this.CalcAncillarycostsBeforeTaxes(sum, this.Form.value.AncillaryPercent);

        this.fullPriceForm.setValue(CalcAccountsReceivableLedgerAmount(1, this.Form.value.Tax, priceBeforeTaxes)),
            this.priceBeforeTaxes.setValue(priceBeforeTaxes);
        this.generatedInformation = 'Nebenkosten zu Position ' + positions.toString();
        this.Form.patchValue({ Information: this.generatedInformation });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    private setForm(
        bookingOption?: AccountsReceivableLedgerEntity,
        bookingOptionTemplate?: AccountsReceivableLedgerTemplateEntity,
    ) {
        if (bookingOption && bookingOptionTemplate) {
            throw 'wrong funktion input';
        }
        this.Form.setValue(
            bookingOption
                ? {
                      Tax: bookingOption.Tax,
                      AncillaryPercent: 0,
                      CurrencyCode: bookingOption.CurrencyCode,
                      BookingText: bookingOption.BookingText,
                      Information: bookingOption.Information,
                      BaseCost: bookingOption.BaseCost,

                      Quantity: bookingOption.Quantity,
                      DiscountAmount: bookingOption.DiscountAmount,
                      DiscountPercentage: bookingOption.DiscountPercentage,
                      DiscountPaymentDate: bookingOption.DiscountPaymentDate,
                      DiscountAmount2: bookingOption.DiscountAmount2,
                      DiscountPercentage2: bookingOption.DiscountPercentage2,
                      DiscountPaymentDate2: bookingOption.DiscountPaymentDate2,
                      Date: bookingOption.Date,
                      LedgerImportId: bookingOption.LedgerImportId,
                      CustomerId: bookingOption.CustomerId,
                      CommissionId: bookingOption.CommissionId,
                      QuantityTypeId: bookingOption.QuantityTypeId,

                      Multiplier: bookingOption.Multiplier,
                      CustomProperties: bookingOption.CustomProperties,
                  }
                : bookingOptionTemplate
                ? {
                      Tax: bookingOptionTemplate.Tax,
                      AncillaryPercent: 0,
                      CurrencyCode: bookingOptionTemplate.CurrencyCode,
                      BookingText: bookingOptionTemplate.BookingText,
                      Information: bookingOptionTemplate.Information,
                      BaseCost: bookingOptionTemplate.BaseCost,
                      QuantityTypeId: bookingOptionTemplate.QuantityTypeId,
                      Quantity: bookingOptionTemplate.Quantity,

                      DiscountAmount: this.defaults.DiscountAmount,
                      DiscountPercentage: this.defaults.DiscountPercentage,
                      DiscountPaymentDate: this.defaults.DiscountPaymentDate,
                      DiscountAmount2: this.defaults.DiscountAmount2,
                      DiscountPercentage2: this.defaults.DiscountPercentage2,
                      DiscountPaymentDate2: this.defaults.DiscountPaymentDate2,
                      Date: this.defaults.Date,
                      LedgerImportId: this.defaults.LedgerImportId,
                      CustomerId: this.defaults.CustomerId,
                      CommissionId: this.defaults.CommissionId,

                      Multiplier: bookingOptionTemplate.Multiplier,
                      CustomProperties: bookingOptionTemplate.CustomProperties,
                  }
                : this.defaults,
        );
        this.Form.patchValue({
            Information: this.generatedInformation,
        });
    }
}

export interface ModifyBookingOptionDialogComponentData {
    DefaultsForNewOptions?: NewOptionDefaultValues;
    BookingOptionId?: number;
}
