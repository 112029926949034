import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { HttpService } from '../../services/http.service';
import { BaseActionTypes } from '../actions/base.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import {
    EmployeeToCommissionEntity,
    EmployeeToCommissionEntityFromBackend,
} from '../../entities/employee-to-commission.entity';
import { EmployeeToCommissionActionTypes } from '../actions/employee-to-commission.action';

enum ErrorCodes {
    Load = 'EmployeeToCommission Abrufen fehlgeschlagen',
    Modify = 'EmployeeToCommission Bearbeiten fehlgeschlagen',
    Remove = 'EmployeeToCommission Löschen fehlgeschlagen',
    Add = 'EmployeeToCommission Hinzufügen fehlgeschlagen',
}

@Injectable()
export class EmployeeToCommissionEffects {

    CreateEmployeeToCommission$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeToCommissionActionTypes.Create),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
                mutation {
                    createEmployee2Commission(
                        ${stringifyIfNotNullOrUndefined(Payload, 'employeeId')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                ) {
                    ${EmployeeToCommissionEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    console.log(Payload);
                    return res && res.createEmployee2Commission
                        ? EmployeeToCommissionActionTypes.SetOne({
                              Payload: EmployeeToCommissionEntityFromBackend(res.createEmployee2Commission),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    //view wird nicht übergeben, da keine berechtigungen

    ModifyEmployeeToCommission$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeToCommissionActionTypes.Change),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
            mutation {
                changeEmployee2Commission(
                    commissionId: ${Payload.commissionId}
                    employeeId: ${Payload.employeeId}
                    ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                ) {
                    ${EmployeeToCommissionEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res => {
                    const ret: Action[] = [];
                    if (res && res.changeEmployee2Commission) {
                        ret.push(
                            EmployeeToCommissionActionTypes.SetOne({
                                Payload: EmployeeToCommissionEntityFromBackend(res.changeEmployee2Commission),
                            }),
                        );
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    GetEmployeeToCommission$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeToCommissionActionTypes.LoadAll),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([action, token]) => {
            const queryString = `
            query {
                employee2Commission {
                    ${EmployeeToCommissionEntity.GqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                map(res => {
                    return res && res.employee2Commission
                        ? EmployeeToCommissionActionTypes.SetAll({
                              Payload: res.employee2Commission.map(e => EmployeeToCommissionEntityFromBackend(e)),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    RemoveEmployeeToCommission$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeToCommissionActionTypes.Delete),
        withLatestFrom(this.store$),
        concatMap(([{Payload}, store]) => {
            const queryString = `
                mutation{
                  deleteEmployee2Commission(
                  employeeId: ${Payload.employeeId}
                  commissionId: ${Payload.commissionId}
                  )
                }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.deleteEmployee2Commission
                        ? EmployeeToCommissionActionTypes.RemoveOne({
                              Payload: EmployeeToCommissionEntityFromBackend(Payload),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
