import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    MandateTypeState,
    MANDATE_TYPE_KEY,
    selectAllMandateTypes,
    selectMandateTypesFetched,
} from '../reducers/mandate-type.reducer';
import { State } from '../index';

export const selectMandateType = createFeatureSelector< MandateTypeState>(
    MANDATE_TYPE_KEY,
);
export const getMandateTypes = createSelector(
    selectMandateType,
    selectAllMandateTypes,
);

export const getMandateTypesFetched = createSelector(
    selectMandateType,
    selectMandateTypesFetched,
);
