import { PermissionEntity } from './permission.entity';

export class RoleEntity {
    constructor(
        public Name: string = null,
        public Description: string = null,
        public ProductId: number = null,
        public Permissions: Array<PermissionEntity> = [],
    ) {}

    public Clone(override: RoleEntityAttributes = {}): RoleEntity {
        const attr = {...this, ...override};
        return Object.assign(new RoleEntity(), attr);
    }
}

export interface RoleEntityAttributes {
    Name?: string;
    Description?: string;
    ProductId?: number;
    Permissions?: Array<PermissionEntity>;
}
