import { FilterApps } from '../../services/default-filter.service';
import { UserType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
import {
    CommissionDashboardComponent
} from '../../dave-commission-module/components/comission-dashboard/commission-dashboard.component';
export enum DmsViewStyle {
    Tile,
    List,
    OldDms
}
export interface SettingEntityAttributes {
    ShowTimeTrackingClockWatch?: boolean;
    ShowTimeTrackingPopup?: boolean;
    ShowTimeTrackingMultiplePopup?: boolean;
    ShowDayTimeTrackingPopup?: boolean;
    DarkMode?: boolean;
    FilterCommissions?: boolean;
    AutomaticEventBookmark?: boolean;
    DmsViewStyle?: DmsViewStyle;
    ListFilters?: Map<FilterApps, object>;
    PreventEmailSeenOnOpenInSideView?: boolean;
    CommissionDashboardComponentCommissionTypeIds?: number[];
}
export interface UserAdditionalData {
    // settings: SettingEntityAttributes;
}
export class UserEntity {
    constructor(
        public Id: number = null,
        public Aud: string = null,
        public Email: string = null,
        public Exp: number = null,
        public Iat: string = null,
        public Iss: string = null,
        public PartnerId: number = null,
        public Firstname: string = null,
        public Lastname: string = null,
        public Deleted: boolean = false,
        public Roles: string[] = null,
        public ProductIds: number[] = null,
        public AdditionalData: UserAdditionalData = null,
        public DocumentId: number = null,
        public UpdatedAt: Date = null,
) {
    }
    public static readonly GqlFields = `deleted
    email
    firstname
    id
    lastname
    roles
    partnerIds
    additionalData
    documentId
    updatedAt
    userProducts{
        productId
    }`;
    get DisplayName() {
        return `${this.Lastname ? this.Lastname + ', ' : ''}${this.Firstname || ''}`;
    }

    public Clone(override: UserEntityAttributes = {}): UserEntity {
        const attr = {...this, ...override};
        return Object.assign(new UserEntity(), attr);
    }
}

export function UserEntityFromBackend(res: UserType): UserEntity {
    const additionalData: UserAdditionalData = {...res.additionalData as any};
    //@ts-ignore
    if (additionalData?.settings) {
        //@ts-ignore
        additionalData.settings = undefined; // Settings stehen im State an einer anderen stelle, damit beim ändern einer einstellung nicht der @link getUser selector getriggert wird
        // additionalData.settings.ListFilters = new Map(Object.entries(additionalData.settings.ListFilters)) as Map<FilterApps, object>;
    }
    return new UserEntity(
        res.id,
        null,
        res.email,
        null,
        null,
        null,
        res.partnerIds != null && res.partnerIds.length > 0 ?
            res.partnerIds[0] : null,
        res.firstname,
        res.lastname,
        res.deleted,
        res.roles,
        res.userProducts ? res.userProducts.map(uP => uP.productId) : null,
        additionalData,
        res.documentId,
        BackendDate(res.updatedAt),
    );
}

export interface UserEntityAttributes {
    Id?: number;
    Aud?: string;
    Email?: string;
    Exp?: number;
    Iat?: string;
    Iss?: string;
    PartnerId?: number;
    Firstname?: string;
    Lastname?: string;
    Deleted?: boolean;
    Roles?: string[];
    ProductIds?: number[];
    AdditionalData?: UserAdditionalData;
    DocumentId?: number;
    UpdatedAt?: Date;
}

export enum UserRoles {
    DaveAdmin = 'daveadmin',
    CustomerManagementAdmin = 'CustomerManagementAdmin',
    CustomerManagementUser = 'CustomerManagementUser',
    EmployeeManagementAdmin = 'EmployeeManagementAdmin',
    EmployeeManagementUser = 'EmployeeManagementUser',
    defaultUser = 'defaultUser',
    PartnerAdmin = 'PartnerAdmin',
    AccountingUser = 'AccountingUser',
    AccountingAdmin = 'AccountingAdmin',
}
