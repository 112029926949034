import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ProductNames } from '../dave-data-module/entities/license.entity';
import {
    CalendarPageMeta,
    ResourcePageMeta,
    ContactBookMeta,
    RessourcePlanPageMeta,
    ShiftPlanPageMeta,
    UserAdministrationMeta, CommissionMeta, ProcessMeta, MaterialPageMeta,
} from '../helper/page-metadata';
import { Permissions } from '@dave/types/dist/proto/global/permissions';

/**
 * the strings are used in the DataBase, do not change them!
 */
export enum DashboardItemsDataType {
    commissionAdministration = 'commissionAdministration',
    customerAdministration = 'customerAdministration',
    akkord = 'akkord',
    shiftPlan = 'shiftPlan',
    resourceAdministration = 'resourceAdministration',
    resourcePlan = 'resourcePlan',
    tasks = 'tasks',
    videoDoku = 'videoDoku',
    dms = 'dms',
    employeeAdministration = 'employeeAdministration',
    email = 'email',
    invoices = 'invoices',
    contactBook = 'contactBook',
    generatedDocuments = 'generatedDocuments',
    chronik = 'chronik',
    settings = 'settings',
    partnerAdministration = 'partnerAdministration',
    process = 'process',
}
export const DashboardItemsData: {
    title: string;
    route: string;
    image: IconProp;
    theme: string;
    disabled: boolean;
    type: DashboardItemsDataType;
    license?: ProductNames | null;
    requiredPermissions?: Permissions[];
    buttons?: [
        {
            name: string;
            icon: IconProp;
        },
    ];
}[] = [
    {
        title: 'Akkord',
        route: 'akkord',
        image: 'euro-sign',
        theme: 'piecework-theme',
        disabled: false,
        type: DashboardItemsDataType.akkord,
        license: null,
        requiredPermissions: [Permissions.GetAkkord],
    },
    {
        title: 'Kunden',
        route: 'kunden',
        image: 'building',
        theme: 'admin-theme',
        type: DashboardItemsDataType.customerAdministration,
        disabled: false,
        license: null,
        requiredPermissions: [Permissions.GetCustomer],
    },
    {
        title: '',
        route: CommissionMeta.Path,
        image: CommissionMeta.Icon,
        theme: CommissionMeta.Theme,
        disabled: false,
        type: DashboardItemsDataType.commissionAdministration,
        license: ProductNames.Commission,
        requiredPermissions: [Permissions.GetCommission],
        buttons: [
            {
                name: 'diary-button',
                icon: 'hard-hat',
            },
        ],
    },
    {
        title: 'E-Mail',
        route: 'email',
        image: 'envelope',
        theme: 'email-theme',
        disabled: false,
        type: DashboardItemsDataType.email,
        license: null,
        requiredPermissions: null,
    },
    {
        title: '',
        route: CalendarPageMeta.Path + '/' + RessourcePlanPageMeta.Path,
        image: CalendarPageMeta.Icon,
        theme: ResourcePageMeta.Theme,
        disabled: false,
        type: DashboardItemsDataType.resourcePlan,
        license: null,
        requiredPermissions: null,
    },
    {
        title: ShiftPlanPageMeta.Breadcrumb,
        route: CalendarPageMeta.Path + '/' + ShiftPlanPageMeta.Path,
        image: CalendarPageMeta.Icon,
        theme: CalendarPageMeta.Theme,
        disabled: false,
        type: DashboardItemsDataType.shiftPlan,
        license: null,
        requiredPermissions: null,
    },
    {
        title: ResourcePageMeta.Breadcrumb,
        route: ResourcePageMeta.Path,
        image: ResourcePageMeta.Icon,
        theme: ResourcePageMeta.Theme,
        type: DashboardItemsDataType.resourceAdministration,
        disabled: false,
        license: null,
        requiredPermissions: null,
    },
    {
        title: MaterialPageMeta.Breadcrumb,
        route: MaterialPageMeta.Path,
        image: MaterialPageMeta.Icon,
        theme: ResourcePageMeta.Theme,
        type: DashboardItemsDataType.resourceAdministration,
        disabled: false,
        license: null,
        requiredPermissions: null,
    },
    {
        title: 'Aufgaben',
        route: 'aufgaben',
        image: 'clipboard-check',
        theme: 'task-theme',
        disabled: false,
        type: DashboardItemsDataType.tasks,
        license: null,
        requiredPermissions: null,
        buttons: [
            {
                name: 'add-task-button',
                icon: 'plus',
            },
        ],
    },
    {
        title: ContactBookMeta.Breadcrumb,
        route: ContactBookMeta.Path,
        image: ContactBookMeta.Icon,
        theme: 'contacts-theme',
        disabled: false,
        type: DashboardItemsDataType.contactBook,
        license: null,
        requiredPermissions: null,
    },
    {
        title: ProcessMeta.Breadcrumb,
        route: ProcessMeta.Path,
        image: ProcessMeta.Icon,
        theme: ProcessMeta.Theme,
        disabled: false,
        type: DashboardItemsDataType.process,
        license: null,
        requiredPermissions: null,
    },
    {
        title: 'DMS',
        route: 'dms',
        image: 'file',
        theme: 'dms-theme',
        disabled: false,
        type: DashboardItemsDataType.dms,
        license: ProductNames.Basis,
        requiredPermissions: [Permissions.GetFiles],
    },
    {
        title: 'Personal & Urlaub',
        route: UserAdministrationMeta.Path,
        image: 'users',
        theme: 'user-theme',
        disabled: false,
        type: DashboardItemsDataType.employeeAdministration,
        license: null,
        requiredPermissions: [Permissions.GetEmployees],
    },
    {
        title: 'Rechnungen & Angebote',
        route: 'rechnungen_und_angebote',
        image: 'file-invoice-dollar',
        theme: 'reports-theme',
        disabled: false,
        type: DashboardItemsDataType.invoices,
        license: ProductNames.Accounting,
        requiredPermissions: null,
    },

    {
        title: 'Berichte',
        route: 'berichte',
        image: 'file-signature',
        theme: 'generated-documents-theme',
        disabled: false,
        type: DashboardItemsDataType.generatedDocuments,
        license: null,
        requiredPermissions: [Permissions.GetGeneratedDocuments],
    },

    {
        title: 'Chronik',
        route: 'chronik',
        image: 'history',
        theme: 'history-theme',
        disabled: false,
        type: DashboardItemsDataType.chronik,
        license: null,
        requiredPermissions: [Permissions.GetEvents, Permissions.GetServiceHistory],
    },
    {
        title: 'Videodoku',
        route: 'videodokumentation',
        image: 'video',
        theme: 'video-theme',
        disabled: false,
        type: DashboardItemsDataType.videoDoku,
        license: ProductNames.Video,
        requiredPermissions: null,
    },
    {
        title: 'Einstellungen',
        route: 'einstellungen',
        image: 'cog',
        theme: 'settings-theme',
        disabled: false,
        type: DashboardItemsDataType.settings,
        license: null,
        requiredPermissions: null,
    },
    {
        title: 'Partner',
        route: 'partner',
        image: 'handshake',
        theme: 'partner-theme',
        disabled: false,
        type: DashboardItemsDataType.partnerAdministration,
        license: ProductNames.Partner,
        requiredPermissions: null,
    },
];
