import { User2CommissionShiftType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';


export class User2CommissionShiftEntity {
    constructor(
        public Id: number = null,
        public UserId: number = null,
        public CommissionId: number = null,
        public PartnerId: number = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
        public StartDate: Date = null,
        public EndDate: Date = null,
    ) {}
    public static GQLFields =`
    id
    userId
    commissionId
    partnerId
    createdAt
    updatedAt
    deletedAt
    startDate
    endDate
    `
}

//res: User2CommissionShiftType gets genereated in graphyql-types later on
export function User2CommissionShiftEntityFromBackend(res: User2CommissionShiftType): User2CommissionShiftEntity {
    return new User2CommissionShiftEntity(
        res.id,
        res.userId,
        res.commissionId,
        res.partnerId,
        BackendDate(res.createdAt),
        BackendDate(res.updatedAt),
        res.deletedAt ? BackendDate(res.deletedAt) : null,
        BackendDate(res.startDate),
        BackendDate(res.endDate),
    );
}
