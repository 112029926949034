import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, firstValueFrom, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {stringifyIfNotNullOrUndefined, stringifyIfNotUndefined} from '../../../helper/helper';
import { ClockInEntityFromBackend, ClockInEntityGqlFields } from '../../entities/clock-in.entity';
// import { DaveMutationChangeClockInArgs, DaveMutationCreateClockInArgs } from '../../graphql-types';
import { ClockInResolver } from '../../guards/clock-in.resolver';
import { EmployeeResolver } from '../../guards/employee.resolver';
import { WorkDayResolver } from '../../guards/work-day.resolver';
import { WorkedTimesResolver } from '../../guards/worked-times.resolver';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { ClockInActionTypes } from '../actions/clock-in.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { getEmployeesFetched } from '../selectors/employees.selectors';
import { getWorkDaysFetched } from '../selectors/work-day.selectors';
import { getClockInsFetched } from '../selectors/clock-in.selectors';
import { getWorkedTimesFetched } from '../selectors/worked-times.selectors';
import { ClockInDataService } from "../../../services/clock-in-data.service";

export enum ClockInErrorCodes {
    Load = 'Stempelzeit Abrufen fehlgeschlagen',
    Remove = 'Stempelzeit Löschen fehlgeschlagen',
    Modify = 'Stempelzeit ändern fehlgeschlagen',
    Add = 'Stempelzeit hinzufügen fehlgeschlagen',
    Delete = 'Stempelzeit löschen fehlgeschlagen',
}

@Injectable()
export class ClockInEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
        private employeeResolver: EmployeeResolver,
        private clockInResolver: ClockInResolver,
        private workDayResolver: WorkDayResolver,
        private workedTimesResolver: WorkedTimesResolver,
        private clockInDataService: ClockInDataService
    ) {}

    GetAllClockInsQuery$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ClockInActionTypes.Load),
                withLatestFrom(this.store$.select(getToken)),
                switchMap(([{ Payload }, token]) => {
                    const queryString = `query {
          clockIn${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
            ${ClockInEntityGqlFields.join(',')}
          }
        }
        `;
                    return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                        tap((res) => {
                            if (!(Payload?.updatedSince && !res.clockIn.length)) {
                                this.store$.dispatch(
                                    res && res.clockIn
                                        ? Payload?.updatedSince
                                            ? ClockInActionTypes.UpdateMany({
                                                  Payload: res.clockIn.map((val) => ClockInEntityFromBackend(val)),
                                                  updateLatestUpdatedAt: true,
                                              })
                                            : ClockInActionTypes.UpdateAll({
                                                  Payload: res.clockIn.map((val) => ClockInEntityFromBackend(val)),
                                                  updateLatestUpdatedAt: true,
                                              })
                                        : BaseActionTypes.ErrorAction({
                                              Payload: { ToasterMessage: ClockInErrorCodes.Load },
                                          }),
                                );
                            }
                        }),
                        catchError((err, caught) => {
                            this.store$.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ClockInErrorCodes.Load,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            );
                            return EMPTY;
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    ModifyClockIn$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClockInActionTypes.ModifyClockIn),
            withLatestFrom(this.store$),
            concatMap(([{ Payload }, store]) => {

                return this.clockInDataService.changeClockIn(Payload).pipe(
                    map((res) => {
                        if (res && res.changeClockIn) {
                            this.workDayResolver.pollUpdated();
                            this.clockInResolver.pollUpdated();
                            this.employeeResolver.pollUpdated();
                            this.workedTimesResolver.pollUpdated();
                            return ClockInActionTypes.UpdateMany({
                                Payload: [ClockInEntityFromBackend(res.changeClockIn)],
                            });
                        }
                        return BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ClockInErrorCodes.Modify } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ClockInErrorCodes.Modify,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    ModifyClockIns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClockInActionTypes.ModifyClockIns),
            withLatestFrom(this.store$),
            concatMap(([{ Payload }, store]) => {
                if (!Payload.length) {
                    return EMPTY;
                }
                return combineLatest(Payload.map((p) => this.clockInDataService.changeClockIn(p)));
            }),
            map((results) => {
                if (results.every((r) => r?.changeClockIn)) {
                    this.store$.dispatch(ClockInActionTypes.ModifyClockInsSuccess());
                } else {
                    this.store$.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ClockInErrorCodes.Modify } }));
                }
            }),
            catchError((err, caught) => {
                this.store$.dispatch(
                    BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ClockInErrorCodes.Modify,
                            Err: err,
                            Caught: caught,
                        },
                    }),
                );
                return EMPTY;
            }),
        ), { dispatch: false },
    );

    AddClockInsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClockInActionTypes.AddClockInsSuccess, ClockInActionTypes.ModifyClockInsSuccess, ClockInActionTypes.DeleteClockInsSuccess),
            tap(() => {
                this.workDayResolver.pollUpdated();
                this.clockInResolver.pollUpdated();
                this.employeeResolver.pollUpdated();
                this.workedTimesResolver.pollUpdated();
            })
        )
    , {dispatch: false});

    AddClockIn$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClockInActionTypes.AddClockIn),
            withLatestFrom(this.store$),
            concatMap(([{ Payload }, store]) => {

                return this.clockInDataService.createClockIn(Payload).pipe(
                    map((res) => {
                        if (res && res.createClockIn) {
                            this.workDayResolver.pollUpdated();
                            this.clockInResolver.pollUpdated();
                            this.employeeResolver.pollUpdated();
                            this.workedTimesResolver.pollUpdated();
                            return ClockInActionTypes.UpdateMany({
                                Payload: [ClockInEntityFromBackend(res.createClockIn)],
                            });
                        }
                        return BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ClockInErrorCodes.Add } });
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ClockInErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
    AddClockIns$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ClockInActionTypes.AddClockIns),
                withLatestFrom(this.store$),
                concatMap(([{ Payload }, store]) => {
                    if (!Payload.length) {
                        return EMPTY;
                    }

                    return combineLatest(Payload.map((p) => this.clockInDataService.createClockIn(p)));
                }),
                map((results) => {
                    if (results.every((r) => r?.createClockIn)) {
                        this.store$.dispatch(ClockInActionTypes.AddClockInsSuccess());
                    } else {
                        this.store$.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ClockInErrorCodes.Add } }));
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ClockInErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            ),
        { dispatch: false },
    );

    DeleteClockIn$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClockInActionTypes.DeleteClockIn),
            withLatestFrom(this.store$),
            concatMap(([{ Payload }, store]) => {
                const queryString = `
        mutation {
            deleteClockIn(
                id: ${JSON.stringify(Payload.id)}
            ) {
                ${ClockInEntityGqlFields.join(',')}
          }
        }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    tap(() => firstValueFrom(this.store$.select(getEmployeesFetched)).then(f => {if (f) { this.employeeResolver.pollUpdated()}})),
                    tap(() => firstValueFrom(this.store$.select(getWorkDaysFetched)).then(f => {if (f) { this.workDayResolver.pollUpdated()}})),
                    tap(() => firstValueFrom(this.store$.select(getClockInsFetched)).then(f => {if (f) { this.clockInResolver.pollUpdated()}})),
                    tap(() => firstValueFrom(this.store$.select(getWorkedTimesFetched)).then(f => {if (f) { this.workedTimesResolver.pollUpdated()}})),
                    map((res) =>
                        res && res.deleteClockIn
                            ? ClockInActionTypes.UpdateMany({
                                  Payload: [ClockInEntityFromBackend(res.deleteClockIn)],
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ClockInErrorCodes.Delete } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ClockInErrorCodes.Delete,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
}
