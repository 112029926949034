import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, BehaviorSubject, firstValueFrom, merge, Subject, switchMap } from 'rxjs';
import { filter, first, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
// @ts-ignore
import ImageEditor from 'tui-image-editor';
import { FileEntity } from '../../../dave-data-module/entities/file.entity';
import { VersionEntity } from '../../../dave-data-module/entities/version.entity';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { FilesActionTypes } from '../../../dave-data-module/State/actions/files.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getFileById } from '../../../dave-data-module/State/selectors/files.selectors';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { b64toBlob } from '../../../helper/helper';
import { LoadingService } from '../../../services/loading.service';
import { Actions, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';

// const FileSaver = require('file-saver'); // to download edited image to local. Use after npm install file-saver

@Component({
    selector: 'app-image-editor',
    templateUrl: './image-editor.component.html',
    styleUrls: ['./image-editor.component.scss', '../../../../../node_modules/tui-color-picker/dist/tui-color-picker.css', '../../../../../node_modules/tui-image-editor/dist/tui-image-editor.css'],
})
export class ImageEditorComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(private api: HttpService, private store: Store<State>, public BS: BreakpointObserverService, public ls: LoadingService, private actions$: Actions, private apiToasterService: ToastrService) {}
    Version$ = new BehaviorSubject<VersionEntity>(null);
    @Input() set Version(v: VersionEntity) {
        this.Version$.next(v);
    }
    File$ = new BehaviorSubject<FileEntity>(null)
    @Input() set File(v: FileEntity) {
        this.File$.next(v);
    }
    get File() {
        return this.File$.value;
    }
    private afterViewInit$: Subject<void> = new Subject<void>();
    private onDestroy$: Subject<void> = new Subject<void>();
    private instance: ImageEditor;
    public Save$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        combineLatest([this.afterViewInit$, this.File$, this.Version$, this.BS.TouchQuery.pipe(first()), this.store.select(getToken)])
            .subscribe(([, file, version, mobile, token]) => {
                if (!mobile) {
                    if (!!document.querySelector('#tui-image-editor')) {
                        this.instance = new ImageEditor(document.querySelector('#tui-image-editor'), {
                            includeUI: {
                                // loadImage?: {
                                //     path: string;
                                //     name: string;
                                // };
                                // theme?: IThemeConfig;
                                // menu?: string[];
                                // initMenu: 'draw',
                                // uiSize: {
                                //     width: '100%',
                                //     height: '100%',
                                // },
                                menuBarPosition: 'top',
                                usageStatistics: false,
                            },
                            selectionStyle: {
                                cornerSize: 20,
                                rotatingPointOffset: 70,
                            },
                            usageStatistics: false,
                        });
                        this.instance.loadImageFromURL(this.api.GetUrl(version.GetDownloadLink(token), 'file'), file.Name || '');
                        // die methode gibt es und sie behebt den bug das die buttons nicht funktionieren https://github.com/nhn/tui.image-editor/issues/477
                        // @ts-ignore
                        this.instance.ui.activeMenuEvent();
                        // @ts-ignore
                        this.instance.ui._actions.draw.setDrawMode('free', { color: '#FF0000' });
                        // @ts-ignore
                        this.instance.ui.draw.colorPickerInputBox.defaultValue = '#FF0000'
                        // @ts-ignore
                        this.instance.ui.draw.color = '#FF0000'
                        // @ts-ignore
                        this.instance.ui.draw._els.drawColorPicker.color = '#FF0000'
                    } else {
                        console.error("querySelector('#tui-image-editor') not found");
                    }
                } else {
                    // initMobileEditor();
                }
            });
        this.Save$.pipe(
            takeUntil(this.onDestroy$),
            tap(() => {
                const format = this.File.MIMEType === 'image/jpeg' ? 'jpeg' : this.File.MIMEType === 'image/png' ? 'png' : null;
                if (!format) {
                    BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'Bild speichern fehlgeschlagen, falsches Format' } });
                    return;
                }
                const versionUpdatedAt$ = this.store.select(getFileById({ id: this.File.Id })).pipe(map((file) => file.GetLastVersion().UpdatedAt));
                this.ls.startLoading('save-image', { dialogMessage: 'Bild wird gespeichert' });
                firstValueFrom(
                    merge(versionUpdatedAt$.pipe(
                        first(),
                        switchMap((lastUpdatedAt) => versionUpdatedAt$.pipe(filter((updatedAt) => updatedAt > lastUpdatedAt))),
                        tap(() => this.apiToasterService.success('Speichern erfolgreich!')),
                    ), this.actions$.pipe(ofType(BaseActionTypes.ErrorAction)))
                ).then(() => {
                    this.ls.endLoading('save-image');
                });
                this.store.dispatch(
                    FilesActionTypes.UploadFileBackendRequest({
                        Payload: {
                            file: b64toBlob(this.instance.toDataURL({ format, quality: 1 })),
                            params: new Map().set('document_id', this.File.Id),
                        },
                    }),
                );
            }),
        ).subscribe();
    }

    ngAfterViewInit(): void {
        this.afterViewInit$.next();
    }
    ngOnDestroy() {
        this.onDestroy$.next();
    }
}
