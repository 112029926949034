import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { UsersActionTypes } from '../State/actions/users.actions';
import { getUserLatestUpdatedAt, getUsersFetched } from '../State/selectors/users.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class UserResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getUsersFetched, getUserLatestUpdatedAt, UsersActionTypes.Load, store);
    }
}
