import { createAction, props } from '@ngrx/store';
import { CustomerSpecificationTypeEntity } from '../../entities/customerSpecificationType.entity';


export const CustomerSpecificationTypeActionTypes = {
    UpdateCustomerSpecificationType: createAction(
        'data/CustomerSpecificationType/update',
        props<{ Payload: Array<CustomerSpecificationTypeEntity> }>(),
    ),
    GetCustomerSpecificationTypeRequest: createAction(
        'data/CustomerSpecificationType/getAll',
    ),
};

