<h1 mat-dialog-title>Zeiterfassung</h1>
<mat-dialog-content class="content">
    <mat-form-field appearance="outline">
        <mat-label>Datum</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="form.controls.date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
<!--    <h3 class="sub-line">Arbeitstag</h3>-->
    <mat-form-field appearance="outline">
        <mat-label>Arbeitstag</mat-label>
        <app-time-span-picker-select>
            <app-time-picker-select appStartTime required placeholder="Start" [formControl]="form.controls.startTime"></app-time-picker-select>
            <app-time-picker-select appEndTime placeholder="Ende" [min]="form.value.startTime" [offsetTimeForOptionLabel]="form.value.startTime" [formControl]="form.controls.endTime"></app-time-picker-select>
        </app-time-span-picker-select>
    </mat-form-field>
    <h3 *ngIf="form.controls.additionalTimeSpans.controls.length" class="sub-line">Zusätzliche Stempelungen:</h3>
    <mat-accordion displayMode="flat">
        <mat-expansion-panel *ngFor="let control of form.controls.additionalTimeSpans.controls; index as i; first as isFirst; last as isLast" [expanded]="expandedIndex === i" (opened)="expandedIndex = i">
            <mat-expansion-panel-header>
                <span class="header-label">
                    {{ (control.value.startTime || ' -- ') + ' - ' + (control.value.endTime || ' -- ') }}
                </span>
                <button mat-icon-button class="header-button" (click)="onDeleteClick(i)"><fa-icon icon="trash"></fa-icon></button>
            </mat-expansion-panel-header>
            <div *matExpansionPanelContent class="expansion-panel-content">
                <mat-form-field>
                    <mat-label>Zeitraum</mat-label>
                    <app-time-span-picker-select required>
                        <app-time-picker-select appStartTime required placeholder="Start" [max]="form.value.endTime" [min]="isFirst ? form.value.startTime : form.controls.additionalTimeSpans.controls[i - 1].value.endTime" [formControl]="control.controls.startTime"></app-time-picker-select>
                        <app-time-picker-select
                            appEndTime
                            required
                            placeholder="Ende"
                            [max]="form.value.endTime"
                            [min]="control.value.startTime"
                            [offsetTimeForOptionLabel]="control.value.startTime"
                            [formControl]="control.controls.endTime"
                        ></app-time-picker-select>
                    </app-time-span-picker-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Art</mat-label>
                    <mat-select [formControl]="control.controls.clockInTypeId">
                        <mat-option *ngFor="let type of clockInTypes$ | async" [value]="type.Id">{{ type.Name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ cls.getSingle$('Commission') | async }}</mat-label>
                    <app-select-search [formControl]="control.controls.commissionId" [Options]="commissions$ | async"></app-select-search>
                    <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer;" (click)="control.controls.commissionId.setValue(null);$event.stopPropagation();" *ngIf="!control.controls.commissionId.disabled"></fa-icon>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Notiz</mat-label>
                    <input matInput type="text" [formControl]="control.controls.note" />
                </mat-form-field>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <button
        mat-button
        (click)="onAddFormClick()"
        [disabled]="!(hasPermissions$ | async)"
        class="add-button"
    >
        <fa-icon icon="plus"></fa-icon> Stempelungen hinzufügen
    </button>
</mat-dialog-content>
<mat-dialog-actions class="buttons">
    <button app-button [IsLoading]="ls.IsLoading$ | async" Color="cancel" Inverted (click)="closeDialog()">abbrechen</button>
    <button app-button [IsLoading]="ls.IsLoading$ | async" Color="green" (click)="submit()" [Disabled]="form.invalid || !(hasPermissions$ | async)">speichern</button>
</mat-dialog-actions>
