import { createAction, props } from '@ngrx/store';
import {
    DaveMutationChangeCommentArgs,
    DaveMutationChangeLastSeenCommentArgs,
    DaveMutationCreateCommentArgs,
    DaveMutationDeleteCommentArgs,
    QueryCommentArgs,
} from '../../graphql-types';
import { CommentEntity } from '../../entities/comment.entity';


export const CommentActionTypes = {
    Load: createAction('data/comment/loadAll', props<{ Payload?: QueryCommentArgs }>()),
    UpdateAll: createAction('data/comment/updateAll', props<{ Payload: Array<CommentEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/comment/updateMany', props<{ Payload: Array<CommentEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction('data/comment/updateOne', props<{ Payload: CommentEntity }>()),
    RemoveOne: createAction('data/comment/removeOne', props<{ Payload: number }>()),
    Change: createAction('data/comment/modify', props<{ Payload: DaveMutationChangeCommentArgs }>()),
    Create: createAction('data/comment/create', props<{ Payload: DaveMutationCreateCommentArgs }>()),
    Delete: createAction('data/comment/delete', props<{ Payload: DaveMutationDeleteCommentArgs }>()),
    SetLastSeen: createAction('data/comment/setLastSeen', props<{ Payload: DaveMutationChangeLastSeenCommentArgs }>()),
};
