<div *ngIf="DataInitialized$ | async; else loading" cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="full-height border-box flex-column" [style.cursor]="LS.isLoading ? 'progress' : 'default'">
    <div class="full-width drag-bar" cdkDragHandle>
        <fa-icon icon="arrows-alt"></fa-icon>
        <fa-icon class="close-button" icon="times" size="2x" (click)="onCloseClick()"></fa-icon>
    </div>
    <div class="wrapper">
        <div class="flex-row">
            <div class="full-width">
                <button class="margin05" app-round-button matTooltip="Senden" (click)="Send$.next()" [IsLoading]="LS.IsLoading$ | async" [Disabled]="SourceEmailForm.invalid || !((TargetEmails$ | async)?.length > 0) || (MailSize$ | async) > MaxEmailSize">
                    <fa-icon icon="paper-plane"></fa-icon>
                </button>
                <h4 *ngIf="(MailSize$ | async) > MaxEmailSize"><fa-icon icon="triangle-exclamation" style="margin-right: 0.25rem"></fa-icon>Die Email darf maximal 50mb groß sein</h4>
                <mat-form-field class="full-width">
                    <app-select-search required [placeholder]="'Absender'" [formControl]="SourceEmailForm" [Options]="SourceEmailList" [CompareOptions]="compareById"></app-select-search>
                </mat-form-field>
                <div class="full-width flex-row">
                    <app-email-form
                        class="full-width"
                        [InitialValue]="InitTargetEmails || []"
                        [AllEmails]="EmailList$ | async"
                        [SelectedEmails]="TargetEmails$ | async"
                        (SelectedEmailsChange)="TargetEmails$.next($event)"
                        Placeholder="Empfänger"
                    ></app-email-form>
                    <button class="margin05" app-round-button matTooltip="aus Gruppe einfügen" *ngIf="!(Groups$ | async) || (Groups$ | async).length > 0" [IsLoading]="!(Groups$ | async)" [matMenuTriggerFor]="groupsMenu">
                        <fa-icon [icon]="GroupsIcon"></fa-icon>
                    </button>
                    <mat-menu #groupsMenu="matMenu">
                        <button mat-menu-item *ngFor="let group of Groups$ | async" (click)="onGroupClick(group)">{{ group.Name }} ({{ group.GroupMembers.length }})</button>
                    </mat-menu>
                    <button class="margin05" app-round-button matTooltip="Weitere Empfänger anzeigen" (click)="ExpandEmailForms = !ExpandEmailForms">
                        <fa-icon [icon]="ExpandEmailForms ? 'chevron-up' : 'chevron-down'"></fa-icon>
                    </button>
                </div>
                <app-email-form [class.hidden]="ExpandEmailForms" [InitialValue]="InitCCEmails || []" [AllEmails]="EmailList$ | async" (SelectedEmailsChange)="CCEmails$.next($event)" Placeholder="CC"></app-email-form>
                <app-email-form [class.hidden]="ExpandEmailForms" [InitialValue]="InitBCCEmails || []" [AllEmails]="EmailList$ | async" (SelectedEmailsChange)="BCCEmails$.next($event)" Placeholder="BCC"></app-email-form>
                <app-textfield class="full-width" Key="Betreff" [Value]="EmailSubjectForm"></app-textfield>
            </div>
            <ng-container *ngIf="!(IsMobile$ | async)" [ngTemplateOutlet]="attachmentWrapper"></ng-container>
        </div>
        <!--        <ckeditor [(ngModel)]="EditorContent" [config]="EditorConfig" [editor]="Editor" (change)="EditorChangeContent$.next($event)"></ckeditor>-->

        <app-ck-editor *ngIf="defaultFontValues$ | async as font" [defaultFontSize]="font.size" [defaultFontFamily]="font.family" [Content]="EditorContentForm.value" (ContentChange)="onEditorContentChange($event)" [CKEditorConfig]="EditorConfig"></app-ck-editor>
        <mat-expansion-panel style="margin-top: 1rem; width: 100%">
            <mat-expansion-panel-header>
                <mat-panel-title> Erweitert </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field style="width: 100%">
                <mat-label>Unternehmenssignatur</mat-label>
                <mat-select [formControl]="PartnerOfficeForm">
                    <mat-option *ngFor="let po of PartnerOffices$ | async" [value]="po">{{ po.Name }}</mat-option>
                    <mat-option [value]="null">--</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-expansion-panel>
        <ng-container *ngIf="IsMobile$ | async" [ngTemplateOutlet]="attachmentWrapper"></ng-container>
    </div>
</div>
<ng-template #attachmentWrapper>
    <div class="file-attachment-wrapper">
        <div class="full-width flex-row file-attachment">
            <h3><fa-icon icon="file"></fa-icon> Anhang</h3>
            <button class="margin05" app-round-button [matMenuTriggerFor]="menu">
                <fa-icon icon="paperclip"></fa-icon>
            </button>
        </div>
        <button
            app-button
            *ngIf="ReferredEventId && !attachmentIncludesIcsFileFromReferredEvent"
            (click)="addIcsFromEvent()"
            matTooltip="Kalenderdatei hinzufügen"
            style="line-height: 20px; margin-bottom: .25rem;"
            [IsLoading]="LS.IsLoading$ | async"
        >
            <fa-icon icon="plus"></fa-icon>
            Kalenderdatei
        </button>
        <ngx-simplebar class="file-list" *ngIf="ShownFileAttachment$ | async as files; else loading">
            <div *ngFor="let file of files" class="flex-row align-center">
                <p *ngIf="file.documentId" class="file-name clickable" (click)="OpenFilePreview(file.documentId)">{{ file.Name }}</p>
                <ng-container *ngIf="!file.documentId">
                    <p class="file-name">{{ file.Name }}</p>
                    <app-loading [Size]="24"></app-loading>
                </ng-container>
                <fa-icon icon="trash" class="remove-file-icon" (click)="RemoveFileFromAttachment(file)"></fa-icon>
            </div>
        </ngx-simplebar>
    </div>
</ng-template>
<ng-template #loading>
    <app-loading Size="75"></app-loading>
</ng-template>
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="OpenFileUpload('dms')">
        <fa-icon [icon]="DMSMeta.Icon"></fa-icon>
        <span style="margin-left: 1rem">aus DMS</span>
    </button>
    <button mat-menu-item (click)="OpenFileUpload('filesystem')">
        <fa-icon [icon]="'upload'"></fa-icon>
        <span style="margin-left: 1rem">vom Computer</span>
    </button>
    <button mat-menu-item (click)="OpenFileUpload('reports')">
        <fa-icon [icon]="ReportsMeta.Icon"></fa-icon>
        <span style="margin-left: 1rem">aus Berichte</span>
    </button>
    <button mat-menu-item (click)="OpenCreateEvent()">
        <fa-icon icon="history"></fa-icon>
        <span style="margin-left: 1rem">Termin erstellen</span>
    </button>
</mat-menu>
