import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { StatusFromBackofficeEntity } from '../../entities/statusFromBackoffice.entity';
import { StatusFromBackofficeActionTypes } from '../actions/statusFromBackoffice.actions';

export const StatusFromBackofficeFeatureKey = 'statusFromBackoffice';
export interface StatusFromBackofficeState extends EntityState<StatusFromBackofficeEntity> {
    fetched: boolean;
}
const adapter = createEntityAdapter<StatusFromBackofficeEntity>({
    selectId: (Entity) => Entity.Id,
    sortComparer: (a, b) => (a.DisplayOrder || 0) - (b.DisplayOrder || 0),
});
const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const StatusFromBackofficeReducer = createReducer(
    initialState,
    on(StatusFromBackofficeActionTypes.UpdateStatusFromBackoffice, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
);
/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllStatusFromBackoffice = selectAll;
export const selectStatusFromBackofficeEntities = selectEntities;
export const selectStatusFromBackofficeFetched = (state: StatusFromBackofficeState) => state.fetched;
