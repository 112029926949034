import { BackendDateTimestamp } from '../../helper/backend-frontend-conversion.helper';
import { AkkordType } from '../../graphql-types';


export class AkkordEntity {
    constructor(
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public Id: number = null,
        public Price: number = null, // fehlt im be deshalb wird multiplicator verwendet
        public Name: string = null,
        public PartnerId: number = null,
        public QuantityType: string = null,
        public UpdatedAt: Date = null,
) {
    }
    public static readonly GqlFields = `
createdAt
deletedAt
id
multiplicator
name
partnerId
quantityType
updatedAt`;
    public Clone(override: AkkordEntityAttributes = {}): AkkordEntity {
        const attr = {...this, ...override};
        return Object.assign(new AkkordEntity(), attr);
    }
}

export function AkkordEntityFromBackend(res: AkkordType): AkkordEntity {
    return new AkkordEntity(
        res.createdAt ? BackendDateTimestamp(res.createdAt) : null,
        res.deletedAt ? BackendDateTimestamp(res.deletedAt) : null,
        res.id,
        res.multiplicator,
        res.name,
        res.partnerId,
        res.quantityType,
        res.updatedAt ? BackendDateTimestamp(res.updatedAt) : null,
    );
}

export interface AkkordEntityAttributes {
    CreatedAt?: Date;
    DeletedAt?: Date;
    Id?: number;
    Price?: number;
    Name?: string;
    PartnerId?: number;
    QuantityType?: string;
    UpdatedAt?: Date;
}

