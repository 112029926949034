import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Action, ActionCreator, NotAllowedCheck, Selector, TypedAction } from '@ngrx/store/src/models';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';
import { State } from '../State';
import { getToken } from '../State/selectors/base.selectors';

export class BaseEntityResolver implements Resolve<boolean> {
    /**
     * Implements Basic resolve function for an entity
     * @param fetchSelector - The Selector to get if Entity is fetched
     * @param fetchAction - The Action wich should be called to Fetch Entity
     * @param store - The Store
     */
    constructor(private fetchSelector: Selector<State, boolean>, private fetchAction: Action, private store: Store<State>) {}
    // tslint:disable-next-line:naming-convention
    resolve() {
        const value$ = combineLatest([this.store.select(this.fetchSelector), this.store.select(getToken).pipe(filter((value) => !!value))]).pipe(map(([value]) => value));

        value$.pipe(take(1)).subscribe((value) => {
            if (!value) {
                this.store.dispatch(this.fetchAction);
            }
        });

        return value$.pipe(
            filter((value) => !!value),
            take(1),
        );
    }

    /**
     * @deprecated this resolves all data, use {@link BaseEntityReloadResolver2} instead
     */
    pollUpdated() {
        this.resolve();
    }
}
/**
 * @deprecated Use the new {@link BaseEntityReloadResolver2} base class instead.
 */
export class BaseEntityReloadResolver implements Resolve<boolean> {
    /**
     * Implements Basic resolve function for an entity
     * @param fetchSelector - The Selector to get if Entity is fetched
     * @param fetchAction - The Action wich should be called to Fetch Entity
     * @param reloadAction - The Action wich should be called to Reload Entity
     * @param store - The Store
     */
    constructor(private fetchSelector: Selector<State, boolean>, private fetchAction: Action, private reloadAction: Action, private store: Store<State>) {}
    // tslint:disable-next-line:naming-convention
    resolve() {
        const value$ = combineLatest([this.store.select(this.fetchSelector), this.store.select(getToken).pipe(filter((value) => !!value))]).pipe(map(([value]) => value));

        value$.pipe(take(1)).subscribe((value) => {
            if (!value) {
                this.store.dispatch(this.fetchAction);
            } else {
                this.store.dispatch(this.reloadAction);
            }
        });

        return value$.pipe(
            filter((value) => !!value),
            take(1),
        );
    }
}
export interface UploadActionProps {
    Payload?: {
        updatedSince?: string;
    };
}
export class BaseEntityReloadResolver2 implements Resolve<boolean> {
    /**
     * Implements Basic resolve function for an entity
     * @param fetchSelector - The Selector to get if Entity was fetched
     * @param updatedSinceSelector - The Selector to get when Entity was fetched
     * @param fetchAction - The Action wich should be called to Fetch Entity
     * @param store - The Store
     * @param parseDateFunction - Function to parse date in to string for request, the default "(value) => moment(value).toISOString()" is for .NET gateway use "GetTimestampFromTime()" for go Gateway
     */
    constructor(
        private fetchSelector: Selector<State, boolean>,
        private updatedSinceSelector: Selector<State, Date>,
        private fetchAction: ActionCreator<any, (props: UploadActionProps & NotAllowedCheck<UploadActionProps>) => UploadActionProps & TypedAction<any>>,
        protected store: Store<State>,
        private parseDateFunction: (d: Date) => string = (value) => moment(value).toISOString(),
    ) {}
    // tslint:disable-next-line:naming-convention
    resolve() {
        combineLatest([this.store.select(this.updatedSinceSelector), this.store.select(getToken).pipe(filter((value) => !!value))])
            .pipe(
                map(([value]) => value),
                first(),
            )
            .subscribe((value) => {
                this.store.dispatch(this.fetchAction({ Payload: { updatedSince: value && this.parseDateFunction(value) } }));
            });

        return this.store.select(this.fetchSelector).pipe(
            filter((value) => !!value),
            first(),
        );
    }
    pollUpdated() {
        this.resolve();
    }
}
