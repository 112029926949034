<h1 mat-dialog-title>
    <fa-icon icon="video"></fa-icon>
    Event mit Videoübetragung
</h1>
<form [formGroup]="NewEventForm" (ngSubmit)="Submit()" mat-dialog-content>
    <div class="contactPersonWrapper">
        <mat-form-field class="type">
<!--            <mat-select-->
<!--                [placeholder]="this.Customer ? this.Customer.AnsprechpartnerText() : 'Ansprechpartner / Sachbearbeiter'"-->
<!--                [formControl]="NewEventForm.controls.Person"-->
<!--            >-->
<!--                <mat-option-->
<!--                    *ngFor="let option of PersonList"-->
<!--                    [value]="option"-->
<!--                >{{option.DisplayName}}</mat-option-->
<!--                >-->
<!--            </mat-select>-->
            <app-select-search
                [placeholder]="this.Customer ? this.Customer.AnsprechpartnerText() : 'Ansprechpartner / Sachbearbeiter'"
                [formControl]="NewEventForm.controls.Person"
                [Options]="PersonList"
            ></app-select-search>
            <mat-error *ngIf="NewEventForm.controls.Person.invalid">
                {{ GetErrorMessage(NewEventForm.controls.Person) }}
            </mat-error>
        </mat-form-field>
        <span class="button" [matTooltip]="this.Customer ? '' : (CS.GetSingle$()|async) + ' wählen'">
                <button
                    type="button"
                    app-round-button
                    class="button"
                    [matTooltip]="this.Customer?.AnsprechpartnerText()"
                    (click)="NewContactPerson()"
                >
                <fa-icon icon="plus"></fa-icon>
            </button>
        </span>
    </div>

    <div class="date-time-picker">
        <app-datepicker
            Key="Startdatum"
            [Value]="NewEventForm.controls.EventDate.value"
            (Input)="OnDateChange($event.value)"
            [Required]="true"
        ></app-datepicker>

        <mat-form-field>
            <input
                class="hide-icon-from-browser"
                matInput
                type="time"
                placeholder="Start"
                [formControl]="NewEventForm.controls.EventStartTimeString"
                required
                #inp1
            />
            <fa-icon icon="clock" class="picker-icon" (click)="OpenHTMLInputPicker(inp1)" matSuffix></fa-icon>
        </mat-form-field>
    </div>
    <div class="date-time-picker">
        <app-datepicker
            Key="Enddatum"
            [Value]="NewEventForm.controls.EventEndDate.value"
            (Input)="OnEndDateChange($event.value)"
            [Required]="true"
        ></app-datepicker>

        <mat-form-field>
            <input
                class="hide-icon-from-browser"
                matInput
                type="time"
                placeholder="Ende"
                [formControl]="NewEventForm.controls.EventEndTimeString"
                required
                #inp2
            />
            <fa-icon icon="clock" class="picker-icon" (click)="OpenHTMLInputPicker(inp2)" matSuffix></fa-icon>
        </mat-form-field>
    </div>

    <mat-form-field class="full-width">
        <mat-label>Name</mat-label>
        <input matInput [formControl]="NewEventForm.controls.Name" required>
    </mat-form-field>

    <app-textarea Key="Notiz" [Value]="NewEventForm.controls.Note"></app-textarea>

    <div class="error-message">{{ Error }}</div>
</form>
<mat-dialog-actions class="buttons">
    <button
        Inverted
        type="button"
        app-button
        mat-dialog-close
        Color="cancel"
    >
        Abbrechen
    </button>

    <div class="spacer"></div>

    <button
        app-button
        [Disabled]="NewEventForm.invalid"
        (click)="Submit()"
        mat-dialog-close
    >
        Speichern
    </button>
</mat-dialog-actions>
