import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ListLayoutWithRoutingService {
    private _resizeEvent$: Subject<void> = new Subject<void>();
    constructor() {}
    get resizeEvent$() {
        return this._resizeEvent$.pipe();
    }
    public emmitResizeEvent() {
        this._resizeEvent$.next();
    }
}
