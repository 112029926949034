import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, concatMap, filter, map, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {DaveActions} from '../actions/actions';
import {CustomerTypeActionTypes} from '../actions/customer-types.actions';
import {ResolverActionTypes} from '../actions/resolver.actions';
import {CustomerTypeEntity} from '../../entities/customer-type.entity';
import {HttpService} from '../../services/http.service';
import {State} from '../index';
import {BaseActionTypes} from '../actions/base.actions';
import { getToken } from '../selectors/base.selectors';
import { getCustomerTypes } from '../selectors/customers.selectors';
import { isNotNullOrUndefined, stringifyIfNotNullOrUndefined } from '../../../helper/helper';

enum ErrorCodes {
    Add = 'Kundentyp Hinzufügen fehlgeschlagen',
    Load = 'Kundentypen Abrufen fehlgeschlagen',
    Delete = 'Kundentyp Löschen fehlgeschlagen',
}

@Injectable()
export class CustomerTypesEffects {

    Get$ = createEffect(() => this.actions$.pipe(
        ofType(ResolverActionTypes.LoadCustomerTypes),
        map(
            () => `
            {
                customertype {
                    ${CustomerTypeEntity.GqlFields}
                }
            }`
        ),
        withLatestFrom(this.store$.select(getToken)),
        switchMap(([query, token]) =>
            this.gatewayHttpService.graphQl({query}, {token, retry: true }).pipe(
                map(res => res && res.customertype),
                concatMap(res =>
                    res
                        ? of(
                        CustomerTypeActionTypes.UpdateCustomerTypes({
                            Payload: res.map(ct => new CustomerTypeEntity(ct))
                        })
                        )
                        : of(BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Load}}))
                ),
                catchError((err, caught) => of(BaseActionTypes.ErrorAction({
                    Payload: {
                        ToasterMessage: ErrorCodes.Load,
                        Err: err,
                        Caught: caught
                    }
                })))
            )
        )
    ));


    Post$ = createEffect(() => this.actions$.pipe(
        ofType(CustomerTypeActionTypes.AddCustomerType),
        map(
            ({Payload}) => `
            mutation {
                createCustomerType(${stringifyIfNotNullOrUndefined(Payload, 'name')}) {
                    ${CustomerTypeEntity.GqlFields}
                }
            }`
        ),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([query, token]) =>
            this.gatewayHttpService.graphQl({query}, {token}).pipe(
                map(res => res && res.createCustomerType),
                concatMap(res =>
                    res
                        ? this.store$.select(getCustomerTypes).pipe(
                        filter(isNotNullOrUndefined),
                        take(1),
                        map(customerTypes =>
                            CustomerTypeActionTypes.UpdateCustomerTypes({
                                Payload: [...customerTypes, new CustomerTypeEntity(res)]
                            })
                        )
                        )
                        : [BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Add}})]
                ),
                catchError((err, caught) =>
                    // Besonderes Error Handling: Trotz Fehler einen neuen Wert für CustomerTypes in den
                    // Store geben, damit CustomerTypeChipAutocomplete das Eingabefeld wieder aktiviert
                    this.store$.select(getCustomerTypes).pipe(
                        filter(isNotNullOrUndefined),
                        take(1),
                        concatMap(customerTypes => [
                            CustomerTypeActionTypes.UpdateCustomerTypes({
                                Payload: [...customerTypes]
                            }),
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught
                                }
                            })
                        ])
                    )
                )
            )
        )
    ));


    Delete$ = createEffect(() => this.actions$.pipe(
        ofType(CustomerTypeActionTypes.DeleteCustomerType),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{Payload: {id}}, token]) =>
            this.gatewayHttpService
                .graphQl(
                    {
                        query: `
                        mutation {
                            deleteCustomerType(id: ${JSON.stringify(id)})
                        }`
                    },
                    {token}
                )
                .pipe(
                    map(res => res && res.deleteCustomerType),
                    concatMap(res =>
                        res
                            ? this.store$.select(getCustomerTypes).pipe(
                            filter(isNotNullOrUndefined),
                            take(1),
                            map(customerTypes =>
                                CustomerTypeActionTypes.UpdateCustomerTypes({
                                    Payload: customerTypes.filter(
                                        customerType => customerType.Id !== id
                                    )
                                })
                            )
                            )
                            : [BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Delete}})]
                    ),
                    catchError(
                        (err, caught) => (
                            this.store$.dispatch(BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Delete,
                                    Err: err,
                                    Caught: caught
                                }
                            })),
                                caught
                        )
                    )
                )
        )
    ));

    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService
    ) {
    }
}
