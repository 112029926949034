import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { DaveActions } from '../actions/actions';
import { ResolverActionTypes } from '../actions/resolver.actions';
import { HttpService } from '../../services/http.service';
import { State } from '../index';
import { BaseActionTypes } from '../actions/base.actions';
import { getToken } from '../selectors/base.selectors';
import { isNotNullOrUndefined, stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { DetectionEntity } from '../../entities/detection.entity';
import { DetectionActionTypes } from '../actions/detection.actions';
import { getDetection } from '../selectors/detection.selectors';

enum ErrorCodes {
    Add = 'Dokumentenzuordnung Hinzufügen fehlgeschlagen',
    Load = 'Dokumentenzuordnung Abrufen fehlgeschlagen',
    Modify = 'Dokumentenzuordnung Ändern fehlgeschlagen',
    Delete = 'Dokumentenzuordnung Löschen fehlgeschlagen',
}

@Injectable()
export class DetectionEffects {


    LoadDetections$ = createEffect(() => this.actions$.pipe(
        ofType(ResolverActionTypes.LoadDetections),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
            query {
                detection {
                    ${DetectionEntity.GqlFields}
                }
            }`;
            return this.gatewayHttpService
                .graphQl({query: queryString}, {token: store.base.token, retry: true })
                .pipe(
                    map(res =>
                        res && res.detection
                            ? DetectionActionTypes.UpdateDetections({
                                Payload: res.detection.map(val =>
                                    new DetectionEntity(val),
                                ),
                            })
                            : BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Load}}),
                    ),
                    catchError((err, caught) =>
                        of(BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            }
                        })),
                    ),
                );
        }),
    ));


    Modify$ = createEffect(() => this.actions$.pipe(
        ofType(DetectionActionTypes.ModifyDetection),
        map(
            ({Payload}) => `
            mutation {
                changeDetection(
                ${stringifyIfNotNullOrUndefined(Payload, 'id')}
                ${stringifyIfNotNullOrUndefined(Payload, 'documentType')}
                ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'customerId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'folderId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'eventId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'detectedProperties')}
                ) {
                    ${DetectionEntity.GqlFields}
                }
            }`
        ),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([query, token]) =>
            this.gatewayHttpService.graphQl({query}, {token, retry: true }).pipe(
                map(res => res && res.changeDetection),
                concatMap(res => res
                    ? this.store$.select(getDetection).pipe(
                        filter(isNotNullOrUndefined),
                        take(1),
                        map(detections =>
                            DetectionActionTypes.UpdateDetections(
                                {
                                    Payload: [
                                        ...detections.map(val =>
                                            val.Id === res.id
                                                ? new DetectionEntity(res)
                                                : val,
                                        ),
                                    ],
                                }
                            )
                        )
                    )
                    : [BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Modify}})]
                ),
                catchError((err, caught) =>
                    of(BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Modify,
                            Err: err,
                            Caught: caught,
                        }
                    })),
                ),
            )
        )
    ));

    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService
    ) {
    }
}
