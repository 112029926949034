import { createAction, props } from '@ngrx/store';
import { DamageFlowEntity } from '../../entities/damageflow.entity';
import { DaveMutationChangeDamageFlowArgs, DaveMutationCreateDamageFlowArgs, QueryDamageFlowArgs } from '../../graphql-types';

export const DamageFlowActionTypes = {
    UpdateAll: createAction('data/damageFlow/updateAll', props<{ Payload: Array<DamageFlowEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/damageFlow/updateMany', props<{ Payload: Array<DamageFlowEntity>, updateLatestUpdatedAt?: boolean }>()),
    ModifyDamageFlow: createAction('data/damageFlow/modify', props<{ Payload: DaveMutationChangeDamageFlowArgs }>()),
    AddDamageFlow: createAction('data/damageFlow/add', props<{ Payload: DaveMutationCreateDamageFlowArgs }>()),
    DeleteDamageFlow: createAction('data/damageFlow/delete', props<{ Payload: number }>()),
    Load: createAction('data/damageFlow/getAll', props<{ Payload: QueryDamageFlowArgs }>()),
};
