import { AfterViewInit,Component,ElementRef,Input } from '@angular/core';
import { Actions,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fabric } from 'fabric';
import { saveAs } from 'file-saver';
import $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom,merge,switchMap } from 'rxjs';
import { filter,first,map,take,tap } from 'rxjs/operators';
import colorPicker from 'tui-color-picker';
import ImageEditor from 'tui-image-editor';
import { FileEntity } from '../../../../dave-data-module/entities/file.entity';
import { VersionEntity } from '../../../../dave-data-module/entities/version.entity';
import { HttpService } from '../../../../dave-data-module/services/http.service';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { FilesActionTypes } from '../../../../dave-data-module/State/actions/files.actions';
import { getToken } from '../../../../dave-data-module/State/selectors/base.selectors';
import { getFileById } from '../../../../dave-data-module/State/selectors/files.selectors';
import { b64toBlob } from '../../../../helper/helper';
import { LoadingService } from '../../../../services/loading.service';
import { initPinchZoom } from './pinch-zoom.helper';
function hexToRGBa(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16);
    var g = parseInt(hex.slice(3, 5), 16);
    var b = parseInt(hex.slice(5, 7), 16);
    var a = alpha || 1;

    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
}
@Component({
    selector: 'app-mobile-image-editor',
    templateUrl: './mobile-image-editor.component.html',
    styleUrls: ['./mobile-image-editor.component.scss'],
})
export class MobileImageEditorComponent implements AfterViewInit {
    constructor(private store: Store<State>, private api: HttpService, public ls: LoadingService, private actions$: Actions, private apiToasterService: ToastrService, private elementRef: ElementRef) {}
    @Input() File: FileEntity;
    @Input() Version: VersionEntity;
    private imageEditor: ImageEditor;
    public ShowMenuButtons: boolean = true;
    ngAfterViewInit(): void {
        this.initImageViewer();
        // setTimeout(() => this.opedRedPencil(), 5000);
        this.openFreeDrawing();
        this.store.select(getToken).pipe(
            take(1)
        )
            .subscribe((token) => {
                this.imageEditor.loadImageFromURL(
                    this.api.GetUrl(this.Version.GetDownloadLink(token), 'file'),
                    this.File.Name || '',
                );
            });
    }

    public Save() {
        const format = this.File.MIMEType === 'image/jpeg' ? 'jpeg' : this.File.MIMEType === 'image/png' ? 'png' : null;
        if (!format) {
            BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'Bild speichern fehlgeschlagen, falsches Format'}});
            return;
        }
        const versionUpdatedAt$ = this.store.select(getFileById({ id: this.File.Id })).pipe(map((file) => file.GetLastVersion().UpdatedAt));
        this.ls.startLoading('save-image', { dialogMessage: 'Bild wird gespeichert' });
        firstValueFrom(
            merge(versionUpdatedAt$.pipe(
                first(),
                switchMap((lastUpdatedAt) => versionUpdatedAt$.pipe(filter((updatedAt) => updatedAt > lastUpdatedAt))),
                tap(() => this.apiToasterService.success('Speichern erfolgreich!')),
            ), this.actions$.pipe(ofType(BaseActionTypes.ErrorAction)))
        ).then(() => {
            this.ls.endLoading('save-image');
        });
        this.store.dispatch(
            FilesActionTypes.UploadFileBackendRequest({
                Payload: {
                    file: b64toBlob(this.imageEditor.toDataURL({ format, quality: 1 })),
                    params: new Map().set('document_id', this.File.Id),
                },
            }),
        );
    }
    public openFreeDrawing() {
        const $drawMenuButton = this.elementRef.nativeElement.querySelector('.tui-image-editor-controls .menu-button#btn-draw-line');
        const $freeDrawButton = this.elementRef.nativeElement.querySelector('.tui-image-editor-controls #btn-free-drawing');
        $drawMenuButton.click();
        $freeDrawButton.click();
    }

    private initImageViewer() {
        // this code is mostly from the Example at https://github.com/nhn/tui.image-editor/blob/master/apps/image-editor/examples/js/service-mobile.js
        var MAX_RESOLUTION = 3264 * 2448; // 8MP (Mega Pixel)

        var supportingFileAPI = !!(window.File && window.FileList && window.FileReader);
        var rImageType = /data:(image\/.+);base64,/;
        var shapeOpt = {
            fill: '#fff',
            stroke: '#000',
            strokeWidth: 10,
        };
        var activeObjectId;

        // Selector of image editor controls
        var submenuClass = '.submenu';
        var hiddenmenuClass = '.hiddenmenu';
        var textHeightClass = '.text-height';
        var textStyleClass = '.text-style'
        var textAlignClass = '.text-align'
        var textColorClass = '.text-color'

        var $controls = $('.tui-image-editor-controls');
        var $menuButtons = $controls.find('.menu-button');
        var $submenuButtons = $controls.find('.submenu-button');
        var $btnShowMenu = $controls.find('.btn-prev');
        var $msg = $controls.find('.msg');

        var $subMenus = $controls.find(submenuClass);
        var $hiddenMenus = $controls.find(hiddenmenuClass);
        var $textHeight = $controls.find(textHeightClass);
        var $textStyle = $controls.find(textStyleClass);
        var $textAlign = $controls.find(textAlignClass);
        var $textColor = $controls.find(textColorClass);
        // Image editor controls - top menu buttons
        var $inputImage = $('#input-image-file');
        var $btnDownload = $('#btn-download');
        var $btnUndo = $('#btn-undo');
        var $btnRedo = $('#btn-redo');
        var $btnRemoveActiveObject = $('#btn-remove-active-object');

        // Image editor controls - bottom menu buttons
        var $btnCrop = $('#btn-crop');
        var $btnAddText = $('#btn-add-text');

        // Image editor controls - bottom submenu buttons
        var $btnApplyCrop = $('#btn-apply-crop');
        var $btnFlipX = $('#btn-flip-x');
        var $btnFlipY = $('#btn-flip-y');
        var $btnRotateClockwise = $('#btn-rotate-clockwise');
        var $btnRotateCounterClockWise = $('#btn-rotate-counter-clockwise');
        var $btnAddArrowIcon = $('#btn-add-arrow-icon');
        var $btnAddCancelIcon = $('#btn-add-cancel-icon');
        var $btnAddCustomIcon = $('#btn-add-custom-icon');
        var $btnFreeDrawing = $('#btn-free-drawing');
        var $btnLineDrawing = $('#btn-line-drawing');
        var $btnAddRect = $('#btn-add-rect');
        var $btnAddSquare = $('#btn-add-square');
        var $btnAddEllipse = $('#btn-add-ellipse');
        var $btnAddCircle = $('#btn-add-circle');
        var $btnAddTriangle = $('#btn-add-triangle');
        var $btnChangeTextStyle = $('.btn-change-text-style');
        var $btnChangeSize = $('#btn-change-size');
        var $btnChangeStyle = $('#btn-change-style');
        var $btnChangeAlign = $('#btn-change-align');
        var $btnChangeColor = $('#btn-change-color');
        var $btnZoomIn = $('#btn-zoom-in');
        var $btnZoomOut = $('#btn-zoom-out');
        var $btnDrag = $('#btn-drag');
        // Image editor controls - etc.
        var $inputTextSizeRange = $('#input-text-size-range');
        var $inputBrushWidthRange = $('#input-brush-range');
        var $inputStrokeWidthRange = $('#input-stroke-range');
        var $inputCheckTransparent = $('#input-check-transparent');

        // Colorpicker
        var iconColorpicker = colorPicker.create({
            container: $('#tui-icon-color-picker')[0],
            color: '#000000',
        });

        var textColorpicker = colorPicker.create({
            container: $('#tui-text-color-picker')[0],
            color: '#000000',
        });

        var brushColorpicker = colorPicker.create({
            container: $('#tui-brush-color-picker')[0],
            color: '#FF0000',
        });

        var shapeColorpicker = colorPicker.create({
            container: $('#tui-shape-color-picker')[0],
            color: '#000000',
        });

        // Create image editor
        this.imageEditor = new ImageEditor('.tui-image-editor', {
            cssMaxWidth: document.documentElement.clientWidth,
            cssMaxHeight: document.documentElement.clientHeight - 160,
            selectionStyle: {
                cornerSize: 50,
                rotatingPointOffset: 100,
            },
            usageStatistics: false,
            includeUI: {
                // uiSize: {
                //     // width: '0',
                //     // height: '0',
                // },
                usageStatistics: false,
            }
        });

        const imageEditor = this.imageEditor;
        var $displayingSubMenu ;
        var $displayingHiddenMenu;
        var $displayingButtonMenu;

        function base64ToBlob(data) {
            var mimeString = '';
            var raw, uInt8Array, i, rawLength;

            raw = data.replace(rImageType, function (header, imageType) {
                mimeString = imageType;

                return '';
            });

            raw = atob(raw);
            rawLength = raw.length;
            uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

            for (i = 0; i < rawLength; i += 1) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array], { type: mimeString });
        }

        function getBrushSettings() {
            var brushWidth = $inputBrushWidthRange.val();
            var brushColor = brushColorpicker.getColor();

            return {
                width: brushWidth as number,
                color: hexToRGBa(brushColor, 0.5),
            };
        }

        function activateShapeMode() {
            imageEditor.stopDrawingMode();
        }

        function activateIconMode() {
            imageEditor.stopDrawingMode();
        }

        function activateTextMode() {
            if (imageEditor.getDrawingMode() !== 'TEXT') {
                imageEditor.stopDrawingMode();
                imageEditor.startDrawingMode('TEXT');
            }
        }

        function setTextToolbar(obj) {
            var fontSize = obj.fontSize;
            var fontColor = obj.fill;

            $inputTextSizeRange.val(fontSize);
            textColorpicker.setColor(fontColor);
        }

        function setIconToolbar(obj) {
            var iconColor = obj.fill;

            iconColorpicker.setColor(iconColor);
        }

        function setShapeToolbar(obj) {
            var strokeColor, fillColor, isTransparent;
            var colorType = $('[name="select-color-type"]:checked').val();

            if (colorType === 'stroke') {
                strokeColor = obj.stroke;
                isTransparent = strokeColor === 'transparent';

                if (!isTransparent) {
                    shapeColorpicker.setColor(strokeColor);
                }
            } else if (colorType === 'fill') {
                fillColor = obj.fill;
                isTransparent = fillColor === 'transparent';

                if (!isTransparent) {
                    shapeColorpicker.setColor(fillColor);
                }
            }

            $inputCheckTransparent.prop('checked', isTransparent);
            $inputStrokeWidthRange.val(obj.strokeWith);
        }

        function showSubMenu(type) {
            var index;

            switch (type) {
                case 'shape':
                    index = 3;
                    break;
                case 'icon':
                    index = 4;
                    break;
                case 'text':
                    index = 5;
                    break;
                default:
                    index = 0;
            }

            $displayingSubMenu.hide();

            $displayingSubMenu = $menuButtons.eq(index).parent().find(submenuClass).show();
        }

        // Bind custom event of image editor
        imageEditor.on('undoStackChanged', function (length) {
            if (length > 1) {
                $btnUndo.removeClass('disabled');
                ($btnUndo[0] as HTMLButtonElement).disabled = false;
            } else {
                $btnUndo.addClass('disabled');
                ($btnUndo[0] as HTMLButtonElement).disabled = true;
            }
        });
        imageEditor.on('redoStackChanged', function (length) {
            if (length) {
                $btnRedo.removeClass('disabled');
                ($btnRedo[0] as HTMLButtonElement).disabled = false;
            } else {
                $btnRedo.addClass('disabled');
                ($btnRedo[0] as HTMLButtonElement).disabled = true;
            }
        });
        imageEditor.on('objectScaled', function (obj) {
            if (obj.type === 'text') {
                $inputTextSizeRange.val(obj.fontSize);
            }
        });
        imageEditor.on('objectActivated', function (obj) {
            activeObjectId = obj.id;
            if (obj.type === 'rect' || obj.type === 'circle' || obj.type === 'triangle') {
                // showSubMenu('shape');
                setShapeToolbar(obj);
                activateShapeMode();
            } else if (obj.type === 'icon') {
                // showSubMenu('icon');
                setIconToolbar(obj);
                activateIconMode();
            } else if (obj.type === 'text') {
                // showSubMenu('text');
                setTextToolbar(obj);
                activateTextMode();
            }
        });
        // Image editor controls action
        $menuButtons.on('click', function () {
            $displayingSubMenu = $(this).parent().find(submenuClass).show();
            $displayingHiddenMenu = $(this).parent().find(hiddenmenuClass).show();
            $menuButtons.hide();
        });

        $submenuButtons.on('click', function () {
        });

        $btnShowMenu.on('click', function () {
            $displayingSubMenu.hide();
            $displayingHiddenMenu = $(this).parent().find(hiddenmenuClass).hide();
            $msg.show();
            $menuButtons.show();
            imageEditor.stopDrawingMode();
        });

        // Image load action
        $inputImage.on('change', function (event) {
            var file;
            var img;
            var resolution;

            if (!supportingFileAPI) {
                alert('This browser does not support file-api');
            }

            // @ts-ignore
            file = event.target.files[0];

            if (file) {
                img = new Image();

                img.onload = function () {
                    resolution = this.width * this.height;

                    if (resolution <= MAX_RESOLUTION) {
                        imageEditor.loadImageFromFile(file).then(function () {
                            imageEditor.clearUndoStack();
                        });
                    } else {
                        alert("Loaded image's resolution is too large!\nRecommended resolution is 3264 * 2448!");
                    }

                    URL.revokeObjectURL(file);
                };

                img.src = URL.createObjectURL(file);
            }
        });

        // Undo action
        $btnUndo.on('click', function () {
            if (!$(this).hasClass('disabled')) {
                imageEditor.undo();
            }
        });

        // Redo action
        $btnRedo.on('click', function () {
            if (!$(this).hasClass('disabled')) {
                imageEditor.redo();
            }
        });

        // Remove active object action
        $btnRemoveActiveObject.on('click', function () {
            imageEditor.removeObject(activeObjectId);
        });

        // Download action
        $btnDownload.on('click', function () {
            var imageName = imageEditor.getImageName();
            var dataURL = imageEditor.toDataURL();
            var blob, type, w;

            if (supportingFileAPI) {
                blob = base64ToBlob(dataURL);
                type = blob.type.split('/')[1];
                if (imageName.split('.').pop() !== type) {
                    imageName += '.' + type;
                }

                // Library: FileSaver - saveAs
                saveAs(blob, imageName); // eslint-disable-line
            } else {
                alert('This browser needs a file-server');
                w = window.open();
                w.document.body.innerHTML = '<img src=' + dataURL + '>';
            }
        });

        // Crop menu action
        $btnCrop.on('click', function () {
            imageEditor.startDrawingMode('CROPPER');
        });

        $btnApplyCrop.on('click', function () {
            imageEditor.crop(imageEditor.getCropzoneRect()).then(function () {
                imageEditor.stopDrawingMode();
                $displayingSubMenu.hide();
                $hiddenMenus.removeClass('show');
                $menuButtons.show()
            });
        });

        // Orientation menu action
        $btnRotateClockwise.on('click', function () {
            imageEditor.rotate(90);
        });

        $btnRotateCounterClockWise.on('click', function () {
            imageEditor.rotate(-90);
        });

        $btnFlipX.on('click', function () {
            imageEditor.flipX();
        });

        $btnFlipY.on('click', function () {
            imageEditor.flipY();
        });

        // Icon menu action
        $btnAddArrowIcon.on('click', function () {
            imageEditor.addIcon('arrow');
        });

        $btnAddCancelIcon.on('click', function () {
            imageEditor.addIcon('cancel');
        });

        $btnAddCustomIcon.on('click', function () {
            imageEditor.addIcon('customArrow');
        });

        iconColorpicker.on('selectColor', function (event) {
            imageEditor.changeIconColor(activeObjectId, event.color);
        });

        // Text menu action
        $btnAddText.on('click', function () {
            var initText = 'DoubleClick';

            imageEditor.startDrawingMode('TEXT');
            imageEditor.addText(initText, {
                styles: {
                    fontSize: parseInt(<string>$inputTextSizeRange.val(), 10),
                },
            });
        });

        $btnChangeSize.on('click', function () {
            $textHeight.addClass('show');
            $textStyle.removeClass('show');
            $textAlign.removeClass('show');
            $textColor.removeClass('show');
        })

        $btnChangeStyle.on('click', function () {
            $textHeight.removeClass('show');
            $textStyle.addClass('show');
            $textAlign.removeClass('show');
            $textColor.removeClass('show');
        })

        $btnChangeAlign.on('click', function () {
            $textHeight.removeClass('show');
            $textStyle.removeClass('show');
            $textAlign.addClass('show');
            $textColor.removeClass('show');
        })

        $btnChangeColor.on('click', function () {
            $textHeight.removeClass('show');
            $textStyle.removeClass('show');
            $textAlign.removeClass('show');
            $textColor.addClass('show');
        })
        let zoomLevel = 1;
        function zoom(v : number, position: {x: number, y: number}) {
            console.log(v, position)
            zoomLevel = zoomLevel + v;
            if (zoomLevel < 1) {
                zoomLevel = 1;
            }
            // @ts-ignore
            imageEditor.zoom({...position, zoomLevel });
        }
        initPinchZoom(document.querySelector(".tui-image-editor"), (v, position) => {
            zoom(v / document.querySelector(".tui-image-editor").clientWidth, position)
        });

        // try drag mode
        // let dragMode = false;
        // $btnDrag.on('click', () => {
        //     console.log(imageEditor)
        //     console.log(imageEditor.ui)
        //     dragMode = !dragMode;
        //     //@ts-ignore
        //     imageEditor.ui.changeHandButtonStatus(dragMode);
        // })
        // let touchStart = null
        // document.querySelector(".tui-image-editor").addEventListener("touchstart", (e: TouchEvent) => {
        //     touchStart = e.touches[0];
        //     console.log({e})
        // });
        // document.querySelector(".tui-image-editor").addEventListener("touchmove", (e: TouchEvent) => {
        //     if (touchStart ) {
        //         const diff = {x: e.touches[0].clientX - touchStart.clientX, y: e.touches[0].clientY - touchStart.clientY}
        //         touchStart = e.touches[0];
        //         // imageEditor.
        //         console.log({e, diff})
        //     }
        // });
        // document.querySelector(".tui-image-editor").addEventListener("touchend", (e) => {
        //     touchStart = null;
        //     console.log({e})
        // });
        $btnChangeTextStyle.on('click', function () {
            var styleType = $(this).attr('data-style-type');
            var styleObj = {};
            var styleObjKey;

            switch (styleType) {
                case 'bold':
                    styleObjKey = 'fontWeight';
                    break;
                case 'italic':
                    styleObjKey = 'fontStyle';
                    break;
                case 'underline':
                    styleObjKey = 'underline';
                    break;
                case 'left':
                    styleObjKey = 'textAlign';
                    break;
                case 'center':
                    styleObjKey = 'textAlign';
                    break;
                case 'right':
                    styleObjKey = 'textAlign';
                    break;
                default:
                    styleObjKey = '';
            }

            styleObj[styleObjKey] = styleType;

            imageEditor.changeTextStyle(activeObjectId, styleObj);
        });

        $inputTextSizeRange.on('change', function () {
            imageEditor.changeTextStyle(activeObjectId, {
                fontSize: parseInt(<string>$(this).val(), 10),
            });
        });

        textColorpicker.on('selectColor', function (event) {
            imageEditor.changeTextStyle(activeObjectId, {
                fill: event.color,
            });
        });

        // Draw line menu action
        $btnFreeDrawing.on('click', function () {
            var settings = getBrushSettings();

            imageEditor.stopDrawingMode();
            imageEditor.startDrawingMode('FREE_DRAWING', settings);
        });

        $btnLineDrawing.on('click', function () {
            var settings = getBrushSettings();

            imageEditor.stopDrawingMode();
            imageEditor.startDrawingMode('LINE_DRAWING', settings);
        });

        $inputBrushWidthRange.on('change', function () {
            imageEditor.setBrush({
                width: parseInt(<string>$(this).val(), 10),
                color: '',
            });
        });

        brushColorpicker.on('selectColor', function (event) {
            imageEditor.setBrush({
                width: 0,
                color: hexToRGBa(event.color, 0.5),
            });
        });

        // Add shape menu action
        $btnAddRect.on('click', function () {
            imageEditor.addShape(
                'rect',
                fabric.util.extend(
                    {
                        width: 500,
                        height: 300,
                    },
                    shapeOpt,
                ),
            );
        });

        $btnAddSquare.on('click', function () {
            imageEditor.addShape(
                'rect',
                fabric.util.extend(
                    {
                        width: 400,
                        height: 400,
                        isRegular: true,
                    },
                    shapeOpt,
                ),
            );
        });

        $btnAddEllipse.on('click', function () {
            imageEditor.addShape(
                'circle',
                fabric.util.extend(
                    {
                        rx: 300,
                        ry: 200,
                    },
                    shapeOpt,
                ),
            );
        });

        $btnAddCircle.on('click', function () {
            imageEditor.addShape(
                'circle',
                fabric.util.extend(
                    {
                        rx: 200,
                        ry: 200,
                        isRegular: true,
                    },
                    shapeOpt,
                ),
            );
        });

        $btnAddTriangle.on('click', function () {
            imageEditor.addShape(
                'triangle',
                fabric.util.extend(
                    {
                        width: 500,
                        height: 400,
                        isRegular: true,
                    },
                    shapeOpt,
                ),
            );
        });

        $inputStrokeWidthRange.on('change', function () {
            imageEditor.changeShape(activeObjectId, {
                strokeWidth: parseInt(<string>$(this).val(), 10),
            });
        });

        $inputCheckTransparent.on('change', function () {
            var colorType = $('[name="select-color-type"]:checked').val();
            var isTransparent = $(this).prop('checked');
            var color;

            if (!isTransparent) {
                color = shapeColorpicker.getColor();
            } else {
                color = 'transparent';
            }

            if (colorType === 'stroke') {
                imageEditor.changeShape(activeObjectId, {
                    stroke: color,
                });
            } else if (colorType === 'fill') {
                imageEditor.changeShape(activeObjectId, {
                    fill: color,
                });
            }
        });

        shapeColorpicker.on('selectColor', function (event) {
            var colorType = $('[name="select-color-type"]:checked').val();
            var isTransparent = $inputCheckTransparent.prop('checked');
            var color = event.color;

            if (isTransparent) {
                return;
            }

            if (colorType === 'stroke') {
                imageEditor.changeShape(activeObjectId, {
                    stroke: color,
                });
            } else if (colorType === 'fill') {
                imageEditor.changeShape(activeObjectId, {
                    fill: color,
                });
            }
        });
    }
}
