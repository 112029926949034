import { TextTemplatesType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';


export class TextTemplatesEntity {
    constructor(
        public Category: string = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public Description: string = null,
        public Id: number = null,
        public IdCode: string = null,
        public MatchingCode: string = null,
        public PartnerId: number = null,
        public QuantityType: string = null,
        public ShortDescription: string = null,
        public UpdatedAt: Date = null,
    ) {}
    public static readonly GqlFields = `category
    createdAt
    deletedAt
    description
    id
    idCode
    matchingCode
    partnerId
    quantityType
    shortDescription
    updatedAt`;
    public Clone(override: TextTemplatesAttributes = {}): TextTemplatesEntity {
        const attr = { ...this, ...override };
        return Object.assign(new TextTemplatesEntity(), attr);
    }
}

export function TextTemplatesEntityFromBackend(res: TextTemplatesType): TextTemplatesEntity {
    return new TextTemplatesEntity(
        res.category,
        res.createdAt && BackendDate(res.createdAt),
        res.deletedAt && BackendDate(res.deletedAt),
        res.description,
        res.id,
        res.idCode,
        res.matchingCode,
        res.partnerId,
        res.quantityType,
        res.shortDescription,
        res.updatedAt && BackendDate(res.updatedAt),

    );
}

export interface TextTemplatesAttributes {
    Category?: string,
    CreatedAt?: Date,
    DeletedAt?: Date,
    Description?: string,
    Id?: number,
    IdCode?: string,
    MatchingCode?: string,
    PartnerId?: number,
    QuantityType?: string,
    ShortDescription?: string,
    UpdatedAt?: Date,

}
