import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../../helper/helper';
import { BaseActionTypes } from '../../actions/base.actions';
import { HttpService } from '../../../services/http.service';
import { DaveActions } from '../../actions/actions';
import { State } from '../../index';
import {
    ResourceTypeEntity,
    ResourceTypeEntityFromBackend,
} from '../../../entities/resource-dispo/resource-type.entity';
import { ResourceTypeActionTypes } from '../../actions/resource-dispo/resource-type.actions';

enum ErrorCodes {
    Add = 'ResourceType Hinzufügen fehlgeschlagen',
    Load = 'ResourceType Abrufen fehlgeschlagen',
    Modify = 'ResourceType Bearbeiten fehlgeschlagen',
    Remove = 'ResourceType Löschen fehlgeschlagen',
}

@Injectable()
export class ResourceTypeEffects {

    AddResourceType$ = createEffect(() => this.actions$.pipe(
        ofType(ResourceTypeActionTypes.Create),
        concatMap(({Payload}) => {
            const queryString = `
      mutation{
        createResourceType(
            ${stringifyIfNotNullOrUndefined(Payload, 'name')}
            ${stringifyIfNotNullOrUndefined(Payload, 'customFields')}
          ){${ResourceTypeEntity.GqlFields.join(',')}}
      }`;

            return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                map(res => {
                    return res && res.createResourceType
                        ? ResourceTypeActionTypes.UpdateMany({
                              Payload: [ResourceTypeEntityFromBackend(res.createResourceType)],
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    LoadResourceTypes = createEffect(() => this.actions$.pipe(
        ofType(ResourceTypeActionTypes.Load),
        switchMap(({ Payload }) => {
            const queryString = `
query{
    resourceType${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''}{${ResourceTypeEntity.GqlFields.join(',')}}
      }`;
            return this.gatewayHttpService.graphQl({ query: queryString }, { retry: true }).pipe(
                map(res =>
                    res && res.resourceType
                        ? Payload?.updatedSince
                            ? ResourceTypeActionTypes.UpdateMany({
                                  Payload: res.resourceType.map(val => ResourceTypeEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                            : ResourceTypeActionTypes.UpdateAll({
                                  Payload: res.resourceType.map(val => ResourceTypeEntityFromBackend(val)),
                                  updateLatestUpdatedAt: true,
                              })
                        : BaseActionTypes.ErrorAction({
                              Payload: { ToasterMessage: ErrorCodes.Load },
                          }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
