import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BusinessVolumeEntity, BusinessVolumeEntityFromBackend } from '../../entities/business-volume.entity';
import { BusinessVolumeType, DaveMutationChangeBusinessVolumeArgs, DaveMutationCreateBusinessVolumeArgs, DaveMutationDeleteBusinessVolumeArgs } from '../../graphql-types';
import { GraphQlEffectService } from '../../services/graph-ql-effect.service';
import { HttpService } from '../../services/http.service';
import { BaseActionTypes } from '../actions/base.actions';
import { BusinessVolumeActionTypes } from '../actions/business-volume.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { stringifyIfNotUndefined } from '../../../helper/helper';
import { FileEntity, FileEntityFromBackend } from '../../entities/file.entity';

enum ErrorCodes {
    Load = 'Umsätze Abrufen fehlgeschlagen',
    Modify = 'Umsätze Bearbeiten fehlgeschlagen',
    Remove = 'Umsätze Löschen fehlgeschlagen',
    Add = 'Umsätze Hinzufügen fehlgeschlagen',
    Export = 'Umsätze Exportieren fehlgeschlagen',
}

@Injectable()
export class BusinessVolumeEffects {
    RemoveBusinessVolume$ = this.graphQlEffectService.createDeleteOneEffect<DaveMutationDeleteBusinessVolumeArgs, BusinessVolumeEntity, BusinessVolumeType>(
        'deleteBusinessVolume',
        BusinessVolumeEntity.GqlFields.join(','),
        BusinessVolumeActionTypes.Delete,
        BusinessVolumeActionTypes.RemoveOne,
        (v) => v.id,
        ErrorCodes.Remove,
        ['id'],
    );
    ChangeBusinessVolume$ = this.graphQlEffectService.createModifyEffect<DaveMutationChangeBusinessVolumeArgs, BusinessVolumeEntity, BusinessVolumeType>(
        'changeBusinessVolume',
        BusinessVolumeEntity.GqlFields.join(','),
        BusinessVolumeActionTypes.Change,
        BusinessVolumeActionTypes.UpdateOne,
        BusinessVolumeEntityFromBackend,
        ErrorCodes.Modify,
        ['id'],
        [
            'amount',
            'commissionId',
            'ledgerImportId',
            'status',
            'bVCustomNamesIds',
            'bVType',
            'businessVolumeNo',
            'payedAmount',
            'customerId',
            'name',
            'description',
            'documentIds',
            'consolidatedDate',
            'consolidatedDeliveryDate',
            'taxValue',
            'taxPercentageValue',
            'discountValue',
            'sachkontoCOAId',
            'payedTaxValue'
        ],
        new Map([
            ['bVType', (value) => (value.bVType === null ? null : value.bVType.toString())],
            ['status', (value) => (value.status === null ? null : value.status.toString())],
        ]),
    );
    CreateBusinessVolume$ = this.graphQlEffectService.createAddEffect<DaveMutationCreateBusinessVolumeArgs, BusinessVolumeEntity, BusinessVolumeType>(
        'createBusinessVolume',
        BusinessVolumeEntity.GqlFields.join(','),
        BusinessVolumeActionTypes.Add,
        BusinessVolumeActionTypes.UpdateOne,
        BusinessVolumeEntityFromBackend,
        ErrorCodes.Add,
        [],
        [
            'commissionId',
            'amount',
            'ledgerImportId',
            'status',
            'bVCustomNamesIds',
            'bVType',
            'businessVolumeNo',
            'payedAmount',
            'customerId',
            'name',
            'description',
            'documentIds',
            'consolidatedDate',
            'consolidatedDeliveryDate',
            'taxValue',
            'discountValue',
            'sachkontoCOAId',
            'payedTaxValue'
        ],
        new Map([
            ['bVType', (value) => (value.bVType === null ? null : value.bVType.toString())],
            ['status', (value) => (value.status === null ? null : value.status.toString())],
        ]),
    );
    GetBusinessVolume$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BusinessVolumeActionTypes.Load),
                withLatestFrom(this.store$.select(getToken)),
                switchMap(([{ Payload }, token]) => {
                    const queryString = `query {
          businessVolume${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
            ${BusinessVolumeEntity.GqlFields.join(',')}
          }
        }
        `;
                    return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                        tap((res) => {
                            if (!(Payload?.updatedSince && !res.businessVolume.length)) {
                                this.store$.dispatch(
                                    res && res.businessVolume
                                        ? Payload?.updatedSince
                                            ? BusinessVolumeActionTypes.UpdateMany({
                                                  Payload: res.businessVolume.map((val) => BusinessVolumeEntityFromBackend(val)),
                                                  updateLatestUpdatedAt: true,
                                              })
                                            : BusinessVolumeActionTypes.UpdateAll({
                                                  Payload: res.businessVolume.map((val) => BusinessVolumeEntityFromBackend(val)),
                                                  updateLatestUpdatedAt: true,
                                              })
                                        : BaseActionTypes.ErrorAction({
                                              Payload: { ToasterMessage: ErrorCodes.Load },
                                          }),
                                );
                            }
                        }),
                        catchError((err, caught) => {
                            this.store$.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Load,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            );
                            return EMPTY;
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    createCsv$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BusinessVolumeActionTypes.ExportRequest),
            withLatestFrom(this.store$.select(getToken)),
            concatMap(([{ Payload }, token]) => {
                const queryString = `
mutation{
  createDatevCsv(
    ${stringifyIfNotUndefined(Payload, 'startDate')}
    ${stringifyIfNotUndefined(Payload, 'endDate')}
    ${stringifyIfNotUndefined(Payload, 'bvIds')}
  ) {
        ${FileEntity.GqlFields}
  }
}
`
                return this.gatewayHttpService.graphQl({ query: queryString }, { token , retry: true }).pipe(
                    concatMap((res) =>
                    {
                        return   res && res.createDatevCsv && res.createDatevCsv.id
                            ? [BusinessVolumeActionTypes.ExportSuccess({Payload: FileEntityFromBackend(res.createDatevCsv)})]
                            : [BusinessVolumeActionTypes.ExportFailure({ ToasterMessage: ErrorCodes.Export, Errors: 'createDatevCsv wrong response' })]
                    }),
                    catchError((err) => of(BusinessVolumeActionTypes.ExportFailure({ ToasterMessage: ErrorCodes.Export, Errors: err }))),
                );
            }),
        ),
    );
    error$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BusinessVolumeActionTypes.ExportFailure),
            map((action) =>
                BaseActionTypes.ErrorAction({
                    Payload: {
                        ToasterMessage: action.ToasterMessage,
                        Err: action.Errors,
                    },
                }),
            ),
        ),
    );
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayHttpService: HttpService, private graphQlEffectService: GraphQlEffectService) {}
}
