import { GetTimeFromTimestamp, Material, MaterialList } from '@dave/types';
import { AmountTypeEnum, MaterialEntity } from './material.entity';
import { Status } from '@dave/types/dist/proto/erp/materialList';

export enum MaterialListStatusEnum {
    NONE = "KEINER",
    OPEN = "OFFEN",
    ORDER_CREATED = "BESTELLUNG_ERSTELLT",
    DELIVERED = "LIEFERUNG_ERFOLGT",
    DELIVERY_BILL_CREATED = "LIEFERRECHNUNG_ERSTELLT",
    STORED = "EINGELAGERT",
    PICKED = "ABGEHOLT",
    CANCELED = "STORNIERT",
    COMPLETED = "ABGESCHLOSSEN"
}

export class MaterialListEntity {
    constructor(
        public Id: number = null,
        public Amount: number = null,
        public MaterialId: number = null,
        public CommissionId: number = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
        public PartnerId: number = null,
        public UserId: number = null,
        public ArlId: number = null,
        public Status: MaterialListStatusEnum = null,
    ) {}

    public static EntityName = 'MaterialList';
    public Clone(override: Partial<MaterialListEntity> = {}): MaterialListEntity {
        const attr = { ...this, ...override };
        return Object.assign(new MaterialListEntity(), attr);
    }

}

export function MaterialListEntityFromBackend(res: MaterialList): MaterialListEntity {
    const statusEnumValue = res.Status ? MaterialListStatusEnum[res?.Status] : null;

    return new MaterialListEntity(
        res.Id && +res.Id,
        res.Amount && +res.Amount,
        res.MaterialId && +res.MaterialId,
        res.CommissionId && +res.CommissionId,
        res.CreatedAt && GetTimeFromTimestamp(+res.CreatedAt),
        res.UpdatedAt && GetTimeFromTimestamp(+res.UpdatedAt),
        res.DeletedAt && GetTimeFromTimestamp(+res.DeletedAt),
        res.PartnerId && +res.PartnerId,
        res.UserId && +res.UserId,
        res?.ArlId && +res.ArlId,
        statusEnumValue
    );
}
