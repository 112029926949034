<header class="header">
    <fa-icon class="icon" icon="bookmark"></fa-icon>
    <div class="dark-theme title">
        <mat-form-field floatLabel="never" color="primary" *ngIf="SearchBarActive; else searchBarInactive" [ngStyle]="{ width: '100%' }">
            <mat-label>{{ SelectedTab?.searchLabel }}</mat-label>
            <input type="text" [formControl]="SearchForm" #input matInput />
            <button class="clear-search" (click)="SearchForm.setValue('')" mat-icon-button *ngIf="SearchForm.value">
                <fa-icon [icon]="IsSearching ? 'spinner' : 'times'" [spin]="IsSearching"></fa-icon>
            </button>
        </mat-form-field>

        <ng-template #searchBarInactive>
            <h3 class="heading">Meine Aufgaben</h3>
        </ng-template>
    </div>

    <button app-round-button matTooltipPosition="left" [matTooltip]="SearchBarActive && !SearchForm.value ? 'Ausblenden' : 'Suche'" (click)="SearchButtonClicked()">
        <fa-icon [icon]="SearchBarActive && !SearchForm.value ? 'times' : 'search'"></fa-icon>
    </button>
    <ng-container *ngIf="SelectedTab?.headerButtons">
        <button
            *ngFor="let btn of SelectedTab.headerButtons"
            class="header-button mat-badge-more-overlay"
            app-round-button
            matTooltipPosition="left"
            [matTooltip]="btn.checkLabel"
            (click)="btn.checkAction()"
            [Disabled]="btn.disableCheck$ | async"
            [matMenuTriggerFor]="btn.matMenu"
            [matBadge]="btn.matBadge$ | async"
        >
            <fa-icon [icon]="btn.checkIcon"></fa-icon>
        </button>
    </ng-container>
</header>
<app-filter-card #taskFilterComponent [Settings]="TaskFilterSettings$ | async" [Values]="TaskFilterValues$ | async" (ValuesChange)="TaskFilterValues$.next($event)"></app-filter-card>

<mat-menu #sortMenu >
    <button mat-menu-item (click)="TaskSort$.next({sortBy: 'endDate', direction: 'asc'})"><fa-icon icon="chevron-up"></fa-icon> Frist</button>
    <button mat-menu-item (click)="TaskSort$.next({sortBy: 'endDate', direction: 'dsc'})"><fa-icon icon="chevron-down"></fa-icon> Frist</button>
    <button mat-menu-item (click)="TaskSort$.next({sortBy: 'priority', direction: 'asc'})"><fa-icon icon="chevron-up"></fa-icon> Priorität</button>
    <button mat-menu-item (click)="TaskSort$.next({sortBy: 'priority', direction: 'dsc'})"><fa-icon icon="chevron-down"></fa-icon> Priorität</button>
</mat-menu>
<mat-tab-group *ngIf="Loaded$ | async; else loading" preserveContent (selectedIndexChange)="SelectedTab = Tabs[$event]">
    <mat-tab labelClass="small-mat-tab-label">
        <ng-template mat-tab-label>
            <div class="tab-header">
                <fa-icon [icon]="TaskPageMeta.Icon"></fa-icon>
                {{ ' (' + (TaskDataSource$ | async)?.length + ')' }}
                <span class="unseen" *ngIf="(UnseenTaskCount$ | async) != 0">{{ UnseenTaskCount$ | async }}</span>
            </div>
        </ng-template>
        <ng-template matTabContent>
            <cdk-virtual-scroll-viewport class="scroll-viewport" itemSize="89" minBufferPx="200" maxBufferPx="400">
                <app-event-list-entry
                    #entry
                    class="full-width"
                    *cdkVirtualFor="let e of TaskDataSource$ | async"
                    [ShowRoutingButtons]="true"
                    [EventId]="e"
                ></app-event-list-entry>
            </cdk-virtual-scroll-viewport>
        </ng-template>
        <table *ngIf="(TaskDataSource$ | async)?.length === 0" style="margin: 0 auto">
            <tr>
                <td class="empty-table-text">Keine Aufgaben vorhanden</td>
            </tr>
        </table>
    </mat-tab>
    <mat-tab labelClass="small-mat-tab-label">
        <ng-template mat-tab-label>
            <div class="tab-header">
                <fa-icon [icon]="BookmarkIcon"></fa-icon>
                {{ ' (' + (DataSource$ | async)?.length + ')' }}
                <span class="unseen" *ngIf="(UnseenBookmarkCount$ | async) != 0">{{ UnseenBookmarkCount$ | async }}</span>
            </div>
        </ng-template>
        <ng-template matTabContent>
            <cdk-virtual-scroll-viewport class="scroll-viewport" itemSize="89" minBufferPx="200" maxBufferPx="400">
                <app-event-list-entry
                    class="full-width"
                    *cdkVirtualFor="let rowData of DataSource$ | async"
                    [ShowRoutingButtons]="true"
                    [Event]="rowData"
                ></app-event-list-entry>
            </cdk-virtual-scroll-viewport>
        </ng-template>
    </mat-tab>
    <mat-tab labelClass="small-mat-tab-label" *ngIf="(PS.Has(PS.Permission.ApproveAbsent) | async) || (PS.Has(PS.Permission.ApproveVacation) | async)">
        <ng-template mat-tab-label>
            <div class="tab-header"><fa-icon [icon]="VacationMetaIcon"></fa-icon> {{ ' (' + (VacationDataSource$ | async).filteredData.length + ')' }}</div>
        </ng-template>
        <ng-template matTabContent>
            <table mat-table [dataSource]="VacationDataSource$ | async" matSort>
                <ng-container matColumnDef="name">
                    <td class="vacation-left" mat-cell *matCellDef="let rowData">
                        <span class="text">
                            <b>{{ VacationTypeEnumNameMap.get(rowData.vacation.Type) }} - {{ rowData.employee?.DisplayName }}</b
                            ><br />
                            {{ rowData.vacation.StartDate | date }} - {{ rowData.vacation.EndDate | date }}<br />
                            {{ rowData.declinedList ? 'Akzeptiert' : 'Gesehen' }} von:
                            <ng-container *ngFor="let user of rowData?.acceptedList"> {{ user }},&nbsp; </ng-container>
                            <ng-container *ngIf="!rowData.acceptedList"> - </ng-container>
                            <ng-container *ngIf="rowData.declinedList">
                                <br />
                                Abgelehnt von:
                                <ng-container *ngFor="let user of rowData?.declinedList"> {{ user }},&nbsp; </ng-container>
                                <ng-container *ngIf="!rowData.declinedList"> - </ng-container>
                            </ng-container>
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="envelope">
                    <td mat-cell *matCellDef="let rowData" style="width: 50px">
                        <div class="icons">
                            <div>
                                <button
                                    style="margin: 0.25rem"
                                    app-round-button
                                    (click)="OpenVacationDialog(rowData.vacation.Id, true); $event.stopPropagation()"
                                    [matTooltip]="rowData.declinedList ? 'Genehmigen' : 'Gesehen'"
                                >
                                    <fa-icon class="icon" icon="check"></fa-icon>
                                </button>
                                <button
                                    *ngIf="rowData.declinedList"
                                    style="margin: 0.25rem"
                                    app-round-button
                                    (click)="OpenVacationDialog(rowData.vacation.Id, false); $event.stopPropagation()"
                                    matTooltip="Ablehnen"
                                    Color="red"
                                >
                                    <fa-icon class="icon" icon="times"></fa-icon>
                                </button>
                            </div>
                            <fa-icon class="icon" *ngIf="rowData.icon" [icon]="rowData.icon | icon"></fa-icon>
                        </div>
                    </td>
                </ng-container>

                <tr
                    mat-row
                    [matTooltip]="rowData.tooltip"
                    matTooltipPosition="above"
                    *matRowDef="let rowData; columns: ['name', 'envelope']"
                    (click)="OpenVacationPopUp(rowData)"
                ></tr>
            </table>
        </ng-template>
        <table *ngIf="(VacationDataSource$ | async) !== null && (VacationDataSource$ | async)?.data.length === 0">
            <tr>
                <td class="empty-table-text">Keine Urlaubsanträge vorhanden</td>
            </tr>
        </table>
        <app-loading [Size]="75" *ngIf="(VacationDataSource$ | async) === null"></app-loading>
    </mat-tab>
    <mat-tab labelClass="small-mat-tab-label" *ngIf="PS.Has(PS.Permission.CreateAbsent) | async">
        <ng-template mat-tab-label>
            <div class="tab-header"><fa-icon [icon]="AbsentMetaIcon"></fa-icon> {{ ' (' + (AbsentDataSource$ | async)?.filteredData.length + ')' }}</div>
        </ng-template>
        <ng-template matTabContent>
            <table mat-table [dataSource]="AbsentDataSource$ | async" matSort>
                <ng-container matColumnDef="name">
                    <td class="vacation-left" mat-cell *matCellDef="let rowData">
                        <span class="text">
                            <b>{{ VacationTypeEnumNameMap.get(rowData.vacation.Type) }} - {{ rowData.employee?.DisplayName }}</b
                            ><br />
                            {{ rowData.vacation.StartDate | date }} - {{ rowData.vacation.EndDate | date }}<br />
                            {{ rowData.declinedList ? 'Akzeptiert' : 'Gesehen' }} von:
                            <ng-container *ngFor="let user of rowData?.acceptedList"> {{ user }},&nbsp; </ng-container>
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="envelope">
                    <td mat-cell *matCellDef="let rowData" style="width: 50px">
                        <div class="icons">
                            <div>
                                <button
                                    style="margin: 0.25rem"
                                    app-round-button
                                    (click)="SetAbsentToRead(rowData.vacation.Id, true); $event.stopPropagation()"
                                    [matTooltip]="rowData.declinedList ? 'Genehmigen' : 'Gesehen'"
                                >
                                    <fa-icon class="icon" icon="check"></fa-icon>
                                </button>
                            </div>
                            <fa-icon class="icon" *ngIf="rowData.icon" [icon]="rowData.icon | icon"></fa-icon>
                        </div>
                    </td>
                </ng-container>

                <tr
                    mat-row
                    [matTooltip]="rowData.tooltip"
                    matTooltipPosition="above"
                    *matRowDef="let rowData; columns: ['name', 'envelope']"
                    (click)="OpenVacationPopUp(rowData)"
                ></tr>
            </table>
        </ng-template>
        <table *ngIf="(AbsentDataSource$ | async) !== null && (AbsentDataSource$ | async)?.data.length === 0">
            <tr>
                <td class="empty-table-text">Keine Urlaubsanträge vorhanden</td>
            </tr>
        </table>
        <app-loading [Size]="75" *ngIf="(AbsentDataSource$ | async) === null"></app-loading>
    </mat-tab>
</mat-tab-group>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
