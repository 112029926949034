<h1 mat-dialog-title>
    <fa-icon [icon]="ProcessMeta.Icon"></fa-icon>
    Prozess starten
</h1>
<!--<header class="dialog-header">-->
<!--    <h3 mat-dialog-title>Prozess starten</h3>-->
<!--    <fa-icon class="close-button" icon="times" size="2x" (click)="dialogRef.close()"></fa-icon>-->
<!--</header>-->
<mat-dialog-content>
    <div class="form-wrapper">
        <app-detail-list-template [Data]="detailListData$ | async" [Inline]="true" [Editing]="true"></app-detail-list-template>
        <app-expansion-panel [Headline]="'Dokumente zuweisen (' + selectedDocumentIds.length + ')'" [Expanded]="false" (ExpandedChange)="fileExplorerExpanded$.next($event)">
            <fa-icon headerSuffix size="2x" icon="question-circle" matTooltip="Hier können weitere Dokumente, die bereits im System gespeichert sind, dem Prozess angehängt werden" style="margin: auto 0;"></fa-icon>
            <div class="file-panel">
                <div class="selected-files-list">
                    <!--        <div class="element" *ngFor="let id of valid_fileIds" [matBadgeOverlap]="true" [matBadge]="1" matBadgeColor="primary" matBadgeSize="large" matBadgePosition="center">-->
                    <!--            <app-small-file-card [FileId]="id"></app-small-file-card>-->
                    <!--        </div>-->
                    <p *ngIf="!selectedDocumentIds.length" class="empty-text">keine Dokumente ausgewählt</p>
                    <app-small-file-card RemoveButton (RemoveClick)="onRemoveFileClick($event)" (FileClick)="openFilePreview($event.Id)" [FileId]="id" *ngFor="let id of selectedDocumentIds"></app-small-file-card>
                </div>
<!--                <app-document-list-card RemoveButton [FileIds]="selectedDocumentIds" (FileClick)="openFilePreview($event.Id)" (RemoveClick)="onRemoveFileClick($event)" EmptyText="Keine Dateien ausgewählt"></app-document-list-card>-->
                <div class="file-explorer">
                    <div class="headline">
                        <h3><fa-icon icon="paperclip"></fa-icon> Anhänge</h3>
                        <span class="spacer"></span>
                        <mat-slide-toggle [(ngModel)]="DMSNotMinimalistic">DMS</mat-slide-toggle>
                        <!--                <button style="margin-left: auto;" app-round-button (click)="OpenFileUpload(event.CommissionId)" matTooltip="Dateien hinzufügen">-->
                        <!--                    <app-double-icon [Icon]="DMSPageMeta.Icon" Icon2="upload"></app-double-icon>-->
                        <!--                </button>-->
                    </div>
                    <app-file-explorer #fileExplorerComponent [FileUploadButton]="true" (FileClicked)="onAddFileClick($event)" [DisableRoutingParams]="true" PreventFileClick [Minimalistic]="!DMSNotMinimalistic"></app-file-explorer>
                </div>
            </div>
        </app-expansion-panel>
    </div>
    <div class="file-wrapper">
        <ng-container *ngIf="fileId$ | async; else addFileButton">
            <ng-container *ngIf="file$ | async as file">
                <img *ngIf="file.MIMEType.indexOf('image/') > -1" [src]="versionSrc$ | async" />
                <app-pdf-editor *ngIf="file.MIMEType.indexOf('application/pdf') > -1" [Version]="version$ | async" style="height: 100%"></app-pdf-editor>
                <app-file-card style="margin: auto" *ngIf="!(file.MIMEType.indexOf('image/') > -1) && !(file.MIMEType.indexOf('application/pdf') > -1)" (Clicked)="openFilePreview(file.Id)" [File]="file"></app-file-card>
            </ng-container>
        </ng-container>
        <ng-template #addFileButton>
            <!--<p>Prozess ohne datei starten</p>-->
            <!--<div style="margin: auto;">-->
            <p style="margin: auto auto 1rem">Keine Hauptdatei ausgewählt</p>
            <button app-button style="width: 6rem; margin: 0 auto auto" [matMenuTriggerFor]="fileUploadMenu" [IsLoading]="ls.IsLoading$ | async" matTooltip="Hauptdatei auswählen">
                <fa-icon icon="plus"></fa-icon>
            </button>
            <!--</div>-->
<!--            <input id="file" type="file" hidden #fileInput (change)="onMainFileChanged($event)" />-->
            <mat-menu #fileUploadMenu>
                <button mat-menu-item (click)="openFileSelectDialog()">Datei hinzufügen</button>
                <button mat-menu-item (click)="onMainFileChanged()">Datei hochladen</button>
            </mat-menu>
        </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button app-button Color="cancel" class="cancel-button" Inverted [matDialogClose]="undefined">abbrechen</button>
    <button app-button Color="green" (click)="onSubmitClick()" [IsLoading]="ls.IsLoading$ | async" [Disabled]="formGroup.invalid">anlegen</button>
</mat-dialog-actions>
<button mat-icon-button [matDialogClose]="undefined" class="close-mat-dialog-button" matTooltip="Schließen">
    <fa-icon icon="times" size="2x"></fa-icon>
</button>
