import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Subscription, switchMap } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import {
    getAutomaticAuftragsnummerSubscription,
    getAutomaticInterneNummerSubscription,
    ICommissionDetailListTemplateEntrys,
    ICommissionForm,
    setCommissionDetailListTemplateEntries,
} from '../dave-commission-module/components/detail-commission/helper';
import { CommissionEntity } from '../dave-data-module/entities/commission.entity';
import { DaveMutationCreateCommissionArgs } from '../dave-data-module/graphql-types';
import { CommissionTypeResolver } from '../dave-data-module/guards/commissionType.resolver';
import { StatusFromBackofficeResolver } from '../dave-data-module/guards/statusFromBackoffice.resolver';
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';
import { CommissionActionTypes } from '../dave-data-module/State/actions/commission.actions';
import {
    getCommissionTypeDictionary,
    getCommissionTypes,
    getCommissionTypesFetched,
} from '../dave-data-module/State/selectors/commissionType.selectors';
import { getPartner, getPartnersFetched } from '../dave-data-module/State/selectors/partners.selectors';
import { getStatusFromBackoffice, getStatusFromBackofficeFetched } from '../dave-data-module/State/selectors/statusFromBackoffice.selectors';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { IDetailListTemplateData } from '../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { SelectSearchData } from '../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { isNotNullOrUndefined } from '../helper/helper';
import { AllCommissionMeta, CommissionMeta } from '../helper/page-metadata';
import { CustomLabelService } from '../services/custom-label.service';
import { LoadingService } from '../services/loading.service';
import { PartnerTypeEnum } from '../dave-data-module/entities/partner.entity';
import { getFetched$ } from '../dave-data-module/helper/helper';

export interface NewCommissionDialogComponentDialogReturnData {
    Commission?: CommissionEntity;
}
export interface NewCommissionDialogComponentDialogData {
    CreateCommissionPayload?: Partial<DaveMutationCreateCommissionArgs>;
    NavigateRelativeTo?: ActivatedRoute;
    PreventRouting?: boolean;
    ForceCommissionTypeId?: number;
}
@Component({
    selector: 'app-new-commission-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule, AppButtonModule, DaveSharedComponentsModule, DaveLoadingModule, MatSlideToggleModule, ReactiveFormsModule],
    templateUrl: './new-commission-dialog.component.html',
    styleUrls: ['./new-commission-dialog.component.scss'],
})
export class NewCommissionDialogComponent implements OnInit, OnDestroy {
    Form: FormGroup<
        Partial<ICommissionForm> & {
            CommissionTypeId: FormControl<SelectSearchData | null>;
            AutomaticAuftragsnummer: FormControl<boolean | null>;
            Auftragsnummer: FormControl<string | null>;
            InterneNummer: FormControl<string | null>;
            AutomaticInterneNummer: FormControl<boolean | null>;
            // addResourcesFromLedgerImport: FormControl<boolean>;
        }
    > = new FormGroup({
        CommissionTypeId: new FormControl<SelectSearchData | null>(null, Validators.required),
        Auftragsnummer: new FormControl<string | null>(null),
        AutomaticAuftragsnummer: new FormControl<boolean>(true),
        InterneNummer: new FormControl<string | null>(null),
        AutomaticInterneNummer: new FormControl<boolean>(true),
        // addResourcesFromLedgerImport: new FormControl<boolean>(false),
    });
    protected addResourcesFromLedgerImportForm = new FormControl<boolean>(false);
    Data: IDetailListTemplateData;
    protected showAddResourcesFromLedgerImport = false;
    public subscriptions: Subscription[] = [];
    constructor(
        @Inject(MAT_DIALOG_DATA) protected dialogData: NewCommissionDialogComponentDialogData,
        private store: Store<State>,
        protected ls: LoadingService,
        private actions$: Actions,
        private dialogRef: MatDialogRef<NewCommissionDialogComponent, NewCommissionDialogComponentDialogReturnData>,
        private router: Router,
        private statusFromBoRes: StatusFromBackofficeResolver,
        private commissionTyperRes: CommissionTypeResolver,
        protected cls: CustomLabelService,
    ) {
        if (dialogData) {
            this.showAddResourcesFromLedgerImport = !!dialogData.CreateCommissionPayload?.addResourcesFromLedgerImportId;
            if (this.showAddResourcesFromLedgerImport) {
                this.addResourcesFromLedgerImportForm.setValue(true);
            }
            if(dialogData.ForceCommissionTypeId) {
                this.Form.controls.CommissionTypeId.setValue({Id: dialogData.ForceCommissionTypeId, Name:''});
                this.Form.controls.CommissionTypeId.disable();
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ngOnInit(): void {
        firstValueFrom(
            combineLatest([
                this.store.select(getStatusFromBackofficeFetched).pipe(
                    tap((f) => {
                        if (!f) {
                            this.statusFromBoRes.resolve();
                        }
                    }),
                    filter((f) => f),
                ),
                this.store.select(getCommissionTypesFetched).pipe(
                    tap((f) => {
                        if (!f) {
                            this.commissionTyperRes.resolve();
                        }
                    }),
                    filter((f) => f),
                    switchMap(() => this.store.select(getCommissionTypes)),
                ),
                this.store.select(getPartner),
                this.cls.getSingle$('Commission'),
            ]),
        ).then(([statusesFetched, commissionTypes, partner, commissionLabel]) => {
            const obj: ICommissionDetailListTemplateEntrys = {};
            setCommissionDetailListTemplateEntries(
                new CommissionEntity(),
                obj,
                ['CommissionType', 'CommissonNumber', 'CommissonNumberAuto', 'InterneNummer', 'InterneNummerAuto'],
                this.Form as FormGroup<Partial<ICommissionForm>>,
                [],
                {
                    commissionTypes,
                    partner,
                },
                commissionLabel,
            );
            this.Data = {
                Properties: Object.values(obj).filter(isNotNullOrUndefined),
            };
            this.subscriptions.push(getAutomaticAuftragsnummerSubscription(this.Form.controls), getAutomaticInterneNummerSubscription(this.Form.controls));
            this.Form.controls.AutomaticAuftragsnummer.setValue(this.Form.value.AutomaticAuftragsnummer);
            this.Form.controls.AutomaticInterneNummer.setValue(this.Form.value.AutomaticInterneNummer);
        });
    }

    Submit() {
        if (this.Form.valid) {
            this.ls.startLoading('add-commission');
            firstValueFrom(
            combineLatest([
                this.store.select(getStatusFromBackoffice),
                this.store.select(getPartner),
                this.store.select(getCommissionTypeDictionary),
            ]))
                .then(([status, partner, commissionTypes]) => {
                    const statusBackofficeId = status.find((s) => s.IsDefault)?.Id;
                    let description = '';
                    if (partner.PartnerTypeId === PartnerTypeEnum.TSB ){
                        description = commissionTypes[this.Form.getRawValue().CommissionTypeId.Id]?.Name || '';
                    }
                    this.store.dispatch(
                        CommissionActionTypes.AddCommission({
                            Payload: {
                                commissionTypeId: this.Form.getRawValue().CommissionTypeId.Id,
                                description,
                                auftragsnummer: this.Form.value.Auftragsnummer,
                                automaticAuftragsnummer: this.Form.value.AutomaticAuftragsnummer,
                                interneNummer: this.Form.value.InterneNummer,
                                automaticInterneNummer: this.Form.value.AutomaticInterneNummer,
                                additionalData: JSON.stringify({
                                    wizardPage: 1,
                                }),
                                statusBackofficeId,
                                ...this.dialogData?.CreateCommissionPayload,
                                addResourcesFromLedgerImportId: this.addResourcesFromLedgerImportForm.value ? this.dialogData?.CreateCommissionPayload.addResourcesFromLedgerImportId : undefined,
                            },
                        }),
                    );
                    return firstValueFrom(this.actions$.pipe(ofType(CommissionActionTypes.UpdateOne, BaseActionTypes.ErrorAction)));
                })
                .then((action) => {
                    this.ls.endLoading('add-commission');
                    if (this.dialogData?.CreateCommissionPayload.addResourcesFromLedgerImportId) {
                        // this.store.dispatch()
                    }
                    if (action.type === CommissionActionTypes.UpdateOne.type) {
                        if (!this.dialogData?.PreventRouting) {
                            if (this.dialogData?.NavigateRelativeTo) {
                                this.router.navigate([action.Payload.Id], { relativeTo: this.dialogData.NavigateRelativeTo });
                            } else {
                                this.router.navigate(['/', CommissionMeta.Path, AllCommissionMeta.Path, action.Payload.Id]);
                            }
                        }
                        this.dialogRef?.close({ Commission: action.Payload });
                    }
                });
        }
    }
}
