<div class="header">
    <h2 class="headline" *ngIf="Headline">{{Headline}}</h2>
    <span class="spacer"></span>
    <ng-content select="[headerButton]"></ng-content>
</div>
<input type="text" class="search-field" [placeholder]="SearchPlaceHolder" #searchString (keyup)="SearchStringChange.emit(searchString.value)" />

<ng-content></ng-content>

<cdk-virtual-scroll-viewport [tvsItemSize]="56" [headerHeight]="56" style="height: 20rem; overflow-y: scroll" [bufferMultiplier]="5">
    <table mat-table matSort [dataSource]="dataSource">

        <ng-container [matColumnDef]="CheckBoxColumnName">
            <th mat-header-cell *matHeaderCellDef [style.width]="'1rem'">
                <fa-icon icon="check-square"></fa-icon>
            </th>
            <td mat-cell *matCellDef="let rowData">
                <mat-checkbox [formControl]="rowData.selectedForm" color="primary" (click)="$event.stopPropagation()"> </mat-checkbox>
            </td>
        </ng-container>
        <ng-container *ngFor="let columnConfig of TableDataConfig$ | async" [matColumnDef]="columnConfig.id">
            <ng-container *ngIf="columnConfig.sortAccessor">
                <th mat-header-cell mat-sort-header *matHeaderCellDef [style.width]="columnConfig.width ? columnConfig.width : 'unset'">
                    {{ columnConfig.header }}
                </th>
            </ng-container>
            <ng-container *ngIf="!columnConfig.sortAccessor">
                <th mat-header-cell *matHeaderCellDef [style.width]="columnConfig.width ? columnConfig.width : 'unset'">
                    {{ columnConfig.header }}
                </th>
            </ng-container>
            <td mat-cell *matCellDef="let rowData">
                <ng-container *ngIf="columnConfig.customCellTemplate; else defaultCell" [ngTemplateOutlet]="columnConfig.customCellTemplate" [ngTemplateOutletContext]="{data: rowData}"></ng-container>
                <ng-template #defaultCell>
                    <ng-container [ngSwitch]="rowData[columnConfig.id].__typename">
                        <ng-container *ngSwitchCase="'string'">
                            {{ rowData[columnConfig.id].content }}
                        </ng-container>
                        <mat-checkbox *ngSwitchCase="'boolean'" [formControl]="rowData[columnConfig.id].formControl" color="primary"> </mat-checkbox>
                        <mat-form-field *ngSwitchCase="'select'">
                            <mat-label>{{ columnConfig.header }}</mat-label>
                            <mat-select [formControl]="rowData[columnConfig.id].formControl">
                                <mat-option *ngFor="let option of rowData[columnConfig.id].options" [value]="option.id">
                                    {{ rowData[columnConfig.id].optionLabel(option) }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngSwitchCase="'timespan'">
                            <mat-label>{{ columnConfig.header }}</mat-label>
                            <mat-date-range-input
                                [formGroup]="rowData[columnConfig.id].formGroup"
                                [rangePicker]="timespanPicker">
                                <input matStartDate placeholder="Von" formControlName="from">
                                <input matEndDate placeholder="Bis" formControlName="to">
                            </mat-date-range-input>
                            <!--                        <mat-hint>TT.MM.JJJJ – TT.MM.JJJJ</mat-hint>-->
                            <mat-datepicker-toggle matSuffix [for]="timespanPicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #timespanPicker></mat-date-range-picker>
                        </mat-form-field>
                    </ng-container>
                </ng-template>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="Columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let rowData; columns: Columns" [style]="rowData?.cssStyle || ''" [ngClass]="rowData.cssClass || ''" (click)="RowClick.emit(rowData)"></tr>
    </table>


</cdk-virtual-scroll-viewport>
<table *ngIf="dataSource.data?.length === 0" style="width: 100%">
    <tr>
        <td class="empty-table-text">{{EmptyText || 'Keine Mitarbeiter vorhanden'}}</td>
    </tr>
</table>
<h3>{{SelectedHeadline || 'zugewiesene Mitarbeiter:'}}</h3>
<mat-chip-list *ngIf="Selected?.length !== 0">
    <mat-chip *ngFor="let entry of Selected" (click)="ClickSelected.emit(entry)" (removed)="RemoveSelected.emit(entry)" style="height: unset">
        <ng-container *ngIf="CustomChipTemplate; else default" [ngTemplateOutlet]="CustomChipTemplate" [ngTemplateOutletContext]="{data: entry}"></ng-container>
        <ng-template #default>{{ entry.label }}</ng-template>
        <button matChipRemove >
            <fa-icon icon="times-circle"></fa-icon>
        </button>
    </mat-chip>
</mat-chip-list>
