import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { State } from '../State';
import { SharedCommissionsEntity } from '../entities/sharedCommissions.entity';
import { getSharedCommissions } from '../State/selectors/sharedCommissions.selectors';
import { SharedCommissionsActionTypes } from '../State/actions/sharedCommissions.actions';

@Injectable({
  providedIn: 'root'
})
export class SharedCommissionsResolver implements Resolve<SharedCommissionsEntity[]> {
  constructor(private store: Store<State>) {
  }

  resolve() {
    const value$ = this.store.select(getSharedCommissions);

    value$
      .pipe(
        filter(value => !value),
        take(1),
        tap(() => {
          if (localStorage.getItem('token') != null) {
            this.store.dispatch(SharedCommissionsActionTypes.GetSharedCommissionsRequest());
          }
        })
      )
      .subscribe();

    return value$.pipe(
      filter(value => !!value),
      take(1)
    );
  }
}
