import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    selectAllWorkedTimes,
    selectWorkedTimesEntities, selectWorkedTimesFetched, selectWorkedTimesLatestUpdatedAt,
    workedTimesFeatureKey,
    WorkedTimesState,
} from '../reducers/worked-times.reducer';

const selectState = createFeatureSelector<WorkedTimesState>(workedTimesFeatureKey);
const selectDictionary = createSelector(selectState, selectWorkedTimesEntities);

export const getWorkedTimes = createSelector(selectState, selectAllWorkedTimes);
export const getWorkedTimesFetched = createSelector(selectState, selectWorkedTimesFetched);
export const getWorkedTimesLatestUpdatedAt = createSelector(selectState, selectWorkedTimesLatestUpdatedAt);
export const getWorkedTimesById = createSelector(selectDictionary, (workedTimes: ReturnType<typeof selectDictionary>, Id: number) => Id && workedTimes[Id]);
