import { ClockInType } from '../graphql-types';
import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';

export enum ClockInTypes {
    Start = 'Start',
    End = 'End',
}
export const ClockInTypeNames = new Map<ClockInTypes, string>([
    [ClockInTypes.Start, 'Start'],
    [ClockInTypes.End, 'Stop'],
])
export const ClockInEntityGqlFields: Array<keyof ClockInType> = [
    'id',
    'timeStamp',
    'clockInTypeId',
    'type',
    'userId',
    'employeeId',
    'createdAt',
    'updatedAt',
    'deletedAt',
    'city',
    'postalcode',
    'country',
    'commissionId',
    'workDayId',
    'workDescription',
    'kostenstelle',
    'sachkonto',
    'bearbeitungsschluessel',
    'drivingTime',
    'timespan',
    'forcedBreakTime',
    'realTimespan',
    'exportedAt',
];
export class ClockInEntity {
    constructor(
        public Id: number = null,
        public UserId: number = null,
        public EmployeeId: number = null,
        public ClockInTypeId: number = null,
        public Type: ClockInTypes = null,
        public TimeStamp: Date = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,

        public PartnerId: number = null,
        public City: string = null,
        public Postalcode: string = null,
        public Country: string = null,
        public CommissionId: number = null,
        public WorkDayId: number = null,
        public WorkDescription: string = null,
        public Kostenstelle: number = null,
        public Sachkonto: number = null,
        public Bearbeitungsschluessel: string = null,

        public Timespan: number = null,
        public RealTimespan: number = null,
        public ForcedBreakTime: number = null,
        public DrivingTime: number = null,
        public ExportedAt: Date = null,
    ) {}
    public Clone(override: Partial<ClockInEntity> = {}): ClockInEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ClockInEntity(), attr);
    }
}

export function ClockInEntityFromBackend(res: ClockInType): ClockInEntity {
    return new ClockInEntity(
        res.id,
        res.userId,
        res.employeeId,
        res.clockInTypeId,
        res.type as ClockInTypes,
        res.timeStamp ? BackendDateTimestamp(res.timeStamp) : null,
        res.createdAt ? BackendDateTimestamp(res.createdAt) : null,
        res.updatedAt ? BackendDateTimestamp(res.updatedAt) : null,
        res.deletedAt ? BackendDateTimestamp(res.deletedAt) : null,
        res.partnerId,
        res.city,
        res.postalcode,
        res.country,
        res.commissionId,
        res.workDayId,
        res.workDescription,
        res.kostenstelle,
        res.sachkonto,
        res.bearbeitungsschluessel,
        res.timespan,
        res.realTimespan,
        res.forcedBreakTime,
        res.drivingTime,
        res.exportedAt ? BackendDateTimestamp(res.exportedAt) : null,
    );
}
