import { CommonModule } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component, DEFAULT_CURRENCY_CODE,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output
} from "@angular/core";
import {
    AbstractControl,
    FormControl,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
    Validators, ɵFormGroupValue
} from "@angular/forms";
import { Store } from '@ngrx/store';
import { combineLatest,Observable,Subject,Subscription } from 'rxjs';
import { filter,map,skip,startWith,switchMap,take,tap,withLatestFrom } from 'rxjs/operators';
import { ARLTemplateTypeEntity } from '../../../dave-data-module/entities/a-r-l-template-type.entity';
import { AccountsReceivableLedgerTemplateEntity,ARLCustomProperties } from '../../../dave-data-module/entities/accounts-receivable-ledger-template.entity';
import { PartnerSpecificationType } from '../../../dave-data-module/entities/partner.entity';
import { State } from '../../../dave-data-module/State';
import { AccountsReceivableLedgerTemplateActionTypes,ARLTemplateTypeActions } from '../../../dave-data-module/State/actions/accounting.actions';
import {
getAccountsReceivableLedgerTemplateById,
getAccountsReceivableLedgerTemplates,
getARLTemplateTypes,
getQuantityTypes
} from '../../../dave-data-module/State/selectors/accounting.selector';
import { getCommissionTypes } from '../../../dave-data-module/State/selectors/commissionType.selectors';
import { getPartner } from '../../../dave-data-module/State/selectors/partners.selectors';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { isNotNullOrUndefined } from "../../../helper/helper";
import { decimalPriceValidator,userRequiredFieldValidator } from '../../../helper/validation.helper';
import { JvegTemplateListComponent } from "../jveg-template-list/jveg-template-list.component";
import {
    DaveSharedComponentsModule
} from "../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module";
import { currencies } from "../../../dave-data-module/helper/helper";
type tFormGroup = {
    Tax: FormControl<number>;
    CurrencyCode: FormControl<string>;
    BookingText: FormControl<string>;
    CostAmount: FormControl<number>;
    Quantity: FormControl<number>;
    QuantityTypeId: FormControl<number>;
    Information: FormControl<string>;
    CommissionTypeId: FormControl<number>;
    Multiplier: FormControl<number>;
    CustomProperties: FormControl<ARLCustomProperties>;
    TemplateTypeName: FormControl<string>;
    Zuschlag: FormControl<number>;
    Longtext: FormControl<string>;
    ShowLongtext: FormControl<boolean>;
}
type tFormGroupValues = ɵFormGroupValue<tFormGroup>;
const defaults: tFormGroupValues = {
    Tax: 19,
    CurrencyCode: 'EUR',
    BookingText: null,
    CostAmount: null,
    Quantity: null,
    QuantityTypeId: null,
    Information: null,
    CommissionTypeId: null,
    Multiplier: 1,
    CustomProperties: null,
    TemplateTypeName: '',
    Zuschlag: null
};
@Component({
    selector: 'app-modify-booking-option-template',
    templateUrl: './modify-booking-option-template.component.html',
    styleUrls: ['./modify-booking-option-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, JvegTemplateListComponent, DaveSharedComponentsModule],
})
export class ModifyBookingOptionTemplateComponent implements OnInit, OnDestroy {
    public Form = new FormGroup<tFormGroup>(
        {
            Tax: new FormControl<number>(19),
            CurrencyCode: new FormControl<string>(this.defaultCurrencyCode),
            BookingText: new FormControl<string>(null),
            CostAmount: new FormControl<number>(null, [decimalPriceValidator()]),
            Quantity: new FormControl<number>(null),
            QuantityTypeId: new FormControl<number>(null),
            Information: new FormControl<string>(null, [Validators.required]),
            CommissionTypeId: new UntypedFormControl(null),
            Multiplier: new FormControl<number>(1, [Validators.required, Validators.min(0)]),
            CustomProperties: new FormControl<ARLCustomProperties>(null),
            TemplateTypeName: new FormControl<string>(''),
            Zuschlag: new FormControl<number>(null),
            Longtext: new FormControl<string>(null),
            ShowLongtext: new FormControl<boolean>(null),
        },
    );
    @Input() BookingOptionTemplateId: number = null;
    @Input() Save$: Observable<void> = new Subject<void>();
    @Input() Delete$: Observable<void> = new Subject<void>();
    @Output() Close = new EventEmitter<void>();
    ShowJVEG$ = this.store.select(getPartner).pipe(
        filter(isNotNullOrUndefined),
        map((p) => p.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser && !this.BookingOptionTemplateId),
    );
    public ListTemplateData$: Observable<IDetailListTemplateData>;
    public BookingOptionTemplate$: Observable<AccountsReceivableLedgerTemplateEntity>;
    private subs: Subscription[] = [];
    public PushValuesToForm = new Subject<number>();
    SelectedOptions: any[] = [];

    constructor(private appDialog: AppDialogService, private fb: UntypedFormBuilder, private store: Store<State>, @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string) {}

    ngOnInit(): void {
        this.BookingOptionTemplate$ = this.store.select(getAccountsReceivableLedgerTemplateById({ id: this.BookingOptionTemplateId })).pipe(
            tap((bookingOptionTemplate) => {
                var arlTemplates = null;
                this.store
                    .select(getARLTemplateTypes)
                    .pipe(take(1))
                    .subscribe((val) => (arlTemplates = val));
                if (bookingOptionTemplate) {
                    this.Form.setValue({
                        Tax: bookingOptionTemplate.Tax,
                        CurrencyCode: bookingOptionTemplate.CurrencyCode,
                        BookingText: bookingOptionTemplate.BookingText,
                        CostAmount: bookingOptionTemplate.CostAmount,
                        Zuschlag: (bookingOptionTemplate.BaseCost || 0) - (bookingOptionTemplate.CostAmount || 0),
                        Quantity: bookingOptionTemplate.Quantity,
                        QuantityTypeId: bookingOptionTemplate.QuantityTypeId,
                        Information: bookingOptionTemplate.Information,
                        CommissionTypeId: bookingOptionTemplate.CommissionTypeId,
                        Multiplier: bookingOptionTemplate.Multiplier,
                        CustomProperties: bookingOptionTemplate.CustomProperties,
                        TemplateTypeName: bookingOptionTemplate.ARLTemplateTypeId ? arlTemplates.find((a) => a.Id === bookingOptionTemplate.ARLTemplateTypeId)?.Name : '',
                        Longtext: bookingOptionTemplate.Longtext,
                        ShowLongtext: bookingOptionTemplate.ShowLongtext,
                    });
                } else {
                    this.Form.reset(defaults);
                }
            }),
        );
        this.ListTemplateData$ = combineLatest([this.BookingOptionTemplate$, this.Form.controls.CustomProperties.valueChanges.pipe(startWith(this.Form.value.CustomProperties))]).pipe(
            withLatestFrom(this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined)), this.store.select(getCommissionTypes).pipe(filter(isNotNullOrUndefined)), this.store.select(getARLTemplateTypes)),
            map(([[, customProps], quantityTypes, commissionTypes, arlTemplateTypes]) => {
                return {
                    Properties: [
                        // Könnte dazu genutzt werden den type vorauszufüllen
                        // {
                        //     key: 'Art der Vorlage',
                        //     value: '',
                        //     formControl: this.Form.controls.CommissionTypeId,
                        //     options: {
                        //         specialInput: {
                        //             select: commissionTypes.map(ct => ({ optionValue: ct.Id, optionLabel: ct.Name })),
                        //         },
                        //     },
                        // },
                        {
                            key: 'Artikelnummer',
                            value: '',
                            formControl: this.Form.controls.BookingText,
                        },
                        {
                            key: 'Bezeichnung',
                            value: '',
                            formControl: this.Form.controls.Information,
                        },
                        {
                            key: 'Langtext',
                            value: this.Form.value.Longtext || '',
                            formControl: this.Form.controls.Longtext,
                            options: {
                                specialInput: {
                                    textArea: { Fill: false, MinRows: 3 },
                                },
                            },
                        },
                        {
                            key: 'Langtext anzeigen',
                            value: this.Form.value.ShowLongtext || '',
                            formControl: this.Form.controls.ShowLongtext,
                            options: {
                                specialInput: {
                                    boolean: true,
                                },
                            },
                        },
                        {
                            key: 'Menge',
                            value: '',
                            formControl: this.Form.controls.Quantity,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: 'Einheit',
                            value: '',
                            formControl: this.Form.controls.QuantityTypeId,
                            options: {
                                specialInput: {
                                    select: quantityTypes.map((q) => ({ optionValue: q.Id, optionLabel: [q.Name, q.LangerName].filter((v) => !!v).join(' ') })),
                                },
                            },
                        },
                        {
                            key: 'Vorlagentyp',
                            value: this.Form.value.TemplateTypeName || '',
                            formControl: this.Form.controls.TemplateTypeName,
                            options: {
                                specialInput: {
                                    // select: arlTemplateTypes.map(q => ({ optionValue: q.Id, optionLabel: q.Name })),
                                    autocomplete: {
                                        MapFn: (option: ARLTemplateTypeEntity) => option.Name,
                                        FilterFn: (option: ARLTemplateTypeEntity, searchString: string) => [option.Name].some((o) => o?.toLowerCase().includes(searchString?.toLowerCase())),
                                        Options: arlTemplateTypes,
                                        DeleteFn: (option: ARLTemplateTypeEntity) =>
                                            this.appDialog.OpenConfirmationDialog({ paragraph: `Vorlage ${option.Name} wirklich löschen?`, styleDelete: true }).subscribe(([result]) => {
                                                this.SelectedOptions = [];

                                                if (result) {
                                                    this.store
                                                        .select(getAccountsReceivableLedgerTemplates)
                                                        .pipe(
                                                            map((arlts) => arlts.filter((arlt) => arlt.ARLTemplateTypeId == option.Id)),
                                                            take(1),
                                                        )
                                                        .subscribe((val) => {
                                                            val.map((at) => {
                                                                this.store.dispatch(
                                                                    AccountsReceivableLedgerTemplateActionTypes.ChangeAccountsReceivableLedgerTemplate({
                                                                        Payload: {
                                                                            id: at.Id,
                                                                            quantity: at.Quantity,
                                                                            tax: at.Tax,
                                                                            currencyCode: at.CurrencyCode,
                                                                            bookingText: at.BookingText,
                                                                            baseCost: at.BaseCost,
                                                                            information: at.Information,
                                                                            quantityTypeId: at.QuantityTypeId,
                                                                            multiplikator: at.Multiplier,
                                                                            customProperties: JSON.stringify(at.CustomProperties),
                                                                            aRLTemplateTypeId: null,
                                                                        },
                                                                    }),
                                                                );
                                                            });
                                                        });
                                                    this.store.dispatch(
                                                        ARLTemplateTypeActions.DeleteARLTemplateType({
                                                            Payload: {
                                                                id: option.Id,
                                                            },
                                                        }),
                                                    );
                                                }
                                            }),
                                    },
                                },
                            },
                        },
                        {
                            key: 'Kosten',
                            value: '',
                            formControl: this.Form.controls.CostAmount,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: 'Zuschlag',
                            value: '',
                            formControl: this.Form.controls.Zuschlag,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: 'Multiplikator',
                            value: '',
                            formControl: this.Form.controls.Multiplier,
                            hideFormControl: !customProps?.showMultiplier,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: 'Steuern',
                            value: '',
                            formControl: this.Form.controls.Tax,
                            options: {
                                type: CustomPropertyType.Percentage,
                                specialInput: {
                                    number: true,
                                },
                                suffix: '%',
                            },
                        },
                        {
                            key: 'Währung',
                            value: '',
                            formControl: this.Form.controls.CurrencyCode,
                            options: {
                                specialInput: {
                                    select: currencies.map(c => ({ optionValue: c.code, optionLabel: c.label })),
                                },
                            },
                        },
                    ],
                };
            }),
        );
        this.subs.push(
            this.Form.controls.CustomProperties.valueChanges
                .pipe(
                    tap((v) => {
                        if (!v?.showMultiplier) {
                            this.Form.controls.Multiplier.setValue(1);
                        }
                    }),
                )
                .subscribe(),
            this.Delete$.pipe(
                tap(() =>
                    this.store.dispatch(
                        AccountsReceivableLedgerTemplateActionTypes.DeleteAccountsReceivableLedgerTemplate({
                            Payload: { id: this.BookingOptionTemplateId },
                        }),
                    ),
                ),
            ).subscribe(),
            this.Save$.pipe(
                filter(() => this.Form.valid),
                withLatestFrom(this.store.select(getARLTemplateTypes)),
                switchMap(([, templateTypes]) => {
                    if (!this.Form.value.TemplateTypeName || templateTypes.some((t) => t.Name === this.Form.value.TemplateTypeName)) {
                        return this.store.select(getARLTemplateTypes).pipe(take(1));
                    } else {
                        this.store.dispatch(
                            ARLTemplateTypeActions.AddARLTemplateType({
                                Payload: {
                                    name: this.Form.value.TemplateTypeName,
                                },
                            }),
                        );
                        return (
                            this.store
                                .select(getARLTemplateTypes)
                                // aktuellen Wert überspringen und auf Response warten -
                                // mit oder ohne neuem ARLTemplateType, siehe AddARLTemplateType Effect
                                .pipe(skip(1), take(1))
                        );
                    }
                }),
                tap((arlTemplates) => {
                    this.store.dispatch(
                        this.BookingOptionTemplateId
                            ? AccountsReceivableLedgerTemplateActionTypes.ChangeAccountsReceivableLedgerTemplate({
                                  Payload: {
                                      id: this.BookingOptionTemplateId,
                                      tax: this.Form.value.Tax,
                                      currencyCode: this.Form.value.CurrencyCode,
                                      bookingText: this.Form.value.BookingText,
                                      quantity: this.Form.value.Quantity,
                                      baseCost: (this.Form.value.CostAmount || 0) + (this.Form.value.Zuschlag || 0),
                                      costAmount: this.Form.value.CostAmount,
                                      quantityTypeId: this.Form.value.QuantityTypeId,
                                      information: this.Form.value.Information,
                                      multiplikator: this.Form.value.Multiplier,
                                      customProperties: JSON.stringify(this.Form.value.CustomProperties),
                                      commissionTypeId: this.Form.value.CommissionTypeId,
                                      aRLTemplateTypeId: this.Form.value.TemplateTypeName ? arlTemplates.find((a) => a.Name === this.Form.value.TemplateTypeName)?.Id : null,
                                      showLongInformation: this.Form.value.ShowLongtext || false,
                                      longInformation: this.Form.value.Longtext,
                                  },
                              })
                            : AccountsReceivableLedgerTemplateActionTypes.AddAccountsReceivableLedgerTemplate({
                                  Payload: {
                                      tax: this.Form.value.Tax,
                                      currencyCode: this.Form.value.CurrencyCode,
                                      bookingText: this.Form.value.BookingText,
                                      quantity: this.Form.value.Quantity,
                                      baseCost: (this.Form.value.CostAmount || 0) + (this.Form.value.Zuschlag || 0),
                                      costAmount: this.Form.value.CostAmount,
                                      quantityTypeId: this.Form.value.QuantityTypeId,
                                      information: this.Form.value.Information,
                                      multiplikator: this.Form.value.Multiplier,
                                      customProperties: JSON.stringify(this.Form.value.CustomProperties),
                                      commissionTypeId: this.Form.value.CommissionTypeId,
                                      aRLTemplateTypeId: this.Form.value.TemplateTypeName ? arlTemplates.find((a) => a.Name === this.Form.value.TemplateTypeName)?.Id : null,
                                      showLongInformation: this.Form.value.ShowLongtext || false,
                                      longInformation: this.Form.value.Longtext,
                                  },
                              }),
                    );
                    this.Close.emit();
                }),
            ).subscribe(),
            this.PushValuesToForm.pipe(
                switchMap((id) => this.store.select(getAccountsReceivableLedgerTemplateById({ id }))),
                tap((bookingOptionTemplate) => {
                    this.SelectedOptions = [];
                    var arlTemplates = null;
                    this.store
                        .select(getARLTemplateTypes)
                        .pipe(take(1))
                        .subscribe((val) => (arlTemplates = val));
                    this.Form.setValue({
                        Tax: bookingOptionTemplate.Tax,
                        CurrencyCode: bookingOptionTemplate.CurrencyCode,
                        BookingText: bookingOptionTemplate.BookingText,
                        CostAmount: bookingOptionTemplate.CostAmount,
                        Zuschlag: (bookingOptionTemplate.BaseCost || 0) - (bookingOptionTemplate.CostAmount || 0),
                        Quantity: bookingOptionTemplate.Quantity,
                        QuantityTypeId: bookingOptionTemplate.QuantityTypeId,
                        Information: bookingOptionTemplate.Information,
                        CommissionTypeId: bookingOptionTemplate.CommissionTypeId,
                        Multiplier: bookingOptionTemplate.Multiplier,
                        CustomProperties: { ...bookingOptionTemplate.CustomProperties, jveg: false },
                        TemplateTypeName: bookingOptionTemplate.ARLTemplateTypeId ? arlTemplates.find((a) => a.Id == bookingOptionTemplate.ARLTemplateTypeId)?.Name : null,
                        Longtext: bookingOptionTemplate.Longtext,
                        ShowLongtext: bookingOptionTemplate.ShowLongtext,
                    });
                }),
            ).subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
}
