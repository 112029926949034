import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GetFileMimeTypeText } from '../../../dave-data-module/entities/file.entity';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { State } from '../../../dave-data-module/State';
import { getFiles } from '../../../dave-data-module/State/selectors/files.selectors';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, uniqArray } from '../../../helper/helper';
import { DMSPageMeta } from '../../../helper/page-metadata';
export interface DaveSelectFileFromDmsComponentDialogData {
    preSelectedFiles?: number[];
    folderId?: number;
    moveWarning?: string;
    maxFiles?: number;
    acceptedMimeTypes?: string;
}
export interface DaveSelectFileFromDmsComponentReturnData {
    documents?: number[];
}
@Component({
    selector: 'app-email-file-upload-dms',
    templateUrl: './dave-select-file-from-dms.component.html',
    styleUrls: ['./dave-select-file-from-dms.component.scss'],
})
export class DaveSelectFileFromDmsComponent implements OnInit {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        maxHeight: '100%',
        maxWidth: '100%',
        minWidth: '80vw',
        height: '90vh',
    };
    public Mobile$ = this.bs.SmallQuery;
    public DMSMeta = DMSPageMeta;
    public SelectedFiles = new BehaviorSubject<number[]>([]);
    public PreviewedFileId$ = new BehaviorSubject<number | null>(null);
    public ShownDMSFiles$ = combineLatest([this.store.select(getFiles), this.SelectedFiles]).pipe(map(([files, selectedFileIds]) => files.filter((f) => selectedFileIds.indexOf(f.Id) >= 0)));
    private previewDialogRef: MatDialogRef<DaveFilePreviewComponent>;
    constructor(
        private bs: BreakpointObserverService,
        private store: Store<State>,
        private dialog: MatDialog,
        private fs: FileDataService,
        private toastrService: ToastrService,
        @Inject(MAT_DIALOG_DATA) public DialogData: DaveSelectFileFromDmsComponentDialogData,
        private fileDataService: FileDataService,
    ) {}
    public selectUnselectFile(fileId: number) {
        if (this.SelectedFiles.value.indexOf(fileId) >= 0) {
            const temp = this.SelectedFiles.value;
            temp.splice(temp.indexOf(fileId), 1);
            this.SelectedFiles.next(temp);
        } else {
            if (this.DialogData?.maxFiles && this.SelectedFiles.value.length >= this.DialogData?.maxFiles) {
                return;
            }
            const temp = this.SelectedFiles.value.slice();
            temp.push(fileId);
            this.SelectedFiles.next(temp);
        }
    }
    public SelectUnselectFile(fileId: number) {
        if (!this.DialogData.acceptedMimeTypes) {
            this.selectUnselectFile(fileId);
        } else {
            firstValueFrom(this.fs.GetFileById$(fileId)).then((f) => {
                if (f?.MIMEType !== this.DialogData.acceptedMimeTypes) {
                    this.toastrService.error('Falscher Dateityp, nur ' + GetFileMimeTypeText(this.DialogData.acceptedMimeTypes) + ' erlaubt');
                } else {
                    this.selectUnselectFile(fileId);
                }
            });
        }
    }
    ngOnInit(): void {
        if (!this.DialogData) {
            console.warn('DialogData not set');
        }
        if (this.DialogData?.preSelectedFiles) {
            this.SelectedFiles.next(this.DialogData?.preSelectedFiles);
        }
    }

    openPreview(fileId: number) {
        if (this.previewDialogRef && this.previewDialogRef.getState() !== MatDialogState.CLOSED) {
            this.previewDialogRef.close();
        }
        let imageIds: number[];
        this.fileDataService
            .GetFilesById(uniqArray([...this.SelectedFiles.value.slice(), fileId].filter(isNotNullOrUndefined)))
            .pipe(
                take(1),
                map((files) => files.filter((f) => f.MIMEType.indexOf('image/') > -1).map((f) => f.Id)),
            )
            .subscribe((imgIds) => {
                imageIds = imgIds;
            });
        this.previewDialogRef = this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: {
                fileId,
                fileExplorerContext: imageIds
                    ? {
                          directoryFileIds: imageIds,
                      }
                    : undefined,
            },
        });
    }
}
