import { DamageFlowTypeEntity } from '../../entities/damageFlowType.entity';
import { createReducer, on } from '@ngrx/store';
import { DamageFlowTypeActionTypes } from '../actions/damageFlowType.actions';


export const damageFlowTypesFeatureKey = 'damageFlowTypes';

export interface DamageFlowTypeState {
    damageFlowTypes: DamageFlowTypeEntity[];
}

export const initialState: DamageFlowTypeState = {
    damageFlowTypes: null,
};

export const DamageFlowTypeReducer = createReducer(
    initialState,
    on(DamageFlowTypeActionTypes.UpdateDamageFlowType, (state, { Payload }) => ({
        ...state,
        damageFlowTypes: Payload,
    })),
);
