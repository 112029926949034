import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { ConstructionDiaryEntity, ConstructionDiaryStatusEnum } from '../../../../dave-data-module/entities/construction-diary.entity';
import { EventTypeNamesEnum } from '../../../../dave-data-module/entities/event-type.entity';
import { EventEntity } from '../../../../dave-data-module/entities/event.entity';
import { GeneratedDocumentsEntity } from '../../../../dave-data-module/entities/generatedDocuments.entity';
import { GeneratedDocumentsTypeSlugs, GeneratedDocumentsTypeSlugsForDiary } from '../../../../dave-data-module/entities/generatedDocumentsType.entity';
import { GeneratedDocumentsResolver } from '../../../../dave-data-module/guards/generatedDocuments.resolver';
import { GeneratedDocumentsTypeResolver } from '../../../../dave-data-module/guards/generatedDocumentsType.resolver';
import { TextTemplateLanguageResolver } from '../../../../dave-data-module/guards/text-template-language.resolver';
import { UserToEventResolver } from '../../../../dave-data-module/guards/user-to-event.resolver';
import { FrontendDate } from '../../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { HttpService } from '../../../../dave-data-module/services/http.service';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { getConstructionDiaries } from '../../../../dave-data-module/State/selectors/construction-diary.selectors';
import { getEventTypes } from '../../../../dave-data-module/State/selectors/event-type.selector';
import { getEvents } from '../../../../dave-data-module/State/selectors/events.selectors';
import { getUserToEventFetched } from '../../../../dave-data-module/State/selectors/user-to-event.selector';
import { sameDay } from '../../../../helper/helper';
import { AllReportsMeta, DocumentEditorMeta, GeneratedDocumentsPageMeta } from '../../../../helper/page-metadata';
import { CustomLabelService } from '../../../../services/custom-label.service';
import { LoadingService } from '../../../../services/loading.service';
import { DiaryDataService } from "../../../../services/diary-data.service";
export interface SelectDiaryTimespanDialogComponentDialogData {
    CommissionId: number;
    DiaryType?: GeneratedDocumentsTypeSlugsForDiary;
}
@Component({
    selector: 'app-select-diary-timespan-dialog',
    templateUrl: './select-diary-timespan-dialog.component.html',
    styleUrls: ['./select-diary-timespan-dialog.component.scss'],
    providers: [DatePipe],
})
export class SelectDiaryTimespanDialogComponent implements OnInit, OnDestroy {
    private today = () => {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        return d;
    }
    FormGroup = new FormGroup(
        {
            from: new FormControl<Moment>(moment(this.today()), Validators.required),
            to: new FormControl<Moment>(moment(this.today()), Validators.required),
            zusatzarbeiten: new FormControl<boolean>(false),
            bedenken: new FormControl<boolean>(false),
            mangel: new FormControl<boolean>(false),
            bautagebuch: new FormControl<boolean>(true),
        },
        (group) => {
            return group.value.zusatzarbeiten || group.value.bautagebuch || group.value.bedenken || group.value.mangel ? null : { nothingToExport: { value: group.value } };
        },
    );
    public DateForm = this.FormGroup.controls.from;
    public ToDateForm = this.FormGroup.controls.to;
    public Diaries: ConstructionDiaryEntity[];
    private subs: Subscription[] = [];
    private diaries$: Observable<ConstructionDiaryEntity[]>;
    private nachnahmenFromCommission$: Observable<EventEntity[]>;
    private mangelFromCommission$: Observable<EventEntity[]>;
    private bedenkenFromCommission$: Observable<EventEntity[]>;
    private nachnahmenFromCommission: EventEntity[];
    private mangelFromCommission: EventEntity[];
    private bedenkenFromCommission: EventEntity[];
    public DiaryCount$: Observable<number>;
    public NachnahmenCount$: Observable<number>;
    public BedenkenCount$: Observable<number>;
    public MangelCount$: Observable<number>;
    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA)
        public DialogData: SelectDiaryTimespanDialogComponentDialogData,
        private router: Router,
        private dialogRef: MatDialogRef<SelectDiaryTimespanDialogComponent>,
        private api: HttpService,
        public LS: LoadingService,
        u2eResolver: UserToEventResolver,
        langRes: TextTemplateLanguageResolver,
        generatedDocumentsTypeResolver: GeneratedDocumentsTypeResolver,
        private generatedDocumentsResolver: GeneratedDocumentsResolver,
        protected cls: CustomLabelService,
        private diaryService: DiaryDataService,
    ) {
        langRes.resolve();
        u2eResolver.resolve();
        generatedDocumentsTypeResolver.resolve();
    }
    DateClass = (d: Moment): MatCalendarCellCssClasses => {
        const date = d.toDate();

        if (this.FormGroup.controls.zusatzarbeiten.value && this.nachnahmenFromCommission?.some((e) => sameDay(e.EventDate, date))) {
            return 'mat-datepicker-yellow';
        } else if (this.FormGroup.controls.bedenken.value && this.bedenkenFromCommission?.some((e) => sameDay(e.EventDate, date))) {
            return 'mat-datepicker-yellow';
        } else if (this.FormGroup.controls.mangel.value && this.mangelFromCommission?.some((e) => sameDay(e.EventDate, date))) {
            return 'mat-datepicker-yellow';
        } else if (this.FormGroup.controls.bautagebuch.value) {
            const diary = this.Diaries.find((d) => sameDay(d.Date, date));
            return diary?.GeneratedFileId ? 'mat-datepicker-blue' : diary?.Status === ConstructionDiaryStatusEnum.Newly ? 'mat-datepicker-yellow' : diary?.Status === ConstructionDiaryStatusEnum.Done ? 'mat-datepicker-green' : '';
        }
        return '';
    };
    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    ngOnInit(): void {
        if (this.DialogData.DiaryType) {
            this.FormGroup.controls.bautagebuch.setValue(this.DialogData.DiaryType === GeneratedDocumentsTypeSlugs.constructionDiary);
            this.FormGroup.controls.zusatzarbeiten.setValue(this.DialogData.DiaryType === GeneratedDocumentsTypeSlugs.zusatzarbeit);
            this.FormGroup.controls.bedenken.setValue(this.DialogData.DiaryType === GeneratedDocumentsTypeSlugs.bedenken);
            this.FormGroup.controls.mangel.setValue(this.DialogData.DiaryType === GeneratedDocumentsTypeSlugs.mangel);
        }
        const eventsByTypeByCommissionId$ = (eventTypeName: EventTypeNamesEnum, commissonId: number) =>
            combineLatest([this.store.select(getEvents), this.store.select(getEventTypes)]).pipe(
                map(([events, types]) => {
                    const type = types?.find((e) => e.Name === eventTypeName);
                    return events?.filter((e) => e.EventTypeId === type.Id && e.CommissionId === commissonId);
                }),
                shareReplay({ refCount: true, bufferSize: 1 }),
            );
        this.nachnahmenFromCommission$ = eventsByTypeByCommissionId$(EventTypeNamesEnum.Zusatzarbeit, this.DialogData.CommissionId);
        this.mangelFromCommission$ = eventsByTypeByCommissionId$(EventTypeNamesEnum.Mangelanzeige, this.DialogData.CommissionId);
        this.bedenkenFromCommission$ = eventsByTypeByCommissionId$(EventTypeNamesEnum.Bedenkenanzeige, this.DialogData.CommissionId);
        this.diaries$ = combineLatest([this.store.select(getConstructionDiaries), this.store.select(getUserToEventFetched).pipe(filter((v) => !!v))]).pipe(
            map(([diaries]) => diaries?.filter((d) => d.CommissionId === this.DialogData.CommissionId)),
        );
        this.subs.push(
            this.diaries$.subscribe((d) => (this.Diaries = d)),
            this.nachnahmenFromCommission$.subscribe((d) => (this.nachnahmenFromCommission = d)),
            this.mangelFromCommission$.subscribe((d) => (this.mangelFromCommission = d)),
            this.bedenkenFromCommission$.subscribe((d) => (this.bedenkenFromCommission = d)),
        );
        this.DiaryCount$ = combineLatest([this.diaries$, this.ToDateForm.valueChanges.pipe(startWith('')), this.DateForm.valueChanges.pipe(startWith(''))]).pipe(
            map(([diaries]) => {
                if (this.DateForm.value && this.ToDateForm.value) {
                    const fromMoment = moment(new Date(FrontendDate(this.DateForm.value.toDate(), true)));
                    const toMoment = moment(new Date(FrontendDate(this.ToDateForm.value.toDate(), true)));
                    return diaries.filter((d) => moment(new Date(FrontendDate(d.Date, true))).isBetween(fromMoment, toMoment, undefined, '[]')).length;
                } else {
                    return 0;
                }
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
        const eventCountFromForm = (events$: Observable<EventEntity[]>) =>
            combineLatest([events$, this.ToDateForm.valueChanges.pipe(startWith('')), this.DateForm.valueChanges.pipe(startWith(''))]).pipe(
                map(([nachnahmen]) => {
                    if (this.DateForm.value && this.ToDateForm.value) {
                        const fromMoment = moment(new Date(FrontendDate(this.DateForm.value.toDate(), true)));
                        const toMoment = moment(new Date(FrontendDate(this.ToDateForm.value.toDate(), true)));
                        return nachnahmen.filter((d) => moment(new Date(FrontendDate(d.EventDate, true))).isBetween(fromMoment, toMoment, undefined, '[]')).length;
                    } else {
                        return 0;
                    }
                }),
                shareReplay({ bufferSize: 1, refCount: true }),
            );
        this.NachnahmenCount$ = eventCountFromForm(this.nachnahmenFromCommission$);
        this.BedenkenCount$ = eventCountFromForm(this.bedenkenFromCommission$);
        this.MangelCount$ = eventCountFromForm(this.mangelFromCommission$);
    }
    public Submit() {
        const typesToGenerate: GeneratedDocumentsTypeSlugsForDiary[] = [];
        if (this.FormGroup.value.bautagebuch) {
            typesToGenerate.push(GeneratedDocumentsTypeSlugs.constructionDiary);
        }
        if (this.FormGroup.value.zusatzarbeiten) {
            typesToGenerate.push(GeneratedDocumentsTypeSlugs.zusatzarbeit);
        }
        if (this.FormGroup.value.bedenken) {
            typesToGenerate.push(GeneratedDocumentsTypeSlugs.bedenken);
        }
        if (this.FormGroup.value.mangel) {
            typesToGenerate.push(GeneratedDocumentsTypeSlugs.mangel);
        }
        const count = typesToGenerate.length;
        if (count === 0) {
            return;
        }
        this.LS.startLoading('create-diary');
        this.diaryService.generateDiary(typesToGenerate, this.DialogData.CommissionId, this.DateForm.value.toDate(), this.ToDateForm.value.toDate()).then(res => {
            if (res?.length) {
                if (count === 1) {
                    this.router.navigate(['/', GeneratedDocumentsPageMeta.Path, AllReportsMeta.Path, DocumentEditorMeta.Path, res[0].id]);
                } else {
                    this.generatedDocumentsResolver.pollUpdated();
                }
                this.dialogRef.close();
            }
            }
        ).finally(() => this.LS.endLoading('create-diary'));
    }
}
