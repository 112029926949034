// window.env 566werden von /assets/env.js zur Laufzeit gesetzt

import { isNotNullOrUndefined } from '../app/helper/helper';

export const environment = {
    // @ts-ignore
    production: window?.env?.production != null ? window?.env?.production : false,
    // @ts-ignore
    hmr: window?.env?.hmr != null ? window?.env?.hmr : false,
    // @ts-ignore
    gatewayApiUrl: window?.env?.gatewayApiUrl || 'dave.omnia5.local',
    // @ts-ignore
    videoUrl: window?.env?.videoUrl || 'dave.omnia5.local',
    // @ts-ignore
    searchApiUrl: window?.env?.searchApiUrl || '/search',
    // @ts-ignore
    useProtobuf: window?.env?.useProtobuf != null ? window.env.useProtobuf : true,
};
