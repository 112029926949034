import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  GeneratedDocumentsTypeState,
  generatedDocumentsTypesFeatureKey
} from "../reducers/generatedDocumentsType.reducer";
import { State } from "../index";

export const selectGeneratedDocumentsTypes = createFeatureSelector<
  GeneratedDocumentsTypeState
>(generatedDocumentsTypesFeatureKey);
export const getGeneratedDocumentsTypes = createSelector(
  selectGeneratedDocumentsTypes,
  data => data.generatedDocumentsTypes
);export const getGeneratedDocumentsTypesFetched = createSelector(
  selectGeneratedDocumentsTypes,
  data => !!data.generatedDocumentsTypes
);
export const getSingleGeneratedDocumentsType = createSelector(
  getGeneratedDocumentsTypes,
  (generatedDocumentsType: ReturnType<typeof getGeneratedDocumentsTypes>, Id: number) =>
    generatedDocumentsType ? generatedDocumentsType.find(e => e.Id === Id) : null
);
