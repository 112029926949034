<app-detail-view-template [ShowPlaceholder]="false">
    <ng-container *appDetailViewButtons>
        <button app-round-button Inverted matTooltip="Sortieren" aria-label="Sortieren" style="margin: .5rem" [matMenuTriggerFor]="sortMenu"><fa-icon icon="sort"></fa-icon></button>
        <button app-round-button Inverted matTooltip="Filter" aria-label="Filter" (click)="filter.Open()" [matBadge]="FilterAmount$ | async" [matBadgeHidden]="!(FilterAmount$ | async)" style="margin: .5rem"><fa-icon icon="filter"></fa-icon></button>
    </ng-container>
    <ng-container *appDetailViewTopContent>
        <app-tasks-board [Filter]="TaskFilter$ | async" [PreFilter]="{authorOrUserToEventOrUserToCommission: true}" (FileClicked)="OpenFile($event)" [Sort]="TaskBoardSort"></app-tasks-board>
    </ng-container>
</app-detail-view-template>
<app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
<mat-menu #sortMenu="matMenu">
    <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'endDate', direction: 'asc'}"><fa-icon icon="chevron-up"></fa-icon> Frist</button>
    <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'endDate', direction: 'dsc'}"><fa-icon icon="chevron-down"></fa-icon> Frist</button>
    <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'priority', direction: 'asc'}"><fa-icon icon="chevron-up"></fa-icon> Priorität</button>
    <button mat-menu-item (click)="TaskBoardSort = {sortBy: 'priority', direction: 'dsc'}"><fa-icon icon="chevron-down"></fa-icon> Priorität</button>
</mat-menu>
