import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, firstValueFrom, of } from 'rxjs';
import { catchError, concatMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import {
    EmployeeToVacationEntity,
    EmployeeToVacationEntityFromBackend,
} from '../../entities/employee-to-vacation.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { EmployeeToVacationActionTypes } from '../actions/employee-to-vacation.actions';
import { State } from '../index';
import { getEmployeeToVacations } from '../selectors/employee-to-vacation.selectors';
import { EmployeeActionTypes } from '../actions/employee.actions';
import { getToken } from '../selectors/base.selectors';
import { WorkDayResolver } from '../../guards/work-day.resolver';
import { getEmployeesFetched } from '../selectors/employees.selectors';
import { getWorkDaysFetched } from '../selectors/work-day.selectors';

enum ErrorCodes {
    Load = 'Urlaubsanträge Abrufen fehlgeschlagen',
    Remove = 'Urlaubsantrag Löschen fehlgeschlagen',
    Modify = 'Urlaubsantrag ändern fehlgeschlagen',
    Add = 'Urlaubsantrag hinzufügen fehlgeschlagen',
    Approve = 'Urlaubsantrag genehmigen fehlgeschlagen',
}

@Injectable()
export class EmployeeToVacationEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
        private workDayResolver: WorkDayResolver,
    ) {}

    GetAllVacationsQuery$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeToVacationActionTypes.Load),
        withLatestFrom(this.store$.select(getToken)),
        switchMap(([{ Payload }, token]) => {
            const queryString = `query {
          employee2vacation${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
            ${EmployeeToVacationEntity.GqlFields.join(',')}
          }
        }
        `;
            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                tap(res => {
                    if (!(Payload?.updatedSince && !res.employee2vacation.length)) {
                        this.store$.dispatch(
                            res && res.employee2vacation
                                ? Payload?.updatedSince
                                    ? EmployeeToVacationActionTypes.UpdateMany({
                                        Payload: res.employee2vacation.map(val => EmployeeToVacationEntityFromBackend(val)),
                                        updateLatestUpdatedAt: true,
                                    })
                                    : EmployeeToVacationActionTypes.UpdateAll({
                                        Payload: res.employee2vacation.map(val => EmployeeToVacationEntityFromBackend(val)),
                                        updateLatestUpdatedAt: true,
                                    })
                                : BaseActionTypes.ErrorAction({
                                    Payload: { ToasterMessage: ErrorCodes.Load },
                                }),
                        );
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            );
        }),
    ), { dispatch: false });

    ModifyVacations$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeToVacationActionTypes.Modify),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
        mutation {
            changeEmployee2Vacation(
                id: ${JSON.stringify(Payload.id)}
                ${stringifyIfNotNullOrUndefined(Payload, 'employeeId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'voteCount')}
                ${stringifyIfNotNullOrUndefined(Payload, 'vacationDays')}
                ${stringifyIfNotNullOrUndefined(Payload, 'startDate')}
                ${stringifyIfNotNullOrUndefined(Payload, 'endDate')}
                ${stringifyIfNotNullOrUndefined(Payload, 'vacationStatus')}
                ${stringifyIfNotNullOrUndefined(Payload, 'userIdListApproved')}
                ${stringifyIfNotNullOrUndefined(Payload, 'userIdListDeclined')}
                ${stringifyIfNotNullOrUndefined(Payload, 'notes')}
                ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
            ) {
                ${EmployeeToVacationEntity.GqlFields.join(',')}
          }
        }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                tap(() => firstValueFrom(this.store$.select(getWorkDaysFetched)).then(f => {if (f) { this.workDayResolver.pollUpdated()}})),
                concatMap((res) =>
                    res && res.changeEmployee2Vacation
                        ? [EmployeeToVacationActionTypes.UpdateMany({
                              Payload: [
                                  EmployeeToVacationEntityFromBackend(res.changeEmployee2Vacation),
                              ],
                          }),
                            EmployeeActionTypes.Load({})]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } })],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    AddVacations$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeToVacationActionTypes.Add),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
        mutation {
            createEmployee2Vacation(
                ${stringifyIfNotNullOrUndefined(Payload, 'employeeId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'vacationDays')}
                ${stringifyIfNotNullOrUndefined(Payload, 'startDate')}
                ${stringifyIfNotNullOrUndefined(Payload, 'endDate')}
                ${stringifyIfNotNullOrUndefined(Payload, 'userIdListApproved')}
                ${stringifyIfNotNullOrUndefined(Payload, 'userIdListDeclined')}
                ${stringifyIfNotNullOrUndefined(Payload, 'type')}
                ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                ${stringifyIfNotNullOrUndefined(Payload, 'notes')}
            ) {
                ${EmployeeToVacationEntity.GqlFields.join(',')}
          }
        }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                tap(() => firstValueFrom(this.store$.select(getWorkDaysFetched)).then(f => {if (f) { this.workDayResolver.pollUpdated()}})),
                concatMap((res) =>
                    res && res.createEmployee2Vacation
                        ? [EmployeeToVacationActionTypes.UpdateMany({
                              Payload: [
                                  EmployeeToVacationEntityFromBackend(res.createEmployee2Vacation),
                              ],
                          }),
                            EmployeeActionTypes.Load({})]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } })],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    ApproveVacations$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeToVacationActionTypes.Approve),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
        mutation {
            changeEmployee2VacationApproval(
                id: ${JSON.stringify(Payload.id)}
                ${stringifyIfNotNullOrUndefined(Payload, 'approved')}
            ) {
                ${EmployeeToVacationEntity.GqlFields.join(',')}
          }
        }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                tap(() => firstValueFrom(this.store$.select(getWorkDaysFetched)).then(f => {if (f) { this.workDayResolver.pollUpdated()}})),
                withLatestFrom(this.store$.select(getEmployeeToVacations)),
                concatMap(([res, employeeToVacations]) =>
                    res && res.changeEmployee2VacationApproval
                        ? [EmployeeToVacationActionTypes.UpdateAll({
                              Payload: [
                                  ...employeeToVacations.filter(e => e.Id !== res.changeEmployee2VacationApproval.id),
                                  EmployeeToVacationEntityFromBackend(res.changeEmployee2VacationApproval),
                              ],
                          }),
                        EmployeeActionTypes.Load({})]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Approve } })],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Approve,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    DeleteVacations$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeToVacationActionTypes.Delete),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                deleteEmployee2Vacation(id: ${action.Payload.id}){
            ${EmployeeToVacationEntity.GqlFields.join(',')}
          }
        }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                tap(() => firstValueFrom(this.store$.select(getWorkDaysFetched)).then(f => {if (f) { this.workDayResolver.pollUpdated()}})),
                withLatestFrom(this.store$.select(getEmployeeToVacations)),
                concatMap(([res, employeeToVacations]) =>
                    res && res.deleteEmployee2Vacation
                        ? [EmployeeToVacationActionTypes.UpdateAll({
                              Payload: employeeToVacations.filter(e => e.Id !== res.deleteEmployee2Vacation.id),
                          }), EmployeeActionTypes.Load({})]
                        : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } })],
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
}
