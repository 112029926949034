export const transparentPixelLogo: Base64Logo = {
    base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
    width: 1,
    height: 1,
};
export interface Base64Logo {
    base64: string;
    width: number;
    height: number;
}
