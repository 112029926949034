import { DetectionEntity } from '../../entities/detection.entity';
import { createReducer, on } from '@ngrx/store';
import { DetectionActionTypes } from '../actions/detection.actions';


export const documentDetectionFeatureKey = 'documentDetection';

export interface DocumentDetectionState {
    detection: DetectionEntity[];
}

export const initialState: DocumentDetectionState = {
    detection: null,
};

export const documentDetectionReducer = createReducer(
    initialState,
    on(DetectionActionTypes.RemoveDetection, (state, { Payload }) => ({
        ...state,
        detection: state.detection ? [...state.detection.filter(d => d.Id !== Payload)] : state.detection,
    })),
    on(DetectionActionTypes.UpdateDetections, (state, { Payload }) => {
        const newIds = Payload.map(d => d.Id);
        const oldDetections = state.detection ? state.detection.filter(d => newIds.indexOf(d.Id) < 0) : [];
        return {
            ...state,
            detection: [...oldDetections, ...Payload],
        };
    }),
);
