import { BooleanInput,coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest,Observable } from 'rxjs';
import { filter,map } from 'rxjs/operators';
import { DocumentState, FileEntity, FileEntityDocumentStateNames } from '../dave-data-module/entities/file.entity';
import { FileDataService } from '../dave-data-module/services/file-data.service';
import { HttpService } from '../dave-data-module/services/http.service';
import { State } from '../dave-data-module/State';
import { getToken } from '../dave-data-module/State/selectors/base.selectors';
import { isNotNullOrUndefined } from '../helper/helper';
import { FileLinkIcon } from "../helper/page-metadata";
interface ShownFile extends FileEntity {
    thumbnailPath?: string;
}
@Component({
    selector: 'app-small-file-card',
    templateUrl: './small-file-card.component.html',
    styleUrls: ['./small-file-card.component.scss'],
})
export class SmallFileCardComponent implements OnInit {
    @Input() FileId: number;
    @Input() ShowFileName = true;
    protected removeButton = false;
    @Input() set RemoveButton(value) {
        this.removeButton = coerceBooleanProperty(value);
    };
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_RemoveButton: BooleanInput;
    protected checkbox = false;
    @Input() set Checkbox(value) {
        this.checkbox = coerceBooleanProperty(value);
    };
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_Checkbox: BooleanInput;
    protected checkboxSelected = false;
    @Input() set CheckboxSelected(value) {
        this.checkboxSelected = coerceBooleanProperty(value);
    };
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_CheckboxSelected: BooleanInput;
    @Output() FileClick = new EventEmitter<ShownFile>();
    @Output() RemoveClick = new EventEmitter<ShownFile>();
    File$: Observable<ShownFile>;
    constructor(private store: Store<State>, private api: HttpService, private fileDataService: FileDataService) {}

    ngOnInit(): void {
        this.File$ = combineLatest([this.fileDataService.GetFileById$(this.FileId).pipe(filter(isNotNullOrUndefined)), this.store.select(getToken)]).pipe(
            map(([file, token]) =>
                file && Object.assign(file.Clone(), {
                    thumbnailPath: file.GetLastVersion()?.HasThumbnail ? this.api.GetUrl('', 'file') + file.GetLastVersion().GetThumbnailLink(token) : null,
                }),
            ),
        );
    }

    protected readonly FileLinkIcon = FileLinkIcon;
    protected readonly FileEntityDocumentStateNames = FileEntityDocumentStateNames;
    protected readonly DocumentState = DocumentState;
}
