import { createReducer, on } from '@ngrx/store';

import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ClockInEntity } from '../../entities/clock-in.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { ClockInActionTypes } from '../actions/clock-in.actions';

export const clockInsFeatureKey = 'clockIns';

export interface ClockInsState extends EntityState<ClockInEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}
const adapter = createEntityAdapter<ClockInEntity>({
    selectId: (Entity) => Entity.Id,
});

const initialConditionsState: ClockInsState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

export const clockInsStateReducer = createReducer(
    initialConditionsState,
    on(ClockInActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(ClockInActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(ClockInActionTypes.DeleteClockInsSuccess, (state, { Payload }) =>
        adapter.setMany(Payload, { ...state }),
    ),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllClockIn = selectAll;
export const selectClockInEntities = selectEntities;

export const selectClockInFetched = (state: ClockInsState) => state.fetched;
export const selectClockInLatestUpdatedAt = (state: ClockInsState) => state.latestUpdatedAt;
