import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { OBLIGO_PARTNERTYPE_ID, ZERT_PARTNERTYPE_ID } from '../../../app.component';
import { DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData } from '../../../components/templates/new-document-view/component/dave-file-upload-dialog/dave-file-upload-dialog.component';
import { EventEntity } from '../../../dave-data-module/entities/event.entity';
import { FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { TransmissionEntity, TransmissionEntityFromSocket } from '../../../dave-data-module/entities/transmission.entity';
import { DaveMutationChangeTransmissionArgs } from '../../../dave-data-module/graphql-types';
import { EventResolver } from '../../../dave-data-module/guards/event.resolver';
import { ComponentCanDeactivate } from '../../../dave-data-module/guards/pending-changes.guard';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import { FilesActionTypes } from '../../../dave-data-module/State/actions/files.actions';
import { TransmissionActionTypes } from '../../../dave-data-module/State/actions/transmission.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getEventById } from '../../../dave-data-module/State/selectors/events.selectors';
import { getFiles } from '../../../dave-data-module/State/selectors/files.selectors';
import { getPartner } from '../../../dave-data-module/State/selectors/partners.selectors';
import { getTransmission, getTransmissionById } from '../../../dave-data-module/State/selectors/transmission.selector';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { InfoDialogComponent, InfoDialogData } from '../../../dave-utils-module/dave-shared-components-module/components/dialogs/info-dialog/info-dialog.component';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { isNotNullOrUndefined, linkify } from '../../../helper/helper';
import { AllCommissionMeta, CommissionMeta, CustomerAdministrationMeta, DMSPageMeta, HistoryMeta } from '../../../helper/page-metadata';
import { CustomLabelService } from '../../../services/custom-label.service';
import { LinkDialogComponent, LinkDialogData } from '../link-dialog/link-dialog.component';
import { EventMessagePayload, Message, MessageType, RegisterMessagePayload } from '../socket';

interface Resolution {
    Name: string;
    Height: number;
    Bitrate: number;
}

interface Image {
    Preview: string;
    FileId: number;
    Loading: boolean;
    Uploaded: boolean;
    Taken: boolean;
    Id: number;
}

@Component({
    selector: 'app-detail',
    templateUrl: './detail-videodokumentation.component.html',
    styleUrls: ['./detail-videodokumentation.component.scss'],
})
export class DetailVideodokumentationComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    @ViewChild('remote') remote: ElementRef<HTMLVideoElement>;
    @ViewChild('local') local: ElementRef<HTMLVideoElement>;

    protected transmission: BehaviorSubject<TransmissionEntity> = new BehaviorSubject<TransmissionEntity>(null);

    public SpeakerSetEnabled = 'sinkId' in HTMLMediaElement.prototype;
    public Editing$ = new BehaviorSubject<boolean>(false);
    public Connected$ = new BehaviorSubject<boolean>(false);
    public ClientConnected$ = new BehaviorSubject<boolean>(false);
    public Save$ = new Subject();

    public PreviewedFileId$ = new BehaviorSubject<number | null>(null);
    public ImageIds: number[];
    public TransmissionClosed = false; // ToDo: Transmission Status einbauen
    public PreviewOpen = false;
    public ChronikPath = HistoryMeta.Path;
    public ChronikIcon = HistoryMeta.Icon;
    public DMSPath = DMSPageMeta.Path;
    public DMSIcon = DMSPageMeta.Icon;
    public CustomerPath = CustomerAdministrationMeta.Path;
    public CustomerIcon = CustomerAdministrationMeta.Icon;
    public CommissionPath = CommissionMeta.Path;
    public AllCommissionPath = AllCommissionMeta.Path;
    public CommissionIcon = CommissionMeta.Icon;
    public Images: Image[] = [];
    private subscriptions: Subscription[] = [];
    protected PartnerTypeId = 0;
    protected SocketReady$ = new BehaviorSubject(false);
    protected ShowUseHere = false;

    private files = this.store.select(getFiles);

    protected localVideoStream: MediaStreamTrack;
    protected localAudioStream: MediaStreamTrack;

    public SettingsForm = this.fb.group({
        AllowClientShoot: new UntypedFormControl(false),
        FlashLight: [{ value: false, disabled: true }],
        Speaker: new UntypedFormControl(null),
        Microphone: new UntypedFormControl(null),
        Camera: new UntypedFormControl(null),
    }) as FormGroupTyped<{
        AllowClientShoot: boolean;
        FlashLight: boolean;
        Speaker: string | null;
        Microphone: string | null;
        Camera: string | null;
    }>;
    public Speakers$ = new BehaviorSubject<MediaDeviceInfo[]>([]);
    public Microphones$ = new BehaviorSubject<MediaDeviceInfo[]>([]);
    public Cameras$ = new BehaviorSubject<MediaDeviceInfo[]>([]);

    public ConnectionStats: BehaviorSubject<{
        admin: number;
        client: number;
        total: number;
    }> = new BehaviorSubject({ admin: 0, client: 0, total: 0 });
    public ConnectionText = '';

    public TranmissionForm = new FormGroup({
        Name: new FormControl<string | null>(null),
        Customer: new FormControl<SelectSearchData | null>(null),
        Commission: new FormControl<SelectSearchData | null>(null),
    });

    protected webrtcConnection: RTCPeerConnection | null = null;
    protected webrtcConfig: RTCConfiguration = {
        iceServers: [
            { urls: 'stun:turn.dave-cc.com' },
            {
                urls: 'turn:turn.dave-cc.com',
                username: 'dave',
                credential: 'pcUVA8wED9JrkcDffnJKxmpPzRk3Yym3',
            },
        ],
    };

    protected socket: WebSocket | null = null;

    public Transmission$: Observable<TransmissionEntity> = this.activatedRoute.paramMap.pipe(
        map((paramMap) => +paramMap.get('transmissionId')),
        switchMap((transmissionId) => this.store.select(getTransmissionById({ id: transmissionId }))),
        filter(isNotNullOrUndefined),
        tap((t) => this.transmission.next(t)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    public FolderId$: Observable<number> = this.Transmission$.pipe(
        switchMap((transmission) => this.folderDataService.getFolderFromEntity(transmission.Id, FolderTypes.transmission)),
        map((folder) => folder?.Id),
        filter(isNotNullOrUndefined),
        distinctUntilChanged(),
    );
    public Customer$ = combineLatest([this.store.select(getCustomers), this.Transmission$]).pipe(map(([customers, transmission]) => customers.find((c) => c.Id === transmission?.CustomerId)));
    public TransmissionData = combineLatest([
        this.Transmission$,
        this.store.select(getCustomers),
        this.store.select(getCommissions),
        this.CS.GetSingle$(),
        merge(this.TranmissionForm.controls.Customer.valueChanges, of(this.TranmissionForm.controls.Customer.value)).pipe(distinctUntilChanged((a, b) => a?.Id === b?.Id)),
        this.cls.getSingle$('Commission'),
    ]).pipe(
        map(([transmission, customers, commissions, customerName, customerForm, commissionLabel]): IDetailListTemplateData => {
            if (isNotNullOrUndefined(this.TranmissionForm.get('Customer').value?.Id) && this.TranmissionForm.controls['Commission'].disabled) {
                this.TranmissionForm.controls['Commission'].enable();
            } else if (!isNotNullOrUndefined(this.TranmissionForm.get('Customer').value?.Id) && this.TranmissionForm.controls['Commission'].enabled) {
                this.TranmissionForm.controls['Commission'].disable();
            }
            return {
                HeaderIcon: 'video' as IconProp,
                Headline: transmission.Name ? 'Übertragung: ' + transmission.Name : 'Übertragung',
                Properties: [
                    {
                        key: 'Übertragung',
                        formControl: this.TranmissionForm.get('Name'),
                    },
                    {
                        key: customerName,
                        value: customers.find((c) => c.Id === transmission.CustomerId)?.Name,
                        formControl: this.TranmissionForm.controls.Customer,
                        options: {
                            specialInput: {
                                selectSearch: customers
                                    ?.filter((c) => !c.Deleted)
                                    .map((c) => ({
                                        optionValue: c.Id,
                                        optionLabel: c.DisplayInformation,
                                    })),
                            },
                        },
                    },
                    {
                        key: commissionLabel,
                        value: commissions?.find((c) => c.Id === transmission.CommissionId)?.GetDisplayName(),
                        formControl: this.TranmissionForm.get('Commission'),
                        options: {
                            specialInput: {
                                selectSearch: commissions
                                    ?.filter((c) => !c.Deleted)
                                    .filter((c) => (customerForm ? c.CustomerId === customerForm.Id : true))
                                    .map((c) => ({
                                        optionValue: c.Id,
                                        optionLabel: c.DisplayName,
                                    })),
                            },
                        },
                    },
                ],
            };
        }),
    );
    public StyleTagRegEx = new RegExp('<style>(.|\\n)*<\\/style>', 'gi');
    public TmpDescription = new UntypedFormControl('', { updateOn: 'blur' });
    public TransmissionId = null as number;
    public Event$ = this.Transmission$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((tr) => this.store.select(getEventById({ id: tr.EventId }))),
    );

    public Data$: Observable<{
        transmission: TransmissionEntity;
        event: EventEntity | undefined;
    }> = combineLatest([this.Transmission$, this.Event$]).pipe(
        tap(([, event]) => console.log('EVENT DESCR', event?.Description)),
        map(([transmission, event]) => ({
            transmission,
            event,
        })),
    );

    public Linkify(text: string) {
        return linkify(text);
    }

    connectSocket(): void {
        // let url = 'https://dev.stage.dave-cc.com' + '/videodocumentation/socket';
        let url = window.location.origin + '/videodocumentation/socket';
        url = url.replace('https://', 'wss://');
        url = url.replace('http://', 'ws://');
        this.socket = new WebSocket(url);
        this.socket.onmessage = (mr) => {
            const m: Message<any> = JSON.parse(mr.data);
            switch (m.Type) {
                case MessageType.RegisterResponse:
                    if (m.Payload) {
                        this.SocketReady$.next(true);
                        this.ShowUseHere = false;
                    } else {
                        const dialogRef = this.dialog.open(ForceConnectComponent, {
                            disableClose: true,
                            hasBackdrop: true,
                        });
                        dialogRef.afterClosed().subscribe((res) => {
                            if (res) {
                                this.ConnectSocket(true);
                            } else {
                                this.ShowUseHere = true;
                            }
                        });
                    }
                    break;
                case MessageType.Event:
                    const em: EventMessagePayload = m.Payload;
                    switch (em.Name) {
                        case 'stop':
                            this.SocketReady$.next(false);
                            this.ShowUseHere = true;
                            this.cd.markForCheck();
                            break;
                        case 'connection.ping':
                            this.send('connection.pong', null);
                            break;
                        case 'flashlight':
                            if (em.Payload) {
                                this.SettingsForm.controls.FlashLight.enable();
                            } else {
                                this.SettingsForm.controls.FlashLight.disable();
                            }
                            break;
                        case 'settings.change':
                            this.SettingsForm.controls.AllowClientShoot.setValue(em.Payload.AllowClientShoot);
                            this.SettingsForm.controls.FlashLight.setValue(em.Payload.FlashLight);
                            break;
                        case 'connection.stats':
                            this.ConnectionStats.next(em.Payload);
                            break;
                        case 'transmission.update':
                            const transmission: TransmissionEntity = TransmissionEntityFromSocket(em.Payload);
                            console.log(transmission);
                            if (transmission.State >= 2) {
                                this.store.dispatch(TransmissionActionTypes.GetTransmissionRequest());
                                this.store.dispatch(
                                    FilesActionTypes.LoadFilesAfter({
                                        Payload: 180,
                                    }),
                                );
                            }
                            this.store
                                .select(getTransmission)
                                .pipe(take(1))
                                .subscribe((transmissions) => {
                                    this.store.dispatch(
                                        TransmissionActionTypes.UpdateTransmission({
                                            Payload: transmissions.map((t) => (t.Id === transmission.Id ? transmission : t)),
                                        }),
                                    );
                                });
                            break;
                        case 'client.connect':
                            this.ClientConnected$.next(true);
                            this.cd.markForCheck();
                            break;
                        case 'client.disconnect':
                            this.ClientConnected$.next(false);
                            this.cd.markForCheck();
                            break;
                        case 'photo.update':
                            let found = false;
                            this.Images.forEach((img, i) => {
                                if (img.Id === em.Payload.Id) {
                                    found = true;
                                    this.Images[i] = em.Payload;
                                }
                            });
                            if (!found) {
                                this.Images.push(em.Payload);
                            }
                            console.log(this.Images, em.Payload);
                            this.Images = this.Images.sort((a, b) => (a.Id > b.Id ? -1 : 2));
                            this.cd.markForCheck();
                            break;
                        case 'webRtc.answer':
                            if (this.webrtcConnection != null) {
                                this.webrtcConnection.setRemoteDescription(
                                    new RTCSessionDescription({
                                        type: 'answer',
                                        sdp: em.Payload,
                                    }),
                                );
                            }
                            break;
                        case 'webRtc.iceCandidate':
                            if (this.webrtcConnection != null) {
                                this.webrtcConnection.addIceCandidate(new RTCIceCandidate(em.Payload));
                            }
                            break;
                        case 'webRtc.init':
                            this.connectWebRtc();
                            break;
                    }
                    break;
            }
        };
        this.socket.onopen = () => {
            this.SocketReady$.next(true);
            this.ConnectSocket(false);
        };
        this.socket.onclose = () => {
            this.socket?.close();
            setTimeout(() => {
                this.connectSocket();
            }, 1000);
        };
    }

    constructor(
        private route: ActivatedRoute,
        private store: Store<State>,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private cd: ChangeDetectorRef,
        private appDialog: AppDialogService,
        private router: Router,
        public CS: CustomerNameService,
        public PS: PermissionService,
        private gatewayHttpService: HttpService,
        eventResolver: EventResolver,
        protected cls: CustomLabelService,
        private folderDataService: FolderDataService,
    ) {
        eventResolver.resolve();
        this.subscriptions.push(
            this.PreviewedFileId$.subscribe((s) => console.log(s)),
            this.store
                .select(getPartner)
                .pipe(filter(isNotNullOrUndefined))
                .subscribe((p) => (this.PartnerTypeId = p.PartnerTypeId)),
            this.ConnectionStats.subscribe((s) => {
                if (s.total < 0) {
                    this.ConnectionText = 'keine Verbindung';
                } else {
                    this.ConnectionText = s.total + 'ms';
                }
                this.cd.markForCheck();
            }),
            this.Editing$.pipe(
                withLatestFrom(this.Transmission$, this.Event$),
                tap(([editing, transmission, event]) => {
                    if (editing) {
                        this.TranmissionForm.setValue({
                            Name: transmission.Name,
                            Commission: { Name: '', Id: transmission.CommissionId },
                            Customer: { Name: '', Id: transmission.CustomerId },
                        });
                    }
                    this.TmpDescription.setValue(event?.Description);
                }),
            ).subscribe(),
            this.Save$.pipe(
                withLatestFrom(this.Transmission$, this.Event$),
                tap(([, transmission, event]) => {
                    if (this.TranmissionForm.valid) {
                        const p: DaveMutationChangeTransmissionArgs = {
                            id: transmission.Id,
                            name: this.TranmissionForm.controls.Name.value,
                        };

                        if (this.TranmissionForm.controls.Customer.value.Id !== null) {
                            p.customerId = this.TranmissionForm.controls.Customer.value.Id;
                        }

                        if (this.TranmissionForm.controls.Commission.value.Id !== null) {
                            p.commissionId = this.TranmissionForm.controls.Commission.value.Id;
                        }

                        if (event) {
                            this.store.dispatch(
                                EventsActionTypes.ModifyEvent({
                                    Payload: {
                                        id: event.Id,
                                        description: this.TmpDescription.value,
                                    },
                                }),
                            );
                        }

                        this.store.dispatch(
                            TransmissionActionTypes.ChangeTransmission({
                                Payload: p,
                            }),
                        );

                        this.Editing$.next(false);
                    }
                }),
            ).subscribe(),
        );
    }

    ngOnInit(): void {
        if (this.SpeakerSetEnabled) {
            this.subscriptions.push(
                this.SettingsForm.controls.Speaker.valueChanges.subscribe((s) => {
                    // ignore weil nur manche browser das können und die typings das net kennen
                    // @ts-ignore
                    this.remote.nativeElement.setSinkId(s);
                }),
            );
        }

        this.subscriptions.push(
            this.SocketReady$.subscribe((v) => {
                if (v) {
                    this.SettingsForm.controls.AllowClientShoot.enable();
                } else {
                    this.SettingsForm.controls.AllowClientShoot.disable();
                }
            }),
            combineLatest([this.SettingsForm.controls.Camera.valueChanges, this.Transmission$]).subscribe(([c, t]) => {
                if (t.State === 1) {
                    const constraints = {
                        video: {
                            deviceId: c,
                            width: 360,
                            framerate: 30,
                        },
                    };
                    navigator.mediaDevices.getUserMedia(constraints).then(
                        (s) => {
                            const videoTrack = s.getVideoTracks()[0];
                            const sender = this.webrtcConnection.getSenders().find((s) => s.track.kind == videoTrack.kind);
                            const oldTrack = sender.track;
                            sender.replaceTrack(videoTrack).then(() => {
                                this.localVideoStream = videoTrack;
                                this.local.nativeElement.srcObject = s;
                                oldTrack.stop();
                                this.local.nativeElement.play();
                                this.local.nativeElement.muted = true;
                            });
                        },
                        (e) => console.error(e),
                    );
                }
            }),

            combineLatest([this.SettingsForm.controls.Microphone.valueChanges, this.Transmission$]).subscribe(([m, t]) => {
                if (t.State === 1) {
                    const constraints = {
                        audio: {
                            deviceId: m,
                        },
                    };
                    navigator.mediaDevices.getUserMedia(constraints).then(
                        (s) => {
                            const audioTrack = s.getAudioTracks()[0];
                            const sender = this.webrtcConnection.getSenders().find((s) => s.track.kind == audioTrack.kind);
                            const oldTrack = sender.track;
                            sender.replaceTrack(audioTrack).then(() => {
                                this.localAudioStream = audioTrack;
                                oldTrack.stop();
                            });
                        },
                        (e) => console.error(e),
                    );
                }
            }),
            combineLatest([this.SettingsForm.controls.AllowClientShoot.valueChanges, this.SettingsForm.controls.FlashLight.valueChanges]).subscribe(([allowClientShoot, flashLight]) => {
                this.send('settings.change', {
                    AllowClientShoot: allowClientShoot,
                    FlashLight: flashLight,
                });
            }),
            this.transmission.pipe(filter((t) => t !== null && t !== undefined)).subscribe((t) => {
                if (t.State !== 0 && t.State !== 1) {
                    this.TransmissionClosed = true;
                }
            }),
            this.transmission
                .pipe(
                    filter((t) => t !== null && t !== undefined),
                    distinctUntilChanged((p, c) => p.Id === c.Id),
                )
                .subscribe((t) => {
                    if (t) {
                        if (this.socket != null) {
                            this.socket.close();
                            this.connectSocket();
                        } else {
                            this.connectSocket();
                        }
                    }
                }),
        );

        this.getDevices();

        this.route.params.subscribe((params) => {
            this.store.select(getTransmission).subscribe((transmissions) => this.transmission.next(transmissions.find((t) => t.Id === +params['transmissionId'])));
        });
    }

    public getDevices(): void {
        navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
                const speakers = devices.filter((d) => d.kind === 'audiooutput');
                const microphones = devices.filter((d) => d.kind === 'audioinput');
                const cameras = devices.filter((d) => d.kind === 'videoinput');

                this.Speakers$.next(speakers.filter((s) => s.deviceId.length > 16));
                this.Microphones$.next(microphones);
                this.Cameras$.next(cameras);

                let foundComSpeaker = false;
                let foundDefaultSpeaker = false;
                let speakerSetSame = '';

                speakers.forEach((s) => {
                    if (s.deviceId == 'communications') {
                        foundComSpeaker = true;
                        speakerSetSame = s.label;
                    }
                });
                if (!foundComSpeaker) {
                    speakers.forEach((s) => {
                        if (s.deviceId == 'default') {
                            foundDefaultSpeaker = true;
                            speakerSetSame = s.label;
                        }
                    });
                }
                if (speakers.length != 0) {
                    let found = false;
                    // let search = /^[A-z]+ - (.*)$/.exec(speakerSetSame)[1];
                    const regExp = /^[A-z]+ - (.*)$/.exec(speakerSetSame);
                    let search = null;
                    if (regExp?.length) {
                        search = regExp[1];
                    }

                    speakers.forEach((s) => {
                        if (s.label.includes(search)) {
                            found = true;
                            this.SettingsForm.controls.Speaker.setValue(s.deviceId);
                        }
                    });

                    if (!found) {
                        this.SettingsForm.controls.Speaker.setValue(speakers[0].deviceId);
                    }
                }

                let foundComMicrophone = false;
                let foundDefaultMicrophone = false;
                microphones.forEach((m) => {
                    if (m.deviceId == 'communications') {
                        foundComMicrophone = true;
                        this.SettingsForm.controls.Microphone.setValue(m.deviceId);
                    }
                });
                if (!foundComMicrophone) {
                    microphones.forEach((m) => {
                        if (m.deviceId == 'default') {
                            foundDefaultMicrophone = true;
                            this.SettingsForm.controls.Microphone.setValue(m.deviceId);
                        }
                    });
                }
                if (!foundComMicrophone && !foundDefaultMicrophone && microphones.length != 0) {
                    this.SettingsForm.controls.Microphone.setValue(microphones[0].deviceId);
                }

                this.SettingsForm.controls.Camera.setValue(cameras[0].deviceId);
            })
            .catch((e) => {
                console.error(e);
                alert('Leider ist ein Fehler');
            });
    }

    connectWebRtc(): void {
        this.webrtcConnection = new RTCPeerConnection(this.webrtcConfig);
        this.webrtcConnection.onicecandidate = ({ candidate }) => {
            if (candidate) {
                this.send('webRtc.iceCandidate', candidate);
            }
        };

        this.webrtcConnection.onnegotiationneeded = async () => {
            try {
                await this.webrtcConnection.setLocalDescription(await this.webrtcConnection.createOffer());
                // console.log(pc.localDescription)
                this.send('webRtc.offer', this.webrtcConnection.localDescription.sdp);
            } catch (err) {
                console.error(err);
            }
        };

        this.webrtcConnection.ontrack = (event) => {
            this.Connected$.next(true);
            this.remote.nativeElement.srcObject = event.streams[0];
            this.remote.nativeElement.play().catch(() => {
                setTimeout(() => {
                    this.remote.nativeElement.play();
                }, 200);
            });
        };

        navigator.mediaDevices
            .getUserMedia({
                audio: {
                    deviceId: this.SettingsForm.controls.Microphone.value,
                },
                video: {
                    deviceId: this.SettingsForm.controls.Camera.value,
                    width: 360,
                    frameRate: 30,
                },
            })
            .then((stream) => {
                this.getDevices();
                this.localVideoStream = stream.getVideoTracks()[0];
                this.localAudioStream = stream.getAudioTracks()[0];
                stream.getTracks().forEach((track) => {
                    this.webrtcConnection.addTrack(track, stream);
                });
                this.local.nativeElement.srcObject = stream;
                this.local.nativeElement.play();
                this.local.nativeElement.muted = true;
            })
            .catch((err) => {
                if (err.toString().includes('Could not start video source')) {
                    this.dialog.open<InfoDialogComponent, InfoDialogData>(InfoDialogComponent, {
                        data: {
                            paragraph: 'Es scheint ein Problem mit der gewählten Kamera zu geben. Bitte wählen Sie eine andere Kamera aus. Prüfen Sie gegebenenfalls, ob ein anderes Programm die Kamera derzeit verwendet.',
                        },
                        maxWidth: '50rem',
                    });
                } else {
                    console.error(err);
                }
            });
    }

    Connect(): void {
        this.send('transmission.start', {});
    }

    protected send(name: string, payload: any) {
        this.socket.send(
            JSON.stringify({
                Payload: {
                    Name: name,
                    Payload: payload,
                },
                Type: MessageType.Event,
            } as Message<EventMessagePayload>),
        );
    }

    public ConnectSocket(force: boolean) {
        combineLatest([this.transmission.pipe(filter((t) => t !== null && t !== undefined)), this.store.select(getToken)])
            .pipe(take(1))
            .subscribe(([t, token]) => {
                console.warn('asd');
                this.socket.send(
                    JSON.stringify({
                        Payload: {
                            Id: t.Id,
                            Token: token,
                            Force: force,
                        },
                        Type: MessageType.Register,
                    } as Message<RegisterMessagePayload>),
                );
            });
    }

    Photo(): void {
        this.send('photo.take', null);
    }

    Stop(): void {
        this.localVideoStream.stop();
        this.localAudioStream.stop();
        this.webrtcConnection.close();
        this.send('stop', null);
    }

    Link() {
        this.gatewayHttpService
            .graphQl({ query: '{\n' + '  transmissionSlug(id: ' + this.transmission.getValue().Id + ') {\n' + '    slug\n' + '  }\n' + '}' })
            .toPromise()
            .then((res) => {
                let link = environment.videoUrl + '/video/' + res.transmissionSlug.slug;

                if (this.PartnerTypeId === OBLIGO_PARTNERTYPE_ID) {
                    link = link + '/obligo';
                } else if (this.PartnerTypeId === ZERT_PARTNERTYPE_ID) {
                    link = link + '/zert';
                }

                this.dialog.open<LinkDialogComponent, LinkDialogData, void>(LinkDialogComponent, {
                    autoFocus: false,
                    data: {
                        heading: 'Link zur Videodokumentation',
                        paragraph: 'Geben Sie diesen Link an den Teilnehmer der Videodokumentation weiter.\n\n\n' + link,
                        link,
                    },
                    maxWidth: '90%',
                });
            });
    }

    Delete(): void {
        const sub = this.Transmission$.pipe(take(1), delay(100)).subscribe((t) => {
            sub.unsubscribe();
            this.appDialog
                .OpenConfirmationDialog({
                    paragraph: `Übertragung ${t.Name} wirklich löschen?`,
                    styleDelete: true,
                })
                .subscribe(([result]) => {
                    if (result) {
                        this.store.dispatch(
                            TransmissionActionTypes.DeleteTransmission({
                                Payload: {
                                    id: t.Id,
                                },
                            }),
                        );
                        this.router.navigate(['/videodokumentation/alle']);
                    }
                });
        });
    }

    GetSpeaker(id: string): MediaDeviceInfo {
        return this.Speakers$.getValue().find((d) => d.deviceId == id);
    }

    GetMicrophone(id: string): MediaDeviceInfo {
        return this.Microphones$.getValue().find((d) => d.deviceId == id);
    }

    GetCamera(id: string): MediaDeviceInfo {
        return this.Cameras$.getValue().find((d) => d.deviceId == id);
    }

    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): Observable<boolean> | boolean {
        return this.Editing$.pipe(map((editing) => !editing || !this.TranmissionForm.dirty));
    }

    ngOnDestroy(): void {
        this.socket.close();
    }

    public OpenUploadDialog(data: DaveFileUploadDialogComponentDialogData) {
        this.dialog.open<DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData>(DaveFileUploadDialogComponent, {
            ...DaveFileUploadDialogComponent.DefaultConfig,
            data,
        });
    }
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'force-connect-component-dialog',
    styles: [
        `
            mat-dialog-actions {
                justify-content: space-between;
            }
        `,
    ],
    template: `
        <h2 mat-dialog-title>Hinweis</h2>
        <mat-dialog-content> Es besteht bereits eine Verbindung in einem anderen Fenster. Möchten Sie mit der Übertragung in diesem Fenster fortfahren? </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-stroked-button color="primary" [mat-dialog-close]="false">Abbrechen</button>
            <button mat-raised-button color="primary" [mat-dialog-close]="true">Hier fortfahren</button>
        </mat-dialog-actions>
    `,
})
export class ForceConnectComponent {}
