import { createAction, props } from '@ngrx/store';
import { CustomerEntity } from '../../entities/customer.entity';
import {
    DaveMutationChangeCustomerArgs,
    DaveMutationCreateCustomerArgs,
    DaveMutationCreateCustomerWithOfficePersonArgs,
    QueryCustomerArgs,
    QueryUser2CustomerArgs,
} from '../../graphql-types';
import { UserToCustomerEntity } from '../../entities/user-to-customer.entity';

export const CustomerActionTypes = {
    Load: createAction('data/Customers/get', props<{ Payload?: QueryCustomerArgs }>()),
    UpdateAll: createAction('data/Customers/updateAll', props<{ Payload: Array<CustomerEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/Customers/updateMany', props<{ Payload: Array<CustomerEntity>; updateLatestUpdatedAt?: boolean }>()),
    RemoveOne: createAction('data/Customers/remove', props<{ Payload: number }>()),
    ModifyCustomer: createAction('data/Customers/modify', props<{ Payload: DaveMutationChangeCustomerArgs }>()),
    /**
     * deprecated  use AddCustomerAndOffice
     */
    AddCustomer: createAction('data/Customers/add', props<{ Payload: DaveMutationCreateCustomerArgs }>()),
    DeleteCustomer: createAction('data/Customers/delete', props<{ Payload: number }>()),
    SetCustomerImage: createAction('data/Customers/set/image', props<{ Payload: { Id: number; Image: string } }>()),
    AddCustomerAndOffice: createAction('data/CustomerId&Office/add', props<{ Payload: DaveMutationCreateCustomerWithOfficePersonArgs }>()),
};
export const UserToCustomerActionTypes = {
    Load: createAction('data/userToCustomer/get', props<{ Payload?: QueryUser2CustomerArgs }>()),
    UpdateAll: createAction('data/userToCustomer/updateAll', props<{ Payload: Array<UserToCustomerEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/userToCustomer/updateMany', props<{ Payload: Array<UserToCustomerEntity>; updateLatestUpdatedAt?: boolean }>()),
    Create: createAction('data/userToCustomer/add', props<{ Payload: { customerId: number; userId: number; roleId: number } }>()), // todo
    Delete: createAction('data/userToCustomer/delete', props<{ Payload: { id: number } }>()), // todo
    RemoveOne: createAction('data/userToCustomer/removeOne', props<{ Payload: number }>()), // todo
};
