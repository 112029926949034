import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';
import { PublicVersionEntity, PublicVersionEntityFromBackend, PublicVersionType } from './public-version.entity';

export enum PublicFileTypeEnum {
    ResourceEntityProfileImage = 'ResourceImg',
    PartnerOfficeProfileImage = 'PartnerOfficeImg',
}
export class PublicFileEntity {
    constructor(
        public Id: number = null,
        public PartnerId: number = null,
        public UserId: number = null,
        public Name: string = null,
        public Type: PublicFileTypeEnum = null,
        public EntityId: number = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
        public Versions: PublicVersionEntity[] = null,
        public AdditionalData: any = null,
    ) {}
    public get LastVersion() {
        if (!this.Versions || !this.Versions[0]) {
            console.error('PublicFile Corrupt, has no versions', this);
            return;
        }
        return this.Versions.reduce((previousValue, currentValue) => currentValue.CreatedAt > previousValue.CreatedAt ? currentValue : previousValue, this.Versions[0])
    }
    public Clone(override: Partial<PublicFileEntity> = {}): PublicFileEntity {
        const attr = { ...this, ...override };
        return Object.assign(new PublicFileEntity(), attr);
    }
}

export function PublicFileEntityFromBackend(res: PublicFileType): PublicFileEntity {
    return new PublicFileEntity(
        res.id,
        res.partner_id,
        res.user_id,
        res.name,
        res.type,
        res.entity_id,
        res.created_at && BackendDateTimestamp(res.created_at),
        res.updated_at && BackendDateTimestamp(res.updated_at),
        res.deleted_at && BackendDateTimestamp(res.deleted_at),
        res.versions && res.versions.map(PublicVersionEntityFromBackend),
        res.additional_data,
    );
}

export type PublicFileType = {
    id: number;
    partner_id: number;
    user_id: number;
    name: string;
    type: PublicFileTypeEnum;
    entity_id: number;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    versions: PublicVersionType[];
    additional_data: any | null;
};
