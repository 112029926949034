import { BackendDateTimestamp } from '../../helper/backend-frontend-conversion.helper';
import { Akkord2CommissionType } from '../../graphql-types';
import { AkkordEntity} from './akkord.entity';


export class AkkordToCommissionEntity {
    /**
     * @param QuantityType - Is Unused (use QuantityType from AkkordEntity)
     * @param Name - Is Unused (use Name from AkkordEntity)
     * @see AkkordEntity
     */
    constructor(
        public AkkordId: number = null,
        public CommissionId: number = null,
        public CreatedAt: Date = null,
        public Date: Date = null,
        public DeletedAt: Date = null,
        public Id: number = null,
        public Quantity: number = null,
        /**
         * @deprecated use Name from AkkordEntity
         * @see AkkordEntity
         */
        public Name: string = null,
        public PartnerId: number = null,
        /**
         * @deprecated use QuantityType from AkkordEntity
         * @see AkkordEntity
         */
        public QuantityType: string = null,
        public UpdatedAt: Date = null,
        public UserId: number = null,
        /**
         * @deprecated unused
         */
        public Multiplier: number = null,
) {}
    public static GqlFields = `
akkordId
commissionId
createdAt
date
deletedAt
id
multiplicator
name
partnerId
quantityType
updatedAt
userId
value`;
    public Clone(override: AkkordToCommissionEntityAttributes = {}): AkkordToCommissionEntity {
        const attr = {...this, ...override};
        return Object.assign(new AkkordToCommissionEntity(), attr);
    }
}

export function AkkordToCommissionEntityFromBackend(res: Akkord2CommissionType): AkkordToCommissionEntity {
    return new AkkordToCommissionEntity(
        res.akkordId,
        res.commissionId,
        res.createdAt ? BackendDateTimestamp(res.createdAt) : null,
        res.date ? BackendDateTimestamp(res.date) : null,
        res.deletedAt ? BackendDateTimestamp(res.deletedAt) : null,
        res.id,
        res.value,
        res.name,
        res.partnerId,
        res.quantityType,
        res.updatedAt ? BackendDateTimestamp(res.updatedAt) : null,
        res.userId,
        res.multiplicator,
    );
}

export interface AkkordToCommissionEntityAttributes {
    AkkordId?: number;
    CommissionId?: number;
    CreatedAt?: Date;
    Date?: Date;
    DeletedAt?: Date;
    Id?: number;
    Quantity?: number;
    Name?: string;
    PartnerId?: number;
    QuantityType?: string;
    UpdatedAt?: Date;
    UserId?: number;
    Multiplier?: number;
}

