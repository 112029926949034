import { createReducer, on } from '@ngrx/store';
import { EmployeeToVacationEntity } from '../../entities/employee-to-vacation.entity';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { EmployeeToVacationActionTypes } from '../actions/employee-to-vacation.actions';

export const employeeToVacationFeatureKey = 'employeeToVacation';

export interface EmployeeToVacationState extends EntityState<EmployeeToVacationEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

const adapter = createEntityAdapter<EmployeeToVacationEntity>({
    selectId: Entity => Entity.Id,
});

const initialState: EmployeeToVacationState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const employeeToVacationStateReducer = createReducer(
    initialState,
    on(EmployeeToVacationActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, { ...state, fetched: true, latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt }),
    ),
    on(EmployeeToVacationActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        Payload.reduce((s, entity) => adapter.setOne(entity, { ...s }), {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllEmployeeToVacation = selectAll;
export const selectEmployeeToVacationEntities = selectEntities;

export const selectEmployeeToVacationFetched = (state: EmployeeToVacationState) => state.fetched;
export const selectEmployeeToVacationLatestUpdatedAt = (state: EmployeeToVacationState) => state.latestUpdatedAt;
