import {Action} from '@ngrx/store';

export enum EmailSettingsActionTypes {
    LoadEmailConnections = '[EmailSettings] Load EmailConnections'
}

export class EmailSettingsLoadEmailConnections implements Action {
    // tslint:disable-next-line: naming-convention
    readonly type = EmailSettingsActionTypes.LoadEmailConnections;
}

export type EmailSettingsActions = EmailSettingsLoadEmailConnections;
