import { Injectable } from '@angular/core';
import { GetTimestampFromTime } from '@dave/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';
import { isNotNullOrUndefined } from '../../../../helper/helper';
import { ResourceEntityFromBackend } from '../../../entities/resource-dispo/resource.entity';
import { AppGatewayService } from '../../../services/app-gateway.service';
import { DaveActions } from '../../actions/actions';
import { BaseActionTypes } from '../../actions/base.actions';
import { ResourceActionTypes } from '../../actions/resource-dispo/resource.actions';
import { State } from '../../index';
import { getResourceDictionary } from '../../selectors/resource-dispo/resource.selectors';

enum ErrorCodes {
    Add = 'Resource Hinzufügen fehlgeschlagen',
    Load = 'Resource Abrufen fehlgeschlagen',
    Modify = 'Resource Bearbeiten fehlgeschlagen',
    Remove = 'Resource Löschen fehlgeschlagen',
}

@Injectable()
export class ResourceEffects {
    ModifyResource$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResourceActionTypes.Change),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ErpResourceEdit: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return ResourceActionTypes.UpdateOne({ Payload: ResourceEntityFromBackend(res.ErpResourceEdit.resources[0]) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );

    DeleteResource$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResourceActionTypes.Delete),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ErpResourceDelete: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return ResourceActionTypes.RemoveOne({ Payload: +Payload.id });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );

    AddResource$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResourceActionTypes.Create),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ErpResourceAdd: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return ResourceActionTypes.UpdateOne({ Payload: ResourceEntityFromBackend(res.ErpResourceAdd.resources[0]) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );

    LoadResources = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ResourceActionTypes.Load),
                switchMap(({ Payload }) => {
                    const requestPayload = isNotNullOrUndefined(Payload?.updatedSince) ? { UpdatedAt: Payload.updatedSince, WithDeleted: true } : { WithDeleted: true };

                    return this.gatewayService
                        .Request({ ErpResourceGet: requestPayload })
                        .then((res) => {
                            if (Object.keys(res?.Errors || {}).length === 0) {
                                if (!isNotNullOrUndefined(Payload.updatedSince)) {
                                    return this.store$.dispatch(
                                        ResourceActionTypes.UpdateAll({
                                            Payload: res.ErpResourceGet.resources.map((val) => ResourceEntityFromBackend(val)),
                                            updateLatestUpdatedAt: true,
                                        }),
                                    );
                                } else if (res.ErpResourceGet.resources.length) {
                                    return firstValueFrom(this.store$.select(getResourceDictionary)).then((resources) => {
                                        const filteredResources = res.ErpResourceGet.resources.filter((resource) => {
                                            const fromState = resources[resource.Id];
                                            return !fromState || GetTimestampFromTime(fromState.UpdatedAt).toString() !== resource.UpdatedAt;
                                        });

                                        if (filteredResources.length) {
                                            return this.store$.dispatch(
                                                ResourceActionTypes.UpdateMany({
                                                    Payload: filteredResources.map((val) => ResourceEntityFromBackend(val)),
                                                    updateLatestUpdatedAt: true,
                                                }),
                                            );
                                        }
                                    });
                                }
                            } else {
                                throw res.Errors;
                            }
                        })
                        .catch((err) => {
                            this.store$.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Load,
                                        Err: err,
                                    },
                                }),
                            );
                            // return EMPTY;
                        });
                }),
            );
        },
        { dispatch: false },
    );

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayService: AppGatewayService) {}
}
