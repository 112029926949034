import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule,DatePipe,registerLocaleData } from '@angular/common';
import { HttpClientJsonpModule,HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { CUSTOM_ELEMENTS_SCHEMA,DEFAULT_CURRENCY_CODE,ErrorHandler,LOCALE_ID,NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule,MAT_DATE_FORMATS,MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule,MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule,MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from "@angular/router";
import { FaIconLibrary,FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AngularImageViewerModule } from '@hreimer/angular-image-viewer';
import { Store } from '@ngrx/store';
import * as Sentry from "@sentry/angular-ivy";
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { DropzoneModule,DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { MarkdownModule,MarkedOptions } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';
import { SimplebarAngularModule } from 'simplebar-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BurgerButtonWrapperComponent } from './burger-button-wrapper/burger-button-wrapper.component';
import { HomeDashboardComponent } from './components/dashboard/home-dashboard.component';
import { ServiceButtonsComponent } from './components/dashboard/services/service-buttons/service-buttons.component';
import { ServicesComponent } from './components/dashboard/services/services.component';
import { TimeTrackerKagetecComponent } from './components/dashboard/services/time-tracker-kagetec/time-tracker-kagetec.component';
import { WeatherComponent } from './components/dashboard/weather/weather.component';
import { EmailDialogComponent } from './components/detail-views/detail-event/email-dialog/email-dialog.component';
import { BreadcrumbsComponent } from './components/header/breadcrumbs/breadcrumbs.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { SearchReturnComponent } from './components/search/search-return/search-return.component';
import { SearchComponent } from './components/search/search.component';
import { SideNavBarComponent } from './components/side-nav-bar/side-nav-bar.component';
import { TagChipAutocompleteModule } from './components/templates/tag-chip-autocomplete/tag-chip-autocomplete.module';
import { TextareaModule } from './components/templates/textarea/textarea.module';
import { VerifyOrderComponent } from './components/verify-order/verify-order.component';
import { DaveCalendarModule } from './dave-calendar/dave-calendar.module';
import { DaveCommissionModule } from './dave-commission-module/dave-commission.module';
import { DaveDataStateModule } from './dave-data-module/dave-data-state.module';
import { State } from './dave-data-module/State';
import { DaveDmsModule } from './dave-dms-module/dave-dms.module';
import { DaveDocumentEditorModule } from './dave-document-editor-module/dave-document-editor.module';
import { DaveDoubleIconModule } from './dave-double-icon/dave-double-icon.module';
import { EmailEditorComponent } from './dave-email-module/components/email-editor/email-editor.component';
import { DaveEmailModule } from './dave-email-module/dave-email.module';
import { DaveFileExplorerModule } from './dave-file-explorer/dave-file-explorer.module';
import { DaveFilePreviewDialogModule } from './dave-file-preview-dialog/dave-file-preview-dialog.module';
import { DaveHistoryModule } from './dave-history-module/dave-history.module';
import { DaveImageSliderComponent } from './dave-image-slider/dave-image-slider.component';
import { DaveListCardModule } from './dave-list-card/dave-list-card.module';
import { DaveLoadingModule } from './dave-loading/dave-loading.module';
import { DaveNewsModule } from './dave-news/dave-news.module';
import { NewReportDialogModule } from './dave-reports/components/new-report-dialog/new-report-dialog.module';
import { DaveSelectUserModule } from './dave-select-user/dave-select-user.module';
import { DaveTimeTrackerModule } from './dave-time-tracker/dave-time-tracker.module';
import { AppButtonModule } from './dave-utils-module/app-button-module/app-button.module';
import { AppDialogModule } from './dave-utils-module/app-dialog-module/app-dialog.module';
import { DaveSharedComponentsModule } from './dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { PipesModule } from './dave-utils-module/dave-shared-components-module/pipes/pipes.module';
import { IconPipeModule } from './dave-utils-module/icon-pipe/icon-pipe.module';
import { SelectSearchModule } from './dave-utils-module/select-search/select-search.module';
import { AutofocusModule } from './directives/autofocus/autofocus.module';
import { appMatDialogDefaultConfig,DEFAULT_DROPZONE_CONFIG,myCustomTooltipDefaults,MY_DATE_FORMAT } from './helper/helper';
import { ResourceDispoModule } from './resource-dispo/resource-dispo.module';
import { SessionService } from './services/session.service';

@NgModule({
    declarations: [
        AppComponent,
        HomeDashboardComponent,
        HeaderComponent,
        LoginComponent,
        LogoutComponent,
        ServicesComponent,
        WeatherComponent,
        SearchComponent,
        SearchReturnComponent,
        BreadcrumbsComponent,
        VerifyOrderComponent,
        EmailDialogComponent,
        DaveImageSliderComponent,
        TimeTrackerKagetecComponent,
        ServiceButtonsComponent,
    ],
    imports: [
        CommonModule,
        ResourceDispoModule, // todo wech machen
        NgClickOutsideDirective,
        BrowserModule,
        AngularImageViewerModule, // AngularImageViewerModule muss hier importiert werden, da es BrowserModule importiert
        MatGridListModule,
        MatChipsModule,
        AppRoutingModule,
        FontAwesomeModule,
        MatInputModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatIconModule,
        MatMenuModule,
        MatNativeDateModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTooltipModule,
        HttpClientModule,
        MatCheckboxModule,
        MatMomentDateModule,
        BrowserAnimationsModule,
        MatDividerModule,
        MatCardModule,
        MatButtonModule,
        DaveDocumentEditorModule,
        MatChipsModule,
        LayoutModule,
        MatTabsModule,
        MatTableModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientJsonpModule,
        DropzoneModule,
        ToastrModule.forRoot({ extendedTimeOut: 1000, timeOut: 5000 }),
        MatProgressSpinnerModule,
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useValue: {
                    gfm: true,
                    tables: true,
                    breaks: true,
                    pedantic: false,
                    sanitize: false,
                    smartLists: true,
                    smartypants: false,
                },
            },
        }),
        MatListModule,
        PipesModule,
        MatAutocompleteModule,
        SimplebarAngularModule,
        MatTableModule,
        DaveDataStateModule,
        DaveSharedComponentsModule,
        MatDialogModule,
        RouterModule,
        IconPipeModule,
        AppButtonModule,
        DaveHistoryModule,
        SelectSearchModule,
        TagChipAutocompleteModule,
        DaveDmsModule,
        DaveCommissionModule,
        AppDialogModule,
        NewReportDialogModule,
        DaveFileExplorerModule,
        TextareaModule,
        DaveNewsModule,
        DaveEmailModule,
        EmailEditorComponent,
        // CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
        DaveTimeTrackerModule,
        MatBadgeModule,
        DaveFilePreviewDialogModule,
        DaveCalendarModule,
        DaveLoadingModule,
        DaveSelectUserModule,
        SideNavBarComponent,
        BurgerButtonWrapperComponent,
        DaveListCardModule,
        AutofocusModule,
        DaveDoubleIconModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        DatePipe,
        // {
        //     provide: HttpService,
        //     // useFactory: (httpClient: HttpClient) =>
        //     //     httpServiceFactory(httpClient, HTTP_SERVICE_CONFIG),
        //     deps: [HttpClient],
        // },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        {
            provide: LOCALE_ID,
            deps: [SessionService],
            useFactory: (sessionService: SessionService) => sessionService.getLocale(),
        },
        {
            provide: DROPZONE_CONFIG,
            useValue: DEFAULT_DROPZONE_CONFIG,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: appMatDialogDefaultConfig },
        // CustomerNameService
    ],
    bootstrap: [AppComponent],
    // entryComponents: [EmailDialogComponent],

    exports: [EmailDialogComponent, HeaderComponent],
})
export class AppModule {
    constructor(protected store: Store<State>, library: FaIconLibrary) {
        library.addIconPacks(fas, far, fab);
        registerLocaleData(localeDe);
    }
}
