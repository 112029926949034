import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import { ClockInEntityGqlFields } from '../dave-data-module/entities/clock-in.entity';
import {
    DaveMutation,
    DaveMutationChangeClockInArgs,
    DaveMutationCreateClockInArgs, DaveMutationDeleteClockInArgs,
    Query
} from "../dave-data-module/graphql-types";
import { HttpService } from '../dave-data-module/services/http.service';
import { stringifyIfNotNullOrUndefined, stringifyIfNotUndefined } from "../helper/helper";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class ClockInDataService {
    constructor(private gatewayHttpService: HttpService) {}
    createClockIn(payload: DaveMutationCreateClockInArgs):  Observable<Query & DaveMutation>;
    createClockIn(payload: DaveMutationCreateClockInArgs, withProgress: true):  Observable<HttpEvent<Object>>;
    createClockIn(payload: DaveMutationCreateClockInArgs, withProgress: boolean = false){
        const queryString = `
        mutation {
            createClockIn(
                ${stringifyIfNotNullOrUndefined(payload, 'timeStamp')}
                ${stringifyIfNotNullOrUndefined(payload, 'commissionId')}
                ${stringifyIfNotNullOrUndefined(payload, 'userId')}
                ${stringifyIfNotNullOrUndefined(payload, 'employeeId')}
                ${stringifyIfNotNullOrUndefined(payload, 'clockInTypeId')}
                ${stringifyIfNotNullOrUndefined(payload, 'type')}
                ${stringifyIfNotNullOrUndefined(payload, 'city')}
                ${stringifyIfNotNullOrUndefined(payload, 'street')}
                ${stringifyIfNotNullOrUndefined(payload, 'postalCode')}
                ${stringifyIfNotNullOrUndefined(payload, 'country')}
                ${stringifyIfNotNullOrUndefined(payload, 'workDescription')}
                ${stringifyIfNotNullOrUndefined(payload, 'drivingTime')}
                ${stringifyIfNotNullOrUndefined(payload, 'autoAssignCommissionId')}
            ) {
                ${ClockInEntityGqlFields.join(',')}
          }
        }`;
        return withProgress ? this.gatewayHttpService.graphQlWithProgress({ query: queryString }) : this.gatewayHttpService.graphQl({query: queryString});
    }
    changeClockIn(payload: DaveMutationChangeClockInArgs):  Observable<Query & DaveMutation>;
    changeClockIn(payload: DaveMutationChangeClockInArgs, withProgress: true):  Observable<HttpEvent<Object>>;
    changeClockIn(payload: DaveMutationChangeClockInArgs, withProgress: boolean = false){
        const queryString = `
        mutation {
            changeClockIn(
                id: ${JSON.stringify(payload.id)}
                ${stringifyIfNotNullOrUndefined(payload, 'timeStamp')}
                ${stringifyIfNotUndefined(payload, 'commissionId')}
                ${stringifyIfNotNullOrUndefined(payload, 'userId')}
                ${stringifyIfNotNullOrUndefined(payload, 'employeeId')}
                ${stringifyIfNotNullOrUndefined(payload, 'clockInTypeId')}
                ${stringifyIfNotNullOrUndefined(payload, 'type')}
                ${stringifyIfNotNullOrUndefined(payload, 'city')}
                ${stringifyIfNotNullOrUndefined(payload, 'street')}
                ${stringifyIfNotNullOrUndefined(payload, 'postalCode')}
                ${stringifyIfNotNullOrUndefined(payload, 'country')}
                ${stringifyIfNotNullOrUndefined(payload, 'workDescription')}
                ${stringifyIfNotNullOrUndefined(payload, 'kostenstelle')}
                ${stringifyIfNotNullOrUndefined(payload, 'sachkonto')}
                ${stringifyIfNotNullOrUndefined(payload, 'bearbeitungsschluessel')}
                ${stringifyIfNotNullOrUndefined(payload, 'drivingTime')}
            ) {
                ${ClockInEntityGqlFields.join(',')}
          }
        }`;
        return withProgress ? this.gatewayHttpService.graphQlWithProgress({ query: queryString }) : this.gatewayHttpService.graphQl({query: queryString});
    }
    deleteClockIn(payload: DaveMutationDeleteClockInArgs):  Observable<Query & DaveMutation>;
    deleteClockIn(payload: DaveMutationDeleteClockInArgs, withProgress: true):  Observable<HttpEvent<Object>>;
    deleteClockIn(payload: DaveMutationDeleteClockInArgs, withProgress: boolean = false){
        const queryString = `
        mutation {
            deleteClockIn(
                id: ${JSON.stringify(payload.id)}
            ) {
                ${ClockInEntityGqlFields.join(',')}
          }
        }`;
        return withProgress ? this.gatewayHttpService.graphQlWithProgress({ query: queryString }) : this.gatewayHttpService.graphQl({query: queryString});
    }
}
