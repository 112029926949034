import { Injectable, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { catchError, first, map, share, shareReplay, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { stringifyIfNotNullOrUndefined, uniqArray } from '../../helper/helper';
import { EmailEntity, EmailEntityFromBackend } from '../entities/email.entity';
import { EmailFolderEntityType } from '../entities/emailFolder.entity';
import { FileTypeFromFileBackend } from '../entities/file.entity';
import { EmailType, EmailUpdatedFilterType } from '../graphql-types';
import { State } from '../State';
import { BaseActionTypes } from '../State/actions/base.actions';
import { EmailActionTypes } from '../State/actions/email.actions';
import { getEmailById, getEmailDictionary, getEmailsByIds } from "../State/selectors/emails.selectors";
import { HttpService } from './http.service';
import { FrontendDate } from '../helper/backend-frontend-conversion.helper';
import { EMailErrorCodes } from "../State/effects/email.effects";
import { ToastrService } from "ngx-toastr";
import { Dictionary } from "@ngrx/entity";

const PAGE_SIZE = 100;

@Injectable({
    providedIn: 'root',
})
export class EmailDataService implements OnDestroy {
    /**
     *  key is folderId
     *  value is Date of latest updatedAt
     * @private
     */
    private folderCacheMap: Map<string, BehaviorSubject<{ pages: number[][]; allPagesLoaded: boolean }>>;
    private pendingRequestsBuffer: Map<string, Observable<EmailType[]>>;
    private clearSub: Subscription;

    constructor(private store: Store<State>, private gatewayHttpService: HttpService, private actions$: Actions, private toastrService: ToastrService,) {
        this.reset();
        this.clearSub = this.actions$.pipe(ofType(BaseActionTypes.InitStore)).subscribe(() => this.reset());
    }

    ngOnDestroy(): void {
        this.clearSub.unsubscribe();
    }

    private reset() {
        this.pendingRequestsBuffer = new Map<string, Observable<FileTypeFromFileBackend[]>>();
        this.folderCacheMap = new Map<string, BehaviorSubject<{ pages: number[][]; allPagesLoaded: boolean }>>();
    }

    public SyncEmails(folderId: number | EmailFolderEntityType, seen: boolean | null = null) {


        let key = folderId.toString() + (seen !== null ? '|' + (seen ? 'true' : 'false') : '');
        const subj = this.folderCacheMap.get(key);
        if (subj) {
            firstValueFrom(subj)
                .then((res) => {
                    const emailIds = uniqArray(res.pages.flat());
                    return firstValueFrom(this.store.select(getEmailsByIds({ Ids: emailIds })));
                })
                .then((emails) => {
                    if (!emails?.length) {
                        return;
                    }
                    const query = `query {
  updatedEmails(emailUpdateFilter: [${emails
                        .map<EmailUpdatedFilterType>((e) => ({ emailId: e.Id, updatedSince: FrontendDate(e.UpdatedAt) }))
                        .map(e => '{' + stringifyIfNotNullOrUndefined(e, 'emailId') + ', ' + stringifyIfNotNullOrUndefined(e, 'updatedSince') + '}').join(',\n')
                    }]) {
    ${EmailEntity.GQLFields}
  }
}`;
                    firstValueFrom(this.gatewayHttpService.graphQl({ query }))
                        .then((res) => {
                            if (!res?.updatedEmails) {
                                throw new Error('No updatedEmails in response');
                            }

                            if (res.updatedEmails.length) {
                                firstValueFrom(this.store.select(getEmailDictionary)).then(mails => {
                                    this.checkSendingStateChange(mails, res.updatedEmails);
                                    this.store.dispatch(EmailActionTypes.UpdateSomeEmails({ Payload: res.updatedEmails.map(EmailEntityFromBackend) }));
                                })
                            }
                        })
                        .catch((err) => {
                            this.store.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: 'E-Mail aktualisieren fehlgeschlagen',
                                        Err: err,
                                    },
                                }),
                            );
                        });
                });
        }
    }
    private checkSendingStateChange(emailsFromState: Dictionary<EmailEntity>, newEmails: EmailType[]) {
        newEmails.forEach(e => {
            if (emailsFromState[e.id]?.IsSending && !e.isSending) {
                if (e.errorMessage) {
                    this.store.dispatch(BaseActionTypes.ErrorAction({Payload: {
                            ToasterMessage: EMailErrorCodes.Sent,
                        },}))
                } else {
                    this.toastrService.success('E-Mail erfolgreich versandt.');
                }
            }
        })
    }
    private getEmailsFromBackend(
        options: {
            folderId?: number;
            folderType?: EmailFolderEntityType;
            updatedSince?: Date;
            id?: number;
            seenState?: boolean;
            filterArchiveAndTrash?: boolean;
            page?: number;
            searchExpression?: string;
        },
        paging: boolean = true,
    ): Observable<EmailType[]> {
        const query = `query{
        email(
        ${paging ? `pageSize: ${PAGE_SIZE}` : ''}
        ${stringifyIfNotNullOrUndefined(options, 'searchExpression')}
        ${stringifyIfNotNullOrUndefined(options, 'folderId')}
        ${stringifyIfNotNullOrUndefined(options, 'folderType')}
        ${stringifyIfNotNullOrUndefined(options, 'id')}
        ${stringifyIfNotNullOrUndefined(options, 'updatedSince')}
        ${stringifyIfNotNullOrUndefined(options, 'page')}
        ${stringifyIfNotNullOrUndefined(options, 'seenState')}
        ${stringifyIfNotNullOrUndefined(options, 'filterArchiveAndTrash')}
           ) {${EmailEntity.GQLFields}}
           }`;
        const key = JSON.stringify(options);
        if (!this.pendingRequestsBuffer.has(key)) {
            this.pendingRequestsBuffer.set(
                key,
                this.gatewayHttpService.graphQl({ query }).pipe(
                    catchError((err, caught) => {
                        this.store.dispatch(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: 'E-Mail abrufen fehlgeschlagen',
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        );
                        return of(null);
                    }),
                    take(1),
                    tap(() => this.pendingRequestsBuffer.delete(key)),
                    map((res) => {
                        if (res?.email) {
                            return res.email;
                        } else {
                            this.store.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: 'E-Mail abrufen fehlgeschlagen',
                                        Err: 'wrong response ' + res,
                                    },
                                }),
                            );
                            return [];
                        }
                    }),
                    shareReplay({ refCount: true, bufferSize: 1 }),
                ),
            );
        }
        return this.pendingRequestsBuffer.get(key);
    }

    public GetEmailById$(id: number, reload = false): Observable<EmailEntity> {
        if (!id) {
            console.error('Id is required');
            return of(null);
        }
        return this.store.select(getEmailById({ id })).pipe(
            take(1),
            switchMap((email) => {
                if (email && !reload) {
                    return this.store.select(getEmailById({ id }));
                } else {
                    const req = this.getEmailsFromBackend({ id: id }).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
                    req.subscribe((emails) => {
                        if (emails?.length === 1) {
                            firstValueFrom(this.store.select(getEmailDictionary)).then(mails => {
                                this.checkSendingStateChange(mails, emails);
                                this.store.dispatch(EmailActionTypes.UpdateSomeEmails({ Payload: emails.map(EmailEntityFromBackend) }));
                            })
                        } else {
                            console.error('E-Mail mit Id ' + id + ' konnte nicht abgerufen werden.');
                        }
                    });
                    return req.pipe(switchMap(() => this.store.select(getEmailById({ id }))));
                }
            }),
        );
    }

    public GetEmailSearchString$(searchString: string, folder: number | EmailFolderEntityType, seen?: boolean | null): Observable<EmailEntity[]> {
        console.warn(!searchString, seen === null);
        if (!searchString && seen === null) {
            console.error('SearchString is required');
            return of(null);
        }

        let options: {
            folderId?: number;
            folderType?: EmailFolderEntityType;
            updatedSince?: Date;
            id?: number;
            seenState?: boolean;
            filterArchiveAndTrash?: boolean;
            page?: number;
            searchExpression?: string;
        } = {
            searchExpression: searchString,
            seenState: seen,
        };
        if (folder == 'inbox') {
            options.filterArchiveAndTrash = true;
        } else {
            options.filterArchiveAndTrash = false;
            options.folderId = typeof folder === 'number' ? folder : undefined;
            options.folderType = typeof folder !== 'number' ? folder : undefined;
        }
        return this.getEmailsFromBackend(options, false).pipe(
            map((emailResults) => emailResults.map(EmailEntityFromBackend)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }

    public ClearCache(emailIds: number[], clearFull: boolean = true) {
        const reloadFolders: Array<string> = [];
        this.folderCacheMap.forEach((value, key) => {
            if (value.value.pages.flat().some((id) => emailIds.includes(id))) {
                reloadFolders.push(key);
            }
        });

        const reloadFoldersFull: Array<string> = [];

        if (clearFull) {
            console.warn('clear full');
            uniqArray(reloadFolders).forEach((key) => {
                let sKey = key.split('|')[0];
                reloadFoldersFull.push(sKey);
                reloadFoldersFull.push(sKey + '|true');
                reloadFoldersFull.push(sKey + '|false');
            });
        } else {
            reloadFolders.forEach((key) => reloadFoldersFull.push(key));
        }
        console.warn('reload', uniqArray(reloadFoldersFull));

        // uniqArray(reloadFoldersFull).forEach(key => this.folderCacheMap.delete(key));
        uniqArray(reloadFoldersFull).forEach((key) => {
            this.folderCacheMap.get(key)?.next({ pages: [], allPagesLoaded: false });
            this.LoadNextPage$(+key.split('|')[0] ? +key.split('|')[0] : (key.split('|')[0] as EmailFolderEntityType), key.split('|')[1] ? key.split('|')[1] == 'true' : null)
                .pipe(first())
                .subscribe();
        });
    }

    // clear full clears a folder in all states
    public CleanPages(emailIds: number[], clearFull: boolean = true) {
        const reloadFolders: Array<string> = [];
        this.folderCacheMap.forEach((value, key) => {
            if (value.value.pages.flat().some((id) => emailIds.includes(id))) {
                reloadFolders.push(key);
            }
        });

        const reloadFoldersFull: Array<string> = [];

        if (clearFull) {
            console.warn('clear full');
            uniqArray(reloadFolders).forEach((key) => {
                let sKey = key.split('|')[0];
                reloadFoldersFull.push(sKey);
                reloadFoldersFull.push(sKey + '|true');
                reloadFoldersFull.push(sKey + '|false');
            });
        } else {
            reloadFolders.forEach((key) => reloadFoldersFull.push(key));
        }
        console.warn('reload', uniqArray(reloadFoldersFull));

        uniqArray(reloadFoldersFull).forEach((key) =>
            this.LoadNextPage$(+key.split('|')[0] ? +key.split('|')[0] : (key.split('|')[0] as EmailFolderEntityType), key.split('|')[1] ? key.split('|')[1] == 'true' : null)
                .pipe(first())
                .subscribe(),
        );
    }

    public GetFirstPage$(folderId: number | EmailFolderEntityType, seen: boolean | null, reload = true): Observable<EmailEntity[]> {
        let key = folderId.toString() + (seen !== null ? '|' + (seen ? 'true' : 'false') : '');
        console.warn('key', key);
        if (reload || true) {
            if (this.folderCacheMap.has(key)) {
                this.folderCacheMap.get(key).next({ pages: [], allPagesLoaded: false });
            }
        } else if (this.folderCacheMap.has(key)) {
            return this.folderCacheMap.get(key).pipe(switchMap((ret) => this.store.select(getEmailsByIds({ Ids: uniqArray(ret.pages.flat()) }))));
        }
        return this.LoadNextPage$(folderId, seen).pipe(
            switchMap((ret) => ret),
            switchMap((ret) => this.store.select(getEmailsByIds({ Ids: uniqArray(ret.pages.flat()) }))),
        );
    }

    public LoadNextPage$(folderId: number | EmailFolderEntityType, seen: boolean | null = null): Observable<BehaviorSubject<{ pages: number[][]; allPagesLoaded: boolean }>> {
        let key = folderId.toString() + (seen !== null ? '|' + (seen ? 'true' : 'false') : '');

        const cache = this.folderCacheMap.get(key);
        // if (cache?.value.allPagesLoaded) {
        //     return of(cache);
        // }
        const page = cache ? (cache.value.allPagesLoaded ? cache.value.pages.length - 1 : cache.value.pages.length) : 0;

        let options: {
            folderId?: number;
            folderType?: EmailFolderEntityType;
            updatedSince?: Date;
            id?: number;
            seenState?: boolean;
            page?: number;
            searchExpression?: string;
        } = {
            folderId: typeof folderId === 'number' ? folderId : undefined,
            folderType: typeof folderId !== 'number' ? folderId : undefined,
            page,
        };

        if (seen !== null) {
            options.seenState = seen;
        }

        const req = this.getEmailsFromBackend(options).pipe(
            take(1),
            withLatestFrom(this.store.select(getEmailDictionary)),
            switchMap(([emailReturn, emailState]) => {
                this.checkSendingStateChange(emailState, emailReturn);
                let pageInValid = false;
                const emails = emailReturn.map(EmailEntityFromBackend);
                // this.handleByEmailFolderTypeReturn(emails.map(EmailEntityFromBackend), folderType, page);
                const allPagesLoaded = emails.length < PAGE_SIZE;
                if (this.folderCacheMap.has(key)) {
                    const pages = this.folderCacheMap.get(key).value.pages;
                    pages[page] = emails.map((e) => e.Id);
                    if (page && pages[page - 1][PAGE_SIZE - 1] !== pages[page][0]) {
                        pageInValid = true;
                        this.folderCacheMap.get(key).next({ pages: [], allPagesLoaded: false });
                    } else {
                        this.folderCacheMap.get(key).next({ pages, allPagesLoaded });
                    }
                } else {
                    const pages = [];
                    pages[page] = emails.map((e) => e.Id);
                    this.folderCacheMap.set(
                        key,
                        new BehaviorSubject<{ pages: number[][]; allPagesLoaded: boolean }>({
                            pages,
                            allPagesLoaded,
                        }),
                    );
                }
                if (emails.length) {

                    this.store.dispatch(EmailActionTypes.UpdateSomeEmails({ Payload: emails }));
                }
                return pageInValid ? this.LoadNextPage$(folderId, seen) : of(this.folderCacheMap.get(key));
            }),
            share(),
        );
        return req;
    }
}
