<div class="header">
	<button app-round-button class="back-button" (click)="DialogRef.close()">
		<fa-icon icon="chevron-right"></fa-icon>
	</button>
	<div class="buttons">
		<button app-round-button (click)="Edit()" *ngIf="!Editing" [Disabled]="!HasEditPermissions" matTooltip="Ordner bearbeiten">
			<fa-icon icon="pen"></fa-icon>
		</button>
		<button app-round-button (click)="Save()" *ngIf="Editing">
			<fa-icon icon="save"></fa-icon>
		</button>
	</div>
</div>
<div class="scrollbar">
	<div class="icon-wrapper">
		<fa-icon icon="folder" size="8x"></fa-icon>
	</div>
	<mat-tab-group>
		<mat-tab label="Details">
			<app-detail-list-template [Data]="Details | async" [Editing]="Editing" [Inline]="false" class="full-width">
			</app-detail-list-template>
		</mat-tab>
		<mat-tab label="Freigaben">
			<div class="loading-wrapper" *ngIf="UsersLoading">
				<app-loading></app-loading>
			</div>
			<table *ngIf="!UsersLoading" class="user-table" mat-table [dataSource]="UserTableData | async">
				<ng-container matColumnDef="user">
					<th mat-header-cell *matHeaderCellDef>Benutzer</th>
					<td mat-cell *matCellDef="let element">
						{{element.user.DisplayName}}
					</td>
				</ng-container>
				<ng-container matColumnDef="permission">
					<th mat-header-cell *matHeaderCellDef style="width: 7rem">Berechtigung</th>
					<td mat-cell *matCellDef="let element">
						<ng-container *ngIf="element.edit">
							<fa-icon icon="pen" size="xs"></fa-icon>
							bearbeiten
						</ng-container>
						<ng-container *ngIf="!element.edit">
							<fa-icon icon="eye" size="xs"></fa-icon>
							ansehen
						</ng-container>
					</td>
				</ng-container>
				<ng-container matColumnDef="edit">
					<th mat-header-cell *matHeaderCellDef style="width: 3rem">
						<button app-round-button (click)="NewFolderUser()" *ngIf="HasEditPermissions" matTooltip="Benutzer hinzufügen">
							<fa-icon icon="plus"></fa-icon>
						</button>
					</th>
					<td mat-cell *matCellDef="let element">
						<button app-round-button (click)="ViewFolderUser(element.folderUsers)">
							<fa-icon icon="pen" *ngIf="!element.inherited" matTooltip="Benutzer bearbeiten"></fa-icon>
							<fa-icon icon="eye" *ngIf="element.inherited" matTooltip="Benutzer ansehen"></fa-icon>
						</button>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="['user', 'permission', 'edit']"></tr>
				<tr mat-row *matRowDef="let row; columns: ['user', 'permission', 'edit']" [class.inherited]="row.inherited"></tr>
			</table>
		</mat-tab>
	</mat-tab-group>
</div>
