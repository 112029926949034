import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, Subscription } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import { EventTypesSelectorCardComponent } from '../../../components/templates/event-types-selector-card/event-types-selector-card.component';
import { TextareaModule } from '../../../components/templates/textarea/textarea.module';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { EventTypeEntity } from '../../../dave-data-module/entities/event-type.entity';
import { FileEntityFromBackend } from '../../../dave-data-module/entities/file.entity';
import { FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import { FilesActionTypes } from '../../../dave-data-module/State/actions/files.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getCommissionById, getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomerById, getCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getEventTypes } from '../../../dave-data-module/State/selectors/event-type.selector';
import { getFiles } from '../../../dave-data-module/State/selectors/files.selectors';
import { getPersonById, getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { IcsService } from '../../../dave-history-module/services/ics.service';
import { PersonModalComponent, PersonModalComponentDialogData } from '../../../dave-person-module/components/person-modal/person-modal';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { SelectSearchModule } from '../../../dave-utils-module/select-search/select-search.module';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, OpenHTMLInputPicker, sameDay } from '../../../helper/helper';
import { getErrorMessage } from '../../../helper/validation.helper';
import { CustomLabelService } from '../../../services/custom-label.service';
import { LoadingService } from '../../../services/loading.service';
import { TimePickerSelectComponent } from '../../../time-picker-select/time-picker-select.component';
import { TimeSpanPickerSelectComponent } from '../../../time-span-picker-select/time-span-picker-select.component';

export interface CreateEmailEventComponentDialogData {
    defaultValues?: {
        personId?: number;
        commissionId?: number;
        customerId?: number;
    };
}

@Component({
    selector: 'app-create-email-event',
    templateUrl: 'create-email-event.component.html',
    styleUrls: ['create-email-event.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        FontAwesomeModule,
        EventTypesSelectorCardComponent,
        ReactiveFormsModule,
        FormsModule,
        SelectSearchModule,
        AppButtonModule,
        MatTooltipModule,
        MatInputModule,
        MatDatepickerModule,
        TimeSpanPickerSelectComponent,
        TimePickerSelectComponent,
        TextareaModule,
    ],
})
export class CreateEmailEventComponent implements OnInit, OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        height: '80vh',
    };
    public OpenPicker = OpenHTMLInputPicker;
    public EventTypeList: EventTypeEntity[] = null;
    public PersonList: SelectSearchData[] = [];
    public Customers: SelectSearchData[] = [];
    public Commissions: SelectSearchData[] = [];
    public Customer: CustomerEntity;
    public SelectedPersons$ = new BehaviorSubject<PersonEntity[]>([]);
    public GetErrorMessage = getErrorMessage;
    public NewEventForm = new FormGroup({
        CustomerId: new FormControl<SelectSearchData>(null),
        CommissionId: new FormControl<SelectSearchData>(null),
        EventTypeId: new UntypedFormControl(null, Validators.required),
        PersonId: new FormControl<SelectSearchData>(null),
        Note: new FormControl<string>('', {
            updateOn: 'blur',
        }),
        EventDate: new FormControl<Moment>(moment(), Validators.required),
        EventEndDate: new FormControl<Moment>(moment(), Validators.required),
        EventStartTimeString: new FormControl<string>(moment().get('hour') + ':' + moment().get('minute'), {
            validators: Validators.required,
            updateOn: 'blur',
        }),
        EventEndTimeString: new UntypedFormControl(moment().get('hour') + ':' + moment().get('minute'), {
            validators: Validators.required,
            updateOn: 'blur',
        }),
        Street: new UntypedFormControl(null),
        City: new UntypedFormControl(null),
        PostalCode: new UntypedFormControl(null),
        Country: new UntypedFormControl(null),
    });
    private subscriptions: Subscription[] = [];
    private token: string;
    private saved = false;

    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: CreateEmailEventComponentDialogData,
        public CS: CustomerNameService,
        protected ics: IcsService,
        private dialog: MatDialog,
        protected dialogRef: MatDialogRef<CreateEmailEventComponent>,
        protected cls: CustomLabelService,
        private actions$: Actions,
        protected ls: LoadingService,
        private folderDataService: FolderDataService,
    ) {}

    ngOnInit() {
        if (this.Dialogdata?.defaultValues) {
            if (this.Dialogdata.defaultValues.personId) {
                firstValueFrom(this.store.select(getPersonById({ id: this.Dialogdata.defaultValues.personId }))).then((person) => {
                    if (person) {
                        this.NewEventForm.controls.PersonId.setValue({ Id: person.Id, Name: this.ContactPersonText(person) });
                    }
                });
            }
            if (this.Dialogdata.defaultValues.customerId) {
                firstValueFrom(this.store.select(getCustomerById({ id: this.Dialogdata.defaultValues.customerId }))).then((customer) => {
                    if (customer) {
                        this.NewEventForm.controls.CustomerId.setValue({ Id: customer.Id, Name: customer.DisplayName });
                    }
                });
            }
            if (this.Dialogdata.defaultValues.commissionId) {
                firstValueFrom(this.store.select(getCommissionById({ id: this.Dialogdata.defaultValues.commissionId }))).then((commission) => {
                    if (commission) {
                        this.NewEventForm.controls.CommissionId.setValue({ Id: commission.Id, Name: this.CommissionPersonText(commission) });
                    }
                });
            }
        }
        this.subscriptions.push(
            this.store
                .select(getToken)
                .pipe(filter(isNotNullOrUndefined))
                .subscribe((t) => (this.token = t)),
            this.store.select(getEventTypes).subscribe((e) => (this.EventTypeList = e)),
            combineLatest([
                combineLatest([
                    this.store.select(getPersons).pipe(map((p) => p.filter((p) => !p.Deleted))),
                    this.store.select(getPerson2Entities).pipe(map((pes) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Customer))),
                    this.NewEventForm.controls.CustomerId.valueChanges.pipe(startWith(null)),
                ]).pipe(
                    map(([persons, personEntities, customer]) => {
                        if (customer !== null) {
                            return persons.filter((p) => personEntities.find((pe) => pe.PersonId === p.Id && pe.EntityId === customer.Id) !== undefined);
                        } else {
                            return persons;
                        }
                    }),
                    map((persons) =>
                        persons.map((p) => ({
                            Id: p.Id,
                            Name: this.ContactPersonText(p),
                        })),
                    ),
                ),
            ]).subscribe(([c]) => {
                this.PersonList = c;
            }),
            combineLatest([
                combineLatest([this.store.select(getCommissions).pipe(map((c) => c.filter((c) => !c.Deleted))), this.NewEventForm.controls.CustomerId.valueChanges]).pipe(
                    map(([commissions, customer]) => (customer ? commissions.filter((c) => c.CustomerId === customer.Id) : commissions.filter((c) => c.CustomerId == null))),
                    map((c) =>
                        c.map((c) => ({
                            Name: this.CommissionPersonText(c),
                            Id: c.Id,
                        })),
                    ),
                ),
            ]).subscribe(([c]) => {
                this.Commissions = c;
            }),
            combineLatest([this.store.select(getCustomers)]).subscribe(([c]) => {
                this.Customers = c.filter((c) => !c.Deleted).map((c) => ({ Name: c.DisplayName, Id: c.Id }));
            }),
            combineLatest([this.NewEventForm.controls?.CustomerId.valueChanges, this.store.select(getCustomers)]).subscribe(([customer, customers]) => {
                this.Customer = customers.find((c) => c.Id == customer.Id);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public Submit() {
        if (!this.saved) {
            this.saved = true;
            if (this.NewEventForm.invalid) {
                return;
            }
            this.ls.startLoading('app-new-from-email-submit');
            const { PersonId, Note, CustomerId, CommissionId, EventDate, EventEndDate, EventStartTimeString, EventEndTimeString } = this.NewEventForm.value;

            const eventDate = EventDate?.toDate();
            const tempEventEndDate = EventEndDate?.toDate();
            const eventEndDate = new Date(
                tempEventEndDate.getFullYear(),
                tempEventEndDate.getMonth(),
                tempEventEndDate.getDate(),
                +EventEndTimeString.split(':')[0],
                +EventEndTimeString.split(':')[1],
                tempEventEndDate.getSeconds(),
                tempEventEndDate.getMilliseconds(),
            );
            eventDate.setHours(+EventStartTimeString.split(':')[0]);
            eventDate.setMinutes(+EventStartTimeString.split(':')[1]);
            eventDate.setSeconds(0);
            eventDate.setMilliseconds(0);

            this.store.dispatch(
                EventsActionTypes.AddEvent({
                    Payload: {
                        personId: PersonId?.Id,
                        customerId: CustomerId?.Id,
                        hint: null,
                        street: this.NewEventForm.value.Street,
                        city: this.NewEventForm.value.City,
                        postalCode: this.NewEventForm.value.PostalCode,
                        country: this.NewEventForm.value.Country,
                        commissionId: CommissionId?.Id,
                        eventTypeId: this.NewEventForm.value.EventTypeId.Id,
                        eventDate: FrontendDate(eventDate),
                        eventEndDate: FrontendDate(eventEndDate),
                        description: Note || '',
                        private: false,
                    },
                }),
            );

            combineLatest([this.actions$.pipe(ofType(BaseActionTypes.ErrorAction, EventsActionTypes.UpdateEvent)), this.folderDataService.getFolderByUniqueType(FolderTypes.email_attachment).pipe(take(1))]).subscribe(([action, folder]) => {
                if (action.type === EventsActionTypes.UpdateEvent.type) {
                    this.ics.GetICSFileFromEvent(action.Payload).then((file) => {
                        const formData = new FormData();
                        formData.append('Files', file);
                        const req = new XMLHttpRequest();
                        req.onreadystatechange = () => {
                            if (req.readyState === 4) {
                                const response = JSON.parse(req.response).data[0];
                                this.store.dispatch(FilesActionTypes.UpdateOne({ Payload: FileEntityFromBackend(response) }));
                                this.store
                                    .select(getFiles)
                                    .pipe(
                                        map((files) => files.find((f) => f.Id === response.id)),
                                        filter(isNotNullOrUndefined),
                                        take(1),
                                    )
                                    .subscribe((f) => {
                                        this.ls.endLoading('app-new-from-email-submit');
                                        this.dialogRef.close(f.Id);
                                    });

                                // let folder = folders.find((f) => f.ParentId == null && f.Type === FolderTypes.email_attachment);
                                this.store.dispatch(
                                    FilesActionTypes.ModifyFileRequest({
                                        Payload: {
                                            DocumentId: response.id,
                                            EventIds: [action.Payload.Id.toString()],
                                            CustomerIds: CustomerId?.Id && [CustomerId.Id.toString()],
                                            Hidden: false,
                                            FolderId: folder?.Id && folder.Id.toString(),
                                        },
                                    }),
                                );
                            }
                        };
                        req.open('POST', HttpService.getUrl('files', 'gateway', -1));
                        req.setRequestHeader('Accept', 'application/json');
                        req.setRequestHeader('Authorization', 'Bearer ' + this.token);
                        req.send(formData);
                    });
                } else {
                    this.ls.endLoading('app-new-from-email-submit');
                }
            });
        }
    }

    public NewContactPerson() {
        this.dialog
            .open(PersonModalComponent, {
                ...PersonModalComponent.DefaultConfig,
                data: {} as PersonModalComponentDialogData,
                // panelClass: 'custom-dialog-class-without-padding-margin-top-1-75',
            })
            .afterClosed()
            .subscribe((newPerson) => {
                if (newPerson) {
                    let v = this.SelectedPersons$.getValue();
                    v.push(newPerson);
                    this.SelectedPersons$.next(v);
                }
            });
    }

    public ContactPersonText(option: PersonEntity): string {
        return isNotNullOrUndefined(option.DisplayName)
            ? option.DisplayName + (isNotNullOrUndefined(option.Email) ? ' ' + option.Email : '') + (isNotNullOrUndefined(option.Description) ? ' ' + option.Description : '')
            : 'unbennanter ' + this.Customer
            ? this.Customer.AnsprechpartnerText()
            : 'Ansprechpartner / Sachbearbeiter';
    }

    // public OnDateChange(date: Date) {
    //     this.NewEventForm.controls.EventDate.setValue(date);
    //     this.OnEndDateChange(new Date(date));
    // }
    //
    // public OnEndDateChange(date: Date) {
    //     this.NewEventForm.controls.EventEndDate.setValue(date);
    // }

    private CommissionPersonText(c: CommissionEntity) {
        return (c.InterneNummer || '--') + ' | ' + (c.Description || '--') + ' | ' + (c.Auftragsnummer || '--');
    }

    protected readonly sameDay = sameDay;
}
