import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, from, Observable, of, switchMap } from 'rxjs';
import { catchError, distinctUntilChanged, map, shareReplay, take, tap, withLatestFrom } from 'rxjs/operators';
import { ErrorPopupService } from '../../error-popup/error-popup.service';
import { isNotNullOrUndefined } from '../../helper/helper';
import { FolderEntity, FolderEntityFromFileBackend, FolderTypeFromFileBackend, FolderTypes, getFolderTypeString } from '../entities/folder.entity';
import { State } from '../State';
import { BaseActionTypes } from '../State/actions/base.actions';
import { FolderActionTypes } from '../State/actions/folder.actions';
import { getFolderByEntity, getFolderById, getFolderByIdWithChildren, getFolderByParentId, getFoldersDictionary } from '../State/selectors/folder.selectors';
import { HttpService } from './http.service';
import moment from 'moment/moment';

@Injectable({
    providedIn: 'root',
})
export class FolderDataService {
    private pendingRequestsBuffer: Map<string, Promise<FolderEntity[]>> = new Map<string, Promise<FolderEntity[]>>();
    private cacheById = new Map<number, Date>();
    private cacheByTypeAndEntity = new Map<string, Date>();
    private cacheByParentId = new Map<number, Date>();
    private cacheByType = new Map<FolderTypes, number>();
    private cacheByIdWithChildren = new Map<number, Date>();

    private baseUrl = this.gatewayHttpService.GetUrl('folders', 'file');
    constructor(private http: HttpClient, private store: Store<State>, private gatewayHttpService: HttpService, private errorPopup: ErrorPopupService) {}

    public pollFolderSince(since: Date): Promise<FolderEntity[]> {
        const startTime = new Date();

        return this.getFoldersFromBackend({since: moment(since).toISOString() }).then((folders) => {
            folders.forEach((f) => {
                this.cacheById.set(f.Id, startTime);
                if (f.EntityId && f.Type) {
                    this.cacheByTypeAndEntity.set(getFolderTypeString(f.Type) + '|' + f.EntityId, startTime);
                }
            });
            return folders;
        });
    }
    public getFolderFromEntity(entityId: number, entityType: FolderTypes, useCache = true): Observable<FolderEntity> {
        const startTime = new Date();

        const stateObservable$ = this.store.select(getFolderByEntity({ entityId, type: entityType }));

        if (useCache) {
            const cache = this.cacheByTypeAndEntity.get(entityType + '|' + entityId);
            if (cache) {
                return stateObservable$;
            }
        }
        const request$ = () =>
            this.getFoldersFromBackend({ withParentFolders: true, selectFolder: { entityId, folderType: entityType } }).then((folders) => {
                folders.forEach((f) => {
                    this.cacheById.set(f.Id, startTime);
                    if (f.EntityId && f.Type) {
                        this.cacheByTypeAndEntity.set(getFolderTypeString(f.Type) + '|' + f.EntityId, startTime);
                    }
                });
            });
        return of(null).pipe(
            switchMap(() => from(request$())),
            shareReplay({ refCount: true, bufferSize: 1 }),
            switchMap(() => stateObservable$),
        );
    }
    public getFolderByUniqueType(entityType: FolderTypes, useCache = true): Observable<FolderEntity> {
        const startTime = new Date();

        const stateObservable$ = (id: number) => this.store.select(getFolderById({ id }));
        if (useCache) {
            const cache = this.cacheByType.get(entityType);
            if (cache) {
                return stateObservable$(cache);
            }
        }
        const request$ = this.getFoldersFromBackend({ withParentFolders: true, selectFolder: { folderType: entityType } }).then((folders) => {
            folders.forEach((f) => {
                this.cacheById.set(f.Id, startTime);
                if (f.EntityId && f.Type) {
                    this.cacheByTypeAndEntity.set(getFolderTypeString(f.Type) + '|' + f.EntityId, startTime);
                }
            });
            return folders;
        });
        return from(request$).pipe(
            shareReplay({ refCount: true, bufferSize: 1 }),
            switchMap((res) => stateObservable$(res.find((r) => r.Type === entityType)?.Id)),
        );
    }
    public getFolderByParent(parentFolderId: number, useCache = true): Observable<FolderEntity[]> {
        // return of([]);

        const startTime = new Date();

        const stateObservable$ = this.store.select(getFolderByParentId({ parentId: parentFolderId })).pipe(
            distinctUntilChanged((a, b) => a.length === b.length && a.every((e, index) => e === b[index])),
        );

        if (useCache) {
            const cache = this.cacheByParentId.get(parentFolderId);
            if (cache) {
                return stateObservable$;
            }
        }
        const request$ = this.getFoldersFromBackend({ withParentFolders: false, parentFolder: parentFolderId || 0 }).then((folders) => {
            folders.forEach((f) => {
                this.cacheByParentId.set(parentFolderId, startTime);
                this.cacheById.set(f.Id, startTime);
                if (f.EntityId && f.Type) {
                    this.cacheByTypeAndEntity.set(getFolderTypeString(f.Type) + '|' + f.EntityId, startTime);
                }
            });
        });
        return from(request$).pipe(
            shareReplay({ refCount: true, bufferSize: 1 }),
            switchMap(() => stateObservable$),
        );
    }
    public getFolderById(folderId: number, useCache = true): Observable<FolderEntity> {
        // return of(null);

        return this.getFolderByIds([folderId], useCache).pipe(map((folders) => folders[0]));
    }

    public getFolderByIds(folderIds: number[], useCache = true): Observable<FolderEntity[]> {
        // return of([]);

        const startTime = new Date();

        const stateObservable$ = combineLatest(folderIds.map((id) => this.store.select(getFolderById({ id }))));
        const cacheComplete = folderIds.every((id) => this.cacheById.has(id));
        if (useCache && cacheComplete) {
            return stateObservable$;
        }

        const request$ = this.getFoldersFromBackend({ withParentFolders: false, folderIds }).then((folders) => {
            folders.forEach((f) => {
                this.cacheById.set(f.Id, startTime);
                if (f.EntityId && f.Type) {
                    this.cacheByTypeAndEntity.set(getFolderTypeString(f.Type) + '|' + f.EntityId, startTime);
                }
            });
        });
        if (cacheComplete) {
            request$.then();
            return stateObservable$;
        } else {
            return from(request$).pipe(
                shareReplay({ refCount: true, bufferSize: 1 }),
                switchMap(() => stateObservable$),
            );
        }
    }
    public getFolderByIdWithChildren(folderId: number, useCache = true): Observable<FolderEntity[]> {
        // return of([]);

        const startTime = new Date();

        const stateObservable$ = this.store.select(getFolderByIdWithChildren({ id: folderId }));
        const cacheComplete = this.cacheByIdWithChildren.has(folderId);
        if (useCache && cacheComplete) {
            return stateObservable$;
        }

        const request$ = this.getFoldersFromBackend({ withParentFolders: false, folderIds: [folderId], withSubFolder: true }).then((folders) => {
            this.cacheByIdWithChildren.set(folderId, startTime);
            folders.forEach((f) => {
                this.cacheById.set(f.Id, startTime);
                if (f.EntityId && f.Type) {
                    this.cacheByTypeAndEntity.set(getFolderTypeString(f.Type) + '|' + f.EntityId, startTime);
                }
            });
        });
        if (cacheComplete) {
            request$.then();
            return stateObservable$;
        } else {
            return from(request$).pipe(
                shareReplay({ refCount: true, bufferSize: 1 }),
                switchMap(() => stateObservable$),
            );
        }
    }

    public getFolderAndParentsById(folderId: number, useCache = true): Observable<FolderEntity[]> {
        if (!folderId) {
            return of([]);
        }

        const startTime = new Date();

        const stateObservable$ = this.store.select(getFoldersDictionary).pipe(map((folders) => buildPathArray(folderId, folders)));

        return this.store.select(getFoldersDictionary).pipe(
            switchMap((folders) => {
                const breadcrumbs = buildPathArray(folderId, folders);
                if (breadcrumbs && useCache) {
                    return stateObservable$;
                } else {
                    const request$ = this.getFoldersFromBackend({ withParentFolders: true, folderIds: [folderId] }).then((folders) => {
                        folders.forEach((f) => {
                            this.cacheById.set(f.Id, startTime);
                            if (f.EntityId && f.Type) {
                                this.cacheByTypeAndEntity.set(getFolderTypeString(f.Type) + '|' + f.EntityId, startTime);
                            }
                        });
                    });
                    if (breadcrumbs) {
                        request$.then();
                        return stateObservable$;
                    }
                    return from(request$).pipe(
                        shareReplay({ refCount: true, bufferSize: 1 }),
                        switchMap(() => stateObservable$),
                    );
                }
            }),
        );
    }

    public GetSubFolders = (folderId: number) => this.getFoldersFromBackend({ folderIds: [folderId], withSubFolder: true });
    private getFoldersFromBackend(options: { folderIds?: number[]; withSubFolder?: boolean; withParentFolders?: boolean; parentFolder?: number; selectFolder?: { folderType: FolderTypes; entityId?: number }, since?: string}): Promise<FolderEntity[]> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
        const params: { [key: string]: string } = Object.create(null);

        if (options.folderIds?.length) {
            params['folder_ids'] = options.folderIds.join(',');
        }
        if (options.parentFolder !== undefined) {
            // null für die Files die im imaginären Hauptordner liegen, also keine FolderId haben
            params['parent_folder'] = options.parentFolder ? options.parentFolder + '' : 'null';
        }
        if (options.withSubFolder) {
            params['with_sub_folder'] = 'true';
        }
        if (options.withParentFolders) {
            params['with_parent_folders'] = 'true';
        }
        if (options.selectFolder) {
            params['select_folder'] = [isNotNullOrUndefined(options.selectFolder.folderType) ? getFolderTypeString(options.selectFolder.folderType) : null, options.selectFolder.entityId].filter(isNotNullOrUndefined).join('|');
        }

        if (options.since) {
            params['since'] = options.since;
        }
        const paramString = `${
            Object.keys(params).length
                ? '?' +
                  Object.keys(params)
                      .map((k) => k + '=' + params[k])
                      .join('&')
                : ''
        }`;
        if (!this.pendingRequestsBuffer.has(paramString)) {
            this.pendingRequestsBuffer.set(
                paramString,
                firstValueFrom(
                    this.http
                        .get<FolderTypeFromFileBackend[]>(this.baseUrl + paramString, {
                            headers,
                        })
                        .pipe(
                            // retryWithBackoff(),
                            //
                            catchError((err, caught) => {
                                if (!err.error.includes('select_folder not found')) {
                                    this.errorPopup.OpenErrorPopup();
                                    this.store.dispatch(
                                        BaseActionTypes.ErrorAction({
                                            Payload: {
                                                ToasterMessage: 'Ordner Abrufen fehlgeschlagen',
                                                Err: err,
                                                Caught: caught,
                                            },
                                        }),
                                    );
                                }
                                return of([]);
                            }),
                            take(1),
                            tap((res) => {
                                this.pendingRequestsBuffer.delete(paramString);
                            }),
                            withLatestFrom(this.store.select(getFoldersDictionary)),
                            map(([res, folders]) => {
                                if (res?.length) {
                                    const folderRes: FolderEntity[] = res.map(FolderEntityFromFileBackend);
                                    const Payload = folderRes.filter((f) => {
                                        const folderFromState = folders[f.Id];
                                        return !folderFromState || f.UpdatedAt.getTime() > folderFromState.UpdatedAt.getTime();
                                    });
                                    if (Payload.length) {
                                        this.store.dispatch(
                                            FolderActionTypes.UpdateMany({
                                                Payload,
                                                updateLatestUpdatedAt: false,
                                            }),
                                        );
                                    }
                                    return folderRes;
                                }
                                return [];
                            }),
                            shareReplay({ refCount: true, bufferSize: 1 }),
                        ),
                ),
            );
        }
        return this.pendingRequestsBuffer.get(paramString);
    }
}
function buildPathArray(folderId: number, folders: Dictionary<FolderEntity>): FolderEntity[] {
    let folder = folders[folderId];
    let foundAll = !!folder;
    const breadcrumbs = [folder];
    while (folder?.ParentId) {
        folder = folders[folder.ParentId];
        if (!folder) {
            foundAll = false;
            break;
        }
        breadcrumbs.unshift(folder);
    }
    return foundAll ? breadcrumbs : null;
}
