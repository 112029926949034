import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';
import { FileVersionType } from '../graphql-types';
import { DetectionResultData, DocumentType as DocumentDetectionDocumentType } from '@dave/types/dist/proto/documentDetection/documentDetection';
import { Version, VersionDocumentDetectionState } from '@dave/types/dist/proto/file/file';
import { isNotNullOrUndefined } from "../../helper/helper";
import { GetTimeFromTimestamp, GetTimestampFromTime } from "@dave/types";

// export enum DocumentDetectionDocumentType {
//     DocumentTypeUnknown = "DocumentTypeUnknown",
//     DocumentTypeInvoice = "DocumentTypeInvoice",
//     DocumentTypeOffer = "DocumentTypeOffer",
//     DocumentTypeReceipt = "DocumentTypeReceipt",
//     DocumentTypeOrder = "DocumentTypeOrder",
//     DocumentTypeDeliveryNote = "DocumentTypeDeliveryNote"
// }

// kopie aus types package
enum VersionDocumentDetectionStateFromFileService {
    VersionDocumentDetectionStateNone = 0,
    VersionDocumentDetectionStateManual = 1,
    VersionDocumentDetectionStateAutomatic = 2,
}
// kopie aus types package
enum DocumentDetectionDocumentTypeFromFileService {
    DocumentTypeUnknown = 0,
    DocumentTypeInvoice = 1,
    DocumentTypeOffer = 2,
    DocumentTypeReceipt = 3,
    DocumentTypeOrder = 4,
    DocumentTypeDeliveryNote = 5,
}
const documentDetectionTypeFromBackend: Map<DocumentDetectionDocumentTypeFromFileService, DocumentDetectionDocumentType> = new Map<DocumentDetectionDocumentTypeFromFileService, DocumentDetectionDocumentType>([
    [DocumentDetectionDocumentTypeFromFileService.DocumentTypeUnknown, DocumentDetectionDocumentType.DocumentTypeUnknown],
    [DocumentDetectionDocumentTypeFromFileService.DocumentTypeInvoice, DocumentDetectionDocumentType.DocumentTypeInvoice],
    [DocumentDetectionDocumentTypeFromFileService.DocumentTypeOffer, DocumentDetectionDocumentType.DocumentTypeOffer],
    [DocumentDetectionDocumentTypeFromFileService.DocumentTypeReceipt, DocumentDetectionDocumentType.DocumentTypeReceipt],
    [DocumentDetectionDocumentTypeFromFileService.DocumentTypeOrder, DocumentDetectionDocumentType.DocumentTypeOrder],
    [DocumentDetectionDocumentTypeFromFileService.DocumentTypeDeliveryNote, DocumentDetectionDocumentType.DocumentTypeDeliveryNote],
]);
const documentDetectionStateFromBackend: Map<VersionDocumentDetectionStateFromFileService, VersionDocumentDetectionState> = new Map<VersionDocumentDetectionStateFromFileService, VersionDocumentDetectionState>([
    [VersionDocumentDetectionStateFromFileService.VersionDocumentDetectionStateNone, VersionDocumentDetectionState.VersionDocumentDetectionStateNone],
    [VersionDocumentDetectionStateFromFileService.VersionDocumentDetectionStateManual, VersionDocumentDetectionState.VersionDocumentDetectionStateManual],
    [VersionDocumentDetectionStateFromFileService.VersionDocumentDetectionStateAutomatic, VersionDocumentDetectionState.VersionDocumentDetectionStateAutomatic],
]);
export type FileVersionTypeFromFileBackend = {
    created_at?: string,
    deleted_at?: string,
    document_id: number,
    file_deleted: boolean,
    has_thumbnail: boolean,
    hash: string,
    id: number,
    number: number,
    size: number,
    updated_at?: string,
    user_id?: number,
    document_detection_data?: DetectionResultData,
    document_detection_type?: DocumentDetectionDocumentTypeFromFileService,
    document_detection_state?: VersionDocumentDetectionStateFromFileService,
};
export const DocumentDetectionDocumentTypeNames = new Map<DocumentDetectionDocumentType, string>([
    [DocumentDetectionDocumentType.DocumentTypeUnknown, 'unbekannt'],
    [DocumentDetectionDocumentType.DocumentTypeInvoice, 'Rechnung'],
    [DocumentDetectionDocumentType.DocumentTypeOffer, 'Angebot'],
    [DocumentDetectionDocumentType.DocumentTypeReceipt, 'Quittung'],
    [DocumentDetectionDocumentType.DocumentTypeOrder, 'Bestellung'],
    [DocumentDetectionDocumentType.DocumentTypeDeliveryNote, 'Lieferschein'],
])
export const DocumentDetectionStateNames = new Map<VersionDocumentDetectionState, string>([
    [VersionDocumentDetectionState.VersionDocumentDetectionStateNone, 'keine'],
    [VersionDocumentDetectionState.VersionDocumentDetectionStateManual, 'manuel'],
    [VersionDocumentDetectionState.VersionDocumentDetectionStateAutomatic, 'automatisch'],
])
export class VersionEntity {
    constructor(
        public Id: number = null,
        public Number: number = null,
        public Size: number = null,
        public Hash: string = null,
        public UserId: number = null,
        public HasThumbnail: boolean = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DocumentId: number = null,
        public DocumentDetectionData: DetectionResultData = null,
        public DocumentDetectionType: DocumentDetectionDocumentType = null,
        public DocumentDetectionState: VersionDocumentDetectionState = null,
        public readonly parentDocumentId: number = null,
    ) {

    }
    public static readonly GqlFields = 'created_At document_Id hash id number size updated_At user_Id has_Thumbnail document_Id document_detection_data';
    get DocumentDetectionDocumentTypeName() {
        return this.DocumentDetectionType ? DocumentDetectionDocumentTypeNames.get(this.DocumentDetectionType) || 'unbekannt' : null;
    }
    get DocumentDetectionStateName() {
        return this.DocumentDetectionState ? DocumentDetectionStateNames.get(this.DocumentDetectionState) || 'unbekannt' : null;
    }
    public Clone(override: Partial<VersionEntity> = {}): VersionEntity {
        const attr = { ...this, ...override };
        return Object.assign(new VersionEntity(), attr);
    }

    public GetDownloadLink(token: string) {
        return `/files/download/${this.Id}?document_id=${this.parentDocumentId}&token=${token}`;
    }

    public GetThumbnailLink(token: string) {
        return `/files/thumbnail/${this.Id}?document_id=${this.parentDocumentId}&token=${token}`;
    }
}
export function VersionEntityFromBackend(res: Version, parentDocumentId: number): VersionEntity {
    return new VersionEntity(
        +res.Id,
        res.Number,
        isNotNullOrUndefined(res.Size) ? +res.Size : null,
        res.Hash,
        res.UserId ? +res.UserId : null,
        res.HasThumbnail,
        GetTimeFromTimestamp(+res.CreatedAt),
        GetTimeFromTimestamp(+res.UpdatedAt),
        +res.DocumentId,
        res.DocumentDetectionData,
        res.DocumentDetectionType,
        res.DocumentDetectionState,
        parentDocumentId
    )
}
export function VersionEntityFromLegacyBackend(res: FileVersionType, parentDocumentId: number): VersionEntity {
    console.log({res})
    return new VersionEntity(
        res.id,
        res.number,
        res.size,
        res.hash,
        res.user_Id,
        res.has_Thumbnail,
        BackendDateTimestamp(res.created_At),
        BackendDateTimestamp(res.updated_At),
        res.document_Id,
        res.document_detection_data && JSON.parse(res.document_detection_data),
        res.document_detection_type as any,
        res.document_detection_state as any,
        parentDocumentId,
    );
}

export function VersionEntityFromFileBackend(res: FileVersionTypeFromFileBackend, parentDocumentId: number): VersionEntity {
    return new VersionEntity(
        res.id,
        res.number,
        res.size,
        res.hash,
        res.user_id,
        res.has_thumbnail,
        BackendDateTimestamp(res.created_at),
        BackendDateTimestamp(res.updated_at),
        res.document_id,
        res.document_detection_data,
        res.document_detection_type ? documentDetectionTypeFromBackend.get(res.document_detection_type) : null,
        res.document_detection_state ? documentDetectionStateFromBackend.get(res.document_detection_state) : null,
        parentDocumentId,
    );
}
