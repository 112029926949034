import { FolderUserType } from '../graphql-types';

export class FolderUserEntity {
    public static readonly GqlFields = 'edit folder_id from_child from_document from_id from_parent from_type role_id user_id';
    public Inherited = false;

    constructor(
        public FolderId: number = null,
        public UserId: number = null,
        public FromDocument: number = null,
        public FromParent: number = null,
        public FromChild: number = null,
        public FromType: string = null,
        public FromId: number = null,
        public RoleId: number = null,
        public Edit: boolean = null,
    ) {
        if (FromChild !== 0 || FromDocument !== 0 || FromParent !== 0 || FromType !== '' || FromId !== 0) {
            this.Inherited = true;
        }
    }

    public Clone(override: FolderUserEntityEntityAttributes = {}): FolderUserEntity {
        const attr = {...this, ...override};
        return Object.assign(new FolderUserEntity(), attr);
    }
}

export function FolderUserEntityFromBackend(res: FolderUserType): FolderUserEntity {
    return new FolderUserEntity(
        res.folder_id,
        res.user_id,
        res.from_document,
        res.from_parent,
        res.from_child,
        res.from_type,
        res.from_id,
        res.role_id,
        res.edit,
    );
}

export interface FolderUserEntityEntityAttributes {
    FolderId?: number;
    UserId?: number;
    FromDocument?: number;
    FromParent?: number;
    FromChild?: number;
    FromType?: string;
    FromId?: number;
    RoleId?: number;
    Edit?: boolean;
}
