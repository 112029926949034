    <h1 mat-dialog-title>
        <fa-icon icon="history"></fa-icon>
        Ereignis anlegen
    </h1>

    <form class="form-wrapper" [formGroup]="NewEventForm" (ngSubmit)="Submit()">
        <mat-dialog-content>
        <form [formControl]="this.NewEventForm.controls.EventTypeId" (ngSubmit)="($event.preventDefault)" name="fieldName" ngDefaultControl>
            <app-event-types-selector-card
                [EventTypeList]="EventTypeList"
                [CurrentEventType]="this.NewEventForm.value.EventTypeId"
                (EventTypeChange)="this.NewEventForm.controls.EventTypeId.setValue($event)"
            ></app-event-types-selector-card>
        </form>

        <app-select-search-legacy class="hover-form" [Values]="Customers" [Placeholder]="CS.GetSingle$() | async" [Control]="NewEventForm.controls.CustomerId"></app-select-search-legacy>

        <div class="contactPersonWrapper">
            <app-select-search-legacy
                class="hover-form"
                [Values]="PersonList"
                [Placeholder]="this.Customer ? this.Customer?.AnsprechpartnerText() : 'Ansprechpartner / Sachbearbeiter'"
                [Control]="NewEventForm.controls.PersonId"
            ></app-select-search-legacy>
            <span class="button">
                <button
                    type="button"
                    app-round-button
                    class="button"
                    [Disabled]="Customer?.Id == null"
                    [matTooltip]="(this.Customer ? this.Customer?.AnsprechpartnerText() : 'Ansprechpartner / Sachbearbeiter') + ' hinzufügen'"
                    (click)="NewContactPerson()"
                >
                    <fa-icon icon="plus"></fa-icon>
                </button>
            </span>
        </div>

        <div>
            <br /><span><fa-icon icon="map-marker-alt"></fa-icon> Adresse</span>
            <br />
            <mat-form-field class="form-field full-width hover-form">
                <input matInput class="" placeholder="Straße" type="text" [formControl]="NewEventForm.controls.Street" />
            </mat-form-field>
            <br />
            <mat-form-field class="form-field full-width hover-form">
                <input matInput class="inputStreet" placeholder="Postleitzahl" type="text" [formControl]="NewEventForm.controls.PostalCode" />
            </mat-form-field>
            <br />
            <mat-form-field class="form-field full-width hover-form">
                <input matInput class="inputStreet" placeholder="Ort" type="text" [formControl]="NewEventForm.controls.City" />
            </mat-form-field>
            <br />
            <mat-form-field class="form-field full-width hover-form">
                <input matInput class="inputStreet" placeholder="Land" type="text" [formControl]="NewEventForm.controls.Country" />
            </mat-form-field>
        </div>

            <div class="date-time-picker">
                <mat-form-field>
                    <mat-label>Datum</mat-label>
                    <mat-date-range-input [rangePicker]="picker" required>
                        <input matStartDate placeholder="Start" [formControl]="NewEventForm.controls.EventDate" required>
                        <input matEndDate placeholder="Ende" [formControl]="NewEventForm.controls.EventEndDate" required>
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

                <mat-form-field *ngIf="!NewEventForm.value.EventEndDate || (NewEventForm.value.EventDate && sameDay(NewEventForm.value.EventDate.toDate(), NewEventForm.value.EventEndDate.toDate()))">
                    <mat-label>Zeitraum</mat-label>
                    <app-time-span-picker-select>
                        <app-time-picker-select appStartTime required placeholder="Start" [formControl]="NewEventForm.controls.EventStartTimeString"></app-time-picker-select>
                        <app-time-picker-select appEndTime required placeholder="Ende" [min]="NewEventForm.value.EventStartTimeString" [offsetTimeForOptionLabel]="NewEventForm.value.EventStartTimeString" [formControl]="NewEventForm.controls.EventEndTimeString"></app-time-picker-select>
                    </app-time-span-picker-select>
                </mat-form-field>
            </div>
<!--        <div class="date-time-picker">-->
<!--            <app-datepicker Key="Startdatum" [Value]="NewEventForm.controls.EventDate.value" (Input)="OnDateChange($event.value)" [Required]="true"></app-datepicker>-->

<!--            <mat-form-field>-->
<!--                <input class="hide-icon-from-browser" matInput type="time" placeholder="Start" [formControl]="NewEventForm.controls.EventStartTimeString" required #inp/>-->
<!--                <fa-icon class="picker-icon" icon="clock" (click)="OpenPicker(inp)" matSuffix></fa-icon>-->
<!--            </mat-form-field>-->
<!--        </div>-->
<!--        <div class="date-time-picker">-->
<!--            <app-datepicker Key="Enddatum" [Value]="NewEventForm.controls.EventEndDate.value" (Input)="OnEndDateChange($event.value)" [Required]="true"></app-datepicker>-->

<!--            <mat-form-field>-->
<!--                <input matInput type="time" class="hide-icon-from-browser" placeholder="Ende" [formControl]="NewEventForm.controls.EventEndTimeString" required #inp2/>-->
<!--                <fa-icon class="picker-icon" icon="clock" (click)="OpenPicker(inp2)" matSuffix></fa-icon>-->
<!--            </mat-form-field>-->
<!--        </div>-->

        <app-select-search-legacy class="hover-form" [Values]="Commissions" [Placeholder]="cls.getSingle$('Commission') | async" [Control]="NewEventForm.controls.CommissionId"></app-select-search-legacy>

        <app-textarea Key="Notiz" [Value]="NewEventForm.controls.Note"></app-textarea>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="buttons">
                <button Color="cancel" type="button" Inverted app-button class="button" mat-dialog-close>
                    Abbrechen
                </button>

                <button app-button class="button" Color="green" [IsLoading]="ls.IsLoading$ | async" [Disabled]="NewEventForm.invalid" (click)="Submit()">
                    Speichern
                </button>
            </div>
        </mat-dialog-actions>
    </form>
