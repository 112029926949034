import { CommonModule } from '@angular/common';
import { Component,HostListener,Input,OnDestroy } from '@angular/core';
import { FormControl,FormGroup,ReactiveFormsModule,UntypedFormBuilder,Validators,ɵFormGroupValue } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResolveData, Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest,firstValueFrom,lastValueFrom,Observable,of,Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter,map,shareReplay,skip,startWith,switchMap,switchMapTo,take,tap,withLatestFrom } from 'rxjs/operators';
import { CustomerTypeEntity } from '../../../../dave-data-module/entities/customer-type.entity';
import { CustomerEntity, CustomerStatusEnum,TaxTypeNames,TaxTypes } from '../../../../dave-data-module/entities/customer.entity';
import { CustomerSpecificationTypeEntity } from '../../../../dave-data-module/entities/customerSpecificationType.entity';
import { MandateTypeEntity,MandateTypeNames } from '../../../../dave-data-module/entities/mandate-type.entity';
import { PartnerOfficeEntity } from '../../../../dave-data-module/entities/partnerOffice.entity';
import { Person2EntityEntityTypeEnum } from '../../../../dave-data-module/entities/person2entity.entity';
import { CustomerResolver } from '../../../../dave-data-module/guards/customer.resolver';
import { CustomerSpecificationTypeResolver } from '../../../../dave-data-module/guards/customerSpecificationType.resolver';
import { MandateTypeResolver } from '../../../../dave-data-module/guards/mandate-type.resolver';
import { PartnerOfficeResolver } from '../../../../dave-data-module/guards/partner-office.resolver';
import { ComponentCanDeactivate } from '../../../../dave-data-module/guards/pending-changes.guard';
import { PersonResolver } from '../../../../dave-data-module/guards/person.resolver';
import { State } from '../../../../dave-data-module/State';
import { CustomerActionTypes } from '../../../../dave-data-module/State/actions/customers.actions';
import { PersonActionTypes } from '../../../../dave-data-module/State/actions/person.actions';
import { Person2EntityActionTypes } from '../../../../dave-data-module/State/actions/person2entity.action';
import { customersFeatureKey } from '../../../../dave-data-module/State/reducers/customers.reducer';
import { customerSpecificationTypesFeatureKey } from '../../../../dave-data-module/State/reducers/customerSpecificationType.reducer';
import { MANDATE_TYPE_KEY } from '../../../../dave-data-module/State/reducers/mandate-type.reducer';
import { personsFeatureKey } from '../../../../dave-data-module/State/reducers/person.reducer';
import { getCustomers } from '../../../../dave-data-module/State/selectors/customers.selectors';
import { getCustomerSpecificationTypes } from '../../../../dave-data-module/State/selectors/customerSpecificationType.selectors';
import { getMandateTypes,getMandateTypesFetched } from '../../../../dave-data-module/State/selectors/mandate-types.selectors';
import {
    getOwnPartnerOffices, getPartner,
    getPartnerFetched,
    getPartnerOfficesFetched,
} from '../../../../dave-data-module/State/selectors/partners.selectors';
import { getPersons } from '../../../../dave-data-module/State/selectors/person.selectors';
import { getUser } from '../../../../dave-data-module/State/selectors/users.selectors';
import { AppButtonModule } from '../../../../dave-utils-module/app-button-module/app-button.module';
import { AppConfirmationDialogComponent,AppConfirmationDialogData, AppConfirmationDialogReturnData } from '../../../../dave-utils-module/app-dialog-module/app-confirmation-dialog/app-confirmation-dialog.component';
import { DaveSharedComponentsModule } from '../../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { CustomerNameService } from '../../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { GoogleMapsService } from '../../../../dave-utils-module/dave-shared-components-module/services/google-maps.service';
import { RoutingHistoryService } from '../../../../dave-utils-module/dave-shared-components-module/services/routing-history.service';
import { SelectSearchModule } from '../../../../dave-utils-module/select-search/select-search.module';
import {
DetailListTemplateDialogModule
} from '../../../../detail-list-template-dialog/detail-list-template-dialog.module';
import { isNotNullOrUndefined, SearchQueriesDebounceTime, stringSearch } from '../../../../helper/helper';
import { decimalValidator,phoneNumberValidator } from '../../../../helper/validation.helper';
import { CustomerTypeChipAutocompleteComponent } from '../../../templates/customer-type-chip-autocomplete/customer-type-chip-autocomplete.component';
import { AccountTypeEnum, ChartOfAccountEntity } from '../../../../dave-data-module/entities/chart-of-Account.entity';
import {
    getChartOfAccounts,
    getChartOfAccountsFetched,
} from '../../../../dave-data-module/State/selectors/chartOfAccount.selectors';
import {
    SelectSearchOption
} from '../../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { ChartOfAccountResolver } from '../../../../dave-data-module/guards/chartOfAccount.resolver';
import {
    DetailListDialogReturn,
    DetailListTemplateDialogComponent,
    DetailListTemplateDialogData,
} from '../../../../detail-list-template-dialog/components/detail-list-template-dialog.component';
import { ChartOfAccountActionTypes } from '../../../../dave-data-module/State/actions/chartOfAccount.actions';
import { Actions, ofType } from '@ngrx/effects';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { getFetched$ } from '../../../../dave-data-module/helper/helper';

export type newCustomerDefaultValues = {
    name?: string;
    firstName?:string;
    lastName?:string;
    customer?: any;
    office?: any;
};
@Component({
    selector: 'app-new-customer-main',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SelectSearchModule,
        DaveSharedComponentsModule,
        MatSlideToggleModule,
        FontAwesomeModule,
        MatTooltipModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatSelectModule,
        CustomerTypeChipAutocompleteComponent,
        DetailListTemplateDialogModule,
        AppButtonModule,
    ],
    templateUrl: './new-customer-main.component.html',
    styleUrls: ['./new-customer-main.component.scss'],
})
export class NewCustomerMainComponent implements OnDestroy, ComponentCanDeactivate {
    public static readonly RequiredResolvers: ResolveData = {
        [customerSpecificationTypesFeatureKey]: CustomerSpecificationTypeResolver,
        partnerOffice: PartnerOfficeResolver,
        [MANDATE_TYPE_KEY]: MandateTypeResolver,
        [customersFeatureKey]: CustomerResolver,
        [personsFeatureKey]: PersonResolver,
    };

    /** (NN) Die Werte, die im Template verwendet werden */
    public Data$: Observable<{
        // AreFormsInvalid: boolean;
        MandateTypes: MandateTypeEntity[];
    }>;

    /** Das eventuell hochzuladende Profilbild des anzulegenden Kunden */
    public CustomerImageFile?: File;

    /**
     * FormControl für den Namen des Unternehmens.
     *
     * Ausgegliedert in eine alleinstehende FormControl, da somit die Validierung ohne schwer zu
     * debuggende Aufrufe von `FormControl.disable()` und `FormControl.enable()` vonstattengeht.
     */
    public NameForm = this.fb.control(null, [Validators.required]) as FormControlTyped<string | null>;
    public FirstnameForm = new FormControl<string | null>(null) ;
    public LastNameForm = new FormControl<string >(null , Validators.required) ;
    public CustomerTypesForm = this.fb.control([]) as FormControlTyped<CustomerTypeEntity[]>;
    public isPrivatePerson : boolean = false ;

    public TaxTypes: { value: TaxTypes; name: string }[] = Object.values(TaxTypes).map((value) => ({
        value,
        name: TaxTypeNames.get(value),
    }));

    /** FormGroup für die Kundendaten */
    public CustomerForm = new FormGroup({
        CustomerId: new FormControl<string | null>(null),
        AutomaticCustomerId: new FormControl<boolean>(false),
        Description: new FormControl<string | null>(null),
        Sales: new FormControl<number | null>(null),
        EmployeeNumber: new FormControl<number | null>(null),
        PartnerOfficeId: new FormControl<PartnerOfficeEntity | null>(null),
        Homepage: new FormControl<string | null>(null),
        MandateTypeId: new FormControl<number | null>(null),
        CustomerSpecificationType: new FormControl<CustomerSpecificationTypeEntity | null>(null, Validators.required),
        Gerichtsort: new FormControl<string | null>({ value: null, disabled: true }),
        AutomaticAccountsReceivableLedger: new FormControl<boolean>(false),
        UstId: new FormControl<string | null>(null),
        Steuernummer: new FormControl<string | null>(null),
        DebitorNo: new FormControl<string | null>(null),
        TaxType: new FormControl<TaxTypes | null>(null),
        Sachkonto: new FormControl<SelectSearchOption<ChartOfAccountEntity> | null>(null),
        Konto: new FormControl<SelectSearchOption<ChartOfAccountEntity> | null>(null),
        PaymentTargetInDays: new FormControl<number | null>(null, decimalValidator()),
        PaymentDiscount: new FormControl<number | null>(null, [Validators.min(0), Validators.max(100), decimalValidator(4)]),
    });

    public CustomerIdForm = this.CustomerForm.controls.CustomerId;
    public DescriptionForm = this.CustomerForm.controls.Description;
    public HomepageForm = this.CustomerForm.controls.Homepage;
    public MandateTypeIdForm = this.CustomerForm.controls.MandateTypeId;
    public UstIdForm = this.CustomerForm.controls.UstId;
    public SteuernummerForm = this.CustomerForm.controls.Steuernummer;
    public SachkontochartOfAccounts: Array<ChartOfAccountEntity & { optionLabel: string }>;
    public KontochartOfAccounts: Array<ChartOfAccountEntity & { optionLabel: string }>;
    public ChartOfAccounts : Array<ChartOfAccountEntity>;

    /** FormGroup für die Standortdaten */
    private officeForm = new FormGroup({
        PhoneNumber: new FormControl<string | null>(null, phoneNumberValidator()),
        Email: new FormControl<string | null>(null, Validators.email),
        InvoiceEmail: new FormControl<string | null>(null, Validators.email),
        Street: new FormControl<string | null>(null),
        PostalCode: new FormControl<string | null>(null),
        City: new FormControl<string | null>(null),
        Country: new FormControl<string | null>(null),
        FaxNumber: new FormControl<string | null>(null),
        MobileNumber: new FormControl<string | null>(null),
        IBAN: new FormControl<string | null>(null),
        Swift: new FormControl<string | null>(null),
        BankName: new FormControl<string | null>(null),
        BankLocation: new FormControl<string | null>(null),
        BankPostalCode: new FormControl<string | null>(null),
        BankSortCode: new FormControl<string | null>(null),
    });

    public PhoneNumberForm = this.officeForm.controls.PhoneNumber;
    public EmailForm = this.officeForm.controls.Email;
    public InvoiceEmailForm = this.officeForm.controls.InvoiceEmail;
    public StreetForm = this.officeForm.controls.Street;
    public PostalCodeForm = this.officeForm.controls.PostalCode;
    public CityForm = this.officeForm.controls.City;
    public CountryForm = this.officeForm.controls.Country;
    public FaxNumberForm = this.officeForm.controls.FaxNumber;
    public MobileNumberForm = this.officeForm.controls.MobileNumber;
    public IBANForm = this.officeForm.controls.IBAN;
    public SwiftForm = this.officeForm.controls.Swift;
    public BankNameForm = this.officeForm.controls.BankName;
    public BankLocationForm = this.officeForm.controls.BankLocation;
    public BankPostalCodeForm = this.officeForm.controls.BankPostalCode;
    public BankSortCodeForm = this.officeForm.controls.BankSortCode;

    @Input() set PreventNewPersonDialog(value) {
        this.preventNewPersonDialog = coerceBooleanProperty(value);
    }
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_PreventNewPersonDialog: BooleanInput;
    private preventNewPersonDialog = false;
    @Input() set DefaultValues(value: newCustomerDefaultValues) {
        if (value.name) {
            this.NameForm.setValue(value.name);
        }
        if (value.firstName){
            this.FirstnameForm.setValue(value.firstName);
        }
        if (value.lastName){
            this.LastNameForm.setValue(value.lastName);
        }
        if (value.customer) {
            for (const k in value.customer) {
                if (value.customer[k] !== undefined) {
                    this.CustomerForm.controls[k].setValue(value.customer[k]);
                }
            }
        }
        if (value.office) {
            for (const k in value.office) {
                if (value.office[k] !== undefined) {
                    this.officeForm.controls[k].setValue(value.office[k]);
                }
            }
        }
    }

    public CustomerSpecificationtypes$ = this.store.select(getCustomerSpecificationTypes);
    private subscriptions: Subscription[] = [];
    public PartnerOffices$ = this.store.select(getPartnerOfficesFetched).pipe(
        filter((fetched) => fetched),
        switchMap(() => this.store.select(getOwnPartnerOffices)),
    );
    public Customer$ = new BehaviorSubject<CustomerEntity | null>(null);
    public OtherCustomerWithSameNumber$: Observable<CustomerEntity[] | undefined> = this.Customer$.pipe(
        map((c) => c?.Id),
        distinctUntilChanged(),
        switchMap((customerId) =>
            this.store.select(getCustomers).pipe(
                map((customers) => {
                    const customerNumberMap: { [key: string]: CustomerEntity[] } = {};
                    customers.forEach((c) => {
                        if (c.CustomerNo && c.Id !== customerId) {
                            if (!customerNumberMap[c.CustomerNo]) {
                                customerNumberMap[c.CustomerNo] = [];
                            }
                            customerNumberMap[c.CustomerNo].push(c);
                        }
                    });
                    return customerNumberMap;
                }),
            ),
        ),
        switchMap((customerNumberMap) =>
            this.CustomerForm.controls.CustomerId.valueChanges.pipe(
                startWith(this.CustomerForm.controls.CustomerId.value),
                debounceTime(SearchQueriesDebounceTime),
                distinctUntilChanged(),
                map((number: string) => customerNumberMap[(number || '').trim()]),
            ),
        ),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    /** (NN) Ob die Eingabefelder Validierungsfehler enthalten */
    public AreFormsInvalid$ = combineLatest([
        // startWith(null) produziert deprecation-Warnungen, weil wir kein Null Checking haben...
        this.NameForm.statusChanges.pipe(startWith('')),
        this.CustomerForm.statusChanges.pipe(startWith('')),
        this.officeForm.statusChanges.pipe(startWith('')),
    ]).pipe(
        map(() => this.CustomerForm.invalid || this.officeForm.invalid || this.NameForm.invalid),
        shareReplay(1),
    );

    public CompareSelectSearchChartOfAccountsOptions = (a: ChartOfAccountEntity & SelectSearchOption, b: ChartOfAccountEntity & SelectSearchOption) => a.Id === b.Id;

    public SelectSearchChartOfAccountsOptionsFunction = (search: string, option: ChartOfAccountEntity) => [option.Name, option.Number.toString()].some((v) => v && stringSearch(v, search));

    constructor(
        private fb: UntypedFormBuilder,
        private googleGeocoderService: GoogleMapsService,
        private routingHistoryService: RoutingHistoryService,
        private store: Store<State>,
        private dialog: MatDialog,
        public CS: CustomerNameService,
        protected chartOfAccountResolver: ChartOfAccountResolver,
        private actions$: Actions,
    ) {
        firstValueFrom(getFetched$(this.store, getPartnerFetched, getPartner)).then(partner => {
            const autoCustomerNumber = !!partner.AdditionalData?.createCustomerNumberAutomaticDefault;
            if (autoCustomerNumber !== this.CustomerForm.value.AutomaticCustomerId) {
                this.CustomerForm.controls.AutomaticCustomerId.setValue(autoCustomerNumber);
            }
        })
        /** (NN) Ob der zu erstellende Kunde eine Privatperson (statt eines Unternehmens) ist */
        this.Data$ = combineLatest([
            // areFormsInvalid$,
            this.store.select(getMandateTypes),
        ]).pipe(
            map(([MandateTypes]) => ({
                // AreFormsInvalid,
                MandateTypes,
            })),
        );
        firstValueFrom(
            this.store.select(getMandateTypesFetched).pipe(
                filter((f) => f),
                switchMap(() => this.store.select(getMandateTypes)),
            ),
        ).then((MandateType) => {
            const defaultType = MandateType.find((mt) => mt.Name === MandateTypeNames.debitor);
            this.MandateTypeIdForm.setValue(defaultType.Id);
        });

        firstValueFrom(this.store.select(getChartOfAccountsFetched)).then((fetched) => {
            if (!fetched) {
                chartOfAccountResolver.resolve();
            }
        });

        this.subscriptions.push(
            this.PartnerOffices$.pipe(
                map((partners) => {
                    let partnerValues = {};

                    partnerValues['-'] = 'keinen auswählen';

                    partners.forEach((partner) => {
                        partnerValues[partner.Id.toString()] = partner.DisplayName;
                    });
                    return partnerValues;
                }),
            ).subscribe(),
            this.CustomerForm.controls.CustomerSpecificationType
                .valueChanges.pipe(filter(isNotNullOrUndefined))
                .subscribe((v) => {
                    if (v.Name.toLocaleLowerCase().includes('gericht') && this.CustomerForm.controls.Gerichtsort.disabled) {
                        this.CustomerForm.controls.Gerichtsort.enable();
                    } else if (!v.Name.toLocaleLowerCase().includes('gericht') && this.CustomerForm.controls.Gerichtsort.enabled) {
                        this.CustomerForm.controls.Gerichtsort.disable();
                        this.CustomerForm.controls.Gerichtsort.setValue(null);
                    }
                }),
            this.CustomerForm.controls.AutomaticCustomerId.valueChanges.subscribe((v) => {
                if (v && this.CustomerForm.controls.CustomerId.enabled) {
                    this.CustomerForm.controls.CustomerId.disable();
                    this.CustomerForm.controls.CustomerId.setValue(null);
                } else if (!v && this.CustomerForm.controls.CustomerId.disabled) {
                    this.CustomerForm.controls.CustomerId.enable();
                }
            }),
            this.CustomerForm.controls.CustomerSpecificationType.valueChanges.subscribe(value => {
                if (value && value.Name) {
                    this.isPrivatePerson = value.Name.toLowerCase() === 'privatperson';
                    if (this.isPrivatePerson) {
                        this.NameForm.clearValidators();
                    } else {
                        this.NameForm.setValidators(Validators.required);
                    }
                    this.NameForm.updateValueAndValidity();
                } })
        );

        this.subscriptions.push(
            this.store.select(getChartOfAccountsFetched).pipe(
                filter((f) => f),
                switchMap(() => this.store.select(getChartOfAccounts).pipe(map(ca=> ca.filter(p=> !p.DeletedAt)))),
            ).subscribe((chartAcc) => {
                this.SachkontochartOfAccounts = chartAcc.filter(ca=> ca.AccountType === AccountTypeEnum.Contra).map((r) => Object.assign(r.Clone()));
                this.KontochartOfAccounts = chartAcc.filter(ca=> ca.AccountType === AccountTypeEnum.Normal).map((r) => Object.assign(r.Clone()));
                this.ChartOfAccounts = chartAcc.map((r) => Object.assign(r.Clone()));
            })
        );

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((v) => v.unsubscribe());
    }

    public Cancel() {
        this.routingHistoryService.NavigateBackwards();
    }

    public Submit(): Promise<number | null> {
        const _submit = () => {
            return firstValueFrom(this.AreFormsInvalid$).then((areFormsInvalid) => {
                if (areFormsInvalid) {
                    return firstValueFrom(of(null));
                }
                if (this.StreetForm.value && this.CityForm.value && this.CountryForm.value) {
                    const address = [this.StreetForm.value.trim(), this.PostalCodeForm.value.trim(), this.CityForm.value.trim(), this.CountryForm.value.trim()].join();
                    return firstValueFrom(this.googleGeocoderService.Geocode(address)).then(({ Latitude, Longitude }) => this.submit(Longitude, Latitude));
                } else {
                    return this.submit();
                }
            });
        }
        return firstValueFrom(this.OtherCustomerWithSameNumber$).then( (OtherCustomerWithSameNumber) => {
            if (OtherCustomerWithSameNumber?.length) {
                return firstValueFrom(this.dialog
                    .open<AppConfirmationDialogComponent, AppConfirmationDialogData, AppConfirmationDialogReturnData>(AppConfirmationDialogComponent, {
                        autoFocus: false,
                        data: {
                            heading: 'Kundennummer bereits vergeben',
                            paragraph: 'Die Kundennummer ist bereits vergeben, möchten Sie dennoch speichern ?',
                        },
                    })
                    .afterClosed()).then(([r, string]) => {
                        if (r) {
                           return _submit();
                        } else {
                           return null;
                        }
                    }
                )
            } else {
                return _submit();
            }
        });
    }

    private submit(Longitude: number = null, Latitude: number = null): Promise<any> {
        const values = {
            Name: this.NameForm.value,
            ...this.CustomerForm.value,
            ...this.officeForm.value,
        };
        return firstValueFrom(
            this.store.select(getUser).pipe(
                take(1),
                withLatestFrom(this.PartnerOffices$),
                tap(([user, ownPartnerOffices]) => {
                    return this.store.dispatch(
                        CustomerActionTypes.AddCustomerAndOffice({
                            Payload: {
                                partnerId: user.PartnerId,
                                name: this.isPrivatePerson ? (this.FirstnameForm?.value !== null ? this.FirstnameForm?.value + ' & ' + this.LastNameForm.value : this.LastNameForm.value) : values.Name,
                                customerNo: values.CustomerId,
                                description: values.Description,
                                gerichtsort: values.Gerichtsort,
                                customerSpecificationTypeId: values.CustomerSpecificationType.Id,
                                sales: values.Sales,
                                employeeNumber: values.EmployeeNumber,
                                customerTypeIds: this.CustomerTypesForm.value?.map((v) => v.Id),
                                homepage: values.Homepage,
                                mandateTypeIds: values.MandateTypeId ? [values.MandateTypeId] : [],
                                automaticCustomerNo: values.AutomaticCustomerId,
                                person: null,
                                automaticAccountsReceivableLedger: values.AutomaticAccountsReceivableLedger ? 1 : 2,
                                ustId: values.UstId,
                                steuernummer: values.Steuernummer,
                                partnerOfficeId: ownPartnerOffices?.length > 1 ? values.PartnerOfficeId.Id : ownPartnerOffices?.length ? ownPartnerOffices[0]?.Id : null,
                                debitorNo: values.DebitorNo,
                                taxType: values.TaxType as any,
                                customerStatus: CustomerStatusEnum.Aktiv,
                                sachkontoCOAId: this.CustomerForm.controls.Sachkonto?.value?.Id,
                                kontoCOAId: this.CustomerForm.value?.Konto?.Id,
                                paymentTargetInDays: this.CustomerForm.value.PaymentTargetInDays,
                                paymentDiscount: this.CustomerForm.value.PaymentDiscount,
                                office: {
                                    email: values.Email,
                                    invoiceEmail: values.InvoiceEmail,
                                    isHeadquarter: true,
                                    phoneNumber: values.PhoneNumber,
                                    street: values.Street,
                                    postalCode: values.PostalCode,
                                    city: values.City,
                                    country: values.Country,
                                    longitude: Longitude,
                                    latitude: Latitude,
                                    faxNumber: values.FaxNumber,
                                    mobileNumber: values.MobileNumber,
                                    iBAN: values.IBAN,
                                    swift: values.Swift,
                                    bankName: values.BankName,
                                    bankLocation: values.BankLocation,
                                    bankPostalCode: values.BankPostalCode,
                                    bankSortCode: values.BankSortCode,
                                },

                            },
                        }),
                    );
                }),
                // Warten, bis es durch die API-Antwort auf AddCustomerAndOffice neue Offices gibt
                switchMapTo(this.store.select(getCustomers)),
                skip(1),
                take(1),
                // Nach erfolgreichem Anlegen: das Bild (falls vorhanden) hochladen
                map((customers) => {
                    const latestCustomerId = customers.reduce((id, customer) => (customer.Id > id ? customer.Id : id), -1);
                    return latestCustomerId;
                }),
                // zu neu angelegtem Kunden routen
                switchMap((latestCustomerId) => {
                    return new Promise<number>((resolve) => {
                        if (!this.preventNewPersonDialog && values.CustomerSpecificationType.Name.toLowerCase() == 'privatperson') {
                            lastValueFrom(
                                this.dialog
                                    .open<AppConfirmationDialogComponent, AppConfirmationDialogData>(AppConfirmationDialogComponent, {
                                        data: {
                                            heading: 'Möchten Sie auch einen Kontakt anlegen?',
                                            noButton: 'Nein, weiter',
                                            // styleDelete: true,
                                        },
                                    })
                                    .afterClosed(),
                            ).then(([res]) => {
                                if (res) {
                                    this.store.dispatch(
                                        PersonActionTypes.AddPerson({
                                            Payload: {
                                                firstname: this.FirstnameForm.value ? this.FirstnameForm.value : null,
                                                lastname: this.LastNameForm?.value,
                                                email: values.Email,
                                                phonenumber: values.PhoneNumber,
                                                mobilenumber: values.MobileNumber,
                                                faxnumber: values.FaxNumber,
                                                street: values.Street,
                                                postalCode: values.PostalCode,
                                                city: values.City,
                                                country: values.Country,
                                            },
                                        }),
                                    );
                                    lastValueFrom(this.store.select(getPersons).pipe(skip(1), take(1))).then((persons) => {
                                        let personId = persons[persons.length - 1].Id;
                                        this.store.dispatch(
                                            Person2EntityActionTypes.AddPerson2Entity({
                                                Payload: {
                                                    entityId: latestCustomerId,
                                                    personId: personId,
                                                    entityType: Person2EntityEntityTypeEnum.Customer,
                                                },
                                            }),
                                        );
                                        resolve(latestCustomerId);
                                    });
                                } else {
                                    resolve(latestCustomerId);
                                }
                            });
                        } else {
                            resolve(latestCustomerId);
                        }
                    });
                }),
                tap(() => {
                    this.CustomerForm.reset(this.CustomerForm.value);
                    this.officeForm.reset(this.officeForm.value);
                    this.NameForm.reset(this.NameForm.value);
                    this.FirstnameForm.reset();
                    this.LastNameForm.reset();
                }),
            ),
        );
    }

    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): Observable<boolean> | boolean {
        return !this.CustomerForm.dirty && !this.officeForm.dirty && !this.NameForm.dirty;
    }

    newChartOfAccountPopUp(entity?: ChartOfAccountEntity , accountType?: AccountTypeEnum) {

        const chartOfAccount =  entity ? this.ChartOfAccounts.find(chart=> chart.Id === entity.Id): null;

        const name = new FormControl<string>(chartOfAccount?.Name ? chartOfAccount.Name : '');
        const description = new FormControl<string>(chartOfAccount?.Description ? chartOfAccount.Description : '');
        const number = new FormControl<number>(chartOfAccount?.Number ? chartOfAccount.Number : null ,Validators.required);

        this.dialog
            .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                ...DetailListTemplateDialogComponent.DefaultConfig,
                data: {
                    DisableSaveButton$: number.statusChanges.pipe(
                        startWith(number.status),
                        map((state) => state !== 'VALID'),
                    ),
                    Editing: true,
                    DeleteButton: !!chartOfAccount,
                    Data: {
                        Headline: chartOfAccount ? (chartOfAccount.Name ? chartOfAccount.Name + ' bearbeiten' : 'Konto bearbeiten') : 'Neues Konto anlegen',
                        Properties: [
                            {
                                key: 'Name',
                                formControl: name,
                            },
                            {
                                key: 'Nummer',
                                formControl: number,
                                options: {
                                    specialInput: {
                                        number: true,
                                    }
                                }
                            },
                            {
                                key: 'Bezeichnung',
                                formControl: description,
                            },
                        ].filter(isNotNullOrUndefined),
                    },
                },
            })
            .afterClosed()
            .subscribe((ret)=> {
                if (ret.Action === 'delete') {
                    this.store.dispatch(ChartOfAccountActionTypes.Delete({ Payload: { id: chartOfAccount.Id } }))
                }
                if (ret.Action === 'save') {
                    if (!chartOfAccount) {
                        this.store.dispatch(ChartOfAccountActionTypes.Add({
                            Payload: {
                                description: description.value,
                                name: name.value,
                                number: number.value,
                                accountType: accountType,
                            }
                        }));
                        this.actions$
                            .pipe(ofType(ChartOfAccountActionTypes.AddSuccess))
                            .subscribe((res) => {
                                if (res.Payload ) {
                                    if (res.Payload.AccountType === AccountTypeEnum.Contra){
                                        this.CustomerForm.controls.Sachkonto.setValue(Object.assign(res.Payload));
                                    }
                                   if (res.Payload.AccountType === AccountTypeEnum.Normal){
                                       this.CustomerForm.controls.Konto.setValue(Object.assign(res.Payload));
                                   }
                                }
                            });
                    }
                    else {
                        this.store.dispatch(ChartOfAccountActionTypes.ChangeChartOfAccount({
                            Payload: {
                                id: chartOfAccount.Id,
                                description: description.value,
                                name: name.value,
                                number: number.value,
                                accountType:  chartOfAccount.AccountType,
                            }
                        }));
                        this.actions$
                            .pipe(ofType(ChartOfAccountActionTypes.ChangeSuccess))
                            .subscribe((res) => {
                                if (res.Payload ) {
                                    if (res.Payload.AccountType === AccountTypeEnum.Contra){
                                        this.CustomerForm.controls.Sachkonto.setValue(Object.assign(res.Payload));
                                    }
                                    if (res.Payload.AccountType === AccountTypeEnum.Normal){
                                        this.CustomerForm.controls.Konto.setValue(Object.assign(res.Payload));
                                    }
                                }
                            });
                    }
                }
            })

    }

    protected readonly AccountTypeEnum = AccountTypeEnum;
}
