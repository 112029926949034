import { Process } from '@dave/types/dist/proto/process/process';
import { ProcessEntity } from './process.entity';
import { User2Entity } from '@dave/types/dist/proto/global/entityPermissions';

export class UserToEntityEntity {
    constructor(public UserId: number = null, public EntityId: number = null, public RoleId: number = null, public AdditionalData: string = null, public FromEntity: string = null, public FromId: number = null) {}

}
export function UserToEntityEntityFromBackend(res: User2Entity): UserToEntityEntity {
    return new UserToEntityEntity(
        res.UserId && +res.UserId,
        res.EntityId && +res.EntityId,
        res.RoleId && +res.RoleId,
        res.AdditionalData,
        res.FromEntity,
        res.FromId && +res.FromId,
    );
}
