import { CustomerType, User2CommissionType, User2CustomerType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';

export const CustomerEntityGQLFields = `createdAt
customerNo
customerSpecificationTypeId
customerTypeList {
    id
}
deleted
description
employeeNumber
gerichtsort
homepage
hqLocation
id
imageId
mandateTypeIds
name
partnerId
ranking
rankingCount
sales
userRanking
canDelete
canEdit
canRoleEdit
automaticAccountsReceivableLedger
ustId
steuernummer
partnerOfficeId
debitorNo
taxType
updatedAt
customerStatus
sachkontoCOAId
kontoCOAId
paymentTargetInDays
paymentDiscount
`;
export enum TaxTypes {
    Netto = 'NETTO',
    Free = 'FREE',
    EGLeistung = 'EG_LEISTUNG',
    Brutto = 'BRUTTO',
    Steuerschuldumkehr = 'STEUERSCHULDUMKEHR',
}
export enum CustomerStatusEnum {
    Aktiv = 'AKTIV',
    Gesperrt = 'GESPERRT',
    Inaktiv = 'INAKTIV'
  }

export const CustomerStatusTypeNames: Map<CustomerStatusEnum, string> = new Map<CustomerStatusEnum, string>([
    [CustomerStatusEnum.Aktiv, 'Aktiv'],
    [CustomerStatusEnum.Gesperrt, 'Gesperrt'],
    [CustomerStatusEnum.Inaktiv, 'Inaktiv'],
]);

export const TaxTypeNames: Map<TaxTypes, string> = new Map<TaxTypes, string>([
    [ TaxTypes.Netto , 'Netto zzgl. MwSt.'],
    [ TaxTypes.Free , 'Steuerfrei'],
    [ TaxTypes.EGLeistung , 'EG - Leistung'],
    [ TaxTypes.Brutto , 'Brutto inkl. MwSt.'],
    [ TaxTypes.Steuerschuldumkehr , '§13B Steuerschuldumkehr'],
]);
export class CustomerEntity {
    public static EntityPropertyNames: Map<keyof CustomerEntity, string> = new Map([
        ['Name', 'Name'],
        ['Description', 'Beschreibung'],
        ['Sales', 'Umsatz'],
        ['EmployeeNumber', 'Anzahl Mitarbeiter'],
        ['Homepage', 'Homepage'],
        ['Steuernummer', 'Steuernummer'],
        ['DebitorNo', 'Debitorennummer'],
    ])
    constructor(
        public Id: number = null,
        public Name: string = null,
        public PartnerId: number = null,
        public CustomerNo: string = null,
        public Description: string = null,
        public CustomerSpecificationTypeId: number = null,
        public ImageId: number = null,
        public Deleted: boolean = false,
        public FirstName: string = null,
        public LastName: string = null,
        public HQLocation: string = null,
        public Gerichtsort: string = null,
        public Sales: number = null,
        public EmployeeNumber: number = null,
        public CustomerTypeIds: number[] = null,
        public Rating: number = null,
        public RatingCount: number = null,
        public UserRating: number = null,
        public Homepage: string = null,
        public MandateTypeId: number = null,
        public CreatedAt: Date = null,
        public CanEdit: boolean = null,
        public CanDelete: boolean = null,
        public CanRoleEdit: boolean = null,
        public UstId: string = null,
        public Steuernummer: string = null,
        public AutomaticAccountsReceivableLedger: number = null,
        public PartnerOfficeId: number = null,
        public DebitorNo: string = null,
        public TaxType: TaxTypes = null,
        public UpdatedAt: Date = null,
        public CustomerStatus: CustomerStatusEnum = null,
        public SachkontoCOAId: number = null,
        public KontoCOAId: number = null,
        public PaymentTargetInDays: number = null,
        public PaymentDiscount: number = null,
    ) {}

    get DisplayName() {
        return this.Name;
    }
    get DisplayInformation() {
        let info = this.CustomerNo ? this.CustomerNo : '';
        info = info && this.Name ? info + ' ' : info;
        info = this.Name ? info + this.Name : info;

        return info ? info : 'unbenannter Kunde';
    }
    public AnsprechpartnerText() {
        return this.CustomerSpecificationTypeId === 2 ? 'Sachbearbeiter' : 'Ansprechpartner';
    }

    public Clone(override: Partial<CustomerEntity> = {}): CustomerEntity {
        const attr = { ...this, ...override };
        return Object.assign(new CustomerEntity(), attr);
    }
}
export function CustomerEntityFromBackend(res: CustomerType): CustomerEntity {
    return new CustomerEntity(
        res.id,
        res.name,
        res.partnerId,
        res.customerNo,
        res.description,
        res.customerSpecificationTypeId,
        res.imageId,
        res.deleted,
        null, // ToDo aus Model entfernen
        null, // ToDo aus Model entfernen
        res.hqLocation,
        res.gerichtsort,
        res.sales,
        res.employeeNumber,
        (res.customerTypeList || []).map(c => c.id),
        res.ranking,
        res.rankingCount,
        res.userRanking,
        res.homepage,
        res.mandateTypeIds[0],
        BackendDate(res.createdAt),
        res.canEdit,
        res.canDelete,
        res.canRoleEdit,
        res.ustId,
        res.steuernummer,
        res.automaticAccountsReceivableLedger,
        res.partnerOfficeId,
        res.debitorNo,
        res.taxType as any,
        BackendDate(res.updatedAt),
        res.customerStatus,
        res.sachkontoCOAId,
        res.kontoCOAId,
        res.paymentTargetInDays,
        res.paymentDiscount,
    );
}
