import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BookmarkEntity, BookmarkEntityFromBackend } from '../../entities/bookmark.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { BookmarkActionTypes } from '../actions/bookmarks.actions';
import { ResolverActionTypes, ResolverLoadBookmarks } from '../actions/resolver.actions';
import { State } from '../index';
import { getBookmarkLastFetched, getBookmarks } from '../selectors/bookmarks.selectors';
import { EventsActionTypes } from '../actions/events.actions';

enum ErrorCodes {
    Add = 'Lesezeichen Hinzufügen fehlgeschlagen',
    Load = 'Lesezeichen Abrufen fehlgeschlagen',
    Remove = 'Lesezeichen Entfernen fehlgeschlagen',
}

@Injectable()
export class BookmarkEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
    ) {
    }


    UpdateBookmark$ = createEffect(() => this.actions$.pipe(
        ofType(EventsActionTypes.UpdateEvent),
        withLatestFrom(this.store$.select(getBookmarks)),
        map(([{Payload}, bookmarks]) => {
            const oldBookmark = bookmarks?.find(value => value.Event.Id === Payload.Id);
            if (oldBookmark) {
                this.store$.dispatch(BookmarkActionTypes.SetOne({Payload: oldBookmark.Clone({Event: Payload})}));
            }
        }),
    ), {dispatch: false});


    AddBookmark$ = createEffect(() => this.actions$.pipe(
        ofType(BookmarkActionTypes.AddBookmark),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                createEventBookmark(eventId: ${action.EventId}) {
                    ${BookmarkEntity.GQLFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({query: queryString}, {token: store.base.token}).pipe(
                map(res => {
                    return res && res.createEventBookmark && res.createEventBookmark.event
                        ? BookmarkActionTypes.SetOne({
                            Payload: BookmarkEntityFromBackend(res.createEventBookmark),
                        })
                        : BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Add}});
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    LoadBookmarks$ = createEffect(() => this.actions$.pipe(
        ofType(ResolverActionTypes.LoadBookmarks),
        withLatestFrom(this.store$),
        switchMap(([action, store]) => {
            const queryString = `
            query {
                eventBookmark {
                    ${BookmarkEntity.GQLFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({query: queryString}, {token: store.base.token, retry: true }).pipe(
                concatMap(res => {
                    if (res && res.eventBookmark) {
                        const bookmarks = res.eventBookmark.map(val => BookmarkEntityFromBackend(val));
                        let last = new Date();
                        last.setFullYear(1900, 1, 1);
                        bookmarks.forEach(e => {
                            if (e.CreatedAt > last) {
                                last = e.CreatedAt;
                                // console.warn(e.Id)
                            }
                        });
                        last = moment(last).utc().toDate();
                        const out: TypedAction<any>[] = [
                            BookmarkActionTypes.UpdateBookmarks({
                                NewBookmarks: bookmarks,
                            })
                        ];
                        if (bookmarks.length !== 0) {
                            out.push(BookmarkActionTypes.UpdateFetched({
                                Date: last
                            }));
                        }
                        return out;
                    } else {
                        return [
                            BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Load}})
                        ];
                    }
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    LoadBookmarksAfter$ = createEffect(() => this.actions$.pipe(
        ofType(ResolverActionTypes.ReLoadBookmarks),
        withLatestFrom(this.store$),
        withLatestFrom(this.store$.select(getBookmarkLastFetched)),
        switchMap(([[action, store], lastFetched]) => {
            if (lastFetched !== null) {
                const queryString = `
            query {
                eventBookmark(createdSince: "${moment(lastFetched).toISOString()}") {
                    ${BookmarkEntity.GQLFields}
                }
            }`;

                return this.gatewayHttpService.graphQl({query: queryString}, {token: store.base.token, retry: true }).pipe(
                    withLatestFrom(this.store$.select(getBookmarks)),
                    concatMap(([res, oldBookmarks]) => {
                        if (res && res.eventBookmark) {
                            const bookmarks = res.eventBookmark.map(val => BookmarkEntityFromBackend(val));
                            let last = new Date();
                            last.setFullYear(1900, 1, 1);
                            bookmarks.forEach(e => {
                                if (e.CreatedAt > last) {
                                    last = e.CreatedAt;
                                    // console.warn(e.Id)
                                }
                            });
                            last = moment(last).utc().toDate();
                            const out: TypedAction<any>[] = [
                                BookmarkActionTypes.UpdateBookmarks({
                                    NewBookmarks: [
                                        ...oldBookmarks.filter(e => !bookmarks.find(e2 => e.Id === e2.Id)),
                                        ...bookmarks
                                    ],
                                })
                            ];
                            if (bookmarks.length !== 0) {
                                out.push(BookmarkActionTypes.UpdateFetched({
                                    Date: last
                                }));
                            }
                            return out;
                        } else {
                            return [
                                BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Load}})
                            ];
                        }
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Load,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            } else {
                return of(new ResolverLoadBookmarks());
            }
        }),
    ));


    RemoveBookmark$ = createEffect(() => this.actions$.pipe(
        ofType(BookmarkActionTypes.RemoveBookmark),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
            mutation {
                deleteEventBookmark(ids: ${JSON.stringify(action.BookmarkIds)})
            }`;

            return this.gatewayHttpService.graphQl({query: queryString}, {token: store.base.token}).pipe(
                withLatestFrom(this.store$.select(getBookmarks)),
                map(([res, bookmarks]) =>
                    res && res.deleteEventBookmark
                        ? BookmarkActionTypes.RemoveMany({
                            Payload: bookmarks.filter(b => action.BookmarkIds.includes(b.Id)).map(b => b.EventId),
                        })
                        : BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Remove}}),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
}
