import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { BusinessVolumeActionTypes } from '../State/actions/business-volume.actions';
import { getBusinessVolumeFetched, getBusinessVolumeUpdatedAt } from '../State/selectors/business-volume.selector';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class BusinessVolumeResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getBusinessVolumeFetched, getBusinessVolumeUpdatedAt, BusinessVolumeActionTypes.Load, store);
    }
}
