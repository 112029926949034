import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { EventsActionTypes } from '../State/actions/events.actions';
import { getEventFetched, getEventLatestUpdatedAt } from '../State/selectors/events.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class EventResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getEventFetched, getEventLatestUpdatedAt, EventsActionTypes.Load, store);
    }
}
