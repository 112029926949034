import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    GROUP_KEY,
    GroupState,
    selectAllGroup,
    selectGroupEntities,
    selectGroupFetched, selectGroupLatestUpdatedAt,
} from '../reducers/group.reducer';

const selectState = createFeatureSelector<GroupState>(GROUP_KEY);

export const getGroupDictionary = createSelector(selectState, selectGroupEntities);
export const getGroups = createSelector(selectState, selectAllGroup);
export const getGroupsActive = createSelector(getGroups, (entities) => entities.filter(e => !e.DeletedAt));
export const getGroupsFetched = createSelector(selectState, selectGroupFetched);
export const getGroupLatestUpdatedAt = createSelector(selectState, selectGroupLatestUpdatedAt);

export const getGroupById = (props: { id: number }) => createSelector(getGroupDictionary, (dictionary) => props?.id && dictionary[props.id]);
