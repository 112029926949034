import { PartnerResolver } from './../../../dave-data-module/guards/partner.resolver';
import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {State} from '../../../dave-data-module/State';
import {getPartner} from '../../../dave-data-module/State/selectors/partners.selectors';
import { PartnerSpecificationType } from '../../../dave-data-module/entities/partner.entity';

@Injectable({
    providedIn: 'root',
})
export class CommissionNameService implements OnDestroy {
    protected name$ = new BehaviorSubject<string>('Sache');
    protected subscription: Subscription;

    constructor(private store: Store<State>, partnerResolver: PartnerResolver) {
        this.subscription = combineLatest([this.store.select(getPartner), partnerResolver.resolve()])
            .pipe(filter(([p]) => p !== null && p !== undefined))
            .subscribe(([p]) => {
                if (p.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser) {
                    this.name$.next('Sache');
                } else {
                    this.name$.next('Bezeichnung');
                }
            });
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public get getName(){
        return this.name$.getValue();
    }

    public get getName$() {
        return this.name$.asObservable();
    }
}
