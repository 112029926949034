import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { OfficeActionTypes } from '../State/actions/office.actions';
import { getOfficeLatestUpdatedAt, getOfficesFetched } from '../State/selectors/offices.selectors';
import { BaseEntityReloadResolver2 } from './resolver.helper';

@Injectable({
    providedIn: 'root',
})
export class OfficeResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getOfficesFetched, getOfficeLatestUpdatedAt, OfficeActionTypes.Load, store);
    }
}
