export class WeatherEntity {
    constructor(
        public Description: string = null,
        public IconClass: string = null,
        public Location: string = null,
        public Temp: number = null,
    ) {}

    public Clone(override: WeatherEntityAttributes = {}): WeatherEntity {
        const attr = {...this, ...override};
        return Object.assign(new WeatherEntity(), attr);
    }
}

export interface WeatherEntityAttributes {
    Description?: string;
    IconClass?: string;
    Location?: string;
    Temp?: number;
}
