import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { OAuthTokenEntity } from '../../entities/o-auth-token.entity';
import { OAuthTokenActionTypes } from '../actions/o-auth-token.actions';

export interface OAuthTokenState extends EntityState<OAuthTokenEntity> {
    fetched: boolean;
}

export const O_AUTH_TOKEN_KEY = 'OAuthToken';

const adapter = createEntityAdapter<OAuthTokenEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const oAuthTokenReducer = createReducer(
    initialState,
    on(OAuthTokenActionTypes.UpdateAll, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllOAuthToken = selectAll;
export const selectOAuthTokenEntities = selectEntities;
export const selectOAuthTokenFetched = (state: OAuthTokenState) => state.fetched;
