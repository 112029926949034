import { formatCurrency, formatNumber, getCurrencySymbol } from "@angular/common";
import { ɵFormGroupValue } from '@angular/forms';
import { Dictionary } from '@ngrx/entity';
import {
    AccountsReceivableLedgerEntity,
    AccountsReceivableLedgerTypeEnum,
    CalcAccountsReceivableLedgerAmount,
    CalcAccountsReceivableLedgerTaxAmount,
    CalcDiscount,
    isBookingPositionTypeEnum
} from "../../../dave-data-module/entities/accounts-receivable-ledger.entity";
import { QuantityTypeEntity } from '../../../dave-data-module/entities/quantity-type.entity';
import { isNotNullOrUndefined, uniqArray } from "../../../helper/helper";
import { calculationArlForm } from './calculation-arl-form-data.service';
import { arlTableDataArlForm } from './arl-form-data.service';

export function assignPositionStrings<T, K>(
    td: Array<T | K>,
    positionKey: string,
    determineIfIsArl: (toBeDetermined: T | K) => toBeDetermined is T,
    isSelected: (e: K | T) => boolean,
    isGroupEnd: (e: K) => boolean,
    isGroupStart: (e: K) => boolean,
) {
    const positions = calcPositionStringsLegacy(td, determineIfIsArl, isSelected, isGroupEnd, isGroupStart);
    return td.map((e, i) => Object.assign(e, { [positionKey]: positions[i] }));
}
export function calcPositionStrings<T, K>(td: Array<T | K>, determineIfIsArl: (toBeDetermined: T | K) => toBeDetermined is T, isGroupEnd: (e: K) => boolean, isGroupStart: (e: T) => boolean) {
    let currPosition = 0;
    let positionPrefix = '';
    return td.map((e) => {
        let position = '';
        if (!determineIfIsArl(e) && isGroupEnd(e)) {
            const prefarr = positionPrefix.split('.').filter((s) => s);
            currPosition = +prefarr.pop();
            positionPrefix = prefarr.length ? prefarr.join('.') + '.' : '';
        } else {
            position = positionPrefix + ++currPosition;

            if (determineIfIsArl(e) && isGroupStart(e)) {
                positionPrefix = position + '.';
                currPosition = 0;
            }
        }
        return position;
    });
}

export function calcPositionStringsLegacy<T, K>(td: Array<T | K>, determineIfIsArl: (toBeDetermined: T | K) => toBeDetermined is T, isSelected: (e: K | T) => boolean, isGroupEnd: (e: K) => boolean, isGroupStart: (e: K) => boolean) {
    let currPosition = 0;
    let positionPrefix = '';
    let groupSum = 0;
    return td.map((e) => {
        let position = '';

        if (determineIfIsArl(e)) {
            // let position = '';

            if (isSelected(e)) {
                position = positionPrefix + ++currPosition;
                // groupSum += e.entity.CompleteCost;
            }
        } else {
            // let position = '';
            if (isSelected(e)) {
                if (isGroupEnd(e)) {
                    const prefarr = positionPrefix.split('.').filter((s) => s);
                    currPosition = +prefarr.pop();
                    positionPrefix = prefarr.length ? prefarr.join('.') + '.' : '';
                    // Object.assign(e, { amount: groupSum });
                } else {
                    position = positionPrefix + ++currPosition;

                    if (isGroupStart(e)) {
                        positionPrefix = /*positionPrefix +*/ position + '.';
                        currPosition = 0;
                        groupSum = 0;
                    }
                }
            }
        }
        return position;
    });
}
interface bpi {
    BookingText: string;
    Information: string;
    Quantity: number;
    QuantityTypeId: number;
    BaseCost: number;
    CurrencyCode: string;
    CompleteCost: number;
    CompleteTaxCost: number;
    Type: AccountsReceivableLedgerTypeEnum;

    IsVisible: boolean;
    ArlIds: number[];
    Id: number;
    Tax: number;

    Longtext?: string;
    ShowLongtext?: boolean;
}
export const getBookingPositionTwigVariables = (
    arlIds: Array<number> = [],
    quantityTypes: QuantityTypeEntity[],
    discountPercentage: number,
    arlDict: Dictionary<AccountsReceivableLedgerEntity>,
    useArlForms?: (id: number) => arlTableDataArlForm,
    calculationFormMap?: Map<number, Array<ɵFormGroupValue<calculationArlForm> & { getBaseCost: () => number }>>,
    defaultValuesForAddedArls?: { CurrencyCode: string; IsVisible: boolean; Tax: number },
) => {
    console.group('getBookingPositionTwigVariables');
    const getArlFormDataWithChildren = (id: number, position: string, parentId: number, index: number, quantityMultiplier: number = 1, inheritFromChildren: boolean = false): Array<{ values: bpi; position: string; quantityMultiplier: number; onlyForCalculation: boolean; isGroupEnd?: boolean }> => {
        const arlFromState = arlDict[id];
        if (id && !arlFromState) {
            //arl must be deleted
            return [];
        }
        console.log({ arlFromState, quantityMultiplier });
        const returnFromEntity = () => {
            if (!arlFromState) {
                console.error('arl not found, id; ' + id);
                return [];
            }
            const ret: Array<{ values: bpi; position: string; quantityMultiplier: number; onlyForCalculation: boolean; isGroupEnd?: boolean }> = [
                { values: arlFromState, position, quantityMultiplier, onlyForCalculation: inheritFromChildren },
                ...(arlFromState.InheritFromChildren || arlFromState.Type === AccountsReceivableLedgerTypeEnum.Group ? arlFromState.ArlIds : [])
                    .map((id, i) => getArlFormDataWithChildren(id, position + '.' + (i + 1), arlFromState.Id, i, arlFromState.Type === AccountsReceivableLedgerTypeEnum.Group ? 1 : arlFromState.Quantity, arlFromState.InheritFromChildren))
                    .flat(),
            ];
            if (arlFromState.Type === AccountsReceivableLedgerTypeEnum.Group) {
                ret.push({
                    values: arlFromState,
                    position,
                    quantityMultiplier,
                    onlyForCalculation: false,
                    isGroupEnd: true
                });
            }
            return ret;
        };
        if (!useArlForms) {
            return returnFromEntity();
        }
        const form = useArlForms(id);
        let arlFromForm: Partial<{
            InheritFromChildren: boolean;
            Type: AccountsReceivableLedgerTypeEnum;
            Quantity: number;
            ARLIds: number[];
            BaseCost: number;
            getBaseCost?: () => number;
            IsVisible?: boolean;
            Longtext?: string;
            ShowLongtext?: boolean;
        }> = form?.getRawValue();
        if (!arlFromForm) {
            // todo das ist für die arls in der Kalkulation, deren forms stehen (noch) nicht in dem formservice
            if ( calculationFormMap?.has(parentId)) {
                arlFromForm = calculationFormMap.get(parentId)[index];
                if (isNotNullOrUndefined(arlFromForm?.getBaseCost)) {
                    arlFromForm.BaseCost = arlFromForm.getBaseCost();
                }
            }
            console.log('arlFromForm 1', arlFromForm, arlFromState);
            if (!arlFromForm) {
                return returnFromEntity();
            }
        }
        console.log('arlFromForm 2', arlFromForm, arlFromState);

        const arlsToReturn = [];
        let missingArls = 0;
        (arlFromForm.InheritFromChildren || arlFromForm.Type === AccountsReceivableLedgerTypeEnum.Group ? arlFromForm.ARLIds : [])?.forEach((childId, i) => {
            const bpsWithChildren = getArlFormDataWithChildren(childId, position + '.' + (i - missingArls + 1), id, i, arlFromForm.Type === AccountsReceivableLedgerTypeEnum.Group ? 1 : arlFromForm.Quantity, arlFromForm.InheritFromChildren);
            if (bpsWithChildren.length) {
                arlsToReturn.push(...bpsWithChildren);
            } else {
                missingArls++;
            }
        });

        const values = {
                CurrencyCode: arlFromState ? arlFromState.CurrencyCode : defaultValuesForAddedArls.CurrencyCode,
                CompleteCost: CalcAccountsReceivableLedgerAmount(arlFromForm.Quantity, arlFromState ? arlFromState.Tax : defaultValuesForAddedArls.Tax, arlFromForm.BaseCost),
                CompleteTaxCost: CalcAccountsReceivableLedgerTaxAmount(arlFromForm.Quantity, arlFromState ? arlFromState.Tax : defaultValuesForAddedArls.Tax, arlFromForm.BaseCost),
                IsVisible: isNotNullOrUndefined(arlFromForm?.IsVisible) ? arlFromForm.IsVisible : arlFromState ? arlFromState.IsVisible : defaultValuesForAddedArls.IsVisible,
                ArlIds: arlFromForm.ARLIds,
                Id: id,
                Tax: arlFromState ? arlFromState.Tax : defaultValuesForAddedArls.Tax,
                ...arlFromForm,
                Quantity: arlFromForm.Quantity || 0,
            };
        const ret = [
            {
                values,
                quantityMultiplier,
                position,
                onlyForCalculation: inheritFromChildren,
            },
            ...arlsToReturn, //arlFromForm.ARLIds.map((id, i) => getArlFormDataWithChildren(id, position + '.' + (i + 1))).flat(),

        ];
        if (arlFromForm.Type === AccountsReceivableLedgerTypeEnum.Group) {
            ret.push({
                values,
                position,
                quantityMultiplier,
                onlyForCalculation: false,
                isGroupEnd: true
            });
        }
        return ret;
    };

    // const BookingPositions = arlIds.map(id => getArlFormData(id));
    const BookingPositions: ReturnType<typeof getArlFormDataWithChildren> = [];
    let missingArls = 0;
    arlIds.forEach((id, i) => {
        const bpsWithChildren = getArlFormDataWithChildren(id, i - missingArls + 1 + '', null, i);
        if (bpsWithChildren.length) {
            BookingPositions.push(...bpsWithChildren);
        } else {
            missingArls++;
        }
    }); //.flat();
    console.log('new BookingPositions', BookingPositions);
    const DiscountPercentage = discountPercentage || '';
    let DiscountAmount = '0';
    let SumNetto = '0';
    let SumNettoWithDiscount = '0';
    let Sum = '0';
    let SumWithDiscount = '0';
    let Taxes = [];
    BookingPositions.filter(({ onlyForCalculation }) => !onlyForCalculation).forEach(({ values }) => {
        const bp = values;
        if (bp && bp?.CompleteTaxCost !== undefined && bp.IsVisible) {
            SumNetto = (+SumNetto + bp.CompleteCost - bp.CompleteTaxCost).toFixed(2);
            Sum = (+Sum + bp.CompleteCost).toFixed(2);
            SumNettoWithDiscount = (+SumNettoWithDiscount + CalcDiscount(bp.CompleteCost - bp.CompleteTaxCost, discountPercentage)).toFixed(2);
            SumWithDiscount = (+SumWithDiscount + CalcDiscount(bp.CompleteCost, discountPercentage)).toFixed(2);
            if (Taxes.find(([tax, sum]) => tax === bp.Tax)) {
                const index = Taxes.indexOf(Taxes.find(([tax, sum]) => tax === bp.Tax));
                Taxes[index][1] = (+Taxes[index][1] + CalcDiscount(bp.CompleteTaxCost, discountPercentage)).toFixed(2);
            } else {
                Taxes.push([bp.Tax, CalcDiscount(bp.CompleteTaxCost, discountPercentage).toFixed(2)]);
            }
        }
    });
    // ja hier wird einfach der currencyCode qus dem ersten arl genommen, das BE stellt sicher das es keine verschiedenen in einer Rechnung gibt
    const CurrencyCode = BookingPositions.find((bp) => true)?.values?.CurrencyCode;
    if (CurrencyCode) {
        // const CurrencyCode = BookingPositions[0].CurrencyCode;
        DiscountAmount = formatCurrency(+(+SumNettoWithDiscount - +SumNetto).toFixed(2), 'de-DE', getCurrencySymbol(CurrencyCode, 'narrow'), CurrencyCode);

        SumNetto = formatCurrency(+SumNetto, 'de-DE', getCurrencySymbol(CurrencyCode, 'narrow'), CurrencyCode);
        SumWithDiscount = formatCurrency(+SumWithDiscount, 'de-DE', getCurrencySymbol(CurrencyCode, 'narrow'), CurrencyCode);
        Sum = formatCurrency(+Sum, 'de-DE', getCurrencySymbol(CurrencyCode, 'narrow'), CurrencyCode);
        Taxes = Taxes.slice().map(([tax, sum]) => [tax, formatCurrency(sum, 'de-DE', getCurrencySymbol(CurrencyCode, 'narrow'), CurrencyCode)]);
    }
    const digitsInfo = '1.2-2';
    const getBPVar = (b: bpi, quantityMultiplier: number) => {

        return {
            BookingText: b.BookingText || '',
            Information: b.Information || '',
            Quantity: isBookingPositionTypeEnum(b.Type) && b.Quantity ? formatNumber(b.Quantity * quantityMultiplier, 'de-DE', digitsInfo) + '' : '',
            QuantityType: quantityTypes.find((q) => q.Id === b.QuantityTypeId)?.Name || '',
            BaseCost: isBookingPositionTypeEnum(b.Type) ? (formatNumber(b.BaseCost, 'de-DE' /*getCurrencySymbol(b.CurrencyCode, 'narrow'), b.CurrencyCode*/, digitsInfo) || '') : '',
            BaseCostWithTax: isBookingPositionTypeEnum(b.Type) ? (formatNumber(CalcAccountsReceivableLedgerAmount(1, b.Tax, b.BaseCost), 'de-DE' /*getCurrencySymbol(b.CurrencyCode, 'narrow'), b.CurrencyCode*/, digitsInfo) || '') : '',
            CompleteCost: isBookingPositionTypeEnum(b.Type) ? formatNumber(+(((b.CompleteCost || 0) - (b.CompleteTaxCost || 0)) * quantityMultiplier).toFixed(4), 'de-DE' /*getCurrencySymbol(b.CurrencyCode, 'narrow'), b.CurrencyCode*/, digitsInfo) : '',
            CompleteCostWithTax: isBookingPositionTypeEnum(b.Type) ? formatNumber(+((b.CompleteCost || 0) * quantityMultiplier).toFixed(4), 'de-DE' /*getCurrencySymbol(b.CurrencyCode, 'narrow'), b.CurrencyCode*/, digitsInfo) : '',
            Longtext: b.ShowLongtext && b.Longtext ? b.Longtext : '',
            isBookingPosition: isBookingPositionTypeEnum(b.Type),
        };
    };
    const bps = [];

    BookingPositions.forEach((b, index) => {
        if (b.values.IsVisible /*|| !b.values.ArlIds || !b.values.ArlIds.length*/) {
            // const arl = getArlFormData(b.Id);
            // if (!arl) {
            //     throw new Error('ARL not found');
            // }
            let vars;
            if (b.isGroupEnd) {
                console.warn('# group end')
                console.log(vars)
                console.log(b)
                const sums = [];
                for (let i = index -1; i > 0; i--) {
                    console.log({i, index});
                    const v = BookingPositions[i];
                    if (v.values.Id === b.values.Id) {
                        i = 0;
                    } else if (v.values.IsVisible) {
                        if (!v.onlyForCalculation) {
                           sums.push((v.values.CompleteCost || 0) - (v.values.CompleteTaxCost || 0))
                        }
                    }
                }
                vars = {
                    Information: 'Summe '+ b.position + ': ' + b.values.Information,
                    CompleteCost: formatNumber(+(sums.reduce((p, c) => p+c, 0) * b.quantityMultiplier).toFixed(4), 'de-DE' /*getCurrencySymbol(b.CurrencyCode, 'narrow'), b.CurrencyCode*/, digitsInfo),
                    isBookingPosition: false,
                    isGroupEnd: true,
                    Position: '',
                };
            } else {
                vars = getBPVar(b.values, b.quantityMultiplier);
            }

            bps.push({
                Position: b.position,
                isCalculationArl: !!b.onlyForCalculation,
                ...vars,
            });
        }

    });
    console.log({ bps, BookingPositions });
    console.groupEnd();
    return {
        SumNetto,
        SumNettoWithDiscount,
        Sum,
        SumWithDiscount,
        Taxes: Taxes.map((t) => ({ tax: t[0], sum: t[1] })),
        DiscountAmount,
        DiscountPercentage,
        BookingPositions: bps,
    };
};
export const _getArlChildren: (id: number, allArls: Dictionary<AccountsReceivableLedgerEntity>) => AccountsReceivableLedgerEntity[] = (id: number, allArls: Dictionary<AccountsReceivableLedgerEntity>) => {
    const arl = allArls[id];
    let ret = null;
    if (!arl) {
        console.error('arl not found, id; ' + id);
        return [];
    }
    if (arl.Type === AccountsReceivableLedgerTypeEnum.Group && arl.ArlIds?.length) {
        console.log('_getArlChildren arl.ArlIds', uniqArray(arl.ArlIds));
        ret = [arl,
            ...uniqArray(arl.ArlIds)
                .map((i) => _getArlChildren(i, allArls))
                .flat(),
        ];
    } else {
        ret = [arl];
    }
    console.log('_getArlChildren ', id, ret);
    return ret;
};
// const getChildren = (bpId: number, submittedBookingPositions: AccountsReceivableLedgerEntity[]): AccountsReceivableLedgerEntity[] => {
//     const bp = submittedBookingPositions.find(b => b.Id === bpId);
//     if (bp?.ArlIds.length && bp.Type === ARLTypeEnum.Group) {
//         return [bp, ...bp.ArlIds.map(id => getChildren(id, submittedBookingPositions)).flat()]
//     } else if (bp) {
//         return [bp];
//     }
//     return []
// }
