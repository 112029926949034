import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { map, skip, switchMap, take, tap } from 'rxjs/operators';
import { SharedCommissionsEntity } from '../../../../dave-data-module/entities/sharedCommissions.entity';
import { PartnerEntity } from '../../../../dave-data-module/entities/partner.entity';
import { SharedCommissionsActionTypes } from '../../../../dave-data-module/State/actions/sharedCommissions.actions';
import { getSharedCommissions } from '../../../../dave-data-module/State/selectors/sharedCommissions.selectors';
import { getPartners } from '../../../../dave-data-module/State/selectors/partners.selectors';
import { State } from '../../../../dave-data-module/State';
import { isNotNullOrUndefined } from '../../../../helper/helper';

interface DisplayPartner {
    Selected: boolean;
    Partner: PartnerEntity;
}

@Component({
    selector: 'app-select-partner',
    templateUrl: './select-partner.component.html',
    styleUrls: ['./select-partner.component.scss'],
})
export class SelectPartnerComponent implements OnInit {
    public DisplayPartner$: Observable<DisplayPartner[]>;
    public Save$ = new Subject<void>();
    private subscription: Subscription;
    protected selectedPartners$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

    constructor(
        public dialogRef: MatDialogRef<any>,
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA) public Data: SharedCommissionsEntity[],
    ) {
        this.subscription = this.Save$.pipe(
            tap(() => {
                var newObjects = [];
                    for (const sp of this.selectedPartners$.getValue()) {
                        if (!isNotNullOrUndefined(this.Data.find(d => d.PartnerLinkedId == sp))) {
                            newObjects.push({
                                commissionId: this.Data[0].CommissionId,
                                partnerLinkedId: sp,
                            });
                        }
                    }
                this.store.dispatch(
                    SharedCommissionsActionTypes.AddSharedCommissions({
                        Payload: newObjects,
                    }),
                );

                    var deleteObjects = [];
                    for (const d of this.Data.filter(d => d.PartnerLinkedId != null)) {
                        if (!isNotNullOrUndefined(this.selectedPartners$.getValue().find(sp => d.PartnerLinkedId == sp))) {
                            if(deleteObjects.filter(dO => dO.PartnerLinkedId == d.PartnerLinkedId).length <= 0) {
                                deleteObjects.push({
                                    commissionId: this.Data[0].CommissionId,
                                    partnerLinkedId: d.PartnerLinkedId,
                                });
                            }
                        }
                    }
                this.store.dispatch(
                    SharedCommissionsActionTypes.DeleteSharedCommissions({
                        Payload: deleteObjects,
                    }),
                );
                    this.dialogRef.close();
                },
            )
        ).subscribe();
    }

    // sharedcommission -> 1 partner raussuchen
    ngOnInit(): void {
        this.DisplayPartner$ = combineLatest([
            this.selectedPartners$,
            this.store.select(getPartners),
        ]).pipe(
            map(([selected, partners]) =>
                partners.map(p => {
                    return {
                        Selected: isNotNullOrUndefined(selected.find(s => s === p.Id)),
                        Partner: p,
                    };
                }),
            ),
        );
        var sps = this.selectedPartners$.getValue();
        for (var d of this.Data) {
            if (isNotNullOrUndefined(d.PartnerLinkedId)) {
                sps.push(d.PartnerLinkedId);
            }
        }
        this.selectedPartners$.next(sps);
    }

    public ToggleSelected(id: number) {
        var oldSelectedPartner = this.selectedPartners$.getValue();
        if (isNotNullOrUndefined(oldSelectedPartner.find(osp => osp == id))) {

            var newSP = oldSelectedPartner.filter(osp => osp != id);
            this.selectedPartners$.next(newSP);
        } else {
            oldSelectedPartner.push(id);
            this.selectedPartners$.next(oldSelectedPartner);
        }
    }
}
