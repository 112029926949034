import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { MaterialActionTypes } from '../State/actions/material.actions';

import { BaseEntityReloadResolver2 } from './resolver.helper';
import { GetTimestampFromTime } from '@dave/types';
import { getMaterialLatestUpdatedAt, getMaterialsFetched } from '../State/selectors/material.selector';

@Injectable({
    providedIn: 'root',
})
export class MaterialResolver extends BaseEntityReloadResolver2 {
    constructor(store: Store<State>) {
        super(getMaterialsFetched, getMaterialLatestUpdatedAt, MaterialActionTypes.Load, store,(d) => GetTimestampFromTime(d)+'');
    }
}
